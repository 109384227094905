export const API_URL_VO = process.env.REACT_APP_API_URL
  ? `${process.env.REACT_APP_API_URL}`
  : 'https://go.epilot.cloud/ivy/api/epilot-cloud/v0';

export const SLS_FE_CONFIG_API_URL = process.env.REACT_APP_SLS_FE_CONFIG_URL
  ? `${process.env.REACT_APP_SLS_FE_CONFIG_URL}`
  : 'https://frontend.sls.epilot.io/v1';

export const SLS_ORG_GEO_SEARCH_URL = process.env.REACT_APP_SLS_ORG_GEO_SEARCH_URL
  ? `${process.env.REACT_APP_SLS_ORG_GEO_SEARCH_URL}`
  : 'https://organization-location.sls.epilot.io/v1';

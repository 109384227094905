import { createSelector } from 'reselect';
import _ from 'lodash';
import { productsSelector, questionConfigCJSelector } from '.';
import {
  HOUSE_CONNECTION_ELECTRICITY_FEED_INTO_GRID,
  HOUSE_CONNECTION_EXTINGUISHING_WATER_DEMAND,
  PACKAGE_SELECTED_KEY,
  PRODUCT_AVAILABLE_STATUS,
  PRODUCT_TYPE,
  QUESTION_ID,
  QUESTION_ID_INPUT_ANSWER_MAPPING,
  QUESTION_ID_SELECTION_ANSWER_MAPPING,
  SPECIAL_CHARACTER
} from '../../common/constants';
import { removeAddon } from '../../common/utils';

const getProductSelections = state => state.productSelections;
const getProducts = state => productsSelector(state);
const getCustomerJourneyAnswer = state => state.customerJourneyAnswer;
const getQuestionConfigCJ = state => questionConfigCJSelector(state);

export const productSelectionsSelector = createSelector(
  [getProducts, getProductSelections, getCustomerJourneyAnswer, getQuestionConfigCJ],
  (products, productSelections, customerJourneyAnswer, questionConfigCJ) => {
    let cloneProductSelections = _.assign({}, productSelections);
    _.forEach(products, product => {
      let packageSelectedKey = PACKAGE_SELECTED_KEY[product.type];
      if (product.showOnRequest) {
        cloneProductSelections[packageSelectedKey] = {};
        cloneProductSelections.selectedAddonIds = removeAddon(product, cloneProductSelections);
        return;
      }
      if (product.availableStatus === PRODUCT_AVAILABLE_STATUS.DEACTIVATED) {
        cloneProductSelections.selectedAddonIds = removeAddon(product, cloneProductSelections);
        return;
      }
      let questionId;
      switch (product.type) {
        case PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY:
          questionId = QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_FEED_INTO_GRID;
          if (
            isSelectionAnswerYes(
              questionId,
              customerJourneyAnswer,
              HOUSE_CONNECTION_ELECTRICITY_FEED_INTO_GRID.YES
            )
          ) {
            if (isInputAnswerExceedMaxValue(questionId, questionConfigCJ, customerJourneyAnswer)) {
              cloneProductSelections[packageSelectedKey] = {};
              cloneProductSelections.selectedAddonIds = removeAddon(
                product,
                cloneProductSelections
              );
              return;
            }
          }
          break;
        case PRODUCT_TYPE.HOUSE_CONNECTION_GAS:
          questionId = QUESTION_ID.HOUSE_CONNECTION_OUTLET_PRESSURE_REQUIRED;
          if (isInputAnswerExceedMaxValue(questionId, questionConfigCJ, customerJourneyAnswer)) {
            cloneProductSelections[packageSelectedKey] = {};
            cloneProductSelections.selectedAddonIds = removeAddon(product, cloneProductSelections);
            return;
          }
          break;
        case PRODUCT_TYPE.HOUSE_CONNECTION_WATER:
          questionId = QUESTION_ID.HOUSE_CONNECTION_EXTINGUISHING_WATER_DEMAND;
          if (
            isSelectionAnswerYes(
              questionId,
              customerJourneyAnswer,
              HOUSE_CONNECTION_EXTINGUISHING_WATER_DEMAND.YES
            )
          ) {
            if (isInputAnswerExceedMaxValue(questionId, questionConfigCJ, customerJourneyAnswer)) {
              cloneProductSelections[packageSelectedKey] = {
                packageName: cloneProductSelections[packageSelectedKey].packageName,
                id: null
              };
              cloneProductSelections.selectedAddonIds = removeAddon(
                product,
                cloneProductSelections
              );
              return;
            }
          }
          break;
        default:
          break;
      }
      cloneProductSelections[packageSelectedKey] = productSelections[packageSelectedKey];
      cloneProductSelections.selectedAddonIds = productSelections.selectedAddonIds;
    });
    return cloneProductSelections;
  }
);

export const isSelectionAnswerYes = (
  questionId,
  customerJourneyAnswer,
  questionYesSelectionMapping
) => {
  let questionSelectionAnswer =
    customerJourneyAnswer[QUESTION_ID_SELECTION_ANSWER_MAPPING[questionId]];
  return questionSelectionAnswer === questionYesSelectionMapping;
};

export const isInputAnswerExceedMaxValue = (
  questionId,
  questionConfigCJ,
  customerJourneyAnswer
) => {
  let questionConfig = questionConfigCJ[questionId];
  let questionInputAnswer = customerJourneyAnswer[QUESTION_ID_INPUT_ANSWER_MAPPING[questionId]];
  if (questionConfig && questionConfig.inputConfig) {
    if (!questionConfig.inputConfig.isMaxValueActive) return false;
    return !!(
      questionInputAnswer !== SPECIAL_CHARACTER.EMPTY &&
      questionInputAnswer > questionConfig.inputConfig.maxValue
    );
  }
  return false;
};

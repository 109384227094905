import * as _ from 'lodash';
import { CUSTOMER_TYPE } from '../../common/constants';
import {
  UPDATE_CONTACT_FORM,
  RESET_CONTACT_FORM,
  RESET_CUSTOMER_JOURNEY_DATA,
  CREATE_CUSTOMER_REQUEST,
  FRONTEND_CONFIGURATION_REQUEST,
  UPDATE_UPLOADED_RELATED_DOCUMENTS,
  UPDATE_UPLOADED_TAX_DOCUMENTS,
  REMOVE_UPLOADED_RELATED_DOCUMENT
} from '../actions';

let initState = {
  relatedDocuments: [],
  uploadTaxDocuments: [],
  customerData: {},
  customerType: CUSTOMER_TYPE.PRIVATE,
  isSubmittingContactForm: false
};

export default (state = initState, action) => {
  switch (action.type) {
    case CREATE_CUSTOMER_REQUEST:
      return {
        ...state,
        isSubmittingContactForm: true
      };
    case UPDATE_UPLOADED_RELATED_DOCUMENTS:
      return {
        ...state,
        relatedDocuments: [...state.relatedDocuments, ...action.payload]
      };
    case UPDATE_UPLOADED_TAX_DOCUMENTS:
      return {
        ...state,
        uploadTaxDocuments: [...state.uploadTaxDocuments, ...action.payload]
      };
    case REMOVE_UPLOADED_RELATED_DOCUMENT:
      return {
        ...state,
        relatedDocuments: _.reduce(
          state.relatedDocuments,
          (result, relatedDocument) => {
            if (relatedDocument?.name?.localeCompare(action.payload?.name) !== 0) {
              result.push(relatedDocument);
            }
            return result;
          },
          []
        )
      };
    case UPDATE_CONTACT_FORM:
      return {
        ...state,
        ...action.payload
      };
    case FRONTEND_CONFIGURATION_REQUEST:
      return {
        ...state,
        isSubmittingContactForm: false
      };
    case RESET_CONTACT_FORM:
    case RESET_CUSTOMER_JOURNEY_DATA:
      return initState;
    default:
      return state;
  }
};

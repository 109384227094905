import React from 'react';
import { useSelector } from 'react-redux';
import { COLOR } from '../common/constants';
import { StyledSpan } from '../components';
import { getFontTextBoldSelector, getFontTextSelector } from '../store/selectors';
import unsupportedBrowser from '../assets/images/unsupported-browser.svg';

const UnsupportedBrowserPage = () => {
  const { frontendConfiguration } = useSelector(state => state);
  const fontText = getFontTextSelector(useSelector(state => state));
  const fontTextBold = getFontTextBoldSelector(useSelector(state => state));
  return (
    <div className="text-center ep--p-32" style={{ backgroundColor: COLOR.WHITE }}>
      <div className="d-flex justify-content-center align-items-center ep--mt-32">
        <StyledSpan
          font={fontTextBold}
          className="ep__txt--h1 ep__txt-semibold"
          style={{
            color: frontendConfiguration ? frontendConfiguration.primaryColor : COLOR.DEFAULT_BLUE
          }}
        >
          {'Browser not supported'}
        </StyledSpan>
      </div>
      <div className="d-flex justify-content-center align-items-center ep--mt-32">
        <StyledSpan
          font={fontText}
          className="ep__txt-regular"
          style={{ color: COLOR.DEFAULT_BLUE }}
        >
          {
            'This service is unfortunately not available with your browser. Please use current versions'
          }
        </StyledSpan>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <StyledSpan
          font={fontText}
          className="ep__txt-regular"
          style={{ color: COLOR.DEFAULT_BLUE }}
        >
          {' of Mozilla Firefox, Google Chrome, Safari, IE 11 or Microsoft Edge'}
        </StyledSpan>
      </div>
      <div className="mt-4 mr-2">
        <img src={unsupportedBrowser} alt="Unsupported browser" />
      </div>
    </div>
  );
};

export default UnsupportedBrowserPage;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { formatQuestion, ShowIconByQuestionIdUtil, ShowIfUtil } from '../../common/utils';
import {
  ATTRIBUTE,
  COLOR,
  PRODUCT_AVAILABLE_STATUS,
  PRODUCT_TYPE,
  QUESTION_ID,
  PRODUCT_STATUS
} from '../../common/constants';
import {
  StyledButton,
  StyledP,
  StyledSpan,
  InfoHelpDescription
} from '../../components/epilot-forms/common';
import { UPDATE_PRODUCT_SELECTIONS } from '../../store/actions';
import { productSelectionsSelector, productsSelector } from '../../store/selectors';

class MultipleChoiceQuestionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selections: [],
      selected: []
    };
  }

  componentDidMount() {
    const { question, productSelections } = this.props;
    const newSelections = [];
    const newSelected = [];

    switch (question.id) {
      case QUESTION_ID.HOUSE_CONNECTION_SELECT_PRODUCT:
        if (question.objectModel.items.hasOwnProperty(ATTRIBUTE.ELECTRICITY)) {
          newSelections.push(question.objectModel.items.Electricity);
          if (productSelections.isSelectedProductHouseConnectionForElectricity) {
            newSelected.push(question.objectModel.items.Electricity);
          }
        }
        if (question.objectModel.items.hasOwnProperty(ATTRIBUTE.GAS)) {
          newSelections.push(question.objectModel.items.Gas);
          if (productSelections.isSelectedProductHouseConnectionForGas) {
            newSelected.push(question.objectModel.items.Gas);
          }
        }
        if (question.objectModel.items.hasOwnProperty(ATTRIBUTE.WATER)) {
          newSelections.push(question.objectModel.items.Water);
          if (productSelections.isSelectedProductHouseConnectionForWater) {
            newSelected.push(question.objectModel.items.Water);
          }
        }
        if (question.objectModel.items.hasOwnProperty(ATTRIBUTE.HEATING)) {
          newSelections.push(question.objectModel.items.Heating);
          if (productSelections.isSelectedProductHouseConnectionForHeating) {
            newSelected.push(question.objectModel.items.Heating);
          }
        }
        if (question.objectModel.items.hasOwnProperty(ATTRIBUTE.BROADBAND)) {
          newSelections.push(question.objectModel.items.Broadband);
          if (productSelections.isSelectedProductHouseConnectionForBroadband) {
            newSelected.push(question.objectModel.items.Broadband);
          }
        }
        break;
      default:
        break;
    }

    this.setState({
      selections: newSelections,
      selected: newSelected
    });
  }

  componentWillReceiveProps(nextProps) {
    const { productSelections, question } = this.props;
    const { selected } = this.state;
    let cloneSelected = [...selected];

    if (nextProps.productSelections !== productSelections) {
      if (nextProps.productSelections.isSelectedProductHouseConnectionForElectricity) {
        if (cloneSelected.indexOf(question.objectModel.items.Electricity) < 0) {
          cloneSelected.push(question.objectModel.items.Electricity);
        }
      } else {
        if (cloneSelected.indexOf(question.objectModel.items.Electricity) >= 0) {
          cloneSelected.splice(cloneSelected.indexOf(question.objectModel.items.Electricity), 1);
        }
      }

      if (nextProps.productSelections.isSelectedProductHouseConnectionForGas) {
        if (cloneSelected.indexOf(question.objectModel.items.Gas) < 0) {
          cloneSelected.push(question.objectModel.items.Gas);
        }
      } else {
        if (cloneSelected.indexOf(question.objectModel.items.Gas) >= 0) {
          cloneSelected.splice(cloneSelected.indexOf(question.objectModel.items.Gas), 1);
        }
      }

      if (nextProps.productSelections.isSelectedProductHouseConnectionForWater) {
        if (cloneSelected.indexOf(question.objectModel.items.Water) < 0) {
          cloneSelected.push(question.objectModel.items.Water);
        }
      } else {
        if (cloneSelected.indexOf(question.objectModel.items.Water) >= 0) {
          cloneSelected.splice(cloneSelected.indexOf(question.objectModel.items.Water), 1);
        }
      }

      if (nextProps.productSelections.isSelectedProductHouseConnectionForHeating) {
        if (cloneSelected.indexOf(question.objectModel.items.Heating) < 0) {
          cloneSelected.push(question.objectModel.items.Heating);
        }
      } else {
        if (cloneSelected.indexOf(question.objectModel.items.Heating) >= 0) {
          cloneSelected.splice(cloneSelected.indexOf(question.objectModel.items.Heating), 1);
        }
      }

      if (nextProps.productSelections.isSelectedProductHouseConnectionForBroadband) {
        if (cloneSelected.indexOf(question.objectModel.items.Broadband) < 0) {
          cloneSelected.push(question.objectModel.items.Broadband);
        }
      } else {
        if (cloneSelected.indexOf(question.objectModel.items.Broadband) >= 0) {
          cloneSelected.splice(cloneSelected.indexOf(question.objectModel.items.Broadband), 1);
        }
      }
    }
    this.setState({ selected: cloneSelected });
  }

  select = selection => {
    const { question, productSelections, dispatch } = this.props;
    let { selected } = this.state;
    let cloneSelected = [...selected];

    let newProductSelections = {
      selectedAddonIds: productSelections.selectedAddonIds
    };

    switch (question.id) {
      case QUESTION_ID.HOUSE_CONNECTION_SELECT_PRODUCT:
        if (cloneSelected.indexOf(selection) >= 0) {
          cloneSelected.splice(cloneSelected.indexOf(selection), 1);
          switch (selection) {
            case question.objectModel.items.Electricity:
              newProductSelections.isSelectedProductHouseConnectionForElectricity = false;
              newProductSelections = this.unselectAddon(
                newProductSelections,
                PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY
              );
              break;
            case question.objectModel.items.Gas:
              newProductSelections.isSelectedProductHouseConnectionForGas = false;
              newProductSelections = this.unselectAddon(
                newProductSelections,
                PRODUCT_TYPE.HOUSE_CONNECTION_GAS
              );
              break;
            case question.objectModel.items.Water:
              newProductSelections.isSelectedProductHouseConnectionForWater = false;
              newProductSelections = this.unselectAddon(
                newProductSelections,
                PRODUCT_TYPE.HOUSE_CONNECTION_WATER
              );
              break;
            case question.objectModel.items.Heating:
              newProductSelections.isSelectedProductHouseConnectionForHeating = false;
              newProductSelections = this.unselectAddon(
                newProductSelections,
                PRODUCT_TYPE.HOUSE_CONNECTION_HEAT
              );
              break;
            case question.objectModel.items.Broadband:
              newProductSelections.isSelectedProductHouseConnectionForBroadband = false;
              newProductSelections = this.unselectAddon(
                newProductSelections,
                PRODUCT_TYPE.HOUSE_CONNECTION_BROADBAND
              );
              break;
            default:
              break;
          }
        } else {
          cloneSelected.push(selection);
          switch (selection) {
            case question.objectModel.items.Electricity:
              newProductSelections.isSelectedProductHouseConnectionForElectricity = true;
              break;
            case question.objectModel.items.Gas:
              newProductSelections.isSelectedProductHouseConnectionForGas = true;
              break;
            case question.objectModel.items.Water:
              newProductSelections.isSelectedProductHouseConnectionForWater = true;
              break;
            case question.objectModel.items.Heating:
              newProductSelections.isSelectedProductHouseConnectionForHeating = true;
              break;
            case question.objectModel.items.Broadband:
              newProductSelections.isSelectedProductHouseConnectionForBroadband = true;
              break;
            default:
              break;
          }
        }
        break;
      default:
        break;
    }
    this.setState({ selected: cloneSelected });
    dispatch({
      type: UPDATE_PRODUCT_SELECTIONS,
      payload: newProductSelections
    });
  };

  unselectAddon = (productSelections, productType) => {
    const {
      frontendConfiguration: { products }
    } = this.props;
    _.forEach(products, product => {
      if (product.type === productType) {
        _.forEach(product.addons, addon => {
          productSelections.selectedAddonIds.splice(
            productSelections.selectedAddonIds.indexOf(addon.id),
            1
          );
        });
      }
    });
    return productSelections;
  };

  isProductDeactivated = selection => {
    const { products, question } = this.props;
    const selectionProductMapping = {
      [question.objectModel.items.Electricity]: PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY,
      [question.objectModel.items.Gas]: PRODUCT_TYPE.HOUSE_CONNECTION_GAS,
      [question.objectModel.items.Water]: PRODUCT_TYPE.HOUSE_CONNECTION_WATER,
      [question.objectModel.items.Heating]: PRODUCT_TYPE.HOUSE_CONNECTION_HEAT,
      [question.objectModel.items.Broadband]: PRODUCT_TYPE.HOUSE_CONNECTION_BROADBAND
    };
    const currentSelectionProduct = _.find(
      products,
      product => product.type === selectionProductMapping[selection]
    );
    return currentSelectionProduct
      ? currentSelectionProduct.availableStatus === PRODUCT_AVAILABLE_STATUS.DEACTIVATED ||
          (currentSelectionProduct.status &&
            currentSelectionProduct.status === PRODUCT_STATUS.DEACTIVATED)
      : true;
  };

  renderSelections = () => {
    const { selected, selections } = this.state;
    const {
      fontText,
      question,
      frontendConfiguration,
      customerJourneyAnswer,
      defaultTextColor
    } = this.props;

    let button = {},
      color = COLOR.DARK_GRAY;

    return _.map(selections, (selection, index) => {
      let available = true;

      if (
        (selection === question.objectModel.items.Electricity &&
          customerJourneyAnswer.availableProduct.indexOf(
            PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY
          ) < 0) ||
        this.isProductDeactivated(selection)
      ) {
        available = false;
      }
      if (
        (selection === question.objectModel.items.Gas &&
          customerJourneyAnswer.availableProduct.indexOf(PRODUCT_TYPE.HOUSE_CONNECTION_GAS) < 0) ||
        this.isProductDeactivated(selection)
      ) {
        available = false;
      }
      if (
        (selection === question.objectModel.items.Water &&
          customerJourneyAnswer.availableProduct.indexOf(PRODUCT_TYPE.HOUSE_CONNECTION_WATER) <
            0) ||
        this.isProductDeactivated(selection)
      ) {
        available = false;
      }
      if (
        (selection === question.objectModel.items.Heating &&
          customerJourneyAnswer.availableProduct.indexOf(PRODUCT_TYPE.HOUSE_CONNECTION_HEAT) < 0) ||
        this.isProductDeactivated(selection)
      ) {
        available = false;
      }
      if (
        (selection === question.objectModel.items.Broadband &&
          customerJourneyAnswer.availableProduct.indexOf(PRODUCT_TYPE.HOUSE_CONNECTION_BROADBAND) <
            0) ||
        this.isProductDeactivated(selection)
      ) {
        available = false;
      }

      if (available) {
        if (_.indexOf(selected, selection) >= 0) {
          button = {
            minWidth: '96px',
            height: '96px',
            background: COLOR.WHITE,
            color: defaultTextColor,
            border: '2px solid',
            borderColor: frontendConfiguration.primaryColor,
            borderRadius: frontendConfiguration.borderRadiusMax4px,
            boxShadow: '0 0 10px 0 rgba(0,0,0,0.25)'
          };
          color = defaultTextColor;
        } else {
          button = {
            minWidth: '96px',
            height: '96px',
            background: COLOR.WHITE,
            color: defaultTextColor,
            border: '1px solid rgba(68,68,68,0.16)',
            borderRadius: frontendConfiguration.borderRadiusMax4px
          };
          color = defaultTextColor;
        }
      } else {
        button = {
          minWidth: '96px',
          height: '96px',
          backgroundColor: '#F9F9F9',
          color: '#8A8A8A',
          border: '1px solid rgba(68,68,68,0.16)',
          borderRadius: frontendConfiguration.borderRadiusMax4px
        };
        color = '#8A8A8A';
      }

      return (
        <div key={index} className="ep--mx-8 ep--my-8">
          <StyledButton
            className="ep-button ep__txt--sm"
            font={fontText}
            style={button}
            disabled={!available}
            onClick={() => this.select(selection)}
          >
            <div className="d-flex justify-content-end align-items-start">
              <ShowIfUtil condition={_.indexOf(selected, selection) >= 0}>
                <i
                  className="ep-icon-check-circle-08 ep__txt--md"
                  style={{ color: frontendConfiguration.primaryColor }}
                />
              </ShowIfUtil>
              <ShowIfUtil condition={_.indexOf(selected, selection) < 0}>
                <span>&nbsp;</span>
              </ShowIfUtil>
            </div>
            <div className="d-flex ep--mb-8 justify-content-center">
              {this.renderProductIconForSelections(selection, color)}
            </div>
            <StyledP font={fontText}>{selection}</StyledP>
          </StyledButton>
        </div>
      );
    });
  };

  renderProductIconForSelections = (selection, color) => {
    const { question } = this.props;
    switch (selection) {
      case question.objectModel.items.Electricity:
        return (
          <span>
            <i className="epc-icon-house-electricity ep__txt--ic" style={{ color: color }} />
          </span>
        );
      case question.objectModel.items.Gas:
        return (
          <span>
            <i className="epc-icon-house-gas ep__txt--ic" style={{ color: color }} />
          </span>
        );
      case question.objectModel.items.Water:
        return (
          <span>
            <i className="epc-icon-house-water ep__txt--ic" style={{ color: color }} />
          </span>
        );
      case question.objectModel.items.Heating:
        return (
          <span>
            <i className="epc-icon-house-heating ep__txt--ic" style={{ color: color }} />
          </span>
        );
      case question.objectModel.items.Broadband:
        return (
          <span>
            <i className="epc-icon-house-broadband ep__txt--ic" style={{ color: color }} />
          </span>
        );
      default:
        break;
    }
  };

  render() {
    const {
      fontText,
      question,
      frontendConfiguration,
      customerJourneyAnswer,
      defaultTextColor
    } = this.props;

    const textColor = {
      color: defaultTextColor
    };

    return (
      <div className="ep-customerjourneypage__question">
        <ShowIconByQuestionIdUtil
          questionId={question.id}
          colorIcon={frontendConfiguration.primaryColor}
        />
        <div className="text-center ep-min-height-70 ep--pt-8 ep--pb-16">
          <StyledSpan
            font={fontText}
            className="ep__txt-regular ep__txt--lg ep__txt-no-select"
            style={textColor}
          >
            {formatQuestion(question.questionTitle, customerJourneyAnswer.name)}
          </StyledSpan>
          <InfoHelpDescription
            showHelpDescription={question.showHelpDescription}
            tooltipInfoContent={<StyledSpan font={fontText}>{question.helpDescription}</StyledSpan>}
            classNameForInfoIcon="ep__txt--lg ep--ml-8"
            defaultTextColor={defaultTextColor}
          />
        </div>

        <div className="row align-items-center justify-content-center ep-min-height-130">
          {this.renderSelections()}
        </div>
      </div>
    );
  }
}

MultipleChoiceQuestionContainer.propTypes = {
  question: PropTypes.object,
  products: PropTypes.array,
  fontText: PropTypes.object,
  dispatch: PropTypes.func,
  defaultTextColor: PropTypes.string,
  productSelections: PropTypes.object,
  frontendConfiguration: PropTypes.object,
  customerJourneyAnswer: PropTypes.object
};

MultipleChoiceQuestionContainer.defaultProps = {
  question: {},
  products: [],
  fontText: {},
  defaultTextColor: COLOR.DARK_GRAY,
  dispatch: () => ({}),
  productSelections: {},
  frontendConfiguration: {},
  customerJourneyAnswer: {}
};

function mapStateToProps(state) {
  return {
    frontendConfiguration: state.frontendConfiguration,
    customerJourneyAnswer: state.customerJourneyAnswer,
    productSelections: productSelectionsSelector(state),
    products: productsSelector(state)
  };
}

export default connect(mapStateToProps)(MultipleChoiceQuestionContainer);

import _ from 'lodash';
import { FRONTEND_CONFIGURATION_SUCCESS, UPDATE_FRONTEND_CONFIGURATION } from '../actions';
import {
  FRONTEND_TYPE,
  PRODUCT_ORDER,
  QUESTION_ID,
  SPECIAL_CHARACTER
} from '../../common/constants';

export default (state = false, action) => {
  switch (action.type) {
    case FRONTEND_CONFIGURATION_SUCCESS:
      return {
        ...state,
        ...parseFrontendConfiguration(action.payload)
      };
    case UPDATE_FRONTEND_CONFIGURATION:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

const getBusinessQuestions = sourceQuestions => {
  let privatelyOrBusinessHCQuestion = _.find(
    sourceQuestions,
    question => question.id === QUESTION_ID.HOUSE_CONNECTION_PRIVATELY_OR_BUSINESS
  );
  return privatelyOrBusinessHCQuestion
    ? privatelyOrBusinessHCQuestion.objectModel.businessCustomerQuestions
    : [];
};

const parseFrontendConfiguration = data => {
  const products = data.products;
  let productsOrdered = [];

  _.forEach(products, product => {
    productsOrdered[PRODUCT_ORDER[product.type]] = product;
  });

  productsOrdered = _.filter(productsOrdered, product => product);

  const productIds = _.map(productsOrdered, 'id');

  const productMap = _.keyBy(productsOrdered, 'id');

  const questions = data.questionConfigurations ? data.questionConfigurations : [];

  const questionIds = _.map(questions, 'id');

  const questionMap = _.keyBy(questions, 'id');

  const addOnsV2 = data.addOnsV2 || [];

  const businessQuestions = getBusinessQuestions(questions);

  const packageMappings = data.packageMappings || {};

  const buyRentLabelValueItems = data.buyRentLabelValueItems;

  const customerTypeLabelValueItems = data.customerTypeLabelValueItems;

  const checkboxes = data.checkboxes;

  const normalCustomFonts = data.normalCustomFonts ? data.normalCustomFonts : null;

  const boldCustomFonts = data.boldCustomFonts ? data.boldCustomFonts : null;

  const designOptions = data.designOptions;

  const primaryColor =
    data.frontEndType === FRONTEND_TYPE.CONTACT_FORM
      ? designOptions.buttonBackground
      : designOptions.primaryColor;

  let borderRadiusMax4px = designOptions.borderRadius;
  if (borderRadiusMax4px > 4) {
    borderRadiusMax4px = 4;
  }
  return {
    products,
    productIds,
    productMap,
    questions,
    questionIds,
    questionMap,
    addOnsV2,
    businessQuestions,
    listRentalInfo: data.listRentalInfo,
    packageMappings,
    thankYouScreenOptionsModel: data.thankYouScreenOptionsModel,
    onlinePurchaseSettingModel: data.onlinePurchaseSettingModel,
    checkOutOptionsModel: data.checkOutOptionsModel,
    checkboxes,
    availableFields: data.contactFormItems,
    installationDetailsFields: data.installationDetailsFields,
    listAttachment: data.listAttachment,
    thirdPartyServiceSetting: data.thirdPartyServiceSetting,
    contactFormFileUploadOption: data.contactFormFileUploadOption,
    additionalAddressesConfiguration: data.additionalAddressesConfiguration,
    isInstallationDetailsFieldsActive: data.activeInstallationDetailsFields,
    isShowPackageInfo: designOptions.isShowPackageInfo,
    isProductBundlingEnable: data.activeProductBundling,
    isCJQuestionsSkipEnable: data.skipJourneyQuestions,
    displayPdfResponse: data.displayPdfResponse,
    pdfFilenameResponses: data.pdfFilenameResponses,
    displayAllowFileTypes: data.displayAllowFileTypes,
    allowFileTypes: data.allowFileTypes,
    isOneTimePayOff: data.oneTimePayOff,
    isAdvanced: designOptions.isAdvanced,
    kmlDataUrl: data.kmlDataUrl,
    defaultSellingOption: data.defaultSellingOption,
    fontFamily: designOptions.font,
    primaryColor: SPECIAL_CHARACTER.HASH + primaryColor,
    secondaryColor: SPECIAL_CHARACTER.HASH + designOptions.secondaryColor,
    primaryTextColor: SPECIAL_CHARACTER.HASH + designOptions.primaryTextColor,
    secondaryTextColor: SPECIAL_CHARACTER.HASH + designOptions.secondaryTextColor,
    backgroundColor: SPECIAL_CHARACTER.HASH + designOptions.backgroundColor,
    backgroundOpacity: designOptions.backgroundOpacity,
    textColor: SPECIAL_CHARACTER.HASH + designOptions.defaultTextColor,
    defaultTextColor: SPECIAL_CHARACTER.HASH + designOptions.defaultTextColor,
    borderRadius: designOptions.borderRadius + 'px',
    borderRadiusMax4px: borderRadiusMax4px + 'px',
    buttonBackground: SPECIAL_CHARACTER.HASH + designOptions.buttonBackground,
    buttonTextColor: SPECIAL_CHARACTER.HASH + designOptions.buttonTextColor,
    buttonBorderRadius: designOptions.buttonBorderRadius + 'px',
    designOptions: designOptions,
    frontendType: data.frontEndType,
    frontendStatus: data.status,
    termsOfService: data.termsOfService,
    privacyStatement: data.privacyStatement,
    adStatement: data.adStatement,
    normalCustomFonts,
    boldCustomFonts,
    contactFormType: data.contactFormType,
    buyRentLabelValueItems,
    customerTypeLabelValueItems,
    customCode: data.customCode,
    newCustomCode: data.newCustomCode,
    cancelScreenCJDefaultInfo: data.cancelScreenOptionsModel,
    locale: data.locale,
    availablePaymentTypes: data.availablePaymentTypes,
    brandId: data.brandId,
    uploadedPostcode: data.uploadedPostcode
  };
};

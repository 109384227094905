import React from 'react';
import * as PropTypes from 'prop-types';
import { COLOR } from '../../../common/constants';

function AddonCheckboxPopup({ popupContent, btnText, btnAction, defaultTextColor }) {
  let btnTextClassName =
    'ep__txt--sm ep__txt-semibold ep-text-decoration-underline ep-text-dark-blue ep-cursor-pointer';
  return (
    <div style={{ maxWidth: '10rem' }} className="text-center">
      <p className="ep__txt--sm ep__txt-semibold ep--mb-8" style={{ color: defaultTextColor }}>
        {popupContent}
      </p>
      <span className={btnTextClassName} onClick={btnAction}>
        {btnText}
      </span>
    </div>
  );
}

AddonCheckboxPopup.propTypes = {
  defaultTextColor: PropTypes.string,
  popupContent: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  btnAction: PropTypes.func.isRequired
};

AddonCheckboxPopup.defaultProps = {
  defaultTextColor: COLOR.DARK_GRAY
};

export default AddonCheckboxPopup;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { DropZoneFile, ShowIfUtil, getAcceptFileType } from '../../common/utils';
import {
  COLOR,
  EXAMPTION_DATA,
  INPUT_TYPE_CONTACT_FORM,
  SPECIAL_CHARACTER
} from '../../common/constants';
import {
  AutoComplete,
  DateInput,
  DropDown,
  Input,
  TextArea
} from '../../components/epilot-forms/common';

class QuestionContainer extends Component {
  onChange = e => {
    const { onChange } = this.props;
    onChange(e);
  };

  onItemSelect = value => {
    const { onItemSelect } = this.props;
    onItemSelect(value);
  };

  onBlur = e => {
    const { onBlur } = this.props;
    onBlur(e);
  };

  render() {
    const {
      label,
      borderRadius,
      primaryTextColor,
      placeholder,
      required,
      classStyle,
      fontFamily,
      borderColorFocus,
      options,
      type,
      initialValue,
      disabled,
      defaultTextColor,
      linkColor,
      acceptFileType,
      uploadFileStatus,
      isSubmittingContactForm
    } = this.props;
    return (
      <div>
        <ShowIfUtil condition={type === INPUT_TYPE_CONTACT_FORM.TEXT}>
          <Input
            label={label}
            borderRadius={borderRadius}
            primaryTextColor={primaryTextColor}
            defaultTextColor={defaultTextColor}
            placeholder={placeholder}
            require={required}
            onChange={this.onChange}
            onBlur={this.onBlur}
            classStyle={classStyle}
            fontFamily={fontFamily}
            value={initialValue}
            borderColorFocus={borderColorFocus}
          />
        </ShowIfUtil>

        <ShowIfUtil condition={type === INPUT_TYPE_CONTACT_FORM.TEXTAREA}>
          <TextArea
            label={label}
            borderRadius={borderRadius}
            primaryTextColor={primaryTextColor}
            defaultTextColor={defaultTextColor}
            placeholder={placeholder}
            require={required}
            onChange={this.onChange}
            onBlur={this.onBlur}
            classStyle={classStyle}
            fontFamily={fontFamily}
            value={initialValue}
            borderColorFocus={borderColorFocus}
          />
        </ShowIfUtil>

        <ShowIfUtil condition={type === INPUT_TYPE_CONTACT_FORM.DROPDOWN}>
          <DropDown
            label={label}
            placeholder={placeholder}
            borderRadius={borderRadius}
            primaryTextColor={primaryTextColor}
            defaultTextColor={defaultTextColor}
            onItemSelect={this.onItemSelect}
            onChange={this.onChange}
            onBlur={this.onBlur}
            selectedValue={initialValue}
            fontFamily={fontFamily}
            classStyle={classStyle}
            options={options}
            require={required}
            borderColorFocus={borderColorFocus}
            styleClassText="ep__txt--md ep__txt-regular"
          />
        </ShowIfUtil>

        <ShowIfUtil condition={type === INPUT_TYPE_CONTACT_FORM.AUTO_COMPLETE}>
          <AutoComplete
            disabled={disabled}
            label={label}
            placeholder={placeholder}
            borderRadius={borderRadius}
            primaryTextColor={primaryTextColor}
            defaultTextColor={defaultTextColor}
            require={required}
            onItemSelect={this.onItemSelect}
            onBlur={this.onBlur}
            fontFamily={fontFamily}
            classStyle={classStyle}
            options={options}
            popoverClassName="autocomplete-popover-custom"
            selectedValue={initialValue}
            inputClassName="ep-autocomplete-input"
            borderColorFocus={borderColorFocus}
            itemsLimited={false}
          />
        </ShowIfUtil>
        <ShowIfUtil condition={type === INPUT_TYPE_CONTACT_FORM.DOCUMENT_UPLOAD}>
          <DropZoneFile
            label={label}
            linkColor={linkColor}
            primaryTextColor={primaryTextColor}
            defaultTextColor={defaultTextColor}
            fontText={fontFamily}
            require={required}
            uploadInstruction={EXAMPTION_DATA.UPLOAD_INSTRUCTION}
            onUpdateData={this.onChange}
            acceptFileType={acceptFileType}
            isUploading={uploadFileStatus.isUploading || isSubmittingContactForm}
          />
        </ShowIfUtil>
        <ShowIfUtil condition={type === INPUT_TYPE_CONTACT_FORM.DATE_TIME_SELECT}>
          <DateInput
            label={label}
            inputClassName="ep-datepicker-input"
            primaryTextColor={primaryTextColor}
            defaultTextColor={defaultTextColor}
            fontFamily={fontFamily}
            require={required}
            onChange={this.onChange}
            value={SPECIAL_CHARACTER.EMPTY}
            minDate={new Date()}
          />
        </ShowIfUtil>
      </div>
    );
  }
}

QuestionContainer.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.array,
  classStyle: PropTypes.string,
  onChange: PropTypes.func,
  onItemSelect: PropTypes.func,
  onBlur: PropTypes.func,
  linkColor: PropTypes.string,
  primaryTextColor: PropTypes.string,
  defaultTextColor: PropTypes.string,
  label: PropTypes.string,
  fontFamily: PropTypes.object,
  borderRadius: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  borderColorFocus: PropTypes.string,
  disabled: PropTypes.bool,
  acceptFileType: PropTypes.string.isRequired,
  uploadFileStatus: PropTypes.object.isRequired,
  isSubmittingContactForm: PropTypes.bool.isRequired
};

QuestionContainer.defaultProps = {
  placeholder: SPECIAL_CHARACTER.EMPTY,
  type: SPECIAL_CHARACTER.EMPTY,
  required: false,
  options: [],
  classStyle: SPECIAL_CHARACTER.EMPTY,
  onChange: () => ({}),
  onItemSelect: () => ({}),
  onBlur: () => ({}),
  linkColor: COLOR.DEFAULT_BLUE,
  primaryTextColor: COLOR.DARK_GRAY,
  defaultTextColor: COLOR.DARK_GRAY,
  label: SPECIAL_CHARACTER.EMPTY,
  fontFamily: {},
  borderRadius: '4px',
  initialValue: SPECIAL_CHARACTER.EMPTY,
  borderColorFocus: SPECIAL_CHARACTER.EMPTY,
  disabled: false
};

function mapStateToProps(state) {
  return {
    acceptFileType: getAcceptFileType(state.frontendConfiguration),
    uploadFileStatus: state.uploadFileStatus,
    isSubmittingContactForm: state.contactFormData.isSubmittingContactForm
  };
}

export default connect(
  mapStateToProps,
  null
)(QuestionContainer);

import { createSelector } from 'reselect';
import _ from 'lodash';
import { productSelectionsSelector, selectedProductsSelector } from '.';
import {
  ATTRIBUTE,
  PACKAGE_PREMIUM_TYPE_KEY,
  PACKAGE_SELECTED_KEY,
  PAYMENT_TYPE,
  RENTAL_UNIT
} from '../../common/constants';
import { filterMonthlyPriceByPackageType, filterMonthlyPriceGrossByPackageType } from '../../common/utils';

const getProductSelections = state => productSelectionsSelector(state);
const getSelectedProducts = state => selectedProductsSelector(state);
const getFrontendConfiguration = state => state.frontendConfiguration;

export const totalPriceCalcSelector = createSelector(
  [getProductSelections, getSelectedProducts, getFrontendConfiguration],
  (productSelections, selectedProducts, frontendConfiguration) => {
    let totalPrice = {
      totalPrice: 0,
      totalPriceGross: 0,
      totalUpfrontFee: 0,
      totalUpfrontFeeGross: 0,
      totalPurchasePrice: 0,
      totalPurchasePriceGross: 0,
      totalTax: 0,
      totalUpfrontFeeTax: 0
    };

    _.forEach(selectedProducts, selectedProduct => {
      let isPremiumType = productSelections[PACKAGE_PREMIUM_TYPE_KEY[selectedProduct.type]];
      let productPackage = productSelections[PACKAGE_SELECTED_KEY[selectedProduct.type]];
      totalPrice = calculateTotalAmount({
        totalPrice,
        selectedProduct,
        isPremiumType,
        productPackage,
        productSelections,
        frontendConfiguration
      });
    });

    return totalPrice;
  }
);

const calculateTotalAmount = ({
  totalPrice,
  selectedProduct,
  isPremiumType,
  productPackage,
  productSelections,
  frontendConfiguration
}) => {
  if (productPackage && productPackage.hasOwnProperty(ATTRIBUTE.ID)) {
    const packagePurchasePrice = isPremiumType
      ? productPackage.premiumPurchasePrice
      : productPackage.standardPurchasePrice;
    const packagePurchasePriceGross = isPremiumType
      ? productPackage.premiumPurchasePriceGross
      : productPackage.standardPurchasePriceGross;
    totalPrice.totalPurchasePrice = totalPrice.totalPurchasePrice + packagePurchasePrice;
    totalPrice.totalPurchasePriceGross = totalPrice.totalPurchasePriceGross + packagePurchasePriceGross;

    if (productSelections.selectedPurchaseRental === PAYMENT_TYPE.PURCHASE) {
      const packageRetailPrice = isPremiumType
        ? productPackage.premiumRetailAble
          ? productPackage.premiumRetailPrice
          : 0
        : productPackage.standardRetailAble
        ? productPackage.standardRetailPrice
        : 0;
      const packageRetailPriceGross = isPremiumType
        ? productPackage.premiumRetailAble
          ? productPackage.premiumRetailPriceGross
          : 0
        : productPackage.standardRetailAble
        ? productPackage.standardRetailPriceGross
        : 0;
      totalPrice.totalPrice = totalPrice.totalPrice + packageRetailPrice;
      totalPrice.totalPriceGross = totalPrice.totalPriceGross + packageRetailPriceGross;
    } else {
      if (frontendConfiguration.listRentalInfo.length > 0) {
        const monthlyPrice = filterMonthlyPriceByPackageType(productSelections, isPremiumType, productPackage);
        const monthlyPriceGross = filterMonthlyPriceGrossByPackageType(
          productSelections,
          isPremiumType,
          productPackage
        );
        if (isPremiumType) {
          if (productPackage.premiumRentedStatus) {
            totalPrice.totalPrice = totalPrice.totalPrice + monthlyPrice;
            totalPrice.totalPriceGross = totalPrice.totalPriceGross + monthlyPriceGross;
            totalPrice.totalUpfrontFee = totalPrice.totalUpfrontFee + productPackage.premiumRentalUpfrontFee;
            totalPrice.totalUpfrontFeeGross =
              totalPrice.totalUpfrontFeeGross + productPackage.premiumRentalUpfrontFeeGross;
          }
        } else {
          if (productPackage.standardRentedStatus) {
            totalPrice.totalPrice = totalPrice.totalPrice + monthlyPrice;
            totalPrice.totalPriceGross = totalPrice.totalPriceGross + monthlyPriceGross;
            totalPrice.totalUpfrontFee = totalPrice.totalUpfrontFee + productPackage.standardRentalUpfrontFee;
            totalPrice.totalUpfrontFeeGross =
              totalPrice.totalUpfrontFeeGross + productPackage.standardRentalUpfrontFeeGross;
          }
        }
      } else {
        if (isPremiumType) {
          totalPrice.totalUpfrontFee = totalPrice.totalUpfrontFee + productPackage.premiumRentalUpfrontFee;
          totalPrice.totalUpfrontFeeGross =
            totalPrice.totalUpfrontFeeGross + productPackage.premiumRentalUpfrontFeeGross;
        } else {
          totalPrice.totalUpfrontFee = totalPrice.totalUpfrontFee + productPackage.standardRentalUpfrontFee;
          totalPrice.totalUpfrontFeeGross =
            totalPrice.totalUpfrontFeeGross + productPackage.standardRentalUpfrontFeeGross;
        }
      }
    }
  }

  selectedProduct.addons &&
    _.forEach(selectedProduct.addons, addon => {
      if (productSelections.selectedAddonIds.indexOf(addon.id) >= 0) {
        if (productSelections.selectedPurchaseRental === PAYMENT_TYPE.PURCHASE && addon.retailAble) {
          totalPrice.totalPrice = totalPrice.totalPrice + addon.retailPrice;
          totalPrice.totalPriceGross = totalPrice.totalPriceGross + addon.retailPriceGross;
        } else {
          if (addon.rentable) {
            _.forEach(addon.addonRentals, rental => {
              if (
                rental.duration === productSelections.selectedRentalInfo.duration &&
                rental.unit === RENTAL_UNIT[productSelections.selectedRentalInfo.unit]
              ) {
                totalPrice.totalPrice += rental.price;
                totalPrice.totalPriceGross += rental.grossPrice;
              }
            });
          }
        }
      }
    });

  totalPrice = calculateTax(totalPrice);

  return totalPrice;
};

const calculateTax = totalPrice => {
  totalPrice.totalTax = totalPrice.totalPriceGross - totalPrice.totalPrice;
  totalPrice.totalUpfrontFeeTax = totalPrice.totalUpfrontFeeGross - totalPrice.totalUpfrontFee;
  return totalPrice;
};

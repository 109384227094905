import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { Position, Tooltip } from '@blueprintjs/core';
import _ from 'lodash';
import {
  calculateHCElectricityMaxPower,
  convertDataToComma,
  formatQuestion,
  ShowIconByQuestionIdUtil
} from '../../common/utils';
import { updateCustomerJourneyAnswer } from '../../store/actions';
import {
  COLOR,
  GERMAN_TEXT,
  INPUT_TYPE,
  PRODUCT_TYPE,
  QUESTION_ID,
  SPECIAL_CHARACTER,
  UNIT
} from '../../common/constants';
import {
  Input,
  StyledSpan,
  StyledSpanBold,
  Switch,
  InfoHelpDescription
} from '../../components/epilot-forms/common';
import { productsSelector } from '../../store/selectors';

class InputWithInfoQuestionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenTooltip: false,
      electricityOrGas: 'Electricity'
    };
  }

  componentDidMount() {
    const { question, customerJourneyAnswer, updateCustomerJourneyAnswer, products } = this.props;
    let newCustomerJourneyAnswer = {};
    let multipleChoiceItems;
    if (_.find(products, product => product.type === PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY)) {
      const housingElectricityMaxPower = Number(
        this.calculateRequiredOutput(
          question.objectModel.Electricity.items,
          customerJourneyAnswer.houseConnectionUnits
        )
      );

      if (customerJourneyAnswer.totalAdditionalOutput === 0) {
        const totalAdditionalOutput =
          Number(customerJourneyAnswer.totalAdditionalOutput) + Number(housingElectricityMaxPower);
        newCustomerJourneyAnswer = {
          housingElectricityMaxPower,
          totalAdditionalOutput,
          houseConnectionElectricityMaxPower: calculateHCElectricityMaxPower(totalAdditionalOutput)
        };
      }
    }

    if (_.find(products, product => product.type === PRODUCT_TYPE.HOUSE_CONNECTION_GAS)) {
      newCustomerJourneyAnswer.houseConnectionGasMaxLoad = Number(
        this.calculateRequiredOutput(
          question.objectModel.Gas.items,
          customerJourneyAnswer.houseConnectionUnits
        )
      );
    }

    multipleChoiceItems = _.find(
      [...customerJourneyAnswer.multipleChoiceItems],
      item => item.id === question.id
    );
    switch (question.id) {
      case QUESTION_ID.HOUSE_CONNECTION_UNITS:
        newCustomerJourneyAnswer.multipleChoiceItems = !multipleChoiceItems
          ? [
              ...customerJourneyAnswer.multipleChoiceItems,
              {
                id: question.id,
                label: question.questionLabel,
                value: customerJourneyAnswer.houseConnectionUnits.toString()
              }
            ]
          : [...customerJourneyAnswer.multipleChoiceItems];
        break;
      default:
        break;
    }

    updateCustomerJourneyAnswer(newCustomerJourneyAnswer);
  }

  onChange = value => {
    const { question, updateCustomerJourneyAnswer, products, customerJourneyAnswer } = this.props;
    let newCustomerJourneyAnswer = {};
    let houseConnectionUnits;
    let cloneMultipleChoiceItems;
    let multipleChoiceIndex;
    switch (question.id) {
      case QUESTION_ID.HOUSE_CONNECTION_UNITS:
        houseConnectionUnits = value;
        newCustomerJourneyAnswer.houseConnectionUnits = Number(houseConnectionUnits);
        if (
          _.find(products, product => product.type === PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY)
        ) {
          const housingElectricityMaxPower = this.calculateRequiredOutput(
            question.objectModel.Electricity.items,
            houseConnectionUnits
          );

          const totalAdditionalOutput =
            Number(customerJourneyAnswer.totalAdditionalOutput) +
            Number(housingElectricityMaxPower) -
            Number(customerJourneyAnswer.housingElectricityMaxPower);
          newCustomerJourneyAnswer = {
            ...newCustomerJourneyAnswer,
            housingElectricityMaxPower,
            totalAdditionalOutput,
            houseConnectionElectricityMaxPower: calculateHCElectricityMaxPower(
              totalAdditionalOutput
            )
          };
        }
        if (_.find(products, product => product.type === PRODUCT_TYPE.HOUSE_CONNECTION_GAS)) {
          newCustomerJourneyAnswer.houseConnectionGasMaxLoad = Number(
            this.calculateRequiredOutput(question.objectModel.Gas.items, houseConnectionUnits)
          );
        }

        cloneMultipleChoiceItems = [...customerJourneyAnswer.multipleChoiceItems];
        multipleChoiceIndex = _.findIndex(
          cloneMultipleChoiceItems,
          item => item.id === question.id
        );
        if (multipleChoiceIndex > -1) {
          cloneMultipleChoiceItems[multipleChoiceIndex].value = houseConnectionUnits;
          newCustomerJourneyAnswer.multipleChoiceItems = cloneMultipleChoiceItems;
        }
        break;
      default:
        break;
    }
    updateCustomerJourneyAnswer(newCustomerJourneyAnswer);
  };

  calculateRequiredOutput = (items, houseConnectionUnits) => {
    let requiredOutput = 0;
    _.forEach(items, item => {
      if (
        Number(houseConnectionUnits) >= Number(item.min) &&
        Number(houseConnectionUnits) <= Number(item.max)
      ) {
        requiredOutput =
          requiredOutput +
          (Number(houseConnectionUnits) - Number(item.min) + 1) * Number(item.value);
      }
      if (Number(houseConnectionUnits) > Number(item.max)) {
        requiredOutput =
          requiredOutput + (Number(item.max) - Number(item.min) + 1) * Number(item.value);
      }
      if (Number(houseConnectionUnits) > 100 && Number(item.max) === 100) {
        requiredOutput =
          requiredOutput + (Number(houseConnectionUnits) - Number(item.max)) * Number(item.value);
      }
    });
    return requiredOutput;
  };

  onChangeSwitch = e => {
    this.setState({ electricityOrGas: e });
  };

  showTooltip = () => {
    const { isOpenTooltip } = this.state;
    this.setState({ isOpenTooltip: !isOpenTooltip, electricityOrGas: 'Electricity' });
  };

  inputType = () => INPUT_TYPE.TEXT;

  inputValue = () => {
    const { customerJourneyAnswer, question } = this.props;
    switch (question.id) {
      case QUESTION_ID.HOUSE_CONNECTION_UNITS:
        return customerJourneyAnswer.houseConnectionUnits.toString();
      default:
        return SPECIAL_CHARACTER.EMPTY;
    }
  };

  info = () => {
    const { question, fontText, defaultTextColor } = this.props;

    switch (question.id) {
      case QUESTION_ID.HOUSE_CONNECTION_UNITS:
        return this.infoForHouseConnectionUnitsQuestion();
      default:
        return (
          <div>
            <StyledSpan font={fontText} style={{ color: defaultTextColor }}>
              No information available
            </StyledSpan>
          </div>
        );
    }
  };

  infoForHouseConnectionUnitsQuestion = () => {
    const { question, fontText, fontTextBold, defaultTextColor } = this.props;
    const { electricityOrGas } = this.state;

    const options = [
      {
        keyName: 'Electricity',
        valueName: 'Strom'
      },
      {
        keyName: 'Gas',
        valueName: 'Gas'
      }
    ];

    let selectedOption = {
      keyName: electricityOrGas
    };

    const textColor = {
      color: defaultTextColor
    };

    return (
      <div className="container">
        <div className="ep--mb-8">
          <Switch
            options={options}
            onChange={this.onChangeSwitch}
            fontText={fontText}
            fontTextBold={fontTextBold}
            selectedOption={selectedOption}
          />
        </div>
        <div className="row ep--mb-8" style={{ borderBottom: '1px solid #979797' }}>
          <div className="col-3">
            <StyledSpanBold
              font={fontTextBold}
              className="ep__txt-bold ep__txt--required ep__txt-no-select"
              style={textColor}
            >
              {question.objectModel[electricityOrGas].leftColumnHeader}
            </StyledSpanBold>
          </div>
          <div className="col-9">
            <StyledSpanBold
              font={fontTextBold}
              className="ep__txt-bold ep__txt--required ep__txt-no-select"
              style={textColor}
            >
              {question.objectModel[electricityOrGas].rightColumnHeader}
            </StyledSpanBold>
          </div>
        </div>
        {question.objectModel[electricityOrGas].items.map((item, key) => {
          return (
            <div className="row" key={key}>
              <div className="col-3">
                <StyledSpan
                  font={fontText}
                  className="ep__txt-regular ep__txt--required ep__txt-no-select"
                  style={textColor}
                >
                  {item.min === item.max
                    ? item.min
                    : item.min +
                      (Number(item.max) === 100
                        ? SPECIAL_CHARACTER.PLUS
                        : SPECIAL_CHARACTER.DASH + item.max)}
                </StyledSpan>
              </div>
              <div className="col-9">
                <StyledSpan
                  font={fontText}
                  className="ep__txt-regular ep__txt--required ep__txt-no-select"
                  style={textColor}
                >
                  {convertDataToComma(item.value) + SPECIAL_CHARACTER.SPACE + UNIT.KILOWALT}
                  {item.min === item.max ? SPECIAL_CHARACTER.EMPTY : `/${GERMAN_TEXT.UNIT}`}
                  {question.objectModel[electricityOrGas].items.indexOf(item) > 0
                    ? SPECIAL_CHARACTER.SPACE + GERMAN_TEXT.ADDL
                    : SPECIAL_CHARACTER.EMPTY}
                </StyledSpan>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const {
      question,
      fontText,
      frontendConfiguration,
      customerJourneyAnswer,
      defaultTextColor
    } = this.props;
    const { isOpenTooltip } = this.state;

    const textColor = {
      color: defaultTextColor
    };

    return (
      <div className="ep-customerjourneypage__question">
        <ShowIconByQuestionIdUtil
          questionId={question.id}
          colorIcon={frontendConfiguration.primaryColor}
        />

        <div className="text-center ep-min-height-70 ep--pt-8 ep--pb-16">
          <StyledSpan
            font={fontText}
            className="ep__txt-regular ep__txt--lg ep__txt-no-select"
            style={textColor}
          >
            {formatQuestion(question.questionTitle, customerJourneyAnswer.name)}
          </StyledSpan>
          <InfoHelpDescription
            showHelpDescription={question.showHelpDescription}
            tooltipInfoContent={<StyledSpan font={fontText}>{question.helpDescription}</StyledSpan>}
            classNameForInfoIcon="ep__txt--lg ep--ml-8"
            defaultTextColor={defaultTextColor}
          />
        </div>

        <div className="ep-min-height-130">
          <div className="d-inline-flex justify-content-center align-items-center ep--mt-8">
            <Input
              questionId={question.id}
              fontSize="14px"
              height="56px"
              width="80px"
              textAlign="center"
              borderRadius={frontendConfiguration.borderRadius}
              label=""
              type={this.inputType()}
              placeholder={question.questionLabel}
              require
              onChange={this.onChange}
              value={this.inputValue()}
              primaryTextColor={COLOR.DARK_GRAY}
              fontFamily={fontText}
              borderColorFocus={frontendConfiguration.primaryColor}
              defaultTextColor={defaultTextColor}
            />

            <Tooltip
              isOpen={isOpenTooltip}
              content={this.info()}
              position={Position.RIGHT}
              tooltipClassName="ep-tooltip"
            >
              <i
                className="d-inline-block ep-icon-alert-circle-i ep__txt--ic ep--ml-8 ep-cursor-pointer"
                style={{ outline: 'none', color: defaultTextColor }}
                onClick={this.showTooltip}
              />
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }
}

InputWithInfoQuestionContainer.propTypes = {
  question: PropTypes.object,
  fontText: PropTypes.object,
  fontTextBold: PropTypes.object,
  defaultTextColor: PropTypes.string,
  customerJourneyAnswer: PropTypes.object,
  frontendConfiguration: PropTypes.object,
  products: PropTypes.array,
  updateCustomerJourneyAnswer: PropTypes.func
};

InputWithInfoQuestionContainer.defaultProps = {
  question: {},
  products: [],
  fontText: {},
  fontTextBold: {},
  defaultTextColor: COLOR.DARK_GRAY,
  customerJourneyAnswer: {},
  frontendConfiguration: {},
  updateCustomerJourneyAnswer: () => ({})
};

function mapStateToProps(state) {
  return {
    frontendConfiguration: state.frontendConfiguration,
    customerJourneyAnswer: state.customerJourneyAnswer,
    customerJourneyMapping: state.customerJourneyMapping,
    products: productsSelector(state)
  };
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateCustomerJourneyAnswer
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputWithInfoQuestionContainer);

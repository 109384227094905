export const PACKAGE_PRICING_COMPONENT_TYPE = {
  STATIC_COMPONENT: 'STATIC_COMPONENT',
  METER_COMPONENT: 'METER_COMPONENT',
  KILOWATT_COMPONENT: 'KILOWATT_COMPONENT',
  HEATING_CAPACITY_COMPONENT: 'HEATING_CAPACITY_COMPONENT',
  KILOWATT_HOUR_COMPONENT: 'KILOWATT_HOUR_COMPONENT',
  LITER_PER_SECOND_COMPONENT: 'LITER_PER_SECOND_COMPONENT',
  STREET_FRONT_LENGTH: 'STREET_FRONT_LENGTH',
  HOUSING_UNITS: 'HOUSING_UNITS',
  PROPERTY_SIZE: 'PROPERTY_SIZE',
  FLOOR_SIZE: 'FLOOR_SIZE'
};

export const PRICING_COMP_RANGE_TYPE = {
  NO_RANGE: 'NO_RANGE',
  TO_INFINITY: 'TO_INFINITY',
  SPECIFIC_RANGE: 'SPECIFIC_RANGE'
};

import { CALL_API } from '../middlewares/customMiddleware';
import { requestParamExternalServiceSelector } from '../selectors';
import { API_URL_VO } from '../../common/settings/ApiUrlSettings';

export const EXTERNAL_PRODUCT_AVAILABILITY_REQUEST = 'EXTERNAL_PRODUCT_AVAILABILITY_REQUEST';
export const EXTERNAL_PRODUCT_AVAILABILITY_SUCCESS = 'EXTERNAL_PRODUCT_AVAILABILITY_SUCCESS';
export const EXTERNAL_PRODUCT_AVAILABILITY_FAILURE = 'EXTERNAL_PRODUCT_AVAILABILITY_FAILURE';
export const RESET_EXTERNAL_PRODUCT_AVAILABILITY = 'RESET_EXTERNAL_PRODUCT_AVAILABILITY';
export const EXTERNAL_PRODUCT_AVAILABILITY_URL = process.env.EXTERNAL_PRODUCT_AVAILABILITY_URL
  ? process.env.EXTERNAL_PRODUCT_AVAILABILITY_URL
  : 'https://forwarder.sls.epilot.io/v1/forwarder';

export const checkProductAvailableExternalService = () => (dispatch, getStore) => {
  const checkProductAvailabilityUrl = `${EXTERNAL_PRODUCT_AVAILABILITY_URL}`;
  let params = requestParamExternalServiceSelector(getStore());

  return dispatch({
    [CALL_API]: {
      types: [
        EXTERNAL_PRODUCT_AVAILABILITY_REQUEST,
        EXTERNAL_PRODUCT_AVAILABILITY_SUCCESS,
        EXTERNAL_PRODUCT_AVAILABILITY_FAILURE
      ],
      endpoint: checkProductAvailabilityUrl,
      method: 'POST',
      params: params,
      isNodeServerAPI: true
    }
  });
};

export const resetProductAvailability = () => ({
  type: RESET_EXTERNAL_PRODUCT_AVAILABILITY
});

export const INTERNAL_PRODUCT_AVAILABILITY_REQUEST = 'INTERNAL_PRODUCT_AVAILABILITY_REQUEST';
export const INTERNAL_PRODUCT_AVAILABILITY_SUCCESS = 'INTERNAL_PRODUCT_AVAILABILITY_SUCCESS';
export const INTERNAL_PRODUCT_AVAILABILITY_FAILURE = 'INTERNAL_PRODUCT_AVAILABILITY_FAILURE';
export const INTERNAL_PRODUCT_AVAILABILITY_URL = '/postal-code-mapping';

export const checkProductAvailableInternalService = params => dispatch => {
  const internalPAServiceUrl = `${API_URL_VO}${INTERNAL_PRODUCT_AVAILABILITY_URL}`;
  return dispatch({
    [CALL_API]: {
      types: [
        INTERNAL_PRODUCT_AVAILABILITY_REQUEST,
        INTERNAL_PRODUCT_AVAILABILITY_SUCCESS,
        INTERNAL_PRODUCT_AVAILABILITY_FAILURE
      ],
      endpoint: internalPAServiceUrl,
      method: 'POST',
      params: params
    }
  });
};

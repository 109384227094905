import React from 'react';
import Dropzone from 'react-dropzone';
import _ from 'lodash';
import * as PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';
import { COLOR, SPECIAL_CHARACTER } from '../constants';
import { StyledLabel, StyledSpan } from '../../components';
import { ShowIfUtil } from './ShowIfUtil';

export const DropZoneFileV2 = ({
  isUploading,
  uploadInstruction,
  label,
  fontText,
  primaryTextColor,
  defaultTextColor,
  required,
  linkColor,
  acceptFileType,
  isMultipleUpload,
  uploadedFileInfo,
  onDrop,
  invalidFileNames,
  invalidSizeFileNames,
  invalidTypeFileNames,
  failedUploadedFileNames,
  removeUploadedFile
}) => {
  const textColor = {
    color: defaultTextColor
  };

  const errorStyle = {
    color: COLOR.ERROR_TEXT_COLOR,
    textAlign: 'center'
  };

  const renderUploadedFiles = () => {
    return _.map(uploadedFileInfo, (fileInfo, index) => (
      <div
        className="d-flex justify-content-between align-items-center ep--mt-8 ep--mb-8"
        key={index}
      >
        <div className="d-flex align-items-center">
          <i className="ep-icon-attach-87" style={{ color: defaultTextColor }} />
          <StyledSpan
            className="ep__txt--md ep__txt-semibold ep__txt-no-select ep--ml-8"
            style={{ color: linkColor }}
            font={fontText}
          >
            {fileInfo.name}
          </StyledSpan>
        </div>
        <ShowIfUtil condition>
          <i
            className="ep-icon-trash-simple ep-cursor-pointer float-right"
            onClick={() => removeUploadedFile(fileInfo)}
            style={{ color: defaultTextColor }}
          />
        </ShowIfUtil>
      </div>
    ));
  };

  return (
    <>
      <ShowIfUtil condition={label !== SPECIAL_CHARACTER.EMPTY}>
        <StyledLabel
          className="ep__txt--sm ep__txt-regular ep__txt-no-select"
          font={fontText}
          style={{ color: primaryTextColor }}
        >
          {label}
          <ShowIfUtil condition={required}>
            <StyledSpan
              className="ep__txt--sm ep__txt-regular ep__txt-no-select"
              font={fontText}
              style={{ color: primaryTextColor }}
            >
              {' *'}
            </StyledSpan>
          </ShowIfUtil>
        </StyledLabel>
      </ShowIfUtil>
      <Dropzone
        multiple={isMultipleUpload}
        accept={acceptFileType}
        onDrop={onDrop}
        style={{ background: COLOR.WHITE }}
        disabled={isUploading}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className="ep-cursor-pointer row justify-content-center align-items-center ep-dropzone ep--ml-0"
          >
            <input {...getInputProps()} />
            <i className="ep-icon-cloud-upload-94 p-8" style={textColor} />
            <StyledSpan className="ep__txt--sm ml-2" font={fontText} style={textColor}>
              {uploadInstruction}
            </StyledSpan>
          </div>
        )}
      </Dropzone>
      {isUploading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20
          }}
        >
          <ClipLoader
            color={defaultTextColor || '#000'}
            loading
            size={25}
            css={{
              marginLeft: 10
            }}
          />
        </div>
      ) : (
        <>
          <div className="ep--mt-16">{renderUploadedFiles()}</div>
          <ShowIfUtil condition={failedUploadedFileNames?.length > 0}>
            {_.map(failedUploadedFileNames, value => (
              <div className="text-center" key={value}>
                <StyledSpan
                  className="ep__txt--md ep__txt--required ep__txt-regular ep__txt-no-select"
                  font={fontText}
                  style={errorStyle}
                >
                  {`${value} Hochladen fehlgeschlagen`}
                </StyledSpan>
              </div>
            ))}
          </ShowIfUtil>
          <ShowIfUtil condition={invalidFileNames?.length > 0}>
            {_.map(invalidFileNames, value => (
              <div className="text-center" key={value}>
                <StyledSpan
                  className="ep__txt--md ep__txt--required ep__txt-regular ep__txt-no-select"
                  font={fontText}
                  style={errorStyle}
                >
                  {`${value} ist bereits vorhanden`}
                </StyledSpan>
              </div>
            ))}
          </ShowIfUtil>
          <ShowIfUtil condition={invalidSizeFileNames?.length > 0}>
            {_.map(invalidSizeFileNames, value => (
              <div className="text-center" key={value}>
                <StyledSpan
                  className="ep__txt--md ep__txt--required ep__txt-regular ep__txt-no-select"
                  font={fontText}
                  style={errorStyle}
                >
                  {`${value} hat eine ungültige Dateigröße, Dateigröße sollte kleiner als 10MB sein`}
                </StyledSpan>
              </div>
            ))}
          </ShowIfUtil>
          <ShowIfUtil condition={invalidTypeFileNames?.length > 0}>
            {_.map(invalidTypeFileNames, value => (
              <div className="text-center" key={value}>
                <StyledSpan
                  className="ep__txt--md ep__txt--required ep__txt-regular ep__txt-no-select"
                  font={fontText}
                  style={errorStyle}
                >
                  {`${value} ist ein ungültiger Dateityp`}
                </StyledSpan>
              </div>
            ))}
          </ShowIfUtil>
        </>
      )}
    </>
  );
};

DropZoneFileV2.propTypes = {
  uploadInstruction: PropTypes.string,
  isUploading: PropTypes.bool.isRequired,
  isMultipleUpload: PropTypes.bool,
  removeUploadedFile: PropTypes.func.isRequired,
  primaryTextColor: PropTypes.string,
  defaultTextColor: PropTypes.string,
  linkColor: PropTypes.string,
  label: PropTypes.string,
  fontText: PropTypes.object,
  required: PropTypes.bool,
  acceptFileType: PropTypes.string.isRequired,
  onDrop: PropTypes.func.isRequired,
  uploadedFileInfo: PropTypes.array.isRequired,
  invalidFileNames: PropTypes.array.isRequired,
  invalidSizeFileNames: PropTypes.array.isRequired,
  invalidTypeFileNames: PropTypes.array.isRequired,
  failedUploadedFileNames: PropTypes.array.isRequired
};

DropZoneFileV2.defaultProps = {
  uploadInstruction: '',
  primaryTextColor: COLOR.DARK_GRAY,
  defaultTextColor: COLOR.DARK_GRAY,
  linkColor: COLOR.DEFAULT_BLUE,
  label: SPECIAL_CHARACTER.EMPTY,
  fontText: {},
  required: false,
  isMultipleUpload: true
};

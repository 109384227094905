export const GERMAN_TEXT = {
  ADD_ONS: 'Zusatzoptionen',
  OTHER_ADD_ONS: 'Andere Zusatzoptionen',
  ADDL: 'zusätzlich',
  ADDRESS_COMMENT_ANSWER: 'Adresse wurde manuell durch den Endkunden eingegeben!',
  CHARGING_STATION: 'Ladeeinrichtung',
  FOR: 'für',
  HOUSING_UNITS: 'Wohneinheiten',
  MONTH: 'Monat',
  MONTHLY: 'monatlich',
  ON_REQUEST: 'Auf Anfrage',
  ONE_TIME_PAYMENT: 'Einmalige Zahlung',
  PACKAGE_COMPONENTS: 'Paket-Komponenten',
  RECURRING_PAYMENT: 'Wiederkehrende Zahlung',
  SELECT_A_PACKAGE: 'Wählen Sie ein Paket',
  SELECT_SALUTATION: 'Anrede auswählen',
  TOTAL_AMOUNT_INCL_TAX: 'Gesamtbetrag inkl. MwSt.',
  TOTAL_MONTHLY_AMOUNT: 'Ges. monatlicher Betrag',
  UNIT: 'Einheit',
  VIEW_COMPONENTS: 'Details ansehen',
  YEAR: 'Jahr',
  YEARLY: 'Jahre'
};

import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import { Position, Tooltip } from '@blueprintjs/core';
import { StyledLabel, StyledSpan } from './StyledElement';
import {
  COLOR,
  QUESTION_ID,
  INPUT_VALUE,
  MESSAGE_CONSTANTS,
  SPECIAL_CHARACTER,
  CONTACT_FORM_KEY
} from '../../../common/constants';
import { ShowIfUtil, convertDataToDot, convertDataToComma } from '../../../common/utils';

const MAXIMUM_NUMBER_SPLIT = 3;

const InputFont = styled.input`
  @font-face {
    font-family: 'custom';
    src: url(${props => props.font.fontUrl});
  }

  border: 1px solid ${props => (props.isStreetNotFound ? COLOR.RED : COLOR.LIGHT_GRAY_1)} !important;
  font-family: ${props => props.font.font} !important;

  &:focus {
    border-color: ${props =>
      props.isStreetNotFound ? COLOR.RED : props.borderColorFocus} !important;
  }
`;

class Input extends Component {
  constructor(props) {
    super(props);
    const { value } = this.props;
    this.state = {
      value: this.checkQuestionIdToConvertValue(value),
      isValid: true,
      isOpenTooltip: false
    };
  }

  componentWillReceiveProps(nextProps) {
    const { value } = this.props;
    if (nextProps.value !== value) {
      this.setState({ value: this.checkQuestionIdToConvertValue(nextProps.value) });
    }

    if (nextProps.require && typeof nextProps.value === 'string' && nextProps.value.trim()) {
      this.setState({ isValid: true });
    }
  }

  checkQuestionIdToConvertValue = value => {
    const { questionId } = this.props;
    switch (questionId) {
      case QUESTION_ID.HOUSE_CONNECTION_REQUIRED_VALUES:
      case QUESTION_ID.OUTPUT_OF_HEATING:
      case QUESTION_ID.HOUSE_CONNECTION_APARTMENT_BUILDING:
      case QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_MAX_POWER:
      case QUESTION_ID.HOUSE_CONNECTION_GAS_MAX_LOAD:
      case QUESTION_ID.HOUSE_CONNECTION_WATER_MAX_FLOW:
      case QUESTION_ID.HOUSE_CONNECTION_TRENCH_LENGTH:
      case QUESTION_ID.HOUSE_CONNECTION_E_MOBILITY_REQUIREMENT:
      case QUESTION_ID.HOUSE_CONNECTION_OUTLET_PRESSURE_REQUIRED:
      case QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_ADDITIONAL:
      case QUESTION_ID.HOUSE_CONNECTION_EXTINGUISHING_WATER_DEMAND:
      case QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_FEED_INTO_GRID:
      case QUESTION_ID.HOUSE_CONNECTION_PEAK_WATER:
      case QUESTION_ID.HOUSE_CONNECTION_WATER_STREET_FRONT_LENGTH:
      case QUESTION_ID.HOUSE_CONNECTION_WATER_PROPERTY_SIZE:
      case QUESTION_ID.HOUSE_CONNECTION_WATER_FLOOR_SPACE:
        return convertDataToComma(value);
      default:
        return value;
    }
  };

  onChange = (e, questionId) => {
    const { onChange } = this.props;
    let regex;
    switch (questionId) {
      case QUESTION_ID.HOUSE_CONNECTION_UNITS:
        regex = /^[0-9]+$/;
        this.updateOnChangeValue(regex, e.target.value);
        break;
      case QUESTION_ID.HOUSE_CONNECTION_REQUIRED_VALUES:
      case QUESTION_ID.OUTPUT_OF_HEATING:
      case QUESTION_ID.HOUSE_CONNECTION_APARTMENT_BUILDING:
      case QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_MAX_POWER:
      case QUESTION_ID.HOUSE_CONNECTION_GAS_MAX_LOAD:
      case QUESTION_ID.HOUSE_CONNECTION_WATER_MAX_FLOW:
      case QUESTION_ID.HOUSE_CONNECTION_TRENCH_LENGTH:
      case QUESTION_ID.HOUSE_CONNECTION_E_MOBILITY_REQUIREMENT:
      case QUESTION_ID.HOUSE_CONNECTION_OUTLET_PRESSURE_REQUIRED:
      case QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_ADDITIONAL:
      case QUESTION_ID.HOUSE_CONNECTION_EXTINGUISHING_WATER_DEMAND:
      case QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_FEED_INTO_GRID:
      case QUESTION_ID.HOUSE_CONNECTION_PEAK_WATER:
      case QUESTION_ID.HOUSE_CONNECTION_WATER_STREET_FRONT_LENGTH:
      case QUESTION_ID.HOUSE_CONNECTION_WATER_PROPERTY_SIZE:
      case QUESTION_ID.HOUSE_CONNECTION_WATER_FLOOR_SPACE:
        regex = /^[0-9,.]+$/;
        this.updateOnChangeValue(regex, e.target.value);
        break;
      case QUESTION_ID.ADDRESS_DETAILS:
        if (e.target.name !== CONTACT_FORM_KEY.BLOCK && e.target.name !== CONTACT_FORM_KEY.LOT) {
          this.setState({ value: e.target.value });
          onChange(e.target.value);
          break;
        } else {
          const val =
            e.target.name === CONTACT_FORM_KEY.LOT
              ? e.target.value.replace(/[^0-9|/]/g, '')
              : e.target.value.replace(/[^0-9]/g, '');
          this.setState({ value: val });
          onChange(val);
          break;
        }
      default:
        this.setState({ value: e.target.value });
        onChange(e.target.value);
        break;
    }
  };

  updateOnChangeValue = (regex, value) => {
    const { onChange } = this.props;
    if (
      this.correctValueBySeparator(value, SPECIAL_CHARACTER.COMMA) &&
      this.correctValueBySeparator(value, SPECIAL_CHARACTER.DOT) &&
      this.checkExistenceCommaAndDot(value) &&
      regex.test(value)
    ) {
      this.setState({ value });
      onChange(convertDataToDot(value));
    } else {
      if (!value) {
        this.setState({ value: INPUT_VALUE.ZERO });
        onChange(INPUT_VALUE.ZERO);
      }
    }
  };

  correctValueBySeparator = (value, separator) => {
    const convertValueToArray = value && value.split(separator);
    return (
      value !== separator &&
      convertValueToArray &&
      convertValueToArray.length < MAXIMUM_NUMBER_SPLIT
    );
  };

  checkExistenceCommaAndDot = value => {
    return !(value.includes(SPECIAL_CHARACTER.COMMA) && value.includes(SPECIAL_CHARACTER.DOT));
  };

  onBlur = e => {
    const { onBlur } = this.props;
    onBlur(e.target.value);
  };

  handleFocus = e => {
    e.target.select();
  };

  toggleTooltip = () => {
    const { isOpenTooltip } = this.state;
    this.setState({ isOpenTooltip: !isOpenTooltip });
  };

  render() {
    const {
      minHeight,
      classStyle,
      maxWidth,
      label,
      fontFamily,
      type,
      name,
      disabled,
      placeholder,
      maxLength,
      borderColorFocus,
      borderRadius,
      fontSize,
      width,
      minWidth,
      height,
      textAlign,
      paddingRight,
      customClassStyle,
      unit,
      require,
      fontWeight,
      questionId,
      tooltipInfoContent,
      defaultTextColor,
      isStreetNotFound
    } = this.props;
    const { value, isValid, isOpenTooltip } = this.state;
    return (
      <div style={{ minHeight: minHeight }}>
        <div
          className={`position-relative ${classStyle}`}
          style={{ maxWidth: maxWidth, margin: '0 auto' }}
        >
          {/*----- INPUT LABEL -----*/}
          <ShowIfUtil condition={label !== SPECIAL_CHARACTER.EMPTY}>
            <StyledLabel
              className="ep__txt--sm ep__txt-regular ep__txt-no-select"
              font={fontFamily}
              style={{ color: defaultTextColor }}
            >
              {label}
              <ShowIfUtil condition={require}>
                <StyledSpan
                  className="ep__txt--sm ep__txt-regular ep__txt-no-select"
                  font={fontFamily}
                  style={{ color: defaultTextColor }}
                >
                  {' *'}
                </StyledSpan>
              </ShowIfUtil>
            </StyledLabel>
          </ShowIfUtil>

          {/*----- INPUT -----*/}
          <InputFont
            isStreetNotFound={isStreetNotFound}
            font={fontFamily}
            className={`ep-input ${customClassStyle}`}
            type={type}
            name={name}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            maxLength={maxLength}
            onChange={e => this.onChange(e, questionId)}
            onFocus={this.handleFocus}
            onBlur={e => this.onBlur(e)}
            min={0}
            borderColorFocus={borderColorFocus}
            style={{
              borderRadius: borderRadius,
              fontSize: fontSize,
              width: width,
              maxWidth: maxWidth,
              minWidth: minWidth,
              height: height,
              textAlign: textAlign,
              fontWeight: fontWeight,
              paddingRight: paddingRight,
              color: defaultTextColor
            }}
          />
          <ShowIfUtil condition={unit !== SPECIAL_CHARACTER}>
            <StyledSpan
              className="ep__txt-no-select"
              font={fontFamily}
              style={{
                position: 'absolute',
                top: 'calc(50% + -10px)',
                right: '15px',
                color: defaultTextColor,
                fontSize: fontSize,
                fontWeight: fontWeight
              }}
              dangerouslySetInnerHTML={{ __html: unit }}
            />
          </ShowIfUtil>
          <ShowIfUtil condition={!_.isEmpty(tooltipInfoContent)}>
            <div
              style={{
                position: 'absolute',
                top: 'calc(50% + -10px)',
                right: '15px',
                color: defaultTextColor
              }}
            >
              <Tooltip
                isOpen={isOpenTooltip}
                content={tooltipInfoContent}
                position={Position.BOTTOM}
                tooltipClassName="ep-tooltip"
              >
                <i
                  className="ep-icon-alert-circle-i ep__txt--lg ep-cursor-pointer"
                  style={{ outline: 'none', color: defaultTextColor }}
                  onClick={this.toggleTooltip}
                />
              </Tooltip>
            </div>
          </ShowIfUtil>
          {/*----- ERROR MESSAGE -----*/}
          <ShowIfUtil condition={!isValid && value.trim() === SPECIAL_CHARACTER.EMPTY}>
            <div className="">{MESSAGE_CONSTANTS.REQUIRED}</div>
          </ShowIfUtil>
        </div>
      </div>
    );
  }
}

Input.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  require: PropTypes.bool,
  disabled: PropTypes.bool,
  classStyle: PropTypes.string,
  borderRadius: PropTypes.string,
  fontFamily: PropTypes.object,
  fontSize: PropTypes.string,
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  height: PropTypes.string,
  minHeight: PropTypes.string,
  minWidth: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  textAlign: PropTypes.string,
  borderColorFocus: PropTypes.string,
  defaultTextColor: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unit: PropTypes.string,
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paddingRight: PropTypes.string,
  customClassStyle: PropTypes.string,
  questionId: PropTypes.number,
  tooltipInfoContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.array
  ]),
  isStreetNotFound: PropTypes.bool
};

Input.defaultProps = {
  disabled: false,
  borderColorFocus: '',
  minWidth: '0',
  type: 'text',
  label: SPECIAL_CHARACTER.EMPTY,
  placeholder: null,
  maxLength: 255,
  name: SPECIAL_CHARACTER.EMPTY,
  require: false,
  classStyle: SPECIAL_CHARACTER.EMPTY,
  borderRadius: SPECIAL_CHARACTER.EMPTY,
  fontFamily: {},
  fontSize: '14px',
  maxWidth: '',
  minHeight: '',
  width: '100%',
  height: '32px',
  textAlign: 'left',
  defaultTextColor: COLOR.DARK_GRAY,
  onChange: () => ({}),
  onBlur: () => ({}),
  value: SPECIAL_CHARACTER.EMPTY,
  unit: SPECIAL_CHARACTER.EMPTY,
  fontWeight: 500,
  paddingRight: '',
  customClassStyle: '',
  questionId: 0,
  tooltipInfoContent: '',
  isStreetNotFound: false
};

export default Input;

export const UPDATE_CONTACT_FORM = 'UPDATE_CONTACT_FORM';
export const RESET_CONTACT_FORM = 'RESET_CONTACT_FORM';

export const updateContactForm = data => ({
  type: UPDATE_CONTACT_FORM,
  payload: data
});

export const UPDATE_UPLOADED_RELATED_DOCUMENTS = 'UPDATE_UPLOADED_RELATED_DOCUMENTS';
export const updateUploadedRelatedDocuments = data => ({
  type: UPDATE_UPLOADED_RELATED_DOCUMENTS,
  payload: data
});

export const UPDATE_UPLOADED_TAX_DOCUMENTS = 'UPDATE_UPLOADED_TAX_DOCUMENTS';
export const updateUploadedTaxDocuments = data => ({
  type: UPDATE_UPLOADED_TAX_DOCUMENTS,
  payload: data
});

export const REMOVE_UPLOADED_RELATED_DOCUMENT = 'REMOVE_UPLOADED_RELATED_DOCUMENT';
export const removeUploadedRelatedDocument = data => ({
  type: REMOVE_UPLOADED_RELATED_DOCUMENT,
  payload: data
});

export const REMOVE_UPLOADED_TAX_DOCUMENT = 'REMOVE_UPLOADED_TAX_DOCUMENT';
export const removeUploadedTaxDocument = data => ({
  type: REMOVE_UPLOADED_TAX_DOCUMENT,
  payload: data
});

export const resetContactForm = () => ({
  type: RESET_CONTACT_FORM
});

import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import {
  CHARGE_CAR_SPEED,
  CHARGE_SPEED,
  CHARGE_TYPE,
  CHARGE_TYPE_LANGUAGE,
  CHP_TYPE,
  COLOR,
  FRONTEND_TYPE,
  HOUSE_CONNECTION_NEW_OR_CHANGE,
  LIMIT_HEIGHT,
  PACKAGE_PREMIUM_TYPE_KEY,
  PACKAGE_SELECTED_KEY,
  PACKAGE_TYPE,
  PAYMENT_TYPE,
  POWER_FLAT_RATE,
  PRODUCT_AVAILABLE_STATUS,
  PRODUCT_SELECTED_KEY,
  PRODUCT_TYPE,
  QUESTION_ID,
  RENTAL_UNIT,
  SPECIAL_CHARACTER,
  STATUS,
  USAGE_OF_ELECTRIC_CAR
} from '../../common/constants';
import { Product, ProductPackage, PurchaseRentalSelection, TemporaryTotal } from '.';
import {
  calcTotalOutputHeatingByBuiltYearAndLivingSpace,
  hexaToRGBA,
  removeAddon,
  removeInvalidAddonsWhenChangePackageV1,
  ScrollUtil,
  selectProductForSingleHouseConnectionCJ,
  ShowIfUtil
} from '../../common/utils';
import {
  updateCustomerJourneyMapping,
  updateFrontendConfiguration,
  updateProductSelections
} from '../../store/actions';
import { StyledButtonBold, StyledSpan } from '../../components';
import { ContactForm } from '../contact-form';
import {
  productSelectionsSelector,
  productsSelector,
  selectedProductsSelector
} from '../../store/selectors';

class WebshopContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isShowProductPackage: false,
      isShowContactForm: false,
      selectedProduct: {},
      productsWrapperHeight: LIMIT_HEIGHT.DEFAULT_PRODUCT_WRAPPER
    };
  }

  componentDidMount() {
    const { frontendConfiguration, productSelections } = this.props;
    if (productSelections.preselectProductsAndPackages) {
      if (frontendConfiguration.frontendType === FRONTEND_TYPE.WEBSHOP) {
        this.preSelectProductForWebshop();
        this.preSelectRentalInfo();
      }
      if (frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY) {
        this.preSelectProductForCustomerJourney();
        this.preSelectRentalInfo();
      }
      this.setPaymentType();
      this.selectCheapestPackages();
    }

    this.setScrollHeight();
    this.handleWindowResize();
  }

  componentWillReceiveProps(nextProps) {
    const {
      customerJourneyAnswer,
      customerJourneyMapping,
      products,
      productSelections
    } = this.props;

    if (!_.isEqual(nextProps.productSelections, productSelections)) {
      this.unselectUnavailableProduct(products);
    }

    if (!_.isEqual(nextProps.customerJourneyMapping, customerJourneyMapping)) {
      let { packageMappings } = nextProps.frontendConfiguration;

      if (!packageMappings) {
        return;
      }

      if (packageMappings.hasOwnProperty(PRODUCT_TYPE.SOLAR)) {
        this.solarMapping(packageMappings, nextProps);
      }

      if (packageMappings.hasOwnProperty(PRODUCT_TYPE.STORAGE)) {
        this.storageMapping(packageMappings, nextProps);
      }

      if (packageMappings.hasOwnProperty(PRODUCT_TYPE.CHARGE)) {
        this.chargeMapping(packageMappings, nextProps);
      }

      if (packageMappings.hasOwnProperty(PRODUCT_TYPE.POWER)) {
        this.powerMapping(packageMappings, nextProps);
      }

      if (packageMappings.hasOwnProperty(PRODUCT_TYPE.CHP)) {
        this.chpMapping(packageMappings, nextProps);
      }

      if (packageMappings.hasOwnProperty(PRODUCT_TYPE.SOLAR_B2B)) {
        this.solarB2BMapping(packageMappings, nextProps);
      }

      if (packageMappings.hasOwnProperty(PRODUCT_TYPE.CHARGE_B2B)) {
        this.chargeB2BMapping(packageMappings, nextProps);
      }
    }

    if (!_.isEqual(nextProps.customerJourneyAnswer, customerJourneyAnswer)) {
      let { packageMappings } = nextProps.frontendConfiguration;

      if (!packageMappings) {
        return;
      }

      if (packageMappings.hasOwnProperty(PRODUCT_TYPE.CHARGE)) {
        this.chargeMapping(packageMappings, nextProps);
      }

      if (packageMappings.hasOwnProperty(PRODUCT_TYPE.HEATING)) {
        this.heatingMapping(packageMappings, nextProps);
      }

      if (packageMappings.hasOwnProperty(PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY)) {
        this.houseConnectionForElectricityMapping(packageMappings, nextProps);
      }

      if (packageMappings.hasOwnProperty(PRODUCT_TYPE.HOUSE_CONNECTION_GAS)) {
        this.houseConnectionForGasMapping(packageMappings, nextProps);
      }

      if (packageMappings.hasOwnProperty(PRODUCT_TYPE.HOUSE_CONNECTION_WATER)) {
        this.houseConnectionForWaterMapping(packageMappings, nextProps);
      }

      if (packageMappings.hasOwnProperty(PRODUCT_TYPE.HOUSE_CONNECTION_HEAT)) {
        this.houseConnectionForHeatingMapping(packageMappings, nextProps);
      }

      if (packageMappings.hasOwnProperty(PRODUCT_TYPE.HOUSE_CONNECTION_BROADBAND)) {
        this.houseConnectionForBroadbandMapping(packageMappings, nextProps);
      }

      if (packageMappings.hasOwnProperty(PRODUCT_TYPE.CHARGE_B2B)) {
        this.chargeB2BMapping(packageMappings, nextProps);
      }
    }

    if (nextProps.products !== products) {
      this.unselectUnavailableProduct(nextProps.products);
    }
  }

  componentDidUpdate(prevProps) {
    const { currQuestions: prevCurrQuestions, productSelections: prevProductSelection } = prevProps;
    const { currQuestions, productSelections } = this.props;

    if (currQuestions !== prevCurrQuestions) {
      if (
        currQuestions &&
        currQuestions.length >= 3 &&
        currQuestions[2].id === QUESTION_ID.AUTARKY &&
        Number(currQuestions[2].objectModel.value) === 0
      ) {
        this.selectStorageProduct();
      }
    }

    if (prevProductSelection.selectedPurchaseRental !== productSelections.selectedPurchaseRental) {
      this.selectCheapestPackages();
    }
  }

  setScrollHeight = addonClientHeight => {
    const { productsWrapperHeight } = this.props;
    let productsWrapperByAddonClientHeight = null;

    if (this.productScrollBar) {
      if (!addonClientHeight) {
        productsWrapperByAddonClientHeight = this.productScrollBar.clientHeight;
      } else {
        productsWrapperByAddonClientHeight = this.productScrollBar.clientHeight + addonClientHeight;
      }
      this.setState({
        productsWrapperHeight:
          productsWrapperHeight === SPECIAL_CHARACTER.EMPTY
            ? productsWrapperByAddonClientHeight + 5
            : productsWrapperHeight + 5
      });
    }
  };

  setPaymentType = () => {
    const { frontendConfiguration, updateProductSelections } = this.props;
    const { defaultSellingOption, listRentalInfo } = frontendConfiguration;
    let newProductSelections = {};

    if (
      frontendConfiguration.listRentalInfo.length === 0 &&
      frontendConfiguration.isOneTimePayOff
    ) {
      newProductSelections.selectedPurchaseRental = PAYMENT_TYPE.PURCHASE;
    }

    if (frontendConfiguration.listRentalInfo.length > 0 && !frontendConfiguration.isOneTimePayOff) {
      newProductSelections.selectedPurchaseRental = PAYMENT_TYPE.RENTAL;
    }

    if (frontendConfiguration.listRentalInfo.length > 0 && frontendConfiguration.isOneTimePayOff) {
      newProductSelections.selectedPurchaseRental = PAYMENT_TYPE.PURCHASE;
    }

    if (defaultSellingOption) {
      const rentalInfo = _.find(listRentalInfo, {
        unit: defaultSellingOption.unit,
        duration: defaultSellingOption.duration
      });
      if (rentalInfo) {
        newProductSelections.selectedPurchaseRental = PAYMENT_TYPE.RENTAL;
        newProductSelections.selectedRentalInfo = rentalInfo;
      }
    }
    !_.isEmpty(newProductSelections) && updateProductSelections(newProductSelections);
  };

  getQuestionIndex(questionId) {
    const { currQuestions } = this.props;
    let questionIndex = undefined;

    _.forEach(currQuestions, (question, index) => {
      if (question.id === questionId) {
        questionIndex = index;
      }
    });
    return questionIndex;
  }

  showProductPackages = product => {
    this.setState({
      isShowProductPackage: true,
      selectedProduct: product
    });
  };

  showProductComponent = () => {
    this.setState({ isShowProductPackage: false });
  };

  backFromContactForm = () => {
    this.setState({ isShowContactForm: false });
  };

  selectProduct = (isSelected, product, available) => {
    let { productSelections, updateProductSelections } = this.props;
    let cloneProductSelections = _.assign({}, productSelections);
    let newProductSelections = {
      selectedAddonIds: productSelections.selectedAddonIds
    };

    if (available) {
      newProductSelections[PRODUCT_SELECTED_KEY[product.type]] = isSelected;

      if (product.type === PRODUCT_TYPE.STORAGE && isSelected) {
        newProductSelections.isManualSelectedStorageProduct = true;
      }

      if (product.type === PRODUCT_TYPE.CHARGE && isSelected) {
        newProductSelections.isManualSelectedChargeProduct = true;
      }

      if (product.type === PRODUCT_TYPE.POWER && isSelected) {
        newProductSelections.isManualSelectedPowerProduct = true;
      }

      if (product.type === PRODUCT_TYPE.GAS && isSelected) {
        newProductSelections.isManualSelectedGasProduct = true;
      }

      if (product.type === PRODUCT_TYPE.CHARGE_B2B && isSelected) {
        newProductSelections.isManualSelectedChargeB2BProduct = true;
      }
    }

    if (!isSelected) {
      newProductSelections.selectedAddonIds = removeAddon(product, cloneProductSelections);
    }
    updateProductSelections(newProductSelections);
  };

  showContactForm = () => {
    this.setState({ isShowContactForm: true });
  };

  handleWindowResize = () => {
    let resizeTimeout;
    window.addEventListener('resize', () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(this.setScrollHeight, 50);
    });
  };

  generateProducts = () => {
    const { products, productSelections, fontText, fontTextBold } = this.props;

    return products.map(product => {
      return (
        <Product
          key={product.id}
          product={product}
          viewAllPackages={this.showProductPackages}
          selectProduct={this.selectProduct}
          isSelectedProduct={productSelections[PRODUCT_SELECTED_KEY[product.type]]}
          isAddonEnable={!product.showOnRequest}
          fontText={fontText}
          fontTextBold={fontTextBold}
          setScrollHeight={this.setScrollHeight}
        />
      );
    });
  };

  preSelectRentalInfo = () => {
    const { frontendConfiguration, updateProductSelections } = this.props;

    if (frontendConfiguration.listRentalInfo.length > 0) {
      updateProductSelections({
        selectedRentalInfo: frontendConfiguration.listRentalInfo[0]
      });
    }
  };

  preSelectProductForWebshop = () => {
    const { updateProductSelections } = this.props;
    let newProductSelections = {};

    newProductSelections.isSelectedProductSolar = true;
    newProductSelections.isSelectedProductStorage = true;
    newProductSelections.isSelectedProductCharge = true;
    newProductSelections.isSelectedProductPower = true;
    newProductSelections.isSelectedProductGas = true;
    newProductSelections.isSelectedProductHouseConnectionForElectricity = true;
    newProductSelections.isSelectedProductHouseConnectionForGas = true;
    newProductSelections.isSelectedProductHouseConnectionForWater = true;
    newProductSelections.isSelectedProductHouseConnectionForHeating = true;
    newProductSelections.isSelectedProductHouseConnectionForBroadband = true;
    newProductSelections.isSelectedProductHeating = true;
    newProductSelections.isSelectedProductCHP = true;
    newProductSelections.isSelectedProductHeatPump = true;
    newProductSelections.isSelectedProductCarsharing = true;
    newProductSelections.isSelectedProductSolarB2B = true;
    newProductSelections.isSelectedProductChargeB2B = true;
    updateProductSelections(newProductSelections);
  };

  preSelectProductForCustomerJourney = () => {
    const { frontendConfiguration, products, updateProductSelections } = this.props;
    let newProductSelections = {};

    const { packageMappings } = frontendConfiguration;

    if (packageMappings.hasOwnProperty(PRODUCT_TYPE.SOLAR)) {
      newProductSelections.isSelectedProductSolar = true;
    }

    if (packageMappings.hasOwnProperty(PRODUCT_TYPE.CHP)) {
      newProductSelections.isSelectedProductCHP = true;
    }

    if (packageMappings.hasOwnProperty(PRODUCT_TYPE.HEATING)) {
      newProductSelections.isSelectedProductHeating = true;
    }

    if (packageMappings.hasOwnProperty(PRODUCT_TYPE.SOLAR_B2B)) {
      newProductSelections.isSelectedProductSolarB2B = true;
    }

    newProductSelections = selectProductForSingleHouseConnectionCJ(
      products,
      newProductSelections,
      packageMappings
    );

    if (packageMappings.hasOwnProperty(PRODUCT_TYPE.CHARGE) && products.length === 1) {
      newProductSelections.isSelectedProductCharge = true;
    }
    if (packageMappings.hasOwnProperty(PRODUCT_TYPE.CHARGE_B2B) && products.length === 1) {
      newProductSelections.isSelectedProductChargeB2B = true;
    }
    !_.isEmpty(newProductSelections) && updateProductSelections(newProductSelections);
  };

  selectCheapestPackages = () => {
    const { products, productSelections, updateProductSelections } = this.props;

    let newProductSelections = {};

    _.forEach(products, product => {
      let price = 0;

      _.forEach(product.packages, onePackage => {
        if (onePackage.status === STATUS.ACTIVE) {
          if (productSelections.selectedPurchaseRental === PAYMENT_TYPE.PURCHASE) {
            if (onePackage.standardRetailAble) {
              price = onePackage.standardRetailPriceGross;
            }
          } else {
            if (onePackage.standardRentedStatus) {
              price = this.calculatePackageRentalPrice(onePackage);
            }
          }
        }
      });

      _.forEach(product.packages, onePackage => {
        if (onePackage.status === STATUS.ACTIVE) {
          if (productSelections.selectedPurchaseRental === PAYMENT_TYPE.PURCHASE) {
            if (onePackage.standardRetailAble) {
              if (onePackage.standardRetailPriceGross <= price) {
                newProductSelections[PACKAGE_SELECTED_KEY[product.type]] = onePackage;
                price = onePackage.standardRetailPriceGross;
              }
            }
          } else {
            if (onePackage.standardRentedStatus) {
              if (this.calculatePackageRentalPrice(onePackage) <= price) {
                newProductSelections[PACKAGE_SELECTED_KEY[product.type]] = onePackage;
                price = this.calculatePackageRentalPrice(onePackage);
              }
            }
          }
        }
      });
    });
    !_.isEmpty(newProductSelections) && updateProductSelections(newProductSelections);
  };

  calculatePackageRentalPrice = onePackage => {
    const { productSelections } = this.props;
    let totalPayment = 0;
    _.forEach(onePackage.packageRentals, packageRental => {
      if (
        packageRental.packageRentalType === PACKAGE_TYPE.STANDARD &&
        productSelections.selectedRentalInfo.duration === packageRental.duration &&
        RENTAL_UNIT[productSelections.selectedRentalInfo.unit] === packageRental.unit
      ) {
        totalPayment =
          onePackage.standardRentalUpfrontFeeGross +
          packageRental.priceGross * packageRental.duration;
      }
    });
    return totalPayment;
  };

  selectStorageProduct = () => {
    const { updateCustomerJourneyMapping } = this.props;
    let customerJourneyMapping = {
      selectStorage: true
    };
    updateCustomerJourneyMapping(customerJourneyMapping);
  };

  unselectUnavailableProduct = nextProducts => {
    const { updateProductSelections } = this.props;
    let newProductSelections = {};
    _.forEach(nextProducts, product => {
      if (product.availableStatus === PRODUCT_AVAILABLE_STATUS.DEACTIVATED) {
        if (product.type) {
          newProductSelections[PRODUCT_SELECTED_KEY[product.type]] = false;
        }
      }
    });
    !_.isEmpty(newProductSelections) && updateProductSelections(newProductSelections);
  };

  solarMapping = (packageMappings, nextProps) => {
    const { customerJourneyMapping } = nextProps;
    const { products, updateProductSelections, productSelections } = this.props;
    let newProductSelections = {};

    newProductSelections.isSelectedProductSolar = true;
    newProductSelections.isSelectedSolarPackagePremiumType = false;

    _.forEach(packageMappings.SOLAR.packageMappings.Default, mapping => {
      let solarProduct = products.filter(product => product.type === PRODUCT_TYPE.SOLAR);

      if (
        customerJourneyMapping.totalConsumption < Number(mapping.startRangeValue) ||
        customerJourneyMapping.totalConsumption > Number(mapping.value)
      ) {
        newProductSelections.selectedSolarPackage = {};
      } else {
        let packages = solarProduct[0].packages.filter(
          onePackage => onePackage.id === mapping.selectedPackageId
        );

        if (packages[0]) {
          newProductSelections.selectedSolarPackage = packages[0];
          newProductSelections.selectedAddonIds = removeInvalidAddonsWhenChangePackageV1({
            currentSelectedAddonIds: productSelections?.selectedAddonIds,
            nextPackage: packages[0],
            currentPackage: productSelections?.selectedSolarPackage
          });
        }
        return false; // break forEach
      }
    });
    !_.isEmpty(newProductSelections) && updateProductSelections(newProductSelections);
  };

  solarB2BMapping = (packageMappings, nextProps) => {
    const { customerJourneyMapping } = nextProps;
    const { products, updateProductSelections, productSelections } = this.props;
    let newProductSelections = {};

    newProductSelections.isSelectedProductSolarB2B = true;
    newProductSelections.isSelectedSolarB2BPackagePremiumType = false;

    _.forEach(packageMappings.SOLAR_B2B.packageMappings.Default, mapping => {
      let solarB2BProduct = products.filter(product => product.type === PRODUCT_TYPE.SOLAR_B2B);

      if (
        customerJourneyMapping.totalConsumption < Number(mapping.startRangeValue) ||
        customerJourneyMapping.totalConsumption > Number(mapping.value)
      ) {
        newProductSelections.selectedSolarB2BPackage = {};
      } else {
        let packages = solarB2BProduct[0].packages.filter(
          onePackage => onePackage.id === mapping.selectedPackageId
        );

        if (packages[0]) {
          newProductSelections.selectedSolarB2BPackage = packages[0];
          newProductSelections.selectedAddonIds = removeInvalidAddonsWhenChangePackageV1({
            currentSelectedAddonIds: productSelections?.selectedAddonIds,
            nextPackage: packages[0],
            currentPackage: productSelections?.selectedSolarB2BPackage
          });
        }
        return false; // break forEach
      }
    });
    !_.isEmpty(newProductSelections) && updateProductSelections(newProductSelections);
  };

  storageMapping = (packageMappings, nextProps) => {
    const { customerJourneyMapping } = nextProps;
    const { products, productSelections, updateProductSelections } = this.props;
    let newProductSelections = {};

    if (customerJourneyMapping.selectStorage) {
      newProductSelections.isSelectedProductStorage = true;
      newProductSelections.isSelectedStoragePackagePremiumType = false;

      let storageProducts = products.filter(product => product.type === PRODUCT_TYPE.STORAGE);

      _.forEach(packageMappings.STORAGE.packageMappings.Default, mapping => {
        if (
          customerJourneyMapping.totalConsumption < Number(mapping.startRangeValue) ||
          customerJourneyMapping.totalConsumption > Number(mapping.value)
        ) {
          newProductSelections.selectedStoragePackage = {};
        } else {
          let packages = storageProducts[0].packages.filter(
            onePackage => onePackage.id === mapping.selectedPackageId
          );
          if (packages[0]) {
            newProductSelections.selectedStoragePackage = packages[0];
            newProductSelections.selectedAddonIds = removeInvalidAddonsWhenChangePackageV1({
              currentSelectedAddonIds: productSelections?.selectedAddonIds,
              nextPackage: packages[0],
              currentPackage: productSelections?.selectedStoragePackage
            });
          }
          return false; // break forEach
        }
      });
    } else {
      if (!productSelections.isManualSelectedStorageProduct) {
        newProductSelections.isSelectedProductStorage = false;
      }
    }
    !_.isEmpty(newProductSelections) && updateProductSelections(newProductSelections);
  };

  chargeMapping = (packageMappings, nextProps) => {
    if (packageMappings.hasOwnProperty(PRODUCT_TYPE.SOLAR)) {
      this.chargeMappingForCombo4(packageMappings, nextProps);
    } else {
      this.chargeMappingSingle(packageMappings, nextProps);
    }
  };

  chargeB2BMapping = (packageMappings, nextProps) => {
    if (packageMappings.hasOwnProperty(PRODUCT_TYPE.SOLAR_B2B)) {
      this.chargeMappingForCombo4(packageMappings, nextProps);
    } else {
      this.chargeB2BMappingSingle(packageMappings, nextProps);
    }
  };

  chargeMappingForCombo4 = (packageMappings, nextProps) => {
    const { customerJourneyMapping, customerJourneyAnswer } = nextProps;
    const { products, productSelections, updateProductSelections } = this.props;
    let newProductSelections = {};

    if (
      customerJourneyMapping.selectCharge &&
      customerJourneyAnswer.electricCar === USAGE_OF_ELECTRIC_CAR.YES
    ) {
      newProductSelections.isSelectedProductCharge = true;
      newProductSelections.isSelectedChargePackagePremiumType = false;

      let electricCarChargeSpeed = SPECIAL_CHARACTER.EMPTY;

      let chargeType = SPECIAL_CHARACTER.EMPTY;
      if (packageMappings.CHARGE.packageMappings.hasOwnProperty(CHARGE_TYPE_LANGUAGE[0].ENGLISH)) {
        chargeType = CHARGE_TYPE_LANGUAGE[0].ENGLISH;
      } else {
        chargeType = CHARGE_TYPE_LANGUAGE[0].GERMAN;
      }

      if (
        packageMappings.CHARGE.packageMappings[chargeType][
          customerJourneyAnswer.electricCarChargeSpeed
        ].value === CHARGE_SPEED.PROPERTIES[customerJourneyAnswer.electricCarChargeSpeed].ENGLISH
      ) {
        electricCarChargeSpeed =
          CHARGE_SPEED.PROPERTIES[customerJourneyAnswer.electricCarChargeSpeed].ENGLISH;
      } else {
        electricCarChargeSpeed =
          CHARGE_SPEED.PROPERTIES[customerJourneyAnswer.electricCarChargeSpeed].GERMAN;
      }
      _.forEach(packageMappings.CHARGE.packageMappings[chargeType], mapping => {
        let chargeProducts = products.filter(product => product.type === PRODUCT_TYPE.CHARGE);

        if (electricCarChargeSpeed === mapping.value && mapping.packageSelectable) {
          let packages = chargeProducts[0].packages.filter(
            onePackage => onePackage.id === mapping.selectedPackageId
          );

          if (packages[0]) {
            newProductSelections.selectedChargePackage = packages[0];
            newProductSelections.selectedAddonIds = removeInvalidAddonsWhenChangePackageV1({
              currentSelectedAddonIds: productSelections?.selectedAddonIds,
              nextPackage: packages[0],
              currentPackage: productSelections?.selectedChargePackage
            });
          }
        }
      });
    } else {
      if (!productSelections.isManualSelectedChargeProduct) {
        newProductSelections.isSelectedProductCharge = false;
      }
    }
    !_.isEmpty(newProductSelections) && updateProductSelections(newProductSelections);
  };

  chargeMappingSingle = (packageMappings, nextProps) => {
    const { currQuestions, customerJourneyAnswer } = nextProps;
    const { products, updateProductSelections, productSelections } = this.props;
    let newProductSelections = {};

    newProductSelections.isSelectedProductCharge = true;
    newProductSelections.isSelectedChargePackagePremiumType = false;

    let chargeType = SPECIAL_CHARACTER.EMPTY;

    if (!currQuestions[this.getQuestionIndex(QUESTION_ID.CHARGE_TYPE)]) {
      if (packageMappings.CHARGE.packageMappings.hasOwnProperty(CHARGE_TYPE_LANGUAGE[0].ENGLISH)) {
        chargeType = CHARGE_TYPE_LANGUAGE[0].ENGLISH;
      } else {
        chargeType = CHARGE_TYPE_LANGUAGE[0].GERMAN;
      }
    }

    if (customerJourneyAnswer.chargeType === CHARGE_TYPE.CHARGING_STATION.index) {
      if (packageMappings.CHARGE.packageMappings.hasOwnProperty(CHARGE_TYPE_LANGUAGE[0].ENGLISH)) {
        chargeType = CHARGE_TYPE_LANGUAGE[0].ENGLISH;
      } else {
        chargeType = CHARGE_TYPE_LANGUAGE[0].GERMAN;
      }
    }
    if (customerJourneyAnswer.chargeType === CHARGE_TYPE.WALLBOX.index) {
      if (packageMappings.CHARGE.packageMappings.hasOwnProperty(CHARGE_TYPE_LANGUAGE[0].ENGLISH)) {
        chargeType = CHARGE_TYPE_LANGUAGE[1].ENGLISH;
      } else {
        chargeType = CHARGE_TYPE_LANGUAGE[1].GERMAN;
      }
    }

    let chargeProducts = products.filter(product => product.type === PRODUCT_TYPE.CHARGE);

    let cheapestPrice = 0;

    if (typeof packageMappings.CHARGE.packageMappings[chargeType] !== 'undefined') {
      _.forEach(packageMappings.CHARGE.packageMappings[chargeType], mapping => {
        let packages = chargeProducts[0].packages.filter(
          onePackage => onePackage.id === mapping.selectedPackageId
        );
        cheapestPrice = packages[0].standardRetailPrice;
      });

      _.forEach(packageMappings.CHARGE.packageMappings[chargeType], mapping => {
        if (customerJourneyAnswer.chargeCarSpeed >= 0) {
          let selectedChargeCarSpeed = SPECIAL_CHARACTER.EMPTY;

          if (customerJourneyAnswer.chargeCarSpeed === CHARGE_CAR_SPEED.SLOW.index) {
            selectedChargeCarSpeed =
              currQuestions[this.getQuestionIndex(QUESTION_ID.CHARGE_CAR_SPEED)].objectModel
                .tableItems.items[CHARGE_CAR_SPEED.SLOW.name];
          }
          if (customerJourneyAnswer.chargeCarSpeed === CHARGE_CAR_SPEED.MEDIUM.index) {
            selectedChargeCarSpeed =
              currQuestions[this.getQuestionIndex(QUESTION_ID.CHARGE_CAR_SPEED)].objectModel
                .tableItems.items[CHARGE_CAR_SPEED.MEDIUM.name];
          }
          if (customerJourneyAnswer.chargeCarSpeed === CHARGE_CAR_SPEED.FAST.index) {
            selectedChargeCarSpeed =
              currQuestions[this.getQuestionIndex(QUESTION_ID.CHARGE_CAR_SPEED)].objectModel
                .tableItems.items[CHARGE_CAR_SPEED.FAST.name];
          }

          if (selectedChargeCarSpeed === mapping.value) {
            let packages = chargeProducts[0].packages.filter(
              onePackage => onePackage.id === mapping.selectedPackageId
            );

            if (packages[0]) {
              newProductSelections.selectedChargePackage = packages[0];
              newProductSelections.selectedAddonIds = removeInvalidAddonsWhenChangePackageV1({
                currentSelectedAddonIds: productSelections?.selectedAddonIds,
                nextPackage: packages[0],
                currentPackage: productSelections?.selectedChargePackage
              });
            }
          }
        } else {
          let packages = chargeProducts[0].packages.filter(
            onePackage => onePackage.id === mapping.selectedPackageId
          );

          if (packages[0] && packages[0].standardRetailPrice <= cheapestPrice) {
            newProductSelections.selectedChargePackage = packages[0];
            newProductSelections.selectedAddonIds = removeInvalidAddonsWhenChangePackageV1({
              currentSelectedAddonIds: productSelections?.selectedAddonIds,
              nextPackage: packages[0],
              currentPackage: productSelections?.selectedChargePackage
            });
            cheapestPrice = packages[0].standardRetailPrice;
          }
        }
      });
    }
    !_.isEmpty(newProductSelections) && updateProductSelections(newProductSelections);
  };

  chargeB2BMappingSingle = (packageMappings, nextProps) => {
    const { currQuestions, customerJourneyAnswer } = nextProps;
    const { products, updateProductSelections, productSelections } = this.props;
    let newProductSelections = {};

    newProductSelections.isSelectedProductChargeB2B = true;
    newProductSelections.isSelectedChargeB2BPackagePremiumType = false;

    let chargeType = SPECIAL_CHARACTER.EMPTY;

    if (!currQuestions[this.getQuestionIndex(QUESTION_ID.CHARGE_TYPE)]) {
      if (
        packageMappings.CHARGE_B2B.packageMappings.hasOwnProperty(CHARGE_TYPE_LANGUAGE[0].ENGLISH)
      ) {
        chargeType = CHARGE_TYPE_LANGUAGE[0].ENGLISH;
      } else {
        chargeType = CHARGE_TYPE_LANGUAGE[0].GERMAN;
      }
    }

    if (customerJourneyAnswer.chargeType === CHARGE_TYPE.CHARGING_STATION.index) {
      if (
        packageMappings.CHARGE_B2B.packageMappings.hasOwnProperty(CHARGE_TYPE_LANGUAGE[0].ENGLISH)
      ) {
        chargeType = CHARGE_TYPE_LANGUAGE[0].ENGLISH;
      } else {
        chargeType = CHARGE_TYPE_LANGUAGE[0].GERMAN;
      }
    }
    if (customerJourneyAnswer.chargeType === CHARGE_TYPE.WALLBOX.index) {
      if (
        packageMappings.CHARGE_B2B.packageMappings.hasOwnProperty(CHARGE_TYPE_LANGUAGE[0].ENGLISH)
      ) {
        chargeType = CHARGE_TYPE_LANGUAGE[1].ENGLISH;
      } else {
        chargeType = CHARGE_TYPE_LANGUAGE[1].GERMAN;
      }
    }

    let chargeProducts = products.filter(product => product.type === PRODUCT_TYPE.CHARGE_B2B);

    let cheapestPrice = 0;

    if (typeof packageMappings.CHARGE_B2B.packageMappings[chargeType] !== 'undefined') {
      _.forEach(packageMappings.CHARGE_B2B.packageMappings[chargeType], mapping => {
        let packages = chargeProducts[0].packages.filter(
          onePackage => onePackage.id === mapping.selectedPackageId
        );
        cheapestPrice = packages[0].standardRetailPrice;
      });

      _.forEach(packageMappings.CHARGE_B2B.packageMappings[chargeType], mapping => {
        if (customerJourneyAnswer.chargeCarSpeed >= 0) {
          let selectedChargeCarSpeed = SPECIAL_CHARACTER.EMPTY;

          if (customerJourneyAnswer.chargeCarSpeed === CHARGE_CAR_SPEED.SLOW.index) {
            selectedChargeCarSpeed =
              currQuestions[this.getQuestionIndex(QUESTION_ID.CHARGE_CAR_SPEED)].objectModel
                .tableItems.items[CHARGE_CAR_SPEED.SLOW.name];
          }
          if (customerJourneyAnswer.chargeCarSpeed === CHARGE_CAR_SPEED.MEDIUM.index) {
            selectedChargeCarSpeed =
              currQuestions[this.getQuestionIndex(QUESTION_ID.CHARGE_CAR_SPEED)].objectModel
                .tableItems.items[CHARGE_CAR_SPEED.MEDIUM.name];
          }
          if (customerJourneyAnswer.chargeCarSpeed === CHARGE_CAR_SPEED.FAST.index) {
            selectedChargeCarSpeed =
              currQuestions[this.getQuestionIndex(QUESTION_ID.CHARGE_CAR_SPEED)].objectModel
                .tableItems.items[CHARGE_CAR_SPEED.FAST.name];
          }

          if (selectedChargeCarSpeed === mapping.value) {
            let packages = chargeProducts[0].packages.filter(
              onePackage => onePackage.id === mapping.selectedPackageId
            );

            if (packages[0]) {
              newProductSelections.selectedChargeB2BPackage = packages[0];
              newProductSelections.selectedAddonIds = removeInvalidAddonsWhenChangePackageV1({
                currentSelectedAddonIds: productSelections?.selectedAddonIds,
                nextPackage: packages[0],
                currentPackage: productSelections?.selectedChargeB2BPackage
              });
            }
          }
        } else {
          let packages = chargeProducts[0].packages.filter(
            onePackage => onePackage.id === mapping.selectedPackageId
          );

          if (packages[0] && packages[0].standardRetailPrice <= cheapestPrice) {
            newProductSelections.selectedChargeB2BPackage = packages[0];
            newProductSelections.selectedAddonIds = removeInvalidAddonsWhenChangePackageV1({
              currentSelectedAddonIds: productSelections?.selectedAddonIds,
              nextPackage: packages[0],
              currentPackage: productSelections?.selectedChargeB2BPackage
            });
            cheapestPrice = packages[0].standardRetailPrice;
          }
        }
      });
    }
    !_.isEmpty(newProductSelections) && updateProductSelections(newProductSelections);
  };

  powerMapping = (packageMappings, nextProps) => {
    const { customerJourneyMapping, customerJourneyAnswer } = nextProps;
    const { products, productSelections, updateProductSelections } = this.props;
    let newProductSelections = {};
    let cloneProductSelections = _.assign({}, productSelections);

    if (customerJourneyMapping.selectPower) {
      newProductSelections.isSelectedProductPower = true;
      newProductSelections.isSelectedPowerPackagePremiumType = false;

      let powerFlatRate;

      if (
        packageMappings.POWER.packageMappings[
          POWER_FLAT_RATE.PROPERTIES[customerJourneyAnswer.powerFlatRate].ENGLISH
        ]
      ) {
        powerFlatRate = POWER_FLAT_RATE.PROPERTIES[customerJourneyAnswer.powerFlatRate].ENGLISH;
      } else {
        powerFlatRate = POWER_FLAT_RATE.PROPERTIES[customerJourneyAnswer.powerFlatRate].GERMAN;
      }

      let powerProducts = products.filter(product => product.type === PRODUCT_TYPE.POWER);

      _.forEach(packageMappings.POWER.packageMappings[powerFlatRate], mapping => {
        if (
          (customerJourneyMapping.totalConsumption >= Number(mapping.startRangeValue) &&
            customerJourneyMapping.totalConsumption <= Number(mapping.value)) ||
          customerJourneyMapping.totalConsumption > Number(mapping.value)
        ) {
          let packages = powerProducts[0].packages.filter(
            onePackage => onePackage.id === mapping.selectedPackageId
          );

          if (packages[0]) {
            newProductSelections.selectedPowerPackage = packages[0];
            newProductSelections.selectedAddonIds = removeInvalidAddonsWhenChangePackageV1({
              currentPackage: productSelections?.selectedPowerPackage,
              nextPackage: packages[0],
              currentSelectedAddonIds: productSelections?.selectedAddonIds
            });
          }
        }
      });
    } else {
      if (!productSelections.isManualSelectedPowerProduct) {
        newProductSelections.isSelectedProductPower = false;
        _.forEach(products, product => {
          if (product.type === PRODUCT_TYPE.POWER) {
            newProductSelections.selectedAddonIds = removeAddon(product, cloneProductSelections);
          }
        });
      }
    }
    !_.isEmpty(newProductSelections) && updateProductSelections(newProductSelections);
  };

  chpMapping = (packageMappings, nextProps) => {
    const { customerJourneyMapping, customerJourneyAnswer } = nextProps;
    const { products, updateProductSelections, productSelections } = this.props;
    let newProductSelections = {};

    newProductSelections.isSelectedProductCHP = true;
    newProductSelections.isSelectedCHPPackagePremiumType = false;

    let usageOfGas = '';

    if (customerJourneyAnswer.usageOfGas !== -1) {
      if (
        packageMappings.CHP.packageMappings[
          CHP_TYPE.PROPERTIES[customerJourneyAnswer.usageOfGas].ENGLISH
        ]
      ) {
        usageOfGas = CHP_TYPE.PROPERTIES[customerJourneyAnswer.usageOfGas].ENGLISH;
      } else {
        usageOfGas = CHP_TYPE.PROPERTIES[customerJourneyAnswer.usageOfGas].GERMAN;
      }

      let chpProducts = products.filter(product => product.type === PRODUCT_TYPE.CHP);
      let cheapestPrice = 0;

      _.forEach(packageMappings.CHP.packageMappings[usageOfGas], mapping => {
        let packages = chpProducts[0].packages.filter(
          onePackage => onePackage.id === mapping.selectedPackageId
        );
        cheapestPrice = packages[0].standardRetailPrice;
      });

      _.forEach(packageMappings.CHP.packageMappings[usageOfGas], mapping => {
        if (customerJourneyMapping.outputHeating >= 0) {
          if (
            (customerJourneyMapping.outputHeating >= Number(mapping.startRangeValue) &&
              customerJourneyMapping.outputHeating <= Number(mapping.value)) ||
            customerJourneyMapping.outputHeating > Number(mapping.value)
          ) {
            let packages = chpProducts[0].packages.filter(
              onePackage => onePackage.id === mapping.selectedPackageId
            );

            if (packages[0]) {
              newProductSelections.selectedCHPPackage = packages[0];
              newProductSelections.selectedAddonIds = removeInvalidAddonsWhenChangePackageV1({
                currentSelectedAddonIds: productSelections?.selectedAddonIds,
                nextPackage: packages[0],
                currentPackage: productSelections?.selectedCHPPackage
              });
            }
          }
        } else {
          let packages = chpProducts[0].packages.filter(
            onePackage => onePackage.id === mapping.selectedPackageId
          );

          if (packages[0] && packages[0].standardRetailPrice <= cheapestPrice) {
            newProductSelections.selectedCHPPackage = packages[0];
            newProductSelections.selectedAddonIds = removeInvalidAddonsWhenChangePackageV1({
              currentSelectedAddonIds: productSelections?.selectedAddonIds,
              nextPackage: packages[0],
              currentPackage: productSelections?.selectedCHPPackage
            });
            cheapestPrice = packages[0].standardRetailPrice;
          }
        }
      });
    }
    !_.isEmpty(newProductSelections) && updateProductSelections(newProductSelections);
  };

  heatingMapping = (packageMappings, nextProps) => {
    const { customerJourneyAnswer } = nextProps;
    const { products, updateProductSelections, productSelections } = this.props;
    let newProductSelections = {};

    let heatingProducts = products.filter(product => product.type === PRODUCT_TYPE.HEATING);

    newProductSelections.isSelectedProductHeating = true;
    let outputHeating = 0;

    if (
      typeof customerJourneyAnswer.heatingBuiltYearIndex !== 'undefined' &&
      typeof customerJourneyAnswer.heatingLivingSpace !== 'undefined'
    ) {
      outputHeating = calcTotalOutputHeatingByBuiltYearAndLivingSpace(customerJourneyAnswer);
    }

    _.forEach(packageMappings.HEATING.packageMappings.Default, mapping => {
      if (
        (outputHeating > mapping.startRangeValue && outputHeating <= mapping.value) ||
        outputHeating > mapping.value
      ) {
        const packages = heatingProducts[0].packages.filter(
          onePackage => onePackage.id === mapping.selectedPackageId
        );
        newProductSelections.selectedHeatingPackage = packages[0] ?? {};
        newProductSelections.selectedAddonIds = removeInvalidAddonsWhenChangePackageV1({
          currentPackage: productSelections?.selectedHeatingPackage,
          nextPackage: packages[0],
          currentSelectedAddonIds: productSelections?.selectedAddonIds
        });
      }
    });

    if (
      outputHeating >
      packageMappings.HEATING.packageMappings.Default[
        packageMappings.HEATING.packageMappings.Default.length - 1
      ].value
    ) {
      newProductSelections.selectedHeatingPackage = {};
    }
    !_.isEmpty(newProductSelections) && updateProductSelections(newProductSelections);
  };

  houseConnectionForElectricityMapping = (packageMappings, nextProps) => {
    const { customerJourneyAnswer } = nextProps;
    const { products, updateProductSelections, productSelections } = this.props;
    let newProductSelections = {};

    if (
      customerJourneyAnswer.houseConnectionNewOrChange === HOUSE_CONNECTION_NEW_OR_CHANGE.CHANGE ||
      (customerJourneyAnswer.isShowPriceElectricity &&
        customerJourneyAnswer.houseConnectionWarmWaterPreparation === 0)
    ) {
      newProductSelections.selectedHouseConnectionForElectricityPackage = {};
    } else {
      _.forEach(packageMappings.HOUSE_CONNECTION_ELECTRICITY.packageMappings.Default, mapping => {
        let electricHCs = products.filter(
          product => product.type === PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY
        );

        if (
          customerJourneyAnswer.houseConnectionElectricityMaxPower >=
            Number(mapping.startRangeValue) &&
          customerJourneyAnswer.houseConnectionElectricityMaxPower <= Number(mapping.value)
        ) {
          let packages = electricHCs[0].packages.filter(
            onPackage => onPackage.id === mapping.selectedPackageId
          );

          if (packages[0]) {
            newProductSelections.selectedHouseConnectionForElectricityPackage = packages[0];
            newProductSelections.selectedAddonIds = removeInvalidAddonsWhenChangePackageV1({
              currentSelectedAddonIds: productSelections?.selectedAddonIds,
              nextPackage: packages[0],
              currentPackage: productSelections?.selectedHouseConnectionForElectricityPackage
            });
          }
        } else {
          newProductSelections.selectedHouseConnectionForElectricityPackage = {};
        }
      });
    }

    !_.isEmpty(newProductSelections) && updateProductSelections(newProductSelections);
  };

  houseConnectionForGasMapping = (packageMappings, nextProps) => {
    const { customerJourneyAnswer } = nextProps;
    const { products, updateProductSelections, productSelections } = this.props;
    let newProductSelections = {};

    if (
      customerJourneyAnswer.houseConnectionNewOrChange === HOUSE_CONNECTION_NEW_OR_CHANGE.CHANGE
    ) {
      newProductSelections.selectedHouseConnectionForGasPackage = {};
    } else {
      _.forEach(packageMappings.HOUSE_CONNECTION_GAS.packageMappings.Default, mapping => {
        let gasHCs = products.filter(product => product.type === PRODUCT_TYPE.HOUSE_CONNECTION_GAS);

        if (
          customerJourneyAnswer.houseConnectionGasMaxLoad >= Number(mapping.startRangeValue) &&
          customerJourneyAnswer.houseConnectionGasMaxLoad <= Number(mapping.value)
        ) {
          let packages = gasHCs[0].packages.filter(
            onPackage => onPackage.id === mapping.selectedPackageId
          );

          if (packages[0]) {
            newProductSelections.selectedHouseConnectionForGasPackage = packages[0];
            newProductSelections.selectedAddonIds = removeInvalidAddonsWhenChangePackageV1({
              currentSelectedAddonIds: productSelections?.selectedAddonIds,
              nextPackage: packages[0],
              currentPackage: productSelections?.selectedHouseConnectionForGasPackage
            });
          }
        } else {
          newProductSelections.selectedHouseConnectionForGasPackage = {};
        }
      });
    }
    !_.isEmpty(newProductSelections) && updateProductSelections(newProductSelections);
  };

  houseConnectionForWaterMapping = (packageMappings, nextProps) => {
    const { customerJourneyAnswer } = nextProps;
    const { products, updateProductSelections, productSelections } = this.props;
    let newProductSelections = {};

    if (
      customerJourneyAnswer.houseConnectionNewOrChange === HOUSE_CONNECTION_NEW_OR_CHANGE.CHANGE
    ) {
      newProductSelections.selectedHouseConnectionForWaterPackage = {};
    } else {
      _.forEach(packageMappings.HOUSE_CONNECTION_WATER.packageMappings.Default, mapping => {
        let waterHCs = products.filter(
          product => product.type === PRODUCT_TYPE.HOUSE_CONNECTION_WATER
        );

        if (
          customerJourneyAnswer.houseConnectionWaterMaxFlow >= Number(mapping.startRangeValue) &&
          customerJourneyAnswer.houseConnectionWaterMaxFlow <= Number(mapping.value)
        ) {
          let packages = waterHCs[0].packages.filter(
            onPackage => onPackage.id === mapping.selectedPackageId
          );

          if (packages[0]) {
            newProductSelections.selectedHouseConnectionForWaterPackage = packages[0];
            newProductSelections.selectedAddonIds = removeInvalidAddonsWhenChangePackageV1({
              currentSelectedAddonIds: productSelections?.selectedAddonIds,
              nextPackage: packages[0],
              currentPackage: productSelections?.selectedHouseConnectionForWaterPackage
            });
          }
        } else {
          // newProductSelections.selectedHouseConnectionForWaterPackage = {};
        }
      });
    }
    !_.isEmpty(newProductSelections) && updateProductSelections(newProductSelections);
  };

  houseConnectionForHeatingMapping = (packageMappings, nextProps) => {
    const { customerJourneyAnswer } = nextProps;
    const { products, updateProductSelections, productSelections } = this.props;
    let newProductSelections = {};

    if (
      customerJourneyAnswer.houseConnectionNewOrChange === HOUSE_CONNECTION_NEW_OR_CHANGE.CHANGE
    ) {
      newProductSelections.selectedHouseConnectionForHeatingPackage = {};
    } else {
      let heatHCs = products.filter(product => product.type === PRODUCT_TYPE.HOUSE_CONNECTION_HEAT);

      let packages = heatHCs[0].packages;

      if (packages[0]) {
        newProductSelections.selectedHouseConnectionForHeatingPackage = packages[0];
        newProductSelections.selectedAddonIds = removeInvalidAddonsWhenChangePackageV1({
          currentSelectedAddonIds: productSelections?.selectedAddonIds,
          nextPackage: packages[0],
          currentPackage: productSelections?.selectedHouseConnectionForHeatingPackage
        });
      }
    }
    !_.isEmpty(newProductSelections) && updateProductSelections(newProductSelections);
  };

  houseConnectionForBroadbandMapping = (packageMappings, nextProps) => {
    const { customerJourneyAnswer } = nextProps;
    const { products, updateProductSelections, productSelections } = this.props;
    let newProductSelections = {};

    if (
      customerJourneyAnswer.houseConnectionNewOrChange === HOUSE_CONNECTION_NEW_OR_CHANGE.CHANGE
    ) {
      newProductSelections.selectedHouseConnectionForBroadbandPackage = {};
    } else {
      let broadBandHCs = products.filter(
        product => product.type === PRODUCT_TYPE.HOUSE_CONNECTION_BROADBAND
      );

      let packages = broadBandHCs[0].packages;

      if (packages[0]) {
        newProductSelections.selectedHouseConnectionForBroadbandPackage = packages[0];
        newProductSelections.selectedAddonIds = removeInvalidAddonsWhenChangePackageV1({
          currentSelectedAddonIds: productSelections?.selectedAddonIds,
          nextPackage: packages[0],
          currentPackage: productSelections?.selectedHouseConnectionForBroadbandPackage
        });
      }
    }
    !_.isEmpty(newProductSelections) && updateProductSelections(newProductSelections);
  };

  render() {
    const {
      fontText,
      fontTextBold,
      productSelections,
      selectedProducts,
      frontendConfiguration,
      routeToConnectErrorPage,
      createOpportunities,
      children,
      linkColor,
      productsWrapperHeight: productsWrapperHeightProp
    } = this.props;
    const {
      selectedProduct,
      isShowContactForm,
      isShowProductPackage,
      productsWrapperHeight
    } = this.state;

    const purchaseRentalSelectionComponent = (
      <PurchaseRentalSelection
        prepareTotalAmountCalculation={() => ({})}
        fontText={fontText}
        fontTextBold={fontTextBold}
      />
    );

    const selectedPackage = productSelections[PACKAGE_SELECTED_KEY[selectedProduct.type]];

    const isSelectedPackagePremiumType =
      productSelections[PACKAGE_PREMIUM_TYPE_KEY[selectedProduct.type]];

    const productPackageComponent = (
      <ProductPackage
        product={selectedProduct}
        selectedPackage={selectedPackage}
        isSelectedPackagePremiumType={isSelectedPackagePremiumType}
        showProductComponent={this.showProductComponent}
        fontText={fontText}
        fontTextBold={fontTextBold}
        packageInfoHeight={
          frontendConfiguration.frontendType === FRONTEND_TYPE.WEBSHOP
            ? LIMIT_HEIGHT.DEFAULT_PACKAGE_WRAPPER
            : productsWrapperHeightProp
        }
      />
    );
    const temporaryTotalComponent = (
      <TemporaryTotal fontText={fontText} fontTextBold={fontTextBold} />
    );

    const contactFormComponent = (
      <ContactForm
        routeToConnectErrorPage={routeToConnectErrorPage}
        createOpportunities={createOpportunities}
        backToSelectProduct={this.backFromContactForm}
        fontText={fontText}
        fontTextBold={fontTextBold}
        linkColor={linkColor}
      />
    );

    let style = {
      background: COLOR.TRANSPARENT
    };

    if (frontendConfiguration.frontendType === FRONTEND_TYPE.WEBSHOP) {
      style = {
        background: hexaToRGBA(
          frontendConfiguration.backgroundColor,
          frontendConfiguration.backgroundOpacity / 100
        )
      };
    }

    const buttonText = frontendConfiguration.designOptions.requestButtonText;

    const button = {
      color: frontendConfiguration.buttonTextColor,
      backgroundColor: frontendConfiguration.buttonBackground,
      borderRadius: frontendConfiguration.buttonBorderRadius,
      width: '100%',
      opacity: selectedProducts.length === 0 ? '0.5' : '1',
      cursor: selectedProducts.length === 0 ? 'not-allowed' : 'pointer',
      borderColor: frontendConfiguration.secondaryColor
    };

    const webshopCTAStyle = {
      backgroundColor: frontendConfiguration.secondaryColor,
      borderRadius: frontendConfiguration.borderRadiusMax4px,
      marginTop: '11px'
    };

    const totalPriceWebshop = {
      padding: '20px'
    };

    const totalPriceCustomerJourney = {
      backgroundColor: frontendConfiguration.secondaryColor,
      borderRadius: frontendConfiguration.borderRadiusMax4px
    };

    const textColor = {
      color: frontendConfiguration.secondaryTextColor
    };

    return (
      <div className="container-fluid ep-webshoppage-wrapper ep--pb-0" style={style}>
        <ShowIfUtil condition={!isShowContactForm}>
          <div className="row ep--p-0">
            <div className="col-12 ep--px-8">
              <ShowIfUtil condition={frontendConfiguration.isShowPackageInfo}>
                {purchaseRentalSelectionComponent}
              </ShowIfUtil>

              <ShowIfUtil
                condition={
                  frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY &&
                  !frontendConfiguration.isShowPackageInfo
                }
              >
                <div className="d-flex align-items-center justify-content-center ep--mt-16">
                  <StyledSpan
                    font={fontText}
                    style={textColor}
                    className="ep__txt--md ep__txt-regular"
                  >
                    {'Anfrage für die folgenden Produkte:'}
                  </StyledSpan>
                </div>
              </ShowIfUtil>

              <ShowIfUtil condition={!isShowProductPackage}>
                <ScrollUtil
                  height={
                    parseInt(productsWrapperHeight, 10) < 455
                      ? productsWrapperHeight.toString()
                      : LIMIT_HEIGHT.DEFAULT_PRODUCT_WRAPPER
                  }
                >
                  <div
                    className="ep--pb-8"
                    ref={product => {
                      this.productScrollBar = product;
                    }}
                  >
                    {this.generateProducts()}
                  </div>
                </ScrollUtil>
              </ShowIfUtil>

              <ShowIfUtil
                className="d-flex align-items-center justify-content-center"
                condition={isShowProductPackage}
              >
                {productPackageComponent}
              </ShowIfUtil>

              <ShowIfUtil condition={frontendConfiguration.frontendType === FRONTEND_TYPE.WEBSHOP}>
                <div className="ep--px-8 d-flex flex-column" style={totalPriceWebshop}>
                  {temporaryTotalComponent}
                </div>
              </ShowIfUtil>
            </div>

            <ShowIfUtil
              condition={
                !isShowProductPackage &&
                frontendConfiguration.frontendType === FRONTEND_TYPE.WEBSHOP
              }
            >
              <div className="col-12 text-center" style={webshopCTAStyle}>
                <div className="ep--pt-16">
                  <StyledSpan
                    font={fontText}
                    style={textColor}
                    className="ep__txt--md ep__txt-regular"
                  >
                    {'Jetzt unverbindliches Angebot anfordern!'}
                  </StyledSpan>
                </div>
                <div className="box ep--my-16">
                  <StyledButtonBold
                    font={fontTextBold}
                    className="ep-button ep__txt--md ep__txt-bold"
                    style={button}
                    disabled={selectedProducts.length === 0}
                    onClick={this.showContactForm}
                  >
                    {buttonText}
                  </StyledButtonBold>
                </div>
              </div>
            </ShowIfUtil>

            <ShowIfUtil
              condition={
                frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY &&
                frontendConfiguration.isShowPackageInfo
              }
            >
              <div className="col-12 ep--px-8">
                <div
                  className="ep-webshoppage-total-wrapper d-flex flex-column align-items-center justify-content-center ep--mx-8"
                  style={totalPriceCustomerJourney}
                >
                  {temporaryTotalComponent}
                  {children}
                </div>
              </div>
            </ShowIfUtil>
          </div>
        </ShowIfUtil>

        <ShowIfUtil condition={isShowContactForm}>{contactFormComponent}</ShowIfUtil>
      </div>
    );
  }
}

WebshopContainer.propTypes = {
  routeToConnectErrorPage: PropTypes.func.isRequired,
  updateCustomerJourneyMapping: PropTypes.func.isRequired,
  updateProductSelections: PropTypes.func.isRequired,
  createOpportunities: PropTypes.func,
  currQuestions: PropTypes.array.isRequired,
  fontText: PropTypes.object,
  fontTextBold: PropTypes.object,
  linkColor: PropTypes.string,
  frontendConfiguration: PropTypes.object,
  customerJourneyMapping: PropTypes.object,
  customerJourneyAnswer: PropTypes.object,
  productSelections: PropTypes.object,
  products: PropTypes.array,
  selectedProducts: PropTypes.array,
  productsWrapperHeight: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

WebshopContainer.defaultProps = {
  createOpportunities: () => ({}),
  fontText: {},
  fontTextBold: {},
  linkColor: COLOR.DEFAULT_BLUE,
  frontendConfiguration: {},
  customerJourneyMapping: {},
  customerJourneyAnswer: {},
  productSelections: {},
  children: `<div></div>`,
  products: [],
  selectedProducts: [],
  productsWrapperHeight: LIMIT_HEIGHT.DEFAULT_PRODUCT_WRAPPER
};

function mapStateToProps(state) {
  return {
    frontendConfiguration: state.frontendConfiguration,
    customerJourneyAnswer: state.customerJourneyAnswer,
    customerJourneyMapping: state.customerJourneyMapping,
    productSelections: productSelectionsSelector(state),
    selectedProducts: selectedProductsSelector(state),
    products: productsSelector(state),
    currQuestions: state.currentState.currQuestions
  };
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateProductSelections,
        updateCustomerJourneyMapping,
        updateFrontendConfiguration
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WebshopContainer);

import React, { PureComponent } from 'react';
import { Collapse } from '@blueprintjs/core';
import * as PropTypes from 'prop-types';
import { ShowIfUtil } from '.';
import { COLOR, SPECIAL_CHARACTER, Z_INDEX } from '../constants';
import { StyledSpan } from '../../components/epilot-forms/common';

export class CustomCollapse extends PureComponent {
  constructor(props) {
    super(props);
    const { isOpen } = this.props;
    this.state = {
      isOpen: isOpen
    };
  }

  async onCollapse() {
    const { onCollapse } = this.props;
    const { isOpen } = this.state;

    await this.setState(preState => ({
      ...preState,
      isOpen: !isOpen
    }));
    onCollapse(isOpen);
  }

  render() {
    const {
      title,
      completedContent,
      children,
      isCompleted,
      isError,
      errorMessage,
      uploadDocument,
      fontText,
      zIndex,
      defaultTextColor
    } = this.props;
    const { isOpen } = this.state;

    const textColor = {
      color: defaultTextColor
    };

    return (
      <div className="w-100 col-12" style={{ zIndex }}>
        <div className="d-flex justify-content-between align-items-center ep--mb-8">
          {/*----- TITLE -----*/}
          <div
            onClick={() => this.onCollapse()}
            className="d-flex justify-content-between align-items-center ep-cursor-pointer"
          >
            <i
              className={`ep__txt--md ${isOpen ? 'ep-icon-minimal-down' : 'ep-icon-minimal-right'}`}
              style={textColor}
            />
            <StyledSpan
              className="ep__txt--h2 ep__txt-bold ep--ml-8"
              font={fontText}
              style={textColor}
            >
              {title}
            </StyledSpan>
          </div>

          {/*----- COMPLETED -----*/}
          <ShowIfUtil condition={!!(isCompleted && !isError)}>
            <i className="ep-icon-check-circle-08 text-success ep__txt--h1" style={textColor} />
          </ShowIfUtil>

          {/*----- ERROR -----*/}
          <ShowIfUtil condition={!!isError}>
            <div className="d-flex align-items-center">
              <StyledSpan className="ep-text-red ep__txt-bold ep__txt--sm" font={fontText}>
                {errorMessage}
              </StyledSpan>
              <i className="ep-icon-circle-remove ep-text-red ep__txt--h1 ep--ml-8" />
            </div>
          </ShowIfUtil>
        </div>

        {/*----- COLLAPSE CONTENT -----*/}
        <Collapse
          isOpen={isOpen}
          keepChildrenMounted
          transitionDuration={0}
          className="ep-custom-collapse"
        >
          {children}
        </Collapse>

        {/*----- COMPLETED CONTENT -----*/}
        <ShowIfUtil condition={!!(!isOpen && (isCompleted || uploadDocument))}>
          {completedContent}
        </ShowIfUtil>
      </div>
    );
  }
}

CustomCollapse.propTypes = {
  title: PropTypes.string,
  completedContent: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
  isCompleted: PropTypes.bool,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  onCollapse: PropTypes.func,
  isOpen: PropTypes.bool,
  uploadDocument: PropTypes.bool,
  fontText: PropTypes.object,
  zIndex: PropTypes.number,
  defaultTextColor: PropTypes.string
};

CustomCollapse.defaultProps = {
  title: SPECIAL_CHARACTER.EMPTY,
  isCompleted: false,
  isError: false,
  errorMessage: SPECIAL_CHARACTER.EMPTY,
  onCollapse: () => {},
  isOpen: true,
  uploadDocument: false,
  fontText: {},
  zIndex: Z_INDEX.Z_INDEX_BASIC_LEVEL,
  defaultTextColor: COLOR.DARK_GRAY
};

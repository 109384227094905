import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import {
  addMappingLabel,
  deleteMappingLabel,
  removeSelectedAddOnV2,
  updateCurrentPackage,
  updateCurrPackageManually,
  updateCurrPackageOnRequest,
  updateCustomerJourneyAnswer,
  updateCustomerJourneyMapping,
  updateProductSelections,
  updateSelectedAddOnV2,
  updateShowAllProductOnRequest
} from '../../store/actions';
import {
  ADDON_MAPPING_OPTION,
  CHARGE_CAR_SPEED,
  CHARGE_SPEED,
  CHARGE_SPEED_LEVELS,
  CHARGE_TYPE,
  COLOR,
  FRONTEND_TYPE,
  HEATING_ITEM,
  HOUSE_CONNECTION_CELLAR,
  HOUSE_CONNECTION_E_MOBILITY,
  HOUSE_CONNECTION_ELECTRICITY_FEED_INTO_GRID,
  HOUSE_CONNECTION_EXTINGUISHING_WATER_DEMAND,
  HOUSE_CONNECTION_IS_NEW_BUILDING,
  HOUSE_CONNECTION_IS_OWNER,
  HOUSE_CONNECTION_NEW_OR_CHANGE,
  HOUSE_CONNECTION_PREPARATION_WATER_HEATERS,
  INPUT_TYPE,
  KNOWING_OF_HEATING_OUTPUT,
  PACKAGE_LABEL_TYPE,
  PACKAGE_PREMIUM_TYPE_KEY,
  PACKAGE_SELECTED_KEY,
  PAYMENT_TYPE,
  POWER_FLAT_RATE,
  PRIVATELY_OR_BUSINESS,
  PRODUCT_SELECTED_KEY,
  PRODUCT_TYPE_CURR_PACKAGE_MAPPING,
  QUESTION_EVENT,
  QUESTION_ID,
  QUESTION_TYPE,
  SPECIAL_CHARACTER,
  UNIT,
  USAGE_OF_ELECTRIC_CAR,
  USAGE_OF_GAS,
  WHERE_TO_HEAT
} from '../../common/constants';
import {
  CheckBox,
  DateInput,
  InfoHelpDescription,
  Input,
  StyledButton,
  StyledSpan
} from '../../components/epilot-forms/common';
import {
  calculateHCElectricityMaxPower,
  calculateHCWaterMaxFlow,
  convertDataToComma,
  convertDataToDot,
  findCheapestSellingOptionAddOnV2,
  formatQuestion,
  isValidCJV1WithPricingV2FE,
  ShowIconByQuestionIdUtil,
  ShowIfUtil
} from '../../common/utils';
import {
  currentStateSelector,
  onRequestSelectedProductTypesSelector,
  productAddonsMappingSelector,
  productSelectionsSelector,
  productsSelector,
  selectedProductsSelector
} from '../../store/selectors';

class SelectionQuestionContainer extends PureComponent {
  onChangeTextInput = _.debounce(value => {
    const { customerJourneyAnswer, updateCustomerJourneyAnswer, question } = this.props;
    let newCustomerJourneyAnswer = {};
    switch (question.id) {
      case QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_FEED_INTO_GRID:
        newCustomerJourneyAnswer.houseConnectionElectricityFeedIntoGrid = convertDataToDot(value);
        break;
      case QUESTION_ID.HOUSE_CONNECTION_EXTINGUISHING_WATER_DEMAND:
        newCustomerJourneyAnswer.houseConnectionExtinguishingWaterDemand = convertDataToDot(value);
        newCustomerJourneyAnswer.houseConnectionWaterMaxFlow = calculateHCWaterMaxFlow(
          Number(convertDataToDot(value)),
          customerJourneyAnswer.houseConnectionPeakWater
        );
        break;
      default:
        break;
    }
    !_.isEmpty(newCustomerJourneyAnswer) && updateCustomerJourneyAnswer(newCustomerJourneyAnswer);
  }, 500);

  constructor(props) {
    super(props);
    this.state = {
      answer: SPECIAL_CHARACTER.EMPTY,
      inputPlaceholder: null,
      inputValueUnit: null,
      selections: [],
      checked: false
    };
  }

  componentDidMount() {
    const { customerJourneyAnswer, question, updateCustomerJourneyAnswer } = this.props;
    let selections = [];
    let newCustomerJourneyAnswer = {};
    let datePickerItems;
    let multipleChoiceItems;
    switch (question.id) {
      case QUESTION_ID.ELECTRIC_CAR_CHARGE_SPEED:
        selections.push(question.objectModel.buttonLabels[CHARGE_SPEED.SLOW]);
        selections.push(question.objectModel.buttonLabels[CHARGE_SPEED.MEDIUM]);
        if (Number(question.objectModel.radioItem) === CHARGE_SPEED_LEVELS.THREE_LEVELS) {
          selections.push(question.objectModel.buttonLabels[CHARGE_SPEED.FAST]);
        }
        this.setState({
          answer: question.objectModel.buttonLabels[customerJourneyAnswer.electricCarChargeSpeed],
          selections
        });
        break;

      case QUESTION_ID.ELECTRIC_CAR:
        selections.push(question.objectModel.items[USAGE_OF_ELECTRIC_CAR.YES]);
        selections.push(question.objectModel.items[USAGE_OF_ELECTRIC_CAR.NO]);
        this.setState({
          answer: question.objectModel.items[customerJourneyAnswer.electricCar],
          selections
        });
        break;

      case QUESTION_ID.POWER_FLAT_RATE:
        selections.push(question.objectModel.items[POWER_FLAT_RATE.GREY_POWER]);
        selections.push(question.objectModel.items[POWER_FLAT_RATE.GREEN_POWER]);
        this.setState({
          answer: question.objectModel.items[customerJourneyAnswer.powerFlatRate],
          selections
        });
        break;

      case QUESTION_ID.USAGE_OF_GAS:
        selections.push(question.objectModel.items[USAGE_OF_GAS.YES]);
        selections.push(question.objectModel.items[USAGE_OF_GAS.NO]);
        this.setState({
          answer: question.objectModel.items[customerJourneyAnswer.usageOfGas],
          selections
        });
        break;

      case QUESTION_ID.OUTPUT_OF_HEATING_KNOWING:
        selections.push(question.objectModel.items[KNOWING_OF_HEATING_OUTPUT.YES]);
        selections.push(question.objectModel.items[KNOWING_OF_HEATING_OUTPUT.NO]);
        this.setState({
          answer: question.objectModel.items[customerJourneyAnswer.outputOfHeatingKnowing],
          selections
        });
        break;

      case QUESTION_ID.HEATING_ITEM:
        selections.push(question.objectModel.items[HEATING_ITEM.SINGLE_FAMILY_HOUSE]);
        selections.push(question.objectModel.items[HEATING_ITEM.APARTMENT_BUILDING]);
        selections.push(question.objectModel.items[HEATING_ITEM.OTHER]);
        if (customerJourneyAnswer.whereToHeat >= 0) {
          this.setState({
            answer:
              question.objectModel.items[
                WHERE_TO_HEAT.PROPERTIES[customerJourneyAnswer.whereToHeat].ENGLISH
              ]
          });
        }
        this.setState({ selections: selections });
        break;

      case QUESTION_ID.HOUSE_CONNECTION_NEW_OR_CHANGE:
        selections.push(question.objectModel.items[HOUSE_CONNECTION_NEW_OR_CHANGE.NEW]);
        selections.push(question.objectModel.items[HOUSE_CONNECTION_NEW_OR_CHANGE.CHANGE]);
        this.setState({
          answer: question.objectModel.items[customerJourneyAnswer.houseConnectionNewOrChange],
          selections
        });
        break;

      case QUESTION_ID.HOUSE_CONNECTION_PRIVATELY_OR_BUSINESS:
        selections.push(question.objectModel.items[PRIVATELY_OR_BUSINESS.PRIVATELY]);
        selections.push(question.objectModel.items[PRIVATELY_OR_BUSINESS.BUSINESS]);
        this.setState({
          answer:
            question.objectModel.items[customerJourneyAnswer.houseConnectionPrivatelyOrBusiness],
          selections
        });
        break;

      case QUESTION_ID.HOUSE_CONNECTION_PREPARATION_WATER_HEATERS:
        selections.push(question.objectModel.items[HOUSE_CONNECTION_PREPARATION_WATER_HEATERS.YES]);
        selections.push(question.objectModel.items[HOUSE_CONNECTION_PREPARATION_WATER_HEATERS.NO]);
        this.setState({
          answer:
            question.objectModel.items[customerJourneyAnswer.houseConnectionWarmWaterPreparation],
          selections
        });

        newCustomerJourneyAnswer.isShowPriceElectricity = question.objectModel.showPriceWaterHeaters
          ? question.objectModel.showPriceWaterHeaters.isActive
          : false;
        updateCustomerJourneyAnswer(newCustomerJourneyAnswer);
        break;

      case QUESTION_ID.HOUSE_CONNECTION_SINGLE_FAMILY_HOUSE:
        selections.push(question.objectModel.items[HOUSE_CONNECTION_CELLAR.YES]);
        selections.push(question.objectModel.items[HOUSE_CONNECTION_CELLAR.NO]);
        this.setState({
          answer: question.objectModel.items[customerJourneyAnswer.houseConnectionCellar],
          selections
        });
        break;

      case QUESTION_ID.HOUSE_CONNECTION_IS_OWNER:
        selections.push(question.objectModel.items[HOUSE_CONNECTION_IS_OWNER.YES]);
        selections.push(question.objectModel.items[HOUSE_CONNECTION_IS_OWNER.NO]);
        this.setState({
          answer: question.objectModel.items[customerJourneyAnswer.houseConnectionIsOwner],
          selections
        });
        break;

      case QUESTION_ID.HOUSE_CONNECTION_IS_NEW_BUILDING:
        selections.push(question.objectModel.items[HOUSE_CONNECTION_IS_NEW_BUILDING.YES]);
        selections.push(question.objectModel.items[HOUSE_CONNECTION_IS_NEW_BUILDING.NO]);
        this.setState({
          answer: question.objectModel.items[customerJourneyAnswer.houseConnectionIsNewBuilding],
          selections
        });
        break;

      case QUESTION_ID.CHARGE_PRIVATELY_OR_BUSINESS:
        selections.push(question.objectModel.items[PRIVATELY_OR_BUSINESS.PRIVATELY]);
        selections.push(question.objectModel.items[PRIVATELY_OR_BUSINESS.BUSINESS]);
        this.setState({
          answer: question.objectModel.items[customerJourneyAnswer.chargePrivatelyOrBusiness],
          selections
        });
        break;

      case QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_FEED_INTO_GRID:
        selections.push(
          question.objectModel.items[HOUSE_CONNECTION_ELECTRICITY_FEED_INTO_GRID.YES]
        );
        selections.push(question.objectModel.items[HOUSE_CONNECTION_ELECTRICITY_FEED_INTO_GRID.NO]);
        this.setState({
          answer:
            question.objectModel.items[
              customerJourneyAnswer.houseConnectionElectricityIsFeedIntoGrid
            ],
          inputPlaceholder: question.objectModel.inputConfig.inputPlaceholder,
          inputValueUnit: UNIT.KILOWALT,
          selections
        });
        break;

      case QUESTION_ID.HOUSE_CONNECTION_EXTINGUISHING_WATER_DEMAND:
        selections.push(
          question.objectModel.items[HOUSE_CONNECTION_EXTINGUISHING_WATER_DEMAND.YES]
        );
        selections.push(question.objectModel.items[HOUSE_CONNECTION_EXTINGUISHING_WATER_DEMAND.NO]);
        this.setState({
          answer:
            question.objectModel.items[
              customerJourneyAnswer.houseConnectionIsExtinguishingWaterDemand
            ],
          inputPlaceholder: question.objectModel.inputConfig.inputPlaceholder,
          inputValueUnit: UNIT.CUBIC_METRE,
          selections
        });
        break;

      case QUESTION_ID.CHARGE_TYPE:
        selections.push(question.objectModel.items[CHARGE_TYPE.CHARGING_STATION.name]);
        selections.push(question.objectModel.items[CHARGE_TYPE.WALLBOX.name]);
        if (customerJourneyAnswer.chargeType === CHARGE_TYPE.CHARGING_STATION.index) {
          this.setState({
            answer: question.objectModel.items[CHARGE_TYPE.CHARGING_STATION.name]
          });
        }
        if (customerJourneyAnswer.chargeType === CHARGE_TYPE.WALLBOX.index) {
          this.setState({ answer: question.objectModel.items[CHARGE_TYPE.WALLBOX.name] });
        }
        this.setState({ selections: selections });
        break;

      case QUESTION_ID.CHARGE_CAR_SPEED:
        selections.push(question.objectModel.tableItems.items[CHARGE_CAR_SPEED.SLOW.name]);
        selections.push(question.objectModel.tableItems.items[CHARGE_CAR_SPEED.MEDIUM.name]);
        if (Number(question.objectModel.radioItem) === CHARGE_SPEED_LEVELS.THREE_LEVELS) {
          selections.push(question.objectModel.tableItems.items[CHARGE_CAR_SPEED.FAST.name]);
        }
        if (customerJourneyAnswer.chargeCarSpeed === CHARGE_CAR_SPEED.SLOW.index) {
          this.setState({
            answer: question.objectModel.tableItems.items[CHARGE_CAR_SPEED.SLOW.name]
          });
        }
        if (customerJourneyAnswer.chargeCarSpeed === CHARGE_CAR_SPEED.MEDIUM.index) {
          this.setState({
            answer: question.objectModel.tableItems.items[CHARGE_CAR_SPEED.MEDIUM.name]
          });
        }
        if (customerJourneyAnswer.chargeCarSpeed === CHARGE_CAR_SPEED.FAST.index) {
          this.setState({
            answer: question.objectModel.tableItems.items[CHARGE_CAR_SPEED.FAST.name]
          });
        }
        this.setState({ selections: selections });
        break;

      case QUESTION_ID.HOUSE_CONNECTION_E_MOBILITY:
        selections.push(question.objectModel.items[HOUSE_CONNECTION_E_MOBILITY.YES]);
        selections.push(question.objectModel.items[HOUSE_CONNECTION_E_MOBILITY.NO]);
        this.setState({
          answer: question.objectModel.items[customerJourneyAnswer.eMobilityPlan],
          selections,
          checked: customerJourneyAnswer.eMobilityAgree
        });
        break;
      default:
        break;
    }

    switch (question.type) {
      case QUESTION_TYPE.MULTIPLE_CHOICE:
        selections = selections.concat(question.objectModel);
        multipleChoiceItems = _.find(
          [...customerJourneyAnswer.multipleChoiceItems],
          item => item.id === question.id
        );
        newCustomerJourneyAnswer.multipleChoiceItems = !multipleChoiceItems
          ? [
              ...customerJourneyAnswer.multipleChoiceItems,
              {
                id: question.id,
                label: question.questionLabel,
                value: SPECIAL_CHARACTER.EMPTY
              }
            ]
          : [...customerJourneyAnswer.multipleChoiceItems];
        this.setState({
          answer: question.objectModel[customerJourneyAnswer[question.id]],
          selections
        });
        updateCustomerJourneyAnswer(newCustomerJourneyAnswer);
        break;
      case QUESTION_TYPE.DATE_PICKER:
        selections = selections.concat(question.objectModel);
        datePickerItems = _.find(
          [...customerJourneyAnswer.datePickerItems],
          item => item.id === question.id
        );
        newCustomerJourneyAnswer.datePickerItems = !datePickerItems
          ? [
              ...customerJourneyAnswer.datePickerItems,
              {
                id: question.id,
                label: question.questionLabel,
                date: SPECIAL_CHARACTER.EMPTY
              }
            ]
          : [...customerJourneyAnswer.datePickerItems];
        this.setState({
          answer: datePickerItems ? datePickerItems.date : SPECIAL_CHARACTER.EMPTY,
          selections
        });
        updateCustomerJourneyAnswer(newCustomerJourneyAnswer);
        break;
      default:
        break;
    }
  }

  handleUpdateMappingLabel = ({ eMobilityPlan }) => {
    const { checked } = this.state;
    const { question, addMappingLabel, deleteMappingLabel } = this.props;
    if (
      question.objectModel &&
      question.objectModel.packageLabel &&
      question.objectModel.packageLabel.id !== -1 &&
      question.objectModel.packageLabel.label
    ) {
      if (checked) {
        if (eMobilityPlan === HOUSE_CONNECTION_E_MOBILITY.YES) {
          addMappingLabel({
            [PACKAGE_LABEL_TYPE.E_MOBILITY]: question.objectModel.packageLabel.label
          });
        } else {
          deleteMappingLabel(PACKAGE_LABEL_TYPE.E_MOBILITY);
        }
      } else {
        deleteMappingLabel(PACKAGE_LABEL_TYPE.E_MOBILITY);
      }
    }
  };

  onChangeCheckBox = () => {
    const { question, updateCustomerJourneyAnswer } = this.props;
    const { checked } = this.state;
    this.setState(
      {
        checked: !checked
      },
      () => {
        const { checked } = this.state;
        if (question.id === QUESTION_ID.HOUSE_CONNECTION_E_MOBILITY) {
          this.handleUpdateMappingLabel({ eMobilityPlan: HOUSE_CONNECTION_E_MOBILITY.YES });
          updateCustomerJourneyAnswer({ eMobilityAgree: checked });
        }
      }
    );
  };

  select = (selection, index, e) => {
    if (selection && selection.type === QUESTION_TYPE.DATE_PICKER) {
      this.setState({ answer: e });
    } else {
      this.setState({ answer: selection });
    }
    const {
      question,
      updateCustomerJourneyAnswer,
      updateCustomerJourneyMapping,
      updateProductSelections,
      customerJourneyAnswer,
      customerJourneyMapping,
      products,
      productSelections,
      productAddonsMapping,
      frontendConfiguration: { frontendType },
      updateShowAllProductOnRequest,
      updateCurrPackageOnRequest
    } = this.props;

    let newCustomerJourneyAnswer = {};
    let newCustomerJourneyMapping = {};
    let newProductSelections = {
      selectedAddonIds: productSelections.selectedAddonIds,
      automaticSelectedAddonIds: []
    };
    let cloneDatePickerItems;
    let cloneMultipleChoiceItems;
    let dateIndex;
    let multipleChoiceIndex;

    switch (question.id) {
      case QUESTION_ID.ELECTRIC_CAR:
        newCustomerJourneyAnswer.electricCar = index;
        if (index === USAGE_OF_ELECTRIC_CAR.YES) {
          newCustomerJourneyMapping.totalConsumption =
            customerJourneyMapping.householdConsumption +
            customerJourneyMapping.electricCarUsageConsumption;
        } else {
          newCustomerJourneyMapping.totalConsumption = customerJourneyMapping.householdConsumption;
        }
        break;

      case QUESTION_ID.ELECTRIC_CAR_CHARGE_SPEED:
        newCustomerJourneyAnswer.electricCarChargeSpeed = index;
        newCustomerJourneyMapping.selectCharge = true;
        break;

      case QUESTION_ID.POWER_FLAT_RATE:
        newCustomerJourneyAnswer.powerFlatRate = index;
        newCustomerJourneyMapping.selectPower = true;
        break;

      case QUESTION_ID.USAGE_OF_GAS:
        newCustomerJourneyAnswer.usageOfGas = index;
        break;

      case QUESTION_ID.OUTPUT_OF_HEATING_KNOWING:
        newCustomerJourneyAnswer.outputOfHeatingKnowing = index;
        break;

      case QUESTION_ID.HEATING_ITEM:
        newCustomerJourneyAnswer.whereToHeat = index;
        break;

      case QUESTION_ID.HOUSE_CONNECTION_NEW_OR_CHANGE:
        newCustomerJourneyAnswer.houseConnectionNewOrChange = index;
        if (index === HOUSE_CONNECTION_NEW_OR_CHANGE.CHANGE) {
          newCustomerJourneyAnswer.contactFormMessage = question.objectModel.configMessage.content;
          updateShowAllProductOnRequest({
            isShowAllProductOnRequest: true
          });
        } else {
          newCustomerJourneyAnswer.contactFormMessage = null;
          updateShowAllProductOnRequest({
            isShowAllProductOnRequest: false
          });
        }
        break;

      case QUESTION_ID.HOUSE_CONNECTION_PRIVATELY_OR_BUSINESS:
        newCustomerJourneyAnswer.houseConnectionPrivatelyOrBusiness = index;
        break;

      case QUESTION_ID.HOUSE_CONNECTION_PREPARATION_WATER_HEATERS:
        newCustomerJourneyAnswer.houseConnectionWarmWaterPreparation = index;
        break;

      case QUESTION_ID.HOUSE_CONNECTION_BUILDING_TYPE:
        newCustomerJourneyAnswer.houseConnectionBuildingType = index;
        break;

      case QUESTION_ID.HOUSE_CONNECTION_SINGLE_FAMILY_HOUSE:
        newCustomerJourneyAnswer.houseConnectionCellar = index;
        break;

      case QUESTION_ID.HOUSE_CONNECTION_IS_OWNER:
        newCustomerJourneyAnswer.houseConnectionIsOwner = index;
        break;

      case QUESTION_ID.HOUSE_CONNECTION_IS_NEW_BUILDING:
        newCustomerJourneyAnswer.houseConnectionIsNewBuilding = index;
        break;

      case QUESTION_ID.CHARGE_PRIVATELY_OR_BUSINESS:
        newCustomerJourneyAnswer.chargePrivatelyOrBusiness = index;
        break;

      case QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_FEED_INTO_GRID:
        newCustomerJourneyAnswer.houseConnectionElectricityIsFeedIntoGrid = index;
        break;

      case QUESTION_ID.HOUSE_CONNECTION_EXTINGUISHING_WATER_DEMAND:
        newCustomerJourneyAnswer.houseConnectionIsExtinguishingWaterDemand = index;
        break;

      case QUESTION_ID.CHARGE_TYPE:
        newCustomerJourneyAnswer.chargeType = index;
        if (isValidCJV1WithPricingV2FE(products)) {
          const currentProduct = products[0];
          // reset answer in case go back and change the previous answer
          newCustomerJourneyAnswer.chargeCarSpeed = -1;
          // reset package
          updateCurrPackageOnRequest(PRODUCT_TYPE_CURR_PACKAGE_MAPPING[currentProduct.type]);
        }
        break;

      case QUESTION_ID.CHARGE_CAR_SPEED:
        newCustomerJourneyAnswer.chargeCarSpeed = index;
        break;

      case QUESTION_ID.HOUSE_CONNECTION_E_MOBILITY:
        newCustomerJourneyAnswer.eMobilityPlan = index;
        if (newCustomerJourneyAnswer.eMobilityPlan === HOUSE_CONNECTION_E_MOBILITY.YES) {
          this.handleUpdateMappingLabel({ eMobilityPlan: HOUSE_CONNECTION_E_MOBILITY.YES });
        } else {
          this.handleUpdateMappingLabel({ eMobilityPlan: HOUSE_CONNECTION_E_MOBILITY.NO });
          newCustomerJourneyAnswer.totalAdditionalOutput =
            customerJourneyAnswer.totalAdditionalOutput - customerJourneyAnswer.eMobilityPower;
          newCustomerJourneyAnswer.houseConnectionElectricityMaxPower = calculateHCElectricityMaxPower(
            newCustomerJourneyAnswer.totalAdditionalOutput,
            0
          );
          newCustomerJourneyAnswer.eMobilityPower = 0;
        }
        break;

      default:
        newCustomerJourneyAnswer[question.id] = index;
        if (
          frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2 ||
          frontendType === FRONTEND_TYPE.WEBSHOP_V2
        ) {
          this.handleRemoveSelectedAddonV2OfPreviousAnswer({
            selection,
            questionId: question.id
          });
        }

        switch (selection.event) {
          case QUESTION_EVENT.JUMP_TO_CONTACT_FORM:
            newCustomerJourneyAnswer.contactFormMessage = selection.messageContactForm;
            newProductSelections = this.unselectAddon(newProductSelections, customerJourneyAnswer);
            break;

          case QUESTION_EVENT.CANCEL_CUSTOMER_JOURNEY:
            newCustomerJourneyAnswer.cancelScreenCJInfo =
              selection.cancelCustomerJourneyAnswerModel;
            break;

          case QUESTION_EVENT.SELECT_AN_ADD_ON:
            newCustomerJourneyAnswer.contactFormMessage = selection.messageContactForm;
            newProductSelections = this.unselectAddon(newProductSelections, customerJourneyAnswer);
            newCustomerJourneyAnswer[`${question.id}selectedAddOnId`] = [];
            if (
              frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2 ||
              frontendType === FRONTEND_TYPE.WEBSHOP_V2
            ) {
              this.handleSelectAddonV2ByAnswerTheQuestion({
                selection,
                questionId: question.id
              });
            } else {
              this.handleSelectAddonV1ByAnswerTheQuestion({
                selection,
                productSelections,
                newProductSelections,
                newCustomerJourneyAnswer,
                question,
                productAddonsMapping
              });
            }

            break;
          default:
            newCustomerJourneyAnswer.contactFormMessage = selection.messageContactForm;
            newProductSelections = this.unselectAddon(newProductSelections, customerJourneyAnswer);
            break;
        }
        break;
    }
    switch (question.type) {
      case QUESTION_TYPE.MULTIPLE_CHOICE:
        cloneMultipleChoiceItems = [...customerJourneyAnswer.multipleChoiceItems];
        multipleChoiceIndex = _.findIndex(
          cloneMultipleChoiceItems,
          item => item.id === question.id
        );
        if (multipleChoiceIndex > -1) {
          cloneMultipleChoiceItems[multipleChoiceIndex].value = selection.content;
          newCustomerJourneyAnswer.multipleChoiceItems = cloneMultipleChoiceItems;
        }
        break;
      case QUESTION_TYPE.DATE_PICKER:
        cloneDatePickerItems = [...customerJourneyAnswer.datePickerItems];
        dateIndex = _.findIndex(cloneDatePickerItems, item => item.id === question.id);
        if (dateIndex > -1) {
          cloneDatePickerItems[dateIndex].date = e;
          newCustomerJourneyAnswer.datePickerItems = cloneDatePickerItems;
        }
        if (!e) {
          newProductSelections = this.unselectAddon(newProductSelections, customerJourneyAnswer);
          newCustomerJourneyAnswer[question.id] = null;
        }
        break;
      default:
        break;
    }
    updateCustomerJourneyAnswer(newCustomerJourneyAnswer);
    updateCustomerJourneyMapping(newCustomerJourneyMapping);
    !_.isEmpty(newProductSelections) && updateProductSelections(newProductSelections);
  };

  handleSelectAddonV1ByAnswerTheQuestion = ({
    selection,
    productSelections,
    newProductSelections,
    newCustomerJourneyAnswer,
    question,
    productAddonsMapping
  }) => {
    _.forEach(selection.addOnMappingModels, addOnMappingModel => {
      let isPackageOnRequest = true;
      let currentSelectedPackage =
        productSelections[PACKAGE_SELECTED_KEY[addOnMappingModel.productType]];
      let isCurrentSelectedPackagePremium =
        productSelections[PACKAGE_PREMIUM_TYPE_KEY[addOnMappingModel.productType]];
      let currentSelectedProduct =
        productSelections[PRODUCT_SELECTED_KEY[addOnMappingModel.productType]];

      if (productSelections.selectedPurchaseRental === PAYMENT_TYPE.PURCHASE) {
        if (isCurrentSelectedPackagePremium) {
          if (currentSelectedPackage && currentSelectedPackage.premiumRetailAble) {
            isPackageOnRequest = false;
          }
        } else {
          if (currentSelectedPackage && currentSelectedPackage.standardRetailAble) {
            isPackageOnRequest = false;
          }
        }
      } else {
        if (isCurrentSelectedPackagePremium) {
          if (currentSelectedPackage && currentSelectedPackage.premiumRentedStatus) {
            isPackageOnRequest = false;
          }
        } else {
          if (currentSelectedPackage && currentSelectedPackage.standardRentedStatus) {
            isPackageOnRequest = false;
          }
        }
      }
      let {
        mappingAddOnOption,
        allPackagesMappingAddOnId,
        addOnPackageMapping,
        otherPackagesMappingAddOnId,
        productId
      } = addOnMappingModel;
      let addonMappedPackage;
      let automaticAddonSelectedId;

      let {
        selectedAddonIds: tmpSelectedAddonIds,
        automaticSelectedAddonIds
      } = newProductSelections;
      let { selectedAddonIds: currentSelectedAddonIds } = productSelections;

      if (currentSelectedProduct && !isPackageOnRequest) {
        switch (mappingAddOnOption) {
          case ADDON_MAPPING_OPTION.ALL_PACKAGES:
            if (tmpSelectedAddonIds.indexOf(allPackagesMappingAddOnId) < 0) {
              tmpSelectedAddonIds.push(allPackagesMappingAddOnId);
              newCustomerJourneyAnswer[`${question.id}selectedAddOnId`].push(
                allPackagesMappingAddOnId
              );
            }
            break;
          case ADDON_MAPPING_OPTION.SPECIFIC_PACKAGE:
            addonMappedPackage = _.find(
              addOnPackageMapping,
              elem => currentSelectedPackage.id === elem.packageId
            );
            if (addonMappedPackage) {
              if (_.indexOf(currentSelectedAddonIds, addonMappedPackage.addOnId) < 0) {
                automaticAddonSelectedId = addonMappedPackage.addOnId;
                tmpSelectedAddonIds.push(automaticAddonSelectedId);
                automaticSelectedAddonIds.push(automaticAddonSelectedId);
                newCustomerJourneyAnswer[`${question.id}selectedAddOnId`].push(
                  automaticAddonSelectedId
                );
              } else {
                // this addon was automatically selected by previous question,
                // but we need to show popup again for this question
                automaticSelectedAddonIds.push(addonMappedPackage.addOnId);
              }
            } else {
              if (_.indexOf(currentSelectedAddonIds, otherPackagesMappingAddOnId) < 0) {
                automaticAddonSelectedId = otherPackagesMappingAddOnId;
                tmpSelectedAddonIds.push(automaticAddonSelectedId);
                automaticSelectedAddonIds.push(automaticAddonSelectedId);
                newCustomerJourneyAnswer[`${question.id}selectedAddOnId`].push(
                  automaticAddonSelectedId
                );
              } else {
                // this addon was automatically selected by previous question,
                // and we need to show automatic addon selected notification popup again for this question
                automaticSelectedAddonIds.push(otherPackagesMappingAddOnId);
              }
            }
            if (!question.multipleAddonsSelectionEnable && automaticAddonSelectedId) {
              let addonsToRemove = _.without(
                productAddonsMapping[productId].singleAddonSelectionGroupIds,
                automaticAddonSelectedId
              );
              _.pull(tmpSelectedAddonIds, ...addonsToRemove);
            }
            break;
          default:
            break;
        }
      }
    });
  };

  /**
   *
   * We need to remove automatic selected addon in case of changing the answer in the same question
   * Ex: Question 1 has two answer, A and B. Answer A select addon A1, change the answer to B,
   *     remove addon A1, select mapping addon B1 of answer B and vice versa
   *
   * @param questionId
   * @param selection
   */
  handleRemoveSelectedAddonV2OfPreviousAnswer = ({ questionId, selection }) => {
    const { selectedAddOnV2MapKeyByProductType, removeSelectedAddOnV2 } = this.props;
    const { addOnMappingModels } = selection;
    _.forEach(addOnMappingModels, addOnMappingModel => {
      const { productType } = addOnMappingModel;

      const selectedAddOns = selectedAddOnV2MapKeyByProductType[productType];

      const previousAnswerSelectedAddOn = _.find(
        selectedAddOns,
        selectedAddOn => selectedAddOn.selectedByQuestionId === questionId
      );

      if (previousAnswerSelectedAddOn) {
        removeSelectedAddOnV2({ productType, addOnId: previousAnswerSelectedAddOn.addOnId });
      }
    });
  };

  /**
   * handle select package mapping addon configured by OLD (CJ 1.0) package addon mapping configuration
   * both of package and addon use pricing 2.0
   *
   * @param selection
   * @param questionId
   */
  handleSelectAddonV2ByAnswerTheQuestion = ({ selection, questionId }) => {
    const { selectedProducts, selectedAddOnV2MapKeyByProductType, currentState } = this.props;
    const { addOnMappingModels } = selection;
    _.forEach(addOnMappingModels, addOnMappingModel => {
      const {
        addOnPackageMapping,
        allPackagesMappingAddOnId,
        otherPackagesMappingAddOnId,
        productType
      } = addOnMappingModel;

      const currentProductPackage = currentState[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[productType]];

      if (_.isEmpty(addOnMappingModels)) return;

      // Do not select addon if product isn't selected
      if (!_.find(selectedProducts, selectedProduct => selectedProduct.type === productType))
        return;

      const selectedAddOns = selectedAddOnV2MapKeyByProductType[productType];

      if (_.isEmpty(addOnPackageMapping)) {
        this.handleSelectAllPackagesMappingAddOnV2({
          productType,
          allPackagesMappingAddOnId,
          questionId,
          selectedAddOns
        });
      } else {
        this.handleSelectSpecificPackageMappingAddOnV2({
          addOnPackageMapping,
          otherPackagesMappingAddOnId,
          productType,
          currentProductPackage,
          selectedAddOns,
          questionId
        });
      }
    });
  };

  /**
   * This function only for mapping pricing V2's addon with OLD question answer addon mapping configuration
   *
   * @param addOnsV2
   * @param addOnId
   * @param selectedAddOns
   * @param productType
   * @param questionId
   */
  selectMappingAddOnV2 = ({ addOnsV2, addOnId, selectedAddOns, productType, questionId }) => {
    const { updateSelectedAddOnV2 } = this.props;
    const matchedAddOn = _.find(addOnsV2, addOn => addOn.id === addOnId);
    if (!matchedAddOn || matchedAddOn.sellingOptions?.length === 0) return;

    // Do not reselect addon if selected
    if (
      !selectedAddOns ||
      _.isEmpty(selectedAddOns) ||
      !_.find(selectedAddOns, selectedAddOn => selectedAddOn.addOnId === matchedAddOn.id)
    ) {
      updateSelectedAddOnV2({
        productType,
        addOnId: matchedAddOn.id,
        addOnSellingOption: findCheapestSellingOptionAddOnV2(matchedAddOn.sellingOptions),
        addOnName: matchedAddOn.name,
        selectedByQuestionId: questionId
      });
    }
  };

  /**
   * This function only for mapping pricing V2's addon with OLD question answer addon mapping configuration
   *
   * @param currentProductPackage
   * @param addOnPackageMapping
   * @param otherPackagesMappingAddOnId
   * @param productType
   * @param questionId
   * @param selectedAddOns
   */
  handleSelectSpecificPackageMappingAddOnV2 = ({
    currentProductPackage,
    addOnPackageMapping,
    otherPackagesMappingAddOnId,
    productType,
    questionId,
    selectedAddOns
  }) => {
    const {
      productAddOnConfig: {
        productAddOnsMapKeyByProductType,
        specificPackageAddOnsMapKeyByPackageId,
        otherPackageAddOnsMapKeyByExcludedPackageId
      }
    } = this.props;
    const { packageId: currentPackageId } = currentProductPackage;
    const selectAddOnConfig = _.find(
      addOnPackageMapping,
      elem => elem.packageId === currentPackageId
    );
    if (selectAddOnConfig) {
      this.selectMappingAddOnV2({
        addOnsV2: [
          ...(productAddOnsMapKeyByProductType[productType] || []),
          ...(specificPackageAddOnsMapKeyByPackageId[currentPackageId] || [])
        ],
        addOnId: selectAddOnConfig.addOnId,
        selectedAddOns,
        questionId,
        productType
      });
    } else {
      if (productAddOnsMapKeyByProductType[productType]?.length > 0) {
        const addOnsV2 = [...productAddOnsMapKeyByProductType[productType]];
        _.forIn(otherPackageAddOnsMapKeyByExcludedPackageId, (addOns, packageId) => {
          if (Number(packageId) !== currentPackageId) {
            addOnsV2.push(...addOns);
          }
        });
        this.selectMappingAddOnV2({
          addOnsV2: _.uniqBy(addOnsV2.filter(addOnV2 => addOnV2.productType === productType), 'id'),
          addOnId: otherPackagesMappingAddOnId,
          selectedAddOns,
          questionId,
          productType
        });
      }
    }
  };

  /**
   * This function only for mapping pricing V2's addon with OLD question answer addon mapping configuration
   *
   * @param productType
   * @param allPackagesMappingAddOnId
   * @param questionId
   * @param selectedAddOns
   */
  handleSelectAllPackagesMappingAddOnV2 = ({
    productType,
    allPackagesMappingAddOnId,
    questionId,
    selectedAddOns
  }) => {
    const {
      productAddOnConfig: { productAddOnsMapKeyByProductType }
    } = this.props;

    this.selectMappingAddOnV2({
      addOnsV2: productAddOnsMapKeyByProductType[productType],
      addOnId: allPackagesMappingAddOnId,
      selectedAddOns,
      questionId,
      productType
    });
  };

  unselectAddon = (productSelections, customerJourneyAnswer) => {
    const { question } = this.props;
    _.forEach(customerJourneyAnswer[`${question.id}selectedAddOnId`], selectedAddonId => {
      let addonIdx = _.indexOf(productSelections.selectedAddonIds, selectedAddonId);
      if (addonIdx !== -1) {
        productSelections.selectedAddonIds.splice(addonIdx, 1);
      }
    });
    return productSelections;
  };

  renderSelections = () => {
    const { selections, answer } = this.state;
    const { frontendConfiguration, question, fontText, defaultTextColor } = this.props;

    let button = {};
    return _.map(selections, (selection, index) => {
      if (answer === selection) {
        button = {
          minWidth: '90px',
          height: '36px',
          background: frontendConfiguration.primaryColor,
          color: frontendConfiguration.primaryTextColor,
          borderRadius: frontendConfiguration.borderRadius
        };
      } else {
        button = {
          minWidth: '90px',
          height: '36px',
          background: COLOR.WHITE,
          color: defaultTextColor,
          borderColor: frontendConfiguration.primaryColor,
          borderRadius: frontendConfiguration.borderRadius
        };
      }
      switch (question.type) {
        case QUESTION_TYPE.MULTIPLE_CHOICE:
          return (
            <div key={index} className="ep--mx-8 ep--my-8">
              <StyledButton
                className="ep-button ep__txt--sm ep__txt-no-select"
                font={fontText}
                style={button}
                onClick={() => this.select(selection, index)}
                disabled={answer === selection}
              >
                {selection.content}
              </StyledButton>
            </div>
          );
        case QUESTION_TYPE.DATE_PICKER:
          return (
            <DateInput
              key={index}
              value={answer}
              datePickerCenter
              onChange={e => this.select(selection, index, e)}
              primaryTextColor={COLOR.DARK_GRAY}
              defaultTextColor={defaultTextColor}
              minDate={new Date()}
            />
          );
        default:
          return (
            <div key={index} className="ep--mx-8 ep--pt-8">
              <StyledButton
                className="ep-button ep__txt--sm ep__txt-no-select"
                font={fontText}
                style={button}
                onClick={() => this.select(selection, index)}
              >
                {selection}
              </StyledButton>
            </div>
          );
      }
    });
  };

  isShowCheckbox = () => {
    const { customerJourneyAnswer, question } = this.props;

    return (
      question.id === QUESTION_ID.HOUSE_CONNECTION_E_MOBILITY &&
      question.objectModel.checkBoxConfig.isActive &&
      customerJourneyAnswer.eMobilityPlan === HOUSE_CONNECTION_E_MOBILITY.YES
    );
  };

  isShowInputField = () => {
    const { question, customerJourneyAnswer } = this.props;
    switch (question.id) {
      case QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_FEED_INTO_GRID:
        return (
          customerJourneyAnswer.houseConnectionElectricityIsFeedIntoGrid ===
          HOUSE_CONNECTION_ELECTRICITY_FEED_INTO_GRID.YES
        );
      case QUESTION_ID.HOUSE_CONNECTION_EXTINGUISHING_WATER_DEMAND:
        return (
          customerJourneyAnswer.houseConnectionIsExtinguishingWaterDemand ===
          HOUSE_CONNECTION_EXTINGUISHING_WATER_DEMAND.YES
        );
      default:
        return false;
    }
  };

  checkboxContent = () => {
    const { question } = this.props;
    if (question.id === QUESTION_ID.HOUSE_CONNECTION_E_MOBILITY) {
      return question.objectModel.checkBoxConfig.title;
    }
  };

  getInputValue = () => {
    const { question, customerJourneyAnswer } = this.props;
    switch (question.id) {
      case QUESTION_ID.HOUSE_CONNECTION_EXTINGUISHING_WATER_DEMAND:
        return customerJourneyAnswer.houseConnectionExtinguishingWaterDemand;
      case QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_FEED_INTO_GRID:
        return customerJourneyAnswer.houseConnectionElectricityFeedIntoGrid;
      default:
        break;
    }
  };

  render() {
    const { checked, inputPlaceholder, inputValueUnit } = this.state;
    const {
      frontendConfiguration,
      customerJourneyAnswer,
      question,
      fontText,
      defaultTextColor
    } = this.props;
    const textColor = {
      color: defaultTextColor
    };

    return (
      <div className="ep-customerjourneypage__question">
        <ShowIconByQuestionIdUtil
          questionId={question.id}
          questionType={question.type}
          colorIcon={frontendConfiguration.primaryColor}
        />

        <div className="text-center ep-min-height-70 ep--pt-8 ep--pb-16">
          <StyledSpan
            font={fontText}
            className="ep__txt-regular ep__txt--lg ep__txt-no-select"
            style={textColor}
          >
            {formatQuestion(question.questionTitle, customerJourneyAnswer.name)}
          </StyledSpan>
          <InfoHelpDescription
            showHelpDescription={question.showHelpDescription}
            tooltipInfoContent={<StyledSpan font={fontText}>{question.helpDescription}</StyledSpan>}
            classNameForInfoIcon="ep__txt--lg ep--ml-8"
            defaultTextColor={defaultTextColor}
          />
        </div>

        <div className="ep-min-height-130">
          <div className="d-flex align-items-center justify-content-center flex-wrap">
            {this.renderSelections()}
          </div>
          <ShowIfUtil condition={this.isShowCheckbox()}>
            <div className="d-flex align-items-center justify-content-center ep--mt-16">
              <CheckBox
                onChange={this.onChangeCheckBox}
                content={this.checkboxContent()}
                isChecked={checked}
                defaultTextColor={defaultTextColor}
                primaryTextColor={COLOR.BLACK}
                primaryCheckBoxIconColor={frontendConfiguration.primaryColor}
                fontText={fontText}
              />
            </div>
          </ShowIfUtil>
          <ShowIfUtil condition={this.isShowInputField()}>
            <div className="d-flex align-items-center justify-content-center ep--mt-16">
              <Input
                questionId={question.id}
                fontSize="12px"
                height="36px"
                minWidth="120px"
                maxWidth="196px"
                textAlign="left"
                paddingRight="3rem"
                customClassStyle=""
                borderRadius={frontendConfiguration.borderRadius}
                type={INPUT_TYPE.TEXT}
                require
                onChange={value => this.onChangeTextInput(value)}
                value={convertDataToComma(this.getInputValue() && this.getInputValue().toString())}
                primaryTextColor={COLOR.DARK_GRAY}
                defaultTextColor={defaultTextColor}
                fontFamily={fontText}
                borderColorFocus={frontendConfiguration.primaryColor}
                placeholder={inputPlaceholder}
                unit={inputValueUnit}
              />
            </div>
          </ShowIfUtil>
        </div>
      </div>
    );
  }
}

SelectionQuestionContainer.propTypes = {
  products: PropTypes.array.isRequired,
  currentState: PropTypes.object.isRequired,
  question: PropTypes.object,
  selectedProducts: PropTypes.array.isRequired,
  productAddOnConfig: PropTypes.object.isRequired,
  selectedAddOnV2MapKeyByProductType: PropTypes.object.isRequired,
  fontText: PropTypes.object,
  defaultTextColor: PropTypes.string,
  frontendConfiguration: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  customerJourneyAnswer: PropTypes.object,
  customerJourneyMapping: PropTypes.object,
  productSelections: PropTypes.object,
  productAddonsMapping: PropTypes.object,
  updateCustomerJourneyAnswer: PropTypes.func,
  updateCustomerJourneyMapping: PropTypes.func,
  updateProductSelections: PropTypes.func,
  addMappingLabel: PropTypes.func.isRequired,
  updateCurrPackageOnRequest: PropTypes.func.isRequired,
  deleteMappingLabel: PropTypes.func.isRequired,
  updateSelectedAddOnV2: PropTypes.func.isRequired,
  removeSelectedAddOnV2: PropTypes.func.isRequired,
  updateShowAllProductOnRequest: PropTypes.func
};

SelectionQuestionContainer.defaultProps = {
  question: {},
  fontText: {},
  defaultTextColor: COLOR.DARK_GRAY,
  frontendConfiguration: {},
  customerJourneyAnswer: {},
  customerJourneyMapping: {},
  productAddonsMapping: {},
  productSelections: {},
  updateCustomerJourneyAnswer: () => ({}),
  updateCustomerJourneyMapping: () => ({}),
  updateProductSelections: () => ({}),
  updateShowAllProductOnRequest: () => ({})
};

const mapStateToProps = state => {
  return {
    currentState: currentStateSelector(state),
    frontendConfiguration: state.frontendConfiguration,
    customerJourneyAnswer: state.customerJourneyAnswer,
    customerJourneyMapping: state.customerJourneyMapping,
    productAddOnConfig: state.productAddOnConfig,
    selectedAddOnV2MapKeyByProductType: state.addOnV2Selections.selectedAddOnV2MapKeyByProductType,
    selectedProducts: selectedProductsSelector(state),
    productSelections: productSelectionsSelector(state),
    productAddonsMapping: productAddonsMappingSelector(state),
    products: productsSelector(state),
    onRequestSelectedProductTypes: onRequestSelectedProductTypesSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateCustomerJourneyAnswer,
        updateCustomerJourneyMapping,
        updateProductSelections,
        updateCurrentPackage,
        addMappingLabel,
        deleteMappingLabel,
        updateShowAllProductOnRequest,
        updateSelectedAddOnV2,
        removeSelectedAddOnV2,
        updateCurrPackageManually,
        updateCurrPackageOnRequest
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectionQuestionContainer);

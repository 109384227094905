import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { getFrontendConfiguration } from '../store/actions';
import { COLOR, SPECIAL_CHARACTER, STATUS } from '../common/constants';
import { StyledSpan } from '../components';
import { getFontTextBoldSelector, getFontTextSelector } from '../store/selectors';

class LaunchingSoonPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fontText: {
        font: SPECIAL_CHARACTER.EMPTY,
        fontUrl: SPECIAL_CHARACTER.EMPTY
      },
      fontTextBold: {
        font: SPECIAL_CHARACTER.EMPTY,
        fontUrl: SPECIAL_CHARACTER.EMPTY
      }
    };
  }

  componentDidMount() {
    const { fontText, fontTextBold } = this.props;

    // Custom font
    this.setState(prevState => ({
      fontText: {
        ...prevState.fontText,
        ...fontText
      }
    }));

    this.setState(prevState => ({
      fontTextBold: {
        ...prevState.fontTextBold,
        font: fontTextBold.font
      }
    }));
  }

  render() {
    const { fontText, fontTextBold } = this.state;
    const { frontendConfiguration } = this.props;
    let status = SPECIAL_CHARACTER.EMPTY;
    if (frontendConfiguration.frontendStatus === STATUS.INACTIVE) {
      status = 'Dieser Inhalt wurde durch unseren Administrator deaktiviert';
    }
    if (frontendConfiguration.frontendStatus === STATUS.DELETED) {
      status = 'Dieser Inhalt wurde durch unseren Administrator gelöscht';
    }

    return (
      <div className="text-center ep--p-32" style={{ backgroundColor: COLOR.WHITE }}>
        <div
          className="ep-success-wrapper-icon"
          style={{
            color: frontendConfiguration ? frontendConfiguration.primaryColor : COLOR.DEFAULT_BLUE
          }}
        >
          <i className="ep-icon-link-broken-70" />
        </div>
        <div className="d-flex justify-content-center align-items-center ep--mt-32">
          <StyledSpan
            font={fontTextBold}
            className="ep__txt--h1"
            style={{
              color: frontendConfiguration ? frontendConfiguration.primaryColor : COLOR.DEFAULT_BLUE
            }}
          >
            {'Inhalt nicht verfügbar.'}
          </StyledSpan>
        </div>
        <div className="d-flex justify-content-center align-items-center ep--mt-32">
          <StyledSpan
            font={fontText}
            className="ep__txt--md"
            style={{
              color: frontendConfiguration ? frontendConfiguration.primaryColor : COLOR.DEFAULT_BLUE
            }}
          >
            {status}
            {'.'}
          </StyledSpan>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <StyledSpan
            font={fontText}
            className="ep__txt--md"
            style={{
              color: frontendConfiguration ? frontendConfiguration.primaryColor : COLOR.DEFAULT_BLUE
            }}
          >
            {'Bitte nehmen Sie Kontakt mit uns auf, um weitere Information zu erhalten.'}
          </StyledSpan>
        </div>
      </div>
    );
  }
}

LaunchingSoonPage.propTypes = {
  frontendConfiguration: PropTypes.object,
  fontText: PropTypes.object,
  fontTextBold: PropTypes.object
};

LaunchingSoonPage.defaultProps = {
  frontendConfiguration: {
    primaryColor: COLOR.GRAY,
    primaryTextColor: COLOR.DEFAULT_BLUE,
    fontFamily: SPECIAL_CHARACTER.EMPTY
  },
  fontText: {},
  fontTextBold: {}
};

function mapStateToProps(state) {
  return {
    frontendConfiguration: state.frontendConfiguration,
    fontText: getFontTextSelector(state),
    fontTextBold: getFontTextBoldSelector(state)
  };
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators(
    {
      getFrontendConfiguration
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LaunchingSoonPage);

import _ from 'lodash';
import {
  FRONTEND_TYPE,
  ON_REQUEST,
  PACKAGE_PREMIUM_TYPE_KEY,
  PACKAGE_SELECTED_KEY,
  PAYMENT_TYPE,
  PRODUCT_TYPE,
  PURCHASE_TYPE
} from '../constants';
import { checkPurchaseType, checkPurchaseTypeV2 } from './PaymentUtil';
import { convertProdTypeToProdName } from './ConvertProductTypeToProductNameUtil';

export const checkSelectedProductOnRequest = ({ selectedProducts, productSelections }) => {
  let selectedProductOnRequest = false;

  if (!_.isEmpty(selectedProducts)) {
    selectedProductOnRequest = _.every(selectedProducts, elem => {
      const isCurrentPackagePremium = productSelections[PACKAGE_PREMIUM_TYPE_KEY[elem.type]];
      const currentPackage = productSelections[PACKAGE_SELECTED_KEY[elem.type]];
      if (_.isEmpty(currentPackage)) return true;
      if (productSelections.selectedPurchaseRental === PAYMENT_TYPE.PURCHASE) {
        if (isCurrentPackagePremium) {
          return !currentPackage.premiumRetailAble;
        } else {
          return !currentPackage.standardRetailAble;
        }
      } else {
        if (isCurrentPackagePremium) {
          return !currentPackage.premiumRentedStatus;
        } else {
          return !currentPackage.standardRentedStatus;
        }
      }
    });
  }
  return selectedProductOnRequest;
};

export const checkIsOnlinePurchase = ({
  frontendConfiguration,
  productSelections,
  totalPrice,
  selectedProducts,
  onlinePurchaseOnlyRequest
}) => {
  let isOnlinePurchase = checkPurchaseType(
    productSelections.selectedPurchaseRental,
    totalPrice,
    frontendConfiguration.onlinePurchaseSettingModel.active
  );
  if (
    (!_.isEmpty(selectedProducts) &&
      (frontendConfiguration.frontendType !== FRONTEND_TYPE.CUSTOMER_JOURNEY_V2 &&
        frontendConfiguration.frontendType !== FRONTEND_TYPE.WEBSHOP_V2) &&
      checkSelectedProductOnRequest({
        selectedProducts,
        productSelections
      })) ||
    onlinePurchaseOnlyRequest
  ) {
    isOnlinePurchase = PURCHASE_TYPE.REQUEST;
  }
  return isOnlinePurchase;
};

export const checkIsOnlinePurchaseV2 = (frontendConfiguration, totalPriceV2) => {
  return checkPurchaseTypeV2(totalPriceV2, frontendConfiguration.onlinePurchaseSettingModel.active);
};

export const isHouseConnectionProductType = product => {
  return (
    product.type === PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY ||
    product.type === PRODUCT_TYPE.HOUSE_CONNECTION_GAS ||
    product.type === PRODUCT_TYPE.HOUSE_CONNECTION_WATER ||
    product.type === PRODUCT_TYPE.HOUSE_CONNECTION_HEAT ||
    product.type === PRODUCT_TYPE.HOUSE_CONNECTION_BROADBAND
  );
};

export const calcTotalOutputHeatingByBuiltYearAndLivingSpace = customerJourneyAnswer => {
  let outputHeating = 0;
  switch (customerJourneyAnswer.heatingBuiltYearIndex) {
    case 0:
      outputHeating = 0.035 * customerJourneyAnswer.heatingLivingSpace + 2.5;
      break;
    case 1:
      outputHeating = 0.057 * customerJourneyAnswer.heatingLivingSpace + 1.429;
      break;
    case 2:
      outputHeating = 0.084 * customerJourneyAnswer.heatingLivingSpace + 2.2;
      break;
    case 3:
      outputHeating = 0.106 * customerJourneyAnswer.heatingLivingSpace + 2.429;
      break;
    case 4:
      outputHeating = 0.142 * customerJourneyAnswer.heatingLivingSpace + 3.333;
      break;
    default:
      break;
  }
  return outputHeating;
};

export const calculateHCElectricityMaxPower = totalAdditionalOutput => {
  return Number(totalAdditionalOutput).toFixed(2);
};

export const calculateHCWaterMaxFlow = (
  houseConnectionExtinguishingWaterDemand,
  houseConnectionPeakWater
) => {
  return Number(
    Number(houseConnectionExtinguishingWaterDemand) + Number(houseConnectionPeakWater)
  ).toFixed(2);
};

export const isAllOnRequestProductPackagesSummaryV2 = productPackagesSummary => {
  return (
    !_.isEmpty(productPackagesSummary) &&
    (_.every(productPackagesSummary, ['onRequest', true]) ||
      _.every(productPackagesSummary, ['alwaysShowOnRequest', true]))
  );
};

export const isAllOnlinePurchaseProductPackagesSummaryV2 = productPackagesSummary => {
  return (
    !_.isEmpty(productPackagesSummary) &&
    _.every(productPackagesSummary, ['onRequest', false]) &&
    _.every(productPackagesSummary, ['alwaysShowOnRequest', false])
  );
};

export const getSummaryDataModelV1 = (selectedProducts, productSelections) => {
  let packageSummaryModels = [];
  let addOnSummaryModels = [];
  let isAllOnRequestPackageSummaryModelsV1 = false;
  let isAllOnlinePurchasePackageSummaryModelsV1 = false;
  _.forEach(selectedProducts, selectedProduct => {
    const isPremiumPackage = productSelections[PACKAGE_PREMIUM_TYPE_KEY[selectedProduct.type]];
    const selectedPackage = productSelections[PACKAGE_SELECTED_KEY[selectedProduct.type]];

    const packageSummaryModel = {
      id: selectedPackage.id,
      name: selectedPackage.packageName
        ? selectedPackage.packageName
        : convertProdTypeToProdName(selectedProduct.type),
      productType: selectedProduct.type,
      premiumPackage: isPremiumPackage,
      onRequest: isPricingV1ProductPackageOnRequest(
        productSelections,
        selectedPackage,
        isPremiumPackage
      )
    };
    packageSummaryModels.push(packageSummaryModel);

    _.forEach(selectedProduct.addons, addon => {
      if (productSelections.selectedAddonIds.indexOf(addon.id) >= 0) {
        let addOnSummaryModel = {
          id: addon.id,
          name: addon.name,
          onRequest: isAddonOnRequest(productSelections, addon)
        };
        addOnSummaryModels.push(addOnSummaryModel);
      }
    });
  });
  if (!_.isEmpty(packageSummaryModels) && _.every(packageSummaryModels, ['onRequest', false])) {
    isAllOnlinePurchasePackageSummaryModelsV1 = true;
  }

  if (!_.isEmpty(packageSummaryModels) && _.every(packageSummaryModels, ['onRequest', true])) {
    isAllOnRequestPackageSummaryModelsV1 = true;
  }

  return {
    packageSummaryModels,
    addOnSummaryModels,
    isAllOnRequestPackageSummaryModelsV1,
    isAllOnlinePurchasePackageSummaryModelsV1
  };
};

export const isPricingV1ProductPackageOnRequest = (
  productSelections,
  productPackage,
  isPremiumPackage
) => {
  if (productSelections.selectedPurchaseRental === PAYMENT_TYPE.PURCHASE) {
    return !!(
      (!productPackage.standardRetailAble && !isPremiumPackage) ||
      (!productPackage.premiumRetailAble && isPremiumPackage)
    );
  } else {
    return !!(
      (!productPackage.standardRentedStatus && !isPremiumPackage) ||
      (!productPackage.premiumRentedStatus && isPremiumPackage)
    );
  }
};

const isAddonOnRequest = (productSelections, addon) => {
  return (
    (productSelections.selectedPurchaseRental === PAYMENT_TYPE.PURCHASE && !addon.retailAble) ||
    (productSelections.selectedPurchaseRental === PAYMENT_TYPE.RENTAL && !addon.rentable)
  );
};

// This function cover all product on request condition check in frontend
export const isPricingV2SelectedProductOnRequest = ({
  currentSelectedPackage,
  selectedProduct
}) => {
  if (!selectedProduct || !currentSelectedPackage) return false;
  return (
    !currentSelectedPackage.packageId ||
    currentSelectedPackage.alwaysShowOnRequest ||
    (currentSelectedPackage.oneTimePrice === ON_REQUEST &&
      currentSelectedPackage.monthlyPrice === ON_REQUEST &&
      currentSelectedPackage.yearlyPrice === ON_REQUEST &&
      currentSelectedPackage.totalPrice === ON_REQUEST) ||
    selectedProduct.showOnRequest
  );
};

/**
 * Check current FE is the case CJ 1.0 FE with pricing 2.0 configuration
 * Affected by only four products (single):
 * Solar
 * Charging station
 * Heating
 * CHP
 *
 * @param products
 * @returns {boolean}
 */
export const isValidCJV1WithPricingV2FE = products => {
  const isSingleProductFE = products.length === 1;
  const isValidProduct = [
    PRODUCT_TYPE.SOLAR,
    PRODUCT_TYPE.CHARGE,
    PRODUCT_TYPE.HEATING,
    PRODUCT_TYPE.CHP,
    PRODUCT_TYPE.SOLAR_B2B,
    PRODUCT_TYPE.CHARGE_B2B
  ].includes(products[0]?.type);

  return isSingleProductFE && isValidProduct;
};

import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import {
  ADD_ON_V2_PRODUCT_TYPE,
  CHARGE_TYPE_LANGUAGE,
  COLOR,
  FRONTEND_TYPE,
  LIMIT_HEIGHT,
  PACKAGE_SELECTED_KEY,
  PAYMENT_TYPE,
  PRODUCT_AVAILABLE_STATUS,
  PRODUCT_HAS_FILTERED_PACKAGE_LABEL,
  PRODUCT_SELECTED_KEY,
  PRODUCT_TYPE,
  PRODUCT_TYPE_CURR_PACKAGE_MAPPING,
  RENTAL,
  SELLING_OPTION_TYPE,
  SERVICE_REQUEST_STATUS,
  SPECIAL_CHARACTER
} from '../../common/constants';
import {
  PricingComponent,
  Product,
  ProductPackage,
  PurchaseRentalSelection,
  TemporaryTotal
} from '.';
import {
  filterPackageWithoutMeterComponent,
  filterSellingOptionByProductAndLabels,
  findCheapestSellingOptionAddOnV2,
  hexaToRGBA,
  isValidCJV1WithPricingV2FE,
  ShowIfUtil
} from '../../common/utils';
import {
  createCurrProductPackages,
  updateCurrentPackage,
  updateCurrPackageManually,
  updateCurrPackageOnRequest,
  updateCurrProductPackageConfig,
  updateCustomerJourneyMapping,
  updateFrontendConfiguration,
  updateProductSelections,
  filterAddOnSellingOptionsByPaymentType,
  removeAllSelectedAddOnV2,
  updateSelectedAddOnV2
} from '../../store/actions';
import { ContactForm } from '../contact-form';
import {
  currentStateSelector,
  housingUnitForHCWaterConsumptionSelector,
  KWConsumptionForElectricitySelector,
  KWConsumptionForGasSelector,
  KWHConsumptionSelector,
  litterPerSecConsumptionSelector,
  meterConsumptionSelector,
  meterStreetFrontLengthForHCWaterConsumptionSelector,
  productSelectionsSelector,
  productsSelector,
  providerProductSelector,
  squareMetrePropertySizeForHCWaterConsumptionSelector,
  squareMetreFloorSizeForHCWaterConsumptionSelector,
  KWConsumptionForHeatingSelector
} from '../../store/selectors';
import { generatePackageConfig } from '../../store/reducers/productPackageConfigReducer';
import { GlobalAddOn } from '../../components/common';

class WebshopContainer extends PureComponent {
  constructor(props) {
    super(props);
    const {
      oneTimeProducts,
      monthlyProducts,
      yearlyProducts
    } = this.categorizeProductBySellingOptionType();
    const { recurringList, isPurchase, isRental } = this.preSelectRentalInfo();
    this.state = {
      isShowProductPackage: false,
      isShowPricingComponent: false,
      isShowContactForm: false,
      selectedProductType: '',
      totalPriceHeight: LIMIT_HEIGHT.DEFAULT_TOTAL_AMOUNT_WRAPPER,
      productsWrapperHeight: LIMIT_HEIGHT.DEFAULT_PRODUCT_WRAPPER,
      extraClientHeight: null,
      oneTimeProducts,
      monthlyProducts,
      yearlyProducts,
      recurringList,
      isPurchase,
      isRental
    };
  }

  componentDidMount() {
    const {
      products,
      kwConsumption,
      kwhConsumption,
      meterConsumption,
      kwConsumptionHCGas,
      kwConsumptionHCHeating,
      productPackageConfig,
      updateCurrentPackage,
      litterPerSecConsumption,
      meterStreetFrontLengthForHCWaterConsumption,
      housingUnitForHCWaterConsumption,
      squareMetrePropertySizeForHCWaterConsumption,
      squareMetreFloorSizeForHCWaterConsumption,
      createCurrProductPackages,
      updateCurrProductPackageConfig,
      currProductPackageConfig
    } = this.props;

    if (_.isEmpty(currProductPackageConfig)) {
      if (Array(products) && products.length === 1) {
        this.handleSelectProduct({ productType: products[0].type, isProductSelected: false });
      }
      createCurrProductPackages({ productPackageConfig, products });
      let packagesInit = this.initProductPackageConfig();
      updateCurrProductPackageConfig(packagesInit);

      updateCurrentPackage({
        kwConsumption,
        kwConsumptionHCGas,
        kwConsumptionHCHeating,
        litterPerSecConsumption,
        kwhConsumption,
        meterConsumption,
        meterStreetFrontLengthForHCWaterConsumption,
        housingUnitForHCWaterConsumption,
        squareMetrePropertySizeForHCWaterConsumption,
        squareMetreFloorSizeForHCWaterConsumption
      });
    }
    this.setScrollHeight(null);
    this.handleWindowResize();
  }

  componentDidUpdate(prevProps) {
    const {
      totalConsumptionMapping,
      kwConsumption,
      litterPerSecConsumption,
      meterConsumption,
      meterStreetFrontLengthForHCWaterConsumption,
      housingUnitForHCWaterConsumption,
      squareMetrePropertySizeForHCWaterConsumption,
      squareMetreFloorSizeForHCWaterConsumption,
      updateCurrentPackage,
      kwhConsumption,
      productPackageConfig,
      kwConsumptionHCGas,
      kwConsumptionHCHeating,
      currMappingLabels,
      currProductPackageConfig,
      updateCurrProductPackageConfig,
      productProvider,
      externalServiceRequestStatus,
      isHouseConnectionProductV2,
      usageOfGasAnswer,
      chargeCarSpeedAnswer,
      chargeTypeAnswer,
      outputHeating,
      filterAddOnSellingOptionsByPaymentType,
      productSelections: { selectedPurchaseRental, selectedRentalInfo },
      products,
      addOnsV2,
      productAddOnConfig
    } = this.props;

    if (!isHouseConnectionProductV2) {
      if (
        prevProps.productSelections.selectedPurchaseRental !== selectedPurchaseRental ||
        prevProps.productSelections.selectedRentalInfo.id !== selectedRentalInfo.id
      ) {
        let tmpProducts = this.getProductAndPackageByCurrentSelectedPaymentType().products;
        _.forEach(tmpProducts, product => {
          let packagesInit = {};
          packagesInit = {
            ...packagesInit,
            [product.type]: {
              ...generatePackageConfig({
                packages: tmpProducts[0].productPackages,
                productType: product.type
              })
            }
          };
          updateCurrProductPackageConfig(packagesInit);
        });
        filterAddOnSellingOptionsByPaymentType({ addOnsV2, paymentType: selectedPurchaseRental });
      }
      if (prevProps.productAddOnConfig !== productAddOnConfig) {
        this.changeSelectedAddOnV2SellingOptionWhenPaymentTypeChange();
      }

      if (prevProps.products !== products) {
        const {
          oneTimeProducts,
          monthlyProducts,
          yearlyProducts
        } = this.categorizeProductBySellingOptionType();
        this.setState({
          oneTimeProducts,
          monthlyProducts,
          yearlyProducts
        });
      }
    }

    if (currProductPackageConfig !== prevProps.currProductPackageConfig) {
      if (isValidCJV1WithPricingV2FE(products)) {
        // CJ 1.0 with pricing 2.0
        this.findAndUpdateMappedPackage();
      } else {
        // normal CJ 2.0
        updateCurrentPackage({
          kwConsumption,
          kwConsumptionHCGas,
          kwConsumptionHCHeating,
          meterConsumption,
          meterStreetFrontLengthForHCWaterConsumption,
          housingUnitForHCWaterConsumption,
          squareMetrePropertySizeForHCWaterConsumption,
          squareMetreFloorSizeForHCWaterConsumption,
          litterPerSecConsumption,
          kwhConsumption
        });
      }
    }

    let _currProductPackageConfig = {};

    if (externalServiceRequestStatus === SERVICE_REQUEST_STATUS.REQUEST_SUCCESS) {
      let finalProductPackageConfig;
      if (_.some(products, product => product.isFarAwayProviderNode)) {
        _currProductPackageConfig = filterPackageWithoutMeterComponent({
          currProductPackageConfig: productPackageConfig,
          products
        });
        finalProductPackageConfig = _currProductPackageConfig;
      } else {
        finalProductPackageConfig = productPackageConfig;
      }
      if (
        prevProps.externalServiceRequestStatus === SERVICE_REQUEST_STATUS.NO_REQUEST ||
        prevProps.meterConsumption !== meterConsumption
      ) {
        updateCurrProductPackageConfig(finalProductPackageConfig);
      }

      if (
        !_.isEqual(currMappingLabels, prevProps.currMappingLabels) ||
        productProvider !== prevProps.productProvider ||
        meterConsumption !== prevProps.meterConsumption
      ) {
        let productTypes = [];
        // use the result of filterPackageWithoutMeterComponent to filter label and product provider if needed
        let _productPackageConfig = _.isEmpty(_currProductPackageConfig)
          ? productPackageConfig
          : _currProductPackageConfig;

        _.forIn(_productPackageConfig, (_value, key) => productTypes.push(key));
        let tmpProductPackageConfig = filterSellingOptionByProductAndLabels({
          productPackageConfig: _productPackageConfig,
          labels: currMappingLabels,
          providers: productProvider,
          productTypes: productTypes
        });
        updateCurrProductPackageConfig(tmpProductPackageConfig);
      }
    } else if (externalServiceRequestStatus === SERVICE_REQUEST_STATUS.NO_REQUEST) {
      if (!_.isEqual(currMappingLabels, prevProps.currMappingLabels)) {
        let productTypes = [];
        _.forIn(productPackageConfig, (_value, key) => productTypes.push(key));
        let tmpProductPackageConfig = filterSellingOptionByProductAndLabels({
          productPackageConfig: productPackageConfig,
          labels: currMappingLabels,
          providers: productProvider,
          productTypes: productTypes
        });
        updateCurrProductPackageConfig(tmpProductPackageConfig);
      } else {
        if (prevProps.externalServiceRequestStatus !== externalServiceRequestStatus) {
          const packageInit = this.initProductPackageConfig();
          updateCurrProductPackageConfig(packageInit);
        }
      }
    }

    if (isValidCJV1WithPricingV2FE(products)) {
      if (
        prevProps.totalConsumptionMapping !== totalConsumptionMapping ||
        prevProps.outputHeating !== outputHeating ||
        prevProps.kwConsumption !== kwConsumption ||
        prevProps.chargeCarSpeedAnswer !== chargeCarSpeedAnswer ||
        prevProps.chargeTypeAnswer !== chargeTypeAnswer ||
        prevProps.kwConsumption !== kwConsumption ||
        (prevProps.usageOfGasAnswer !== usageOfGasAnswer && usageOfGasAnswer !== -1)
      ) {
        this.findAndUpdateMappedPackage();
      }
    } else {
      if (
        prevProps.kwConsumption !== kwConsumption ||
        prevProps.meterConsumption !== meterConsumption ||
        prevProps.meterStreetFrontLengthForHCWaterConsumption !==
          meterStreetFrontLengthForHCWaterConsumption ||
        prevProps.housingUnitForHCWaterConsumption !== housingUnitForHCWaterConsumption ||
        prevProps.squareMetrePropertySizeForHCWaterConsumption !==
          squareMetrePropertySizeForHCWaterConsumption ||
        prevProps.squareMetreFloorSizeForHCWaterConsumption !==
          squareMetreFloorSizeForHCWaterConsumption ||
        prevProps.litterPerSecConsumption !== litterPerSecConsumption ||
        prevProps.kwhConsumption !== kwhConsumption ||
        prevProps.kwConsumptionHCGas !== kwConsumptionHCGas ||
        prevProps.kwConsumptionHCHeating !== kwConsumptionHCHeating
      ) {
        updateCurrentPackage({
          kwConsumption,
          kwConsumptionHCGas,
          kwConsumptionHCHeating,
          meterConsumption,
          meterStreetFrontLengthForHCWaterConsumption,
          housingUnitForHCWaterConsumption,
          squareMetrePropertySizeForHCWaterConsumption,
          squareMetreFloorSizeForHCWaterConsumption,
          litterPerSecConsumption,
          kwhConsumption
        });
      }
    }
  }

  /**
   * When switch between buy and rent payment type, the selected addon selling option should be
   * switch too. Switch the addon selling option if possible or unselect the addon which there isn't
   * any selling option.
   *
   */
  changeSelectedAddOnV2SellingOptionWhenPaymentTypeChange = () => {
    const {
      addOnV2Selections: { selectedAddOnV2MapKeyByProductType },
      productAddOnConfig: { productAddOnsMapKeyByProductType },
      updateSelectedAddOnV2,
      removeAllSelectedAddOnV2
    } = this.props;
    removeAllSelectedAddOnV2();
    _.forIn(selectedAddOnV2MapKeyByProductType, (selectedAddOns, productType) => {
      const productAddOns = productAddOnsMapKeyByProductType[productType];
      if (productAddOns?.length > 0) {
        _.forEach(selectedAddOns, selectedAddOn => {
          const addOnSellingOptions =
            productAddOns.find(productAddOn => productAddOn.id === selectedAddOn.addOnId)
              ?.sellingOptions ?? [];
          if (addOnSellingOptions?.length > 0) {
            updateSelectedAddOnV2({
              productType: productType,
              addOnSellingOption: findCheapestSellingOptionAddOnV2(addOnSellingOptions),
              addOnId: selectedAddOn.addOnId,
              addOnName: selectedAddOn.addOnName
            });
          }
        });
      }
    });
  };

  /**
   * Update matched selling options which be mapped by OLD package mapping configuration logic (CJ 1.0)
   *
   * @param consumption
   * @param productType
   * @param sellingOption
   */
  updateMappedPackage = ({ consumption, productType, sellingOption }) => {
    const { updateCurrPackageManually, currProductPackageConfig } = this.props;
    const { sellingOptionMap } = currProductPackageConfig[productType];
    if (sellingOption) {
      let updateCurrPackageManuallyPayload = {
        productType: productType
      };
      switch (productType) {
        case PRODUCT_TYPE.SOLAR:
          updateCurrPackageManuallyPayload = {
            ...updateCurrPackageManuallyPayload,
            kwhConsumption: consumption,
            sellingOption: sellingOptionMap[sellingOption.id]
          };
          break;
        case PRODUCT_TYPE.CHP:
        case PRODUCT_TYPE.HEATING:
        case PRODUCT_TYPE.CHARGE:
          updateCurrPackageManuallyPayload = {
            ...updateCurrPackageManuallyPayload,
            kwConsumption: consumption,
            sellingOption: sellingOptionMap[sellingOption.id]
          };
          break;
        case PRODUCT_TYPE.SOLAR_B2B:
          updateCurrPackageManuallyPayload = {
            ...updateCurrPackageManuallyPayload,
            kwhConsumption: consumption,
            sellingOption: sellingOptionMap[sellingOption.id]
          };
          break;
        case PRODUCT_TYPE.CHARGE_B2B:
          updateCurrPackageManuallyPayload = {
            ...updateCurrPackageManuallyPayload,
            kwConsumption: consumption,
            sellingOption: sellingOptionMap[sellingOption.id]
          };
          break;
        default:
          break;
      }
      updateCurrPackageManually(updateCurrPackageManuallyPayload);
    }
  };

  /**
   * Handle in case of CJ 2.0 with OLD package mapping logic (CJ 1.0)
   *
   * @param packageMappingConfig
   * @param consumption
   * @param productType
   * @returns {null|*}
   */
  findMappedPackage = ({ packageMappingConfig, consumption, productType }) => {
    const { currProductPackageConfig } = this.props;
    const { packageMap: currentProductPackageMap } = currProductPackageConfig[productType];
    const mappedConfig = _.find(
      packageMappingConfig,
      config => consumption >= Number(config.startRangeValue) && consumption <= Number(config.value)
    );
    if (!mappedConfig) return null;
    return currentProductPackageMap[mappedConfig.selectedPackageId];
  };

  /**
   * Handle in case of CJ 2.0 with OLD package mapping logic (CJ 1.0)
   * Specific CHARGE product
   *
   * @param productType
   * @param chargeTypeAnswer
   */
  findMappedChargePackage = ({ productType }) => {
    const {
      updateCurrPackageManually,
      updateCurrPackageOnRequest,
      currProductPackageConfig,
      chargeTypeAnswer,
      chargeCarSpeedAnswer,
      frontendConfiguration: { packageMappings: packageMappingsConfig }
    } = this.props;
    if (chargeCarSpeedAnswer == null || chargeCarSpeedAnswer === -1) {
      updateCurrPackageOnRequest(PRODUCT_TYPE_CURR_PACKAGE_MAPPING[productType]);
      return;
    }
    const { sellingOptionMap, packageSellingOptionMap } = currProductPackageConfig[productType];
    const packageMappings = packageMappingsConfig[PRODUCT_TYPE.CHARGE]?.packageMappings;
    let language = 'ENGLISH';
    let packageMapping =
      packageMappings[
        chargeTypeAnswer === 0 || chargeTypeAnswer === -1
          ? CHARGE_TYPE_LANGUAGE[0][language]
          : CHARGE_TYPE_LANGUAGE[1][language]
      ];

    if (!packageMapping) {
      language = 'GERMAN';
      packageMapping =
        packageMappings[
          chargeTypeAnswer === 0 || chargeTypeAnswer === -1
            ? CHARGE_TYPE_LANGUAGE[0][language]
            : CHARGE_TYPE_LANGUAGE[1][language]
        ];
    }

    if (!packageMapping) {
      updateCurrPackageOnRequest(PRODUCT_TYPE_CURR_PACKAGE_MAPPING[productType]);
      return;
    }

    const mappedPackageConfig = packageMapping[chargeCarSpeedAnswer];
    if (
      mappedPackageConfig &&
      packageSellingOptionMap[mappedPackageConfig.selectedPackageId]?.length > 0
    ) {
      const { id: sellingOptionId } = packageSellingOptionMap[
        mappedPackageConfig.selectedPackageId
      ][0];
      updateCurrPackageManually({
        productType: productType,
        sellingOption: sellingOptionMap[sellingOptionId],
        kwConsumption: 0
      });
    } else {
      updateCurrPackageOnRequest(PRODUCT_TYPE_CURR_PACKAGE_MAPPING[productType]);
    }
  };

  /**
   * Handle in case of CJ 2.0 with OLD package mapping logic (CJ 1.0)
   */
  findAndUpdateMappedPackage = () => {
    const {
      products,
      updateProductSelections,
      totalConsumptionMapping,
      outputHeating,
      usageOfGasAnswer,
      updateCurrPackageOnRequest,
      kwConsumption,
      frontendConfiguration: { packageMappings }
    } = this.props;

    const currentProduct = products[0];
    if (currentProduct == null) return;

    let packageMappingConfig = null;
    let consumption = null;
    if (currentProduct.type === PRODUCT_TYPE.CHARGE) {
      this.findMappedChargePackage({ productType: PRODUCT_TYPE.CHARGE });
    } else {
      switch (currentProduct.type) {
        case PRODUCT_TYPE.CHP:
          packageMappingConfig =
            packageMappings[currentProduct.type]?.packageMappings?.[
              (usageOfGasAnswer === 0 ? 'Gas' : usageOfGasAnswer === 1 ? 'Other' : null)
            ];
          consumption = outputHeating;
          break;
        case PRODUCT_TYPE.SOLAR:
          packageMappingConfig = packageMappings[currentProduct.type]?.packageMappings?.Default;
          consumption = totalConsumptionMapping;
          break;
        case PRODUCT_TYPE.HEATING:
          packageMappingConfig = packageMappings[currentProduct.type]?.packageMappings?.Default;
          consumption = kwConsumption;
          break;
        case PRODUCT_TYPE.SOLAR_B2B:
          packageMappingConfig = packageMappings[currentProduct.type]?.packageMappings?.Default;
          consumption = totalConsumptionMapping;
          break;
        default:
          break;
      }

      if (packageMappingConfig) {
        const mappedPackage = this.findMappedPackage({
          packageMappingConfig,
          productType: currentProduct.type,
          consumption
        });

        if (mappedPackage?.sellingOptions?.length > 0) {
          // update current package with first selling option in matched package above
          this.updateMappedPackage({
            sellingOption: mappedPackage?.sellingOptions[0],
            productType: currentProduct.type,
            consumption
          });
          if (currentProduct.availableStatus === PRODUCT_AVAILABLE_STATUS.ACTIVATED) {
            updateProductSelections({
              [PRODUCT_SELECTED_KEY[currentProduct.type]]: true,
              [PACKAGE_SELECTED_KEY[currentProduct.type]]: mappedPackage
            });
          }
        } else {
          updateCurrPackageOnRequest(PRODUCT_TYPE_CURR_PACKAGE_MAPPING[currentProduct.type]);
        }
      } else {
        updateCurrPackageOnRequest(PRODUCT_TYPE_CURR_PACKAGE_MAPPING[currentProduct.type]);
      }
    }
  };

  /**
   * Get product and packages by current payment type: Purchase or Rental
   * Only get package and selling option which payment type match with current selected payment type.
   *
   * @returns {{products: *}}
   */
  getProductAndPackageByCurrentSelectedPaymentType = () => {
    const {
      productSelections: { selectedPurchaseRental, selectedRentalInfo }
    } = this.props;
    const { oneTimeProducts, monthlyProducts, yearlyProducts } = this.state;
    let products = [];

    if (
      selectedPurchaseRental === SELLING_OPTION_TYPE.ONE_TIME ||
      selectedPurchaseRental === PAYMENT_TYPE.PURCHASE
    ) {
      products = oneTimeProducts;
    } else {
      if (selectedRentalInfo.durationUnit === RENTAL.MONTH) {
        products = _.cloneDeep(monthlyProducts);
      } else {
        products = _.cloneDeep(yearlyProducts);
      }
      _.forEach(products, product => {
        _.forEach(product.productPackages, productPackage => {
          _.remove(
            productPackage.sellingOptions,
            sellingOption => sellingOption.duration !== selectedRentalInfo.duration
          );
        });
        _.remove(
          product.productPackages,
          productPackage => productPackage.sellingOptions.length < 1
        );
      });
    }

    return { products };
  };

  preSelectRentalInfo = () => {
    const {
      isHouseConnectionProductV2,
      frontendConfiguration: { availablePaymentTypes },
      updateProductSelections
    } = this.props;

    let selectedRentalInfo = {};
    let selectedSellingOptionType = PAYMENT_TYPE.PURCHASE;
    let recurringList = [];
    let isPurchase = false;
    let isRental = false;

    if (!isHouseConnectionProductV2) {
      const defaultPaymentType = _.find(availablePaymentTypes, paymentType => paymentType.chosen);
      if (defaultPaymentType) {
        selectedRentalInfo = {
          ...defaultPaymentType,
          unit: defaultPaymentType.durationUnit,
          id: 1
        };
        selectedSellingOptionType = defaultPaymentType.sellingOptionType;
        if (defaultPaymentType.sellingOptionType === SELLING_OPTION_TYPE.ONE_TIME) {
          isPurchase = true;
        }
        if (defaultPaymentType.sellingOptionType === SELLING_OPTION_TYPE.RECURRING) {
          isRental = true;
        }
        updateProductSelections({
          selectedPurchaseRental: selectedSellingOptionType,
          selectedRentalInfo: selectedRentalInfo
        });
        recurringList = _.map(
          _.filter(
            availablePaymentTypes,
            elem => elem.sellingOptionType === SELLING_OPTION_TYPE.RECURRING
          ),
          (paymentType, i) => {
            return { ...paymentType, id: i + 1, unit: paymentType.durationUnit };
          }
        );
      } else {
        _.forEach(availablePaymentTypes, (paymentType, i) => {
          paymentType.id = i + 1;
          paymentType.unit = paymentType.durationUnit;

          selectedRentalInfo = paymentType;
          selectedSellingOptionType = paymentType.sellingOptionType;
          if (paymentType.sellingOptionType === SELLING_OPTION_TYPE.ONE_TIME) {
            isPurchase = true;
          }
          if (paymentType.sellingOptionType === SELLING_OPTION_TYPE.RECURRING) {
            isRental = true;
            recurringList.push(paymentType);
          }
        });

        if (
          selectedRentalInfo.sellingOptionType === SELLING_OPTION_TYPE.ONE_TIME &&
          recurringList.length > 0
        ) {
          selectedRentalInfo = recurringList[0];
        }

        updateProductSelections({
          selectedPurchaseRental: selectedSellingOptionType,
          selectedRentalInfo: selectedRentalInfo
        });
      }
    }

    return { recurringList, isPurchase, isRental };
  };

  categorizeProductBySellingOptionType = () => {
    const { isHouseConnectionProductV2, products } = this.props;

    let oneTimeProducts = [];
    let monthlyProducts = [];
    let yearlyProducts = [];

    if (!isHouseConnectionProductV2) {
      _.forEach(products, product => {
        let oneTimeProduct = _.assign({}, product);
        oneTimeProduct.productPackages = [];
        let monthlyProduct = _.assign({}, product);
        monthlyProduct.productPackages = [];
        let yearlyProduct = _.assign({}, product);
        yearlyProduct.productPackages = [];

        _.forEach(product.productPackages, productPackage => {
          let oneTimePackage = _.assign({}, productPackage);
          oneTimePackage.sellingOptions = [];
          let monthlyPackage = _.assign({}, productPackage);
          monthlyPackage.sellingOptions = [];
          let yearlyPackage = _.assign({}, productPackage);
          yearlyPackage.sellingOptions = [];

          _.forEach(productPackage.sellingOptions, sellingOption => {
            if (sellingOption.sellingOptionType === SELLING_OPTION_TYPE.ONE_TIME) {
              oneTimePackage.sellingOptions.push(sellingOption);
            }
            if (sellingOption.sellingOptionType === SELLING_OPTION_TYPE.RECURRING) {
              const durationUnit = sellingOption.durationUnit;

              if (durationUnit === RENTAL.MONTH) {
                monthlyPackage.sellingOptions.push(sellingOption);
              }
              if (durationUnit === RENTAL.YEAR) {
                yearlyPackage.sellingOptions.push(sellingOption);
              }
            }
          });

          if (oneTimePackage.sellingOptions.length > 0) {
            oneTimeProduct.productPackages.push(oneTimePackage);
          }
          if (monthlyPackage.sellingOptions.length > 0) {
            monthlyProduct.productPackages.push(monthlyPackage);
          }
          if (yearlyPackage.sellingOptions.length > 0) {
            yearlyProduct.productPackages.push(yearlyPackage);
          }
        });

        if (oneTimeProduct.productPackages.length > 0) {
          oneTimeProducts.push(oneTimeProduct);
        }
        if (monthlyProduct.productPackages.length > 0) {
          monthlyProducts.push(monthlyProduct);
        }
        if (yearlyProduct.productPackages.length > 0) {
          yearlyProducts.push(yearlyProduct);
        }
      });
    }
    return { oneTimeProducts, monthlyProducts, yearlyProducts };
  };

  initProductPackageConfig = () => {
    const { productPackageConfig } = this.props;
    let packagesInit = {};
    _.forEach(PRODUCT_HAS_FILTERED_PACKAGE_LABEL, product => {
      if (productPackageConfig[product]) {
        const packageNoLabels = _.filter(productPackageConfig[product].packages, elem =>
          _.isEmpty(elem.labels)
        );
        packagesInit = {
          ...packagesInit,
          [product]: {
            ...generatePackageConfig({ packages: packageNoLabels, productType: product.type })
          }
        };
      }
    });

    if (_.isEmpty(packagesInit)) {
      packagesInit = {
        ...productPackageConfig
      };
    }

    return packagesInit;
  };

  showProductPackagesComponent = product => {
    this.setState({
      isShowProductPackage: true,
      extraClientHeight: null,
      selectedProductType: product
    });
  };

  showPricingComponent = productType => {
    // reset extraClientHeight when show pricing component
    this.setState({
      isShowPricingComponent: true,
      extraClientHeight: null,
      selectedProductType: productType
    });
  };

  showProductComponent = () => {
    // reset height when show product component
    this.setState(
      {
        isShowProductPackage: false,
        extraClientHeight: null,
        isShowPricingComponent: false
      },
      () => this.setScrollHeight(1)
    );
  };

  backFromContactForm = () => this.setState({ isShowContactForm: false });

  handleSelectProduct = ({ productType, isProductSelected }) => {
    const { updateProductSelections } = this.props;
    updateProductSelections({
      [PRODUCT_SELECTED_KEY[productType]]: !isProductSelected
    });
  };

  handleWindowResize = () => {
    let resizeTimeout;
    window.addEventListener('resize', () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(this.setScrollHeight.bind(null, null), 50);
    });
  };

  setScrollHeight = extraClientHeight => {
    const {
      frontendConfiguration: { isShowPackageInfo },
      isHouseConnectionProductV2
    } = this.props;
    const { isPurchase, isRental } = this.state;

    const { isShowProductPackage, isShowPricingComponent } = this.state;

    let productScrollBarHeight, totalPriceHeight;

    if (this.totalPriceRef) {
      totalPriceHeight = this.totalPriceRef.clientHeight;
    } else {
      totalPriceHeight = 0;
    }

    if (this.productRef) {
      if (extraClientHeight != null && !isShowProductPackage && !isShowPricingComponent) {
        // handle only when show product component
        if (
          parseInt(LIMIT_HEIGHT.DEFAULT_CUSTOMER_JOURNEY_HEIGHT) <
          this.productRef.clientHeight + totalPriceHeight + extraClientHeight
        ) {
          productScrollBarHeight = isShowPackageInfo
            ? parseInt(LIMIT_HEIGHT.DEFAULT_CUSTOMER_JOURNEY_HEIGHT) - totalPriceHeight
            : parseInt(LIMIT_HEIGHT.DEFAULT_WEBSHOPV2_WRAPPER);
        } else {
          if (isHouseConnectionProductV2 || (isPurchase && !isRental)) {
            productScrollBarHeight = this.productRef.clientHeight + extraClientHeight;
          } else {
            productScrollBarHeight = this.productRef.clientHeight + extraClientHeight + 200;
          }
          if (productScrollBarHeight > parseInt(LIMIT_HEIGHT.DEFAULT_PRODUCT_WRAPPER)) {
            productScrollBarHeight = parseInt(LIMIT_HEIGHT.DEFAULT_PRODUCT_WRAPPER) + 40;
          }
        }
      } else {
        if (
          parseInt(LIMIT_HEIGHT.DEFAULT_CUSTOMER_JOURNEY_HEIGHT) <
          this.productRef.clientHeight + totalPriceHeight
        ) {
          productScrollBarHeight = isShowPackageInfo
            ? parseInt(LIMIT_HEIGHT.DEFAULT_CUSTOMER_JOURNEY_HEIGHT) - totalPriceHeight
            : parseInt(LIMIT_HEIGHT.DEFAULT_WEBSHOPV2_WRAPPER);
        } else {
          if (isHouseConnectionProductV2 || (isPurchase && !isRental)) {
            productScrollBarHeight = this.productRef.clientHeight + 5;
          } else {
            productScrollBarHeight = this.productRef.clientHeight + 200;
          }
          if (productScrollBarHeight > parseInt(LIMIT_HEIGHT.DEFAULT_PRODUCT_WRAPPER)) {
            productScrollBarHeight = parseInt(LIMIT_HEIGHT.DEFAULT_PRODUCT_WRAPPER) + 40;
          }
        }
      }
    }

    if (extraClientHeight != null) {
      this.setState({
        // eslint-disable-next-line react/no-access-state-in-setstate
        ...this.state,
        extraClientHeight: extraClientHeight,
        productsWrapperHeight: productScrollBarHeight,
        totalPriceHeight: totalPriceHeight
      });
    } else {
      this.setState({
        // eslint-disable-next-line react/no-access-state-in-setstate
        ...this.state,
        productsWrapperHeight: productScrollBarHeight,
        totalPriceHeight: totalPriceHeight
      });
    }
  };

  setHeightPricingComponentView = pricingComponentHeight => {
    const { totalPriceHeight } = this.state;
    if (
      pricingComponentHeight + totalPriceHeight >
      parseInt(LIMIT_HEIGHT.DEFAULT_CUSTOMER_JOURNEY_HEIGHT)
    ) {
      this.setState({
        productsWrapperHeight:
          parseInt(LIMIT_HEIGHT.DEFAULT_CUSTOMER_JOURNEY_HEIGHT) - totalPriceHeight
      });
    } else {
      this.setState({
        productsWrapperHeight: pricingComponentHeight
      });
    }
  };

  setHeightProductPackageComponentView = productPackageComponentHeight => {
    const { isHouseConnectionProductV2 } = this.props;
    let productsWrapperHeight =
      productPackageComponentHeight > parseInt(LIMIT_HEIGHT.DEFAULT_CUSTOMER_JOURNEY_HEIGHT)
        ? parseInt(LIMIT_HEIGHT.DEFAULT_CUSTOMER_JOURNEY_HEIGHT)
        : productPackageComponentHeight;

    if (!isHouseConnectionProductV2) {
      productsWrapperHeight += 50;
    }

    this.setState({
      productsWrapperHeight
    });
  };

  renderProducts = () => {
    const {
      fontText,
      fontTextBold,
      frontendConfiguration,
      currState,
      productSelections,
      isHouseConnectionProductV2
    } = this.props;
    let { products } = this.props;
    let clonedProducts = _.cloneDeep(products);

    if (!isHouseConnectionProductV2) {
      clonedProducts = this.getProductAndPackageByCurrentSelectedPaymentType().products;
    }

    const {
      defaultTextColor,
      primaryColor,
      primaryTextColor,
      borderRadiusMax4px
    } = frontendConfiguration;

    const uiStyle = {
      primaryColor,
      defaultTextColor,
      primaryTextColor,
      borderRadiusMax4px,
      fontText,
      fontTextBold,
      textColor: COLOR.DARK_GRAY
    };

    return (
      <>
        {_.map(clonedProducts, product => {
          const currentPackage = currState[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[product.type]];
          const isProductSelected = productSelections[PRODUCT_SELECTED_KEY[product.type]];
          const isProductAvailable = product.availableStatus === PRODUCT_AVAILABLE_STATUS.ACTIVATED;
          return (
            <Product
              key={product.id}
              product={product}
              productId={product.id}
              productType={product.type}
              uiStyle={uiStyle}
              isProductSelected={isProductSelected}
              isProductAvailable={isProductAvailable}
              currentPackage={currentPackage}
              showProductPackageComp={this.showProductPackagesComponent}
              showPricingComp={this.showPricingComponent}
              selectProduct={this.handleSelectProduct}
              setScrollHeight={this.setScrollHeight}
            />
          );
        })}
        <GlobalAddOn
          uiStyle={uiStyle}
          productType={ADD_ON_V2_PRODUCT_TYPE.GLOBAL}
          setScrollHeight={this.setScrollHeight}
        />
      </>
    );
  };

  renderPricingComponent = () => {
    const {
      fontText,
      fontTextBold,
      frontendConfiguration,
      currState,
      productsWrapperHeight: productsWrapperHeightProp
    } = this.props;
    const { selectedProductType, totalPriceHeight } = this.state;
    const currentPackage = currState[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[selectedProductType]] || {};
    return (
      <PricingComponent
        frontendConfiguration={frontendConfiguration}
        pricingComponents={currentPackage.pricingComponents}
        showProductComponent={this.showProductComponent}
        fontText={fontText}
        fontTextBold={fontTextBold}
        setScrollHeight={this.setHeightPricingComponentView}
        pricingComponentHeight={
          frontendConfiguration.frontendType === FRONTEND_TYPE.WEBSHOP_V2
            ? productsWrapperHeightProp
            : (
                parseInt(LIMIT_HEIGHT.DEFAULT_WEBSHOPV2_WRAPPER) - parseInt(totalPriceHeight)
              ).toString()
        }
      />
    );
  };

  renderProductPackageComponent = () => {
    const { fontText, fontTextBold } = this.props;
    const { selectedProductType } = this.state;

    return (
      <ProductPackage
        productType={selectedProductType}
        showProductComponent={this.showProductComponent}
        fontText={fontText}
        fontTextBold={fontTextBold}
        setScrollHeight={this.setHeightProductPackageComponentView}
      />
    );
  };

  render() {
    const {
      fontText,
      fontTextBold,
      frontendConfiguration,
      routeToConnectErrorPage,
      createOpportunities,
      children,
      linkColor,
      isHouseConnectionProductV2
    } = this.props;

    const {
      isShowContactForm,
      isShowProductPackage,
      isShowPricingComponent,
      productsWrapperHeight,
      recurringList,
      isPurchase,
      isRental
    } = this.state;

    const temporaryTotalComponent = (
      <TemporaryTotal
        fontText={fontText}
        fontTextBold={fontTextBold}
        setScrollHeight={this.setScrollHeight}
      />
    );
    let style = {
      background: COLOR.TRANSPARENT
    };

    if (frontendConfiguration.frontendType === FRONTEND_TYPE.WEBSHOP) {
      style = {
        background: hexaToRGBA(
          frontendConfiguration.backgroundColor,
          frontendConfiguration.backgroundOpacity / 100
        )
      };
    }

    const totalPriceWebshop = {
      padding: '20px'
    };

    const totalPriceCustomerJourneyStyle = {
      backgroundColor: frontendConfiguration.secondaryColor,
      borderRadius: frontendConfiguration.borderRadiusMax4px
    };

    return (
      <div className="container-fluid ep-webshoppage-wrapper ep--pb-0" style={style}>
        <ShowIfUtil condition={!isShowContactForm}>
          <div className="row ep--p-0">
            <div
              className="col-12 ep--px-8 ep-webshop-scroll-block overflow-auto"
              style={{ height: productsWrapperHeight }}
            >
              <ShowIfUtil condition={!(isHouseConnectionProductV2 || (isPurchase && !isRental))}>
                <PurchaseRentalSelection
                  prepareTotalAmountCalculation={() => ({})}
                  fontText={fontText}
                  fontTextBold={fontTextBold}
                  isPurchase={isPurchase}
                  isRental={isRental}
                  recurringList={recurringList}
                />
              </ShowIfUtil>
              <div
                ref={productRef => {
                  if (productRef) {
                    this.productRef = productRef;
                  }
                }}
              >
                <ShowIfUtil condition={!isShowProductPackage && !isShowPricingComponent}>
                  {this.renderProducts()}
                </ShowIfUtil>
                <ShowIfUtil condition={isShowPricingComponent}>
                  {this.renderPricingComponent()}
                </ShowIfUtil>
                <ShowIfUtil condition={isShowProductPackage}>
                  {this.renderProductPackageComponent()}
                </ShowIfUtil>

                <ShowIfUtil
                  condition={frontendConfiguration.frontendType === FRONTEND_TYPE.WEBSHOP}
                >
                  <div className="d-flex flex-column" style={totalPriceWebshop}>
                    {temporaryTotalComponent}
                  </div>
                </ShowIfUtil>
              </div>
            </div>

            <div
              className="col-12 ep--px-8"
              ref={totalPriceRef => {
                if (totalPriceRef) {
                  this.totalPriceRef = totalPriceRef;
                }
              }}
            >
              <ShowIfUtil
                condition={
                  frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2 &&
                  frontendConfiguration.isShowPackageInfo &&
                  !isShowProductPackage
                }
              >
                <div
                  className="d-flex flex-column align-items-center justify-content-center ep--mt-8"
                  style={totalPriceCustomerJourneyStyle}
                >
                  {temporaryTotalComponent}
                  {children}
                </div>
              </ShowIfUtil>
            </div>
          </div>
        </ShowIfUtil>

        <ShowIfUtil condition={isShowContactForm}>
          <ContactForm
            routeToConnectErrorPage={routeToConnectErrorPage}
            createOpportunities={createOpportunities}
            backToSelectProduct={this.backFromContactForm}
            fontText={fontText}
            fontTextBold={fontTextBold}
            linkColor={linkColor}
          />
        </ShowIfUtil>
      </div>
    );
  }
}

WebshopContainer.propTypes = {
  updateSelectedAddOnV2: PropTypes.func.isRequired,
  removeAllSelectedAddOnV2: PropTypes.func.isRequired,
  filterAddOnSellingOptionsByPaymentType: PropTypes.func.isRequired,
  updateCurrPackageOnRequest: PropTypes.func.isRequired,
  updateCurrPackageManually: PropTypes.func.isRequired,
  createCurrProductPackages: PropTypes.func.isRequired,
  updateCurrProductPackageConfig: PropTypes.func.isRequired,
  updateCurrentPackage: PropTypes.func.isRequired,
  routeToConnectErrorPage: PropTypes.func.isRequired,
  createOpportunities: PropTypes.func,
  updateProductSelections: PropTypes.func,
  totalConsumptionMapping: PropTypes.number.isRequired,
  kwConsumption: PropTypes.string.isRequired,
  externalServiceRequestStatus: PropTypes.string.isRequired,
  kwConsumptionHCGas: PropTypes.string.isRequired,
  kwConsumptionHCHeating: PropTypes.string.isRequired,
  litterPerSecConsumption: PropTypes.string.isRequired,
  meterConsumption: PropTypes.string.isRequired,
  meterStreetFrontLengthForHCWaterConsumption: PropTypes.string.isRequired,
  housingUnitForHCWaterConsumption: PropTypes.string.isRequired,
  squareMetrePropertySizeForHCWaterConsumption: PropTypes.string.isRequired,
  squareMetreFloorSizeForHCWaterConsumption: PropTypes.string.isRequired,
  kwhConsumption: PropTypes.string.isRequired,
  productPackageConfig: PropTypes.object.isRequired,
  currProductPackageConfig: PropTypes.object.isRequired,
  productAddOnConfig: PropTypes.object.isRequired,
  addOnV2Selections: PropTypes.object.isRequired,
  currState: PropTypes.object.isRequired,
  usageOfGasAnswer: PropTypes.number.isRequired,
  chargeTypeAnswer: PropTypes.number.isRequired,
  chargeCarSpeedAnswer: PropTypes.number.isRequired,
  outputHeating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  fontText: PropTypes.object,
  fontTextBold: PropTypes.object,
  linkColor: PropTypes.string,
  frontendConfiguration: PropTypes.object,
  productSelections: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  products: PropTypes.array,
  addOnsV2: PropTypes.array,
  currMappingLabels: PropTypes.object.isRequired,
  productsWrapperHeight: PropTypes.string,
  productProvider: PropTypes.object,
  isHouseConnectionProductV2: PropTypes.bool
};

WebshopContainer.defaultProps = {
  createOpportunities: () => ({}),
  fontText: {},
  fontTextBold: {},
  linkColor: COLOR.DEFAULT_BLUE,
  frontendConfiguration: {},
  updateProductSelections: () => ({}),
  productSelections: {},
  children: `<div></div>`,
  addOnsV2: [],
  products: [],
  productsWrapperHeight: SPECIAL_CHARACTER.EMPTY,
  productProvider: {},
  isHouseConnectionProductV2: false
};

const mapStateToProps = state => {
  const currState = currentStateSelector(state);
  const { currProductPackageConfig, currMappingLabels } = currState;
  const productProvider = providerProductSelector(state);
  const {
    usageOfGas: usageOfGasAnswer,
    chargeType: chargeTypeAnswer,
    chargeCarSpeed: chargeCarSpeedAnswer
  } = state.customerJourneyAnswer;
  const { outputHeating } = state.customerJourneyMapping;
  const { addOnsV2 } = state.frontendConfiguration;
  return {
    totalConsumptionMapping: state.customerJourneyMapping.totalConsumption,
    frontendConfiguration: state.frontendConfiguration,
    productSelections: productSelectionsSelector(state),
    products: productsSelector(state),
    kwConsumption: KWConsumptionForElectricitySelector(state),
    kwConsumptionHCGas: KWConsumptionForGasSelector(state),
    kwConsumptionHCHeating: KWConsumptionForHeatingSelector(state),
    litterPerSecConsumption: litterPerSecConsumptionSelector(state),
    meterConsumption: meterConsumptionSelector(state),
    meterStreetFrontLengthForHCWaterConsumption: meterStreetFrontLengthForHCWaterConsumptionSelector(
      state
    ),
    usageOfGasAnswer,
    chargeTypeAnswer,
    chargeCarSpeedAnswer,
    outputHeating,
    housingUnitForHCWaterConsumption: housingUnitForHCWaterConsumptionSelector(state),
    squareMetrePropertySizeForHCWaterConsumption: squareMetrePropertySizeForHCWaterConsumptionSelector(
      state
    ),
    squareMetreFloorSizeForHCWaterConsumption: squareMetreFloorSizeForHCWaterConsumptionSelector(
      state
    ),
    kwhConsumption: KWHConsumptionSelector(state),
    productPackageConfig: state.productPackageConfig,
    productAddOnConfig: state.productAddOnConfig,
    addOnV2Selections: state.addOnV2Selections,
    currProductPackageConfig,
    currMappingLabels,
    currState,
    productProvider,
    addOnsV2,
    externalServiceRequestStatus: state.externalProductAvailability.externalServiceRequestStatus
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateProductSelections,
        updateCustomerJourneyMapping,
        updateFrontendConfiguration,
        updateCurrentPackage,
        createCurrProductPackages,
        updateCurrProductPackageConfig,
        updateCurrPackageManually,
        updateCurrPackageOnRequest,
        filterAddOnSellingOptionsByPaymentType,
        removeAllSelectedAddOnV2,
        updateSelectedAddOnV2
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WebshopContainer);

import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import axios from 'axios';
import { COLOR, FRONTEND_TYPE } from '../../common/constants';
import { ShowIfUtil } from '../../common/utils';
import { StyledButton } from '../../components/epilot-forms/common';

class AttachmentsContainer extends PureComponent {
  componentWillReceiveProps = nextProps => {
    const { isOnlinePurchase, setContactFormHeight } = this.props;

    if (nextProps.isOnlinePurchase !== isOnlinePurchase) {
      setTimeout(() => {
        setContactFormHeight();
      }, 100);
    }
  };

  downloadFile = (url, originalFileName) => {
    axios({
      url: url,
      method: 'GET',
      responseType: 'blob'
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', originalFileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  };

  renderAttachment(attachment, index) {
    const { isOnlinePurchase, font, textColor, frontendConfiguration } = this.props;

    return (
      <ShowIfUtil
        condition={
          (isOnlinePurchase && attachment.checkoutOnlinePurchase) ||
          (!isOnlinePurchase && attachment.checkoutOrderRequest)
        }
        key={index}
      >
        <div className="d-flex align-items-center">
          <i
            className="ep-icon-attach-87 ep__txt--lg ep--mr-8"
            style={{ color: frontendConfiguration.defaultTextColor }}
          />
          <StyledButton
            className="border-0 ep-bg-transparent ep-cursor-pointer ep__txt--md ep__txt-regular"
            style={{
              color:
                frontendConfiguration.frontendType === FRONTEND_TYPE.CONTACT_FORM
                  ? frontendConfiguration.buttonBackground
                  : textColor
            }}
            onClick={() => this.downloadFile(attachment.url, attachment.originalFileName)}
            font={font}
          >
            {attachment.displayName}
          </StyledButton>
        </div>
      </ShowIfUtil>
    );
  }

  render() {
    const { listAttachment } = this.props;

    return (
      <div>
        {listAttachment.map((attachment, index) => {
          return this.renderAttachment(attachment, index);
        })}
      </div>
    );
  }
}

AttachmentsContainer.propTypes = {
  listAttachment: PropTypes.array,
  isOnlinePurchase: PropTypes.bool,
  font: PropTypes.object,
  textColor: PropTypes.string,
  setContactFormHeight: PropTypes.func,
  frontendConfiguration: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
};

AttachmentsContainer.defaultProps = {
  listAttachment: [],
  isOnlinePurchase: false,
  font: {},
  textColor: COLOR.DARK_GRAY,
  setContactFormHeight: () => {},
  frontendConfiguration: {}
};

export default AttachmentsContainer;

import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { Collapse, Position, Tooltip } from '@blueprintjs/core';
import _ from 'lodash';
import $ from 'jquery';
import {
  updateCurrPackageManually,
  updateProductSelections,
  updateSelectedAddon
} from '../../store/actions';
import {
  ATTRIBUTE,
  COLOR,
  GERMAN_TEXT,
  RENTAL,
  SELLING_OPTION,
  SELLING_OPTION_TYPE,
  SPECIAL_CHARACTER
} from '../../common/constants';
import {
  KWConsumptionForElectricitySelector,
  KWConsumptionForGasSelector,
  KWConsumptionForHeatingSelector,
  KWHConsumptionSelector,
  litterPerSecConsumptionSelector,
  meterConsumptionSelector,
  meterStreetFrontLengthForHCWaterConsumptionSelector,
  housingUnitForHCWaterConsumptionSelector,
  squareMetrePropertySizeForHCWaterConsumptionSelector,
  squareMetreFloorSizeForHCWaterConsumptionSelector,
  productSelectionsSelector
} from '../../store/selectors';
import {
  DropDown,
  PackageRadioButton,
  StyledSpan,
  StyledSpanBold
} from '../../components/epilot-forms';
import { formatPrice, ShowIfUtil } from '../../common/utils';

const DescriptionText = styled.div`
  margin-top: 5px;
  margin-left: 42px;
  margin-right: 20px;
  text-align: justify;
`;

class PackageContainer extends PureComponent {
  constructor(props) {
    super(props);
    const { sellingOptionObject, selectedSellingOption } = this.getSelectedSellingOption();

    this.state = {
      isOpen: false,
      selectedSellingOption: selectedSellingOption,
      selectedSellingOptionObject: sellingOptionObject,
      sellingOptionObject: sellingOptionObject,
      sellingOptionId: props.sellingOptionId
    };
  }

  componentDidMount = () => {
    $('.pt-collapse').addClass('pt-description-wrap');
    this.setBackgroundColor();
  };

  componentDidUpdate = () => {
    const { sellingOptionObject } = this.getSelectedSellingOption();
    this.setState({
      selectedSellingOptionObject: sellingOptionObject
    });
    this.setBackgroundColor();
  };

  setBackgroundColor = () => {
    const { frontendConfiguration, isSelectedPackage, productPackage } = this.props;
    const radio = document.getElementsByClassName('package-' + productPackage[ATTRIBUTE.ID]);

    for (let node of radio[0].childNodes) {
      if (node.className === 'pt-control-indicator') {
        if (isSelectedPackage) {
          node.style.backgroundColor =
            frontendConfiguration.primaryColor !== '#ffffff'
              ? frontendConfiguration.primaryColor
              : COLOR.BLACK;
        } else {
          node.style.backgroundColor = COLOR.WHITE;
        }
        break;
      }
    }
  };

  getSelectedSellingOption = () => {
    const {
      productPackage: { sellingOptions },
      sellingOptionId
    } = this.props;
    let selectedSellingOption = SPECIAL_CHARACTER.EMPTY;
    let sellingOptionObject = {};
    if (_.isArray(sellingOptions) && sellingOptions.length > 0) {
      sellingOptionObject =
        _.find(sellingOptions, item => item.id === sellingOptionId) || sellingOptions[0];
      selectedSellingOption = sellingOptionObject && sellingOptionObject.title;
    }
    return {
      selectedSellingOption,
      sellingOptionObject
    };
  };

  selectPackage = selectedSellingOptionMap => {
    const {
      productType,
      updateCurrPackageManually,
      kwConsumption,
      litterPerSecConsumption,
      meterConsumption,
      meterStreetFrontLengthForHCWaterConsumption,
      housingUnitForHCWaterConsumption,
      squareMetrePropertySizeForHCWaterConsumption,
      squareMetreFloorSizeForHCWaterConsumption,
      kwhConsumption,
      kwConsumptionHCGas,
      kwConsumptionHCHeating
    } = this.props;
    updateCurrPackageManually({
      productType,
      packageId: selectedSellingOptionMap.packageId,
      sellingOptionId: selectedSellingOptionMap.sellingOptionId,
      name: selectedSellingOptionMap.packageName,
      sellingOption: selectedSellingOptionMap,
      kwConsumption,
      litterPerSecConsumption,
      meterConsumption,
      meterStreetFrontLengthForHCWaterConsumption,
      housingUnitForHCWaterConsumption,
      squareMetrePropertySizeForHCWaterConsumption,
      squareMetreFloorSizeForHCWaterConsumption,
      kwhConsumption,
      kwConsumptionHCGas,
      kwConsumptionHCHeating
    });
  };

  updateCurrentSellingOption = () => {
    const {
      updateCurrPackageManually,
      productType,
      kwConsumption,
      sellingOptionMap,
      litterPerSecConsumption,
      meterConsumption,
      meterStreetFrontLengthForHCWaterConsumption,
      housingUnitForHCWaterConsumption,
      squareMetrePropertySizeForHCWaterConsumption,
      squareMetreFloorSizeForHCWaterConsumption,
      kwhConsumption,
      kwConsumptionHCGas,
      kwConsumptionHCHeating
    } = this.props;
    const { sellingOptionId } = this.state;
    updateCurrPackageManually({
      productType,
      packageId: sellingOptionMap[sellingOptionId].packageId,
      name: sellingOptionMap[sellingOptionId].packageName,
      sellingOptionId,
      sellingOption: sellingOptionMap[sellingOptionId],
      kwConsumption,
      litterPerSecConsumption,
      meterStreetFrontLengthForHCWaterConsumption,
      housingUnitForHCWaterConsumption,
      squareMetrePropertySizeForHCWaterConsumption,
      squareMetreFloorSizeForHCWaterConsumption,
      meterConsumption,
      kwhConsumption,
      kwConsumptionHCGas,
      kwConsumptionHCHeating
    });
  };

  handleClick = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });

    Array.from(document.getElementsByClassName('ep-icon-minimal-up')).forEach(function(element) {
      element.click();
    });
  };

  displayPricingSellingOption = data => {
    const { frontendConfiguration, currProductPackage, fontText } = this.props;
    const textColor = {
      color: frontendConfiguration.defaultTextColor
    };

    return (
      <div>
        {currProductPackage[data.sellingOptionId] &&
          _.isNumber(currProductPackage[data.sellingOptionId].oneTimePrice) &&
          currProductPackage[data.sellingOptionId].oneTimePrice !== 0 && (
            <div>
              <StyledSpan
                font={fontText}
                className="ep-ellipsis ep__txt--sm ep__txt-regular ep__txt-no-select"
                style={textColor}
              >
                {`${formatPrice(currProductPackage[data.sellingOptionId].oneTimePrice)}`}
              </StyledSpan>
            </div>
          )}
        {currProductPackage[data.sellingOptionId] &&
          _.isNumber(currProductPackage[data.sellingOptionId].monthlyPrice) &&
          currProductPackage[data.sellingOptionId].monthlyPrice !== 0 && (
            <div>
              <StyledSpan
                font={fontText}
                className="ep-ellipsis ep__txt--sm ep__txt-regular ep__txt-no-select"
                style={textColor}
              >
                {`${
                  _.isNumber(currProductPackage[data.sellingOptionId].oneTimePrice) &&
                  currProductPackage[data.sellingOptionId].oneTimePrice !== 0
                    ? currProductPackage[data.sellingOptionId].monthlyPrice > 0
                      ? SPECIAL_CHARACTER.PLUS
                      : SPECIAL_CHARACTER.MINUS
                    : SPECIAL_CHARACTER.EMPTY
                } ${formatPrice(Math.abs(currProductPackage[data.sellingOptionId].monthlyPrice))}${
                  RENTAL.PER_MONTH
                }`}
              </StyledSpan>
            </div>
          )}
        {currProductPackage[data.sellingOptionId] &&
          _.isNumber(currProductPackage[data.sellingOptionId].yearlyPrice) &&
          currProductPackage[data.sellingOptionId].yearlyPrice !== 0 && (
            <div>
              <StyledSpan
                font={fontText}
                className="ep-ellipsis ep__txt--sm ep__txt-regular ep__txt-no-select"
                style={textColor}
              >
                {`${
                  (_.isNumber(currProductPackage[data.sellingOptionId].oneTimePrice) &&
                    currProductPackage[data.sellingOptionId].oneTimePrice !== 0) ||
                  (_.isNumber(currProductPackage[data.sellingOptionId].monthlyPrice) &&
                    currProductPackage[data.sellingOptionId].monthlyPrice !== 0)
                    ? currProductPackage[data.sellingOptionId].yearlyPrice > 0
                      ? SPECIAL_CHARACTER.PLUS
                      : SPECIAL_CHARACTER.MINUS
                    : SPECIAL_CHARACTER.EMPTY
                } ${formatPrice(Math.abs(currProductPackage[data.sellingOptionId].yearlyPrice))}${
                  RENTAL.PER_YEAR
                }`}
              </StyledSpan>
            </div>
          )}
      </div>
    );
  };

  displayPerformance = data => {
    const { frontendConfiguration, fontText } = this.props;
    const textColor = {
      color: frontendConfiguration.defaultTextColor
    };

    return (
      <div>
        {_.isArray(data) && data.length > 0 ? (
          data.map((performance, i) => {
            return (
              <div key={i}>
                <StyledSpan
                  font={fontText}
                  className="ep-ellipsis ep__txt--sm ep__txt-regular ep__txt-no-select"
                  style={textColor}
                >
                  {performance.name}: {performance.value ? performance.value : '-'}
                </StyledSpan>
              </div>
            );
          })
        ) : (
          <StyledSpan
            font={fontText}
            className="ep-ellipsis ep__txt--sm ep__txt-regular ep__txt-no-select"
            style={textColor}
          >
            -
          </StyledSpan>
        )}
      </div>
    );
  };

  handleSelectedSellingOption = ({
    sellingOptions,
    selectedSellingOption,
    sellingOptionType,
    selectedSellingOptionMap
  }) => {
    const { sellingOptionMap } = this.props;

    const sellingOptionObject =
      _.find(sellingOptions, item => item.title === selectedSellingOption) || sellingOptions[0];
    let sellingOptionId = null;
    let packagePricingComponents = null;

    if (sellingOptionObject) {
      sellingOptionId = sellingOptionObject.id;
      sellingOptionType = sellingOptionObject.sellingOptionType;
      packagePricingComponents = sellingOptionObject.packagePricingComponents;
      selectedSellingOptionMap = sellingOptionMap[sellingOptionObject.id];
    }

    return {
      sellingOptionId,
      sellingOptionType,
      packagePricingComponents,
      selectedSellingOptionMap,
      sellingOptionObject
    };
  };

  displayTextBySellingOptionType = sellingOptionType => {
    const { sellingOptionObject } = this.state;

    return sellingOptionType === SELLING_OPTION_TYPE.RECURRING
      ? `${GERMAN_TEXT.RECURRING_PAYMENT}, ${sellingOptionObject.duration} ${
          SELLING_OPTION[sellingOptionObject.durationUnit] === RENTAL.MONTHS
            ? GERMAN_TEXT.MONTHLY
            : GERMAN_TEXT.YEARLY
        }`
      : GERMAN_TEXT.ONE_TIME_PAYMENT;
  };

  formatSellingOptionTitle = sellingOption => {
    if (sellingOption) {
      const detail =
        sellingOption.sellingOptionType === SELLING_OPTION_TYPE.RECURRING
          ? `${sellingOption.duration} ${
              SELLING_OPTION[sellingOption.durationUnit] === RENTAL.MONTHS
                ? GERMAN_TEXT.MONTHLY
                : GERMAN_TEXT.YEARLY
            }`
          : GERMAN_TEXT.ONE_TIME_PAYMENT;
      return `${sellingOption.title} (${detail})`;
    }
  };

  render() {
    const {
      fontText,
      fontTextBold,
      productPackage,
      isSelectedPackage,
      sellingOptionMap,
      frontendConfiguration
    } = this.props;

    const { isOpen, selectedSellingOption, selectedSellingOptionObject } = this.state;
    const textColor = { color: frontendConfiguration.defaultTextColor };

    let performances = productPackage.performances;
    let sellingOptionsData = [];
    let sellingOptionType = SPECIAL_CHARACTER.EMPTY;
    let selectedSellingOptionMap = {};
    const { sellingOptions } = productPackage;

    if (_.isArray(sellingOptions) && sellingOptions.length > 0) {
      sellingOptionType = sellingOptions[0].sellingOptionType;
      sellingOptionsData = _.map(sellingOptions, 'title');
      if (selectedSellingOption) {
        sellingOptionType = this.handleSelectedSellingOption({
          sellingOptions,
          selectedSellingOption,
          sellingOptionType,
          selectedSellingOptionMap
        }).sellingOptionType;

        selectedSellingOptionMap = this.handleSelectedSellingOption({
          sellingOptions,
          selectedSellingOption,
          sellingOptionType,
          selectedSellingOptionMap
        }).selectedSellingOptionMap;
      } else {
        selectedSellingOptionMap = sellingOptionMap[sellingOptions[0].id];
      }
    }
    return (
      <div className="container-fluid">
        <div className="d-flex justify-content-between flex-nowrap ep--mt-16">
          <div className="d-flex justify-content-start w-82">
            <div className="justify-content-end align-items-start ep--pt-8">
              {/* RADIO BUTTON */}
              <PackageRadioButton
                className={`package-${productPackage[ATTRIBUTE.ID]}`}
                onChange={() => this.selectPackage(selectedSellingOptionMap)}
                name="package"
                isChecked={isSelectedPackage}
              />
            </div>

            <div className="ep--ml-16 w-100">
              {/* PACKAGE NAME */}
              <Tooltip
                content={
                  <StyledSpan font={fontText} style={textColor}>
                    {productPackage.packageName}
                  </StyledSpan>
                }
                position={Position.BOTTOM}
                className="mw-100"
                tooltipClassName="ep-tooltip ep-tooltip-width-300"
              >
                <StyledSpanBold
                  className="ep__txt--md ep__txt-bold ep__txt-no-select ep-ellipsis d-block"
                  font={fontTextBold}
                  style={textColor}
                >
                  {productPackage.packageName}
                </StyledSpanBold>
              </Tooltip>
              <ShowIfUtil condition={!!sellingOptionsData}>
                <div className="ep--pt-8">
                  <div className="row ep--mr-0">
                    {/* SELECT SELLING OPTION */}
                    <div className="col-7 ep--pr-0">
                      <DropDown
                        onItemSelect={value => {
                          this.setState(
                            {
                              selectedSellingOption: value.title,
                              selectedSellingOptionObject: value,
                              sellingOptionId: this.handleSelectedSellingOption({
                                sellingOptions,
                                selectedSellingOption: value.title
                              }).sellingOptionId,
                              sellingOptionObject: this.handleSelectedSellingOption({
                                sellingOptions,
                                selectedSellingOption: value.title
                              }).sellingOptionObject
                            },
                            () => {
                              this.updateCurrentSellingOption();
                            }
                          );
                        }}
                        borderRadius="8px"
                        selectedItem={selectedSellingOptionObject}
                        options={sellingOptions}
                        itemKey="id"
                        itemLabel={this.formatSellingOptionTitle}
                        isObjectOptions
                        isWebshopV2
                        borderColorFocus={frontendConfiguration.primaryColor}
                        defaultTextColor={frontendConfiguration.defaultTextColor}
                        fontFamily={fontText}
                        styleClassText="ep__txt--sm ep__txt-regular ep__txt-regular"
                        usePortal
                      />
                    </div>
                    {/* SELLING OPTION TYPE */}
                    <div className="d-flex col-5 align-items-center">
                      <Tooltip
                        content={
                          <StyledSpan font={fontText} style={textColor} className="ep-ellipsis">
                            {this.displayTextBySellingOptionType(sellingOptionType)}
                          </StyledSpan>
                        }
                        position={Position.BOTTOM}
                        className="mw-100"
                        tooltipClassName="ep-tooltip"
                      >
                        <StyledSpan
                          font={fontText}
                          style={textColor}
                          className="ep-ellipsis ep__txt--sm ep__txt-regular"
                        >
                          {this.displayTextBySellingOptionType(sellingOptionType)}
                        </StyledSpan>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </ShowIfUtil>

              {/* PERFORMANCES */}
              <div>
                <div className="ep--pt-8 ep-ellipsis">
                  <div className="d-flex justify-content-start ep-white-space-nowrap">
                    <i className="ep-icon-dashboard-half ep__txt--sm ep--mr-8" style={textColor} />
                    {this.displayPerformance(performances)}
                  </div>
                </div>
              </div>

              {/* SELLING OPTION PRICE */}
              <ShowIfUtil condition={!!this.displayPricingSellingOption(selectedSellingOptionMap)}>
                <div className="ep--pt-8 ep-ellipsis">
                  <div className="d-flex justify-content-start ep-white-space-nowrap">
                    <i className="ep-icon-money-coins ep__txt--sm ep--mr-8" style={textColor} />
                    <ShowIfUtil condition={!selectedSellingOptionMap.alwaysShowOnRequest}>
                      {this.displayPricingSellingOption(selectedSellingOptionMap)}
                    </ShowIfUtil>
                    <ShowIfUtil condition={selectedSellingOptionMap.alwaysShowOnRequest}>
                      <StyledSpan
                        className="ep-ellipsis ep__txt--sm ep__txt-regular ep__txt-no-select"
                        font={fontText}
                        style={textColor}
                      >
                        {GERMAN_TEXT.ON_REQUEST}
                      </StyledSpan>
                    </ShowIfUtil>
                  </div>
                </div>
              </ShowIfUtil>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-end ep--ml-8">
            <i
              className={`ep--ml-8 ep--pt-8 ${
                isOpen
                  ? 'ep-icon-minimal-up ep__txt--sm ep-position-top-3 ep-cursor-pointer'
                  : 'ep-icon-minimal-down ep__txt--sm ep-position-top-3 ep-cursor-pointer'
              }`}
              style={textColor}
              onClick={this.handleClick}
            />
          </div>
        </div>

        <DescriptionText className="d-flex flex-row">
          <div className="justify-content-start align-items-start">
            <Collapse isOpen={isOpen} keepChildrenMounted>
              <div className="d-flex">
                <i className="ep-icon-alert-circle-i ep__txt--sm" style={textColor} />
                <StyledSpan
                  className="ep__txt--sm ep__txt-regular ep--pl-8 ep-opacity-50 ep__txt-no-select"
                  font={fontText}
                  style={textColor}
                >
                  {productPackage.description ? productPackage.description : '-'}
                </StyledSpan>
              </div>
            </Collapse>
          </div>
        </DescriptionText>
      </div>
    );
  }
}

PackageContainer.propTypes = {
  updateCurrPackageManually: PropTypes.func.isRequired,
  productType: PropTypes.string,
  productPackage: PropTypes.object.isRequired,
  isSelectedPackage: PropTypes.bool,
  fontText: PropTypes.object,
  fontTextBold: PropTypes.object,
  frontendConfiguration: PropTypes.object,
  sellingOptionMap: PropTypes.object,
  sellingOptionId: PropTypes.number,
  kwConsumption: PropTypes.string.isRequired,
  litterPerSecConsumption: PropTypes.string,
  meterConsumption: PropTypes.string.isRequired,
  meterStreetFrontLengthForHCWaterConsumption: PropTypes.string,
  housingUnitForHCWaterConsumption: PropTypes.string,
  squareMetrePropertySizeForHCWaterConsumption: PropTypes.string,
  squareMetreFloorSizeForHCWaterConsumption: PropTypes.string,
  kwConsumptionHCGas: PropTypes.string.isRequired,
  kwConsumptionHCHeating: PropTypes.string.isRequired,
  kwhConsumption: PropTypes.string.isRequired,
  currProductPackage: PropTypes.object
};

PackageContainer.defaultProps = {
  fontText: {},
  fontTextBold: {},
  frontendConfiguration: {},
  isSelectedPackage: false,
  sellingOptionMap: {},
  sellingOptionId: 0,
  productType: SPECIAL_CHARACTER.EMPTY,
  litterPerSecConsumption: '0.00',
  meterStreetFrontLengthForHCWaterConsumption: '0',
  housingUnitForHCWaterConsumption: '0',
  squareMetrePropertySizeForHCWaterConsumption: '0',
  squareMetreFloorSizeForHCWaterConsumption: '0',
  currProductPackage: {}
};

function mapStateToProps(state) {
  return {
    frontendConfiguration: state.frontendConfiguration,
    productSelections: productSelectionsSelector(state),
    kwConsumption: KWConsumptionForElectricitySelector(state),
    kwConsumptionHCGas: KWConsumptionForGasSelector(state),
    kwConsumptionHCHeating: KWConsumptionForHeatingSelector(state),
    litterPerSecConsumption: litterPerSecConsumptionSelector(state),
    meterConsumption: meterConsumptionSelector(state),
    meterStreetFrontLengthForHCWaterConsumption: meterStreetFrontLengthForHCWaterConsumptionSelector(
      state
    ),
    housingUnitForHCWaterConsumption: housingUnitForHCWaterConsumptionSelector(state),
    squareMetrePropertySizeForHCWaterConsumption: squareMetrePropertySizeForHCWaterConsumptionSelector(
      state
    ),
    squareMetreFloorSizeForHCWaterConsumption: squareMetreFloorSizeForHCWaterConsumptionSelector(
      state
    ),
    kwhConsumption: KWHConsumptionSelector(state)
  };
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateProductSelections,
        updateSelectedAddon,
        updateCurrPackageManually
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PackageContainer);

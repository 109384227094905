import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Radio } from '@blueprintjs/core';
import { COLOR, SPECIAL_CHARACTER } from '../../../common/constants';
import { StyledSpan } from './StyledElement';

class RadioLabelComponent extends Component {
  constructor(props) {
    super(props);
    const { isChecked } = this.props;
    this.state = {
      checked: isChecked
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ checked: nextProps.isChecked });
  }

  onChange = e => {
    const { onChange } = this.props;
    const { checked } = this.state;
    this.setState({
      checked: !checked
    });
    onChange(e);
  };

  render() {
    const {
      className,
      name,
      value,
      fontFamily,
      titleFont,
      defaultTextColor,
      content,
      disabled
    } = this.props;
    const { checked } = this.state;
    return (
      <Radio
        className={className}
        name={name}
        value={value}
        checked={checked}
        onChange={this.onChange}
        disabled={disabled}
      >
        <StyledSpan
          font={fontFamily}
          className={titleFont || 'ep__txt--sm ep__txt-regular'}
          style={{ color: defaultTextColor }}
        >
          {content}
        </StyledSpan>
      </Radio>
    );
  }
}

RadioLabelComponent.propTypes = {
  className: PropTypes.string,
  isChecked: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  fontFamily: PropTypes.object,
  titleFont: PropTypes.string,
  defaultTextColor: PropTypes.string,
  content: PropTypes.string,
  disabled: PropTypes.bool
};

RadioLabelComponent.defaultProps = {
  className: SPECIAL_CHARACTER.EMPTY,
  isChecked: false,
  name: SPECIAL_CHARACTER.EMPTY,
  onChange: function() {},
  value: '',
  fontFamily: {},
  titleFont: '',
  defaultTextColor: COLOR.DARK_GRAY,
  content: '',
  disabled: false
};

export default RadioLabelComponent;

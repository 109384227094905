import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { SPECIAL_CHARACTER } from '../constants';

export class ScrollUtil extends Component {
  handleUpdate = values => {
    const { showShadowBottom } = this.props;

    if (showShadowBottom) {
      const { scrollTop, scrollHeight, clientHeight } = values;
      const bottomScrollTop = scrollHeight - clientHeight;
      // eslint-disable-next-line react/no-string-refs
      this.refs.shadowBottom.style.opacity =
        (1 / 20) * (bottomScrollTop - Math.max(scrollTop, bottomScrollTop - 20));
    }
  };

  render() {
    const { height, marginLeft, marginRight, children, showShadowBottom } = this.props;
    const shadowBottomStyle = {
      bottom: 0,
      left: 0,
      right: 0,
      height: 10,
      background: 'linear-gradient(to top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%)'
    };

    return (
      <>
        <Scrollbars
          style={{
            width: 'auto',
            height: height.includes('px') ? height : height + 'px', //Note ===== Improve: Check Unit
            marginLeft: marginLeft,
            marginRight: marginRight
          }}
          onUpdate={this.handleUpdate}
          universal
        >
          {children}
        </Scrollbars>

        <div
          className={`${showShadowBottom ? '' : 'd-none'}`}
          style={shadowBottomStyle}
          ref="shadowBottom"
        />
      </>
    );
  }
}

ScrollUtil.propTypes = {
  height: PropTypes.string,
  marginLeft: PropTypes.string,
  marginRight: PropTypes.string,
  showShadowBottom: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.array
  ])
};

ScrollUtil.defaultProps = {
  height: '100px',
  marginLeft: SPECIAL_CHARACTER.EMPTY,
  marginRight: SPECIAL_CHARACTER.EMPTY,
  showShadowBottom: false
};

import {
  ErrorPage,
  FrontendPage,
  LaunchingSoonPage,
  NotFoundPage,
  SuccessPage,
  ThankYouPage,
  CancelPage,
  UnsupportedBrowserPage,
  ExceedRateLimitPage
} from '../pages';
import {
  CONNECT_ERROR_PAGE,
  FRONTENDS_PAGE,
  LAUNCHING_SOON_PAGE,
  NOT_FOUND_PAGE,
  SUCCESS_PAGE,
  THANK_YOU_SCREEN_PAGE,
  CANCEL_SCREEN_PAGE,
  UNSUPPORTED_BROWSER_PAGE,
  EXCEED_RATE_LIMIT_PAGE
} from '../common/constants';

export default [
  {
    component: FrontendPage,
    exact: true,
    path: FRONTENDS_PAGE
  },
  {
    component: LaunchingSoonPage,
    exact: true,
    path: LAUNCHING_SOON_PAGE
  },
  {
    component: SuccessPage,
    exact: true,
    path: SUCCESS_PAGE
  },
  {
    component: ErrorPage,
    exact: true,
    path: CONNECT_ERROR_PAGE
  },
  {
    component: ThankYouPage,
    exact: true,
    path: THANK_YOU_SCREEN_PAGE
  },
  {
    component: CancelPage,
    exact: true,
    path: CANCEL_SCREEN_PAGE
  },
  {
    component: ExceedRateLimitPage,
    exact: true,
    path: EXCEED_RATE_LIMIT_PAGE
  },
  {
    component: NotFoundPage,
    exact: true,
    path: NOT_FOUND_PAGE
  },
  {
    component: UnsupportedBrowserPage,
    exact: true,
    path: UNSUPPORTED_BROWSER_PAGE
  }
];

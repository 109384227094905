import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { ProgressBar } from '@blueprintjs/core';
import { connect } from 'react-redux';

class ProgressBarContainer extends PureComponent {
  componentDidMount() {
    const { frontendConfiguration } = this.props;
    let progressMeterElem = document.getElementsByClassName('pt-progress-meter');
    let progressBarElem = document.getElementsByClassName('pt-progress-bar');
    setTimeout(() => {
      if (progressMeterElem[0]) {
        progressMeterElem[0].style.backgroundColor = frontendConfiguration.primaryColor;
        progressMeterElem[0].style.borderRadius = frontendConfiguration.borderRadius;
      }
      if (progressBarElem[0]) {
        progressBarElem[0].style.borderRadius = frontendConfiguration.borderRadius;
      }
    }, 1);
  }

  render() {
    const { currentQuestionIndex, lastQuestionIndex } = this.props;
    let progress = (currentQuestionIndex + 1) / (lastQuestionIndex + 1);
    return (
      <div>
        <ProgressBar className="ep-height-8" animate={false} stripes={false} value={progress} />
      </div>
    );
  }
}

ProgressBarContainer.propTypes = {
  currentQuestionIndex: PropTypes.number,
  lastQuestionIndex: PropTypes.number,
  frontendConfiguration: PropTypes.object
};

ProgressBarContainer.defaultProps = {
  currentQuestionIndex: 0,
  lastQuestionIndex: 1,
  frontendConfiguration: {}
};

const mapStateToProps = state => ({
  frontendConfiguration: state.frontendConfiguration
});

export default connect(mapStateToProps)(ProgressBarContainer);

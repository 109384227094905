export const CHARGE_SPEED = {
  SLOW: 0,
  MEDIUM: 1,
  FAST: 2,
  PROPERTIES: {
    0: { ENGLISH: 'Slow', GERMAN: 'Langsam' },
    1: { ENGLISH: 'Medium', GERMAN: 'Schnell' },
    2: { ENGLISH: 'Fast', GERMAN: 'Sehr schnell' }
  }
};

export const CHARGE_SPEED_LEVELS = {
  TWO_LEVELS: 2,
  THREE_LEVELS: 3
};

export const POWER_FLAT_RATE = {
  GREY_POWER: 0,
  GREEN_POWER: 1,
  PROPERTIES: {
    0: { ENGLISH: 'Grey Power', GERMAN: 'Graustrom Flat' },
    1: { ENGLISH: 'Green Power', GERMAN: 'Grünstrom Flat' }
  }
};

export const CHP_TYPE = {
  GAS: 0,
  OTHER: 1,
  PROPERTIES: {
    0: { ENGLISH: 'Gas', GERMAN: 'Gas' },
    1: { ENGLISH: 'Other', GERMAN: 'Andere' }
  }
};

export const USAGE_OF_ELECTRIC_CAR = {
  YES: 0,
  NO: 1
};

export const USAGE_OF_GAS = {
  YES: 0,
  NO: 1
};

export const KNOWING_OF_HEATING_OUTPUT = {
  YES: 0,
  NO: 1
};

export const HEATING_ITEM = {
  SINGLE_FAMILY_HOUSE: 'Single-family house',
  APARTMENT_BUILDING: 'Apartment building',
  OTHER: 'Other'
};

export const WHERE_TO_HEAT = {
  SINGLE_FAMILY_HOUSE: 0,
  APARTMENT_BUILDING: 1,
  OTHER: 2,
  PROPERTIES: {
    0: { ENGLISH: 'Single-family house', GERMAN: '' },
    1: { ENGLISH: 'Apartment building', GERMAN: 'Mehrfamilienhaus' },
    2: { ENGLISH: 'Other', GERMAN: 'Andere' }
  }
};

export const HEATING_LIVING_SPACE = {
  FIRST_LEVEL_MAX_SIZE: 'First level max size',
  SECOND_LEVEL_MAX_SIZE: 'Second level max size',
  THIRD_LEVEL_MAX_SIZE: 'Third level max size'
};

export const HEATING_BUILT_YEAR = {
  AFTER_2002: '0',
  BETWEEN_1995_2001: '1',
  BETWEEN_1984_1994: '2',
  BETWEEN_1978_1983: '3',
  BEFORE_1977: '4'
};

export const HOUSE_CONNECTION_NEW_OR_CHANGE = {
  NEW: 0,
  CHANGE: 1
};

export const PRIVATELY_OR_BUSINESS = {
  PRIVATELY: 0,
  BUSINESS: 1
};

export const HOUSE_CONNECTION_PREPARATION_WATER_HEATERS = {
  YES: 0,
  NO: 1
};

export const HOUSE_CONNECTION_BUILDING_TYPE = {
  SINGLE_FAMILY_HOUSE: 0,
  APARTMENT_BUILDING: 1,
  BROADBAND_CABLE_REPEATER: 2,
  OTHER: 3
};

export const HOUSE_CONNECTION_CELLAR = {
  YES: 0,
  NO: 1
};

export const HOUSE_CONNECTION_IS_OWNER = {
  YES: 0,
  NO: 1
};

export const HOUSE_CONNECTION_IS_NEW_BUILDING = {
  YES: 0,
  NO: 1
};

export const HOUSE_CONNECTION_ELECTRICITY_FEED_INTO_GRID = {
  YES: 0,
  NO: 1
};

export const HOUSE_CONNECTION_EXTINGUISHING_WATER_DEMAND = {
  YES: 0,
  NO: 1
};

export const HOUSE_CONNECTION_E_MOBILITY = {
  YES: 0,
  NO: 1
};

export const CHARGE_TYPE = {
  CHARGING_STATION: {
    index: 0,
    name: 'Charging station'
  },
  WALLBOX: {
    index: 1,
    name: 'Wallbox'
  }
};

export const CHARGE_TYPE_LANGUAGE = {
  0: { ENGLISH: 'Charging station', GERMAN: 'Ladesäule' },
  1: { ENGLISH: 'Wallbox', GERMAN: 'Wallbox' }
};

export const CHARGE_CAR_SPEED = {
  SLOW: {
    index: 0,
    name: 'Slow'
  },
  MEDIUM: {
    index: 1,
    name: 'Medium'
  },
  FAST: {
    index: 2,
    name: 'Fast'
  }
};

export const RENTAL_UNIT = {
  MONTH: 0,
  YEAR: 1
};

export const PACKAGE_TYPE = {
  STANDARD: 0,
  PREMIUM: 1
};

export const PURCHASE_TYPE = {
  REQUEST: 0,
  ONLINE_PURCHASE: 1
};

export const BUILDING_TYPE = {
  RESIDENTIAL: 'Residential',
  RESIDENTIAL_GERMAN: 'Wohngebäude',
  INDUSTRIAL: 'Industrial/Commercial',
  INDUSTRIAL_GERMAN: 'Industrie- / Gewerbegebäude'
};

export const CURRENCY = {
  EURO: 'EURO',
  CENT: 'CENT'
};

import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as _ from 'lodash';
import {
  getPreSignedUrl,
  GET_PRE_SIGNED_URL_FAILURE,
  updatePreSignedUrls,
  updateUploadFileErrors,
  updateUploadFileStatus,
  uploadFilePreSignedUrl,
  UPLOAD_FILE_PRE_SIGNED_URL_FAILURE
} from '../../store/actions';
import {
  generateUploadFileData,
  isValidFileName,
  isValidFileSize,
  retryMakeHttpRequest
} from '../utils';

export const useUploadFile = ({ question, uploadedFileInfo }) => {
  const dispatch = useDispatch();
  const [invalidFileNames, setInvalidFileNames] = useState([]);
  const [invalidSizeFileNames, setInvalidSizeFileNames] = useState([]);
  const [invalidTypeFileNames, setInvalidTypeFileNames] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [isMultipleUpload, setIsMultipleUpload] = useState(false);
  const [failedUploadedFileNames, setFailedUploadedFileNames] = useState([]);
  const [successUploadedFileInfo, setSuccessUploadedFileInfo] = useState([]);

  /**
   * Return list promise of upload file to s3 bucket action
   *
   * @param preSignedUrlData
   * @param files
   * @returns {Promise[]}
   */
  const _uploadFileUsingPreSignUrlPromise = useCallback(
    ({ preSignedUrlData, files }) => {
      return _.map(files, file => {
        return dispatch(
          uploadFilePreSignedUrl({
            endpoint:
              _.find(preSignedUrlData, data => {
                if (typeof data.originalFileName === 'string' && typeof file.name === 'string') {
                  return data.originalFileName?.localeCompare(file.name) === 0;
                }
                return false;
              })?.uploadURL || '',
            file: file,
            contentType: file.type || 'text/plain'
          })
        );
      });
    },
    [dispatch]
  );

  /**
   * Return list promise of get S3 pre-signed url action
   *
   * @param uploadFileData
   * @returns {Promise[]}
   */
  const _getPreSignedUrlPromise = useCallback(
    ({ uploadFileData }) => {
      return _.map(uploadFileData, data => {
        return dispatch(
          getPreSignedUrl({
            ...data
          })
        );
      });
    },
    [dispatch]
  );

  /**
   * @param approvedFiles
   * @param rejectedFiles
   * @returns uploadFiles[]
   */
  const onDrop = (approvedFiles, rejectedFiles) => {
    let tmpUploadFiles = [];
    let invalidFileNames = [];
    let invalidSizeFileNames = [];
    let invalidTypeFileNames = [];

    _.forEach(approvedFiles, file => {
      if (!isValidFileSize(file)) {
        invalidSizeFileNames.push(file.name);
        return;
      }

      if (!isValidFileName(uploadedFileInfo, file)) {
        invalidFileNames.push(file.name);
        return;
      }
      if (question?.id) {
        file.questionId = question.id;
      }

      if (isMultipleUpload) {
        tmpUploadFiles.push(file);
      } else {
        tmpUploadFiles = [file];
      }
    });

    rejectedFiles?.length > 0 &&
      invalidTypeFileNames.concat(..._.map(rejectedFiles, rejectedFile => rejectedFile.name));

    invalidFileNames?.length > 0 && setInvalidFileNames(invalidFileNames);
    invalidSizeFileNames?.length > 0 && setInvalidSizeFileNames(invalidSizeFileNames);
    invalidTypeFileNames?.length > 0 && setInvalidTypeFileNames(invalidTypeFileNames);
    tmpUploadFiles?.length > 0 && setUploadFiles(tmpUploadFiles);
  };

  /**
   *
   * @param files
   * @param uploadFileData
   */
  const _handleUploadFileUsingPreSignedUrl = useCallback(
    async ({ files, uploadFileData }) => {
      const successUploadedFileInfo = [];
      try {
        dispatch(
          updateUploadFileStatus({
            isUploading: true
          })
        );
        const preSignedUrlResponses = await retryMakeHttpRequest({
          callback: _getPreSignedUrlPromise,
          data: { uploadFileData },
          retryCount: 0,
          errorType: GET_PRE_SIGNED_URL_FAILURE
        });

        dispatch(updatePreSignedUrls(_.map(preSignedUrlResponses, response => response.payload)));

        for (const file of files) {
          try {
            await retryMakeHttpRequest({
              callback: _uploadFileUsingPreSignUrlPromise,
              data: {
                files: [file],
                preSignedUrlData: _.map(preSignedUrlResponses, response => response.payload)
              },
              retryCount: 0,
              errorType: UPLOAD_FILE_PRE_SIGNED_URL_FAILURE
            });
            successUploadedFileInfo.push({
              name: file.name,
              type: `.${file.name?.split('.')?.pop()}`,
              size: file.size,
              questionId: question?.id || 0
            });
          } catch (error) {
            setFailedUploadedFileNames(prevState => [...prevState, file.name]);
            if (question?.id) {
              error.questionId = question?.id;
            }
            dispatch(updateUploadFileErrors(error));
          }
        }
      } catch (error) {
        setFailedUploadedFileNames(prevState => [...prevState, ..._.map(files, file => file.name)]);
        if (question?.id) {
          error.questionId = question?.id;
        }
        dispatch(updateUploadFileErrors(error));
      } finally {
        dispatch(
          updateUploadFileStatus({
            isUploading: false
          })
        );
        successUploadedFileInfo?.length > 0 && setSuccessUploadedFileInfo(successUploadedFileInfo);
        setTimeout(() => {
          setFailedUploadedFileNames([]);
        }, 5000);
      }
    },
    [_getPreSignedUrlPromise, _uploadFileUsingPreSignUrlPromise, question, dispatch]
  );

  // Reset invalid file error
  useEffect(() => {
    if (invalidFileNames?.length > 0) {
      setTimeout(() => {
        setInvalidFileNames([]);
      }, 4000);
    }
    if (invalidSizeFileNames?.length > 0) {
      setTimeout(() => {
        setInvalidSizeFileNames([]);
      }, 4000);
    }
    if (invalidTypeFileNames?.length > 0) {
      setTimeout(() => {
        setInvalidTypeFileNames([]);
      }, 4000);
    }
  }, [invalidFileNames, invalidSizeFileNames, invalidTypeFileNames]);

  // Upload file
  useEffect(() => {
    if (uploadFiles.length > 0) {
      _handleUploadFileUsingPreSignedUrl({
        files: uploadFiles,
        uploadFileData: generateUploadFileData({ files: uploadFiles })
      });
    }
  }, [uploadFiles, _handleUploadFileUsingPreSignedUrl]);

  return {
    invalidFileNames,
    invalidSizeFileNames,
    invalidTypeFileNames,
    failedUploadedFileNames,
    successUploadedFileInfo,
    setIsMultipleUpload,
    setSuccessUploadedFileInfo,
    onDrop
  };
};

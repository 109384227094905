export const COUNTRY = {
  ANDORRA: {
    englishName: 'Andorra',
    germanName: 'Andorra',
    code: 'AD'
  },
  UNITED_ARAB_EMIRATES: {
    englishName: 'United Arab Emirates',
    germanName: 'Vereinigte Arabische Emirate',
    code: 'AE'
  },
  AFGHANISTAN: {
    englishName: 'Afghanistan',
    germanName: 'Afghanistan',
    code: 'AF'
  },
  ANTIGUA_AND_BARBUDA: {
    englishName: 'Antigua and Barbuda',
    germanName: 'Antigua/Barbuda',
    code: 'AG'
  },
  ANGUILLA: {
    englishName: 'Anguilla',
    germanName: 'Anguilla',
    code: 'AI'
  },
  ALBANIA: {
    englishName: 'Albania',
    germanName: 'Albanien',
    code: 'AL'
  },
  ARMENIA: {
    englishName: 'Armenia',
    germanName: 'Armenien',
    code: 'AM'
  },
  NETHERLANDS_ANTILLES: {
    englishName: 'Netherlands Antilles',
    germanName: 'Niederländische Antillen',
    code: 'AN'
  },
  ANGOLA: {
    englishName: 'Angola',
    germanName: 'Angola',
    code: 'AO'
  },
  ANTARCTICA: {
    englishName: 'Antarctica',
    germanName: 'Antarktis',
    code: 'AQ'
  },
  ARGENTINA: {
    englishName: 'Argentina',
    germanName: 'Argentinien',
    code: 'AR'
  },
  AMERICAN_SAMOA: {
    englishName: 'American Samoa',
    germanName: 'Amerikanisch-Samoa',
    code: 'AS'
  },
  AUSTRIA: {
    englishName: 'Austria',
    germanName: 'Österreich',
    code: 'AT'
  },
  AUSTRALIA: {
    englishName: 'Australia',
    germanName: 'Australien',
    code: 'AU'
  },
  ARUBA: {
    englishName: 'Aruba',
    germanName: 'Aruba',
    code: 'AW'
  },
  ALAND_ISLANDS: {
    englishName: 'Åland Islands',
    germanName: 'Åland',
    code: 'AX'
  },
  AZERBAIJAN: {
    englishName: 'Azerbaijan',
    germanName: 'Aserbaidschan',
    code: 'AZ'
  },
  BOSNIA_AND_HERZEGOVINA: {
    englishName: 'Bosnia and Herzegovina',
    germanName: 'Bosnien/Herzegowina',
    code: 'BA'
  },
  BARBADOS: {
    englishName: 'Barbados',
    germanName: 'Barbados',
    code: 'BB'
  },
  BANGLADESH: {
    englishName: 'Bangladesh',
    germanName: 'Bangladesch',
    code: 'BD'
  },
  BELGIUM: {
    englishName: 'Belgium',
    germanName: 'Belgien',
    code: 'BE'
  },
  BURKINA_FASO: {
    englishName: 'Burkina Faso',
    germanName: 'Burkina Faso',
    code: 'BF'
  },
  BULGARIA: {
    englishName: 'Bulgaria',
    germanName: 'Bulgarien',
    code: 'BG'
  },
  BAHRAIN: {
    englishName: 'Bahrain',
    germanName: 'Bahrain',
    code: 'BH'
  },
  BURUNDI: {
    englishName: 'Burundi',
    germanName: 'Burundi',
    code: 'BI'
  },
  BENIN: {
    englishName: 'Benin',
    germanName: 'Benin',
    code: 'BJ'
  },
  SAINT_BARTHELEMY: {
    englishName: 'Saint Barthélemy',
    germanName: 'St. Barthélemy',
    code: 'BL'
  },
  BERMUDA: {
    englishName: 'Bermuda',
    germanName: 'Bermuda',
    code: 'BM'
  },
  BRUNEI_DARUSSALAM: {
    englishName: 'Brunei Darussalam',
    germanName: 'Brunei Darussalam',
    code: 'BN'
  },
  BOLIVIA_PLURINATIONAL_STATE_OF: {
    englishName: 'Bolivia, Plurinational State of',
    germanName: 'Bolivien',
    code: 'BO'
  },
  BONAIRE_SINT_EUSTATIUS_AND_SABA: {
    englishName: 'Bonaire, Sint Eustatius and Saba',
    germanName: 'Bonaire, Sint Eustatius und Saba',
    code: 'BQ'
  },
  BRAZIL: {
    englishName: 'Brazil',
    germanName: 'Brasilien',
    code: 'BR'
  },
  BAHAMAS: {
    englishName: 'Bahamas',
    germanName: 'Bahamas',
    code: 'BS'
  },
  BHUTAN: {
    englishName: 'Bhutan',
    germanName: 'Bhutan',
    code: 'BT'
  },
  BOUVET_ISLAND: {
    englishName: 'Bouvet Island',
    germanName: 'Bouvetinsel',
    code: 'BV'
  },
  BOTSWANA: {
    englishName: 'Botswana',
    germanName: 'Botsuana',
    code: 'BW'
  },
  BELARUS: {
    englishName: 'Belarus',
    germanName: 'Weißrussland',
    code: 'BY'
  },
  BELIZE: {
    englishName: 'Belize',
    germanName: 'Belize',
    code: 'BZ'
  },
  CANADA: {
    englishName: 'Canada',
    germanName: 'Kanada',
    code: 'CA'
  },
  COCOS__KEELING__ISLANDS: {
    englishName: 'Cocos (Keeling) Islands',
    germanName: 'Kokosinseln',
    code: 'CC'
  },
  CENTRAL_AFRICAN_REPUBLIC: {
    englishName: 'Central African Republic',
    germanName: 'Zentralafrikanische Republik',
    code: 'CF'
  },
  CONGO: {
    englishName: 'Congo',
    germanName: 'Kongo',
    code: 'CG'
  },
  SWITZERLAND: {
    englishName: 'Switzerland',
    germanName: 'Schweiz',
    code: 'CH'
  },
  COTE_DIVOIRE: {
    englishName: "Côte d'Ivoire",
    germanName: 'Elfenbeinküste',
    code: 'CI'
  },
  COOK_ISLANDS: {
    englishName: 'Cook Islands',
    germanName: 'Cookinseln',
    code: 'CK'
  },
  CHILE: {
    englishName: 'Chile',
    germanName: 'Chile',
    code: 'CL'
  },
  CAMEROON: {
    englishName: 'Cameroon',
    germanName: 'Kamerun',
    code: 'CM'
  },
  CHINA: {
    englishName: 'China',
    germanName: 'China',
    code: 'CN'
  },
  COLOMBIA: {
    englishName: 'Colombia',
    germanName: 'Kolumbien',
    code: 'CO'
  },
  COSTA_RICA: {
    englishName: 'Costa Rica',
    germanName: 'Costa Rica',
    code: 'CR'
  },
  SERBIA_AND_MONTENEGRO: {
    englishName: 'Serbia and Montenegro',
    germanName: 'Serbien und Montenegro',
    code: 'CS'
  },
  CUBA: {
    englishName: 'Cuba',
    germanName: 'Cuba',
    code: 'CU'
  },
  CAPE_VERDE: {
    englishName: 'Cape Verde',
    germanName: 'Cabo Verde',
    code: 'CV'
  },
  CURACAO: {
    englishName: 'Curaçao',
    germanName: 'Curaçao',
    code: 'CW'
  },
  CHRISTMAS_ISLAND: {
    englishName: 'Christmas Island',
    germanName: 'Weihnachtsinsel',
    code: 'CX'
  },
  CYPRUS: {
    englishName: 'Cyprus',
    germanName: 'Zypern ',
    code: 'CY'
  },
  CZECH_REPUBLIC: {
    englishName: 'Czech Republic',
    germanName: 'Tschechoslowakei',
    code: 'CZ'
  },
  GERMANY: {
    englishName: 'Germany',
    germanName: 'Deutschland',
    code: 'DE'
  },
  DJIBOUTI: {
    englishName: 'Djibouti',
    germanName: 'Dschibuti',
    code: 'DJ'
  },
  DENMARK: {
    englishName: 'Denmark',
    germanName: 'Dänemark',
    code: 'DK'
  },
  DOMINICA: {
    englishName: 'Dominica',
    germanName: 'Dominica',
    code: 'DM'
  },
  DOMINICAN_REPUBLIC: {
    englishName: 'Dominican Republic',
    germanName: 'Dominikanische Republik',
    code: 'DO'
  },
  ALGERIA: {
    englishName: 'Algeria',
    germanName: 'Algerien',
    code: 'DZ'
  },
  ECUADOR: {
    englishName: 'Ecuador',
    germanName: 'Ecuador',
    code: 'EC'
  },
  ESTONIA: {
    englishName: 'Estonia',
    germanName: 'Estland',
    code: 'EE'
  },
  EGYPT: {
    englishName: 'Egypt',
    germanName: 'Ägypten',
    code: 'EG'
  },
  WESTERN_SAHARA: {
    englishName: 'Western Sahara',
    germanName: 'Westsahara',
    code: 'EH'
  },
  ERITREA: {
    englishName: 'Eritrea',
    germanName: 'Eritrea',
    code: 'ER'
  },
  SPAIN: {
    englishName: 'Spain',
    germanName: 'Spanien',
    code: 'ES'
  },
  ETHIOPIA: {
    englishName: 'Ethiopia',
    germanName: 'Äthiopien',
    code: 'ET'
  },
  FINLAND: {
    englishName: 'Finland',
    germanName: 'Finnland',
    code: 'SF'
  },
  FIJI: {
    englishName: 'Fiji',
    germanName: 'Fidschi',
    code: 'FJ'
  },
  FALKLAND_ISLANDS_MALVINAS: {
    englishName: 'Falkland Islands (Malvinas)',
    germanName: 'Falklandinseln',
    code: 'FK'
  },
  MICRONESIA_FEDERATED_STATES_OF: {
    englishName: 'Micronesia, Federated States of',
    germanName: 'Mikronesien',
    code: 'FM'
  },
  FAROE_ISLANDS: {
    englishName: 'Faroe Islands',
    germanName: 'Färöer',
    code: 'FO'
  },
  FRANCE: {
    englishName: 'France',
    germanName: 'Frankreich',
    code: 'FR'
  },
  GABON: {
    englishName: 'Gabon',
    germanName: 'Gabun',
    code: 'GA'
  },
  UNITED_KINGDOM: {
    englishName: 'United Kingdom',
    germanName: 'Vereinigtes Königreich',
    code: 'UK'
  },
  GRENADA: {
    englishName: 'Grenada',
    germanName: 'Grenada',
    code: 'GD'
  },
  GEORGIA: {
    englishName: 'Georgia',
    germanName: 'Georgien',
    code: 'GE'
  },
  FRENCH_GUIANA: {
    englishName: 'French Guiana',
    germanName: 'Französisch-Guayana',
    code: 'GF'
  },
  GUERNSEY: {
    englishName: 'Guernsey',
    germanName: 'Guernsey',
    code: 'GG'
  },
  GHANA: {
    englishName: 'Ghana',
    germanName: 'Ghana',
    code: 'GH'
  },
  GIBRALTAR: {
    englishName: 'Gibraltar',
    germanName: 'Gibraltar',
    code: 'GI'
  },
  GREENLAND: {
    englishName: 'Greenland',
    germanName: 'Grönland',
    code: 'GL'
  },
  GAMBIA: {
    englishName: 'Gambia',
    germanName: 'Gambia',
    code: 'GM'
  },
  GUINEA: {
    englishName: 'Guinea',
    germanName: 'Guinea',
    code: 'GN'
  },
  GUADELOUPE: {
    englishName: 'Guadeloupe',
    germanName: 'Guadeloupe',
    code: 'GP'
  },
  EQUATORIAL_GUINEA: {
    englishName: 'Equatorial Guinea',
    germanName: 'Äquatorialguinea',
    code: 'GQ'
  },
  GREECE: {
    englishName: 'Greece',
    germanName: 'Griechenland',
    code: 'GR'
  },
  SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS: {
    englishName: 'South Georgia and the South Sandwich Islands',
    germanName: 'Südgeorgien/Südlichen Sandwichinseln',
    code: 'GS'
  },
  GUATEMALA: {
    englishName: 'Guatemala',
    germanName: 'Guatemala',
    code: 'GT'
  },
  GUAM: {
    englishName: 'Guam',
    germanName: 'Guam',
    code: 'GU'
  },
  GUINEA_BISSAU: {
    englishName: 'Guinea-Bissau',
    germanName: 'Guinea-Bissau',
    code: 'GW'
  },
  GUYANA: {
    englishName: 'Guyana',
    germanName: 'Guyana',
    code: 'GY'
  },
  HONG_KONG: {
    englishName: 'Hong Kong',
    germanName: 'Hong Kong',
    code: 'HK'
  },
  HEARD_ISLAND_AND_MCDONALD_ISLANDS: {
    englishName: 'Heard Island and McDonald Islands',
    germanName: 'Heard und McDonaldinseln',
    code: 'HM'
  },
  HONDURAS: {
    englishName: 'Honduras',
    germanName: 'Honduras',
    code: 'HN'
  },
  CROATIA: {
    englishName: 'Croatia',
    germanName: 'Kroatien',
    code: 'HR'
  },
  HAITI: {
    englishName: 'Haiti',
    germanName: 'Haiti',
    code: 'HT'
  },
  HUNGARY: {
    englishName: 'Hungary',
    germanName: 'Ungarn',
    code: 'HU'
  },
  INDONESIA: {
    englishName: 'Indonesia',
    germanName: 'Indonesien',
    code: 'ID'
  },
  IRELAND: {
    englishName: 'Ireland',
    germanName: 'Irland',
    code: 'IE'
  },
  ISRAEL: {
    englishName: 'Israel',
    germanName: 'Israel',
    code: 'IL'
  },
  ISLE_OF_MAN: {
    englishName: 'Isle of Man',
    germanName: 'Isle Of Man',
    code: 'IM'
  },
  INDIA: {
    englishName: 'India',
    germanName: 'Indien',
    code: 'IN'
  },
  BRITISH_INDIAN_OCEAN_TERRITORY: {
    englishName: 'British Indian Ocean Territory',
    germanName: 'Britisches Territorium im Indischen Ozean',
    code: 'IO'
  },
  IRAQ: {
    englishName: 'Iraq',
    germanName: 'Irak',
    code: 'IQ'
  },
  IRAN: {
    englishName: 'Iran, Islamic Republic of',
    germanName: 'Iran',
    code: 'IR'
  },
  ICELAND: {
    englishName: 'Iceland',
    germanName: 'Island',
    code: 'IS'
  },
  ITALY: {
    englishName: 'Italy',
    germanName: 'Italien',
    code: 'IT'
  },
  JERSEY: {
    englishName: 'Jersey',
    germanName: 'Jersey',
    code: 'JE'
  },
  JAMAICA: {
    englishName: 'Jamaica',
    germanName: 'Jamaika',
    code: 'JM'
  },
  JORDAN: {
    englishName: 'Jordan',
    germanName: 'Jordanien',
    code: 'JO'
  },
  JAPAN: {
    englishName: 'Japan',
    germanName: 'Japan',
    code: 'JP'
  },
  KENYA: {
    englishName: 'Kenya',
    germanName: 'Kenia',
    code: 'KE'
  },
  KYRGYZSTAN: {
    englishName: 'Kyrgyzstan',
    germanName: 'Kirgisistan',
    code: 'KG'
  },
  CAMBODIA: {
    englishName: 'Cambodia',
    germanName: 'Kambodscha',
    code: 'KH'
  },
  KIRIBATI: {
    englishName: 'Kiribati',
    germanName: 'Kiribati',
    code: 'KI'
  },
  COMOROS: {
    englishName: 'Comoros',
    germanName: 'Komoren',
    code: 'KM'
  },
  SAINT_KITTS_AND_NEVIS: {
    englishName: 'Saint Kitts and Nevis',
    germanName: 'St. Kitts/Nevis',
    code: 'KN'
  },
  NORTH_KOREA: {
    englishName: 'North Korea',
    germanName: 'Nordkorea',
    code: 'KP'
  },
  SOUTH_KOREA: {
    englishName: 'South Korea',
    germanName: 'Südkorea',
    code: 'KR'
  },
  KUWAIT: {
    englishName: 'Kuwait',
    germanName: 'Kuwait',
    code: 'KW'
  },
  CAYMAN_ISLANDS: {
    englishName: 'Cayman Islands',
    germanName: 'Kaimaninseln',
    code: 'KY'
  },
  KAZAKHSTAN: {
    englishName: 'Kazakhstan',
    germanName: 'Kasachstan',
    code: 'KZ'
  },
  LAOS: {
    englishName: "Lao People's Democratic Republic",
    germanName: 'Laos',
    code: 'LA'
  },
  LEBANON: {
    englishName: 'Lebanon',
    germanName: 'Libanon',
    code: 'LB'
  },
  SAINT_LUCIA: {
    englishName: 'Saint Lucia',
    germanName: 'St. Lucia',
    code: 'LC'
  },
  LIECHTENSTEIN: {
    englishName: 'Liechtenstein',
    germanName: 'Liechtenstein',
    code: 'LI'
  },
  SRI_LANKA: {
    englishName: 'Sri Lanka',
    germanName: 'Sri Lanka',
    code: 'LK'
  },
  LIBERIA: {
    englishName: 'Liberia',
    germanName: 'Liberia',
    code: 'LR'
  },
  LESOTHO: {
    englishName: 'Lesotho',
    germanName: 'Lesotho',
    code: 'LS'
  },
  LITHUANIA: {
    englishName: 'Lithuania',
    germanName: 'Litauen',
    code: 'LT'
  },
  LUXEMBOURG: {
    englishName: 'Luxembourg',
    germanName: 'Luxemburg',
    code: 'LU'
  },
  LATVIA: {
    englishName: 'Latvia',
    germanName: 'Lettland',
    code: 'LV'
  },
  LIBYA: {
    englishName: 'Libya',
    germanName: 'Libyen',
    code: 'LY'
  },
  MOROCCO: {
    englishName: 'Morocco',
    germanName: 'Morokko',
    code: 'MA'
  },
  MONACO: {
    englishName: 'Monaco',
    germanName: 'Monaco',
    code: 'MC'
  },
  MOLDOVA_REPUBLIC_OF: {
    englishName: 'Moldova, Republic of',
    germanName: 'Moldawien',
    code: 'MD'
  },
  MONTENEGRO: {
    englishName: 'Montenegro',
    germanName: 'Montenegro',
    code: 'ME'
  },
  SAINT_MARTIN_FRENCH_PART: {
    englishName: 'Saint Martin (French part)',
    germanName: 'St. Martin',
    code: 'MF'
  },
  MADAGASCAR: {
    englishName: 'Madagascar',
    germanName: 'Madagaskar',
    code: 'MG'
  },
  MARSHALL_ISLANDS: {
    englishName: 'Marshall Islands',
    germanName: 'Marshallinseln',
    code: 'MH'
  },
  NORTH_MACEDONIA_REPUBLIC_OF: {
    englishName: 'North Macedonia, Republic of',
    germanName: 'Mazedonien',
    code: 'MK'
  },
  MALI: {
    englishName: 'Mali',
    germanName: 'Mali',
    code: 'ML'
  },
  MYANMAR: {
    englishName: 'Myanmar',
    germanName: 'Myanmar',
    code: 'MM'
  },
  MONGOLIA: {
    englishName: 'Mongolia',
    germanName: 'Mongolei',
    code: 'MN'
  },
  MACAO: {
    englishName: 'Macao',
    germanName: 'Macao',
    code: 'MO'
  },
  NORTHERN_MARIANA_ISLANDS: {
    englishName: 'Northern Mariana Islands',
    germanName: 'Nördliche Marianen',
    code: 'MP'
  },
  MARTINIQUE: {
    englishName: 'Martinique',
    germanName: 'Martinique',
    code: 'MQ'
  },
  MAURITANIA: {
    englishName: 'Mauritania',
    germanName: 'Mauretanien',
    code: 'MR'
  },
  MONTSERRAT: {
    englishName: 'Montserrat',
    germanName: 'Montserrat',
    code: 'MS'
  },
  MALTA: {
    englishName: 'Malta',
    germanName: 'Malta',
    code: 'MT'
  },
  MAURITIUS: {
    englishName: 'Mauritius',
    germanName: 'Mauritius',
    code: 'MU'
  },
  MALDIVES: {
    englishName: 'Maldives',
    germanName: 'Maldiven',
    code: 'MV'
  },
  MALAWI: {
    englishName: 'Malawi',
    germanName: 'Malawi',
    code: 'MW'
  },
  MEXICO: {
    englishName: 'Mexico',
    germanName: 'Mexiko',
    code: 'MX'
  },
  MALAYSIA: {
    englishName: 'Malaysia',
    germanName: 'Malaysia',
    code: 'MY'
  },
  MOZAMBIQUE: {
    englishName: 'Mozambique',
    germanName: 'Mosambik',
    code: 'MZ'
  },
  NAMIBIA: {
    englishName: 'Namibia',
    germanName: 'Namibia',
    code: 'NA'
  },
  NEW_CALEDONIA: {
    englishName: 'New Caledonia',
    germanName: 'Neukaledonien',
    code: 'NC'
  },
  NIGER: {
    englishName: 'Niger',
    germanName: 'Niger',
    code: 'NE'
  },
  NORFOLK_ISLAND: {
    englishName: 'Norfolk Island',
    germanName: 'Norfolkinsel',
    code: 'NF'
  },
  NIGERIA: {
    englishName: 'Nigeria',
    germanName: 'Nigeria',
    code: 'NG'
  },
  NICARAGUA: {
    englishName: 'Nicaragua',
    germanName: 'Nicaragua',
    code: 'NI'
  },
  NETHERLANDS: {
    englishName: 'Netherlands',
    germanName: 'Niederlande',
    code: 'NL'
  },
  NORWAY: {
    englishName: 'Norway',
    germanName: 'Norwegen',
    code: 'NO'
  },
  NEPAL: {
    englishName: 'Nepal',
    germanName: 'Nepal',
    code: 'NP'
  },
  NAURU: {
    englishName: 'Nauru',
    germanName: 'Nauru',
    code: 'NR'
  },
  NIUE: {
    englishName: 'Niue',
    germanName: 'Niue',
    code: 'NU'
  },
  NEW_ZEALAND: {
    englishName: 'New Zealand',
    germanName: 'Neuseeland',
    code: 'NZ'
  },
  OMAN: {
    englishName: 'Oman',
    germanName: 'Oman',
    code: 'OM'
  },
  PANAMA: {
    englishName: 'Panama',
    germanName: 'Panama',
    code: 'PA'
  },
  PERU: {
    englishName: 'Peru',
    germanName: 'Peru',
    code: 'PE'
  },
  FRENCH_POLYNESIA: {
    englishName: 'French Polynesia',
    germanName: 'Französisch-Polynesien',
    code: 'PF'
  },
  PAPUA_NEW_GUINEA: {
    englishName: 'Papua New Guinea',
    germanName: 'Papua-Neuguinea',
    code: 'PG'
  },
  PHILIPPINES: {
    englishName: 'Philippines',
    germanName: 'Philippinen',
    code: 'PH'
  },
  PAKISTAN: {
    englishName: 'Pakistan',
    germanName: 'Pakistan',
    code: 'PK'
  },
  POLAND: {
    englishName: 'Poland',
    germanName: 'Polen',
    code: 'PL'
  },
  SAINT_PIERRE_AND_MIQUELON: {
    englishName: 'Saint Pierre and Miquelon',
    germanName: 'St. Pierre/Miquelon',
    code: 'PM'
  },
  PITCAIRN: {
    englishName: 'Pitcairn',
    germanName: 'Pitcairninseln',
    code: 'PN'
  },
  PUERTO_RICO: {
    englishName: 'Puerto Rico',
    germanName: 'Puerto Rico',
    code: 'PR'
  },
  PALESTINE_STATE_OF: {
    englishName: 'Palestine, State of',
    germanName: 'Palestina',
    code: 'PS'
  },
  PORTUGAL: {
    englishName: 'Portugal',
    germanName: 'Portugal',
    code: 'PT'
  },
  PALAU: {
    englishName: 'Palau',
    germanName: 'Palau',
    code: 'PW'
  },
  PARAGUAY: {
    englishName: 'Paraguay',
    germanName: 'Paraguay',
    code: 'PY'
  },
  QATAR: {
    englishName: 'Qatar',
    germanName: 'Qatar',
    code: 'QA'
  },
  REUNION: {
    englishName: 'Réunion',
    germanName: 'Réunion',
    code: 'RE'
  },
  ROMANIA: {
    englishName: 'Romania',
    germanName: 'Rumänien',
    code: 'RO'
  },
  SERBIA: {
    englishName: 'Serbia',
    germanName: 'Serbien',
    code: 'RS'
  },
  RUSSIAN_FEDERATION: {
    englishName: 'Russian Federation',
    germanName: 'Russland',
    code: 'RU'
  },
  RWANDA: {
    englishName: 'Rwanda',
    germanName: 'Ruanda',
    code: 'RW'
  },
  SAUDI_ARABIA: {
    englishName: 'Saudi Arabia',
    germanName: 'Saudi-Arabien',
    code: 'SA'
  },
  SOLOMON_ISLANDS: {
    englishName: 'Solomon Islands',
    germanName: 'Salomonen',
    code: 'SB'
  },
  SEYCHELLES: {
    englishName: 'Seychelles',
    germanName: 'Seychellen',
    code: 'SC'
  },
  SUDAN: {
    englishName: 'Sudan',
    germanName: 'Sudan',
    code: 'SD'
  },
  SWEDEN: {
    englishName: 'Sweden',
    germanName: 'Schweden',
    code: 'SE'
  },
  SINGAPORE: {
    englishName: 'Singapore',
    germanName: 'Singapur',
    code: 'SG'
  },
  SAINT_HELENAASCENSION_AND_TRISTAN_DA_CUNHA: {
    englishName: 'Saint Helena, Ascension and Tristan da Cunha',
    germanName: 'St. Helena',
    code: 'SH'
  },
  SLOVENIA: {
    englishName: 'Slovenia',
    germanName: 'Slowenien',
    code: 'SI'
  },
  SVALBARD_AND_JAN_MAYEN: {
    englishName: 'Svalbard and Jan Mayen',
    germanName: 'Svalbard/Jan Mayen',
    code: 'SJ'
  },
  SLOVAKIA: {
    englishName: 'Slovakia',
    germanName: 'Slowakische Republik',
    code: 'SK'
  },
  SIERRA_LEONE: {
    englishName: 'Sierra Leone',
    germanName: 'Sierra Leone',
    code: 'SL'
  },
  SAN_MARINO: {
    englishName: 'San Marino',
    germanName: 'San Marino',
    code: 'SM'
  },
  SENEGAL: {
    englishName: 'Senegal',
    germanName: 'Senegal',
    code: 'SN'
  },
  SOMALIA: {
    englishName: 'Somalia',
    germanName: 'Somalia',
    code: 'SO'
  },
  SURINAME: {
    englishName: 'Suriname',
    germanName: 'Surinam',
    code: 'SR'
  },
  SOUTH_SUDAN: {
    englishName: 'South Sudan',
    germanName: 'Südsudan',
    code: 'SS'
  },
  SAO_TOME_AND_PRINCIPE: {
    englishName: 'Sao Tome and Principe',
    germanName: 'São Tomé/Príncipe',
    code: 'ST'
  },
  EL_SALVADOR: {
    englishName: 'El Salvador',
    germanName: 'El Salvador',
    code: 'SV'
  },
  SINT_MAARTEN_DUTCH_PART: {
    englishName: 'Sint Maarten (Dutch part)',
    germanName: 'Sint Maarten (Königreich der Niederlande)',
    code: 'SX'
  },
  SYRIAN_ARAB_REPUBLIC: {
    englishName: 'Syrian Arab Republic',
    germanName: 'Syrien',
    code: 'SY'
  },
  ESWATINI: {
    englishName: 'Eswatini',
    germanName: 'Swasiland',
    code: 'SZ'
  },
  TURKS_AND_CAICOS_ISLANDS: {
    englishName: 'Turks and Caicos Islands',
    germanName: 'Turks- und Caicosinseln',
    code: 'TC'
  },
  CHAD: {
    englishName: 'Chad',
    germanName: 'Tschad',
    code: 'TD'
  },
  FRENCH_SOUTHERN_TERRITORIES: {
    englishName: 'French Southern Territories',
    germanName: 'Französische Südpolarterritorien',
    code: 'TF'
  },
  TOGO: {
    englishName: 'Togo',
    germanName: 'Togo',
    code: 'TG'
  },
  THAILAND: {
    englishName: 'Thailand',
    germanName: 'Thailand',
    code: 'TH'
  },
  TAJIKISTAN: {
    englishName: 'Tajikistan',
    germanName: 'Tadschikistan',
    code: 'TJ'
  },
  TOKELAU: {
    englishName: 'Tokelau',
    germanName: 'Tokelau',
    code: 'TK'
  },
  TIMOR_LESTE: {
    englishName: 'Timor-Leste',
    germanName: 'Timor-Leste',
    code: 'TL'
  },
  TURKMENISTAN: {
    englishName: 'Turkmenistan',
    germanName: 'Turkmenistan',
    code: 'TM'
  },
  TUNISIA: {
    englishName: 'Tunisia',
    germanName: 'Tunisien',
    code: 'TN'
  },
  TONGA: {
    englishName: 'Tonga',
    germanName: 'Tonga',
    code: 'TO'
  },
  TURKEY: {
    englishName: 'Turkey',
    germanName: 'Türkei',
    code: 'TR'
  },
  TRINIDAD_AND_TOBAGO: {
    englishName: 'Trinidad and Tobago',
    germanName: 'Trinidad und Tobago',
    code: 'TT'
  },
  TUVALU: {
    englishName: 'Tuvalu',
    germanName: 'Tuvalu',
    code: 'TV'
  },
  TAIWAN: {
    englishName: 'Taiwan, Province of China',
    germanName: 'Taiwan',
    code: 'TW'
  },
  TANZANIA_UNITED_REPUBLIC_OF: {
    englishName: 'Tanzania, United Republic of',
    germanName: 'Tansania',
    code: 'TZ'
  },
  UKRAINE: {
    englishName: 'Ukraine',
    germanName: 'Ukraine',
    code: 'UA'
  },
  UGANDA: {
    englishName: 'Uganda',
    germanName: 'Uganda',
    code: 'UG'
  },
  UNITED_STATES_MINOR_OUTLYING_ISLANDS: {
    englishName: 'United States Minor Outlying Islands',
    germanName: 'United States Minor Islands',
    code: 'UM'
  },
  UNITED_STATES: {
    englishName: 'United States',
    germanName: 'USA',
    code: 'US'
  },
  URUGUAY: {
    englishName: 'Uruguay',
    germanName: 'Uruguay',
    code: 'UY'
  },
  UZBEKISTAN: {
    englishName: 'Uzbekistan',
    germanName: 'Usbekistan',
    code: 'UZ'
  },
  HOLY_SEE_VATICAN_CITY_STATE: {
    englishName: 'Holy See (Vatican City State)',
    germanName: 'Vatikanstadt',
    code: 'VA'
  },
  SAINT_VINCENT_AND_THE_GRENADINES: {
    englishName: 'Saint Vincent and the Grenadines',
    germanName: 'St. Vincent/Die Grenadinen',
    code: 'VC'
  },
  VENEZUELA_BOLIVARIAN_REPUBLIC_OF: {
    englishName: 'Venezuela, Bolivarian Republic of',
    germanName: 'Venezuela',
    code: 'VE'
  },
  VIRGIN_ISLANDS_BRITISH: {
    englishName: 'Virgin Islands, British',
    germanName: 'Jungferninseln, Britische',
    code: 'VG'
  },
  VIRGIN_ISLANDS_U_S: {
    englishName: 'Virgin Islands, U.S.',
    germanName: 'Jungferninseln, Amerikanische',
    code: 'VI'
  },
  VIET_NAM: {
    englishName: 'Viet Nam',
    germanName: 'Vietnam',
    code: 'VN'
  },
  VANUATU: {
    englishName: 'Vanuatu',
    germanName: 'Vanuatu',
    code: 'VU'
  },
  WALLIS_AND_FUTUNA: {
    englishName: 'Wallis and Futuna',
    germanName: 'Wallis/Futuna',
    code: 'WF'
  },
  SAMOA: {
    englishName: 'Samoa',
    germanName: 'Samoa',
    code: 'WS'
  },
  YEMEN: {
    englishName: 'Yemen',
    germanName: 'Jemen',
    code: 'YE'
  },
  MAYOTTE: {
    englishName: 'Mayotte',
    germanName: 'Mayotte',
    code: 'YT'
  },
  SOUTH_AFRICA: {
    englishName: 'South Africa',
    germanName: 'Südafrika',
    code: 'ZA'
  },
  ZAMBIA: {
    englishName: 'Zambia',
    germanName: 'Sambia',
    code: 'ZM'
  },
  ZIMBABWE: {
    englishName: 'Zimbabwe',
    germanName: 'Simbabwe',
    code: 'ZW'
  }
};

import React from 'react';
import { Position, Tooltip } from '@blueprintjs/core';
import * as PropTypes from 'prop-types';
import { PAYMENT_FREQUENCY } from '../../../common/constants';
import { StyledSpan, StyledSpanBold } from '../../epilot-forms/common';
import { PriceBlock } from '.';

const TaxSummary = ({ taxPricingSummary, defaultTextColor, fontText }) => {
  const textColor = {
    color: defaultTextColor
  };

  return taxPricingSummary.map(tax => (
    <div className="row ep--mb-24" key={tax.id}>
      <StyledSpanBold
        style={textColor}
        font={fontText}
        className="col-6 text-right ep__txt--md ep-opacity-50 ep__txt-semibold"
      >
        {`${tax.name}(${tax.rate}%)`}
      </StyledSpanBold>
      <StyledSpan
        style={textColor}
        font={fontText}
        className="col-5 ep__txt--md ep__txt-bold text-right text-break"
      >
        <Tooltip
          content={
            <PriceBlock
              defaultTextColor={defaultTextColor}
              fontText={fontText}
              oneTimePrice={tax.price[PAYMENT_FREQUENCY.ONE_TIME]}
              monthlyPrice={tax.price[PAYMENT_FREQUENCY.MONTHLY]}
              yearlyPrice={tax.price[PAYMENT_FREQUENCY.YEARLY]}
            />
          }
          position={Position.BOTTOM}
          tooltipClassName="ep-tooltip ep-tooltip-width-300"
        >
          <PriceBlock
            defaultTextColor={defaultTextColor}
            fontText={fontText}
            oneTimePrice={tax.price[PAYMENT_FREQUENCY.ONE_TIME]}
            monthlyPrice={tax.price[PAYMENT_FREQUENCY.MONTHLY]}
            yearlyPrice={tax.price[PAYMENT_FREQUENCY.YEARLY]}
          />
        </Tooltip>
      </StyledSpan>
    </div>
  ));
};

TaxSummary.propTypes = {
  taxPricingSummary: PropTypes.array.isRequired,
  defaultTextColor: PropTypes.string.isRequired,
  fontText: PropTypes.object.isRequired
};

export default React.memo(TaxSummary);

const hexToR = h => {
  return parseInt(cutHex(h)?.substring(0, 2), 16);
};

const hexToG = h => {
  return parseInt(cutHex(h)?.substring(2, 4), 16);
};

const hexToB = h => {
  return parseInt(cutHex(h)?.substring(4, 6), 16);
};

const cutHex = h => {
  return h?.charAt(0) === '#' ? h?.substring(1, 7) : h;
};

export const hexaToRGBA = (hexa, transparency) => {
  let R = hexToR(hexa);
  let G = hexToG(hexa);
  let B = hexToB(hexa);
  return 'rgba(' + R + ',' + G + ',' + B + ',' + transparency + ')';
};

export const UPDATE_CUSTOMER_JOURNEY_ANSWER = 'UPDATE_CUSTOMER_JOURNEY_ANSWER';
export const RESET_CUSTOMER_JOURNEY_ANSWER = 'RESET_CUSTOMER_JOURNEY_ANSWER';
export const RESET_ANSWER_AFTER_CHANGE_PRIVATELY_OR_BUSINESS =
  'RESET_ANSWER_AFTER_CHANGE_PRIVATELY_OR_BUSINESS';

export const updateCustomerJourneyAnswer = data => ({
  type: UPDATE_CUSTOMER_JOURNEY_ANSWER,
  payload: data
});

export const resetCustomerJourneyAnswer = () => ({
  type: RESET_CUSTOMER_JOURNEY_ANSWER
});

export const resetAnswerAfterChangePrivatelyOrBusiness = () => ({
  type: RESET_ANSWER_AFTER_CHANGE_PRIVATELY_OR_BUSINESS
});

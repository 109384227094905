import { CALL_API } from '../middlewares/customMiddleware';
import { API_URL_VO } from '../../common/settings/ApiUrlSettings';
import { passUrlQueryParams } from '../../common/utils';

export const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE';
export const CHECK_VALID_TAX_ID_REQUEST = 'CHECK_VALID_TAX_ID_REQUEST';
export const CHECK_VALID_TAX_ID_SUCCESS = 'CHECK_VALID_TAX_ID_SUCCESS';
export const CHECK_VALID_TAX_ID_FAILURE = 'CHECK_VALID_TAX_ID_FAILURE';
export const CREATE_CUSTOMER_URL = '/customers';
export const CHECK_VALID_TAX_ID_URL = '/customers/check-valid-taxid';

export const createCustomer = params => dispatch => {
  const fullCreateCustomerUrl = `${API_URL_VO}${CREATE_CUSTOMER_URL}`;
  return dispatch({
    [CALL_API]: {
      types: [CREATE_CUSTOMER_REQUEST, CREATE_CUSTOMER_SUCCESS, CREATE_CUSTOMER_FAILURE],
      endpoint: fullCreateCustomerUrl,
      method: 'POST',
      params: params
    }
  });
};

export const checkValidTaxID = params => dispatch => {
  const fullCheckValidTaxIDUrl = `${API_URL_VO}${passUrlQueryParams(
    CHECK_VALID_TAX_ID_URL,
    params
  )}`;
  return dispatch({
    [CALL_API]: {
      types: [CHECK_VALID_TAX_ID_REQUEST, CHECK_VALID_TAX_ID_SUCCESS, CHECK_VALID_TAX_ID_FAILURE],
      endpoint: fullCheckValidTaxIDUrl,
      method: 'GET'
    }
  });
};

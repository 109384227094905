import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import _ from 'lodash';
import { productSelectionsSelector } from './productSelectionsSelector';
import { PRODUCT_SELECTED_KEY } from '../../common/constants';

const getQuestions = state => state.frontendConfiguration.questions;
const getCurrQuestions = state => state.currentState.currQuestions;
const getProductSelections = state => productSelectionsSelector(state);

export const createDeepEqualSelector = createSelectorCreator(defaultMemoize, _.isEqual);

export const questionConfigCJSelector = createSelector(
  [getQuestions],
  questions => _.mapValues(_.keyBy(questions, 'id'), 'objectModel')
);

const generateCurrQuestionIndexMap = currentQuestionIds => {
  let currQuestionIndexMap = {};
  _.forEach(currentQuestionIds, (elem, index) => {
    currQuestionIndexMap[elem] = index;
  });
  return currQuestionIndexMap;
};

const HC_ELECTRICITY_QUESTION_GROUP = [
  'HOUSE_CONNECTION_ELECTRICITY_ADDITIONAL',
  'HOUSE_CONNECTION_ELECTRICITY_FEED_INTO_GRID',
  'HOUSE_CONNECTION_E_MOBILITY',
  'HOUSE_CONNECTION_E_MOBILITY_REQUIREMENT',
  'HOUSE_CONNECTION_PREPARATION_WATER_HEATERS'
];

const HC_GAS_QUESTION_GROUP = ['HOUSE_CONNECTION_OUTLET_PRESSURE_REQUIRED'];

const HC_WATER_QUESTION_GROUP = [
  'HOUSE_CONNECTION_EXTINGUISHING_WATER_DEMAND',
  'HOUSE_CONNECTION_PEAK_WATER',
  'HOUSE_CONNECTION_WATER_STREET_FRONT_LENGTH',
  'HOUSE_CONNECTION_WATER_PROPERTY_SIZE',
  'HOUSE_CONNECTION_WATER_FLOOR_SPACE'
];

export const cookingQuestionsSelector = createDeepEqualSelector(
  [getCurrQuestions, getProductSelections],
  (currQuestions, productSelections) => {
    let cloneCurrQuestion = _.assign([], currQuestions);
    if (!productSelections[PRODUCT_SELECTED_KEY.HOUSE_CONNECTION_ELECTRICITY]) {
      _.pullAll(
        cloneCurrQuestion,
        _.filter(
          currQuestions,
          currentQuestion => _.indexOf(HC_ELECTRICITY_QUESTION_GROUP, currentQuestion.name) !== -1
        )
      );
    }
    if (!productSelections[PRODUCT_SELECTED_KEY.HOUSE_CONNECTION_GAS]) {
      _.pullAll(
        cloneCurrQuestion,
        _.filter(
          currQuestions,
          currentQuestion => _.indexOf(HC_GAS_QUESTION_GROUP, currentQuestion.name) !== -1
        )
      );
    }
    if (!productSelections[PRODUCT_SELECTED_KEY.HOUSE_CONNECTION_WATER]) {
      _.pullAll(
        cloneCurrQuestion,
        _.filter(
          currQuestions,
          currentQuestion => _.indexOf(HC_WATER_QUESTION_GROUP, currentQuestion.name) !== -1
        )
      );
    }

    const currQuestionIds = _.map(cloneCurrQuestion, 'id');

    const currQuestionMap = _.keyBy(cloneCurrQuestion, 'id');

    const currQuestionIndexMap = generateCurrQuestionIndexMap(currQuestionIds);
    return {
      currQuestions: cloneCurrQuestion,
      currQuestionIds,
      currQuestionMap,
      currQuestionIndexMap
    };
  }
);

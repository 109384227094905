import _ from 'lodash';
import { SALUTATION_DATA, LANGUAGE, GERMAN_TEXT } from '../constants';

const getListSalutation = () => {
  let options = _.values(SALUTATION_DATA[LANGUAGE.DE]);
  options.unshift(GERMAN_TEXT.SELECT_SALUTATION);
  return options;
};

export { getListSalutation };

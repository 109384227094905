import React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import AddOnItem from './AddOnItem';
import { COLOR } from '../../../common/constants';

const AddOnV2 = ({
  addOns,
  addOnV2Selections,
  productType,
  disabled,
  primaryColor,
  fontText,
  selectAddOnSellingOption,
  selectAddOn,
  textColor,
  setScrollHeight
}) => {
  return (
    <>
      {_.map(addOns, addOn => {
        const selectedAddOn = _.find(
          addOnV2Selections.selectedAddOnV2MapKeyByProductType[productType],
          e => e.addOnId === addOn.id
        );

        if (addOn.sellingOptions?.length === 0) {
          return null;
        }

        return (
          <AddOnItem
            addOn={addOn}
            disabled={disabled}
            fontText={fontText}
            isAddOnSelected={!!selectedAddOn}
            selectedAddOn={selectedAddOn}
            key={`${productType}-${addOn.id}`}
            productType={productType}
            primaryColor={primaryColor}
            selectAddOn={selectAddOn}
            selectAddOnSellingOption={selectAddOnSellingOption}
            textColor={textColor}
            setScrollHeight={setScrollHeight}
          />
        );
      })}
    </>
  );
};

AddOnV2.propTypes = {
  fontText: PropTypes.object,
  addOns: PropTypes.array,
  primaryColor: PropTypes.string,
  productType: PropTypes.string.isRequired,
  addOnV2Selections: PropTypes.object.isRequired,
  textColor: PropTypes.string,
  setScrollHeight: PropTypes.func.isRequired,
  selectAddOn: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  selectAddOnSellingOption: PropTypes.func.isRequired
};

AddOnV2.defaultProps = {
  fontText: {},
  primaryColor: COLOR.DEFAULT_BLUE,
  textColor: COLOR.DARK_GRAY,
  addOns: [],
  disabled: false
};

export default React.memo(AddOnV2);

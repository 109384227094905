import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Map, StyledSpan, InfoHelpDescription } from '../../components/epilot-forms/common';
import { COLOR, GEO_QUESTION_STATUS, SPECIAL_CHARACTER } from '../../common/constants';
import {
  checkProductAvailableExternalService,
  updateCustomerJourneyAnswer,
  updateIsAddressChange
} from '../../store/actions';
import { formatQuestion } from '../../common/utils';
import { geoQuestionStatusSelector } from '../../store/selectors';

class GoogleMapContainer extends Component {
  componentDidUpdate(prevProps) {
    const {
      address: { lat, lng }
    } = this.props;
    const {
      address: { lat: prevLat, lng: prevLng }
    } = prevProps;
    if (lat !== prevLat || lng !== prevLng) {
      this.handleCallExternalService();
    }
  }

  handleCallExternalService = () => {
    const {
      geoQuestionStatus,
      checkProductAvailableExternalService,
      frontendConfiguration: { thirdPartyServiceSetting }
    } = this.props;
    if (thirdPartyServiceSetting && thirdPartyServiceSetting.active) {
      if (geoQuestionStatus === GEO_QUESTION_STATUS.PIN_ADDRESS_ON_MAP) {
        checkProductAvailableExternalService();
      }
    }
  };

  getCoordinates = (lat, lng, country) => {
    const { address, updateCustomerJourneyAnswer } = this.props;
    const data = {
      ...address,
      lat,
      lng,
      country
    };
    updateCustomerJourneyAnswer({ address: data });
  };

  render() {
    const {
      question,
      fontText,
      name,
      postalCode,
      address,
      google,
      frontendConfiguration,
      isAddressChange,
      updateIsAddressChange,
      defaultTextColor
    } = this.props;

    const textColor = { color: defaultTextColor };

    let query = postalCode + SPECIAL_CHARACTER.SPACE;
    if (address && address.street && address.number) {
      query = `${query}${address.street} ${address.number} `;
    }

    if (address && address.district && address.block && address.lot) {
      query = `${query}${address.district} ${address.block} ${address.lot}`;
    }
    return (
      <>
        <div className="ep--mb-8" style={{ height: '300px' }}>
          <Map
            google={google}
            query={query}
            height="300px"
            zoom={14}
            getCoordinates={this.getCoordinates}
            kmlDataUrl={frontendConfiguration.kmlDataUrl}
            isAddressChange={isAddressChange}
            addressData={address}
            postalCode={postalCode}
            onUpdateIsAddressChange={() => updateIsAddressChange({ isAddressChange: false })}
          />
        </div>
        <div className="text-center ep--pt-8 ep--pb-16">
          <StyledSpan
            font={fontText}
            className="ep__txt-regular ep__txt--lg ep__txt-no-select"
            style={textColor}
          >
            {formatQuestion(question.questionTitle, name)}
          </StyledSpan>
          <InfoHelpDescription
            showHelpDescription={question.showHelpDescription}
            tooltipInfoContent={<StyledSpan font={fontText}>{question.helpDescription}</StyledSpan>}
            classNameForInfoIcon="ep__txt--lg ep--ml-8"
            defaultTextColor={defaultTextColor}
          />
        </div>
      </>
    );
  }
}

GoogleMapContainer.propTypes = {
  question: PropTypes.object,
  fontText: PropTypes.object,
  defaultTextColor: PropTypes.string,
  frontendConfiguration: PropTypes.object,
  address: PropTypes.object.isRequired,
  google: PropTypes.object,
  checkProductAvailableExternalService: PropTypes.func,
  geoQuestionStatus: PropTypes.string,
  name: PropTypes.string.isRequired,
  postalCode: PropTypes.string.isRequired,
  isAddressChange: PropTypes.bool,
  updateCustomerJourneyAnswer: PropTypes.func,
  updateIsAddressChange: PropTypes.func
};

GoogleMapContainer.defaultProps = {
  question: {},
  fontText: {},
  defaultTextColor: COLOR.DARK_GRAY,
  frontendConfiguration: {},
  google: {},
  checkProductAvailableExternalService: () => ({}),
  geoQuestionStatus: SPECIAL_CHARACTER.EMPTY,
  isAddressChange: false,
  updateCustomerJourneyAnswer: () => ({}),
  updateIsAddressChange: () => ({})
};

function mapStateToProps(state) {
  return {
    frontendConfiguration: state.frontendConfiguration,
    address: state.customerJourneyAnswer.address,
    name: state.customerJourneyAnswer.name,
    postalCode: state.customerJourneyAnswer.postalCode,
    geoQuestionStatus: geoQuestionStatusSelector(state),
    isAddressChange: state.currentState.isAddressChange
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateCustomerJourneyAnswer,
        checkProductAvailableExternalService,
        updateIsAddressChange
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoogleMapContainer);

import { createSelector } from 'reselect';
import _ from 'lodash';
import { GEO_QUESTION_STATUS } from '../../common/constants';

const getCurrQuestions = state => state.currentState.currQuestions;

export const geoQuestionStatusSelector = createSelector(
  [getCurrQuestions],
  currQuestions => {
    let geoQuestionStatus = GEO_QUESTION_STATUS.NO_GEO_QUESTION;
    _.forEach(currQuestions, elem => {
      switch (elem.type) {
        case GEO_QUESTION_STATUS.POSTAL_CODE:
          geoQuestionStatus = GEO_QUESTION_STATUS.POSTAL_CODE;
          break;
        case GEO_QUESTION_STATUS.ADDRESS_DETAILS:
          geoQuestionStatus = GEO_QUESTION_STATUS.ADDRESS_DETAILS;
          return;
        case GEO_QUESTION_STATUS.PIN_ADDRESS_ON_MAP:
          geoQuestionStatus = GEO_QUESTION_STATUS.PIN_ADDRESS_ON_MAP;
          return;
        default:
          break;
      }
    });
    return geoQuestionStatus;
  }
);

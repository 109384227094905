export const ALL_POSTAL_CODE = [
  {
    postalCode: '01067',
    city: 'Dresden'
  },
  {
    postalCode: '01069',
    city: 'Dresden'
  },
  {
    postalCode: '01097',
    city: 'Dresden'
  },
  {
    postalCode: '01099',
    city: 'Dresden'
  },
  {
    postalCode: '01108',
    city: 'Dresden'
  },
  {
    postalCode: '01109',
    city: 'Dresden'
  },
  {
    postalCode: '01127',
    city: 'Dresden'
  },
  {
    postalCode: '01129',
    city: 'Dresden'
  },
  {
    postalCode: '01139',
    city: 'Dresden'
  },
  {
    postalCode: '01156',
    city: 'Dresden'
  },
  {
    postalCode: '01157',
    city: 'Dresden'
  },
  {
    postalCode: '01159',
    city: 'Dresden'
  },
  {
    postalCode: '01169',
    city: 'Dresden'
  },
  {
    postalCode: '01187',
    city: 'Dresden'
  },
  {
    postalCode: '01189',
    city: 'Dresden'
  },
  {
    postalCode: '01217',
    city: 'Dresden'
  },
  {
    postalCode: '01219',
    city: 'Dresden'
  },
  {
    postalCode: '01237',
    city: 'Dresden'
  },
  {
    postalCode: '01239',
    city: 'Dresden'
  },
  {
    postalCode: '01257',
    city: 'Dresden'
  },
  {
    postalCode: '01259',
    city: 'Dresden'
  },
  {
    postalCode: '01277',
    city: 'Dresden'
  },
  {
    postalCode: '01279',
    city: 'Dresden'
  },
  {
    postalCode: '01307',
    city: 'Dresden'
  },
  {
    postalCode: '01309',
    city: 'Dresden'
  },
  {
    postalCode: '01324',
    city: 'Dresden'
  },
  {
    postalCode: '01326',
    city: 'Dresden'
  },
  {
    postalCode: '01328',
    city: 'Dresden'
  },
  {
    postalCode: '01445',
    city: 'Radebeul'
  },
  {
    postalCode: '01454',
    city: 'Großerkmannsdorf'
  },
  {
    postalCode: '01454',
    city: 'Radeberg'
  },
  {
    postalCode: '01454',
    city: 'Ullersdorf'
  },
  {
    postalCode: '01454',
    city: 'Wachau'
  },
  {
    postalCode: '01458',
    city: 'Ottendorf-Okrilla'
  },
  {
    postalCode: '01462',
    city: 'Cossebaude'
  },
  {
    postalCode: '01462',
    city: 'Gohlis'
  },
  {
    postalCode: '01462',
    city: 'Niederwartha'
  },
  {
    postalCode: '01465',
    city: 'Langebrück'
  },
  {
    postalCode: '01465',
    city: 'Liegau-Augustusbad'
  },
  {
    postalCode: '01465',
    city: 'Schönborn'
  },
  {
    postalCode: '01468',
    city: 'Volkersdorf'
  },
  {
    postalCode: '01468',
    city: 'Moritzburg'
  },
  {
    postalCode: '01468',
    city: 'Reichenberg'
  },
  {
    postalCode: '01471',
    city: 'Bärnsdorf'
  },
  {
    postalCode: '01471',
    city: 'Berbisdorf'
  },
  {
    postalCode: '01471',
    city: 'Großdittmannsdorf'
  },
  {
    postalCode: '01471',
    city: 'Radeburg'
  },
  {
    postalCode: '01471',
    city: 'Steinbach bei Moritzburg'
  },
  {
    postalCode: '01477',
    city: 'Arnsdorf'
  },
  {
    postalCode: '01477',
    city: 'Fischbach'
  },
  {
    postalCode: '01477',
    city: 'Kleinwolmsdorf'
  },
  {
    postalCode: '01477',
    city: 'Wallroda'
  },
  {
    postalCode: '01558',
    city: 'Großenhain'
  },
  {
    postalCode: '01561',
    city: 'Adelsdorf'
  },
  {
    postalCode: '01561',
    city: 'Bauda'
  },
  {
    postalCode: '01561',
    city: 'Blochwitz'
  },
  {
    postalCode: '01561',
    city: 'Böhla'
  },
  {
    postalCode: '01561',
    city: 'Brockwitz'
  },
  {
    postalCode: '01561',
    city: 'Brößnitz'
  },
  {
    postalCode: '01561',
    city: 'Dobra'
  },
  {
    postalCode: '01561',
    city: 'Dobra Bad'
  },
  {
    postalCode: '01561',
    city: 'Ebersbach'
  },
  {
    postalCode: '01561',
    city: 'Fasanerie'
  },
  {
    postalCode: '01561',
    city: 'Görzig'
  },
  {
    postalCode: '01561',
    city: 'Kleinnaundorf'
  },
  {
    postalCode: '01561',
    city: 'Kleinthiemig'
  },
  {
    postalCode: '01561',
    city: 'Krauschütz'
  },
  {
    postalCode: '01561',
    city: 'Kraußnitz'
  },
  {
    postalCode: '01561',
    city: 'Lampertswalde'
  },
  {
    postalCode: '01561',
    city: 'Liega'
  },
  {
    postalCode: '01561',
    city: 'Nasseböhla'
  },
  {
    postalCode: '01561',
    city: 'Niegeroda'
  },
  {
    postalCode: '01561',
    city: 'Oelsnitz'
  },
  {
    postalCode: '01561',
    city: 'Priestewitz'
  },
  {
    postalCode: '01561',
    city: 'Quersa'
  },
  {
    postalCode: '01561',
    city: 'Schönborn'
  },
  {
    postalCode: '01561',
    city: 'Schönfeld'
  },
  {
    postalCode: '01561',
    city: 'Skäßchen'
  },
  {
    postalCode: '01561',
    city: 'Skaup'
  },
  {
    postalCode: '01561',
    city: 'Strauch'
  },
  {
    postalCode: '01561',
    city: 'Stroga'
  },
  {
    postalCode: '01561',
    city: 'Tauscha'
  },
  {
    postalCode: '01561',
    city: 'Thiendorf'
  },
  {
    postalCode: '01561',
    city: 'Treugeböhla'
  },
  {
    postalCode: '01561',
    city: 'Uebigau'
  },
  {
    postalCode: '01561',
    city: 'Walda'
  },
  {
    postalCode: '01561',
    city: 'Weißig am Raschütz'
  },
  {
    postalCode: '01561',
    city: 'Wildenhain'
  },
  {
    postalCode: '01561',
    city: 'Würschnitz'
  },
  {
    postalCode: '01561',
    city: 'Zabeltitz'
  },
  {
    postalCode: '01561',
    city: 'Zschorna'
  },
  {
    postalCode: '01587',
    city: 'Riesa'
  },
  {
    postalCode: '01589',
    city: 'Oelsitz'
  },
  {
    postalCode: '01589',
    city: 'Riesa'
  },
  {
    postalCode: '01591',
    city: 'Riesa'
  },
  {
    postalCode: '01594',
    city: 'Böhlen'
  },
  {
    postalCode: '01594',
    city: 'Dobernitz'
  },
  {
    postalCode: '01594',
    city: 'Dösitz'
  },
  {
    postalCode: '01594',
    city: 'Gleina'
  },
  {
    postalCode: '01594',
    city: 'Gostewitz'
  },
  {
    postalCode: '01594',
    city: 'Groptitz'
  },
  {
    postalCode: '01594',
    city: 'Hirschstein'
  },
  {
    postalCode: '01594',
    city: 'Ibanitz'
  },
  {
    postalCode: '01594',
    city: 'Jahnishausen'
  },
  {
    postalCode: '01594',
    city: 'Kalbitz'
  },
  {
    postalCode: '01594',
    city: 'Leutewitz'
  },
  {
    postalCode: '01594',
    city: 'Mautitz'
  },
  {
    postalCode: '01594',
    city: 'Nickritz'
  },
  {
    postalCode: '01594',
    city: 'Panitz'
  },
  {
    postalCode: '01594',
    city: 'Plotitz'
  },
  {
    postalCode: '01594',
    city: 'Pöhsig'
  },
  {
    postalCode: '01594',
    city: 'Prositz'
  },
  {
    postalCode: '01594',
    city: 'Seerhausen'
  },
  {
    postalCode: '01594',
    city: 'Staucha'
  },
  {
    postalCode: '01594',
    city: 'Stauchitz'
  },
  {
    postalCode: '01594',
    city: 'Steudten'
  },
  {
    postalCode: '01594',
    city: 'Stösitz'
  },
  {
    postalCode: '01594',
    city: 'Treben'
  },
  {
    postalCode: '01609',
    city: 'Gröditz'
  },
  {
    postalCode: '01609',
    city: 'Heidehäuser'
  },
  {
    postalCode: '01609',
    city: 'Lichtensee'
  },
  {
    postalCode: '01609',
    city: 'Nauwalde'
  },
  {
    postalCode: '01609',
    city: 'Peritz'
  },
  {
    postalCode: '01609',
    city: 'Röderaue'
  },
  {
    postalCode: '01609',
    city: 'Streumen'
  },
  {
    postalCode: '01609',
    city: 'Tiefenau'
  },
  {
    postalCode: '01609',
    city: 'Wülknitz'
  },
  {
    postalCode: '01612',
    city: 'Colmnitz'
  },
  {
    postalCode: '01612',
    city: 'Diesbar-Seußlitz'
  },
  {
    postalCode: '01612',
    city: 'Glaubitz'
  },
  {
    postalCode: '01612',
    city: 'Nünchritz'
  },
  {
    postalCode: '01616',
    city: 'Strehla'
  },
  {
    postalCode: '01619',
    city: 'Gohlis'
  },
  {
    postalCode: '01619',
    city: 'Röderau-Bobersen'
  },
  {
    postalCode: '01619',
    city: 'Zeithain'
  },
  {
    postalCode: '01623',
    city: 'Abend'
  },
  {
    postalCode: '01623',
    city: 'Höfgen'
  },
  {
    postalCode: '01623',
    city: 'Klessig'
  },
  {
    postalCode: '01623',
    city: 'Kreißa'
  },
  {
    postalCode: '01623',
    city: 'Leuben-Schleinitz'
  },
  {
    postalCode: '01623',
    city: 'Lommatzsch'
  },
  {
    postalCode: '01623',
    city: 'Mutzschwitz'
  },
  {
    postalCode: '01623',
    city: 'Noßlitz'
  },
  {
    postalCode: '01623',
    city: 'Oberstößwitz'
  },
  {
    postalCode: '01623',
    city: 'Pinnewitz'
  },
  {
    postalCode: '01623',
    city: 'Priesen'
  },
  {
    postalCode: '01623',
    city: 'Raußlitz'
  },
  {
    postalCode: '01623',
    city: 'Rüsseina'
  },
  {
    postalCode: '01623',
    city: 'Stahna'
  },
  {
    postalCode: '01623',
    city: 'Ziegenhain'
  },
  {
    postalCode: '01640',
    city: 'Coswig'
  },
  {
    postalCode: '01662',
    city: 'Meißen'
  },
  {
    postalCode: '01665',
    city: 'Diera'
  },
  {
    postalCode: '01665',
    city: 'Gallschütz'
  },
  {
    postalCode: '01665',
    city: 'Gauernitz'
  },
  {
    postalCode: '01665',
    city: 'Käbschütztal'
  },
  {
    postalCode: '01665',
    city: 'Klipphausen'
  },
  {
    postalCode: '01665',
    city: 'Leippen'
  },
  {
    postalCode: '01665',
    city: 'Lindigt'
  },
  {
    postalCode: '01665',
    city: 'Lösten'
  },
  {
    postalCode: '01665',
    city: 'Perne'
  },
  {
    postalCode: '01665',
    city: 'Proschwitz'
  },
  {
    postalCode: '01665',
    city: 'Rottewitz'
  },
  {
    postalCode: '01665',
    city: 'Sachsdorf'
  },
  {
    postalCode: '01665',
    city: 'Schänitz'
  },
  {
    postalCode: '01665',
    city: 'Scharfenberg'
  },
  {
    postalCode: '01665',
    city: 'Schrebitz'
  },
  {
    postalCode: '01665',
    city: 'Taubenheim'
  },
  {
    postalCode: '01665',
    city: 'Triebischtal'
  },
  {
    postalCode: '01665',
    city: 'Winkwitz'
  },
  {
    postalCode: '01665',
    city: 'Zehren'
  },
  {
    postalCode: '01665',
    city: 'Zetta'
  },
  {
    postalCode: '01683',
    city: 'Bodenbach'
  },
  {
    postalCode: '01683',
    city: 'Gruna'
  },
  {
    postalCode: '01683',
    city: 'Heynitz'
  },
  {
    postalCode: '01683',
    city: 'Karcha'
  },
  {
    postalCode: '01683',
    city: 'Neubodenbach'
  },
  {
    postalCode: '01683',
    city: 'Nossen'
  },
  {
    postalCode: '01683',
    city: 'Rhäsa'
  },
  {
    postalCode: '01683',
    city: 'Rothschönberg'
  },
  {
    postalCode: '01683',
    city: 'Saultitz'
  },
  {
    postalCode: '01683',
    city: 'Starbach'
  },
  {
    postalCode: '01683',
    city: 'Tanneberg'
  },
  {
    postalCode: '01683',
    city: 'Wolkau'
  },
  {
    postalCode: '01689',
    city: 'Niederau'
  },
  {
    postalCode: '01689',
    city: 'Weinböhla'
  },
  {
    postalCode: '01705',
    city: 'Freital'
  },
  {
    postalCode: '01705',
    city: 'Pesterwitz'
  },
  {
    postalCode: '01723',
    city: 'Grumbach'
  },
  {
    postalCode: '01723',
    city: 'Helbigsdorf-Blankenstein'
  },
  {
    postalCode: '01723',
    city: 'Kesselsdorf'
  },
  {
    postalCode: '01723',
    city: 'Mohorn'
  },
  {
    postalCode: '01723',
    city: 'Wilsdruff'
  },
  {
    postalCode: '01728',
    city: 'Bannewitz'
  },
  {
    postalCode: '01728',
    city: 'Cunnersdorf'
  },
  {
    postalCode: '01728',
    city: 'Goppeln'
  },
  {
    postalCode: '01728',
    city: 'Hänichen'
  },
  {
    postalCode: '01728',
    city: 'Possendorf'
  },
  {
    postalCode: '01728',
    city: 'Rippien'
  },
  {
    postalCode: '01731',
    city: 'Hornschänke'
  },
  {
    postalCode: '01731',
    city: 'Kreischa'
  },
  {
    postalCode: '01734',
    city: 'Rabenau'
  },
  {
    postalCode: '01737',
    city: 'Braunsdorf'
  },
  {
    postalCode: '01737',
    city: 'Kleinopitz'
  },
  {
    postalCode: '01737',
    city: 'Hartha'
  },
  {
    postalCode: '01737',
    city: 'Oberhermsdorf'
  },
  {
    postalCode: '01737',
    city: 'Pohrsdorf'
  },
  {
    postalCode: '01737',
    city: 'Tharandt'
  },
  {
    postalCode: '01738',
    city: 'Colmnitz'
  },
  {
    postalCode: '01738',
    city: 'Dorfhain'
  },
  {
    postalCode: '01738',
    city: 'Klingenberg'
  },
  {
    postalCode: '01744',
    city: 'Dippoldiswalde'
  },
  {
    postalCode: '01744',
    city: 'Friedersdorf'
  },
  {
    postalCode: '01744',
    city: 'Malter'
  },
  {
    postalCode: '01744',
    city: 'Oberhäslich'
  },
  {
    postalCode: '01744',
    city: 'Reichstädt'
  },
  {
    postalCode: '01744',
    city: 'Reinholdshain'
  },
  {
    postalCode: '01744',
    city: 'Röthenbach'
  },
  {
    postalCode: '01762',
    city: 'Hartmannsdorf-Reichenau'
  },
  {
    postalCode: '01762',
    city: 'Obercarsdorf'
  },
  {
    postalCode: '01762',
    city: 'Schmiedeberg'
  },
  {
    postalCode: '01768',
    city: 'Bärenstein'
  },
  {
    postalCode: '01768',
    city: 'Glashütte'
  },
  {
    postalCode: '01768',
    city: 'Glashütte-Dittersdorf'
  },
  {
    postalCode: '01768',
    city: 'Glashütte-Johnsbach'
  },
  {
    postalCode: '01768',
    city: 'Glashütte-Luchau'
  },
  {
    postalCode: '01768',
    city: 'Glashütte-Schlottwitz'
  },
  {
    postalCode: '01768',
    city: 'Reinhardtsgrimma'
  },
  {
    postalCode: '01773',
    city: 'Altenberg'
  },
  {
    postalCode: '01774',
    city: 'Höckendorf'
  },
  {
    postalCode: '01774',
    city: 'Pretzschendorf'
  },
  {
    postalCode: '01776',
    city: 'Hermsdorf'
  },
  {
    postalCode: '01776',
    city: 'Hermsdorf-Seyde'
  },
  {
    postalCode: '01778',
    city: 'Fürstenau'
  },
  {
    postalCode: '01778',
    city: 'Fürstenwalde'
  },
  {
    postalCode: '01778',
    city: 'Geising'
  },
  {
    postalCode: '01778',
    city: 'Lauenstein'
  },
  {
    postalCode: '01778',
    city: 'Liebenau'
  },
  {
    postalCode: '01778',
    city: 'Müglitz'
  },
  {
    postalCode: '01796',
    city: 'Dohma'
  },
  {
    postalCode: '01796',
    city: 'Pirna'
  },
  {
    postalCode: '01796',
    city: 'Struppen'
  },
  {
    postalCode: '01809',
    city: 'Dohna'
  },
  {
    postalCode: '01809',
    city: 'Heidenau'
  },
  {
    postalCode: '01809',
    city: 'Meusegast'
  },
  {
    postalCode: '01809',
    city: 'Müglitztal'
  },
  {
    postalCode: '01809',
    city: 'Röhrsdorf'
  },
  {
    postalCode: '01814',
    city: 'Bad Schandau'
  },
  {
    postalCode: '01814',
    city: 'Kleingießhübel'
  },
  {
    postalCode: '01814',
    city: 'Porschdorf'
  },
  {
    postalCode: '01814',
    city: 'Rathmannsdorf'
  },
  {
    postalCode: '01814',
    city: 'Reinhardtsdorf'
  },
  {
    postalCode: '01814',
    city: 'Schöna'
  },
  {
    postalCode: '01816',
    city: 'Bad Gottleuba'
  },
  {
    postalCode: '01816',
    city: 'Bahratal'
  },
  {
    postalCode: '01816',
    city: 'Oelsen'
  },
  {
    postalCode: '01819',
    city: 'Bahretal'
  },
  {
    postalCode: '01819',
    city: 'Berggießhübel'
  },
  {
    postalCode: '01819',
    city: 'Langenhennersdorf'
  },
  {
    postalCode: '01824',
    city: 'Gohrisch'
  },
  {
    postalCode: '01824',
    city: 'Königstein'
  },
  {
    postalCode: '01824',
    city: 'Rathen'
  },
  {
    postalCode: '01824',
    city: 'Rosenthal-Bielatal'
  },
  {
    postalCode: '01825',
    city: 'Börnersdorf-Breitenau'
  },
  {
    postalCode: '01825',
    city: 'Liebstadt'
  },
  {
    postalCode: '01829',
    city: 'Stadt Wehlen'
  },
  {
    postalCode: '01833',
    city: 'Dürrröhrsdorf-Dittersbach'
  },
  {
    postalCode: '01833',
    city: 'Stolpen'
  },
  {
    postalCode: '01844',
    city: 'Hohwald'
  },
  {
    postalCode: '01844',
    city: 'Neustadt'
  },
  {
    postalCode: '01847',
    city: 'Lohmen'
  },
  {
    postalCode: '01848',
    city: 'Hohnstein'
  },
  {
    postalCode: '01855',
    city: 'Altendorf'
  },
  {
    postalCode: '01855',
    city: 'Hinterhermsdorf'
  },
  {
    postalCode: '01855',
    city: 'Lichtenhain'
  },
  {
    postalCode: '01855',
    city: 'Mittelndorf'
  },
  {
    postalCode: '01855',
    city: 'Ottendorf'
  },
  {
    postalCode: '01855',
    city: 'Saupsdorf'
  },
  {
    postalCode: '01855',
    city: 'Sebnitz'
  },
  {
    postalCode: '01855',
    city: 'Zeughaus'
  },
  {
    postalCode: '01877',
    city: 'Bischofswerda'
  },
  {
    postalCode: '01877',
    city: 'Demitz-Thumitz'
  },
  {
    postalCode: '01877',
    city: 'Naundorf'
  },
  {
    postalCode: '01877',
    city: 'Putzkau'
  },
  {
    postalCode: '01877',
    city: 'Rammenau'
  },
  {
    postalCode: '01877',
    city: 'Schmölln'
  },
  {
    postalCode: '01877',
    city: 'Tröbigau'
  },
  {
    postalCode: '01896',
    city: 'Lichtenberg'
  },
  {
    postalCode: '01896',
    city: 'Ohorn'
  },
  {
    postalCode: '01896',
    city: 'Pulsnitz'
  },
  {
    postalCode: '01896',
    city: 'Röderhäuser'
  },
  {
    postalCode: '01900',
    city: 'Bretnig-Hauswalde'
  },
  {
    postalCode: '01900',
    city: 'Großröhrsdorf'
  },
  {
    postalCode: '01904',
    city: 'Neukirch'
  },
  {
    postalCode: '01904',
    city: 'Ringenhain'
  },
  {
    postalCode: '01904',
    city: 'Steinigtwolmsdorf'
  },
  {
    postalCode: '01904',
    city: 'Weifa'
  },
  {
    postalCode: '01906',
    city: 'Burkau'
  },
  {
    postalCode: '01909',
    city: 'Frankenthal'
  },
  {
    postalCode: '01909',
    city: 'Großharthau'
  },
  {
    postalCode: '01909',
    city: 'Großharthau-Bühlau'
  },
  {
    postalCode: '01909',
    city: 'Großharthau-Schmiedefeld'
  },
  {
    postalCode: '01909',
    city: 'Großharthau-Seeligstadt'
  },
  {
    postalCode: '01917',
    city: 'Kamenz'
  },
  {
    postalCode: '01920',
    city: 'Bischheim-Häslich'
  },
  {
    postalCode: '01920',
    city: 'Crostwitz'
  },
  {
    postalCode: '01920',
    city: 'Elstra'
  },
  {
    postalCode: '01920',
    city: 'Gersdorf-Möhrsdorf'
  },
  {
    postalCode: '01920',
    city: 'Nebelschütz'
  },
  {
    postalCode: '01920',
    city: 'Oßling'
  },
  {
    postalCode: '01920',
    city: 'Panschwitz-Kuckau'
  },
  {
    postalCode: '01920',
    city: 'Räckelwitz'
  },
  {
    postalCode: '01920',
    city: 'Ralbitz-Rosenthal'
  },
  {
    postalCode: '01920',
    city: 'Schönteichen'
  },
  {
    postalCode: '01920',
    city: 'Steina'
  },
  {
    postalCode: '01936',
    city: 'Gottschdorf'
  },
  {
    postalCode: '01936',
    city: 'Großnaundorf'
  },
  {
    postalCode: '01936',
    city: 'Koitzsch'
  },
  {
    postalCode: '01936',
    city: 'Königsbrück'
  },
  {
    postalCode: '01936',
    city: 'Laußnitz'
  },
  {
    postalCode: '01936',
    city: 'Neukirch'
  },
  {
    postalCode: '01936',
    city: 'Oberlichtenau'
  },
  {
    postalCode: '01936',
    city: 'Reichenbach-Reichenau'
  },
  {
    postalCode: '01936',
    city: 'Schmorkau'
  },
  {
    postalCode: '01936',
    city: 'Schwepnitz'
  },
  {
    postalCode: '01936',
    city: 'Straßgräbchen'
  },
  {
    postalCode: '01936',
    city: 'Weißbach bei Königsbrück'
  },
  {
    postalCode: '01945',
    city: 'Biehlen'
  },
  {
    postalCode: '01945',
    city: 'Frauendorf'
  },
  {
    postalCode: '01945',
    city: 'Grünewald'
  },
  {
    postalCode: '01945',
    city: 'Guteborn'
  },
  {
    postalCode: '01945',
    city: 'Hermsdorf'
  },
  {
    postalCode: '01945',
    city: 'Hohenbocka'
  },
  {
    postalCode: '01945',
    city: 'Jannowitz'
  },
  {
    postalCode: '01945',
    city: 'Kroppen'
  },
  {
    postalCode: '01945',
    city: 'Lindenau'
  },
  {
    postalCode: '01945',
    city: 'Lipsa'
  },
  {
    postalCode: '01945',
    city: 'Peickwitz'
  },
  {
    postalCode: '01945',
    city: 'Ruhland'
  },
  {
    postalCode: '01945',
    city: 'Schwarzbach'
  },
  {
    postalCode: '01945',
    city: 'Tettau'
  },
  {
    postalCode: '01968',
    city: 'Brieske'
  },
  {
    postalCode: '01968',
    city: 'Großkoschen'
  },
  {
    postalCode: '01968',
    city: 'Hörlitz'
  },
  {
    postalCode: '01968',
    city: 'Niemtsch'
  },
  {
    postalCode: '01968',
    city: 'Sedlitz'
  },
  {
    postalCode: '01968',
    city: 'Senftenberg'
  },
  {
    postalCode: '01979',
    city: 'Grünewalde'
  },
  {
    postalCode: '01979',
    city: 'Kostebrau'
  },
  {
    postalCode: '01979',
    city: 'Lauchhammer'
  },
  {
    postalCode: '01983',
    city: 'Allmosen'
  },
  {
    postalCode: '01983',
    city: 'Bahnsdorf'
  },
  {
    postalCode: '01983',
    city: 'Dörrwalde'
  },
  {
    postalCode: '01983',
    city: 'Freienhufen'
  },
  {
    postalCode: '01983',
    city: 'Großräschen'
  },
  {
    postalCode: '01983',
    city: 'Woschkow'
  },
  {
    postalCode: '01987',
    city: 'Schwarzheide'
  },
  {
    postalCode: '01990',
    city: 'Frauwalde'
  },
  {
    postalCode: '01990',
    city: 'Großkmehlen'
  },
  {
    postalCode: '01990',
    city: 'Kleinkmehlen'
  },
  {
    postalCode: '01990',
    city: 'Ortrand'
  },
  {
    postalCode: '01993',
    city: 'Schipkau'
  },
  {
    postalCode: '01994',
    city: 'Annahütte'
  },
  {
    postalCode: '01994',
    city: 'Barzig'
  },
  {
    postalCode: '01994',
    city: 'Drochow'
  },
  {
    postalCode: '01994',
    city: 'Meuro'
  },
  {
    postalCode: '01994',
    city: 'Saalhausen'
  },
  {
    postalCode: '01994',
    city: 'Wormlage'
  },
  {
    postalCode: '01996',
    city: 'Hosena'
  },
  {
    postalCode: '01998',
    city: 'Klettwitz'
  },
  {
    postalCode: '02625',
    city: 'Bautzen'
  },
  {
    postalCode: '02627',
    city: 'Hochkirch'
  },
  {
    postalCode: '02627',
    city: 'Kubschütz'
  },
  {
    postalCode: '02627',
    city: 'Radibor'
  },
  {
    postalCode: '02627',
    city: 'Weißenberg'
  },
  {
    postalCode: '02633',
    city: 'Gaußig'
  },
  {
    postalCode: '02633',
    city: 'Göda'
  },
  {
    postalCode: '02633',
    city: 'Weißnaußlitz'
  },
  {
    postalCode: '02681',
    city: 'Crostau'
  },
  {
    postalCode: '02681',
    city: 'Kirschau'
  },
  {
    postalCode: '02681',
    city: 'Schirgiswalde'
  },
  {
    postalCode: '02681',
    city: 'Wilthen'
  },
  {
    postalCode: '02689',
    city: 'Sohland'
  },
  {
    postalCode: '02692',
    city: 'Doberschau'
  },
  {
    postalCode: '02692',
    city: 'Eulowitz'
  },
  {
    postalCode: '02692',
    city: 'Gnaschwitz'
  },
  {
    postalCode: '02692',
    city: 'Großpostwitz'
  },
  {
    postalCode: '02692',
    city: 'Obergurig'
  },
  {
    postalCode: '02694',
    city: 'Großdubrau'
  },
  {
    postalCode: '02694',
    city: 'Guttau'
  },
  {
    postalCode: '02694',
    city: 'Malschwitz'
  },
  {
    postalCode: '02699',
    city: 'Königswartha'
  },
  {
    postalCode: '02699',
    city: 'Neschwitz'
  },
  {
    postalCode: '02699',
    city: 'Puschwitz'
  },
  {
    postalCode: '02708',
    city: 'Dürrhennersdorf'
  },
  {
    postalCode: '02708',
    city: 'Großschweidnitz'
  },
  {
    postalCode: '02708',
    city: 'Kittlitz'
  },
  {
    postalCode: '02708',
    city: 'Lawalde'
  },
  {
    postalCode: '02708',
    city: 'Lawalde-Kleindehsa'
  },
  {
    postalCode: '02708',
    city: 'Lawalde-Lauba'
  },
  {
    postalCode: '02708',
    city: 'Löbau'
  },
  {
    postalCode: '02708',
    city: 'Niedercunnersdorf'
  },
  {
    postalCode: '02708',
    city: 'Obercunnersdorf'
  },
  {
    postalCode: '02708',
    city: 'Rosenbach'
  },
  {
    postalCode: '02708',
    city: 'Schönbach'
  },
  {
    postalCode: '02727',
    city: 'Neugersdorf'
  },
  {
    postalCode: '02730',
    city: 'Ebersbach'
  },
  {
    postalCode: '02733',
    city: 'Cunewalde'
  },
  {
    postalCode: '02733',
    city: 'Czorneboh'
  },
  {
    postalCode: '02736',
    city: 'Beiersdorf'
  },
  {
    postalCode: '02736',
    city: 'Oppach'
  },
  {
    postalCode: '02739',
    city: 'Eibau'
  },
  {
    postalCode: '02739',
    city: 'Eibau-Neueibau'
  },
  {
    postalCode: '02739',
    city: 'Eibau-Walddorf'
  },
  {
    postalCode: '02742',
    city: 'Friedersdorf'
  },
  {
    postalCode: '02742',
    city: 'Neusalza-Spremberg'
  },
  {
    postalCode: '02747',
    city: 'Berthelsdorf'
  },
  {
    postalCode: '02747',
    city: 'Großhennersdorf'
  },
  {
    postalCode: '02747',
    city: 'Herrnhut'
  },
  {
    postalCode: '02747',
    city: 'Rennersdorf'
  },
  {
    postalCode: '02747',
    city: 'Strahwalde'
  },
  {
    postalCode: '02748',
    city: 'Bernstadt'
  },
  {
    postalCode: '02763',
    city: 'Bertsdorf-Hörnitz'
  },
  {
    postalCode: '02763',
    city: 'Eckartsberg'
  },
  {
    postalCode: '02763',
    city: 'Mittelherwigsdorf'
  },
  {
    postalCode: '02763',
    city: 'Oberseifersdorf'
  },
  {
    postalCode: '02763',
    city: 'Radgendorf'
  },
  {
    postalCode: '02763',
    city: 'Zittau'
  },
  {
    postalCode: '02779',
    city: 'Großschönau'
  },
  {
    postalCode: '02779',
    city: 'Hainewalde'
  },
  {
    postalCode: '02782',
    city: 'Seifhennersdorf'
  },
  {
    postalCode: '02785',
    city: 'Olbersdorf'
  },
  {
    postalCode: '02788',
    city: 'Dittelsdorf'
  },
  {
    postalCode: '02788',
    city: 'Hirschfelde'
  },
  {
    postalCode: '02788',
    city: 'Schlegel'
  },
  {
    postalCode: '02791',
    city: 'Oderwitz'
  },
  {
    postalCode: '02794',
    city: 'Leutersdorf'
  },
  {
    postalCode: '02794',
    city: 'Spitzkunnersdorf'
  },
  {
    postalCode: '02796',
    city: 'Jonsdorf'
  },
  {
    postalCode: '02797',
    city: 'Oybin'
  },
  {
    postalCode: '02797',
    city: 'Lückendorf'
  },
  {
    postalCode: '02799',
    city: 'Waltersdorf'
  },
  {
    postalCode: '02826',
    city: 'Görlitz'
  },
  {
    postalCode: '02827',
    city: 'Görlitz'
  },
  {
    postalCode: '02828',
    city: 'Görlitz'
  },
  {
    postalCode: '02829',
    city: 'Königshain'
  },
  {
    postalCode: '02829',
    city: 'Markersdorf'
  },
  {
    postalCode: '02829',
    city: 'Neißeaue'
  },
  {
    postalCode: '02829',
    city: 'Schöpstal'
  },
  {
    postalCode: '02894',
    city: 'Reichenbach'
  },
  {
    postalCode: '02894',
    city: 'Sohland'
  },
  {
    postalCode: '02894',
    city: 'Vierkirchen'
  },
  {
    postalCode: '02899',
    city: 'Ostritz'
  },
  {
    postalCode: '02899',
    city: 'Schönau-Berzdorf'
  },
  {
    postalCode: '02906',
    city: 'Hohendubrau'
  },
  {
    postalCode: '02906',
    city: 'Klitten'
  },
  {
    postalCode: '02906',
    city: 'Kreba-Neudorf'
  },
  {
    postalCode: '02906',
    city: 'Mücka'
  },
  {
    postalCode: '02906',
    city: 'Niesky'
  },
  {
    postalCode: '02906',
    city: 'Quitzdorf am See'
  },
  {
    postalCode: '02906',
    city: 'Waldhufen'
  },
  {
    postalCode: '02923',
    city: 'Hähnichen'
  },
  {
    postalCode: '02923',
    city: 'Horka'
  },
  {
    postalCode: '02923',
    city: 'Kodersdorf'
  },
  {
    postalCode: '02923',
    city: 'Kosel'
  },
  {
    postalCode: '02923',
    city: 'Quolsdorf'
  },
  {
    postalCode: '02923',
    city: 'Sandschenke'
  },
  {
    postalCode: '02923',
    city: 'Spree'
  },
  {
    postalCode: '02923',
    city: 'Stannewisch'
  },
  {
    postalCode: '02923',
    city: 'Trebus'
  },
  {
    postalCode: '02929',
    city: 'Rothenburg'
  },
  {
    postalCode: '02943',
    city: 'Boxberg'
  },
  {
    postalCode: '02943',
    city: 'Weißwasser'
  },
  {
    postalCode: '02953',
    city: 'Bad Muskau'
  },
  {
    postalCode: '02953',
    city: 'Gablenz'
  },
  {
    postalCode: '02953',
    city: 'Gablenz-Kromlau'
  },
  {
    postalCode: '02953',
    city: 'Halbendorf'
  },
  {
    postalCode: '02956',
    city: 'Rietschen'
  },
  {
    postalCode: '02957',
    city: 'Krauschwitz'
  },
  {
    postalCode: '02957',
    city: 'Weißkeißel'
  },
  {
    postalCode: '02959',
    city: 'Groß Düben'
  },
  {
    postalCode: '02959',
    city: 'Schleife'
  },
  {
    postalCode: '02959',
    city: 'Schleife-Mulkwitz'
  },
  {
    postalCode: '02959',
    city: 'Schleife-Rohne'
  },
  {
    postalCode: '02959',
    city: 'Trebendorf'
  },
  {
    postalCode: '02959',
    city: 'Trebendorf-Mühlrose'
  },
  {
    postalCode: '02977',
    city: 'Hoyerswerda'
  },
  {
    postalCode: '02979',
    city: 'Burg'
  },
  {
    postalCode: '02979',
    city: 'Burghammer'
  },
  {
    postalCode: '02979',
    city: 'Burgneudorf'
  },
  {
    postalCode: '02979',
    city: 'Elsterheide'
  },
  {
    postalCode: '02979',
    city: 'Neustadt'
  },
  {
    postalCode: '02979',
    city: 'Spreetal'
  },
  {
    postalCode: '02979',
    city: 'Spreewitz'
  },
  {
    postalCode: '02979',
    city: 'Zerre'
  },
  {
    postalCode: '02991',
    city: 'Laubusch'
  },
  {
    postalCode: '02991',
    city: 'Lauta'
  },
  {
    postalCode: '02991',
    city: 'Leippe-Torno'
  },
  {
    postalCode: '02994',
    city: 'Bernsdorf'
  },
  {
    postalCode: '02994',
    city: 'Wiednitz'
  },
  {
    postalCode: '02997',
    city: 'Wittichenau'
  },
  {
    postalCode: '02999',
    city: 'Knappensee-Groß Särchen'
  },
  {
    postalCode: '02999',
    city: 'Knappensee-Koblenz'
  },
  {
    postalCode: '02999',
    city: 'Knappensee-Wartha'
  },
  {
    postalCode: '02999',
    city: 'Lohsa'
  },
  {
    postalCode: '02999',
    city: 'Uhyst'
  },
  {
    postalCode: '03042',
    city: 'Cottbus'
  },
  {
    postalCode: '03044',
    city: 'Cottbus'
  },
  {
    postalCode: '03046',
    city: 'Cottbus'
  },
  {
    postalCode: '03048',
    city: 'Cottbus'
  },
  {
    postalCode: '03050',
    city: 'Cottbus'
  },
  {
    postalCode: '03051',
    city: 'Cottbus'
  },
  {
    postalCode: '03052',
    city: 'Cottbus'
  },
  {
    postalCode: '03053',
    city: 'Cottbus'
  },
  {
    postalCode: '03054',
    city: 'Cottbus'
  },
  {
    postalCode: '03055',
    city: 'Cottbus'
  },
  {
    postalCode: '03058',
    city: 'Frauendorf'
  },
  {
    postalCode: '03058',
    city: 'Gablenz'
  },
  {
    postalCode: '03058',
    city: 'Gallinchen'
  },
  {
    postalCode: '03058',
    city: 'Groß Döbbern'
  },
  {
    postalCode: '03058',
    city: 'Groß Gaglow'
  },
  {
    postalCode: '03058',
    city: 'Groß Oßnig'
  },
  {
    postalCode: '03058',
    city: 'Haasow'
  },
  {
    postalCode: '03058',
    city: 'Kathlow'
  },
  {
    postalCode: '03058',
    city: 'Kiekebusch'
  },
  {
    postalCode: '03058',
    city: 'Klein Döbbern'
  },
  {
    postalCode: '03058',
    city: 'Klein Oßnig'
  },
  {
    postalCode: '03058',
    city: 'Komptendorf'
  },
  {
    postalCode: '03058',
    city: 'Koppatz'
  },
  {
    postalCode: '03058',
    city: 'Laubsdorf'
  },
  {
    postalCode: '03058',
    city: 'Leuthen'
  },
  {
    postalCode: '03058',
    city: 'Neuhausen'
  },
  {
    postalCode: '03058',
    city: 'Roggosen'
  },
  {
    postalCode: '03058',
    city: 'Schorbus'
  },
  {
    postalCode: '03058',
    city: 'Sergen'
  },
  {
    postalCode: '03096',
    city: 'Brahmow'
  },
  {
    postalCode: '03096',
    city: 'Briesen'
  },
  {
    postalCode: '03096',
    city: 'Burg'
  },
  {
    postalCode: '03096',
    city: 'Dissen'
  },
  {
    postalCode: '03096',
    city: 'Fehrow'
  },
  {
    postalCode: '03096',
    city: 'Guhrow'
  },
  {
    postalCode: '03096',
    city: 'Müschen'
  },
  {
    postalCode: '03096',
    city: 'Saccassne'
  },
  {
    postalCode: '03096',
    city: 'Schmogrow'
  },
  {
    postalCode: '03096',
    city: 'Striesow'
  },
  {
    postalCode: '03096',
    city: 'Werben'
  },
  {
    postalCode: '03099',
    city: 'Kolkwitz'
  },
  {
    postalCode: '03103',
    city: 'Lindchen'
  },
  {
    postalCode: '03103',
    city: 'Lubochow'
  },
  {
    postalCode: '03103',
    city: 'Neupetershain'
  },
  {
    postalCode: '03103',
    city: 'Ressen'
  },
  {
    postalCode: '03116',
    city: 'Domsdorf'
  },
  {
    postalCode: '03116',
    city: 'Drebkau'
  },
  {
    postalCode: '03116',
    city: 'Golschow'
  },
  {
    postalCode: '03116',
    city: 'Greifenhain'
  },
  {
    postalCode: '03116',
    city: 'Illmersdorf'
  },
  {
    postalCode: '03116',
    city: 'Jehserig'
  },
  {
    postalCode: '03116',
    city: 'Laubst'
  },
  {
    postalCode: '03116',
    city: 'Siewisch'
  },
  {
    postalCode: '03119',
    city: 'Welzow'
  },
  {
    postalCode: '03130',
    city: 'Bagenz'
  },
  {
    postalCode: '03130',
    city: 'Bloischdorf'
  },
  {
    postalCode: '03130',
    city: 'Bohsdorf'
  },
  {
    postalCode: '03130',
    city: 'Drieschnitz-Kahsel'
  },
  {
    postalCode: '03130',
    city: 'Friedrichshain'
  },
  {
    postalCode: '03130',
    city: 'Graustein'
  },
  {
    postalCode: '03130',
    city: 'Groß Luja'
  },
  {
    postalCode: '03130',
    city: 'Haidemühl'
  },
  {
    postalCode: '03130',
    city: 'Hornow'
  },
  {
    postalCode: '03130',
    city: 'Jämlitz'
  },
  {
    postalCode: '03130',
    city: 'Kausche'
  },
  {
    postalCode: '03130',
    city: 'Klein Düben'
  },
  {
    postalCode: '03130',
    city: 'Klein Loitz'
  },
  {
    postalCode: '03130',
    city: 'Lieskau'
  },
  {
    postalCode: '03130',
    city: 'Proschim'
  },
  {
    postalCode: '03130',
    city: 'Reuthen'
  },
  {
    postalCode: '03130',
    city: 'Sellessen'
  },
  {
    postalCode: '03130',
    city: 'Spremberg'
  },
  {
    postalCode: '03130',
    city: 'Tschernitz'
  },
  {
    postalCode: '03130',
    city: 'Türkendorf'
  },
  {
    postalCode: '03130',
    city: 'Wadelsdorf'
  },
  {
    postalCode: '03130',
    city: 'Wolfshain'
  },
  {
    postalCode: '03139',
    city: 'Schwarze Pumpe'
  },
  {
    postalCode: '03139',
    city: 'Terpe'
  },
  {
    postalCode: '03149',
    city: 'Dubrau'
  },
  {
    postalCode: '03149',
    city: 'Forst'
  },
  {
    postalCode: '03149',
    city: 'Gahry'
  },
  {
    postalCode: '03149',
    city: 'Gosda'
  },
  {
    postalCode: '03149',
    city: 'Groß Schacksdorf'
  },
  {
    postalCode: '03149',
    city: 'Jethe'
  },
  {
    postalCode: '03149',
    city: 'Jocksdorf'
  },
  {
    postalCode: '03149',
    city: 'Simmersdorf'
  },
  {
    postalCode: '03149',
    city: 'Trebendorf'
  },
  {
    postalCode: '03159',
    city: 'Döbern'
  },
  {
    postalCode: '03159',
    city: 'Groß Kölzig'
  },
  {
    postalCode: '03159',
    city: 'Jerischke'
  },
  {
    postalCode: '03159',
    city: 'Klein Kölzig'
  },
  {
    postalCode: '03159',
    city: 'Mattendorf'
  },
  {
    postalCode: '03159',
    city: 'Preschen'
  },
  {
    postalCode: '03172',
    city: 'Atterwasch'
  },
  {
    postalCode: '03172',
    city: 'Bärenklau'
  },
  {
    postalCode: '03172',
    city: 'Gastrose-Kerkwitz'
  },
  {
    postalCode: '03172',
    city: 'Grabko'
  },
  {
    postalCode: '03172',
    city: 'Grießen'
  },
  {
    postalCode: '03172',
    city: 'Guben'
  },
  {
    postalCode: '03172',
    city: 'Horno'
  },
  {
    postalCode: '03172',
    city: 'Lutzketal'
  },
  {
    postalCode: '03172',
    city: 'Pinnow-Heideland'
  },
  {
    postalCode: '03185',
    city: 'Drachhausen'
  },
  {
    postalCode: '03185',
    city: 'Drehnow'
  },
  {
    postalCode: '03185',
    city: 'Grötsch'
  },
  {
    postalCode: '03185',
    city: 'Heinersbrück'
  },
  {
    postalCode: '03185',
    city: 'Peitz'
  },
  {
    postalCode: '03185',
    city: 'Tauer'
  },
  {
    postalCode: '03185',
    city: 'Teichland'
  },
  {
    postalCode: '03185',
    city: 'Turnow-Preilack'
  },
  {
    postalCode: '03197',
    city: 'Drewitz'
  },
  {
    postalCode: '03197',
    city: 'Jänschwalde'
  },
  {
    postalCode: '03197',
    city: 'Jänschwalde Ost'
  },
  {
    postalCode: '03205',
    city: 'Bischdorf'
  },
  {
    postalCode: '03205',
    city: 'Bolschwitz'
  },
  {
    postalCode: '03205',
    city: 'Bronkow'
  },
  {
    postalCode: '03205',
    city: 'Buchwäldchen Ziegelei'
  },
  {
    postalCode: '03205',
    city: 'Buckow'
  },
  {
    postalCode: '03205',
    city: 'Calau'
  },
  {
    postalCode: '03205',
    city: 'Craupe'
  },
  {
    postalCode: '03205',
    city: 'Dubrau'
  },
  {
    postalCode: '03205',
    city: 'Gollmitz'
  },
  {
    postalCode: '03205',
    city: 'Gosda'
  },
  {
    postalCode: '03205',
    city: 'Groß Jehser'
  },
  {
    postalCode: '03205',
    city: 'Groß-Mehßow'
  },
  {
    postalCode: '03205',
    city: 'Kemmen'
  },
  {
    postalCode: '03205',
    city: 'Laasow'
  },
  {
    postalCode: '03205',
    city: 'Lipten'
  },
  {
    postalCode: '03205',
    city: 'Lug'
  },
  {
    postalCode: '03205',
    city: 'Missen'
  },
  {
    postalCode: '03205',
    city: 'Mlode'
  },
  {
    postalCode: '03205',
    city: 'Ogrosen'
  },
  {
    postalCode: '03205',
    city: 'Repten'
  },
  {
    postalCode: '03205',
    city: 'Saßleben'
  },
  {
    postalCode: '03205',
    city: 'Werchow'
  },
  {
    postalCode: '03205',
    city: 'Zinnitz'
  },
  {
    postalCode: '03222',
    city: 'Boblitz'
  },
  {
    postalCode: '03222',
    city: 'Groß Beuchow'
  },
  {
    postalCode: '03222',
    city: 'Groß-Klessow'
  },
  {
    postalCode: '03222',
    city: 'Hindenberg'
  },
  {
    postalCode: '03222',
    city: 'Kittlitz'
  },
  {
    postalCode: '03222',
    city: 'Klein Radden'
  },
  {
    postalCode: '03222',
    city: 'Lübbenau'
  },
  {
    postalCode: '03222',
    city: 'Ragow'
  },
  {
    postalCode: '03226',
    city: 'Göritz'
  },
  {
    postalCode: '03226',
    city: 'Groß Lübbenau'
  },
  {
    postalCode: '03226',
    city: 'Koßwig'
  },
  {
    postalCode: '03226',
    city: 'Leipe'
  },
  {
    postalCode: '03226',
    city: 'Naundorf'
  },
  {
    postalCode: '03226',
    city: 'Raddusch'
  },
  {
    postalCode: '03226',
    city: 'Stradow'
  },
  {
    postalCode: '03226',
    city: 'Suschow'
  },
  {
    postalCode: '03226',
    city: 'Vetschau'
  },
  {
    postalCode: '03229',
    city: 'Altdöbern'
  },
  {
    postalCode: '03229',
    city: 'Buchwäldchen'
  },
  {
    postalCode: '03229',
    city: 'Casel'
  },
  {
    postalCode: '03229',
    city: 'Muckwar'
  },
  {
    postalCode: '03229',
    city: 'Ranzow'
  },
  {
    postalCode: '03229',
    city: 'Reddern'
  },
  {
    postalCode: '03229',
    city: 'Schöllnitz'
  },
  {
    postalCode: '03238',
    city: 'Betten'
  },
  {
    postalCode: '03238',
    city: 'Dollenchen'
  },
  {
    postalCode: '03238',
    city: 'Eichholz-Drößig'
  },
  {
    postalCode: '03238',
    city: 'Finsterwalde'
  },
  {
    postalCode: '03238',
    city: 'Fischwasser'
  },
  {
    postalCode: '03238',
    city: 'Göllnitz'
  },
  {
    postalCode: '03238',
    city: 'Gorden-Staupitz'
  },
  {
    postalCode: '03238',
    city: 'Gröbitz'
  },
  {
    postalCode: '03238',
    city: 'Lichterfeld'
  },
  {
    postalCode: '03238',
    city: 'Lieskau'
  },
  {
    postalCode: '03238',
    city: 'Lindthal'
  },
  {
    postalCode: '03238',
    city: 'Lugau'
  },
  {
    postalCode: '03238',
    city: 'Massen'
  },
  {
    postalCode: '03238',
    city: 'Münchhausen'
  },
  {
    postalCode: '03238',
    city: 'Oppelhain'
  },
  {
    postalCode: '03238',
    city: 'Ponnsdorf'
  },
  {
    postalCode: '03238',
    city: 'Rückersdorf'
  },
  {
    postalCode: '03238',
    city: 'Sallgast'
  },
  {
    postalCode: '03238',
    city: 'Schacksdorf'
  },
  {
    postalCode: '03246',
    city: 'Babben'
  },
  {
    postalCode: '03246',
    city: 'Bergen'
  },
  {
    postalCode: '03246',
    city: 'Crinitz'
  },
  {
    postalCode: '03246',
    city: 'Fürstlich Drehna'
  },
  {
    postalCode: '03246',
    city: 'Gahro'
  },
  {
    postalCode: '03249',
    city: 'Bahren'
  },
  {
    postalCode: '03249',
    city: 'Breitenau'
  },
  {
    postalCode: '03249',
    city: 'Goßmar'
  },
  {
    postalCode: '03249',
    city: 'Großkrausnik'
  },
  {
    postalCode: '03249',
    city: 'Kleinkrausnik'
  },
  {
    postalCode: '03249',
    city: 'Pahlsdorf'
  },
  {
    postalCode: '03249',
    city: 'Sonnewalde'
  },
  {
    postalCode: '03249',
    city: 'Zeckerin'
  },
  {
    postalCode: '03253',
    city: 'Arenzhain'
  },
  {
    postalCode: '03253',
    city: 'Brenitz'
  },
  {
    postalCode: '03253',
    city: 'Buchhain'
  },
  {
    postalCode: '03253',
    city: 'Doberlug-Kirchhain'
  },
  {
    postalCode: '03253',
    city: 'Dübrichen'
  },
  {
    postalCode: '03253',
    city: 'Frankena'
  },
  {
    postalCode: '03253',
    city: 'Friedersdorf'
  },
  {
    postalCode: '03253',
    city: 'Hennersdorf'
  },
  {
    postalCode: '03253',
    city: 'Nexdorf'
  },
  {
    postalCode: '03253',
    city: 'Prießen'
  },
  {
    postalCode: '03253',
    city: 'Schilda'
  },
  {
    postalCode: '03253',
    city: 'Schönborn'
  },
  {
    postalCode: '03253',
    city: 'Trebbus'
  },
  {
    postalCode: '03253',
    city: 'Tröbitz'
  },
  {
    postalCode: '03253',
    city: 'Werenzhain'
  },
  {
    postalCode: '04103',
    city: 'Leipzig'
  },
  {
    postalCode: '04105',
    city: 'Leipzig'
  },
  {
    postalCode: '04107',
    city: 'Leipzig'
  },
  {
    postalCode: '04109',
    city: 'Leipzig'
  },
  {
    postalCode: '04129',
    city: 'Leipzig'
  },
  {
    postalCode: '04155',
    city: 'Leipzig'
  },
  {
    postalCode: '04157',
    city: 'Leipzig'
  },
  {
    postalCode: '04158',
    city: 'Leipzig'
  },
  {
    postalCode: '04159',
    city: 'Leipzig'
  },
  {
    postalCode: '04177',
    city: 'Leipzig'
  },
  {
    postalCode: '04178',
    city: 'Leipzig'
  },
  {
    postalCode: '04179',
    city: 'Leipzig'
  },
  {
    postalCode: '04205',
    city: 'Leipzig'
  },
  {
    postalCode: '04207',
    city: 'Leipzig'
  },
  {
    postalCode: '04209',
    city: 'Leipzig'
  },
  {
    postalCode: '04229',
    city: 'Leipzig'
  },
  {
    postalCode: '04249',
    city: 'Leipzig'
  },
  {
    postalCode: '04275',
    city: 'Leipzig'
  },
  {
    postalCode: '04277',
    city: 'Leipzig'
  },
  {
    postalCode: '04279',
    city: 'Leipzig'
  },
  {
    postalCode: '04288',
    city: 'Leipzig'
  },
  {
    postalCode: '04289',
    city: 'Leipzig'
  },
  {
    postalCode: '04299',
    city: 'Leipzig'
  },
  {
    postalCode: '04315',
    city: 'Leipzig'
  },
  {
    postalCode: '04316',
    city: 'Leipzig'
  },
  {
    postalCode: '04317',
    city: 'Leipzig'
  },
  {
    postalCode: '04318',
    city: 'Leipzig'
  },
  {
    postalCode: '04319',
    city: 'Leipzig'
  },
  {
    postalCode: '04328',
    city: 'Leipzig'
  },
  {
    postalCode: '04329',
    city: 'Leipzig'
  },
  {
    postalCode: '04347',
    city: 'Leipzig'
  },
  {
    postalCode: '04349',
    city: 'Leipzig'
  },
  {
    postalCode: '04356',
    city: 'Leipzig'
  },
  {
    postalCode: '04357',
    city: 'Leipzig'
  },
  {
    postalCode: '04416',
    city: 'Markkleeberg'
  },
  {
    postalCode: '04420',
    city: 'Großlehna'
  },
  {
    postalCode: '04420',
    city: 'Kulkwitz'
  },
  {
    postalCode: '04420',
    city: 'Markranstädt'
  },
  {
    postalCode: '04425',
    city: 'Taucha'
  },
  {
    postalCode: '04435',
    city: 'Schkeuditz'
  },
  {
    postalCode: '04442',
    city: 'Zwenkau'
  },
  {
    postalCode: '04451',
    city: 'Borsdorf'
  },
  {
    postalCode: '04451',
    city: 'Panitzsch'
  },
  {
    postalCode: '04460',
    city: 'Kitzen'
  },
  {
    postalCode: '04463',
    city: 'Großpösna'
  },
  {
    postalCode: '04509',
    city: 'Brodau'
  },
  {
    postalCode: '04509',
    city: 'Delitzsch'
  },
  {
    postalCode: '04509',
    city: 'Döbernitz'
  },
  {
    postalCode: '04509',
    city: 'Krostitz'
  },
  {
    postalCode: '04509',
    city: 'Löbnitz'
  },
  {
    postalCode: '04509',
    city: 'Neukyhna'
  },
  {
    postalCode: '04509',
    city: 'Priester'
  },
  {
    postalCode: '04509',
    city: 'Schönwölkau'
  },
  {
    postalCode: '04509',
    city: 'Selben'
  },
  {
    postalCode: '04509',
    city: 'Wiedemar'
  },
  {
    postalCode: '04509',
    city: 'Zschepen'
  },
  {
    postalCode: '04509',
    city: 'Zschortau'
  },
  {
    postalCode: '04509',
    city: 'Zwochau'
  },
  {
    postalCode: '04519',
    city: 'Kletzen-Zschölkau'
  },
  {
    postalCode: '04519',
    city: 'Rackwitz'
  },
  {
    postalCode: '04523',
    city: 'Elstertrebnitz'
  },
  {
    postalCode: '04523',
    city: 'Pegau'
  },
  {
    postalCode: '04539',
    city: 'Groitzsch'
  },
  {
    postalCode: '04552',
    city: 'Borna'
  },
  {
    postalCode: '04552',
    city: 'Lobstädt'
  },
  {
    postalCode: '04552',
    city: 'Wyhratal'
  },
  {
    postalCode: '04564',
    city: 'Böhlen'
  },
  {
    postalCode: '04565',
    city: 'Regis-Breitingen'
  },
  {
    postalCode: '04567',
    city: 'Kitzscher'
  },
  {
    postalCode: '04571',
    city: 'Rötha'
  },
  {
    postalCode: '04574',
    city: 'Deutzen'
  },
  {
    postalCode: '04574',
    city: 'Heuersdorf'
  },
  {
    postalCode: '04575',
    city: 'Neukieritzsch'
  },
  {
    postalCode: '04579',
    city: 'Espenhain'
  },
  {
    postalCode: '04579',
    city: 'Mölbis'
  },
  {
    postalCode: '04600',
    city: 'Altenburg'
  },
  {
    postalCode: '04603',
    city: 'Göhren'
  },
  {
    postalCode: '04603',
    city: 'Nobitz'
  },
  {
    postalCode: '04603',
    city: 'Saara'
  },
  {
    postalCode: '04603',
    city: 'Windischleuba'
  },
  {
    postalCode: '04610',
    city: 'Meuselwitz'
  },
  {
    postalCode: '04610',
    city: 'Wintersdorf'
  },
  {
    postalCode: '04613',
    city: 'Lucka'
  },
  {
    postalCode: '04617',
    city: 'Fockendorf'
  },
  {
    postalCode: '04617',
    city: 'Gerstenberg'
  },
  {
    postalCode: '04617',
    city: 'Großröda'
  },
  {
    postalCode: '04617',
    city: 'Haselbach'
  },
  {
    postalCode: '04617',
    city: 'Kriebitzsch'
  },
  {
    postalCode: '04617',
    city: 'Lehma'
  },
  {
    postalCode: '04617',
    city: 'Lödla'
  },
  {
    postalCode: '04617',
    city: 'Monstab'
  },
  {
    postalCode: '04617',
    city: 'Naundorf'
  },
  {
    postalCode: '04617',
    city: 'Neubraunshain'
  },
  {
    postalCode: '04617',
    city: 'Rositz'
  },
  {
    postalCode: '04617',
    city: 'Starkenberg'
  },
  {
    postalCode: '04617',
    city: 'Tegkwitz'
  },
  {
    postalCode: '04617',
    city: 'Treben'
  },
  {
    postalCode: '04617',
    city: 'Waltersdorf'
  },
  {
    postalCode: '04618',
    city: 'Frohnsdorf'
  },
  {
    postalCode: '04618',
    city: 'Göpfersdorf'
  },
  {
    postalCode: '04618',
    city: 'Jückelberg'
  },
  {
    postalCode: '04618',
    city: 'Langenleuba-Niederhain'
  },
  {
    postalCode: '04618',
    city: 'Ziegelheim'
  },
  {
    postalCode: '04626',
    city: 'Altkirchen'
  },
  {
    postalCode: '04626',
    city: 'Dobitschen'
  },
  {
    postalCode: '04626',
    city: 'Drogen'
  },
  {
    postalCode: '04626',
    city: 'Göllnitz'
  },
  {
    postalCode: '04626',
    city: 'Heukewalde'
  },
  {
    postalCode: '04626',
    city: 'Heyersdorf'
  },
  {
    postalCode: '04626',
    city: 'Jonaswalde'
  },
  {
    postalCode: '04626',
    city: 'Löbichau'
  },
  {
    postalCode: '04626',
    city: 'Lumpzig'
  },
  {
    postalCode: '04626',
    city: 'Mehna'
  },
  {
    postalCode: '04626',
    city: 'Nöbdenitz'
  },
  {
    postalCode: '04626',
    city: 'Posterstein'
  },
  {
    postalCode: '04626',
    city: 'Schmölln'
  },
  {
    postalCode: '04626',
    city: 'Thonhausen'
  },
  {
    postalCode: '04626',
    city: 'Vollmershain'
  },
  {
    postalCode: '04626',
    city: 'Wildenbörten'
  },
  {
    postalCode: '04639',
    city: 'Gößnitz'
  },
  {
    postalCode: '04639',
    city: 'Ponitz'
  },
  {
    postalCode: '04643',
    city: 'Altottenhain'
  },
  {
    postalCode: '04643',
    city: 'Bruchheim'
  },
  {
    postalCode: '04643',
    city: 'Frankenhain'
  },
  {
    postalCode: '04643',
    city: 'Geithain'
  },
  {
    postalCode: '04643',
    city: 'Kolka'
  },
  {
    postalCode: '04643',
    city: 'Niederpickenhain'
  },
  {
    postalCode: '04643',
    city: 'Ossa'
  },
  {
    postalCode: '04643',
    city: 'Ottenhain'
  },
  {
    postalCode: '04643',
    city: 'Tautenhain'
  },
  {
    postalCode: '04643',
    city: 'Wenigossa'
  },
  {
    postalCode: '04651',
    city: 'Bad Lausick'
  },
  {
    postalCode: '04651',
    city: 'Elbisbach'
  },
  {
    postalCode: '04651',
    city: 'Flößberg'
  },
  {
    postalCode: '04651',
    city: 'Hopfgarten'
  },
  {
    postalCode: '04651',
    city: 'Prießnitz'
  },
  {
    postalCode: '04651',
    city: 'Trebishain'
  },
  {
    postalCode: '04654',
    city: 'Frohburg'
  },
  {
    postalCode: '04655',
    city: 'Kohren-Sahlis'
  },
  {
    postalCode: '04657',
    city: 'Langensteinbach'
  },
  {
    postalCode: '04657',
    city: 'Narsdorf'
  },
  {
    postalCode: '04657',
    city: 'Rathendorf'
  },
  {
    postalCode: '04668',
    city: 'Böhlen'
  },
  {
    postalCode: '04668',
    city: 'Bröhsen'
  },
  {
    postalCode: '04668',
    city: 'Draschwitz'
  },
  {
    postalCode: '04668',
    city: 'Dürrweitzschen'
  },
  {
    postalCode: '04668',
    city: 'Frauendorf'
  },
  {
    postalCode: '04668',
    city: 'Grimma'
  },
  {
    postalCode: '04668',
    city: 'Großbardau'
  },
  {
    postalCode: '04668',
    city: 'Großbothen'
  },
  {
    postalCode: '04668',
    city: 'Haubitz'
  },
  {
    postalCode: '04668',
    city: 'Keiselwitz'
  },
  {
    postalCode: '04668',
    city: 'Kuckeland'
  },
  {
    postalCode: '04668',
    city: 'Leipnitz'
  },
  {
    postalCode: '04668',
    city: 'Motterwitz'
  },
  {
    postalCode: '04668',
    city: 'Muschau'
  },
  {
    postalCode: '04668',
    city: 'Nauberg'
  },
  {
    postalCode: '04668',
    city: 'Ostrau'
  },
  {
    postalCode: '04668',
    city: 'Otterwisch'
  },
  {
    postalCode: '04668',
    city: 'Papsdorf'
  },
  {
    postalCode: '04668',
    city: 'Parthenstein'
  },
  {
    postalCode: '04668',
    city: 'Pöhsig'
  },
  {
    postalCode: '04668',
    city: 'Poischwitz'
  },
  {
    postalCode: '04668',
    city: 'Ragewitz'
  },
  {
    postalCode: '04668',
    city: 'Seidewitz'
  },
  {
    postalCode: '04668',
    city: 'Zaschwitz'
  },
  {
    postalCode: '04668',
    city: 'Zeunitz'
  },
  {
    postalCode: '04668',
    city: 'Zschoppach'
  },
  {
    postalCode: '04680',
    city: 'Bockwitz'
  },
  {
    postalCode: '04680',
    city: 'Colditz'
  },
  {
    postalCode: '04680',
    city: 'Collmen'
  },
  {
    postalCode: '04680',
    city: 'Commichau'
  },
  {
    postalCode: '04680',
    city: 'Meuselwitz'
  },
  {
    postalCode: '04680',
    city: 'Skoplau'
  },
  {
    postalCode: '04680',
    city: 'Tanndorf'
  },
  {
    postalCode: '04680',
    city: 'Zschadraß'
  },
  {
    postalCode: '04683',
    city: 'Belgershain'
  },
  {
    postalCode: '04683',
    city: 'Fuchshain'
  },
  {
    postalCode: '04683',
    city: 'Naunhof'
  },
  {
    postalCode: '04685',
    city: 'Nerchau'
  },
  {
    postalCode: '04687',
    city: 'Trebsen'
  },
  {
    postalCode: '04688',
    city: 'Mutzschen'
  },
  {
    postalCode: '04703',
    city: 'Bockelwitz'
  },
  {
    postalCode: '04703',
    city: 'Gersdorf'
  },
  {
    postalCode: '04703',
    city: 'Leisnig'
  },
  {
    postalCode: '04720',
    city: 'Beicha'
  },
  {
    postalCode: '04720',
    city: 'Choren'
  },
  {
    postalCode: '04720',
    city: 'Döbeln'
  },
  {
    postalCode: '04720',
    city: 'Dreißig'
  },
  {
    postalCode: '04720',
    city: 'Ebersbach'
  },
  {
    postalCode: '04720',
    city: 'Geleitshäuser'
  },
  {
    postalCode: '04720',
    city: 'Großweitzschen'
  },
  {
    postalCode: '04720',
    city: 'Juchhöh'
  },
  {
    postalCode: '04720',
    city: 'Kleinmockritz'
  },
  {
    postalCode: '04720',
    city: 'Leschen'
  },
  {
    postalCode: '04720',
    city: 'Lüttewitz bei Döbeln'
  },
  {
    postalCode: '04720',
    city: 'Maltitz'
  },
  {
    postalCode: '04720',
    city: 'Markritz'
  },
  {
    postalCode: '04720',
    city: 'Mochau'
  },
  {
    postalCode: '04720',
    city: 'Petersberg'
  },
  {
    postalCode: '04720',
    city: 'Prüfern'
  },
  {
    postalCode: '04720',
    city: 'Theeschütz'
  },
  {
    postalCode: '04720',
    city: 'Ziegra-Knobelsdorf'
  },
  {
    postalCode: '04720',
    city: 'Zschaitz-Ottewig'
  },
  {
    postalCode: '04736',
    city: 'Waldheim'
  },
  {
    postalCode: '04741',
    city: 'Gertitzsch'
  },
  {
    postalCode: '04741',
    city: 'Niederstriegis'
  },
  {
    postalCode: '04741',
    city: 'Roßwein'
  },
  {
    postalCode: '04746',
    city: 'Hartha'
  },
  {
    postalCode: '04749',
    city: 'Ostrau'
  },
  {
    postalCode: '04758',
    city: 'Cavertitz'
  },
  {
    postalCode: '04758',
    city: 'Hof'
  },
  {
    postalCode: '04758',
    city: 'Liebschützberg'
  },
  {
    postalCode: '04758',
    city: 'Oschatz'
  },
  {
    postalCode: '04769',
    city: 'Mügeln'
  },
  {
    postalCode: '04769',
    city: 'Naundorf'
  },
  {
    postalCode: '04769',
    city: 'Salbitz'
  },
  {
    postalCode: '04769',
    city: 'Sornzig-Ablaß'
  },
  {
    postalCode: '04774',
    city: 'Dahlen'
  },
  {
    postalCode: '04779',
    city: 'Wermsdorf'
  },
  {
    postalCode: '04808',
    city: 'Dornreichenbach'
  },
  {
    postalCode: '04808',
    city: 'Falkenhain'
  },
  {
    postalCode: '04808',
    city: 'Hohburg'
  },
  {
    postalCode: '04808',
    city: 'Kühnitzsch'
  },
  {
    postalCode: '04808',
    city: 'Kühren-Burkartshain'
  },
  {
    postalCode: '04808',
    city: 'Meltewitz'
  },
  {
    postalCode: '04808',
    city: 'Thallwitz'
  },
  {
    postalCode: '04808',
    city: 'Thammenhain'
  },
  {
    postalCode: '04808',
    city: 'Voigtshain'
  },
  {
    postalCode: '04808',
    city: 'Wurzen'
  },
  {
    postalCode: '04821',
    city: 'Brandis'
  },
  {
    postalCode: '04821',
    city: 'Polenz'
  },
  {
    postalCode: '04824',
    city: 'Beucha'
  },
  {
    postalCode: '04827',
    city: 'Gerichshain'
  },
  {
    postalCode: '04827',
    city: 'Lübschützer Teiche'
  },
  {
    postalCode: '04827',
    city: 'Machern'
  },
  {
    postalCode: '04828',
    city: 'Altenbach'
  },
  {
    postalCode: '04828',
    city: 'Bach'
  },
  {
    postalCode: '04828',
    city: 'Bennewitz'
  },
  {
    postalCode: '04828',
    city: 'Deuben'
  },
  {
    postalCode: '04828',
    city: 'Grubnitz'
  },
  {
    postalCode: '04828',
    city: 'Nepperwitz'
  },
  {
    postalCode: '04828',
    city: 'Pausitz'
  },
  {
    postalCode: '04828',
    city: 'Püchau'
  },
  {
    postalCode: '04828',
    city: 'Rothersdorf'
  },
  {
    postalCode: '04828',
    city: 'Schmölen'
  },
  {
    postalCode: '04838',
    city: 'Audenhain'
  },
  {
    postalCode: '04838',
    city: 'Battaune'
  },
  {
    postalCode: '04838',
    city: 'Doberschütz'
  },
  {
    postalCode: '04838',
    city: 'Eilenburg'
  },
  {
    postalCode: '04838',
    city: 'Jesewitz'
  },
  {
    postalCode: '04838',
    city: 'Laußig'
  },
  {
    postalCode: '04838',
    city: 'Mockrehna'
  },
  {
    postalCode: '04838',
    city: 'Mörtitz'
  },
  {
    postalCode: '04838',
    city: 'Paschwitz'
  },
  {
    postalCode: '04838',
    city: 'Schöna'
  },
  {
    postalCode: '04838',
    city: 'Sprotta'
  },
  {
    postalCode: '04838',
    city: 'Strelln'
  },
  {
    postalCode: '04838',
    city: 'Wildenhain'
  },
  {
    postalCode: '04838',
    city: 'Wildschütz'
  },
  {
    postalCode: '04838',
    city: 'Wöllnau'
  },
  {
    postalCode: '04838',
    city: 'Zschepplin'
  },
  {
    postalCode: '04849',
    city: 'Authausen'
  },
  {
    postalCode: '04849',
    city: 'Bad Düben'
  },
  {
    postalCode: '04849',
    city: 'Kossa'
  },
  {
    postalCode: '04849',
    city: 'Pressel'
  },
  {
    postalCode: '04849',
    city: 'Schnaditz'
  },
  {
    postalCode: '04849',
    city: 'Tiefensee'
  },
  {
    postalCode: '04860',
    city: 'Großwig'
  },
  {
    postalCode: '04860',
    city: 'Klitzschen'
  },
  {
    postalCode: '04860',
    city: 'Süptitz'
  },
  {
    postalCode: '04860',
    city: 'Torgau'
  },
  {
    postalCode: '04860',
    city: 'Weidenhain'
  },
  {
    postalCode: '04860',
    city: 'Zinna'
  },
  {
    postalCode: '04874',
    city: 'Belgern'
  },
  {
    postalCode: '04880',
    city: 'Dommitzsch'
  },
  {
    postalCode: '04880',
    city: 'Elsnig'
  },
  {
    postalCode: '04880',
    city: 'Trossin'
  },
  {
    postalCode: '04880',
    city: 'Wörblitz'
  },
  {
    postalCode: '04886',
    city: 'Arzberg'
  },
  {
    postalCode: '04886',
    city: 'Beilrode'
  },
  {
    postalCode: '04886',
    city: 'Döbrichau'
  },
  {
    postalCode: '04886',
    city: 'Großtreben-Zwethau'
  },
  {
    postalCode: '04889',
    city: 'Langenreichenbach'
  },
  {
    postalCode: '04889',
    city: 'Pflückuff'
  },
  {
    postalCode: '04889',
    city: 'Schildau'
  },
  {
    postalCode: '04895',
    city: 'Bahnsdorf'
  },
  {
    postalCode: '04895',
    city: 'Beyern'
  },
  {
    postalCode: '04895',
    city: 'Brottewitz'
  },
  {
    postalCode: '04895',
    city: 'Buckau'
  },
  {
    postalCode: '04895',
    city: 'Burxdorf'
  },
  {
    postalCode: '04895',
    city: 'Falkenberg'
  },
  {
    postalCode: '04895',
    city: 'Fermerswalde'
  },
  {
    postalCode: '04895',
    city: 'Großrössen'
  },
  {
    postalCode: '04895',
    city: 'Kleinrössen'
  },
  {
    postalCode: '04895',
    city: 'Kölsa'
  },
  {
    postalCode: '04895',
    city: 'Koßdorf'
  },
  {
    postalCode: '04895',
    city: 'Löhsten'
  },
  {
    postalCode: '04895',
    city: 'Martinskirchen'
  },
  {
    postalCode: '04895',
    city: 'Neudeck'
  },
  {
    postalCode: '04895',
    city: 'Rehfeld'
  },
  {
    postalCode: '04895',
    city: 'Saxdorf'
  },
  {
    postalCode: '04895',
    city: 'Schmerkendorf'
  },
  {
    postalCode: '04895',
    city: 'Züllsdorf'
  },
  {
    postalCode: '04910',
    city: 'Elsterwerda'
  },
  {
    postalCode: '04910',
    city: 'Haida'
  },
  {
    postalCode: '04910',
    city: 'Kraupa'
  },
  {
    postalCode: '04916',
    city: 'Ahlsdorf'
  },
  {
    postalCode: '04916',
    city: 'Arnsnesta'
  },
  {
    postalCode: '04916',
    city: 'Bernsdorf'
  },
  {
    postalCode: '04916',
    city: 'Borken'
  },
  {
    postalCode: '04916',
    city: 'Brandis'
  },
  {
    postalCode: '04916',
    city: 'Dubro'
  },
  {
    postalCode: '04916',
    city: 'Friedersdorf'
  },
  {
    postalCode: '04916',
    city: 'Gräfendorf'
  },
  {
    postalCode: '04916',
    city: 'Grassau'
  },
  {
    postalCode: '04916',
    city: 'Herzberg'
  },
  {
    postalCode: '04916',
    city: 'Jeßnigk'
  },
  {
    postalCode: '04916',
    city: 'Mahdel'
  },
  {
    postalCode: '04916',
    city: 'Osteroda'
  },
  {
    postalCode: '04916',
    city: 'Polzen'
  },
  {
    postalCode: '04916',
    city: 'Rahnisdorf'
  },
  {
    postalCode: '04916',
    city: 'Schönewalde'
  },
  {
    postalCode: '04916',
    city: 'Stolzenhain'
  },
  {
    postalCode: '04916',
    city: 'Werchau'
  },
  {
    postalCode: '04916',
    city: 'Wildberg'
  },
  {
    postalCode: '04924',
    city: 'Bad Liebenwerda'
  },
  {
    postalCode: '04924',
    city: 'Beiersdorf'
  },
  {
    postalCode: '04924',
    city: 'Beutersitz'
  },
  {
    postalCode: '04924',
    city: 'Bönitz'
  },
  {
    postalCode: '04924',
    city: 'Dobra'
  },
  {
    postalCode: '04924',
    city: 'Domsdorf'
  },
  {
    postalCode: '04924',
    city: 'Kauxdorf'
  },
  {
    postalCode: '04924',
    city: 'Kröbeln'
  },
  {
    postalCode: '04924',
    city: 'Lausitz'
  },
  {
    postalCode: '04924',
    city: 'Maasdorf'
  },
  {
    postalCode: '04924',
    city: 'Marxdorf'
  },
  {
    postalCode: '04924',
    city: 'Oschätzchen'
  },
  {
    postalCode: '04924',
    city: 'Prestewitz'
  },
  {
    postalCode: '04924',
    city: 'Prieschka'
  },
  {
    postalCode: '04924',
    city: 'Rothstein'
  },
  {
    postalCode: '04924',
    city: 'Thalberg'
  },
  {
    postalCode: '04924',
    city: 'Theisa'
  },
  {
    postalCode: '04924',
    city: 'Wahrenbrück'
  },
  {
    postalCode: '04924',
    city: 'Wildgrube'
  },
  {
    postalCode: '04924',
    city: 'Winkel'
  },
  {
    postalCode: '04924',
    city: 'Zeischa'
  },
  {
    postalCode: '04924',
    city: 'Zobersdorf'
  },
  {
    postalCode: '04928',
    city: 'Döllingen'
  },
  {
    postalCode: '04928',
    city: 'Kahla'
  },
  {
    postalCode: '04928',
    city: 'Plessa'
  },
  {
    postalCode: '04928',
    city: 'Schraden'
  },
  {
    postalCode: '04931',
    city: 'Altenau'
  },
  {
    postalCode: '04931',
    city: 'Fichtenberg'
  },
  {
    postalCode: '04931',
    city: 'Kosilenzien'
  },
  {
    postalCode: '04931',
    city: 'Langenrieth'
  },
  {
    postalCode: '04931',
    city: 'Möglenz'
  },
  {
    postalCode: '04931',
    city: 'Mühlberg'
  },
  {
    postalCode: '04931',
    city: 'Neuburxdorf'
  },
  {
    postalCode: '04932',
    city: 'Gröden'
  },
  {
    postalCode: '04932',
    city: 'Großthiemig'
  },
  {
    postalCode: '04932',
    city: 'Hirschfeld'
  },
  {
    postalCode: '04932',
    city: 'Merzdorf'
  },
  {
    postalCode: '04932',
    city: 'Prösen'
  },
  {
    postalCode: '04932',
    city: 'Reichenhain'
  },
  {
    postalCode: '04932',
    city: 'Saathain'
  },
  {
    postalCode: '04932',
    city: 'Stolzenhain an d Röder'
  },
  {
    postalCode: '04932',
    city: 'Wainsdorf'
  },
  {
    postalCode: '04934',
    city: 'Hohenleipisch'
  },
  {
    postalCode: '04936',
    city: 'Frankenhain'
  },
  {
    postalCode: '04936',
    city: 'Freileben'
  },
  {
    postalCode: '04936',
    city: 'Hillmersdorf'
  },
  {
    postalCode: '04936',
    city: 'Hohenbucko'
  },
  {
    postalCode: '04936',
    city: 'Jagsal'
  },
  {
    postalCode: '04936',
    city: 'Kolochau'
  },
  {
    postalCode: '04936',
    city: 'Körba'
  },
  {
    postalCode: '04936',
    city: 'Lebusa'
  },
  {
    postalCode: '04936',
    city: 'Malitschkendorf'
  },
  {
    postalCode: '04936',
    city: 'Naundorf'
  },
  {
    postalCode: '04936',
    city: 'Oelsig'
  },
  {
    postalCode: '04936',
    city: 'Proßmarke'
  },
  {
    postalCode: '04936',
    city: 'Schlieben'
  },
  {
    postalCode: '04936',
    city: 'Schöna-Kolpien'
  },
  {
    postalCode: '04936',
    city: 'Stechau'
  },
  {
    postalCode: '04936',
    city: 'Wehrhain'
  },
  {
    postalCode: '04938',
    city: 'Drasdo'
  },
  {
    postalCode: '04938',
    city: 'Langennaundorf'
  },
  {
    postalCode: '04938',
    city: 'Uebigau'
  },
  {
    postalCode: '04938',
    city: 'Wiederau'
  },
  {
    postalCode: '06108',
    city: 'Halle'
  },
  {
    postalCode: '06110',
    city: 'Halle'
  },
  {
    postalCode: '06112',
    city: 'Halle'
  },
  {
    postalCode: '06114',
    city: 'Halle'
  },
  {
    postalCode: '06116',
    city: 'Halle'
  },
  {
    postalCode: '06118',
    city: 'Halle'
  },
  {
    postalCode: '06120',
    city: 'Halle'
  },
  {
    postalCode: '06120',
    city: 'Lieskau'
  },
  {
    postalCode: '06122',
    city: 'Halle'
  },
  {
    postalCode: '06124',
    city: 'Halle'
  },
  {
    postalCode: '06126',
    city: 'Halle'
  },
  {
    postalCode: '06128',
    city: 'Halle'
  },
  {
    postalCode: '06130',
    city: 'Halle'
  },
  {
    postalCode: '06132',
    city: 'Halle'
  },
  {
    postalCode: '06179',
    city: 'Angersdorf'
  },
  {
    postalCode: '06179',
    city: 'Benkendorf bei Salzmünde'
  },
  {
    postalCode: '06179',
    city: 'Bennstedt'
  },
  {
    postalCode: '06179',
    city: 'Dornstedt'
  },
  {
    postalCode: '06179',
    city: 'Hohenweiden'
  },
  {
    postalCode: '06179',
    city: 'Höhnstedt'
  },
  {
    postalCode: '06179',
    city: 'Holleben'
  },
  {
    postalCode: '06179',
    city: 'Langenbogen'
  },
  {
    postalCode: '06179',
    city: 'Quillschina'
  },
  {
    postalCode: '06179',
    city: 'Schochwitz'
  },
  {
    postalCode: '06179',
    city: 'Steuden'
  },
  {
    postalCode: '06179',
    city: 'Teutschenthal'
  },
  {
    postalCode: '06179',
    city: 'Zappendorf'
  },
  {
    postalCode: '06179',
    city: 'Zscherben'
  },
  {
    postalCode: '06184',
    city: 'Burgliebenau'
  },
  {
    postalCode: '06184',
    city: 'Dieskau'
  },
  {
    postalCode: '06184',
    city: 'Dölbau'
  },
  {
    postalCode: '06184',
    city: 'Döllnitz'
  },
  {
    postalCode: '06184',
    city: 'Ermlitz'
  },
  {
    postalCode: '06184',
    city: 'Gröbers'
  },
  {
    postalCode: '06184',
    city: 'Großkugel'
  },
  {
    postalCode: '06184',
    city: 'Lochau'
  },
  {
    postalCode: '06184',
    city: 'Raßnitz'
  },
  {
    postalCode: '06184',
    city: 'Röglitz'
  },
  {
    postalCode: '06188',
    city: 'Brachstedt'
  },
  {
    postalCode: '06188',
    city: 'Braschwitz'
  },
  {
    postalCode: '06188',
    city: 'Hohenthurm'
  },
  {
    postalCode: '06188',
    city: 'Landsberg'
  },
  {
    postalCode: '06188',
    city: 'Niemberg'
  },
  {
    postalCode: '06188',
    city: 'Oppin'
  },
  {
    postalCode: '06188',
    city: 'Peißen'
  },
  {
    postalCode: '06188',
    city: 'Queis'
  },
  {
    postalCode: '06188',
    city: 'Reußen'
  },
  {
    postalCode: '06188',
    city: 'Schwerz'
  },
  {
    postalCode: '06188',
    city: 'Sietzsch'
  },
  {
    postalCode: '06188',
    city: 'Spickendorf'
  },
  {
    postalCode: '06193',
    city: 'Gutenberg'
  },
  {
    postalCode: '06193',
    city: 'Krosigk'
  },
  {
    postalCode: '06193',
    city: 'Kütten'
  },
  {
    postalCode: '06193',
    city: 'Löbejün'
  },
  {
    postalCode: '06193',
    city: 'Morl'
  },
  {
    postalCode: '06193',
    city: 'Mösthinsdorf'
  },
  {
    postalCode: '06193',
    city: 'Nauendorf'
  },
  {
    postalCode: '06193',
    city: 'Nehlitz'
  },
  {
    postalCode: '06193',
    city: 'Ostrau'
  },
  {
    postalCode: '06193',
    city: 'Petersberg'
  },
  {
    postalCode: '06193',
    city: 'Plötz'
  },
  {
    postalCode: '06193',
    city: 'Sennewitz'
  },
  {
    postalCode: '06193',
    city: 'Teicha'
  },
  {
    postalCode: '06193',
    city: 'Wallwitz'
  },
  {
    postalCode: '06198',
    city: 'Beesenstedt'
  },
  {
    postalCode: '06198',
    city: 'Brachwitz'
  },
  {
    postalCode: '06198',
    city: 'Döblitz'
  },
  {
    postalCode: '06198',
    city: 'Dößel'
  },
  {
    postalCode: '06198',
    city: 'Fienstedt'
  },
  {
    postalCode: '06198',
    city: 'Gimritz'
  },
  {
    postalCode: '06198',
    city: 'Kloschwitz'
  },
  {
    postalCode: '06198',
    city: 'Neutz-Lettewitz'
  },
  {
    postalCode: '06198',
    city: 'Salzmünde'
  },
  {
    postalCode: '06198',
    city: 'Wettin'
  },
  {
    postalCode: '06217',
    city: 'Beuna'
  },
  {
    postalCode: '06217',
    city: 'Geusa'
  },
  {
    postalCode: '06217',
    city: 'Gut Werder'
  },
  {
    postalCode: '06217',
    city: 'Merseburg'
  },
  {
    postalCode: '06217',
    city: 'Meuschau'
  },
  {
    postalCode: '06217',
    city: 'Trebnitz'
  },
  {
    postalCode: '06231',
    city: 'Bad Dürrenberg'
  },
  {
    postalCode: '06231',
    city: 'Kötzschau'
  },
  {
    postalCode: '06231',
    city: 'Kreypau'
  },
  {
    postalCode: '06231',
    city: 'Nempitz'
  },
  {
    postalCode: '06231',
    city: 'Oebles-Schlechtewitz'
  },
  {
    postalCode: '06231',
    city: 'Rodden'
  },
  {
    postalCode: '06231',
    city: 'Tollwitz'
  },
  {
    postalCode: '06237',
    city: 'Leuna'
  },
  {
    postalCode: '06237',
    city: 'Spergau'
  },
  {
    postalCode: '06242',
    city: 'Braunsbedra'
  },
  {
    postalCode: '06242',
    city: 'Großkayna'
  },
  {
    postalCode: '06242',
    city: 'Krumpa'
  },
  {
    postalCode: '06242',
    city: 'Roßbach'
  },
  {
    postalCode: '06246',
    city: 'Bad Lauchstädt'
  },
  {
    postalCode: '06246',
    city: 'Delitz'
  },
  {
    postalCode: '06246',
    city: 'Klobikau'
  },
  {
    postalCode: '06246',
    city: 'Knapendorf'
  },
  {
    postalCode: '06246',
    city: 'Milzau'
  },
  {
    postalCode: '06249',
    city: 'Mücheln'
  },
  {
    postalCode: '06254',
    city: 'Friedensdorf'
  },
  {
    postalCode: '06254',
    city: 'Günthersdorf'
  },
  {
    postalCode: '06254',
    city: 'Horburg-Maßlau'
  },
  {
    postalCode: '06254',
    city: 'Kötschlitz'
  },
  {
    postalCode: '06254',
    city: 'Luppenau'
  },
  {
    postalCode: '06254',
    city: 'Wallendorf'
  },
  {
    postalCode: '06254',
    city: 'Zöschen'
  },
  {
    postalCode: '06254',
    city: 'Zweimen'
  },
  {
    postalCode: '06255',
    city: 'Schafstädt'
  },
  {
    postalCode: '06255',
    city: 'Wünsch'
  },
  {
    postalCode: '06258',
    city: 'Korbetha'
  },
  {
    postalCode: '06258',
    city: 'Schkopau'
  },
  {
    postalCode: '06259',
    city: 'Frankleben'
  },
  {
    postalCode: '06268',
    city: 'Albersroda'
  },
  {
    postalCode: '06268',
    city: 'Barnstädt'
  },
  {
    postalCode: '06268',
    city: 'Gatterstädt'
  },
  {
    postalCode: '06268',
    city: 'Grockstädt'
  },
  {
    postalCode: '06268',
    city: 'Kuckenburg'
  },
  {
    postalCode: '06268',
    city: 'Langeneichstädt'
  },
  {
    postalCode: '06268',
    city: 'Leimbach'
  },
  {
    postalCode: '06268',
    city: 'Nemsdorf-Göhrendorf'
  },
  {
    postalCode: '06268',
    city: 'Obhausen'
  },
  {
    postalCode: '06268',
    city: 'Oechlitz'
  },
  {
    postalCode: '06268',
    city: 'Querfurt'
  },
  {
    postalCode: '06268',
    city: 'Schmon'
  },
  {
    postalCode: '06268',
    city: 'Steigra'
  },
  {
    postalCode: '06268',
    city: 'Vitzenburg'
  },
  {
    postalCode: '06268',
    city: 'Weißenschirmbach'
  },
  {
    postalCode: '06268',
    city: 'Ziegelroda'
  },
  {
    postalCode: '06279',
    city: 'Alberstedt'
  },
  {
    postalCode: '06279',
    city: 'Esperstedt'
  },
  {
    postalCode: '06279',
    city: 'Farnstädt'
  },
  {
    postalCode: '06279',
    city: 'Schraplau'
  },
  {
    postalCode: '06295',
    city: 'Bischofrode'
  },
  {
    postalCode: '06295',
    city: 'Bornstedt'
  },
  {
    postalCode: '06295',
    city: 'Burgsdorf'
  },
  {
    postalCode: '06295',
    city: 'Dederstedt'
  },
  {
    postalCode: '06295',
    city: 'Hedersleben'
  },
  {
    postalCode: '06295',
    city: 'Hornburg'
  },
  {
    postalCode: '06295',
    city: 'Lutherstadt Eisleben'
  },
  {
    postalCode: '06295',
    city: 'Neehausen'
  },
  {
    postalCode: '06295',
    city: 'Osterhausen'
  },
  {
    postalCode: '06295',
    city: 'Polleben'
  },
  {
    postalCode: '06295',
    city: 'Rothenschirmbach'
  },
  {
    postalCode: '06295',
    city: 'Rottelsdorf'
  },
  {
    postalCode: '06295',
    city: 'Schmalzerode'
  },
  {
    postalCode: '06295',
    city: 'Schwittersdorf'
  },
  {
    postalCode: '06295',
    city: 'Unterrißdorf'
  },
  {
    postalCode: '06295',
    city: 'Volkstedt'
  },
  {
    postalCode: '06295',
    city: 'Wolferode'
  },
  {
    postalCode: '06308',
    city: 'Annarode'
  },
  {
    postalCode: '06308',
    city: 'Augsdorf'
  },
  {
    postalCode: '06308',
    city: 'Benndorf'
  },
  {
    postalCode: '06308',
    city: 'Hübitz'
  },
  {
    postalCode: '06308',
    city: 'Klostermansfeld'
  },
  {
    postalCode: '06308',
    city: 'Siebigerode'
  },
  {
    postalCode: '06308',
    city: 'Siersleben'
  },
  {
    postalCode: '06311',
    city: 'Helbra'
  },
  {
    postalCode: '06313',
    city: 'Ahlsdorf'
  },
  {
    postalCode: '06313',
    city: 'Hergisdorf'
  },
  {
    postalCode: '06313',
    city: 'Wimmelburg'
  },
  {
    postalCode: '06317',
    city: 'Amsdorf'
  },
  {
    postalCode: '06317',
    city: 'Aseleben'
  },
  {
    postalCode: '06317',
    city: 'Erdeborn'
  },
  {
    postalCode: '06317',
    city: 'Lüttchendorf'
  },
  {
    postalCode: '06317',
    city: 'Röblingen am See'
  },
  {
    postalCode: '06317',
    city: 'Seeburg'
  },
  {
    postalCode: '06317',
    city: 'Stedten'
  },
  {
    postalCode: '06318',
    city: 'Wansleben am See'
  },
  {
    postalCode: '06333',
    city: 'Arnstedt'
  },
  {
    postalCode: '06333',
    city: 'Bräunrode'
  },
  {
    postalCode: '06333',
    city: 'Endorf'
  },
  {
    postalCode: '06333',
    city: 'Greifenhagen'
  },
  {
    postalCode: '06333',
    city: 'Hettstedt'
  },
  {
    postalCode: '06333',
    city: 'Neuplatendorf'
  },
  {
    postalCode: '06333',
    city: 'Quenstedt'
  },
  {
    postalCode: '06333',
    city: 'Ritterode'
  },
  {
    postalCode: '06333',
    city: 'Saurasen'
  },
  {
    postalCode: '06333',
    city: 'Sylda'
  },
  {
    postalCode: '06333',
    city: 'Walbeck'
  },
  {
    postalCode: '06333',
    city: 'Welbsleben'
  },
  {
    postalCode: '06333',
    city: 'Welfesholz'
  },
  {
    postalCode: '06333',
    city: 'Wiederstedt'
  },
  {
    postalCode: '06343',
    city: 'Biesenrode'
  },
  {
    postalCode: '06343',
    city: 'Gorenzen'
  },
  {
    postalCode: '06343',
    city: 'Mansfeld'
  },
  {
    postalCode: '06343',
    city: 'Möllendorf'
  },
  {
    postalCode: '06343',
    city: 'Piskaborn'
  },
  {
    postalCode: '06343',
    city: 'Rödgen'
  },
  {
    postalCode: '06343',
    city: 'Vatterode'
  },
  {
    postalCode: '06347',
    city: 'Freist'
  },
  {
    postalCode: '06347',
    city: 'Friedeburg'
  },
  {
    postalCode: '06347',
    city: 'Friedeburgerhütte'
  },
  {
    postalCode: '06347',
    city: 'Gerbstedt'
  },
  {
    postalCode: '06347',
    city: 'Heiligenthal'
  },
  {
    postalCode: '06347',
    city: 'Ihlewitz'
  },
  {
    postalCode: '06347',
    city: 'Zabenstedt'
  },
  {
    postalCode: '06348',
    city: 'Großörner'
  },
  {
    postalCode: '06366',
    city: 'Köthen'
  },
  {
    postalCode: '06369',
    city: 'Arensdorf'
  },
  {
    postalCode: '06369',
    city: 'Cosa'
  },
  {
    postalCode: '06369',
    city: 'Cösitz'
  },
  {
    postalCode: '06369',
    city: 'Diebzig'
  },
  {
    postalCode: '06369',
    city: 'Dohndorf'
  },
  {
    postalCode: '06369',
    city: 'Dornbock'
  },
  {
    postalCode: '06369',
    city: 'Drosa'
  },
  {
    postalCode: '06369',
    city: 'Elsdorf'
  },
  {
    postalCode: '06369',
    city: 'Glauzig'
  },
  {
    postalCode: '06369',
    city: 'Gnetsch'
  },
  {
    postalCode: '06369',
    city: 'Görzig'
  },
  {
    postalCode: '06369',
    city: 'Großbadegast'
  },
  {
    postalCode: '06369',
    city: 'Großpaschleben'
  },
  {
    postalCode: '06369',
    city: 'Kleinpaschleben'
  },
  {
    postalCode: '06369',
    city: 'Libehna'
  },
  {
    postalCode: '06369',
    city: 'Löbnitz an der Linde'
  },
  {
    postalCode: '06369',
    city: 'Merzien'
  },
  {
    postalCode: '06369',
    city: 'Porst'
  },
  {
    postalCode: '06369',
    city: 'Prosigk'
  },
  {
    postalCode: '06369',
    city: 'Radegast'
  },
  {
    postalCode: '06369',
    city: 'Reupzig'
  },
  {
    postalCode: '06369',
    city: 'Riesdorf'
  },
  {
    postalCode: '06369',
    city: 'Schortewitz'
  },
  {
    postalCode: '06369',
    city: 'Trebbichau'
  },
  {
    postalCode: '06369',
    city: 'Trinum'
  },
  {
    postalCode: '06369',
    city: 'Weißandt-Gölzau'
  },
  {
    postalCode: '06369',
    city: 'Wörbzig'
  },
  {
    postalCode: '06369',
    city: 'Wulfen'
  },
  {
    postalCode: '06369',
    city: 'Wülknitz'
  },
  {
    postalCode: '06369',
    city: 'Zabitz'
  },
  {
    postalCode: '06369',
    city: 'Zehbitz'
  },
  {
    postalCode: '06385',
    city: 'Aken'
  },
  {
    postalCode: '06386',
    city: 'Chörau'
  },
  {
    postalCode: '06386',
    city: 'Elsnigk'
  },
  {
    postalCode: '06386',
    city: 'Fraßdorf'
  },
  {
    postalCode: '06386',
    city: 'Friedrichsdorf'
  },
  {
    postalCode: '06386',
    city: 'Hinsdorf'
  },
  {
    postalCode: '06386',
    city: 'Kleinzerbst'
  },
  {
    postalCode: '06386',
    city: 'Libbesdorf'
  },
  {
    postalCode: '06386',
    city: 'Meilendorf'
  },
  {
    postalCode: '06386',
    city: 'Micheln'
  },
  {
    postalCode: '06386',
    city: 'Osternienburg'
  },
  {
    postalCode: '06386',
    city: 'Quellendorf'
  },
  {
    postalCode: '06386',
    city: 'Reppichau'
  },
  {
    postalCode: '06386',
    city: 'Scheuder'
  },
  {
    postalCode: '06386',
    city: 'Storkau'
  },
  {
    postalCode: '06388',
    city: 'Baasdorf'
  },
  {
    postalCode: '06388',
    city: 'Edderitz'
  },
  {
    postalCode: '06388',
    city: 'Gröbzig'
  },
  {
    postalCode: '06388',
    city: 'Maasdorf'
  },
  {
    postalCode: '06388',
    city: 'Piethen'
  },
  {
    postalCode: '06388',
    city: 'Wieskau'
  },
  {
    postalCode: '06406',
    city: 'Bernburg'
  },
  {
    postalCode: '06408',
    city: 'Aderstedt'
  },
  {
    postalCode: '06408',
    city: 'Baalberge'
  },
  {
    postalCode: '06408',
    city: 'Biendorf'
  },
  {
    postalCode: '06408',
    city: 'Cörmigk'
  },
  {
    postalCode: '06408',
    city: 'Gröna'
  },
  {
    postalCode: '06408',
    city: 'Ilberstedt'
  },
  {
    postalCode: '06408',
    city: 'Latdorf'
  },
  {
    postalCode: '06408',
    city: 'Peißen'
  },
  {
    postalCode: '06408',
    city: 'Poley'
  },
  {
    postalCode: '06408',
    city: 'Preußlitz'
  },
  {
    postalCode: '06408',
    city: 'Wohlsdorf'
  },
  {
    postalCode: '06408',
    city: 'Zepzig'
  },
  {
    postalCode: '06420',
    city: 'Domnitz'
  },
  {
    postalCode: '06420',
    city: 'Edlau'
  },
  {
    postalCode: '06420',
    city: 'Gerlebogk'
  },
  {
    postalCode: '06420',
    city: 'Gnölbzig'
  },
  {
    postalCode: '06420',
    city: 'Golbitz'
  },
  {
    postalCode: '06420',
    city: 'Könnern'
  },
  {
    postalCode: '06420',
    city: 'Lebendorf'
  },
  {
    postalCode: '06420',
    city: 'Rothenburg'
  },
  {
    postalCode: '06420',
    city: 'Trebnitz'
  },
  {
    postalCode: '06420',
    city: 'Wiendorf'
  },
  {
    postalCode: '06420',
    city: 'Zickeritz'
  },
  {
    postalCode: '06425',
    city: 'Alsleben'
  },
  {
    postalCode: '06425',
    city: 'Beesenlaublingen'
  },
  {
    postalCode: '06425',
    city: 'Belleben'
  },
  {
    postalCode: '06425',
    city: 'Plötzkau'
  },
  {
    postalCode: '06425',
    city: 'Schackstedt'
  },
  {
    postalCode: '06425',
    city: 'Strenznaundorf'
  },
  {
    postalCode: '06429',
    city: 'Gerbitz'
  },
  {
    postalCode: '06429',
    city: 'Neugattersleben'
  },
  {
    postalCode: '06429',
    city: 'Nienburg'
  },
  {
    postalCode: '06429',
    city: 'Pobzig'
  },
  {
    postalCode: '06429',
    city: 'Wedlitz'
  },
  {
    postalCode: '06449',
    city: 'Aschersleben'
  },
  {
    postalCode: '06449',
    city: 'Cochstedt'
  },
  {
    postalCode: '06449',
    city: 'Friedrichsaue'
  },
  {
    postalCode: '06449',
    city: 'Giersleben'
  },
  {
    postalCode: '06449',
    city: 'Groß Schierstedt'
  },
  {
    postalCode: '06449',
    city: 'Klein Schierstedt'
  },
  {
    postalCode: '06449',
    city: 'Neu Königsaue'
  },
  {
    postalCode: '06449',
    city: 'Schackenthal'
  },
  {
    postalCode: '06449',
    city: 'Schadeleben'
  },
  {
    postalCode: '06449',
    city: 'Westdorf'
  },
  {
    postalCode: '06449',
    city: 'Wilsleben'
  },
  {
    postalCode: '06449',
    city: 'Winningen'
  },
  {
    postalCode: '06456',
    city: 'Drohndorf'
  },
  {
    postalCode: '06456',
    city: 'Freckleben'
  },
  {
    postalCode: '06456',
    city: 'Mehringen'
  },
  {
    postalCode: '06456',
    city: 'Sandersleben'
  },
  {
    postalCode: '06458',
    city: 'Hausneindorf'
  },
  {
    postalCode: '06458',
    city: 'Hedersleben'
  },
  {
    postalCode: '06458',
    city: 'Heteborn'
  },
  {
    postalCode: '06458',
    city: 'Wedderstedt'
  },
  {
    postalCode: '06463',
    city: 'Ermsleben'
  },
  {
    postalCode: '06463',
    city: 'Meisdorf'
  },
  {
    postalCode: '06463',
    city: 'Radisleben'
  },
  {
    postalCode: '06463',
    city: 'Reinstedt'
  },
  {
    postalCode: '06464',
    city: 'Frose'
  },
  {
    postalCode: '06466',
    city: 'Gatersleben'
  },
  {
    postalCode: '06467',
    city: 'Hoym'
  },
  {
    postalCode: '06469',
    city: 'Nachterstedt'
  },
  {
    postalCode: '06484',
    city: 'Ditfurt'
  },
  {
    postalCode: '06484',
    city: 'Quedlinburg'
  },
  {
    postalCode: '06484',
    city: 'Westerhausen'
  },
  {
    postalCode: '06493',
    city: 'Badeborn'
  },
  {
    postalCode: '06493',
    city: 'Ballenstedt'
  },
  {
    postalCode: '06493',
    city: 'Dankerode'
  },
  {
    postalCode: '06493',
    city: 'Harzgerode'
  },
  {
    postalCode: '06493',
    city: 'Königerode'
  },
  {
    postalCode: '06493',
    city: 'Neudorf'
  },
  {
    postalCode: '06493',
    city: 'Schielo'
  },
  {
    postalCode: '06493',
    city: 'Straßberg'
  },
  {
    postalCode: '06502',
    city: 'Neinstedt'
  },
  {
    postalCode: '06502',
    city: 'Thale'
  },
  {
    postalCode: '06502',
    city: 'Timmenrode'
  },
  {
    postalCode: '06502',
    city: 'Warnstedt'
  },
  {
    postalCode: '06502',
    city: 'Weddersleben'
  },
  {
    postalCode: '06507',
    city: 'Allrode'
  },
  {
    postalCode: '06507',
    city: 'Bad Suderode'
  },
  {
    postalCode: '06507',
    city: 'Friedrichsbrunn'
  },
  {
    postalCode: '06507',
    city: 'Gernrode'
  },
  {
    postalCode: '06507',
    city: 'Güntersberge'
  },
  {
    postalCode: '06507',
    city: 'Rieder'
  },
  {
    postalCode: '06507',
    city: 'Siptenfelde'
  },
  {
    postalCode: '06507',
    city: 'Stecklenberg'
  },
  {
    postalCode: '06526',
    city: 'Sangerhausen'
  },
  {
    postalCode: '06528',
    city: 'Beyernaumburg'
  },
  {
    postalCode: '06528',
    city: 'Birkenschäferei'
  },
  {
    postalCode: '06528',
    city: 'Blankenheim'
  },
  {
    postalCode: '06528',
    city: 'Breitenbach'
  },
  {
    postalCode: '06528',
    city: 'Brücken'
  },
  {
    postalCode: '06528',
    city: 'Drebsdorf'
  },
  {
    postalCode: '06528',
    city: 'Edersleben'
  },
  {
    postalCode: '06528',
    city: 'Emseloh'
  },
  {
    postalCode: '06528',
    city: 'Gonna'
  },
  {
    postalCode: '06528',
    city: 'Grillenberg'
  },
  {
    postalCode: '06528',
    city: 'Großleinungen'
  },
  {
    postalCode: '06528',
    city: 'Hackpfüffel'
  },
  {
    postalCode: '06528',
    city: 'Hainrode'
  },
  {
    postalCode: '06528',
    city: 'Holdenstedt'
  },
  {
    postalCode: '06528',
    city: 'Horla'
  },
  {
    postalCode: '06528',
    city: 'Kleinleinungen'
  },
  {
    postalCode: '06528',
    city: 'Landgemeinde'
  },
  {
    postalCode: '06528',
    city: 'Lengefeld'
  },
  {
    postalCode: '06528',
    city: 'Liedersdorf'
  },
  {
    postalCode: '06528',
    city: 'Martinsrieth'
  },
  {
    postalCode: '06528',
    city: 'Morungen'
  },
  {
    postalCode: '06528',
    city: 'Neuhaus'
  },
  {
    postalCode: '06528',
    city: 'Oberröblingen'
  },
  {
    postalCode: '06528',
    city: 'Obersdorf'
  },
  {
    postalCode: '06528',
    city: 'Pölsfeld'
  },
  {
    postalCode: '06528',
    city: 'Riestedt'
  },
  {
    postalCode: '06528',
    city: 'Riethnordhausen'
  },
  {
    postalCode: '06528',
    city: 'Rotha'
  },
  {
    postalCode: '06528',
    city: 'Sotterhausen'
  },
  {
    postalCode: '06528',
    city: 'Wallhausen'
  },
  {
    postalCode: '06528',
    city: 'Wettelrode'
  },
  {
    postalCode: '06536',
    city: 'Bennungen'
  },
  {
    postalCode: '06536',
    city: 'Berga'
  },
  {
    postalCode: '06536',
    city: 'Breitungen'
  },
  {
    postalCode: '06536',
    city: 'Dietersdorf'
  },
  {
    postalCode: '06536',
    city: 'Hayn'
  },
  {
    postalCode: '06536',
    city: 'Questenberg'
  },
  {
    postalCode: '06536',
    city: 'Roßla'
  },
  {
    postalCode: '06536',
    city: 'Wickerode'
  },
  {
    postalCode: '06536',
    city: 'Wolfsberg'
  },
  {
    postalCode: '06537',
    city: 'Kelbra'
  },
  {
    postalCode: '06537',
    city: 'Tilleda'
  },
  {
    postalCode: '06542',
    city: 'Allstedt'
  },
  {
    postalCode: '06542',
    city: 'Katharinenrieth'
  },
  {
    postalCode: '06542',
    city: 'Mittelhausen'
  },
  {
    postalCode: '06542',
    city: 'Niederröblingen'
  },
  {
    postalCode: '06542',
    city: 'Nienstedt'
  },
  {
    postalCode: '06542',
    city: 'Winkel'
  },
  {
    postalCode: '06542',
    city: 'Wolferstedt'
  },
  {
    postalCode: '06543',
    city: 'Abberode'
  },
  {
    postalCode: '06543',
    city: 'Alterode'
  },
  {
    postalCode: '06543',
    city: 'Braunschwende'
  },
  {
    postalCode: '06543',
    city: 'Friesdorf'
  },
  {
    postalCode: '06543',
    city: 'Harkerode'
  },
  {
    postalCode: '06543',
    city: 'Hermerode'
  },
  {
    postalCode: '06543',
    city: 'Molmerswende'
  },
  {
    postalCode: '06543',
    city: 'Pansfelde'
  },
  {
    postalCode: '06543',
    city: 'Ritzgerode'
  },
  {
    postalCode: '06543',
    city: 'Stangerode'
  },
  {
    postalCode: '06543',
    city: 'Ulzigerode'
  },
  {
    postalCode: '06543',
    city: 'Wieserode'
  },
  {
    postalCode: '06543',
    city: 'Wippra'
  },
  {
    postalCode: '06547',
    city: 'Breitenstein'
  },
  {
    postalCode: '06547',
    city: 'Schwenda'
  },
  {
    postalCode: '06547',
    city: 'Stolberg'
  },
  {
    postalCode: '06548',
    city: 'Rottleberode'
  },
  {
    postalCode: '06548',
    city: 'Uftrungen'
  },
  {
    postalCode: '06556',
    city: 'Artern'
  },
  {
    postalCode: '06556',
    city: 'Borxleben'
  },
  {
    postalCode: '06556',
    city: 'Bretleben'
  },
  {
    postalCode: '06556',
    city: 'Heygendorf'
  },
  {
    postalCode: '06556',
    city: 'Ichstedt'
  },
  {
    postalCode: '06556',
    city: 'Kachstedt'
  },
  {
    postalCode: '06556',
    city: 'Kalbsrieth'
  },
  {
    postalCode: '06556',
    city: 'Mönchpfiffel-Nikolausrieth'
  },
  {
    postalCode: '06556',
    city: 'Reinsdorf'
  },
  {
    postalCode: '06556',
    city: 'Ringleben'
  },
  {
    postalCode: '06556',
    city: 'Ritteburg'
  },
  {
    postalCode: '06556',
    city: 'Schönewerda'
  },
  {
    postalCode: '06556',
    city: 'Schönfeld'
  },
  {
    postalCode: '06556',
    city: 'Udersleben'
  },
  {
    postalCode: '06556',
    city: 'Voigtstedt'
  },
  {
    postalCode: '06567',
    city: 'Bad Frankenhausen'
  },
  {
    postalCode: '06567',
    city: 'Esperstedt'
  },
  {
    postalCode: '06567',
    city: 'Göllingen'
  },
  {
    postalCode: '06567',
    city: 'Günserode'
  },
  {
    postalCode: '06567',
    city: 'Rottleben'
  },
  {
    postalCode: '06567',
    city: 'Seega'
  },
  {
    postalCode: '06567',
    city: 'Seehausen'
  },
  {
    postalCode: '06567',
    city: 'Steinthaleben'
  },
  {
    postalCode: '06571',
    city: 'Bottendorf'
  },
  {
    postalCode: '06571',
    city: 'Donndorf'
  },
  {
    postalCode: '06571',
    city: 'Garnbach'
  },
  {
    postalCode: '06571',
    city: 'Gehofen'
  },
  {
    postalCode: '06571',
    city: 'Hechendorf'
  },
  {
    postalCode: '06571',
    city: 'Langenroda'
  },
  {
    postalCode: '06571',
    city: 'Nausitz'
  },
  {
    postalCode: '06571',
    city: 'Roßleben'
  },
  {
    postalCode: '06571',
    city: 'Wiehe'
  },
  {
    postalCode: '06577',
    city: 'Braunsroda'
  },
  {
    postalCode: '06577',
    city: 'Etzleben'
  },
  {
    postalCode: '06577',
    city: 'Gorsleben'
  },
  {
    postalCode: '06577',
    city: 'Hauteroda'
  },
  {
    postalCode: '06577',
    city: 'Heldrungen'
  },
  {
    postalCode: '06577',
    city: 'Hemleben'
  },
  {
    postalCode: '06577',
    city: 'Oberheldrungen'
  },
  {
    postalCode: '06578',
    city: 'Bilzingsleben'
  },
  {
    postalCode: '06578',
    city: 'Kannawurf'
  },
  {
    postalCode: '06578',
    city: 'Oldisleben'
  },
  {
    postalCode: '06578',
    city: 'Sachsenburg'
  },
  {
    postalCode: '06618',
    city: 'Casekirchen'
  },
  {
    postalCode: '06618',
    city: 'Eulau'
  },
  {
    postalCode: '06618',
    city: 'Gieckau'
  },
  {
    postalCode: '06618',
    city: 'Görschen'
  },
  {
    postalCode: '06618',
    city: 'Heiligenkreuz'
  },
  {
    postalCode: '06618',
    city: 'Henne'
  },
  {
    postalCode: '06618',
    city: 'Janisroda'
  },
  {
    postalCode: '06618',
    city: 'Kleinjena'
  },
  {
    postalCode: '06618',
    city: 'Leislau'
  },
  {
    postalCode: '06618',
    city: 'Löbitz'
  },
  {
    postalCode: '06618',
    city: 'Mertendorf'
  },
  {
    postalCode: '06618',
    city: 'Molau'
  },
  {
    postalCode: '06618',
    city: 'Naumburg'
  },
  {
    postalCode: '06618',
    city: 'Pödelist'
  },
  {
    postalCode: '06618',
    city: 'Prießnitz'
  },
  {
    postalCode: '06618',
    city: 'Schönburg'
  },
  {
    postalCode: '06618',
    city: 'Utenbach'
  },
  {
    postalCode: '06618',
    city: 'Wethau'
  },
  {
    postalCode: '06628',
    city: 'Abtlöbnitz'
  },
  {
    postalCode: '06628',
    city: 'Bad Kösen'
  },
  {
    postalCode: '06628',
    city: 'Crölpa-Löbschütz'
  },
  {
    postalCode: '06628',
    city: 'Möllern'
  },
  {
    postalCode: '06628',
    city: 'Spielberg'
  },
  {
    postalCode: '06628',
    city: 'Taugwitz'
  },
  {
    postalCode: '06632',
    city: 'Balgstädt'
  },
  {
    postalCode: '06632',
    city: 'Baumersroda'
  },
  {
    postalCode: '06632',
    city: 'Branderoda'
  },
  {
    postalCode: '06632',
    city: 'Ebersroda'
  },
  {
    postalCode: '06632',
    city: 'Freyburg'
  },
  {
    postalCode: '06632',
    city: 'Gleina'
  },
  {
    postalCode: '06632',
    city: 'Größnitz'
  },
  {
    postalCode: '06632',
    city: 'Gröst'
  },
  {
    postalCode: '06632',
    city: 'Schleberoda'
  },
  {
    postalCode: '06632',
    city: 'Zeuchfeld'
  },
  {
    postalCode: '06636',
    city: 'Burgscheidungen'
  },
  {
    postalCode: '06636',
    city: 'Golzen'
  },
  {
    postalCode: '06636',
    city: 'Hirschroda'
  },
  {
    postalCode: '06636',
    city: 'Kirchscheidungen'
  },
  {
    postalCode: '06636',
    city: 'Laucha'
  },
  {
    postalCode: '06636',
    city: 'Weischütz'
  },
  {
    postalCode: '06638',
    city: 'Karsdorf'
  },
  {
    postalCode: '06642',
    city: 'Altenroda'
  },
  {
    postalCode: '06642',
    city: 'Bucha'
  },
  {
    postalCode: '06642',
    city: 'Memleben'
  },
  {
    postalCode: '06642',
    city: 'Nebra'
  },
  {
    postalCode: '06642',
    city: 'Reinsdorf'
  },
  {
    postalCode: '06642',
    city: 'Wangen'
  },
  {
    postalCode: '06642',
    city: 'Wohlmirstedt'
  },
  {
    postalCode: '06642',
    city: 'Zuckerfabrik Vitzenburg'
  },
  {
    postalCode: '06647',
    city: 'Bad Bibra'
  },
  {
    postalCode: '06647',
    city: 'Billroda'
  },
  {
    postalCode: '06647',
    city: 'Burkersroda'
  },
  {
    postalCode: '06647',
    city: 'Hohndorf'
  },
  {
    postalCode: '06647',
    city: 'Kahlwinkel'
  },
  {
    postalCode: '06647',
    city: 'Klosterhäseler'
  },
  {
    postalCode: '06647',
    city: 'Lindenberg'
  },
  {
    postalCode: '06647',
    city: 'Lossa'
  },
  {
    postalCode: '06647',
    city: 'Saubach'
  },
  {
    postalCode: '06647',
    city: 'Steinburg'
  },
  {
    postalCode: '06647',
    city: 'Thalwinkel'
  },
  {
    postalCode: '06647',
    city: 'Wischroda'
  },
  {
    postalCode: '06648',
    city: 'Braunsroda'
  },
  {
    postalCode: '06648',
    city: 'Burgholzhausen'
  },
  {
    postalCode: '06648',
    city: 'Eckartsberga'
  },
  {
    postalCode: '06648',
    city: 'Gernstedt'
  },
  {
    postalCode: '06648',
    city: 'Herrengosserstedt'
  },
  {
    postalCode: '06648',
    city: 'Tromsdorf'
  },
  {
    postalCode: '06667',
    city: 'Burgwerben'
  },
  {
    postalCode: '06667',
    city: 'Goseck'
  },
  {
    postalCode: '06667',
    city: 'Gröbitz'
  },
  {
    postalCode: '06667',
    city: 'Langendorf'
  },
  {
    postalCode: '06667',
    city: 'Leißling'
  },
  {
    postalCode: '06667',
    city: 'Markwerben'
  },
  {
    postalCode: '06667',
    city: 'Pretzsch'
  },
  {
    postalCode: '06667',
    city: 'Prittitz'
  },
  {
    postalCode: '06667',
    city: 'Reichardtswerben'
  },
  {
    postalCode: '06667',
    city: 'Schmerdorf'
  },
  {
    postalCode: '06667',
    city: 'Storkau'
  },
  {
    postalCode: '06667',
    city: 'Stößen'
  },
  {
    postalCode: '06667',
    city: 'Tagewerben'
  },
  {
    postalCode: '06667',
    city: 'Uichteritz'
  },
  {
    postalCode: '06667',
    city: 'Weißenfels'
  },
  {
    postalCode: '06679',
    city: 'Granschütz'
  },
  {
    postalCode: '06679',
    city: 'Großgrimma'
  },
  {
    postalCode: '06679',
    city: 'Hohenmölsen'
  },
  {
    postalCode: '06679',
    city: 'Muschwitz'
  },
  {
    postalCode: '06679',
    city: 'Taucha'
  },
  {
    postalCode: '06679',
    city: 'Webau'
  },
  {
    postalCode: '06679',
    city: 'Zembschen'
  },
  {
    postalCode: '06679',
    city: 'Zorbau'
  },
  {
    postalCode: '06682',
    city: 'Deuben'
  },
  {
    postalCode: '06682',
    city: 'Gröben'
  },
  {
    postalCode: '06682',
    city: 'Krauschwitz'
  },
  {
    postalCode: '06682',
    city: 'Nessa'
  },
  {
    postalCode: '06682',
    city: 'Schelkau'
  },
  {
    postalCode: '06682',
    city: 'Teuchern'
  },
  {
    postalCode: '06682',
    city: 'Trebnitz'
  },
  {
    postalCode: '06682',
    city: 'Werschen'
  },
  {
    postalCode: '06686',
    city: 'Dehlitz'
  },
  {
    postalCode: '06686',
    city: 'Großgörschen'
  },
  {
    postalCode: '06686',
    city: 'Lützen'
  },
  {
    postalCode: '06686',
    city: 'Poserna'
  },
  {
    postalCode: '06686',
    city: 'Rippach'
  },
  {
    postalCode: '06686',
    city: 'Röcken'
  },
  {
    postalCode: '06686',
    city: 'Sössen'
  },
  {
    postalCode: '06686',
    city: 'Starsiedel'
  },
  {
    postalCode: '06688',
    city: 'Großkorbetha'
  },
  {
    postalCode: '06688',
    city: 'Schkortleben'
  },
  {
    postalCode: '06688',
    city: 'Wengelsdorf'
  },
  {
    postalCode: '06712',
    city: 'Bergisdorf'
  },
  {
    postalCode: '06712',
    city: 'Bornitz'
  },
  {
    postalCode: '06712',
    city: 'Breitenbach'
  },
  {
    postalCode: '06712',
    city: 'Döschwitz'
  },
  {
    postalCode: '06712',
    city: 'Draschwitz'
  },
  {
    postalCode: '06712',
    city: 'Droßdorf'
  },
  {
    postalCode: '06712',
    city: 'Geußnitz'
  },
  {
    postalCode: '06712',
    city: 'Göbitz'
  },
  {
    postalCode: '06712',
    city: 'Grana'
  },
  {
    postalCode: '06712',
    city: 'Haynsburg'
  },
  {
    postalCode: '06712',
    city: 'Heuckewalde'
  },
  {
    postalCode: '06712',
    city: 'Könderitz'
  },
  {
    postalCode: '06712',
    city: 'Kretzschau'
  },
  {
    postalCode: '06712',
    city: 'Schellbach'
  },
  {
    postalCode: '06712',
    city: 'Schkauditz'
  },
  {
    postalCode: '06712',
    city: 'Wittgendorf'
  },
  {
    postalCode: '06712',
    city: 'Würchwitz'
  },
  {
    postalCode: '06712',
    city: 'Zangenberg'
  },
  {
    postalCode: '06712',
    city: 'Zeitz'
  },
  {
    postalCode: '06721',
    city: 'Goldschau'
  },
  {
    postalCode: '06721',
    city: 'Meineweh'
  },
  {
    postalCode: '06721',
    city: 'Osterfeld'
  },
  {
    postalCode: '06721',
    city: 'Unterkaka'
  },
  {
    postalCode: '06721',
    city: 'Waldau'
  },
  {
    postalCode: '06722',
    city: 'Droyßig'
  },
  {
    postalCode: '06722',
    city: 'Kleinhelmsdorf'
  },
  {
    postalCode: '06722',
    city: 'Weickelsdorf'
  },
  {
    postalCode: '06722',
    city: 'Weißenborn'
  },
  {
    postalCode: '06722',
    city: 'Wetterzeube'
  },
  {
    postalCode: '06724',
    city: 'Bröckau'
  },
  {
    postalCode: '06724',
    city: 'Kayna'
  },
  {
    postalCode: '06724',
    city: 'Spora'
  },
  {
    postalCode: '06725',
    city: 'Profen'
  },
  {
    postalCode: '06725',
    city: 'Reuden'
  },
  {
    postalCode: '06727',
    city: 'Döbris'
  },
  {
    postalCode: '06727',
    city: 'Luckenau'
  },
  {
    postalCode: '06727',
    city: 'Nonnewitz'
  },
  {
    postalCode: '06727',
    city: 'Schleinitz'
  },
  {
    postalCode: '06727',
    city: 'Theißen'
  },
  {
    postalCode: '06729',
    city: 'Etzoldshain'
  },
  {
    postalCode: '06729',
    city: 'Langendorf'
  },
  {
    postalCode: '06729',
    city: 'Minkwitz'
  },
  {
    postalCode: '06729',
    city: 'Rehmsdorf'
  },
  {
    postalCode: '06729',
    city: 'Torna'
  },
  {
    postalCode: '06729',
    city: 'Traupitz'
  },
  {
    postalCode: '06729',
    city: 'Tröglitz'
  },
  {
    postalCode: '06729',
    city: 'Wadewitz'
  },
  {
    postalCode: '06749',
    city: 'Bitterfeld'
  },
  {
    postalCode: '06749',
    city: 'Friedersdorf'
  },
  {
    postalCode: '06766',
    city: 'Bobbau'
  },
  {
    postalCode: '06766',
    city: 'Reuden an der Fuhne'
  },
  {
    postalCode: '06766',
    city: 'Rödgen'
  },
  {
    postalCode: '06766',
    city: 'Thalheim'
  },
  {
    postalCode: '06766',
    city: 'Wolfen'
  },
  {
    postalCode: '06773',
    city: 'Bergwitz'
  },
  {
    postalCode: '06773',
    city: 'Gossa'
  },
  {
    postalCode: '06773',
    city: 'Gräfenhainichen'
  },
  {
    postalCode: '06773',
    city: 'Gröbern'
  },
  {
    postalCode: '06773',
    city: 'Jüdenberg'
  },
  {
    postalCode: '06773',
    city: 'Radis'
  },
  {
    postalCode: '06773',
    city: 'Rotta'
  },
  {
    postalCode: '06773',
    city: 'Schköna'
  },
  {
    postalCode: '06773',
    city: 'Selbitz'
  },
  {
    postalCode: '06773',
    city: 'Uthausen'
  },
  {
    postalCode: '06774',
    city: 'Krina'
  },
  {
    postalCode: '06774',
    city: 'Mühlbeck'
  },
  {
    postalCode: '06774',
    city: 'Plodda'
  },
  {
    postalCode: '06774',
    city: 'Pouch'
  },
  {
    postalCode: '06774',
    city: 'Rösa'
  },
  {
    postalCode: '06774',
    city: 'Schlaitz'
  },
  {
    postalCode: '06774',
    city: 'Schwemsal'
  },
  {
    postalCode: '06774',
    city: 'Söllichau'
  },
  {
    postalCode: '06774',
    city: 'Tornau'
  },
  {
    postalCode: '06779',
    city: 'Marke'
  },
  {
    postalCode: '06779',
    city: 'Raguhn'
  },
  {
    postalCode: '06779',
    city: 'Retzau'
  },
  {
    postalCode: '06779',
    city: 'Salzfurtkapelle'
  },
  {
    postalCode: '06779',
    city: 'Schierau'
  },
  {
    postalCode: '06779',
    city: 'Thurland'
  },
  {
    postalCode: '06779',
    city: 'Tornau von der Heide'
  },
  {
    postalCode: '06780',
    city: 'Göttnitz'
  },
  {
    postalCode: '06780',
    city: 'Großzöberitz'
  },
  {
    postalCode: '06780',
    city: 'Heideloh'
  },
  {
    postalCode: '06780',
    city: 'Löberitz'
  },
  {
    postalCode: '06780',
    city: 'Quetzdölsdorf'
  },
  {
    postalCode: '06780',
    city: 'Schrenz'
  },
  {
    postalCode: '06780',
    city: 'Spören'
  },
  {
    postalCode: '06780',
    city: 'Stumsdorf'
  },
  {
    postalCode: '06780',
    city: 'Zörbig'
  },
  {
    postalCode: '06785',
    city: 'Brandhorst'
  },
  {
    postalCode: '06785',
    city: 'Horstdorf'
  },
  {
    postalCode: '06785',
    city: 'Kakau'
  },
  {
    postalCode: '06785',
    city: 'Oranienbaum'
  },
  {
    postalCode: '06785',
    city: 'Schleesen'
  },
  {
    postalCode: '06786',
    city: 'Gohrau'
  },
  {
    postalCode: '06786',
    city: 'Griesen'
  },
  {
    postalCode: '06786',
    city: 'Rehsen'
  },
  {
    postalCode: '06786',
    city: 'Riesigk'
  },
  {
    postalCode: '06786',
    city: 'Vockerode'
  },
  {
    postalCode: '06786',
    city: 'Wörlitz'
  },
  {
    postalCode: '06791',
    city: 'Möhlau'
  },
  {
    postalCode: '06791',
    city: 'Zschornewitz'
  },
  {
    postalCode: '06792',
    city: 'Sandersdorf'
  },
  {
    postalCode: '06794',
    city: 'Glebitzsch'
  },
  {
    postalCode: '06794',
    city: 'Ramsin'
  },
  {
    postalCode: '06794',
    city: 'Renneritz'
  },
  {
    postalCode: '06794',
    city: 'Zscherndorf'
  },
  {
    postalCode: '06796',
    city: 'Brehna'
  },
  {
    postalCode: '06800',
    city: 'Altjeßnitz'
  },
  {
    postalCode: '06800',
    city: 'Jeßnitz'
  },
  {
    postalCode: '06803',
    city: 'Greppin'
  },
  {
    postalCode: '06804',
    city: 'Burgkemnitz'
  },
  {
    postalCode: '06804',
    city: 'Muldenstein'
  },
  {
    postalCode: '06808',
    city: 'Holzweißig'
  },
  {
    postalCode: '06809',
    city: 'Petersroda'
  },
  {
    postalCode: '06809',
    city: 'Roitzsch'
  },
  {
    postalCode: '06842',
    city: 'Dessau'
  },
  {
    postalCode: '06844',
    city: 'Dessau'
  },
  {
    postalCode: '06846',
    city: 'Dessau'
  },
  {
    postalCode: '06847',
    city: 'Dessau'
  },
  {
    postalCode: '06849',
    city: 'Dessau'
  },
  {
    postalCode: '06862',
    city: 'Brambach'
  },
  {
    postalCode: '06862',
    city: 'Bräsen'
  },
  {
    postalCode: '06862',
    city: 'Hundeluft'
  },
  {
    postalCode: '06862',
    city: 'Jeber-Bergfrieden'
  },
  {
    postalCode: '06862',
    city: 'Mühlstedt'
  },
  {
    postalCode: '06862',
    city: 'Ragösen'
  },
  {
    postalCode: '06862',
    city: 'Rodleben'
  },
  {
    postalCode: '06862',
    city: 'Roßlau'
  },
  {
    postalCode: '06862',
    city: 'Serno'
  },
  {
    postalCode: '06862',
    city: 'Stackelitz'
  },
  {
    postalCode: '06862',
    city: 'Streetz'
  },
  {
    postalCode: '06862',
    city: 'Thießen'
  },
  {
    postalCode: '06869',
    city: 'Buko'
  },
  {
    postalCode: '06869',
    city: 'Cobbelsdorf'
  },
  {
    postalCode: '06869',
    city: 'Coswig'
  },
  {
    postalCode: '06869',
    city: 'Düben'
  },
  {
    postalCode: '06869',
    city: 'Göritz'
  },
  {
    postalCode: '06869',
    city: 'Griebo'
  },
  {
    postalCode: '06869',
    city: 'Klieken'
  },
  {
    postalCode: '06869',
    city: 'Köselitz'
  },
  {
    postalCode: '06869',
    city: 'Luko'
  },
  {
    postalCode: '06869',
    city: 'Möllensdorf'
  },
  {
    postalCode: '06869',
    city: 'Senst'
  },
  {
    postalCode: '06869',
    city: 'Wörpen'
  },
  {
    postalCode: '06869',
    city: 'Zieko'
  },
  {
    postalCode: '06886',
    city: 'Lutherstadt Wittenberg'
  },
  {
    postalCode: '06888',
    city: 'Abtsdorf'
  },
  {
    postalCode: '06888',
    city: 'Dabrun'
  },
  {
    postalCode: '06888',
    city: 'Dietrichsdorf'
  },
  {
    postalCode: '06888',
    city: 'Eutzsch'
  },
  {
    postalCode: '06888',
    city: 'Mochau'
  },
  {
    postalCode: '06888',
    city: 'Mühlanger'
  },
  {
    postalCode: '06888',
    city: 'Pratau'
  },
  {
    postalCode: '06888',
    city: 'Seegrehna'
  },
  {
    postalCode: '06888',
    city: 'Zörnigall'
  },
  {
    postalCode: '06895',
    city: 'Boßdorf'
  },
  {
    postalCode: '06895',
    city: 'Bülzig'
  },
  {
    postalCode: '06895',
    city: 'Kropstädt'
  },
  {
    postalCode: '06895',
    city: 'Leetza'
  },
  {
    postalCode: '06895',
    city: 'Rahnsdorf'
  },
  {
    postalCode: '06895',
    city: 'Zahna'
  },
  {
    postalCode: '06896',
    city: 'Nudersdorf'
  },
  {
    postalCode: '06896',
    city: 'Reinsdorf'
  },
  {
    postalCode: '06896',
    city: 'Schmilkendorf'
  },
  {
    postalCode: '06896',
    city: 'Straach'
  },
  {
    postalCode: '06901',
    city: 'Ateritz'
  },
  {
    postalCode: '06901',
    city: 'Dorna'
  },
  {
    postalCode: '06901',
    city: 'Globig-Bleddin'
  },
  {
    postalCode: '06901',
    city: 'Kemberg'
  },
  {
    postalCode: '06901',
    city: 'Rackith'
  },
  {
    postalCode: '06901',
    city: 'Schnellin'
  },
  {
    postalCode: '06901',
    city: 'Wartenburg'
  },
  {
    postalCode: '06905',
    city: 'Bad Schmiedeberg'
  },
  {
    postalCode: '06905',
    city: 'Korgau'
  },
  {
    postalCode: '06905',
    city: 'Meuro'
  },
  {
    postalCode: '06909',
    city: 'Pretzsch'
  },
  {
    postalCode: '06909',
    city: 'Priesitz'
  },
  {
    postalCode: '06909',
    city: 'Trebitz'
  },
  {
    postalCode: '06917',
    city: 'Arnsdorf'
  },
  {
    postalCode: '06917',
    city: 'Battin'
  },
  {
    postalCode: '06917',
    city: 'Düßnitz'
  },
  {
    postalCode: '06917',
    city: 'Gorsdorf-Hemsendorf'
  },
  {
    postalCode: '06917',
    city: 'Jessen'
  },
  {
    postalCode: '06917',
    city: 'Kleindröben'
  },
  {
    postalCode: '06917',
    city: 'Klöden'
  },
  {
    postalCode: '06917',
    city: 'Leipa'
  },
  {
    postalCode: '06917',
    city: 'Rade'
  },
  {
    postalCode: '06917',
    city: 'Ruhlsdorf'
  },
  {
    postalCode: '06917',
    city: 'Schützberg'
  },
  {
    postalCode: '06918',
    city: 'Elster'
  },
  {
    postalCode: '06918',
    city: 'Gadegast'
  },
  {
    postalCode: '06918',
    city: 'Gentha'
  },
  {
    postalCode: '06918',
    city: 'Listerfehrda'
  },
  {
    postalCode: '06918',
    city: 'Mellnitz'
  },
  {
    postalCode: '06918',
    city: 'Morxdorf'
  },
  {
    postalCode: '06918',
    city: 'Naundorf'
  },
  {
    postalCode: '06918',
    city: 'Seyda'
  },
  {
    postalCode: '06918',
    city: 'Zemnick'
  },
  {
    postalCode: '06922',
    city: 'Axien'
  },
  {
    postalCode: '06922',
    city: 'Gerbisbach'
  },
  {
    postalCode: '06922',
    city: 'Labrun'
  },
  {
    postalCode: '06922',
    city: 'Lebien'
  },
  {
    postalCode: '06922',
    city: 'Plossig'
  },
  {
    postalCode: '06922',
    city: 'Prettin'
  },
  {
    postalCode: '06925',
    city: 'Annaburg'
  },
  {
    postalCode: '06925',
    city: 'Bethau'
  },
  {
    postalCode: '06925',
    city: 'Groß Naundorf'
  },
  {
    postalCode: '06925',
    city: 'Löben'
  },
  {
    postalCode: '06925',
    city: 'Purzien'
  },
  {
    postalCode: '06926',
    city: 'Buschkuhnsdorf'
  },
  {
    postalCode: '06926',
    city: 'Großkorga'
  },
  {
    postalCode: '06926',
    city: 'Holzdorf'
  },
  {
    postalCode: '06926',
    city: 'Kleinkorga'
  },
  {
    postalCode: '06926',
    city: 'Mönchenhöfe'
  },
  {
    postalCode: '06926',
    city: 'Neuerstadt'
  },
  {
    postalCode: '06926',
    city: 'Premsendorf'
  },
  {
    postalCode: '06926',
    city: 'Reicho'
  },
  {
    postalCode: '06928',
    city: 'Dixförda'
  },
  {
    postalCode: '06928',
    city: 'Klossa'
  },
  {
    postalCode: '06928',
    city: 'Linda'
  },
  {
    postalCode: '06928',
    city: 'Lindwerder'
  },
  {
    postalCode: '06928',
    city: 'Mügeln'
  },
  {
    postalCode: '06928',
    city: 'Schweinitz'
  },
  {
    postalCode: '06928',
    city: 'Steinsdorf'
  },
  {
    postalCode: '07318',
    city: 'Arnsgereuth'
  },
  {
    postalCode: '07318',
    city: 'Aue am Berg'
  },
  {
    postalCode: '07318',
    city: 'Bernsdorf'
  },
  {
    postalCode: '07318',
    city: 'Beulwitz'
  },
  {
    postalCode: '07318',
    city: 'Crösten'
  },
  {
    postalCode: '07318',
    city: 'Eyba'
  },
  {
    postalCode: '07318',
    city: 'Kämmeritze'
  },
  {
    postalCode: '07318',
    city: 'Kleingeschwenda'
  },
  {
    postalCode: '07318',
    city: 'Lositz-Jehmichen'
  },
  {
    postalCode: '07318',
    city: 'Reschwitz'
  },
  {
    postalCode: '07318',
    city: 'Saalfeld'
  },
  {
    postalCode: '07318',
    city: 'Volkmannsdorf'
  },
  {
    postalCode: '07318',
    city: 'Wickersdorf'
  },
  {
    postalCode: '07318',
    city: 'Wittgendorf'
  },
  {
    postalCode: '07318',
    city: 'Wittmannsgereuth'
  },
  {
    postalCode: '07318',
    city: 'Witzendorf'
  },
  {
    postalCode: '07318',
    city: 'Wöhlsdorf'
  },
  {
    postalCode: '07330',
    city: 'Arnsbach'
  },
  {
    postalCode: '07330',
    city: 'Döhlen'
  },
  {
    postalCode: '07330',
    city: 'Laasen'
  },
  {
    postalCode: '07330',
    city: 'Marktgölitz'
  },
  {
    postalCode: '07330',
    city: 'Oberloquitz'
  },
  {
    postalCode: '07330',
    city: 'Probstzella'
  },
  {
    postalCode: '07330',
    city: 'Reichenbach'
  },
  {
    postalCode: '07330',
    city: 'Schaderthal'
  },
  {
    postalCode: '07330',
    city: 'Unterloquitz'
  },
  {
    postalCode: '07333',
    city: 'Unterwellenborn'
  },
  {
    postalCode: '07334',
    city: 'Goßwitz'
  },
  {
    postalCode: '07334',
    city: 'Kamsdorf'
  },
  {
    postalCode: '07336',
    city: 'Birkigt'
  },
  {
    postalCode: '07336',
    city: 'Könitz'
  },
  {
    postalCode: '07338',
    city: 'Altenbeuthen'
  },
  {
    postalCode: '07338',
    city: 'Blaues Glück'
  },
  {
    postalCode: '07338',
    city: 'Dorfilm'
  },
  {
    postalCode: '07338',
    city: 'Drognitz'
  },
  {
    postalCode: '07338',
    city: 'Großgeschwenda'
  },
  {
    postalCode: '07338',
    city: 'Grünau'
  },
  {
    postalCode: '07338',
    city: 'Hirzbach'
  },
  {
    postalCode: '07338',
    city: 'Hohenwarte'
  },
  {
    postalCode: '07338',
    city: 'Kaulsdorf'
  },
  {
    postalCode: '07338',
    city: 'Landsendorf'
  },
  {
    postalCode: '07338',
    city: 'Leutenberg'
  },
  {
    postalCode: '07338',
    city: 'Lichtentanne'
  },
  {
    postalCode: '07338',
    city: 'Munschwitz'
  },
  {
    postalCode: '07338',
    city: 'Neidenberga'
  },
  {
    postalCode: '07338',
    city: 'Neue Mühle'
  },
  {
    postalCode: '07338',
    city: 'Neuenbeuthen'
  },
  {
    postalCode: '07338',
    city: 'Reitzengeschwenda'
  },
  {
    postalCode: '07338',
    city: 'Rosenthal'
  },
  {
    postalCode: '07338',
    city: 'Schweinbach'
  },
  {
    postalCode: '07338',
    city: 'Steinsdorf'
  },
  {
    postalCode: '07338',
    city: 'Wickendorf'
  },
  {
    postalCode: '07343',
    city: 'Granitwerk Sormitztal'
  },
  {
    postalCode: '07343',
    city: 'Grubersmühle'
  },
  {
    postalCode: '07343',
    city: 'Neumühle'
  },
  {
    postalCode: '07343',
    city: 'Wurzbach'
  },
  {
    postalCode: '07343',
    city: 'Zschachenmühle'
  },
  {
    postalCode: '07349',
    city: 'Lehesten'
  },
  {
    postalCode: '07356',
    city: 'Altengesees'
  },
  {
    postalCode: '07356',
    city: 'Burglemnitz'
  },
  {
    postalCode: '07356',
    city: 'Eliasbrunn'
  },
  {
    postalCode: '07356',
    city: 'Gahma'
  },
  {
    postalCode: '07356',
    city: 'Gleima'
  },
  {
    postalCode: '07356',
    city: 'Helmsgrün'
  },
  {
    postalCode: '07356',
    city: 'Kühler Morgen'
  },
  {
    postalCode: '07356',
    city: 'Lobenstein'
  },
  {
    postalCode: '07356',
    city: 'Lothra'
  },
  {
    postalCode: '07356',
    city: 'Neundorf'
  },
  {
    postalCode: '07356',
    city: 'Oberlemnitz'
  },
  {
    postalCode: '07356',
    city: 'Rauschengesees'
  },
  {
    postalCode: '07356',
    city: 'Ruppersdorf'
  },
  {
    postalCode: '07356',
    city: 'Saaldorf'
  },
  {
    postalCode: '07356',
    city: 'Thierbach'
  },
  {
    postalCode: '07356',
    city: 'Thimmendorf'
  },
  {
    postalCode: '07356',
    city: 'Unterlemnitz'
  },
  {
    postalCode: '07356',
    city: 'Weisbach'
  },
  {
    postalCode: '07366',
    city: 'Birkenhügel'
  },
  {
    postalCode: '07366',
    city: 'Blankenberg'
  },
  {
    postalCode: '07366',
    city: 'Blankenstein'
  },
  {
    postalCode: '07366',
    city: 'Harra'
  },
  {
    postalCode: '07366',
    city: 'Lichtenbrunn'
  },
  {
    postalCode: '07366',
    city: 'Pottiga'
  },
  {
    postalCode: '07366',
    city: 'Schlegel'
  },
  {
    postalCode: '07366',
    city: 'Seibis'
  },
  {
    postalCode: '07368',
    city: 'Ebersdorf'
  },
  {
    postalCode: '07368',
    city: 'Liebengrün'
  },
  {
    postalCode: '07368',
    city: 'Liebschütz'
  },
  {
    postalCode: '07368',
    city: 'Remptendorf'
  },
  {
    postalCode: '07381',
    city: 'Bodelwitz'
  },
  {
    postalCode: '07381',
    city: 'Döbritz'
  },
  {
    postalCode: '07381',
    city: 'Friedebach'
  },
  {
    postalCode: '07381',
    city: 'Herschdorf'
  },
  {
    postalCode: '07381',
    city: 'Langenorla'
  },
  {
    postalCode: '07381',
    city: 'Mariengrund'
  },
  {
    postalCode: '07381',
    city: 'Moxa'
  },
  {
    postalCode: '07381',
    city: 'Nimritz'
  },
  {
    postalCode: '07381',
    city: 'Oberoppurg'
  },
  {
    postalCode: '07381',
    city: 'Oppurg'
  },
  {
    postalCode: '07381',
    city: 'Paska'
  },
  {
    postalCode: '07381',
    city: 'Pößneck'
  },
  {
    postalCode: '07381',
    city: 'Solkwitz'
  },
  {
    postalCode: '07381',
    city: 'Trannroda'
  },
  {
    postalCode: '07381',
    city: 'Wernburg'
  },
  {
    postalCode: '07381',
    city: 'Zella'
  },
  {
    postalCode: '07387',
    city: 'Gräfendorf'
  },
  {
    postalCode: '07387',
    city: 'Krölpa'
  },
  {
    postalCode: '07387',
    city: 'Lausnitz'
  },
  {
    postalCode: '07387',
    city: 'Rockendorf'
  },
  {
    postalCode: '07389',
    city: 'Bucha'
  },
  {
    postalCode: '07389',
    city: 'Dobian'
  },
  {
    postalCode: '07389',
    city: 'Gertewitz'
  },
  {
    postalCode: '07389',
    city: 'Gössitz'
  },
  {
    postalCode: '07389',
    city: 'Grobengereuth'
  },
  {
    postalCode: '07389',
    city: 'Keila'
  },
  {
    postalCode: '07389',
    city: 'Knau'
  },
  {
    postalCode: '07389',
    city: 'Peuschen'
  },
  {
    postalCode: '07389',
    city: 'Quaschwitz'
  },
  {
    postalCode: '07389',
    city: 'Ranis'
  },
  {
    postalCode: '07389',
    city: 'Schmorda'
  },
  {
    postalCode: '07389',
    city: 'Seisla'
  },
  {
    postalCode: '07389',
    city: 'Wilhelmsdorf'
  },
  {
    postalCode: '07389',
    city: 'Wöhlsdorf'
  },
  {
    postalCode: '07407',
    city: 'Ammelstädt'
  },
  {
    postalCode: '07407',
    city: 'Beutelsdorf'
  },
  {
    postalCode: '07407',
    city: 'Breitenheerda'
  },
  {
    postalCode: '07407',
    city: 'Clöswitz'
  },
  {
    postalCode: '07407',
    city: 'Dorndorf'
  },
  {
    postalCode: '07407',
    city: 'Engerda'
  },
  {
    postalCode: '07407',
    city: 'Eschdorf'
  },
  {
    postalCode: '07407',
    city: 'Geitersdorf'
  },
  {
    postalCode: '07407',
    city: 'Großkochberg'
  },
  {
    postalCode: '07407',
    city: 'Haufeld'
  },
  {
    postalCode: '07407',
    city: 'Heilingen'
  },
  {
    postalCode: '07407',
    city: 'Heilsberg'
  },
  {
    postalCode: '07407',
    city: 'Kirchhasel'
  },
  {
    postalCode: '07407',
    city: 'Kleinkochberg'
  },
  {
    postalCode: '07407',
    city: 'Milbitz bei Teichel'
  },
  {
    postalCode: '07407',
    city: 'Niederkrossen'
  },
  {
    postalCode: '07407',
    city: 'Remda'
  },
  {
    postalCode: '07407',
    city: 'Rödelwitz'
  },
  {
    postalCode: '07407',
    city: 'Rudolstadt'
  },
  {
    postalCode: '07407',
    city: 'Schloßkulm'
  },
  {
    postalCode: '07407',
    city: 'Schmieden'
  },
  {
    postalCode: '07407',
    city: 'Sundremda'
  },
  {
    postalCode: '07407',
    city: 'Tännich'
  },
  {
    postalCode: '07407',
    city: 'Teichel'
  },
  {
    postalCode: '07407',
    city: 'Teichröda'
  },
  {
    postalCode: '07407',
    city: 'Teichweiden'
  },
  {
    postalCode: '07407',
    city: 'Treppendorf'
  },
  {
    postalCode: '07407',
    city: 'Uhlstädt'
  },
  {
    postalCode: '07407',
    city: 'Zeutsch'
  },
  {
    postalCode: '07422',
    city: 'Bad Blankenburg'
  },
  {
    postalCode: '07422',
    city: 'Burkersdorf'
  },
  {
    postalCode: '07422',
    city: 'Dittersdorf'
  },
  {
    postalCode: '07422',
    city: 'Dittrichshütte'
  },
  {
    postalCode: '07422',
    city: 'Hengelbach'
  },
  {
    postalCode: '07422',
    city: 'Kossau'
  },
  {
    postalCode: '07422',
    city: 'Leutnitz'
  },
  {
    postalCode: '07422',
    city: 'Milbitz bei Rottenbach'
  },
  {
    postalCode: '07422',
    city: 'Paulinzella'
  },
  {
    postalCode: '07422',
    city: 'Quittelsdorf'
  },
  {
    postalCode: '07422',
    city: 'Rottenbach'
  },
  {
    postalCode: '07422',
    city: 'Solsdorf'
  },
  {
    postalCode: '07422',
    city: 'Thälendorf'
  },
  {
    postalCode: '07422',
    city: 'Unterwirbach'
  },
  {
    postalCode: '07426',
    city: 'Allendorf'
  },
  {
    postalCode: '07426',
    city: 'Aschau'
  },
  {
    postalCode: '07426',
    city: 'Bechstedt'
  },
  {
    postalCode: '07426',
    city: 'Dröbischau'
  },
  {
    postalCode: '07426',
    city: 'Köditzberg'
  },
  {
    postalCode: '07426',
    city: 'Königsee'
  },
  {
    postalCode: '07426',
    city: 'Oberhain'
  },
  {
    postalCode: '07426',
    city: 'Unterhain'
  },
  {
    postalCode: '07427',
    city: 'Schwarzburg'
  },
  {
    postalCode: '07429',
    city: 'Döschnitz'
  },
  {
    postalCode: '07429',
    city: 'Rohrbach'
  },
  {
    postalCode: '07429',
    city: 'Sitzendorf'
  },
  {
    postalCode: '07545',
    city: 'Gera'
  },
  {
    postalCode: '07546',
    city: 'Gera'
  },
  {
    postalCode: '07548',
    city: 'Gera'
  },
  {
    postalCode: '07549',
    city: 'Gera'
  },
  {
    postalCode: '07551',
    city: 'Gera'
  },
  {
    postalCode: '07552',
    city: 'Gera'
  },
  {
    postalCode: '07554',
    city: 'Bethenhausen'
  },
  {
    postalCode: '07554',
    city: 'Brahmenau'
  },
  {
    postalCode: '07554',
    city: 'Gera'
  },
  {
    postalCode: '07554',
    city: 'Hirschfeld'
  },
  {
    postalCode: '07554',
    city: 'Kauern'
  },
  {
    postalCode: '07554',
    city: 'Korbußen'
  },
  {
    postalCode: '07554',
    city: 'Pölzig'
  },
  {
    postalCode: '07554',
    city: 'Schwaara'
  },
  {
    postalCode: '07557',
    city: 'Crimla'
  },
  {
    postalCode: '07557',
    city: 'Gera'
  },
  {
    postalCode: '07557',
    city: 'Hundhaupten'
  },
  {
    postalCode: '07557',
    city: 'Köfeln'
  },
  {
    postalCode: '07557',
    city: 'Zedlitz'
  },
  {
    postalCode: '07570',
    city: 'Burkersdorf'
  },
  {
    postalCode: '07570',
    city: 'Endschütz'
  },
  {
    postalCode: '07570',
    city: 'Forstwolfersdorf'
  },
  {
    postalCode: '07570',
    city: 'Frießnitz'
  },
  {
    postalCode: '07570',
    city: 'Hohenölsen'
  },
  {
    postalCode: '07570',
    city: 'Neundorf'
  },
  {
    postalCode: '07570',
    city: 'Niederpöllnitz'
  },
  {
    postalCode: '07570',
    city: 'Rohna'
  },
  {
    postalCode: '07570',
    city: 'Schömberg'
  },
  {
    postalCode: '07570',
    city: 'Steinsdorf'
  },
  {
    postalCode: '07570',
    city: 'Teichwitz'
  },
  {
    postalCode: '07570',
    city: 'Weida'
  },
  {
    postalCode: '07570',
    city: 'Wünschendorf'
  },
  {
    postalCode: '07580',
    city: 'Braunichswalde'
  },
  {
    postalCode: '07580',
    city: 'Gauern'
  },
  {
    postalCode: '07580',
    city: 'Großenstein'
  },
  {
    postalCode: '07580',
    city: 'Hilbersdorf'
  },
  {
    postalCode: '07580',
    city: 'Linda'
  },
  {
    postalCode: '07580',
    city: 'Paitzdorf'
  },
  {
    postalCode: '07580',
    city: 'Reichstädt'
  },
  {
    postalCode: '07580',
    city: 'Ronneburg'
  },
  {
    postalCode: '07580',
    city: 'Rückersdorf'
  },
  {
    postalCode: '07580',
    city: 'Seelingstädt'
  },
  {
    postalCode: '07586',
    city: 'Bad Köstritz'
  },
  {
    postalCode: '07586',
    city: 'Caaschwitz'
  },
  {
    postalCode: '07586',
    city: 'Hartmannsdorf'
  },
  {
    postalCode: '07586',
    city: 'Kraftsdorf'
  },
  {
    postalCode: '07589',
    city: 'Bocka'
  },
  {
    postalCode: '07589',
    city: 'Großebersdorf'
  },
  {
    postalCode: '07589',
    city: 'Lederhose'
  },
  {
    postalCode: '07589',
    city: 'Lindenkreuz'
  },
  {
    postalCode: '07589',
    city: 'Münchenbernsdorf'
  },
  {
    postalCode: '07589',
    city: 'Saara'
  },
  {
    postalCode: '07589',
    city: 'Schwarzbach'
  },
  {
    postalCode: '07607',
    city: 'Eisenberg'
  },
  {
    postalCode: '07607',
    city: 'Gösen'
  },
  {
    postalCode: '07607',
    city: 'Hainspitz'
  },
  {
    postalCode: '07613',
    city: 'Crossen'
  },
  {
    postalCode: '07613',
    city: 'Hartmannsdorf'
  },
  {
    postalCode: '07613',
    city: 'Heideland'
  },
  {
    postalCode: '07613',
    city: 'Rauda'
  },
  {
    postalCode: '07613',
    city: 'Seifartsdorf'
  },
  {
    postalCode: '07613',
    city: 'Silbitz'
  },
  {
    postalCode: '07613',
    city: 'Walpernhain'
  },
  {
    postalCode: '07616',
    city: 'Beulbar-Ilmsdorf'
  },
  {
    postalCode: '07616',
    city: 'Bürgel'
  },
  {
    postalCode: '07616',
    city: 'Droschka'
  },
  {
    postalCode: '07616',
    city: 'Graitschen bei Bürgel'
  },
  {
    postalCode: '07616',
    city: 'Hetzdorf'
  },
  {
    postalCode: '07616',
    city: 'Hohendorf'
  },
  {
    postalCode: '07616',
    city: 'Langethalsmühle'
  },
  {
    postalCode: '07616',
    city: 'Lochmühle'
  },
  {
    postalCode: '07616',
    city: 'Nausnitz'
  },
  {
    postalCode: '07616',
    city: 'Ölmühle'
  },
  {
    postalCode: '07616',
    city: 'Petersberg'
  },
  {
    postalCode: '07616',
    city: 'Poxdorf'
  },
  {
    postalCode: '07616',
    city: 'Rauschwitz'
  },
  {
    postalCode: '07616',
    city: 'Rodigast-Lucka'
  },
  {
    postalCode: '07616',
    city: 'Serba'
  },
  {
    postalCode: '07616',
    city: 'Taupadel'
  },
  {
    postalCode: '07616',
    city: 'Thalbürgel'
  },
  {
    postalCode: '07616',
    city: 'Wilhelmshöhe'
  },
  {
    postalCode: '07619',
    city: 'Mertendorf'
  },
  {
    postalCode: '07619',
    city: 'Schkölen'
  },
  {
    postalCode: '07619',
    city: 'Stünzmühle'
  },
  {
    postalCode: '07629',
    city: 'Hermsdorf'
  },
  {
    postalCode: '07629',
    city: 'Reichenbach'
  },
  {
    postalCode: '07629',
    city: 'Schleifreisen'
  },
  {
    postalCode: '07629',
    city: 'St Gangloff'
  },
  {
    postalCode: '07639',
    city: 'Bad Klosterlausnitz'
  },
  {
    postalCode: '07639',
    city: 'Tautenhain'
  },
  {
    postalCode: '07639',
    city: 'Weißenborn'
  },
  {
    postalCode: '07646',
    city: 'Albersdorf'
  },
  {
    postalCode: '07646',
    city: 'Bobeck'
  },
  {
    postalCode: '07646',
    city: 'Bollberg'
  },
  {
    postalCode: '07646',
    city: 'Bremsnitz'
  },
  {
    postalCode: '07646',
    city: 'Eineborn'
  },
  {
    postalCode: '07646',
    city: 'Geisenhain'
  },
  {
    postalCode: '07646',
    city: 'Gneus'
  },
  {
    postalCode: '07646',
    city: 'Großbockedra'
  },
  {
    postalCode: '07646',
    city: 'Karlsdorf'
  },
  {
    postalCode: '07646',
    city: 'Kleinbockedra'
  },
  {
    postalCode: '07646',
    city: 'Kleinebersdorf'
  },
  {
    postalCode: '07646',
    city: 'Laasdorf'
  },
  {
    postalCode: '07646',
    city: 'Lippersdorf-Erdmannsdorf'
  },
  {
    postalCode: '07646',
    city: 'Meusebach'
  },
  {
    postalCode: '07646',
    city: 'Möckern'
  },
  {
    postalCode: '07646',
    city: 'Mörsdorf'
  },
  {
    postalCode: '07646',
    city: 'Oberbodnitz'
  },
  {
    postalCode: '07646',
    city: 'Ottendorf'
  },
  {
    postalCode: '07646',
    city: 'Quirla'
  },
  {
    postalCode: '07646',
    city: 'Rattelsdorf'
  },
  {
    postalCode: '07646',
    city: 'Rausdorf'
  },
  {
    postalCode: '07646',
    city: 'Renthendorf'
  },
  {
    postalCode: '07646',
    city: 'Ruttersdorf-Lotschen'
  },
  {
    postalCode: '07646',
    city: 'Scheiditz'
  },
  {
    postalCode: '07646',
    city: 'Schlöben'
  },
  {
    postalCode: '07646',
    city: 'Schöngleina'
  },
  {
    postalCode: '07646',
    city: 'Stadtroda'
  },
  {
    postalCode: '07646',
    city: 'Tautendorf'
  },
  {
    postalCode: '07646',
    city: 'Tissa'
  },
  {
    postalCode: '07646',
    city: 'Tröbnitz'
  },
  {
    postalCode: '07646',
    city: 'Trockenborn-Wolfersdorf'
  },
  {
    postalCode: '07646',
    city: 'Unterbodnitz'
  },
  {
    postalCode: '07646',
    city: 'Waldeck'
  },
  {
    postalCode: '07646',
    city: 'Waltersdorf'
  },
  {
    postalCode: '07646',
    city: 'Weißbach'
  },
  {
    postalCode: '07743',
    city: 'Jena'
  },
  {
    postalCode: '07745',
    city: 'Jena'
  },
  {
    postalCode: '07747',
    city: 'Jena'
  },
  {
    postalCode: '07749',
    city: 'Jena'
  },
  {
    postalCode: '07751',
    city: 'Bucha'
  },
  {
    postalCode: '07751',
    city: 'Cospeda'
  },
  {
    postalCode: '07751',
    city: 'Drackendorf'
  },
  {
    postalCode: '07751',
    city: 'Golmsdorf'
  },
  {
    postalCode: '07751',
    city: 'Großlöbichau'
  },
  {
    postalCode: '07751',
    city: 'Großpürschütz'
  },
  {
    postalCode: '07751',
    city: 'Isserstedt'
  },
  {
    postalCode: '07751',
    city: 'Jenalöbnitz'
  },
  {
    postalCode: '07751',
    city: 'Jenaprießnitz'
  },
  {
    postalCode: '07751',
    city: 'Krippendorf'
  },
  {
    postalCode: '07751',
    city: 'Kunitz'
  },
  {
    postalCode: '07751',
    city: 'Löberschütz'
  },
  {
    postalCode: '07751',
    city: 'Maua'
  },
  {
    postalCode: '07751',
    city: 'Milda'
  },
  {
    postalCode: '07751',
    city: 'Münchenroda'
  },
  {
    postalCode: '07751',
    city: 'Rothenstein'
  },
  {
    postalCode: '07751',
    city: 'Sulza'
  },
  {
    postalCode: '07751',
    city: 'Zöllnitz'
  },
  {
    postalCode: '07768',
    city: 'Altenberga'
  },
  {
    postalCode: '07768',
    city: 'Bibra'
  },
  {
    postalCode: '07768',
    city: 'Eichenberg'
  },
  {
    postalCode: '07768',
    city: 'Freienorla'
  },
  {
    postalCode: '07768',
    city: 'Großeutersdorf'
  },
  {
    postalCode: '07768',
    city: 'Gumperda'
  },
  {
    postalCode: '07768',
    city: 'Hummelshain'
  },
  {
    postalCode: '07768',
    city: 'Kahla'
  },
  {
    postalCode: '07768',
    city: 'Kleineutersdorf'
  },
  {
    postalCode: '07768',
    city: 'Lindig'
  },
  {
    postalCode: '07768',
    city: 'Orlamünde'
  },
  {
    postalCode: '07768',
    city: 'Reinstädt'
  },
  {
    postalCode: '07768',
    city: 'Schöps'
  },
  {
    postalCode: '07768',
    city: 'Seitenroda'
  },
  {
    postalCode: '07774',
    city: 'Camburg'
  },
  {
    postalCode: '07774',
    city: 'Frauenprießnitz'
  },
  {
    postalCode: '07774',
    city: 'Thierschneck'
  },
  {
    postalCode: '07774',
    city: 'Wichmar'
  },
  {
    postalCode: '07778',
    city: 'Dornburg'
  },
  {
    postalCode: '07778',
    city: 'Dorndorf-Steudnitz'
  },
  {
    postalCode: '07778',
    city: 'Hainichen'
  },
  {
    postalCode: '07778',
    city: 'Lehesten'
  },
  {
    postalCode: '07778',
    city: 'Neuengönna'
  },
  {
    postalCode: '07778',
    city: 'Tautenburg'
  },
  {
    postalCode: '07778',
    city: 'Zimmern'
  },
  {
    postalCode: '07806',
    city: 'Breitenhain'
  },
  {
    postalCode: '07806',
    city: 'Burgwitz'
  },
  {
    postalCode: '07806',
    city: 'Dreba'
  },
  {
    postalCode: '07806',
    city: 'Eisenhammer'
  },
  {
    postalCode: '07806',
    city: 'Harrasmühle'
  },
  {
    postalCode: '07806',
    city: 'Kospoda'
  },
  {
    postalCode: '07806',
    city: 'Lausnitz'
  },
  {
    postalCode: '07806',
    city: 'Meilitz'
  },
  {
    postalCode: '07806',
    city: 'Neustadt'
  },
  {
    postalCode: '07806',
    city: 'Papiermühle'
  },
  {
    postalCode: '07806',
    city: 'Stanau'
  },
  {
    postalCode: '07806',
    city: 'Weira'
  },
  {
    postalCode: '07819',
    city: 'Burkersdorf'
  },
  {
    postalCode: '07819',
    city: 'Dreitzsch'
  },
  {
    postalCode: '07819',
    city: 'Geheege'
  },
  {
    postalCode: '07819',
    city: 'Geroda'
  },
  {
    postalCode: '07819',
    city: 'Kopitzsch'
  },
  {
    postalCode: '07819',
    city: 'Lemnitz'
  },
  {
    postalCode: '07819',
    city: 'Leubsdorf'
  },
  {
    postalCode: '07819',
    city: 'Linda'
  },
  {
    postalCode: '07819',
    city: 'Miesitz'
  },
  {
    postalCode: '07819',
    city: 'Mittelpöllnitz'
  },
  {
    postalCode: '07819',
    city: 'Pillingsdorf'
  },
  {
    postalCode: '07819',
    city: 'Rosendorf'
  },
  {
    postalCode: '07819',
    city: 'Schmieritz'
  },
  {
    postalCode: '07819',
    city: 'Tömmelsdorf'
  },
  {
    postalCode: '07819',
    city: 'Traun'
  },
  {
    postalCode: '07819',
    city: 'Triptis'
  },
  {
    postalCode: '07819',
    city: 'Weltwitz'
  },
  {
    postalCode: '07819',
    city: 'Wittchenstein'
  },
  {
    postalCode: '07819',
    city: 'Wüstenwetzdorf'
  },
  {
    postalCode: '07819',
    city: 'Zwackau'
  },
  {
    postalCode: '07907',
    city: 'Burgk'
  },
  {
    postalCode: '07907',
    city: 'Chursdorf'
  },
  {
    postalCode: '07907',
    city: 'Dittersdorf'
  },
  {
    postalCode: '07907',
    city: 'Dragensdorf'
  },
  {
    postalCode: '07907',
    city: 'Görkwitz'
  },
  {
    postalCode: '07907',
    city: 'Göschitz'
  },
  {
    postalCode: '07907',
    city: 'Löhma'
  },
  {
    postalCode: '07907',
    city: 'Moßbach'
  },
  {
    postalCode: '07907',
    city: 'Oettersdorf'
  },
  {
    postalCode: '07907',
    city: 'Plothen'
  },
  {
    postalCode: '07907',
    city: 'Pörmitz'
  },
  {
    postalCode: '07907',
    city: 'Schleiz'
  },
  {
    postalCode: '07907',
    city: 'Tegau'
  },
  {
    postalCode: '07919',
    city: 'Kirschkau'
  },
  {
    postalCode: '07919',
    city: 'Langenbach'
  },
  {
    postalCode: '07919',
    city: 'Mühltroff'
  },
  {
    postalCode: '07922',
    city: 'Tanna'
  },
  {
    postalCode: '07924',
    city: 'Crispendorf'
  },
  {
    postalCode: '07924',
    city: 'Eßbach'
  },
  {
    postalCode: '07924',
    city: 'Neundorf'
  },
  {
    postalCode: '07924',
    city: 'Schöndorf'
  },
  {
    postalCode: '07924',
    city: 'Volkmannsdorf'
  },
  {
    postalCode: '07924',
    city: 'Ziegenrück'
  },
  {
    postalCode: '07926',
    city: 'Gefell'
  },
  {
    postalCode: '07927',
    city: 'Hirschberg'
  },
  {
    postalCode: '07929',
    city: 'Saalburg'
  },
  {
    postalCode: '07937',
    city: 'Förthen'
  },
  {
    postalCode: '07937',
    city: 'Langenwolschendorf'
  },
  {
    postalCode: '07937',
    city: 'Läwitz'
  },
  {
    postalCode: '07937',
    city: 'Leitlitz'
  },
  {
    postalCode: '07937',
    city: 'Pahren'
  },
  {
    postalCode: '07937',
    city: 'Pöllwitz'
  },
  {
    postalCode: '07937',
    city: 'Silberfeld'
  },
  {
    postalCode: '07937',
    city: 'Weckersdorf'
  },
  {
    postalCode: '07937',
    city: 'Zadelsdorf'
  },
  {
    postalCode: '07937',
    city: 'Zeulenroda'
  },
  {
    postalCode: '07950',
    city: 'Göhren-Döhlen'
  },
  {
    postalCode: '07950',
    city: 'Merkendorf'
  },
  {
    postalCode: '07950',
    city: 'Niederböhmersdorf'
  },
  {
    postalCode: '07950',
    city: 'Staitz'
  },
  {
    postalCode: '07950',
    city: 'Triebes'
  },
  {
    postalCode: '07950',
    city: 'Weißendorf'
  },
  {
    postalCode: '07950',
    city: 'Wiebelsdorf'
  },
  {
    postalCode: '07952',
    city: 'Arnsgrün'
  },
  {
    postalCode: '07952',
    city: 'Bernsgrün'
  },
  {
    postalCode: '07952',
    city: 'Pausa'
  },
  {
    postalCode: '07955',
    city: 'Auma'
  },
  {
    postalCode: '07955',
    city: 'Braunsdorf'
  },
  {
    postalCode: '07957',
    city: 'Hain'
  },
  {
    postalCode: '07957',
    city: 'Langenwetzendorf'
  },
  {
    postalCode: '07958',
    city: 'Hohenleuben'
  },
  {
    postalCode: '07973',
    city: 'Greiz'
  },
  {
    postalCode: '07973',
    city: 'Krebsbach Talsperre'
  },
  {
    postalCode: '07980',
    city: 'Berga'
  },
  {
    postalCode: '07980',
    city: 'Großkundorf'
  },
  {
    postalCode: '07980',
    city: 'Kühdorf'
  },
  {
    postalCode: '07980',
    city: 'Lunzig'
  },
  {
    postalCode: '07980',
    city: 'Neugernsdorf'
  },
  {
    postalCode: '07980',
    city: 'Neumühle'
  },
  {
    postalCode: '07980',
    city: 'Waltersdorf'
  },
  {
    postalCode: '07980',
    city: 'Wildetaube'
  },
  {
    postalCode: '07985',
    city: 'Cossengrün'
  },
  {
    postalCode: '07985',
    city: 'Elsterberg'
  },
  {
    postalCode: '07985',
    city: 'Hohndorf'
  },
  {
    postalCode: '07985',
    city: 'Leiningen'
  },
  {
    postalCode: '07985',
    city: 'Rentzschmühle'
  },
  {
    postalCode: '07985',
    city: 'Schönbach'
  },
  {
    postalCode: '07985',
    city: 'Tremnitz'
  },
  {
    postalCode: '07987',
    city: 'Mohlsdorf'
  },
  {
    postalCode: '07989',
    city: 'Kleinreinsdorf'
  },
  {
    postalCode: '07989',
    city: 'Teichwolframsdorf'
  },
  {
    postalCode: '08056',
    city: 'Zwickau'
  },
  {
    postalCode: '08058',
    city: 'Zwickau'
  },
  {
    postalCode: '08060',
    city: 'Zwickau'
  },
  {
    postalCode: '08062',
    city: 'Zwickau'
  },
  {
    postalCode: '08064',
    city: 'Zwickau'
  },
  {
    postalCode: '08066',
    city: 'Zwickau'
  },
  {
    postalCode: '08107',
    city: 'Hartmannsdorf'
  },
  {
    postalCode: '08107',
    city: 'Kirchberg'
  },
  {
    postalCode: '08107',
    city: 'Niedercrinitz'
  },
  {
    postalCode: '08112',
    city: 'Wilkau-Haßlau'
  },
  {
    postalCode: '08115',
    city: 'Lichtentanne'
  },
  {
    postalCode: '08115',
    city: 'Schönfels'
  },
  {
    postalCode: '08118',
    city: 'Hartenstein'
  },
  {
    postalCode: '08121',
    city: 'Silberstraße'
  },
  {
    postalCode: '08132',
    city: 'Mülsen'
  },
  {
    postalCode: '08134',
    city: 'Langenweißbach'
  },
  {
    postalCode: '08134',
    city: 'Wildenfels'
  },
  {
    postalCode: '08141',
    city: 'Reinsdorf'
  },
  {
    postalCode: '08115',
    city: 'Ebersbrunn'
  },
  {
    postalCode: '08144',
    city: 'Hirschfeld'
  },
  {
    postalCode: '08115',
    city: 'Stenn'
  },
  {
    postalCode: '08144',
    city: 'Waldhaus'
  },
  {
    postalCode: '08147',
    city: 'Crinitzberg'
  },
  {
    postalCode: '08209',
    city: 'Auerbach'
  },
  {
    postalCode: '08209',
    city: 'Rebesgrün'
  },
  {
    postalCode: '08223',
    city: 'Falkenstein'
  },
  {
    postalCode: '08223',
    city: 'Grünbach'
  },
  {
    postalCode: '08223',
    city: 'Grünbach-Muldenberg'
  },
  {
    postalCode: '08223',
    city: 'Neustadt'
  },
  {
    postalCode: '08223',
    city: 'Werda'
  },
  {
    postalCode: '08228',
    city: 'Rodewisch'
  },
  {
    postalCode: '08233',
    city: 'Eich'
  },
  {
    postalCode: '08233',
    city: 'Hartmannsgrün'
  },
  {
    postalCode: '08233',
    city: 'Treuen'
  },
  {
    postalCode: '08236',
    city: 'Ellefeld'
  },
  {
    postalCode: '08237',
    city: 'Steinberg'
  },
  {
    postalCode: '08239',
    city: 'Bergen'
  },
  {
    postalCode: '08239',
    city: 'Oberlauterbach'
  },
  {
    postalCode: '08239',
    city: 'Trieb'
  },
  {
    postalCode: '08248',
    city: 'Klingenthal'
  },
  {
    postalCode: '08258',
    city: 'Bethanien'
  },
  {
    postalCode: '08258',
    city: 'Breitenfeld'
  },
  {
    postalCode: '08258',
    city: 'Landwüst'
  },
  {
    postalCode: '08258',
    city: 'Markneukirchen'
  },
  {
    postalCode: '08258',
    city: 'Wernitzgrün'
  },
  {
    postalCode: '08261',
    city: 'Schöneck'
  },
  {
    postalCode: '08262',
    city: 'Morgenröthe-Rautenkranz'
  },
  {
    postalCode: '08262',
    city: 'Tannenbergsthal'
  },
  {
    postalCode: '08265',
    city: 'Erlbach'
  },
  {
    postalCode: '08267',
    city: 'Zwota'
  },
  {
    postalCode: '08269',
    city: 'Hammerbrücke'
  },
  {
    postalCode: '08280',
    city: 'Aue'
  },
  {
    postalCode: '08289',
    city: 'Schneeberg'
  },
  {
    postalCode: '08294',
    city: 'Lößnitz'
  },
  {
    postalCode: '08297',
    city: 'Zwönitz'
  },
  {
    postalCode: '08301',
    city: 'Schlema'
  },
  {
    postalCode: '08304',
    city: 'Schönheide'
  },
  {
    postalCode: '08309',
    city: 'Blechhammer'
  },
  {
    postalCode: '08309',
    city: 'Eibenstock'
  },
  {
    postalCode: '08309',
    city: 'Neues Wiesenhaus'
  },
  {
    postalCode: '08309',
    city: 'Stabhammer'
  },
  {
    postalCode: '08309',
    city: 'Weitersglashütte'
  },
  {
    postalCode: '08309',
    city: 'Wildenthal'
  },
  {
    postalCode: '08309',
    city: 'Wilzschmühle'
  },
  {
    postalCode: '08312',
    city: 'Lauter'
  },
  {
    postalCode: '08315',
    city: 'Bernsbach'
  },
  {
    postalCode: '08318',
    city: 'Blauenthal'
  },
  {
    postalCode: '08318',
    city: 'Hundshübel'
  },
  {
    postalCode: '08318',
    city: 'Lichtenau'
  },
  {
    postalCode: '08318',
    city: 'Neidhardtsthal'
  },
  {
    postalCode: '08318',
    city: 'Torfstich'
  },
  {
    postalCode: '08318',
    city: 'Wolfsgrün'
  },
  {
    postalCode: '08321',
    city: 'Zschorlau'
  },
  {
    postalCode: '08324',
    city: 'Bockau'
  },
  {
    postalCode: '08325',
    city: 'Carlsfeld'
  },
  {
    postalCode: '08326',
    city: 'Sosa'
  },
  {
    postalCode: '08328',
    city: 'Stützengrün'
  },
  {
    postalCode: '08340',
    city: 'Beierfeld'
  },
  {
    postalCode: '08340',
    city: 'Schwarzenberg'
  },
  {
    postalCode: '08349',
    city: 'Erlabrunn'
  },
  {
    postalCode: '08349',
    city: 'Johanngeorgenstadt'
  },
  {
    postalCode: '08352',
    city: 'Markersbach'
  },
  {
    postalCode: '08352',
    city: 'Pöhla'
  },
  {
    postalCode: '08352',
    city: 'Raschau'
  },
  {
    postalCode: '08352',
    city: 'Wolfner Mühle'
  },
  {
    postalCode: '08355',
    city: 'Rittersgrün'
  },
  {
    postalCode: '08358',
    city: 'Grünhain'
  },
  {
    postalCode: '08359',
    city: 'Breitenbrunn'
  },
  {
    postalCode: '08371',
    city: 'Glauchau'
  },
  {
    postalCode: '08373',
    city: 'Remse'
  },
  {
    postalCode: '08393',
    city: 'Dennheritz'
  },
  {
    postalCode: '08393',
    city: 'Meerane'
  },
  {
    postalCode: '08393',
    city: 'Schönberg'
  },
  {
    postalCode: '08396',
    city: 'Dürrenuhlsdorf'
  },
  {
    postalCode: '08396',
    city: 'Oberwiera'
  },
  {
    postalCode: '08396',
    city: 'Waldenburg'
  },
  {
    postalCode: '08399',
    city: 'Wolkenburg-Kaufungen'
  },
  {
    postalCode: '08412',
    city: 'Königswalde'
  },
  {
    postalCode: '08412',
    city: 'Leubnitz'
  },
  {
    postalCode: '08412',
    city: 'Werdau'
  },
  {
    postalCode: '08427',
    city: 'Fraureuth'
  },
  {
    postalCode: '08428',
    city: 'Langenbernsdorf'
  },
  {
    postalCode: '08412',
    city: 'Steinpleis'
  },
  {
    postalCode: '08412',
    city: 'Langenhessen'
  },
  {
    postalCode: '08451',
    city: 'Crimmitschau'
  },
  {
    postalCode: '08459',
    city: 'Neukirchen'
  },
  {
    postalCode: '08468',
    city: 'Heinsdorfergrund'
  },
  {
    postalCode: '08468',
    city: 'Mühlwand'
  },
  {
    postalCode: '08468',
    city: 'Reichenbach'
  },
  {
    postalCode: '08468',
    city: 'Schneidenbach'
  },
  {
    postalCode: '08485',
    city: 'Lengenfeld'
  },
  {
    postalCode: '08485',
    city: 'Schönbrunn'
  },
  {
    postalCode: '08485',
    city: 'Waldkirchen'
  },
  {
    postalCode: '08491',
    city: 'Brockau'
  },
  {
    postalCode: '08491',
    city: 'Jägerhaus'
  },
  {
    postalCode: '08491',
    city: 'Lauschgrün'
  },
  {
    postalCode: '08491',
    city: 'Limbach'
  },
  {
    postalCode: '08491',
    city: 'Netzschkau'
  },
  {
    postalCode: '08491',
    city: 'Reimersgrün'
  },
  {
    postalCode: '08496',
    city: 'Neumark'
  },
  {
    postalCode: '08499',
    city: 'Mylau'
  },
  {
    postalCode: '08523',
    city: 'Plauen'
  },
  {
    postalCode: '08525',
    city: 'Kauschwitz'
  },
  {
    postalCode: '08525',
    city: 'Plauen'
  },
  {
    postalCode: '08527',
    city: 'Neundorf'
  },
  {
    postalCode: '08527',
    city: 'Plauen'
  },
  {
    postalCode: '08527',
    city: 'Rößnitz'
  },
  {
    postalCode: '08527',
    city: 'Schneckengrün'
  },
  {
    postalCode: '08527',
    city: 'Straßberg'
  },
  {
    postalCode: '08529',
    city: 'Plauen'
  },
  {
    postalCode: '08538',
    city: 'Burgstein'
  },
  {
    postalCode: '08538',
    city: 'Reuth'
  },
  {
    postalCode: '08538',
    city: 'Weischlitz'
  },
  {
    postalCode: '08539',
    city: 'Kornbach'
  },
  {
    postalCode: '08539',
    city: 'Leubnitz'
  },
  {
    postalCode: '08539',
    city: 'Mehltheuer'
  },
  {
    postalCode: '08539',
    city: 'Rodau'
  },
  {
    postalCode: '08539',
    city: 'Schönberg'
  },
  {
    postalCode: '08541',
    city: 'Großfriesen'
  },
  {
    postalCode: '08541',
    city: 'Mechelgrün'
  },
  {
    postalCode: '08541',
    city: 'Neuensalz'
  },
  {
    postalCode: '08541',
    city: 'Theuma'
  },
  {
    postalCode: '08541',
    city: 'Thoßfell'
  },
  {
    postalCode: '08541',
    city: 'Zobes'
  },
  {
    postalCode: '08543',
    city: 'Helmsgrün'
  },
  {
    postalCode: '08543',
    city: 'Herlasgrün'
  },
  {
    postalCode: '08543',
    city: 'Jocketa'
  },
  {
    postalCode: '08543',
    city: 'Möschwitz'
  },
  {
    postalCode: '08543',
    city: 'Ruppertsgrün'
  },
  {
    postalCode: '08547',
    city: 'Jößnitz'
  },
  {
    postalCode: '08548',
    city: 'Syrau'
  },
  {
    postalCode: '08606',
    city: 'Bobenneukirchen'
  },
  {
    postalCode: '08606',
    city: 'Bösenbrunn'
  },
  {
    postalCode: '08606',
    city: 'Droßdorf'
  },
  {
    postalCode: '08606',
    city: 'Lottengrün'
  },
  {
    postalCode: '08606',
    city: 'Oelsnitz'
  },
  {
    postalCode: '08606',
    city: 'Ottengrün'
  },
  {
    postalCode: '08606',
    city: 'Planschwitz'
  },
  {
    postalCode: '08606',
    city: 'Posseck'
  },
  {
    postalCode: '08606',
    city: 'Sachsgrün'
  },
  {
    postalCode: '08606',
    city: 'Schönbrunn'
  },
  {
    postalCode: '08606',
    city: 'Tirpersdorf'
  },
  {
    postalCode: '08606',
    city: 'Tirschendorf'
  },
  {
    postalCode: '08606',
    city: 'Triebel'
  },
  {
    postalCode: '08626',
    city: 'Adorf'
  },
  {
    postalCode: '08626',
    city: 'Eichigt'
  },
  {
    postalCode: '08626',
    city: 'Gettengrün'
  },
  {
    postalCode: '08626',
    city: 'Hermsgrün'
  },
  {
    postalCode: '08626',
    city: 'Leubetha'
  },
  {
    postalCode: '08626',
    city: 'Marieney'
  },
  {
    postalCode: '08626',
    city: 'Rebersreuth'
  },
  {
    postalCode: '08626',
    city: 'Unterwürschnitz'
  },
  {
    postalCode: '08626',
    city: 'Wohlbach'
  },
  {
    postalCode: '08645',
    city: 'Bad Elster'
  },
  {
    postalCode: '08648',
    city: 'Bad Brambach'
  },
  {
    postalCode: '09111',
    city: 'Chemnitz'
  },
  {
    postalCode: '09112',
    city: 'Chemnitz'
  },
  {
    postalCode: '09113',
    city: 'Chemnitz'
  },
  {
    postalCode: '09114',
    city: 'Chemnitz'
  },
  {
    postalCode: '09116',
    city: 'Chemnitz'
  },
  {
    postalCode: '09117',
    city: 'Chemnitz'
  },
  {
    postalCode: '09119',
    city: 'Chemnitz'
  },
  {
    postalCode: '09120',
    city: 'Chemnitz'
  },
  {
    postalCode: '09122',
    city: 'Chemnitz'
  },
  {
    postalCode: '09123',
    city: 'Chemnitz'
  },
  {
    postalCode: '09125',
    city: 'Chemnitz'
  },
  {
    postalCode: '09126',
    city: 'Chemnitz'
  },
  {
    postalCode: '09127',
    city: 'Chemnitz'
  },
  {
    postalCode: '09128',
    city: 'Chemnitz'
  },
  {
    postalCode: '09130',
    city: 'Chemnitz'
  },
  {
    postalCode: '09131',
    city: 'Chemnitz'
  },
  {
    postalCode: '09212',
    city: 'Bräunsdorf'
  },
  {
    postalCode: '09212',
    city: 'Limbach-Oberfrohna'
  },
  {
    postalCode: '09217',
    city: 'Burgstädt'
  },
  {
    postalCode: '09221',
    city: 'Neukirchen'
  },
  {
    postalCode: '09221',
    city: 'Neukirchen-Adorf'
  },
  {
    postalCode: '09224',
    city: 'Grüna'
  },
  {
    postalCode: '09224',
    city: 'Mittelbach'
  },
  {
    postalCode: '09228',
    city: 'Wittgensdorf'
  },
  {
    postalCode: '09232',
    city: 'Hartmannsdorf'
  },
  {
    postalCode: '09235',
    city: 'Burkhardtsdorf'
  },
  {
    postalCode: '09236',
    city: 'Claußnitz'
  },
  {
    postalCode: '09241',
    city: 'Mühlau'
  },
  {
    postalCode: '09243',
    city: 'Niederfrohna'
  },
  {
    postalCode: '09244',
    city: 'Lichtenau'
  },
  {
    postalCode: '09246',
    city: 'Pleißa'
  },
  {
    postalCode: '09247',
    city: 'Kändler'
  },
  {
    postalCode: '09247',
    city: 'Röhrsdorf'
  },
  {
    postalCode: '09249',
    city: 'Taura'
  },
  {
    postalCode: '09306',
    city: 'Doberenz'
  },
  {
    postalCode: '09306',
    city: 'Erlau'
  },
  {
    postalCode: '09306',
    city: 'Hausdorf'
  },
  {
    postalCode: '09306',
    city: 'Kaltenborn'
  },
  {
    postalCode: '09306',
    city: 'Königsfeld'
  },
  {
    postalCode: '09306',
    city: 'Königshain-Wiederau'
  },
  {
    postalCode: '09306',
    city: 'Köttwitzsch'
  },
  {
    postalCode: '09306',
    city: 'Leupahn'
  },
  {
    postalCode: '09306',
    city: 'Leutenhain'
  },
  {
    postalCode: '09306',
    city: 'Rochlitz'
  },
  {
    postalCode: '09306',
    city: 'Schwarzbach'
  },
  {
    postalCode: '09306',
    city: 'Seelitz'
  },
  {
    postalCode: '09306',
    city: 'Seupahn'
  },
  {
    postalCode: '09306',
    city: 'Stollsdorf'
  },
  {
    postalCode: '09306',
    city: 'Terpitzsch'
  },
  {
    postalCode: '09306',
    city: 'Wechselburg'
  },
  {
    postalCode: '09306',
    city: 'Weiditz'
  },
  {
    postalCode: '09306',
    city: 'Weißbach'
  },
  {
    postalCode: '09306',
    city: 'Zettlitz'
  },
  {
    postalCode: '09306',
    city: 'Zollwitz'
  },
  {
    postalCode: '09306',
    city: 'Zschirla'
  },
  {
    postalCode: '09322',
    city: 'Chursdorf'
  },
  {
    postalCode: '09322',
    city: 'Penig'
  },
  {
    postalCode: '09322',
    city: 'Tauscha'
  },
  {
    postalCode: '09322',
    city: 'Thierbach'
  },
  {
    postalCode: '09326',
    city: 'Aitzendorf'
  },
  {
    postalCode: '09326',
    city: 'Arras'
  },
  {
    postalCode: '09326',
    city: 'Dittmannsdorf'
  },
  {
    postalCode: '09326',
    city: 'Erlbach'
  },
  {
    postalCode: '09326',
    city: 'Geringswalde'
  },
  {
    postalCode: '09326',
    city: 'Holzhausen'
  },
  {
    postalCode: '09326',
    city: 'Koltzschen'
  },
  {
    postalCode: '09328',
    city: 'Lunzenau'
  },
  {
    postalCode: '09337',
    city: 'Bernsdorf'
  },
  {
    postalCode: '09337',
    city: 'Callenberg'
  },
  {
    postalCode: '09337',
    city: 'Hohenstein-Ernstthal'
  },
  {
    postalCode: '09350',
    city: 'Lichtenstein'
  },
  {
    postalCode: '09353',
    city: 'Oberlungwitz'
  },
  {
    postalCode: '09355',
    city: 'Gersdorf'
  },
  {
    postalCode: '09356',
    city: 'St. Egidien'
  },
  {
    postalCode: '09366',
    city: 'Niederdorf'
  },
  {
    postalCode: '09366',
    city: 'Stollberg'
  },
  {
    postalCode: '09376',
    city: 'Oelsnitz'
  },
  {
    postalCode: '09380',
    city: 'Thalheim'
  },
  {
    postalCode: '09385',
    city: 'Erlbach-Kirchberg'
  },
  {
    postalCode: '09385',
    city: 'Lugau'
  },
  {
    postalCode: '09387',
    city: 'Jahnsdorf'
  },
  {
    postalCode: '09390',
    city: 'Gornsdorf'
  },
  {
    postalCode: '09392',
    city: 'Auerbach'
  },
  {
    postalCode: '09394',
    city: 'Hohndorf'
  },
  {
    postalCode: '09395',
    city: 'Hormersdorf'
  },
  {
    postalCode: '09399',
    city: 'Niederwürschnitz'
  },
  {
    postalCode: '09405',
    city: 'Gornau'
  },
  {
    postalCode: '09405',
    city: 'Zschopau'
  },
  {
    postalCode: '09419',
    city: 'Thum'
  },
  {
    postalCode: '09419',
    city: 'Thum-Herold'
  },
  {
    postalCode: '09419',
    city: 'Thum-Jahnsbach'
  },
  {
    postalCode: '09419',
    city: 'Venusberg Spinnerei'
  },
  {
    postalCode: '09423',
    city: 'Gelenau'
  },
  {
    postalCode: '09423',
    city: 'Wiltzsch'
  },
  {
    postalCode: '09427',
    city: 'Ehrenfriedersdorf'
  },
  {
    postalCode: '09429',
    city: 'Falkenbach'
  },
  {
    postalCode: '09429',
    city: 'Gehringswalde'
  },
  {
    postalCode: '09429',
    city: 'Hilmersdorf'
  },
  {
    postalCode: '09429',
    city: 'Hopfgarten'
  },
  {
    postalCode: '09429',
    city: 'Schönbrunn'
  },
  {
    postalCode: '09429',
    city: 'Wolkenstein'
  },
  {
    postalCode: '09430',
    city: 'Drebach'
  },
  {
    postalCode: '09430',
    city: 'Venusberg'
  },
  {
    postalCode: '09432',
    city: 'Großolbersdorf'
  },
  {
    postalCode: '09434',
    city: 'Hohndorf'
  },
  {
    postalCode: '09434',
    city: 'Krumhermersdorf'
  },
  {
    postalCode: '09435',
    city: 'Grießbach'
  },
  {
    postalCode: '09435',
    city: 'Scharfenstein'
  },
  {
    postalCode: '09437',
    city: 'Börnichen'
  },
  {
    postalCode: '09437',
    city: 'Waldkirchen'
  },
  {
    postalCode: '09437',
    city: 'Witzschdorf'
  },
  {
    postalCode: '09439',
    city: 'Amtsberg'
  },
  {
    postalCode: '09439',
    city: 'Wilischthal'
  },
  {
    postalCode: '09456',
    city: 'Annaberg-Buchholz'
  },
  {
    postalCode: '09456',
    city: 'Mildenau'
  },
  {
    postalCode: '09465',
    city: 'Sehmatal-Cranzahl'
  },
  {
    postalCode: '09465',
    city: 'Sehmatal-Neudorf'
  },
  {
    postalCode: '09465',
    city: 'Sehmatal-Sehma'
  },
  {
    postalCode: '09468',
    city: 'Geyer'
  },
  {
    postalCode: '09468',
    city: 'Jugendherberge Hormersdorf'
  },
  {
    postalCode: '09468',
    city: 'Tannenberg'
  },
  {
    postalCode: '09471',
    city: 'Bärenstein'
  },
  {
    postalCode: '09471',
    city: 'Königswalde'
  },
  {
    postalCode: '09474',
    city: 'Crottendorf'
  },
  {
    postalCode: '09474',
    city: 'Walthersdorf'
  },
  {
    postalCode: '09477',
    city: 'Grumbach'
  },
  {
    postalCode: '09477',
    city: 'Jöhstadt'
  },
  {
    postalCode: '09477',
    city: 'Steinbach'
  },
  {
    postalCode: '09481',
    city: 'Elterlein'
  },
  {
    postalCode: '09481',
    city: 'Scheibenberg'
  },
  {
    postalCode: '09484',
    city: 'Oberwiesenthal'
  },
  {
    postalCode: '09487',
    city: 'Schlettau'
  },
  {
    postalCode: '09488',
    city: 'Neundorf'
  },
  {
    postalCode: '09488',
    city: 'Plattenthal'
  },
  {
    postalCode: '09488',
    city: 'Schönfeld'
  },
  {
    postalCode: '09488',
    city: 'Thermalbad Wiesenbad'
  },
  {
    postalCode: '09488',
    city: 'Wiesa'
  },
  {
    postalCode: '09496',
    city: 'Kühnhaide'
  },
  {
    postalCode: '09496',
    city: 'Marienberg'
  },
  {
    postalCode: '09496',
    city: 'Pobershau'
  },
  {
    postalCode: '09496',
    city: 'Reitzenhain'
  },
  {
    postalCode: '09496',
    city: 'Satzung'
  },
  {
    postalCode: '09509',
    city: 'Pockau'
  },
  {
    postalCode: '09514',
    city: 'Lengefeld'
  },
  {
    postalCode: '09517',
    city: 'Zöblitz'
  },
  {
    postalCode: '09518',
    city: 'Großrückerswalde'
  },
  {
    postalCode: '09526',
    city: 'Heidersdorf'
  },
  {
    postalCode: '09526',
    city: 'Olbernhau'
  },
  {
    postalCode: '09526',
    city: 'Pfaffroda'
  },
  {
    postalCode: '09526',
    city: 'Pfaffroda-Dittmannsdorf'
  },
  {
    postalCode: '09526',
    city: 'Pfaffroda-Dörnthal'
  },
  {
    postalCode: '09526',
    city: 'Pfaffroda-Hallbach'
  },
  {
    postalCode: '09526',
    city: 'Pfaffroda-Haselbach'
  },
  {
    postalCode: '09526',
    city: 'Pfaffroda-Hutha'
  },
  {
    postalCode: '09526',
    city: 'Pfaffroda-Schönfeld'
  },
  {
    postalCode: '09526',
    city: 'Rübenau'
  },
  {
    postalCode: '09544',
    city: 'Neuhausen'
  },
  {
    postalCode: '09548',
    city: 'Deutschneudorf'
  },
  {
    postalCode: '09548',
    city: 'Seiffen'
  },
  {
    postalCode: '09557',
    city: 'Flöha'
  },
  {
    postalCode: '09569',
    city: 'Falkenau'
  },
  {
    postalCode: '09569',
    city: 'Frankenstein'
  },
  {
    postalCode: '09569',
    city: 'Gahlenz'
  },
  {
    postalCode: '09569',
    city: 'Oederan'
  },
  {
    postalCode: '09573',
    city: 'Augustusburg'
  },
  {
    postalCode: '09573',
    city: 'Dittmannsdorf'
  },
  {
    postalCode: '09573',
    city: 'Leubsdorf'
  },
  {
    postalCode: '09575',
    city: 'Eppendorf'
  },
  {
    postalCode: '09577',
    city: 'Niederwiesa'
  },
  {
    postalCode: '09579',
    city: 'Borstendorf'
  },
  {
    postalCode: '09579',
    city: 'Grünhainichen'
  },
  {
    postalCode: '09599',
    city: 'Freiberg'
  },
  {
    postalCode: '09600',
    city: 'Niederschöna'
  },
  {
    postalCode: '09600',
    city: 'Oberschöna'
  },
  {
    postalCode: '09600',
    city: 'Wegefarth'
  },
  {
    postalCode: '09600',
    city: 'Weißenborn'
  },
  {
    postalCode: '09603',
    city: 'Bräunsdorf'
  },
  {
    postalCode: '09603',
    city: 'Großschirma'
  },
  {
    postalCode: '09603',
    city: 'Langhennersdorf'
  },
  {
    postalCode: '09618',
    city: 'Brand-Erbisdorf'
  },
  {
    postalCode: '09618',
    city: 'Großhartmannsdorf'
  },
  {
    postalCode: '09619',
    city: 'Dorfchemnitz'
  },
  {
    postalCode: '09619',
    city: 'Helbigsdorf'
  },
  {
    postalCode: '09619',
    city: 'Mulda'
  },
  {
    postalCode: '09619',
    city: 'Sayda'
  },
  {
    postalCode: '09619',
    city: 'Voigtsdorf'
  },
  {
    postalCode: '09619',
    city: 'Wolfsgrund'
  },
  {
    postalCode: '09619',
    city: 'Zethau'
  },
  {
    postalCode: '09623',
    city: 'Frauenstein'
  },
  {
    postalCode: '09623',
    city: 'Rechenberg-Bienenmühle'
  },
  {
    postalCode: '09627',
    city: 'Bobritzsch'
  },
  {
    postalCode: '09627',
    city: 'Hilbersdorf'
  },
  {
    postalCode: '09629',
    city: 'Bieberstein'
  },
  {
    postalCode: '09629',
    city: 'Burkersdorf, Mulde'
  },
  {
    postalCode: '09629',
    city: 'Dittmannsdorf'
  },
  {
    postalCode: '09629',
    city: 'Drehfeld'
  },
  {
    postalCode: '09629',
    city: 'Neukirchen'
  },
  {
    postalCode: '09629',
    city: 'Reinsberg'
  },
  {
    postalCode: '09629',
    city: 'Steinbach'
  },
  {
    postalCode: '09629',
    city: 'Wolfsgrün'
  },
  {
    postalCode: '09633',
    city: 'Halsbrücke'
  },
  {
    postalCode: '09634',
    city: 'Friedland'
  },
  {
    postalCode: '09634',
    city: 'Hirschfeld'
  },
  {
    postalCode: '09634',
    city: 'Siebenlehn'
  },
  {
    postalCode: '09636',
    city: 'Langenau'
  },
  {
    postalCode: '09638',
    city: 'Lichtenberg'
  },
  {
    postalCode: '09648',
    city: 'Altmittweida'
  },
  {
    postalCode: '09648',
    city: 'Kriebstein'
  },
  {
    postalCode: '09648',
    city: 'Mittweida'
  },
  {
    postalCode: '09661',
    city: 'Hainichen'
  },
  {
    postalCode: '09661',
    city: 'Rossau'
  },
  {
    postalCode: '09661',
    city: 'Schlegel'
  },
  {
    postalCode: '09661',
    city: 'Striegistal'
  },
  {
    postalCode: '09661',
    city: 'Tiefenbach'
  },
  {
    postalCode: '09669',
    city: 'Frankenberg'
  },
  {
    postalCode: '10115',
    city: 'Berlin'
  },
  {
    postalCode: '10117',
    city: 'Berlin'
  },
  {
    postalCode: '10119',
    city: 'Berlin'
  },
  {
    postalCode: '10178',
    city: 'Berlin'
  },
  {
    postalCode: '10179',
    city: 'Berlin'
  },
  {
    postalCode: '10243',
    city: 'Berlin'
  },
  {
    postalCode: '10245',
    city: 'Berlin'
  },
  {
    postalCode: '10247',
    city: 'Berlin'
  },
  {
    postalCode: '10249',
    city: 'Berlin'
  },
  {
    postalCode: '10315',
    city: 'Berlin'
  },
  {
    postalCode: '10317',
    city: 'Berlin'
  },
  {
    postalCode: '10318',
    city: 'Berlin'
  },
  {
    postalCode: '10319',
    city: 'Berlin'
  },
  {
    postalCode: '10365',
    city: 'Berlin'
  },
  {
    postalCode: '10367',
    city: 'Berlin'
  },
  {
    postalCode: '10369',
    city: 'Berlin'
  },
  {
    postalCode: '10405',
    city: 'Berlin'
  },
  {
    postalCode: '10407',
    city: 'Berlin'
  },
  {
    postalCode: '10409',
    city: 'Berlin'
  },
  {
    postalCode: '10435',
    city: 'Berlin'
  },
  {
    postalCode: '10437',
    city: 'Berlin'
  },
  {
    postalCode: '10439',
    city: 'Berlin'
  },
  {
    postalCode: '10551',
    city: 'Berlin'
  },
  {
    postalCode: '10553',
    city: 'Berlin'
  },
  {
    postalCode: '10555',
    city: 'Berlin'
  },
  {
    postalCode: '10557',
    city: 'Berlin'
  },
  {
    postalCode: '10559',
    city: 'Berlin'
  },
  {
    postalCode: '10585',
    city: 'Berlin'
  },
  {
    postalCode: '10587',
    city: 'Berlin'
  },
  {
    postalCode: '10589',
    city: 'Berlin'
  },
  {
    postalCode: '10623',
    city: 'Berlin'
  },
  {
    postalCode: '10625',
    city: 'Berlin'
  },
  {
    postalCode: '10627',
    city: 'Berlin'
  },
  {
    postalCode: '10629',
    city: 'Berlin'
  },
  {
    postalCode: '10707',
    city: 'Berlin'
  },
  {
    postalCode: '10709',
    city: 'Berlin'
  },
  {
    postalCode: '10711',
    city: 'Berlin'
  },
  {
    postalCode: '10713',
    city: 'Berlin'
  },
  {
    postalCode: '10715',
    city: 'Berlin'
  },
  {
    postalCode: '10717',
    city: 'Berlin'
  },
  {
    postalCode: '10719',
    city: 'Berlin'
  },
  {
    postalCode: '10777',
    city: 'Berlin'
  },
  {
    postalCode: '10779',
    city: 'Berlin'
  },
  {
    postalCode: '10781',
    city: 'Berlin'
  },
  {
    postalCode: '10783',
    city: 'Berlin'
  },
  {
    postalCode: '10785',
    city: 'Berlin'
  },
  {
    postalCode: '10787',
    city: 'Berlin'
  },
  {
    postalCode: '10789',
    city: 'Berlin'
  },
  {
    postalCode: '10823',
    city: 'Berlin'
  },
  {
    postalCode: '10825',
    city: 'Berlin'
  },
  {
    postalCode: '10827',
    city: 'Berlin'
  },
  {
    postalCode: '10829',
    city: 'Berlin'
  },
  {
    postalCode: '10961',
    city: 'Berlin'
  },
  {
    postalCode: '10963',
    city: 'Berlin'
  },
  {
    postalCode: '10965',
    city: 'Berlin'
  },
  {
    postalCode: '10967',
    city: 'Berlin'
  },
  {
    postalCode: '10969',
    city: 'Berlin'
  },
  {
    postalCode: '10997',
    city: 'Berlin'
  },
  {
    postalCode: '10999',
    city: 'Berlin'
  },
  {
    postalCode: '12043',
    city: 'Berlin'
  },
  {
    postalCode: '12045',
    city: 'Berlin'
  },
  {
    postalCode: '12047',
    city: 'Berlin'
  },
  {
    postalCode: '12049',
    city: 'Berlin'
  },
  {
    postalCode: '12051',
    city: 'Berlin'
  },
  {
    postalCode: '12053',
    city: 'Berlin'
  },
  {
    postalCode: '12055',
    city: 'Berlin'
  },
  {
    postalCode: '12057',
    city: 'Berlin'
  },
  {
    postalCode: '12059',
    city: 'Berlin'
  },
  {
    postalCode: '12099',
    city: 'Berlin'
  },
  {
    postalCode: '12101',
    city: 'Berlin'
  },
  {
    postalCode: '12103',
    city: 'Berlin'
  },
  {
    postalCode: '12105',
    city: 'Berlin'
  },
  {
    postalCode: '12107',
    city: 'Berlin'
  },
  {
    postalCode: '12109',
    city: 'Berlin'
  },
  {
    postalCode: '12157',
    city: 'Berlin'
  },
  {
    postalCode: '12159',
    city: 'Berlin'
  },
  {
    postalCode: '12161',
    city: 'Berlin'
  },
  {
    postalCode: '12163',
    city: 'Berlin'
  },
  {
    postalCode: '12165',
    city: 'Berlin'
  },
  {
    postalCode: '12167',
    city: 'Berlin'
  },
  {
    postalCode: '12169',
    city: 'Berlin'
  },
  {
    postalCode: '12203',
    city: 'Berlin'
  },
  {
    postalCode: '12205',
    city: 'Berlin'
  },
  {
    postalCode: '12207',
    city: 'Berlin'
  },
  {
    postalCode: '12209',
    city: 'Berlin'
  },
  {
    postalCode: '12247',
    city: 'Berlin'
  },
  {
    postalCode: '12249',
    city: 'Berlin'
  },
  {
    postalCode: '12277',
    city: 'Berlin'
  },
  {
    postalCode: '12279',
    city: 'Berlin'
  },
  {
    postalCode: '12305',
    city: 'Berlin'
  },
  {
    postalCode: '12307',
    city: 'Berlin'
  },
  {
    postalCode: '12309',
    city: 'Berlin'
  },
  {
    postalCode: '12347',
    city: 'Berlin'
  },
  {
    postalCode: '12349',
    city: 'Berlin'
  },
  {
    postalCode: '12351',
    city: 'Berlin'
  },
  {
    postalCode: '12353',
    city: 'Berlin'
  },
  {
    postalCode: '12355',
    city: 'Berlin'
  },
  {
    postalCode: '12357',
    city: 'Berlin'
  },
  {
    postalCode: '12359',
    city: 'Berlin'
  },
  {
    postalCode: '12435',
    city: 'Berlin'
  },
  {
    postalCode: '12437',
    city: 'Berlin'
  },
  {
    postalCode: '12439',
    city: 'Berlin'
  },
  {
    postalCode: '12459',
    city: 'Berlin'
  },
  {
    postalCode: '12487',
    city: 'Berlin'
  },
  {
    postalCode: '12489',
    city: 'Berlin'
  },
  {
    postalCode: '12524',
    city: 'Berlin'
  },
  {
    postalCode: '12526',
    city: 'Berlin'
  },
  {
    postalCode: '12527',
    city: 'Berlin'
  },
  {
    postalCode: '12529',
    city: 'Schönefeld'
  },
  {
    postalCode: '12555',
    city: 'Berlin'
  },
  {
    postalCode: '12557',
    city: 'Berlin'
  },
  {
    postalCode: '12559',
    city: 'Berlin'
  },
  {
    postalCode: '12587',
    city: 'Berlin'
  },
  {
    postalCode: '12589',
    city: 'Berlin'
  },
  {
    postalCode: '12619',
    city: 'Berlin'
  },
  {
    postalCode: '12621',
    city: 'Berlin'
  },
  {
    postalCode: '12623',
    city: 'Berlin'
  },
  {
    postalCode: '12625',
    city: 'Waldesruh'
  },
  {
    postalCode: '12627',
    city: 'Berlin'
  },
  {
    postalCode: '12629',
    city: 'Berlin'
  },
  {
    postalCode: '12679',
    city: 'Berlin'
  },
  {
    postalCode: '12681',
    city: 'Berlin'
  },
  {
    postalCode: '12683',
    city: 'Berlin'
  },
  {
    postalCode: '12685',
    city: 'Berlin'
  },
  {
    postalCode: '12687',
    city: 'Berlin'
  },
  {
    postalCode: '12689',
    city: 'Berlin'
  },
  {
    postalCode: '13051',
    city: 'Berlin'
  },
  {
    postalCode: '13053',
    city: 'Berlin'
  },
  {
    postalCode: '13055',
    city: 'Berlin'
  },
  {
    postalCode: '13057',
    city: 'Berlin'
  },
  {
    postalCode: '13059',
    city: 'Berlin'
  },
  {
    postalCode: '13086',
    city: 'Berlin'
  },
  {
    postalCode: '13088',
    city: 'Berlin'
  },
  {
    postalCode: '13089',
    city: 'Berlin'
  },
  {
    postalCode: '13125',
    city: 'Berlin'
  },
  {
    postalCode: '13127',
    city: 'Berlin'
  },
  {
    postalCode: '13129',
    city: 'Berlin'
  },
  {
    postalCode: '13156',
    city: 'Berlin'
  },
  {
    postalCode: '13158',
    city: 'Berlin'
  },
  {
    postalCode: '13159',
    city: 'Berlin'
  },
  {
    postalCode: '13187',
    city: 'Berlin'
  },
  {
    postalCode: '13189',
    city: 'Berlin'
  },
  {
    postalCode: '13347',
    city: 'Berlin'
  },
  {
    postalCode: '13349',
    city: 'Berlin'
  },
  {
    postalCode: '13351',
    city: 'Berlin'
  },
  {
    postalCode: '13353',
    city: 'Berlin'
  },
  {
    postalCode: '13355',
    city: 'Berlin'
  },
  {
    postalCode: '13357',
    city: 'Berlin'
  },
  {
    postalCode: '13359',
    city: 'Berlin'
  },
  {
    postalCode: '13403',
    city: 'Berlin'
  },
  {
    postalCode: '13405',
    city: 'Berlin'
  },
  {
    postalCode: '13407',
    city: 'Berlin'
  },
  {
    postalCode: '13409',
    city: 'Berlin'
  },
  {
    postalCode: '13435',
    city: 'Berlin'
  },
  {
    postalCode: '13437',
    city: 'Berlin'
  },
  {
    postalCode: '13439',
    city: 'Berlin'
  },
  {
    postalCode: '13465',
    city: 'Berlin'
  },
  {
    postalCode: '13467',
    city: 'Berlin'
  },
  {
    postalCode: '13469',
    city: 'Berlin'
  },
  {
    postalCode: '13503',
    city: 'Berlin'
  },
  {
    postalCode: '13505',
    city: 'Berlin'
  },
  {
    postalCode: '13507',
    city: 'Berlin'
  },
  {
    postalCode: '13509',
    city: 'Berlin'
  },
  {
    postalCode: '13581',
    city: 'Berlin'
  },
  {
    postalCode: '13583',
    city: 'Berlin'
  },
  {
    postalCode: '13585',
    city: 'Berlin'
  },
  {
    postalCode: '13587',
    city: 'Berlin'
  },
  {
    postalCode: '13589',
    city: 'Berlin'
  },
  {
    postalCode: '13591',
    city: 'Berlin'
  },
  {
    postalCode: '13593',
    city: 'Berlin'
  },
  {
    postalCode: '13595',
    city: 'Berlin'
  },
  {
    postalCode: '13597',
    city: 'Berlin'
  },
  {
    postalCode: '13599',
    city: 'Berlin'
  },
  {
    postalCode: '13627',
    city: 'Berlin'
  },
  {
    postalCode: '13629',
    city: 'Berlin'
  },
  {
    postalCode: '14050',
    city: 'Berlin'
  },
  {
    postalCode: '14052',
    city: 'Berlin'
  },
  {
    postalCode: '14053',
    city: 'Berlin'
  },
  {
    postalCode: '14055',
    city: 'Berlin'
  },
  {
    postalCode: '14057',
    city: 'Berlin'
  },
  {
    postalCode: '14059',
    city: 'Berlin'
  },
  {
    postalCode: '14089',
    city: 'Berlin'
  },
  {
    postalCode: '14109',
    city: 'Berlin'
  },
  {
    postalCode: '14129',
    city: 'Berlin'
  },
  {
    postalCode: '14163',
    city: 'Berlin'
  },
  {
    postalCode: '14165',
    city: 'Berlin'
  },
  {
    postalCode: '14167',
    city: 'Berlin'
  },
  {
    postalCode: '14169',
    city: 'Berlin'
  },
  {
    postalCode: '14193',
    city: 'Berlin'
  },
  {
    postalCode: '14195',
    city: 'Berlin'
  },
  {
    postalCode: '14197',
    city: 'Berlin'
  },
  {
    postalCode: '14199',
    city: 'Berlin'
  },
  {
    postalCode: '14467',
    city: 'Potsdam'
  },
  {
    postalCode: '14469',
    city: 'Potsdam'
  },
  {
    postalCode: '14471',
    city: 'Potsdam'
  },
  {
    postalCode: '14473',
    city: 'Potsdam'
  },
  {
    postalCode: '14476',
    city: 'Fahrland'
  },
  {
    postalCode: '14476',
    city: 'Golm'
  },
  {
    postalCode: '14476',
    city: 'Göttin'
  },
  {
    postalCode: '14476',
    city: 'Groß Glienicke'
  },
  {
    postalCode: '14476',
    city: 'Kartzow'
  },
  {
    postalCode: '14476',
    city: 'Krampnitz'
  },
  {
    postalCode: '14476',
    city: 'Leest'
  },
  {
    postalCode: '14476',
    city: 'Marquardt'
  },
  {
    postalCode: '14476',
    city: 'Neu Fahrland'
  },
  {
    postalCode: '14476',
    city: 'Paaren'
  },
  {
    postalCode: '14476',
    city: 'Satzkorn'
  },
  {
    postalCode: '14476',
    city: 'Seeburg'
  },
  {
    postalCode: '14476',
    city: 'Töplitz'
  },
  {
    postalCode: '14476',
    city: 'Uetz'
  },
  {
    postalCode: '14478',
    city: 'Potsdam'
  },
  {
    postalCode: '14480',
    city: 'Potsdam'
  },
  {
    postalCode: '14482',
    city: 'Potsdam'
  },
  {
    postalCode: '14513',
    city: 'Teltow'
  },
  {
    postalCode: '14532',
    city: 'Fahlhorst'
  },
  {
    postalCode: '14532',
    city: 'Güterfelde'
  },
  {
    postalCode: '14532',
    city: 'Kienwerder'
  },
  {
    postalCode: '14532',
    city: 'Kleinmachnow'
  },
  {
    postalCode: '14532',
    city: 'Marggraffshof'
  },
  {
    postalCode: '14532',
    city: 'Nudow'
  },
  {
    postalCode: '14532',
    city: 'Philippsthal'
  },
  {
    postalCode: '14532',
    city: 'Schenkenhorst'
  },
  {
    postalCode: '14532',
    city: 'Sputendorf'
  },
  {
    postalCode: '14532',
    city: 'Stahnsdorf'
  },
  {
    postalCode: '14542',
    city: 'Bliesendorf'
  },
  {
    postalCode: '14542',
    city: 'Elisabethhöhe'
  },
  {
    postalCode: '14542',
    city: 'Geltow'
  },
  {
    postalCode: '14542',
    city: 'Glindow'
  },
  {
    postalCode: '14542',
    city: 'Göhlsdorf'
  },
  {
    postalCode: '14542',
    city: 'Kemnitz'
  },
  {
    postalCode: '14542',
    city: 'Neu Plötzin'
  },
  {
    postalCode: '14542',
    city: 'Petzow'
  },
  {
    postalCode: '14542',
    city: 'Phöben'
  },
  {
    postalCode: '14542',
    city: 'Plessow'
  },
  {
    postalCode: '14542',
    city: 'Plötzin'
  },
  {
    postalCode: '14542',
    city: 'Werder'
  },
  {
    postalCode: '14542',
    city: 'Wildpark West'
  },
  {
    postalCode: '14547',
    city: 'Beelitz'
  },
  {
    postalCode: '14547',
    city: 'Birkhorst'
  },
  {
    postalCode: '14547',
    city: 'Buchholz'
  },
  {
    postalCode: '14547',
    city: 'Busendorf'
  },
  {
    postalCode: '14547',
    city: 'Elsholz'
  },
  {
    postalCode: '14547',
    city: 'Fichtenwalde'
  },
  {
    postalCode: '14547',
    city: 'Körzin'
  },
  {
    postalCode: '14547',
    city: 'Reesdorf'
  },
  {
    postalCode: '14547',
    city: 'Rieben'
  },
  {
    postalCode: '14547',
    city: 'Salzbrunn'
  },
  {
    postalCode: '14547',
    city: 'Schäpe'
  },
  {
    postalCode: '14547',
    city: 'Schlunkendorf'
  },
  {
    postalCode: '14547',
    city: 'Schönefeld'
  },
  {
    postalCode: '14547',
    city: 'Stücken'
  },
  {
    postalCode: '14547',
    city: 'Wittbrietzen'
  },
  {
    postalCode: '14547',
    city: 'Zauchwitz'
  },
  {
    postalCode: '14548',
    city: 'Caputh'
  },
  {
    postalCode: '14548',
    city: 'Ferch'
  },
  {
    postalCode: '14550',
    city: 'Bochow'
  },
  {
    postalCode: '14550',
    city: 'Deetz'
  },
  {
    postalCode: '14550',
    city: 'Derwitz'
  },
  {
    postalCode: '14550',
    city: 'Groß Kreutz'
  },
  {
    postalCode: '14550',
    city: 'Krielow'
  },
  {
    postalCode: '14550',
    city: 'Neu Bochow'
  },
  {
    postalCode: '14550',
    city: 'Schmergow'
  },
  {
    postalCode: '14552',
    city: 'Fresdorf'
  },
  {
    postalCode: '14552',
    city: 'Langerwisch Süd'
  },
  {
    postalCode: '14552',
    city: 'Michendorf'
  },
  {
    postalCode: '14552',
    city: 'Saarmund'
  },
  {
    postalCode: '14552',
    city: 'Siedlung Bergheide'
  },
  {
    postalCode: '14552',
    city: 'Tremsdorf'
  },
  {
    postalCode: '14552',
    city: 'Wildenbruch'
  },
  {
    postalCode: '14554',
    city: 'Seddiner See'
  },
  {
    postalCode: '14557',
    city: 'Langerwisch'
  },
  {
    postalCode: '14557',
    city: 'Wilhelmshorst'
  },
  {
    postalCode: '14558',
    city: 'Bergholz-Rehbrücke'
  },
  {
    postalCode: '14612',
    city: 'Falkensee'
  },
  {
    postalCode: '14612',
    city: 'Waldheim'
  },
  {
    postalCode: '14621',
    city: 'Schönwalde'
  },
  {
    postalCode: '14624',
    city: 'Dallgow'
  },
  {
    postalCode: '14624',
    city: 'Rohrbeck'
  },
  {
    postalCode: '14627',
    city: 'Elstal'
  },
  {
    postalCode: '14641',
    city: 'Berge'
  },
  {
    postalCode: '14641',
    city: 'Bergerdamm'
  },
  {
    postalCode: '14641',
    city: 'Börnicke'
  },
  {
    postalCode: '14641',
    city: 'Brädikow'
  },
  {
    postalCode: '14641',
    city: 'Bredow'
  },
  {
    postalCode: '14641',
    city: 'Buchow-Karpzow'
  },
  {
    postalCode: '14641',
    city: 'Dyrotz'
  },
  {
    postalCode: '14641',
    city: 'Ebereschenhof'
  },
  {
    postalCode: '14641',
    city: 'Etzin'
  },
  {
    postalCode: '14641',
    city: 'Falkenrehde'
  },
  {
    postalCode: '14641',
    city: 'Gohlitz'
  },
  {
    postalCode: '14641',
    city: 'Groß Behnitz'
  },
  {
    postalCode: '14641',
    city: 'Grünefeld'
  },
  {
    postalCode: '14641',
    city: 'Hanffabrik'
  },
  {
    postalCode: '14641',
    city: 'Hertefeld'
  },
  {
    postalCode: '14641',
    city: 'Hoppenrade'
  },
  {
    postalCode: '14641',
    city: 'Kienberg'
  },
  {
    postalCode: '14641',
    city: 'Klein Behnitz'
  },
  {
    postalCode: '14641',
    city: 'Lietzow'
  },
  {
    postalCode: '14641',
    city: 'Markau'
  },
  {
    postalCode: '14641',
    city: 'Markee'
  },
  {
    postalCode: '14641',
    city: 'Nauen'
  },
  {
    postalCode: '14641',
    city: 'Neu Falkenrehde'
  },
  {
    postalCode: '14641',
    city: 'Niebede'
  },
  {
    postalCode: '14641',
    city: 'Paaren im Glien'
  },
  {
    postalCode: '14641',
    city: 'Paulinenaue'
  },
  {
    postalCode: '14641',
    city: 'Pausin'
  },
  {
    postalCode: '14641',
    city: 'Perwenitz'
  },
  {
    postalCode: '14641',
    city: 'Pessin'
  },
  {
    postalCode: '14641',
    city: 'Priort'
  },
  {
    postalCode: '14641',
    city: 'Quermathen'
  },
  {
    postalCode: '14641',
    city: 'Retzow'
  },
  {
    postalCode: '14641',
    city: 'Ribbeck'
  },
  {
    postalCode: '14641',
    city: 'Selbelang'
  },
  {
    postalCode: '14641',
    city: 'Teufelshof'
  },
  {
    postalCode: '14641',
    city: 'Tietzow'
  },
  {
    postalCode: '14641',
    city: 'Tremmen'
  },
  {
    postalCode: '14641',
    city: 'Utershorst'
  },
  {
    postalCode: '14641',
    city: 'Wachow'
  },
  {
    postalCode: '14641',
    city: 'Wagenitz'
  },
  {
    postalCode: '14641',
    city: 'Wansdorf'
  },
  {
    postalCode: '14641',
    city: 'Wernitz'
  },
  {
    postalCode: '14641',
    city: 'Wustermark'
  },
  {
    postalCode: '14641',
    city: 'Zeestow'
  },
  {
    postalCode: '14656',
    city: 'Alt Brieselang'
  },
  {
    postalCode: '14656',
    city: 'Brieselang'
  },
  {
    postalCode: '14662',
    city: 'Friesack'
  },
  {
    postalCode: '14662',
    city: 'Haage'
  },
  {
    postalCode: '14662',
    city: 'Klessener Zootzen'
  },
  {
    postalCode: '14662',
    city: 'Senzke'
  },
  {
    postalCode: '14662',
    city: 'Vietznitz'
  },
  {
    postalCode: '14662',
    city: 'Warsow'
  },
  {
    postalCode: '14662',
    city: 'Wutzetz'
  },
  {
    postalCode: '14662',
    city: 'Zootzen Damm'
  },
  {
    postalCode: '14669',
    city: 'Gutenpaaren'
  },
  {
    postalCode: '14669',
    city: 'Ketzin'
  },
  {
    postalCode: '14669',
    city: 'Paretz'
  },
  {
    postalCode: '14669',
    city: 'Zachow'
  },
  {
    postalCode: '14712',
    city: 'Grünaue'
  },
  {
    postalCode: '14712',
    city: 'Rathenow'
  },
  {
    postalCode: '14715',
    city: 'Bahnitz'
  },
  {
    postalCode: '14715',
    city: 'Bamme'
  },
  {
    postalCode: '14715',
    city: 'Barnewitz'
  },
  {
    postalCode: '14715',
    city: 'Buckow bei Großwudicke'
  },
  {
    postalCode: '14715',
    city: 'Buckow bei Nennhausen'
  },
  {
    postalCode: '14715',
    city: 'Bünsche'
  },
  {
    postalCode: '14715',
    city: 'Buschow'
  },
  {
    postalCode: '14715',
    city: 'Bützer'
  },
  {
    postalCode: '14715',
    city: 'Damme'
  },
  {
    postalCode: '14715',
    city: 'Elslaake'
  },
  {
    postalCode: '14715',
    city: 'Ferchels'
  },
  {
    postalCode: '14715',
    city: 'Ferchesar'
  },
  {
    postalCode: '14715',
    city: 'Galm'
  },
  {
    postalCode: '14715',
    city: 'Garlitz'
  },
  {
    postalCode: '14715',
    city: 'Glien'
  },
  {
    postalCode: '14715',
    city: 'Gräningen'
  },
  {
    postalCode: '14715',
    city: 'Großwudicke'
  },
  {
    postalCode: '14715',
    city: 'Gülpe'
  },
  {
    postalCode: '14715',
    city: 'Hohennauen'
  },
  {
    postalCode: '14715',
    city: 'Hohenwinkel'
  },
  {
    postalCode: '14715',
    city: 'Jerchel'
  },
  {
    postalCode: '14715',
    city: 'Knoblauch'
  },
  {
    postalCode: '14715',
    city: 'Kohlhof'
  },
  {
    postalCode: '14715',
    city: 'Königshütte'
  },
  {
    postalCode: '14715',
    city: 'Kotzen'
  },
  {
    postalCode: '14715',
    city: 'Kriele'
  },
  {
    postalCode: '14715',
    city: 'Landin'
  },
  {
    postalCode: '14715',
    city: 'Liepe'
  },
  {
    postalCode: '14715',
    city: 'Mahlitz'
  },
  {
    postalCode: '14715',
    city: 'Marquede'
  },
  {
    postalCode: '14715',
    city: 'Milow'
  },
  {
    postalCode: '14715',
    city: 'Mögelin'
  },
  {
    postalCode: '14715',
    city: 'Molkenberg'
  },
  {
    postalCode: '14715',
    city: 'Möthlitz'
  },
  {
    postalCode: '14715',
    city: 'Möthlow'
  },
  {
    postalCode: '14715',
    city: 'Mützlitz'
  },
  {
    postalCode: '14715',
    city: 'Nennhausen'
  },
  {
    postalCode: '14715',
    city: 'Neu Dessau'
  },
  {
    postalCode: '14715',
    city: 'Neumolkenberg'
  },
  {
    postalCode: '14715',
    city: 'Neuschollene'
  },
  {
    postalCode: '14715',
    city: 'Neuwartensleben'
  },
  {
    postalCode: '14715',
    city: 'Nierow'
  },
  {
    postalCode: '14715',
    city: 'Nitzahn'
  },
  {
    postalCode: '14715',
    city: 'Parey'
  },
  {
    postalCode: '14715',
    city: 'Prietzen'
  },
  {
    postalCode: '14715',
    city: 'Schleuse Grütz'
  },
  {
    postalCode: '14715',
    city: 'Schmetzdorf'
  },
  {
    postalCode: '14715',
    city: 'Schollene'
  },
  {
    postalCode: '14715',
    city: 'Spaatz'
  },
  {
    postalCode: '14715',
    city: 'Stechow'
  },
  {
    postalCode: '14715',
    city: 'Vieritz'
  },
  {
    postalCode: '14715',
    city: 'Wassersuppe'
  },
  {
    postalCode: '14715',
    city: 'Wendeberg'
  },
  {
    postalCode: '14715',
    city: 'Witzke'
  },
  {
    postalCode: '14715',
    city: 'Wolsier'
  },
  {
    postalCode: '14715',
    city: 'Zollchow'
  },
  {
    postalCode: '14727',
    city: 'Döberitz'
  },
  {
    postalCode: '14727',
    city: 'Gapel'
  },
  {
    postalCode: '14727',
    city: 'Premnitz'
  },
  {
    postalCode: '14728',
    city: 'Dickte'
  },
  {
    postalCode: '14728',
    city: 'Görne'
  },
  {
    postalCode: '14728',
    city: 'Kietz'
  },
  {
    postalCode: '14728',
    city: 'Kleßen'
  },
  {
    postalCode: '14728',
    city: 'Neuwerder'
  },
  {
    postalCode: '14728',
    city: 'Ohnewitz'
  },
  {
    postalCode: '14728',
    city: 'Rhinow'
  },
  {
    postalCode: '14728',
    city: 'Schönholz'
  },
  {
    postalCode: '14728',
    city: 'Stölln'
  },
  {
    postalCode: '14728',
    city: 'Strodehne'
  },
  {
    postalCode: '14770',
    city: 'Brandenburg'
  },
  {
    postalCode: '14772',
    city: 'Brandenburg'
  },
  {
    postalCode: '14774',
    city: 'Brandenburg'
  },
  {
    postalCode: '14776',
    city: 'Brandenburg'
  },
  {
    postalCode: '14776',
    city: 'Paterdamm'
  },
  {
    postalCode: '14776',
    city: 'Rotscherlinde'
  },
  {
    postalCode: '14778',
    city: 'Bagow'
  },
  {
    postalCode: '14778',
    city: 'Böcke'
  },
  {
    postalCode: '14778',
    city: 'Bollmannsruh'
  },
  {
    postalCode: '14778',
    city: 'Brielow'
  },
  {
    postalCode: '14778',
    city: 'Briest'
  },
  {
    postalCode: '14778',
    city: 'Brückermark'
  },
  {
    postalCode: '14778',
    city: 'Butzow'
  },
  {
    postalCode: '14778',
    city: 'Gollwitz'
  },
  {
    postalCode: '14778',
    city: 'Golzow'
  },
  {
    postalCode: '14778',
    city: 'Gortz'
  },
  {
    postalCode: '14778',
    city: 'Götz'
  },
  {
    postalCode: '14778',
    city: 'Grabow'
  },
  {
    postalCode: '14778',
    city: 'Grüneiche'
  },
  {
    postalCode: '14778',
    city: 'Grüningen'
  },
  {
    postalCode: '14778',
    city: 'Jeserig'
  },
  {
    postalCode: '14778',
    city: 'Ketzür'
  },
  {
    postalCode: '14778',
    city: 'Klein Kreutz'
  },
  {
    postalCode: '14778',
    city: 'Krahne'
  },
  {
    postalCode: '14778',
    city: 'Lucksfleiß'
  },
  {
    postalCode: '14778',
    city: 'Lünow'
  },
  {
    postalCode: '14778',
    city: 'Marzahne'
  },
  {
    postalCode: '14778',
    city: 'Meßdunk'
  },
  {
    postalCode: '14778',
    city: 'Mötzow'
  },
  {
    postalCode: '14778',
    city: 'Oberjünne'
  },
  {
    postalCode: '14778',
    city: 'Päwesin'
  },
  {
    postalCode: '14778',
    city: 'Pernitz'
  },
  {
    postalCode: '14778',
    city: 'Radewege'
  },
  {
    postalCode: '14778',
    city: 'Reckahn'
  },
  {
    postalCode: '14778',
    city: 'Riewend'
  },
  {
    postalCode: '14778',
    city: 'Roskow'
  },
  {
    postalCode: '14778',
    city: 'Saaringen'
  },
  {
    postalCode: '14778',
    city: 'Schenkenberg'
  },
  {
    postalCode: '14778',
    city: 'Trechwitz'
  },
  {
    postalCode: '14778',
    city: 'Wenzlow'
  },
  {
    postalCode: '14778',
    city: 'Weseram'
  },
  {
    postalCode: '14778',
    city: 'Wollin'
  },
  {
    postalCode: '14778',
    city: 'Wust'
  },
  {
    postalCode: '14789',
    city: 'Bensdorf'
  },
  {
    postalCode: '14789',
    city: 'Herrenhölzer'
  },
  {
    postalCode: '14789',
    city: 'Mahlenzien'
  },
  {
    postalCode: '14789',
    city: 'Neubensdorf'
  },
  {
    postalCode: '14789',
    city: 'Rosenau'
  },
  {
    postalCode: '14789',
    city: 'Vehlen'
  },
  {
    postalCode: '14789',
    city: 'Woltersdorf'
  },
  {
    postalCode: '14789',
    city: 'Wusterwitz'
  },
  {
    postalCode: '14793',
    city: 'Buckau'
  },
  {
    postalCode: '14793',
    city: 'Bücknitz'
  },
  {
    postalCode: '14793',
    city: 'Dahlen'
  },
  {
    postalCode: '14793',
    city: 'Dretzen'
  },
  {
    postalCode: '14793',
    city: 'Glienecke'
  },
  {
    postalCode: '14793',
    city: 'Gräben'
  },
  {
    postalCode: '14793',
    city: 'Grebs'
  },
  {
    postalCode: '14793',
    city: 'Köpernitz'
  },
  {
    postalCode: '14793',
    city: 'Pramsdorf'
  },
  {
    postalCode: '14793',
    city: 'Rottstock'
  },
  {
    postalCode: '14793',
    city: 'Steinberg'
  },
  {
    postalCode: '14793',
    city: 'Ziesar'
  },
  {
    postalCode: '14797',
    city: 'Damsdorf'
  },
  {
    postalCode: '14797',
    city: 'Emstal'
  },
  {
    postalCode: '14797',
    city: 'Grebs'
  },
  {
    postalCode: '14797',
    city: 'Lehnin'
  },
  {
    postalCode: '14797',
    city: 'Michelsdorf'
  },
  {
    postalCode: '14797',
    city: 'Nahmitz'
  },
  {
    postalCode: '14797',
    city: 'Netzen'
  },
  {
    postalCode: '14797',
    city: 'Prützke'
  },
  {
    postalCode: '14797',
    city: 'Rädel'
  },
  {
    postalCode: '14797',
    city: 'Rietz'
  },
  {
    postalCode: '14798',
    city: 'Fohrde'
  },
  {
    postalCode: '14798',
    city: 'Hohenferchesar'
  },
  {
    postalCode: '14798',
    city: 'Krahnepuhl'
  },
  {
    postalCode: '14798',
    city: 'Kützkow'
  },
  {
    postalCode: '14798',
    city: 'Pritzerbe'
  },
  {
    postalCode: '14798',
    city: 'Seelensdorf'
  },
  {
    postalCode: '14798',
    city: 'Tieckow'
  },
  {
    postalCode: '14806',
    city: 'Baitz'
  },
  {
    postalCode: '14806',
    city: 'Belzig'
  },
  {
    postalCode: '14806',
    city: 'Benken'
  },
  {
    postalCode: '14806',
    city: 'Bergholz'
  },
  {
    postalCode: '14806',
    city: 'Borne'
  },
  {
    postalCode: '14806',
    city: 'Dahnsdorf'
  },
  {
    postalCode: '14806',
    city: 'Dippmannsdorf'
  },
  {
    postalCode: '14806',
    city: 'Egelinde'
  },
  {
    postalCode: '14806',
    city: 'Fredersdorf'
  },
  {
    postalCode: '14806',
    city: 'Groß Briesen'
  },
  {
    postalCode: '14806',
    city: 'Hagelberg'
  },
  {
    postalCode: '14806',
    city: 'Hohenspringe'
  },
  {
    postalCode: '14806',
    city: 'Klein Briesen'
  },
  {
    postalCode: '14806',
    city: 'Kranepuhl'
  },
  {
    postalCode: '14806',
    city: 'Kuhlowitz'
  },
  {
    postalCode: '14806',
    city: 'Locktow'
  },
  {
    postalCode: '14806',
    city: 'Lübnitz'
  },
  {
    postalCode: '14806',
    city: 'Lüsse'
  },
  {
    postalCode: '14806',
    city: 'Lütte'
  },
  {
    postalCode: '14806',
    city: 'Mörz'
  },
  {
    postalCode: '14806',
    city: 'Neschholz'
  },
  {
    postalCode: '14806',
    city: 'Preußnitz'
  },
  {
    postalCode: '14806',
    city: 'Ragösen'
  },
  {
    postalCode: '14806',
    city: 'Rothebach'
  },
  {
    postalCode: '14806',
    city: 'Schwanebeck'
  },
  {
    postalCode: '14806',
    city: 'Verlorenwasser'
  },
  {
    postalCode: '14806',
    city: 'Weitzgrund'
  },
  {
    postalCode: '14806',
    city: 'Werbig'
  },
  {
    postalCode: '14806',
    city: 'Ziezow'
  },
  {
    postalCode: '14822',
    city: 'Alt Bork'
  },
  {
    postalCode: '14822',
    city: 'Borkheide'
  },
  {
    postalCode: '14822',
    city: 'Borkwalde'
  },
  {
    postalCode: '14822',
    city: 'Brachwitz'
  },
  {
    postalCode: '14822',
    city: 'Brück'
  },
  {
    postalCode: '14822',
    city: 'Cammer'
  },
  {
    postalCode: '14822',
    city: 'Damelang'
  },
  {
    postalCode: '14822',
    city: 'Deutsch Bork'
  },
  {
    postalCode: '14822',
    city: 'Freienthal'
  },
  {
    postalCode: '14822',
    city: 'Gömnigk'
  },
  {
    postalCode: '14822',
    city: 'Jeserig'
  },
  {
    postalCode: '14822',
    city: 'Linthe'
  },
  {
    postalCode: '14822',
    city: 'Neuendorf bei Brück'
  },
  {
    postalCode: '14822',
    city: 'Nichel'
  },
  {
    postalCode: '14822',
    city: 'Niederwerbig'
  },
  {
    postalCode: '14822',
    city: 'Schlalach'
  },
  {
    postalCode: '14822',
    city: 'Trebitz'
  },
  {
    postalCode: '14823',
    city: 'Buchholz'
  },
  {
    postalCode: '14823',
    city: 'Garrey'
  },
  {
    postalCode: '14823',
    city: 'Grabow'
  },
  {
    postalCode: '14823',
    city: 'Groß Marzehns'
  },
  {
    postalCode: '14823',
    city: 'Grubo'
  },
  {
    postalCode: '14823',
    city: 'Haseloff'
  },
  {
    postalCode: '14823',
    city: 'Hohenwerbig'
  },
  {
    postalCode: '14823',
    city: 'Klein Marzehns'
  },
  {
    postalCode: '14823',
    city: 'Klepzig'
  },
  {
    postalCode: '14823',
    city: 'Lehnsdorf'
  },
  {
    postalCode: '14823',
    city: 'Lühnsdorf'
  },
  {
    postalCode: '14823',
    city: 'Mützdorf'
  },
  {
    postalCode: '14823',
    city: 'Neuendorf bei Niemegk'
  },
  {
    postalCode: '14823',
    city: 'Niemegk'
  },
  {
    postalCode: '14823',
    city: 'Raben'
  },
  {
    postalCode: '14823',
    city: 'Rädigke'
  },
  {
    postalCode: '14823',
    city: 'Welsigke'
  },
  {
    postalCode: '14823',
    city: 'Zixdorf'
  },
  {
    postalCode: '14827',
    city: 'Arensnest'
  },
  {
    postalCode: '14827',
    city: 'Glashütte'
  },
  {
    postalCode: '14827',
    city: 'Jeserig'
  },
  {
    postalCode: '14827',
    city: 'Jeserigerhütten'
  },
  {
    postalCode: '14827',
    city: 'Mahlsdorf'
  },
  {
    postalCode: '14827',
    city: 'Medewitz'
  },
  {
    postalCode: '14827',
    city: 'Medewitzerhütten'
  },
  {
    postalCode: '14827',
    city: 'Neuehütten'
  },
  {
    postalCode: '14827',
    city: 'Reetz'
  },
  {
    postalCode: '14827',
    city: 'Reetzerhütten'
  },
  {
    postalCode: '14827',
    city: 'Schlamau'
  },
  {
    postalCode: '14827',
    city: 'Schmerwitz'
  },
  {
    postalCode: '14827',
    city: 'Setzsteig'
  },
  {
    postalCode: '14827',
    city: 'Spring'
  },
  {
    postalCode: '14827',
    city: 'Wiesenburg'
  },
  {
    postalCode: '14828',
    city: 'Börnecke'
  },
  {
    postalCode: '14828',
    city: 'Dangelsdorf'
  },
  {
    postalCode: '14828',
    city: 'Görzke'
  },
  {
    postalCode: '14828',
    city: 'Hohenlobbese'
  },
  {
    postalCode: '14828',
    city: 'Reppinichen'
  },
  {
    postalCode: '14828',
    city: 'Wutzow'
  },
  {
    postalCode: '14913',
    city: 'Altes Lager'
  },
  {
    postalCode: '14913',
    city: 'Bardenitz'
  },
  {
    postalCode: '14913',
    city: 'Bärwalde'
  },
  {
    postalCode: '14913',
    city: 'Blönsdorf'
  },
  {
    postalCode: '14913',
    city: 'Bochow'
  },
  {
    postalCode: '14913',
    city: 'Borgisdorf'
  },
  {
    postalCode: '14913',
    city: 'Buckow'
  },
  {
    postalCode: '14913',
    city: 'Dalichow'
  },
  {
    postalCode: '14913',
    city: 'Danna'
  },
  {
    postalCode: '14913',
    city: 'Dennewitz'
  },
  {
    postalCode: '14913',
    city: 'Dietersdorf'
  },
  {
    postalCode: '14913',
    city: 'Eckmannsdorf'
  },
  {
    postalCode: '14913',
    city: 'Feldheim'
  },
  {
    postalCode: '14913',
    city: 'Fröhden'
  },
  {
    postalCode: '14913',
    city: 'Gölsdorf'
  },
  {
    postalCode: '14913',
    city: 'Gräfendorf'
  },
  {
    postalCode: '14913',
    city: 'Grüna'
  },
  {
    postalCode: '14913',
    city: 'Heinsdorf'
  },
  {
    postalCode: '14913',
    city: 'Herbersdorf'
  },
  {
    postalCode: '14913',
    city: 'Höfgen'
  },
  {
    postalCode: '14913',
    city: 'Hohenahlsdorf'
  },
  {
    postalCode: '14913',
    city: 'Hohengörsdorf'
  },
  {
    postalCode: '14913',
    city: 'Hohenseefeld'
  },
  {
    postalCode: '14913',
    city: 'Ihlow'
  },
  {
    postalCode: '14913',
    city: 'Illmersdorf'
  },
  {
    postalCode: '14913',
    city: 'Jüterbog'
  },
  {
    postalCode: '14913',
    city: 'Kaltenborn'
  },
  {
    postalCode: '14913',
    city: 'Kloster Zinna'
  },
  {
    postalCode: '14913',
    city: 'Körbitz'
  },
  {
    postalCode: '14913',
    city: 'Kossin'
  },
  {
    postalCode: '14913',
    city: 'Kurzlipsdorf'
  },
  {
    postalCode: '14913',
    city: 'Langenlipsdorf'
  },
  {
    postalCode: '14913',
    city: 'Lichterfelde'
  },
  {
    postalCode: '14913',
    city: 'Liepe'
  },
  {
    postalCode: '14913',
    city: 'Ließen'
  },
  {
    postalCode: '14913',
    city: 'Lindow'
  },
  {
    postalCode: '14913',
    city: 'Lobbese'
  },
  {
    postalCode: '14913',
    city: 'Lüdendorf'
  },
  {
    postalCode: '14913',
    city: 'Malterhausen'
  },
  {
    postalCode: '14913',
    city: 'Markendorf'
  },
  {
    postalCode: '14913',
    city: 'Marzahna'
  },
  {
    postalCode: '14913',
    city: 'Meinsdorf'
  },
  {
    postalCode: '14913',
    city: 'Mellnsdorf'
  },
  {
    postalCode: '14913',
    city: 'Merzdorf'
  },
  {
    postalCode: '14913',
    city: 'Neuheim'
  },
  {
    postalCode: '14913',
    city: 'Neuhof bei Zinna'
  },
  {
    postalCode: '14913',
    city: 'Niebendorf'
  },
  {
    postalCode: '14913',
    city: 'Niedergörsdorf'
  },
  {
    postalCode: '14913',
    city: 'Nonnendorf'
  },
  {
    postalCode: '14913',
    city: 'Oehna'
  },
  {
    postalCode: '14913',
    city: 'Pechüle'
  },
  {
    postalCode: '14913',
    city: 'Petkus'
  },
  {
    postalCode: '14913',
    city: 'Pflügkuff'
  },
  {
    postalCode: '14913',
    city: 'Reinsdorf'
  },
  {
    postalCode: '14913',
    city: 'Riesdorf'
  },
  {
    postalCode: '14913',
    city: 'Rinow'
  },
  {
    postalCode: '14913',
    city: 'Rohrbeck'
  },
  {
    postalCode: '14913',
    city: 'Schlenzer'
  },
  {
    postalCode: '14913',
    city: 'Schmögelsdorf'
  },
  {
    postalCode: '14913',
    city: 'Schönefeld'
  },
  {
    postalCode: '14913',
    city: 'Schwabeck'
  },
  {
    postalCode: '14913',
    city: 'Seehausen'
  },
  {
    postalCode: '14913',
    city: 'Sernow'
  },
  {
    postalCode: '14913',
    city: 'Wahlsdorf'
  },
  {
    postalCode: '14913',
    city: 'Waltersdorf'
  },
  {
    postalCode: '14913',
    city: 'Weidmannsruh'
  },
  {
    postalCode: '14913',
    city: 'Weißen'
  },
  {
    postalCode: '14913',
    city: 'Welsickendorf'
  },
  {
    postalCode: '14913',
    city: 'Werbig'
  },
  {
    postalCode: '14913',
    city: 'Werder'
  },
  {
    postalCode: '14913',
    city: 'Wergzahna'
  },
  {
    postalCode: '14913',
    city: 'Wiepersdorf'
  },
  {
    postalCode: '14913',
    city: 'Wölmsdorf'
  },
  {
    postalCode: '14913',
    city: 'Zellendorf'
  },
  {
    postalCode: '14913',
    city: 'Zeuden'
  },
  {
    postalCode: '14929',
    city: 'Frohnsdorf'
  },
  {
    postalCode: '14929',
    city: 'Klausdorf'
  },
  {
    postalCode: '14929',
    city: 'Niebel'
  },
  {
    postalCode: '14929',
    city: 'Niebelhorst'
  },
  {
    postalCode: '14929',
    city: 'Rietz'
  },
  {
    postalCode: '14929',
    city: 'Tiefenbrunnen'
  },
  {
    postalCode: '14929',
    city: 'Treuenbrietzen'
  },
  {
    postalCode: '14943',
    city: 'Luckenwalde'
  },
  {
    postalCode: '14943',
    city: 'Lüdersdorf'
  },
  {
    postalCode: '14943',
    city: 'Lühsdorf'
  },
  {
    postalCode: '14943',
    city: 'Wiesenhagen'
  },
  {
    postalCode: '14947',
    city: 'Nuthe-Urstromtal'
  },
  {
    postalCode: '14959',
    city: 'Blankensee'
  },
  {
    postalCode: '14959',
    city: 'Glau'
  },
  {
    postalCode: '14959',
    city: 'Klein Schulzendorf'
  },
  {
    postalCode: '14959',
    city: 'Kliestow'
  },
  {
    postalCode: '14959',
    city: 'Schönhagen'
  },
  {
    postalCode: '14959',
    city: 'Stangenhagen'
  },
  {
    postalCode: '14959',
    city: 'Trebbin'
  },
  {
    postalCode: '14974',
    city: 'Ahrensdorf'
  },
  {
    postalCode: '14974',
    city: 'Genshagen'
  },
  {
    postalCode: '14974',
    city: 'Gröben'
  },
  {
    postalCode: '14974',
    city: 'Großbeuthen'
  },
  {
    postalCode: '14974',
    city: 'Kerzendorf'
  },
  {
    postalCode: '14974',
    city: 'Löwenbruch'
  },
  {
    postalCode: '14974',
    city: 'Ludwigsfelde'
  },
  {
    postalCode: '14974',
    city: 'Märkisch Wilmersdorf'
  },
  {
    postalCode: '14974',
    city: 'Siethen'
  },
  {
    postalCode: '14974',
    city: 'Thyrow'
  },
  {
    postalCode: '14974',
    city: 'Wietstock'
  },
  {
    postalCode: '14979',
    city: 'Birkenhain'
  },
  {
    postalCode: '14979',
    city: 'Friderikenhof'
  },
  {
    postalCode: '14979',
    city: 'Großbeeren'
  },
  {
    postalCode: '14979',
    city: 'Heinersdorf'
  },
  {
    postalCode: '14979',
    city: 'Osdorf'
  },
  {
    postalCode: '15230',
    city: 'Frankfurt'
  },
  {
    postalCode: '15232',
    city: 'Frankfurt'
  },
  {
    postalCode: '15234',
    city: 'Frankfurt'
  },
  {
    postalCode: '15236',
    city: 'Biegen'
  },
  {
    postalCode: '15236',
    city: 'Frankfurt'
  },
  {
    postalCode: '15236',
    city: 'Jacobsdorf'
  },
  {
    postalCode: '15236',
    city: 'Petersdorf'
  },
  {
    postalCode: '15236',
    city: 'Pillgram'
  },
  {
    postalCode: '15236',
    city: 'Sieversdorf'
  },
  {
    postalCode: '15236',
    city: 'Treplin'
  },
  {
    postalCode: '15295',
    city: 'Brieskow-Finkenheerd'
  },
  {
    postalCode: '15295',
    city: 'Groß Lindow'
  },
  {
    postalCode: '15295',
    city: 'Wiesenau'
  },
  {
    postalCode: '15295',
    city: 'Ziltendorf'
  },
  {
    postalCode: '15299',
    city: 'Dammendorf'
  },
  {
    postalCode: '15299',
    city: 'Grunow'
  },
  {
    postalCode: '15299',
    city: 'Mixdorf'
  },
  {
    postalCode: '15299',
    city: 'Müllrose'
  },
  {
    postalCode: '15306',
    city: 'Alt Mahlisch'
  },
  {
    postalCode: '15306',
    city: 'Diedersdorf'
  },
  {
    postalCode: '15306',
    city: 'Dolgelin'
  },
  {
    postalCode: '15306',
    city: 'Falkenhagen'
  },
  {
    postalCode: '15306',
    city: 'Friedersdorf'
  },
  {
    postalCode: '15306',
    city: 'Gusow'
  },
  {
    postalCode: '15306',
    city: 'Libbenichen'
  },
  {
    postalCode: '15306',
    city: 'Lietzen'
  },
  {
    postalCode: '15306',
    city: 'Marxdorf'
  },
  {
    postalCode: '15306',
    city: 'Neu Mahlisch'
  },
  {
    postalCode: '15306',
    city: 'Niederjesar'
  },
  {
    postalCode: '15306',
    city: 'Platkow'
  },
  {
    postalCode: '15306',
    city: 'Sachsendorf'
  },
  {
    postalCode: '15306',
    city: 'Seelow'
  },
  {
    postalCode: '15306',
    city: 'Werbig'
  },
  {
    postalCode: '15306',
    city: 'Worin'
  },
  {
    postalCode: '15320',
    city: 'Altbarnim'
  },
  {
    postalCode: '15320',
    city: 'Altfriedland'
  },
  {
    postalCode: '15320',
    city: 'Jahnsfelde'
  },
  {
    postalCode: '15320',
    city: 'Neuhardenberg'
  },
  {
    postalCode: '15320',
    city: 'Neutrebbin'
  },
  {
    postalCode: '15320',
    city: 'Quappendorf'
  },
  {
    postalCode: '15320',
    city: 'Trebnitz'
  },
  {
    postalCode: '15320',
    city: 'Wulkow'
  },
  {
    postalCode: '15324',
    city: 'Gieshof-Zelliner Loose'
  },
  {
    postalCode: '15324',
    city: 'Groß Neuendorf'
  },
  {
    postalCode: '15324',
    city: 'Kiehnwerder'
  },
  {
    postalCode: '15324',
    city: 'Kienitz'
  },
  {
    postalCode: '15324',
    city: 'Letschin'
  },
  {
    postalCode: '15324',
    city: 'Neubarnim'
  },
  {
    postalCode: '15324',
    city: 'Ortwig'
  },
  {
    postalCode: '15324',
    city: 'Sietzing'
  },
  {
    postalCode: '15324',
    city: 'Solikante'
  },
  {
    postalCode: '15324',
    city: 'Sophienthal'
  },
  {
    postalCode: '15324',
    city: 'Steintoch'
  },
  {
    postalCode: '15324',
    city: 'Wilhelmsaue'
  },
  {
    postalCode: '15326',
    city: 'Carzig'
  },
  {
    postalCode: '15326',
    city: 'Lebus'
  },
  {
    postalCode: '15326',
    city: 'Mallnow'
  },
  {
    postalCode: '15326',
    city: 'Podelzig'
  },
  {
    postalCode: '15326',
    city: 'Schönfließ'
  },
  {
    postalCode: '15326',
    city: 'Wulkow'
  },
  {
    postalCode: '15326',
    city: 'Zeschdorf'
  },
  {
    postalCode: '15328',
    city: 'Alt Tucheband'
  },
  {
    postalCode: '15328',
    city: 'Bleyen'
  },
  {
    postalCode: '15328',
    city: 'Buschdorf'
  },
  {
    postalCode: '15328',
    city: 'Friedrichsaue'
  },
  {
    postalCode: '15328',
    city: 'Genschmar'
  },
  {
    postalCode: '15328',
    city: 'Golzow'
  },
  {
    postalCode: '15328',
    city: 'Gorgast'
  },
  {
    postalCode: '15328',
    city: 'Hathenow'
  },
  {
    postalCode: '15328',
    city: 'Küstrin-Kietz'
  },
  {
    postalCode: '15328',
    city: 'Manschnow'
  },
  {
    postalCode: '15328',
    city: 'Rathstock'
  },
  {
    postalCode: '15328',
    city: 'Reitwein'
  },
  {
    postalCode: '15328',
    city: 'Zechin'
  },
  {
    postalCode: '15344',
    city: 'Strausberg'
  },
  {
    postalCode: '15345',
    city: 'Altlandsberg'
  },
  {
    postalCode: '15345',
    city: 'Buchholz'
  },
  {
    postalCode: '15345',
    city: 'Eggersdorf'
  },
  {
    postalCode: '15345',
    city: 'Garzau'
  },
  {
    postalCode: '15345',
    city: 'Garzin'
  },
  {
    postalCode: '15345',
    city: 'Gielsdorf'
  },
  {
    postalCode: '15345',
    city: 'Hohenstein'
  },
  {
    postalCode: '15345',
    city: 'Hoppegarten bei Müncheberg'
  },
  {
    postalCode: '15345',
    city: 'Kagel'
  },
  {
    postalCode: '15345',
    city: 'Kienbaum'
  },
  {
    postalCode: '15345',
    city: 'Klosterdorf'
  },
  {
    postalCode: '15345',
    city: 'Lichtenow'
  },
  {
    postalCode: '15345',
    city: 'Möllensee'
  },
  {
    postalCode: '15345',
    city: 'Prädikow'
  },
  {
    postalCode: '15345',
    city: 'Prötzel'
  },
  {
    postalCode: '15345',
    city: 'Rehfelde'
  },
  {
    postalCode: '15345',
    city: 'Reichenow-Möglin'
  },
  {
    postalCode: '15345',
    city: 'Ruhlsdorf'
  },
  {
    postalCode: '15345',
    city: 'Werder'
  },
  {
    postalCode: '15345',
    city: 'Wesendahl'
  },
  {
    postalCode: '15345',
    city: 'Zinndorf'
  },
  {
    postalCode: '15366',
    city: 'Birkenstein'
  },
  {
    postalCode: '15366',
    city: 'Dahlwitz-Hoppegarten'
  },
  {
    postalCode: '15366',
    city: 'Hönow'
  },
  {
    postalCode: '15366',
    city: 'Münchehofe, Mark'
  },
  {
    postalCode: '15366',
    city: 'Neuenhagen'
  },
  {
    postalCode: '15370',
    city: 'Bruchmühle'
  },
  {
    postalCode: '15370',
    city: 'Fredersdorf'
  },
  {
    postalCode: '15370',
    city: 'Petershagen'
  },
  {
    postalCode: '15370',
    city: 'Vogelsdorf'
  },
  {
    postalCode: '15374',
    city: 'Eggersdorf Siedlung'
  },
  {
    postalCode: '15374',
    city: 'Hermersdorf/Obersdorf'
  },
  {
    postalCode: '15374',
    city: 'Müncheberg'
  },
  {
    postalCode: '15377',
    city: 'Batzlow'
  },
  {
    postalCode: '15377',
    city: 'Bergschäferei'
  },
  {
    postalCode: '15377',
    city: 'Bollersdorf'
  },
  {
    postalCode: '15377',
    city: 'Buckow'
  },
  {
    postalCode: '15377',
    city: 'Grunow'
  },
  {
    postalCode: '15377',
    city: 'Ihlow'
  },
  {
    postalCode: '15377',
    city: 'Liebenhof'
  },
  {
    postalCode: '15377',
    city: 'Reichenberg'
  },
  {
    postalCode: '15377',
    city: 'Ringenwalde'
  },
  {
    postalCode: '15377',
    city: 'Waldsieversdorf'
  },
  {
    postalCode: '15378',
    city: 'Hennickendorf'
  },
  {
    postalCode: '15378',
    city: 'Herzfelde'
  },
  {
    postalCode: '15517',
    city: 'Fürstenwalde'
  },
  {
    postalCode: '15518',
    city: 'Alt Madlitz'
  },
  {
    postalCode: '15518',
    city: 'Arensdorf'
  },
  {
    postalCode: '15518',
    city: 'Beerfelde'
  },
  {
    postalCode: '15518',
    city: 'Berkenbrück'
  },
  {
    postalCode: '15518',
    city: 'Braunsdorf'
  },
  {
    postalCode: '15518',
    city: 'Briesen'
  },
  {
    postalCode: '15518',
    city: 'Buchholz'
  },
  {
    postalCode: '15518',
    city: 'Demnitz'
  },
  {
    postalCode: '15518',
    city: 'Eggersdorf'
  },
  {
    postalCode: '15518',
    city: 'Falkenberg'
  },
  {
    postalCode: '15518',
    city: 'Hangelsberg'
  },
  {
    postalCode: '15518',
    city: 'Hasenfelde'
  },
  {
    postalCode: '15518',
    city: 'Heinersdorf'
  },
  {
    postalCode: '15518',
    city: 'Jänickendorf'
  },
  {
    postalCode: '15518',
    city: 'Kolpin'
  },
  {
    postalCode: '15518',
    city: 'Langewahl'
  },
  {
    postalCode: '15518',
    city: 'Neuendorf im Sande'
  },
  {
    postalCode: '15518',
    city: 'Petersdorf'
  },
  {
    postalCode: '15518',
    city: 'Rauen'
  },
  {
    postalCode: '15518',
    city: 'Schönfelde'
  },
  {
    postalCode: '15518',
    city: 'Steinhöfel'
  },
  {
    postalCode: '15518',
    city: 'Tempelberg'
  },
  {
    postalCode: '15518',
    city: 'Wilmersdorf'
  },
  {
    postalCode: '15526',
    city: 'Alt Golm'
  },
  {
    postalCode: '15526',
    city: 'Bad Saarow-Pieskow'
  },
  {
    postalCode: '15526',
    city: 'Neu Golm'
  },
  {
    postalCode: '15526',
    city: 'Reichenwalde'
  },
  {
    postalCode: '15528',
    city: 'Hartmannsdorf'
  },
  {
    postalCode: '15528',
    city: 'Markgrafpieske'
  },
  {
    postalCode: '15528',
    city: 'Mönchwinkel'
  },
  {
    postalCode: '15528',
    city: 'Spreeau'
  },
  {
    postalCode: '15528',
    city: 'Spreenhagen'
  },
  {
    postalCode: '15537',
    city: 'Burig'
  },
  {
    postalCode: '15537',
    city: 'Erkner'
  },
  {
    postalCode: '15537',
    city: 'Gosen'
  },
  {
    postalCode: '15537',
    city: 'Grünheide'
  },
  {
    postalCode: '15537',
    city: 'Neu Zittau'
  },
  {
    postalCode: '15537',
    city: 'Wernsdorf'
  },
  {
    postalCode: '15562',
    city: 'Rüdersdorf'
  },
  {
    postalCode: '15566',
    city: 'Schöneiche'
  },
  {
    postalCode: '15569',
    city: 'Woltersdorf'
  },
  {
    postalCode: '15711',
    city: 'Königs Wusterhausen'
  },
  {
    postalCode: '15711',
    city: 'Krummensee'
  },
  {
    postalCode: '15711',
    city: 'Schenkendorf'
  },
  {
    postalCode: '15711',
    city: 'Zeesen'
  },
  {
    postalCode: '15732',
    city: 'Eichwalde'
  },
  {
    postalCode: '15732',
    city: 'Schulzendorf'
  },
  {
    postalCode: '15732',
    city: 'Waltersdorf'
  },
  {
    postalCode: '15738',
    city: 'Zeuthen'
  },
  {
    postalCode: '15741',
    city: 'Bestensee'
  },
  {
    postalCode: '15741',
    city: 'Gräbendorf'
  },
  {
    postalCode: '15741',
    city: 'Motzen'
  },
  {
    postalCode: '15741',
    city: 'Pätz'
  },
  {
    postalCode: '15745',
    city: 'Wildau'
  },
  {
    postalCode: '15746',
    city: 'Groß Köris'
  },
  {
    postalCode: '15748',
    city: 'Birkholz'
  },
  {
    postalCode: '15748',
    city: 'Hermsdorf'
  },
  {
    postalCode: '15748',
    city: 'Kleinwasserburg'
  },
  {
    postalCode: '15748',
    city: 'Märkisch Buchholz'
  },
  {
    postalCode: '15748',
    city: 'Münchehofe'
  },
  {
    postalCode: '15749',
    city: 'Brusendorf'
  },
  {
    postalCode: '15749',
    city: 'Gallun'
  },
  {
    postalCode: '15749',
    city: 'Kiekebusch'
  },
  {
    postalCode: '15749',
    city: 'Mittenwalde'
  },
  {
    postalCode: '15749',
    city: 'Ragow'
  },
  {
    postalCode: '15749',
    city: 'Schöneicher Plan'
  },
  {
    postalCode: '15751',
    city: 'Miersdorfer Werder'
  },
  {
    postalCode: '15751',
    city: 'Niederlehme'
  },
  {
    postalCode: '15752',
    city: 'Kolberg'
  },
  {
    postalCode: '15752',
    city: 'Prieros'
  },
  {
    postalCode: '15752',
    city: 'Streganz'
  },
  {
    postalCode: '15754',
    city: 'Bindow'
  },
  {
    postalCode: '15754',
    city: 'Blossin'
  },
  {
    postalCode: '15754',
    city: 'Dannenreich'
  },
  {
    postalCode: '15754',
    city: 'Dolgenbrodt'
  },
  {
    postalCode: '15754',
    city: 'Friedersdorf'
  },
  {
    postalCode: '15754',
    city: 'Friedrichshof'
  },
  {
    postalCode: '15754',
    city: 'Gussow'
  },
  {
    postalCode: '15754',
    city: 'Senzig'
  },
  {
    postalCode: '15754',
    city: 'Wolzig'
  },
  {
    postalCode: '15755',
    city: 'Egsdorf'
  },
  {
    postalCode: '15755',
    city: 'Neuendorf'
  },
  {
    postalCode: '15755',
    city: 'Schwerin'
  },
  {
    postalCode: '15755',
    city: 'Teupitz'
  },
  {
    postalCode: '15755',
    city: 'Töpchin'
  },
  {
    postalCode: '15755',
    city: 'Tornow'
  },
  {
    postalCode: '15757',
    city: 'Briesen'
  },
  {
    postalCode: '15757',
    city: 'Freidorf'
  },
  {
    postalCode: '15757',
    city: 'Halbe'
  },
  {
    postalCode: '15757',
    city: 'Löpten'
  },
  {
    postalCode: '15757',
    city: 'Oderin'
  },
  {
    postalCode: '15758',
    city: 'Kablow'
  },
  {
    postalCode: '15758',
    city: 'Kablow Ziegelei'
  },
  {
    postalCode: '15758',
    city: 'Zernsdorf'
  },
  {
    postalCode: '15806',
    city: 'Christinendorf'
  },
  {
    postalCode: '15806',
    city: 'Dabendorf'
  },
  {
    postalCode: '15806',
    city: 'Gadsdorf'
  },
  {
    postalCode: '15806',
    city: 'Glienick'
  },
  {
    postalCode: '15806',
    city: 'Groß Machnow'
  },
  {
    postalCode: '15806',
    city: 'Groß Schulzendorf'
  },
  {
    postalCode: '15806',
    city: 'Horstfelde'
  },
  {
    postalCode: '15806',
    city: 'Kallinchen'
  },
  {
    postalCode: '15806',
    city: 'Kummersdorf-Alexanderdorf'
  },
  {
    postalCode: '15806',
    city: 'Mellensee'
  },
  {
    postalCode: '15806',
    city: 'Nächst Neuendorf'
  },
  {
    postalCode: '15806',
    city: 'Nunsdorf'
  },
  {
    postalCode: '15806',
    city: 'Rehagen'
  },
  {
    postalCode: '15806',
    city: 'Saalow'
  },
  {
    postalCode: '15806',
    city: 'Schöneiche'
  },
  {
    postalCode: '15806',
    city: 'Schünow'
  },
  {
    postalCode: '15806',
    city: 'Telz'
  },
  {
    postalCode: '15806',
    city: 'Werben'
  },
  {
    postalCode: '15806',
    city: 'Zossen'
  },
  {
    postalCode: '15827',
    city: 'Blankenfelde'
  },
  {
    postalCode: '15827',
    city: 'Dahlewitz'
  },
  {
    postalCode: '15831',
    city: 'Birkholz'
  },
  {
    postalCode: '15831',
    city: 'Diedersdorf'
  },
  {
    postalCode: '15831',
    city: 'Diepensee'
  },
  {
    postalCode: '15831',
    city: 'Groß Kienitz'
  },
  {
    postalCode: '15831',
    city: 'Großziethen'
  },
  {
    postalCode: '15831',
    city: 'Jühnsdorf'
  },
  {
    postalCode: '15831',
    city: 'Kleinziethen'
  },
  {
    postalCode: '15831',
    city: 'Mahlow'
  },
  {
    postalCode: '15831',
    city: 'Selchow'
  },
  {
    postalCode: '15831',
    city: 'Waßmannsdorf'
  },
  {
    postalCode: '15834',
    city: 'Pramsdorf'
  },
  {
    postalCode: '15834',
    city: 'Rangsdorf'
  },
  {
    postalCode: '15834',
    city: 'Theresenhof'
  },
  {
    postalCode: '15837',
    city: 'Baruth'
  },
  {
    postalCode: '15837',
    city: 'Dornswalde'
  },
  {
    postalCode: '15837',
    city: 'Groß Ziescht'
  },
  {
    postalCode: '15837',
    city: 'Horstwalde'
  },
  {
    postalCode: '15837',
    city: 'Klasdorf'
  },
  {
    postalCode: '15837',
    city: 'Mückendorf'
  },
  {
    postalCode: '15837',
    city: 'Paplitz'
  },
  {
    postalCode: '15837',
    city: 'Radeland'
  },
  {
    postalCode: '15837',
    city: 'Schöbendorf'
  },
  {
    postalCode: '15838',
    city: 'Klausdorf'
  },
  {
    postalCode: '15838',
    city: 'Kummersdorf Gut'
  },
  {
    postalCode: '15838',
    city: 'Lindenbrück'
  },
  {
    postalCode: '15838',
    city: 'Neuhof'
  },
  {
    postalCode: '15838',
    city: 'Sperenberg'
  },
  {
    postalCode: '15838',
    city: 'Waldstadt'
  },
  {
    postalCode: '15838',
    city: 'Wünsdorf'
  },
  {
    postalCode: '15838',
    city: 'Zesch am See'
  },
  {
    postalCode: '15848',
    city: 'Beeskow'
  },
  {
    postalCode: '15848',
    city: 'Birkholz'
  },
  {
    postalCode: '15848',
    city: 'Bornow'
  },
  {
    postalCode: '15848',
    city: 'Bornower Ausbau'
  },
  {
    postalCode: '15848',
    city: 'Briescht'
  },
  {
    postalCode: '15848',
    city: 'Buckow'
  },
  {
    postalCode: '15848',
    city: 'Drahendorf'
  },
  {
    postalCode: '15848',
    city: 'Falkenberg'
  },
  {
    postalCode: '15848',
    city: 'Friedland'
  },
  {
    postalCode: '15848',
    city: 'Giesensdorf'
  },
  {
    postalCode: '15848',
    city: 'Görsdorf'
  },
  {
    postalCode: '15848',
    city: 'Görzig'
  },
  {
    postalCode: '15848',
    city: 'Groß Rietz'
  },
  {
    postalCode: '15848',
    city: 'Kadelhof'
  },
  {
    postalCode: '15848',
    city: 'Kohlsdorf'
  },
  {
    postalCode: '15848',
    city: 'Kossenblatt'
  },
  {
    postalCode: '15848',
    city: 'Krügersdorf'
  },
  {
    postalCode: '15848',
    city: 'Kunersdorf'
  },
  {
    postalCode: '15848',
    city: 'Merz'
  },
  {
    postalCode: '15848',
    city: 'Mittweide'
  },
  {
    postalCode: '15848',
    city: 'Neubrück'
  },
  {
    postalCode: '15848',
    city: 'Neuendorf'
  },
  {
    postalCode: '15848',
    city: 'Oegeln'
  },
  {
    postalCode: '15848',
    city: 'Pfaffendorf'
  },
  {
    postalCode: '15848',
    city: 'Plattkow'
  },
  {
    postalCode: '15848',
    city: 'Radinkendorf'
  },
  {
    postalCode: '15848',
    city: 'Ragow'
  },
  {
    postalCode: '15848',
    city: 'Ranzig'
  },
  {
    postalCode: '15848',
    city: 'Raßmannsdorf'
  },
  {
    postalCode: '15848',
    city: 'Reudnitz'
  },
  {
    postalCode: '15848',
    city: 'Rietz-Neuendorf'
  },
  {
    postalCode: '15848',
    city: 'Sauen'
  },
  {
    postalCode: '15848',
    city: 'Schneeberg'
  },
  {
    postalCode: '15848',
    city: 'Speichrow'
  },
  {
    postalCode: '15848',
    city: 'Stremmen'
  },
  {
    postalCode: '15848',
    city: 'Tauche'
  },
  {
    postalCode: '15848',
    city: 'Trebatsch'
  },
  {
    postalCode: '15848',
    city: 'Werder'
  },
  {
    postalCode: '15848',
    city: 'Wilmersdorf'
  },
  {
    postalCode: '15859',
    city: 'Alt Stahnsdorf'
  },
  {
    postalCode: '15859',
    city: 'Bugk'
  },
  {
    postalCode: '15859',
    city: 'Dickdamm'
  },
  {
    postalCode: '15859',
    city: 'Görsdorf'
  },
  {
    postalCode: '15859',
    city: 'Groß Eichholz'
  },
  {
    postalCode: '15859',
    city: 'Groß Schauen'
  },
  {
    postalCode: '15859',
    city: 'Kehrigk'
  },
  {
    postalCode: '15859',
    city: 'Kummersdorf'
  },
  {
    postalCode: '15859',
    city: 'Philadelphia'
  },
  {
    postalCode: '15859',
    city: 'Rieplos'
  },
  {
    postalCode: '15859',
    city: 'Schwerin'
  },
  {
    postalCode: '15859',
    city: 'Selchow'
  },
  {
    postalCode: '15859',
    city: 'Storkow'
  },
  {
    postalCode: '15859',
    city: 'Wochowsee'
  },
  {
    postalCode: '15864',
    city: 'Ahrensdorf'
  },
  {
    postalCode: '15864',
    city: 'Behrensdorf'
  },
  {
    postalCode: '15864',
    city: 'Dahmsdorf'
  },
  {
    postalCode: '15864',
    city: 'Diensdorf-Radlow'
  },
  {
    postalCode: '15864',
    city: 'Glienicke'
  },
  {
    postalCode: '15864',
    city: 'Hartensdorf'
  },
  {
    postalCode: '15864',
    city: 'Herzberg'
  },
  {
    postalCode: '15864',
    city: 'Krachtsheide'
  },
  {
    postalCode: '15864',
    city: 'Limsdorf'
  },
  {
    postalCode: '15864',
    city: 'Lindenberg'
  },
  {
    postalCode: '15864',
    city: 'Wendisch Rietz'
  },
  {
    postalCode: '15868',
    city: 'Behlow'
  },
  {
    postalCode: '15868',
    city: 'Blasdorf'
  },
  {
    postalCode: '15868',
    city: 'Doberburg'
  },
  {
    postalCode: '15868',
    city: 'Goschen'
  },
  {
    postalCode: '15868',
    city: 'Groß Muckrow'
  },
  {
    postalCode: '15868',
    city: 'Hollbrunn'
  },
  {
    postalCode: '15868',
    city: 'Jamlitz'
  },
  {
    postalCode: '15868',
    city: 'Leeskow'
  },
  {
    postalCode: '15868',
    city: 'Lieberose'
  },
  {
    postalCode: '15868',
    city: 'Münchhofe'
  },
  {
    postalCode: '15868',
    city: 'Trebitz'
  },
  {
    postalCode: '15868',
    city: 'Ullersdorf'
  },
  {
    postalCode: '15868',
    city: 'Wirchensee Försterei'
  },
  {
    postalCode: '15890',
    city: 'Bremsdorf'
  },
  {
    postalCode: '15890',
    city: 'Eisenhüttenstadt'
  },
  {
    postalCode: '15890',
    city: 'Fünfeichen'
  },
  {
    postalCode: '15890',
    city: 'Kieselwitz'
  },
  {
    postalCode: '15890',
    city: 'Kobbeln'
  },
  {
    postalCode: '15890',
    city: 'Möbiskruge'
  },
  {
    postalCode: '15890',
    city: 'Pohlitz'
  },
  {
    postalCode: '15890',
    city: 'Rießen'
  },
  {
    postalCode: '15890',
    city: 'Schernsdorf'
  },
  {
    postalCode: '15890',
    city: 'Vogelsang'
  },
  {
    postalCode: '15898',
    city: 'Bahro'
  },
  {
    postalCode: '15898',
    city: 'Bomsdorf'
  },
  {
    postalCode: '15898',
    city: 'Breslack'
  },
  {
    postalCode: '15898',
    city: 'Coschen'
  },
  {
    postalCode: '15898',
    city: 'Göhlen'
  },
  {
    postalCode: '15898',
    city: 'Henzendorf'
  },
  {
    postalCode: '15898',
    city: 'Lawitz'
  },
  {
    postalCode: '15898',
    city: 'Neuzelle'
  },
  {
    postalCode: '15898',
    city: 'Ossendorf'
  },
  {
    postalCode: '15898',
    city: 'Ratzdorf'
  },
  {
    postalCode: '15898',
    city: 'Schwerzko'
  },
  {
    postalCode: '15898',
    city: 'Steinsdorf'
  },
  {
    postalCode: '15898',
    city: 'Streichwitz'
  },
  {
    postalCode: '15898',
    city: 'Treppeln'
  },
  {
    postalCode: '15898',
    city: 'Wellmitz'
  },
  {
    postalCode: '15907',
    city: 'Lübben'
  },
  {
    postalCode: '15910',
    city: 'Alt-Schadow'
  },
  {
    postalCode: '15910',
    city: 'Dürrenhofe'
  },
  {
    postalCode: '15910',
    city: 'Freiwalde'
  },
  {
    postalCode: '15910',
    city: 'Gröditsch'
  },
  {
    postalCode: '15910',
    city: 'Groß Wasserburg'
  },
  {
    postalCode: '15910',
    city: 'Hohenbrück-Neu Schadow'
  },
  {
    postalCode: '15910',
    city: 'Krausnick'
  },
  {
    postalCode: '15910',
    city: 'Kuschkow'
  },
  {
    postalCode: '15910',
    city: 'Leibsch'
  },
  {
    postalCode: '15910',
    city: 'Neu Lübbenau'
  },
  {
    postalCode: '15910',
    city: 'Neuendorf am See'
  },
  {
    postalCode: '15910',
    city: 'Niewitz'
  },
  {
    postalCode: '15910',
    city: 'Pretschen'
  },
  {
    postalCode: '15910',
    city: 'Rietzneuendorf-Friedrichshof'
  },
  {
    postalCode: '15910',
    city: 'Schlepzig'
  },
  {
    postalCode: '15910',
    city: 'Schönwalde'
  },
  {
    postalCode: '15910',
    city: 'Schuhlen-Wiese'
  },
  {
    postalCode: '15910',
    city: 'Staakow'
  },
  {
    postalCode: '15910',
    city: 'Waldow'
  },
  {
    postalCode: '15910',
    city: 'Wittmannsdorf-Bückchen'
  },
  {
    postalCode: '15913',
    city: 'Alt Zauche'
  },
  {
    postalCode: '15913',
    city: 'Biebersdorf'
  },
  {
    postalCode: '15913',
    city: 'Briesensee'
  },
  {
    postalCode: '15913',
    city: 'Butzen'
  },
  {
    postalCode: '15913',
    city: 'Byhleguhre'
  },
  {
    postalCode: '15913',
    city: 'Byhlen'
  },
  {
    postalCode: '15913',
    city: 'Caminchen'
  },
  {
    postalCode: '15913',
    city: 'Dollgen'
  },
  {
    postalCode: '15913',
    city: 'Glietz'
  },
  {
    postalCode: '15913',
    city: 'Goyatz'
  },
  {
    postalCode: '15913',
    city: 'Groß Leine'
  },
  {
    postalCode: '15913',
    city: 'Groß Leuthen'
  },
  {
    postalCode: '15913',
    city: 'Guhlen'
  },
  {
    postalCode: '15913',
    city: 'Jessern'
  },
  {
    postalCode: '15913',
    city: 'Klein Leine'
  },
  {
    postalCode: '15913',
    city: 'Krugau'
  },
  {
    postalCode: '15913',
    city: 'Laasow'
  },
  {
    postalCode: '15913',
    city: 'Lamsfeld-Groß Liebitz'
  },
  {
    postalCode: '15913',
    city: 'Leibchel'
  },
  {
    postalCode: '15913',
    city: 'Mochow'
  },
  {
    postalCode: '15913',
    city: 'Neu Zauche'
  },
  {
    postalCode: '15913',
    city: 'Ressen'
  },
  {
    postalCode: '15913',
    city: 'Sacrow'
  },
  {
    postalCode: '15913',
    city: 'Siegadel'
  },
  {
    postalCode: '15913',
    city: 'Straupitz'
  },
  {
    postalCode: '15913',
    city: 'Waldow-Sacrow'
  },
  {
    postalCode: '15913',
    city: 'Wußwerk'
  },
  {
    postalCode: '15913',
    city: 'Zaue'
  },
  {
    postalCode: '15926',
    city: 'Beesdau'
  },
  {
    postalCode: '15926',
    city: 'Bornsdorf'
  },
  {
    postalCode: '15926',
    city: 'Cahnsdorf'
  },
  {
    postalCode: '15926',
    city: 'Duben'
  },
  {
    postalCode: '15926',
    city: 'Egsdorf'
  },
  {
    postalCode: '15926',
    city: 'Falkenberg'
  },
  {
    postalCode: '15926',
    city: 'Freesdorf'
  },
  {
    postalCode: '15926',
    city: 'Gehren'
  },
  {
    postalCode: '15926',
    city: 'Gießmannsdorf'
  },
  {
    postalCode: '15926',
    city: 'Görlsdorf'
  },
  {
    postalCode: '15926',
    city: 'Goßmar'
  },
  {
    postalCode: '15926',
    city: 'Karche-Zaacko'
  },
  {
    postalCode: '15926',
    city: 'Kemlitz'
  },
  {
    postalCode: '15926',
    city: 'Kreblitz'
  },
  {
    postalCode: '15926',
    city: 'Langengrassau'
  },
  {
    postalCode: '15926',
    city: 'Luckau'
  },
  {
    postalCode: '15926',
    city: 'Pitschen-Pickel'
  },
  {
    postalCode: '15926',
    city: 'Schlabendorf'
  },
  {
    postalCode: '15926',
    city: 'Terpt'
  },
  {
    postalCode: '15926',
    city: 'Uckro'
  },
  {
    postalCode: '15926',
    city: 'Walddrehna'
  },
  {
    postalCode: '15926',
    city: 'Waltersdorf'
  },
  {
    postalCode: '15926',
    city: 'Weißack'
  },
  {
    postalCode: '15926',
    city: 'Willmersdorf-Stöbritz'
  },
  {
    postalCode: '15926',
    city: 'Wüstermarke'
  },
  {
    postalCode: '15926',
    city: 'Zieckau'
  },
  {
    postalCode: '15926',
    city: 'Zöllmersdorf'
  },
  {
    postalCode: '15936',
    city: 'Bollensdorf'
  },
  {
    postalCode: '15936',
    city: 'Dahme'
  },
  {
    postalCode: '15936',
    city: 'Gebersdorf'
  },
  {
    postalCode: '15936',
    city: 'Glienig'
  },
  {
    postalCode: '15936',
    city: 'Görsdorf'
  },
  {
    postalCode: '15936',
    city: 'Mehlsdorf'
  },
  {
    postalCode: '15936',
    city: 'Niendorf'
  },
  {
    postalCode: '15936',
    city: 'Prensdorf'
  },
  {
    postalCode: '15936',
    city: 'Rietdorf'
  },
  {
    postalCode: '15936',
    city: 'Rosenthal'
  },
  {
    postalCode: '15938',
    city: 'Drahnsdorf'
  },
  {
    postalCode: '15938',
    city: 'Falkenhain'
  },
  {
    postalCode: '15938',
    city: 'Golßen'
  },
  {
    postalCode: '15938',
    city: 'Jetsch'
  },
  {
    postalCode: '15938',
    city: 'Kasel-Golzig'
  },
  {
    postalCode: '15938',
    city: 'Kümmritz'
  },
  {
    postalCode: '15938',
    city: 'Reichwalde'
  },
  {
    postalCode: '15938',
    city: 'Schiebsdorf'
  },
  {
    postalCode: '15938',
    city: 'Sellendorf'
  },
  {
    postalCode: '15938',
    city: 'Wildau-Wentdorf'
  },
  {
    postalCode: '15938',
    city: 'Zützen'
  },
  {
    postalCode: '16225',
    city: 'Eberswalde'
  },
  {
    postalCode: '16227',
    city: 'Eberswalde'
  },
  {
    postalCode: '16230',
    city: 'Blütenberg'
  },
  {
    postalCode: '16230',
    city: 'Breydin'
  },
  {
    postalCode: '16230',
    city: 'Britz'
  },
  {
    postalCode: '16230',
    city: 'Brodowin'
  },
  {
    postalCode: '16230',
    city: 'Buckow'
  },
  {
    postalCode: '16230',
    city: 'Chorin'
  },
  {
    postalCode: '16230',
    city: 'Golzow'
  },
  {
    postalCode: '16230',
    city: 'Kahlenberg'
  },
  {
    postalCode: '16230',
    city: 'Lichterfelde'
  },
  {
    postalCode: '16230',
    city: 'Melchow'
  },
  {
    postalCode: '16230',
    city: 'Neuehütte'
  },
  {
    postalCode: '16230',
    city: 'Sandkrug'
  },
  {
    postalCode: '16230',
    city: 'Senftenhütte'
  },
  {
    postalCode: '16230',
    city: 'Serwest'
  },
  {
    postalCode: '16230',
    city: 'Sydower Fließ'
  },
  {
    postalCode: '16230',
    city: 'Teerofen'
  },
  {
    postalCode: '16244',
    city: 'Altenhof'
  },
  {
    postalCode: '16244',
    city: 'Eichhorst'
  },
  {
    postalCode: '16244',
    city: 'Finowfurt'
  },
  {
    postalCode: '16244',
    city: 'Üdersee bei Werbellin'
  },
  {
    postalCode: '16244',
    city: 'Werbellin'
  },
  {
    postalCode: '16247',
    city: 'Althüttendorf'
  },
  {
    postalCode: '16247',
    city: 'Friedrichswalde'
  },
  {
    postalCode: '16247',
    city: 'Groß-Ziethen'
  },
  {
    postalCode: '16247',
    city: 'Joachimsthal'
  },
  {
    postalCode: '16247',
    city: 'Klein Ziethen'
  },
  {
    postalCode: '16247',
    city: 'Neugrimnitz'
  },
  {
    postalCode: '16247',
    city: 'Parlow-Glambeck'
  },
  {
    postalCode: '16247',
    city: 'Wucker'
  },
  {
    postalCode: '16248',
    city: 'Bölkendorf'
  },
  {
    postalCode: '16248',
    city: 'Hohenfinow'
  },
  {
    postalCode: '16248',
    city: 'Hohensaaten'
  },
  {
    postalCode: '16248',
    city: 'Klosterbrücke'
  },
  {
    postalCode: '16248',
    city: 'Liepe'
  },
  {
    postalCode: '16248',
    city: 'Lüdersdorf'
  },
  {
    postalCode: '16248',
    city: 'Lunow'
  },
  {
    postalCode: '16248',
    city: 'Niederfinow'
  },
  {
    postalCode: '16248',
    city: 'Oderberg'
  },
  {
    postalCode: '16248',
    city: 'Parstein'
  },
  {
    postalCode: '16248',
    city: 'Stecherschleuse'
  },
  {
    postalCode: '16248',
    city: 'Stolzenhagen'
  },
  {
    postalCode: '16259',
    city: 'Ackermannshof'
  },
  {
    postalCode: '16259',
    city: 'Altgersdorf'
  },
  {
    postalCode: '16259',
    city: 'Altglietzen'
  },
  {
    postalCode: '16259',
    city: 'Altlewin'
  },
  {
    postalCode: '16259',
    city: 'Altmädewitz'
  },
  {
    postalCode: '16259',
    city: 'Altreetz'
  },
  {
    postalCode: '16259',
    city: 'Alttrebbin'
  },
  {
    postalCode: '16259',
    city: 'Altwustrow'
  },
  {
    postalCode: '16259',
    city: 'Bad Freienwalde'
  },
  {
    postalCode: '16259',
    city: 'Beerbaum'
  },
  {
    postalCode: '16259',
    city: 'Beiersdorf'
  },
  {
    postalCode: '16259',
    city: 'Bralitz'
  },
  {
    postalCode: '16259',
    city: 'Broichsdorf'
  },
  {
    postalCode: '16259',
    city: 'Brunow'
  },
  {
    postalCode: '16259',
    city: 'Cöthen'
  },
  {
    postalCode: '16259',
    city: 'Dannenberg'
  },
  {
    postalCode: '16259',
    city: 'Falkenberg'
  },
  {
    postalCode: '16259',
    city: 'Freudenberg'
  },
  {
    postalCode: '16259',
    city: 'Friedrichshof'
  },
  {
    postalCode: '16259',
    city: 'Gratze'
  },
  {
    postalCode: '16259',
    city: 'Güstebieser Loose'
  },
  {
    postalCode: '16259',
    city: 'Heckelberg'
  },
  {
    postalCode: '16259',
    city: 'Hohenwutzen'
  },
  {
    postalCode: '16259',
    city: 'Kruge'
  },
  {
    postalCode: '16259',
    city: 'Leuenberg'
  },
  {
    postalCode: '16259',
    city: 'Neuenhagen'
  },
  {
    postalCode: '16259',
    city: 'Neugersdorf'
  },
  {
    postalCode: '16259',
    city: 'Neukietz bei Wriezen'
  },
  {
    postalCode: '16259',
    city: 'Neuküstrinchen'
  },
  {
    postalCode: '16259',
    city: 'Neulewin'
  },
  {
    postalCode: '16259',
    city: 'Neulietzegöricke'
  },
  {
    postalCode: '16259',
    city: 'Neumädewitz'
  },
  {
    postalCode: '16259',
    city: 'Neureetz'
  },
  {
    postalCode: '16259',
    city: 'Neurüdnitz'
  },
  {
    postalCode: '16259',
    city: 'Neuwustrow'
  },
  {
    postalCode: '16259',
    city: 'Schiffmühle'
  },
  {
    postalCode: '16259',
    city: 'Steinbeck'
  },
  {
    postalCode: '16259',
    city: 'Tiefensee'
  },
  {
    postalCode: '16259',
    city: 'Wölsickendorf-Wollenberg'
  },
  {
    postalCode: '16259',
    city: 'Zäckericker Loose'
  },
  {
    postalCode: '16269',
    city: 'Bliesdorf'
  },
  {
    postalCode: '16269',
    city: 'Harnekop'
  },
  {
    postalCode: '16269',
    city: 'Königshof'
  },
  {
    postalCode: '16269',
    city: 'Sternebeck'
  },
  {
    postalCode: '16269',
    city: 'Thöringswerder'
  },
  {
    postalCode: '16269',
    city: 'Wriezen'
  },
  {
    postalCode: '16269',
    city: 'Wriezener Höhe'
  },
  {
    postalCode: '16278',
    city: 'Alt-Galow'
  },
  {
    postalCode: '16278',
    city: 'Altkünkendorf'
  },
  {
    postalCode: '16278',
    city: 'Angermünde'
  },
  {
    postalCode: '16278',
    city: 'Biesenbrow'
  },
  {
    postalCode: '16278',
    city: 'Bruchhagen'
  },
  {
    postalCode: '16278',
    city: 'Crussow'
  },
  {
    postalCode: '16278',
    city: 'Felchow'
  },
  {
    postalCode: '16278',
    city: 'Frauenhagen'
  },
  {
    postalCode: '16278',
    city: 'Galow'
  },
  {
    postalCode: '16278',
    city: 'Gellmersdorf'
  },
  {
    postalCode: '16278',
    city: 'Görlsdorf'
  },
  {
    postalCode: '16278',
    city: 'Greiffenberg'
  },
  {
    postalCode: '16278',
    city: 'Grünow'
  },
  {
    postalCode: '16278',
    city: 'Günterberg'
  },
  {
    postalCode: '16278',
    city: 'Herzsprung'
  },
  {
    postalCode: '16278',
    city: 'Kerkow'
  },
  {
    postalCode: '16278',
    city: 'Landin'
  },
  {
    postalCode: '16278',
    city: 'Mürow'
  },
  {
    postalCode: '16278',
    city: 'Neukünkendorf'
  },
  {
    postalCode: '16278',
    city: 'Pinnow'
  },
  {
    postalCode: '16278',
    city: 'Polßen'
  },
  {
    postalCode: '16278',
    city: 'Schmargendorf'
  },
  {
    postalCode: '16278',
    city: 'Schmiedeberg'
  },
  {
    postalCode: '16278',
    city: 'Schöneberg'
  },
  {
    postalCode: '16278',
    city: 'Schönermark'
  },
  {
    postalCode: '16278',
    city: 'Steinhöfel'
  },
  {
    postalCode: '16278',
    city: 'Stolpe'
  },
  {
    postalCode: '16278',
    city: 'Welsow'
  },
  {
    postalCode: '16278',
    city: 'Wilmersdorf'
  },
  {
    postalCode: '16278',
    city: 'Wolletz'
  },
  {
    postalCode: '16303',
    city: 'Schwedt'
  },
  {
    postalCode: '16306',
    city: 'Berkholz-Meyenburg'
  },
  {
    postalCode: '16306',
    city: 'Biesendahlshof'
  },
  {
    postalCode: '16306',
    city: 'Blumberg'
  },
  {
    postalCode: '16306',
    city: 'Blumenhagen'
  },
  {
    postalCode: '16306',
    city: 'Casekow'
  },
  {
    postalCode: '16306',
    city: 'Criewen'
  },
  {
    postalCode: '16306',
    city: 'Flemsdorf'
  },
  {
    postalCode: '16306',
    city: 'Fredersdorf'
  },
  {
    postalCode: '16306',
    city: 'Friedrichsthal'
  },
  {
    postalCode: '16306',
    city: 'Gatow'
  },
  {
    postalCode: '16306',
    city: 'Golm'
  },
  {
    postalCode: '16306',
    city: 'Groß Pinnow'
  },
  {
    postalCode: '16306',
    city: 'Hohenfelde'
  },
  {
    postalCode: '16306',
    city: 'Hohenselchow'
  },
  {
    postalCode: '16306',
    city: 'Kummerow'
  },
  {
    postalCode: '16306',
    city: 'Kunow'
  },
  {
    postalCode: '16306',
    city: 'Luckow-Petershagen'
  },
  {
    postalCode: '16306',
    city: 'Mürowsche Kavelheide'
  },
  {
    postalCode: '16306',
    city: 'Niederfelde'
  },
  {
    postalCode: '16306',
    city: 'Schönow'
  },
  {
    postalCode: '16306',
    city: 'Stendell'
  },
  {
    postalCode: '16306',
    city: 'Vierraden'
  },
  {
    postalCode: '16306',
    city: 'Wartin'
  },
  {
    postalCode: '16306',
    city: 'Welsebruch'
  },
  {
    postalCode: '16306',
    city: 'Woltersdorf'
  },
  {
    postalCode: '16306',
    city: 'Zichow'
  },
  {
    postalCode: '16306',
    city: 'Zützen'
  },
  {
    postalCode: '16307',
    city: 'Gartz'
  },
  {
    postalCode: '16307',
    city: 'Geesow'
  },
  {
    postalCode: '16307',
    city: 'Hohenreinkendorf'
  },
  {
    postalCode: '16307',
    city: 'Mescherin'
  },
  {
    postalCode: '16307',
    city: 'Neurochlitz'
  },
  {
    postalCode: '16307',
    city: 'Radekow'
  },
  {
    postalCode: '16307',
    city: 'Rosow'
  },
  {
    postalCode: '16307',
    city: 'Schönfeld'
  },
  {
    postalCode: '16307',
    city: 'Tantow'
  },
  {
    postalCode: '16321',
    city: 'Bernau'
  },
  {
    postalCode: '16321',
    city: 'Börnicke'
  },
  {
    postalCode: '16321',
    city: 'Danewitz'
  },
  {
    postalCode: '16321',
    city: 'Ladeburg'
  },
  {
    postalCode: '16321',
    city: 'Lindenberg'
  },
  {
    postalCode: '16321',
    city: 'Lobetal'
  },
  {
    postalCode: '16321',
    city: 'Rüdnitz'
  },
  {
    postalCode: '16321',
    city: 'Schönow'
  },
  {
    postalCode: '16341',
    city: 'Schwanebeck'
  },
  {
    postalCode: '16341',
    city: 'Zepernick'
  },
  {
    postalCode: '16348',
    city: 'Groß Schönebeck'
  },
  {
    postalCode: '16348',
    city: 'Klosterfelde'
  },
  {
    postalCode: '16348',
    city: 'Lottschesee'
  },
  {
    postalCode: '16348',
    city: 'Marienwalde'
  },
  {
    postalCode: '16348',
    city: 'Marienwerder'
  },
  {
    postalCode: '16348',
    city: 'Neudörfchen'
  },
  {
    postalCode: '16348',
    city: 'Prenden'
  },
  {
    postalCode: '16348',
    city: 'Ruhlsdorf'
  },
  {
    postalCode: '16348',
    city: 'Ruhlsdorf Ausbau'
  },
  {
    postalCode: '16348',
    city: 'Sophienstädt'
  },
  {
    postalCode: '16348',
    city: 'Stolzenhagen'
  },
  {
    postalCode: '16348',
    city: 'Wandlitz'
  },
  {
    postalCode: '16348',
    city: 'Zerpenschleuse'
  },
  {
    postalCode: '16352',
    city: 'Basdorf'
  },
  {
    postalCode: '16352',
    city: 'Schönerlinde'
  },
  {
    postalCode: '16352',
    city: 'Schönwalde'
  },
  {
    postalCode: '16356',
    city: 'Ahrensfelde'
  },
  {
    postalCode: '16356',
    city: 'Blumberg'
  },
  {
    postalCode: '16356',
    city: 'Eiche'
  },
  {
    postalCode: '16356',
    city: 'Hirschfelde'
  },
  {
    postalCode: '16356',
    city: 'Krummensee'
  },
  {
    postalCode: '16356',
    city: 'Löhme'
  },
  {
    postalCode: '16356',
    city: 'Mehrow'
  },
  {
    postalCode: '16356',
    city: 'Schönfeld'
  },
  {
    postalCode: '16356',
    city: 'Seefeld'
  },
  {
    postalCode: '16356',
    city: 'Weesow'
  },
  {
    postalCode: '16356',
    city: 'Werneuchen'
  },
  {
    postalCode: '16356',
    city: 'Willmersdorf'
  },
  {
    postalCode: '16359',
    city: 'Biesenthal'
  },
  {
    postalCode: '16359',
    city: 'Lanke'
  },
  {
    postalCode: '16515',
    city: 'Bernöwe'
  },
  {
    postalCode: '16515',
    city: 'Freienhagen'
  },
  {
    postalCode: '16515',
    city: 'Friedrichsthal'
  },
  {
    postalCode: '16515',
    city: 'Hertefeld'
  },
  {
    postalCode: '16515',
    city: 'Hohenbruch'
  },
  {
    postalCode: '16515',
    city: 'Malz'
  },
  {
    postalCode: '16515',
    city: 'Nassenheide'
  },
  {
    postalCode: '16515',
    city: 'Neuendorf'
  },
  {
    postalCode: '16515',
    city: 'Neuholland'
  },
  {
    postalCode: '16515',
    city: 'Oranienburg'
  },
  {
    postalCode: '16515',
    city: 'Schmachtenhagen'
  },
  {
    postalCode: '16515',
    city: 'Teschendorf'
  },
  {
    postalCode: '16515',
    city: 'Wensickendorf'
  },
  {
    postalCode: '16515',
    city: 'Zehlendorf'
  },
  {
    postalCode: '16515',
    city: 'Zühlsdorf'
  },
  {
    postalCode: '16540',
    city: 'Hohen Neuendorf'
  },
  {
    postalCode: '16540',
    city: 'Stolpe'
  },
  {
    postalCode: '16547',
    city: 'Birkenwerder'
  },
  {
    postalCode: '16548',
    city: 'Glienicke'
  },
  {
    postalCode: '16552',
    city: 'Schildow'
  },
  {
    postalCode: '16556',
    city: 'Borgsdorf'
  },
  {
    postalCode: '16559',
    city: 'Hammer'
  },
  {
    postalCode: '16559',
    city: 'Kreuzbruch'
  },
  {
    postalCode: '16559',
    city: 'Liebenthal'
  },
  {
    postalCode: '16559',
    city: 'Liebenwalde'
  },
  {
    postalCode: '16562',
    city: 'Bergfelde'
  },
  {
    postalCode: '16565',
    city: 'Lehnitz'
  },
  {
    postalCode: '16567',
    city: 'Mühlenbeck'
  },
  {
    postalCode: '16567',
    city: 'Schönfließ'
  },
  {
    postalCode: '16727',
    city: 'Bärenklau'
  },
  {
    postalCode: '16727',
    city: 'Bötzow'
  },
  {
    postalCode: '16727',
    city: 'Eichstädt'
  },
  {
    postalCode: '16727',
    city: 'Marwitz'
  },
  {
    postalCode: '16727',
    city: 'Schwante'
  },
  {
    postalCode: '16727',
    city: 'Vehlefanz'
  },
  {
    postalCode: '16727',
    city: 'Velten'
  },
  {
    postalCode: '16761',
    city: 'Hennigsdorf'
  },
  {
    postalCode: '16761',
    city: 'Stolpe-Süd'
  },
  {
    postalCode: '16766',
    city: 'Amalienfelde'
  },
  {
    postalCode: '16766',
    city: 'Beetz'
  },
  {
    postalCode: '16766',
    city: 'Dorotheenhof'
  },
  {
    postalCode: '16766',
    city: 'Flatow'
  },
  {
    postalCode: '16766',
    city: 'Groß-Ziethen'
  },
  {
    postalCode: '16766',
    city: 'Kremmen'
  },
  {
    postalCode: '16766',
    city: 'Linumhorst'
  },
  {
    postalCode: '16766',
    city: 'Neu-Vehlefanz'
  },
  {
    postalCode: '16766',
    city: 'Sommerfeld'
  },
  {
    postalCode: '16766',
    city: 'Staffelde'
  },
  {
    postalCode: '16767',
    city: 'Germendorf'
  },
  {
    postalCode: '16767',
    city: 'Leegebruch'
  },
  {
    postalCode: '16775',
    city: 'Altlüdersdorf'
  },
  {
    postalCode: '16775',
    city: 'Badingen'
  },
  {
    postalCode: '16775',
    city: 'Barsdorf'
  },
  {
    postalCode: '16775',
    city: 'Baumgarten'
  },
  {
    postalCode: '16775',
    city: 'Bergsdorf'
  },
  {
    postalCode: '16775',
    city: 'Blumenow'
  },
  {
    postalCode: '16775',
    city: 'Bredereiche'
  },
  {
    postalCode: '16775',
    city: 'Burgwall'
  },
  {
    postalCode: '16775',
    city: 'Dannenwalde'
  },
  {
    postalCode: '16775',
    city: 'Dollgow'
  },
  {
    postalCode: '16775',
    city: 'Falkenthal'
  },
  {
    postalCode: '16775',
    city: 'Glambeck'
  },
  {
    postalCode: '16775',
    city: 'Gramzow'
  },
  {
    postalCode: '16775',
    city: 'Gransee'
  },
  {
    postalCode: '16775',
    city: 'Grieben'
  },
  {
    postalCode: '16775',
    city: 'Großmutz'
  },
  {
    postalCode: '16775',
    city: 'Großwoltersdorf'
  },
  {
    postalCode: '16775',
    city: 'Grüneberg'
  },
  {
    postalCode: '16775',
    city: 'Gutengermendorf'
  },
  {
    postalCode: '16775',
    city: 'Häsen'
  },
  {
    postalCode: '16775',
    city: 'Kappe'
  },
  {
    postalCode: '16775',
    city: 'Keller'
  },
  {
    postalCode: '16775',
    city: 'Klein-Mutz'
  },
  {
    postalCode: '16775',
    city: 'Kraatz-Buberow'
  },
  {
    postalCode: '16775',
    city: 'Krewelin'
  },
  {
    postalCode: '16775',
    city: 'Löwenberg'
  },
  {
    postalCode: '16775',
    city: 'Marienthal'
  },
  {
    postalCode: '16775',
    city: 'Menz'
  },
  {
    postalCode: '16775',
    city: 'Meseberg'
  },
  {
    postalCode: '16775',
    city: 'Mildenberg'
  },
  {
    postalCode: '16775',
    city: 'Neuglobsow'
  },
  {
    postalCode: '16775',
    city: 'Neulögow'
  },
  {
    postalCode: '16775',
    city: 'Neulöwenberg'
  },
  {
    postalCode: '16775',
    city: 'Pian'
  },
  {
    postalCode: '16775',
    city: 'Ribbeck'
  },
  {
    postalCode: '16775',
    city: 'Rönnebeck'
  },
  {
    postalCode: '16775',
    city: 'Schönermark'
  },
  {
    postalCode: '16775',
    city: 'Schulzendorf'
  },
  {
    postalCode: '16775',
    city: 'Seilershof'
  },
  {
    postalCode: '16775',
    city: 'Sonnenberg'
  },
  {
    postalCode: '16775',
    city: 'Tornow'
  },
  {
    postalCode: '16775',
    city: 'Wolfsruh'
  },
  {
    postalCode: '16775',
    city: 'Zabelsdorf'
  },
  {
    postalCode: '16775',
    city: 'Zernikow'
  },
  {
    postalCode: '16792',
    city: 'Kurtschlag'
  },
  {
    postalCode: '16792',
    city: 'Vogelsang'
  },
  {
    postalCode: '16792',
    city: 'Wesendorf'
  },
  {
    postalCode: '16792',
    city: 'Zehdenick'
  },
  {
    postalCode: '16798',
    city: 'Altthymen'
  },
  {
    postalCode: '16798',
    city: 'Fürstenberg'
  },
  {
    postalCode: '16798',
    city: 'Himmelpfort'
  },
  {
    postalCode: '16798',
    city: 'Schönhorn Forsthaus'
  },
  {
    postalCode: '16798',
    city: 'Steinförde'
  },
  {
    postalCode: '16798',
    city: 'Zootzen'
  },
  {
    postalCode: '16816',
    city: 'Buskow'
  },
  {
    postalCode: '16816',
    city: 'Neuruppin'
  },
  {
    postalCode: '16816',
    city: 'Nietwerder'
  },
  {
    postalCode: '16818',
    city: 'Albertinenhof'
  },
  {
    postalCode: '16818',
    city: 'Altfriesack'
  },
  {
    postalCode: '16818',
    city: 'Basdorf'
  },
  {
    postalCode: '16818',
    city: 'Braunsberg'
  },
  {
    postalCode: '16818',
    city: 'Buchenhaus'
  },
  {
    postalCode: '16818',
    city: 'Dabergotz'
  },
  {
    postalCode: '16818',
    city: 'Darritz'
  },
  {
    postalCode: '16818',
    city: 'Darsikow'
  },
  {
    postalCode: '16818',
    city: 'Deutschhof'
  },
  {
    postalCode: '16818',
    city: 'Frankendorf'
  },
  {
    postalCode: '16818',
    city: 'Gnewikow'
  },
  {
    postalCode: '16818',
    city: 'Gühlen Glienicke'
  },
  {
    postalCode: '16818',
    city: 'Karwe'
  },
  {
    postalCode: '16818',
    city: 'Katerbow'
  },
  {
    postalCode: '16818',
    city: 'Kränzlin'
  },
  {
    postalCode: '16818',
    city: 'Kuhhorst'
  },
  {
    postalCode: '16818',
    city: 'Langen'
  },
  {
    postalCode: '16818',
    city: 'Lichtenberg'
  },
  {
    postalCode: '16818',
    city: 'Netzeband'
  },
  {
    postalCode: '16818',
    city: 'Neu Glienicke'
  },
  {
    postalCode: '16818',
    city: 'Pfalzheim'
  },
  {
    postalCode: '16818',
    city: 'Radensleben'
  },
  {
    postalCode: '16818',
    city: 'Rägelin'
  },
  {
    postalCode: '16818',
    city: 'Rheinsberg Glienicke'
  },
  {
    postalCode: '16818',
    city: 'Ribbeckshorst'
  },
  {
    postalCode: '16818',
    city: 'Storbeck'
  },
  {
    postalCode: '16818',
    city: 'Tornow'
  },
  {
    postalCode: '16818',
    city: 'Wahlendorf'
  },
  {
    postalCode: '16818',
    city: 'Wall'
  },
  {
    postalCode: '16818',
    city: 'Walsleben'
  },
  {
    postalCode: '16818',
    city: 'Werder'
  },
  {
    postalCode: '16818',
    city: 'Wustrau'
  },
  {
    postalCode: '16818',
    city: 'Wuthenow'
  },
  {
    postalCode: '16818',
    city: 'Ziethenhorst'
  },
  {
    postalCode: '16827',
    city: 'Alt Ruppin'
  },
  {
    postalCode: '16827',
    city: 'Krangen'
  },
  {
    postalCode: '16827',
    city: 'Molchow'
  },
  {
    postalCode: '16827',
    city: 'Zermützel'
  },
  {
    postalCode: '16827',
    city: 'Zippelsförde'
  },
  {
    postalCode: '16831',
    city: 'Großzerlang'
  },
  {
    postalCode: '16831',
    city: 'Heinrichsdorf'
  },
  {
    postalCode: '16831',
    city: 'Kleinzerlang'
  },
  {
    postalCode: '16831',
    city: 'Linow'
  },
  {
    postalCode: '16831',
    city: 'Rheinsberg'
  },
  {
    postalCode: '16831',
    city: 'Schwanow'
  },
  {
    postalCode: '16831',
    city: 'Zechlinerhütte'
  },
  {
    postalCode: '16831',
    city: 'Zechow'
  },
  {
    postalCode: '16831',
    city: 'Zühlen'
  },
  {
    postalCode: '16833',
    city: 'Betzin'
  },
  {
    postalCode: '16833',
    city: 'Brunne'
  },
  {
    postalCode: '16833',
    city: 'Dechtow'
  },
  {
    postalCode: '16833',
    city: 'Fehrbellin'
  },
  {
    postalCode: '16833',
    city: 'Hakenberg'
  },
  {
    postalCode: '16833',
    city: 'Jahnberge'
  },
  {
    postalCode: '16833',
    city: 'Karwesee'
  },
  {
    postalCode: '16833',
    city: 'Königshorst'
  },
  {
    postalCode: '16833',
    city: 'Lentzke'
  },
  {
    postalCode: '16833',
    city: 'Linum'
  },
  {
    postalCode: '16833',
    city: 'Protzen'
  },
  {
    postalCode: '16833',
    city: 'Stöffin'
  },
  {
    postalCode: '16833',
    city: 'Tarmow'
  },
  {
    postalCode: '16833',
    city: 'Walchow'
  },
  {
    postalCode: '16835',
    city: 'Banzendorf'
  },
  {
    postalCode: '16835',
    city: 'Dierberg'
  },
  {
    postalCode: '16835',
    city: 'Herzberg'
  },
  {
    postalCode: '16835',
    city: 'Hindenberg'
  },
  {
    postalCode: '16835',
    city: 'Klosterheide'
  },
  {
    postalCode: '16835',
    city: 'Lindow'
  },
  {
    postalCode: '16835',
    city: 'Rüthnick'
  },
  {
    postalCode: '16835',
    city: 'Schönberg'
  },
  {
    postalCode: '16835',
    city: 'Seebeck'
  },
  {
    postalCode: '16835',
    city: 'Strubensee'
  },
  {
    postalCode: '16835',
    city: 'Vielitz'
  },
  {
    postalCode: '16835',
    city: 'Wulkow'
  },
  {
    postalCode: '16837',
    city: 'Alt Lutterow'
  },
  {
    postalCode: '16837',
    city: 'Dorf Zechlin'
  },
  {
    postalCode: '16837',
    city: 'Flecken Zechlin'
  },
  {
    postalCode: '16837',
    city: 'Kagar'
  },
  {
    postalCode: '16837',
    city: 'Luhme'
  },
  {
    postalCode: '16837',
    city: 'Neu Lutterow'
  },
  {
    postalCode: '16837',
    city: 'Wallitz'
  },
  {
    postalCode: '16837',
    city: 'Zempow'
  },
  {
    postalCode: '16845',
    city: 'Barsikow'
  },
  {
    postalCode: '16845',
    city: 'Blankenberg'
  },
  {
    postalCode: '16845',
    city: 'Blumenaue'
  },
  {
    postalCode: '16845',
    city: 'Breddin'
  },
  {
    postalCode: '16845',
    city: 'Brunn'
  },
  {
    postalCode: '16845',
    city: 'Bückwitz'
  },
  {
    postalCode: '16845',
    city: 'Dessow'
  },
  {
    postalCode: '16845',
    city: 'Dreetz'
  },
  {
    postalCode: '16845',
    city: 'Ganzer'
  },
  {
    postalCode: '16845',
    city: 'Gartow'
  },
  {
    postalCode: '16845',
    city: 'Garz'
  },
  {
    postalCode: '16845',
    city: 'Giesenhorst'
  },
  {
    postalCode: '16845',
    city: 'Goldbeck'
  },
  {
    postalCode: '16845',
    city: 'Gottberg'
  },
  {
    postalCode: '16845',
    city: 'Großderschau'
  },
  {
    postalCode: '16845',
    city: 'Hohenofen'
  },
  {
    postalCode: '16845',
    city: 'Holzhausen'
  },
  {
    postalCode: '16845',
    city: 'Kantow'
  },
  {
    postalCode: '16845',
    city: 'Kerzlin'
  },
  {
    postalCode: '16845',
    city: 'Koppenbrück'
  },
  {
    postalCode: '16845',
    city: 'Küdow'
  },
  {
    postalCode: '16845',
    city: 'Leddin'
  },
  {
    postalCode: '16845',
    city: 'Lögow'
  },
  {
    postalCode: '16845',
    city: 'Lohm'
  },
  {
    postalCode: '16845',
    city: 'Lüchfeld'
  },
  {
    postalCode: '16845',
    city: 'Manker'
  },
  {
    postalCode: '16845',
    city: 'Nackel'
  },
  {
    postalCode: '16845',
    city: 'Neuendorf'
  },
  {
    postalCode: '16845',
    city: 'Neustadt'
  },
  {
    postalCode: '16845',
    city: 'Plänitz'
  },
  {
    postalCode: '16845',
    city: 'Roddahn'
  },
  {
    postalCode: '16845',
    city: 'Rohrlack'
  },
  {
    postalCode: '16845',
    city: 'Schönermark'
  },
  {
    postalCode: '16845',
    city: 'Segeletz'
  },
  {
    postalCode: '16845',
    city: 'Segeletz Bahnhof'
  },
  {
    postalCode: '16845',
    city: 'Sieversdorf'
  },
  {
    postalCode: '16845',
    city: 'Stüdenitz'
  },
  {
    postalCode: '16845',
    city: 'Trieplatz'
  },
  {
    postalCode: '16845',
    city: 'Vichel'
  },
  {
    postalCode: '16845',
    city: 'Wildberg'
  },
  {
    postalCode: '16845',
    city: 'Zernitz'
  },
  {
    postalCode: '16866',
    city: 'Barenthin'
  },
  {
    postalCode: '16866',
    city: 'Bork-Lellichow'
  },
  {
    postalCode: '16866',
    city: 'Dannenwalde'
  },
  {
    postalCode: '16866',
    city: 'Demerthin'
  },
  {
    postalCode: '16866',
    city: 'Döllen'
  },
  {
    postalCode: '16866',
    city: 'Drewen'
  },
  {
    postalCode: '16866',
    city: 'Görike'
  },
  {
    postalCode: '16866',
    city: 'Granzow'
  },
  {
    postalCode: '16866',
    city: 'Groß Welle'
  },
  {
    postalCode: '16866',
    city: 'Gumtow'
  },
  {
    postalCode: '16866',
    city: 'Kolrep'
  },
  {
    postalCode: '16866',
    city: 'Kötzlin'
  },
  {
    postalCode: '16866',
    city: 'Kunow'
  },
  {
    postalCode: '16866',
    city: 'Kyritz'
  },
  {
    postalCode: '16866',
    city: 'Rehfeld-Berlitt'
  },
  {
    postalCode: '16866',
    city: 'Schönberg'
  },
  {
    postalCode: '16866',
    city: 'Schönhagen'
  },
  {
    postalCode: '16866',
    city: 'Schrepkow'
  },
  {
    postalCode: '16866',
    city: 'Sechzehneichen'
  },
  {
    postalCode: '16866',
    city: 'Teetz'
  },
  {
    postalCode: '16866',
    city: 'Tornow'
  },
  {
    postalCode: '16866',
    city: 'Vehlin'
  },
  {
    postalCode: '16866',
    city: 'Vehlow'
  },
  {
    postalCode: '16866',
    city: 'Wutike'
  },
  {
    postalCode: '16868',
    city: 'Bantikow'
  },
  {
    postalCode: '16868',
    city: 'Wusterhausen'
  },
  {
    postalCode: '16909',
    city: 'Berlinchen'
  },
  {
    postalCode: '16909',
    city: 'Blandikow'
  },
  {
    postalCode: '16909',
    city: 'Blesendorf'
  },
  {
    postalCode: '16909',
    city: 'Christdorf'
  },
  {
    postalCode: '16909',
    city: 'Dossow'
  },
  {
    postalCode: '16909',
    city: 'Dranse'
  },
  {
    postalCode: '16909',
    city: 'Fretzdorf'
  },
  {
    postalCode: '16909',
    city: 'Gadow'
  },
  {
    postalCode: '16909',
    city: 'Glienicke'
  },
  {
    postalCode: '16909',
    city: 'Goldbeck'
  },
  {
    postalCode: '16909',
    city: 'Grabow'
  },
  {
    postalCode: '16909',
    city: 'Groß Haßlow'
  },
  {
    postalCode: '16909',
    city: 'Heiligengrabe'
  },
  {
    postalCode: '16909',
    city: 'Herzsprung'
  },
  {
    postalCode: '16909',
    city: 'Jabel'
  },
  {
    postalCode: '16909',
    city: 'Klein Haßlow'
  },
  {
    postalCode: '16909',
    city: 'Königsberg'
  },
  {
    postalCode: '16909',
    city: 'Liebenthal'
  },
  {
    postalCode: '16909',
    city: 'Maulbeerwalde'
  },
  {
    postalCode: '16909',
    city: 'Niemerlang'
  },
  {
    postalCode: '16909',
    city: 'Papenbruch'
  },
  {
    postalCode: '16909',
    city: 'Rossow'
  },
  {
    postalCode: '16909',
    city: 'Schweinrich'
  },
  {
    postalCode: '16909',
    city: 'Sewekow'
  },
  {
    postalCode: '16909',
    city: 'Wernikow'
  },
  {
    postalCode: '16909',
    city: 'Wittstock'
  },
  {
    postalCode: '16909',
    city: 'Wulfersdorf'
  },
  {
    postalCode: '16909',
    city: 'Zaatzke'
  },
  {
    postalCode: '16909',
    city: 'Zootzen'
  },
  {
    postalCode: '16918',
    city: 'Freyenstein'
  },
  {
    postalCode: '16928',
    city: 'Alt Krüssow'
  },
  {
    postalCode: '16928',
    city: 'Beveringen'
  },
  {
    postalCode: '16928',
    city: 'Biesterholz'
  },
  {
    postalCode: '16928',
    city: 'Birkenfelde'
  },
  {
    postalCode: '16928',
    city: 'Blumenthal'
  },
  {
    postalCode: '16928',
    city: 'Boddin'
  },
  {
    postalCode: '16928',
    city: 'Bölzke'
  },
  {
    postalCode: '16928',
    city: 'Brügge'
  },
  {
    postalCode: '16928',
    city: 'Brünkendorf'
  },
  {
    postalCode: '16928',
    city: 'Buchholz'
  },
  {
    postalCode: '16928',
    city: 'Buchhorst'
  },
  {
    postalCode: '16928',
    city: 'Bullendorf'
  },
  {
    postalCode: '16928',
    city: 'Dahlhausen'
  },
  {
    postalCode: '16928',
    city: 'Ellershagen'
  },
  {
    postalCode: '16928',
    city: 'Falkenhagen'
  },
  {
    postalCode: '16928',
    city: 'Felsenhagen'
  },
  {
    postalCode: '16928',
    city: 'Gerdshagen'
  },
  {
    postalCode: '16928',
    city: 'Giesenhagen'
  },
  {
    postalCode: '16928',
    city: 'Giesensdorf'
  },
  {
    postalCode: '16928',
    city: 'Groß Langerwisch'
  },
  {
    postalCode: '16928',
    city: 'Groß Pankow'
  },
  {
    postalCode: '16928',
    city: 'Groß Woltersdorf'
  },
  {
    postalCode: '16928',
    city: 'Hasenwinkel'
  },
  {
    postalCode: '16928',
    city: 'Heidelberg'
  },
  {
    postalCode: '16928',
    city: 'Helle'
  },
  {
    postalCode: '16928',
    city: 'Holzländerhof'
  },
  {
    postalCode: '16928',
    city: 'Hoppenrade'
  },
  {
    postalCode: '16928',
    city: 'Horst'
  },
  {
    postalCode: '16928',
    city: 'Kammermark'
  },
  {
    postalCode: '16928',
    city: 'Kehrberg'
  },
  {
    postalCode: '16928',
    city: 'Kemnitz'
  },
  {
    postalCode: '16928',
    city: 'Kiebitzberg, Gem Beveringen'
  },
  {
    postalCode: '16928',
    city: 'Klein Langerwisch'
  },
  {
    postalCode: '16928',
    city: 'Klein Woltersdorf'
  },
  {
    postalCode: '16928',
    city: 'Klenzenhof'
  },
  {
    postalCode: '16928',
    city: 'Könkendorf'
  },
  {
    postalCode: '16928',
    city: 'Kuckuck'
  },
  {
    postalCode: '16928',
    city: 'Kuhbier'
  },
  {
    postalCode: '16928',
    city: 'Kuhsdorf'
  },
  {
    postalCode: '16928',
    city: 'Langnow'
  },
  {
    postalCode: '16928',
    city: 'Lindenberg'
  },
  {
    postalCode: '16928',
    city: 'Luggendorf'
  },
  {
    postalCode: '16928',
    city: 'Mesendorf'
  },
  {
    postalCode: '16928',
    city: 'Neu Kemnitz'
  },
  {
    postalCode: '16928',
    city: 'Neu Krüssow'
  },
  {
    postalCode: '16928',
    city: 'Neu Redlin'
  },
  {
    postalCode: '16928',
    city: 'Neudorf'
  },
  {
    postalCode: '16928',
    city: 'Neuhausen'
  },
  {
    postalCode: '16928',
    city: 'Neuhof'
  },
  {
    postalCode: '16928',
    city: 'Preddöhl'
  },
  {
    postalCode: '16928',
    city: 'Pritzwalk'
  },
  {
    postalCode: '16928',
    city: 'Rapshagen'
  },
  {
    postalCode: '16928',
    city: 'Reckenthin'
  },
  {
    postalCode: '16928',
    city: 'Rohlsdorf'
  },
  {
    postalCode: '16928',
    city: 'Rosenwinkel'
  },
  {
    postalCode: '16928',
    city: 'Sadenbeck'
  },
  {
    postalCode: '16928',
    city: 'Sarnow'
  },
  {
    postalCode: '16928',
    city: 'Schönebeck'
  },
  {
    postalCode: '16928',
    city: 'Schönhagen'
  },
  {
    postalCode: '16928',
    city: 'Schönhagener Mühle'
  },
  {
    postalCode: '16928',
    city: 'Seefeld'
  },
  {
    postalCode: '16928',
    city: 'Steffenshagen'
  },
  {
    postalCode: '16928',
    city: 'Streckenthin'
  },
  {
    postalCode: '16928',
    city: 'Tüchen'
  },
  {
    postalCode: '16928',
    city: 'Vettin'
  },
  {
    postalCode: '16928',
    city: 'Wilmersdorf'
  },
  {
    postalCode: '16945',
    city: 'Buckow'
  },
  {
    postalCode: '16945',
    city: 'Frehne'
  },
  {
    postalCode: '16945',
    city: 'Grabow'
  },
  {
    postalCode: '16945',
    city: 'Halenbeck'
  },
  {
    postalCode: '16945',
    city: 'Krempendorf'
  },
  {
    postalCode: '16945',
    city: 'Meyenburg'
  },
  {
    postalCode: '16945',
    city: 'Schmolde'
  },
  {
    postalCode: '16945',
    city: 'Stepenitz'
  },
  {
    postalCode: '16945',
    city: 'Warnsdorf'
  },
  {
    postalCode: '16949',
    city: 'Hülsebeck'
  },
  {
    postalCode: '16949',
    city: 'Jännersdorf'
  },
  {
    postalCode: '16949',
    city: 'Putlitz'
  },
  {
    postalCode: '16949',
    city: 'Triglitz'
  },
  {
    postalCode: '17033',
    city: 'Neubrandenburg'
  },
  {
    postalCode: '17034',
    city: 'Neubrandenburg'
  },
  {
    postalCode: '17036',
    city: 'Neubrandenburg'
  },
  {
    postalCode: '17039',
    city: 'Beseritz'
  },
  {
    postalCode: '17039',
    city: 'Blankenhof'
  },
  {
    postalCode: '17039',
    city: 'Brunn'
  },
  {
    postalCode: '17039',
    city: 'Neddemin'
  },
  {
    postalCode: '17039',
    city: 'Neuenkirchen'
  },
  {
    postalCode: '17039',
    city: 'Neverin'
  },
  {
    postalCode: '17039',
    city: 'Sponholz'
  },
  {
    postalCode: '17039',
    city: 'Staven'
  },
  {
    postalCode: '17039',
    city: 'Trollenhagen'
  },
  {
    postalCode: '17039',
    city: 'Warlin'
  },
  {
    postalCode: '17039',
    city: 'Woggersin'
  },
  {
    postalCode: '17039',
    city: 'Wulkenzin'
  },
  {
    postalCode: '17039',
    city: 'Zirzow'
  },
  {
    postalCode: '17087',
    city: 'Altentreptow'
  },
  {
    postalCode: '17089',
    city: 'Bartow'
  },
  {
    postalCode: '17089',
    city: 'Breest'
  },
  {
    postalCode: '17089',
    city: 'Burow'
  },
  {
    postalCode: '17089',
    city: 'Gnevkow'
  },
  {
    postalCode: '17089',
    city: 'Golchen'
  },
  {
    postalCode: '17089',
    city: 'Grapzow'
  },
  {
    postalCode: '17089',
    city: 'Grischow'
  },
  {
    postalCode: '17089',
    city: 'Gültz'
  },
  {
    postalCode: '17089',
    city: 'Siedenbollentin'
  },
  {
    postalCode: '17089',
    city: 'Werder'
  },
  {
    postalCode: '17091',
    city: 'Altenhagen'
  },
  {
    postalCode: '17091',
    city: 'Breesen'
  },
  {
    postalCode: '17091',
    city: 'Groß Teetzleben'
  },
  {
    postalCode: '17091',
    city: 'Knorrendorf'
  },
  {
    postalCode: '17091',
    city: 'Kriesow'
  },
  {
    postalCode: '17091',
    city: 'Mölln'
  },
  {
    postalCode: '17091',
    city: 'Pripsleben'
  },
  {
    postalCode: '17091',
    city: 'Röckwitz'
  },
  {
    postalCode: '17091',
    city: 'Rosenow'
  },
  {
    postalCode: '17091',
    city: 'Tützpatz'
  },
  {
    postalCode: '17091',
    city: 'Wildberg'
  },
  {
    postalCode: '17091',
    city: 'Wolde'
  },
  {
    postalCode: '17091',
    city: 'Wrodow'
  },
  {
    postalCode: '17094',
    city: 'Burg Stargard'
  },
  {
    postalCode: '17094',
    city: 'Cammin'
  },
  {
    postalCode: '17094',
    city: 'Cölpin'
  },
  {
    postalCode: '17094',
    city: 'Groß Nemerow'
  },
  {
    postalCode: '17094',
    city: 'Holldorf'
  },
  {
    postalCode: '17094',
    city: 'Pragsdorf'
  },
  {
    postalCode: '17094',
    city: 'Teschendorf'
  },
  {
    postalCode: '17098',
    city: 'Friedland'
  },
  {
    postalCode: '17099',
    city: 'Brohm'
  },
  {
    postalCode: '17099',
    city: 'Eichhorst'
  },
  {
    postalCode: '17099',
    city: 'Fleethof'
  },
  {
    postalCode: '17099',
    city: 'Genzkow'
  },
  {
    postalCode: '17099',
    city: 'Glienke'
  },
  {
    postalCode: '17099',
    city: 'Jatzke'
  },
  {
    postalCode: '17099',
    city: 'Kotelow'
  },
  {
    postalCode: '17099',
    city: 'Sadelkow'
  },
  {
    postalCode: '17099',
    city: 'Salow'
  },
  {
    postalCode: '17099',
    city: 'Schwanbeck'
  },
  {
    postalCode: '17099',
    city: 'Schwichtenberg'
  },
  {
    postalCode: '17109',
    city: 'Demmin'
  },
  {
    postalCode: '17111',
    city: 'Beestland'
  },
  {
    postalCode: '17111',
    city: 'Beggerow'
  },
  {
    postalCode: '17111',
    city: 'Borrentin'
  },
  {
    postalCode: '17111',
    city: 'Gnevezow'
  },
  {
    postalCode: '17111',
    city: 'Hohenbollentin'
  },
  {
    postalCode: '17111',
    city: 'Hohenbrünzow'
  },
  {
    postalCode: '17111',
    city: 'Hohenmocker'
  },
  {
    postalCode: '17111',
    city: 'Kletzin'
  },
  {
    postalCode: '17111',
    city: 'Lindenberg'
  },
  {
    postalCode: '17111',
    city: 'Meesiger'
  },
  {
    postalCode: '17111',
    city: 'Metschow'
  },
  {
    postalCode: '17111',
    city: 'Neu-Kentzlin'
  },
  {
    postalCode: '17111',
    city: 'Nossendorf'
  },
  {
    postalCode: '17111',
    city: 'Quitzerow'
  },
  {
    postalCode: '17111',
    city: 'Sanzkow'
  },
  {
    postalCode: '17111',
    city: 'Sarow'
  },
  {
    postalCode: '17111',
    city: 'Schönfeld'
  },
  {
    postalCode: '17111',
    city: 'Siedenbrünzow'
  },
  {
    postalCode: '17111',
    city: 'Sommersdorf'
  },
  {
    postalCode: '17111',
    city: 'Teusin'
  },
  {
    postalCode: '17111',
    city: 'Upost'
  },
  {
    postalCode: '17111',
    city: 'Utzedel'
  },
  {
    postalCode: '17111',
    city: 'Verchen'
  },
  {
    postalCode: '17111',
    city: 'Warrenzin'
  },
  {
    postalCode: '17111',
    city: 'Wotenick'
  },
  {
    postalCode: '17121',
    city: 'Damerow'
  },
  {
    postalCode: '17121',
    city: 'Düvier'
  },
  {
    postalCode: '17121',
    city: 'Görmin'
  },
  {
    postalCode: '17121',
    city: 'Loitz'
  },
  {
    postalCode: '17121',
    city: 'Sassen'
  },
  {
    postalCode: '17121',
    city: 'Trantow'
  },
  {
    postalCode: '17121',
    city: 'Vorbein'
  },
  {
    postalCode: '17121',
    city: 'Wüstenfelde'
  },
  {
    postalCode: '17126',
    city: 'Jarmen'
  },
  {
    postalCode: '17129',
    city: 'Alt Tellin'
  },
  {
    postalCode: '17129',
    city: 'Bentzin'
  },
  {
    postalCode: '17129',
    city: 'Daberkow'
  },
  {
    postalCode: '17129',
    city: 'Kruckow'
  },
  {
    postalCode: '17129',
    city: 'Plötz'
  },
  {
    postalCode: '17129',
    city: 'Schmarsow'
  },
  {
    postalCode: '17129',
    city: 'Tutow'
  },
  {
    postalCode: '17129',
    city: 'Völschow'
  },
  {
    postalCode: '17139',
    city: 'Basedow'
  },
  {
    postalCode: '17139',
    city: 'Duckow'
  },
  {
    postalCode: '17139',
    city: 'Faulenrost'
  },
  {
    postalCode: '17139',
    city: 'Gielow'
  },
  {
    postalCode: '17139',
    city: 'Gorschendorf'
  },
  {
    postalCode: '17139',
    city: 'Kummerow'
  },
  {
    postalCode: '17139',
    city: 'Langwitz'
  },
  {
    postalCode: '17139',
    city: 'Malchin'
  },
  {
    postalCode: '17139',
    city: 'Remplin'
  },
  {
    postalCode: '17139',
    city: 'Schwinkendorf'
  },
  {
    postalCode: '17153',
    city: 'Bredenfelde'
  },
  {
    postalCode: '17153',
    city: 'Briggow'
  },
  {
    postalCode: '17153',
    city: 'Galenbeck'
  },
  {
    postalCode: '17153',
    city: 'Grammentin'
  },
  {
    postalCode: '17153',
    city: 'Gülzow'
  },
  {
    postalCode: '17153',
    city: 'Ivenack'
  },
  {
    postalCode: '17153',
    city: 'Jürgenstorf'
  },
  {
    postalCode: '17153',
    city: 'Kittendorf'
  },
  {
    postalCode: '17153',
    city: 'Ritzerow'
  },
  {
    postalCode: '17153',
    city: 'Stavenhagen'
  },
  {
    postalCode: '17153',
    city: 'Wackerow'
  },
  {
    postalCode: '17153',
    city: 'Zettemin'
  },
  {
    postalCode: '17154',
    city: 'Neukalen'
  },
  {
    postalCode: '17159',
    city: 'Brudersdorf'
  },
  {
    postalCode: '17159',
    city: 'Dargun'
  },
  {
    postalCode: '17159',
    city: 'Stubbendorf'
  },
  {
    postalCode: '17159',
    city: 'Wagun'
  },
  {
    postalCode: '17159',
    city: 'Zarnekow'
  },
  {
    postalCode: '17166',
    city: 'Alt-Sührkow'
  },
  {
    postalCode: '17166',
    city: 'Bristow'
  },
  {
    postalCode: '17166',
    city: 'Bülow'
  },
  {
    postalCode: '17166',
    city: 'Dahmen'
  },
  {
    postalCode: '17166',
    city: 'Dalkendorf'
  },
  {
    postalCode: '17166',
    city: 'Groß Roge'
  },
  {
    postalCode: '17166',
    city: 'Großen-Luckow'
  },
  {
    postalCode: '17166',
    city: 'Groß-Wokern'
  },
  {
    postalCode: '17166',
    city: 'Hohen-Demzin'
  },
  {
    postalCode: '17166',
    city: 'Neu-Ziddorf'
  },
  {
    postalCode: '17166',
    city: 'Teterow'
  },
  {
    postalCode: '17168',
    city: 'Groß Wüstenfelde'
  },
  {
    postalCode: '17168',
    city: 'Jördenstorf'
  },
  {
    postalCode: '17168',
    city: 'Lelkendorf'
  },
  {
    postalCode: '17168',
    city: 'Levitzow'
  },
  {
    postalCode: '17168',
    city: 'Matgendorf'
  },
  {
    postalCode: '17168',
    city: 'Neu Heinde'
  },
  {
    postalCode: '17168',
    city: 'Poggelow'
  },
  {
    postalCode: '17168',
    city: 'Prebberede'
  },
  {
    postalCode: '17168',
    city: 'Remlin'
  },
  {
    postalCode: '17168',
    city: 'Rey'
  },
  {
    postalCode: '17168',
    city: 'Sukow-Marienhof'
  },
  {
    postalCode: '17168',
    city: 'Thürkow'
  },
  {
    postalCode: '17168',
    city: 'Warnkenhagen'
  },
  {
    postalCode: '17179',
    city: 'Altkalen'
  },
  {
    postalCode: '17179',
    city: 'Behren-Lübchin'
  },
  {
    postalCode: '17179',
    city: 'Boddin'
  },
  {
    postalCode: '17179',
    city: 'Finkenthal'
  },
  {
    postalCode: '17179',
    city: 'Gnoien'
  },
  {
    postalCode: '17179',
    city: 'Groß Nieköhr'
  },
  {
    postalCode: '17179',
    city: 'Kleverhof'
  },
  {
    postalCode: '17179',
    city: 'Lühburg'
  },
  {
    postalCode: '17179',
    city: 'Walkendorf'
  },
  {
    postalCode: '17179',
    city: 'Wasdow'
  },
  {
    postalCode: '17192',
    city: 'Alt Schönau'
  },
  {
    postalCode: '17192',
    city: 'Groß Dratow'
  },
  {
    postalCode: '17192',
    city: 'Groß Gievitz'
  },
  {
    postalCode: '17192',
    city: 'Groß Plasten'
  },
  {
    postalCode: '17192',
    city: 'Kargow'
  },
  {
    postalCode: '17192',
    city: 'Klink'
  },
  {
    postalCode: '17192',
    city: 'Lansen'
  },
  {
    postalCode: '17192',
    city: 'Levenstorf'
  },
  {
    postalCode: '17192',
    city: 'Neu Schloen'
  },
  {
    postalCode: '17192',
    city: 'Schloen'
  },
  {
    postalCode: '17192',
    city: 'Schwarzenhof bei Groß Gievitz'
  },
  {
    postalCode: '17192',
    city: 'Torgelow'
  },
  {
    postalCode: '17192',
    city: 'Varchentin'
  },
  {
    postalCode: '17192',
    city: 'Waren'
  },
  {
    postalCode: '17194',
    city: 'Beckenkrug'
  },
  {
    postalCode: '17194',
    city: 'Grabowhöfe'
  },
  {
    postalCode: '17194',
    city: 'Hinrichshagen'
  },
  {
    postalCode: '17194',
    city: 'Hohen Wangelin'
  },
  {
    postalCode: '17194',
    city: 'Jabel'
  },
  {
    postalCode: '17194',
    city: 'Klocksin'
  },
  {
    postalCode: '17194',
    city: 'Lupendorf'
  },
  {
    postalCode: '17194',
    city: 'Moltzow'
  },
  {
    postalCode: '17194',
    city: 'Neu Gaarz'
  },
  {
    postalCode: '17194',
    city: 'Vielist'
  },
  {
    postalCode: '17194',
    city: 'Vollrathsruhe'
  },
  {
    postalCode: '17207',
    city: 'Bollewick'
  },
  {
    postalCode: '17207',
    city: 'Gotthun'
  },
  {
    postalCode: '17207',
    city: 'Groß Kelle'
  },
  {
    postalCode: '17207',
    city: 'Kambs'
  },
  {
    postalCode: '17207',
    city: 'Ludorf'
  },
  {
    postalCode: '17207',
    city: 'Röbel'
  },
  {
    postalCode: '17207',
    city: 'Zierzow'
  },
  {
    postalCode: '17209',
    city: 'Altenhof'
  },
  {
    postalCode: '17209',
    city: 'Below'
  },
  {
    postalCode: '17209',
    city: 'Buchholz'
  },
  {
    postalCode: '17209',
    city: 'Bütow'
  },
  {
    postalCode: '17209',
    city: 'Fincken'
  },
  {
    postalCode: '17209',
    city: 'Grabow'
  },
  {
    postalCode: '17209',
    city: 'Jaebetz'
  },
  {
    postalCode: '17209',
    city: 'Kieve'
  },
  {
    postalCode: '17209',
    city: 'Leizen'
  },
  {
    postalCode: '17209',
    city: 'Massow'
  },
  {
    postalCode: '17209',
    city: 'Melz'
  },
  {
    postalCode: '17209',
    city: 'Minzow'
  },
  {
    postalCode: '17209',
    city: 'Priborn'
  },
  {
    postalCode: '17209',
    city: 'Rogeez'
  },
  {
    postalCode: '17209',
    city: 'Satow'
  },
  {
    postalCode: '17209',
    city: 'Sietow'
  },
  {
    postalCode: '17209',
    city: 'Stuer'
  },
  {
    postalCode: '17209',
    city: 'Vipperow'
  },
  {
    postalCode: '17209',
    city: 'Walow'
  },
  {
    postalCode: '17209',
    city: 'Wildkuhl'
  },
  {
    postalCode: '17209',
    city: 'Wredenhagen'
  },
  {
    postalCode: '17209',
    city: 'Zepkow'
  },
  {
    postalCode: '17209',
    city: 'Zislow'
  },
  {
    postalCode: '17213',
    city: 'Adamshoffnung'
  },
  {
    postalCode: '17213',
    city: 'Göhren-Lebbin'
  },
  {
    postalCode: '17213',
    city: 'Grüssow'
  },
  {
    postalCode: '17213',
    city: 'Kogel'
  },
  {
    postalCode: '17213',
    city: 'Lexow'
  },
  {
    postalCode: '17213',
    city: 'Malchow'
  },
  {
    postalCode: '17213',
    city: 'Penkow'
  },
  {
    postalCode: '17214',
    city: 'Alt Schwerin'
  },
  {
    postalCode: '17214',
    city: 'Nossentiner Hütte'
  },
  {
    postalCode: '17214',
    city: 'Silz'
  },
  {
    postalCode: '17217',
    city: 'Alt Rehse'
  },
  {
    postalCode: '17217',
    city: 'Groß Vielen'
  },
  {
    postalCode: '17217',
    city: 'Klein Lukow'
  },
  {
    postalCode: '17217',
    city: 'Krukow'
  },
  {
    postalCode: '17217',
    city: 'Lapitz'
  },
  {
    postalCode: '17217',
    city: 'Mallin'
  },
  {
    postalCode: '17217',
    city: 'Mollenstorf'
  },
  {
    postalCode: '17217',
    city: 'Penzlin'
  },
  {
    postalCode: '17217',
    city: 'Puchow'
  },
  {
    postalCode: '17219',
    city: 'Ankershagen'
  },
  {
    postalCode: '17219',
    city: 'Carolinenhof'
  },
  {
    postalCode: '17219',
    city: 'Groß Flotow'
  },
  {
    postalCode: '17219',
    city: 'Klockow'
  },
  {
    postalCode: '17219',
    city: 'Marihn'
  },
  {
    postalCode: '17219',
    city: 'Möllenhagen'
  },
  {
    postalCode: '17235',
    city: 'Neustrelitz'
  },
  {
    postalCode: '17237',
    city: 'Babke'
  },
  {
    postalCode: '17237',
    city: 'Blankenförde'
  },
  {
    postalCode: '17237',
    city: 'Blankensee'
  },
  {
    postalCode: '17237',
    city: 'Blumenholz'
  },
  {
    postalCode: '17237',
    city: 'Carpin'
  },
  {
    postalCode: '17237',
    city: 'Godendorf'
  },
  {
    postalCode: '17237',
    city: 'Grünow'
  },
  {
    postalCode: '17237',
    city: 'Hohenzieritz'
  },
  {
    postalCode: '17237',
    city: 'Klein Vielen'
  },
  {
    postalCode: '17237',
    city: 'Koldenhof'
  },
  {
    postalCode: '17237',
    city: 'Kratzeburg'
  },
  {
    postalCode: '17237',
    city: 'Möllenbeck'
  },
  {
    postalCode: '17237',
    city: 'Rödlin-Thurow'
  },
  {
    postalCode: '17237',
    city: 'Userin'
  },
  {
    postalCode: '17237',
    city: 'Watzkendorf'
  },
  {
    postalCode: '17237',
    city: 'Wokuhl-Dabelow'
  },
  {
    postalCode: '17237',
    city: 'Zartwitz'
  },
  {
    postalCode: '17248',
    city: 'Lärz'
  },
  {
    postalCode: '17248',
    city: 'Rechlin'
  },
  {
    postalCode: '17252',
    city: 'Diemitz'
  },
  {
    postalCode: '17252',
    city: 'Mirow'
  },
  {
    postalCode: '17252',
    city: 'Roggentin'
  },
  {
    postalCode: '17252',
    city: 'Schwarz'
  },
  {
    postalCode: '17252',
    city: 'Siedenheide'
  },
  {
    postalCode: '17252',
    city: 'Zirtow'
  },
  {
    postalCode: '17255',
    city: 'Priepert'
  },
  {
    postalCode: '17255',
    city: 'Strasen'
  },
  {
    postalCode: '17255',
    city: 'Wesenberg'
  },
  {
    postalCode: '17255',
    city: 'Wustrow'
  },
  {
    postalCode: '17258',
    city: 'Beenz'
  },
  {
    postalCode: '17258',
    city: 'Conow'
  },
  {
    postalCode: '17258',
    city: 'Dolgen'
  },
  {
    postalCode: '17258',
    city: 'Feldberg'
  },
  {
    postalCode: '17258',
    city: 'Lüttenhagen'
  },
  {
    postalCode: '17259',
    city: 'Lichtenberg'
  },
  {
    postalCode: '17259',
    city: 'Tornowhof'
  },
  {
    postalCode: '17259',
    city: 'Triepkendorf'
  },
  {
    postalCode: '17268',
    city: 'Beutel'
  },
  {
    postalCode: '17268',
    city: 'Boitzenburger Land'
  },
  {
    postalCode: '17268',
    city: 'Densow'
  },
  {
    postalCode: '17268',
    city: 'Flieth'
  },
  {
    postalCode: '17268',
    city: 'Friedenfelde'
  },
  {
    postalCode: '17268',
    city: 'Gandenitz'
  },
  {
    postalCode: '17268',
    city: 'Gerswalde'
  },
  {
    postalCode: '17268',
    city: 'Gollin'
  },
  {
    postalCode: '17268',
    city: 'Groß Dölln'
  },
  {
    postalCode: '17268',
    city: 'Groß Fredenwalde'
  },
  {
    postalCode: '17268',
    city: 'Groß Kölpin'
  },
  {
    postalCode: '17268',
    city: 'Grunewald'
  },
  {
    postalCode: '17268',
    city: 'Hammelspring'
  },
  {
    postalCode: '17268',
    city: 'Herzfelde'
  },
  {
    postalCode: '17268',
    city: 'Kaakstedt'
  },
  {
    postalCode: '17268',
    city: 'Klosterwalde'
  },
  {
    postalCode: '17268',
    city: 'Krohnhorst'
  },
  {
    postalCode: '17268',
    city: 'Milmersdorf'
  },
  {
    postalCode: '17268',
    city: 'Mittenwalde'
  },
  {
    postalCode: '17268',
    city: 'Petznick'
  },
  {
    postalCode: '17268',
    city: 'Ringenwalde'
  },
  {
    postalCode: '17268',
    city: 'Röddelin'
  },
  {
    postalCode: '17268',
    city: 'Stegelitz'
  },
  {
    postalCode: '17268',
    city: 'Storkow'
  },
  {
    postalCode: '17268',
    city: 'Tackmannshof'
  },
  {
    postalCode: '17268',
    city: 'Temmen'
  },
  {
    postalCode: '17268',
    city: 'Templin'
  },
  {
    postalCode: '17268',
    city: 'Vietmannsdorf'
  },
  {
    postalCode: '17279',
    city: 'Lychen'
  },
  {
    postalCode: '17279',
    city: 'Retzow'
  },
  {
    postalCode: '17279',
    city: 'Rutenberg'
  },
  {
    postalCode: '17291',
    city: 'Albrechtshof'
  },
  {
    postalCode: '17291',
    city: 'Arendsee'
  },
  {
    postalCode: '17291',
    city: 'Bertikow'
  },
  {
    postalCode: '17291',
    city: 'Bietikow'
  },
  {
    postalCode: '17291',
    city: 'Blankenburg'
  },
  {
    postalCode: '17291',
    city: 'Blindow'
  },
  {
    postalCode: '17291',
    city: 'Carmzow'
  },
  {
    postalCode: '17291',
    city: 'Damme'
  },
  {
    postalCode: '17291',
    city: 'Dauer'
  },
  {
    postalCode: '17291',
    city: 'Dedelow'
  },
  {
    postalCode: '17291',
    city: 'Drense'
  },
  {
    postalCode: '17291',
    city: 'Eickstedt'
  },
  {
    postalCode: '17291',
    city: 'Falkenhagen'
  },
  {
    postalCode: '17291',
    city: 'Falkenwalde'
  },
  {
    postalCode: '17291',
    city: 'Ferdinandshorst'
  },
  {
    postalCode: '17291',
    city: 'Fürstenwerder'
  },
  {
    postalCode: '17291',
    city: 'Gollmitz'
  },
  {
    postalCode: '17291',
    city: 'Göritz'
  },
  {
    postalCode: '17291',
    city: 'Gramzow'
  },
  {
    postalCode: '17291',
    city: 'Grenz'
  },
  {
    postalCode: '17291',
    city: 'Grünow'
  },
  {
    postalCode: '17291',
    city: 'Güstow'
  },
  {
    postalCode: '17291',
    city: 'Hohengüstow'
  },
  {
    postalCode: '17291',
    city: 'Holzendorf'
  },
  {
    postalCode: '17291',
    city: 'Kleinow'
  },
  {
    postalCode: '17291',
    city: 'Klinkow'
  },
  {
    postalCode: '17291',
    city: 'Kraatz'
  },
  {
    postalCode: '17291',
    city: 'Ludwigsburg'
  },
  {
    postalCode: '17291',
    city: 'Lützlow'
  },
  {
    postalCode: '17291',
    city: 'Meichow'
  },
  {
    postalCode: '17291',
    city: 'Naugarten'
  },
  {
    postalCode: '17291',
    city: 'Parmen-Weggun'
  },
  {
    postalCode: '17291',
    city: 'Potzlow'
  },
  {
    postalCode: '17291',
    city: 'Prenzlau'
  },
  {
    postalCode: '17291',
    city: 'Röpersdorf'
  },
  {
    postalCode: '17291',
    city: 'Schapow'
  },
  {
    postalCode: '17291',
    city: 'Schenkenberg'
  },
  {
    postalCode: '17291',
    city: 'Schmölln'
  },
  {
    postalCode: '17291',
    city: 'Schönermark'
  },
  {
    postalCode: '17291',
    city: 'Schönfeld'
  },
  {
    postalCode: '17291',
    city: 'Schönwerder'
  },
  {
    postalCode: '17291',
    city: 'Schwaneberg'
  },
  {
    postalCode: '17291',
    city: 'Seehausen'
  },
  {
    postalCode: '17291',
    city: 'Sternhagen'
  },
  {
    postalCode: '17291',
    city: 'Wallmow'
  },
  {
    postalCode: '17291',
    city: 'Warnitz'
  },
  {
    postalCode: '17291',
    city: 'Weselitz'
  },
  {
    postalCode: '17291',
    city: 'Wollin'
  },
  {
    postalCode: '17291',
    city: 'Ziemkendorf'
  },
  {
    postalCode: '17309',
    city: 'Belling'
  },
  {
    postalCode: '17309',
    city: 'Brietzig'
  },
  {
    postalCode: '17309',
    city: 'Damerow'
  },
  {
    postalCode: '17309',
    city: 'Fahrenwalde'
  },
  {
    postalCode: '17309',
    city: 'Jatznick'
  },
  {
    postalCode: '17309',
    city: 'Koblentz'
  },
  {
    postalCode: '17309',
    city: 'Krugsdorf'
  },
  {
    postalCode: '17309',
    city: 'Marienthal'
  },
  {
    postalCode: '17309',
    city: 'Nieden'
  },
  {
    postalCode: '17309',
    city: 'Papendorf'
  },
  {
    postalCode: '17309',
    city: 'Pasewalk'
  },
  {
    postalCode: '17309',
    city: 'Polzow'
  },
  {
    postalCode: '17309',
    city: 'Rollwitz'
  },
  {
    postalCode: '17309',
    city: 'Schönwalde'
  },
  {
    postalCode: '17309',
    city: 'Viereck'
  },
  {
    postalCode: '17309',
    city: 'Zerrenthin'
  },
  {
    postalCode: '17309',
    city: 'Züsedom'
  },
  {
    postalCode: '17321',
    city: 'Bergholz'
  },
  {
    postalCode: '17321',
    city: 'Löcknitz'
  },
  {
    postalCode: '17321',
    city: 'Plöwen'
  },
  {
    postalCode: '17321',
    city: 'Ramin'
  },
  {
    postalCode: '17321',
    city: 'Rothenklempenow'
  },
  {
    postalCode: '17322',
    city: 'Bismark'
  },
  {
    postalCode: '17322',
    city: 'Blankensee'
  },
  {
    postalCode: '17322',
    city: 'Boock'
  },
  {
    postalCode: '17322',
    city: 'Glasow'
  },
  {
    postalCode: '17322',
    city: 'Grambow'
  },
  {
    postalCode: '17322',
    city: 'Lebehn'
  },
  {
    postalCode: '17322',
    city: 'Mewegen'
  },
  {
    postalCode: '17322',
    city: 'Pampow'
  },
  {
    postalCode: '17322',
    city: 'Rossow'
  },
  {
    postalCode: '17326',
    city: 'Bagemühl'
  },
  {
    postalCode: '17326',
    city: 'Brüssow'
  },
  {
    postalCode: '17326',
    city: 'Grünberg'
  },
  {
    postalCode: '17326',
    city: 'Menkin'
  },
  {
    postalCode: '17326',
    city: 'Woddow'
  },
  {
    postalCode: '17326',
    city: 'Wollschow'
  },
  {
    postalCode: '17328',
    city: 'Battinsthal'
  },
  {
    postalCode: '17328',
    city: 'Grünz'
  },
  {
    postalCode: '17328',
    city: 'Penkun'
  },
  {
    postalCode: '17328',
    city: 'Schuckmannshöhe'
  },
  {
    postalCode: '17328',
    city: 'Sommersdorf'
  },
  {
    postalCode: '17328',
    city: 'Storkow'
  },
  {
    postalCode: '17328',
    city: 'Wollin'
  },
  {
    postalCode: '17329',
    city: 'Krackow'
  },
  {
    postalCode: '17329',
    city: 'Ladenthin'
  },
  {
    postalCode: '17329',
    city: 'Nadrensee'
  },
  {
    postalCode: '17335',
    city: 'Rohrkrug'
  },
  {
    postalCode: '17335',
    city: 'Strasburg'
  },
  {
    postalCode: '17337',
    city: 'Blumenhagen'
  },
  {
    postalCode: '17337',
    city: 'Friedrichshof'
  },
  {
    postalCode: '17337',
    city: 'Galenbeck'
  },
  {
    postalCode: '17337',
    city: 'Groß Luckow'
  },
  {
    postalCode: '17337',
    city: 'Groß Spiegelberg'
  },
  {
    postalCode: '17337',
    city: 'Johannisberg'
  },
  {
    postalCode: '17337',
    city: 'Klein Luckow'
  },
  {
    postalCode: '17337',
    city: 'Schönhausen'
  },
  {
    postalCode: '17337',
    city: 'Uckerland'
  },
  {
    postalCode: '17337',
    city: 'Wittenborn'
  },
  {
    postalCode: '17348',
    city: 'Göhren'
  },
  {
    postalCode: '17348',
    city: 'Groß Daberkow'
  },
  {
    postalCode: '17348',
    city: 'Mildenitz'
  },
  {
    postalCode: '17348',
    city: 'Neu Käbelich'
  },
  {
    postalCode: '17348',
    city: 'Petersdorf'
  },
  {
    postalCode: '17348',
    city: 'Woldegk'
  },
  {
    postalCode: '17349',
    city: 'Groß Miltzow'
  },
  {
    postalCode: '17349',
    city: 'Helpt'
  },
  {
    postalCode: '17349',
    city: 'Kublank'
  },
  {
    postalCode: '17349',
    city: 'Lindetal'
  },
  {
    postalCode: '17349',
    city: 'Neetzka'
  },
  {
    postalCode: '17349',
    city: 'Schönbeck'
  },
  {
    postalCode: '17349',
    city: 'Voigtsdorf'
  },
  {
    postalCode: '17358',
    city: 'Hammer'
  },
  {
    postalCode: '17358',
    city: 'Torgelow'
  },
  {
    postalCode: '17358',
    city: 'Torgelow-Holländerei'
  },
  {
    postalCode: '17367',
    city: 'Eggesin'
  },
  {
    postalCode: '17373',
    city: 'Ueckermünde'
  },
  {
    postalCode: '17375',
    city: 'Ahlbeck'
  },
  {
    postalCode: '17375',
    city: 'Altwarp'
  },
  {
    postalCode: '17375',
    city: 'Grambin'
  },
  {
    postalCode: '17375',
    city: 'Hintersee'
  },
  {
    postalCode: '17375',
    city: 'Hoppenwalde'
  },
  {
    postalCode: '17375',
    city: 'Leopoldshagen'
  },
  {
    postalCode: '17375',
    city: 'Liepgarten'
  },
  {
    postalCode: '17375',
    city: 'Luckow'
  },
  {
    postalCode: '17375',
    city: 'Meiersberg'
  },
  {
    postalCode: '17375',
    city: 'Mönkebude'
  },
  {
    postalCode: '17375',
    city: 'Rieth'
  },
  {
    postalCode: '17375',
    city: 'Vogelsang-Warsin'
  },
  {
    postalCode: '17379',
    city: 'Altwigshagen'
  },
  {
    postalCode: '17379',
    city: 'Ferdinandshof'
  },
  {
    postalCode: '17379',
    city: 'Heinrichsruh'
  },
  {
    postalCode: '17379',
    city: 'Heinrichswalde'
  },
  {
    postalCode: '17379',
    city: 'Lübs'
  },
  {
    postalCode: '17379',
    city: 'Neuendorf A'
  },
  {
    postalCode: '17379',
    city: 'Rothemühl'
  },
  {
    postalCode: '17379',
    city: 'Wietstock'
  },
  {
    postalCode: '17379',
    city: 'Wilhelmsburg'
  },
  {
    postalCode: '17389',
    city: 'Anklam'
  },
  {
    postalCode: '17390',
    city: 'Groß Polzin'
  },
  {
    postalCode: '17390',
    city: 'Klein Bünzow'
  },
  {
    postalCode: '17390',
    city: 'Murchin'
  },
  {
    postalCode: '17390',
    city: 'Rubkow'
  },
  {
    postalCode: '17390',
    city: 'Schmatzin'
  },
  {
    postalCode: '17390',
    city: 'Ziethen'
  },
  {
    postalCode: '17391',
    city: 'Iven'
  },
  {
    postalCode: '17391',
    city: 'Krien'
  },
  {
    postalCode: '17391',
    city: 'Krusenfelde'
  },
  {
    postalCode: '17391',
    city: 'Liepen'
  },
  {
    postalCode: '17391',
    city: 'Medow'
  },
  {
    postalCode: '17391',
    city: 'Neetzow'
  },
  {
    postalCode: '17391',
    city: 'Nerdin'
  },
  {
    postalCode: '17391',
    city: 'Neuendorf B'
  },
  {
    postalCode: '17391',
    city: 'Postlow'
  },
  {
    postalCode: '17391',
    city: 'Stolpe'
  },
  {
    postalCode: '17392',
    city: 'Blesewitz'
  },
  {
    postalCode: '17392',
    city: 'Boldekow'
  },
  {
    postalCode: '17392',
    city: 'Butzow'
  },
  {
    postalCode: '17392',
    city: 'Drewelow'
  },
  {
    postalCode: '17392',
    city: 'Japenzin'
  },
  {
    postalCode: '17392',
    city: 'Neuenkirchen'
  },
  {
    postalCode: '17392',
    city: 'Pelsin'
  },
  {
    postalCode: '17392',
    city: 'Putzar'
  },
  {
    postalCode: '17392',
    city: 'Sarnow'
  },
  {
    postalCode: '17392',
    city: 'Spantekow'
  },
  {
    postalCode: '17398',
    city: 'Bargischow'
  },
  {
    postalCode: '17398',
    city: 'Bugewitz'
  },
  {
    postalCode: '17398',
    city: 'Ducherow'
  },
  {
    postalCode: '17398',
    city: 'Löwitz'
  },
  {
    postalCode: '17398',
    city: 'Neu Kosenow'
  },
  {
    postalCode: '17398',
    city: 'Rathebur'
  },
  {
    postalCode: '17398',
    city: 'Rossin'
  },
  {
    postalCode: '17398',
    city: 'Schwerinsburg'
  },
  {
    postalCode: '17406',
    city: 'Morgenitz'
  },
  {
    postalCode: '17406',
    city: 'Rankwitz'
  },
  {
    postalCode: '17406',
    city: 'Stolpe'
  },
  {
    postalCode: '17406',
    city: 'Usedom'
  },
  {
    postalCode: '17419',
    city: 'Dargen'
  },
  {
    postalCode: '17419',
    city: 'Garz'
  },
  {
    postalCode: '17419',
    city: 'Kamminke'
  },
  {
    postalCode: '17419',
    city: 'Korswandt'
  },
  {
    postalCode: '17419',
    city: 'Seebad Ahlbeck'
  },
  {
    postalCode: '17419',
    city: 'Zirchow'
  },
  {
    postalCode: '17424',
    city: 'Seebad Heringsdorf'
  },
  {
    postalCode: '17429',
    city: 'Benz'
  },
  {
    postalCode: '17429',
    city: 'Katschow'
  },
  {
    postalCode: '17429',
    city: 'Mellenthin'
  },
  {
    postalCode: '17429',
    city: 'Neppermin'
  },
  {
    postalCode: '17429',
    city: 'Pudagla'
  },
  {
    postalCode: '17429',
    city: 'Seebad Bansin'
  },
  {
    postalCode: '17438',
    city: 'Wolgast'
  },
  {
    postalCode: '17440',
    city: 'Buddenhagen'
  },
  {
    postalCode: '17440',
    city: 'Buggenhagen'
  },
  {
    postalCode: '17440',
    city: 'Groß Ernsthof'
  },
  {
    postalCode: '17440',
    city: 'Hohendorf'
  },
  {
    postalCode: '17440',
    city: 'Kröslin'
  },
  {
    postalCode: '17440',
    city: 'Krummin'
  },
  {
    postalCode: '17440',
    city: 'Lassan'
  },
  {
    postalCode: '17440',
    city: 'Lütow'
  },
  {
    postalCode: '17440',
    city: 'Pulow'
  },
  {
    postalCode: '17440',
    city: 'Sauzin'
  },
  {
    postalCode: '17440',
    city: 'Zemitz'
  },
  {
    postalCode: '17449',
    city: 'Karlshagen'
  },
  {
    postalCode: '17449',
    city: 'Mölschow'
  },
  {
    postalCode: '17449',
    city: 'Peenemünde'
  },
  {
    postalCode: '17449',
    city: 'Trassenheide'
  },
  {
    postalCode: '17454',
    city: 'Zinnowitz'
  },
  {
    postalCode: '17459',
    city: 'Koserow'
  },
  {
    postalCode: '17459',
    city: 'Loddin'
  },
  {
    postalCode: '17459',
    city: 'Ückeritz'
  },
  {
    postalCode: '17459',
    city: 'Zempin'
  },
  {
    postalCode: '17489',
    city: 'Greifswald'
  },
  {
    postalCode: '17491',
    city: 'Greifswald'
  },
  {
    postalCode: '17493',
    city: 'Greifswald'
  },
  {
    postalCode: '17493',
    city: 'Insel Koos'
  },
  {
    postalCode: '17495',
    city: 'Groß Kiesow'
  },
  {
    postalCode: '17495',
    city: 'Karlsburg'
  },
  {
    postalCode: '17495',
    city: 'Lühmannsdorf'
  },
  {
    postalCode: '17495',
    city: 'Ranzin'
  },
  {
    postalCode: '17495',
    city: 'Wrangelsburg'
  },
  {
    postalCode: '17495',
    city: 'Züssow'
  },
  {
    postalCode: '17498',
    city: 'Behrenhoff'
  },
  {
    postalCode: '17498',
    city: 'Dargelin'
  },
  {
    postalCode: '17498',
    city: 'Dersekow'
  },
  {
    postalCode: '17498',
    city: 'Diedrichshagen'
  },
  {
    postalCode: '17498',
    city: 'Hinrichshagen'
  },
  {
    postalCode: '17498',
    city: 'Insel Riems'
  },
  {
    postalCode: '17498',
    city: 'Levenhagen'
  },
  {
    postalCode: '17498',
    city: 'Mesekenhagen'
  },
  {
    postalCode: '17498',
    city: 'Neuenkirchen'
  },
  {
    postalCode: '17498',
    city: 'Riemserort'
  },
  {
    postalCode: '17498',
    city: 'Wackerow'
  },
  {
    postalCode: '17498',
    city: 'Weitenhagen'
  },
  {
    postalCode: '17506',
    city: 'Bandelin'
  },
  {
    postalCode: '17506',
    city: 'Breechen'
  },
  {
    postalCode: '17506',
    city: 'Gribow'
  },
  {
    postalCode: '17506',
    city: 'Gützkow'
  },
  {
    postalCode: '17506',
    city: 'Kammin'
  },
  {
    postalCode: '17506',
    city: 'Kölzin'
  },
  {
    postalCode: '17506',
    city: 'Lüssow'
  },
  {
    postalCode: '17509',
    city: 'Brünzow'
  },
  {
    postalCode: '17509',
    city: 'Hanshagen'
  },
  {
    postalCode: '17509',
    city: 'Katzow'
  },
  {
    postalCode: '17509',
    city: 'Kemnitz'
  },
  {
    postalCode: '17509',
    city: 'Loissin'
  },
  {
    postalCode: '17509',
    city: 'Lubmin'
  },
  {
    postalCode: '17509',
    city: 'Neu Boltenhagen'
  },
  {
    postalCode: '17509',
    city: 'Rubenow'
  },
  {
    postalCode: '17509',
    city: 'Wusterhusen'
  },
  {
    postalCode: '18055',
    city: 'Rostock'
  },
  {
    postalCode: '18057',
    city: 'Rostock'
  },
  {
    postalCode: '18059',
    city: 'Papendorf'
  },
  {
    postalCode: '18059',
    city: 'Pölchow'
  },
  {
    postalCode: '18059',
    city: 'Rostock'
  },
  {
    postalCode: '18059',
    city: 'Ziesendorf'
  },
  {
    postalCode: '18069',
    city: 'Lambrechtshagen'
  },
  {
    postalCode: '18069',
    city: 'Rostock'
  },
  {
    postalCode: '18106',
    city: 'Rostock'
  },
  {
    postalCode: '18107',
    city: 'Elmenhorst/Lichtenhagen'
  },
  {
    postalCode: '18107',
    city: 'Rostock'
  },
  {
    postalCode: '18109',
    city: 'Rostock'
  },
  {
    postalCode: '18119',
    city: 'Rostock'
  },
  {
    postalCode: '18146',
    city: 'Niederhagen'
  },
  {
    postalCode: '18146',
    city: 'Rostock'
  },
  {
    postalCode: '18147',
    city: 'Rostock'
  },
  {
    postalCode: '18181',
    city: 'Graal-Müritz'
  },
  {
    postalCode: '18181',
    city: 'Torfbrücke'
  },
  {
    postalCode: '18182',
    city: 'Bentwisch'
  },
  {
    postalCode: '18182',
    city: 'Blankenhagen'
  },
  {
    postalCode: '18182',
    city: 'Gelbensande'
  },
  {
    postalCode: '18182',
    city: 'Mönchhagen'
  },
  {
    postalCode: '18182',
    city: 'Rostock'
  },
  {
    postalCode: '18182',
    city: 'Rövershagen'
  },
  {
    postalCode: '18182',
    city: 'Wiethagen'
  },
  {
    postalCode: '18184',
    city: 'Broderstorf'
  },
  {
    postalCode: '18184',
    city: 'Klein Kussewitz'
  },
  {
    postalCode: '18184',
    city: 'Mandelshagen'
  },
  {
    postalCode: '18184',
    city: 'Poppendorf'
  },
  {
    postalCode: '18184',
    city: 'Roggentin'
  },
  {
    postalCode: '18184',
    city: 'Steinfeld'
  },
  {
    postalCode: '18184',
    city: 'Thulendorf'
  },
  {
    postalCode: '18190',
    city: 'Groß Lüsewitz'
  },
  {
    postalCode: '18190',
    city: 'Gubkow'
  },
  {
    postalCode: '18190',
    city: 'Niekrenz'
  },
  {
    postalCode: '18190',
    city: 'Reppelin'
  },
  {
    postalCode: '18190',
    city: 'Sanitz'
  },
  {
    postalCode: '18195',
    city: 'Cammin'
  },
  {
    postalCode: '18195',
    city: 'Gnewitz'
  },
  {
    postalCode: '18195',
    city: 'Grammow'
  },
  {
    postalCode: '18195',
    city: 'Nustrow'
  },
  {
    postalCode: '18195',
    city: 'Selpin'
  },
  {
    postalCode: '18195',
    city: 'Stubbendorf'
  },
  {
    postalCode: '18195',
    city: 'Tessin'
  },
  {
    postalCode: '18195',
    city: 'Thelkow'
  },
  {
    postalCode: '18195',
    city: 'Zarnewanz'
  },
  {
    postalCode: '18196',
    city: 'Damm'
  },
  {
    postalCode: '18196',
    city: 'Dummerstorf'
  },
  {
    postalCode: '18196',
    city: 'Kavelstorf'
  },
  {
    postalCode: '18196',
    city: 'Kessin'
  },
  {
    postalCode: '18196',
    city: 'Lieblingshof'
  },
  {
    postalCode: '18196',
    city: 'Prisannewitz'
  },
  {
    postalCode: '18198',
    city: 'Kritzmow'
  },
  {
    postalCode: '18198',
    city: 'Stäbelow'
  },
  {
    postalCode: '18209',
    city: 'Bad Doberan'
  },
  {
    postalCode: '18209',
    city: 'Badenmühle'
  },
  {
    postalCode: '18209',
    city: 'Bartenshagen'
  },
  {
    postalCode: '18209',
    city: 'Bollbrücke'
  },
  {
    postalCode: '18209',
    city: 'Brodhagen'
  },
  {
    postalCode: '18209',
    city: 'Fulgenkoppel'
  },
  {
    postalCode: '18209',
    city: 'Glashagen'
  },
  {
    postalCode: '18209',
    city: 'Hohenfelde'
  },
  {
    postalCode: '18209',
    city: 'Hütten'
  },
  {
    postalCode: '18209',
    city: 'Neu Hohenfelde'
  },
  {
    postalCode: '18209',
    city: 'Neuhof'
  },
  {
    postalCode: '18209',
    city: 'Parkentin'
  },
  {
    postalCode: '18209',
    city: 'Reddelich'
  },
  {
    postalCode: '18209',
    city: 'Steffenshagen'
  },
  {
    postalCode: '18209',
    city: 'Stülow'
  },
  {
    postalCode: '18209',
    city: 'Wittenbeck'
  },
  {
    postalCode: '18211',
    city: 'Admannshagen-Bargeshagen'
  },
  {
    postalCode: '18211',
    city: 'Bahrenhorst'
  },
  {
    postalCode: '18211',
    city: 'Börgerende'
  },
  {
    postalCode: '18211',
    city: 'Ehbruch'
  },
  {
    postalCode: '18211',
    city: 'Forsthaus Ivendorf'
  },
  {
    postalCode: '18211',
    city: 'Ivendorf'
  },
  {
    postalCode: '18211',
    city: 'Neu Rethwisch'
  },
  {
    postalCode: '18211',
    city: 'Ostseebad Nienhagen'
  },
  {
    postalCode: '18211',
    city: 'Rethwisch'
  },
  {
    postalCode: '18211',
    city: 'Retschow'
  },
  {
    postalCode: '18211',
    city: 'Retschow Waldsiedlung'
  },
  {
    postalCode: '18225',
    city: 'Kühlungsborn'
  },
  {
    postalCode: '18230',
    city: 'Bastorf'
  },
  {
    postalCode: '18230',
    city: 'Biendorf'
  },
  {
    postalCode: '18230',
    city: 'Blengow'
  },
  {
    postalCode: '18230',
    city: 'Büttelkow'
  },
  {
    postalCode: '18230',
    city: 'Gaarzerhof'
  },
  {
    postalCode: '18230',
    city: 'Garvsmühlen'
  },
  {
    postalCode: '18230',
    city: 'Gersdorf'
  },
  {
    postalCode: '18230',
    city: 'Hohen Niendorf'
  },
  {
    postalCode: '18230',
    city: 'Jennewitz'
  },
  {
    postalCode: '18230',
    city: 'Kägsdorf'
  },
  {
    postalCode: '18230',
    city: 'Körchow'
  },
  {
    postalCode: '18230',
    city: 'Mechelsdorf'
  },
  {
    postalCode: '18230',
    city: 'Meschendorf'
  },
  {
    postalCode: '18230',
    city: 'Meschendorf Zeltplatz'
  },
  {
    postalCode: '18230',
    city: 'Neu Gaarz'
  },
  {
    postalCode: '18230',
    city: 'Ostseebad Rerik'
  },
  {
    postalCode: '18230',
    city: 'Ostseebad Rerik Zeltplatz'
  },
  {
    postalCode: '18230',
    city: 'Roggow'
  },
  {
    postalCode: '18230',
    city: 'Wendelstorf'
  },
  {
    postalCode: '18230',
    city: 'Westhof'
  },
  {
    postalCode: '18230',
    city: 'Wischuer'
  },
  {
    postalCode: '18230',
    city: 'Wischuer Abbau'
  },
  {
    postalCode: '18230',
    city: 'Zweedorf'
  },
  {
    postalCode: '18233',
    city: 'Alt Bukow'
  },
  {
    postalCode: '18233',
    city: 'Bolland'
  },
  {
    postalCode: '18233',
    city: 'Clausdorf'
  },
  {
    postalCode: '18233',
    city: 'Garvensdorf'
  },
  {
    postalCode: '18233',
    city: 'Jörnstorf'
  },
  {
    postalCode: '18233',
    city: 'Kamin'
  },
  {
    postalCode: '18233',
    city: 'Kirch Mulsow'
  },
  {
    postalCode: '18233',
    city: 'Krempin'
  },
  {
    postalCode: '18233',
    city: 'Neubukow'
  },
  {
    postalCode: '18233',
    city: 'Parchow'
  },
  {
    postalCode: '18233',
    city: 'Pepelow'
  },
  {
    postalCode: '18233',
    city: 'Rakow'
  },
  {
    postalCode: '18233',
    city: 'Ravensberg'
  },
  {
    postalCode: '18233',
    city: 'Sandhagen'
  },
  {
    postalCode: '18233',
    city: 'Sandhagen Abbau'
  },
  {
    postalCode: '18233',
    city: 'Steinhagen'
  },
  {
    postalCode: '18233',
    city: 'Teßmannsdorf'
  },
  {
    postalCode: '18233',
    city: 'Uhlenbrook'
  },
  {
    postalCode: '18233',
    city: 'Westenbrügge'
  },
  {
    postalCode: '18233',
    city: 'Zarfzow'
  },
  {
    postalCode: '18236',
    city: 'Alt Karin'
  },
  {
    postalCode: '18236',
    city: 'Altenhagen'
  },
  {
    postalCode: '18236',
    city: 'Danneborth'
  },
  {
    postalCode: '18236',
    city: 'Einhusen'
  },
  {
    postalCode: '18236',
    city: 'Groß Siemen'
  },
  {
    postalCode: '18236',
    city: 'Kröpelin'
  },
  {
    postalCode: '18236',
    city: 'Neu Karin'
  },
  {
    postalCode: '18236',
    city: 'Schmadebeck'
  },
  {
    postalCode: '18239',
    city: 'Anna Luisenhof'
  },
  {
    postalCode: '18239',
    city: 'Berendshagen'
  },
  {
    postalCode: '18239',
    city: 'Clausdorf'
  },
  {
    postalCode: '18239',
    city: 'Dolglas'
  },
  {
    postalCode: '18239',
    city: 'Forsthof Satow'
  },
  {
    postalCode: '18239',
    city: 'Gerdshagen'
  },
  {
    postalCode: '18239',
    city: 'Gorow'
  },
  {
    postalCode: '18239',
    city: 'Groß Bölkow'
  },
  {
    postalCode: '18239',
    city: 'Groß Nienhagen'
  },
  {
    postalCode: '18239',
    city: 'Hanstorf'
  },
  {
    postalCode: '18239',
    city: 'Hastorf'
  },
  {
    postalCode: '18239',
    city: 'Heiligenhagen'
  },
  {
    postalCode: '18239',
    city: 'Hohen Luckow'
  },
  {
    postalCode: '18239',
    city: 'Horst'
  },
  {
    postalCode: '18239',
    city: 'Klein Bölkow'
  },
  {
    postalCode: '18239',
    city: 'Konow'
  },
  {
    postalCode: '18239',
    city: 'Lüningshagen'
  },
  {
    postalCode: '18239',
    city: 'Matersen'
  },
  {
    postalCode: '18239',
    city: 'Miekenhagen'
  },
  {
    postalCode: '18239',
    city: 'Püschow'
  },
  {
    postalCode: '18239',
    city: 'Pustohl'
  },
  {
    postalCode: '18239',
    city: 'Radegast'
  },
  {
    postalCode: '18239',
    city: 'Rederank'
  },
  {
    postalCode: '18239',
    city: 'Reinshagen'
  },
  {
    postalCode: '18239',
    city: 'Rosenhagen'
  },
  {
    postalCode: '18239',
    city: 'Satow'
  },
  {
    postalCode: '18239',
    city: 'Sophienholz'
  },
  {
    postalCode: '18239',
    city: 'Steinhagen'
  },
  {
    postalCode: '18246',
    city: 'Baumgarten'
  },
  {
    postalCode: '18246',
    city: 'Bützow'
  },
  {
    postalCode: '18246',
    city: 'Friedrichshof'
  },
  {
    postalCode: '18246',
    city: 'Glambeck'
  },
  {
    postalCode: '18246',
    city: 'Gnemern'
  },
  {
    postalCode: '18246',
    city: 'Göllin'
  },
  {
    postalCode: '18246',
    city: 'Groß Belitz'
  },
  {
    postalCode: '18246',
    city: 'Jabelitz'
  },
  {
    postalCode: '18246',
    city: 'Jürgenshagen'
  },
  {
    postalCode: '18246',
    city: 'Käterhagen'
  },
  {
    postalCode: '18246',
    city: 'Klein Belitz'
  },
  {
    postalCode: '18246',
    city: 'Klein Gischow'
  },
  {
    postalCode: '18246',
    city: 'Klein Gnemern'
  },
  {
    postalCode: '18246',
    city: 'Klein Sien'
  },
  {
    postalCode: '18246',
    city: 'Krugland'
  },
  {
    postalCode: '18246',
    city: 'Kurzen Trechow'
  },
  {
    postalCode: '18246',
    city: 'Langen Trechow'
  },
  {
    postalCode: '18246',
    city: 'Moisall'
  },
  {
    postalCode: '18246',
    city: 'Moltenow'
  },
  {
    postalCode: '18246',
    city: 'Neu Käterhagen'
  },
  {
    postalCode: '18246',
    city: 'Neuendorf'
  },
  {
    postalCode: '18246',
    city: 'Neukirchen'
  },
  {
    postalCode: '18246',
    city: 'Oettelin'
  },
  {
    postalCode: '18246',
    city: 'Reinstorf'
  },
  {
    postalCode: '18246',
    city: 'Rühn'
  },
  {
    postalCode: '18246',
    city: 'Selow'
  },
  {
    postalCode: '18246',
    city: 'Steinhagen'
  },
  {
    postalCode: '18246',
    city: 'Ulrikenhof'
  },
  {
    postalCode: '18246',
    city: 'Wokrent'
  },
  {
    postalCode: '18246',
    city: 'Zepelin'
  },
  {
    postalCode: '18249',
    city: 'Bernitt'
  },
  {
    postalCode: '18249',
    city: 'Boitin'
  },
  {
    postalCode: '18249',
    city: 'Buchenhof'
  },
  {
    postalCode: '18249',
    city: 'Dreetz'
  },
  {
    postalCode: '18249',
    city: 'Eickelberg'
  },
  {
    postalCode: '18249',
    city: 'Eickhof'
  },
  {
    postalCode: '18249',
    city: 'Katelbogen'
  },
  {
    postalCode: '18249',
    city: 'Klein Raden'
  },
  {
    postalCode: '18249',
    city: 'Laase'
  },
  {
    postalCode: '18249',
    city: 'Lübzin-Rosenow'
  },
  {
    postalCode: '18249',
    city: 'Penzin'
  },
  {
    postalCode: '18249',
    city: 'Qualitz'
  },
  {
    postalCode: '18249',
    city: 'Schlemmin'
  },
  {
    postalCode: '18249',
    city: 'Schlockow'
  },
  {
    postalCode: '18249',
    city: 'Tarnow'
  },
  {
    postalCode: '18249',
    city: 'Viezen'
  },
  {
    postalCode: '18249',
    city: 'Warnow'
  },
  {
    postalCode: '18249',
    city: 'Zernin'
  },
  {
    postalCode: '18258',
    city: 'Bandow'
  },
  {
    postalCode: '18258',
    city: 'Benitz'
  },
  {
    postalCode: '18258',
    city: 'Bröbberow'
  },
  {
    postalCode: '18258',
    city: 'Brookhusen'
  },
  {
    postalCode: '18258',
    city: 'Göldenitz'
  },
  {
    postalCode: '18258',
    city: 'Kambs'
  },
  {
    postalCode: '18258',
    city: 'Kassow'
  },
  {
    postalCode: '18258',
    city: 'Rukieten'
  },
  {
    postalCode: '18258',
    city: 'Schwaan'
  },
  {
    postalCode: '18258',
    city: 'Vorbeck'
  },
  {
    postalCode: '18258',
    city: 'Wiendorf'
  },
  {
    postalCode: '18273',
    city: 'Güstrow'
  },
  {
    postalCode: '18276',
    city: 'Bülow'
  },
  {
    postalCode: '18276',
    city: 'Glasewitz'
  },
  {
    postalCode: '18276',
    city: 'Groß Schwiesow'
  },
  {
    postalCode: '18276',
    city: 'Gülzow'
  },
  {
    postalCode: '18276',
    city: 'Gutow'
  },
  {
    postalCode: '18276',
    city: 'Klein Upahl'
  },
  {
    postalCode: '18276',
    city: 'Knegendorf'
  },
  {
    postalCode: '18276',
    city: 'Kuhs'
  },
  {
    postalCode: '18276',
    city: 'Lohmen'
  },
  {
    postalCode: '18276',
    city: 'Lüssow'
  },
  {
    postalCode: '18276',
    city: 'Mistorf'
  },
  {
    postalCode: '18276',
    city: 'Mühl Rosin'
  },
  {
    postalCode: '18276',
    city: 'Plaaz'
  },
  {
    postalCode: '18276',
    city: 'Prüzen'
  },
  {
    postalCode: '18276',
    city: 'Recknitz'
  },
  {
    postalCode: '18276',
    city: 'Reimershagen'
  },
  {
    postalCode: '18276',
    city: 'Rossewitz'
  },
  {
    postalCode: '18276',
    city: 'Sarmstorf'
  },
  {
    postalCode: '18276',
    city: 'Zehna'
  },
  {
    postalCode: '18279',
    city: 'Lalendorf'
  },
  {
    postalCode: '18279',
    city: 'Langhagen'
  },
  {
    postalCode: '18279',
    city: 'Mamerow'
  },
  {
    postalCode: '18279',
    city: 'Vietgest'
  },
  {
    postalCode: '18279',
    city: 'Wattmannshagen'
  },
  {
    postalCode: '18292',
    city: 'Bellin'
  },
  {
    postalCode: '18292',
    city: 'Charlottenthal'
  },
  {
    postalCode: '18292',
    city: 'Dobbin'
  },
  {
    postalCode: '18292',
    city: 'Hoppenrade'
  },
  {
    postalCode: '18292',
    city: 'Klein Tessin'
  },
  {
    postalCode: '18292',
    city: 'Krakow'
  },
  {
    postalCode: '18292',
    city: 'Kuchelmiß'
  },
  {
    postalCode: '18292',
    city: 'Linstow'
  },
  {
    postalCode: '18299',
    city: 'Alt Kätwin'
  },
  {
    postalCode: '18299',
    city: 'Diekhof'
  },
  {
    postalCode: '18299',
    city: 'Groß Ridsenow'
  },
  {
    postalCode: '18299',
    city: 'Hohen Sprenz'
  },
  {
    postalCode: '18299',
    city: 'Korleput'
  },
  {
    postalCode: '18299',
    city: 'Laage'
  },
  {
    postalCode: '18299',
    city: 'Liessow'
  },
  {
    postalCode: '18299',
    city: 'Pölitz'
  },
  {
    postalCode: '18299',
    city: 'Sabel'
  },
  {
    postalCode: '18299',
    city: 'Striesdorf'
  },
  {
    postalCode: '18299',
    city: 'Wardow'
  },
  {
    postalCode: '18299',
    city: 'Weitendorf'
  },
  {
    postalCode: '18311',
    city: 'Ribnitz-Damgarten'
  },
  {
    postalCode: '18314',
    city: 'Bartelshagen II'
  },
  {
    postalCode: '18314',
    city: 'Beughorst'
  },
  {
    postalCode: '18314',
    city: 'Divitz-Spoldershagen'
  },
  {
    postalCode: '18314',
    city: 'Kenz-Küstrow'
  },
  {
    postalCode: '18314',
    city: 'Kindshagen'
  },
  {
    postalCode: '18314',
    city: 'Löbnitz'
  },
  {
    postalCode: '18314',
    city: 'Lüdershagen'
  },
  {
    postalCode: '18314',
    city: 'Redebas'
  },
  {
    postalCode: '18314',
    city: 'Saatel'
  },
  {
    postalCode: '18317',
    city: 'Hermannshagen Dorf'
  },
  {
    postalCode: '18317',
    city: 'Hermannshagen Hof'
  },
  {
    postalCode: '18317',
    city: 'Hessenburg'
  },
  {
    postalCode: '18317',
    city: 'Kückenshagen'
  },
  {
    postalCode: '18317',
    city: 'Kuhlenbusch'
  },
  {
    postalCode: '18317',
    city: 'Neuendorf'
  },
  {
    postalCode: '18317',
    city: 'Neuendorf Heide'
  },
  {
    postalCode: '18317',
    city: 'Saal'
  },
  {
    postalCode: '18317',
    city: 'Staben'
  },
  {
    postalCode: '18320',
    city: 'Ahrenshagen'
  },
  {
    postalCode: '18320',
    city: 'Balkenkoppel'
  },
  {
    postalCode: '18320',
    city: 'Buchenhorst'
  },
  {
    postalCode: '18320',
    city: 'Daskow'
  },
  {
    postalCode: '18320',
    city: 'Eickhof'
  },
  {
    postalCode: '18320',
    city: 'Gruel'
  },
  {
    postalCode: '18320',
    city: 'Langenhanshagen'
  },
  {
    postalCode: '18320',
    city: 'Langenhanshagen Heide'
  },
  {
    postalCode: '18320',
    city: 'Neuenlübke'
  },
  {
    postalCode: '18320',
    city: 'Neuenrost'
  },
  {
    postalCode: '18320',
    city: 'Prusdorf'
  },
  {
    postalCode: '18320',
    city: 'Schlemmin'
  },
  {
    postalCode: '18320',
    city: 'Todenhagen'
  },
  {
    postalCode: '18320',
    city: 'Tribohm'
  },
  {
    postalCode: '18320',
    city: 'Trinwillershagen'
  },
  {
    postalCode: '18320',
    city: 'Wiepkenhagen'
  },
  {
    postalCode: '18334',
    city: 'Bad Sülze'
  },
  {
    postalCode: '18334',
    city: 'Böhlendorf'
  },
  {
    postalCode: '18334',
    city: 'Breesen'
  },
  {
    postalCode: '18334',
    city: 'Dettmannsdorf'
  },
  {
    postalCode: '18334',
    city: 'Eixen'
  },
  {
    postalCode: '18334',
    city: 'Langsdorf'
  },
  {
    postalCode: '18334',
    city: 'Schulenberg'
  },
  {
    postalCode: '18334',
    city: 'Semlow'
  },
  {
    postalCode: '18337',
    city: 'Marlow'
  },
  {
    postalCode: '18347',
    city: 'Dierhagen'
  },
  {
    postalCode: '18347',
    city: 'Ostseebad Ahrenshoop'
  },
  {
    postalCode: '18347',
    city: 'Ostseebad Wustrow'
  },
  {
    postalCode: '18356',
    city: 'Barth'
  },
  {
    postalCode: '18356',
    city: 'Bodstedt'
  },
  {
    postalCode: '18356',
    city: 'Bresewitz'
  },
  {
    postalCode: '18356',
    city: 'Fahrenkamp'
  },
  {
    postalCode: '18356',
    city: 'Fuhlendorf'
  },
  {
    postalCode: '18356',
    city: 'Glöwitz'
  },
  {
    postalCode: '18356',
    city: 'Gutglück'
  },
  {
    postalCode: '18356',
    city: 'Michaelsdorf'
  },
  {
    postalCode: '18356',
    city: 'Planitz'
  },
  {
    postalCode: '18356',
    city: 'Pruchten'
  },
  {
    postalCode: '18374',
    city: 'Zingst'
  },
  {
    postalCode: '18375',
    city: 'Born'
  },
  {
    postalCode: '18375',
    city: 'Ostseebad Prerow'
  },
  {
    postalCode: '18375',
    city: 'Wieck amDarß'
  },
  {
    postalCode: '18435',
    city: 'Stralsund'
  },
  {
    postalCode: '18437',
    city: 'Stralsund'
  },
  {
    postalCode: '18439',
    city: 'Stralsund'
  },
  {
    postalCode: '18442',
    city: 'Groß Kordshagen'
  },
  {
    postalCode: '18442',
    city: 'Jakobsdorf'
  },
  {
    postalCode: '18442',
    city: 'Kummerow'
  },
  {
    postalCode: '18442',
    city: 'Lüssow'
  },
  {
    postalCode: '18442',
    city: 'Neu Bartelshagen'
  },
  {
    postalCode: '18442',
    city: 'Niepars'
  },
  {
    postalCode: '18442',
    city: 'Pantelitz'
  },
  {
    postalCode: '18442',
    city: 'Steinhagen'
  },
  {
    postalCode: '18442',
    city: 'Wendorf'
  },
  {
    postalCode: '18445',
    city: 'Altenpleen'
  },
  {
    postalCode: '18445',
    city: 'Groß Mohrdorf'
  },
  {
    postalCode: '18445',
    city: 'Klausdorf'
  },
  {
    postalCode: '18445',
    city: 'Kramerhof'
  },
  {
    postalCode: '18445',
    city: 'Preetz'
  },
  {
    postalCode: '18445',
    city: 'Prohn'
  },
  {
    postalCode: '18461',
    city: 'Franzburg'
  },
  {
    postalCode: '18461',
    city: 'Gremersdorf-Buchholz'
  },
  {
    postalCode: '18461',
    city: 'Millienhagen'
  },
  {
    postalCode: '18461',
    city: 'Oebelitz'
  },
  {
    postalCode: '18461',
    city: 'Richtenberg'
  },
  {
    postalCode: '18461',
    city: 'Weitenhagen'
  },
  {
    postalCode: '18465',
    city: 'Drechow'
  },
  {
    postalCode: '18465',
    city: 'Hugoldsdorf'
  },
  {
    postalCode: '18465',
    city: 'Tribsees'
  },
  {
    postalCode: '18469',
    city: 'Karnin'
  },
  {
    postalCode: '18469',
    city: 'Velgast'
  },
  {
    postalCode: '18507',
    city: 'Grimmen'
  },
  {
    postalCode: '18510',
    city: 'Behnkendorf'
  },
  {
    postalCode: '18510',
    city: 'Elmenhorst'
  },
  {
    postalCode: '18510',
    city: 'Heidebrink'
  },
  {
    postalCode: '18510',
    city: 'Papenhagen'
  },
  {
    postalCode: '18510',
    city: 'Stoltenhagen'
  },
  {
    postalCode: '18510',
    city: 'Wittenhagen'
  },
  {
    postalCode: '18510',
    city: 'Zarrendorf'
  },
  {
    postalCode: '18513',
    city: 'Deyelsdorf'
  },
  {
    postalCode: '18513',
    city: 'Glewitz'
  },
  {
    postalCode: '18513',
    city: 'Grammendorf'
  },
  {
    postalCode: '18513',
    city: 'Gransebieth'
  },
  {
    postalCode: '18513',
    city: 'Splietsdorf'
  },
  {
    postalCode: '18513',
    city: 'Wendisch Baggendorf'
  },
  {
    postalCode: '18516',
    city: 'Süderholz'
  },
  {
    postalCode: '18519',
    city: 'Brandshagen'
  },
  {
    postalCode: '18519',
    city: 'Horst'
  },
  {
    postalCode: '18519',
    city: 'Kirchdorf'
  },
  {
    postalCode: '18519',
    city: 'Miltzow'
  },
  {
    postalCode: '18519',
    city: 'Reinberg'
  },
  {
    postalCode: '18519',
    city: 'Wilmshagen'
  },
  {
    postalCode: '18528',
    city: 'Bergen'
  },
  {
    postalCode: '18528',
    city: 'Buschvitz'
  },
  {
    postalCode: '18528',
    city: 'Lietzow'
  },
  {
    postalCode: '18528',
    city: 'Parchtitz'
  },
  {
    postalCode: '18528',
    city: 'Patzig'
  },
  {
    postalCode: '18528',
    city: 'Ralswiek'
  },
  {
    postalCode: '18528',
    city: 'Rappin'
  },
  {
    postalCode: '18528',
    city: 'Sehlen'
  },
  {
    postalCode: '18528',
    city: 'Thesenvitz'
  },
  {
    postalCode: '18528',
    city: 'Zirkow'
  },
  {
    postalCode: '18546',
    city: 'Sassnitz'
  },
  {
    postalCode: '18551',
    city: 'Glowe'
  },
  {
    postalCode: '18551',
    city: 'Lohme'
  },
  {
    postalCode: '18551',
    city: 'Sagard'
  },
  {
    postalCode: '18556',
    city: 'Altenkirchen'
  },
  {
    postalCode: '18556',
    city: 'Breege'
  },
  {
    postalCode: '18556',
    city: 'Dranske'
  },
  {
    postalCode: '18556',
    city: 'Putgarten'
  },
  {
    postalCode: '18556',
    city: 'Wiek'
  },
  {
    postalCode: '18565',
    city: 'Grieben'
  },
  {
    postalCode: '18565',
    city: 'Kloster'
  },
  {
    postalCode: '18565',
    city: 'Neuendorf, Hiddensee'
  },
  {
    postalCode: '18565',
    city: 'Vitte'
  },
  {
    postalCode: '18569',
    city: 'Gingst'
  },
  {
    postalCode: '18569',
    city: 'Kluis'
  },
  {
    postalCode: '18569',
    city: 'Neuenkirchen'
  },
  {
    postalCode: '18569',
    city: 'Schaprode'
  },
  {
    postalCode: '18569',
    city: 'Trent'
  },
  {
    postalCode: '18569',
    city: 'Ummanz'
  },
  {
    postalCode: '18573',
    city: 'Altefähr'
  },
  {
    postalCode: '18573',
    city: 'Dreschvitz'
  },
  {
    postalCode: '18573',
    city: 'Rambin'
  },
  {
    postalCode: '18573',
    city: 'Samtens'
  },
  {
    postalCode: '18574',
    city: 'Garz'
  },
  {
    postalCode: '18574',
    city: 'Groß Schoritz'
  },
  {
    postalCode: '18574',
    city: 'Gustow'
  },
  {
    postalCode: '18574',
    city: 'Karnitz'
  },
  {
    postalCode: '18574',
    city: 'Poseritz'
  },
  {
    postalCode: '18574',
    city: 'Zudar'
  },
  {
    postalCode: '18581',
    city: 'Alt Lanschvitz'
  },
  {
    postalCode: '18581',
    city: 'Beuchow'
  },
  {
    postalCode: '18581',
    city: 'Darsband'
  },
  {
    postalCode: '18581',
    city: 'Insel Vilm'
  },
  {
    postalCode: '18581',
    city: 'Putbus'
  },
  {
    postalCode: '18581',
    city: 'Strachtitz'
  },
  {
    postalCode: '18586',
    city: 'Baabe'
  },
  {
    postalCode: '18586',
    city: 'Gager'
  },
  {
    postalCode: '18586',
    city: 'Göhren'
  },
  {
    postalCode: '18586',
    city: 'Lancken-Granitz'
  },
  {
    postalCode: '18586',
    city: 'Middelhagen'
  },
  {
    postalCode: '18586',
    city: 'Sellin'
  },
  {
    postalCode: '18586',
    city: 'Thiessow'
  },
  {
    postalCode: '18609',
    city: 'Ostseebad Binz'
  },
  {
    postalCode: '19053',
    city: 'Schwerin'
  },
  {
    postalCode: '19055',
    city: 'Schwerin'
  },
  {
    postalCode: '19057',
    city: 'Schwerin'
  },
  {
    postalCode: '19059',
    city: 'Schwerin'
  },
  {
    postalCode: '19061',
    city: 'Schwerin'
  },
  {
    postalCode: '19063',
    city: 'Schwerin'
  },
  {
    postalCode: '19065',
    city: 'Gneven'
  },
  {
    postalCode: '19065',
    city: 'Godern'
  },
  {
    postalCode: '19065',
    city: 'Görslow'
  },
  {
    postalCode: '19065',
    city: 'Kritzow'
  },
  {
    postalCode: '19065',
    city: 'Pinnow'
  },
  {
    postalCode: '19065',
    city: 'Raben Steinfeld'
  },
  {
    postalCode: '19067',
    city: 'Cambs'
  },
  {
    postalCode: '19067',
    city: 'Langen Brütz'
  },
  {
    postalCode: '19067',
    city: 'Leezen'
  },
  {
    postalCode: '19067',
    city: 'Neu Schlagsdorf'
  },
  {
    postalCode: '19067',
    city: 'Rampe'
  },
  {
    postalCode: '19067',
    city: 'Retgendorf'
  },
  {
    postalCode: '19067',
    city: 'Rubow'
  },
  {
    postalCode: '19067',
    city: 'Zittow'
  },
  {
    postalCode: '19069',
    city: 'Alt Meteln'
  },
  {
    postalCode: '19069',
    city: 'Böken'
  },
  {
    postalCode: '19069',
    city: 'Drispeth'
  },
  {
    postalCode: '19069',
    city: 'Klein Trebbow'
  },
  {
    postalCode: '19069',
    city: 'Lübstorf'
  },
  {
    postalCode: '19069',
    city: 'Moltenow'
  },
  {
    postalCode: '19069',
    city: 'Pingelshagen'
  },
  {
    postalCode: '19069',
    city: 'Rugensee'
  },
  {
    postalCode: '19069',
    city: 'Seehof'
  },
  {
    postalCode: '19069',
    city: 'Zickhusen'
  },
  {
    postalCode: '19071',
    city: 'Brüsewitz'
  },
  {
    postalCode: '19071',
    city: 'Cramonshagen'
  },
  {
    postalCode: '19071',
    city: 'Dalberg-Wendelstorf'
  },
  {
    postalCode: '19071',
    city: 'Grambow'
  },
  {
    postalCode: '19071',
    city: 'Groß Brütz'
  },
  {
    postalCode: '19071',
    city: 'Herren Steinfeld'
  },
  {
    postalCode: '19073',
    city: 'Dümmer'
  },
  {
    postalCode: '19073',
    city: 'Klein Rogahn'
  },
  {
    postalCode: '19073',
    city: 'Schossin'
  },
  {
    postalCode: '19073',
    city: 'Stralendorf'
  },
  {
    postalCode: '19073',
    city: 'Wittenförden'
  },
  {
    postalCode: '19073',
    city: 'Wodenhof'
  },
  {
    postalCode: '19073',
    city: 'Zülow'
  },
  {
    postalCode: '19075',
    city: 'Holthusen'
  },
  {
    postalCode: '19075',
    city: 'Mühlenbeck'
  },
  {
    postalCode: '19075',
    city: 'Pampow'
  },
  {
    postalCode: '19075',
    city: 'Warsow'
  },
  {
    postalCode: '19077',
    city: 'Kraak'
  },
  {
    postalCode: '19077',
    city: 'Lübesse'
  },
  {
    postalCode: '19077',
    city: 'Rastow'
  },
  {
    postalCode: '19077',
    city: 'Sülstorf'
  },
  {
    postalCode: '19077',
    city: 'Uelitz'
  },
  {
    postalCode: '19079',
    city: 'Banzkow'
  },
  {
    postalCode: '19079',
    city: 'Goldenstädt'
  },
  {
    postalCode: '19079',
    city: 'Sukow'
  },
  {
    postalCode: '19086',
    city: 'Plate'
  },
  {
    postalCode: '19089',
    city: 'Bahlenhüschen'
  },
  {
    postalCode: '19089',
    city: 'Barnin'
  },
  {
    postalCode: '19089',
    city: 'Bülow'
  },
  {
    postalCode: '19089',
    city: 'Crivitz'
  },
  {
    postalCode: '19089',
    city: 'Demen'
  },
  {
    postalCode: '19089',
    city: 'Gädebehn'
  },
  {
    postalCode: '19089',
    city: 'Göhren'
  },
  {
    postalCode: '19089',
    city: 'Ruthenbeck'
  },
  {
    postalCode: '19089',
    city: 'Settin'
  },
  {
    postalCode: '19089',
    city: 'Tramm'
  },
  {
    postalCode: '19089',
    city: 'Wessin'
  },
  {
    postalCode: '19089',
    city: 'Zapel'
  },
  {
    postalCode: '19205',
    city: 'Bendhof'
  },
  {
    postalCode: '19205',
    city: 'Botelsdorf'
  },
  {
    postalCode: '19205',
    city: 'Breesen'
  },
  {
    postalCode: '19205',
    city: 'Dragun'
  },
  {
    postalCode: '19205',
    city: 'Dutzow'
  },
  {
    postalCode: '19205',
    city: 'Gadebusch'
  },
  {
    postalCode: '19205',
    city: 'Goddin'
  },
  {
    postalCode: '19205',
    city: 'Groß Eichsen'
  },
  {
    postalCode: '19205',
    city: 'Groß Salitz'
  },
  {
    postalCode: '19205',
    city: 'Groß Thurow'
  },
  {
    postalCode: '19205',
    city: 'Hindenberg'
  },
  {
    postalCode: '19205',
    city: 'Kaeselow'
  },
  {
    postalCode: '19205',
    city: 'Klein Salitz'
  },
  {
    postalCode: '19205',
    city: 'Klein Thurow'
  },
  {
    postalCode: '19205',
    city: 'Kneese'
  },
  {
    postalCode: '19205',
    city: 'Krembz'
  },
  {
    postalCode: '19205',
    city: 'Marienthal'
  },
  {
    postalCode: '19205',
    city: 'Meetzen'
  },
  {
    postalCode: '19205',
    city: 'Mühlen Eichsen'
  },
  {
    postalCode: '19205',
    city: 'Neu Thurow'
  },
  {
    postalCode: '19205',
    city: 'Passow'
  },
  {
    postalCode: '19205',
    city: 'Pokrent'
  },
  {
    postalCode: '19205',
    city: 'Radegast'
  },
  {
    postalCode: '19205',
    city: 'Rambeel'
  },
  {
    postalCode: '19205',
    city: 'Roggendorf'
  },
  {
    postalCode: '19205',
    city: 'Rögnitz'
  },
  {
    postalCode: '19205',
    city: 'Rosenow'
  },
  {
    postalCode: '19205',
    city: 'Schönfeld'
  },
  {
    postalCode: '19205',
    city: 'Schönwolde'
  },
  {
    postalCode: '19205',
    city: 'Veelböken'
  },
  {
    postalCode: '19205',
    city: 'Webelsfelde'
  },
  {
    postalCode: '19209',
    city: 'Badow'
  },
  {
    postalCode: '19209',
    city: 'Gottesgabe'
  },
  {
    postalCode: '19209',
    city: 'Groß Welzin'
  },
  {
    postalCode: '19209',
    city: 'Klein Welzin'
  },
  {
    postalCode: '19209',
    city: 'Lützow'
  },
  {
    postalCode: '19209',
    city: 'Perlin'
  },
  {
    postalCode: '19209',
    city: 'Renzow'
  },
  {
    postalCode: '19209',
    city: 'Rosenhagen'
  },
  {
    postalCode: '19217',
    city: 'Benzin'
  },
  {
    postalCode: '19217',
    city: 'Bestenrade'
  },
  {
    postalCode: '19217',
    city: 'Brützkow'
  },
  {
    postalCode: '19217',
    city: 'Bülow'
  },
  {
    postalCode: '19217',
    city: 'Campow'
  },
  {
    postalCode: '19217',
    city: 'Carlow'
  },
  {
    postalCode: '19217',
    city: 'Dechow'
  },
  {
    postalCode: '19217',
    city: 'Demern'
  },
  {
    postalCode: '19217',
    city: 'Gletzow'
  },
  {
    postalCode: '19217',
    city: 'Groß Hundorf'
  },
  {
    postalCode: '19217',
    city: 'Groß Molzahn'
  },
  {
    postalCode: '19217',
    city: 'Groß Rünz'
  },
  {
    postalCode: '19217',
    city: 'Holdorf'
  },
  {
    postalCode: '19217',
    city: 'Kasendorf'
  },
  {
    postalCode: '19217',
    city: 'Klein Molzahn'
  },
  {
    postalCode: '19217',
    city: 'Köchelstorf'
  },
  {
    postalCode: '19217',
    city: 'Kuhlrade'
  },
  {
    postalCode: '19217',
    city: 'Löwitz'
  },
  {
    postalCode: '19217',
    city: 'Neschow'
  },
  {
    postalCode: '19217',
    city: 'Nesow'
  },
  {
    postalCode: '19217',
    city: 'Neu Vitense'
  },
  {
    postalCode: '19217',
    city: 'Othenstorf'
  },
  {
    postalCode: '19217',
    city: 'Parber'
  },
  {
    postalCode: '19217',
    city: 'Pogez'
  },
  {
    postalCode: '19217',
    city: 'Raddingsdorf'
  },
  {
    postalCode: '19217',
    city: 'Rehna'
  },
  {
    postalCode: '19217',
    city: 'Rieps'
  },
  {
    postalCode: '19217',
    city: 'Samkow'
  },
  {
    postalCode: '19217',
    city: 'Schaddingsdorf'
  },
  {
    postalCode: '19217',
    city: 'Schlagsdorf'
  },
  {
    postalCode: '19217',
    city: 'Stove'
  },
  {
    postalCode: '19217',
    city: 'Thandorf'
  },
  {
    postalCode: '19217',
    city: 'Törber'
  },
  {
    postalCode: '19217',
    city: 'Utecht'
  },
  {
    postalCode: '19217',
    city: 'Vitense'
  },
  {
    postalCode: '19217',
    city: 'Wedendorf'
  },
  {
    postalCode: '19217',
    city: 'Woitendorf'
  },
  {
    postalCode: '19230',
    city: 'Alt Zachun'
  },
  {
    postalCode: '19230',
    city: 'Bandenitz'
  },
  {
    postalCode: '19230',
    city: 'Belsch'
  },
  {
    postalCode: '19230',
    city: 'Bobzin'
  },
  {
    postalCode: '19230',
    city: 'Bresegard'
  },
  {
    postalCode: '19230',
    city: 'Gammelin'
  },
  {
    postalCode: '19230',
    city: 'Goldenitz'
  },
  {
    postalCode: '19230',
    city: 'Gramnitz'
  },
  {
    postalCode: '19230',
    city: 'Granzin'
  },
  {
    postalCode: '19230',
    city: 'Groß Krams'
  },
  {
    postalCode: '19230',
    city: 'Grünhof'
  },
  {
    postalCode: '19230',
    city: 'Hagenow'
  },
  {
    postalCode: '19230',
    city: 'Hagenow Heide'
  },
  {
    postalCode: '19230',
    city: 'Hoort'
  },
  {
    postalCode: '19230',
    city: 'Hülseburg'
  },
  {
    postalCode: '19230',
    city: 'Jasnitz'
  },
  {
    postalCode: '19230',
    city: 'Kirch Jesar'
  },
  {
    postalCode: '19230',
    city: 'Kuhstorf'
  },
  {
    postalCode: '19230',
    city: 'Moraas'
  },
  {
    postalCode: '19230',
    city: 'Neu Zachun'
  },
  {
    postalCode: '19230',
    city: 'Neuenrode'
  },
  {
    postalCode: '19230',
    city: 'Pätow'
  },
  {
    postalCode: '19230',
    city: 'Picher'
  },
  {
    postalCode: '19230',
    city: 'Pritzier'
  },
  {
    postalCode: '19230',
    city: 'Redefin'
  },
  {
    postalCode: '19230',
    city: 'Ruhethal'
  },
  {
    postalCode: '19230',
    city: 'Scharbow'
  },
  {
    postalCode: '19230',
    city: 'Schwaberow'
  },
  {
    postalCode: '19230',
    city: 'Schwechow'
  },
  {
    postalCode: '19230',
    city: 'Setzin'
  },
  {
    postalCode: '19230',
    city: 'Steegen'
  },
  {
    postalCode: '19230',
    city: 'Strohkirchen'
  },
  {
    postalCode: '19230',
    city: 'Sudenhof'
  },
  {
    postalCode: '19230',
    city: 'Toddin'
  },
  {
    postalCode: '19230',
    city: 'Viez'
  },
  {
    postalCode: '19230',
    city: 'Warlitz'
  },
  {
    postalCode: '19230',
    city: 'Zapel'
  },
  {
    postalCode: '19243',
    city: 'Boddin'
  },
  {
    postalCode: '19243',
    city: 'Döbbersen'
  },
  {
    postalCode: '19243',
    city: 'Dodow'
  },
  {
    postalCode: '19243',
    city: 'Dreilützow'
  },
  {
    postalCode: '19243',
    city: 'Drönnewitz'
  },
  {
    postalCode: '19243',
    city: 'Harst'
  },
  {
    postalCode: '19243',
    city: 'Karft'
  },
  {
    postalCode: '19243',
    city: 'Körchow'
  },
  {
    postalCode: '19243',
    city: 'Kützin'
  },
  {
    postalCode: '19243',
    city: 'Lehsen'
  },
  {
    postalCode: '19243',
    city: 'Luckwitz'
  },
  {
    postalCode: '19243',
    city: 'Parum'
  },
  {
    postalCode: '19243',
    city: 'Perdöhl'
  },
  {
    postalCode: '19243',
    city: 'Pogreß'
  },
  {
    postalCode: '19243',
    city: 'Püttelkow'
  },
  {
    postalCode: '19243',
    city: 'Raguth'
  },
  {
    postalCode: '19243',
    city: 'Tessin'
  },
  {
    postalCode: '19243',
    city: 'Waschow'
  },
  {
    postalCode: '19243',
    city: 'Wittenburg'
  },
  {
    postalCode: '19243',
    city: 'Woez'
  },
  {
    postalCode: '19243',
    city: 'Wulfskuhl'
  },
  {
    postalCode: '19243',
    city: 'Zühr'
  },
  {
    postalCode: '19246',
    city: 'Bantin'
  },
  {
    postalCode: '19246',
    city: 'Bernstorf'
  },
  {
    postalCode: '19246',
    city: 'Boissow'
  },
  {
    postalCode: '19246',
    city: 'Boize'
  },
  {
    postalCode: '19246',
    city: 'Camin'
  },
  {
    postalCode: '19246',
    city: 'Hakendorf'
  },
  {
    postalCode: '19246',
    city: 'Kogel'
  },
  {
    postalCode: '19246',
    city: 'Kölzin'
  },
  {
    postalCode: '19246',
    city: 'Lassahn'
  },
  {
    postalCode: '19246',
    city: 'Lüttow'
  },
  {
    postalCode: '19246',
    city: 'Neuenkirchen'
  },
  {
    postalCode: '19246',
    city: 'Neuhof'
  },
  {
    postalCode: '19246',
    city: 'Pamprin'
  },
  {
    postalCode: '19246',
    city: 'Stintenburg'
  },
  {
    postalCode: '19246',
    city: 'Stintenburger Hütte'
  },
  {
    postalCode: '19246',
    city: 'Techin'
  },
  {
    postalCode: '19246',
    city: 'Testorf'
  },
  {
    postalCode: '19246',
    city: 'Valluhn'
  },
  {
    postalCode: '19246',
    city: 'Zarrentin'
  },
  {
    postalCode: '19249',
    city: 'Bandekow'
  },
  {
    postalCode: '19249',
    city: 'Benz'
  },
  {
    postalCode: '19249',
    city: 'Briest'
  },
  {
    postalCode: '19249',
    city: 'Brömsenberg'
  },
  {
    postalCode: '19249',
    city: 'Garlitz'
  },
  {
    postalCode: '19249',
    city: 'Gößlow'
  },
  {
    postalCode: '19249',
    city: 'Gudow'
  },
  {
    postalCode: '19249',
    city: 'Jessenitz'
  },
  {
    postalCode: '19249',
    city: 'Jessenitz-Werk'
  },
  {
    postalCode: '19249',
    city: 'Lank'
  },
  {
    postalCode: '19249',
    city: 'Lübbendorf'
  },
  {
    postalCode: '19249',
    city: 'Lübtheen'
  },
  {
    postalCode: '19249',
    city: 'Neu Lübtheen'
  },
  {
    postalCode: '19249',
    city: 'Probst Jesar'
  },
  {
    postalCode: '19249',
    city: 'Quassel'
  },
  {
    postalCode: '19249',
    city: 'Trebs'
  },
  {
    postalCode: '19249',
    city: 'Volzrade'
  },
  {
    postalCode: '19258',
    city: 'Badekow'
  },
  {
    postalCode: '19258',
    city: 'Besitz'
  },
  {
    postalCode: '19258',
    city: 'Bickhusen'
  },
  {
    postalCode: '19258',
    city: 'Boizenburg'
  },
  {
    postalCode: '19258',
    city: 'Gallin'
  },
  {
    postalCode: '19258',
    city: 'Granzin'
  },
  {
    postalCode: '19258',
    city: 'Gresse'
  },
  {
    postalCode: '19258',
    city: 'Greven'
  },
  {
    postalCode: '19258',
    city: 'Groß Bengerstorf'
  },
  {
    postalCode: '19258',
    city: 'Heide'
  },
  {
    postalCode: '19258',
    city: 'Heidekrug'
  },
  {
    postalCode: '19258',
    city: 'Horst'
  },
  {
    postalCode: '19258',
    city: 'Klein Bengerstorf'
  },
  {
    postalCode: '19258',
    city: 'Kuhlenfeld'
  },
  {
    postalCode: '19258',
    city: 'Leisterförde'
  },
  {
    postalCode: '19258',
    city: 'Lüttenmark'
  },
  {
    postalCode: '19258',
    city: 'Neu Gülze'
  },
  {
    postalCode: '19258',
    city: 'Nieklitz'
  },
  {
    postalCode: '19258',
    city: 'Nostorf'
  },
  {
    postalCode: '19258',
    city: 'Rensdorf'
  },
  {
    postalCode: '19258',
    city: 'Schwanheide'
  },
  {
    postalCode: '19258',
    city: 'Tessin'
  },
  {
    postalCode: '19258',
    city: 'Wiebendorf'
  },
  {
    postalCode: '19258',
    city: 'Zahrensdorf'
  },
  {
    postalCode: '19260',
    city: 'Albertinenhof'
  },
  {
    postalCode: '19260',
    city: 'Banzin'
  },
  {
    postalCode: '19260',
    city: 'Bennin'
  },
  {
    postalCode: '19260',
    city: 'Dersenow'
  },
  {
    postalCode: '19260',
    city: 'Goldenbow'
  },
  {
    postalCode: '19260',
    city: 'Kloddram'
  },
  {
    postalCode: '19260',
    city: 'Marsow'
  },
  {
    postalCode: '19260',
    city: 'Rodenwalde'
  },
  {
    postalCode: '19260',
    city: 'Schildfeld'
  },
  {
    postalCode: '19260',
    city: 'Tüschow'
  },
  {
    postalCode: '19260',
    city: 'Vellahn'
  },
  {
    postalCode: '19260',
    city: 'Vietow'
  },
  {
    postalCode: '19273',
    city: 'Bandekow'
  },
  {
    postalCode: '19273',
    city: 'Bitter'
  },
  {
    postalCode: '19273',
    city: 'Bleckederwerder'
  },
  {
    postalCode: '19273',
    city: 'Bohnenburg'
  },
  {
    postalCode: '19273',
    city: 'Brahlstorf'
  },
  {
    postalCode: '19273',
    city: 'Dammereez'
  },
  {
    postalCode: '19273',
    city: 'Darchau'
  },
  {
    postalCode: '19273',
    city: 'Dellien'
  },
  {
    postalCode: '19273',
    city: 'Groß Kühren'
  },
  {
    postalCode: '19273',
    city: 'Groß Timkenberg'
  },
  {
    postalCode: '19273',
    city: 'Gülstorf'
  },
  {
    postalCode: '19273',
    city: 'Gülze'
  },
  {
    postalCode: '19273',
    city: 'Haar'
  },
  {
    postalCode: '19273',
    city: 'Herrenhof'
  },
  {
    postalCode: '19273',
    city: 'Hinterhagen'
  },
  {
    postalCode: '19273',
    city: 'Kaarßen'
  },
  {
    postalCode: '19273',
    city: 'Konau'
  },
  {
    postalCode: '19273',
    city: 'Krusendorf'
  },
  {
    postalCode: '19273',
    city: 'Laake'
  },
  {
    postalCode: '19273',
    city: 'Laave'
  },
  {
    postalCode: '19273',
    city: 'Langenheide'
  },
  {
    postalCode: '19273',
    city: 'Melkof'
  },
  {
    postalCode: '19273',
    city: 'Neu Bleckede'
  },
  {
    postalCode: '19273',
    city: 'Neu Garge'
  },
  {
    postalCode: '19273',
    city: 'Neu Wendischthun'
  },
  {
    postalCode: '19273',
    city: 'Neuhaus'
  },
  {
    postalCode: '19273',
    city: 'Niendorf'
  },
  {
    postalCode: '19273',
    city: 'Pinnau'
  },
  {
    postalCode: '19273',
    city: 'Popelau'
  },
  {
    postalCode: '19273',
    city: 'Preten'
  },
  {
    postalCode: '19273',
    city: 'Privelack'
  },
  {
    postalCode: '19273',
    city: 'Rassau'
  },
  {
    postalCode: '19273',
    city: 'Rosien'
  },
  {
    postalCode: '19273',
    city: 'Schleusenow'
  },
  {
    postalCode: '19273',
    city: 'Soltow'
  },
  {
    postalCode: '19273',
    city: 'Stapel'
  },
  {
    postalCode: '19273',
    city: 'Stiepelse'
  },
  {
    postalCode: '19273',
    city: 'Stixe'
  },
  {
    postalCode: '19273',
    city: 'Strachau'
  },
  {
    postalCode: '19273',
    city: 'Sückau'
  },
  {
    postalCode: '19273',
    city: 'Sumte'
  },
  {
    postalCode: '19273',
    city: 'Teldau'
  },
  {
    postalCode: '19273',
    city: 'Tripkau'
  },
  {
    postalCode: '19273',
    city: 'Viehle'
  },
  {
    postalCode: '19273',
    city: 'Vockfey'
  },
  {
    postalCode: '19273',
    city: 'Vorderhagen'
  },
  {
    postalCode: '19273',
    city: 'Wehningen'
  },
  {
    postalCode: '19273',
    city: 'Wilkenstorf'
  },
  {
    postalCode: '19273',
    city: 'Zeetze'
  },
  {
    postalCode: '19288',
    city: 'Alt Krenzlin'
  },
  {
    postalCode: '19288',
    city: 'Fahrbinde'
  },
  {
    postalCode: '19288',
    city: 'Glaisin'
  },
  {
    postalCode: '19288',
    city: 'Göhlen'
  },
  {
    postalCode: '19288',
    city: 'Groß Laasch'
  },
  {
    postalCode: '19288',
    city: 'Klein Krams'
  },
  {
    postalCode: '19288',
    city: 'Krenzliner Hütte'
  },
  {
    postalCode: '19288',
    city: 'Kummer'
  },
  {
    postalCode: '19288',
    city: 'Leussow'
  },
  {
    postalCode: '19288',
    city: 'Lüblow'
  },
  {
    postalCode: '19288',
    city: 'Ludwigslust'
  },
  {
    postalCode: '19288',
    city: 'Neu Lüblow'
  },
  {
    postalCode: '19288',
    city: 'Warlow'
  },
  {
    postalCode: '19288',
    city: 'Wöbbelin'
  },
  {
    postalCode: '19294',
    city: 'Altona'
  },
  {
    postalCode: '19294',
    city: 'Bresegard'
  },
  {
    postalCode: '19294',
    city: 'Dadow'
  },
  {
    postalCode: '19294',
    city: 'Eldena'
  },
  {
    postalCode: '19294',
    city: 'Göhren'
  },
  {
    postalCode: '19294',
    city: 'Gorlosen'
  },
  {
    postalCode: '19294',
    city: 'Görnitz'
  },
  {
    postalCode: '19294',
    city: 'Grebs'
  },
  {
    postalCode: '19294',
    city: 'Güritz Försterei'
  },
  {
    postalCode: '19294',
    city: 'Karenz'
  },
  {
    postalCode: '19294',
    city: 'Karstädt'
  },
  {
    postalCode: '19294',
    city: 'Krinitz'
  },
  {
    postalCode: '19294',
    city: 'Liepe'
  },
  {
    postalCode: '19294',
    city: 'Malk'
  },
  {
    postalCode: '19294',
    city: 'Malliß'
  },
  {
    postalCode: '19294',
    city: 'Neu Göhren'
  },
  {
    postalCode: '19294',
    city: 'Neu Kaliß'
  },
  {
    postalCode: '19294',
    city: 'Niendorf'
  },
  {
    postalCode: '19294',
    city: 'Strassen'
  },
  {
    postalCode: '19294',
    city: 'Stuck'
  },
  {
    postalCode: '19300',
    city: 'Balow'
  },
  {
    postalCode: '19300',
    city: 'Beckentin'
  },
  {
    postalCode: '19300',
    city: 'Deibow'
  },
  {
    postalCode: '19300',
    city: 'Fresenbrügge'
  },
  {
    postalCode: '19300',
    city: 'Grabow'
  },
  {
    postalCode: '19300',
    city: 'Kastorf'
  },
  {
    postalCode: '19300',
    city: 'Kremmin'
  },
  {
    postalCode: '19300',
    city: 'Marienhof'
  },
  {
    postalCode: '19300',
    city: 'Milow'
  },
  {
    postalCode: '19300',
    city: 'Möllenbeck'
  },
  {
    postalCode: '19300',
    city: 'Muchow'
  },
  {
    postalCode: '19300',
    city: 'Neese'
  },
  {
    postalCode: '19300',
    city: 'Neese Ausbau'
  },
  {
    postalCode: '19300',
    city: 'Prislich'
  },
  {
    postalCode: '19300',
    city: 'Semmerin'
  },
  {
    postalCode: '19300',
    city: 'Steesow'
  },
  {
    postalCode: '19300',
    city: 'Wanzlitz'
  },
  {
    postalCode: '19300',
    city: 'Werle'
  },
  {
    postalCode: '19300',
    city: 'Zierzow'
  },
  {
    postalCode: '19303',
    city: 'Alt Jabel'
  },
  {
    postalCode: '19303',
    city: 'Dömitz'
  },
  {
    postalCode: '19303',
    city: 'Heidhof'
  },
  {
    postalCode: '19303',
    city: 'Neu Jabel'
  },
  {
    postalCode: '19303',
    city: 'Polz'
  },
  {
    postalCode: '19303',
    city: 'Rüterberg'
  },
  {
    postalCode: '19303',
    city: 'Tewswoos'
  },
  {
    postalCode: '19303',
    city: 'Vielank'
  },
  {
    postalCode: '19303',
    city: 'Woosmer'
  },
  {
    postalCode: '19306',
    city: 'Blievenstorf'
  },
  {
    postalCode: '19306',
    city: 'Brenz'
  },
  {
    postalCode: '19306',
    city: 'Neustadt-Glewe'
  },
  {
    postalCode: '19309',
    city: 'Baarz'
  },
  {
    postalCode: '19309',
    city: 'Bernheide'
  },
  {
    postalCode: '19309',
    city: 'Besandten'
  },
  {
    postalCode: '19309',
    city: 'Eldenburg'
  },
  {
    postalCode: '19309',
    city: 'Ferbitz'
  },
  {
    postalCode: '19309',
    city: 'Gaarz'
  },
  {
    postalCode: '19309',
    city: 'Jagel'
  },
  {
    postalCode: '19309',
    city: 'Lanz'
  },
  {
    postalCode: '19309',
    city: 'Lenzen'
  },
  {
    postalCode: '19309',
    city: 'Mellen'
  },
  {
    postalCode: '19309',
    city: 'Rambow amSee'
  },
  {
    postalCode: '19309',
    city: 'Unbesandten'
  },
  {
    postalCode: '19309',
    city: 'Wootz'
  },
  {
    postalCode: '19309',
    city: 'Wustrow'
  },
  {
    postalCode: '19322',
    city: 'Abbendorf'
  },
  {
    postalCode: '19322',
    city: 'Bälow'
  },
  {
    postalCode: '19322',
    city: 'Bentwisch'
  },
  {
    postalCode: '19322',
    city: 'Breese'
  },
  {
    postalCode: '19322',
    city: 'Cumlosen'
  },
  {
    postalCode: '19322',
    city: 'Garsedow'
  },
  {
    postalCode: '19322',
    city: 'Gnevsdorf'
  },
  {
    postalCode: '19322',
    city: 'Groß Breese'
  },
  {
    postalCode: '19322',
    city: 'Hermannshof'
  },
  {
    postalCode: '19322',
    city: 'Hinzdorf'
  },
  {
    postalCode: '19322',
    city: 'Karlsruhe'
  },
  {
    postalCode: '19322',
    city: 'Klein Leppin'
  },
  {
    postalCode: '19322',
    city: 'Klein Lüben'
  },
  {
    postalCode: '19322',
    city: 'Kuhberg'
  },
  {
    postalCode: '19322',
    city: 'Kuhblank'
  },
  {
    postalCode: '19322',
    city: 'Lindenberg'
  },
  {
    postalCode: '19322',
    city: 'Lütjenheide'
  },
  {
    postalCode: '19322',
    city: 'Motrich'
  },
  {
    postalCode: '19322',
    city: 'Müggendorf'
  },
  {
    postalCode: '19322',
    city: 'Plattenburg Forsthaus'
  },
  {
    postalCode: '19322',
    city: 'Rühstädt'
  },
  {
    postalCode: '19322',
    city: 'Sandkrug'
  },
  {
    postalCode: '19322',
    city: 'Schadebeuster'
  },
  {
    postalCode: '19322',
    city: 'Schilde'
  },
  {
    postalCode: '19322',
    city: 'Weisen'
  },
  {
    postalCode: '19322',
    city: 'Wentdorf'
  },
  {
    postalCode: '19322',
    city: 'Wittenberge'
  },
  {
    postalCode: '19322',
    city: 'Zwischendeich'
  },
  {
    postalCode: '19336',
    city: 'Bad Wilsnack'
  },
  {
    postalCode: '19336',
    city: 'Groß Lüben'
  },
  {
    postalCode: '19336',
    city: 'Groß Werzin'
  },
  {
    postalCode: '19336',
    city: 'Grube'
  },
  {
    postalCode: '19336',
    city: 'Haaren'
  },
  {
    postalCode: '19336',
    city: 'Karthan'
  },
  {
    postalCode: '19336',
    city: 'Kletzke'
  },
  {
    postalCode: '19336',
    city: 'Legde'
  },
  {
    postalCode: '19336',
    city: 'Lennewitz'
  },
  {
    postalCode: '19336',
    city: 'Plattenburg'
  },
  {
    postalCode: '19336',
    city: 'Quitzöbel'
  },
  {
    postalCode: '19336',
    city: 'Rambow'
  },
  {
    postalCode: '19336',
    city: 'Roddan'
  },
  {
    postalCode: '19336',
    city: 'Sigrön'
  },
  {
    postalCode: '19336',
    city: 'Viesecke'
  },
  {
    postalCode: '19339',
    city: 'Bendelin'
  },
  {
    postalCode: '19339',
    city: 'Friedrichswalde'
  },
  {
    postalCode: '19339',
    city: 'Glöwen'
  },
  {
    postalCode: '19339',
    city: 'Groß Leppin'
  },
  {
    postalCode: '19339',
    city: 'Hermshof'
  },
  {
    postalCode: '19339',
    city: 'Netzow'
  },
  {
    postalCode: '19339',
    city: 'Scharleuk'
  },
  {
    postalCode: '19339',
    city: 'Schwanensee'
  },
  {
    postalCode: '19339',
    city: 'Söllenthin'
  },
  {
    postalCode: '19339',
    city: 'Storbeckshof'
  },
  {
    postalCode: '19339',
    city: 'Zernikow'
  },
  {
    postalCode: '19339',
    city: 'Zichtow'
  },
  {
    postalCode: '19348',
    city: 'Baek'
  },
  {
    postalCode: '19348',
    city: 'Berge'
  },
  {
    postalCode: '19348',
    city: 'Bresch'
  },
  {
    postalCode: '19348',
    city: 'Burghagen'
  },
  {
    postalCode: '19348',
    city: 'Burow'
  },
  {
    postalCode: '19348',
    city: 'Dannhof'
  },
  {
    postalCode: '19348',
    city: 'Dergenthin'
  },
  {
    postalCode: '19348',
    city: 'Düpow'
  },
  {
    postalCode: '19348',
    city: 'Gramzow'
  },
  {
    postalCode: '19348',
    city: 'Grenzheim'
  },
  {
    postalCode: '19348',
    city: 'Groß Buchholz'
  },
  {
    postalCode: '19348',
    city: 'Groß Gottschow'
  },
  {
    postalCode: '19348',
    city: 'Groß Linde'
  },
  {
    postalCode: '19348',
    city: 'Guhlsdorf'
  },
  {
    postalCode: '19348',
    city: 'Gülitz'
  },
  {
    postalCode: '19348',
    city: 'Gulow'
  },
  {
    postalCode: '19348',
    city: 'Hellburg'
  },
  {
    postalCode: '19348',
    city: 'Hohenvier'
  },
  {
    postalCode: '19348',
    city: 'Horst'
  },
  {
    postalCode: '19348',
    city: 'Kaltenhof'
  },
  {
    postalCode: '19348',
    city: 'Karlshorst'
  },
  {
    postalCode: '19348',
    city: 'Kleeste'
  },
  {
    postalCode: '19348',
    city: 'Klein Gottschow'
  },
  {
    postalCode: '19348',
    city: 'Klein Linde'
  },
  {
    postalCode: '19348',
    city: 'Kleinow'
  },
  {
    postalCode: '19348',
    city: 'Knorrenhof'
  },
  {
    postalCode: '19348',
    city: 'Krampfer'
  },
  {
    postalCode: '19348',
    city: 'Kreuzburg'
  },
  {
    postalCode: '19348',
    city: 'Laaslich'
  },
  {
    postalCode: '19348',
    city: 'Lenzersilge'
  },
  {
    postalCode: '19348',
    city: 'Lübzow'
  },
  {
    postalCode: '19348',
    city: 'Lübzow Ausbau'
  },
  {
    postalCode: '19348',
    city: 'Margarethenthal'
  },
  {
    postalCode: '19348',
    city: 'Mollnitz'
  },
  {
    postalCode: '19348',
    city: 'Muggerkuhl'
  },
  {
    postalCode: '19348',
    city: 'Nebelin'
  },
  {
    postalCode: '19348',
    city: 'Neu Hohenvier'
  },
  {
    postalCode: '19348',
    city: 'Neuhausen'
  },
  {
    postalCode: '19348',
    city: 'Perleberg'
  },
  {
    postalCode: '19348',
    city: 'Pirow'
  },
  {
    postalCode: '19348',
    city: 'Platenhof'
  },
  {
    postalCode: '19348',
    city: 'Ponitz'
  },
  {
    postalCode: '19348',
    city: 'Quitzow'
  },
  {
    postalCode: '19348',
    city: 'Reetz'
  },
  {
    postalCode: '19348',
    city: 'Retzin'
  },
  {
    postalCode: '19348',
    city: 'Rohlsdorf'
  },
  {
    postalCode: '19348',
    city: 'Rosenhagen'
  },
  {
    postalCode: '19348',
    city: 'Schönfeld'
  },
  {
    postalCode: '19348',
    city: 'Schönholz'
  },
  {
    postalCode: '19348',
    city: 'Seddin'
  },
  {
    postalCode: '19348',
    city: 'Simonshagen'
  },
  {
    postalCode: '19348',
    city: 'Spiegelhagen'
  },
  {
    postalCode: '19348',
    city: 'Steinberg'
  },
  {
    postalCode: '19348',
    city: 'Strigleben'
  },
  {
    postalCode: '19348',
    city: 'Sükow'
  },
  {
    postalCode: '19348',
    city: 'Tacken'
  },
  {
    postalCode: '19348',
    city: 'Tangendorf'
  },
  {
    postalCode: '19348',
    city: 'Tiefenthal'
  },
  {
    postalCode: '19348',
    city: 'Uenze'
  },
  {
    postalCode: '19348',
    city: 'Waldhof'
  },
  {
    postalCode: '19348',
    city: 'Wolfshagen'
  },
  {
    postalCode: '19348',
    city: 'Wüsten-Buchholz'
  },
  {
    postalCode: '19357',
    city: 'Birkholz'
  },
  {
    postalCode: '19357',
    city: 'Blüthen'
  },
  {
    postalCode: '19357',
    city: 'Boberow'
  },
  {
    postalCode: '19357',
    city: 'Bootz'
  },
  {
    postalCode: '19357',
    city: 'Dallmin'
  },
  {
    postalCode: '19357',
    city: 'Dambeck'
  },
  {
    postalCode: '19357',
    city: 'Dargardt'
  },
  {
    postalCode: '19357',
    city: 'Garlin'
  },
  {
    postalCode: '19357',
    city: 'Glövzin'
  },
  {
    postalCode: '19357',
    city: 'Gosedahl'
  },
  {
    postalCode: '19357',
    city: 'Groß Warnow'
  },
  {
    postalCode: '19357',
    city: 'Karstädt'
  },
  {
    postalCode: '19357',
    city: 'Karwe'
  },
  {
    postalCode: '19357',
    city: 'Klein Warnow'
  },
  {
    postalCode: '19357',
    city: 'Klockow'
  },
  {
    postalCode: '19357',
    city: 'Klüß'
  },
  {
    postalCode: '19357',
    city: 'Kribbe'
  },
  {
    postalCode: '19357',
    city: 'Mankmuß'
  },
  {
    postalCode: '19357',
    city: 'Mesekow'
  },
  {
    postalCode: '19357',
    city: 'Neu Pinnow'
  },
  {
    postalCode: '19357',
    city: 'Neu Premslin'
  },
  {
    postalCode: '19357',
    city: 'Neuhof'
  },
  {
    postalCode: '19357',
    city: 'Pinnow'
  },
  {
    postalCode: '19357',
    city: 'Postlin'
  },
  {
    postalCode: '19357',
    city: 'Premslin'
  },
  {
    postalCode: '19357',
    city: 'Pröttlin'
  },
  {
    postalCode: '19357',
    city: 'Reckenzin'
  },
  {
    postalCode: '19357',
    city: 'Sargleben'
  },
  {
    postalCode: '19357',
    city: 'Seetz'
  },
  {
    postalCode: '19357',
    city: 'Simonshof'
  },
  {
    postalCode: '19357',
    city: 'Stavenow'
  },
  {
    postalCode: '19357',
    city: 'Streesow'
  },
  {
    postalCode: '19357',
    city: 'Strehlen'
  },
  {
    postalCode: '19357',
    city: 'Waterloo'
  },
  {
    postalCode: '19357',
    city: 'Wittmoor'
  },
  {
    postalCode: '19357',
    city: 'Zapel'
  },
  {
    postalCode: '19370',
    city: 'Parchim'
  },
  {
    postalCode: '19372',
    city: 'Bauerkuhl'
  },
  {
    postalCode: '19372',
    city: 'Brunow'
  },
  {
    postalCode: '19372',
    city: 'Darze'
  },
  {
    postalCode: '19372',
    city: 'Drefahl'
  },
  {
    postalCode: '19372',
    city: 'Dütschow'
  },
  {
    postalCode: '19372',
    city: 'Groß Godems'
  },
  {
    postalCode: '19372',
    city: 'Herzfeld'
  },
  {
    postalCode: '19372',
    city: 'Karrenzin'
  },
  {
    postalCode: '19372',
    city: 'Lancken'
  },
  {
    postalCode: '19372',
    city: 'Löcknitz'
  },
  {
    postalCode: '19372',
    city: 'Matzlow-Garwitz'
  },
  {
    postalCode: '19372',
    city: 'Meierstorf'
  },
  {
    postalCode: '19372',
    city: 'Pampin'
  },
  {
    postalCode: '19372',
    city: 'Platschow'
  },
  {
    postalCode: '19372',
    city: 'Primank'
  },
  {
    postalCode: '19372',
    city: 'Rom'
  },
  {
    postalCode: '19372',
    city: 'Spornitz'
  },
  {
    postalCode: '19372',
    city: 'Steinbeck'
  },
  {
    postalCode: '19372',
    city: 'Stolpe'
  },
  {
    postalCode: '19372',
    city: 'Stralendorf'
  },
  {
    postalCode: '19372',
    city: 'Stresendorf'
  },
  {
    postalCode: '19372',
    city: 'Ziegendorf'
  },
  {
    postalCode: '19374',
    city: 'Bergrade Dorf'
  },
  {
    postalCode: '19374',
    city: 'Damm'
  },
  {
    postalCode: '19374',
    city: 'Domsühl'
  },
  {
    postalCode: '19374',
    city: 'Frauenmark'
  },
  {
    postalCode: '19374',
    city: 'Friedrichsruhe'
  },
  {
    postalCode: '19374',
    city: 'Goldenbow'
  },
  {
    postalCode: '19374',
    city: 'Grebbin'
  },
  {
    postalCode: '19374',
    city: 'Groß Niendorf'
  },
  {
    postalCode: '19374',
    city: 'Herzberg'
  },
  {
    postalCode: '19374',
    city: 'Hof Grabow'
  },
  {
    postalCode: '19374',
    city: 'Kadow'
  },
  {
    postalCode: '19374',
    city: 'Kladrum'
  },
  {
    postalCode: '19374',
    city: 'Klinken'
  },
  {
    postalCode: '19374',
    city: 'Lenschow'
  },
  {
    postalCode: '19374',
    city: 'Mestlin'
  },
  {
    postalCode: '19374',
    city: 'Neu Ruthenbeck'
  },
  {
    postalCode: '19374',
    city: 'Raduhn'
  },
  {
    postalCode: '19374',
    city: 'Ruest'
  },
  {
    postalCode: '19374',
    city: 'Severin'
  },
  {
    postalCode: '19374',
    city: 'Vimfow'
  },
  {
    postalCode: '19374',
    city: 'Zieslübbe'
  },
  {
    postalCode: '19374',
    city: 'Zölkow'
  },
  {
    postalCode: '19376',
    city: 'Drenkow'
  },
  {
    postalCode: '19376',
    city: 'Jarchow'
  },
  {
    postalCode: '19376',
    city: 'Leppin'
  },
  {
    postalCode: '19376',
    city: 'Marnitz'
  },
  {
    postalCode: '19376',
    city: 'Mentin'
  },
  {
    postalCode: '19376',
    city: 'Poltnitz'
  },
  {
    postalCode: '19376',
    city: 'Siggelkow'
  },
  {
    postalCode: '19376',
    city: 'Suckow'
  },
  {
    postalCode: '19376',
    city: 'Tessenow'
  },
  {
    postalCode: '19386',
    city: 'Broock'
  },
  {
    postalCode: '19386',
    city: 'Gallin'
  },
  {
    postalCode: '19386',
    city: 'Gischow'
  },
  {
    postalCode: '19386',
    city: 'Granzin'
  },
  {
    postalCode: '19386',
    city: 'Karbow-Vietlübbe'
  },
  {
    postalCode: '19386',
    city: 'Kreien'
  },
  {
    postalCode: '19386',
    city: 'Kritzow'
  },
  {
    postalCode: '19386',
    city: 'Kuhwalk'
  },
  {
    postalCode: '19386',
    city: 'Kuppentin'
  },
  {
    postalCode: '19386',
    city: 'Lübz'
  },
  {
    postalCode: '19386',
    city: 'Lutheran'
  },
  {
    postalCode: '19386',
    city: 'Passow'
  },
  {
    postalCode: '19386',
    city: 'Quaßliner Mühle'
  },
  {
    postalCode: '19386',
    city: 'Sandkrug'
  },
  {
    postalCode: '19386',
    city: 'Wahlstorf'
  },
  {
    postalCode: '19386',
    city: 'Werder'
  },
  {
    postalCode: '19395',
    city: 'Barkow'
  },
  {
    postalCode: '19395',
    city: 'Ganzlin'
  },
  {
    postalCode: '19395',
    city: 'Gnevsdorf'
  },
  {
    postalCode: '19395',
    city: 'Hahnenhorst'
  },
  {
    postalCode: '19395',
    city: 'Karow'
  },
  {
    postalCode: '19395',
    city: 'Plau'
  },
  {
    postalCode: '19395',
    city: 'Plauerhagen'
  },
  {
    postalCode: '19395',
    city: 'Redewisch'
  },
  {
    postalCode: '19395',
    city: 'Retzow'
  },
  {
    postalCode: '19395',
    city: 'Wendisch Priborn'
  },
  {
    postalCode: '19395',
    city: 'Wunderfeld'
  },
  {
    postalCode: '19399',
    city: 'Diestelow'
  },
  {
    postalCode: '19399',
    city: 'Dobbertin'
  },
  {
    postalCode: '19399',
    city: 'Goldberg'
  },
  {
    postalCode: '19399',
    city: 'Jellen bei Dobbertin'
  },
  {
    postalCode: '19399',
    city: 'Jellen bei Goldberg'
  },
  {
    postalCode: '19399',
    city: 'Kleesten'
  },
  {
    postalCode: '19399',
    city: 'Langenhagen'
  },
  {
    postalCode: '19399',
    city: 'Neu Poserin'
  },
  {
    postalCode: '19399',
    city: 'Suckwitz Abbau'
  },
  {
    postalCode: '19399',
    city: 'Techentin'
  },
  {
    postalCode: '19399',
    city: 'Unterbrüz'
  },
  {
    postalCode: '19399',
    city: 'Wendisch Waren'
  },
  {
    postalCode: '19406',
    city: 'Bolz'
  },
  {
    postalCode: '19406',
    city: 'Borkow'
  },
  {
    postalCode: '19406',
    city: 'Dabel'
  },
  {
    postalCode: '19406',
    city: 'Dessin'
  },
  {
    postalCode: '19406',
    city: 'Dinnies'
  },
  {
    postalCode: '19406',
    city: 'Gägelow'
  },
  {
    postalCode: '19406',
    city: 'Groß Görnow'
  },
  {
    postalCode: '19406',
    city: 'Groß Raden'
  },
  {
    postalCode: '19406',
    city: 'Hohen Pritz'
  },
  {
    postalCode: '19406',
    city: 'Hohenfelde'
  },
  {
    postalCode: '19406',
    city: 'Holzendorf bei Sternberg'
  },
  {
    postalCode: '19406',
    city: 'Klein Görnow'
  },
  {
    postalCode: '19406',
    city: 'Klein Pritz'
  },
  {
    postalCode: '19406',
    city: 'Kobrow'
  },
  {
    postalCode: '19406',
    city: 'Lenzen'
  },
  {
    postalCode: '19406',
    city: 'Loiz'
  },
  {
    postalCode: '19406',
    city: 'Mustin'
  },
  {
    postalCode: '19406',
    city: 'Neu Pastin'
  },
  {
    postalCode: '19406',
    city: 'Neu Woserin'
  },
  {
    postalCode: '19406',
    city: 'Pastin'
  },
  {
    postalCode: '19406',
    city: 'Rothen'
  },
  {
    postalCode: '19406',
    city: 'Ruchow'
  },
  {
    postalCode: '19406',
    city: 'Sagsdorf'
  },
  {
    postalCode: '19406',
    city: 'Schlowe'
  },
  {
    postalCode: '19406',
    city: 'Schönfeld'
  },
  {
    postalCode: '19406',
    city: 'Sternberg'
  },
  {
    postalCode: '19406',
    city: 'Sternberger Burg'
  },
  {
    postalCode: '19406',
    city: 'Stieten'
  },
  {
    postalCode: '19406',
    city: 'Turloff'
  },
  {
    postalCode: '19406',
    city: 'Wamckow'
  },
  {
    postalCode: '19406',
    city: 'Witzin'
  },
  {
    postalCode: '19406',
    city: 'Woland'
  },
  {
    postalCode: '19406',
    city: 'Woserin'
  },
  {
    postalCode: '19406',
    city: 'Zülow'
  },
  {
    postalCode: '19412',
    city: 'Alt Necheln'
  },
  {
    postalCode: '19412',
    city: 'Blankenberg'
  },
  {
    postalCode: '19412',
    city: 'Brüel'
  },
  {
    postalCode: '19412',
    city: 'Friedrichswalde'
  },
  {
    postalCode: '19412',
    city: 'Golchen'
  },
  {
    postalCode: '19412',
    city: 'Gustävel'
  },
  {
    postalCode: '19412',
    city: 'Häven'
  },
  {
    postalCode: '19412',
    city: 'Holdorf'
  },
  {
    postalCode: '19412',
    city: 'Holzendorf'
  },
  {
    postalCode: '19412',
    city: 'Jülchendorf'
  },
  {
    postalCode: '19412',
    city: 'Kaarz'
  },
  {
    postalCode: '19412',
    city: 'Keez'
  },
  {
    postalCode: '19412',
    city: 'Klein Jarchow'
  },
  {
    postalCode: '19412',
    city: 'Kuhlen'
  },
  {
    postalCode: '19412',
    city: 'Langen Jarchow'
  },
  {
    postalCode: '19412',
    city: 'Müsselmow'
  },
  {
    postalCode: '19412',
    city: 'Neu Necheln'
  },
  {
    postalCode: '19412',
    city: 'Nutteln'
  },
  {
    postalCode: '19412',
    city: 'Penzin'
  },
  {
    postalCode: '19412',
    city: 'Schönlage'
  },
  {
    postalCode: '19412',
    city: 'Sülten'
  },
  {
    postalCode: '19412',
    city: 'Tempzin'
  },
  {
    postalCode: '19412',
    city: 'Tessin'
  },
  {
    postalCode: '19412',
    city: 'Thurow'
  },
  {
    postalCode: '19412',
    city: 'Weberin'
  },
  {
    postalCode: '19412',
    city: 'Weitendorf'
  },
  {
    postalCode: '19412',
    city: 'Wendorf'
  },
  {
    postalCode: '19412',
    city: 'Wipersdorf'
  },
  {
    postalCode: '19412',
    city: 'Zahrensdorf'
  },
  {
    postalCode: '19412',
    city: 'Zaschendorf'
  },
  {
    postalCode: '19417',
    city: 'Bibow'
  },
  {
    postalCode: '19417',
    city: 'Büschow'
  },
  {
    postalCode: '19417',
    city: 'Dämelow'
  },
  {
    postalCode: '19417',
    city: 'Groß Labenz'
  },
  {
    postalCode: '19417',
    city: 'Hasenwinkel'
  },
  {
    postalCode: '19417',
    city: 'Jesendorf'
  },
  {
    postalCode: '19417',
    city: 'Kleekamp'
  },
  {
    postalCode: '19417',
    city: 'Klein Labenz'
  },
  {
    postalCode: '19417',
    city: 'Lindenhof'
  },
  {
    postalCode: '19417',
    city: 'Mankmoos'
  },
  {
    postalCode: '19417',
    city: 'Neperstorf'
  },
  {
    postalCode: '19417',
    city: 'Neu Pennewitt'
  },
  {
    postalCode: '19417',
    city: 'Neuhof'
  },
  {
    postalCode: '19417',
    city: 'Nisbill'
  },
  {
    postalCode: '19417',
    city: 'Pennewitt'
  },
  {
    postalCode: '19417',
    city: 'Trams'
  },
  {
    postalCode: '19417',
    city: 'Ventschow'
  },
  {
    postalCode: '19417',
    city: 'Warin'
  },
  {
    postalCode: '19417',
    city: 'Weiße Krug'
  },
  {
    postalCode: '20095',
    city: 'Hamburg'
  },
  {
    postalCode: '20097',
    city: 'Hamburg'
  },
  {
    postalCode: '20099',
    city: 'Hamburg'
  },
  {
    postalCode: '20144',
    city: 'Hamburg'
  },
  {
    postalCode: '20146',
    city: 'Hamburg'
  },
  {
    postalCode: '20148',
    city: 'Hamburg'
  },
  {
    postalCode: '20149',
    city: 'Hamburg'
  },
  {
    postalCode: '20249',
    city: 'Hamburg'
  },
  {
    postalCode: '20251',
    city: 'Hamburg'
  },
  {
    postalCode: '20253',
    city: 'Hamburg'
  },
  {
    postalCode: '20255',
    city: 'Hamburg'
  },
  {
    postalCode: '20257',
    city: 'Hamburg'
  },
  {
    postalCode: '20259',
    city: 'Hamburg'
  },
  {
    postalCode: '20354',
    city: 'Hamburg'
  },
  {
    postalCode: '20355',
    city: 'Hamburg'
  },
  {
    postalCode: '20357',
    city: 'Hamburg'
  },
  {
    postalCode: '20359',
    city: 'Hamburg'
  },
  {
    postalCode: '20457',
    city: 'Hamburg'
  },
  {
    postalCode: '20459',
    city: 'Hamburg'
  },
  {
    postalCode: '20535',
    city: 'Hamburg'
  },
  {
    postalCode: '20537',
    city: 'Hamburg'
  },
  {
    postalCode: '20539',
    city: 'Hamburg'
  },
  {
    postalCode: '21029',
    city: 'Hamburg'
  },
  {
    postalCode: '21031',
    city: 'Hamburg'
  },
  {
    postalCode: '21033',
    city: 'Hamburg'
  },
  {
    postalCode: '21035',
    city: 'Hamburg'
  },
  {
    postalCode: '21037',
    city: 'Hamburg'
  },
  {
    postalCode: '21039',
    city: 'Börnsen'
  },
  {
    postalCode: '21039',
    city: 'Escheburg'
  },
  {
    postalCode: '21039',
    city: 'Fahrendorf'
  },
  {
    postalCode: '21039',
    city: 'Hamburg'
  },
  {
    postalCode: '21073',
    city: 'Hamburg'
  },
  {
    postalCode: '21075',
    city: 'Hamburg'
  },
  {
    postalCode: '21077',
    city: 'Hamburg'
  },
  {
    postalCode: '21079',
    city: 'Hamburg'
  },
  {
    postalCode: '21107',
    city: 'Hamburg'
  },
  {
    postalCode: '21109',
    city: 'Hamburg'
  },
  {
    postalCode: '21129',
    city: 'Hamburg'
  },
  {
    postalCode: '21147',
    city: 'Hamburg'
  },
  {
    postalCode: '21149',
    city: 'Hamburg'
  },
  {
    postalCode: '21217',
    city: 'Seevetal'
  },
  {
    postalCode: '21218',
    city: 'Seevetal'
  },
  {
    postalCode: '21220',
    city: 'Seevetal'
  },
  {
    postalCode: '21224',
    city: 'Rosengarten'
  },
  {
    postalCode: '21227',
    city: 'Am Jägerberg'
  },
  {
    postalCode: '21227',
    city: 'Bendestorf'
  },
  {
    postalCode: '21228',
    city: 'Harmstorf'
  },
  {
    postalCode: '21244',
    city: 'Buchholz'
  },
  {
    postalCode: '21255',
    city: 'Dohren'
  },
  {
    postalCode: '21255',
    city: 'Drestedt-Valzik'
  },
  {
    postalCode: '21255',
    city: 'Kakenstorf'
  },
  {
    postalCode: '21255',
    city: 'Königsmoor'
  },
  {
    postalCode: '21255',
    city: 'Riepshof'
  },
  {
    postalCode: '21255',
    city: 'Tostedt'
  },
  {
    postalCode: '21255',
    city: 'Wistedt'
  },
  {
    postalCode: '21256',
    city: 'Handeloh'
  },
  {
    postalCode: '21258',
    city: 'Heidenau'
  },
  {
    postalCode: '21259',
    city: 'Otter'
  },
  {
    postalCode: '21261',
    city: 'Welle'
  },
  {
    postalCode: '21266',
    city: 'Jesteburg'
  },
  {
    postalCode: '21271',
    city: 'Asendorf'
  },
  {
    postalCode: '21271',
    city: 'Hanstedt'
  },
  {
    postalCode: '21272',
    city: 'Egestorf'
  },
  {
    postalCode: '21274',
    city: 'Undeloh'
  },
  {
    postalCode: '21279',
    city: 'Appel'
  },
  {
    postalCode: '21279',
    city: 'Appelbeck'
  },
  {
    postalCode: '21279',
    city: 'Drestedt'
  },
  {
    postalCode: '21279',
    city: 'Hollenstedt'
  },
  {
    postalCode: '21279',
    city: 'Wenzendorf'
  },
  {
    postalCode: '21335',
    city: 'Lüneburg'
  },
  {
    postalCode: '21337',
    city: 'Lüneburg'
  },
  {
    postalCode: '21339',
    city: 'Lüneburg'
  },
  {
    postalCode: '21354',
    city: 'Bleckede'
  },
  {
    postalCode: '21357',
    city: 'Bardowick'
  },
  {
    postalCode: '21357',
    city: 'Barum'
  },
  {
    postalCode: '21357',
    city: 'Wittorf'
  },
  {
    postalCode: '21358',
    city: 'Mechtersen'
  },
  {
    postalCode: '21360',
    city: 'Vögelsen'
  },
  {
    postalCode: '21365',
    city: 'Adendorf'
  },
  {
    postalCode: '21368',
    city: 'Boitze'
  },
  {
    postalCode: '21368',
    city: 'Dahlem'
  },
  {
    postalCode: '21368',
    city: 'Dahlenburg'
  },
  {
    postalCode: '21369',
    city: 'Nahrendorf'
  },
  {
    postalCode: '21371',
    city: 'Tosterglope'
  },
  {
    postalCode: '21376',
    city: 'Eyendorf'
  },
  {
    postalCode: '21376',
    city: 'Garlstorf'
  },
  {
    postalCode: '21376',
    city: 'Gödenstorf'
  },
  {
    postalCode: '21376',
    city: 'Salzhausen'
  },
  {
    postalCode: '21379',
    city: 'Echem'
  },
  {
    postalCode: '21379',
    city: 'Lüdersburg'
  },
  {
    postalCode: '21379',
    city: 'Neumühlen'
  },
  {
    postalCode: '21379',
    city: 'Rullstorf'
  },
  {
    postalCode: '21379',
    city: 'Scharnebeck'
  },
  {
    postalCode: '21380',
    city: 'Artlenburg'
  },
  {
    postalCode: '21382',
    city: 'Brietlingen'
  },
  {
    postalCode: '21385',
    city: 'Amelinghausen'
  },
  {
    postalCode: '21385',
    city: 'Oldendorf'
  },
  {
    postalCode: '21385',
    city: 'Rehlingen'
  },
  {
    postalCode: '21386',
    city: 'Betzendorf'
  },
  {
    postalCode: '21388',
    city: 'Aspelhorn'
  },
  {
    postalCode: '21388',
    city: 'Soderstorf'
  },
  {
    postalCode: '21391',
    city: 'Lüneburg'
  },
  {
    postalCode: '21391',
    city: 'Reppenstedt'
  },
  {
    postalCode: '21394',
    city: 'Kirchgellersen'
  },
  {
    postalCode: '21394',
    city: 'Südergellersen'
  },
  {
    postalCode: '21394',
    city: 'Westergellersen'
  },
  {
    postalCode: '21395',
    city: 'Tespe'
  },
  {
    postalCode: '21397',
    city: 'Barendorf'
  },
  {
    postalCode: '21397',
    city: 'Vastorf'
  },
  {
    postalCode: '21398',
    city: 'Neetze'
  },
  {
    postalCode: '21400',
    city: 'Reinstorf'
  },
  {
    postalCode: '21401',
    city: 'Thomasburg'
  },
  {
    postalCode: '21403',
    city: 'Wendisch Evern'
  },
  {
    postalCode: '21406',
    city: 'Barnstedt'
  },
  {
    postalCode: '21406',
    city: 'Melbeck'
  },
  {
    postalCode: '21407',
    city: 'Deutsch Evern'
  },
  {
    postalCode: '21409',
    city: 'Embsen'
  },
  {
    postalCode: '21423',
    city: 'Drage'
  },
  {
    postalCode: '21423',
    city: 'Winsen'
  },
  {
    postalCode: '21435',
    city: 'Stelle'
  },
  {
    postalCode: '21436',
    city: 'Marschacht'
  },
  {
    postalCode: '21438',
    city: 'Brackel'
  },
  {
    postalCode: '21439',
    city: 'Marxen'
  },
  {
    postalCode: '21441',
    city: 'Garstedt'
  },
  {
    postalCode: '21442',
    city: 'Toppenstedt'
  },
  {
    postalCode: '21444',
    city: 'Einemhof'
  },
  {
    postalCode: '21444',
    city: 'Vierhöfen'
  },
  {
    postalCode: '21445',
    city: 'Wulfsen'
  },
  {
    postalCode: '21447',
    city: 'Handorf'
  },
  {
    postalCode: '21449',
    city: 'Radbruch'
  },
  {
    postalCode: '21465',
    city: 'Reinbek'
  },
  {
    postalCode: '21465',
    city: 'Wentorf'
  },
  {
    postalCode: '21481',
    city: 'Buchhorst'
  },
  {
    postalCode: '21481',
    city: 'Lauenburg'
  },
  {
    postalCode: '21481',
    city: 'Schnakenbek'
  },
  {
    postalCode: '21483',
    city: 'Basedow'
  },
  {
    postalCode: '21483',
    city: 'Dalldorf'
  },
  {
    postalCode: '21483',
    city: 'Gülzow'
  },
  {
    postalCode: '21483',
    city: 'Juliusburg'
  },
  {
    postalCode: '21483',
    city: 'Krukow'
  },
  {
    postalCode: '21483',
    city: 'Krüzen'
  },
  {
    postalCode: '21483',
    city: 'Lanze'
  },
  {
    postalCode: '21483',
    city: 'Lütau'
  },
  {
    postalCode: '21483',
    city: 'Wangelau'
  },
  {
    postalCode: '21493',
    city: 'Basthorst'
  },
  {
    postalCode: '21493',
    city: 'Elmenhorst'
  },
  {
    postalCode: '21493',
    city: 'Fuhlenhagen'
  },
  {
    postalCode: '21493',
    city: 'Grabau'
  },
  {
    postalCode: '21493',
    city: 'Groß Pampau'
  },
  {
    postalCode: '21493',
    city: 'Grove'
  },
  {
    postalCode: '21493',
    city: 'Havekost'
  },
  {
    postalCode: '21493',
    city: 'Kollow, Kurheim'
  },
  {
    postalCode: '21493',
    city: 'Louisenhof'
  },
  {
    postalCode: '21493',
    city: 'Melusinental'
  },
  {
    postalCode: '21493',
    city: 'Möhnsen'
  },
  {
    postalCode: '21493',
    city: 'Mühlenrade'
  },
  {
    postalCode: '21493',
    city: 'Sahms'
  },
  {
    postalCode: '21493',
    city: 'Schretstaken'
  },
  {
    postalCode: '21493',
    city: 'Schwarzenbek'
  },
  {
    postalCode: '21493',
    city: 'Talkau'
  },
  {
    postalCode: '21502',
    city: 'Geesthacht'
  },
  {
    postalCode: '21502',
    city: 'Hamwarde'
  },
  {
    postalCode: '21502',
    city: 'Heidkaten'
  },
  {
    postalCode: '21502',
    city: 'Wiershop'
  },
  {
    postalCode: '21502',
    city: 'Worth'
  },
  {
    postalCode: '21509',
    city: 'Glinde'
  },
  {
    postalCode: '21514',
    city: 'Bröthen'
  },
  {
    postalCode: '21514',
    city: 'Büchen'
  },
  {
    postalCode: '21514',
    city: 'Fitzen'
  },
  {
    postalCode: '21514',
    city: 'Göttin'
  },
  {
    postalCode: '21514',
    city: 'Güster'
  },
  {
    postalCode: '21514',
    city: 'Hornbek'
  },
  {
    postalCode: '21514',
    city: 'Kankelau'
  },
  {
    postalCode: '21514',
    city: 'Klein Pampau'
  },
  {
    postalCode: '21514',
    city: 'Langenlehsten'
  },
  {
    postalCode: '21514',
    city: 'Neue Mühle'
  },
  {
    postalCode: '21514',
    city: 'Neugüster'
  },
  {
    postalCode: '21514',
    city: 'Roseburg'
  },
  {
    postalCode: '21514',
    city: 'Siebeneichen'
  },
  {
    postalCode: '21514',
    city: 'Witzeeze'
  },
  {
    postalCode: '21514',
    city: 'Wotersen'
  },
  {
    postalCode: '21516',
    city: 'Müssen'
  },
  {
    postalCode: '21516',
    city: 'Schulendorf'
  },
  {
    postalCode: '21516',
    city: 'Tramm'
  },
  {
    postalCode: '21516',
    city: 'Woltersdorf'
  },
  {
    postalCode: '21521',
    city: 'Aumühle'
  },
  {
    postalCode: '21521',
    city: 'Bismarckquelle'
  },
  {
    postalCode: '21521',
    city: 'Dassendorf'
  },
  {
    postalCode: '21521',
    city: 'Friedrichsruh'
  },
  {
    postalCode: '21521',
    city: 'Wohltorf'
  },
  {
    postalCode: '21522',
    city: 'Hittbergen'
  },
  {
    postalCode: '21522',
    city: 'Hohnstorf'
  },
  {
    postalCode: '21524',
    city: 'Brunstorf'
  },
  {
    postalCode: '21526',
    city: 'Hohenhorn'
  },
  {
    postalCode: '21527',
    city: 'Kollow'
  },
  {
    postalCode: '21529',
    city: 'Kröppelshagen-Fahrendorf'
  },
  {
    postalCode: '21614',
    city: 'Buxtehude'
  },
  {
    postalCode: '21629',
    city: 'Neu Wulmstorf'
  },
  {
    postalCode: '21635',
    city: 'Hinterdeich'
  },
  {
    postalCode: '21635',
    city: 'Jork'
  },
  {
    postalCode: '21640',
    city: 'Bliedersdorf'
  },
  {
    postalCode: '21640',
    city: 'Horneburg'
  },
  {
    postalCode: '21640',
    city: 'Neuenkirchen'
  },
  {
    postalCode: '21640',
    city: 'Nottensdorf'
  },
  {
    postalCode: '21641',
    city: 'Apensen'
  },
  {
    postalCode: '21643',
    city: 'Beckdorf'
  },
  {
    postalCode: '21644',
    city: 'Sauensiek'
  },
  {
    postalCode: '21646',
    city: 'Halvesbostel'
  },
  {
    postalCode: '21647',
    city: 'Moisburg'
  },
  {
    postalCode: '21649',
    city: 'Regesbostel'
  },
  {
    postalCode: '21680',
    city: 'Stade'
  },
  {
    postalCode: '21682',
    city: 'Stade'
  },
  {
    postalCode: '21683',
    city: 'Stade'
  },
  {
    postalCode: '21684',
    city: 'Agathenburg'
  },
  {
    postalCode: '21684',
    city: 'Stade'
  },
  {
    postalCode: '21698',
    city: 'Bargstedt'
  },
  {
    postalCode: '21698',
    city: 'Brest'
  },
  {
    postalCode: '21698',
    city: 'Harsefeld'
  },
  {
    postalCode: '21702',
    city: 'Ahlerstedt'
  },
  {
    postalCode: '21706',
    city: 'Am Rönndeich'
  },
  {
    postalCode: '21706',
    city: 'Drochtersen'
  },
  {
    postalCode: '21709',
    city: 'Burweg'
  },
  {
    postalCode: '21709',
    city: 'Düdenbüttel'
  },
  {
    postalCode: '21709',
    city: 'Himmelpforten'
  },
  {
    postalCode: '21710',
    city: 'Engelschoff'
  },
  {
    postalCode: '21712',
    city: 'Großenwörden'
  },
  {
    postalCode: '21714',
    city: 'Hammah'
  },
  {
    postalCode: '21717',
    city: 'Deinste'
  },
  {
    postalCode: '21717',
    city: 'Fredenbeck'
  },
  {
    postalCode: '21720',
    city: 'Grünendeich'
  },
  {
    postalCode: '21720',
    city: 'Guderhandviertel'
  },
  {
    postalCode: '21720',
    city: 'Mittelnkirchen'
  },
  {
    postalCode: '21720',
    city: 'Steinkirchen'
  },
  {
    postalCode: '21723',
    city: 'Hollern-Twielenfleth'
  },
  {
    postalCode: '21726',
    city: 'Heinbockel'
  },
  {
    postalCode: '21726',
    city: 'Kranenburg'
  },
  {
    postalCode: '21726',
    city: 'Oldendorf'
  },
  {
    postalCode: '21727',
    city: 'Estorf'
  },
  {
    postalCode: '21729',
    city: 'Freiburg'
  },
  {
    postalCode: '21730',
    city: 'Balje'
  },
  {
    postalCode: '21732',
    city: 'Krummendeich'
  },
  {
    postalCode: '21734',
    city: 'Breitendeich'
  },
  {
    postalCode: '21734',
    city: 'Eggerkamp'
  },
  {
    postalCode: '21734',
    city: 'Hollerdeich'
  },
  {
    postalCode: '21734',
    city: 'Oederquart'
  },
  {
    postalCode: '21734',
    city: 'Zehntweg'
  },
  {
    postalCode: '21737',
    city: 'Wischhafen'
  },
  {
    postalCode: '21739',
    city: 'Dollern'
  },
  {
    postalCode: '21745',
    city: 'Hemmoor'
  },
  {
    postalCode: '21755',
    city: 'Hechthausen'
  },
  {
    postalCode: '21756',
    city: 'Osten'
  },
  {
    postalCode: '21762',
    city: 'Osterbruch'
  },
  {
    postalCode: '21762',
    city: 'Otterndorf'
  },
  {
    postalCode: '21763',
    city: 'Neuenkirchen'
  },
  {
    postalCode: '21765',
    city: 'Nordleda'
  },
  {
    postalCode: '21769',
    city: 'Armstorf'
  },
  {
    postalCode: '21769',
    city: 'Hollnseth'
  },
  {
    postalCode: '21769',
    city: 'Lamstedt'
  },
  {
    postalCode: '21770',
    city: 'Mittelstenahe'
  },
  {
    postalCode: '21772',
    city: 'Stinstedt'
  },
  {
    postalCode: '21775',
    city: 'Ihlienworth'
  },
  {
    postalCode: '21775',
    city: 'Odisheim'
  },
  {
    postalCode: '21775',
    city: 'Steinau'
  },
  {
    postalCode: '21776',
    city: 'Wanna'
  },
  {
    postalCode: '21781',
    city: 'Cadenberge'
  },
  {
    postalCode: '21782',
    city: 'Bülkau'
  },
  {
    postalCode: '21784',
    city: 'Geversdorf'
  },
  {
    postalCode: '21785',
    city: 'Belum'
  },
  {
    postalCode: '21785',
    city: 'Neuhaus'
  },
  {
    postalCode: '21787',
    city: 'Oberndorf'
  },
  {
    postalCode: '21789',
    city: 'Wingst'
  },
  {
    postalCode: '22041',
    city: 'Hamburg'
  },
  {
    postalCode: '22043',
    city: 'Hamburg'
  },
  {
    postalCode: '22045',
    city: 'Hamburg'
  },
  {
    postalCode: '22047',
    city: 'Hamburg'
  },
  {
    postalCode: '22049',
    city: 'Hamburg'
  },
  {
    postalCode: '22081',
    city: 'Hamburg'
  },
  {
    postalCode: '22083',
    city: 'Hamburg'
  },
  {
    postalCode: '22085',
    city: 'Hamburg'
  },
  {
    postalCode: '22087',
    city: 'Hamburg'
  },
  {
    postalCode: '22089',
    city: 'Hamburg'
  },
  {
    postalCode: '22111',
    city: 'Hamburg'
  },
  {
    postalCode: '22113',
    city: 'Hamburg'
  },
  {
    postalCode: '22113',
    city: 'Oststeinbek'
  },
  {
    postalCode: '22115',
    city: 'Hamburg'
  },
  {
    postalCode: '22117',
    city: 'Hamburg'
  },
  {
    postalCode: '22119',
    city: 'Hamburg'
  },
  {
    postalCode: '22143',
    city: 'Hamburg'
  },
  {
    postalCode: '22145',
    city: 'Braak'
  },
  {
    postalCode: '22145',
    city: 'Hamburg'
  },
  {
    postalCode: '22145',
    city: 'Stapelfeld'
  },
  {
    postalCode: '22147',
    city: 'Hamburg'
  },
  {
    postalCode: '22149',
    city: 'Hamburg'
  },
  {
    postalCode: '22159',
    city: 'Hamburg'
  },
  {
    postalCode: '22175',
    city: 'Hamburg'
  },
  {
    postalCode: '22177',
    city: 'Hamburg'
  },
  {
    postalCode: '22179',
    city: 'Hamburg'
  },
  {
    postalCode: '22297',
    city: 'Hamburg'
  },
  {
    postalCode: '22299',
    city: 'Hamburg'
  },
  {
    postalCode: '22301',
    city: 'Hamburg'
  },
  {
    postalCode: '22303',
    city: 'Hamburg'
  },
  {
    postalCode: '22305',
    city: 'Hamburg'
  },
  {
    postalCode: '22307',
    city: 'Hamburg'
  },
  {
    postalCode: '22309',
    city: 'Hamburg'
  },
  {
    postalCode: '22335',
    city: 'Hamburg'
  },
  {
    postalCode: '22337',
    city: 'Hamburg'
  },
  {
    postalCode: '22339',
    city: 'Hamburg'
  },
  {
    postalCode: '22359',
    city: 'Hamburg'
  },
  {
    postalCode: '22391',
    city: 'Hamburg'
  },
  {
    postalCode: '22393',
    city: 'Hamburg'
  },
  {
    postalCode: '22395',
    city: 'Hamburg'
  },
  {
    postalCode: '22397',
    city: 'Hamburg'
  },
  {
    postalCode: '22399',
    city: 'Hamburg'
  },
  {
    postalCode: '22415',
    city: 'Hamburg'
  },
  {
    postalCode: '22417',
    city: 'Hamburg'
  },
  {
    postalCode: '22419',
    city: 'Hamburg'
  },
  {
    postalCode: '22453',
    city: 'Hamburg'
  },
  {
    postalCode: '22455',
    city: 'Hamburg'
  },
  {
    postalCode: '22457',
    city: 'Hamburg'
  },
  {
    postalCode: '22459',
    city: 'Hamburg'
  },
  {
    postalCode: '22523',
    city: 'Hamburg'
  },
  {
    postalCode: '22525',
    city: 'Hamburg'
  },
  {
    postalCode: '22527',
    city: 'Hamburg'
  },
  {
    postalCode: '22529',
    city: 'Hamburg'
  },
  {
    postalCode: '22547',
    city: 'Hamburg'
  },
  {
    postalCode: '22549',
    city: 'Hamburg'
  },
  {
    postalCode: '22559',
    city: 'Hamburg'
  },
  {
    postalCode: '22587',
    city: 'Hamburg'
  },
  {
    postalCode: '22589',
    city: 'Hamburg'
  },
  {
    postalCode: '22605',
    city: 'Hamburg'
  },
  {
    postalCode: '22607',
    city: 'Hamburg'
  },
  {
    postalCode: '22609',
    city: 'Hamburg'
  },
  {
    postalCode: '22761',
    city: 'Hamburg'
  },
  {
    postalCode: '22763',
    city: 'Hamburg'
  },
  {
    postalCode: '22765',
    city: 'Hamburg'
  },
  {
    postalCode: '22767',
    city: 'Hamburg'
  },
  {
    postalCode: '22769',
    city: 'Hamburg'
  },
  {
    postalCode: '22844',
    city: 'Norderstedt'
  },
  {
    postalCode: '22846',
    city: 'Norderstedt'
  },
  {
    postalCode: '22848',
    city: 'Norderstedt'
  },
  {
    postalCode: '22850',
    city: 'Norderstedt'
  },
  {
    postalCode: '22851',
    city: 'Norderstedt'
  },
  {
    postalCode: '22869',
    city: 'Schenefeld'
  },
  {
    postalCode: '22880',
    city: 'Wedel'
  },
  {
    postalCode: '22885',
    city: 'Barsbüttel'
  },
  {
    postalCode: '22889',
    city: 'Tangstedt'
  },
  {
    postalCode: '22926',
    city: 'Ahrensburg'
  },
  {
    postalCode: '22927',
    city: 'Großhansdorf'
  },
  {
    postalCode: '22929',
    city: 'Hamfelde, Kr Hzgt Lauenburg'
  },
  {
    postalCode: '22929',
    city: 'Hamfelde, Kr Stormarn'
  },
  {
    postalCode: '22929',
    city: 'Kasseburg'
  },
  {
    postalCode: '22929',
    city: 'Köthel, Kr Hzgt Lauenburg'
  },
  {
    postalCode: '22929',
    city: 'Köthel, Kr Stormarn'
  },
  {
    postalCode: '22929',
    city: 'Rausdorf'
  },
  {
    postalCode: '22929',
    city: 'Schönberg'
  },
  {
    postalCode: '22941',
    city: 'Bargteheide'
  },
  {
    postalCode: '22941',
    city: 'Delingsdorf'
  },
  {
    postalCode: '22941',
    city: 'Hammoor'
  },
  {
    postalCode: '22941',
    city: 'Jersbek'
  },
  {
    postalCode: '22946',
    city: 'Brunsbek'
  },
  {
    postalCode: '22946',
    city: 'Dahmker'
  },
  {
    postalCode: '22946',
    city: 'Grande'
  },
  {
    postalCode: '22946',
    city: 'Großensee'
  },
  {
    postalCode: '22946',
    city: 'Hohenfelde'
  },
  {
    postalCode: '22946',
    city: 'Trittau'
  },
  {
    postalCode: '22949',
    city: 'Ammersbek'
  },
  {
    postalCode: '22952',
    city: 'Lütjensee'
  },
  {
    postalCode: '22955',
    city: 'Hoisdorf'
  },
  {
    postalCode: '22956',
    city: 'Grönwohld'
  },
  {
    postalCode: '22958',
    city: 'Kuddewörde'
  },
  {
    postalCode: '22959',
    city: 'Linau'
  },
  {
    postalCode: '22961',
    city: 'Oetjendorf'
  },
  {
    postalCode: '22962',
    city: 'Siek'
  },
  {
    postalCode: '22964',
    city: 'Steinburg'
  },
  {
    postalCode: '22965',
    city: 'Todendorf'
  },
  {
    postalCode: '22967',
    city: 'Tremsbüttel'
  },
  {
    postalCode: '22969',
    city: 'Witzhave'
  },
  {
    postalCode: '23552',
    city: 'Lübeck'
  },
  {
    postalCode: '23554',
    city: 'Lübeck'
  },
  {
    postalCode: '23556',
    city: 'Lübeck'
  },
  {
    postalCode: '23558',
    city: 'Lübeck'
  },
  {
    postalCode: '23560',
    city: 'Lübeck'
  },
  {
    postalCode: '23562',
    city: 'Lübeck'
  },
  {
    postalCode: '23564',
    city: 'Lübeck'
  },
  {
    postalCode: '23566',
    city: 'Lübeck'
  },
  {
    postalCode: '23568',
    city: 'Lübeck'
  },
  {
    postalCode: '23569',
    city: 'Lübeck'
  },
  {
    postalCode: '23570',
    city: 'Lübeck'
  },
  {
    postalCode: '23611',
    city: 'Bad Schwartau'
  },
  {
    postalCode: '23611',
    city: 'Sereetz'
  },
  {
    postalCode: '23611',
    city: 'Sereetzerfeld'
  },
  {
    postalCode: '23611',
    city: 'Sielbek'
  },
  {
    postalCode: '23617',
    city: 'Stockelsdorf'
  },
  {
    postalCode: '23619',
    city: 'Badendorf'
  },
  {
    postalCode: '23619',
    city: 'Goldenerhahn'
  },
  {
    postalCode: '23619',
    city: 'Hamberge'
  },
  {
    postalCode: '23619',
    city: 'Heilshoop'
  },
  {
    postalCode: '23619',
    city: 'Herrenbranden'
  },
  {
    postalCode: '23619',
    city: 'Mönkhagen'
  },
  {
    postalCode: '23619',
    city: 'Rehhorst'
  },
  {
    postalCode: '23619',
    city: 'Zarpen'
  },
  {
    postalCode: '23623',
    city: 'Ahrensbök'
  },
  {
    postalCode: '23623',
    city: 'Jeshop'
  },
  {
    postalCode: '23626',
    city: 'Ratekau'
  },
  {
    postalCode: '23627',
    city: 'Groß Grönau'
  },
  {
    postalCode: '23627',
    city: 'Groß Sarau'
  },
  {
    postalCode: '23627',
    city: 'Klein Grönau'
  },
  {
    postalCode: '23628',
    city: 'Klempau'
  },
  {
    postalCode: '23628',
    city: 'Krummesse'
  },
  {
    postalCode: '23629',
    city: 'Sarkwitz'
  },
  {
    postalCode: '23669',
    city: 'Neuhof'
  },
  {
    postalCode: '23669',
    city: 'Oeverdiek'
  },
  {
    postalCode: '23669',
    city: 'Timmendorfer Strand'
  },
  {
    postalCode: '23683',
    city: 'Scharbeutz'
  },
  {
    postalCode: '23684',
    city: 'Scharbeutz'
  },
  {
    postalCode: '23689',
    city: 'Hobbersdorf'
  },
  {
    postalCode: '23689',
    city: 'Luschendorf'
  },
  {
    postalCode: '23689',
    city: 'Luschendorferhof'
  },
  {
    postalCode: '23689',
    city: 'Pansdorf'
  },
  {
    postalCode: '23689',
    city: 'Rohlsdorf'
  },
  {
    postalCode: '23689',
    city: 'Rohlsdorferbeek'
  },
  {
    postalCode: '23689',
    city: 'Techau'
  },
  {
    postalCode: '23701',
    city: 'Eutin'
  },
  {
    postalCode: '23701',
    city: 'Süsel'
  },
  {
    postalCode: '23714',
    city: 'Kirchnüchel'
  },
  {
    postalCode: '23714',
    city: 'Malente'
  },
  {
    postalCode: '23715',
    city: 'Bosau'
  },
  {
    postalCode: '23717',
    city: 'Bökensberg'
  },
  {
    postalCode: '23717',
    city: 'Kasseedorf'
  },
  {
    postalCode: '23719',
    city: 'Glasau'
  },
  {
    postalCode: '23730',
    city: 'Altenkrempe'
  },
  {
    postalCode: '23730',
    city: 'Neustadt'
  },
  {
    postalCode: '23730',
    city: 'Schashagen'
  },
  {
    postalCode: '23730',
    city: 'Sierksdorf'
  },
  {
    postalCode: '23738',
    city: 'Beschendorf'
  },
  {
    postalCode: '23738',
    city: 'Damlos'
  },
  {
    postalCode: '23738',
    city: 'Harmsdorf'
  },
  {
    postalCode: '23738',
    city: 'Kabelhorst'
  },
  {
    postalCode: '23738',
    city: 'Lensahn'
  },
  {
    postalCode: '23738',
    city: 'Manhagen'
  },
  {
    postalCode: '23738',
    city: 'Riepsdorf'
  },
  {
    postalCode: '23743',
    city: 'Grömitz'
  },
  {
    postalCode: '23743',
    city: 'Morest'
  },
  {
    postalCode: '23744',
    city: 'Schönwalde'
  },
  {
    postalCode: '23746',
    city: 'Kellenhusen'
  },
  {
    postalCode: '23746',
    city: 'Rittbruch'
  },
  {
    postalCode: '23747',
    city: 'Dahme'
  },
  {
    postalCode: '23747',
    city: 'Gruberhagen'
  },
  {
    postalCode: '23749',
    city: 'Grube'
  },
  {
    postalCode: '23758',
    city: 'Göhl'
  },
  {
    postalCode: '23758',
    city: 'Gremersdorf'
  },
  {
    postalCode: '23758',
    city: 'Oldenburg'
  },
  {
    postalCode: '23758',
    city: 'Wangels'
  },
  {
    postalCode: '23769',
    city: 'Bannesdorf'
  },
  {
    postalCode: '23769',
    city: 'Burg'
  },
  {
    postalCode: '23769',
    city: 'Landkirchen'
  },
  {
    postalCode: '23769',
    city: 'Puttgarden'
  },
  {
    postalCode: '23769',
    city: 'Westfehmarn'
  },
  {
    postalCode: '23774',
    city: 'Heiligenhafen'
  },
  {
    postalCode: '23775',
    city: 'Großenbrode'
  },
  {
    postalCode: '23777',
    city: 'Heringsdorf'
  },
  {
    postalCode: '23777',
    city: 'Wulfshof'
  },
  {
    postalCode: '23779',
    city: 'Neukirchen'
  },
  {
    postalCode: '23779',
    city: 'Neuseegalendorf'
  },
  {
    postalCode: '23795',
    city: 'Bad Segeberg'
  },
  {
    postalCode: '23795',
    city: 'Fahrenkrug'
  },
  {
    postalCode: '23795',
    city: 'Groß Rönnau'
  },
  {
    postalCode: '23795',
    city: 'Högersdorf'
  },
  {
    postalCode: '23795',
    city: 'Klein Gladebrügge'
  },
  {
    postalCode: '23795',
    city: 'Klein Rönnau'
  },
  {
    postalCode: '23795',
    city: 'Mözen'
  },
  {
    postalCode: '23795',
    city: 'Negernbötel'
  },
  {
    postalCode: '23795',
    city: 'Schackendorf'
  },
  {
    postalCode: '23795',
    city: 'Schieren'
  },
  {
    postalCode: '23795',
    city: 'Schwissel'
  },
  {
    postalCode: '23795',
    city: 'Stipsdorf'
  },
  {
    postalCode: '23795',
    city: 'Traventhal'
  },
  {
    postalCode: '23795',
    city: 'Weede'
  },
  {
    postalCode: '23812',
    city: 'Glashütte'
  },
  {
    postalCode: '23812',
    city: 'Wahlstedt'
  },
  {
    postalCode: '23813',
    city: 'Blunk'
  },
  {
    postalCode: '23813',
    city: 'Nehms'
  },
  {
    postalCode: '23815',
    city: 'Geschendorf'
  },
  {
    postalCode: '23815',
    city: 'Strukdorf'
  },
  {
    postalCode: '23815',
    city: 'Westerrade'
  },
  {
    postalCode: '23816',
    city: 'Bebensee'
  },
  {
    postalCode: '23816',
    city: 'Groß Niendorf'
  },
  {
    postalCode: '23816',
    city: 'Leezen'
  },
  {
    postalCode: '23816',
    city: 'Neversdorf'
  },
  {
    postalCode: '23818',
    city: 'Neuengörs'
  },
  {
    postalCode: '23820',
    city: 'Pronstorf'
  },
  {
    postalCode: '23821',
    city: 'Rohlstorf'
  },
  {
    postalCode: '23823',
    city: 'Seedorf'
  },
  {
    postalCode: '23824',
    city: 'Damsdorf'
  },
  {
    postalCode: '23824',
    city: 'Tensfeld'
  },
  {
    postalCode: '23826',
    city: 'Bark'
  },
  {
    postalCode: '23826',
    city: 'Fredesdorf'
  },
  {
    postalCode: '23826',
    city: 'Todesfelde'
  },
  {
    postalCode: '23827',
    city: 'Krems II'
  },
  {
    postalCode: '23827',
    city: 'Travenhorst'
  },
  {
    postalCode: '23827',
    city: 'Wensin'
  },
  {
    postalCode: '23829',
    city: 'Kükels'
  },
  {
    postalCode: '23829',
    city: 'Wittenborn'
  },
  {
    postalCode: '23843',
    city: 'Bad Oldesloe'
  },
  {
    postalCode: '23843',
    city: 'Neritz'
  },
  {
    postalCode: '23843',
    city: 'Rümpel'
  },
  {
    postalCode: '23843',
    city: 'Travenbrück'
  },
  {
    postalCode: '23845',
    city: 'Bahrenhof'
  },
  {
    postalCode: '23845',
    city: 'Borstel'
  },
  {
    postalCode: '23845',
    city: 'Bühnsdorf'
  },
  {
    postalCode: '23845',
    city: 'Dreggers'
  },
  {
    postalCode: '23845',
    city: 'Grabau'
  },
  {
    postalCode: '23845',
    city: 'Itzstedt'
  },
  {
    postalCode: '23845',
    city: 'Oering'
  },
  {
    postalCode: '23845',
    city: 'Seth'
  },
  {
    postalCode: '23845',
    city: 'Wakendorf I'
  },
  {
    postalCode: '23847',
    city: 'Bliestorf'
  },
  {
    postalCode: '23847',
    city: 'Düchelsdorf'
  },
  {
    postalCode: '23847',
    city: 'Grinau'
  },
  {
    postalCode: '23847',
    city: 'Groß Boden'
  },
  {
    postalCode: '23847',
    city: 'Kastorf'
  },
  {
    postalCode: '23847',
    city: 'Lasbek'
  },
  {
    postalCode: '23847',
    city: 'Meddewade'
  },
  {
    postalCode: '23847',
    city: 'Pölitz'
  },
  {
    postalCode: '23847',
    city: 'Rethwisch'
  },
  {
    postalCode: '23847',
    city: 'Schiphorst'
  },
  {
    postalCode: '23847',
    city: 'Schürensöhlen'
  },
  {
    postalCode: '23847',
    city: 'Siebenbäumen'
  },
  {
    postalCode: '23847',
    city: 'Sierksrade'
  },
  {
    postalCode: '23847',
    city: 'Steinhorst'
  },
  {
    postalCode: '23847',
    city: 'Stubben'
  },
  {
    postalCode: '23847',
    city: 'Westerau'
  },
  {
    postalCode: '23858',
    city: 'Barnitz'
  },
  {
    postalCode: '23858',
    city: 'Feldhorst'
  },
  {
    postalCode: '23858',
    city: 'Heidekamp'
  },
  {
    postalCode: '23858',
    city: 'Reinfeld'
  },
  {
    postalCode: '23858',
    city: 'Wesenberg'
  },
  {
    postalCode: '23860',
    city: 'Groß Schenkenberg'
  },
  {
    postalCode: '23860',
    city: 'Klein Wesenberg'
  },
  {
    postalCode: '23863',
    city: 'Bargfeld-Stegen'
  },
  {
    postalCode: '23863',
    city: 'Kayhude'
  },
  {
    postalCode: '23863',
    city: 'Nienwohld'
  },
  {
    postalCode: '23866',
    city: 'Nahe'
  },
  {
    postalCode: '23867',
    city: 'Sülfeld'
  },
  {
    postalCode: '23869',
    city: 'Elmenhorst'
  },
  {
    postalCode: '23879',
    city: 'Drüsen'
  },
  {
    postalCode: '23879',
    city: 'Mölln'
  },
  {
    postalCode: '23881',
    city: 'Alt Mölln'
  },
  {
    postalCode: '23881',
    city: 'Bälau'
  },
  {
    postalCode: '23881',
    city: 'Borstorf'
  },
  {
    postalCode: '23881',
    city: 'Breitenfelde'
  },
  {
    postalCode: '23881',
    city: 'Koberg'
  },
  {
    postalCode: '23881',
    city: 'Lankau'
  },
  {
    postalCode: '23881',
    city: 'Niendorf'
  },
  {
    postalCode: '23883',
    city: 'Bresahn'
  },
  {
    postalCode: '23883',
    city: 'Brunsmark'
  },
  {
    postalCode: '23883',
    city: 'Dargow'
  },
  {
    postalCode: '23883',
    city: 'Grambek'
  },
  {
    postalCode: '23883',
    city: 'Hollenbek'
  },
  {
    postalCode: '23883',
    city: 'Horst'
  },
  {
    postalCode: '23883',
    city: 'Klein Zecher'
  },
  {
    postalCode: '23883',
    city: 'Lehmrade'
  },
  {
    postalCode: '23883',
    city: 'Marienstedt'
  },
  {
    postalCode: '23883',
    city: 'Seedorf'
  },
  {
    postalCode: '23883',
    city: 'Sterley'
  },
  {
    postalCode: '23883',
    city: 'Sterleyerheide'
  },
  {
    postalCode: '23883',
    city: 'Torfkaten'
  },
  {
    postalCode: '23896',
    city: 'Nusse'
  },
  {
    postalCode: '23896',
    city: 'Panten'
  },
  {
    postalCode: '23896',
    city: 'Poggensee'
  },
  {
    postalCode: '23896',
    city: 'Ritzerau'
  },
  {
    postalCode: '23896',
    city: 'Walksfelde'
  },
  {
    postalCode: '23898',
    city: 'Duvensee'
  },
  {
    postalCode: '23898',
    city: 'Klinkrade'
  },
  {
    postalCode: '23898',
    city: 'Kühsen'
  },
  {
    postalCode: '23898',
    city: 'Labenz'
  },
  {
    postalCode: '23898',
    city: 'Lüchow'
  },
  {
    postalCode: '23898',
    city: 'Sandesneben'
  },
  {
    postalCode: '23898',
    city: 'Sirksfelde'
  },
  {
    postalCode: '23898',
    city: 'Wentorf'
  },
  {
    postalCode: '23899',
    city: 'Besenthal'
  },
  {
    postalCode: '23899',
    city: 'Gudow'
  },
  {
    postalCode: '23909',
    city: 'Albsfelde'
  },
  {
    postalCode: '23909',
    city: 'Bäk'
  },
  {
    postalCode: '23909',
    city: 'Farchau'
  },
  {
    postalCode: '23909',
    city: 'Farchauermühle'
  },
  {
    postalCode: '23909',
    city: 'Fredeburg'
  },
  {
    postalCode: '23909',
    city: 'Giesensdorf'
  },
  {
    postalCode: '23909',
    city: 'Hundebusch'
  },
  {
    postalCode: '23909',
    city: 'Mechow'
  },
  {
    postalCode: '23909',
    city: 'Ratzeburg'
  },
  {
    postalCode: '23909',
    city: 'Römnitz'
  },
  {
    postalCode: '23909',
    city: 'Sande'
  },
  {
    postalCode: '23909',
    city: 'Söhren'
  },
  {
    postalCode: '23909',
    city: 'Weißer Hirsch'
  },
  {
    postalCode: '23911',
    city: 'Buchholz'
  },
  {
    postalCode: '23911',
    city: 'Einhaus'
  },
  {
    postalCode: '23911',
    city: 'Groß Disnack'
  },
  {
    postalCode: '23911',
    city: 'Harmsdorf'
  },
  {
    postalCode: '23911',
    city: 'Kittlitz'
  },
  {
    postalCode: '23911',
    city: 'Kulpin'
  },
  {
    postalCode: '23911',
    city: 'Mustin'
  },
  {
    postalCode: '23911',
    city: 'Pogeez'
  },
  {
    postalCode: '23911',
    city: 'Salem'
  },
  {
    postalCode: '23911',
    city: 'Schmilau'
  },
  {
    postalCode: '23911',
    city: 'Ziethen'
  },
  {
    postalCode: '23919',
    city: 'Behlendorf'
  },
  {
    postalCode: '23919',
    city: 'Berkenthin'
  },
  {
    postalCode: '23919',
    city: 'Fliegenberg'
  },
  {
    postalCode: '23919',
    city: 'Göldenitz'
  },
  {
    postalCode: '23919',
    city: 'Niendorf'
  },
  {
    postalCode: '23919',
    city: 'Rondeshagen'
  },
  {
    postalCode: '23923',
    city: 'Bechelsdorf'
  },
  {
    postalCode: '23923',
    city: 'Blüssen'
  },
  {
    postalCode: '23923',
    city: 'Boitin-Resdorf'
  },
  {
    postalCode: '23923',
    city: 'Dorf Wahrsow'
  },
  {
    postalCode: '23923',
    city: 'Duvennest'
  },
  {
    postalCode: '23923',
    city: 'Eulenkrug'
  },
  {
    postalCode: '23923',
    city: 'Groß Bünsdorf'
  },
  {
    postalCode: '23923',
    city: 'Groß Neuleben'
  },
  {
    postalCode: '23923',
    city: 'Groß Siemz'
  },
  {
    postalCode: '23923',
    city: 'Herrnburg'
  },
  {
    postalCode: '23923',
    city: 'Hof Selmsdorf'
  },
  {
    postalCode: '23923',
    city: 'Hof Wahrsow'
  },
  {
    postalCode: '23923',
    city: 'Klein Bünsdorf'
  },
  {
    postalCode: '23923',
    city: 'Klein Neuleben'
  },
  {
    postalCode: '23923',
    city: 'Klein Siemz'
  },
  {
    postalCode: '23923',
    city: 'Kleinfeld'
  },
  {
    postalCode: '23923',
    city: 'Lauen'
  },
  {
    postalCode: '23923',
    city: 'Lindow'
  },
  {
    postalCode: '23923',
    city: 'Lockwisch'
  },
  {
    postalCode: '23923',
    city: 'Lüdersdorf'
  },
  {
    postalCode: '23923',
    city: 'Malzow'
  },
  {
    postalCode: '23923',
    city: 'Menzendorf'
  },
  {
    postalCode: '23923',
    city: 'Niendorf'
  },
  {
    postalCode: '23923',
    city: 'Ollndorf'
  },
  {
    postalCode: '23923',
    city: 'Palingen'
  },
  {
    postalCode: '23923',
    city: 'Petersberg'
  },
  {
    postalCode: '23923',
    city: 'Retelsdorf'
  },
  {
    postalCode: '23923',
    city: 'Roduchelstorf'
  },
  {
    postalCode: '23923',
    city: 'Rupensdorf'
  },
  {
    postalCode: '23923',
    city: 'Sabow'
  },
  {
    postalCode: '23923',
    city: 'Schattin'
  },
  {
    postalCode: '23923',
    city: 'Schönberg'
  },
  {
    postalCode: '23923',
    city: 'Selmsdorf'
  },
  {
    postalCode: '23923',
    city: 'Sülsdorf'
  },
  {
    postalCode: '23923',
    city: 'Teschow'
  },
  {
    postalCode: '23923',
    city: 'Torisdorf'
  },
  {
    postalCode: '23923',
    city: 'Törpt'
  },
  {
    postalCode: '23923',
    city: 'Wahrsow'
  },
  {
    postalCode: '23923',
    city: 'Westerbeck'
  },
  {
    postalCode: '23923',
    city: 'Zarnewenz'
  },
  {
    postalCode: '23936',
    city: 'Barendorf'
  },
  {
    postalCode: '23936',
    city: 'Bernstorf'
  },
  {
    postalCode: '23936',
    city: 'Blieschendorf'
  },
  {
    postalCode: '23936',
    city: 'Boienhagen'
  },
  {
    postalCode: '23936',
    city: 'Bonnhagen'
  },
  {
    postalCode: '23936',
    city: 'Börzow'
  },
  {
    postalCode: '23936',
    city: 'Bössow'
  },
  {
    postalCode: '23936',
    city: 'Büttlingen'
  },
  {
    postalCode: '23936',
    city: 'Diedrichshagen'
  },
  {
    postalCode: '23936',
    city: 'Everstorf'
  },
  {
    postalCode: '23936',
    city: 'Fräulein-Steinfort'
  },
  {
    postalCode: '23936',
    city: 'Friedrichshagen'
  },
  {
    postalCode: '23936',
    city: 'Gostorf'
  },
  {
    postalCode: '23936',
    city: 'Grenzhausen'
  },
  {
    postalCode: '23936',
    city: 'Greschendorf'
  },
  {
    postalCode: '23936',
    city: 'Grevenstein'
  },
  {
    postalCode: '23936',
    city: 'Grevesmühlen'
  },
  {
    postalCode: '23936',
    city: 'Grieben'
  },
  {
    postalCode: '23936',
    city: 'Groß Pravtshagen'
  },
  {
    postalCode: '23936',
    city: 'Hamberge'
  },
  {
    postalCode: '23936',
    city: 'Hanshagen'
  },
  {
    postalCode: '23936',
    city: 'Hanstorf'
  },
  {
    postalCode: '23936',
    city: 'Harmshagen'
  },
  {
    postalCode: '23936',
    city: 'Hilgendorf'
  },
  {
    postalCode: '23936',
    city: 'Hof Mummendorf'
  },
  {
    postalCode: '23936',
    city: 'Hoikendorf'
  },
  {
    postalCode: '23936',
    city: 'Jeese'
  },
  {
    postalCode: '23936',
    city: 'Kastahn'
  },
  {
    postalCode: '23936',
    city: 'Kirch-Mummendorf'
  },
  {
    postalCode: '23936',
    city: 'Mallentin'
  },
  {
    postalCode: '23936',
    city: 'Meierstorf'
  },
  {
    postalCode: '23936',
    city: 'Naschendorf'
  },
  {
    postalCode: '23936',
    city: 'Neu Degtow'
  },
  {
    postalCode: '23936',
    city: 'Papenhusen'
  },
  {
    postalCode: '23936',
    city: 'Pieverstorf'
  },
  {
    postalCode: '23936',
    city: 'Plüschow'
  },
  {
    postalCode: '23936',
    city: 'Pohnstorf'
  },
  {
    postalCode: '23936',
    city: 'Questin'
  },
  {
    postalCode: '23936',
    city: 'Rankendorf'
  },
  {
    postalCode: '23936',
    city: 'Rodenberg'
  },
  {
    postalCode: '23936',
    city: 'Roggenstorf'
  },
  {
    postalCode: '23936',
    city: 'Roxin'
  },
  {
    postalCode: '23936',
    city: 'Rüting'
  },
  {
    postalCode: '23936',
    city: 'Rüting Steinfort'
  },
  {
    postalCode: '23936',
    city: 'Santow'
  },
  {
    postalCode: '23936',
    city: 'Schildberg'
  },
  {
    postalCode: '23936',
    city: 'Schmachthagen'
  },
  {
    postalCode: '23936',
    city: 'Schönhof'
  },
  {
    postalCode: '23936',
    city: 'Seefeld'
  },
  {
    postalCode: '23936',
    city: 'Sievershagen'
  },
  {
    postalCode: '23936',
    city: 'Strohkirchen'
  },
  {
    postalCode: '23936',
    city: 'Teschow'
  },
  {
    postalCode: '23936',
    city: 'Testorf'
  },
  {
    postalCode: '23936',
    city: 'Testorf-Steinfort'
  },
  {
    postalCode: '23936',
    city: 'Thorstorf'
  },
  {
    postalCode: '23936',
    city: 'Tramm'
  },
  {
    postalCode: '23936',
    city: 'Upahl'
  },
  {
    postalCode: '23936',
    city: 'Volkenshagen'
  },
  {
    postalCode: '23936',
    city: 'Waldeck'
  },
  {
    postalCode: '23936',
    city: 'Warnow'
  },
  {
    postalCode: '23936',
    city: 'Wendorf'
  },
  {
    postalCode: '23936',
    city: 'Wilkenhagen'
  },
  {
    postalCode: '23936',
    city: 'Wölschendorf'
  },
  {
    postalCode: '23936',
    city: 'Wotenitz'
  },
  {
    postalCode: '23936',
    city: 'Wüstenmark'
  },
  {
    postalCode: '23936',
    city: 'Zehmen'
  },
  {
    postalCode: '23942',
    city: 'Benckendorf'
  },
  {
    postalCode: '23942',
    city: 'Dassow'
  },
  {
    postalCode: '23942',
    city: 'Dönkendorf'
  },
  {
    postalCode: '23942',
    city: 'Feldhusen'
  },
  {
    postalCode: '23942',
    city: 'Groß Schwansee'
  },
  {
    postalCode: '23942',
    city: 'Harkensee'
  },
  {
    postalCode: '23942',
    city: 'Johannstorf'
  },
  {
    postalCode: '23942',
    city: 'Kalkhorst'
  },
  {
    postalCode: '23942',
    city: 'Klein Schwansee'
  },
  {
    postalCode: '23942',
    city: 'Neuenhagen'
  },
  {
    postalCode: '23942',
    city: 'Pötenitz'
  },
  {
    postalCode: '23942',
    city: 'Rosenhagen'
  },
  {
    postalCode: '23942',
    city: 'Volkstorf'
  },
  {
    postalCode: '23946',
    city: 'Ostseebad Boltenhagen'
  },
  {
    postalCode: '23946',
    city: 'Ostseebad Boltenhagen Zeltplatz'
  },
  {
    postalCode: '23946',
    city: 'Redewisch'
  },
  {
    postalCode: '23946',
    city: 'Redewisch Fischerhaus'
  },
  {
    postalCode: '23946',
    city: 'Tarnewitz'
  },
  {
    postalCode: '23946',
    city: 'Tarnewitzerhagen'
  },
  {
    postalCode: '23946',
    city: 'Wichmannsdorf'
  },
  {
    postalCode: '23948',
    city: 'Arpshagen'
  },
  {
    postalCode: '23948',
    city: 'Brook'
  },
  {
    postalCode: '23948',
    city: 'Christinenfeld'
  },
  {
    postalCode: '23948',
    city: 'Damshagen'
  },
  {
    postalCode: '23948',
    city: 'Dorf Gutow'
  },
  {
    postalCode: '23948',
    city: 'Dorf Reppenhagen'
  },
  {
    postalCode: '23948',
    city: 'Elmenhorst'
  },
  {
    postalCode: '23948',
    city: 'Eulenkrug'
  },
  {
    postalCode: '23948',
    city: 'Gantenbeck'
  },
  {
    postalCode: '23948',
    city: 'Goldbeck'
  },
  {
    postalCode: '23948',
    city: 'Groß Walmstorf'
  },
  {
    postalCode: '23948',
    city: 'Großenhof'
  },
  {
    postalCode: '23948',
    city: 'Grundshagen'
  },
  {
    postalCode: '23948',
    city: 'Hof Gutow'
  },
  {
    postalCode: '23948',
    city: 'Hof Reppenhagen'
  },
  {
    postalCode: '23948',
    city: 'Hofzumfelde'
  },
  {
    postalCode: '23948',
    city: 'Hohen Schönberg'
  },
  {
    postalCode: '23948',
    city: 'Klein Pravtshagen'
  },
  {
    postalCode: '23948',
    city: 'Klütz'
  },
  {
    postalCode: '23948',
    city: 'Kühlenstein'
  },
  {
    postalCode: '23948',
    city: 'Kussow'
  },
  {
    postalCode: '23948',
    city: 'Moor'
  },
  {
    postalCode: '23948',
    city: 'Nedderhagen'
  },
  {
    postalCode: '23948',
    city: 'Niederklütz'
  },
  {
    postalCode: '23948',
    city: 'Niendorf'
  },
  {
    postalCode: '23948',
    city: 'Oberhof'
  },
  {
    postalCode: '23948',
    city: 'Oberklütz'
  },
  {
    postalCode: '23948',
    city: 'Parin'
  },
  {
    postalCode: '23948',
    city: 'Rolofshagen'
  },
  {
    postalCode: '23948',
    city: 'Steinbeck'
  },
  {
    postalCode: '23948',
    city: 'Stellshagen'
  },
  {
    postalCode: '23948',
    city: 'Wahrstorf'
  },
  {
    postalCode: '23948',
    city: 'Warnkenhagen'
  },
  {
    postalCode: '23948',
    city: 'Welzin'
  },
  {
    postalCode: '23948',
    city: 'Wohlenberg'
  },
  {
    postalCode: '23948',
    city: 'Wohlenhagen'
  },
  {
    postalCode: '23966',
    city: 'Groß Krankow'
  },
  {
    postalCode: '23966',
    city: 'Karow'
  },
  {
    postalCode: '23966',
    city: 'Kletzin'
  },
  {
    postalCode: '23966',
    city: 'Moidentin'
  },
  {
    postalCode: '23966',
    city: 'Neu Stieten'
  },
  {
    postalCode: '23966',
    city: 'Olgashof'
  },
  {
    postalCode: '23966',
    city: 'Petersdorf bei Wismar, Meckl'
  },
  {
    postalCode: '23966',
    city: 'Rambow'
  },
  {
    postalCode: '23966',
    city: 'Rosenthal'
  },
  {
    postalCode: '23966',
    city: 'Steffin'
  },
  {
    postalCode: '23966',
    city: 'Triwalk'
  },
  {
    postalCode: '23966',
    city: 'Wietow'
  },
  {
    postalCode: '23966',
    city: 'Wismar'
  },
  {
    postalCode: '23968',
    city: 'Barnekow'
  },
  {
    postalCode: '23968',
    city: 'Gägelow'
  },
  {
    postalCode: '23968',
    city: 'Gramkow'
  },
  {
    postalCode: '23968',
    city: 'Gressow'
  },
  {
    postalCode: '23968',
    city: 'Jamel'
  },
  {
    postalCode: '23968',
    city: 'Neu Weitendorf'
  },
  {
    postalCode: '23968',
    city: 'Proseken'
  },
  {
    postalCode: '23968',
    city: 'Stofferstorf'
  },
  {
    postalCode: '23968',
    city: 'Voßkuhl'
  },
  {
    postalCode: '23968',
    city: 'Weitendorf'
  },
  {
    postalCode: '23968',
    city: 'Wismar'
  },
  {
    postalCode: '23968',
    city: 'Wolde'
  },
  {
    postalCode: '23968',
    city: 'Zierow'
  },
  {
    postalCode: '23970',
    city: 'Benz'
  },
  {
    postalCode: '23970',
    city: 'Greese'
  },
  {
    postalCode: '23970',
    city: 'Kritzow'
  },
  {
    postalCode: '23970',
    city: 'Levetzow'
  },
  {
    postalCode: '23970',
    city: 'Rüggow'
  },
  {
    postalCode: '23970',
    city: 'Wismar'
  },
  {
    postalCode: '23972',
    city: 'Dorf Mecklenburg'
  },
  {
    postalCode: '23972',
    city: 'Groß Stieten'
  },
  {
    postalCode: '23972',
    city: 'Klein Stieten'
  },
  {
    postalCode: '23972',
    city: 'Klüssendorf'
  },
  {
    postalCode: '23972',
    city: 'Lübow'
  },
  {
    postalCode: '23972',
    city: 'Martensdorf'
  },
  {
    postalCode: '23972',
    city: 'Maßlow'
  },
  {
    postalCode: '23972',
    city: 'Metelsdorf'
  },
  {
    postalCode: '23972',
    city: 'Schimm'
  },
  {
    postalCode: '23972',
    city: 'Schulenbrook'
  },
  {
    postalCode: '23972',
    city: 'Tarzow'
  },
  {
    postalCode: '23974',
    city: 'Blowatz'
  },
  {
    postalCode: '23974',
    city: 'Boiensdorf'
  },
  {
    postalCode: '23974',
    city: 'Gagzow'
  },
  {
    postalCode: '23974',
    city: 'Hagebök'
  },
  {
    postalCode: '23974',
    city: 'Hof Redentin'
  },
  {
    postalCode: '23974',
    city: 'Hornstorf'
  },
  {
    postalCode: '23974',
    city: 'Krusenhagen'
  },
  {
    postalCode: '23974',
    city: 'Mückenfang'
  },
  {
    postalCode: '23974',
    city: 'Neuburg-Steinhausen'
  },
  {
    postalCode: '23974',
    city: 'Rohlstorf'
  },
  {
    postalCode: '23992',
    city: 'Alt Poorstorf'
  },
  {
    postalCode: '23992',
    city: 'Bäbelin'
  },
  {
    postalCode: '23992',
    city: 'Babst'
  },
  {
    postalCode: '23992',
    city: 'Charlottenfelde'
  },
  {
    postalCode: '23992',
    city: 'Fahren'
  },
  {
    postalCode: '23992',
    city: 'Glasin'
  },
  {
    postalCode: '23992',
    city: 'Goldberg'
  },
  {
    postalCode: '23992',
    city: 'Groß Tessin'
  },
  {
    postalCode: '23992',
    city: 'Höltingsdorf'
  },
  {
    postalCode: '23992',
    city: 'Kahlenberg'
  },
  {
    postalCode: '23992',
    city: 'Klein Warin'
  },
  {
    postalCode: '23992',
    city: 'Klein Warin Lager'
  },
  {
    postalCode: '23992',
    city: 'Krassow'
  },
  {
    postalCode: '23992',
    city: 'Langenstück'
  },
  {
    postalCode: '23992',
    city: 'Lübberstorf'
  },
  {
    postalCode: '23992',
    city: 'Lüdersdorf'
  },
  {
    postalCode: '23992',
    city: 'Nakenstorf'
  },
  {
    postalCode: '23992',
    city: 'Neu Babst'
  },
  {
    postalCode: '23992',
    city: 'Neu Poorstorf'
  },
  {
    postalCode: '23992',
    city: 'Neuhof'
  },
  {
    postalCode: '23992',
    city: 'Neukloster'
  },
  {
    postalCode: '23992',
    city: 'Neumühle'
  },
  {
    postalCode: '23992',
    city: 'Nevern'
  },
  {
    postalCode: '23992',
    city: 'Passee'
  },
  {
    postalCode: '23992',
    city: 'Perniek'
  },
  {
    postalCode: '23992',
    city: 'Pinnowhof'
  },
  {
    postalCode: '23992',
    city: 'Poischendorf'
  },
  {
    postalCode: '23992',
    city: 'Poischendorf Molkerei'
  },
  {
    postalCode: '23992',
    city: 'Ravensruh'
  },
  {
    postalCode: '23992',
    city: 'Reinstorf'
  },
  {
    postalCode: '23992',
    city: 'Rügkamp'
  },
  {
    postalCode: '23992',
    city: 'Schmakentin'
  },
  {
    postalCode: '23992',
    city: 'Sellin'
  },
  {
    postalCode: '23992',
    city: 'Strameuß'
  },
  {
    postalCode: '23992',
    city: 'Teplitz'
  },
  {
    postalCode: '23992',
    city: 'Tollow'
  },
  {
    postalCode: '23992',
    city: 'Tüzen'
  },
  {
    postalCode: '23992',
    city: 'Wakendorf'
  },
  {
    postalCode: '23992',
    city: 'Warnkenhagen'
  },
  {
    postalCode: '23992',
    city: 'Zurow'
  },
  {
    postalCode: '23992',
    city: 'Züsow'
  },
  {
    postalCode: '23992',
    city: 'Zweihausen'
  },
  {
    postalCode: '23996',
    city: 'Bad Kleinen'
  },
  {
    postalCode: '23996',
    city: 'Beidendorf'
  },
  {
    postalCode: '23996',
    city: 'Bobitz'
  },
  {
    postalCode: '23996',
    city: 'Dalliendorf'
  },
  {
    postalCode: '23996',
    city: 'Dambeck'
  },
  {
    postalCode: '23996',
    city: 'Fichtenhusen'
  },
  {
    postalCode: '23996',
    city: 'Gallentin'
  },
  {
    postalCode: '23996',
    city: 'Glashagen'
  },
  {
    postalCode: '23996',
    city: 'Grapen-Stieten'
  },
  {
    postalCode: '23996',
    city: 'Hädchenshof'
  },
  {
    postalCode: '23996',
    city: 'Hohen Viecheln'
  },
  {
    postalCode: '23996',
    city: 'Hoppenrade'
  },
  {
    postalCode: '23996',
    city: 'Losten'
  },
  {
    postalCode: '23996',
    city: 'Lutterstorf'
  },
  {
    postalCode: '23996',
    city: 'Moltow'
  },
  {
    postalCode: '23996',
    city: 'Naudin'
  },
  {
    postalCode: '23996',
    city: 'Neu Saunstorf'
  },
  {
    postalCode: '23996',
    city: 'Neu Viecheln'
  },
  {
    postalCode: '23996',
    city: 'Neuhof'
  },
  {
    postalCode: '23996',
    city: 'Niendorf'
  },
  {
    postalCode: '23996',
    city: 'Rastorf'
  },
  {
    postalCode: '23996',
    city: 'Saunstorf'
  },
  {
    postalCode: '23996',
    city: 'Scharfstorf'
  },
  {
    postalCode: '23996',
    city: 'Wendisch Rambow'
  },
  {
    postalCode: '23999',
    city: 'Insel Poel'
  },
  {
    postalCode: '24103',
    city: 'Kiel'
  },
  {
    postalCode: '24105',
    city: 'Kiel'
  },
  {
    postalCode: '24106',
    city: 'Kiel'
  },
  {
    postalCode: '24107',
    city: 'Kiel'
  },
  {
    postalCode: '24107',
    city: 'Ottendorf'
  },
  {
    postalCode: '24107',
    city: 'Quarnbek'
  },
  {
    postalCode: '24109',
    city: 'Kiel'
  },
  {
    postalCode: '24109',
    city: 'Melsdorf'
  },
  {
    postalCode: '24111',
    city: 'Kiel'
  },
  {
    postalCode: '24113',
    city: 'Kiel'
  },
  {
    postalCode: '24113',
    city: 'Molfsee'
  },
  {
    postalCode: '24114',
    city: 'Kiel'
  },
  {
    postalCode: '24116',
    city: 'Kiel'
  },
  {
    postalCode: '24118',
    city: 'Kiel'
  },
  {
    postalCode: '24119',
    city: 'Kronshagen'
  },
  {
    postalCode: '24143',
    city: 'Kiel'
  },
  {
    postalCode: '24145',
    city: 'Kiel'
  },
  {
    postalCode: '24146',
    city: 'Kiel'
  },
  {
    postalCode: '24147',
    city: 'Kiel'
  },
  {
    postalCode: '24147',
    city: 'Klausdorf'
  },
  {
    postalCode: '24148',
    city: 'Kiel'
  },
  {
    postalCode: '24149',
    city: 'Kiel'
  },
  {
    postalCode: '24159',
    city: 'Kiel'
  },
  {
    postalCode: '24161',
    city: 'Altenholz'
  },
  {
    postalCode: '24161',
    city: 'Levensau'
  },
  {
    postalCode: '24161',
    city: 'Rathmannsdorf, Gut'
  },
  {
    postalCode: '24161',
    city: 'Rathmannsdorf, Hof'
  },
  {
    postalCode: '24161',
    city: 'Rathmannsdorferschleuse'
  },
  {
    postalCode: '24211',
    city: 'Honigsee'
  },
  {
    postalCode: '24211',
    city: 'Kahlkamp'
  },
  {
    postalCode: '24211',
    city: 'Kühren'
  },
  {
    postalCode: '24211',
    city: 'Lehmkuhlen'
  },
  {
    postalCode: '24211',
    city: 'Pohnsdorf'
  },
  {
    postalCode: '24211',
    city: 'Postfeld'
  },
  {
    postalCode: '24211',
    city: 'Preetz'
  },
  {
    postalCode: '24211',
    city: 'Rastorf'
  },
  {
    postalCode: '24211',
    city: 'Schellhorn'
  },
  {
    postalCode: '24211',
    city: 'Wahlstorf'
  },
  {
    postalCode: '24214',
    city: 'Altwittenbek'
  },
  {
    postalCode: '24214',
    city: 'Fahrenhorst'
  },
  {
    postalCode: '24214',
    city: 'Gettorf'
  },
  {
    postalCode: '24214',
    city: 'Großkönigsförde'
  },
  {
    postalCode: '24214',
    city: 'Großkönigsförderwohld'
  },
  {
    postalCode: '24214',
    city: 'Langenhorst'
  },
  {
    postalCode: '24214',
    city: 'Lindau'
  },
  {
    postalCode: '24214',
    city: 'Neudorf-Bornstein'
  },
  {
    postalCode: '24214',
    city: 'Neuwittenbek'
  },
  {
    postalCode: '24214',
    city: 'Noer'
  },
  {
    postalCode: '24214',
    city: 'Scharfenholz'
  },
  {
    postalCode: '24214',
    city: 'Schinkel'
  },
  {
    postalCode: '24214',
    city: 'Tüttendorf'
  },
  {
    postalCode: '24214',
    city: 'Waterdiek'
  },
  {
    postalCode: '24217',
    city: 'Barsbek'
  },
  {
    postalCode: '24217',
    city: 'Bendfeld'
  },
  {
    postalCode: '24217',
    city: 'Fiefbergen'
  },
  {
    postalCode: '24217',
    city: 'Höhndorf'
  },
  {
    postalCode: '24217',
    city: 'Krokau'
  },
  {
    postalCode: '24217',
    city: 'Krummbek'
  },
  {
    postalCode: '24217',
    city: 'Rögen'
  },
  {
    postalCode: '24217',
    city: 'Schönberg'
  },
  {
    postalCode: '24217',
    city: 'Stakendorf'
  },
  {
    postalCode: '24217',
    city: 'Wisch'
  },
  {
    postalCode: '24220',
    city: 'Böhnhusen'
  },
  {
    postalCode: '24220',
    city: 'Boksee'
  },
  {
    postalCode: '24220',
    city: 'Flintbek'
  },
  {
    postalCode: '24220',
    city: 'Schönhorst'
  },
  {
    postalCode: '24220',
    city: 'Techelsdorf'
  },
  {
    postalCode: '24223',
    city: 'Neuwühren'
  },
  {
    postalCode: '24223',
    city: 'Raisdorf'
  },
  {
    postalCode: '24226',
    city: 'Heikendorf'
  },
  {
    postalCode: '24229',
    city: 'Dänischenhagen'
  },
  {
    postalCode: '24229',
    city: 'Schwedeneck'
  },
  {
    postalCode: '24229',
    city: 'Strande'
  },
  {
    postalCode: '24232',
    city: 'Dobersdorf'
  },
  {
    postalCode: '24232',
    city: 'Kählen'
  },
  {
    postalCode: '24232',
    city: 'Schönkirchen'
  },
  {
    postalCode: '24235',
    city: 'Brodersdorf'
  },
  {
    postalCode: '24235',
    city: 'Laboe'
  },
  {
    postalCode: '24235',
    city: 'Lutterbek'
  },
  {
    postalCode: '24235',
    city: 'Stein'
  },
  {
    postalCode: '24235',
    city: 'Wendtorf'
  },
  {
    postalCode: '24238',
    city: 'Lammershagen'
  },
  {
    postalCode: '24238',
    city: 'Martensrade'
  },
  {
    postalCode: '24238',
    city: 'Mucheln'
  },
  {
    postalCode: '24238',
    city: 'Selent'
  },
  {
    postalCode: '24239',
    city: 'Achterwehr'
  },
  {
    postalCode: '24241',
    city: 'Blumenthal'
  },
  {
    postalCode: '24241',
    city: 'Grevenkrug'
  },
  {
    postalCode: '24241',
    city: 'Reesdorf'
  },
  {
    postalCode: '24241',
    city: 'Schierensee'
  },
  {
    postalCode: '24241',
    city: 'Schmalstede'
  },
  {
    postalCode: '24241',
    city: 'Sören'
  },
  {
    postalCode: '24242',
    city: 'Felde'
  },
  {
    postalCode: '24244',
    city: 'Felm'
  },
  {
    postalCode: '24245',
    city: 'Barmissen'
  },
  {
    postalCode: '24245',
    city: 'Großbarkau'
  },
  {
    postalCode: '24245',
    city: 'Kirchbarkau'
  },
  {
    postalCode: '24245',
    city: 'Klein Barkau'
  },
  {
    postalCode: '24247',
    city: 'Mielkendorf'
  },
  {
    postalCode: '24247',
    city: 'Rodenbek'
  },
  {
    postalCode: '24248',
    city: 'Mönkeberg'
  },
  {
    postalCode: '24250',
    city: 'Bothkamp'
  },
  {
    postalCode: '24250',
    city: 'Löptin'
  },
  {
    postalCode: '24250',
    city: 'Nettelsee'
  },
  {
    postalCode: '24250',
    city: 'Warnau'
  },
  {
    postalCode: '24251',
    city: 'Osdorf'
  },
  {
    postalCode: '24253',
    city: 'Fahren'
  },
  {
    postalCode: '24253',
    city: 'Passade'
  },
  {
    postalCode: '24253',
    city: 'Prasdorf'
  },
  {
    postalCode: '24253',
    city: 'Probsteierhagen'
  },
  {
    postalCode: '24254',
    city: 'Rumohr'
  },
  {
    postalCode: '24256',
    city: 'Fargau-Pratjau'
  },
  {
    postalCode: '24256',
    city: 'Schlesen'
  },
  {
    postalCode: '24256',
    city: 'Stoltenberg'
  },
  {
    postalCode: '24257',
    city: 'Hohenfelde'
  },
  {
    postalCode: '24257',
    city: 'Köhn'
  },
  {
    postalCode: '24257',
    city: 'Köhnerbrücke'
  },
  {
    postalCode: '24257',
    city: 'Schwartbuck'
  },
  {
    postalCode: '24259',
    city: 'Westensee'
  },
  {
    postalCode: '24306',
    city: 'Bösdorf'
  },
  {
    postalCode: '24306',
    city: 'Hinterste Wache'
  },
  {
    postalCode: '24306',
    city: 'Lebrade'
  },
  {
    postalCode: '24306',
    city: 'Plön'
  },
  {
    postalCode: '24306',
    city: 'Rathjensdorf'
  },
  {
    postalCode: '24306',
    city: 'Waldshagen'
  },
  {
    postalCode: '24306',
    city: 'Wittmoldt'
  },
  {
    postalCode: '24321',
    city: 'Behrensdorf'
  },
  {
    postalCode: '24321',
    city: 'Giekau'
  },
  {
    postalCode: '24321',
    city: 'Helmstorf'
  },
  {
    postalCode: '24321',
    city: 'Hohwacht'
  },
  {
    postalCode: '24321',
    city: 'Klamp'
  },
  {
    postalCode: '24321',
    city: 'Lütjenburg'
  },
  {
    postalCode: '24321',
    city: 'Panker'
  },
  {
    postalCode: '24321',
    city: 'Rantzauer Papiermühle'
  },
  {
    postalCode: '24321',
    city: 'Tivoli'
  },
  {
    postalCode: '24321',
    city: 'Tröndel'
  },
  {
    postalCode: '24326',
    city: 'Ascheberg'
  },
  {
    postalCode: '24326',
    city: 'Dersau'
  },
  {
    postalCode: '24326',
    city: 'Dörnick'
  },
  {
    postalCode: '24326',
    city: 'Kalübbe'
  },
  {
    postalCode: '24326',
    city: 'Nehmten'
  },
  {
    postalCode: '24326',
    city: 'Stadtbek, Gem Bosau'
  },
  {
    postalCode: '24326',
    city: 'Stadtbek, Gem Seedorf'
  },
  {
    postalCode: '24326',
    city: 'Stadtbekermühle'
  },
  {
    postalCode: '24326',
    city: 'Stocksee'
  },
  {
    postalCode: '24327',
    city: 'Blekendorf'
  },
  {
    postalCode: '24327',
    city: 'Grimmelsberg'
  },
  {
    postalCode: '24327',
    city: 'Högsdorf'
  },
  {
    postalCode: '24327',
    city: 'Kletkamp'
  },
  {
    postalCode: '24329',
    city: 'Dannau'
  },
  {
    postalCode: '24329',
    city: 'Grebin'
  },
  {
    postalCode: '24329',
    city: 'Rantzau'
  },
  {
    postalCode: '24340',
    city: 'Altenhof'
  },
  {
    postalCode: '24340',
    city: 'Eckernförde'
  },
  {
    postalCode: '24340',
    city: 'Gammelby'
  },
  {
    postalCode: '24340',
    city: 'Goosefeld'
  },
  {
    postalCode: '24340',
    city: 'Louisenberg'
  },
  {
    postalCode: '24340',
    city: 'Windeby'
  },
  {
    postalCode: '24351',
    city: 'Damp'
  },
  {
    postalCode: '24351',
    city: 'Thumby'
  },
  {
    postalCode: '24354',
    city: 'Bösby'
  },
  {
    postalCode: '24354',
    city: 'Bösbyfeld'
  },
  {
    postalCode: '24354',
    city: 'Kosel'
  },
  {
    postalCode: '24354',
    city: 'Pukholt'
  },
  {
    postalCode: '24354',
    city: 'Rieseby'
  },
  {
    postalCode: '24357',
    city: 'Fleckeby'
  },
  {
    postalCode: '24357',
    city: 'Güby'
  },
  {
    postalCode: '24357',
    city: 'Hummelfeld'
  },
  {
    postalCode: '24357',
    city: 'Krummland'
  },
  {
    postalCode: '24358',
    city: 'Ascheffel'
  },
  {
    postalCode: '24358',
    city: 'Bistensee'
  },
  {
    postalCode: '24358',
    city: 'Fresenboje'
  },
  {
    postalCode: '24358',
    city: 'Hütten'
  },
  {
    postalCode: '24360',
    city: 'Barkelsby'
  },
  {
    postalCode: '24360',
    city: 'Rögen, Gut'
  },
  {
    postalCode: '24361',
    city: 'Damendorf'
  },
  {
    postalCode: '24361',
    city: 'Groß Wittensee'
  },
  {
    postalCode: '24361',
    city: 'Haby'
  },
  {
    postalCode: '24361',
    city: 'Holzbunge'
  },
  {
    postalCode: '24361',
    city: 'Klein Wittensee'
  },
  {
    postalCode: '24361',
    city: 'Lehmsiek'
  },
  {
    postalCode: '24361',
    city: 'Profit'
  },
  {
    postalCode: '24361',
    city: 'Sande'
  },
  {
    postalCode: '24363',
    city: 'Holtsee'
  },
  {
    postalCode: '24363',
    city: 'Ropahl'
  },
  {
    postalCode: '24364',
    city: 'Holzdorf'
  },
  {
    postalCode: '24366',
    city: 'Loose'
  },
  {
    postalCode: '24367',
    city: 'Osterby'
  },
  {
    postalCode: '24367',
    city: 'Suhrbrook'
  },
  {
    postalCode: '24369',
    city: 'Waabs'
  },
  {
    postalCode: '24376',
    city: 'Grödersby'
  },
  {
    postalCode: '24376',
    city: 'Hasselberg'
  },
  {
    postalCode: '24376',
    city: 'Hauheck'
  },
  {
    postalCode: '24376',
    city: 'Kappeln'
  },
  {
    postalCode: '24376',
    city: 'Rabel'
  },
  {
    postalCode: '24376',
    city: 'Wormshöft'
  },
  {
    postalCode: '24392',
    city: 'Bicken'
  },
  {
    postalCode: '24392',
    city: 'Blick'
  },
  {
    postalCode: '24392',
    city: 'Boren'
  },
  {
    postalCode: '24392',
    city: 'Brebel'
  },
  {
    postalCode: '24392',
    city: 'Dollrottfeld'
  },
  {
    postalCode: '24392',
    city: 'Ekenis'
  },
  {
    postalCode: '24392',
    city: 'Flarupholz'
  },
  {
    postalCode: '24392',
    city: 'Hye'
  },
  {
    postalCode: '24392',
    city: 'Kiesby'
  },
  {
    postalCode: '24392',
    city: 'Norderbrarup'
  },
  {
    postalCode: '24392',
    city: 'Nottfeld'
  },
  {
    postalCode: '24392',
    city: 'Saustrup'
  },
  {
    postalCode: '24392',
    city: 'Scheggerott'
  },
  {
    postalCode: '24392',
    city: 'Süderbrarup'
  },
  {
    postalCode: '24392',
    city: 'Wagersrott'
  },
  {
    postalCode: '24395',
    city: 'Gelting'
  },
  {
    postalCode: '24395',
    city: 'Holzkoppel'
  },
  {
    postalCode: '24395',
    city: 'Kronsgaard'
  },
  {
    postalCode: '24395',
    city: 'Lüchtoft'
  },
  {
    postalCode: '24395',
    city: 'Nieby'
  },
  {
    postalCode: '24395',
    city: 'Niesgrau'
  },
  {
    postalCode: '24395',
    city: 'Pommerby'
  },
  {
    postalCode: '24395',
    city: 'Rabenholz'
  },
  {
    postalCode: '24395',
    city: 'Röhrmoos'
  },
  {
    postalCode: '24395',
    city: 'Stangheck'
  },
  {
    postalCode: '24398',
    city: 'Brodersby'
  },
  {
    postalCode: '24398',
    city: 'Büßholz'
  },
  {
    postalCode: '24398',
    city: 'Dörphof'
  },
  {
    postalCode: '24398',
    city: 'Karby'
  },
  {
    postalCode: '24398',
    city: 'Karlsburgerholz'
  },
  {
    postalCode: '24398',
    city: 'Kniepenberg'
  },
  {
    postalCode: '24398',
    city: 'Steinerholz'
  },
  {
    postalCode: '24398',
    city: 'Winnemark'
  },
  {
    postalCode: '24399',
    city: 'Arnis'
  },
  {
    postalCode: '24399',
    city: 'Marienhof'
  },
  {
    postalCode: '24401',
    city: 'Böel'
  },
  {
    postalCode: '24402',
    city: 'Esgrus'
  },
  {
    postalCode: '24402',
    city: 'Schrepperie'
  },
  {
    postalCode: '24404',
    city: 'Maasholm'
  },
  {
    postalCode: '24404',
    city: 'Schleimünde'
  },
  {
    postalCode: '24405',
    city: 'Böelnorderfeld'
  },
  {
    postalCode: '24405',
    city: 'Borrishaag'
  },
  {
    postalCode: '24405',
    city: 'Fraulund'
  },
  {
    postalCode: '24405',
    city: 'Mohrkirch'
  },
  {
    postalCode: '24405',
    city: 'Rügge'
  },
  {
    postalCode: '24405',
    city: 'Stoltoft'
  },
  {
    postalCode: '24405',
    city: 'Thiesholz'
  },
  {
    postalCode: '24407',
    city: 'Karschau'
  },
  {
    postalCode: '24407',
    city: 'Kragelund'
  },
  {
    postalCode: '24407',
    city: 'Oersberg'
  },
  {
    postalCode: '24407',
    city: 'Rabenkirchen-Faulück'
  },
  {
    postalCode: '24407',
    city: 'Schweltholm'
  },
  {
    postalCode: '24409',
    city: 'Stoltebüll'
  },
  {
    postalCode: '24534',
    city: 'Neumünster'
  },
  {
    postalCode: '24536',
    city: 'Neumünster'
  },
  {
    postalCode: '24536',
    city: 'Tasdorf'
  },
  {
    postalCode: '24537',
    city: 'Neumünster'
  },
  {
    postalCode: '24539',
    city: 'Neumünster'
  },
  {
    postalCode: '24558',
    city: 'Henstedt-Ulzburg'
  },
  {
    postalCode: '24558',
    city: 'Wakendorf'
  },
  {
    postalCode: '24568',
    city: 'Kaltenkirchen'
  },
  {
    postalCode: '24568',
    city: 'Kattendorf'
  },
  {
    postalCode: '24568',
    city: 'Nützen'
  },
  {
    postalCode: '24568',
    city: 'Oersdorf'
  },
  {
    postalCode: '24568',
    city: 'Winsen'
  },
  {
    postalCode: '24576',
    city: 'Bad Bramstedt'
  },
  {
    postalCode: '24576',
    city: 'Bimöhlen'
  },
  {
    postalCode: '24576',
    city: 'Hagen'
  },
  {
    postalCode: '24576',
    city: 'Hegebuchenbusch'
  },
  {
    postalCode: '24576',
    city: 'Hitzhusen'
  },
  {
    postalCode: '24576',
    city: 'Mönkloh'
  },
  {
    postalCode: '24576',
    city: 'Reesmoor'
  },
  {
    postalCode: '24576',
    city: 'Weddelbrook'
  },
  {
    postalCode: '24582',
    city: 'Bissee'
  },
  {
    postalCode: '24582',
    city: 'Bordesholm'
  },
  {
    postalCode: '24582',
    city: 'Brügge'
  },
  {
    postalCode: '24582',
    city: 'Groß Buchwald'
  },
  {
    postalCode: '24582',
    city: 'Hoffeld'
  },
  {
    postalCode: '24582',
    city: 'Mühbrook'
  },
  {
    postalCode: '24582',
    city: 'Schönbek'
  },
  {
    postalCode: '24582',
    city: 'Wattenbek'
  },
  {
    postalCode: '24589',
    city: 'Borgdorf-Seedorf'
  },
  {
    postalCode: '24589',
    city: 'Dätgen'
  },
  {
    postalCode: '24589',
    city: 'Eisendorf'
  },
  {
    postalCode: '24589',
    city: 'Ellerdorf'
  },
  {
    postalCode: '24589',
    city: 'Nortorf'
  },
  {
    postalCode: '24589',
    city: 'Schülp'
  },
  {
    postalCode: '24594',
    city: 'Grauel'
  },
  {
    postalCode: '24594',
    city: 'Heinkenborstel'
  },
  {
    postalCode: '24594',
    city: 'Hohenwestedt'
  },
  {
    postalCode: '24594',
    city: 'Jahrsdorf'
  },
  {
    postalCode: '24594',
    city: 'Meezen'
  },
  {
    postalCode: '24594',
    city: 'Mörel'
  },
  {
    postalCode: '24594',
    city: 'Nindorf'
  },
  {
    postalCode: '24594',
    city: 'Rade'
  },
  {
    postalCode: '24594',
    city: 'Remmels'
  },
  {
    postalCode: '24594',
    city: 'Tappendorf'
  },
  {
    postalCode: '24594',
    city: 'Wapelfeld'
  },
  {
    postalCode: '24598',
    city: 'Boostedt'
  },
  {
    postalCode: '24598',
    city: 'Heidmühlen'
  },
  {
    postalCode: '24598',
    city: 'Latendorf'
  },
  {
    postalCode: '24601',
    city: 'Belau'
  },
  {
    postalCode: '24601',
    city: 'Ruhwinkel'
  },
  {
    postalCode: '24601',
    city: 'Stolpe'
  },
  {
    postalCode: '24601',
    city: 'Wankendorf'
  },
  {
    postalCode: '24610',
    city: 'Gönnebek'
  },
  {
    postalCode: '24610',
    city: 'Trappenkamp'
  },
  {
    postalCode: '24613',
    city: 'Aukrug'
  },
  {
    postalCode: '24613',
    city: 'Wiedenborstel'
  },
  {
    postalCode: '24616',
    city: 'Armstedt'
  },
  {
    postalCode: '24616',
    city: 'Borstel'
  },
  {
    postalCode: '24616',
    city: 'Brokstedt'
  },
  {
    postalCode: '24616',
    city: 'Hardebek'
  },
  {
    postalCode: '24616',
    city: 'Hasenkrug'
  },
  {
    postalCode: '24616',
    city: 'Sarlhusen'
  },
  {
    postalCode: '24616',
    city: 'Willenscharen'
  },
  {
    postalCode: '24619',
    city: 'Bornhöved'
  },
  {
    postalCode: '24619',
    city: 'Rendswühren'
  },
  {
    postalCode: '24619',
    city: 'Tarbek'
  },
  {
    postalCode: '24620',
    city: 'Bönebüttel'
  },
  {
    postalCode: '24622',
    city: 'Gnutz'
  },
  {
    postalCode: '24623',
    city: 'Großenaspe'
  },
  {
    postalCode: '24625',
    city: 'Großharrie'
  },
  {
    postalCode: '24625',
    city: 'Negenharrie'
  },
  {
    postalCode: '24626',
    city: 'Groß Kummerfeld'
  },
  {
    postalCode: '24628',
    city: 'Hartenholm'
  },
  {
    postalCode: '24629',
    city: 'Kisdorf'
  },
  {
    postalCode: '24631',
    city: 'Langwedel'
  },
  {
    postalCode: '24632',
    city: 'Heidmoor'
  },
  {
    postalCode: '24632',
    city: 'Lentföhrden'
  },
  {
    postalCode: '24634',
    city: 'Arpsdorf'
  },
  {
    postalCode: '24634',
    city: 'Padenstedt'
  },
  {
    postalCode: '24635',
    city: 'Daldorf'
  },
  {
    postalCode: '24635',
    city: 'Rickling'
  },
  {
    postalCode: '24637',
    city: 'Ovendorferredder'
  },
  {
    postalCode: '24637',
    city: 'Schillsdorf'
  },
  {
    postalCode: '24638',
    city: 'Schmalensee'
  },
  {
    postalCode: '24640',
    city: 'Bollweg'
  },
  {
    postalCode: '24640',
    city: 'Hasenmoor'
  },
  {
    postalCode: '24640',
    city: 'Schmalfeld'
  },
  {
    postalCode: '24640',
    city: 'Wierenkamp'
  },
  {
    postalCode: '24641',
    city: 'Hüttblek'
  },
  {
    postalCode: '24641',
    city: 'Sievershütten'
  },
  {
    postalCode: '24641',
    city: 'Stuvenborn'
  },
  {
    postalCode: '24643',
    city: 'Struvenhütten'
  },
  {
    postalCode: '24644',
    city: 'Krogaspe'
  },
  {
    postalCode: '24644',
    city: 'Loop'
  },
  {
    postalCode: '24644',
    city: 'Timmaspe'
  },
  {
    postalCode: '24646',
    city: 'Warder'
  },
  {
    postalCode: '24647',
    city: 'Ehndorf'
  },
  {
    postalCode: '24647',
    city: 'Wasbek'
  },
  {
    postalCode: '24649',
    city: 'Fuhlendorf'
  },
  {
    postalCode: '24649',
    city: 'Wiemersdorf'
  },
  {
    postalCode: '24768',
    city: 'Nübbel'
  },
  {
    postalCode: '24768',
    city: 'Rendsburg'
  },
  {
    postalCode: '24782',
    city: 'Büdelsdorf'
  },
  {
    postalCode: '24782',
    city: 'Duten'
  },
  {
    postalCode: '24782',
    city: 'Rickert'
  },
  {
    postalCode: '24783',
    city: 'Osterrönfeld'
  },
  {
    postalCode: '24784',
    city: 'Westerrönfeld'
  },
  {
    postalCode: '24787',
    city: 'Ahrenstedt'
  },
  {
    postalCode: '24787',
    city: 'Fockbek'
  },
  {
    postalCode: '24790',
    city: 'Haßmoor'
  },
  {
    postalCode: '24790',
    city: 'Heidkrug'
  },
  {
    postalCode: '24790',
    city: 'Ostenfeld'
  },
  {
    postalCode: '24790',
    city: 'Rade'
  },
  {
    postalCode: '24790',
    city: 'Schacht-Audorf'
  },
  {
    postalCode: '24790',
    city: 'Schülldorf'
  },
  {
    postalCode: '24791',
    city: 'Alt Duvenstedt'
  },
  {
    postalCode: '24791',
    city: 'Neu Duvenstedt-Nord'
  },
  {
    postalCode: '24793',
    city: 'Bargstedt'
  },
  {
    postalCode: '24793',
    city: 'Brammer'
  },
  {
    postalCode: '24793',
    city: 'Oldenhütten'
  },
  {
    postalCode: '24794',
    city: 'Borgstedt'
  },
  {
    postalCode: '24794',
    city: 'Bünsdorf'
  },
  {
    postalCode: '24794',
    city: 'Inselhof'
  },
  {
    postalCode: '24794',
    city: 'Neu Duvenstedt'
  },
  {
    postalCode: '24794',
    city: 'Rader Insel'
  },
  {
    postalCode: '24796',
    city: 'Bovenau'
  },
  {
    postalCode: '24796',
    city: 'Bredenbek'
  },
  {
    postalCode: '24796',
    city: 'Krummwisch'
  },
  {
    postalCode: '24797',
    city: 'Bokelhoop'
  },
  {
    postalCode: '24797',
    city: 'Breiholz'
  },
  {
    postalCode: '24797',
    city: 'Gieselauschleuse'
  },
  {
    postalCode: '24797',
    city: 'Hörsten'
  },
  {
    postalCode: '24797',
    city: 'Jevenberg'
  },
  {
    postalCode: '24797',
    city: 'Moltkestein'
  },
  {
    postalCode: '24797',
    city: 'Tackesdorf-Nord'
  },
  {
    postalCode: '24799',
    city: 'Christiansholm'
  },
  {
    postalCode: '24799',
    city: 'Friedrichsgraben'
  },
  {
    postalCode: '24799',
    city: 'Friedrichsholm'
  },
  {
    postalCode: '24799',
    city: 'Königshügel'
  },
  {
    postalCode: '24799',
    city: 'Meggerdorf'
  },
  {
    postalCode: '24799',
    city: 'Umleitungsdeich'
  },
  {
    postalCode: '24800',
    city: 'Elsdorf-Westermühlen'
  },
  {
    postalCode: '24802',
    city: 'Bokel'
  },
  {
    postalCode: '24802',
    city: 'Emkendorf'
  },
  {
    postalCode: '24802',
    city: 'Groß Vollstedt'
  },
  {
    postalCode: '24803',
    city: 'Erfde'
  },
  {
    postalCode: '24803',
    city: 'Tielen'
  },
  {
    postalCode: '24805',
    city: 'Hamdorf'
  },
  {
    postalCode: '24805',
    city: 'Prinzenmoor'
  },
  {
    postalCode: '24806',
    city: 'Bargstall'
  },
  {
    postalCode: '24806',
    city: 'Hohn'
  },
  {
    postalCode: '24806',
    city: 'Lohe-Föhrden'
  },
  {
    postalCode: '24806',
    city: 'Sophienhamm'
  },
  {
    postalCode: '24806',
    city: 'Sorgbrück'
  },
  {
    postalCode: '24808',
    city: 'Jevenstedt'
  },
  {
    postalCode: '24808',
    city: 'Plirup'
  },
  {
    postalCode: '24809',
    city: 'Nübbel'
  },
  {
    postalCode: '24811',
    city: 'Ahlefeld'
  },
  {
    postalCode: '24811',
    city: 'Brekendorf'
  },
  {
    postalCode: '24811',
    city: 'Owschlag'
  },
  {
    postalCode: '24811',
    city: 'Stenten'
  },
  {
    postalCode: '24813',
    city: 'Schülp'
  },
  {
    postalCode: '24814',
    city: 'Sehestedt'
  },
  {
    postalCode: '24816',
    city: 'Brinjahe'
  },
  {
    postalCode: '24816',
    city: 'Freudenberg'
  },
  {
    postalCode: '24816',
    city: 'Hamweddel'
  },
  {
    postalCode: '24816',
    city: 'Luhnstedt'
  },
  {
    postalCode: '24816',
    city: 'Luhnvie'
  },
  {
    postalCode: '24816',
    city: 'Stafstedt'
  },
  {
    postalCode: '24817',
    city: 'Tetenhusen'
  },
  {
    postalCode: '24819',
    city: 'Embühren'
  },
  {
    postalCode: '24819',
    city: 'Grotenknöll'
  },
  {
    postalCode: '24819',
    city: 'Haale'
  },
  {
    postalCode: '24819',
    city: 'Nienborstel'
  },
  {
    postalCode: '24819',
    city: 'Todenbüttel'
  },
  {
    postalCode: '24837',
    city: 'Königswill'
  },
  {
    postalCode: '24837',
    city: 'Schleswig'
  },
  {
    postalCode: '24848',
    city: 'Alt Bennebek'
  },
  {
    postalCode: '24848',
    city: 'Boklund'
  },
  {
    postalCode: '24848',
    city: 'Großrheider Ausbau'
  },
  {
    postalCode: '24848',
    city: 'Großrheiderfeld'
  },
  {
    postalCode: '24848',
    city: 'Klein Bennebek'
  },
  {
    postalCode: '24848',
    city: 'Klein Rheide'
  },
  {
    postalCode: '24848',
    city: 'Kropp'
  },
  {
    postalCode: '24848',
    city: 'Mielberg'
  },
  {
    postalCode: '24850',
    city: 'Hüsby'
  },
  {
    postalCode: '24850',
    city: 'Lürschau'
  },
  {
    postalCode: '24850',
    city: 'Schuby'
  },
  {
    postalCode: '24852',
    city: 'Eggebek'
  },
  {
    postalCode: '24852',
    city: 'Langstedt'
  },
  {
    postalCode: '24852',
    city: 'Sollerup'
  },
  {
    postalCode: '24852',
    city: 'Süderhackstedt'
  },
  {
    postalCode: '24855',
    city: 'Bollingstedt'
  },
  {
    postalCode: '24855',
    city: 'Jübek'
  },
  {
    postalCode: '24857',
    city: 'Borgwedel'
  },
  {
    postalCode: '24857',
    city: 'Fahrdorf'
  },
  {
    postalCode: '24860',
    city: 'Böklund'
  },
  {
    postalCode: '24860',
    city: 'Klappholz'
  },
  {
    postalCode: '24860',
    city: 'Uelsby'
  },
  {
    postalCode: '24860',
    city: 'Wellspang'
  },
  {
    postalCode: '24861',
    city: 'Bergenhusen'
  },
  {
    postalCode: '24861',
    city: 'Fünfmühlen'
  },
  {
    postalCode: '24861',
    city: 'Papenbrook'
  },
  {
    postalCode: '24861',
    city: 'Reppel'
  },
  {
    postalCode: '24861',
    city: 'Wassermühle'
  },
  {
    postalCode: '24863',
    city: 'Barkhorn'
  },
  {
    postalCode: '24863',
    city: 'Börm'
  },
  {
    postalCode: '24864',
    city: 'Brodersby'
  },
  {
    postalCode: '24864',
    city: 'Goltoft'
  },
  {
    postalCode: '24866',
    city: 'Busdorf'
  },
  {
    postalCode: '24867',
    city: 'Dannewerk'
  },
  {
    postalCode: '24869',
    city: 'Dörpstedt'
  },
  {
    postalCode: '24870',
    city: 'Ellingstedt'
  },
  {
    postalCode: '24872',
    city: 'Groß Rheide'
  },
  {
    postalCode: '24873',
    city: 'Havetoft'
  },
  {
    postalCode: '24875',
    city: 'Havetoftloit'
  },
  {
    postalCode: '24876',
    city: 'Hollingstedt'
  },
  {
    postalCode: '24878',
    city: 'Jagel'
  },
  {
    postalCode: '24878',
    city: 'Lottorf'
  },
  {
    postalCode: '24879',
    city: 'Idstedt'
  },
  {
    postalCode: '24879',
    city: 'Neuberend'
  },
  {
    postalCode: '24881',
    city: 'Nübel'
  },
  {
    postalCode: '24882',
    city: 'Geelbek'
  },
  {
    postalCode: '24882',
    city: 'Schaalby'
  },
  {
    postalCode: '24884',
    city: 'Geltorf'
  },
  {
    postalCode: '24884',
    city: 'Hahnenkrug'
  },
  {
    postalCode: '24884',
    city: 'Selk'
  },
  {
    postalCode: '24885',
    city: 'Sieverstedt'
  },
  {
    postalCode: '24887',
    city: 'Schwittschau'
  },
  {
    postalCode: '24887',
    city: 'Silberstedt'
  },
  {
    postalCode: '24888',
    city: 'Brebelmoor'
  },
  {
    postalCode: '24888',
    city: 'Loit'
  },
  {
    postalCode: '24888',
    city: 'Schmedeland'
  },
  {
    postalCode: '24888',
    city: 'Steinfeld'
  },
  {
    postalCode: '24890',
    city: 'Stolk'
  },
  {
    postalCode: '24890',
    city: 'Süderfahrenstedt'
  },
  {
    postalCode: '24891',
    city: 'Dingwatt'
  },
  {
    postalCode: '24891',
    city: 'Ekebergkrug'
  },
  {
    postalCode: '24891',
    city: 'Eslingholz'
  },
  {
    postalCode: '24891',
    city: 'Hollmühle'
  },
  {
    postalCode: '24891',
    city: 'Lüttholm'
  },
  {
    postalCode: '24891',
    city: 'Rabenholzlück'
  },
  {
    postalCode: '24891',
    city: 'Schnarup-Thumby'
  },
  {
    postalCode: '24891',
    city: 'Struxdorf'
  },
  {
    postalCode: '24893',
    city: 'Taarstedt'
  },
  {
    postalCode: '24894',
    city: 'Hoffnungstal'
  },
  {
    postalCode: '24894',
    city: 'Tolk'
  },
  {
    postalCode: '24894',
    city: 'Twedt'
  },
  {
    postalCode: '24896',
    city: 'Nordergeilwang'
  },
  {
    postalCode: '24896',
    city: 'Treia'
  },
  {
    postalCode: '24897',
    city: 'Ulsnis'
  },
  {
    postalCode: '24899',
    city: 'Wohlde'
  },
  {
    postalCode: '24937',
    city: 'Flensburg'
  },
  {
    postalCode: '24939',
    city: 'Flensburg'
  },
  {
    postalCode: '24941',
    city: 'Flensburg'
  },
  {
    postalCode: '24941',
    city: 'Jarplund-Weding'
  },
  {
    postalCode: '24943',
    city: 'Flensburg'
  },
  {
    postalCode: '24943',
    city: 'Tastrup'
  },
  {
    postalCode: '24944',
    city: 'Flensburg'
  },
  {
    postalCode: '24955',
    city: 'Harrislee'
  },
  {
    postalCode: '24960',
    city: 'Glücksburg'
  },
  {
    postalCode: '24960',
    city: 'Munkbrarup'
  },
  {
    postalCode: '24963',
    city: 'Jerrishoe'
  },
  {
    postalCode: '24963',
    city: 'Tarp'
  },
  {
    postalCode: '24966',
    city: 'Sörup'
  },
  {
    postalCode: '24969',
    city: 'Großenwiehe'
  },
  {
    postalCode: '24969',
    city: 'Lindewitt'
  },
  {
    postalCode: '24972',
    city: 'Quern'
  },
  {
    postalCode: '24972',
    city: 'Steinberg'
  },
  {
    postalCode: '24972',
    city: 'Steinbergkirche'
  },
  {
    postalCode: '24975',
    city: 'Ausacker'
  },
  {
    postalCode: '24975',
    city: 'Hürup'
  },
  {
    postalCode: '24975',
    city: 'Husby'
  },
  {
    postalCode: '24975',
    city: 'Maasbüll'
  },
  {
    postalCode: '24977',
    city: 'Grundhof'
  },
  {
    postalCode: '24977',
    city: 'Langballig'
  },
  {
    postalCode: '24977',
    city: 'Ringsberg'
  },
  {
    postalCode: '24977',
    city: 'Westerholz'
  },
  {
    postalCode: '24980',
    city: 'Hörup'
  },
  {
    postalCode: '24980',
    city: 'Meyn'
  },
  {
    postalCode: '24980',
    city: 'Nordhackstedt'
  },
  {
    postalCode: '24980',
    city: 'Schafflund'
  },
  {
    postalCode: '24980',
    city: 'Wallsbüll'
  },
  {
    postalCode: '24983',
    city: 'Handewitt'
  },
  {
    postalCode: '24986',
    city: 'Rüde'
  },
  {
    postalCode: '24986',
    city: 'Satrup'
  },
  {
    postalCode: '24988',
    city: 'Oeversee'
  },
  {
    postalCode: '24988',
    city: 'Sankelmark'
  },
  {
    postalCode: '24989',
    city: 'Dollerup'
  },
  {
    postalCode: '24989',
    city: 'Tiefengruft'
  },
  {
    postalCode: '24991',
    city: 'Freienwill'
  },
  {
    postalCode: '24991',
    city: 'Großsolt'
  },
  {
    postalCode: '24992',
    city: 'Janneby'
  },
  {
    postalCode: '24992',
    city: 'Jerrisbekfeld'
  },
  {
    postalCode: '24992',
    city: 'Jörl'
  },
  {
    postalCode: '24992',
    city: 'Süderhackstedtfeld'
  },
  {
    postalCode: '24994',
    city: 'Böxlund'
  },
  {
    postalCode: '24994',
    city: 'Holt'
  },
  {
    postalCode: '24994',
    city: 'Jardelund'
  },
  {
    postalCode: '24994',
    city: 'Medelby'
  },
  {
    postalCode: '24994',
    city: 'Osterby'
  },
  {
    postalCode: '24994',
    city: 'Weesby'
  },
  {
    postalCode: '24996',
    city: 'Ahneby'
  },
  {
    postalCode: '24996',
    city: 'Sterup'
  },
  {
    postalCode: '24997',
    city: 'Wanderup'
  },
  {
    postalCode: '24999',
    city: 'Wees'
  },
  {
    postalCode: '25335',
    city: 'Altenmoor'
  },
  {
    postalCode: '25335',
    city: 'Bokholt-Hanredder'
  },
  {
    postalCode: '25335',
    city: 'Elmshorn'
  },
  {
    postalCode: '25335',
    city: 'Neuendorf'
  },
  {
    postalCode: '25335',
    city: 'Raa-Besenbek'
  },
  {
    postalCode: '25336',
    city: 'Elmshorn'
  },
  {
    postalCode: '25336',
    city: 'Klein Nordende'
  },
  {
    postalCode: '25337',
    city: 'Elmshorn'
  },
  {
    postalCode: '25337',
    city: 'Kölln-Reisiek'
  },
  {
    postalCode: '25337',
    city: 'Seeth-Ekholt'
  },
  {
    postalCode: '25348',
    city: 'Blomesche Wildnis'
  },
  {
    postalCode: '25348',
    city: 'Engelbrechtsche Wildnis'
  },
  {
    postalCode: '25348',
    city: 'Glückstadt'
  },
  {
    postalCode: '25355',
    city: 'Barmstedt'
  },
  {
    postalCode: '25355',
    city: 'Bevern'
  },
  {
    postalCode: '25355',
    city: 'Bullenkuhlen'
  },
  {
    postalCode: '25355',
    city: 'Groß Offenseth-Aspern'
  },
  {
    postalCode: '25355',
    city: 'Heede'
  },
  {
    postalCode: '25355',
    city: 'Lutzhorn'
  },
  {
    postalCode: '25358',
    city: 'Hohenfelde'
  },
  {
    postalCode: '25358',
    city: 'Horst'
  },
  {
    postalCode: '25358',
    city: 'Sommerland'
  },
  {
    postalCode: '25361',
    city: 'Elskop'
  },
  {
    postalCode: '25361',
    city: 'Gehrhof'
  },
  {
    postalCode: '25361',
    city: 'Grevenkop'
  },
  {
    postalCode: '25361',
    city: 'Krempe'
  },
  {
    postalCode: '25361',
    city: 'Muchelndorf'
  },
  {
    postalCode: '25361',
    city: 'Süderau'
  },
  {
    postalCode: '25364',
    city: 'Bokel'
  },
  {
    postalCode: '25364',
    city: 'Brande-Hörnerkirchen'
  },
  {
    postalCode: '25364',
    city: 'Osterhorn'
  },
  {
    postalCode: '25364',
    city: 'Westerhorn'
  },
  {
    postalCode: '25365',
    city: 'Klein Offenseth-Sparrieshoop'
  },
  {
    postalCode: '25368',
    city: 'Kiebitzreihe'
  },
  {
    postalCode: '25370',
    city: 'Seester'
  },
  {
    postalCode: '25371',
    city: 'Seestermühe'
  },
  {
    postalCode: '25373',
    city: 'Ellerhoop'
  },
  {
    postalCode: '25376',
    city: 'Borsfleth'
  },
  {
    postalCode: '25376',
    city: 'Krempdorf'
  },
  {
    postalCode: '25377',
    city: 'Kollmar'
  },
  {
    postalCode: '25377',
    city: 'Pagensand'
  },
  {
    postalCode: '25379',
    city: 'Herzhorn'
  },
  {
    postalCode: '25379',
    city: 'Kamerlanderdeich'
  },
  {
    postalCode: '25421',
    city: 'Pinneberg'
  },
  {
    postalCode: '25436',
    city: 'Groß Nordende'
  },
  {
    postalCode: '25436',
    city: 'Heidgraben'
  },
  {
    postalCode: '25436',
    city: 'Moorrege'
  },
  {
    postalCode: '25436',
    city: 'Neuendeich'
  },
  {
    postalCode: '25436',
    city: 'Tornesch'
  },
  {
    postalCode: '25436',
    city: 'Uetersen'
  },
  {
    postalCode: '25451',
    city: 'Quickborn'
  },
  {
    postalCode: '25462',
    city: 'Rellingen'
  },
  {
    postalCode: '25469',
    city: 'Halstenbek'
  },
  {
    postalCode: '25474',
    city: 'Bönningstedt'
  },
  {
    postalCode: '25474',
    city: 'Ellerbek'
  },
  {
    postalCode: '25474',
    city: 'Hasloh'
  },
  {
    postalCode: '25479',
    city: 'Ellerau'
  },
  {
    postalCode: '25482',
    city: 'Appen'
  },
  {
    postalCode: '25485',
    city: 'Bilsen'
  },
  {
    postalCode: '25485',
    city: 'Hemdingen'
  },
  {
    postalCode: '25485',
    city: 'Langeln'
  },
  {
    postalCode: '25486',
    city: 'Alveslohe'
  },
  {
    postalCode: '25488',
    city: 'Holm'
  },
  {
    postalCode: '25489',
    city: 'Haselau'
  },
  {
    postalCode: '25489',
    city: 'Haseldorf'
  },
  {
    postalCode: '25491',
    city: 'Hetlingen'
  },
  {
    postalCode: '25492',
    city: 'Heist'
  },
  {
    postalCode: '25492',
    city: 'Hetlinger Neuerkoog'
  },
  {
    postalCode: '25494',
    city: 'Borstel-Hohenraden'
  },
  {
    postalCode: '25495',
    city: 'Kummerfeld'
  },
  {
    postalCode: '25497',
    city: 'Prisdorf'
  },
  {
    postalCode: '25499',
    city: 'Tangstedt'
  },
  {
    postalCode: '25524',
    city: 'Bekmünde'
  },
  {
    postalCode: '25524',
    city: 'Breitenburg'
  },
  {
    postalCode: '25524',
    city: 'Heiligenstedten'
  },
  {
    postalCode: '25524',
    city: 'Heiligenstedtenerkamp'
  },
  {
    postalCode: '25524',
    city: 'Itzehoe'
  },
  {
    postalCode: '25524',
    city: 'Kollmoor'
  },
  {
    postalCode: '25524',
    city: 'Oelixdorf'
  },
  {
    postalCode: '25541',
    city: 'Brunsbüttel'
  },
  {
    postalCode: '25548',
    city: 'Auufer'
  },
  {
    postalCode: '25548',
    city: 'Kellinghusen'
  },
  {
    postalCode: '25548',
    city: 'Mühlenbarbek'
  },
  {
    postalCode: '25548',
    city: 'Oeschebüttel'
  },
  {
    postalCode: '25548',
    city: 'Rosdorf'
  },
  {
    postalCode: '25548',
    city: 'Störkathen'
  },
  {
    postalCode: '25548',
    city: 'Wittenbergen'
  },
  {
    postalCode: '25551',
    city: 'Hohenlockstedt'
  },
  {
    postalCode: '25551',
    city: 'Lockstedt'
  },
  {
    postalCode: '25551',
    city: 'Lohbarbek'
  },
  {
    postalCode: '25551',
    city: 'Peissen'
  },
  {
    postalCode: '25551',
    city: 'Schlotfeld'
  },
  {
    postalCode: '25551',
    city: 'Silzen'
  },
  {
    postalCode: '25551',
    city: 'Springhoe'
  },
  {
    postalCode: '25551',
    city: 'Winseldorf'
  },
  {
    postalCode: '25554',
    city: 'Bekdorf'
  },
  {
    postalCode: '25554',
    city: 'Brokdorferlandscheide'
  },
  {
    postalCode: '25554',
    city: 'Dammfleth'
  },
  {
    postalCode: '25554',
    city: 'Kleve'
  },
  {
    postalCode: '25554',
    city: 'Krummendiek'
  },
  {
    postalCode: '25554',
    city: 'Landrecht'
  },
  {
    postalCode: '25554',
    city: 'Moorhusen'
  },
  {
    postalCode: '25554',
    city: 'Neuendorf'
  },
  {
    postalCode: '25554',
    city: 'Nortorf'
  },
  {
    postalCode: '25554',
    city: 'Sachsenbande'
  },
  {
    postalCode: '25554',
    city: 'Stördorf'
  },
  {
    postalCode: '25554',
    city: 'Wilster'
  },
  {
    postalCode: '25557',
    city: 'Beldorf'
  },
  {
    postalCode: '25557',
    city: 'Bendorf'
  },
  {
    postalCode: '25557',
    city: 'Bornholt'
  },
  {
    postalCode: '25557',
    city: 'Gokels'
  },
  {
    postalCode: '25557',
    city: 'Hanerau-Hademarschen'
  },
  {
    postalCode: '25557',
    city: 'Keller'
  },
  {
    postalCode: '25557',
    city: 'Oldenbüttel'
  },
  {
    postalCode: '25557',
    city: 'Seefeld'
  },
  {
    postalCode: '25557',
    city: 'Steenfeld'
  },
  {
    postalCode: '25557',
    city: 'Thaden'
  },
  {
    postalCode: '25560',
    city: 'Aasbüttel'
  },
  {
    postalCode: '25560',
    city: 'Agethorst'
  },
  {
    postalCode: '25560',
    city: 'Bokhorst'
  },
  {
    postalCode: '25560',
    city: 'Hadenfeld'
  },
  {
    postalCode: '25560',
    city: 'Kaisborstel'
  },
  {
    postalCode: '25560',
    city: 'Oldenborstel'
  },
  {
    postalCode: '25560',
    city: 'Pöschendorf'
  },
  {
    postalCode: '25560',
    city: 'Puls'
  },
  {
    postalCode: '25560',
    city: 'Schenefeld'
  },
  {
    postalCode: '25560',
    city: 'Siezbüttel'
  },
  {
    postalCode: '25560',
    city: 'Warringholz'
  },
  {
    postalCode: '25563',
    city: 'Bargholz'
  },
  {
    postalCode: '25563',
    city: 'Föhrden-Barl'
  },
  {
    postalCode: '25563',
    city: 'Hingstheide'
  },
  {
    postalCode: '25563',
    city: 'Quarnstedt'
  },
  {
    postalCode: '25563',
    city: 'Wrist'
  },
  {
    postalCode: '25563',
    city: 'Wulfsmoor'
  },
  {
    postalCode: '25566',
    city: 'Lägerdorf'
  },
  {
    postalCode: '25566',
    city: 'Rethwisch'
  },
  {
    postalCode: '25569',
    city: 'Bahrenfleth'
  },
  {
    postalCode: '25569',
    city: 'Hodorf'
  },
  {
    postalCode: '25569',
    city: 'Kremperheide'
  },
  {
    postalCode: '25569',
    city: 'Krempermoor'
  },
  {
    postalCode: '25572',
    city: 'Aebtissinwisch'
  },
  {
    postalCode: '25572',
    city: 'Büttel'
  },
  {
    postalCode: '25572',
    city: 'Ecklak'
  },
  {
    postalCode: '25572',
    city: 'Kudensee'
  },
  {
    postalCode: '25572',
    city: 'Landscheide'
  },
  {
    postalCode: '25572',
    city: 'Oberstenwehr'
  },
  {
    postalCode: '25572',
    city: 'Sankt Margarethen'
  },
  {
    postalCode: '25572',
    city: 'Vaalerfeld'
  },
  {
    postalCode: '25573',
    city: 'Beidenfleth'
  },
  {
    postalCode: '25573',
    city: 'Klein Kampen'
  },
  {
    postalCode: '25575',
    city: 'Beringstedt'
  },
  {
    postalCode: '25576',
    city: 'Brokdorf'
  },
  {
    postalCode: '25578',
    city: 'Dägeling'
  },
  {
    postalCode: '25578',
    city: 'Neuenbrook'
  },
  {
    postalCode: '25579',
    city: 'Fitzbek'
  },
  {
    postalCode: '25579',
    city: 'Rade'
  },
  {
    postalCode: '25581',
    city: 'Hennstedt'
  },
  {
    postalCode: '25581',
    city: 'Poyenberg'
  },
  {
    postalCode: '25582',
    city: 'Drage'
  },
  {
    postalCode: '25582',
    city: 'Hohenaspe'
  },
  {
    postalCode: '25582',
    city: 'Kaaks'
  },
  {
    postalCode: '25582',
    city: 'Looft'
  },
  {
    postalCode: '25584',
    city: 'Besdorf'
  },
  {
    postalCode: '25584',
    city: 'Holstenniendorf'
  },
  {
    postalCode: '25585',
    city: 'Lütjenwestedt'
  },
  {
    postalCode: '25585',
    city: 'Tackesdorf'
  },
  {
    postalCode: '25587',
    city: 'Münsterdorf'
  },
  {
    postalCode: '25588',
    city: 'Huje'
  },
  {
    postalCode: '25588',
    city: 'Mehlbek'
  },
  {
    postalCode: '25588',
    city: 'Oldendorf'
  },
  {
    postalCode: '25590',
    city: 'Osterstedt'
  },
  {
    postalCode: '25591',
    city: 'Ottenbüttel'
  },
  {
    postalCode: '25593',
    city: 'Christinenthal'
  },
  {
    postalCode: '25593',
    city: 'Reher'
  },
  {
    postalCode: '25594',
    city: 'Nutteln'
  },
  {
    postalCode: '25594',
    city: 'Vaale'
  },
  {
    postalCode: '25594',
    city: 'Vaalermoor'
  },
  {
    postalCode: '25596',
    city: 'Bokelrehm'
  },
  {
    postalCode: '25596',
    city: 'Gribbohm'
  },
  {
    postalCode: '25596',
    city: 'Nienbüttel'
  },
  {
    postalCode: '25596',
    city: 'Wacken'
  },
  {
    postalCode: '25597',
    city: 'Breitenberg'
  },
  {
    postalCode: '25597',
    city: 'Kronsmoor'
  },
  {
    postalCode: '25597',
    city: 'Moordiek'
  },
  {
    postalCode: '25597',
    city: 'Moordorf'
  },
  {
    postalCode: '25597',
    city: 'Westermoor'
  },
  {
    postalCode: '25599',
    city: 'Wewelsfleth'
  },
  {
    postalCode: '25693',
    city: 'Christianslust'
  },
  {
    postalCode: '25693',
    city: 'Gudendorf'
  },
  {
    postalCode: '25693',
    city: 'St. Michaelisdonn'
  },
  {
    postalCode: '25693',
    city: 'Trennewurth'
  },
  {
    postalCode: '25693',
    city: 'Volsemenhusen'
  },
  {
    postalCode: '25704',
    city: 'Bargenstedt'
  },
  {
    postalCode: '25704',
    city: 'Elpersbüttel'
  },
  {
    postalCode: '25704',
    city: 'Epenwöhrden'
  },
  {
    postalCode: '25704',
    city: 'Meldorf'
  },
  {
    postalCode: '25704',
    city: 'Nindorf'
  },
  {
    postalCode: '25704',
    city: 'Nordermeldorf'
  },
  {
    postalCode: '25704',
    city: 'Wolmersdorf'
  },
  {
    postalCode: '25709',
    city: 'Diekhusen-Fahrstedt'
  },
  {
    postalCode: '25709',
    city: 'Helse'
  },
  {
    postalCode: '25709',
    city: 'Kaiser-Wilhelm-Koog'
  },
  {
    postalCode: '25709',
    city: 'Kronprinzenkoog'
  },
  {
    postalCode: '25709',
    city: 'Marne'
  },
  {
    postalCode: '25709',
    city: 'Marnerdeich'
  },
  {
    postalCode: '25712',
    city: 'Brickeln'
  },
  {
    postalCode: '25712',
    city: 'Buchholz'
  },
  {
    postalCode: '25712',
    city: 'Burg'
  },
  {
    postalCode: '25712',
    city: 'Burgerfeld'
  },
  {
    postalCode: '25712',
    city: 'Großenrade'
  },
  {
    postalCode: '25712',
    city: 'Hochdonn'
  },
  {
    postalCode: '25712',
    city: 'Kuden'
  },
  {
    postalCode: '25712',
    city: 'Quickborn'
  },
  {
    postalCode: '25715',
    city: 'Averlak'
  },
  {
    postalCode: '25715',
    city: 'Blangenmoorfeld'
  },
  {
    postalCode: '25715',
    city: 'Dingen'
  },
  {
    postalCode: '25715',
    city: 'Eddelak'
  },
  {
    postalCode: '25715',
    city: 'Ramhusen'
  },
  {
    postalCode: '25718',
    city: 'Friedrichskoog'
  },
  {
    postalCode: '25719',
    city: 'Barlt'
  },
  {
    postalCode: '25719',
    city: 'Busenwurth'
  },
  {
    postalCode: '25721',
    city: 'Eggstedt'
  },
  {
    postalCode: '25724',
    city: 'Neufeld'
  },
  {
    postalCode: '25724',
    city: 'Neufelderkoog'
  },
  {
    postalCode: '25724',
    city: 'Schmedeswurth'
  },
  {
    postalCode: '25725',
    city: 'Bornholt'
  },
  {
    postalCode: '25725',
    city: 'Schafstedt'
  },
  {
    postalCode: '25725',
    city: 'Weidenhof'
  },
  {
    postalCode: '25727',
    city: 'Frestedt'
  },
  {
    postalCode: '25727',
    city: 'Krumstedt'
  },
  {
    postalCode: '25727',
    city: 'Süderhastedt'
  },
  {
    postalCode: '25729',
    city: 'Windbergen'
  },
  {
    postalCode: '25746',
    city: 'Heide'
  },
  {
    postalCode: '25746',
    city: 'Lohe-Rickelshof'
  },
  {
    postalCode: '25746',
    city: 'Norderwöhrden'
  },
  {
    postalCode: '25746',
    city: 'Ostrohe'
  },
  {
    postalCode: '25746',
    city: 'Wesseln'
  },
  {
    postalCode: '25761',
    city: 'Büsum'
  },
  {
    postalCode: '25761',
    city: 'Büsumer Deichhausen'
  },
  {
    postalCode: '25761',
    city: 'Hedwigenkoog'
  },
  {
    postalCode: '25761',
    city: 'Oesterdeichstrich'
  },
  {
    postalCode: '25761',
    city: 'Warwerort'
  },
  {
    postalCode: '25761',
    city: 'Westerdeichstrich'
  },
  {
    postalCode: '25764',
    city: 'Friedrichsgabekoog'
  },
  {
    postalCode: '25764',
    city: 'Hellschen-Heringsand-Unterschaar'
  },
  {
    postalCode: '25764',
    city: 'Hillgroven'
  },
  {
    postalCode: '25764',
    city: 'Norddeich'
  },
  {
    postalCode: '25764',
    city: 'Oesterwurth'
  },
  {
    postalCode: '25764',
    city: 'Reinsbüttel'
  },
  {
    postalCode: '25764',
    city: 'Schülp'
  },
  {
    postalCode: '25764',
    city: 'Schülperneuensiel'
  },
  {
    postalCode: '25764',
    city: 'Süderdeich'
  },
  {
    postalCode: '25764',
    city: 'Wesselburen'
  },
  {
    postalCode: '25764',
    city: 'Wesselburener Deichhausen'
  },
  {
    postalCode: '25764',
    city: 'Wesselburenerkoog'
  },
  {
    postalCode: '25764',
    city: 'Wulfenhusen'
  },
  {
    postalCode: '25767',
    city: 'Albersdorf'
  },
  {
    postalCode: '25767',
    city: 'Arkebek'
  },
  {
    postalCode: '25767',
    city: 'Bunsoh'
  },
  {
    postalCode: '25767',
    city: 'Grünental'
  },
  {
    postalCode: '25767',
    city: 'Immenstedt'
  },
  {
    postalCode: '25767',
    city: 'Offenbüttel'
  },
  {
    postalCode: '25767',
    city: 'Osterrade'
  },
  {
    postalCode: '25767',
    city: 'Riesewohld'
  },
  {
    postalCode: '25767',
    city: 'Tensbüttel-Röst'
  },
  {
    postalCode: '25767',
    city: 'Wennbüttel'
  },
  {
    postalCode: '25770',
    city: 'Hemmingstedt'
  },
  {
    postalCode: '25770',
    city: 'Lieth'
  },
  {
    postalCode: '25774',
    city: 'Groven'
  },
  {
    postalCode: '25774',
    city: 'Hemme'
  },
  {
    postalCode: '25774',
    city: 'Karolinenkoog'
  },
  {
    postalCode: '25774',
    city: 'Krempel'
  },
  {
    postalCode: '25774',
    city: 'Lehe'
  },
  {
    postalCode: '25774',
    city: 'Lunden'
  },
  {
    postalCode: '25776',
    city: 'Dammsfeld'
  },
  {
    postalCode: '25776',
    city: 'Hehm'
  },
  {
    postalCode: '25776',
    city: 'Leherfeld'
  },
  {
    postalCode: '25776',
    city: 'Nordfeld'
  },
  {
    postalCode: '25776',
    city: 'Rehm-Flehde-Bargen'
  },
  {
    postalCode: '25776',
    city: 'St. Annen'
  },
  {
    postalCode: '25779',
    city: 'Bergewöhrden'
  },
  {
    postalCode: '25779',
    city: 'Fedderingen'
  },
  {
    postalCode: '25779',
    city: 'Glüsing'
  },
  {
    postalCode: '25779',
    city: 'Hägen'
  },
  {
    postalCode: '25779',
    city: 'Hennstedt'
  },
  {
    postalCode: '25779',
    city: 'Kleve'
  },
  {
    postalCode: '25779',
    city: 'Norderheistedt'
  },
  {
    postalCode: '25779',
    city: 'Schlichting'
  },
  {
    postalCode: '25779',
    city: 'Süderheistedt'
  },
  {
    postalCode: '25779',
    city: 'Westermoor'
  },
  {
    postalCode: '25779',
    city: 'Wiemerstedt'
  },
  {
    postalCode: '25782',
    city: 'Gaushorn'
  },
  {
    postalCode: '25782',
    city: 'Hövede'
  },
  {
    postalCode: '25782',
    city: 'Schalkholz'
  },
  {
    postalCode: '25782',
    city: 'Schrum'
  },
  {
    postalCode: '25782',
    city: 'Süderdorf'
  },
  {
    postalCode: '25782',
    city: 'Tellingstedt'
  },
  {
    postalCode: '25782',
    city: 'Welmbüttel'
  },
  {
    postalCode: '25782',
    city: 'Westerborstel'
  },
  {
    postalCode: '25785',
    city: 'Nordhastedt'
  },
  {
    postalCode: '25785',
    city: 'Odderade'
  },
  {
    postalCode: '25785',
    city: 'Sarzbüttel'
  },
  {
    postalCode: '25786',
    city: 'Dellstedt'
  },
  {
    postalCode: '25788',
    city: 'Delve'
  },
  {
    postalCode: '25788',
    city: 'Hollingstedt'
  },
  {
    postalCode: '25788',
    city: 'Wallen'
  },
  {
    postalCode: '25791',
    city: 'Barkenholm'
  },
  {
    postalCode: '25791',
    city: 'Brandmoor'
  },
  {
    postalCode: '25791',
    city: 'Linden'
  },
  {
    postalCode: '25792',
    city: 'Neuenkirchen'
  },
  {
    postalCode: '25792',
    city: 'Strübbel'
  },
  {
    postalCode: '25794',
    city: 'Dörpling'
  },
  {
    postalCode: '25794',
    city: 'Pahlen'
  },
  {
    postalCode: '25794',
    city: 'Tielenhemme'
  },
  {
    postalCode: '25795',
    city: 'Stelle-Wittenwurth'
  },
  {
    postalCode: '25795',
    city: 'Weddingstedt'
  },
  {
    postalCode: '25797',
    city: 'Wöhrden'
  },
  {
    postalCode: '25799',
    city: 'Wrohm'
  },
  {
    postalCode: '25813',
    city: 'Husum'
  },
  {
    postalCode: '25813',
    city: 'Schwesing'
  },
  {
    postalCode: '25813',
    city: 'Simonsberg'
  },
  {
    postalCode: '25813',
    city: 'Spaltenhof'
  },
  {
    postalCode: '25813',
    city: 'Südermarsch'
  },
  {
    postalCode: '25821',
    city: 'Almdorf'
  },
  {
    postalCode: '25821',
    city: 'Bordelumsiel'
  },
  {
    postalCode: '25821',
    city: 'Bredstedt'
  },
  {
    postalCode: '25821',
    city: 'Breklum'
  },
  {
    postalCode: '25821',
    city: 'Brommelund'
  },
  {
    postalCode: '25821',
    city: 'Dörpum'
  },
  {
    postalCode: '25821',
    city: 'Dörpumfeld'
  },
  {
    postalCode: '25821',
    city: 'Glücksburg'
  },
  {
    postalCode: '25821',
    city: 'Reußenköge'
  },
  {
    postalCode: '25821',
    city: 'Sönnebüll'
  },
  {
    postalCode: '25821',
    city: 'Struckum'
  },
  {
    postalCode: '25821',
    city: 'Vollstedt'
  },
  {
    postalCode: '25826',
    city: 'Sankt Peter-Ording'
  },
  {
    postalCode: '25832',
    city: 'Kotzenbüll'
  },
  {
    postalCode: '25832',
    city: 'Tönning'
  },
  {
    postalCode: '25836',
    city: 'Garding'
  },
  {
    postalCode: '25836',
    city: 'Garding, Kirchspiel'
  },
  {
    postalCode: '25836',
    city: 'Grothusenkoog'
  },
  {
    postalCode: '25836',
    city: 'Katharinenheerd'
  },
  {
    postalCode: '25836',
    city: 'Osterhever'
  },
  {
    postalCode: '25836',
    city: 'Poppenbüll'
  },
  {
    postalCode: '25836',
    city: 'Vollerwiek'
  },
  {
    postalCode: '25836',
    city: 'Welt'
  },
  {
    postalCode: '25840',
    city: 'An der Chaussee'
  },
  {
    postalCode: '25840',
    city: 'Feddersdeich'
  },
  {
    postalCode: '25840',
    city: 'Friedrichstadt'
  },
  {
    postalCode: '25840',
    city: 'Hakenhof'
  },
  {
    postalCode: '25840',
    city: 'Koldenbüttel'
  },
  {
    postalCode: '25840',
    city: 'Mildterhof'
  },
  {
    postalCode: '25840',
    city: 'Peterskoogsdeich'
  },
  {
    postalCode: '25840',
    city: 'Rantrumdeich'
  },
  {
    postalCode: '25840',
    city: 'Ziegelhof'
  },
  {
    postalCode: '25842',
    city: 'Addebüll'
  },
  {
    postalCode: '25842',
    city: 'Bargum'
  },
  {
    postalCode: '25842',
    city: 'Langenhorn'
  },
  {
    postalCode: '25842',
    city: 'Lütjenholm'
  },
  {
    postalCode: '25842',
    city: 'Ockholm'
  },
  {
    postalCode: '25845',
    city: 'Elisabeth-Sophien-Koog'
  },
  {
    postalCode: '25845',
    city: 'Nordstrand'
  },
  {
    postalCode: '25845',
    city: 'Nordstrandischmoor'
  },
  {
    postalCode: '25845',
    city: 'Südfall'
  },
  {
    postalCode: '25849',
    city: 'Pellworm'
  },
  {
    postalCode: '25849',
    city: 'Süderoog'
  },
  {
    postalCode: '25850',
    city: 'Behrendorf'
  },
  {
    postalCode: '25850',
    city: 'Bondelum'
  },
  {
    postalCode: '25852',
    city: 'Bordelum'
  },
  {
    postalCode: '25853',
    city: 'Ahrenshöft'
  },
  {
    postalCode: '25853',
    city: 'Bohmstedt'
  },
  {
    postalCode: '25853',
    city: 'Drelsdorf'
  },
  {
    postalCode: '25855',
    city: 'Haselund'
  },
  {
    postalCode: '25856',
    city: 'Hattstedt'
  },
  {
    postalCode: '25856',
    city: 'Hattstedtermarsch'
  },
  {
    postalCode: '25856',
    city: 'Wobbenbüll'
  },
  {
    postalCode: '25858',
    city: 'Högel'
  },
  {
    postalCode: '25859',
    city: 'Hooge'
  },
  {
    postalCode: '25860',
    city: 'Arlewatt'
  },
  {
    postalCode: '25860',
    city: 'Horstedt'
  },
  {
    postalCode: '25860',
    city: 'Olderup'
  },
  {
    postalCode: '25862',
    city: 'Goldebek'
  },
  {
    postalCode: '25862',
    city: 'Goldelund'
  },
  {
    postalCode: '25862',
    city: 'Joldelund'
  },
  {
    postalCode: '25862',
    city: 'Kolkerheide'
  },
  {
    postalCode: '25863',
    city: 'Langeneß'
  },
  {
    postalCode: '25864',
    city: 'Löwenstedt'
  },
  {
    postalCode: '25866',
    city: 'Mildstedt'
  },
  {
    postalCode: '25868',
    city: 'Erfderdamm'
  },
  {
    postalCode: '25868',
    city: 'Norderstapel'
  },
  {
    postalCode: '25868',
    city: 'Steinschleuse'
  },
  {
    postalCode: '25869',
    city: 'Gröde'
  },
  {
    postalCode: '25869',
    city: 'Habel'
  },
  {
    postalCode: '25869',
    city: 'Oland'
  },
  {
    postalCode: '25870',
    city: 'Norderfriedrichskoog'
  },
  {
    postalCode: '25870',
    city: 'Oldenswort'
  },
  {
    postalCode: '25872',
    city: 'Ostenfeld'
  },
  {
    postalCode: '25872',
    city: 'Wittbek'
  },
  {
    postalCode: '25873',
    city: 'Oldersbek'
  },
  {
    postalCode: '25873',
    city: 'Rantrum'
  },
  {
    postalCode: '25875',
    city: 'Schobüll'
  },
  {
    postalCode: '25876',
    city: 'Fresendelf'
  },
  {
    postalCode: '25876',
    city: 'Hude'
  },
  {
    postalCode: '25876',
    city: 'Huderfähre'
  },
  {
    postalCode: '25876',
    city: 'Ramstedt'
  },
  {
    postalCode: '25876',
    city: 'Schwabstedt'
  },
  {
    postalCode: '25876',
    city: 'Süderhöft'
  },
  {
    postalCode: '25876',
    city: 'Wisch'
  },
  {
    postalCode: '25878',
    city: 'Drage'
  },
  {
    postalCode: '25878',
    city: 'Jeppern'
  },
  {
    postalCode: '25878',
    city: 'Krelau'
  },
  {
    postalCode: '25878',
    city: 'Seeth'
  },
  {
    postalCode: '25879',
    city: 'Süderstapel'
  },
  {
    postalCode: '25881',
    city: 'Tating'
  },
  {
    postalCode: '25881',
    city: 'Tümlauer Koog'
  },
  {
    postalCode: '25881',
    city: 'Westerhever'
  },
  {
    postalCode: '25882',
    city: 'Tetenbüll'
  },
  {
    postalCode: '25884',
    city: 'Norstedt'
  },
  {
    postalCode: '25884',
    city: 'Sollwitt'
  },
  {
    postalCode: '25884',
    city: 'Viöl'
  },
  {
    postalCode: '25885',
    city: 'Ahrenviöl'
  },
  {
    postalCode: '25885',
    city: 'Ahrenviölfeld'
  },
  {
    postalCode: '25885',
    city: 'Immenstedt'
  },
  {
    postalCode: '25885',
    city: 'Oster-Ohrstedt'
  },
  {
    postalCode: '25885',
    city: 'Wester-Ohrstedt'
  },
  {
    postalCode: '25887',
    city: 'Winnert'
  },
  {
    postalCode: '25889',
    city: 'Uelvesbüll'
  },
  {
    postalCode: '25889',
    city: 'Witzwort'
  },
  {
    postalCode: '25899',
    city: 'Bosbüll'
  },
  {
    postalCode: '25899',
    city: 'Dagebüll'
  },
  {
    postalCode: '25899',
    city: 'Galmsbüll'
  },
  {
    postalCode: '25899',
    city: 'Gasthafen'
  },
  {
    postalCode: '25899',
    city: 'Gotteskoogdeich'
  },
  {
    postalCode: '25899',
    city: 'Katzhörn'
  },
  {
    postalCode: '25899',
    city: 'Kleinkoogsdeich'
  },
  {
    postalCode: '25899',
    city: 'Klixbüll'
  },
  {
    postalCode: '25899',
    city: 'Niebüll'
  },
  {
    postalCode: '25899',
    city: 'Osterdeich'
  },
  {
    postalCode: '25917',
    city: 'Achtrup'
  },
  {
    postalCode: '25917',
    city: 'Enge-Sande'
  },
  {
    postalCode: '25917',
    city: 'Leck'
  },
  {
    postalCode: '25917',
    city: 'Sprakebüll'
  },
  {
    postalCode: '25917',
    city: 'Stadum'
  },
  {
    postalCode: '25917',
    city: 'Tinningstedt'
  },
  {
    postalCode: '25920',
    city: 'Risum-Lindholm'
  },
  {
    postalCode: '25920',
    city: 'Stedesand'
  },
  {
    postalCode: '25923',
    city: 'Braderup'
  },
  {
    postalCode: '25923',
    city: 'Ellhöft'
  },
  {
    postalCode: '25923',
    city: 'Hattersbüllhallig'
  },
  {
    postalCode: '25923',
    city: 'Holm'
  },
  {
    postalCode: '25923',
    city: 'Humptrup'
  },
  {
    postalCode: '25923',
    city: 'Lexgaard'
  },
  {
    postalCode: '25923',
    city: 'Süderlügum'
  },
  {
    postalCode: '25923',
    city: 'Uphusum'
  },
  {
    postalCode: '25924',
    city: 'Emmelsbüll-Horsbüll'
  },
  {
    postalCode: '25924',
    city: 'Friedrich-Wilhelm-Lübke-Koog'
  },
  {
    postalCode: '25924',
    city: 'Klanxbüll'
  },
  {
    postalCode: '25924',
    city: 'Rodenäs'
  },
  {
    postalCode: '25926',
    city: 'Bramstedtlund'
  },
  {
    postalCode: '25926',
    city: 'Karlum'
  },
  {
    postalCode: '25926',
    city: 'Ladelund'
  },
  {
    postalCode: '25926',
    city: 'Westre'
  },
  {
    postalCode: '25927',
    city: 'Aventoft'
  },
  {
    postalCode: '25927',
    city: 'Neukirchen'
  },
  {
    postalCode: '25938',
    city: 'Alkersum'
  },
  {
    postalCode: '25938',
    city: 'Borgsum'
  },
  {
    postalCode: '25938',
    city: 'Dunsum'
  },
  {
    postalCode: '25938',
    city: 'Midlum'
  },
  {
    postalCode: '25938',
    city: 'Nieblum'
  },
  {
    postalCode: '25938',
    city: 'Oevenum'
  },
  {
    postalCode: '25938',
    city: 'Oldsum'
  },
  {
    postalCode: '25938',
    city: 'Süderende'
  },
  {
    postalCode: '25938',
    city: 'Utersum'
  },
  {
    postalCode: '25938',
    city: 'Witsum'
  },
  {
    postalCode: '25938',
    city: 'Wrixum'
  },
  {
    postalCode: '25938',
    city: 'Wyk'
  },
  {
    postalCode: '25946',
    city: 'Nebel'
  },
  {
    postalCode: '25946',
    city: 'Norddorf'
  },
  {
    postalCode: '25946',
    city: 'Wittdün'
  },
  {
    postalCode: '25980',
    city: 'Rantum'
  },
  {
    postalCode: '25980',
    city: 'Sylt-Ost'
  },
  {
    postalCode: '25980',
    city: 'Westerland'
  },
  {
    postalCode: '25992',
    city: 'Jugendseeheim Kassel'
  },
  {
    postalCode: '25992',
    city: 'List'
  },
  {
    postalCode: '25992',
    city: 'Vogelkoje'
  },
  {
    postalCode: '25996',
    city: 'Wenningstedt'
  },
  {
    postalCode: '25997',
    city: 'Hörnum'
  },
  {
    postalCode: '25999',
    city: 'Kampen'
  },
  {
    postalCode: '26121',
    city: 'Oldenburg'
  },
  {
    postalCode: '26122',
    city: 'Oldenburg'
  },
  {
    postalCode: '26123',
    city: 'Oldenburg'
  },
  {
    postalCode: '26125',
    city: 'Oldenburg'
  },
  {
    postalCode: '26127',
    city: 'Oldenburg'
  },
  {
    postalCode: '26129',
    city: 'Oldenburg'
  },
  {
    postalCode: '26131',
    city: 'Oldenburg'
  },
  {
    postalCode: '26133',
    city: 'Oldenburg'
  },
  {
    postalCode: '26135',
    city: 'Oldenburg'
  },
  {
    postalCode: '26160',
    city: 'Bad Zwischenahn'
  },
  {
    postalCode: '26169',
    city: 'Friesoythe'
  },
  {
    postalCode: '26180',
    city: 'Rastede'
  },
  {
    postalCode: '26188',
    city: 'Edewecht'
  },
  {
    postalCode: '26197',
    city: 'Großenkneten'
  },
  {
    postalCode: '26203',
    city: 'Wardenburg'
  },
  {
    postalCode: '26209',
    city: 'Hatten'
  },
  {
    postalCode: '26215',
    city: 'Wiefelstede'
  },
  {
    postalCode: '26219',
    city: 'Bösel'
  },
  {
    postalCode: '26316',
    city: 'Varel'
  },
  {
    postalCode: '26340',
    city: 'Zetel'
  },
  {
    postalCode: '26345',
    city: 'Bockhorn'
  },
  {
    postalCode: '26349',
    city: 'Jade'
  },
  {
    postalCode: '26382',
    city: 'Wilhelmshaven'
  },
  {
    postalCode: '26384',
    city: 'Wilhelmshaven'
  },
  {
    postalCode: '26386',
    city: 'Wilhelmshaven'
  },
  {
    postalCode: '26388',
    city: 'Wilhelmshaven'
  },
  {
    postalCode: '26389',
    city: 'Wilhelmshaven'
  },
  {
    postalCode: '26409',
    city: 'Knyphauserwald'
  },
  {
    postalCode: '26409',
    city: 'Pfahlhaus'
  },
  {
    postalCode: '26409',
    city: 'Wittmund'
  },
  {
    postalCode: '26419',
    city: 'Schortens'
  },
  {
    postalCode: '26427',
    city: 'Dunum'
  },
  {
    postalCode: '26427',
    city: 'Esens'
  },
  {
    postalCode: '26427',
    city: 'Holtgast'
  },
  {
    postalCode: '26427',
    city: 'Moorweg'
  },
  {
    postalCode: '26427',
    city: 'Neuharlingersiel'
  },
  {
    postalCode: '26427',
    city: 'Stedesdorf'
  },
  {
    postalCode: '26427',
    city: 'Werdum'
  },
  {
    postalCode: '26427',
    city: 'Westerburer Polder'
  },
  {
    postalCode: '26434',
    city: 'Wangerland'
  },
  {
    postalCode: '26441',
    city: 'Groß Hauskreuz'
  },
  {
    postalCode: '26441',
    city: 'Jever'
  },
  {
    postalCode: '26441',
    city: 'Streitfeld'
  },
  {
    postalCode: '26446',
    city: 'Friedeburg'
  },
  {
    postalCode: '26452',
    city: 'Sande'
  },
  {
    postalCode: '26465',
    city: 'Langeoog'
  },
  {
    postalCode: '26474',
    city: 'Spiekeroog'
  },
  {
    postalCode: '26486',
    city: 'Wangerooge'
  },
  {
    postalCode: '26487',
    city: 'Blomberg'
  },
  {
    postalCode: '26487',
    city: 'Neuschoo'
  },
  {
    postalCode: '26489',
    city: 'Ochtersum'
  },
  {
    postalCode: '26506',
    city: 'Norden'
  },
  {
    postalCode: '26524',
    city: 'Berumbur'
  },
  {
    postalCode: '26524',
    city: 'Hage'
  },
  {
    postalCode: '26524',
    city: 'Hagermarsch'
  },
  {
    postalCode: '26524',
    city: 'Halbemond'
  },
  {
    postalCode: '26524',
    city: 'Lütetsburg'
  },
  {
    postalCode: '26529',
    city: 'Leezdorf'
  },
  {
    postalCode: '26529',
    city: 'Marienhafe'
  },
  {
    postalCode: '26529',
    city: 'Osteel'
  },
  {
    postalCode: '26529',
    city: 'Rechtsupweg'
  },
  {
    postalCode: '26529',
    city: 'Upgant-Schott'
  },
  {
    postalCode: '26529',
    city: 'Wirdum'
  },
  {
    postalCode: '26532',
    city: 'Großheide'
  },
  {
    postalCode: '26548',
    city: 'Norderney'
  },
  {
    postalCode: '26553',
    city: 'Dornum'
  },
  {
    postalCode: '26556',
    city: 'Eversmeer'
  },
  {
    postalCode: '26556',
    city: 'Nenndorf'
  },
  {
    postalCode: '26556',
    city: 'Schweindorf'
  },
  {
    postalCode: '26556',
    city: 'Utarp'
  },
  {
    postalCode: '26556',
    city: 'Westerholt'
  },
  {
    postalCode: '26571',
    city: 'Juist'
  },
  {
    postalCode: '26571',
    city: 'Nordseeinsel Memmert'
  },
  {
    postalCode: '26579',
    city: 'Baltrum'
  },
  {
    postalCode: '26603',
    city: 'Aurich'
  },
  {
    postalCode: '26605',
    city: 'Aurich'
  },
  {
    postalCode: '26607',
    city: 'Aurich'
  },
  {
    postalCode: '26624',
    city: 'Südbrookmerland'
  },
  {
    postalCode: '26629',
    city: 'Großefehn'
  },
  {
    postalCode: '26632',
    city: 'Ihlow'
  },
  {
    postalCode: '26639',
    city: 'Wiesmoor'
  },
  {
    postalCode: '26655',
    city: 'Westerstede'
  },
  {
    postalCode: '26670',
    city: 'Uplengen'
  },
  {
    postalCode: '26676',
    city: 'Barßel'
  },
  {
    postalCode: '26683',
    city: 'Saterland'
  },
  {
    postalCode: '26689',
    city: 'Apen'
  },
  {
    postalCode: '26721',
    city: 'Emden'
  },
  {
    postalCode: '26723',
    city: 'Emden'
  },
  {
    postalCode: '26725',
    city: 'Emden'
  },
  {
    postalCode: '26736',
    city: 'Krummhörn'
  },
  {
    postalCode: '26757',
    city: 'Borkum'
  },
  {
    postalCode: '26759',
    city: 'Hinte'
  },
  {
    postalCode: '26759',
    city: 'Klein Heikeland'
  },
  {
    postalCode: '26789',
    city: 'Leer'
  },
  {
    postalCode: '26802',
    city: 'Grovehörn'
  },
  {
    postalCode: '26802',
    city: 'Moormerland'
  },
  {
    postalCode: '26810',
    city: 'Westoverledingen'
  },
  {
    postalCode: '26817',
    city: 'Rhauderfehn'
  },
  {
    postalCode: '26826',
    city: 'Weener'
  },
  {
    postalCode: '26831',
    city: 'Boen'
  },
  {
    postalCode: '26831',
    city: 'Bunde'
  },
  {
    postalCode: '26831',
    city: 'Bunderhee'
  },
  {
    postalCode: '26831',
    city: 'Dollart'
  },
  {
    postalCode: '26831',
    city: 'Wymeer'
  },
  {
    postalCode: '26835',
    city: 'Brinkum'
  },
  {
    postalCode: '26835',
    city: 'Firrel'
  },
  {
    postalCode: '26835',
    city: 'Hesel'
  },
  {
    postalCode: '26835',
    city: 'Holtland'
  },
  {
    postalCode: '26835',
    city: 'Neukamperfehn'
  },
  {
    postalCode: '26835',
    city: 'Schwerinsdorf'
  },
  {
    postalCode: '26842',
    city: 'Ostrhauderfehn'
  },
  {
    postalCode: '26844',
    city: 'Jemgum'
  },
  {
    postalCode: '26845',
    city: 'Nortmoor'
  },
  {
    postalCode: '26847',
    city: 'Detern'
  },
  {
    postalCode: '26849',
    city: 'Filsum'
  },
  {
    postalCode: '26871',
    city: 'Aschendorf'
  },
  {
    postalCode: '26871',
    city: 'Papenburg'
  },
  {
    postalCode: '26892',
    city: 'Dörpen'
  },
  {
    postalCode: '26892',
    city: 'Heede'
  },
  {
    postalCode: '26892',
    city: 'Kluse'
  },
  {
    postalCode: '26892',
    city: 'Lehe'
  },
  {
    postalCode: '26892',
    city: 'Wippingen'
  },
  {
    postalCode: '26897',
    city: 'Bockhorst'
  },
  {
    postalCode: '26897',
    city: 'Breddenberg'
  },
  {
    postalCode: '26897',
    city: 'Esterwegen'
  },
  {
    postalCode: '26897',
    city: 'Hilkenbrook'
  },
  {
    postalCode: '26899',
    city: 'Rhede'
  },
  {
    postalCode: '26901',
    city: 'Lorup'
  },
  {
    postalCode: '26901',
    city: 'Rastdorf'
  },
  {
    postalCode: '26903',
    city: 'Surwold'
  },
  {
    postalCode: '26904',
    city: 'Börger'
  },
  {
    postalCode: '26906',
    city: 'Dersum'
  },
  {
    postalCode: '26907',
    city: 'Walchum'
  },
  {
    postalCode: '26909',
    city: 'Neubörger'
  },
  {
    postalCode: '26909',
    city: 'Neulehe'
  },
  {
    postalCode: '26919',
    city: 'Brake'
  },
  {
    postalCode: '26931',
    city: 'Elsfleth'
  },
  {
    postalCode: '26935',
    city: 'Stadland'
  },
  {
    postalCode: '26936',
    city: 'Stadland'
  },
  {
    postalCode: '26937',
    city: 'Stadland'
  },
  {
    postalCode: '26939',
    city: 'Ovelgönne'
  },
  {
    postalCode: '26954',
    city: 'Nordenham'
  },
  {
    postalCode: '26969',
    city: 'Butjadingen'
  },
  {
    postalCode: '27211',
    city: 'Bassum'
  },
  {
    postalCode: '27232',
    city: 'Sulingen'
  },
  {
    postalCode: '27239',
    city: 'Twistringen'
  },
  {
    postalCode: '27243',
    city: 'Beckeln'
  },
  {
    postalCode: '27243',
    city: 'Colnrade'
  },
  {
    postalCode: '27243',
    city: 'Dünsen'
  },
  {
    postalCode: '27243',
    city: 'Groß Ippener'
  },
  {
    postalCode: '27243',
    city: 'Harpstedt'
  },
  {
    postalCode: '27243',
    city: 'Kirchseelte'
  },
  {
    postalCode: '27243',
    city: 'Prinzhöfte'
  },
  {
    postalCode: '27243',
    city: 'Winkelsett'
  },
  {
    postalCode: '27245',
    city: 'Bahrenborstel'
  },
  {
    postalCode: '27245',
    city: 'Barenburg'
  },
  {
    postalCode: '27245',
    city: 'Kirchdorf'
  },
  {
    postalCode: '27246',
    city: 'Borstel'
  },
  {
    postalCode: '27248',
    city: 'Ehrenburg'
  },
  {
    postalCode: '27249',
    city: 'Maasen'
  },
  {
    postalCode: '27249',
    city: 'Mellinghausen'
  },
  {
    postalCode: '27251',
    city: 'Neuenkirchen'
  },
  {
    postalCode: '27251',
    city: 'Scholen'
  },
  {
    postalCode: '27252',
    city: 'Schwaförden'
  },
  {
    postalCode: '27254',
    city: 'Siedenburg'
  },
  {
    postalCode: '27254',
    city: 'Staffhorst'
  },
  {
    postalCode: '27257',
    city: 'Affinghausen'
  },
  {
    postalCode: '27257',
    city: 'Sudwalde'
  },
  {
    postalCode: '27259',
    city: 'Freistatt'
  },
  {
    postalCode: '27259',
    city: 'Varrel'
  },
  {
    postalCode: '27259',
    city: 'Wehrbleck'
  },
  {
    postalCode: '27283',
    city: 'Verden'
  },
  {
    postalCode: '27299',
    city: 'Langwedel'
  },
  {
    postalCode: '27305',
    city: 'Bruchhausen-Vilsen'
  },
  {
    postalCode: '27305',
    city: 'Engeln'
  },
  {
    postalCode: '27305',
    city: 'Süstedt'
  },
  {
    postalCode: '27308',
    city: 'Kirchlinteln'
  },
  {
    postalCode: '27313',
    city: 'Dörverden'
  },
  {
    postalCode: '27318',
    city: 'Hilgermissen'
  },
  {
    postalCode: '27318',
    city: 'Hoya'
  },
  {
    postalCode: '27318',
    city: 'Hoyerhagen'
  },
  {
    postalCode: '27321',
    city: 'Emtinghausen'
  },
  {
    postalCode: '27321',
    city: 'Morsum'
  },
  {
    postalCode: '27321',
    city: 'Thedinghausen'
  },
  {
    postalCode: '27324',
    city: 'Eystrup'
  },
  {
    postalCode: '27324',
    city: 'Gandesbergen'
  },
  {
    postalCode: '27324',
    city: 'Hämelhausen'
  },
  {
    postalCode: '27324',
    city: 'Hassel'
  },
  {
    postalCode: '27324',
    city: 'Schweringen, Ziegelei'
  },
  {
    postalCode: '27327',
    city: 'Martfeld'
  },
  {
    postalCode: '27327',
    city: 'Schwarme'
  },
  {
    postalCode: '27330',
    city: 'Asendorf'
  },
  {
    postalCode: '27333',
    city: 'Bücken'
  },
  {
    postalCode: '27333',
    city: 'Schweringen'
  },
  {
    postalCode: '27333',
    city: 'Warpe'
  },
  {
    postalCode: '27336',
    city: 'Frankenfeld'
  },
  {
    postalCode: '27336',
    city: 'Häuslingen'
  },
  {
    postalCode: '27336',
    city: 'Rethem'
  },
  {
    postalCode: '27336',
    city: 'Rethemer Fähre'
  },
  {
    postalCode: '27337',
    city: 'Blender'
  },
  {
    postalCode: '27339',
    city: 'Riede'
  },
  {
    postalCode: '27356',
    city: 'Rotenburg'
  },
  {
    postalCode: '27367',
    city: 'Ahausen'
  },
  {
    postalCode: '27367',
    city: 'Bötersen'
  },
  {
    postalCode: '27367',
    city: 'Hassendorf'
  },
  {
    postalCode: '27367',
    city: 'Hellwege'
  },
  {
    postalCode: '27367',
    city: 'Horstedt'
  },
  {
    postalCode: '27367',
    city: 'Reeßum'
  },
  {
    postalCode: '27367',
    city: 'Sottrum'
  },
  {
    postalCode: '27374',
    city: 'Visselhövede'
  },
  {
    postalCode: '27383',
    city: 'Scheeßel'
  },
  {
    postalCode: '27386',
    city: 'Bothel'
  },
  {
    postalCode: '27386',
    city: 'Brockel'
  },
  {
    postalCode: '27386',
    city: 'Hemsbünde'
  },
  {
    postalCode: '27386',
    city: 'Hemslingen'
  },
  {
    postalCode: '27386',
    city: 'Kirchwalsede'
  },
  {
    postalCode: '27386',
    city: 'Westerwalsede'
  },
  {
    postalCode: '27389',
    city: 'Fintel'
  },
  {
    postalCode: '27389',
    city: 'Helvesiek'
  },
  {
    postalCode: '27389',
    city: 'Lauenbrück'
  },
  {
    postalCode: '27389',
    city: 'Stemmen'
  },
  {
    postalCode: '27389',
    city: 'Vahlde'
  },
  {
    postalCode: '27404',
    city: 'Elsdorf'
  },
  {
    postalCode: '27404',
    city: 'Gyhum'
  },
  {
    postalCode: '27404',
    city: 'Heeslingen'
  },
  {
    postalCode: '27404',
    city: 'Ostereistedt'
  },
  {
    postalCode: '27404',
    city: 'Rhade'
  },
  {
    postalCode: '27404',
    city: 'Seedorf'
  },
  {
    postalCode: '27404',
    city: 'Zeven'
  },
  {
    postalCode: '27412',
    city: 'Breddorf'
  },
  {
    postalCode: '27412',
    city: 'Bülstedt'
  },
  {
    postalCode: '27412',
    city: 'Hepstedt'
  },
  {
    postalCode: '27412',
    city: 'Kirchtimke'
  },
  {
    postalCode: '27412',
    city: 'Tarmstedt'
  },
  {
    postalCode: '27412',
    city: 'Vorwerk'
  },
  {
    postalCode: '27412',
    city: 'Westertimke'
  },
  {
    postalCode: '27412',
    city: 'Wilstedt'
  },
  {
    postalCode: '27419',
    city: 'Groß Meckelsen'
  },
  {
    postalCode: '27419',
    city: 'Hamersen'
  },
  {
    postalCode: '27419',
    city: 'Kalbe'
  },
  {
    postalCode: '27419',
    city: 'Klein Meckelsen'
  },
  {
    postalCode: '27419',
    city: 'Lengenbostel'
  },
  {
    postalCode: '27419',
    city: 'Sittensen'
  },
  {
    postalCode: '27419',
    city: 'Tiste'
  },
  {
    postalCode: '27419',
    city: 'Vierden'
  },
  {
    postalCode: '27419',
    city: 'Wohnste'
  },
  {
    postalCode: '27432',
    city: 'Alfstedt'
  },
  {
    postalCode: '27432',
    city: 'Basdahl'
  },
  {
    postalCode: '27432',
    city: 'Bremervörde'
  },
  {
    postalCode: '27432',
    city: 'Ebersdorf'
  },
  {
    postalCode: '27432',
    city: 'Hipstedt'
  },
  {
    postalCode: '27432',
    city: 'Malse'
  },
  {
    postalCode: '27432',
    city: 'Oerel'
  },
  {
    postalCode: '27442',
    city: 'Gnarrenburg'
  },
  {
    postalCode: '27446',
    city: 'Anderlingen'
  },
  {
    postalCode: '27446',
    city: 'Deinstedt'
  },
  {
    postalCode: '27446',
    city: 'Farven'
  },
  {
    postalCode: '27446',
    city: 'Sandbostel'
  },
  {
    postalCode: '27446',
    city: 'Selsingen'
  },
  {
    postalCode: '27449',
    city: 'Kutenholz'
  },
  {
    postalCode: '27472',
    city: 'Cuxhaven'
  },
  {
    postalCode: '27474',
    city: 'Cuxhaven'
  },
  {
    postalCode: '27476',
    city: 'Cuxhaven'
  },
  {
    postalCode: '27478',
    city: 'Cuxhaven'
  },
  {
    postalCode: '27498',
    city: 'Helgoland'
  },
  {
    postalCode: '27499',
    city: 'Hamburg-Insel Neuwerk'
  },
  {
    postalCode: '27568',
    city: 'Bremerhaven'
  },
  {
    postalCode: '27570',
    city: 'Bremerhaven'
  },
  {
    postalCode: '27572',
    city: 'Bremerhaven'
  },
  {
    postalCode: '27574',
    city: 'Bremerhaven'
  },
  {
    postalCode: '27576',
    city: 'Bremerhaven'
  },
  {
    postalCode: '27578',
    city: 'Bremerhaven'
  },
  {
    postalCode: '27580',
    city: 'Bremerhaven'
  },
  {
    postalCode: '27607',
    city: 'Langen'
  },
  {
    postalCode: '27612',
    city: 'Loxstedt'
  },
  {
    postalCode: '27616',
    city: 'Appeln'
  },
  {
    postalCode: '27616',
    city: 'Beverstedt'
  },
  {
    postalCode: '27616',
    city: 'Bokel'
  },
  {
    postalCode: '27616',
    city: 'Frelsdorf'
  },
  {
    postalCode: '27616',
    city: 'Heerstedt'
  },
  {
    postalCode: '27616',
    city: 'Hollen'
  },
  {
    postalCode: '27616',
    city: 'Kirchwistedt'
  },
  {
    postalCode: '27616',
    city: 'Lunestedt'
  },
  {
    postalCode: '27616',
    city: 'Stubben'
  },
  {
    postalCode: '27619',
    city: 'Schiffdorf'
  },
  {
    postalCode: '27624',
    city: 'Bad Bederkesa'
  },
  {
    postalCode: '27624',
    city: 'Drangstedt'
  },
  {
    postalCode: '27624',
    city: 'Elmlohe'
  },
  {
    postalCode: '27624',
    city: 'Flögeln'
  },
  {
    postalCode: '27624',
    city: 'Köhlen'
  },
  {
    postalCode: '27624',
    city: 'Kührstedt'
  },
  {
    postalCode: '27624',
    city: 'Lintig'
  },
  {
    postalCode: '27624',
    city: 'Ringstedt'
  },
  {
    postalCode: '27628',
    city: 'Bramstedt'
  },
  {
    postalCode: '27628',
    city: 'Driftsethe'
  },
  {
    postalCode: '27628',
    city: 'Hagen'
  },
  {
    postalCode: '27628',
    city: 'Sandstedt'
  },
  {
    postalCode: '27628',
    city: 'Uthlede'
  },
  {
    postalCode: '27628',
    city: 'Wulsbüttel'
  },
  {
    postalCode: '27632',
    city: 'Cappel'
  },
  {
    postalCode: '27632',
    city: 'Dorum'
  },
  {
    postalCode: '27632',
    city: 'Midlum'
  },
  {
    postalCode: '27632',
    city: 'Misselwarden'
  },
  {
    postalCode: '27632',
    city: 'Mulsum'
  },
  {
    postalCode: '27632',
    city: 'Padingbüttel'
  },
  {
    postalCode: '27637',
    city: 'Nordholz'
  },
  {
    postalCode: '27638',
    city: 'Wremen'
  },
  {
    postalCode: '27711',
    city: 'Osterholz-Scharmbeck'
  },
  {
    postalCode: '27721',
    city: 'Ritterhude'
  },
  {
    postalCode: '27726',
    city: 'Breddorfermoor'
  },
  {
    postalCode: '27726',
    city: 'Worpswede'
  },
  {
    postalCode: '27729',
    city: 'Axstedt'
  },
  {
    postalCode: '27729',
    city: 'Hambergen'
  },
  {
    postalCode: '27729',
    city: 'Holste'
  },
  {
    postalCode: '27729',
    city: 'Lübberstedt'
  },
  {
    postalCode: '27729',
    city: 'Vollersode'
  },
  {
    postalCode: '27749',
    city: 'Delmenhorst'
  },
  {
    postalCode: '27751',
    city: 'Delmenhorst'
  },
  {
    postalCode: '27753',
    city: 'Delmenhorst'
  },
  {
    postalCode: '27755',
    city: 'Delmenhorst'
  },
  {
    postalCode: '27777',
    city: 'Ganderkesee'
  },
  {
    postalCode: '27793',
    city: 'Wildeshausen'
  },
  {
    postalCode: '27798',
    city: 'Hude'
  },
  {
    postalCode: '27801',
    city: 'Dötlingen'
  },
  {
    postalCode: '27804',
    city: 'Berne'
  },
  {
    postalCode: '27809',
    city: 'Lemwerder'
  },
  {
    postalCode: '28195',
    city: 'Bremen'
  },
  {
    postalCode: '28197',
    city: 'Bremen'
  },
  {
    postalCode: '28199',
    city: 'Bremen'
  },
  {
    postalCode: '28201',
    city: 'Bremen'
  },
  {
    postalCode: '28203',
    city: 'Bremen'
  },
  {
    postalCode: '28205',
    city: 'Bremen'
  },
  {
    postalCode: '28207',
    city: 'Bremen'
  },
  {
    postalCode: '28209',
    city: 'Bremen'
  },
  {
    postalCode: '28211',
    city: 'Bremen'
  },
  {
    postalCode: '28213',
    city: 'Bremen'
  },
  {
    postalCode: '28215',
    city: 'Bremen'
  },
  {
    postalCode: '28217',
    city: 'Bremen'
  },
  {
    postalCode: '28219',
    city: 'Bremen'
  },
  {
    postalCode: '28237',
    city: 'Bremen'
  },
  {
    postalCode: '28239',
    city: 'Bremen'
  },
  {
    postalCode: '28259',
    city: 'Bremen'
  },
  {
    postalCode: '28277',
    city: 'Bremen'
  },
  {
    postalCode: '28279',
    city: 'Bremen'
  },
  {
    postalCode: '28307',
    city: 'Bremen'
  },
  {
    postalCode: '28309',
    city: 'Bremen'
  },
  {
    postalCode: '28325',
    city: 'Bremen'
  },
  {
    postalCode: '28327',
    city: 'Bremen'
  },
  {
    postalCode: '28329',
    city: 'Bremen'
  },
  {
    postalCode: '28355',
    city: 'Bremen'
  },
  {
    postalCode: '28357',
    city: 'Bremen'
  },
  {
    postalCode: '28359',
    city: 'Bremen'
  },
  {
    postalCode: '28717',
    city: 'Bremen'
  },
  {
    postalCode: '28719',
    city: 'Bremen'
  },
  {
    postalCode: '28755',
    city: 'Bremen'
  },
  {
    postalCode: '28757',
    city: 'Bremen'
  },
  {
    postalCode: '28759',
    city: 'Bremen'
  },
  {
    postalCode: '28777',
    city: 'Bremen'
  },
  {
    postalCode: '28779',
    city: 'Bremen'
  },
  {
    postalCode: '28790',
    city: 'Schwanewede'
  },
  {
    postalCode: '28816',
    city: 'Stuhr'
  },
  {
    postalCode: '28832',
    city: 'Achim'
  },
  {
    postalCode: '28844',
    city: 'Weyhe'
  },
  {
    postalCode: '28857',
    city: 'Syke'
  },
  {
    postalCode: '28865',
    city: 'Lilienthal'
  },
  {
    postalCode: '28870',
    city: 'Ottersberg'
  },
  {
    postalCode: '28876',
    city: 'Oyten'
  },
  {
    postalCode: '28879',
    city: 'Grasberg'
  },
  {
    postalCode: '29221',
    city: 'Celle'
  },
  {
    postalCode: '29223',
    city: 'Celle'
  },
  {
    postalCode: '29225',
    city: 'Celle'
  },
  {
    postalCode: '29227',
    city: 'Celle'
  },
  {
    postalCode: '29229',
    city: 'Celle'
  },
  {
    postalCode: '29229',
    city: 'Wittbeck'
  },
  {
    postalCode: '29303',
    city: 'Bergen'
  },
  {
    postalCode: '29303',
    city: 'Lohheide, gemfr. Bezirk'
  },
  {
    postalCode: '29303',
    city: 'Miele'
  },
  {
    postalCode: '29303',
    city: 'Rehwinkel'
  },
  {
    postalCode: '29308',
    city: 'Winsen'
  },
  {
    postalCode: '29313',
    city: 'Hambühren'
  },
  {
    postalCode: '29320',
    city: 'Hermannsburg'
  },
  {
    postalCode: '29323',
    city: 'Wietze'
  },
  {
    postalCode: '29328',
    city: 'Faßberg'
  },
  {
    postalCode: '29331',
    city: 'Lachendorf'
  },
  {
    postalCode: '29336',
    city: 'Nienhagen'
  },
  {
    postalCode: '29339',
    city: 'Wathlingen'
  },
  {
    postalCode: '29342',
    city: 'Wienhausen'
  },
  {
    postalCode: '29345',
    city: 'Unterlüß'
  },
  {
    postalCode: '29348',
    city: 'Eschede'
  },
  {
    postalCode: '29348',
    city: 'Scharnhorst'
  },
  {
    postalCode: '29351',
    city: 'Eldingen'
  },
  {
    postalCode: '29352',
    city: 'Adelheidsdorf'
  },
  {
    postalCode: '29353',
    city: 'Ahnsbeck'
  },
  {
    postalCode: '29355',
    city: 'Beedenbostel'
  },
  {
    postalCode: '29356',
    city: 'Bröckel'
  },
  {
    postalCode: '29358',
    city: 'Eicklingen'
  },
  {
    postalCode: '29359',
    city: 'Habighorst'
  },
  {
    postalCode: '29361',
    city: 'Höfer'
  },
  {
    postalCode: '29362',
    city: 'Hohne'
  },
  {
    postalCode: '29364',
    city: 'Langlingen'
  },
  {
    postalCode: '29365',
    city: 'Sprakensehl'
  },
  {
    postalCode: '29367',
    city: 'Steinhorst'
  },
  {
    postalCode: '29369',
    city: 'Ummern'
  },
  {
    postalCode: '29378',
    city: 'Wittingen'
  },
  {
    postalCode: '29379',
    city: 'Wittingen'
  },
  {
    postalCode: '29386',
    city: 'Dedelstorf'
  },
  {
    postalCode: '29386',
    city: 'Hankensbüttel'
  },
  {
    postalCode: '29386',
    city: 'Obernholz'
  },
  {
    postalCode: '29389',
    city: 'Bad Bodenteich'
  },
  {
    postalCode: '29392',
    city: 'Wesendorf'
  },
  {
    postalCode: '29393',
    city: 'Groß Oesingen'
  },
  {
    postalCode: '29394',
    city: 'Lüder'
  },
  {
    postalCode: '29396',
    city: 'Schönewörde'
  },
  {
    postalCode: '29399',
    city: 'Wahrenholz'
  },
  {
    postalCode: '29410',
    city: 'Klein Chüden'
  },
  {
    postalCode: '29410',
    city: 'Ritze'
  },
  {
    postalCode: '29410',
    city: 'Salzwedel'
  },
  {
    postalCode: '29413',
    city: 'Bonese'
  },
  {
    postalCode: '29413',
    city: 'Bornsen'
  },
  {
    postalCode: '29413',
    city: 'Brietz'
  },
  {
    postalCode: '29413',
    city: 'Dähre'
  },
  {
    postalCode: '29413',
    city: 'Diesdorf'
  },
  {
    postalCode: '29413',
    city: 'Ellenberg'
  },
  {
    postalCode: '29413',
    city: 'Gieseritz'
  },
  {
    postalCode: '29413',
    city: 'Groß Wieblitz'
  },
  {
    postalCode: '29413',
    city: 'Henningen'
  },
  {
    postalCode: '29413',
    city: 'Klein Wieblitz'
  },
  {
    postalCode: '29413',
    city: 'Lagendorf'
  },
  {
    postalCode: '29413',
    city: 'Langenapel'
  },
  {
    postalCode: '29413',
    city: 'Mehmke'
  },
  {
    postalCode: '29413',
    city: 'Neuekrug'
  },
  {
    postalCode: '29413',
    city: 'Osterwohle'
  },
  {
    postalCode: '29413',
    city: 'Seebenau'
  },
  {
    postalCode: '29413',
    city: 'Tylsen'
  },
  {
    postalCode: '29413',
    city: 'Wallstawe'
  },
  {
    postalCode: '29416',
    city: 'Abbau Ader'
  },
  {
    postalCode: '29416',
    city: 'Altensalzwedel'
  },
  {
    postalCode: '29416',
    city: 'Benkendorf'
  },
  {
    postalCode: '29416',
    city: 'Bierstedt'
  },
  {
    postalCode: '29416',
    city: 'Binde'
  },
  {
    postalCode: '29416',
    city: 'Dambeck'
  },
  {
    postalCode: '29416',
    city: 'Eversdorf'
  },
  {
    postalCode: '29416',
    city: 'Fleetmark'
  },
  {
    postalCode: '29416',
    city: 'Gischau'
  },
  {
    postalCode: '29416',
    city: 'Groß Chüden'
  },
  {
    postalCode: '29416',
    city: 'Jeggeleben'
  },
  {
    postalCode: '29416',
    city: 'Kaulitz'
  },
  {
    postalCode: '29416',
    city: 'Kerkau'
  },
  {
    postalCode: '29416',
    city: 'Klein Gartz'
  },
  {
    postalCode: '29416',
    city: 'Kuhfelde'
  },
  {
    postalCode: '29416',
    city: 'Liesten'
  },
  {
    postalCode: '29416',
    city: 'Mahlsdorf'
  },
  {
    postalCode: '29416',
    city: 'Mechau'
  },
  {
    postalCode: '29416',
    city: 'Pretzier'
  },
  {
    postalCode: '29416',
    city: 'Püggen'
  },
  {
    postalCode: '29416',
    city: 'Rademin'
  },
  {
    postalCode: '29416',
    city: 'Riebau'
  },
  {
    postalCode: '29416',
    city: 'Siedenlangenbeck'
  },
  {
    postalCode: '29416',
    city: 'Stappenbeck'
  },
  {
    postalCode: '29416',
    city: 'Steinitz'
  },
  {
    postalCode: '29416',
    city: 'Valfitz'
  },
  {
    postalCode: '29416',
    city: 'Vissum'
  },
  {
    postalCode: '29416',
    city: 'Winterfeld'
  },
  {
    postalCode: '29439',
    city: 'Lüchow'
  },
  {
    postalCode: '29451',
    city: 'Dannenberg'
  },
  {
    postalCode: '29456',
    city: 'Hitzacker'
  },
  {
    postalCode: '29459',
    city: 'Clenze'
  },
  {
    postalCode: '29462',
    city: 'Wustrow'
  },
  {
    postalCode: '29465',
    city: 'Schnega'
  },
  {
    postalCode: '29468',
    city: 'Bergen'
  },
  {
    postalCode: '29471',
    city: 'Gartow'
  },
  {
    postalCode: '29472',
    city: 'Damnatz'
  },
  {
    postalCode: '29473',
    city: 'Göhrde'
  },
  {
    postalCode: '29475',
    city: 'Gorleben'
  },
  {
    postalCode: '29476',
    city: 'Gusborn'
  },
  {
    postalCode: '29478',
    city: 'Höhbeck'
  },
  {
    postalCode: '29479',
    city: 'Jameln'
  },
  {
    postalCode: '29481',
    city: 'Karwitz'
  },
  {
    postalCode: '29482',
    city: 'Küsten'
  },
  {
    postalCode: '29484',
    city: 'Langendorf'
  },
  {
    postalCode: '29485',
    city: 'Lemgow'
  },
  {
    postalCode: '29487',
    city: 'Luckau'
  },
  {
    postalCode: '29488',
    city: 'Lübbow'
  },
  {
    postalCode: '29490',
    city: 'Neu Darchau'
  },
  {
    postalCode: '29491',
    city: 'Prezelle'
  },
  {
    postalCode: '29493',
    city: 'Schnackenburg'
  },
  {
    postalCode: '29494',
    city: 'Trebel'
  },
  {
    postalCode: '29496',
    city: 'Waddeweitz'
  },
  {
    postalCode: '29497',
    city: 'Woltersdorf'
  },
  {
    postalCode: '29499',
    city: 'Zernien'
  },
  {
    postalCode: '29525',
    city: 'Uelzen'
  },
  {
    postalCode: '29549',
    city: 'Bad Bevensen'
  },
  {
    postalCode: '29553',
    city: 'Bienenbüttel'
  },
  {
    postalCode: '29556',
    city: 'Suderburg'
  },
  {
    postalCode: '29559',
    city: 'Wrestedt'
  },
  {
    postalCode: '29562',
    city: 'Suhlendorf'
  },
  {
    postalCode: '29565',
    city: 'Wriedel'
  },
  {
    postalCode: '29568',
    city: 'Wieren'
  },
  {
    postalCode: '29571',
    city: 'Rosche'
  },
  {
    postalCode: '29574',
    city: 'Ebstorf'
  },
  {
    postalCode: '29575',
    city: 'Altenmedingen'
  },
  {
    postalCode: '29576',
    city: 'Barum'
  },
  {
    postalCode: '29578',
    city: 'Eimke'
  },
  {
    postalCode: '29579',
    city: 'Emmendorf'
  },
  {
    postalCode: '29581',
    city: 'Gerdau'
  },
  {
    postalCode: '29582',
    city: 'Hanstedt'
  },
  {
    postalCode: '29584',
    city: 'Himbergen'
  },
  {
    postalCode: '29585',
    city: 'Jelmstorf'
  },
  {
    postalCode: '29587',
    city: 'Natendorf'
  },
  {
    postalCode: '29588',
    city: 'Oetzen'
  },
  {
    postalCode: '29590',
    city: 'Rätzlingen'
  },
  {
    postalCode: '29591',
    city: 'Römstedt'
  },
  {
    postalCode: '29593',
    city: 'Schwienau'
  },
  {
    postalCode: '29594',
    city: 'Soltendieck'
  },
  {
    postalCode: '29596',
    city: 'Stadensen'
  },
  {
    postalCode: '29597',
    city: 'Stoetze'
  },
  {
    postalCode: '29599',
    city: 'Weste'
  },
  {
    postalCode: '29614',
    city: 'Soltau'
  },
  {
    postalCode: '29633',
    city: 'Munster'
  },
  {
    postalCode: '29640',
    city: 'Heimbuch'
  },
  {
    postalCode: '29640',
    city: 'Schneverdingen'
  },
  {
    postalCode: '29643',
    city: 'Neuenkirchen'
  },
  {
    postalCode: '29646',
    city: 'Bispingen'
  },
  {
    postalCode: '29649',
    city: 'Wietzendorf'
  },
  {
    postalCode: '29664',
    city: 'Ostenholz'
  },
  {
    postalCode: '29664',
    city: 'Walsrode'
  },
  {
    postalCode: '29683',
    city: 'Fallingbostel'
  },
  {
    postalCode: '29683',
    city: 'Oerbke'
  },
  {
    postalCode: '29683',
    city: 'Wense'
  },
  {
    postalCode: '29690',
    city: 'Buchholz'
  },
  {
    postalCode: '29690',
    city: 'Essel'
  },
  {
    postalCode: '29690',
    city: 'Gilten'
  },
  {
    postalCode: '29690',
    city: 'Grethem'
  },
  {
    postalCode: '29690',
    city: 'Lindwedel'
  },
  {
    postalCode: '29690',
    city: 'Schwarmstedt'
  },
  {
    postalCode: '29693',
    city: 'Ahlden'
  },
  {
    postalCode: '29693',
    city: 'Böhme'
  },
  {
    postalCode: '29693',
    city: 'Eickeloh'
  },
  {
    postalCode: '29693',
    city: 'Hademstorf'
  },
  {
    postalCode: '29693',
    city: 'Hodenhagen'
  },
  {
    postalCode: '29693',
    city: 'Krelinger Bruch'
  },
  {
    postalCode: '29699',
    city: 'Bomlitz'
  },
  {
    postalCode: '30159',
    city: 'Hannover'
  },
  {
    postalCode: '30161',
    city: 'Hannover'
  },
  {
    postalCode: '30163',
    city: 'Hannover'
  },
  {
    postalCode: '30165',
    city: 'Hannover'
  },
  {
    postalCode: '30167',
    city: 'Hannover'
  },
  {
    postalCode: '30169',
    city: 'Hannover'
  },
  {
    postalCode: '30171',
    city: 'Hannover'
  },
  {
    postalCode: '30173',
    city: 'Hannover'
  },
  {
    postalCode: '30175',
    city: 'Hannover'
  },
  {
    postalCode: '30177',
    city: 'Hannover'
  },
  {
    postalCode: '30179',
    city: 'Hannover'
  },
  {
    postalCode: '30419',
    city: 'Hannover'
  },
  {
    postalCode: '30449',
    city: 'Hannover'
  },
  {
    postalCode: '30451',
    city: 'Hannover'
  },
  {
    postalCode: '30453',
    city: 'Hannover'
  },
  {
    postalCode: '30455',
    city: 'Hannover'
  },
  {
    postalCode: '30457',
    city: 'Hannover'
  },
  {
    postalCode: '30459',
    city: 'Hannover'
  },
  {
    postalCode: '30519',
    city: 'Hannover'
  },
  {
    postalCode: '30521',
    city: 'Hannover'
  },
  {
    postalCode: '30539',
    city: 'Hannover'
  },
  {
    postalCode: '30559',
    city: 'Hannover'
  },
  {
    postalCode: '30625',
    city: 'Hannover'
  },
  {
    postalCode: '30627',
    city: 'Hannover'
  },
  {
    postalCode: '30629',
    city: 'Hannover'
  },
  {
    postalCode: '30655',
    city: 'Hannover'
  },
  {
    postalCode: '30657',
    city: 'Hannover'
  },
  {
    postalCode: '30659',
    city: 'Hannover'
  },
  {
    postalCode: '30669',
    city: 'Hannover'
  },
  {
    postalCode: '30823',
    city: 'Garbsen'
  },
  {
    postalCode: '30826',
    city: 'Garbsen'
  },
  {
    postalCode: '30827',
    city: 'Garbsen'
  },
  {
    postalCode: '30851',
    city: 'Langenhagen'
  },
  {
    postalCode: '30853',
    city: 'Langenhagen'
  },
  {
    postalCode: '30855',
    city: 'Langenhagen'
  },
  {
    postalCode: '30880',
    city: 'Laatzen'
  },
  {
    postalCode: '30890',
    city: 'Barsinghausen'
  },
  {
    postalCode: '30900',
    city: 'Wedemark'
  },
  {
    postalCode: '30916',
    city: 'Isernhagen'
  },
  {
    postalCode: '30926',
    city: 'Seelze'
  },
  {
    postalCode: '30938',
    city: 'Burgwedel'
  },
  {
    postalCode: '30952',
    city: 'Ronnenberg'
  },
  {
    postalCode: '30966',
    city: 'Hemmingen'
  },
  {
    postalCode: '30974',
    city: 'Wennigsen'
  },
  {
    postalCode: '30982',
    city: 'Pattensen'
  },
  {
    postalCode: '30989',
    city: 'Gehrden'
  },
  {
    postalCode: '31008',
    city: 'Elze'
  },
  {
    postalCode: '31020',
    city: 'Salzhemmendorf'
  },
  {
    postalCode: '31028',
    city: 'Gronau'
  },
  {
    postalCode: '31029',
    city: 'Banteln'
  },
  {
    postalCode: '31032',
    city: 'Betheln'
  },
  {
    postalCode: '31033',
    city: 'Brüggen'
  },
  {
    postalCode: '31035',
    city: 'Despetal'
  },
  {
    postalCode: '31036',
    city: 'Eime'
  },
  {
    postalCode: '31039',
    city: 'Rheden'
  },
  {
    postalCode: '31061',
    city: 'Alfeld'
  },
  {
    postalCode: '31073',
    city: 'Delligsen'
  },
  {
    postalCode: '31073',
    city: 'Grünenplan'
  },
  {
    postalCode: '31079',
    city: 'Adenstedt'
  },
  {
    postalCode: '31079',
    city: 'Almstedt'
  },
  {
    postalCode: '31079',
    city: 'Eberholzen'
  },
  {
    postalCode: '31079',
    city: 'Sibbesse'
  },
  {
    postalCode: '31079',
    city: 'Westfeld'
  },
  {
    postalCode: '31084',
    city: 'Freden'
  },
  {
    postalCode: '31085',
    city: 'Everode'
  },
  {
    postalCode: '31087',
    city: 'Landwehr'
  },
  {
    postalCode: '31088',
    city: 'Winzenburg'
  },
  {
    postalCode: '31089',
    city: 'Duingen'
  },
  {
    postalCode: '31091',
    city: 'Coppengrave'
  },
  {
    postalCode: '31093',
    city: 'Hoyershausen'
  },
  {
    postalCode: '31094',
    city: 'Marienhagen'
  },
  {
    postalCode: '31096',
    city: 'Weenzen'
  },
  {
    postalCode: '31097',
    city: 'Harbarnsen'
  },
  {
    postalCode: '31099',
    city: 'Woltershausen'
  },
  {
    postalCode: '31134',
    city: 'Hildesheim'
  },
  {
    postalCode: '31135',
    city: 'Hildesheim'
  },
  {
    postalCode: '31137',
    city: 'Hildesheim'
  },
  {
    postalCode: '31139',
    city: 'Hildesheim'
  },
  {
    postalCode: '31141',
    city: 'Hildesheim'
  },
  {
    postalCode: '31157',
    city: 'Sarstedt'
  },
  {
    postalCode: '31162',
    city: 'Bad Salzdetfurth'
  },
  {
    postalCode: '31167',
    city: 'Bockenem'
  },
  {
    postalCode: '31171',
    city: 'Nordstemmen'
  },
  {
    postalCode: '31174',
    city: 'Schellerten'
  },
  {
    postalCode: '31177',
    city: 'Harsum'
  },
  {
    postalCode: '31180',
    city: 'Giesen'
  },
  {
    postalCode: '31185',
    city: 'Söhlde'
  },
  {
    postalCode: '31188',
    city: 'Holle'
  },
  {
    postalCode: '31191',
    city: 'Algermissen'
  },
  {
    postalCode: '31195',
    city: 'Lamspringe'
  },
  {
    postalCode: '31195',
    city: 'Neuhof'
  },
  {
    postalCode: '31195',
    city: 'Wöllersheim'
  },
  {
    postalCode: '31196',
    city: 'Sehlem'
  },
  {
    postalCode: '31199',
    city: 'Diekholzen'
  },
  {
    postalCode: '31224',
    city: 'Peine'
  },
  {
    postalCode: '31226',
    city: 'Peine'
  },
  {
    postalCode: '31228',
    city: 'Peine'
  },
  {
    postalCode: '31234',
    city: 'Edemissen'
  },
  {
    postalCode: '31241',
    city: 'Ilsede'
  },
  {
    postalCode: '31246',
    city: 'Lahstedt'
  },
  {
    postalCode: '31249',
    city: 'Hohenhameln'
  },
  {
    postalCode: '31275',
    city: 'Lehrte'
  },
  {
    postalCode: '31303',
    city: 'Burgdorf'
  },
  {
    postalCode: '31311',
    city: 'Uetze'
  },
  {
    postalCode: '31319',
    city: 'Sehnde'
  },
  {
    postalCode: '31515',
    city: 'Wunstorf'
  },
  {
    postalCode: '31535',
    city: 'Neustadt'
  },
  {
    postalCode: '31542',
    city: 'Bad Nenndorf'
  },
  {
    postalCode: '31547',
    city: 'Rehburg-Loccum'
  },
  {
    postalCode: '31552',
    city: 'Apelern'
  },
  {
    postalCode: '31552',
    city: 'Rodenberg'
  },
  {
    postalCode: '31553',
    city: 'Auhagen'
  },
  {
    postalCode: '31553',
    city: 'Sachsenhagen'
  },
  {
    postalCode: '31555',
    city: 'Suthfeld'
  },
  {
    postalCode: '31556',
    city: 'Wölpinghausen'
  },
  {
    postalCode: '31558',
    city: 'Hagenburg'
  },
  {
    postalCode: '31559',
    city: 'Haste'
  },
  {
    postalCode: '31559',
    city: 'Hohnhorst'
  },
  {
    postalCode: '31582',
    city: 'Nienburg'
  },
  {
    postalCode: '31592',
    city: 'Stolzenau'
  },
  {
    postalCode: '31595',
    city: 'Steyerberg'
  },
  {
    postalCode: '31600',
    city: 'Uchte'
  },
  {
    postalCode: '31603',
    city: 'Diepenau'
  },
  {
    postalCode: '31604',
    city: 'Raddestorf'
  },
  {
    postalCode: '31606',
    city: 'Warmsen'
  },
  {
    postalCode: '31608',
    city: 'Marklohe'
  },
  {
    postalCode: '31609',
    city: 'Balge'
  },
  {
    postalCode: '31613',
    city: 'Wietzen'
  },
  {
    postalCode: '31618',
    city: 'Liebenau'
  },
  {
    postalCode: '31619',
    city: 'Binnen'
  },
  {
    postalCode: '31621',
    city: 'Pennigsehl'
  },
  {
    postalCode: '31622',
    city: 'Heemsen'
  },
  {
    postalCode: '31623',
    city: 'Drakenburg'
  },
  {
    postalCode: '31626',
    city: 'Haßbergen'
  },
  {
    postalCode: '31627',
    city: 'Rohrsen'
  },
  {
    postalCode: '31628',
    city: 'Landesbergen'
  },
  {
    postalCode: '31629',
    city: 'Estorf'
  },
  {
    postalCode: '31632',
    city: 'Husum'
  },
  {
    postalCode: '31633',
    city: 'Leese'
  },
  {
    postalCode: '31634',
    city: 'Steimbke'
  },
  {
    postalCode: '31636',
    city: 'Linsburg'
  },
  {
    postalCode: '31637',
    city: 'Rodewald'
  },
  {
    postalCode: '31638',
    city: 'Stöckse'
  },
  {
    postalCode: '31655',
    city: 'Stadthagen'
  },
  {
    postalCode: '31675',
    city: 'Bückeburg'
  },
  {
    postalCode: '31683',
    city: 'Obernkirchen'
  },
  {
    postalCode: '31688',
    city: 'Nienstädt'
  },
  {
    postalCode: '31691',
    city: 'Helpsen'
  },
  {
    postalCode: '31691',
    city: 'Seggebruch'
  },
  {
    postalCode: '31693',
    city: 'Hespe'
  },
  {
    postalCode: '31698',
    city: 'Beckedorfer Schacht'
  },
  {
    postalCode: '31698',
    city: 'Lindhorst'
  },
  {
    postalCode: '31699',
    city: 'Beckedorf'
  },
  {
    postalCode: '31700',
    city: 'Heuerßen'
  },
  {
    postalCode: '31702',
    city: 'Lüdersfeld'
  },
  {
    postalCode: '31707',
    city: 'Bad Eilsen'
  },
  {
    postalCode: '31707',
    city: 'Heeßen'
  },
  {
    postalCode: '31708',
    city: 'Ahnsen'
  },
  {
    postalCode: '31710',
    city: 'Buchholz'
  },
  {
    postalCode: '31711',
    city: 'Luhden'
  },
  {
    postalCode: '31712',
    city: 'Niedernwöhren'
  },
  {
    postalCode: '31714',
    city: 'Lauenhagen'
  },
  {
    postalCode: '31715',
    city: 'Meerbeck'
  },
  {
    postalCode: '31717',
    city: 'Nordsehl'
  },
  {
    postalCode: '31718',
    city: 'Pollhagen'
  },
  {
    postalCode: '31719',
    city: 'Wiedensahl'
  },
  {
    postalCode: '31737',
    city: 'Rinteln'
  },
  {
    postalCode: '31749',
    city: 'Auetal'
  },
  {
    postalCode: '31785',
    city: 'Hameln'
  },
  {
    postalCode: '31787',
    city: 'Hameln'
  },
  {
    postalCode: '31789',
    city: 'Hameln'
  },
  {
    postalCode: '31812',
    city: 'Bad Pyrmont'
  },
  {
    postalCode: '31832',
    city: 'Springe'
  },
  {
    postalCode: '31840',
    city: 'Hessisch Oldendorf'
  },
  {
    postalCode: '31848',
    city: 'Bad Münder'
  },
  {
    postalCode: '31855',
    city: 'Aerzen'
  },
  {
    postalCode: '31860',
    city: 'Emmerthal'
  },
  {
    postalCode: '31863',
    city: 'Coppenbrügge'
  },
  {
    postalCode: '31867',
    city: 'Hülsede'
  },
  {
    postalCode: '31867',
    city: 'Lauenau'
  },
  {
    postalCode: '31867',
    city: 'Messenkamp'
  },
  {
    postalCode: '31867',
    city: 'Pohle'
  },
  {
    postalCode: '31868',
    city: 'Ottenstein'
  },
  {
    postalCode: '32049',
    city: 'Herford'
  },
  {
    postalCode: '32051',
    city: 'Herford'
  },
  {
    postalCode: '32052',
    city: 'Herford'
  },
  {
    postalCode: '32105',
    city: 'Bad Salzuflen'
  },
  {
    postalCode: '32107',
    city: 'Bad Salzuflen'
  },
  {
    postalCode: '32108',
    city: 'Bad Salzuflen'
  },
  {
    postalCode: '32120',
    city: 'Hiddenhausen'
  },
  {
    postalCode: '32130',
    city: 'Enger'
  },
  {
    postalCode: '32139',
    city: 'Spenge'
  },
  {
    postalCode: '32257',
    city: 'Bünde'
  },
  {
    postalCode: '32278',
    city: 'Kirchlengern'
  },
  {
    postalCode: '32289',
    city: 'Rödinghausen'
  },
  {
    postalCode: '32312',
    city: 'Lübbecke'
  },
  {
    postalCode: '32339',
    city: 'Espelkamp'
  },
  {
    postalCode: '32351',
    city: 'Stemwede'
  },
  {
    postalCode: '32361',
    city: 'Preußisch Oldendorf'
  },
  {
    postalCode: '32369',
    city: 'Rahden'
  },
  {
    postalCode: '32423',
    city: 'Minden'
  },
  {
    postalCode: '32425',
    city: 'Minden'
  },
  {
    postalCode: '32427',
    city: 'Minden'
  },
  {
    postalCode: '32429',
    city: 'Minden'
  },
  {
    postalCode: '32457',
    city: 'Porta Westfalica'
  },
  {
    postalCode: '32469',
    city: 'Petershagen'
  },
  {
    postalCode: '32479',
    city: 'Hille'
  },
  {
    postalCode: '32545',
    city: 'Bad Oeynhausen'
  },
  {
    postalCode: '32547',
    city: 'Bad Oeynhausen'
  },
  {
    postalCode: '32549',
    city: 'Bad Oeynhausen'
  },
  {
    postalCode: '32584',
    city: 'Löhne'
  },
  {
    postalCode: '32602',
    city: 'Vlotho'
  },
  {
    postalCode: '32609',
    city: 'Hüllhorst'
  },
  {
    postalCode: '32657',
    city: 'Lemgo'
  },
  {
    postalCode: '32676',
    city: 'Lügde'
  },
  {
    postalCode: '32683',
    city: 'Barntrup'
  },
  {
    postalCode: '32689',
    city: 'Kalletal'
  },
  {
    postalCode: '32694',
    city: 'Dörentrup'
  },
  {
    postalCode: '32699',
    city: 'Extertal'
  },
  {
    postalCode: '32756',
    city: 'Detmold'
  },
  {
    postalCode: '32758',
    city: 'Detmold'
  },
  {
    postalCode: '32760',
    city: 'Detmold'
  },
  {
    postalCode: '32791',
    city: 'Lage'
  },
  {
    postalCode: '32805',
    city: 'Horn-Bad Meinberg'
  },
  {
    postalCode: '32816',
    city: 'Schieder-Schwalenberg'
  },
  {
    postalCode: '32825',
    city: 'Blomberg'
  },
  {
    postalCode: '32832',
    city: 'Augustdorf'
  },
  {
    postalCode: '32839',
    city: 'Steinheim'
  },
  {
    postalCode: '33014',
    city: 'Bad Driburg'
  },
  {
    postalCode: '33034',
    city: 'Brakel'
  },
  {
    postalCode: '33039',
    city: 'Nieheim'
  },
  {
    postalCode: '33098',
    city: 'Paderborn'
  },
  {
    postalCode: '33100',
    city: 'Paderborn'
  },
  {
    postalCode: '33102',
    city: 'Paderborn'
  },
  {
    postalCode: '33104',
    city: 'Paderborn'
  },
  {
    postalCode: '33106',
    city: 'Paderborn'
  },
  {
    postalCode: '33129',
    city: 'Delbrück'
  },
  {
    postalCode: '33142',
    city: 'Büren'
  },
  {
    postalCode: '33154',
    city: 'Paderborn'
  },
  {
    postalCode: '33154',
    city: 'Salzkotten'
  },
  {
    postalCode: '33161',
    city: 'Hövelhof'
  },
  {
    postalCode: '33161',
    city: 'Paderborn'
  },
  {
    postalCode: '33165',
    city: 'Lichtenau'
  },
  {
    postalCode: '33175',
    city: 'Bad Lippspringe'
  },
  {
    postalCode: '33178',
    city: 'Borchen'
  },
  {
    postalCode: '33181',
    city: 'Bad Wünnenberg'
  },
  {
    postalCode: '33184',
    city: 'Altenbeken'
  },
  {
    postalCode: '33189',
    city: 'Schlangen'
  },
  {
    postalCode: '33330',
    city: 'Gütersloh'
  },
  {
    postalCode: '33332',
    city: 'Gütersloh'
  },
  {
    postalCode: '33334',
    city: 'Gütersloh'
  },
  {
    postalCode: '33335',
    city: 'Gütersloh'
  },
  {
    postalCode: '33378',
    city: 'Rheda-Wiedenbrück'
  },
  {
    postalCode: '33397',
    city: 'Rietberg'
  },
  {
    postalCode: '33415',
    city: 'Verl'
  },
  {
    postalCode: '33428',
    city: 'Harsewinkel'
  },
  {
    postalCode: '33428',
    city: 'Marienfeld'
  },
  {
    postalCode: '33442',
    city: 'Herzebrock-Clarholz'
  },
  {
    postalCode: '33449',
    city: 'Langenberg'
  },
  {
    postalCode: '33602',
    city: 'Bielefeld'
  },
  {
    postalCode: '33604',
    city: 'Bielefeld'
  },
  {
    postalCode: '33605',
    city: 'Bielefeld'
  },
  {
    postalCode: '33607',
    city: 'Bielefeld'
  },
  {
    postalCode: '33609',
    city: 'Bielefeld'
  },
  {
    postalCode: '33611',
    city: 'Bielefeld'
  },
  {
    postalCode: '33613',
    city: 'Bielefeld'
  },
  {
    postalCode: '33615',
    city: 'Bielefeld'
  },
  {
    postalCode: '33617',
    city: 'Bielefeld'
  },
  {
    postalCode: '33619',
    city: 'Bielefeld'
  },
  {
    postalCode: '33647',
    city: 'Bielefeld'
  },
  {
    postalCode: '33649',
    city: 'Bielefeld'
  },
  {
    postalCode: '33659',
    city: 'Bielefeld'
  },
  {
    postalCode: '33689',
    city: 'Bielefeld'
  },
  {
    postalCode: '33699',
    city: 'Bielefeld'
  },
  {
    postalCode: '33719',
    city: 'Bielefeld'
  },
  {
    postalCode: '33729',
    city: 'Bielefeld'
  },
  {
    postalCode: '33739',
    city: 'Bielefeld'
  },
  {
    postalCode: '33758',
    city: 'Schloß Holte-Stukenbrock'
  },
  {
    postalCode: '33775',
    city: 'Versmold'
  },
  {
    postalCode: '33790',
    city: 'Halle'
  },
  {
    postalCode: '33803',
    city: 'Steinhagen'
  },
  {
    postalCode: '33813',
    city: 'Oerlinghausen'
  },
  {
    postalCode: '33818',
    city: 'Leopoldshöhe'
  },
  {
    postalCode: '33824',
    city: 'Werther'
  },
  {
    postalCode: '33829',
    city: 'Borgholzhausen'
  },
  {
    postalCode: '34117',
    city: 'Kassel'
  },
  {
    postalCode: '34119',
    city: 'Kassel'
  },
  {
    postalCode: '34121',
    city: 'Kassel'
  },
  {
    postalCode: '34123',
    city: 'Kassel'
  },
  {
    postalCode: '34125',
    city: 'Kassel'
  },
  {
    postalCode: '34127',
    city: 'Am Sandkopf'
  },
  {
    postalCode: '34127',
    city: 'Kassel'
  },
  {
    postalCode: '34128',
    city: 'Kassel'
  },
  {
    postalCode: '34130',
    city: 'Kassel'
  },
  {
    postalCode: '34131',
    city: 'Kassel'
  },
  {
    postalCode: '34132',
    city: 'Kassel'
  },
  {
    postalCode: '34134',
    city: 'Kassel'
  },
  {
    postalCode: '34212',
    city: 'Melsungen'
  },
  {
    postalCode: '34225',
    city: 'Baunatal'
  },
  {
    postalCode: '34225',
    city: 'Kahler Berg'
  },
  {
    postalCode: '34233',
    city: 'Fuldatal'
  },
  {
    postalCode: '34233',
    city: 'Kassel'
  },
  {
    postalCode: '34246',
    city: 'Hof Mondschirm'
  },
  {
    postalCode: '34246',
    city: 'Vellmar'
  },
  {
    postalCode: '34253',
    city: 'Lohfelden'
  },
  {
    postalCode: '34260',
    city: 'Kaufungen'
  },
  {
    postalCode: '34266',
    city: 'Niestetal'
  },
  {
    postalCode: '34270',
    city: 'Schauenburg'
  },
  {
    postalCode: '34277',
    city: 'Fuldabrück'
  },
  {
    postalCode: '34281',
    city: 'Gudensberg'
  },
  {
    postalCode: '34286',
    city: 'Spangenberg'
  },
  {
    postalCode: '34289',
    city: 'Zierenberg'
  },
  {
    postalCode: '34292',
    city: 'Ahnatal'
  },
  {
    postalCode: '34295',
    city: 'Edermünde'
  },
  {
    postalCode: '34298',
    city: 'Helsa'
  },
  {
    postalCode: '34302',
    city: 'Guxhagen'
  },
  {
    postalCode: '34305',
    city: 'Gestecke'
  },
  {
    postalCode: '34305',
    city: 'Niedenstein'
  },
  {
    postalCode: '34308',
    city: 'Bad Emstal'
  },
  {
    postalCode: '34311',
    city: 'Naumburg'
  },
  {
    postalCode: '34314',
    city: 'Espenau'
  },
  {
    postalCode: '34317',
    city: 'Habichtswald'
  },
  {
    postalCode: '34320',
    city: 'Söhrewald'
  },
  {
    postalCode: '34323',
    city: 'Malsfeld'
  },
  {
    postalCode: '34326',
    city: 'Morschen'
  },
  {
    postalCode: '34327',
    city: 'Körle'
  },
  {
    postalCode: '34329',
    city: 'Nieste'
  },
  {
    postalCode: '34346',
    city: 'Hann. Münden'
  },
  {
    postalCode: '34355',
    city: 'Kassel'
  },
  {
    postalCode: '34355',
    city: 'Staufenberg'
  },
  {
    postalCode: '34359',
    city: 'Reinhardshagen'
  },
  {
    postalCode: '34369',
    city: 'Hofgeismar'
  },
  {
    postalCode: '34376',
    city: 'Immenhausen'
  },
  {
    postalCode: '34379',
    city: 'Calden'
  },
  {
    postalCode: '34385',
    city: 'Bad Karlshafen'
  },
  {
    postalCode: '34388',
    city: 'Trendelburg'
  },
  {
    postalCode: '34393',
    city: 'Grebenstein'
  },
  {
    postalCode: '34396',
    city: 'Liebenau'
  },
  {
    postalCode: '34399',
    city: 'Oberweser'
  },
  {
    postalCode: '34414',
    city: 'Warburg'
  },
  {
    postalCode: '34431',
    city: 'Marsberg'
  },
  {
    postalCode: '34434',
    city: 'Borgentreich'
  },
  {
    postalCode: '34439',
    city: 'Willebadessen'
  },
  {
    postalCode: '34454',
    city: 'Bad Arolsen'
  },
  {
    postalCode: '34466',
    city: 'Wolfhagen'
  },
  {
    postalCode: '34471',
    city: 'Volkmarsen'
  },
  {
    postalCode: '34474',
    city: 'Diemelstadt'
  },
  {
    postalCode: '34477',
    city: 'Twistetal'
  },
  {
    postalCode: '34479',
    city: 'Breuna'
  },
  {
    postalCode: '34497',
    city: 'Am Rainberge'
  },
  {
    postalCode: '34497',
    city: 'Korbach'
  },
  {
    postalCode: '34508',
    city: 'Willingen'
  },
  {
    postalCode: '34513',
    city: 'Klippmühle'
  },
  {
    postalCode: '34513',
    city: 'Waldeck'
  },
  {
    postalCode: '34516',
    city: 'Fürstental'
  },
  {
    postalCode: '34516',
    city: 'Vöhl'
  },
  {
    postalCode: '34519',
    city: 'Diemelsee'
  },
  {
    postalCode: '34537',
    city: 'Bad Wildungen'
  },
  {
    postalCode: '34549',
    city: 'Edertal'
  },
  {
    postalCode: '34560',
    city: 'Fritzlar'
  },
  {
    postalCode: '34576',
    city: 'Grünhof'
  },
  {
    postalCode: '34576',
    city: 'Homberg'
  },
  {
    postalCode: '34582',
    city: 'Borken'
  },
  {
    postalCode: '34587',
    city: 'Felsberg'
  },
  {
    postalCode: '34590',
    city: 'Wabern'
  },
  {
    postalCode: '34593',
    city: 'Knüllwald'
  },
  {
    postalCode: '34596',
    city: 'Bad Zwesten'
  },
  {
    postalCode: '34599',
    city: 'Neuental'
  },
  {
    postalCode: '34613',
    city: 'Schwalmstadt'
  },
  {
    postalCode: '34621',
    city: 'Frielendorf'
  },
  {
    postalCode: '34626',
    city: 'Neukirchen'
  },
  {
    postalCode: '34628',
    city: 'Willingshausen'
  },
  {
    postalCode: '34630',
    city: 'Gilserberg'
  },
  {
    postalCode: '34632',
    city: 'Jesberg'
  },
  {
    postalCode: '34633',
    city: 'Ottrau'
  },
  {
    postalCode: '34637',
    city: 'Schrecksbach'
  },
  {
    postalCode: '34639',
    city: 'Schwarzenborn'
  },
  {
    postalCode: '35037',
    city: 'Marburg'
  },
  {
    postalCode: '35039',
    city: 'Marburg'
  },
  {
    postalCode: '35041',
    city: 'Marburg'
  },
  {
    postalCode: '35043',
    city: 'Capelle'
  },
  {
    postalCode: '35043',
    city: 'Marburg'
  },
  {
    postalCode: '35066',
    city: 'Frankenberg'
  },
  {
    postalCode: '35075',
    city: 'Gladenbach'
  },
  {
    postalCode: '35080',
    city: 'Bad Endbach'
  },
  {
    postalCode: '35083',
    city: 'Wetter'
  },
  {
    postalCode: '35085',
    city: 'Ebsdorfergrund'
  },
  {
    postalCode: '35088',
    city: 'Battenberg'
  },
  {
    postalCode: '35091',
    city: 'Cölbe'
  },
  {
    postalCode: '35094',
    city: 'Lahntal'
  },
  {
    postalCode: '35094',
    city: 'Michelbacher Mühle'
  },
  {
    postalCode: '35096',
    city: 'Weimar'
  },
  {
    postalCode: '35099',
    city: 'Burgwald'
  },
  {
    postalCode: '35102',
    city: 'Lohra'
  },
  {
    postalCode: '35104',
    city: 'Lichtenfels'
  },
  {
    postalCode: '35108',
    city: 'Allendorf'
  },
  {
    postalCode: '35110',
    city: 'Frankenau'
  },
  {
    postalCode: '35112',
    city: 'Fronhausen'
  },
  {
    postalCode: '35114',
    city: 'Haina'
  },
  {
    postalCode: '35116',
    city: 'Hatzfeld'
  },
  {
    postalCode: '35117',
    city: 'Münchhausen'
  },
  {
    postalCode: '35119',
    city: 'Rosenthal'
  },
  {
    postalCode: '35216',
    city: 'Biedenkopf'
  },
  {
    postalCode: '35216',
    city: 'Waldhaus Grebe'
  },
  {
    postalCode: '35232',
    city: 'Dautphetal'
  },
  {
    postalCode: '35236',
    city: 'Breidenbach'
  },
  {
    postalCode: '35239',
    city: 'Steffenberg'
  },
  {
    postalCode: '35260',
    city: 'Stadtallendorf'
  },
  {
    postalCode: '35274',
    city: 'Kirchhain'
  },
  {
    postalCode: '35279',
    city: 'Neustadt'
  },
  {
    postalCode: '35282',
    city: 'Rauschenberg'
  },
  {
    postalCode: '35285',
    city: 'Gemünden'
  },
  {
    postalCode: '35287',
    city: 'Amöneburg'
  },
  {
    postalCode: '35288',
    city: 'Wohratal'
  },
  {
    postalCode: '35305',
    city: 'Grünberg'
  },
  {
    postalCode: '35315',
    city: 'Homberg'
  },
  {
    postalCode: '35321',
    city: 'Laubach'
  },
  {
    postalCode: '35325',
    city: 'Mücke'
  },
  {
    postalCode: '35327',
    city: 'Ulrichstein'
  },
  {
    postalCode: '35329',
    city: 'Gemünden'
  },
  {
    postalCode: '35390',
    city: 'Gießen'
  },
  {
    postalCode: '35392',
    city: 'Gießen'
  },
  {
    postalCode: '35394',
    city: 'Gießen'
  },
  {
    postalCode: '35396',
    city: 'Gießen'
  },
  {
    postalCode: '35398',
    city: 'Gießen'
  },
  {
    postalCode: '35410',
    city: 'Hungen'
  },
  {
    postalCode: '35415',
    city: 'Pohlheim'
  },
  {
    postalCode: '35418',
    city: 'Buseck'
  },
  {
    postalCode: '35423',
    city: 'Lich'
  },
  {
    postalCode: '35428',
    city: 'Langgöns'
  },
  {
    postalCode: '35435',
    city: 'Wettenberg'
  },
  {
    postalCode: '35440',
    city: 'Linden'
  },
  {
    postalCode: '35444',
    city: 'Biebertal'
  },
  {
    postalCode: '35447',
    city: 'Reiskirchen'
  },
  {
    postalCode: '35452',
    city: 'Heuchelheim'
  },
  {
    postalCode: '35457',
    city: 'Lollar'
  },
  {
    postalCode: '35460',
    city: 'Staufenberg'
  },
  {
    postalCode: '35463',
    city: 'Fernwald'
  },
  {
    postalCode: '35466',
    city: 'Rabenau'
  },
  {
    postalCode: '35469',
    city: 'Allendorf'
  },
  {
    postalCode: '35510',
    city: 'Butzbach'
  },
  {
    postalCode: '35516',
    city: 'Münzenberg'
  },
  {
    postalCode: '35519',
    city: 'Rockenberg'
  },
  {
    postalCode: '35576',
    city: 'Wetzlar'
  },
  {
    postalCode: '35578',
    city: 'Wetzlar'
  },
  {
    postalCode: '35579',
    city: 'Wetzlar'
  },
  {
    postalCode: '35580',
    city: 'Wetzlar'
  },
  {
    postalCode: '35581',
    city: 'Wetzlar'
  },
  {
    postalCode: '35582',
    city: 'Wetzlar'
  },
  {
    postalCode: '35583',
    city: 'Wetzlar'
  },
  {
    postalCode: '35584',
    city: 'Wetzlar'
  },
  {
    postalCode: '35585',
    city: 'Wetzlar'
  },
  {
    postalCode: '35586',
    city: 'Wetzlar'
  },
  {
    postalCode: '35606',
    city: 'Solms'
  },
  {
    postalCode: '35614',
    city: 'Aßlar'
  },
  {
    postalCode: '35619',
    city: 'Braunfels'
  },
  {
    postalCode: '35625',
    city: 'Hüttenberg'
  },
  {
    postalCode: '35630',
    city: 'Ehringshausen'
  },
  {
    postalCode: '35630',
    city: 'Grundmühle'
  },
  {
    postalCode: '35630',
    city: 'Heinrichsegen'
  },
  {
    postalCode: '35633',
    city: 'Lahnau'
  },
  {
    postalCode: '35638',
    city: 'Leun'
  },
  {
    postalCode: '35641',
    city: 'Schöffengrund'
  },
  {
    postalCode: '35644',
    city: 'Hohenahr'
  },
  {
    postalCode: '35647',
    city: 'Waldsolms'
  },
  {
    postalCode: '35649',
    city: 'Bischoffen'
  },
  {
    postalCode: '35683',
    city: 'Dillenburg'
  },
  {
    postalCode: '35684',
    city: 'Dillenburg'
  },
  {
    postalCode: '35685',
    city: 'Dillenburg'
  },
  {
    postalCode: '35686',
    city: 'Dillenburg'
  },
  {
    postalCode: '35687',
    city: 'Dillenburg'
  },
  {
    postalCode: '35688',
    city: 'Dillenburg'
  },
  {
    postalCode: '35689',
    city: 'Dillenburg'
  },
  {
    postalCode: '35690',
    city: 'Dillenburg'
  },
  {
    postalCode: '35708',
    city: 'Haiger'
  },
  {
    postalCode: '35713',
    city: 'Eschenburg'
  },
  {
    postalCode: '35716',
    city: 'Dietzhölztal'
  },
  {
    postalCode: '35719',
    city: 'Angelburg'
  },
  {
    postalCode: '35745',
    city: 'Herborn'
  },
  {
    postalCode: '35753',
    city: 'Greifenstein'
  },
  {
    postalCode: '35756',
    city: 'Mittenaar'
  },
  {
    postalCode: '35759',
    city: 'Driedorf'
  },
  {
    postalCode: '35764',
    city: 'Sinn'
  },
  {
    postalCode: '35767',
    city: 'Breitscheid'
  },
  {
    postalCode: '35768',
    city: 'Siegbach'
  },
  {
    postalCode: '35781',
    city: 'Weilburg'
  },
  {
    postalCode: '35789',
    city: 'Weilmünster'
  },
  {
    postalCode: '35792',
    city: 'Löhnberg'
  },
  {
    postalCode: '35794',
    city: 'Mengerskirchen'
  },
  {
    postalCode: '35796',
    city: 'Weinbach'
  },
  {
    postalCode: '35799',
    city: 'Merenberg'
  },
  {
    postalCode: '36037',
    city: 'Fulda'
  },
  {
    postalCode: '36039',
    city: 'Fulda'
  },
  {
    postalCode: '36041',
    city: 'Fulda'
  },
  {
    postalCode: '36043',
    city: 'Fulda'
  },
  {
    postalCode: '36088',
    city: 'Hünfeld'
  },
  {
    postalCode: '36093',
    city: 'Künzell'
  },
  {
    postalCode: '36100',
    city: 'Petersberg'
  },
  {
    postalCode: '36103',
    city: 'Flieden'
  },
  {
    postalCode: '36110',
    city: 'Schlitz'
  },
  {
    postalCode: '36115',
    city: 'Ehrenberg'
  },
  {
    postalCode: '36115',
    city: 'Hilders'
  },
  {
    postalCode: '36119',
    city: 'Neuhof'
  },
  {
    postalCode: '36124',
    city: 'Eichenzell'
  },
  {
    postalCode: '36129',
    city: 'Gersfeld'
  },
  {
    postalCode: '36129',
    city: 'Wachtküppel'
  },
  {
    postalCode: '36132',
    city: 'Eiterfeld'
  },
  {
    postalCode: '36137',
    city: 'Großenlüder'
  },
  {
    postalCode: '36142',
    city: 'Tann'
  },
  {
    postalCode: '36145',
    city: 'Hofbieber'
  },
  {
    postalCode: '36148',
    city: 'Kalbach'
  },
  {
    postalCode: '36151',
    city: 'Burghaun'
  },
  {
    postalCode: '36154',
    city: 'Hosenfeld'
  },
  {
    postalCode: '36154',
    city: 'Zwickmühle'
  },
  {
    postalCode: '36157',
    city: 'Ebersburg'
  },
  {
    postalCode: '36160',
    city: 'Dipperz'
  },
  {
    postalCode: '36163',
    city: 'Poppenhausen'
  },
  {
    postalCode: '36166',
    city: 'Haunetal'
  },
  {
    postalCode: '36166',
    city: 'Sennhütte'
  },
  {
    postalCode: '36167',
    city: 'Nüsttal'
  },
  {
    postalCode: '36169',
    city: 'Rasdorf'
  },
  {
    postalCode: '36179',
    city: 'Bebra'
  },
  {
    postalCode: '36179',
    city: 'Gunkelrode'
  },
  {
    postalCode: '36199',
    city: 'Rotenburg'
  },
  {
    postalCode: '36205',
    city: 'Sontra'
  },
  {
    postalCode: '36208',
    city: 'Bellers'
  },
  {
    postalCode: '36208',
    city: 'Wildeck'
  },
  {
    postalCode: '36211',
    city: 'Alheim'
  },
  {
    postalCode: '36214',
    city: 'Nentershausen'
  },
  {
    postalCode: '36217',
    city: 'Ronshausen'
  },
  {
    postalCode: '36219',
    city: 'Cornberg'
  },
  {
    postalCode: '36219',
    city: 'Menglers'
  },
  {
    postalCode: '36251',
    city: 'Bad Hersfeld'
  },
  {
    postalCode: '36251',
    city: 'Ludwigsau'
  },
  {
    postalCode: '36266',
    city: 'Heringen'
  },
  {
    postalCode: '36269',
    city: 'Philippsthal'
  },
  {
    postalCode: '36272',
    city: 'Niederaula'
  },
  {
    postalCode: '36275',
    city: 'Kirchheim'
  },
  {
    postalCode: '36277',
    city: 'Schenklengsfeld'
  },
  {
    postalCode: '36280',
    city: 'Oberaula'
  },
  {
    postalCode: '36282',
    city: 'Hauneck'
  },
  {
    postalCode: '36284',
    city: 'Hohenroda'
  },
  {
    postalCode: '36286',
    city: 'Neuenstein'
  },
  {
    postalCode: '36287',
    city: 'Breitenbach'
  },
  {
    postalCode: '36289',
    city: 'Friedewald'
  },
  {
    postalCode: '36304',
    city: 'Alsfeld'
  },
  {
    postalCode: '36304',
    city: 'Hardtmühle'
  },
  {
    postalCode: '36318',
    city: 'Schwalmtal'
  },
  {
    postalCode: '36320',
    city: 'Kirtorf'
  },
  {
    postalCode: '36323',
    city: 'Grebenau'
  },
  {
    postalCode: '36325',
    city: 'Feldatal'
  },
  {
    postalCode: '36326',
    city: 'Antrifttal'
  },
  {
    postalCode: '36326',
    city: 'Dammeshof'
  },
  {
    postalCode: '36326',
    city: 'Dammesmühle'
  },
  {
    postalCode: '36329',
    city: 'Romrod'
  },
  {
    postalCode: '36341',
    city: 'Lauterbach'
  },
  {
    postalCode: '36355',
    city: 'Grebenhain'
  },
  {
    postalCode: '36358',
    city: 'Herbstein'
  },
  {
    postalCode: '36364',
    city: 'Bad Salzschlirf'
  },
  {
    postalCode: '36367',
    city: 'Wartenberg'
  },
  {
    postalCode: '36369',
    city: 'Lautertal'
  },
  {
    postalCode: '36381',
    city: 'Schlüchtern'
  },
  {
    postalCode: '36391',
    city: 'Sinntal'
  },
  {
    postalCode: '36396',
    city: 'Steinau'
  },
  {
    postalCode: '36399',
    city: 'Freiensteinau'
  },
  {
    postalCode: '36404',
    city: 'Gehaus'
  },
  {
    postalCode: '36404',
    city: 'Martinroda'
  },
  {
    postalCode: '36404',
    city: 'Mieswarz'
  },
  {
    postalCode: '36404',
    city: 'Oechsen'
  },
  {
    postalCode: '36404',
    city: 'Otzbach'
  },
  {
    postalCode: '36404',
    city: 'Sünna'
  },
  {
    postalCode: '36404',
    city: 'Vacha'
  },
  {
    postalCode: '36404',
    city: 'Völkershausen'
  },
  {
    postalCode: '36404',
    city: 'Wölferbütt'
  },
  {
    postalCode: '36414',
    city: 'Pferdsdorf'
  },
  {
    postalCode: '36414',
    city: 'Unterbreizbach'
  },
  {
    postalCode: '36419',
    city: 'Bermbach'
  },
  {
    postalCode: '36419',
    city: 'Borsch'
  },
  {
    postalCode: '36419',
    city: 'Bremen'
  },
  {
    postalCode: '36419',
    city: 'Buttlar'
  },
  {
    postalCode: '36419',
    city: 'Geisa'
  },
  {
    postalCode: '36419',
    city: 'Geismar'
  },
  {
    postalCode: '36419',
    city: 'Gerstengrund'
  },
  {
    postalCode: '36419',
    city: 'Ketten'
  },
  {
    postalCode: '36419',
    city: 'Kranlucken'
  },
  {
    postalCode: '36419',
    city: 'Motzlar'
  },
  {
    postalCode: '36419',
    city: 'Schleid'
  },
  {
    postalCode: '36419',
    city: 'Spahl'
  },
  {
    postalCode: '36419',
    city: 'Wenigentaft'
  },
  {
    postalCode: '36419',
    city: 'Zitters'
  },
  {
    postalCode: '36433',
    city: 'Bad Salzungen'
  },
  {
    postalCode: '36433',
    city: 'Immelborn'
  },
  {
    postalCode: '36433',
    city: 'Leimbach'
  },
  {
    postalCode: '36433',
    city: 'Moorgrund'
  },
  {
    postalCode: '36448',
    city: 'Bad Liebenstein'
  },
  {
    postalCode: '36448',
    city: 'Schweina'
  },
  {
    postalCode: '36448',
    city: 'Steinbach'
  },
  {
    postalCode: '36452',
    city: 'Andenhausen'
  },
  {
    postalCode: '36452',
    city: 'Brunnhartshausen'
  },
  {
    postalCode: '36452',
    city: 'Diedorf'
  },
  {
    postalCode: '36452',
    city: 'Empfertshausen'
  },
  {
    postalCode: '36452',
    city: 'Fischbach'
  },
  {
    postalCode: '36452',
    city: 'Kaltenlengsfeld'
  },
  {
    postalCode: '36452',
    city: 'Kaltennordheim'
  },
  {
    postalCode: '36452',
    city: 'Klings'
  },
  {
    postalCode: '36452',
    city: 'Neidhartshausen'
  },
  {
    postalCode: '36452',
    city: 'Zella'
  },
  {
    postalCode: '36456',
    city: 'Barchfeld'
  },
  {
    postalCode: '36457',
    city: 'Stadtlengsfeld'
  },
  {
    postalCode: '36457',
    city: 'Urnshausen'
  },
  {
    postalCode: '36457',
    city: 'Weilar'
  },
  {
    postalCode: '36460',
    city: 'Dietlas'
  },
  {
    postalCode: '36460',
    city: 'Dönges'
  },
  {
    postalCode: '36460',
    city: 'Dorndorf'
  },
  {
    postalCode: '36460',
    city: 'Frauensee'
  },
  {
    postalCode: '36460',
    city: 'Kieselbach'
  },
  {
    postalCode: '36460',
    city: 'Merkers'
  },
  {
    postalCode: '36460',
    city: 'Weißendiez'
  },
  {
    postalCode: '36466',
    city: 'Dermbach'
  },
  {
    postalCode: '36466',
    city: 'Wiesenthal'
  },
  {
    postalCode: '36469',
    city: 'Oberrohn'
  },
  {
    postalCode: '36469',
    city: 'Tiefenort'
  },
  {
    postalCode: '37073',
    city: 'Göttingen'
  },
  {
    postalCode: '37075',
    city: 'Göttingen'
  },
  {
    postalCode: '37077',
    city: 'Göttingen'
  },
  {
    postalCode: '37079',
    city: 'Göttingen'
  },
  {
    postalCode: '37081',
    city: 'Göttingen'
  },
  {
    postalCode: '37083',
    city: 'Göttingen'
  },
  {
    postalCode: '37085',
    city: 'Göttingen'
  },
  {
    postalCode: '37115',
    city: 'Duderstadt'
  },
  {
    postalCode: '37120',
    city: 'Bovenden'
  },
  {
    postalCode: '37124',
    city: 'Rosdorf'
  },
  {
    postalCode: '37127',
    city: 'Brackenberg'
  },
  {
    postalCode: '37127',
    city: 'Bühren'
  },
  {
    postalCode: '37127',
    city: 'Dransfeld'
  },
  {
    postalCode: '37127',
    city: 'Jühnde'
  },
  {
    postalCode: '37127',
    city: 'Niemetal'
  },
  {
    postalCode: '37127',
    city: 'Scheden'
  },
  {
    postalCode: '37130',
    city: 'Gleichen'
  },
  {
    postalCode: '37133',
    city: 'Friedland'
  },
  {
    postalCode: '37136',
    city: 'Ebergötzen'
  },
  {
    postalCode: '37136',
    city: 'Landolfshausen'
  },
  {
    postalCode: '37136',
    city: 'Seeburg'
  },
  {
    postalCode: '37136',
    city: 'Seulingen'
  },
  {
    postalCode: '37136',
    city: 'Waake'
  },
  {
    postalCode: '37139',
    city: 'Adelebsen'
  },
  {
    postalCode: '37154',
    city: 'Northeim'
  },
  {
    postalCode: '37170',
    city: 'Uslar'
  },
  {
    postalCode: '37176',
    city: 'Nörten-Hardenberg'
  },
  {
    postalCode: '37181',
    city: 'Hardegsen'
  },
  {
    postalCode: '37186',
    city: 'Moringen'
  },
  {
    postalCode: '37191',
    city: 'Katlenburg-Lindau'
  },
  {
    postalCode: '37194',
    city: 'Bodenfelde'
  },
  {
    postalCode: '37194',
    city: 'Wahlsburg'
  },
  {
    postalCode: '37197',
    city: 'Hattorf'
  },
  {
    postalCode: '37199',
    city: 'Wulften'
  },
  {
    postalCode: '37213',
    city: 'Witzenhausen'
  },
  {
    postalCode: '37214',
    city: 'Witzenhausen'
  },
  {
    postalCode: '37215',
    city: 'Witzenhausen'
  },
  {
    postalCode: '37216',
    city: 'Witzenhausen'
  },
  {
    postalCode: '37217',
    city: 'Nonnenholz'
  },
  {
    postalCode: '37217',
    city: 'Witzenhausen'
  },
  {
    postalCode: '37218',
    city: 'Witzenhausen'
  },
  {
    postalCode: '37235',
    city: 'Hessisch Lichtenau'
  },
  {
    postalCode: '37242',
    city: 'Bad Sooden-Allendorf'
  },
  {
    postalCode: '37247',
    city: 'Großalmerode'
  },
  {
    postalCode: '37249',
    city: 'Neu-Eichenberg'
  },
  {
    postalCode: '37269',
    city: 'Eschwege'
  },
  {
    postalCode: '37276',
    city: 'Meinhard'
  },
  {
    postalCode: '37281',
    city: 'Wanfried'
  },
  {
    postalCode: '37284',
    city: 'Waldkappel'
  },
  {
    postalCode: '37287',
    city: 'Wehretal'
  },
  {
    postalCode: '37290',
    city: 'Meißner'
  },
  {
    postalCode: '37293',
    city: 'Herleshausen'
  },
  {
    postalCode: '37296',
    city: 'Ringgau'
  },
  {
    postalCode: '37297',
    city: 'Berkatal'
  },
  {
    postalCode: '37299',
    city: 'Weißenborn'
  },
  {
    postalCode: '37308',
    city: 'Ankermühle'
  },
  {
    postalCode: '37308',
    city: 'Bebendorf'
  },
  {
    postalCode: '37308',
    city: 'Bernterode'
  },
  {
    postalCode: '37308',
    city: 'Bischhagen'
  },
  {
    postalCode: '37308',
    city: 'Bodenrode'
  },
  {
    postalCode: '37308',
    city: 'Döringsdorf'
  },
  {
    postalCode: '37308',
    city: 'Geisleden'
  },
  {
    postalCode: '37308',
    city: 'Geismar'
  },
  {
    postalCode: '37308',
    city: 'Glasehausen'
  },
  {
    postalCode: '37308',
    city: 'Heilbad Heiligenstadt'
  },
  {
    postalCode: '37308',
    city: 'Heuthen'
  },
  {
    postalCode: '37308',
    city: 'Kella'
  },
  {
    postalCode: '37308',
    city: 'Krombach'
  },
  {
    postalCode: '37308',
    city: 'Mengelrode'
  },
  {
    postalCode: '37308',
    city: 'Pfaffschwende'
  },
  {
    postalCode: '37308',
    city: 'Reinholterode'
  },
  {
    postalCode: '37308',
    city: 'Schimberg'
  },
  {
    postalCode: '37308',
    city: 'Sickerode'
  },
  {
    postalCode: '37308',
    city: 'Siemerode'
  },
  {
    postalCode: '37308',
    city: 'Steinbach'
  },
  {
    postalCode: '37308',
    city: 'Streitholz'
  },
  {
    postalCode: '37308',
    city: 'Volkerode'
  },
  {
    postalCode: '37308',
    city: 'Westhausen'
  },
  {
    postalCode: '37308',
    city: 'Wiesenfeld'
  },
  {
    postalCode: '37318',
    city: 'Arenshausen'
  },
  {
    postalCode: '37318',
    city: 'Asbach-Sickenberg'
  },
  {
    postalCode: '37318',
    city: 'Birkenfelde'
  },
  {
    postalCode: '37318',
    city: 'Bornhagen'
  },
  {
    postalCode: '37318',
    city: 'Burgwalde'
  },
  {
    postalCode: '37318',
    city: 'Dieterode'
  },
  {
    postalCode: '37318',
    city: 'Dietzenrode/Vatterode'
  },
  {
    postalCode: '37318',
    city: 'Eichstruth'
  },
  {
    postalCode: '37318',
    city: 'Freienhagen'
  },
  {
    postalCode: '37318',
    city: 'Fretterode'
  },
  {
    postalCode: '37318',
    city: 'Gerbershausen'
  },
  {
    postalCode: '37318',
    city: 'Hohengandern'
  },
  {
    postalCode: '37318',
    city: 'Kirchgandern'
  },
  {
    postalCode: '37318',
    city: 'Lenterode'
  },
  {
    postalCode: '37318',
    city: 'Lindewerra'
  },
  {
    postalCode: '37318',
    city: 'Lutter'
  },
  {
    postalCode: '37318',
    city: 'Mackenrode'
  },
  {
    postalCode: '37318',
    city: 'Marth'
  },
  {
    postalCode: '37318',
    city: 'Rohrberg'
  },
  {
    postalCode: '37318',
    city: 'Röhrig'
  },
  {
    postalCode: '37318',
    city: 'Rustenfelde'
  },
  {
    postalCode: '37318',
    city: 'Schachtebich'
  },
  {
    postalCode: '37318',
    city: 'Schönhagen'
  },
  {
    postalCode: '37318',
    city: 'Schwobfeld'
  },
  {
    postalCode: '37318',
    city: 'Steinheuterode'
  },
  {
    postalCode: '37318',
    city: 'Thalwenden'
  },
  {
    postalCode: '37318',
    city: 'Uder'
  },
  {
    postalCode: '37318',
    city: 'Wahlhausen'
  },
  {
    postalCode: '37318',
    city: 'Wüstheuterode'
  },
  {
    postalCode: '37327',
    city: 'Beuren'
  },
  {
    postalCode: '37327',
    city: 'Birkungen'
  },
  {
    postalCode: '37327',
    city: 'Breitenbach'
  },
  {
    postalCode: '37327',
    city: 'Hausen'
  },
  {
    postalCode: '37327',
    city: 'Kallmerode'
  },
  {
    postalCode: '37327',
    city: 'Leinefelde'
  },
  {
    postalCode: '37327',
    city: 'Wingerode'
  },
  {
    postalCode: '37339',
    city: 'Berlingerode'
  },
  {
    postalCode: '37339',
    city: 'Bleckenrode'
  },
  {
    postalCode: '37339',
    city: 'Böseckendorf'
  },
  {
    postalCode: '37339',
    city: 'Brehme'
  },
  {
    postalCode: '37339',
    city: 'Breitenworbis'
  },
  {
    postalCode: '37339',
    city: 'Buchmühle'
  },
  {
    postalCode: '37339',
    city: 'Buhla'
  },
  {
    postalCode: '37339',
    city: 'Ecklingerode'
  },
  {
    postalCode: '37339',
    city: 'Ferna'
  },
  {
    postalCode: '37339',
    city: 'Gernrode'
  },
  {
    postalCode: '37339',
    city: 'Haynrode'
  },
  {
    postalCode: '37339',
    city: 'Hundeshagen'
  },
  {
    postalCode: '37339',
    city: 'Kaltohmfeld'
  },
  {
    postalCode: '37339',
    city: 'Kirchohmfeld'
  },
  {
    postalCode: '37339',
    city: 'Kirchworbis'
  },
  {
    postalCode: '37339',
    city: 'Neuendorf'
  },
  {
    postalCode: '37339',
    city: 'Tastungen'
  },
  {
    postalCode: '37339',
    city: 'Teistungen'
  },
  {
    postalCode: '37339',
    city: 'Wehnde'
  },
  {
    postalCode: '37339',
    city: 'Wintzingerode'
  },
  {
    postalCode: '37339',
    city: 'Worbis'
  },
  {
    postalCode: '37345',
    city: 'Bischofferode'
  },
  {
    postalCode: '37345',
    city: 'Bockelnhagen'
  },
  {
    postalCode: '37345',
    city: 'Großbodungen'
  },
  {
    postalCode: '37345',
    city: 'Holungen'
  },
  {
    postalCode: '37345',
    city: 'Jützenbach'
  },
  {
    postalCode: '37345',
    city: 'Neustadt'
  },
  {
    postalCode: '37345',
    city: 'Silkerode'
  },
  {
    postalCode: '37345',
    city: 'Steinrode'
  },
  {
    postalCode: '37345',
    city: 'Stöckey'
  },
  {
    postalCode: '37345',
    city: 'Weißenborn-Lüderode'
  },
  {
    postalCode: '37345',
    city: 'Zwinge'
  },
  {
    postalCode: '37351',
    city: 'Dingelstädt'
  },
  {
    postalCode: '37351',
    city: 'Helmsdorf'
  },
  {
    postalCode: '37351',
    city: 'Kefferhausen'
  },
  {
    postalCode: '37351',
    city: 'Kreuzebra'
  },
  {
    postalCode: '37351',
    city: 'Silberhausen'
  },
  {
    postalCode: '37355',
    city: 'Bernterode'
  },
  {
    postalCode: '37355',
    city: 'Deuna'
  },
  {
    postalCode: '37355',
    city: 'Gerterode'
  },
  {
    postalCode: '37355',
    city: 'Kleinbartloff'
  },
  {
    postalCode: '37355',
    city: 'Niederorschel'
  },
  {
    postalCode: '37355',
    city: 'Vollenborn'
  },
  {
    postalCode: '37359',
    city: 'Büttstedt'
  },
  {
    postalCode: '37359',
    city: 'Effelder'
  },
  {
    postalCode: '37359',
    city: 'Großbartloff'
  },
  {
    postalCode: '37359',
    city: 'Küllstedt'
  },
  {
    postalCode: '37359',
    city: 'Wachstedt'
  },
  {
    postalCode: '37412',
    city: 'Aschenhütte'
  },
  {
    postalCode: '37412',
    city: 'Elbingerode'
  },
  {
    postalCode: '37412',
    city: 'Herzberg'
  },
  {
    postalCode: '37412',
    city: 'Hörden'
  },
  {
    postalCode: '37431',
    city: 'Bad Lauterberg'
  },
  {
    postalCode: '37434',
    city: 'An der Rhumequelle'
  },
  {
    postalCode: '37434',
    city: 'Bilshausen'
  },
  {
    postalCode: '37434',
    city: 'Bodensee'
  },
  {
    postalCode: '37434',
    city: 'Gieboldehausen'
  },
  {
    postalCode: '37434',
    city: 'Krebeck'
  },
  {
    postalCode: '37434',
    city: 'Obernfeld'
  },
  {
    postalCode: '37434',
    city: 'Rhumspringe'
  },
  {
    postalCode: '37434',
    city: 'Rollshausen'
  },
  {
    postalCode: '37434',
    city: 'Rotenberg, Jagdhaus'
  },
  {
    postalCode: '37434',
    city: 'Rüdershausen'
  },
  {
    postalCode: '37434',
    city: 'Wollbrandshausen'
  },
  {
    postalCode: '37434',
    city: 'Wollershausen'
  },
  {
    postalCode: '37441',
    city: 'Bad Sachsa'
  },
  {
    postalCode: '37444',
    city: 'St. Andreasberg'
  },
  {
    postalCode: '37445',
    city: 'Walkenried'
  },
  {
    postalCode: '37447',
    city: 'Wieda'
  },
  {
    postalCode: '37449',
    city: 'Zorge'
  },
  {
    postalCode: '37520',
    city: 'Osterode'
  },
  {
    postalCode: '37534',
    city: 'Badenhausen'
  },
  {
    postalCode: '37534',
    city: 'Eisdorf'
  },
  {
    postalCode: '37534',
    city: 'Gittelde'
  },
  {
    postalCode: '37539',
    city: 'Bad Grund'
  },
  {
    postalCode: '37539',
    city: 'Windhausen'
  },
  {
    postalCode: '37547',
    city: 'Kreiensen'
  },
  {
    postalCode: '37574',
    city: 'Einbeck'
  },
  {
    postalCode: '37581',
    city: 'Bad Gandersheim'
  },
  {
    postalCode: '37586',
    city: 'Dassel'
  },
  {
    postalCode: '37589',
    city: 'Kalefeld'
  },
  {
    postalCode: '37603',
    city: 'Holzminden'
  },
  {
    postalCode: '37619',
    city: 'Bodenwerder'
  },
  {
    postalCode: '37619',
    city: 'Hehlen'
  },
  {
    postalCode: '37619',
    city: 'Heyen'
  },
  {
    postalCode: '37619',
    city: 'Kirchbrak'
  },
  {
    postalCode: '37619',
    city: 'Pegestorf'
  },
  {
    postalCode: '37620',
    city: 'Bremke'
  },
  {
    postalCode: '37620',
    city: 'Dohnsen'
  },
  {
    postalCode: '37620',
    city: 'Halle'
  },
  {
    postalCode: '37620',
    city: 'Hunzen'
  },
  {
    postalCode: '37620',
    city: 'Tuchtfeld'
  },
  {
    postalCode: '37620',
    city: 'Wegensen'
  },
  {
    postalCode: '37627',
    city: 'Arholzen'
  },
  {
    postalCode: '37627',
    city: 'Deensen'
  },
  {
    postalCode: '37627',
    city: 'Heinade'
  },
  {
    postalCode: '37627',
    city: 'Lenne'
  },
  {
    postalCode: '37627',
    city: 'Stadtoldendorf'
  },
  {
    postalCode: '37627',
    city: 'Wangelnstedt'
  },
  {
    postalCode: '37632',
    city: 'Eimen'
  },
  {
    postalCode: '37632',
    city: 'Eschershausen'
  },
  {
    postalCode: '37632',
    city: 'Holzen'
  },
  {
    postalCode: '37633',
    city: 'Dielmissen'
  },
  {
    postalCode: '37635',
    city: 'Lüerdissen'
  },
  {
    postalCode: '37639',
    city: 'Bevern'
  },
  {
    postalCode: '37639',
    city: 'Brille'
  },
  {
    postalCode: '37640',
    city: 'Golmbach'
  },
  {
    postalCode: '37642',
    city: 'Holenberg'
  },
  {
    postalCode: '37643',
    city: 'Negenborn'
  },
  {
    postalCode: '37647',
    city: 'Brevörde'
  },
  {
    postalCode: '37647',
    city: 'Polle'
  },
  {
    postalCode: '37647',
    city: 'Vahlbruch'
  },
  {
    postalCode: '37647',
    city: 'Wilmeröderberg'
  },
  {
    postalCode: '37649',
    city: 'Heinsen'
  },
  {
    postalCode: '37671',
    city: 'Höxter'
  },
  {
    postalCode: '37688',
    city: 'Beverungen'
  },
  {
    postalCode: '37691',
    city: 'Boffzen'
  },
  {
    postalCode: '37691',
    city: 'Derental'
  },
  {
    postalCode: '37696',
    city: 'Marienmünster'
  },
  {
    postalCode: '37697',
    city: 'Lauenförde'
  },
  {
    postalCode: '37699',
    city: 'Fürstenberg'
  },
  {
    postalCode: '38100',
    city: 'Braunschweig'
  },
  {
    postalCode: '38102',
    city: 'Braunschweig'
  },
  {
    postalCode: '38104',
    city: 'Braunschweig'
  },
  {
    postalCode: '38106',
    city: 'Braunschweig'
  },
  {
    postalCode: '38108',
    city: 'Braunschweig'
  },
  {
    postalCode: '38110',
    city: 'Braunschweig'
  },
  {
    postalCode: '38112',
    city: 'Braunschweig'
  },
  {
    postalCode: '38114',
    city: 'Braunschweig'
  },
  {
    postalCode: '38116',
    city: 'Braunschweig'
  },
  {
    postalCode: '38118',
    city: 'Braunschweig'
  },
  {
    postalCode: '38120',
    city: 'Braunschweig'
  },
  {
    postalCode: '38122',
    city: 'Braunschweig'
  },
  {
    postalCode: '38124',
    city: 'Braunschweig'
  },
  {
    postalCode: '38126',
    city: 'Braunschweig'
  },
  {
    postalCode: '38154',
    city: 'Brunsleberfeld'
  },
  {
    postalCode: '38154',
    city: 'Groß Rode'
  },
  {
    postalCode: '38154',
    city: 'Königslutter'
  },
  {
    postalCode: '38154',
    city: 'Tetzelstein'
  },
  {
    postalCode: '38154',
    city: 'Watzumer Häuschen'
  },
  {
    postalCode: '38154',
    city: 'Zur Schunterquelle'
  },
  {
    postalCode: '38159',
    city: 'Vechelde'
  },
  {
    postalCode: '38162',
    city: 'Cremlingen'
  },
  {
    postalCode: '38165',
    city: 'Lehre'
  },
  {
    postalCode: '38170',
    city: 'Dahlum'
  },
  {
    postalCode: '38170',
    city: 'Kneitlingen'
  },
  {
    postalCode: '38170',
    city: 'Schöppenstedt'
  },
  {
    postalCode: '38170',
    city: 'Uehrde'
  },
  {
    postalCode: '38170',
    city: 'Vahlberg'
  },
  {
    postalCode: '38170',
    city: 'Winnigstedt'
  },
  {
    postalCode: '38173',
    city: 'Dettum'
  },
  {
    postalCode: '38173',
    city: 'Erkerode'
  },
  {
    postalCode: '38173',
    city: 'Evessen'
  },
  {
    postalCode: '38173',
    city: 'Reitling'
  },
  {
    postalCode: '38173',
    city: 'Sickte'
  },
  {
    postalCode: '38173',
    city: 'Veltheim'
  },
  {
    postalCode: '38176',
    city: 'Wendeburg'
  },
  {
    postalCode: '38179',
    city: 'Schwülper'
  },
  {
    postalCode: '38226',
    city: 'Salzgitter'
  },
  {
    postalCode: '38228',
    city: 'Salzgitter'
  },
  {
    postalCode: '38229',
    city: 'Salzgitter'
  },
  {
    postalCode: '38239',
    city: 'Salzgitter'
  },
  {
    postalCode: '38259',
    city: 'Salzgitter'
  },
  {
    postalCode: '38268',
    city: 'Lengede'
  },
  {
    postalCode: '38271',
    city: 'Baddeckenstedt'
  },
  {
    postalCode: '38272',
    city: 'Burgdorf'
  },
  {
    postalCode: '38274',
    city: 'Elbe'
  },
  {
    postalCode: '38275',
    city: 'Haverlah'
  },
  {
    postalCode: '38277',
    city: 'Heere'
  },
  {
    postalCode: '38279',
    city: 'Sehlde'
  },
  {
    postalCode: '38300',
    city: 'Wolfenbüttel'
  },
  {
    postalCode: '38302',
    city: 'Wolfenbüttel'
  },
  {
    postalCode: '38304',
    city: 'Wolfenbüttel'
  },
  {
    postalCode: '38312',
    city: 'Achim'
  },
  {
    postalCode: '38312',
    city: 'Börßum'
  },
  {
    postalCode: '38312',
    city: 'Cramme'
  },
  {
    postalCode: '38312',
    city: 'Dorstadt'
  },
  {
    postalCode: '38312',
    city: 'Flöthe'
  },
  {
    postalCode: '38312',
    city: 'Heiningen'
  },
  {
    postalCode: '38312',
    city: 'Ohrum'
  },
  {
    postalCode: '38315',
    city: 'Gielde'
  },
  {
    postalCode: '38315',
    city: 'Hornburg'
  },
  {
    postalCode: '38315',
    city: 'Schladen'
  },
  {
    postalCode: '38315',
    city: 'Werlaburgdorf'
  },
  {
    postalCode: '38319',
    city: 'Remlingen'
  },
  {
    postalCode: '38321',
    city: 'Denkte'
  },
  {
    postalCode: '38322',
    city: 'Hedeper'
  },
  {
    postalCode: '38324',
    city: 'Kissenbrück'
  },
  {
    postalCode: '38325',
    city: 'Roklum'
  },
  {
    postalCode: '38327',
    city: 'Semmenstedt'
  },
  {
    postalCode: '38329',
    city: 'Wittmar'
  },
  {
    postalCode: '38350',
    city: 'Am Tekenberge'
  },
  {
    postalCode: '38350',
    city: 'Buschhaus'
  },
  {
    postalCode: '38350',
    city: 'Helmstedt'
  },
  {
    postalCode: '38350',
    city: 'Kißleberfeld'
  },
  {
    postalCode: '38350',
    city: 'Südschacht'
  },
  {
    postalCode: '38364',
    city: 'Schöningen'
  },
  {
    postalCode: '38368',
    city: 'Grasleben'
  },
  {
    postalCode: '38368',
    city: 'Mariental'
  },
  {
    postalCode: '38368',
    city: 'Querenhorst'
  },
  {
    postalCode: '38368',
    city: 'Rennau'
  },
  {
    postalCode: '38372',
    city: 'Büddenstedt'
  },
  {
    postalCode: '38373',
    city: 'Frellstedt'
  },
  {
    postalCode: '38373',
    city: 'Süpplingen'
  },
  {
    postalCode: '38375',
    city: 'Räbke'
  },
  {
    postalCode: '38376',
    city: 'Süpplingenburg'
  },
  {
    postalCode: '38378',
    city: 'Warberg'
  },
  {
    postalCode: '38379',
    city: 'Wolsdorf'
  },
  {
    postalCode: '38381',
    city: 'Jerxheim'
  },
  {
    postalCode: '38382',
    city: 'Beierstedt'
  },
  {
    postalCode: '38384',
    city: 'Gevensleben'
  },
  {
    postalCode: '38385',
    city: 'Ingeleben'
  },
  {
    postalCode: '38387',
    city: 'Söllingen'
  },
  {
    postalCode: '38388',
    city: 'Twieflingen'
  },
  {
    postalCode: '38440',
    city: 'Wolfsburg'
  },
  {
    postalCode: '38442',
    city: 'Wolfsburg'
  },
  {
    postalCode: '38444',
    city: 'Wolfsburg'
  },
  {
    postalCode: '38446',
    city: 'Wolfsburg'
  },
  {
    postalCode: '38448',
    city: 'Wolfsburg'
  },
  {
    postalCode: '38458',
    city: 'Velpke'
  },
  {
    postalCode: '38459',
    city: 'Bahrdorf'
  },
  {
    postalCode: '38461',
    city: 'Danndorf'
  },
  {
    postalCode: '38462',
    city: 'Grafhorst'
  },
  {
    postalCode: '38464',
    city: 'Groß Twülpstedt'
  },
  {
    postalCode: '38465',
    city: 'Brome'
  },
  {
    postalCode: '38467',
    city: 'Bergfeld'
  },
  {
    postalCode: '38468',
    city: 'Ehra-Lessien'
  },
  {
    postalCode: '38470',
    city: 'Parsau'
  },
  {
    postalCode: '38471',
    city: 'Rühen'
  },
  {
    postalCode: '38473',
    city: 'Tiddische'
  },
  {
    postalCode: '38474',
    city: 'Tülau'
  },
  {
    postalCode: '38476',
    city: 'Barwedel'
  },
  {
    postalCode: '38477',
    city: 'Jembke'
  },
  {
    postalCode: '38479',
    city: 'Tappenbeck'
  },
  {
    postalCode: '38486',
    city: 'Apenburg'
  },
  {
    postalCode: '38486',
    city: 'Bandau'
  },
  {
    postalCode: '38486',
    city: 'Dönitz'
  },
  {
    postalCode: '38486',
    city: 'Hohenhenningen'
  },
  {
    postalCode: '38486',
    city: 'Immekath'
  },
  {
    postalCode: '38486',
    city: 'Jahrstedt'
  },
  {
    postalCode: '38486',
    city: 'Klötze'
  },
  {
    postalCode: '38486',
    city: 'Kunrau'
  },
  {
    postalCode: '38486',
    city: 'Kusey'
  },
  {
    postalCode: '38486',
    city: 'Neuendorf'
  },
  {
    postalCode: '38486',
    city: 'Neuferchau'
  },
  {
    postalCode: '38486',
    city: 'Ristedt'
  },
  {
    postalCode: '38486',
    city: 'Röwitz'
  },
  {
    postalCode: '38486',
    city: 'Steimke'
  },
  {
    postalCode: '38486',
    city: 'Wenze'
  },
  {
    postalCode: '38489',
    city: 'Ahlum'
  },
  {
    postalCode: '38489',
    city: 'Beetzendorf'
  },
  {
    postalCode: '38489',
    city: 'Hanum'
  },
  {
    postalCode: '38489',
    city: 'Hohentramm'
  },
  {
    postalCode: '38489',
    city: 'Jeeben'
  },
  {
    postalCode: '38489',
    city: 'Jübar'
  },
  {
    postalCode: '38489',
    city: 'Lüdelsen'
  },
  {
    postalCode: '38489',
    city: 'Mellin'
  },
  {
    postalCode: '38489',
    city: 'Nettgau'
  },
  {
    postalCode: '38489',
    city: 'Rohrberg'
  },
  {
    postalCode: '38489',
    city: 'Tangeln'
  },
  {
    postalCode: '38518',
    city: 'Gifhorn'
  },
  {
    postalCode: '38524',
    city: 'Sassenburg'
  },
  {
    postalCode: '38527',
    city: 'Meine'
  },
  {
    postalCode: '38528',
    city: 'Adenbüttel'
  },
  {
    postalCode: '38530',
    city: 'Didderse'
  },
  {
    postalCode: '38531',
    city: 'Rötgesbüttel'
  },
  {
    postalCode: '38533',
    city: 'Vordorf'
  },
  {
    postalCode: '38536',
    city: 'Meinersen'
  },
  {
    postalCode: '38539',
    city: 'Müden'
  },
  {
    postalCode: '38542',
    city: 'Leiferde'
  },
  {
    postalCode: '38543',
    city: 'Hillerse'
  },
  {
    postalCode: '38547',
    city: 'Calberlah'
  },
  {
    postalCode: '38550',
    city: 'Isenbüttel'
  },
  {
    postalCode: '38551',
    city: 'Algesbüttel'
  },
  {
    postalCode: '38551',
    city: 'Ribbesbüttel'
  },
  {
    postalCode: '38553',
    city: 'Wasbüttel'
  },
  {
    postalCode: '38554',
    city: 'Weyhausen'
  },
  {
    postalCode: '38556',
    city: 'Bokensdorf'
  },
  {
    postalCode: '38557',
    city: 'Osloß'
  },
  {
    postalCode: '38559',
    city: 'Ringelah'
  },
  {
    postalCode: '38559',
    city: 'Wagenhoff'
  },
  {
    postalCode: '38640',
    city: 'Goslar'
  },
  {
    postalCode: '38642',
    city: 'Goslar'
  },
  {
    postalCode: '38644',
    city: 'Goslar'
  },
  {
    postalCode: '38667',
    city: 'Bad Harzburg'
  },
  {
    postalCode: '38667',
    city: 'Torfhaus'
  },
  {
    postalCode: '38678',
    city: 'Clausthal-Zellerfeld'
  },
  {
    postalCode: '38678',
    city: 'Oberschulenberg'
  },
  {
    postalCode: '38685',
    city: 'Langelsheim'
  },
  {
    postalCode: '38690',
    city: 'Vienenburg'
  },
  {
    postalCode: '38700',
    city: 'Braunlage'
  },
  {
    postalCode: '38704',
    city: 'Liebenburg'
  },
  {
    postalCode: '38707',
    city: 'Altenau'
  },
  {
    postalCode: '38707',
    city: 'Schulenberg'
  },
  {
    postalCode: '38709',
    city: 'Wildemann'
  },
  {
    postalCode: '38723',
    city: 'Seesen'
  },
  {
    postalCode: '38729',
    city: 'Hahausen'
  },
  {
    postalCode: '38729',
    city: 'Lutter'
  },
  {
    postalCode: '38729',
    city: 'Wallmoden'
  },
  {
    postalCode: '38820',
    city: 'Halberstadt'
  },
  {
    postalCode: '38822',
    city: 'Aspenstedt'
  },
  {
    postalCode: '38822',
    city: 'Athenstedt'
  },
  {
    postalCode: '38822',
    city: 'Emersleben'
  },
  {
    postalCode: '38822',
    city: 'Groß Quenstedt'
  },
  {
    postalCode: '38822',
    city: 'Klein Quenstedt'
  },
  {
    postalCode: '38822',
    city: 'Mahndorf'
  },
  {
    postalCode: '38822',
    city: 'Neu Runstedt'
  },
  {
    postalCode: '38822',
    city: 'Sargstedt'
  },
  {
    postalCode: '38822',
    city: 'Schachdorf Ströbeck'
  },
  {
    postalCode: '38822',
    city: 'Veltensmühle'
  },
  {
    postalCode: '38828',
    city: 'Rodersdorf'
  },
  {
    postalCode: '38828',
    city: 'Wegeleben'
  },
  {
    postalCode: '38829',
    city: 'Harsleben'
  },
  {
    postalCode: '38835',
    city: 'Berßel'
  },
  {
    postalCode: '38835',
    city: 'Bühne'
  },
  {
    postalCode: '38835',
    city: 'Deersheim'
  },
  {
    postalCode: '38835',
    city: 'Hessen'
  },
  {
    postalCode: '38835',
    city: 'Lüttgenrode'
  },
  {
    postalCode: '38835',
    city: 'Osterode'
  },
  {
    postalCode: '38835',
    city: 'Osterwieck'
  },
  {
    postalCode: '38835',
    city: 'Rhoden'
  },
  {
    postalCode: '38835',
    city: 'Schauen'
  },
  {
    postalCode: '38835',
    city: 'Veltheim'
  },
  {
    postalCode: '38835',
    city: 'Wülperode'
  },
  {
    postalCode: '38835',
    city: 'Zilly'
  },
  {
    postalCode: '38836',
    city: 'Anderbeck'
  },
  {
    postalCode: '38836',
    city: 'Badersleben'
  },
  {
    postalCode: '38836',
    city: 'Dardesheim'
  },
  {
    postalCode: '38836',
    city: 'Dedeleben'
  },
  {
    postalCode: '38836',
    city: 'Huy-Neinstedt'
  },
  {
    postalCode: '38836',
    city: 'Pabstorf'
  },
  {
    postalCode: '38836',
    city: 'Rohrsheim'
  },
  {
    postalCode: '38836',
    city: 'Vogelsdorf'
  },
  {
    postalCode: '38838',
    city: 'Aderstedt'
  },
  {
    postalCode: '38838',
    city: 'Dingelstedt'
  },
  {
    postalCode: '38838',
    city: 'Eilenstedt'
  },
  {
    postalCode: '38838',
    city: 'Eilsdorf'
  },
  {
    postalCode: '38838',
    city: 'Schlanstedt'
  },
  {
    postalCode: '38855',
    city: 'Danstedt'
  },
  {
    postalCode: '38855',
    city: 'Heudeber'
  },
  {
    postalCode: '38855',
    city: 'Reddeber'
  },
  {
    postalCode: '38855',
    city: 'Schmatzfeld'
  },
  {
    postalCode: '38855',
    city: 'Wernigerode'
  },
  {
    postalCode: '38871',
    city: 'Abbenrode'
  },
  {
    postalCode: '38871',
    city: 'Darlingerode'
  },
  {
    postalCode: '38871',
    city: 'Drübeck'
  },
  {
    postalCode: '38871',
    city: 'Ilsenburg'
  },
  {
    postalCode: '38871',
    city: 'Langeln'
  },
  {
    postalCode: '38871',
    city: 'Stapelburg'
  },
  {
    postalCode: '38871',
    city: 'Veckenstedt'
  },
  {
    postalCode: '38871',
    city: 'Wasserleben'
  },
  {
    postalCode: '38875',
    city: 'Drei Annen Hohne'
  },
  {
    postalCode: '38875',
    city: 'Elbingerode'
  },
  {
    postalCode: '38875',
    city: 'Elend'
  },
  {
    postalCode: '38875',
    city: 'Königshütte'
  },
  {
    postalCode: '38875',
    city: 'Sorge'
  },
  {
    postalCode: '38875',
    city: 'Tanne'
  },
  {
    postalCode: '38877',
    city: 'Benneckenstein'
  },
  {
    postalCode: '38879',
    city: 'Schierke'
  },
  {
    postalCode: '38889',
    city: 'Altenbrak'
  },
  {
    postalCode: '38889',
    city: 'Blankenburg'
  },
  {
    postalCode: '38889',
    city: 'Cattenstedt'
  },
  {
    postalCode: '38889',
    city: 'Heimburg'
  },
  {
    postalCode: '38889',
    city: 'Hüttenrode'
  },
  {
    postalCode: '38889',
    city: 'Rübeland'
  },
  {
    postalCode: '38889',
    city: 'Treseburg'
  },
  {
    postalCode: '38889',
    city: 'Wienrode'
  },
  {
    postalCode: '38895',
    city: 'Derenburg'
  },
  {
    postalCode: '38895',
    city: 'Langenstein'
  },
  {
    postalCode: '38899',
    city: 'Hasselfelde'
  },
  {
    postalCode: '38899',
    city: 'Stiege'
  },
  {
    postalCode: '38899',
    city: 'Trautenstein'
  },
  {
    postalCode: '39104',
    city: 'Magdeburg'
  },
  {
    postalCode: '39106',
    city: 'Magdeburg'
  },
  {
    postalCode: '39108',
    city: 'Magdeburg'
  },
  {
    postalCode: '39110',
    city: 'Magdeburg'
  },
  {
    postalCode: '39112',
    city: 'Magdeburg'
  },
  {
    postalCode: '39114',
    city: 'Magdeburg'
  },
  {
    postalCode: '39116',
    city: 'Magdeburg'
  },
  {
    postalCode: '39118',
    city: 'Magdeburg'
  },
  {
    postalCode: '39120',
    city: 'Magdeburg'
  },
  {
    postalCode: '39122',
    city: 'Magdeburg'
  },
  {
    postalCode: '39124',
    city: 'Magdeburg'
  },
  {
    postalCode: '39126',
    city: 'Magdeburg'
  },
  {
    postalCode: '39128',
    city: 'Magdeburg'
  },
  {
    postalCode: '39130',
    city: 'Magdeburg'
  },
  {
    postalCode: '39164',
    city: 'Bottmersdorf'
  },
  {
    postalCode: '39164',
    city: 'Domersleben'
  },
  {
    postalCode: '39164',
    city: 'Klein Wanzleben'
  },
  {
    postalCode: '39164',
    city: 'Remkersleben'
  },
  {
    postalCode: '39164',
    city: 'Wanzleben'
  },
  {
    postalCode: '39167',
    city: 'Eichenbarleben'
  },
  {
    postalCode: '39167',
    city: 'Groß Rodensleben'
  },
  {
    postalCode: '39167',
    city: 'Hohendodeleben'
  },
  {
    postalCode: '39167',
    city: 'Irxleben'
  },
  {
    postalCode: '39167',
    city: 'Klein Rodensleben'
  },
  {
    postalCode: '39167',
    city: 'Niederndodeleben'
  },
  {
    postalCode: '39167',
    city: 'Ochtmersleben'
  },
  {
    postalCode: '39167',
    city: 'Wellen'
  },
  {
    postalCode: '39171',
    city: 'Altenweddingen'
  },
  {
    postalCode: '39171',
    city: 'Bahrendorf'
  },
  {
    postalCode: '39171',
    city: 'Dodendorf'
  },
  {
    postalCode: '39171',
    city: 'Langenweddingen'
  },
  {
    postalCode: '39171',
    city: 'Osterweddingen'
  },
  {
    postalCode: '39171',
    city: 'Schwaneberg'
  },
  {
    postalCode: '39171',
    city: 'Sülldorf'
  },
  {
    postalCode: '39175',
    city: 'Biederitz'
  },
  {
    postalCode: '39175',
    city: 'Gerwisch'
  },
  {
    postalCode: '39175',
    city: 'Gübs'
  },
  {
    postalCode: '39175',
    city: 'Königsborn'
  },
  {
    postalCode: '39175',
    city: 'Körbelitz'
  },
  {
    postalCode: '39175',
    city: 'Menz'
  },
  {
    postalCode: '39175',
    city: 'Wahlitz'
  },
  {
    postalCode: '39175',
    city: 'Woltersdorf'
  },
  {
    postalCode: '39179',
    city: 'Barleben'
  },
  {
    postalCode: '39179',
    city: 'Ebendorf'
  },
  {
    postalCode: '39218',
    city: 'Schönebeck'
  },
  {
    postalCode: '39221',
    city: 'Biere'
  },
  {
    postalCode: '39221',
    city: 'Eggersdorf'
  },
  {
    postalCode: '39221',
    city: 'Eickendorf'
  },
  {
    postalCode: '39221',
    city: 'Großmühlingen'
  },
  {
    postalCode: '39221',
    city: 'Kleinmühlingen'
  },
  {
    postalCode: '39221',
    city: 'Ranies'
  },
  {
    postalCode: '39221',
    city: 'Welsleben'
  },
  {
    postalCode: '39221',
    city: 'Zens'
  },
  {
    postalCode: '39240',
    city: 'Breitenhagen'
  },
  {
    postalCode: '39240',
    city: 'Brumby'
  },
  {
    postalCode: '39240',
    city: 'Calbe'
  },
  {
    postalCode: '39240',
    city: 'Glöthe'
  },
  {
    postalCode: '39240',
    city: 'Groß Rosenburg'
  },
  {
    postalCode: '39240',
    city: 'Grube Alfred'
  },
  {
    postalCode: '39240',
    city: 'Lödderitz'
  },
  {
    postalCode: '39240',
    city: 'Sachsendorf'
  },
  {
    postalCode: '39240',
    city: 'Schwarz'
  },
  {
    postalCode: '39240',
    city: 'Trabitz'
  },
  {
    postalCode: '39240',
    city: 'Zuchau'
  },
  {
    postalCode: '39245',
    city: 'Dannigkow'
  },
  {
    postalCode: '39245',
    city: 'Gommern'
  },
  {
    postalCode: '39245',
    city: 'Plötzky'
  },
  {
    postalCode: '39245',
    city: 'Pretzien'
  },
  {
    postalCode: '39249',
    city: 'Barby'
  },
  {
    postalCode: '39249',
    city: 'Glinde'
  },
  {
    postalCode: '39249',
    city: 'Gnadau'
  },
  {
    postalCode: '39249',
    city: 'Pömmelte'
  },
  {
    postalCode: '39249',
    city: 'Tornitz'
  },
  {
    postalCode: '39249',
    city: 'Wespen'
  },
  {
    postalCode: '39261',
    city: 'Zerbst'
  },
  {
    postalCode: '39264',
    city: 'Bias'
  },
  {
    postalCode: '39264',
    city: 'Bornum'
  },
  {
    postalCode: '39264',
    city: 'Buhlendorf'
  },
  {
    postalCode: '39264',
    city: 'Deetz'
  },
  {
    postalCode: '39264',
    city: 'Dobritz'
  },
  {
    postalCode: '39264',
    city: 'Dornburg'
  },
  {
    postalCode: '39264',
    city: 'Gehrden'
  },
  {
    postalCode: '39264',
    city: 'Gödnitz'
  },
  {
    postalCode: '39264',
    city: 'Grimme'
  },
  {
    postalCode: '39264',
    city: 'Güterglück'
  },
  {
    postalCode: '39264',
    city: 'Hohenlepte'
  },
  {
    postalCode: '39264',
    city: 'Jütrichau'
  },
  {
    postalCode: '39264',
    city: 'Leps'
  },
  {
    postalCode: '39264',
    city: 'Lindau'
  },
  {
    postalCode: '39264',
    city: 'Lübs'
  },
  {
    postalCode: '39264',
    city: 'Luso'
  },
  {
    postalCode: '39264',
    city: 'Moritz'
  },
  {
    postalCode: '39264',
    city: 'Nedlitz'
  },
  {
    postalCode: '39264',
    city: 'Nutha'
  },
  {
    postalCode: '39264',
    city: 'Polenzko'
  },
  {
    postalCode: '39264',
    city: 'Prödel'
  },
  {
    postalCode: '39264',
    city: 'Pulspforde'
  },
  {
    postalCode: '39264',
    city: 'Reuden'
  },
  {
    postalCode: '39264',
    city: 'Steutz'
  },
  {
    postalCode: '39264',
    city: 'Straguth'
  },
  {
    postalCode: '39264',
    city: 'Walternienburg'
  },
  {
    postalCode: '39264',
    city: 'Zernitz'
  },
  {
    postalCode: '39279',
    city: 'Hobeck'
  },
  {
    postalCode: '39279',
    city: 'Ladeburg'
  },
  {
    postalCode: '39279',
    city: 'Leitzkau'
  },
  {
    postalCode: '39279',
    city: 'Loburg'
  },
  {
    postalCode: '39279',
    city: 'Rosian'
  },
  {
    postalCode: '39279',
    city: 'Schweinitz'
  },
  {
    postalCode: '39279',
    city: 'Zeppernick'
  },
  {
    postalCode: '39288',
    city: 'Burg'
  },
  {
    postalCode: '39291',
    city: 'Büden'
  },
  {
    postalCode: '39291',
    city: 'Detershagen'
  },
  {
    postalCode: '39291',
    city: 'Dörnitz'
  },
  {
    postalCode: '39291',
    city: 'Drewitz'
  },
  {
    postalCode: '39291',
    city: 'Friedensau'
  },
  {
    postalCode: '39291',
    city: 'Grabow'
  },
  {
    postalCode: '39291',
    city: 'Hohenwarthe'
  },
  {
    postalCode: '39291',
    city: 'Hohenziatz'
  },
  {
    postalCode: '39291',
    city: 'Ihleburg'
  },
  {
    postalCode: '39291',
    city: 'Karith'
  },
  {
    postalCode: '39291',
    city: 'Krüssau'
  },
  {
    postalCode: '39291',
    city: 'Küsel'
  },
  {
    postalCode: '39291',
    city: 'Lostau'
  },
  {
    postalCode: '39291',
    city: 'Lübars'
  },
  {
    postalCode: '39291',
    city: 'Magdeburgerforth'
  },
  {
    postalCode: '39291',
    city: 'Möckern'
  },
  {
    postalCode: '39291',
    city: 'Möser'
  },
  {
    postalCode: '39291',
    city: 'Nedlitz'
  },
  {
    postalCode: '39291',
    city: 'Niegripp'
  },
  {
    postalCode: '39291',
    city: 'Parchau'
  },
  {
    postalCode: '39291',
    city: 'Pietzpuhl'
  },
  {
    postalCode: '39291',
    city: 'Reesdorf'
  },
  {
    postalCode: '39291',
    city: 'Reesen'
  },
  {
    postalCode: '39291',
    city: 'Rietzel'
  },
  {
    postalCode: '39291',
    city: 'Ringelsdorf'
  },
  {
    postalCode: '39291',
    city: 'Schartau'
  },
  {
    postalCode: '39291',
    city: 'Schermen'
  },
  {
    postalCode: '39291',
    city: 'Schopsdorf'
  },
  {
    postalCode: '39291',
    city: 'Stegelitz'
  },
  {
    postalCode: '39291',
    city: 'Stresow'
  },
  {
    postalCode: '39291',
    city: 'Theeßen'
  },
  {
    postalCode: '39291',
    city: 'Tryppehna'
  },
  {
    postalCode: '39291',
    city: 'Vehlitz'
  },
  {
    postalCode: '39291',
    city: 'Wallwitz'
  },
  {
    postalCode: '39291',
    city: 'Wörmlitz'
  },
  {
    postalCode: '39291',
    city: 'Wüstenjerichow'
  },
  {
    postalCode: '39291',
    city: 'Zeddenick'
  },
  {
    postalCode: '39291',
    city: 'Ziepel'
  },
  {
    postalCode: '39307',
    city: 'Bergzow'
  },
  {
    postalCode: '39307',
    city: 'Brandenstein'
  },
  {
    postalCode: '39307',
    city: 'Brettin'
  },
  {
    postalCode: '39307',
    city: 'Demsin'
  },
  {
    postalCode: '39307',
    city: 'Genthin'
  },
  {
    postalCode: '39307',
    city: 'Gladau'
  },
  {
    postalCode: '39307',
    city: 'Hohenseeden'
  },
  {
    postalCode: '39307',
    city: 'Kade'
  },
  {
    postalCode: '39307',
    city: 'Karow'
  },
  {
    postalCode: '39307',
    city: 'Klitsche'
  },
  {
    postalCode: '39307',
    city: 'Mützel'
  },
  {
    postalCode: '39307',
    city: 'Paplitz'
  },
  {
    postalCode: '39307',
    city: 'Parchen'
  },
  {
    postalCode: '39307',
    city: 'Roßdorf'
  },
  {
    postalCode: '39307',
    city: 'Schlagenthin'
  },
  {
    postalCode: '39307',
    city: 'Tucheim'
  },
  {
    postalCode: '39307',
    city: 'Zabakuck'
  },
  {
    postalCode: '39317',
    city: 'Derben'
  },
  {
    postalCode: '39317',
    city: 'Ferchland'
  },
  {
    postalCode: '39317',
    city: 'Güsen'
  },
  {
    postalCode: '39317',
    city: 'Parey'
  },
  {
    postalCode: '39317',
    city: 'Zerben'
  },
  {
    postalCode: '39319',
    city: 'Jerichow'
  },
  {
    postalCode: '39319',
    city: 'Nielebock'
  },
  {
    postalCode: '39319',
    city: 'Redekin'
  },
  {
    postalCode: '39319',
    city: 'Wulkow'
  },
  {
    postalCode: '39326',
    city: 'Angern'
  },
  {
    postalCode: '39326',
    city: 'Colbitz'
  },
  {
    postalCode: '39326',
    city: 'Dahlenwarsleben'
  },
  {
    postalCode: '39326',
    city: 'Farsleben'
  },
  {
    postalCode: '39326',
    city: 'Glindenberg'
  },
  {
    postalCode: '39326',
    city: 'Groß Ammensleben'
  },
  {
    postalCode: '39326',
    city: 'Gutenswegen'
  },
  {
    postalCode: '39326',
    city: 'Heinrichsberg'
  },
  {
    postalCode: '39326',
    city: 'Hermsdorf'
  },
  {
    postalCode: '39326',
    city: 'Hohenwarsleben'
  },
  {
    postalCode: '39326',
    city: 'Jersleben'
  },
  {
    postalCode: '39326',
    city: 'Klein Ammensleben'
  },
  {
    postalCode: '39326',
    city: 'Loitsche'
  },
  {
    postalCode: '39326',
    city: 'Meitzendorf'
  },
  {
    postalCode: '39326',
    city: 'Meseberg'
  },
  {
    postalCode: '39326',
    city: 'Mose'
  },
  {
    postalCode: '39326',
    city: 'Rogätz'
  },
  {
    postalCode: '39326',
    city: 'Samswegen'
  },
  {
    postalCode: '39326',
    city: 'Wolmirstedt'
  },
  {
    postalCode: '39326',
    city: 'Zielitz'
  },
  {
    postalCode: '39340',
    city: 'Haldensleben'
  },
  {
    postalCode: '39340',
    city: 'Maschenhorst'
  },
  {
    postalCode: '39340',
    city: 'Zillbeck'
  },
  {
    postalCode: '39343',
    city: 'Ackendorf'
  },
  {
    postalCode: '39343',
    city: 'Alleringersleben'
  },
  {
    postalCode: '39343',
    city: 'Altenhausen'
  },
  {
    postalCode: '39343',
    city: 'Bartensleben'
  },
  {
    postalCode: '39343',
    city: 'Bebertal'
  },
  {
    postalCode: '39343',
    city: 'Beendorf'
  },
  {
    postalCode: '39343',
    city: 'Bornstedt'
  },
  {
    postalCode: '39343',
    city: 'Bregenstedt'
  },
  {
    postalCode: '39343',
    city: 'Eimersleben'
  },
  {
    postalCode: '39343',
    city: 'Emden'
  },
  {
    postalCode: '39343',
    city: 'Erxleben'
  },
  {
    postalCode: '39343',
    city: 'Groß Santersleben'
  },
  {
    postalCode: '39343',
    city: 'Hakenstedt'
  },
  {
    postalCode: '39343',
    city: 'Hillersleben'
  },
  {
    postalCode: '39343',
    city: 'Hundisburg'
  },
  {
    postalCode: '39343',
    city: 'Ivenrode'
  },
  {
    postalCode: '39343',
    city: 'Morsleben'
  },
  {
    postalCode: '39343',
    city: 'Nordgermersleben'
  },
  {
    postalCode: '39343',
    city: 'Ostingersleben'
  },
  {
    postalCode: '39343',
    city: 'Rottmersleben'
  },
  {
    postalCode: '39343',
    city: 'Schackensleben'
  },
  {
    postalCode: '39343',
    city: 'Schwanefeld'
  },
  {
    postalCode: '39343',
    city: 'Süplingen'
  },
  {
    postalCode: '39343',
    city: 'Uhrsleben'
  },
  {
    postalCode: '39345',
    city: 'Born'
  },
  {
    postalCode: '39345',
    city: 'Bülstringen'
  },
  {
    postalCode: '39345',
    city: 'Detzel Gut'
  },
  {
    postalCode: '39345',
    city: 'Detzel Schloß'
  },
  {
    postalCode: '39345',
    city: 'Flechtingen'
  },
  {
    postalCode: '39345',
    city: 'Hütten'
  },
  {
    postalCode: '39345',
    city: 'Keindorf'
  },
  {
    postalCode: '39345',
    city: 'Neuenhofe'
  },
  {
    postalCode: '39345',
    city: 'Planken'
  },
  {
    postalCode: '39345',
    city: 'Satuelle'
  },
  {
    postalCode: '39345',
    city: 'Uthmöden'
  },
  {
    postalCode: '39345',
    city: 'Vahldorf'
  },
  {
    postalCode: '39345',
    city: 'Wedringen'
  },
  {
    postalCode: '39345',
    city: 'Wieglitz'
  },
  {
    postalCode: '39356',
    city: 'Behnsdorf'
  },
  {
    postalCode: '39356',
    city: 'Belsdorf'
  },
  {
    postalCode: '39356',
    city: 'Döhren'
  },
  {
    postalCode: '39356',
    city: 'Eschenrode'
  },
  {
    postalCode: '39356',
    city: 'Hödingen'
  },
  {
    postalCode: '39356',
    city: 'Hörsingen'
  },
  {
    postalCode: '39356',
    city: 'Seggerde'
  },
  {
    postalCode: '39356',
    city: 'Siestedt'
  },
  {
    postalCode: '39356',
    city: 'Walbeck'
  },
  {
    postalCode: '39356',
    city: 'Weferlingen'
  },
  {
    postalCode: '39359',
    city: 'Böddensell'
  },
  {
    postalCode: '39359',
    city: 'Bösdorf'
  },
  {
    postalCode: '39359',
    city: 'Calvörde'
  },
  {
    postalCode: '39359',
    city: 'Eickendorf'
  },
  {
    postalCode: '39359',
    city: 'Etingen'
  },
  {
    postalCode: '39359',
    city: 'Everingen'
  },
  {
    postalCode: '39359',
    city: 'Gehrendorf'
  },
  {
    postalCode: '39359',
    city: 'Grauingen'
  },
  {
    postalCode: '39359',
    city: 'Kathendorf'
  },
  {
    postalCode: '39359',
    city: 'Lockstedt'
  },
  {
    postalCode: '39359',
    city: 'Mannhausen'
  },
  {
    postalCode: '39359',
    city: 'Rätzlingen'
  },
  {
    postalCode: '39359',
    city: 'Velsdorf'
  },
  {
    postalCode: '39359',
    city: 'Wegenstedt'
  },
  {
    postalCode: '39365',
    city: 'Drackenstedt'
  },
  {
    postalCode: '39365',
    city: 'Dreileben'
  },
  {
    postalCode: '39365',
    city: 'Druxberge'
  },
  {
    postalCode: '39365',
    city: 'Eggenstedt'
  },
  {
    postalCode: '39365',
    city: 'Eilsleben'
  },
  {
    postalCode: '39365',
    city: 'Harbke'
  },
  {
    postalCode: '39365',
    city: 'Marienborn'
  },
  {
    postalCode: '39365',
    city: 'Ovelgünne'
  },
  {
    postalCode: '39365',
    city: 'Seehausen'
  },
  {
    postalCode: '39365',
    city: 'Sommersdorf'
  },
  {
    postalCode: '39365',
    city: 'Ummendorf'
  },
  {
    postalCode: '39365',
    city: 'Wefensleben'
  },
  {
    postalCode: '39365',
    city: 'Wormsdorf'
  },
  {
    postalCode: '39387',
    city: 'Altbrandsleben'
  },
  {
    postalCode: '39387',
    city: 'Ampfurth'
  },
  {
    postalCode: '39387',
    city: 'Großalsleben'
  },
  {
    postalCode: '39387',
    city: 'Hordorf'
  },
  {
    postalCode: '39387',
    city: 'Hornhausen'
  },
  {
    postalCode: '39387',
    city: 'Krottorf'
  },
  {
    postalCode: '39387',
    city: 'Neindorf'
  },
  {
    postalCode: '39387',
    city: 'Neuwegersleben'
  },
  {
    postalCode: '39387',
    city: 'Oschersleben'
  },
  {
    postalCode: '39387',
    city: 'Schermcke'
  },
  {
    postalCode: '39387',
    city: 'Wulferstedt'
  },
  {
    postalCode: '39393',
    city: 'Ausleben'
  },
  {
    postalCode: '39393',
    city: 'Barneberg'
  },
  {
    postalCode: '39393',
    city: 'Beckendorf'
  },
  {
    postalCode: '39393',
    city: 'Gunsleben'
  },
  {
    postalCode: '39393',
    city: 'Hamersleben'
  },
  {
    postalCode: '39393',
    city: 'Hötensleben'
  },
  {
    postalCode: '39393',
    city: 'Ohrsleben'
  },
  {
    postalCode: '39393',
    city: 'Völpke'
  },
  {
    postalCode: '39393',
    city: 'Wackersleben'
  },
  {
    postalCode: '39397',
    city: 'Gröningen'
  },
  {
    postalCode: '39397',
    city: 'Kroppenstedt'
  },
  {
    postalCode: '39397',
    city: 'Nienhagen'
  },
  {
    postalCode: '39397',
    city: 'Schwanebeck'
  },
  {
    postalCode: '39398',
    city: 'Alikendorf'
  },
  {
    postalCode: '39398',
    city: 'Groß Germersleben'
  },
  {
    postalCode: '39398',
    city: 'Hadmersleben'
  },
  {
    postalCode: '39398',
    city: 'Klein Oschersleben'
  },
  {
    postalCode: '39398',
    city: 'Kleinalsleben'
  },
  {
    postalCode: '39398',
    city: 'Peseckendorf'
  },
  {
    postalCode: '39418',
    city: 'Lust'
  },
  {
    postalCode: '39418',
    city: 'Neu Staßfurt'
  },
  {
    postalCode: '39418',
    city: 'Neundorf (Anhalt)'
  },
  {
    postalCode: '39418',
    city: 'Rathmannsdorf'
  },
  {
    postalCode: '39418',
    city: 'Staßfurt'
  },
  {
    postalCode: '39435',
    city: 'Borne'
  },
  {
    postalCode: '39435',
    city: 'Egeln'
  },
  {
    postalCode: '39435',
    city: 'Groß Börnecke'
  },
  {
    postalCode: '39435',
    city: 'Schneidlingen'
  },
  {
    postalCode: '39435',
    city: 'Tarthun'
  },
  {
    postalCode: '39435',
    city: 'Unseburg'
  },
  {
    postalCode: '39435',
    city: 'Wolmirsleben'
  },
  {
    postalCode: '39439',
    city: 'Amesdorf'
  },
  {
    postalCode: '39439',
    city: 'Güsten'
  },
  {
    postalCode: '39439',
    city: 'Rathmannsdorf'
  },
  {
    postalCode: '39443',
    city: 'Atzendorf'
  },
  {
    postalCode: '39443',
    city: 'Brumby'
  },
  {
    postalCode: '39443',
    city: 'Förderstedt'
  },
  {
    postalCode: '39443',
    city: 'Glöthe'
  },
  {
    postalCode: '39443',
    city: 'Hohenerxleben'
  },
  {
    postalCode: '39443',
    city: 'Löbnitz (Bode)'
  },
  {
    postalCode: '39443',
    city: 'Staßfurt'
  },
  {
    postalCode: '39443',
    city: 'Üllnitz'
  },
  {
    postalCode: '39444',
    city: 'Hecklingen'
  },
  {
    postalCode: '39446',
    city: 'Athensleben'
  },
  {
    postalCode: '39446',
    city: 'Löderburg'
  },
  {
    postalCode: '39446',
    city: 'Rothenförde'
  },
  {
    postalCode: '39446',
    city: 'Staßfurt'
  },
  {
    postalCode: '39448',
    city: 'Etgersleben'
  },
  {
    postalCode: '39448',
    city: 'Hakeborn'
  },
  {
    postalCode: '39448',
    city: 'Westeregeln'
  },
  {
    postalCode: '39517',
    city: 'Bertingen'
  },
  {
    postalCode: '39517',
    city: 'Birkholz'
  },
  {
    postalCode: '39517',
    city: 'Bittkau'
  },
  {
    postalCode: '39517',
    city: 'Bölsdorf'
  },
  {
    postalCode: '39517',
    city: 'Brunkau'
  },
  {
    postalCode: '39517',
    city: 'Buch'
  },
  {
    postalCode: '39517',
    city: 'Burgstall'
  },
  {
    postalCode: '39517',
    city: 'Cobbel'
  },
  {
    postalCode: '39517',
    city: 'Cröchern'
  },
  {
    postalCode: '39517',
    city: 'Dolle'
  },
  {
    postalCode: '39517',
    city: 'Grieben'
  },
  {
    postalCode: '39517',
    city: 'Jerchel'
  },
  {
    postalCode: '39517',
    city: 'Kehnert'
  },
  {
    postalCode: '39517',
    city: 'Klein Schwarzlosen'
  },
  {
    postalCode: '39517',
    city: 'Lüderitz'
  },
  {
    postalCode: '39517',
    city: 'Mahlwinkel'
  },
  {
    postalCode: '39517',
    city: 'Ringfurth'
  },
  {
    postalCode: '39517',
    city: 'Sandbeiendorf'
  },
  {
    postalCode: '39517',
    city: 'Schelldorf'
  },
  {
    postalCode: '39517',
    city: 'Schernebeck'
  },
  {
    postalCode: '39517',
    city: 'Schönwalde'
  },
  {
    postalCode: '39517',
    city: 'Tangerhütte'
  },
  {
    postalCode: '39517',
    city: 'Uchtdorf'
  },
  {
    postalCode: '39517',
    city: 'Uetz'
  },
  {
    postalCode: '39517',
    city: 'Weißewarte'
  },
  {
    postalCode: '39517',
    city: 'Wenddorf'
  },
  {
    postalCode: '39524',
    city: 'Fischbeck'
  },
  {
    postalCode: '39524',
    city: 'Garz'
  },
  {
    postalCode: '39524',
    city: 'Hohengöhren'
  },
  {
    postalCode: '39524',
    city: 'Jederitz'
  },
  {
    postalCode: '39524',
    city: 'Kamern'
  },
  {
    postalCode: '39524',
    city: 'Klietz'
  },
  {
    postalCode: '39524',
    city: 'Kuhlhausen'
  },
  {
    postalCode: '39524',
    city: 'Mangelsdorf'
  },
  {
    postalCode: '39524',
    city: 'Neuermark-Lübars'
  },
  {
    postalCode: '39524',
    city: 'Sandau'
  },
  {
    postalCode: '39524',
    city: 'Schönfeld'
  },
  {
    postalCode: '39524',
    city: 'Schönhausen'
  },
  {
    postalCode: '39524',
    city: 'Warnau'
  },
  {
    postalCode: '39524',
    city: 'Wulkau'
  },
  {
    postalCode: '39524',
    city: 'Wust'
  },
  {
    postalCode: '39539',
    city: 'Damerow'
  },
  {
    postalCode: '39539',
    city: 'Havelberg'
  },
  {
    postalCode: '39539',
    city: 'Kümmernitz'
  },
  {
    postalCode: '39539',
    city: 'Neuwerben'
  },
  {
    postalCode: '39539',
    city: 'Nitzow'
  },
  {
    postalCode: '39539',
    city: 'Vehlgast'
  },
  {
    postalCode: '39539',
    city: 'Waldfrieden'
  },
  {
    postalCode: '39576',
    city: 'Stendal'
  },
  {
    postalCode: '39579',
    city: 'Badingen'
  },
  {
    postalCode: '39579',
    city: 'Bellingen'
  },
  {
    postalCode: '39579',
    city: 'Buchholz'
  },
  {
    postalCode: '39579',
    city: 'Dahlen'
  },
  {
    postalCode: '39579',
    city: 'Demker'
  },
  {
    postalCode: '39579',
    city: 'Garlipp'
  },
  {
    postalCode: '39579',
    city: 'Grassau'
  },
  {
    postalCode: '39579',
    city: 'Grobleben'
  },
  {
    postalCode: '39579',
    city: 'Groß Schwechten'
  },
  {
    postalCode: '39579',
    city: 'Hüselitz'
  },
  {
    postalCode: '39579',
    city: 'Kläden'
  },
  {
    postalCode: '39579',
    city: 'Klein Schwechten'
  },
  {
    postalCode: '39579',
    city: 'Möllendorf'
  },
  {
    postalCode: '39579',
    city: 'Petersmark'
  },
  {
    postalCode: '39579',
    city: 'Querstedt'
  },
  {
    postalCode: '39579',
    city: 'Rochau'
  },
  {
    postalCode: '39579',
    city: 'Schäplitz'
  },
  {
    postalCode: '39579',
    city: 'Schernikau'
  },
  {
    postalCode: '39579',
    city: 'Schinne'
  },
  {
    postalCode: '39579',
    city: 'Uenglingen'
  },
  {
    postalCode: '39579',
    city: 'Windberge'
  },
  {
    postalCode: '39579',
    city: 'Wittenmoor'
  },
  {
    postalCode: '39590',
    city: 'Bindfelde'
  },
  {
    postalCode: '39590',
    city: 'Hämerten'
  },
  {
    postalCode: '39590',
    city: 'Heeren'
  },
  {
    postalCode: '39590',
    city: 'Langensalzwedel'
  },
  {
    postalCode: '39590',
    city: 'Miltern'
  },
  {
    postalCode: '39590',
    city: 'Storkau'
  },
  {
    postalCode: '39590',
    city: 'Tangermünde'
  },
  {
    postalCode: '39596',
    city: 'Altenzaun'
  },
  {
    postalCode: '39596',
    city: 'Arneburg'
  },
  {
    postalCode: '39596',
    city: 'Baben'
  },
  {
    postalCode: '39596',
    city: 'Beelitz'
  },
  {
    postalCode: '39596',
    city: 'Bertkow'
  },
  {
    postalCode: '39596',
    city: 'Eichstedt'
  },
  {
    postalCode: '39596',
    city: 'Goldbeck'
  },
  {
    postalCode: '39596',
    city: 'Hassel'
  },
  {
    postalCode: '39596',
    city: 'Hindenburg'
  },
  {
    postalCode: '39596',
    city: 'Hohenberg-Krusemark'
  },
  {
    postalCode: '39596',
    city: 'Jarchau'
  },
  {
    postalCode: '39596',
    city: 'Lindtorf'
  },
  {
    postalCode: '39596',
    city: 'Sanne'
  },
  {
    postalCode: '39596',
    city: 'Schwarzholz'
  },
  {
    postalCode: '39596',
    city: 'Staffelde'
  },
  {
    postalCode: '39599',
    city: 'Deetz'
  },
  {
    postalCode: '39599',
    city: 'Insel'
  },
  {
    postalCode: '39599',
    city: 'Käthen'
  },
  {
    postalCode: '39599',
    city: 'Klinke'
  },
  {
    postalCode: '39599',
    city: 'Möringen'
  },
  {
    postalCode: '39599',
    city: 'Nahrstedt'
  },
  {
    postalCode: '39599',
    city: 'Staats'
  },
  {
    postalCode: '39599',
    city: 'Steinfeld'
  },
  {
    postalCode: '39599',
    city: 'Uchtspringe'
  },
  {
    postalCode: '39599',
    city: 'Vinzelberg'
  },
  {
    postalCode: '39599',
    city: 'Volgfelde'
  },
  {
    postalCode: '39606',
    city: 'Ballerstedt'
  },
  {
    postalCode: '39606',
    city: 'Behrendorf'
  },
  {
    postalCode: '39606',
    city: 'Boock'
  },
  {
    postalCode: '39606',
    city: 'Bretsch'
  },
  {
    postalCode: '39606',
    city: 'Dobberkau'
  },
  {
    postalCode: '39606',
    city: 'Dobbrun'
  },
  {
    postalCode: '39606',
    city: 'Düsedau'
  },
  {
    postalCode: '39606',
    city: 'Erxleben'
  },
  {
    postalCode: '39606',
    city: 'Flessau'
  },
  {
    postalCode: '39606',
    city: 'Gagel'
  },
  {
    postalCode: '39606',
    city: 'Gladigau'
  },
  {
    postalCode: '39606',
    city: 'Heiligenfelde'
  },
  {
    postalCode: '39606',
    city: 'Hohenwulsch'
  },
  {
    postalCode: '39606',
    city: 'Iden'
  },
  {
    postalCode: '39606',
    city: 'Kleinau'
  },
  {
    postalCode: '39606',
    city: 'Königsmark'
  },
  {
    postalCode: '39606',
    city: 'Kossebau'
  },
  {
    postalCode: '39606',
    city: 'Krevese'
  },
  {
    postalCode: '39606',
    city: 'Lückstedt'
  },
  {
    postalCode: '39606',
    city: 'Meseberg'
  },
  {
    postalCode: '39606',
    city: 'Osterburg'
  },
  {
    postalCode: '39606',
    city: 'Rossau'
  },
  {
    postalCode: '39606',
    city: 'Sandauerholz'
  },
  {
    postalCode: '39606',
    city: 'Sanne-Kerkuhn'
  },
  {
    postalCode: '39606',
    city: 'Schorstedt'
  },
  {
    postalCode: '39606',
    city: 'Walsleben'
  },
  {
    postalCode: '39615',
    city: 'Aulosen'
  },
  {
    postalCode: '39615',
    city: 'Beuster'
  },
  {
    postalCode: '39615',
    city: 'Drüsedau'
  },
  {
    postalCode: '39615',
    city: 'Falkenberg'
  },
  {
    postalCode: '39615',
    city: 'Geestgottberg'
  },
  {
    postalCode: '39615',
    city: 'Gollensdorf'
  },
  {
    postalCode: '39615',
    city: 'Groß Garz'
  },
  {
    postalCode: '39615',
    city: 'Höwisch'
  },
  {
    postalCode: '39615',
    city: 'Krüden'
  },
  {
    postalCode: '39615',
    city: 'Leppin'
  },
  {
    postalCode: '39615',
    city: 'Lichterfelde'
  },
  {
    postalCode: '39615',
    city: 'Losenrade'
  },
  {
    postalCode: '39615',
    city: 'Losse'
  },
  {
    postalCode: '39615',
    city: 'Neukirchen'
  },
  {
    postalCode: '39615',
    city: 'Neulingen'
  },
  {
    postalCode: '39615',
    city: 'Pollitz'
  },
  {
    postalCode: '39615',
    city: 'Schönberg'
  },
  {
    postalCode: '39615',
    city: 'Seehausen'
  },
  {
    postalCode: '39615',
    city: 'Wahrenberg'
  },
  {
    postalCode: '39615',
    city: 'Wanzer'
  },
  {
    postalCode: '39615',
    city: 'Wendemark'
  },
  {
    postalCode: '39615',
    city: 'Werben'
  },
  {
    postalCode: '39619',
    city: 'Arendsee'
  },
  {
    postalCode: '39619',
    city: 'Kläden'
  },
  {
    postalCode: '39619',
    city: 'Schrampe'
  },
  {
    postalCode: '39619',
    city: 'Thielbeer'
  },
  {
    postalCode: '39619',
    city: 'Ziemendorf'
  },
  {
    postalCode: '39624',
    city: 'Altmersleben'
  },
  {
    postalCode: '39624',
    city: 'Badel'
  },
  {
    postalCode: '39624',
    city: 'Berkau'
  },
  {
    postalCode: '39624',
    city: 'Brunau'
  },
  {
    postalCode: '39624',
    city: 'Büste'
  },
  {
    postalCode: '39624',
    city: 'Güssefeld'
  },
  {
    postalCode: '39624',
    city: 'Jeetze'
  },
  {
    postalCode: '39624',
    city: 'Kahrstedt'
  },
  {
    postalCode: '39624',
    city: 'Kakerbeck'
  },
  {
    postalCode: '39624',
    city: 'Kalbe'
  },
  {
    postalCode: '39624',
    city: 'Kremkau'
  },
  {
    postalCode: '39624',
    city: 'Meßdorf'
  },
  {
    postalCode: '39624',
    city: 'Neuendorf am Damm'
  },
  {
    postalCode: '39624',
    city: 'Packebusch'
  },
  {
    postalCode: '39624',
    city: 'Vienau'
  },
  {
    postalCode: '39624',
    city: 'Wernstedt'
  },
  {
    postalCode: '39624',
    city: 'Winkelstedt'
  },
  {
    postalCode: '39624',
    city: 'Zethlingen'
  },
  {
    postalCode: '39629',
    city: 'Bismark'
  },
  {
    postalCode: '39629',
    city: 'Holzhausen'
  },
  {
    postalCode: '39629',
    city: 'Könnigde'
  },
  {
    postalCode: '39638',
    city: 'Algenstedt'
  },
  {
    postalCode: '39638',
    city: 'Berenbrock'
  },
  {
    postalCode: '39638',
    city: 'Berge'
  },
  {
    postalCode: '39638',
    city: 'Breitenfeld'
  },
  {
    postalCode: '39638',
    city: 'Dorst'
  },
  {
    postalCode: '39638',
    city: 'Engersen'
  },
  {
    postalCode: '39638',
    city: 'Estedt'
  },
  {
    postalCode: '39638',
    city: 'Gardelegen'
  },
  {
    postalCode: '39638',
    city: 'Hemstedt'
  },
  {
    postalCode: '39638',
    city: 'Hottendorf'
  },
  {
    postalCode: '39638',
    city: 'Jävenitz'
  },
  {
    postalCode: '39638',
    city: 'Jerchel'
  },
  {
    postalCode: '39638',
    city: 'Jeseritz'
  },
  {
    postalCode: '39638',
    city: 'Kassieck'
  },
  {
    postalCode: '39638',
    city: 'Kloster Neuendorf'
  },
  {
    postalCode: '39638',
    city: 'Klüden'
  },
  {
    postalCode: '39638',
    city: 'Letzlingen'
  },
  {
    postalCode: '39638',
    city: 'Lindstedt'
  },
  {
    postalCode: '39638',
    city: 'Potzehne'
  },
  {
    postalCode: '39638',
    city: 'Roxförde'
  },
  {
    postalCode: '39638',
    city: 'Schenkenhorst'
  },
  {
    postalCode: '39638',
    city: 'Schwiesau'
  },
  {
    postalCode: '39638',
    city: 'Seethen'
  },
  {
    postalCode: '39638',
    city: 'Solpke'
  },
  {
    postalCode: '39638',
    city: 'Wannefeld'
  },
  {
    postalCode: '39638',
    city: 'Wiepke'
  },
  {
    postalCode: '39638',
    city: 'Zichtau'
  },
  {
    postalCode: '39638',
    city: 'Zobbenitz'
  },
  {
    postalCode: '39646',
    city: 'Kahnstieg'
  },
  {
    postalCode: '39646',
    city: 'Oebisfelde'
  },
  {
    postalCode: '39646',
    city: 'Taterberg'
  },
  {
    postalCode: '39649',
    city: 'Dannefeld'
  },
  {
    postalCode: '39649',
    city: 'Jeggau'
  },
  {
    postalCode: '39649',
    city: 'Köckte'
  },
  {
    postalCode: '39649',
    city: 'Mieste'
  },
  {
    postalCode: '39649',
    city: 'Miesterhorst'
  },
  {
    postalCode: '39649',
    city: 'Peckfitz'
  },
  {
    postalCode: '39649',
    city: 'Sachau'
  },
  {
    postalCode: '39649',
    city: 'Sichau'
  },
  {
    postalCode: '39649',
    city: 'Trippigleben'
  },
  {
    postalCode: '40210',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40211',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40212',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40213',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40215',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40217',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40219',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40221',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40223',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40225',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40227',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40229',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40231',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40233',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40235',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40237',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40239',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40468',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40470',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40472',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40472',
    city: 'Ratingen'
  },
  {
    postalCode: '40474',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40476',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40477',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40479',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40489',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40545',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40547',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40549',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40589',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40591',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40593',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40595',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40597',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40599',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40625',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40627',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40629',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40667',
    city: 'Meerbusch'
  },
  {
    postalCode: '40668',
    city: 'Meerbusch'
  },
  {
    postalCode: '40670',
    city: 'Meerbusch'
  },
  {
    postalCode: '40699',
    city: 'Erkrath'
  },
  {
    postalCode: '40721',
    city: 'Düsseldorf'
  },
  {
    postalCode: '40721',
    city: 'Hilden'
  },
  {
    postalCode: '40723',
    city: 'Hilden'
  },
  {
    postalCode: '40724',
    city: 'Hilden'
  },
  {
    postalCode: '40764',
    city: 'Langenfeld'
  },
  {
    postalCode: '40789',
    city: 'Monheim'
  },
  {
    postalCode: '40822',
    city: 'Mettmann'
  },
  {
    postalCode: '40878',
    city: 'Ratingen'
  },
  {
    postalCode: '40880',
    city: 'Ratingen'
  },
  {
    postalCode: '40882',
    city: 'Ratingen'
  },
  {
    postalCode: '40883',
    city: 'Ratingen'
  },
  {
    postalCode: '40885',
    city: 'Ratingen'
  },
  {
    postalCode: '41061',
    city: 'Mönchengladbach'
  },
  {
    postalCode: '41063',
    city: 'Mönchengladbach'
  },
  {
    postalCode: '41065',
    city: 'Mönchengladbach'
  },
  {
    postalCode: '41066',
    city: 'Mönchengladbach'
  },
  {
    postalCode: '41068',
    city: 'Mönchengladbach'
  },
  {
    postalCode: '41069',
    city: 'Mönchengladbach'
  },
  {
    postalCode: '41169',
    city: 'Mönchengladbach'
  },
  {
    postalCode: '41179',
    city: 'Mönchengladbach'
  },
  {
    postalCode: '41189',
    city: 'Mönchengladbach'
  },
  {
    postalCode: '41199',
    city: 'Mönchengladbach'
  },
  {
    postalCode: '41236',
    city: 'Mönchengladbach'
  },
  {
    postalCode: '41238',
    city: 'Mönchengladbach'
  },
  {
    postalCode: '41239',
    city: 'Mönchengladbach'
  },
  {
    postalCode: '41334',
    city: 'Nettetal'
  },
  {
    postalCode: '41352',
    city: 'Korschenbroich'
  },
  {
    postalCode: '41363',
    city: 'Jüchen'
  },
  {
    postalCode: '41366',
    city: 'Schwalmtal'
  },
  {
    postalCode: '41372',
    city: 'Niederkrüchten'
  },
  {
    postalCode: '41379',
    city: 'Brüggen'
  },
  {
    postalCode: '41460',
    city: 'Neuss'
  },
  {
    postalCode: '41462',
    city: 'Neuss'
  },
  {
    postalCode: '41464',
    city: 'Neuss'
  },
  {
    postalCode: '41466',
    city: 'Neuss'
  },
  {
    postalCode: '41468',
    city: 'Neuss'
  },
  {
    postalCode: '41469',
    city: 'Neuss'
  },
  {
    postalCode: '41470',
    city: 'Neuss'
  },
  {
    postalCode: '41472',
    city: 'Neuss'
  },
  {
    postalCode: '41515',
    city: 'Grevenbroich'
  },
  {
    postalCode: '41516',
    city: 'Grevenbroich'
  },
  {
    postalCode: '41517',
    city: 'Grevenbroich'
  },
  {
    postalCode: '41539',
    city: 'Dormagen'
  },
  {
    postalCode: '41540',
    city: 'Dormagen'
  },
  {
    postalCode: '41541',
    city: 'Dormagen'
  },
  {
    postalCode: '41542',
    city: 'Dormagen'
  },
  {
    postalCode: '41564',
    city: 'Kaarst'
  },
  {
    postalCode: '41569',
    city: 'Rommerskirchen'
  },
  {
    postalCode: '41747',
    city: 'Viersen'
  },
  {
    postalCode: '41748',
    city: 'Viersen'
  },
  {
    postalCode: '41749',
    city: 'Viersen'
  },
  {
    postalCode: '41751',
    city: 'Viersen'
  },
  {
    postalCode: '41812',
    city: 'Erkelenz'
  },
  {
    postalCode: '41836',
    city: 'Hückelhoven'
  },
  {
    postalCode: '41844',
    city: 'Wegberg'
  },
  {
    postalCode: '41849',
    city: 'Wassenberg'
  },
  {
    postalCode: '42103',
    city: 'Wuppertal'
  },
  {
    postalCode: '42105',
    city: 'Wuppertal'
  },
  {
    postalCode: '42107',
    city: 'Wuppertal'
  },
  {
    postalCode: '42109',
    city: 'Wuppertal'
  },
  {
    postalCode: '42111',
    city: 'Wuppertal'
  },
  {
    postalCode: '42113',
    city: 'Wuppertal'
  },
  {
    postalCode: '42115',
    city: 'Wuppertal'
  },
  {
    postalCode: '42117',
    city: 'Wuppertal'
  },
  {
    postalCode: '42119',
    city: 'Wuppertal'
  },
  {
    postalCode: '42275',
    city: 'Wuppertal'
  },
  {
    postalCode: '42277',
    city: 'Wuppertal'
  },
  {
    postalCode: '42279',
    city: 'Wuppertal'
  },
  {
    postalCode: '42281',
    city: 'Wuppertal'
  },
  {
    postalCode: '42283',
    city: 'Wuppertal'
  },
  {
    postalCode: '42285',
    city: 'Wuppertal'
  },
  {
    postalCode: '42287',
    city: 'Wuppertal'
  },
  {
    postalCode: '42289',
    city: 'Wuppertal'
  },
  {
    postalCode: '42327',
    city: 'Wuppertal'
  },
  {
    postalCode: '42329',
    city: 'Wuppertal'
  },
  {
    postalCode: '42349',
    city: 'Wuppertal'
  },
  {
    postalCode: '42369',
    city: 'Wuppertal'
  },
  {
    postalCode: '42389',
    city: 'Wuppertal'
  },
  {
    postalCode: '42399',
    city: 'Wuppertal'
  },
  {
    postalCode: '42477',
    city: 'Radevormwald'
  },
  {
    postalCode: '42489',
    city: 'Wülfrath'
  },
  {
    postalCode: '42499',
    city: 'Hückeswagen'
  },
  {
    postalCode: '42549',
    city: 'Velbert'
  },
  {
    postalCode: '42551',
    city: 'Velbert'
  },
  {
    postalCode: '42553',
    city: 'Velbert'
  },
  {
    postalCode: '42555',
    city: 'Velbert'
  },
  {
    postalCode: '42579',
    city: 'Heiligenhaus'
  },
  {
    postalCode: '42651',
    city: 'Solingen'
  },
  {
    postalCode: '42653',
    city: 'Solingen'
  },
  {
    postalCode: '42655',
    city: 'Solingen'
  },
  {
    postalCode: '42657',
    city: 'Solingen'
  },
  {
    postalCode: '42659',
    city: 'Solingen'
  },
  {
    postalCode: '42697',
    city: 'Solingen'
  },
  {
    postalCode: '42699',
    city: 'Solingen'
  },
  {
    postalCode: '42719',
    city: 'Solingen'
  },
  {
    postalCode: '42781',
    city: 'Haan'
  },
  {
    postalCode: '42799',
    city: 'Leichlingen'
  },
  {
    postalCode: '42853',
    city: 'Remscheid'
  },
  {
    postalCode: '42855',
    city: 'Remscheid'
  },
  {
    postalCode: '42857',
    city: 'Remscheid'
  },
  {
    postalCode: '42859',
    city: 'Remscheid'
  },
  {
    postalCode: '42897',
    city: 'Remscheid'
  },
  {
    postalCode: '42899',
    city: 'Remscheid'
  },
  {
    postalCode: '42929',
    city: 'Wermelskirchen'
  },
  {
    postalCode: '44135',
    city: 'Dortmund'
  },
  {
    postalCode: '44137',
    city: 'Dortmund'
  },
  {
    postalCode: '44139',
    city: 'Dortmund'
  },
  {
    postalCode: '44141',
    city: 'Dortmund'
  },
  {
    postalCode: '44143',
    city: 'Dortmund'
  },
  {
    postalCode: '44145',
    city: 'Dortmund'
  },
  {
    postalCode: '44147',
    city: 'Dortmund'
  },
  {
    postalCode: '44149',
    city: 'Dortmund'
  },
  {
    postalCode: '44225',
    city: 'Dortmund'
  },
  {
    postalCode: '44227',
    city: 'Dortmund'
  },
  {
    postalCode: '44229',
    city: 'Dortmund'
  },
  {
    postalCode: '44263',
    city: 'Dortmund'
  },
  {
    postalCode: '44265',
    city: 'Dortmund'
  },
  {
    postalCode: '44267',
    city: 'Dortmund'
  },
  {
    postalCode: '44269',
    city: 'Dortmund'
  },
  {
    postalCode: '44287',
    city: 'Dortmund'
  },
  {
    postalCode: '44289',
    city: 'Dortmund'
  },
  {
    postalCode: '44309',
    city: 'Dortmund'
  },
  {
    postalCode: '44319',
    city: 'Dortmund'
  },
  {
    postalCode: '44328',
    city: 'Dortmund'
  },
  {
    postalCode: '44329',
    city: 'Dortmund'
  },
  {
    postalCode: '44339',
    city: 'Dortmund'
  },
  {
    postalCode: '44357',
    city: 'Dortmund'
  },
  {
    postalCode: '44359',
    city: 'Dortmund'
  },
  {
    postalCode: '44369',
    city: 'Dortmund'
  },
  {
    postalCode: '44379',
    city: 'Dortmund'
  },
  {
    postalCode: '44388',
    city: 'Dortmund'
  },
  {
    postalCode: '44532',
    city: 'Lünen'
  },
  {
    postalCode: '44534',
    city: 'Lünen'
  },
  {
    postalCode: '44536',
    city: 'Lünen'
  },
  {
    postalCode: '44575',
    city: 'Castrop-Rauxel'
  },
  {
    postalCode: '44577',
    city: 'Castrop-Rauxel'
  },
  {
    postalCode: '44579',
    city: 'Castrop-Rauxel'
  },
  {
    postalCode: '44581',
    city: 'Castrop-Rauxel'
  },
  {
    postalCode: '44623',
    city: 'Herne'
  },
  {
    postalCode: '44625',
    city: 'Herne'
  },
  {
    postalCode: '44627',
    city: 'Herne'
  },
  {
    postalCode: '44628',
    city: 'Herne'
  },
  {
    postalCode: '44629',
    city: 'Herne'
  },
  {
    postalCode: '44649',
    city: 'Herne'
  },
  {
    postalCode: '44651',
    city: 'Herne'
  },
  {
    postalCode: '44652',
    city: 'Herne'
  },
  {
    postalCode: '44653',
    city: 'Herne'
  },
  {
    postalCode: '44787',
    city: 'Bochum'
  },
  {
    postalCode: '44789',
    city: 'Bochum'
  },
  {
    postalCode: '44791',
    city: 'Bochum'
  },
  {
    postalCode: '44793',
    city: 'Bochum'
  },
  {
    postalCode: '44795',
    city: 'Bochum'
  },
  {
    postalCode: '44797',
    city: 'Bochum'
  },
  {
    postalCode: '44799',
    city: 'Bochum'
  },
  {
    postalCode: '44801',
    city: 'Bochum'
  },
  {
    postalCode: '44803',
    city: 'Bochum'
  },
  {
    postalCode: '44805',
    city: 'Bochum'
  },
  {
    postalCode: '44807',
    city: 'Bochum'
  },
  {
    postalCode: '44809',
    city: 'Bochum'
  },
  {
    postalCode: '44866',
    city: 'Bochum'
  },
  {
    postalCode: '44867',
    city: 'Bochum'
  },
  {
    postalCode: '44869',
    city: 'Bochum'
  },
  {
    postalCode: '44879',
    city: 'Bochum'
  },
  {
    postalCode: '44892',
    city: 'Bochum'
  },
  {
    postalCode: '44894',
    city: 'Bochum'
  },
  {
    postalCode: '45127',
    city: 'Essen'
  },
  {
    postalCode: '45128',
    city: 'Essen'
  },
  {
    postalCode: '45130',
    city: 'Essen'
  },
  {
    postalCode: '45131',
    city: 'Essen'
  },
  {
    postalCode: '45133',
    city: 'Essen'
  },
  {
    postalCode: '45134',
    city: 'Essen'
  },
  {
    postalCode: '45136',
    city: 'Essen'
  },
  {
    postalCode: '45138',
    city: 'Essen'
  },
  {
    postalCode: '45139',
    city: 'Essen'
  },
  {
    postalCode: '45141',
    city: 'Essen'
  },
  {
    postalCode: '45143',
    city: 'Essen'
  },
  {
    postalCode: '45144',
    city: 'Essen'
  },
  {
    postalCode: '45145',
    city: 'Essen'
  },
  {
    postalCode: '45147',
    city: 'Essen'
  },
  {
    postalCode: '45149',
    city: 'Essen'
  },
  {
    postalCode: '45219',
    city: 'Essen'
  },
  {
    postalCode: '45239',
    city: 'Essen'
  },
  {
    postalCode: '45257',
    city: 'Essen'
  },
  {
    postalCode: '45259',
    city: 'Essen'
  },
  {
    postalCode: '45276',
    city: 'Essen'
  },
  {
    postalCode: '45277',
    city: 'Essen'
  },
  {
    postalCode: '45279',
    city: 'Essen'
  },
  {
    postalCode: '45289',
    city: 'Essen'
  },
  {
    postalCode: '45307',
    city: 'Essen'
  },
  {
    postalCode: '45309',
    city: 'Essen'
  },
  {
    postalCode: '45326',
    city: 'Essen'
  },
  {
    postalCode: '45327',
    city: 'Essen'
  },
  {
    postalCode: '45329',
    city: 'Essen'
  },
  {
    postalCode: '45355',
    city: 'Essen'
  },
  {
    postalCode: '45356',
    city: 'Essen'
  },
  {
    postalCode: '45357',
    city: 'Essen'
  },
  {
    postalCode: '45359',
    city: 'Essen'
  },
  {
    postalCode: '45468',
    city: 'Mülheim'
  },
  {
    postalCode: '45470',
    city: 'Mülheim'
  },
  {
    postalCode: '45472',
    city: 'Mülheim'
  },
  {
    postalCode: '45473',
    city: 'Mülheim'
  },
  {
    postalCode: '45475',
    city: 'Mülheim'
  },
  {
    postalCode: '45476',
    city: 'Mülheim'
  },
  {
    postalCode: '45478',
    city: 'Mülheim'
  },
  {
    postalCode: '45479',
    city: 'Mülheim'
  },
  {
    postalCode: '45481',
    city: 'Mülheim'
  },
  {
    postalCode: '45525',
    city: 'Hattingen'
  },
  {
    postalCode: '45527',
    city: 'Hattingen'
  },
  {
    postalCode: '45529',
    city: 'Hattingen'
  },
  {
    postalCode: '45549',
    city: 'Sprockhövel'
  },
  {
    postalCode: '45657',
    city: 'Recklinghausen'
  },
  {
    postalCode: '45659',
    city: 'Recklinghausen'
  },
  {
    postalCode: '45661',
    city: 'Recklinghausen'
  },
  {
    postalCode: '45663',
    city: 'Recklinghausen'
  },
  {
    postalCode: '45665',
    city: 'Recklinghausen'
  },
  {
    postalCode: '45699',
    city: 'Herten'
  },
  {
    postalCode: '45701',
    city: 'Herten'
  },
  {
    postalCode: '45711',
    city: 'Datteln'
  },
  {
    postalCode: '45721',
    city: 'Haltern am See'
  },
  {
    postalCode: '45731',
    city: 'Waltrop'
  },
  {
    postalCode: '45739',
    city: 'Oer-Erkenschwick'
  },
  {
    postalCode: '45768',
    city: 'Marl'
  },
  {
    postalCode: '45770',
    city: 'Marl'
  },
  {
    postalCode: '45772',
    city: 'Marl'
  },
  {
    postalCode: '45879',
    city: 'Gelsenkirchen'
  },
  {
    postalCode: '45881',
    city: 'Gelsenkirchen'
  },
  {
    postalCode: '45883',
    city: 'Gelsenkirchen'
  },
  {
    postalCode: '45884',
    city: 'Gelsenkirchen'
  },
  {
    postalCode: '45886',
    city: 'Gelsenkirchen'
  },
  {
    postalCode: '45888',
    city: 'Gelsenkirchen'
  },
  {
    postalCode: '45889',
    city: 'Gelsenkirchen'
  },
  {
    postalCode: '45891',
    city: 'Gelsenkirchen'
  },
  {
    postalCode: '45892',
    city: 'Gelsenkirchen'
  },
  {
    postalCode: '45894',
    city: 'Gelsenkirchen'
  },
  {
    postalCode: '45896',
    city: 'Gelsenkirchen'
  },
  {
    postalCode: '45897',
    city: 'Gelsenkirchen'
  },
  {
    postalCode: '45899',
    city: 'Gelsenkirchen'
  },
  {
    postalCode: '45964',
    city: 'Gladbeck'
  },
  {
    postalCode: '45966',
    city: 'Gladbeck'
  },
  {
    postalCode: '45968',
    city: 'Gladbeck'
  },
  {
    postalCode: '46045',
    city: 'Oberhausen'
  },
  {
    postalCode: '46047',
    city: 'Oberhausen'
  },
  {
    postalCode: '46049',
    city: 'Oberhausen'
  },
  {
    postalCode: '46117',
    city: 'Oberhausen'
  },
  {
    postalCode: '46119',
    city: 'Oberhausen'
  },
  {
    postalCode: '46145',
    city: 'Oberhausen'
  },
  {
    postalCode: '46147',
    city: 'Oberhausen'
  },
  {
    postalCode: '46149',
    city: 'Oberhausen'
  },
  {
    postalCode: '46236',
    city: 'Bottrop'
  },
  {
    postalCode: '46238',
    city: 'Bottrop'
  },
  {
    postalCode: '46240',
    city: 'Bottrop'
  },
  {
    postalCode: '46242',
    city: 'Bottrop'
  },
  {
    postalCode: '46244',
    city: 'Bottrop'
  },
  {
    postalCode: '46282',
    city: 'Dorsten'
  },
  {
    postalCode: '46284',
    city: 'Dorsten'
  },
  {
    postalCode: '46286',
    city: 'Dorsten'
  },
  {
    postalCode: '46325',
    city: 'Borken'
  },
  {
    postalCode: '46342',
    city: 'Velen'
  },
  {
    postalCode: '46348',
    city: 'Raesfeld'
  },
  {
    postalCode: '46354',
    city: 'Südlohn'
  },
  {
    postalCode: '46359',
    city: 'Heiden'
  },
  {
    postalCode: '46395',
    city: 'Bocholt'
  },
  {
    postalCode: '46397',
    city: 'Bocholt'
  },
  {
    postalCode: '46399',
    city: 'Bocholt'
  },
  {
    postalCode: '46414',
    city: 'Rhede'
  },
  {
    postalCode: '46419',
    city: 'Isselburg'
  },
  {
    postalCode: '46446',
    city: 'Emmerich'
  },
  {
    postalCode: '46459',
    city: 'Rees'
  },
  {
    postalCode: '46483',
    city: 'Wesel'
  },
  {
    postalCode: '46485',
    city: 'Wesel'
  },
  {
    postalCode: '46487',
    city: 'Wesel'
  },
  {
    postalCode: '46499',
    city: 'Hamminkeln'
  },
  {
    postalCode: '46509',
    city: 'Xanten'
  },
  {
    postalCode: '46514',
    city: 'Schermbeck'
  },
  {
    postalCode: '46519',
    city: 'Alpen'
  },
  {
    postalCode: '46535',
    city: 'Dinslaken'
  },
  {
    postalCode: '46537',
    city: 'Dinslaken'
  },
  {
    postalCode: '46539',
    city: 'Dinslaken'
  },
  {
    postalCode: '46562',
    city: 'Voerde'
  },
  {
    postalCode: '46569',
    city: 'Hünxe'
  },
  {
    postalCode: '47051',
    city: 'Duisburg'
  },
  {
    postalCode: '47053',
    city: 'Duisburg'
  },
  {
    postalCode: '47055',
    city: 'Duisburg'
  },
  {
    postalCode: '47057',
    city: 'Duisburg'
  },
  {
    postalCode: '47058',
    city: 'Duisburg'
  },
  {
    postalCode: '47059',
    city: 'Duisburg'
  },
  {
    postalCode: '47119',
    city: 'Duisburg'
  },
  {
    postalCode: '47137',
    city: 'Duisburg'
  },
  {
    postalCode: '47138',
    city: 'Duisburg'
  },
  {
    postalCode: '47139',
    city: 'Duisburg'
  },
  {
    postalCode: '47166',
    city: 'Duisburg'
  },
  {
    postalCode: '47167',
    city: 'Duisburg'
  },
  {
    postalCode: '47169',
    city: 'Duisburg'
  },
  {
    postalCode: '47178',
    city: 'Duisburg'
  },
  {
    postalCode: '47179',
    city: 'Duisburg'
  },
  {
    postalCode: '47198',
    city: 'Duisburg'
  },
  {
    postalCode: '47199',
    city: 'Duisburg'
  },
  {
    postalCode: '47226',
    city: 'Duisburg'
  },
  {
    postalCode: '47228',
    city: 'Duisburg'
  },
  {
    postalCode: '47229',
    city: 'Duisburg'
  },
  {
    postalCode: '47239',
    city: 'Duisburg'
  },
  {
    postalCode: '47249',
    city: 'Duisburg'
  },
  {
    postalCode: '47259',
    city: 'Duisburg'
  },
  {
    postalCode: '47269',
    city: 'Duisburg'
  },
  {
    postalCode: '47279',
    city: 'Duisburg'
  },
  {
    postalCode: '47441',
    city: 'Moers'
  },
  {
    postalCode: '47443',
    city: 'Moers'
  },
  {
    postalCode: '47445',
    city: 'Moers'
  },
  {
    postalCode: '47447',
    city: 'Moers'
  },
  {
    postalCode: '47475',
    city: 'Kamp-Lintfort'
  },
  {
    postalCode: '47495',
    city: 'Rheinberg'
  },
  {
    postalCode: '47506',
    city: 'Neukirchen-Vluyn'
  },
  {
    postalCode: '47509',
    city: 'Rheurdt'
  },
  {
    postalCode: '47533',
    city: 'Kleve'
  },
  {
    postalCode: '47546',
    city: 'Kalkar'
  },
  {
    postalCode: '47551',
    city: 'Bedburg-Hau'
  },
  {
    postalCode: '47559',
    city: 'Kranenburg'
  },
  {
    postalCode: '47574',
    city: 'Goch'
  },
  {
    postalCode: '47589',
    city: 'Uedem'
  },
  {
    postalCode: '47608',
    city: 'Geldern'
  },
  {
    postalCode: '47623',
    city: 'Kevelaer'
  },
  {
    postalCode: '47624',
    city: 'Kevelaer'
  },
  {
    postalCode: '47625',
    city: 'Kevelaer'
  },
  {
    postalCode: '47626',
    city: 'Kevelaer'
  },
  {
    postalCode: '47627',
    city: 'Kevelaer'
  },
  {
    postalCode: '47638',
    city: 'Straelen'
  },
  {
    postalCode: '47647',
    city: 'Kerken'
  },
  {
    postalCode: '47652',
    city: 'Weeze'
  },
  {
    postalCode: '47661',
    city: 'Issum'
  },
  {
    postalCode: '47665',
    city: 'Sonsbeck'
  },
  {
    postalCode: '47669',
    city: 'Wachtendonk'
  },
  {
    postalCode: '47798',
    city: 'Krefeld'
  },
  {
    postalCode: '47799',
    city: 'Krefeld'
  },
  {
    postalCode: '47800',
    city: 'Krefeld'
  },
  {
    postalCode: '47802',
    city: 'Krefeld'
  },
  {
    postalCode: '47803',
    city: 'Krefeld'
  },
  {
    postalCode: '47804',
    city: 'Krefeld'
  },
  {
    postalCode: '47805',
    city: 'Krefeld'
  },
  {
    postalCode: '47807',
    city: 'Krefeld'
  },
  {
    postalCode: '47809',
    city: 'Krefeld'
  },
  {
    postalCode: '47829',
    city: 'Krefeld'
  },
  {
    postalCode: '47839',
    city: 'Krefeld'
  },
  {
    postalCode: '47877',
    city: 'Willich'
  },
  {
    postalCode: '47906',
    city: 'Kempen'
  },
  {
    postalCode: '47918',
    city: 'Tönisvorst'
  },
  {
    postalCode: '47929',
    city: 'Grefrath'
  },
  {
    postalCode: '48143',
    city: 'Münster'
  },
  {
    postalCode: '48145',
    city: 'Münster'
  },
  {
    postalCode: '48147',
    city: 'Münster'
  },
  {
    postalCode: '48149',
    city: 'Münster'
  },
  {
    postalCode: '48151',
    city: 'Münster'
  },
  {
    postalCode: '48153',
    city: 'Münster'
  },
  {
    postalCode: '48155',
    city: 'Münster'
  },
  {
    postalCode: '48157',
    city: 'Münster'
  },
  {
    postalCode: '48159',
    city: 'Münster'
  },
  {
    postalCode: '48161',
    city: 'Münster'
  },
  {
    postalCode: '48163',
    city: 'Münster'
  },
  {
    postalCode: '48165',
    city: 'Münster'
  },
  {
    postalCode: '48167',
    city: 'Münster'
  },
  {
    postalCode: '48231',
    city: 'Warendorf'
  },
  {
    postalCode: '48249',
    city: 'Dülmen'
  },
  {
    postalCode: '48268',
    city: 'Greven'
  },
  {
    postalCode: '48282',
    city: 'Emsdetten'
  },
  {
    postalCode: '48291',
    city: 'Telgte'
  },
  {
    postalCode: '48301',
    city: 'Nottuln'
  },
  {
    postalCode: '48308',
    city: 'Senden'
  },
  {
    postalCode: '48317',
    city: 'Drensteinfurt'
  },
  {
    postalCode: '48324',
    city: 'Sendenhorst'
  },
  {
    postalCode: '48329',
    city: 'Havixbeck'
  },
  {
    postalCode: '48336',
    city: 'Sassenberg'
  },
  {
    postalCode: '48341',
    city: 'Altenberge'
  },
  {
    postalCode: '48346',
    city: 'Ostbevern'
  },
  {
    postalCode: '48351',
    city: 'Everswinkel'
  },
  {
    postalCode: '48356',
    city: 'Nordwalde'
  },
  {
    postalCode: '48361',
    city: 'Beelen'
  },
  {
    postalCode: '48366',
    city: 'Laer'
  },
  {
    postalCode: '48369',
    city: 'Saerbeck'
  },
  {
    postalCode: '48429',
    city: 'Rheine'
  },
  {
    postalCode: '48431',
    city: 'Rheine'
  },
  {
    postalCode: '48432',
    city: 'Rheine'
  },
  {
    postalCode: '48455',
    city: 'Bad Bentheim'
  },
  {
    postalCode: '48465',
    city: 'Engden'
  },
  {
    postalCode: '48465',
    city: 'Isterberg'
  },
  {
    postalCode: '48465',
    city: 'Ohne'
  },
  {
    postalCode: '48465',
    city: 'Quendorf'
  },
  {
    postalCode: '48465',
    city: 'Samern'
  },
  {
    postalCode: '48465',
    city: 'Schüttorf'
  },
  {
    postalCode: '48465',
    city: 'Suddendorf'
  },
  {
    postalCode: '48477',
    city: 'Hörstel'
  },
  {
    postalCode: '48480',
    city: 'Lünne'
  },
  {
    postalCode: '48480',
    city: 'Schapen'
  },
  {
    postalCode: '48480',
    city: 'Spelle'
  },
  {
    postalCode: '48485',
    city: 'Neuenkirchen'
  },
  {
    postalCode: '48488',
    city: 'Emsbüren'
  },
  {
    postalCode: '48493',
    city: 'Wettringen'
  },
  {
    postalCode: '48496',
    city: 'Hopsten'
  },
  {
    postalCode: '48499',
    city: 'Salzbergen'
  },
  {
    postalCode: '48527',
    city: 'Nordhorn'
  },
  {
    postalCode: '48529',
    city: 'Nordhorn'
  },
  {
    postalCode: '48531',
    city: 'Nordhorn'
  },
  {
    postalCode: '48565',
    city: 'Steinfurt'
  },
  {
    postalCode: '48599',
    city: 'Gronau'
  },
  {
    postalCode: '48607',
    city: 'Ochtrup'
  },
  {
    postalCode: '48612',
    city: 'Horstmar'
  },
  {
    postalCode: '48619',
    city: 'Heek'
  },
  {
    postalCode: '48624',
    city: 'Schöppingen'
  },
  {
    postalCode: '48629',
    city: 'Metelen'
  },
  {
    postalCode: '48653',
    city: 'Coesfeld'
  },
  {
    postalCode: '48683',
    city: 'Ahaus'
  },
  {
    postalCode: '48691',
    city: 'Vreden'
  },
  {
    postalCode: '48703',
    city: 'Stadtlohn'
  },
  {
    postalCode: '48712',
    city: 'Gescher'
  },
  {
    postalCode: '48720',
    city: 'Rosendahl'
  },
  {
    postalCode: '48727',
    city: 'Billerbeck'
  },
  {
    postalCode: '48734',
    city: 'Reken'
  },
  {
    postalCode: '48739',
    city: 'Legden'
  },
  {
    postalCode: '49074',
    city: 'Osnabrück'
  },
  {
    postalCode: '49076',
    city: 'Osnabrück'
  },
  {
    postalCode: '49078',
    city: 'Osnabrück'
  },
  {
    postalCode: '49080',
    city: 'Osnabrück'
  },
  {
    postalCode: '49082',
    city: 'Osnabrück'
  },
  {
    postalCode: '49084',
    city: 'Osnabrück'
  },
  {
    postalCode: '49086',
    city: 'Osnabrück'
  },
  {
    postalCode: '49088',
    city: 'Osnabrück'
  },
  {
    postalCode: '49090',
    city: 'Osnabrück'
  },
  {
    postalCode: '49124',
    city: 'Georgsmarienhütte'
  },
  {
    postalCode: '49134',
    city: 'Wallenhorst'
  },
  {
    postalCode: '49143',
    city: 'Bissendorf'
  },
  {
    postalCode: '49152',
    city: 'Bad Essen'
  },
  {
    postalCode: '49163',
    city: 'Bohmte'
  },
  {
    postalCode: '49170',
    city: 'Hagen'
  },
  {
    postalCode: '49176',
    city: 'Hilter'
  },
  {
    postalCode: '49179',
    city: 'Ostercappeln'
  },
  {
    postalCode: '49186',
    city: 'Bad Iburg'
  },
  {
    postalCode: '49191',
    city: 'Belm'
  },
  {
    postalCode: '49196',
    city: 'Bad Laer'
  },
  {
    postalCode: '49201',
    city: 'Dissen'
  },
  {
    postalCode: '49205',
    city: 'Hasbergen'
  },
  {
    postalCode: '49214',
    city: 'Bad Rothenfelde'
  },
  {
    postalCode: '49219',
    city: 'Glandorf'
  },
  {
    postalCode: '49324',
    city: 'Melle'
  },
  {
    postalCode: '49326',
    city: 'Melle'
  },
  {
    postalCode: '49328',
    city: 'Melle'
  },
  {
    postalCode: '49356',
    city: 'Diepholz'
  },
  {
    postalCode: '49377',
    city: 'Vechta'
  },
  {
    postalCode: '49393',
    city: 'Lohne'
  },
  {
    postalCode: '49401',
    city: 'Damme'
  },
  {
    postalCode: '49406',
    city: 'Barnstorf'
  },
  {
    postalCode: '49406',
    city: 'Drentwede'
  },
  {
    postalCode: '49406',
    city: 'Eydelstedt'
  },
  {
    postalCode: '49413',
    city: 'Dinklage'
  },
  {
    postalCode: '49419',
    city: 'Wagenfeld'
  },
  {
    postalCode: '49424',
    city: 'Goldenstedt'
  },
  {
    postalCode: '49429',
    city: 'Visbek'
  },
  {
    postalCode: '49434',
    city: 'Neuenkirchen-Vörden'
  },
  {
    postalCode: '49439',
    city: 'Steinfeld'
  },
  {
    postalCode: '49448',
    city: 'Brockum'
  },
  {
    postalCode: '49448',
    city: 'Hüde'
  },
  {
    postalCode: '49448',
    city: 'Lemförde'
  },
  {
    postalCode: '49448',
    city: 'Marl'
  },
  {
    postalCode: '49448',
    city: 'Quernheim'
  },
  {
    postalCode: '49448',
    city: 'Stemshorn'
  },
  {
    postalCode: '49451',
    city: 'Holdorf'
  },
  {
    postalCode: '49453',
    city: 'Barver'
  },
  {
    postalCode: '49453',
    city: 'Dickel'
  },
  {
    postalCode: '49453',
    city: 'Hemsloh'
  },
  {
    postalCode: '49453',
    city: 'Rehden'
  },
  {
    postalCode: '49453',
    city: 'Wetschen'
  },
  {
    postalCode: '49456',
    city: 'Bakum'
  },
  {
    postalCode: '49457',
    city: 'Drebber'
  },
  {
    postalCode: '49457',
    city: 'Dreeker Fladder'
  },
  {
    postalCode: '49459',
    city: 'Berglage'
  },
  {
    postalCode: '49459',
    city: 'Burlage'
  },
  {
    postalCode: '49459',
    city: 'Evershorst'
  },
  {
    postalCode: '49459',
    city: 'Kuhlhorst'
  },
  {
    postalCode: '49459',
    city: 'Lembruch'
  },
  {
    postalCode: '49459',
    city: 'Mecklinge'
  },
  {
    postalCode: '49459',
    city: 'Quernheimerbruch'
  },
  {
    postalCode: '49477',
    city: 'Ibbenbüren'
  },
  {
    postalCode: '49479',
    city: 'Ibbenbüren'
  },
  {
    postalCode: '49492',
    city: 'Westerkappeln'
  },
  {
    postalCode: '49497',
    city: 'Mettingen'
  },
  {
    postalCode: '49504',
    city: 'Lotte'
  },
  {
    postalCode: '49509',
    city: 'Recke'
  },
  {
    postalCode: '49525',
    city: 'Lengerich'
  },
  {
    postalCode: '49536',
    city: 'Lienen'
  },
  {
    postalCode: '49545',
    city: 'Tecklenburg'
  },
  {
    postalCode: '49549',
    city: 'Ladbergen'
  },
  {
    postalCode: '49565',
    city: 'Bramsche'
  },
  {
    postalCode: '49577',
    city: 'Ankum'
  },
  {
    postalCode: '49577',
    city: 'Eggermühlen'
  },
  {
    postalCode: '49577',
    city: 'Kettenkamp'
  },
  {
    postalCode: '49584',
    city: 'Fürstenau'
  },
  {
    postalCode: '49586',
    city: 'Merzen'
  },
  {
    postalCode: '49586',
    city: 'Neuenkirchen'
  },
  {
    postalCode: '49593',
    city: 'Bersenbrück'
  },
  {
    postalCode: '49594',
    city: 'Alfhausen'
  },
  {
    postalCode: '49596',
    city: 'Gehrde'
  },
  {
    postalCode: '49597',
    city: 'Rieste'
  },
  {
    postalCode: '49599',
    city: 'Voltlage'
  },
  {
    postalCode: '49610',
    city: 'Quakenbrück'
  },
  {
    postalCode: '49624',
    city: 'Löningen'
  },
  {
    postalCode: '49626',
    city: 'Berge'
  },
  {
    postalCode: '49626',
    city: 'Bippen'
  },
  {
    postalCode: '49632',
    city: 'Essen'
  },
  {
    postalCode: '49635',
    city: 'Badbergen'
  },
  {
    postalCode: '49637',
    city: 'Menslage'
  },
  {
    postalCode: '49638',
    city: 'Nortrup'
  },
  {
    postalCode: '49661',
    city: 'Cloppenburg'
  },
  {
    postalCode: '49681',
    city: 'Garrel'
  },
  {
    postalCode: '49685',
    city: 'Bühren'
  },
  {
    postalCode: '49685',
    city: 'Drantum'
  },
  {
    postalCode: '49685',
    city: 'Emstek'
  },
  {
    postalCode: '49685',
    city: 'Garthe'
  },
  {
    postalCode: '49685',
    city: 'Halen'
  },
  {
    postalCode: '49685',
    city: 'Hoheging'
  },
  {
    postalCode: '49685',
    city: 'Höltinghausen'
  },
  {
    postalCode: '49685',
    city: 'Schneiderkrug'
  },
  {
    postalCode: '49688',
    city: 'Lastrup'
  },
  {
    postalCode: '49692',
    city: 'Cappeln'
  },
  {
    postalCode: '49696',
    city: 'Dwergte'
  },
  {
    postalCode: '49696',
    city: 'Ermke'
  },
  {
    postalCode: '49696',
    city: 'Grönheim'
  },
  {
    postalCode: '49696',
    city: 'Molbergen'
  },
  {
    postalCode: '49696',
    city: 'Peheim'
  },
  {
    postalCode: '49696',
    city: 'Resthausen'
  },
  {
    postalCode: '49696',
    city: 'Stalförden'
  },
  {
    postalCode: '49699',
    city: 'Lindern'
  },
  {
    postalCode: '49716',
    city: 'Meppen'
  },
  {
    postalCode: '49733',
    city: 'Haren'
  },
  {
    postalCode: '49740',
    city: 'Haselünne'
  },
  {
    postalCode: '49744',
    city: 'Geeste'
  },
  {
    postalCode: '49751',
    city: 'Hüven'
  },
  {
    postalCode: '49751',
    city: 'Sögel'
  },
  {
    postalCode: '49751',
    city: 'Spahnharrenstätte'
  },
  {
    postalCode: '49751',
    city: 'Werpeloh'
  },
  {
    postalCode: '49757',
    city: 'Lahn'
  },
  {
    postalCode: '49757',
    city: 'Vrees'
  },
  {
    postalCode: '49757',
    city: 'Werlte'
  },
  {
    postalCode: '49762',
    city: 'Fresenburg'
  },
  {
    postalCode: '49762',
    city: 'Lathen'
  },
  {
    postalCode: '49762',
    city: 'Renkenberge'
  },
  {
    postalCode: '49762',
    city: 'Sustrum'
  },
  {
    postalCode: '49767',
    city: 'Twist'
  },
  {
    postalCode: '49770',
    city: 'Dohren'
  },
  {
    postalCode: '49770',
    city: 'Herzlake'
  },
  {
    postalCode: '49774',
    city: 'Lähden'
  },
  {
    postalCode: '49777',
    city: 'Groß Berßen'
  },
  {
    postalCode: '49777',
    city: 'Klein Berßen'
  },
  {
    postalCode: '49777',
    city: 'Stavern'
  },
  {
    postalCode: '49779',
    city: 'Niederlangen'
  },
  {
    postalCode: '49779',
    city: 'Oberlangen'
  },
  {
    postalCode: '49808',
    city: 'Lingen'
  },
  {
    postalCode: '49809',
    city: 'Lingen'
  },
  {
    postalCode: '49811',
    city: 'Lingen'
  },
  {
    postalCode: '49824',
    city: 'Emlichheim'
  },
  {
    postalCode: '49824',
    city: 'Laar'
  },
  {
    postalCode: '49824',
    city: 'Ringe'
  },
  {
    postalCode: '49828',
    city: 'Esche'
  },
  {
    postalCode: '49828',
    city: 'Georgsdorf'
  },
  {
    postalCode: '49828',
    city: 'Lage'
  },
  {
    postalCode: '49828',
    city: 'Neuenhaus'
  },
  {
    postalCode: '49828',
    city: 'Osterwald'
  },
  {
    postalCode: '49832',
    city: 'Andervenne'
  },
  {
    postalCode: '49832',
    city: 'Beesten'
  },
  {
    postalCode: '49832',
    city: 'Freren'
  },
  {
    postalCode: '49832',
    city: 'Messingen'
  },
  {
    postalCode: '49832',
    city: 'Thuine'
  },
  {
    postalCode: '49835',
    city: 'Wietmarschen'
  },
  {
    postalCode: '49838',
    city: 'Gersten'
  },
  {
    postalCode: '49838',
    city: 'Handrup'
  },
  {
    postalCode: '49838',
    city: 'Langen'
  },
  {
    postalCode: '49838',
    city: 'Lengerich'
  },
  {
    postalCode: '49838',
    city: 'Wettrup'
  },
  {
    postalCode: '49843',
    city: 'Getelo'
  },
  {
    postalCode: '49843',
    city: 'Gölenkamp'
  },
  {
    postalCode: '49843',
    city: 'Halle'
  },
  {
    postalCode: '49843',
    city: 'Uelsen'
  },
  {
    postalCode: '49844',
    city: 'Bawinkel'
  },
  {
    postalCode: '49846',
    city: 'Hoogstede'
  },
  {
    postalCode: '49847',
    city: 'Itterbeck'
  },
  {
    postalCode: '49847',
    city: 'Wielen'
  },
  {
    postalCode: '49849',
    city: 'Wilsum'
  },
  {
    postalCode: '50126',
    city: 'Bergheim'
  },
  {
    postalCode: '50127',
    city: 'Bergheim'
  },
  {
    postalCode: '50129',
    city: 'Bergheim'
  },
  {
    postalCode: '50169',
    city: 'Kerpen'
  },
  {
    postalCode: '50170',
    city: 'Kerpen'
  },
  {
    postalCode: '50171',
    city: 'Kerpen'
  },
  {
    postalCode: '50181',
    city: 'Bedburg'
  },
  {
    postalCode: '50189',
    city: 'Elsdorf'
  },
  {
    postalCode: '50226',
    city: 'Frechen'
  },
  {
    postalCode: '50259',
    city: 'Pulheim'
  },
  {
    postalCode: '50321',
    city: 'Brühl'
  },
  {
    postalCode: '50354',
    city: 'Hürth'
  },
  {
    postalCode: '50374',
    city: 'Erftstadt'
  },
  {
    postalCode: '50389',
    city: 'Wesseling'
  },
  {
    postalCode: '50667',
    city: 'Köln'
  },
  {
    postalCode: '50668',
    city: 'Köln'
  },
  {
    postalCode: '50670',
    city: 'Köln'
  },
  {
    postalCode: '50672',
    city: 'Köln'
  },
  {
    postalCode: '50674',
    city: 'Köln'
  },
  {
    postalCode: '50676',
    city: 'Köln'
  },
  {
    postalCode: '50677',
    city: 'Köln'
  },
  {
    postalCode: '50678',
    city: 'Köln'
  },
  {
    postalCode: '50679',
    city: 'Köln'
  },
  {
    postalCode: '50733',
    city: 'Köln'
  },
  {
    postalCode: '50735',
    city: 'Köln'
  },
  {
    postalCode: '50737',
    city: 'Köln'
  },
  {
    postalCode: '50739',
    city: 'Köln'
  },
  {
    postalCode: '50765',
    city: 'Köln'
  },
  {
    postalCode: '50767',
    city: 'Köln'
  },
  {
    postalCode: '50769',
    city: 'Köln'
  },
  {
    postalCode: '50823',
    city: 'Köln'
  },
  {
    postalCode: '50825',
    city: 'Köln'
  },
  {
    postalCode: '50827',
    city: 'Köln'
  },
  {
    postalCode: '50829',
    city: 'Köln'
  },
  {
    postalCode: '50858',
    city: 'Köln'
  },
  {
    postalCode: '50859',
    city: 'Köln'
  },
  {
    postalCode: '50931',
    city: 'Köln'
  },
  {
    postalCode: '50933',
    city: 'Köln'
  },
  {
    postalCode: '50935',
    city: 'Köln'
  },
  {
    postalCode: '50937',
    city: 'Köln'
  },
  {
    postalCode: '50939',
    city: 'Köln'
  },
  {
    postalCode: '50968',
    city: 'Köln'
  },
  {
    postalCode: '50969',
    city: 'Köln'
  },
  {
    postalCode: '50996',
    city: 'Köln'
  },
  {
    postalCode: '50997',
    city: 'Köln'
  },
  {
    postalCode: '50999',
    city: 'Köln'
  },
  {
    postalCode: '51061',
    city: 'Köln'
  },
  {
    postalCode: '51063',
    city: 'Köln'
  },
  {
    postalCode: '51065',
    city: 'Köln'
  },
  {
    postalCode: '51067',
    city: 'Köln'
  },
  {
    postalCode: '51069',
    city: 'Köln'
  },
  {
    postalCode: '51103',
    city: 'Köln'
  },
  {
    postalCode: '51105',
    city: 'Köln'
  },
  {
    postalCode: '51107',
    city: 'Köln'
  },
  {
    postalCode: '51109',
    city: 'Köln'
  },
  {
    postalCode: '51143',
    city: 'Köln'
  },
  {
    postalCode: '51145',
    city: 'Köln'
  },
  {
    postalCode: '51147',
    city: 'Köln'
  },
  {
    postalCode: '51149',
    city: 'Köln'
  },
  {
    postalCode: '51371',
    city: 'Leverkusen'
  },
  {
    postalCode: '51373',
    city: 'Leverkusen'
  },
  {
    postalCode: '51375',
    city: 'Leverkusen'
  },
  {
    postalCode: '51377',
    city: 'Leverkusen'
  },
  {
    postalCode: '51379',
    city: 'Leverkusen'
  },
  {
    postalCode: '51381',
    city: 'Leverkusen'
  },
  {
    postalCode: '51399',
    city: 'Burscheid'
  },
  {
    postalCode: '51427',
    city: 'Bergisch Gladbach'
  },
  {
    postalCode: '51429',
    city: 'Bergisch Gladbach'
  },
  {
    postalCode: '51465',
    city: 'Bergisch Gladbach'
  },
  {
    postalCode: '51467',
    city: 'Bergisch Gladbach'
  },
  {
    postalCode: '51467',
    city: 'Köln'
  },
  {
    postalCode: '51469',
    city: 'Bergisch Gladbach'
  },
  {
    postalCode: '51491',
    city: 'Overath'
  },
  {
    postalCode: '51503',
    city: 'Rösrath'
  },
  {
    postalCode: '51515',
    city: 'Kürten'
  },
  {
    postalCode: '51519',
    city: 'Odenthal'
  },
  {
    postalCode: '51545',
    city: 'Waldbröl'
  },
  {
    postalCode: '51570',
    city: 'Windeck'
  },
  {
    postalCode: '51580',
    city: 'Reichshof'
  },
  {
    postalCode: '51588',
    city: 'Nümbrecht'
  },
  {
    postalCode: '51597',
    city: 'Morsbach'
  },
  {
    postalCode: '51598',
    city: 'Friesenhagen'
  },
  {
    postalCode: '51643',
    city: 'Gummersbach'
  },
  {
    postalCode: '51645',
    city: 'Gummersbach'
  },
  {
    postalCode: '51647',
    city: 'Gummersbach'
  },
  {
    postalCode: '51674',
    city: 'Wiehl'
  },
  {
    postalCode: '51688',
    city: 'Wipperfürth'
  },
  {
    postalCode: '51702',
    city: 'Bergneustadt'
  },
  {
    postalCode: '51709',
    city: 'Marienheide'
  },
  {
    postalCode: '51766',
    city: 'Engelskirchen'
  },
  {
    postalCode: '51789',
    city: 'Lindlar'
  },
  {
    postalCode: '52062',
    city: 'Aachen'
  },
  {
    postalCode: '52064',
    city: 'Aachen'
  },
  {
    postalCode: '52066',
    city: 'Aachen'
  },
  {
    postalCode: '52068',
    city: 'Aachen'
  },
  {
    postalCode: '52070',
    city: 'Aachen'
  },
  {
    postalCode: '52072',
    city: 'Aachen'
  },
  {
    postalCode: '52074',
    city: 'Aachen'
  },
  {
    postalCode: '52076',
    city: 'Aachen'
  },
  {
    postalCode: '52078',
    city: 'Aachen'
  },
  {
    postalCode: '52080',
    city: 'Aachen'
  },
  {
    postalCode: '52134',
    city: 'Herzogenrath'
  },
  {
    postalCode: '52146',
    city: 'Würselen'
  },
  {
    postalCode: '52152',
    city: 'Simmerath'
  },
  {
    postalCode: '52156',
    city: 'Monschau'
  },
  {
    postalCode: '52159',
    city: 'Roetgen'
  },
  {
    postalCode: '52222',
    city: 'Stolberg'
  },
  {
    postalCode: '52223',
    city: 'Stolberg'
  },
  {
    postalCode: '52224',
    city: 'Stolberg'
  },
  {
    postalCode: '52249',
    city: 'Eschweiler'
  },
  {
    postalCode: '52349',
    city: 'Düren'
  },
  {
    postalCode: '52351',
    city: 'Düren'
  },
  {
    postalCode: '52353',
    city: 'Düren'
  },
  {
    postalCode: '52355',
    city: 'Düren'
  },
  {
    postalCode: '52372',
    city: 'Kreuzau'
  },
  {
    postalCode: '52379',
    city: 'Langerwehe'
  },
  {
    postalCode: '52382',
    city: 'Niederzier'
  },
  {
    postalCode: '52385',
    city: 'Nideggen'
  },
  {
    postalCode: '52388',
    city: 'Nörvenich'
  },
  {
    postalCode: '52391',
    city: 'Vettweiß'
  },
  {
    postalCode: '52393',
    city: 'Hürtgenwald'
  },
  {
    postalCode: '52396',
    city: 'Heimbach'
  },
  {
    postalCode: '52399',
    city: 'Merzenich'
  },
  {
    postalCode: '52428',
    city: 'Jülich'
  },
  {
    postalCode: '52441',
    city: 'Linnich'
  },
  {
    postalCode: '52445',
    city: 'Titz'
  },
  {
    postalCode: '52457',
    city: 'Aldenhoven'
  },
  {
    postalCode: '52459',
    city: 'Inden'
  },
  {
    postalCode: '52477',
    city: 'Alsdorf'
  },
  {
    postalCode: '52499',
    city: 'Baesweiler'
  },
  {
    postalCode: '52511',
    city: 'Geilenkirchen'
  },
  {
    postalCode: '52525',
    city: 'Heinsberg'
  },
  {
    postalCode: '52525',
    city: 'Waldfeucht'
  },
  {
    postalCode: '52531',
    city: 'Übach-Palenberg'
  },
  {
    postalCode: '52538',
    city: 'Gangelt'
  },
  {
    postalCode: '52538',
    city: 'Selfkant'
  },
  {
    postalCode: '53111',
    city: 'Bonn'
  },
  {
    postalCode: '53113',
    city: 'Bonn'
  },
  {
    postalCode: '53115',
    city: 'Bonn'
  },
  {
    postalCode: '53117',
    city: 'Bonn'
  },
  {
    postalCode: '53119',
    city: 'Bonn'
  },
  {
    postalCode: '53121',
    city: 'Bonn'
  },
  {
    postalCode: '53123',
    city: 'Bonn'
  },
  {
    postalCode: '53125',
    city: 'Bonn'
  },
  {
    postalCode: '53127',
    city: 'Bonn'
  },
  {
    postalCode: '53129',
    city: 'Bonn'
  },
  {
    postalCode: '53173',
    city: 'Bonn'
  },
  {
    postalCode: '53175',
    city: 'Bonn'
  },
  {
    postalCode: '53177',
    city: 'Bonn'
  },
  {
    postalCode: '53179',
    city: 'Bonn'
  },
  {
    postalCode: '53225',
    city: 'Bonn'
  },
  {
    postalCode: '53227',
    city: 'Bonn'
  },
  {
    postalCode: '53229',
    city: 'Bonn'
  },
  {
    postalCode: '53332',
    city: 'Bornheim'
  },
  {
    postalCode: '53340',
    city: 'Meckenheim'
  },
  {
    postalCode: '53343',
    city: 'Wachtberg'
  },
  {
    postalCode: '53347',
    city: 'Alfter'
  },
  {
    postalCode: '53359',
    city: 'Rheinbach'
  },
  {
    postalCode: '53424',
    city: 'Calmuth'
  },
  {
    postalCode: '53424',
    city: 'Remagen'
  },
  {
    postalCode: '53426',
    city: 'Dedenbach'
  },
  {
    postalCode: '53426',
    city: 'Königsfeld'
  },
  {
    postalCode: '53426',
    city: 'Schalkenbach'
  },
  {
    postalCode: '53474',
    city: 'Bad Neuenahr-Ahrweiler'
  },
  {
    postalCode: '53489',
    city: 'Sinzig'
  },
  {
    postalCode: '53498',
    city: 'Bad Breisig'
  },
  {
    postalCode: '53498',
    city: 'Gönnersdorf'
  },
  {
    postalCode: '53498',
    city: 'Waldorf'
  },
  {
    postalCode: '53501',
    city: 'Grafschaft'
  },
  {
    postalCode: '53505',
    city: 'Altenahr'
  },
  {
    postalCode: '53505',
    city: 'Berg'
  },
  {
    postalCode: '53505',
    city: 'Kalenborn'
  },
  {
    postalCode: '53505',
    city: 'Kirchsahr'
  },
  {
    postalCode: '53506',
    city: 'Ahrbrück'
  },
  {
    postalCode: '53506',
    city: 'Heckenbach'
  },
  {
    postalCode: '53506',
    city: 'Hönningen'
  },
  {
    postalCode: '53506',
    city: 'Kesseling'
  },
  {
    postalCode: '53506',
    city: 'Lind'
  },
  {
    postalCode: '53506',
    city: 'Rech'
  },
  {
    postalCode: '53507',
    city: 'Dernau'
  },
  {
    postalCode: '53507',
    city: 'Marienthal'
  },
  {
    postalCode: '53508',
    city: 'Mayschoß'
  },
  {
    postalCode: '53518',
    city: 'Adenau'
  },
  {
    postalCode: '53518',
    city: 'Herschbroich'
  },
  {
    postalCode: '53518',
    city: 'Honerath'
  },
  {
    postalCode: '53518',
    city: 'Kottenborn'
  },
  {
    postalCode: '53518',
    city: 'Kottenbornermühle'
  },
  {
    postalCode: '53518',
    city: 'Leimbach'
  },
  {
    postalCode: '53518',
    city: 'Quiddelbach'
  },
  {
    postalCode: '53518',
    city: 'Wimbach'
  },
  {
    postalCode: '53520',
    city: 'Bierschbacher Mühle'
  },
  {
    postalCode: '53520',
    city: 'Dankerath'
  },
  {
    postalCode: '53520',
    city: 'Daubiansmühle'
  },
  {
    postalCode: '53520',
    city: 'Döttinger Höhe'
  },
  {
    postalCode: '53520',
    city: 'Drees'
  },
  {
    postalCode: '53520',
    city: 'Dümpelfeld'
  },
  {
    postalCode: '53520',
    city: 'Harscheid'
  },
  {
    postalCode: '53520',
    city: 'Hümmel'
  },
  {
    postalCode: '53520',
    city: 'Insul'
  },
  {
    postalCode: '53520',
    city: 'Kaltenborn'
  },
  {
    postalCode: '53520',
    city: 'Meuspath'
  },
  {
    postalCode: '53520',
    city: 'Müllenbach'
  },
  {
    postalCode: '53520',
    city: 'Nürburg'
  },
  {
    postalCode: '53520',
    city: 'Ohlenhard'
  },
  {
    postalCode: '53520',
    city: 'Reifferscheid'
  },
  {
    postalCode: '53520',
    city: 'Rodder'
  },
  {
    postalCode: '53520',
    city: 'Schuld'
  },
  {
    postalCode: '53520',
    city: 'Senscheid'
  },
  {
    postalCode: '53520',
    city: 'Sierscheid'
  },
  {
    postalCode: '53520',
    city: 'Trierscheid'
  },
  {
    postalCode: '53520',
    city: 'Wershofen'
  },
  {
    postalCode: '53520',
    city: 'Winnerath'
  },
  {
    postalCode: '53533',
    city: 'Antweiler'
  },
  {
    postalCode: '53533',
    city: 'Aremberg'
  },
  {
    postalCode: '53533',
    city: 'Dorsel'
  },
  {
    postalCode: '53533',
    city: 'Eichenbach'
  },
  {
    postalCode: '53533',
    city: 'Fuchshofen'
  },
  {
    postalCode: '53533',
    city: 'Laufenbacherhof'
  },
  {
    postalCode: '53533',
    city: 'Müsch'
  },
  {
    postalCode: '53533',
    city: 'Stahlhütte'
  },
  {
    postalCode: '53534',
    city: 'Barweiler'
  },
  {
    postalCode: '53534',
    city: 'Bauler'
  },
  {
    postalCode: '53534',
    city: 'Hoffeld'
  },
  {
    postalCode: '53534',
    city: 'Kirmutscheid'
  },
  {
    postalCode: '53534',
    city: 'Pomster'
  },
  {
    postalCode: '53534',
    city: 'Wiesemscheid'
  },
  {
    postalCode: '53534',
    city: 'Wirft'
  },
  {
    postalCode: '53539',
    city: 'Bodenbach'
  },
  {
    postalCode: '53539',
    city: 'Bongard'
  },
  {
    postalCode: '53539',
    city: 'Borler'
  },
  {
    postalCode: '53539',
    city: 'Brücktal'
  },
  {
    postalCode: '53539',
    city: 'Gelenberg'
  },
  {
    postalCode: '53539',
    city: 'Kelberg'
  },
  {
    postalCode: '53539',
    city: 'Kirsbach'
  },
  {
    postalCode: '53539',
    city: 'Reimerath'
  },
  {
    postalCode: '53539',
    city: 'Welcherath'
  },
  {
    postalCode: '53545',
    city: 'Linz'
  },
  {
    postalCode: '53545',
    city: 'Ockenfels'
  },
  {
    postalCode: '53547',
    city: 'Alsau'
  },
  {
    postalCode: '53547',
    city: 'Arnsau'
  },
  {
    postalCode: '53547',
    city: 'Breitscheid'
  },
  {
    postalCode: '53547',
    city: 'Dattenberg'
  },
  {
    postalCode: '53547',
    city: 'Girgenrath'
  },
  {
    postalCode: '53547',
    city: 'Grübelsberg'
  },
  {
    postalCode: '53547',
    city: 'Hausen'
  },
  {
    postalCode: '53547',
    city: 'Heeg'
  },
  {
    postalCode: '53547',
    city: 'Hümmerich'
  },
  {
    postalCode: '53547',
    city: 'Kasbach-Ohlenberg'
  },
  {
    postalCode: '53547',
    city: 'Krumscheid'
  },
  {
    postalCode: '53547',
    city: 'Leubsdorf'
  },
  {
    postalCode: '53547',
    city: 'Reidenbruch'
  },
  {
    postalCode: '53547',
    city: 'Roßbach'
  },
  {
    postalCode: '53557',
    city: 'Bad Hönningen'
  },
  {
    postalCode: '53560',
    city: 'Kretzhaus'
  },
  {
    postalCode: '53560',
    city: 'Vettelschoß'
  },
  {
    postalCode: '53562',
    city: 'Hähnen'
  },
  {
    postalCode: '53562',
    city: 'Rothekreuz'
  },
  {
    postalCode: '53562',
    city: 'St Katharinen'
  },
  {
    postalCode: '53567',
    city: 'Asbach'
  },
  {
    postalCode: '53567',
    city: 'Buchholz'
  },
  {
    postalCode: '53572',
    city: 'Bruchhausen'
  },
  {
    postalCode: '53572',
    city: 'Hohenunkel'
  },
  {
    postalCode: '53572',
    city: 'Unkel'
  },
  {
    postalCode: '53577',
    city: 'Neustadt'
  },
  {
    postalCode: '53578',
    city: 'Windhagen'
  },
  {
    postalCode: '53579',
    city: 'Erpel'
  },
  {
    postalCode: '53604',
    city: 'Bad Honnef'
  },
  {
    postalCode: '53619',
    city: 'Rheinbreitbach'
  },
  {
    postalCode: '53639',
    city: 'Königswinter'
  },
  {
    postalCode: '53721',
    city: 'Siegburg'
  },
  {
    postalCode: '53757',
    city: 'Sankt Augustin'
  },
  {
    postalCode: '53773',
    city: 'Hennef'
  },
  {
    postalCode: '53783',
    city: 'Eitorf'
  },
  {
    postalCode: '53797',
    city: 'Lohmar'
  },
  {
    postalCode: '53804',
    city: 'Much'
  },
  {
    postalCode: '53809',
    city: 'Ruppichteroth'
  },
  {
    postalCode: '53819',
    city: 'Neunkirchen-Seelscheid'
  },
  {
    postalCode: '53840',
    city: 'Troisdorf'
  },
  {
    postalCode: '53842',
    city: 'Troisdorf'
  },
  {
    postalCode: '53844',
    city: 'Troisdorf'
  },
  {
    postalCode: '53859',
    city: 'Niederkassel'
  },
  {
    postalCode: '53879',
    city: 'Euskirchen'
  },
  {
    postalCode: '53881',
    city: 'Euskirchen'
  },
  {
    postalCode: '53894',
    city: 'Mechernich'
  },
  {
    postalCode: '53902',
    city: 'Bad Münstereifel'
  },
  {
    postalCode: '53909',
    city: 'Zülpich'
  },
  {
    postalCode: '53913',
    city: 'Swisttal'
  },
  {
    postalCode: '53919',
    city: 'Weilerswist'
  },
  {
    postalCode: '53925',
    city: 'Kall'
  },
  {
    postalCode: '53937',
    city: 'Schleiden'
  },
  {
    postalCode: '53940',
    city: 'Hellenthal'
  },
  {
    postalCode: '53945',
    city: 'Blankenheim'
  },
  {
    postalCode: '53947',
    city: 'Nettersheim'
  },
  {
    postalCode: '53949',
    city: 'Dahlem'
  },
  {
    postalCode: '54290',
    city: 'Trier'
  },
  {
    postalCode: '54292',
    city: 'Trier'
  },
  {
    postalCode: '54293',
    city: 'Trier'
  },
  {
    postalCode: '54294',
    city: 'Trier'
  },
  {
    postalCode: '54295',
    city: 'Trier'
  },
  {
    postalCode: '54296',
    city: 'Trier'
  },
  {
    postalCode: '54298',
    city: 'Aach'
  },
  {
    postalCode: '54298',
    city: 'Eisenach'
  },
  {
    postalCode: '54298',
    city: 'Gilzem'
  },
  {
    postalCode: '54298',
    city: 'Höhjunk'
  },
  {
    postalCode: '54298',
    city: 'Igel'
  },
  {
    postalCode: '54298',
    city: 'Orenhofen'
  },
  {
    postalCode: '54298',
    city: 'Welschbillig'
  },
  {
    postalCode: '54306',
    city: 'Kordel'
  },
  {
    postalCode: '54308',
    city: 'Langsur'
  },
  {
    postalCode: '54309',
    city: 'Newel'
  },
  {
    postalCode: '54310',
    city: 'Menningen'
  },
  {
    postalCode: '54310',
    city: 'Minden'
  },
  {
    postalCode: '54310',
    city: 'Ralingen'
  },
  {
    postalCode: '54311',
    city: 'Trierweiler'
  },
  {
    postalCode: '54313',
    city: 'Zemmer'
  },
  {
    postalCode: '54314',
    city: 'Baldringen'
  },
  {
    postalCode: '54314',
    city: 'Benratherhof'
  },
  {
    postalCode: '54314',
    city: 'Greimerath'
  },
  {
    postalCode: '54314',
    city: 'Hentern'
  },
  {
    postalCode: '54314',
    city: 'Neunhäuser'
  },
  {
    postalCode: '54314',
    city: 'Paschel'
  },
  {
    postalCode: '54314',
    city: 'Schömerich'
  },
  {
    postalCode: '54314',
    city: 'Vierherrenborn'
  },
  {
    postalCode: '54314',
    city: 'Zerf'
  },
  {
    postalCode: '54316',
    city: 'Bonerath'
  },
  {
    postalCode: '54316',
    city: 'Franzenheim'
  },
  {
    postalCode: '54316',
    city: 'Hinzenburg'
  },
  {
    postalCode: '54316',
    city: 'Hockweiler'
  },
  {
    postalCode: '54316',
    city: 'Holzerath'
  },
  {
    postalCode: '54316',
    city: 'Lampaden'
  },
  {
    postalCode: '54316',
    city: 'Ollmuth'
  },
  {
    postalCode: '54316',
    city: 'Pluwig'
  },
  {
    postalCode: '54316',
    city: 'Schöndorf'
  },
  {
    postalCode: '54317',
    city: 'Farschweiler'
  },
  {
    postalCode: '54317',
    city: 'Gusterath'
  },
  {
    postalCode: '54317',
    city: 'Gutweiler'
  },
  {
    postalCode: '54317',
    city: 'Herl'
  },
  {
    postalCode: '54317',
    city: 'Kasel'
  },
  {
    postalCode: '54317',
    city: 'Korlingen'
  },
  {
    postalCode: '54317',
    city: 'Lorscheid'
  },
  {
    postalCode: '54317',
    city: 'Morscheid'
  },
  {
    postalCode: '54317',
    city: 'Osburg'
  },
  {
    postalCode: '54317',
    city: 'Riveris'
  },
  {
    postalCode: '54317',
    city: 'Sommerau'
  },
  {
    postalCode: '54317',
    city: 'Thomm'
  },
  {
    postalCode: '54318',
    city: 'Mertesdorf'
  },
  {
    postalCode: '54320',
    city: 'Waldrach'
  },
  {
    postalCode: '54329',
    city: 'Konz'
  },
  {
    postalCode: '54331',
    city: 'Oberbillig'
  },
  {
    postalCode: '54331',
    city: 'Pellingen'
  },
  {
    postalCode: '54332',
    city: 'Wasserliesch'
  },
  {
    postalCode: '54338',
    city: 'Longen'
  },
  {
    postalCode: '54338',
    city: 'Schweich'
  },
  {
    postalCode: '54340',
    city: 'Bekond'
  },
  {
    postalCode: '54340',
    city: 'Detzem'
  },
  {
    postalCode: '54340',
    city: 'Ensch'
  },
  {
    postalCode: '54340',
    city: 'Klüsserath'
  },
  {
    postalCode: '54340',
    city: 'Köwerich'
  },
  {
    postalCode: '54340',
    city: 'Leiwen'
  },
  {
    postalCode: '54340',
    city: 'Longuich'
  },
  {
    postalCode: '54340',
    city: 'Naurath'
  },
  {
    postalCode: '54340',
    city: 'Pölich'
  },
  {
    postalCode: '54340',
    city: 'Riol'
  },
  {
    postalCode: '54340',
    city: 'Schleich'
  },
  {
    postalCode: '54340',
    city: 'Thörnich'
  },
  {
    postalCode: '54341',
    city: 'Fell'
  },
  {
    postalCode: '54343',
    city: 'Föhren'
  },
  {
    postalCode: '54344',
    city: 'Kenn'
  },
  {
    postalCode: '54346',
    city: 'Mehring'
  },
  {
    postalCode: '54347',
    city: 'Neumagen-Dhron'
  },
  {
    postalCode: '54349',
    city: 'Trittenheim'
  },
  {
    postalCode: '54411',
    city: 'Deuselbach'
  },
  {
    postalCode: '54411',
    city: 'Hermeskeil'
  },
  {
    postalCode: '54411',
    city: 'Rorodt'
  },
  {
    postalCode: '54413',
    city: 'Bescheid'
  },
  {
    postalCode: '54413',
    city: 'Beuren'
  },
  {
    postalCode: '54413',
    city: 'Damflos'
  },
  {
    postalCode: '54413',
    city: 'Geisfeld'
  },
  {
    postalCode: '54413',
    city: 'Grimburg'
  },
  {
    postalCode: '54413',
    city: 'Gusenburg'
  },
  {
    postalCode: '54413',
    city: 'Rascheid'
  },
  {
    postalCode: '54421',
    city: 'Hinzert-Pölert'
  },
  {
    postalCode: '54421',
    city: 'Reinsfeld'
  },
  {
    postalCode: '54422',
    city: 'Börfink'
  },
  {
    postalCode: '54422',
    city: 'Neuhof'
  },
  {
    postalCode: '54422',
    city: 'Neuhütten'
  },
  {
    postalCode: '54422',
    city: 'Züsch'
  },
  {
    postalCode: '54424',
    city: 'Burtscheid'
  },
  {
    postalCode: '54424',
    city: 'Etgert'
  },
  {
    postalCode: '54424',
    city: 'Gielert'
  },
  {
    postalCode: '54424',
    city: 'Lückenburg'
  },
  {
    postalCode: '54424',
    city: 'Thalfang'
  },
  {
    postalCode: '54426',
    city: 'Berglicht'
  },
  {
    postalCode: '54426',
    city: 'Breit'
  },
  {
    postalCode: '54426',
    city: 'Büdlich'
  },
  {
    postalCode: '54426',
    city: 'Dhronecken'
  },
  {
    postalCode: '54426',
    city: 'Gräfendhron'
  },
  {
    postalCode: '54426',
    city: 'Heidenburg'
  },
  {
    postalCode: '54426',
    city: 'Hilscheid'
  },
  {
    postalCode: '54426',
    city: 'Immert'
  },
  {
    postalCode: '54426',
    city: 'Malborn'
  },
  {
    postalCode: '54426',
    city: 'Merschbach'
  },
  {
    postalCode: '54426',
    city: 'Naurath'
  },
  {
    postalCode: '54426',
    city: 'Neunkirchen'
  },
  {
    postalCode: '54426',
    city: 'Schönberg'
  },
  {
    postalCode: '54426',
    city: 'Talling'
  },
  {
    postalCode: '54427',
    city: 'Kell'
  },
  {
    postalCode: '54427',
    city: 'Mühlscheid'
  },
  {
    postalCode: '54429',
    city: 'Heddert'
  },
  {
    postalCode: '54429',
    city: 'Mandern'
  },
  {
    postalCode: '54429',
    city: 'Schillingen'
  },
  {
    postalCode: '54429',
    city: 'Waldweiler'
  },
  {
    postalCode: '54439',
    city: 'Fisch'
  },
  {
    postalCode: '54439',
    city: 'Merzkirchen'
  },
  {
    postalCode: '54439',
    city: 'Palzem'
  },
  {
    postalCode: '54439',
    city: 'Saarburg'
  },
  {
    postalCode: '54441',
    city: 'Ayl'
  },
  {
    postalCode: '54441',
    city: 'Kanzem'
  },
  {
    postalCode: '54441',
    city: 'Kastel-Staadt'
  },
  {
    postalCode: '54441',
    city: 'Kirf'
  },
  {
    postalCode: '54441',
    city: 'Mannebach'
  },
  {
    postalCode: '54441',
    city: 'Ockfen'
  },
  {
    postalCode: '54441',
    city: 'Schoden'
  },
  {
    postalCode: '54441',
    city: 'Taben-Rodt'
  },
  {
    postalCode: '54441',
    city: 'Temmels'
  },
  {
    postalCode: '54441',
    city: 'Trassem'
  },
  {
    postalCode: '54441',
    city: 'Wawern'
  },
  {
    postalCode: '54441',
    city: 'Wellen'
  },
  {
    postalCode: '54450',
    city: 'Freudenburg'
  },
  {
    postalCode: '54451',
    city: 'Irsch'
  },
  {
    postalCode: '54453',
    city: 'Nittel'
  },
  {
    postalCode: '54455',
    city: 'Serrig'
  },
  {
    postalCode: '54456',
    city: 'Onsdorf'
  },
  {
    postalCode: '54456',
    city: 'Tawern'
  },
  {
    postalCode: '54457',
    city: 'Wincheringen'
  },
  {
    postalCode: '54459',
    city: 'Wiltingen'
  },
  {
    postalCode: '54470',
    city: 'Bernkastel-Kues'
  },
  {
    postalCode: '54470',
    city: 'Graach'
  },
  {
    postalCode: '54470',
    city: 'Hirzlei'
  },
  {
    postalCode: '54470',
    city: 'Lieser'
  },
  {
    postalCode: '54472',
    city: 'Brauneberg'
  },
  {
    postalCode: '54472',
    city: 'Burgen'
  },
  {
    postalCode: '54472',
    city: 'Gornhausen'
  },
  {
    postalCode: '54472',
    city: 'Hochscheid'
  },
  {
    postalCode: '54472',
    city: 'Kommen'
  },
  {
    postalCode: '54472',
    city: 'Longkamp'
  },
  {
    postalCode: '54472',
    city: 'Monzelfeld'
  },
  {
    postalCode: '54472',
    city: 'Veldenz'
  },
  {
    postalCode: '54483',
    city: 'Kleinich'
  },
  {
    postalCode: '54484',
    city: 'Maring-Noviand'
  },
  {
    postalCode: '54486',
    city: 'Mülheim'
  },
  {
    postalCode: '54487',
    city: 'Wintrich'
  },
  {
    postalCode: '54492',
    city: 'Altmachern'
  },
  {
    postalCode: '54492',
    city: 'Erden'
  },
  {
    postalCode: '54492',
    city: 'Klosterberg'
  },
  {
    postalCode: '54492',
    city: 'Lösnich'
  },
  {
    postalCode: '54492',
    city: 'Machern'
  },
  {
    postalCode: '54492',
    city: 'Zeltingen-Rachtig'
  },
  {
    postalCode: '54497',
    city: 'Horath'
  },
  {
    postalCode: '54497',
    city: 'Morbach'
  },
  {
    postalCode: '54498',
    city: 'Piesport'
  },
  {
    postalCode: '54516',
    city: 'Flußbach'
  },
  {
    postalCode: '54516',
    city: 'Wittlich'
  },
  {
    postalCode: '54518',
    city: 'Altrich'
  },
  {
    postalCode: '54518',
    city: 'Arenrath'
  },
  {
    postalCode: '54518',
    city: 'Bergweiler'
  },
  {
    postalCode: '54518',
    city: 'Binsfeld'
  },
  {
    postalCode: '54518',
    city: 'Bruch'
  },
  {
    postalCode: '54518',
    city: 'Dodenburg'
  },
  {
    postalCode: '54518',
    city: 'Dreis'
  },
  {
    postalCode: '54518',
    city: 'Esch'
  },
  {
    postalCode: '54518',
    city: 'Gladbach'
  },
  {
    postalCode: '54518',
    city: 'Heckenmünster'
  },
  {
    postalCode: '54518',
    city: 'Heidweiler'
  },
  {
    postalCode: '54518',
    city: 'Hupperath'
  },
  {
    postalCode: '54518',
    city: 'Kaisermühle'
  },
  {
    postalCode: '54518',
    city: 'Kesten'
  },
  {
    postalCode: '54518',
    city: 'Minderlittgen'
  },
  {
    postalCode: '54518',
    city: 'Minheim'
  },
  {
    postalCode: '54518',
    city: 'Niersbach'
  },
  {
    postalCode: '54518',
    city: 'Osann-Monzel'
  },
  {
    postalCode: '54518',
    city: 'Platten'
  },
  {
    postalCode: '54518',
    city: 'Plein'
  },
  {
    postalCode: '54518',
    city: 'Rivenich'
  },
  {
    postalCode: '54518',
    city: 'Sehlem'
  },
  {
    postalCode: '54523',
    city: 'Dierscheid'
  },
  {
    postalCode: '54523',
    city: 'Hetzerath'
  },
  {
    postalCode: '54523',
    city: 'Kalbergerhof'
  },
  {
    postalCode: '54524',
    city: 'Klausen'
  },
  {
    postalCode: '54526',
    city: 'Landscheid'
  },
  {
    postalCode: '54528',
    city: 'Salmtal'
  },
  {
    postalCode: '54529',
    city: 'Spangdahlem'
  },
  {
    postalCode: '54531',
    city: 'Buchholz, Gem Eckfeld'
  },
  {
    postalCode: '54531',
    city: 'Buchholz, Gem Pantenburg'
  },
  {
    postalCode: '54531',
    city: 'Eckfeld'
  },
  {
    postalCode: '54531',
    city: 'Manderscheid'
  },
  {
    postalCode: '54531',
    city: 'Meerfeld'
  },
  {
    postalCode: '54531',
    city: 'Pantenburg'
  },
  {
    postalCode: '54531',
    city: 'Wallscheid'
  },
  {
    postalCode: '54533',
    city: 'Bettenfeld'
  },
  {
    postalCode: '54533',
    city: 'Biermühle'
  },
  {
    postalCode: '54533',
    city: 'Dierfeld'
  },
  {
    postalCode: '54533',
    city: 'Eisenschmitt'
  },
  {
    postalCode: '54533',
    city: 'Gipperath'
  },
  {
    postalCode: '54533',
    city: 'Gransdorf'
  },
  {
    postalCode: '54533',
    city: 'Greimerath'
  },
  {
    postalCode: '54533',
    city: 'Hasborn'
  },
  {
    postalCode: '54533',
    city: 'Laufeld'
  },
  {
    postalCode: '54533',
    city: 'Niederöfflingen'
  },
  {
    postalCode: '54533',
    city: 'Niederscheidweiler'
  },
  {
    postalCode: '54533',
    city: 'Oberkail'
  },
  {
    postalCode: '54533',
    city: 'Oberöfflingen'
  },
  {
    postalCode: '54533',
    city: 'Oberscheidweiler'
  },
  {
    postalCode: '54533',
    city: 'Salmberg'
  },
  {
    postalCode: '54533',
    city: 'Schwarzenborn'
  },
  {
    postalCode: '54533',
    city: 'Willwerscheid'
  },
  {
    postalCode: '54534',
    city: 'Großlittgen'
  },
  {
    postalCode: '54534',
    city: 'Karl'
  },
  {
    postalCode: '54534',
    city: 'Musweiler'
  },
  {
    postalCode: '54534',
    city: 'Schladt'
  },
  {
    postalCode: '54536',
    city: 'Kröv'
  },
  {
    postalCode: '54538',
    city: 'Bausendorf'
  },
  {
    postalCode: '54538',
    city: 'Bengel'
  },
  {
    postalCode: '54538',
    city: 'Diefenbach'
  },
  {
    postalCode: '54538',
    city: 'Engelsberg'
  },
  {
    postalCode: '54538',
    city: 'Hontheim'
  },
  {
    postalCode: '54538',
    city: 'Kinderbeuern'
  },
  {
    postalCode: '54538',
    city: 'Kinheim'
  },
  {
    postalCode: '54538',
    city: 'Melchhof'
  },
  {
    postalCode: '54538',
    city: 'Neidhof'
  },
  {
    postalCode: '54539',
    city: 'Ürzig'
  },
  {
    postalCode: '54539',
    city: 'Zeltingen, Forsthaus'
  },
  {
    postalCode: '54550',
    city: 'Daun'
  },
  {
    postalCode: '54552',
    city: 'Beinhausen'
  },
  {
    postalCode: '54552',
    city: 'Boxberg'
  },
  {
    postalCode: '54552',
    city: 'Brockscheid'
  },
  {
    postalCode: '54552',
    city: 'Darscheid'
  },
  {
    postalCode: '54552',
    city: 'Demerath'
  },
  {
    postalCode: '54552',
    city: 'Dockweiler'
  },
  {
    postalCode: '54552',
    city: 'Dreis-Brück'
  },
  {
    postalCode: '54552',
    city: 'Ellscheid'
  },
  {
    postalCode: '54552',
    city: 'Gefell'
  },
  {
    postalCode: '54552',
    city: 'Hörscheid'
  },
  {
    postalCode: '54552',
    city: 'Hörschhausen'
  },
  {
    postalCode: '54552',
    city: 'Immerath'
  },
  {
    postalCode: '54552',
    city: 'Katzwinkel'
  },
  {
    postalCode: '54552',
    city: 'Kradenbach'
  },
  {
    postalCode: '54552',
    city: 'Mehren'
  },
  {
    postalCode: '54552',
    city: 'Neichen'
  },
  {
    postalCode: '54552',
    city: 'Nerdlen'
  },
  {
    postalCode: '54552',
    city: 'Sarmersbach'
  },
  {
    postalCode: '54552',
    city: 'Schalkenmehren'
  },
  {
    postalCode: '54552',
    city: 'Schönbach'
  },
  {
    postalCode: '54552',
    city: 'Steineberg'
  },
  {
    postalCode: '54552',
    city: 'Steiningen'
  },
  {
    postalCode: '54552',
    city: 'Strotzbüsch'
  },
  {
    postalCode: '54552',
    city: 'Üdersdorf'
  },
  {
    postalCode: '54552',
    city: 'Udler'
  },
  {
    postalCode: '54552',
    city: 'Utzerath'
  },
  {
    postalCode: '54558',
    city: 'Gillenfeld'
  },
  {
    postalCode: '54558',
    city: 'Mückeln'
  },
  {
    postalCode: '54558',
    city: 'Saxler'
  },
  {
    postalCode: '54558',
    city: 'Sprink'
  },
  {
    postalCode: '54558',
    city: 'Strohn'
  },
  {
    postalCode: '54558',
    city: 'Winkel'
  },
  {
    postalCode: '54568',
    city: 'Gerolstein'
  },
  {
    postalCode: '54570',
    city: 'Berlingen'
  },
  {
    postalCode: '54570',
    city: 'Betteldorf'
  },
  {
    postalCode: '54570',
    city: 'Bleckhausen'
  },
  {
    postalCode: '54570',
    city: 'Bleckhausener Mühle'
  },
  {
    postalCode: '54570',
    city: 'Densborn'
  },
  {
    postalCode: '54570',
    city: 'Deudesfeld'
  },
  {
    postalCode: '54570',
    city: 'Hinterweiler'
  },
  {
    postalCode: '54570',
    city: 'Hohenfels-Essingen'
  },
  {
    postalCode: '54570',
    city: 'Kalenborn-Scheuern'
  },
  {
    postalCode: '54570',
    city: 'Kirchweiler'
  },
  {
    postalCode: '54570',
    city: 'Meisburg'
  },
  {
    postalCode: '54570',
    city: 'Mürlenbach'
  },
  {
    postalCode: '54570',
    city: 'Neroth'
  },
  {
    postalCode: '54570',
    city: 'Niederstadtfeld'
  },
  {
    postalCode: '54570',
    city: 'Oberstadtfeld'
  },
  {
    postalCode: '54570',
    city: 'Pelm'
  },
  {
    postalCode: '54570',
    city: 'Rockeskyll'
  },
  {
    postalCode: '54570',
    city: 'Salm'
  },
  {
    postalCode: '54570',
    city: 'Sauerseifen'
  },
  {
    postalCode: '54570',
    city: 'Schutz'
  },
  {
    postalCode: '54570',
    city: 'Wallenborn'
  },
  {
    postalCode: '54570',
    city: 'Weidenbach'
  },
  {
    postalCode: '54574',
    city: 'Birresborn'
  },
  {
    postalCode: '54574',
    city: 'Kopp'
  },
  {
    postalCode: '54574',
    city: 'Rom'
  },
  {
    postalCode: '54576',
    city: 'Dohm-Lammersdorf'
  },
  {
    postalCode: '54576',
    city: 'Hillesheim'
  },
  {
    postalCode: '54578',
    city: 'Basberg'
  },
  {
    postalCode: '54578',
    city: 'Berndorf'
  },
  {
    postalCode: '54578',
    city: 'Kerpen'
  },
  {
    postalCode: '54578',
    city: 'Nohn'
  },
  {
    postalCode: '54578',
    city: 'Oberbettingen'
  },
  {
    postalCode: '54578',
    city: 'Oberehe-Stroheich'
  },
  {
    postalCode: '54578',
    city: 'Walsdorf'
  },
  {
    postalCode: '54578',
    city: 'Wiesbaum'
  },
  {
    postalCode: '54579',
    city: 'Üxheim'
  },
  {
    postalCode: '54584',
    city: 'Feusdorf'
  },
  {
    postalCode: '54584',
    city: 'Gönnersdorf'
  },
  {
    postalCode: '54584',
    city: 'Jünkerath'
  },
  {
    postalCode: '54585',
    city: 'Esch'
  },
  {
    postalCode: '54586',
    city: 'Schüller'
  },
  {
    postalCode: '54587',
    city: 'Birgel'
  },
  {
    postalCode: '54587',
    city: 'Lissendorf'
  },
  {
    postalCode: '54589',
    city: 'Kerschenbach'
  },
  {
    postalCode: '54589',
    city: 'Stadtkyll'
  },
  {
    postalCode: '54595',
    city: 'Gondenbrett'
  },
  {
    postalCode: '54595',
    city: 'Orlenbach'
  },
  {
    postalCode: '54595',
    city: 'Pittenbach'
  },
  {
    postalCode: '54595',
    city: 'Prüm'
  },
  {
    postalCode: '54595',
    city: 'Watzerath'
  },
  {
    postalCode: '54595',
    city: 'Weinsheim'
  },
  {
    postalCode: '54597',
    city: 'Auw'
  },
  {
    postalCode: '54597',
    city: 'Balesfeld'
  },
  {
    postalCode: '54597',
    city: 'Burbach'
  },
  {
    postalCode: '54597',
    city: 'Denterhof'
  },
  {
    postalCode: '54597',
    city: 'Duppach'
  },
  {
    postalCode: '54597',
    city: 'Euscheid'
  },
  {
    postalCode: '54597',
    city: 'Feuerscheid'
  },
  {
    postalCode: '54597',
    city: 'Fleringen'
  },
  {
    postalCode: '54597',
    city: 'Gesotz, Gem Feuerscheid'
  },
  {
    postalCode: '54597',
    city: 'Gesotz, Gem Hargarten'
  },
  {
    postalCode: '54597',
    city: 'Gesotz, Gem Plütscheid'
  },
  {
    postalCode: '54597',
    city: 'Habscheid'
  },
  {
    postalCode: '54597',
    city: 'Hersdorf'
  },
  {
    postalCode: '54597',
    city: 'Hofswald'
  },
  {
    postalCode: '54597',
    city: 'Kinzenburg'
  },
  {
    postalCode: '54597',
    city: 'Kleinlangenfeld'
  },
  {
    postalCode: '54597',
    city: 'Lascheid'
  },
  {
    postalCode: '54597',
    city: 'Lierfeld'
  },
  {
    postalCode: '54597',
    city: 'Lünebach'
  },
  {
    postalCode: '54597',
    city: 'Masthorn'
  },
  {
    postalCode: '54597',
    city: 'Matzerath'
  },
  {
    postalCode: '54597',
    city: 'Merlscheid'
  },
  {
    postalCode: '54597',
    city: 'Neuendorf'
  },
  {
    postalCode: '54597',
    city: 'Neuheilenbach'
  },
  {
    postalCode: '54597',
    city: 'Olzheim'
  },
  {
    postalCode: '54597',
    city: 'Ormont'
  },
  {
    postalCode: '54597',
    city: 'Plütscheid'
  },
  {
    postalCode: '54597',
    city: 'Pronsfeld'
  },
  {
    postalCode: '54597',
    city: 'Reuth'
  },
  {
    postalCode: '54597',
    city: 'Rommersheim'
  },
  {
    postalCode: '54597',
    city: 'Roth'
  },
  {
    postalCode: '54597',
    city: 'Schwarzbach'
  },
  {
    postalCode: '54597',
    city: 'Schwirzheim'
  },
  {
    postalCode: '54597',
    city: 'Seiwerath'
  },
  {
    postalCode: '54597',
    city: 'Steffeln'
  },
  {
    postalCode: '54597',
    city: 'Strickscheid'
  },
  {
    postalCode: '54597',
    city: 'Wallersheim'
  },
  {
    postalCode: '54597',
    city: 'Weißenseifen, Gem Mürlenbach'
  },
  {
    postalCode: '54597',
    city: 'Weißenseifen, Gem Wallersheim'
  },
  {
    postalCode: '54608',
    city: 'Bleialf'
  },
  {
    postalCode: '54608',
    city: 'Brandscheid'
  },
  {
    postalCode: '54608',
    city: 'Buchet'
  },
  {
    postalCode: '54608',
    city: 'Großlangenfeld'
  },
  {
    postalCode: '54608',
    city: 'Mützenich'
  },
  {
    postalCode: '54608',
    city: 'Oberlascheid'
  },
  {
    postalCode: '54608',
    city: 'Schweiler'
  },
  {
    postalCode: '54608',
    city: 'Sellerich'
  },
  {
    postalCode: '54608',
    city: 'Winterscheid'
  },
  {
    postalCode: '54610',
    city: 'Büdesheim'
  },
  {
    postalCode: '54611',
    city: 'Hallschlag'
  },
  {
    postalCode: '54611',
    city: 'Scheid'
  },
  {
    postalCode: '54612',
    city: 'Lasel'
  },
  {
    postalCode: '54612',
    city: 'Nimshuscheid'
  },
  {
    postalCode: '54612',
    city: 'Wawern'
  },
  {
    postalCode: '54614',
    city: 'Dingdorf'
  },
  {
    postalCode: '54614',
    city: 'Giesdorf'
  },
  {
    postalCode: '54614',
    city: 'Heisdorf'
  },
  {
    postalCode: '54614',
    city: 'Niederlauch'
  },
  {
    postalCode: '54614',
    city: 'Nimsreuland'
  },
  {
    postalCode: '54614',
    city: 'Oberlauch'
  },
  {
    postalCode: '54614',
    city: 'Schönecken'
  },
  {
    postalCode: '54614',
    city: 'Winringen'
  },
  {
    postalCode: '54616',
    city: 'Winterspelt'
  },
  {
    postalCode: '54617',
    city: 'Harspelt'
  },
  {
    postalCode: '54617',
    city: 'Lützkampen'
  },
  {
    postalCode: '54617',
    city: 'Sevenig'
  },
  {
    postalCode: '54619',
    city: 'Banzenhof'
  },
  {
    postalCode: '54619',
    city: 'Dackscheid'
  },
  {
    postalCode: '54619',
    city: 'Dromigt'
  },
  {
    postalCode: '54619',
    city: 'Eschfeld'
  },
  {
    postalCode: '54619',
    city: 'Großkampenberg'
  },
  {
    postalCode: '54619',
    city: 'Heckhuscheid'
  },
  {
    postalCode: '54619',
    city: 'Herzfeld'
  },
  {
    postalCode: '54619',
    city: 'Kesfeld'
  },
  {
    postalCode: '54619',
    city: 'Leidenborn'
  },
  {
    postalCode: '54619',
    city: 'Lichtenborn'
  },
  {
    postalCode: '54619',
    city: 'Reiff'
  },
  {
    postalCode: '54619',
    city: 'Roscheid'
  },
  {
    postalCode: '54619',
    city: 'Sengerich'
  },
  {
    postalCode: '54619',
    city: 'Spielmannsholz'
  },
  {
    postalCode: '54619',
    city: 'Üttfeld'
  },
  {
    postalCode: '54634',
    city: 'Birtlingen'
  },
  {
    postalCode: '54634',
    city: 'Bitburg'
  },
  {
    postalCode: '54634',
    city: 'Metterich'
  },
  {
    postalCode: '54634',
    city: 'Niederstedem'
  },
  {
    postalCode: '54634',
    city: 'Oberstedem'
  },
  {
    postalCode: '54636',
    city: 'Altenhof'
  },
  {
    postalCode: '54636',
    city: 'Altscheid'
  },
  {
    postalCode: '54636',
    city: 'Baustert'
  },
  {
    postalCode: '54636',
    city: 'Bickendorf'
  },
  {
    postalCode: '54636',
    city: 'Biersdorf am See'
  },
  {
    postalCode: '54636',
    city: 'Brecht'
  },
  {
    postalCode: '54636',
    city: 'Dahlem'
  },
  {
    postalCode: '54636',
    city: 'Dockendorf'
  },
  {
    postalCode: '54636',
    city: 'Echtershausen'
  },
  {
    postalCode: '54636',
    city: 'Ehlenz'
  },
  {
    postalCode: '54636',
    city: 'Eßlingen'
  },
  {
    postalCode: '54636',
    city: 'Feilsdorf'
  },
  {
    postalCode: '54636',
    city: 'Fließem'
  },
  {
    postalCode: '54636',
    city: 'Hamm'
  },
  {
    postalCode: '54636',
    city: 'Heilenbach'
  },
  {
    postalCode: '54636',
    city: 'Hütterscheid'
  },
  {
    postalCode: '54636',
    city: 'Hüttingen'
  },
  {
    postalCode: '54636',
    city: 'Idenheim'
  },
  {
    postalCode: '54636',
    city: 'Idesheim'
  },
  {
    postalCode: '54636',
    city: 'Ingendorf'
  },
  {
    postalCode: '54636',
    city: 'Ließem'
  },
  {
    postalCode: '54636',
    city: 'Meckel'
  },
  {
    postalCode: '54636',
    city: 'Meilbrück'
  },
  {
    postalCode: '54636',
    city: 'Merkeshausen'
  },
  {
    postalCode: '54636',
    city: 'Messerich'
  },
  {
    postalCode: '54636',
    city: 'Mülbach'
  },
  {
    postalCode: '54636',
    city: 'Nattenheim'
  },
  {
    postalCode: '54636',
    city: 'Neumühle'
  },
  {
    postalCode: '54636',
    city: 'Niederweiler'
  },
  {
    postalCode: '54636',
    city: 'Oberweiler'
  },
  {
    postalCode: '54636',
    city: 'Oberweis'
  },
  {
    postalCode: '54636',
    city: 'Rittermühle'
  },
  {
    postalCode: '54636',
    city: 'Rittersdorf'
  },
  {
    postalCode: '54636',
    city: 'Röhl'
  },
  {
    postalCode: '54636',
    city: 'Scharfbillig'
  },
  {
    postalCode: '54636',
    city: 'Schleid'
  },
  {
    postalCode: '54636',
    city: 'Seffern'
  },
  {
    postalCode: '54636',
    city: 'Sefferweich'
  },
  {
    postalCode: '54636',
    city: 'Sülm'
  },
  {
    postalCode: '54636',
    city: 'Trimport'
  },
  {
    postalCode: '54636',
    city: 'Weidingen'
  },
  {
    postalCode: '54636',
    city: 'Wiersdorf'
  },
  {
    postalCode: '54636',
    city: 'Wißmannsdorf'
  },
  {
    postalCode: '54636',
    city: 'Wolsfeld'
  },
  {
    postalCode: '54646',
    city: 'Bettingen'
  },
  {
    postalCode: '54646',
    city: 'Brimingen'
  },
  {
    postalCode: '54646',
    city: 'Burg'
  },
  {
    postalCode: '54646',
    city: 'Enzen'
  },
  {
    postalCode: '54646',
    city: 'Halsdorf'
  },
  {
    postalCode: '54646',
    city: 'Hisel'
  },
  {
    postalCode: '54646',
    city: 'Niehl'
  },
  {
    postalCode: '54646',
    city: 'Olsdorf'
  },
  {
    postalCode: '54646',
    city: 'Stockem'
  },
  {
    postalCode: '54646',
    city: 'Wettlingen'
  },
  {
    postalCode: '54647',
    city: 'Dudeldorf'
  },
  {
    postalCode: '54647',
    city: 'Gondorf'
  },
  {
    postalCode: '54647',
    city: 'Pickließem'
  },
  {
    postalCode: '54649',
    city: 'Dackscheid'
  },
  {
    postalCode: '54649',
    city: 'Eilscheid'
  },
  {
    postalCode: '54649',
    city: 'Hargarten'
  },
  {
    postalCode: '54649',
    city: 'Lambertsberg'
  },
  {
    postalCode: '54649',
    city: 'Lauperath'
  },
  {
    postalCode: '54649',
    city: 'Luppertsseifen, Gem Burscheid'
  },
  {
    postalCode: '54649',
    city: 'Manderscheid'
  },
  {
    postalCode: '54649',
    city: 'Mauel'
  },
  {
    postalCode: '54649',
    city: 'Niederpierscheid'
  },
  {
    postalCode: '54649',
    city: 'Oberpierscheid'
  },
  {
    postalCode: '54649',
    city: 'Pintesfeld'
  },
  {
    postalCode: '54649',
    city: 'Waxweiler'
  },
  {
    postalCode: '54655',
    city: 'Altenhof'
  },
  {
    postalCode: '54655',
    city: 'Etteldorf'
  },
  {
    postalCode: '54655',
    city: 'Friedbüsch'
  },
  {
    postalCode: '54655',
    city: 'Kyllburg'
  },
  {
    postalCode: '54655',
    city: 'Kyllburgweiler'
  },
  {
    postalCode: '54655',
    city: 'Malberg'
  },
  {
    postalCode: '54655',
    city: 'Malbergweich'
  },
  {
    postalCode: '54655',
    city: 'Orsfeld'
  },
  {
    postalCode: '54655',
    city: 'Sankt Thomas'
  },
  {
    postalCode: '54655',
    city: 'Seinsfeld'
  },
  {
    postalCode: '54655',
    city: 'Steinborn'
  },
  {
    postalCode: '54655',
    city: 'Usch'
  },
  {
    postalCode: '54655',
    city: 'Wilsecker'
  },
  {
    postalCode: '54655',
    city: 'Zendscheid'
  },
  {
    postalCode: '54657',
    city: 'Badem'
  },
  {
    postalCode: '54657',
    city: 'Gindorf'
  },
  {
    postalCode: '54657',
    city: 'Neidenbach'
  },
  {
    postalCode: '54662',
    city: 'Beilingen'
  },
  {
    postalCode: '54662',
    city: 'Herforst'
  },
  {
    postalCode: '54662',
    city: 'Pfalzkyll'
  },
  {
    postalCode: '54662',
    city: 'Philippsheim'
  },
  {
    postalCode: '54662',
    city: 'Speicher'
  },
  {
    postalCode: '54664',
    city: 'Auw'
  },
  {
    postalCode: '54664',
    city: 'Hosten'
  },
  {
    postalCode: '54664',
    city: 'Preist'
  },
  {
    postalCode: '54666',
    city: 'Irrel'
  },
  {
    postalCode: '54668',
    city: 'Alsdorf'
  },
  {
    postalCode: '54668',
    city: 'Diesburgerhof'
  },
  {
    postalCode: '54668',
    city: 'Echternacherbrück'
  },
  {
    postalCode: '54668',
    city: 'Ernzen'
  },
  {
    postalCode: '54668',
    city: 'Ferschweiler'
  },
  {
    postalCode: '54668',
    city: 'Holsthum'
  },
  {
    postalCode: '54668',
    city: 'Kaschenbach'
  },
  {
    postalCode: '54668',
    city: 'Neu-Diesburgerhof'
  },
  {
    postalCode: '54668',
    city: 'Niederweis'
  },
  {
    postalCode: '54668',
    city: 'Peffingen'
  },
  {
    postalCode: '54668',
    city: 'Prümzurlay'
  },
  {
    postalCode: '54668',
    city: 'Schankweiler'
  },
  {
    postalCode: '54669',
    city: 'Bollendorf'
  },
  {
    postalCode: '54673',
    city: 'Ammeldingen'
  },
  {
    postalCode: '54673',
    city: 'Bauler'
  },
  {
    postalCode: '54673',
    city: 'Berkoth'
  },
  {
    postalCode: '54673',
    city: 'Berscheid'
  },
  {
    postalCode: '54673',
    city: 'Burscheid'
  },
  {
    postalCode: '54673',
    city: 'Dauwelshausen'
  },
  {
    postalCode: '54673',
    city: 'Emmelbaum'
  },
  {
    postalCode: '54673',
    city: 'Falkenauel'
  },
  {
    postalCode: '54673',
    city: 'Gaymühle, Gem Bauler'
  },
  {
    postalCode: '54673',
    city: 'Gaymühle, Gem Berscheid'
  },
  {
    postalCode: '54673',
    city: 'Gemünd'
  },
  {
    postalCode: '54673',
    city: 'Heilbach'
  },
  {
    postalCode: '54673',
    city: 'Herbstmühle'
  },
  {
    postalCode: '54673',
    city: 'Hütten'
  },
  {
    postalCode: '54673',
    city: 'Karlshausen'
  },
  {
    postalCode: '54673',
    city: 'Keppeshausen'
  },
  {
    postalCode: '54673',
    city: 'Koxhausen'
  },
  {
    postalCode: '54673',
    city: 'Krautscheid'
  },
  {
    postalCode: '54673',
    city: 'Leimbach'
  },
  {
    postalCode: '54673',
    city: 'Machtemesmühle'
  },
  {
    postalCode: '54673',
    city: 'Muxerath'
  },
  {
    postalCode: '54673',
    city: 'Nasingen'
  },
  {
    postalCode: '54673',
    city: 'Neuerburg'
  },
  {
    postalCode: '54673',
    city: 'Plascheid'
  },
  {
    postalCode: '54673',
    city: 'Preischeiderley'
  },
  {
    postalCode: '54673',
    city: 'Rodershausen'
  },
  {
    postalCode: '54673',
    city: 'Sauerwies'
  },
  {
    postalCode: '54673',
    city: 'Scheitenkorb'
  },
  {
    postalCode: '54673',
    city: 'Scheuern'
  },
  {
    postalCode: '54673',
    city: 'Schwabert'
  },
  {
    postalCode: '54673',
    city: 'Sevenig'
  },
  {
    postalCode: '54673',
    city: 'Uppershausen'
  },
  {
    postalCode: '54673',
    city: 'Waldhof-Falkenstein'
  },
  {
    postalCode: '54673',
    city: 'Zweifelscheid'
  },
  {
    postalCode: '54675',
    city: 'Ammeldingen'
  },
  {
    postalCode: '54675',
    city: 'Biesdorf'
  },
  {
    postalCode: '54675',
    city: 'Fischbach-Oberraden'
  },
  {
    postalCode: '54675',
    city: 'Gaymühle, Gem Biesdorf'
  },
  {
    postalCode: '54675',
    city: 'Gaymühle, Gem Wallendorf'
  },
  {
    postalCode: '54675',
    city: 'Geichlingen'
  },
  {
    postalCode: '54675',
    city: 'Gentingen'
  },
  {
    postalCode: '54675',
    city: 'Hommerdingen'
  },
  {
    postalCode: '54675',
    city: 'Hüttingen'
  },
  {
    postalCode: '54675',
    city: 'Körperich'
  },
  {
    postalCode: '54675',
    city: 'Kruchten'
  },
  {
    postalCode: '54675',
    city: 'Lahr'
  },
  {
    postalCode: '54675',
    city: 'Mettendorf'
  },
  {
    postalCode: '54675',
    city: 'Niedergeckler'
  },
  {
    postalCode: '54675',
    city: 'Niederraden'
  },
  {
    postalCode: '54675',
    city: 'Nusbaum'
  },
  {
    postalCode: '54675',
    city: 'Obergeckler'
  },
  {
    postalCode: '54675',
    city: 'Roth'
  },
  {
    postalCode: '54675',
    city: 'Sinspelt'
  },
  {
    postalCode: '54675',
    city: 'Utscheid'
  },
  {
    postalCode: '54675',
    city: 'Wallendorf'
  },
  {
    postalCode: '54687',
    city: 'Arzfeld'
  },
  {
    postalCode: '54689',
    city: 'Affler'
  },
  {
    postalCode: '54689',
    city: 'Dahnen'
  },
  {
    postalCode: '54689',
    city: 'Daleiden'
  },
  {
    postalCode: '54689',
    city: 'Dasburg'
  },
  {
    postalCode: '54689',
    city: 'Irrhausen'
  },
  {
    postalCode: '54689',
    city: 'Jucken'
  },
  {
    postalCode: '54689',
    city: 'Kickeshausen'
  },
  {
    postalCode: '54689',
    city: 'Olmscheid'
  },
  {
    postalCode: '54689',
    city: 'Preischeid'
  },
  {
    postalCode: '54689',
    city: 'Reipeldingen'
  },
  {
    postalCode: '54689',
    city: 'Steinrausch'
  },
  {
    postalCode: '54689',
    city: 'Übereisenbach'
  },
  {
    postalCode: '55116',
    city: 'Mainz'
  },
  {
    postalCode: '55118',
    city: 'Mainz'
  },
  {
    postalCode: '55120',
    city: 'Mainz'
  },
  {
    postalCode: '55122',
    city: 'Mainz'
  },
  {
    postalCode: '55124',
    city: 'Mainz'
  },
  {
    postalCode: '55126',
    city: 'Mainz'
  },
  {
    postalCode: '55127',
    city: 'Mainz'
  },
  {
    postalCode: '55128',
    city: 'Mainz'
  },
  {
    postalCode: '55129',
    city: 'Mainz'
  },
  {
    postalCode: '55130',
    city: 'Mainz'
  },
  {
    postalCode: '55131',
    city: 'Mainz'
  },
  {
    postalCode: '55218',
    city: 'Ingelheim'
  },
  {
    postalCode: '55232',
    city: 'Alzey'
  },
  {
    postalCode: '55232',
    city: 'Ensheim'
  },
  {
    postalCode: '55234',
    city: 'Albig'
  },
  {
    postalCode: '55234',
    city: 'Bechenheim'
  },
  {
    postalCode: '55234',
    city: 'Bechtolsheim'
  },
  {
    postalCode: '55234',
    city: 'Bermersheim'
  },
  {
    postalCode: '55234',
    city: 'Biebelnheim'
  },
  {
    postalCode: '55234',
    city: 'Dintesheim'
  },
  {
    postalCode: '55234',
    city: 'Eppelsheim'
  },
  {
    postalCode: '55234',
    city: 'Erbes-Büdesheim'
  },
  {
    postalCode: '55234',
    city: 'Esselborn'
  },
  {
    postalCode: '55234',
    city: 'Flomborn'
  },
  {
    postalCode: '55234',
    city: 'Framersheim'
  },
  {
    postalCode: '55234',
    city: 'Freimersheim'
  },
  {
    postalCode: '55234',
    city: 'Gau-Heppenheim'
  },
  {
    postalCode: '55234',
    city: 'Hangen-Weisheim'
  },
  {
    postalCode: '55234',
    city: 'Hochborn'
  },
  {
    postalCode: '55234',
    city: 'Kettenheim'
  },
  {
    postalCode: '55234',
    city: 'Monzernheim'
  },
  {
    postalCode: '55234',
    city: 'Nack'
  },
  {
    postalCode: '55234',
    city: 'Nieder-Wiesen'
  },
  {
    postalCode: '55234',
    city: 'Ober-Flörsheim'
  },
  {
    postalCode: '55234',
    city: 'Offenheim'
  },
  {
    postalCode: '55234',
    city: 'Wahlheim'
  },
  {
    postalCode: '55234',
    city: 'Wendelsheim'
  },
  {
    postalCode: '55237',
    city: 'Bornheim'
  },
  {
    postalCode: '55237',
    city: 'Flonheim'
  },
  {
    postalCode: '55237',
    city: 'Lonsheim'
  },
  {
    postalCode: '55239',
    city: 'Gau-Odernheim'
  },
  {
    postalCode: '55246',
    city: 'Mainz-Kostheim'
  },
  {
    postalCode: '55252',
    city: 'Mainz-Kastel'
  },
  {
    postalCode: '55257',
    city: 'Budenheim'
  },
  {
    postalCode: '55262',
    city: 'Heidesheim'
  },
  {
    postalCode: '55263',
    city: 'Wackernheim'
  },
  {
    postalCode: '55268',
    city: 'Nieder-Olm'
  },
  {
    postalCode: '55270',
    city: 'Bubenheim'
  },
  {
    postalCode: '55270',
    city: 'Engelstadt'
  },
  {
    postalCode: '55270',
    city: 'Essenheim'
  },
  {
    postalCode: '55270',
    city: 'Jugenheim'
  },
  {
    postalCode: '55270',
    city: 'Klein-Winternheim'
  },
  {
    postalCode: '55270',
    city: 'Ober-Olm'
  },
  {
    postalCode: '55270',
    city: 'Schwabenheim'
  },
  {
    postalCode: '55270',
    city: 'Sörgenloch'
  },
  {
    postalCode: '55270',
    city: 'Zornheim'
  },
  {
    postalCode: '55271',
    city: 'Stadecken-Elsheim'
  },
  {
    postalCode: '55276',
    city: 'Dienheim'
  },
  {
    postalCode: '55276',
    city: 'Oppenheim'
  },
  {
    postalCode: '55278',
    city: 'Dalheim'
  },
  {
    postalCode: '55278',
    city: 'Dexheim'
  },
  {
    postalCode: '55278',
    city: 'Dolgesheim'
  },
  {
    postalCode: '55278',
    city: 'Eimsheim'
  },
  {
    postalCode: '55278',
    city: 'Friesenheim'
  },
  {
    postalCode: '55278',
    city: 'Hahnheim'
  },
  {
    postalCode: '55278',
    city: 'Köngernheim'
  },
  {
    postalCode: '55278',
    city: 'Ludwigshöhe'
  },
  {
    postalCode: '55278',
    city: 'Mommenheim'
  },
  {
    postalCode: '55278',
    city: 'Selzen'
  },
  {
    postalCode: '55278',
    city: 'Uelversheim'
  },
  {
    postalCode: '55278',
    city: 'Undenheim'
  },
  {
    postalCode: '55278',
    city: 'Weinolsheim'
  },
  {
    postalCode: '55283',
    city: 'Nierstein'
  },
  {
    postalCode: '55286',
    city: 'Sulzheim'
  },
  {
    postalCode: '55286',
    city: 'Wörrstadt'
  },
  {
    postalCode: '55288',
    city: 'Armsheim'
  },
  {
    postalCode: '55288',
    city: 'Gabsheim'
  },
  {
    postalCode: '55288',
    city: 'Partenheim'
  },
  {
    postalCode: '55288',
    city: 'Schornsheim'
  },
  {
    postalCode: '55288',
    city: 'Spiesheim'
  },
  {
    postalCode: '55288',
    city: 'Udenheim'
  },
  {
    postalCode: '55291',
    city: 'Saulheim'
  },
  {
    postalCode: '55294',
    city: 'Bodenheim'
  },
  {
    postalCode: '55296',
    city: 'Gau-Bischofsheim'
  },
  {
    postalCode: '55296',
    city: 'Harxheim'
  },
  {
    postalCode: '55296',
    city: 'Lörzweiler'
  },
  {
    postalCode: '55299',
    city: 'Nackenheim'
  },
  {
    postalCode: '55411',
    city: 'Bingen'
  },
  {
    postalCode: '55413',
    city: 'Manubach'
  },
  {
    postalCode: '55413',
    city: 'Niederheimbach'
  },
  {
    postalCode: '55413',
    city: 'Oberdiebach'
  },
  {
    postalCode: '55413',
    city: 'Oberheimbach'
  },
  {
    postalCode: '55413',
    city: 'Trechtingshausen'
  },
  {
    postalCode: '55413',
    city: 'Weiler'
  },
  {
    postalCode: '55422',
    city: 'Bacharach'
  },
  {
    postalCode: '55422',
    city: 'Breitscheid'
  },
  {
    postalCode: '55424',
    city: 'Münster-Sarmsheim'
  },
  {
    postalCode: '55425',
    city: 'Waldalgesheim'
  },
  {
    postalCode: '55430',
    city: 'Oberwesel'
  },
  {
    postalCode: '55430',
    city: 'Perscheid'
  },
  {
    postalCode: '55430',
    city: 'Urbar'
  },
  {
    postalCode: '55432',
    city: 'Damscheid'
  },
  {
    postalCode: '55432',
    city: 'Niederburg'
  },
  {
    postalCode: '55435',
    city: 'Gau-Algesheim'
  },
  {
    postalCode: '55437',
    city: 'Appenheim'
  },
  {
    postalCode: '55437',
    city: 'Nieder-Hilbersheim'
  },
  {
    postalCode: '55437',
    city: 'Ober-Hilbersheim'
  },
  {
    postalCode: '55437',
    city: 'Ockenheim'
  },
  {
    postalCode: '55442',
    city: 'Daxweiler'
  },
  {
    postalCode: '55442',
    city: 'Roth'
  },
  {
    postalCode: '55442',
    city: 'Stromberg'
  },
  {
    postalCode: '55442',
    city: 'Warmsroth'
  },
  {
    postalCode: '55444',
    city: 'Dörrebach'
  },
  {
    postalCode: '55444',
    city: 'Eckenroth'
  },
  {
    postalCode: '55444',
    city: 'Schöneberg'
  },
  {
    postalCode: '55444',
    city: 'Schweppenhausen'
  },
  {
    postalCode: '55444',
    city: 'Seibersbach'
  },
  {
    postalCode: '55444',
    city: 'Waldlaubersheim'
  },
  {
    postalCode: '55450',
    city: 'Langenlonsheim'
  },
  {
    postalCode: '55452',
    city: 'Dorsheim'
  },
  {
    postalCode: '55452',
    city: 'Guldental'
  },
  {
    postalCode: '55452',
    city: 'Hergenfeld'
  },
  {
    postalCode: '55452',
    city: 'Laubenheim'
  },
  {
    postalCode: '55452',
    city: 'Rümmelsheim'
  },
  {
    postalCode: '55452',
    city: 'Windesheim'
  },
  {
    postalCode: '55457',
    city: 'Gensingen'
  },
  {
    postalCode: '55457',
    city: 'Horrweiler'
  },
  {
    postalCode: '55459',
    city: 'Aspisheim'
  },
  {
    postalCode: '55459',
    city: 'Grolsheim'
  },
  {
    postalCode: '55469',
    city: 'Altweidelbach'
  },
  {
    postalCode: '55469',
    city: 'Belgweiler'
  },
  {
    postalCode: '55469',
    city: 'Bergenhausen'
  },
  {
    postalCode: '55469',
    city: 'Birkenhof'
  },
  {
    postalCode: '55469',
    city: 'Budenbach'
  },
  {
    postalCode: '55469',
    city: 'Heidehof'
  },
  {
    postalCode: '55469',
    city: 'Holzbach'
  },
  {
    postalCode: '55469',
    city: 'Horn'
  },
  {
    postalCode: '55469',
    city: 'Klosterkumbd'
  },
  {
    postalCode: '55469',
    city: 'Mutterschied'
  },
  {
    postalCode: '55469',
    city: 'Nannhausen'
  },
  {
    postalCode: '55469',
    city: 'Niederkumbd'
  },
  {
    postalCode: '55469',
    city: 'Ohlweiler'
  },
  {
    postalCode: '55469',
    city: 'Oppertshausen'
  },
  {
    postalCode: '55469',
    city: 'Pleizenhausen'
  },
  {
    postalCode: '55469',
    city: 'Rayerschied'
  },
  {
    postalCode: '55469',
    city: 'Riegenroth'
  },
  {
    postalCode: '55469',
    city: 'Schönborn'
  },
  {
    postalCode: '55469',
    city: 'Simmern'
  },
  {
    postalCode: '55471',
    city: 'Biebern'
  },
  {
    postalCode: '55471',
    city: 'Fronhofen'
  },
  {
    postalCode: '55471',
    city: 'Keidelheim'
  },
  {
    postalCode: '55471',
    city: 'Külz'
  },
  {
    postalCode: '55471',
    city: 'Kümbdchen'
  },
  {
    postalCode: '55471',
    city: 'Neuerkirch'
  },
  {
    postalCode: '55471',
    city: 'Ravengiersburg'
  },
  {
    postalCode: '55471',
    city: 'Reich'
  },
  {
    postalCode: '55471',
    city: 'Sargenroth'
  },
  {
    postalCode: '55471',
    city: 'Tiefenbach'
  },
  {
    postalCode: '55471',
    city: 'Wüschheim'
  },
  {
    postalCode: '55481',
    city: 'Dillendorf'
  },
  {
    postalCode: '55481',
    city: 'Hecken'
  },
  {
    postalCode: '55481',
    city: 'Kirchberg'
  },
  {
    postalCode: '55481',
    city: 'Kludenbach'
  },
  {
    postalCode: '55481',
    city: 'Lindenschied'
  },
  {
    postalCode: '55481',
    city: 'Maitzborn'
  },
  {
    postalCode: '55481',
    city: 'Metzenhausen'
  },
  {
    postalCode: '55481',
    city: 'Nieder Kostenz'
  },
  {
    postalCode: '55481',
    city: 'Ober Kostenz'
  },
  {
    postalCode: '55481',
    city: 'Reckershausen'
  },
  {
    postalCode: '55481',
    city: 'Rödern'
  },
  {
    postalCode: '55481',
    city: 'Schwarzen'
  },
  {
    postalCode: '55481',
    city: 'Todenroth'
  },
  {
    postalCode: '55481',
    city: 'Womrath'
  },
  {
    postalCode: '55483',
    city: 'Bärenbach'
  },
  {
    postalCode: '55483',
    city: 'Dickenschied'
  },
  {
    postalCode: '55483',
    city: 'Hahn-Flughafen'
  },
  {
    postalCode: '55483',
    city: 'Heinzenbach'
  },
  {
    postalCode: '55483',
    city: 'Hirschfeld'
  },
  {
    postalCode: '55483',
    city: 'Horbruch'
  },
  {
    postalCode: '55483',
    city: 'Kappel'
  },
  {
    postalCode: '55483',
    city: 'Krummenau'
  },
  {
    postalCode: '55483',
    city: 'Lautzenhausen'
  },
  {
    postalCode: '55483',
    city: 'Schlierschied'
  },
  {
    postalCode: '55483',
    city: 'Unzenberg'
  },
  {
    postalCode: '55487',
    city: 'Dill'
  },
  {
    postalCode: '55487',
    city: 'Laufersweiler'
  },
  {
    postalCode: '55487',
    city: 'Niedersohren'
  },
  {
    postalCode: '55487',
    city: 'Sohren'
  },
  {
    postalCode: '55487',
    city: 'Sohrschied'
  },
  {
    postalCode: '55490',
    city: 'Gehlweiler'
  },
  {
    postalCode: '55490',
    city: 'Gemünden'
  },
  {
    postalCode: '55490',
    city: 'Henau'
  },
  {
    postalCode: '55490',
    city: 'Mengerschied'
  },
  {
    postalCode: '55490',
    city: 'Rohrbach'
  },
  {
    postalCode: '55490',
    city: 'Wildburg'
  },
  {
    postalCode: '55490',
    city: 'Woppenroth'
  },
  {
    postalCode: '55491',
    city: 'Büchenbeuren'
  },
  {
    postalCode: '55491',
    city: 'Niederweiler'
  },
  {
    postalCode: '55491',
    city: 'Wahlenau'
  },
  {
    postalCode: '55494',
    city: 'Benzweiler'
  },
  {
    postalCode: '55494',
    city: 'Dichtelbach'
  },
  {
    postalCode: '55494',
    city: 'Erbach'
  },
  {
    postalCode: '55494',
    city: 'Liebshausen'
  },
  {
    postalCode: '55494',
    city: 'Mörschbach'
  },
  {
    postalCode: '55494',
    city: 'Rheinböllen'
  },
  {
    postalCode: '55494',
    city: 'Wahlbach'
  },
  {
    postalCode: '55496',
    city: 'Argenthal'
  },
  {
    postalCode: '55497',
    city: 'Ellern'
  },
  {
    postalCode: '55497',
    city: 'Schnorbach'
  },
  {
    postalCode: '55499',
    city: 'Riesweiler'
  },
  {
    postalCode: '55543',
    city: 'Bad Kreuznach'
  },
  {
    postalCode: '55543',
    city: 'Rosenhof'
  },
  {
    postalCode: '55545',
    city: 'Bad Kreuznach'
  },
  {
    postalCode: '55546',
    city: 'Biebelsheim'
  },
  {
    postalCode: '55546',
    city: 'Frei-Laubersheim'
  },
  {
    postalCode: '55546',
    city: 'Fürfeld'
  },
  {
    postalCode: '55546',
    city: 'Hackenheim'
  },
  {
    postalCode: '55546',
    city: 'Neu-Bamberg'
  },
  {
    postalCode: '55546',
    city: 'Pfaffen-Schwabenheim'
  },
  {
    postalCode: '55546',
    city: 'Tiefenthal'
  },
  {
    postalCode: '55546',
    city: 'Volxheim'
  },
  {
    postalCode: '55559',
    city: 'Bretzenheim'
  },
  {
    postalCode: '55566',
    city: 'Bad Sobernheim'
  },
  {
    postalCode: '55566',
    city: 'Daubach'
  },
  {
    postalCode: '55566',
    city: 'Ippenschied'
  },
  {
    postalCode: '55566',
    city: 'Kirschroth'
  },
  {
    postalCode: '55566',
    city: 'Meddersheim'
  },
  {
    postalCode: '55566',
    city: 'Rehbach'
  },
  {
    postalCode: '55568',
    city: 'Abtweiler'
  },
  {
    postalCode: '55568',
    city: 'Lauschied'
  },
  {
    postalCode: '55568',
    city: 'Staudernheim'
  },
  {
    postalCode: '55569',
    city: 'Auen'
  },
  {
    postalCode: '55569',
    city: 'Langenthal'
  },
  {
    postalCode: '55569',
    city: 'Monzingen'
  },
  {
    postalCode: '55569',
    city: 'Nußbaum'
  },
  {
    postalCode: '55571',
    city: 'Odernheim'
  },
  {
    postalCode: '55576',
    city: 'Badenheim'
  },
  {
    postalCode: '55576',
    city: 'Pleitersheim'
  },
  {
    postalCode: '55576',
    city: 'Sprendlingen'
  },
  {
    postalCode: '55576',
    city: 'Welgesheim'
  },
  {
    postalCode: '55576',
    city: 'Zotzenheim'
  },
  {
    postalCode: '55578',
    city: 'Gau-Weinheim'
  },
  {
    postalCode: '55578',
    city: 'St Johann'
  },
  {
    postalCode: '55578',
    city: 'Vendersheim'
  },
  {
    postalCode: '55578',
    city: 'Wallertheim'
  },
  {
    postalCode: '55578',
    city: 'Wolfsheim'
  },
  {
    postalCode: '55583',
    city: 'Bad Münster-Ebernburg'
  },
  {
    postalCode: '55585',
    city: 'Altenbamberg'
  },
  {
    postalCode: '55585',
    city: 'Duchroth'
  },
  {
    postalCode: '55585',
    city: 'Hochstätten'
  },
  {
    postalCode: '55585',
    city: 'Niederhausen'
  },
  {
    postalCode: '55585',
    city: 'Norheim'
  },
  {
    postalCode: '55585',
    city: 'Oberhausen'
  },
  {
    postalCode: '55590',
    city: 'Meisenheim'
  },
  {
    postalCode: '55592',
    city: 'Breitenheim'
  },
  {
    postalCode: '55592',
    city: 'Desloch'
  },
  {
    postalCode: '55592',
    city: 'Jeckenbach'
  },
  {
    postalCode: '55592',
    city: 'Raumbach'
  },
  {
    postalCode: '55592',
    city: 'Rehborn'
  },
  {
    postalCode: '55593',
    city: 'Rüdesheim'
  },
  {
    postalCode: '55595',
    city: 'Allenfeld'
  },
  {
    postalCode: '55595',
    city: 'Argenschwang'
  },
  {
    postalCode: '55595',
    city: 'Bockenau'
  },
  {
    postalCode: '55595',
    city: 'Boos'
  },
  {
    postalCode: '55595',
    city: 'Braunweiler'
  },
  {
    postalCode: '55595',
    city: 'Burgsponheim'
  },
  {
    postalCode: '55595',
    city: 'Dalberg'
  },
  {
    postalCode: '55595',
    city: 'Gebroth'
  },
  {
    postalCode: '55595',
    city: 'Gutenberg'
  },
  {
    postalCode: '55595',
    city: 'Hargesheim'
  },
  {
    postalCode: '55595',
    city: 'Hüffelsheim'
  },
  {
    postalCode: '55595',
    city: 'Mandel'
  },
  {
    postalCode: '55595',
    city: 'Münchwald'
  },
  {
    postalCode: '55595',
    city: 'Roxheim'
  },
  {
    postalCode: '55595',
    city: 'Sankt Katharinen'
  },
  {
    postalCode: '55595',
    city: 'Sommerloch'
  },
  {
    postalCode: '55595',
    city: 'Spabrücken'
  },
  {
    postalCode: '55595',
    city: 'Spall'
  },
  {
    postalCode: '55595',
    city: 'Sponheim'
  },
  {
    postalCode: '55595',
    city: 'Traisen'
  },
  {
    postalCode: '55595',
    city: 'Wallhausen'
  },
  {
    postalCode: '55595',
    city: 'Weinsheim'
  },
  {
    postalCode: '55595',
    city: 'Winterbach'
  },
  {
    postalCode: '55595',
    city: 'Winterburg'
  },
  {
    postalCode: '55596',
    city: 'Oberstreit'
  },
  {
    postalCode: '55596',
    city: 'Schloßböckelheim'
  },
  {
    postalCode: '55596',
    city: 'Waldböckelheim'
  },
  {
    postalCode: '55597',
    city: 'Gumbsheim'
  },
  {
    postalCode: '55597',
    city: 'Wöllstein'
  },
  {
    postalCode: '55599',
    city: 'Eckelsheim'
  },
  {
    postalCode: '55599',
    city: 'Gau-Bickelheim'
  },
  {
    postalCode: '55599',
    city: 'Siefersheim'
  },
  {
    postalCode: '55599',
    city: 'Stein-Bockenheim'
  },
  {
    postalCode: '55599',
    city: 'Wonsheim'
  },
  {
    postalCode: '55606',
    city: 'Bärweiler'
  },
  {
    postalCode: '55606',
    city: 'Brauweiler'
  },
  {
    postalCode: '55606',
    city: 'Bruschied'
  },
  {
    postalCode: '55606',
    city: 'Hahnenbach'
  },
  {
    postalCode: '55606',
    city: 'Heimweiler'
  },
  {
    postalCode: '55606',
    city: 'Heinzenberg'
  },
  {
    postalCode: '55606',
    city: 'Heinzenberger-Gesellschaftsmühle'
  },
  {
    postalCode: '55606',
    city: 'Hochstetten-Dhaun'
  },
  {
    postalCode: '55606',
    city: 'Horbach'
  },
  {
    postalCode: '55606',
    city: 'Hottenmühle'
  },
  {
    postalCode: '55606',
    city: 'Kellenbach'
  },
  {
    postalCode: '55606',
    city: 'Kirn'
  },
  {
    postalCode: '55606',
    city: 'Königsau'
  },
  {
    postalCode: '55606',
    city: 'Limbach'
  },
  {
    postalCode: '55606',
    city: 'Lochmühle'
  },
  {
    postalCode: '55606',
    city: 'Lohmühle'
  },
  {
    postalCode: '55606',
    city: 'Lützelsoon'
  },
  {
    postalCode: '55606',
    city: 'Meckenbach'
  },
  {
    postalCode: '55606',
    city: 'Oberhausen'
  },
  {
    postalCode: '55606',
    city: 'Otzweiler'
  },
  {
    postalCode: '55606',
    city: 'Untere Horbachsmühle'
  },
  {
    postalCode: '55606',
    city: 'Wartenstein'
  },
  {
    postalCode: '55606',
    city: 'Welschrötherhof'
  },
  {
    postalCode: '55608',
    city: 'Becherbach'
  },
  {
    postalCode: '55608',
    city: 'Bergen'
  },
  {
    postalCode: '55608',
    city: 'Berschweiler'
  },
  {
    postalCode: '55608',
    city: 'Griebelschied'
  },
  {
    postalCode: '55608',
    city: 'Hausen'
  },
  {
    postalCode: '55608',
    city: 'Schneppenbach'
  },
  {
    postalCode: '55618',
    city: 'Simmertal'
  },
  {
    postalCode: '55619',
    city: 'Hennweiler'
  },
  {
    postalCode: '55621',
    city: 'Hundsbach'
  },
  {
    postalCode: '55624',
    city: 'Bollenbach'
  },
  {
    postalCode: '55624',
    city: 'Gösenroth'
  },
  {
    postalCode: '55624',
    city: 'Oberkirn'
  },
  {
    postalCode: '55624',
    city: 'Rhaunen'
  },
  {
    postalCode: '55624',
    city: 'Schwerbach'
  },
  {
    postalCode: '55624',
    city: 'Weitersbach'
  },
  {
    postalCode: '55626',
    city: 'Bundenbach'
  },
  {
    postalCode: '55627',
    city: 'Martinstein'
  },
  {
    postalCode: '55627',
    city: 'Merxheim'
  },
  {
    postalCode: '55627',
    city: 'Weiler'
  },
  {
    postalCode: '55629',
    city: 'Schwarzerden'
  },
  {
    postalCode: '55629',
    city: 'Seesbach'
  },
  {
    postalCode: '55629',
    city: 'Weitersborn'
  },
  {
    postalCode: '55743',
    city: 'Fischbach'
  },
  {
    postalCode: '55743',
    city: 'Gerach'
  },
  {
    postalCode: '55743',
    city: 'Gerichtsmannsmühle'
  },
  {
    postalCode: '55743',
    city: 'Heidehof'
  },
  {
    postalCode: '55743',
    city: 'Hintertiefenbach'
  },
  {
    postalCode: '55743',
    city: 'Hüttgeswasen'
  },
  {
    postalCode: '55743',
    city: 'Idar-Oberstein'
  },
  {
    postalCode: '55743',
    city: 'Kirschweiler'
  },
  {
    postalCode: '55756',
    city: 'Herrstein'
  },
  {
    postalCode: '55758',
    city: 'Allenbach'
  },
  {
    postalCode: '55758',
    city: 'Asbach'
  },
  {
    postalCode: '55758',
    city: 'Asbacherhütte'
  },
  {
    postalCode: '55758',
    city: 'Bärenbach'
  },
  {
    postalCode: '55758',
    city: 'Breitenthal'
  },
  {
    postalCode: '55758',
    city: 'Bruchweiler'
  },
  {
    postalCode: '55758',
    city: 'Dickesbach'
  },
  {
    postalCode: '55758',
    city: 'Hammerbirkenfeld'
  },
  {
    postalCode: '55758',
    city: 'Harfenmühle'
  },
  {
    postalCode: '55758',
    city: 'Hellertshausen'
  },
  {
    postalCode: '55758',
    city: 'Herborn'
  },
  {
    postalCode: '55758',
    city: 'Hettenrodt'
  },
  {
    postalCode: '55758',
    city: 'Hottenbach'
  },
  {
    postalCode: '55758',
    city: 'Kempfeld'
  },
  {
    postalCode: '55758',
    city: 'Langweiler'
  },
  {
    postalCode: '55758',
    city: 'Mackenrodt'
  },
  {
    postalCode: '55758',
    city: 'Mittelreidenbach'
  },
  {
    postalCode: '55758',
    city: 'Mörschied'
  },
  {
    postalCode: '55758',
    city: 'Niederhosenbach'
  },
  {
    postalCode: '55758',
    city: 'Niederwörresbach'
  },
  {
    postalCode: '55758',
    city: 'Oberhosenbach'
  },
  {
    postalCode: '55758',
    city: 'Oberreidenbach'
  },
  {
    postalCode: '55758',
    city: 'Oberwörresbach'
  },
  {
    postalCode: '55758',
    city: 'Schauren'
  },
  {
    postalCode: '55758',
    city: 'Schmidthachenbach'
  },
  {
    postalCode: '55758',
    city: 'Sensweiler'
  },
  {
    postalCode: '55758',
    city: 'Sien'
  },
  {
    postalCode: '55758',
    city: 'Sienhachenbach'
  },
  {
    postalCode: '55758',
    city: 'Sonnschied'
  },
  {
    postalCode: '55758',
    city: 'Stipshausen'
  },
  {
    postalCode: '55758',
    city: 'Sulzbach'
  },
  {
    postalCode: '55758',
    city: 'Veitsrodt'
  },
  {
    postalCode: '55758',
    city: 'Vollmersbach'
  },
  {
    postalCode: '55758',
    city: 'Weiden'
  },
  {
    postalCode: '55758',
    city: 'Wickenrodt'
  },
  {
    postalCode: '55758',
    city: 'Wirschweiler'
  },
  {
    postalCode: '55765',
    city: 'Birkenfeld'
  },
  {
    postalCode: '55765',
    city: 'Dambach'
  },
  {
    postalCode: '55765',
    city: 'Dienstweiler'
  },
  {
    postalCode: '55765',
    city: 'Eborn'
  },
  {
    postalCode: '55765',
    city: 'Elchweiler'
  },
  {
    postalCode: '55765',
    city: 'Ellenberg'
  },
  {
    postalCode: '55765',
    city: 'Ellweiler'
  },
  {
    postalCode: '55765',
    city: 'Oberhambach'
  },
  {
    postalCode: '55765',
    city: 'Rimsberg'
  },
  {
    postalCode: '55765',
    city: 'Sauerbrunnen'
  },
  {
    postalCode: '55765',
    city: 'Schmißberg'
  },
  {
    postalCode: '55765',
    city: 'Waldfriede'
  },
  {
    postalCode: '55767',
    city: 'Abentheuer'
  },
  {
    postalCode: '55767',
    city: 'Achtelsbach'
  },
  {
    postalCode: '55767',
    city: 'Brücken'
  },
  {
    postalCode: '55767',
    city: 'Buhlenberg'
  },
  {
    postalCode: '55767',
    city: 'Gimbweiler'
  },
  {
    postalCode: '55767',
    city: 'Gollenberg'
  },
  {
    postalCode: '55767',
    city: 'Hattgenstein'
  },
  {
    postalCode: '55767',
    city: 'Kronweiler'
  },
  {
    postalCode: '55767',
    city: 'Leisel'
  },
  {
    postalCode: '55767',
    city: 'Meckenbach'
  },
  {
    postalCode: '55767',
    city: 'Niederbrombach'
  },
  {
    postalCode: '55767',
    city: 'Niederhambach'
  },
  {
    postalCode: '55767',
    city: 'Nohen'
  },
  {
    postalCode: '55767',
    city: 'Oberbrombach'
  },
  {
    postalCode: '55767',
    city: 'Rinzenberg'
  },
  {
    postalCode: '55767',
    city: 'Rötsweiler-Nockenthal'
  },
  {
    postalCode: '55767',
    city: 'Schwollen'
  },
  {
    postalCode: '55767',
    city: 'Siesbach'
  },
  {
    postalCode: '55767',
    city: 'Sonnenberg-Winnenberg'
  },
  {
    postalCode: '55767',
    city: 'Wilzenberg-Hußweiler'
  },
  {
    postalCode: '55768',
    city: 'Hoppstädten-Weiersbach'
  },
  {
    postalCode: '55774',
    city: 'Baumholder'
  },
  {
    postalCode: '55776',
    city: 'Berglangenbach'
  },
  {
    postalCode: '55776',
    city: 'Frauenberg'
  },
  {
    postalCode: '55776',
    city: 'Hahnweiler'
  },
  {
    postalCode: '55776',
    city: 'Reichenbach'
  },
  {
    postalCode: '55776',
    city: 'Rohrbach'
  },
  {
    postalCode: '55776',
    city: 'Rückweiler'
  },
  {
    postalCode: '55776',
    city: 'Ruschberg'
  },
  {
    postalCode: '55776',
    city: 'Zinkweilerhof'
  },
  {
    postalCode: '55777',
    city: 'Berschweiler'
  },
  {
    postalCode: '55777',
    city: 'Eckersweiler'
  },
  {
    postalCode: '55777',
    city: 'Fohren-Linden'
  },
  {
    postalCode: '55777',
    city: 'Mettweiler'
  },
  {
    postalCode: '55779',
    city: 'Heimbach'
  },
  {
    postalCode: '55779',
    city: 'Leitzweiler'
  },
  {
    postalCode: '56068',
    city: 'Koblenz'
  },
  {
    postalCode: '56070',
    city: 'Koblenz'
  },
  {
    postalCode: '56072',
    city: 'Koblenz'
  },
  {
    postalCode: '56073',
    city: 'Koblenz'
  },
  {
    postalCode: '56075',
    city: 'Koblenz'
  },
  {
    postalCode: '56076',
    city: 'Koblenz'
  },
  {
    postalCode: '56077',
    city: 'Koblenz'
  },
  {
    postalCode: '56112',
    city: 'Lahnstein'
  },
  {
    postalCode: '56130',
    city: 'Altes Forsthaus'
  },
  {
    postalCode: '56130',
    city: 'Bad Ems'
  },
  {
    postalCode: '56130',
    city: 'Buchenberg'
  },
  {
    postalCode: '56130',
    city: 'Grenzloch'
  },
  {
    postalCode: '56130',
    city: 'Heinrichshof'
  },
  {
    postalCode: '56130',
    city: 'Lindenbach'
  },
  {
    postalCode: '56130',
    city: 'Schleuse Dausenau'
  },
  {
    postalCode: '56130',
    city: 'Schleuse Ems'
  },
  {
    postalCode: '56132',
    city: 'Becheln'
  },
  {
    postalCode: '56132',
    city: 'Dausenau'
  },
  {
    postalCode: '56132',
    city: 'Frücht'
  },
  {
    postalCode: '56132',
    city: 'Kemmenau'
  },
  {
    postalCode: '56132',
    city: 'Miellen'
  },
  {
    postalCode: '56132',
    city: 'Nievern'
  },
  {
    postalCode: '56132',
    city: 'Schöne Aussicht'
  },
  {
    postalCode: '56132',
    city: 'Waldfrieden-Schweizertal'
  },
  {
    postalCode: '56133',
    city: 'Fachbach'
  },
  {
    postalCode: '56154',
    city: 'Boppard'
  },
  {
    postalCode: '56170',
    city: 'Bembsmühle'
  },
  {
    postalCode: '56170',
    city: 'Bendorf'
  },
  {
    postalCode: '56170',
    city: 'Lachnitzmühle'
  },
  {
    postalCode: '56179',
    city: 'Niederwerth'
  },
  {
    postalCode: '56179',
    city: 'Vallendar'
  },
  {
    postalCode: '56182',
    city: 'Urbar'
  },
  {
    postalCode: '56191',
    city: 'Weitersburg'
  },
  {
    postalCode: '56203',
    city: 'Bembermühle'
  },
  {
    postalCode: '56203',
    city: 'Höhr-Grenzhausen'
  },
  {
    postalCode: '56203',
    city: 'Waldfriede'
  },
  {
    postalCode: '56204',
    city: 'Hillscheid'
  },
  {
    postalCode: '56206',
    city: 'Hilgert'
  },
  {
    postalCode: '56206',
    city: 'Kammerforst'
  },
  {
    postalCode: '56218',
    city: 'Mülheim-Kärlich'
  },
  {
    postalCode: '56220',
    city: 'Bassenheim'
  },
  {
    postalCode: '56220',
    city: 'Kaltenengers'
  },
  {
    postalCode: '56220',
    city: 'Kettig'
  },
  {
    postalCode: '56220',
    city: 'Sankt Sebastian'
  },
  {
    postalCode: '56220',
    city: 'Urmitz'
  },
  {
    postalCode: '56235',
    city: 'Faulbach'
  },
  {
    postalCode: '56235',
    city: 'Hundsdorf'
  },
  {
    postalCode: '56235',
    city: 'Ransbach-Baumbach'
  },
  {
    postalCode: '56237',
    city: 'Alsbach'
  },
  {
    postalCode: '56237',
    city: 'Breitenau'
  },
  {
    postalCode: '56237',
    city: 'Caan'
  },
  {
    postalCode: '56237',
    city: 'Deesen'
  },
  {
    postalCode: '56237',
    city: 'Nauort'
  },
  {
    postalCode: '56237',
    city: 'Oberhaid'
  },
  {
    postalCode: '56237',
    city: 'Sessenbach'
  },
  {
    postalCode: '56237',
    city: 'Wirscheid'
  },
  {
    postalCode: '56237',
    city: 'Wittgert'
  },
  {
    postalCode: '56242',
    city: 'Ellenhausen'
  },
  {
    postalCode: '56242',
    city: 'Hammermühle'
  },
  {
    postalCode: '56242',
    city: 'Marienrachdorf'
  },
  {
    postalCode: '56242',
    city: 'Nordhofen'
  },
  {
    postalCode: '56242',
    city: 'Quirnbach'
  },
  {
    postalCode: '56242',
    city: 'Selters'
  },
  {
    postalCode: '56244',
    city: 'Arnshöfen'
  },
  {
    postalCode: '56244',
    city: 'Ettinghausen'
  },
  {
    postalCode: '56244',
    city: 'Ewighausen'
  },
  {
    postalCode: '56244',
    city: 'Freilingen'
  },
  {
    postalCode: '56244',
    city: 'Freirachdorf'
  },
  {
    postalCode: '56244',
    city: 'Goddert'
  },
  {
    postalCode: '56244',
    city: 'Hahn'
  },
  {
    postalCode: '56244',
    city: 'Hartenfels'
  },
  {
    postalCode: '56244',
    city: 'Helferskirchen'
  },
  {
    postalCode: '56244',
    city: 'Krümmel'
  },
  {
    postalCode: '56244',
    city: 'Kuhnhöfen'
  },
  {
    postalCode: '56244',
    city: 'Leuterod'
  },
  {
    postalCode: '56244',
    city: 'Maxsain'
  },
  {
    postalCode: '56244',
    city: 'Niedersayn'
  },
  {
    postalCode: '56244',
    city: 'Ötzingen'
  },
  {
    postalCode: '56244',
    city: 'Rückeroth'
  },
  {
    postalCode: '56244',
    city: 'Schenkelberg'
  },
  {
    postalCode: '56244',
    city: 'Sessenhausen'
  },
  {
    postalCode: '56244',
    city: 'Steinen'
  },
  {
    postalCode: '56244',
    city: 'Vielbach'
  },
  {
    postalCode: '56244',
    city: 'Weidenhahn'
  },
  {
    postalCode: '56244',
    city: 'Wölferlingen'
  },
  {
    postalCode: '56249',
    city: 'Herschbach'
  },
  {
    postalCode: '56253',
    city: 'Treis-Karden'
  },
  {
    postalCode: '56254',
    city: 'Moselkern'
  },
  {
    postalCode: '56254',
    city: 'Müden'
  },
  {
    postalCode: '56269',
    city: 'Dierdorf'
  },
  {
    postalCode: '56269',
    city: 'Marienhausen'
  },
  {
    postalCode: '56271',
    city: 'Isenburg'
  },
  {
    postalCode: '56271',
    city: 'Kettemers Mühle'
  },
  {
    postalCode: '56271',
    city: 'Kleinmaischeid'
  },
  {
    postalCode: '56271',
    city: 'Maroth'
  },
  {
    postalCode: '56271',
    city: 'Mündersbach'
  },
  {
    postalCode: '56271',
    city: 'Roßbach'
  },
  {
    postalCode: '56271',
    city: 'Wiedischhausen'
  },
  {
    postalCode: '56276',
    city: 'Großmaischeid'
  },
  {
    postalCode: '56276',
    city: 'Stebach'
  },
  {
    postalCode: '56281',
    city: 'Dörth'
  },
  {
    postalCode: '56281',
    city: 'Emmelshausen'
  },
  {
    postalCode: '56281',
    city: 'Hungenroth'
  },
  {
    postalCode: '56281',
    city: 'Karbach'
  },
  {
    postalCode: '56281',
    city: 'Schwall'
  },
  {
    postalCode: '56283',
    city: 'Beulich'
  },
  {
    postalCode: '56283',
    city: 'Dieler'
  },
  {
    postalCode: '56283',
    city: 'Gondershausen'
  },
  {
    postalCode: '56283',
    city: 'Halsenbach'
  },
  {
    postalCode: '56283',
    city: 'Kratzenburg'
  },
  {
    postalCode: '56283',
    city: 'Mermuth'
  },
  {
    postalCode: '56283',
    city: 'Morshausen'
  },
  {
    postalCode: '56283',
    city: 'Ney'
  },
  {
    postalCode: '56283',
    city: 'Nörtershausen'
  },
  {
    postalCode: '56283',
    city: 'Wildenbungert'
  },
  {
    postalCode: '56288',
    city: 'Alterkülz'
  },
  {
    postalCode: '56288',
    city: 'Bell'
  },
  {
    postalCode: '56288',
    city: 'Braunshorn'
  },
  {
    postalCode: '56288',
    city: 'Bubach'
  },
  {
    postalCode: '56288',
    city: 'Dudenroth'
  },
  {
    postalCode: '56288',
    city: 'Hasselbach'
  },
  {
    postalCode: '56288',
    city: 'Hollnich'
  },
  {
    postalCode: '56288',
    city: 'Hundheim'
  },
  {
    postalCode: '56288',
    city: 'Kastellaun'
  },
  {
    postalCode: '56288',
    city: 'Korweiler'
  },
  {
    postalCode: '56288',
    city: 'Krastel'
  },
  {
    postalCode: '56288',
    city: 'Lahr'
  },
  {
    postalCode: '56288',
    city: 'Laubach'
  },
  {
    postalCode: '56288',
    city: 'Michelbach'
  },
  {
    postalCode: '56288',
    city: 'Roth'
  },
  {
    postalCode: '56288',
    city: 'Rothenbergerhof'
  },
  {
    postalCode: '56288',
    city: 'Spesenroth'
  },
  {
    postalCode: '56288',
    city: 'Steffenshof'
  },
  {
    postalCode: '56288',
    city: 'Völkenroth'
  },
  {
    postalCode: '56288',
    city: 'Wohnroth'
  },
  {
    postalCode: '56288',
    city: 'Zilshausen'
  },
  {
    postalCode: '56290',
    city: 'Beltheim'
  },
  {
    postalCode: '56290',
    city: 'Buch'
  },
  {
    postalCode: '56290',
    city: 'Dommershausen'
  },
  {
    postalCode: '56290',
    city: 'Gödenroth'
  },
  {
    postalCode: '56290',
    city: 'Lieg'
  },
  {
    postalCode: '56290',
    city: 'Lütz'
  },
  {
    postalCode: '56290',
    city: 'Macken'
  },
  {
    postalCode: '56290',
    city: 'Mörsdorf'
  },
  {
    postalCode: '56290',
    city: 'Uhler'
  },
  {
    postalCode: '56291',
    city: 'Badenhard'
  },
  {
    postalCode: '56291',
    city: 'Bickenbach'
  },
  {
    postalCode: '56291',
    city: 'Birkheim'
  },
  {
    postalCode: '56291',
    city: 'Hausbay'
  },
  {
    postalCode: '56291',
    city: 'Kehlenmühle'
  },
  {
    postalCode: '56291',
    city: 'Kisselbach'
  },
  {
    postalCode: '56291',
    city: 'Laudert'
  },
  {
    postalCode: '56291',
    city: 'Leiningen'
  },
  {
    postalCode: '56291',
    city: 'Lingerhahn'
  },
  {
    postalCode: '56291',
    city: 'Maisborn'
  },
  {
    postalCode: '56291',
    city: 'Mühlpfad'
  },
  {
    postalCode: '56291',
    city: 'Niedert'
  },
  {
    postalCode: '56291',
    city: 'Norath'
  },
  {
    postalCode: '56291',
    city: 'Ostersmühle'
  },
  {
    postalCode: '56291',
    city: 'Pfalzfeld'
  },
  {
    postalCode: '56291',
    city: 'Steinbach'
  },
  {
    postalCode: '56291',
    city: 'Thörlingen'
  },
  {
    postalCode: '56291',
    city: 'Utzenhain'
  },
  {
    postalCode: '56291',
    city: 'Wiebelsheim'
  },
  {
    postalCode: '56294',
    city: 'Gappenach'
  },
  {
    postalCode: '56294',
    city: 'Gierschnach'
  },
  {
    postalCode: '56294',
    city: 'Kalt'
  },
  {
    postalCode: '56294',
    city: 'Münstermaifeld'
  },
  {
    postalCode: '56294',
    city: 'Wierschem'
  },
  {
    postalCode: '56295',
    city: 'Kerben'
  },
  {
    postalCode: '56295',
    city: 'Lonnig'
  },
  {
    postalCode: '56295',
    city: 'Rüber'
  },
  {
    postalCode: '56299',
    city: 'Achterspannerhof'
  },
  {
    postalCode: '56299',
    city: 'Ochtendung'
  },
  {
    postalCode: '56299',
    city: 'Sackenheimerhof'
  },
  {
    postalCode: '56299',
    city: 'Sürzerhof'
  },
  {
    postalCode: '56305',
    city: 'Döttesfeld'
  },
  {
    postalCode: '56305',
    city: 'Niederähren'
  },
  {
    postalCode: '56305',
    city: 'Puderbach'
  },
  {
    postalCode: '56307',
    city: 'Dernbach'
  },
  {
    postalCode: '56307',
    city: 'Dürrholz'
  },
  {
    postalCode: '56307',
    city: 'Harschbach'
  },
  {
    postalCode: '56316',
    city: 'Hanroth'
  },
  {
    postalCode: '56316',
    city: 'Niederhofen'
  },
  {
    postalCode: '56316',
    city: 'Raubach'
  },
  {
    postalCode: '56317',
    city: 'Linkenbach'
  },
  {
    postalCode: '56317',
    city: 'Urbach'
  },
  {
    postalCode: '56321',
    city: 'Brey'
  },
  {
    postalCode: '56321',
    city: 'Rhens'
  },
  {
    postalCode: '56322',
    city: 'Spay'
  },
  {
    postalCode: '56323',
    city: 'Hünenfeld'
  },
  {
    postalCode: '56323',
    city: 'Waldesch'
  },
  {
    postalCode: '56329',
    city: 'St. Goar'
  },
  {
    postalCode: '56330',
    city: 'Fißmühle'
  },
  {
    postalCode: '56330',
    city: 'Kobern-Gondorf'
  },
  {
    postalCode: '56332',
    city: 'Alken'
  },
  {
    postalCode: '56332',
    city: 'Brodenbach'
  },
  {
    postalCode: '56332',
    city: 'Burgen'
  },
  {
    postalCode: '56332',
    city: 'Dieblich'
  },
  {
    postalCode: '56332',
    city: 'Franzenmühle'
  },
  {
    postalCode: '56332',
    city: 'Gastemühle'
  },
  {
    postalCode: '56332',
    city: 'Grüne Mühle'
  },
  {
    postalCode: '56332',
    city: 'Hatzenport'
  },
  {
    postalCode: '56332',
    city: 'Lehmen'
  },
  {
    postalCode: '56332',
    city: 'Löf'
  },
  {
    postalCode: '56332',
    city: 'Mohrenmühle'
  },
  {
    postalCode: '56332',
    city: 'Niederfell'
  },
  {
    postalCode: '56332',
    city: 'Oberfell'
  },
  {
    postalCode: '56332',
    city: 'Rosenhof'
  },
  {
    postalCode: '56332',
    city: 'Schwalberhof'
  },
  {
    postalCode: '56332',
    city: 'Wolken'
  },
  {
    postalCode: '56333',
    city: 'Winningen'
  },
  {
    postalCode: '56335',
    city: 'Neuhäusel'
  },
  {
    postalCode: '56337',
    city: 'Arzbach'
  },
  {
    postalCode: '56337',
    city: 'Eitelborn'
  },
  {
    postalCode: '56337',
    city: 'Kadenbach'
  },
  {
    postalCode: '56337',
    city: 'Simmern'
  },
  {
    postalCode: '56338',
    city: 'Braubach'
  },
  {
    postalCode: '56340',
    city: 'Büchelborn'
  },
  {
    postalCode: '56340',
    city: 'Dachsborn'
  },
  {
    postalCode: '56340',
    city: 'Dachsenhausen'
  },
  {
    postalCode: '56340',
    city: 'Erlenborn'
  },
  {
    postalCode: '56340',
    city: 'Osterspai'
  },
  {
    postalCode: '56341',
    city: 'Filsen'
  },
  {
    postalCode: '56341',
    city: 'Kamp-Bornhofen'
  },
  {
    postalCode: '56346',
    city: 'Bornsmühle'
  },
  {
    postalCode: '56346',
    city: 'Gute Mühle'
  },
  {
    postalCode: '56346',
    city: 'Immenhof'
  },
  {
    postalCode: '56346',
    city: 'Loreley'
  },
  {
    postalCode: '56346',
    city: 'Lykershausen'
  },
  {
    postalCode: '56346',
    city: 'Prath'
  },
  {
    postalCode: '56346',
    city: 'Rödelbach'
  },
  {
    postalCode: '56346',
    city: 'Saueressigs Mühle'
  },
  {
    postalCode: '56346',
    city: 'St. Goarshausen'
  },
  {
    postalCode: '56346',
    city: 'Ströbels Mühle'
  },
  {
    postalCode: '56346',
    city: 'Theismühlen'
  },
  {
    postalCode: '56348',
    city: 'Bornich'
  },
  {
    postalCode: '56348',
    city: 'Dahlheim'
  },
  {
    postalCode: '56348',
    city: 'Dörscheid'
  },
  {
    postalCode: '56348',
    city: 'Kestert'
  },
  {
    postalCode: '56348',
    city: 'Patersberg'
  },
  {
    postalCode: '56348',
    city: 'Weisel'
  },
  {
    postalCode: '56349',
    city: 'Kaub'
  },
  {
    postalCode: '56349',
    city: 'Viktoriastollen'
  },
  {
    postalCode: '56355',
    city: 'Aftholderbach'
  },
  {
    postalCode: '56355',
    city: 'Bettendorf'
  },
  {
    postalCode: '56355',
    city: 'Diethardt'
  },
  {
    postalCode: '56355',
    city: 'Endlichhofen'
  },
  {
    postalCode: '56355',
    city: 'Hunzel'
  },
  {
    postalCode: '56355',
    city: 'Kehlbach'
  },
  {
    postalCode: '56355',
    city: 'Lautert'
  },
  {
    postalCode: '56355',
    city: 'Nastätten'
  },
  {
    postalCode: '56355',
    city: 'Oberbachheim'
  },
  {
    postalCode: '56355',
    city: 'Schneidmühle'
  },
  {
    postalCode: '56355',
    city: 'Weidenbach'
  },
  {
    postalCode: '56355',
    city: 'Winterwerb'
  },
  {
    postalCode: '56357',
    city: 'Auel'
  },
  {
    postalCode: '56357',
    city: 'Berg'
  },
  {
    postalCode: '56357',
    city: 'Bogel'
  },
  {
    postalCode: '56357',
    city: 'Buch'
  },
  {
    postalCode: '56357',
    city: 'Dessighofen'
  },
  {
    postalCode: '56357',
    city: 'Dornholzhausen'
  },
  {
    postalCode: '56357',
    city: 'Ehr'
  },
  {
    postalCode: '56357',
    city: 'Eschbach'
  },
  {
    postalCode: '56357',
    city: 'Geisig'
  },
  {
    postalCode: '56357',
    city: 'Gemmerich'
  },
  {
    postalCode: '56357',
    city: 'Hainau'
  },
  {
    postalCode: '56357',
    city: 'Hasenmühle'
  },
  {
    postalCode: '56357',
    city: 'Himmighofen'
  },
  {
    postalCode: '56357',
    city: 'Hollermühle'
  },
  {
    postalCode: '56357',
    city: 'Holzhausen'
  },
  {
    postalCode: '56357',
    city: 'Kasdorf'
  },
  {
    postalCode: '56357',
    city: 'Lierschied'
  },
  {
    postalCode: '56357',
    city: 'Lipporn'
  },
  {
    postalCode: '56357',
    city: 'Lollschied'
  },
  {
    postalCode: '56357',
    city: 'Marienfels'
  },
  {
    postalCode: '56357',
    city: 'Miehlen'
  },
  {
    postalCode: '56357',
    city: 'Niederbachheim'
  },
  {
    postalCode: '56357',
    city: 'Niederwallmenach'
  },
  {
    postalCode: '56357',
    city: 'Nochern'
  },
  {
    postalCode: '56357',
    city: 'Obertiefenbach'
  },
  {
    postalCode: '56357',
    city: 'Oberwallmenach'
  },
  {
    postalCode: '56357',
    city: 'Oelsberg'
  },
  {
    postalCode: '56357',
    city: 'Pohl'
  },
  {
    postalCode: '56357',
    city: 'Reichenberg'
  },
  {
    postalCode: '56357',
    city: 'Reitzenhain'
  },
  {
    postalCode: '56357',
    city: 'Rettershain'
  },
  {
    postalCode: '56357',
    city: 'Ruppertshofen'
  },
  {
    postalCode: '56357',
    city: 'Strüth'
  },
  {
    postalCode: '56357',
    city: 'Uhusmühle'
  },
  {
    postalCode: '56357',
    city: 'Welterod'
  },
  {
    postalCode: '56357',
    city: 'Weyer'
  },
  {
    postalCode: '56368',
    city: 'Berghausen'
  },
  {
    postalCode: '56368',
    city: 'Ergeshausen'
  },
  {
    postalCode: '56368',
    city: 'Herold'
  },
  {
    postalCode: '56368',
    city: 'Katzenelnbogen'
  },
  {
    postalCode: '56368',
    city: 'Klingelbach'
  },
  {
    postalCode: '56368',
    city: 'Niedertiefenbach'
  },
  {
    postalCode: '56368',
    city: 'Roth'
  },
  {
    postalCode: '56370',
    city: 'Allendorf'
  },
  {
    postalCode: '56370',
    city: 'Attenhausen'
  },
  {
    postalCode: '56370',
    city: 'Berndroth'
  },
  {
    postalCode: '56370',
    city: 'Biebrich'
  },
  {
    postalCode: '56370',
    city: 'Bremberg'
  },
  {
    postalCode: '56370',
    city: 'Dörsdorf'
  },
  {
    postalCode: '56370',
    city: 'Ebertshausen'
  },
  {
    postalCode: '56370',
    city: 'Eisighofen'
  },
  {
    postalCode: '56370',
    city: 'Gutenacker'
  },
  {
    postalCode: '56370',
    city: 'Kördorf'
  },
  {
    postalCode: '56370',
    city: 'Mittelfischbach'
  },
  {
    postalCode: '56370',
    city: 'Oberfischbach'
  },
  {
    postalCode: '56370',
    city: 'Reckenroth'
  },
  {
    postalCode: '56370',
    city: 'Rettert'
  },
  {
    postalCode: '56370',
    city: 'Schönborn'
  },
  {
    postalCode: '56370',
    city: 'Wasenbach'
  },
  {
    postalCode: '56377',
    city: 'Gieshübel'
  },
  {
    postalCode: '56377',
    city: 'Herbelsmühle'
  },
  {
    postalCode: '56377',
    city: 'Kalkofen, Gem Dörnberg'
  },
  {
    postalCode: '56377',
    city: 'Misselberg'
  },
  {
    postalCode: '56377',
    city: 'Nassau'
  },
  {
    postalCode: '56377',
    city: 'Schleuse'
  },
  {
    postalCode: '56377',
    city: 'Schweighausen'
  },
  {
    postalCode: '56377',
    city: 'Seelbach'
  },
  {
    postalCode: '56377',
    city: 'Waldschmidtmühle'
  },
  {
    postalCode: '56379',
    city: 'Arnstein'
  },
  {
    postalCode: '56379',
    city: 'Bergerhof'
  },
  {
    postalCode: '56379',
    city: 'Buchholz'
  },
  {
    postalCode: '56379',
    city: 'Charlottenberg'
  },
  {
    postalCode: '56379',
    city: 'Dienethal'
  },
  {
    postalCode: '56379',
    city: 'Dörnberg'
  },
  {
    postalCode: '56379',
    city: 'Gasteyers Mühle'
  },
  {
    postalCode: '56379',
    city: 'Geilnau'
  },
  {
    postalCode: '56379',
    city: 'Holzappel'
  },
  {
    postalCode: '56379',
    city: 'Hömberg'
  },
  {
    postalCode: '56379',
    city: 'Horhausen'
  },
  {
    postalCode: '56379',
    city: 'Hütte, Gem Dörnberg'
  },
  {
    postalCode: '56379',
    city: 'Laurenburg'
  },
  {
    postalCode: '56379',
    city: 'Obernhof'
  },
  {
    postalCode: '56379',
    city: 'Oberwies'
  },
  {
    postalCode: '56379',
    city: 'Rupbach, Gem Bremberg'
  },
  {
    postalCode: '56379',
    city: 'Rupbach, Gem Gutenacker'
  },
  {
    postalCode: '56379',
    city: 'Rupbach, Gem Steinsberg'
  },
  {
    postalCode: '56379',
    city: 'Salscheid'
  },
  {
    postalCode: '56379',
    city: 'Scheidt'
  },
  {
    postalCode: '56379',
    city: 'Singhofen'
  },
  {
    postalCode: '56379',
    city: 'Steckenbergermühle'
  },
  {
    postalCode: '56379',
    city: 'Steinsberg'
  },
  {
    postalCode: '56379',
    city: 'Sulzbach'
  },
  {
    postalCode: '56379',
    city: 'Weinähr'
  },
  {
    postalCode: '56379',
    city: 'Winden'
  },
  {
    postalCode: '56379',
    city: 'Zechenhaus'
  },
  {
    postalCode: '56379',
    city: 'Zimmerschied'
  },
  {
    postalCode: '56410',
    city: 'Montabaur'
  },
  {
    postalCode: '56412',
    city: 'Boden'
  },
  {
    postalCode: '56412',
    city: 'Daubach'
  },
  {
    postalCode: '56412',
    city: 'Gackenbach'
  },
  {
    postalCode: '56412',
    city: 'Girod'
  },
  {
    postalCode: '56412',
    city: 'Görgeshausen'
  },
  {
    postalCode: '56412',
    city: 'Großholbach'
  },
  {
    postalCode: '56412',
    city: 'Heilberscheid'
  },
  {
    postalCode: '56412',
    city: 'Heiligenroth'
  },
  {
    postalCode: '56412',
    city: 'Holler'
  },
  {
    postalCode: '56412',
    city: 'Horbach'
  },
  {
    postalCode: '56412',
    city: 'Hübingen'
  },
  {
    postalCode: '56412',
    city: 'Nentershausen'
  },
  {
    postalCode: '56412',
    city: 'Niederelbert'
  },
  {
    postalCode: '56412',
    city: 'Niedererbach'
  },
  {
    postalCode: '56412',
    city: 'Nomborn'
  },
  {
    postalCode: '56412',
    city: 'Oberelbert'
  },
  {
    postalCode: '56412',
    city: 'Ruppach-Goldhausen'
  },
  {
    postalCode: '56412',
    city: 'Stahlhofen'
  },
  {
    postalCode: '56412',
    city: 'Untershausen'
  },
  {
    postalCode: '56412',
    city: 'Welschneudorf'
  },
  {
    postalCode: '56414',
    city: 'Berod'
  },
  {
    postalCode: '56414',
    city: 'Bilkheim'
  },
  {
    postalCode: '56414',
    city: 'Dreikirchen'
  },
  {
    postalCode: '56414',
    city: 'Hundsangen'
  },
  {
    postalCode: '56414',
    city: 'Meudt'
  },
  {
    postalCode: '56414',
    city: 'Molsberg'
  },
  {
    postalCode: '56414',
    city: 'Niederahr'
  },
  {
    postalCode: '56414',
    city: 'Oberahr'
  },
  {
    postalCode: '56414',
    city: 'Obererbach'
  },
  {
    postalCode: '56414',
    city: 'Salz'
  },
  {
    postalCode: '56414',
    city: 'Steinefrenz'
  },
  {
    postalCode: '56414',
    city: 'Wallmerod'
  },
  {
    postalCode: '56414',
    city: 'Weroth'
  },
  {
    postalCode: '56414',
    city: 'Zehnhausen'
  },
  {
    postalCode: '56422',
    city: 'Wirges'
  },
  {
    postalCode: '56424',
    city: 'Bannberscheid'
  },
  {
    postalCode: '56424',
    city: 'Ebernhahn'
  },
  {
    postalCode: '56424',
    city: 'Mogendorf'
  },
  {
    postalCode: '56424',
    city: 'Moschheim'
  },
  {
    postalCode: '56424',
    city: 'Staudt'
  },
  {
    postalCode: '56427',
    city: 'Siershahn'
  },
  {
    postalCode: '56428',
    city: 'Dernbach'
  },
  {
    postalCode: '56457',
    city: 'Halbs'
  },
  {
    postalCode: '56457',
    city: 'Hergenroth'
  },
  {
    postalCode: '56457',
    city: 'Jeremiasmühle'
  },
  {
    postalCode: '56457',
    city: 'Westerburg'
  },
  {
    postalCode: '56459',
    city: 'Ailertchen'
  },
  {
    postalCode: '56459',
    city: 'Bellingen'
  },
  {
    postalCode: '56459',
    city: 'Berzhahn'
  },
  {
    postalCode: '56459',
    city: 'Brandscheid'
  },
  {
    postalCode: '56459',
    city: 'Elbingen'
  },
  {
    postalCode: '56459',
    city: 'Gemünden'
  },
  {
    postalCode: '56459',
    city: 'Girkenroth'
  },
  {
    postalCode: '56459',
    city: 'Guckheim'
  },
  {
    postalCode: '56459',
    city: 'Hammermühle'
  },
  {
    postalCode: '56459',
    city: 'Harschbacherfeld'
  },
  {
    postalCode: '56459',
    city: 'Härtlingen'
  },
  {
    postalCode: '56459',
    city: 'Kaden'
  },
  {
    postalCode: '56459',
    city: 'Kölbingen'
  },
  {
    postalCode: '56459',
    city: 'Langenhahn'
  },
  {
    postalCode: '56459',
    city: 'Mähren'
  },
  {
    postalCode: '56459',
    city: 'Pottum'
  },
  {
    postalCode: '56459',
    city: 'Rotenhain'
  },
  {
    postalCode: '56459',
    city: 'Rothenbach'
  },
  {
    postalCode: '56459',
    city: 'Stahlhofen'
  },
  {
    postalCode: '56459',
    city: 'Stockum-Püschen'
  },
  {
    postalCode: '56459',
    city: 'Weltersburg'
  },
  {
    postalCode: '56459',
    city: 'Westert'
  },
  {
    postalCode: '56459',
    city: 'Willmenrod'
  },
  {
    postalCode: '56459',
    city: 'Winnen'
  },
  {
    postalCode: '56462',
    city: 'Hilpischmühle'
  },
  {
    postalCode: '56462',
    city: 'Höhn'
  },
  {
    postalCode: '56470',
    city: 'Bad Marienberg'
  },
  {
    postalCode: '56472',
    city: 'Dammühle'
  },
  {
    postalCode: '56472',
    city: 'Dreisbach'
  },
  {
    postalCode: '56472',
    city: 'Fehl-Ritzhausen'
  },
  {
    postalCode: '56472',
    city: 'Großseifen'
  },
  {
    postalCode: '56472',
    city: 'Hahn'
  },
  {
    postalCode: '56472',
    city: 'Hardt'
  },
  {
    postalCode: '56472',
    city: 'Hof'
  },
  {
    postalCode: '56472',
    city: 'Lautzenbrücken'
  },
  {
    postalCode: '56472',
    city: 'Nisterau'
  },
  {
    postalCode: '56472',
    city: 'Nisterberg'
  },
  {
    postalCode: '56472',
    city: 'Stockhausen-Illfurth'
  },
  {
    postalCode: '56477',
    city: 'Nister-Möhrendorf'
  },
  {
    postalCode: '56477',
    city: 'Rennerod'
  },
  {
    postalCode: '56477',
    city: 'Waigandshain'
  },
  {
    postalCode: '56477',
    city: 'Zehnhausen'
  },
  {
    postalCode: '56479',
    city: 'Bretthausen'
  },
  {
    postalCode: '56479',
    city: 'Elsoff'
  },
  {
    postalCode: '56479',
    city: 'Hellenhahn-Schellenberg'
  },
  {
    postalCode: '56479',
    city: 'Homberg'
  },
  {
    postalCode: '56479',
    city: 'Hüblingen'
  },
  {
    postalCode: '56479',
    city: 'Irmtraut'
  },
  {
    postalCode: '56479',
    city: 'Liebenscheid'
  },
  {
    postalCode: '56479',
    city: 'Neunkirchen'
  },
  {
    postalCode: '56479',
    city: 'Neustadt'
  },
  {
    postalCode: '56479',
    city: 'Niederroßbach'
  },
  {
    postalCode: '56479',
    city: 'Oberrod'
  },
  {
    postalCode: '56479',
    city: 'Oberroßbach'
  },
  {
    postalCode: '56479',
    city: 'Rehe'
  },
  {
    postalCode: '56479',
    city: 'Salzburg'
  },
  {
    postalCode: '56479',
    city: 'Seck'
  },
  {
    postalCode: '56479',
    city: 'Stein-Neukirch'
  },
  {
    postalCode: '56479',
    city: 'Waldmühlen'
  },
  {
    postalCode: '56479',
    city: 'Westernohe'
  },
  {
    postalCode: '56479',
    city: 'Willingen'
  },
  {
    postalCode: '56564',
    city: 'Neuwied'
  },
  {
    postalCode: '56566',
    city: 'Neuwied'
  },
  {
    postalCode: '56567',
    city: 'Neuwied'
  },
  {
    postalCode: '56575',
    city: 'Weißenthurm'
  },
  {
    postalCode: '56579',
    city: 'Bonefeld'
  },
  {
    postalCode: '56579',
    city: 'Hardert'
  },
  {
    postalCode: '56579',
    city: 'Rengsdorf'
  },
  {
    postalCode: '56581',
    city: 'Ehlscheid'
  },
  {
    postalCode: '56581',
    city: 'Hegerhof'
  },
  {
    postalCode: '56581',
    city: 'Kurtscheid'
  },
  {
    postalCode: '56581',
    city: 'Melsbach'
  },
  {
    postalCode: '56584',
    city: 'Anhausen'
  },
  {
    postalCode: '56584',
    city: 'Meinborn'
  },
  {
    postalCode: '56584',
    city: 'Rüscheid'
  },
  {
    postalCode: '56584',
    city: 'Thalhausen'
  },
  {
    postalCode: '56587',
    city: 'Oberhonnefeld-Gierend'
  },
  {
    postalCode: '56587',
    city: 'Oberraden'
  },
  {
    postalCode: '56587',
    city: 'Straßenhaus'
  },
  {
    postalCode: '56588',
    city: 'Bremscheid'
  },
  {
    postalCode: '56588',
    city: 'Sohl'
  },
  {
    postalCode: '56588',
    city: 'Stopperich'
  },
  {
    postalCode: '56588',
    city: 'Waldbreitbach'
  },
  {
    postalCode: '56589',
    city: 'Datzeroth'
  },
  {
    postalCode: '56589',
    city: 'Niederbreitbach'
  },
  {
    postalCode: '56593',
    city: 'Bürdenbach'
  },
  {
    postalCode: '56593',
    city: 'Güllesheim'
  },
  {
    postalCode: '56593',
    city: 'Horhausen'
  },
  {
    postalCode: '56593',
    city: 'Krunkel'
  },
  {
    postalCode: '56593',
    city: 'Niedersteinebach'
  },
  {
    postalCode: '56593',
    city: 'Obersteinebach'
  },
  {
    postalCode: '56593',
    city: 'Pleckhausen'
  },
  {
    postalCode: '56593',
    city: 'Pleckhausermühle'
  },
  {
    postalCode: '56594',
    city: 'Willroth'
  },
  {
    postalCode: '56598',
    city: 'Hammerstein'
  },
  {
    postalCode: '56598',
    city: 'Rheinbrohl'
  },
  {
    postalCode: '56599',
    city: 'Leutesdorf'
  },
  {
    postalCode: '56626',
    city: 'Andernach'
  },
  {
    postalCode: '56630',
    city: 'Kretz'
  },
  {
    postalCode: '56637',
    city: 'Plaidt'
  },
  {
    postalCode: '56642',
    city: 'Kruft'
  },
  {
    postalCode: '56645',
    city: 'Nickenich'
  },
  {
    postalCode: '56648',
    city: 'Saffig'
  },
  {
    postalCode: '56651',
    city: 'Brenk'
  },
  {
    postalCode: '56651',
    city: 'Galenberg'
  },
  {
    postalCode: '56651',
    city: 'Niederdürenbach'
  },
  {
    postalCode: '56651',
    city: 'Niederzissen'
  },
  {
    postalCode: '56651',
    city: 'Oberdürenbach'
  },
  {
    postalCode: '56651',
    city: 'Oberzissen'
  },
  {
    postalCode: '56653',
    city: 'Glees'
  },
  {
    postalCode: '56653',
    city: 'Maria Laach'
  },
  {
    postalCode: '56653',
    city: 'Wassenach'
  },
  {
    postalCode: '56653',
    city: 'Wehr'
  },
  {
    postalCode: '56656',
    city: 'Brohl-Lützing'
  },
  {
    postalCode: '56659',
    city: 'Burgbrohl'
  },
  {
    postalCode: '56727',
    city: 'Brachems'
  },
  {
    postalCode: '56727',
    city: 'Mayen'
  },
  {
    postalCode: '56727',
    city: 'Reudelsterz'
  },
  {
    postalCode: '56727',
    city: 'Sankt Johann'
  },
  {
    postalCode: '56729',
    city: 'Acht'
  },
  {
    postalCode: '56729',
    city: 'Anschau'
  },
  {
    postalCode: '56729',
    city: 'Arft'
  },
  {
    postalCode: '56729',
    city: 'Baar'
  },
  {
    postalCode: '56729',
    city: 'Bermel'
  },
  {
    postalCode: '56729',
    city: 'Boos'
  },
  {
    postalCode: '56729',
    city: 'Ditscheid'
  },
  {
    postalCode: '56729',
    city: 'Ettringen'
  },
  {
    postalCode: '56729',
    city: 'Geisbüschhof'
  },
  {
    postalCode: '56729',
    city: 'Herresbach'
  },
  {
    postalCode: '56729',
    city: 'Heunenhof'
  },
  {
    postalCode: '56729',
    city: 'Hirten'
  },
  {
    postalCode: '56729',
    city: 'Kehrig'
  },
  {
    postalCode: '56729',
    city: 'Kirchwald'
  },
  {
    postalCode: '56729',
    city: 'Langenfeld'
  },
  {
    postalCode: '56729',
    city: 'Langscheid'
  },
  {
    postalCode: '56729',
    city: 'Lind'
  },
  {
    postalCode: '56729',
    city: 'Luxem'
  },
  {
    postalCode: '56729',
    city: 'Mimbach'
  },
  {
    postalCode: '56729',
    city: 'Monreal'
  },
  {
    postalCode: '56729',
    city: 'Münk'
  },
  {
    postalCode: '56729',
    city: 'Nachtsheim'
  },
  {
    postalCode: '56729',
    city: 'Neu Virneburg'
  },
  {
    postalCode: '56729',
    city: 'Nitz'
  },
  {
    postalCode: '56729',
    city: 'Siebenbach'
  },
  {
    postalCode: '56729',
    city: 'Virneburg'
  },
  {
    postalCode: '56729',
    city: 'Weiler'
  },
  {
    postalCode: '56729',
    city: 'Welschenbach'
  },
  {
    postalCode: '56729',
    city: 'Wüsteratherhof'
  },
  {
    postalCode: '56736',
    city: 'Kottenheim'
  },
  {
    postalCode: '56743',
    city: 'Mendig'
  },
  {
    postalCode: '56743',
    city: 'Thür'
  },
  {
    postalCode: '56745',
    city: 'Bell'
  },
  {
    postalCode: '56745',
    city: 'Hausten'
  },
  {
    postalCode: '56745',
    city: 'Rieden'
  },
  {
    postalCode: '56745',
    city: 'Volkesfeld'
  },
  {
    postalCode: '56745',
    city: 'Weibern'
  },
  {
    postalCode: '56746',
    city: 'Hohenleimbach'
  },
  {
    postalCode: '56746',
    city: 'Kempenich'
  },
  {
    postalCode: '56746',
    city: 'Langhardt'
  },
  {
    postalCode: '56746',
    city: 'Spessart'
  },
  {
    postalCode: '56751',
    city: 'Einig'
  },
  {
    postalCode: '56751',
    city: 'Gering'
  },
  {
    postalCode: '56751',
    city: 'Kollig'
  },
  {
    postalCode: '56751',
    city: 'Polch'
  },
  {
    postalCode: '56753',
    city: 'Mertloch'
  },
  {
    postalCode: '56753',
    city: 'Naunheim'
  },
  {
    postalCode: '56753',
    city: 'Nettemühle'
  },
  {
    postalCode: '56753',
    city: 'Pillig'
  },
  {
    postalCode: '56753',
    city: 'Schloßhof'
  },
  {
    postalCode: '56753',
    city: 'Trimbs'
  },
  {
    postalCode: '56753',
    city: 'Welling'
  },
  {
    postalCode: '56754',
    city: 'Binningen'
  },
  {
    postalCode: '56754',
    city: 'Brohl'
  },
  {
    postalCode: '56754',
    city: 'Brückenmühle'
  },
  {
    postalCode: '56754',
    city: 'Dünfus'
  },
  {
    postalCode: '56754',
    city: 'Forst'
  },
  {
    postalCode: '56754',
    city: 'Möntenich'
  },
  {
    postalCode: '56754',
    city: 'Pilligerheck'
  },
  {
    postalCode: '56754',
    city: 'Roes'
  },
  {
    postalCode: '56754',
    city: 'Rotherhof'
  },
  {
    postalCode: '56754',
    city: 'Sauersmühle'
  },
  {
    postalCode: '56759',
    city: 'Eppenberg'
  },
  {
    postalCode: '56759',
    city: 'Kaisersesch'
  },
  {
    postalCode: '56759',
    city: 'Kalenborn'
  },
  {
    postalCode: '56759',
    city: 'Laubach'
  },
  {
    postalCode: '56759',
    city: 'Leienkaul'
  },
  {
    postalCode: '56759',
    city: 'Maria Martental'
  },
  {
    postalCode: '56759',
    city: 'Neuhof'
  },
  {
    postalCode: '56759',
    city: 'Präfekturhof'
  },
  {
    postalCode: '56759',
    city: 'Schöne Aussicht, Gem Landkern'
  },
  {
    postalCode: '56759',
    city: 'Schöne Aussicht, Gem Masburg'
  },
  {
    postalCode: '56761',
    city: 'Brachtendorf'
  },
  {
    postalCode: '56761',
    city: 'Düngenheim'
  },
  {
    postalCode: '56761',
    city: 'Eulgem'
  },
  {
    postalCode: '56761',
    city: 'Gamlen'
  },
  {
    postalCode: '56761',
    city: 'Hambuch'
  },
  {
    postalCode: '56761',
    city: 'Hauroth'
  },
  {
    postalCode: '56761',
    city: 'Kaifenheim'
  },
  {
    postalCode: '56761',
    city: 'Masburg'
  },
  {
    postalCode: '56761',
    city: 'Müllenbach'
  },
  {
    postalCode: '56761',
    city: 'Polcherholz'
  },
  {
    postalCode: '56761',
    city: 'Urmersbach'
  },
  {
    postalCode: '56761',
    city: 'Zettingen'
  },
  {
    postalCode: '56766',
    city: 'Auderath'
  },
  {
    postalCode: '56766',
    city: 'Berenbach'
  },
  {
    postalCode: '56766',
    city: 'Filz'
  },
  {
    postalCode: '56766',
    city: 'Horperath'
  },
  {
    postalCode: '56766',
    city: 'Ulmen'
  },
  {
    postalCode: '56767',
    city: 'Gunderath'
  },
  {
    postalCode: '56767',
    city: 'Höchstberg'
  },
  {
    postalCode: '56767',
    city: 'Kaperich'
  },
  {
    postalCode: '56767',
    city: 'Kölnische Höfe'
  },
  {
    postalCode: '56767',
    city: 'Kolverath'
  },
  {
    postalCode: '56767',
    city: 'Kötterichen'
  },
  {
    postalCode: '56767',
    city: 'Lirstal'
  },
  {
    postalCode: '56767',
    city: 'Mosbruch'
  },
  {
    postalCode: '56767',
    city: 'Oberelz'
  },
  {
    postalCode: '56767',
    city: 'Sassen'
  },
  {
    postalCode: '56767',
    city: 'Uersfeld'
  },
  {
    postalCode: '56767',
    city: 'Ueß'
  },
  {
    postalCode: '56769',
    city: 'Arbach'
  },
  {
    postalCode: '56769',
    city: 'Bereborn'
  },
  {
    postalCode: '56769',
    city: 'Mannebach'
  },
  {
    postalCode: '56769',
    city: 'Retterath'
  },
  {
    postalCode: '56812',
    city: 'Cochem'
  },
  {
    postalCode: '56812',
    city: 'Dohr'
  },
  {
    postalCode: '56812',
    city: 'Valwig'
  },
  {
    postalCode: '56814',
    city: 'Beilstein'
  },
  {
    postalCode: '56814',
    city: 'Bremm'
  },
  {
    postalCode: '56814',
    city: 'Bruttig-Fankel'
  },
  {
    postalCode: '56814',
    city: 'Ediger-Eller'
  },
  {
    postalCode: '56814',
    city: 'Ernst'
  },
  {
    postalCode: '56814',
    city: 'Faid'
  },
  {
    postalCode: '56814',
    city: 'Greimersburg'
  },
  {
    postalCode: '56814',
    city: 'Illerich'
  },
  {
    postalCode: '56814',
    city: 'Landkern'
  },
  {
    postalCode: '56814',
    city: 'Wirfus'
  },
  {
    postalCode: '56818',
    city: 'Klotten'
  },
  {
    postalCode: '56820',
    city: 'Briedern'
  },
  {
    postalCode: '56820',
    city: 'Mesenich'
  },
  {
    postalCode: '56820',
    city: 'Nehren'
  },
  {
    postalCode: '56820',
    city: 'Senheim'
  },
  {
    postalCode: '56821',
    city: 'Ellenz-Poltersdorf'
  },
  {
    postalCode: '56823',
    city: 'Büchel'
  },
  {
    postalCode: '56825',
    city: 'Beuren'
  },
  {
    postalCode: '56825',
    city: 'Gevenich'
  },
  {
    postalCode: '56825',
    city: 'Gillenbeuren'
  },
  {
    postalCode: '56825',
    city: 'Kliding'
  },
  {
    postalCode: '56825',
    city: 'Schmitt'
  },
  {
    postalCode: '56825',
    city: 'Urschmitt'
  },
  {
    postalCode: '56825',
    city: 'Weiler'
  },
  {
    postalCode: '56826',
    city: 'Heckenhof'
  },
  {
    postalCode: '56826',
    city: 'Lutzerath'
  },
  {
    postalCode: '56826',
    city: 'Wagenhausen'
  },
  {
    postalCode: '56826',
    city: 'Wollmerath'
  },
  {
    postalCode: '56828',
    city: 'Alflen'
  },
  {
    postalCode: '56829',
    city: 'Brieden'
  },
  {
    postalCode: '56829',
    city: 'Kail'
  },
  {
    postalCode: '56829',
    city: 'Pommern'
  },
  {
    postalCode: '56841',
    city: 'Hödeshof'
  },
  {
    postalCode: '56841',
    city: 'Traben-Trarbach'
  },
  {
    postalCode: '56843',
    city: 'Burg'
  },
  {
    postalCode: '56843',
    city: 'Irmenach'
  },
  {
    postalCode: '56843',
    city: 'Lötzbeuren'
  },
  {
    postalCode: '56843',
    city: 'Scholmunderhof'
  },
  {
    postalCode: '56843',
    city: 'Starkenburg'
  },
  {
    postalCode: '56850',
    city: 'Briedeler Heck'
  },
  {
    postalCode: '56850',
    city: 'Enkirch'
  },
  {
    postalCode: '56850',
    city: 'Hahn'
  },
  {
    postalCode: '56850',
    city: 'Hohestein'
  },
  {
    postalCode: '56850',
    city: 'Maiermund'
  },
  {
    postalCode: '56850',
    city: 'Raversbeuren'
  },
  {
    postalCode: '56856',
    city: 'Zell'
  },
  {
    postalCode: '56858',
    city: 'Altlay'
  },
  {
    postalCode: '56858',
    city: 'Altstrimmig'
  },
  {
    postalCode: '56858',
    city: 'Belg'
  },
  {
    postalCode: '56858',
    city: 'Forst'
  },
  {
    postalCode: '56858',
    city: 'Grenderich'
  },
  {
    postalCode: '56858',
    city: 'Haserich'
  },
  {
    postalCode: '56858',
    city: 'Liesenich'
  },
  {
    postalCode: '56858',
    city: 'Mittelstrimmig'
  },
  {
    postalCode: '56858',
    city: 'Neef'
  },
  {
    postalCode: '56858',
    city: 'Peterswald-Löffelscheid'
  },
  {
    postalCode: '56858',
    city: 'Rödelhausen'
  },
  {
    postalCode: '56858',
    city: 'Sankt Aldegund'
  },
  {
    postalCode: '56858',
    city: 'Sosberg'
  },
  {
    postalCode: '56858',
    city: 'Tellig'
  },
  {
    postalCode: '56858',
    city: 'Würrich'
  },
  {
    postalCode: '56859',
    city: 'Alf'
  },
  {
    postalCode: '56859',
    city: 'Bullay'
  },
  {
    postalCode: '56859',
    city: 'Weidenhell'
  },
  {
    postalCode: '56861',
    city: 'Reil'
  },
  {
    postalCode: '56862',
    city: 'Pünderich'
  },
  {
    postalCode: '56864',
    city: 'Bad Bertrich'
  },
  {
    postalCode: '56864',
    city: 'Beurenermühle'
  },
  {
    postalCode: '56864',
    city: 'Bonsbeuern'
  },
  {
    postalCode: '56864',
    city: 'Pelzerhaus'
  },
  {
    postalCode: '56865',
    city: 'Blankenrath'
  },
  {
    postalCode: '56865',
    city: 'Hesweiler'
  },
  {
    postalCode: '56865',
    city: 'Moritzheim'
  },
  {
    postalCode: '56865',
    city: 'Panzweiler'
  },
  {
    postalCode: '56865',
    city: 'Reidenhausen'
  },
  {
    postalCode: '56865',
    city: 'Schauren'
  },
  {
    postalCode: '56865',
    city: 'Vogthof'
  },
  {
    postalCode: '56865',
    city: 'Walhausen'
  },
  {
    postalCode: '56867',
    city: 'Briedel'
  },
  {
    postalCode: '56869',
    city: 'Mastershausen'
  },
  {
    postalCode: '57072',
    city: 'Siegen'
  },
  {
    postalCode: '57074',
    city: 'Siegen'
  },
  {
    postalCode: '57076',
    city: 'Siegen'
  },
  {
    postalCode: '57078',
    city: 'Siegen'
  },
  {
    postalCode: '57080',
    city: 'Siegen'
  },
  {
    postalCode: '57223',
    city: 'Kreuztal'
  },
  {
    postalCode: '57234',
    city: 'Wilnsdorf'
  },
  {
    postalCode: '57250',
    city: 'Netphen'
  },
  {
    postalCode: '57258',
    city: 'Freudenberg'
  },
  {
    postalCode: '57271',
    city: 'Hilchenbach'
  },
  {
    postalCode: '57290',
    city: 'Neunkirchen'
  },
  {
    postalCode: '57299',
    city: 'Burbach'
  },
  {
    postalCode: '57319',
    city: 'Bad Berleburg'
  },
  {
    postalCode: '57334',
    city: 'Bad Laasphe'
  },
  {
    postalCode: '57339',
    city: 'Erndtebrück'
  },
  {
    postalCode: '57368',
    city: 'Lennestadt'
  },
  {
    postalCode: '57392',
    city: 'Schmallenberg'
  },
  {
    postalCode: '57399',
    city: 'Kirchhundem'
  },
  {
    postalCode: '57413',
    city: 'Finnentrop'
  },
  {
    postalCode: '57439',
    city: 'Attendorn'
  },
  {
    postalCode: '57462',
    city: 'Olpe'
  },
  {
    postalCode: '57482',
    city: 'Wenden'
  },
  {
    postalCode: '57489',
    city: 'Drolshagen'
  },
  {
    postalCode: '57518',
    city: 'Alsdorf'
  },
  {
    postalCode: '57518',
    city: 'Betzdorf'
  },
  {
    postalCode: '57518',
    city: 'Steineroth'
  },
  {
    postalCode: '57520',
    city: 'Derschen'
  },
  {
    postalCode: '57520',
    city: 'Dickendorf'
  },
  {
    postalCode: '57520',
    city: 'Emmerzhausen'
  },
  {
    postalCode: '57520',
    city: 'Friedewald'
  },
  {
    postalCode: '57520',
    city: 'Grünebach'
  },
  {
    postalCode: '57520',
    city: 'Kausen'
  },
  {
    postalCode: '57520',
    city: 'Langenbach'
  },
  {
    postalCode: '57520',
    city: 'Mauden'
  },
  {
    postalCode: '57520',
    city: 'Molzhain'
  },
  {
    postalCode: '57520',
    city: 'Neunkhausen'
  },
  {
    postalCode: '57520',
    city: 'Niederdreisbach'
  },
  {
    postalCode: '57520',
    city: 'Rosenheim'
  },
  {
    postalCode: '57520',
    city: 'Rosenheimerlay'
  },
  {
    postalCode: '57520',
    city: 'Schutzbach'
  },
  {
    postalCode: '57520',
    city: 'Steinebach'
  },
  {
    postalCode: '57537',
    city: 'Dellingen'
  },
  {
    postalCode: '57537',
    city: 'Forst'
  },
  {
    postalCode: '57537',
    city: 'Hövels'
  },
  {
    postalCode: '57537',
    city: 'Kaltau'
  },
  {
    postalCode: '57537',
    city: 'Langenbach'
  },
  {
    postalCode: '57537',
    city: 'Lechenbach'
  },
  {
    postalCode: '57537',
    city: 'Mittelhof'
  },
  {
    postalCode: '57537',
    city: 'Selbach'
  },
  {
    postalCode: '57537',
    city: 'Thal'
  },
  {
    postalCode: '57537',
    city: 'Wissen'
  },
  {
    postalCode: '57539',
    city: 'Bitzen'
  },
  {
    postalCode: '57539',
    city: 'Breitscheidt'
  },
  {
    postalCode: '57539',
    city: 'Bruchertseifen'
  },
  {
    postalCode: '57539',
    city: 'Etzbach'
  },
  {
    postalCode: '57539',
    city: 'Fürthen'
  },
  {
    postalCode: '57539',
    city: 'Heckenhof'
  },
  {
    postalCode: '57539',
    city: 'Herrgottsau'
  },
  {
    postalCode: '57539',
    city: 'Neuschlade'
  },
  {
    postalCode: '57539',
    city: 'Roth'
  },
  {
    postalCode: '57548',
    city: 'Diedenberg'
  },
  {
    postalCode: '57548',
    city: 'Kirchen'
  },
  {
    postalCode: '57555',
    city: 'Brachbach'
  },
  {
    postalCode: '57555',
    city: 'Euteneuen'
  },
  {
    postalCode: '57555',
    city: 'Mudersbach'
  },
  {
    postalCode: '57562',
    city: 'Herdorf'
  },
  {
    postalCode: '57567',
    city: 'Daaden'
  },
  {
    postalCode: '57572',
    city: 'Harbach'
  },
  {
    postalCode: '57572',
    city: 'Niederfischbach'
  },
  {
    postalCode: '57577',
    city: 'Hamm'
  },
  {
    postalCode: '57577',
    city: 'Pfannenschoppen'
  },
  {
    postalCode: '57577',
    city: 'Seelbach'
  },
  {
    postalCode: '57577',
    city: 'Thalhausen'
  },
  {
    postalCode: '57577',
    city: 'Thalhausermühle'
  },
  {
    postalCode: '57578',
    city: 'Elkenroth'
  },
  {
    postalCode: '57580',
    city: 'Elben'
  },
  {
    postalCode: '57580',
    city: 'Fensdorf'
  },
  {
    postalCode: '57580',
    city: 'Gebhardshain'
  },
  {
    postalCode: '57581',
    city: 'Katzwinkel'
  },
  {
    postalCode: '57583',
    city: 'Mörlen'
  },
  {
    postalCode: '57583',
    city: 'Nauroth'
  },
  {
    postalCode: '57584',
    city: 'Scheuerfeld'
  },
  {
    postalCode: '57584',
    city: 'Wallmenroth'
  },
  {
    postalCode: '57586',
    city: 'Weitefeld'
  },
  {
    postalCode: '57587',
    city: 'Birken-Honigsessen'
  },
  {
    postalCode: '57587',
    city: 'Mühlenthal'
  },
  {
    postalCode: '57589',
    city: 'Birkenbeul'
  },
  {
    postalCode: '57589',
    city: 'Friedenthal'
  },
  {
    postalCode: '57589',
    city: 'Niederirsen'
  },
  {
    postalCode: '57589',
    city: 'Pracht'
  },
  {
    postalCode: '57610',
    city: 'Almersbach'
  },
  {
    postalCode: '57610',
    city: 'Altenkirchen'
  },
  {
    postalCode: '57610',
    city: 'Bachenberg'
  },
  {
    postalCode: '57610',
    city: 'Gieleroth'
  },
  {
    postalCode: '57610',
    city: 'Ingelbach'
  },
  {
    postalCode: '57610',
    city: 'Michelbach'
  },
  {
    postalCode: '57612',
    city: 'Birnbach'
  },
  {
    postalCode: '57612',
    city: 'Busenhausen'
  },
  {
    postalCode: '57612',
    city: 'Eichelhardt'
  },
  {
    postalCode: '57612',
    city: 'Giesenhausen'
  },
  {
    postalCode: '57612',
    city: 'Helmenzen'
  },
  {
    postalCode: '57612',
    city: 'Helmeroth'
  },
  {
    postalCode: '57612',
    city: 'Hemmelzen'
  },
  {
    postalCode: '57612',
    city: 'Heupelzen'
  },
  {
    postalCode: '57612',
    city: 'Hilgenroth'
  },
  {
    postalCode: '57612',
    city: 'Idelberg'
  },
  {
    postalCode: '57612',
    city: 'Ingelbach-Bahnhof'
  },
  {
    postalCode: '57612',
    city: 'Isert'
  },
  {
    postalCode: '57612',
    city: 'Kettenhausen'
  },
  {
    postalCode: '57612',
    city: 'Kroppach'
  },
  {
    postalCode: '57612',
    city: 'Marienthal'
  },
  {
    postalCode: '57612',
    city: 'Obererbach'
  },
  {
    postalCode: '57612',
    city: 'Ölsen'
  },
  {
    postalCode: '57612',
    city: 'Racksen'
  },
  {
    postalCode: '57612',
    city: 'Volkerzen'
  },
  {
    postalCode: '57614',
    city: 'Alberthofen'
  },
  {
    postalCode: '57614',
    city: 'Berod'
  },
  {
    postalCode: '57614',
    city: 'Borod'
  },
  {
    postalCode: '57614',
    city: 'Brubbach'
  },
  {
    postalCode: '57614',
    city: 'Fluterschen'
  },
  {
    postalCode: '57614',
    city: 'Mudenbach'
  },
  {
    postalCode: '57614',
    city: 'Niederwambach'
  },
  {
    postalCode: '57614',
    city: 'Oberwambach'
  },
  {
    postalCode: '57614',
    city: 'Ratzert'
  },
  {
    postalCode: '57614',
    city: 'Steimel'
  },
  {
    postalCode: '57614',
    city: 'Stürzelbach'
  },
  {
    postalCode: '57614',
    city: 'Wahlrod'
  },
  {
    postalCode: '57614',
    city: 'Woldert'
  },
  {
    postalCode: '57627',
    city: 'Astert'
  },
  {
    postalCode: '57627',
    city: 'Gehlert'
  },
  {
    postalCode: '57627',
    city: 'Hachenburg'
  },
  {
    postalCode: '57627',
    city: 'Heuzert'
  },
  {
    postalCode: '57627',
    city: 'Marzhausen'
  },
  {
    postalCode: '57629',
    city: 'Atzelgift'
  },
  {
    postalCode: '57629',
    city: 'Dreifelden'
  },
  {
    postalCode: '57629',
    city: 'Heimborn'
  },
  {
    postalCode: '57629',
    city: 'Höchstenbach'
  },
  {
    postalCode: '57629',
    city: 'Kirburg'
  },
  {
    postalCode: '57629',
    city: 'Kundert'
  },
  {
    postalCode: '57629',
    city: 'Limbach'
  },
  {
    postalCode: '57629',
    city: 'Linden'
  },
  {
    postalCode: '57629',
    city: 'Lochum'
  },
  {
    postalCode: '57629',
    city: 'Luckenbach'
  },
  {
    postalCode: '57629',
    city: 'Malberg'
  },
  {
    postalCode: '57629',
    city: 'Marienstatt'
  },
  {
    postalCode: '57629',
    city: 'Merkelbach'
  },
  {
    postalCode: '57629',
    city: 'Mörsbach'
  },
  {
    postalCode: '57629',
    city: 'Müschenbach'
  },
  {
    postalCode: '57629',
    city: 'Norken'
  },
  {
    postalCode: '57629',
    city: 'Steinebach'
  },
  {
    postalCode: '57629',
    city: 'Stein-Wingert'
  },
  {
    postalCode: '57629',
    city: 'Streithausen'
  },
  {
    postalCode: '57629',
    city: 'Wied'
  },
  {
    postalCode: '57632',
    city: 'Berzhausen'
  },
  {
    postalCode: '57632',
    city: 'Burglahr'
  },
  {
    postalCode: '57632',
    city: 'Eichen'
  },
  {
    postalCode: '57632',
    city: 'Eulenberg'
  },
  {
    postalCode: '57632',
    city: 'Flammersfeld'
  },
  {
    postalCode: '57632',
    city: 'Giershausen'
  },
  {
    postalCode: '57632',
    city: 'Kescheid'
  },
  {
    postalCode: '57632',
    city: 'Orfgen'
  },
  {
    postalCode: '57632',
    city: 'Peterslahr'
  },
  {
    postalCode: '57632',
    city: 'Reiferscheid'
  },
  {
    postalCode: '57632',
    city: 'Rott'
  },
  {
    postalCode: '57632',
    city: 'Schürdt'
  },
  {
    postalCode: '57632',
    city: 'Seelbach'
  },
  {
    postalCode: '57632',
    city: 'Seifen'
  },
  {
    postalCode: '57632',
    city: 'Walterschen'
  },
  {
    postalCode: '57632',
    city: 'Ziegenhain'
  },
  {
    postalCode: '57635',
    city: 'Ersfeld'
  },
  {
    postalCode: '57635',
    city: 'Fiersbach'
  },
  {
    postalCode: '57635',
    city: 'Forstmehren'
  },
  {
    postalCode: '57635',
    city: 'Hasselbach'
  },
  {
    postalCode: '57635',
    city: 'Heuberg'
  },
  {
    postalCode: '57635',
    city: 'Hirz-Maulsbach'
  },
  {
    postalCode: '57635',
    city: 'Kircheib'
  },
  {
    postalCode: '57635',
    city: 'Kraam'
  },
  {
    postalCode: '57635',
    city: 'Mehren'
  },
  {
    postalCode: '57635',
    city: 'Oberirsen'
  },
  {
    postalCode: '57635',
    city: 'Rettersen'
  },
  {
    postalCode: '57635',
    city: 'Werkhausen'
  },
  {
    postalCode: '57635',
    city: 'Weyerbusch'
  },
  {
    postalCode: '57635',
    city: 'Wölmersen'
  },
  {
    postalCode: '57636',
    city: 'Mammelzen'
  },
  {
    postalCode: '57636',
    city: 'Sörth'
  },
  {
    postalCode: '57638',
    city: 'Neitersen'
  },
  {
    postalCode: '57638',
    city: 'Obernau'
  },
  {
    postalCode: '57638',
    city: 'Schöneberg'
  },
  {
    postalCode: '57639',
    city: 'Oberdreis'
  },
  {
    postalCode: '57639',
    city: 'Rodenbach'
  },
  {
    postalCode: '57641',
    city: 'Oberlahr'
  },
  {
    postalCode: '57642',
    city: 'Alpenrod'
  },
  {
    postalCode: '57644',
    city: 'Hattert'
  },
  {
    postalCode: '57644',
    city: 'Krambergsmühle'
  },
  {
    postalCode: '57644',
    city: 'Marzauermühle'
  },
  {
    postalCode: '57644',
    city: 'Welkenbach'
  },
  {
    postalCode: '57644',
    city: 'Winkelbach'
  },
  {
    postalCode: '57645',
    city: 'Kellershof'
  },
  {
    postalCode: '57645',
    city: 'Nister'
  },
  {
    postalCode: '57647',
    city: 'Enspel'
  },
  {
    postalCode: '57647',
    city: 'Hirtscheid'
  },
  {
    postalCode: '57647',
    city: 'Nistertal'
  },
  {
    postalCode: '57647',
    city: 'Stöffel'
  },
  {
    postalCode: '57647',
    city: 'Talmühle'
  },
  {
    postalCode: '57648',
    city: 'Bölsberg'
  },
  {
    postalCode: '57648',
    city: 'Unnau'
  },
  {
    postalCode: '58089',
    city: 'Hagen'
  },
  {
    postalCode: '58091',
    city: 'Hagen'
  },
  {
    postalCode: '58093',
    city: 'Hagen'
  },
  {
    postalCode: '58095',
    city: 'Hagen'
  },
  {
    postalCode: '58097',
    city: 'Hagen'
  },
  {
    postalCode: '58099',
    city: 'Hagen'
  },
  {
    postalCode: '58119',
    city: 'Hagen'
  },
  {
    postalCode: '58135',
    city: 'Hagen'
  },
  {
    postalCode: '58239',
    city: 'Schwerte'
  },
  {
    postalCode: '58256',
    city: 'Ennepetal'
  },
  {
    postalCode: '58285',
    city: 'Gevelsberg'
  },
  {
    postalCode: '58300',
    city: 'Wetter'
  },
  {
    postalCode: '58313',
    city: 'Herdecke'
  },
  {
    postalCode: '58332',
    city: 'Schwelm'
  },
  {
    postalCode: '58339',
    city: 'Breckerfeld'
  },
  {
    postalCode: '58452',
    city: 'Witten'
  },
  {
    postalCode: '58453',
    city: 'Witten'
  },
  {
    postalCode: '58454',
    city: 'Witten'
  },
  {
    postalCode: '58455',
    city: 'Witten'
  },
  {
    postalCode: '58456',
    city: 'Witten'
  },
  {
    postalCode: '58507',
    city: 'Lüdenscheid'
  },
  {
    postalCode: '58509',
    city: 'Lüdenscheid'
  },
  {
    postalCode: '58511',
    city: 'Lüdenscheid'
  },
  {
    postalCode: '58513',
    city: 'Lüdenscheid'
  },
  {
    postalCode: '58515',
    city: 'Lüdenscheid'
  },
  {
    postalCode: '58540',
    city: 'Meinerzhagen'
  },
  {
    postalCode: '58553',
    city: 'Halver'
  },
  {
    postalCode: '58566',
    city: 'Kierspe'
  },
  {
    postalCode: '58579',
    city: 'Schalksmühle'
  },
  {
    postalCode: '58636',
    city: 'Iserlohn'
  },
  {
    postalCode: '58638',
    city: 'Iserlohn'
  },
  {
    postalCode: '58640',
    city: 'Iserlohn'
  },
  {
    postalCode: '58642',
    city: 'Iserlohn'
  },
  {
    postalCode: '58644',
    city: 'Iserlohn'
  },
  {
    postalCode: '58675',
    city: 'Hemer'
  },
  {
    postalCode: '58706',
    city: 'Menden'
  },
  {
    postalCode: '58708',
    city: 'Menden'
  },
  {
    postalCode: '58710',
    city: 'Menden'
  },
  {
    postalCode: '58730',
    city: 'Fröndenberg'
  },
  {
    postalCode: '58739',
    city: 'Wickede'
  },
  {
    postalCode: '58762',
    city: 'Altena'
  },
  {
    postalCode: '58769',
    city: 'Nachrodt-Wiblingwerde'
  },
  {
    postalCode: '58791',
    city: 'Werdohl'
  },
  {
    postalCode: '58802',
    city: 'Balve'
  },
  {
    postalCode: '58809',
    city: 'Neuenrade'
  },
  {
    postalCode: '58840',
    city: 'Plettenberg'
  },
  {
    postalCode: '58849',
    city: 'Herscheid'
  },
  {
    postalCode: '59063',
    city: 'Hamm'
  },
  {
    postalCode: '59065',
    city: 'Hamm'
  },
  {
    postalCode: '59067',
    city: 'Hamm'
  },
  {
    postalCode: '59069',
    city: 'Hamm'
  },
  {
    postalCode: '59071',
    city: 'Hamm'
  },
  {
    postalCode: '59073',
    city: 'Hamm'
  },
  {
    postalCode: '59075',
    city: 'Hamm'
  },
  {
    postalCode: '59077',
    city: 'Hamm'
  },
  {
    postalCode: '59174',
    city: 'Kamen'
  },
  {
    postalCode: '59192',
    city: 'Bergkamen'
  },
  {
    postalCode: '59199',
    city: 'Bönen'
  },
  {
    postalCode: '59227',
    city: 'Ahlen'
  },
  {
    postalCode: '59229',
    city: 'Ahlen'
  },
  {
    postalCode: '59269',
    city: 'Beckum'
  },
  {
    postalCode: '59302',
    city: 'Oelde'
  },
  {
    postalCode: '59320',
    city: 'Ennigerloh'
  },
  {
    postalCode: '59329',
    city: 'Wadersloh'
  },
  {
    postalCode: '59348',
    city: 'Lüdinghausen'
  },
  {
    postalCode: '59368',
    city: 'Werne'
  },
  {
    postalCode: '59379',
    city: 'Selm'
  },
  {
    postalCode: '59387',
    city: 'Ascheberg'
  },
  {
    postalCode: '59394',
    city: 'Nordkirchen'
  },
  {
    postalCode: '59399',
    city: 'Olfen'
  },
  {
    postalCode: '59423',
    city: 'Unna'
  },
  {
    postalCode: '59425',
    city: 'Unna'
  },
  {
    postalCode: '59427',
    city: 'Unna'
  },
  {
    postalCode: '59439',
    city: 'Holzwickede'
  },
  {
    postalCode: '59457',
    city: 'Werl'
  },
  {
    postalCode: '59469',
    city: 'Ense'
  },
  {
    postalCode: '59494',
    city: 'Soest'
  },
  {
    postalCode: '59505',
    city: 'Bad Sassendorf'
  },
  {
    postalCode: '59510',
    city: 'Lippetal'
  },
  {
    postalCode: '59514',
    city: 'Welver'
  },
  {
    postalCode: '59519',
    city: 'Möhnesee'
  },
  {
    postalCode: '59555',
    city: 'Lippstadt'
  },
  {
    postalCode: '59556',
    city: 'Lippstadt'
  },
  {
    postalCode: '59557',
    city: 'Lippstadt'
  },
  {
    postalCode: '59558',
    city: 'Lippstadt'
  },
  {
    postalCode: '59581',
    city: 'Warstein'
  },
  {
    postalCode: '59590',
    city: 'Geseke'
  },
  {
    postalCode: '59597',
    city: 'Erwitte'
  },
  {
    postalCode: '59602',
    city: 'Rüthen'
  },
  {
    postalCode: '59609',
    city: 'Anröchte'
  },
  {
    postalCode: '59755',
    city: 'Arnsberg'
  },
  {
    postalCode: '59757',
    city: 'Arnsberg'
  },
  {
    postalCode: '59759',
    city: 'Arnsberg'
  },
  {
    postalCode: '59821',
    city: 'Arnsberg'
  },
  {
    postalCode: '59823',
    city: 'Arnsberg'
  },
  {
    postalCode: '59846',
    city: 'Sundern'
  },
  {
    postalCode: '59872',
    city: 'Meschede'
  },
  {
    postalCode: '59889',
    city: 'Eslohe'
  },
  {
    postalCode: '59909',
    city: 'Bestwig'
  },
  {
    postalCode: '59929',
    city: 'Brilon'
  },
  {
    postalCode: '59939',
    city: 'Olsberg'
  },
  {
    postalCode: '59955',
    city: 'Winterberg'
  },
  {
    postalCode: '59964',
    city: 'Medebach'
  },
  {
    postalCode: '59969',
    city: 'Bromskirchen'
  },
  {
    postalCode: '59969',
    city: 'Hallenberg'
  },
  {
    postalCode: '60308',
    city: 'Frankfurt'
  },
  {
    postalCode: '60311',
    city: 'Frankfurt'
  },
  {
    postalCode: '60313',
    city: 'Frankfurt'
  },
  {
    postalCode: '60314',
    city: 'Frankfurt'
  },
  {
    postalCode: '60316',
    city: 'Frankfurt'
  },
  {
    postalCode: '60318',
    city: 'Frankfurt'
  },
  {
    postalCode: '60320',
    city: 'Frankfurt'
  },
  {
    postalCode: '60322',
    city: 'Frankfurt'
  },
  {
    postalCode: '60323',
    city: 'Frankfurt'
  },
  {
    postalCode: '60325',
    city: 'Frankfurt'
  },
  {
    postalCode: '60326',
    city: 'Frankfurt'
  },
  {
    postalCode: '60327',
    city: 'Frankfurt'
  },
  {
    postalCode: '60329',
    city: 'Frankfurt'
  },
  {
    postalCode: '60385',
    city: 'Frankfurt'
  },
  {
    postalCode: '60386',
    city: 'Frankfurt'
  },
  {
    postalCode: '60388',
    city: 'Frankfurt'
  },
  {
    postalCode: '60389',
    city: 'Frankfurt'
  },
  {
    postalCode: '60431',
    city: 'Frankfurt'
  },
  {
    postalCode: '60433',
    city: 'Frankfurt'
  },
  {
    postalCode: '60435',
    city: 'Frankfurt'
  },
  {
    postalCode: '60437',
    city: 'Frankfurt'
  },
  {
    postalCode: '60438',
    city: 'Frankfurt'
  },
  {
    postalCode: '60439',
    city: 'Frankfurt'
  },
  {
    postalCode: '60486',
    city: 'Frankfurt'
  },
  {
    postalCode: '60487',
    city: 'Frankfurt'
  },
  {
    postalCode: '60488',
    city: 'Frankfurt'
  },
  {
    postalCode: '60489',
    city: 'Frankfurt'
  },
  {
    postalCode: '60528',
    city: 'Frankfurt'
  },
  {
    postalCode: '60529',
    city: 'Frankfurt'
  },
  {
    postalCode: '60549',
    city: 'Frankfurt'
  },
  {
    postalCode: '60594',
    city: 'Frankfurt'
  },
  {
    postalCode: '60596',
    city: 'Frankfurt'
  },
  {
    postalCode: '60598',
    city: 'Frankfurt'
  },
  {
    postalCode: '60599',
    city: 'Frankfurt'
  },
  {
    postalCode: '61118',
    city: 'Bad Vilbel'
  },
  {
    postalCode: '61130',
    city: 'Nidderau'
  },
  {
    postalCode: '61130',
    city: 'Winnerhöfe'
  },
  {
    postalCode: '61137',
    city: 'Schöneck'
  },
  {
    postalCode: '61138',
    city: 'Niederdorfelden'
  },
  {
    postalCode: '61169',
    city: 'Friedberg'
  },
  {
    postalCode: '61184',
    city: 'Karben'
  },
  {
    postalCode: '61191',
    city: 'Rosbach'
  },
  {
    postalCode: '61194',
    city: 'Niddatal'
  },
  {
    postalCode: '61197',
    city: 'Florstadt'
  },
  {
    postalCode: '61200',
    city: 'Am Römerhof'
  },
  {
    postalCode: '61200',
    city: 'Am Römerschacht'
  },
  {
    postalCode: '61200',
    city: 'Wölfersheim'
  },
  {
    postalCode: '61203',
    city: 'Bingenheimer Mühle'
  },
  {
    postalCode: '61203',
    city: 'Eiserhof'
  },
  {
    postalCode: '61203',
    city: 'Reichelsheim'
  },
  {
    postalCode: '61203',
    city: 'Winterhof'
  },
  {
    postalCode: '61206',
    city: 'Wöllstadt'
  },
  {
    postalCode: '61209',
    city: 'Echzell'
  },
  {
    postalCode: '61209',
    city: 'Kreuzquelle'
  },
  {
    postalCode: '61231',
    city: 'Bad Nauheim'
  },
  {
    postalCode: '61239',
    city: 'Ober-Mörlen'
  },
  {
    postalCode: '61250',
    city: 'Erdfunkstelle Usingen'
  },
  {
    postalCode: '61250',
    city: 'Usingen'
  },
  {
    postalCode: '61267',
    city: 'Neu-Anspach'
  },
  {
    postalCode: '61273',
    city: 'Saalburg'
  },
  {
    postalCode: '61273',
    city: 'Wehrheim'
  },
  {
    postalCode: '61276',
    city: 'Weilrod'
  },
  {
    postalCode: '61279',
    city: 'Grävenwiesbach'
  },
  {
    postalCode: '61348',
    city: 'Bad Homburg'
  },
  {
    postalCode: '61350',
    city: 'Bad Homburg'
  },
  {
    postalCode: '61352',
    city: 'Bad Homburg'
  },
  {
    postalCode: '61381',
    city: 'Friedrichsdorf'
  },
  {
    postalCode: '61389',
    city: 'Schmitten'
  },
  {
    postalCode: '61440',
    city: 'Oberursel'
  },
  {
    postalCode: '61449',
    city: 'Steinbach'
  },
  {
    postalCode: '61462',
    city: 'Königstein'
  },
  {
    postalCode: '61476',
    city: 'Kronberg'
  },
  {
    postalCode: '61479',
    city: 'Glashütten'
  },
  {
    postalCode: '63065',
    city: 'Offenbach'
  },
  {
    postalCode: '63067',
    city: 'Offenbach'
  },
  {
    postalCode: '63069',
    city: 'Offenbach'
  },
  {
    postalCode: '63071',
    city: 'Offenbach'
  },
  {
    postalCode: '63073',
    city: 'Offenbach'
  },
  {
    postalCode: '63075',
    city: 'Offenbach'
  },
  {
    postalCode: '63110',
    city: 'Rodgau'
  },
  {
    postalCode: '63128',
    city: 'Dietzenbach'
  },
  {
    postalCode: '63150',
    city: 'Heusenstamm'
  },
  {
    postalCode: '63150',
    city: 'Wildhof'
  },
  {
    postalCode: '63165',
    city: 'Mühlheim'
  },
  {
    postalCode: '63179',
    city: 'Obertshausen'
  },
  {
    postalCode: '63225',
    city: 'Langen'
  },
  {
    postalCode: '63263',
    city: 'Neu-Isenburg'
  },
  {
    postalCode: '63303',
    city: 'Dreieich'
  },
  {
    postalCode: '63322',
    city: 'Rödermark'
  },
  {
    postalCode: '63329',
    city: 'Egelsbach'
  },
  {
    postalCode: '63450',
    city: 'Hanau'
  },
  {
    postalCode: '63452',
    city: 'Hanau'
  },
  {
    postalCode: '63454',
    city: 'Hanau'
  },
  {
    postalCode: '63456',
    city: 'Hanau'
  },
  {
    postalCode: '63457',
    city: 'Hanau'
  },
  {
    postalCode: '63477',
    city: 'Maintal'
  },
  {
    postalCode: '63486',
    city: 'Bruchköbel'
  },
  {
    postalCode: '63500',
    city: 'Seligenstadt'
  },
  {
    postalCode: '63505',
    city: 'Hof Eckeberg'
  },
  {
    postalCode: '63505',
    city: 'Langenselbold'
  },
  {
    postalCode: '63512',
    city: 'Hainburg'
  },
  {
    postalCode: '63517',
    city: 'Rodenbach'
  },
  {
    postalCode: '63526',
    city: 'Erlensee'
  },
  {
    postalCode: '63533',
    city: 'Mainhausen'
  },
  {
    postalCode: '63538',
    city: 'Großkrotzenburg'
  },
  {
    postalCode: '63543',
    city: 'Bei den Tongruben'
  },
  {
    postalCode: '63543',
    city: 'Neuberg'
  },
  {
    postalCode: '63546',
    city: 'Hammersbach'
  },
  {
    postalCode: '63549',
    city: 'Ronneburg'
  },
  {
    postalCode: '63571',
    city: 'Gelnhausen'
  },
  {
    postalCode: '63579',
    city: 'Freigericht'
  },
  {
    postalCode: '63584',
    city: 'Gründau'
  },
  {
    postalCode: '63589',
    city: 'Linsengericht'
  },
  {
    postalCode: '63589',
    city: 'Tannengrundhof'
  },
  {
    postalCode: '63589',
    city: 'Weißkirchhof'
  },
  {
    postalCode: '63594',
    city: 'Hasselroth'
  },
  {
    postalCode: '63599',
    city: 'Biebergemünd'
  },
  {
    postalCode: '63607',
    city: 'Wächtersbach'
  },
  {
    postalCode: '63619',
    city: 'Bad Orb'
  },
  {
    postalCode: '63628',
    city: 'Bad Soden-Salmünster'
  },
  {
    postalCode: '63633',
    city: 'Birstein'
  },
  {
    postalCode: '63636',
    city: 'Brachttal'
  },
  {
    postalCode: '63637',
    city: 'Jossgrund'
  },
  {
    postalCode: '63639',
    city: 'Flörsbachtal'
  },
  {
    postalCode: '63654',
    city: 'Büdingen'
  },
  {
    postalCode: '63667',
    city: 'Nidda'
  },
  {
    postalCode: '63674',
    city: 'Altenstadt'
  },
  {
    postalCode: '63679',
    city: 'Schotten'
  },
  {
    postalCode: '63683',
    city: 'Ortenberg'
  },
  {
    postalCode: '63688',
    city: 'Gedern'
  },
  {
    postalCode: '63691',
    city: 'Ranstadt'
  },
  {
    postalCode: '63694',
    city: 'Limeshain'
  },
  {
    postalCode: '63695',
    city: 'Glauburg'
  },
  {
    postalCode: '63697',
    city: 'Hirzenhain'
  },
  {
    postalCode: '63699',
    city: 'Birkenstöcke'
  },
  {
    postalCode: '63699',
    city: 'Hanchesmühle'
  },
  {
    postalCode: '63699',
    city: 'Kefenrod'
  },
  {
    postalCode: '63699',
    city: 'Loosemühle'
  },
  {
    postalCode: '63739',
    city: 'Aschaffenburg'
  },
  {
    postalCode: '63741',
    city: 'Aschaffenburg'
  },
  {
    postalCode: '63743',
    city: 'Aschaffenburg'
  },
  {
    postalCode: '63755',
    city: 'Alzenau'
  },
  {
    postalCode: '63762',
    city: 'Großostheim'
  },
  {
    postalCode: '63768',
    city: 'Hösbach'
  },
  {
    postalCode: '63773',
    city: 'Goldbach'
  },
  {
    postalCode: '63776',
    city: 'Hüttelngesäß'
  },
  {
    postalCode: '63776',
    city: 'Mömbris'
  },
  {
    postalCode: '63785',
    city: 'Obernburg'
  },
  {
    postalCode: '63791',
    city: 'Karlstein'
  },
  {
    postalCode: '63796',
    city: 'Kahl'
  },
  {
    postalCode: '63801',
    city: 'Kleinostheim'
  },
  {
    postalCode: '63808',
    city: 'Haibach'
  },
  {
    postalCode: '63811',
    city: 'Stockstadt'
  },
  {
    postalCode: '63814',
    city: 'Mainaschaff'
  },
  {
    postalCode: '63820',
    city: 'Elsenfeld'
  },
  {
    postalCode: '63825',
    city: 'Blankenbach'
  },
  {
    postalCode: '63825',
    city: 'Schöllkrippen'
  },
  {
    postalCode: '63825',
    city: 'Sommerkahl'
  },
  {
    postalCode: '63825',
    city: 'Westerngrund'
  },
  {
    postalCode: '63826',
    city: 'Geiselbach'
  },
  {
    postalCode: '63828',
    city: 'Kleinkahl'
  },
  {
    postalCode: '63829',
    city: 'Krombach'
  },
  {
    postalCode: '63831',
    city: 'Wiesen'
  },
  {
    postalCode: '63834',
    city: 'Sulzbach'
  },
  {
    postalCode: '63839',
    city: 'Kleinwallstadt'
  },
  {
    postalCode: '63840',
    city: 'Hausen'
  },
  {
    postalCode: '63843',
    city: 'Niedernberg'
  },
  {
    postalCode: '63846',
    city: 'Laufach'
  },
  {
    postalCode: '63846',
    city: 'Weiberhof'
  },
  {
    postalCode: '63849',
    city: 'Leidersbach'
  },
  {
    postalCode: '63853',
    city: 'Mömlingen'
  },
  {
    postalCode: '63856',
    city: 'Bessenbach'
  },
  {
    postalCode: '63857',
    city: 'Waldaschaff'
  },
  {
    postalCode: '63860',
    city: 'Rothenbuch'
  },
  {
    postalCode: '63863',
    city: 'Eschau'
  },
  {
    postalCode: '63863',
    city: 'Hundsrück'
  },
  {
    postalCode: '63864',
    city: 'Glattbach'
  },
  {
    postalCode: '63867',
    city: 'Johannesberg'
  },
  {
    postalCode: '63868',
    city: 'Großwallstadt'
  },
  {
    postalCode: '63869',
    city: 'Heigenbrücken'
  },
  {
    postalCode: '63871',
    city: 'Heinrichsthal'
  },
  {
    postalCode: '63872',
    city: 'Heimbuchenthal'
  },
  {
    postalCode: '63874',
    city: 'Dammbach'
  },
  {
    postalCode: '63874',
    city: 'Höllhammer'
  },
  {
    postalCode: '63875',
    city: 'Mespelbrunn'
  },
  {
    postalCode: '63877',
    city: 'Sailauf'
  },
  {
    postalCode: '63879',
    city: 'Weibersbrunn'
  },
  {
    postalCode: '63897',
    city: 'Miltenberg'
  },
  {
    postalCode: '63906',
    city: 'Erlenbach'
  },
  {
    postalCode: '63911',
    city: 'Klingenberg'
  },
  {
    postalCode: '63916',
    city: 'Amorbach'
  },
  {
    postalCode: '63916',
    city: 'Sansenhof'
  },
  {
    postalCode: '63920',
    city: 'Großheubach'
  },
  {
    postalCode: '63924',
    city: 'Kleinheubach'
  },
  {
    postalCode: '63924',
    city: 'Rüdenau'
  },
  {
    postalCode: '63925',
    city: 'Brunnthal'
  },
  {
    postalCode: '63925',
    city: 'Laudenbach'
  },
  {
    postalCode: '63927',
    city: 'Bürgstadt'
  },
  {
    postalCode: '63928',
    city: 'Eichenbühl'
  },
  {
    postalCode: '63928',
    city: 'Spritzenmühle'
  },
  {
    postalCode: '63930',
    city: 'Neunkirchen'
  },
  {
    postalCode: '63931',
    city: 'Kirchzell'
  },
  {
    postalCode: '63933',
    city: 'Mönchberg'
  },
  {
    postalCode: '63934',
    city: 'Röllbach'
  },
  {
    postalCode: '63936',
    city: 'Schneeberg'
  },
  {
    postalCode: '63937',
    city: 'Weilbach'
  },
  {
    postalCode: '63939',
    city: 'Wörth'
  },
  {
    postalCode: '64283',
    city: 'Darmstadt'
  },
  {
    postalCode: '64285',
    city: 'Darmstadt'
  },
  {
    postalCode: '64287',
    city: 'Darmstadt'
  },
  {
    postalCode: '64289',
    city: 'Darmstadt'
  },
  {
    postalCode: '64291',
    city: 'Darmstadt'
  },
  {
    postalCode: '64293',
    city: 'Darmstadt'
  },
  {
    postalCode: '64295',
    city: 'Darmstadt'
  },
  {
    postalCode: '64297',
    city: 'Darmstadt'
  },
  {
    postalCode: '64319',
    city: 'Pfungstadt'
  },
  {
    postalCode: '64331',
    city: 'Weiterstadt'
  },
  {
    postalCode: '64342',
    city: 'Seeheim-Jugenheim'
  },
  {
    postalCode: '64347',
    city: 'Griesheim'
  },
  {
    postalCode: '64354',
    city: 'Reinheim'
  },
  {
    postalCode: '64367',
    city: 'Mühltal'
  },
  {
    postalCode: '64372',
    city: 'Ober-Ramstadt'
  },
  {
    postalCode: '64380',
    city: 'Roßdorf'
  },
  {
    postalCode: '64385',
    city: 'Gumpener Kreuz'
  },
  {
    postalCode: '64385',
    city: 'Reichelsheim'
  },
  {
    postalCode: '64390',
    city: 'Erzhausen'
  },
  {
    postalCode: '64395',
    city: 'Brensbach'
  },
  {
    postalCode: '64395',
    city: 'Hippelsbach'
  },
  {
    postalCode: '64395',
    city: 'Hundertmorgen'
  },
  {
    postalCode: '64395',
    city: 'Kohlbacher Hof'
  },
  {
    postalCode: '64397',
    city: 'Modautal'
  },
  {
    postalCode: '64401',
    city: 'Groß-Bieberau'
  },
  {
    postalCode: '64404',
    city: 'Bickenbach'
  },
  {
    postalCode: '64405',
    city: 'Fischbachtal'
  },
  {
    postalCode: '64407',
    city: 'Fränkisch-Crumbach'
  },
  {
    postalCode: '64409',
    city: 'Messel'
  },
  {
    postalCode: '64521',
    city: 'Groß-Gerau'
  },
  {
    postalCode: '64546',
    city: 'Mörfelden-Walldorf'
  },
  {
    postalCode: '64560',
    city: 'Riedstadt'
  },
  {
    postalCode: '64569',
    city: 'Nauheim'
  },
  {
    postalCode: '64572',
    city: 'Büttelborn'
  },
  {
    postalCode: '64579',
    city: 'Gernsheim'
  },
  {
    postalCode: '64584',
    city: 'Biebesheim'
  },
  {
    postalCode: '64589',
    city: 'Stockstadt'
  },
  {
    postalCode: '64625',
    city: 'Bensheim'
  },
  {
    postalCode: '64646',
    city: 'Heppenheim'
  },
  {
    postalCode: '64653',
    city: 'Lorsch'
  },
  {
    postalCode: '64658',
    city: 'Faustenbach'
  },
  {
    postalCode: '64658',
    city: 'Fürth'
  },
  {
    postalCode: '64665',
    city: 'Alsbach-Hähnlein'
  },
  {
    postalCode: '64668',
    city: 'Rimbach'
  },
  {
    postalCode: '64673',
    city: 'Zwingenberg'
  },
  {
    postalCode: '64678',
    city: 'Lindenfels'
  },
  {
    postalCode: '64683',
    city: 'Einhausen'
  },
  {
    postalCode: '64686',
    city: 'Lautertal'
  },
  {
    postalCode: '64689',
    city: 'Grasellenbach'
  },
  {
    postalCode: '64711',
    city: 'Erbach'
  },
  {
    postalCode: '64711',
    city: 'Gebhardshütte'
  },
  {
    postalCode: '64720',
    city: 'Michelstadt'
  },
  {
    postalCode: '64720',
    city: 'Schimmelshütte'
  },
  {
    postalCode: '64732',
    city: 'Bad König'
  },
  {
    postalCode: '64739',
    city: 'Höchst'
  },
  {
    postalCode: '64743',
    city: 'Beerfelden'
  },
  {
    postalCode: '64743',
    city: 'Marbach'
  },
  {
    postalCode: '64747',
    city: 'Breuberg'
  },
  {
    postalCode: '64750',
    city: 'Lützelbach'
  },
  {
    postalCode: '64753',
    city: 'Brombachtal'
  },
  {
    postalCode: '64753',
    city: 'Wünschbach'
  },
  {
    postalCode: '64754',
    city: 'Badisch Schöllenbach'
  },
  {
    postalCode: '64754',
    city: 'Hesseneck'
  },
  {
    postalCode: '64756',
    city: 'Mossautal'
  },
  {
    postalCode: '64757',
    city: 'Rothenberg'
  },
  {
    postalCode: '64757',
    city: 'Unter-Hainbrunn'
  },
  {
    postalCode: '64759',
    city: 'Sensbachtal'
  },
  {
    postalCode: '64807',
    city: 'Dieburg'
  },
  {
    postalCode: '64823',
    city: 'Groß-Umstadt'
  },
  {
    postalCode: '64832',
    city: 'Babenhausen'
  },
  {
    postalCode: '64839',
    city: 'Münster'
  },
  {
    postalCode: '64846',
    city: 'Groß-Zimmern'
  },
  {
    postalCode: '64850',
    city: 'Schaafheim'
  },
  {
    postalCode: '64853',
    city: 'Otzberg'
  },
  {
    postalCode: '64859',
    city: 'Eppertshausen'
  },
  {
    postalCode: '65183',
    city: 'Wiesbaden'
  },
  {
    postalCode: '65185',
    city: 'Wiesbaden'
  },
  {
    postalCode: '65187',
    city: 'Wiesbaden'
  },
  {
    postalCode: '65189',
    city: 'Wiesbaden'
  },
  {
    postalCode: '65191',
    city: 'Wiesbaden'
  },
  {
    postalCode: '65193',
    city: 'Wiesbaden'
  },
  {
    postalCode: '65195',
    city: 'Wiesbaden'
  },
  {
    postalCode: '65197',
    city: 'Wiesbaden'
  },
  {
    postalCode: '65199',
    city: 'Wiesbaden'
  },
  {
    postalCode: '65201',
    city: 'Wiesbaden'
  },
  {
    postalCode: '65203',
    city: 'Wiesbaden'
  },
  {
    postalCode: '65205',
    city: 'Wiesbaden'
  },
  {
    postalCode: '65207',
    city: 'Wiesbaden'
  },
  {
    postalCode: '65232',
    city: 'Taunusstein'
  },
  {
    postalCode: '65239',
    city: 'Hochheim'
  },
  {
    postalCode: '65307',
    city: 'Bad Schwalbach'
  },
  {
    postalCode: '65321',
    city: 'Heidenrod'
  },
  {
    postalCode: '65326',
    city: 'Aarbergen'
  },
  {
    postalCode: '65326',
    city: 'Sandersmühle'
  },
  {
    postalCode: '65329',
    city: 'Hohenstein'
  },
  {
    postalCode: '65343',
    city: 'Eltville'
  },
  {
    postalCode: '65344',
    city: 'Eltville'
  },
  {
    postalCode: '65345',
    city: 'Eltville'
  },
  {
    postalCode: '65346',
    city: 'Eltville'
  },
  {
    postalCode: '65347',
    city: 'Eltville'
  },
  {
    postalCode: '65366',
    city: 'Geisenheim'
  },
  {
    postalCode: '65375',
    city: 'Oestrich-Winkel'
  },
  {
    postalCode: '65385',
    city: 'Am Rüdesheimer Hafen'
  },
  {
    postalCode: '65385',
    city: 'Rüdesheim am Rhein'
  },
  {
    postalCode: '65388',
    city: 'Schlangenbad'
  },
  {
    postalCode: '65391',
    city: 'Lorch'
  },
  {
    postalCode: '65391',
    city: 'Sauerthal'
  },
  {
    postalCode: '65396',
    city: 'Walluf'
  },
  {
    postalCode: '65399',
    city: 'Kiedrich'
  },
  {
    postalCode: '65428',
    city: 'Rüsselsheim'
  },
  {
    postalCode: '65439',
    city: 'Flörsheim'
  },
  {
    postalCode: '65451',
    city: 'Kelsterbach'
  },
  {
    postalCode: '65462',
    city: 'Ginsheim-Gustavsburg'
  },
  {
    postalCode: '65468',
    city: 'Trebur'
  },
  {
    postalCode: '65474',
    city: 'Bischofsheim'
  },
  {
    postalCode: '65479',
    city: 'Raunheim'
  },
  {
    postalCode: '65510',
    city: 'Hasenmühle'
  },
  {
    postalCode: '65510',
    city: 'Hühnerkirche'
  },
  {
    postalCode: '65510',
    city: 'Hünstetten'
  },
  {
    postalCode: '65510',
    city: 'Idstein'
  },
  {
    postalCode: '65520',
    city: 'Bad Camberg'
  },
  {
    postalCode: '65527',
    city: 'Niedernhausen'
  },
  {
    postalCode: '65529',
    city: 'Waldems'
  },
  {
    postalCode: '65549',
    city: 'Limburg'
  },
  {
    postalCode: '65550',
    city: 'Limburg'
  },
  {
    postalCode: '65551',
    city: 'Limburg'
  },
  {
    postalCode: '65552',
    city: 'Limburg'
  },
  {
    postalCode: '65553',
    city: 'Limburg'
  },
  {
    postalCode: '65554',
    city: 'Limburg'
  },
  {
    postalCode: '65555',
    city: 'Limburg'
  },
  {
    postalCode: '65556',
    city: 'Limburg'
  },
  {
    postalCode: '65558',
    city: 'Balduinstein'
  },
  {
    postalCode: '65558',
    city: 'Burgschwalbach'
  },
  {
    postalCode: '65558',
    city: 'Cramberg'
  },
  {
    postalCode: '65558',
    city: 'Eppenrod'
  },
  {
    postalCode: '65558',
    city: 'Flacht'
  },
  {
    postalCode: '65558',
    city: 'Gückingen'
  },
  {
    postalCode: '65558',
    city: 'Heistenbach'
  },
  {
    postalCode: '65558',
    city: 'Hirschberg'
  },
  {
    postalCode: '65558',
    city: 'Holzheim'
  },
  {
    postalCode: '65558',
    city: 'Isselbach'
  },
  {
    postalCode: '65558',
    city: 'Kaltenholzhausen'
  },
  {
    postalCode: '65558',
    city: 'Langenscheid'
  },
  {
    postalCode: '65558',
    city: 'Lohrheim'
  },
  {
    postalCode: '65558',
    city: 'Oberneisen'
  },
  {
    postalCode: '65582',
    city: 'Aull'
  },
  {
    postalCode: '65582',
    city: 'Diez'
  },
  {
    postalCode: '65582',
    city: 'Hambach'
  },
  {
    postalCode: '65589',
    city: 'Hadamar'
  },
  {
    postalCode: '65594',
    city: 'Runkel'
  },
  {
    postalCode: '65597',
    city: 'Hünfelden'
  },
  {
    postalCode: '65599',
    city: 'Dornburg'
  },
  {
    postalCode: '65604',
    city: 'Elz'
  },
  {
    postalCode: '65606',
    city: 'Villmar'
  },
  {
    postalCode: '65611',
    city: 'Brechen'
  },
  {
    postalCode: '65614',
    city: 'Beselich'
  },
  {
    postalCode: '65618',
    city: 'Selters'
  },
  {
    postalCode: '65620',
    city: 'Waldbrunn'
  },
  {
    postalCode: '65623',
    city: 'Hahnstätten'
  },
  {
    postalCode: '65623',
    city: 'Mudershausen'
  },
  {
    postalCode: '65623',
    city: 'Netzbach'
  },
  {
    postalCode: '65623',
    city: 'Schiesheim'
  },
  {
    postalCode: '65624',
    city: 'Altendiez'
  },
  {
    postalCode: '65626',
    city: 'Birlenbach'
  },
  {
    postalCode: '65626',
    city: 'Fachingen'
  },
  {
    postalCode: '65627',
    city: 'Elbtal'
  },
  {
    postalCode: '65629',
    city: 'Niederneisen'
  },
  {
    postalCode: '65719',
    city: 'Hofheim'
  },
  {
    postalCode: '65760',
    city: 'Eschborn'
  },
  {
    postalCode: '65779',
    city: 'Kelkheim'
  },
  {
    postalCode: '65795',
    city: 'Hattersheim'
  },
  {
    postalCode: '65812',
    city: 'Bad Soden'
  },
  {
    postalCode: '65817',
    city: 'Eppstein'
  },
  {
    postalCode: '65824',
    city: 'Schwalbach'
  },
  {
    postalCode: '65830',
    city: 'Kriftel'
  },
  {
    postalCode: '65835',
    city: 'Liederbach'
  },
  {
    postalCode: '65843',
    city: 'Sulzbach'
  },
  {
    postalCode: '65929',
    city: 'Frankfurt'
  },
  {
    postalCode: '65931',
    city: 'Frankfurt'
  },
  {
    postalCode: '65933',
    city: 'Frankfurt'
  },
  {
    postalCode: '65934',
    city: 'Frankfurt'
  },
  {
    postalCode: '65936',
    city: 'Frankfurt'
  },
  {
    postalCode: '66111',
    city: 'Saarbrücken'
  },
  {
    postalCode: '66113',
    city: 'Saarbrücken'
  },
  {
    postalCode: '66115',
    city: 'Saarbrücken'
  },
  {
    postalCode: '66117',
    city: 'Saarbrücken'
  },
  {
    postalCode: '66119',
    city: 'Saarbrücken'
  },
  {
    postalCode: '66121',
    city: 'Saarbrücken'
  },
  {
    postalCode: '66123',
    city: 'Saarbrücken'
  },
  {
    postalCode: '66125',
    city: 'Saarbrücken'
  },
  {
    postalCode: '66126',
    city: 'Saarbrücken'
  },
  {
    postalCode: '66127',
    city: 'Saarbrücken'
  },
  {
    postalCode: '66128',
    city: 'Saarbrücken'
  },
  {
    postalCode: '66129',
    city: 'Saarbrücken'
  },
  {
    postalCode: '66130',
    city: 'Saarbrücken'
  },
  {
    postalCode: '66131',
    city: 'Saarbrücken'
  },
  {
    postalCode: '66132',
    city: 'Saarbrücken'
  },
  {
    postalCode: '66133',
    city: 'Saarbrücken'
  },
  {
    postalCode: '66265',
    city: 'Heusweiler'
  },
  {
    postalCode: '66271',
    city: 'Kleinblittersdorf'
  },
  {
    postalCode: '66280',
    city: 'Sulzbach'
  },
  {
    postalCode: '66287',
    city: 'Quierschied'
  },
  {
    postalCode: '66292',
    city: 'Riegelsberg'
  },
  {
    postalCode: '66299',
    city: 'Friedrichsthal'
  },
  {
    postalCode: '66333',
    city: 'Völklingen'
  },
  {
    postalCode: '66346',
    city: 'Püttlingen'
  },
  {
    postalCode: '66352',
    city: 'Großrosseln'
  },
  {
    postalCode: '66359',
    city: 'Bous'
  },
  {
    postalCode: '66386',
    city: 'St. Ingbert'
  },
  {
    postalCode: '66399',
    city: 'Mandelbachtal'
  },
  {
    postalCode: '66424',
    city: 'Homburg'
  },
  {
    postalCode: '66440',
    city: 'Blieskastel'
  },
  {
    postalCode: '66450',
    city: 'Bexbach'
  },
  {
    postalCode: '66453',
    city: 'Gersheim'
  },
  {
    postalCode: '66459',
    city: 'Kirkel'
  },
  {
    postalCode: '66482',
    city: 'Faustermühle'
  },
  {
    postalCode: '66482',
    city: 'Hirschhof'
  },
  {
    postalCode: '66482',
    city: 'Hitscherhof'
  },
  {
    postalCode: '66482',
    city: 'Kirschbacherhof'
  },
  {
    postalCode: '66482',
    city: 'Kirschbachermühle'
  },
  {
    postalCode: '66482',
    city: 'Pirmannsteinerhof'
  },
  {
    postalCode: '66482',
    city: 'Riedelberg-Tal'
  },
  {
    postalCode: '66482',
    city: 'Stausteinerhof'
  },
  {
    postalCode: '66482',
    city: 'Zweibrücken'
  },
  {
    postalCode: '66484',
    city: 'Althornbach'
  },
  {
    postalCode: '66484',
    city: 'Battweiler'
  },
  {
    postalCode: '66484',
    city: 'Dietrichingen'
  },
  {
    postalCode: '66484',
    city: 'Großsteinhausen'
  },
  {
    postalCode: '66484',
    city: 'Kleinsteinhausen'
  },
  {
    postalCode: '66484',
    city: 'Riedelberg'
  },
  {
    postalCode: '66484',
    city: 'Schmitshausen'
  },
  {
    postalCode: '66484',
    city: 'Stockbornerhof'
  },
  {
    postalCode: '66484',
    city: 'Walshausen'
  },
  {
    postalCode: '66484',
    city: 'Winterbach'
  },
  {
    postalCode: '66497',
    city: 'Contwig'
  },
  {
    postalCode: '66500',
    city: 'Bödingerhof'
  },
  {
    postalCode: '66500',
    city: 'Buchholzbergerhof'
  },
  {
    postalCode: '66500',
    city: 'Hornbach'
  },
  {
    postalCode: '66500',
    city: 'Hübelhof'
  },
  {
    postalCode: '66500',
    city: 'Mauschbach'
  },
  {
    postalCode: '66501',
    city: 'Großbundenbach'
  },
  {
    postalCode: '66501',
    city: 'Kleinbundenbach'
  },
  {
    postalCode: '66503',
    city: 'Dellfeld'
  },
  {
    postalCode: '66503',
    city: 'Ziegelhütte'
  },
  {
    postalCode: '66504',
    city: 'Bottenbach'
  },
  {
    postalCode: '66506',
    city: 'Maßweiler'
  },
  {
    postalCode: '66507',
    city: 'Reifenberg'
  },
  {
    postalCode: '66509',
    city: 'Rieschweiler-Mühlbach'
  },
  {
    postalCode: '66538',
    city: 'Neunkirchen'
  },
  {
    postalCode: '66539',
    city: 'Neunkirchen'
  },
  {
    postalCode: '66540',
    city: 'Neunkirchen'
  },
  {
    postalCode: '66557',
    city: 'Illingen'
  },
  {
    postalCode: '66564',
    city: 'Ottweiler'
  },
  {
    postalCode: '66571',
    city: 'Eppelborn'
  },
  {
    postalCode: '66578',
    city: 'Schiffweiler'
  },
  {
    postalCode: '66583',
    city: 'Spiesen-Elversberg'
  },
  {
    postalCode: '66589',
    city: 'Merchweiler'
  },
  {
    postalCode: '66606',
    city: 'St. Wendel'
  },
  {
    postalCode: '66620',
    city: 'Nonnweiler'
  },
  {
    postalCode: '66625',
    city: 'Nohfelden'
  },
  {
    postalCode: '66629',
    city: 'Freisen'
  },
  {
    postalCode: '66636',
    city: 'Tholey'
  },
  {
    postalCode: '66640',
    city: 'Namborn'
  },
  {
    postalCode: '66646',
    city: 'Marpingen'
  },
  {
    postalCode: '66649',
    city: 'Oberthal'
  },
  {
    postalCode: '66663',
    city: 'Merzig'
  },
  {
    postalCode: '66679',
    city: 'Losheim'
  },
  {
    postalCode: '66687',
    city: 'Wadern'
  },
  {
    postalCode: '66693',
    city: 'Mettlach'
  },
  {
    postalCode: '66701',
    city: 'Beckingen'
  },
  {
    postalCode: '66706',
    city: 'Perl'
  },
  {
    postalCode: '66709',
    city: 'Weiskirchen'
  },
  {
    postalCode: '66740',
    city: 'Saarlouis'
  },
  {
    postalCode: '66763',
    city: 'Dillingen'
  },
  {
    postalCode: '66773',
    city: 'Schwalbach'
  },
  {
    postalCode: '66780',
    city: 'Rehlingen-Siersburg'
  },
  {
    postalCode: '66787',
    city: 'Wadgassen'
  },
  {
    postalCode: '66793',
    city: 'Saarwellingen'
  },
  {
    postalCode: '66798',
    city: 'Wallerfangen'
  },
  {
    postalCode: '66802',
    city: 'Überherrn'
  },
  {
    postalCode: '66806',
    city: 'Ensdorf'
  },
  {
    postalCode: '66809',
    city: 'Nalbach'
  },
  {
    postalCode: '66822',
    city: 'Lebach'
  },
  {
    postalCode: '66839',
    city: 'Schmelz'
  },
  {
    postalCode: '66849',
    city: 'Am Sandweiher'
  },
  {
    postalCode: '66849',
    city: 'Artamhof'
  },
  {
    postalCode: '66849',
    city: 'Landstuhl'
  },
  {
    postalCode: '66851',
    city: 'Bann'
  },
  {
    postalCode: '66851',
    city: 'Erkelshäuserhof'
  },
  {
    postalCode: '66851',
    city: 'Hauptstuhl'
  },
  {
    postalCode: '66851',
    city: 'Horbach'
  },
  {
    postalCode: '66851',
    city: 'Königreich'
  },
  {
    postalCode: '66851',
    city: 'Linden'
  },
  {
    postalCode: '66851',
    city: 'Mittelbrunn'
  },
  {
    postalCode: '66851',
    city: 'Oberarnbach'
  },
  {
    postalCode: '66851',
    city: 'Olenkorb'
  },
  {
    postalCode: '66851',
    city: 'Queidersbach'
  },
  {
    postalCode: '66851',
    city: 'Scharrhof'
  },
  {
    postalCode: '66851',
    city: 'Scharrmühle'
  },
  {
    postalCode: '66851',
    city: 'Schwanenmühle'
  },
  {
    postalCode: '66851',
    city: 'Steinalben'
  },
  {
    postalCode: '66862',
    city: 'Kindsbach'
  },
  {
    postalCode: '66869',
    city: 'Blaubach'
  },
  {
    postalCode: '66869',
    city: 'Kusel'
  },
  {
    postalCode: '66869',
    city: 'Ruthweiler'
  },
  {
    postalCode: '66869',
    city: 'Schellweiler'
  },
  {
    postalCode: '66871',
    city: 'Albessen'
  },
  {
    postalCode: '66871',
    city: 'Dennweiler-Frohnbach'
  },
  {
    postalCode: '66871',
    city: 'Ehweiler'
  },
  {
    postalCode: '66871',
    city: 'Etschberg'
  },
  {
    postalCode: '66871',
    city: 'Haschbach'
  },
  {
    postalCode: '66871',
    city: 'Herchweiler'
  },
  {
    postalCode: '66871',
    city: 'Konken'
  },
  {
    postalCode: '66871',
    city: 'Körborn'
  },
  {
    postalCode: '66871',
    city: 'Oberalben'
  },
  {
    postalCode: '66871',
    city: 'Pfeffelbach'
  },
  {
    postalCode: '66871',
    city: 'Reichweiler'
  },
  {
    postalCode: '66871',
    city: 'Selchenbach'
  },
  {
    postalCode: '66871',
    city: 'Thallichtenberg'
  },
  {
    postalCode: '66871',
    city: 'Theisbergstegen'
  },
  {
    postalCode: '66877',
    city: 'Ramstein-Miesenbach'
  },
  {
    postalCode: '66879',
    city: 'Kollweiler'
  },
  {
    postalCode: '66879',
    city: 'Kottweiler-Schwanden'
  },
  {
    postalCode: '66879',
    city: 'Niedermohr'
  },
  {
    postalCode: '66879',
    city: 'Niederstaufenbach'
  },
  {
    postalCode: '66879',
    city: 'Oberstaufenbach'
  },
  {
    postalCode: '66879',
    city: 'Reichenbach-Steegen'
  },
  {
    postalCode: '66879',
    city: 'Steinwenden'
  },
  {
    postalCode: '66882',
    city: 'Hütschenhausen'
  },
  {
    postalCode: '66882',
    city: 'Schanzerhof'
  },
  {
    postalCode: '66882',
    city: 'Schanzermühle'
  },
  {
    postalCode: '66885',
    city: 'Altenglan'
  },
  {
    postalCode: '66885',
    city: 'Bedesbach'
  },
  {
    postalCode: '66885',
    city: 'Sulzbacherhof'
  },
  {
    postalCode: '66887',
    city: 'Bosenbach'
  },
  {
    postalCode: '66887',
    city: 'Elzweiler'
  },
  {
    postalCode: '66887',
    city: 'Erdesbach'
  },
  {
    postalCode: '66887',
    city: 'Föckelberg'
  },
  {
    postalCode: '66887',
    city: 'Glanbrücken'
  },
  {
    postalCode: '66887',
    city: 'Horschbach'
  },
  {
    postalCode: '66887',
    city: 'Jettenbach'
  },
  {
    postalCode: '66887',
    city: 'Neunkirchen'
  },
  {
    postalCode: '66887',
    city: 'Niederalben'
  },
  {
    postalCode: '66887',
    city: 'Rammelsbach'
  },
  {
    postalCode: '66887',
    city: 'Rathsweiler'
  },
  {
    postalCode: '66887',
    city: 'Rathsweiler Mühle'
  },
  {
    postalCode: '66887',
    city: 'Rutsweiler'
  },
  {
    postalCode: '66887',
    city: 'Sankt Julian'
  },
  {
    postalCode: '66887',
    city: 'Ulmet'
  },
  {
    postalCode: '66887',
    city: 'Welchweiler'
  },
  {
    postalCode: '66892',
    city: 'Bruchmühlbach-Miesau'
  },
  {
    postalCode: '66892',
    city: 'Elschbacherhof'
  },
  {
    postalCode: '66894',
    city: 'Bechhofen'
  },
  {
    postalCode: '66894',
    city: 'Etzenbachermühle'
  },
  {
    postalCode: '66894',
    city: 'Gerhardsbrunn'
  },
  {
    postalCode: '66894',
    city: 'Käshofen'
  },
  {
    postalCode: '66894',
    city: 'Krähenberg'
  },
  {
    postalCode: '66894',
    city: 'Lambsborn'
  },
  {
    postalCode: '66894',
    city: 'Langwieden'
  },
  {
    postalCode: '66894',
    city: 'Martinshöhe'
  },
  {
    postalCode: '66894',
    city: 'Rosenkopf'
  },
  {
    postalCode: '66894',
    city: 'Wiesbach'
  },
  {
    postalCode: '66901',
    city: 'Schönenberg-Kübelberg'
  },
  {
    postalCode: '66903',
    city: 'Altenkirchen'
  },
  {
    postalCode: '66903',
    city: 'Dittweiler'
  },
  {
    postalCode: '66903',
    city: 'Frohnhofen'
  },
  {
    postalCode: '66903',
    city: 'Gries'
  },
  {
    postalCode: '66903',
    city: 'Lindenhof'
  },
  {
    postalCode: '66903',
    city: 'Ohmbach'
  },
  {
    postalCode: '66903',
    city: 'Reismühle'
  },
  {
    postalCode: '66904',
    city: 'Börsborn'
  },
  {
    postalCode: '66904',
    city: 'Brücken'
  },
  {
    postalCode: '66904',
    city: 'Haseldell'
  },
  {
    postalCode: '66904',
    city: 'Lebecksmühle'
  },
  {
    postalCode: '66907',
    city: 'Glan-Münchweiler'
  },
  {
    postalCode: '66907',
    city: 'Rehweiler'
  },
  {
    postalCode: '66909',
    city: 'Henschtal'
  },
  {
    postalCode: '66909',
    city: 'Herschweiler-Pettersheim'
  },
  {
    postalCode: '66909',
    city: 'Hüffler'
  },
  {
    postalCode: '66909',
    city: 'Krottelbach'
  },
  {
    postalCode: '66909',
    city: 'Langenbach'
  },
  {
    postalCode: '66909',
    city: 'Matzenbach'
  },
  {
    postalCode: '66909',
    city: 'Nanzdietschweiler'
  },
  {
    postalCode: '66909',
    city: 'Quirnbach'
  },
  {
    postalCode: '66909',
    city: 'Steinbach'
  },
  {
    postalCode: '66909',
    city: 'Wahnwegen'
  },
  {
    postalCode: '66914',
    city: 'Waldmohr'
  },
  {
    postalCode: '66916',
    city: 'Breitenbach'
  },
  {
    postalCode: '66916',
    city: 'Dunzweiler'
  },
  {
    postalCode: '66916',
    city: 'Hainhof'
  },
  {
    postalCode: '66917',
    city: 'Biedershausen'
  },
  {
    postalCode: '66917',
    city: 'Erlenmühle'
  },
  {
    postalCode: '66917',
    city: 'Hellbornerhof'
  },
  {
    postalCode: '66917',
    city: 'Kneispermühle'
  },
  {
    postalCode: '66917',
    city: 'Knoppermühle'
  },
  {
    postalCode: '66917',
    city: 'Knopp-Labach'
  },
  {
    postalCode: '66917',
    city: 'Konradsmühle'
  },
  {
    postalCode: '66917',
    city: 'Seitershof'
  },
  {
    postalCode: '66917',
    city: 'Wallhalben'
  },
  {
    postalCode: '66917',
    city: 'Würschhauserhof'
  },
  {
    postalCode: '66917',
    city: 'Würschhausermühle'
  },
  {
    postalCode: '66919',
    city: 'Hermersberg'
  },
  {
    postalCode: '66919',
    city: 'Herschberg'
  },
  {
    postalCode: '66919',
    city: 'Hettenhausen'
  },
  {
    postalCode: '66919',
    city: 'Obernheim-Kirchenarnbach'
  },
  {
    postalCode: '66919',
    city: 'Saalstadt'
  },
  {
    postalCode: '66919',
    city: 'Schauerberg'
  },
  {
    postalCode: '66919',
    city: 'Weselberg'
  },
  {
    postalCode: '66953',
    city: 'Pirmasens'
  },
  {
    postalCode: '66954',
    city: 'Pirmasens'
  },
  {
    postalCode: '66954',
    city: 'Schelertal'
  },
  {
    postalCode: '66955',
    city: 'Beckenhof'
  },
  {
    postalCode: '66955',
    city: 'Pirmasens'
  },
  {
    postalCode: '66957',
    city: 'Eppenbrunn'
  },
  {
    postalCode: '66957',
    city: 'Hilst'
  },
  {
    postalCode: '66957',
    city: 'Kröppen'
  },
  {
    postalCode: '66957',
    city: 'Obersimten'
  },
  {
    postalCode: '66957',
    city: 'Ruppertsweiler'
  },
  {
    postalCode: '66957',
    city: 'Schweix'
  },
  {
    postalCode: '66957',
    city: 'Trulben'
  },
  {
    postalCode: '66957',
    city: 'Vinningen'
  },
  {
    postalCode: '66969',
    city: 'Lemberg'
  },
  {
    postalCode: '66976',
    city: 'Rodalben'
  },
  {
    postalCode: '66978',
    city: 'Clausen'
  },
  {
    postalCode: '66978',
    city: 'Donsieders'
  },
  {
    postalCode: '66978',
    city: 'Leimen'
  },
  {
    postalCode: '66978',
    city: 'Merzalben'
  },
  {
    postalCode: '66981',
    city: 'Münchweiler'
  },
  {
    postalCode: '66981',
    city: 'Ständehof'
  },
  {
    postalCode: '66987',
    city: 'Thaleischweiler-Fröschen'
  },
  {
    postalCode: '66987',
    city: 'Weihermühle'
  },
  {
    postalCode: '66989',
    city: 'Dusenbrücken'
  },
  {
    postalCode: '66989',
    city: 'Höheinöd'
  },
  {
    postalCode: '66989',
    city: 'Höheischweiler'
  },
  {
    postalCode: '66989',
    city: 'Höhfröschen'
  },
  {
    postalCode: '66989',
    city: 'Huberhof'
  },
  {
    postalCode: '66989',
    city: 'Nünschweiler'
  },
  {
    postalCode: '66989',
    city: 'Petersberg'
  },
  {
    postalCode: '66994',
    city: 'Dahn'
  },
  {
    postalCode: '66996',
    city: 'Bärenbrunnerhof'
  },
  {
    postalCode: '66996',
    city: 'Bärenbrunnermühle'
  },
  {
    postalCode: '66996',
    city: 'Biehlerhof'
  },
  {
    postalCode: '66996',
    city: 'Erfweiler'
  },
  {
    postalCode: '66996',
    city: 'Fischbach'
  },
  {
    postalCode: '66996',
    city: 'Hirschthal'
  },
  {
    postalCode: '66996',
    city: 'Ludwigswinkel'
  },
  {
    postalCode: '66996',
    city: 'Schindhard'
  },
  {
    postalCode: '66996',
    city: 'Schönau'
  },
  {
    postalCode: '66999',
    city: 'Hinterweidenthal'
  },
  {
    postalCode: '66999',
    city: 'Wieslauterhof'
  },
  {
    postalCode: '67059',
    city: 'Ludwigshafen'
  },
  {
    postalCode: '67061',
    city: 'Ludwigshafen'
  },
  {
    postalCode: '67063',
    city: 'Ludwigshafen'
  },
  {
    postalCode: '67065',
    city: 'Ludwigshafen'
  },
  {
    postalCode: '67067',
    city: 'Ludwigshafen'
  },
  {
    postalCode: '67069',
    city: 'Ludwigshafen'
  },
  {
    postalCode: '67071',
    city: 'Ludwigshafen'
  },
  {
    postalCode: '67098',
    city: 'Annaberg'
  },
  {
    postalCode: '67098',
    city: 'Bad Dürkheim'
  },
  {
    postalCode: '67098',
    city: 'Lindemannsruhe'
  },
  {
    postalCode: '67098',
    city: 'Mundhardterhof'
  },
  {
    postalCode: '67105',
    city: 'Schifferstadt'
  },
  {
    postalCode: '67112',
    city: 'Mutterstadt'
  },
  {
    postalCode: '67117',
    city: 'Limburgerhof'
  },
  {
    postalCode: '67122',
    city: 'Altrip'
  },
  {
    postalCode: '67125',
    city: 'Dannstadt-Schauernheim'
  },
  {
    postalCode: '67126',
    city: 'Hochdorf-Assenheim'
  },
  {
    postalCode: '67127',
    city: 'Rödersheim-Gronau'
  },
  {
    postalCode: '67133',
    city: 'Maxdorf'
  },
  {
    postalCode: '67134',
    city: 'Birkenheide'
  },
  {
    postalCode: '67136',
    city: 'Fußgönheim'
  },
  {
    postalCode: '67141',
    city: 'Neuhofen'
  },
  {
    postalCode: '67146',
    city: 'Deidesheim'
  },
  {
    postalCode: '67147',
    city: 'Forst'
  },
  {
    postalCode: '67149',
    city: 'Meckenheim'
  },
  {
    postalCode: '67150',
    city: 'Niederkirchen'
  },
  {
    postalCode: '67152',
    city: 'Ruppertsberg'
  },
  {
    postalCode: '67157',
    city: 'Silbertal'
  },
  {
    postalCode: '67157',
    city: 'Wachenheim'
  },
  {
    postalCode: '67158',
    city: 'Ellerstadt'
  },
  {
    postalCode: '67159',
    city: 'Friedelsheim'
  },
  {
    postalCode: '67161',
    city: 'Gönnheim'
  },
  {
    postalCode: '67165',
    city: 'Waldsee'
  },
  {
    postalCode: '67166',
    city: 'Otterstadt'
  },
  {
    postalCode: '67166',
    city: 'Reffenthal'
  },
  {
    postalCode: '67167',
    city: 'Erpolzheim'
  },
  {
    postalCode: '67169',
    city: 'Kallstadt'
  },
  {
    postalCode: '67227',
    city: 'Frankenthal'
  },
  {
    postalCode: '67229',
    city: 'Gerolsheim'
  },
  {
    postalCode: '67229',
    city: 'Großkarlbach'
  },
  {
    postalCode: '67229',
    city: 'Laumersheim'
  },
  {
    postalCode: '67240',
    city: 'Bobenheim-Roxheim'
  },
  {
    postalCode: '67245',
    city: 'Lambsheim'
  },
  {
    postalCode: '67246',
    city: 'Dirmstein'
  },
  {
    postalCode: '67251',
    city: 'Freinsheim'
  },
  {
    postalCode: '67256',
    city: 'Weisenheim am Sand'
  },
  {
    postalCode: '67258',
    city: 'Heßheim'
  },
  {
    postalCode: '67259',
    city: 'Beindersheim'
  },
  {
    postalCode: '67259',
    city: 'Großniedesheim'
  },
  {
    postalCode: '67259',
    city: 'Heuchelheim'
  },
  {
    postalCode: '67259',
    city: 'Kleinniedesheim'
  },
  {
    postalCode: '67269',
    city: 'Grünstadt'
  },
  {
    postalCode: '67271',
    city: 'Battenberg'
  },
  {
    postalCode: '67271',
    city: 'Kindenheim'
  },
  {
    postalCode: '67271',
    city: 'Kleinkarlbach'
  },
  {
    postalCode: '67271',
    city: 'Mertesheim'
  },
  {
    postalCode: '67271',
    city: 'Neuleiningen'
  },
  {
    postalCode: '67271',
    city: 'Neuleiningen-Tal'
  },
  {
    postalCode: '67271',
    city: 'Obersülzen'
  },
  {
    postalCode: '67273',
    city: 'Bobenheim am Berg'
  },
  {
    postalCode: '67273',
    city: 'Dackenheim'
  },
  {
    postalCode: '67273',
    city: 'Herxheim am Berg'
  },
  {
    postalCode: '67273',
    city: 'Weisenheim am Berg'
  },
  {
    postalCode: '67278',
    city: 'Bockenheim'
  },
  {
    postalCode: '67280',
    city: 'Ebertsheim'
  },
  {
    postalCode: '67280',
    city: 'Quirnheim'
  },
  {
    postalCode: '67281',
    city: 'Bissersheim'
  },
  {
    postalCode: '67281',
    city: 'Bruchmühle'
  },
  {
    postalCode: '67281',
    city: 'Kirchheim'
  },
  {
    postalCode: '67283',
    city: 'Obrigheim'
  },
  {
    postalCode: '67292',
    city: 'Bolanderhof'
  },
  {
    postalCode: '67292',
    city: 'Heubergerhof'
  },
  {
    postalCode: '67292',
    city: 'Kirchheimbolanden'
  },
  {
    postalCode: '67292',
    city: 'Leithof'
  },
  {
    postalCode: '67292',
    city: 'Oberthierwasen'
  },
  {
    postalCode: '67292',
    city: 'Pulvermühle'
  },
  {
    postalCode: '67292',
    city: 'Unterthierwasen'
  },
  {
    postalCode: '67294',
    city: 'Bischheim'
  },
  {
    postalCode: '67294',
    city: 'Gauersheim'
  },
  {
    postalCode: '67294',
    city: 'Hessenhütte'
  },
  {
    postalCode: '67294',
    city: 'Ilbesheim'
  },
  {
    postalCode: '67294',
    city: 'Josefsmühle'
  },
  {
    postalCode: '67294',
    city: 'Mauchenheim'
  },
  {
    postalCode: '67294',
    city: 'Morschheim'
  },
  {
    postalCode: '67294',
    city: 'Oberwiesen'
  },
  {
    postalCode: '67294',
    city: 'Orbis'
  },
  {
    postalCode: '67294',
    city: 'Pfalzfeld'
  },
  {
    postalCode: '67294',
    city: 'Rittersheim'
  },
  {
    postalCode: '67294',
    city: 'Stetten'
  },
  {
    postalCode: '67294',
    city: 'Steuerwaldsmühle'
  },
  {
    postalCode: '67295',
    city: 'Bolanden'
  },
  {
    postalCode: '67297',
    city: 'Heyerhof'
  },
  {
    postalCode: '67297',
    city: 'Marnheim'
  },
  {
    postalCode: '67304',
    city: 'Eisenberg'
  },
  {
    postalCode: '67304',
    city: 'Kerzenheim'
  },
  {
    postalCode: '67305',
    city: 'Ochsenbusch'
  },
  {
    postalCode: '67305',
    city: 'Ramsen'
  },
  {
    postalCode: '67307',
    city: 'Göllheim'
  },
  {
    postalCode: '67308',
    city: 'Albisheim'
  },
  {
    postalCode: '67308',
    city: 'Biedesheim'
  },
  {
    postalCode: '67308',
    city: 'Bubenheim'
  },
  {
    postalCode: '67308',
    city: 'Einselthum'
  },
  {
    postalCode: '67308',
    city: 'Immesheim'
  },
  {
    postalCode: '67308',
    city: 'Kleinmühle'
  },
  {
    postalCode: '67308',
    city: 'Lautersheim'
  },
  {
    postalCode: '67308',
    city: 'Ottersheim'
  },
  {
    postalCode: '67308',
    city: 'Rüssingen'
  },
  {
    postalCode: '67308',
    city: 'Wiesenmühle'
  },
  {
    postalCode: '67308',
    city: 'Zellertal'
  },
  {
    postalCode: '67310',
    city: 'Hettenleidelheim'
  },
  {
    postalCode: '67311',
    city: 'Nackterhof'
  },
  {
    postalCode: '67311',
    city: 'Tiefenthal'
  },
  {
    postalCode: '67316',
    city: 'Carlsberg'
  },
  {
    postalCode: '67316',
    city: 'Junghof'
  },
  {
    postalCode: '67316',
    city: 'Neuhof'
  },
  {
    postalCode: '67316',
    city: 'Neuwoog'
  },
  {
    postalCode: '67317',
    city: 'Altleiningen'
  },
  {
    postalCode: '67317',
    city: 'Maihof'
  },
  {
    postalCode: '67317',
    city: 'Nikolaushof'
  },
  {
    postalCode: '67317',
    city: 'Süßenhof'
  },
  {
    postalCode: '67319',
    city: 'Lauberhof'
  },
  {
    postalCode: '67319',
    city: 'Wattenheim'
  },
  {
    postalCode: '67346',
    city: 'Angelhof I u. II'
  },
  {
    postalCode: '67346',
    city: 'Speyer'
  },
  {
    postalCode: '67354',
    city: 'Römerberg'
  },
  {
    postalCode: '67360',
    city: 'Lingenfeld'
  },
  {
    postalCode: '67361',
    city: 'Freisbach'
  },
  {
    postalCode: '67363',
    city: 'Lustadt'
  },
  {
    postalCode: '67365',
    city: 'Schwegenheim'
  },
  {
    postalCode: '67366',
    city: 'Weingarten'
  },
  {
    postalCode: '67368',
    city: 'Westheim'
  },
  {
    postalCode: '67373',
    city: 'Dudenhofen'
  },
  {
    postalCode: '67374',
    city: 'Hanhofen'
  },
  {
    postalCode: '67376',
    city: 'Harthausen'
  },
  {
    postalCode: '67377',
    city: 'Gommersheim'
  },
  {
    postalCode: '67378',
    city: 'Zeiskam'
  },
  {
    postalCode: '67433',
    city: 'Neustadt'
  },
  {
    postalCode: '67434',
    city: 'Neustadt'
  },
  {
    postalCode: '67435',
    city: 'Benjental'
  },
  {
    postalCode: '67435',
    city: 'Looganlage'
  },
  {
    postalCode: '67435',
    city: 'Neustadt'
  },
  {
    postalCode: '67454',
    city: 'Haßloch'
  },
  {
    postalCode: '67459',
    city: 'Böhl-Iggelheim'
  },
  {
    postalCode: '67466',
    city: 'Breitenstein'
  },
  {
    postalCode: '67466',
    city: 'Erfenstein'
  },
  {
    postalCode: '67466',
    city: 'Krankenthal'
  },
  {
    postalCode: '67466',
    city: 'Lambrecht'
  },
  {
    postalCode: '67466',
    city: 'Luhrbach'
  },
  {
    postalCode: '67466',
    city: 'Neu-Maschine'
  },
  {
    postalCode: '67466',
    city: 'Nonnental'
  },
  {
    postalCode: '67468',
    city: 'Erlenbacher Forsthaus'
  },
  {
    postalCode: '67468',
    city: 'Frankeneck'
  },
  {
    postalCode: '67468',
    city: 'Frankenstein'
  },
  {
    postalCode: '67468',
    city: 'Lambertskreuz'
  },
  {
    postalCode: '67468',
    city: 'Neidenfels'
  },
  {
    postalCode: '67468',
    city: 'Sattelmühle'
  },
  {
    postalCode: '67471',
    city: 'Elmstein'
  },
  {
    postalCode: '67472',
    city: 'Esthal'
  },
  {
    postalCode: '67473',
    city: 'Lindenberg'
  },
  {
    postalCode: '67475',
    city: 'Weidenthal'
  },
  {
    postalCode: '67480',
    city: 'Edenkoben'
  },
  {
    postalCode: '67482',
    city: 'Altdorf'
  },
  {
    postalCode: '67482',
    city: 'Böbingen'
  },
  {
    postalCode: '67482',
    city: 'Freimersheim'
  },
  {
    postalCode: '67482',
    city: 'Venningen'
  },
  {
    postalCode: '67483',
    city: 'Edesheim'
  },
  {
    postalCode: '67483',
    city: 'Großfischlingen'
  },
  {
    postalCode: '67483',
    city: 'Kleinfischlingen'
  },
  {
    postalCode: '67487',
    city: 'Maikammer'
  },
  {
    postalCode: '67487',
    city: 'St Martin'
  },
  {
    postalCode: '67489',
    city: 'Kirrweiler'
  },
  {
    postalCode: '67547',
    city: 'Worms'
  },
  {
    postalCode: '67549',
    city: 'Worms'
  },
  {
    postalCode: '67550',
    city: 'Worms'
  },
  {
    postalCode: '67551',
    city: 'Worms'
  },
  {
    postalCode: '67574',
    city: 'Osthofen'
  },
  {
    postalCode: '67575',
    city: 'Eich'
  },
  {
    postalCode: '67577',
    city: 'Alsheim'
  },
  {
    postalCode: '67578',
    city: 'Gimbsheim'
  },
  {
    postalCode: '67580',
    city: 'Hamm'
  },
  {
    postalCode: '67582',
    city: 'Mettenheim'
  },
  {
    postalCode: '67583',
    city: 'Guntersblum'
  },
  {
    postalCode: '67585',
    city: 'Dorn-Dürkheim'
  },
  {
    postalCode: '67586',
    city: 'Hillesheim'
  },
  {
    postalCode: '67587',
    city: 'Wintersheim'
  },
  {
    postalCode: '67590',
    city: 'Monsheim'
  },
  {
    postalCode: '67591',
    city: 'Hohen-Sülzen'
  },
  {
    postalCode: '67591',
    city: 'Mölsheim'
  },
  {
    postalCode: '67591',
    city: 'Mörstadt'
  },
  {
    postalCode: '67591',
    city: 'Offstein'
  },
  {
    postalCode: '67591',
    city: 'Wachenheim'
  },
  {
    postalCode: '67592',
    city: 'Flörsheim-Dalsheim'
  },
  {
    postalCode: '67593',
    city: 'Bermersheim'
  },
  {
    postalCode: '67593',
    city: 'Westhofen'
  },
  {
    postalCode: '67595',
    city: 'Bechtheim'
  },
  {
    postalCode: '67596',
    city: 'Dittelsheim-Heßloch'
  },
  {
    postalCode: '67596',
    city: 'Frettenheim'
  },
  {
    postalCode: '67598',
    city: 'Gundersheim'
  },
  {
    postalCode: '67599',
    city: 'Gundheim'
  },
  {
    postalCode: '67655',
    city: 'Kaiserslautern'
  },
  {
    postalCode: '67657',
    city: 'Kaiserslautern'
  },
  {
    postalCode: '67659',
    city: 'Kaiserslautern'
  },
  {
    postalCode: '67661',
    city: 'Breitenau'
  },
  {
    postalCode: '67661',
    city: 'Kaiserslautern'
  },
  {
    postalCode: '67661',
    city: 'Kindsbach, Forsthaus'
  },
  {
    postalCode: '67661',
    city: 'Stüterhof'
  },
  {
    postalCode: '67663',
    city: 'Kaiserslautern'
  },
  {
    postalCode: '67677',
    city: 'Altenhof'
  },
  {
    postalCode: '67677',
    city: 'Enkenbach-Alsenborn'
  },
  {
    postalCode: '67677',
    city: 'Schorlenberg'
  },
  {
    postalCode: '67678',
    city: 'Mehlingen'
  },
  {
    postalCode: '67680',
    city: 'Eichenbachermühle'
  },
  {
    postalCode: '67680',
    city: 'Neuhemsbach'
  },
  {
    postalCode: '67681',
    city: 'Sembach'
  },
  {
    postalCode: '67681',
    city: 'Wartenberg-Rohrbach'
  },
  {
    postalCode: '67685',
    city: 'Erzenhausen'
  },
  {
    postalCode: '67685',
    city: 'Eulenbis'
  },
  {
    postalCode: '67685',
    city: 'Mückenhof'
  },
  {
    postalCode: '67685',
    city: 'Mückenmühle'
  },
  {
    postalCode: '67685',
    city: 'Schwedelbach'
  },
  {
    postalCode: '67685',
    city: 'Weilerbach'
  },
  {
    postalCode: '67686',
    city: 'Mackenbach'
  },
  {
    postalCode: '67688',
    city: 'Rodenbach'
  },
  {
    postalCode: '67691',
    city: 'Hochspeyer'
  },
  {
    postalCode: '67691',
    city: 'Klaftertalerhof'
  },
  {
    postalCode: '67693',
    city: 'Fischbach'
  },
  {
    postalCode: '67693',
    city: 'Waldleiningen'
  },
  {
    postalCode: '67697',
    city: 'Otterberg'
  },
  {
    postalCode: '67699',
    city: 'Heiligenmoschel'
  },
  {
    postalCode: '67699',
    city: 'Horterhof'
  },
  {
    postalCode: '67699',
    city: 'Schneckenhausen'
  },
  {
    postalCode: '67699',
    city: 'Sickingerhof'
  },
  {
    postalCode: '67700',
    city: 'Niederkirchen'
  },
  {
    postalCode: '67701',
    city: 'Schallodenbach'
  },
  {
    postalCode: '67705',
    city: 'Eisenschmelz'
  },
  {
    postalCode: '67705',
    city: 'Finsterbrunnertal'
  },
  {
    postalCode: '67705',
    city: 'Neuhof'
  },
  {
    postalCode: '67705',
    city: 'Stelzenberg'
  },
  {
    postalCode: '67705',
    city: 'Trippstadt'
  },
  {
    postalCode: '67706',
    city: 'Krickenbach'
  },
  {
    postalCode: '67706',
    city: 'Ländlerhof'
  },
  {
    postalCode: '67707',
    city: 'Karlsthal Bahnhof'
  },
  {
    postalCode: '67707',
    city: 'Schopp'
  },
  {
    postalCode: '67714',
    city: 'Heidelsburg'
  },
  {
    postalCode: '67714',
    city: 'Hollertal'
  },
  {
    postalCode: '67714',
    city: 'Hundsweihersägemühle'
  },
  {
    postalCode: '67714',
    city: 'Waldfischbach-Burgalben'
  },
  {
    postalCode: '67715',
    city: 'Geiselberg'
  },
  {
    postalCode: '67716',
    city: 'Heltersberg'
  },
  {
    postalCode: '67718',
    city: 'Schmalenberg'
  },
  {
    postalCode: '67722',
    city: 'Winnweiler'
  },
  {
    postalCode: '67724',
    city: 'Gehrweiler'
  },
  {
    postalCode: '67724',
    city: 'Gonbach'
  },
  {
    postalCode: '67724',
    city: 'Gundersweiler'
  },
  {
    postalCode: '67724',
    city: 'Höringen'
  },
  {
    postalCode: '67724',
    city: 'Messersbacherhof'
  },
  {
    postalCode: '67725',
    city: 'Börrstadt'
  },
  {
    postalCode: '67725',
    city: 'Breunigweiler'
  },
  {
    postalCode: '67725',
    city: 'Röderhof'
  },
  {
    postalCode: '67727',
    city: 'Lohnsfeld'
  },
  {
    postalCode: '67728',
    city: 'Münchweiler'
  },
  {
    postalCode: '67729',
    city: 'Sippersfeld'
  },
  {
    postalCode: '67731',
    city: 'Dudenbacherhof'
  },
  {
    postalCode: '67731',
    city: 'Otterbach'
  },
  {
    postalCode: '67731',
    city: 'Reichenbacherhof'
  },
  {
    postalCode: '67732',
    city: 'Hirschhorn'
  },
  {
    postalCode: '67734',
    city: 'Katzweiler'
  },
  {
    postalCode: '67734',
    city: 'Sulzbachtal'
  },
  {
    postalCode: '67735',
    city: 'Mehlbach'
  },
  {
    postalCode: '67737',
    city: 'Frankelbach'
  },
  {
    postalCode: '67737',
    city: 'Olsbrücken'
  },
  {
    postalCode: '67737',
    city: 'Winterbach'
  },
  {
    postalCode: '67742',
    city: 'Adenbach'
  },
  {
    postalCode: '67742',
    city: 'Bösodenbacherhof'
  },
  {
    postalCode: '67742',
    city: 'Buborn'
  },
  {
    postalCode: '67742',
    city: 'Deimberg'
  },
  {
    postalCode: '67742',
    city: 'Ginsweiler'
  },
  {
    postalCode: '67742',
    city: 'Hausweiler'
  },
  {
    postalCode: '67742',
    city: 'Heinzenhausen'
  },
  {
    postalCode: '67742',
    city: 'Herren-Sulzbach'
  },
  {
    postalCode: '67742',
    city: 'Lauterecken'
  },
  {
    postalCode: '67742',
    city: 'Schönbornerhof'
  },
  {
    postalCode: '67744',
    city: 'Cronenberg'
  },
  {
    postalCode: '67744',
    city: 'Hohenöllen'
  },
  {
    postalCode: '67744',
    city: 'Homberg'
  },
  {
    postalCode: '67744',
    city: 'Hoppstädten'
  },
  {
    postalCode: '67744',
    city: 'Kappeln'
  },
  {
    postalCode: '67744',
    city: 'Kirrweiler'
  },
  {
    postalCode: '67744',
    city: 'Lohnweiler'
  },
  {
    postalCode: '67744',
    city: 'Löllbach'
  },
  {
    postalCode: '67744',
    city: 'Medard'
  },
  {
    postalCode: '67744',
    city: 'Rathskirchen'
  },
  {
    postalCode: '67744',
    city: 'Schweinschied'
  },
  {
    postalCode: '67744',
    city: 'Seelen'
  },
  {
    postalCode: '67744',
    city: 'Wiesweiler'
  },
  {
    postalCode: '67745',
    city: 'Grumbach'
  },
  {
    postalCode: '67746',
    city: 'Langweiler'
  },
  {
    postalCode: '67746',
    city: 'Merzweiler'
  },
  {
    postalCode: '67746',
    city: 'Unterjeckenbach'
  },
  {
    postalCode: '67748',
    city: 'Odenbach'
  },
  {
    postalCode: '67749',
    city: 'Nerzweiler'
  },
  {
    postalCode: '67749',
    city: 'Offenbach-Hundheim'
  },
  {
    postalCode: '67752',
    city: 'Oberweiler-Tiefenbach'
  },
  {
    postalCode: '67752',
    city: 'Rutsweiler'
  },
  {
    postalCode: '67752',
    city: 'Wolfstein'
  },
  {
    postalCode: '67753',
    city: 'Aschbach'
  },
  {
    postalCode: '67753',
    city: 'Einöllen'
  },
  {
    postalCode: '67753',
    city: 'Hefersweiler'
  },
  {
    postalCode: '67753',
    city: 'Reipoltskirchen'
  },
  {
    postalCode: '67753',
    city: 'Relsberg'
  },
  {
    postalCode: '67753',
    city: 'Rothselberg'
  },
  {
    postalCode: '67754',
    city: 'Eßweiler'
  },
  {
    postalCode: '67756',
    city: 'Hinzweiler'
  },
  {
    postalCode: '67756',
    city: 'Oberweiler im Tal'
  },
  {
    postalCode: '67757',
    city: 'Kreimbach-Kaulbach'
  },
  {
    postalCode: '67759',
    city: 'Nußbach'
  },
  {
    postalCode: '67759',
    city: 'Reichsthal'
  },
  {
    postalCode: '67806',
    city: 'Bisterschied'
  },
  {
    postalCode: '67806',
    city: 'Dörrmoschel'
  },
  {
    postalCode: '67806',
    city: 'Felsbergerhof'
  },
  {
    postalCode: '67806',
    city: 'Karlshöhe'
  },
  {
    postalCode: '67806',
    city: 'Katzenbach'
  },
  {
    postalCode: '67806',
    city: 'Kolbenmühle'
  },
  {
    postalCode: '67806',
    city: 'Kreuzhof'
  },
  {
    postalCode: '67806',
    city: 'Obermittweilerhof'
  },
  {
    postalCode: '67806',
    city: 'Rockenhausen'
  },
  {
    postalCode: '67806',
    city: 'Schacherhof'
  },
  {
    postalCode: '67806',
    city: 'Simonshof'
  },
  {
    postalCode: '67806',
    city: 'Spreiterhof'
  },
  {
    postalCode: '67806',
    city: 'Teschenmoschel'
  },
  {
    postalCode: '67806',
    city: 'Untermittweilerhof'
  },
  {
    postalCode: '67806',
    city: 'Wolfsmühle'
  },
  {
    postalCode: '67808',
    city: 'Bayerfeld-Steckweiler'
  },
  {
    postalCode: '67808',
    city: 'Bennhausen'
  },
  {
    postalCode: '67808',
    city: 'Falkenstein'
  },
  {
    postalCode: '67808',
    city: 'Imsweiler'
  },
  {
    postalCode: '67808',
    city: 'Jägerlust, Forsthaus'
  },
  {
    postalCode: '67808',
    city: 'Langheckerhof'
  },
  {
    postalCode: '67808',
    city: 'Mörsfeld'
  },
  {
    postalCode: '67808',
    city: 'Neubau'
  },
  {
    postalCode: '67808',
    city: 'Ransweiler'
  },
  {
    postalCode: '67808',
    city: 'Ruppertsecken'
  },
  {
    postalCode: '67808',
    city: 'Schönborn'
  },
  {
    postalCode: '67808',
    city: 'Schweisweiler'
  },
  {
    postalCode: '67808',
    city: 'Stahlberg'
  },
  {
    postalCode: '67808',
    city: 'Steinbach'
  },
  {
    postalCode: '67808',
    city: 'Sulzhof'
  },
  {
    postalCode: '67808',
    city: 'Wambacherhof'
  },
  {
    postalCode: '67808',
    city: 'Weitersweiler'
  },
  {
    postalCode: '67808',
    city: 'Würzweiler'
  },
  {
    postalCode: '67811',
    city: 'Dielkirchen'
  },
  {
    postalCode: '67813',
    city: 'Gerbach'
  },
  {
    postalCode: '67813',
    city: 'Schwarzengraben'
  },
  {
    postalCode: '67813',
    city: 'St Alban'
  },
  {
    postalCode: '67814',
    city: 'Dannenfels'
  },
  {
    postalCode: '67814',
    city: 'Jakobsweiler'
  },
  {
    postalCode: '67816',
    city: 'Dreisen'
  },
  {
    postalCode: '67816',
    city: 'Herfingerhof'
  },
  {
    postalCode: '67816',
    city: 'Mühlbusch'
  },
  {
    postalCode: '67816',
    city: 'Standenbühl'
  },
  {
    postalCode: '67817',
    city: 'Imsbach'
  },
  {
    postalCode: '67819',
    city: 'Kriegsfeld'
  },
  {
    postalCode: '67821',
    city: 'Alsenz'
  },
  {
    postalCode: '67821',
    city: 'Oberndorf'
  },
  {
    postalCode: '67822',
    city: 'Bremricherhof'
  },
  {
    postalCode: '67822',
    city: 'Finkenbach-Gersweiler'
  },
  {
    postalCode: '67822',
    city: 'Gaugrehweiler'
  },
  {
    postalCode: '67822',
    city: 'Gutenbacherhof'
  },
  {
    postalCode: '67822',
    city: 'Hengstbacherhof'
  },
  {
    postalCode: '67822',
    city: 'Kalkofen'
  },
  {
    postalCode: '67822',
    city: 'Leiningerhof'
  },
  {
    postalCode: '67822',
    city: 'Mannweiler-Cölln'
  },
  {
    postalCode: '67822',
    city: 'Münsterappel'
  },
  {
    postalCode: '67822',
    city: 'Niederhausen'
  },
  {
    postalCode: '67822',
    city: 'Niedermoschel'
  },
  {
    postalCode: '67822',
    city: 'Oberhausen'
  },
  {
    postalCode: '67822',
    city: 'Schmalfelderhof'
  },
  {
    postalCode: '67822',
    city: 'Stolzenbergerhof'
  },
  {
    postalCode: '67822',
    city: 'Waldgrehweiler'
  },
  {
    postalCode: '67822',
    city: 'Winterborn'
  },
  {
    postalCode: '67823',
    city: 'Bergmühle'
  },
  {
    postalCode: '67823',
    city: 'Lettweiler'
  },
  {
    postalCode: '67823',
    city: 'Obermoschel'
  },
  {
    postalCode: '67823',
    city: 'Schiersfeld'
  },
  {
    postalCode: '67823',
    city: 'Sitters'
  },
  {
    postalCode: '67823',
    city: 'Unkenbach'
  },
  {
    postalCode: '67824',
    city: 'Feilbingert'
  },
  {
    postalCode: '67826',
    city: 'Hallgarten'
  },
  {
    postalCode: '67826',
    city: 'Montforterhof'
  },
  {
    postalCode: '67827',
    city: 'Becherbach'
  },
  {
    postalCode: '67829',
    city: 'Callbach'
  },
  {
    postalCode: '67829',
    city: 'Reiffelbach'
  },
  {
    postalCode: '67829',
    city: 'Schmittweiler'
  },
  {
    postalCode: '68159',
    city: 'Mannheim'
  },
  {
    postalCode: '68161',
    city: 'Mannheim'
  },
  {
    postalCode: '68163',
    city: 'Mannheim'
  },
  {
    postalCode: '68165',
    city: 'Mannheim'
  },
  {
    postalCode: '68167',
    city: 'Mannheim'
  },
  {
    postalCode: '68169',
    city: 'Mannheim'
  },
  {
    postalCode: '68199',
    city: 'Mannheim'
  },
  {
    postalCode: '68219',
    city: 'Mannheim'
  },
  {
    postalCode: '68229',
    city: 'Mannheim'
  },
  {
    postalCode: '68239',
    city: 'Mannheim'
  },
  {
    postalCode: '68259',
    city: 'Mannheim'
  },
  {
    postalCode: '68305',
    city: 'Mannheim'
  },
  {
    postalCode: '68307',
    city: 'Mannheim'
  },
  {
    postalCode: '68309',
    city: 'Mannheim'
  },
  {
    postalCode: '68519',
    city: 'Viernheim'
  },
  {
    postalCode: '68526',
    city: 'Ladenburg'
  },
  {
    postalCode: '68535',
    city: 'Edingen-Neckarhausen'
  },
  {
    postalCode: '68542',
    city: 'Heddesheim'
  },
  {
    postalCode: '68549',
    city: 'Ilvesheim'
  },
  {
    postalCode: '68623',
    city: 'Forsthaus Heide'
  },
  {
    postalCode: '68623',
    city: 'Lampertheim'
  },
  {
    postalCode: '68642',
    city: 'Bürstadt'
  },
  {
    postalCode: '68647',
    city: 'Biblis'
  },
  {
    postalCode: '68649',
    city: 'Groß-Rohrheim'
  },
  {
    postalCode: '68723',
    city: 'Oftersheim'
  },
  {
    postalCode: '68723',
    city: 'Plankstadt'
  },
  {
    postalCode: '68723',
    city: 'Schwetzingen'
  },
  {
    postalCode: '68753',
    city: 'Waghäusel'
  },
  {
    postalCode: '68766',
    city: 'Hockenheim'
  },
  {
    postalCode: '68775',
    city: 'Ketsch'
  },
  {
    postalCode: '68782',
    city: 'Brühl'
  },
  {
    postalCode: '68789',
    city: 'St. Leon-Rot'
  },
  {
    postalCode: '68794',
    city: 'Oberhausen-Rheinhausen'
  },
  {
    postalCode: '68799',
    city: 'Reilingen'
  },
  {
    postalCode: '68804',
    city: 'Altlußheim'
  },
  {
    postalCode: '68809',
    city: 'Neulußheim'
  },
  {
    postalCode: '69115',
    city: 'Heidelberg'
  },
  {
    postalCode: '69117',
    city: 'Heidelberg'
  },
  {
    postalCode: '69118',
    city: 'Heidelberg'
  },
  {
    postalCode: '69120',
    city: 'Heidelberg'
  },
  {
    postalCode: '69121',
    city: 'Heidelberg'
  },
  {
    postalCode: '69123',
    city: 'Heidelberg'
  },
  {
    postalCode: '69124',
    city: 'Heidelberg'
  },
  {
    postalCode: '69126',
    city: 'Heidelberg'
  },
  {
    postalCode: '69151',
    city: 'Neckargemünd'
  },
  {
    postalCode: '69168',
    city: 'Wiesloch'
  },
  {
    postalCode: '69181',
    city: 'Leimen'
  },
  {
    postalCode: '69190',
    city: 'Walldorf'
  },
  {
    postalCode: '69198',
    city: 'Schriesheim'
  },
  {
    postalCode: '69207',
    city: 'Sandhausen'
  },
  {
    postalCode: '69214',
    city: 'Eppelheim'
  },
  {
    postalCode: '69221',
    city: 'Dossenheim'
  },
  {
    postalCode: '69226',
    city: 'Nußloch'
  },
  {
    postalCode: '69231',
    city: 'Rauenberg'
  },
  {
    postalCode: '69234',
    city: 'Dielheim'
  },
  {
    postalCode: '69239',
    city: 'Neckarsteinach'
  },
  {
    postalCode: '69242',
    city: 'Mühlhausen'
  },
  {
    postalCode: '69245',
    city: 'Bammental'
  },
  {
    postalCode: '69250',
    city: 'Schönau'
  },
  {
    postalCode: '69251',
    city: 'Gaiberg'
  },
  {
    postalCode: '69253',
    city: 'Heiligkreuzsteinach'
  },
  {
    postalCode: '69254',
    city: 'Malsch'
  },
  {
    postalCode: '69256',
    city: 'Mauer'
  },
  {
    postalCode: '69257',
    city: 'Wiesenbach'
  },
  {
    postalCode: '69259',
    city: 'Wilhelmsfeld'
  },
  {
    postalCode: '69412',
    city: 'Eberbach'
  },
  {
    postalCode: '69412',
    city: 'Igelsbach'
  },
  {
    postalCode: '69427',
    city: 'Mudau'
  },
  {
    postalCode: '69429',
    city: 'Unterdielbach'
  },
  {
    postalCode: '69429',
    city: 'Waldbrunn'
  },
  {
    postalCode: '69434',
    city: 'Brombach'
  },
  {
    postalCode: '69434',
    city: 'Heddesbach'
  },
  {
    postalCode: '69434',
    city: 'Hirschhorn'
  },
  {
    postalCode: '69436',
    city: 'Schönbrunn'
  },
  {
    postalCode: '69437',
    city: 'Neckargerach'
  },
  {
    postalCode: '69439',
    city: 'Zwingenberg'
  },
  {
    postalCode: '69469',
    city: 'Weinheim'
  },
  {
    postalCode: '69483',
    city: 'Wald-Michelbach'
  },
  {
    postalCode: '69488',
    city: 'Birkenau'
  },
  {
    postalCode: '69493',
    city: 'Hirschberg'
  },
  {
    postalCode: '69502',
    city: 'Hemsbach'
  },
  {
    postalCode: '69509',
    city: 'Mörlenbach'
  },
  {
    postalCode: '69514',
    city: 'Laudenbach'
  },
  {
    postalCode: '69517',
    city: 'Gorxheimertal'
  },
  {
    postalCode: '69518',
    city: 'Abtsteinach'
  },
  {
    postalCode: '70173',
    city: 'Stuttgart'
  },
  {
    postalCode: '70174',
    city: 'Stuttgart'
  },
  {
    postalCode: '70176',
    city: 'Stuttgart'
  },
  {
    postalCode: '70178',
    city: 'Stuttgart'
  },
  {
    postalCode: '70180',
    city: 'Stuttgart'
  },
  {
    postalCode: '70182',
    city: 'Stuttgart'
  },
  {
    postalCode: '70184',
    city: 'Stuttgart'
  },
  {
    postalCode: '70186',
    city: 'Stuttgart'
  },
  {
    postalCode: '70188',
    city: 'Stuttgart'
  },
  {
    postalCode: '70190',
    city: 'Stuttgart'
  },
  {
    postalCode: '70191',
    city: 'Stuttgart'
  },
  {
    postalCode: '70192',
    city: 'Stuttgart'
  },
  {
    postalCode: '70193',
    city: 'Stuttgart'
  },
  {
    postalCode: '70195',
    city: 'Stuttgart'
  },
  {
    postalCode: '70197',
    city: 'Stuttgart'
  },
  {
    postalCode: '70199',
    city: 'Stuttgart'
  },
  {
    postalCode: '70327',
    city: 'Stuttgart'
  },
  {
    postalCode: '70329',
    city: 'Stuttgart'
  },
  {
    postalCode: '70372',
    city: 'Stuttgart'
  },
  {
    postalCode: '70374',
    city: 'Stuttgart'
  },
  {
    postalCode: '70376',
    city: 'Stuttgart'
  },
  {
    postalCode: '70378',
    city: 'Sonnenhof'
  },
  {
    postalCode: '70378',
    city: 'Stuttgart'
  },
  {
    postalCode: '70435',
    city: 'Stuttgart'
  },
  {
    postalCode: '70437',
    city: 'Stuttgart'
  },
  {
    postalCode: '70439',
    city: 'Stuttgart'
  },
  {
    postalCode: '70469',
    city: 'Stuttgart'
  },
  {
    postalCode: '70499',
    city: 'Stuttgart'
  },
  {
    postalCode: '70563',
    city: 'Stuttgart'
  },
  {
    postalCode: '70565',
    city: 'Stuttgart'
  },
  {
    postalCode: '70567',
    city: 'Stuttgart'
  },
  {
    postalCode: '70569',
    city: 'Stuttgart'
  },
  {
    postalCode: '70597',
    city: 'Stuttgart'
  },
  {
    postalCode: '70599',
    city: 'Stuttgart'
  },
  {
    postalCode: '70619',
    city: 'Stuttgart'
  },
  {
    postalCode: '70629',
    city: 'Stuttgart'
  },
  {
    postalCode: '70734',
    city: 'Fellbach'
  },
  {
    postalCode: '70736',
    city: 'Fellbach'
  },
  {
    postalCode: '70771',
    city: 'Leinfelden-Echterdingen'
  },
  {
    postalCode: '70794',
    city: 'Filderstadt'
  },
  {
    postalCode: '70806',
    city: 'Kornwestheim'
  },
  {
    postalCode: '70825',
    city: 'Korntal-Münchingen'
  },
  {
    postalCode: '70839',
    city: 'Gerlingen'
  },
  {
    postalCode: '71032',
    city: 'Böblingen'
  },
  {
    postalCode: '71034',
    city: 'Böblingen'
  },
  {
    postalCode: '71063',
    city: 'Sindelfingen'
  },
  {
    postalCode: '71065',
    city: 'Sindelfingen'
  },
  {
    postalCode: '71067',
    city: 'Sindelfingen'
  },
  {
    postalCode: '71069',
    city: 'Sindelfingen'
  },
  {
    postalCode: '71083',
    city: 'Herrenberg'
  },
  {
    postalCode: '71088',
    city: 'Holzgerlingen'
  },
  {
    postalCode: '71093',
    city: 'Weil im Schönbuch'
  },
  {
    postalCode: '71101',
    city: 'Schönaich'
  },
  {
    postalCode: '71106',
    city: 'Magstadt'
  },
  {
    postalCode: '71111',
    city: 'Burkhardtsmühle'
  },
  {
    postalCode: '71111',
    city: 'Obere Kleinmichelesmühle'
  },
  {
    postalCode: '71111',
    city: 'Obere Rauhmühle'
  },
  {
    postalCode: '71111',
    city: 'Untere Kleinmichelesmühle'
  },
  {
    postalCode: '71111',
    city: 'Untere Rauhmühle'
  },
  {
    postalCode: '71111',
    city: 'Waldenbuch'
  },
  {
    postalCode: '71116',
    city: 'Gärtringen'
  },
  {
    postalCode: '71120',
    city: 'Grafenau'
  },
  {
    postalCode: '71126',
    city: 'Gäufelden'
  },
  {
    postalCode: '71131',
    city: 'Jettingen'
  },
  {
    postalCode: '71134',
    city: 'Aidlingen'
  },
  {
    postalCode: '71139',
    city: 'Ehningen'
  },
  {
    postalCode: '71144',
    city: 'Schlechtenmühle'
  },
  {
    postalCode: '71144',
    city: 'Schlößlesmühle'
  },
  {
    postalCode: '71144',
    city: 'Steinenbronn'
  },
  {
    postalCode: '71144',
    city: 'Walzenmühle'
  },
  {
    postalCode: '71149',
    city: 'Bondorf'
  },
  {
    postalCode: '71154',
    city: 'Nufringen'
  },
  {
    postalCode: '71155',
    city: 'Altdorf'
  },
  {
    postalCode: '71157',
    city: 'Hildrizhausen'
  },
  {
    postalCode: '71159',
    city: 'Mötzingen'
  },
  {
    postalCode: '71229',
    city: 'Leonberg'
  },
  {
    postalCode: '71254',
    city: 'Ditzingen'
  },
  {
    postalCode: '71263',
    city: 'Weil der Stadt'
  },
  {
    postalCode: '71272',
    city: 'Grundhof'
  },
  {
    postalCode: '71272',
    city: 'Renningen'
  },
  {
    postalCode: '71277',
    city: 'Rutesheim'
  },
  {
    postalCode: '71282',
    city: 'Hemmingen'
  },
  {
    postalCode: '71287',
    city: 'Weissach'
  },
  {
    postalCode: '71292',
    city: 'Friolzheim'
  },
  {
    postalCode: '71296',
    city: 'Heimsheim'
  },
  {
    postalCode: '71297',
    city: 'Mönsheim'
  },
  {
    postalCode: '71299',
    city: 'Wimsheim'
  },
  {
    postalCode: '71332',
    city: 'Waiblingen'
  },
  {
    postalCode: '71334',
    city: 'Waiblingen'
  },
  {
    postalCode: '71336',
    city: 'Waiblingen'
  },
  {
    postalCode: '71364',
    city: 'Birkachhof'
  },
  {
    postalCode: '71364',
    city: 'Steinächle'
  },
  {
    postalCode: '71364',
    city: 'Winnenden'
  },
  {
    postalCode: '71384',
    city: 'Weinstadt'
  },
  {
    postalCode: '71394',
    city: 'Kernen'
  },
  {
    postalCode: '71397',
    city: 'Leutenbach'
  },
  {
    postalCode: '71404',
    city: 'Korb'
  },
  {
    postalCode: '71409',
    city: 'Schwaikheim'
  },
  {
    postalCode: '71522',
    city: 'Backnang'
  },
  {
    postalCode: '71540',
    city: 'Glattenzainbach'
  },
  {
    postalCode: '71540',
    city: 'Hornberger Reute'
  },
  {
    postalCode: '71540',
    city: 'Murrhardt'
  },
  {
    postalCode: '71543',
    city: 'Stocksberg, Gem Beilstein'
  },
  {
    postalCode: '71543',
    city: 'Wüstenrot'
  },
  {
    postalCode: '71546',
    city: 'Aspach'
  },
  {
    postalCode: '71549',
    city: 'Auenwald'
  },
  {
    postalCode: '71549',
    city: 'Glaitenhof'
  },
  {
    postalCode: '71554',
    city: 'Weissach'
  },
  {
    postalCode: '71560',
    city: 'Bernhalden'
  },
  {
    postalCode: '71560',
    city: 'Mittelfischbach'
  },
  {
    postalCode: '71560',
    city: 'Sulzbach'
  },
  {
    postalCode: '71560',
    city: 'Unterfischbach'
  },
  {
    postalCode: '71563',
    city: 'Affalterbach'
  },
  {
    postalCode: '71563',
    city: 'Siegelhausen'
  },
  {
    postalCode: '71566',
    city: 'Althütte'
  },
  {
    postalCode: '71570',
    city: 'Katharinenhof'
  },
  {
    postalCode: '71570',
    city: 'Oppenweiler'
  },
  {
    postalCode: '71573',
    city: 'Allmersbach'
  },
  {
    postalCode: '71576',
    city: 'Burgstetten'
  },
  {
    postalCode: '71577',
    city: 'Großerlach'
  },
  {
    postalCode: '71577',
    city: 'Hals'
  },
  {
    postalCode: '71579',
    city: 'Spiegelberg'
  },
  {
    postalCode: '71579',
    city: 'Warthof'
  },
  {
    postalCode: '71634',
    city: 'Ludwigsburg'
  },
  {
    postalCode: '71636',
    city: 'Ludwigsburg'
  },
  {
    postalCode: '71638',
    city: 'Ludwigsburg'
  },
  {
    postalCode: '71640',
    city: 'Ludwigsburg'
  },
  {
    postalCode: '71642',
    city: 'Ludwigsburg'
  },
  {
    postalCode: '71665',
    city: 'Vaihingen'
  },
  {
    postalCode: '71672',
    city: 'Makenhof'
  },
  {
    postalCode: '71672',
    city: 'Marbach'
  },
  {
    postalCode: '71679',
    city: 'Asperg'
  },
  {
    postalCode: '71686',
    city: 'Remseck'
  },
  {
    postalCode: '71691',
    city: 'Freiberg'
  },
  {
    postalCode: '71696',
    city: 'Möglingen'
  },
  {
    postalCode: '71701',
    city: 'Schwieberdingen'
  },
  {
    postalCode: '71706',
    city: 'Hardthof'
  },
  {
    postalCode: '71706',
    city: 'Markgröningen'
  },
  {
    postalCode: '71711',
    city: 'Hinterbirkenhof'
  },
  {
    postalCode: '71711',
    city: 'Murr'
  },
  {
    postalCode: '71711',
    city: 'Steinheim'
  },
  {
    postalCode: '71717',
    city: 'Beilstein'
  },
  {
    postalCode: '71720',
    city: 'Obere Ölmühle'
  },
  {
    postalCode: '71720',
    city: 'Oberstenfeld'
  },
  {
    postalCode: '71720',
    city: 'Untere Ölmühle'
  },
  {
    postalCode: '71723',
    city: 'Großbottwar'
  },
  {
    postalCode: '71726',
    city: 'Benningen'
  },
  {
    postalCode: '71729',
    city: 'Erdmannhausen'
  },
  {
    postalCode: '71729',
    city: 'Rundsmühlhof'
  },
  {
    postalCode: '71732',
    city: 'Lehenfeld'
  },
  {
    postalCode: '71732',
    city: 'Silberhälden'
  },
  {
    postalCode: '71732',
    city: 'Tamm'
  },
  {
    postalCode: '71735',
    city: 'Eberdingen'
  },
  {
    postalCode: '71737',
    city: 'Kirchberg'
  },
  {
    postalCode: '71739',
    city: 'Oberriexingen'
  },
  {
    postalCode: '72070',
    city: 'Hohenentringen'
  },
  {
    postalCode: '72070',
    city: 'Tübingen'
  },
  {
    postalCode: '72072',
    city: 'Tübingen'
  },
  {
    postalCode: '72074',
    city: 'Tübingen'
  },
  {
    postalCode: '72076',
    city: 'Tübingen'
  },
  {
    postalCode: '72108',
    city: 'Rottenburg'
  },
  {
    postalCode: '72116',
    city: 'Mössingen'
  },
  {
    postalCode: '72119',
    city: 'Ammerbuch'
  },
  {
    postalCode: '72124',
    city: 'Pliezhausen'
  },
  {
    postalCode: '72127',
    city: 'Kusterdingen'
  },
  {
    postalCode: '72131',
    city: 'Ofterdingen'
  },
  {
    postalCode: '72135',
    city: 'Dettenhausen'
  },
  {
    postalCode: '72138',
    city: 'Im Hengstrain'
  },
  {
    postalCode: '72138',
    city: 'Kirchentellinsfurt'
  },
  {
    postalCode: '72141',
    city: 'Walddorfhäslach'
  },
  {
    postalCode: '72144',
    city: 'Dußlingen'
  },
  {
    postalCode: '72145',
    city: 'Hirrlingen'
  },
  {
    postalCode: '72147',
    city: 'Nehren'
  },
  {
    postalCode: '72147',
    city: 'Schlattwiesen'
  },
  {
    postalCode: '72149',
    city: 'Neustetten'
  },
  {
    postalCode: '72160',
    city: 'Horb'
  },
  {
    postalCode: '72160',
    city: 'Oberer Eutinger Talhof'
  },
  {
    postalCode: '72160',
    city: 'Oberhof'
  },
  {
    postalCode: '72172',
    city: 'Sulz'
  },
  {
    postalCode: '72175',
    city: 'Dornhan'
  },
  {
    postalCode: '72178',
    city: 'Waldachtal'
  },
  {
    postalCode: '72181',
    city: 'Starzach'
  },
  {
    postalCode: '72184',
    city: 'Eutingen'
  },
  {
    postalCode: '72186',
    city: 'Empfingen'
  },
  {
    postalCode: '72186',
    city: 'Weiherhof'
  },
  {
    postalCode: '72189',
    city: 'Vöhringen'
  },
  {
    postalCode: '72202',
    city: 'Nagold'
  },
  {
    postalCode: '72213',
    city: 'Altensteig'
  },
  {
    postalCode: '72218',
    city: 'Wildberg'
  },
  {
    postalCode: '72221',
    city: 'Haiterbach'
  },
  {
    postalCode: '72224',
    city: 'Ebhausen'
  },
  {
    postalCode: '72226',
    city: 'Simmersfeld'
  },
  {
    postalCode: '72227',
    city: 'Egenhausen'
  },
  {
    postalCode: '72229',
    city: 'Rohrdorf'
  },
  {
    postalCode: '72250',
    city: 'Freudenstadt'
  },
  {
    postalCode: '72250',
    city: 'Zuflucht'
  },
  {
    postalCode: '72270',
    city: 'Baiersbronn'
  },
  {
    postalCode: '72275',
    city: 'Alpirsbach'
  },
  {
    postalCode: '72280',
    city: 'Dornstetten'
  },
  {
    postalCode: '72285',
    city: 'Pfalzgrafenweiler'
  },
  {
    postalCode: '72290',
    city: 'Loßburg'
  },
  {
    postalCode: '72290',
    city: 'Schwenkenhof'
  },
  {
    postalCode: '72291',
    city: 'Betzweiler-Wälde'
  },
  {
    postalCode: '72291',
    city: 'Salzenweiler'
  },
  {
    postalCode: '72293',
    city: 'Glatten'
  },
  {
    postalCode: '72294',
    city: 'Grömbach'
  },
  {
    postalCode: '72296',
    city: 'Schopfloch'
  },
  {
    postalCode: '72297',
    city: 'Pfaffenstube'
  },
  {
    postalCode: '72297',
    city: 'Seewald'
  },
  {
    postalCode: '72297',
    city: 'Völmlesmühle'
  },
  {
    postalCode: '72299',
    city: 'Wörnersberg'
  },
  {
    postalCode: '72299',
    city: 'Zinsbachmühle'
  },
  {
    postalCode: '72336',
    city: 'Balingen'
  },
  {
    postalCode: '72348',
    city: 'Rosenfeld'
  },
  {
    postalCode: '72351',
    city: 'Geislingen'
  },
  {
    postalCode: '72355',
    city: 'Schömberg'
  },
  {
    postalCode: '72356',
    city: 'Dautmergen'
  },
  {
    postalCode: '72358',
    city: 'Dormettingen'
  },
  {
    postalCode: '72359',
    city: 'Dotternhausen'
  },
  {
    postalCode: '72361',
    city: 'Hausen'
  },
  {
    postalCode: '72362',
    city: 'Nusplingen'
  },
  {
    postalCode: '72364',
    city: 'Obernheim'
  },
  {
    postalCode: '72365',
    city: 'Ratshausen'
  },
  {
    postalCode: '72367',
    city: 'Weilen'
  },
  {
    postalCode: '72369',
    city: 'Zimmern'
  },
  {
    postalCode: '72379',
    city: 'Burg Hohenzollern'
  },
  {
    postalCode: '72379',
    city: 'Hechingen'
  },
  {
    postalCode: '72393',
    city: 'Burladingen'
  },
  {
    postalCode: '72401',
    city: 'Haigerloch'
  },
  {
    postalCode: '72406',
    city: 'Bisingen'
  },
  {
    postalCode: '72411',
    city: 'Bodelshausen'
  },
  {
    postalCode: '72414',
    city: 'Rangendingen'
  },
  {
    postalCode: '72415',
    city: 'Grosselfingen'
  },
  {
    postalCode: '72417',
    city: 'Jungingen'
  },
  {
    postalCode: '72419',
    city: 'Lieshöfe'
  },
  {
    postalCode: '72419',
    city: 'Neufra'
  },
  {
    postalCode: '72419',
    city: 'Stollbeck'
  },
  {
    postalCode: '72458',
    city: 'Albstadt'
  },
  {
    postalCode: '72458',
    city: 'Linderhof'
  },
  {
    postalCode: '72459',
    city: 'Albstadt'
  },
  {
    postalCode: '72461',
    city: 'Albstadt'
  },
  {
    postalCode: '72469',
    city: 'Meßstetten'
  },
  {
    postalCode: '72474',
    city: 'Winterlingen'
  },
  {
    postalCode: '72475',
    city: 'Bitz'
  },
  {
    postalCode: '72477',
    city: 'Schwenningen'
  },
  {
    postalCode: '72479',
    city: 'Straßberg'
  },
  {
    postalCode: '72488',
    city: 'Sigmaringen'
  },
  {
    postalCode: '72501',
    city: 'Gammertingen'
  },
  {
    postalCode: '72501',
    city: 'Pistre'
  },
  {
    postalCode: '72505',
    city: 'Krauchenwies'
  },
  {
    postalCode: '72510',
    city: 'Stetten'
  },
  {
    postalCode: '72511',
    city: 'Bingen'
  },
  {
    postalCode: '72513',
    city: 'Hettingen'
  },
  {
    postalCode: '72514',
    city: 'Inzigkofen'
  },
  {
    postalCode: '72516',
    city: 'Scheer'
  },
  {
    postalCode: '72517',
    city: 'Sigmaringendorf'
  },
  {
    postalCode: '72519',
    city: 'Veringenstadt'
  },
  {
    postalCode: '72525',
    city: 'Gutsbezirk Münsingen'
  },
  {
    postalCode: '72525',
    city: 'Münsingen'
  },
  {
    postalCode: '72531',
    city: 'Hohenstein'
  },
  {
    postalCode: '72532',
    city: 'Gomadingen'
  },
  {
    postalCode: '72534',
    city: 'Hayingen'
  },
  {
    postalCode: '72535',
    city: 'Heroldstatt'
  },
  {
    postalCode: '72537',
    city: 'Mehrstetten'
  },
  {
    postalCode: '72539',
    city: 'Pfronstetten'
  },
  {
    postalCode: '72555',
    city: 'Metzingen'
  },
  {
    postalCode: '72574',
    city: 'Bad Urach'
  },
  {
    postalCode: '72581',
    city: 'Dettingen'
  },
  {
    postalCode: '72582',
    city: 'Grabenstetten'
  },
  {
    postalCode: '72584',
    city: 'Hülben'
  },
  {
    postalCode: '72585',
    city: 'Riederich'
  },
  {
    postalCode: '72587',
    city: 'Römerstein'
  },
  {
    postalCode: '72589',
    city: 'Westerheim'
  },
  {
    postalCode: '72622',
    city: 'Nürtingen'
  },
  {
    postalCode: '72631',
    city: 'Aichtal'
  },
  {
    postalCode: '72636',
    city: 'Frickenhausen'
  },
  {
    postalCode: '72639',
    city: 'Neuffen'
  },
  {
    postalCode: '72644',
    city: 'Oberboihingen'
  },
  {
    postalCode: '72649',
    city: 'Wolfschlugen'
  },
  {
    postalCode: '72654',
    city: 'Neckartenzlingen'
  },
  {
    postalCode: '72655',
    city: 'Altdorf'
  },
  {
    postalCode: '72657',
    city: 'Altenriet'
  },
  {
    postalCode: '72658',
    city: 'Bempflingen'
  },
  {
    postalCode: '72660',
    city: 'Beuren'
  },
  {
    postalCode: '72661',
    city: 'Grafenberg'
  },
  {
    postalCode: '72663',
    city: 'Großbettlingen'
  },
  {
    postalCode: '72664',
    city: 'Kohlberg'
  },
  {
    postalCode: '72666',
    city: 'Neckartailfingen'
  },
  {
    postalCode: '72667',
    city: 'Schlaitdorf'
  },
  {
    postalCode: '72669',
    city: 'Unterensingen'
  },
  {
    postalCode: '72760',
    city: 'Reutlingen'
  },
  {
    postalCode: '72762',
    city: 'Reutlingen'
  },
  {
    postalCode: '72764',
    city: 'Reutlingen'
  },
  {
    postalCode: '72766',
    city: 'Reutlingen'
  },
  {
    postalCode: '72768',
    city: 'Reutlingen'
  },
  {
    postalCode: '72770',
    city: 'Reutlingen'
  },
  {
    postalCode: '72793',
    city: 'Pfullingen'
  },
  {
    postalCode: '72800',
    city: 'Eningen'
  },
  {
    postalCode: '72805',
    city: 'Lichtenstein'
  },
  {
    postalCode: '72810',
    city: 'Gomaringen'
  },
  {
    postalCode: '72813',
    city: 'Oberer Lindenhof'
  },
  {
    postalCode: '72813',
    city: 'St. Johann'
  },
  {
    postalCode: '72818',
    city: 'Trochtelfingen'
  },
  {
    postalCode: '72820',
    city: 'Sonnenbühl'
  },
  {
    postalCode: '72827',
    city: 'Wannweil'
  },
  {
    postalCode: '72829',
    city: 'Engstingen'
  },
  {
    postalCode: '73033',
    city: 'Göppingen'
  },
  {
    postalCode: '73035',
    city: 'Göppingen'
  },
  {
    postalCode: '73037',
    city: 'Eitleshof'
  },
  {
    postalCode: '73037',
    city: 'Göppingen'
  },
  {
    postalCode: '73037',
    city: 'Iltishof'
  },
  {
    postalCode: '73054',
    city: 'Eislingen'
  },
  {
    postalCode: '73054',
    city: 'Näherhof'
  },
  {
    postalCode: '73061',
    city: 'Ebersbach'
  },
  {
    postalCode: '73066',
    city: 'Uhingen'
  },
  {
    postalCode: '73072',
    city: 'Donzdorf'
  },
  {
    postalCode: '73072',
    city: 'Frauenholz'
  },
  {
    postalCode: '73072',
    city: 'Kratzerhöfle'
  },
  {
    postalCode: '73072',
    city: 'Schurrenhof'
  },
  {
    postalCode: '73079',
    city: 'Baierhof'
  },
  {
    postalCode: '73079',
    city: 'Süßen'
  },
  {
    postalCode: '73084',
    city: 'Salach'
  },
  {
    postalCode: '73087',
    city: 'Boll'
  },
  {
    postalCode: '73087',
    city: 'Erlenwasen'
  },
  {
    postalCode: '73092',
    city: 'Heiningen'
  },
  {
    postalCode: '73095',
    city: 'Albershausen'
  },
  {
    postalCode: '73098',
    city: 'Rechberghausen'
  },
  {
    postalCode: '73099',
    city: 'Adelberg'
  },
  {
    postalCode: '73101',
    city: 'Aichelberg'
  },
  {
    postalCode: '73102',
    city: 'Birenbach'
  },
  {
    postalCode: '73104',
    city: 'Börtlingen'
  },
  {
    postalCode: '73105',
    city: 'Dürnau'
  },
  {
    postalCode: '73107',
    city: 'Eschenbach'
  },
  {
    postalCode: '73107',
    city: 'Haagwiesen'
  },
  {
    postalCode: '73107',
    city: 'Lotenberg'
  },
  {
    postalCode: '73108',
    city: 'Gammelshausen'
  },
  {
    postalCode: '73110',
    city: 'Hattenhofen'
  },
  {
    postalCode: '73111',
    city: 'Lauterstein'
  },
  {
    postalCode: '73113',
    city: 'Oberer Etzberg'
  },
  {
    postalCode: '73113',
    city: 'Ottenbach'
  },
  {
    postalCode: '73114',
    city: 'Schlat'
  },
  {
    postalCode: '73116',
    city: 'Krettenhof'
  },
  {
    postalCode: '73116',
    city: 'Schützenhof'
  },
  {
    postalCode: '73116',
    city: 'Wäschenbeuren'
  },
  {
    postalCode: '73117',
    city: 'Wangen'
  },
  {
    postalCode: '73119',
    city: 'Zell'
  },
  {
    postalCode: '73207',
    city: 'Plochingen'
  },
  {
    postalCode: '73230',
    city: 'Kirchheim'
  },
  {
    postalCode: '73235',
    city: 'Kaltenwanghof'
  },
  {
    postalCode: '73235',
    city: 'Weilheim'
  },
  {
    postalCode: '73240',
    city: 'Wendlingen'
  },
  {
    postalCode: '73249',
    city: 'Berghof'
  },
  {
    postalCode: '73249',
    city: 'Erlenhof'
  },
  {
    postalCode: '73249',
    city: 'Wernau'
  },
  {
    postalCode: '73252',
    city: 'Lenningen'
  },
  {
    postalCode: '73257',
    city: 'Köngen'
  },
  {
    postalCode: '73262',
    city: 'Reichenbach'
  },
  {
    postalCode: '73265',
    city: 'Dettingen'
  },
  {
    postalCode: '73266',
    city: 'Bissingen'
  },
  {
    postalCode: '73266',
    city: 'Diepoldsburg'
  },
  {
    postalCode: '73266',
    city: 'Engelhof'
  },
  {
    postalCode: '73266',
    city: 'Torfgrube'
  },
  {
    postalCode: '73268',
    city: 'Erkenbrechtsweiler'
  },
  {
    postalCode: '73269',
    city: 'Hochdorf'
  },
  {
    postalCode: '73271',
    city: 'Holzmaden'
  },
  {
    postalCode: '73272',
    city: 'Neidlingen'
  },
  {
    postalCode: '73274',
    city: 'Notzingen'
  },
  {
    postalCode: '73275',
    city: 'Ohmden'
  },
  {
    postalCode: '73277',
    city: 'Owen'
  },
  {
    postalCode: '73312',
    city: 'Berneck'
  },
  {
    postalCode: '73312',
    city: 'Geislingen'
  },
  {
    postalCode: '73312',
    city: 'Obere Roggenmühle'
  },
  {
    postalCode: '73312',
    city: 'Schonterhöhe'
  },
  {
    postalCode: '73326',
    city: 'Deggingen'
  },
  {
    postalCode: '73329',
    city: 'Kuchen'
  },
  {
    postalCode: '73333',
    city: 'Gingen'
  },
  {
    postalCode: '73337',
    city: 'Bad Überkingen'
  },
  {
    postalCode: '73340',
    city: 'Amstetten'
  },
  {
    postalCode: '73342',
    city: 'Bad Ditzenbach'
  },
  {
    postalCode: '73344',
    city: 'Gruibingen'
  },
  {
    postalCode: '73345',
    city: 'Drackenstein'
  },
  {
    postalCode: '73345',
    city: 'Geislinger Weg'
  },
  {
    postalCode: '73345',
    city: 'Großmannshof'
  },
  {
    postalCode: '73345',
    city: 'Hohenstadt'
  },
  {
    postalCode: '73347',
    city: 'Mühlhausen'
  },
  {
    postalCode: '73349',
    city: 'Eselhöfe'
  },
  {
    postalCode: '73349',
    city: 'Kölleshof'
  },
  {
    postalCode: '73349',
    city: 'Todsburg'
  },
  {
    postalCode: '73349',
    city: 'Wiesensteig'
  },
  {
    postalCode: '73430',
    city: 'Aalen'
  },
  {
    postalCode: '73431',
    city: 'Aalen'
  },
  {
    postalCode: '73432',
    city: 'Aalen'
  },
  {
    postalCode: '73433',
    city: 'Aalen'
  },
  {
    postalCode: '73434',
    city: 'Aalen'
  },
  {
    postalCode: '73434',
    city: 'Untersiegenbühl'
  },
  {
    postalCode: '73441',
    city: 'Bopfingen'
  },
  {
    postalCode: '73441',
    city: 'Heerhof'
  },
  {
    postalCode: '73441',
    city: 'Hohenlohe'
  },
  {
    postalCode: '73441',
    city: 'Osterholz'
  },
  {
    postalCode: '73447',
    city: 'Oberkochen'
  },
  {
    postalCode: '73450',
    city: 'Hochstatter Hof'
  },
  {
    postalCode: '73450',
    city: 'Neresheim'
  },
  {
    postalCode: '73453',
    city: 'Abtsgmünd'
  },
  {
    postalCode: '73453',
    city: 'Algishofen'
  },
  {
    postalCode: '73453',
    city: 'Brandhof'
  },
  {
    postalCode: '73453',
    city: 'Fach'
  },
  {
    postalCode: '73453',
    city: 'Fuchshäusle'
  },
  {
    postalCode: '73453',
    city: 'Langenhalde'
  },
  {
    postalCode: '73453',
    city: 'Mühlholz'
  },
  {
    postalCode: '73453',
    city: 'Obersiegenbühl'
  },
  {
    postalCode: '73453',
    city: 'Rötenbach'
  },
  {
    postalCode: '73453',
    city: 'Schlauchhof'
  },
  {
    postalCode: '73453',
    city: 'Schleifhäusle'
  },
  {
    postalCode: '73453',
    city: 'Suhhaus'
  },
  {
    postalCode: '73453',
    city: 'Wahlenhalden'
  },
  {
    postalCode: '73453',
    city: 'Zanken'
  },
  {
    postalCode: '73457',
    city: 'Essingen'
  },
  {
    postalCode: '73460',
    city: 'Hüttlingen'
  },
  {
    postalCode: '73463',
    city: 'Westhausen'
  },
  {
    postalCode: '73466',
    city: 'Lauchheim'
  },
  {
    postalCode: '73467',
    city: 'Kirchheim'
  },
  {
    postalCode: '73469',
    city: 'Riesbürg'
  },
  {
    postalCode: '73479',
    city: 'Adlersteige'
  },
  {
    postalCode: '73479',
    city: 'Ellwangen'
  },
  {
    postalCode: '73485',
    city: 'Ellrichsbronn'
  },
  {
    postalCode: '73485',
    city: 'Unterschneidheim'
  },
  {
    postalCode: '73486',
    city: 'Adelmannsfelden'
  },
  {
    postalCode: '73488',
    city: 'Ellenberg'
  },
  {
    postalCode: '73489',
    city: 'Grunbachsägmühle'
  },
  {
    postalCode: '73489',
    city: 'Henkensägmühle'
  },
  {
    postalCode: '73489',
    city: 'Jagstzell'
  },
  {
    postalCode: '73489',
    city: 'Ölmühle'
  },
  {
    postalCode: '73489',
    city: 'Reifenhof'
  },
  {
    postalCode: '73489',
    city: 'Reifensägmühle'
  },
  {
    postalCode: '73489',
    city: 'Sperrhof'
  },
  {
    postalCode: '73491',
    city: 'Neuler'
  },
  {
    postalCode: '73492',
    city: 'Rainau'
  },
  {
    postalCode: '73494',
    city: 'Belzhof'
  },
  {
    postalCode: '73494',
    city: 'Fleckenbacher Sägmühle'
  },
  {
    postalCode: '73494',
    city: 'Gauchshausen'
  },
  {
    postalCode: '73494',
    city: 'Rosenberg'
  },
  {
    postalCode: '73495',
    city: 'Stödtlen'
  },
  {
    postalCode: '73497',
    city: 'Tannhausen'
  },
  {
    postalCode: '73499',
    city: 'Wört'
  },
  {
    postalCode: '73525',
    city: 'Schwäbisch Gmünd'
  },
  {
    postalCode: '73527',
    city: 'Schwäbisch Gmünd'
  },
  {
    postalCode: '73527',
    city: 'Täferrot'
  },
  {
    postalCode: '73529',
    city: 'Bärenhof'
  },
  {
    postalCode: '73529',
    city: 'Pfeilhalde'
  },
  {
    postalCode: '73529',
    city: 'Saurenhof'
  },
  {
    postalCode: '73529',
    city: 'Schwäbisch Gmünd'
  },
  {
    postalCode: '73540',
    city: 'Heubach'
  },
  {
    postalCode: '73547',
    city: 'Beutenmühle'
  },
  {
    postalCode: '73547',
    city: 'Lorch'
  },
  {
    postalCode: '73550',
    city: 'Hummelshalden'
  },
  {
    postalCode: '73550',
    city: 'Waldstetten'
  },
  {
    postalCode: '73553',
    city: 'Alfdorf'
  },
  {
    postalCode: '73553',
    city: 'Birkhof'
  },
  {
    postalCode: '73553',
    city: 'Haselbach-Söldhaus'
  },
  {
    postalCode: '73553',
    city: 'Ölmühle'
  },
  {
    postalCode: '73553',
    city: 'Schillinghof'
  },
  {
    postalCode: '73553',
    city: 'Voggenmühlhöfle'
  },
  {
    postalCode: '73557',
    city: 'Mutlangen'
  },
  {
    postalCode: '73560',
    city: 'Böbingen'
  },
  {
    postalCode: '73563',
    city: 'Mögglingen'
  },
  {
    postalCode: '73563',
    city: 'Sixenhof'
  },
  {
    postalCode: '73565',
    city: 'Mooswiese'
  },
  {
    postalCode: '73565',
    city: 'Spraitbach'
  },
  {
    postalCode: '73565',
    city: 'Stutzenklinge'
  },
  {
    postalCode: '73566',
    city: 'Bartholomä'
  },
  {
    postalCode: '73566',
    city: 'Bibersohl'
  },
  {
    postalCode: '73566',
    city: 'Birkenteich'
  },
  {
    postalCode: '73566',
    city: 'Irmannsweiler'
  },
  {
    postalCode: '73568',
    city: 'Durlangen'
  },
  {
    postalCode: '73568',
    city: 'Leinhäusle'
  },
  {
    postalCode: '73568',
    city: 'Weggen-Ziegelhütte'
  },
  {
    postalCode: '73569',
    city: 'Bräunlesrain'
  },
  {
    postalCode: '73569',
    city: 'Buchhof'
  },
  {
    postalCode: '73569',
    city: 'Eschach'
  },
  {
    postalCode: '73569',
    city: 'Obergröningen'
  },
  {
    postalCode: '73569',
    city: 'Reute'
  },
  {
    postalCode: '73571',
    city: 'Göggingen'
  },
  {
    postalCode: '73572',
    city: 'Heuchlingen'
  },
  {
    postalCode: '73574',
    city: 'Iggingen'
  },
  {
    postalCode: '73574',
    city: 'Mühlhölzle'
  },
  {
    postalCode: '73574',
    city: 'Pfaffenhäusle'
  },
  {
    postalCode: '73575',
    city: 'Horn'
  },
  {
    postalCode: '73575',
    city: 'Horner Mühle'
  },
  {
    postalCode: '73575',
    city: 'Kleemeisterei'
  },
  {
    postalCode: '73575',
    city: 'Leinzell'
  },
  {
    postalCode: '73575',
    city: 'Mulfingen'
  },
  {
    postalCode: '73577',
    city: 'Buchhof'
  },
  {
    postalCode: '73577',
    city: 'Heiligenbruck'
  },
  {
    postalCode: '73577',
    city: 'Koppenkreut'
  },
  {
    postalCode: '73577',
    city: 'Ruppertshofen'
  },
  {
    postalCode: '73577',
    city: 'Schilpenbühl'
  },
  {
    postalCode: '73577',
    city: 'Utzstetten'
  },
  {
    postalCode: '73579',
    city: 'Schechingen'
  },
  {
    postalCode: '73614',
    city: 'Schorndorf'
  },
  {
    postalCode: '73614',
    city: 'Wellingshof'
  },
  {
    postalCode: '73630',
    city: 'Remshalden'
  },
  {
    postalCode: '73635',
    city: 'Obersteinenberg'
  },
  {
    postalCode: '73635',
    city: 'Rudersberg'
  },
  {
    postalCode: '73642',
    city: 'Eibenhof'
  },
  {
    postalCode: '73642',
    city: 'Klingenmühlhöfle'
  },
  {
    postalCode: '73642',
    city: 'Köshof'
  },
  {
    postalCode: '73642',
    city: 'Schautenhof'
  },
  {
    postalCode: '73642',
    city: 'Welzheim'
  },
  {
    postalCode: '73650',
    city: 'Winterbach'
  },
  {
    postalCode: '73655',
    city: 'Bärenbach'
  },
  {
    postalCode: '73655',
    city: 'Eulenhof'
  },
  {
    postalCode: '73655',
    city: 'Haldenhof'
  },
  {
    postalCode: '73655',
    city: 'Haselhof'
  },
  {
    postalCode: '73655',
    city: 'Ilgenhof'
  },
  {
    postalCode: '73655',
    city: 'Pfahlbronner Mühle'
  },
  {
    postalCode: '73655',
    city: 'Plüderhausen'
  },
  {
    postalCode: '73655',
    city: 'Schenkhöfle'
  },
  {
    postalCode: '73660',
    city: 'Urbach'
  },
  {
    postalCode: '73663',
    city: 'Berglen'
  },
  {
    postalCode: '73666',
    city: 'Baltmannsweiler'
  },
  {
    postalCode: '73667',
    city: 'Ebnisee'
  },
  {
    postalCode: '73667',
    city: 'Kaisersbach'
  },
  {
    postalCode: '73669',
    city: 'Lichtenwald'
  },
  {
    postalCode: '73728',
    city: 'Esslingen'
  },
  {
    postalCode: '73730',
    city: 'Esslingen'
  },
  {
    postalCode: '73732',
    city: 'Esslingen'
  },
  {
    postalCode: '73733',
    city: 'Esslingen'
  },
  {
    postalCode: '73734',
    city: 'Esslingen'
  },
  {
    postalCode: '73760',
    city: 'Ostfildern'
  },
  {
    postalCode: '73765',
    city: 'Neuhausen'
  },
  {
    postalCode: '73770',
    city: 'Denkendorf'
  },
  {
    postalCode: '73773',
    city: 'Aichwald'
  },
  {
    postalCode: '73776',
    city: 'Altbach'
  },
  {
    postalCode: '73779',
    city: 'Deizisau'
  },
  {
    postalCode: '74072',
    city: 'Heilbronn'
  },
  {
    postalCode: '74074',
    city: 'Heilbronn'
  },
  {
    postalCode: '74076',
    city: 'Heilbronn'
  },
  {
    postalCode: '74078',
    city: 'Heilbronn'
  },
  {
    postalCode: '74080',
    city: 'Heilbronn'
  },
  {
    postalCode: '74081',
    city: 'Heilbronn'
  },
  {
    postalCode: '74172',
    city: 'Neckarsulm'
  },
  {
    postalCode: '74177',
    city: 'Bad Friedrichshall'
  },
  {
    postalCode: '74182',
    city: 'Obersulm'
  },
  {
    postalCode: '74189',
    city: 'Weinsberg'
  },
  {
    postalCode: '74193',
    city: 'Schwaigern'
  },
  {
    postalCode: '74196',
    city: 'Grollenhof'
  },
  {
    postalCode: '74196',
    city: 'Neuenstadt'
  },
  {
    postalCode: '74199',
    city: 'Untergruppenbach'
  },
  {
    postalCode: '74206',
    city: 'Bad Wimpfen'
  },
  {
    postalCode: '74211',
    city: 'Leingarten'
  },
  {
    postalCode: '74214',
    city: 'Schöntal'
  },
  {
    postalCode: '74219',
    city: 'Möckmühl'
  },
  {
    postalCode: '74223',
    city: 'Flein'
  },
  {
    postalCode: '74223',
    city: 'Talheimer Hof'
  },
  {
    postalCode: '74226',
    city: 'Nordheim'
  },
  {
    postalCode: '74229',
    city: 'Oedheim'
  },
  {
    postalCode: '74232',
    city: 'Abstatt'
  },
  {
    postalCode: '74235',
    city: 'Erlenbach'
  },
  {
    postalCode: '74238',
    city: 'Krautheim'
  },
  {
    postalCode: '74239',
    city: 'Hardthausen'
  },
  {
    postalCode: '74243',
    city: 'Langenbrettach'
  },
  {
    postalCode: '74245',
    city: 'Löwenstein'
  },
  {
    postalCode: '74246',
    city: 'Eberstadt'
  },
  {
    postalCode: '74248',
    city: 'Ellhofen'
  },
  {
    postalCode: '74249',
    city: 'Buchhof, Gem Hardthausen'
  },
  {
    postalCode: '74249',
    city: 'Buchhof, Gem Öhringen'
  },
  {
    postalCode: '74249',
    city: 'Jagsthausen'
  },
  {
    postalCode: '74249',
    city: 'Trautenhof'
  },
  {
    postalCode: '74251',
    city: 'Lehrensteinsfeld'
  },
  {
    postalCode: '74252',
    city: 'Massenbachhausen'
  },
  {
    postalCode: '74254',
    city: 'Offenau'
  },
  {
    postalCode: '74255',
    city: 'Roigheim'
  },
  {
    postalCode: '74257',
    city: 'Untereisesheim'
  },
  {
    postalCode: '74259',
    city: 'Widdern'
  },
  {
    postalCode: '74321',
    city: 'Bietigheim-Bissingen'
  },
  {
    postalCode: '74336',
    city: 'Brackenheim'
  },
  {
    postalCode: '74343',
    city: 'Sachsenheim'
  },
  {
    postalCode: '74348',
    city: 'Lauffen'
  },
  {
    postalCode: '74354',
    city: 'Besigheim'
  },
  {
    postalCode: '74357',
    city: 'Bellevue'
  },
  {
    postalCode: '74357',
    city: 'Bönnigheim'
  },
  {
    postalCode: '74360',
    city: 'Ilsfeld'
  },
  {
    postalCode: '74360',
    city: 'Pfahlhof'
  },
  {
    postalCode: '74363',
    city: 'Güglingen'
  },
  {
    postalCode: '74366',
    city: 'Kirchheim'
  },
  {
    postalCode: '74369',
    city: 'Löchgau'
  },
  {
    postalCode: '74372',
    city: 'Sersheim'
  },
  {
    postalCode: '74374',
    city: 'Zaberfeld'
  },
  {
    postalCode: '74376',
    city: 'Gemmrigheim'
  },
  {
    postalCode: '74379',
    city: 'Ingersheim'
  },
  {
    postalCode: '74382',
    city: 'Neckarwestheim'
  },
  {
    postalCode: '74385',
    city: 'Pleidelsheim'
  },
  {
    postalCode: '74388',
    city: 'Talheim'
  },
  {
    postalCode: '74389',
    city: 'Cleebronn'
  },
  {
    postalCode: '74391',
    city: 'Erligheim'
  },
  {
    postalCode: '74392',
    city: 'Freudental'
  },
  {
    postalCode: '74394',
    city: 'Hessigheim'
  },
  {
    postalCode: '74394',
    city: 'Schreyerhof'
  },
  {
    postalCode: '74395',
    city: 'Mundelsheim'
  },
  {
    postalCode: '74397',
    city: 'Pfaffenhofen'
  },
  {
    postalCode: '74399',
    city: 'Walheim'
  },
  {
    postalCode: '74405',
    city: 'Gaildorf'
  },
  {
    postalCode: '74417',
    city: 'Gschwend'
  },
  {
    postalCode: '74417',
    city: 'Höllhof'
  },
  {
    postalCode: '74417',
    city: 'Jakobsberg'
  },
  {
    postalCode: '74420',
    city: 'Oberrot'
  },
  {
    postalCode: '74423',
    city: 'Obersontheim'
  },
  {
    postalCode: '74424',
    city: 'Bühlertann'
  },
  {
    postalCode: '74426',
    city: 'Bühlerzell'
  },
  {
    postalCode: '74427',
    city: 'Fichtenberg'
  },
  {
    postalCode: '74429',
    city: 'Sulzbach-Laufen'
  },
  {
    postalCode: '74523',
    city: 'Bühlerzimmern'
  },
  {
    postalCode: '74523',
    city: 'Burgbretzingen'
  },
  {
    postalCode: '74523',
    city: 'Einkorn'
  },
  {
    postalCode: '74523',
    city: 'Scherbenmühle'
  },
  {
    postalCode: '74523',
    city: 'Schwäbisch Hall'
  },
  {
    postalCode: '74523',
    city: 'Traubenmühle'
  },
  {
    postalCode: '74532',
    city: 'Buch'
  },
  {
    postalCode: '74532',
    city: 'Ilshofen'
  },
  {
    postalCode: '74532',
    city: 'Landturm'
  },
  {
    postalCode: '74535',
    city: 'Mainhardt'
  },
  {
    postalCode: '74538',
    city: 'Rosengarten'
  },
  {
    postalCode: '74541',
    city: 'Vellberg'
  },
  {
    postalCode: '74542',
    city: 'Braunsbach'
  },
  {
    postalCode: '74544',
    city: 'Michelbach an der Bilz'
  },
  {
    postalCode: '74545',
    city: 'Hinterziegelhalden'
  },
  {
    postalCode: '74545',
    city: 'Michelfeld'
  },
  {
    postalCode: '74547',
    city: 'Untermünkheim'
  },
  {
    postalCode: '74549',
    city: 'Wolpertshausen'
  },
  {
    postalCode: '74564',
    city: 'Auhof'
  },
  {
    postalCode: '74564',
    city: 'Crailsheim'
  },
  {
    postalCode: '74564',
    city: 'Heldenmühle'
  },
  {
    postalCode: '74564',
    city: 'Schummhof'
  },
  {
    postalCode: '74572',
    city: 'Blaufelden'
  },
  {
    postalCode: '74575',
    city: 'Schrozberg'
  },
  {
    postalCode: '74579',
    city: 'Buchmühle'
  },
  {
    postalCode: '74579',
    city: 'Fichtenau'
  },
  {
    postalCode: '74579',
    city: 'Finkenberg'
  },
  {
    postalCode: '74579',
    city: 'Finkenhaus'
  },
  {
    postalCode: '74579',
    city: 'Hahnenmühle'
  },
  {
    postalCode: '74579',
    city: 'Neumühle'
  },
  {
    postalCode: '74579',
    city: 'Ratzensägmühle'
  },
  {
    postalCode: '74582',
    city: 'Gerabronn'
  },
  {
    postalCode: '74585',
    city: 'Horschhof'
  },
  {
    postalCode: '74585',
    city: 'Rot am See'
  },
  {
    postalCode: '74586',
    city: 'Frankenhardt'
  },
  {
    postalCode: '74589',
    city: 'Satteldorf'
  },
  {
    postalCode: '74592',
    city: 'Kirchberg'
  },
  {
    postalCode: '74594',
    city: 'Gumpenweiler'
  },
  {
    postalCode: '74594',
    city: 'Kreßberg'
  },
  {
    postalCode: '74595',
    city: 'Langenburg'
  },
  {
    postalCode: '74597',
    city: 'Appensee'
  },
  {
    postalCode: '74597',
    city: 'Eckarrot'
  },
  {
    postalCode: '74597',
    city: 'Eulenmühle'
  },
  {
    postalCode: '74597',
    city: 'Mittelmühle'
  },
  {
    postalCode: '74597',
    city: 'Riegersheim'
  },
  {
    postalCode: '74597',
    city: 'Stimpfach'
  },
  {
    postalCode: '74599',
    city: 'Wallhausen'
  },
  {
    postalCode: '74613',
    city: 'Öhringen'
  },
  {
    postalCode: '74626',
    city: 'Bretzfeld'
  },
  {
    postalCode: '74629',
    city: 'Pfedelbach'
  },
  {
    postalCode: '74632',
    city: 'Haberhof'
  },
  {
    postalCode: '74632',
    city: 'Neuenstein'
  },
  {
    postalCode: '74632',
    city: 'Orbachshof'
  },
  {
    postalCode: '74635',
    city: 'Kupferzell'
  },
  {
    postalCode: '74638',
    city: 'Waldenburg'
  },
  {
    postalCode: '74639',
    city: 'Schießhof'
  },
  {
    postalCode: '74639',
    city: 'Zweiflingen'
  },
  {
    postalCode: '74653',
    city: 'Ingelfingen'
  },
  {
    postalCode: '74653',
    city: 'Künzelsau'
  },
  {
    postalCode: '74670',
    city: 'Forchtenberg'
  },
  {
    postalCode: '74673',
    city: 'Mulfingen'
  },
  {
    postalCode: '74676',
    city: 'Niedernhall'
  },
  {
    postalCode: '74677',
    city: 'Dörzbach'
  },
  {
    postalCode: '74679',
    city: 'Weißbach'
  },
  {
    postalCode: '74706',
    city: 'Osterburken'
  },
  {
    postalCode: '74722',
    city: 'Buchen'
  },
  {
    postalCode: '74731',
    city: 'Storchhof'
  },
  {
    postalCode: '74731',
    city: 'Walldürn'
  },
  {
    postalCode: '74736',
    city: 'Hardheim'
  },
  {
    postalCode: '74740',
    city: 'Adelsheim'
  },
  {
    postalCode: '74743',
    city: 'Seckach'
  },
  {
    postalCode: '74744',
    city: 'Ahorn'
  },
  {
    postalCode: '74746',
    city: 'Höpfingen'
  },
  {
    postalCode: '74747',
    city: 'Ravenstein'
  },
  {
    postalCode: '74749',
    city: 'Rosenberg'
  },
  {
    postalCode: '74821',
    city: 'Mosbach'
  },
  {
    postalCode: '74831',
    city: 'Gundelsheim'
  },
  {
    postalCode: '74834',
    city: 'Elztal'
  },
  {
    postalCode: '74834',
    city: 'Heidersbacher Mühle'
  },
  {
    postalCode: '74838',
    city: 'Limbach'
  },
  {
    postalCode: '74842',
    city: 'Billigheim'
  },
  {
    postalCode: '74847',
    city: 'Obrigheim'
  },
  {
    postalCode: '74850',
    city: 'Schefflenz'
  },
  {
    postalCode: '74855',
    city: 'Haßmersheim'
  },
  {
    postalCode: '74858',
    city: 'Aglasterhausen'
  },
  {
    postalCode: '74861',
    city: 'Neudenau'
  },
  {
    postalCode: '74862',
    city: 'Binau'
  },
  {
    postalCode: '74864',
    city: 'Fahrenbach'
  },
  {
    postalCode: '74865',
    city: 'Neckarzimmern'
  },
  {
    postalCode: '74867',
    city: 'Neunkirchen'
  },
  {
    postalCode: '74869',
    city: 'Schwarzach'
  },
  {
    postalCode: '74889',
    city: 'Sinsheim'
  },
  {
    postalCode: '74906',
    city: 'Bad Rappenau'
  },
  {
    postalCode: '74909',
    city: 'Meckesheim'
  },
  {
    postalCode: '74912',
    city: 'Kirchardt'
  },
  {
    postalCode: '74915',
    city: 'Waibstadt'
  },
  {
    postalCode: '74918',
    city: 'Angelbachtal'
  },
  {
    postalCode: '74921',
    city: 'Helmstadt-Bargen'
  },
  {
    postalCode: '74924',
    city: 'Neckarbischofsheim'
  },
  {
    postalCode: '74925',
    city: 'Epfenbach'
  },
  {
    postalCode: '74927',
    city: 'Eschelbronn'
  },
  {
    postalCode: '74928',
    city: 'Hüffenhardt'
  },
  {
    postalCode: '74930',
    city: 'Ittlingen'
  },
  {
    postalCode: '74931',
    city: 'Lobbach'
  },
  {
    postalCode: '74933',
    city: 'Neidenstein'
  },
  {
    postalCode: '74934',
    city: 'Reichartshausen'
  },
  {
    postalCode: '74936',
    city: 'Siegelsbach'
  },
  {
    postalCode: '74937',
    city: 'Spechbach'
  },
  {
    postalCode: '74939',
    city: 'Zuzenhausen'
  },
  {
    postalCode: '75015',
    city: 'Bretten'
  },
  {
    postalCode: '75031',
    city: 'Eppingen'
  },
  {
    postalCode: '75031',
    city: 'Gießhübelmühle'
  },
  {
    postalCode: '75031',
    city: 'Neuhof'
  },
  {
    postalCode: '75038',
    city: 'Oberderdingen'
  },
  {
    postalCode: '75045',
    city: 'Walzbachtal'
  },
  {
    postalCode: '75050',
    city: 'Gemmingen'
  },
  {
    postalCode: '75053',
    city: 'Gondelsheim'
  },
  {
    postalCode: '75056',
    city: 'Sulzfeld'
  },
  {
    postalCode: '75057',
    city: 'Kürnbach'
  },
  {
    postalCode: '75059',
    city: 'Egonmühle'
  },
  {
    postalCode: '75059',
    city: 'Zaisenhausen'
  },
  {
    postalCode: '75172',
    city: 'Pforzheim'
  },
  {
    postalCode: '75173',
    city: 'Pforzheim'
  },
  {
    postalCode: '75175',
    city: 'Pforzheim'
  },
  {
    postalCode: '75177',
    city: 'Katharinenthalerhof'
  },
  {
    postalCode: '75177',
    city: 'Pforzheim'
  },
  {
    postalCode: '75179',
    city: 'Pforzheim'
  },
  {
    postalCode: '75180',
    city: 'Pforzheim'
  },
  {
    postalCode: '75181',
    city: 'Pforzheim'
  },
  {
    postalCode: '75196',
    city: 'Remchingen'
  },
  {
    postalCode: '75203',
    city: 'Königsbach-Stein'
  },
  {
    postalCode: '75210',
    city: 'Keltern'
  },
  {
    postalCode: '75217',
    city: 'Birkenfeld'
  },
  {
    postalCode: '75223',
    city: 'Niefern-Öschelbronn'
  },
  {
    postalCode: '75228',
    city: 'Ispringen'
  },
  {
    postalCode: '75233',
    city: 'Tiefenbronn'
  },
  {
    postalCode: '75236',
    city: 'Kämpfelbach'
  },
  {
    postalCode: '75239',
    city: 'Eisingen'
  },
  {
    postalCode: '75242',
    city: 'Neuhausen'
  },
  {
    postalCode: '75245',
    city: 'Neulingen'
  },
  {
    postalCode: '75248',
    city: 'Ölbronn-Dürrn'
  },
  {
    postalCode: '75249',
    city: 'Kieselbronn'
  },
  {
    postalCode: '75305',
    city: 'Neuenbürg'
  },
  {
    postalCode: '75323',
    city: 'Bad Wildbad'
  },
  {
    postalCode: '75328',
    city: 'Schömberg'
  },
  {
    postalCode: '75331',
    city: 'Engelsbrand'
  },
  {
    postalCode: '75334',
    city: 'Straubenhardt'
  },
  {
    postalCode: '75335',
    city: 'Dobel'
  },
  {
    postalCode: '75337',
    city: 'Enzklösterle'
  },
  {
    postalCode: '75337',
    city: 'Nonnenmiß'
  },
  {
    postalCode: '75339',
    city: 'Höfen'
  },
  {
    postalCode: '75365',
    city: 'Calw'
  },
  {
    postalCode: '75378',
    city: 'Bad Liebenzell'
  },
  {
    postalCode: '75382',
    city: 'Althengstett'
  },
  {
    postalCode: '75385',
    city: 'Bad Teinach-Zavelstein'
  },
  {
    postalCode: '75385',
    city: 'Dachshof'
  },
  {
    postalCode: '75385',
    city: 'Glasmühle'
  },
  {
    postalCode: '75385',
    city: 'Lautenbachhof'
  },
  {
    postalCode: '75387',
    city: 'Neubulach'
  },
  {
    postalCode: '75389',
    city: 'Neuweiler'
  },
  {
    postalCode: '75391',
    city: 'Gechingen'
  },
  {
    postalCode: '75392',
    city: 'Deckenpfronn'
  },
  {
    postalCode: '75394',
    city: 'Oberreichenbach'
  },
  {
    postalCode: '75395',
    city: 'Ostelsheim'
  },
  {
    postalCode: '75397',
    city: 'Simmozheim'
  },
  {
    postalCode: '75399',
    city: 'Nagoldtal'
  },
  {
    postalCode: '75399',
    city: 'Untere Mühle'
  },
  {
    postalCode: '75399',
    city: 'Unterreichenbach'
  },
  {
    postalCode: '75417',
    city: 'Mühlacker'
  },
  {
    postalCode: '75428',
    city: 'Illingen'
  },
  {
    postalCode: '75433',
    city: 'Maulbronn'
  },
  {
    postalCode: '75438',
    city: 'Knittlingen'
  },
  {
    postalCode: '75443',
    city: 'Ötisheim'
  },
  {
    postalCode: '75446',
    city: 'Wiernsheim'
  },
  {
    postalCode: '75447',
    city: 'Sternenfels'
  },
  {
    postalCode: '75449',
    city: 'Wurmberg'
  },
  {
    postalCode: '76131',
    city: 'Karlsruhe'
  },
  {
    postalCode: '76133',
    city: 'Karlsruhe'
  },
  {
    postalCode: '76135',
    city: 'Karlsruhe'
  },
  {
    postalCode: '76137',
    city: 'Karlsruhe'
  },
  {
    postalCode: '76139',
    city: 'Karlsruhe'
  },
  {
    postalCode: '76149',
    city: 'Karlsruhe'
  },
  {
    postalCode: '76185',
    city: 'Karlsruhe'
  },
  {
    postalCode: '76187',
    city: 'Karlsruhe'
  },
  {
    postalCode: '76189',
    city: 'Karlsruhe'
  },
  {
    postalCode: '76199',
    city: 'Karlsruhe'
  },
  {
    postalCode: '76227',
    city: 'Karlsruhe'
  },
  {
    postalCode: '76228',
    city: 'Karlsruhe'
  },
  {
    postalCode: '76229',
    city: 'Karlsruhe'
  },
  {
    postalCode: '76275',
    city: 'Ettlingen'
  },
  {
    postalCode: '76287',
    city: 'Rheinstetten'
  },
  {
    postalCode: '76297',
    city: 'Stutensee'
  },
  {
    postalCode: '76307',
    city: 'Karlsbad'
  },
  {
    postalCode: '76316',
    city: 'Malsch'
  },
  {
    postalCode: '76327',
    city: 'Pfinztal'
  },
  {
    postalCode: '76332',
    city: 'Bad Herrenalb'
  },
  {
    postalCode: '76332',
    city: 'Plotzsägemühle'
  },
  {
    postalCode: '76337',
    city: 'Waldbronn'
  },
  {
    postalCode: '76344',
    city: 'Eggenstein-Leopoldshafen'
  },
  {
    postalCode: '76351',
    city: 'Linkenheim-Hochstetten'
  },
  {
    postalCode: '76356',
    city: 'Weingarten'
  },
  {
    postalCode: '76356',
    city: 'Werrabronn'
  },
  {
    postalCode: '76359',
    city: 'Fischweier'
  },
  {
    postalCode: '76359',
    city: 'Marxzell'
  },
  {
    postalCode: '76359',
    city: 'Schöllbronner Mühle'
  },
  {
    postalCode: '76437',
    city: 'Rastatt'
  },
  {
    postalCode: '76448',
    city: 'Durmersheim'
  },
  {
    postalCode: '76456',
    city: 'Kuppenheim'
  },
  {
    postalCode: '76461',
    city: 'Muggensturm'
  },
  {
    postalCode: '76467',
    city: 'Bietigheim'
  },
  {
    postalCode: '76470',
    city: 'Ötigheim'
  },
  {
    postalCode: '76473',
    city: 'Iffezheim'
  },
  {
    postalCode: '76474',
    city: 'Au'
  },
  {
    postalCode: '76476',
    city: 'Bischweier'
  },
  {
    postalCode: '76477',
    city: 'Elchesheim-Illingen'
  },
  {
    postalCode: '76479',
    city: 'Steinmauern'
  },
  {
    postalCode: '76530',
    city: 'Baden-Baden'
  },
  {
    postalCode: '76532',
    city: 'Baden-Baden'
  },
  {
    postalCode: '76534',
    city: 'Baden-Baden'
  },
  {
    postalCode: '76547',
    city: 'Sinzheim'
  },
  {
    postalCode: '76549',
    city: 'Hügelsheim'
  },
  {
    postalCode: '76571',
    city: 'Gaggenau'
  },
  {
    postalCode: '76593',
    city: 'Gernsbach'
  },
  {
    postalCode: '76596',
    city: 'Forbach'
  },
  {
    postalCode: '76597',
    city: 'Loffenau'
  },
  {
    postalCode: '76599',
    city: 'Weisenbach'
  },
  {
    postalCode: '76646',
    city: 'Bruchsal'
  },
  {
    postalCode: '76661',
    city: 'Philippsburg'
  },
  {
    postalCode: '76669',
    city: 'Bad Schönborn'
  },
  {
    postalCode: '76676',
    city: 'Graben-Neudorf'
  },
  {
    postalCode: '76684',
    city: 'Östringen'
  },
  {
    postalCode: '76689',
    city: 'Karlsdorf-Neuthard'
  },
  {
    postalCode: '76694',
    city: 'Fasanenhof'
  },
  {
    postalCode: '76694',
    city: 'Forst'
  },
  {
    postalCode: '76698',
    city: 'Ubstadt-Weiher'
  },
  {
    postalCode: '76703',
    city: 'Kraichtal'
  },
  {
    postalCode: '76706',
    city: 'Dettenheim'
  },
  {
    postalCode: '76707',
    city: 'Hambrücken'
  },
  {
    postalCode: '76709',
    city: 'Kronau'
  },
  {
    postalCode: '76726',
    city: 'Altbrand'
  },
  {
    postalCode: '76726',
    city: 'Germersheim'
  },
  {
    postalCode: '76726',
    city: 'Vorwerk Friedrich'
  },
  {
    postalCode: '76744',
    city: 'Höllenmühle'
  },
  {
    postalCode: '76744',
    city: 'Langenberg'
  },
  {
    postalCode: '76744',
    city: 'Vollmersweiler'
  },
  {
    postalCode: '76744',
    city: 'Wörth'
  },
  {
    postalCode: '76751',
    city: 'Jockgrim'
  },
  {
    postalCode: '76756',
    city: 'Bellheim'
  },
  {
    postalCode: '76761',
    city: 'Rülzheim'
  },
  {
    postalCode: '76764',
    city: 'Rheinzabern'
  },
  {
    postalCode: '76767',
    city: 'Hagenbach'
  },
  {
    postalCode: '76767',
    city: 'Neufeldhof'
  },
  {
    postalCode: '76768',
    city: 'Berg'
  },
  {
    postalCode: '76770',
    city: 'Hatzenbühl'
  },
  {
    postalCode: '76771',
    city: 'Hördt'
  },
  {
    postalCode: '76773',
    city: 'Kuhardt'
  },
  {
    postalCode: '76774',
    city: 'Leimersheim'
  },
  {
    postalCode: '76776',
    city: 'Neuburg'
  },
  {
    postalCode: '76777',
    city: 'Neupotz'
  },
  {
    postalCode: '76779',
    city: 'Salmbacher Passage'
  },
  {
    postalCode: '76779',
    city: 'Scheibenhardt'
  },
  {
    postalCode: '76829',
    city: 'Landau'
  },
  {
    postalCode: '76829',
    city: 'Leinsweiler'
  },
  {
    postalCode: '76829',
    city: 'Modenbacherhof'
  },
  {
    postalCode: '76829',
    city: 'Ranschbach'
  },
  {
    postalCode: '76831',
    city: 'Billigheim-Ingenheim'
  },
  {
    postalCode: '76831',
    city: 'Birkweiler'
  },
  {
    postalCode: '76831',
    city: 'Eschbach'
  },
  {
    postalCode: '76831',
    city: 'Göcklingen'
  },
  {
    postalCode: '76831',
    city: 'Heuchelheim-Klingen'
  },
  {
    postalCode: '76831',
    city: 'Ilbesheim'
  },
  {
    postalCode: '76831',
    city: 'Impflingen'
  },
  {
    postalCode: '76831',
    city: 'Ziegelhütte'
  },
  {
    postalCode: '76833',
    city: 'Böchingen'
  },
  {
    postalCode: '76833',
    city: 'Frankweiler'
  },
  {
    postalCode: '76833',
    city: 'Knöringen'
  },
  {
    postalCode: '76833',
    city: 'Siebeldingen'
  },
  {
    postalCode: '76833',
    city: 'Walsheim'
  },
  {
    postalCode: '76835',
    city: 'Burrweiler'
  },
  {
    postalCode: '76835',
    city: 'Flemlingen'
  },
  {
    postalCode: '76835',
    city: 'Gleisweiler'
  },
  {
    postalCode: '76835',
    city: 'Hainfeld'
  },
  {
    postalCode: '76835',
    city: 'Rhodt'
  },
  {
    postalCode: '76835',
    city: 'Roschbach'
  },
  {
    postalCode: '76835',
    city: 'Weyher'
  },
  {
    postalCode: '76835',
    city: 'Wolfseck, Forsthaus'
  },
  {
    postalCode: '76846',
    city: 'Hauenstein'
  },
  {
    postalCode: '76848',
    city: 'Darstein'
  },
  {
    postalCode: '76848',
    city: 'Dimbach'
  },
  {
    postalCode: '76848',
    city: 'Lug'
  },
  {
    postalCode: '76848',
    city: 'Schwanheim'
  },
  {
    postalCode: '76848',
    city: 'Spirkelbach'
  },
  {
    postalCode: '76848',
    city: 'Wilgartswiesen'
  },
  {
    postalCode: '76855',
    city: 'Annweiler'
  },
  {
    postalCode: '76855',
    city: 'Kaisermühle'
  },
  {
    postalCode: '76855',
    city: 'Knochenmühle'
  },
  {
    postalCode: '76857',
    city: 'Albersweiler'
  },
  {
    postalCode: '76857',
    city: 'Dernbach'
  },
  {
    postalCode: '76857',
    city: 'Eußerthal'
  },
  {
    postalCode: '76857',
    city: 'Gossersweiler-Stein'
  },
  {
    postalCode: '76857',
    city: 'Hoecker, Haus'
  },
  {
    postalCode: '76857',
    city: 'Münchweiler'
  },
  {
    postalCode: '76857',
    city: 'Neumühle'
  },
  {
    postalCode: '76857',
    city: 'Ramberg'
  },
  {
    postalCode: '76857',
    city: 'Rinnthal'
  },
  {
    postalCode: '76857',
    city: 'Silz'
  },
  {
    postalCode: '76857',
    city: 'Taubensuhl'
  },
  {
    postalCode: '76857',
    city: 'Völkersweiler'
  },
  {
    postalCode: '76857',
    city: 'Waldeck'
  },
  {
    postalCode: '76857',
    city: 'Waldhambach'
  },
  {
    postalCode: '76857',
    city: 'Waldrohrbach'
  },
  {
    postalCode: '76857',
    city: 'Wernersberg'
  },
  {
    postalCode: '76863',
    city: 'Herxheim'
  },
  {
    postalCode: '76863',
    city: 'Herxheimweyher'
  },
  {
    postalCode: '76865',
    city: 'Insheim'
  },
  {
    postalCode: '76865',
    city: 'Rohrbach'
  },
  {
    postalCode: '76870',
    city: 'Kandel'
  },
  {
    postalCode: '76872',
    city: 'Bruchsiedlung'
  },
  {
    postalCode: '76872',
    city: 'Erlenbach'
  },
  {
    postalCode: '76872',
    city: 'Freckenfeld'
  },
  {
    postalCode: '76872',
    city: 'Hergersweiler'
  },
  {
    postalCode: '76872',
    city: 'Minfeld'
  },
  {
    postalCode: '76872',
    city: 'Steinweiler'
  },
  {
    postalCode: '76872',
    city: 'Winden'
  },
  {
    postalCode: '76877',
    city: 'Fuchsmühle'
  },
  {
    postalCode: '76877',
    city: 'Offenbach'
  },
  {
    postalCode: '76879',
    city: 'Bornheim'
  },
  {
    postalCode: '76879',
    city: 'Dreihof'
  },
  {
    postalCode: '76879',
    city: 'Essingen'
  },
  {
    postalCode: '76879',
    city: 'Hochstadt'
  },
  {
    postalCode: '76879',
    city: 'Knittelsheim'
  },
  {
    postalCode: '76879',
    city: 'Ottersheim'
  },
  {
    postalCode: '76887',
    city: 'Bad Bergzabern'
  },
  {
    postalCode: '76887',
    city: 'Böllenborn'
  },
  {
    postalCode: '76887',
    city: 'Gehlmühle'
  },
  {
    postalCode: '76887',
    city: 'Oberhausen'
  },
  {
    postalCode: '76887',
    city: 'Sandbühlerhof'
  },
  {
    postalCode: '76889',
    city: 'Am Springberg'
  },
  {
    postalCode: '76889',
    city: 'Barbelroth'
  },
  {
    postalCode: '76889',
    city: 'Birkenhördt'
  },
  {
    postalCode: '76889',
    city: 'Dierbach'
  },
  {
    postalCode: '76889',
    city: 'Dörrenbach'
  },
  {
    postalCode: '76889',
    city: 'Gleiszellen-Gleishorbach'
  },
  {
    postalCode: '76889',
    city: 'Haftelhof'
  },
  {
    postalCode: '76889',
    city: 'Kapellen-Drusweiler'
  },
  {
    postalCode: '76889',
    city: 'Kapsweyer'
  },
  {
    postalCode: '76889',
    city: 'Kleinsteinfeld'
  },
  {
    postalCode: '76889',
    city: 'Klingenmünster'
  },
  {
    postalCode: '76889',
    city: 'Niederhorbach'
  },
  {
    postalCode: '76889',
    city: 'Niederotterbach'
  },
  {
    postalCode: '76889',
    city: 'Oberotterbach'
  },
  {
    postalCode: '76889',
    city: 'Oberschlettenbach'
  },
  {
    postalCode: '76889',
    city: 'Pleisweiler-Oberhofen'
  },
  {
    postalCode: '76889',
    city: 'Schweigen-Rechtenbach'
  },
  {
    postalCode: '76889',
    city: 'Schweighofen'
  },
  {
    postalCode: '76889',
    city: 'Steinfeld'
  },
  {
    postalCode: '76889',
    city: 'Vorderweidenthal'
  },
  {
    postalCode: '76889',
    city: 'Waldhof'
  },
  {
    postalCode: '76891',
    city: 'Bobenthal'
  },
  {
    postalCode: '76891',
    city: 'Bruchweiler-Bärenbach'
  },
  {
    postalCode: '76891',
    city: 'Bundenthal'
  },
  {
    postalCode: '76891',
    city: 'Busenberg'
  },
  {
    postalCode: '76891',
    city: 'Erlenbach'
  },
  {
    postalCode: '76891',
    city: 'Falkenmühle'
  },
  {
    postalCode: '76891',
    city: 'Niederschlettenbach'
  },
  {
    postalCode: '76891',
    city: 'Nothweiler'
  },
  {
    postalCode: '76891',
    city: 'Rumbach'
  },
  {
    postalCode: '77652',
    city: 'Offenburg'
  },
  {
    postalCode: '77654',
    city: 'Offenburg'
  },
  {
    postalCode: '77656',
    city: 'Offenburg'
  },
  {
    postalCode: '77694',
    city: 'Kehl'
  },
  {
    postalCode: '77704',
    city: 'Oberkirch'
  },
  {
    postalCode: '77709',
    city: 'Oberwolfach'
  },
  {
    postalCode: '77709',
    city: 'Wolfach'
  },
  {
    postalCode: '77716',
    city: 'Fischerbach'
  },
  {
    postalCode: '77716',
    city: 'Haslach'
  },
  {
    postalCode: '77716',
    city: 'Hofstetten'
  },
  {
    postalCode: '77723',
    city: 'Gengenbach'
  },
  {
    postalCode: '77728',
    city: 'Oppenau'
  },
  {
    postalCode: '77731',
    city: 'Willstätt'
  },
  {
    postalCode: '77736',
    city: 'Zell'
  },
  {
    postalCode: '77740',
    city: 'Bad Peterstal-Griesbach'
  },
  {
    postalCode: '77743',
    city: 'Neuried'
  },
  {
    postalCode: '77746',
    city: 'Schutterwald'
  },
  {
    postalCode: '77749',
    city: 'Hohberg'
  },
  {
    postalCode: '77756',
    city: 'Hausach'
  },
  {
    postalCode: '77761',
    city: 'Schiltach'
  },
  {
    postalCode: '77767',
    city: 'Appenweier'
  },
  {
    postalCode: '77770',
    city: 'Durbach'
  },
  {
    postalCode: '77773',
    city: 'Schenkenzell'
  },
  {
    postalCode: '77776',
    city: 'Bad Rippoldsau-Schapbach'
  },
  {
    postalCode: '77781',
    city: 'Biberach'
  },
  {
    postalCode: '77784',
    city: 'Oberharmersbach'
  },
  {
    postalCode: '77787',
    city: 'Nordrach'
  },
  {
    postalCode: '77790',
    city: 'Steinach'
  },
  {
    postalCode: '77791',
    city: 'Berghaupten'
  },
  {
    postalCode: '77793',
    city: 'Gutach'
  },
  {
    postalCode: '77794',
    city: 'Lautenbach'
  },
  {
    postalCode: '77796',
    city: 'Mühlenbach'
  },
  {
    postalCode: '77797',
    city: 'Ohlsbach'
  },
  {
    postalCode: '77799',
    city: 'Ortenberg'
  },
  {
    postalCode: '77815',
    city: 'Bühl'
  },
  {
    postalCode: '77830',
    city: 'Bühlertal'
  },
  {
    postalCode: '77833',
    city: 'Ottersweier'
  },
  {
    postalCode: '77836',
    city: 'Rheinmünster'
  },
  {
    postalCode: '77839',
    city: 'Lichtenau'
  },
  {
    postalCode: '77855',
    city: 'Achern'
  },
  {
    postalCode: '77866',
    city: 'Rheinau'
  },
  {
    postalCode: '77871',
    city: 'Renchen'
  },
  {
    postalCode: '77876',
    city: 'Kappelrodeck'
  },
  {
    postalCode: '77880',
    city: 'Sasbach'
  },
  {
    postalCode: '77883',
    city: 'Ottenhöfen'
  },
  {
    postalCode: '77886',
    city: 'Lauf'
  },
  {
    postalCode: '77887',
    city: 'Sasbachwalden'
  },
  {
    postalCode: '77889',
    city: 'Seebach'
  },
  {
    postalCode: '77933',
    city: 'Lahr'
  },
  {
    postalCode: '77948',
    city: 'Friesenheim'
  },
  {
    postalCode: '77955',
    city: 'Ettenheim'
  },
  {
    postalCode: '77960',
    city: 'Seelbach'
  },
  {
    postalCode: '77963',
    city: 'Schwanau'
  },
  {
    postalCode: '77966',
    city: 'Kappel-Grafenhausen'
  },
  {
    postalCode: '77971',
    city: 'Kippenheim'
  },
  {
    postalCode: '77972',
    city: 'Mahlberg'
  },
  {
    postalCode: '77974',
    city: 'Meißenheim'
  },
  {
    postalCode: '77975',
    city: 'Ringsheim'
  },
  {
    postalCode: '77977',
    city: 'Rust'
  },
  {
    postalCode: '77978',
    city: 'Schuttertal'
  },
  {
    postalCode: '78048',
    city: 'Villingen-Schwenningen'
  },
  {
    postalCode: '78050',
    city: 'Villingen-Schwenningen'
  },
  {
    postalCode: '78052',
    city: 'Villingen-Schwenningen'
  },
  {
    postalCode: '78054',
    city: 'Villingen-Schwenningen'
  },
  {
    postalCode: '78056',
    city: 'Villingen-Schwenningen'
  },
  {
    postalCode: '78073',
    city: 'Bad Dürrheim'
  },
  {
    postalCode: '78078',
    city: 'Niedereschach'
  },
  {
    postalCode: '78083',
    city: 'Dauchingen'
  },
  {
    postalCode: '78083',
    city: 'Ebersteinerhof'
  },
  {
    postalCode: '78086',
    city: 'Brigachtal'
  },
  {
    postalCode: '78087',
    city: 'Mönchweiler'
  },
  {
    postalCode: '78089',
    city: 'Unterkirnach'
  },
  {
    postalCode: '78098',
    city: 'Triberg'
  },
  {
    postalCode: '78112',
    city: 'Schoren'
  },
  {
    postalCode: '78112',
    city: 'St. Georgen'
  },
  {
    postalCode: '78120',
    city: 'Furtwangen'
  },
  {
    postalCode: '78126',
    city: 'Königsfeld'
  },
  {
    postalCode: '78132',
    city: 'Hornberg'
  },
  {
    postalCode: '78136',
    city: 'Schonach'
  },
  {
    postalCode: '78141',
    city: 'Schönwald'
  },
  {
    postalCode: '78144',
    city: 'Tennenbronn'
  },
  {
    postalCode: '78147',
    city: 'Vöhrenbach'
  },
  {
    postalCode: '78148',
    city: 'Gütenbach'
  },
  {
    postalCode: '78166',
    city: 'Donaueschingen'
  },
  {
    postalCode: '78176',
    city: 'Blumberg'
  },
  {
    postalCode: '78183',
    city: 'Hüfingen'
  },
  {
    postalCode: '78187',
    city: 'Geisingen'
  },
  {
    postalCode: '78194',
    city: 'Immendingen'
  },
  {
    postalCode: '78199',
    city: 'Bräunlingen'
  },
  {
    postalCode: '78224',
    city: 'Singen'
  },
  {
    postalCode: '78234',
    city: 'Engen'
  },
  {
    postalCode: '78239',
    city: 'Rielasingen-Worblingen'
  },
  {
    postalCode: '78244',
    city: 'Gottmadingen'
  },
  {
    postalCode: '78247',
    city: 'Hilzingen'
  },
  {
    postalCode: '78250',
    city: 'Tengen'
  },
  {
    postalCode: '78253',
    city: 'Eigeltingen'
  },
  {
    postalCode: '78256',
    city: 'Steißlingen'
  },
  {
    postalCode: '78259',
    city: 'Mühlhausen-Ehingen'
  },
  {
    postalCode: '78262',
    city: 'Gailingen'
  },
  {
    postalCode: '78266',
    city: 'Büsingen'
  },
  {
    postalCode: '78267',
    city: 'Aach'
  },
  {
    postalCode: '78269',
    city: 'Volkertshausen'
  },
  {
    postalCode: '78315',
    city: 'Radolfzell'
  },
  {
    postalCode: '78333',
    city: 'Stockach'
  },
  {
    postalCode: '78337',
    city: 'Öhningen'
  },
  {
    postalCode: '78343',
    city: 'Gaienhofen'
  },
  {
    postalCode: '78345',
    city: 'Moos'
  },
  {
    postalCode: '78351',
    city: 'Bodman-Ludwigshafen'
  },
  {
    postalCode: '78354',
    city: 'Sipplingen'
  },
  {
    postalCode: '78355',
    city: 'Hohenfels'
  },
  {
    postalCode: '78357',
    city: 'Mühlingen'
  },
  {
    postalCode: '78359',
    city: 'Orsingen-Nenzingen'
  },
  {
    postalCode: '78462',
    city: 'Konstanz'
  },
  {
    postalCode: '78464',
    city: 'Konstanz'
  },
  {
    postalCode: '78465',
    city: 'Insel Mainau'
  },
  {
    postalCode: '78465',
    city: 'Konstanz'
  },
  {
    postalCode: '78467',
    city: 'Konstanz'
  },
  {
    postalCode: '78476',
    city: 'Allensbach'
  },
  {
    postalCode: '78479',
    city: 'Reichenau'
  },
  {
    postalCode: '78532',
    city: 'Tuttlingen'
  },
  {
    postalCode: '78549',
    city: 'Spaichingen'
  },
  {
    postalCode: '78554',
    city: 'Aldingen'
  },
  {
    postalCode: '78554',
    city: 'Erlenmühle'
  },
  {
    postalCode: '78554',
    city: 'Michelhölzle'
  },
  {
    postalCode: '78559',
    city: 'Gosheim'
  },
  {
    postalCode: '78564',
    city: 'Reichenbach'
  },
  {
    postalCode: '78564',
    city: 'Wehingen'
  },
  {
    postalCode: '78567',
    city: 'Fridingen'
  },
  {
    postalCode: '78567',
    city: 'Scheuerlehof'
  },
  {
    postalCode: '78570',
    city: 'Mühlheim'
  },
  {
    postalCode: '78573',
    city: 'Wurmlingen'
  },
  {
    postalCode: '78576',
    city: 'Emmingen-Liptingen'
  },
  {
    postalCode: '78579',
    city: 'Neuhausen'
  },
  {
    postalCode: '78580',
    city: 'Bärenthal'
  },
  {
    postalCode: '78580',
    city: 'Hammer'
  },
  {
    postalCode: '78582',
    city: 'Balgheim'
  },
  {
    postalCode: '78583',
    city: 'Böttingen'
  },
  {
    postalCode: '78585',
    city: 'Bubsheim'
  },
  {
    postalCode: '78586',
    city: 'Deilingen'
  },
  {
    postalCode: '78588',
    city: 'Denkingen'
  },
  {
    postalCode: '78589',
    city: 'Dürbheim'
  },
  {
    postalCode: '78591',
    city: 'Durchhausen'
  },
  {
    postalCode: '78592',
    city: 'Egesheim'
  },
  {
    postalCode: '78594',
    city: 'Gunningen'
  },
  {
    postalCode: '78595',
    city: 'Hausen'
  },
  {
    postalCode: '78597',
    city: 'Irndorf'
  },
  {
    postalCode: '78598',
    city: 'Königsheim'
  },
  {
    postalCode: '78600',
    city: 'Kolbingen'
  },
  {
    postalCode: '78601',
    city: 'Mahlstetten'
  },
  {
    postalCode: '78603',
    city: 'Renquishausen'
  },
  {
    postalCode: '78604',
    city: 'Rietheim-Weilheim'
  },
  {
    postalCode: '78606',
    city: 'Seitingen-Oberflacht'
  },
  {
    postalCode: '78607',
    city: 'Talheim'
  },
  {
    postalCode: '78609',
    city: 'Tuningen'
  },
  {
    postalCode: '78628',
    city: 'Hochhalden'
  },
  {
    postalCode: '78628',
    city: 'Rottweil'
  },
  {
    postalCode: '78647',
    city: 'Trossingen'
  },
  {
    postalCode: '78652',
    city: 'Deißlingen'
  },
  {
    postalCode: '78652',
    city: 'Unterrotenstein'
  },
  {
    postalCode: '78652',
    city: 'Wildenstein'
  },
  {
    postalCode: '78655',
    city: 'Dunningen'
  },
  {
    postalCode: '78658',
    city: 'Zimmern'
  },
  {
    postalCode: '78661',
    city: 'Dietingen'
  },
  {
    postalCode: '78662',
    city: 'Bösingen'
  },
  {
    postalCode: '78664',
    city: 'Eschbronn'
  },
  {
    postalCode: '78665',
    city: 'Frittlingen'
  },
  {
    postalCode: '78667',
    city: 'Hochwald'
  },
  {
    postalCode: '78667',
    city: 'Villingendorf'
  },
  {
    postalCode: '78669',
    city: 'Wellendingen'
  },
  {
    postalCode: '78713',
    city: 'Gifizenmoos'
  },
  {
    postalCode: '78713',
    city: 'Schramberg'
  },
  {
    postalCode: '78727',
    city: 'Oberndorf'
  },
  {
    postalCode: '78730',
    city: 'Lauterbach'
  },
  {
    postalCode: '78730',
    city: 'Schloßhof'
  },
  {
    postalCode: '78730',
    city: 'Wolfsbühl'
  },
  {
    postalCode: '78733',
    city: 'Aichhalden'
  },
  {
    postalCode: '78733',
    city: 'Breitreute'
  },
  {
    postalCode: '78733',
    city: 'Neuhaus'
  },
  {
    postalCode: '78736',
    city: 'Epfendorf'
  },
  {
    postalCode: '78737',
    city: 'Fluorn-Winzeln'
  },
  {
    postalCode: '78739',
    city: 'Hardt'
  },
  {
    postalCode: '79098',
    city: 'Freiburg'
  },
  {
    postalCode: '79100',
    city: 'Freiburg'
  },
  {
    postalCode: '79102',
    city: 'Freiburg'
  },
  {
    postalCode: '79104',
    city: 'Freiburg'
  },
  {
    postalCode: '79106',
    city: 'Freiburg'
  },
  {
    postalCode: '79108',
    city: 'Freiburg'
  },
  {
    postalCode: '79110',
    city: 'Freiburg'
  },
  {
    postalCode: '79111',
    city: 'Freiburg'
  },
  {
    postalCode: '79112',
    city: 'Freiburg'
  },
  {
    postalCode: '79114',
    city: 'Freiburg'
  },
  {
    postalCode: '79115',
    city: 'Freiburg'
  },
  {
    postalCode: '79117',
    city: 'Freiburg'
  },
  {
    postalCode: '79183',
    city: 'Waldkirch'
  },
  {
    postalCode: '79189',
    city: 'Bad Krozingen'
  },
  {
    postalCode: '79194',
    city: 'Gundelfingen'
  },
  {
    postalCode: '79194',
    city: 'Heuweiler'
  },
  {
    postalCode: '79199',
    city: 'Kirchzarten'
  },
  {
    postalCode: '79206',
    city: 'Breisach'
  },
  {
    postalCode: '79211',
    city: 'Denzlingen'
  },
  {
    postalCode: '79215',
    city: 'Biederbach'
  },
  {
    postalCode: '79215',
    city: 'Elzach'
  },
  {
    postalCode: '79219',
    city: 'Staufen'
  },
  {
    postalCode: '79224',
    city: 'Umkirch'
  },
  {
    postalCode: '79227',
    city: 'Schallstadt'
  },
  {
    postalCode: '79232',
    city: 'March'
  },
  {
    postalCode: '79235',
    city: 'Vogtsburg'
  },
  {
    postalCode: '79238',
    city: 'Ehrenkirchen'
  },
  {
    postalCode: '79241',
    city: 'Ihringen'
  },
  {
    postalCode: '79244',
    city: 'Münstertal'
  },
  {
    postalCode: '79249',
    city: 'Merzhausen'
  },
  {
    postalCode: '79252',
    city: 'Stegen'
  },
  {
    postalCode: '79254',
    city: 'Oberried'
  },
  {
    postalCode: '79256',
    city: 'Buchenbach'
  },
  {
    postalCode: '79258',
    city: 'Hartheim'
  },
  {
    postalCode: '79261',
    city: 'Gutach'
  },
  {
    postalCode: '79263',
    city: 'Simonswald'
  },
  {
    postalCode: '79268',
    city: 'Bötzingen'
  },
  {
    postalCode: '79271',
    city: 'St. Peter'
  },
  {
    postalCode: '79274',
    city: 'St. Märgen'
  },
  {
    postalCode: '79276',
    city: 'Reute'
  },
  {
    postalCode: '79279',
    city: 'Vörstetten'
  },
  {
    postalCode: '79280',
    city: 'Au'
  },
  {
    postalCode: '79282',
    city: 'Ballrechten-Dottingen'
  },
  {
    postalCode: '79283',
    city: 'Bollschweil'
  },
  {
    postalCode: '79285',
    city: 'Ebringen'
  },
  {
    postalCode: '79286',
    city: 'Glottertal'
  },
  {
    postalCode: '79288',
    city: 'Gottenheim'
  },
  {
    postalCode: '79289',
    city: 'Horben'
  },
  {
    postalCode: '79291',
    city: 'Merdingen'
  },
  {
    postalCode: '79292',
    city: 'Pfaffenweiler'
  },
  {
    postalCode: '79294',
    city: 'Sölden'
  },
  {
    postalCode: '79295',
    city: 'Sulzburg'
  },
  {
    postalCode: '79297',
    city: 'Winden'
  },
  {
    postalCode: '79299',
    city: 'Wittnau'
  },
  {
    postalCode: '79312',
    city: 'Emmendingen'
  },
  {
    postalCode: '79312',
    city: 'Landeck'
  },
  {
    postalCode: '79331',
    city: 'Teningen'
  },
  {
    postalCode: '79336',
    city: 'Herbolzheim'
  },
  {
    postalCode: '79341',
    city: 'Kenzingen'
  },
  {
    postalCode: '79346',
    city: 'Endingen'
  },
  {
    postalCode: '79348',
    city: 'Freiamt'
  },
  {
    postalCode: '79350',
    city: 'Sexau'
  },
  {
    postalCode: '79353',
    city: 'Bahlingen'
  },
  {
    postalCode: '79356',
    city: 'Eichstetten'
  },
  {
    postalCode: '79359',
    city: 'Riegel'
  },
  {
    postalCode: '79361',
    city: 'Sasbach'
  },
  {
    postalCode: '79362',
    city: 'Forchheim'
  },
  {
    postalCode: '79364',
    city: 'Malterdingen'
  },
  {
    postalCode: '79365',
    city: 'Rheinhausen'
  },
  {
    postalCode: '79367',
    city: 'Weisweil'
  },
  {
    postalCode: '79369',
    city: 'Wyhl'
  },
  {
    postalCode: '79379',
    city: 'Müllheim'
  },
  {
    postalCode: '79395',
    city: 'Neuenburg'
  },
  {
    postalCode: '79400',
    city: 'Kandern'
  },
  {
    postalCode: '79410',
    city: 'Badenweiler'
  },
  {
    postalCode: '79415',
    city: 'Bad Bellingen'
  },
  {
    postalCode: '79418',
    city: 'Schliengen'
  },
  {
    postalCode: '79423',
    city: 'Heitersheim'
  },
  {
    postalCode: '79424',
    city: 'Auggen'
  },
  {
    postalCode: '79426',
    city: 'Buggingen'
  },
  {
    postalCode: '79427',
    city: 'Eschbach'
  },
  {
    postalCode: '79429',
    city: 'Malsburg-Marzell'
  },
  {
    postalCode: '79539',
    city: 'Lörrach'
  },
  {
    postalCode: '79540',
    city: 'Lörrach'
  },
  {
    postalCode: '79541',
    city: 'Lörrach'
  },
  {
    postalCode: '79576',
    city: 'Weil am Rhein'
  },
  {
    postalCode: '79585',
    city: 'Steinen'
  },
  {
    postalCode: '79588',
    city: 'Efringen-Kirchen'
  },
  {
    postalCode: '79589',
    city: 'Binzen'
  },
  {
    postalCode: '79591',
    city: 'Eimeldingen'
  },
  {
    postalCode: '79592',
    city: 'Fischingen'
  },
  {
    postalCode: '79594',
    city: 'Inzlingen'
  },
  {
    postalCode: '79595',
    city: 'Rümmingen'
  },
  {
    postalCode: '79597',
    city: 'Schallbach'
  },
  {
    postalCode: '79599',
    city: 'Wittlingen'
  },
  {
    postalCode: '79618',
    city: 'Rheinfelden'
  },
  {
    postalCode: '79639',
    city: 'Grenzach-Wyhlen'
  },
  {
    postalCode: '79650',
    city: 'Schopfheim'
  },
  {
    postalCode: '79664',
    city: 'Wehr'
  },
  {
    postalCode: '79669',
    city: 'Zell'
  },
  {
    postalCode: '79674',
    city: 'Todtnau'
  },
  {
    postalCode: '79677',
    city: 'Aitern'
  },
  {
    postalCode: '79677',
    city: 'Böllen'
  },
  {
    postalCode: '79677',
    city: 'Fröhnd'
  },
  {
    postalCode: '79677',
    city: 'Schönau'
  },
  {
    postalCode: '79677',
    city: 'Schönenberg'
  },
  {
    postalCode: '79677',
    city: 'Tunau'
  },
  {
    postalCode: '79677',
    city: 'Wembach'
  },
  {
    postalCode: '79682',
    city: 'Todtmoos'
  },
  {
    postalCode: '79683',
    city: 'Bürchau'
  },
  {
    postalCode: '79685',
    city: 'Häg-Ehrsberg'
  },
  {
    postalCode: '79686',
    city: 'Hasel'
  },
  {
    postalCode: '79688',
    city: 'Hausen'
  },
  {
    postalCode: '79689',
    city: 'Maulburg'
  },
  {
    postalCode: '79691',
    city: 'Neuenweg'
  },
  {
    postalCode: '79692',
    city: 'Elbenschwand'
  },
  {
    postalCode: '79692',
    city: 'Raich'
  },
  {
    postalCode: '79692',
    city: 'Sallneck'
  },
  {
    postalCode: '79692',
    city: 'Tegernau'
  },
  {
    postalCode: '79694',
    city: 'Utzenfeld'
  },
  {
    postalCode: '79695',
    city: 'Wieden'
  },
  {
    postalCode: '79697',
    city: 'Wies'
  },
  {
    postalCode: '79699',
    city: 'Wieslet'
  },
  {
    postalCode: '79713',
    city: 'Bad Säckingen'
  },
  {
    postalCode: '79725',
    city: 'Laufenburg'
  },
  {
    postalCode: '79730',
    city: 'Murg'
  },
  {
    postalCode: '79733',
    city: 'Görwihl'
  },
  {
    postalCode: '79736',
    city: 'Rickenbach'
  },
  {
    postalCode: '79737',
    city: 'Herrischried'
  },
  {
    postalCode: '79739',
    city: 'Schwörstadt'
  },
  {
    postalCode: '79761',
    city: 'Waldshut-Tiengen'
  },
  {
    postalCode: '79771',
    city: 'Klettgau'
  },
  {
    postalCode: '79774',
    city: 'Albbruck'
  },
  {
    postalCode: '79777',
    city: 'Ühlingen-Birkendorf'
  },
  {
    postalCode: '79780',
    city: 'Stühlingen'
  },
  {
    postalCode: '79787',
    city: 'Lauchringen'
  },
  {
    postalCode: '79790',
    city: 'Küssaberg'
  },
  {
    postalCode: '79793',
    city: 'Wutöschingen'
  },
  {
    postalCode: '79798',
    city: 'Jestetten'
  },
  {
    postalCode: '79801',
    city: 'Hohentengen'
  },
  {
    postalCode: '79802',
    city: 'Dettighofen'
  },
  {
    postalCode: '79804',
    city: 'Dogern'
  },
  {
    postalCode: '79805',
    city: 'Eggingen'
  },
  {
    postalCode: '79807',
    city: 'Lottstetten'
  },
  {
    postalCode: '79809',
    city: 'Weilheim'
  },
  {
    postalCode: '79822',
    city: 'Titisee-Neustadt'
  },
  {
    postalCode: '79837',
    city: 'Häusern'
  },
  {
    postalCode: '79837',
    city: 'Ibach'
  },
  {
    postalCode: '79837',
    city: 'Oberkutterau'
  },
  {
    postalCode: '79837',
    city: 'St. Blasien'
  },
  {
    postalCode: '79843',
    city: 'Löffingen'
  },
  {
    postalCode: '79848',
    city: 'Bonndorf'
  },
  {
    postalCode: '79853',
    city: 'Lenzkirch'
  },
  {
    postalCode: '79856',
    city: 'Hinterzarten'
  },
  {
    postalCode: '79859',
    city: 'Schluchsee'
  },
  {
    postalCode: '79862',
    city: 'Höchenschwand'
  },
  {
    postalCode: '79865',
    city: 'Grafenhausen'
  },
  {
    postalCode: '79868',
    city: 'Feldberg'
  },
  {
    postalCode: '79871',
    city: 'Eisenbach'
  },
  {
    postalCode: '79872',
    city: 'Bernau'
  },
  {
    postalCode: '79874',
    city: 'Breitnau'
  },
  {
    postalCode: '79875',
    city: 'Dachsberg'
  },
  {
    postalCode: '79875',
    city: 'Luchle'
  },
  {
    postalCode: '79877',
    city: 'Friedenweiler'
  },
  {
    postalCode: '79879',
    city: 'Wutach'
  },
  {
    postalCode: '80331',
    city: 'München'
  },
  {
    postalCode: '80333',
    city: 'München'
  },
  {
    postalCode: '80335',
    city: 'München'
  },
  {
    postalCode: '80336',
    city: 'München'
  },
  {
    postalCode: '80337',
    city: 'München'
  },
  {
    postalCode: '80339',
    city: 'München'
  },
  {
    postalCode: '80469',
    city: 'München'
  },
  {
    postalCode: '80538',
    city: 'München'
  },
  {
    postalCode: '80539',
    city: 'München'
  },
  {
    postalCode: '80634',
    city: 'München'
  },
  {
    postalCode: '80636',
    city: 'München'
  },
  {
    postalCode: '80637',
    city: 'München'
  },
  {
    postalCode: '80638',
    city: 'München'
  },
  {
    postalCode: '80639',
    city: 'München'
  },
  {
    postalCode: '80686',
    city: 'München'
  },
  {
    postalCode: '80687',
    city: 'München'
  },
  {
    postalCode: '80689',
    city: 'München'
  },
  {
    postalCode: '80796',
    city: 'München'
  },
  {
    postalCode: '80797',
    city: 'München'
  },
  {
    postalCode: '80798',
    city: 'München'
  },
  {
    postalCode: '80799',
    city: 'München'
  },
  {
    postalCode: '80801',
    city: 'München'
  },
  {
    postalCode: '80802',
    city: 'München'
  },
  {
    postalCode: '80803',
    city: 'München'
  },
  {
    postalCode: '80804',
    city: 'München'
  },
  {
    postalCode: '80805',
    city: 'München'
  },
  {
    postalCode: '80807',
    city: 'München'
  },
  {
    postalCode: '80809',
    city: 'München'
  },
  {
    postalCode: '80933',
    city: 'München'
  },
  {
    postalCode: '80935',
    city: 'München'
  },
  {
    postalCode: '80937',
    city: 'München'
  },
  {
    postalCode: '80939',
    city: 'München'
  },
  {
    postalCode: '80992',
    city: 'München'
  },
  {
    postalCode: '80993',
    city: 'München'
  },
  {
    postalCode: '80995',
    city: 'München'
  },
  {
    postalCode: '80997',
    city: 'München'
  },
  {
    postalCode: '80999',
    city: 'München'
  },
  {
    postalCode: '81241',
    city: 'München'
  },
  {
    postalCode: '81243',
    city: 'München'
  },
  {
    postalCode: '81245',
    city: 'München'
  },
  {
    postalCode: '81247',
    city: 'München'
  },
  {
    postalCode: '81249',
    city: 'München'
  },
  {
    postalCode: '81249',
    city: 'Streiflach'
  },
  {
    postalCode: '81369',
    city: 'München'
  },
  {
    postalCode: '81371',
    city: 'München'
  },
  {
    postalCode: '81373',
    city: 'München'
  },
  {
    postalCode: '81375',
    city: 'München'
  },
  {
    postalCode: '81377',
    city: 'München'
  },
  {
    postalCode: '81379',
    city: 'München'
  },
  {
    postalCode: '81475',
    city: 'München'
  },
  {
    postalCode: '81476',
    city: 'München'
  },
  {
    postalCode: '81477',
    city: 'München'
  },
  {
    postalCode: '81479',
    city: 'München'
  },
  {
    postalCode: '81539',
    city: 'München'
  },
  {
    postalCode: '81541',
    city: 'München'
  },
  {
    postalCode: '81543',
    city: 'München'
  },
  {
    postalCode: '81545',
    city: 'München'
  },
  {
    postalCode: '81547',
    city: 'München'
  },
  {
    postalCode: '81549',
    city: 'München'
  },
  {
    postalCode: '81667',
    city: 'München'
  },
  {
    postalCode: '81669',
    city: 'München'
  },
  {
    postalCode: '81671',
    city: 'München'
  },
  {
    postalCode: '81673',
    city: 'München'
  },
  {
    postalCode: '81675',
    city: 'München'
  },
  {
    postalCode: '81677',
    city: 'München'
  },
  {
    postalCode: '81679',
    city: 'München'
  },
  {
    postalCode: '81735',
    city: 'München'
  },
  {
    postalCode: '81737',
    city: 'München'
  },
  {
    postalCode: '81739',
    city: 'München'
  },
  {
    postalCode: '81825',
    city: 'München'
  },
  {
    postalCode: '81827',
    city: 'München'
  },
  {
    postalCode: '81829',
    city: 'München'
  },
  {
    postalCode: '81925',
    city: 'München'
  },
  {
    postalCode: '81927',
    city: 'München'
  },
  {
    postalCode: '81929',
    city: 'München'
  },
  {
    postalCode: '82008',
    city: 'Unterhaching'
  },
  {
    postalCode: '82024',
    city: 'Taufkirchen'
  },
  {
    postalCode: '82031',
    city: 'Grünwald'
  },
  {
    postalCode: '82041',
    city: 'Deisenhofen'
  },
  {
    postalCode: '82041',
    city: 'Furth'
  },
  {
    postalCode: '82041',
    city: 'Gerblinghausen'
  },
  {
    postalCode: '82041',
    city: 'Kreuzpullach'
  },
  {
    postalCode: '82041',
    city: 'Laufzorn'
  },
  {
    postalCode: '82041',
    city: 'Oberbiberg'
  },
  {
    postalCode: '82041',
    city: 'Oberhaching'
  },
  {
    postalCode: '82041',
    city: 'Ödenpullach'
  },
  {
    postalCode: '82049',
    city: 'Großhesselohe'
  },
  {
    postalCode: '82049',
    city: 'Höllriegelskreuth'
  },
  {
    postalCode: '82049',
    city: 'Pullach'
  },
  {
    postalCode: '82054',
    city: 'Sauerlach'
  },
  {
    postalCode: '82057',
    city: 'Icking'
  },
  {
    postalCode: '82061',
    city: 'Neuried'
  },
  {
    postalCode: '82064',
    city: 'Jettenhausen'
  },
  {
    postalCode: '82064',
    city: 'Oberdill'
  },
  {
    postalCode: '82064',
    city: 'Straßlach-Dingharting'
  },
  {
    postalCode: '82065',
    city: 'Baierbrunn'
  },
  {
    postalCode: '82067',
    city: 'Ebenhausen'
  },
  {
    postalCode: '82067',
    city: 'Kloster Schäftlarn'
  },
  {
    postalCode: '82067',
    city: 'Zell'
  },
  {
    postalCode: '82069',
    city: 'Hohenschäftlarn'
  },
  {
    postalCode: '82069',
    city: 'Schäftlarn'
  },
  {
    postalCode: '82110',
    city: 'Germering'
  },
  {
    postalCode: '82131',
    city: 'Gauting'
  },
  {
    postalCode: '82131',
    city: 'Kasten'
  },
  {
    postalCode: '82131',
    city: 'Stockdorf'
  },
  {
    postalCode: '82140',
    city: 'Olching'
  },
  {
    postalCode: '82152',
    city: 'Krailling'
  },
  {
    postalCode: '82152',
    city: 'Planegg'
  },
  {
    postalCode: '82166',
    city: 'Gräfelfing'
  },
  {
    postalCode: '82178',
    city: 'Puchheim'
  },
  {
    postalCode: '82194',
    city: 'Gröbenzell'
  },
  {
    postalCode: '82205',
    city: 'Gilching'
  },
  {
    postalCode: '82205',
    city: 'Hüll'
  },
  {
    postalCode: '82211',
    city: 'Herrsching'
  },
  {
    postalCode: '82216',
    city: 'Frauenberg'
  },
  {
    postalCode: '82216',
    city: 'Germerswang'
  },
  {
    postalCode: '82216',
    city: 'Maisach'
  },
  {
    postalCode: '82216',
    city: 'Malching'
  },
  {
    postalCode: '82216',
    city: 'Stefansberg'
  },
  {
    postalCode: '82223',
    city: 'Eichenau'
  },
  {
    postalCode: '82223',
    city: 'Roggenstein'
  },
  {
    postalCode: '82229',
    city: 'Seefeld'
  },
  {
    postalCode: '82234',
    city: 'Weßling'
  },
  {
    postalCode: '82237',
    city: 'Wörthsee'
  },
  {
    postalCode: '82239',
    city: 'Alling'
  },
  {
    postalCode: '82256',
    city: 'Fürstenfeldbruck'
  },
  {
    postalCode: '82266',
    city: 'Inning'
  },
  {
    postalCode: '82269',
    city: 'Geltendorf'
  },
  {
    postalCode: '82272',
    city: 'Moorenweis'
  },
  {
    postalCode: '82275',
    city: 'Emmering'
  },
  {
    postalCode: '82276',
    city: 'Adelshofen'
  },
  {
    postalCode: '82278',
    city: 'Althegnenberg'
  },
  {
    postalCode: '82279',
    city: 'Eching'
  },
  {
    postalCode: '82281',
    city: 'Egenhofen'
  },
  {
    postalCode: '82282',
    city: 'Aufkirchen'
  },
  {
    postalCode: '82282',
    city: 'Englertshofen'
  },
  {
    postalCode: '82282',
    city: 'Eurastetten'
  },
  {
    postalCode: '82282',
    city: 'Geisenhofen'
  },
  {
    postalCode: '82282',
    city: 'Holzmühl'
  },
  {
    postalCode: '82282',
    city: 'Kumpfmühle'
  },
  {
    postalCode: '82282',
    city: 'Oberweikertshofen'
  },
  {
    postalCode: '82282',
    city: 'Pischertshofen'
  },
  {
    postalCode: '82282',
    city: 'Rammertshofen'
  },
  {
    postalCode: '82282',
    city: 'Rottenfuß'
  },
  {
    postalCode: '82282',
    city: 'Unterschweinbach'
  },
  {
    postalCode: '82282',
    city: 'Waltershofen'
  },
  {
    postalCode: '82284',
    city: 'Grafrath'
  },
  {
    postalCode: '82285',
    city: 'Hattenhofen'
  },
  {
    postalCode: '82287',
    city: 'Jesenwang'
  },
  {
    postalCode: '82288',
    city: 'Kottgeisering'
  },
  {
    postalCode: '82290',
    city: 'Landsberied'
  },
  {
    postalCode: '82291',
    city: 'Mammendorf'
  },
  {
    postalCode: '82293',
    city: 'Mittelstetten'
  },
  {
    postalCode: '82294',
    city: 'Oberschweinbach'
  },
  {
    postalCode: '82296',
    city: 'Schöngeising'
  },
  {
    postalCode: '82297',
    city: 'Steindorf'
  },
  {
    postalCode: '82299',
    city: 'Türkenfeld'
  },
  {
    postalCode: '82319',
    city: 'Seewiesen'
  },
  {
    postalCode: '82319',
    city: 'Starnberg'
  },
  {
    postalCode: '82327',
    city: 'Tutzing'
  },
  {
    postalCode: '82335',
    city: 'Berg'
  },
  {
    postalCode: '82340',
    city: 'Feldafing'
  },
  {
    postalCode: '82343',
    city: 'Pöcking'
  },
  {
    postalCode: '82346',
    city: 'Andechs'
  },
  {
    postalCode: '82347',
    city: 'Bernried'
  },
  {
    postalCode: '82349',
    city: 'Frohnloh'
  },
  {
    postalCode: '82349',
    city: 'Pentenried'
  },
  {
    postalCode: '82362',
    city: 'Schörghof'
  },
  {
    postalCode: '82362',
    city: 'Weilheim'
  },
  {
    postalCode: '82377',
    city: 'Penzberg'
  },
  {
    postalCode: '82380',
    city: 'Bruckerhof'
  },
  {
    postalCode: '82380',
    city: 'Osterwald'
  },
  {
    postalCode: '82380',
    city: 'Peißenberg'
  },
  {
    postalCode: '82380',
    city: 'Roßlaich'
  },
  {
    postalCode: '82383',
    city: 'Hohenpeißenberg'
  },
  {
    postalCode: '82386',
    city: 'Huglfing'
  },
  {
    postalCode: '82386',
    city: 'Oberhausen'
  },
  {
    postalCode: '82387',
    city: 'Antdorf'
  },
  {
    postalCode: '82389',
    city: 'Böbing'
  },
  {
    postalCode: '82390',
    city: 'Eberfing'
  },
  {
    postalCode: '82390',
    city: 'Moos'
  },
  {
    postalCode: '82390',
    city: 'Tradlenz'
  },
  {
    postalCode: '82392',
    city: 'Habach'
  },
  {
    postalCode: '82392',
    city: 'Habaching'
  },
  {
    postalCode: '82392',
    city: 'Hachtsee'
  },
  {
    postalCode: '82392',
    city: 'Reinthal'
  },
  {
    postalCode: '82393',
    city: 'Iffeldorf'
  },
  {
    postalCode: '82393',
    city: 'Schechen'
  },
  {
    postalCode: '82395',
    city: 'Obersöchering'
  },
  {
    postalCode: '82396',
    city: 'Pähl'
  },
  {
    postalCode: '82398',
    city: 'Polling'
  },
  {
    postalCode: '82399',
    city: 'Raisting'
  },
  {
    postalCode: '82401',
    city: 'Rottenbuch'
  },
  {
    postalCode: '82402',
    city: 'Seeshaupt'
  },
  {
    postalCode: '82404',
    city: 'Sindelsdorf'
  },
  {
    postalCode: '82405',
    city: 'Wessobrunn'
  },
  {
    postalCode: '82407',
    city: 'Wielenbach'
  },
  {
    postalCode: '82409',
    city: 'Wildsteig'
  },
  {
    postalCode: '82418',
    city: 'Hofheim'
  },
  {
    postalCode: '82418',
    city: 'Murnau'
  },
  {
    postalCode: '82418',
    city: 'Riegsee'
  },
  {
    postalCode: '82418',
    city: 'Seehausen'
  },
  {
    postalCode: '82431',
    city: 'Kochel'
  },
  {
    postalCode: '82432',
    city: 'Herzogstand'
  },
  {
    postalCode: '82432',
    city: 'Obernach'
  },
  {
    postalCode: '82432',
    city: 'Urfeld'
  },
  {
    postalCode: '82432',
    city: 'Walchensee'
  },
  {
    postalCode: '82432',
    city: 'Zwergern'
  },
  {
    postalCode: '82433',
    city: 'Bad Kohlgrub'
  },
  {
    postalCode: '82435',
    city: 'Bad Bayersoien'
  },
  {
    postalCode: '82436',
    city: 'Eglfing'
  },
  {
    postalCode: '82438',
    city: 'Eschenlohe'
  },
  {
    postalCode: '82439',
    city: 'Großweil'
  },
  {
    postalCode: '82439',
    city: 'Kreut'
  },
  {
    postalCode: '82441',
    city: 'Ohlstadt'
  },
  {
    postalCode: '82442',
    city: 'Saulgrub'
  },
  {
    postalCode: '82444',
    city: 'Schlehdorf'
  },
  {
    postalCode: '82445',
    city: 'Schwaigen'
  },
  {
    postalCode: '82447',
    city: 'Spatzenhausen'
  },
  {
    postalCode: '82449',
    city: 'Heimgarten'
  },
  {
    postalCode: '82449',
    city: 'Uffing'
  },
  {
    postalCode: '82467',
    city: 'Garmisch-Partenkirchen'
  },
  {
    postalCode: '82467',
    city: 'Plattele'
  },
  {
    postalCode: '82475',
    city: 'Schneefernerhaus'
  },
  {
    postalCode: '82481',
    city: 'Mittenwald'
  },
  {
    postalCode: '82487',
    city: 'Oberammergau'
  },
  {
    postalCode: '82488',
    city: 'Ettal'
  },
  {
    postalCode: '82490',
    city: 'Farchant'
  },
  {
    postalCode: '82491',
    city: 'Grainau'
  },
  {
    postalCode: '82493',
    city: 'Am Quicken'
  },
  {
    postalCode: '82493',
    city: 'Elmau'
  },
  {
    postalCode: '82493',
    city: 'Gerold'
  },
  {
    postalCode: '82493',
    city: 'Klais'
  },
  {
    postalCode: '82493',
    city: 'Kranzbach'
  },
  {
    postalCode: '82494',
    city: 'Krün'
  },
  {
    postalCode: '82496',
    city: 'Oberau'
  },
  {
    postalCode: '82497',
    city: 'Unterammergau'
  },
  {
    postalCode: '82499',
    city: 'Wallgau'
  },
  {
    postalCode: '82515',
    city: 'Bruckmaier'
  },
  {
    postalCode: '82515',
    city: 'Wolfratshausen'
  },
  {
    postalCode: '82538',
    city: 'Geretsried'
  },
  {
    postalCode: '82541',
    city: 'Münsing'
  },
  {
    postalCode: '82544',
    city: 'Egling'
  },
  {
    postalCode: '82547',
    city: 'Eurasburg'
  },
  {
    postalCode: '82549',
    city: 'Königsdorf'
  },
  {
    postalCode: '83022',
    city: 'Rosenheim'
  },
  {
    postalCode: '83024',
    city: 'Rosenheim'
  },
  {
    postalCode: '83026',
    city: 'Rosenheim'
  },
  {
    postalCode: '83043',
    city: 'Bad Aibling'
  },
  {
    postalCode: '83052',
    city: 'Bruckmühl'
  },
  {
    postalCode: '83059',
    city: 'Kolbermoor'
  },
  {
    postalCode: '83064',
    city: 'Raubling'
  },
  {
    postalCode: '83071',
    city: 'Stephanskirchen'
  },
  {
    postalCode: '83075',
    city: 'Bad Feilnbach'
  },
  {
    postalCode: '83080',
    city: 'Oberaudorf'
  },
  {
    postalCode: '83083',
    city: 'Riedering'
  },
  {
    postalCode: '83088',
    city: 'Kiefersfelden'
  },
  {
    postalCode: '83093',
    city: 'Bad Endorf'
  },
  {
    postalCode: '83098',
    city: 'Brannenburg'
  },
  {
    postalCode: '83101',
    city: 'Rohrdorf'
  },
  {
    postalCode: '83104',
    city: 'Tuntenhausen'
  },
  {
    postalCode: '83109',
    city: 'Großkarolinenfeld'
  },
  {
    postalCode: '83112',
    city: 'Frasdorf'
  },
  {
    postalCode: '83115',
    city: 'Neubeuern'
  },
  {
    postalCode: '83119',
    city: 'Eggerdach'
  },
  {
    postalCode: '83119',
    city: 'Gröben'
  },
  {
    postalCode: '83119',
    city: 'Obing'
  },
  {
    postalCode: '83122',
    city: 'Samerberg'
  },
  {
    postalCode: '83123',
    city: 'Amerang'
  },
  {
    postalCode: '83125',
    city: 'Eggstätt'
  },
  {
    postalCode: '83126',
    city: 'Flintsbach'
  },
  {
    postalCode: '83128',
    city: 'Halfing'
  },
  {
    postalCode: '83129',
    city: 'Höslwang'
  },
  {
    postalCode: '83131',
    city: 'Nußdorf'
  },
  {
    postalCode: '83132',
    city: 'Pittenhart'
  },
  {
    postalCode: '83134',
    city: 'Prutting'
  },
  {
    postalCode: '83135',
    city: 'Schechen'
  },
  {
    postalCode: '83137',
    city: 'Schonstett'
  },
  {
    postalCode: '83139',
    city: 'Söchtenau'
  },
  {
    postalCode: '83209',
    city: 'Herrenchiemsee'
  },
  {
    postalCode: '83209',
    city: 'Prien'
  },
  {
    postalCode: '83224',
    city: 'Grassau'
  },
  {
    postalCode: '83224',
    city: 'Staudach-Egerndach'
  },
  {
    postalCode: '83229',
    city: 'Aschau'
  },
  {
    postalCode: '83233',
    city: 'Bernau'
  },
  {
    postalCode: '83236',
    city: 'Übersee'
  },
  {
    postalCode: '83242',
    city: 'Reit im Winkl'
  },
  {
    postalCode: '83246',
    city: 'Unterwössen'
  },
  {
    postalCode: '83250',
    city: 'Fahrnpoint'
  },
  {
    postalCode: '83250',
    city: 'Hochgernhaus'
  },
  {
    postalCode: '83250',
    city: 'Marquartstein'
  },
  {
    postalCode: '83253',
    city: 'Rimsting'
  },
  {
    postalCode: '83254',
    city: 'Breitbrunn'
  },
  {
    postalCode: '83256',
    city: 'Chiemsee'
  },
  {
    postalCode: '83256',
    city: 'Frauenchiemsee'
  },
  {
    postalCode: '83257',
    city: 'Gstadt'
  },
  {
    postalCode: '83259',
    city: 'Schleching'
  },
  {
    postalCode: '83278',
    city: 'Traunstein'
  },
  {
    postalCode: '83301',
    city: 'Traunreut'
  },
  {
    postalCode: '83308',
    city: 'Trostberg'
  },
  {
    postalCode: '83313',
    city: 'Grub'
  },
  {
    postalCode: '83313',
    city: 'Siegsdorf'
  },
  {
    postalCode: '83317',
    city: 'Teisendorf'
  },
  {
    postalCode: '83324',
    city: 'Ruhpolding'
  },
  {
    postalCode: '83329',
    city: 'Aichberg'
  },
  {
    postalCode: '83329',
    city: 'Aichwies'
  },
  {
    postalCode: '83329',
    city: 'Amselgraben'
  },
  {
    postalCode: '83329',
    city: 'Burgstall'
  },
  {
    postalCode: '83329',
    city: 'Dobelgraben'
  },
  {
    postalCode: '83329',
    city: 'Eglsee'
  },
  {
    postalCode: '83329',
    city: 'Enzersdorf'
  },
  {
    postalCode: '83329',
    city: 'Fürst'
  },
  {
    postalCode: '83329',
    city: 'Gänsberg'
  },
  {
    postalCode: '83329',
    city: 'Greinach'
  },
  {
    postalCode: '83329',
    city: 'Heinrichsdorf'
  },
  {
    postalCode: '83329',
    city: 'Hellmannsberg'
  },
  {
    postalCode: '83329',
    city: 'Hoggen'
  },
  {
    postalCode: '83329',
    city: 'Horn'
  },
  {
    postalCode: '83329',
    city: 'Kirchhalling'
  },
  {
    postalCode: '83329',
    city: 'Köpfelsberg'
  },
  {
    postalCode: '83329',
    city: 'Madreut'
  },
  {
    postalCode: '83329',
    city: 'Oberdoblmühle'
  },
  {
    postalCode: '83329',
    city: 'Oberhalling'
  },
  {
    postalCode: '83329',
    city: 'Obermoosen'
  },
  {
    postalCode: '83329',
    city: 'Oberwendling'
  },
  {
    postalCode: '83329',
    city: 'Panolsreut'
  },
  {
    postalCode: '83329',
    city: 'Ramgraben'
  },
  {
    postalCode: '83329',
    city: 'Riendlhäusl'
  },
  {
    postalCode: '83329',
    city: 'Ruhsdorf'
  },
  {
    postalCode: '83329',
    city: 'Scharling'
  },
  {
    postalCode: '83329',
    city: 'St Leonhard'
  },
  {
    postalCode: '83329',
    city: 'Sträubl'
  },
  {
    postalCode: '83329',
    city: 'Untermoosen'
  },
  {
    postalCode: '83329',
    city: 'Unterwendling'
  },
  {
    postalCode: '83329',
    city: 'Waging'
  },
  {
    postalCode: '83329',
    city: 'Weißbach'
  },
  {
    postalCode: '83329',
    city: 'Wonneberg'
  },
  {
    postalCode: '83334',
    city: 'Inzell'
  },
  {
    postalCode: '83339',
    city: 'Chieming'
  },
  {
    postalCode: '83342',
    city: 'Tacherting'
  },
  {
    postalCode: '83346',
    city: 'Bergen'
  },
  {
    postalCode: '83346',
    city: 'Brünndling-Alm'
  },
  {
    postalCode: '83346',
    city: 'Hochfellnhaus'
  },
  {
    postalCode: '83349',
    city: 'Palling'
  },
  {
    postalCode: '83352',
    city: 'Altenmarkt'
  },
  {
    postalCode: '83355',
    city: 'Grabenstätt'
  },
  {
    postalCode: '83358',
    city: 'Grafenanger'
  },
  {
    postalCode: '83358',
    city: 'Seebruck'
  },
  {
    postalCode: '83359',
    city: 'Ettendorf'
  },
  {
    postalCode: '83359',
    city: 'Hallabruck'
  },
  {
    postalCode: '83359',
    city: 'Hierankl'
  },
  {
    postalCode: '83359',
    city: 'Hufschlag'
  },
  {
    postalCode: '83359',
    city: 'Jahn'
  },
  {
    postalCode: '83359',
    city: 'Trenkmoos'
  },
  {
    postalCode: '83361',
    city: 'Kienberg'
  },
  {
    postalCode: '83362',
    city: 'Berg'
  },
  {
    postalCode: '83362',
    city: 'Brunnstadt'
  },
  {
    postalCode: '83362',
    city: 'Eder'
  },
  {
    postalCode: '83362',
    city: 'Hirschau'
  },
  {
    postalCode: '83362',
    city: 'Lauter'
  },
  {
    postalCode: '83362',
    city: 'Reut'
  },
  {
    postalCode: '83362',
    city: 'Schnatzlreut'
  },
  {
    postalCode: '83362',
    city: 'Surberg'
  },
  {
    postalCode: '83362',
    city: 'Zell'
  },
  {
    postalCode: '83364',
    city: 'Adligstadt'
  },
  {
    postalCode: '83364',
    city: 'Andrichstadt'
  },
  {
    postalCode: '83364',
    city: 'Atzlbach'
  },
  {
    postalCode: '83364',
    city: 'Au'
  },
  {
    postalCode: '83364',
    city: 'Bach'
  },
  {
    postalCode: '83364',
    city: 'Diepoltstatt'
  },
  {
    postalCode: '83364',
    city: 'Fuchssteig'
  },
  {
    postalCode: '83364',
    city: 'Gierstling'
  },
  {
    postalCode: '83364',
    city: 'Graben'
  },
  {
    postalCode: '83364',
    city: 'Grabenhäusl'
  },
  {
    postalCode: '83364',
    city: 'Grub'
  },
  {
    postalCode: '83364',
    city: 'Gschwend'
  },
  {
    postalCode: '83364',
    city: 'Hinterleiten'
  },
  {
    postalCode: '83364',
    city: 'Hinterloh'
  },
  {
    postalCode: '83364',
    city: 'Hochhorn'
  },
  {
    postalCode: '83364',
    city: 'Hörbering'
  },
  {
    postalCode: '83364',
    city: 'Kendl'
  },
  {
    postalCode: '83364',
    city: 'Knappenfeld, Gem Surberg'
  },
  {
    postalCode: '83364',
    city: 'Krainwinkl'
  },
  {
    postalCode: '83364',
    city: 'Lehen in Surberg'
  },
  {
    postalCode: '83364',
    city: 'Lerchen'
  },
  {
    postalCode: '83364',
    city: 'Loch'
  },
  {
    postalCode: '83364',
    city: 'Mauerreuten'
  },
  {
    postalCode: '83364',
    city: 'Mitterleiten'
  },
  {
    postalCode: '83364',
    city: 'Mitterstatt'
  },
  {
    postalCode: '83364',
    city: 'Mühlpoint'
  },
  {
    postalCode: '83364',
    city: 'Neukirchen'
  },
  {
    postalCode: '83364',
    city: 'Oberachthal'
  },
  {
    postalCode: '83364',
    city: 'Oberhöhenwald'
  },
  {
    postalCode: '83364',
    city: 'Oberreut'
  },
  {
    postalCode: '83364',
    city: 'Point'
  },
  {
    postalCode: '83364',
    city: 'Rainer'
  },
  {
    postalCode: '83364',
    city: 'Roll'
  },
  {
    postalCode: '83364',
    city: 'Schnaidt'
  },
  {
    postalCode: '83364',
    city: 'Schütz'
  },
  {
    postalCode: '83364',
    city: 'Schwammgraben'
  },
  {
    postalCode: '83364',
    city: 'Schwarzenberg'
  },
  {
    postalCode: '83364',
    city: 'Spittenreut'
  },
  {
    postalCode: '83364',
    city: 'Sprung'
  },
  {
    postalCode: '83364',
    city: 'Stadl'
  },
  {
    postalCode: '83364',
    city: 'Strußberg'
  },
  {
    postalCode: '83364',
    city: 'Surbergbichl'
  },
  {
    postalCode: '83364',
    city: 'Vorderleiten'
  },
  {
    postalCode: '83364',
    city: 'Vorderloh'
  },
  {
    postalCode: '83364',
    city: 'Wald'
  },
  {
    postalCode: '83364',
    city: 'Weitwies, Gem Neukirchen'
  },
  {
    postalCode: '83364',
    city: 'Wetzelsberg'
  },
  {
    postalCode: '83364',
    city: 'Wildberg'
  },
  {
    postalCode: '83365',
    city: 'Nußdorf'
  },
  {
    postalCode: '83367',
    city: 'Ebing'
  },
  {
    postalCode: '83367',
    city: 'Parschall'
  },
  {
    postalCode: '83367',
    city: 'Petting'
  },
  {
    postalCode: '83367',
    city: 'Schuhegg'
  },
  {
    postalCode: '83367',
    city: 'Unterholzen'
  },
  {
    postalCode: '83368',
    city: 'Anning'
  },
  {
    postalCode: '83368',
    city: 'Daxberg'
  },
  {
    postalCode: '83368',
    city: 'Gigling'
  },
  {
    postalCode: '83368',
    city: 'Hochreit'
  },
  {
    postalCode: '83368',
    city: 'Mais'
  },
  {
    postalCode: '83368',
    city: 'Reit'
  },
  {
    postalCode: '83368',
    city: 'St Georgen'
  },
  {
    postalCode: '83368',
    city: 'Steineck'
  },
  {
    postalCode: '83368',
    city: 'Weisbrunn'
  },
  {
    postalCode: '83368',
    city: 'Weisham'
  },
  {
    postalCode: '83370',
    city: 'Seeon'
  },
  {
    postalCode: '83371',
    city: 'Buchberg'
  },
  {
    postalCode: '83371',
    city: 'Fasanenjäger'
  },
  {
    postalCode: '83371',
    city: 'Höhenberg'
  },
  {
    postalCode: '83371',
    city: 'Hohenester'
  },
  {
    postalCode: '83371',
    city: 'Holzreit'
  },
  {
    postalCode: '83371',
    city: 'Oberhaus'
  },
  {
    postalCode: '83371',
    city: 'Plattenberg'
  },
  {
    postalCode: '83371',
    city: 'Roitham'
  },
  {
    postalCode: '83371',
    city: 'Stein'
  },
  {
    postalCode: '83371',
    city: 'Zieglstadl'
  },
  {
    postalCode: '83373',
    city: 'Taching'
  },
  {
    postalCode: '83374',
    city: 'Arleting'
  },
  {
    postalCode: '83374',
    city: 'Frauenhurt'
  },
  {
    postalCode: '83374',
    city: 'Hölzl'
  },
  {
    postalCode: '83374',
    city: 'Hörzing'
  },
  {
    postalCode: '83374',
    city: 'Hurt'
  },
  {
    postalCode: '83374',
    city: 'Kirchstätt'
  },
  {
    postalCode: '83374',
    city: 'Niedling'
  },
  {
    postalCode: '83374',
    city: 'Oderberg'
  },
  {
    postalCode: '83374',
    city: 'Parzing'
  },
  {
    postalCode: '83374',
    city: 'Schmieding'
  },
  {
    postalCode: '83374',
    city: 'Traunwalchen'
  },
  {
    postalCode: '83374',
    city: 'Walchenberg'
  },
  {
    postalCode: '83374',
    city: 'Zweckham'
  },
  {
    postalCode: '83376',
    city: 'Apperting'
  },
  {
    postalCode: '83376',
    city: 'Höllthal'
  },
  {
    postalCode: '83376',
    city: 'Steinrab'
  },
  {
    postalCode: '83376',
    city: 'Truchtlaching'
  },
  {
    postalCode: '83377',
    city: 'Lug'
  },
  {
    postalCode: '83377',
    city: 'Vachendorf'
  },
  {
    postalCode: '83379',
    city: 'Egerdach'
  },
  {
    postalCode: '83379',
    city: 'Fritzenweng'
  },
  {
    postalCode: '83379',
    city: 'Greinach'
  },
  {
    postalCode: '83379',
    city: 'Mooshäusl'
  },
  {
    postalCode: '83379',
    city: 'Moospoint'
  },
  {
    postalCode: '83379',
    city: 'Oberau'
  },
  {
    postalCode: '83379',
    city: 'Reichwimm'
  },
  {
    postalCode: '83379',
    city: 'Selberting'
  },
  {
    postalCode: '83379',
    city: 'Töfenreut'
  },
  {
    postalCode: '83379',
    city: 'Voitswinkl'
  },
  {
    postalCode: '83379',
    city: 'Wald'
  },
  {
    postalCode: '83379',
    city: 'Weberhäusl'
  },
  {
    postalCode: '83379',
    city: 'Weibhausen'
  },
  {
    postalCode: '83395',
    city: 'Freilassing'
  },
  {
    postalCode: '83404',
    city: 'Ainring'
  },
  {
    postalCode: '83410',
    city: 'Laufen'
  },
  {
    postalCode: '83413',
    city: 'Fridolfing'
  },
  {
    postalCode: '83417',
    city: 'Kirchanschöring'
  },
  {
    postalCode: '83435',
    city: 'Bad Reichenhall'
  },
  {
    postalCode: '83451',
    city: 'Piding'
  },
  {
    postalCode: '83454',
    city: 'Anger'
  },
  {
    postalCode: '83457',
    city: 'Bayerisch Gmain'
  },
  {
    postalCode: '83458',
    city: 'Schneizlreuth'
  },
  {
    postalCode: '83471',
    city: 'Berchtesgaden'
  },
  {
    postalCode: '83471',
    city: 'Engedey'
  },
  {
    postalCode: '83471',
    city: 'Kärlingerhaus'
  },
  {
    postalCode: '83471',
    city: 'Purtschellerhaus'
  },
  {
    postalCode: '83471',
    city: 'Schönau'
  },
  {
    postalCode: '83471',
    city: 'Stanggaß'
  },
  {
    postalCode: '83471',
    city: 'Stöhrhaus'
  },
  {
    postalCode: '83483',
    city: 'Bischofswiesen'
  },
  {
    postalCode: '83486',
    city: 'Blaueishütte'
  },
  {
    postalCode: '83486',
    city: 'Ramsau'
  },
  {
    postalCode: '83486',
    city: 'Watzmannhaus'
  },
  {
    postalCode: '83486',
    city: 'Wimbachgrieshütte'
  },
  {
    postalCode: '83486',
    city: 'Wimbachschloß'
  },
  {
    postalCode: '83487',
    city: 'Marktschellenberg'
  },
  {
    postalCode: '83489',
    city: 'Strub'
  },
  {
    postalCode: '83512',
    city: 'Langwiederberg'
  },
  {
    postalCode: '83512',
    city: 'Reitmehring'
  },
  {
    postalCode: '83512',
    city: 'Wasserburg'
  },
  {
    postalCode: '83527',
    city: 'Haag'
  },
  {
    postalCode: '83527',
    city: 'Kirchdorf'
  },
  {
    postalCode: '83530',
    city: 'Schnaitsee'
  },
  {
    postalCode: '83533',
    city: 'Edling'
  },
  {
    postalCode: '83536',
    city: 'Gars'
  },
  {
    postalCode: '83539',
    city: 'Pfaffing'
  },
  {
    postalCode: '83543',
    city: 'Rott'
  },
  {
    postalCode: '83544',
    city: 'Albaching'
  },
  {
    postalCode: '83546',
    city: 'Au'
  },
  {
    postalCode: '83546',
    city: 'Berg'
  },
  {
    postalCode: '83546',
    city: 'Biburg'
  },
  {
    postalCode: '83546',
    city: 'Dornhecken'
  },
  {
    postalCode: '83546',
    city: 'Elbrechting'
  },
  {
    postalCode: '83546',
    city: 'Ensdorf'
  },
  {
    postalCode: '83546',
    city: 'Frimberg'
  },
  {
    postalCode: '83546',
    city: 'Gaisberg'
  },
  {
    postalCode: '83546',
    city: 'Gerer'
  },
  {
    postalCode: '83546',
    city: 'Höllthal'
  },
  {
    postalCode: '83546',
    city: 'Hopfgarten'
  },
  {
    postalCode: '83546',
    city: 'Kronberg'
  },
  {
    postalCode: '83546',
    city: 'Obereinöd'
  },
  {
    postalCode: '83546',
    city: 'Reisleite'
  },
  {
    postalCode: '83546',
    city: 'Sattlthambach'
  },
  {
    postalCode: '83546',
    city: 'Schachen'
  },
  {
    postalCode: '83546',
    city: 'Stampfl'
  },
  {
    postalCode: '83546',
    city: 'Steinau'
  },
  {
    postalCode: '83546',
    city: 'Tiefenweg'
  },
  {
    postalCode: '83546',
    city: 'Trescherberg'
  },
  {
    postalCode: '83546',
    city: 'Untereinöd'
  },
  {
    postalCode: '83546',
    city: 'Untermödling'
  },
  {
    postalCode: '83546',
    city: 'Weingarten'
  },
  {
    postalCode: '83546',
    city: 'Winterberg'
  },
  {
    postalCode: '83546',
    city: 'Wörth'
  },
  {
    postalCode: '83547',
    city: 'Babensham'
  },
  {
    postalCode: '83549',
    city: 'Eiselfing'
  },
  {
    postalCode: '83550',
    city: 'Emmering'
  },
  {
    postalCode: '83552',
    city: 'Achen'
  },
  {
    postalCode: '83552',
    city: 'Durrhausen'
  },
  {
    postalCode: '83552',
    city: 'Eck'
  },
  {
    postalCode: '83552',
    city: 'Englstetten'
  },
  {
    postalCode: '83552',
    city: 'Eßbaum'
  },
  {
    postalCode: '83552',
    city: 'Evenhausen'
  },
  {
    postalCode: '83552',
    city: 'Feichten'
  },
  {
    postalCode: '83552',
    city: 'Freinberg'
  },
  {
    postalCode: '83552',
    city: 'Froitshub'
  },
  {
    postalCode: '83552',
    city: 'Furth'
  },
  {
    postalCode: '83552',
    city: 'Grub'
  },
  {
    postalCode: '83552',
    city: 'Halmannsöd'
  },
  {
    postalCode: '83552',
    city: 'Hatthal'
  },
  {
    postalCode: '83552',
    city: 'Hinterholzmühle'
  },
  {
    postalCode: '83552',
    city: 'Hofstätt'
  },
  {
    postalCode: '83552',
    city: 'Hohenöd'
  },
  {
    postalCode: '83552',
    city: 'Hub'
  },
  {
    postalCode: '83552',
    city: 'Kirchensur'
  },
  {
    postalCode: '83552',
    city: 'Lacken'
  },
  {
    postalCode: '83552',
    city: 'Leiteneck'
  },
  {
    postalCode: '83552',
    city: 'Lindach'
  },
  {
    postalCode: '83552',
    city: 'Locking'
  },
  {
    postalCode: '83552',
    city: 'Mais'
  },
  {
    postalCode: '83552',
    city: 'Osendorf'
  },
  {
    postalCode: '83552',
    city: 'Pfaffing'
  },
  {
    postalCode: '83552',
    city: 'Reit, Gem Kirchensur'
  },
  {
    postalCode: '83552',
    city: 'Ried'
  },
  {
    postalCode: '83552',
    city: 'Schachen'
  },
  {
    postalCode: '83552',
    city: 'Scherer'
  },
  {
    postalCode: '83552',
    city: 'Seeleiten'
  },
  {
    postalCode: '83552',
    city: 'Spittersberg'
  },
  {
    postalCode: '83552',
    city: 'Stephanskirchen'
  },
  {
    postalCode: '83552',
    city: 'Streit'
  },
  {
    postalCode: '83552',
    city: 'Surau'
  },
  {
    postalCode: '83552',
    city: 'Unteröd'
  },
  {
    postalCode: '83552',
    city: 'Unterstreit'
  },
  {
    postalCode: '83552',
    city: 'Vorderholzmühle'
  },
  {
    postalCode: '83552',
    city: 'Weitmoos'
  },
  {
    postalCode: '83552',
    city: 'Wimpasing'
  },
  {
    postalCode: '83553',
    city: 'Frauenneuharting'
  },
  {
    postalCode: '83555',
    city: 'Gars Bahnhof'
  },
  {
    postalCode: '83555',
    city: 'Haiden'
  },
  {
    postalCode: '83555',
    city: 'Hochstraß'
  },
  {
    postalCode: '83555',
    city: 'Mailham'
  },
  {
    postalCode: '83555',
    city: 'Thal'
  },
  {
    postalCode: '83556',
    city: 'Griesstätt'
  },
  {
    postalCode: '83558',
    city: 'Maitenbeth'
  },
  {
    postalCode: '83559',
    city: 'Heuwinkl'
  },
  {
    postalCode: '83559',
    city: 'Krücklham'
  },
  {
    postalCode: '83559',
    city: 'Lohen'
  },
  {
    postalCode: '83559',
    city: 'Mittergars'
  },
  {
    postalCode: '83559',
    city: 'Reiser'
  },
  {
    postalCode: '83561',
    city: 'Ramerberg'
  },
  {
    postalCode: '83562',
    city: 'Rechtmehring'
  },
  {
    postalCode: '83564',
    city: 'Soyen'
  },
  {
    postalCode: '83565',
    city: 'Aichat'
  },
  {
    postalCode: '83565',
    city: 'Anger'
  },
  {
    postalCode: '83565',
    city: 'Ast'
  },
  {
    postalCode: '83565',
    city: 'Biebing'
  },
  {
    postalCode: '83565',
    city: 'Eichbichl'
  },
  {
    postalCode: '83565',
    city: 'Eschenlohe'
  },
  {
    postalCode: '83565',
    city: 'Gersdorf'
  },
  {
    postalCode: '83565',
    city: 'Hagenberg'
  },
  {
    postalCode: '83565',
    city: 'Heimgarten'
  },
  {
    postalCode: '83565',
    city: 'Kleinaschau'
  },
  {
    postalCode: '83565',
    city: 'Moosen'
  },
  {
    postalCode: '83565',
    city: 'Oed'
  },
  {
    postalCode: '83565',
    city: 'Ried'
  },
  {
    postalCode: '83565',
    city: 'Schaurach'
  },
  {
    postalCode: '83565',
    city: 'Tegernau'
  },
  {
    postalCode: '83565',
    city: 'Wimpersing'
  },
  {
    postalCode: '83567',
    city: 'Unterreit'
  },
  {
    postalCode: '83569',
    city: 'Vogtareuth'
  },
  {
    postalCode: '83607',
    city: 'Holzkirchen'
  },
  {
    postalCode: '83620',
    city: 'Feldkirchen-Westerham'
  },
  {
    postalCode: '83623',
    city: 'Dietramszell'
  },
  {
    postalCode: '83624',
    city: 'Otterfing'
  },
  {
    postalCode: '83626',
    city: 'Grabenstoffl'
  },
  {
    postalCode: '83626',
    city: 'Valley'
  },
  {
    postalCode: '83627',
    city: 'Warngau'
  },
  {
    postalCode: '83629',
    city: 'Weyarn'
  },
  {
    postalCode: '83646',
    city: 'Bad Tölz'
  },
  {
    postalCode: '83646',
    city: 'Wackersberg'
  },
  {
    postalCode: '83661',
    city: 'Lenggries'
  },
  {
    postalCode: '83666',
    city: 'Waakirchen'
  },
  {
    postalCode: '83670',
    city: 'Bad Heilbrunn'
  },
  {
    postalCode: '83671',
    city: 'Benediktbeuern'
  },
  {
    postalCode: '83673',
    city: 'Bichl'
  },
  {
    postalCode: '83674',
    city: 'Gaißach'
  },
  {
    postalCode: '83676',
    city: 'Jachenau'
  },
  {
    postalCode: '83677',
    city: 'Greiling'
  },
  {
    postalCode: '83677',
    city: 'Reichersbeuern'
  },
  {
    postalCode: '83679',
    city: 'Sachsenkam'
  },
  {
    postalCode: '83684',
    city: 'Tegernsee'
  },
  {
    postalCode: '83700',
    city: 'Oberhof'
  },
  {
    postalCode: '83700',
    city: 'Reitrain'
  },
  {
    postalCode: '83700',
    city: 'Rottach-Egern'
  },
  {
    postalCode: '83700',
    city: 'Weißach'
  },
  {
    postalCode: '83703',
    city: 'Gmund'
  },
  {
    postalCode: '83707',
    city: 'Bad Wiessee'
  },
  {
    postalCode: '83707',
    city: 'Ringsee'
  },
  {
    postalCode: '83708',
    city: 'Kreuth'
  },
  {
    postalCode: '83714',
    city: 'Fußstall'
  },
  {
    postalCode: '83714',
    city: 'Kogel'
  },
  {
    postalCode: '83714',
    city: 'Lehner'
  },
  {
    postalCode: '83714',
    city: 'Miesbach'
  },
  {
    postalCode: '83714',
    city: 'Unterschönberg'
  },
  {
    postalCode: '83727',
    city: 'Rotwandhaus'
  },
  {
    postalCode: '83727',
    city: 'Schliersee'
  },
  {
    postalCode: '83730',
    city: 'Fischbachau'
  },
  {
    postalCode: '83734',
    city: 'Hausham'
  },
  {
    postalCode: '83735',
    city: 'Bayrischzell'
  },
  {
    postalCode: '83737',
    city: 'Irschenberg'
  },
  {
    postalCode: '83739',
    city: 'Ahrain'
  },
  {
    postalCode: '83739',
    city: 'Angl'
  },
  {
    postalCode: '83739',
    city: 'Aschbach'
  },
  {
    postalCode: '83739',
    city: 'Bach'
  },
  {
    postalCode: '83739',
    city: 'Bichl'
  },
  {
    postalCode: '83739',
    city: 'Briefer'
  },
  {
    postalCode: '83739',
    city: 'Deining'
  },
  {
    postalCode: '83739',
    city: 'Ehgarten'
  },
  {
    postalCode: '83739',
    city: 'Eyrain'
  },
  {
    postalCode: '83739',
    city: 'Furt'
  },
  {
    postalCode: '83739',
    city: 'Großhub'
  },
  {
    postalCode: '83739',
    city: 'Großschönau'
  },
  {
    postalCode: '83739',
    city: 'Grub'
  },
  {
    postalCode: '83739',
    city: 'Grund'
  },
  {
    postalCode: '83739',
    city: 'Grundbach'
  },
  {
    postalCode: '83739',
    city: 'Harraß'
  },
  {
    postalCode: '83739',
    city: 'Hatzl'
  },
  {
    postalCode: '83739',
    city: 'Heißkistler'
  },
  {
    postalCode: '83739',
    city: 'Hilgenrain'
  },
  {
    postalCode: '83739',
    city: 'Hinterholz'
  },
  {
    postalCode: '83739',
    city: 'Hofreuth'
  },
  {
    postalCode: '83739',
    city: 'Kalten'
  },
  {
    postalCode: '83739',
    city: 'Kleinhub'
  },
  {
    postalCode: '83739',
    city: 'Köck'
  },
  {
    postalCode: '83739',
    city: 'Kolmberg'
  },
  {
    postalCode: '83739',
    city: 'Lehermann'
  },
  {
    postalCode: '83739',
    city: 'Loder'
  },
  {
    postalCode: '83739',
    city: 'Moos'
  },
  {
    postalCode: '83739',
    city: 'Moosweber'
  },
  {
    postalCode: '83739',
    city: 'Neuradthal'
  },
  {
    postalCode: '83739',
    city: 'Niklasreuth'
  },
  {
    postalCode: '83739',
    city: 'Oberkretzach'
  },
  {
    postalCode: '83739',
    city: 'Offenstätter'
  },
  {
    postalCode: '83739',
    city: 'Radthal'
  },
  {
    postalCode: '83739',
    city: 'Riedl'
  },
  {
    postalCode: '83739',
    city: 'Schlosser'
  },
  {
    postalCode: '83739',
    city: 'Sonnenreuth'
  },
  {
    postalCode: '83739',
    city: 'Starzberg'
  },
  {
    postalCode: '83739',
    city: 'Streitau'
  },
  {
    postalCode: '83739',
    city: 'Wartbichl'
  },
  {
    postalCode: '83739',
    city: 'Wiedmann'
  },
  {
    postalCode: '83739',
    city: 'Wieser'
  },
  {
    postalCode: '83739',
    city: 'Windwart'
  },
  {
    postalCode: '83739',
    city: 'Wörnsmühl'
  },
  {
    postalCode: '83739',
    city: 'Zieglhaus'
  },
  {
    postalCode: '84028',
    city: 'Landshut'
  },
  {
    postalCode: '84030',
    city: 'Ergolding'
  },
  {
    postalCode: '84030',
    city: 'Landshut'
  },
  {
    postalCode: '84032',
    city: 'Altdorf'
  },
  {
    postalCode: '84032',
    city: 'Landshut'
  },
  {
    postalCode: '84032',
    city: 'Linden'
  },
  {
    postalCode: '84034',
    city: 'Landshut'
  },
  {
    postalCode: '84036',
    city: 'Kumhausen'
  },
  {
    postalCode: '84036',
    city: 'Landshut'
  },
  {
    postalCode: '84036',
    city: 'Seepoint'
  },
  {
    postalCode: '84048',
    city: 'Mainburg'
  },
  {
    postalCode: '84051',
    city: 'Essenbach'
  },
  {
    postalCode: '84056',
    city: 'Rottenburg'
  },
  {
    postalCode: '84061',
    city: 'Ergoldsbach'
  },
  {
    postalCode: '84066',
    city: 'Mallersdorf-Pfaffenberg'
  },
  {
    postalCode: '84069',
    city: 'Schierling'
  },
  {
    postalCode: '84072',
    city: 'Au'
  },
  {
    postalCode: '84076',
    city: 'Pfeffenhausen'
  },
  {
    postalCode: '84076',
    city: 'Spitzau, Gem Attenhofen'
  },
  {
    postalCode: '84079',
    city: 'Bruckberg'
  },
  {
    postalCode: '84082',
    city: 'Laberweinting'
  },
  {
    postalCode: '84085',
    city: 'Langquaid'
  },
  {
    postalCode: '84088',
    city: 'Neufahrn'
  },
  {
    postalCode: '84088',
    city: 'Salzburg'
  },
  {
    postalCode: '84089',
    city: 'Aiglsbach'
  },
  {
    postalCode: '84091',
    city: 'Attenhofen'
  },
  {
    postalCode: '84092',
    city: 'Bayerbach'
  },
  {
    postalCode: '84094',
    city: 'Elsendorf'
  },
  {
    postalCode: '84095',
    city: 'Furth'
  },
  {
    postalCode: '84097',
    city: 'Herrngiersdorf'
  },
  {
    postalCode: '84098',
    city: 'Hohenthann'
  },
  {
    postalCode: '84100',
    city: 'Niederaichbach'
  },
  {
    postalCode: '84101',
    city: 'Obersüßbach'
  },
  {
    postalCode: '84103',
    city: 'Postau'
  },
  {
    postalCode: '84104',
    city: 'Rudelzhausen'
  },
  {
    postalCode: '84106',
    city: 'Volkenschwand'
  },
  {
    postalCode: '84107',
    city: 'Weihmichl'
  },
  {
    postalCode: '84109',
    city: 'Wörth'
  },
  {
    postalCode: '84130',
    city: 'Dingolfing'
  },
  {
    postalCode: '84137',
    city: 'Aiteröd'
  },
  {
    postalCode: '84137',
    city: 'Vilsbiburg'
  },
  {
    postalCode: '84140',
    city: 'Gangkofen'
  },
  {
    postalCode: '84144',
    city: 'Geisenhausen'
  },
  {
    postalCode: '84149',
    city: 'Velden'
  },
  {
    postalCode: '84152',
    city: 'Mengkofen'
  },
  {
    postalCode: '84155',
    city: 'Bodenkirchen'
  },
  {
    postalCode: '84160',
    city: 'Frontenhausen'
  },
  {
    postalCode: '84163',
    city: 'Marklkofen'
  },
  {
    postalCode: '84163',
    city: 'Wunder'
  },
  {
    postalCode: '84164',
    city: 'Dreifaltigkeitsberg, Gem Weng'
  },
  {
    postalCode: '84164',
    city: 'Moosthenning'
  },
  {
    postalCode: '84166',
    city: 'Adlkofen'
  },
  {
    postalCode: '84168',
    city: 'Aham'
  },
  {
    postalCode: '84169',
    city: 'Altfraunhofen'
  },
  {
    postalCode: '84171',
    city: 'Baierbach'
  },
  {
    postalCode: '84172',
    city: 'Buch'
  },
  {
    postalCode: '84174',
    city: 'Eching'
  },
  {
    postalCode: '84175',
    city: 'Gerzen'
  },
  {
    postalCode: '84175',
    city: 'Schalkham'
  },
  {
    postalCode: '84177',
    city: 'Gottfrieding'
  },
  {
    postalCode: '84178',
    city: 'Kröning'
  },
  {
    postalCode: '84180',
    city: 'Loiching'
  },
  {
    postalCode: '84181',
    city: 'Neufraunhofen'
  },
  {
    postalCode: '84183',
    city: 'Niederviehbach'
  },
  {
    postalCode: '84184',
    city: 'Tiefenbach'
  },
  {
    postalCode: '84186',
    city: 'Vilsheim'
  },
  {
    postalCode: '84187',
    city: 'Weng'
  },
  {
    postalCode: '84189',
    city: 'Wurmsham'
  },
  {
    postalCode: '84307',
    city: 'Eggenfelden'
  },
  {
    postalCode: '84323',
    city: 'Massing'
  },
  {
    postalCode: '84326',
    city: 'Falkenberg'
  },
  {
    postalCode: '84326',
    city: 'Rimbach'
  },
  {
    postalCode: '84329',
    city: 'Wurmannsquick'
  },
  {
    postalCode: '84332',
    city: 'Hebertsfelden'
  },
  {
    postalCode: '84333',
    city: 'Malgersdorf'
  },
  {
    postalCode: '84335',
    city: 'Mitterskirchen'
  },
  {
    postalCode: '84337',
    city: 'Schönau'
  },
  {
    postalCode: '84339',
    city: 'Unterdietfurt'
  },
  {
    postalCode: '84347',
    city: 'Pfarrkirchen'
  },
  {
    postalCode: '84359',
    city: 'Simbach'
  },
  {
    postalCode: '84364',
    city: 'Bad Birnbach'
  },
  {
    postalCode: '84367',
    city: 'Reut'
  },
  {
    postalCode: '84367',
    city: 'Tann'
  },
  {
    postalCode: '84367',
    city: 'Zeilarn'
  },
  {
    postalCode: '84371',
    city: 'Dachsbergau'
  },
  {
    postalCode: '84371',
    city: 'Triftern'
  },
  {
    postalCode: '84375',
    city: 'Kirchdorf'
  },
  {
    postalCode: '84378',
    city: 'Dietersburg'
  },
  {
    postalCode: '84381',
    city: 'Johanniskirchen'
  },
  {
    postalCode: '84384',
    city: 'Wittibreut'
  },
  {
    postalCode: '84385',
    city: 'Egglham'
  },
  {
    postalCode: '84385',
    city: 'Schachahof'
  },
  {
    postalCode: '84387',
    city: 'Julbach'
  },
  {
    postalCode: '84389',
    city: 'Postmünster'
  },
  {
    postalCode: '84405',
    city: 'Brunn'
  },
  {
    postalCode: '84405',
    city: 'Dorfen'
  },
  {
    postalCode: '84416',
    city: 'Inning'
  },
  {
    postalCode: '84416',
    city: 'Taufkirchen'
  },
  {
    postalCode: '84419',
    city: 'Birnbach'
  },
  {
    postalCode: '84419',
    city: 'Obertaufkirchen'
  },
  {
    postalCode: '84419',
    city: 'Schwindegg'
  },
  {
    postalCode: '84424',
    city: 'Isen'
  },
  {
    postalCode: '84427',
    city: 'Sankt Wolfgang'
  },
  {
    postalCode: '84428',
    city: 'Bachzelten'
  },
  {
    postalCode: '84428',
    city: 'Buchbach'
  },
  {
    postalCode: '84431',
    city: 'Heldenstein'
  },
  {
    postalCode: '84431',
    city: 'Rattenkirchen'
  },
  {
    postalCode: '84432',
    city: 'Hohenpolding'
  },
  {
    postalCode: '84434',
    city: 'Kirchberg'
  },
  {
    postalCode: '84435',
    city: 'Lengdorf'
  },
  {
    postalCode: '84437',
    city: 'Reichertsheim'
  },
  {
    postalCode: '84439',
    city: 'Steinkirchen'
  },
  {
    postalCode: '84453',
    city: 'Mühldorf'
  },
  {
    postalCode: '84478',
    city: 'Waldkraiburg'
  },
  {
    postalCode: '84489',
    city: 'Burghausen'
  },
  {
    postalCode: '84494',
    city: 'Lohkirchen'
  },
  {
    postalCode: '84494',
    city: 'Neumarkt-Sankt Veit'
  },
  {
    postalCode: '84494',
    city: 'Niederbergkirchen'
  },
  {
    postalCode: '84494',
    city: 'Niedertaufkirchen'
  },
  {
    postalCode: '84503',
    city: 'Altötting'
  },
  {
    postalCode: '84503',
    city: 'Holzhauser Eck'
  },
  {
    postalCode: '84508',
    city: 'Burgkirchen'
  },
  {
    postalCode: '84513',
    city: 'Erharting'
  },
  {
    postalCode: '84513',
    city: 'Töging'
  },
  {
    postalCode: '84518',
    city: 'Garching'
  },
  {
    postalCode: '84524',
    city: 'Neuötting'
  },
  {
    postalCode: '84529',
    city: 'Tittmoning'
  },
  {
    postalCode: '84533',
    city: 'Haiming'
  },
  {
    postalCode: '84533',
    city: 'Marktl'
  },
  {
    postalCode: '84533',
    city: 'Stammham'
  },
  {
    postalCode: '84539',
    city: 'Ampfing'
  },
  {
    postalCode: '84539',
    city: 'Zangberg'
  },
  {
    postalCode: '84543',
    city: 'Winhöring'
  },
  {
    postalCode: '84544',
    city: 'Aschau'
  },
  {
    postalCode: '84546',
    city: 'Egglkofen'
  },
  {
    postalCode: '84547',
    city: 'Emmerting'
  },
  {
    postalCode: '84549',
    city: 'Engelsberg'
  },
  {
    postalCode: '84550',
    city: 'Feichten'
  },
  {
    postalCode: '84552',
    city: 'Geratskirchen'
  },
  {
    postalCode: '84553',
    city: 'Halsbach'
  },
  {
    postalCode: '84555',
    city: 'Jettenbach'
  },
  {
    postalCode: '84556',
    city: 'Kastl'
  },
  {
    postalCode: '84558',
    city: 'Kirchweidach'
  },
  {
    postalCode: '84558',
    city: 'Tyrlaching'
  },
  {
    postalCode: '84559',
    city: 'Kraiburg'
  },
  {
    postalCode: '84561',
    city: 'Mehring'
  },
  {
    postalCode: '84562',
    city: 'Mettenheim'
  },
  {
    postalCode: '84564',
    city: 'Oberbergkirchen'
  },
  {
    postalCode: '84565',
    city: 'Oberneukirchen'
  },
  {
    postalCode: '84567',
    city: 'Erlbach'
  },
  {
    postalCode: '84567',
    city: 'Perach'
  },
  {
    postalCode: '84568',
    city: 'Pleiskirchen'
  },
  {
    postalCode: '84570',
    city: 'Polling'
  },
  {
    postalCode: '84571',
    city: 'Reischach'
  },
  {
    postalCode: '84573',
    city: 'Schönberg'
  },
  {
    postalCode: '84574',
    city: 'Taufkirchen'
  },
  {
    postalCode: '84576',
    city: 'Teising'
  },
  {
    postalCode: '84577',
    city: 'Tüßling'
  },
  {
    postalCode: '84579',
    city: 'Unterneukirchen'
  },
  {
    postalCode: '85049',
    city: 'Ingolstadt'
  },
  {
    postalCode: '85051',
    city: 'Ingolstadt'
  },
  {
    postalCode: '85053',
    city: 'Ingolstadt'
  },
  {
    postalCode: '85055',
    city: 'Ingolstadt'
  },
  {
    postalCode: '85057',
    city: 'Ingolstadt'
  },
  {
    postalCode: '85072',
    city: 'Eichstätt'
  },
  {
    postalCode: '85072',
    city: 'Harthof'
  },
  {
    postalCode: '85077',
    city: 'Manching'
  },
  {
    postalCode: '85080',
    city: 'Gaimersheim'
  },
  {
    postalCode: '85084',
    city: 'Reichertshofen'
  },
  {
    postalCode: '85088',
    city: 'Vohburg'
  },
  {
    postalCode: '85092',
    city: 'Kösching'
  },
  {
    postalCode: '85092',
    city: 'Köschinger Waldhaus'
  },
  {
    postalCode: '85095',
    city: 'Denkendorf'
  },
  {
    postalCode: '85098',
    city: 'Großmehring'
  },
  {
    postalCode: '85101',
    city: 'Lenting'
  },
  {
    postalCode: '85104',
    city: 'Pförring'
  },
  {
    postalCode: '85107',
    city: 'Baar-Ebenhausen'
  },
  {
    postalCode: '85110',
    city: 'Kipfenberg'
  },
  {
    postalCode: '85111',
    city: 'Adelschlag'
  },
  {
    postalCode: '85113',
    city: 'Böhmfeld'
  },
  {
    postalCode: '85114',
    city: 'Buxheim'
  },
  {
    postalCode: '85116',
    city: 'Egweil'
  },
  {
    postalCode: '85117',
    city: 'Eitensheim'
  },
  {
    postalCode: '85119',
    city: 'Ernsgaden'
  },
  {
    postalCode: '85120',
    city: 'Hepberg'
  },
  {
    postalCode: '85122',
    city: 'Hitzhofen'
  },
  {
    postalCode: '85123',
    city: 'Karlskron'
  },
  {
    postalCode: '85125',
    city: 'Kinding'
  },
  {
    postalCode: '85126',
    city: 'Münchsmünster'
  },
  {
    postalCode: '85128',
    city: 'Nassenfels'
  },
  {
    postalCode: '85129',
    city: 'Oberdolling'
  },
  {
    postalCode: '85131',
    city: 'Pollenfeld'
  },
  {
    postalCode: '85132',
    city: 'Schernfeld'
  },
  {
    postalCode: '85134',
    city: 'Stammham'
  },
  {
    postalCode: '85135',
    city: 'Titting'
  },
  {
    postalCode: '85137',
    city: 'Walting'
  },
  {
    postalCode: '85139',
    city: 'Wettstetten'
  },
  {
    postalCode: '85221',
    city: 'Dachau'
  },
  {
    postalCode: '85229',
    city: 'Markt Indersdorf'
  },
  {
    postalCode: '85232',
    city: 'Bergkirchen'
  },
  {
    postalCode: '85235',
    city: 'Odelzhausen'
  },
  {
    postalCode: '85235',
    city: 'Pfaffenhofen'
  },
  {
    postalCode: '85238',
    city: 'Petershausen'
  },
  {
    postalCode: '85241',
    city: 'Hebertshausen'
  },
  {
    postalCode: '85244',
    city: 'Röhrmoos'
  },
  {
    postalCode: '85247',
    city: 'Schwabhausen'
  },
  {
    postalCode: '85250',
    city: 'Altomünster'
  },
  {
    postalCode: '85253',
    city: 'Erdweg'
  },
  {
    postalCode: '85254',
    city: 'Einsbach'
  },
  {
    postalCode: '85254',
    city: 'Fuchsberg'
  },
  {
    postalCode: '85254',
    city: 'Sulzemoos'
  },
  {
    postalCode: '85256',
    city: 'Vierkirchen'
  },
  {
    postalCode: '85258',
    city: 'Weichs'
  },
  {
    postalCode: '85259',
    city: 'Wiedenzhausen'
  },
  {
    postalCode: '85276',
    city: 'Feldmühle'
  },
  {
    postalCode: '85276',
    city: 'Hettenshausen'
  },
  {
    postalCode: '85276',
    city: 'Jahnhöhe'
  },
  {
    postalCode: '85276',
    city: 'Pfaffenhofen'
  },
  {
    postalCode: '85276',
    city: 'Posthof'
  },
  {
    postalCode: '85276',
    city: 'Reisgang'
  },
  {
    postalCode: '85276',
    city: 'Washof'
  },
  {
    postalCode: '85276',
    city: 'Webling'
  },
  {
    postalCode: '85283',
    city: 'Wolnzach'
  },
  {
    postalCode: '85290',
    city: 'Geisenfeld'
  },
  {
    postalCode: '85293',
    city: 'Reichertshausen'
  },
  {
    postalCode: '85296',
    city: 'Rohrbach'
  },
  {
    postalCode: '85298',
    city: 'Scheyern'
  },
  {
    postalCode: '85301',
    city: 'Schweitenkirchen'
  },
  {
    postalCode: '85302',
    city: 'Gerolsbach'
  },
  {
    postalCode: '85304',
    city: 'Ehrensberg'
  },
  {
    postalCode: '85304',
    city: 'Harres'
  },
  {
    postalCode: '85304',
    city: 'Ilmmünster'
  },
  {
    postalCode: '85304',
    city: 'Leiten'
  },
  {
    postalCode: '85304',
    city: 'Prambach'
  },
  {
    postalCode: '85304',
    city: 'Schaibmaierhof'
  },
  {
    postalCode: '85304',
    city: 'Winden'
  },
  {
    postalCode: '85305',
    city: 'Jetzendorf'
  },
  {
    postalCode: '85307',
    city: 'Entrischenbrunn'
  },
  {
    postalCode: '85307',
    city: 'Paunzhausen'
  },
  {
    postalCode: '85307',
    city: 'Streitberg'
  },
  {
    postalCode: '85309',
    city: 'Pörnbach'
  },
  {
    postalCode: '85354',
    city: 'Freising'
  },
  {
    postalCode: '85356',
    city: 'Freising'
  },
  {
    postalCode: '85356',
    city: 'München-Flughafen'
  },
  {
    postalCode: '85368',
    city: 'Moosburg'
  },
  {
    postalCode: '85368',
    city: 'Wang'
  },
  {
    postalCode: '85375',
    city: 'Neufahrn'
  },
  {
    postalCode: '85376',
    city: 'Giggenhausen'
  },
  {
    postalCode: '85376',
    city: 'Hetzenhausen'
  },
  {
    postalCode: '85376',
    city: 'Massenhausen'
  },
  {
    postalCode: '85376',
    city: 'Moosmühle'
  },
  {
    postalCode: '85376',
    city: 'Schaidenhausen'
  },
  {
    postalCode: '85386',
    city: 'Eching'
  },
  {
    postalCode: '85391',
    city: 'Allershausen'
  },
  {
    postalCode: '85395',
    city: 'Attenkirchen'
  },
  {
    postalCode: '85395',
    city: 'Oberholzhäuseln'
  },
  {
    postalCode: '85395',
    city: 'Wolfersdorf'
  },
  {
    postalCode: '85399',
    city: 'Hallbergmoos'
  },
  {
    postalCode: '85402',
    city: 'Kranzberg'
  },
  {
    postalCode: '85405',
    city: 'Nandlstadt'
  },
  {
    postalCode: '85406',
    city: 'Wälschbuch'
  },
  {
    postalCode: '85406',
    city: 'Zolling'
  },
  {
    postalCode: '85408',
    city: 'Gammelsdorf'
  },
  {
    postalCode: '85410',
    city: 'Haag'
  },
  {
    postalCode: '85411',
    city: 'Hohenkammer'
  },
  {
    postalCode: '85413',
    city: 'Hörgertshausen'
  },
  {
    postalCode: '85414',
    city: 'Kirchdorf'
  },
  {
    postalCode: '85416',
    city: 'Langenbach'
  },
  {
    postalCode: '85417',
    city: 'Marzling'
  },
  {
    postalCode: '85419',
    city: 'Mauern'
  },
  {
    postalCode: '85435',
    city: 'Erding'
  },
  {
    postalCode: '85445',
    city: 'Oberding'
  },
  {
    postalCode: '85447',
    city: 'Fraunberg'
  },
  {
    postalCode: '85447',
    city: 'Weg'
  },
  {
    postalCode: '85452',
    city: 'Moosinning'
  },
  {
    postalCode: '85456',
    city: 'Wartenberg'
  },
  {
    postalCode: '85457',
    city: 'Wörth'
  },
  {
    postalCode: '85459',
    city: 'Berglern'
  },
  {
    postalCode: '85461',
    city: 'Bockhorn'
  },
  {
    postalCode: '85462',
    city: 'Eitting'
  },
  {
    postalCode: '85464',
    city: 'Finsing'
  },
  {
    postalCode: '85465',
    city: 'Fürnsbach'
  },
  {
    postalCode: '85465',
    city: 'Langenpreising'
  },
  {
    postalCode: '85467',
    city: 'Neuching'
  },
  {
    postalCode: '85469',
    city: 'Walpertskirchen'
  },
  {
    postalCode: '85521',
    city: 'Ottobrunn'
  },
  {
    postalCode: '85521',
    city: 'Riemerling'
  },
  {
    postalCode: '85540',
    city: 'Haar'
  },
  {
    postalCode: '85551',
    city: 'Kirchheim'
  },
  {
    postalCode: '85560',
    city: 'Ebersberg'
  },
  {
    postalCode: '85567',
    city: 'Bruck'
  },
  {
    postalCode: '85567',
    city: 'Grafing'
  },
  {
    postalCode: '85570',
    city: 'Köppelmühle'
  },
  {
    postalCode: '85570',
    city: 'Markt Schwaben'
  },
  {
    postalCode: '85570',
    city: 'Ottenhofen'
  },
  {
    postalCode: '85579',
    city: 'Neubiberg'
  },
  {
    postalCode: '85586',
    city: 'Poing'
  },
  {
    postalCode: '85591',
    city: 'Vaterstetten'
  },
  {
    postalCode: '85598',
    city: 'Baldham'
  },
  {
    postalCode: '85599',
    city: 'Hergolding'
  },
  {
    postalCode: '85599',
    city: 'Parsdorf'
  },
  {
    postalCode: '85604',
    city: 'Zorneding'
  },
  {
    postalCode: '85609',
    city: 'Aschheim'
  },
  {
    postalCode: '85614',
    city: 'Kirchseeon'
  },
  {
    postalCode: '85617',
    city: 'Aßling'
  },
  {
    postalCode: '85622',
    city: 'Feldkirchen'
  },
  {
    postalCode: '85622',
    city: 'Weißenfeld'
  },
  {
    postalCode: '85625',
    city: 'Baiern'
  },
  {
    postalCode: '85625',
    city: 'Glonn'
  },
  {
    postalCode: '85630',
    city: 'Grasbrunn'
  },
  {
    postalCode: '85635',
    city: 'Höhenkirchen-Siegertsbrunn'
  },
  {
    postalCode: '85640',
    city: 'Putzbrunn'
  },
  {
    postalCode: '85643',
    city: 'Steinhöring'
  },
  {
    postalCode: '85646',
    city: 'Anzing'
  },
  {
    postalCode: '85646',
    city: 'Neufarn'
  },
  {
    postalCode: '85646',
    city: 'Purfing'
  },
  {
    postalCode: '85649',
    city: 'Brunnthal'
  },
  {
    postalCode: '85652',
    city: 'Pliening'
  },
  {
    postalCode: '85653',
    city: 'Aying'
  },
  {
    postalCode: '85655',
    city: 'Blindham'
  },
  {
    postalCode: '85655',
    city: 'Göggenhofen'
  },
  {
    postalCode: '85655',
    city: 'Graß'
  },
  {
    postalCode: '85655',
    city: 'Griesstätt'
  },
  {
    postalCode: '85655',
    city: 'Großhelfendorf'
  },
  {
    postalCode: '85655',
    city: 'Heimatshofen'
  },
  {
    postalCode: '85655',
    city: 'Helfendorf'
  },
  {
    postalCode: '85655',
    city: 'Kaltenbrunn'
  },
  {
    postalCode: '85655',
    city: 'Kaps'
  },
  {
    postalCode: '85655',
    city: 'Kleinhelfendorf'
  },
  {
    postalCode: '85655',
    city: 'Kleinkarolinenfeld'
  },
  {
    postalCode: '85655',
    city: 'Loibersdorf'
  },
  {
    postalCode: '85655',
    city: 'Oberschops'
  },
  {
    postalCode: '85655',
    city: 'Rauchenberg'
  },
  {
    postalCode: '85655',
    city: 'Spielberg'
  },
  {
    postalCode: '85655',
    city: 'Trautshofen'
  },
  {
    postalCode: '85655',
    city: 'Unterschops'
  },
  {
    postalCode: '85656',
    city: 'Buch'
  },
  {
    postalCode: '85658',
    city: 'Egmating'
  },
  {
    postalCode: '85659',
    city: 'Forstern'
  },
  {
    postalCode: '85661',
    city: 'Forstinning'
  },
  {
    postalCode: '85662',
    city: 'Hohenbrunn'
  },
  {
    postalCode: '85664',
    city: 'Hohenlinden'
  },
  {
    postalCode: '85665',
    city: 'Moosach'
  },
  {
    postalCode: '85667',
    city: 'Oberpframmern'
  },
  {
    postalCode: '85669',
    city: 'Ödenbach'
  },
  {
    postalCode: '85669',
    city: 'Pastetten'
  },
  {
    postalCode: '85716',
    city: 'Unterschleißheim'
  },
  {
    postalCode: '85737',
    city: 'Ismaning'
  },
  {
    postalCode: '85737',
    city: 'Zwillingshof'
  },
  {
    postalCode: '85748',
    city: 'Garching'
  },
  {
    postalCode: '85757',
    city: 'Karlsfeld'
  },
  {
    postalCode: '85764',
    city: 'Hackermoos'
  },
  {
    postalCode: '85764',
    city: 'Oberschleißheim'
  },
  {
    postalCode: '85774',
    city: 'Unterföhring'
  },
  {
    postalCode: '85777',
    city: 'Fahrenzhausen'
  },
  {
    postalCode: '85778',
    city: 'Haimhausen'
  },
  {
    postalCode: '86150',
    city: 'Augsburg'
  },
  {
    postalCode: '86152',
    city: 'Augsburg'
  },
  {
    postalCode: '86153',
    city: 'Augsburg'
  },
  {
    postalCode: '86154',
    city: 'Augsburg'
  },
  {
    postalCode: '86156',
    city: 'Augsburg'
  },
  {
    postalCode: '86157',
    city: 'Augsburg'
  },
  {
    postalCode: '86159',
    city: 'Augsburg'
  },
  {
    postalCode: '86161',
    city: 'Augsburg'
  },
  {
    postalCode: '86163',
    city: 'Augsburg'
  },
  {
    postalCode: '86165',
    city: 'Augsburg'
  },
  {
    postalCode: '86167',
    city: 'Augsburg'
  },
  {
    postalCode: '86169',
    city: 'Augsburg'
  },
  {
    postalCode: '86179',
    city: 'Augsburg'
  },
  {
    postalCode: '86199',
    city: 'Augsburg'
  },
  {
    postalCode: '86316',
    city: 'Friedberg'
  },
  {
    postalCode: '86343',
    city: 'Königsbrunn'
  },
  {
    postalCode: '86356',
    city: 'Neusäß'
  },
  {
    postalCode: '86368',
    city: 'Gersthofen'
  },
  {
    postalCode: '86381',
    city: 'Krumbach'
  },
  {
    postalCode: '86391',
    city: 'Stadtbergen'
  },
  {
    postalCode: '86399',
    city: 'Bobingen'
  },
  {
    postalCode: '86405',
    city: 'Meitingen'
  },
  {
    postalCode: '86415',
    city: 'Mering'
  },
  {
    postalCode: '86420',
    city: 'Diedorf'
  },
  {
    postalCode: '86424',
    city: 'Dinkelscherben'
  },
  {
    postalCode: '86438',
    city: 'Kissing'
  },
  {
    postalCode: '86441',
    city: 'Zusmarshausen'
  },
  {
    postalCode: '86444',
    city: 'Affing'
  },
  {
    postalCode: '86447',
    city: 'Aindling'
  },
  {
    postalCode: '86447',
    city: 'Todtenweis'
  },
  {
    postalCode: '86450',
    city: 'Altenmünster'
  },
  {
    postalCode: '86453',
    city: 'Dasing'
  },
  {
    postalCode: '86456',
    city: 'Gablingen'
  },
  {
    postalCode: '86456',
    city: 'Peterhof'
  },
  {
    postalCode: '86459',
    city: 'Gessertshausen'
  },
  {
    postalCode: '86462',
    city: 'Langweid'
  },
  {
    postalCode: '86465',
    city: 'Heretsried'
  },
  {
    postalCode: '86465',
    city: 'Welden'
  },
  {
    postalCode: '86470',
    city: 'Thannhausen'
  },
  {
    postalCode: '86473',
    city: 'Ziemetshausen'
  },
  {
    postalCode: '86476',
    city: 'Neuburg'
  },
  {
    postalCode: '86477',
    city: 'Adelsried'
  },
  {
    postalCode: '86479',
    city: 'Aichen'
  },
  {
    postalCode: '86480',
    city: 'Aletshausen'
  },
  {
    postalCode: '86480',
    city: 'Waltenhausen'
  },
  {
    postalCode: '86482',
    city: 'Aystetten'
  },
  {
    postalCode: '86483',
    city: 'Balzhausen'
  },
  {
    postalCode: '86485',
    city: 'Biberbach'
  },
  {
    postalCode: '86486',
    city: 'Bonstetten'
  },
  {
    postalCode: '86488',
    city: 'Breitenthal'
  },
  {
    postalCode: '86489',
    city: 'Deisenhausen'
  },
  {
    postalCode: '86491',
    city: 'Ebershausen'
  },
  {
    postalCode: '86492',
    city: 'Egling'
  },
  {
    postalCode: '86494',
    city: 'Emersacker'
  },
  {
    postalCode: '86495',
    city: 'Eurasburg'
  },
  {
    postalCode: '86497',
    city: 'Horgau'
  },
  {
    postalCode: '86498',
    city: 'Kettershausen'
  },
  {
    postalCode: '86500',
    city: 'Kutzenhausen'
  },
  {
    postalCode: '86502',
    city: 'Laugna'
  },
  {
    postalCode: '86504',
    city: 'Merching'
  },
  {
    postalCode: '86505',
    city: 'Münsterhausen'
  },
  {
    postalCode: '86507',
    city: 'Kleinaitingen'
  },
  {
    postalCode: '86507',
    city: 'Oberottmarshausen'
  },
  {
    postalCode: '86508',
    city: 'Rehling'
  },
  {
    postalCode: '86510',
    city: 'Ried'
  },
  {
    postalCode: '86511',
    city: 'Schmiechen'
  },
  {
    postalCode: '86513',
    city: 'Ursberg'
  },
  {
    postalCode: '86514',
    city: 'Ustersbach'
  },
  {
    postalCode: '86517',
    city: 'Wehringen'
  },
  {
    postalCode: '86519',
    city: 'Wiesenbach'
  },
  {
    postalCode: '86529',
    city: 'Schrobenhausen'
  },
  {
    postalCode: '86551',
    city: 'Aichach'
  },
  {
    postalCode: '86554',
    city: 'Pöttmes'
  },
  {
    postalCode: '86556',
    city: 'Kühbach'
  },
  {
    postalCode: '86558',
    city: 'Hohenwart'
  },
  {
    postalCode: '86559',
    city: 'Adelzhausen'
  },
  {
    postalCode: '86561',
    city: 'Aresing'
  },
  {
    postalCode: '86562',
    city: 'Berg im Gau'
  },
  {
    postalCode: '86564',
    city: 'Brunnen'
  },
  {
    postalCode: '86565',
    city: 'Gachenbach'
  },
  {
    postalCode: '86567',
    city: 'Hilgertshausen-Tandern'
  },
  {
    postalCode: '86568',
    city: 'Hollenbach'
  },
  {
    postalCode: '86570',
    city: 'Inchenhofen'
  },
  {
    postalCode: '86571',
    city: 'Langenmosen'
  },
  {
    postalCode: '86573',
    city: 'Obergriesbach'
  },
  {
    postalCode: '86574',
    city: 'Petersdorf'
  },
  {
    postalCode: '86576',
    city: 'Schiltberg'
  },
  {
    postalCode: '86577',
    city: 'Sielenbach'
  },
  {
    postalCode: '86579',
    city: 'Waidhofen'
  },
  {
    postalCode: '86609',
    city: 'Donauwörth'
  },
  {
    postalCode: '86633',
    city: 'Neuburg'
  },
  {
    postalCode: '86637',
    city: 'Binswangen'
  },
  {
    postalCode: '86637',
    city: 'Villenbach'
  },
  {
    postalCode: '86637',
    city: 'Wertingen'
  },
  {
    postalCode: '86637',
    city: 'Zusamaltheim'
  },
  {
    postalCode: '86641',
    city: 'Rain'
  },
  {
    postalCode: '86643',
    city: 'Rennertshofen'
  },
  {
    postalCode: '86647',
    city: 'Buttenwiesen'
  },
  {
    postalCode: '86650',
    city: 'Wemding'
  },
  {
    postalCode: '86653',
    city: 'Daiting'
  },
  {
    postalCode: '86653',
    city: 'Monheim'
  },
  {
    postalCode: '86655',
    city: 'Harburg'
  },
  {
    postalCode: '86657',
    city: 'Bissingen'
  },
  {
    postalCode: '86660',
    city: 'Tapfheim'
  },
  {
    postalCode: '86663',
    city: 'Asbach-Bäumenheim'
  },
  {
    postalCode: '86666',
    city: 'Burgheim'
  },
  {
    postalCode: '86668',
    city: 'Karlshuld'
  },
  {
    postalCode: '86669',
    city: 'Königsmoos'
  },
  {
    postalCode: '86672',
    city: 'Thierhaupten'
  },
  {
    postalCode: '86673',
    city: 'Bergheim'
  },
  {
    postalCode: '86674',
    city: 'Baar'
  },
  {
    postalCode: '86675',
    city: 'Buchdorf'
  },
  {
    postalCode: '86676',
    city: 'Ehekirchen'
  },
  {
    postalCode: '86678',
    city: 'Ehingen'
  },
  {
    postalCode: '86679',
    city: 'Ellgau'
  },
  {
    postalCode: '86681',
    city: 'Fünfstetten'
  },
  {
    postalCode: '86682',
    city: 'Genderkingen'
  },
  {
    postalCode: '86684',
    city: 'Holzheim'
  },
  {
    postalCode: '86685',
    city: 'Huisheim'
  },
  {
    postalCode: '86687',
    city: 'Kaisheim'
  },
  {
    postalCode: '86688',
    city: 'Marxheim'
  },
  {
    postalCode: '86690',
    city: 'Mertingen'
  },
  {
    postalCode: '86692',
    city: 'Münster'
  },
  {
    postalCode: '86694',
    city: 'Niederschönenfeld'
  },
  {
    postalCode: '86695',
    city: 'Allmannshofen'
  },
  {
    postalCode: '86695',
    city: 'Nordendorf'
  },
  {
    postalCode: '86697',
    city: 'Oberhausen'
  },
  {
    postalCode: '86698',
    city: 'Oberndorf'
  },
  {
    postalCode: '86700',
    city: 'Otting'
  },
  {
    postalCode: '86701',
    city: 'Rohrenfels'
  },
  {
    postalCode: '86703',
    city: 'Rögling'
  },
  {
    postalCode: '86704',
    city: 'Tagmersheim'
  },
  {
    postalCode: '86706',
    city: 'Weichering'
  },
  {
    postalCode: '86707',
    city: 'Kühlenthal'
  },
  {
    postalCode: '86707',
    city: 'Westendorf'
  },
  {
    postalCode: '86709',
    city: 'Wolferstadt'
  },
  {
    postalCode: '86720',
    city: 'Nördlingen'
  },
  {
    postalCode: '86732',
    city: 'Oettingen'
  },
  {
    postalCode: '86733',
    city: 'Alerheim'
  },
  {
    postalCode: '86735',
    city: 'Amerdingen'
  },
  {
    postalCode: '86735',
    city: 'Forheim'
  },
  {
    postalCode: '86736',
    city: 'Auhausen'
  },
  {
    postalCode: '86738',
    city: 'Deiningen'
  },
  {
    postalCode: '86739',
    city: 'Ederheim'
  },
  {
    postalCode: '86739',
    city: 'Karlshof'
  },
  {
    postalCode: '86741',
    city: 'Ehingen'
  },
  {
    postalCode: '86742',
    city: 'Fremdingen'
  },
  {
    postalCode: '86744',
    city: 'Hainsfarth'
  },
  {
    postalCode: '86745',
    city: 'Hohenaltheim'
  },
  {
    postalCode: '86747',
    city: 'Maihingen'
  },
  {
    postalCode: '86748',
    city: 'Marktoffingen'
  },
  {
    postalCode: '86750',
    city: 'Megesheim'
  },
  {
    postalCode: '86751',
    city: 'Mönchsdeggingen'
  },
  {
    postalCode: '86753',
    city: 'Möttingen'
  },
  {
    postalCode: '86754',
    city: 'Munningen'
  },
  {
    postalCode: '86756',
    city: 'Reimlingen'
  },
  {
    postalCode: '86757',
    city: 'Wallerstein'
  },
  {
    postalCode: '86759',
    city: 'Wechingen'
  },
  {
    postalCode: '86807',
    city: 'Buchloe'
  },
  {
    postalCode: '86825',
    city: 'Bad Wörishofen'
  },
  {
    postalCode: '86830',
    city: 'Schwabmünchen'
  },
  {
    postalCode: '86833',
    city: 'Ettringen'
  },
  {
    postalCode: '86836',
    city: 'Graben'
  },
  {
    postalCode: '86836',
    city: 'Gutshof Lechfeld'
  },
  {
    postalCode: '86836',
    city: 'Klosterlechfeld'
  },
  {
    postalCode: '86836',
    city: 'Obermeitingen'
  },
  {
    postalCode: '86836',
    city: 'Untermeitingen'
  },
  {
    postalCode: '86842',
    city: 'Türkheim'
  },
  {
    postalCode: '86845',
    city: 'Großaitingen'
  },
  {
    postalCode: '86850',
    city: 'Fischach'
  },
  {
    postalCode: '86853',
    city: 'Langerringen'
  },
  {
    postalCode: '86854',
    city: 'Amberg'
  },
  {
    postalCode: '86856',
    city: 'Hiltenfingen'
  },
  {
    postalCode: '86857',
    city: 'Hurlach'
  },
  {
    postalCode: '86859',
    city: 'Igling'
  },
  {
    postalCode: '86860',
    city: 'Jengen'
  },
  {
    postalCode: '86862',
    city: 'Lamerdingen'
  },
  {
    postalCode: '86863',
    city: 'Langenneufnach'
  },
  {
    postalCode: '86865',
    city: 'Markt Wald'
  },
  {
    postalCode: '86866',
    city: 'Mickhausen'
  },
  {
    postalCode: '86868',
    city: 'Mittelneufnach'
  },
  {
    postalCode: '86869',
    city: 'Oberostendorf'
  },
  {
    postalCode: '86871',
    city: 'Rammingen'
  },
  {
    postalCode: '86872',
    city: 'Scherstetten'
  },
  {
    postalCode: '86874',
    city: 'Tussenhausen'
  },
  {
    postalCode: '86875',
    city: 'Waal'
  },
  {
    postalCode: '86877',
    city: 'Walkertshofen'
  },
  {
    postalCode: '86879',
    city: 'Wiedergeltingen'
  },
  {
    postalCode: '86899',
    city: 'Landsberg'
  },
  {
    postalCode: '86911',
    city: 'Dießen'
  },
  {
    postalCode: '86916',
    city: 'Kaufering'
  },
  {
    postalCode: '86919',
    city: 'Utting'
  },
  {
    postalCode: '86920',
    city: 'Denklingen'
  },
  {
    postalCode: '86920',
    city: 'Epfach'
  },
  {
    postalCode: '86922',
    city: 'Eresing'
  },
  {
    postalCode: '86923',
    city: 'Finning'
  },
  {
    postalCode: '86925',
    city: 'Fuchstal'
  },
  {
    postalCode: '86926',
    city: 'Algertshausen'
  },
  {
    postalCode: '86926',
    city: 'Greifenberg'
  },
  {
    postalCode: '86926',
    city: 'Pflaumdorf'
  },
  {
    postalCode: '86928',
    city: 'Hofstetten'
  },
  {
    postalCode: '86929',
    city: 'Penzing'
  },
  {
    postalCode: '86931',
    city: 'Prittriching'
  },
  {
    postalCode: '86932',
    city: 'Pürgen'
  },
  {
    postalCode: '86932',
    city: 'Thalhofen'
  },
  {
    postalCode: '86934',
    city: 'Reichling'
  },
  {
    postalCode: '86935',
    city: 'Rott'
  },
  {
    postalCode: '86937',
    city: 'Scheuring'
  },
  {
    postalCode: '86938',
    city: 'Schondorf'
  },
  {
    postalCode: '86940',
    city: 'Schwifting'
  },
  {
    postalCode: '86941',
    city: 'St Ottilien'
  },
  {
    postalCode: '86943',
    city: 'Thaining'
  },
  {
    postalCode: '86944',
    city: 'Unterdießen'
  },
  {
    postalCode: '86946',
    city: 'Vilgertshofen'
  },
  {
    postalCode: '86947',
    city: 'Weil'
  },
  {
    postalCode: '86949',
    city: 'Windach'
  },
  {
    postalCode: '86956',
    city: 'Schongau'
  },
  {
    postalCode: '86971',
    city: 'Peiting'
  },
  {
    postalCode: '86972',
    city: 'Altenstadt'
  },
  {
    postalCode: '86974',
    city: 'Apfeldorf'
  },
  {
    postalCode: '86975',
    city: 'Bernbeuren'
  },
  {
    postalCode: '86977',
    city: 'Burggen'
  },
  {
    postalCode: '86978',
    city: 'Hohenfurch'
  },
  {
    postalCode: '86980',
    city: 'Ingenried'
  },
  {
    postalCode: '86981',
    city: 'Kinsau'
  },
  {
    postalCode: '86983',
    city: 'Lechbruck'
  },
  {
    postalCode: '86984',
    city: 'Prem'
  },
  {
    postalCode: '86986',
    city: 'Schwabbruck'
  },
  {
    postalCode: '86987',
    city: 'Schwabsoien'
  },
  {
    postalCode: '86989',
    city: 'Deutenried'
  },
  {
    postalCode: '86989',
    city: 'Steingaden'
  },
  {
    postalCode: '87435',
    city: 'Kempten'
  },
  {
    postalCode: '87437',
    city: 'Kempten'
  },
  {
    postalCode: '87439',
    city: 'Kempten'
  },
  {
    postalCode: '87448',
    city: 'Waltenhofen'
  },
  {
    postalCode: '87452',
    city: 'Altusried'
  },
  {
    postalCode: '87459',
    city: 'Pfronten'
  },
  {
    postalCode: '87463',
    city: 'Dietmannsried'
  },
  {
    postalCode: '87466',
    city: 'Oy-Mittelberg'
  },
  {
    postalCode: '87471',
    city: 'Durach'
  },
  {
    postalCode: '87474',
    city: 'Buchenberg'
  },
  {
    postalCode: '87477',
    city: 'Sulzberg'
  },
  {
    postalCode: '87480',
    city: 'Weitnau'
  },
  {
    postalCode: '87484',
    city: 'Nesselwang'
  },
  {
    postalCode: '87487',
    city: 'Wiggensbach'
  },
  {
    postalCode: '87488',
    city: 'Betzigau'
  },
  {
    postalCode: '87490',
    city: 'Haldenwang'
  },
  {
    postalCode: '87493',
    city: 'Lauben'
  },
  {
    postalCode: '87494',
    city: 'Rückholz'
  },
  {
    postalCode: '87496',
    city: 'Untrasried'
  },
  {
    postalCode: '87497',
    city: 'Wertach'
  },
  {
    postalCode: '87499',
    city: 'Wildpoldsried'
  },
  {
    postalCode: '87509',
    city: 'Immenstadt'
  },
  {
    postalCode: '87527',
    city: 'Ofterschwang'
  },
  {
    postalCode: '87527',
    city: 'Sonthofen'
  },
  {
    postalCode: '87534',
    city: 'Oberstaufen'
  },
  {
    postalCode: '87538',
    city: 'Balderschwang'
  },
  {
    postalCode: '87538',
    city: 'Bolsterlang'
  },
  {
    postalCode: '87538',
    city: 'Fischen'
  },
  {
    postalCode: '87538',
    city: 'Obermaiselstein'
  },
  {
    postalCode: '87541',
    city: 'Hindelang'
  },
  {
    postalCode: '87544',
    city: 'Blaichach'
  },
  {
    postalCode: '87545',
    city: 'Burgberg'
  },
  {
    postalCode: '87547',
    city: 'Missen-Wilhams'
  },
  {
    postalCode: '87549',
    city: 'Rettenberg'
  },
  {
    postalCode: '87561',
    city: 'Oberstdorf'
  },
  {
    postalCode: '87600',
    city: 'Kaufbeuren'
  },
  {
    postalCode: '87616',
    city: 'Marktoberdorf'
  },
  {
    postalCode: '87616',
    city: 'Wald'
  },
  {
    postalCode: '87629',
    city: 'Füssen'
  },
  {
    postalCode: '87634',
    city: 'Günzach'
  },
  {
    postalCode: '87634',
    city: 'Obergünzburg'
  },
  {
    postalCode: '87637',
    city: 'Eisenberg'
  },
  {
    postalCode: '87637',
    city: 'Seeg'
  },
  {
    postalCode: '87640',
    city: 'Biessenhofen'
  },
  {
    postalCode: '87642',
    city: 'Halblech'
  },
  {
    postalCode: '87645',
    city: 'Schwangau'
  },
  {
    postalCode: '87647',
    city: 'Kraftisried'
  },
  {
    postalCode: '87647',
    city: 'Unterthingau'
  },
  {
    postalCode: '87648',
    city: 'Aitrang'
  },
  {
    postalCode: '87650',
    city: 'Baisweil'
  },
  {
    postalCode: '87651',
    city: 'Bidingen'
  },
  {
    postalCode: '87653',
    city: 'Eggenthal'
  },
  {
    postalCode: '87654',
    city: 'Friesenried'
  },
  {
    postalCode: '87656',
    city: 'Germaringen'
  },
  {
    postalCode: '87657',
    city: 'Görisried'
  },
  {
    postalCode: '87659',
    city: 'Hopferau'
  },
  {
    postalCode: '87660',
    city: 'Irsee'
  },
  {
    postalCode: '87662',
    city: 'Kaltental'
  },
  {
    postalCode: '87662',
    city: 'Osterzell'
  },
  {
    postalCode: '87663',
    city: 'Lengenwang'
  },
  {
    postalCode: '87665',
    city: 'Mauerstetten'
  },
  {
    postalCode: '87666',
    city: 'Pforzen'
  },
  {
    postalCode: '87668',
    city: 'Rieden'
  },
  {
    postalCode: '87669',
    city: 'Rieden'
  },
  {
    postalCode: '87671',
    city: 'Flohkraut'
  },
  {
    postalCode: '87671',
    city: 'Ronsberg'
  },
  {
    postalCode: '87672',
    city: 'Roßhaupten'
  },
  {
    postalCode: '87674',
    city: 'Ruderatshofen'
  },
  {
    postalCode: '87675',
    city: 'Rettenbach'
  },
  {
    postalCode: '87675',
    city: 'Stötten'
  },
  {
    postalCode: '87677',
    city: 'Stöttwang'
  },
  {
    postalCode: '87679',
    city: 'Westendorf'
  },
  {
    postalCode: '87700',
    city: 'Memmingen'
  },
  {
    postalCode: '87719',
    city: 'Mindelheim'
  },
  {
    postalCode: '87724',
    city: 'Krautenberg'
  },
  {
    postalCode: '87724',
    city: 'Ottobeuren'
  },
  {
    postalCode: '87724',
    city: 'Schochen'
  },
  {
    postalCode: '87727',
    city: 'Babenhausen'
  },
  {
    postalCode: '87730',
    city: 'Bad Grönenbach'
  },
  {
    postalCode: '87733',
    city: 'Markt Rettenbach'
  },
  {
    postalCode: '87734',
    city: 'Benningen'
  },
  {
    postalCode: '87736',
    city: 'Böhen'
  },
  {
    postalCode: '87737',
    city: 'Boos'
  },
  {
    postalCode: '87739',
    city: 'Breitenbrunn'
  },
  {
    postalCode: '87740',
    city: 'Buxheim'
  },
  {
    postalCode: '87742',
    city: 'Apfeltrach'
  },
  {
    postalCode: '87742',
    city: 'Dirlewang'
  },
  {
    postalCode: '87743',
    city: 'Betzenhausen'
  },
  {
    postalCode: '87743',
    city: 'Egg'
  },
  {
    postalCode: '87745',
    city: 'Eppishausen'
  },
  {
    postalCode: '87746',
    city: 'Erkheim'
  },
  {
    postalCode: '87748',
    city: 'Fellheim'
  },
  {
    postalCode: '87749',
    city: 'Hawangen'
  },
  {
    postalCode: '87751',
    city: 'Heimertingen'
  },
  {
    postalCode: '87752',
    city: 'Holzgünz'
  },
  {
    postalCode: '87754',
    city: 'Kammlach'
  },
  {
    postalCode: '87755',
    city: 'Kirchhaslach'
  },
  {
    postalCode: '87757',
    city: 'Bronnerlehe'
  },
  {
    postalCode: '87757',
    city: 'Kirchheim'
  },
  {
    postalCode: '87758',
    city: 'Kronburg'
  },
  {
    postalCode: '87760',
    city: 'Lachen'
  },
  {
    postalCode: '87761',
    city: 'Lauben'
  },
  {
    postalCode: '87763',
    city: 'Lautrach'
  },
  {
    postalCode: '87764',
    city: 'Legau'
  },
  {
    postalCode: '87766',
    city: 'Memmingerberg'
  },
  {
    postalCode: '87767',
    city: 'Niederrieden'
  },
  {
    postalCode: '87769',
    city: 'Oberrieden'
  },
  {
    postalCode: '87770',
    city: 'Beblinstetten'
  },
  {
    postalCode: '87770',
    city: 'Oberschönegg'
  },
  {
    postalCode: '87772',
    city: 'Pfaffenhausen'
  },
  {
    postalCode: '87773',
    city: 'Pleß'
  },
  {
    postalCode: '87775',
    city: 'Salgen'
  },
  {
    postalCode: '87776',
    city: 'Sontheim'
  },
  {
    postalCode: '87778',
    city: 'Stetten'
  },
  {
    postalCode: '87779',
    city: 'Trunkelsberg'
  },
  {
    postalCode: '87781',
    city: 'Ungerhausen'
  },
  {
    postalCode: '87782',
    city: 'Sonderhof'
  },
  {
    postalCode: '87782',
    city: 'Unteregg'
  },
  {
    postalCode: '87782',
    city: 'Wallenried'
  },
  {
    postalCode: '87784',
    city: 'Westerheim'
  },
  {
    postalCode: '87785',
    city: 'Winterrieden'
  },
  {
    postalCode: '87787',
    city: 'Wolfertschwenden'
  },
  {
    postalCode: '87789',
    city: 'Woringen'
  },
  {
    postalCode: '88045',
    city: 'Friedrichshafen'
  },
  {
    postalCode: '88046',
    city: 'Friedrichshafen'
  },
  {
    postalCode: '88048',
    city: 'Friedrichshafen'
  },
  {
    postalCode: '88069',
    city: 'Tettnang'
  },
  {
    postalCode: '88074',
    city: 'Meckenbeuren'
  },
  {
    postalCode: '88079',
    city: 'Kressbronn'
  },
  {
    postalCode: '88079',
    city: 'Reichen'
  },
  {
    postalCode: '88079',
    city: 'Wiesach'
  },
  {
    postalCode: '88085',
    city: 'Langenargen'
  },
  {
    postalCode: '88090',
    city: 'Immenstaad'
  },
  {
    postalCode: '88094',
    city: 'Oberteuringen'
  },
  {
    postalCode: '88097',
    city: 'Eriskirch'
  },
  {
    postalCode: '88099',
    city: 'Neukirch'
  },
  {
    postalCode: '88131',
    city: 'Bodolz'
  },
  {
    postalCode: '88131',
    city: 'Lindau'
  },
  {
    postalCode: '88138',
    city: 'Hergensweiler'
  },
  {
    postalCode: '88138',
    city: 'Sigmarszell'
  },
  {
    postalCode: '88138',
    city: 'Weißensberg'
  },
  {
    postalCode: '88142',
    city: 'Wasserburg'
  },
  {
    postalCode: '88145',
    city: 'Hergatz'
  },
  {
    postalCode: '88145',
    city: 'Opfenbach'
  },
  {
    postalCode: '88147',
    city: 'Achberg'
  },
  {
    postalCode: '88147',
    city: 'Hugelitz'
  },
  {
    postalCode: '88149',
    city: 'Nonnenhorn'
  },
  {
    postalCode: '88161',
    city: 'Lindenberg'
  },
  {
    postalCode: '88167',
    city: 'Gestratz'
  },
  {
    postalCode: '88167',
    city: 'Grünenbach'
  },
  {
    postalCode: '88167',
    city: 'Lengersau'
  },
  {
    postalCode: '88167',
    city: 'Maierhöfen'
  },
  {
    postalCode: '88167',
    city: 'Malleichen'
  },
  {
    postalCode: '88167',
    city: 'Röthenbach'
  },
  {
    postalCode: '88167',
    city: 'Stiefenhofen'
  },
  {
    postalCode: '88171',
    city: 'Weiler-Simmerberg'
  },
  {
    postalCode: '88175',
    city: 'Scheidegg'
  },
  {
    postalCode: '88175',
    city: 'Westkinberg'
  },
  {
    postalCode: '88178',
    city: 'Heimenkirch'
  },
  {
    postalCode: '88179',
    city: 'Oberreute'
  },
  {
    postalCode: '88212',
    city: 'Ravensburg'
  },
  {
    postalCode: '88213',
    city: 'Ravensburg'
  },
  {
    postalCode: '88214',
    city: 'Ravensburg'
  },
  {
    postalCode: '88239',
    city: 'Wangen'
  },
  {
    postalCode: '88250',
    city: 'Weingarten'
  },
  {
    postalCode: '88255',
    city: 'Baienfurt'
  },
  {
    postalCode: '88255',
    city: 'Baindt'
  },
  {
    postalCode: '88260',
    city: 'Argenbühl'
  },
  {
    postalCode: '88263',
    city: 'Horgenzell'
  },
  {
    postalCode: '88267',
    city: 'Vogt'
  },
  {
    postalCode: '88271',
    city: 'Wilhelmsdorf'
  },
  {
    postalCode: '88273',
    city: 'Fronreute'
  },
  {
    postalCode: '88276',
    city: 'Berg'
  },
  {
    postalCode: '88279',
    city: 'Amtzell'
  },
  {
    postalCode: '88281',
    city: 'Schlier'
  },
  {
    postalCode: '88284',
    city: 'Wolpertswende'
  },
  {
    postalCode: '88285',
    city: 'Bodnegg'
  },
  {
    postalCode: '88287',
    city: 'Grünkraut'
  },
  {
    postalCode: '88289',
    city: 'Waldburg'
  },
  {
    postalCode: '88299',
    city: 'Leutkirch'
  },
  {
    postalCode: '88316',
    city: 'Isny'
  },
  {
    postalCode: '88317',
    city: 'Aichstetten'
  },
  {
    postalCode: '88319',
    city: 'Aitrach'
  },
  {
    postalCode: '88326',
    city: 'Aulendorf'
  },
  {
    postalCode: '88339',
    city: 'Bad Waldsee'
  },
  {
    postalCode: '88348',
    city: 'Allmannsweiler'
  },
  {
    postalCode: '88348',
    city: 'Bad Saulgau'
  },
  {
    postalCode: '88353',
    city: 'Kißlegg'
  },
  {
    postalCode: '88356',
    city: 'Ostrach'
  },
  {
    postalCode: '88361',
    city: 'Altshausen'
  },
  {
    postalCode: '88361',
    city: 'Boms'
  },
  {
    postalCode: '88361',
    city: 'Eichstegen'
  },
  {
    postalCode: '88364',
    city: 'Wolfegg'
  },
  {
    postalCode: '88367',
    city: 'Hohentengen'
  },
  {
    postalCode: '88368',
    city: 'Bergatreute'
  },
  {
    postalCode: '88370',
    city: 'Ebenweiler'
  },
  {
    postalCode: '88371',
    city: 'Ebersbach-Musbach'
  },
  {
    postalCode: '88373',
    city: 'Fleischwangen'
  },
  {
    postalCode: '88373',
    city: 'Muttenhaus'
  },
  {
    postalCode: '88374',
    city: 'Hoßkirch'
  },
  {
    postalCode: '88376',
    city: 'Königseggwald'
  },
  {
    postalCode: '88377',
    city: 'Riedhausen'
  },
  {
    postalCode: '88379',
    city: 'Guggenhausen'
  },
  {
    postalCode: '88379',
    city: 'Unterwaldhausen'
  },
  {
    postalCode: '88400',
    city: 'Biberach'
  },
  {
    postalCode: '88400',
    city: 'Scholterhaus'
  },
  {
    postalCode: '88410',
    city: 'Bad Wurzach'
  },
  {
    postalCode: '88416',
    city: 'Erlenmoos'
  },
  {
    postalCode: '88416',
    city: 'Ochsenhausen'
  },
  {
    postalCode: '88416',
    city: 'Steinhausen'
  },
  {
    postalCode: '88422',
    city: 'Alleshausen'
  },
  {
    postalCode: '88422',
    city: 'Bad Buchau'
  },
  {
    postalCode: '88422',
    city: 'Betzenweiler'
  },
  {
    postalCode: '88422',
    city: 'Dürnau'
  },
  {
    postalCode: '88422',
    city: 'Kanzach'
  },
  {
    postalCode: '88422',
    city: 'Moosburg'
  },
  {
    postalCode: '88422',
    city: 'Oggelshausen'
  },
  {
    postalCode: '88422',
    city: 'Seekirch'
  },
  {
    postalCode: '88422',
    city: 'Tiefenbach'
  },
  {
    postalCode: '88427',
    city: 'Bad Schussenried'
  },
  {
    postalCode: '88430',
    city: 'Emishalden'
  },
  {
    postalCode: '88430',
    city: 'Rot'
  },
  {
    postalCode: '88433',
    city: 'Schemmerhofen'
  },
  {
    postalCode: '88436',
    city: 'Eberhardzell'
  },
  {
    postalCode: '88436',
    city: 'Venusberg'
  },
  {
    postalCode: '88437',
    city: 'Maselheim'
  },
  {
    postalCode: '88441',
    city: 'Bahnstock'
  },
  {
    postalCode: '88441',
    city: 'Birkhof'
  },
  {
    postalCode: '88441',
    city: 'Maierhof'
  },
  {
    postalCode: '88441',
    city: 'Mittelbiberach'
  },
  {
    postalCode: '88441',
    city: 'Streitberg'
  },
  {
    postalCode: '88444',
    city: 'Ummendorf'
  },
  {
    postalCode: '88447',
    city: 'Warthausen'
  },
  {
    postalCode: '88448',
    city: 'Attenweiler'
  },
  {
    postalCode: '88450',
    city: 'Berkheim'
  },
  {
    postalCode: '88450',
    city: 'Binnrot'
  },
  {
    postalCode: '88451',
    city: 'Dettingen'
  },
  {
    postalCode: '88453',
    city: 'Erolzheim'
  },
  {
    postalCode: '88453',
    city: 'Waldenhofen'
  },
  {
    postalCode: '88454',
    city: 'Hochdorf'
  },
  {
    postalCode: '88454',
    city: 'Winkel'
  },
  {
    postalCode: '88456',
    city: 'Ingoldingen'
  },
  {
    postalCode: '88457',
    city: 'Kirchdorf'
  },
  {
    postalCode: '88459',
    city: 'Rudeshof'
  },
  {
    postalCode: '88459',
    city: 'Tannheim'
  },
  {
    postalCode: '88459',
    city: 'Wiesbauer'
  },
  {
    postalCode: '88471',
    city: 'Laupheim'
  },
  {
    postalCode: '88477',
    city: 'Schwendi'
  },
  {
    postalCode: '88480',
    city: 'Achstetten'
  },
  {
    postalCode: '88481',
    city: 'Balzheim'
  },
  {
    postalCode: '88483',
    city: 'Burgrieden'
  },
  {
    postalCode: '88484',
    city: 'Gutenzell-Hürbel'
  },
  {
    postalCode: '88486',
    city: 'Kirchberg'
  },
  {
    postalCode: '88487',
    city: 'Mietingen'
  },
  {
    postalCode: '88489',
    city: 'Wain'
  },
  {
    postalCode: '88499',
    city: 'Altheim'
  },
  {
    postalCode: '88499',
    city: 'Emeringen'
  },
  {
    postalCode: '88499',
    city: 'Riedlingen'
  },
  {
    postalCode: '88512',
    city: 'Mengen'
  },
  {
    postalCode: '88515',
    city: 'Langenenslingen'
  },
  {
    postalCode: '88518',
    city: 'Herbertingen'
  },
  {
    postalCode: '88521',
    city: 'Ertingen'
  },
  {
    postalCode: '88524',
    city: 'Uttenweiler'
  },
  {
    postalCode: '88525',
    city: 'Dürmentingen'
  },
  {
    postalCode: '88527',
    city: 'Unlingen'
  },
  {
    postalCode: '88529',
    city: 'Zwiefalten'
  },
  {
    postalCode: '88605',
    city: 'Meßkirch'
  },
  {
    postalCode: '88605',
    city: 'Sauldorf'
  },
  {
    postalCode: '88630',
    city: 'Pfullendorf'
  },
  {
    postalCode: '88631',
    city: 'Beuron'
  },
  {
    postalCode: '88633',
    city: 'Heiligenberg'
  },
  {
    postalCode: '88634',
    city: 'Herdwangen-Schönach'
  },
  {
    postalCode: '88636',
    city: 'Illmensee'
  },
  {
    postalCode: '88637',
    city: 'Buchheim'
  },
  {
    postalCode: '88637',
    city: 'Leibertingen'
  },
  {
    postalCode: '88639',
    city: 'Wald'
  },
  {
    postalCode: '88662',
    city: 'Überlingen'
  },
  {
    postalCode: '88677',
    city: 'Markdorf'
  },
  {
    postalCode: '88682',
    city: 'Salem'
  },
  {
    postalCode: '88690',
    city: 'Uhldingen-Mühlhofen'
  },
  {
    postalCode: '88693',
    city: 'Deggenhausertal'
  },
  {
    postalCode: '88696',
    city: 'Owingen'
  },
  {
    postalCode: '88697',
    city: 'Bermatingen'
  },
  {
    postalCode: '88699',
    city: 'Frickingen'
  },
  {
    postalCode: '88709',
    city: 'Hagnau'
  },
  {
    postalCode: '88709',
    city: 'Meersburg'
  },
  {
    postalCode: '88718',
    city: 'Daisendorf'
  },
  {
    postalCode: '88719',
    city: 'Stetten'
  },
  {
    postalCode: '89073',
    city: 'Ulm'
  },
  {
    postalCode: '89075',
    city: 'Ulm'
  },
  {
    postalCode: '89077',
    city: 'Ulm'
  },
  {
    postalCode: '89079',
    city: 'Ulm'
  },
  {
    postalCode: '89081',
    city: 'Seligweiler'
  },
  {
    postalCode: '89081',
    city: 'Ulm'
  },
  {
    postalCode: '89129',
    city: 'Langenau'
  },
  {
    postalCode: '89129',
    city: 'Nerenstetten'
  },
  {
    postalCode: '89129',
    city: 'Öllingen'
  },
  {
    postalCode: '89129',
    city: 'Setzingen'
  },
  {
    postalCode: '89129',
    city: 'Wettingen'
  },
  {
    postalCode: '89134',
    city: 'Blaustein'
  },
  {
    postalCode: '89143',
    city: 'Blaubeuren'
  },
  {
    postalCode: '89143',
    city: 'Hinterer Hessenhof'
  },
  {
    postalCode: '89150',
    city: 'Laichingen'
  },
  {
    postalCode: '89155',
    city: 'Erbach'
  },
  {
    postalCode: '89160',
    city: 'Dornstadt'
  },
  {
    postalCode: '89160',
    city: 'Himmelweiler'
  },
  {
    postalCode: '89165',
    city: 'Dietenheim'
  },
  {
    postalCode: '89165',
    city: 'Dürach'
  },
  {
    postalCode: '89165',
    city: 'Halbertshof'
  },
  {
    postalCode: '89165',
    city: 'Oberführbuch'
  },
  {
    postalCode: '89165',
    city: 'Unterführbuch'
  },
  {
    postalCode: '89168',
    city: 'Niederstotzingen'
  },
  {
    postalCode: '89171',
    city: 'Illerkirchberg'
  },
  {
    postalCode: '89173',
    city: 'Lonsee'
  },
  {
    postalCode: '89174',
    city: 'Altheim'
  },
  {
    postalCode: '89176',
    city: 'Asselfingen'
  },
  {
    postalCode: '89177',
    city: 'Ballendorf'
  },
  {
    postalCode: '89177',
    city: 'Börslingen'
  },
  {
    postalCode: '89179',
    city: 'Beimerstetten'
  },
  {
    postalCode: '89180',
    city: 'Berghülen'
  },
  {
    postalCode: '89182',
    city: 'Bernstadt'
  },
  {
    postalCode: '89183',
    city: 'Breitingen'
  },
  {
    postalCode: '89183',
    city: 'Holzkirch'
  },
  {
    postalCode: '89185',
    city: 'Hüttisheim'
  },
  {
    postalCode: '89186',
    city: 'Illerrieden'
  },
  {
    postalCode: '89188',
    city: 'Merklingen'
  },
  {
    postalCode: '89189',
    city: 'Neenstetten'
  },
  {
    postalCode: '89191',
    city: 'Nellingen'
  },
  {
    postalCode: '89192',
    city: 'Rammingen'
  },
  {
    postalCode: '89194',
    city: 'Schnürpflingen'
  },
  {
    postalCode: '89195',
    city: 'Staig'
  },
  {
    postalCode: '89197',
    city: 'Weidenstetten'
  },
  {
    postalCode: '89198',
    city: 'Westerstetten'
  },
  {
    postalCode: '89231',
    city: 'Neu-Ulm'
  },
  {
    postalCode: '89233',
    city: 'Neu-Ulm'
  },
  {
    postalCode: '89250',
    city: 'Senden'
  },
  {
    postalCode: '89257',
    city: 'Illertissen'
  },
  {
    postalCode: '89264',
    city: 'Dirrfelden'
  },
  {
    postalCode: '89264',
    city: 'Riedhof'
  },
  {
    postalCode: '89264',
    city: 'Weißenhorn'
  },
  {
    postalCode: '89269',
    city: 'Vöhringen'
  },
  {
    postalCode: '89275',
    city: 'Elchingen'
  },
  {
    postalCode: '89278',
    city: 'Nersingen'
  },
  {
    postalCode: '89281',
    city: 'Altenstadt'
  },
  {
    postalCode: '89284',
    city: 'Pfaffenhofen'
  },
  {
    postalCode: '89287',
    city: 'Bellenberg'
  },
  {
    postalCode: '89290',
    city: 'Buch'
  },
  {
    postalCode: '89290',
    city: 'Hochbuch'
  },
  {
    postalCode: '89291',
    city: 'Holzheim'
  },
  {
    postalCode: '89293',
    city: 'Kellmünz'
  },
  {
    postalCode: '89294',
    city: 'Oberroth'
  },
  {
    postalCode: '89296',
    city: 'Osterberg'
  },
  {
    postalCode: '89297',
    city: 'Roggenburg'
  },
  {
    postalCode: '89299',
    city: 'Unterroth'
  },
  {
    postalCode: '89312',
    city: 'Günzburg'
  },
  {
    postalCode: '89331',
    city: 'Burgau'
  },
  {
    postalCode: '89335',
    city: 'Brandfeld'
  },
  {
    postalCode: '89335',
    city: 'Heubelsburg'
  },
  {
    postalCode: '89335',
    city: 'Ichenhausen'
  },
  {
    postalCode: '89335',
    city: 'Wiblishauserhof'
  },
  {
    postalCode: '89340',
    city: 'Leipheim'
  },
  {
    postalCode: '89343',
    city: 'Jettingen-Scheppach'
  },
  {
    postalCode: '89344',
    city: 'Aislingen'
  },
  {
    postalCode: '89346',
    city: 'Bibertal'
  },
  {
    postalCode: '89347',
    city: 'Bubesheim'
  },
  {
    postalCode: '89349',
    city: 'Burtenbach'
  },
  {
    postalCode: '89350',
    city: 'Dürrlauingen'
  },
  {
    postalCode: '89352',
    city: 'Ellzee'
  },
  {
    postalCode: '89353',
    city: 'Glött'
  },
  {
    postalCode: '89355',
    city: 'Gundremmingen'
  },
  {
    postalCode: '89356',
    city: 'Haldenwang'
  },
  {
    postalCode: '89358',
    city: 'Kammeltal'
  },
  {
    postalCode: '89359',
    city: 'Kötz'
  },
  {
    postalCode: '89361',
    city: 'Landensberg'
  },
  {
    postalCode: '89362',
    city: 'Offingen'
  },
  {
    postalCode: '89364',
    city: 'Rettenbach'
  },
  {
    postalCode: '89365',
    city: 'Röfingen'
  },
  {
    postalCode: '89367',
    city: 'Waldstetten'
  },
  {
    postalCode: '89368',
    city: 'Winterbach'
  },
  {
    postalCode: '89407',
    city: 'Dillingen'
  },
  {
    postalCode: '89415',
    city: 'Lauingen'
  },
  {
    postalCode: '89420',
    city: 'Höchstädt'
  },
  {
    postalCode: '89423',
    city: 'Gundelfingen'
  },
  {
    postalCode: '89426',
    city: 'Mödingen'
  },
  {
    postalCode: '89426',
    city: 'Wittislingen'
  },
  {
    postalCode: '89428',
    city: 'Syrgenstein'
  },
  {
    postalCode: '89429',
    city: 'Bachhagel'
  },
  {
    postalCode: '89431',
    city: 'Bächingen'
  },
  {
    postalCode: '89434',
    city: 'Blindheim'
  },
  {
    postalCode: '89435',
    city: 'Finningen'
  },
  {
    postalCode: '89437',
    city: 'Haunsheim'
  },
  {
    postalCode: '89438',
    city: 'Holzheim'
  },
  {
    postalCode: '89440',
    city: 'Lutzingen'
  },
  {
    postalCode: '89441',
    city: 'Medlingen'
  },
  {
    postalCode: '89443',
    city: 'Schwenningen'
  },
  {
    postalCode: '89446',
    city: 'Ziertheim'
  },
  {
    postalCode: '89447',
    city: 'Zöschingen'
  },
  {
    postalCode: '89518',
    city: 'Heidenheim'
  },
  {
    postalCode: '89520',
    city: 'Heidenheim'
  },
  {
    postalCode: '89522',
    city: 'Asbach'
  },
  {
    postalCode: '89522',
    city: 'Buchhof'
  },
  {
    postalCode: '89522',
    city: 'Christophruhe'
  },
  {
    postalCode: '89522',
    city: 'Heidenheim'
  },
  {
    postalCode: '89522',
    city: 'Neuasbach'
  },
  {
    postalCode: '89537',
    city: 'Gerschweiler'
  },
  {
    postalCode: '89537',
    city: 'Giengen'
  },
  {
    postalCode: '89542',
    city: 'Herbrechtingen'
  },
  {
    postalCode: '89547',
    city: 'Gerstetten'
  },
  {
    postalCode: '89551',
    city: 'Königsbronn'
  },
  {
    postalCode: '89555',
    city: 'Steinheim'
  },
  {
    postalCode: '89558',
    city: 'Böhmenkirch'
  },
  {
    postalCode: '89561',
    city: 'Dischingen'
  },
  {
    postalCode: '89564',
    city: 'Nattheim'
  },
  {
    postalCode: '89567',
    city: 'Sontheim'
  },
  {
    postalCode: '89568',
    city: 'Hermaringen'
  },
  {
    postalCode: '89584',
    city: 'Ehingen'
  },
  {
    postalCode: '89584',
    city: 'Lauterach'
  },
  {
    postalCode: '89597',
    city: 'Hausen'
  },
  {
    postalCode: '89597',
    city: 'Munderkingen'
  },
  {
    postalCode: '89597',
    city: 'Unterwachingen'
  },
  {
    postalCode: '89601',
    city: 'Karlshof'
  },
  {
    postalCode: '89601',
    city: 'Schelklingen'
  },
  {
    postalCode: '89604',
    city: 'Allmendingen'
  },
  {
    postalCode: '89605',
    city: 'Altheim'
  },
  {
    postalCode: '89607',
    city: 'Emerkingen'
  },
  {
    postalCode: '89608',
    city: 'Griesingen'
  },
  {
    postalCode: '89610',
    city: 'Oberdischingen'
  },
  {
    postalCode: '89611',
    city: 'Brühlhof'
  },
  {
    postalCode: '89611',
    city: 'Obermarchtal'
  },
  {
    postalCode: '89611',
    city: 'Rechtenstein'
  },
  {
    postalCode: '89613',
    city: 'Grundsheim'
  },
  {
    postalCode: '89613',
    city: 'Oberstadion'
  },
  {
    postalCode: '89614',
    city: 'Öpfingen'
  },
  {
    postalCode: '89616',
    city: 'Rottenacker'
  },
  {
    postalCode: '89617',
    city: 'Untermarchtal'
  },
  {
    postalCode: '89619',
    city: 'Unterstadion'
  },
  {
    postalCode: '90402',
    city: 'Nürnberg'
  },
  {
    postalCode: '90403',
    city: 'Nürnberg'
  },
  {
    postalCode: '90408',
    city: 'Nürnberg'
  },
  {
    postalCode: '90409',
    city: 'Nürnberg'
  },
  {
    postalCode: '90411',
    city: 'Nürnberg'
  },
  {
    postalCode: '90419',
    city: 'Nürnberg'
  },
  {
    postalCode: '90425',
    city: 'Nürnberg'
  },
  {
    postalCode: '90427',
    city: 'Nürnberg'
  },
  {
    postalCode: '90429',
    city: 'Nürnberg'
  },
  {
    postalCode: '90431',
    city: 'Nürnberg'
  },
  {
    postalCode: '90439',
    city: 'Nürnberg'
  },
  {
    postalCode: '90441',
    city: 'Nürnberg'
  },
  {
    postalCode: '90443',
    city: 'Nürnberg'
  },
  {
    postalCode: '90449',
    city: 'Nürnberg'
  },
  {
    postalCode: '90451',
    city: 'Nürnberg'
  },
  {
    postalCode: '90453',
    city: 'Nürnberg'
  },
  {
    postalCode: '90455',
    city: 'Nürnberg'
  },
  {
    postalCode: '90459',
    city: 'Nürnberg'
  },
  {
    postalCode: '90461',
    city: 'Nürnberg'
  },
  {
    postalCode: '90469',
    city: 'Nürnberg'
  },
  {
    postalCode: '90471',
    city: 'Nürnberg'
  },
  {
    postalCode: '90473',
    city: 'Nürnberg'
  },
  {
    postalCode: '90475',
    city: 'Nürnberg'
  },
  {
    postalCode: '90478',
    city: 'Nürnberg'
  },
  {
    postalCode: '90480',
    city: 'Nürnberg'
  },
  {
    postalCode: '90482',
    city: 'Nürnberg'
  },
  {
    postalCode: '90489',
    city: 'Nürnberg'
  },
  {
    postalCode: '90491',
    city: 'Nürnberg'
  },
  {
    postalCode: '90513',
    city: 'Zirndorf'
  },
  {
    postalCode: '90518',
    city: 'Altdorf'
  },
  {
    postalCode: '90522',
    city: 'Oberasbach'
  },
  {
    postalCode: '90530',
    city: 'Wendelstein'
  },
  {
    postalCode: '90537',
    city: 'Feucht'
  },
  {
    postalCode: '90542',
    city: 'Eckental'
  },
  {
    postalCode: '90547',
    city: 'Stein'
  },
  {
    postalCode: '90552',
    city: 'Röthenbach'
  },
  {
    postalCode: '90556',
    city: 'Cadolzburg'
  },
  {
    postalCode: '90556',
    city: 'Seukendorf'
  },
  {
    postalCode: '90559',
    city: 'Burgthann'
  },
  {
    postalCode: '90562',
    city: 'Heroldsberg'
  },
  {
    postalCode: '90562',
    city: 'Kalchreuth'
  },
  {
    postalCode: '90571',
    city: 'Schwaig'
  },
  {
    postalCode: '90574',
    city: 'Roßtal'
  },
  {
    postalCode: '90579',
    city: 'Langenzenn'
  },
  {
    postalCode: '90584',
    city: 'Allersberg'
  },
  {
    postalCode: '90587',
    city: 'Obermichelbach'
  },
  {
    postalCode: '90587',
    city: 'Tuchenbach'
  },
  {
    postalCode: '90587',
    city: 'Veitsbronn'
  },
  {
    postalCode: '90592',
    city: 'Schwarzenbruck'
  },
  {
    postalCode: '90596',
    city: 'Schwanstetten'
  },
  {
    postalCode: '90599',
    city: 'Dietenhofen'
  },
  {
    postalCode: '90602',
    city: 'Pyrbaum'
  },
  {
    postalCode: '90607',
    city: 'Rückersdorf'
  },
  {
    postalCode: '90610',
    city: 'Winkelhaid'
  },
  {
    postalCode: '90613',
    city: 'Großhabersdorf'
  },
  {
    postalCode: '90614',
    city: 'Ammerndorf'
  },
  {
    postalCode: '90616',
    city: 'Neuhof'
  },
  {
    postalCode: '90617',
    city: 'Puschendorf'
  },
  {
    postalCode: '90619',
    city: 'Trautskirchen'
  },
  {
    postalCode: '90762',
    city: 'Fürth'
  },
  {
    postalCode: '90763',
    city: 'Fürth'
  },
  {
    postalCode: '90765',
    city: 'Fürth'
  },
  {
    postalCode: '90766',
    city: 'Fürth'
  },
  {
    postalCode: '90768',
    city: 'Fürth'
  },
  {
    postalCode: '91052',
    city: 'Erlangen'
  },
  {
    postalCode: '91054',
    city: 'Buckenhof'
  },
  {
    postalCode: '91054',
    city: 'Erlangen'
  },
  {
    postalCode: '91056',
    city: 'Erlangen'
  },
  {
    postalCode: '91058',
    city: 'Erlangen'
  },
  {
    postalCode: '91074',
    city: 'Herzogenaurach'
  },
  {
    postalCode: '91077',
    city: 'Dormitz'
  },
  {
    postalCode: '91077',
    city: 'Gabermühle'
  },
  {
    postalCode: '91077',
    city: 'Hetzles'
  },
  {
    postalCode: '91077',
    city: 'Kleinsendelbach'
  },
  {
    postalCode: '91077',
    city: 'Minderleinsmühle'
  },
  {
    postalCode: '91077',
    city: 'Neunkirchen'
  },
  {
    postalCode: '91080',
    city: 'Marloffstein'
  },
  {
    postalCode: '91080',
    city: 'Spardorf'
  },
  {
    postalCode: '91080',
    city: 'Uttenreuth'
  },
  {
    postalCode: '91083',
    city: 'Baiersdorf'
  },
  {
    postalCode: '91085',
    city: 'Weisendorf'
  },
  {
    postalCode: '91086',
    city: 'Aurachtal'
  },
  {
    postalCode: '91088',
    city: 'Bubenreuth'
  },
  {
    postalCode: '91090',
    city: 'Effeltrich'
  },
  {
    postalCode: '91091',
    city: 'Großenseebach'
  },
  {
    postalCode: '91093',
    city: 'Heßdorf'
  },
  {
    postalCode: '91094',
    city: 'Langensendelbach'
  },
  {
    postalCode: '91096',
    city: 'Möhrendorf'
  },
  {
    postalCode: '91097',
    city: 'Oberreichenbach'
  },
  {
    postalCode: '91099',
    city: 'Poxdorf'
  },
  {
    postalCode: '91126',
    city: 'Kammerstein'
  },
  {
    postalCode: '91126',
    city: 'Rednitzhembach'
  },
  {
    postalCode: '91126',
    city: 'Schwabach'
  },
  {
    postalCode: '91154',
    city: 'Roth'
  },
  {
    postalCode: '91161',
    city: 'Hilpoltstein'
  },
  {
    postalCode: '91166',
    city: 'Georgensgmünd'
  },
  {
    postalCode: '91171',
    city: 'Greding'
  },
  {
    postalCode: '91174',
    city: 'Spalt'
  },
  {
    postalCode: '91177',
    city: 'Thalmässing'
  },
  {
    postalCode: '91180',
    city: 'Heideck'
  },
  {
    postalCode: '91183',
    city: 'Abenberg'
  },
  {
    postalCode: '91186',
    city: 'Büchenbach'
  },
  {
    postalCode: '91187',
    city: 'Röttenbach'
  },
  {
    postalCode: '91189',
    city: 'Rohr'
  },
  {
    postalCode: '91207',
    city: 'Lauf'
  },
  {
    postalCode: '91217',
    city: 'Hersbruck'
  },
  {
    postalCode: '91220',
    city: 'Schnaittach'
  },
  {
    postalCode: '91224',
    city: 'Pommelsbrunn'
  },
  {
    postalCode: '91227',
    city: 'Leinburg'
  },
  {
    postalCode: '91230',
    city: 'Happurg'
  },
  {
    postalCode: '91233',
    city: 'Neunkirchen'
  },
  {
    postalCode: '91235',
    city: 'Hartenstein'
  },
  {
    postalCode: '91235',
    city: 'Velden'
  },
  {
    postalCode: '91236',
    city: 'Alfeld'
  },
  {
    postalCode: '91238',
    city: 'Engelthal'
  },
  {
    postalCode: '91238',
    city: 'Offenhausen'
  },
  {
    postalCode: '91239',
    city: 'Henfenfeld'
  },
  {
    postalCode: '91241',
    city: 'Kirchensittenbach'
  },
  {
    postalCode: '91242',
    city: 'Ottensoos'
  },
  {
    postalCode: '91244',
    city: 'Reichenschwand'
  },
  {
    postalCode: '91245',
    city: 'Simmelsdorf'
  },
  {
    postalCode: '91247',
    city: 'Vorra'
  },
  {
    postalCode: '91249',
    city: 'Weigendorf'
  },
  {
    postalCode: '91257',
    city: 'Pegnitz'
  },
  {
    postalCode: '91275',
    city: 'Auerbach'
  },
  {
    postalCode: '91278',
    city: 'Pottenstein'
  },
  {
    postalCode: '91281',
    city: 'Kirchenthumbach'
  },
  {
    postalCode: '91282',
    city: 'Betzenstein'
  },
  {
    postalCode: '91284',
    city: 'Neuhaus'
  },
  {
    postalCode: '91286',
    city: 'Obertrubach'
  },
  {
    postalCode: '91287',
    city: 'Plech'
  },
  {
    postalCode: '91289',
    city: 'Schnabelwaid'
  },
  {
    postalCode: '91301',
    city: 'Forchheim'
  },
  {
    postalCode: '91315',
    city: 'Höchstadt'
  },
  {
    postalCode: '91320',
    city: 'Ebermannstadt'
  },
  {
    postalCode: '91322',
    city: 'Gräfenberg'
  },
  {
    postalCode: '91325',
    city: 'Adelsdorf'
  },
  {
    postalCode: '91327',
    city: 'Gößweinstein'
  },
  {
    postalCode: '91330',
    city: 'Eggolsheim'
  },
  {
    postalCode: '91332',
    city: 'Heiligenstadt'
  },
  {
    postalCode: '91334',
    city: 'Hemhofen'
  },
  {
    postalCode: '91336',
    city: 'Heroldsbach'
  },
  {
    postalCode: '91338',
    city: 'Igensdorf'
  },
  {
    postalCode: '91341',
    city: 'Röttenbach'
  },
  {
    postalCode: '91344',
    city: 'Fuchshof'
  },
  {
    postalCode: '91344',
    city: 'Schottersmühle'
  },
  {
    postalCode: '91344',
    city: 'Waischenfeld'
  },
  {
    postalCode: '91346',
    city: 'Wiesenttal'
  },
  {
    postalCode: '91347',
    city: 'Aufseß'
  },
  {
    postalCode: '91349',
    city: 'Egloffstein'
  },
  {
    postalCode: '91350',
    city: 'Gremsdorf'
  },
  {
    postalCode: '91352',
    city: 'Hallerndorf'
  },
  {
    postalCode: '91353',
    city: 'Hausen'
  },
  {
    postalCode: '91355',
    city: 'Hiltpoltstein'
  },
  {
    postalCode: '91356',
    city: 'Kirchehrenbach'
  },
  {
    postalCode: '91358',
    city: 'Kunreuth'
  },
  {
    postalCode: '91358',
    city: 'Steingraben'
  },
  {
    postalCode: '91359',
    city: 'Leutenbach'
  },
  {
    postalCode: '91361',
    city: 'Pinzberg'
  },
  {
    postalCode: '91362',
    city: 'Pretzfeld'
  },
  {
    postalCode: '91364',
    city: 'Unterleinleiter'
  },
  {
    postalCode: '91365',
    city: 'Weilersbach'
  },
  {
    postalCode: '91367',
    city: 'Weißenohe'
  },
  {
    postalCode: '91369',
    city: 'Wiesenthau'
  },
  {
    postalCode: '91413',
    city: 'Neustadt'
  },
  {
    postalCode: '91438',
    city: 'Bad Windsheim'
  },
  {
    postalCode: '91438',
    city: 'Dutzenthal'
  },
  {
    postalCode: '91443',
    city: 'Scheinfeld'
  },
  {
    postalCode: '91448',
    city: 'Emskirchen'
  },
  {
    postalCode: '91452',
    city: 'Wilhermsdorf'
  },
  {
    postalCode: '91456',
    city: 'Diespeck'
  },
  {
    postalCode: '91459',
    city: 'Markt Erlbach'
  },
  {
    postalCode: '91460',
    city: 'Baudenbach'
  },
  {
    postalCode: '91462',
    city: 'Dachsbach'
  },
  {
    postalCode: '91463',
    city: 'Dietersheim'
  },
  {
    postalCode: '91465',
    city: 'Ergersheim'
  },
  {
    postalCode: '91466',
    city: 'Gerhardshofen'
  },
  {
    postalCode: '91468',
    city: 'Gutenstetten'
  },
  {
    postalCode: '91469',
    city: 'Erlachskirchen'
  },
  {
    postalCode: '91469',
    city: 'Hagenbüchach'
  },
  {
    postalCode: '91471',
    city: 'Illesheim'
  },
  {
    postalCode: '91472',
    city: 'Ipsheim'
  },
  {
    postalCode: '91474',
    city: 'Langenfeld'
  },
  {
    postalCode: '91475',
    city: 'Lonnerstadt'
  },
  {
    postalCode: '91477',
    city: 'Markt Bibart'
  },
  {
    postalCode: '91478',
    city: 'Markt Nordheim'
  },
  {
    postalCode: '91480',
    city: 'Markt Taschendorf'
  },
  {
    postalCode: '91481',
    city: 'Münchsteinach'
  },
  {
    postalCode: '91483',
    city: 'Oberscheinfeld'
  },
  {
    postalCode: '91484',
    city: 'Sugenheim'
  },
  {
    postalCode: '91486',
    city: 'Uehlfeld'
  },
  {
    postalCode: '91487',
    city: 'Vestenbergsgreuth'
  },
  {
    postalCode: '91489',
    city: 'Tanzenhaid'
  },
  {
    postalCode: '91489',
    city: 'Wilhelmsdorf'
  },
  {
    postalCode: '91522',
    city: 'Ansbach'
  },
  {
    postalCode: '91541',
    city: 'Rothenburg'
  },
  {
    postalCode: '91550',
    city: 'Dinkelsbühl'
  },
  {
    postalCode: '91555',
    city: 'Feuchtwangen'
  },
  {
    postalCode: '91560',
    city: 'Heilsbronn'
  },
  {
    postalCode: '91564',
    city: 'Neuendettelsau'
  },
  {
    postalCode: '91567',
    city: 'Herrieden'
  },
  {
    postalCode: '91572',
    city: 'Bechhofen'
  },
  {
    postalCode: '91575',
    city: 'Windsbach'
  },
  {
    postalCode: '91578',
    city: 'Leutershausen'
  },
  {
    postalCode: '91580',
    city: 'Petersaurach'
  },
  {
    postalCode: '91583',
    city: 'Diebach'
  },
  {
    postalCode: '91583',
    city: 'Schillingsfürst'
  },
  {
    postalCode: '91586',
    city: 'Lichtenau'
  },
  {
    postalCode: '91587',
    city: 'Adelshofen'
  },
  {
    postalCode: '91589',
    city: 'Aurach'
  },
  {
    postalCode: '91590',
    city: 'Bruckberg'
  },
  {
    postalCode: '91592',
    city: 'Buch a. Wald'
  },
  {
    postalCode: '91593',
    city: 'Burgbernheim'
  },
  {
    postalCode: '91595',
    city: 'Burgoberbach'
  },
  {
    postalCode: '91596',
    city: 'Burk'
  },
  {
    postalCode: '91598',
    city: 'Colmberg'
  },
  {
    postalCode: '91599',
    city: 'Dentlein'
  },
  {
    postalCode: '91601',
    city: 'Dombühl'
  },
  {
    postalCode: '91602',
    city: 'Dürrwangen'
  },
  {
    postalCode: '91604',
    city: 'Flachslanden'
  },
  {
    postalCode: '91605',
    city: 'Gallmersgarten'
  },
  {
    postalCode: '91607',
    city: 'Gebsattel'
  },
  {
    postalCode: '91608',
    city: 'Geslau'
  },
  {
    postalCode: '91610',
    city: 'Insingen'
  },
  {
    postalCode: '91611',
    city: 'Lehrberg'
  },
  {
    postalCode: '91613',
    city: 'Marktbergel'
  },
  {
    postalCode: '91614',
    city: 'Mönchsroth'
  },
  {
    postalCode: '91616',
    city: 'Neusitz'
  },
  {
    postalCode: '91617',
    city: 'Oberdachstetten'
  },
  {
    postalCode: '91619',
    city: 'Obernzenn'
  },
  {
    postalCode: '91620',
    city: 'Ohrenbach'
  },
  {
    postalCode: '91622',
    city: 'Rügland'
  },
  {
    postalCode: '91623',
    city: 'Sachsen'
  },
  {
    postalCode: '91625',
    city: 'Schnelldorf'
  },
  {
    postalCode: '91626',
    city: 'Schopfloch'
  },
  {
    postalCode: '91628',
    city: 'Steinsfeld'
  },
  {
    postalCode: '91629',
    city: 'Weihenzell'
  },
  {
    postalCode: '91631',
    city: 'Wettringen'
  },
  {
    postalCode: '91632',
    city: 'Wieseth'
  },
  {
    postalCode: '91634',
    city: 'Wilburgstetten'
  },
  {
    postalCode: '91635',
    city: 'Windelsbach'
  },
  {
    postalCode: '91637',
    city: 'Wörnitz'
  },
  {
    postalCode: '91639',
    city: 'Wolframs-Eschenbach'
  },
  {
    postalCode: '91710',
    city: 'Gunzenhausen'
  },
  {
    postalCode: '91717',
    city: 'Linkersbaindt'
  },
  {
    postalCode: '91717',
    city: 'Schmalzmühle'
  },
  {
    postalCode: '91717',
    city: 'Wassertrüdingen'
  },
  {
    postalCode: '91719',
    city: 'Heidenheim'
  },
  {
    postalCode: '91720',
    city: 'Absberg'
  },
  {
    postalCode: '91722',
    city: 'Arberg'
  },
  {
    postalCode: '91723',
    city: 'Dittenheim'
  },
  {
    postalCode: '91725',
    city: 'Ehingen'
  },
  {
    postalCode: '91726',
    city: 'Gerolfingen'
  },
  {
    postalCode: '91728',
    city: 'Gnotzheim'
  },
  {
    postalCode: '91729',
    city: 'Haundorf'
  },
  {
    postalCode: '91731',
    city: 'Langfurth'
  },
  {
    postalCode: '91732',
    city: 'Merkendorf'
  },
  {
    postalCode: '91734',
    city: 'Mitteleschenbach'
  },
  {
    postalCode: '91735',
    city: 'Muhr'
  },
  {
    postalCode: '91737',
    city: 'Ornbau'
  },
  {
    postalCode: '91738',
    city: 'Pfofeld'
  },
  {
    postalCode: '91740',
    city: 'Röckingen'
  },
  {
    postalCode: '91741',
    city: 'Theilenhofen'
  },
  {
    postalCode: '91743',
    city: 'Unterschwaningen'
  },
  {
    postalCode: '91744',
    city: 'Weiltingen'
  },
  {
    postalCode: '91746',
    city: 'Weidenbach'
  },
  {
    postalCode: '91747',
    city: 'Westheim'
  },
  {
    postalCode: '91749',
    city: 'Wittelshofen'
  },
  {
    postalCode: '91757',
    city: 'Treuchtlingen'
  },
  {
    postalCode: '91781',
    city: 'Weißenburg'
  },
  {
    postalCode: '91785',
    city: 'Pleinfeld'
  },
  {
    postalCode: '91788',
    city: 'Altheimersberg'
  },
  {
    postalCode: '91788',
    city: 'Pappenheim'
  },
  {
    postalCode: '91790',
    city: 'Bergen'
  },
  {
    postalCode: '91790',
    city: 'Burgsalach'
  },
  {
    postalCode: '91790',
    city: 'Nennslingen'
  },
  {
    postalCode: '91790',
    city: 'Raitenbuch'
  },
  {
    postalCode: '91792',
    city: 'Ellingen'
  },
  {
    postalCode: '91793',
    city: 'Alesheim'
  },
  {
    postalCode: '91795',
    city: 'Dollnstein'
  },
  {
    postalCode: '91796',
    city: 'Ettenstatt'
  },
  {
    postalCode: '91798',
    city: 'Höttingen'
  },
  {
    postalCode: '91799',
    city: 'Langenaltheim'
  },
  {
    postalCode: '91801',
    city: 'Markt Berolzheim'
  },
  {
    postalCode: '91802',
    city: 'Meinheim'
  },
  {
    postalCode: '91804',
    city: 'Mörnsheim'
  },
  {
    postalCode: '91805',
    city: 'Polsingen'
  },
  {
    postalCode: '91807',
    city: 'Lichtenberg'
  },
  {
    postalCode: '91807',
    city: 'Maxberg'
  },
  {
    postalCode: '91807',
    city: 'Solnhofen'
  },
  {
    postalCode: '91809',
    city: 'Wellheim'
  },
  {
    postalCode: '92224',
    city: 'Amberg'
  },
  {
    postalCode: '92237',
    city: 'Sulzbach-Rosenberg'
  },
  {
    postalCode: '92242',
    city: 'Hirschau'
  },
  {
    postalCode: '92245',
    city: 'Kümmersbruck'
  },
  {
    postalCode: '92249',
    city: 'Vilseck'
  },
  {
    postalCode: '92253',
    city: 'Schnaittenbach'
  },
  {
    postalCode: '92256',
    city: 'Hahnbach'
  },
  {
    postalCode: '92259',
    city: 'Neukirchen'
  },
  {
    postalCode: '92260',
    city: 'Ammerthal'
  },
  {
    postalCode: '92262',
    city: 'Birgland'
  },
  {
    postalCode: '92263',
    city: 'Ebermannsdorf'
  },
  {
    postalCode: '92265',
    city: 'Edelsfeld'
  },
  {
    postalCode: '92266',
    city: 'Ensdorf'
  },
  {
    postalCode: '92268',
    city: 'Etzelwang'
  },
  {
    postalCode: '92269',
    city: 'Fensterbach'
  },
  {
    postalCode: '92271',
    city: 'Freihung'
  },
  {
    postalCode: '92272',
    city: 'Freudenberg'
  },
  {
    postalCode: '92274',
    city: 'Gebenbach'
  },
  {
    postalCode: '92275',
    city: 'Hirschbach'
  },
  {
    postalCode: '92277',
    city: 'Hohenburg'
  },
  {
    postalCode: '92278',
    city: 'Illschwang'
  },
  {
    postalCode: '92280',
    city: 'Kastl'
  },
  {
    postalCode: '92281',
    city: 'Königstein'
  },
  {
    postalCode: '92283',
    city: 'Lauterhofen'
  },
  {
    postalCode: '92284',
    city: 'Poppenricht'
  },
  {
    postalCode: '92286',
    city: 'Rieden'
  },
  {
    postalCode: '92287',
    city: 'Schmidmühlen'
  },
  {
    postalCode: '92289',
    city: 'Ursensollen'
  },
  {
    postalCode: '92318',
    city: 'Neumarkt'
  },
  {
    postalCode: '92331',
    city: 'Lupburg'
  },
  {
    postalCode: '92331',
    city: 'Parsberg'
  },
  {
    postalCode: '92334',
    city: 'Berching'
  },
  {
    postalCode: '92339',
    city: 'Beilngries'
  },
  {
    postalCode: '92342',
    city: 'Freystadt'
  },
  {
    postalCode: '92342',
    city: 'Realsmühle'
  },
  {
    postalCode: '92345',
    city: 'Dietfurt'
  },
  {
    postalCode: '92345',
    city: 'Erbmühle'
  },
  {
    postalCode: '92345',
    city: 'Pfenninghof'
  },
  {
    postalCode: '92348',
    city: 'Berg'
  },
  {
    postalCode: '92348',
    city: 'Großwiesenhof'
  },
  {
    postalCode: '92353',
    city: 'Postbauer-Heng'
  },
  {
    postalCode: '92355',
    city: 'Velburg'
  },
  {
    postalCode: '92358',
    city: 'Seubersdorf'
  },
  {
    postalCode: '92360',
    city: 'Mühlhausen'
  },
  {
    postalCode: '92361',
    city: 'Berngau'
  },
  {
    postalCode: '92363',
    city: 'Breitenbrunn'
  },
  {
    postalCode: '92364',
    city: 'Deining'
  },
  {
    postalCode: '92366',
    city: 'Hohenfels'
  },
  {
    postalCode: '92367',
    city: 'Pilsach'
  },
  {
    postalCode: '92369',
    city: 'Sengenthal'
  },
  {
    postalCode: '92421',
    city: 'Schwandorf'
  },
  {
    postalCode: '92431',
    city: 'Neunburg'
  },
  {
    postalCode: '92436',
    city: 'Bruck'
  },
  {
    postalCode: '92439',
    city: 'Bodenwöhr'
  },
  {
    postalCode: '92442',
    city: 'Wackersdorf'
  },
  {
    postalCode: '92444',
    city: 'Rötz'
  },
  {
    postalCode: '92445',
    city: 'Neukirchen-Balbini'
  },
  {
    postalCode: '92447',
    city: 'Schwarzhofen'
  },
  {
    postalCode: '92449',
    city: 'Steinberg'
  },
  {
    postalCode: '92507',
    city: 'Nabburg'
  },
  {
    postalCode: '92521',
    city: 'Schwarzenfeld'
  },
  {
    postalCode: '92526',
    city: 'Oberviechtach'
  },
  {
    postalCode: '92533',
    city: 'Wernberg-Köblitz'
  },
  {
    postalCode: '92536',
    city: 'Pfreimd'
  },
  {
    postalCode: '92539',
    city: 'Schönsee'
  },
  {
    postalCode: '92540',
    city: 'Altendorf'
  },
  {
    postalCode: '92542',
    city: 'Dieterskirchen'
  },
  {
    postalCode: '92543',
    city: 'Guteneck'
  },
  {
    postalCode: '92545',
    city: 'Niedermurach'
  },
  {
    postalCode: '92546',
    city: 'Schmidgaden'
  },
  {
    postalCode: '92548',
    city: 'Schwarzach'
  },
  {
    postalCode: '92549',
    city: 'Stadlern'
  },
  {
    postalCode: '92551',
    city: 'Stulln'
  },
  {
    postalCode: '92552',
    city: 'Teunz'
  },
  {
    postalCode: '92554',
    city: 'Thanstein'
  },
  {
    postalCode: '92555',
    city: 'Trausnitz'
  },
  {
    postalCode: '92557',
    city: 'Weiding'
  },
  {
    postalCode: '92559',
    city: 'Winklarn'
  },
  {
    postalCode: '92637',
    city: 'Theisseil'
  },
  {
    postalCode: '92637',
    city: 'Weiden'
  },
  {
    postalCode: '92648',
    city: 'Vohenstrauß'
  },
  {
    postalCode: '92655',
    city: 'Grafenwöhr'
  },
  {
    postalCode: '92660',
    city: 'Neustadt'
  },
  {
    postalCode: '92665',
    city: 'Altenstadt'
  },
  {
    postalCode: '92665',
    city: 'Kirchendemenreuth'
  },
  {
    postalCode: '92670',
    city: 'Windischeschenbach'
  },
  {
    postalCode: '92676',
    city: 'Eschenbach'
  },
  {
    postalCode: '92676',
    city: 'Speinshart'
  },
  {
    postalCode: '92681',
    city: 'Erbendorf'
  },
  {
    postalCode: '92681',
    city: 'Kammerermühle'
  },
  {
    postalCode: '92681',
    city: 'Sassenhof'
  },
  {
    postalCode: '92685',
    city: 'Floß'
  },
  {
    postalCode: '92690',
    city: 'Glashütte'
  },
  {
    postalCode: '92690',
    city: 'Pressath'
  },
  {
    postalCode: '92693',
    city: 'Eslarn'
  },
  {
    postalCode: '92694',
    city: 'Etzenricht'
  },
  {
    postalCode: '92696',
    city: 'Flossenbürg'
  },
  {
    postalCode: '92697',
    city: 'Georgenberg'
  },
  {
    postalCode: '92699',
    city: 'Bechtsrieth'
  },
  {
    postalCode: '92699',
    city: 'Irchenrieth'
  },
  {
    postalCode: '92700',
    city: 'Kaltenbrunn'
  },
  {
    postalCode: '92702',
    city: 'Kohlberg'
  },
  {
    postalCode: '92703',
    city: 'Krummennaab'
  },
  {
    postalCode: '92705',
    city: 'Leuchtenberg'
  },
  {
    postalCode: '92706',
    city: 'Luhe-Wildenau'
  },
  {
    postalCode: '92708',
    city: 'Mantel'
  },
  {
    postalCode: '92709',
    city: 'Moosbach'
  },
  {
    postalCode: '92711',
    city: 'Parkstein'
  },
  {
    postalCode: '92712',
    city: 'Pirk'
  },
  {
    postalCode: '92714',
    city: 'Pleystein'
  },
  {
    postalCode: '92715',
    city: 'Püchersreuth'
  },
  {
    postalCode: '92717',
    city: 'Reuth'
  },
  {
    postalCode: '92718',
    city: 'Schirmitz'
  },
  {
    postalCode: '92720',
    city: 'Schwarzenbach'
  },
  {
    postalCode: '92721',
    city: 'Störnstein'
  },
  {
    postalCode: '92723',
    city: 'Gleiritsch'
  },
  {
    postalCode: '92723',
    city: 'Tännesberg'
  },
  {
    postalCode: '92724',
    city: 'Trabitz'
  },
  {
    postalCode: '92726',
    city: 'Waidhaus'
  },
  {
    postalCode: '92727',
    city: 'Waldthurn'
  },
  {
    postalCode: '92729',
    city: 'Weiherhammer'
  },
  {
    postalCode: '93047',
    city: 'Regensburg'
  },
  {
    postalCode: '93049',
    city: 'Regensburg'
  },
  {
    postalCode: '93051',
    city: 'Regensburg'
  },
  {
    postalCode: '93053',
    city: 'Regensburg'
  },
  {
    postalCode: '93055',
    city: 'Regensburg'
  },
  {
    postalCode: '93057',
    city: 'Regensburg'
  },
  {
    postalCode: '93059',
    city: 'Regensburg'
  },
  {
    postalCode: '93073',
    city: 'Neutraubling'
  },
  {
    postalCode: '93077',
    city: 'Bad Abbach'
  },
  {
    postalCode: '93080',
    city: 'Pentling'
  },
  {
    postalCode: '93083',
    city: 'Obertraubling'
  },
  {
    postalCode: '93086',
    city: 'Wörth'
  },
  {
    postalCode: '93087',
    city: 'Alteglofsheim'
  },
  {
    postalCode: '93089',
    city: 'Aufhausen'
  },
  {
    postalCode: '93090',
    city: 'Bach'
  },
  {
    postalCode: '93092',
    city: 'Barbing'
  },
  {
    postalCode: '93093',
    city: 'Donaustauf'
  },
  {
    postalCode: '93093',
    city: 'Jagdschloß Thiergarten'
  },
  {
    postalCode: '93095',
    city: 'Hagelstadt'
  },
  {
    postalCode: '93096',
    city: 'Köfering'
  },
  {
    postalCode: '93098',
    city: 'Mintraching'
  },
  {
    postalCode: '93099',
    city: 'Mötzing'
  },
  {
    postalCode: '93101',
    city: 'Pfakofen'
  },
  {
    postalCode: '93102',
    city: 'Pfatter'
  },
  {
    postalCode: '93104',
    city: 'Riekofen'
  },
  {
    postalCode: '93104',
    city: 'Sünching'
  },
  {
    postalCode: '93105',
    city: 'Tegernheim'
  },
  {
    postalCode: '93107',
    city: 'Thalmassing'
  },
  {
    postalCode: '93109',
    city: 'Wiesent'
  },
  {
    postalCode: '93128',
    city: 'Regenstauf'
  },
  {
    postalCode: '93133',
    city: 'Burglengenfeld'
  },
  {
    postalCode: '93138',
    city: 'Lappersdorf'
  },
  {
    postalCode: '93142',
    city: 'Maxhütte-Haidhof'
  },
  {
    postalCode: '93149',
    city: 'Nittenau'
  },
  {
    postalCode: '93149',
    city: 'Spitalhaus'
  },
  {
    postalCode: '93152',
    city: 'Deckelstein'
  },
  {
    postalCode: '93152',
    city: 'Ebenwies'
  },
  {
    postalCode: '93152',
    city: 'Hardt'
  },
  {
    postalCode: '93152',
    city: 'Nittendorf'
  },
  {
    postalCode: '93152',
    city: 'Zeiler'
  },
  {
    postalCode: '93155',
    city: 'Hemau'
  },
  {
    postalCode: '93158',
    city: 'Teublitz'
  },
  {
    postalCode: '93161',
    city: 'Grafenried'
  },
  {
    postalCode: '93161',
    city: 'Sinzing'
  },
  {
    postalCode: '93164',
    city: 'Brunn'
  },
  {
    postalCode: '93164',
    city: 'Laaber'
  },
  {
    postalCode: '93167',
    city: 'Falkenstein'
  },
  {
    postalCode: '93167',
    city: 'Holzmühle'
  },
  {
    postalCode: '93170',
    city: 'Bernhardswald'
  },
  {
    postalCode: '93173',
    city: 'Wenzenbach'
  },
  {
    postalCode: '93176',
    city: 'Beratzhausen'
  },
  {
    postalCode: '93177',
    city: 'Altenthann'
  },
  {
    postalCode: '93179',
    city: 'Brennberg'
  },
  {
    postalCode: '93180',
    city: 'Deuerling'
  },
  {
    postalCode: '93182',
    city: 'Duggendorf'
  },
  {
    postalCode: '93183',
    city: 'Holzheim'
  },
  {
    postalCode: '93183',
    city: 'Kallmünz'
  },
  {
    postalCode: '93185',
    city: 'Michelsneukirchen'
  },
  {
    postalCode: '93186',
    city: 'Pettendorf'
  },
  {
    postalCode: '93188',
    city: 'Pielenhofen'
  },
  {
    postalCode: '93189',
    city: 'Reichenbach'
  },
  {
    postalCode: '93191',
    city: 'Rettenbach'
  },
  {
    postalCode: '93192',
    city: 'Wald'
  },
  {
    postalCode: '93194',
    city: 'Walderbach'
  },
  {
    postalCode: '93195',
    city: 'Biersackschlag'
  },
  {
    postalCode: '93195',
    city: 'Wolfsegg'
  },
  {
    postalCode: '93197',
    city: 'Zeitlarn'
  },
  {
    postalCode: '93199',
    city: 'Zell'
  },
  {
    postalCode: '93309',
    city: 'Kelheim'
  },
  {
    postalCode: '93326',
    city: 'Abensberg'
  },
  {
    postalCode: '93333',
    city: 'Neustadt'
  },
  {
    postalCode: '93336',
    city: 'Altmannstein'
  },
  {
    postalCode: '93339',
    city: 'Riedenburg'
  },
  {
    postalCode: '93342',
    city: 'Saal'
  },
  {
    postalCode: '93343',
    city: 'Essing'
  },
  {
    postalCode: '93345',
    city: 'Hausen'
  },
  {
    postalCode: '93346',
    city: 'Ihrlerstein'
  },
  {
    postalCode: '93348',
    city: 'Kirchdorf'
  },
  {
    postalCode: '93349',
    city: 'Mindelstetten'
  },
  {
    postalCode: '93351',
    city: 'Painten'
  },
  {
    postalCode: '93352',
    city: 'Rohr'
  },
  {
    postalCode: '93354',
    city: 'Biburg'
  },
  {
    postalCode: '93354',
    city: 'Siegenburg'
  },
  {
    postalCode: '93356',
    city: 'Teugn'
  },
  {
    postalCode: '93358',
    city: 'Train'
  },
  {
    postalCode: '93359',
    city: 'Wildenberg'
  },
  {
    postalCode: '93413',
    city: 'Cham'
  },
  {
    postalCode: '93426',
    city: 'Roding'
  },
  {
    postalCode: '93437',
    city: 'Dürnberg'
  },
  {
    postalCode: '93437',
    city: 'Enklarn'
  },
  {
    postalCode: '93437',
    city: 'Furth im Wald'
  },
  {
    postalCode: '93444',
    city: 'Kötzting'
  },
  {
    postalCode: '93449',
    city: 'Albernhof'
  },
  {
    postalCode: '93449',
    city: 'Almosmühle'
  },
  {
    postalCode: '93449',
    city: 'Ast'
  },
  {
    postalCode: '93449',
    city: 'Eschlhof'
  },
  {
    postalCode: '93449',
    city: 'Geigant'
  },
  {
    postalCode: '93449',
    city: 'Haschaberg'
  },
  {
    postalCode: '93449',
    city: 'Häuslarn'
  },
  {
    postalCode: '93449',
    city: 'Hirschhöf'
  },
  {
    postalCode: '93449',
    city: 'Kritzenast'
  },
  {
    postalCode: '93449',
    city: 'Sinzendorf'
  },
  {
    postalCode: '93449',
    city: 'Waldmünchen'
  },
  {
    postalCode: '93453',
    city: 'Neukirchen'
  },
  {
    postalCode: '93455',
    city: 'Traitsching'
  },
  {
    postalCode: '93458',
    city: 'Eschlkam'
  },
  {
    postalCode: '93462',
    city: 'Lam'
  },
  {
    postalCode: '93464',
    city: 'Tiefenbach'
  },
  {
    postalCode: '93466',
    city: 'Chamerau'
  },
  {
    postalCode: '93468',
    city: 'Miltach'
  },
  {
    postalCode: '93470',
    city: 'Lohberg'
  },
  {
    postalCode: '93471',
    city: 'Arnbruck'
  },
  {
    postalCode: '93473',
    city: 'Arnschwang'
  },
  {
    postalCode: '93474',
    city: 'Arrach'
  },
  {
    postalCode: '93476',
    city: 'Blaibach'
  },
  {
    postalCode: '93477',
    city: 'Gleißenberg'
  },
  {
    postalCode: '93479',
    city: 'Grafenwiesen'
  },
  {
    postalCode: '93479',
    city: 'Watzlsteg'
  },
  {
    postalCode: '93480',
    city: 'Hohenwarth'
  },
  {
    postalCode: '93482',
    city: 'Pemfling'
  },
  {
    postalCode: '93483',
    city: 'Pösing'
  },
  {
    postalCode: '93485',
    city: 'Rimbach'
  },
  {
    postalCode: '93486',
    city: 'Runding'
  },
  {
    postalCode: '93488',
    city: 'Schönthal'
  },
  {
    postalCode: '93489',
    city: 'Schorndorf'
  },
  {
    postalCode: '93491',
    city: 'Stamsried'
  },
  {
    postalCode: '93492',
    city: 'Treffelstein'
  },
  {
    postalCode: '93494',
    city: 'Waffenbrunn'
  },
  {
    postalCode: '93495',
    city: 'Weiding'
  },
  {
    postalCode: '93497',
    city: 'Willmering'
  },
  {
    postalCode: '93499',
    city: 'Zandt'
  },
  {
    postalCode: '94032',
    city: 'Passau'
  },
  {
    postalCode: '94034',
    city: 'Passau'
  },
  {
    postalCode: '94036',
    city: 'Abraham'
  },
  {
    postalCode: '94036',
    city: 'Passau'
  },
  {
    postalCode: '94051',
    city: 'Hauzenberg'
  },
  {
    postalCode: '94060',
    city: 'Breitwies'
  },
  {
    postalCode: '94060',
    city: 'Pocking'
  },
  {
    postalCode: '94065',
    city: 'Waldkirchen'
  },
  {
    postalCode: '94072',
    city: 'Bad Füssing'
  },
  {
    postalCode: '94078',
    city: 'Freyung'
  },
  {
    postalCode: '94081',
    city: 'Fürstenzell'
  },
  {
    postalCode: '94086',
    city: 'Bad Griesbach'
  },
  {
    postalCode: '94089',
    city: 'Neureichenau'
  },
  {
    postalCode: '94094',
    city: 'Malching'
  },
  {
    postalCode: '94094',
    city: 'Rotthalmünster'
  },
  {
    postalCode: '94099',
    city: 'Ruhstorf'
  },
  {
    postalCode: '94104',
    city: 'Tittling'
  },
  {
    postalCode: '94104',
    city: 'Witzmannsberg'
  },
  {
    postalCode: '94107',
    city: 'Rollhäusl'
  },
  {
    postalCode: '94107',
    city: 'Untergriesbach'
  },
  {
    postalCode: '94110',
    city: 'Wegscheid'
  },
  {
    postalCode: '94113',
    city: 'Tiefenbach'
  },
  {
    postalCode: '94116',
    city: 'Hutthurm'
  },
  {
    postalCode: '94118',
    city: 'Jandelsbrunn'
  },
  {
    postalCode: '94121',
    city: 'Salzweg'
  },
  {
    postalCode: '94124',
    city: 'Büchlberg'
  },
  {
    postalCode: '94127',
    city: 'Neuburg'
  },
  {
    postalCode: '94130',
    city: 'Obernzell'
  },
  {
    postalCode: '94133',
    city: 'Röhrnbach'
  },
  {
    postalCode: '94136',
    city: 'Thyrnau'
  },
  {
    postalCode: '94137',
    city: 'Bayerbach'
  },
  {
    postalCode: '94139',
    city: 'Breitenberg'
  },
  {
    postalCode: '94140',
    city: 'Ering'
  },
  {
    postalCode: '94142',
    city: 'Fürsteneck'
  },
  {
    postalCode: '94143',
    city: 'Grainet'
  },
  {
    postalCode: '94145',
    city: 'Haidmühle'
  },
  {
    postalCode: '94146',
    city: 'Hinterschmiding'
  },
  {
    postalCode: '94148',
    city: 'Kirchham'
  },
  {
    postalCode: '94149',
    city: 'Kößlarn'
  },
  {
    postalCode: '94151',
    city: 'Mauth'
  },
  {
    postalCode: '94152',
    city: 'Neuhaus'
  },
  {
    postalCode: '94154',
    city: 'Neukirchen'
  },
  {
    postalCode: '94155',
    city: 'Otterskirchen'
  },
  {
    postalCode: '94157',
    city: 'Perlesreut'
  },
  {
    postalCode: '94158',
    city: 'Philippsreut'
  },
  {
    postalCode: '94160',
    city: 'Ringelai'
  },
  {
    postalCode: '94161',
    city: 'Ruderting'
  },
  {
    postalCode: '94163',
    city: 'Saldenburg'
  },
  {
    postalCode: '94164',
    city: 'Sonnen'
  },
  {
    postalCode: '94166',
    city: 'Stubenberg'
  },
  {
    postalCode: '94167',
    city: 'Tettenweis'
  },
  {
    postalCode: '94169',
    city: 'Thurmansbang'
  },
  {
    postalCode: '94209',
    city: 'Regen'
  },
  {
    postalCode: '94227',
    city: 'Lindberg'
  },
  {
    postalCode: '94227',
    city: 'Schwellhäusl'
  },
  {
    postalCode: '94227',
    city: 'Zwiesel'
  },
  {
    postalCode: '94234',
    city: 'Viechtach'
  },
  {
    postalCode: '94239',
    city: 'Gotteszell'
  },
  {
    postalCode: '94239',
    city: 'Ruhmannsfelden'
  },
  {
    postalCode: '94239',
    city: 'Zachenberg'
  },
  {
    postalCode: '94244',
    city: 'Geiersthal'
  },
  {
    postalCode: '94244',
    city: 'Teisnach'
  },
  {
    postalCode: '94249',
    city: 'Bodenmais'
  },
  {
    postalCode: '94250',
    city: 'Achslach'
  },
  {
    postalCode: '94252',
    city: 'Bayerisch Eisenstein'
  },
  {
    postalCode: '94253',
    city: 'Bischofsmais'
  },
  {
    postalCode: '94255',
    city: 'Böbrach'
  },
  {
    postalCode: '94256',
    city: 'Drachselsried'
  },
  {
    postalCode: '94258',
    city: 'Frauenau'
  },
  {
    postalCode: '94259',
    city: 'Kirchberg i. Wald'
  },
  {
    postalCode: '94261',
    city: 'Kirchdorf'
  },
  {
    postalCode: '94262',
    city: 'Kollnburg'
  },
  {
    postalCode: '94264',
    city: 'Langdorf'
  },
  {
    postalCode: '94265',
    city: 'Patersdorf'
  },
  {
    postalCode: '94267',
    city: 'Prackenbach'
  },
  {
    postalCode: '94269',
    city: 'Rinchnach'
  },
  {
    postalCode: '94315',
    city: 'Straubing'
  },
  {
    postalCode: '94327',
    city: 'Bogen'
  },
  {
    postalCode: '94330',
    city: 'Aiterhofen'
  },
  {
    postalCode: '94330',
    city: 'Salching'
  },
  {
    postalCode: '94333',
    city: 'Geiselhöring'
  },
  {
    postalCode: '94336',
    city: 'Hunderdorf'
  },
  {
    postalCode: '94336',
    city: 'Windberg'
  },
  {
    postalCode: '94339',
    city: 'Leiblfing'
  },
  {
    postalCode: '94342',
    city: 'Irlbach'
  },
  {
    postalCode: '94342',
    city: 'Straßkirchen'
  },
  {
    postalCode: '94344',
    city: 'Wiesenfelden'
  },
  {
    postalCode: '94345',
    city: 'Aholfing'
  },
  {
    postalCode: '94347',
    city: 'Ascha'
  },
  {
    postalCode: '94348',
    city: 'Atting'
  },
  {
    postalCode: '94350',
    city: 'Falkenfels'
  },
  {
    postalCode: '94351',
    city: 'Feldkirchen'
  },
  {
    postalCode: '94353',
    city: 'Haibach'
  },
  {
    postalCode: '94354',
    city: 'Haselbach'
  },
  {
    postalCode: '94356',
    city: 'Kirchroth'
  },
  {
    postalCode: '94357',
    city: 'Höhenstein'
  },
  {
    postalCode: '94357',
    city: 'Konzell'
  },
  {
    postalCode: '94359',
    city: 'Loitzendorf'
  },
  {
    postalCode: '94360',
    city: 'Mitterfels'
  },
  {
    postalCode: '94362',
    city: 'Neukirchen'
  },
  {
    postalCode: '94363',
    city: 'Oberschneiding'
  },
  {
    postalCode: '94365',
    city: 'Parkstetten'
  },
  {
    postalCode: '94366',
    city: 'Perasdorf'
  },
  {
    postalCode: '94368',
    city: 'Perkam'
  },
  {
    postalCode: '94369',
    city: 'Rain'
  },
  {
    postalCode: '94371',
    city: 'Rattenberg'
  },
  {
    postalCode: '94372',
    city: 'Rattiszell'
  },
  {
    postalCode: '94374',
    city: 'Eckhütt'
  },
  {
    postalCode: '94374',
    city: 'Oberlindberg'
  },
  {
    postalCode: '94374',
    city: 'Schwarzach'
  },
  {
    postalCode: '94375',
    city: 'Stallwang'
  },
  {
    postalCode: '94377',
    city: 'Steinach'
  },
  {
    postalCode: '94379',
    city: 'Sankt Englmar'
  },
  {
    postalCode: '94405',
    city: 'Landau'
  },
  {
    postalCode: '94419',
    city: 'Reisbach'
  },
  {
    postalCode: '94424',
    city: 'Arnstorf'
  },
  {
    postalCode: '94428',
    city: 'Eichendorf'
  },
  {
    postalCode: '94431',
    city: 'Pilsting'
  },
  {
    postalCode: '94436',
    city: 'Simbach'
  },
  {
    postalCode: '94437',
    city: 'Mamming'
  },
  {
    postalCode: '94439',
    city: 'Roßbach'
  },
  {
    postalCode: '94447',
    city: 'Plattling'
  },
  {
    postalCode: '94469',
    city: 'Deggendorf'
  },
  {
    postalCode: '94469',
    city: 'Freiberg'
  },
  {
    postalCode: '94474',
    city: 'Vilshofen'
  },
  {
    postalCode: '94481',
    city: 'Grafenau'
  },
  {
    postalCode: '94481',
    city: 'Rehbruck'
  },
  {
    postalCode: '94486',
    city: 'Osterhofen'
  },
  {
    postalCode: '94491',
    city: 'Hengersberg'
  },
  {
    postalCode: '94496',
    city: 'Ortenburg'
  },
  {
    postalCode: '94501',
    city: 'Aidenbach'
  },
  {
    postalCode: '94501',
    city: 'Aldersbach'
  },
  {
    postalCode: '94501',
    city: 'Beutelsbach'
  },
  {
    postalCode: '94501',
    city: 'Freudenheim'
  },
  {
    postalCode: '94501',
    city: 'Mairing'
  },
  {
    postalCode: '94505',
    city: 'Bernried'
  },
  {
    postalCode: '94508',
    city: 'Schöllnach'
  },
  {
    postalCode: '94513',
    city: 'Schönberg'
  },
  {
    postalCode: '94518',
    city: 'Linden'
  },
  {
    postalCode: '94518',
    city: 'Spiegelau'
  },
  {
    postalCode: '94522',
    city: 'Wallersdorf'
  },
  {
    postalCode: '94526',
    city: 'Dammersbach'
  },
  {
    postalCode: '94526',
    city: 'Laufmühle'
  },
  {
    postalCode: '94526',
    city: 'Lehmberg'
  },
  {
    postalCode: '94526',
    city: 'Metten'
  },
  {
    postalCode: '94527',
    city: 'Aholming'
  },
  {
    postalCode: '94529',
    city: 'Aicha'
  },
  {
    postalCode: '94529',
    city: 'Gottholling'
  },
  {
    postalCode: '94529',
    city: 'Kerschbaum'
  },
  {
    postalCode: '94529',
    city: 'Weiding'
  },
  {
    postalCode: '94530',
    city: 'Auerbach'
  },
  {
    postalCode: '94532',
    city: 'Außernzell'
  },
  {
    postalCode: '94533',
    city: 'Buchhofen'
  },
  {
    postalCode: '94535',
    city: 'Eging'
  },
  {
    postalCode: '94536',
    city: 'Eppenschlag'
  },
  {
    postalCode: '94538',
    city: 'Fürstenstein'
  },
  {
    postalCode: '94539',
    city: 'Grafling'
  },
  {
    postalCode: '94541',
    city: 'Grattersdorf'
  },
  {
    postalCode: '94542',
    city: 'Haarbach'
  },
  {
    postalCode: '94544',
    city: 'Hofkirchen'
  },
  {
    postalCode: '94545',
    city: 'Hohenau'
  },
  {
    postalCode: '94547',
    city: 'Iggensbach'
  },
  {
    postalCode: '94548',
    city: 'Innernzell'
  },
  {
    postalCode: '94550',
    city: 'Künzing'
  },
  {
    postalCode: '94551',
    city: 'Hunding'
  },
  {
    postalCode: '94551',
    city: 'Lalling'
  },
  {
    postalCode: '94551',
    city: 'Reigersberg'
  },
  {
    postalCode: '94553',
    city: 'Mariaposching'
  },
  {
    postalCode: '94554',
    city: 'Moos'
  },
  {
    postalCode: '94556',
    city: 'Neuschönau'
  },
  {
    postalCode: '94557',
    city: 'Niederalteich'
  },
  {
    postalCode: '94559',
    city: 'Niederwinkling'
  },
  {
    postalCode: '94560',
    city: 'Offenberg'
  },
  {
    postalCode: '94562',
    city: 'Oberpöring'
  },
  {
    postalCode: '94563',
    city: 'Otzing'
  },
  {
    postalCode: '94565',
    city: 'Bertholling'
  },
  {
    postalCode: '94565',
    city: 'Erlhof'
  },
  {
    postalCode: '94565',
    city: 'Haseneck'
  },
  {
    postalCode: '94565',
    city: 'Naßkamping'
  },
  {
    postalCode: '94565',
    city: 'Punzing'
  },
  {
    postalCode: '94565',
    city: 'Rathsmannsdorf'
  },
  {
    postalCode: '94565',
    city: 'Schwarzhöring'
  },
  {
    postalCode: '94565',
    city: 'Söldenham'
  },
  {
    postalCode: '94566',
    city: 'Reichenberg bei Riedlhütte'
  },
  {
    postalCode: '94566',
    city: 'Riedlhütte'
  },
  {
    postalCode: '94568',
    city: 'Auwies'
  },
  {
    postalCode: '94568',
    city: 'St Oswald'
  },
  {
    postalCode: '94569',
    city: 'Stephansposching'
  },
  {
    postalCode: '94571',
    city: 'Schaufling'
  },
  {
    postalCode: '94572',
    city: 'Schöfweg'
  },
  {
    postalCode: '94574',
    city: 'Wallerfing'
  },
  {
    postalCode: '94575',
    city: 'Windorf'
  },
  {
    postalCode: '94577',
    city: 'Winzer'
  },
  {
    postalCode: '94579',
    city: 'Zenting'
  },
  {
    postalCode: '95028',
    city: 'Hof'
  },
  {
    postalCode: '95030',
    city: 'Hof'
  },
  {
    postalCode: '95032',
    city: 'Hof'
  },
  {
    postalCode: '95100',
    city: 'Selb'
  },
  {
    postalCode: '95100',
    city: 'Vorsuchhütte'
  },
  {
    postalCode: '95111',
    city: 'Rehau'
  },
  {
    postalCode: '95119',
    city: 'Naila'
  },
  {
    postalCode: '95126',
    city: 'Schwarzenbach an der Saale'
  },
  {
    postalCode: '95131',
    city: 'Fels'
  },
  {
    postalCode: '95131',
    city: 'Schwarzenbach am Wald'
  },
  {
    postalCode: '95138',
    city: 'Bad Steben'
  },
  {
    postalCode: '95145',
    city: 'Oberkotzau'
  },
  {
    postalCode: '95152',
    city: 'Selbitz'
  },
  {
    postalCode: '95158',
    city: 'Kirchenlamitz'
  },
  {
    postalCode: '95158',
    city: 'Mittelschieda'
  },
  {
    postalCode: '95163',
    city: 'Weißenstadt'
  },
  {
    postalCode: '95168',
    city: 'Marktleuthen'
  },
  {
    postalCode: '95173',
    city: 'Schönwald'
  },
  {
    postalCode: '95176',
    city: 'Konradsreuth'
  },
  {
    postalCode: '95179',
    city: 'Geroldsgrün'
  },
  {
    postalCode: '95180',
    city: 'Berg'
  },
  {
    postalCode: '95182',
    city: 'Döhlau'
  },
  {
    postalCode: '95183',
    city: 'Feilitzsch'
  },
  {
    postalCode: '95183',
    city: 'Töpen'
  },
  {
    postalCode: '95183',
    city: 'Trogen'
  },
  {
    postalCode: '95185',
    city: 'Gattendorf'
  },
  {
    postalCode: '95186',
    city: 'Höchstädt'
  },
  {
    postalCode: '95188',
    city: 'Issigau'
  },
  {
    postalCode: '95189',
    city: 'Fattigsmühle'
  },
  {
    postalCode: '95189',
    city: 'Köditz'
  },
  {
    postalCode: '95191',
    city: 'Leupoldsgrün'
  },
  {
    postalCode: '95192',
    city: 'Lichtenberg'
  },
  {
    postalCode: '95194',
    city: 'Regnitzlosau'
  },
  {
    postalCode: '95195',
    city: 'Röslau'
  },
  {
    postalCode: '95197',
    city: 'Schauenstein'
  },
  {
    postalCode: '95199',
    city: 'Thierstein'
  },
  {
    postalCode: '95213',
    city: 'Münchberg'
  },
  {
    postalCode: '95233',
    city: 'Helmbrechts'
  },
  {
    postalCode: '95234',
    city: 'Sparneck'
  },
  {
    postalCode: '95236',
    city: 'Hinterbug'
  },
  {
    postalCode: '95236',
    city: 'Stammbach'
  },
  {
    postalCode: '95237',
    city: 'Weißdorf'
  },
  {
    postalCode: '95239',
    city: 'Zell'
  },
  {
    postalCode: '95326',
    city: 'Kulmbach'
  },
  {
    postalCode: '95336',
    city: 'Mainleus'
  },
  {
    postalCode: '95339',
    city: 'Neuenmarkt'
  },
  {
    postalCode: '95339',
    city: 'Wirsberg'
  },
  {
    postalCode: '95346',
    city: 'Stadtsteinach'
  },
  {
    postalCode: '95349',
    city: 'Thurnau'
  },
  {
    postalCode: '95352',
    city: 'Marktleugast'
  },
  {
    postalCode: '95355',
    city: 'Presseck'
  },
  {
    postalCode: '95355',
    city: 'Schmölz'
  },
  {
    postalCode: '95356',
    city: 'Grafengehaig'
  },
  {
    postalCode: '95356',
    city: 'Großrehmühle'
  },
  {
    postalCode: '95356',
    city: 'Kleinrehmühle'
  },
  {
    postalCode: '95356',
    city: 'Zegastmühle'
  },
  {
    postalCode: '95358',
    city: 'Guttenberg'
  },
  {
    postalCode: '95359',
    city: 'Kasendorf'
  },
  {
    postalCode: '95361',
    city: 'Ködnitz'
  },
  {
    postalCode: '95362',
    city: 'Kupferberg'
  },
  {
    postalCode: '95364',
    city: 'Ludwigschorgast'
  },
  {
    postalCode: '95365',
    city: 'Rugendorf'
  },
  {
    postalCode: '95367',
    city: 'Trebgast'
  },
  {
    postalCode: '95369',
    city: 'Untersteinach'
  },
  {
    postalCode: '95444',
    city: 'Bayreuth'
  },
  {
    postalCode: '95445',
    city: 'Bayreuth'
  },
  {
    postalCode: '95447',
    city: 'Bayreuth'
  },
  {
    postalCode: '95448',
    city: 'Bayreuth'
  },
  {
    postalCode: '95460',
    city: 'Bad Berneck'
  },
  {
    postalCode: '95460',
    city: 'Friedrichshof'
  },
  {
    postalCode: '95460',
    city: 'Lindenhof'
  },
  {
    postalCode: '95463',
    city: 'Bindlach'
  },
  {
    postalCode: '95466',
    city: 'Kirchenpingarten'
  },
  {
    postalCode: '95466',
    city: 'Weidenberg'
  },
  {
    postalCode: '95469',
    city: 'Speichersdorf'
  },
  {
    postalCode: '95473',
    city: 'Creußen'
  },
  {
    postalCode: '95473',
    city: 'Haag'
  },
  {
    postalCode: '95473',
    city: 'Prebitz'
  },
  {
    postalCode: '95478',
    city: 'Kemnath'
  },
  {
    postalCode: '95482',
    city: 'Gefrees'
  },
  {
    postalCode: '95485',
    city: 'Warmensteinach'
  },
  {
    postalCode: '95488',
    city: 'Eckersdorf'
  },
  {
    postalCode: '95490',
    city: 'Mistelgau'
  },
  {
    postalCode: '95490',
    city: 'Schöchleinsmühle'
  },
  {
    postalCode: '95491',
    city: 'Ahorntal'
  },
  {
    postalCode: '95493',
    city: 'Bischofsgrün'
  },
  {
    postalCode: '95494',
    city: 'Gesees'
  },
  {
    postalCode: '95496',
    city: 'Glashütten'
  },
  {
    postalCode: '95497',
    city: 'Goldkronach'
  },
  {
    postalCode: '95499',
    city: 'Harsdorf'
  },
  {
    postalCode: '95500',
    city: 'Heinersreuth'
  },
  {
    postalCode: '95502',
    city: 'Himmelkron'
  },
  {
    postalCode: '95503',
    city: 'Hummeltal'
  },
  {
    postalCode: '95505',
    city: 'Immenreuth'
  },
  {
    postalCode: '95506',
    city: 'Kastl'
  },
  {
    postalCode: '95508',
    city: 'Kulmain'
  },
  {
    postalCode: '95509',
    city: 'Marktschorgast'
  },
  {
    postalCode: '95511',
    city: 'Mistelbach'
  },
  {
    postalCode: '95512',
    city: 'Neudrossenfeld'
  },
  {
    postalCode: '95514',
    city: 'Neustadt'
  },
  {
    postalCode: '95515',
    city: 'Plankenfels'
  },
  {
    postalCode: '95515',
    city: 'Schnackenwöhr'
  },
  {
    postalCode: '95517',
    city: 'Emtmannsberg'
  },
  {
    postalCode: '95517',
    city: 'Seybothenreuth'
  },
  {
    postalCode: '95519',
    city: 'Schlammersdorf'
  },
  {
    postalCode: '95519',
    city: 'Vorbach'
  },
  {
    postalCode: '95615',
    city: 'Marktredwitz'
  },
  {
    postalCode: '95615',
    city: 'Silberhaus'
  },
  {
    postalCode: '95632',
    city: 'Wunsiedel'
  },
  {
    postalCode: '95643',
    city: 'Tirschenreuth'
  },
  {
    postalCode: '95652',
    city: 'Waldsassen'
  },
  {
    postalCode: '95659',
    city: 'Arzberg'
  },
  {
    postalCode: '95666',
    city: 'Leonberg'
  },
  {
    postalCode: '95666',
    city: 'Mitterteich'
  },
  {
    postalCode: '95671',
    city: 'Bärnau'
  },
  {
    postalCode: '95676',
    city: 'Wiesau'
  },
  {
    postalCode: '95679',
    city: 'Waldershof'
  },
  {
    postalCode: '95680',
    city: 'Bad Alexandersbad'
  },
  {
    postalCode: '95682',
    city: 'Brand'
  },
  {
    postalCode: '95683',
    city: 'Ebnath'
  },
  {
    postalCode: '95685',
    city: 'Falkenberg'
  },
  {
    postalCode: '95686',
    city: 'Fichtelberg'
  },
  {
    postalCode: '95686',
    city: 'Seehaus'
  },
  {
    postalCode: '95688',
    city: 'Friedenfels'
  },
  {
    postalCode: '95689',
    city: 'Fuchsmühl'
  },
  {
    postalCode: '95689',
    city: 'Hurtingöd'
  },
  {
    postalCode: '95691',
    city: 'Hohenberg'
  },
  {
    postalCode: '95692',
    city: 'Konnersreuth'
  },
  {
    postalCode: '95694',
    city: 'Mehlmeisel'
  },
  {
    postalCode: '95695',
    city: 'Mähring'
  },
  {
    postalCode: '95697',
    city: 'Nagel'
  },
  {
    postalCode: '95698',
    city: 'Neualbenreuth'
  },
  {
    postalCode: '95700',
    city: 'Neusorg'
  },
  {
    postalCode: '95701',
    city: 'Pechbrunn'
  },
  {
    postalCode: '95703',
    city: 'Auerberg'
  },
  {
    postalCode: '95703',
    city: 'Plößberg'
  },
  {
    postalCode: '95704',
    city: 'Pullenreuth'
  },
  {
    postalCode: '95706',
    city: 'Schirnding'
  },
  {
    postalCode: '95707',
    city: 'Thiersheim'
  },
  {
    postalCode: '95709',
    city: 'Tröstau'
  },
  {
    postalCode: '96047',
    city: 'Bamberg'
  },
  {
    postalCode: '96049',
    city: 'Bamberg'
  },
  {
    postalCode: '96050',
    city: 'Bamberg'
  },
  {
    postalCode: '96052',
    city: 'Bamberg'
  },
  {
    postalCode: '96103',
    city: 'Hallstadt'
  },
  {
    postalCode: '96106',
    city: 'Ebern'
  },
  {
    postalCode: '96110',
    city: 'Scheßlitz'
  },
  {
    postalCode: '96114',
    city: 'Hirschaid'
  },
  {
    postalCode: '96117',
    city: 'Memmelsdorf'
  },
  {
    postalCode: '96120',
    city: 'Bischberg'
  },
  {
    postalCode: '96123',
    city: 'Litzendorf'
  },
  {
    postalCode: '96126',
    city: 'Ermershausen'
  },
  {
    postalCode: '96126',
    city: 'Maroldsweisach'
  },
  {
    postalCode: '96129',
    city: 'Strullendorf'
  },
  {
    postalCode: '96132',
    city: 'Schlüsselfeld'
  },
  {
    postalCode: '96135',
    city: 'Stegaurach'
  },
  {
    postalCode: '96138',
    city: 'Burgebrach'
  },
  {
    postalCode: '96142',
    city: 'Hollfeld'
  },
  {
    postalCode: '96145',
    city: 'Seßlach'
  },
  {
    postalCode: '96146',
    city: 'Altendorf'
  },
  {
    postalCode: '96148',
    city: 'Baunach'
  },
  {
    postalCode: '96149',
    city: 'Breitengüßbach'
  },
  {
    postalCode: '96151',
    city: 'Breitbrunn'
  },
  {
    postalCode: '96152',
    city: 'Burghaslach'
  },
  {
    postalCode: '96154',
    city: 'Burgwindheim'
  },
  {
    postalCode: '96155',
    city: 'Buttenheim'
  },
  {
    postalCode: '96157',
    city: 'Ebrach'
  },
  {
    postalCode: '96158',
    city: 'Frensdorf'
  },
  {
    postalCode: '96160',
    city: 'Geiselwind'
  },
  {
    postalCode: '96161',
    city: 'Gerach'
  },
  {
    postalCode: '96163',
    city: 'Gundelsheim'
  },
  {
    postalCode: '96164',
    city: 'Kemmern'
  },
  {
    postalCode: '96166',
    city: 'Kirchlauter'
  },
  {
    postalCode: '96167',
    city: 'Königsfeld'
  },
  {
    postalCode: '96169',
    city: 'Lauter'
  },
  {
    postalCode: '96170',
    city: 'Lisberg'
  },
  {
    postalCode: '96170',
    city: 'Priesendorf'
  },
  {
    postalCode: '96172',
    city: 'Mühlhausen'
  },
  {
    postalCode: '96173',
    city: 'Oberhaid'
  },
  {
    postalCode: '96175',
    city: 'Pettstadt'
  },
  {
    postalCode: '96176',
    city: 'Pfarrweisach'
  },
  {
    postalCode: '96178',
    city: 'Pommersfelden'
  },
  {
    postalCode: '96179',
    city: 'Rattelsdorf'
  },
  {
    postalCode: '96181',
    city: 'Rauhenebrach'
  },
  {
    postalCode: '96182',
    city: 'Reckendorf'
  },
  {
    postalCode: '96184',
    city: 'Rentweinsdorf'
  },
  {
    postalCode: '96185',
    city: 'Schönbrunn'
  },
  {
    postalCode: '96187',
    city: 'Stadelhofen'
  },
  {
    postalCode: '96188',
    city: 'Stettfeld'
  },
  {
    postalCode: '96190',
    city: 'Untermerzbach'
  },
  {
    postalCode: '96191',
    city: 'Viereth-Trunstadt'
  },
  {
    postalCode: '96193',
    city: 'Wachenroth'
  },
  {
    postalCode: '96194',
    city: 'Walsdorf'
  },
  {
    postalCode: '96196',
    city: 'Wattendorf'
  },
  {
    postalCode: '96197',
    city: 'Wonsees'
  },
  {
    postalCode: '96199',
    city: 'Zapfendorf'
  },
  {
    postalCode: '96215',
    city: 'Lichtenfels'
  },
  {
    postalCode: '96224',
    city: 'Burgkunstadt'
  },
  {
    postalCode: '96231',
    city: 'Bad Staffelstein'
  },
  {
    postalCode: '96237',
    city: 'Ebersdorf'
  },
  {
    postalCode: '96242',
    city: 'Sonnefeld'
  },
  {
    postalCode: '96247',
    city: 'Michelau'
  },
  {
    postalCode: '96250',
    city: 'Ebensfeld'
  },
  {
    postalCode: '96253',
    city: 'Untersiemau'
  },
  {
    postalCode: '96257',
    city: 'Marktgraitz'
  },
  {
    postalCode: '96257',
    city: 'Redwitz'
  },
  {
    postalCode: '96260',
    city: 'Weismain'
  },
  {
    postalCode: '96264',
    city: 'Altenkunstadt'
  },
  {
    postalCode: '96268',
    city: 'Mitwitz'
  },
  {
    postalCode: '96269',
    city: 'Großheirath'
  },
  {
    postalCode: '96271',
    city: 'Grub'
  },
  {
    postalCode: '96272',
    city: 'Hochstadt'
  },
  {
    postalCode: '96274',
    city: 'Itzgrund'
  },
  {
    postalCode: '96275',
    city: 'Marktzeuln'
  },
  {
    postalCode: '96277',
    city: 'Schneckenlohe'
  },
  {
    postalCode: '96279',
    city: 'Weidhausen'
  },
  {
    postalCode: '96317',
    city: 'Kaltbuch'
  },
  {
    postalCode: '96317',
    city: 'Kronach'
  },
  {
    postalCode: '96328',
    city: 'Küps'
  },
  {
    postalCode: '96332',
    city: 'Pressig'
  },
  {
    postalCode: '96332',
    city: 'Rittersmühle'
  },
  {
    postalCode: '96337',
    city: 'Ludwigsstadt'
  },
  {
    postalCode: '96342',
    city: 'Stockheim'
  },
  {
    postalCode: '96346',
    city: 'Wallenfels'
  },
  {
    postalCode: '96349',
    city: 'Mauthaus'
  },
  {
    postalCode: '96349',
    city: 'Steinwiesen'
  },
  {
    postalCode: '96352',
    city: 'Wilhelmsthal'
  },
  {
    postalCode: '96355',
    city: 'Tettau'
  },
  {
    postalCode: '96358',
    city: 'Kohlmühle'
  },
  {
    postalCode: '96358',
    city: 'Reichenbach'
  },
  {
    postalCode: '96358',
    city: 'Teuschnitz'
  },
  {
    postalCode: '96361',
    city: 'Steinbach'
  },
  {
    postalCode: '96364',
    city: 'Marktrodach'
  },
  {
    postalCode: '96365',
    city: 'Nordhalben'
  },
  {
    postalCode: '96367',
    city: 'Tschirn'
  },
  {
    postalCode: '96369',
    city: 'Weißenbrunn'
  },
  {
    postalCode: '96369',
    city: 'Welzmühle'
  },
  {
    postalCode: '96450',
    city: 'Coburg'
  },
  {
    postalCode: '96465',
    city: 'Neustadt'
  },
  {
    postalCode: '96472',
    city: 'Rödental'
  },
  {
    postalCode: '96476',
    city: 'Bad Rodach'
  },
  {
    postalCode: '96479',
    city: 'Weitramsdorf'
  },
  {
    postalCode: '96482',
    city: 'Ahorn'
  },
  {
    postalCode: '96484',
    city: 'Meeder'
  },
  {
    postalCode: '96486',
    city: 'Lautertal'
  },
  {
    postalCode: '96487',
    city: 'Dörfles-Esbach'
  },
  {
    postalCode: '96489',
    city: 'Niederfüllbach'
  },
  {
    postalCode: '96515',
    city: 'Heinersdorf'
  },
  {
    postalCode: '96515',
    city: 'Hüttengrund'
  },
  {
    postalCode: '96515',
    city: 'Jagdshof'
  },
  {
    postalCode: '96515',
    city: 'Judenbach'
  },
  {
    postalCode: '96515',
    city: 'Neuenbau'
  },
  {
    postalCode: '96515',
    city: 'Sonneberg'
  },
  {
    postalCode: '96523',
    city: 'Eschenthal'
  },
  {
    postalCode: '96523',
    city: 'Haselbach'
  },
  {
    postalCode: '96523',
    city: 'Hasenthal'
  },
  {
    postalCode: '96523',
    city: 'Steinach'
  },
  {
    postalCode: '96524',
    city: 'Föritz'
  },
  {
    postalCode: '96524',
    city: 'Gefell'
  },
  {
    postalCode: '96524',
    city: 'Heubisch'
  },
  {
    postalCode: '96524',
    city: 'Mupperg'
  },
  {
    postalCode: '96524',
    city: 'Neuhaus-Schierschnitz'
  },
  {
    postalCode: '96528',
    city: 'Bachfeld'
  },
  {
    postalCode: '96528',
    city: 'Effelder'
  },
  {
    postalCode: '96528',
    city: 'Grümpen'
  },
  {
    postalCode: '96528',
    city: 'Rabenäußig'
  },
  {
    postalCode: '96528',
    city: 'Rauenstein'
  },
  {
    postalCode: '96528',
    city: 'Rückerswind'
  },
  {
    postalCode: '96528',
    city: 'Schalkau'
  },
  {
    postalCode: '96528',
    city: 'Seltendorf'
  },
  {
    postalCode: '96529',
    city: 'Mengersgereuth-Hämmern'
  },
  {
    postalCode: '97070',
    city: 'Würzburg'
  },
  {
    postalCode: '97072',
    city: 'Würzburg'
  },
  {
    postalCode: '97074',
    city: 'Würzburg'
  },
  {
    postalCode: '97076',
    city: 'Würzburg'
  },
  {
    postalCode: '97078',
    city: 'Würzburg'
  },
  {
    postalCode: '97080',
    city: 'Würzburg'
  },
  {
    postalCode: '97082',
    city: 'Würzburg'
  },
  {
    postalCode: '97084',
    city: 'Würzburg'
  },
  {
    postalCode: '97199',
    city: 'Ochsenfurt'
  },
  {
    postalCode: '97204',
    city: 'Höchberg'
  },
  {
    postalCode: '97209',
    city: 'Veitshöchheim'
  },
  {
    postalCode: '97215',
    city: 'Simmershofen'
  },
  {
    postalCode: '97215',
    city: 'Uffenheim'
  },
  {
    postalCode: '97215',
    city: 'Weigenheim'
  },
  {
    postalCode: '97218',
    city: 'Gerbrunn'
  },
  {
    postalCode: '97222',
    city: 'Rimpar'
  },
  {
    postalCode: '97225',
    city: 'Zellingen'
  },
  {
    postalCode: '97228',
    city: 'Rottendorf'
  },
  {
    postalCode: '97230',
    city: 'Estenfeld'
  },
  {
    postalCode: '97232',
    city: 'Giebelstadt'
  },
  {
    postalCode: '97234',
    city: 'Reichenberg'
  },
  {
    postalCode: '97236',
    city: 'Randersacker'
  },
  {
    postalCode: '97237',
    city: 'Altertheim'
  },
  {
    postalCode: '97239',
    city: 'Aub'
  },
  {
    postalCode: '97241',
    city: 'Bergtheim'
  },
  {
    postalCode: '97241',
    city: 'Oberpleichfeld'
  },
  {
    postalCode: '97243',
    city: 'Bieberehren'
  },
  {
    postalCode: '97244',
    city: 'Bütthard'
  },
  {
    postalCode: '97246',
    city: 'Eibelstadt'
  },
  {
    postalCode: '97247',
    city: 'Eisenheim'
  },
  {
    postalCode: '97249',
    city: 'Eisingen'
  },
  {
    postalCode: '97250',
    city: 'Erlabrunn'
  },
  {
    postalCode: '97252',
    city: 'Frickenhausen'
  },
  {
    postalCode: '97253',
    city: 'Gaukönigshofen'
  },
  {
    postalCode: '97255',
    city: 'Gelchsheim'
  },
  {
    postalCode: '97255',
    city: 'Sonderhofen'
  },
  {
    postalCode: '97256',
    city: 'Geroldshausen'
  },
  {
    postalCode: '97258',
    city: 'Gollhofen'
  },
  {
    postalCode: '97258',
    city: 'Hemmersheim'
  },
  {
    postalCode: '97258',
    city: 'Ippesheim'
  },
  {
    postalCode: '97258',
    city: 'Oberickelsheim'
  },
  {
    postalCode: '97259',
    city: 'Greußenheim'
  },
  {
    postalCode: '97261',
    city: 'Güntersleben'
  },
  {
    postalCode: '97262',
    city: 'Hausen'
  },
  {
    postalCode: '97264',
    city: 'Helmstadt'
  },
  {
    postalCode: '97265',
    city: 'Hettstadt'
  },
  {
    postalCode: '97267',
    city: 'Himmelstadt'
  },
  {
    postalCode: '97268',
    city: 'Kirchheim'
  },
  {
    postalCode: '97270',
    city: 'Kist'
  },
  {
    postalCode: '97271',
    city: 'Kleinrinderfeld'
  },
  {
    postalCode: '97273',
    city: 'Kürnach'
  },
  {
    postalCode: '97274',
    city: 'Leinach'
  },
  {
    postalCode: '97276',
    city: 'Margetshöchheim'
  },
  {
    postalCode: '97277',
    city: 'Neubrunn'
  },
  {
    postalCode: '97279',
    city: 'Prosselsheim'
  },
  {
    postalCode: '97280',
    city: 'Remlingen'
  },
  {
    postalCode: '97282',
    city: 'Retzstadt'
  },
  {
    postalCode: '97283',
    city: 'Riedenheim'
  },
  {
    postalCode: '97285',
    city: 'Röttingen'
  },
  {
    postalCode: '97285',
    city: 'Tauberrettersheim'
  },
  {
    postalCode: '97286',
    city: 'Sommerhausen'
  },
  {
    postalCode: '97286',
    city: 'Winterhausen'
  },
  {
    postalCode: '97288',
    city: 'Theilheim'
  },
  {
    postalCode: '97289',
    city: 'Thüngen'
  },
  {
    postalCode: '97291',
    city: 'Thüngersheim'
  },
  {
    postalCode: '97292',
    city: 'Holzkirchen'
  },
  {
    postalCode: '97292',
    city: 'Holzmühle'
  },
  {
    postalCode: '97292',
    city: 'Uettingen'
  },
  {
    postalCode: '97294',
    city: 'Unterpleichfeld'
  },
  {
    postalCode: '97295',
    city: 'Waldbrunn'
  },
  {
    postalCode: '97297',
    city: 'Waldbüttelbrunn'
  },
  {
    postalCode: '97299',
    city: 'Zell'
  },
  {
    postalCode: '97318',
    city: 'Biebelried'
  },
  {
    postalCode: '97318',
    city: 'Kitzingen'
  },
  {
    postalCode: '97320',
    city: 'Albertshofen'
  },
  {
    postalCode: '97320',
    city: 'Buchbrunn'
  },
  {
    postalCode: '97320',
    city: 'Großlangheim'
  },
  {
    postalCode: '97320',
    city: 'Mainstockheim'
  },
  {
    postalCode: '97320',
    city: 'Sulzfeld'
  },
  {
    postalCode: '97332',
    city: 'Volkach'
  },
  {
    postalCode: '97334',
    city: 'Nordheim'
  },
  {
    postalCode: '97334',
    city: 'Sommerach'
  },
  {
    postalCode: '97337',
    city: 'Dettelbach'
  },
  {
    postalCode: '97340',
    city: 'Marktbreit'
  },
  {
    postalCode: '97340',
    city: 'Martinsheim'
  },
  {
    postalCode: '97340',
    city: 'Segnitz'
  },
  {
    postalCode: '97342',
    city: 'Marktsteft'
  },
  {
    postalCode: '97342',
    city: 'Obernbreit'
  },
  {
    postalCode: '97342',
    city: 'Seinsheim'
  },
  {
    postalCode: '97346',
    city: 'Iphofen'
  },
  {
    postalCode: '97348',
    city: 'Markt Einersheim'
  },
  {
    postalCode: '97348',
    city: 'Rödelsee'
  },
  {
    postalCode: '97348',
    city: 'Willanzheim'
  },
  {
    postalCode: '97350',
    city: 'Mainbernheim'
  },
  {
    postalCode: '97353',
    city: 'Wiesentheid'
  },
  {
    postalCode: '97355',
    city: 'Abtswind'
  },
  {
    postalCode: '97355',
    city: 'Castell'
  },
  {
    postalCode: '97355',
    city: 'Kleinlangheim'
  },
  {
    postalCode: '97355',
    city: 'Rüdenhausen'
  },
  {
    postalCode: '97355',
    city: 'Wiesenbronn'
  },
  {
    postalCode: '97357',
    city: 'Prichsenstadt'
  },
  {
    postalCode: '97359',
    city: 'Münsterschwarzach Abtei'
  },
  {
    postalCode: '97359',
    city: 'Schwarzach'
  },
  {
    postalCode: '97421',
    city: 'Schweinfurt'
  },
  {
    postalCode: '97422',
    city: 'Schweinfurt'
  },
  {
    postalCode: '97424',
    city: 'Schweinfurt'
  },
  {
    postalCode: '97437',
    city: 'Haßfurt'
  },
  {
    postalCode: '97440',
    city: 'Werneck'
  },
  {
    postalCode: '97447',
    city: 'Frankenwinheim'
  },
  {
    postalCode: '97447',
    city: 'Gerolzhofen'
  },
  {
    postalCode: '97450',
    city: 'Arnstein'
  },
  {
    postalCode: '97453',
    city: 'Schonungen'
  },
  {
    postalCode: '97456',
    city: 'Dittelbrunn'
  },
  {
    postalCode: '97461',
    city: 'Hofheim'
  },
  {
    postalCode: '97464',
    city: 'Niederwerrn'
  },
  {
    postalCode: '97469',
    city: 'Gochsheim'
  },
  {
    postalCode: '97475',
    city: 'Zeil'
  },
  {
    postalCode: '97478',
    city: 'Knetzgau'
  },
  {
    postalCode: '97478',
    city: 'Reinhardswinden'
  },
  {
    postalCode: '97483',
    city: 'Eltmann'
  },
  {
    postalCode: '97486',
    city: 'Klaubmühle'
  },
  {
    postalCode: '97486',
    city: 'Königsberg'
  },
  {
    postalCode: '97488',
    city: 'Stadtlauringen'
  },
  {
    postalCode: '97490',
    city: 'Poppenhausen'
  },
  {
    postalCode: '97491',
    city: 'Aidhausen'
  },
  {
    postalCode: '97493',
    city: 'Bergrheinfeld'
  },
  {
    postalCode: '97494',
    city: 'Bundorf'
  },
  {
    postalCode: '97496',
    city: 'Burgpreppach'
  },
  {
    postalCode: '97497',
    city: 'Dingolshausen'
  },
  {
    postalCode: '97499',
    city: 'Donnersdorf'
  },
  {
    postalCode: '97500',
    city: 'Ebelsbach'
  },
  {
    postalCode: '97502',
    city: 'Euerbach'
  },
  {
    postalCode: '97503',
    city: 'Gädheim'
  },
  {
    postalCode: '97505',
    city: 'Geldersheim'
  },
  {
    postalCode: '97506',
    city: 'Grafenrheinfeld'
  },
  {
    postalCode: '97508',
    city: 'Grettstadt'
  },
  {
    postalCode: '97509',
    city: 'Kloster St Ludwig'
  },
  {
    postalCode: '97509',
    city: 'Kolitzheim'
  },
  {
    postalCode: '97511',
    city: 'Lülsfeld'
  },
  {
    postalCode: '97513',
    city: 'Michelau'
  },
  {
    postalCode: '97514',
    city: 'Markertsgrün'
  },
  {
    postalCode: '97514',
    city: 'Oberaurach'
  },
  {
    postalCode: '97516',
    city: 'Oberschwarzach'
  },
  {
    postalCode: '97517',
    city: 'Rannungen'
  },
  {
    postalCode: '97519',
    city: 'Riedbach'
  },
  {
    postalCode: '97520',
    city: 'Röthlein'
  },
  {
    postalCode: '97522',
    city: 'Sand'
  },
  {
    postalCode: '97523',
    city: 'Schwanfeld'
  },
  {
    postalCode: '97525',
    city: 'Schwebheim'
  },
  {
    postalCode: '97526',
    city: 'Reichelshof'
  },
  {
    postalCode: '97526',
    city: 'Sennfeld'
  },
  {
    postalCode: '97528',
    city: 'Sulzdorf'
  },
  {
    postalCode: '97529',
    city: 'Sulzheim'
  },
  {
    postalCode: '97531',
    city: 'Theres'
  },
  {
    postalCode: '97532',
    city: 'Üchtelhausen'
  },
  {
    postalCode: '97534',
    city: 'Waigolshausen'
  },
  {
    postalCode: '97535',
    city: 'Wasserlosen'
  },
  {
    postalCode: '97537',
    city: 'Wipfeld'
  },
  {
    postalCode: '97539',
    city: 'Wonfurt'
  },
  {
    postalCode: '97616',
    city: 'Bad Neustadt'
  },
  {
    postalCode: '97616',
    city: 'Salz'
  },
  {
    postalCode: '97618',
    city: 'Heustreu'
  },
  {
    postalCode: '97618',
    city: 'Hohenroth'
  },
  {
    postalCode: '97618',
    city: 'Hollstadt'
  },
  {
    postalCode: '97618',
    city: 'Niederlauer'
  },
  {
    postalCode: '97618',
    city: 'Rödelmaier'
  },
  {
    postalCode: '97618',
    city: 'Strahlungen'
  },
  {
    postalCode: '97618',
    city: 'Unsleben'
  },
  {
    postalCode: '97618',
    city: 'Wollbach'
  },
  {
    postalCode: '97618',
    city: 'Wülfershausen'
  },
  {
    postalCode: '97631',
    city: 'Bad Königshofen'
  },
  {
    postalCode: '97633',
    city: 'Aubstadt'
  },
  {
    postalCode: '97633',
    city: 'Großbardorf'
  },
  {
    postalCode: '97633',
    city: 'Großeibstadt'
  },
  {
    postalCode: '97633',
    city: 'Herbstadt'
  },
  {
    postalCode: '97633',
    city: 'Höchheim'
  },
  {
    postalCode: '97633',
    city: 'Saal'
  },
  {
    postalCode: '97633',
    city: 'Sulzfeld'
  },
  {
    postalCode: '97633',
    city: 'Trappstadt'
  },
  {
    postalCode: '97638',
    city: 'Mellrichstadt'
  },
  {
    postalCode: '97640',
    city: 'Hendungen'
  },
  {
    postalCode: '97640',
    city: 'Oberstreu'
  },
  {
    postalCode: '97640',
    city: 'Stockheim'
  },
  {
    postalCode: '97645',
    city: 'Ostheim'
  },
  {
    postalCode: '97647',
    city: 'Hausen'
  },
  {
    postalCode: '97647',
    city: 'Nordheim'
  },
  {
    postalCode: '97647',
    city: 'Schweinfurter Haus'
  },
  {
    postalCode: '97647',
    city: 'Sondheim'
  },
  {
    postalCode: '97647',
    city: 'Thüringer Hütte'
  },
  {
    postalCode: '97647',
    city: 'Willmars'
  },
  {
    postalCode: '97650',
    city: 'Fladungen'
  },
  {
    postalCode: '97653',
    city: 'Bischofsheim'
  },
  {
    postalCode: '97654',
    city: 'Bastheim'
  },
  {
    postalCode: '97656',
    city: 'Oberelsbach'
  },
  {
    postalCode: '97657',
    city: 'Sandberg'
  },
  {
    postalCode: '97659',
    city: 'Schönau'
  },
  {
    postalCode: '97688',
    city: 'Bad Kissingen'
  },
  {
    postalCode: '97702',
    city: 'Münnerstadt'
  },
  {
    postalCode: '97705',
    city: 'Burkardroth'
  },
  {
    postalCode: '97708',
    city: 'Bad Bocklet'
  },
  {
    postalCode: '97711',
    city: 'Maßbach'
  },
  {
    postalCode: '97711',
    city: 'Thundorf'
  },
  {
    postalCode: '97714',
    city: 'Oerlenbach'
  },
  {
    postalCode: '97717',
    city: 'Aura'
  },
  {
    postalCode: '97717',
    city: 'Euerdorf'
  },
  {
    postalCode: '97717',
    city: 'Sulzthal'
  },
  {
    postalCode: '97720',
    city: 'Nüdlingen'
  },
  {
    postalCode: '97723',
    city: 'Oberthulba'
  },
  {
    postalCode: '97724',
    city: 'Burglauer'
  },
  {
    postalCode: '97725',
    city: 'Elfershausen'
  },
  {
    postalCode: '97727',
    city: 'Fuchsstadt'
  },
  {
    postalCode: '97729',
    city: 'Ramsthal'
  },
  {
    postalCode: '97737',
    city: 'Gemünden'
  },
  {
    postalCode: '97753',
    city: 'Karlstadt'
  },
  {
    postalCode: '97762',
    city: 'Hammelburg'
  },
  {
    postalCode: '97769',
    city: 'Bad Brückenau'
  },
  {
    postalCode: '97772',
    city: 'Wildflecken'
  },
  {
    postalCode: '97773',
    city: 'Aura'
  },
  {
    postalCode: '97775',
    city: 'Burgsinn'
  },
  {
    postalCode: '97776',
    city: 'Eußenheim'
  },
  {
    postalCode: '97778',
    city: 'Fellen'
  },
  {
    postalCode: '97779',
    city: 'Geroda'
  },
  {
    postalCode: '97780',
    city: 'Gössenheim'
  },
  {
    postalCode: '97782',
    city: 'Gräfendorf'
  },
  {
    postalCode: '97783',
    city: 'Karsbach'
  },
  {
    postalCode: '97785',
    city: 'Mittelsinn'
  },
  {
    postalCode: '97786',
    city: 'Motten'
  },
  {
    postalCode: '97788',
    city: 'Neuendorf'
  },
  {
    postalCode: '97789',
    city: 'Oberleichtersbach'
  },
  {
    postalCode: '97791',
    city: 'Obersinn'
  },
  {
    postalCode: '97792',
    city: 'Riedenberg'
  },
  {
    postalCode: '97794',
    city: 'Rieneck'
  },
  {
    postalCode: '97795',
    city: 'Schondra'
  },
  {
    postalCode: '97797',
    city: 'Wartmannsroth'
  },
  {
    postalCode: '97799',
    city: 'Zeitlofs'
  },
  {
    postalCode: '97816',
    city: 'Lohr'
  },
  {
    postalCode: '97828',
    city: 'Fuchsenmühle'
  },
  {
    postalCode: '97828',
    city: 'Hessenmühle'
  },
  {
    postalCode: '97828',
    city: 'Marktheidenfeld'
  },
  {
    postalCode: '97833',
    city: 'Frammersbach'
  },
  {
    postalCode: '97834',
    city: 'Birkenfeld'
  },
  {
    postalCode: '97836',
    city: 'Bischbrunn'
  },
  {
    postalCode: '97836',
    city: 'Karlshöhe'
  },
  {
    postalCode: '97836',
    city: 'Schleifthor'
  },
  {
    postalCode: '97837',
    city: 'Erlenbach'
  },
  {
    postalCode: '97839',
    city: 'Esselbach'
  },
  {
    postalCode: '97840',
    city: 'Erlenfurt'
  },
  {
    postalCode: '97840',
    city: 'Hafenlohr'
  },
  {
    postalCode: '97840',
    city: 'Lichtenau'
  },
  {
    postalCode: '97842',
    city: 'Karbach'
  },
  {
    postalCode: '97843',
    city: 'Neuhütten'
  },
  {
    postalCode: '97845',
    city: 'Neustadt'
  },
  {
    postalCode: '97846',
    city: 'Partenstein'
  },
  {
    postalCode: '97848',
    city: 'Rechtenbach'
  },
  {
    postalCode: '97849',
    city: 'Roden'
  },
  {
    postalCode: '97851',
    city: 'Rothenfels'
  },
  {
    postalCode: '97852',
    city: 'Schleifmühle'
  },
  {
    postalCode: '97852',
    city: 'Schollbrunn'
  },
  {
    postalCode: '97854',
    city: 'Steinfeld'
  },
  {
    postalCode: '97855',
    city: 'Triefenstein'
  },
  {
    postalCode: '97857',
    city: 'Urspringen'
  },
  {
    postalCode: '97859',
    city: 'Wiesthal'
  },
  {
    postalCode: '97877',
    city: 'Wertheim'
  },
  {
    postalCode: '97892',
    city: 'Kreuzwertheim'
  },
  {
    postalCode: '97896',
    city: 'Ebenheiderhof'
  },
  {
    postalCode: '97896',
    city: 'Freudenberg'
  },
  {
    postalCode: '97896',
    city: 'Kirschfurt'
  },
  {
    postalCode: '97900',
    city: 'Külsheim'
  },
  {
    postalCode: '97901',
    city: 'Altenbuch'
  },
  {
    postalCode: '97903',
    city: 'Collenberg'
  },
  {
    postalCode: '97904',
    city: 'Dorfprozelten'
  },
  {
    postalCode: '97906',
    city: 'Faulbach'
  },
  {
    postalCode: '97907',
    city: 'Hasloch'
  },
  {
    postalCode: '97907',
    city: 'Karthause Grünau'
  },
  {
    postalCode: '97909',
    city: 'Stadtprozelten'
  },
  {
    postalCode: '97922',
    city: 'Lauda-Königshofen'
  },
  {
    postalCode: '97941',
    city: 'Tauberbischofsheim'
  },
  {
    postalCode: '97944',
    city: 'Boxberg'
  },
  {
    postalCode: '97947',
    city: 'Grünsfeld'
  },
  {
    postalCode: '97950',
    city: 'Großrinderfeld'
  },
  {
    postalCode: '97953',
    city: 'Königheim'
  },
  {
    postalCode: '97956',
    city: 'Werbach'
  },
  {
    postalCode: '97957',
    city: 'Bowiesen'
  },
  {
    postalCode: '97957',
    city: 'Wittighausen'
  },
  {
    postalCode: '97959',
    city: 'Assamstadt'
  },
  {
    postalCode: '97980',
    city: 'Bad Mergentheim'
  },
  {
    postalCode: '97980',
    city: 'Mönchshof'
  },
  {
    postalCode: '97990',
    city: 'Standorf'
  },
  {
    postalCode: '97990',
    city: 'Weikersheim'
  },
  {
    postalCode: '97993',
    city: 'Creglingen'
  },
  {
    postalCode: '97996',
    city: 'Niederstetten'
  },
  {
    postalCode: '97999',
    city: 'Igersheim'
  },
  {
    postalCode: '98527',
    city: 'Suhl'
  },
  {
    postalCode: '98528',
    city: 'Suhl'
  },
  {
    postalCode: '98529',
    city: 'Albrechts'
  },
  {
    postalCode: '98529',
    city: 'Suhl'
  },
  {
    postalCode: '98530',
    city: 'Dietzhausen'
  },
  {
    postalCode: '98530',
    city: 'Dillstädt'
  },
  {
    postalCode: '98530',
    city: 'Grub'
  },
  {
    postalCode: '98530',
    city: 'Marisfeld'
  },
  {
    postalCode: '98530',
    city: 'Oberstadt'
  },
  {
    postalCode: '98530',
    city: 'Rohr'
  },
  {
    postalCode: '98530',
    city: 'Schmeheim'
  },
  {
    postalCode: '98530',
    city: 'Wichtshausen'
  },
  {
    postalCode: '98544',
    city: 'Zella-Mehlis'
  },
  {
    postalCode: '98547',
    city: 'Christes'
  },
  {
    postalCode: '98547',
    city: 'Kühndorf'
  },
  {
    postalCode: '98547',
    city: 'Schwarza'
  },
  {
    postalCode: '98547',
    city: 'Viernau'
  },
  {
    postalCode: '98553',
    city: 'Ahlstädt'
  },
  {
    postalCode: '98553',
    city: 'Altendambach'
  },
  {
    postalCode: '98553',
    city: 'Bischofrod'
  },
  {
    postalCode: '98553',
    city: 'Breitenbach'
  },
  {
    postalCode: '98553',
    city: 'Eichenberg'
  },
  {
    postalCode: '98553',
    city: 'Erlau'
  },
  {
    postalCode: '98553',
    city: 'Hinternah'
  },
  {
    postalCode: '98553',
    city: 'Hirschbach'
  },
  {
    postalCode: '98553',
    city: 'Schleusingen'
  },
  {
    postalCode: '98553',
    city: 'Schleusingerneundorf'
  },
  {
    postalCode: '98553',
    city: 'St. Kilian'
  },
  {
    postalCode: '98554',
    city: 'Benshausen'
  },
  {
    postalCode: '98559',
    city: 'Gehlberg'
  },
  {
    postalCode: '98559',
    city: 'Oberhof'
  },
  {
    postalCode: '98574',
    city: 'Asbach'
  },
  {
    postalCode: '98574',
    city: 'Grumbach'
  },
  {
    postalCode: '98574',
    city: 'Mittelstille'
  },
  {
    postalCode: '98574',
    city: 'Schmalkalden'
  },
  {
    postalCode: '98587',
    city: 'Altersbach'
  },
  {
    postalCode: '98587',
    city: 'Bermbach'
  },
  {
    postalCode: '98587',
    city: 'Oberschönau'
  },
  {
    postalCode: '98587',
    city: 'Rotterode'
  },
  {
    postalCode: '98587',
    city: 'Springstille'
  },
  {
    postalCode: '98587',
    city: 'Steinbach-Hallenberg'
  },
  {
    postalCode: '98587',
    city: 'Unterschönau'
  },
  {
    postalCode: '98590',
    city: 'Georgenzell'
  },
  {
    postalCode: '98590',
    city: 'Mittelschmalkalden'
  },
  {
    postalCode: '98590',
    city: 'Möckers'
  },
  {
    postalCode: '98590',
    city: 'Rosa'
  },
  {
    postalCode: '98590',
    city: 'Roßdorf'
  },
  {
    postalCode: '98590',
    city: 'Schwallungen'
  },
  {
    postalCode: '98590',
    city: 'Wernshausen'
  },
  {
    postalCode: '98593',
    city: 'Floh-Seligenthal'
  },
  {
    postalCode: '98593',
    city: 'Kleinschmalkalden'
  },
  {
    postalCode: '98596',
    city: 'Trusetal'
  },
  {
    postalCode: '98597',
    city: 'Breitungen'
  },
  {
    postalCode: '98597',
    city: 'Fambach'
  },
  {
    postalCode: '98597',
    city: 'Heßles'
  },
  {
    postalCode: '98599',
    city: 'Brotterode'
  },
  {
    postalCode: '98617',
    city: 'Bauerbach'
  },
  {
    postalCode: '98617',
    city: 'Belrieth'
  },
  {
    postalCode: '98617',
    city: 'Bettenhausen'
  },
  {
    postalCode: '98617',
    city: 'Einhausen'
  },
  {
    postalCode: '98617',
    city: 'Ellingshausen'
  },
  {
    postalCode: '98617',
    city: 'Gerthausen'
  },
  {
    postalCode: '98617',
    city: 'Helmershausen'
  },
  {
    postalCode: '98617',
    city: 'Henneberg'
  },
  {
    postalCode: '98617',
    city: 'Hermannsfeld'
  },
  {
    postalCode: '98617',
    city: 'Herpf'
  },
  {
    postalCode: '98617',
    city: 'Leutersdorf'
  },
  {
    postalCode: '98617',
    city: 'Meiningen'
  },
  {
    postalCode: '98617',
    city: 'Neubrunn'
  },
  {
    postalCode: '98617',
    city: 'Obermaßfeld-Grimmenthal'
  },
  {
    postalCode: '98617',
    city: 'Ritschenhausen'
  },
  {
    postalCode: '98617',
    city: 'Stedtlingen'
  },
  {
    postalCode: '98617',
    city: 'Stepfershausen'
  },
  {
    postalCode: '98617',
    city: 'Sülzfeld'
  },
  {
    postalCode: '98617',
    city: 'Untermaßfeld'
  },
  {
    postalCode: '98617',
    city: 'Utendorf'
  },
  {
    postalCode: '98617',
    city: 'Vachdorf'
  },
  {
    postalCode: '98617',
    city: 'Wohlmuthausen'
  },
  {
    postalCode: '98617',
    city: 'Wölfershausen'
  },
  {
    postalCode: '98631',
    city: 'Behrungen'
  },
  {
    postalCode: '98631',
    city: 'Berkach'
  },
  {
    postalCode: '98631',
    city: 'Bibra'
  },
  {
    postalCode: '98631',
    city: 'Exdorf'
  },
  {
    postalCode: '98631',
    city: 'Haina'
  },
  {
    postalCode: '98631',
    city: 'Jüchsen'
  },
  {
    postalCode: '98631',
    city: 'Mendhausen'
  },
  {
    postalCode: '98631',
    city: 'Milz'
  },
  {
    postalCode: '98631',
    city: 'Nordheim'
  },
  {
    postalCode: '98631',
    city: 'Queienfeld'
  },
  {
    postalCode: '98631',
    city: 'Rentwertshausen'
  },
  {
    postalCode: '98631',
    city: 'Römhild'
  },
  {
    postalCode: '98631',
    city: 'Schwickershausen'
  },
  {
    postalCode: '98631',
    city: 'Westenfeld'
  },
  {
    postalCode: '98631',
    city: 'Wolfmannshausen'
  },
  {
    postalCode: '98634',
    city: 'Aschenhausen'
  },
  {
    postalCode: '98634',
    city: 'Birx'
  },
  {
    postalCode: '98634',
    city: 'Erbenhausen'
  },
  {
    postalCode: '98634',
    city: 'Frankenheim'
  },
  {
    postalCode: '98634',
    city: 'Friedelshausen'
  },
  {
    postalCode: '98634',
    city: 'Hümpfershausen'
  },
  {
    postalCode: '98634',
    city: 'Kaltensundheim'
  },
  {
    postalCode: '98634',
    city: 'Kaltenwestheim'
  },
  {
    postalCode: '98634',
    city: 'Mehmels'
  },
  {
    postalCode: '98634',
    city: 'Melpers'
  },
  {
    postalCode: '98634',
    city: 'Oberkatz'
  },
  {
    postalCode: '98634',
    city: 'Oberweid'
  },
  {
    postalCode: '98634',
    city: 'Oepfershausen'
  },
  {
    postalCode: '98634',
    city: 'Unterkatz'
  },
  {
    postalCode: '98634',
    city: 'Unterweid'
  },
  {
    postalCode: '98634',
    city: 'Wahns'
  },
  {
    postalCode: '98634',
    city: 'Wasungen'
  },
  {
    postalCode: '98639',
    city: 'Metzels'
  },
  {
    postalCode: '98639',
    city: 'Rippershausen'
  },
  {
    postalCode: '98639',
    city: 'Wallbach'
  },
  {
    postalCode: '98639',
    city: 'Walldorf'
  },
  {
    postalCode: '98646',
    city: 'Adelhausen'
  },
  {
    postalCode: '98646',
    city: 'Dingsleben'
  },
  {
    postalCode: '98646',
    city: 'Eishausen'
  },
  {
    postalCode: '98646',
    city: 'Gleichamberg'
  },
  {
    postalCode: '98646',
    city: 'Heßberg'
  },
  {
    postalCode: '98646',
    city: 'Hildburghausen'
  },
  {
    postalCode: '98646',
    city: 'Linden'
  },
  {
    postalCode: '98646',
    city: 'Massenhausen'
  },
  {
    postalCode: '98646',
    city: 'Reurieth'
  },
  {
    postalCode: '98646',
    city: 'Seidingstadt'
  },
  {
    postalCode: '98646',
    city: 'Sophienthal'
  },
  {
    postalCode: '98646',
    city: 'Steinfeld'
  },
  {
    postalCode: '98646',
    city: 'Stressenhausen'
  },
  {
    postalCode: '98646',
    city: 'Streufdorf'
  },
  {
    postalCode: '98660',
    city: 'Beinerstadt'
  },
  {
    postalCode: '98660',
    city: 'Ehrenberg'
  },
  {
    postalCode: '98660',
    city: 'Grimmelshausen'
  },
  {
    postalCode: '98660',
    city: 'Henfstädt'
  },
  {
    postalCode: '98660',
    city: 'Kloster Veßra'
  },
  {
    postalCode: '98660',
    city: 'Lengfeld'
  },
  {
    postalCode: '98660',
    city: 'St. Bernhard'
  },
  {
    postalCode: '98660',
    city: 'Themar'
  },
  {
    postalCode: '98663',
    city: 'Bad Colberg-Heldburg'
  },
  {
    postalCode: '98663',
    city: 'Gompertshausen'
  },
  {
    postalCode: '98663',
    city: 'Hellingen'
  },
  {
    postalCode: '98663',
    city: 'Rieth'
  },
  {
    postalCode: '98663',
    city: 'Schlechtsart'
  },
  {
    postalCode: '98663',
    city: 'Schweickershausen'
  },
  {
    postalCode: '98663',
    city: 'Ummerstadt'
  },
  {
    postalCode: '98663',
    city: 'Westhausen'
  },
  {
    postalCode: '98666',
    city: 'Biberau'
  },
  {
    postalCode: '98666',
    city: 'Masserberg'
  },
  {
    postalCode: '98666',
    city: 'Waffenrod'
  },
  {
    postalCode: '98667',
    city: 'Gießübel'
  },
  {
    postalCode: '98667',
    city: 'Langenbach'
  },
  {
    postalCode: '98667',
    city: 'Schönbrunn'
  },
  {
    postalCode: '98667',
    city: 'Steinbach'
  },
  {
    postalCode: '98667',
    city: 'Waldau'
  },
  {
    postalCode: '98667',
    city: 'Wiedersbach'
  },
  {
    postalCode: '98669',
    city: 'Veilsdorf'
  },
  {
    postalCode: '98673',
    city: 'Bockstadt'
  },
  {
    postalCode: '98673',
    city: 'Brattendorf'
  },
  {
    postalCode: '98673',
    city: 'Brünn'
  },
  {
    postalCode: '98673',
    city: 'Crock'
  },
  {
    postalCode: '98673',
    city: 'Eisfeld'
  },
  {
    postalCode: '98673',
    city: 'Harras'
  },
  {
    postalCode: '98673',
    city: 'Merbelsrod'
  },
  {
    postalCode: '98673',
    city: 'Poppenwind'
  },
  {
    postalCode: '98673',
    city: 'Schwarzbach'
  },
  {
    postalCode: '98678',
    city: 'Hirschendorf'
  },
  {
    postalCode: '98678',
    city: 'Saargrund'
  },
  {
    postalCode: '98678',
    city: 'Sachsenbrunn'
  },
  {
    postalCode: '98678',
    city: 'Schirnrod'
  },
  {
    postalCode: '98678',
    city: 'Stelzen'
  },
  {
    postalCode: '98693',
    city: 'Bücheloh'
  },
  {
    postalCode: '98693',
    city: 'Heyda'
  },
  {
    postalCode: '98693',
    city: 'Ilmenau'
  },
  {
    postalCode: '98693',
    city: 'Manebach'
  },
  {
    postalCode: '98693',
    city: 'Martinroda'
  },
  {
    postalCode: '98693',
    city: 'Oberpörlitz'
  },
  {
    postalCode: '98693',
    city: 'Roda'
  },
  {
    postalCode: '98693',
    city: 'Unterpörlitz'
  },
  {
    postalCode: '98701',
    city: 'Allersdorf'
  },
  {
    postalCode: '98701',
    city: 'Altenfeld'
  },
  {
    postalCode: '98701',
    city: 'Böhlen'
  },
  {
    postalCode: '98701',
    city: 'Friedersdorf'
  },
  {
    postalCode: '98701',
    city: 'Gillersdorf'
  },
  {
    postalCode: '98701',
    city: 'Großbreitenbach'
  },
  {
    postalCode: '98701',
    city: 'Herschdorf'
  },
  {
    postalCode: '98701',
    city: 'Neustadt'
  },
  {
    postalCode: '98701',
    city: 'Wildenspring'
  },
  {
    postalCode: '98701',
    city: 'Willmersdorf'
  },
  {
    postalCode: '98704',
    city: 'Gräfinau-Angstedt'
  },
  {
    postalCode: '98704',
    city: 'Langewiesen'
  },
  {
    postalCode: '98704',
    city: 'Oehrenstock'
  },
  {
    postalCode: '98704',
    city: 'Wümbach'
  },
  {
    postalCode: '98708',
    city: 'Gehren'
  },
  {
    postalCode: '98708',
    city: 'Möhrenbach'
  },
  {
    postalCode: '98708',
    city: 'Pennewitz'
  },
  {
    postalCode: '98711',
    city: 'Frauenwald'
  },
  {
    postalCode: '98711',
    city: 'Schmiedefeld'
  },
  {
    postalCode: '98711',
    city: 'Vesser'
  },
  {
    postalCode: '98714',
    city: 'Stützerbach'
  },
  {
    postalCode: '98716',
    city: 'Elgersburg'
  },
  {
    postalCode: '98716',
    city: 'Geraberg'
  },
  {
    postalCode: '98716',
    city: 'Geschwenda'
  },
  {
    postalCode: '98724',
    city: 'Ernstthal'
  },
  {
    postalCode: '98724',
    city: 'Lauscha'
  },
  {
    postalCode: '98724',
    city: 'Neuhaus'
  },
  {
    postalCode: '98739',
    city: 'Lichte'
  },
  {
    postalCode: '98739',
    city: 'Piesau'
  },
  {
    postalCode: '98739',
    city: 'Reichmannsdorf'
  },
  {
    postalCode: '98739',
    city: 'Schmiedefeld'
  },
  {
    postalCode: '98743',
    city: 'Buchbach'
  },
  {
    postalCode: '98743',
    city: 'Gebersdorf'
  },
  {
    postalCode: '98743',
    city: 'Gräfenthal'
  },
  {
    postalCode: '98743',
    city: 'Großneundorf'
  },
  {
    postalCode: '98743',
    city: 'Lichtenhain bei Gräfenthal'
  },
  {
    postalCode: '98743',
    city: 'Lippelsdorf'
  },
  {
    postalCode: '98743',
    city: 'Spechtsbrunn'
  },
  {
    postalCode: '98744',
    city: 'Cursdorf'
  },
  {
    postalCode: '98744',
    city: 'Deesbach'
  },
  {
    postalCode: '98744',
    city: 'Lichtenhain Bergbahn'
  },
  {
    postalCode: '98744',
    city: 'Meura'
  },
  {
    postalCode: '98744',
    city: 'Oberweißbach'
  },
  {
    postalCode: '98744',
    city: 'Unterweißbach'
  },
  {
    postalCode: '98746',
    city: 'Goldisthal'
  },
  {
    postalCode: '98746',
    city: 'Katzhütte'
  },
  {
    postalCode: '98746',
    city: 'Mellenbach-Glasbach'
  },
  {
    postalCode: '98746',
    city: 'Meuselbach-Schwarzmühle'
  },
  {
    postalCode: '98749',
    city: 'Friedrichshöhe'
  },
  {
    postalCode: '98749',
    city: 'Scheibe-Alsbach'
  },
  {
    postalCode: '98749',
    city: 'Siegmundsburg'
  },
  {
    postalCode: '98749',
    city: 'Steinheid'
  },
  {
    postalCode: '99084',
    city: 'Erfurt'
  },
  {
    postalCode: '99085',
    city: 'Erfurt'
  },
  {
    postalCode: '99086',
    city: 'Erfurt'
  },
  {
    postalCode: '99087',
    city: 'Erfurt'
  },
  {
    postalCode: '99089',
    city: 'Erfurt'
  },
  {
    postalCode: '99091',
    city: 'Erfurt'
  },
  {
    postalCode: '99092',
    city: 'Erfurt'
  },
  {
    postalCode: '99094',
    city: 'Erfurt'
  },
  {
    postalCode: '99096',
    city: 'Erfurt'
  },
  {
    postalCode: '99097',
    city: 'Erfurt'
  },
  {
    postalCode: '99098',
    city: 'Erfurt'
  },
  {
    postalCode: '99099',
    city: 'Erfurt'
  },
  {
    postalCode: '99100',
    city: 'Alach'
  },
  {
    postalCode: '99100',
    city: 'Bahnhof Zimmernsupra-Töttelstädt'
  },
  {
    postalCode: '99100',
    city: 'Bienstädt'
  },
  {
    postalCode: '99100',
    city: 'Dachwig'
  },
  {
    postalCode: '99100',
    city: 'Döllstädt'
  },
  {
    postalCode: '99100',
    city: 'Gierstädt'
  },
  {
    postalCode: '99100',
    city: 'Großfahner'
  },
  {
    postalCode: '99100',
    city: 'Salomonsborn'
  },
  {
    postalCode: '99100',
    city: 'Töttelstädt'
  },
  {
    postalCode: '99100',
    city: 'Zimmernsupra'
  },
  {
    postalCode: '99102',
    city: 'Egstedt am Steiger'
  },
  {
    postalCode: '99102',
    city: 'Haarberg'
  },
  {
    postalCode: '99102',
    city: 'Klettbach'
  },
  {
    postalCode: '99102',
    city: 'Niedernissa'
  },
  {
    postalCode: '99102',
    city: 'Rockhausen'
  },
  {
    postalCode: '99102',
    city: 'Rohdam am Haarberg'
  },
  {
    postalCode: '99102',
    city: 'Suhlequelle'
  },
  {
    postalCode: '99102',
    city: 'Waltersleben'
  },
  {
    postalCode: '99102',
    city: 'Windischholzhausen'
  },
  {
    postalCode: '99189',
    city: 'Andisleben'
  },
  {
    postalCode: '99189',
    city: 'Elxleben'
  },
  {
    postalCode: '99189',
    city: 'Gebesee'
  },
  {
    postalCode: '99189',
    city: 'Haßleben'
  },
  {
    postalCode: '99189',
    city: 'Kühnhausen'
  },
  {
    postalCode: '99189',
    city: 'Ringleben'
  },
  {
    postalCode: '99189',
    city: 'Tiefthal'
  },
  {
    postalCode: '99189',
    city: 'Walschleben'
  },
  {
    postalCode: '99189',
    city: 'Witterda'
  },
  {
    postalCode: '99192',
    city: 'Apfelstädt'
  },
  {
    postalCode: '99192',
    city: 'Ermstedt'
  },
  {
    postalCode: '99192',
    city: 'Frienstedt'
  },
  {
    postalCode: '99192',
    city: 'Gamstädt'
  },
  {
    postalCode: '99192',
    city: 'Gottstedt'
  },
  {
    postalCode: '99192',
    city: 'Ingersleben'
  },
  {
    postalCode: '99192',
    city: 'Luisenhof'
  },
  {
    postalCode: '99192',
    city: 'Marienthal'
  },
  {
    postalCode: '99192',
    city: 'Molsdorf'
  },
  {
    postalCode: '99192',
    city: 'Neudietendorf'
  },
  {
    postalCode: '99192',
    city: 'Nottleben'
  },
  {
    postalCode: '99192',
    city: 'Osterberg'
  },
  {
    postalCode: '99195',
    city: 'Alperstedt'
  },
  {
    postalCode: '99195',
    city: 'Eckstedt'
  },
  {
    postalCode: '99195',
    city: 'Großrudestedt'
  },
  {
    postalCode: '99195',
    city: 'Kleinrudestedt'
  },
  {
    postalCode: '99195',
    city: 'Kranichborn'
  },
  {
    postalCode: '99195',
    city: 'Markvippach'
  },
  {
    postalCode: '99195',
    city: 'Mittelhausen'
  },
  {
    postalCode: '99195',
    city: 'Nöda'
  },
  {
    postalCode: '99195',
    city: 'Riethnordhausen'
  },
  {
    postalCode: '99195',
    city: 'Schloßvippach'
  },
  {
    postalCode: '99195',
    city: 'Schwansee'
  },
  {
    postalCode: '99195',
    city: 'Schwerborn'
  },
  {
    postalCode: '99195',
    city: 'Stotternheim'
  },
  {
    postalCode: '99198',
    city: 'Azmannsdorf'
  },
  {
    postalCode: '99198',
    city: 'Büßleben'
  },
  {
    postalCode: '99198',
    city: 'Eichelborn'
  },
  {
    postalCode: '99198',
    city: 'Großmölsen'
  },
  {
    postalCode: '99198',
    city: 'Hayn'
  },
  {
    postalCode: '99198',
    city: 'Hochstedt'
  },
  {
    postalCode: '99198',
    city: 'Kerspleben'
  },
  {
    postalCode: '99198',
    city: 'Kleinmölsen'
  },
  {
    postalCode: '99198',
    city: 'Linderbach'
  },
  {
    postalCode: '99198',
    city: 'Mönchenholzhausen'
  },
  {
    postalCode: '99198',
    city: 'Obernissa'
  },
  {
    postalCode: '99198',
    city: 'Ollendorf'
  },
  {
    postalCode: '99198',
    city: 'Sohnstedt'
  },
  {
    postalCode: '99198',
    city: 'Töttleben'
  },
  {
    postalCode: '99198',
    city: 'Udestedt'
  },
  {
    postalCode: '99198',
    city: 'Urbich'
  },
  {
    postalCode: '99198',
    city: 'Vieselbach'
  },
  {
    postalCode: '99198',
    city: 'Wallichen'
  },
  {
    postalCode: '99310',
    city: 'Alkersleben'
  },
  {
    postalCode: '99310',
    city: 'Arnstadt'
  },
  {
    postalCode: '99310',
    city: 'Bösleben-Wüllersleben'
  },
  {
    postalCode: '99310',
    city: 'Dornheim'
  },
  {
    postalCode: '99310',
    city: 'Hohes Kreuz'
  },
  {
    postalCode: '99310',
    city: 'Osthausen-Wülfershausen'
  },
  {
    postalCode: '99310',
    city: 'Wachsenburggemeinde'
  },
  {
    postalCode: '99310',
    city: 'Wipfratal'
  },
  {
    postalCode: '99310',
    city: 'Witzleben'
  },
  {
    postalCode: '99326',
    city: 'Behringen'
  },
  {
    postalCode: '99326',
    city: 'Behringer Schenke'
  },
  {
    postalCode: '99326',
    city: 'Cottendorf'
  },
  {
    postalCode: '99326',
    city: 'Dienstedt-Hettstedt'
  },
  {
    postalCode: '99326',
    city: 'Dörnfeld an der Ilm'
  },
  {
    postalCode: '99326',
    city: 'Ehrenstein'
  },
  {
    postalCode: '99326',
    city: 'Geilsdorf'
  },
  {
    postalCode: '99326',
    city: 'Gösselborn'
  },
  {
    postalCode: '99326',
    city: 'Griesheim'
  },
  {
    postalCode: '99326',
    city: 'Großliebringen'
  },
  {
    postalCode: '99326',
    city: 'Hammersfeld'
  },
  {
    postalCode: '99326',
    city: 'Nahwinden'
  },
  {
    postalCode: '99326',
    city: 'Niederwillingen'
  },
  {
    postalCode: '99326',
    city: 'Oberwillingen'
  },
  {
    postalCode: '99326',
    city: 'Singen'
  },
  {
    postalCode: '99326',
    city: 'Stadtilm'
  },
  {
    postalCode: '99326',
    city: 'Traßdorf'
  },
  {
    postalCode: '99330',
    city: 'Crawinkel'
  },
  {
    postalCode: '99330',
    city: 'Frankenhain'
  },
  {
    postalCode: '99330',
    city: 'Gräfenroda'
  },
  {
    postalCode: '99330',
    city: 'Liebenstein'
  },
  {
    postalCode: '99334',
    city: 'Elleben'
  },
  {
    postalCode: '99334',
    city: 'Elxleben'
  },
  {
    postalCode: '99334',
    city: 'Ichtershausen'
  },
  {
    postalCode: '99334',
    city: 'Kirchheim'
  },
  {
    postalCode: '99338',
    city: 'Angelroda'
  },
  {
    postalCode: '99338',
    city: 'Gossel'
  },
  {
    postalCode: '99338',
    city: 'Neusiß'
  },
  {
    postalCode: '99338',
    city: 'Plaue'
  },
  {
    postalCode: '99423',
    city: 'Weimar'
  },
  {
    postalCode: '99425',
    city: 'Weimar'
  },
  {
    postalCode: '99427',
    city: 'Weimar'
  },
  {
    postalCode: '99428',
    city: 'Bechstedtstraß'
  },
  {
    postalCode: '99428',
    city: 'Daasdorf am Berge'
  },
  {
    postalCode: '99428',
    city: 'Gaberndorf'
  },
  {
    postalCode: '99428',
    city: 'Gelmeroda'
  },
  {
    postalCode: '99428',
    city: 'Hopfgarten'
  },
  {
    postalCode: '99428',
    city: 'Isseroda'
  },
  {
    postalCode: '99428',
    city: 'Niedergrunstedt'
  },
  {
    postalCode: '99428',
    city: 'Niederzimmern'
  },
  {
    postalCode: '99428',
    city: 'Nohra'
  },
  {
    postalCode: '99428',
    city: 'Ottstedt am Berge'
  },
  {
    postalCode: '99428',
    city: 'Tröbsdorf'
  },
  {
    postalCode: '99428',
    city: 'Utzberg'
  },
  {
    postalCode: '99438',
    city: 'Bad Berka'
  },
  {
    postalCode: '99438',
    city: 'Buchfart'
  },
  {
    postalCode: '99438',
    city: 'Gutendorf'
  },
  {
    postalCode: '99438',
    city: 'Hetschburg'
  },
  {
    postalCode: '99438',
    city: 'Legefeld'
  },
  {
    postalCode: '99438',
    city: 'Oettern'
  },
  {
    postalCode: '99438',
    city: 'Possendorf'
  },
  {
    postalCode: '99438',
    city: 'Tonndorf'
  },
  {
    postalCode: '99438',
    city: 'Troistedt'
  },
  {
    postalCode: '99438',
    city: 'Vollersroda'
  },
  {
    postalCode: '99439',
    city: 'Ballstedt'
  },
  {
    postalCode: '99439',
    city: 'Berlstedt'
  },
  {
    postalCode: '99439',
    city: 'Buttelstedt'
  },
  {
    postalCode: '99439',
    city: 'Daasdorf bei Buttelstedt'
  },
  {
    postalCode: '99439',
    city: 'Ettersburg'
  },
  {
    postalCode: '99439',
    city: 'Großobringen'
  },
  {
    postalCode: '99439',
    city: 'Heichelheim'
  },
  {
    postalCode: '99439',
    city: 'Hottelstedt'
  },
  {
    postalCode: '99439',
    city: 'Kleinobringen'
  },
  {
    postalCode: '99439',
    city: 'Krautheim'
  },
  {
    postalCode: '99439',
    city: 'Leutenthal'
  },
  {
    postalCode: '99439',
    city: 'Nermsdorf'
  },
  {
    postalCode: '99439',
    city: 'Neumark'
  },
  {
    postalCode: '99439',
    city: 'Ottmannshausen'
  },
  {
    postalCode: '99439',
    city: 'Ramsla'
  },
  {
    postalCode: '99439',
    city: 'Rohrbach'
  },
  {
    postalCode: '99439',
    city: 'Sachsenhausen'
  },
  {
    postalCode: '99439',
    city: 'Schwerstedt'
  },
  {
    postalCode: '99439',
    city: 'Stedten am Ettersberg'
  },
  {
    postalCode: '99439',
    city: 'Vippachedelhausen'
  },
  {
    postalCode: '99439',
    city: 'Weiden'
  },
  {
    postalCode: '99439',
    city: 'Wohlsborn'
  },
  {
    postalCode: '99441',
    city: 'Döbritschen'
  },
  {
    postalCode: '99441',
    city: 'Frankendorf'
  },
  {
    postalCode: '99441',
    city: 'Großschwabhausen'
  },
  {
    postalCode: '99441',
    city: 'Hammerstedt'
  },
  {
    postalCode: '99441',
    city: 'Hohlstedt'
  },
  {
    postalCode: '99441',
    city: 'Kiliansroda'
  },
  {
    postalCode: '99441',
    city: 'Kleinschwabhausen'
  },
  {
    postalCode: '99441',
    city: 'Kötschau'
  },
  {
    postalCode: '99441',
    city: 'Kromsdorf'
  },
  {
    postalCode: '99441',
    city: 'Lehnstedt'
  },
  {
    postalCode: '99441',
    city: 'Magdala'
  },
  {
    postalCode: '99441',
    city: 'Mechelroda'
  },
  {
    postalCode: '99441',
    city: 'Mellingen'
  },
  {
    postalCode: '99441',
    city: 'Süßenborn'
  },
  {
    postalCode: '99441',
    city: 'Umpferstedt'
  },
  {
    postalCode: '99444',
    city: 'Blankenhain'
  },
  {
    postalCode: '99448',
    city: 'Hohenfelden'
  },
  {
    postalCode: '99448',
    city: 'Kranichfeld'
  },
  {
    postalCode: '99448',
    city: 'Nauendorf'
  },
  {
    postalCode: '99448',
    city: 'Rittersdorf'
  },
  {
    postalCode: '99510',
    city: 'Apolda'
  },
  {
    postalCode: '99510',
    city: 'Eckolstädt'
  },
  {
    postalCode: '99510',
    city: 'Flurstedt'
  },
  {
    postalCode: '99510',
    city: 'Gebstedt'
  },
  {
    postalCode: '99510',
    city: 'Großromstedt'
  },
  {
    postalCode: '99510',
    city: 'Hermstedt'
  },
  {
    postalCode: '99510',
    city: 'Kapellendorf'
  },
  {
    postalCode: '99510',
    city: 'Kleinromstedt'
  },
  {
    postalCode: '99510',
    city: 'Kösnitz'
  },
  {
    postalCode: '99510',
    city: 'Liebstedt'
  },
  {
    postalCode: '99510',
    city: 'Mattstedt'
  },
  {
    postalCode: '99510',
    city: 'Münchengosserstädt'
  },
  {
    postalCode: '99510',
    city: 'Niederreißen'
  },
  {
    postalCode: '99510',
    city: 'Niederroßla'
  },
  {
    postalCode: '99510',
    city: 'Nirmsdorf'
  },
  {
    postalCode: '99510',
    city: 'Oberreißen'
  },
  {
    postalCode: '99510',
    city: 'Obertrebra'
  },
  {
    postalCode: '99510',
    city: 'Oßmannstedt'
  },
  {
    postalCode: '99510',
    city: 'Pfiffelbach'
  },
  {
    postalCode: '99510',
    city: 'Pfuhlsborn'
  },
  {
    postalCode: '99510',
    city: 'Stobra'
  },
  {
    postalCode: '99510',
    city: 'Wickerstedt'
  },
  {
    postalCode: '99510',
    city: 'Wiegendorf'
  },
  {
    postalCode: '99510',
    city: 'Willerstedt'
  },
  {
    postalCode: '99510',
    city: 'Wormstedt'
  },
  {
    postalCode: '99518',
    city: 'Auerstedt'
  },
  {
    postalCode: '99518',
    city: 'Bad Sulza'
  },
  {
    postalCode: '99518',
    city: 'Eberstedt'
  },
  {
    postalCode: '99518',
    city: 'Großheringen'
  },
  {
    postalCode: '99518',
    city: 'Ködderitzsch'
  },
  {
    postalCode: '99518',
    city: 'Lachstedt'
  },
  {
    postalCode: '99518',
    city: 'Niedertrebra'
  },
  {
    postalCode: '99518',
    city: 'Rannstedt'
  },
  {
    postalCode: '99518',
    city: 'Reisdorf'
  },
  {
    postalCode: '99518',
    city: 'Schmiedehausen'
  },
  {
    postalCode: '99610',
    city: 'Frohndorf'
  },
  {
    postalCode: '99610',
    city: 'Großbrembach'
  },
  {
    postalCode: '99610',
    city: 'Kleinbrembach'
  },
  {
    postalCode: '99610',
    city: 'Leubingen'
  },
  {
    postalCode: '99610',
    city: 'Orlishausen'
  },
  {
    postalCode: '99610',
    city: 'Rohrborn'
  },
  {
    postalCode: '99610',
    city: 'Schallenburg'
  },
  {
    postalCode: '99610',
    city: 'Sömmerda'
  },
  {
    postalCode: '99610',
    city: 'Sprötau'
  },
  {
    postalCode: '99610',
    city: 'Stödten'
  },
  {
    postalCode: '99610',
    city: 'Tunzenhausen'
  },
  {
    postalCode: '99610',
    city: 'Vogelsberg'
  },
  {
    postalCode: '99610',
    city: 'Weißenburg'
  },
  {
    postalCode: '99610',
    city: 'Wenigensömmern'
  },
  {
    postalCode: '99610',
    city: 'Wundersleben'
  },
  {
    postalCode: '99625',
    city: 'Battgendorf'
  },
  {
    postalCode: '99625',
    city: 'Beichlingen'
  },
  {
    postalCode: '99625',
    city: 'Dermsdorf'
  },
  {
    postalCode: '99625',
    city: 'Großmonra'
  },
  {
    postalCode: '99625',
    city: 'Großneuhausen'
  },
  {
    postalCode: '99625',
    city: 'Kleinneuhausen'
  },
  {
    postalCode: '99625',
    city: 'Kölleda'
  },
  {
    postalCode: '99625',
    city: 'Schillingstedt'
  },
  {
    postalCode: '99628',
    city: 'Buttstädt'
  },
  {
    postalCode: '99628',
    city: 'Ellersleben'
  },
  {
    postalCode: '99628',
    city: 'Eßleben'
  },
  {
    postalCode: '99628',
    city: 'Guthmannshausen'
  },
  {
    postalCode: '99628',
    city: 'Hardisleben'
  },
  {
    postalCode: '99628',
    city: 'Mannstedt'
  },
  {
    postalCode: '99628',
    city: 'Olbersleben'
  },
  {
    postalCode: '99628',
    city: 'Rudersdorf'
  },
  {
    postalCode: '99628',
    city: 'Teutleben'
  },
  {
    postalCode: '99631',
    city: 'Günstedt'
  },
  {
    postalCode: '99631',
    city: 'Herrnschwende'
  },
  {
    postalCode: '99631',
    city: 'Nausiß'
  },
  {
    postalCode: '99631',
    city: 'Weißensee'
  },
  {
    postalCode: '99634',
    city: 'Gangloffsömmern'
  },
  {
    postalCode: '99634',
    city: 'Henschleben'
  },
  {
    postalCode: '99634',
    city: 'Schwerstedt'
  },
  {
    postalCode: '99634',
    city: 'Straußfurt'
  },
  {
    postalCode: '99634',
    city: 'Werningshausen'
  },
  {
    postalCode: '99636',
    city: 'Ostramondra'
  },
  {
    postalCode: '99636',
    city: 'Rastenberg'
  },
  {
    postalCode: '99638',
    city: 'Büchel'
  },
  {
    postalCode: '99638',
    city: 'Frömmstedt'
  },
  {
    postalCode: '99638',
    city: 'Griefstedt'
  },
  {
    postalCode: '99638',
    city: 'Kindelbrück'
  },
  {
    postalCode: '99638',
    city: 'Riethgen'
  },
  {
    postalCode: '99706',
    city: 'Badra'
  },
  {
    postalCode: '99706',
    city: 'Bendeleben'
  },
  {
    postalCode: '99706',
    city: 'Hachelbich'
  },
  {
    postalCode: '99706',
    city: 'Sondershausen'
  },
  {
    postalCode: '99713',
    city: 'Abtsbessingen'
  },
  {
    postalCode: '99713',
    city: 'Allmenhausen'
  },
  {
    postalCode: '99713',
    city: 'Bellstedt'
  },
  {
    postalCode: '99713',
    city: 'Billeben'
  },
  {
    postalCode: '99713',
    city: 'Dietenborn'
  },
  {
    postalCode: '99713',
    city: 'Ebeleben'
  },
  {
    postalCode: '99713',
    city: 'Freienbessingen'
  },
  {
    postalCode: '99713',
    city: 'Friedrichsrode'
  },
  {
    postalCode: '99713',
    city: 'Großberndten'
  },
  {
    postalCode: '99713',
    city: 'Großbrüchter'
  },
  {
    postalCode: '99713',
    city: 'Gundersleben'
  },
  {
    postalCode: '99713',
    city: 'Himmelsberg'
  },
  {
    postalCode: '99713',
    city: 'Hohenebra'
  },
  {
    postalCode: '99713',
    city: 'Holzsußra'
  },
  {
    postalCode: '99713',
    city: 'Holzthaleben'
  },
  {
    postalCode: '99713',
    city: 'Immenrode'
  },
  {
    postalCode: '99713',
    city: 'Keula'
  },
  {
    postalCode: '99713',
    city: 'Kleinberndten'
  },
  {
    postalCode: '99713',
    city: 'Kleinbrüchter'
  },
  {
    postalCode: '99713',
    city: 'Niederspier'
  },
  {
    postalCode: '99713',
    city: 'Peukendorf'
  },
  {
    postalCode: '99713',
    city: 'Rockensußra'
  },
  {
    postalCode: '99713',
    city: 'Rockstedt'
  },
  {
    postalCode: '99713',
    city: 'Schernberg'
  },
  {
    postalCode: '99713',
    city: 'Straußberg'
  },
  {
    postalCode: '99713',
    city: 'Thalebra'
  },
  {
    postalCode: '99713',
    city: 'Thüringenhausen'
  },
  {
    postalCode: '99713',
    city: 'Toba'
  },
  {
    postalCode: '99713',
    city: 'Wenigenehrich'
  },
  {
    postalCode: '99713',
    city: 'Wiedermuth'
  },
  {
    postalCode: '99713',
    city: 'Wolferschwenda'
  },
  {
    postalCode: '99713',
    city: 'Zaunröden'
  },
  {
    postalCode: '99718',
    city: 'Bliederstedt'
  },
  {
    postalCode: '99718',
    city: 'Clingen'
  },
  {
    postalCode: '99718',
    city: 'Feldengel'
  },
  {
    postalCode: '99718',
    city: 'Greußen'
  },
  {
    postalCode: '99718',
    city: 'Großenehrich'
  },
  {
    postalCode: '99718',
    city: 'Holzengel'
  },
  {
    postalCode: '99718',
    city: 'Kirchengel'
  },
  {
    postalCode: '99718',
    city: 'Niederbösa'
  },
  {
    postalCode: '99718',
    city: 'Oberbösa'
  },
  {
    postalCode: '99718',
    city: 'Otterstedt'
  },
  {
    postalCode: '99718',
    city: 'Rohnstedt'
  },
  {
    postalCode: '99718',
    city: 'Topfstedt'
  },
  {
    postalCode: '99718',
    city: 'Trebra'
  },
  {
    postalCode: '99718',
    city: 'Wasserthaleben'
  },
  {
    postalCode: '99718',
    city: 'Westerengel'
  },
  {
    postalCode: '99718',
    city: 'Westgreußen'
  },
  {
    postalCode: '99734',
    city: 'Nordhausen'
  },
  {
    postalCode: '99734',
    city: 'Rüdigsdorf'
  },
  {
    postalCode: '99735',
    city: 'Bielen'
  },
  {
    postalCode: '99735',
    city: 'Etzelsrode'
  },
  {
    postalCode: '99735',
    city: 'Friedrichsthal'
  },
  {
    postalCode: '99735',
    city: 'Fronderode'
  },
  {
    postalCode: '99735',
    city: 'Großwechsungen'
  },
  {
    postalCode: '99735',
    city: 'Günzerode'
  },
  {
    postalCode: '99735',
    city: 'Haferungen'
  },
  {
    postalCode: '99735',
    city: 'Hainrode'
  },
  {
    postalCode: '99735',
    city: 'Herreden'
  },
  {
    postalCode: '99735',
    city: 'Hesserode'
  },
  {
    postalCode: '99735',
    city: 'Himmelgarten'
  },
  {
    postalCode: '99735',
    city: 'Hochstedt'
  },
  {
    postalCode: '99735',
    city: 'Hörningen'
  },
  {
    postalCode: '99735',
    city: 'Immenrode'
  },
  {
    postalCode: '99735',
    city: 'Kleinfurra'
  },
  {
    postalCode: '99735',
    city: 'Kleinwechsungen'
  },
  {
    postalCode: '99735',
    city: 'Leimbach'
  },
  {
    postalCode: '99735',
    city: 'Mauderode'
  },
  {
    postalCode: '99735',
    city: 'Mörbach'
  },
  {
    postalCode: '99735',
    city: 'Nohra'
  },
  {
    postalCode: '99735',
    city: 'Petersdorf'
  },
  {
    postalCode: '99735',
    city: 'Pützlingen'
  },
  {
    postalCode: '99735',
    city: 'Steinbrücken'
  },
  {
    postalCode: '99735',
    city: 'Sundhausen'
  },
  {
    postalCode: '99735',
    city: 'Wernrode'
  },
  {
    postalCode: '99735',
    city: 'Werther'
  },
  {
    postalCode: '99735',
    city: 'Wolkramshausen'
  },
  {
    postalCode: '99735',
    city: 'Wollersleben'
  },
  {
    postalCode: '99752',
    city: 'Bleicherode'
  },
  {
    postalCode: '99752',
    city: 'Kehmstedt'
  },
  {
    postalCode: '99752',
    city: 'Kleinbodungen'
  },
  {
    postalCode: '99752',
    city: 'Kraja'
  },
  {
    postalCode: '99752',
    city: 'Lipprechterode'
  },
  {
    postalCode: '99752',
    city: 'Wipperdorf'
  },
  {
    postalCode: '99755',
    city: 'Ellrich'
  },
  {
    postalCode: '99755',
    city: 'Gudersleben'
  },
  {
    postalCode: '99755',
    city: 'Hohenstein'
  },
  {
    postalCode: '99755',
    city: 'Sülzhayn'
  },
  {
    postalCode: '99759',
    city: 'Elende'
  },
  {
    postalCode: '99759',
    city: 'Großlohra'
  },
  {
    postalCode: '99759',
    city: 'Münchenlohra'
  },
  {
    postalCode: '99759',
    city: 'Niedergebra'
  },
  {
    postalCode: '99759',
    city: 'Obergebra'
  },
  {
    postalCode: '99759',
    city: 'Rehungen'
  },
  {
    postalCode: '99759',
    city: 'Sollstedt'
  },
  {
    postalCode: '99762',
    city: 'Buchholz'
  },
  {
    postalCode: '99762',
    city: 'Harzungen'
  },
  {
    postalCode: '99762',
    city: 'Herrmannsacker'
  },
  {
    postalCode: '99762',
    city: 'Neustadt'
  },
  {
    postalCode: '99762',
    city: 'Niedersachswerfen'
  },
  {
    postalCode: '99762',
    city: 'Rodishain'
  },
  {
    postalCode: '99762',
    city: 'Steigerthal'
  },
  {
    postalCode: '99762',
    city: 'Stempeda'
  },
  {
    postalCode: '99762',
    city: 'Woffleben'
  },
  {
    postalCode: '99765',
    city: 'Auleben'
  },
  {
    postalCode: '99765',
    city: 'Görsbach'
  },
  {
    postalCode: '99765',
    city: 'Hamma'
  },
  {
    postalCode: '99765',
    city: 'Heringen'
  },
  {
    postalCode: '99765',
    city: 'Urbach'
  },
  {
    postalCode: '99765',
    city: 'Uthleben'
  },
  {
    postalCode: '99765',
    city: 'Windehausen'
  },
  {
    postalCode: '99768',
    city: 'Appenrode'
  },
  {
    postalCode: '99768',
    city: 'Ilfeld'
  },
  {
    postalCode: '99768',
    city: 'Rothesütte'
  },
  {
    postalCode: '99817',
    city: 'Eisenach'
  },
  {
    postalCode: '99819',
    city: 'Beuernfeld'
  },
  {
    postalCode: '99819',
    city: 'Bolleroda'
  },
  {
    postalCode: '99819',
    city: 'Eckardtshausen'
  },
  {
    postalCode: '99819',
    city: 'Ettenhausen an der Nesse'
  },
  {
    postalCode: '99819',
    city: 'Ettenhausen an der Suhl'
  },
  {
    postalCode: '99819',
    city: 'Förtha'
  },
  {
    postalCode: '99819',
    city: 'Großenlupnitz'
  },
  {
    postalCode: '99819',
    city: 'Krauthausen'
  },
  {
    postalCode: '99819',
    city: 'Lauchröden'
  },
  {
    postalCode: '99819',
    city: 'Marksuhl'
  },
  {
    postalCode: '99819',
    city: 'Oberellen'
  },
  {
    postalCode: '99819',
    city: 'Unterellen'
  },
  {
    postalCode: '99819',
    city: 'Wenigenlupnitz'
  },
  {
    postalCode: '99819',
    city: 'Wolfsburg-Unkeroda'
  },
  {
    postalCode: '99826',
    city: 'Berka vor dem Hainich'
  },
  {
    postalCode: '99826',
    city: 'Bischofroda'
  },
  {
    postalCode: '99826',
    city: 'Ebenshausen'
  },
  {
    postalCode: '99826',
    city: 'Frankenroda'
  },
  {
    postalCode: '99826',
    city: 'Hallungen'
  },
  {
    postalCode: '99826',
    city: 'Lauterbach'
  },
  {
    postalCode: '99826',
    city: 'Mihla'
  },
  {
    postalCode: '99826',
    city: 'Nazza'
  },
  {
    postalCode: '99830',
    city: 'Falken'
  },
  {
    postalCode: '99830',
    city: 'Großburschla'
  },
  {
    postalCode: '99830',
    city: 'Treffurt'
  },
  {
    postalCode: '99831',
    city: 'Creuzburg'
  },
  {
    postalCode: '99831',
    city: 'Hattengehau'
  },
  {
    postalCode: '99831',
    city: 'Ifta'
  },
  {
    postalCode: '99831',
    city: 'Schnellmannshausen'
  },
  {
    postalCode: '99831',
    city: 'Schrapfendorf'
  },
  {
    postalCode: '99834',
    city: 'Gerstungen'
  },
  {
    postalCode: '99834',
    city: 'Neustädt'
  },
  {
    postalCode: '99834',
    city: 'Sallmannshausen'
  },
  {
    postalCode: '99837',
    city: 'Berka'
  },
  {
    postalCode: '99837',
    city: 'Dankmarshausen'
  },
  {
    postalCode: '99837',
    city: 'Dippach'
  },
  {
    postalCode: '99837',
    city: 'Großensee'
  },
  {
    postalCode: '99842',
    city: 'Ruhla'
  },
  {
    postalCode: '99843',
    city: 'Kittelsthal'
  },
  {
    postalCode: '99843',
    city: 'Thal'
  },
  {
    postalCode: '99846',
    city: 'Seebach'
  },
  {
    postalCode: '99848',
    city: 'Hastrungsfeld-Burla'
  },
  {
    postalCode: '99848',
    city: 'Kälberfeld'
  },
  {
    postalCode: '99848',
    city: 'Sättelstädt'
  },
  {
    postalCode: '99848',
    city: 'Wutha-Farnroda'
  },
  {
    postalCode: '99867',
    city: 'Gotha'
  },
  {
    postalCode: '99869',
    city: 'Ballstädt'
  },
  {
    postalCode: '99869',
    city: 'Brüheim'
  },
  {
    postalCode: '99869',
    city: 'Bufleben'
  },
  {
    postalCode: '99869',
    city: 'Ebenheim'
  },
  {
    postalCode: '99869',
    city: 'Emleben'
  },
  {
    postalCode: '99869',
    city: 'Eschenbergen'
  },
  {
    postalCode: '99869',
    city: 'Friedrichswerth'
  },
  {
    postalCode: '99869',
    city: 'Friemar'
  },
  {
    postalCode: '99869',
    city: 'Goldbach'
  },
  {
    postalCode: '99869',
    city: 'Grabsleben'
  },
  {
    postalCode: '99869',
    city: 'Günthersleben'
  },
  {
    postalCode: '99869',
    city: 'Haina'
  },
  {
    postalCode: '99869',
    city: 'Hochheim'
  },
  {
    postalCode: '99869',
    city: 'Molschleben'
  },
  {
    postalCode: '99869',
    city: 'Mühlberg'
  },
  {
    postalCode: '99869',
    city: 'Pferdingsleben'
  },
  {
    postalCode: '99869',
    city: 'Remstädt'
  },
  {
    postalCode: '99869',
    city: 'Schwabhausen'
  },
  {
    postalCode: '99869',
    city: 'Seebergen'
  },
  {
    postalCode: '99869',
    city: 'Sonneborn'
  },
  {
    postalCode: '99869',
    city: 'Tröchtelborn'
  },
  {
    postalCode: '99869',
    city: 'Tüttleben'
  },
  {
    postalCode: '99869',
    city: 'Wandersleben'
  },
  {
    postalCode: '99869',
    city: 'Wangenheim'
  },
  {
    postalCode: '99869',
    city: 'Warza'
  },
  {
    postalCode: '99869',
    city: 'Wechmar'
  },
  {
    postalCode: '99869',
    city: 'Weingarten'
  },
  {
    postalCode: '99869',
    city: 'Westhausen'
  },
  {
    postalCode: '99880',
    city: 'Aspach'
  },
  {
    postalCode: '99880',
    city: 'Fröttstädt'
  },
  {
    postalCode: '99880',
    city: 'Gospiteroda'
  },
  {
    postalCode: '99880',
    city: 'Hörselgau'
  },
  {
    postalCode: '99880',
    city: 'Langenhain'
  },
  {
    postalCode: '99880',
    city: 'Laucha'
  },
  {
    postalCode: '99880',
    city: 'Leina'
  },
  {
    postalCode: '99880',
    city: 'Mechterstädt'
  },
  {
    postalCode: '99880',
    city: 'Metebach'
  },
  {
    postalCode: '99880',
    city: 'Teutleben'
  },
  {
    postalCode: '99880',
    city: 'Trügleben'
  },
  {
    postalCode: '99880',
    city: 'Waltershausen'
  },
  {
    postalCode: '99885',
    city: 'Luisenthal'
  },
  {
    postalCode: '99885',
    city: 'Ohrdruf'
  },
  {
    postalCode: '99885',
    city: 'Wölfis'
  },
  {
    postalCode: '99887',
    city: 'Catterfeld'
  },
  {
    postalCode: '99887',
    city: 'Georgenthal'
  },
  {
    postalCode: '99887',
    city: 'Gräfenhain'
  },
  {
    postalCode: '99887',
    city: 'Herrenhof'
  },
  {
    postalCode: '99887',
    city: 'Hohenkirchen'
  },
  {
    postalCode: '99887',
    city: 'Petriroda'
  },
  {
    postalCode: '99891',
    city: 'Fischbach'
  },
  {
    postalCode: '99891',
    city: 'Schmerbach'
  },
  {
    postalCode: '99891',
    city: 'Schwarzhausen'
  },
  {
    postalCode: '99891',
    city: 'Tabarz'
  },
  {
    postalCode: '99891',
    city: 'Winterstein'
  },
  {
    postalCode: '99894',
    city: 'Ernstroda'
  },
  {
    postalCode: '99894',
    city: 'Friedrichroda'
  },
  {
    postalCode: '99894',
    city: 'Schönau von der Walde'
  },
  {
    postalCode: '99897',
    city: 'Tambach-Dietharz'
  },
  {
    postalCode: '99898',
    city: 'Engelsbach'
  },
  {
    postalCode: '99898',
    city: 'Finsterbergen'
  },
  {
    postalCode: '99947',
    city: 'Bad Langensalza'
  },
  {
    postalCode: '99947',
    city: 'Behringen'
  },
  {
    postalCode: '99947',
    city: 'Bothenheilingen'
  },
  {
    postalCode: '99947',
    city: 'Großwelsbach'
  },
  {
    postalCode: '99947',
    city: 'Harthhaus'
  },
  {
    postalCode: '99947',
    city: 'Issersheilingen'
  },
  {
    postalCode: '99947',
    city: 'Kirchheilingen'
  },
  {
    postalCode: '99947',
    city: 'Kleinwelsbach'
  },
  {
    postalCode: '99947',
    city: 'Merxleben'
  },
  {
    postalCode: '99947',
    city: 'Mülverstedt'
  },
  {
    postalCode: '99947',
    city: 'Neunheilingen'
  },
  {
    postalCode: '99947',
    city: 'Schönstedt'
  },
  {
    postalCode: '99947',
    city: 'Sundhausen'
  },
  {
    postalCode: '99947',
    city: 'Thamsbrück'
  },
  {
    postalCode: '99947',
    city: 'Tottleben'
  },
  {
    postalCode: '99947',
    city: 'Weberstedt'
  },
  {
    postalCode: '99947',
    city: 'Zimmern'
  },
  {
    postalCode: '99955',
    city: 'Bad Tennstedt'
  },
  {
    postalCode: '99955',
    city: 'Ballhausen'
  },
  {
    postalCode: '99955',
    city: 'Blankenburg'
  },
  {
    postalCode: '99955',
    city: 'Bruchstedt'
  },
  {
    postalCode: '99955',
    city: 'Haussömmern'
  },
  {
    postalCode: '99955',
    city: 'Herbsleben'
  },
  {
    postalCode: '99955',
    city: 'Hornsömmern'
  },
  {
    postalCode: '99955',
    city: 'Klettstedt'
  },
  {
    postalCode: '99955',
    city: 'Kutzleben'
  },
  {
    postalCode: '99955',
    city: 'Mittelsömmern'
  },
  {
    postalCode: '99955',
    city: 'Urleben'
  },
  {
    postalCode: '99958',
    city: 'Aschara'
  },
  {
    postalCode: '99958',
    city: 'Eckardtsleben'
  },
  {
    postalCode: '99958',
    city: 'Großvargula'
  },
  {
    postalCode: '99958',
    city: 'Illeben'
  },
  {
    postalCode: '99958',
    city: 'Nägelstedt'
  },
  {
    postalCode: '99958',
    city: 'Tonna'
  },
  {
    postalCode: '99974',
    city: 'Ammern'
  },
  {
    postalCode: '99974',
    city: 'Dachrieden'
  },
  {
    postalCode: '99974',
    city: 'Kaisershagen'
  },
  {
    postalCode: '99974',
    city: 'Mühlhausen'
  },
  {
    postalCode: '99974',
    city: 'Reiser'
  },
  {
    postalCode: '99976',
    city: 'Beberstedt'
  },
  {
    postalCode: '99976',
    city: 'Bickenriede'
  },
  {
    postalCode: '99976',
    city: 'Dörna'
  },
  {
    postalCode: '99976',
    city: 'Eigenrode'
  },
  {
    postalCode: '99976',
    city: 'Faulungen'
  },
  {
    postalCode: '99976',
    city: 'Hildebrandshausen'
  },
  {
    postalCode: '99976',
    city: 'Hollenbach'
  },
  {
    postalCode: '99976',
    city: 'Horsmar'
  },
  {
    postalCode: '99976',
    city: 'Hüpstedt'
  },
  {
    postalCode: '99976',
    city: 'Kleinkeula'
  },
  {
    postalCode: '99976',
    city: 'Lengefeld'
  },
  {
    postalCode: '99976',
    city: 'Lengenfeld unterm Stein'
  },
  {
    postalCode: '99976',
    city: 'Rodeberg'
  },
  {
    postalCode: '99976',
    city: 'Sollstedt'
  },
  {
    postalCode: '99976',
    city: 'Zella'
  },
  {
    postalCode: '99986',
    city: 'Flarchheim'
  },
  {
    postalCode: '99986',
    city: 'Kammerforst'
  },
  {
    postalCode: '99986',
    city: 'Langula'
  },
  {
    postalCode: '99986',
    city: 'Niederdorla'
  },
  {
    postalCode: '99986',
    city: 'Oberdorla'
  },
  {
    postalCode: '99986',
    city: 'Oppershausen'
  },
  {
    postalCode: '99988',
    city: 'Diedorf'
  },
  {
    postalCode: '99988',
    city: 'Heyerode'
  },
  {
    postalCode: '99988',
    city: 'Schierschwende'
  },
  {
    postalCode: '99988',
    city: 'Wendehausen'
  },
  {
    postalCode: '99991',
    city: 'Altengottern'
  },
  {
    postalCode: '99991',
    city: 'Großengottern'
  },
  {
    postalCode: '99991',
    city: 'Heroldishausen'
  },
  {
    postalCode: '99994',
    city: 'Hohenbergen'
  },
  {
    postalCode: '99994',
    city: 'Marolterode'
  },
  {
    postalCode: '99994',
    city: 'Mehrstedt'
  },
  {
    postalCode: '99994',
    city: 'Schlotheim'
  },
  {
    postalCode: '99996',
    city: 'Großmehlra'
  },
  {
    postalCode: '99996',
    city: 'Menteroda'
  },
  {
    postalCode: '99996',
    city: 'Obermehler'
  },
  {
    postalCode: '99996',
    city: 'Urbach'
  },
  {
    postalCode: '99998',
    city: 'Körner'
  },
  {
    postalCode: '99998',
    city: 'Weinbergen'
  },
  {
    postalCode: '1010',
    city: 'Wien'
  },
  {
    postalCode: '1020',
    city: 'Wien'
  },
  {
    postalCode: '1030',
    city: 'Wien'
  },
  {
    postalCode: '1040',
    city: 'Wien'
  },
  {
    postalCode: '1050',
    city: 'Wien'
  },
  {
    postalCode: '1060',
    city: 'Wien'
  },
  {
    postalCode: '1070',
    city: 'Wien'
  },
  {
    postalCode: '1080',
    city: 'Wien'
  },
  {
    postalCode: '1090',
    city: 'Wien'
  },
  {
    postalCode: '1100',
    city: 'Wien'
  },
  {
    postalCode: '1110',
    city: 'Wien'
  },
  {
    postalCode: '1120',
    city: 'Wien'
  },
  {
    postalCode: '1130',
    city: 'Wien'
  },
  {
    postalCode: '1140',
    city: 'Purkersdorf'
  },
  {
    postalCode: '1140',
    city: 'Wien'
  },
  {
    postalCode: '1150',
    city: 'Wien'
  },
  {
    postalCode: '1160',
    city: 'Wien'
  },
  {
    postalCode: '1170',
    city: 'Wien'
  },
  {
    postalCode: '1180',
    city: 'Wien'
  },
  {
    postalCode: '1190',
    city: 'Wien'
  },
  {
    postalCode: '1200',
    city: 'Wien'
  },
  {
    postalCode: '1210',
    city: 'Langenzersdorf'
  },
  {
    postalCode: '1210',
    city: 'Wien'
  },
  {
    postalCode: '1220',
    city: 'Wien'
  },
  {
    postalCode: '1230',
    city: 'Wien'
  },
  {
    postalCode: '1300',
    city: 'Mannswörth'
  },
  {
    postalCode: '2000',
    city: 'Zissersdorf'
  },
  {
    postalCode: '2000',
    city: 'Oberolberndorf'
  },
  {
    postalCode: '2000',
    city: 'Oberzögersdorf'
  },
  {
    postalCode: '2000',
    city: 'Stockerau'
  },
  {
    postalCode: '2000',
    city: 'Unterzögersdorf'
  },
  {
    postalCode: '2002',
    city: 'Füllersdorf'
  },
  {
    postalCode: '2002',
    city: 'Geitzendorf'
  },
  {
    postalCode: '2002',
    city: 'Großmugl'
  },
  {
    postalCode: '2002',
    city: 'Herzogbirbaum'
  },
  {
    postalCode: '2002',
    city: 'Nursch'
  },
  {
    postalCode: '2002',
    city: 'Ottendorf'
  },
  {
    postalCode: '2002',
    city: 'Ringendorf'
  },
  {
    postalCode: '2002',
    city: 'Roseldorf'
  },
  {
    postalCode: '2002',
    city: 'Steinabrunn'
  },
  {
    postalCode: '2003',
    city: 'Leitzersdorf'
  },
  {
    postalCode: '2003',
    city: 'Wollmannsberg'
  },
  {
    postalCode: '2003',
    city: 'Haselbach'
  },
  {
    postalCode: '2003',
    city: 'Stockerau'
  },
  {
    postalCode: '2003',
    city: 'Wiesen'
  },
  {
    postalCode: '2004',
    city: 'Bruderndorf'
  },
  {
    postalCode: '2004',
    city: 'Niederfellabrunn'
  },
  {
    postalCode: '2004',
    city: 'Niederhollabrunn'
  },
  {
    postalCode: '2004',
    city: 'Streitdorf'
  },
  {
    postalCode: '2011',
    city: 'Hatzenbach'
  },
  {
    postalCode: '2011',
    city: 'Höbersdorf'
  },
  {
    postalCode: '2011',
    city: 'Oberhautzental'
  },
  {
    postalCode: '2011',
    city: 'Obermallebarn'
  },
  {
    postalCode: '2011',
    city: 'Senning'
  },
  {
    postalCode: '2011',
    city: 'Sierndorf'
  },
  {
    postalCode: '2011',
    city: 'Unterhautzental'
  },
  {
    postalCode: '2011',
    city: 'Untermallebarn'
  },
  {
    postalCode: '2011',
    city: 'Unterparschenbrunn'
  },
  {
    postalCode: '2013',
    city: 'Bergau'
  },
  {
    postalCode: '2013',
    city: 'Eitzersthal'
  },
  {
    postalCode: '2013',
    city: 'Göllersdorf'
  },
  {
    postalCode: '2013',
    city: 'Oberparschenbrunn'
  },
  {
    postalCode: '2013',
    city: 'Schönborn'
  },
  {
    postalCode: '2013',
    city: 'Furth'
  },
  {
    postalCode: '2013',
    city: 'Großstelzendorf'
  },
  {
    postalCode: '2013',
    city: 'Obergrub'
  },
  {
    postalCode: '2013',
    city: 'Porrau'
  },
  {
    postalCode: '2013',
    city: 'Untergrub'
  },
  {
    postalCode: '2013',
    city: 'Viendorf'
  },
  {
    postalCode: '2013',
    city: 'Wischathal'
  },
  {
    postalCode: '2014',
    city: 'Breitenwaida'
  },
  {
    postalCode: '2014',
    city: 'Dietersdorf'
  },
  {
    postalCode: '2014',
    city: 'Kleedorf'
  },
  {
    postalCode: '2014',
    city: 'Puch'
  },
  {
    postalCode: '2020',
    city: 'Aspersdorf'
  },
  {
    postalCode: '2020',
    city: 'Mittergrabern'
  },
  {
    postalCode: '2020',
    city: 'Obergrabern'
  },
  {
    postalCode: '2020',
    city: 'Schöngrabern'
  },
  {
    postalCode: '2020',
    city: 'Windpassing'
  },
  {
    postalCode: '2020',
    city: 'Groß'
  },
  {
    postalCode: '2020',
    city: 'Kleinstetteldorf'
  },
  {
    postalCode: '2020',
    city: 'Hollabrunn'
  },
  {
    postalCode: '2020',
    city: 'Kleinstelzendorf'
  },
  {
    postalCode: '2020',
    city: 'Magersdorf'
  },
  {
    postalCode: '2020',
    city: 'Mariathal'
  },
  {
    postalCode: '2020',
    city: 'Oberfellabrunn'
  },
  {
    postalCode: '2020',
    city: 'Raschala'
  },
  {
    postalCode: '2020',
    city: 'Sonnberg'
  },
  {
    postalCode: '2020',
    city: 'Suttenbrunn'
  },
  {
    postalCode: '2020',
    city: 'Wieselsfeld'
  },
  {
    postalCode: '2020',
    city: 'Wolfsbrunn'
  },
  {
    postalCode: '2022',
    city: 'Immendorf'
  },
  {
    postalCode: '2022',
    city: 'Schalladorf'
  },
  {
    postalCode: '2023',
    city: 'Haslach'
  },
  {
    postalCode: '2023',
    city: 'Kleinweikersdorf'
  },
  {
    postalCode: '2023',
    city: 'Nappersdorf'
  },
  {
    postalCode: '2023',
    city: 'Oberstinkenbrunn'
  },
  {
    postalCode: '2024',
    city: 'Mailberg'
  },
  {
    postalCode: '2031',
    city: 'Altenmarkt im Thale'
  },
  {
    postalCode: '2031',
    city: 'Eggendorf im Thale'
  },
  {
    postalCode: '2031',
    city: 'Weyerburg'
  },
  {
    postalCode: '2032',
    city: 'Oedenkirchenwald'
  },
  {
    postalCode: '2032',
    city: 'Glaswein'
  },
  {
    postalCode: '2032',
    city: 'Enzersdorf im Thale'
  },
  {
    postalCode: '2032',
    city: 'Kleinkadolz'
  },
  {
    postalCode: '2033',
    city: 'Dürnleis'
  },
  {
    postalCode: '2033',
    city: 'Kammersdorf'
  },
  {
    postalCode: '2033',
    city: 'Kleinsierndorf'
  },
  {
    postalCode: '2034',
    city: 'Diepolz'
  },
  {
    postalCode: '2034',
    city: 'Großharras'
  },
  {
    postalCode: '2041',
    city: 'Aschendorf'
  },
  {
    postalCode: '2041',
    city: 'Hart'
  },
  {
    postalCode: '2041',
    city: 'Grund'
  },
  {
    postalCode: '2041',
    city: 'Hetzmannsdorf'
  },
  {
    postalCode: '2041',
    city: 'Maria Roggendorf'
  },
  {
    postalCode: '2041',
    city: 'Wullersdorf'
  },
  {
    postalCode: '2042',
    city: 'Ober-Steinabrunn'
  },
  {
    postalCode: '2042',
    city: 'Großnondorf'
  },
  {
    postalCode: '2042',
    city: 'Guntersdorf'
  },
  {
    postalCode: '2042',
    city: 'Grund'
  },
  {
    postalCode: '2042',
    city: 'Kalladorf'
  },
  {
    postalCode: '2051',
    city: 'Deinzendorf'
  },
  {
    postalCode: '2051',
    city: 'Dietmannsdorf'
  },
  {
    postalCode: '2051',
    city: 'Platt'
  },
  {
    postalCode: '2051',
    city: 'Watzelsdorf'
  },
  {
    postalCode: '2051',
    city: 'Zellerndorf'
  },
  {
    postalCode: '2052',
    city: 'Karlsdorf'
  },
  {
    postalCode: '2052',
    city: 'Pernersdorf'
  },
  {
    postalCode: '2052',
    city: 'Pfaffendorf'
  },
  {
    postalCode: '2053',
    city: 'Jetzelsdorf'
  },
  {
    postalCode: '2053',
    city: 'Peigarten'
  },
  {
    postalCode: '2053',
    city: 'Ragelsdorf'
  },
  {
    postalCode: '2054',
    city: 'Alberndorf im Pulkautal'
  },
  {
    postalCode: '2054',
    city: 'Auggenthal'
  },
  {
    postalCode: '2054',
    city: 'Haugsdorf'
  },
  {
    postalCode: '2054',
    city: 'Kleinhaugsdorf'
  },
  {
    postalCode: '2054',
    city: 'Unterretzbach'
  },
  {
    postalCode: '2061',
    city: 'Hadres'
  },
  {
    postalCode: '2061',
    city: 'Obritz'
  },
  {
    postalCode: '2061',
    city: 'Untermarkersdorf'
  },
  {
    postalCode: '2062',
    city: 'Großkadolz'
  },
  {
    postalCode: '2062',
    city: 'Seefeld'
  },
  {
    postalCode: '2063',
    city: 'Zwingendorf'
  },
  {
    postalCode: '2064',
    city: 'Wulzeshofen'
  },
  {
    postalCode: '2070',
    city: 'Obernalb'
  },
  {
    postalCode: '2070',
    city: 'Retz'
  },
  {
    postalCode: '2070',
    city: 'Unternalb'
  },
  {
    postalCode: '2070',
    city: 'Mitterretzbach'
  },
  {
    postalCode: '2070',
    city: 'Oberretzbach'
  },
  {
    postalCode: '2070',
    city: 'Unterretzbach'
  },
  {
    postalCode: '2073',
    city: 'Obermarkersdorf'
  },
  {
    postalCode: '2073',
    city: 'Schrattenthal'
  },
  {
    postalCode: '2073',
    city: 'Waitzendorf'
  },
  {
    postalCode: '2073',
    city: 'Pillersdorf'
  },
  {
    postalCode: '2074',
    city: 'Kleinhöflein'
  },
  {
    postalCode: '2074',
    city: 'Kleinriedenthal'
  },
  {
    postalCode: '2074',
    city: 'Unterretzbach'
  },
  {
    postalCode: '2081',
    city: 'Niederfladnitz'
  },
  {
    postalCode: '2081',
    city: 'Hofern'
  },
  {
    postalCode: '2081',
    city: 'Oberfladnitz'
  },
  {
    postalCode: '2082',
    city: 'Hardegg'
  },
  {
    postalCode: '2082',
    city: 'Merkersdorf'
  },
  {
    postalCode: '2083',
    city: 'Heufurth'
  },
  {
    postalCode: '2083',
    city: 'Pleißing'
  },
  {
    postalCode: '2083',
    city: 'Waschbach'
  },
  {
    postalCode: '2084',
    city: 'Fronsburg'
  },
  {
    postalCode: '2084',
    city: 'Obermixnitz'
  },
  {
    postalCode: '2084',
    city: 'Prutzendorf'
  },
  {
    postalCode: '2084',
    city: 'Rassingdorf'
  },
  {
    postalCode: '2084',
    city: 'Starrein'
  },
  {
    postalCode: '2084',
    city: 'Untermixnitz'
  },
  {
    postalCode: '2084',
    city: 'Weitersfeld'
  },
  {
    postalCode: '2091',
    city: 'Hessendorf'
  },
  {
    postalCode: '2091',
    city: 'Langau'
  },
  {
    postalCode: '2091',
    city: 'Oberhöflein'
  },
  {
    postalCode: '2092',
    city: 'Felling'
  },
  {
    postalCode: '2092',
    city: 'Mallersbach'
  },
  {
    postalCode: '2092',
    city: 'Riegersburg'
  },
  {
    postalCode: '2093',
    city: 'Fugnitz'
  },
  {
    postalCode: '2093',
    city: 'Geras'
  },
  {
    postalCode: '2093',
    city: 'Goggitsch'
  },
  {
    postalCode: '2093',
    city: 'Kottaun'
  },
  {
    postalCode: '2093',
    city: 'Pfaffenreith'
  },
  {
    postalCode: '2093',
    city: 'Trautmannsdorf'
  },
  {
    postalCode: '2094',
    city: 'Pingendorf'
  },
  {
    postalCode: '2094',
    city: 'Wollmersdorf'
  },
  {
    postalCode: '2094',
    city: 'Zettlitz'
  },
  {
    postalCode: '2094',
    city: 'Zissersdorf'
  },
  {
    postalCode: '2094',
    city: 'Eibenstein'
  },
  {
    postalCode: '2094',
    city: 'Unterpfaffendorf'
  },
  {
    postalCode: '2095',
    city: 'Autendorf'
  },
  {
    postalCode: '2095',
    city: 'Drosendorf Altstadt'
  },
  {
    postalCode: '2095',
    city: 'Drosendorf Stadt'
  },
  {
    postalCode: '2095',
    city: 'Elsern'
  },
  {
    postalCode: '2095',
    city: 'Heinrichsreith'
  },
  {
    postalCode: '2095',
    city: 'Oberthürnau'
  },
  {
    postalCode: '2095',
    city: 'Unterthürnau'
  },
  {
    postalCode: '2095',
    city: 'Wolfsbach'
  },
  {
    postalCode: '2095',
    city: 'Primmersdorf'
  },
  {
    postalCode: '2095',
    city: 'Trabersdorf'
  },
  {
    postalCode: '2100',
    city: 'Bisamberg'
  },
  {
    postalCode: '2100',
    city: 'Korneuburg'
  },
  {
    postalCode: '2100',
    city: 'Langenzersdorf'
  },
  {
    postalCode: '2100',
    city: 'Leobendorf'
  },
  {
    postalCode: '2100',
    city: 'Tresdorf'
  },
  {
    postalCode: '2100',
    city: 'Stetten'
  },
  {
    postalCode: '2102',
    city: 'Bisamberg'
  },
  {
    postalCode: '2102',
    city: 'Flandorf'
  },
  {
    postalCode: '2102',
    city: 'Hagenbrunn'
  },
  {
    postalCode: '2102',
    city: 'Klein-Engersdorf'
  },
  {
    postalCode: '2102',
    city: 'Langenzersdorf'
  },
  {
    postalCode: '2103',
    city: 'Langenzersdorf'
  },
  {
    postalCode: '2104',
    city: 'Spillern'
  },
  {
    postalCode: '2105',
    city: 'Kleinwilfersdorf'
  },
  {
    postalCode: '2105',
    city: 'Leobendorf'
  },
  {
    postalCode: '2105',
    city: 'Oberrohrbach'
  },
  {
    postalCode: '2105',
    city: 'Rückersdorf'
  },
  {
    postalCode: '2105',
    city: 'Unterrohrbach'
  },
  {
    postalCode: '2111',
    city: 'Harmannsdorf'
  },
  {
    postalCode: '2111',
    city: 'Kleinrötz'
  },
  {
    postalCode: '2111',
    city: 'Tresdorf'
  },
  {
    postalCode: '2111',
    city: 'Mollmannsdorf'
  },
  {
    postalCode: '2111',
    city: 'Obergänserndorf'
  },
  {
    postalCode: '2111',
    city: 'Rückersdorf'
  },
  {
    postalCode: '2111',
    city: 'Seebarn'
  },
  {
    postalCode: '2112',
    city: 'Hetzmannsdorf'
  },
  {
    postalCode: '2112',
    city: 'Lerchenau'
  },
  {
    postalCode: '2112',
    city: 'Würnitz'
  },
  {
    postalCode: '2112',
    city: 'Ritzendorf'
  },
  {
    postalCode: '2113',
    city: 'Lachsfeld'
  },
  {
    postalCode: '2113',
    city: 'Naglern'
  },
  {
    postalCode: '2113',
    city: 'Karnabrunn'
  },
  {
    postalCode: '2113',
    city: 'Wetzleinsdorf'
  },
  {
    postalCode: '2114',
    city: 'Großrußbach'
  },
  {
    postalCode: '2114',
    city: 'Hipples'
  },
  {
    postalCode: '2114',
    city: 'Kleinebersdorf'
  },
  {
    postalCode: '2114',
    city: 'Weinsteig'
  },
  {
    postalCode: '2114',
    city: 'Hornsburg'
  },
  {
    postalCode: '2115',
    city: 'Dörfles'
  },
  {
    postalCode: '2115',
    city: 'Ernstbrunn'
  },
  {
    postalCode: '2115',
    city: 'Gebmanns'
  },
  {
    postalCode: '2115',
    city: 'Merkersdorf'
  },
  {
    postalCode: '2115',
    city: 'Simonsfeld'
  },
  {
    postalCode: '2115',
    city: 'Steinbach'
  },
  {
    postalCode: '2115',
    city: 'Thomasl'
  },
  {
    postalCode: '2115',
    city: 'Maisbirbaum'
  },
  {
    postalCode: '2115',
    city: 'Helfens'
  },
  {
    postalCode: '2115',
    city: 'Kleinsitzendorf'
  },
  {
    postalCode: '2116',
    city: 'Au'
  },
  {
    postalCode: '2116',
    city: 'Klement'
  },
  {
    postalCode: '2116',
    city: 'Niederleis'
  },
  {
    postalCode: '2116',
    city: 'Nodendorf'
  },
  {
    postalCode: '2120',
    city: 'Obersdorf'
  },
  {
    postalCode: '2120',
    city: 'Wolkersdorf im Weinviertel'
  },
  {
    postalCode: '2122',
    city: 'Münichsthal'
  },
  {
    postalCode: '2122',
    city: 'Pfösing'
  },
  {
    postalCode: '2122',
    city: 'Riedenthal'
  },
  {
    postalCode: '2122',
    city: 'Ulrichskirchen'
  },
  {
    postalCode: '2123',
    city: 'Hautzendorf'
  },
  {
    postalCode: '2123',
    city: 'Kronberg'
  },
  {
    postalCode: '2123',
    city: 'Schleinbach'
  },
  {
    postalCode: '2123',
    city: 'Traunfeld'
  },
  {
    postalCode: '2123',
    city: 'Unterolberndorf'
  },
  {
    postalCode: '2123',
    city: 'Wolfpassing an der Hochleithen'
  },
  {
    postalCode: '2124',
    city: 'Niederkreuzstetten'
  },
  {
    postalCode: '2124',
    city: 'Oberkreuzstetten'
  },
  {
    postalCode: '2125',
    city: 'Bogenneusiedl'
  },
  {
    postalCode: '2125',
    city: 'Niederkreuzstetten'
  },
  {
    postalCode: '2125',
    city: 'Streifing'
  },
  {
    postalCode: '2125',
    city: 'Neubau'
  },
  {
    postalCode: '2126',
    city: 'Eggersdorf'
  },
  {
    postalCode: '2126',
    city: 'Garmanns'
  },
  {
    postalCode: '2126',
    city: 'Grafensulz'
  },
  {
    postalCode: '2126',
    city: 'Herrnleis'
  },
  {
    postalCode: '2126',
    city: 'Ladendorf'
  },
  {
    postalCode: '2126',
    city: 'Pürstendorf'
  },
  {
    postalCode: '2130',
    city: 'Eibesthal'
  },
  {
    postalCode: '2130',
    city: 'Hörersdorf'
  },
  {
    postalCode: '2130',
    city: 'Hüttendorf'
  },
  {
    postalCode: '2130',
    city: 'Ebendorf'
  },
  {
    postalCode: '2130',
    city: 'Lanzendorf'
  },
  {
    postalCode: '2130',
    city: 'Mistelbach'
  },
  {
    postalCode: '2130',
    city: 'Paasdorf'
  },
  {
    postalCode: '2130',
    city: 'Siebenhirten'
  },
  {
    postalCode: '2132',
    city: 'Frättingsdorf'
  },
  {
    postalCode: '2132',
    city: 'Hörersdorf'
  },
  {
    postalCode: '2133',
    city: 'Fallbach'
  },
  {
    postalCode: '2133',
    city: 'Friebritz'
  },
  {
    postalCode: '2133',
    city: 'Hagenberg'
  },
  {
    postalCode: '2133',
    city: 'Hagendorf'
  },
  {
    postalCode: '2133',
    city: 'Loosdorf'
  },
  {
    postalCode: '2133',
    city: 'Ungerndorf'
  },
  {
    postalCode: '2134',
    city: 'Enzersdorf bei Staatz'
  },
  {
    postalCode: '2134',
    city: 'Ernsdorf bei Staatz'
  },
  {
    postalCode: '2134',
    city: 'Kautendorf'
  },
  {
    postalCode: '2134',
    city: 'Staatz'
  },
  {
    postalCode: '2134',
    city: 'Waltersdorf bei Staatz'
  },
  {
    postalCode: '2134',
    city: 'Wultendorf'
  },
  {
    postalCode: '2135',
    city: 'Kottingneusiedl'
  },
  {
    postalCode: '2135',
    city: 'Kirchstetten'
  },
  {
    postalCode: '2135',
    city: 'Neudorf bei Staatz'
  },
  {
    postalCode: '2135',
    city: 'Zlabern'
  },
  {
    postalCode: '2135',
    city: 'Altruppersdorf'
  },
  {
    postalCode: '2136',
    city: 'Hanfthal'
  },
  {
    postalCode: '2136',
    city: 'Laa an der Thaya'
  },
  {
    postalCode: '2136',
    city: 'Ruhhof'
  },
  {
    postalCode: '2136',
    city: 'Wulzeshofen'
  },
  {
    postalCode: '2136',
    city: 'Rothenseehof'
  },
  {
    postalCode: '2141',
    city: 'Föllim'
  },
  {
    postalCode: '2141',
    city: 'Ameis'
  },
  {
    postalCode: '2143',
    city: 'Althöflein'
  },
  {
    postalCode: '2143',
    city: 'Ginzersdorf'
  },
  {
    postalCode: '2143',
    city: 'Großkrut'
  },
  {
    postalCode: '2143',
    city: 'Harrersdorf'
  },
  {
    postalCode: '2144',
    city: 'Altlichtenwarth'
  },
  {
    postalCode: '2145',
    city: 'Hausbrunn'
  },
  {
    postalCode: '2151',
    city: 'Altmanns'
  },
  {
    postalCode: '2151',
    city: 'Asparn an der Zaya'
  },
  {
    postalCode: '2151',
    city: 'Schletz'
  },
  {
    postalCode: '2151',
    city: 'Michelstetten'
  },
  {
    postalCode: '2151',
    city: 'Olgersdorf'
  },
  {
    postalCode: '2152',
    city: 'Eichenbrunn'
  },
  {
    postalCode: '2152',
    city: 'Gnadendorf'
  },
  {
    postalCode: '2152',
    city: 'Pyhra'
  },
  {
    postalCode: '2152',
    city: 'Röhrabrunn'
  },
  {
    postalCode: '2152',
    city: 'Wenzersdorf'
  },
  {
    postalCode: '2152',
    city: 'Zwentendorf'
  },
  {
    postalCode: '2153',
    city: 'Oberschoderlee'
  },
  {
    postalCode: '2153',
    city: 'Patzenthal'
  },
  {
    postalCode: '2153',
    city: 'Patzmannsdorf'
  },
  {
    postalCode: '2153',
    city: 'Stronegg'
  },
  {
    postalCode: '2153',
    city: 'Stronsdorf'
  },
  {
    postalCode: '2153',
    city: 'Unterschoderlee'
  },
  {
    postalCode: '2154',
    city: 'Altenmarkt'
  },
  {
    postalCode: '2154',
    city: 'Gaubitsch'
  },
  {
    postalCode: '2154',
    city: 'Kleinbaumgarten'
  },
  {
    postalCode: '2154',
    city: 'Unterstinkenbrunn'
  },
  {
    postalCode: '2161',
    city: 'Poysbrunn'
  },
  {
    postalCode: '2162',
    city: 'Falkenstein'
  },
  {
    postalCode: '2163',
    city: 'Guttenbrunn'
  },
  {
    postalCode: '2163',
    city: 'Ottenthal'
  },
  {
    postalCode: '2163',
    city: 'Pottenhofen'
  },
  {
    postalCode: '2164',
    city: 'Neuruppersdorf'
  },
  {
    postalCode: '2164',
    city: 'Alt-Prerau'
  },
  {
    postalCode: '2164',
    city: 'Wildendürnbach'
  },
  {
    postalCode: '2165',
    city: 'Drasenhofen'
  },
  {
    postalCode: '2165',
    city: 'Fünfkirchen'
  },
  {
    postalCode: '2165',
    city: 'Kleinschweinbarth'
  },
  {
    postalCode: '2165',
    city: 'Steinebrunn'
  },
  {
    postalCode: '2165',
    city: 'Stützenhofen'
  },
  {
    postalCode: '2170',
    city: 'Ketzelsdorf'
  },
  {
    postalCode: '2170',
    city: 'Kleinhadersdorf'
  },
  {
    postalCode: '2170',
    city: 'Poysdorf'
  },
  {
    postalCode: '2170',
    city: 'Walterskirchen'
  },
  {
    postalCode: '2170',
    city: 'Wetzelsdorf'
  },
  {
    postalCode: '2170',
    city: 'Wilhelmsdorf'
  },
  {
    postalCode: '2171',
    city: 'Herrnbaumgarten'
  },
  {
    postalCode: '2172',
    city: 'Schrattenberg'
  },
  {
    postalCode: '2181',
    city: 'Dobermannsdorf'
  },
  {
    postalCode: '2182',
    city: 'Palterndorf'
  },
  {
    postalCode: '2183',
    city: 'Neusiedl an der Zaya'
  },
  {
    postalCode: '2183',
    city: 'St. Ulrich'
  },
  {
    postalCode: '2184',
    city: 'Hauskirchen'
  },
  {
    postalCode: '2185',
    city: 'Prinzendorf an der Zaya'
  },
  {
    postalCode: '2185',
    city: 'Rannersdorf an der Zaya'
  },
  {
    postalCode: '2185',
    city: 'Ebersdorf an der Zaya'
  },
  {
    postalCode: '2191',
    city: 'Atzelsdorf'
  },
  {
    postalCode: '2191',
    city: 'Gaweinstal'
  },
  {
    postalCode: '2191',
    city: 'Höbersbrunn'
  },
  {
    postalCode: '2191',
    city: 'Pellendorf'
  },
  {
    postalCode: '2191',
    city: 'Schrick'
  },
  {
    postalCode: '2192',
    city: 'Kettlasbrunn'
  },
  {
    postalCode: '2193',
    city: 'Erdberg'
  },
  {
    postalCode: '2193',
    city: 'Bullendorf'
  },
  {
    postalCode: '2193',
    city: 'Hobersdorf'
  },
  {
    postalCode: '2193',
    city: 'Wilfersdorf'
  },
  {
    postalCode: '2201',
    city: 'Gerasdorf'
  },
  {
    postalCode: '2201',
    city: 'Hagenbrunn'
  },
  {
    postalCode: '2201',
    city: 'Seyring'
  },
  {
    postalCode: '2202',
    city: 'Enzersfeld im Weinviertel'
  },
  {
    postalCode: '2202',
    city: 'Königsbrunn im Weinviertel'
  },
  {
    postalCode: '2202',
    city: 'Flandorf'
  },
  {
    postalCode: '2202',
    city: 'Hagenbrunn'
  },
  {
    postalCode: '2203',
    city: 'Eibesbrunn'
  },
  {
    postalCode: '2203',
    city: 'Großebersdorf'
  },
  {
    postalCode: '2203',
    city: 'Manhartsbrunn'
  },
  {
    postalCode: '2203',
    city: 'Putzing'
  },
  {
    postalCode: '2211',
    city: 'Pillichsdorf'
  },
  {
    postalCode: '2212',
    city: 'Großengersdorf'
  },
  {
    postalCode: '2213',
    city: 'Bockfließ'
  },
  {
    postalCode: '2214',
    city: 'Auersthal'
  },
  {
    postalCode: '2215',
    city: 'Raggendorf'
  },
  {
    postalCode: '2221',
    city: 'Groß-Schweinbarth'
  },
  {
    postalCode: '2222',
    city: 'Bad Pirawarth'
  },
  {
    postalCode: '2222',
    city: 'Kollnbrunn'
  },
  {
    postalCode: '2223',
    city: 'Martinsdorf'
  },
  {
    postalCode: '2223',
    city: 'Hohenruppersdorf'
  },
  {
    postalCode: '2223',
    city: 'Klein-Harras'
  },
  {
    postalCode: '2224',
    city: 'Erdpreß'
  },
  {
    postalCode: '2224',
    city: 'Nexing'
  },
  {
    postalCode: '2224',
    city: 'Niedersulz'
  },
  {
    postalCode: '2224',
    city: 'Obersulz'
  },
  {
    postalCode: '2225',
    city: 'Blumenthal'
  },
  {
    postalCode: '2225',
    city: 'Gaiselberg'
  },
  {
    postalCode: '2225',
    city: 'Gösting'
  },
  {
    postalCode: '2225',
    city: 'Großinzersdorf'
  },
  {
    postalCode: '2225',
    city: 'Loidesthal'
  },
  {
    postalCode: '2225',
    city: 'Maustrenk'
  },
  {
    postalCode: '2225',
    city: 'Windisch Baumgarten'
  },
  {
    postalCode: '2225',
    city: 'Eichhorn'
  },
  {
    postalCode: '2225',
    city: 'Zistersdorf'
  },
  {
    postalCode: '2230',
    city: 'Gänserndorf'
  },
  {
    postalCode: '2231',
    city: 'Bockfließ'
  },
  {
    postalCode: '2231',
    city: 'Strasshof an der Nordbahn'
  },
  {
    postalCode: '2232',
    city: 'Aderklaa'
  },
  {
    postalCode: '2232',
    city: 'Deutsch-Wagram'
  },
  {
    postalCode: '2232',
    city: 'Parbasdorf'
  },
  {
    postalCode: '2241',
    city: 'Reyersdorf'
  },
  {
    postalCode: '2241',
    city: 'Schönkirchen-Reyersdorf'
  },
  {
    postalCode: '2242',
    city: 'Prottes'
  },
  {
    postalCode: '2243',
    city: 'Matzen'
  },
  {
    postalCode: '2244',
    city: 'Spannberg'
  },
  {
    postalCode: '2245',
    city: 'Götzendorf'
  },
  {
    postalCode: '2245',
    city: 'Velm'
  },
  {
    postalCode: '2251',
    city: 'Ebenthal'
  },
  {
    postalCode: '2252',
    city: 'Ollersdorf'
  },
  {
    postalCode: '2253',
    city: 'Dörfles'
  },
  {
    postalCode: '2253',
    city: 'Stripfing'
  },
  {
    postalCode: '2253',
    city: 'Tallesbrunn'
  },
  {
    postalCode: '2253',
    city: 'Weikendorf'
  },
  {
    postalCode: '2261',
    city: 'Angern an der March'
  },
  {
    postalCode: '2261',
    city: 'Mannersdorf an der March'
  },
  {
    postalCode: '2261',
    city: 'Zwerndorf'
  },
  {
    postalCode: '2262',
    city: 'Grub an der March'
  },
  {
    postalCode: '2262',
    city: 'Stillfried'
  },
  {
    postalCode: '2263',
    city: 'Dürnkrut'
  },
  {
    postalCode: '2263',
    city: 'Waidendorf'
  },
  {
    postalCode: '2264',
    city: 'Jedenspeigen'
  },
  {
    postalCode: '2264',
    city: 'Sierndorf an der March'
  },
  {
    postalCode: '2265',
    city: 'Drösing'
  },
  {
    postalCode: '2265',
    city: 'Waltersdorf an der March'
  },
  {
    postalCode: '2272',
    city: 'Niederabsdorf'
  },
  {
    postalCode: '2272',
    city: 'Ringelsdorf'
  },
  {
    postalCode: '2273',
    city: 'Hohenau an der March'
  },
  {
    postalCode: '2274',
    city: 'Rabensburg'
  },
  {
    postalCode: '2275',
    city: 'Bernhardsthal'
  },
  {
    postalCode: '2276',
    city: 'Katzelsdorf'
  },
  {
    postalCode: '2276',
    city: 'Reintal'
  },
  {
    postalCode: '2281',
    city: 'Pysdorf'
  },
  {
    postalCode: '2281',
    city: 'Raasdorf'
  },
  {
    postalCode: '2282',
    city: 'Glinzendorf'
  },
  {
    postalCode: '2282',
    city: 'Großhofen'
  },
  {
    postalCode: '2282',
    city: 'Markgrafneusiedl'
  },
  {
    postalCode: '2283',
    city: 'Obersiebenbrunn'
  },
  {
    postalCode: '2284',
    city: 'Untersiebenbrunn'
  },
  {
    postalCode: '2285',
    city: 'Breitstetten'
  },
  {
    postalCode: '2285',
    city: 'Leopoldsdorf im Marchfelde'
  },
  {
    postalCode: '2286',
    city: 'Fuchsenbigl'
  },
  {
    postalCode: '2286',
    city: 'Haringsee'
  },
  {
    postalCode: '2286',
    city: 'Straudorf'
  },
  {
    postalCode: '2291',
    city: 'Lassee'
  },
  {
    postalCode: '2291',
    city: 'Schönfeld im Marchfeld'
  },
  {
    postalCode: '2292',
    city: 'Engelhartstetten'
  },
  {
    postalCode: '2292',
    city: 'Loimersdorf'
  },
  {
    postalCode: '2292',
    city: 'Stopfenreuth'
  },
  {
    postalCode: '2293',
    city: 'Marchegg'
  },
  {
    postalCode: '2294',
    city: 'Groißenbrunn'
  },
  {
    postalCode: '2294',
    city: 'Markthof'
  },
  {
    postalCode: '2294',
    city: 'Schloßhof'
  },
  {
    postalCode: '2294',
    city: 'Breitensee'
  },
  {
    postalCode: '2294',
    city: 'Marchegg'
  },
  {
    postalCode: '2295',
    city: 'Baumgarten an der March'
  },
  {
    postalCode: '2295',
    city: 'Oberweiden'
  },
  {
    postalCode: '2301',
    city: 'Andlersdorf'
  },
  {
    postalCode: '2301',
    city: 'Franzensdorf'
  },
  {
    postalCode: '2301',
    city: 'Groß-Enzersdorf'
  },
  {
    postalCode: '2301',
    city: 'Oberhausen'
  },
  {
    postalCode: '2301',
    city: 'Rutzendorf'
  },
  {
    postalCode: '2301',
    city: 'Mühlleiten'
  },
  {
    postalCode: '2301',
    city: 'Matzneusiedl'
  },
  {
    postalCode: '2301',
    city: 'Probstdorf'
  },
  {
    postalCode: '2301',
    city: 'Schönau an der Donau'
  },
  {
    postalCode: '2301',
    city: 'Wittau'
  },
  {
    postalCode: '2304',
    city: 'Wagram an der Donau'
  },
  {
    postalCode: '2304',
    city: 'Mannsdorf an der Donau'
  },
  {
    postalCode: '2304',
    city: 'Orth an der Donau'
  },
  {
    postalCode: '2305',
    city: 'Eckartsau'
  },
  {
    postalCode: '2305',
    city: 'Kopfstetten'
  },
  {
    postalCode: '2305',
    city: 'Pframa'
  },
  {
    postalCode: '2305',
    city: 'Witzelsdorf'
  },
  {
    postalCode: '2320',
    city: 'Mannswörth'
  },
  {
    postalCode: '2320',
    city: 'Rauchenwarth'
  },
  {
    postalCode: '2320',
    city: 'Kledering'
  },
  {
    postalCode: '2320',
    city: 'Rannersdorf'
  },
  {
    postalCode: '2320',
    city: 'Schwechat'
  },
  {
    postalCode: '2322',
    city: 'Zwölfaxing'
  },
  {
    postalCode: '2325',
    city: 'Gutenhof'
  },
  {
    postalCode: '2325',
    city: 'Himberg'
  },
  {
    postalCode: '2325',
    city: 'Velm'
  },
  {
    postalCode: '2325',
    city: 'Pellendorf'
  },
  {
    postalCode: '2326',
    city: 'Lanzendorf'
  },
  {
    postalCode: '2326',
    city: 'Maria-Lanzendorf'
  },
  {
    postalCode: '2331',
    city: 'Vösendorf'
  },
  {
    postalCode: '2332',
    city: 'Hennersdorf'
  },
  {
    postalCode: '2333',
    city: 'Leopoldsdorf'
  },
  {
    postalCode: '2334',
    city: 'Vösendorf'
  },
  {
    postalCode: '2334',
    city: 'Wiener Neudorf'
  },
  {
    postalCode: '2340',
    city: 'Maria Enzersdorf'
  },
  {
    postalCode: '2340',
    city: 'Mödling'
  },
  {
    postalCode: '2344',
    city: 'Maria Enzersdorf'
  },
  {
    postalCode: '2345',
    city: 'Brunn am Gebirge'
  },
  {
    postalCode: '2351',
    city: 'Biedermannsdorf'
  },
  {
    postalCode: '2351',
    city: 'Guntramsdorf'
  },
  {
    postalCode: '2351',
    city: 'Laxenburg'
  },
  {
    postalCode: '2351',
    city: 'Wiener Neudorf'
  },
  {
    postalCode: '2352',
    city: 'Gumpoldskirchen'
  },
  {
    postalCode: '2353',
    city: 'Gumpoldskirchen'
  },
  {
    postalCode: '2353',
    city: 'Guntramsdorf'
  },
  {
    postalCode: '2361',
    city: 'Laxenburg'
  },
  {
    postalCode: '2362',
    city: 'Biedermannsdorf'
  },
  {
    postalCode: '2371',
    city: 'Hinterbrühl'
  },
  {
    postalCode: '2371',
    city: 'Wassergspreng'
  },
  {
    postalCode: '2371',
    city: 'Weissenbach bei Mödling'
  },
  {
    postalCode: '2372',
    city: 'Gießhübl'
  },
  {
    postalCode: '2380',
    city: 'Perchtoldsdorf'
  },
  {
    postalCode: '2381',
    city: 'Laab im Walde'
  },
  {
    postalCode: '2381',
    city: 'Wolfsgraben'
  },
  {
    postalCode: '2384',
    city: 'Breitenfurt bei Wien'
  },
  {
    postalCode: '2391',
    city: 'Kaltenleutgeben'
  },
  {
    postalCode: '2392',
    city: 'Dornbach'
  },
  {
    postalCode: '2392',
    city: 'Grub'
  },
  {
    postalCode: '2392',
    city: 'Stangau'
  },
  {
    postalCode: '2392',
    city: 'Sulz im Wienerwald'
  },
  {
    postalCode: '2392',
    city: 'Gruberau'
  },
  {
    postalCode: '2392',
    city: 'Wöglerin'
  },
  {
    postalCode: '2393',
    city: 'Sparbach'
  },
  {
    postalCode: '2393',
    city: 'Sittendorf'
  },
  {
    postalCode: '2401',
    city: 'Fischamend-Dorf'
  },
  {
    postalCode: '2401',
    city: 'Fischamend-Markt'
  },
  {
    postalCode: '2402',
    city: 'Haslau an der Donau'
  },
  {
    postalCode: '2402',
    city: 'Maria Ellend'
  },
  {
    postalCode: '2403',
    city: 'Regelsbrunn'
  },
  {
    postalCode: '2403',
    city: 'Scharndorf'
  },
  {
    postalCode: '2403',
    city: 'Wildungsmauer'
  },
  {
    postalCode: '2404',
    city: 'Petronell-Carnuntum'
  },
  {
    postalCode: '2405',
    city: 'Bad Deutsch-Altenburg'
  },
  {
    postalCode: '2405',
    city: 'Hundsheim'
  },
  {
    postalCode: '2410',
    city: 'Hainburg an der Donau'
  },
  {
    postalCode: '2412',
    city: 'Wolfsthal'
  },
  {
    postalCode: '2413',
    city: 'Berg'
  },
  {
    postalCode: '2413',
    city: 'Edelstal'
  },
  {
    postalCode: '2421',
    city: 'Kittsee'
  },
  {
    postalCode: '2422',
    city: 'Pama '
  },
  {
    postalCode: '2423',
    city: 'Deutsch Jahrndorf'
  },
  {
    postalCode: '2424',
    city: 'Zurndorf'
  },
  {
    postalCode: '2425',
    city: 'Nickelsdorf'
  },
  {
    postalCode: '2431',
    city: 'Enzersdorf an der Fischa'
  },
  {
    postalCode: '2431',
    city: 'Klein-Neusiedl'
  },
  {
    postalCode: '2432',
    city: 'Schwadorf'
  },
  {
    postalCode: '2433',
    city: 'Margarethen am Moos'
  },
  {
    postalCode: '2434',
    city: 'Götzendorf an der Leitha'
  },
  {
    postalCode: '2434',
    city: 'Mannersdorf am Leithagebirge'
  },
  {
    postalCode: '2434',
    city: 'Pischelsdorf'
  },
  {
    postalCode: '2434',
    city: 'Sommerein'
  },
  {
    postalCode: '2435',
    city: 'Ebergassing'
  },
  {
    postalCode: '2435',
    city: 'Wienerherberg'
  },
  {
    postalCode: '2435',
    city: 'Götzendorf an der Leitha'
  },
  {
    postalCode: '2435',
    city: 'Pischelsdorf'
  },
  {
    postalCode: '2440',
    city: 'Gramatneusiedl'
  },
  {
    postalCode: '2440',
    city: 'Mitterndorf an der Fischa'
  },
  {
    postalCode: '2440',
    city: 'Moosbrunn'
  },
  {
    postalCode: '2440',
    city: 'Reisenberg'
  },
  {
    postalCode: '2441',
    city: 'Gramatneusiedl'
  },
  {
    postalCode: '2441',
    city: 'Mitterndorf an der Fischa'
  },
  {
    postalCode: '2441',
    city: 'Moosbrunn'
  },
  {
    postalCode: '2442',
    city: 'Schranawand'
  },
  {
    postalCode: '2442',
    city: 'Unterwaltersdorf'
  },
  {
    postalCode: '2443',
    city: 'Leithaprodersdorf'
  },
  {
    postalCode: '2443',
    city: 'Loretto'
  },
  {
    postalCode: '2443',
    city: 'Deutsch-Brodersdorf'
  },
  {
    postalCode: '2443',
    city: 'Stotzing'
  },
  {
    postalCode: '2444',
    city: 'Seibersdorf'
  },
  {
    postalCode: '2451',
    city: 'Au am Leithaberge'
  },
  {
    postalCode: '2451',
    city: 'Hof am Leithaberge'
  },
  {
    postalCode: '2452',
    city: 'Mannersdorf am Leithagebirge'
  },
  {
    postalCode: '2452',
    city: 'Reisenberg'
  },
  {
    postalCode: '2453',
    city: 'Sommerein'
  },
  {
    postalCode: '2454',
    city: 'Sarasdorf'
  },
  {
    postalCode: '2454',
    city: 'Trautmannsdorf an der Leitha'
  },
  {
    postalCode: '2460',
    city: 'Bruck an der Leitha'
  },
  {
    postalCode: '2460',
    city: 'Wilfleinsdorf'
  },
  {
    postalCode: '2460',
    city: 'Bruckneudorf'
  },
  {
    postalCode: '2462',
    city: 'Wilfleinsdorf'
  },
  {
    postalCode: '2462',
    city: 'Kaisersteinbruch'
  },
  {
    postalCode: '2462',
    city: 'Königshof'
  },
  {
    postalCode: '2463',
    city: 'Gallbrunn'
  },
  {
    postalCode: '2463',
    city: 'Stixneusiedl'
  },
  {
    postalCode: '2464',
    city: 'Arbesthal'
  },
  {
    postalCode: '2464',
    city: 'Göttlesbrunn'
  },
  {
    postalCode: '2465',
    city: 'Höflein'
  },
  {
    postalCode: '2471',
    city: 'Gerhaus'
  },
  {
    postalCode: '2471',
    city: 'Hollern'
  },
  {
    postalCode: '2471',
    city: 'Pachfurth'
  },
  {
    postalCode: '2471',
    city: 'Parndorf '
  },
  {
    postalCode: '2471',
    city: 'Schönabrunn'
  },
  {
    postalCode: '2471',
    city: 'Rohrau'
  },
  {
    postalCode: '2472',
    city: 'Prellenkirchen'
  },
  {
    postalCode: '2473',
    city: 'Potzneusiedl'
  },
  {
    postalCode: '2473',
    city: 'Deutsch-Haslau'
  },
  {
    postalCode: '2474',
    city: 'Gattendorf'
  },
  {
    postalCode: '2475',
    city: 'Neudorf '
  },
  {
    postalCode: '2481',
    city: 'Achau'
  },
  {
    postalCode: '2482',
    city: 'Münchendorf'
  },
  {
    postalCode: '2483',
    city: 'Ebreichsdorf'
  },
  {
    postalCode: '2483',
    city: 'Weigelsdorf'
  },
  {
    postalCode: '2485',
    city: 'Wampersdorf'
  },
  {
    postalCode: '2485',
    city: 'Wimpassing an der Leitha'
  },
  {
    postalCode: '2486',
    city: 'Landegg'
  },
  {
    postalCode: '2486',
    city: 'Pottendorf'
  },
  {
    postalCode: '2486',
    city: 'Siegersdorf'
  },
  {
    postalCode: '2490',
    city: 'Ebenfurth'
  },
  {
    postalCode: '2490',
    city: 'Haschendorf'
  },
  {
    postalCode: '2491',
    city: 'Hornstein '
  },
  {
    postalCode: '2491',
    city: 'Neufeld an der Leitha'
  },
  {
    postalCode: '2491',
    city: 'Steinbrunn '
  },
  {
    postalCode: '2491',
    city: 'Zillingdorf-Bergwerk'
  },
  {
    postalCode: '2492',
    city: 'Eggendorf'
  },
  {
    postalCode: '2492',
    city: 'Zillingdorf'
  },
  {
    postalCode: '2493',
    city: 'Eggendorf'
  },
  {
    postalCode: '2493',
    city: 'Lichtenwörth'
  },
  {
    postalCode: '2500',
    city: 'Schwechatbach'
  },
  {
    postalCode: '2500',
    city: 'Baden'
  },
  {
    postalCode: '2500',
    city: 'Siegenfeld'
  },
  {
    postalCode: '2500',
    city: 'Oberwaltersdorf'
  },
  {
    postalCode: '2500',
    city: 'Sooß'
  },
  {
    postalCode: '2511',
    city: 'Einöde'
  },
  {
    postalCode: '2511',
    city: 'Pfaffstätten'
  },
  {
    postalCode: '2512',
    city: 'Oeynhausen'
  },
  {
    postalCode: '2512',
    city: 'Tribuswinkel'
  },
  {
    postalCode: '2514',
    city: 'Möllersdorf'
  },
  {
    postalCode: '2514',
    city: 'Traiskirchen'
  },
  {
    postalCode: '2514',
    city: 'Wienersdorf'
  },
  {
    postalCode: '2521',
    city: 'Trumau'
  },
  {
    postalCode: '2522',
    city: 'Oberwaltersdorf'
  },
  {
    postalCode: '2523',
    city: 'Tattendorf'
  },
  {
    postalCode: '2524',
    city: 'Teesdorf'
  },
  {
    postalCode: '2525',
    city: 'Günselsdorf'
  },
  {
    postalCode: '2525',
    city: 'Schönau an der Triesting'
  },
  {
    postalCode: '2531',
    city: 'Gaaden'
  },
  {
    postalCode: '2532',
    city: 'Schwechatbach'
  },
  {
    postalCode: '2532',
    city: 'Füllenberg'
  },
  {
    postalCode: '2532',
    city: 'Heiligenkreuz'
  },
  {
    postalCode: '2532',
    city: 'Preinsfeld'
  },
  {
    postalCode: '2532',
    city: 'Sattelbach'
  },
  {
    postalCode: '2533',
    city: 'Glashütten'
  },
  {
    postalCode: '2533',
    city: 'Klausen-Leopoldsdorf'
  },
  {
    postalCode: '2533',
    city: 'Kleinmariazellerforst'
  },
  {
    postalCode: '2533',
    city: 'Pfalzau'
  },
  {
    postalCode: '2534',
    city: 'Alland'
  },
  {
    postalCode: '2534',
    city: 'Glashütten'
  },
  {
    postalCode: '2534',
    city: 'Groisbach'
  },
  {
    postalCode: '2534',
    city: 'Mayerling'
  },
  {
    postalCode: '2534',
    city: 'Windhaag'
  },
  {
    postalCode: '2534',
    city: 'Holzschlag'
  },
  {
    postalCode: '2534',
    city: 'Maria Raisenmarkt'
  },
  {
    postalCode: '2534',
    city: 'Schwechatbach'
  },
  {
    postalCode: '2534',
    city: 'Untermeierhof'
  },
  {
    postalCode: '2534',
    city: 'Sattelbach'
  },
  {
    postalCode: '2540',
    city: 'Bad Vöslau'
  },
  {
    postalCode: '2540',
    city: 'Gainfarn'
  },
  {
    postalCode: '2540',
    city: 'Großau'
  },
  {
    postalCode: '2540',
    city: 'Siegenfeld'
  },
  {
    postalCode: '2540',
    city: 'Sooß'
  },
  {
    postalCode: '2542',
    city: 'Kottingbrunn'
  },
  {
    postalCode: '2542',
    city: 'Leobersdorf'
  },
  {
    postalCode: '2544',
    city: 'Enzesfeld-Lindabrunn'
  },
  {
    postalCode: '2544',
    city: 'Kottingbrunn'
  },
  {
    postalCode: '2544',
    city: 'Leobersdorf'
  },
  {
    postalCode: '2544',
    city: 'Dornau'
  },
  {
    postalCode: '2544',
    city: 'Siebenhaus'
  },
  {
    postalCode: '2551',
    city: 'Enzesfeld-Lindabrunn'
  },
  {
    postalCode: '2552',
    city: 'Enzesfeld-Lindabrunn'
  },
  {
    postalCode: '2552',
    city: 'Hirtenberg'
  },
  {
    postalCode: '2560',
    city: 'Berndorf'
  },
  {
    postalCode: '2560',
    city: 'Aigen'
  },
  {
    postalCode: '2560',
    city: 'Alkersdorf'
  },
  {
    postalCode: '2560',
    city: 'Grillenberg'
  },
  {
    postalCode: '2560',
    city: 'Hernstein'
  },
  {
    postalCode: '2560',
    city: 'Kleinfeld'
  },
  {
    postalCode: '2560',
    city: 'Neusiedl'
  },
  {
    postalCode: '2560',
    city: 'Pöllau'
  },
  {
    postalCode: '2563',
    city: 'Grabenweg'
  },
  {
    postalCode: '2563',
    city: 'Pottenstein'
  },
  {
    postalCode: '2564',
    city: 'Sulzbach'
  },
  {
    postalCode: '2564',
    city: 'Dürntal'
  },
  {
    postalCode: '2564',
    city: 'Furth'
  },
  {
    postalCode: '2564',
    city: 'Fahrafeld'
  },
  {
    postalCode: '2564',
    city: 'Pottenstein'
  },
  {
    postalCode: '2564',
    city: 'Weissenbach an der Triesting'
  },
  {
    postalCode: '2565',
    city: 'Holzschlag'
  },
  {
    postalCode: '2565',
    city: 'Maria Raisenmarkt'
  },
  {
    postalCode: '2565',
    city: 'Rohrbach'
  },
  {
    postalCode: '2565',
    city: 'Schwechatbach'
  },
  {
    postalCode: '2565',
    city: 'Nöstach'
  },
  {
    postalCode: '2565',
    city: 'Gainfarn'
  },
  {
    postalCode: '2565',
    city: 'Gadenweith'
  },
  {
    postalCode: '2565',
    city: 'Kienberg'
  },
  {
    postalCode: '2565',
    city: 'Neuhaus'
  },
  {
    postalCode: '2565',
    city: 'Schwarzensee'
  },
  {
    postalCode: '2571',
    city: 'Altenmarkt an der Triesting'
  },
  {
    postalCode: '2571',
    city: 'Sulzbach'
  },
  {
    postalCode: '2571',
    city: 'Klein-Mariazell'
  },
  {
    postalCode: '2571',
    city: 'Nöstach'
  },
  {
    postalCode: '2571',
    city: 'Thenneberg'
  },
  {
    postalCode: '2572',
    city: 'St. Corona am Schöpfl'
  },
  {
    postalCode: '2572',
    city: 'Gerichtsberg'
  },
  {
    postalCode: '2572',
    city: 'Höfnergraben'
  },
  {
    postalCode: '2572',
    city: 'Kaumberg'
  },
  {
    postalCode: '2572',
    city: 'Laabach'
  },
  {
    postalCode: '2572',
    city: 'Obertriesting'
  },
  {
    postalCode: '2572',
    city: 'Steinbachtal'
  },
  {
    postalCode: '2572',
    city: 'Untertriesting'
  },
  {
    postalCode: '2601',
    city: 'Ebenfurth'
  },
  {
    postalCode: '2601',
    city: 'Großmittel'
  },
  {
    postalCode: '2601',
    city: 'Eggendorf'
  },
  {
    postalCode: '2601',
    city: 'Sollenau'
  },
  {
    postalCode: '2602',
    city: 'Blumau'
  },
  {
    postalCode: '2602',
    city: 'Schönau an der Triesting'
  },
  {
    postalCode: '2602',
    city: 'Sollenau'
  },
  {
    postalCode: '2603',
    city: 'Haschendorf'
  },
  {
    postalCode: '2603',
    city: 'Felixdorf'
  },
  {
    postalCode: '2603',
    city: 'Matzendorf'
  },
  {
    postalCode: '2604',
    city: 'Eggendorf'
  },
  {
    postalCode: '2604',
    city: 'Theresienfeld'
  },
  {
    postalCode: '2620',
    city: 'Ober-Danegg'
  },
  {
    postalCode: '2620',
    city: 'Loipersbach'
  },
  {
    postalCode: '2620',
    city: 'Natschbach'
  },
  {
    postalCode: '2620',
    city: 'Lindgrub'
  },
  {
    postalCode: '2620',
    city: 'Mollram'
  },
  {
    postalCode: '2620',
    city: 'Neunkirchen'
  },
  {
    postalCode: '2620',
    city: 'Peisching'
  },
  {
    postalCode: '2620',
    city: 'Schwarzau am Steinfeld'
  },
  {
    postalCode: '2620',
    city: 'Raglitz'
  },
  {
    postalCode: '2620',
    city: 'Flatz'
  },
  {
    postalCode: '2620',
    city: 'Reith'
  },
  {
    postalCode: '2620',
    city: 'St. Lorenzen am Steinfelde'
  },
  {
    postalCode: '2620',
    city: 'Ternitz'
  },
  {
    postalCode: '2620',
    city: 'Diepolz'
  },
  {
    postalCode: '2620',
    city: 'Gramatl'
  },
  {
    postalCode: '2620',
    city: 'Hafning'
  },
  {
    postalCode: '2620',
    city: 'Ramplach'
  },
  {
    postalCode: '2620',
    city: 'Straßhof'
  },
  {
    postalCode: '2620',
    city: 'Unter-Danegg'
  },
  {
    postalCode: '2620',
    city: 'Wartmannstetten'
  },
  {
    postalCode: '2620',
    city: 'Weibnitz'
  },
  {
    postalCode: '2624',
    city: 'Breitenau'
  },
  {
    postalCode: '2624',
    city: 'Neusiedl am Steinfeld'
  },
  {
    postalCode: '2624',
    city: 'Schwarzau am Steinfeld'
  },
  {
    postalCode: '2625',
    city: 'Guntrams'
  },
  {
    postalCode: '2625',
    city: 'Schwarzau am Steinfeld'
  },
  {
    postalCode: '2630',
    city: 'Buchbach'
  },
  {
    postalCode: '2630',
    city: 'Liesling'
  },
  {
    postalCode: '2630',
    city: 'Bürg'
  },
  {
    postalCode: '2630',
    city: 'Vöstenhof'
  },
  {
    postalCode: '2630',
    city: 'Grafenbach'
  },
  {
    postalCode: '2630',
    city: 'Gasteil'
  },
  {
    postalCode: '2630',
    city: 'Holzweg'
  },
  {
    postalCode: '2630',
    city: 'Pottschach'
  },
  {
    postalCode: '2630',
    city: 'Putzmannsdorf'
  },
  {
    postalCode: '2630',
    city: 'Ternitz'
  },
  {
    postalCode: '2631',
    city: 'Gadenweith'
  },
  {
    postalCode: '2631',
    city: 'Sieding'
  },
  {
    postalCode: '2631',
    city: 'Stixenstein'
  },
  {
    postalCode: '2631',
    city: 'Thann'
  },
  {
    postalCode: '2632',
    city: 'Altendorf'
  },
  {
    postalCode: '2632',
    city: 'Grafenbach'
  },
  {
    postalCode: '2632',
    city: 'Göttschach'
  },
  {
    postalCode: '2632',
    city: 'Ober-Danegg'
  },
  {
    postalCode: '2632',
    city: 'Penk'
  },
  {
    postalCode: '2632',
    city: 'Köttlach'
  },
  {
    postalCode: '2632',
    city: 'St. Valentin-Landschach'
  },
  {
    postalCode: '2632',
    city: 'Putzmannsdorf'
  },
  {
    postalCode: '2632',
    city: 'Wimpassing im Schwarzatale'
  },
  {
    postalCode: '2640',
    city: 'Loitzmannsdorf'
  },
  {
    postalCode: '2640',
    city: 'Schönstadl'
  },
  {
    postalCode: '2640',
    city: 'Tachenberg'
  },
  {
    postalCode: '2640',
    city: 'Syhrn'
  },
  {
    postalCode: '2640',
    city: 'Liesling'
  },
  {
    postalCode: '2640',
    city: 'Enzenreith'
  },
  {
    postalCode: '2640',
    city: 'Hilzmannsdorf'
  },
  {
    postalCode: '2640',
    city: 'Thürmannsdorf'
  },
  {
    postalCode: '2640',
    city: 'Abfaltersbach'
  },
  {
    postalCode: '2640',
    city: 'Berglach'
  },
  {
    postalCode: '2640',
    city: 'Eichberg'
  },
  {
    postalCode: '2640',
    city: 'Gloggnitz'
  },
  {
    postalCode: '2640',
    city: 'Graben'
  },
  {
    postalCode: '2640',
    city: 'Heufeld'
  },
  {
    postalCode: '2640',
    city: 'Saloder'
  },
  {
    postalCode: '2640',
    city: 'Stuppach'
  },
  {
    postalCode: '2640',
    city: 'Weißenbach'
  },
  {
    postalCode: '2640',
    city: 'Hart'
  },
  {
    postalCode: '2640',
    city: 'Kranichberg'
  },
  {
    postalCode: '2640',
    city: 'Köttlach'
  },
  {
    postalCode: '2640',
    city: 'Pettenbach'
  },
  {
    postalCode: '2640',
    city: 'Schmidsdorf'
  },
  {
    postalCode: '2640',
    city: 'Gasteil'
  },
  {
    postalCode: '2640',
    city: 'Prigglitz'
  },
  {
    postalCode: '2640',
    city: 'Stuppachgraben'
  },
  {
    postalCode: '2640',
    city: 'Raach am Hochgebirge'
  },
  {
    postalCode: '2640',
    city: 'Schlagl'
  },
  {
    postalCode: '2640',
    city: 'Sonnleiten'
  },
  {
    postalCode: '2640',
    city: 'Wartenstein'
  },
  {
    postalCode: '2640',
    city: 'Wörth'
  },
  {
    postalCode: '2641',
    city: 'Breitenstein'
  },
  {
    postalCode: '2641',
    city: 'Aue'
  },
  {
    postalCode: '2641',
    city: 'Eichberg'
  },
  {
    postalCode: '2641',
    city: 'Göstritz'
  },
  {
    postalCode: '2641',
    city: 'Greis'
  },
  {
    postalCode: '2641',
    city: 'Schottwien'
  },
  {
    postalCode: '2642',
    city: 'Göstritz'
  },
  {
    postalCode: '2650',
    city: 'Geyerhof'
  },
  {
    postalCode: '2650',
    city: 'Kreuzberg'
  },
  {
    postalCode: '2650',
    city: 'Küb'
  },
  {
    postalCode: '2650',
    city: 'Mühlhof'
  },
  {
    postalCode: '2650',
    city: 'Payerbach'
  },
  {
    postalCode: '2650',
    city: 'Werning'
  },
  {
    postalCode: '2651',
    city: 'Dörfl'
  },
  {
    postalCode: '2651',
    city: 'Edlach an der Rax'
  },
  {
    postalCode: '2651',
    city: 'Großau'
  },
  {
    postalCode: '2651',
    city: 'Grünsting'
  },
  {
    postalCode: '2651',
    city: 'Hinterleiten'
  },
  {
    postalCode: '2651',
    city: 'Hirschwang an der Rax'
  },
  {
    postalCode: '2651',
    city: 'Kleinau'
  },
  {
    postalCode: '2651',
    city: 'Mayerhöfen'
  },
  {
    postalCode: '2651',
    city: 'Reichenau an der Rax'
  },
  {
    postalCode: '2651',
    city: 'Oberland'
  },
  {
    postalCode: '2651',
    city: 'Thonberg'
  },
  {
    postalCode: '2654',
    city: 'Raxen'
  },
  {
    postalCode: '2654',
    city: 'Griesleiten'
  },
  {
    postalCode: '2654',
    city: 'Prein an der Rax'
  },
  {
    postalCode: '2654',
    city: 'Preinrotte'
  },
  {
    postalCode: '2654',
    city: 'Sonnleiten'
  },
  {
    postalCode: '2661',
    city: 'Graben'
  },
  {
    postalCode: '2662',
    city: 'Gegend'
  },
  {
    postalCode: '2662',
    city: 'Graben'
  },
  {
    postalCode: '2662',
    city: 'Preintal'
  },
  {
    postalCode: '2662',
    city: 'Schwarzau im Gebirge'
  },
  {
    postalCode: '2662',
    city: 'Steinbruch'
  },
  {
    postalCode: '2662',
    city: 'Trauch'
  },
  {
    postalCode: '2662',
    city: 'Vois'
  },
  {
    postalCode: '2663',
    city: 'Innerhalbach'
  },
  {
    postalCode: '2663',
    city: 'Rohr im Gebirge'
  },
  {
    postalCode: '2671',
    city: 'Abfaltersbach'
  },
  {
    postalCode: '2671',
    city: 'Küb'
  },
  {
    postalCode: '2671',
    city: 'Pettenbach'
  },
  {
    postalCode: '2671',
    city: 'Schmidsdorf'
  },
  {
    postalCode: '2673',
    city: 'Breitenstein'
  },
  {
    postalCode: '2673',
    city: 'Kreuzberg'
  },
  {
    postalCode: '2680',
    city: 'Semmering-Kurort'
  },
  {
    postalCode: '2680',
    city: 'Spital am Semmering'
  },
  {
    postalCode: '2680',
    city: 'Steinhaus am Semmering'
  },
  {
    postalCode: '2700',
    city: 'Bad Fischau'
  },
  {
    postalCode: '2700',
    city: 'Katzelsdorf'
  },
  {
    postalCode: '2700',
    city: 'Kleinwolkersdorf'
  },
  {
    postalCode: '2700',
    city: 'Lichtenwörth'
  },
  {
    postalCode: '2700',
    city: 'Weikersdorf am Steinfelde'
  },
  {
    postalCode: '2700',
    city: 'Wiener Neustadt'
  },
  {
    postalCode: '2721',
    city: 'Bad Fischau'
  },
  {
    postalCode: '2721',
    city: 'Brunn an der Schneebergbahn'
  },
  {
    postalCode: '2721',
    city: 'Dreistetten'
  },
  {
    postalCode: '2722',
    city: 'Netting'
  },
  {
    postalCode: '2722',
    city: 'Weikersdorf am Steinfelde'
  },
  {
    postalCode: '2722',
    city: 'Emmerberg'
  },
  {
    postalCode: '2722',
    city: 'Winzendorf'
  },
  {
    postalCode: '2723',
    city: 'Gaaden'
  },
  {
    postalCode: '2723',
    city: 'Stollhof'
  },
  {
    postalCode: '2723',
    city: 'Emmerberg'
  },
  {
    postalCode: '2723',
    city: 'Muthmannsdorf'
  },
  {
    postalCode: '2724',
    city: 'Oberhöflein'
  },
  {
    postalCode: '2724',
    city: 'Gaaden'
  },
  {
    postalCode: '2724',
    city: 'Maiersdorf'
  },
  {
    postalCode: '2724',
    city: 'Stollhof'
  },
  {
    postalCode: '2724',
    city: 'Dreistetten'
  },
  {
    postalCode: '2724',
    city: 'Dürnbach'
  },
  {
    postalCode: '2731',
    city: 'Gerasdorf am Steinfeld'
  },
  {
    postalCode: '2731',
    city: 'Neusiedl am Steinfeld'
  },
  {
    postalCode: '2731',
    city: 'Saubersdorf'
  },
  {
    postalCode: '2731',
    city: 'St. Egyden am Steinfeld'
  },
  {
    postalCode: '2731',
    city: 'Urschendorf'
  },
  {
    postalCode: '2731',
    city: 'Dörfles'
  },
  {
    postalCode: '2732',
    city: 'Hettmannsdorf'
  },
  {
    postalCode: '2732',
    city: 'Oberhöflein'
  },
  {
    postalCode: '2732',
    city: 'Unterhöflein'
  },
  {
    postalCode: '2732',
    city: 'Zweiersdorf'
  },
  {
    postalCode: '2732',
    city: 'Raglitz'
  },
  {
    postalCode: '2732',
    city: 'Rothengrub'
  },
  {
    postalCode: '2732',
    city: 'Strelzhof'
  },
  {
    postalCode: '2732',
    city: 'Willendorf'
  },
  {
    postalCode: '2732',
    city: 'Wolfsohl'
  },
  {
    postalCode: '2732',
    city: 'Würflach'
  },
  {
    postalCode: '2733',
    city: 'Grünbach am Schneeberg'
  },
  {
    postalCode: '2733',
    city: 'Neusiedl am Walde'
  },
  {
    postalCode: '2733',
    city: 'Oberhöflein'
  },
  {
    postalCode: '2733',
    city: 'Unterhöflein'
  },
  {
    postalCode: '2733',
    city: 'Puchberg am Schneeberg'
  },
  {
    postalCode: '2733',
    city: 'Greith'
  },
  {
    postalCode: '2733',
    city: 'Gutenmann'
  },
  {
    postalCode: '2733',
    city: 'Hornungstal'
  },
  {
    postalCode: '2733',
    city: 'Rosental'
  },
  {
    postalCode: '2733',
    city: 'Schrattenbach'
  },
  {
    postalCode: '2734',
    city: 'Puchberg am Schneeberg'
  },
  {
    postalCode: '2734',
    city: 'Ödenhof'
  },
  {
    postalCode: '2734',
    city: 'Gutenmann'
  },
  {
    postalCode: '2751',
    city: 'Hölles'
  },
  {
    postalCode: '2751',
    city: 'Matzendorf'
  },
  {
    postalCode: '2751',
    city: 'Wiener Neustadt'
  },
  {
    postalCode: '2751',
    city: 'Steinabrückl'
  },
  {
    postalCode: '2751',
    city: 'Wöllersdorf'
  },
  {
    postalCode: '2752',
    city: 'Bad Fischau'
  },
  {
    postalCode: '2752',
    city: 'Wiener Neustadt'
  },
  {
    postalCode: '2752',
    city: 'Wöllersdorf'
  },
  {
    postalCode: '2753',
    city: 'Dreistetten'
  },
  {
    postalCode: '2753',
    city: 'Markt Piesting'
  },
  {
    postalCode: '2753',
    city: 'Ober-Piesting'
  },
  {
    postalCode: '2753',
    city: 'Wopfing'
  },
  {
    postalCode: '2754',
    city: 'Dürnbach'
  },
  {
    postalCode: '2754',
    city: 'Peisching'
  },
  {
    postalCode: '2754',
    city: 'Waldegg'
  },
  {
    postalCode: '2754',
    city: 'Wopfing'
  },
  {
    postalCode: '2755',
    city: 'Oed'
  },
  {
    postalCode: '2761',
    city: 'Miesenbach'
  },
  {
    postalCode: '2761',
    city: 'Feichtenbach'
  },
  {
    postalCode: '2761',
    city: 'Puchberg am Schneeberg'
  },
  {
    postalCode: '2761',
    city: 'Waidmannsfeld'
  },
  {
    postalCode: '2761',
    city: 'Reichental'
  },
  {
    postalCode: '2763',
    city: 'Vorderbruck'
  },
  {
    postalCode: '2763',
    city: 'Kreuth'
  },
  {
    postalCode: '2763',
    city: 'Muggendorf'
  },
  {
    postalCode: '2763',
    city: 'Thal'
  },
  {
    postalCode: '2763',
    city: 'Feichtenbach'
  },
  {
    postalCode: '2763',
    city: 'Pernitz'
  },
  {
    postalCode: '2763',
    city: 'Neusiedl'
  },
  {
    postalCode: '2763',
    city: 'Schallhof'
  },
  {
    postalCode: '2770',
    city: 'Gutenstein'
  },
  {
    postalCode: '2770',
    city: 'Hintergschaid'
  },
  {
    postalCode: '2770',
    city: 'Klostertal'
  },
  {
    postalCode: '2770',
    city: 'Längapiesting'
  },
  {
    postalCode: '2770',
    city: 'Steinapiesting'
  },
  {
    postalCode: '2770',
    city: 'Urgersbach'
  },
  {
    postalCode: '2770',
    city: 'Vorderbruck'
  },
  {
    postalCode: '2770',
    city: 'Zellenbach'
  },
  {
    postalCode: '2801',
    city: 'Eichbüchl'
  },
  {
    postalCode: '2801',
    city: 'Katzelsdorf'
  },
  {
    postalCode: '2802',
    city: 'Schlatten'
  },
  {
    postalCode: '2802',
    city: 'Hackbichl'
  },
  {
    postalCode: '2802',
    city: 'Hochwolkersdorf (Dorf)'
  },
  {
    postalCode: '2802',
    city: 'Hochwolkersdorf (Zerstreut)'
  },
  {
    postalCode: '2802',
    city: 'Rosenbrunn'
  },
  {
    postalCode: '2802',
    city: 'Schwarzenbach (Zerstreut)'
  },
  {
    postalCode: '2802',
    city: 'Schleinz'
  },
  {
    postalCode: '2803',
    city: 'Schwarzenbach'
  },
  {
    postalCode: '2803',
    city: 'Schwarzenbach (Zerstreut)'
  },
  {
    postalCode: '2803',
    city: 'Wiesmath'
  },
  {
    postalCode: '2811',
    city: 'Schlatten'
  },
  {
    postalCode: '2811',
    city: 'Wiesmath'
  },
  {
    postalCode: '2812',
    city: 'Blumau'
  },
  {
    postalCode: '2812',
    city: 'Gleichenbach'
  },
  {
    postalCode: '2812',
    city: 'Grohdorf'
  },
  {
    postalCode: '2812',
    city: 'Hollenthon'
  },
  {
    postalCode: '2812',
    city: 'Horndorf'
  },
  {
    postalCode: '2812',
    city: 'Lehen'
  },
  {
    postalCode: '2812',
    city: 'Mittereck'
  },
  {
    postalCode: '2812',
    city: 'Obereck'
  },
  {
    postalCode: '2812',
    city: 'Spratzau'
  },
  {
    postalCode: '2812',
    city: 'Spratzeck'
  },
  {
    postalCode: '2812',
    city: 'Stickelberg'
  },
  {
    postalCode: '2812',
    city: 'Untereck'
  },
  {
    postalCode: '2812',
    city: 'Stang'
  },
  {
    postalCode: '2812',
    city: 'Winkl'
  },
  {
    postalCode: '2812',
    city: 'Landsee'
  },
  {
    postalCode: '2813',
    city: 'Schlag'
  },
  {
    postalCode: '2813',
    city: 'Pürahöfen'
  },
  {
    postalCode: '2813',
    city: 'Amlos'
  },
  {
    postalCode: '2813',
    city: 'Feichten'
  },
  {
    postalCode: '2813',
    city: 'Kaltenberg'
  },
  {
    postalCode: '2813',
    city: 'Kühbach'
  },
  {
    postalCode: '2813',
    city: 'Lichtenegg'
  },
  {
    postalCode: '2813',
    city: 'Spratzau'
  },
  {
    postalCode: '2813',
    city: 'Maierhöfen'
  },
  {
    postalCode: '2813',
    city: 'Pengersdorf'
  },
  {
    postalCode: '2813',
    city: 'Pesendorf'
  },
  {
    postalCode: '2813',
    city: 'Ransdorf'
  },
  {
    postalCode: '2813',
    city: 'Thal'
  },
  {
    postalCode: '2813',
    city: 'Wieden'
  },
  {
    postalCode: '2813',
    city: 'Purgstall'
  },
  {
    postalCode: '2813',
    city: 'Tafern'
  },
  {
    postalCode: '2813',
    city: 'Wäschau'
  },
  {
    postalCode: '2813',
    city: 'Pregart'
  },
  {
    postalCode: '2813',
    city: 'Schlagergraben'
  },
  {
    postalCode: '2813',
    city: 'Thernberg'
  },
  {
    postalCode: '2813',
    city: 'Sauerbichl'
  },
  {
    postalCode: '2821',
    city: 'Frohsdorf'
  },
  {
    postalCode: '2821',
    city: 'Haderswörth'
  },
  {
    postalCode: '2821',
    city: 'Kleinwolkersdorf'
  },
  {
    postalCode: '2821',
    city: 'Lanzenkirchen'
  },
  {
    postalCode: '2821',
    city: 'Ofenbach'
  },
  {
    postalCode: '2821',
    city: 'Schleinz'
  },
  {
    postalCode: '2821',
    city: 'Walpersbach'
  },
  {
    postalCode: '2822',
    city: 'Brunn bei Pitten'
  },
  {
    postalCode: '2822',
    city: 'Bad Erlach'
  },
  {
    postalCode: '2822',
    city: 'Linsberg'
  },
  {
    postalCode: '2822',
    city: 'Schlatten'
  },
  {
    postalCode: '2822',
    city: 'Haderswörth'
  },
  {
    postalCode: '2822',
    city: 'Inzenhof'
  },
  {
    postalCode: '2822',
    city: 'Föhrenau'
  },
  {
    postalCode: '2822',
    city: 'Schwarzau am Steinfeld'
  },
  {
    postalCode: '2822',
    city: 'Klingfurth'
  },
  {
    postalCode: '2822',
    city: 'Walpersbach'
  },
  {
    postalCode: '2823',
    city: 'Brunn bei Pitten'
  },
  {
    postalCode: '2823',
    city: 'Inzenhof'
  },
  {
    postalCode: '2823',
    city: 'Leiding'
  },
  {
    postalCode: '2823',
    city: 'Pitten'
  },
  {
    postalCode: '2823',
    city: 'Sautern'
  },
  {
    postalCode: '2823',
    city: 'Weinberg'
  },
  {
    postalCode: '2824',
    city: 'Schiltern'
  },
  {
    postalCode: '2824',
    city: 'Seebenstein'
  },
  {
    postalCode: '2831',
    city: 'Gleißenfeld'
  },
  {
    postalCode: '2831',
    city: 'Grimmenstein'
  },
  {
    postalCode: '2831',
    city: 'Scheiblingkirchen'
  },
  {
    postalCode: '2831',
    city: 'Reitersberg'
  },
  {
    postalCode: '2831',
    city: 'Witzelsberg'
  },
  {
    postalCode: '2831',
    city: 'Sollgraben'
  },
  {
    postalCode: '2831',
    city: 'Haßbach'
  },
  {
    postalCode: '2831',
    city: 'Kirchau'
  },
  {
    postalCode: '2831',
    city: 'Kulm'
  },
  {
    postalCode: '2831',
    city: 'Steyersberg'
  },
  {
    postalCode: '2831',
    city: 'Thann'
  },
  {
    postalCode: '2831',
    city: 'Warth'
  },
  {
    postalCode: '2832',
    city: 'Thernberg'
  },
  {
    postalCode: '2833',
    city: 'Bad Erlach'
  },
  {
    postalCode: '2833',
    city: 'Breitenbuch'
  },
  {
    postalCode: '2833',
    city: 'Bromberg'
  },
  {
    postalCode: '2833',
    city: 'Schlag'
  },
  {
    postalCode: '2833',
    city: 'Schlatten'
  },
  {
    postalCode: '2833',
    city: 'Michelbach'
  },
  {
    postalCode: '2833',
    city: 'Thernberg'
  },
  {
    postalCode: '2840',
    city: 'Edlitz'
  },
  {
    postalCode: '2840',
    city: 'Grimmenstein'
  },
  {
    postalCode: '2840',
    city: 'Hochegg'
  },
  {
    postalCode: '2840',
    city: 'Hütten'
  },
  {
    postalCode: '2840',
    city: 'Königsberg'
  },
  {
    postalCode: '2840',
    city: 'Sauerbichl'
  },
  {
    postalCode: '2840',
    city: 'Petersbaumgarten'
  },
  {
    postalCode: '2842',
    city: 'Edlitz-Markt'
  },
  {
    postalCode: '2842',
    city: 'Edlitz'
  },
  {
    postalCode: '2842',
    city: 'Grimmenstein'
  },
  {
    postalCode: '2842',
    city: 'Pregart'
  },
  {
    postalCode: '2842',
    city: 'Königsberg'
  },
  {
    postalCode: '2842',
    city: 'Thomasberg'
  },
  {
    postalCode: '2851',
    city: 'Krumbach-Amt'
  },
  {
    postalCode: '2851',
    city: 'Krumbach'
  },
  {
    postalCode: '2851',
    city: 'Tiefenbach'
  },
  {
    postalCode: '2851',
    city: 'Kulma'
  },
  {
    postalCode: '2851',
    city: 'Thomasberg'
  },
  {
    postalCode: '2851',
    city: 'Kampichl'
  },
  {
    postalCode: '2852',
    city: 'Dreihütten'
  },
  {
    postalCode: '2852',
    city: 'Burgerschlag'
  },
  {
    postalCode: '2852',
    city: 'Grametschlag'
  },
  {
    postalCode: '2852',
    city: 'Gschaidt'
  },
  {
    postalCode: '2852',
    city: 'Harmannsdorf'
  },
  {
    postalCode: '2852',
    city: 'Hattmannsdorf'
  },
  {
    postalCode: '2852',
    city: 'Hochneukirchen'
  },
  {
    postalCode: '2852',
    city: 'Kirchschlagl'
  },
  {
    postalCode: '2852',
    city: 'Loipersdorf'
  },
  {
    postalCode: '2852',
    city: 'Maltern'
  },
  {
    postalCode: '2852',
    city: 'Offenegg'
  },
  {
    postalCode: '2852',
    city: 'Ulrichsdorf'
  },
  {
    postalCode: '2852',
    city: 'Züggen'
  },
  {
    postalCode: '2852',
    city: 'Krumbach-Amt'
  },
  {
    postalCode: '2852',
    city: 'Aschau im Burgenland'
  },
  {
    postalCode: '2852',
    city: 'Elsenau'
  },
  {
    postalCode: '2852',
    city: 'Pichl'
  },
  {
    postalCode: '2852',
    city: 'Stübegg'
  },
  {
    postalCode: '2853',
    city: 'Almen'
  },
  {
    postalCode: '2853',
    city: 'Bad Schönau'
  },
  {
    postalCode: '2853',
    city: 'Leitenviertel'
  },
  {
    postalCode: '2853',
    city: 'Maierhöfen'
  },
  {
    postalCode: '2853',
    city: 'Schlägen'
  },
  {
    postalCode: '2853',
    city: 'Schützenkasten'
  },
  {
    postalCode: '2853',
    city: 'Wenigreith'
  },
  {
    postalCode: '2853',
    city: 'Aigen'
  },
  {
    postalCode: '2853',
    city: 'Krumbach-Amt'
  },
  {
    postalCode: '2860',
    city: 'Aigen'
  },
  {
    postalCode: '2860',
    city: 'Kirchschlag in der Buckligen Welt'
  },
  {
    postalCode: '2860',
    city: 'Lembach'
  },
  {
    postalCode: '2860',
    city: 'Straß'
  },
  {
    postalCode: '2860',
    city: 'Thomasdorf'
  },
  {
    postalCode: '2860',
    city: 'Ungerbach'
  },
  {
    postalCode: '2860',
    city: 'Stang'
  },
  {
    postalCode: '2870',
    city: 'Außeraigen'
  },
  {
    postalCode: '2870',
    city: 'Höll'
  },
  {
    postalCode: '2870',
    city: 'Hoffeld'
  },
  {
    postalCode: '2870',
    city: 'Inneraigen'
  },
  {
    postalCode: '2870',
    city: 'Königsberg'
  },
  {
    postalCode: '2870',
    city: 'Langegg'
  },
  {
    postalCode: '2870',
    city: 'Neustift am Alpenwalde'
  },
  {
    postalCode: '2870',
    city: 'Neustift am Hartberg'
  },
  {
    postalCode: '2870',
    city: 'Neuwald'
  },
  {
    postalCode: '2870',
    city: 'Sonneck'
  },
  {
    postalCode: '2870',
    city: 'Aspang Markt'
  },
  {
    postalCode: '2870',
    city: 'Grottendorf'
  },
  {
    postalCode: '2870',
    city: 'Guggendorf'
  },
  {
    postalCode: '2870',
    city: 'St. Corona am Wechsel'
  },
  {
    postalCode: '2870',
    city: 'Kulma'
  },
  {
    postalCode: '2870',
    city: 'Kampichl'
  },
  {
    postalCode: '2870',
    city: 'Maierhöfen'
  },
  {
    postalCode: '2870',
    city: 'Stübegg'
  },
  {
    postalCode: '2871',
    city: 'Krumbach-Amt'
  },
  {
    postalCode: '2871',
    city: 'Guggendorf'
  },
  {
    postalCode: '2871',
    city: 'Knolln'
  },
  {
    postalCode: '2871',
    city: 'Kampichl'
  },
  {
    postalCode: '2871',
    city: 'Maierhöfen'
  },
  {
    postalCode: '2871',
    city: 'Pichl'
  },
  {
    postalCode: '2871',
    city: 'Schlag'
  },
  {
    postalCode: '2871',
    city: 'Stübegg'
  },
  {
    postalCode: '2871',
    city: 'Zöbern'
  },
  {
    postalCode: '2872',
    city: 'Langegg'
  },
  {
    postalCode: '2872',
    city: 'Neustift am Alpenwalde'
  },
  {
    postalCode: '2872',
    city: 'Mönichkirchen'
  },
  {
    postalCode: '2872',
    city: 'Schaueregg'
  },
  {
    postalCode: '2873',
    city: 'Höll'
  },
  {
    postalCode: '2873',
    city: 'Feistritz am Wechsel'
  },
  {
    postalCode: '2873',
    city: 'Grottendorf'
  },
  {
    postalCode: '2880',
    city: 'Hasleiten'
  },
  {
    postalCode: '2880',
    city: 'Kirchberg am Wechsel-Außen'
  },
  {
    postalCode: '2880',
    city: 'Kirchberg am Wechsel-Markt'
  },
  {
    postalCode: '2880',
    city: 'Kranichberg'
  },
  {
    postalCode: '2880',
    city: 'Alpeltal'
  },
  {
    postalCode: '2880',
    city: 'Lehen'
  },
  {
    postalCode: '2880',
    city: 'Molzegg'
  },
  {
    postalCode: '2880',
    city: 'Ofenbach'
  },
  {
    postalCode: '2880',
    city: 'Otterthal'
  },
  {
    postalCode: '2880',
    city: 'St. Corona am Wechsel'
  },
  {
    postalCode: '2881',
    city: 'Lehen'
  },
  {
    postalCode: '2881',
    city: 'Trattenbach'
  },
  {
    postalCode: '3001',
    city: 'Hainbuch'
  },
  {
    postalCode: '3001',
    city: 'Mauerbach'
  },
  {
    postalCode: '3001',
    city: 'Steinbach'
  },
  {
    postalCode: '3001',
    city: 'Tulbing'
  },
  {
    postalCode: '3002',
    city: 'Purkersdorf'
  },
  {
    postalCode: '3003',
    city: 'Gablitz'
  },
  {
    postalCode: '3004',
    city: 'Gablitz'
  },
  {
    postalCode: '3004',
    city: 'Ollern'
  },
  {
    postalCode: '3004',
    city: 'Ried am Riederberg'
  },
  {
    postalCode: '3004',
    city: 'Flachberg'
  },
  {
    postalCode: '3004',
    city: 'Riederberg'
  },
  {
    postalCode: '3004',
    city: 'Reichersberg'
  },
  {
    postalCode: '3004',
    city: 'Weinzierl'
  },
  {
    postalCode: '3004',
    city: 'Irenental'
  },
  {
    postalCode: '3011',
    city: 'Purkersdorf'
  },
  {
    postalCode: '3011',
    city: 'Irenental'
  },
  {
    postalCode: '3011',
    city: 'Untertullnerbach'
  },
  {
    postalCode: '3012',
    city: 'Pressbaum'
  },
  {
    postalCode: '3012',
    city: 'Purkersdorf'
  },
  {
    postalCode: '3012',
    city: 'Wolfsgraben'
  },
  {
    postalCode: '3013',
    city: 'Pressbaum'
  },
  {
    postalCode: '3013',
    city: 'Tullnerbach-Lawies'
  },
  {
    postalCode: '3021',
    city: 'Au am Kraking'
  },
  {
    postalCode: '3021',
    city: 'Pfalzau'
  },
  {
    postalCode: '3021',
    city: 'Pressbaum'
  },
  {
    postalCode: '3021',
    city: 'Rekawinkel'
  },
  {
    postalCode: '3021',
    city: 'Irenental'
  },
  {
    postalCode: '3021',
    city: 'Tullnerbach-Lawies'
  },
  {
    postalCode: '3021',
    city: 'Wolfsgraben'
  },
  {
    postalCode: '3031',
    city: 'Pressbaum'
  },
  {
    postalCode: '3031',
    city: 'Rekawinkel'
  },
  {
    postalCode: '3032',
    city: 'Eichgraben'
  },
  {
    postalCode: '3032',
    city: 'Hinterleiten'
  },
  {
    postalCode: '3032',
    city: 'Hutten'
  },
  {
    postalCode: '3032',
    city: 'Ottenheim'
  },
  {
    postalCode: '3032',
    city: 'Stein'
  },
  {
    postalCode: '3032',
    city: 'Winkl'
  },
  {
    postalCode: '3032',
    city: 'Gschaid'
  },
  {
    postalCode: '3032',
    city: 'Furth'
  },
  {
    postalCode: '3032',
    city: 'Rekawinkel'
  },
  {
    postalCode: '3032',
    city: 'Unter-Oberndorf'
  },
  {
    postalCode: '3033',
    city: 'Altlengbach'
  },
  {
    postalCode: '3033',
    city: 'Großenberg'
  },
  {
    postalCode: '3033',
    city: 'Hart'
  },
  {
    postalCode: '3033',
    city: 'Kogl'
  },
  {
    postalCode: '3033',
    city: 'Öd'
  },
  {
    postalCode: '3033',
    city: 'Außerfurth'
  },
  {
    postalCode: '3033',
    city: 'Götzwiesen'
  },
  {
    postalCode: '3033',
    city: 'Gschaid'
  },
  {
    postalCode: '3033',
    city: 'Klausen-Leopoldsdorf'
  },
  {
    postalCode: '3033',
    city: 'Kleinberg'
  },
  {
    postalCode: '3033',
    city: 'Knagg'
  },
  {
    postalCode: '3033',
    city: 'Lengbachl'
  },
  {
    postalCode: '3033',
    city: 'Linden'
  },
  {
    postalCode: '3033',
    city: 'Maiß'
  },
  {
    postalCode: '3033',
    city: 'Außermanzing'
  },
  {
    postalCode: '3033',
    city: 'Gumpersberg'
  },
  {
    postalCode: '3033',
    city: 'Pfalzau'
  },
  {
    postalCode: '3033',
    city: 'Rekawinkel'
  },
  {
    postalCode: '3033',
    city: 'Steinhäusl'
  },
  {
    postalCode: '3034',
    city: 'Dornberg'
  },
  {
    postalCode: '3034',
    city: 'Erlaa'
  },
  {
    postalCode: '3034',
    city: 'Burgstall'
  },
  {
    postalCode: '3034',
    city: 'Groß-Raßberg'
  },
  {
    postalCode: '3034',
    city: 'Gschwendt'
  },
  {
    postalCode: '3034',
    city: 'Hof'
  },
  {
    postalCode: '3034',
    city: 'Hofstatt am Anzbach'
  },
  {
    postalCode: '3034',
    city: 'Maierhöfen'
  },
  {
    postalCode: '3034',
    city: 'Klein-Weinberg'
  },
  {
    postalCode: '3034',
    city: 'Maria-Anzbach'
  },
  {
    postalCode: '3034',
    city: 'Pameth'
  },
  {
    postalCode: '3034',
    city: 'Winten'
  },
  {
    postalCode: '3034',
    city: 'Oed'
  },
  {
    postalCode: '3034',
    city: 'Unter-Oberndorf'
  },
  {
    postalCode: '3040',
    city: 'Almersberg'
  },
  {
    postalCode: '3040',
    city: 'Au am Anzbach'
  },
  {
    postalCode: '3040',
    city: 'Ebersberg'
  },
  {
    postalCode: '3040',
    city: 'Haag bei Neulengbach'
  },
  {
    postalCode: '3040',
    city: 'Hofstatt am Anzbach'
  },
  {
    postalCode: '3040',
    city: 'Karl-Deix-Siedlung'
  },
  {
    postalCode: '3040',
    city: 'Kleinraßberg'
  },
  {
    postalCode: '3040',
    city: 'Pameth'
  },
  {
    postalCode: '3040',
    city: 'Matzelsdorf'
  },
  {
    postalCode: '3040',
    city: 'Anzing'
  },
  {
    postalCode: '3040',
    city: 'Berging'
  },
  {
    postalCode: '3040',
    city: 'Emmersdorf'
  },
  {
    postalCode: '3040',
    city: 'Großweinberg'
  },
  {
    postalCode: '3040',
    city: 'Haag bei Markersdorf'
  },
  {
    postalCode: '3040',
    city: 'Herbstgraben'
  },
  {
    postalCode: '3040',
    city: 'Inprugg'
  },
  {
    postalCode: '3040',
    city: 'Langenberg'
  },
  {
    postalCode: '3040',
    city: 'Markersdorf'
  },
  {
    postalCode: '3040',
    city: 'Mosletzberg'
  },
  {
    postalCode: '3040',
    city: 'Neulengbach'
  },
  {
    postalCode: '3040',
    city: 'Obereichen'
  },
  {
    postalCode: '3040',
    city: 'Schwertfegen'
  },
  {
    postalCode: '3040',
    city: 'Weiding'
  },
  {
    postalCode: '3040',
    city: 'Eitzenberg'
  },
  {
    postalCode: '3040',
    city: 'Laa an der Tulln'
  },
  {
    postalCode: '3040',
    city: 'Stocket'
  },
  {
    postalCode: '3040',
    city: 'Umsee'
  },
  {
    postalCode: '3040',
    city: 'Raipoltenbach'
  },
  {
    postalCode: '3040',
    city: 'Straß'
  },
  {
    postalCode: '3040',
    city: 'Untereichen'
  },
  {
    postalCode: '3041',
    city: 'Asperhofen'
  },
  {
    postalCode: '3041',
    city: 'Diesendorf'
  },
  {
    postalCode: '3041',
    city: 'Großgraben'
  },
  {
    postalCode: '3041',
    city: 'Haghöfen'
  },
  {
    postalCode: '3041',
    city: 'Maierhöfen'
  },
  {
    postalCode: '3041',
    city: 'Weinzierl'
  },
  {
    postalCode: '3041',
    city: 'Grabensee'
  },
  {
    postalCode: '3041',
    city: 'Habersdorf'
  },
  {
    postalCode: '3041',
    city: 'Kerschenberg'
  },
  {
    postalCode: '3041',
    city: 'Dörfl'
  },
  {
    postalCode: '3041',
    city: 'Erlaa'
  },
  {
    postalCode: '3041',
    city: 'Hagenau'
  },
  {
    postalCode: '3041',
    city: 'Johannesberg'
  },
  {
    postalCode: '3041',
    city: 'Kleingraben'
  },
  {
    postalCode: '3041',
    city: 'Paisling'
  },
  {
    postalCode: '3041',
    city: 'Starzing'
  },
  {
    postalCode: '3041',
    city: 'Wimmersdorf'
  },
  {
    postalCode: '3041',
    city: 'Siegersdorf'
  },
  {
    postalCode: '3042',
    city: 'Gotthartsberg'
  },
  {
    postalCode: '3042',
    city: 'Gumperding'
  },
  {
    postalCode: '3042',
    city: 'Anzing'
  },
  {
    postalCode: '3042',
    city: 'Egelsee'
  },
  {
    postalCode: '3042',
    city: 'Grub'
  },
  {
    postalCode: '3042',
    city: 'Holzleiten'
  },
  {
    postalCode: '3042',
    city: 'Jetzing'
  },
  {
    postalCode: '3042',
    city: 'Mittermoos'
  },
  {
    postalCode: '3042',
    city: 'Pöding'
  },
  {
    postalCode: '3042',
    city: 'Untermoos'
  },
  {
    postalCode: '3042',
    city: 'Waltendorf'
  },
  {
    postalCode: '3042',
    city: 'Würmla'
  },
  {
    postalCode: '3051',
    city: 'Gottleitsberg'
  },
  {
    postalCode: '3051',
    city: 'Haagen'
  },
  {
    postalCode: '3051',
    city: 'Hocheichberg'
  },
  {
    postalCode: '3051',
    city: 'Leitsberg'
  },
  {
    postalCode: '3051',
    city: 'Nest'
  },
  {
    postalCode: '3051',
    city: 'Gamesreith'
  },
  {
    postalCode: '3051',
    city: 'Herrenhub'
  },
  {
    postalCode: '3051',
    city: 'Kleinhart'
  },
  {
    postalCode: '3051',
    city: 'Ludmerfeld'
  },
  {
    postalCode: '3051',
    city: 'Oberndorf'
  },
  {
    postalCode: '3051',
    city: 'Rothenbucherhöhe'
  },
  {
    postalCode: '3051',
    city: 'St. Christophen'
  },
  {
    postalCode: '3051',
    city: 'Trainst'
  },
  {
    postalCode: '3051',
    city: 'Unterdambach'
  },
  {
    postalCode: '3051',
    city: 'Almerberg'
  },
  {
    postalCode: '3051',
    city: 'Aschberg'
  },
  {
    postalCode: '3051',
    city: 'Gießhübl'
  },
  {
    postalCode: '3051',
    city: 'Mannersdorf'
  },
  {
    postalCode: '3051',
    city: 'Unterthurm'
  },
  {
    postalCode: '3052',
    city: 'Audorf'
  },
  {
    postalCode: '3052',
    city: 'Höfer'
  },
  {
    postalCode: '3052',
    city: 'Pamet'
  },
  {
    postalCode: '3052',
    city: 'Pyrat'
  },
  {
    postalCode: '3052',
    city: 'Innerfurth'
  },
  {
    postalCode: '3052',
    city: 'Kienberg'
  },
  {
    postalCode: '3052',
    city: 'Maiß'
  },
  {
    postalCode: '3052',
    city: 'Manzing'
  },
  {
    postalCode: '3052',
    city: 'Barbaraholz'
  },
  {
    postalCode: '3052',
    city: 'Eck'
  },
  {
    postalCode: '3052',
    city: 'Innermanzing'
  },
  {
    postalCode: '3052',
    city: 'Neustift'
  },
  {
    postalCode: '3052',
    city: 'Oberkühberg'
  },
  {
    postalCode: '3052',
    city: 'Unterkühberg'
  },
  {
    postalCode: '3052',
    city: 'Ödengraben'
  },
  {
    postalCode: '3052',
    city: 'Schoderleh'
  },
  {
    postalCode: '3053',
    city: 'Pamet'
  },
  {
    postalCode: '3053',
    city: 'Brand'
  },
  {
    postalCode: '3053',
    city: 'Eck'
  },
  {
    postalCode: '3053',
    city: 'Gern'
  },
  {
    postalCode: '3053',
    city: 'Gföhl'
  },
  {
    postalCode: '3053',
    city: 'Klamm'
  },
  {
    postalCode: '3053',
    city: 'Laaben'
  },
  {
    postalCode: '3053',
    city: 'Pyrat'
  },
  {
    postalCode: '3053',
    city: 'Stollberg'
  },
  {
    postalCode: '3053',
    city: 'Wöllersdorf'
  },
  {
    postalCode: '3053',
    city: 'Klausen-Leopoldsdorf'
  },
  {
    postalCode: '3053',
    city: 'Kleinmariazellerforst'
  },
  {
    postalCode: '3053',
    city: 'Bonnleiten'
  },
  {
    postalCode: '3061',
    city: 'Doppel'
  },
  {
    postalCode: '3061',
    city: 'Oberdambach'
  },
  {
    postalCode: '3061',
    city: 'Unterwolfsbach'
  },
  {
    postalCode: '3061',
    city: 'Wolfersdorf'
  },
  {
    postalCode: '3061',
    city: 'Schrabatz'
  },
  {
    postalCode: '3061',
    city: 'Tausendblum'
  },
  {
    postalCode: '3061',
    city: 'Ollersbach'
  },
  {
    postalCode: '3061',
    city: 'Schönfeld'
  },
  {
    postalCode: '3062',
    city: 'Aschberg'
  },
  {
    postalCode: '3062',
    city: 'Doppel'
  },
  {
    postalCode: '3062',
    city: 'Fuchsberg'
  },
  {
    postalCode: '3062',
    city: 'Gstockert'
  },
  {
    postalCode: '3062',
    city: 'Hinterholz'
  },
  {
    postalCode: '3062',
    city: 'Kirchstetten'
  },
  {
    postalCode: '3062',
    city: 'Ober-Wolfsbach'
  },
  {
    postalCode: '3062',
    city: 'Paltram'
  },
  {
    postalCode: '3062',
    city: 'Senning'
  },
  {
    postalCode: '3062',
    city: 'Sichelbach'
  },
  {
    postalCode: '3062',
    city: 'Totzenbach'
  },
  {
    postalCode: '3062',
    city: 'Waasen'
  },
  {
    postalCode: '3062',
    city: 'Pettenau'
  },
  {
    postalCode: '3071',
    city: 'Bauland'
  },
  {
    postalCode: '3071',
    city: 'Blindorf'
  },
  {
    postalCode: '3071',
    city: 'Böheimkirchen'
  },
  {
    postalCode: '3071',
    city: 'Diemannsberg'
  },
  {
    postalCode: '3071',
    city: 'Dorfern'
  },
  {
    postalCode: '3071',
    city: 'Dürnhag'
  },
  {
    postalCode: '3071',
    city: 'Furth'
  },
  {
    postalCode: '3071',
    city: 'Gemersdorf'
  },
  {
    postalCode: '3071',
    city: 'Grub'
  },
  {
    postalCode: '3071',
    city: 'Hinterberg'
  },
  {
    postalCode: '3071',
    city: 'Hinterholz'
  },
  {
    postalCode: '3071',
    city: 'Hub'
  },
  {
    postalCode: '3071',
    city: 'Kollersberg'
  },
  {
    postalCode: '3071',
    city: 'Lanzendorf'
  },
  {
    postalCode: '3071',
    city: 'Mechters'
  },
  {
    postalCode: '3071',
    city: 'Plosdorf'
  },
  {
    postalCode: '3071',
    city: 'Reith'
  },
  {
    postalCode: '3071',
    city: 'Röhrenbach'
  },
  {
    postalCode: '3071',
    city: 'Schildberg'
  },
  {
    postalCode: '3071',
    city: 'Siebenhirten'
  },
  {
    postalCode: '3071',
    city: 'Untergrafendorf'
  },
  {
    postalCode: '3071',
    city: 'Untertiefenbach'
  },
  {
    postalCode: '3071',
    city: 'Weisching'
  },
  {
    postalCode: '3071',
    city: 'Wiesen'
  },
  {
    postalCode: '3071',
    city: 'Berg'
  },
  {
    postalCode: '3071',
    city: 'Braunsberg'
  },
  {
    postalCode: '3071',
    city: 'Fahrafeld'
  },
  {
    postalCode: '3071',
    city: 'Kirchweg'
  },
  {
    postalCode: '3071',
    city: 'Perersdorf'
  },
  {
    postalCode: '3072',
    city: 'Außerkasten'
  },
  {
    postalCode: '3072',
    city: 'Baumgarten bei Kasten'
  },
  {
    postalCode: '3072',
    city: 'Damberg'
  },
  {
    postalCode: '3072',
    city: 'Dörfl bei Kasten'
  },
  {
    postalCode: '3072',
    city: 'Gwörth'
  },
  {
    postalCode: '3072',
    city: 'Hummelberg bei Kasten'
  },
  {
    postalCode: '3072',
    city: 'Kasten bei Böheimkirchen'
  },
  {
    postalCode: '3072',
    city: 'Kirchsteig'
  },
  {
    postalCode: '3072',
    city: 'Lanzendorf bei Kasten'
  },
  {
    postalCode: '3072',
    city: 'Lielach'
  },
  {
    postalCode: '3072',
    city: 'Mitterfeld'
  },
  {
    postalCode: '3072',
    city: 'Stallbach'
  },
  {
    postalCode: '3072',
    city: 'Steinabruck'
  },
  {
    postalCode: '3072',
    city: 'Wallenreith'
  },
  {
    postalCode: '3072',
    city: 'Trainst'
  },
  {
    postalCode: '3072',
    city: 'Hochgschaid'
  },
  {
    postalCode: '3073',
    city: 'Pyrat'
  },
  {
    postalCode: '3073',
    city: 'Mayerhöfen'
  },
  {
    postalCode: '3073',
    city: 'Eck'
  },
  {
    postalCode: '3073',
    city: 'Bonnleiten'
  },
  {
    postalCode: '3073',
    city: 'Buchbach'
  },
  {
    postalCode: '3073',
    city: 'Dachsbach'
  },
  {
    postalCode: '3073',
    city: 'Freiling'
  },
  {
    postalCode: '3073',
    city: 'Hendelgraben'
  },
  {
    postalCode: '3073',
    city: 'Hochgschaid'
  },
  {
    postalCode: '3073',
    city: 'Hochstraß'
  },
  {
    postalCode: '3073',
    city: 'Hof'
  },
  {
    postalCode: '3073',
    city: 'Sonnleiten'
  },
  {
    postalCode: '3073',
    city: 'Stössing'
  },
  {
    postalCode: '3074',
    city: 'Kronberg'
  },
  {
    postalCode: '3074',
    city: 'Finsteregg'
  },
  {
    postalCode: '3074',
    city: 'Gstetten'
  },
  {
    postalCode: '3074',
    city: 'Kleindurlas'
  },
  {
    postalCode: '3074',
    city: 'Kropfsdorf'
  },
  {
    postalCode: '3074',
    city: 'Michelbach Dorf'
  },
  {
    postalCode: '3074',
    city: 'Michelbach Markt'
  },
  {
    postalCode: '3074',
    city: 'Untergoin'
  },
  {
    postalCode: '3074',
    city: 'Mayerhöfen'
  },
  {
    postalCode: '3074',
    city: 'Hochstraß'
  },
  {
    postalCode: '3100',
    city: 'Oberwagram'
  },
  {
    postalCode: '3100',
    city: 'St. Pölten'
  },
  {
    postalCode: '3100',
    city: 'Spratzern'
  },
  {
    postalCode: '3100',
    city: 'Stattersdorf'
  },
  {
    postalCode: '3100',
    city: 'Teufelhof'
  },
  {
    postalCode: '3100',
    city: 'Unterwagram'
  },
  {
    postalCode: '3100',
    city: 'Viehofen'
  },
  {
    postalCode: '3100',
    city: 'Völtendorf'
  },
  {
    postalCode: '3100',
    city: 'Waitzendorf'
  },
  {
    postalCode: '3100',
    city: 'Witzendorf'
  },
  {
    postalCode: '3100',
    city: 'Hafing'
  },
  {
    postalCode: '3100',
    city: 'Nadelbach'
  },
  {
    postalCode: '3100',
    city: 'Pummersdorf'
  },
  {
    postalCode: '3100',
    city: 'Schwadorf'
  },
  {
    postalCode: '3100',
    city: 'Ratzersdorf an der Traisen'
  },
  {
    postalCode: '3100',
    city: 'Oberzwischenbrunn'
  },
  {
    postalCode: '3100',
    city: 'Unterzwischenbrunn'
  },
  {
    postalCode: '3104',
    city: 'Brunn'
  },
  {
    postalCode: '3104',
    city: 'Harland'
  },
  {
    postalCode: '3104',
    city: 'St. Pölten'
  },
  {
    postalCode: '3104',
    city: 'Stattersdorf'
  },
  {
    postalCode: '3104',
    city: 'Altmannsdorf'
  },
  {
    postalCode: '3104',
    city: 'Windpassing'
  },
  {
    postalCode: '3105',
    city: 'Oberradlberg'
  },
  {
    postalCode: '3105',
    city: 'Unterradlberg'
  },
  {
    postalCode: '3107',
    city: 'Angern'
  },
  {
    postalCode: '3107',
    city: 'Diendorf'
  },
  {
    postalCode: '3107',
    city: 'Flinsdorf'
  },
  {
    postalCode: '3107',
    city: 'Greiling'
  },
  {
    postalCode: '3107',
    city: 'Großhain'
  },
  {
    postalCode: '3107',
    city: 'Kleinhain'
  },
  {
    postalCode: '3107',
    city: 'Zagging'
  },
  {
    postalCode: '3107',
    city: 'Ragelsdorf'
  },
  {
    postalCode: '3107',
    city: 'St. Pölten'
  },
  {
    postalCode: '3107',
    city: 'Viehofen'
  },
  {
    postalCode: '3107',
    city: 'Weitern'
  },
  {
    postalCode: '3110',
    city: 'Afing'
  },
  {
    postalCode: '3110',
    city: 'Dietersberg'
  },
  {
    postalCode: '3110',
    city: 'Enikelberg'
  },
  {
    postalCode: '3110',
    city: 'Flinsbach'
  },
  {
    postalCode: '3110',
    city: 'Gabersdorf'
  },
  {
    postalCode: '3110',
    city: 'Goldegg'
  },
  {
    postalCode: '3110',
    city: 'Griechenberg'
  },
  {
    postalCode: '3110',
    city: 'Neidling'
  },
  {
    postalCode: '3110',
    city: 'Pultendorf'
  },
  {
    postalCode: '3110',
    city: 'Watzelsdorf'
  },
  {
    postalCode: '3110',
    city: 'Wernersdorf'
  },
  {
    postalCode: '3121',
    city: 'Dreihöf'
  },
  {
    postalCode: '3121',
    city: 'Hausenbach'
  },
  {
    postalCode: '3121',
    city: 'Heitzing'
  },
  {
    postalCode: '3121',
    city: 'Karlstetten'
  },
  {
    postalCode: '3121',
    city: 'Lauterbach'
  },
  {
    postalCode: '3121',
    city: 'Obermamau'
  },
  {
    postalCode: '3121',
    city: 'Rosenthal'
  },
  {
    postalCode: '3121',
    city: 'Schaubing'
  },
  {
    postalCode: '3121',
    city: 'Untermamau'
  },
  {
    postalCode: '3121',
    city: 'Weyersdorf'
  },
  {
    postalCode: '3121',
    city: 'Wieshöf'
  },
  {
    postalCode: '3121',
    city: 'Heinigstetten'
  },
  {
    postalCode: '3122',
    city: 'Geyersberg'
  },
  {
    postalCode: '3122',
    city: 'Nesselstauden'
  },
  {
    postalCode: '3122',
    city: 'Scheiblwies'
  },
  {
    postalCode: '3122',
    city: 'Wolfenreith'
  },
  {
    postalCode: '3122',
    city: 'Aichberg'
  },
  {
    postalCode: '3122',
    city: 'Besenbuch'
  },
  {
    postalCode: '3122',
    city: 'Bittersbach'
  },
  {
    postalCode: '3122',
    city: 'Dürnbach'
  },
  {
    postalCode: '3122',
    city: 'Gansbach'
  },
  {
    postalCode: '3122',
    city: 'Hessendorf'
  },
  {
    postalCode: '3122',
    city: 'Himberg'
  },
  {
    postalCode: '3122',
    city: 'Kicking'
  },
  {
    postalCode: '3122',
    city: 'Krapfenberg'
  },
  {
    postalCode: '3122',
    city: 'Lottersberg'
  },
  {
    postalCode: '3122',
    city: 'Mauer bei Melk'
  },
  {
    postalCode: '3122',
    city: 'Maierhöfen'
  },
  {
    postalCode: '3122',
    city: 'Nonnenhöfen'
  },
  {
    postalCode: '3122',
    city: 'Oed'
  },
  {
    postalCode: '3122',
    city: 'Bichl'
  },
  {
    postalCode: '3122',
    city: 'Pinnenhöfen'
  },
  {
    postalCode: '3122',
    city: 'Schwaigbichl'
  },
  {
    postalCode: '3122',
    city: 'Viehausen'
  },
  {
    postalCode: '3123',
    city: 'Doppel'
  },
  {
    postalCode: '3123',
    city: 'Eitzendorf'
  },
  {
    postalCode: '3123',
    city: 'Fugging'
  },
  {
    postalCode: '3123',
    city: 'Großrust'
  },
  {
    postalCode: '3123',
    city: 'Grünz'
  },
  {
    postalCode: '3123',
    city: 'Heinigstetten'
  },
  {
    postalCode: '3123',
    city: 'Hofstetten'
  },
  {
    postalCode: '3123',
    city: 'Kleinrust'
  },
  {
    postalCode: '3123',
    city: 'Landhausen'
  },
  {
    postalCode: '3123',
    city: 'Mittermerking'
  },
  {
    postalCode: '3123',
    city: 'Neustift'
  },
  {
    postalCode: '3123',
    city: 'Obermerking'
  },
  {
    postalCode: '3123',
    city: 'Obritzberg'
  },
  {
    postalCode: '3123',
    city: 'Pfaffing'
  },
  {
    postalCode: '3123',
    city: 'Schweinern'
  },
  {
    postalCode: '3123',
    city: 'Thallern'
  },
  {
    postalCode: '3123',
    city: 'Untermerking'
  },
  {
    postalCode: '3123',
    city: 'Winzing'
  },
  {
    postalCode: '3124',
    city: 'Ambach'
  },
  {
    postalCode: '3124',
    city: 'Hausheim'
  },
  {
    postalCode: '3124',
    city: 'Landersdorf'
  },
  {
    postalCode: '3124',
    city: 'Oberwölbling'
  },
  {
    postalCode: '3124',
    city: 'Ratzersdorf'
  },
  {
    postalCode: '3124',
    city: 'Unterwölbling'
  },
  {
    postalCode: '3124',
    city: 'Wetzlarn'
  },
  {
    postalCode: '3125',
    city: 'Fugging'
  },
  {
    postalCode: '3125',
    city: 'Absdorf'
  },
  {
    postalCode: '3125',
    city: 'Kuffern'
  },
  {
    postalCode: '3125',
    city: 'Rottersdorf'
  },
  {
    postalCode: '3125',
    city: 'Statzendorf'
  },
  {
    postalCode: '3125',
    city: 'Weidling'
  },
  {
    postalCode: '3125',
    city: 'Anzenhof'
  },
  {
    postalCode: '3125',
    city: 'Noppendorf'
  },
  {
    postalCode: '3130',
    city: 'Angern'
  },
  {
    postalCode: '3130',
    city: 'Ederding'
  },
  {
    postalCode: '3130',
    city: 'Einöd'
  },
  {
    postalCode: '3130',
    city: 'Herzogenburg'
  },
  {
    postalCode: '3130',
    city: 'Oberwinden'
  },
  {
    postalCode: '3130',
    city: 'Ossarn'
  },
  {
    postalCode: '3130',
    city: 'St. Andrä an der Traisen'
  },
  {
    postalCode: '3130',
    city: 'Unterwinden'
  },
  {
    postalCode: '3130',
    city: 'Wielandsthal'
  },
  {
    postalCode: '3130',
    city: 'Wiesing'
  },
  {
    postalCode: '3130',
    city: 'Oberndorf in der Ebene'
  },
  {
    postalCode: '3131',
    city: 'Anzenberg'
  },
  {
    postalCode: '3131',
    city: 'Getzersdorf'
  },
  {
    postalCode: '3131',
    city: 'Inzersdorf ob der Traisen'
  },
  {
    postalCode: '3131',
    city: 'Walpersdorf'
  },
  {
    postalCode: '3131',
    city: 'Wetzmannsthal'
  },
  {
    postalCode: '3133',
    city: 'Frauendorf'
  },
  {
    postalCode: '3133',
    city: 'Gemeinlebarn'
  },
  {
    postalCode: '3133',
    city: 'Hilpersdorf'
  },
  {
    postalCode: '3133',
    city: 'Mitterndorf'
  },
  {
    postalCode: '3133',
    city: 'Oberndorf am Gebirge'
  },
  {
    postalCode: '3133',
    city: 'Rittersfeld'
  },
  {
    postalCode: '3133',
    city: 'St. Georgen an der Traisen'
  },
  {
    postalCode: '3133',
    city: 'Stollhofen'
  },
  {
    postalCode: '3133',
    city: 'Traismauer'
  },
  {
    postalCode: '3133',
    city: 'Venusberg'
  },
  {
    postalCode: '3133',
    city: 'Wagram ob der Traisen'
  },
  {
    postalCode: '3133',
    city: 'Waldlesberg'
  },
  {
    postalCode: '3134',
    city: 'Franzhausen'
  },
  {
    postalCode: '3134',
    city: 'Fräuleinmühle'
  },
  {
    postalCode: '3134',
    city: 'Neusiedl'
  },
  {
    postalCode: '3134',
    city: 'Nußdorf ob der Traisen'
  },
  {
    postalCode: '3134',
    city: 'Reichersdorf'
  },
  {
    postalCode: '3134',
    city: 'Ried'
  },
  {
    postalCode: '3134',
    city: 'Theyern'
  },
  {
    postalCode: '3140',
    city: 'Maria Jeutendorf'
  },
  {
    postalCode: '3140',
    city: 'Mauterheim'
  },
  {
    postalCode: '3140',
    city: 'Pengersdorf'
  },
  {
    postalCode: '3140',
    city: 'Pottenbrunn'
  },
  {
    postalCode: '3140',
    city: 'Wasserburg'
  },
  {
    postalCode: '3140',
    city: 'Zwerndorf'
  },
  {
    postalCode: '3141',
    city: 'Etzersdorf'
  },
  {
    postalCode: '3141',
    city: 'Kapelln'
  },
  {
    postalCode: '3141',
    city: 'Katzenberg'
  },
  {
    postalCode: '3141',
    city: 'Mitterau'
  },
  {
    postalCode: '3141',
    city: 'Mitterkilling'
  },
  {
    postalCode: '3141',
    city: 'Oberkilling'
  },
  {
    postalCode: '3141',
    city: 'Obermiesting'
  },
  {
    postalCode: '3141',
    city: 'Panzing'
  },
  {
    postalCode: '3141',
    city: 'Pönning'
  },
  {
    postalCode: '3141',
    city: 'Rapoltendorf'
  },
  {
    postalCode: '3141',
    city: 'Rassing'
  },
  {
    postalCode: '3141',
    city: 'Thalheim'
  },
  {
    postalCode: '3141',
    city: 'Unterau'
  },
  {
    postalCode: '3141',
    city: 'Unterkilling'
  },
  {
    postalCode: '3141',
    city: 'Untermiesting'
  },
  {
    postalCode: '3142',
    city: 'Gunnersdorf'
  },
  {
    postalCode: '3142',
    city: 'Murstetten'
  },
  {
    postalCode: '3142',
    city: 'Obermoos'
  },
  {
    postalCode: '3142',
    city: 'Winkling'
  },
  {
    postalCode: '3142',
    city: 'Grunddorf'
  },
  {
    postalCode: '3142',
    city: 'Haselbach'
  },
  {
    postalCode: '3142',
    city: 'Langmannersdorf'
  },
  {
    postalCode: '3142',
    city: 'Perschling'
  },
  {
    postalCode: '3142',
    city: 'Weißenkirchen an der Perschling'
  },
  {
    postalCode: '3142',
    city: 'Wieselbruck'
  },
  {
    postalCode: '3143',
    city: 'Adeldorf'
  },
  {
    postalCode: '3143',
    city: 'Blindorf'
  },
  {
    postalCode: '3143',
    city: 'Brunn'
  },
  {
    postalCode: '3143',
    city: 'Ebersreith'
  },
  {
    postalCode: '3143',
    city: 'Egelsee'
  },
  {
    postalCode: '3143',
    city: 'Fahra'
  },
  {
    postalCode: '3143',
    city: 'Gattring-Raking'
  },
  {
    postalCode: '3143',
    city: 'Getzersdorf'
  },
  {
    postalCode: '3143',
    city: 'Heuberg'
  },
  {
    postalCode: '3143',
    city: 'Nützling'
  },
  {
    postalCode: '3143',
    city: 'Obergrub'
  },
  {
    postalCode: '3143',
    city: 'Oberloitzenberg'
  },
  {
    postalCode: '3143',
    city: 'Obertiefenbach'
  },
  {
    postalCode: '3143',
    city: 'Perersdorf'
  },
  {
    postalCode: '3143',
    city: 'Pyhra'
  },
  {
    postalCode: '3143',
    city: 'Reichenhag'
  },
  {
    postalCode: '3143',
    city: 'Reichgrüben'
  },
  {
    postalCode: '3143',
    city: 'Schauching'
  },
  {
    postalCode: '3143',
    city: 'Schnabling'
  },
  {
    postalCode: '3143',
    city: 'Unterloitzenberg'
  },
  {
    postalCode: '3143',
    city: 'Weinzettl'
  },
  {
    postalCode: '3143',
    city: 'Wieden'
  },
  {
    postalCode: '3143',
    city: 'Zuleithen'
  },
  {
    postalCode: '3143',
    city: 'Oberburbach'
  },
  {
    postalCode: '3143',
    city: 'Unterburbach'
  },
  {
    postalCode: '3144',
    city: 'Atzling'
  },
  {
    postalCode: '3144',
    city: 'Auern'
  },
  {
    postalCode: '3144',
    city: 'Hinterholz'
  },
  {
    postalCode: '3144',
    city: 'Hummelberg bei Hinterholz'
  },
  {
    postalCode: '3144',
    city: 'Aigen'
  },
  {
    postalCode: '3144',
    city: 'Baumgarten'
  },
  {
    postalCode: '3144',
    city: 'Perschenegg'
  },
  {
    postalCode: '3144',
    city: 'Steinbach'
  },
  {
    postalCode: '3144',
    city: 'Wald'
  },
  {
    postalCode: '3144',
    city: 'Windhag'
  },
  {
    postalCode: '3144',
    city: 'Zell'
  },
  {
    postalCode: '3150',
    city: 'Aigelsbach'
  },
  {
    postalCode: '3150',
    city: 'Schwarzenbach an der Gölsen'
  },
  {
    postalCode: '3150',
    city: 'Steinwandleiten'
  },
  {
    postalCode: '3150',
    city: 'Altenburg'
  },
  {
    postalCode: '3150',
    city: 'Göblasbruck'
  },
  {
    postalCode: '3150',
    city: 'Handelberg'
  },
  {
    postalCode: '3150',
    city: 'Kanzling'
  },
  {
    postalCode: '3150',
    city: 'Kreisbach'
  },
  {
    postalCode: '3150',
    city: 'Pömmern'
  },
  {
    postalCode: '3150',
    city: 'Wegbach'
  },
  {
    postalCode: '3150',
    city: 'Wielandsberg'
  },
  {
    postalCode: '3150',
    city: 'Wilhelmsburg'
  },
  {
    postalCode: '3150',
    city: 'Wolkersberg'
  },
  {
    postalCode: '3151',
    city: 'Spratzern'
  },
  {
    postalCode: '3151',
    city: 'Dörfl'
  },
  {
    postalCode: '3151',
    city: 'Eggendorf'
  },
  {
    postalCode: '3151',
    city: 'Ganzendorf'
  },
  {
    postalCode: '3151',
    city: 'Hart'
  },
  {
    postalCode: '3151',
    city: 'Kreisberg'
  },
  {
    postalCode: '3151',
    city: 'Mühlgang'
  },
  {
    postalCode: '3151',
    city: 'Ochsenburg'
  },
  {
    postalCode: '3151',
    city: 'Reitzersdorf'
  },
  {
    postalCode: '3151',
    city: 'St. Georgen am Steinfelde'
  },
  {
    postalCode: '3151',
    city: 'Steinfeld'
  },
  {
    postalCode: '3151',
    city: 'Wörth'
  },
  {
    postalCode: '3151',
    city: 'Wetzersdorf'
  },
  {
    postalCode: '3151',
    city: 'Wolfenberg'
  },
  {
    postalCode: '3153',
    city: 'Eschenau'
  },
  {
    postalCode: '3153',
    city: 'Laimergraben'
  },
  {
    postalCode: '3153',
    city: 'Rotheau'
  },
  {
    postalCode: '3153',
    city: 'Sonnleitgraben'
  },
  {
    postalCode: '3153',
    city: 'Steubach'
  },
  {
    postalCode: '3153',
    city: 'Wehrabach'
  },
  {
    postalCode: '3160',
    city: 'Maierhöfen'
  },
  {
    postalCode: '3160',
    city: 'Steinwandleiten'
  },
  {
    postalCode: '3160',
    city: 'Traisen'
  },
  {
    postalCode: '3161',
    city: 'Pfenningbach'
  },
  {
    postalCode: '3161',
    city: 'Außer-Wiesenbach'
  },
  {
    postalCode: '3161',
    city: 'Inner-Wiesenbach'
  },
  {
    postalCode: '3161',
    city: 'Kerschenbach'
  },
  {
    postalCode: '3161',
    city: 'Kropfsdorf'
  },
  {
    postalCode: '3161',
    city: 'Maierhöfen'
  },
  {
    postalCode: '3161',
    city: 'Obergegend'
  },
  {
    postalCode: '3161',
    city: 'St. Veit an der Gölsen'
  },
  {
    postalCode: '3161',
    city: 'Schwarzenbach an der Gölsen'
  },
  {
    postalCode: '3161',
    city: 'Steinwandleiten'
  },
  {
    postalCode: '3161',
    city: 'Traisenort'
  },
  {
    postalCode: '3161',
    city: 'Wiesenfeld'
  },
  {
    postalCode: '3161',
    city: 'Wobach'
  },
  {
    postalCode: '3162',
    city: 'Obergegend'
  },
  {
    postalCode: '3162',
    city: 'Rainfeld'
  },
  {
    postalCode: '3163',
    city: 'Ob der Kirche'
  },
  {
    postalCode: '3163',
    city: 'Außerhalbach'
  },
  {
    postalCode: '3163',
    city: 'Bernreit'
  },
  {
    postalCode: '3163',
    city: 'Durlaß'
  },
  {
    postalCode: '3163',
    city: 'Oberrohrbach'
  },
  {
    postalCode: '3163',
    city: 'Prünst'
  },
  {
    postalCode: '3163',
    city: 'Rohrbach an der Gölsen'
  },
  {
    postalCode: '3163',
    city: 'Obergegend'
  },
  {
    postalCode: '3170',
    city: 'Gegend Egg'
  },
  {
    postalCode: '3170',
    city: 'Gölsen'
  },
  {
    postalCode: '3170',
    city: 'Hainfeld'
  },
  {
    postalCode: '3170',
    city: 'Heugraben'
  },
  {
    postalCode: '3170',
    city: 'Kasberg'
  },
  {
    postalCode: '3170',
    city: 'Landstal'
  },
  {
    postalCode: '3170',
    city: 'Ob der Kirche'
  },
  {
    postalCode: '3170',
    city: 'Vollberg'
  },
  {
    postalCode: '3170',
    city: 'Gerichtsberg'
  },
  {
    postalCode: '3170',
    city: 'Gerstbach'
  },
  {
    postalCode: '3170',
    city: 'Bernau'
  },
  {
    postalCode: '3170',
    city: 'Gstettl'
  },
  {
    postalCode: '3170',
    city: 'Kaufmannberg'
  },
  {
    postalCode: '3170',
    city: 'Oberried'
  },
  {
    postalCode: '3171',
    city: 'Außerhalbach'
  },
  {
    postalCode: '3171',
    city: 'Ebenwald'
  },
  {
    postalCode: '3171',
    city: 'Innerhalbach'
  },
  {
    postalCode: '3171',
    city: 'Kleinzell'
  },
  {
    postalCode: '3172',
    city: 'Fahrabach'
  },
  {
    postalCode: '3172',
    city: 'Gaupmannsgraben'
  },
  {
    postalCode: '3172',
    city: 'Haraseck'
  },
  {
    postalCode: '3172',
    city: 'Kieneck'
  },
  {
    postalCode: '3172',
    city: 'Oberhöhe'
  },
  {
    postalCode: '3172',
    city: 'Oberried'
  },
  {
    postalCode: '3172',
    city: 'Ramsau'
  },
  {
    postalCode: '3172',
    city: 'Schneidbach'
  },
  {
    postalCode: '3172',
    city: 'Unterried'
  },
  {
    postalCode: '3180',
    city: 'Dörfl'
  },
  {
    postalCode: '3180',
    city: 'Hintereben'
  },
  {
    postalCode: '3180',
    city: 'Jungherrntal'
  },
  {
    postalCode: '3180',
    city: 'Lilienfeld'
  },
  {
    postalCode: '3180',
    city: 'Marktl'
  },
  {
    postalCode: '3180',
    city: 'Schrambach'
  },
  {
    postalCode: '3180',
    city: 'Stangental'
  },
  {
    postalCode: '3180',
    city: 'Vordereben'
  },
  {
    postalCode: '3180',
    city: 'Zögersbach'
  },
  {
    postalCode: '3183',
    city: 'Innerfahrafeld'
  },
  {
    postalCode: '3183',
    city: 'Außerfahrafeld'
  },
  {
    postalCode: '3183',
    city: 'Lehenrotte'
  },
  {
    postalCode: '3183',
    city: 'Moosbach'
  },
  {
    postalCode: '3184',
    city: 'Anthofrotte'
  },
  {
    postalCode: '3184',
    city: 'Pichlrotte'
  },
  {
    postalCode: '3184',
    city: 'Raxenbachrotte'
  },
  {
    postalCode: '3184',
    city: 'Schildbachrotte'
  },
  {
    postalCode: '3184',
    city: 'Steinbachrotte'
  },
  {
    postalCode: '3184',
    city: 'Traisenbachrotte'
  },
  {
    postalCode: '3184',
    city: 'Türnitz'
  },
  {
    postalCode: '3184',
    city: 'Weidenaurotte'
  },
  {
    postalCode: '3192',
    city: 'Andersbach'
  },
  {
    postalCode: '3192',
    city: 'Furthof'
  },
  {
    postalCode: '3192',
    city: 'Hofamt'
  },
  {
    postalCode: '3192',
    city: 'Hohenberg'
  },
  {
    postalCode: '3192',
    city: 'Innerfahrafeld'
  },
  {
    postalCode: '3192',
    city: 'Ebenwald'
  },
  {
    postalCode: '3193',
    city: 'Kernhof'
  },
  {
    postalCode: '3193',
    city: 'Mitterbach'
  },
  {
    postalCode: '3193',
    city: 'St. Aegyd am Neuwalde'
  },
  {
    postalCode: '3195',
    city: 'Kernhof'
  },
  {
    postalCode: '3195',
    city: 'Lahnsattel'
  },
  {
    postalCode: '3195',
    city: 'Ulreichsberg'
  },
  {
    postalCode: '3200',
    city: 'Dietmannsdorf'
  },
  {
    postalCode: '3200',
    city: 'Badendorf'
  },
  {
    postalCode: '3200',
    city: 'Baumgarten'
  },
  {
    postalCode: '3200',
    city: 'Ebersdorf'
  },
  {
    postalCode: '3200',
    city: 'Fridau'
  },
  {
    postalCode: '3200',
    city: 'Gasten'
  },
  {
    postalCode: '3200',
    city: 'Gattmannsdorf'
  },
  {
    postalCode: '3200',
    city: 'Gröben'
  },
  {
    postalCode: '3200',
    city: 'Grub'
  },
  {
    postalCode: '3200',
    city: 'Kotting'
  },
  {
    postalCode: '3200',
    city: 'Kuning'
  },
  {
    postalCode: '3200',
    city: 'Neustift'
  },
  {
    postalCode: '3200',
    city: 'Ober-Grafendorf'
  },
  {
    postalCode: '3200',
    city: 'Reitzing'
  },
  {
    postalCode: '3200',
    city: 'Rennersdorf'
  },
  {
    postalCode: '3200',
    city: 'Ritzersdorf'
  },
  {
    postalCode: '3200',
    city: 'Wantendorf'
  },
  {
    postalCode: '3200',
    city: 'Willersdorf'
  },
  {
    postalCode: '3200',
    city: 'Waasen'
  },
  {
    postalCode: '3200',
    city: 'Eck'
  },
  {
    postalCode: '3200',
    city: 'Edlitz'
  },
  {
    postalCode: '3200',
    city: 'Engelsdorf'
  },
  {
    postalCode: '3200',
    city: 'Klangen'
  },
  {
    postalCode: '3200',
    city: 'Luberg'
  },
  {
    postalCode: '3200',
    city: 'Mühlhofen'
  },
  {
    postalCode: '3200',
    city: 'Oed'
  },
  {
    postalCode: '3200',
    city: 'Weinburg'
  },
  {
    postalCode: '3202',
    city: 'Aigelsbach'
  },
  {
    postalCode: '3202',
    city: 'Grünau'
  },
  {
    postalCode: '3202',
    city: 'Grünsbach'
  },
  {
    postalCode: '3202',
    city: 'Hofstetten'
  },
  {
    postalCode: '3202',
    city: 'Kammerhof'
  },
  {
    postalCode: '3202',
    city: 'Mainburg'
  },
  {
    postalCode: '3202',
    city: 'Plambach'
  },
  {
    postalCode: '3202',
    city: 'Plambacheck'
  },
  {
    postalCode: '3203',
    city: 'Mainburg'
  },
  {
    postalCode: '3203',
    city: 'Plambach'
  },
  {
    postalCode: '3203',
    city: 'Laach'
  },
  {
    postalCode: '3203',
    city: 'Deutschbach'
  },
  {
    postalCode: '3203',
    city: 'Dorf-Au'
  },
  {
    postalCode: '3203',
    city: 'Königsbach'
  },
  {
    postalCode: '3203',
    city: 'Rabenstein an der Pielach'
  },
  {
    postalCode: '3203',
    city: 'Röhrenbach'
  },
  {
    postalCode: '3203',
    city: 'Steinklamm'
  },
  {
    postalCode: '3203',
    city: 'Tradigist'
  },
  {
    postalCode: '3203',
    city: 'Warth'
  },
  {
    postalCode: '3204',
    city: 'Hohenbrand'
  },
  {
    postalCode: '3204',
    city: 'Kirchberg an der Pielach'
  },
  {
    postalCode: '3204',
    city: 'Kirchberggegend'
  },
  {
    postalCode: '3204',
    city: 'Schloßgegend'
  },
  {
    postalCode: '3204',
    city: 'Schwerbachgegend'
  },
  {
    postalCode: '3204',
    city: 'Soisgegend'
  },
  {
    postalCode: '3204',
    city: 'Tradigistdorf'
  },
  {
    postalCode: '3204',
    city: 'Tradigistgegend'
  },
  {
    postalCode: '3204',
    city: 'Rehgrabengegend'
  },
  {
    postalCode: '3204',
    city: 'Schroffengegend'
  },
  {
    postalCode: '3204',
    city: 'Warth'
  },
  {
    postalCode: '3211',
    city: 'Dobersnigg'
  },
  {
    postalCode: '3211',
    city: 'Hammerlmühlgegend'
  },
  {
    postalCode: '3211',
    city: 'Loich'
  },
  {
    postalCode: '3211',
    city: 'Loicheckgegend'
  },
  {
    postalCode: '3211',
    city: 'Oedgegend'
  },
  {
    postalCode: '3211',
    city: 'Rehgrabengegend'
  },
  {
    postalCode: '3211',
    city: 'Schroffengegend'
  },
  {
    postalCode: '3211',
    city: 'Schwarzengrabengegend'
  },
  {
    postalCode: '3211',
    city: 'Siedlung'
  },
  {
    postalCode: '3212',
    city: 'Gstettengegend'
  },
  {
    postalCode: '3212',
    city: 'Taschlgrabenrotte'
  },
  {
    postalCode: '3212',
    city: 'Brunnrotte'
  },
  {
    postalCode: '3212',
    city: 'Finzenebengegend'
  },
  {
    postalCode: '3212',
    city: 'Grabschifterwald'
  },
  {
    postalCode: '3212',
    city: 'Guttenhofgegend'
  },
  {
    postalCode: '3212',
    city: 'Haslaurotte'
  },
  {
    postalCode: '3212',
    city: 'Hofrotte'
  },
  {
    postalCode: '3212',
    city: 'Loicheckgegend'
  },
  {
    postalCode: '3212',
    city: 'Schwarzenbachgegend'
  },
  {
    postalCode: '3212',
    city: 'Seerotte'
  },
  {
    postalCode: '3212',
    city: 'Staudachgegend'
  },
  {
    postalCode: '3212',
    city: 'Steinrotte'
  },
  {
    postalCode: '3213',
    city: 'Falkensteinrotte'
  },
  {
    postalCode: '3213',
    city: 'Fischbachmühlrotte'
  },
  {
    postalCode: '3213',
    city: 'Frankenfels'
  },
  {
    postalCode: '3213',
    city: 'Grasserrotte'
  },
  {
    postalCode: '3213',
    city: 'Gstettengegend'
  },
  {
    postalCode: '3213',
    city: 'Hofstadtgegend'
  },
  {
    postalCode: '3213',
    city: 'Karrotte'
  },
  {
    postalCode: '3213',
    city: 'Laubenbachgegend'
  },
  {
    postalCode: '3213',
    city: 'Lehengegend'
  },
  {
    postalCode: '3213',
    city: 'Markenschlagrotte'
  },
  {
    postalCode: '3213',
    city: 'Ödrotte'
  },
  {
    postalCode: '3213',
    city: 'Pernarotte'
  },
  {
    postalCode: '3213',
    city: 'Pielachleitengegend'
  },
  {
    postalCode: '3213',
    city: 'Rosenbühelrotte'
  },
  {
    postalCode: '3213',
    city: 'Taschlgrabenrotte'
  },
  {
    postalCode: '3213',
    city: 'Tiefgrabenrotte'
  },
  {
    postalCode: '3213',
    city: 'Übergangrotte'
  },
  {
    postalCode: '3213',
    city: 'Weißenburggegend'
  },
  {
    postalCode: '3213',
    city: 'Wiesrotte'
  },
  {
    postalCode: '3213',
    city: 'Hollenstein'
  },
  {
    postalCode: '3214',
    city: 'Laubenbachgegend'
  },
  {
    postalCode: '3214',
    city: 'Übergangrotte'
  },
  {
    postalCode: '3214',
    city: 'Am Sulzbichl'
  },
  {
    postalCode: '3214',
    city: 'Bergrotte'
  },
  {
    postalCode: '3214',
    city: 'Brandeben'
  },
  {
    postalCode: '3214',
    city: 'Brandgegend'
  },
  {
    postalCode: '3214',
    city: 'Buchberg'
  },
  {
    postalCode: '3214',
    city: 'Laubenbach'
  },
  {
    postalCode: '3214',
    city: 'Puchenstuben'
  },
  {
    postalCode: '3214',
    city: 'Sulzbichl'
  },
  {
    postalCode: '3214',
    city: 'Waldgegend'
  },
  {
    postalCode: '3214',
    city: 'Anger'
  },
  {
    postalCode: '3214',
    city: 'Kreuztanne'
  },
  {
    postalCode: '3214',
    city: 'Wohlfahrtsschlag'
  },
  {
    postalCode: '3221',
    city: 'Gösing an der Mariazeller Bahn'
  },
  {
    postalCode: '3222',
    city: 'Annarotte'
  },
  {
    postalCode: '3222',
    city: 'Haupttürnitzrotte'
  },
  {
    postalCode: '3222',
    city: 'Langseitenrotte'
  },
  {
    postalCode: '3222',
    city: 'Lassingrotte'
  },
  {
    postalCode: '3222',
    city: 'Ulreichsberg'
  },
  {
    postalCode: '3223',
    city: 'Langseitenrotte'
  },
  {
    postalCode: '3223',
    city: 'Lassingrotte'
  },
  {
    postalCode: '3223',
    city: 'Trübenbach-Gaming'
  },
  {
    postalCode: '3223',
    city: 'Mitterbach-Seerotte'
  },
  {
    postalCode: '3223',
    city: 'Gösing an der Mariazeller Bahn'
  },
  {
    postalCode: '3223',
    city: 'Schaflahn'
  },
  {
    postalCode: '3224',
    city: 'Josefsrotte'
  },
  {
    postalCode: '3224',
    city: 'Mitterbach-Seerotte'
  },
  {
    postalCode: '3231',
    city: 'Eigendorf'
  },
  {
    postalCode: '3231',
    city: 'Feilendorf'
  },
  {
    postalCode: '3231',
    city: 'Kainratsdorf'
  },
  {
    postalCode: '3231',
    city: 'Kleinsierning'
  },
  {
    postalCode: '3231',
    city: 'Linsberg'
  },
  {
    postalCode: '3231',
    city: 'Oberhofen'
  },
  {
    postalCode: '3231',
    city: 'Rammersdorf'
  },
  {
    postalCode: '3231',
    city: 'St. Margarethen an der Sierning'
  },
  {
    postalCode: '3231',
    city: 'Saudorf'
  },
  {
    postalCode: '3231',
    city: 'Türnau'
  },
  {
    postalCode: '3231',
    city: 'Unterradl'
  },
  {
    postalCode: '3231',
    city: 'Wieden'
  },
  {
    postalCode: '3231',
    city: 'Wilhersdorf'
  },
  {
    postalCode: '3232',
    city: 'Bischofstetten'
  },
  {
    postalCode: '3232',
    city: 'Buchgraben'
  },
  {
    postalCode: '3232',
    city: 'Dörfl'
  },
  {
    postalCode: '3232',
    city: 'Großa'
  },
  {
    postalCode: '3232',
    city: 'Grünwies'
  },
  {
    postalCode: '3232',
    city: 'Haag'
  },
  {
    postalCode: '3232',
    city: 'Haberg'
  },
  {
    postalCode: '3232',
    city: 'Hanau'
  },
  {
    postalCode: '3232',
    city: 'Hintergrub'
  },
  {
    postalCode: '3232',
    city: 'Mitterschildbach'
  },
  {
    postalCode: '3232',
    city: 'Neubing'
  },
  {
    postalCode: '3232',
    city: 'Niederbauern'
  },
  {
    postalCode: '3232',
    city: 'Oberschildbach'
  },
  {
    postalCode: '3232',
    city: 'Oberweg'
  },
  {
    postalCode: '3232',
    city: 'Rametzhofen'
  },
  {
    postalCode: '3232',
    city: 'Strohdorf'
  },
  {
    postalCode: '3232',
    city: 'Tonach'
  },
  {
    postalCode: '3232',
    city: 'Unterschildbach'
  },
  {
    postalCode: '3232',
    city: 'Unterweg'
  },
  {
    postalCode: '3232',
    city: 'Winkelsdorf'
  },
  {
    postalCode: '3232',
    city: 'Zauching'
  },
  {
    postalCode: '3233',
    city: 'Christenberg'
  },
  {
    postalCode: '3233',
    city: 'Sierning'
  },
  {
    postalCode: '3233',
    city: 'Grünsbach'
  },
  {
    postalCode: '3233',
    city: 'Braunöd'
  },
  {
    postalCode: '3233',
    city: 'Bühren'
  },
  {
    postalCode: '3233',
    city: 'Dörfl'
  },
  {
    postalCode: '3233',
    city: 'Dornhof'
  },
  {
    postalCode: '3233',
    city: 'Feld'
  },
  {
    postalCode: '3233',
    city: 'Fleischessen'
  },
  {
    postalCode: '3233',
    city: 'Fohra'
  },
  {
    postalCode: '3233',
    city: 'Fohrafeld'
  },
  {
    postalCode: '3233',
    city: 'Freyen'
  },
  {
    postalCode: '3233',
    city: 'Gartling'
  },
  {
    postalCode: '3233',
    city: 'Glosbach'
  },
  {
    postalCode: '3233',
    city: 'Graben'
  },
  {
    postalCode: '3233',
    city: 'Graben bei Haag'
  },
  {
    postalCode: '3233',
    city: 'Guglberg'
  },
  {
    postalCode: '3233',
    city: 'Hauersdorf'
  },
  {
    postalCode: '3233',
    city: 'Haxenöd'
  },
  {
    postalCode: '3233',
    city: 'Heinrichsberg'
  },
  {
    postalCode: '3233',
    city: 'Hohenbrand'
  },
  {
    postalCode: '3233',
    city: 'Hummelbach'
  },
  {
    postalCode: '3233',
    city: 'Kettenreith'
  },
  {
    postalCode: '3233',
    city: 'Kilb'
  },
  {
    postalCode: '3233',
    city: 'Kohlenberg'
  },
  {
    postalCode: '3233',
    city: 'Laach'
  },
  {
    postalCode: '3233',
    city: 'Maierhöfen'
  },
  {
    postalCode: '3233',
    city: 'Mallau'
  },
  {
    postalCode: '3233',
    city: 'Niederhofen'
  },
  {
    postalCode: '3233',
    city: 'Oberneuberg'
  },
  {
    postalCode: '3233',
    city: 'Panschach'
  },
  {
    postalCode: '3233',
    city: 'Petersberg'
  },
  {
    postalCode: '3233',
    city: 'Rametzberg'
  },
  {
    postalCode: '3233',
    city: 'Ranzenbach'
  },
  {
    postalCode: '3233',
    city: 'Ruttersdorf'
  },
  {
    postalCode: '3233',
    city: 'Schlögelsbach'
  },
  {
    postalCode: '3233',
    city: 'Schützen'
  },
  {
    postalCode: '3233',
    city: 'Taubenwang'
  },
  {
    postalCode: '3233',
    city: 'Teufelsdorf'
  },
  {
    postalCode: '3233',
    city: 'Umbach'
  },
  {
    postalCode: '3233',
    city: 'Unterneuberg'
  },
  {
    postalCode: '3233',
    city: 'Volkersdorf'
  },
  {
    postalCode: '3233',
    city: 'Waasen bei Kilb'
  },
  {
    postalCode: '3233',
    city: 'Wiesenöd'
  },
  {
    postalCode: '3233',
    city: 'Wötzling'
  },
  {
    postalCode: '3233',
    city: 'Massendorf'
  },
  {
    postalCode: '3240',
    city: 'Grub bei Kilb'
  },
  {
    postalCode: '3240',
    city: 'Unterschmidbach'
  },
  {
    postalCode: '3240',
    city: 'Artlehen'
  },
  {
    postalCode: '3240',
    city: 'Aichen'
  },
  {
    postalCode: '3240',
    city: 'Altenhofen'
  },
  {
    postalCode: '3240',
    city: 'Anzenbach'
  },
  {
    postalCode: '3240',
    city: 'Pichlreit'
  },
  {
    postalCode: '3240',
    city: 'Bodendorf'
  },
  {
    postalCode: '3240',
    city: 'Busendorf'
  },
  {
    postalCode: '3240',
    city: 'Dorna'
  },
  {
    postalCode: '3240',
    city: 'Fohra'
  },
  {
    postalCode: '3240',
    city: 'Fritzberg'
  },
  {
    postalCode: '3240',
    city: 'Gries'
  },
  {
    postalCode: '3240',
    city: 'Großaigen'
  },
  {
    postalCode: '3240',
    city: 'Hagberg'
  },
  {
    postalCode: '3240',
    city: 'Hörgstberg'
  },
  {
    postalCode: '3240',
    city: 'Hörsdorf'
  },
  {
    postalCode: '3240',
    city: 'Kälberhart'
  },
  {
    postalCode: '3240',
    city: 'Kleinaigen'
  },
  {
    postalCode: '3240',
    city: 'Kleinzell'
  },
  {
    postalCode: '3240',
    city: 'Lehen'
  },
  {
    postalCode: '3240',
    city: 'Loipersdorf'
  },
  {
    postalCode: '3240',
    city: 'Loitsbach'
  },
  {
    postalCode: '3240',
    city: 'Loitsdorf'
  },
  {
    postalCode: '3240',
    city: 'Mank'
  },
  {
    postalCode: '3240',
    city: 'Münichhofen'
  },
  {
    postalCode: '3240',
    city: 'Nacht'
  },
  {
    postalCode: '3240',
    city: 'Oberschmidbach'
  },
  {
    postalCode: '3240',
    city: 'Pölla'
  },
  {
    postalCode: '3240',
    city: 'Poppendorf'
  },
  {
    postalCode: '3240',
    city: 'Rührsdorf'
  },
  {
    postalCode: '3240',
    city: 'St. Frein'
  },
  {
    postalCode: '3240',
    city: 'St. Haus'
  },
  {
    postalCode: '3240',
    city: 'Simonsberg'
  },
  {
    postalCode: '3240',
    city: 'Strannersdorf'
  },
  {
    postalCode: '3240',
    city: 'Wies'
  },
  {
    postalCode: '3240',
    city: 'Wolkersdorf'
  },
  {
    postalCode: '3240',
    city: 'Ritzenberg'
  },
  {
    postalCode: '3241',
    city: 'Außerreith'
  },
  {
    postalCode: '3241',
    city: 'Furth'
  },
  {
    postalCode: '3241',
    city: 'Innerreith'
  },
  {
    postalCode: '3241',
    city: 'Kimming'
  },
  {
    postalCode: '3241',
    city: 'Kirnberg an der Mank'
  },
  {
    postalCode: '3241',
    city: 'Kroisbach'
  },
  {
    postalCode: '3241',
    city: 'Maierhöfen'
  },
  {
    postalCode: '3241',
    city: 'Obergraben'
  },
  {
    postalCode: '3241',
    city: 'Oed an der Mank'
  },
  {
    postalCode: '3241',
    city: 'Pöllaberg'
  },
  {
    postalCode: '3241',
    city: 'Ranitzhof'
  },
  {
    postalCode: '3241',
    city: 'Sattlehen'
  },
  {
    postalCode: '3241',
    city: 'Strohhof'
  },
  {
    postalCode: '3241',
    city: 'Untergraben'
  },
  {
    postalCode: '3241',
    city: 'Wolfsbach'
  },
  {
    postalCode: '3241',
    city: 'Wolfsmath'
  },
  {
    postalCode: '3241',
    city: 'Unterdörfl'
  },
  {
    postalCode: '3241',
    city: 'Hochstraß'
  },
  {
    postalCode: '3242',
    city: 'Zehethof'
  },
  {
    postalCode: '3242',
    city: 'Gärtenberg'
  },
  {
    postalCode: '3242',
    city: 'Dachsberg'
  },
  {
    postalCode: '3242',
    city: 'Ramsau'
  },
  {
    postalCode: '3242',
    city: 'Haberleiten'
  },
  {
    postalCode: '3242',
    city: 'Hinterberg'
  },
  {
    postalCode: '3242',
    city: 'Hinterholz'
  },
  {
    postalCode: '3242',
    city: 'Mühlgraben'
  },
  {
    postalCode: '3242',
    city: 'Plankenstein'
  },
  {
    postalCode: '3242',
    city: 'Rosenbichl'
  },
  {
    postalCode: '3242',
    city: 'Schwaighof'
  },
  {
    postalCode: '3242',
    city: 'Straß bei Texing'
  },
  {
    postalCode: '3242',
    city: 'Walzberg'
  },
  {
    postalCode: '3242',
    city: 'Weißenbach'
  },
  {
    postalCode: '3242',
    city: 'Fischbach'
  },
  {
    postalCode: '3242',
    city: 'Großmaierhof'
  },
  {
    postalCode: '3242',
    city: 'St. Gotthard'
  },
  {
    postalCode: '3242',
    city: 'Altendorf'
  },
  {
    postalCode: '3242',
    city: 'Hinterleiten'
  },
  {
    postalCode: '3242',
    city: 'Kleinmaierhof'
  },
  {
    postalCode: '3242',
    city: 'Panholz'
  },
  {
    postalCode: '3242',
    city: 'Reinöd'
  },
  {
    postalCode: '3242',
    city: 'Sonnleithen'
  },
  {
    postalCode: '3242',
    city: 'Steingrub'
  },
  {
    postalCode: '3242',
    city: 'Texing'
  },
  {
    postalCode: '3242',
    city: 'Bach'
  },
  {
    postalCode: '3243',
    city: 'Hofstetten'
  },
  {
    postalCode: '3243',
    city: 'Rottenhof'
  },
  {
    postalCode: '3243',
    city: 'Altenhofen'
  },
  {
    postalCode: '3243',
    city: 'Au'
  },
  {
    postalCode: '3243',
    city: 'Brandstatt bei Au'
  },
  {
    postalCode: '3243',
    city: 'Brandstatt bei Oed'
  },
  {
    postalCode: '3243',
    city: 'Eisguggen'
  },
  {
    postalCode: '3243',
    city: 'Eselsteiggraben'
  },
  {
    postalCode: '3243',
    city: 'Fachelberg'
  },
  {
    postalCode: '3243',
    city: 'Gassen'
  },
  {
    postalCode: '3243',
    city: 'Grillenreith'
  },
  {
    postalCode: '3243',
    city: 'Großweichselbach'
  },
  {
    postalCode: '3243',
    city: 'Haindorf'
  },
  {
    postalCode: '3243',
    city: 'Haslach'
  },
  {
    postalCode: '3243',
    city: 'Hohenreith'
  },
  {
    postalCode: '3243',
    city: 'Hub an der Mank'
  },
  {
    postalCode: '3243',
    city: 'Kleinweichselbach'
  },
  {
    postalCode: '3243',
    city: 'Kühberg'
  },
  {
    postalCode: '3243',
    city: 'Lehenleiten'
  },
  {
    postalCode: '3243',
    city: 'Lunzen'
  },
  {
    postalCode: '3243',
    city: 'Neusiedl'
  },
  {
    postalCode: '3243',
    city: 'Oed bei Haslach'
  },
  {
    postalCode: '3243',
    city: 'Pöllendorf'
  },
  {
    postalCode: '3243',
    city: 'Pühra'
  },
  {
    postalCode: '3243',
    city: 'Reith bei Vornholz'
  },
  {
    postalCode: '3243',
    city: 'Reith bei Weichselbach'
  },
  {
    postalCode: '3243',
    city: 'Rinn'
  },
  {
    postalCode: '3243',
    city: 'Ritzengrub'
  },
  {
    postalCode: '3243',
    city: 'Sandeben'
  },
  {
    postalCode: '3243',
    city: 'Schönbuch'
  },
  {
    postalCode: '3243',
    city: 'Schweining'
  },
  {
    postalCode: '3243',
    city: 'Seimetzbach'
  },
  {
    postalCode: '3243',
    city: 'Steghof'
  },
  {
    postalCode: '3243',
    city: 'Steinbach'
  },
  {
    postalCode: '3243',
    city: 'Thal'
  },
  {
    postalCode: '3243',
    city: 'Urbach'
  },
  {
    postalCode: '3243',
    city: 'Vornholz'
  },
  {
    postalCode: '3243',
    city: 'Wegscheid'
  },
  {
    postalCode: '3243',
    city: 'Ziegelstadl'
  },
  {
    postalCode: '3243',
    city: 'Aichbach'
  },
  {
    postalCode: '3243',
    city: 'Apfaltersbach'
  },
  {
    postalCode: '3243',
    city: 'Dangelsbach'
  },
  {
    postalCode: '3243',
    city: 'Diesendorf'
  },
  {
    postalCode: '3243',
    city: 'Geigenberg'
  },
  {
    postalCode: '3243',
    city: 'Grimmegg'
  },
  {
    postalCode: '3243',
    city: 'Grub bei Harbach'
  },
  {
    postalCode: '3243',
    city: 'Harbach'
  },
  {
    postalCode: '3243',
    city: 'Hochstraß'
  },
  {
    postalCode: '3243',
    city: 'Hörgerstall'
  },
  {
    postalCode: '3243',
    city: 'Kerndl'
  },
  {
    postalCode: '3243',
    city: 'Lachau'
  },
  {
    postalCode: '3243',
    city: 'St. Leonhard am Forst'
  },
  {
    postalCode: '3243',
    city: 'Straß'
  },
  {
    postalCode: '3244',
    city: 'Baulanden'
  },
  {
    postalCode: '3244',
    city: 'Brunnwiesen'
  },
  {
    postalCode: '3244',
    city: 'Etzen'
  },
  {
    postalCode: '3244',
    city: 'Fittenberg'
  },
  {
    postalCode: '3244',
    city: 'Fohregg'
  },
  {
    postalCode: '3244',
    city: 'Geretzbach'
  },
  {
    postalCode: '3244',
    city: 'Graben'
  },
  {
    postalCode: '3244',
    city: 'Grabenegg'
  },
  {
    postalCode: '3244',
    city: 'Grub'
  },
  {
    postalCode: '3244',
    city: 'Hofstetten'
  },
  {
    postalCode: '3244',
    city: 'Hohentann'
  },
  {
    postalCode: '3244',
    city: 'Hub'
  },
  {
    postalCode: '3244',
    city: 'Kagelsberg'
  },
  {
    postalCode: '3244',
    city: 'Kalcha'
  },
  {
    postalCode: '3244',
    city: 'Koth'
  },
  {
    postalCode: '3244',
    city: 'Kronberg'
  },
  {
    postalCode: '3244',
    city: 'Kühberg'
  },
  {
    postalCode: '3244',
    city: 'Lasserthal'
  },
  {
    postalCode: '3244',
    city: 'Lehen'
  },
  {
    postalCode: '3244',
    city: 'Naspern'
  },
  {
    postalCode: '3244',
    city: 'Ockert'
  },
  {
    postalCode: '3244',
    city: 'Oed'
  },
  {
    postalCode: '3244',
    city: 'Rainberg'
  },
  {
    postalCode: '3244',
    city: 'Riegers'
  },
  {
    postalCode: '3244',
    city: 'Rottenhof'
  },
  {
    postalCode: '3244',
    city: 'Ruprechtshofen'
  },
  {
    postalCode: '3244',
    city: 'Schlatten'
  },
  {
    postalCode: '3244',
    city: 'Simhof'
  },
  {
    postalCode: '3244',
    city: 'Sinhof'
  },
  {
    postalCode: '3244',
    city: 'Weghof'
  },
  {
    postalCode: '3244',
    city: 'Wies'
  },
  {
    postalCode: '3244',
    city: 'Zinsenhof'
  },
  {
    postalCode: '3244',
    city: 'Zwerbach'
  },
  {
    postalCode: '3244',
    city: 'Anzenberg'
  },
  {
    postalCode: '3250',
    city: 'Gumprechtsberg'
  },
  {
    postalCode: '3250',
    city: 'Wieselburg'
  },
  {
    postalCode: '3250',
    city: 'Bauxberg'
  },
  {
    postalCode: '3250',
    city: 'Berging'
  },
  {
    postalCode: '3250',
    city: 'Bodensdorf'
  },
  {
    postalCode: '3250',
    city: 'Brandstetten'
  },
  {
    postalCode: '3250',
    city: 'Brunning'
  },
  {
    postalCode: '3250',
    city: 'Dürnbach'
  },
  {
    postalCode: '3250',
    city: 'Forst am Berg'
  },
  {
    postalCode: '3250',
    city: 'Galtbrunn'
  },
  {
    postalCode: '3250',
    city: 'Großa'
  },
  {
    postalCode: '3250',
    city: 'Grub'
  },
  {
    postalCode: '3250',
    city: 'Gumprechtsfelden'
  },
  {
    postalCode: '3250',
    city: 'Haag'
  },
  {
    postalCode: '3250',
    city: 'Hart'
  },
  {
    postalCode: '3250',
    city: 'Holzhäuseln'
  },
  {
    postalCode: '3250',
    city: 'Kaswinkel'
  },
  {
    postalCode: '3250',
    city: 'Kratzenberg'
  },
  {
    postalCode: '3250',
    city: 'Krügling'
  },
  {
    postalCode: '3250',
    city: 'Laimstetten'
  },
  {
    postalCode: '3250',
    city: 'Marbach an der Kleinen Erlauf'
  },
  {
    postalCode: '3250',
    city: 'Moos'
  },
  {
    postalCode: '3250',
    city: 'Mühling'
  },
  {
    postalCode: '3250',
    city: 'Neumühl'
  },
  {
    postalCode: '3250',
    city: 'Oed am Seichten Graben'
  },
  {
    postalCode: '3250',
    city: 'Plaika'
  },
  {
    postalCode: '3250',
    city: 'Schadendorf'
  },
  {
    postalCode: '3250',
    city: 'Sill'
  },
  {
    postalCode: '3250',
    city: 'Ströblitz'
  },
  {
    postalCode: '3250',
    city: 'Unteretzerstetten'
  },
  {
    postalCode: '3250',
    city: 'Wechling'
  },
  {
    postalCode: '3250',
    city: 'Weinzierl'
  },
  {
    postalCode: '3251',
    city: 'Ameishaufen'
  },
  {
    postalCode: '3251',
    city: 'Edelbach bei Purgstall'
  },
  {
    postalCode: '3251',
    city: 'Erb'
  },
  {
    postalCode: '3251',
    city: 'Feichsen'
  },
  {
    postalCode: '3251',
    city: 'Föhrenhain'
  },
  {
    postalCode: '3251',
    city: 'Gaisberg'
  },
  {
    postalCode: '3251',
    city: 'Galtbrunn'
  },
  {
    postalCode: '3251',
    city: 'Haag'
  },
  {
    postalCode: '3251',
    city: 'Harmersdorf'
  },
  {
    postalCode: '3251',
    city: 'Heidegrund'
  },
  {
    postalCode: '3251',
    city: 'Hochrieß'
  },
  {
    postalCode: '3251',
    city: 'Höfl'
  },
  {
    postalCode: '3251',
    city: 'Koth'
  },
  {
    postalCode: '3251',
    city: 'Kroißenberg'
  },
  {
    postalCode: '3251',
    city: 'Mayerhof'
  },
  {
    postalCode: '3251',
    city: 'Nottendorf'
  },
  {
    postalCode: '3251',
    city: 'Söllingerwald'
  },
  {
    postalCode: '3251',
    city: 'Öd bei Purgstall'
  },
  {
    postalCode: '3251',
    city: 'Petzelsdorf'
  },
  {
    postalCode: '3251',
    city: 'Purgstall'
  },
  {
    postalCode: '3251',
    city: 'Reichersau'
  },
  {
    postalCode: '3251',
    city: 'Rogatsboden'
  },
  {
    postalCode: '3251',
    city: 'Schauboden'
  },
  {
    postalCode: '3251',
    city: 'Sölling'
  },
  {
    postalCode: '3251',
    city: 'Stock'
  },
  {
    postalCode: '3251',
    city: 'Unternberg'
  },
  {
    postalCode: '3251',
    city: 'Weigstatt'
  },
  {
    postalCode: '3251',
    city: 'Weinberg'
  },
  {
    postalCode: '3251',
    city: 'Zehnbach'
  },
  {
    postalCode: '3251',
    city: 'Gimpering'
  },
  {
    postalCode: '3251',
    city: 'Pögling'
  },
  {
    postalCode: '3251',
    city: 'Gumprechtsfelden'
  },
  {
    postalCode: '3252',
    city: 'Bergland'
  },
  {
    postalCode: '3252',
    city: 'Landfriedstetten'
  },
  {
    postalCode: '3252',
    city: 'Plaika'
  },
  {
    postalCode: '3252',
    city: 'Holzing'
  },
  {
    postalCode: '3252',
    city: 'Gumprechtsberg'
  },
  {
    postalCode: '3252',
    city: 'Petzenkirchen'
  },
  {
    postalCode: '3252',
    city: 'Reisenhof'
  },
  {
    postalCode: '3252',
    city: 'Dürnbach'
  },
  {
    postalCode: '3252',
    city: 'Oed beim Roten Kreuz'
  },
  {
    postalCode: '3252',
    city: 'Pellendorf'
  },
  {
    postalCode: '3253',
    city: 'Plaika'
  },
  {
    postalCode: '3253',
    city: 'Ratzenberg'
  },
  {
    postalCode: '3253',
    city: 'Wohlfahrtsbrunn'
  },
  {
    postalCode: '3253',
    city: 'Erlauf'
  },
  {
    postalCode: '3253',
    city: 'Harlanden'
  },
  {
    postalCode: '3253',
    city: 'Knocking'
  },
  {
    postalCode: '3253',
    city: 'Wolfring'
  },
  {
    postalCode: '3253',
    city: 'Niederndorf'
  },
  {
    postalCode: '3253',
    city: 'Maierhofen'
  },
  {
    postalCode: '3253',
    city: 'Ofling'
  },
  {
    postalCode: '3253',
    city: 'Rampersdorf'
  },
  {
    postalCode: '3253',
    city: 'Arb'
  },
  {
    postalCode: '3261',
    city: 'Götzwang'
  },
  {
    postalCode: '3261',
    city: 'Altenhof'
  },
  {
    postalCode: '3261',
    city: 'Amesbach'
  },
  {
    postalCode: '3261',
    city: 'Brandstatt'
  },
  {
    postalCode: '3261',
    city: 'Dürnbach'
  },
  {
    postalCode: '3261',
    city: 'Edla'
  },
  {
    postalCode: '3261',
    city: 'Edelbach'
  },
  {
    postalCode: '3261',
    city: 'Ernegg'
  },
  {
    postalCode: '3261',
    city: 'Felberach'
  },
  {
    postalCode: '3261',
    city: 'Haberg'
  },
  {
    postalCode: '3261',
    city: 'Hausberg'
  },
  {
    postalCode: '3261',
    city: 'Kleinreith'
  },
  {
    postalCode: '3261',
    city: 'Knolling'
  },
  {
    postalCode: '3261',
    city: 'Lonitzberg'
  },
  {
    postalCode: '3261',
    city: 'Oberstampfing'
  },
  {
    postalCode: '3261',
    city: 'Ochsenbach'
  },
  {
    postalCode: '3261',
    city: 'Oedt'
  },
  {
    postalCode: '3261',
    city: 'Oed bei Ernegg'
  },
  {
    postalCode: '3261',
    city: 'Reith bei Weinberg'
  },
  {
    postalCode: '3261',
    city: 'Schollödt'
  },
  {
    postalCode: '3261',
    city: 'Steinakirchen am Forst'
  },
  {
    postalCode: '3261',
    city: 'Straß'
  },
  {
    postalCode: '3261',
    city: 'Stritzling'
  },
  {
    postalCode: '3261',
    city: 'Unterstampfing'
  },
  {
    postalCode: '3261',
    city: 'Windpassing'
  },
  {
    postalCode: '3261',
    city: 'Zehetgrub'
  },
  {
    postalCode: '3261',
    city: 'Zehethof'
  },
  {
    postalCode: '3261',
    city: 'Berg'
  },
  {
    postalCode: '3261',
    city: 'Hofweid'
  },
  {
    postalCode: '3261',
    city: 'Kaisitzberg'
  },
  {
    postalCode: '3261',
    city: 'Nebetenberg'
  },
  {
    postalCode: '3261',
    city: 'Pyhrafeld'
  },
  {
    postalCode: '3261',
    city: 'Schlott'
  },
  {
    postalCode: '3261',
    city: 'Etzerstetten'
  },
  {
    postalCode: '3261',
    city: 'Figelsberg'
  },
  {
    postalCode: '3261',
    city: 'Klein-Erlauf'
  },
  {
    postalCode: '3261',
    city: 'Loising'
  },
  {
    postalCode: '3261',
    city: 'Stetten'
  },
  {
    postalCode: '3261',
    city: 'Thorwarting'
  },
  {
    postalCode: '3261',
    city: 'Thurhofglasen'
  },
  {
    postalCode: '3261',
    city: 'Wolfpassing'
  },
  {
    postalCode: '3261',
    city: 'Zarnsdorf'
  },
  {
    postalCode: '3262',
    city: 'Kerschenberg'
  },
  {
    postalCode: '3262',
    city: 'Lonitzberg'
  },
  {
    postalCode: '3262',
    city: 'Ewixen'
  },
  {
    postalCode: '3262',
    city: 'Griesperwarth'
  },
  {
    postalCode: '3262',
    city: 'Grieswang'
  },
  {
    postalCode: '3262',
    city: 'Höfling'
  },
  {
    postalCode: '3262',
    city: 'Lehmgstetten'
  },
  {
    postalCode: '3262',
    city: 'Mitterberg'
  },
  {
    postalCode: '3262',
    city: 'Reidlingberg'
  },
  {
    postalCode: '3262',
    city: 'Reidlingdorf'
  },
  {
    postalCode: '3262',
    city: 'Reitering'
  },
  {
    postalCode: '3262',
    city: 'Thurhofwang'
  },
  {
    postalCode: '3262',
    city: 'Wang'
  },
  {
    postalCode: '3263',
    city: 'Franzenreith'
  },
  {
    postalCode: '3263',
    city: 'Graben'
  },
  {
    postalCode: '3263',
    city: 'Hinterleiten'
  },
  {
    postalCode: '3263',
    city: 'Hochkoglberg'
  },
  {
    postalCode: '3263',
    city: 'Mitterberg'
  },
  {
    postalCode: '3263',
    city: 'Perwarth'
  },
  {
    postalCode: '3263',
    city: 'Puchberg bei Randegg'
  },
  {
    postalCode: '3263',
    city: 'Randegg'
  },
  {
    postalCode: '3263',
    city: 'Schliefau'
  },
  {
    postalCode: '3263',
    city: 'Steinholz'
  },
  {
    postalCode: '3263',
    city: 'St. Leonhard am Wald'
  },
  {
    postalCode: '3264',
    city: 'Brettl-Gaming'
  },
  {
    postalCode: '3264',
    city: 'Gresten'
  },
  {
    postalCode: '3264',
    city: 'Ybbsbachamt'
  },
  {
    postalCode: '3264',
    city: 'Oberamt'
  },
  {
    postalCode: '3264',
    city: 'Obergut'
  },
  {
    postalCode: '3264',
    city: 'Schadneramt'
  },
  {
    postalCode: '3264',
    city: 'Unteramt'
  },
  {
    postalCode: '3264',
    city: 'Franzenreith'
  },
  {
    postalCode: '3264',
    city: 'Hinterleiten'
  },
  {
    postalCode: '3264',
    city: 'Buchberg'
  },
  {
    postalCode: '3264',
    city: 'Kerschenberg'
  },
  {
    postalCode: '3264',
    city: 'Reinsberg'
  },
  {
    postalCode: '3264',
    city: 'Robitzboden'
  },
  {
    postalCode: '3264',
    city: 'Schaitten'
  },
  {
    postalCode: '3270',
    city: 'Brandstatt'
  },
  {
    postalCode: '3270',
    city: 'Fürteben'
  },
  {
    postalCode: '3270',
    city: 'Ginning'
  },
  {
    postalCode: '3270',
    city: 'Ginselberg'
  },
  {
    postalCode: '3270',
    city: 'Heuberg'
  },
  {
    postalCode: '3270',
    city: 'Hochbruck'
  },
  {
    postalCode: '3270',
    city: 'Lueggraben'
  },
  {
    postalCode: '3270',
    city: 'Miesenbach'
  },
  {
    postalCode: '3270',
    city: 'Neustift'
  },
  {
    postalCode: '3270',
    city: 'Saffen'
  },
  {
    postalCode: '3270',
    city: 'Scheibbs'
  },
  {
    postalCode: '3270',
    city: 'Scheibbsbach'
  },
  {
    postalCode: '3270',
    city: 'Schöllgraben'
  },
  {
    postalCode: '3270',
    city: 'Gärtenberg'
  },
  {
    postalCode: '3281',
    city: 'Grub'
  },
  {
    postalCode: '3281',
    city: 'Pledichen'
  },
  {
    postalCode: '3281',
    city: 'Altenmarkt'
  },
  {
    postalCode: '3281',
    city: 'Bach'
  },
  {
    postalCode: '3281',
    city: 'Baumbach'
  },
  {
    postalCode: '3281',
    city: 'Diendorf'
  },
  {
    postalCode: '3281',
    city: 'Dörfl'
  },
  {
    postalCode: '3281',
    city: 'Dürrockert'
  },
  {
    postalCode: '3281',
    city: 'Eck'
  },
  {
    postalCode: '3281',
    city: 'Edlach'
  },
  {
    postalCode: '3281',
    city: 'Ganz'
  },
  {
    postalCode: '3281',
    city: 'Gries'
  },
  {
    postalCode: '3281',
    city: 'Gstetten'
  },
  {
    postalCode: '3281',
    city: 'Hameth'
  },
  {
    postalCode: '3281',
    city: 'Hasenberg'
  },
  {
    postalCode: '3281',
    city: 'Holzwies'
  },
  {
    postalCode: '3281',
    city: 'Koppendorf'
  },
  {
    postalCode: '3281',
    city: 'Lehen'
  },
  {
    postalCode: '3281',
    city: 'Lingheim'
  },
  {
    postalCode: '3281',
    city: 'Listberg'
  },
  {
    postalCode: '3281',
    city: 'Maierhof'
  },
  {
    postalCode: '3281',
    city: 'Melk'
  },
  {
    postalCode: '3281',
    city: 'Oberdörfl'
  },
  {
    postalCode: '3281',
    city: 'Oberhub'
  },
  {
    postalCode: '3281',
    city: 'Oberndorf an der Melk'
  },
  {
    postalCode: '3281',
    city: 'Oberschweinz'
  },
  {
    postalCode: '3281',
    city: 'Ofenbach'
  },
  {
    postalCode: '3281',
    city: 'Perwarth'
  },
  {
    postalCode: '3281',
    city: 'Pfoisau'
  },
  {
    postalCode: '3281',
    city: 'Reitl'
  },
  {
    postalCode: '3281',
    city: 'Rinn'
  },
  {
    postalCode: '3281',
    city: 'Schachau'
  },
  {
    postalCode: '3281',
    city: 'Scheibenbach'
  },
  {
    postalCode: '3281',
    city: 'Scheibenberg'
  },
  {
    postalCode: '3281',
    city: 'Steg'
  },
  {
    postalCode: '3281',
    city: 'Straß'
  },
  {
    postalCode: '3281',
    city: 'Strauchen'
  },
  {
    postalCode: '3281',
    city: 'Sulzbach'
  },
  {
    postalCode: '3281',
    city: 'Unterhub'
  },
  {
    postalCode: '3281',
    city: 'Unterschweinz'
  },
  {
    postalCode: '3281',
    city: 'Waasen'
  },
  {
    postalCode: '3281',
    city: 'Weg'
  },
  {
    postalCode: '3281',
    city: 'Weissee'
  },
  {
    postalCode: '3281',
    city: 'Wiedenhof'
  },
  {
    postalCode: '3281',
    city: 'Wies'
  },
  {
    postalCode: '3281',
    city: 'Wildengraben'
  },
  {
    postalCode: '3281',
    city: 'Wildenmaierhof'
  },
  {
    postalCode: '3281',
    city: 'Zehethof'
  },
  {
    postalCode: '3281',
    city: 'Zimmerau'
  },
  {
    postalCode: '3281',
    city: 'Furth'
  },
  {
    postalCode: '3281',
    city: 'Hörmannsberg'
  },
  {
    postalCode: '3282',
    city: 'Gärtenberg'
  },
  {
    postalCode: '3282',
    city: 'Kreuzfeld'
  },
  {
    postalCode: '3282',
    city: 'Wiesmühl'
  },
  {
    postalCode: '3282',
    city: 'Ahornleiten'
  },
  {
    postalCode: '3282',
    city: 'Bach'
  },
  {
    postalCode: '3282',
    city: 'Bichl'
  },
  {
    postalCode: '3282',
    city: 'Dachsberg'
  },
  {
    postalCode: '3282',
    city: 'Forsthub'
  },
  {
    postalCode: '3282',
    city: 'Gries'
  },
  {
    postalCode: '3282',
    city: 'Kandelsberg'
  },
  {
    postalCode: '3282',
    city: 'Kröll'
  },
  {
    postalCode: '3282',
    city: 'Maierhof'
  },
  {
    postalCode: '3282',
    city: 'Mitteröd'
  },
  {
    postalCode: '3282',
    city: 'Oedwies'
  },
  {
    postalCode: '3282',
    city: 'St. Georgen an der Leys'
  },
  {
    postalCode: '3282',
    city: 'Schießer'
  },
  {
    postalCode: '3282',
    city: 'Windhag'
  },
  {
    postalCode: '3282',
    city: 'Zwickelsberg'
  },
  {
    postalCode: '3283',
    city: 'Fürteben'
  },
  {
    postalCode: '3283',
    city: 'Miesenbach'
  },
  {
    postalCode: '3283',
    city: 'Neubruck'
  },
  {
    postalCode: '3283',
    city: 'Anger'
  },
  {
    postalCode: '3283',
    city: 'Gabel'
  },
  {
    postalCode: '3283',
    city: 'Gärtenberg'
  },
  {
    postalCode: '3283',
    city: 'Gnadenberg'
  },
  {
    postalCode: '3283',
    city: 'Grafenmühl'
  },
  {
    postalCode: '3283',
    city: 'Gruft'
  },
  {
    postalCode: '3283',
    city: 'Hochreith'
  },
  {
    postalCode: '3283',
    city: 'Hollenstein'
  },
  {
    postalCode: '3283',
    city: 'Kreuztanne'
  },
  {
    postalCode: '3283',
    city: 'St. Anton an der Jeßnitz'
  },
  {
    postalCode: '3283',
    city: 'Wohlfahrtsschlag'
  },
  {
    postalCode: '3291',
    city: 'Kienberg'
  },
  {
    postalCode: '3292',
    city: 'Altenreith'
  },
  {
    postalCode: '3292',
    city: 'Brettl-Gaming'
  },
  {
    postalCode: '3292',
    city: 'Gaming'
  },
  {
    postalCode: '3292',
    city: 'Gamingrotte'
  },
  {
    postalCode: '3292',
    city: 'Hofrotte'
  },
  {
    postalCode: '3292',
    city: 'Mitterau'
  },
  {
    postalCode: '3292',
    city: 'Pockau'
  },
  {
    postalCode: '3292',
    city: 'Steinwand'
  },
  {
    postalCode: '3292',
    city: 'Zürner'
  },
  {
    postalCode: '3292',
    city: 'Anger'
  },
  {
    postalCode: '3293',
    city: 'Gaming'
  },
  {
    postalCode: '3293',
    city: 'Polzberg'
  },
  {
    postalCode: '3293',
    city: 'Lunz am See'
  },
  {
    postalCode: '3294',
    city: 'Holzhüttenboden'
  },
  {
    postalCode: '3294',
    city: 'Lackenhof'
  },
  {
    postalCode: '3294',
    city: 'Langau'
  },
  {
    postalCode: '3294',
    city: 'Maierhöfen'
  },
  {
    postalCode: '3294',
    city: 'Neuhaus-Langau'
  },
  {
    postalCode: '3294',
    city: 'Polzberg'
  },
  {
    postalCode: '3294',
    city: 'Taschelbach-Langau'
  },
  {
    postalCode: '3294',
    city: 'Grünau'
  },
  {
    postalCode: '3295',
    city: 'Lackenhof'
  },
  {
    postalCode: '3295',
    city: 'Nestelberg-Lackenhof'
  },
  {
    postalCode: '3295',
    city: 'Polzberg'
  },
  {
    postalCode: '3295',
    city: 'Steinwand'
  },
  {
    postalCode: '3300',
    city: 'Amstetten'
  },
  {
    postalCode: '3300',
    city: 'Schönbichl'
  },
  {
    postalCode: '3300',
    city: 'Mauer bei Amstetten'
  },
  {
    postalCode: '3300',
    city: 'Edla'
  },
  {
    postalCode: '3300',
    city: 'Preinsbach'
  },
  {
    postalCode: '3300',
    city: 'Ardagger Stift'
  },
  {
    postalCode: '3300',
    city: 'Stephanshart'
  },
  {
    postalCode: '3300',
    city: 'Greinsfurth'
  },
  {
    postalCode: '3300',
    city: 'Seisenegg'
  },
  {
    postalCode: '3300',
    city: 'Haag Dorf'
  },
  {
    postalCode: '3300',
    city: 'Winklarn'
  },
  {
    postalCode: '3300',
    city: 'Zeillern'
  },
  {
    postalCode: '3304',
    city: 'Hart'
  },
  {
    postalCode: '3304',
    city: 'Hermannsdorf'
  },
  {
    postalCode: '3304',
    city: 'Krahof'
  },
  {
    postalCode: '3304',
    city: 'Leutzmannsdorf'
  },
  {
    postalCode: '3304',
    city: 'Matzendorf'
  },
  {
    postalCode: '3304',
    city: 'St. Georgen am Ybbsfelde'
  },
  {
    postalCode: '3311',
    city: 'Amstetten'
  },
  {
    postalCode: '3311',
    city: 'Edla'
  },
  {
    postalCode: '3311',
    city: 'Zeillern'
  },
  {
    postalCode: '3312',
    city: 'Oed'
  },
  {
    postalCode: '3312',
    city: 'Öhling'
  },
  {
    postalCode: '3312',
    city: 'Igelschwang'
  },
  {
    postalCode: '3312',
    city: 'Ried'
  },
  {
    postalCode: '3312',
    city: 'Schweinberg'
  },
  {
    postalCode: '3312',
    city: 'Zeillern'
  },
  {
    postalCode: '3313',
    city: 'Igelschwang'
  },
  {
    postalCode: '3313',
    city: 'Ried'
  },
  {
    postalCode: '3313',
    city: 'Schweinberg'
  },
  {
    postalCode: '3313',
    city: 'Wallsee'
  },
  {
    postalCode: '3313',
    city: 'Zeillern'
  },
  {
    postalCode: '3314',
    city: 'Au'
  },
  {
    postalCode: '3314',
    city: 'Limbach'
  },
  {
    postalCode: '3314',
    city: 'Ottendorf'
  },
  {
    postalCode: '3314',
    city: 'Ramsau'
  },
  {
    postalCode: '3314',
    city: 'Strengberg'
  },
  {
    postalCode: '3314',
    city: 'Thürnbuch'
  },
  {
    postalCode: '3321',
    city: 'Ardagger Markt'
  },
  {
    postalCode: '3321',
    city: 'Ardagger Stift'
  },
  {
    postalCode: '3321',
    city: 'Kollmitzberg'
  },
  {
    postalCode: '3321',
    city: 'Stephanshart'
  },
  {
    postalCode: '3321',
    city: 'Zeillern'
  },
  {
    postalCode: '3322',
    city: 'Ardagger Stift'
  },
  {
    postalCode: '3322',
    city: 'Kollmitzberg'
  },
  {
    postalCode: '3322',
    city: 'Hainstetten'
  },
  {
    postalCode: '3322',
    city: 'Seisenegg'
  },
  {
    postalCode: '3322',
    city: 'Viehdorf'
  },
  {
    postalCode: '3323',
    city: 'Kollmitzberg'
  },
  {
    postalCode: '3323',
    city: 'Berghof'
  },
  {
    postalCode: '3323',
    city: 'Freyenstein'
  },
  {
    postalCode: '3323',
    city: 'Hößgang'
  },
  {
    postalCode: '3323',
    city: 'Kleinwolfstein'
  },
  {
    postalCode: '3323',
    city: 'Nabegg'
  },
  {
    postalCode: '3323',
    city: 'Neustadtl-Markt'
  },
  {
    postalCode: '3323',
    city: 'Neustadtl-Umgebung'
  },
  {
    postalCode: '3323',
    city: 'Schaltberg'
  },
  {
    postalCode: '3323',
    city: 'Windpassing'
  },
  {
    postalCode: '3324',
    city: 'Aigen'
  },
  {
    postalCode: '3324',
    city: 'Euratsfeld'
  },
  {
    postalCode: '3324',
    city: 'Gafring'
  },
  {
    postalCode: '3325',
    city: 'Ferschnitz'
  },
  {
    postalCode: '3325',
    city: 'Innerochsenbach'
  },
  {
    postalCode: '3325',
    city: 'Straß'
  },
  {
    postalCode: '3331',
    city: 'Biberbach'
  },
  {
    postalCode: '3331',
    city: 'Kematen'
  },
  {
    postalCode: '3331',
    city: 'Rosenau am Sonntagberg'
  },
  {
    postalCode: '3331',
    city: 'Sonntagberg'
  },
  {
    postalCode: '3331',
    city: 'Hilm'
  },
  {
    postalCode: '3332',
    city: 'Biberbach'
  },
  {
    postalCode: '3332',
    city: 'Gleiß'
  },
  {
    postalCode: '3332',
    city: 'Rosenau am Sonntagberg'
  },
  {
    postalCode: '3332',
    city: 'Rotte Wühr'
  },
  {
    postalCode: '3332',
    city: 'Baichberg'
  },
  {
    postalCode: '3332',
    city: 'Sonntagberg'
  },
  {
    postalCode: '3332',
    city: 'Hilm'
  },
  {
    postalCode: '3332',
    city: 'St. Georgen in der Klaus'
  },
  {
    postalCode: '3333',
    city: 'Biberbach'
  },
  {
    postalCode: '3333',
    city: 'Böhlerwerk'
  },
  {
    postalCode: '3333',
    city: 'Bruckbach'
  },
  {
    postalCode: '3333',
    city: 'Rotte Wühr'
  },
  {
    postalCode: '3333',
    city: 'St. Georgen in der Klaus'
  },
  {
    postalCode: '3334',
    city: 'Breitenau'
  },
  {
    postalCode: '3334',
    city: 'Gaflenz'
  },
  {
    postalCode: '3334',
    city: 'Großgschnaidt'
  },
  {
    postalCode: '3334',
    city: 'Kleingschnaidt'
  },
  {
    postalCode: '3334',
    city: 'Lindau'
  },
  {
    postalCode: '3334',
    city: 'Oberland'
  },
  {
    postalCode: '3334',
    city: 'Pettendorf'
  },
  {
    postalCode: '3335',
    city: 'Breitenau'
  },
  {
    postalCode: '3335',
    city: 'Lindau'
  },
  {
    postalCode: '3335',
    city: 'Neudorf'
  },
  {
    postalCode: '3335',
    city: 'Anger'
  },
  {
    postalCode: '3335',
    city: 'Au'
  },
  {
    postalCode: '3335',
    city: 'Küpfern'
  },
  {
    postalCode: '3335',
    city: 'Mühlein'
  },
  {
    postalCode: '3335',
    city: 'Nach der Enns'
  },
  {
    postalCode: '3335',
    city: 'Pichl'
  },
  {
    postalCode: '3335',
    city: 'Rapoldeck'
  },
  {
    postalCode: '3335',
    city: 'Obsweyer'
  },
  {
    postalCode: '3335',
    city: 'Weyer'
  },
  {
    postalCode: '3340',
    city: 'Graben'
  },
  {
    postalCode: '3340',
    city: 'Sonntagberg'
  },
  {
    postalCode: '3340',
    city: 'Waidhofen an der Ybbs'
  },
  {
    postalCode: '3340',
    city: 'St. Leonhard am Wald'
  },
  {
    postalCode: '3340',
    city: 'Konradsheim'
  },
  {
    postalCode: '3340',
    city: 'Kreilhof'
  },
  {
    postalCode: '3340',
    city: 'Rien'
  },
  {
    postalCode: '3340',
    city: 'St. Georgen in der Klaus'
  },
  {
    postalCode: '3340',
    city: 'Wirts'
  },
  {
    postalCode: '3340',
    city: 'Kronhobl'
  },
  {
    postalCode: '3340',
    city: 'Schilchermühle'
  },
  {
    postalCode: '3340',
    city: 'Stritzlödt'
  },
  {
    postalCode: '3340',
    city: 'Unterzell'
  },
  {
    postalCode: '3340',
    city: 'Zell-Markt'
  },
  {
    postalCode: '3340',
    city: 'Zell-Arzberg'
  },
  {
    postalCode: '3340',
    city: 'Windhag'
  },
  {
    postalCode: '3340',
    city: 'Schwarzenberg'
  },
  {
    postalCode: '3341',
    city: 'Oberamt'
  },
  {
    postalCode: '3341',
    city: 'Schadneramt'
  },
  {
    postalCode: '3341',
    city: 'Schwarzenbach'
  },
  {
    postalCode: '3341',
    city: 'Großprolling'
  },
  {
    postalCode: '3341',
    city: 'Haselgraben'
  },
  {
    postalCode: '3341',
    city: 'Hubberg'
  },
  {
    postalCode: '3341',
    city: 'Kleinprolling'
  },
  {
    postalCode: '3341',
    city: 'Knieberg'
  },
  {
    postalCode: '3341',
    city: 'Maisberg'
  },
  {
    postalCode: '3341',
    city: 'Prochenberg'
  },
  {
    postalCode: '3341',
    city: 'Schwarzenberg'
  },
  {
    postalCode: '3341',
    city: 'Schwarzois'
  },
  {
    postalCode: '3341',
    city: 'Ybbsitz'
  },
  {
    postalCode: '3341',
    city: 'Zogelsgraben'
  },
  {
    postalCode: '3342',
    city: 'Graben'
  },
  {
    postalCode: '3342',
    city: 'Gstadt'
  },
  {
    postalCode: '3342',
    city: 'Ofenberg'
  },
  {
    postalCode: '3342',
    city: 'Hauslehen'
  },
  {
    postalCode: '3342',
    city: 'Schwarzenbach'
  },
  {
    postalCode: '3342',
    city: 'Strubb'
  },
  {
    postalCode: '3342',
    city: 'Thann'
  },
  {
    postalCode: '3343',
    city: 'Berg'
  },
  {
    postalCode: '3343',
    city: 'Dornleiten'
  },
  {
    postalCode: '3343',
    city: 'Garnberg'
  },
  {
    postalCode: '3343',
    city: 'Grießau'
  },
  {
    postalCode: '3343',
    city: 'Hohenlehen'
  },
  {
    postalCode: '3343',
    city: 'Hollenstein an der Ybbs'
  },
  {
    postalCode: '3343',
    city: 'Krenngraben'
  },
  {
    postalCode: '3343',
    city: 'Oberkirchen'
  },
  {
    postalCode: '3343',
    city: 'Oisberg'
  },
  {
    postalCode: '3343',
    city: 'Sattel'
  },
  {
    postalCode: '3343',
    city: 'Thalbauer'
  },
  {
    postalCode: '3343',
    city: 'Thomasberg'
  },
  {
    postalCode: '3343',
    city: 'Walcherbauer'
  },
  {
    postalCode: '3343',
    city: 'Wenten'
  },
  {
    postalCode: '3344',
    city: 'Hochau'
  },
  {
    postalCode: '3344',
    city: 'Königsbergau'
  },
  {
    postalCode: '3344',
    city: 'Kogelsbach'
  },
  {
    postalCode: '3344',
    city: 'St. Georgen am Reith'
  },
  {
    postalCode: '3345',
    city: 'Eisenwiesen'
  },
  {
    postalCode: '3345',
    city: 'Göstling an der Ybbs'
  },
  {
    postalCode: '3345',
    city: 'Großegg'
  },
  {
    postalCode: '3345',
    city: 'Hochreit'
  },
  {
    postalCode: '3345',
    city: 'Königsberg'
  },
  {
    postalCode: '3345',
    city: 'Lassing'
  },
  {
    postalCode: '3345',
    city: 'Mendling'
  },
  {
    postalCode: '3345',
    city: 'Oberkogelsbach'
  },
  {
    postalCode: '3345',
    city: 'Pernegg'
  },
  {
    postalCode: '3345',
    city: 'Steinbachmauer'
  },
  {
    postalCode: '3345',
    city: 'Stixenlehen'
  },
  {
    postalCode: '3345',
    city: 'Strohmarkt'
  },
  {
    postalCode: '3345',
    city: 'Ybbssteinbach'
  },
  {
    postalCode: '3350',
    city: 'Edelhof'
  },
  {
    postalCode: '3350',
    city: 'Gstetten'
  },
  {
    postalCode: '3350',
    city: 'Haag'
  },
  {
    postalCode: '3350',
    city: 'Heimberg'
  },
  {
    postalCode: '3350',
    city: 'Holzleiten'
  },
  {
    postalCode: '3350',
    city: 'Knillhof'
  },
  {
    postalCode: '3350',
    city: 'Krottendorf'
  },
  {
    postalCode: '3350',
    city: 'Porstenberg'
  },
  {
    postalCode: '3350',
    city: 'Radhof'
  },
  {
    postalCode: '3350',
    city: 'Reichhub'
  },
  {
    postalCode: '3350',
    city: 'Salaberg'
  },
  {
    postalCode: '3350',
    city: 'Schudutz'
  },
  {
    postalCode: '3350',
    city: 'Brunnhof'
  },
  {
    postalCode: '3350',
    city: 'St. Johann in Engstetten'
  },
  {
    postalCode: '3350',
    city: 'Hofkirchen'
  },
  {
    postalCode: '3351',
    city: 'Sträußl'
  },
  {
    postalCode: '3351',
    city: 'Tröstlberg'
  },
  {
    postalCode: '3351',
    city: 'Grub'
  },
  {
    postalCode: '3351',
    city: 'Hartlmühl'
  },
  {
    postalCode: '3351',
    city: 'Holzschachen'
  },
  {
    postalCode: '3351',
    city: 'Rohrbach'
  },
  {
    postalCode: '3351',
    city: 'Schwaig'
  },
  {
    postalCode: '3351',
    city: 'Weistrach'
  },
  {
    postalCode: '3352',
    city: 'Hohenreith'
  },
  {
    postalCode: '3352',
    city: 'Kürnberg'
  },
  {
    postalCode: '3352',
    city: 'St. Johann in Engstetten'
  },
  {
    postalCode: '3352',
    city: 'St. Michael am Bruckbach'
  },
  {
    postalCode: '3352',
    city: 'St. Peter in der Au-Dorf'
  },
  {
    postalCode: '3352',
    city: 'St. Peter in der Au-Markt'
  },
  {
    postalCode: '3352',
    city: 'Grub'
  },
  {
    postalCode: '3352',
    city: 'Hartlmühl'
  },
  {
    postalCode: '3352',
    city: 'Schwaig'
  },
  {
    postalCode: '3353',
    city: 'Biberbach'
  },
  {
    postalCode: '3353',
    city: 'Seitenstetten Dorf'
  },
  {
    postalCode: '3353',
    city: 'Seitenstetten Markt'
  },
  {
    postalCode: '3354',
    city: 'Ottendorf'
  },
  {
    postalCode: '3354',
    city: 'Bubendorf'
  },
  {
    postalCode: '3354',
    city: 'Meilersdorf'
  },
  {
    postalCode: '3354',
    city: 'Wolfsbach'
  },
  {
    postalCode: '3355',
    city: 'Ertl'
  },
  {
    postalCode: '3361',
    city: 'Abetzberg'
  },
  {
    postalCode: '3361',
    city: 'Aschbach-Dorf'
  },
  {
    postalCode: '3361',
    city: 'Aschbach-Markt'
  },
  {
    postalCode: '3361',
    city: 'Krenstetten'
  },
  {
    postalCode: '3361',
    city: 'Mitterhausleiten'
  },
  {
    postalCode: '3361',
    city: 'Oberaschbach'
  },
  {
    postalCode: '3361',
    city: 'Biberbach'
  },
  {
    postalCode: '3361',
    city: 'Kematen'
  },
  {
    postalCode: '3361',
    city: 'Öhling'
  },
  {
    postalCode: '3362',
    city: 'Mauer bei Amstetten'
  },
  {
    postalCode: '3362',
    city: 'Greinsfurth'
  },
  {
    postalCode: '3362',
    city: 'Öhling'
  },
  {
    postalCode: '3362',
    city: 'Zeillern'
  },
  {
    postalCode: '3363',
    city: 'Ulmerfeld'
  },
  {
    postalCode: '3363',
    city: 'Hausmening'
  },
  {
    postalCode: '3363',
    city: 'Neufurth'
  },
  {
    postalCode: '3363',
    city: 'Scherbling'
  },
  {
    postalCode: '3363',
    city: 'Schindau'
  },
  {
    postalCode: '3363',
    city: 'Winklarn'
  },
  {
    postalCode: '3364',
    city: 'Amesleiten'
  },
  {
    postalCode: '3364',
    city: 'Kornberg'
  },
  {
    postalCode: '3364',
    city: 'Neuhofen an der Ybbs'
  },
  {
    postalCode: '3364',
    city: 'Perbersdorf'
  },
  {
    postalCode: '3364',
    city: 'Scherbling'
  },
  {
    postalCode: '3364',
    city: 'Schindau'
  },
  {
    postalCode: '3364',
    city: 'Toberstetten'
  },
  {
    postalCode: '3364',
    city: 'Graben'
  },
  {
    postalCode: '3365',
    city: 'Allhartsberg'
  },
  {
    postalCode: '3365',
    city: 'Angerholz'
  },
  {
    postalCode: '3365',
    city: 'Kühberg'
  },
  {
    postalCode: '3365',
    city: 'Maierhofen'
  },
  {
    postalCode: '3365',
    city: 'Kröllendorf'
  },
  {
    postalCode: '3365',
    city: 'Wallmersdorf'
  },
  {
    postalCode: '3365',
    city: 'Toberstetten'
  },
  {
    postalCode: '3365',
    city: 'Sonntagberg'
  },
  {
    postalCode: '3370',
    city: 'Göttsbach'
  },
  {
    postalCode: '3370',
    city: 'Waasen'
  },
  {
    postalCode: '3370',
    city: 'Donaudorf'
  },
  {
    postalCode: '3370',
    city: 'Ybbs an der Donau'
  },
  {
    postalCode: '3371',
    city: 'Neumarkt an der Ybbs'
  },
  {
    postalCode: '3371',
    city: 'Schönegg'
  },
  {
    postalCode: '3371',
    city: 'Breitenschollen'
  },
  {
    postalCode: '3371',
    city: 'Köchling'
  },
  {
    postalCode: '3371',
    city: 'Buch'
  },
  {
    postalCode: '3371',
    city: 'Dörfl'
  },
  {
    postalCode: '3371',
    city: 'Fischerberg'
  },
  {
    postalCode: '3371',
    city: 'Hofa'
  },
  {
    postalCode: '3371',
    city: 'Keppelberg'
  },
  {
    postalCode: '3371',
    city: 'Krottenthal'
  },
  {
    postalCode: '3371',
    city: 'Linden'
  },
  {
    postalCode: '3372',
    city: 'Atzelsdorf'
  },
  {
    postalCode: '3372',
    city: 'Blindenmarkt'
  },
  {
    postalCode: '3372',
    city: 'Kottingburgstall'
  },
  {
    postalCode: '3372',
    city: 'Weitgraben'
  },
  {
    postalCode: '3372',
    city: 'Ferschnitz'
  },
  {
    postalCode: '3373',
    city: 'Plaika'
  },
  {
    postalCode: '3373',
    city: 'Holzing'
  },
  {
    postalCode: '3373',
    city: 'Kemmelbach'
  },
  {
    postalCode: '3373',
    city: 'Winden'
  },
  {
    postalCode: '3373',
    city: 'Wolfsberg'
  },
  {
    postalCode: '3373',
    city: 'Ströblitz'
  },
  {
    postalCode: '3373',
    city: 'Sarling'
  },
  {
    postalCode: '3374',
    city: 'Ratzenberg'
  },
  {
    postalCode: '3374',
    city: 'Diedersdorf'
  },
  {
    postalCode: '3374',
    city: 'Säusenstein'
  },
  {
    postalCode: '3374',
    city: 'Sarling'
  },
  {
    postalCode: '3375',
    city: 'Annastift'
  },
  {
    postalCode: '3375',
    city: 'Holzern'
  },
  {
    postalCode: '3375',
    city: 'Krummnußbaum'
  },
  {
    postalCode: '3375',
    city: 'Neustift'
  },
  {
    postalCode: '3375',
    city: 'Wallenbach'
  },
  {
    postalCode: '3376',
    city: 'Ennsbach'
  },
  {
    postalCode: '3376',
    city: 'Karlsbach'
  },
  {
    postalCode: '3376',
    city: 'St. Martin am Ybbsfelde'
  },
  {
    postalCode: '3380',
    city: 'Steinwand'
  },
  {
    postalCode: '3380',
    city: 'Ornding'
  },
  {
    postalCode: '3380',
    city: 'Wörth'
  },
  {
    postalCode: '3380',
    city: 'Am Rechen'
  },
  {
    postalCode: '3380',
    city: 'Brunn an der Erlauf'
  },
  {
    postalCode: '3380',
    city: 'Pöchlarn'
  },
  {
    postalCode: '3380',
    city: 'Röhrapoint'
  },
  {
    postalCode: '3381',
    city: 'Golling'
  },
  {
    postalCode: '3381',
    city: 'Hinterleiten'
  },
  {
    postalCode: '3381',
    city: 'Neuda'
  },
  {
    postalCode: '3381',
    city: 'Sittenberg'
  },
  {
    postalCode: '3382',
    city: 'Eckartsberg'
  },
  {
    postalCode: '3382',
    city: 'Lanzing'
  },
  {
    postalCode: '3382',
    city: 'Lerchfeld'
  },
  {
    postalCode: '3382',
    city: 'Mauer bei Melk'
  },
  {
    postalCode: '3382',
    city: 'Neuhofen'
  },
  {
    postalCode: '3382',
    city: 'Pfaffing bei Mauer'
  },
  {
    postalCode: '3382',
    city: 'Thal'
  },
  {
    postalCode: '3382',
    city: 'Umbach'
  },
  {
    postalCode: '3382',
    city: 'Ursprung'
  },
  {
    postalCode: '3382',
    city: 'Sooß'
  },
  {
    postalCode: '3382',
    city: 'Albrechtsberg an der Pielach'
  },
  {
    postalCode: '3382',
    city: 'Loosdorf'
  },
  {
    postalCode: '3382',
    city: 'Neubach'
  },
  {
    postalCode: '3382',
    city: 'Rohr'
  },
  {
    postalCode: '3382',
    city: 'Sitzenthal'
  },
  {
    postalCode: '3382',
    city: 'Anzendorf'
  },
  {
    postalCode: '3382',
    city: 'Groß-Schollach'
  },
  {
    postalCode: '3382',
    city: 'Klein-Schollach'
  },
  {
    postalCode: '3382',
    city: 'Merkendorf'
  },
  {
    postalCode: '3382',
    city: 'Roggendorf'
  },
  {
    postalCode: '3382',
    city: 'Schallaburg'
  },
  {
    postalCode: '3382',
    city: 'Steinparz'
  },
  {
    postalCode: '3383',
    city: 'Arnersdorf'
  },
  {
    postalCode: '3383',
    city: 'Atzing'
  },
  {
    postalCode: '3383',
    city: 'Diendorf'
  },
  {
    postalCode: '3383',
    city: 'Grub'
  },
  {
    postalCode: '3383',
    city: 'Hainberg'
  },
  {
    postalCode: '3383',
    city: 'Harmersdorf'
  },
  {
    postalCode: '3383',
    city: 'Hösing'
  },
  {
    postalCode: '3383',
    city: 'Hürm'
  },
  {
    postalCode: '3383',
    city: 'Inning'
  },
  {
    postalCode: '3383',
    city: 'Kronaberg'
  },
  {
    postalCode: '3383',
    city: 'Löbersdorf'
  },
  {
    postalCode: '3383',
    city: 'Maxenbach'
  },
  {
    postalCode: '3383',
    city: 'Mitterradl'
  },
  {
    postalCode: '3383',
    city: 'Murschratten'
  },
  {
    postalCode: '3383',
    city: 'Neustift bei Sooß'
  },
  {
    postalCode: '3383',
    city: 'Oberhaag'
  },
  {
    postalCode: '3383',
    city: 'Oberradl'
  },
  {
    postalCode: '3383',
    city: 'Ober-Siegendorf'
  },
  {
    postalCode: '3383',
    city: 'Ober-Thurnhofen'
  },
  {
    postalCode: '3383',
    city: 'Pöttendorf'
  },
  {
    postalCode: '3383',
    city: 'Scharagraben'
  },
  {
    postalCode: '3383',
    city: 'Schlatzendorf'
  },
  {
    postalCode: '3383',
    city: 'Seeben'
  },
  {
    postalCode: '3383',
    city: 'Unterhaag'
  },
  {
    postalCode: '3383',
    city: 'Unter-Siegendorf'
  },
  {
    postalCode: '3383',
    city: 'Unter-Thurnhofen'
  },
  {
    postalCode: '3384',
    city: 'Eibelsau'
  },
  {
    postalCode: '3384',
    city: 'Eidletzberg'
  },
  {
    postalCode: '3384',
    city: 'Groß Sierning'
  },
  {
    postalCode: '3384',
    city: 'Haunoldstein'
  },
  {
    postalCode: '3384',
    city: 'Osterburg'
  },
  {
    postalCode: '3384',
    city: 'Pielachhäuser'
  },
  {
    postalCode: '3384',
    city: 'Pottschollach'
  },
  {
    postalCode: '3384',
    city: 'Haindorf'
  },
  {
    postalCode: '3384',
    city: 'Knetzersdorf'
  },
  {
    postalCode: '3384',
    city: 'Mannersdorf'
  },
  {
    postalCode: '3384',
    city: 'Mitterndorf'
  },
  {
    postalCode: '3384',
    city: 'Nenndorf'
  },
  {
    postalCode: '3384',
    city: 'Winkel'
  },
  {
    postalCode: '3385',
    city: 'Distelburg'
  },
  {
    postalCode: '3385',
    city: 'Eggsdorf'
  },
  {
    postalCode: '3385',
    city: 'Friesing'
  },
  {
    postalCode: '3385',
    city: 'Gerersdorf'
  },
  {
    postalCode: '3385',
    city: 'Grillenhöfe'
  },
  {
    postalCode: '3385',
    city: 'Hetzersdorf'
  },
  {
    postalCode: '3385',
    city: 'Hofing'
  },
  {
    postalCode: '3385',
    city: 'Loipersdorf'
  },
  {
    postalCode: '3385',
    city: 'Salau'
  },
  {
    postalCode: '3385',
    city: 'Stainingsdorf'
  },
  {
    postalCode: '3385',
    city: 'Völlerndorf'
  },
  {
    postalCode: '3385',
    city: 'Weitendorf'
  },
  {
    postalCode: '3385',
    city: 'Markersdorf an der Pielach'
  },
  {
    postalCode: '3385',
    city: 'Mitterau'
  },
  {
    postalCode: '3385',
    city: 'Nenndorf'
  },
  {
    postalCode: '3385',
    city: 'Poppendorf'
  },
  {
    postalCode: '3385',
    city: 'Wultendorf'
  },
  {
    postalCode: '3385',
    city: 'Prinzersdorf'
  },
  {
    postalCode: '3385',
    city: 'Uttendorf'
  },
  {
    postalCode: '3385',
    city: 'Matzersdorf'
  },
  {
    postalCode: '3386',
    city: 'Doppel'
  },
  {
    postalCode: '3386',
    city: 'Eichberg'
  },
  {
    postalCode: '3386',
    city: 'Hafnerbach'
  },
  {
    postalCode: '3386',
    city: 'Hengstberg'
  },
  {
    postalCode: '3386',
    city: 'Hohenegg'
  },
  {
    postalCode: '3386',
    city: 'Korning'
  },
  {
    postalCode: '3386',
    city: 'Obergraben'
  },
  {
    postalCode: '3386',
    city: 'Oed'
  },
  {
    postalCode: '3386',
    city: 'Pfaffing'
  },
  {
    postalCode: '3386',
    city: 'Pielachhaag'
  },
  {
    postalCode: '3386',
    city: 'Rannersdorf'
  },
  {
    postalCode: '3386',
    city: 'Sasendorf'
  },
  {
    postalCode: '3386',
    city: 'Stein'
  },
  {
    postalCode: '3386',
    city: 'Thal'
  },
  {
    postalCode: '3386',
    city: 'Untergraben'
  },
  {
    postalCode: '3386',
    city: 'Weghof'
  },
  {
    postalCode: '3386',
    city: 'Weinzierl'
  },
  {
    postalCode: '3386',
    city: 'Wimpassing an der Pielach'
  },
  {
    postalCode: '3386',
    city: 'Windschnur'
  },
  {
    postalCode: '3386',
    city: 'Würmling'
  },
  {
    postalCode: '3386',
    city: 'Zendorf'
  },
  {
    postalCode: '3390',
    city: 'Großpriel'
  },
  {
    postalCode: '3390',
    city: 'Kollapriel'
  },
  {
    postalCode: '3390',
    city: 'Melk'
  },
  {
    postalCode: '3390',
    city: 'Pielach'
  },
  {
    postalCode: '3390',
    city: 'Pielachberg'
  },
  {
    postalCode: '3390',
    city: 'Pöverding'
  },
  {
    postalCode: '3390',
    city: 'Rosenfeld'
  },
  {
    postalCode: '3390',
    city: 'Schrattenbruck'
  },
  {
    postalCode: '3390',
    city: 'Spielberg'
  },
  {
    postalCode: '3390',
    city: 'Winden'
  },
  {
    postalCode: '3390',
    city: 'Roggendorf'
  },
  {
    postalCode: '3390',
    city: 'Bergern'
  },
  {
    postalCode: '3390',
    city: 'Freiningau'
  },
  {
    postalCode: '3390',
    city: 'Maierhöfen'
  },
  {
    postalCode: '3392',
    city: 'Gerolding'
  },
  {
    postalCode: '3392',
    city: 'Heitzing'
  },
  {
    postalCode: '3392',
    city: 'Hohenwarth'
  },
  {
    postalCode: '3392',
    city: 'Neu-Gerolding'
  },
  {
    postalCode: '3392',
    city: 'Nölling'
  },
  {
    postalCode: '3392',
    city: 'Berging'
  },
  {
    postalCode: '3392',
    city: 'Hub'
  },
  {
    postalCode: '3392',
    city: 'Schönbühel an der Donau'
  },
  {
    postalCode: '3393',
    city: 'Anzenberg'
  },
  {
    postalCode: '3393',
    city: 'Arb'
  },
  {
    postalCode: '3393',
    city: 'Einsiedl'
  },
  {
    postalCode: '3393',
    city: 'Gassen'
  },
  {
    postalCode: '3393',
    city: 'Hofstetten'
  },
  {
    postalCode: '3393',
    city: 'Mannersdorf'
  },
  {
    postalCode: '3393',
    city: 'Matzleinsdorf'
  },
  {
    postalCode: '3393',
    city: 'Zelking'
  },
  {
    postalCode: '3400',
    city: 'Kierling'
  },
  {
    postalCode: '3400',
    city: 'Klosterneuburg'
  },
  {
    postalCode: '3400',
    city: 'Kritzendorf'
  },
  {
    postalCode: '3400',
    city: 'Maria Gugging'
  },
  {
    postalCode: '3400',
    city: 'Mauerbach'
  },
  {
    postalCode: '3400',
    city: 'Weidling'
  },
  {
    postalCode: '3400',
    city: 'Weidlingbach'
  },
  {
    postalCode: '3413',
    city: 'Hintersdorf'
  },
  {
    postalCode: '3413',
    city: 'Kirchbach'
  },
  {
    postalCode: '3420',
    city: 'Kritzendorf'
  },
  {
    postalCode: '3421',
    city: 'Höflein an der Donau'
  },
  {
    postalCode: '3422',
    city: 'Altenberg'
  },
  {
    postalCode: '3422',
    city: 'Greifenstein'
  },
  {
    postalCode: '3422',
    city: 'Hadersfeld'
  },
  {
    postalCode: '3423',
    city: 'St. Andrä vor dem Hagenthale'
  },
  {
    postalCode: '3423',
    city: 'Wördern'
  },
  {
    postalCode: '3423',
    city: 'Zeiselmauer'
  },
  {
    postalCode: '3424',
    city: 'Muckendorf an der Donau'
  },
  {
    postalCode: '3424',
    city: 'Wipfing'
  },
  {
    postalCode: '3424',
    city: 'Wolfpassing'
  },
  {
    postalCode: '3424',
    city: 'Zeiselmauer'
  },
  {
    postalCode: '3425',
    city: 'Langenlebarn-Oberaigen'
  },
  {
    postalCode: '3425',
    city: 'Langenlebarn-Unteraigen'
  },
  {
    postalCode: '3425',
    city: 'Tulln an der Donau'
  },
  {
    postalCode: '3430',
    city: 'Eggendorf am Wagram'
  },
  {
    postalCode: '3430',
    city: 'Chorherrn'
  },
  {
    postalCode: '3430',
    city: 'Langenlebarn-Oberaigen'
  },
  {
    postalCode: '3430',
    city: 'Mollersdorf'
  },
  {
    postalCode: '3430',
    city: 'Neuaigen'
  },
  {
    postalCode: '3430',
    city: 'Trübensee'
  },
  {
    postalCode: '3430',
    city: 'Frauenhofen'
  },
  {
    postalCode: '3430',
    city: 'Nitzing'
  },
  {
    postalCode: '3430',
    city: 'Staasdorf'
  },
  {
    postalCode: '3430',
    city: 'Tulln an der Donau'
  },
  {
    postalCode: '3433',
    city: 'Königstetten'
  },
  {
    postalCode: '3434',
    city: 'Katzelsdorf'
  },
  {
    postalCode: '3434',
    city: 'Tulbing'
  },
  {
    postalCode: '3434',
    city: 'Wilfersdorf'
  },
  {
    postalCode: '3435',
    city: 'Neusiedl'
  },
  {
    postalCode: '3435',
    city: 'Bärndorf'
  },
  {
    postalCode: '3435',
    city: 'Dürnrohr'
  },
  {
    postalCode: '3435',
    city: 'Erpersdorf'
  },
  {
    postalCode: '3435',
    city: 'Kleinschönbichl'
  },
  {
    postalCode: '3435',
    city: 'Pischelsdorf'
  },
  {
    postalCode: '3435',
    city: 'Zwentendorf an der Donau'
  },
  {
    postalCode: '3441',
    city: 'Baumgarten am Tullnerfeld'
  },
  {
    postalCode: '3441',
    city: 'Freundorf'
  },
  {
    postalCode: '3441',
    city: 'Judenau'
  },
  {
    postalCode: '3441',
    city: 'Pixendorf'
  },
  {
    postalCode: '3441',
    city: 'Abstetten'
  },
  {
    postalCode: '3441',
    city: 'Dietersdorf'
  },
  {
    postalCode: '3441',
    city: 'Einsiedl'
  },
  {
    postalCode: '3441',
    city: 'Gollarn'
  },
  {
    postalCode: '3441',
    city: 'Ranzelsdorf'
  },
  {
    postalCode: '3441',
    city: 'Zöfing'
  },
  {
    postalCode: '3442',
    city: 'Asparn'
  },
  {
    postalCode: '3442',
    city: 'Kronau'
  },
  {
    postalCode: '3442',
    city: 'Langenrohr'
  },
  {
    postalCode: '3442',
    city: 'Langenschönbichl'
  },
  {
    postalCode: '3443',
    city: 'Dornberg'
  },
  {
    postalCode: '3443',
    city: 'Geigelberg'
  },
  {
    postalCode: '3443',
    city: 'Hagenau'
  },
  {
    postalCode: '3443',
    city: 'Elsbach'
  },
  {
    postalCode: '3443',
    city: 'Kreuth'
  },
  {
    postalCode: '3443',
    city: 'Au am Kraking'
  },
  {
    postalCode: '3443',
    city: 'Rappoltenkirchen'
  },
  {
    postalCode: '3443',
    city: 'Kogl'
  },
  {
    postalCode: '3443',
    city: 'Penzing'
  },
  {
    postalCode: '3443',
    city: 'Kracking'
  },
  {
    postalCode: '3443',
    city: 'Öpping'
  },
  {
    postalCode: '3443',
    city: 'Röhrenbach'
  },
  {
    postalCode: '3443',
    city: 'Gerersdorf'
  },
  {
    postalCode: '3443',
    city: 'Henzing'
  },
  {
    postalCode: '3443',
    city: 'Sieghartskirchen'
  },
  {
    postalCode: '3443',
    city: 'Wagendorf'
  },
  {
    postalCode: '3443',
    city: 'Kronstein'
  },
  {
    postalCode: '3443',
    city: 'Irenental'
  },
  {
    postalCode: '3451',
    city: 'Atzelsdorf'
  },
  {
    postalCode: '3451',
    city: 'Michelhausen'
  },
  {
    postalCode: '3451',
    city: 'Spital'
  },
  {
    postalCode: '3451',
    city: 'Streithofen'
  },
  {
    postalCode: '3451',
    city: 'Rust im Tullnerfeld'
  },
  {
    postalCode: '3451',
    city: 'Siegersdorf'
  },
  {
    postalCode: '3451',
    city: 'Plankenberg'
  },
  {
    postalCode: '3451',
    city: 'Steinhäusl'
  },
  {
    postalCode: '3452',
    city: 'Atzenbrugg'
  },
  {
    postalCode: '3452',
    city: 'Ebersdorf'
  },
  {
    postalCode: '3452',
    city: 'Heiligeneich'
  },
  {
    postalCode: '3452',
    city: 'Moosbierbaum'
  },
  {
    postalCode: '3452',
    city: 'Tautendorf'
  },
  {
    postalCode: '3452',
    city: 'Weinzierl'
  },
  {
    postalCode: '3452',
    city: 'Hütteldorf'
  },
  {
    postalCode: '3452',
    city: 'Trasdorf'
  },
  {
    postalCode: '3452',
    city: 'Watzendorf'
  },
  {
    postalCode: '3452',
    city: 'Michelndorf'
  },
  {
    postalCode: '3452',
    city: 'Mitterndorf'
  },
  {
    postalCode: '3452',
    city: 'Diendorf'
  },
  {
    postalCode: '3452',
    city: 'Hankenfeld'
  },
  {
    postalCode: '3452',
    city: 'Saladorf'
  },
  {
    postalCode: '3454',
    city: 'Adletzberg'
  },
  {
    postalCode: '3454',
    city: 'Gutenbrunn'
  },
  {
    postalCode: '3454',
    city: 'Heiligenkreuz'
  },
  {
    postalCode: '3454',
    city: 'Oberhameten'
  },
  {
    postalCode: '3454',
    city: 'Pottschall'
  },
  {
    postalCode: '3454',
    city: 'Unterhameten'
  },
  {
    postalCode: '3454',
    city: 'Ahrenberg'
  },
  {
    postalCode: '3454',
    city: 'Baumgarten'
  },
  {
    postalCode: '3454',
    city: 'Eggendorf'
  },
  {
    postalCode: '3454',
    city: 'Hasendorf'
  },
  {
    postalCode: '3454',
    city: 'Neustift'
  },
  {
    postalCode: '3454',
    city: 'Reidling'
  },
  {
    postalCode: '3454',
    city: 'Sitzenberg'
  },
  {
    postalCode: '3454',
    city: 'Thallern'
  },
  {
    postalCode: '3454',
    city: 'Buttendorf'
  },
  {
    postalCode: '3454',
    city: 'Kaindorf'
  },
  {
    postalCode: '3454',
    city: 'Maria Ponsee'
  },
  {
    postalCode: '3454',
    city: 'Oberbierbaum'
  },
  {
    postalCode: '3454',
    city: 'Preuwitz'
  },
  {
    postalCode: '3462',
    city: 'Absdorf'
  },
  {
    postalCode: '3462',
    city: 'Bierbaum am Kleebühel'
  },
  {
    postalCode: '3462',
    city: 'Frauendorf an der Au'
  },
  {
    postalCode: '3462',
    city: 'Hippersdorf'
  },
  {
    postalCode: '3462',
    city: 'Königsbrunn am Wagram'
  },
  {
    postalCode: '3462',
    city: 'Utzenlaa'
  },
  {
    postalCode: '3463',
    city: 'Eggendorf am Wagram'
  },
  {
    postalCode: '3463',
    city: 'Starnwörth'
  },
  {
    postalCode: '3463',
    city: 'Stetteldorf am Wagram'
  },
  {
    postalCode: '3464',
    city: 'Gaisruck'
  },
  {
    postalCode: '3464',
    city: 'Goldgeben'
  },
  {
    postalCode: '3464',
    city: 'Hausleiten'
  },
  {
    postalCode: '3464',
    city: 'Perzendorf'
  },
  {
    postalCode: '3464',
    city: 'Pettendorf'
  },
  {
    postalCode: '3464',
    city: 'Schmida'
  },
  {
    postalCode: '3464',
    city: 'Seitzersdorf-Wolfpassing'
  },
  {
    postalCode: '3464',
    city: 'Zaina'
  },
  {
    postalCode: '3464',
    city: 'Eggendorf am Wagram'
  },
  {
    postalCode: '3465',
    city: 'Unterstockstall'
  },
  {
    postalCode: '3465',
    city: 'Königsbrunn am Wagram'
  },
  {
    postalCode: '3470',
    city: 'Ottenthal'
  },
  {
    postalCode: '3470',
    city: 'Dörfl'
  },
  {
    postalCode: '3470',
    city: 'Engelmannsbrunn'
  },
  {
    postalCode: '3470',
    city: 'Kirchberg am Wagram'
  },
  {
    postalCode: '3470',
    city: 'Mallon'
  },
  {
    postalCode: '3470',
    city: 'Mitterstockstall'
  },
  {
    postalCode: '3470',
    city: 'Neustift im Felde'
  },
  {
    postalCode: '3470',
    city: 'Oberstockstall'
  },
  {
    postalCode: '3471',
    city: 'Großriedenthal'
  },
  {
    postalCode: '3471',
    city: 'Neudegg'
  },
  {
    postalCode: '3472',
    city: 'Hohenwarth'
  },
  {
    postalCode: '3473',
    city: 'Bösendürnbach'
  },
  {
    postalCode: '3473',
    city: 'Mühlbach am Manhartsberg'
  },
  {
    postalCode: '3473',
    city: 'Ronthal'
  },
  {
    postalCode: '3473',
    city: 'Zemling'
  },
  {
    postalCode: '3473',
    city: 'Olbersdorf'
  },
  {
    postalCode: '3474',
    city: 'Altenwörth'
  },
  {
    postalCode: '3474',
    city: 'Gigging'
  },
  {
    postalCode: '3474',
    city: 'Kollersdorf'
  },
  {
    postalCode: '3474',
    city: 'Sachsendorf'
  },
  {
    postalCode: '3474',
    city: 'Winkl'
  },
  {
    postalCode: '3481',
    city: 'Fels am Wagram'
  },
  {
    postalCode: '3481',
    city: 'Thürnthal'
  },
  {
    postalCode: '3482',
    city: 'Gösing am Wagram'
  },
  {
    postalCode: '3482',
    city: 'Stettenhof'
  },
  {
    postalCode: '3483',
    city: 'Feuersbrunn'
  },
  {
    postalCode: '3483',
    city: 'Wagram am Wagram'
  },
  {
    postalCode: '3484',
    city: 'Grafenwörth'
  },
  {
    postalCode: '3484',
    city: 'Jettsdorf'
  },
  {
    postalCode: '3484',
    city: 'Seebarn am Wagram'
  },
  {
    postalCode: '3484',
    city: 'St. Johann'
  },
  {
    postalCode: '3485',
    city: 'Engabrunn'
  },
  {
    postalCode: '3485',
    city: 'Donaudorf'
  },
  {
    postalCode: '3485',
    city: 'Grafenegg'
  },
  {
    postalCode: '3485',
    city: 'Grunddorf'
  },
  {
    postalCode: '3485',
    city: 'Haitzendorf'
  },
  {
    postalCode: '3485',
    city: 'Kamp'
  },
  {
    postalCode: '3485',
    city: 'Sittendorf'
  },
  {
    postalCode: '3491',
    city: 'Elsarn im Straßertal'
  },
  {
    postalCode: '3491',
    city: 'Diendorf am Walde'
  },
  {
    postalCode: '3491',
    city: 'Obernholz'
  },
  {
    postalCode: '3491',
    city: 'Straß im Straßertale'
  },
  {
    postalCode: '3491',
    city: 'Wiedendorf'
  },
  {
    postalCode: '3492',
    city: 'Diendorf am Kamp'
  },
  {
    postalCode: '3492',
    city: 'Engabrunn'
  },
  {
    postalCode: '3492',
    city: 'Etsdorf am Kamp'
  },
  {
    postalCode: '3492',
    city: 'Walkersdorf am Kamp'
  },
  {
    postalCode: '3493',
    city: 'Hadersdorf am Kamp'
  },
  {
    postalCode: '3493',
    city: 'Kammern'
  },
  {
    postalCode: '3494',
    city: 'Altweidling'
  },
  {
    postalCode: '3494',
    city: 'Brunn im Felde'
  },
  {
    postalCode: '3494',
    city: 'Gedersdorf'
  },
  {
    postalCode: '3494',
    city: 'Schlickendorf'
  },
  {
    postalCode: '3494',
    city: 'Stratzdorf'
  },
  {
    postalCode: '3494',
    city: 'Theiß'
  },
  {
    postalCode: '3495',
    city: 'Neustift an der Donau'
  },
  {
    postalCode: '3495',
    city: 'Neuweidling'
  },
  {
    postalCode: '3495',
    city: 'Oberrohrendorf'
  },
  {
    postalCode: '3495',
    city: 'Unterrohrendorf'
  },
  {
    postalCode: '3500',
    city: 'Gneixendorf'
  },
  {
    postalCode: '3500',
    city: 'Krems an der Donau'
  },
  {
    postalCode: '3500',
    city: 'Landersdorf'
  },
  {
    postalCode: '3500',
    city: 'Lerchenfeld'
  },
  {
    postalCode: '3500',
    city: 'Rehberg'
  },
  {
    postalCode: '3500',
    city: 'Stein an der Donau'
  },
  {
    postalCode: '3500',
    city: 'Egelsee'
  },
  {
    postalCode: '3500',
    city: 'Scheibenhof'
  },
  {
    postalCode: '3500',
    city: 'Imbach'
  },
  {
    postalCode: '3500',
    city: 'Senftenberg'
  },
  {
    postalCode: '3500',
    city: 'Stratzing'
  },
  {
    postalCode: '3506',
    city: 'Angern'
  },
  {
    postalCode: '3506',
    city: 'Hollenburg'
  },
  {
    postalCode: '3508',
    city: 'Hörfarth'
  },
  {
    postalCode: '3508',
    city: 'Meidling'
  },
  {
    postalCode: '3508',
    city: 'Eggendorf'
  },
  {
    postalCode: '3508',
    city: 'Höbenbach'
  },
  {
    postalCode: '3508',
    city: 'Krustetten'
  },
  {
    postalCode: '3508',
    city: 'Paudorf'
  },
  {
    postalCode: '3508',
    city: 'Tiefenfucha'
  },
  {
    postalCode: '3511',
    city: 'Aigen'
  },
  {
    postalCode: '3511',
    city: 'Furth bei Göttweig'
  },
  {
    postalCode: '3511',
    city: 'Klein-Wien'
  },
  {
    postalCode: '3511',
    city: 'Oberfucha'
  },
  {
    postalCode: '3511',
    city: 'Palt'
  },
  {
    postalCode: '3511',
    city: 'Steinaweg'
  },
  {
    postalCode: '3511',
    city: 'Stift Göttweig'
  },
  {
    postalCode: '3511',
    city: 'Angern'
  },
  {
    postalCode: '3511',
    city: 'Brunnkirchen'
  },
  {
    postalCode: '3511',
    city: 'Thallern'
  },
  {
    postalCode: '3512',
    city: 'Oberbergern'
  },
  {
    postalCode: '3512',
    city: 'Paltmühl'
  },
  {
    postalCode: '3512',
    city: 'Plaimberg'
  },
  {
    postalCode: '3512',
    city: 'Schenkenbrunn'
  },
  {
    postalCode: '3512',
    city: 'Unterbergern'
  },
  {
    postalCode: '3512',
    city: 'Baumgarten'
  },
  {
    postalCode: '3512',
    city: 'Mautern an der Donau'
  },
  {
    postalCode: '3512',
    city: 'Hundsheim'
  },
  {
    postalCode: '3512',
    city: 'Mauternbach'
  },
  {
    postalCode: '3521',
    city: 'Felling'
  },
  {
    postalCode: '3521',
    city: 'Hohenstein'
  },
  {
    postalCode: '3521',
    city: 'Ober-Meisling'
  },
  {
    postalCode: '3521',
    city: 'Unter-Meisling'
  },
  {
    postalCode: '3521',
    city: 'Meislingeramt'
  },
  {
    postalCode: '3521',
    city: 'Nöhagen'
  },
  {
    postalCode: '3521',
    city: 'Reichau'
  },
  {
    postalCode: '3522',
    city: 'Allentsgschwendt'
  },
  {
    postalCode: '3522',
    city: 'Brunn am Wald'
  },
  {
    postalCode: '3522',
    city: 'Ebergersch'
  },
  {
    postalCode: '3522',
    city: 'Erdweis'
  },
  {
    postalCode: '3522',
    city: 'Jeitendorf'
  },
  {
    postalCode: '3522',
    city: 'Ladings'
  },
  {
    postalCode: '3522',
    city: 'Lichtenau'
  },
  {
    postalCode: '3522',
    city: 'Loiwein'
  },
  {
    postalCode: '3522',
    city: 'Obergrünbach'
  },
  {
    postalCode: '3522',
    city: 'Pallweis'
  },
  {
    postalCode: '3522',
    city: 'Scheutz'
  },
  {
    postalCode: '3522',
    city: 'Taubitz'
  },
  {
    postalCode: '3522',
    city: 'Wietzen'
  },
  {
    postalCode: '3522',
    city: 'Wurschenaigen'
  },
  {
    postalCode: '3524',
    city: 'Engelschalks'
  },
  {
    postalCode: '3524',
    city: 'Gloden'
  },
  {
    postalCode: '3524',
    city: 'Großreinprechts'
  },
  {
    postalCode: '3524',
    city: 'Kornberg'
  },
  {
    postalCode: '3524',
    city: 'Grainbrunn'
  },
  {
    postalCode: '3524',
    city: 'Großnondorf'
  },
  {
    postalCode: '3524',
    city: 'Moniholz'
  },
  {
    postalCode: '3524',
    city: 'Kleinhaslau'
  },
  {
    postalCode: '3524',
    city: 'Spielleithen'
  },
  {
    postalCode: '3524',
    city: 'Voitschlag'
  },
  {
    postalCode: '3525',
    city: 'Armschlag'
  },
  {
    postalCode: '3525',
    city: 'Heubach'
  },
  {
    postalCode: '3525',
    city: 'Lugendorf'
  },
  {
    postalCode: '3525',
    city: 'Rabenhof'
  },
  {
    postalCode: '3525',
    city: 'Sallingberg'
  },
  {
    postalCode: '3525',
    city: 'Spielleithen'
  },
  {
    postalCode: '3531',
    city: 'Brand'
  },
  {
    postalCode: '3531',
    city: 'Gutenbrunn'
  },
  {
    postalCode: '3531',
    city: 'Niedernondorf'
  },
  {
    postalCode: '3531',
    city: 'Niederwaltenreith'
  },
  {
    postalCode: '3531',
    city: 'Werschenschlag'
  },
  {
    postalCode: '3531',
    city: 'Wiesenreith'
  },
  {
    postalCode: '3532',
    city: 'Eisengraberamt'
  },
  {
    postalCode: '3532',
    city: 'Marbach im Felde'
  },
  {
    postalCode: '3532',
    city: 'Ottenstein'
  },
  {
    postalCode: '3532',
    city: 'Peygarten-Ottenstein'
  },
  {
    postalCode: '3532',
    city: 'Zierings'
  },
  {
    postalCode: '3532',
    city: 'Mottingeramt'
  },
  {
    postalCode: '3532',
    city: 'Niedergrünbach'
  },
  {
    postalCode: '3532',
    city: 'Rastenberg'
  },
  {
    postalCode: '3532',
    city: 'Rastenfeld'
  },
  {
    postalCode: '3532',
    city: 'Sperkental'
  },
  {
    postalCode: '3532',
    city: 'Friedersbach'
  },
  {
    postalCode: '3533',
    city: 'Eschabruck'
  },
  {
    postalCode: '3533',
    city: 'Friedersbach'
  },
  {
    postalCode: '3533',
    city: 'Kleehof'
  },
  {
    postalCode: '3533',
    city: 'Kleinschönau'
  },
  {
    postalCode: '3533',
    city: 'Mitterreith'
  },
  {
    postalCode: '3533',
    city: 'Oberwaltenreith'
  },
  {
    postalCode: '3533',
    city: 'Wolfsberg'
  },
  {
    postalCode: '3541',
    city: 'Rehberg'
  },
  {
    postalCode: '3541',
    city: 'Imbach'
  },
  {
    postalCode: '3541',
    city: 'Meislingeramt'
  },
  {
    postalCode: '3541',
    city: 'Priel'
  },
  {
    postalCode: '3541',
    city: 'Reichaueramt'
  },
  {
    postalCode: '3541',
    city: 'Senftenberg'
  },
  {
    postalCode: '3541',
    city: 'Senftenbergeramt'
  },
  {
    postalCode: '3541',
    city: 'Ostra'
  },
  {
    postalCode: '3542',
    city: 'Garmanns'
  },
  {
    postalCode: '3542',
    city: 'Gföhl'
  },
  {
    postalCode: '3542',
    city: 'Gföhleramt'
  },
  {
    postalCode: '3542',
    city: 'Großmotten'
  },
  {
    postalCode: '3542',
    city: 'Grottendorf'
  },
  {
    postalCode: '3542',
    city: 'Lengenfelderamt'
  },
  {
    postalCode: '3542',
    city: 'Litschgraben'
  },
  {
    postalCode: '3542',
    city: 'Mittelbergeramt'
  },
  {
    postalCode: '3542',
    city: 'Moritzreith'
  },
  {
    postalCode: '3542',
    city: 'Neubau'
  },
  {
    postalCode: '3542',
    city: 'Rastbach'
  },
  {
    postalCode: '3542',
    city: 'Reisling'
  },
  {
    postalCode: '3542',
    city: 'Reittern'
  },
  {
    postalCode: '3542',
    city: 'Seeb'
  },
  {
    postalCode: '3542',
    city: 'Wurfenthalgraben'
  },
  {
    postalCode: '3542',
    city: 'Eisenbergeramt'
  },
  {
    postalCode: '3542',
    city: 'Eisengraben'
  },
  {
    postalCode: '3542',
    city: 'Eisengraberamt'
  },
  {
    postalCode: '3542',
    city: 'Jaidhof'
  },
  {
    postalCode: '3542',
    city: 'Schiltingeramt'
  },
  {
    postalCode: '3542',
    city: 'Pallweis'
  },
  {
    postalCode: '3543',
    city: 'Eisengraberamt'
  },
  {
    postalCode: '3543',
    city: 'Krumau am Kamp'
  },
  {
    postalCode: '3543',
    city: 'Krumauer Waldhütten'
  },
  {
    postalCode: '3543',
    city: 'Preinreichs'
  },
  {
    postalCode: '3543',
    city: 'Unterdobrawaldhütten'
  },
  {
    postalCode: '3543',
    city: 'Tiefenbach'
  },
  {
    postalCode: '3543',
    city: 'Mottingeramt'
  },
  {
    postalCode: '3544',
    city: 'Eisenberg'
  },
  {
    postalCode: '3544',
    city: 'Idolsberg'
  },
  {
    postalCode: '3544',
    city: 'Thurnberg'
  },
  {
    postalCode: '3544',
    city: 'Preinreichs'
  },
  {
    postalCode: '3550',
    city: 'Kammern'
  },
  {
    postalCode: '3550',
    city: 'Gobelsburg'
  },
  {
    postalCode: '3550',
    city: 'Zeiselberg'
  },
  {
    postalCode: '3550',
    city: 'Langenlois'
  },
  {
    postalCode: '3550',
    city: 'Mittelberg'
  },
  {
    postalCode: '3552',
    city: 'Droß'
  },
  {
    postalCode: '3552',
    city: 'Droßeramt'
  },
  {
    postalCode: '3552',
    city: 'Lengenfeld'
  },
  {
    postalCode: '3552',
    city: 'Stratzing'
  },
  {
    postalCode: '3553',
    city: 'Mittelbergeramt'
  },
  {
    postalCode: '3553',
    city: 'Schiltingeramt'
  },
  {
    postalCode: '3553',
    city: 'Reith'
  },
  {
    postalCode: '3553',
    city: 'Schiltern'
  },
  {
    postalCode: '3561',
    city: 'Langenlois'
  },
  {
    postalCode: '3561',
    city: 'Zöbing'
  },
  {
    postalCode: '3562',
    city: 'Schönberg'
  },
  {
    postalCode: '3562',
    city: 'Buchberger Waldhütten'
  },
  {
    postalCode: '3562',
    city: 'Thürneustift'
  },
  {
    postalCode: '3562',
    city: 'Mollands'
  },
  {
    postalCode: '3562',
    city: 'Schönberg-Neustift'
  },
  {
    postalCode: '3562',
    city: 'See'
  },
  {
    postalCode: '3562',
    city: 'Stiefern'
  },
  {
    postalCode: '3564',
    city: 'Altenhof'
  },
  {
    postalCode: '3564',
    city: 'Fernitz'
  },
  {
    postalCode: '3564',
    city: 'Oberplank'
  },
  {
    postalCode: '3564',
    city: 'Plank am Kamp'
  },
  {
    postalCode: '3564',
    city: 'Freischling'
  },
  {
    postalCode: '3564',
    city: 'Kriegenreith'
  },
  {
    postalCode: '3564',
    city: 'Raan'
  },
  {
    postalCode: '3571',
    city: 'Buchberg am Kamp'
  },
  {
    postalCode: '3571',
    city: 'Gars am Kamp'
  },
  {
    postalCode: '3571',
    city: 'Kamegg'
  },
  {
    postalCode: '3571',
    city: 'Kotzendorf'
  },
  {
    postalCode: '3571',
    city: 'Loibersdorf'
  },
  {
    postalCode: '3571',
    city: 'Maiersch'
  },
  {
    postalCode: '3571',
    city: 'Nonndorf bei Gars'
  },
  {
    postalCode: '3571',
    city: 'Tautendorf'
  },
  {
    postalCode: '3571',
    city: 'Thunau am Kamp'
  },
  {
    postalCode: '3571',
    city: 'Wolfshof'
  },
  {
    postalCode: '3571',
    city: 'Zitternberg'
  },
  {
    postalCode: '3571',
    city: 'Stallegg'
  },
  {
    postalCode: '3571',
    city: 'Untertautendorferamt'
  },
  {
    postalCode: '3572',
    city: 'Eisenbergeramt'
  },
  {
    postalCode: '3572',
    city: 'Obertautendorferamt'
  },
  {
    postalCode: '3572',
    city: 'St. Leonhard am Hornerwald'
  },
  {
    postalCode: '3572',
    city: 'Wilhalm'
  },
  {
    postalCode: '3572',
    city: 'Wolfshoferamt'
  },
  {
    postalCode: '3573',
    city: 'Etzmannsdorf am Kamp'
  },
  {
    postalCode: '3573',
    city: 'Wanzenau'
  },
  {
    postalCode: '3573',
    city: 'Rosenburg'
  },
  {
    postalCode: '3580',
    city: 'Breiteneich'
  },
  {
    postalCode: '3580',
    city: 'Horn'
  },
  {
    postalCode: '3580',
    city: 'Mödring'
  },
  {
    postalCode: '3580',
    city: 'Mühlfeld'
  },
  {
    postalCode: '3580',
    city: 'Mörtersdorf'
  },
  {
    postalCode: '3580',
    city: 'Mold'
  },
  {
    postalCode: '3580',
    city: 'Rosenburg'
  },
  {
    postalCode: '3580',
    city: 'Zaingrub'
  },
  {
    postalCode: '3580',
    city: 'Frauenhofen'
  },
  {
    postalCode: '3580',
    city: 'Groß Burgstall'
  },
  {
    postalCode: '3580',
    city: 'Grünberg'
  },
  {
    postalCode: '3580',
    city: 'Poigen'
  },
  {
    postalCode: '3580',
    city: 'St. Bernhard'
  },
  {
    postalCode: '3580',
    city: 'Strögen'
  },
  {
    postalCode: '3591',
    city: 'Altenburg'
  },
  {
    postalCode: '3591',
    city: 'Burgerwiesen'
  },
  {
    postalCode: '3591',
    city: 'Fuglau'
  },
  {
    postalCode: '3591',
    city: 'Mahrersdorf'
  },
  {
    postalCode: '3591',
    city: 'Steinegg'
  },
  {
    postalCode: '3592',
    city: 'Feinfeld'
  },
  {
    postalCode: '3592',
    city: 'Gobelsdorf'
  },
  {
    postalCode: '3592',
    city: 'Greillenstein'
  },
  {
    postalCode: '3592',
    city: 'Neubau'
  },
  {
    postalCode: '3592',
    city: 'Röhrenbach'
  },
  {
    postalCode: '3592',
    city: 'Tautendorf'
  },
  {
    postalCode: '3592',
    city: 'Winkl'
  },
  {
    postalCode: '3593',
    city: 'Altpölla'
  },
  {
    postalCode: '3593',
    city: 'Kleinenzersdorf'
  },
  {
    postalCode: '3593',
    city: 'Kleinraabs'
  },
  {
    postalCode: '3593',
    city: 'Krug'
  },
  {
    postalCode: '3593',
    city: 'Neupölla'
  },
  {
    postalCode: '3593',
    city: 'Ramsau'
  },
  {
    postalCode: '3593',
    city: 'Wegscheid am Kamp'
  },
  {
    postalCode: '3593',
    city: 'Germanns'
  },
  {
    postalCode: '3594',
    city: 'Franzen'
  },
  {
    postalCode: '3594',
    city: 'Kienberg'
  },
  {
    postalCode: '3594',
    city: 'Nondorf'
  },
  {
    postalCode: '3594',
    city: 'Reichhalms'
  },
  {
    postalCode: '3594',
    city: 'Schmerbach am Kamp'
  },
  {
    postalCode: '3594',
    city: 'Waldreichs'
  },
  {
    postalCode: '3594',
    city: 'Wetzlas'
  },
  {
    postalCode: '3595',
    city: 'Atzelsdorf'
  },
  {
    postalCode: '3595',
    city: 'Brunn an der Wild'
  },
  {
    postalCode: '3595',
    city: 'Dappach'
  },
  {
    postalCode: '3595',
    city: 'Dietmannsdorf an der Wild'
  },
  {
    postalCode: '3595',
    city: 'Frankenreith'
  },
  {
    postalCode: '3595',
    city: 'Fürwald'
  },
  {
    postalCode: '3595',
    city: 'Neukirchen an der Wild'
  },
  {
    postalCode: '3595',
    city: 'St. Marein'
  },
  {
    postalCode: '3595',
    city: 'Waiden'
  },
  {
    postalCode: '3595',
    city: 'Wutzendorf'
  },
  {
    postalCode: '3601',
    city: 'Dürnstein'
  },
  {
    postalCode: '3601',
    city: 'Dürnsteiner Waldhütten'
  },
  {
    postalCode: '3601',
    city: 'Oberloiben'
  },
  {
    postalCode: '3601',
    city: 'Rothenhof'
  },
  {
    postalCode: '3601',
    city: 'Unterloiben'
  },
  {
    postalCode: '3601',
    city: 'Weißenkirchen in der Wachau'
  },
  {
    postalCode: '3602',
    city: 'Rossatz'
  },
  {
    postalCode: '3602',
    city: 'Rossatzbach'
  },
  {
    postalCode: '3602',
    city: 'Rührsdorf'
  },
  {
    postalCode: '3602',
    city: 'St. Lorenz'
  },
  {
    postalCode: '3610',
    city: 'Maigen'
  },
  {
    postalCode: '3610',
    city: 'Stixendorf'
  },
  {
    postalCode: '3610',
    city: 'Weinzierl am Walde'
  },
  {
    postalCode: '3610',
    city: 'Joching'
  },
  {
    postalCode: '3610',
    city: 'Weißenkirchen in der Wachau'
  },
  {
    postalCode: '3610',
    city: 'Wösendorf in der Wachau'
  },
  {
    postalCode: '3610',
    city: 'St. Michael'
  },
  {
    postalCode: '3611',
    city: 'Großheinrichschlag'
  },
  {
    postalCode: '3611',
    city: 'Habruck'
  },
  {
    postalCode: '3611',
    city: 'Himberg'
  },
  {
    postalCode: '3611',
    city: 'Lobendorf'
  },
  {
    postalCode: '3611',
    city: 'Neusiedl'
  },
  {
    postalCode: '3611',
    city: 'Wolfenreith'
  },
  {
    postalCode: '3613',
    city: 'Albrechtsberg an der Großen Krems'
  },
  {
    postalCode: '3613',
    city: 'Arzwiesen'
  },
  {
    postalCode: '3613',
    city: 'Attenreith'
  },
  {
    postalCode: '3613',
    city: 'Els'
  },
  {
    postalCode: '3613',
    city: 'Eppenberg'
  },
  {
    postalCode: '3613',
    city: 'Gillaus'
  },
  {
    postalCode: '3613',
    city: 'Harrau'
  },
  {
    postalCode: '3613',
    city: 'Klein-Heinrichschlag'
  },
  {
    postalCode: '3613',
    city: 'Marbach an der Kleinen Krems'
  },
  {
    postalCode: '3613',
    city: 'Purkersdorf'
  },
  {
    postalCode: '3613',
    city: 'Scheutz'
  },
  {
    postalCode: '3613',
    city: 'Nöhagen'
  },
  {
    postalCode: '3620',
    city: 'Benking'
  },
  {
    postalCode: '3620',
    city: 'Mitterndorf'
  },
  {
    postalCode: '3620',
    city: 'Oberndorf'
  },
  {
    postalCode: '3620',
    city: 'Gut am Steg'
  },
  {
    postalCode: '3620',
    city: 'Vießling'
  },
  {
    postalCode: '3620',
    city: 'Schwallenbach'
  },
  {
    postalCode: '3620',
    city: 'Spitz'
  },
  {
    postalCode: '3621',
    city: 'Bacharnsdorf'
  },
  {
    postalCode: '3621',
    city: 'Hofarnsdorf'
  },
  {
    postalCode: '3621',
    city: 'Mitterarnsdorf'
  },
  {
    postalCode: '3621',
    city: 'Oberarnsdorf'
  },
  {
    postalCode: '3621',
    city: 'St. Johann im Mauerthale'
  },
  {
    postalCode: '3622',
    city: 'Doppl'
  },
  {
    postalCode: '3622',
    city: 'Fohra'
  },
  {
    postalCode: '3622',
    city: 'Gschwendt'
  },
  {
    postalCode: '3622',
    city: 'Runds'
  },
  {
    postalCode: '3622',
    city: 'Trittings'
  },
  {
    postalCode: '3622',
    city: 'Wernhies'
  },
  {
    postalCode: '3622',
    city: 'Schoberhof'
  },
  {
    postalCode: '3622',
    city: 'Elsarn am Jauerling'
  },
  {
    postalCode: '3622',
    city: 'Povat'
  },
  {
    postalCode: '3622',
    city: 'Mühldorf'
  },
  {
    postalCode: '3622',
    city: 'Niederranna'
  },
  {
    postalCode: '3622',
    city: 'Oberranna'
  },
  {
    postalCode: '3622',
    city: 'Ötz'
  },
  {
    postalCode: '3622',
    city: 'Ötzbach'
  },
  {
    postalCode: '3622',
    city: 'Amstall'
  },
  {
    postalCode: '3622',
    city: 'Trandorf'
  },
  {
    postalCode: '3623',
    city: 'Bernhards'
  },
  {
    postalCode: '3623',
    city: 'Dankholz'
  },
  {
    postalCode: '3623',
    city: 'Elsenreith'
  },
  {
    postalCode: '3623',
    city: 'Felles'
  },
  {
    postalCode: '3623',
    city: 'Gotthardschlag'
  },
  {
    postalCode: '3623',
    city: 'Günsles'
  },
  {
    postalCode: '3623',
    city: 'Heitzles'
  },
  {
    postalCode: '3623',
    city: 'Hörans'
  },
  {
    postalCode: '3623',
    city: 'Kalkgrub'
  },
  {
    postalCode: '3623',
    city: 'Kottes'
  },
  {
    postalCode: '3623',
    city: 'Leopolds'
  },
  {
    postalCode: '3623',
    city: 'Münichreith'
  },
  {
    postalCode: '3623',
    city: 'Pfaffenschlag'
  },
  {
    postalCode: '3623',
    city: 'Pötzles'
  },
  {
    postalCode: '3623',
    city: 'Purk'
  },
  {
    postalCode: '3623',
    city: 'Reichpolds'
  },
  {
    postalCode: '3623',
    city: 'Richterhof'
  },
  {
    postalCode: '3623',
    city: 'Singenreith'
  },
  {
    postalCode: '3623',
    city: 'Teichmanns'
  },
  {
    postalCode: '3623',
    city: 'Voirans'
  },
  {
    postalCode: '3623',
    city: 'Voitsau'
  },
  {
    postalCode: '3623',
    city: 'Weikartschlag'
  },
  {
    postalCode: '3623',
    city: 'Koppenhof'
  },
  {
    postalCode: '3623',
    city: 'Ensberg'
  },
  {
    postalCode: '3623',
    city: 'Ernst'
  },
  {
    postalCode: '3631',
    city: 'Bernhardshof'
  },
  {
    postalCode: '3631',
    city: 'Eck'
  },
  {
    postalCode: '3631',
    city: 'Gaßles'
  },
  {
    postalCode: '3631',
    city: 'Kienings'
  },
  {
    postalCode: '3631',
    city: 'Kirchschlag'
  },
  {
    postalCode: '3631',
    city: 'Merkengerst'
  },
  {
    postalCode: '3631',
    city: 'Pleßberg'
  },
  {
    postalCode: '3631',
    city: 'Scheib'
  },
  {
    postalCode: '3631',
    city: 'Schneeberg'
  },
  {
    postalCode: '3631',
    city: 'Bernreith'
  },
  {
    postalCode: '3631',
    city: 'Endlas'
  },
  {
    postalCode: '3631',
    city: 'Jungschlag'
  },
  {
    postalCode: '3631',
    city: 'Neuhof'
  },
  {
    postalCode: '3631',
    city: 'Oedwinkel'
  },
  {
    postalCode: '3631',
    city: 'Ottenschlag'
  },
  {
    postalCode: '3631',
    city: 'Reith'
  },
  {
    postalCode: '3632',
    city: 'Aschen'
  },
  {
    postalCode: '3632',
    city: 'Pernthon'
  },
  {
    postalCode: '3632',
    city: 'Biberschlag'
  },
  {
    postalCode: '3632',
    city: 'Dietmanns'
  },
  {
    postalCode: '3632',
    city: 'Gürtelberg'
  },
  {
    postalCode: '3632',
    city: 'Haselberg'
  },
  {
    postalCode: '3632',
    city: 'Hummelberg'
  },
  {
    postalCode: '3632',
    city: 'Kaltenbach'
  },
  {
    postalCode: '3632',
    city: 'Pfaffings'
  },
  {
    postalCode: '3632',
    city: 'Prettles'
  },
  {
    postalCode: '3632',
    city: 'Schönau'
  },
  {
    postalCode: '3632',
    city: 'Spielberg'
  },
  {
    postalCode: '3632',
    city: 'Stein'
  },
  {
    postalCode: '3632',
    city: 'Traunstein'
  },
  {
    postalCode: '3632',
    city: 'Walterschlag'
  },
  {
    postalCode: '3632',
    city: 'Weidenegg'
  },
  {
    postalCode: '3633',
    city: 'Großpertenschlag'
  },
  {
    postalCode: '3633',
    city: 'Kleinpertenschlag'
  },
  {
    postalCode: '3633',
    city: 'Bärnkopf'
  },
  {
    postalCode: '3633',
    city: 'Dorfstadt'
  },
  {
    postalCode: '3633',
    city: 'Fichtenhöfen'
  },
  {
    postalCode: '3633',
    city: 'Grub im Thale'
  },
  {
    postalCode: '3633',
    city: 'Klein-Siegharts'
  },
  {
    postalCode: '3633',
    city: 'Lengau'
  },
  {
    postalCode: '3633',
    city: 'Lichtenau'
  },
  {
    postalCode: '3633',
    city: 'Lohn'
  },
  {
    postalCode: '3633',
    city: 'Pernthon'
  },
  {
    postalCode: '3633',
    city: 'Schönbach'
  },
  {
    postalCode: '3633',
    city: 'Stein'
  },
  {
    postalCode: '3641',
    city: 'Aggsbach Markt'
  },
  {
    postalCode: '3641',
    city: 'Groisbach'
  },
  {
    postalCode: '3641',
    city: 'Köfering'
  },
  {
    postalCode: '3641',
    city: 'Willendorf in der Wachau'
  },
  {
    postalCode: '3642',
    city: 'Maria Langegg'
  },
  {
    postalCode: '3642',
    city: 'Häusling'
  },
  {
    postalCode: '3642',
    city: 'Heitzing'
  },
  {
    postalCode: '3642',
    city: 'Kochholz'
  },
  {
    postalCode: '3642',
    city: 'Ohnreith'
  },
  {
    postalCode: '3642',
    city: 'Aggsbach-Dorf'
  },
  {
    postalCode: '3642',
    city: 'Aggstein'
  },
  {
    postalCode: '3642',
    city: 'Gschwendt'
  },
  {
    postalCode: '3642',
    city: 'Siedelgraben'
  },
  {
    postalCode: '3642',
    city: 'Wolfstein'
  },
  {
    postalCode: '3643',
    city: 'Aggsbach Markt'
  },
  {
    postalCode: '3643',
    city: 'Felbring'
  },
  {
    postalCode: '3643',
    city: 'Gießhübl'
  },
  {
    postalCode: '3643',
    city: 'Hof'
  },
  {
    postalCode: '3643',
    city: 'Kuffarn'
  },
  {
    postalCode: '3643',
    city: 'Litzendorf'
  },
  {
    postalCode: '3643',
    city: 'Maria Laach am Jauerling'
  },
  {
    postalCode: '3643',
    city: 'Schlaubing'
  },
  {
    postalCode: '3643',
    city: 'Thalham'
  },
  {
    postalCode: '3643',
    city: 'Weinberg'
  },
  {
    postalCode: '3643',
    city: 'Wiesmannsreith'
  },
  {
    postalCode: '3643',
    city: 'Zeißing'
  },
  {
    postalCode: '3643',
    city: 'Zintring'
  },
  {
    postalCode: '3643',
    city: 'Friedersdorf'
  },
  {
    postalCode: '3643',
    city: 'Haslarn'
  },
  {
    postalCode: '3643',
    city: 'Hinterkogel'
  },
  {
    postalCode: '3643',
    city: 'Loitzendorf'
  },
  {
    postalCode: '3643',
    city: 'Nonnersdorf'
  },
  {
    postalCode: '3644',
    city: 'Emmersdorf an der Donau'
  },
  {
    postalCode: '3644',
    city: 'Fahnsdorf'
  },
  {
    postalCode: '3644',
    city: 'Goßam'
  },
  {
    postalCode: '3644',
    city: 'Grimsing'
  },
  {
    postalCode: '3644',
    city: 'Hain'
  },
  {
    postalCode: '3644',
    city: 'Hofamt'
  },
  {
    postalCode: '3644',
    city: 'Mödelsdorf'
  },
  {
    postalCode: '3644',
    city: 'Pömling'
  },
  {
    postalCode: '3644',
    city: 'Rantenberg'
  },
  {
    postalCode: '3644',
    city: 'Reith'
  },
  {
    postalCode: '3644',
    city: 'St. Georgen'
  },
  {
    postalCode: '3644',
    city: 'Schallemmersdorf'
  },
  {
    postalCode: '3644',
    city: 'Luberegg'
  },
  {
    postalCode: '3650',
    city: 'Kehrbach'
  },
  {
    postalCode: '3650',
    city: 'Arndorf'
  },
  {
    postalCode: '3650',
    city: 'Brennhof'
  },
  {
    postalCode: '3650',
    city: 'Bruck am Ostrong'
  },
  {
    postalCode: '3650',
    city: 'Gottsberg'
  },
  {
    postalCode: '3650',
    city: 'Grub bei Neukirchen am Ostrong'
  },
  {
    postalCode: '3650',
    city: 'Haag'
  },
  {
    postalCode: '3650',
    city: 'Landstetten'
  },
  {
    postalCode: '3650',
    city: 'Mürfelndorf'
  },
  {
    postalCode: '3650',
    city: 'Neukirchen am Ostrong'
  },
  {
    postalCode: '3650',
    city: 'Oberbierbaum'
  },
  {
    postalCode: '3650',
    city: 'Oberdörfl'
  },
  {
    postalCode: '3650',
    city: 'Oberhohenau'
  },
  {
    postalCode: '3650',
    city: 'Prinzelndorf'
  },
  {
    postalCode: '3650',
    city: 'Unterhohenau'
  },
  {
    postalCode: '3650',
    city: 'Wachtberg'
  },
  {
    postalCode: '3650',
    city: 'Weißpyhra'
  },
  {
    postalCode: '3650',
    city: 'Zöbring'
  },
  {
    postalCode: '3650',
    city: 'Annagschmais'
  },
  {
    postalCode: '3650',
    city: 'Aschelberg'
  },
  {
    postalCode: '3650',
    city: 'Bergern bei Pöggstall'
  },
  {
    postalCode: '3650',
    city: 'Dietsam'
  },
  {
    postalCode: '3650',
    city: 'Gerersdorf'
  },
  {
    postalCode: '3650',
    city: 'Grub bei Aschelberg'
  },
  {
    postalCode: '3650',
    city: 'Krempersbach'
  },
  {
    postalCode: '3650',
    city: 'Krumling'
  },
  {
    postalCode: '3650',
    city: 'Laas'
  },
  {
    postalCode: '3650',
    city: 'Loibersdorf'
  },
  {
    postalCode: '3650',
    city: 'Muckendorf'
  },
  {
    postalCode: '3650',
    city: 'Oed'
  },
  {
    postalCode: '3650',
    city: 'Pöggstall'
  },
  {
    postalCode: '3650',
    city: 'Pömmerstall'
  },
  {
    postalCode: '3650',
    city: 'Sading'
  },
  {
    postalCode: '3650',
    city: 'Straßreith'
  },
  {
    postalCode: '3650',
    city: 'Würnsdorf'
  },
  {
    postalCode: '3650',
    city: 'Weinling'
  },
  {
    postalCode: '3650',
    city: 'Braunegg'
  },
  {
    postalCode: '3650',
    city: 'Steinbach'
  },
  {
    postalCode: '3650',
    city: 'Troibetsberg'
  },
  {
    postalCode: '3652',
    city: 'Aichau'
  },
  {
    postalCode: '3652',
    city: 'Payerstetten'
  },
  {
    postalCode: '3652',
    city: 'Trennegg'
  },
  {
    postalCode: '3652',
    city: 'St. Georgen'
  },
  {
    postalCode: '3652',
    city: 'Klein-Pöchlarn'
  },
  {
    postalCode: '3652',
    city: 'Ebersdorf'
  },
  {
    postalCode: '3652',
    city: 'Kaumberg'
  },
  {
    postalCode: '3652',
    city: 'Lehen'
  },
  {
    postalCode: '3652',
    city: 'Leiben'
  },
  {
    postalCode: '3652',
    city: 'Losau'
  },
  {
    postalCode: '3652',
    city: 'Mampasberg'
  },
  {
    postalCode: '3652',
    city: 'Weinzierl'
  },
  {
    postalCode: '3652',
    city: 'Urfahr'
  },
  {
    postalCode: '3652',
    city: 'Weitenegg'
  },
  {
    postalCode: '3653',
    city: 'Aichau'
  },
  {
    postalCode: '3653',
    city: 'Fahnsdorf'
  },
  {
    postalCode: '3653',
    city: 'Leiben'
  },
  {
    postalCode: '3653',
    city: 'Gerersdorf'
  },
  {
    postalCode: '3653',
    city: 'Feistritz'
  },
  {
    postalCode: '3653',
    city: 'Mannersdorf'
  },
  {
    postalCode: '3653',
    city: 'Moos'
  },
  {
    postalCode: '3653',
    city: 'Neusiedl am Feldstein'
  },
  {
    postalCode: '3653',
    city: 'Ottenberg'
  },
  {
    postalCode: '3653',
    city: 'Robans'
  },
  {
    postalCode: '3653',
    city: 'Walkersdorf'
  },
  {
    postalCode: '3653',
    city: 'Zehentegg'
  },
  {
    postalCode: '3653',
    city: 'Zogelsdorf'
  },
  {
    postalCode: '3653',
    city: 'Eibetsberg'
  },
  {
    postalCode: '3653',
    city: 'Eitental'
  },
  {
    postalCode: '3653',
    city: 'Filsendorf'
  },
  {
    postalCode: '3653',
    city: 'Jasenegg'
  },
  {
    postalCode: '3653',
    city: 'Mörenz'
  },
  {
    postalCode: '3653',
    city: 'Mollenburg'
  },
  {
    postalCode: '3653',
    city: 'Mollendorf'
  },
  {
    postalCode: '3653',
    city: 'Nasting'
  },
  {
    postalCode: '3653',
    city: 'Rafles'
  },
  {
    postalCode: '3653',
    city: 'Seiterndorf'
  },
  {
    postalCode: '3653',
    city: 'Streitwiesen'
  },
  {
    postalCode: '3653',
    city: 'Tottendorf'
  },
  {
    postalCode: '3653',
    city: 'Weiten'
  },
  {
    postalCode: '3653',
    city: 'Weiterndorf'
  },
  {
    postalCode: '3654',
    city: 'Afterbach'
  },
  {
    postalCode: '3654',
    city: 'Eibetsberg'
  },
  {
    postalCode: '3654',
    city: 'Feistritz'
  },
  {
    postalCode: '3654',
    city: 'Klebing'
  },
  {
    postalCode: '3654',
    city: 'Laufenegg'
  },
  {
    postalCode: '3654',
    city: 'Lehsdorf'
  },
  {
    postalCode: '3654',
    city: 'Neudorf'
  },
  {
    postalCode: '3654',
    city: 'Neusiedl bei Pfaffenhof'
  },
  {
    postalCode: '3654',
    city: 'Ottenberg'
  },
  {
    postalCode: '3654',
    city: 'Pfaffenhof'
  },
  {
    postalCode: '3654',
    city: 'Pölla'
  },
  {
    postalCode: '3654',
    city: 'Raxendorf'
  },
  {
    postalCode: '3654',
    city: 'Steinbach'
  },
  {
    postalCode: '3654',
    city: 'Zeining'
  },
  {
    postalCode: '3660',
    city: 'Klein-Pöchlarn'
  },
  {
    postalCode: '3661',
    city: 'Artstetten'
  },
  {
    postalCode: '3661',
    city: 'Dölla'
  },
  {
    postalCode: '3661',
    city: 'Fritzelsdorf'
  },
  {
    postalCode: '3661',
    city: 'Hart'
  },
  {
    postalCode: '3661',
    city: 'Hasling'
  },
  {
    postalCode: '3661',
    city: 'Lohsdorf'
  },
  {
    postalCode: '3661',
    city: 'Nussendorf'
  },
  {
    postalCode: '3661',
    city: 'Oberndorf'
  },
  {
    postalCode: '3661',
    city: 'Pleißing'
  },
  {
    postalCode: '3661',
    city: 'Pöbring'
  },
  {
    postalCode: '3661',
    city: 'Schwarzau'
  },
  {
    postalCode: '3661',
    city: 'Unterbierbaum'
  },
  {
    postalCode: '3661',
    city: 'Unterthalheim'
  },
  {
    postalCode: '3661',
    city: 'Rappoltenreith'
  },
  {
    postalCode: '3662',
    city: 'Edelsreith'
  },
  {
    postalCode: '3662',
    city: 'Kehrbach'
  },
  {
    postalCode: '3662',
    city: 'Kollnitz'
  },
  {
    postalCode: '3662',
    city: 'Mayerhofen'
  },
  {
    postalCode: '3662',
    city: 'Münichreith'
  },
  {
    postalCode: '3662',
    city: 'Pargatstetten'
  },
  {
    postalCode: '3662',
    city: 'Rappoltenreith'
  },
  {
    postalCode: '3663',
    city: 'Eggathon'
  },
  {
    postalCode: '3663',
    city: 'Gmaining'
  },
  {
    postalCode: '3663',
    city: 'Laimbach am Ostrong'
  },
  {
    postalCode: '3663',
    city: 'Zöbring'
  },
  {
    postalCode: '3663',
    city: 'Würnsdorf'
  },
  {
    postalCode: '3663',
    city: 'Weinling'
  },
  {
    postalCode: '3664',
    city: 'Roggenreith'
  },
  {
    postalCode: '3664',
    city: 'Kleingerungs'
  },
  {
    postalCode: '3664',
    city: 'Kleinpertholz'
  },
  {
    postalCode: '3664',
    city: 'Loitzenreith'
  },
  {
    postalCode: '3664',
    city: 'Martinsberg'
  },
  {
    postalCode: '3664',
    city: 'Mitterndorf'
  },
  {
    postalCode: '3664',
    city: 'Oed'
  },
  {
    postalCode: '3664',
    city: 'Pitzeichen'
  },
  {
    postalCode: '3664',
    city: 'Poggschlag'
  },
  {
    postalCode: '3664',
    city: 'Reitzendorf'
  },
  {
    postalCode: '3664',
    city: 'Thumling'
  },
  {
    postalCode: '3664',
    city: 'Walpersdorf'
  },
  {
    postalCode: '3664',
    city: 'Weixelberg'
  },
  {
    postalCode: '3664',
    city: 'Wiehalm'
  },
  {
    postalCode: '3664',
    city: 'Prettles'
  },
  {
    postalCode: '3665',
    city: 'Bärnkopf'
  },
  {
    postalCode: '3665',
    city: 'Gutenbrunn'
  },
  {
    postalCode: '3665',
    city: 'Ulrichschlag'
  },
  {
    postalCode: '3665',
    city: 'Edlesberg'
  },
  {
    postalCode: '3665',
    city: 'Oed'
  },
  {
    postalCode: '3671',
    city: 'Auratsberg'
  },
  {
    postalCode: '3671',
    city: 'Friesenegg'
  },
  {
    postalCode: '3671',
    city: 'Granz'
  },
  {
    postalCode: '3671',
    city: 'Kracking'
  },
  {
    postalCode: '3671',
    city: 'Krummnußbaum an der Donauuferbahn'
  },
  {
    postalCode: '3671',
    city: 'Marbach an der Donau'
  },
  {
    postalCode: '3671',
    city: 'Schaufel'
  },
  {
    postalCode: '3672',
    city: 'Marbach an der Donau'
  },
  {
    postalCode: '3672',
    city: 'Maria Taferl'
  },
  {
    postalCode: '3672',
    city: 'Obererla'
  },
  {
    postalCode: '3672',
    city: 'Oberthalheim'
  },
  {
    postalCode: '3672',
    city: 'Reitern'
  },
  {
    postalCode: '3672',
    city: 'Untererla'
  },
  {
    postalCode: '3672',
    city: 'Unterthalheim'
  },
  {
    postalCode: '3672',
    city: 'Wimm'
  },
  {
    postalCode: '3672',
    city: 'Hilmanger'
  },
  {
    postalCode: '3680',
    city: 'Hofamt Priel'
  },
  {
    postalCode: '3680',
    city: 'Ysperdorf'
  },
  {
    postalCode: '3680',
    city: 'Rottenberg'
  },
  {
    postalCode: '3680',
    city: 'Rottenhof'
  },
  {
    postalCode: '3680',
    city: 'Weins'
  },
  {
    postalCode: '3680',
    city: 'Freigericht'
  },
  {
    postalCode: '3680',
    city: 'Mitterndorf'
  },
  {
    postalCode: '3680',
    city: 'Niederndorf'
  },
  {
    postalCode: '3680',
    city: 'Gottsdorf'
  },
  {
    postalCode: '3680',
    city: 'Hagsdorf'
  },
  {
    postalCode: '3680',
    city: 'Loja'
  },
  {
    postalCode: '3680',
    city: 'Metzling'
  },
  {
    postalCode: '3680',
    city: 'Persenbeug'
  },
  {
    postalCode: '3683',
    city: 'Hofamt Priel'
  },
  {
    postalCode: '3683',
    city: 'Urthaleramt'
  },
  {
    postalCode: '3683',
    city: 'Haslau'
  },
  {
    postalCode: '3683',
    city: 'Kapelleramt'
  },
  {
    postalCode: '3683',
    city: 'Nächst Altenmarkt'
  },
  {
    postalCode: '3683',
    city: 'Wimberg'
  },
  {
    postalCode: '3683',
    city: 'Yspertal'
  },
  {
    postalCode: '3684',
    city: 'Fünflingeramt'
  },
  {
    postalCode: '3684',
    city: 'Loseneggeramt'
  },
  {
    postalCode: '3684',
    city: 'St. Oswald'
  },
  {
    postalCode: '3684',
    city: 'Stiegeramt'
  },
  {
    postalCode: '3684',
    city: 'Urthaleramt'
  },
  {
    postalCode: '3691',
    city: 'Artneramt'
  },
  {
    postalCode: '3691',
    city: 'Baumgartenberg'
  },
  {
    postalCode: '3691',
    city: 'Freigericht'
  },
  {
    postalCode: '3691',
    city: 'Gulling'
  },
  {
    postalCode: '3691',
    city: 'Mitterndorf'
  },
  {
    postalCode: '3691',
    city: 'Niederndorf'
  },
  {
    postalCode: '3691',
    city: 'Nöchling'
  },
  {
    postalCode: '3701',
    city: 'Ameistal'
  },
  {
    postalCode: '3701',
    city: 'Baumgarten am Wagram'
  },
  {
    postalCode: '3701',
    city: 'Großweikersdorf'
  },
  {
    postalCode: '3701',
    city: 'Großwiesendorf'
  },
  {
    postalCode: '3701',
    city: 'Kleinwiesendorf'
  },
  {
    postalCode: '3701',
    city: 'Ruppersthal'
  },
  {
    postalCode: '3701',
    city: 'Tiefenthal'
  },
  {
    postalCode: '3701',
    city: 'Oberthern'
  },
  {
    postalCode: '3701',
    city: 'Unterthern'
  },
  {
    postalCode: '3701',
    city: 'Zaußenberg'
  },
  {
    postalCode: '3701',
    city: 'Inkersdorf'
  },
  {
    postalCode: '3702',
    city: 'Oberrußbach'
  },
  {
    postalCode: '3702',
    city: 'Niederrußbach'
  },
  {
    postalCode: '3702',
    city: 'Stranzendorf'
  },
  {
    postalCode: '3704',
    city: 'Glaubendorf'
  },
  {
    postalCode: '3704',
    city: 'Großwetzdorf'
  },
  {
    postalCode: '3704',
    city: 'Kleinwetzdorf'
  },
  {
    postalCode: '3710',
    city: 'Glaubendorf'
  },
  {
    postalCode: '3710',
    city: 'Frauendorf an der Schmida'
  },
  {
    postalCode: '3710',
    city: 'Dippersdorf'
  },
  {
    postalCode: '3710',
    city: 'Fahndorf'
  },
  {
    postalCode: '3710',
    city: 'Gettsdorf'
  },
  {
    postalCode: '3710',
    city: 'Hollenstein'
  },
  {
    postalCode: '3710',
    city: 'Kiblitz'
  },
  {
    postalCode: '3710',
    city: 'Radlbrunn'
  },
  {
    postalCode: '3710',
    city: 'Rohrbach'
  },
  {
    postalCode: '3710',
    city: 'Ziersdorf'
  },
  {
    postalCode: '3711',
    city: 'Ebersbrunn'
  },
  {
    postalCode: '3711',
    city: 'Großmeiseldorf'
  },
  {
    postalCode: '3712',
    city: 'Eggendorf am Walde'
  },
  {
    postalCode: '3712',
    city: 'Grübern'
  },
  {
    postalCode: '3712',
    city: 'Maissau'
  },
  {
    postalCode: '3712',
    city: 'Wilhelmsdorf'
  },
  {
    postalCode: '3713',
    city: 'Amelsdorf'
  },
  {
    postalCode: '3713',
    city: 'Buttendorf'
  },
  {
    postalCode: '3713',
    city: 'Matzelsdorf'
  },
  {
    postalCode: '3713',
    city: 'Reinprechtspölla'
  },
  {
    postalCode: '3713',
    city: 'Sachsendorf'
  },
  {
    postalCode: '3713',
    city: 'Harmannsdorf'
  },
  {
    postalCode: '3713',
    city: 'Gumping'
  },
  {
    postalCode: '3713',
    city: 'Klein-Burgstall'
  },
  {
    postalCode: '3713',
    city: 'Reikersdorf'
  },
  {
    postalCode: '3714',
    city: 'Roseldorf'
  },
  {
    postalCode: '3714',
    city: 'Braunsdorf'
  },
  {
    postalCode: '3714',
    city: 'Goggendorf'
  },
  {
    postalCode: '3714',
    city: 'Kleinkirchberg'
  },
  {
    postalCode: '3714',
    city: 'Pranhartsberg'
  },
  {
    postalCode: '3714',
    city: 'Sitzendorf an der Schmida'
  },
  {
    postalCode: '3714',
    city: 'Sitzenhart'
  },
  {
    postalCode: '3720',
    city: 'Baierdorf'
  },
  {
    postalCode: '3720',
    city: 'Gaindorf'
  },
  {
    postalCode: '3720',
    city: 'Minichhofen'
  },
  {
    postalCode: '3720',
    city: 'Oberravelsbach'
  },
  {
    postalCode: '3720',
    city: 'Parisdorf'
  },
  {
    postalCode: '3720',
    city: 'Pfaffstetten'
  },
  {
    postalCode: '3720',
    city: 'Ravelsbach'
  },
  {
    postalCode: '3721',
    city: 'Limberg'
  },
  {
    postalCode: '3721',
    city: 'Oberdürnbach'
  },
  {
    postalCode: '3721',
    city: 'Unterdürnbach'
  },
  {
    postalCode: '3721',
    city: 'Niederschleinz'
  },
  {
    postalCode: '3722',
    city: 'Etzmannsdorf bei Straning'
  },
  {
    postalCode: '3722',
    city: 'Straning'
  },
  {
    postalCode: '3730',
    city: 'Burgschleinitz'
  },
  {
    postalCode: '3730',
    city: 'Kühnring'
  },
  {
    postalCode: '3730',
    city: 'Sonndorf'
  },
  {
    postalCode: '3730',
    city: 'Zogelsdorf'
  },
  {
    postalCode: '3730',
    city: 'Eggenburg'
  },
  {
    postalCode: '3730',
    city: 'Engelsdorf'
  },
  {
    postalCode: '3730',
    city: 'Gauderndorf'
  },
  {
    postalCode: '3730',
    city: 'Stoitzendorf'
  },
  {
    postalCode: '3730',
    city: 'Kattau'
  },
  {
    postalCode: '3730',
    city: 'Klein-Jetzelsdorf'
  },
  {
    postalCode: '3730',
    city: 'Klein-Reinprechtsdorf'
  },
  {
    postalCode: '3730',
    city: 'Roggendorf'
  },
  {
    postalCode: '3730',
    city: 'Grafenberg'
  },
  {
    postalCode: '3730',
    city: 'Wartberg'
  },
  {
    postalCode: '3741',
    city: 'Groß-Reipersdorf'
  },
  {
    postalCode: '3741',
    city: 'Leodagger'
  },
  {
    postalCode: '3741',
    city: 'Pulkau'
  },
  {
    postalCode: '3741',
    city: 'Rafing'
  },
  {
    postalCode: '3741',
    city: 'Rohrendorf an der Pulkau'
  },
  {
    postalCode: '3742',
    city: 'Passendorf'
  },
  {
    postalCode: '3742',
    city: 'Theras'
  },
  {
    postalCode: '3742',
    city: 'Heinrichsdorf'
  },
  {
    postalCode: '3743',
    city: 'Röschitz'
  },
  {
    postalCode: '3744',
    city: 'Klein-Meiseldorf'
  },
  {
    postalCode: '3744',
    city: 'Stockern'
  },
  {
    postalCode: '3744',
    city: 'Mold'
  },
  {
    postalCode: '3751',
    city: 'Doberndorf'
  },
  {
    postalCode: '3751',
    city: 'Maigen'
  },
  {
    postalCode: '3751',
    city: 'Rodingersdorf'
  },
  {
    postalCode: '3751',
    city: 'Missingdorf'
  },
  {
    postalCode: '3751',
    city: 'Sigmundsherberg'
  },
  {
    postalCode: '3752',
    city: 'Purgstall'
  },
  {
    postalCode: '3752',
    city: 'Kainreith'
  },
  {
    postalCode: '3752',
    city: 'Brugg'
  },
  {
    postalCode: '3752',
    city: 'Röhrawiesen'
  },
  {
    postalCode: '3752',
    city: 'Walkenstein'
  },
  {
    postalCode: '3752',
    city: 'Nonnersdorf'
  },
  {
    postalCode: '3752',
    city: 'Sallapulka'
  },
  {
    postalCode: '3753',
    city: 'Dallein'
  },
  {
    postalCode: '3753',
    city: 'Goggitsch'
  },
  {
    postalCode: '3753',
    city: 'Harth'
  },
  {
    postalCode: '3753',
    city: 'Hötzelsdorf'
  },
  {
    postalCode: '3753',
    city: 'Schirmannsreith'
  },
  {
    postalCode: '3753',
    city: 'Sieghartsreith'
  },
  {
    postalCode: '3753',
    city: 'Etzelsreith'
  },
  {
    postalCode: '3753',
    city: 'Lehndorf'
  },
  {
    postalCode: '3753',
    city: 'Ludweishofen'
  },
  {
    postalCode: '3753',
    city: 'Nödersdorf'
  },
  {
    postalCode: '3753',
    city: 'Pernegg'
  },
  {
    postalCode: '3753',
    city: 'Posselsdorf'
  },
  {
    postalCode: '3753',
    city: 'Raisdorf'
  },
  {
    postalCode: '3753',
    city: 'Staningersdorf'
  },
  {
    postalCode: '3754',
    city: 'Haselberg'
  },
  {
    postalCode: '3754',
    city: 'Irnfritz'
  },
  {
    postalCode: '3754',
    city: 'Klein-Ulrichschlag'
  },
  {
    postalCode: '3754',
    city: 'Nondorf an der Wild'
  },
  {
    postalCode: '3754',
    city: 'Reichharts'
  },
  {
    postalCode: '3754',
    city: 'Trabenreith'
  },
  {
    postalCode: '3754',
    city: 'Wappoltenreith'
  },
  {
    postalCode: '3761',
    city: 'Dietmannsdorf an der Wild'
  },
  {
    postalCode: '3761',
    city: 'Dorna'
  },
  {
    postalCode: '3761',
    city: 'Grub'
  },
  {
    postalCode: '3761',
    city: 'Messern'
  },
  {
    postalCode: '3761',
    city: 'Rothweinsdorf'
  },
  {
    postalCode: '3761',
    city: 'Sitzendorf'
  },
  {
    postalCode: '3761',
    city: 'Poigen'
  },
  {
    postalCode: '3762',
    city: 'Blumau an der Wild'
  },
  {
    postalCode: '3762',
    city: 'Drösiedl'
  },
  {
    postalCode: '3762',
    city: 'Ludweis'
  },
  {
    postalCode: '3762',
    city: 'Oedt an der Wild'
  },
  {
    postalCode: '3762',
    city: 'Radessen'
  },
  {
    postalCode: '3762',
    city: 'Seebs'
  },
  {
    postalCode: '3763',
    city: 'Goslarn'
  },
  {
    postalCode: '3763',
    city: 'Japons'
  },
  {
    postalCode: '3763',
    city: 'Oberthumeritz'
  },
  {
    postalCode: '3763',
    city: 'Sabatenreith'
  },
  {
    postalCode: '3763',
    city: 'Schweinburg'
  },
  {
    postalCode: '3763',
    city: 'Unterthumeritz'
  },
  {
    postalCode: '3763',
    city: 'Wenjapons'
  },
  {
    postalCode: '3763',
    city: 'Zettenreith'
  },
  {
    postalCode: '3800',
    city: 'Georgenberg'
  },
  {
    postalCode: '3800',
    city: 'Göpfritz an der Wild'
  },
  {
    postalCode: '3800',
    city: 'Merkenbrechts'
  },
  {
    postalCode: '3800',
    city: 'Scheideldorf'
  },
  {
    postalCode: '3800',
    city: 'Weinpolz'
  },
  {
    postalCode: '3804',
    city: 'Allentsteig'
  },
  {
    postalCode: '3804',
    city: 'Reinsbach'
  },
  {
    postalCode: '3804',
    city: 'Thaua'
  },
  {
    postalCode: '3804',
    city: 'Zwinzen'
  },
  {
    postalCode: '3804',
    city: 'Bernschlag'
  },
  {
    postalCode: '3804',
    city: 'Großkainraths'
  },
  {
    postalCode: '3811',
    city: 'Almosen'
  },
  {
    postalCode: '3811',
    city: 'Breitenfeld'
  },
  {
    postalCode: '3811',
    city: 'Kirchberg an der Wild'
  },
  {
    postalCode: '3811',
    city: 'Schönfeld an der Wild'
  },
  {
    postalCode: '3812',
    city: 'Fistritz'
  },
  {
    postalCode: '3812',
    city: 'Ellends'
  },
  {
    postalCode: '3812',
    city: 'Groß-Siegharts'
  },
  {
    postalCode: '3812',
    city: 'Sieghartsles'
  },
  {
    postalCode: '3812',
    city: 'Waldreichs'
  },
  {
    postalCode: '3812',
    city: 'Weinern'
  },
  {
    postalCode: '3812',
    city: 'Wienings'
  },
  {
    postalCode: '3812',
    city: 'Loibes'
  },
  {
    postalCode: '3813',
    city: 'Alt-Dietmanns'
  },
  {
    postalCode: '3813',
    city: 'Neu-Dietmanns'
  },
  {
    postalCode: '3814',
    city: 'Aigen'
  },
  {
    postalCode: '3814',
    city: 'Diemschlag'
  },
  {
    postalCode: '3814',
    city: 'Kollmitzgraben'
  },
  {
    postalCode: '3814',
    city: 'Liebenberg'
  },
  {
    postalCode: '3814',
    city: 'Pfaffenschlag'
  },
  {
    postalCode: '3814',
    city: 'Radl'
  },
  {
    postalCode: '3814',
    city: 'Sauggern'
  },
  {
    postalCode: '3814',
    city: 'Tröbings'
  },
  {
    postalCode: '3820',
    city: 'Alberndorf'
  },
  {
    postalCode: '3820',
    city: 'Koggendorf'
  },
  {
    postalCode: '3820',
    city: 'Kollmitzdörfl'
  },
  {
    postalCode: '3820',
    city: 'Liebnitz'
  },
  {
    postalCode: '3820',
    city: 'Lindau'
  },
  {
    postalCode: '3820',
    city: 'Modsiedl'
  },
  {
    postalCode: '3820',
    city: 'Mostbach'
  },
  {
    postalCode: '3820',
    city: 'Oberndorf bei Raabs'
  },
  {
    postalCode: '3820',
    city: 'Oberpfaffendorf'
  },
  {
    postalCode: '3820',
    city: 'Pommersdorf'
  },
  {
    postalCode: '3820',
    city: 'Raabs an der Thaya'
  },
  {
    postalCode: '3820',
    city: 'Reith'
  },
  {
    postalCode: '3820',
    city: 'Speisendorf'
  },
  {
    postalCode: '3820',
    city: 'Zabernreith'
  },
  {
    postalCode: '3820',
    city: 'Zemmendorf'
  },
  {
    postalCode: '3822',
    city: 'Eggersdorf'
  },
  {
    postalCode: '3822',
    city: 'Göpfritzschlag'
  },
  {
    postalCode: '3822',
    city: 'Goschenreith'
  },
  {
    postalCode: '3822',
    city: 'Griesbach'
  },
  {
    postalCode: '3822',
    city: 'Hohenwarth'
  },
  {
    postalCode: '3822',
    city: 'Karlstein an der Thaya'
  },
  {
    postalCode: '3822',
    city: 'Münchreith an der Thaya'
  },
  {
    postalCode: '3822',
    city: 'Obergrünbach'
  },
  {
    postalCode: '3822',
    city: 'Schlader'
  },
  {
    postalCode: '3822',
    city: 'Thuma'
  },
  {
    postalCode: '3822',
    city: 'Thures'
  },
  {
    postalCode: '3822',
    city: 'Wertenau'
  },
  {
    postalCode: '3823',
    city: 'Neuriegers'
  },
  {
    postalCode: '3823',
    city: 'Niklasberg'
  },
  {
    postalCode: '3823',
    city: 'Oberndorf bei Weikertschlag'
  },
  {
    postalCode: '3823',
    city: 'Rossa'
  },
  {
    postalCode: '3823',
    city: 'Unterpertholz'
  },
  {
    postalCode: '3823',
    city: 'Weikertschlag an der Thaya'
  },
  {
    postalCode: '3823',
    city: 'Wetzles'
  },
  {
    postalCode: '3823',
    city: 'Wilhelmshof'
  },
  {
    postalCode: '3823',
    city: 'Ziernreith'
  },
  {
    postalCode: '3824',
    city: 'Großau'
  },
  {
    postalCode: '3824',
    city: 'Luden'
  },
  {
    postalCode: '3824',
    city: 'Nonndorf bei Raabs'
  },
  {
    postalCode: '3824',
    city: 'Rabesreith'
  },
  {
    postalCode: '3824',
    city: 'Schaditz'
  },
  {
    postalCode: '3824',
    city: 'Süßenbach'
  },
  {
    postalCode: '3830',
    city: 'Großgerharts'
  },
  {
    postalCode: '3830',
    city: 'Jarolden'
  },
  {
    postalCode: '3830',
    city: 'Hollenbach'
  },
  {
    postalCode: '3830',
    city: 'Dimling'
  },
  {
    postalCode: '3830',
    city: 'Puch'
  },
  {
    postalCode: '3830',
    city: 'Pyhra'
  },
  {
    postalCode: '3830',
    city: 'Schlagles'
  },
  {
    postalCode: '3830',
    city: 'Götzles'
  },
  {
    postalCode: '3830',
    city: 'Matzles'
  },
  {
    postalCode: '3830',
    city: 'Ulrichschlag'
  },
  {
    postalCode: '3830',
    city: 'Altwaidhofen'
  },
  {
    postalCode: '3830',
    city: 'Jasnitz'
  },
  {
    postalCode: '3830',
    city: 'Klein Eberharts'
  },
  {
    postalCode: '3830',
    city: 'Vestenötting'
  },
  {
    postalCode: '3830',
    city: 'Waidhofen an der Thaya'
  },
  {
    postalCode: '3830',
    city: 'Brunn'
  },
  {
    postalCode: '3830',
    city: 'Buchbach'
  },
  {
    postalCode: '3830',
    city: 'Götzweis'
  },
  {
    postalCode: '3830',
    city: 'Griesbach'
  },
  {
    postalCode: '3830',
    city: 'Kainraths'
  },
  {
    postalCode: '3830',
    city: 'Nonndorf'
  },
  {
    postalCode: '3830',
    city: 'Sarning'
  },
  {
    postalCode: '3830',
    city: 'Vestenpoppen'
  },
  {
    postalCode: '3830',
    city: 'Wohlfahrts'
  },
  {
    postalCode: '3834',
    city: 'Seyfrieds'
  },
  {
    postalCode: '3834',
    city: 'Guttenbrunn'
  },
  {
    postalCode: '3834',
    city: 'Wolfsegg'
  },
  {
    postalCode: '3834',
    city: 'Rohrbach'
  },
  {
    postalCode: '3834',
    city: 'Arnolz'
  },
  {
    postalCode: '3834',
    city: 'Artolz'
  },
  {
    postalCode: '3834',
    city: 'Eisenreichs'
  },
  {
    postalCode: '3834',
    city: 'Großeberharts'
  },
  {
    postalCode: '3834',
    city: 'Johannessiedlung'
  },
  {
    postalCode: '3834',
    city: 'Kleingöpfritz'
  },
  {
    postalCode: '3834',
    city: 'Pfaffenschlag bei Waidhofen a.d.Thaya'
  },
  {
    postalCode: '3834',
    city: 'Schwarzenberg'
  },
  {
    postalCode: '3834',
    city: 'Hofteichsiedlung'
  },
  {
    postalCode: '3841',
    city: 'Edengans'
  },
  {
    postalCode: '3841',
    city: 'Grünau'
  },
  {
    postalCode: '3841',
    city: 'Kottschallings'
  },
  {
    postalCode: '3841',
    city: 'Lichtenberg'
  },
  {
    postalCode: '3841',
    city: 'Markl'
  },
  {
    postalCode: '3841',
    city: 'Matzlesschlag'
  },
  {
    postalCode: '3841',
    city: 'Meires'
  },
  {
    postalCode: '3841',
    city: 'Rafings'
  },
  {
    postalCode: '3841',
    city: 'Rafingsberg'
  },
  {
    postalCode: '3841',
    city: 'Waldberg'
  },
  {
    postalCode: '3841',
    city: 'Willings'
  },
  {
    postalCode: '3841',
    city: 'Windigsteig'
  },
  {
    postalCode: '3842',
    city: 'Frühwärts'
  },
  {
    postalCode: '3842',
    city: 'Eggmanns'
  },
  {
    postalCode: '3842',
    city: 'Oberedlitz'
  },
  {
    postalCode: '3842',
    city: 'Niederedlitz'
  },
  {
    postalCode: '3842',
    city: 'Ranzles'
  },
  {
    postalCode: '3842',
    city: 'Schirnes'
  },
  {
    postalCode: '3842',
    city: 'Thaya'
  },
  {
    postalCode: '3843',
    city: 'Dobersberg'
  },
  {
    postalCode: '3843',
    city: 'Goschenreith am Taxenbache'
  },
  {
    postalCode: '3843',
    city: 'Großharmanns'
  },
  {
    postalCode: '3843',
    city: 'Hohenau'
  },
  {
    postalCode: '3843',
    city: 'Kleinharmanns'
  },
  {
    postalCode: '3843',
    city: 'Lexnitz'
  },
  {
    postalCode: '3843',
    city: 'Merkengersch'
  },
  {
    postalCode: '3843',
    city: 'Riegers'
  },
  {
    postalCode: '3843',
    city: 'Schuppertholz'
  },
  {
    postalCode: '3843',
    city: 'Peigarten'
  },
  {
    postalCode: '3844',
    city: 'Reibers'
  },
  {
    postalCode: '3844',
    city: 'Reinolz'
  },
  {
    postalCode: '3844',
    city: 'Fratres'
  },
  {
    postalCode: '3844',
    city: 'Gilgenberg'
  },
  {
    postalCode: '3844',
    city: 'Rappolz'
  },
  {
    postalCode: '3844',
    city: 'Rudolz'
  },
  {
    postalCode: '3844',
    city: 'Schönfeld'
  },
  {
    postalCode: '3844',
    city: 'Waldhers'
  },
  {
    postalCode: '3844',
    city: 'Waldkirchen an der Thaya'
  },
  {
    postalCode: '3851',
    city: 'Brunn'
  },
  {
    postalCode: '3851',
    city: 'Engelbrechts'
  },
  {
    postalCode: '3851',
    city: 'Großtaxen'
  },
  {
    postalCode: '3851',
    city: 'Kautzen'
  },
  {
    postalCode: '3851',
    city: 'Kleingerharts'
  },
  {
    postalCode: '3851',
    city: 'Kleintaxen'
  },
  {
    postalCode: '3851',
    city: 'Pleßberg'
  },
  {
    postalCode: '3851',
    city: 'Reinberg-Dobersberg'
  },
  {
    postalCode: '3851',
    city: 'Tiefenbach'
  },
  {
    postalCode: '3852',
    city: 'Garolden'
  },
  {
    postalCode: '3852',
    city: 'Gastern'
  },
  {
    postalCode: '3852',
    city: 'Immenschlag'
  },
  {
    postalCode: '3852',
    city: 'Kleinmotten'
  },
  {
    postalCode: '3852',
    city: 'Kleinzwettl'
  },
  {
    postalCode: '3852',
    city: 'Ruders'
  },
  {
    postalCode: '3852',
    city: 'Weißenbach'
  },
  {
    postalCode: '3852',
    city: 'Wiesmaden'
  },
  {
    postalCode: '3852',
    city: 'Triglas'
  },
  {
    postalCode: '3860',
    city: 'Aalfang'
  },
  {
    postalCode: '3860',
    city: 'Reinberg-Heidenreichstein'
  },
  {
    postalCode: '3860',
    city: 'Altmanns'
  },
  {
    postalCode: '3860',
    city: 'Dietweis'
  },
  {
    postalCode: '3860',
    city: 'Eberweis'
  },
  {
    postalCode: '3860',
    city: 'Heidenreichstein'
  },
  {
    postalCode: '3860',
    city: 'Kleinpertholz'
  },
  {
    postalCode: '3860',
    city: 'Wielandsberg'
  },
  {
    postalCode: '3860',
    city: 'Motten'
  },
  {
    postalCode: '3860',
    city: 'Haslau'
  },
  {
    postalCode: '3860',
    city: 'Seyfrieds'
  },
  {
    postalCode: '3861',
    city: 'Eggern'
  },
  {
    postalCode: '3861',
    city: 'Reinberg-Heidenreichstein'
  },
  {
    postalCode: '3861',
    city: 'Reinberg-Litschau'
  },
  {
    postalCode: '3862',
    city: 'Eisgarn'
  },
  {
    postalCode: '3862',
    city: 'Klein-Radischen'
  },
  {
    postalCode: '3862',
    city: 'Groß-Radischen'
  },
  {
    postalCode: '3862',
    city: 'Wielings'
  },
  {
    postalCode: '3863',
    city: 'Hirschenschlag'
  },
  {
    postalCode: '3863',
    city: 'Leopoldsdorf'
  },
  {
    postalCode: '3863',
    city: 'Reingers'
  },
  {
    postalCode: '3871',
    city: 'Alt-Nagelberg'
  },
  {
    postalCode: '3871',
    city: 'Neu-Nagelberg'
  },
  {
    postalCode: '3871',
    city: 'Steinbach'
  },
  {
    postalCode: '3871',
    city: 'Breitensee'
  },
  {
    postalCode: '3872',
    city: 'Aalfang'
  },
  {
    postalCode: '3872',
    city: 'Amaliendorf'
  },
  {
    postalCode: '3872',
    city: 'Falkendorf'
  },
  {
    postalCode: '3872',
    city: 'Kiensaß'
  },
  {
    postalCode: '3872',
    city: 'Langegg'
  },
  {
    postalCode: '3872',
    city: 'Neulangegg'
  },
  {
    postalCode: '3873',
    city: 'Brand'
  },
  {
    postalCode: '3873',
    city: 'Finsternau'
  },
  {
    postalCode: '3873',
    city: 'Thaures'
  },
  {
    postalCode: '3873',
    city: 'Gopprechts'
  },
  {
    postalCode: '3874',
    city: 'Griesbach'
  },
  {
    postalCode: '3874',
    city: 'Haugschlag'
  },
  {
    postalCode: '3874',
    city: 'Rottal'
  },
  {
    postalCode: '3874',
    city: 'Türnau'
  },
  {
    postalCode: '3874',
    city: 'Hörmanns bei Litschau'
  },
  {
    postalCode: '3874',
    city: 'Gopprechts'
  },
  {
    postalCode: '3874',
    city: 'Litschau'
  },
  {
    postalCode: '3874',
    city: 'Loimanns'
  },
  {
    postalCode: '3874',
    city: 'Reichenbach'
  },
  {
    postalCode: '3874',
    city: 'Schönau bei Litschau'
  },
  {
    postalCode: '3874',
    city: 'Reitzenschlag'
  },
  {
    postalCode: '3874',
    city: 'Saaß'
  },
  {
    postalCode: '3874',
    city: 'Schandachen'
  },
  {
    postalCode: '3874',
    city: 'Josefsthal'
  },
  {
    postalCode: '3874',
    city: 'Schlag'
  },
  {
    postalCode: '3874',
    city: 'Grametten'
  },
  {
    postalCode: '3874',
    city: 'Illmanns'
  },
  {
    postalCode: '3900',
    city: 'Echsenbach'
  },
  {
    postalCode: '3900',
    city: 'Ganz'
  },
  {
    postalCode: '3900',
    city: 'Großhaselbach'
  },
  {
    postalCode: '3900',
    city: 'Schlag'
  },
  {
    postalCode: '3900',
    city: 'Hausbach'
  },
  {
    postalCode: '3900',
    city: 'Limpfings'
  },
  {
    postalCode: '3900',
    city: 'Modlisch'
  },
  {
    postalCode: '3900',
    city: 'Schwarzenau'
  },
  {
    postalCode: '3900',
    city: 'Stögersbach'
  },
  {
    postalCode: '3900',
    city: 'Kleinreichenbach'
  },
  {
    postalCode: '3902',
    city: 'Kleinpoppen'
  },
  {
    postalCode: '3902',
    city: 'Eschenau'
  },
  {
    postalCode: '3902',
    city: 'Eulenbach'
  },
  {
    postalCode: '3902',
    city: 'Grafenschlag'
  },
  {
    postalCode: '3902',
    city: 'Großrupprechts'
  },
  {
    postalCode: '3902',
    city: 'Heinreichs'
  },
  {
    postalCode: '3902',
    city: 'Jaudling'
  },
  {
    postalCode: '3902',
    city: 'Jetzles'
  },
  {
    postalCode: '3902',
    city: 'Kaltenbach'
  },
  {
    postalCode: '3902',
    city: 'Kleingloms'
  },
  {
    postalCode: '3902',
    city: 'Kleinschönau'
  },
  {
    postalCode: '3902',
    city: 'Schacherdorf'
  },
  {
    postalCode: '3902',
    city: 'Schoberdorf'
  },
  {
    postalCode: '3902',
    city: 'Sparbach'
  },
  {
    postalCode: '3902',
    city: 'Stoies'
  },
  {
    postalCode: '3902',
    city: 'Vitis'
  },
  {
    postalCode: '3902',
    city: 'Edelprinz'
  },
  {
    postalCode: '3902',
    city: 'Wiederfeld'
  },
  {
    postalCode: '3903',
    city: 'Echsenbach'
  },
  {
    postalCode: '3903',
    city: 'Gerweis'
  },
  {
    postalCode: '3903',
    city: 'Großkainraths'
  },
  {
    postalCode: '3903',
    city: 'Haimschlag'
  },
  {
    postalCode: '3903',
    city: 'Rieweis'
  },
  {
    postalCode: '3903',
    city: 'Wolfenstein'
  },
  {
    postalCode: '3910',
    city: 'Frankenreith'
  },
  {
    postalCode: '3910',
    city: 'Großweißenbach'
  },
  {
    postalCode: '3910',
    city: 'Reichers'
  },
  {
    postalCode: '3910',
    city: 'Rohrenreith'
  },
  {
    postalCode: '3910',
    city: 'Sprögnitz'
  },
  {
    postalCode: '3910',
    city: 'Böhmhöf'
  },
  {
    postalCode: '3910',
    city: 'Bösenneunzen'
  },
  {
    postalCode: '3910',
    city: 'Edelhof'
  },
  {
    postalCode: '3910',
    city: 'Germanns'
  },
  {
    postalCode: '3910',
    city: 'Gerotten'
  },
  {
    postalCode: '3910',
    city: 'Gradnitz'
  },
  {
    postalCode: '3910',
    city: 'Großglobnitz'
  },
  {
    postalCode: '3910',
    city: 'Großhaslau'
  },
  {
    postalCode: '3910',
    city: 'Gschwendt'
  },
  {
    postalCode: '3910',
    city: 'Hörmanns'
  },
  {
    postalCode: '3910',
    city: 'Jahrings'
  },
  {
    postalCode: '3910',
    city: 'Kleinotten'
  },
  {
    postalCode: '3910',
    city: 'Koblhof'
  },
  {
    postalCode: '3910',
    city: 'Mayerhöfen'
  },
  {
    postalCode: '3910',
    city: 'Moidrams'
  },
  {
    postalCode: '3910',
    city: 'Niederglobnitz'
  },
  {
    postalCode: '3910',
    city: 'Niederstrahlbach'
  },
  {
    postalCode: '3910',
    city: 'Oberstrahlbach'
  },
  {
    postalCode: '3910',
    city: 'Ratschenhof'
  },
  {
    postalCode: '3910',
    city: 'Ritzmannshof'
  },
  {
    postalCode: '3910',
    city: 'Rudmanns'
  },
  {
    postalCode: '3910',
    city: 'Schickenhof'
  },
  {
    postalCode: '3910',
    city: 'Syrafeld'
  },
  {
    postalCode: '3910',
    city: 'Unterrabenthan'
  },
  {
    postalCode: '3910',
    city: 'Waldhams'
  },
  {
    postalCode: '3910',
    city: 'Zwettl-Niederösterreich'
  },
  {
    postalCode: '3910',
    city: 'Zwettl Stift'
  },
  {
    postalCode: '3911',
    city: 'Kirchbach'
  },
  {
    postalCode: '3911',
    city: 'Lembach'
  },
  {
    postalCode: '3911',
    city: 'Oberrabenthan'
  },
  {
    postalCode: '3911',
    city: 'Riebeis'
  },
  {
    postalCode: '3911',
    city: 'Selbitz'
  },
  {
    postalCode: '3911',
    city: 'Aggsbach'
  },
  {
    postalCode: '3911',
    city: 'Arnreith'
  },
  {
    postalCode: '3911',
    city: 'Dietharts'
  },
  {
    postalCode: '3911',
    city: 'Grötschen'
  },
  {
    postalCode: '3911',
    city: 'Grünbach'
  },
  {
    postalCode: '3911',
    city: 'Hausbach'
  },
  {
    postalCode: '3911',
    city: 'Höhendorf'
  },
  {
    postalCode: '3911',
    city: 'Kleinnondorf'
  },
  {
    postalCode: '3911',
    city: 'Neustift'
  },
  {
    postalCode: '3911',
    city: 'Pehendorf'
  },
  {
    postalCode: '3911',
    city: 'Pfaffendorf'
  },
  {
    postalCode: '3911',
    city: 'Pirkenreith'
  },
  {
    postalCode: '3911',
    city: 'Rappottenstein'
  },
  {
    postalCode: '3911',
    city: 'Reichenbach'
  },
  {
    postalCode: '3911',
    city: 'Ritterkamp'
  },
  {
    postalCode: '3911',
    city: 'Roiten'
  },
  {
    postalCode: '3911',
    city: 'Kleinkamp'
  },
  {
    postalCode: '3911',
    city: 'Annatsberg'
  },
  {
    postalCode: '3911',
    city: 'Hörweix'
  },
  {
    postalCode: '3911',
    city: 'Marbach am Walde'
  },
  {
    postalCode: '3911',
    city: 'Merzenstein'
  },
  {
    postalCode: '3911',
    city: 'Rottenbach'
  },
  {
    postalCode: '3911',
    city: 'Uttissenbach'
  },
  {
    postalCode: '3912',
    city: 'Bromberg'
  },
  {
    postalCode: '3912',
    city: 'Grafenschlag'
  },
  {
    postalCode: '3912',
    city: 'Kaltenbrunn'
  },
  {
    postalCode: '3912',
    city: 'Kleingöttfritz'
  },
  {
    postalCode: '3912',
    city: 'Kleinnondorf'
  },
  {
    postalCode: '3912',
    city: 'Langschlag'
  },
  {
    postalCode: '3912',
    city: 'Schafberg'
  },
  {
    postalCode: '3912',
    city: 'Wielands'
  },
  {
    postalCode: '3913',
    city: 'Engelbrechts'
  },
  {
    postalCode: '3913',
    city: 'Großgöttfritz'
  },
  {
    postalCode: '3913',
    city: 'Kleinweißenbach'
  },
  {
    postalCode: '3914',
    city: 'Kamles'
  },
  {
    postalCode: '3914',
    city: 'Hirschenschlag'
  },
  {
    postalCode: '3914',
    city: 'Königsbach'
  },
  {
    postalCode: '3914',
    city: 'Loschberg'
  },
  {
    postalCode: '3914',
    city: 'Obernondorf'
  },
  {
    postalCode: '3914',
    city: 'Rappoltschlag'
  },
  {
    postalCode: '3914',
    city: 'Waldhausen'
  },
  {
    postalCode: '3920',
    city: 'Haselbach'
  },
  {
    postalCode: '3920',
    city: 'Wiesensfeld'
  },
  {
    postalCode: '3920',
    city: 'Aigen'
  },
  {
    postalCode: '3920',
    city: 'Albern'
  },
  {
    postalCode: '3920',
    city: 'Böhmsdorf'
  },
  {
    postalCode: '3920',
    city: 'Dietmanns'
  },
  {
    postalCode: '3920',
    city: 'Egres'
  },
  {
    postalCode: '3920',
    city: 'Etlas'
  },
  {
    postalCode: '3920',
    city: 'Etzen'
  },
  {
    postalCode: '3920',
    city: 'Frauendorf'
  },
  {
    postalCode: '3920',
    city: 'Freitzenschlag'
  },
  {
    postalCode: '3920',
    city: 'Griesbach'
  },
  {
    postalCode: '3920',
    city: 'Groß Gerungs'
  },
  {
    postalCode: '3920',
    city: 'Groß Meinharts'
  },
  {
    postalCode: '3920',
    city: 'Häuslern'
  },
  {
    postalCode: '3920',
    city: 'Haid'
  },
  {
    postalCode: '3920',
    city: 'Harruck'
  },
  {
    postalCode: '3920',
    city: 'Heinreichs'
  },
  {
    postalCode: '3920',
    city: 'Hypolz'
  },
  {
    postalCode: '3920',
    city: 'Josefsdorf'
  },
  {
    postalCode: '3920',
    city: 'Kinzenschlag'
  },
  {
    postalCode: '3920',
    city: 'Klein Gundholz'
  },
  {
    postalCode: '3920',
    city: 'Klein Reinprechts'
  },
  {
    postalCode: '3920',
    city: 'Klein Wetzles'
  },
  {
    postalCode: '3920',
    city: 'Marharts'
  },
  {
    postalCode: '3920',
    city: 'Mühlbach'
  },
  {
    postalCode: '3920',
    city: 'Nonndorf'
  },
  {
    postalCode: '3920',
    city: 'Oberkirchen'
  },
  {
    postalCode: '3920',
    city: 'Ober Neustift'
  },
  {
    postalCode: '3920',
    city: 'Ober Rosenauerwald'
  },
  {
    postalCode: '3920',
    city: 'Preinreichs'
  },
  {
    postalCode: '3920',
    city: 'Schall'
  },
  {
    postalCode: '3920',
    city: 'Schönbichl'
  },
  {
    postalCode: '3920',
    city: 'Siebenberg'
  },
  {
    postalCode: '3920',
    city: 'Sitzmanns'
  },
  {
    postalCode: '3920',
    city: 'Thail'
  },
  {
    postalCode: '3920',
    city: 'Wendelgraben'
  },
  {
    postalCode: '3920',
    city: 'Wurmbrand'
  },
  {
    postalCode: '3920',
    city: 'Kotting Nondorf'
  },
  {
    postalCode: '3920',
    city: 'Blumau'
  },
  {
    postalCode: '3920',
    city: 'Antenfeinhöfe'
  },
  {
    postalCode: '3920',
    city: 'Reitern'
  },
  {
    postalCode: '3920',
    city: 'Grossgundholz'
  },
  {
    postalCode: '3921',
    city: 'Harruck'
  },
  {
    postalCode: '3921',
    city: 'Bruderndorf'
  },
  {
    postalCode: '3921',
    city: 'Bruderndorferwald'
  },
  {
    postalCode: '3921',
    city: 'Fraberg'
  },
  {
    postalCode: '3921',
    city: 'Kainrathschlag'
  },
  {
    postalCode: '3921',
    city: 'Kasbach'
  },
  {
    postalCode: '3921',
    city: 'Kehrbach'
  },
  {
    postalCode: '3921',
    city: 'Kleinpertholz'
  },
  {
    postalCode: '3921',
    city: 'Kogschlag'
  },
  {
    postalCode: '3921',
    city: 'Langschlag'
  },
  {
    postalCode: '3921',
    city: 'Langschlägerwald'
  },
  {
    postalCode: '3921',
    city: 'Mittelberg'
  },
  {
    postalCode: '3921',
    city: 'Mitterschlag'
  },
  {
    postalCode: '3921',
    city: 'Münzbach'
  },
  {
    postalCode: '3921',
    city: 'Schmerbach'
  },
  {
    postalCode: '3921',
    city: 'Siebenhöf'
  },
  {
    postalCode: '3921',
    city: 'Stierberg'
  },
  {
    postalCode: '3921',
    city: 'Streith'
  },
  {
    postalCode: '3922',
    city: 'Watzmanns'
  },
  {
    postalCode: '3922',
    city: 'Engelstein'
  },
  {
    postalCode: '3922',
    city: 'Friedreichs'
  },
  {
    postalCode: '3922',
    city: 'Großotten'
  },
  {
    postalCode: '3922',
    city: 'Großschönau'
  },
  {
    postalCode: '3922',
    city: 'Harmannstein'
  },
  {
    postalCode: '3922',
    city: 'Hirschenhof'
  },
  {
    postalCode: '3922',
    city: 'Mistelbach'
  },
  {
    postalCode: '3922',
    city: 'Schroffen'
  },
  {
    postalCode: '3922',
    city: 'Thaures'
  },
  {
    postalCode: '3922',
    city: 'Wachtberg'
  },
  {
    postalCode: '3922',
    city: 'Wörnharts'
  },
  {
    postalCode: '3922',
    city: 'Siebenlinden'
  },
  {
    postalCode: '3923',
    city: 'Rothfarn'
  },
  {
    postalCode: '3923',
    city: 'Zweres'
  },
  {
    postalCode: '3923',
    city: 'Schweiggers'
  },
  {
    postalCode: '3923',
    city: 'Bernhards'
  },
  {
    postalCode: '3923',
    city: 'Jagenbach'
  },
  {
    postalCode: '3923',
    city: 'Purken'
  },
  {
    postalCode: '3924',
    city: 'Ober Neustift'
  },
  {
    postalCode: '3924',
    city: 'Guttenbrunn'
  },
  {
    postalCode: '3924',
    city: 'Kleinmeinharts'
  },
  {
    postalCode: '3924',
    city: 'Niederneustift'
  },
  {
    postalCode: '3924',
    city: 'Rosenau Schloss'
  },
  {
    postalCode: '3924',
    city: 'Unterrosenauerwald'
  },
  {
    postalCode: '3925',
    city: 'Altmelon'
  },
  {
    postalCode: '3925',
    city: 'Dietrichsbach'
  },
  {
    postalCode: '3925',
    city: 'Fichtenbach'
  },
  {
    postalCode: '3925',
    city: 'Kronegg'
  },
  {
    postalCode: '3925',
    city: 'Perwolfs'
  },
  {
    postalCode: '3925',
    city: 'Schwarzau'
  },
  {
    postalCode: '3925',
    city: 'Arbesbach'
  },
  {
    postalCode: '3925',
    city: 'Brunn'
  },
  {
    postalCode: '3925',
    city: 'Etlas'
  },
  {
    postalCode: '3925',
    city: 'Kamp'
  },
  {
    postalCode: '3925',
    city: 'Neumelon'
  },
  {
    postalCode: '3925',
    city: 'Pretrobruck'
  },
  {
    postalCode: '3925',
    city: 'Purrath'
  },
  {
    postalCode: '3925',
    city: 'Rammelhof'
  },
  {
    postalCode: '3925',
    city: 'Schönfeld'
  },
  {
    postalCode: '3925',
    city: 'Komau'
  },
  {
    postalCode: '3925',
    city: 'Leopoldstein'
  },
  {
    postalCode: '3925',
    city: 'Neustift'
  },
  {
    postalCode: '3931',
    city: 'Großreichenbach'
  },
  {
    postalCode: '3931',
    city: 'Kleinwolfgers'
  },
  {
    postalCode: '3931',
    city: 'Mannshalm'
  },
  {
    postalCode: '3931',
    city: 'Meinhartschlag'
  },
  {
    postalCode: '3931',
    city: 'Perndorf'
  },
  {
    postalCode: '3931',
    city: 'Reinbolden'
  },
  {
    postalCode: '3931',
    city: 'Sallingstadt'
  },
  {
    postalCode: '3931',
    city: 'Schwarzenbach'
  },
  {
    postalCode: '3931',
    city: 'Schweiggers'
  },
  {
    postalCode: '3931',
    city: 'Siebenlinden'
  },
  {
    postalCode: '3931',
    city: 'Streitbach'
  },
  {
    postalCode: '3931',
    city: 'Unterwindhag'
  },
  {
    postalCode: '3931',
    city: 'Vierlings'
  },
  {
    postalCode: '3931',
    city: 'Walterschlag'
  },
  {
    postalCode: '3931',
    city: 'Windhof'
  },
  {
    postalCode: '3931',
    city: 'Gerlas'
  },
  {
    postalCode: '3931',
    city: 'Negers'
  },
  {
    postalCode: '3931',
    city: 'Neusiedl'
  },
  {
    postalCode: '3931',
    city: 'Rieggers'
  },
  {
    postalCode: '3931',
    city: 'Rosenau Dorf'
  },
  {
    postalCode: '3932',
    city: 'Fromberg'
  },
  {
    postalCode: '3932',
    city: 'Hollenstein'
  },
  {
    postalCode: '3932',
    city: 'Kirchberg am Walde'
  },
  {
    postalCode: '3932',
    city: 'Ullrichs'
  },
  {
    postalCode: '3932',
    city: 'Weißenalbern'
  },
  {
    postalCode: '3932',
    city: 'Süßenbach'
  },
  {
    postalCode: '3932',
    city: 'Limbach'
  },
  {
    postalCode: '3932',
    city: 'Warnungs'
  },
  {
    postalCode: '3932',
    city: 'Ottenschlag'
  },
  {
    postalCode: '3942',
    city: 'Hirschbach'
  },
  {
    postalCode: '3942',
    city: 'Stölzles'
  },
  {
    postalCode: '3943',
    city: 'Gebharts'
  },
  {
    postalCode: '3943',
    city: 'Anderlfabrik'
  },
  {
    postalCode: '3943',
    city: 'Ehrenhöbarten'
  },
  {
    postalCode: '3943',
    city: 'Kleedorf'
  },
  {
    postalCode: '3943',
    city: 'Kottinghörmanns'
  },
  {
    postalCode: '3943',
    city: 'Neuniederschrems'
  },
  {
    postalCode: '3943',
    city: 'Niederschrems'
  },
  {
    postalCode: '3943',
    city: 'Schrems'
  },
  {
    postalCode: '3944',
    city: 'Kurzschwarza'
  },
  {
    postalCode: '3944',
    city: 'Langschwarza'
  },
  {
    postalCode: '3944',
    city: 'Pürbach'
  },
  {
    postalCode: '3945',
    city: 'Hoheneich'
  },
  {
    postalCode: '3945',
    city: 'Nondorf'
  },
  {
    postalCode: '3950',
    city: 'Breitensee'
  },
  {
    postalCode: '3950',
    city: 'Eibenstein'
  },
  {
    postalCode: '3950',
    city: 'Grillenstein'
  },
  {
    postalCode: '3950',
    city: 'Gmünd'
  },
  {
    postalCode: '3950',
    city: 'Dietmanns'
  },
  {
    postalCode: '3950',
    city: 'Ehrendorf'
  },
  {
    postalCode: '3950',
    city: 'Eichberg'
  },
  {
    postalCode: '3950',
    city: 'Wielands'
  },
  {
    postalCode: '3961',
    city: 'Hörmanns bei Weitra'
  },
  {
    postalCode: '3961',
    city: 'Albrechts'
  },
  {
    postalCode: '3961',
    city: 'Groß-Höbarten'
  },
  {
    postalCode: '3961',
    city: 'Groß-Neusiedl'
  },
  {
    postalCode: '3961',
    city: 'Grünbach'
  },
  {
    postalCode: '3961',
    city: 'Klein-Ruprechts'
  },
  {
    postalCode: '3961',
    city: 'Waldenstein'
  },
  {
    postalCode: '3961',
    city: 'Zehenthöf'
  },
  {
    postalCode: '3962',
    city: 'Höhenberg'
  },
  {
    postalCode: '3962',
    city: 'Reinpolz'
  },
  {
    postalCode: '3962',
    city: 'Unterlembach'
  },
  {
    postalCode: '3962',
    city: 'Heinrichs bei Weitra'
  },
  {
    postalCode: '3962',
    city: 'Ober-Lembach'
  },
  {
    postalCode: '3962',
    city: 'Pyhrabruck'
  },
  {
    postalCode: '3970',
    city: 'Harbach'
  },
  {
    postalCode: '3970',
    city: 'Wultschau'
  },
  {
    postalCode: '3970',
    city: 'Hirschenwies'
  },
  {
    postalCode: '3970',
    city: 'Maißen'
  },
  {
    postalCode: '3970',
    city: 'Lauterbach'
  },
  {
    postalCode: '3970',
    city: 'Langfeld'
  },
  {
    postalCode: '3970',
    city: 'Schützenberg'
  },
  {
    postalCode: '3970',
    city: 'Altweitra'
  },
  {
    postalCode: '3970',
    city: 'Schagges'
  },
  {
    postalCode: '3970',
    city: 'Ulrichs'
  },
  {
    postalCode: '3970',
    city: 'Unserfrau'
  },
  {
    postalCode: '3970',
    city: 'Großwolfgers'
  },
  {
    postalCode: '3970',
    city: 'Oberbrühl'
  },
  {
    postalCode: '3970',
    city: 'Oberwindhag'
  },
  {
    postalCode: '3970',
    city: 'Reinprechts'
  },
  {
    postalCode: '3970',
    city: 'St. Wolfgang'
  },
  {
    postalCode: '3970',
    city: 'Spital'
  },
  {
    postalCode: '3970',
    city: 'Sulz'
  },
  {
    postalCode: '3970',
    city: 'Tiefenbach'
  },
  {
    postalCode: '3970',
    city: 'Unterbrühl'
  },
  {
    postalCode: '3970',
    city: 'Walterschlag'
  },
  {
    postalCode: '3970',
    city: 'Weitra'
  },
  {
    postalCode: '3970',
    city: 'Wetzles'
  },
  {
    postalCode: '3971',
    city: 'Schwarzau'
  },
  {
    postalCode: '3971',
    city: 'Harmanschlag'
  },
  {
    postalCode: '3971',
    city: 'Anger'
  },
  {
    postalCode: '3971',
    city: 'Oberlainsitz'
  },
  {
    postalCode: '3971',
    city: 'Reitgraben'
  },
  {
    postalCode: '3971',
    city: 'Rörndlwies'
  },
  {
    postalCode: '3971',
    city: 'Roßbruck'
  },
  {
    postalCode: '3971',
    city: 'St. Martin'
  },
  {
    postalCode: '3971',
    city: 'Schöllbüchl'
  },
  {
    postalCode: '3971',
    city: 'Zeil'
  },
  {
    postalCode: '3972',
    city: 'Abschlag'
  },
  {
    postalCode: '3972',
    city: 'Angelbach'
  },
  {
    postalCode: '3972',
    city: 'Bad Großpertholz'
  },
  {
    postalCode: '3972',
    city: 'Hirschenstein'
  },
  {
    postalCode: '3972',
    city: 'Mühlbach'
  },
  {
    postalCode: '3972',
    city: 'Reichenau am Freiwald'
  },
  {
    postalCode: '3972',
    city: 'Scheiben'
  },
  {
    postalCode: '3972',
    city: 'Seifritz'
  },
  {
    postalCode: '3972',
    city: 'Steinbach'
  },
  {
    postalCode: '3972',
    city: 'Weikertschlag'
  },
  {
    postalCode: '3972',
    city: 'Harmanschlag'
  },
  {
    postalCode: '3972',
    city: 'Joachimstal'
  },
  {
    postalCode: '3972',
    city: 'Breitenberg'
  },
  {
    postalCode: '3973',
    city: 'Karlstift'
  },
  {
    postalCode: '3973',
    city: 'Reichenauerwald'
  },
  {
    postalCode: '4020',
    city: 'Friesenegg'
  },
  {
    postalCode: '4020',
    city: 'Zaubertal'
  },
  {
    postalCode: '4020',
    city: 'Linz'
  },
  {
    postalCode: '4030',
    city: 'Linz'
  },
  {
    postalCode: '4040',
    city: 'Katzgraben'
  },
  {
    postalCode: '4040',
    city: 'Kitzelsbach'
  },
  {
    postalCode: '4040',
    city: 'Oberbairing'
  },
  {
    postalCode: '4040',
    city: 'Großamberg'
  },
  {
    postalCode: '4040',
    city: 'Neulichtenberg'
  },
  {
    postalCode: '4040',
    city: 'Pöstlingberg'
  },
  {
    postalCode: '4040',
    city: 'Hochbuchedt'
  },
  {
    postalCode: '4040',
    city: 'Altlichtenberg'
  },
  {
    postalCode: '4040',
    city: 'Asberg'
  },
  {
    postalCode: '4040',
    city: 'Haselgraben'
  },
  {
    postalCode: '4040',
    city: 'Kammerschlag'
  },
  {
    postalCode: '4040',
    city: 'Mühlberg'
  },
  {
    postalCode: '4040',
    city: 'Linz'
  },
  {
    postalCode: '4040',
    city: 'Plesching'
  },
  {
    postalCode: '4048',
    city: 'Großamberg'
  },
  {
    postalCode: '4048',
    city: 'Oberpuchenau'
  },
  {
    postalCode: '4048',
    city: 'Pöstlingberg'
  },
  {
    postalCode: '4048',
    city: 'Puchenau'
  },
  {
    postalCode: '4048',
    city: 'Unterpuchenau'
  },
  {
    postalCode: '4050',
    city: 'Neubau'
  },
  {
    postalCode: '4050',
    city: 'Oedt'
  },
  {
    postalCode: '4050',
    city: 'Sankt Dionysen'
  },
  {
    postalCode: '4050',
    city: 'Sankt Martin'
  },
  {
    postalCode: '4050',
    city: 'Traun'
  },
  {
    postalCode: '4052',
    city: 'Ansfelden'
  },
  {
    postalCode: '4052',
    city: 'Audorf'
  },
  {
    postalCode: '4052',
    city: 'Fleckendorf'
  },
  {
    postalCode: '4052',
    city: 'Freindorf'
  },
  {
    postalCode: '4052',
    city: 'Fürhappen'
  },
  {
    postalCode: '4052',
    city: 'Grabwinkel'
  },
  {
    postalCode: '4052',
    city: 'Kremsdorf'
  },
  {
    postalCode: '4052',
    city: 'Vordermayrberg'
  },
  {
    postalCode: '4053',
    city: 'Berg'
  },
  {
    postalCode: '4053',
    city: 'Haid'
  },
  {
    postalCode: '4053',
    city: 'Kremsdorf'
  },
  {
    postalCode: '4053',
    city: 'Moos'
  },
  {
    postalCode: '4053',
    city: 'Nettingsdorf'
  },
  {
    postalCode: '4053',
    city: 'Rapperswinkel'
  },
  {
    postalCode: '4053',
    city: 'Weißenberg'
  },
  {
    postalCode: '4055',
    city: 'Dörfl'
  },
  {
    postalCode: '4055',
    city: 'Hasenufer'
  },
  {
    postalCode: '4055',
    city: 'Köttsdorf'
  },
  {
    postalCode: '4055',
    city: 'Oberschnadt'
  },
  {
    postalCode: '4055',
    city: 'Pucking'
  },
  {
    postalCode: '4055',
    city: 'Sammersdorf'
  },
  {
    postalCode: '4055',
    city: 'Sankt Leonhard'
  },
  {
    postalCode: '4055',
    city: 'Sipbach'
  },
  {
    postalCode: '4055',
    city: 'Unterschnadt'
  },
  {
    postalCode: '4055',
    city: 'Zeitlham'
  },
  {
    postalCode: '4060',
    city: 'Buchberg'
  },
  {
    postalCode: '4060',
    city: 'Aichberg'
  },
  {
    postalCode: '4060',
    city: 'Alharting'
  },
  {
    postalCode: '4060',
    city: 'Berg'
  },
  {
    postalCode: '4060',
    city: 'Bergham'
  },
  {
    postalCode: '4060',
    city: 'Doppl'
  },
  {
    postalCode: '4060',
    city: 'Enzenwinkl'
  },
  {
    postalCode: '4060',
    city: 'Felling'
  },
  {
    postalCode: '4060',
    city: 'Friesenegg'
  },
  {
    postalCode: '4060',
    city: 'Gaumberg'
  },
  {
    postalCode: '4060',
    city: 'Haag'
  },
  {
    postalCode: '4060',
    city: 'Hart'
  },
  {
    postalCode: '4060',
    city: 'Holzheim'
  },
  {
    postalCode: '4060',
    city: 'Imberg'
  },
  {
    postalCode: '4060',
    city: 'Jetzing'
  },
  {
    postalCode: '4060',
    city: 'Kinderdorf Sankt Isidor'
  },
  {
    postalCode: '4060',
    city: 'Leonding'
  },
  {
    postalCode: '4060',
    city: 'Reith'
  },
  {
    postalCode: '4060',
    city: 'Rufling'
  },
  {
    postalCode: '4060',
    city: 'Staudach'
  },
  {
    postalCode: '4060',
    city: 'Untergaumberg'
  },
  {
    postalCode: '4061',
    city: 'Rufling'
  },
  {
    postalCode: '4061',
    city: 'Aistental'
  },
  {
    postalCode: '4061',
    city: 'Langholzfeld'
  },
  {
    postalCode: '4061',
    city: 'Pasching'
  },
  {
    postalCode: '4061',
    city: 'Thurnharting'
  },
  {
    postalCode: '4061',
    city: 'Wagram'
  },
  {
    postalCode: '4061',
    city: 'Hitzing'
  },
  {
    postalCode: '4062',
    city: 'Aichberg'
  },
  {
    postalCode: '4062',
    city: 'Au'
  },
  {
    postalCode: '4062',
    city: 'Axberg'
  },
  {
    postalCode: '4062',
    city: 'Gumpolding'
  },
  {
    postalCode: '4062',
    city: 'Intenham'
  },
  {
    postalCode: '4062',
    city: 'Kirchberg'
  },
  {
    postalCode: '4062',
    city: 'Niederbuch'
  },
  {
    postalCode: '4062',
    city: 'Niederfeld'
  },
  {
    postalCode: '4062',
    city: 'Schauersfreiling'
  },
  {
    postalCode: '4062',
    city: 'Thening'
  },
  {
    postalCode: '4062',
    city: 'Thürnau'
  },
  {
    postalCode: '4062',
    city: 'Appersberg'
  },
  {
    postalCode: '4063',
    city: 'Aistental'
  },
  {
    postalCode: '4063',
    city: 'Breitbrunn'
  },
  {
    postalCode: '4063',
    city: 'Frindorf'
  },
  {
    postalCode: '4063',
    city: 'Gerersdorf'
  },
  {
    postalCode: '4063',
    city: 'Haid'
  },
  {
    postalCode: '4063',
    city: 'Hörsching'
  },
  {
    postalCode: '4063',
    city: 'Holzleiten'
  },
  {
    postalCode: '4063',
    city: 'Lindenlach'
  },
  {
    postalCode: '4063',
    city: 'Neubau'
  },
  {
    postalCode: '4063',
    city: 'Öhndorf'
  },
  {
    postalCode: '4063',
    city: 'Rudelsdorf'
  },
  {
    postalCode: '4063',
    city: 'Rutzing'
  },
  {
    postalCode: '4064',
    city: 'Freiling'
  },
  {
    postalCode: '4064',
    city: 'Hausleiten'
  },
  {
    postalCode: '4064',
    city: 'Kirchstetten'
  },
  {
    postalCode: '4064',
    city: 'Mitterbachham'
  },
  {
    postalCode: '4064',
    city: 'Niederbachham'
  },
  {
    postalCode: '4064',
    city: 'Oberbachham'
  },
  {
    postalCode: '4064',
    city: 'Oberbuch'
  },
  {
    postalCode: '4064',
    city: 'Oberndorf'
  },
  {
    postalCode: '4064',
    city: 'Oftering'
  },
  {
    postalCode: '4064',
    city: 'Staudach'
  },
  {
    postalCode: '4064',
    city: 'Trindorf'
  },
  {
    postalCode: '4064',
    city: 'Unterholz'
  },
  {
    postalCode: '4070',
    city: 'Eferding'
  },
  {
    postalCode: '4070',
    city: 'Fraham'
  },
  {
    postalCode: '4070',
    city: 'Güttlfeld'
  },
  {
    postalCode: '4070',
    city: 'Hörstorf'
  },
  {
    postalCode: '4070',
    city: 'Inn'
  },
  {
    postalCode: '4070',
    city: 'Kappelding'
  },
  {
    postalCode: '4070',
    city: 'Lahöfen'
  },
  {
    postalCode: '4070',
    city: 'Oberhillinglah'
  },
  {
    postalCode: '4070',
    city: 'Raffelding'
  },
  {
    postalCode: '4070',
    city: 'Ranzing'
  },
  {
    postalCode: '4070',
    city: 'Simbach'
  },
  {
    postalCode: '4070',
    city: 'Trattwörth'
  },
  {
    postalCode: '4070',
    city: 'Unterhillinglah'
  },
  {
    postalCode: '4070',
    city: 'Deinham'
  },
  {
    postalCode: '4070',
    city: 'Hartkirchen'
  },
  {
    postalCode: '4070',
    city: 'Gstocket'
  },
  {
    postalCode: '4070',
    city: 'Gstöttenau'
  },
  {
    postalCode: '4070',
    city: 'Hinzenbach'
  },
  {
    postalCode: '4070',
    city: 'Oberrudling'
  },
  {
    postalCode: '4070',
    city: 'Polsenz'
  },
  {
    postalCode: '4070',
    city: 'Puchet'
  },
  {
    postalCode: '4070',
    city: 'Seebach'
  },
  {
    postalCode: '4070',
    city: 'Sperneck'
  },
  {
    postalCode: '4070',
    city: 'Stieglhöfen'
  },
  {
    postalCode: '4070',
    city: 'Unterleiten'
  },
  {
    postalCode: '4070',
    city: 'Unterrudling'
  },
  {
    postalCode: '4070',
    city: 'Wackersbach'
  },
  {
    postalCode: '4070',
    city: 'Wagrein'
  },
  {
    postalCode: '4070',
    city: 'Grüben'
  },
  {
    postalCode: '4070',
    city: 'Auhof'
  },
  {
    postalCode: '4070',
    city: 'Altau'
  },
  {
    postalCode: '4070',
    city: 'Au bei Brandstatt'
  },
  {
    postalCode: '4070',
    city: 'Au bei hohen Steg'
  },
  {
    postalCode: '4070',
    city: 'Brandstatt'
  },
  {
    postalCode: '4070',
    city: 'Friedlau'
  },
  {
    postalCode: '4070',
    city: 'Leumühle'
  },
  {
    postalCode: '4070',
    city: 'Oberschaden'
  },
  {
    postalCode: '4070',
    city: 'Pupping'
  },
  {
    postalCode: '4070',
    city: 'Goldenberg'
  },
  {
    postalCode: '4070',
    city: 'Taubenbrunn'
  },
  {
    postalCode: '4070',
    city: 'Unterschaden'
  },
  {
    postalCode: '4070',
    city: 'Waschpoint'
  },
  {
    postalCode: '4070',
    city: 'Wörth'
  },
  {
    postalCode: '4070',
    city: 'Leppersdorf'
  },
  {
    postalCode: '4070',
    city: 'Kleinstroheim'
  },
  {
    postalCode: '4072',
    city: 'Aham'
  },
  {
    postalCode: '4072',
    city: 'Alkoven'
  },
  {
    postalCode: '4072',
    city: 'Annaberg'
  },
  {
    postalCode: '4072',
    city: 'Emling'
  },
  {
    postalCode: '4072',
    city: 'Forst'
  },
  {
    postalCode: '4072',
    city: 'Großhart'
  },
  {
    postalCode: '4072',
    city: 'Gstocket'
  },
  {
    postalCode: '4072',
    city: 'Hartheim'
  },
  {
    postalCode: '4072',
    city: 'Kleinhart'
  },
  {
    postalCode: '4072',
    city: 'Kranzing'
  },
  {
    postalCode: '4072',
    city: 'Oberhartheim'
  },
  {
    postalCode: '4072',
    city: 'Polsing'
  },
  {
    postalCode: '4072',
    city: 'Puchham'
  },
  {
    postalCode: '4072',
    city: 'Staudach'
  },
  {
    postalCode: '4072',
    city: 'Straß'
  },
  {
    postalCode: '4072',
    city: 'Straßham'
  },
  {
    postalCode: '4072',
    city: 'Ufer'
  },
  {
    postalCode: '4072',
    city: 'Weidach'
  },
  {
    postalCode: '4072',
    city: 'Winkeln'
  },
  {
    postalCode: '4072',
    city: 'Bergham'
  },
  {
    postalCode: '4073',
    city: 'Appersberg'
  },
  {
    postalCode: '4073',
    city: 'Dörnbach'
  },
  {
    postalCode: '4073',
    city: 'Edramsberg'
  },
  {
    postalCode: '4073',
    city: 'Fall'
  },
  {
    postalCode: '4073',
    city: 'Hitzing'
  },
  {
    postalCode: '4073',
    city: 'Höf'
  },
  {
    postalCode: '4073',
    city: 'Katzing'
  },
  {
    postalCode: '4073',
    city: 'Lohnharting'
  },
  {
    postalCode: '4073',
    city: 'Mühlbach'
  },
  {
    postalCode: '4073',
    city: 'Reith'
  },
  {
    postalCode: '4073',
    city: 'Schönering'
  },
  {
    postalCode: '4073',
    city: 'Thalham'
  },
  {
    postalCode: '4073',
    city: 'Ufer'
  },
  {
    postalCode: '4073',
    city: 'Wilhering'
  },
  {
    postalCode: '4073',
    city: 'Winkeln'
  },
  {
    postalCode: '4074',
    city: 'Schaumberg'
  },
  {
    postalCode: '4074',
    city: 'Taubing'
  },
  {
    postalCode: '4074',
    city: 'Birihub'
  },
  {
    postalCode: '4074',
    city: 'Gmeinholz'
  },
  {
    postalCode: '4074',
    city: 'Götzenberg'
  },
  {
    postalCode: '4074',
    city: 'Großstroheim'
  },
  {
    postalCode: '4074',
    city: 'Gstocket'
  },
  {
    postalCode: '4074',
    city: 'Kleinstroheim'
  },
  {
    postalCode: '4074',
    city: 'Knieparz ob der Leiten'
  },
  {
    postalCode: '4074',
    city: 'Kobling'
  },
  {
    postalCode: '4074',
    city: 'Mayrhof'
  },
  {
    postalCode: '4074',
    city: 'Mitterstroheim'
  },
  {
    postalCode: '4074',
    city: 'Reith'
  },
  {
    postalCode: '4074',
    city: 'Schnellersdorf'
  },
  {
    postalCode: '4074',
    city: 'Stallberg'
  },
  {
    postalCode: '4074',
    city: 'Stroheim'
  },
  {
    postalCode: '4074',
    city: 'Troß'
  },
  {
    postalCode: '4074',
    city: 'Windischdorf'
  },
  {
    postalCode: '4074',
    city: 'Wögern'
  },
  {
    postalCode: '4075',
    city: 'Aumühle'
  },
  {
    postalCode: '4075',
    city: 'Steinholz'
  },
  {
    postalCode: '4075',
    city: 'Kalköfen'
  },
  {
    postalCode: '4075',
    city: 'Limberg'
  },
  {
    postalCode: '4075',
    city: 'Aigen'
  },
  {
    postalCode: '4075',
    city: 'Breitenaich'
  },
  {
    postalCode: '4075',
    city: 'Finklham'
  },
  {
    postalCode: '4075',
    city: 'Herrnholz'
  },
  {
    postalCode: '4075',
    city: 'Oberndorf'
  },
  {
    postalCode: '4075',
    city: 'Roithen'
  },
  {
    postalCode: '4075',
    city: 'Untergrub'
  },
  {
    postalCode: '4076',
    city: 'Doppl'
  },
  {
    postalCode: '4076',
    city: 'Eben'
  },
  {
    postalCode: '4076',
    city: 'Fürneredt'
  },
  {
    postalCode: '4076',
    city: 'Furth'
  },
  {
    postalCode: '4076',
    city: 'Holzwiesen'
  },
  {
    postalCode: '4076',
    city: 'Karling'
  },
  {
    postalCode: '4076',
    city: 'Kirchholz'
  },
  {
    postalCode: '4076',
    city: 'Lengau'
  },
  {
    postalCode: '4076',
    city: 'Leopoldsberg'
  },
  {
    postalCode: '4076',
    city: 'Obergrub'
  },
  {
    postalCode: '4076',
    city: 'Pernau'
  },
  {
    postalCode: '4076',
    city: 'Polsenz'
  },
  {
    postalCode: '4076',
    city: 'Sankt Marienkirchen an der Polsenz'
  },
  {
    postalCode: '4076',
    city: 'Sommersberg'
  },
  {
    postalCode: '4076',
    city: 'Valtau'
  },
  {
    postalCode: '4076',
    city: 'Wieshof'
  },
  {
    postalCode: '4076',
    city: 'Unterfreundorf'
  },
  {
    postalCode: '4081',
    city: 'Hinterberg'
  },
  {
    postalCode: '4081',
    city: 'Deinham'
  },
  {
    postalCode: '4081',
    city: 'Dorf'
  },
  {
    postalCode: '4081',
    city: 'Gfehret'
  },
  {
    postalCode: '4081',
    city: 'Gstaltenhof'
  },
  {
    postalCode: '4081',
    city: 'Hachlham'
  },
  {
    postalCode: '4081',
    city: 'Hacking'
  },
  {
    postalCode: '4081',
    city: 'Hainbach'
  },
  {
    postalCode: '4081',
    city: 'Haizing'
  },
  {
    postalCode: '4081',
    city: 'Hartkirchen'
  },
  {
    postalCode: '4081',
    city: 'Hart ob Hacking'
  },
  {
    postalCode: '4081',
    city: 'Hart ob Haizing'
  },
  {
    postalCode: '4081',
    city: 'Hilkering'
  },
  {
    postalCode: '4081',
    city: 'Hinteraigen'
  },
  {
    postalCode: '4081',
    city: 'Hörmannsedt'
  },
  {
    postalCode: '4081',
    city: 'Karling'
  },
  {
    postalCode: '4081',
    city: 'Kellnering'
  },
  {
    postalCode: '4081',
    city: 'Knieparz unter der Leithen'
  },
  {
    postalCode: '4081',
    city: 'Koppl'
  },
  {
    postalCode: '4081',
    city: 'Lacken'
  },
  {
    postalCode: '4081',
    city: 'Mußbach'
  },
  {
    postalCode: '4081',
    city: 'Oed in Bergen'
  },
  {
    postalCode: '4081',
    city: 'Paching'
  },
  {
    postalCode: '4081',
    city: 'Pfaffing'
  },
  {
    postalCode: '4081',
    city: 'Poxham'
  },
  {
    postalCode: '4081',
    city: 'Rathen'
  },
  {
    postalCode: '4081',
    city: 'Reith'
  },
  {
    postalCode: '4081',
    city: 'Rienberg'
  },
  {
    postalCode: '4081',
    city: 'Schaumberg'
  },
  {
    postalCode: '4081',
    city: 'Schönleiten'
  },
  {
    postalCode: '4081',
    city: 'Senghübl'
  },
  {
    postalCode: '4081',
    city: 'Steinwand'
  },
  {
    postalCode: '4081',
    city: 'Vornholz'
  },
  {
    postalCode: '4081',
    city: 'Wolfsfurth'
  },
  {
    postalCode: '4081',
    city: 'Würting'
  },
  {
    postalCode: '4081',
    city: 'Zagl'
  },
  {
    postalCode: '4081',
    city: 'Dunzing'
  },
  {
    postalCode: '4082',
    city: 'Aschach an der Donau'
  },
  {
    postalCode: '4082',
    city: 'Ruprechting'
  },
  {
    postalCode: '4082',
    city: 'Sommerberg'
  },
  {
    postalCode: '4083',
    city: 'Au'
  },
  {
    postalCode: '4083',
    city: 'Bach'
  },
  {
    postalCode: '4083',
    city: 'Berg'
  },
  {
    postalCode: '4083',
    city: 'Donauleiten'
  },
  {
    postalCode: '4083',
    city: 'Dorf'
  },
  {
    postalCode: '4083',
    city: 'Eckersdorf'
  },
  {
    postalCode: '4083',
    city: 'Gemersdorf'
  },
  {
    postalCode: '4083',
    city: 'Grub'
  },
  {
    postalCode: '4083',
    city: 'Haibach ob der Donau'
  },
  {
    postalCode: '4083',
    city: 'Hinterberg'
  },
  {
    postalCode: '4083',
    city: 'Inzell'
  },
  {
    postalCode: '4083',
    city: 'Kobling'
  },
  {
    postalCode: '4083',
    city: 'Kolleck'
  },
  {
    postalCode: '4083',
    city: 'Komas'
  },
  {
    postalCode: '4083',
    city: 'Lehen'
  },
  {
    postalCode: '4083',
    city: 'Linetshub'
  },
  {
    postalCode: '4083',
    city: 'Mannsdorf'
  },
  {
    postalCode: '4083',
    city: 'Moos'
  },
  {
    postalCode: '4083',
    city: 'Mühlbach'
  },
  {
    postalCode: '4083',
    city: 'Obergschwendt'
  },
  {
    postalCode: '4083',
    city: 'Oberhub'
  },
  {
    postalCode: '4083',
    city: 'Oedt'
  },
  {
    postalCode: '4083',
    city: 'Pamet'
  },
  {
    postalCode: '4083',
    city: 'Pichl'
  },
  {
    postalCode: '4083',
    city: 'Pühret'
  },
  {
    postalCode: '4083',
    city: 'Reith'
  },
  {
    postalCode: '4083',
    city: 'Rennersdorf'
  },
  {
    postalCode: '4083',
    city: 'Schauerdoppl'
  },
  {
    postalCode: '4083',
    city: 'Schlögen'
  },
  {
    postalCode: '4083',
    city: 'Schlögenleiten'
  },
  {
    postalCode: '4083',
    city: 'Sieberstal'
  },
  {
    postalCode: '4083',
    city: 'Starz'
  },
  {
    postalCode: '4083',
    city: 'Untergschwendt'
  },
  {
    postalCode: '4083',
    city: 'Wies'
  },
  {
    postalCode: '4083',
    city: 'Wiesing'
  },
  {
    postalCode: '4083',
    city: 'Dunzing'
  },
  {
    postalCode: '4083',
    city: 'Mitterberg'
  },
  {
    postalCode: '4083',
    city: 'Parz'
  },
  {
    postalCode: '4083',
    city: 'Sonnleiten'
  },
  {
    postalCode: '4084',
    city: 'Hausleithen'
  },
  {
    postalCode: '4084',
    city: 'Bäckenhof'
  },
  {
    postalCode: '4084',
    city: 'Bräuleiten'
  },
  {
    postalCode: '4084',
    city: 'Dittersdorf'
  },
  {
    postalCode: '4084',
    city: 'Dörfledt'
  },
  {
    postalCode: '4084',
    city: 'Dunzing'
  },
  {
    postalCode: '4084',
    city: 'Ernleiten'
  },
  {
    postalCode: '4084',
    city: 'Ensfeld'
  },
  {
    postalCode: '4084',
    city: 'Etzing'
  },
  {
    postalCode: '4084',
    city: 'Freiling'
  },
  {
    postalCode: '4084',
    city: 'Gmein'
  },
  {
    postalCode: '4084',
    city: 'Götzling'
  },
  {
    postalCode: '4084',
    city: 'Gschwendt'
  },
  {
    postalCode: '4084',
    city: 'Hanging'
  },
  {
    postalCode: '4084',
    city: 'Hatzing'
  },
  {
    postalCode: '4084',
    city: 'Henzing'
  },
  {
    postalCode: '4084',
    city: 'Hölzing'
  },
  {
    postalCode: '4084',
    city: 'Hollerbrunn'
  },
  {
    postalCode: '4084',
    city: 'Holzwühr'
  },
  {
    postalCode: '4084',
    city: 'Hundsdorf'
  },
  {
    postalCode: '4084',
    city: 'Königsdorf'
  },
  {
    postalCode: '4084',
    city: 'Kolmhof'
  },
  {
    postalCode: '4084',
    city: 'Löcking'
  },
  {
    postalCode: '4084',
    city: 'Löwengrub'
  },
  {
    postalCode: '4084',
    city: 'Miniberg'
  },
  {
    postalCode: '4084',
    city: 'Mitterberg'
  },
  {
    postalCode: '4084',
    city: 'Mühlgraben'
  },
  {
    postalCode: '4084',
    city: 'Parz'
  },
  {
    postalCode: '4084',
    city: 'Pötzling'
  },
  {
    postalCode: '4084',
    city: 'Riesching'
  },
  {
    postalCode: '4084',
    city: 'Sankt Agatha'
  },
  {
    postalCode: '4084',
    city: 'Sattlberg'
  },
  {
    postalCode: '4084',
    city: 'Schabetsberg'
  },
  {
    postalCode: '4084',
    city: 'Scharzeredt'
  },
  {
    postalCode: '4084',
    city: 'Scheiblberg'
  },
  {
    postalCode: '4084',
    city: 'Schmieding'
  },
  {
    postalCode: '4084',
    city: 'Sonnleiten'
  },
  {
    postalCode: '4084',
    city: 'Steinzen'
  },
  {
    postalCode: '4084',
    city: 'Uring'
  },
  {
    postalCode: '4084',
    city: 'Waid'
  },
  {
    postalCode: '4085',
    city: 'Waldbach'
  },
  {
    postalCode: '4085',
    city: 'Au'
  },
  {
    postalCode: '4085',
    city: 'Freizell'
  },
  {
    postalCode: '4085',
    city: 'Kling'
  },
  {
    postalCode: '4085',
    city: 'Niederranna'
  },
  {
    postalCode: '4085',
    city: 'Kramesau'
  },
  {
    postalCode: '4085',
    city: 'Aichberg'
  },
  {
    postalCode: '4085',
    city: 'Atzersdorf'
  },
  {
    postalCode: '4085',
    city: 'Buchen'
  },
  {
    postalCode: '4085',
    city: 'Dankmairing'
  },
  {
    postalCode: '4085',
    city: 'Edt'
  },
  {
    postalCode: '4085',
    city: 'Erledt'
  },
  {
    postalCode: '4085',
    city: 'Voredt'
  },
  {
    postalCode: '4085',
    city: 'Graben'
  },
  {
    postalCode: '4085',
    city: 'Hundorf'
  },
  {
    postalCode: '4085',
    city: 'Kager'
  },
  {
    postalCode: '4085',
    city: 'Kühdoppl'
  },
  {
    postalCode: '4085',
    city: 'Mühlberg'
  },
  {
    postalCode: '4085',
    city: 'Oberngrub'
  },
  {
    postalCode: '4085',
    city: 'Ort'
  },
  {
    postalCode: '4085',
    city: 'Pasching'
  },
  {
    postalCode: '4085',
    city: 'Ratzling'
  },
  {
    postalCode: '4085',
    city: 'Saulehen'
  },
  {
    postalCode: '4085',
    city: 'Schickedt'
  },
  {
    postalCode: '4085',
    city: 'Schützenedt'
  },
  {
    postalCode: '4085',
    city: 'Sittling'
  },
  {
    postalCode: '4085',
    city: 'Straß'
  },
  {
    postalCode: '4085',
    city: 'Vornwald'
  },
  {
    postalCode: '4085',
    city: 'Waldkirchen'
  },
  {
    postalCode: '4085',
    city: 'Wesenufer'
  },
  {
    postalCode: '4085',
    city: 'Wimm'
  },
  {
    postalCode: '4090',
    city: 'Engelhartszell'
  },
  {
    postalCode: '4090',
    city: 'Engelszell'
  },
  {
    postalCode: '4090',
    city: 'Kronschlag'
  },
  {
    postalCode: '4090',
    city: 'Maierhof'
  },
  {
    postalCode: '4090',
    city: 'Oberranna'
  },
  {
    postalCode: '4090',
    city: 'Ronthal'
  },
  {
    postalCode: '4090',
    city: 'Saag'
  },
  {
    postalCode: '4090',
    city: 'Stadl'
  },
  {
    postalCode: '4090',
    city: 'Flenkental'
  },
  {
    postalCode: '4090',
    city: 'Mühlbach'
  },
  {
    postalCode: '4091',
    city: 'Achleiten'
  },
  {
    postalCode: '4091',
    city: 'Aug'
  },
  {
    postalCode: '4091',
    city: 'Hütt'
  },
  {
    postalCode: '4091',
    city: 'Urschendorf'
  },
  {
    postalCode: '4091',
    city: 'Weeg'
  },
  {
    postalCode: '4091',
    city: 'Rain'
  },
  {
    postalCode: '4091',
    city: 'Vichtenstein'
  },
  {
    postalCode: '4091',
    city: 'Kasten'
  },
  {
    postalCode: '4091',
    city: 'Wenzelberg'
  },
  {
    postalCode: '4092',
    city: 'Dietzendorf'
  },
  {
    postalCode: '4092',
    city: 'Gersdorf'
  },
  {
    postalCode: '4092',
    city: 'Kiesling'
  },
  {
    postalCode: '4092',
    city: 'Kösslarn'
  },
  {
    postalCode: '4092',
    city: 'Lanzendorf'
  },
  {
    postalCode: '4092',
    city: 'Esternberg'
  },
  {
    postalCode: '4092',
    city: 'Pfarrhof'
  },
  {
    postalCode: '4092',
    city: 'Pyrawang'
  },
  {
    postalCode: '4092',
    city: 'Reisdorf'
  },
  {
    postalCode: '4092',
    city: 'Riedlbach'
  },
  {
    postalCode: '4092',
    city: 'Ringlholz'
  },
  {
    postalCode: '4092',
    city: 'Schacher'
  },
  {
    postalCode: '4092',
    city: 'Schörgeneck'
  },
  {
    postalCode: '4092',
    city: 'Silbering'
  },
  {
    postalCode: '4092',
    city: 'Unteresternberg'
  },
  {
    postalCode: '4092',
    city: 'Urschendorf'
  },
  {
    postalCode: '4092',
    city: 'Wetzendorf'
  },
  {
    postalCode: '4092',
    city: 'Winterhof'
  },
  {
    postalCode: '4092',
    city: 'Zeilberg'
  },
  {
    postalCode: '4100',
    city: 'Goldwörth'
  },
  {
    postalCode: '4100',
    city: 'Hagenau'
  },
  {
    postalCode: '4100',
    city: 'Amberg'
  },
  {
    postalCode: '4100',
    city: 'Schlagberg'
  },
  {
    postalCode: '4100',
    city: 'Dürnberg'
  },
  {
    postalCode: '4100',
    city: 'Höflein'
  },
  {
    postalCode: '4100',
    city: 'Niederottensheim'
  },
  {
    postalCode: '4100',
    city: 'Ottensheim'
  },
  {
    postalCode: '4100',
    city: 'Weingarten'
  },
  {
    postalCode: '4100',
    city: 'Bach'
  },
  {
    postalCode: '4100',
    city: 'Rodl'
  },
  {
    postalCode: '4101',
    city: 'Ach'
  },
  {
    postalCode: '4101',
    city: 'Au'
  },
  {
    postalCode: '4101',
    city: 'Audorf'
  },
  {
    postalCode: '4101',
    city: 'Bergheim'
  },
  {
    postalCode: '4101',
    city: 'Feldkirchen an der Donau'
  },
  {
    postalCode: '4101',
    city: 'Freudenstein'
  },
  {
    postalCode: '4101',
    city: 'Gerling'
  },
  {
    postalCode: '4101',
    city: 'Hofham'
  },
  {
    postalCode: '4101',
    city: 'Lacken'
  },
  {
    postalCode: '4101',
    city: 'Mühldorf'
  },
  {
    postalCode: '4101',
    city: 'Bad Mühllacken'
  },
  {
    postalCode: '4101',
    city: 'Oberhart'
  },
  {
    postalCode: '4101',
    city: 'Oberlandshaag'
  },
  {
    postalCode: '4101',
    city: 'Oberndorf'
  },
  {
    postalCode: '4101',
    city: 'Oberstraß'
  },
  {
    postalCode: '4101',
    city: 'Oberwallsee'
  },
  {
    postalCode: '4101',
    city: 'Pesenbach'
  },
  {
    postalCode: '4101',
    city: 'Rosenleiten'
  },
  {
    postalCode: '4101',
    city: 'Unterhart'
  },
  {
    postalCode: '4101',
    city: 'Unterlandshaag'
  },
  {
    postalCode: '4101',
    city: 'Vogging'
  },
  {
    postalCode: '4101',
    city: 'Weidet'
  },
  {
    postalCode: '4101',
    city: 'Goldwörth'
  },
  {
    postalCode: '4111',
    city: 'Hamberg'
  },
  {
    postalCode: '4111',
    city: 'Bach'
  },
  {
    postalCode: '4111',
    city: 'Haid'
  },
  {
    postalCode: '4111',
    city: 'Jörgensbühl'
  },
  {
    postalCode: '4111',
    city: 'Lindham'
  },
  {
    postalCode: '4111',
    city: 'Mursberg'
  },
  {
    postalCode: '4111',
    city: 'Pösting'
  },
  {
    postalCode: '4111',
    city: 'Purwörth'
  },
  {
    postalCode: '4111',
    city: 'Rodl'
  },
  {
    postalCode: '4111',
    city: 'Schwarzgrub'
  },
  {
    postalCode: '4111',
    city: 'Walding'
  },
  {
    postalCode: '4112',
    city: 'Limberg'
  },
  {
    postalCode: '4112',
    city: 'Wieshof'
  },
  {
    postalCode: '4112',
    city: 'Haselwies'
  },
  {
    postalCode: '4112',
    city: 'Rottenegg'
  },
  {
    postalCode: '4112',
    city: 'Eschelberg'
  },
  {
    postalCode: '4112',
    city: 'Grasbach'
  },
  {
    postalCode: '4112',
    city: 'Maierleiten'
  },
  {
    postalCode: '4112',
    city: 'Mühlholz'
  },
  {
    postalCode: '4112',
    city: 'Oberstraß'
  },
  {
    postalCode: '4112',
    city: 'Sankt Gotthard im Mühlkreis'
  },
  {
    postalCode: '4112',
    city: 'Jörgensbühl'
  },
  {
    postalCode: '4112',
    city: 'Mursberg'
  },
  {
    postalCode: '4112',
    city: 'Schwarzgrub'
  },
  {
    postalCode: '4113',
    city: 'Oberlandshaag'
  },
  {
    postalCode: '4113',
    city: 'Erdmannsdorf'
  },
  {
    postalCode: '4113',
    city: 'Adsdorf'
  },
  {
    postalCode: '4113',
    city: 'Allersdorf'
  },
  {
    postalCode: '4113',
    city: 'Anzing'
  },
  {
    postalCode: '4113',
    city: 'Dunzendorf'
  },
  {
    postalCode: '4113',
    city: 'Falkenbach'
  },
  {
    postalCode: '4113',
    city: 'Gerling'
  },
  {
    postalCode: '4113',
    city: 'Grub'
  },
  {
    postalCode: '4113',
    city: 'Kobling'
  },
  {
    postalCode: '4113',
    city: 'Lanzersdorf'
  },
  {
    postalCode: '4113',
    city: 'Mahring'
  },
  {
    postalCode: '4113',
    city: 'Oberhart'
  },
  {
    postalCode: '4113',
    city: 'Reith'
  },
  {
    postalCode: '4113',
    city: 'Ritzersdorf'
  },
  {
    postalCode: '4113',
    city: 'Sankt Martin im Mühlkreis'
  },
  {
    postalCode: '4113',
    city: 'Unterhart'
  },
  {
    postalCode: '4113',
    city: 'Windischberg'
  },
  {
    postalCode: '4113',
    city: 'Windorf'
  },
  {
    postalCode: '4114',
    city: 'Exlau'
  },
  {
    postalCode: '4114',
    city: 'Partenstein'
  },
  {
    postalCode: '4114',
    city: 'Point'
  },
  {
    postalCode: '4114',
    city: 'Falkenbach'
  },
  {
    postalCode: '4114',
    city: 'Falkenberg'
  },
  {
    postalCode: '4114',
    city: 'Neuhaus an der Donau'
  },
  {
    postalCode: '4114',
    city: 'Plöcking'
  },
  {
    postalCode: '4114',
    city: 'Reith'
  },
  {
    postalCode: '4114',
    city: 'Untermühl'
  },
  {
    postalCode: '4115',
    city: 'Edholz'
  },
  {
    postalCode: '4115',
    city: 'Zaun'
  },
  {
    postalCode: '4115',
    city: 'Am Edhügel'
  },
  {
    postalCode: '4115',
    city: 'Steining'
  },
  {
    postalCode: '4115',
    city: 'Grünental'
  },
  {
    postalCode: '4115',
    city: 'Apfelsbach'
  },
  {
    postalCode: '4115',
    city: 'Kleinzell im Mühlkreis'
  },
  {
    postalCode: '4115',
    city: 'Ramersberg'
  },
  {
    postalCode: '4115',
    city: 'Weigelsdorf'
  },
  {
    postalCode: '4120',
    city: 'Langhalsen'
  },
  {
    postalCode: '4120',
    city: 'Oberfeuchtenbach'
  },
  {
    postalCode: '4120',
    city: 'Unterfeuchtenbach'
  },
  {
    postalCode: '4120',
    city: 'Schörgenhub'
  },
  {
    postalCode: '4120',
    city: 'Graben'
  },
  {
    postalCode: '4120',
    city: 'Etzleinsberg'
  },
  {
    postalCode: '4120',
    city: 'Neufelden'
  },
  {
    postalCode: '4120',
    city: 'Plankenberg'
  },
  {
    postalCode: '4120',
    city: 'Pürnstein'
  },
  {
    postalCode: '4120',
    city: 'Steinbruch'
  },
  {
    postalCode: '4120',
    city: 'Unternberg'
  },
  {
    postalCode: '4120',
    city: 'Witzersdorf'
  },
  {
    postalCode: '4120',
    city: 'Bairach'
  },
  {
    postalCode: '4120',
    city: 'Hötzeneck'
  },
  {
    postalCode: '4120',
    city: 'Pehersdorf'
  },
  {
    postalCode: '4120',
    city: 'Sankt Ulrich im Mühlkreis'
  },
  {
    postalCode: '4121',
    city: 'Altenfelden'
  },
  {
    postalCode: '4121',
    city: 'Atzesberg'
  },
  {
    postalCode: '4121',
    city: 'Blumau'
  },
  {
    postalCode: '4121',
    city: 'Doppl'
  },
  {
    postalCode: '4121',
    city: 'Fraunschlag'
  },
  {
    postalCode: '4121',
    city: 'Freileben'
  },
  {
    postalCode: '4121',
    city: 'Godersdorf'
  },
  {
    postalCode: '4121',
    city: 'Haselbach'
  },
  {
    postalCode: '4121',
    city: 'Hörhag'
  },
  {
    postalCode: '4121',
    city: 'Hühnergeschrei'
  },
  {
    postalCode: '4121',
    city: 'Mairhof'
  },
  {
    postalCode: '4121',
    city: 'Neundling'
  },
  {
    postalCode: '4121',
    city: 'Oberfeuchtenbach'
  },
  {
    postalCode: '4121',
    city: 'Panholz'
  },
  {
    postalCode: '4121',
    city: 'Starling'
  },
  {
    postalCode: '4121',
    city: 'Starz'
  },
  {
    postalCode: '4121',
    city: 'Steinerberg'
  },
  {
    postalCode: '4121',
    city: 'Unteredt'
  },
  {
    postalCode: '4121',
    city: 'Unterfeuchtenbach'
  },
  {
    postalCode: '4121',
    city: 'Weigert'
  },
  {
    postalCode: '4121',
    city: 'Wollmannsberg'
  },
  {
    postalCode: '4121',
    city: 'Partenreit'
  },
  {
    postalCode: '4121',
    city: 'Stierberg'
  },
  {
    postalCode: '4121',
    city: 'Krondorf'
  },
  {
    postalCode: '4121',
    city: 'Langhalsen'
  },
  {
    postalCode: '4121',
    city: 'Neufelden'
  },
  {
    postalCode: '4122',
    city: 'Arnreit'
  },
  {
    postalCode: '4122',
    city: 'Daim'
  },
  {
    postalCode: '4122',
    city: 'Eckersberg'
  },
  {
    postalCode: '4122',
    city: 'Etzerreit'
  },
  {
    postalCode: '4122',
    city: 'Getzing'
  },
  {
    postalCode: '4122',
    city: 'Hengstschlag'
  },
  {
    postalCode: '4122',
    city: 'Högling'
  },
  {
    postalCode: '4122',
    city: 'Hölling'
  },
  {
    postalCode: '4122',
    city: 'Humenberg'
  },
  {
    postalCode: '4122',
    city: 'Katzenbach'
  },
  {
    postalCode: '4122',
    city: 'Liebenstein'
  },
  {
    postalCode: '4122',
    city: 'Moosham'
  },
  {
    postalCode: '4122',
    city: 'Schönberg'
  },
  {
    postalCode: '4122',
    city: 'Schörsching'
  },
  {
    postalCode: '4122',
    city: 'Stierberg'
  },
  {
    postalCode: '4122',
    city: 'Untergahleiten'
  },
  {
    postalCode: '4122',
    city: 'Wippling'
  },
  {
    postalCode: '4131',
    city: 'Dorf'
  },
  {
    postalCode: '4131',
    city: 'Ebersdorf'
  },
  {
    postalCode: '4131',
    city: 'Grub'
  },
  {
    postalCode: '4131',
    city: 'Gumpesberg'
  },
  {
    postalCode: '4131',
    city: 'Haiden'
  },
  {
    postalCode: '4131',
    city: 'Kirchberg ob der Donau'
  },
  {
    postalCode: '4131',
    city: 'Mayrhof'
  },
  {
    postalCode: '4131',
    city: 'Obermühl an der Donau'
  },
  {
    postalCode: '4131',
    city: 'Seibersdorf'
  },
  {
    postalCode: '4131',
    city: 'Stieberberg'
  },
  {
    postalCode: '4131',
    city: 'Winzberg'
  },
  {
    postalCode: '4131',
    city: 'Witzersdorf'
  },
  {
    postalCode: '4131',
    city: 'Wölfling'
  },
  {
    postalCode: '4131',
    city: 'Wolkersdorf'
  },
  {
    postalCode: '4131',
    city: 'Windhag'
  },
  {
    postalCode: '4131',
    city: 'Graben'
  },
  {
    postalCode: '4131',
    city: 'Grafenau'
  },
  {
    postalCode: '4131',
    city: 'Haar'
  },
  {
    postalCode: '4132',
    city: 'Außerhötzendorf'
  },
  {
    postalCode: '4132',
    city: 'Eiglersdorf'
  },
  {
    postalCode: '4132',
    city: 'Hörbich'
  },
  {
    postalCode: '4132',
    city: 'Tannberg'
  },
  {
    postalCode: '4132',
    city: 'Atzesberg'
  },
  {
    postalCode: '4132',
    city: 'Feichten'
  },
  {
    postalCode: '4132',
    city: 'Graben'
  },
  {
    postalCode: '4132',
    city: 'Lembach im Mühlkreis'
  },
  {
    postalCode: '4132',
    city: 'Oberlembach'
  },
  {
    postalCode: '4132',
    city: 'Obernort'
  },
  {
    postalCode: '4132',
    city: 'Volkersdorf'
  },
  {
    postalCode: '4132',
    city: 'Lug'
  },
  {
    postalCode: '4132',
    city: 'Lampersdorf'
  },
  {
    postalCode: '4132',
    city: 'Raiden'
  },
  {
    postalCode: '4132',
    city: 'Römersdorf'
  },
  {
    postalCode: '4132',
    city: 'Mayrhof'
  },
  {
    postalCode: '4132',
    city: 'Starz'
  },
  {
    postalCode: '4132',
    city: 'Steining'
  },
  {
    postalCode: '4133',
    city: 'Dorf'
  },
  {
    postalCode: '4133',
    city: 'Oberbumberg'
  },
  {
    postalCode: '4133',
    city: 'Amersdorf'
  },
  {
    postalCode: '4133',
    city: 'Haar'
  },
  {
    postalCode: '4133',
    city: 'Kaindlsdorf'
  },
  {
    postalCode: '4133',
    city: 'Klotzing'
  },
  {
    postalCode: '4133',
    city: 'Lampersdorf'
  },
  {
    postalCode: '4133',
    city: 'Niederbumberg'
  },
  {
    postalCode: '4133',
    city: 'Niederkappel'
  },
  {
    postalCode: '4133',
    city: 'Rumersdorf'
  },
  {
    postalCode: '4133',
    city: 'Weikersdorf'
  },
  {
    postalCode: '4133',
    city: 'Witzersdorf'
  },
  {
    postalCode: '4133',
    city: 'Harrau'
  },
  {
    postalCode: '4133',
    city: 'Kleinobernberg'
  },
  {
    postalCode: '4133',
    city: 'Obernberg'
  },
  {
    postalCode: '4134',
    city: 'Kramsreith'
  },
  {
    postalCode: '4134',
    city: 'Krien'
  },
  {
    postalCode: '4134',
    city: 'Wögerstorf'
  },
  {
    postalCode: '4134',
    city: 'Streinesberg'
  },
  {
    postalCode: '4134',
    city: 'Wulln'
  },
  {
    postalCode: '4134',
    city: 'Berg bei Mairing'
  },
  {
    postalCode: '4134',
    city: 'Daglesbach'
  },
  {
    postalCode: '4134',
    city: 'Ebrasdorf'
  },
  {
    postalCode: '4134',
    city: 'Egnersdorf'
  },
  {
    postalCode: '4134',
    city: 'Kaindlsdorf'
  },
  {
    postalCode: '4134',
    city: 'Glotzing'
  },
  {
    postalCode: '4134',
    city: 'Kronewittet'
  },
  {
    postalCode: '4134',
    city: 'Männersdorf'
  },
  {
    postalCode: '4134',
    city: 'Moos'
  },
  {
    postalCode: '4134',
    city: 'Neundling'
  },
  {
    postalCode: '4134',
    city: 'Ollerndorf'
  },
  {
    postalCode: '4134',
    city: 'Pernersdorf'
  },
  {
    postalCode: '4134',
    city: 'Putzleinsdorf'
  },
  {
    postalCode: '4134',
    city: 'Starz'
  },
  {
    postalCode: '4134',
    city: 'Vernatzgersdorf'
  },
  {
    postalCode: '4141',
    city: 'Atzgersdorf'
  },
  {
    postalCode: '4141',
    city: 'Berg bei Hamet'
  },
  {
    postalCode: '4141',
    city: 'Erdmannsdorf'
  },
  {
    postalCode: '4141',
    city: 'Hamet'
  },
  {
    postalCode: '4141',
    city: 'Hinterleithen'
  },
  {
    postalCode: '4141',
    city: 'Irnezedt'
  },
  {
    postalCode: '4141',
    city: 'Pfarrkirchen im Mühlkreis'
  },
  {
    postalCode: '4141',
    city: 'Pollmannsdorf'
  },
  {
    postalCode: '4141',
    city: 'Scharten'
  },
  {
    postalCode: '4141',
    city: 'Schlag'
  },
  {
    postalCode: '4141',
    city: 'Waldhäusl'
  },
  {
    postalCode: '4141',
    city: 'Weberschlag'
  },
  {
    postalCode: '4141',
    city: 'Wehrbach'
  },
  {
    postalCode: '4141',
    city: 'Wernersdorf'
  },
  {
    postalCode: '4141',
    city: 'Wurzwoll'
  },
  {
    postalCode: '4141',
    city: 'Haag'
  },
  {
    postalCode: '4141',
    city: 'Harrau'
  },
  {
    postalCode: '4141',
    city: 'Hochetting'
  },
  {
    postalCode: '4141',
    city: 'Kleinstifting'
  },
  {
    postalCode: '4141',
    city: 'Krien'
  },
  {
    postalCode: '4141',
    city: 'Mairing'
  },
  {
    postalCode: '4141',
    city: 'Ollerndorf'
  },
  {
    postalCode: '4141',
    city: 'Schrattendoppel'
  },
  {
    postalCode: '4141',
    city: 'Spielleiten'
  },
  {
    postalCode: '4141',
    city: 'Starnberg'
  },
  {
    postalCode: '4141',
    city: 'Steinstraß'
  },
  {
    postalCode: '4142',
    city: 'Emmerstorf'
  },
  {
    postalCode: '4142',
    city: 'Falkenstein'
  },
  {
    postalCode: '4142',
    city: 'Gerastorf'
  },
  {
    postalCode: '4142',
    city: 'Hötzendorf'
  },
  {
    postalCode: '4142',
    city: 'Hofkirchen im Mühlkreis'
  },
  {
    postalCode: '4142',
    city: 'Hundsfülling'
  },
  {
    postalCode: '4142',
    city: 'Marsbach'
  },
  {
    postalCode: '4142',
    city: 'Wiesen'
  },
  {
    postalCode: '4142',
    city: 'Altenhof'
  },
  {
    postalCode: '4142',
    city: 'Eilmannsberg'
  },
  {
    postalCode: '4142',
    city: 'Falkenhof'
  },
  {
    postalCode: '4142',
    city: 'Hochhaus'
  },
  {
    postalCode: '4142',
    city: 'Mühlholz'
  },
  {
    postalCode: '4142',
    city: 'Ratzesberg'
  },
  {
    postalCode: '4143',
    city: 'Dorf'
  },
  {
    postalCode: '4143',
    city: 'Eitzendorf'
  },
  {
    postalCode: '4143',
    city: 'Forstedt'
  },
  {
    postalCode: '4143',
    city: 'Großmollsberg'
  },
  {
    postalCode: '4143',
    city: 'Grub'
  },
  {
    postalCode: '4143',
    city: 'Haitzendorf'
  },
  {
    postalCode: '4143',
    city: 'Kleinmollsberg'
  },
  {
    postalCode: '4143',
    city: 'Maisreith'
  },
  {
    postalCode: '4143',
    city: 'Neustift im Mühlkreis'
  },
  {
    postalCode: '4143',
    city: 'Oberaschenberg'
  },
  {
    postalCode: '4143',
    city: 'Pühret'
  },
  {
    postalCode: '4143',
    city: 'Rannariedl'
  },
  {
    postalCode: '4143',
    city: 'Steinlacken'
  },
  {
    postalCode: '4143',
    city: 'Unteraschenberg'
  },
  {
    postalCode: '4144',
    city: 'Dittmannsdorf'
  },
  {
    postalCode: '4144',
    city: 'Grubberg'
  },
  {
    postalCode: '4144',
    city: 'Hallschlag'
  },
  {
    postalCode: '4144',
    city: 'Hochödt'
  },
  {
    postalCode: '4144',
    city: 'Kaffring'
  },
  {
    postalCode: '4144',
    city: 'Oberkappel'
  },
  {
    postalCode: '4144',
    city: 'Osterwasser'
  },
  {
    postalCode: '4144',
    city: 'Albernberg'
  },
  {
    postalCode: '4144',
    city: 'Amesedt'
  },
  {
    postalCode: '4144',
    city: 'Grettenbach'
  },
  {
    postalCode: '4144',
    city: 'Karlsbach'
  },
  {
    postalCode: '4144',
    city: 'Konzing'
  },
  {
    postalCode: '4144',
    city: 'Pernerstorf'
  },
  {
    postalCode: '4144',
    city: 'Unholnedt'
  },
  {
    postalCode: '4144',
    city: 'Vatersreith'
  },
  {
    postalCode: '4144',
    city: 'Weberschlag'
  },
  {
    postalCode: '4144',
    city: 'Zanklbach'
  },
  {
    postalCode: '4144',
    city: 'Zinöck'
  },
  {
    postalCode: '4150',
    city: 'Arbesberg'
  },
  {
    postalCode: '4150',
    city: 'Berg bei Rohrbach'
  },
  {
    postalCode: '4150',
    city: 'Fraundorf'
  },
  {
    postalCode: '4150',
    city: 'Frindorf'
  },
  {
    postalCode: '4150',
    city: 'Fürling'
  },
  {
    postalCode: '4150',
    city: 'Gattergaßling'
  },
  {
    postalCode: '4150',
    city: 'Gierling'
  },
  {
    postalCode: '4150',
    city: 'Gintersberg'
  },
  {
    postalCode: '4150',
    city: 'Gollner'
  },
  {
    postalCode: '4150',
    city: 'Grub'
  },
  {
    postalCode: '4150',
    city: 'Hauzenberg'
  },
  {
    postalCode: '4150',
    city: 'Hehenberg'
  },
  {
    postalCode: '4150',
    city: 'Hintring'
  },
  {
    postalCode: '4150',
    city: 'Hundbrenning'
  },
  {
    postalCode: '4150',
    city: 'Katzing'
  },
  {
    postalCode: '4150',
    city: 'Keppling'
  },
  {
    postalCode: '4150',
    city: 'Krien'
  },
  {
    postalCode: '4150',
    city: 'Märzing'
  },
  {
    postalCode: '4150',
    city: 'Neundling'
  },
  {
    postalCode: '4150',
    city: 'Nößlbach'
  },
  {
    postalCode: '4150',
    city: 'Perwolfing'
  },
  {
    postalCode: '4150',
    city: 'Reith'
  },
  {
    postalCode: '4150',
    city: 'Scheiblberg'
  },
  {
    postalCode: '4150',
    city: 'Sexling'
  },
  {
    postalCode: '4150',
    city: 'Steineck'
  },
  {
    postalCode: '4150',
    city: 'Wandschaml'
  },
  {
    postalCode: '4150',
    city: 'Berlesreith'
  },
  {
    postalCode: '4150',
    city: 'Diendorf'
  },
  {
    postalCode: '4150',
    city: 'Dobretshofen'
  },
  {
    postalCode: '4150',
    city: 'Götzendorf'
  },
  {
    postalCode: '4150',
    city: 'Gumpenberg'
  },
  {
    postalCode: '4150',
    city: 'Haugsberg'
  },
  {
    postalCode: '4150',
    city: 'Kanten'
  },
  {
    postalCode: '4150',
    city: 'Kimmerting'
  },
  {
    postalCode: '4150',
    city: 'Liebetsberg'
  },
  {
    postalCode: '4150',
    city: 'Oberfischbach'
  },
  {
    postalCode: '4150',
    city: 'Obergahleiten'
  },
  {
    postalCode: '4150',
    city: 'Obergrünau'
  },
  {
    postalCode: '4150',
    city: 'Obermayrhof'
  },
  {
    postalCode: '4150',
    city: 'Oberneudorf'
  },
  {
    postalCode: '4150',
    city: 'Oepping'
  },
  {
    postalCode: '4150',
    city: 'Peherstorf'
  },
  {
    postalCode: '4150',
    city: 'Pitretsberg'
  },
  {
    postalCode: '4150',
    city: 'Rumerstorf'
  },
  {
    postalCode: '4150',
    city: 'Salaberg'
  },
  {
    postalCode: '4150',
    city: 'Unterfischbach'
  },
  {
    postalCode: '4150',
    city: 'Untergrünau'
  },
  {
    postalCode: '4150',
    city: 'Autengrub'
  },
  {
    postalCode: '4150',
    city: 'Harrau'
  },
  {
    postalCode: '4150',
    city: 'Lanzerstorf'
  },
  {
    postalCode: '4150',
    city: 'Rohrbach in Oberösterreich'
  },
  {
    postalCode: '4150',
    city: 'Schönberg'
  },
  {
    postalCode: '4150',
    city: 'Altenhofen'
  },
  {
    postalCode: '4150',
    city: 'Sprinzenstein'
  },
  {
    postalCode: '4152',
    city: 'Atzesberg'
  },
  {
    postalCode: '4152',
    city: 'Hohenschlag'
  },
  {
    postalCode: '4152',
    city: 'Mitternschlag'
  },
  {
    postalCode: '4152',
    city: 'Obernreith'
  },
  {
    postalCode: '4152',
    city: 'Ohnerstorf'
  },
  {
    postalCode: '4152',
    city: 'Wollerdorf'
  },
  {
    postalCode: '4152',
    city: 'Fuchsberg'
  },
  {
    postalCode: '4152',
    city: 'Eilmannsberg'
  },
  {
    postalCode: '4152',
    city: 'Hörbich'
  },
  {
    postalCode: '4152',
    city: 'Krondorf'
  },
  {
    postalCode: '4152',
    city: 'Unternreith'
  },
  {
    postalCode: '4152',
    city: 'Innerödt'
  },
  {
    postalCode: '4152',
    city: 'Götzendorf'
  },
  {
    postalCode: '4152',
    city: 'Altendorf'
  },
  {
    postalCode: '4152',
    city: 'Auerbach'
  },
  {
    postalCode: '4152',
    city: 'Dorf'
  },
  {
    postalCode: '4152',
    city: 'Fürling'
  },
  {
    postalCode: '4152',
    city: 'Graben'
  },
  {
    postalCode: '4152',
    city: 'Hennerbach'
  },
  {
    postalCode: '4152',
    city: 'Innerhötzendorf'
  },
  {
    postalCode: '4152',
    city: 'Kickingerödt'
  },
  {
    postalCode: '4152',
    city: 'Kielesreith'
  },
  {
    postalCode: '4152',
    city: 'Lämmerstorf'
  },
  {
    postalCode: '4152',
    city: 'Leiten'
  },
  {
    postalCode: '4152',
    city: 'Mairhof'
  },
  {
    postalCode: '4152',
    city: 'Meising'
  },
  {
    postalCode: '4152',
    city: 'Meisingerödt'
  },
  {
    postalCode: '4152',
    city: 'Mühel'
  },
  {
    postalCode: '4152',
    city: 'Oberpeilstein'
  },
  {
    postalCode: '4152',
    city: 'Pfaffenberg'
  },
  {
    postalCode: '4152',
    city: 'Pogendorf'
  },
  {
    postalCode: '4152',
    city: 'Poppen'
  },
  {
    postalCode: '4152',
    city: 'Rutzersdorf'
  },
  {
    postalCode: '4152',
    city: 'Sankt Leonhard'
  },
  {
    postalCode: '4152',
    city: 'Sarleinsbach'
  },
  {
    postalCode: '4152',
    city: 'Schinken'
  },
  {
    postalCode: '4152',
    city: 'Schölling'
  },
  {
    postalCode: '4152',
    city: 'Schwand'
  },
  {
    postalCode: '4152',
    city: 'Weißgraben'
  },
  {
    postalCode: '4152',
    city: 'Wintersberg'
  },
  {
    postalCode: '4152',
    city: 'Wolf'
  },
  {
    postalCode: '4153',
    city: 'Niederkraml'
  },
  {
    postalCode: '4153',
    city: 'Vorderschiffl'
  },
  {
    postalCode: '4153',
    city: 'Vorderschlag'
  },
  {
    postalCode: '4153',
    city: 'Kollerschlag'
  },
  {
    postalCode: '4153',
    city: 'Vordernebelberg'
  },
  {
    postalCode: '4153',
    city: 'Oberneudorf'
  },
  {
    postalCode: '4153',
    city: 'Schönberg'
  },
  {
    postalCode: '4153',
    city: 'Steinberg'
  },
  {
    postalCode: '4153',
    city: 'Berging'
  },
  {
    postalCode: '4153',
    city: 'Danneredt'
  },
  {
    postalCode: '4153',
    city: 'Diendorf'
  },
  {
    postalCode: '4153',
    city: 'Emsmannsreit'
  },
  {
    postalCode: '4153',
    city: 'Eschernhof'
  },
  {
    postalCode: '4153',
    city: 'Exenschlag'
  },
  {
    postalCode: '4153',
    city: 'Flatting'
  },
  {
    postalCode: '4153',
    city: 'Geretschlag'
  },
  {
    postalCode: '4153',
    city: 'Humeredt'
  },
  {
    postalCode: '4153',
    city: 'Kicking'
  },
  {
    postalCode: '4153',
    city: 'Kirchbach'
  },
  {
    postalCode: '4153',
    city: 'Martschlag'
  },
  {
    postalCode: '4153',
    city: 'Oberpeilstein'
  },
  {
    postalCode: '4153',
    city: 'Peilstein im Mühlviertel'
  },
  {
    postalCode: '4153',
    city: 'Rampetzreit'
  },
  {
    postalCode: '4153',
    city: 'Stierberg'
  },
  {
    postalCode: '4153',
    city: 'Vordorf'
  },
  {
    postalCode: '4153',
    city: 'Weixelbaum'
  },
  {
    postalCode: '4154',
    city: 'Hinterschiffl'
  },
  {
    postalCode: '4154',
    city: 'Albenödt'
  },
  {
    postalCode: '4154',
    city: 'Fuchsödt'
  },
  {
    postalCode: '4154',
    city: 'Hanging'
  },
  {
    postalCode: '4154',
    city: 'Haselbach'
  },
  {
    postalCode: '4154',
    city: 'Kollerschlag'
  },
  {
    postalCode: '4154',
    city: 'Lengau'
  },
  {
    postalCode: '4154',
    city: 'Mistlberg'
  },
  {
    postalCode: '4154',
    city: 'Raschau'
  },
  {
    postalCode: '4154',
    city: 'Sauedt'
  },
  {
    postalCode: '4154',
    city: 'Schröck'
  },
  {
    postalCode: '4154',
    city: 'Stratberg'
  },
  {
    postalCode: '4154',
    city: 'Heinrichsberg'
  },
  {
    postalCode: '4154',
    city: 'Nebelberg'
  },
  {
    postalCode: '4154',
    city: 'Stift am Grenzbach'
  },
  {
    postalCode: '4154',
    city: 'Vordernebelberg'
  },
  {
    postalCode: '4154',
    city: 'Lamprechtswiesen'
  },
  {
    postalCode: '4154',
    city: 'Mollmannsreith'
  },
  {
    postalCode: '4154',
    city: 'Schöffgattern'
  },
  {
    postalCode: '4160',
    city: 'Aigen im Mühlkreis'
  },
  {
    postalCode: '4160',
    city: 'Grünwald'
  },
  {
    postalCode: '4160',
    city: 'Rudolfing'
  },
  {
    postalCode: '4160',
    city: 'Haugsberg'
  },
  {
    postalCode: '4160',
    city: 'Baureith'
  },
  {
    postalCode: '4160',
    city: 'Breitenstein'
  },
  {
    postalCode: '4160',
    city: 'Diendorf'
  },
  {
    postalCode: '4160',
    city: 'Geiselreith'
  },
  {
    postalCode: '4160',
    city: 'Kerschbaum'
  },
  {
    postalCode: '4160',
    city: 'Natschlag'
  },
  {
    postalCode: '4160',
    city: 'Sankt Wolfgang'
  },
  {
    postalCode: '4160',
    city: 'Schlägl'
  },
  {
    postalCode: '4160',
    city: 'Unterneudorf'
  },
  {
    postalCode: '4160',
    city: 'Weichsberg'
  },
  {
    postalCode: '4160',
    city: 'Winkl'
  },
  {
    postalCode: '4160',
    city: 'Wurmbrand'
  },
  {
    postalCode: '4160',
    city: 'Schindlau'
  },
  {
    postalCode: '4160',
    city: 'Zaglau'
  },
  {
    postalCode: '4161',
    city: 'Sonnenwald'
  },
  {
    postalCode: '4161',
    city: 'Holzschlag'
  },
  {
    postalCode: '4161',
    city: 'Pfaffetschlag'
  },
  {
    postalCode: '4161',
    city: 'Berdetschlag'
  },
  {
    postalCode: '4161',
    city: 'Dietrichschlag'
  },
  {
    postalCode: '4161',
    city: 'Erlet'
  },
  {
    postalCode: '4161',
    city: 'Fuchslug'
  },
  {
    postalCode: '4161',
    city: 'Hintenberg'
  },
  {
    postalCode: '4161',
    city: 'Kandlschlag'
  },
  {
    postalCode: '4161',
    city: 'Lichtenberg'
  },
  {
    postalCode: '4161',
    city: 'Mühlwald'
  },
  {
    postalCode: '4161',
    city: 'Ödenkirchen'
  },
  {
    postalCode: '4161',
    city: 'Salnau'
  },
  {
    postalCode: '4161',
    city: 'Seitelschlag'
  },
  {
    postalCode: '4161',
    city: 'Stangl'
  },
  {
    postalCode: '4161',
    city: 'Stollnberg'
  },
  {
    postalCode: '4161',
    city: 'Ulrichsberg'
  },
  {
    postalCode: '4162',
    city: 'Bräuerau'
  },
  {
    postalCode: '4162',
    city: 'Hinterschiffl'
  },
  {
    postalCode: '4162',
    city: 'Hochkraml'
  },
  {
    postalCode: '4162',
    city: 'Julbach'
  },
  {
    postalCode: '4162',
    city: 'Kriegwald'
  },
  {
    postalCode: '4162',
    city: 'Leithen'
  },
  {
    postalCode: '4162',
    city: 'Oberthiergrub'
  },
  {
    postalCode: '4162',
    city: 'Präuer'
  },
  {
    postalCode: '4162',
    city: 'Sagberg'
  },
  {
    postalCode: '4162',
    city: 'Unterthiergrub'
  },
  {
    postalCode: '4162',
    city: 'Hinterschlag'
  },
  {
    postalCode: '4162',
    city: 'Seitelschlag'
  },
  {
    postalCode: '4163',
    city: 'Freundorf'
  },
  {
    postalCode: '4163',
    city: 'Klaffer am Hochficht'
  },
  {
    postalCode: '4163',
    city: 'Panidorf'
  },
  {
    postalCode: '4163',
    city: 'Schönberg'
  },
  {
    postalCode: '4163',
    city: 'Vorderanger'
  },
  {
    postalCode: '4163',
    city: 'Seitelschlag'
  },
  {
    postalCode: '4164',
    city: 'Panidorf'
  },
  {
    postalCode: '4164',
    city: 'Schönberg'
  },
  {
    postalCode: '4164',
    city: 'Hinteranger'
  },
  {
    postalCode: '4164',
    city: 'Schwarzenberg am Böhmerwald'
  },
  {
    postalCode: '4170',
    city: 'Oberafiesl'
  },
  {
    postalCode: '4170',
    city: 'Auberg'
  },
  {
    postalCode: '4170',
    city: 'Frindorf'
  },
  {
    postalCode: '4170',
    city: 'Keppling'
  },
  {
    postalCode: '4170',
    city: 'Nößlbach'
  },
  {
    postalCode: '4170',
    city: 'Spielleiten'
  },
  {
    postalCode: '4170',
    city: 'Felberau'
  },
  {
    postalCode: '4170',
    city: 'Hartmannsdorf'
  },
  {
    postalCode: '4170',
    city: 'Haslach an der Mühl'
  },
  {
    postalCode: '4170',
    city: 'Jaukenberg'
  },
  {
    postalCode: '4170',
    city: 'Neudorf'
  },
  {
    postalCode: '4170',
    city: 'Damreith'
  },
  {
    postalCode: '4170',
    city: 'Hinternberg'
  },
  {
    postalCode: '4170',
    city: 'Hochhausen'
  },
  {
    postalCode: '4170',
    city: 'Hörleinsödt'
  },
  {
    postalCode: '4170',
    city: 'Lichtenau im Mühlkreis'
  },
  {
    postalCode: '4170',
    city: 'Oedt'
  },
  {
    postalCode: '4170',
    city: 'Unterurasch'
  },
  {
    postalCode: '4170',
    city: 'Almesberg'
  },
  {
    postalCode: '4170',
    city: 'Günterreith'
  },
  {
    postalCode: '4170',
    city: 'Laimbach'
  },
  {
    postalCode: '4170',
    city: 'Minihof'
  },
  {
    postalCode: '4170',
    city: 'Morau'
  },
  {
    postalCode: '4170',
    city: 'Sankt Oswald bei Haslach'
  },
  {
    postalCode: '4170',
    city: 'Sattling'
  },
  {
    postalCode: '4170',
    city: 'Schwackerreith'
  },
  {
    postalCode: '4170',
    city: 'Unterriedl'
  },
  {
    postalCode: '4170',
    city: 'Dambergschlag'
  },
  {
    postalCode: '4170',
    city: 'Gmain'
  },
  {
    postalCode: '4170',
    city: 'Haid'
  },
  {
    postalCode: '4170',
    city: 'Herrnschlag'
  },
  {
    postalCode: '4170',
    city: 'Innenschlag'
  },
  {
    postalCode: '4170',
    city: 'Obereben'
  },
  {
    postalCode: '4170',
    city: 'Oberriedl'
  },
  {
    postalCode: '4170',
    city: 'Raiden'
  },
  {
    postalCode: '4170',
    city: 'Sankt Stefan am Walde'
  },
  {
    postalCode: '4170',
    city: 'Untereben'
  },
  {
    postalCode: '4171',
    city: 'Auberg'
  },
  {
    postalCode: '4171',
    city: 'Iglbach'
  },
  {
    postalCode: '4171',
    city: 'Dorf'
  },
  {
    postalCode: '4171',
    city: 'Eckerstorf'
  },
  {
    postalCode: '4171',
    city: 'Habring'
  },
  {
    postalCode: '4171',
    city: 'Kasten'
  },
  {
    postalCode: '4171',
    city: 'Sankt Peter am Wimberg'
  },
  {
    postalCode: '4171',
    city: 'Simaden'
  },
  {
    postalCode: '4171',
    city: 'Strass'
  },
  {
    postalCode: '4171',
    city: 'Uttendorf'
  },
  {
    postalCode: '4171',
    city: 'Engersdorf'
  },
  {
    postalCode: '4171',
    city: 'Unterriedl'
  },
  {
    postalCode: '4171',
    city: 'Hötzeneck'
  },
  {
    postalCode: '4172',
    city: 'Steinbach'
  },
  {
    postalCode: '4172',
    city: 'Penning'
  },
  {
    postalCode: '4172',
    city: 'Petersberg'
  },
  {
    postalCode: '4172',
    city: 'Sankt Johann am Wimberg'
  },
  {
    postalCode: '4172',
    city: 'Schlag'
  },
  {
    postalCode: '4172',
    city: 'Sichersdorf'
  },
  {
    postalCode: '4173',
    city: 'Neudorf'
  },
  {
    postalCode: '4173',
    city: 'Stamering'
  },
  {
    postalCode: '4173',
    city: 'Schindlberg'
  },
  {
    postalCode: '4173',
    city: 'Höf'
  },
  {
    postalCode: '4173',
    city: 'Grubdorf'
  },
  {
    postalCode: '4173',
    city: 'Grubdorf-Siedlung'
  },
  {
    postalCode: '4173',
    city: 'Haslhof'
  },
  {
    postalCode: '4173',
    city: 'Kepling'
  },
  {
    postalCode: '4173',
    city: 'Königsdorf'
  },
  {
    postalCode: '4173',
    city: 'Rammerstorf'
  },
  {
    postalCode: '4173',
    city: 'Rechberg'
  },
  {
    postalCode: '4173',
    city: 'Sankt Veit im Mühlkreis'
  },
  {
    postalCode: '4173',
    city: 'Windhag'
  },
  {
    postalCode: '4173',
    city: 'Wögersdorf'
  },
  {
    postalCode: '4174',
    city: 'Allersdorf'
  },
  {
    postalCode: '4174',
    city: 'Baumgartsau'
  },
  {
    postalCode: '4174',
    city: 'Drautendorf'
  },
  {
    postalCode: '4174',
    city: 'Niederwaldkirchen'
  },
  {
    postalCode: '4174',
    city: 'Steinbach'
  },
  {
    postalCode: '4174',
    city: 'Uttendorf'
  },
  {
    postalCode: '4174',
    city: 'Witzersdorf'
  },
  {
    postalCode: '4174',
    city: 'Wolkersdorf'
  },
  {
    postalCode: '4174',
    city: 'Zeißendorf'
  },
  {
    postalCode: '4174',
    city: 'Hötzeneck'
  },
  {
    postalCode: '4174',
    city: 'Pehersdorf'
  },
  {
    postalCode: '4174',
    city: 'Haslhof'
  },
  {
    postalCode: '4175',
    city: 'Anzing'
  },
  {
    postalCode: '4175',
    city: 'Bogendorf'
  },
  {
    postalCode: '4175',
    city: 'Buchholz'
  },
  {
    postalCode: '4175',
    city: 'Eidendorf'
  },
  {
    postalCode: '4175',
    city: 'Felsleiten'
  },
  {
    postalCode: '4175',
    city: 'Freilassing'
  },
  {
    postalCode: '4175',
    city: 'Gaisberg'
  },
  {
    postalCode: '4175',
    city: 'Gerling'
  },
  {
    postalCode: '4175',
    city: 'Grasbach'
  },
  {
    postalCode: '4175',
    city: 'Herzogsdorf'
  },
  {
    postalCode: '4175',
    city: 'Hilkering'
  },
  {
    postalCode: '4175',
    city: 'Hofing'
  },
  {
    postalCode: '4175',
    city: 'Koth'
  },
  {
    postalCode: '4175',
    city: 'Mahring'
  },
  {
    postalCode: '4175',
    city: 'Mühlholz'
  },
  {
    postalCode: '4175',
    city: 'Neudorf'
  },
  {
    postalCode: '4175',
    city: 'Neußerling'
  },
  {
    postalCode: '4175',
    city: 'Stamering'
  },
  {
    postalCode: '4175',
    city: 'Stötten'
  },
  {
    postalCode: '4175',
    city: 'Wigretsberg'
  },
  {
    postalCode: '4175',
    city: 'Rammerstorf'
  },
  {
    postalCode: '4180',
    city: 'Glashütten'
  },
  {
    postalCode: '4180',
    city: 'Dreiegg'
  },
  {
    postalCode: '4180',
    city: 'Rudersbach'
  },
  {
    postalCode: '4180',
    city: 'Sonnberg'
  },
  {
    postalCode: '4180',
    city: 'Innernschlag'
  },
  {
    postalCode: '4180',
    city: 'Langzwettl'
  },
  {
    postalCode: '4180',
    city: 'Saumstraß'
  },
  {
    postalCode: '4180',
    city: 'Schauerleithen'
  },
  {
    postalCode: '4180',
    city: 'Schauerschlag'
  },
  {
    postalCode: '4180',
    city: 'Straß'
  },
  {
    postalCode: '4180',
    city: 'Zwettl an der Rodl'
  },
  {
    postalCode: '4181',
    city: 'Berndorf'
  },
  {
    postalCode: '4181',
    city: 'Neudorf'
  },
  {
    postalCode: '4181',
    city: 'Fuchsgraben'
  },
  {
    postalCode: '4181',
    city: 'In der Au'
  },
  {
    postalCode: '4181',
    city: 'Königsberg'
  },
  {
    postalCode: '4181',
    city: 'Mitterfeld'
  },
  {
    postalCode: '4181',
    city: 'Teichfeld'
  },
  {
    postalCode: '4181',
    city: 'Amesschlag'
  },
  {
    postalCode: '4181',
    city: 'Lobenstein'
  },
  {
    postalCode: '4181',
    city: 'Oberneukirchen'
  },
  {
    postalCode: '4181',
    city: 'Reindlsedt'
  },
  {
    postalCode: '4181',
    city: 'Schaffetschlag'
  },
  {
    postalCode: '4181',
    city: 'Schauerschlag'
  },
  {
    postalCode: '4181',
    city: 'Unterbrunnwald'
  },
  {
    postalCode: '4181',
    city: 'Unterwaldschlag'
  },
  {
    postalCode: '4181',
    city: 'Innernschlag'
  },
  {
    postalCode: '4182',
    city: 'Schallenberg'
  },
  {
    postalCode: '4182',
    city: 'Froschau'
  },
  {
    postalCode: '4182',
    city: 'Höf'
  },
  {
    postalCode: '4182',
    city: 'Königsdorf'
  },
  {
    postalCode: '4182',
    city: 'Punzing'
  },
  {
    postalCode: '4182',
    city: 'Reindlsedt'
  },
  {
    postalCode: '4182',
    city: 'Waxenberg'
  },
  {
    postalCode: '4182',
    city: 'Wögersdorf'
  },
  {
    postalCode: '4183',
    city: 'Ahorn'
  },
  {
    postalCode: '4183',
    city: 'Kleintraberg'
  },
  {
    postalCode: '4183',
    city: 'Lichtmeßberg'
  },
  {
    postalCode: '4183',
    city: 'Oberbrunnwald'
  },
  {
    postalCode: '4183',
    city: 'Obertraberg'
  },
  {
    postalCode: '4183',
    city: 'Großtraberg'
  },
  {
    postalCode: '4183',
    city: 'Oberwaldschlag'
  },
  {
    postalCode: '4183',
    city: 'Unterwaldschlag'
  },
  {
    postalCode: '4183',
    city: 'Mitterbrunnwald'
  },
  {
    postalCode: '4183',
    city: 'Unterbrunnwald'
  },
  {
    postalCode: '4184',
    city: 'Köckendorf'
  },
  {
    postalCode: '4184',
    city: 'Neuschlag'
  },
  {
    postalCode: '4184',
    city: 'Unterafiesl'
  },
  {
    postalCode: '4184',
    city: 'Waldhäuser'
  },
  {
    postalCode: '4184',
    city: 'Ahorn'
  },
  {
    postalCode: '4184',
    city: 'Helfenberg'
  },
  {
    postalCode: '4184',
    city: 'Penning'
  },
  {
    postalCode: '4184',
    city: 'Piberstein'
  },
  {
    postalCode: '4184',
    city: 'Thurnerschlag'
  },
  {
    postalCode: '4184',
    city: 'Altenschlag'
  },
  {
    postalCode: '4184',
    city: 'Auhäuser'
  },
  {
    postalCode: '4184',
    city: 'Dobring'
  },
  {
    postalCode: '4184',
    city: 'Mühlholz'
  },
  {
    postalCode: '4184',
    city: 'Neuling'
  },
  {
    postalCode: '4184',
    city: 'Preßleithen'
  },
  {
    postalCode: '4184',
    city: 'Spanfeld'
  },
  {
    postalCode: '4184',
    city: 'Untereben'
  },
  {
    postalCode: '4184',
    city: 'Piberschlag'
  },
  {
    postalCode: '4184',
    city: 'Geierschlag'
  },
  {
    postalCode: '4190',
    city: 'Affetschlag'
  },
  {
    postalCode: '4190',
    city: 'Bad Leonfelden'
  },
  {
    postalCode: '4190',
    city: 'Böheimschlag'
  },
  {
    postalCode: '4190',
    city: 'Burgfried'
  },
  {
    postalCode: '4190',
    city: 'Dietrichschlag'
  },
  {
    postalCode: '4190',
    city: 'Dürnau'
  },
  {
    postalCode: '4190',
    city: 'Elmegg'
  },
  {
    postalCode: '4190',
    city: 'Farb'
  },
  {
    postalCode: '4190',
    city: 'Haid'
  },
  {
    postalCode: '4190',
    city: 'Langbruck'
  },
  {
    postalCode: '4190',
    city: 'Oberlaimbach'
  },
  {
    postalCode: '4190',
    city: 'Oberstern'
  },
  {
    postalCode: '4190',
    city: 'Oberstiftung'
  },
  {
    postalCode: '4190',
    city: 'Rading'
  },
  {
    postalCode: '4190',
    city: 'Roßberg'
  },
  {
    postalCode: '4190',
    city: 'Schönau'
  },
  {
    postalCode: '4190',
    city: 'Silberhartschlag'
  },
  {
    postalCode: '4190',
    city: 'Spielau'
  },
  {
    postalCode: '4190',
    city: 'Unterlaimbach'
  },
  {
    postalCode: '4190',
    city: 'Unterstern'
  },
  {
    postalCode: '4190',
    city: 'Unterstiftung'
  },
  {
    postalCode: '4190',
    city: 'Weigetschlag'
  },
  {
    postalCode: '4190',
    city: 'Weinzierl'
  },
  {
    postalCode: '4190',
    city: 'Amesberg'
  },
  {
    postalCode: '4190',
    city: 'Amesschlag'
  },
  {
    postalCode: '4190',
    city: 'Brunnwald'
  },
  {
    postalCode: '4190',
    city: 'Ortschlag'
  },
  {
    postalCode: '4191',
    city: 'Guglwald'
  },
  {
    postalCode: '4191',
    city: 'Schönegg'
  },
  {
    postalCode: '4191',
    city: 'Amesschlag'
  },
  {
    postalCode: '4191',
    city: 'Bernhardschlag'
  },
  {
    postalCode: '4191',
    city: 'Eberhardschlag'
  },
  {
    postalCode: '4191',
    city: 'Gaisschlag'
  },
  {
    postalCode: '4191',
    city: 'Geierschlag'
  },
  {
    postalCode: '4191',
    city: 'Hinterweißenbach'
  },
  {
    postalCode: '4191',
    city: 'Stumpten'
  },
  {
    postalCode: '4191',
    city: 'Vorderweißenbach'
  },
  {
    postalCode: '4191',
    city: 'Am Hübl'
  },
  {
    postalCode: '4191',
    city: 'Glasau'
  },
  {
    postalCode: '4191',
    city: 'Leithen'
  },
  {
    postalCode: '4191',
    city: 'Sonnenhang'
  },
  {
    postalCode: '4191',
    city: 'Sternwald'
  },
  {
    postalCode: '4192',
    city: 'Oberdorf'
  },
  {
    postalCode: '4192',
    city: 'Thierberg'
  },
  {
    postalCode: '4192',
    city: 'Hinterkönigschlag'
  },
  {
    postalCode: '4192',
    city: 'Lichtenstein'
  },
  {
    postalCode: '4192',
    city: 'Liebenschlag'
  },
  {
    postalCode: '4192',
    city: 'Schenkenfelden'
  },
  {
    postalCode: '4192',
    city: 'Schild'
  },
  {
    postalCode: '4192',
    city: 'Steinschild'
  },
  {
    postalCode: '4192',
    city: 'Vorderkönigschlag'
  },
  {
    postalCode: '4193',
    city: 'Prechtleinschlag'
  },
  {
    postalCode: '4193',
    city: 'Thierberg'
  },
  {
    postalCode: '4193',
    city: 'Eibenstein'
  },
  {
    postalCode: '4193',
    city: 'Stiftung'
  },
  {
    postalCode: '4193',
    city: 'Summerau'
  },
  {
    postalCode: '4193',
    city: 'Allhut'
  },
  {
    postalCode: '4193',
    city: 'Böhmdorf'
  },
  {
    postalCode: '4193',
    city: 'Hayrl'
  },
  {
    postalCode: '4193',
    city: 'Kohlgrub'
  },
  {
    postalCode: '4193',
    city: 'Liebenthal'
  },
  {
    postalCode: '4193',
    city: 'Miesenbach'
  },
  {
    postalCode: '4193',
    city: 'Niederreichenthal'
  },
  {
    postalCode: '4193',
    city: 'Reichenthal'
  },
  {
    postalCode: '4193',
    city: 'Schwarzenbach'
  },
  {
    postalCode: '4193',
    city: 'Vierhöf'
  },
  {
    postalCode: '4193',
    city: 'Schöndorf'
  },
  {
    postalCode: '4201',
    city: 'Aschlberg'
  },
  {
    postalCode: '4201',
    city: 'Berndorf'
  },
  {
    postalCode: '4201',
    city: 'Edt'
  },
  {
    postalCode: '4201',
    city: 'Eidenberg'
  },
  {
    postalCode: '4201',
    city: 'Felsleiten'
  },
  {
    postalCode: '4201',
    city: 'Obergeng'
  },
  {
    postalCode: '4201',
    city: 'Schiefegg'
  },
  {
    postalCode: '4201',
    city: 'Staubgasse'
  },
  {
    postalCode: '4201',
    city: 'Untergeng'
  },
  {
    postalCode: '4201',
    city: 'Amberg'
  },
  {
    postalCode: '4201',
    city: 'Anger'
  },
  {
    postalCode: '4201',
    city: 'Feldsdorf'
  },
  {
    postalCode: '4201',
    city: 'Gramastetten'
  },
  {
    postalCode: '4201',
    city: 'Hals'
  },
  {
    postalCode: '4201',
    city: 'Hamberg'
  },
  {
    postalCode: '4201',
    city: 'Lassersdorf'
  },
  {
    postalCode: '4201',
    city: 'Lichtenhag'
  },
  {
    postalCode: '4201',
    city: 'Limberg'
  },
  {
    postalCode: '4201',
    city: 'Maierleiten'
  },
  {
    postalCode: '4201',
    city: 'Stötten'
  },
  {
    postalCode: '4201',
    city: 'Türkstetten'
  },
  {
    postalCode: '4201',
    city: 'Wieshof'
  },
  {
    postalCode: '4201',
    city: 'Kammerschlag'
  },
  {
    postalCode: '4201',
    city: 'Kronabitedt'
  },
  {
    postalCode: '4201',
    city: 'Mühlberg'
  },
  {
    postalCode: '4201',
    city: 'Innernschlag'
  },
  {
    postalCode: '4202',
    city: 'Kelzendorf'
  },
  {
    postalCode: '4202',
    city: 'Stratreith'
  },
  {
    postalCode: '4202',
    city: 'Wildberg'
  },
  {
    postalCode: '4202',
    city: 'Schiefegg'
  },
  {
    postalCode: '4202',
    city: 'Althellmonsödt'
  },
  {
    postalCode: '4202',
    city: 'Auedt'
  },
  {
    postalCode: '4202',
    city: 'Eckartsbrunn'
  },
  {
    postalCode: '4202',
    city: 'Hellmonsödt'
  },
  {
    postalCode: '4202',
    city: 'Oberaigen'
  },
  {
    postalCode: '4202',
    city: 'Pelmberg'
  },
  {
    postalCode: '4202',
    city: 'Weberndorf'
  },
  {
    postalCode: '4202',
    city: 'Weignersdorf'
  },
  {
    postalCode: '4202',
    city: 'Davidschlag'
  },
  {
    postalCode: '4202',
    city: 'Eben'
  },
  {
    postalCode: '4202',
    city: 'Geitenedt'
  },
  {
    postalCode: '4202',
    city: 'Hochbuchedt'
  },
  {
    postalCode: '4202',
    city: 'Kirchschlag bei Linz'
  },
  {
    postalCode: '4202',
    city: 'Kronabittedt'
  },
  {
    postalCode: '4202',
    city: 'Riedl'
  },
  {
    postalCode: '4202',
    city: 'Rohrach'
  },
  {
    postalCode: '4202',
    city: 'Strich'
  },
  {
    postalCode: '4202',
    city: 'Albrechtschlag'
  },
  {
    postalCode: '4202',
    city: 'Rudersbach'
  },
  {
    postalCode: '4202',
    city: 'Sonnberg'
  },
  {
    postalCode: '4203',
    city: 'Altenberg bei Linz'
  },
  {
    postalCode: '4203',
    city: 'Donach'
  },
  {
    postalCode: '4203',
    city: 'Edt'
  },
  {
    postalCode: '4203',
    city: 'Haslach'
  },
  {
    postalCode: '4203',
    city: 'Katzgraben'
  },
  {
    postalCode: '4203',
    city: 'Kitzelsbach'
  },
  {
    postalCode: '4203',
    city: 'Kulm'
  },
  {
    postalCode: '4203',
    city: 'Niederbairing'
  },
  {
    postalCode: '4203',
    city: 'Niederwinkl'
  },
  {
    postalCode: '4203',
    city: 'Oberbairing'
  },
  {
    postalCode: '4203',
    city: 'Oberkulm'
  },
  {
    postalCode: '4203',
    city: 'Oberweitrag'
  },
  {
    postalCode: '4203',
    city: 'Oberwinkl'
  },
  {
    postalCode: '4203',
    city: 'Pargfried'
  },
  {
    postalCode: '4203',
    city: 'Preising'
  },
  {
    postalCode: '4203',
    city: 'Ramersdorf'
  },
  {
    postalCode: '4203',
    city: 'Schwarzendorf'
  },
  {
    postalCode: '4203',
    city: 'Stratreith'
  },
  {
    postalCode: '4203',
    city: 'Unterweitrag'
  },
  {
    postalCode: '4203',
    city: 'Weignersedt'
  },
  {
    postalCode: '4203',
    city: 'Willersdorf'
  },
  {
    postalCode: '4203',
    city: 'Windpassing'
  },
  {
    postalCode: '4203',
    city: 'Würschendorf'
  },
  {
    postalCode: '4204',
    city: 'Affenberg'
  },
  {
    postalCode: '4204',
    city: 'Haibach im Mühlkreis'
  },
  {
    postalCode: '4204',
    city: 'Kaindorf'
  },
  {
    postalCode: '4204',
    city: 'Oberbaumgarten'
  },
  {
    postalCode: '4204',
    city: 'Wirth'
  },
  {
    postalCode: '4204',
    city: 'Aigen'
  },
  {
    postalCode: '4204',
    city: 'Altenbergerstraße'
  },
  {
    postalCode: '4204',
    city: 'Baumgarten'
  },
  {
    postalCode: '4204',
    city: 'Blaßberg'
  },
  {
    postalCode: '4204',
    city: 'Gusental'
  },
  {
    postalCode: '4204',
    city: 'Mistelbach'
  },
  {
    postalCode: '4204',
    city: 'Renning'
  },
  {
    postalCode: '4204',
    city: 'Schubertweg'
  },
  {
    postalCode: '4204',
    city: 'Hadersdorf'
  },
  {
    postalCode: '4204',
    city: 'Helmetzedt'
  },
  {
    postalCode: '4204',
    city: 'Ottenschlag im Mühlkreis'
  },
  {
    postalCode: '4204',
    city: 'Rohrbach'
  },
  {
    postalCode: '4204',
    city: 'Wintersdorf'
  },
  {
    postalCode: '4204',
    city: 'Puchberg'
  },
  {
    postalCode: '4204',
    city: 'Habruck'
  },
  {
    postalCode: '4204',
    city: 'Ramberg'
  },
  {
    postalCode: '4204',
    city: 'Reichenau im Mühlkreis'
  },
  {
    postalCode: '4204',
    city: 'Zeil'
  },
  {
    postalCode: '4209',
    city: 'Oberkulm'
  },
  {
    postalCode: '4209',
    city: 'Aigen'
  },
  {
    postalCode: '4209',
    city: 'Amberg'
  },
  {
    postalCode: '4209',
    city: 'Au'
  },
  {
    postalCode: '4209',
    city: 'Außertreffling'
  },
  {
    postalCode: '4209',
    city: 'Bach'
  },
  {
    postalCode: '4209',
    city: 'Edtsdorf'
  },
  {
    postalCode: '4209',
    city: 'Engerwitzberg'
  },
  {
    postalCode: '4209',
    city: 'Engerwitzdorf'
  },
  {
    postalCode: '4209',
    city: 'Gallusberg'
  },
  {
    postalCode: '4209',
    city: 'Gratz'
  },
  {
    postalCode: '4209',
    city: 'Haid'
  },
  {
    postalCode: '4209',
    city: 'Hohenstein'
  },
  {
    postalCode: '4209',
    city: 'Holzwiesen'
  },
  {
    postalCode: '4209',
    city: 'Innertreffling'
  },
  {
    postalCode: '4209',
    city: 'Klendorf'
  },
  {
    postalCode: '4209',
    city: 'Langwiesen'
  },
  {
    postalCode: '4209',
    city: 'Linzerberg'
  },
  {
    postalCode: '4209',
    city: 'Mittertreffling'
  },
  {
    postalCode: '4209',
    city: 'Niederkulm'
  },
  {
    postalCode: '4209',
    city: 'Niederreitern'
  },
  {
    postalCode: '4209',
    city: 'Niederthal'
  },
  {
    postalCode: '4209',
    city: 'Oberreichenbach'
  },
  {
    postalCode: '4209',
    city: 'Oberthal'
  },
  {
    postalCode: '4209',
    city: 'Schmiedgassen'
  },
  {
    postalCode: '4209',
    city: 'Schweinbach'
  },
  {
    postalCode: '4209',
    city: 'Steinreith'
  },
  {
    postalCode: '4209',
    city: 'Unterreichenbach'
  },
  {
    postalCode: '4209',
    city: 'Wolfing'
  },
  {
    postalCode: '4209',
    city: 'Zinngießing'
  },
  {
    postalCode: '4209',
    city: 'Gallneukirchen'
  },
  {
    postalCode: '4210',
    city: 'Almesberg'
  },
  {
    postalCode: '4210',
    city: 'Gerbersdorf'
  },
  {
    postalCode: '4210',
    city: 'Grasbach'
  },
  {
    postalCode: '4210',
    city: 'Lindach'
  },
  {
    postalCode: '4210',
    city: 'Luegstetten'
  },
  {
    postalCode: '4210',
    city: 'Oberndorf'
  },
  {
    postalCode: '4210',
    city: 'Riedegg'
  },
  {
    postalCode: '4210',
    city: 'Spattendorf'
  },
  {
    postalCode: '4210',
    city: 'Veitsdorf'
  },
  {
    postalCode: '4210',
    city: 'Niederkulm'
  },
  {
    postalCode: '4210',
    city: 'Unterweitrag'
  },
  {
    postalCode: '4210',
    city: 'Gallneukirchen'
  },
  {
    postalCode: '4210',
    city: 'Bergen'
  },
  {
    postalCode: '4210',
    city: 'Gauschitzberg'
  },
  {
    postalCode: '4210',
    city: 'Hattmannsdorf'
  },
  {
    postalCode: '4210',
    city: 'Loibersdorf'
  },
  {
    postalCode: '4210',
    city: 'Radingdorf'
  },
  {
    postalCode: '4210',
    city: 'Reitern'
  },
  {
    postalCode: '4210',
    city: 'Unterweitersdorf'
  },
  {
    postalCode: '4210',
    city: 'Wögern'
  },
  {
    postalCode: '4210',
    city: 'Untervisnitz'
  },
  {
    postalCode: '4211',
    city: 'Aich'
  },
  {
    postalCode: '4211',
    city: 'Alberndorf in der Riedmark'
  },
  {
    postalCode: '4211',
    city: 'Berbersdorf'
  },
  {
    postalCode: '4211',
    city: 'Greifenberg'
  },
  {
    postalCode: '4211',
    city: 'Hadersdorf'
  },
  {
    postalCode: '4211',
    city: 'Heinberg'
  },
  {
    postalCode: '4211',
    city: 'Hirschstein'
  },
  {
    postalCode: '4211',
    city: 'Kelzendorf'
  },
  {
    postalCode: '4211',
    city: 'Klamleiten'
  },
  {
    postalCode: '4211',
    city: 'Kottingersdorf'
  },
  {
    postalCode: '4211',
    city: 'Loitzendorf'
  },
  {
    postalCode: '4211',
    city: 'Luegstetten'
  },
  {
    postalCode: '4211',
    city: 'Matzelsdorf'
  },
  {
    postalCode: '4211',
    city: 'Oberweitersdorf'
  },
  {
    postalCode: '4211',
    city: 'Pröselsdorf'
  },
  {
    postalCode: '4211',
    city: 'Rinzendorf'
  },
  {
    postalCode: '4211',
    city: 'Schallersdorf'
  },
  {
    postalCode: '4211',
    city: 'Schlammersdorf'
  },
  {
    postalCode: '4211',
    city: 'Steinbach'
  },
  {
    postalCode: '4211',
    city: 'Weikersdorf'
  },
  {
    postalCode: '4211',
    city: 'Zeurz'
  },
  {
    postalCode: '4211',
    city: 'Ramersdorf'
  },
  {
    postalCode: '4211',
    city: 'Traidendorf'
  },
  {
    postalCode: '4212',
    city: 'Albingdorf'
  },
  {
    postalCode: '4212',
    city: 'Galgenau'
  },
  {
    postalCode: '4212',
    city: 'Lest'
  },
  {
    postalCode: '4212',
    city: 'Pernau'
  },
  {
    postalCode: '4212',
    city: 'Achleiten'
  },
  {
    postalCode: '4212',
    city: 'Au'
  },
  {
    postalCode: '4212',
    city: 'Kronast'
  },
  {
    postalCode: '4212',
    city: 'Baumgarten'
  },
  {
    postalCode: '4212',
    city: 'Dingdorf'
  },
  {
    postalCode: '4212',
    city: 'Götschka'
  },
  {
    postalCode: '4212',
    city: 'Lamm'
  },
  {
    postalCode: '4212',
    city: 'Matzelsdorf'
  },
  {
    postalCode: '4212',
    city: 'Möhringdorf'
  },
  {
    postalCode: '4212',
    city: 'Neumarkt im Mühlkreis'
  },
  {
    postalCode: '4212',
    city: 'Oberzeiß'
  },
  {
    postalCode: '4212',
    city: 'Pfaffendorf'
  },
  {
    postalCode: '4212',
    city: 'Rudersdorf'
  },
  {
    postalCode: '4212',
    city: 'Sallersdorf'
  },
  {
    postalCode: '4212',
    city: 'Schall'
  },
  {
    postalCode: '4212',
    city: 'Schallersdorf'
  },
  {
    postalCode: '4212',
    city: 'Schiernersdorf'
  },
  {
    postalCode: '4212',
    city: 'Schwandtendorf'
  },
  {
    postalCode: '4212',
    city: 'Steigersdorf'
  },
  {
    postalCode: '4212',
    city: 'Stiftung'
  },
  {
    postalCode: '4212',
    city: 'Traidendorf'
  },
  {
    postalCode: '4212',
    city: 'Trölsberg'
  },
  {
    postalCode: '4212',
    city: 'Trosselsdorf'
  },
  {
    postalCode: '4212',
    city: 'Unterzeiß'
  },
  {
    postalCode: '4212',
    city: 'Willingdorf'
  },
  {
    postalCode: '4212',
    city: 'Zissingdorf'
  },
  {
    postalCode: '4212',
    city: 'Loibersdorf'
  },
  {
    postalCode: '4221',
    city: 'Götzelsdorf'
  },
  {
    postalCode: '4221',
    city: 'Hasenberg'
  },
  {
    postalCode: '4221',
    city: 'Holzwinden'
  },
  {
    postalCode: '4221',
    city: 'Lachstatt'
  },
  {
    postalCode: '4221',
    city: 'Pulgarn'
  },
  {
    postalCode: '4221',
    city: 'Steyregg'
  },
  {
    postalCode: '4222',
    city: 'Weingraben'
  },
  {
    postalCode: '4222',
    city: 'Stacherlsiedlung'
  },
  {
    postalCode: '4222',
    city: 'Frankenberg'
  },
  {
    postalCode: '4222',
    city: 'Gusen'
  },
  {
    postalCode: '4222',
    city: 'Langenstein'
  },
  {
    postalCode: '4222',
    city: 'Abwinden'
  },
  {
    postalCode: '4222',
    city: 'Forst'
  },
  {
    postalCode: '4222',
    city: 'Gröbetsweg'
  },
  {
    postalCode: '4222',
    city: 'Knierübl'
  },
  {
    postalCode: '4222',
    city: 'Luftenberg'
  },
  {
    postalCode: '4222',
    city: 'Pürach'
  },
  {
    postalCode: '4222',
    city: 'Statzing'
  },
  {
    postalCode: '4222',
    city: 'Steining'
  },
  {
    postalCode: '4222',
    city: 'Denneberg'
  },
  {
    postalCode: '4222',
    city: 'Sankt Georgen an der Gusen'
  },
  {
    postalCode: '4222',
    city: 'Schörgendorf'
  },
  {
    postalCode: '4222',
    city: 'Zottmann'
  },
  {
    postalCode: '4223',
    city: 'Neubodendorf'
  },
  {
    postalCode: '4223',
    city: 'Nöbling'
  },
  {
    postalCode: '4223',
    city: 'Weidegut'
  },
  {
    postalCode: '4223',
    city: 'Bodendorf'
  },
  {
    postalCode: '4223',
    city: 'Breitenbruck'
  },
  {
    postalCode: '4223',
    city: 'Katsdorf'
  },
  {
    postalCode: '4223',
    city: 'Lungitz'
  },
  {
    postalCode: '4223',
    city: 'Ruhstetten'
  },
  {
    postalCode: '4223',
    city: 'Standorf'
  },
  {
    postalCode: '4223',
    city: 'Schörgendorf'
  },
  {
    postalCode: '4223',
    city: 'Reitlingberg'
  },
  {
    postalCode: '4224',
    city: 'Grünbichl'
  },
  {
    postalCode: '4224',
    city: 'Haag'
  },
  {
    postalCode: '4224',
    city: 'Klingenwehr'
  },
  {
    postalCode: '4224',
    city: 'Klausmühle'
  },
  {
    postalCode: '4224',
    city: 'Hacklberg'
  },
  {
    postalCode: '4224',
    city: 'Scheiben'
  },
  {
    postalCode: '4224',
    city: 'Schönreith'
  },
  {
    postalCode: '4224',
    city: 'Wolfsegg'
  },
  {
    postalCode: '4224',
    city: 'Schlossberg'
  },
  {
    postalCode: '4224',
    city: 'Schloss Haus'
  },
  {
    postalCode: '4224',
    city: 'Arnberg'
  },
  {
    postalCode: '4224',
    city: 'Friensdorf'
  },
  {
    postalCode: '4224',
    city: 'Obervisnitz'
  },
  {
    postalCode: '4224',
    city: 'Steinpichl'
  },
  {
    postalCode: '4224',
    city: 'Untervisnitz'
  },
  {
    postalCode: '4224',
    city: 'Wartberg ob der Aist'
  },
  {
    postalCode: '4230',
    city: 'Gaisruckdorf'
  },
  {
    postalCode: '4230',
    city: 'Reichenstein'
  },
  {
    postalCode: '4230',
    city: 'Hagenberg im Mühlkreis'
  },
  {
    postalCode: '4230',
    city: 'Aist'
  },
  {
    postalCode: '4230',
    city: 'Burbach'
  },
  {
    postalCode: '4230',
    city: 'Gmeinerhof'
  },
  {
    postalCode: '4230',
    city: 'Greising'
  },
  {
    postalCode: '4230',
    city: 'Greisingberg'
  },
  {
    postalCode: '4230',
    city: 'Halmenberg'
  },
  {
    postalCode: '4230',
    city: 'Kriechmayrdorf'
  },
  {
    postalCode: '4230',
    city: 'Meitschenhof'
  },
  {
    postalCode: '4230',
    city: 'Pregarten'
  },
  {
    postalCode: '4230',
    city: 'Pregartsdorf'
  },
  {
    postalCode: '4230',
    city: 'Selker'
  },
  {
    postalCode: '4230',
    city: 'Wörgersdorf'
  },
  {
    postalCode: '4230',
    city: 'Kranzlgarten'
  },
  {
    postalCode: '4230',
    city: 'Silberbach'
  },
  {
    postalCode: '4230',
    city: 'Grünbichl'
  },
  {
    postalCode: '4230',
    city: 'Hohensteg'
  },
  {
    postalCode: '4230',
    city: 'Mistlberg'
  },
  {
    postalCode: '4230',
    city: 'Zudersdorf'
  },
  {
    postalCode: '4231',
    city: 'Ruhstetten'
  },
  {
    postalCode: '4231',
    city: 'Schönreith'
  },
  {
    postalCode: '4231',
    city: 'Untere Reitling'
  },
  {
    postalCode: '4231',
    city: 'Reitlingberg'
  },
  {
    postalCode: '4231',
    city: 'Türnberg'
  },
  {
    postalCode: '4231',
    city: 'Zeilerberg'
  },
  {
    postalCode: '4231',
    city: 'Doberhagen'
  },
  {
    postalCode: '4231',
    city: 'Altenhaus'
  },
  {
    postalCode: '4231',
    city: 'Im Bichl'
  },
  {
    postalCode: '4231',
    city: 'Wolfsegg'
  },
  {
    postalCode: '4231',
    city: 'Frensdorf'
  },
  {
    postalCode: '4231',
    city: 'Obergaisbach'
  },
  {
    postalCode: '4231',
    city: 'Reitling'
  },
  {
    postalCode: '4231',
    city: 'Steinpichl'
  },
  {
    postalCode: '4231',
    city: 'Untergaisbach'
  },
  {
    postalCode: '4232',
    city: 'Anitzberg'
  },
  {
    postalCode: '4232',
    city: 'Hagenberg im Mühlkreis'
  },
  {
    postalCode: '4232',
    city: 'Mahrersdorf'
  },
  {
    postalCode: '4232',
    city: 'Niederaich'
  },
  {
    postalCode: '4232',
    city: 'Oberaich'
  },
  {
    postalCode: '4232',
    city: 'Penzendorf'
  },
  {
    postalCode: '4232',
    city: 'Schmidsberg'
  },
  {
    postalCode: '4232',
    city: 'Veichter'
  },
  {
    postalCode: '4240',
    city: 'Freistadt'
  },
  {
    postalCode: '4240',
    city: 'Galgenau'
  },
  {
    postalCode: '4240',
    city: 'Trölsberg'
  },
  {
    postalCode: '4240',
    city: 'Lest'
  },
  {
    postalCode: '4240',
    city: 'Gunnersdorf'
  },
  {
    postalCode: '4240',
    city: 'Manzenreith'
  },
  {
    postalCode: '4240',
    city: 'Reickersdorf'
  },
  {
    postalCode: '4240',
    city: 'Walchshof'
  },
  {
    postalCode: '4240',
    city: 'Apfoltern'
  },
  {
    postalCode: '4240',
    city: 'Dreißgen'
  },
  {
    postalCode: '4240',
    city: 'Sonnberg'
  },
  {
    postalCode: '4240',
    city: 'Vierzehn'
  },
  {
    postalCode: '4240',
    city: 'Vierhöf'
  },
  {
    postalCode: '4240',
    city: 'Freudenthal'
  },
  {
    postalCode: '4240',
    city: 'Harruck'
  },
  {
    postalCode: '4240',
    city: 'Lahrndorf'
  },
  {
    postalCode: '4240',
    city: 'Marreith'
  },
  {
    postalCode: '4240',
    city: 'Mitterreith'
  },
  {
    postalCode: '4240',
    city: 'Oberschwandt'
  },
  {
    postalCode: '4240',
    city: 'Sankt Peter'
  },
  {
    postalCode: '4240',
    city: 'Unterschwandt'
  },
  {
    postalCode: '4240',
    city: 'Waldburg'
  },
  {
    postalCode: '4242',
    city: 'Auerbach'
  },
  {
    postalCode: '4242',
    city: 'Berg'
  },
  {
    postalCode: '4242',
    city: 'Gossenreith'
  },
  {
    postalCode: '4242',
    city: 'Guttenbrunn'
  },
  {
    postalCode: '4242',
    city: 'Hirschbach im Mühlkreis'
  },
  {
    postalCode: '4242',
    city: 'Hofreith'
  },
  {
    postalCode: '4242',
    city: 'Kirchberg'
  },
  {
    postalCode: '4242',
    city: 'Oberhirschgraben'
  },
  {
    postalCode: '4242',
    city: 'Pemsedt'
  },
  {
    postalCode: '4242',
    city: 'Thierberg'
  },
  {
    postalCode: '4242',
    city: 'Tischberg'
  },
  {
    postalCode: '4242',
    city: 'Unterhirschgraben'
  },
  {
    postalCode: '4242',
    city: 'Vorwald'
  },
  {
    postalCode: '4251',
    city: 'Eben'
  },
  {
    postalCode: '4251',
    city: 'Größgstötten'
  },
  {
    postalCode: '4251',
    city: 'Gugu'
  },
  {
    postalCode: '4251',
    city: 'Hacklbrunn'
  },
  {
    postalCode: '4251',
    city: 'Hundsberg'
  },
  {
    postalCode: '4251',
    city: 'Königsau'
  },
  {
    postalCode: '4251',
    city: 'Neuhof'
  },
  {
    postalCode: '4251',
    city: 'Pürstling'
  },
  {
    postalCode: '4251',
    city: 'Rindlberg'
  },
  {
    postalCode: '4251',
    city: 'Rothenbachl'
  },
  {
    postalCode: '4251',
    city: 'Sandl'
  },
  {
    postalCode: '4251',
    city: 'Schönberg'
  },
  {
    postalCode: '4251',
    city: 'Steinkreuz'
  },
  {
    postalCode: '4251',
    city: 'Steinwald'
  },
  {
    postalCode: '4251',
    city: 'Tafelberg'
  },
  {
    postalCode: '4251',
    city: 'Viehberg'
  },
  {
    postalCode: '4251',
    city: 'Weinviertl'
  },
  {
    postalCode: '4251',
    city: 'Obermarreith'
  },
  {
    postalCode: '4252',
    city: 'Eibenberg'
  },
  {
    postalCode: '4252',
    city: 'Geierschlag'
  },
  {
    postalCode: '4252',
    city: 'Glashütten'
  },
  {
    postalCode: '4252',
    city: 'Hirschau'
  },
  {
    postalCode: '4252',
    city: 'Kienau'
  },
  {
    postalCode: '4252',
    city: 'Liebenau'
  },
  {
    postalCode: '4252',
    city: 'Liebenstein'
  },
  {
    postalCode: '4252',
    city: 'Maxldorf'
  },
  {
    postalCode: '4252',
    city: 'Monegg'
  },
  {
    postalCode: '4252',
    city: 'Neustift'
  },
  {
    postalCode: '4252',
    city: 'Reitern'
  },
  {
    postalCode: '4252',
    city: 'Schanz'
  },
  {
    postalCode: '4252',
    city: 'Schöneben'
  },
  {
    postalCode: '4252',
    city: 'Windhagmühl'
  },
  {
    postalCode: '4261',
    city: 'Helbetschlag'
  },
  {
    postalCode: '4261',
    city: 'Unterpaßberg'
  },
  {
    postalCode: '4261',
    city: 'Apfoltern'
  },
  {
    postalCode: '4261',
    city: 'Hörschlag'
  },
  {
    postalCode: '4261',
    city: 'Kerschbaum'
  },
  {
    postalCode: '4261',
    city: 'Labach'
  },
  {
    postalCode: '4261',
    city: 'Rainbach im Mühlkreis'
  },
  {
    postalCode: '4261',
    city: 'Stadln'
  },
  {
    postalCode: '4261',
    city: 'Stiftung'
  },
  {
    postalCode: '4261',
    city: 'Summerau'
  },
  {
    postalCode: '4261',
    city: 'Zulissen'
  },
  {
    postalCode: '4262',
    city: 'Pramhöf'
  },
  {
    postalCode: '4262',
    city: 'Edlbruck'
  },
  {
    postalCode: '4262',
    city: 'Eisenhut'
  },
  {
    postalCode: '4262',
    city: 'Hammern'
  },
  {
    postalCode: '4262',
    city: 'Hiltschen'
  },
  {
    postalCode: '4262',
    city: 'Leitmannsdorf'
  },
  {
    postalCode: '4262',
    city: 'Dorf Leopoldschlag'
  },
  {
    postalCode: '4262',
    city: 'Markt Leopoldschlag'
  },
  {
    postalCode: '4262',
    city: 'Mardetschlag'
  },
  {
    postalCode: '4262',
    city: 'Stiegersdorf'
  },
  {
    postalCode: '4262',
    city: 'Wullowitz'
  },
  {
    postalCode: '4263',
    city: 'Plochwald'
  },
  {
    postalCode: '4263',
    city: 'Freiwalddorf'
  },
  {
    postalCode: '4263',
    city: 'Elmberg'
  },
  {
    postalCode: '4263',
    city: 'Mairspindt'
  },
  {
    postalCode: '4263',
    city: 'Obernschlag'
  },
  {
    postalCode: '4263',
    city: 'Oberpaßberg'
  },
  {
    postalCode: '4263',
    city: 'Oberwindhaag'
  },
  {
    postalCode: '4263',
    city: 'Pieberschlag'
  },
  {
    postalCode: '4263',
    city: 'Predetschlag'
  },
  {
    postalCode: '4263',
    city: 'Prendt'
  },
  {
    postalCode: '4263',
    city: 'Riemetschlag'
  },
  {
    postalCode: '4263',
    city: 'Spörbichl'
  },
  {
    postalCode: '4263',
    city: 'Unterwald'
  },
  {
    postalCode: '4263',
    city: 'Windhaag bei Freistadt'
  },
  {
    postalCode: '4264',
    city: 'Grünbach'
  },
  {
    postalCode: '4264',
    city: 'Heinrichschlag'
  },
  {
    postalCode: '4264',
    city: 'Helbetschlag'
  },
  {
    postalCode: '4264',
    city: 'Lichtenau'
  },
  {
    postalCode: '4264',
    city: 'Mitterbach'
  },
  {
    postalCode: '4264',
    city: 'Oberrauchenödt'
  },
  {
    postalCode: '4264',
    city: 'Schlag'
  },
  {
    postalCode: '4264',
    city: 'Unterrauchenödt'
  },
  {
    postalCode: '4271',
    city: 'Etzelsdorf'
  },
  {
    postalCode: '4271',
    city: 'Reickersdorf'
  },
  {
    postalCode: '4271',
    city: 'Unterrauchenödt'
  },
  {
    postalCode: '4271',
    city: 'Amesreith'
  },
  {
    postalCode: '4271',
    city: 'Florenthein'
  },
  {
    postalCode: '4271',
    city: 'Fünfling'
  },
  {
    postalCode: '4271',
    city: 'March'
  },
  {
    postalCode: '4271',
    city: 'Mayrhöfen'
  },
  {
    postalCode: '4271',
    city: 'Neudorf'
  },
  {
    postalCode: '4271',
    city: 'Obermarreith'
  },
  {
    postalCode: '4271',
    city: 'Oberreitern'
  },
  {
    postalCode: '4271',
    city: 'Piberschlag'
  },
  {
    postalCode: '4271',
    city: 'Rosenau'
  },
  {
    postalCode: '4271',
    city: 'Sankt Oswald bei Freistadt'
  },
  {
    postalCode: '4271',
    city: 'Stiftungsberg'
  },
  {
    postalCode: '4271',
    city: 'Untermarreith'
  },
  {
    postalCode: '4271',
    city: 'Wartberg'
  },
  {
    postalCode: '4271',
    city: 'Wippl'
  },
  {
    postalCode: '4271',
    city: 'Witzelsberg'
  },
  {
    postalCode: '4272',
    city: 'Freudenthal'
  },
  {
    postalCode: '4272',
    city: 'Langfirling'
  },
  {
    postalCode: '4272',
    city: 'Rebuledt'
  },
  {
    postalCode: '4272',
    city: 'Eipoldschlag'
  },
  {
    postalCode: '4272',
    city: 'Haid'
  },
  {
    postalCode: '4272',
    city: 'Harrachstal'
  },
  {
    postalCode: '4272',
    city: 'Knaußer'
  },
  {
    postalCode: '4272',
    city: 'Markersdorf'
  },
  {
    postalCode: '4272',
    city: 'Nadelbach'
  },
  {
    postalCode: '4272',
    city: 'Rabenberg'
  },
  {
    postalCode: '4272',
    city: 'Reitern'
  },
  {
    postalCode: '4272',
    city: 'Ritzenedt'
  },
  {
    postalCode: '4272',
    city: 'Saghammer'
  },
  {
    postalCode: '4272',
    city: 'Straßreit'
  },
  {
    postalCode: '4272',
    city: 'Stumberg'
  },
  {
    postalCode: '4272',
    city: 'Waldfeld'
  },
  {
    postalCode: '4272',
    city: 'Weitersfelden'
  },
  {
    postalCode: '4272',
    city: 'Wienau'
  },
  {
    postalCode: '4272',
    city: 'Windgföll'
  },
  {
    postalCode: '4273',
    city: 'Ebenort'
  },
  {
    postalCode: '4273',
    city: 'Kaltenberg'
  },
  {
    postalCode: '4273',
    city: 'Markersreith'
  },
  {
    postalCode: '4273',
    city: 'Nadelberg'
  },
  {
    postalCode: '4273',
    city: 'Pieberbach'
  },
  {
    postalCode: '4273',
    city: 'Silberberg'
  },
  {
    postalCode: '4273',
    city: 'Tischberg'
  },
  {
    postalCode: '4273',
    city: 'Weidenau'
  },
  {
    postalCode: '4273',
    city: 'Aglasberg'
  },
  {
    postalCode: '4273',
    city: 'Dauerbach'
  },
  {
    postalCode: '4273',
    city: 'Enebitschlag'
  },
  {
    postalCode: '4273',
    city: 'Grafenschlag'
  },
  {
    postalCode: '4273',
    city: 'Greinerschlag'
  },
  {
    postalCode: '4273',
    city: 'Hackstock'
  },
  {
    postalCode: '4273',
    city: 'Hinterberg'
  },
  {
    postalCode: '4273',
    city: 'Hinterreith'
  },
  {
    postalCode: '4273',
    city: 'Landshut'
  },
  {
    postalCode: '4273',
    city: 'Mötlas'
  },
  {
    postalCode: '4273',
    city: 'Mötlasberg'
  },
  {
    postalCode: '4273',
    city: 'Neumühl'
  },
  {
    postalCode: '4273',
    city: 'Obermühl'
  },
  {
    postalCode: '4273',
    city: 'Schattau'
  },
  {
    postalCode: '4273',
    city: 'Unterweißenbach'
  },
  {
    postalCode: '4273',
    city: 'Wildberg'
  },
  {
    postalCode: '4273',
    city: 'Windhing'
  },
  {
    postalCode: '4274',
    city: 'Niederhofstetten'
  },
  {
    postalCode: '4274',
    city: 'Oberhofstetten'
  },
  {
    postalCode: '4274',
    city: 'Fürling'
  },
  {
    postalCode: '4274',
    city: 'Hinterhütten'
  },
  {
    postalCode: '4274',
    city: 'Hofing'
  },
  {
    postalCode: '4274',
    city: 'Kaining'
  },
  {
    postalCode: '4274',
    city: 'Kollnedt'
  },
  {
    postalCode: '4274',
    city: 'Niederndorf'
  },
  {
    postalCode: '4274',
    city: 'Oberndorf'
  },
  {
    postalCode: '4274',
    city: 'Pehersdorf'
  },
  {
    postalCode: '4274',
    city: 'Schönau im Mühlkreis'
  },
  {
    postalCode: '4274',
    city: 'Straß'
  },
  {
    postalCode: '4274',
    city: 'Wolfgrub'
  },
  {
    postalCode: '4280',
    city: 'Ebrixedt'
  },
  {
    postalCode: '4280',
    city: 'Haid'
  },
  {
    postalCode: '4280',
    city: 'Harlingsedt'
  },
  {
    postalCode: '4280',
    city: 'Hörzenschlag'
  },
  {
    postalCode: '4280',
    city: 'Kastendorf'
  },
  {
    postalCode: '4280',
    city: 'Königswiesen'
  },
  {
    postalCode: '4280',
    city: 'Mayrhof'
  },
  {
    postalCode: '4280',
    city: 'Mötlasberg'
  },
  {
    postalCode: '4280',
    city: 'Paroxedt'
  },
  {
    postalCode: '4280',
    city: 'Pernedt'
  },
  {
    postalCode: '4280',
    city: 'Salchenedt'
  },
  {
    postalCode: '4280',
    city: 'Schlag'
  },
  {
    postalCode: '4280',
    city: 'Stifting'
  },
  {
    postalCode: '4281',
    city: 'Mönchdorf'
  },
  {
    postalCode: '4281',
    city: 'Mönchwald'
  },
  {
    postalCode: '4281',
    city: 'Schreineredt'
  },
  {
    postalCode: '4281',
    city: 'Staub'
  },
  {
    postalCode: '4281',
    city: 'Eibeck'
  },
  {
    postalCode: '4281',
    city: 'Henndorf'
  },
  {
    postalCode: '4281',
    city: 'Bergerriedl'
  },
  {
    postalCode: '4281',
    city: 'Niederhofstetten'
  },
  {
    postalCode: '4282',
    city: 'Staub'
  },
  {
    postalCode: '4282',
    city: 'Naarntal'
  },
  {
    postalCode: '4282',
    city: 'Bergerriedl'
  },
  {
    postalCode: '4282',
    city: 'Sonnleitn'
  },
  {
    postalCode: '4282',
    city: 'Lindnerberg'
  },
  {
    postalCode: '4282',
    city: 'Kreuzberg'
  },
  {
    postalCode: '4282',
    city: 'Mühltal'
  },
  {
    postalCode: '4282',
    city: 'Meislberg'
  },
  {
    postalCode: '4282',
    city: 'Kleinhöfnerberg'
  },
  {
    postalCode: '4282',
    city: 'Hinterhütten'
  },
  {
    postalCode: '4282',
    city: 'Höfnerberg'
  },
  {
    postalCode: '4282',
    city: 'Niederhofstetten'
  },
  {
    postalCode: '4282',
    city: 'Pierbach'
  },
  {
    postalCode: '4282',
    city: 'Ober Sankt Thomas'
  },
  {
    postalCode: '4283',
    city: 'Aich'
  },
  {
    postalCode: '4283',
    city: 'Barndorf'
  },
  {
    postalCode: '4283',
    city: 'Brawinkl'
  },
  {
    postalCode: '4283',
    city: 'Erdleiten'
  },
  {
    postalCode: '4283',
    city: 'Haselbach'
  },
  {
    postalCode: '4283',
    city: 'Hirtlhof'
  },
  {
    postalCode: '4283',
    city: 'Lanzendorf'
  },
  {
    postalCode: '4283',
    city: 'Maierhof'
  },
  {
    postalCode: '4283',
    city: 'Riegl'
  },
  {
    postalCode: '4283',
    city: 'Weberberg'
  },
  {
    postalCode: '4283',
    city: 'Bad Zell'
  },
  {
    postalCode: '4283',
    city: 'Zellhof'
  },
  {
    postalCode: '4284',
    city: 'Baumgarten'
  },
  {
    postalCode: '4284',
    city: 'Hennberg'
  },
  {
    postalCode: '4284',
    city: 'Kriechbaum'
  },
  {
    postalCode: '4284',
    city: 'Fraundorf'
  },
  {
    postalCode: '4284',
    city: 'Haarland'
  },
  {
    postalCode: '4284',
    city: 'Hinterberg'
  },
  {
    postalCode: '4284',
    city: 'Hohensteg'
  },
  {
    postalCode: '4284',
    city: 'Knollnhof'
  },
  {
    postalCode: '4284',
    city: 'Lugendorf'
  },
  {
    postalCode: '4284',
    city: 'Mistlberg'
  },
  {
    postalCode: '4284',
    city: 'Schedlberg'
  },
  {
    postalCode: '4284',
    city: 'Schmierreith'
  },
  {
    postalCode: '4284',
    city: 'Stranzberg'
  },
  {
    postalCode: '4284',
    city: 'Tragwein'
  },
  {
    postalCode: '4284',
    city: 'Zudersdorf'
  },
  {
    postalCode: '4291',
    city: 'Freidorf'
  },
  {
    postalCode: '4291',
    city: 'Galgenau'
  },
  {
    postalCode: '4291',
    city: 'Miesenberg'
  },
  {
    postalCode: '4291',
    city: 'Edlau'
  },
  {
    postalCode: '4291',
    city: 'Elz'
  },
  {
    postalCode: '4291',
    city: 'Grensberg'
  },
  {
    postalCode: '4291',
    city: 'Grieb'
  },
  {
    postalCode: '4291',
    city: 'Grub'
  },
  {
    postalCode: '4291',
    city: 'Gunnersdorf'
  },
  {
    postalCode: '4291',
    city: 'Kronau'
  },
  {
    postalCode: '4291',
    city: 'Lasberg'
  },
  {
    postalCode: '4291',
    city: 'Paben'
  },
  {
    postalCode: '4291',
    city: 'Pilgersdorf'
  },
  {
    postalCode: '4291',
    city: 'Punkenhof'
  },
  {
    postalCode: '4291',
    city: 'Siegelsdorf'
  },
  {
    postalCode: '4291',
    city: 'Steinböckhof'
  },
  {
    postalCode: '4291',
    city: 'Walchshof'
  },
  {
    postalCode: '4291',
    city: 'Witzelsberg'
  },
  {
    postalCode: '4292',
    city: 'Neustadt'
  },
  {
    postalCode: '4292',
    city: 'Dörfl'
  },
  {
    postalCode: '4292',
    city: 'Freidorf'
  },
  {
    postalCode: '4292',
    city: 'Harterleiten'
  },
  {
    postalCode: '4292',
    city: 'Kefermarkt'
  },
  {
    postalCode: '4292',
    city: 'Lest'
  },
  {
    postalCode: '4292',
    city: 'Miesenberg'
  },
  {
    postalCode: '4292',
    city: 'Pernau'
  },
  {
    postalCode: '4292',
    city: 'Wagrein'
  },
  {
    postalCode: '4292',
    city: 'Weinberg'
  },
  {
    postalCode: '4292',
    city: 'Wittinghof'
  },
  {
    postalCode: '4292',
    city: 'Elz'
  },
  {
    postalCode: '4292',
    city: 'Siegelsdorf'
  },
  {
    postalCode: '4292',
    city: 'Netzberg'
  },
  {
    postalCode: '4293',
    city: 'Feiblmühl'
  },
  {
    postalCode: '4293',
    city: 'Erdmannsdorf'
  },
  {
    postalCode: '4293',
    city: 'Fürling'
  },
  {
    postalCode: '4293',
    city: 'Gutau'
  },
  {
    postalCode: '4293',
    city: 'Guttenbrunn'
  },
  {
    postalCode: '4293',
    city: 'Hundsdorf'
  },
  {
    postalCode: '4293',
    city: 'Lehen'
  },
  {
    postalCode: '4293',
    city: 'March'
  },
  {
    postalCode: '4293',
    city: 'Marreith'
  },
  {
    postalCode: '4293',
    city: 'Neustadt'
  },
  {
    postalCode: '4293',
    city: 'Nußbaum'
  },
  {
    postalCode: '4293',
    city: 'Schallhof'
  },
  {
    postalCode: '4293',
    city: 'Schnabling'
  },
  {
    postalCode: '4293',
    city: 'Schöferhof'
  },
  {
    postalCode: '4293',
    city: 'Stampfendorf'
  },
  {
    postalCode: '4293',
    city: 'Tannbach'
  },
  {
    postalCode: '4293',
    city: 'Prandegg'
  },
  {
    postalCode: '4293',
    city: 'Stiftung'
  },
  {
    postalCode: '4293',
    city: 'Hinterberg'
  },
  {
    postalCode: '4294',
    city: 'Stampfendorf'
  },
  {
    postalCode: '4294',
    city: 'Oberndorf'
  },
  {
    postalCode: '4294',
    city: 'Unterniederndorf'
  },
  {
    postalCode: '4294',
    city: 'Dirnberg'
  },
  {
    postalCode: '4294',
    city: 'Ennsedt'
  },
  {
    postalCode: '4294',
    city: 'Freudenthal'
  },
  {
    postalCode: '4294',
    city: 'Haid'
  },
  {
    postalCode: '4294',
    city: 'Haslach'
  },
  {
    postalCode: '4294',
    city: 'Herzogreith'
  },
  {
    postalCode: '4294',
    city: 'Langfirling'
  },
  {
    postalCode: '4294',
    city: 'Maasch'
  },
  {
    postalCode: '4294',
    city: 'Oberarzing'
  },
  {
    postalCode: '4294',
    city: 'Prandegg'
  },
  {
    postalCode: '4294',
    city: 'Promenedt'
  },
  {
    postalCode: '4294',
    city: 'Rebuledt'
  },
  {
    postalCode: '4294',
    city: 'Rehberg'
  },
  {
    postalCode: '4294',
    city: 'Reith'
  },
  {
    postalCode: '4294',
    city: 'Sankt Leonhard bei Freistadt'
  },
  {
    postalCode: '4294',
    city: 'Schwaighof'
  },
  {
    postalCode: '4294',
    city: 'Stiftung'
  },
  {
    postalCode: '4294',
    city: 'Unterarzing'
  },
  {
    postalCode: '4294',
    city: 'Waltrasedt'
  },
  {
    postalCode: '4294',
    city: 'Wenigfirling'
  },
  {
    postalCode: '4300',
    city: 'Ennsdorf'
  },
  {
    postalCode: '4300',
    city: 'Aigenfließen'
  },
  {
    postalCode: '4300',
    city: 'Rubring'
  },
  {
    postalCode: '4300',
    city: 'Erla'
  },
  {
    postalCode: '4300',
    city: 'Altenhofen'
  },
  {
    postalCode: '4300',
    city: 'Endholz'
  },
  {
    postalCode: '4300',
    city: 'Hofkirchen'
  },
  {
    postalCode: '4300',
    city: 'Rems'
  },
  {
    postalCode: '4300',
    city: 'St. Valentin'
  },
  {
    postalCode: '4300',
    city: 'Thurnsdorf'
  },
  {
    postalCode: '4303',
    city: 'St. Pantaleon'
  },
  {
    postalCode: '4310',
    city: 'Langenstein'
  },
  {
    postalCode: '4310',
    city: 'Albern'
  },
  {
    postalCode: '4310',
    city: 'Bernascheksiedlung'
  },
  {
    postalCode: '4310',
    city: 'Brunngraben'
  },
  {
    postalCode: '4310',
    city: 'Haid'
  },
  {
    postalCode: '4310',
    city: 'Hart'
  },
  {
    postalCode: '4310',
    city: 'Heinrichsbrunn'
  },
  {
    postalCode: '4310',
    city: 'Hinterholz'
  },
  {
    postalCode: '4310',
    city: 'Marbach'
  },
  {
    postalCode: '4310',
    city: 'Mauthausen'
  },
  {
    postalCode: '4310',
    city: 'Oberzirking'
  },
  {
    postalCode: '4310',
    city: 'Reiferdorf'
  },
  {
    postalCode: '4310',
    city: 'Ufer'
  },
  {
    postalCode: '4310',
    city: 'Vormarkt'
  },
  {
    postalCode: '4311',
    city: 'Oberwagram'
  },
  {
    postalCode: '4311',
    city: 'Aisthofen'
  },
  {
    postalCode: '4311',
    city: 'Hartl'
  },
  {
    postalCode: '4311',
    city: 'Niederzirking'
  },
  {
    postalCode: '4311',
    city: 'Poneggen'
  },
  {
    postalCode: '4311',
    city: 'Am Dachsberg'
  },
  {
    postalCode: '4311',
    city: 'Aisting'
  },
  {
    postalCode: '4311',
    city: 'Doppl'
  },
  {
    postalCode: '4311',
    city: 'Aiser'
  },
  {
    postalCode: '4311',
    city: 'Josefstal'
  },
  {
    postalCode: '4311',
    city: 'Lina'
  },
  {
    postalCode: '4311',
    city: 'Schwertberg'
  },
  {
    postalCode: '4311',
    city: 'Unterjosefstal'
  },
  {
    postalCode: '4311',
    city: 'Windegg'
  },
  {
    postalCode: '4311',
    city: 'Winden'
  },
  {
    postalCode: '4311',
    city: 'Haarland'
  },
  {
    postalCode: '4312',
    city: 'Edt'
  },
  {
    postalCode: '4312',
    city: 'Greinsberg'
  },
  {
    postalCode: '4312',
    city: 'Grünau'
  },
  {
    postalCode: '4312',
    city: 'Rothof'
  },
  {
    postalCode: '4312',
    city: 'Schwarzendorf'
  },
  {
    postalCode: '4312',
    city: 'Blindendorf'
  },
  {
    postalCode: '4312',
    city: 'Lungitz'
  },
  {
    postalCode: '4312',
    city: 'Reiser'
  },
  {
    postalCode: '4312',
    city: 'Frankenberg'
  },
  {
    postalCode: '4312',
    city: 'Langenstein'
  },
  {
    postalCode: '4312',
    city: 'Marbach'
  },
  {
    postalCode: '4312',
    city: 'Oberzirking'
  },
  {
    postalCode: '4312',
    city: 'Aistbergthal'
  },
  {
    postalCode: '4312',
    city: 'Altaist'
  },
  {
    postalCode: '4312',
    city: 'Anzendorf'
  },
  {
    postalCode: '4312',
    city: 'Danndorf'
  },
  {
    postalCode: '4312',
    city: 'Hartl'
  },
  {
    postalCode: '4312',
    city: 'Marwach'
  },
  {
    postalCode: '4312',
    city: 'Niederzirking'
  },
  {
    postalCode: '4312',
    city: 'Obenberg'
  },
  {
    postalCode: '4312',
    city: 'Ried in der Riedmark'
  },
  {
    postalCode: '4312',
    city: 'Thal'
  },
  {
    postalCode: '4312',
    city: 'Wachsreith'
  },
  {
    postalCode: '4312',
    city: 'Buchholz'
  },
  {
    postalCode: '4312',
    city: 'Gerersdorf'
  },
  {
    postalCode: '4312',
    city: 'Hochstraß'
  },
  {
    postalCode: '4312',
    city: 'Holzgasse'
  },
  {
    postalCode: '4312',
    city: 'Kollnerberg'
  },
  {
    postalCode: '4312',
    city: 'Reidl'
  },
  {
    postalCode: '4312',
    city: 'Rieddorf'
  },
  {
    postalCode: '4312',
    city: 'Schnellendorf'
  },
  {
    postalCode: '4312',
    city: 'Waging'
  },
  {
    postalCode: '4312',
    city: 'Weigersdorf'
  },
  {
    postalCode: '4312',
    city: 'Wildberg'
  },
  {
    postalCode: '4312',
    city: 'Wimm'
  },
  {
    postalCode: '4312',
    city: 'Zeinersdorf'
  },
  {
    postalCode: '4320',
    city: 'Allerheiligen im Mühlkreis'
  },
  {
    postalCode: '4320',
    city: 'Judenleiten'
  },
  {
    postalCode: '4320',
    city: 'Niederlebing'
  },
  {
    postalCode: '4320',
    city: 'Oberlebing'
  },
  {
    postalCode: '4320',
    city: 'Oberwagram'
  },
  {
    postalCode: '4320',
    city: 'Mitterberg'
  },
  {
    postalCode: '4320',
    city: 'Auhof'
  },
  {
    postalCode: '4320',
    city: 'Dörfl'
  },
  {
    postalCode: '4320',
    city: 'Karlingberg'
  },
  {
    postalCode: '4320',
    city: 'Kickenau'
  },
  {
    postalCode: '4320',
    city: 'Lanzenberg'
  },
  {
    postalCode: '4320',
    city: 'Lehenbrunn'
  },
  {
    postalCode: '4320',
    city: 'Perg'
  },
  {
    postalCode: '4320',
    city: 'Pergkirchen'
  },
  {
    postalCode: '4320',
    city: 'Thurnhof'
  },
  {
    postalCode: '4320',
    city: 'Tobra'
  },
  {
    postalCode: '4320',
    city: 'Weinzierl'
  },
  {
    postalCode: '4320',
    city: 'Zeitling'
  },
  {
    postalCode: '4320',
    city: 'Forndorf'
  },
  {
    postalCode: '4320',
    city: 'Hochtor'
  },
  {
    postalCode: '4320',
    city: 'Kuchlmühle'
  },
  {
    postalCode: '4320',
    city: 'Pragtal'
  },
  {
    postalCode: '4322',
    city: 'Rechberg'
  },
  {
    postalCode: '4322',
    city: 'Altenburg'
  },
  {
    postalCode: '4322',
    city: 'Asching'
  },
  {
    postalCode: '4322',
    city: 'Forndorf'
  },
  {
    postalCode: '4322',
    city: 'Freindorf'
  },
  {
    postalCode: '4322',
    city: 'Hochtor'
  },
  {
    postalCode: '4322',
    city: 'Holzmann'
  },
  {
    postalCode: '4322',
    city: 'Pragtal'
  },
  {
    postalCode: '4322',
    city: 'Windhaag bei Perg'
  },
  {
    postalCode: '4323',
    city: 'Mollnegg'
  },
  {
    postalCode: '4323',
    city: 'Innernstein'
  },
  {
    postalCode: '4323',
    city: 'Danndorf'
  },
  {
    postalCode: '4323',
    city: 'Münzbach'
  },
  {
    postalCode: '4323',
    city: 'Obergaisberg'
  },
  {
    postalCode: '4323',
    city: 'Pilgram'
  },
  {
    postalCode: '4323',
    city: 'Priehetsberg'
  },
  {
    postalCode: '4323',
    city: 'Saxenegg'
  },
  {
    postalCode: '4323',
    city: 'Sulzbach'
  },
  {
    postalCode: '4323',
    city: 'Kemet'
  },
  {
    postalCode: '4324',
    city: 'Hiesbach'
  },
  {
    postalCode: '4324',
    city: 'Holzmann'
  },
  {
    postalCode: '4324',
    city: 'Kürnstein'
  },
  {
    postalCode: '4324',
    city: 'Puchberg'
  },
  {
    postalCode: '4324',
    city: 'Rechberg'
  },
  {
    postalCode: '4324',
    city: 'Spaten'
  },
  {
    postalCode: '4324',
    city: 'Wansch'
  },
  {
    postalCode: '4324',
    city: 'Windischhof'
  },
  {
    postalCode: '4324',
    city: 'Winkl'
  },
  {
    postalCode: '4331',
    city: 'Baumgarten'
  },
  {
    postalCode: '4331',
    city: 'Dirnwagram'
  },
  {
    postalCode: '4331',
    city: 'Holzleiten'
  },
  {
    postalCode: '4331',
    city: 'Laab'
  },
  {
    postalCode: '4331',
    city: 'Naarn'
  },
  {
    postalCode: '4331',
    city: 'Neuhof'
  },
  {
    postalCode: '4331',
    city: 'Pratztrum'
  },
  {
    postalCode: '4331',
    city: 'Ruprechtshofen'
  },
  {
    postalCode: '4331',
    city: 'Schönau'
  },
  {
    postalCode: '4331',
    city: 'Staffling'
  },
  {
    postalCode: '4331',
    city: 'Starzing'
  },
  {
    postalCode: '4331',
    city: 'Straß'
  },
  {
    postalCode: '4331',
    city: 'Wimm'
  },
  {
    postalCode: '4332',
    city: 'Aist'
  },
  {
    postalCode: '4332',
    city: 'Au an der Donau'
  },
  {
    postalCode: '4332',
    city: 'Naarn'
  },
  {
    postalCode: '4332',
    city: 'Oberwagram'
  },
  {
    postalCode: '4332',
    city: 'Sebern'
  },
  {
    postalCode: '4341',
    city: 'Arbing'
  },
  {
    postalCode: '4341',
    city: 'Frühstorf'
  },
  {
    postalCode: '4341',
    city: 'Groißing'
  },
  {
    postalCode: '4341',
    city: 'Hummelberg'
  },
  {
    postalCode: '4341',
    city: 'Puchberg im Machland'
  },
  {
    postalCode: '4341',
    city: 'Roisenberg'
  },
  {
    postalCode: '4342',
    city: 'Hehenberg'
  },
  {
    postalCode: '4342',
    city: 'Amesbach'
  },
  {
    postalCode: '4342',
    city: 'Au'
  },
  {
    postalCode: '4342',
    city: 'Baumgartenberg'
  },
  {
    postalCode: '4342',
    city: 'Deiming'
  },
  {
    postalCode: '4342',
    city: 'Kolbing'
  },
  {
    postalCode: '4342',
    city: 'Kühofen'
  },
  {
    postalCode: '4342',
    city: 'Lehen'
  },
  {
    postalCode: '4342',
    city: 'Mettensdorf'
  },
  {
    postalCode: '4342',
    city: 'Obergassolding'
  },
  {
    postalCode: '4342',
    city: 'Pitzing'
  },
  {
    postalCode: '4342',
    city: 'Mühlberg'
  },
  {
    postalCode: '4342',
    city: 'Schneckenreitstal'
  },
  {
    postalCode: '4342',
    city: 'Steindl'
  },
  {
    postalCode: '4342',
    city: 'Untergassolding'
  },
  {
    postalCode: '4342',
    city: 'Hochfeld'
  },
  {
    postalCode: '4342',
    city: 'Obergaisberg'
  },
  {
    postalCode: '4342',
    city: 'Froschau'
  },
  {
    postalCode: '4343',
    city: 'Am Bühel'
  },
  {
    postalCode: '4343',
    city: 'Heinz Lettner-Siedlung'
  },
  {
    postalCode: '4343',
    city: 'Gang'
  },
  {
    postalCode: '4343',
    city: 'Haid'
  },
  {
    postalCode: '4343',
    city: 'Hart'
  },
  {
    postalCode: '4343',
    city: 'Hörstorf'
  },
  {
    postalCode: '4343',
    city: 'Hofstetten'
  },
  {
    postalCode: '4343',
    city: 'Hütting'
  },
  {
    postalCode: '4343',
    city: 'Inzing'
  },
  {
    postalCode: '4343',
    city: 'Kaindlau'
  },
  {
    postalCode: '4343',
    city: 'Kirchstetten'
  },
  {
    postalCode: '4343',
    city: 'Labing'
  },
  {
    postalCode: '4343',
    city: 'Langacker'
  },
  {
    postalCode: '4343',
    city: 'Lehen'
  },
  {
    postalCode: '4343',
    city: 'Loa'
  },
  {
    postalCode: '4343',
    city: 'Mitterkirchen'
  },
  {
    postalCode: '4343',
    city: 'Wagra'
  },
  {
    postalCode: '4343',
    city: 'Weisching'
  },
  {
    postalCode: '4343',
    city: 'Wörth'
  },
  {
    postalCode: '4343',
    city: 'Neu Hütting'
  },
  {
    postalCode: '4351',
    city: 'Au'
  },
  {
    postalCode: '4351',
    city: 'Dornach'
  },
  {
    postalCode: '4351',
    city: 'Eizendorf'
  },
  {
    postalCode: '4351',
    city: 'Hofkirchen'
  },
  {
    postalCode: '4351',
    city: 'Knappetsberg'
  },
  {
    postalCode: '4351',
    city: 'Letten'
  },
  {
    postalCode: '4351',
    city: 'Oberbergen'
  },
  {
    postalCode: '4351',
    city: 'Patzenhof'
  },
  {
    postalCode: '4351',
    city: 'Reitberg'
  },
  {
    postalCode: '4351',
    city: 'Saxen'
  },
  {
    postalCode: '4351',
    city: 'Saxendorf'
  },
  {
    postalCode: '4351',
    city: 'Wetzelsdorf'
  },
  {
    postalCode: '4351',
    city: 'Eizenau'
  },
  {
    postalCode: '4351',
    city: 'Gewerbepark'
  },
  {
    postalCode: '4351',
    city: 'Solarpark'
  },
  {
    postalCode: '4352',
    city: 'Innernstein'
  },
  {
    postalCode: '4352',
    city: 'Oberkalmberg'
  },
  {
    postalCode: '4352',
    city: 'Untergaisberg'
  },
  {
    postalCode: '4352',
    city: 'Achatzberg'
  },
  {
    postalCode: '4352',
    city: 'Gauning'
  },
  {
    postalCode: '4352',
    city: 'Klam'
  },
  {
    postalCode: '4352',
    city: 'Letten'
  },
  {
    postalCode: '4352',
    city: 'Linden'
  },
  {
    postalCode: '4352',
    city: 'Niederkalmberg'
  },
  {
    postalCode: '4352',
    city: 'Oberhörnbach'
  },
  {
    postalCode: '4352',
    city: 'Sperken'
  },
  {
    postalCode: '4352',
    city: 'Unterhörnbach'
  },
  {
    postalCode: '4352',
    city: 'Obergaisberg'
  },
  {
    postalCode: '4360',
    city: 'Panholz'
  },
  {
    postalCode: '4360',
    city: 'Schönfichten'
  },
  {
    postalCode: '4360',
    city: 'Würzenberg'
  },
  {
    postalCode: '4360',
    city: 'Dornach'
  },
  {
    postalCode: '4360',
    city: 'Grein'
  },
  {
    postalCode: '4360',
    city: 'Greinburg'
  },
  {
    postalCode: '4360',
    city: 'Herdmann'
  },
  {
    postalCode: '4360',
    city: 'Lehen'
  },
  {
    postalCode: '4360',
    city: 'Lettental'
  },
  {
    postalCode: '4360',
    city: 'Oberbergen'
  },
  {
    postalCode: '4360',
    city: 'Ufer'
  },
  {
    postalCode: '4360',
    city: 'Dörfl'
  },
  {
    postalCode: '4362',
    city: 'Klaus'
  },
  {
    postalCode: '4362',
    city: 'Kollroßdorf'
  },
  {
    postalCode: '4362',
    city: 'Bad Kreuzen'
  },
  {
    postalCode: '4362',
    city: 'Lehen'
  },
  {
    postalCode: '4362',
    city: 'Mitterdörfl'
  },
  {
    postalCode: '4362',
    city: 'Neuaigen'
  },
  {
    postalCode: '4362',
    city: 'Oberdörfl'
  },
  {
    postalCode: '4362',
    city: 'Obereisendorf'
  },
  {
    postalCode: '4362',
    city: 'Oberkalmberg'
  },
  {
    postalCode: '4362',
    city: 'Schönfichten'
  },
  {
    postalCode: '4362',
    city: 'Thomastal'
  },
  {
    postalCode: '4362',
    city: 'Unterdörfl'
  },
  {
    postalCode: '4362',
    city: 'Wetzelstein'
  },
  {
    postalCode: '4362',
    city: 'Würzenberg'
  },
  {
    postalCode: '4362',
    city: 'Kühweid'
  },
  {
    postalCode: '4362',
    city: 'Thomasreit'
  },
  {
    postalCode: '4363',
    city: 'Markt-Süd'
  },
  {
    postalCode: '4363',
    city: 'Henndorf'
  },
  {
    postalCode: '4363',
    city: 'Mitter-Pabneukirchen'
  },
  {
    postalCode: '4363',
    city: 'Neudorf'
  },
  {
    postalCode: '4363',
    city: 'Nieder-Schreineredt'
  },
  {
    postalCode: '4363',
    city: 'Ober-Eisendorf'
  },
  {
    postalCode: '4363',
    city: 'Ober-Pabneukirchen'
  },
  {
    postalCode: '4363',
    city: 'Pabneukirchen'
  },
  {
    postalCode: '4363',
    city: 'Riedersdorf'
  },
  {
    postalCode: '4363',
    city: 'Schreineredt'
  },
  {
    postalCode: '4363',
    city: 'Thomastal'
  },
  {
    postalCode: '4363',
    city: 'Unter-Eisendorf'
  },
  {
    postalCode: '4363',
    city: 'Unter-Pabneukirchen'
  },
  {
    postalCode: '4363',
    city: 'Wetzelsberg'
  },
  {
    postalCode: '4363',
    city: 'Sonnleitn'
  },
  {
    postalCode: '4363',
    city: 'Untermaseldorf'
  },
  {
    postalCode: '4364',
    city: 'Großmaseldorf'
  },
  {
    postalCode: '4364',
    city: 'Kleinmaseldorf'
  },
  {
    postalCode: '4364',
    city: 'Mitter Sankt Thomas'
  },
  {
    postalCode: '4364',
    city: 'Ober Sankt Thomas'
  },
  {
    postalCode: '4364',
    city: 'Thomasreit'
  },
  {
    postalCode: '4364',
    city: 'Untermaseldorf'
  },
  {
    postalCode: '4364',
    city: 'Unter Sankt Thomas'
  },
  {
    postalCode: '4371',
    city: 'Dimbach'
  },
  {
    postalCode: '4371',
    city: 'Dimbachreith'
  },
  {
    postalCode: '4371',
    city: 'Gassen'
  },
  {
    postalCode: '4371',
    city: 'Großerlau'
  },
  {
    postalCode: '4371',
    city: 'Hornberg'
  },
  {
    postalCode: '4371',
    city: 'Kleinerlau'
  },
  {
    postalCode: '4371',
    city: 'Vorderdimbach'
  },
  {
    postalCode: '4372',
    city: 'Kronberg'
  },
  {
    postalCode: '4372',
    city: 'Marchstein'
  },
  {
    postalCode: '4372',
    city: 'Henndorf'
  },
  {
    postalCode: '4372',
    city: 'Unter-Sankt Georgen'
  },
  {
    postalCode: '4372',
    city: 'Ebenedt'
  },
  {
    postalCode: '4372',
    city: 'Haruckstein'
  },
  {
    postalCode: '4372',
    city: 'Linden'
  },
  {
    postalCode: '4372',
    city: 'Ober Sankt Georgen'
  },
  {
    postalCode: '4372',
    city: 'Ottenschlag'
  },
  {
    postalCode: '4372',
    city: 'Unter Sankt Georgen'
  },
  {
    postalCode: '4381',
    city: 'Grein'
  },
  {
    postalCode: '4381',
    city: 'Achleiten'
  },
  {
    postalCode: '4381',
    city: 'Moosbach'
  },
  {
    postalCode: '4381',
    city: 'Sankt Nikola an der Donau'
  },
  {
    postalCode: '4381',
    city: 'Sattl'
  },
  {
    postalCode: '4381',
    city: 'Struden'
  },
  {
    postalCode: '4382',
    city: 'Hirschenau'
  },
  {
    postalCode: '4382',
    city: 'Sarmingstein'
  },
  {
    postalCode: '4382',
    city: 'Sattl'
  },
  {
    postalCode: '4382',
    city: 'Gloxwald'
  },
  {
    postalCode: '4391',
    city: 'Dendlreith'
  },
  {
    postalCode: '4391',
    city: 'Dörfl'
  },
  {
    postalCode: '4391',
    city: 'Ettenberg'
  },
  {
    postalCode: '4391',
    city: 'Gloxwald'
  },
  {
    postalCode: '4391',
    city: 'Handberg'
  },
  {
    postalCode: '4391',
    city: 'Sattlgai'
  },
  {
    postalCode: '4391',
    city: 'Schloßberg'
  },
  {
    postalCode: '4391',
    city: 'Waldhausen im Strudengau'
  },
  {
    postalCode: '4392',
    city: 'Forstamt'
  },
  {
    postalCode: '4392',
    city: 'Wimbergeramt'
  },
  {
    postalCode: '4392',
    city: 'Dendlreith'
  },
  {
    postalCode: '4400',
    city: 'Garsten Nord'
  },
  {
    postalCode: '4400',
    city: 'Christkindl'
  },
  {
    postalCode: '4400',
    city: 'Garsten'
  },
  {
    postalCode: '4400',
    city: 'Pergern'
  },
  {
    postalCode: '4400',
    city: 'Rosenegg'
  },
  {
    postalCode: '4400',
    city: 'Saaß'
  },
  {
    postalCode: '4400',
    city: 'Sarning'
  },
  {
    postalCode: '4400',
    city: 'Tinsting'
  },
  {
    postalCode: '4400',
    city: 'Gmain'
  },
  {
    postalCode: '4400',
    city: 'Kleinraming'
  },
  {
    postalCode: '4400',
    city: 'Sankt Ulrich bei Steyr'
  },
  {
    postalCode: '4400',
    city: 'Unterwald'
  },
  {
    postalCode: '4400',
    city: 'Steyr'
  },
  {
    postalCode: '4407',
    city: 'Dietach'
  },
  {
    postalCode: '4407',
    city: 'Dietachdorf'
  },
  {
    postalCode: '4407',
    city: 'Stadlkirchen'
  },
  {
    postalCode: '4407',
    city: 'Thann'
  },
  {
    postalCode: '4407',
    city: 'Winkling'
  },
  {
    postalCode: '4407',
    city: 'Steyr'
  },
  {
    postalCode: '4421',
    city: 'Aschach an der Steyr'
  },
  {
    postalCode: '4421',
    city: 'Haagen'
  },
  {
    postalCode: '4421',
    city: 'Mitteregg'
  },
  {
    postalCode: '4421',
    city: 'Saaß'
  },
  {
    postalCode: '4431',
    city: 'Brunnhof'
  },
  {
    postalCode: '4431',
    city: 'Dorf an der Enns'
  },
  {
    postalCode: '4431',
    city: 'Haidershofen'
  },
  {
    postalCode: '4431',
    city: 'Tröstlberg'
  },
  {
    postalCode: '4431',
    city: 'Vestenthal'
  },
  {
    postalCode: '4432',
    city: 'Aigenfließen'
  },
  {
    postalCode: '4432',
    city: 'Rubring'
  },
  {
    postalCode: '4432',
    city: 'Brunnhof'
  },
  {
    postalCode: '4441',
    city: 'Badhof'
  },
  {
    postalCode: '4441',
    city: 'Penz'
  },
  {
    postalCode: '4441',
    city: 'Ramingdorf'
  },
  {
    postalCode: '4441',
    city: 'Wanzenöd'
  },
  {
    postalCode: '4441',
    city: 'Sträußl'
  },
  {
    postalCode: '4441',
    city: 'Tröstlberg'
  },
  {
    postalCode: '4442',
    city: 'Blumau'
  },
  {
    postalCode: '4442',
    city: 'Ebersegg'
  },
  {
    postalCode: '4442',
    city: 'Gmain'
  },
  {
    postalCode: '4442',
    city: 'Kohlergraben'
  },
  {
    postalCode: '4442',
    city: 'Kleinraming'
  },
  {
    postalCode: '4442',
    city: 'Unterwald'
  },
  {
    postalCode: '4442',
    city: 'Kürnberg'
  },
  {
    postalCode: '4443',
    city: 'Neustiftgraben'
  },
  {
    postalCode: '4443',
    city: 'Blumau'
  },
  {
    postalCode: '4443',
    city: 'Buchschachen'
  },
  {
    postalCode: '4443',
    city: 'Dörfl'
  },
  {
    postalCode: '4443',
    city: 'Grub'
  },
  {
    postalCode: '4443',
    city: 'Hofberg'
  },
  {
    postalCode: '4443',
    city: 'Neustift'
  },
  {
    postalCode: '4443',
    city: 'Platten'
  },
  {
    postalCode: '4443',
    city: 'Ebersegg'
  },
  {
    postalCode: '4443',
    city: 'Hohenreith'
  },
  {
    postalCode: '4451',
    city: 'Garsten Nord'
  },
  {
    postalCode: '4451',
    city: 'Buchholz'
  },
  {
    postalCode: '4451',
    city: 'Garsten'
  },
  {
    postalCode: '4451',
    city: 'Kraxental'
  },
  {
    postalCode: '4451',
    city: 'Lahrndorf'
  },
  {
    postalCode: '4451',
    city: 'Mühlbach'
  },
  {
    postalCode: '4451',
    city: 'Mühlbachgraben'
  },
  {
    postalCode: '4451',
    city: 'Oberdambach'
  },
  {
    postalCode: '4451',
    city: 'Pesendorf'
  },
  {
    postalCode: '4451',
    city: 'Saaß'
  },
  {
    postalCode: '4451',
    city: 'Sand'
  },
  {
    postalCode: '4451',
    city: 'Sarning'
  },
  {
    postalCode: '4451',
    city: 'Sonnberg'
  },
  {
    postalCode: '4451',
    city: 'Unterdambach'
  },
  {
    postalCode: '4451',
    city: 'Unterwald'
  },
  {
    postalCode: '4451',
    city: 'Steyr'
  },
  {
    postalCode: '4451',
    city: 'Ternberg'
  },
  {
    postalCode: '4452',
    city: 'Ternberg'
  },
  {
    postalCode: '4453',
    city: 'Ternberg'
  },
  {
    postalCode: '4460',
    city: 'Laussa'
  },
  {
    postalCode: '4460',
    city: 'Losenstein'
  },
  {
    postalCode: '4461',
    city: 'Oberdambach'
  },
  {
    postalCode: '4461',
    city: 'Unterlaussa'
  },
  {
    postalCode: '4461',
    city: 'Laussa'
  },
  {
    postalCode: '4462',
    city: 'Arzberg'
  },
  {
    postalCode: '4462',
    city: 'Reichraming'
  },
  {
    postalCode: '4463',
    city: 'Brunnbach'
  },
  {
    postalCode: '4463',
    city: 'Großraming'
  },
  {
    postalCode: '4463',
    city: 'Hintstein'
  },
  {
    postalCode: '4463',
    city: 'Lumplgraben'
  },
  {
    postalCode: '4463',
    city: 'Neustiftgraben'
  },
  {
    postalCode: '4463',
    city: 'Oberplaißa'
  },
  {
    postalCode: '4463',
    city: 'Pechgraben'
  },
  {
    postalCode: '4463',
    city: 'Rodelsbach'
  },
  {
    postalCode: '4463',
    city: 'Laussa'
  },
  {
    postalCode: '4464',
    city: 'Kleinreifling'
  },
  {
    postalCode: '4464',
    city: 'Nach der Enns'
  },
  {
    postalCode: '4470',
    city: 'Einsiedl'
  },
  {
    postalCode: '4470',
    city: 'Enghagen'
  },
  {
    postalCode: '4470',
    city: 'Enghagen am Tabor'
  },
  {
    postalCode: '4470',
    city: 'Enns'
  },
  {
    postalCode: '4470',
    city: 'Ental'
  },
  {
    postalCode: '4470',
    city: 'Erlengraben'
  },
  {
    postalCode: '4470',
    city: 'Hiesendorf'
  },
  {
    postalCode: '4470',
    city: 'Kottingrat'
  },
  {
    postalCode: '4470',
    city: 'Kristein'
  },
  {
    postalCode: '4470',
    city: 'Kronau'
  },
  {
    postalCode: '4470',
    city: 'Lorch'
  },
  {
    postalCode: '4470',
    city: 'Moos'
  },
  {
    postalCode: '4470',
    city: 'Rabenberg'
  },
  {
    postalCode: '4470',
    city: 'Volkersdorf'
  },
  {
    postalCode: '4470',
    city: 'Schieferegg'
  },
  {
    postalCode: '4481',
    city: 'Asten'
  },
  {
    postalCode: '4481',
    city: 'Fisching'
  },
  {
    postalCode: '4481',
    city: 'Ipfdorf'
  },
  {
    postalCode: '4481',
    city: 'Raffelstetten'
  },
  {
    postalCode: '4481',
    city: 'Erlengraben'
  },
  {
    postalCode: '4481',
    city: 'Abwinden'
  },
  {
    postalCode: '4482',
    city: 'Ennsdorf'
  },
  {
    postalCode: '4482',
    city: 'St. Pantaleon'
  },
  {
    postalCode: '4482',
    city: 'Thurnsdorf'
  },
  {
    postalCode: '4483',
    city: 'Angersberg'
  },
  {
    postalCode: '4483',
    city: 'Firsching'
  },
  {
    postalCode: '4483',
    city: 'Franzberg'
  },
  {
    postalCode: '4483',
    city: 'Hargelsberg'
  },
  {
    postalCode: '4483',
    city: 'Hart'
  },
  {
    postalCode: '4483',
    city: 'Hausmanning'
  },
  {
    postalCode: '4483',
    city: 'Penking'
  },
  {
    postalCode: '4483',
    city: 'Pirchhorn'
  },
  {
    postalCode: '4483',
    city: 'Sieding'
  },
  {
    postalCode: '4483',
    city: 'Thann'
  },
  {
    postalCode: '4483',
    city: 'Schieferegg'
  },
  {
    postalCode: '4484',
    city: 'Thann'
  },
  {
    postalCode: '4484',
    city: 'Hart'
  },
  {
    postalCode: '4484',
    city: 'Dörfling'
  },
  {
    postalCode: '4484',
    city: 'Kronstorf'
  },
  {
    postalCode: '4484',
    city: 'Kronstorfberg'
  },
  {
    postalCode: '4484',
    city: 'Plaik'
  },
  {
    postalCode: '4484',
    city: 'Pühring'
  },
  {
    postalCode: '4484',
    city: 'Schieferegg'
  },
  {
    postalCode: '4484',
    city: 'Schmieding'
  },
  {
    postalCode: '4484',
    city: 'Stallbach'
  },
  {
    postalCode: '4484',
    city: 'Teufelsgraben'
  },
  {
    postalCode: '4484',
    city: 'Thaling'
  },
  {
    postalCode: '4484',
    city: 'Unterhaus'
  },
  {
    postalCode: '4484',
    city: 'Winkling'
  },
  {
    postalCode: '4490',
    city: 'Bruck bei Hausleiten'
  },
  {
    postalCode: '4490',
    city: 'Bruck bei Tödling'
  },
  {
    postalCode: '4490',
    city: 'Enzing'
  },
  {
    postalCode: '4490',
    city: 'Fernbach'
  },
  {
    postalCode: '4490',
    city: 'Gemering'
  },
  {
    postalCode: '4490',
    city: 'Hausleiten'
  },
  {
    postalCode: '4490',
    city: 'Hohenbrunn'
  },
  {
    postalCode: '4490',
    city: 'Sankt Florian'
  },
  {
    postalCode: '4490',
    city: 'Mickstetten'
  },
  {
    postalCode: '4490',
    city: 'Niederfraunleiten'
  },
  {
    postalCode: '4490',
    city: 'Oberfraunleiten'
  },
  {
    postalCode: '4490',
    city: 'Oberndorf'
  },
  {
    postalCode: '4490',
    city: 'Oberweidlham'
  },
  {
    postalCode: '4490',
    city: 'Ölkam'
  },
  {
    postalCode: '4490',
    city: 'Rohrbach'
  },
  {
    postalCode: '4490',
    city: 'Samesleiten'
  },
  {
    postalCode: '4490',
    city: 'Taunleiten'
  },
  {
    postalCode: '4490',
    city: 'Tillysburg'
  },
  {
    postalCode: '4490',
    city: 'Tödling'
  },
  {
    postalCode: '4490',
    city: 'Unterweidlham'
  },
  {
    postalCode: '4490',
    city: 'Weilling'
  },
  {
    postalCode: '4491',
    city: 'Kiebach'
  },
  {
    postalCode: '4491',
    city: 'Dörfl'
  },
  {
    postalCode: '4491',
    city: 'Grünbrunn'
  },
  {
    postalCode: '4491',
    city: 'Niederneukirchen'
  },
  {
    postalCode: '4491',
    city: 'Obereglsee'
  },
  {
    postalCode: '4491',
    city: 'Ruprechtshofen'
  },
  {
    postalCode: '4491',
    city: 'Steggraben'
  },
  {
    postalCode: '4491',
    city: 'Untereglsee'
  },
  {
    postalCode: '4491',
    city: 'Weichstetten'
  },
  {
    postalCode: '4492',
    city: 'Harmannsdorf'
  },
  {
    postalCode: '4492',
    city: 'Hofkirchen im Traunkreis'
  },
  {
    postalCode: '4492',
    city: 'Kiebach'
  },
  {
    postalCode: '4492',
    city: 'Rappersdorf'
  },
  {
    postalCode: '4492',
    city: 'Lanzenberg'
  },
  {
    postalCode: '4492',
    city: 'Maria Laah'
  },
  {
    postalCode: '4493',
    city: 'Goldberg'
  },
  {
    postalCode: '4493',
    city: 'Judendorf'
  },
  {
    postalCode: '4493',
    city: 'Kroisbach'
  },
  {
    postalCode: '4493',
    city: 'Losensteinleiten'
  },
  {
    postalCode: '4493',
    city: 'Maria Laah'
  },
  {
    postalCode: '4493',
    city: 'Oberwolfern'
  },
  {
    postalCode: '4493',
    city: 'Schwarzenthal'
  },
  {
    postalCode: '4493',
    city: 'Schwödiau'
  },
  {
    postalCode: '4493',
    city: 'Wolfern'
  },
  {
    postalCode: '4493',
    city: 'Wickendorf'
  },
  {
    postalCode: '4501',
    city: 'Dambach'
  },
  {
    postalCode: '4501',
    city: 'Freiling'
  },
  {
    postalCode: '4501',
    city: 'Gries'
  },
  {
    postalCode: '4501',
    city: 'Guglberg'
  },
  {
    postalCode: '4501',
    city: 'Julianaberg'
  },
  {
    postalCode: '4501',
    city: 'Lining'
  },
  {
    postalCode: '4501',
    city: 'Neuhofen an der Krems'
  },
  {
    postalCode: '4501',
    city: 'Weißenberg'
  },
  {
    postalCode: '4502',
    city: 'Grub'
  },
  {
    postalCode: '4502',
    city: 'Kimmersdorf'
  },
  {
    postalCode: '4502',
    city: 'Kurzenkirchen'
  },
  {
    postalCode: '4502',
    city: 'Niederschöfring'
  },
  {
    postalCode: '4502',
    city: 'Nöstlbach'
  },
  {
    postalCode: '4502',
    city: 'Oberndorf'
  },
  {
    postalCode: '4502',
    city: 'Oberschöfring'
  },
  {
    postalCode: '4502',
    city: 'Pachersdorf'
  },
  {
    postalCode: '4502',
    city: 'Pichlwang'
  },
  {
    postalCode: '4502',
    city: 'Sankt Marien'
  },
  {
    postalCode: '4502',
    city: 'Stein'
  },
  {
    postalCode: '4502',
    city: 'Thal'
  },
  {
    postalCode: '4502',
    city: 'Tiestling'
  },
  {
    postalCode: '4502',
    city: 'Weichstetten'
  },
  {
    postalCode: '4511',
    city: 'Allhaming'
  },
  {
    postalCode: '4511',
    city: 'Laimgräben'
  },
  {
    postalCode: '4511',
    city: 'Lindach'
  },
  {
    postalCode: '4511',
    city: 'Sipbach'
  },
  {
    postalCode: '4511',
    city: 'Guglberg'
  },
  {
    postalCode: '4521',
    city: 'Oberndorf'
  },
  {
    postalCode: '4521',
    city: 'Pichlwang'
  },
  {
    postalCode: '4521',
    city: 'Droißendorf'
  },
  {
    postalCode: '4521',
    city: 'Goldberg'
  },
  {
    postalCode: '4521',
    city: 'Hilbern'
  },
  {
    postalCode: '4521',
    city: 'Matzelsdorf'
  },
  {
    postalCode: '4521',
    city: 'Schiedlberg'
  },
  {
    postalCode: '4521',
    city: 'Thanstetten'
  },
  {
    postalCode: '4522',
    city: 'Bad Hall'
  },
  {
    postalCode: '4522',
    city: 'Großmengersdorf'
  },
  {
    postalCode: '4522',
    city: 'Neuzeug'
  },
  {
    postalCode: '4522',
    city: 'Droißendorf'
  },
  {
    postalCode: '4522',
    city: 'Matzelsdorf'
  },
  {
    postalCode: '4522',
    city: 'Thanstetten'
  },
  {
    postalCode: '4522',
    city: 'Gründberg'
  },
  {
    postalCode: '4522',
    city: 'Hilbern'
  },
  {
    postalCode: '4522',
    city: 'Niederbrunnern'
  },
  {
    postalCode: '4522',
    city: 'Oberbrunnern'
  },
  {
    postalCode: '4522',
    city: 'Pichlern'
  },
  {
    postalCode: '4522',
    city: 'Sierning'
  },
  {
    postalCode: '4522',
    city: 'Pesendorf'
  },
  {
    postalCode: '4523',
    city: 'Aschach an der Steyr'
  },
  {
    postalCode: '4523',
    city: 'Schwaming'
  },
  {
    postalCode: '4523',
    city: 'Neuzeug'
  },
  {
    postalCode: '4523',
    city: 'Gründberg'
  },
  {
    postalCode: '4523',
    city: 'Pichlern'
  },
  {
    postalCode: '4523',
    city: 'Sierning'
  },
  {
    postalCode: '4523',
    city: 'Sierninghofen'
  },
  {
    postalCode: '4531',
    city: 'Burg'
  },
  {
    postalCode: '4531',
    city: 'Gerersdorf'
  },
  {
    postalCode: '4531',
    city: 'Halbarting'
  },
  {
    postalCode: '4531',
    city: 'Kematen an der Krems'
  },
  {
    postalCode: '4531',
    city: 'Kiesenberg'
  },
  {
    postalCode: '4531',
    city: 'Rath'
  },
  {
    postalCode: '4531',
    city: 'Schachen'
  },
  {
    postalCode: '4531',
    city: 'Fischen'
  },
  {
    postalCode: '4531',
    city: 'Guglberg'
  },
  {
    postalCode: '4531',
    city: 'Winden'
  },
  {
    postalCode: '4531',
    city: 'Brandstatt'
  },
  {
    postalCode: '4531',
    city: 'Hilbern'
  },
  {
    postalCode: '4532',
    city: 'Achleiten'
  },
  {
    postalCode: '4532',
    city: 'Halbarting'
  },
  {
    postalCode: '4532',
    city: 'Kremsmünster'
  },
  {
    postalCode: '4532',
    city: 'Fierling'
  },
  {
    postalCode: '4532',
    city: 'Furtberg'
  },
  {
    postalCode: '4532',
    city: 'Haselberg'
  },
  {
    postalCode: '4532',
    city: 'Krottendorf'
  },
  {
    postalCode: '4532',
    city: 'Oberrohr'
  },
  {
    postalCode: '4532',
    city: 'Rohr im Kremstal'
  },
  {
    postalCode: '4533',
    city: 'Brandstatt'
  },
  {
    postalCode: '4533',
    city: 'Pellndorf'
  },
  {
    postalCode: '4533',
    city: 'Piberbach'
  },
  {
    postalCode: '4533',
    city: 'Siedlung'
  },
  {
    postalCode: '4533',
    city: 'Weifersdorf'
  },
  {
    postalCode: '4533',
    city: 'Winden'
  },
  {
    postalCode: '4540',
    city: 'Eggmayr'
  },
  {
    postalCode: '4540',
    city: 'Möderndorf'
  },
  {
    postalCode: '4540',
    city: 'Mühlgrub'
  },
  {
    postalCode: '4540',
    city: 'Bad Hall'
  },
  {
    postalCode: '4540',
    city: 'Furtberg'
  },
  {
    postalCode: '4540',
    city: 'Großmengersdorf'
  },
  {
    postalCode: '4540',
    city: 'Hehenberg  - Gmd Bad Hall'
  },
  {
    postalCode: '4540',
    city: 'Pesendorf'
  },
  {
    postalCode: '4540',
    city: 'Kremsmünster'
  },
  {
    postalCode: '4540',
    city: 'Feyregg'
  },
  {
    postalCode: '4540',
    city: 'Pfarrkirchen bei Bad Hall'
  },
  {
    postalCode: '4540',
    city: 'Oberrohr'
  },
  {
    postalCode: '4540',
    city: 'Eggmair'
  },
  {
    postalCode: '4541',
    city: 'Adlwang'
  },
  {
    postalCode: '4541',
    city: 'Emsenhub'
  },
  {
    postalCode: '4541',
    city: 'Mandorf'
  },
  {
    postalCode: '4541',
    city: 'Möderndorf'
  },
  {
    postalCode: '4542',
    city: 'Auern'
  },
  {
    postalCode: '4542',
    city: 'Dauersdorf'
  },
  {
    postalCode: '4542',
    city: 'Jageredt'
  },
  {
    postalCode: '4542',
    city: 'Natzberg'
  },
  {
    postalCode: '4542',
    city: 'Nußbach'
  },
  {
    postalCode: '4542',
    city: 'Plaschlhof'
  },
  {
    postalCode: '4542',
    city: 'Sinzendorf'
  },
  {
    postalCode: '4550',
    city: 'Achleiten'
  },
  {
    postalCode: '4550',
    city: 'Kremsmünster'
  },
  {
    postalCode: '4550',
    city: 'Fierling'
  },
  {
    postalCode: '4551',
    city: 'Großendorf'
  },
  {
    postalCode: '4551',
    city: 'Penzendorf'
  },
  {
    postalCode: '4551',
    city: 'Pesendorf'
  },
  {
    postalCode: '4551',
    city: 'Ried im Traunkreis'
  },
  {
    postalCode: '4551',
    city: 'Rührndorf'
  },
  {
    postalCode: '4551',
    city: 'Voitsdorf'
  },
  {
    postalCode: '4551',
    city: 'Weigersdorf'
  },
  {
    postalCode: '4551',
    city: 'Zenndorf'
  },
  {
    postalCode: '4552',
    city: 'Feyregg'
  },
  {
    postalCode: '4552',
    city: 'Hammersdorf'
  },
  {
    postalCode: '4552',
    city: 'Penzendorf'
  },
  {
    postalCode: '4552',
    city: 'Schachadorf'
  },
  {
    postalCode: '4552',
    city: 'Maisdorf'
  },
  {
    postalCode: '4552',
    city: 'Diepersdorf'
  },
  {
    postalCode: '4552',
    city: 'Hiersdorf'
  },
  {
    postalCode: '4552',
    city: 'Strienzing'
  },
  {
    postalCode: '4552',
    city: 'Wartberg an der Krems'
  },
  {
    postalCode: '4553',
    city: 'Haselbäckau'
  },
  {
    postalCode: '4553',
    city: 'Dauersdorf'
  },
  {
    postalCode: '4553',
    city: 'Oberschlierbach'
  },
  {
    postalCode: '4553',
    city: 'Haselböckau'
  },
  {
    postalCode: '4553',
    city: 'Hausmanning'
  },
  {
    postalCode: '4553',
    city: 'Maisdorf'
  },
  {
    postalCode: '4553',
    city: 'Schlierbach'
  },
  {
    postalCode: '4560',
    city: 'Lauterbach'
  },
  {
    postalCode: '4560',
    city: 'Inzersdorf im Kremstal'
  },
  {
    postalCode: '4560',
    city: 'Kirchdorf an der Krems'
  },
  {
    postalCode: '4560',
    city: 'Altpernstein'
  },
  {
    postalCode: '4560',
    city: 'Micheldorf'
  },
  {
    postalCode: '4560',
    city: 'Ottsdorf'
  },
  {
    postalCode: '4560',
    city: 'Seebach'
  },
  {
    postalCode: '4560',
    city: 'Weinzierl'
  },
  {
    postalCode: '4560',
    city: 'Weinzierlerbrücke'
  },
  {
    postalCode: '4560',
    city: 'Hausmanning'
  },
  {
    postalCode: '4560',
    city: 'Schlierbach'
  },
  {
    postalCode: '4562',
    city: 'Steinbach am Ziehberg'
  },
  {
    postalCode: '4563',
    city: 'Kirchdorf an der Krems'
  },
  {
    postalCode: '4563',
    city: 'Altpernstein'
  },
  {
    postalCode: '4563',
    city: 'Atzelsdorf'
  },
  {
    postalCode: '4563',
    city: 'Eisbach'
  },
  {
    postalCode: '4563',
    city: 'Heiligenkreuz'
  },
  {
    postalCode: '4563',
    city: 'Hilbing'
  },
  {
    postalCode: '4563',
    city: 'Hinterburg'
  },
  {
    postalCode: '4563',
    city: 'Im Himmelreich'
  },
  {
    postalCode: '4563',
    city: 'In der Krems'
  },
  {
    postalCode: '4563',
    city: 'Kienberg'
  },
  {
    postalCode: '4563',
    city: 'Kremsdorf'
  },
  {
    postalCode: '4563',
    city: 'Micheldorf'
  },
  {
    postalCode: '4563',
    city: 'Oberer Wienerweg'
  },
  {
    postalCode: '4563',
    city: 'Schön'
  },
  {
    postalCode: '4563',
    city: 'Thurnham'
  },
  {
    postalCode: '4563',
    city: 'Unterer Wienerweg'
  },
  {
    postalCode: '4563',
    city: 'Weinzierl'
  },
  {
    postalCode: '4563',
    city: 'Weinzierlerbrücke'
  },
  {
    postalCode: '4564',
    city: 'Klaus an der Pyhrnbahn'
  },
  {
    postalCode: '4564',
    city: 'Kienberg'
  },
  {
    postalCode: '4564',
    city: 'Ramsau'
  },
  {
    postalCode: '4571',
    city: 'Klaus an der Pyhrnbahn'
  },
  {
    postalCode: '4571',
    city: 'Kniewas'
  },
  {
    postalCode: '4571',
    city: 'Steyrling'
  },
  {
    postalCode: '4572',
    city: 'Tambergau'
  },
  {
    postalCode: '4572',
    city: 'Kniewas'
  },
  {
    postalCode: '4572',
    city: 'Sankt Pankraz'
  },
  {
    postalCode: '4572',
    city: 'Schalchgraben'
  },
  {
    postalCode: '4573',
    city: 'Hutterer Böden'
  },
  {
    postalCode: '4573',
    city: 'Hinterberg'
  },
  {
    postalCode: '4573',
    city: 'Hinterstoder'
  },
  {
    postalCode: '4573',
    city: 'Tambergau'
  },
  {
    postalCode: '4573',
    city: 'Mitterstoder'
  },
  {
    postalCode: '4573',
    city: 'Vorderstoder'
  },
  {
    postalCode: '4573',
    city: 'Vordertambergau'
  },
  {
    postalCode: '4574',
    city: 'Tambergau'
  },
  {
    postalCode: '4574',
    city: 'Gaisriegl'
  },
  {
    postalCode: '4574',
    city: 'Vorderstoder'
  },
  {
    postalCode: '4574',
    city: 'Vordertambergau'
  },
  {
    postalCode: '4575',
    city: 'Mayrwinkl'
  },
  {
    postalCode: '4575',
    city: 'Pichl'
  },
  {
    postalCode: '4575',
    city: 'Pießling'
  },
  {
    postalCode: '4575',
    city: 'Rading'
  },
  {
    postalCode: '4575',
    city: 'Roßleithen'
  },
  {
    postalCode: '4575',
    city: 'Schweizersberg'
  },
  {
    postalCode: '4575',
    city: 'Gaisriegl'
  },
  {
    postalCode: '4575',
    city: 'Vordertambergau'
  },
  {
    postalCode: '4575',
    city: 'Walchegg'
  },
  {
    postalCode: '4580',
    city: 'Edlbach'
  },
  {
    postalCode: '4580',
    city: 'Dambach'
  },
  {
    postalCode: '4580',
    city: 'Windischgarsten'
  },
  {
    postalCode: '4581',
    city: 'Edlbach'
  },
  {
    postalCode: '4581',
    city: 'Dambach'
  },
  {
    postalCode: '4581',
    city: 'Rosenau am Hengstpaß'
  },
  {
    postalCode: '4582',
    city: 'Edlbach'
  },
  {
    postalCode: '4582',
    city: 'Mitterweng'
  },
  {
    postalCode: '4582',
    city: 'Fahrenberg'
  },
  {
    postalCode: '4582',
    city: 'Gleinkerau'
  },
  {
    postalCode: '4582',
    city: 'Oberweng'
  },
  {
    postalCode: '4582',
    city: 'Seebach'
  },
  {
    postalCode: '4582',
    city: 'Spital am Pyhrn'
  },
  {
    postalCode: '4591',
    city: 'Leonstein'
  },
  {
    postalCode: '4591',
    city: 'Breitenau'
  },
  {
    postalCode: '4591',
    city: 'Molln'
  },
  {
    postalCode: '4591',
    city: 'Ramsau'
  },
  {
    postalCode: '4591',
    city: 'Dambach'
  },
  {
    postalCode: '4592',
    city: 'Obergrünburg'
  },
  {
    postalCode: '4592',
    city: 'Leonstein'
  },
  {
    postalCode: '4592',
    city: 'Rinnerberg'
  },
  {
    postalCode: '4592',
    city: 'Hausmanning'
  },
  {
    postalCode: '4592',
    city: 'Pernzell'
  },
  {
    postalCode: '4593',
    city: 'Obergrünburg'
  },
  {
    postalCode: '4593',
    city: 'Untergrünburg'
  },
  {
    postalCode: '4593',
    city: 'Wagenhub'
  },
  {
    postalCode: '4593',
    city: 'Pernzell'
  },
  {
    postalCode: '4593',
    city: 'Forstau'
  },
  {
    postalCode: '4594',
    city: 'Haagen'
  },
  {
    postalCode: '4594',
    city: 'Mitteregg'
  },
  {
    postalCode: '4594',
    city: 'Untergrünburg'
  },
  {
    postalCode: '4594',
    city: 'Wagenhub'
  },
  {
    postalCode: '4594',
    city: 'Forstau'
  },
  {
    postalCode: '4594',
    city: 'Pieslwang'
  },
  {
    postalCode: '4594',
    city: 'Steinbach an der Steyr'
  },
  {
    postalCode: '4594',
    city: 'Zehetner'
  },
  {
    postalCode: '4594',
    city: 'Ternberg'
  },
  {
    postalCode: '4594',
    city: 'Sankt Nikola'
  },
  {
    postalCode: '4594',
    city: 'Waldneukirchen'
  },
  {
    postalCode: '4595',
    city: 'Eggmair'
  },
  {
    postalCode: '4595',
    city: 'Pesendorf'
  },
  {
    postalCode: '4595',
    city: 'Sankt Nikola'
  },
  {
    postalCode: '4595',
    city: 'Steinersdorf'
  },
  {
    postalCode: '4595',
    city: 'Waldneukirchen'
  },
  {
    postalCode: '4600',
    city: 'Blindenmarkt'
  },
  {
    postalCode: '4600',
    city: 'Dietach'
  },
  {
    postalCode: '4600',
    city: 'Schleißheim'
  },
  {
    postalCode: '4600',
    city: 'Oberschauersberg'
  },
  {
    postalCode: '4600',
    city: 'Traunleiten'
  },
  {
    postalCode: '4600',
    city: 'Bergerndorf'
  },
  {
    postalCode: '4600',
    city: 'Edtholz'
  },
  {
    postalCode: '4600',
    city: 'Ottstorf'
  },
  {
    postalCode: '4600',
    city: 'Schauersberg'
  },
  {
    postalCode: '4600',
    city: 'Thalheim bei Wels'
  },
  {
    postalCode: '4600',
    city: 'Unterschauersberg'
  },
  {
    postalCode: '4600',
    city: 'Aichberg'
  },
  {
    postalCode: '4600',
    city: 'Au'
  },
  {
    postalCode: '4600',
    city: 'Berg'
  },
  {
    postalCode: '4600',
    city: 'Brandln'
  },
  {
    postalCode: '4600',
    city: 'Dickerldorf'
  },
  {
    postalCode: '4600',
    city: 'Doppelgraben'
  },
  {
    postalCode: '4600',
    city: 'Eben'
  },
  {
    postalCode: '4600',
    city: 'Gaßl'
  },
  {
    postalCode: '4600',
    city: 'Höllwiesen'
  },
  {
    postalCode: '4600',
    city: 'Hölzl'
  },
  {
    postalCode: '4600',
    city: 'Kirchham'
  },
  {
    postalCode: '4600',
    city: 'Laahen'
  },
  {
    postalCode: '4600',
    city: 'Lichtenegg'
  },
  {
    postalCode: '4600',
    city: 'Mitterlaab'
  },
  {
    postalCode: '4600',
    city: 'Niederthan'
  },
  {
    postalCode: '4600',
    city: 'Nöham'
  },
  {
    postalCode: '4600',
    city: 'Oberhaid'
  },
  {
    postalCode: '4600',
    city: 'Oberhart'
  },
  {
    postalCode: '4600',
    city: 'Oberlaab'
  },
  {
    postalCode: '4600',
    city: 'Oberthan'
  },
  {
    postalCode: '4600',
    city: 'Pernau'
  },
  {
    postalCode: '4600',
    city: 'Puchberg'
  },
  {
    postalCode: '4600',
    city: 'Roithen'
  },
  {
    postalCode: '4600',
    city: 'Rosenau'
  },
  {
    postalCode: '4600',
    city: 'Schafwiesen'
  },
  {
    postalCode: '4600',
    city: 'Stadlhof'
  },
  {
    postalCode: '4600',
    city: 'Trausenegg'
  },
  {
    postalCode: '4600',
    city: 'Unterleithen'
  },
  {
    postalCode: '4600',
    city: 'Waidhausen'
  },
  {
    postalCode: '4600',
    city: 'Wels'
  },
  {
    postalCode: '4600',
    city: 'Wimpassing'
  },
  {
    postalCode: '4600',
    city: 'Wispl'
  },
  {
    postalCode: '4611',
    city: 'Buchkirchen'
  },
  {
    postalCode: '4611',
    city: 'Ennsberg'
  },
  {
    postalCode: '4611',
    city: 'Epping'
  },
  {
    postalCode: '4611',
    city: 'Hartberg'
  },
  {
    postalCode: '4611',
    city: 'Hörling'
  },
  {
    postalCode: '4611',
    city: 'Holzwiesen'
  },
  {
    postalCode: '4611',
    city: 'Hundsham'
  },
  {
    postalCode: '4611',
    city: 'Kandlberg'
  },
  {
    postalCode: '4611',
    city: 'Lachgraben'
  },
  {
    postalCode: '4611',
    city: 'Luckermair'
  },
  {
    postalCode: '4611',
    city: 'Niedergrafing'
  },
  {
    postalCode: '4611',
    city: 'Niederhocherenz'
  },
  {
    postalCode: '4611',
    city: 'Niederlaab'
  },
  {
    postalCode: '4611',
    city: 'Obergrafing'
  },
  {
    postalCode: '4611',
    city: 'Oberhocherenz'
  },
  {
    postalCode: '4611',
    city: 'Oberperwend'
  },
  {
    postalCode: '4611',
    city: 'Oberprisching'
  },
  {
    postalCode: '4611',
    city: 'Ötzing'
  },
  {
    postalCode: '4611',
    city: 'Radlach'
  },
  {
    postalCode: '4611',
    city: 'Schickenhäuser'
  },
  {
    postalCode: '4611',
    city: 'Schnadt'
  },
  {
    postalCode: '4611',
    city: 'Sommerfeld'
  },
  {
    postalCode: '4611',
    city: 'Spengenedt'
  },
  {
    postalCode: '4611',
    city: 'Wörist'
  },
  {
    postalCode: '4611',
    city: 'Uttenthal'
  },
  {
    postalCode: '4612',
    city: 'Hochscharten'
  },
  {
    postalCode: '4612',
    city: 'Sommerfeld'
  },
  {
    postalCode: '4612',
    city: 'Steinberg'
  },
  {
    postalCode: '4612',
    city: 'Herrnholz'
  },
  {
    postalCode: '4612',
    city: 'Kronberg'
  },
  {
    postalCode: '4612',
    city: 'Leppersdorf'
  },
  {
    postalCode: '4612',
    city: 'Rexham'
  },
  {
    postalCode: '4612',
    city: 'Roitham'
  },
  {
    postalCode: '4612',
    city: 'Roithen'
  },
  {
    postalCode: '4612',
    city: 'Scharten'
  },
  {
    postalCode: '4612',
    city: 'Vitta'
  },
  {
    postalCode: '4613',
    city: 'Elend'
  },
  {
    postalCode: '4613',
    city: 'Haberfelden'
  },
  {
    postalCode: '4613',
    city: 'Hupfau'
  },
  {
    postalCode: '4613',
    city: 'Mistelbach bei Wels'
  },
  {
    postalCode: '4613',
    city: 'Öhnerhäuser'
  },
  {
    postalCode: '4613',
    city: 'Ottenham'
  },
  {
    postalCode: '4613',
    city: 'Unterholz'
  },
  {
    postalCode: '4613',
    city: 'Wolfsgrub'
  },
  {
    postalCode: '4614',
    city: 'Au an der Traun'
  },
  {
    postalCode: '4614',
    city: 'Kappern'
  },
  {
    postalCode: '4614',
    city: 'Leithen'
  },
  {
    postalCode: '4614',
    city: 'Marchtrenk'
  },
  {
    postalCode: '4614',
    city: 'Mitterperwend'
  },
  {
    postalCode: '4614',
    city: 'Niederperwend'
  },
  {
    postalCode: '4614',
    city: 'Niederprisching'
  },
  {
    postalCode: '4614',
    city: 'Oberneufahrn'
  },
  {
    postalCode: '4614',
    city: 'Schafwiesen'
  },
  {
    postalCode: '4614',
    city: 'Unterhaid'
  },
  {
    postalCode: '4614',
    city: 'Unterhart'
  },
  {
    postalCode: '4614',
    city: 'Unterneufahrn'
  },
  {
    postalCode: '4615',
    city: 'Grillparz'
  },
  {
    postalCode: '4615',
    city: 'Holzhausen'
  },
  {
    postalCode: '4615',
    city: 'Jebenstein'
  },
  {
    postalCode: '4615',
    city: 'Kranzing'
  },
  {
    postalCode: '4615',
    city: 'Lehen'
  },
  {
    postalCode: '4615',
    city: 'Niederprisching'
  },
  {
    postalCode: '4616',
    city: 'Grassing'
  },
  {
    postalCode: '4616',
    city: 'Bergern'
  },
  {
    postalCode: '4616',
    city: 'Graßing'
  },
  {
    postalCode: '4616',
    city: 'Hetzendorf'
  },
  {
    postalCode: '4616',
    city: 'Schimpelsberg'
  },
  {
    postalCode: '4616',
    city: 'Sinnersdorf'
  },
  {
    postalCode: '4616',
    city: 'Weißkirchen'
  },
  {
    postalCode: '4616',
    city: 'Weyerbach'
  },
  {
    postalCode: '4621',
    city: 'Kremsmünster'
  },
  {
    postalCode: '4621',
    city: 'Giering'
  },
  {
    postalCode: '4621',
    city: 'Leombach'
  },
  {
    postalCode: '4621',
    city: 'Loibingdorf'
  },
  {
    postalCode: '4621',
    city: 'Permannsberg'
  },
  {
    postalCode: '4621',
    city: 'Rappersdorf'
  },
  {
    postalCode: '4621',
    city: 'Schachermairdorf'
  },
  {
    postalCode: '4621',
    city: 'Schnarrndorf'
  },
  {
    postalCode: '4621',
    city: 'Sipbachzell'
  },
  {
    postalCode: '4622',
    city: 'Kroisbach'
  },
  {
    postalCode: '4622',
    city: 'Brunnern'
  },
  {
    postalCode: '4622',
    city: 'Eggendorf im Traunkreis'
  },
  {
    postalCode: '4622',
    city: 'Hueb'
  },
  {
    postalCode: '4623',
    city: 'Aichberg'
  },
  {
    postalCode: '4623',
    city: 'Aigen'
  },
  {
    postalCode: '4623',
    city: 'Au bei der Traun'
  },
  {
    postalCode: '4623',
    city: 'Au bei Hischmannsberg'
  },
  {
    postalCode: '4623',
    city: 'Au bei Sirfling'
  },
  {
    postalCode: '4623',
    city: 'Auholz'
  },
  {
    postalCode: '4623',
    city: 'Baumgarting'
  },
  {
    postalCode: '4623',
    city: 'Bichlwimm'
  },
  {
    postalCode: '4623',
    city: 'Buchleiten'
  },
  {
    postalCode: '4623',
    city: 'Dorf'
  },
  {
    postalCode: '4623',
    city: 'Fallsbach'
  },
  {
    postalCode: '4623',
    city: 'Fernreith'
  },
  {
    postalCode: '4623',
    city: 'Gänsanger'
  },
  {
    postalCode: '4623',
    city: 'Grünbach'
  },
  {
    postalCode: '4623',
    city: 'Gunskirchen'
  },
  {
    postalCode: '4623',
    city: 'Hof'
  },
  {
    postalCode: '4623',
    city: 'Holzgassen'
  },
  {
    postalCode: '4623',
    city: 'Holzing'
  },
  {
    postalCode: '4623',
    city: 'Irnharting'
  },
  {
    postalCode: '4623',
    city: 'Kalchau'
  },
  {
    postalCode: '4623',
    city: 'Kappling'
  },
  {
    postalCode: '4623',
    city: 'Kottingreith'
  },
  {
    postalCode: '4623',
    city: 'Kranzl am Eck'
  },
  {
    postalCode: '4623',
    city: 'Lehen'
  },
  {
    postalCode: '4623',
    city: 'Liedering'
  },
  {
    postalCode: '4623',
    city: 'Lucken'
  },
  {
    postalCode: '4623',
    city: 'Luckenberg'
  },
  {
    postalCode: '4623',
    city: 'Moostal'
  },
  {
    postalCode: '4623',
    city: 'Niederschacher'
  },
  {
    postalCode: '4623',
    city: 'Oberndorf'
  },
  {
    postalCode: '4623',
    city: 'Oberriethal'
  },
  {
    postalCode: '4623',
    city: 'Oberschacher'
  },
  {
    postalCode: '4623',
    city: 'Pfarrhofwies'
  },
  {
    postalCode: '4623',
    city: 'Pöschlberg'
  },
  {
    postalCode: '4623',
    city: 'Pötzlberg'
  },
  {
    postalCode: '4623',
    city: 'Riethal'
  },
  {
    postalCode: '4623',
    city: 'Roith'
  },
  {
    postalCode: '4623',
    city: 'Salling'
  },
  {
    postalCode: '4623',
    city: 'Schlambart'
  },
  {
    postalCode: '4623',
    city: 'Sirfling'
  },
  {
    postalCode: '4623',
    city: 'Spraid'
  },
  {
    postalCode: '4623',
    city: 'Straßern'
  },
  {
    postalCode: '4623',
    city: 'Ströblberg'
  },
  {
    postalCode: '4623',
    city: 'Thal'
  },
  {
    postalCode: '4623',
    city: 'Vitzing'
  },
  {
    postalCode: '4623',
    city: 'Vornholz'
  },
  {
    postalCode: '4623',
    city: 'Waldenberg'
  },
  {
    postalCode: '4623',
    city: 'Waldling'
  },
  {
    postalCode: '4623',
    city: 'Wallnstorf'
  },
  {
    postalCode: '4623',
    city: 'Wilhaming'
  },
  {
    postalCode: '4623',
    city: 'Wimberg'
  },
  {
    postalCode: '4623',
    city: 'Hölzl'
  },
  {
    postalCode: '4624',
    city: 'Balding'
  },
  {
    postalCode: '4624',
    city: 'Hölking'
  },
  {
    postalCode: '4624',
    city: 'Arbing'
  },
  {
    postalCode: '4624',
    city: 'Breitenau'
  },
  {
    postalCode: '4624',
    city: 'Dirnberg'
  },
  {
    postalCode: '4624',
    city: 'Felling'
  },
  {
    postalCode: '4624',
    city: 'Graben'
  },
  {
    postalCode: '4624',
    city: 'Horning'
  },
  {
    postalCode: '4624',
    city: 'Krexham'
  },
  {
    postalCode: '4624',
    city: 'Mitterfils'
  },
  {
    postalCode: '4624',
    city: 'Nölling'
  },
  {
    postalCode: '4624',
    city: 'Oberfils'
  },
  {
    postalCode: '4624',
    city: 'Parzham'
  },
  {
    postalCode: '4624',
    city: 'Pennewang'
  },
  {
    postalCode: '4624',
    city: 'Pimming'
  },
  {
    postalCode: '4624',
    city: 'Rosenberg'
  },
  {
    postalCode: '4624',
    city: 'Schmitzberg'
  },
  {
    postalCode: '4624',
    city: 'Schneiting'
  },
  {
    postalCode: '4624',
    city: 'Staffel'
  },
  {
    postalCode: '4624',
    city: 'Stürzling'
  },
  {
    postalCode: '4624',
    city: 'Unterfils'
  },
  {
    postalCode: '4624',
    city: 'Unterwald'
  },
  {
    postalCode: '4624',
    city: 'Weinzierl'
  },
  {
    postalCode: '4624',
    city: 'Weißbach'
  },
  {
    postalCode: '4624',
    city: 'Wiesham'
  },
  {
    postalCode: '4625',
    city: 'Holzhäuseln bei Wilhelmsberg'
  },
  {
    postalCode: '4625',
    city: 'Aigen'
  },
  {
    postalCode: '4625',
    city: 'Amesberg'
  },
  {
    postalCode: '4625',
    city: 'Eglsee'
  },
  {
    postalCode: '4625',
    city: 'Großkrottendorf'
  },
  {
    postalCode: '4625',
    city: 'Grub'
  },
  {
    postalCode: '4625',
    city: 'Haindorf'
  },
  {
    postalCode: '4625',
    city: 'Humplberg'
  },
  {
    postalCode: '4625',
    city: 'Kapsam'
  },
  {
    postalCode: '4625',
    city: 'Kleinkrottendorf'
  },
  {
    postalCode: '4625',
    city: 'Kohlböckhof'
  },
  {
    postalCode: '4625',
    city: 'Kronberg'
  },
  {
    postalCode: '4625',
    city: 'Kurzenkirchen'
  },
  {
    postalCode: '4625',
    city: 'Maierhof'
  },
  {
    postalCode: '4625',
    city: 'Moos'
  },
  {
    postalCode: '4625',
    city: 'Obereggen'
  },
  {
    postalCode: '4625',
    city: 'Offenhausen'
  },
  {
    postalCode: '4625',
    city: 'Osterberg'
  },
  {
    postalCode: '4625',
    city: 'Paschlberg'
  },
  {
    postalCode: '4625',
    city: 'Pfaffendorf'
  },
  {
    postalCode: '4625',
    city: 'Sittenthal'
  },
  {
    postalCode: '4625',
    city: 'Stockerberg'
  },
  {
    postalCode: '4625',
    city: 'Stritzing'
  },
  {
    postalCode: '4625',
    city: 'Untereggen'
  },
  {
    postalCode: '4625',
    city: 'Voglsang'
  },
  {
    postalCode: '4625',
    city: 'Vornholz'
  },
  {
    postalCode: '4625',
    city: 'Weinberg'
  },
  {
    postalCode: '4625',
    city: 'Wies'
  },
  {
    postalCode: '4625',
    city: 'Wieshäusl'
  },
  {
    postalCode: '4625',
    city: 'Würting'
  },
  {
    postalCode: '4625',
    city: 'Bachstätten'
  },
  {
    postalCode: '4625',
    city: 'Braunberg'
  },
  {
    postalCode: '4631',
    city: 'Wörist'
  },
  {
    postalCode: '4631',
    city: 'Achleiten'
  },
  {
    postalCode: '4631',
    city: 'Alkrucken'
  },
  {
    postalCode: '4631',
    city: 'Au'
  },
  {
    postalCode: '4631',
    city: 'Elmischhub'
  },
  {
    postalCode: '4631',
    city: 'Forst'
  },
  {
    postalCode: '4631',
    city: 'Geigen'
  },
  {
    postalCode: '4631',
    city: 'Gfereth'
  },
  {
    postalCode: '4631',
    city: 'Gölding'
  },
  {
    postalCode: '4631',
    city: 'Haiding'
  },
  {
    postalCode: '4631',
    city: 'Holzhäuser'
  },
  {
    postalCode: '4631',
    city: 'Kalteneck'
  },
  {
    postalCode: '4631',
    city: 'Katzbach'
  },
  {
    postalCode: '4631',
    city: 'Krenglbach'
  },
  {
    postalCode: '4631',
    city: 'Nadernberg'
  },
  {
    postalCode: '4631',
    city: 'Oberham'
  },
  {
    postalCode: '4631',
    city: 'Öhlgraben'
  },
  {
    postalCode: '4631',
    city: 'Radgattern'
  },
  {
    postalCode: '4631',
    city: 'Schmiding'
  },
  {
    postalCode: '4631',
    city: 'Unrading'
  },
  {
    postalCode: '4631',
    city: 'Wieshof'
  },
  {
    postalCode: '4631',
    city: 'Wundersberg'
  },
  {
    postalCode: '4631',
    city: 'Geisensheim'
  },
  {
    postalCode: '4631',
    city: 'Haag'
  },
  {
    postalCode: '4632',
    city: 'Krottendorf'
  },
  {
    postalCode: '4632',
    city: 'Ach'
  },
  {
    postalCode: '4632',
    city: 'Aichet'
  },
  {
    postalCode: '4632',
    city: 'Aichmühl'
  },
  {
    postalCode: '4632',
    city: 'Am Irrach'
  },
  {
    postalCode: '4632',
    city: 'Angsterlehen'
  },
  {
    postalCode: '4632',
    city: 'Auhub'
  },
  {
    postalCode: '4632',
    city: 'Brandstatt'
  },
  {
    postalCode: '4632',
    city: 'Breitwies'
  },
  {
    postalCode: '4632',
    city: 'Etzelsdorf'
  },
  {
    postalCode: '4632',
    city: 'Fadleiten'
  },
  {
    postalCode: '4632',
    city: 'Falzberg'
  },
  {
    postalCode: '4632',
    city: 'Franzing'
  },
  {
    postalCode: '4632',
    city: 'Freiung'
  },
  {
    postalCode: '4632',
    city: 'Geisensheim'
  },
  {
    postalCode: '4632',
    city: 'Haag'
  },
  {
    postalCode: '4632',
    city: 'Hochwimm'
  },
  {
    postalCode: '4632',
    city: 'Holzhäuser'
  },
  {
    postalCode: '4632',
    city: 'Inn'
  },
  {
    postalCode: '4632',
    city: 'Jägersberg'
  },
  {
    postalCode: '4632',
    city: 'Kaiserleiten'
  },
  {
    postalCode: '4632',
    city: 'Kerschberg'
  },
  {
    postalCode: '4632',
    city: 'Lechlödt'
  },
  {
    postalCode: '4632',
    city: 'Malling'
  },
  {
    postalCode: '4632',
    city: 'Mitterleiten'
  },
  {
    postalCode: '4632',
    city: 'Moosleiten'
  },
  {
    postalCode: '4632',
    city: 'Nisting'
  },
  {
    postalCode: '4632',
    city: 'Oberirrach'
  },
  {
    postalCode: '4632',
    city: 'Oberndorf'
  },
  {
    postalCode: '4632',
    city: 'Oberthambach'
  },
  {
    postalCode: '4632',
    city: 'Ödt'
  },
  {
    postalCode: '4632',
    city: 'Pfaffendorf'
  },
  {
    postalCode: '4632',
    city: 'Pichl bei Wels'
  },
  {
    postalCode: '4632',
    city: 'Pröstlsberg'
  },
  {
    postalCode: '4632',
    city: 'Puchet'
  },
  {
    postalCode: '4632',
    city: 'Pühret'
  },
  {
    postalCode: '4632',
    city: 'Schalbach'
  },
  {
    postalCode: '4632',
    city: 'Schmiedsberg'
  },
  {
    postalCode: '4632',
    city: 'Schnappling'
  },
  {
    postalCode: '4632',
    city: 'Schnittering'
  },
  {
    postalCode: '4632',
    city: 'Schustersberg'
  },
  {
    postalCode: '4632',
    city: 'Silbersberg'
  },
  {
    postalCode: '4632',
    city: 'Stadl'
  },
  {
    postalCode: '4632',
    city: 'Steinpichl'
  },
  {
    postalCode: '4632',
    city: 'Sulzbach'
  },
  {
    postalCode: '4632',
    city: 'Trappelsberg'
  },
  {
    postalCode: '4632',
    city: 'Unterirrach'
  },
  {
    postalCode: '4632',
    city: 'Unterthambach'
  },
  {
    postalCode: '4632',
    city: 'Uttendorf'
  },
  {
    postalCode: '4632',
    city: 'Waldgattern'
  },
  {
    postalCode: '4632',
    city: 'Weilbach'
  },
  {
    postalCode: '4632',
    city: 'Weinberg'
  },
  {
    postalCode: '4632',
    city: 'Winkelfeld'
  },
  {
    postalCode: '4632',
    city: 'Buchet'
  },
  {
    postalCode: '4632',
    city: 'Dingberg'
  },
  {
    postalCode: '4632',
    city: 'Mitterndorf'
  },
  {
    postalCode: '4633',
    city: 'Breitwies'
  },
  {
    postalCode: '4633',
    city: 'Bubendorf'
  },
  {
    postalCode: '4633',
    city: 'Burghartsberg'
  },
  {
    postalCode: '4633',
    city: 'Burgstall'
  },
  {
    postalCode: '4633',
    city: 'Gaubing'
  },
  {
    postalCode: '4633',
    city: 'Grübl'
  },
  {
    postalCode: '4633',
    city: 'Kematen am Innbach'
  },
  {
    postalCode: '4633',
    city: 'Moos'
  },
  {
    postalCode: '4633',
    city: 'Oberdoppl'
  },
  {
    postalCode: '4633',
    city: 'Oberholzing'
  },
  {
    postalCode: '4633',
    city: 'See'
  },
  {
    postalCode: '4633',
    city: 'Steinerkirchen am Innbach'
  },
  {
    postalCode: '4633',
    city: 'Straß'
  },
  {
    postalCode: '4633',
    city: 'Unterdoppl'
  },
  {
    postalCode: '4633',
    city: 'Unterholzing'
  },
  {
    postalCode: '4633',
    city: 'Wiesing'
  },
  {
    postalCode: '4633',
    city: 'Weilbach'
  },
  {
    postalCode: '4641',
    city: 'Forstberg'
  },
  {
    postalCode: '4641',
    city: 'Oberhart'
  },
  {
    postalCode: '4641',
    city: 'Oberschauersberg'
  },
  {
    postalCode: '4641',
    city: 'Steinhaus'
  },
  {
    postalCode: '4641',
    city: 'Taxlberg'
  },
  {
    postalCode: '4641',
    city: 'Unterhart'
  },
  {
    postalCode: '4642',
    city: 'Maidorf'
  },
  {
    postalCode: '4642',
    city: 'Dirnberg'
  },
  {
    postalCode: '4642',
    city: 'Giering'
  },
  {
    postalCode: '4642',
    city: 'Heiligenkreuz'
  },
  {
    postalCode: '4642',
    city: 'Oberautal'
  },
  {
    postalCode: '4642',
    city: 'Oberhart'
  },
  {
    postalCode: '4642',
    city: 'Pochendorf'
  },
  {
    postalCode: '4642',
    city: 'Rappersdorf'
  },
  {
    postalCode: '4642',
    city: 'Sattledt'
  },
  {
    postalCode: '4642',
    city: 'Unterhart'
  },
  {
    postalCode: '4643',
    city: 'Dürndorf'
  },
  {
    postalCode: '4643',
    city: 'Mayersdorf'
  },
  {
    postalCode: '4643',
    city: 'Magdalenaberg'
  },
  {
    postalCode: '4643',
    city: 'Etzelsdorf'
  },
  {
    postalCode: '4643',
    city: 'Gundendorf'
  },
  {
    postalCode: '4643',
    city: 'Hammersdorf'
  },
  {
    postalCode: '4643',
    city: 'Lungendorf'
  },
  {
    postalCode: '4643',
    city: 'Mitterndorf'
  },
  {
    postalCode: '4643',
    city: 'Pettenbach'
  },
  {
    postalCode: '4643',
    city: 'Pratsdorf'
  },
  {
    postalCode: '4643',
    city: 'Steinfelden'
  },
  {
    postalCode: '4644',
    city: 'Grünau im Almtal'
  },
  {
    postalCode: '4644',
    city: 'Sankt Konrad'
  },
  {
    postalCode: '4644',
    city: 'Dorf'
  },
  {
    postalCode: '4644',
    city: 'Mühldorf'
  },
  {
    postalCode: '4644',
    city: 'Scharnstein'
  },
  {
    postalCode: '4644',
    city: 'Viechtwang'
  },
  {
    postalCode: '4644',
    city: 'Steinbach am Ziehberg'
  },
  {
    postalCode: '4645',
    city: 'Grünau im Almtal'
  },
  {
    postalCode: '4645',
    city: 'Mühldorf'
  },
  {
    postalCode: '4650',
    city: 'Aichham'
  },
  {
    postalCode: '4650',
    city: 'Aigen'
  },
  {
    postalCode: '4650',
    city: 'Bergern'
  },
  {
    postalCode: '4650',
    city: 'Brandstatt'
  },
  {
    postalCode: '4650',
    city: 'Breitenberg'
  },
  {
    postalCode: '4650',
    city: 'Edt'
  },
  {
    postalCode: '4650',
    city: 'Fluchtwang'
  },
  {
    postalCode: '4650',
    city: 'Graben'
  },
  {
    postalCode: '4650',
    city: 'Hagenberg'
  },
  {
    postalCode: '4650',
    city: 'Hölzlberg'
  },
  {
    postalCode: '4650',
    city: 'Holzmanning'
  },
  {
    postalCode: '4650',
    city: 'Klaus'
  },
  {
    postalCode: '4650',
    city: 'Kreisbichl'
  },
  {
    postalCode: '4650',
    city: 'Kropfing'
  },
  {
    postalCode: '4650',
    city: 'Laimberg'
  },
  {
    postalCode: '4650',
    city: 'Mairlambach'
  },
  {
    postalCode: '4650',
    city: 'Mernbach'
  },
  {
    postalCode: '4650',
    city: 'Mitterberg'
  },
  {
    postalCode: '4650',
    city: 'Niederschwaig'
  },
  {
    postalCode: '4650',
    city: 'Niederzeiling'
  },
  {
    postalCode: '4650',
    city: 'Oberroithen'
  },
  {
    postalCode: '4650',
    city: 'Oberzeiling'
  },
  {
    postalCode: '4650',
    city: 'Roith'
  },
  {
    postalCode: '4650',
    city: 'Saag'
  },
  {
    postalCode: '4650',
    city: 'Schlatt'
  },
  {
    postalCode: '4650',
    city: 'Schmidhub'
  },
  {
    postalCode: '4650',
    city: 'Sperr'
  },
  {
    postalCode: '4650',
    city: 'Unterroithen'
  },
  {
    postalCode: '4650',
    city: 'Winkling'
  },
  {
    postalCode: '4650',
    city: 'Lehen'
  },
  {
    postalCode: '4650',
    city: 'Fischerau'
  },
  {
    postalCode: '4650',
    city: 'Lambach'
  },
  {
    postalCode: '4650',
    city: 'Schußstatt'
  },
  {
    postalCode: '4650',
    city: 'Ziegelstadl'
  },
  {
    postalCode: '4651',
    city: 'Stadl-Hausruck'
  },
  {
    postalCode: '4651',
    city: 'Stadl-Traun'
  },
  {
    postalCode: '4651',
    city: 'Stadl-Ufer'
  },
  {
    postalCode: '4652',
    city: 'Laherberg'
  },
  {
    postalCode: '4652',
    city: 'Eggenberg'
  },
  {
    postalCode: '4652',
    city: 'Fischlham'
  },
  {
    postalCode: '4652',
    city: 'Forstberg'
  },
  {
    postalCode: '4652',
    city: 'Hafeld'
  },
  {
    postalCode: '4652',
    city: 'Heitzing'
  },
  {
    postalCode: '4652',
    city: 'Ornharting'
  },
  {
    postalCode: '4652',
    city: 'Seebach'
  },
  {
    postalCode: '4652',
    city: 'Zauset'
  },
  {
    postalCode: '4652',
    city: 'Almegg'
  },
  {
    postalCode: '4652',
    city: 'Atzing'
  },
  {
    postalCode: '4652',
    city: 'Atzmannsdorf'
  },
  {
    postalCode: '4652',
    city: 'Eden'
  },
  {
    postalCode: '4652',
    city: 'Frohnhofen'
  },
  {
    postalCode: '4652',
    city: 'Gundersdorf'
  },
  {
    postalCode: '4652',
    city: 'Hammersedt'
  },
  {
    postalCode: '4652',
    city: 'Hummelberg'
  },
  {
    postalCode: '4652',
    city: 'Kriegsham'
  },
  {
    postalCode: '4652',
    city: 'Niederheischbach'
  },
  {
    postalCode: '4652',
    city: 'Oberaustall'
  },
  {
    postalCode: '4652',
    city: 'Oberheischbach'
  },
  {
    postalCode: '4652',
    city: 'Pesenlittring'
  },
  {
    postalCode: '4652',
    city: 'Reuharting'
  },
  {
    postalCode: '4652',
    city: 'Ritzendorf'
  },
  {
    postalCode: '4652',
    city: 'Schnelling'
  },
  {
    postalCode: '4652',
    city: 'Sölling'
  },
  {
    postalCode: '4652',
    city: 'Steinerkirchen an der Traun'
  },
  {
    postalCode: '4652',
    city: 'Stockham'
  },
  {
    postalCode: '4652',
    city: 'Taxlberg'
  },
  {
    postalCode: '4652',
    city: 'Wollsberg'
  },
  {
    postalCode: '4652',
    city: 'Oberschauersberg'
  },
  {
    postalCode: '4653',
    city: 'Eberstalzell'
  },
  {
    postalCode: '4653',
    city: 'Hallwang'
  },
  {
    postalCode: '4653',
    city: 'Ittensam'
  },
  {
    postalCode: '4653',
    city: 'Littring'
  },
  {
    postalCode: '4653',
    city: 'Mayersdorf'
  },
  {
    postalCode: '4653',
    city: 'Spieldorf'
  },
  {
    postalCode: '4653',
    city: 'Wipfing'
  },
  {
    postalCode: '4653',
    city: 'Albenedt'
  },
  {
    postalCode: '4654',
    city: 'Aigen'
  },
  {
    postalCode: '4654',
    city: 'Au'
  },
  {
    postalCode: '4654',
    city: 'Bachloh'
  },
  {
    postalCode: '4654',
    city: 'Bergham'
  },
  {
    postalCode: '4654',
    city: 'Dorfham'
  },
  {
    postalCode: '4654',
    city: 'Ellnkam'
  },
  {
    postalCode: '4654',
    city: 'Giering'
  },
  {
    postalCode: '4654',
    city: 'Haag'
  },
  {
    postalCode: '4654',
    city: 'Haidermoos'
  },
  {
    postalCode: '4654',
    city: 'Kößlwang'
  },
  {
    postalCode: '4654',
    city: 'Neydharting'
  },
  {
    postalCode: '4654',
    city: 'Traun'
  },
  {
    postalCode: '4654',
    city: 'Wimsbach'
  },
  {
    postalCode: '4655',
    city: 'Hötzelsdorf'
  },
  {
    postalCode: '4655',
    city: 'Reuharting'
  },
  {
    postalCode: '4655',
    city: 'Adlhaming'
  },
  {
    postalCode: '4655',
    city: 'Aggsbach'
  },
  {
    postalCode: '4655',
    city: 'Albenedt'
  },
  {
    postalCode: '4655',
    city: 'Berg'
  },
  {
    postalCode: '4655',
    city: 'Eggenberg'
  },
  {
    postalCode: '4655',
    city: 'Eichham'
  },
  {
    postalCode: '4655',
    city: 'Einsiedling'
  },
  {
    postalCode: '4655',
    city: 'Falkenohren'
  },
  {
    postalCode: '4655',
    city: 'Feldham'
  },
  {
    postalCode: '4655',
    city: 'Fischböckau'
  },
  {
    postalCode: '4655',
    city: 'Heitzing'
  },
  {
    postalCode: '4655',
    city: 'Lederau'
  },
  {
    postalCode: '4655',
    city: 'Moos'
  },
  {
    postalCode: '4655',
    city: 'Mühltal'
  },
  {
    postalCode: '4655',
    city: 'Oberhörbach'
  },
  {
    postalCode: '4655',
    city: 'Pappelleiten'
  },
  {
    postalCode: '4655',
    city: 'Peintal'
  },
  {
    postalCode: '4655',
    city: 'Point'
  },
  {
    postalCode: '4655',
    city: 'Radhaming'
  },
  {
    postalCode: '4655',
    city: 'Schart'
  },
  {
    postalCode: '4655',
    city: 'Seyrkam'
  },
  {
    postalCode: '4655',
    city: 'Theuerwang'
  },
  {
    postalCode: '4655',
    city: 'Unterhörbach'
  },
  {
    postalCode: '4655',
    city: 'Ursprung'
  },
  {
    postalCode: '4655',
    city: 'Vorchdorf'
  },
  {
    postalCode: '4655',
    city: 'Weidach'
  },
  {
    postalCode: '4656',
    city: 'Danzlau'
  },
  {
    postalCode: '4656',
    city: 'Feichtenberg'
  },
  {
    postalCode: '4656',
    city: 'Kaltenmarkt'
  },
  {
    postalCode: '4656',
    city: 'Kampesberg'
  },
  {
    postalCode: '4656',
    city: 'Kirchham'
  },
  {
    postalCode: '4656',
    city: 'Kogl'
  },
  {
    postalCode: '4656',
    city: 'Krottendorf'
  },
  {
    postalCode: '4656',
    city: 'Wahl'
  },
  {
    postalCode: '4656',
    city: 'Bergham'
  },
  {
    postalCode: '4656',
    city: 'Edtholz'
  },
  {
    postalCode: '4656',
    city: 'Eisengattern'
  },
  {
    postalCode: '4656',
    city: 'Im Tal'
  },
  {
    postalCode: '4656',
    city: 'Guggenberg'
  },
  {
    postalCode: '4656',
    city: 'Hagenmühle'
  },
  {
    postalCode: '4656',
    city: 'Hilzing'
  },
  {
    postalCode: '4656',
    city: 'Hüttenboden'
  },
  {
    postalCode: '4656',
    city: 'In der Au'
  },
  {
    postalCode: '4656',
    city: 'Laizing'
  },
  {
    postalCode: '4656',
    city: 'Laudachtal'
  },
  {
    postalCode: '4656',
    city: 'Steg'
  },
  {
    postalCode: '4656',
    city: 'Windberg'
  },
  {
    postalCode: '4656',
    city: 'Kranabeth'
  },
  {
    postalCode: '4656',
    city: 'Oberndorf'
  },
  {
    postalCode: '4656',
    city: 'Ölling'
  },
  {
    postalCode: '4661',
    city: 'Watzing'
  },
  {
    postalCode: '4661',
    city: 'Außerpühret'
  },
  {
    postalCode: '4661',
    city: 'Außerroh'
  },
  {
    postalCode: '4661',
    city: 'Deising'
  },
  {
    postalCode: '4661',
    city: 'Innerroh'
  },
  {
    postalCode: '4661',
    city: 'Kemating'
  },
  {
    postalCode: '4661',
    city: 'Mitterbuch'
  },
  {
    postalCode: '4661',
    city: 'Oberbuch'
  },
  {
    postalCode: '4661',
    city: 'Palmsdorf'
  },
  {
    postalCode: '4661',
    city: 'Roitham'
  },
  {
    postalCode: '4661',
    city: 'Unterpühret'
  },
  {
    postalCode: '4661',
    city: 'Altmanning'
  },
  {
    postalCode: '4661',
    city: 'Au'
  },
  {
    postalCode: '4661',
    city: 'Auholz'
  },
  {
    postalCode: '4661',
    city: 'Bichlbauer'
  },
  {
    postalCode: '4661',
    city: 'Bühl'
  },
  {
    postalCode: '4661',
    city: 'Edt'
  },
  {
    postalCode: '4661',
    city: 'Edtmayer'
  },
  {
    postalCode: '4661',
    city: 'Kirnbach'
  },
  {
    postalCode: '4661',
    city: 'Lebl-Roith'
  },
  {
    postalCode: '4661',
    city: 'Magling'
  },
  {
    postalCode: '4661',
    city: 'Nöstling'
  },
  {
    postalCode: '4661',
    city: 'Sandgasse'
  },
  {
    postalCode: '4661',
    city: 'Stötten'
  },
  {
    postalCode: '4661',
    city: 'Vornbuch'
  },
  {
    postalCode: '4661',
    city: 'Wangham'
  },
  {
    postalCode: '4662',
    city: 'Stötten'
  },
  {
    postalCode: '4662',
    city: 'Kohlwehr'
  },
  {
    postalCode: '4662',
    city: 'Sandhäuslberg'
  },
  {
    postalCode: '4662',
    city: 'Ehrenfeld'
  },
  {
    postalCode: '4663',
    city: 'Diethaming'
  },
  {
    postalCode: '4663',
    city: 'Kirchham'
  },
  {
    postalCode: '4663',
    city: 'Kranabeth'
  },
  {
    postalCode: '4663',
    city: 'Laakirchen'
  },
  {
    postalCode: '4663',
    city: 'Lindach'
  },
  {
    postalCode: '4663',
    city: 'Oberweis'
  },
  {
    postalCode: '4663',
    city: 'Ölling'
  },
  {
    postalCode: '4663',
    city: 'Rahstorf'
  },
  {
    postalCode: '4663',
    city: 'Schweigthal'
  },
  {
    postalCode: '4663',
    city: 'Stötten'
  },
  {
    postalCode: '4664',
    city: 'Kranabeth'
  },
  {
    postalCode: '4664',
    city: 'Moosham'
  },
  {
    postalCode: '4664',
    city: 'Oberweis'
  },
  {
    postalCode: '4664',
    city: 'Laakirchen'
  },
  {
    postalCode: '4664',
    city: 'Oberndorf'
  },
  {
    postalCode: '4664',
    city: 'Schweigthal'
  },
  {
    postalCode: '4671',
    city: 'Voglhub'
  },
  {
    postalCode: '4671',
    city: 'Aichkirchen'
  },
  {
    postalCode: '4671',
    city: 'Brunngasse'
  },
  {
    postalCode: '4671',
    city: 'Eisgering'
  },
  {
    postalCode: '4671',
    city: 'Getzing'
  },
  {
    postalCode: '4671',
    city: 'Ingerendt'
  },
  {
    postalCode: '4671',
    city: 'Nopping'
  },
  {
    postalCode: '4671',
    city: 'Pisdorf'
  },
  {
    postalCode: '4671',
    city: 'Pitting'
  },
  {
    postalCode: '4671',
    city: 'Puch'
  },
  {
    postalCode: '4671',
    city: 'Rabenberg'
  },
  {
    postalCode: '4671',
    city: 'Roitfeld'
  },
  {
    postalCode: '4671',
    city: 'Stötten'
  },
  {
    postalCode: '4671',
    city: 'Gewerbepark Neukirchen bei Lambach'
  },
  {
    postalCode: '4671',
    city: 'Aming'
  },
  {
    postalCode: '4671',
    city: 'Dorf'
  },
  {
    postalCode: '4671',
    city: 'Hof'
  },
  {
    postalCode: '4671',
    city: 'Hofern'
  },
  {
    postalCode: '4671',
    city: 'Iming'
  },
  {
    postalCode: '4671',
    city: 'Löpperding'
  },
  {
    postalCode: '4671',
    city: 'Neukirchen bei Lambach'
  },
  {
    postalCode: '4671',
    city: 'Niederharrern'
  },
  {
    postalCode: '4671',
    city: 'Oberschwaig'
  },
  {
    postalCode: '4671',
    city: 'Schörgendorf'
  },
  {
    postalCode: '4671',
    city: 'Spöck'
  },
  {
    postalCode: '4671',
    city: 'Stroham'
  },
  {
    postalCode: '4671',
    city: 'Weinberg'
  },
  {
    postalCode: '4671',
    city: 'Willing'
  },
  {
    postalCode: '4672',
    city: 'Bachmanning'
  },
  {
    postalCode: '4672',
    city: 'Bachmannsberg'
  },
  {
    postalCode: '4672',
    city: 'Hundhagen'
  },
  {
    postalCode: '4672',
    city: 'Klind'
  },
  {
    postalCode: '4672',
    city: 'Krottendorf'
  },
  {
    postalCode: '4672',
    city: 'Oberseling'
  },
  {
    postalCode: '4672',
    city: 'Unterseling'
  },
  {
    postalCode: '4672',
    city: 'Weingarten'
  },
  {
    postalCode: '4672',
    city: 'Oberndorf'
  },
  {
    postalCode: '4672',
    city: 'Kohlgrub'
  },
  {
    postalCode: '4672',
    city: 'Pühret'
  },
  {
    postalCode: '4673',
    city: 'Aspoltsberg'
  },
  {
    postalCode: '4673',
    city: 'Bachhäuseln'
  },
  {
    postalCode: '4673',
    city: 'Baumgarting'
  },
  {
    postalCode: '4673',
    city: 'Bernhartsdorf'
  },
  {
    postalCode: '4673',
    city: 'Bugram'
  },
  {
    postalCode: '4673',
    city: 'Edt am Stömerberg'
  },
  {
    postalCode: '4673',
    city: 'Eggerding'
  },
  {
    postalCode: '4673',
    city: 'Fading'
  },
  {
    postalCode: '4673',
    city: 'Felling'
  },
  {
    postalCode: '4673',
    city: 'Föching'
  },
  {
    postalCode: '4673',
    city: 'Gaspoltshofen'
  },
  {
    postalCode: '4673',
    city: 'Gramberg'
  },
  {
    postalCode: '4673',
    city: 'Grub'
  },
  {
    postalCode: '4673',
    city: 'Hairedt'
  },
  {
    postalCode: '4673',
    city: 'Hinterleiten'
  },
  {
    postalCode: '4673',
    city: 'Höft'
  },
  {
    postalCode: '4673',
    city: 'Hörbach'
  },
  {
    postalCode: '4673',
    city: 'Hörmeting'
  },
  {
    postalCode: '4673',
    city: 'Hofing'
  },
  {
    postalCode: '4673',
    city: 'Hub'
  },
  {
    postalCode: '4673',
    city: 'Jeding'
  },
  {
    postalCode: '4673',
    city: 'Kirchdorf'
  },
  {
    postalCode: '4673',
    city: 'Kronleiten'
  },
  {
    postalCode: '4673',
    city: 'Leithen'
  },
  {
    postalCode: '4673',
    city: 'Mairhof'
  },
  {
    postalCode: '4673',
    city: 'Moos'
  },
  {
    postalCode: '4673',
    city: 'Niederbauern'
  },
  {
    postalCode: '4673',
    city: 'Obeltsham'
  },
  {
    postalCode: '4673',
    city: 'Oberbergham'
  },
  {
    postalCode: '4673',
    city: 'Obergrünbach'
  },
  {
    postalCode: '4673',
    city: 'Oberhöftberg'
  },
  {
    postalCode: '4673',
    city: 'Ohrenschall'
  },
  {
    postalCode: '4673',
    city: 'Seiring'
  },
  {
    postalCode: '4673',
    city: 'Sölliberg'
  },
  {
    postalCode: '4673',
    city: 'Unteraffnang'
  },
  {
    postalCode: '4673',
    city: 'Unterbergham'
  },
  {
    postalCode: '4673',
    city: 'Untergrünbach'
  },
  {
    postalCode: '4673',
    city: 'Unterhöftberg'
  },
  {
    postalCode: '4673',
    city: 'Watzing'
  },
  {
    postalCode: '4674',
    city: 'Altenhof am Hausruck'
  },
  {
    postalCode: '4674',
    city: 'Buchleiten'
  },
  {
    postalCode: '4674',
    city: 'Gröming'
  },
  {
    postalCode: '4674',
    city: 'Holzing'
  },
  {
    postalCode: '4674',
    city: 'Hueb'
  },
  {
    postalCode: '4674',
    city: 'Lenglach'
  },
  {
    postalCode: '4674',
    city: 'Mösenedt'
  },
  {
    postalCode: '4674',
    city: 'Mühlbach'
  },
  {
    postalCode: '4674',
    city: 'Oberaffnang'
  },
  {
    postalCode: '4674',
    city: 'Oberepfenhofen'
  },
  {
    postalCode: '4674',
    city: 'Obergmain'
  },
  {
    postalCode: '4674',
    city: 'Unterepfenhofen'
  },
  {
    postalCode: '4674',
    city: 'Untergmain'
  },
  {
    postalCode: '4674',
    city: 'Weinberg'
  },
  {
    postalCode: '4675',
    city: 'Schwarzschachen'
  },
  {
    postalCode: '4675',
    city: 'Auhäuseln'
  },
  {
    postalCode: '4675',
    city: 'Buch'
  },
  {
    postalCode: '4675',
    city: 'Dirisam'
  },
  {
    postalCode: '4675',
    city: 'Einberg'
  },
  {
    postalCode: '4675',
    city: 'Eitzenberg'
  },
  {
    postalCode: '4675',
    city: 'Fuchshub'
  },
  {
    postalCode: '4675',
    city: 'Grub'
  },
  {
    postalCode: '4675',
    city: 'Gründling'
  },
  {
    postalCode: '4675',
    city: 'Hofreith'
  },
  {
    postalCode: '4675',
    city: 'Niederndorf'
  },
  {
    postalCode: '4675',
    city: 'Ortmanau'
  },
  {
    postalCode: '4675',
    city: 'Pesendorf'
  },
  {
    postalCode: '4675',
    city: 'Schachenreith'
  },
  {
    postalCode: '4675',
    city: 'Schwarzgrub'
  },
  {
    postalCode: '4675',
    city: 'Seewiesen'
  },
  {
    postalCode: '4675',
    city: 'Stüblreith'
  },
  {
    postalCode: '4675',
    city: 'Trattnach'
  },
  {
    postalCode: '4675',
    city: 'Unterlehen'
  },
  {
    postalCode: '4675',
    city: 'Untermeggenbach'
  },
  {
    postalCode: '4675',
    city: 'Weibern'
  },
  {
    postalCode: '4676',
    city: 'Aistersheim'
  },
  {
    postalCode: '4676',
    city: 'Augassen'
  },
  {
    postalCode: '4676',
    city: 'Edt'
  },
  {
    postalCode: '4676',
    city: 'Grub'
  },
  {
    postalCode: '4676',
    city: 'Haid'
  },
  {
    postalCode: '4676',
    city: 'Haidenheim'
  },
  {
    postalCode: '4676',
    city: 'Himmelreich'
  },
  {
    postalCode: '4676',
    city: 'Kottingaistersheim'
  },
  {
    postalCode: '4676',
    city: 'Pöttenheim'
  },
  {
    postalCode: '4676',
    city: 'Rakesing'
  },
  {
    postalCode: '4676',
    city: 'Thalheim'
  },
  {
    postalCode: '4676',
    city: 'Viertlbach'
  },
  {
    postalCode: '4676',
    city: 'Mühlberg'
  },
  {
    postalCode: '4680',
    city: 'Hinteregg'
  },
  {
    postalCode: '4680',
    city: 'Kruglug'
  },
  {
    postalCode: '4680',
    city: 'Leiten'
  },
  {
    postalCode: '4680',
    city: 'Aubach'
  },
  {
    postalCode: '4680',
    city: 'Bachleiten'
  },
  {
    postalCode: '4680',
    city: 'Brunnberg'
  },
  {
    postalCode: '4680',
    city: 'Buchegg'
  },
  {
    postalCode: '4680',
    city: 'Ditting'
  },
  {
    postalCode: '4680',
    city: 'Dorf'
  },
  {
    postalCode: '4680',
    city: 'Eidenedt'
  },
  {
    postalCode: '4680',
    city: 'Geierau'
  },
  {
    postalCode: '4680',
    city: 'Gotthaming'
  },
  {
    postalCode: '4680',
    city: 'Haag am Hausruck'
  },
  {
    postalCode: '4680',
    city: 'Hatscheksiedlung'
  },
  {
    postalCode: '4680',
    city: 'Hundassing'
  },
  {
    postalCode: '4680',
    city: 'Kreuzerfeld'
  },
  {
    postalCode: '4680',
    city: 'Letten'
  },
  {
    postalCode: '4680',
    city: 'Lugendorf'
  },
  {
    postalCode: '4680',
    city: 'Luisenhöhe'
  },
  {
    postalCode: '4680',
    city: 'Manichgattern'
  },
  {
    postalCode: '4680',
    city: 'Niedernhaag'
  },
  {
    postalCode: '4680',
    city: 'Obermeggenbach'
  },
  {
    postalCode: '4680',
    city: 'Oberntor'
  },
  {
    postalCode: '4680',
    city: 'Pramwald'
  },
  {
    postalCode: '4680',
    city: 'Rampersdorf'
  },
  {
    postalCode: '4680',
    city: 'Reischau'
  },
  {
    postalCode: '4680',
    city: 'Starhemberg'
  },
  {
    postalCode: '4680',
    city: 'Steinpoint'
  },
  {
    postalCode: '4680',
    city: 'Hochfeld'
  },
  {
    postalCode: '4680',
    city: 'Grolzham'
  },
  {
    postalCode: '4681',
    city: 'Frei'
  },
  {
    postalCode: '4681',
    city: 'Großwaldenberg'
  },
  {
    postalCode: '4681',
    city: 'Höbeting'
  },
  {
    postalCode: '4681',
    city: 'Holzhäuseln'
  },
  {
    postalCode: '4681',
    city: 'Innernsee'
  },
  {
    postalCode: '4681',
    city: 'Kleinwaldenberg'
  },
  {
    postalCode: '4681',
    city: 'Lamperstorf'
  },
  {
    postalCode: '4681',
    city: 'Mösenpoint'
  },
  {
    postalCode: '4681',
    city: 'Mühllehen'
  },
  {
    postalCode: '4681',
    city: 'Parz'
  },
  {
    postalCode: '4681',
    city: 'Pommersberg'
  },
  {
    postalCode: '4681',
    city: 'Poppenreith'
  },
  {
    postalCode: '4681',
    city: 'Rottenbach'
  },
  {
    postalCode: '4681',
    city: 'Schachet'
  },
  {
    postalCode: '4681',
    city: 'Stötten'
  },
  {
    postalCode: '4681',
    city: 'Watzing'
  },
  {
    postalCode: '4681',
    city: 'Weeg'
  },
  {
    postalCode: '4681',
    city: 'Winkling'
  },
  {
    postalCode: '4682',
    city: 'Buchleiten'
  },
  {
    postalCode: '4682',
    city: 'Aigen'
  },
  {
    postalCode: '4682',
    city: 'Arming'
  },
  {
    postalCode: '4682',
    city: 'Bergham'
  },
  {
    postalCode: '4682',
    city: 'Brunau'
  },
  {
    postalCode: '4682',
    city: 'Buchet'
  },
  {
    postalCode: '4682',
    city: 'Erlet'
  },
  {
    postalCode: '4682',
    city: 'Geboltskirchen'
  },
  {
    postalCode: '4682',
    city: 'Gschwendt'
  },
  {
    postalCode: '4682',
    city: 'Holzhäuseln'
  },
  {
    postalCode: '4682',
    city: 'Langau'
  },
  {
    postalCode: '4682',
    city: 'Leithen'
  },
  {
    postalCode: '4682',
    city: 'Lucka'
  },
  {
    postalCode: '4682',
    city: 'Marschalling'
  },
  {
    postalCode: '4682',
    city: 'Niederentern'
  },
  {
    postalCode: '4682',
    city: 'Oberentern'
  },
  {
    postalCode: '4682',
    city: 'Odelboding'
  },
  {
    postalCode: '4682',
    city: 'Piesing'
  },
  {
    postalCode: '4682',
    city: 'Polzing'
  },
  {
    postalCode: '4682',
    city: 'Reitting'
  },
  {
    postalCode: '4682',
    city: 'Roßwald'
  },
  {
    postalCode: '4682',
    city: 'Scheiben'
  },
  {
    postalCode: '4682',
    city: 'Stein'
  },
  {
    postalCode: '4682',
    city: 'Thalham'
  },
  {
    postalCode: '4682',
    city: 'Trattnach'
  },
  {
    postalCode: '4682',
    city: 'Traunhof'
  },
  {
    postalCode: '4682',
    city: 'Wiesing'
  },
  {
    postalCode: '4682',
    city: 'Wilding'
  },
  {
    postalCode: '4682',
    city: 'Zeißerding'
  },
  {
    postalCode: '4690',
    city: 'Oberholzham'
  },
  {
    postalCode: '4690',
    city: 'Windham'
  },
  {
    postalCode: '4690',
    city: 'Edt'
  },
  {
    postalCode: '4690',
    city: 'Kaiseredt'
  },
  {
    postalCode: '4690',
    city: 'Kochlöffling'
  },
  {
    postalCode: '4690',
    city: 'Lebertsham'
  },
  {
    postalCode: '4690',
    city: 'Niederholzham'
  },
  {
    postalCode: '4690',
    city: 'Oberndorf'
  },
  {
    postalCode: '4690',
    city: 'Winkl'
  },
  {
    postalCode: '4690',
    city: 'Aich'
  },
  {
    postalCode: '4690',
    city: 'Höck'
  },
  {
    postalCode: '4690',
    city: 'Litzing'
  },
  {
    postalCode: '4690',
    city: 'Pitzenberg'
  },
  {
    postalCode: '4690',
    city: 'Pitzenbergholz'
  },
  {
    postalCode: '4690',
    city: 'Ennsberg'
  },
  {
    postalCode: '4690',
    city: 'Ebersäuln'
  },
  {
    postalCode: '4690',
    city: 'Eglau'
  },
  {
    postalCode: '4690',
    city: 'Glatzing'
  },
  {
    postalCode: '4690',
    city: 'Hart'
  },
  {
    postalCode: '4690',
    city: 'Hof'
  },
  {
    postalCode: '4690',
    city: 'Johannisthal'
  },
  {
    postalCode: '4690',
    city: 'Kaufing'
  },
  {
    postalCode: '4690',
    city: 'Kreut'
  },
  {
    postalCode: '4690',
    city: 'Mitterberg'
  },
  {
    postalCode: '4690',
    city: 'Mitterbergholz'
  },
  {
    postalCode: '4690',
    city: 'Mühlwang'
  },
  {
    postalCode: '4690',
    city: 'Neudorf'
  },
  {
    postalCode: '4690',
    city: 'Pfaffenberg'
  },
  {
    postalCode: '4690',
    city: 'Rüstorf'
  },
  {
    postalCode: '4690',
    city: 'Anzental'
  },
  {
    postalCode: '4690',
    city: 'Bach'
  },
  {
    postalCode: '4690',
    city: 'Bergern'
  },
  {
    postalCode: '4690',
    city: 'Kirchdorf'
  },
  {
    postalCode: '4690',
    city: 'Mühlparz'
  },
  {
    postalCode: '4690',
    city: 'Pichl'
  },
  {
    postalCode: '4690',
    city: 'Rutzenham'
  },
  {
    postalCode: '4690',
    city: 'Philippsberg'
  },
  {
    postalCode: '4690',
    city: 'Staig'
  },
  {
    postalCode: '4690',
    city: 'Römerberg'
  },
  {
    postalCode: '4690',
    city: 'Schwanenstadt'
  },
  {
    postalCode: '4690',
    city: 'Stadl-Hausruck'
  },
  {
    postalCode: '4691',
    city: 'Weikharting'
  },
  {
    postalCode: '4691',
    city: 'Apeding'
  },
  {
    postalCode: '4691',
    city: 'Asperding'
  },
  {
    postalCode: '4691',
    city: 'Breitenschützing'
  },
  {
    postalCode: '4691',
    city: 'Herrenschützing'
  },
  {
    postalCode: '4691',
    city: 'Hinterschützing'
  },
  {
    postalCode: '4691',
    city: 'Hörweg'
  },
  {
    postalCode: '4691',
    city: 'Oberharrern'
  },
  {
    postalCode: '4691',
    city: 'Schlatt'
  },
  {
    postalCode: '4691',
    city: 'Römerberg'
  },
  {
    postalCode: '4692',
    city: 'Kroißbach'
  },
  {
    postalCode: '4692',
    city: 'Salfing'
  },
  {
    postalCode: '4692',
    city: 'Bergham'
  },
  {
    postalCode: '4692',
    city: 'Hainbach'
  },
  {
    postalCode: '4692',
    city: 'Hehenberg'
  },
  {
    postalCode: '4692',
    city: 'Iming'
  },
  {
    postalCode: '4692',
    city: 'Kaiting'
  },
  {
    postalCode: '4692',
    city: 'Koppl'
  },
  {
    postalCode: '4692',
    city: 'Laah'
  },
  {
    postalCode: '4692',
    city: 'Moos'
  },
  {
    postalCode: '4692',
    city: 'Niederau'
  },
  {
    postalCode: '4692',
    city: 'Niederthalheim'
  },
  {
    postalCode: '4692',
    city: 'Oberau'
  },
  {
    postalCode: '4692',
    city: 'Obersteindlberg'
  },
  {
    postalCode: '4692',
    city: 'Penetzdorf'
  },
  {
    postalCode: '4692',
    city: 'Pengering'
  },
  {
    postalCode: '4692',
    city: 'Rankar'
  },
  {
    postalCode: '4692',
    city: 'Untersteindlberg'
  },
  {
    postalCode: '4692',
    city: 'Viert'
  },
  {
    postalCode: '4692',
    city: 'Windham'
  },
  {
    postalCode: '4692',
    city: 'Wufing'
  },
  {
    postalCode: '4693',
    city: 'Berg'
  },
  {
    postalCode: '4693',
    city: 'Brauching'
  },
  {
    postalCode: '4693',
    city: 'Bubenland'
  },
  {
    postalCode: '4693',
    city: 'Desselbrunn'
  },
  {
    postalCode: '4693',
    city: 'Deutenham'
  },
  {
    postalCode: '4693',
    city: 'Fallholz'
  },
  {
    postalCode: '4693',
    city: 'Feldham'
  },
  {
    postalCode: '4693',
    city: 'Haus'
  },
  {
    postalCode: '4693',
    city: 'Hub'
  },
  {
    postalCode: '4693',
    city: 'Oberhaidach'
  },
  {
    postalCode: '4693',
    city: 'Sicking'
  },
  {
    postalCode: '4693',
    city: 'Traunwang'
  },
  {
    postalCode: '4693',
    city: 'Unterhaidach'
  },
  {
    postalCode: '4693',
    city: 'Viecht'
  },
  {
    postalCode: '4693',
    city: 'Windern'
  },
  {
    postalCode: '4693',
    city: 'Edt'
  },
  {
    postalCode: '4693',
    city: 'Hofstätten'
  },
  {
    postalCode: '4693',
    city: 'Penesdorf'
  },
  {
    postalCode: '4693',
    city: 'Buchleiten'
  },
  {
    postalCode: '4693',
    city: 'Kreut'
  },
  {
    postalCode: '4693',
    city: 'Roith'
  },
  {
    postalCode: '4694',
    city: 'Aichlham'
  },
  {
    postalCode: '4694',
    city: 'Aupointen'
  },
  {
    postalCode: '4694',
    city: 'Edlach'
  },
  {
    postalCode: '4694',
    city: 'Edt'
  },
  {
    postalCode: '4694',
    city: 'Ehrendorf'
  },
  {
    postalCode: '4694',
    city: 'Föding'
  },
  {
    postalCode: '4694',
    city: 'Großreith'
  },
  {
    postalCode: '4694',
    city: 'Hafendorf'
  },
  {
    postalCode: '4694',
    city: 'Hildprechting'
  },
  {
    postalCode: '4694',
    city: 'Hochbau'
  },
  {
    postalCode: '4694',
    city: 'Hochleithen'
  },
  {
    postalCode: '4694',
    city: 'Holzhäuseln'
  },
  {
    postalCode: '4694',
    city: 'Irresberg'
  },
  {
    postalCode: '4694',
    city: 'Kleinreith'
  },
  {
    postalCode: '4694',
    city: 'Obernathal'
  },
  {
    postalCode: '4694',
    city: 'Oberthalham'
  },
  {
    postalCode: '4694',
    city: 'Ohlsdorf'
  },
  {
    postalCode: '4694',
    city: 'Parz'
  },
  {
    postalCode: '4694',
    city: 'Peiskam'
  },
  {
    postalCode: '4694',
    city: 'Ruhsam'
  },
  {
    postalCode: '4694',
    city: 'Traich'
  },
  {
    postalCode: '4694',
    city: 'Unternathal'
  },
  {
    postalCode: '4694',
    city: 'Unterthalham'
  },
  {
    postalCode: '4694',
    city: 'Weinberg'
  },
  {
    postalCode: '4701',
    city: 'Bad Schallerbach'
  },
  {
    postalCode: '4701',
    city: 'Gebersdorf'
  },
  {
    postalCode: '4701',
    city: 'Schönau'
  },
  {
    postalCode: '4701',
    city: 'Müllerberg'
  },
  {
    postalCode: '4702',
    city: 'Bad Schallerbach'
  },
  {
    postalCode: '4702',
    city: 'Hungerberg'
  },
  {
    postalCode: '4702',
    city: 'Malling'
  },
  {
    postalCode: '4702',
    city: 'Bergern'
  },
  {
    postalCode: '4702',
    city: 'Breitwiesen'
  },
  {
    postalCode: '4702',
    city: 'Edlgassen'
  },
  {
    postalCode: '4702',
    city: 'Furth'
  },
  {
    postalCode: '4702',
    city: 'Grub'
  },
  {
    postalCode: '4702',
    city: 'Hilling'
  },
  {
    postalCode: '4702',
    city: 'Holz'
  },
  {
    postalCode: '4702',
    city: 'Holzhäuser'
  },
  {
    postalCode: '4702',
    city: 'Kitzing'
  },
  {
    postalCode: '4702',
    city: 'Mauer'
  },
  {
    postalCode: '4702',
    city: 'Parzham'
  },
  {
    postalCode: '4702',
    city: 'Uttenthal'
  },
  {
    postalCode: '4702',
    city: 'Wallern an der Trattnach'
  },
  {
    postalCode: '4702',
    city: 'Weghof'
  },
  {
    postalCode: '4702',
    city: 'Winkeln'
  },
  {
    postalCode: '4707',
    city: 'Grieskirchen'
  },
  {
    postalCode: '4707',
    city: 'Hiering'
  },
  {
    postalCode: '4707',
    city: 'Alte Rosenau'
  },
  {
    postalCode: '4707',
    city: 'Am Kröpflmühlerberg'
  },
  {
    postalCode: '4707',
    city: 'Anzenberg'
  },
  {
    postalCode: '4707',
    city: 'Buchet'
  },
  {
    postalCode: '4707',
    city: 'Aigendorf'
  },
  {
    postalCode: '4707',
    city: 'Atschenbach'
  },
  {
    postalCode: '4707',
    city: 'Au'
  },
  {
    postalCode: '4707',
    city: 'Brandhof'
  },
  {
    postalCode: '4707',
    city: 'Dingbach'
  },
  {
    postalCode: '4707',
    city: 'Fürth'
  },
  {
    postalCode: '4707',
    city: 'Haid'
  },
  {
    postalCode: '4707',
    city: 'Hiererberg'
  },
  {
    postalCode: '4707',
    city: 'Kehrbach'
  },
  {
    postalCode: '4707',
    city: 'Kochlöffleck'
  },
  {
    postalCode: '4707',
    city: 'Kumpfhub'
  },
  {
    postalCode: '4707',
    city: 'Margarethen'
  },
  {
    postalCode: '4707',
    city: 'Mitterndorf'
  },
  {
    postalCode: '4707',
    city: 'Niederndorf'
  },
  {
    postalCode: '4707',
    city: 'Parz'
  },
  {
    postalCode: '4707',
    city: 'Pühret'
  },
  {
    postalCode: '4707',
    city: 'Rosenau'
  },
  {
    postalCode: '4707',
    city: 'Schaffenberg'
  },
  {
    postalCode: '4707',
    city: 'Schlüßlberg'
  },
  {
    postalCode: '4707',
    city: 'Schnölzenberg'
  },
  {
    postalCode: '4707',
    city: 'Tegernbach'
  },
  {
    postalCode: '4707',
    city: 'Thal'
  },
  {
    postalCode: '4707',
    city: 'Trattenegg'
  },
  {
    postalCode: '4707',
    city: 'Unternberg'
  },
  {
    postalCode: '4707',
    city: 'Weinberg'
  },
  {
    postalCode: '4707',
    city: 'Wintersberg'
  },
  {
    postalCode: '4707',
    city: 'Fischleithen'
  },
  {
    postalCode: '4707',
    city: 'Gewerbepark'
  },
  {
    postalCode: '4707',
    city: 'Handelspark'
  },
  {
    postalCode: '4707',
    city: 'Kröpflmühle'
  },
  {
    postalCode: '4707',
    city: 'Oberschaffenberg'
  },
  {
    postalCode: '4707',
    city: 'Sonnwies'
  },
  {
    postalCode: '4707',
    city: 'Straßfeld'
  },
  {
    postalCode: '4710',
    city: 'Tolleterau'
  },
  {
    postalCode: '4710',
    city: 'Grieskirchen'
  },
  {
    postalCode: '4710',
    city: 'Hiering'
  },
  {
    postalCode: '4710',
    city: 'Kickendorf'
  },
  {
    postalCode: '4710',
    city: 'Moosham'
  },
  {
    postalCode: '4710',
    city: 'Parz'
  },
  {
    postalCode: '4710',
    city: 'Paschallern'
  },
  {
    postalCode: '4710',
    city: 'Steindlberg'
  },
  {
    postalCode: '4710',
    city: 'Unternberg'
  },
  {
    postalCode: '4710',
    city: 'Untersteinbach'
  },
  {
    postalCode: '4710',
    city: 'Aigen'
  },
  {
    postalCode: '4710',
    city: 'Edt'
  },
  {
    postalCode: '4710',
    city: 'Egg'
  },
  {
    postalCode: '4710',
    city: 'Forsthof'
  },
  {
    postalCode: '4710',
    city: 'Hainbuch'
  },
  {
    postalCode: '4710',
    city: 'Hornesberg'
  },
  {
    postalCode: '4710',
    city: 'Kaltenbach'
  },
  {
    postalCode: '4710',
    city: 'Kolbing'
  },
  {
    postalCode: '4710',
    city: 'Pollham'
  },
  {
    postalCode: '4710',
    city: 'Pollhamerwald'
  },
  {
    postalCode: '4710',
    city: 'Schmidgraben'
  },
  {
    postalCode: '4710',
    city: 'Wackersbuch'
  },
  {
    postalCode: '4710',
    city: 'Wimm'
  },
  {
    postalCode: '4710',
    city: 'Straßfeld'
  },
  {
    postalCode: '4710',
    city: 'Jörgerberg'
  },
  {
    postalCode: '4710',
    city: 'Grub'
  },
  {
    postalCode: '4710',
    city: 'Maximilian'
  },
  {
    postalCode: '4710',
    city: 'Niederweng'
  },
  {
    postalCode: '4710',
    city: 'Obersteinbach'
  },
  {
    postalCode: '4710',
    city: 'Sankt Georgen bei Grieskirchen'
  },
  {
    postalCode: '4710',
    city: 'Schwabegg'
  },
  {
    postalCode: '4710',
    city: 'Stritzing'
  },
  {
    postalCode: '4710',
    city: 'Vierhausen'
  },
  {
    postalCode: '4710',
    city: 'Ferdinand-Huber-Siedlung'
  },
  {
    postalCode: '4710',
    city: 'Lahof'
  },
  {
    postalCode: '4710',
    city: 'Kroisbach'
  },
  {
    postalCode: '4710',
    city: 'Oberwödling'
  },
  {
    postalCode: '4710',
    city: 'Stein'
  },
  {
    postalCode: '4710',
    city: 'Tollet'
  },
  {
    postalCode: '4710',
    city: 'Winkeln'
  },
  {
    postalCode: '4710',
    city: 'Neuwies'
  },
  {
    postalCode: '4712',
    city: 'Aichet bei Grub'
  },
  {
    postalCode: '4712',
    city: 'Aichet bei Kiesenberg'
  },
  {
    postalCode: '4712',
    city: 'Armau'
  },
  {
    postalCode: '4712',
    city: 'Gaisedt'
  },
  {
    postalCode: '4712',
    city: 'Grub'
  },
  {
    postalCode: '4712',
    city: 'Haid'
  },
  {
    postalCode: '4712',
    city: 'Haus'
  },
  {
    postalCode: '4712',
    city: 'Hilpetsberg'
  },
  {
    postalCode: '4712',
    city: 'Holzing'
  },
  {
    postalCode: '4712',
    city: 'Kiesenberg'
  },
  {
    postalCode: '4712',
    city: 'Krumbach'
  },
  {
    postalCode: '4712',
    city: 'Mairdoppl'
  },
  {
    postalCode: '4712',
    city: 'Michaelnbach'
  },
  {
    postalCode: '4712',
    city: 'Minithal'
  },
  {
    postalCode: '4712',
    city: 'Niederwödling'
  },
  {
    postalCode: '4712',
    city: 'Oberfurth'
  },
  {
    postalCode: '4712',
    city: 'Oberreitbach'
  },
  {
    postalCode: '4712',
    city: 'Oberspaching'
  },
  {
    postalCode: '4712',
    city: 'Pollesbach'
  },
  {
    postalCode: '4712',
    city: 'Reichenau'
  },
  {
    postalCode: '4712',
    city: 'Schappenedt'
  },
  {
    postalCode: '4712',
    city: 'Schickenedt'
  },
  {
    postalCode: '4712',
    city: 'Schmidgraben'
  },
  {
    postalCode: '4712',
    city: 'Schölmlahn'
  },
  {
    postalCode: '4712',
    city: 'Seiblberg'
  },
  {
    postalCode: '4712',
    city: 'Spöck'
  },
  {
    postalCode: '4712',
    city: 'Stefansdorf'
  },
  {
    postalCode: '4712',
    city: 'Stockedt'
  },
  {
    postalCode: '4712',
    city: 'Unterfurth'
  },
  {
    postalCode: '4712',
    city: 'Unterreitbach'
  },
  {
    postalCode: '4712',
    city: 'Weiking'
  },
  {
    postalCode: '4712',
    city: 'Zelli'
  },
  {
    postalCode: '4712',
    city: 'Brunnenweg'
  },
  {
    postalCode: '4712',
    city: 'Niederndorf'
  },
  {
    postalCode: '4713',
    city: 'Enzendorf'
  },
  {
    postalCode: '4713',
    city: 'Gallspach'
  },
  {
    postalCode: '4713',
    city: 'Gferedt'
  },
  {
    postalCode: '4713',
    city: 'Niederndorf'
  },
  {
    postalCode: '4713',
    city: 'Schützendorf'
  },
  {
    postalCode: '4713',
    city: 'Thall'
  },
  {
    postalCode: '4713',
    city: 'Thongraben'
  },
  {
    postalCode: '4713',
    city: 'Vornwald'
  },
  {
    postalCode: '4713',
    city: 'Wies'
  },
  {
    postalCode: '4713',
    city: 'Diesting'
  },
  {
    postalCode: '4713',
    city: 'Vierhausen'
  },
  {
    postalCode: '4714',
    city: 'Moos'
  },
  {
    postalCode: '4714',
    city: 'Breinroith'
  },
  {
    postalCode: '4714',
    city: 'Breitwies'
  },
  {
    postalCode: '4714',
    city: 'Bruckhof'
  },
  {
    postalCode: '4714',
    city: 'Egg'
  },
  {
    postalCode: '4714',
    city: 'Erlet'
  },
  {
    postalCode: '4714',
    city: 'Etnischberg'
  },
  {
    postalCode: '4714',
    city: 'Felling'
  },
  {
    postalCode: '4714',
    city: 'Freinberg'
  },
  {
    postalCode: '4714',
    city: 'Gferet'
  },
  {
    postalCode: '4714',
    city: 'Hart'
  },
  {
    postalCode: '4714',
    city: 'Hirm'
  },
  {
    postalCode: '4714',
    city: 'Holzackern'
  },
  {
    postalCode: '4714',
    city: 'Holzhäuseln bei Hart'
  },
  {
    postalCode: '4714',
    city: 'Holzhäuseln bei Wilhelmsberg'
  },
  {
    postalCode: '4714',
    city: 'Inn'
  },
  {
    postalCode: '4714',
    city: 'Kirchberg'
  },
  {
    postalCode: '4714',
    city: 'Kröstlinghof'
  },
  {
    postalCode: '4714',
    city: 'Langdorf'
  },
  {
    postalCode: '4714',
    city: 'Meggenhofen'
  },
  {
    postalCode: '4714',
    city: 'Niederbuch'
  },
  {
    postalCode: '4714',
    city: 'Niederetnisch'
  },
  {
    postalCode: '4714',
    city: 'Oberetnisch'
  },
  {
    postalCode: '4714',
    city: 'Obergallspach'
  },
  {
    postalCode: '4714',
    city: 'Oberndorf'
  },
  {
    postalCode: '4714',
    city: 'Pfarrhofsberg'
  },
  {
    postalCode: '4714',
    city: 'Radhof'
  },
  {
    postalCode: '4714',
    city: 'Rahof'
  },
  {
    postalCode: '4714',
    city: 'Roitham'
  },
  {
    postalCode: '4714',
    city: 'Schlatt'
  },
  {
    postalCode: '4714',
    city: 'Straß'
  },
  {
    postalCode: '4714',
    city: 'Trappenhof'
  },
  {
    postalCode: '4714',
    city: 'Vornbuch'
  },
  {
    postalCode: '4714',
    city: 'Wald'
  },
  {
    postalCode: '4714',
    city: 'Wilhelmsberg'
  },
  {
    postalCode: '4714',
    city: 'Wimm'
  },
  {
    postalCode: '4714',
    city: 'Zwisl'
  },
  {
    postalCode: '4714',
    city: 'Linet'
  },
  {
    postalCode: '4715',
    city: 'Niedertrattnach'
  },
  {
    postalCode: '4715',
    city: 'Stockwies'
  },
  {
    postalCode: '4715',
    city: 'Weidenau'
  },
  {
    postalCode: '4715',
    city: 'Adrischendorf'
  },
  {
    postalCode: '4715',
    city: 'Aich'
  },
  {
    postalCode: '4715',
    city: 'Aichet'
  },
  {
    postalCode: '4715',
    city: 'Altenhof'
  },
  {
    postalCode: '4715',
    city: 'Brandstetten'
  },
  {
    postalCode: '4715',
    city: 'Damberg'
  },
  {
    postalCode: '4715',
    city: 'Dietensam'
  },
  {
    postalCode: '4715',
    city: 'Eiblhub'
  },
  {
    postalCode: '4715',
    city: 'Erb'
  },
  {
    postalCode: '4715',
    city: 'Fellhof'
  },
  {
    postalCode: '4715',
    city: 'Fürstling'
  },
  {
    postalCode: '4715',
    city: 'Gries'
  },
  {
    postalCode: '4715',
    city: 'Grubhof'
  },
  {
    postalCode: '4715',
    city: 'Gstötten'
  },
  {
    postalCode: '4715',
    city: 'Haslau'
  },
  {
    postalCode: '4715',
    city: 'Hehenberg'
  },
  {
    postalCode: '4715',
    city: 'Helmling'
  },
  {
    postalCode: '4715',
    city: 'Hofmaning'
  },
  {
    postalCode: '4715',
    city: 'Holz'
  },
  {
    postalCode: '4715',
    city: 'Keneding'
  },
  {
    postalCode: '4715',
    city: 'Korntnerberg'
  },
  {
    postalCode: '4715',
    city: 'Mödlbach'
  },
  {
    postalCode: '4715',
    city: 'Oberolzing'
  },
  {
    postalCode: '4715',
    city: 'Obertrattbach'
  },
  {
    postalCode: '4715',
    city: 'Obertrattnach'
  },
  {
    postalCode: '4715',
    city: 'Odlboding'
  },
  {
    postalCode: '4715',
    city: 'Ragering'
  },
  {
    postalCode: '4715',
    city: 'Reischau'
  },
  {
    postalCode: '4715',
    city: 'Roith'
  },
  {
    postalCode: '4715',
    city: 'Taufkirchen an der Trattnach'
  },
  {
    postalCode: '4715',
    city: 'Unterolzing'
  },
  {
    postalCode: '4715',
    city: 'Untertrattbach'
  },
  {
    postalCode: '4715',
    city: 'Vatersam'
  },
  {
    postalCode: '4715',
    city: 'Widldorf'
  },
  {
    postalCode: '4715',
    city: 'Winkl'
  },
  {
    postalCode: '4715',
    city: 'Unterstetten'
  },
  {
    postalCode: '4715',
    city: 'Schröttenham'
  },
  {
    postalCode: '4715',
    city: 'Stadlberg'
  },
  {
    postalCode: '4716',
    city: 'Sommerfeld'
  },
  {
    postalCode: '4716',
    city: 'Aigen'
  },
  {
    postalCode: '4716',
    city: 'Brunham'
  },
  {
    postalCode: '4716',
    city: 'Gassen'
  },
  {
    postalCode: '4716',
    city: 'Hof'
  },
  {
    postalCode: '4716',
    city: 'Hofkirchen an der Trattnach'
  },
  {
    postalCode: '4716',
    city: 'Jungroith'
  },
  {
    postalCode: '4716',
    city: 'Panbruck'
  },
  {
    postalCode: '4716',
    city: 'Pichl'
  },
  {
    postalCode: '4716',
    city: 'Regnersdorf'
  },
  {
    postalCode: '4716',
    city: 'Ruhringsdorf'
  },
  {
    postalCode: '4716',
    city: 'Schallbach'
  },
  {
    postalCode: '4716',
    city: 'Sinzing'
  },
  {
    postalCode: '4716',
    city: 'Strötting'
  },
  {
    postalCode: '4716',
    city: 'Weng'
  },
  {
    postalCode: '4720',
    city: 'Eckartsroith'
  },
  {
    postalCode: '4720',
    city: 'Holzleithen'
  },
  {
    postalCode: '4720',
    city: 'Mittereibach'
  },
  {
    postalCode: '4720',
    city: 'Oberndorf'
  },
  {
    postalCode: '4720',
    city: 'Prambeckenhof'
  },
  {
    postalCode: '4720',
    city: 'Staureth'
  },
  {
    postalCode: '4720',
    city: 'Untereibach'
  },
  {
    postalCode: '4720',
    city: 'Usting'
  },
  {
    postalCode: '4720',
    city: 'Aigen'
  },
  {
    postalCode: '4720',
    city: 'Aschau'
  },
  {
    postalCode: '4720',
    city: 'Aspeth'
  },
  {
    postalCode: '4720',
    city: 'Au'
  },
  {
    postalCode: '4720',
    city: 'Auing'
  },
  {
    postalCode: '4720',
    city: 'Baumgarten'
  },
  {
    postalCode: '4720',
    city: 'Birnsteig'
  },
  {
    postalCode: '4720',
    city: 'Ebergassen'
  },
  {
    postalCode: '4720',
    city: 'Endt'
  },
  {
    postalCode: '4720',
    city: 'Erlach'
  },
  {
    postalCode: '4720',
    city: 'Flenkengrub'
  },
  {
    postalCode: '4720',
    city: 'Frauenhub'
  },
  {
    postalCode: '4720',
    city: 'Geßwagen'
  },
  {
    postalCode: '4720',
    city: 'Güttling'
  },
  {
    postalCode: '4720',
    city: 'Hading'
  },
  {
    postalCode: '4720',
    city: 'Häuseln am Holz'
  },
  {
    postalCode: '4720',
    city: 'Henning'
  },
  {
    postalCode: '4720',
    city: 'Hof'
  },
  {
    postalCode: '4720',
    city: 'Holzhäuseln'
  },
  {
    postalCode: '4720',
    city: 'Itzling'
  },
  {
    postalCode: '4720',
    city: 'Kainzing'
  },
  {
    postalCode: '4720',
    city: 'Kallham'
  },
  {
    postalCode: '4720',
    city: 'Kallhamerdorf'
  },
  {
    postalCode: '4720',
    city: 'Kimpling'
  },
  {
    postalCode: '4720',
    city: 'Kirchbach'
  },
  {
    postalCode: '4720',
    city: 'Lehen'
  },
  {
    postalCode: '4720',
    city: 'Mairhof'
  },
  {
    postalCode: '4720',
    city: 'Niederleithen'
  },
  {
    postalCode: '4720',
    city: 'Obereibach'
  },
  {
    postalCode: '4720',
    city: 'Obernfurth'
  },
  {
    postalCode: '4720',
    city: 'Oberrühringsdorf'
  },
  {
    postalCode: '4720',
    city: 'Obersameting'
  },
  {
    postalCode: '4720',
    city: 'Parzleithen'
  },
  {
    postalCode: '4720',
    city: 'Pauzenberg'
  },
  {
    postalCode: '4720',
    city: 'Pehring'
  },
  {
    postalCode: '4720',
    city: 'Penzing'
  },
  {
    postalCode: '4720',
    city: 'Poing'
  },
  {
    postalCode: '4720',
    city: 'Puchet'
  },
  {
    postalCode: '4720',
    city: 'Schildorf'
  },
  {
    postalCode: '4720',
    city: 'Sixberg'
  },
  {
    postalCode: '4720',
    city: 'Stockham'
  },
  {
    postalCode: '4720',
    city: 'Unternfurth'
  },
  {
    postalCode: '4720',
    city: 'Unterrühringsdorf'
  },
  {
    postalCode: '4720',
    city: 'Untersameting'
  },
  {
    postalCode: '4720',
    city: 'Wachling'
  },
  {
    postalCode: '4720',
    city: 'Weireth'
  },
  {
    postalCode: '4720',
    city: 'Wies'
  },
  {
    postalCode: '4720',
    city: 'Wiesing'
  },
  {
    postalCode: '4720',
    city: 'Würzberg'
  },
  {
    postalCode: '4720',
    city: 'Mairdoppl'
  },
  {
    postalCode: '4720',
    city: 'Kledt'
  },
  {
    postalCode: '4720',
    city: 'Neumarkt im Hausruckkreis'
  },
  {
    postalCode: '4720',
    city: 'Albrechtsberg'
  },
  {
    postalCode: '4720',
    city: 'Dürrnaschach'
  },
  {
    postalCode: '4720',
    city: 'Kronlach'
  },
  {
    postalCode: '4720',
    city: 'Moos'
  },
  {
    postalCode: '4720',
    city: 'Oberaschach'
  },
  {
    postalCode: '4720',
    city: 'Obernfürth'
  },
  {
    postalCode: '4720',
    city: 'Pötting'
  },
  {
    postalCode: '4720',
    city: 'Rumpfendopl'
  },
  {
    postalCode: '4720',
    city: 'Spielmannsberg'
  },
  {
    postalCode: '4720',
    city: 'Straßhof'
  },
  {
    postalCode: '4720',
    city: 'Sumeding'
  },
  {
    postalCode: '4720',
    city: 'Unternfürth'
  },
  {
    postalCode: '4720',
    city: 'Oberhof'
  },
  {
    postalCode: '4721',
    city: 'Altenseng'
  },
  {
    postalCode: '4721',
    city: 'Altschwendt'
  },
  {
    postalCode: '4721',
    city: 'Danrath'
  },
  {
    postalCode: '4721',
    city: 'Fasthub'
  },
  {
    postalCode: '4721',
    city: 'Hausmanning'
  },
  {
    postalCode: '4721',
    city: 'Putzenbach'
  },
  {
    postalCode: '4721',
    city: 'Rien'
  },
  {
    postalCode: '4721',
    city: 'Rödham'
  },
  {
    postalCode: '4721',
    city: 'Urleinsberg'
  },
  {
    postalCode: '4721',
    city: 'Wohlmarch'
  },
  {
    postalCode: '4721',
    city: 'Breitau'
  },
  {
    postalCode: '4721',
    city: 'Oberspitzling'
  },
  {
    postalCode: '4721',
    city: 'Ungering'
  },
  {
    postalCode: '4721',
    city: 'Holzedt'
  },
  {
    postalCode: '4721',
    city: 'Krena'
  },
  {
    postalCode: '4722',
    city: 'Adenbruck'
  },
  {
    postalCode: '4722',
    city: 'Aichet'
  },
  {
    postalCode: '4722',
    city: 'Blindenau'
  },
  {
    postalCode: '4722',
    city: 'Brandstätten'
  },
  {
    postalCode: '4722',
    city: 'Bruck an der Aschach'
  },
  {
    postalCode: '4722',
    city: 'Buch'
  },
  {
    postalCode: '4722',
    city: 'Dunkenedt'
  },
  {
    postalCode: '4722',
    city: 'Erleinsdorf'
  },
  {
    postalCode: '4722',
    city: 'Feichten'
  },
  {
    postalCode: '4722',
    city: 'Freiling'
  },
  {
    postalCode: '4722',
    city: 'Fuchshub'
  },
  {
    postalCode: '4722',
    city: 'Gschwendthäuser'
  },
  {
    postalCode: '4722',
    city: 'Höhenstein'
  },
  {
    postalCode: '4722',
    city: 'Hötzmannsberg'
  },
  {
    postalCode: '4722',
    city: 'Hub'
  },
  {
    postalCode: '4722',
    city: 'Itzling'
  },
  {
    postalCode: '4722',
    city: 'Kastlingeredt'
  },
  {
    postalCode: '4722',
    city: 'Mühlbrenning'
  },
  {
    postalCode: '4722',
    city: 'Niederaching'
  },
  {
    postalCode: '4722',
    city: 'Niederensfelden'
  },
  {
    postalCode: '4722',
    city: 'Niederweiding'
  },
  {
    postalCode: '4722',
    city: 'Nußbaum'
  },
  {
    postalCode: '4722',
    city: 'Oberaching'
  },
  {
    postalCode: '4722',
    city: 'Oberngrub'
  },
  {
    postalCode: '4722',
    city: 'Oberweiding'
  },
  {
    postalCode: '4722',
    city: 'Parz bei Gattern'
  },
  {
    postalCode: '4722',
    city: 'Pfarrhofheuberg'
  },
  {
    postalCode: '4722',
    city: 'Ratzling'
  },
  {
    postalCode: '4722',
    city: 'Schieferhub'
  },
  {
    postalCode: '4722',
    city: 'Sölden an der Straß'
  },
  {
    postalCode: '4722',
    city: 'Stefansdorf'
  },
  {
    postalCode: '4722',
    city: 'Unterheuberg'
  },
  {
    postalCode: '4722',
    city: 'Waasen'
  },
  {
    postalCode: '4722',
    city: 'Waasnerau'
  },
  {
    postalCode: '4722',
    city: 'Winkl'
  },
  {
    postalCode: '4722',
    city: 'Achleiten'
  },
  {
    postalCode: '4722',
    city: 'Greinsfurth'
  },
  {
    postalCode: '4722',
    city: 'Haargassen'
  },
  {
    postalCode: '4722',
    city: 'Köppensteegen'
  },
  {
    postalCode: '4722',
    city: 'Leithen'
  },
  {
    postalCode: '4722',
    city: 'Peuerbach'
  },
  {
    postalCode: '4722',
    city: 'Pühret'
  },
  {
    postalCode: '4722',
    city: 'Ranna'
  },
  {
    postalCode: '4722',
    city: 'Spielmannsberg'
  },
  {
    postalCode: '4722',
    city: 'Steingrüneredt'
  },
  {
    postalCode: '4722',
    city: 'Teucht'
  },
  {
    postalCode: '4722',
    city: 'Thomasberg'
  },
  {
    postalCode: '4722',
    city: 'Untertreßleinsbach'
  },
  {
    postalCode: '4722',
    city: 'Besenberg'
  },
  {
    postalCode: '4722',
    city: 'Asing'
  },
  {
    postalCode: '4722',
    city: 'Enzing'
  },
  {
    postalCode: '4722',
    city: 'Griesbach'
  },
  {
    postalCode: '4722',
    city: 'Kirchenfeld'
  },
  {
    postalCode: '4722',
    city: 'Krottenthal'
  },
  {
    postalCode: '4722',
    city: 'Langenpeuerbach'
  },
  {
    postalCode: '4722',
    city: 'Obererleinsbach'
  },
  {
    postalCode: '4722',
    city: 'Ort an der Straß'
  },
  {
    postalCode: '4722',
    city: 'Parz am Öhlstampf'
  },
  {
    postalCode: '4722',
    city: 'Rittberg'
  },
  {
    postalCode: '4722',
    city: 'Sallet'
  },
  {
    postalCode: '4722',
    city: 'Steegen'
  },
  {
    postalCode: '4722',
    city: 'Steinbruck'
  },
  {
    postalCode: '4722',
    city: 'Stieglhof'
  },
  {
    postalCode: '4722',
    city: 'Unterbubenberg'
  },
  {
    postalCode: '4722',
    city: 'Untererleinsbach'
  },
  {
    postalCode: '4722',
    city: 'Unterndobl'
  },
  {
    postalCode: '4722',
    city: 'Untwüsten'
  },
  {
    postalCode: '4722',
    city: 'Urleinsberg'
  },
  {
    postalCode: '4722',
    city: 'Vest'
  },
  {
    postalCode: '4722',
    city: 'Weireth'
  },
  {
    postalCode: '4722',
    city: 'Windprechting'
  },
  {
    postalCode: '4723',
    city: 'Entholz'
  },
  {
    postalCode: '4723',
    city: 'Pfeneberg'
  },
  {
    postalCode: '4723',
    city: 'Püret'
  },
  {
    postalCode: '4723',
    city: 'Bernrad'
  },
  {
    postalCode: '4723',
    city: 'Untereck'
  },
  {
    postalCode: '4723',
    city: 'Au bei Ed'
  },
  {
    postalCode: '4723',
    city: 'Au bei Natternbach'
  },
  {
    postalCode: '4723',
    city: 'Baumühl'
  },
  {
    postalCode: '4723',
    city: 'Berndorf'
  },
  {
    postalCode: '4723',
    city: 'Dopl'
  },
  {
    postalCode: '4723',
    city: 'Eck'
  },
  {
    postalCode: '4723',
    city: 'Ed'
  },
  {
    postalCode: '4723',
    city: 'Wolfgrub'
  },
  {
    postalCode: '4723',
    city: 'Fronberg'
  },
  {
    postalCode: '4723',
    city: 'Gaisbuchen'
  },
  {
    postalCode: '4723',
    city: 'Gschaid'
  },
  {
    postalCode: '4723',
    city: 'Haibach'
  },
  {
    postalCode: '4723',
    city: 'Hairet'
  },
  {
    postalCode: '4723',
    city: 'Hochstraß'
  },
  {
    postalCode: '4723',
    city: 'Hörmating'
  },
  {
    postalCode: '4723',
    city: 'Hörzingerwald'
  },
  {
    postalCode: '4723',
    city: 'Hungberg'
  },
  {
    postalCode: '4723',
    city: 'Kapping'
  },
  {
    postalCode: '4723',
    city: 'Kirchberg'
  },
  {
    postalCode: '4723',
    city: 'Knotzberg'
  },
  {
    postalCode: '4723',
    city: 'Kreuz'
  },
  {
    postalCode: '4723',
    city: 'Lichtberg'
  },
  {
    postalCode: '4723',
    city: 'Moos'
  },
  {
    postalCode: '4723',
    city: 'Natternbach'
  },
  {
    postalCode: '4723',
    city: 'Oberhörzing'
  },
  {
    postalCode: '4723',
    city: 'Obermaggau'
  },
  {
    postalCode: '4723',
    city: 'Obertresleinsbach'
  },
  {
    postalCode: '4723',
    city: 'Pötzenau'
  },
  {
    postalCode: '4723',
    city: 'Pötzling'
  },
  {
    postalCode: '4723',
    city: 'Reiting'
  },
  {
    postalCode: '4723',
    city: 'Rittberg'
  },
  {
    postalCode: '4723',
    city: 'Schmiedparz'
  },
  {
    postalCode: '4723',
    city: 'Tal'
  },
  {
    postalCode: '4723',
    city: 'Traunolding'
  },
  {
    postalCode: '4723',
    city: 'Unterhörzing'
  },
  {
    postalCode: '4723',
    city: 'Untermaggau'
  },
  {
    postalCode: '4724',
    city: 'Altenberg'
  },
  {
    postalCode: '4724',
    city: 'Eschenau im Hausruckkreis'
  },
  {
    postalCode: '4724',
    city: 'Gferedt'
  },
  {
    postalCode: '4724',
    city: 'Hagenberg'
  },
  {
    postalCode: '4724',
    city: 'Hasledt'
  },
  {
    postalCode: '4724',
    city: 'Hausleithen'
  },
  {
    postalCode: '4724',
    city: 'Hech'
  },
  {
    postalCode: '4724',
    city: 'Hengstberg'
  },
  {
    postalCode: '4724',
    city: 'Höllberg'
  },
  {
    postalCode: '4724',
    city: 'Hofingerleithen'
  },
  {
    postalCode: '4724',
    city: 'Hofstetten'
  },
  {
    postalCode: '4724',
    city: 'Königshub'
  },
  {
    postalCode: '4724',
    city: 'Mitteraubach'
  },
  {
    postalCode: '4724',
    city: 'Neubach'
  },
  {
    postalCode: '4724',
    city: 'Oberaubach'
  },
  {
    postalCode: '4724',
    city: 'Rath'
  },
  {
    postalCode: '4724',
    city: 'Reith'
  },
  {
    postalCode: '4724',
    city: 'Salling'
  },
  {
    postalCode: '4724',
    city: 'Schachen'
  },
  {
    postalCode: '4724',
    city: 'Stilzing'
  },
  {
    postalCode: '4724',
    city: 'Stocket'
  },
  {
    postalCode: '4724',
    city: 'Unteraubach'
  },
  {
    postalCode: '4724',
    city: 'Veitsberg'
  },
  {
    postalCode: '4724',
    city: 'Vilzbach'
  },
  {
    postalCode: '4724',
    city: 'Waldbach'
  },
  {
    postalCode: '4724',
    city: 'Willing'
  },
  {
    postalCode: '4724',
    city: 'Winklpoint'
  },
  {
    postalCode: '4724',
    city: 'Aigen'
  },
  {
    postalCode: '4724',
    city: 'Baumgarten'
  },
  {
    postalCode: '4724',
    city: 'Eben bei Sankt Sixt'
  },
  {
    postalCode: '4724',
    city: 'Feuermühl'
  },
  {
    postalCode: '4724',
    city: 'Frankengrub'
  },
  {
    postalCode: '4724',
    city: 'Graspoint'
  },
  {
    postalCode: '4724',
    city: 'Hofing'
  },
  {
    postalCode: '4724',
    city: 'Hungberg'
  },
  {
    postalCode: '4724',
    city: 'Inzing'
  },
  {
    postalCode: '4724',
    city: 'Jebing'
  },
  {
    postalCode: '4724',
    city: 'Kirchberg'
  },
  {
    postalCode: '4724',
    city: 'Knotzberg'
  },
  {
    postalCode: '4724',
    city: 'Mäusburg'
  },
  {
    postalCode: '4724',
    city: 'Mehring'
  },
  {
    postalCode: '4724',
    city: 'Mehringerau'
  },
  {
    postalCode: '4724',
    city: 'Moosau'
  },
  {
    postalCode: '4724',
    city: 'Neukirchen am Walde'
  },
  {
    postalCode: '4724',
    city: 'Oberbuchberg'
  },
  {
    postalCode: '4724',
    city: 'Obergermating'
  },
  {
    postalCode: '4724',
    city: 'Polstergrub'
  },
  {
    postalCode: '4724',
    city: 'Pühret'
  },
  {
    postalCode: '4724',
    city: 'Spattenbrunn'
  },
  {
    postalCode: '4724',
    city: 'Straß bei Sankt Sixt'
  },
  {
    postalCode: '4724',
    city: 'Unterbuchberg'
  },
  {
    postalCode: '4724',
    city: 'Untergermating'
  },
  {
    postalCode: '4724',
    city: 'Untergrub'
  },
  {
    postalCode: '4724',
    city: 'Vorau'
  },
  {
    postalCode: '4724',
    city: 'Weibing'
  },
  {
    postalCode: '4724',
    city: 'Zierreit'
  },
  {
    postalCode: '4724',
    city: 'Erledt'
  },
  {
    postalCode: '4725',
    city: 'Stadl'
  },
  {
    postalCode: '4725',
    city: 'Kahlberg'
  },
  {
    postalCode: '4725',
    city: 'Adelsgrub'
  },
  {
    postalCode: '4725',
    city: 'Au'
  },
  {
    postalCode: '4725',
    city: 'Breitenau'
  },
  {
    postalCode: '4725',
    city: 'Dorf'
  },
  {
    postalCode: '4725',
    city: 'Dornedt'
  },
  {
    postalCode: '4725',
    city: 'Eben'
  },
  {
    postalCode: '4725',
    city: 'Edern'
  },
  {
    postalCode: '4725',
    city: 'Fraunhof'
  },
  {
    postalCode: '4725',
    city: 'Grübl'
  },
  {
    postalCode: '4725',
    city: 'Gschwendt'
  },
  {
    postalCode: '4725',
    city: 'Hackendorf'
  },
  {
    postalCode: '4725',
    city: 'Henndorf'
  },
  {
    postalCode: '4725',
    city: 'Höllau'
  },
  {
    postalCode: '4725',
    city: 'Innerleiten'
  },
  {
    postalCode: '4725',
    city: 'Kößlau'
  },
  {
    postalCode: '4725',
    city: 'Lehen'
  },
  {
    postalCode: '4725',
    city: 'Maierhof'
  },
  {
    postalCode: '4725',
    city: 'Mittelbach'
  },
  {
    postalCode: '4725',
    city: 'Oberleiten'
  },
  {
    postalCode: '4725',
    city: 'Panholz'
  },
  {
    postalCode: '4725',
    city: 'Prünst'
  },
  {
    postalCode: '4725',
    city: 'Reisedt'
  },
  {
    postalCode: '4725',
    city: 'Reiting'
  },
  {
    postalCode: '4725',
    city: 'Sagedt'
  },
  {
    postalCode: '4725',
    city: 'Sankt Aegidi'
  },
  {
    postalCode: '4725',
    city: 'Schauern'
  },
  {
    postalCode: '4725',
    city: 'Steinedt'
  },
  {
    postalCode: '4725',
    city: 'Tullern'
  },
  {
    postalCode: '4725',
    city: 'Voglgrub'
  },
  {
    postalCode: '4725',
    city: 'Walleiten'
  },
  {
    postalCode: '4725',
    city: 'Wallern'
  },
  {
    postalCode: '4725',
    city: 'Witzenedt'
  },
  {
    postalCode: '4725',
    city: 'Zimmerleiten'
  },
  {
    postalCode: '4725',
    city: 'Simling'
  },
  {
    postalCode: '4730',
    city: 'Hech'
  },
  {
    postalCode: '4730',
    city: 'Schöffling'
  },
  {
    postalCode: '4730',
    city: 'Kropfleiten'
  },
  {
    postalCode: '4730',
    city: 'Löwengrub'
  },
  {
    postalCode: '4730',
    city: 'Bergern'
  },
  {
    postalCode: '4730',
    city: 'Gugerling'
  },
  {
    postalCode: '4730',
    city: 'Wölflhof'
  },
  {
    postalCode: '4730',
    city: 'Anrath'
  },
  {
    postalCode: '4730',
    city: 'Aschach'
  },
  {
    postalCode: '4730',
    city: 'Auweidenholz'
  },
  {
    postalCode: '4730',
    city: 'Auwies'
  },
  {
    postalCode: '4730',
    city: 'Bäckenhof'
  },
  {
    postalCode: '4730',
    city: 'Breitwies'
  },
  {
    postalCode: '4730',
    city: 'Dittenbach'
  },
  {
    postalCode: '4730',
    city: 'Eitzenberg'
  },
  {
    postalCode: '4730',
    city: 'Esthofen'
  },
  {
    postalCode: '4730',
    city: 'Grillparz'
  },
  {
    postalCode: '4730',
    city: 'Hausleiten'
  },
  {
    postalCode: '4730',
    city: 'Holzing'
  },
  {
    postalCode: '4730',
    city: 'Hueb bei Lindbruck'
  },
  {
    postalCode: '4730',
    city: 'Hueb bei Manzing'
  },
  {
    postalCode: '4730',
    city: 'Imperndorf'
  },
  {
    postalCode: '4730',
    city: 'Inzing'
  },
  {
    postalCode: '4730',
    city: 'Keppling'
  },
  {
    postalCode: '4730',
    city: 'Kollerbichl'
  },
  {
    postalCode: '4730',
    city: 'Kranabithen'
  },
  {
    postalCode: '4730',
    city: 'Lindbruck'
  },
  {
    postalCode: '4730',
    city: 'Moospolling'
  },
  {
    postalCode: '4730',
    city: 'Niederndorf'
  },
  {
    postalCode: '4730',
    city: 'Niederspaching'
  },
  {
    postalCode: '4730',
    city: 'Obergschwendt'
  },
  {
    postalCode: '4730',
    city: 'Oberviehbach'
  },
  {
    postalCode: '4730',
    city: 'Oberwegbach'
  },
  {
    postalCode: '4730',
    city: 'Parzham'
  },
  {
    postalCode: '4730',
    city: 'Prambacherholz'
  },
  {
    postalCode: '4730',
    city: 'Punzing'
  },
  {
    postalCode: '4730',
    city: 'Purgstall'
  },
  {
    postalCode: '4730',
    city: 'Röckendorferholz'
  },
  {
    postalCode: '4730',
    city: 'Schurrerprambach'
  },
  {
    postalCode: '4730',
    city: 'Sittling'
  },
  {
    postalCode: '4730',
    city: 'Steinparz'
  },
  {
    postalCode: '4730',
    city: 'Stillfüssing'
  },
  {
    postalCode: '4730',
    city: 'Stroiß'
  },
  {
    postalCode: '4730',
    city: 'Thall'
  },
  {
    postalCode: '4730',
    city: 'Thallham'
  },
  {
    postalCode: '4730',
    city: 'Untergschwendt'
  },
  {
    postalCode: '4730',
    city: 'Unterheuberg'
  },
  {
    postalCode: '4730',
    city: 'Unterviehbach'
  },
  {
    postalCode: '4730',
    city: 'Unterwegbach'
  },
  {
    postalCode: '4730',
    city: 'Waikhartsberg'
  },
  {
    postalCode: '4730',
    city: 'Waizenkirchen'
  },
  {
    postalCode: '4730',
    city: 'Waldweidenholz'
  },
  {
    postalCode: '4730',
    city: 'Weg'
  },
  {
    postalCode: '4730',
    city: 'Weidenholz'
  },
  {
    postalCode: '4730',
    city: 'Willersdorf'
  },
  {
    postalCode: '4730',
    city: 'Brunnwald'
  },
  {
    postalCode: '4730',
    city: 'Gewerbepark Süd'
  },
  {
    postalCode: '4731',
    city: 'Andrichsberg'
  },
  {
    postalCode: '4731',
    city: 'Baumgarten'
  },
  {
    postalCode: '4731',
    city: 'Dachsberg'
  },
  {
    postalCode: '4731',
    city: 'Gallham'
  },
  {
    postalCode: '4731',
    city: 'Gföllnerwald'
  },
  {
    postalCode: '4731',
    city: 'Großsteingrub'
  },
  {
    postalCode: '4731',
    city: 'Grüben'
  },
  {
    postalCode: '4731',
    city: 'Gschnarret'
  },
  {
    postalCode: '4731',
    city: 'Hundswies'
  },
  {
    postalCode: '4731',
    city: 'Kleinsteingrub'
  },
  {
    postalCode: '4731',
    city: 'Langstögen'
  },
  {
    postalCode: '4731',
    city: 'Mairing'
  },
  {
    postalCode: '4731',
    city: 'Mittergallsbach'
  },
  {
    postalCode: '4731',
    city: 'Oberdoppl'
  },
  {
    postalCode: '4731',
    city: 'Obereschlbach'
  },
  {
    postalCode: '4731',
    city: 'Oberfreundorf'
  },
  {
    postalCode: '4731',
    city: 'Obergallsbach'
  },
  {
    postalCode: '4731',
    city: 'Pertmannshub'
  },
  {
    postalCode: '4731',
    city: 'Prambachkirchen'
  },
  {
    postalCode: '4731',
    city: 'Prattsdorf'
  },
  {
    postalCode: '4731',
    city: 'Sallmannsberg'
  },
  {
    postalCode: '4731',
    city: 'Stallberg'
  },
  {
    postalCode: '4731',
    city: 'Steinbruch'
  },
  {
    postalCode: '4731',
    city: 'Taubing'
  },
  {
    postalCode: '4731',
    city: 'Unterbruck'
  },
  {
    postalCode: '4731',
    city: 'Unterdoppl'
  },
  {
    postalCode: '4731',
    city: 'Untereschlbach'
  },
  {
    postalCode: '4731',
    city: 'Untergallsbach'
  },
  {
    postalCode: '4731',
    city: 'Unterprambach'
  },
  {
    postalCode: '4731',
    city: 'Uttenthal'
  },
  {
    postalCode: '4731',
    city: 'Weinberg'
  },
  {
    postalCode: '4731',
    city: 'Birihub'
  },
  {
    postalCode: '4731',
    city: 'Kleinstroheim'
  },
  {
    postalCode: '4731',
    city: 'Gföll'
  },
  {
    postalCode: '4731',
    city: 'Manzing'
  },
  {
    postalCode: '4731',
    city: 'Ritzing'
  },
  {
    postalCode: '4731',
    city: 'Watzenbach'
  },
  {
    postalCode: '4731',
    city: 'Weinzierlbruck'
  },
  {
    postalCode: '4732',
    city: 'Aigelsberg'
  },
  {
    postalCode: '4732',
    city: 'Kleingerstdoppl'
  },
  {
    postalCode: '4732',
    city: 'Mitterwinkl'
  },
  {
    postalCode: '4732',
    city: 'Niederwinkl'
  },
  {
    postalCode: '4732',
    city: 'Reith'
  },
  {
    postalCode: '4732',
    city: 'Ebenstraß'
  },
  {
    postalCode: '4732',
    city: 'Eppenedt'
  },
  {
    postalCode: '4732',
    city: 'Großgerstdopl'
  },
  {
    postalCode: '4732',
    city: 'Kirnwies'
  },
  {
    postalCode: '4732',
    city: 'Lameth'
  },
  {
    postalCode: '4732',
    city: 'Naichet'
  },
  {
    postalCode: '4732',
    city: 'Oberprambach'
  },
  {
    postalCode: '4732',
    city: 'Ramesedt'
  },
  {
    postalCode: '4732',
    city: 'Sankt Thomas'
  },
  {
    postalCode: '4732',
    city: 'Schmidgraben'
  },
  {
    postalCode: '4732',
    city: 'Straß'
  },
  {
    postalCode: '4732',
    city: 'Wimm'
  },
  {
    postalCode: '4733',
    city: 'Andling'
  },
  {
    postalCode: '4733',
    city: 'Au'
  },
  {
    postalCode: '4733',
    city: 'Bach'
  },
  {
    postalCode: '4733',
    city: 'Bruck'
  },
  {
    postalCode: '4733',
    city: 'Eitzenberg'
  },
  {
    postalCode: '4733',
    city: 'Freindorf'
  },
  {
    postalCode: '4733',
    city: 'Grub'
  },
  {
    postalCode: '4733',
    city: 'Haid'
  },
  {
    postalCode: '4733',
    city: 'Heiligenberg'
  },
  {
    postalCode: '4733',
    city: 'Irrenedt'
  },
  {
    postalCode: '4733',
    city: 'Laab'
  },
  {
    postalCode: '4733',
    city: 'Maiden'
  },
  {
    postalCode: '4733',
    city: 'Moos'
  },
  {
    postalCode: '4733',
    city: 'Oberleiten'
  },
  {
    postalCode: '4733',
    city: 'Schörgendorf'
  },
  {
    postalCode: '4733',
    city: 'Süssenbach'
  },
  {
    postalCode: '4733',
    city: 'Wassergraben'
  },
  {
    postalCode: '4741',
    city: 'Still'
  },
  {
    postalCode: '4741',
    city: 'Gattring'
  },
  {
    postalCode: '4741',
    city: 'Unterleiten'
  },
  {
    postalCode: '4741',
    city: 'Rappoltsberg'
  },
  {
    postalCode: '4741',
    city: 'Dötzledt'
  },
  {
    postalCode: '4741',
    city: 'Eck'
  },
  {
    postalCode: '4741',
    city: 'Fellhof'
  },
  {
    postalCode: '4741',
    city: 'Gassen'
  },
  {
    postalCode: '4741',
    city: 'Gugenedt'
  },
  {
    postalCode: '4741',
    city: 'Hareding'
  },
  {
    postalCode: '4741',
    city: 'Hub'
  },
  {
    postalCode: '4741',
    city: 'Kubing'
  },
  {
    postalCode: '4741',
    city: 'Lehen'
  },
  {
    postalCode: '4741',
    city: 'Märzendorf'
  },
  {
    postalCode: '4741',
    city: 'Oberhöglham'
  },
  {
    postalCode: '4741',
    city: 'Oberhof'
  },
  {
    postalCode: '4741',
    city: 'Pauredt'
  },
  {
    postalCode: '4741',
    city: 'Penesedt'
  },
  {
    postalCode: '4741',
    city: 'Perndorf'
  },
  {
    postalCode: '4741',
    city: 'Unterhöglham'
  },
  {
    postalCode: '4741',
    city: 'Weberndorf'
  },
  {
    postalCode: '4741',
    city: 'Weeg'
  },
  {
    postalCode: '4741',
    city: 'Wendling'
  },
  {
    postalCode: '4741',
    city: 'Zupfing'
  },
  {
    postalCode: '4742',
    city: 'Gewerbepark'
  },
  {
    postalCode: '4742',
    city: 'Asbach'
  },
  {
    postalCode: '4742',
    city: 'Bernhartsleiten'
  },
  {
    postalCode: '4742',
    city: 'Bruck'
  },
  {
    postalCode: '4742',
    city: 'Doppl'
  },
  {
    postalCode: '4742',
    city: 'Durrach'
  },
  {
    postalCode: '4742',
    city: 'Echtsberg'
  },
  {
    postalCode: '4742',
    city: 'Edt'
  },
  {
    postalCode: '4742',
    city: 'Feldegg'
  },
  {
    postalCode: '4742',
    city: 'Forsthub'
  },
  {
    postalCode: '4742',
    city: 'Gerhartsbrunn'
  },
  {
    postalCode: '4742',
    city: 'Großpoxruck'
  },
  {
    postalCode: '4742',
    city: 'Grübl'
  },
  {
    postalCode: '4742',
    city: 'Hebetsberg'
  },
  {
    postalCode: '4742',
    city: 'Hochhub'
  },
  {
    postalCode: '4742',
    city: 'Irringsdorf'
  },
  {
    postalCode: '4742',
    city: 'Kleinpoxruck'
  },
  {
    postalCode: '4742',
    city: 'Klinget'
  },
  {
    postalCode: '4742',
    city: 'Lucka'
  },
  {
    postalCode: '4742',
    city: 'Lughof'
  },
  {
    postalCode: '4742',
    city: 'Oberprenning'
  },
  {
    postalCode: '4742',
    city: 'Pram'
  },
  {
    postalCode: '4742',
    city: 'Pramberg'
  },
  {
    postalCode: '4742',
    city: 'Rabenberg'
  },
  {
    postalCode: '4742',
    city: 'Rabenthal'
  },
  {
    postalCode: '4742',
    city: 'Renhartsberg'
  },
  {
    postalCode: '4742',
    city: 'Rotten'
  },
  {
    postalCode: '4742',
    city: 'Rühring'
  },
  {
    postalCode: '4742',
    city: 'Schulterzucker'
  },
  {
    postalCode: '4742',
    city: 'Standharting'
  },
  {
    postalCode: '4742',
    city: 'Steinbruck'
  },
  {
    postalCode: '4742',
    city: 'Straß'
  },
  {
    postalCode: '4742',
    city: 'Unterprenning'
  },
  {
    postalCode: '4742',
    city: 'Viertlbach'
  },
  {
    postalCode: '4742',
    city: 'Wallner'
  },
  {
    postalCode: '4742',
    city: 'Wimm'
  },
  {
    postalCode: '4743',
    city: 'Aggstein'
  },
  {
    postalCode: '4743',
    city: 'Böcklarn'
  },
  {
    postalCode: '4743',
    city: 'Brenning'
  },
  {
    postalCode: '4743',
    city: 'Dorf'
  },
  {
    postalCode: '4743',
    city: 'Eschlried'
  },
  {
    postalCode: '4743',
    city: 'Hilligan'
  },
  {
    postalCode: '4743',
    city: 'Maierhof'
  },
  {
    postalCode: '4743',
    city: 'Manhartsberg'
  },
  {
    postalCode: '4743',
    city: 'Manhartsgrub'
  },
  {
    postalCode: '4743',
    city: 'Mari'
  },
  {
    postalCode: '4743',
    city: 'Maribach'
  },
  {
    postalCode: '4743',
    city: 'Osternach'
  },
  {
    postalCode: '4743',
    city: 'Peterskirchen'
  },
  {
    postalCode: '4743',
    city: 'Untermauer'
  },
  {
    postalCode: '4743',
    city: 'Wolketsedt'
  },
  {
    postalCode: '4751',
    city: 'Augendobl'
  },
  {
    postalCode: '4751',
    city: 'Außerjebing'
  },
  {
    postalCode: '4751',
    city: 'Dorf'
  },
  {
    postalCode: '4751',
    city: 'Großreiting'
  },
  {
    postalCode: '4751',
    city: 'Habetswohl'
  },
  {
    postalCode: '4751',
    city: 'Hinterndobl'
  },
  {
    postalCode: '4751',
    city: 'Kleinreiting'
  },
  {
    postalCode: '4751',
    city: 'Kumpfmühl'
  },
  {
    postalCode: '4751',
    city: 'Lohndorf'
  },
  {
    postalCode: '4751',
    city: 'Mitterjebing'
  },
  {
    postalCode: '4751',
    city: 'Mundorfing'
  },
  {
    postalCode: '4751',
    city: 'Natzing'
  },
  {
    postalCode: '4751',
    city: 'Obernparz'
  },
  {
    postalCode: '4751',
    city: 'Parting'
  },
  {
    postalCode: '4751',
    city: 'Pimingsdorf'
  },
  {
    postalCode: '4751',
    city: 'Roiding'
  },
  {
    postalCode: '4751',
    city: 'Schacha'
  },
  {
    postalCode: '4751',
    city: 'Schatzdorf'
  },
  {
    postalCode: '4751',
    city: 'Stögen'
  },
  {
    postalCode: '4751',
    city: 'Thalling'
  },
  {
    postalCode: '4751',
    city: 'Vorderndobl'
  },
  {
    postalCode: '4751',
    city: 'Weigljebing'
  },
  {
    postalCode: '4752',
    city: 'Baumgarten'
  },
  {
    postalCode: '4752',
    city: 'Achleiten'
  },
  {
    postalCode: '4752',
    city: 'Schwabenbach'
  },
  {
    postalCode: '4752',
    city: 'Berg'
  },
  {
    postalCode: '4752',
    city: 'Habach'
  },
  {
    postalCode: '4752',
    city: 'Bayrisch-Habach'
  },
  {
    postalCode: '4752',
    city: 'Ottenedt'
  },
  {
    postalCode: '4752',
    city: 'Pomedt'
  },
  {
    postalCode: '4752',
    city: 'Riedau'
  },
  {
    postalCode: '4752',
    city: 'Schwaben'
  },
  {
    postalCode: '4752',
    city: 'Stieredt'
  },
  {
    postalCode: '4752',
    city: 'Vormarkt'
  },
  {
    postalCode: '4752',
    city: 'Wildhag'
  },
  {
    postalCode: '4753',
    city: 'Sittling'
  },
  {
    postalCode: '4753',
    city: 'Aichet'
  },
  {
    postalCode: '4753',
    city: 'Altmannsdorf'
  },
  {
    postalCode: '4753',
    city: 'Arling'
  },
  {
    postalCode: '4753',
    city: 'Baumgarten'
  },
  {
    postalCode: '4753',
    city: 'Brandstätten'
  },
  {
    postalCode: '4753',
    city: 'Breitenried'
  },
  {
    postalCode: '4753',
    city: 'Bruckleiten'
  },
  {
    postalCode: '4753',
    city: 'Edtleiten'
  },
  {
    postalCode: '4753',
    city: 'Ellerbach'
  },
  {
    postalCode: '4753',
    city: 'Flohleiten'
  },
  {
    postalCode: '4753',
    city: 'Gansing'
  },
  {
    postalCode: '4753',
    city: 'Gotthalmsedt'
  },
  {
    postalCode: '4753',
    city: 'Günzing'
  },
  {
    postalCode: '4753',
    city: 'Helfingsdorf'
  },
  {
    postalCode: '4753',
    city: 'Hohenerlach'
  },
  {
    postalCode: '4753',
    city: 'Jebing'
  },
  {
    postalCode: '4753',
    city: 'Jedretsberg'
  },
  {
    postalCode: '4753',
    city: 'Kainzing'
  },
  {
    postalCode: '4753',
    city: 'Kleingaisbach'
  },
  {
    postalCode: '4753',
    city: 'Kühdobl'
  },
  {
    postalCode: '4753',
    city: 'Lacken'
  },
  {
    postalCode: '4753',
    city: 'Petersham'
  },
  {
    postalCode: '4753',
    city: 'Taiskirchen im Innkreis'
  },
  {
    postalCode: '4753',
    city: 'Tiefenbach'
  },
  {
    postalCode: '4753',
    city: 'Wiesenberg'
  },
  {
    postalCode: '4753',
    city: 'Wietraun'
  },
  {
    postalCode: '4753',
    city: 'Wohleiten'
  },
  {
    postalCode: '4753',
    city: 'Wolfsedt'
  },
  {
    postalCode: '4753',
    city: 'Zahra'
  },
  {
    postalCode: '4753',
    city: 'Unterbreitenried'
  },
  {
    postalCode: '4753',
    city: 'Lindet'
  },
  {
    postalCode: '4754',
    city: 'Albertsedt'
  },
  {
    postalCode: '4754',
    city: 'Andrichsfurt'
  },
  {
    postalCode: '4754',
    city: 'Furt'
  },
  {
    postalCode: '4754',
    city: 'Gehnbach'
  },
  {
    postalCode: '4754',
    city: 'Irger'
  },
  {
    postalCode: '4754',
    city: 'Krammern'
  },
  {
    postalCode: '4754',
    city: 'Moosedt'
  },
  {
    postalCode: '4754',
    city: 'Pötting'
  },
  {
    postalCode: '4754',
    city: 'Raschhof'
  },
  {
    postalCode: '4754',
    city: 'Steingreß'
  },
  {
    postalCode: '4754',
    city: 'Walchshausen'
  },
  {
    postalCode: '4754',
    city: 'Weilhart'
  },
  {
    postalCode: '4754',
    city: 'Straß'
  },
  {
    postalCode: '4755',
    city: 'Hausmanning'
  },
  {
    postalCode: '4755',
    city: 'Blümling'
  },
  {
    postalCode: '4755',
    city: 'Aiglbrechting'
  },
  {
    postalCode: '4755',
    city: 'Bernetsedt'
  },
  {
    postalCode: '4755',
    city: 'Brandesleiten'
  },
  {
    postalCode: '4755',
    city: 'Dobl'
  },
  {
    postalCode: '4755',
    city: 'Dorf'
  },
  {
    postalCode: '4755',
    city: 'Eichberg'
  },
  {
    postalCode: '4755',
    city: 'Fuckersberg'
  },
  {
    postalCode: '4755',
    city: 'Gmeinedt'
  },
  {
    postalCode: '4755',
    city: 'Habekendobl'
  },
  {
    postalCode: '4755',
    city: 'Hellwagen'
  },
  {
    postalCode: '4755',
    city: 'Hub'
  },
  {
    postalCode: '4755',
    city: 'Jebling'
  },
  {
    postalCode: '4755',
    city: 'Obergriesbach'
  },
  {
    postalCode: '4755',
    city: 'Ornetsedt'
  },
  {
    postalCode: '4755',
    city: 'Point'
  },
  {
    postalCode: '4755',
    city: 'Reischenbach'
  },
  {
    postalCode: '4755',
    city: 'Schwarzgrub'
  },
  {
    postalCode: '4755',
    city: 'Sienleiten'
  },
  {
    postalCode: '4755',
    city: 'Stögen'
  },
  {
    postalCode: '4755',
    city: 'Tischling'
  },
  {
    postalCode: '4755',
    city: 'Weireth'
  },
  {
    postalCode: '4755',
    city: 'Wiesing'
  },
  {
    postalCode: '4755',
    city: 'Wildhag'
  },
  {
    postalCode: '4755',
    city: 'Willing'
  },
  {
    postalCode: '4755',
    city: 'Würting'
  },
  {
    postalCode: '4755',
    city: 'Zell an der Pram'
  },
  {
    postalCode: '4760',
    city: 'Bründl'
  },
  {
    postalCode: '4760',
    city: 'Brünning'
  },
  {
    postalCode: '4760',
    city: 'Einburg'
  },
  {
    postalCode: '4760',
    city: 'Gautzham'
  },
  {
    postalCode: '4760',
    city: 'Großprambach'
  },
  {
    postalCode: '4760',
    city: 'Kleinpireth'
  },
  {
    postalCode: '4760',
    city: 'Krennhof'
  },
  {
    postalCode: '4760',
    city: 'Niederham'
  },
  {
    postalCode: '4760',
    city: 'Oberspitzling'
  },
  {
    postalCode: '4760',
    city: 'Pausing'
  },
  {
    postalCode: '4760',
    city: 'Raab'
  },
  {
    postalCode: '4760',
    city: 'Rackersedt'
  },
  {
    postalCode: '4760',
    city: 'Riedlhof'
  },
  {
    postalCode: '4760',
    city: 'Thal'
  },
  {
    postalCode: '4760',
    city: 'Weeg'
  },
  {
    postalCode: '4761',
    city: 'Enzenkirchen'
  },
  {
    postalCode: '4761',
    city: 'Goldberg'
  },
  {
    postalCode: '4761',
    city: 'Hacking'
  },
  {
    postalCode: '4761',
    city: 'Heitzing'
  },
  {
    postalCode: '4761',
    city: 'Hintersberg'
  },
  {
    postalCode: '4761',
    city: 'Jagern'
  },
  {
    postalCode: '4761',
    city: 'Kenading'
  },
  {
    postalCode: '4761',
    city: 'Kriegen'
  },
  {
    postalCode: '4761',
    city: 'Matzing'
  },
  {
    postalCode: '4761',
    city: 'Mühlwitraun'
  },
  {
    postalCode: '4761',
    city: 'Oberau'
  },
  {
    postalCode: '4761',
    city: 'Oberleiten'
  },
  {
    postalCode: '4761',
    city: 'Bimmersdorf'
  },
  {
    postalCode: '4761',
    city: 'Ratzenbach'
  },
  {
    postalCode: '4761',
    city: 'Ruprechtsberg'
  },
  {
    postalCode: '4761',
    city: 'Schwarzenberg'
  },
  {
    postalCode: '4761',
    city: 'Straßwitraun'
  },
  {
    postalCode: '4761',
    city: 'Ungernberg'
  },
  {
    postalCode: '4761',
    city: 'Au'
  },
  {
    postalCode: '4761',
    city: 'Engertsberg'
  },
  {
    postalCode: '4761',
    city: 'Hub'
  },
  {
    postalCode: '4762',
    city: 'Landersberg'
  },
  {
    postalCode: '4762',
    city: 'Oberantlang'
  },
  {
    postalCode: '4762',
    city: 'Reiting'
  },
  {
    postalCode: '4762',
    city: 'Gschaid'
  },
  {
    postalCode: '4762',
    city: 'Kleinpireth'
  },
  {
    postalCode: '4762',
    city: 'Ungering'
  },
  {
    postalCode: '4762',
    city: 'Aichet'
  },
  {
    postalCode: '4762',
    city: 'Antlangkirchen'
  },
  {
    postalCode: '4762',
    city: 'Geiselham'
  },
  {
    postalCode: '4762',
    city: 'Patrichsham'
  },
  {
    postalCode: '4762',
    city: 'Sankt Willibald'
  },
  {
    postalCode: '4762',
    city: 'Wamprechtsham'
  },
  {
    postalCode: '4762',
    city: 'Oberbubenberg'
  },
  {
    postalCode: '4770',
    city: 'Winertshamerau'
  },
  {
    postalCode: '4770',
    city: 'Andorf'
  },
  {
    postalCode: '4770',
    city: 'Autzing'
  },
  {
    postalCode: '4770',
    city: 'Bach'
  },
  {
    postalCode: '4770',
    city: 'Basling'
  },
  {
    postalCode: '4770',
    city: 'Bruck'
  },
  {
    postalCode: '4770',
    city: 'Burgerding'
  },
  {
    postalCode: '4770',
    city: 'Eberleinsedt'
  },
  {
    postalCode: '4770',
    city: 'Edt bei Heitzing'
  },
  {
    postalCode: '4770',
    city: 'Edt beim Pfarrhof'
  },
  {
    postalCode: '4770',
    city: 'Erlau'
  },
  {
    postalCode: '4770',
    city: 'Gerolding'
  },
  {
    postalCode: '4770',
    city: 'Getzing'
  },
  {
    postalCode: '4770',
    city: 'Großpichl'
  },
  {
    postalCode: '4770',
    city: 'Großschörgern'
  },
  {
    postalCode: '4770',
    city: 'Haula'
  },
  {
    postalCode: '4770',
    city: 'Hebertspram'
  },
  {
    postalCode: '4770',
    city: 'Heitzing'
  },
  {
    postalCode: '4770',
    city: 'Heitzingerau'
  },
  {
    postalCode: '4770',
    city: 'Hier'
  },
  {
    postalCode: '4770',
    city: 'Hörzberg'
  },
  {
    postalCode: '4770',
    city: 'Hötzenedt'
  },
  {
    postalCode: '4770',
    city: 'Hötzlarn'
  },
  {
    postalCode: '4770',
    city: 'Hof'
  },
  {
    postalCode: '4770',
    city: 'Humerleiten'
  },
  {
    postalCode: '4770',
    city: 'Hutstock'
  },
  {
    postalCode: '4770',
    city: 'Kleinpichl'
  },
  {
    postalCode: '4770',
    city: 'Kleinschörgern'
  },
  {
    postalCode: '4770',
    city: 'Kreilern'
  },
  {
    postalCode: '4770',
    city: 'Kurzenkirchen'
  },
  {
    postalCode: '4770',
    city: 'Laab'
  },
  {
    postalCode: '4770',
    city: 'Lauterbrunn'
  },
  {
    postalCode: '4770',
    city: 'Lichtegg'
  },
  {
    postalCode: '4770',
    city: 'Linden'
  },
  {
    postalCode: '4770',
    city: 'Lohstampf'
  },
  {
    postalCode: '4770',
    city: 'Matzing'
  },
  {
    postalCode: '4770',
    city: 'Mayrhof'
  },
  {
    postalCode: '4770',
    city: 'Niederhartwagen'
  },
  {
    postalCode: '4770',
    city: 'Niederleiten'
  },
  {
    postalCode: '4770',
    city: 'Oberndorf'
  },
  {
    postalCode: '4770',
    city: 'Pimpfing'
  },
  {
    postalCode: '4770',
    city: 'Pram'
  },
  {
    postalCode: '4770',
    city: 'Pranzen'
  },
  {
    postalCode: '4770',
    city: 'Rablern'
  },
  {
    postalCode: '4770',
    city: 'Radlern'
  },
  {
    postalCode: '4770',
    city: 'Schärdingerau'
  },
  {
    postalCode: '4770',
    city: 'Schießedt'
  },
  {
    postalCode: '4770',
    city: 'Schulleredt'
  },
  {
    postalCode: '4770',
    city: 'Seifriedsedt'
  },
  {
    postalCode: '4770',
    city: 'Teuflau'
  },
  {
    postalCode: '4770',
    city: 'Untergriesbach'
  },
  {
    postalCode: '4770',
    city: 'Winertsham'
  },
  {
    postalCode: '4770',
    city: 'Winteraigen'
  },
  {
    postalCode: '4771',
    city: 'Oberhaigen'
  },
  {
    postalCode: '4771',
    city: 'Doblern'
  },
  {
    postalCode: '4771',
    city: 'Grub'
  },
  {
    postalCode: '4771',
    city: 'Hacking'
  },
  {
    postalCode: '4771',
    city: 'Sigharting'
  },
  {
    postalCode: '4771',
    city: 'Thal'
  },
  {
    postalCode: '4771',
    city: 'Thalmannsbach'
  },
  {
    postalCode: '4771',
    city: 'Unterhaigen'
  },
  {
    postalCode: '4771',
    city: 'Wurmsdobl'
  },
  {
    postalCode: '4772',
    city: 'Breitenberg'
  },
  {
    postalCode: '4772',
    city: 'Hutstock'
  },
  {
    postalCode: '4772',
    city: 'Sonnleiten'
  },
  {
    postalCode: '4772',
    city: 'Augental'
  },
  {
    postalCode: '4772',
    city: 'Blindendorf'
  },
  {
    postalCode: '4772',
    city: 'Breiningsdorf'
  },
  {
    postalCode: '4772',
    city: 'Bruck'
  },
  {
    postalCode: '4772',
    city: 'Ellerbach'
  },
  {
    postalCode: '4772',
    city: 'Gerhagen'
  },
  {
    postalCode: '4772',
    city: 'Gupfing'
  },
  {
    postalCode: '4772',
    city: 'Kromberg'
  },
  {
    postalCode: '4772',
    city: 'Lambrechten'
  },
  {
    postalCode: '4772',
    city: 'Messenbach'
  },
  {
    postalCode: '4772',
    city: 'Neundling'
  },
  {
    postalCode: '4772',
    city: 'Rabenstreit'
  },
  {
    postalCode: '4772',
    city: 'Reichergerhagen'
  },
  {
    postalCode: '4772',
    city: 'Winkl'
  },
  {
    postalCode: '4772',
    city: 'Oberndorf'
  },
  {
    postalCode: '4772',
    city: 'Reschenedt'
  },
  {
    postalCode: '4772',
    city: 'Wohlmuthen'
  },
  {
    postalCode: '4773',
    city: 'Edenaichet'
  },
  {
    postalCode: '4773',
    city: 'Edenrad'
  },
  {
    postalCode: '4773',
    city: 'Eggerding'
  },
  {
    postalCode: '4773',
    city: 'Hackledt'
  },
  {
    postalCode: '4773',
    city: 'Höribach'
  },
  {
    postalCode: '4773',
    city: 'Hof'
  },
  {
    postalCode: '4773',
    city: 'Hundshagen'
  },
  {
    postalCode: '4773',
    city: 'Maasbach'
  },
  {
    postalCode: '4773',
    city: 'Maihof'
  },
  {
    postalCode: '4773',
    city: 'Ranseredt'
  },
  {
    postalCode: '4773',
    city: 'Wernhartsgrub'
  },
  {
    postalCode: '4773',
    city: 'Bernedt'
  },
  {
    postalCode: '4774',
    city: 'Bach'
  },
  {
    postalCode: '4774',
    city: 'Bernedt'
  },
  {
    postalCode: '4774',
    city: 'Bodenhofen'
  },
  {
    postalCode: '4774',
    city: 'Dietraching'
  },
  {
    postalCode: '4774',
    city: 'Dietrichshofen'
  },
  {
    postalCode: '4774',
    city: 'Großwiesenhart'
  },
  {
    postalCode: '4774',
    city: 'Grub'
  },
  {
    postalCode: '4774',
    city: 'Hackenbuch'
  },
  {
    postalCode: '4774',
    city: 'Holzleithen'
  },
  {
    postalCode: '4774',
    city: 'Hub'
  },
  {
    postalCode: '4774',
    city: 'Kleinwiesenhart'
  },
  {
    postalCode: '4774',
    city: 'Lindenedt'
  },
  {
    postalCode: '4774',
    city: 'Niederham'
  },
  {
    postalCode: '4774',
    city: 'Oberfucking'
  },
  {
    postalCode: '4774',
    city: 'Sankt Marienkirchen bei Schärding'
  },
  {
    postalCode: '4774',
    city: 'Singern'
  },
  {
    postalCode: '4774',
    city: 'Stocket'
  },
  {
    postalCode: '4774',
    city: 'Unterfucking'
  },
  {
    postalCode: '4774',
    city: 'Wernhartsgrub'
  },
  {
    postalCode: '4774',
    city: 'Schratzberg'
  },
  {
    postalCode: '4775',
    city: 'Hundshagen'
  },
  {
    postalCode: '4775',
    city: 'Aichberg'
  },
  {
    postalCode: '4775',
    city: 'Aichedt'
  },
  {
    postalCode: '4775',
    city: 'Bachschwölln'
  },
  {
    postalCode: '4775',
    city: 'Baumgarten'
  },
  {
    postalCode: '4775',
    city: 'Berg'
  },
  {
    postalCode: '4775',
    city: 'Berndobl'
  },
  {
    postalCode: '4775',
    city: 'Brauchsdorf'
  },
  {
    postalCode: '4775',
    city: 'Brunedt'
  },
  {
    postalCode: '4775',
    city: 'Feicht'
  },
  {
    postalCode: '4775',
    city: 'Furth'
  },
  {
    postalCode: '4775',
    city: 'Gadern'
  },
  {
    postalCode: '4775',
    city: 'Haberedt'
  },
  {
    postalCode: '4775',
    city: 'Höbmannsbach'
  },
  {
    postalCode: '4775',
    city: 'Höbmannsdorf'
  },
  {
    postalCode: '4775',
    city: 'Holzing'
  },
  {
    postalCode: '4775',
    city: 'Igling'
  },
  {
    postalCode: '4775',
    city: 'Jechtenham'
  },
  {
    postalCode: '4775',
    city: 'Kalchgrub'
  },
  {
    postalCode: '4775',
    city: 'Kapelln'
  },
  {
    postalCode: '4775',
    city: 'Laufenbach'
  },
  {
    postalCode: '4775',
    city: 'Leoprechting'
  },
  {
    postalCode: '4775',
    city: 'Maad'
  },
  {
    postalCode: '4775',
    city: 'Oberpramau'
  },
  {
    postalCode: '4775',
    city: 'Furth-Pfaffing'
  },
  {
    postalCode: '4775',
    city: 'Pfaffingdorf'
  },
  {
    postalCode: '4775',
    city: 'Pram'
  },
  {
    postalCode: '4775',
    city: 'Schwendt'
  },
  {
    postalCode: '4775',
    city: 'Taufkirchen an der Pram'
  },
  {
    postalCode: '4775',
    city: 'Unterpramau'
  },
  {
    postalCode: '4775',
    city: 'Wagholming'
  },
  {
    postalCode: '4775',
    city: 'Kleinwaging'
  },
  {
    postalCode: '4775',
    city: 'Wimm'
  },
  {
    postalCode: '4775',
    city: 'Windten'
  },
  {
    postalCode: '4775',
    city: 'Wolfsedt'
  },
  {
    postalCode: '4775',
    city: 'Gmeinau'
  },
  {
    postalCode: '4776',
    city: 'Alfersham'
  },
  {
    postalCode: '4776',
    city: 'Angsüß'
  },
  {
    postalCode: '4776',
    city: 'Antersham'
  },
  {
    postalCode: '4776',
    city: 'Bartenberg'
  },
  {
    postalCode: '4776',
    city: 'Bernolden'
  },
  {
    postalCode: '4776',
    city: 'Brunnern'
  },
  {
    postalCode: '4776',
    city: 'Buchet'
  },
  {
    postalCode: '4776',
    city: 'Diersbach'
  },
  {
    postalCode: '4776',
    city: 'Eden'
  },
  {
    postalCode: '4776',
    city: 'Edenwiesen'
  },
  {
    postalCode: '4776',
    city: 'Erledt'
  },
  {
    postalCode: '4776',
    city: 'Etzelbach'
  },
  {
    postalCode: '4776',
    city: 'Froschau'
  },
  {
    postalCode: '4776',
    city: 'Gumping'
  },
  {
    postalCode: '4776',
    city: 'Hartwagen'
  },
  {
    postalCode: '4776',
    city: 'Herrnberg'
  },
  {
    postalCode: '4776',
    city: 'Hochegg'
  },
  {
    postalCode: '4776',
    city: 'Inding'
  },
  {
    postalCode: '4776',
    city: 'Kalling'
  },
  {
    postalCode: '4776',
    city: 'Kindling'
  },
  {
    postalCode: '4776',
    city: 'Kobledt'
  },
  {
    postalCode: '4776',
    city: 'Mayberg'
  },
  {
    postalCode: '4776',
    city: 'Mitterndorf'
  },
  {
    postalCode: '4776',
    city: 'Mörstalling'
  },
  {
    postalCode: '4776',
    city: 'Oberedt'
  },
  {
    postalCode: '4776',
    city: 'Raad'
  },
  {
    postalCode: '4776',
    city: 'Schmidsedt'
  },
  {
    postalCode: '4776',
    city: 'Schwabenhub'
  },
  {
    postalCode: '4776',
    city: 'Sonndorf'
  },
  {
    postalCode: '4776',
    city: 'Dobl'
  },
  {
    postalCode: '4776',
    city: 'Unterholzen'
  },
  {
    postalCode: '4776',
    city: 'Großwaging'
  },
  {
    postalCode: '4776',
    city: 'Sinzing'
  },
  {
    postalCode: '4776',
    city: 'Igling'
  },
  {
    postalCode: '4777',
    city: 'Heiligenbaum'
  },
  {
    postalCode: '4777',
    city: 'Mayrhof'
  },
  {
    postalCode: '4777',
    city: 'Oberndorf'
  },
  {
    postalCode: '4780',
    city: 'Höcking'
  },
  {
    postalCode: '4780',
    city: 'Korneredt'
  },
  {
    postalCode: '4780',
    city: 'Aigerding'
  },
  {
    postalCode: '4780',
    city: 'Allerheiligen'
  },
  {
    postalCode: '4780',
    city: 'Brunnwies'
  },
  {
    postalCode: '4780',
    city: 'Kreuzberg'
  },
  {
    postalCode: '4780',
    city: 'Schärding Innere Stadt'
  },
  {
    postalCode: '4780',
    city: 'Schärding Vorstadt'
  },
  {
    postalCode: '4782',
    city: 'Weizenau'
  },
  {
    postalCode: '4782',
    city: 'Aigerding'
  },
  {
    postalCode: '4782',
    city: 'Allerding'
  },
  {
    postalCode: '4782',
    city: 'Badhöring'
  },
  {
    postalCode: '4782',
    city: 'Bubing'
  },
  {
    postalCode: '4782',
    city: 'Buch'
  },
  {
    postalCode: '4782',
    city: 'Edt'
  },
  {
    postalCode: '4782',
    city: 'Etzelsdorf'
  },
  {
    postalCode: '4782',
    city: 'Gopperding'
  },
  {
    postalCode: '4782',
    city: 'Grub'
  },
  {
    postalCode: '4782',
    city: 'Haid'
  },
  {
    postalCode: '4782',
    city: 'Kalchgrub'
  },
  {
    postalCode: '4782',
    city: 'Oberhofen'
  },
  {
    postalCode: '4782',
    city: 'Oberteufenbach'
  },
  {
    postalCode: '4782',
    city: 'Otterbach'
  },
  {
    postalCode: '4782',
    city: 'Pramerdorf'
  },
  {
    postalCode: '4782',
    city: 'Pramhof'
  },
  {
    postalCode: '4782',
    city: 'Rahaberg'
  },
  {
    postalCode: '4782',
    city: 'Rainding'
  },
  {
    postalCode: '4782',
    city: 'Samberg'
  },
  {
    postalCode: '4782',
    city: 'Sankt Florian am Inn'
  },
  {
    postalCode: '4782',
    city: 'Steinbach'
  },
  {
    postalCode: '4782',
    city: 'Stocket'
  },
  {
    postalCode: '4782',
    city: 'Unterteufenbach'
  },
  {
    postalCode: '4782',
    city: 'Vielsassing'
  },
  {
    postalCode: '4783',
    city: 'Fraunhof'
  },
  {
    postalCode: '4783',
    city: 'Göpping'
  },
  {
    postalCode: '4783',
    city: 'Hofötz'
  },
  {
    postalCode: '4783',
    city: 'Linden'
  },
  {
    postalCode: '4783',
    city: 'Öhret'
  },
  {
    postalCode: '4783',
    city: 'Rutzenberg'
  },
  {
    postalCode: '4783',
    city: 'Sachsenberg'
  },
  {
    postalCode: '4783',
    city: 'Schafberg'
  },
  {
    postalCode: '4783',
    city: 'Stöbichen'
  },
  {
    postalCode: '4783',
    city: 'Wernstein am Inn'
  },
  {
    postalCode: '4783',
    city: 'Wimberg'
  },
  {
    postalCode: '4783',
    city: 'Zwickledt'
  },
  {
    postalCode: '4784',
    city: 'Kösslarn'
  },
  {
    postalCode: '4784',
    city: 'Achleiten'
  },
  {
    postalCode: '4784',
    city: 'Asing'
  },
  {
    postalCode: '4784',
    city: 'Bach'
  },
  {
    postalCode: '4784',
    city: 'Dierthalling'
  },
  {
    postalCode: '4784',
    city: 'Fraunhof'
  },
  {
    postalCode: '4784',
    city: 'Gattern'
  },
  {
    postalCode: '4784',
    city: 'Grub'
  },
  {
    postalCode: '4784',
    city: 'Ingling'
  },
  {
    postalCode: '4784',
    city: 'Lindenberg'
  },
  {
    postalCode: '4784',
    city: 'Luck'
  },
  {
    postalCode: '4784',
    city: 'Schardenberg'
  },
  {
    postalCode: '4784',
    city: 'Schönbach'
  },
  {
    postalCode: '4784',
    city: 'Winkl'
  },
  {
    postalCode: '4784',
    city: 'Amelreiching'
  },
  {
    postalCode: '4784',
    city: 'Edt'
  },
  {
    postalCode: '4784',
    city: 'Kinham'
  },
  {
    postalCode: '4784',
    city: 'Entholz'
  },
  {
    postalCode: '4784',
    city: 'Wibling'
  },
  {
    postalCode: '4785',
    city: 'Anzberg'
  },
  {
    postalCode: '4785',
    city: 'Freinberg'
  },
  {
    postalCode: '4785',
    city: 'Haibach'
  },
  {
    postalCode: '4785',
    city: 'Hanzing'
  },
  {
    postalCode: '4785',
    city: 'Hinding'
  },
  {
    postalCode: '4785',
    city: 'Kritzing'
  },
  {
    postalCode: '4785',
    city: 'Lehen'
  },
  {
    postalCode: '4785',
    city: 'Saming'
  },
  {
    postalCode: '4785',
    city: 'Bach'
  },
  {
    postalCode: '4786',
    city: 'Atzmanning'
  },
  {
    postalCode: '4786',
    city: 'Brunnenthal'
  },
  {
    postalCode: '4786',
    city: 'Brunnwies'
  },
  {
    postalCode: '4786',
    city: 'Dobl'
  },
  {
    postalCode: '4786',
    city: 'Eggersham'
  },
  {
    postalCode: '4786',
    city: 'Haraberg'
  },
  {
    postalCode: '4786',
    city: 'Hueb'
  },
  {
    postalCode: '4786',
    city: 'Kapfham'
  },
  {
    postalCode: '4786',
    city: 'Korneredt'
  },
  {
    postalCode: '4786',
    city: 'Kreuzberg'
  },
  {
    postalCode: '4786',
    city: 'Reikersberg'
  },
  {
    postalCode: '4786',
    city: 'Wallensham'
  },
  {
    postalCode: '4791',
    city: 'Haselbach'
  },
  {
    postalCode: '4791',
    city: 'Hauzing'
  },
  {
    postalCode: '4791',
    city: 'Hingsham'
  },
  {
    postalCode: '4791',
    city: 'Höretzberg'
  },
  {
    postalCode: '4791',
    city: 'Kapfham'
  },
  {
    postalCode: '4791',
    city: 'Ortenholz'
  },
  {
    postalCode: '4791',
    city: 'Pfaffing'
  },
  {
    postalCode: '4791',
    city: 'Rainbach im Innkreis'
  },
  {
    postalCode: '4791',
    city: 'Randolfing'
  },
  {
    postalCode: '4791',
    city: 'Salling'
  },
  {
    postalCode: '4791',
    city: 'Sinzing'
  },
  {
    postalCode: '4791',
    city: 'Steinberg'
  },
  {
    postalCode: '4791',
    city: 'Sumetsrad'
  },
  {
    postalCode: '4791',
    city: 'Wienering'
  },
  {
    postalCode: '4791',
    city: 'Luck'
  },
  {
    postalCode: '4791',
    city: 'Höbmannsbach'
  },
  {
    postalCode: '4791',
    city: 'Eggenberg'
  },
  {
    postalCode: '4792',
    city: 'Eisenbirn'
  },
  {
    postalCode: '4792',
    city: 'Eitzenberg'
  },
  {
    postalCode: '4792',
    city: 'Feicht'
  },
  {
    postalCode: '4792',
    city: 'Ficht'
  },
  {
    postalCode: '4792',
    city: 'Freundorf'
  },
  {
    postalCode: '4792',
    city: 'Füchsledt'
  },
  {
    postalCode: '4792',
    city: 'Geibing'
  },
  {
    postalCode: '4792',
    city: 'Hötzenberg'
  },
  {
    postalCode: '4792',
    city: 'Landertsberg'
  },
  {
    postalCode: '4792',
    city: 'Ludham'
  },
  {
    postalCode: '4792',
    city: 'Münzkirchen'
  },
  {
    postalCode: '4792',
    city: 'Prackenberg'
  },
  {
    postalCode: '4792',
    city: 'Raad'
  },
  {
    postalCode: '4792',
    city: 'Schießdorf'
  },
  {
    postalCode: '4792',
    city: 'Wilhelming'
  },
  {
    postalCode: '4792',
    city: 'Ginzlberg'
  },
  {
    postalCode: '4792',
    city: 'Langendorf'
  },
  {
    postalCode: '4792',
    city: 'Steinerzaun'
  },
  {
    postalCode: '4792',
    city: 'Englhaming'
  },
  {
    postalCode: '4793',
    city: 'Schnürberg'
  },
  {
    postalCode: '4793',
    city: 'Altendorf'
  },
  {
    postalCode: '4793',
    city: 'Aschenberg'
  },
  {
    postalCode: '4793',
    city: 'Au'
  },
  {
    postalCode: '4793',
    city: 'Danedt'
  },
  {
    postalCode: '4793',
    city: 'Ebertsberg'
  },
  {
    postalCode: '4793',
    city: 'Ginzldorf'
  },
  {
    postalCode: '4793',
    city: 'Höll'
  },
  {
    postalCode: '4793',
    city: 'Jetzingerdorf'
  },
  {
    postalCode: '4793',
    city: 'Kössldorf'
  },
  {
    postalCode: '4793',
    city: 'Oberndorf'
  },
  {
    postalCode: '4793',
    city: 'Penzingerdorf'
  },
  {
    postalCode: '4793',
    city: 'Razing'
  },
  {
    postalCode: '4793',
    city: 'Steinerzaun'
  },
  {
    postalCode: '4793',
    city: 'Watzing'
  },
  {
    postalCode: '4793',
    city: 'Wienetsdorf'
  },
  {
    postalCode: '4794',
    city: 'Kenading'
  },
  {
    postalCode: '4794',
    city: 'Beharding'
  },
  {
    postalCode: '4794',
    city: 'Dürnberg'
  },
  {
    postalCode: '4794',
    city: 'Engertsberg'
  },
  {
    postalCode: '4794',
    city: 'Entholz'
  },
  {
    postalCode: '4794',
    city: 'Glatzing'
  },
  {
    postalCode: '4794',
    city: 'Grafendorf'
  },
  {
    postalCode: '4794',
    city: 'Kahlberg'
  },
  {
    postalCode: '4794',
    city: 'Kimleinsdorf'
  },
  {
    postalCode: '4794',
    city: 'Knechtelsdorf'
  },
  {
    postalCode: '4794',
    city: 'Kopfingerdorf'
  },
  {
    postalCode: '4794',
    city: 'Kopfing im Innkreis'
  },
  {
    postalCode: '4794',
    city: 'Leithen'
  },
  {
    postalCode: '4794',
    city: 'Matzelsdorf'
  },
  {
    postalCode: '4794',
    city: 'Mitteredt'
  },
  {
    postalCode: '4794',
    city: 'Neukirchendorf'
  },
  {
    postalCode: '4794',
    city: 'Paulsdorf'
  },
  {
    postalCode: '4794',
    city: 'Pratztrum'
  },
  {
    postalCode: '4794',
    city: 'Raffelsdorf'
  },
  {
    postalCode: '4794',
    city: 'Rasdorf'
  },
  {
    postalCode: '4794',
    city: 'Ruholding'
  },
  {
    postalCode: '4794',
    city: 'Straß'
  },
  {
    postalCode: '4794',
    city: 'Wollmannsdorf'
  },
  {
    postalCode: '4800',
    city: 'Aham'
  },
  {
    postalCode: '4800',
    city: 'Aichet'
  },
  {
    postalCode: '4800',
    city: 'Attnang'
  },
  {
    postalCode: '4800',
    city: 'Hohenbaumgarten'
  },
  {
    postalCode: '4800',
    city: 'Landertsham'
  },
  {
    postalCode: '4800',
    city: 'Niederstraß'
  },
  {
    postalCode: '4800',
    city: 'Oberstraß'
  },
  {
    postalCode: '4800',
    city: 'Puchheim'
  },
  {
    postalCode: '4800',
    city: 'Sonnleithen'
  },
  {
    postalCode: '4800',
    city: 'Steinhübl'
  },
  {
    postalCode: '4800',
    city: 'Deutenham'
  },
  {
    postalCode: '4800',
    city: 'Sicking'
  },
  {
    postalCode: '4800',
    city: 'Unterpilsbach'
  },
  {
    postalCode: '4800',
    city: 'Altensam'
  },
  {
    postalCode: '4800',
    city: 'Lehen'
  },
  {
    postalCode: '4800',
    city: 'Moosham'
  },
  {
    postalCode: '4800',
    city: 'Pühret'
  },
  {
    postalCode: '4800',
    city: 'Schlierberg'
  },
  {
    postalCode: '4800',
    city: 'Redlham'
  },
  {
    postalCode: '4800',
    city: 'Wankham'
  },
  {
    postalCode: '4801',
    city: 'Nachdemsee'
  },
  {
    postalCode: '4801',
    city: 'Eben'
  },
  {
    postalCode: '4801',
    city: 'Mühlbach'
  },
  {
    postalCode: '4801',
    city: 'Traunkirchen'
  },
  {
    postalCode: '4802',
    city: 'Ebensee'
  },
  {
    postalCode: '4802',
    city: 'Kohlstatt'
  },
  {
    postalCode: '4802',
    city: 'Lahnstein'
  },
  {
    postalCode: '4802',
    city: 'Langwies'
  },
  {
    postalCode: '4802',
    city: 'Oberlangbath'
  },
  {
    postalCode: '4802',
    city: 'Offensee'
  },
  {
    postalCode: '4802',
    city: 'Rindbach'
  },
  {
    postalCode: '4802',
    city: 'Trauneck'
  },
  {
    postalCode: '4802',
    city: 'Unterlangbath'
  },
  {
    postalCode: '4802',
    city: 'Plankau'
  },
  {
    postalCode: '4802',
    city: 'Roith'
  },
  {
    postalCode: '4810',
    city: 'Altmünster'
  },
  {
    postalCode: '4810',
    city: 'Eck'
  },
  {
    postalCode: '4810',
    city: 'Gmunden'
  },
  {
    postalCode: '4810',
    city: 'Gschwandt'
  },
  {
    postalCode: '4810',
    city: 'Moosham'
  },
  {
    postalCode: '4810',
    city: 'Buchen'
  },
  {
    postalCode: '4810',
    city: 'Neuhofen'
  },
  {
    postalCode: '4812',
    city: 'Gmundnerberg'
  },
  {
    postalCode: '4812',
    city: 'Aurachkirchen'
  },
  {
    postalCode: '4812',
    city: 'Fraunsdorf'
  },
  {
    postalCode: '4812',
    city: 'Preinsdorf'
  },
  {
    postalCode: '4812',
    city: 'Purndorf'
  },
  {
    postalCode: '4812',
    city: 'Rittham'
  },
  {
    postalCode: '4812',
    city: 'Buchen'
  },
  {
    postalCode: '4812',
    city: 'Großkufhaus'
  },
  {
    postalCode: '4812',
    city: 'Innergrub'
  },
  {
    postalCode: '4812',
    city: 'Moos'
  },
  {
    postalCode: '4812',
    city: 'Neuhofen'
  },
  {
    postalCode: '4812',
    city: 'Pinsdorf'
  },
  {
    postalCode: '4812',
    city: 'Pinsdorfberg'
  },
  {
    postalCode: '4812',
    city: 'Steinbichl'
  },
  {
    postalCode: '4812',
    city: 'Wiesen'
  },
  {
    postalCode: '4812',
    city: 'Wolfsgrub'
  },
  {
    postalCode: '4812',
    city: 'Mairhof'
  },
  {
    postalCode: '4813',
    city: 'Altmünster'
  },
  {
    postalCode: '4813',
    city: 'Ebenzweier'
  },
  {
    postalCode: '4813',
    city: 'Eck'
  },
  {
    postalCode: '4813',
    city: 'Gmundnerberg'
  },
  {
    postalCode: '4813',
    city: 'Grasberg'
  },
  {
    postalCode: '4813',
    city: 'Nachdemsee'
  },
  {
    postalCode: '4813',
    city: 'Neukirchen'
  },
  {
    postalCode: '4813',
    city: 'Eben'
  },
  {
    postalCode: '4814',
    city: 'Gmundnerberg'
  },
  {
    postalCode: '4814',
    city: 'Grasberg'
  },
  {
    postalCode: '4814',
    city: 'Neukirchen'
  },
  {
    postalCode: '4814',
    city: 'Reindlmühl'
  },
  {
    postalCode: '4814',
    city: 'Kasten'
  },
  {
    postalCode: '4814',
    city: 'Eben'
  },
  {
    postalCode: '4814',
    city: 'Mühlbach'
  },
  {
    postalCode: '4816',
    city: 'Gschwandt'
  },
  {
    postalCode: '4816',
    city: 'Moosham'
  },
  {
    postalCode: '4816',
    city: 'Oberndorf'
  },
  {
    postalCode: '4816',
    city: 'Laakirchen'
  },
  {
    postalCode: '4817',
    city: 'Gschwandt'
  },
  {
    postalCode: '4817',
    city: 'Kogl'
  },
  {
    postalCode: '4817',
    city: 'Edt'
  },
  {
    postalCode: '4817',
    city: 'Sankt Konrad'
  },
  {
    postalCode: '4817',
    city: 'Steg'
  },
  {
    postalCode: '4817',
    city: 'Dorf'
  },
  {
    postalCode: '4820',
    city: 'Ahorn'
  },
  {
    postalCode: '4820',
    city: 'Bad Ischl'
  },
  {
    postalCode: '4820',
    city: 'Eck'
  },
  {
    postalCode: '4820',
    city: 'Haiden'
  },
  {
    postalCode: '4820',
    city: 'Hinterstein'
  },
  {
    postalCode: '4820',
    city: 'Jainzen'
  },
  {
    postalCode: '4820',
    city: 'Kaltenbach'
  },
  {
    postalCode: '4820',
    city: 'Kößlbach'
  },
  {
    postalCode: '4820',
    city: 'Kreutern'
  },
  {
    postalCode: '4820',
    city: 'Lindau'
  },
  {
    postalCode: '4820',
    city: 'Mitterweißenbach'
  },
  {
    postalCode: '4820',
    city: 'Perneck'
  },
  {
    postalCode: '4820',
    city: 'Reiterndorf'
  },
  {
    postalCode: '4820',
    city: 'Rettenbach'
  },
  {
    postalCode: '4820',
    city: 'Roith'
  },
  {
    postalCode: '4820',
    city: 'Steinbruch'
  },
  {
    postalCode: '4820',
    city: 'Steinfeld'
  },
  {
    postalCode: '4820',
    city: 'Sulzbach'
  },
  {
    postalCode: '4821',
    city: 'Kaltenbach'
  },
  {
    postalCode: '4821',
    city: 'Lauffen'
  },
  {
    postalCode: '4822',
    city: 'Görb'
  },
  {
    postalCode: '4822',
    city: 'Anzenau'
  },
  {
    postalCode: '4822',
    city: 'Au'
  },
  {
    postalCode: '4822',
    city: 'Edt'
  },
  {
    postalCode: '4822',
    city: 'Goisern'
  },
  {
    postalCode: '4822',
    city: 'Gschwandt'
  },
  {
    postalCode: '4822',
    city: 'Herndl'
  },
  {
    postalCode: '4822',
    city: 'Kogl'
  },
  {
    postalCode: '4822',
    city: 'Lasern'
  },
  {
    postalCode: '4822',
    city: 'Muth'
  },
  {
    postalCode: '4822',
    city: 'Obersee'
  },
  {
    postalCode: '4822',
    city: 'Pichlern'
  },
  {
    postalCode: '4822',
    city: 'Pötschen'
  },
  {
    postalCode: '4822',
    city: 'Posern'
  },
  {
    postalCode: '4822',
    city: 'Primesberg'
  },
  {
    postalCode: '4822',
    city: 'Ramsau'
  },
  {
    postalCode: '4822',
    city: 'Rehkogl'
  },
  {
    postalCode: '4822',
    city: 'Reitern'
  },
  {
    postalCode: '4822',
    city: 'Riedln'
  },
  {
    postalCode: '4822',
    city: 'Sarstein'
  },
  {
    postalCode: '4822',
    city: 'Solbach'
  },
  {
    postalCode: '4822',
    city: 'Stambach'
  },
  {
    postalCode: '4822',
    city: 'Steinach'
  },
  {
    postalCode: '4822',
    city: 'Unterjoch'
  },
  {
    postalCode: '4822',
    city: 'Untersee'
  },
  {
    postalCode: '4822',
    city: 'Weißenbach'
  },
  {
    postalCode: '4822',
    city: 'Wiesen'
  },
  {
    postalCode: '4822',
    city: 'Wildpfad'
  },
  {
    postalCode: '4822',
    city: 'Wurmstein'
  },
  {
    postalCode: '4823',
    city: 'Edt'
  },
  {
    postalCode: '4823',
    city: 'Obersee'
  },
  {
    postalCode: '4823',
    city: 'Pötschen'
  },
  {
    postalCode: '4823',
    city: 'Reitern'
  },
  {
    postalCode: '4823',
    city: 'Sankt Agatha'
  },
  {
    postalCode: '4823',
    city: 'Sarstein'
  },
  {
    postalCode: '4823',
    city: 'Stambach'
  },
  {
    postalCode: '4823',
    city: 'Steeg'
  },
  {
    postalCode: '4823',
    city: 'Untersee'
  },
  {
    postalCode: '4823',
    city: 'Gosauzwang'
  },
  {
    postalCode: '4824',
    city: 'Gosau'
  },
  {
    postalCode: '4825',
    city: 'Gosau'
  },
  {
    postalCode: '4830',
    city: 'Hallstatt'
  },
  {
    postalCode: '4830',
    city: 'Salzberg'
  },
  {
    postalCode: '4830',
    city: 'Lahn'
  },
  {
    postalCode: '4831',
    city: 'Obertraun'
  },
  {
    postalCode: '4831',
    city: 'Winkl'
  },
  {
    postalCode: '4840',
    city: 'Kirchstetten'
  },
  {
    postalCode: '4840',
    city: 'Oberpilsbach'
  },
  {
    postalCode: '4840',
    city: 'Untereinwald'
  },
  {
    postalCode: '4840',
    city: 'Unterpilsbach'
  },
  {
    postalCode: '4840',
    city: 'Am Landlberg'
  },
  {
    postalCode: '4840',
    city: 'Buchleiten'
  },
  {
    postalCode: '4840',
    city: 'Dörfl'
  },
  {
    postalCode: '4840',
    city: 'Dürnau'
  },
  {
    postalCode: '4840',
    city: 'Kirchberg'
  },
  {
    postalCode: '4840',
    city: 'Oberhaus'
  },
  {
    postalCode: '4840',
    city: 'Vöcklabruck'
  },
  {
    postalCode: '4840',
    city: 'Vornbuch'
  },
  {
    postalCode: '4840',
    city: 'Wagrain'
  },
  {
    postalCode: '4840',
    city: 'Wegscheid'
  },
  {
    postalCode: '4840',
    city: 'Ziegelwies'
  },
  {
    postalCode: '4841',
    city: 'Gasteig'
  },
  {
    postalCode: '4841',
    city: 'Scharedt'
  },
  {
    postalCode: '4841',
    city: 'Starling'
  },
  {
    postalCode: '4841',
    city: 'Einwald'
  },
  {
    postalCode: '4841',
    city: 'Kien'
  },
  {
    postalCode: '4841',
    city: 'Mittereinwald'
  },
  {
    postalCode: '4841',
    city: 'Schmidham'
  },
  {
    postalCode: '4841',
    city: 'Heitzing'
  },
  {
    postalCode: '4841',
    city: 'Heuweg'
  },
  {
    postalCode: '4841',
    city: 'Hocheck'
  },
  {
    postalCode: '4841',
    city: 'Dornet'
  },
  {
    postalCode: '4841',
    city: 'Ainwalding'
  },
  {
    postalCode: '4841',
    city: 'Billichsedt'
  },
  {
    postalCode: '4841',
    city: 'Brunau'
  },
  {
    postalCode: '4841',
    city: 'Brunngstaudet'
  },
  {
    postalCode: '4841',
    city: 'Engelsheim'
  },
  {
    postalCode: '4841',
    city: 'Fuchsberg'
  },
  {
    postalCode: '4841',
    city: 'Grillmoos'
  },
  {
    postalCode: '4841',
    city: 'Haag'
  },
  {
    postalCode: '4841',
    city: 'Hochmoos'
  },
  {
    postalCode: '4841',
    city: 'Jocheredt'
  },
  {
    postalCode: '4841',
    city: 'Kellner'
  },
  {
    postalCode: '4841',
    city: 'Kirchholz'
  },
  {
    postalCode: '4841',
    city: 'Kochberg'
  },
  {
    postalCode: '4841',
    city: 'Kronberg'
  },
  {
    postalCode: '4841',
    city: 'Mitterschlag'
  },
  {
    postalCode: '4841',
    city: 'Mösl'
  },
  {
    postalCode: '4841',
    city: 'Natternberg'
  },
  {
    postalCode: '4841',
    city: 'Obereinwald'
  },
  {
    postalCode: '4841',
    city: 'Oberleim'
  },
  {
    postalCode: '4841',
    city: 'Pohn'
  },
  {
    postalCode: '4841',
    city: 'Pohnedt'
  },
  {
    postalCode: '4841',
    city: 'Rametsberg'
  },
  {
    postalCode: '4841',
    city: 'Reichering'
  },
  {
    postalCode: '4841',
    city: 'Ungenach'
  },
  {
    postalCode: '4841',
    city: 'Unterleim'
  },
  {
    postalCode: '4841',
    city: 'Vorderschlag'
  },
  {
    postalCode: '4841',
    city: 'Zahnhof'
  },
  {
    postalCode: '4841',
    city: 'Zehentpoint'
  },
  {
    postalCode: '4841',
    city: 'Außerhafling'
  },
  {
    postalCode: '4841',
    city: 'Innerhafling'
  },
  {
    postalCode: '4842',
    city: 'Gewerbestraße'
  },
  {
    postalCode: '4842',
    city: 'Bruck'
  },
  {
    postalCode: '4842',
    city: 'Burgstall'
  },
  {
    postalCode: '4842',
    city: 'Ehwalchen'
  },
  {
    postalCode: '4842',
    city: 'Franzeneck'
  },
  {
    postalCode: '4842',
    city: 'Gerhardsberg'
  },
  {
    postalCode: '4842',
    city: 'Heinrichsberg'
  },
  {
    postalCode: '4842',
    city: 'Hinteredt'
  },
  {
    postalCode: '4842',
    city: 'Hinterschachen'
  },
  {
    postalCode: '4842',
    city: 'Hochrain'
  },
  {
    postalCode: '4842',
    city: 'Kalletsberg'
  },
  {
    postalCode: '4842',
    city: 'Ketzerhub'
  },
  {
    postalCode: '4842',
    city: 'Kopplbrenn'
  },
  {
    postalCode: '4842',
    city: 'Kreuth'
  },
  {
    postalCode: '4842',
    city: 'Pettenfirst'
  },
  {
    postalCode: '4842',
    city: 'Roith'
  },
  {
    postalCode: '4842',
    city: 'Schablberg'
  },
  {
    postalCode: '4842',
    city: 'Schierling'
  },
  {
    postalCode: '4842',
    city: 'Schwarzland'
  },
  {
    postalCode: '4842',
    city: 'Vornholz'
  },
  {
    postalCode: '4842',
    city: 'Wegleithen'
  },
  {
    postalCode: '4842',
    city: 'Wolfsdoppl'
  },
  {
    postalCode: '4842',
    city: 'Zell am Pettenfirst'
  },
  {
    postalCode: '4843',
    city: 'Aigen'
  },
  {
    postalCode: '4843',
    city: 'Ampflwang'
  },
  {
    postalCode: '4843',
    city: 'Buchleiten'
  },
  {
    postalCode: '4843',
    city: 'Eitzing'
  },
  {
    postalCode: '4843',
    city: 'Hinterschlagen'
  },
  {
    postalCode: '4843',
    city: 'Innerleiten'
  },
  {
    postalCode: '4843',
    city: 'Lukasberg'
  },
  {
    postalCode: '4843',
    city: 'Siedlung'
  },
  {
    postalCode: '4843',
    city: 'Ort'
  },
  {
    postalCode: '4843',
    city: 'Rabelsberg'
  },
  {
    postalCode: '4843',
    city: 'Rödleiten'
  },
  {
    postalCode: '4843',
    city: 'Schachen'
  },
  {
    postalCode: '4843',
    city: 'Scheiblwies'
  },
  {
    postalCode: '4843',
    city: 'Schmitzberg'
  },
  {
    postalCode: '4843',
    city: 'Vorderschlagen'
  },
  {
    postalCode: '4843',
    city: 'Waldpoint'
  },
  {
    postalCode: '4843',
    city: 'Wassenbach'
  },
  {
    postalCode: '4843',
    city: 'Wörmansedt'
  },
  {
    postalCode: '4844',
    city: 'Illingbuch'
  },
  {
    postalCode: '4844',
    city: 'Dornet'
  },
  {
    postalCode: '4844',
    city: 'Geidenberg'
  },
  {
    postalCode: '4844',
    city: 'Himmelreich'
  },
  {
    postalCode: '4844',
    city: 'Lahn'
  },
  {
    postalCode: '4844',
    city: 'Oberlixlau'
  },
  {
    postalCode: '4844',
    city: 'Oberregau'
  },
  {
    postalCode: '4844',
    city: 'Preising'
  },
  {
    postalCode: '4844',
    city: 'Pürstling'
  },
  {
    postalCode: '4844',
    city: 'Regau'
  },
  {
    postalCode: '4844',
    city: 'Riedl'
  },
  {
    postalCode: '4844',
    city: 'Roith'
  },
  {
    postalCode: '4844',
    city: 'Schacha'
  },
  {
    postalCode: '4844',
    city: 'Schalchham'
  },
  {
    postalCode: '4844',
    city: 'Schönberg'
  },
  {
    postalCode: '4844',
    city: 'Lixlau'
  },
  {
    postalCode: '4844',
    city: 'Zaissing'
  },
  {
    postalCode: '4845',
    city: 'Wiesen'
  },
  {
    postalCode: '4845',
    city: 'Alm'
  },
  {
    postalCode: '4845',
    city: 'Dorf'
  },
  {
    postalCode: '4845',
    city: 'Eck'
  },
  {
    postalCode: '4845',
    city: 'Hattenberg'
  },
  {
    postalCode: '4845',
    city: 'Hinterbuch'
  },
  {
    postalCode: '4845',
    city: 'Hub'
  },
  {
    postalCode: '4845',
    city: 'Kirchberg'
  },
  {
    postalCode: '4845',
    city: 'Mairhof'
  },
  {
    postalCode: '4845',
    city: 'Neudorf'
  },
  {
    postalCode: '4845',
    city: 'Oberkriech'
  },
  {
    postalCode: '4845',
    city: 'Pilling'
  },
  {
    postalCode: '4845',
    city: 'Reith'
  },
  {
    postalCode: '4845',
    city: 'Ritzing'
  },
  {
    postalCode: '4845',
    city: 'Rutzenmoos'
  },
  {
    postalCode: '4845',
    city: 'Stölln'
  },
  {
    postalCode: '4845',
    city: 'Tiefenweg'
  },
  {
    postalCode: '4845',
    city: 'Unterkriech'
  },
  {
    postalCode: '4845',
    city: 'Weiding'
  },
  {
    postalCode: '4846',
    city: 'Au'
  },
  {
    postalCode: '4846',
    city: 'Einwarting'
  },
  {
    postalCode: '4846',
    city: 'Erlau'
  },
  {
    postalCode: '4846',
    city: 'Fisching'
  },
  {
    postalCode: '4846',
    city: 'Hainprechting'
  },
  {
    postalCode: '4846',
    city: 'Jebing'
  },
  {
    postalCode: '4846',
    city: 'Landertsham'
  },
  {
    postalCode: '4846',
    city: 'Piesing'
  },
  {
    postalCode: '4846',
    city: 'Redlham'
  },
  {
    postalCode: '4846',
    city: 'Tuffeltsham'
  },
  {
    postalCode: '4850',
    city: 'Jochling'
  },
  {
    postalCode: '4850',
    city: 'Puchkirchen'
  },
  {
    postalCode: '4850',
    city: 'Ader'
  },
  {
    postalCode: '4850',
    city: 'Altwartenburg'
  },
  {
    postalCode: '4850',
    city: 'Außerungenach'
  },
  {
    postalCode: '4850',
    city: 'Eiding'
  },
  {
    postalCode: '4850',
    city: 'Gsteinedt'
  },
  {
    postalCode: '4850',
    city: 'Haag'
  },
  {
    postalCode: '4850',
    city: 'Kalchofen'
  },
  {
    postalCode: '4850',
    city: 'Leidern'
  },
  {
    postalCode: '4850',
    city: 'Maierhof'
  },
  {
    postalCode: '4850',
    city: 'Mühlfeld'
  },
  {
    postalCode: '4850',
    city: 'Neuwartenburg'
  },
  {
    postalCode: '4850',
    city: 'Oberau'
  },
  {
    postalCode: '4850',
    city: 'Obereck'
  },
  {
    postalCode: '4850',
    city: 'Obergallaberg'
  },
  {
    postalCode: '4850',
    city: 'Oberthalheim'
  },
  {
    postalCode: '4850',
    city: 'Pichlwang'
  },
  {
    postalCode: '4850',
    city: 'Stöfling'
  },
  {
    postalCode: '4850',
    city: 'Straß'
  },
  {
    postalCode: '4850',
    city: 'Timelkam'
  },
  {
    postalCode: '4850',
    city: 'Unterau'
  },
  {
    postalCode: '4850',
    city: 'Untergallaberg'
  },
  {
    postalCode: '4850',
    city: 'Wimberg'
  },
  {
    postalCode: '4850',
    city: 'Grillmoos'
  },
  {
    postalCode: '4851',
    city: 'Baumgarting'
  },
  {
    postalCode: '4851',
    city: 'Bergham'
  },
  {
    postalCode: '4851',
    city: 'Bierbaum'
  },
  {
    postalCode: '4851',
    city: 'Egning'
  },
  {
    postalCode: '4851',
    city: 'Fischham'
  },
  {
    postalCode: '4851',
    city: 'Fischhamering'
  },
  {
    postalCode: '4851',
    city: 'Gallnbrunn'
  },
  {
    postalCode: '4851',
    city: 'Gampern'
  },
  {
    postalCode: '4851',
    city: 'Genstetten'
  },
  {
    postalCode: '4851',
    city: 'Haunolding'
  },
  {
    postalCode: '4851',
    city: 'Hehenberg'
  },
  {
    postalCode: '4851',
    city: 'Hörgattern'
  },
  {
    postalCode: '4851',
    city: 'Koberg'
  },
  {
    postalCode: '4851',
    city: 'Oberheikerding'
  },
  {
    postalCode: '4851',
    city: 'Piesdorf'
  },
  {
    postalCode: '4851',
    city: 'Pöring'
  },
  {
    postalCode: '4851',
    city: 'Schwarzmoos'
  },
  {
    postalCode: '4851',
    city: 'Siedling'
  },
  {
    postalCode: '4851',
    city: 'Stein'
  },
  {
    postalCode: '4851',
    city: 'Stötten'
  },
  {
    postalCode: '4851',
    city: 'Unterheikerding'
  },
  {
    postalCode: '4851',
    city: 'Viehaus'
  },
  {
    postalCode: '4851',
    city: 'Weiterschwang'
  },
  {
    postalCode: '4851',
    city: 'Witzling'
  },
  {
    postalCode: '4851',
    city: 'Steindorf'
  },
  {
    postalCode: '4852',
    city: 'Alexenau'
  },
  {
    postalCode: '4852',
    city: 'Bach'
  },
  {
    postalCode: '4852',
    city: 'Gahberg'
  },
  {
    postalCode: '4852',
    city: 'Miglberg'
  },
  {
    postalCode: '4852',
    city: 'Reichholz'
  },
  {
    postalCode: '4852',
    city: 'Seeberg'
  },
  {
    postalCode: '4852',
    city: 'Steinwand'
  },
  {
    postalCode: '4852',
    city: 'Weyregg am Attersee'
  },
  {
    postalCode: '4853',
    city: 'Berg'
  },
  {
    postalCode: '4853',
    city: 'Blümigen'
  },
  {
    postalCode: '4853',
    city: 'Dorf'
  },
  {
    postalCode: '4853',
    city: 'Feld'
  },
  {
    postalCode: '4853',
    city: 'Haslach'
  },
  {
    postalCode: '4853',
    city: 'Hochlecken'
  },
  {
    postalCode: '4853',
    city: 'Kaisigen'
  },
  {
    postalCode: '4853',
    city: 'Kienklause'
  },
  {
    postalCode: '4853',
    city: 'Kiental'
  },
  {
    postalCode: '4853',
    city: 'Oberfeichten'
  },
  {
    postalCode: '4853',
    city: 'Seefeld'
  },
  {
    postalCode: '4853',
    city: 'Steinbach am Attersee'
  },
  {
    postalCode: '4853',
    city: 'Unterfeichten'
  },
  {
    postalCode: '4853',
    city: 'Unterroith'
  },
  {
    postalCode: '4854',
    city: 'Unterburgau'
  },
  {
    postalCode: '4854',
    city: 'Forstamt'
  },
  {
    postalCode: '4854',
    city: 'Gmauret'
  },
  {
    postalCode: '4854',
    city: 'Weißenbach am Attersee'
  },
  {
    postalCode: '4860',
    city: 'Alt Lenzing'
  },
  {
    postalCode: '4860',
    city: 'Haid'
  },
  {
    postalCode: '4860',
    city: 'Kraims'
  },
  {
    postalCode: '4860',
    city: 'Neuhausen'
  },
  {
    postalCode: '4860',
    city: 'Oberachmann'
  },
  {
    postalCode: '4860',
    city: 'Lenzing'
  },
  {
    postalCode: '4860',
    city: 'Pichlwang'
  },
  {
    postalCode: '4860',
    city: 'Raudaschlmühle'
  },
  {
    postalCode: '4860',
    city: 'Reibersdorf'
  },
  {
    postalCode: '4860',
    city: 'Thal'
  },
  {
    postalCode: '4860',
    city: 'Ulrichsberg'
  },
  {
    postalCode: '4860',
    city: 'Unterachmann'
  },
  {
    postalCode: '4860',
    city: 'Burgstall'
  },
  {
    postalCode: '4860',
    city: 'Obereck'
  },
  {
    postalCode: '4860',
    city: 'Untereck'
  },
  {
    postalCode: '4860',
    city: 'Untergallaberg'
  },
  {
    postalCode: '4861',
    city: 'Aurach am Hongar'
  },
  {
    postalCode: '4861',
    city: 'Grafenbuch'
  },
  {
    postalCode: '4861',
    city: 'Hainbach'
  },
  {
    postalCode: '4861',
    city: 'Halbmoos'
  },
  {
    postalCode: '4861',
    city: 'Illingbuch'
  },
  {
    postalCode: '4861',
    city: 'Jetzing'
  },
  {
    postalCode: '4861',
    city: 'Kasten'
  },
  {
    postalCode: '4861',
    city: 'Looh'
  },
  {
    postalCode: '4861',
    city: 'Pranzing'
  },
  {
    postalCode: '4861',
    city: 'Raschbach'
  },
  {
    postalCode: '4861',
    city: 'Weichselbaum'
  },
  {
    postalCode: '4861',
    city: 'Oberachmann'
  },
  {
    postalCode: '4861',
    city: 'Fantaberg'
  },
  {
    postalCode: '4861',
    city: 'Kammer'
  },
  {
    postalCode: '4861',
    city: 'Marktwald'
  },
  {
    postalCode: '4861',
    city: 'Moos'
  },
  {
    postalCode: '4861',
    city: 'Niederham'
  },
  {
    postalCode: '4861',
    city: 'Oberhehenfeld'
  },
  {
    postalCode: '4861',
    city: 'Schörfling'
  },
  {
    postalCode: '4861',
    city: 'Steinbach'
  },
  {
    postalCode: '4861',
    city: 'Sulzberg'
  },
  {
    postalCode: '4861',
    city: 'Wörzing'
  },
  {
    postalCode: '4861',
    city: 'Gahberg'
  },
  {
    postalCode: '4863',
    city: 'Baum'
  },
  {
    postalCode: '4863',
    city: 'Ainwalchen'
  },
  {
    postalCode: '4863',
    city: 'Buchberg'
  },
  {
    postalCode: '4863',
    city: 'Gerlham'
  },
  {
    postalCode: '4863',
    city: 'Haidach'
  },
  {
    postalCode: '4863',
    city: 'Haining'
  },
  {
    postalCode: '4863',
    city: 'Kemating'
  },
  {
    postalCode: '4863',
    city: 'Kraims'
  },
  {
    postalCode: '4863',
    city: 'Litzlberg'
  },
  {
    postalCode: '4863',
    city: 'Moos'
  },
  {
    postalCode: '4863',
    city: 'Neißing'
  },
  {
    postalCode: '4863',
    city: 'Neubrunn'
  },
  {
    postalCode: '4863',
    city: 'Pettighofen'
  },
  {
    postalCode: '4863',
    city: 'Reichersberg'
  },
  {
    postalCode: '4863',
    city: 'Roitham'
  },
  {
    postalCode: '4863',
    city: 'Seewalchen am Attersee'
  },
  {
    postalCode: '4863',
    city: 'Staudach'
  },
  {
    postalCode: '4863',
    city: 'Steindorf'
  },
  {
    postalCode: '4863',
    city: 'Unterbuchberg'
  },
  {
    postalCode: '4864',
    city: 'Abtsdorf'
  },
  {
    postalCode: '4864',
    city: 'Attersee'
  },
  {
    postalCode: '4864',
    city: 'Aufham'
  },
  {
    postalCode: '4864',
    city: 'Mühlbach'
  },
  {
    postalCode: '4864',
    city: 'Neuhofen'
  },
  {
    postalCode: '4864',
    city: 'Oberbach'
  },
  {
    postalCode: '4864',
    city: 'Palmsdorf'
  },
  {
    postalCode: '4865',
    city: 'Altenberg'
  },
  {
    postalCode: '4865',
    city: 'Breitenröth'
  },
  {
    postalCode: '4865',
    city: 'Aich'
  },
  {
    postalCode: '4865',
    city: 'Aichereben'
  },
  {
    postalCode: '4865',
    city: 'Dexelbach'
  },
  {
    postalCode: '4865',
    city: 'Lichtenbuch'
  },
  {
    postalCode: '4865',
    city: 'Limberg'
  },
  {
    postalCode: '4865',
    city: 'Nußdorf am Attersee'
  },
  {
    postalCode: '4865',
    city: 'Parschallen'
  },
  {
    postalCode: '4865',
    city: 'Reith'
  },
  {
    postalCode: '4865',
    city: 'Streit'
  },
  {
    postalCode: '4865',
    city: 'Zell'
  },
  {
    postalCode: '4866',
    city: 'Au'
  },
  {
    postalCode: '4866',
    city: 'Oberburgau'
  },
  {
    postalCode: '4866',
    city: 'Unterburgau'
  },
  {
    postalCode: '4866',
    city: 'Buchenort'
  },
  {
    postalCode: '4866',
    city: 'Unterach'
  },
  {
    postalCode: '4870',
    city: 'Steinberg'
  },
  {
    postalCode: '4870',
    city: 'Mauracherberg'
  },
  {
    postalCode: '4870',
    city: 'Fischham'
  },
  {
    postalCode: '4870',
    city: 'Frieding'
  },
  {
    postalCode: '4870',
    city: 'Graben'
  },
  {
    postalCode: '4870',
    city: 'Hainberg'
  },
  {
    postalCode: '4870',
    city: 'Hainleiten'
  },
  {
    postalCode: '4870',
    city: 'Hausham'
  },
  {
    postalCode: '4870',
    city: 'Holzpoint'
  },
  {
    postalCode: '4870',
    city: 'Kropfling'
  },
  {
    postalCode: '4870',
    city: 'Maurachen'
  },
  {
    postalCode: '4870',
    city: 'Mesnerleiten'
  },
  {
    postalCode: '4870',
    city: 'Mitterberg'
  },
  {
    postalCode: '4870',
    city: 'Nindorf'
  },
  {
    postalCode: '4870',
    city: 'Oberkogl'
  },
  {
    postalCode: '4870',
    city: 'Pfaffing'
  },
  {
    postalCode: '4870',
    city: 'Schweiber'
  },
  {
    postalCode: '4870',
    city: 'Teicht'
  },
  {
    postalCode: '4870',
    city: 'Unterkogl'
  },
  {
    postalCode: '4870',
    city: 'Weixlbaum'
  },
  {
    postalCode: '4870',
    city: 'Weixlbaumerberg'
  },
  {
    postalCode: '4870',
    city: 'Ziegelhaid'
  },
  {
    postalCode: '4870',
    city: 'Sonnleiten'
  },
  {
    postalCode: '4870',
    city: 'Aierzelten'
  },
  {
    postalCode: '4870',
    city: 'Gopprechting'
  },
  {
    postalCode: '4870',
    city: 'Gries'
  },
  {
    postalCode: '4870',
    city: 'Gründberg'
  },
  {
    postalCode: '4870',
    city: 'Haid'
  },
  {
    postalCode: '4870',
    city: 'Hörading'
  },
  {
    postalCode: '4870',
    city: 'Hötzing'
  },
  {
    postalCode: '4870',
    city: 'Kalvarienberg'
  },
  {
    postalCode: '4870',
    city: 'Krichpoint'
  },
  {
    postalCode: '4870',
    city: 'Landberg'
  },
  {
    postalCode: '4870',
    city: 'Maulham'
  },
  {
    postalCode: '4870',
    city: 'Mörasing'
  },
  {
    postalCode: '4870',
    city: 'Mösendorf'
  },
  {
    postalCode: '4870',
    city: 'Mösenthal'
  },
  {
    postalCode: '4870',
    city: 'Moos'
  },
  {
    postalCode: '4870',
    city: 'Mühlreith'
  },
  {
    postalCode: '4870',
    city: 'Reichenthalheim'
  },
  {
    postalCode: '4870',
    city: 'Schmidham'
  },
  {
    postalCode: '4870',
    city: 'Spielberg'
  },
  {
    postalCode: '4870',
    city: 'Thal'
  },
  {
    postalCode: '4870',
    city: 'Unteralberting'
  },
  {
    postalCode: '4870',
    city: 'Unterholz'
  },
  {
    postalCode: '4870',
    city: 'Viecht'
  },
  {
    postalCode: '4870',
    city: 'Vöcklamarkt'
  },
  {
    postalCode: '4870',
    city: 'Walchen'
  },
  {
    postalCode: '4870',
    city: 'Walkering'
  },
  {
    postalCode: '4870',
    city: 'Waschprechting'
  },
  {
    postalCode: '4870',
    city: 'Waschprechtingerberg'
  },
  {
    postalCode: '4870',
    city: 'Wies'
  },
  {
    postalCode: '4870',
    city: 'Wilding'
  },
  {
    postalCode: '4871',
    city: 'Außerhörgersteig'
  },
  {
    postalCode: '4871',
    city: 'Egg'
  },
  {
    postalCode: '4871',
    city: 'Exlwöhr'
  },
  {
    postalCode: '4871',
    city: 'Haslach'
  },
  {
    postalCode: '4871',
    city: 'Haslau'
  },
  {
    postalCode: '4871',
    city: 'Mühlstaudet'
  },
  {
    postalCode: '4871',
    city: 'Tiefenbach'
  },
  {
    postalCode: '4871',
    city: 'Unterau'
  },
  {
    postalCode: '4871',
    city: 'Zeiling'
  },
  {
    postalCode: '4871',
    city: 'Arnberg'
  },
  {
    postalCode: '4871',
    city: 'Dorf'
  },
  {
    postalCode: '4871',
    city: 'Kappligen'
  },
  {
    postalCode: '4871',
    city: 'Neudorf'
  },
  {
    postalCode: '4871',
    city: 'Pollhammeredt'
  },
  {
    postalCode: '4871',
    city: 'Ragereck'
  },
  {
    postalCode: '4871',
    city: 'Zipf'
  },
  {
    postalCode: '4871',
    city: 'Bachleiten'
  },
  {
    postalCode: '4871',
    city: 'Pfefferberg'
  },
  {
    postalCode: '4871',
    city: 'Satteltal'
  },
  {
    postalCode: '4871',
    city: 'Kienleiten'
  },
  {
    postalCode: '4871',
    city: 'Langwies'
  },
  {
    postalCode: '4871',
    city: 'Redl'
  },
  {
    postalCode: '4871',
    city: 'Rohrwies'
  },
  {
    postalCode: '4872',
    city: 'Höllersberg'
  },
  {
    postalCode: '4872',
    city: 'Ackersberg'
  },
  {
    postalCode: '4872',
    city: 'Biber'
  },
  {
    postalCode: '4872',
    city: 'Dachschwendau'
  },
  {
    postalCode: '4872',
    city: 'Endriegl'
  },
  {
    postalCode: '4872',
    city: 'Froschern'
  },
  {
    postalCode: '4872',
    city: 'Haid'
  },
  {
    postalCode: '4872',
    city: 'Jagersberg'
  },
  {
    postalCode: '4872',
    city: 'Kogl'
  },
  {
    postalCode: '4872',
    city: 'Kolopfern'
  },
  {
    postalCode: '4872',
    city: 'Lichtenegg'
  },
  {
    postalCode: '4872',
    city: 'Meislgrub'
  },
  {
    postalCode: '4872',
    city: 'Mixental'
  },
  {
    postalCode: '4872',
    city: 'Mühlleiten'
  },
  {
    postalCode: '4872',
    city: 'Neukirchen an der Vöckla'
  },
  {
    postalCode: '4872',
    city: 'Oberthumberg'
  },
  {
    postalCode: '4872',
    city: 'Raschbach'
  },
  {
    postalCode: '4872',
    city: 'Redl'
  },
  {
    postalCode: '4872',
    city: 'Seirigen'
  },
  {
    postalCode: '4872',
    city: 'Spöck'
  },
  {
    postalCode: '4872',
    city: 'Stipplmühl'
  },
  {
    postalCode: '4872',
    city: 'Teufligen'
  },
  {
    postalCode: '4872',
    city: 'Unterthumberg'
  },
  {
    postalCode: '4872',
    city: 'Verwang'
  },
  {
    postalCode: '4872',
    city: 'Waltersdorf'
  },
  {
    postalCode: '4872',
    city: 'Wegleiten'
  },
  {
    postalCode: '4872',
    city: 'Welsern'
  },
  {
    postalCode: '4872',
    city: 'Weyr'
  },
  {
    postalCode: '4872',
    city: 'Wimm'
  },
  {
    postalCode: '4872',
    city: 'Windbichl'
  },
  {
    postalCode: '4872',
    city: 'Winteredt'
  },
  {
    postalCode: '4872',
    city: 'Wöhr'
  },
  {
    postalCode: '4872',
    city: 'Zuckau'
  },
  {
    postalCode: '4872',
    city: 'Sonnleiten'
  },
  {
    postalCode: '4873',
    city: 'Arbing'
  },
  {
    postalCode: '4873',
    city: 'Au'
  },
  {
    postalCode: '4873',
    city: 'Badstuben'
  },
  {
    postalCode: '4873',
    city: 'Brunnhölzl'
  },
  {
    postalCode: '4873',
    city: 'Diemröth'
  },
  {
    postalCode: '4873',
    city: 'Dorf'
  },
  {
    postalCode: '4873',
    city: 'Engern'
  },
  {
    postalCode: '4873',
    city: 'Erdpries'
  },
  {
    postalCode: '4873',
    city: 'Erlatwaid'
  },
  {
    postalCode: '4873',
    city: 'Finkenröth'
  },
  {
    postalCode: '4873',
    city: 'Fischeredt'
  },
  {
    postalCode: '4873',
    city: 'Fischigen'
  },
  {
    postalCode: '4873',
    city: 'Frankenburg am Hausruck'
  },
  {
    postalCode: '4873',
    city: 'Friedhalbing'
  },
  {
    postalCode: '4873',
    city: 'Geldigen'
  },
  {
    postalCode: '4873',
    city: 'Göblberg'
  },
  {
    postalCode: '4873',
    city: 'Grünbergsiedlung'
  },
  {
    postalCode: '4873',
    city: 'Halt'
  },
  {
    postalCode: '4873',
    city: 'Hintersteining'
  },
  {
    postalCode: '4873',
    city: 'Hoblschlag'
  },
  {
    postalCode: '4873',
    city: 'Hofberg'
  },
  {
    postalCode: '4873',
    city: 'Innerhörgersteig'
  },
  {
    postalCode: '4873',
    city: 'Innerleiten'
  },
  {
    postalCode: '4873',
    city: 'Kinast'
  },
  {
    postalCode: '4873',
    city: 'Klanigen'
  },
  {
    postalCode: '4873',
    city: 'Leitrachstätten'
  },
  {
    postalCode: '4873',
    city: 'Lessigen'
  },
  {
    postalCode: '4873',
    city: 'Loixigen'
  },
  {
    postalCode: '4873',
    city: 'Märzigen'
  },
  {
    postalCode: '4873',
    city: 'Marigen'
  },
  {
    postalCode: '4873',
    city: 'Mauern'
  },
  {
    postalCode: '4873',
    city: 'Mayrhof'
  },
  {
    postalCode: '4873',
    city: 'Mitterriegl'
  },
  {
    postalCode: '4873',
    city: 'Niederriegl'
  },
  {
    postalCode: '4873',
    city: 'Oberedt'
  },
  {
    postalCode: '4873',
    city: 'Oberfeitzing'
  },
  {
    postalCode: '4873',
    city: 'Oberhaselbach'
  },
  {
    postalCode: '4873',
    city: 'Ottigen'
  },
  {
    postalCode: '4873',
    city: 'Ottokönigen'
  },
  {
    postalCode: '4873',
    city: 'Pehigen'
  },
  {
    postalCode: '4873',
    city: 'Perschling'
  },
  {
    postalCode: '4873',
    city: 'Point'
  },
  {
    postalCode: '4873',
    city: 'Pramegg'
  },
  {
    postalCode: '4873',
    city: 'Raitenberg'
  },
  {
    postalCode: '4873',
    city: 'Redltal'
  },
  {
    postalCode: '4873',
    city: 'Renigen'
  },
  {
    postalCode: '4873',
    city: 'Schnöllhof'
  },
  {
    postalCode: '4873',
    city: 'Seibrigen'
  },
  {
    postalCode: '4873',
    city: 'Stöckert'
  },
  {
    postalCode: '4873',
    city: 'Unteredt'
  },
  {
    postalCode: '4873',
    city: 'Unterfeitzing'
  },
  {
    postalCode: '4873',
    city: 'Unterhaselbach'
  },
  {
    postalCode: '4873',
    city: 'Vordersteining'
  },
  {
    postalCode: '4873',
    city: 'Wiederhals'
  },
  {
    postalCode: '4873',
    city: 'Zachleiten'
  },
  {
    postalCode: '4873',
    city: 'Endriegl'
  },
  {
    postalCode: '4873',
    city: 'Rothauptberg'
  },
  {
    postalCode: '4873',
    city: 'Oberegg'
  },
  {
    postalCode: '4873',
    city: 'Erkaburgen'
  },
  {
    postalCode: '4873',
    city: 'Hilprigen'
  },
  {
    postalCode: '4873',
    city: 'Otzigen'
  },
  {
    postalCode: '4873',
    city: 'Redleiten'
  },
  {
    postalCode: '4873',
    city: 'Schweinegg'
  },
  {
    postalCode: '4873',
    city: 'Winkl'
  },
  {
    postalCode: '4873',
    city: 'Aubach'
  },
  {
    postalCode: '4880',
    city: 'Baum'
  },
  {
    postalCode: '4880',
    city: 'Berg im Attergau'
  },
  {
    postalCode: '4880',
    city: 'Brandham'
  },
  {
    postalCode: '4880',
    city: 'Eggenberg'
  },
  {
    postalCode: '4880',
    city: 'Eisenpalmsdorf'
  },
  {
    postalCode: '4880',
    city: 'Engljähring'
  },
  {
    postalCode: '4880',
    city: 'Hipping'
  },
  {
    postalCode: '4880',
    city: 'Jedlham'
  },
  {
    postalCode: '4880',
    city: 'Katterlohen'
  },
  {
    postalCode: '4880',
    city: 'Pössing'
  },
  {
    postalCode: '4880',
    city: 'Raith'
  },
  {
    postalCode: '4880',
    city: 'Rixing'
  },
  {
    postalCode: '4880',
    city: 'Rubensdorf'
  },
  {
    postalCode: '4880',
    city: 'Thanham'
  },
  {
    postalCode: '4880',
    city: 'Walsberg'
  },
  {
    postalCode: '4880',
    city: 'Wötzing'
  },
  {
    postalCode: '4880',
    city: 'Königswiesen'
  },
  {
    postalCode: '4880',
    city: 'Aich'
  },
  {
    postalCode: '4880',
    city: 'Alkersdorf'
  },
  {
    postalCode: '4880',
    city: 'Bergham'
  },
  {
    postalCode: '4880',
    city: 'Buch'
  },
  {
    postalCode: '4880',
    city: 'Kogl'
  },
  {
    postalCode: '4880',
    city: 'Lohen'
  },
  {
    postalCode: '4880',
    city: 'Sankt Georgen im Attergau'
  },
  {
    postalCode: '4880',
    city: 'Thalham'
  },
  {
    postalCode: '4880',
    city: 'Thern'
  },
  {
    postalCode: '4880',
    city: 'Lohened'
  },
  {
    postalCode: '4880',
    city: 'Staudach'
  },
  {
    postalCode: '4880',
    city: 'Erlat'
  },
  {
    postalCode: '4880',
    city: 'Innerlohen'
  },
  {
    postalCode: '4880',
    city: 'Lichtenberg'
  },
  {
    postalCode: '4880',
    city: 'Stöttham'
  },
  {
    postalCode: '4880',
    city: 'Wildenhag'
  },
  {
    postalCode: '4881',
    city: 'Erlat'
  },
  {
    postalCode: '4881',
    city: 'Halt'
  },
  {
    postalCode: '4881',
    city: 'Leming'
  },
  {
    postalCode: '4881',
    city: 'Mitterleiten'
  },
  {
    postalCode: '4881',
    city: 'Oberleiten'
  },
  {
    postalCode: '4881',
    city: 'Pabing'
  },
  {
    postalCode: '4881',
    city: 'Powang'
  },
  {
    postalCode: '4881',
    city: 'Sagerer'
  },
  {
    postalCode: '4881',
    city: 'Straß im Attergau'
  },
  {
    postalCode: '4881',
    city: 'Wald'
  },
  {
    postalCode: '4881',
    city: 'Fronbühel'
  },
  {
    postalCode: '4882',
    city: 'Gessenschwandt'
  },
  {
    postalCode: '4882',
    city: 'Grossenschwandt'
  },
  {
    postalCode: '4882',
    city: 'Oberaschau'
  },
  {
    postalCode: '4882',
    city: 'Oberwang'
  },
  {
    postalCode: '4882',
    city: 'Radau'
  },
  {
    postalCode: '4882',
    city: 'Traschwandt'
  },
  {
    postalCode: '4890',
    city: 'Piereth'
  },
  {
    postalCode: '4890',
    city: 'Röth'
  },
  {
    postalCode: '4890',
    city: 'Asten'
  },
  {
    postalCode: '4890',
    city: 'Auleiten'
  },
  {
    postalCode: '4890',
    city: 'Buchscharten'
  },
  {
    postalCode: '4890',
    city: 'Danzenreith'
  },
  {
    postalCode: '4890',
    city: 'Frankenmarkt'
  },
  {
    postalCode: '4890',
    city: 'Gries'
  },
  {
    postalCode: '4890',
    city: 'Gstocket'
  },
  {
    postalCode: '4890',
    city: 'Haitzenthal'
  },
  {
    postalCode: '4890',
    city: 'Hauchhorn'
  },
  {
    postalCode: '4890',
    city: 'Höhenwarth'
  },
  {
    postalCode: '4890',
    city: 'Hussenreith'
  },
  {
    postalCode: '4890',
    city: 'Kritzing'
  },
  {
    postalCode: '4890',
    city: 'Kühschinken'
  },
  {
    postalCode: '4890',
    city: 'Moos'
  },
  {
    postalCode: '4890',
    city: 'Mühlberg'
  },
  {
    postalCode: '4890',
    city: 'Pointen'
  },
  {
    postalCode: '4890',
    city: 'Raspoldsedt'
  },
  {
    postalCode: '4890',
    city: 'Rudlberg'
  },
  {
    postalCode: '4890',
    city: 'Schwertfern'
  },
  {
    postalCode: '4890',
    city: 'Stauf'
  },
  {
    postalCode: '4890',
    city: 'Unterrain'
  },
  {
    postalCode: '4890',
    city: 'Wimm'
  },
  {
    postalCode: '4890',
    city: 'Mösendorf'
  },
  {
    postalCode: '4890',
    city: 'Brandstatt'
  },
  {
    postalCode: '4890',
    city: 'Egg'
  },
  {
    postalCode: '4890',
    city: 'Freudenthal'
  },
  {
    postalCode: '4890',
    city: 'Geßlingen'
  },
  {
    postalCode: '4890',
    city: 'Giga'
  },
  {
    postalCode: '4890',
    city: 'Haitigen'
  },
  {
    postalCode: '4890',
    city: 'Hölleiten'
  },
  {
    postalCode: '4890',
    city: 'Pabigen'
  },
  {
    postalCode: '4890',
    city: 'Reittern'
  },
  {
    postalCode: '4890',
    city: 'Schwaigern'
  },
  {
    postalCode: '4890',
    city: 'Stadln'
  },
  {
    postalCode: '4890',
    city: 'Steinwand'
  },
  {
    postalCode: '4890',
    city: 'Truchtlingen'
  },
  {
    postalCode: '4890',
    city: 'Tuttingen'
  },
  {
    postalCode: '4890',
    city: 'Weißenkirchen im Attergau'
  },
  {
    postalCode: '4890',
    city: 'Wieneröth'
  },
  {
    postalCode: '4890',
    city: 'Ziegelstadl'
  },
  {
    postalCode: '4891',
    city: 'Höhenwarth'
  },
  {
    postalCode: '4891',
    city: 'Bergham'
  },
  {
    postalCode: '4891',
    city: 'Fellern'
  },
  {
    postalCode: '4891',
    city: 'Forstern'
  },
  {
    postalCode: '4891',
    city: 'Gaisteig'
  },
  {
    postalCode: '4891',
    city: 'Geretseck'
  },
  {
    postalCode: '4891',
    city: 'Haberpoint'
  },
  {
    postalCode: '4891',
    city: 'Haidach'
  },
  {
    postalCode: '4891',
    city: 'Hechfeld'
  },
  {
    postalCode: '4891',
    city: 'Kirchham'
  },
  {
    postalCode: '4891',
    city: 'Matzlröth'
  },
  {
    postalCode: '4891',
    city: 'Nößlthal'
  },
  {
    postalCode: '4891',
    city: 'Obermühlham'
  },
  {
    postalCode: '4891',
    city: 'Oberschwand'
  },
  {
    postalCode: '4891',
    city: 'Pading'
  },
  {
    postalCode: '4891',
    city: 'Plain'
  },
  {
    postalCode: '4891',
    city: 'Pöndorf'
  },
  {
    postalCode: '4891',
    city: 'Preinröth'
  },
  {
    postalCode: '4891',
    city: 'Schachen'
  },
  {
    postalCode: '4891',
    city: 'Schwaigern'
  },
  {
    postalCode: '4891',
    city: 'Untermühlham'
  },
  {
    postalCode: '4891',
    city: 'Unterreith'
  },
  {
    postalCode: '4891',
    city: 'Unterschwand'
  },
  {
    postalCode: '4891',
    city: 'Volkerding'
  },
  {
    postalCode: '4891',
    city: 'Landgraben'
  },
  {
    postalCode: '4891',
    city: 'Brunnwies'
  },
  {
    postalCode: '4892',
    city: 'Adligen'
  },
  {
    postalCode: '4892',
    city: 'Doppelmühle'
  },
  {
    postalCode: '4892',
    city: 'Emming'
  },
  {
    postalCode: '4892',
    city: 'Fachberg'
  },
  {
    postalCode: '4892',
    city: 'Feichtenberg'
  },
  {
    postalCode: '4892',
    city: 'Fornach'
  },
  {
    postalCode: '4892',
    city: 'Gferreth'
  },
  {
    postalCode: '4892',
    city: 'Gmeineck'
  },
  {
    postalCode: '4892',
    city: 'Grilln'
  },
  {
    postalCode: '4892',
    city: 'Grillnpoint'
  },
  {
    postalCode: '4892',
    city: 'Grubleiten'
  },
  {
    postalCode: '4892',
    city: 'Grubleitenpoint'
  },
  {
    postalCode: '4892',
    city: 'Pichl'
  },
  {
    postalCode: '4892',
    city: 'Ramsau'
  },
  {
    postalCode: '4892',
    city: 'Röth'
  },
  {
    postalCode: '4892',
    city: 'Sallach'
  },
  {
    postalCode: '4892',
    city: 'Saxigen'
  },
  {
    postalCode: '4892',
    city: 'Schnöllerberg'
  },
  {
    postalCode: '4892',
    city: 'Schwandeck'
  },
  {
    postalCode: '4892',
    city: 'Seppenröth'
  },
  {
    postalCode: '4892',
    city: 'Unterholzing'
  },
  {
    postalCode: '4892',
    city: 'Walligen'
  },
  {
    postalCode: '4892',
    city: 'Zaißen'
  },
  {
    postalCode: '4892',
    city: 'Obermoos'
  },
  {
    postalCode: '4892',
    city: 'Außerreith'
  },
  {
    postalCode: '4892',
    city: 'Forsterreith'
  },
  {
    postalCode: '4892',
    city: 'Oberalberting'
  },
  {
    postalCode: '4892',
    city: 'Sieberer'
  },
  {
    postalCode: '4892',
    city: 'Unterkogl'
  },
  {
    postalCode: '4893',
    city: 'Schweibern'
  },
  {
    postalCode: '4893',
    city: 'Steinbach'
  },
  {
    postalCode: '4893',
    city: 'Haslau'
  },
  {
    postalCode: '4893',
    city: 'Guggenberg'
  },
  {
    postalCode: '4893',
    city: 'Hof'
  },
  {
    postalCode: '4893',
    city: 'Zell am Moos'
  },
  {
    postalCode: '4893',
    city: 'Brandstatt'
  },
  {
    postalCode: '4893',
    city: 'Breitenau'
  },
  {
    postalCode: '4893',
    city: 'Entersgraben'
  },
  {
    postalCode: '4893',
    city: 'Gassen'
  },
  {
    postalCode: '4893',
    city: 'Gollau'
  },
  {
    postalCode: '4893',
    city: 'Greith'
  },
  {
    postalCode: '4893',
    city: 'Harpoint'
  },
  {
    postalCode: '4893',
    city: 'Haslau-Berg'
  },
  {
    postalCode: '4893',
    city: 'Häusern'
  },
  {
    postalCode: '4893',
    city: 'Heissing'
  },
  {
    postalCode: '4893',
    city: 'Kohlstatt'
  },
  {
    postalCode: '4893',
    city: 'Lindau'
  },
  {
    postalCode: '4893',
    city: 'Oberschwand'
  },
  {
    postalCode: '4893',
    city: 'Unterschwand'
  },
  {
    postalCode: '4893',
    city: 'Vormoos'
  },
  {
    postalCode: '4894',
    city: 'Berg'
  },
  {
    postalCode: '4894',
    city: 'Fischhof'
  },
  {
    postalCode: '4894',
    city: 'Gegend'
  },
  {
    postalCode: '4894',
    city: 'Haslach'
  },
  {
    postalCode: '4894',
    city: 'Höhenroith'
  },
  {
    postalCode: '4894',
    city: 'Oberhofen am Irrsee'
  },
  {
    postalCode: '4894',
    city: 'Obernberg'
  },
  {
    postalCode: '4894',
    city: 'Schoibern'
  },
  {
    postalCode: '4894',
    city: 'Stock'
  },
  {
    postalCode: '4894',
    city: 'Wegdorf'
  },
  {
    postalCode: '4894',
    city: 'Laiter'
  },
  {
    postalCode: '4894',
    city: 'Rabenschwand'
  },
  {
    postalCode: '4894',
    city: 'Eichenweg'
  },
  {
    postalCode: '4894',
    city: 'Salzweg'
  },
  {
    postalCode: '4894',
    city: 'Gewerbegebiet-Salzweg'
  },
  {
    postalCode: '4901',
    city: 'Au'
  },
  {
    postalCode: '4901',
    city: 'Furtpoint'
  },
  {
    postalCode: '4901',
    city: 'Hofmanning'
  },
  {
    postalCode: '4901',
    city: 'Kreuth'
  },
  {
    postalCode: '4901',
    city: 'Lehen'
  },
  {
    postalCode: '4901',
    city: 'Manning'
  },
  {
    postalCode: '4901',
    city: 'Moos'
  },
  {
    postalCode: '4901',
    city: 'Schachen bei Furtpoint'
  },
  {
    postalCode: '4901',
    city: 'Stocket'
  },
  {
    postalCode: '4901',
    city: 'Vornbuch'
  },
  {
    postalCode: '4901',
    city: 'Wolfshütte'
  },
  {
    postalCode: '4901',
    city: 'Zaun'
  },
  {
    postalCode: '4901',
    city: 'Achleithen'
  },
  {
    postalCode: '4901',
    city: 'Bärnthal'
  },
  {
    postalCode: '4901',
    city: 'Bergern'
  },
  {
    postalCode: '4901',
    city: 'Bruckmühl'
  },
  {
    postalCode: '4901',
    city: 'Deisenham'
  },
  {
    postalCode: '4901',
    city: 'Englfing'
  },
  {
    postalCode: '4901',
    city: 'Gatterlacken'
  },
  {
    postalCode: '4901',
    city: 'Grub'
  },
  {
    postalCode: '4901',
    city: 'Grünbach'
  },
  {
    postalCode: '4901',
    city: 'Hagleithen'
  },
  {
    postalCode: '4901',
    city: 'Hausruckedt'
  },
  {
    postalCode: '4901',
    city: 'Holzham'
  },
  {
    postalCode: '4901',
    city: 'Hub'
  },
  {
    postalCode: '4901',
    city: 'Kronabitten'
  },
  {
    postalCode: '4901',
    city: 'Kropfling'
  },
  {
    postalCode: '4901',
    city: 'Laah'
  },
  {
    postalCode: '4901',
    city: 'Mansing'
  },
  {
    postalCode: '4901',
    city: 'Mitterarming'
  },
  {
    postalCode: '4901',
    city: 'Niederottnang'
  },
  {
    postalCode: '4901',
    city: 'Niederpuchheim'
  },
  {
    postalCode: '4901',
    city: 'Oberkienberg'
  },
  {
    postalCode: '4901',
    city: 'Obermühlau'
  },
  {
    postalCode: '4901',
    city: 'Oberottnang'
  },
  {
    postalCode: '4901',
    city: 'Plötzenedt'
  },
  {
    postalCode: '4901',
    city: 'Rackering'
  },
  {
    postalCode: '4901',
    city: 'Redl'
  },
  {
    postalCode: '4901',
    city: 'Roithing'
  },
  {
    postalCode: '4901',
    city: 'Simmering'
  },
  {
    postalCode: '4901',
    city: 'Untermühlau'
  },
  {
    postalCode: '4901',
    city: 'Vorderarming'
  },
  {
    postalCode: '4901',
    city: 'Vornwald'
  },
  {
    postalCode: '4901',
    city: 'Walding'
  },
  {
    postalCode: '4901',
    city: 'Wassenbrunn'
  },
  {
    postalCode: '4901',
    city: 'Wiesing'
  },
  {
    postalCode: '4902',
    city: 'Breitwiesen'
  },
  {
    postalCode: '4902',
    city: 'Aferhagen'
  },
  {
    postalCode: '4902',
    city: 'Albertsham'
  },
  {
    postalCode: '4902',
    city: 'Öldenberg'
  },
  {
    postalCode: '4902',
    city: 'Deisenham'
  },
  {
    postalCode: '4902',
    city: 'Friesam'
  },
  {
    postalCode: '4902',
    city: 'Gstaudet'
  },
  {
    postalCode: '4902',
    city: 'Hauxmoos'
  },
  {
    postalCode: '4902',
    city: 'Hueb'
  },
  {
    postalCode: '4902',
    city: 'Imling'
  },
  {
    postalCode: '4902',
    city: 'Kohlgrube'
  },
  {
    postalCode: '4902',
    city: 'Kühnberg'
  },
  {
    postalCode: '4902',
    city: 'Litzlfeld'
  },
  {
    postalCode: '4902',
    city: 'Schlaugenham'
  },
  {
    postalCode: '4902',
    city: 'Schlaugenholz'
  },
  {
    postalCode: '4902',
    city: 'Steinpoint'
  },
  {
    postalCode: '4902',
    city: 'Unterkienberg'
  },
  {
    postalCode: '4902',
    city: 'Waid'
  },
  {
    postalCode: '4902',
    city: 'Waidring'
  },
  {
    postalCode: '4902',
    city: 'Watzing'
  },
  {
    postalCode: '4902',
    city: 'Wilding'
  },
  {
    postalCode: '4902',
    city: 'Wolfsegg am Hausruck'
  },
  {
    postalCode: '4904',
    city: 'Aigen'
  },
  {
    postalCode: '4904',
    city: 'Atzbach'
  },
  {
    postalCode: '4904',
    city: 'Baumgarting'
  },
  {
    postalCode: '4904',
    city: 'Enzelsberg'
  },
  {
    postalCode: '4904',
    city: 'Freundling'
  },
  {
    postalCode: '4904',
    city: 'Gneisting'
  },
  {
    postalCode: '4904',
    city: 'Hippelsberg'
  },
  {
    postalCode: '4904',
    city: 'Katzenberg'
  },
  {
    postalCode: '4904',
    city: 'Köppach'
  },
  {
    postalCode: '4904',
    city: 'Lameckberg'
  },
  {
    postalCode: '4904',
    city: 'Oberapping'
  },
  {
    postalCode: '4904',
    city: 'Reichering'
  },
  {
    postalCode: '4904',
    city: 'Ritzling'
  },
  {
    postalCode: '4904',
    city: 'Schnötzing'
  },
  {
    postalCode: '4904',
    city: 'Seiring'
  },
  {
    postalCode: '4904',
    city: 'Staudach'
  },
  {
    postalCode: '4904',
    city: 'Unterapping'
  },
  {
    postalCode: '4904',
    city: 'Weigensam'
  },
  {
    postalCode: '4904',
    city: 'Schachen bei Wolfshütte'
  },
  {
    postalCode: '4905',
    city: 'Englfing'
  },
  {
    postalCode: '4905',
    city: 'Hausruckedt'
  },
  {
    postalCode: '4905',
    city: 'Holzleithen'
  },
  {
    postalCode: '4905',
    city: 'Oberkienberg'
  },
  {
    postalCode: '4905',
    city: 'Obermühlau'
  },
  {
    postalCode: '4905',
    city: 'Schlag'
  },
  {
    postalCode: '4905',
    city: 'Stockedt'
  },
  {
    postalCode: '4905',
    city: 'Thomasroith'
  },
  {
    postalCode: '4905',
    city: 'Untermühlau'
  },
  {
    postalCode: '4906',
    city: 'Am Sportplatz'
  },
  {
    postalCode: '4906',
    city: 'Teichterberg'
  },
  {
    postalCode: '4906',
    city: 'Albertsham'
  },
  {
    postalCode: '4906',
    city: 'Anetsham'
  },
  {
    postalCode: '4906',
    city: 'Anhang'
  },
  {
    postalCode: '4906',
    city: 'Antiesen'
  },
  {
    postalCode: '4906',
    city: 'Aspach'
  },
  {
    postalCode: '4906',
    city: 'Eberschwang'
  },
  {
    postalCode: '4906',
    city: 'Eichetsham'
  },
  {
    postalCode: '4906',
    city: 'Feichtet'
  },
  {
    postalCode: '4906',
    city: 'Fleischhacken'
  },
  {
    postalCode: '4906',
    city: 'Greifenedt'
  },
  {
    postalCode: '4906',
    city: 'Hötzing'
  },
  {
    postalCode: '4906',
    city: 'Hof'
  },
  {
    postalCode: '4906',
    city: 'Illing'
  },
  {
    postalCode: '4906',
    city: 'Königsberg'
  },
  {
    postalCode: '4906',
    city: 'Leopoldshofstatt'
  },
  {
    postalCode: '4906',
    city: 'Maierhof'
  },
  {
    postalCode: '4906',
    city: 'Mitterbreitsach'
  },
  {
    postalCode: '4906',
    city: 'Moos'
  },
  {
    postalCode: '4906',
    city: 'Mühring'
  },
  {
    postalCode: '4906',
    city: 'Oberbreitsach'
  },
  {
    postalCode: '4906',
    city: 'Ötzling'
  },
  {
    postalCode: '4906',
    city: 'Prinsach'
  },
  {
    postalCode: '4906',
    city: 'Pumberg'
  },
  {
    postalCode: '4906',
    city: 'Putting'
  },
  {
    postalCode: '4906',
    city: 'Straß'
  },
  {
    postalCode: '4906',
    city: 'Vocking'
  },
  {
    postalCode: '4906',
    city: 'Walling'
  },
  {
    postalCode: '4906',
    city: 'Wappeltsham'
  },
  {
    postalCode: '4906',
    city: 'Wolfharting'
  },
  {
    postalCode: '4906',
    city: 'Hausruck'
  },
  {
    postalCode: '4906',
    city: 'Königsberger Straße'
  },
  {
    postalCode: '4906',
    city: 'Edt'
  },
  {
    postalCode: '4906',
    city: 'Felling'
  },
  {
    postalCode: '4906',
    city: 'Reinthal'
  },
  {
    postalCode: '4906',
    city: 'Wörling'
  },
  {
    postalCode: '4906',
    city: 'An der Malzmühle'
  },
  {
    postalCode: '4910',
    city: 'Altenried'
  },
  {
    postalCode: '4910',
    city: 'Asenham'
  },
  {
    postalCode: '4910',
    city: 'Aubach'
  },
  {
    postalCode: '4910',
    city: 'Renetsham'
  },
  {
    postalCode: '4910',
    city: 'Atzing'
  },
  {
    postalCode: '4910',
    city: 'Daring'
  },
  {
    postalCode: '4910',
    city: 'Dunzing'
  },
  {
    postalCode: '4910',
    city: 'Haging'
  },
  {
    postalCode: '4910',
    city: 'Hochkuchl'
  },
  {
    postalCode: '4910',
    city: 'Hof'
  },
  {
    postalCode: '4910',
    city: 'Oberbrunn'
  },
  {
    postalCode: '4910',
    city: 'Pattigham'
  },
  {
    postalCode: '4910',
    city: 'Pattighamried'
  },
  {
    postalCode: '4910',
    city: 'Sankt Thomas'
  },
  {
    postalCode: '4910',
    city: 'Schachen'
  },
  {
    postalCode: '4910',
    city: 'Zeilach'
  },
  {
    postalCode: '4910',
    city: 'Ried im Innkreis'
  },
  {
    postalCode: '4910',
    city: 'Holnberg'
  },
  {
    postalCode: '4910',
    city: 'Am Stadion'
  },
  {
    postalCode: '4910',
    city: 'Hannesgrub'
  },
  {
    postalCode: '4910',
    city: 'Aigen'
  },
  {
    postalCode: '4910',
    city: 'Eschlried'
  },
  {
    postalCode: '4910',
    city: 'Holzhäuseln'
  },
  {
    postalCode: '4910',
    city: 'Moosedt'
  },
  {
    postalCode: '4910',
    city: 'Ornetsmühl'
  },
  {
    postalCode: '4910',
    city: 'Pesenreith'
  },
  {
    postalCode: '4910',
    city: 'Rabenberg'
  },
  {
    postalCode: '4910',
    city: 'Schnalla'
  },
  {
    postalCode: '4910',
    city: 'Tumeltsham'
  },
  {
    postalCode: '4910',
    city: 'Walchshausen'
  },
  {
    postalCode: '4912',
    city: 'Auleiten'
  },
  {
    postalCode: '4912',
    city: 'Baumbach'
  },
  {
    postalCode: '4912',
    city: 'Baumgarten'
  },
  {
    postalCode: '4912',
    city: 'Bergetsedt'
  },
  {
    postalCode: '4912',
    city: 'Gobrechtsham'
  },
  {
    postalCode: '4912',
    city: 'Grillnau'
  },
  {
    postalCode: '4912',
    city: 'Hauping'
  },
  {
    postalCode: '4912',
    city: 'Hörzing'
  },
  {
    postalCode: '4912',
    city: 'Holzleiten'
  },
  {
    postalCode: '4912',
    city: 'Langstraß'
  },
  {
    postalCode: '4912',
    city: 'Leinberg'
  },
  {
    postalCode: '4912',
    city: 'Neuhofen im Innkreis'
  },
  {
    postalCode: '4912',
    city: 'Niederbrunn'
  },
  {
    postalCode: '4912',
    city: 'Ponneredt'
  },
  {
    postalCode: '4912',
    city: 'Rettenbrunn'
  },
  {
    postalCode: '4912',
    city: 'Kohlhof'
  },
  {
    postalCode: '4912',
    city: 'Spießberg'
  },
  {
    postalCode: '4912',
    city: 'Wiesen'
  },
  {
    postalCode: '4912',
    city: 'Oberleinberg'
  },
  {
    postalCode: '4920',
    city: 'Ebersau'
  },
  {
    postalCode: '4920',
    city: 'Aigen'
  },
  {
    postalCode: '4920',
    city: 'Freidling'
  },
  {
    postalCode: '4920',
    city: 'Au'
  },
  {
    postalCode: '4920',
    city: 'Kronawitten'
  },
  {
    postalCode: '4920',
    city: 'Litzlham'
  },
  {
    postalCode: '4920',
    city: 'Ottenberg'
  },
  {
    postalCode: '4920',
    city: 'Sankt Kollmann'
  },
  {
    postalCode: '4920',
    city: 'Schildorn'
  },
  {
    postalCode: '4920',
    city: 'Schmidsberg'
  },
  {
    postalCode: '4920',
    city: 'Weiketsedt'
  },
  {
    postalCode: '4920',
    city: 'Weissenbrunn'
  },
  {
    postalCode: '4920',
    city: 'Wolfersberg'
  },
  {
    postalCode: '4921',
    city: 'Aching'
  },
  {
    postalCode: '4921',
    city: 'Aschbrechting'
  },
  {
    postalCode: '4921',
    city: 'Breiningsham'
  },
  {
    postalCode: '4921',
    city: 'Breitsach'
  },
  {
    postalCode: '4921',
    city: 'Dürnberg'
  },
  {
    postalCode: '4921',
    city: 'Emprechting'
  },
  {
    postalCode: '4921',
    city: 'Engersdorf'
  },
  {
    postalCode: '4921',
    city: 'Ficht'
  },
  {
    postalCode: '4921',
    city: 'Gadering'
  },
  {
    postalCode: '4921',
    city: 'Gonetsreith'
  },
  {
    postalCode: '4921',
    city: 'Hilprechting'
  },
  {
    postalCode: '4921',
    city: 'Hohenzell'
  },
  {
    postalCode: '4921',
    city: 'Kager'
  },
  {
    postalCode: '4921',
    city: 'Langstadl'
  },
  {
    postalCode: '4921',
    city: 'Leisen'
  },
  {
    postalCode: '4921',
    city: 'Mauler'
  },
  {
    postalCode: '4921',
    city: 'Oberham'
  },
  {
    postalCode: '4921',
    city: 'Oberlemberg'
  },
  {
    postalCode: '4921',
    city: 'Obermauer'
  },
  {
    postalCode: '4921',
    city: 'Plöck'
  },
  {
    postalCode: '4921',
    city: 'Ponner'
  },
  {
    postalCode: '4921',
    city: 'Roith'
  },
  {
    postalCode: '4921',
    city: 'Wanger'
  },
  {
    postalCode: '4921',
    city: 'Willmerting'
  },
  {
    postalCode: '4921',
    city: 'Wöging'
  },
  {
    postalCode: '4921',
    city: 'Wötzling'
  },
  {
    postalCode: '4921',
    city: 'Schwarzenbach'
  },
  {
    postalCode: '4922',
    city: 'Geiersberg'
  },
  {
    postalCode: '4922',
    city: 'Gstöcket'
  },
  {
    postalCode: '4922',
    city: 'Oberleiten'
  },
  {
    postalCode: '4922',
    city: 'Oberrühring'
  },
  {
    postalCode: '4922',
    city: 'Pramerdorf'
  },
  {
    postalCode: '4922',
    city: 'Rödham'
  },
  {
    postalCode: '4922',
    city: 'Rödt'
  },
  {
    postalCode: '4922',
    city: 'Schernham'
  },
  {
    postalCode: '4922',
    city: 'Gries'
  },
  {
    postalCode: '4922',
    city: 'Kornrödt'
  },
  {
    postalCode: '4923',
    city: 'Bergham'
  },
  {
    postalCode: '4923',
    city: 'Felling'
  },
  {
    postalCode: '4923',
    city: 'Fossing'
  },
  {
    postalCode: '4923',
    city: 'Gunzing'
  },
  {
    postalCode: '4923',
    city: 'Helmerding'
  },
  {
    postalCode: '4923',
    city: 'Hochkuchl'
  },
  {
    postalCode: '4923',
    city: 'Kemating'
  },
  {
    postalCode: '4923',
    city: 'Kobernaußen'
  },
  {
    postalCode: '4923',
    city: 'Kramling'
  },
  {
    postalCode: '4923',
    city: 'Lohnsburg'
  },
  {
    postalCode: '4923',
    city: 'Magetsham'
  },
  {
    postalCode: '4923',
    city: 'Mitterberg'
  },
  {
    postalCode: '4923',
    city: 'Neulendt'
  },
  {
    postalCode: '4923',
    city: 'Reintal'
  },
  {
    postalCode: '4923',
    city: 'Schauberg'
  },
  {
    postalCode: '4923',
    city: 'Schlag'
  },
  {
    postalCode: '4923',
    city: 'Schmidham'
  },
  {
    postalCode: '4923',
    city: 'Schönberg'
  },
  {
    postalCode: '4923',
    city: 'Stelzen'
  },
  {
    postalCode: '4924',
    city: 'Weissenbrunn'
  },
  {
    postalCode: '4924',
    city: 'Bach'
  },
  {
    postalCode: '4924',
    city: 'Baumgarten'
  },
  {
    postalCode: '4924',
    city: 'Besendorf'
  },
  {
    postalCode: '4924',
    city: 'Bleckenwegen'
  },
  {
    postalCode: '4924',
    city: 'Brackenberg'
  },
  {
    postalCode: '4924',
    city: 'Brandstatt'
  },
  {
    postalCode: '4924',
    city: 'Brast'
  },
  {
    postalCode: '4924',
    city: 'Breitwies'
  },
  {
    postalCode: '4924',
    city: 'Dundeck'
  },
  {
    postalCode: '4924',
    city: 'Edthelm'
  },
  {
    postalCode: '4924',
    city: 'Födering'
  },
  {
    postalCode: '4924',
    city: 'Gitthof'
  },
  {
    postalCode: '4924',
    city: 'Hacksperr'
  },
  {
    postalCode: '4924',
    city: 'Hartlberg'
  },
  {
    postalCode: '4924',
    city: 'Höschmühl'
  },
  {
    postalCode: '4924',
    city: 'Knechtsgern'
  },
  {
    postalCode: '4924',
    city: 'Kohleck'
  },
  {
    postalCode: '4924',
    city: 'Lerz'
  },
  {
    postalCode: '4924',
    city: 'Maireck'
  },
  {
    postalCode: '4924',
    city: 'Neffenedt'
  },
  {
    postalCode: '4924',
    city: 'Nußbaum am Kobernaußer Walde'
  },
  {
    postalCode: '4924',
    city: 'Reith'
  },
  {
    postalCode: '4924',
    city: 'Roderer'
  },
  {
    postalCode: '4924',
    city: 'Schratteneck'
  },
  {
    postalCode: '4924',
    city: 'Schwendt'
  },
  {
    postalCode: '4924',
    city: 'Straß'
  },
  {
    postalCode: '4924',
    city: 'Waldzell'
  },
  {
    postalCode: '4924',
    city: 'Weißenbrunn'
  },
  {
    postalCode: '4924',
    city: 'Wirglau'
  },
  {
    postalCode: '4924',
    city: 'Wirmling'
  },
  {
    postalCode: '4925',
    city: 'Senzenberg'
  },
  {
    postalCode: '4925',
    city: 'Altsommerau'
  },
  {
    postalCode: '4925',
    city: 'Ecklham'
  },
  {
    postalCode: '4925',
    city: 'Feitzing'
  },
  {
    postalCode: '4925',
    city: 'Großpiesenham'
  },
  {
    postalCode: '4925',
    city: 'Guggenberg'
  },
  {
    postalCode: '4925',
    city: 'Gumpling'
  },
  {
    postalCode: '4925',
    city: 'Gutensham'
  },
  {
    postalCode: '4925',
    city: 'Hartlhof'
  },
  {
    postalCode: '4925',
    city: 'Kleinpiesenham'
  },
  {
    postalCode: '4925',
    city: 'Knirzing'
  },
  {
    postalCode: '4925',
    city: 'Kronawitten'
  },
  {
    postalCode: '4925',
    city: 'Lungdorf'
  },
  {
    postalCode: '4925',
    city: 'Noxberg'
  },
  {
    postalCode: '4925',
    city: 'Pramet'
  },
  {
    postalCode: '4925',
    city: 'Rödt'
  },
  {
    postalCode: '4925',
    city: 'Schmieding'
  },
  {
    postalCode: '4925',
    city: 'Windischhub'
  },
  {
    postalCode: '4926',
    city: 'Baching'
  },
  {
    postalCode: '4926',
    city: 'Grausgrub'
  },
  {
    postalCode: '4926',
    city: 'Hatting'
  },
  {
    postalCode: '4926',
    city: 'Hof'
  },
  {
    postalCode: '4926',
    city: 'Jetzing'
  },
  {
    postalCode: '4926',
    city: 'Manaberg'
  },
  {
    postalCode: '4926',
    city: 'Obereselbach'
  },
  {
    postalCode: '4926',
    city: 'Pilgersham'
  },
  {
    postalCode: '4926',
    city: 'Sankt Marienkirchen am Hausruck'
  },
  {
    postalCode: '4926',
    city: 'Stocket'
  },
  {
    postalCode: '4926',
    city: 'Unering'
  },
  {
    postalCode: '4926',
    city: 'Untereselbach'
  },
  {
    postalCode: '4926',
    city: 'Kern'
  },
  {
    postalCode: '4926',
    city: 'Lehen'
  },
  {
    postalCode: '4931',
    city: 'Kleinschneidt'
  },
  {
    postalCode: '4931',
    city: 'Aichet'
  },
  {
    postalCode: '4931',
    city: 'Aigen'
  },
  {
    postalCode: '4931',
    city: 'Arnberg am Kobernaußer Walde'
  },
  {
    postalCode: '4931',
    city: 'Atzing'
  },
  {
    postalCode: '4931',
    city: 'Berstenham'
  },
  {
    postalCode: '4931',
    city: 'Bockenbach'
  },
  {
    postalCode: '4931',
    city: 'Duttenberg'
  },
  {
    postalCode: '4931',
    city: 'Gledt'
  },
  {
    postalCode: '4931',
    city: 'Grading'
  },
  {
    postalCode: '4931',
    city: 'Großenreith'
  },
  {
    postalCode: '4931',
    city: 'Großweiffendorf'
  },
  {
    postalCode: '4931',
    city: 'Gügling'
  },
  {
    postalCode: '4931',
    city: 'Hub'
  },
  {
    postalCode: '4931',
    city: 'Jagleck'
  },
  {
    postalCode: '4931',
    city: 'Karlberg'
  },
  {
    postalCode: '4931',
    city: 'Katzenberg'
  },
  {
    postalCode: '4931',
    city: 'Kleinreith'
  },
  {
    postalCode: '4931',
    city: 'Kleinweiffendorf'
  },
  {
    postalCode: '4931',
    city: 'Kriegledt'
  },
  {
    postalCode: '4931',
    city: 'Lehen'
  },
  {
    postalCode: '4931',
    city: 'Mairing'
  },
  {
    postalCode: '4931',
    city: 'Mettmach'
  },
  {
    postalCode: '4931',
    city: 'Mitterdorf'
  },
  {
    postalCode: '4931',
    city: 'Nagsdorf'
  },
  {
    postalCode: '4931',
    city: 'Neulendt'
  },
  {
    postalCode: '4931',
    city: 'Neundling'
  },
  {
    postalCode: '4931',
    city: 'Nösting'
  },
  {
    postalCode: '4931',
    city: 'Oberdorf'
  },
  {
    postalCode: '4931',
    city: 'Scherwolling'
  },
  {
    postalCode: '4931',
    city: 'Staxroith'
  },
  {
    postalCode: '4931',
    city: 'Warmanstadl'
  },
  {
    postalCode: '4932',
    city: 'Au'
  },
  {
    postalCode: '4932',
    city: 'Kasing'
  },
  {
    postalCode: '4932',
    city: 'Ried'
  },
  {
    postalCode: '4932',
    city: 'Ampfenham'
  },
  {
    postalCode: '4932',
    city: 'Buch'
  },
  {
    postalCode: '4932',
    city: 'Edt'
  },
  {
    postalCode: '4932',
    city: 'Federnberg'
  },
  {
    postalCode: '4932',
    city: 'Grub'
  },
  {
    postalCode: '4932',
    city: 'Kirchheim im Innkreis'
  },
  {
    postalCode: '4932',
    city: 'Kraxenberg'
  },
  {
    postalCode: '4932',
    city: 'Ramerding'
  },
  {
    postalCode: '4932',
    city: 'Rödham'
  },
  {
    postalCode: '4932',
    city: 'Schacher'
  },
  {
    postalCode: '4933',
    city: 'Aichet'
  },
  {
    postalCode: '4933',
    city: 'Buchleiting'
  },
  {
    postalCode: '4933',
    city: 'Döging'
  },
  {
    postalCode: '4933',
    city: 'Eisecking'
  },
  {
    postalCode: '4933',
    city: 'Hinterholz'
  },
  {
    postalCode: '4933',
    city: 'Hobling'
  },
  {
    postalCode: '4933',
    city: 'Leithen'
  },
  {
    postalCode: '4933',
    city: 'Maierhof'
  },
  {
    postalCode: '4933',
    city: 'Naderling'
  },
  {
    postalCode: '4933',
    city: 'Roith'
  },
  {
    postalCode: '4933',
    city: 'Steinberg'
  },
  {
    postalCode: '4933',
    city: 'Teinsberg'
  },
  {
    postalCode: '4933',
    city: 'Weißau'
  },
  {
    postalCode: '4933',
    city: 'Wieselberg'
  },
  {
    postalCode: '4933',
    city: 'Wildenau'
  },
  {
    postalCode: '4941',
    city: 'Abstätten'
  },
  {
    postalCode: '4941',
    city: 'Aich'
  },
  {
    postalCode: '4941',
    city: 'Asenham'
  },
  {
    postalCode: '4941',
    city: 'Atzing'
  },
  {
    postalCode: '4941',
    city: 'Aubach'
  },
  {
    postalCode: '4941',
    city: 'Baching'
  },
  {
    postalCode: '4941',
    city: 'Bubesting'
  },
  {
    postalCode: '4941',
    city: 'Dopplhub'
  },
  {
    postalCode: '4941',
    city: 'Fritzging'
  },
  {
    postalCode: '4941',
    city: 'Gigling'
  },
  {
    postalCode: '4941',
    city: 'Käfermühl'
  },
  {
    postalCode: '4941',
    city: 'Langdorf'
  },
  {
    postalCode: '4941',
    city: 'Mehrnbach'
  },
  {
    postalCode: '4941',
    city: 'Probenzing'
  },
  {
    postalCode: '4941',
    city: 'Renetsham'
  },
  {
    postalCode: '4941',
    city: 'Riegerting'
  },
  {
    postalCode: '4941',
    city: 'Sieber'
  },
  {
    postalCode: '4941',
    city: 'Steinbach'
  },
  {
    postalCode: '4941',
    city: 'Stötten'
  },
  {
    postalCode: '4941',
    city: 'Thaling'
  },
  {
    postalCode: '4941',
    city: 'Zimetsberg'
  },
  {
    postalCode: '4941',
    city: 'Aubachberg'
  },
  {
    postalCode: '4941',
    city: 'Ried im Innkreis'
  },
  {
    postalCode: '4942',
    city: 'Baumgarten'
  },
  {
    postalCode: '4942',
    city: 'Dorf'
  },
  {
    postalCode: '4942',
    city: 'Edt'
  },
  {
    postalCode: '4942',
    city: 'Freiling'
  },
  {
    postalCode: '4942',
    city: 'Gurten'
  },
  {
    postalCode: '4942',
    city: 'Itzenthal'
  },
  {
    postalCode: '4942',
    city: 'Mittermoos'
  },
  {
    postalCode: '4942',
    city: 'Oberndorf'
  },
  {
    postalCode: '4942',
    city: 'Ranzing'
  },
  {
    postalCode: '4942',
    city: 'Reiset'
  },
  {
    postalCode: '4942',
    city: 'Schmalzberg'
  },
  {
    postalCode: '4942',
    city: 'Schmiedhof'
  },
  {
    postalCode: '4942',
    city: 'Wagnerberg'
  },
  {
    postalCode: '4942',
    city: 'Außerguggenberg'
  },
  {
    postalCode: '4942',
    city: 'Bruck'
  },
  {
    postalCode: '4942',
    city: 'Geretsdorf'
  },
  {
    postalCode: '4942',
    city: 'Gundersberg'
  },
  {
    postalCode: '4942',
    city: 'Mairing'
  },
  {
    postalCode: '4942',
    city: 'Neuratting'
  },
  {
    postalCode: '4942',
    city: 'Sieberting'
  },
  {
    postalCode: '4942',
    city: 'Weinberg'
  },
  {
    postalCode: '4942',
    city: 'Wippenham'
  },
  {
    postalCode: '4943',
    city: 'Durchham'
  },
  {
    postalCode: '4943',
    city: 'Ellreching'
  },
  {
    postalCode: '4943',
    city: 'Geinberg'
  },
  {
    postalCode: '4943',
    city: 'Hart'
  },
  {
    postalCode: '4943',
    city: 'Haudering'
  },
  {
    postalCode: '4943',
    city: 'Kager'
  },
  {
    postalCode: '4943',
    city: 'Moosham'
  },
  {
    postalCode: '4943',
    city: 'Neuhaus'
  },
  {
    postalCode: '4943',
    city: 'Nonsbach'
  },
  {
    postalCode: '4943',
    city: 'Oberaichet'
  },
  {
    postalCode: '4943',
    city: 'Winten'
  },
  {
    postalCode: '4943',
    city: 'Schmalzberg'
  },
  {
    postalCode: '4943',
    city: 'Pirath'
  },
  {
    postalCode: '4943',
    city: 'Simetsham'
  },
  {
    postalCode: '4943',
    city: 'Imolkam'
  },
  {
    postalCode: '4950',
    city: 'Altheim'
  },
  {
    postalCode: '4950',
    city: 'Diepolding'
  },
  {
    postalCode: '4950',
    city: 'Englwertsham'
  },
  {
    postalCode: '4950',
    city: 'Gallenberg'
  },
  {
    postalCode: '4950',
    city: 'Gaugsham'
  },
  {
    postalCode: '4950',
    city: 'Kling'
  },
  {
    postalCode: '4950',
    city: 'Lehen'
  },
  {
    postalCode: '4950',
    city: 'Lüfteneck'
  },
  {
    postalCode: '4950',
    city: 'Mauernberg'
  },
  {
    postalCode: '4950',
    city: 'Schwaig'
  },
  {
    postalCode: '4950',
    city: 'Stern'
  },
  {
    postalCode: '4950',
    city: 'Wagham'
  },
  {
    postalCode: '4950',
    city: 'Weidenthal'
  },
  {
    postalCode: '4950',
    city: 'Weirading'
  },
  {
    postalCode: '4950',
    city: 'Wolfegg'
  },
  {
    postalCode: '4950',
    city: 'Burgstall'
  },
  {
    postalCode: '4951',
    city: 'Nonsbach'
  },
  {
    postalCode: '4951',
    city: 'Aigelsberg'
  },
  {
    postalCode: '4951',
    city: 'Altenaichet'
  },
  {
    postalCode: '4951',
    city: 'Graham'
  },
  {
    postalCode: '4951',
    city: 'Holzerding'
  },
  {
    postalCode: '4951',
    city: 'Imolkam'
  },
  {
    postalCode: '4951',
    city: 'Ornading'
  },
  {
    postalCode: '4951',
    city: 'Polling im Innkreis'
  },
  {
    postalCode: '4951',
    city: 'Remoneuberg'
  },
  {
    postalCode: '4952',
    city: 'Gaugsham'
  },
  {
    postalCode: '4952',
    city: 'Hainschwang'
  },
  {
    postalCode: '4952',
    city: 'Hunding'
  },
  {
    postalCode: '4952',
    city: 'Matzelsberg'
  },
  {
    postalCode: '4952',
    city: 'Steingassen'
  },
  {
    postalCode: '4952',
    city: 'Appersting'
  },
  {
    postalCode: '4952',
    city: 'Bauerding'
  },
  {
    postalCode: '4952',
    city: 'Bergham'
  },
  {
    postalCode: '4952',
    city: 'Buch'
  },
  {
    postalCode: '4952',
    city: 'Burgstall'
  },
  {
    postalCode: '4952',
    city: 'Elling'
  },
  {
    postalCode: '4952',
    city: 'Gunderding'
  },
  {
    postalCode: '4952',
    city: 'Harterding'
  },
  {
    postalCode: '4952',
    city: 'Hauserding'
  },
  {
    postalCode: '4952',
    city: 'Leithen'
  },
  {
    postalCode: '4952',
    city: 'Mankham'
  },
  {
    postalCode: '4952',
    city: 'Pirath'
  },
  {
    postalCode: '4952',
    city: 'Riedlham'
  },
  {
    postalCode: '4952',
    city: 'Weng im Innkreis'
  },
  {
    postalCode: '4952',
    city: 'Wernthal'
  },
  {
    postalCode: '4961',
    city: 'Gimpling'
  },
  {
    postalCode: '4961',
    city: 'Mühlheim am Inn'
  },
  {
    postalCode: '4961',
    city: 'Niederach'
  },
  {
    postalCode: '4961',
    city: 'Stötting'
  },
  {
    postalCode: '4961',
    city: 'Burgstall'
  },
  {
    postalCode: '4962',
    city: 'Alberting'
  },
  {
    postalCode: '4962',
    city: 'Amberg'
  },
  {
    postalCode: '4962',
    city: 'Frauenstein'
  },
  {
    postalCode: '4962',
    city: 'Gundholling'
  },
  {
    postalCode: '4962',
    city: 'Holl'
  },
  {
    postalCode: '4962',
    city: 'Kaltenau'
  },
  {
    postalCode: '4962',
    city: 'Mamling'
  },
  {
    postalCode: '4962',
    city: 'Mining'
  },
  {
    postalCode: '4962',
    city: 'Obersunzing'
  },
  {
    postalCode: '4962',
    city: 'Öppling'
  },
  {
    postalCode: '4962',
    city: 'Unterbergham'
  },
  {
    postalCode: '4962',
    city: 'Untersunzing'
  },
  {
    postalCode: '4962',
    city: 'Aham'
  },
  {
    postalCode: '4962',
    city: 'Schickenedt'
  },
  {
    postalCode: '4963',
    city: 'Spraid'
  },
  {
    postalCode: '4963',
    city: 'Aham'
  },
  {
    postalCode: '4963',
    city: 'Aselkam'
  },
  {
    postalCode: '4963',
    city: 'Bergham'
  },
  {
    postalCode: '4963',
    city: 'Bogenhofen'
  },
  {
    postalCode: '4963',
    city: 'Guggenberg'
  },
  {
    postalCode: '4963',
    city: 'Hagenau'
  },
  {
    postalCode: '4963',
    city: 'Hart'
  },
  {
    postalCode: '4963',
    city: 'Heitzenberg'
  },
  {
    postalCode: '4963',
    city: 'Hundslau'
  },
  {
    postalCode: '4963',
    city: 'Jahrsdorf'
  },
  {
    postalCode: '4963',
    city: 'Moos'
  },
  {
    postalCode: '4963',
    city: 'Nöfing'
  },
  {
    postalCode: '4963',
    city: 'Ofen'
  },
  {
    postalCode: '4963',
    city: 'Reikersdorf'
  },
  {
    postalCode: '4963',
    city: 'Sankt Peter am Hart'
  },
  {
    postalCode: '4963',
    city: 'Wimm'
  },
  {
    postalCode: '4970',
    city: 'Bankham'
  },
  {
    postalCode: '4970',
    city: 'Ertlberg'
  },
  {
    postalCode: '4970',
    city: 'Hofing'
  },
  {
    postalCode: '4970',
    city: 'Kirchberg'
  },
  {
    postalCode: '4970',
    city: 'Obereitzing'
  },
  {
    postalCode: '4970',
    city: 'Probenzing'
  },
  {
    postalCode: '4970',
    city: 'Sausack'
  },
  {
    postalCode: '4970',
    city: 'Untereitzing'
  },
  {
    postalCode: '4970',
    city: 'Ursprung'
  },
  {
    postalCode: '4970',
    city: 'Wöppelhub'
  },
  {
    postalCode: '4971',
    city: 'Baumgartling'
  },
  {
    postalCode: '4971',
    city: 'Pesenreit'
  },
  {
    postalCode: '4971',
    city: 'Altenried'
  },
  {
    postalCode: '4971',
    city: 'Aurolzmünster'
  },
  {
    postalCode: '4971',
    city: 'Danner'
  },
  {
    postalCode: '4971',
    city: 'Edenbach'
  },
  {
    postalCode: '4971',
    city: 'Forchtenau'
  },
  {
    postalCode: '4971',
    city: 'Kochreith'
  },
  {
    postalCode: '4971',
    city: 'Lauterbrunn'
  },
  {
    postalCode: '4971',
    city: 'Maierhof'
  },
  {
    postalCode: '4971',
    city: 'Schöndorf'
  },
  {
    postalCode: '4971',
    city: 'Seyring'
  },
  {
    postalCode: '4971',
    city: 'Weierfing'
  },
  {
    postalCode: '4971',
    city: 'Asenham'
  },
  {
    postalCode: '4971',
    city: 'Antiesen'
  },
  {
    postalCode: '4972',
    city: 'Stelzham'
  },
  {
    postalCode: '4972',
    city: 'Haging'
  },
  {
    postalCode: '4972',
    city: 'Aigen'
  },
  {
    postalCode: '4972',
    city: 'Antiesen'
  },
  {
    postalCode: '4972',
    city: 'Dann'
  },
  {
    postalCode: '4972',
    city: 'Dietarding'
  },
  {
    postalCode: '4972',
    city: 'Dobl'
  },
  {
    postalCode: '4972',
    city: 'Dulmading'
  },
  {
    postalCode: '4972',
    city: 'Etting'
  },
  {
    postalCode: '4972',
    city: 'Flöcklern'
  },
  {
    postalCode: '4972',
    city: 'Gaisbach'
  },
  {
    postalCode: '4972',
    city: 'Gunderpolling'
  },
  {
    postalCode: '4972',
    city: 'Himmelreich'
  },
  {
    postalCode: '4972',
    city: 'Murau'
  },
  {
    postalCode: '4972',
    city: 'Rabenfurt'
  },
  {
    postalCode: '4972',
    city: 'Unterhaselberg'
  },
  {
    postalCode: '4972',
    city: 'Utzenaich'
  },
  {
    postalCode: '4972',
    city: 'Weilbolden'
  },
  {
    postalCode: '4972',
    city: 'Wilhelming'
  },
  {
    postalCode: '4972',
    city: 'Wimm'
  },
  {
    postalCode: '4972',
    city: 'Windhag'
  },
  {
    postalCode: '4972',
    city: 'Wohlmuthen'
  },
  {
    postalCode: '4972',
    city: 'Wolfstraß'
  },
  {
    postalCode: '4973',
    city: 'Haging'
  },
  {
    postalCode: '4973',
    city: 'Hofing'
  },
  {
    postalCode: '4973',
    city: 'Forsthub'
  },
  {
    postalCode: '4973',
    city: 'Sindhöring'
  },
  {
    postalCode: '4973',
    city: 'Traxlham'
  },
  {
    postalCode: '4973',
    city: 'Breitenaich'
  },
  {
    postalCode: '4973',
    city: 'Hötzlarn'
  },
  {
    postalCode: '4973',
    city: 'Karchham'
  },
  {
    postalCode: '4973',
    city: 'Koblstadt'
  },
  {
    postalCode: '4973',
    city: 'Diesseits'
  },
  {
    postalCode: '4973',
    city: 'Jenseits'
  },
  {
    postalCode: '4973',
    city: 'Berg'
  },
  {
    postalCode: '4973',
    city: 'Bruck'
  },
  {
    postalCode: '4973',
    city: 'Dobl'
  },
  {
    postalCode: '4973',
    city: 'Furth'
  },
  {
    postalCode: '4973',
    city: 'Langzaun'
  },
  {
    postalCode: '4973',
    city: 'Ort'
  },
  {
    postalCode: '4973',
    city: 'Reisedt'
  },
  {
    postalCode: '4973',
    city: 'Sankt Ulrich'
  },
  {
    postalCode: '4973',
    city: 'Stockham'
  },
  {
    postalCode: '4973',
    city: 'Weindorf'
  },
  {
    postalCode: '4973',
    city: 'Wolfau'
  },
  {
    postalCode: '4973',
    city: 'Rabenfurt'
  },
  {
    postalCode: '4974',
    city: 'Reintal'
  },
  {
    postalCode: '4974',
    city: 'Stött'
  },
  {
    postalCode: '4974',
    city: 'Aichberg'
  },
  {
    postalCode: '4974',
    city: 'Aigen'
  },
  {
    postalCode: '4974',
    city: 'Bischelsdorf'
  },
  {
    postalCode: '4974',
    city: 'Kammer'
  },
  {
    postalCode: '4974',
    city: 'Kellern'
  },
  {
    postalCode: '4974',
    city: 'Ort im Innkreis'
  },
  {
    postalCode: '4974',
    city: 'Osternach'
  },
  {
    postalCode: '4974',
    city: 'Hart'
  },
  {
    postalCode: '4974',
    city: 'Hübing'
  },
  {
    postalCode: '4974',
    city: 'Traxlham'
  },
  {
    postalCode: '4975',
    city: 'Andiesen'
  },
  {
    postalCode: '4975',
    city: 'Dietrichshofen'
  },
  {
    postalCode: '4975',
    city: 'Niederham'
  },
  {
    postalCode: '4975',
    city: 'Stocket'
  },
  {
    postalCode: '4975',
    city: 'Dorf'
  },
  {
    postalCode: '4975',
    city: 'Etzelshofen'
  },
  {
    postalCode: '4975',
    city: 'Roßbach'
  },
  {
    postalCode: '4975',
    city: 'Schnelldorf'
  },
  {
    postalCode: '4975',
    city: 'Suben'
  },
  {
    postalCode: '4980',
    city: 'Antiesenhofen'
  },
  {
    postalCode: '4980',
    city: 'Mitterding'
  },
  {
    postalCode: '4980',
    city: 'Ungerding'
  },
  {
    postalCode: '4980',
    city: 'Viehausen'
  },
  {
    postalCode: '4980',
    city: 'Maasbach'
  },
  {
    postalCode: '4980',
    city: 'Würm'
  },
  {
    postalCode: '4980',
    city: 'Hübing'
  },
  {
    postalCode: '4980',
    city: 'Münsteuer'
  },
  {
    postalCode: '4981',
    city: 'Fraham'
  },
  {
    postalCode: '4981',
    city: 'Linn'
  },
  {
    postalCode: '4981',
    city: 'Minaberg'
  },
  {
    postalCode: '4981',
    city: 'Münsteuer'
  },
  {
    postalCode: '4981',
    city: 'Pfaffing'
  },
  {
    postalCode: '4981',
    city: 'Reichersberg'
  },
  {
    postalCode: '4982',
    city: 'Graben'
  },
  {
    postalCode: '4982',
    city: 'Katzenberg'
  },
  {
    postalCode: '4982',
    city: 'Katzenbergleithen'
  },
  {
    postalCode: '4982',
    city: 'Kirchdorf am Inn'
  },
  {
    postalCode: '4982',
    city: 'Simetsham'
  },
  {
    postalCode: '4982',
    city: 'Ufer'
  },
  {
    postalCode: '4982',
    city: 'Greifing'
  },
  {
    postalCode: '4982',
    city: 'Großmurham'
  },
  {
    postalCode: '4982',
    city: 'Mörschwang'
  },
  {
    postalCode: '4982',
    city: 'Möslwimm'
  },
  {
    postalCode: '4982',
    city: 'Moosböck'
  },
  {
    postalCode: '4982',
    city: 'Mühlberg'
  },
  {
    postalCode: '4982',
    city: 'Rottenberg'
  },
  {
    postalCode: '4982',
    city: 'Schalchham'
  },
  {
    postalCode: '4982',
    city: 'Obernberg am Inn'
  },
  {
    postalCode: '4983',
    city: 'Grub'
  },
  {
    postalCode: '4983',
    city: 'Ulrichstal'
  },
  {
    postalCode: '4983',
    city: 'Dietraching'
  },
  {
    postalCode: '4983',
    city: 'Hofing'
  },
  {
    postalCode: '4983',
    city: 'Hub'
  },
  {
    postalCode: '4983',
    city: 'Krautsdorf'
  },
  {
    postalCode: '4983',
    city: 'Niederweilbach'
  },
  {
    postalCode: '4983',
    city: 'Nonsbach'
  },
  {
    postalCode: '4983',
    city: 'Pischelsdorf'
  },
  {
    postalCode: '4983',
    city: 'Röfl'
  },
  {
    postalCode: '4983',
    city: 'Sankt Georgen bei Obernberg am Inn'
  },
  {
    postalCode: '4983',
    city: 'Wimm'
  },
  {
    postalCode: '4984',
    city: 'Moosböck'
  },
  {
    postalCode: '4984',
    city: 'Detzlhof'
  },
  {
    postalCode: '4984',
    city: 'Ellreching'
  },
  {
    postalCode: '4984',
    city: 'Hinterweintal'
  },
  {
    postalCode: '4984',
    city: 'Kirchberg'
  },
  {
    postalCode: '4984',
    city: 'Kleinmurham'
  },
  {
    postalCode: '4984',
    city: 'Klingersberg'
  },
  {
    postalCode: '4984',
    city: 'Kölbl'
  },
  {
    postalCode: '4984',
    city: 'Lindl'
  },
  {
    postalCode: '4984',
    city: 'Neudorf'
  },
  {
    postalCode: '4984',
    city: 'Oberweintal'
  },
  {
    postalCode: '4984',
    city: 'Tal'
  },
  {
    postalCode: '4984',
    city: 'Voitshofen'
  },
  {
    postalCode: '4984',
    city: 'Weilbach'
  },
  {
    postalCode: '5020',
    city: 'Salzburg'
  },
  {
    postalCode: '5020',
    city: 'Rott'
  },
  {
    postalCode: '5023',
    city: 'Guggenthal'
  },
  {
    postalCode: '5023',
    city: 'Heuberg'
  },
  {
    postalCode: '5023',
    city: 'Salzburg'
  },
  {
    postalCode: '5026',
    city: 'Glasenbach'
  },
  {
    postalCode: '5026',
    city: 'Oberwinkl'
  },
  {
    postalCode: '5026',
    city: 'Vorderfager'
  },
  {
    postalCode: '5026',
    city: 'Salzburg'
  },
  {
    postalCode: '5061',
    city: 'Elsbethen'
  },
  {
    postalCode: '5061',
    city: 'Gfalls'
  },
  {
    postalCode: '5061',
    city: 'Glasenbach'
  },
  {
    postalCode: '5061',
    city: 'Hinterwinkl'
  },
  {
    postalCode: '5061',
    city: 'Höhenwald'
  },
  {
    postalCode: '5061',
    city: 'Vorderfager'
  },
  {
    postalCode: '5061',
    city: 'Zieglau'
  },
  {
    postalCode: '5061',
    city: 'Haslach'
  },
  {
    postalCode: '5061',
    city: 'Salzburg'
  },
  {
    postalCode: '5071',
    city: 'Salzburg'
  },
  {
    postalCode: '5071',
    city: 'Walserberg'
  },
  {
    postalCode: '5071',
    city: 'Gois'
  },
  {
    postalCode: '5071',
    city: 'Himmelreich'
  },
  {
    postalCode: '5071',
    city: 'Käferheim'
  },
  {
    postalCode: '5071',
    city: 'Kleßheim'
  },
  {
    postalCode: '5071',
    city: 'Rott'
  },
  {
    postalCode: '5071',
    city: 'Schwarzenbergkaserne'
  },
  {
    postalCode: '5071',
    city: 'Siezenheim'
  },
  {
    postalCode: '5071',
    city: 'Viehhausen'
  },
  {
    postalCode: '5071',
    city: 'Wals'
  },
  {
    postalCode: '5071',
    city: 'Walserfeld'
  },
  {
    postalCode: '5081',
    city: 'Anif'
  },
  {
    postalCode: '5081',
    city: 'Neu-Anif'
  },
  {
    postalCode: '5081',
    city: 'Niederalm'
  },
  {
    postalCode: '5081',
    city: 'Salzburg'
  },
  {
    postalCode: '5082',
    city: 'Eichet'
  },
  {
    postalCode: '5082',
    city: 'Glanegg'
  },
  {
    postalCode: '5082',
    city: 'Grödig'
  },
  {
    postalCode: '5082',
    city: 'Salzburg'
  },
  {
    postalCode: '5083',
    city: 'Grödig'
  },
  {
    postalCode: '5083',
    city: 'Sankt Leonhard'
  },
  {
    postalCode: '5083',
    city: 'Taxach'
  },
  {
    postalCode: '5084',
    city: 'Großgmain'
  },
  {
    postalCode: '5090',
    city: 'Au'
  },
  {
    postalCode: '5090',
    city: 'Faistau'
  },
  {
    postalCode: '5090',
    city: 'Hallenstein'
  },
  {
    postalCode: '5090',
    city: 'Lofer'
  },
  {
    postalCode: '5090',
    city: 'Mayrberg'
  },
  {
    postalCode: '5090',
    city: 'Scheffsnoth'
  },
  {
    postalCode: '5090',
    city: 'Gumping'
  },
  {
    postalCode: '5091',
    city: 'Gföll'
  },
  {
    postalCode: '5091',
    city: 'Niederland'
  },
  {
    postalCode: '5091',
    city: 'Reit'
  },
  {
    postalCode: '5091',
    city: 'Unken'
  },
  {
    postalCode: '5091',
    city: 'Unkenberg'
  },
  {
    postalCode: '5092',
    city: 'Gumping'
  },
  {
    postalCode: '5092',
    city: 'Kirchental'
  },
  {
    postalCode: '5092',
    city: 'Obsthurn'
  },
  {
    postalCode: '5092',
    city: 'Sankt Martin bei Lofer'
  },
  {
    postalCode: '5092',
    city: 'Wildental'
  },
  {
    postalCode: '5093',
    city: 'Frohnwies'
  },
  {
    postalCode: '5093',
    city: 'Hintertal'
  },
  {
    postalCode: '5093',
    city: 'Oberweißbach'
  },
  {
    postalCode: '5093',
    city: 'Pürzlbach'
  },
  {
    postalCode: '5093',
    city: 'Unterweißbach'
  },
  {
    postalCode: '5101',
    city: 'Bergheim'
  },
  {
    postalCode: '5101',
    city: 'Lengfelden'
  },
  {
    postalCode: '5101',
    city: 'Muntigl'
  },
  {
    postalCode: '5101',
    city: 'Plain'
  },
  {
    postalCode: '5101',
    city: 'Voggenberg'
  },
  {
    postalCode: '5101',
    city: 'Salzburg'
  },
  {
    postalCode: '5102',
    city: 'Acharting'
  },
  {
    postalCode: '5102',
    city: 'Anthering'
  },
  {
    postalCode: '5102',
    city: 'Anzfelden'
  },
  {
    postalCode: '5102',
    city: 'Berg'
  },
  {
    postalCode: '5102',
    city: 'Kobl'
  },
  {
    postalCode: '5102',
    city: 'Lehen'
  },
  {
    postalCode: '5102',
    city: 'Ried'
  },
  {
    postalCode: '5102',
    city: 'Schönberg'
  },
  {
    postalCode: '5102',
    city: 'Trainting'
  },
  {
    postalCode: '5102',
    city: 'Wald'
  },
  {
    postalCode: '5102',
    city: 'Würzenberg'
  },
  {
    postalCode: '5102',
    city: 'Wurmassing'
  },
  {
    postalCode: '5102',
    city: 'Gollacken'
  },
  {
    postalCode: '5110',
    city: 'Loipferding'
  },
  {
    postalCode: '5110',
    city: 'Haidenöster'
  },
  {
    postalCode: '5110',
    city: 'Oberndorf bei Salzburg'
  },
  {
    postalCode: '5111',
    city: 'Bürmoos'
  },
  {
    postalCode: '5112',
    city: 'Knotzing'
  },
  {
    postalCode: '5112',
    city: 'Asten'
  },
  {
    postalCode: '5112',
    city: 'Ausserfürt'
  },
  {
    postalCode: '5112',
    city: 'Bruck'
  },
  {
    postalCode: '5112',
    city: 'Gresenberg'
  },
  {
    postalCode: '5112',
    city: 'Hausmoning'
  },
  {
    postalCode: '5112',
    city: 'Innerfürt'
  },
  {
    postalCode: '5112',
    city: 'Lamprechtshausen'
  },
  {
    postalCode: '5112',
    city: 'Maxdorf'
  },
  {
    postalCode: '5112',
    city: 'Niederarnsdorf'
  },
  {
    postalCode: '5112',
    city: 'Nopping'
  },
  {
    postalCode: '5112',
    city: 'Oberarnsdorf'
  },
  {
    postalCode: '5112',
    city: 'Reicherting'
  },
  {
    postalCode: '5112',
    city: 'Riedlkam'
  },
  {
    postalCode: '5112',
    city: 'Sankt Alban'
  },
  {
    postalCode: '5112',
    city: 'Schmieden'
  },
  {
    postalCode: '5112',
    city: 'Schwerting'
  },
  {
    postalCode: '5112',
    city: 'Stockham'
  },
  {
    postalCode: '5112',
    city: 'Weidental'
  },
  {
    postalCode: '5112',
    city: 'Wildmann'
  },
  {
    postalCode: '5112',
    city: 'Willenberg'
  },
  {
    postalCode: '5113',
    city: 'Aglassing'
  },
  {
    postalCode: '5113',
    city: 'Au'
  },
  {
    postalCode: '5113',
    city: 'Bruckenholz'
  },
  {
    postalCode: '5113',
    city: 'Helmberg'
  },
  {
    postalCode: '5113',
    city: 'Holzhausen'
  },
  {
    postalCode: '5113',
    city: 'Irlach'
  },
  {
    postalCode: '5113',
    city: 'Jauchsdorf'
  },
  {
    postalCode: '5113',
    city: 'Königsberg'
  },
  {
    postalCode: '5113',
    city: 'Krögn'
  },
  {
    postalCode: '5113',
    city: 'Moospirach'
  },
  {
    postalCode: '5113',
    city: 'Obereching'
  },
  {
    postalCode: '5113',
    city: 'Ölling'
  },
  {
    postalCode: '5113',
    city: 'Roding'
  },
  {
    postalCode: '5113',
    city: 'Sankt Georgen bei Salzburg'
  },
  {
    postalCode: '5113',
    city: 'Seetal'
  },
  {
    postalCode: '5113',
    city: 'Untereching'
  },
  {
    postalCode: '5113',
    city: 'Vollern'
  },
  {
    postalCode: '5114',
    city: 'Bulharting'
  },
  {
    postalCode: '5114',
    city: 'Göming'
  },
  {
    postalCode: '5114',
    city: 'Gunsering'
  },
  {
    postalCode: '5114',
    city: 'Kemating'
  },
  {
    postalCode: '5114',
    city: 'Reinberg'
  },
  {
    postalCode: '5114',
    city: 'Dreimühlen'
  },
  {
    postalCode: '5114',
    city: 'Mittergöming'
  },
  {
    postalCode: '5120',
    city: 'Edt'
  },
  {
    postalCode: '5120',
    city: 'Haigermoos'
  },
  {
    postalCode: '5120',
    city: 'Ortholling'
  },
  {
    postalCode: '5120',
    city: 'Pfaffing'
  },
  {
    postalCode: '5120',
    city: 'Weyer'
  },
  {
    postalCode: '5120',
    city: 'Hollersbach'
  },
  {
    postalCode: '5120',
    city: 'Kirchberg'
  },
  {
    postalCode: '5120',
    city: 'Laubenbach'
  },
  {
    postalCode: '5120',
    city: 'Loidersdorf'
  },
  {
    postalCode: '5120',
    city: 'Mühlach'
  },
  {
    postalCode: '5120',
    city: 'Pirach'
  },
  {
    postalCode: '5120',
    city: 'Reith'
  },
  {
    postalCode: '5120',
    city: 'Riedersbach'
  },
  {
    postalCode: '5120',
    city: 'Sankt Pantaleon'
  },
  {
    postalCode: '5120',
    city: 'Seeleiten'
  },
  {
    postalCode: '5120',
    city: 'Steinwag'
  },
  {
    postalCode: '5120',
    city: 'Stockham'
  },
  {
    postalCode: '5120',
    city: 'Trimmelkam'
  },
  {
    postalCode: '5120',
    city: 'Wildshut'
  },
  {
    postalCode: '5121',
    city: 'Weißplatz'
  },
  {
    postalCode: '5121',
    city: 'Marktl'
  },
  {
    postalCode: '5121',
    city: 'Diepoltsdorf'
  },
  {
    postalCode: '5121',
    city: 'Ernsting'
  },
  {
    postalCode: '5121',
    city: 'Ettenau'
  },
  {
    postalCode: '5121',
    city: 'Felm'
  },
  {
    postalCode: '5121',
    city: 'Gumpling'
  },
  {
    postalCode: '5121',
    city: 'Hollersbach'
  },
  {
    postalCode: '5121',
    city: 'Ostermiething'
  },
  {
    postalCode: '5121',
    city: 'Roidham'
  },
  {
    postalCode: '5121',
    city: 'Simling'
  },
  {
    postalCode: '5121',
    city: 'Sinzing'
  },
  {
    postalCode: '5121',
    city: 'Steinbach'
  },
  {
    postalCode: '5121',
    city: 'Wimm'
  },
  {
    postalCode: '5121',
    city: 'Eichbichl'
  },
  {
    postalCode: '5121',
    city: 'Hadermarkt'
  },
  {
    postalCode: '5121',
    city: 'Schwabenlandl'
  },
  {
    postalCode: '5121',
    city: 'Werfenau'
  },
  {
    postalCode: '5121',
    city: 'Neues Dorf'
  },
  {
    postalCode: '5121',
    city: 'Döstling'
  },
  {
    postalCode: '5121',
    city: 'Eckldorf'
  },
  {
    postalCode: '5121',
    city: 'Ehersdorf'
  },
  {
    postalCode: '5121',
    city: 'Fucking'
  },
  {
    postalCode: '5121',
    city: 'Haid'
  },
  {
    postalCode: '5121',
    city: 'Hörndl'
  },
  {
    postalCode: '5121',
    city: 'Hofstadt'
  },
  {
    postalCode: '5121',
    city: 'Hofweiden'
  },
  {
    postalCode: '5121',
    city: 'Hucking'
  },
  {
    postalCode: '5121',
    city: 'Leithen'
  },
  {
    postalCode: '5121',
    city: 'Ölling'
  },
  {
    postalCode: '5121',
    city: 'Schmidham'
  },
  {
    postalCode: '5121',
    city: 'Staig'
  },
  {
    postalCode: '5121',
    city: 'Tarsdorf'
  },
  {
    postalCode: '5121',
    city: 'Winham'
  },
  {
    postalCode: '5121',
    city: 'Wolfing'
  },
  {
    postalCode: '5121',
    city: 'Wupping'
  },
  {
    postalCode: '5122',
    city: 'Ach'
  },
  {
    postalCode: '5122',
    city: 'Barsdorf'
  },
  {
    postalCode: '5122',
    city: 'Dorfen'
  },
  {
    postalCode: '5122',
    city: 'Duttendorf'
  },
  {
    postalCode: '5122',
    city: 'Endt'
  },
  {
    postalCode: '5122',
    city: 'Geretsdorf'
  },
  {
    postalCode: '5122',
    city: 'Grünhilling'
  },
  {
    postalCode: '5122',
    city: 'Grund'
  },
  {
    postalCode: '5122',
    city: 'Hochburg'
  },
  {
    postalCode: '5122',
    city: 'Holzgassen'
  },
  {
    postalCode: '5122',
    city: 'Kälbermoos'
  },
  {
    postalCode: '5122',
    city: 'Kreil'
  },
  {
    postalCode: '5122',
    city: 'Lindach'
  },
  {
    postalCode: '5122',
    city: 'Mitterndorf'
  },
  {
    postalCode: '5122',
    city: 'Oberkriebach'
  },
  {
    postalCode: '5122',
    city: 'Reisach'
  },
  {
    postalCode: '5122',
    city: 'Reith'
  },
  {
    postalCode: '5122',
    city: 'Sengstatt'
  },
  {
    postalCode: '5122',
    city: 'Staudach'
  },
  {
    postalCode: '5122',
    city: 'Thann'
  },
  {
    postalCode: '5122',
    city: 'Unterkriebach'
  },
  {
    postalCode: '5122',
    city: 'Unterweitzberg'
  },
  {
    postalCode: '5122',
    city: 'Wanghausen'
  },
  {
    postalCode: '5122',
    city: 'Weng'
  },
  {
    postalCode: '5122',
    city: 'Werfenau'
  },
  {
    postalCode: '5122',
    city: 'Aufhausen'
  },
  {
    postalCode: '5122',
    city: 'Berg'
  },
  {
    postalCode: '5122',
    city: 'Kreuzlinden'
  },
  {
    postalCode: '5122',
    city: 'Mühltal'
  },
  {
    postalCode: '5122',
    city: 'Überackern'
  },
  {
    postalCode: '5131',
    city: 'Buch'
  },
  {
    postalCode: '5131',
    city: 'Dorfibm'
  },
  {
    postalCode: '5131',
    city: 'Eggenham'
  },
  {
    postalCode: '5131',
    city: 'Eisengöring'
  },
  {
    postalCode: '5131',
    city: 'Franking'
  },
  {
    postalCode: '5131',
    city: 'Holzleithen'
  },
  {
    postalCode: '5131',
    city: 'Holzöster'
  },
  {
    postalCode: '5131',
    city: 'Neuhausen'
  },
  {
    postalCode: '5131',
    city: 'Oberfranking'
  },
  {
    postalCode: '5131',
    city: 'Pimbach'
  },
  {
    postalCode: '5131',
    city: 'Aich'
  },
  {
    postalCode: '5131',
    city: 'Hehermoos'
  },
  {
    postalCode: '5131',
    city: 'Witzling'
  },
  {
    postalCode: '5132',
    city: 'Brunn'
  },
  {
    postalCode: '5132',
    city: 'Ehrschwendt'
  },
  {
    postalCode: '5132',
    city: 'Emmersberg'
  },
  {
    postalCode: '5132',
    city: 'Gasteig'
  },
  {
    postalCode: '5132',
    city: 'Geretsberg'
  },
  {
    postalCode: '5132',
    city: 'Goldbrunn'
  },
  {
    postalCode: '5132',
    city: 'Henkham'
  },
  {
    postalCode: '5132',
    city: 'Hinterhof'
  },
  {
    postalCode: '5132',
    city: 'Kobl'
  },
  {
    postalCode: '5132',
    city: 'Lehrsberg'
  },
  {
    postalCode: '5132',
    city: 'Maxlmoos'
  },
  {
    postalCode: '5132',
    city: 'Mühlberg'
  },
  {
    postalCode: '5132',
    city: 'Pimbach'
  },
  {
    postalCode: '5132',
    city: 'Preisenberg'
  },
  {
    postalCode: '5132',
    city: 'Reith'
  },
  {
    postalCode: '5132',
    city: 'Straß'
  },
  {
    postalCode: '5132',
    city: 'Webersdorf'
  },
  {
    postalCode: '5132',
    city: 'Weißplatz'
  },
  {
    postalCode: '5132',
    city: 'Werberg'
  },
  {
    postalCode: '5133',
    city: 'Webersdorf'
  },
  {
    postalCode: '5133',
    city: 'Baumgarten'
  },
  {
    postalCode: '5133',
    city: 'Bierberg'
  },
  {
    postalCode: '5133',
    city: 'Bitzlthal'
  },
  {
    postalCode: '5133',
    city: 'Dick'
  },
  {
    postalCode: '5133',
    city: 'Gilgenberg am Weilhart'
  },
  {
    postalCode: '5133',
    city: 'Gilgenberg Revier'
  },
  {
    postalCode: '5133',
    city: 'Hinterklam'
  },
  {
    postalCode: '5133',
    city: 'Hof'
  },
  {
    postalCode: '5133',
    city: 'Hoißgassen'
  },
  {
    postalCode: '5133',
    city: 'Hopfersbach'
  },
  {
    postalCode: '5133',
    city: 'Hub'
  },
  {
    postalCode: '5133',
    city: 'Lohnsberg'
  },
  {
    postalCode: '5133',
    city: 'Mairhof'
  },
  {
    postalCode: '5133',
    city: 'Reith'
  },
  {
    postalCode: '5133',
    city: 'Röhrn'
  },
  {
    postalCode: '5133',
    city: 'Ruderstallgassen'
  },
  {
    postalCode: '5133',
    city: 'Schnellberg'
  },
  {
    postalCode: '5133',
    city: 'Sterz'
  },
  {
    postalCode: '5133',
    city: 'Weidenthal'
  },
  {
    postalCode: '5133',
    city: 'Zeisberg'
  },
  {
    postalCode: '5134',
    city: 'Bierberg'
  },
  {
    postalCode: '5134',
    city: 'Polzwies'
  },
  {
    postalCode: '5134',
    city: 'Spieglern'
  },
  {
    postalCode: '5134',
    city: 'Viermaiern'
  },
  {
    postalCode: '5134',
    city: 'Adenbrunn'
  },
  {
    postalCode: '5134',
    city: 'Berg'
  },
  {
    postalCode: '5134',
    city: 'Berndorf'
  },
  {
    postalCode: '5134',
    city: 'Bernhof'
  },
  {
    postalCode: '5134',
    city: 'Bruck'
  },
  {
    postalCode: '5134',
    city: 'Bruck im Holz'
  },
  {
    postalCode: '5134',
    city: 'Brunn im Gries'
  },
  {
    postalCode: '5134',
    city: 'Brunnthal'
  },
  {
    postalCode: '5134',
    city: 'Ebenthal'
  },
  {
    postalCode: '5134',
    city: 'Ginshöring'
  },
  {
    postalCode: '5134',
    city: 'Gries'
  },
  {
    postalCode: '5134',
    city: 'Haus'
  },
  {
    postalCode: '5134',
    city: 'Holz'
  },
  {
    postalCode: '5134',
    city: 'Kammern'
  },
  {
    postalCode: '5134',
    city: 'Kronleiten'
  },
  {
    postalCode: '5134',
    city: 'Ottenschwand'
  },
  {
    postalCode: '5134',
    city: 'Paischen'
  },
  {
    postalCode: '5134',
    city: 'Paschen'
  },
  {
    postalCode: '5134',
    city: 'Prielhof'
  },
  {
    postalCode: '5134',
    city: 'Reith'
  },
  {
    postalCode: '5134',
    city: 'Reuhub'
  },
  {
    postalCode: '5134',
    city: 'Schmieding'
  },
  {
    postalCode: '5134',
    city: 'Schwand im Innkreis'
  },
  {
    postalCode: '5134',
    city: 'Semmelhof'
  },
  {
    postalCode: '5134',
    city: 'Sengthal'
  },
  {
    postalCode: '5134',
    city: 'Siebenmaiern'
  },
  {
    postalCode: '5134',
    city: 'Weilhart'
  },
  {
    postalCode: '5134',
    city: 'Mühltal'
  },
  {
    postalCode: '5141',
    city: 'Einsperg'
  },
  {
    postalCode: '5141',
    city: 'Elling'
  },
  {
    postalCode: '5141',
    city: 'Furkern'
  },
  {
    postalCode: '5141',
    city: 'Habersdorf'
  },
  {
    postalCode: '5141',
    city: 'Haslach'
  },
  {
    postalCode: '5141',
    city: 'Jedendorf'
  },
  {
    postalCode: '5141',
    city: 'Kimmelsdorf'
  },
  {
    postalCode: '5141',
    city: 'Moosdorf'
  },
  {
    postalCode: '5141',
    city: 'Puttenhausen'
  },
  {
    postalCode: '5141',
    city: 'Seeleiten'
  },
  {
    postalCode: '5141',
    city: 'Stadl'
  },
  {
    postalCode: '5141',
    city: 'Weichsee'
  },
  {
    postalCode: '5141',
    city: 'Hackenbuch'
  },
  {
    postalCode: '5141',
    city: 'Moosmühl'
  },
  {
    postalCode: '5141',
    city: 'Mühlbach'
  },
  {
    postalCode: '5142',
    city: 'Arnstetten'
  },
  {
    postalCode: '5142',
    city: 'Autmannsdorf'
  },
  {
    postalCode: '5142',
    city: 'Beckenberg'
  },
  {
    postalCode: '5142',
    city: 'Bergstetten'
  },
  {
    postalCode: '5142',
    city: 'Eggelsberg'
  },
  {
    postalCode: '5142',
    city: 'Großschäding'
  },
  {
    postalCode: '5142',
    city: 'Gundertshausen'
  },
  {
    postalCode: '5142',
    city: 'Haselreith'
  },
  {
    postalCode: '5142',
    city: 'Hehenberg'
  },
  {
    postalCode: '5142',
    city: 'Heimhausen'
  },
  {
    postalCode: '5142',
    city: 'Hitzging'
  },
  {
    postalCode: '5142',
    city: 'Höpfling'
  },
  {
    postalCode: '5142',
    city: 'Hötzenau'
  },
  {
    postalCode: '5142',
    city: 'Ibm'
  },
  {
    postalCode: '5142',
    city: 'Kleinschäding'
  },
  {
    postalCode: '5142',
    city: 'Meindlsberg'
  },
  {
    postalCode: '5142',
    city: 'Miesling'
  },
  {
    postalCode: '5142',
    city: 'Oberhaslach'
  },
  {
    postalCode: '5142',
    city: 'Oberhaunsberg'
  },
  {
    postalCode: '5142',
    city: 'Pippmannsberg'
  },
  {
    postalCode: '5142',
    city: 'Revier Eggelsberg'
  },
  {
    postalCode: '5142',
    city: 'Revier Gundertshausen'
  },
  {
    postalCode: '5142',
    city: 'Revier Heimhausen'
  },
  {
    postalCode: '5142',
    city: 'Trametshausen'
  },
  {
    postalCode: '5142',
    city: 'Untergrub'
  },
  {
    postalCode: '5142',
    city: 'Unterhaunsberg'
  },
  {
    postalCode: '5142',
    city: 'Wannersdorf'
  },
  {
    postalCode: '5142',
    city: 'Weilbuch'
  },
  {
    postalCode: '5142',
    city: 'Weinberg'
  },
  {
    postalCode: '5142',
    city: 'Gerberling'
  },
  {
    postalCode: '5143',
    city: 'Aich'
  },
  {
    postalCode: '5143',
    city: 'Altheim'
  },
  {
    postalCode: '5143',
    city: 'Aschau'
  },
  {
    postalCode: '5143',
    city: 'Außerpirach'
  },
  {
    postalCode: '5143',
    city: 'Bamberg'
  },
  {
    postalCode: '5143',
    city: 'Burgkirchen'
  },
  {
    postalCode: '5143',
    city: 'Edt'
  },
  {
    postalCode: '5143',
    city: 'Emerding'
  },
  {
    postalCode: '5143',
    city: 'Feldkirchen bei Mattighofen'
  },
  {
    postalCode: '5143',
    city: 'Gietzing'
  },
  {
    postalCode: '5143',
    city: 'Gstaig'
  },
  {
    postalCode: '5143',
    city: 'Hafenberg'
  },
  {
    postalCode: '5143',
    city: 'Haiderthal'
  },
  {
    postalCode: '5143',
    city: 'Hansried'
  },
  {
    postalCode: '5143',
    city: 'Haselpfaffing'
  },
  {
    postalCode: '5143',
    city: 'Hennergraben'
  },
  {
    postalCode: '5143',
    city: 'Höslrein'
  },
  {
    postalCode: '5143',
    city: 'Holz'
  },
  {
    postalCode: '5143',
    city: 'Innerpirach'
  },
  {
    postalCode: '5143',
    city: 'Jetzing'
  },
  {
    postalCode: '5143',
    city: 'Kampern'
  },
  {
    postalCode: '5143',
    city: 'Kendling'
  },
  {
    postalCode: '5143',
    city: 'Klöpfing'
  },
  {
    postalCode: '5143',
    city: 'Öppelhausen'
  },
  {
    postalCode: '5143',
    city: 'Oichten'
  },
  {
    postalCode: '5143',
    city: 'Ottenhausen'
  },
  {
    postalCode: '5143',
    city: 'Otterfing'
  },
  {
    postalCode: '5143',
    city: 'Primsing'
  },
  {
    postalCode: '5143',
    city: 'Quick'
  },
  {
    postalCode: '5143',
    city: 'Renzlhausen'
  },
  {
    postalCode: '5143',
    city: 'Revier Renzlhausen'
  },
  {
    postalCode: '5143',
    city: 'Sattlern'
  },
  {
    postalCode: '5143',
    city: 'Sperledt'
  },
  {
    postalCode: '5143',
    city: 'Vormoos'
  },
  {
    postalCode: '5143',
    city: 'Wenigaschau'
  },
  {
    postalCode: '5143',
    city: 'Wexling'
  },
  {
    postalCode: '5143',
    city: 'Wiesing'
  },
  {
    postalCode: '5143',
    city: 'Willersdorf'
  },
  {
    postalCode: '5144',
    city: 'Adenberg'
  },
  {
    postalCode: '5144',
    city: 'Aigen'
  },
  {
    postalCode: '5144',
    city: 'Apfental'
  },
  {
    postalCode: '5144',
    city: 'Buchsberg'
  },
  {
    postalCode: '5144',
    city: 'Eckbach'
  },
  {
    postalCode: '5144',
    city: 'Edthof'
  },
  {
    postalCode: '5144',
    city: 'Großschieder'
  },
  {
    postalCode: '5144',
    city: 'Handenberg'
  },
  {
    postalCode: '5144',
    city: 'Hangöbl'
  },
  {
    postalCode: '5144',
    city: 'Hinterberg'
  },
  {
    postalCode: '5144',
    city: 'Kastenberg'
  },
  {
    postalCode: '5144',
    city: 'Kleinschieder'
  },
  {
    postalCode: '5144',
    city: 'Kölln'
  },
  {
    postalCode: '5144',
    city: 'Kohlbach'
  },
  {
    postalCode: '5144',
    city: 'Leimhof'
  },
  {
    postalCode: '5144',
    city: 'Moos'
  },
  {
    postalCode: '5144',
    city: 'Pöllersberg'
  },
  {
    postalCode: '5144',
    city: 'Polzwies'
  },
  {
    postalCode: '5144',
    city: 'Sandtal'
  },
  {
    postalCode: '5144',
    city: 'Straß'
  },
  {
    postalCode: '5144',
    city: 'Utting'
  },
  {
    postalCode: '5144',
    city: 'Weg'
  },
  {
    postalCode: '5144',
    city: 'Wurmshub'
  },
  {
    postalCode: '5144',
    city: 'Zaun'
  },
  {
    postalCode: '5144',
    city: 'Anferding'
  },
  {
    postalCode: '5144',
    city: 'Angern'
  },
  {
    postalCode: '5144',
    city: 'Brandstatt'
  },
  {
    postalCode: '5144',
    city: 'Feichten'
  },
  {
    postalCode: '5144',
    city: 'Fillmannsbach'
  },
  {
    postalCode: '5144',
    city: 'Reichsberg'
  },
  {
    postalCode: '5144',
    city: 'Sankt Georgen am Fillmannsbach'
  },
  {
    postalCode: '5144',
    city: 'Scheuern'
  },
  {
    postalCode: '5144',
    city: 'Steckenbach'
  },
  {
    postalCode: '5144',
    city: 'Wies'
  },
  {
    postalCode: '5145',
    city: 'Bachleiten'
  },
  {
    postalCode: '5145',
    city: 'Brand'
  },
  {
    postalCode: '5145',
    city: 'Frieseneck'
  },
  {
    postalCode: '5145',
    city: 'Grillham'
  },
  {
    postalCode: '5145',
    city: 'Scheuhub'
  },
  {
    postalCode: '5145',
    city: 'Solling'
  },
  {
    postalCode: '5145',
    city: 'Walzing'
  },
  {
    postalCode: '5145',
    city: 'Apfenthal'
  },
  {
    postalCode: '5145',
    city: 'Badhub'
  },
  {
    postalCode: '5145',
    city: 'Bogendorf'
  },
  {
    postalCode: '5145',
    city: 'Bründl'
  },
  {
    postalCode: '5145',
    city: 'Brunn im Gries'
  },
  {
    postalCode: '5145',
    city: 'Dietzing'
  },
  {
    postalCode: '5145',
    city: 'Dorf'
  },
  {
    postalCode: '5145',
    city: 'Eisenhub'
  },
  {
    postalCode: '5145',
    city: 'Engelberg'
  },
  {
    postalCode: '5145',
    city: 'Enknach'
  },
  {
    postalCode: '5145',
    city: 'Eschberg'
  },
  {
    postalCode: '5145',
    city: 'Friedrichsdorf'
  },
  {
    postalCode: '5145',
    city: 'Gsotthub'
  },
  {
    postalCode: '5145',
    city: 'Häusl'
  },
  {
    postalCode: '5145',
    city: 'Händschuh'
  },
  {
    postalCode: '5145',
    city: 'Hinterberg'
  },
  {
    postalCode: '5145',
    city: 'Hof'
  },
  {
    postalCode: '5145',
    city: 'Hollstraß'
  },
  {
    postalCode: '5145',
    city: 'Kammerleiten'
  },
  {
    postalCode: '5145',
    city: 'Kirchweg'
  },
  {
    postalCode: '5145',
    city: 'Königsaich'
  },
  {
    postalCode: '5145',
    city: 'Kottingauerbach'
  },
  {
    postalCode: '5145',
    city: 'Maierhof'
  },
  {
    postalCode: '5145',
    city: 'Neukirchen an der Enknach'
  },
  {
    postalCode: '5145',
    city: 'Oberguggen'
  },
  {
    postalCode: '5145',
    city: 'Oberthal'
  },
  {
    postalCode: '5145',
    city: 'Österlehen'
  },
  {
    postalCode: '5145',
    city: 'Paßberg'
  },
  {
    postalCode: '5145',
    city: 'Rittersberg'
  },
  {
    postalCode: '5145',
    city: 'Schmalzhofen'
  },
  {
    postalCode: '5145',
    city: 'Schützing'
  },
  {
    postalCode: '5145',
    city: 'Spritzendorf'
  },
  {
    postalCode: '5145',
    city: 'Stadlern'
  },
  {
    postalCode: '5145',
    city: 'Stockhofen'
  },
  {
    postalCode: '5145',
    city: 'Stoibergassen'
  },
  {
    postalCode: '5145',
    city: 'Straß'
  },
  {
    postalCode: '5145',
    city: 'Tausendengel'
  },
  {
    postalCode: '5145',
    city: 'Unterguggen'
  },
  {
    postalCode: '5145',
    city: 'Unterholz'
  },
  {
    postalCode: '5145',
    city: 'Wiesmaiern'
  },
  {
    postalCode: '5151',
    city: 'Altsberg'
  },
  {
    postalCode: '5151',
    city: 'Durchham'
  },
  {
    postalCode: '5151',
    city: 'Eisping'
  },
  {
    postalCode: '5151',
    city: 'Gastein'
  },
  {
    postalCode: '5151',
    city: 'Hainbach'
  },
  {
    postalCode: '5151',
    city: 'Hochberg'
  },
  {
    postalCode: '5151',
    city: 'Irlach'
  },
  {
    postalCode: '5151',
    city: 'Kleinberg'
  },
  {
    postalCode: '5151',
    city: 'Kroisbach'
  },
  {
    postalCode: '5151',
    city: 'Lauterbach'
  },
  {
    postalCode: '5151',
    city: 'Liersching'
  },
  {
    postalCode: '5151',
    city: 'Lukasedt'
  },
  {
    postalCode: '5151',
    city: 'Nußdorf am Haunsberg'
  },
  {
    postalCode: '5151',
    city: 'Olching'
  },
  {
    postalCode: '5151',
    city: 'Pabing'
  },
  {
    postalCode: '5151',
    city: 'Pinswag'
  },
  {
    postalCode: '5151',
    city: 'Reinharting'
  },
  {
    postalCode: '5151',
    city: 'Rottstätt'
  },
  {
    postalCode: '5151',
    city: 'Schlößl'
  },
  {
    postalCode: '5151',
    city: 'Schröck'
  },
  {
    postalCode: '5151',
    city: 'Steinbach'
  },
  {
    postalCode: '5151',
    city: 'Waidach'
  },
  {
    postalCode: '5151',
    city: 'Weitwörth'
  },
  {
    postalCode: '5152',
    city: 'Wagnerfeld'
  },
  {
    postalCode: '5152',
    city: 'Wagnergraben'
  },
  {
    postalCode: '5152',
    city: 'Au'
  },
  {
    postalCode: '5152',
    city: 'Buchach'
  },
  {
    postalCode: '5152',
    city: 'Dorfbeuern'
  },
  {
    postalCode: '5152',
    city: 'Michaelbeuern'
  },
  {
    postalCode: '5152',
    city: 'Reitsberg'
  },
  {
    postalCode: '5152',
    city: 'Scherhaslach'
  },
  {
    postalCode: '5152',
    city: 'Schönberg'
  },
  {
    postalCode: '5152',
    city: 'Thalhausen'
  },
  {
    postalCode: '5152',
    city: 'Vorau'
  },
  {
    postalCode: '5152',
    city: 'Breitenlohe'
  },
  {
    postalCode: '5152',
    city: 'Gumperding'
  },
  {
    postalCode: '5161',
    city: 'Elixhausen'
  },
  {
    postalCode: '5162',
    city: 'Kleinberg'
  },
  {
    postalCode: '5162',
    city: 'Au'
  },
  {
    postalCode: '5162',
    city: 'Mühlbach'
  },
  {
    postalCode: '5162',
    city: 'Obertrum am See'
  },
  {
    postalCode: '5162',
    city: 'Schmiedkeller'
  },
  {
    postalCode: '5162',
    city: 'Schmieding'
  },
  {
    postalCode: '5162',
    city: 'Schöngumprechting'
  },
  {
    postalCode: '5163',
    city: 'Mattsee'
  },
  {
    postalCode: '5163',
    city: 'Bergham'
  },
  {
    postalCode: '5163',
    city: 'Brandstätt'
  },
  {
    postalCode: '5163',
    city: 'Bruck'
  },
  {
    postalCode: '5163',
    city: 'Dietersham'
  },
  {
    postalCode: '5163',
    city: 'Eidenham'
  },
  {
    postalCode: '5163',
    city: 'Fischerjuden'
  },
  {
    postalCode: '5163',
    city: 'Guggenberg'
  },
  {
    postalCode: '5163',
    city: 'Heming'
  },
  {
    postalCode: '5163',
    city: 'Hiltenwiesen'
  },
  {
    postalCode: '5163',
    city: 'Imsee'
  },
  {
    postalCode: '5163',
    city: 'Macking'
  },
  {
    postalCode: '5163',
    city: 'Mödenham'
  },
  {
    postalCode: '5163',
    city: 'Mundenham'
  },
  {
    postalCode: '5163',
    city: 'Neckreith'
  },
  {
    postalCode: '5163',
    city: 'Palting'
  },
  {
    postalCode: '5163',
    city: 'Rutzing'
  },
  {
    postalCode: '5163',
    city: 'Singham'
  },
  {
    postalCode: '5163',
    city: 'Stockham'
  },
  {
    postalCode: '5163',
    city: 'Unteröd'
  },
  {
    postalCode: '5163',
    city: 'Weikertsham'
  },
  {
    postalCode: '5163',
    city: 'Oberröd'
  },
  {
    postalCode: '5163',
    city: 'Unterröd'
  },
  {
    postalCode: '5163',
    city: 'Edt'
  },
  {
    postalCode: '5163',
    city: 'Elexlochen'
  },
  {
    postalCode: '5163',
    city: 'Endfelden'
  },
  {
    postalCode: '5163',
    city: 'Grub'
  },
  {
    postalCode: '5163',
    city: 'Gumperding'
  },
  {
    postalCode: '5163',
    city: 'Hinterbuch'
  },
  {
    postalCode: '5163',
    city: 'Kirchsteig'
  },
  {
    postalCode: '5163',
    city: 'Perwang am Grabensee'
  },
  {
    postalCode: '5163',
    city: 'Reith'
  },
  {
    postalCode: '5163',
    city: 'Rödhausen'
  },
  {
    postalCode: '5163',
    city: 'Rudersberg'
  },
  {
    postalCode: '5163',
    city: 'Stockach'
  },
  {
    postalCode: '5164',
    city: 'Matzing'
  },
  {
    postalCode: '5164',
    city: 'Seeham'
  },
  {
    postalCode: '5165',
    city: 'Berndorf bei Salzburg'
  },
  {
    postalCode: '5165',
    city: 'Großenegg'
  },
  {
    postalCode: '5165',
    city: 'Reinharting'
  },
  {
    postalCode: '5165',
    city: 'Baumgarten'
  },
  {
    postalCode: '5201',
    city: 'Bayerham'
  },
  {
    postalCode: '5201',
    city: 'Brunn'
  },
  {
    postalCode: '5201',
    city: 'Fischtaging'
  },
  {
    postalCode: '5201',
    city: 'Halberstätten'
  },
  {
    postalCode: '5201',
    city: 'Huttich'
  },
  {
    postalCode: '5201',
    city: 'Kothgumprechting'
  },
  {
    postalCode: '5201',
    city: 'Kraiham'
  },
  {
    postalCode: '5201',
    city: 'Mayerlehen'
  },
  {
    postalCode: '5201',
    city: 'Mödlham'
  },
  {
    postalCode: '5201',
    city: 'Schmieding'
  },
  {
    postalCode: '5201',
    city: 'Schöngumprechting'
  },
  {
    postalCode: '5201',
    city: 'Waldprechting'
  },
  {
    postalCode: '5201',
    city: 'Wies'
  },
  {
    postalCode: '5201',
    city: 'Wimm'
  },
  {
    postalCode: '5201',
    city: 'Wimmsiedlung'
  },
  {
    postalCode: '5201',
    city: 'Zaisberg'
  },
  {
    postalCode: '5201',
    city: 'Seekirchen am Wallersee'
  },
  {
    postalCode: '5201',
    city: 'Ried'
  },
  {
    postalCode: '5201',
    city: 'Seewalchen'
  },
  {
    postalCode: '5202',
    city: 'Kleinköstendorf'
  },
  {
    postalCode: '5202',
    city: 'Lengroid'
  },
  {
    postalCode: '5202',
    city: 'Maierhof'
  },
  {
    postalCode: '5202',
    city: 'Matzing'
  },
  {
    postalCode: '5202',
    city: 'Neufahrn'
  },
  {
    postalCode: '5202',
    city: 'Neumarkt am Wallersee'
  },
  {
    postalCode: '5202',
    city: 'Pfongau'
  },
  {
    postalCode: '5202',
    city: 'Schalkham'
  },
  {
    postalCode: '5202',
    city: 'Sighartstein'
  },
  {
    postalCode: '5202',
    city: 'Sommerholz'
  },
  {
    postalCode: '5202',
    city: 'Thalham'
  },
  {
    postalCode: '5202',
    city: 'Wallersee-Ostbucht'
  },
  {
    postalCode: '5202',
    city: 'Wertheim'
  },
  {
    postalCode: '5202',
    city: 'Höhenroith'
  },
  {
    postalCode: '5203',
    city: 'Tödtleinsdorf'
  },
  {
    postalCode: '5203',
    city: 'Enharting'
  },
  {
    postalCode: '5203',
    city: 'Gramling'
  },
  {
    postalCode: '5203',
    city: 'Köstendorf'
  },
  {
    postalCode: '5203',
    city: 'Helming'
  },
  {
    postalCode: '5203',
    city: 'Hilgertsheim'
  },
  {
    postalCode: '5203',
    city: 'Kleinköstendorf'
  },
  {
    postalCode: '5203',
    city: 'Spanswag'
  },
  {
    postalCode: '5203',
    city: 'Tannham'
  },
  {
    postalCode: '5203',
    city: 'Weng'
  },
  {
    postalCode: '5203',
    city: 'Huttich'
  },
  {
    postalCode: '5204',
    city: 'Aigelsbrunn'
  },
  {
    postalCode: '5204',
    city: 'Außerroid'
  },
  {
    postalCode: '5204',
    city: 'Bodenberg'
  },
  {
    postalCode: '5204',
    city: 'Bruckmoos'
  },
  {
    postalCode: '5204',
    city: 'Brunn'
  },
  {
    postalCode: '5204',
    city: 'Fißlthal'
  },
  {
    postalCode: '5204',
    city: 'Haarlacken'
  },
  {
    postalCode: '5204',
    city: 'Haidach'
  },
  {
    postalCode: '5204',
    city: 'Haslach'
  },
  {
    postalCode: '5204',
    city: 'Hüttenedt'
  },
  {
    postalCode: '5204',
    city: 'Innerroid'
  },
  {
    postalCode: '5204',
    city: 'Irrsdorf'
  },
  {
    postalCode: '5204',
    city: 'Jagdhub'
  },
  {
    postalCode: '5204',
    city: 'Latein'
  },
  {
    postalCode: '5204',
    city: 'Neuhofen'
  },
  {
    postalCode: '5204',
    city: 'Pfenninglanden'
  },
  {
    postalCode: '5204',
    city: 'Rattensam'
  },
  {
    postalCode: '5204',
    city: 'Roidwalchen'
  },
  {
    postalCode: '5204',
    city: 'Ruckling'
  },
  {
    postalCode: '5204',
    city: 'Stadlberg'
  },
  {
    postalCode: '5204',
    city: 'Steindorf'
  },
  {
    postalCode: '5204',
    city: 'Stockham'
  },
  {
    postalCode: '5204',
    city: 'Straßwalchen'
  },
  {
    postalCode: '5204',
    city: 'Taigen'
  },
  {
    postalCode: '5204',
    city: 'Voglhub'
  },
  {
    postalCode: '5204',
    city: 'Watzlberg'
  },
  {
    postalCode: '5204',
    city: 'Winkl'
  },
  {
    postalCode: '5204',
    city: 'Holzfeld'
  },
  {
    postalCode: '5204',
    city: 'Giga'
  },
  {
    postalCode: '5204',
    city: 'Schwaigern'
  },
  {
    postalCode: '5205',
    city: 'Schleedorf'
  },
  {
    postalCode: '5211',
    city: 'Ameisberg'
  },
  {
    postalCode: '5211',
    city: 'Aug'
  },
  {
    postalCode: '5211',
    city: 'Bach'
  },
  {
    postalCode: '5211',
    city: 'Baierberg'
  },
  {
    postalCode: '5211',
    city: 'Flörlplain'
  },
  {
    postalCode: '5211',
    city: 'Frauscherberg'
  },
  {
    postalCode: '5211',
    city: 'Friedburg'
  },
  {
    postalCode: '5211',
    city: 'Gassl'
  },
  {
    postalCode: '5211',
    city: 'Gollmannseck'
  },
  {
    postalCode: '5211',
    city: 'Gstöckat'
  },
  {
    postalCode: '5211',
    city: 'Heiligenstatt'
  },
  {
    postalCode: '5211',
    city: 'Holz'
  },
  {
    postalCode: '5211',
    city: 'Igelsberg'
  },
  {
    postalCode: '5211',
    city: 'Kühbichl'
  },
  {
    postalCode: '5211',
    city: 'Lengau'
  },
  {
    postalCode: '5211',
    city: 'Mittererb'
  },
  {
    postalCode: '5211',
    city: 'Oberehrneck'
  },
  {
    postalCode: '5211',
    city: 'Pfannenstiel'
  },
  {
    postalCode: '5211',
    city: 'Sankt Ulrich'
  },
  {
    postalCode: '5211',
    city: 'Schwöll'
  },
  {
    postalCode: '5211',
    city: 'Teichstätt'
  },
  {
    postalCode: '5211',
    city: 'Unterehrneck'
  },
  {
    postalCode: '5211',
    city: 'Untererb'
  },
  {
    postalCode: '5211',
    city: 'Utzweih'
  },
  {
    postalCode: '5211',
    city: 'Wimpassing'
  },
  {
    postalCode: '5212',
    city: 'Edt'
  },
  {
    postalCode: '5212',
    city: 'Höcken'
  },
  {
    postalCode: '5212',
    city: 'Krenwald'
  },
  {
    postalCode: '5212',
    city: 'Obererb'
  },
  {
    postalCode: '5212',
    city: 'Schneegattern'
  },
  {
    postalCode: '5212',
    city: 'Hocheck'
  },
  {
    postalCode: '5221',
    city: 'Ainhausen'
  },
  {
    postalCode: '5221',
    city: 'Astätt'
  },
  {
    postalCode: '5221',
    city: 'Babenham'
  },
  {
    postalCode: '5221',
    city: 'Bergham'
  },
  {
    postalCode: '5221',
    city: 'Daxjuden'
  },
  {
    postalCode: '5221',
    city: 'Dirnham'
  },
  {
    postalCode: '5221',
    city: 'Edenplain'
  },
  {
    postalCode: '5221',
    city: 'Feldbach'
  },
  {
    postalCode: '5221',
    city: 'Gebertsham'
  },
  {
    postalCode: '5221',
    city: 'Gunzing'
  },
  {
    postalCode: '5221',
    city: 'Gutferding'
  },
  {
    postalCode: '5221',
    city: 'Hofstätt'
  },
  {
    postalCode: '5221',
    city: 'Intenham'
  },
  {
    postalCode: '5221',
    city: 'Kerschham'
  },
  {
    postalCode: '5221',
    city: 'Koppelstätt'
  },
  {
    postalCode: '5221',
    city: 'Kranzing'
  },
  {
    postalCode: '5221',
    city: 'Laßberg'
  },
  {
    postalCode: '5221',
    city: 'Lochen'
  },
  {
    postalCode: '5221',
    city: 'Niedertrum'
  },
  {
    postalCode: '5221',
    city: 'Oberhaft'
  },
  {
    postalCode: '5221',
    city: 'Oberweißau'
  },
  {
    postalCode: '5221',
    city: 'Petersham'
  },
  {
    postalCode: '5221',
    city: 'Rackersing'
  },
  {
    postalCode: '5221',
    city: 'Reitsham'
  },
  {
    postalCode: '5221',
    city: 'Scherschham'
  },
  {
    postalCode: '5221',
    city: 'Schimmerljuden'
  },
  {
    postalCode: '5221',
    city: 'Sprinzenberg'
  },
  {
    postalCode: '5221',
    city: 'Stein'
  },
  {
    postalCode: '5221',
    city: 'Stullerding'
  },
  {
    postalCode: '5221',
    city: 'Tannberg'
  },
  {
    postalCode: '5221',
    city: 'Unterlochen'
  },
  {
    postalCode: '5221',
    city: 'Wichenham'
  },
  {
    postalCode: '5221',
    city: 'Zeisental'
  },
  {
    postalCode: '5222',
    city: 'Oberweißau'
  },
  {
    postalCode: '5222',
    city: 'Haidberg'
  },
  {
    postalCode: '5222',
    city: 'Althöllersberg'
  },
  {
    postalCode: '5222',
    city: 'Spreitzenberg'
  },
  {
    postalCode: '5222',
    city: 'Ach'
  },
  {
    postalCode: '5222',
    city: 'Achenlohe'
  },
  {
    postalCode: '5222',
    city: 'Achtal'
  },
  {
    postalCode: '5222',
    city: 'Baumgarten'
  },
  {
    postalCode: '5222',
    city: 'Bradirn'
  },
  {
    postalCode: '5222',
    city: 'Buch'
  },
  {
    postalCode: '5222',
    city: 'Hirschlag'
  },
  {
    postalCode: '5222',
    city: 'Höllersberg'
  },
  {
    postalCode: '5222',
    city: 'Katztal'
  },
  {
    postalCode: '5222',
    city: 'Kolming'
  },
  {
    postalCode: '5222',
    city: 'Lichteneck'
  },
  {
    postalCode: '5222',
    city: 'Munderfing'
  },
  {
    postalCode: '5222',
    city: 'Parz'
  },
  {
    postalCode: '5222',
    city: 'Pfaffing'
  },
  {
    postalCode: '5222',
    city: 'Rödt'
  },
  {
    postalCode: '5222',
    city: 'Stocker'
  },
  {
    postalCode: '5222',
    city: 'Unterweißau'
  },
  {
    postalCode: '5222',
    city: 'Valentinhaft'
  },
  {
    postalCode: '5222',
    city: 'Schalchen'
  },
  {
    postalCode: '5222',
    city: 'Unterweinberg'
  },
  {
    postalCode: '5223',
    city: 'Erlach'
  },
  {
    postalCode: '5223',
    city: 'Fludau'
  },
  {
    postalCode: '5223',
    city: 'Kitzing'
  },
  {
    postalCode: '5223',
    city: 'Kuglberg'
  },
  {
    postalCode: '5223',
    city: 'Pfaffstätt'
  },
  {
    postalCode: '5223',
    city: 'Sollern'
  },
  {
    postalCode: '5224',
    city: 'Auerbach'
  },
  {
    postalCode: '5224',
    city: 'Höring'
  },
  {
    postalCode: '5224',
    city: 'Holz'
  },
  {
    postalCode: '5224',
    city: 'Oberirnprechting'
  },
  {
    postalCode: '5224',
    city: 'Oberkling'
  },
  {
    postalCode: '5224',
    city: 'Riensberg'
  },
  {
    postalCode: '5224',
    city: 'Rietzing'
  },
  {
    postalCode: '5224',
    city: 'Unterirnprechting'
  },
  {
    postalCode: '5224',
    city: 'Unterkling'
  },
  {
    postalCode: '5224',
    city: 'Wimpassing'
  },
  {
    postalCode: '5225',
    city: 'Abern'
  },
  {
    postalCode: '5225',
    city: 'Bernroid'
  },
  {
    postalCode: '5225',
    city: 'Hochhalting'
  },
  {
    postalCode: '5225',
    city: 'Jeging'
  },
  {
    postalCode: '5225',
    city: 'Oberedt'
  },
  {
    postalCode: '5225',
    city: 'Pfaffing'
  },
  {
    postalCode: '5225',
    city: 'Schweiber'
  },
  {
    postalCode: '5225',
    city: 'Unteredt'
  },
  {
    postalCode: '5230',
    city: 'Auerbach'
  },
  {
    postalCode: '5230',
    city: 'Mattighofen'
  },
  {
    postalCode: '5230',
    city: 'Wiesenham'
  },
  {
    postalCode: '5231',
    city: 'Äpfelberg'
  },
  {
    postalCode: '5231',
    city: 'Au'
  },
  {
    postalCode: '5231',
    city: 'Auffang'
  },
  {
    postalCode: '5231',
    city: 'Baumgarten'
  },
  {
    postalCode: '5231',
    city: 'Erb'
  },
  {
    postalCode: '5231',
    city: 'Furth'
  },
  {
    postalCode: '5231',
    city: 'Häuslberg'
  },
  {
    postalCode: '5231',
    city: 'Hitzleiten'
  },
  {
    postalCode: '5231',
    city: 'Langwiedmoos'
  },
  {
    postalCode: '5231',
    city: 'Mitterholzleiten'
  },
  {
    postalCode: '5231',
    city: 'Neudorf'
  },
  {
    postalCode: '5231',
    city: 'Oberharlochen'
  },
  {
    postalCode: '5231',
    city: 'Oberholzleiten'
  },
  {
    postalCode: '5231',
    city: 'Oberlindach'
  },
  {
    postalCode: '5231',
    city: 'Oberweinberg'
  },
  {
    postalCode: '5231',
    city: 'Schalchen'
  },
  {
    postalCode: '5231',
    city: 'Stallhofen'
  },
  {
    postalCode: '5231',
    city: 'Unterharlochen'
  },
  {
    postalCode: '5231',
    city: 'Unterholzleiten'
  },
  {
    postalCode: '5231',
    city: 'Unterlindach'
  },
  {
    postalCode: '5231',
    city: 'Unterlochen'
  },
  {
    postalCode: '5231',
    city: 'Unterweinberg'
  },
  {
    postalCode: '5231',
    city: 'Wiesing'
  },
  {
    postalCode: '5231',
    city: 'Weinberg'
  },
  {
    postalCode: '5232',
    city: 'Aigen'
  },
  {
    postalCode: '5232',
    city: 'Alterding'
  },
  {
    postalCode: '5232',
    city: 'Angelberg'
  },
  {
    postalCode: '5232',
    city: 'Bermading'
  },
  {
    postalCode: '5232',
    city: 'Buch'
  },
  {
    postalCode: '5232',
    city: 'Eglsee'
  },
  {
    postalCode: '5232',
    city: 'Entham'
  },
  {
    postalCode: '5232',
    city: 'Ersperding'
  },
  {
    postalCode: '5232',
    city: 'Gopperding'
  },
  {
    postalCode: '5232',
    city: 'Gumping'
  },
  {
    postalCode: '5232',
    city: 'Hilprechtsham'
  },
  {
    postalCode: '5232',
    city: 'Iming'
  },
  {
    postalCode: '5232',
    city: 'Kirchberg bei Mattighofen'
  },
  {
    postalCode: '5232',
    city: 'Kobl'
  },
  {
    postalCode: '5232',
    city: 'Lamperding'
  },
  {
    postalCode: '5232',
    city: 'Moosdorf'
  },
  {
    postalCode: '5232',
    city: 'Oberkreit'
  },
  {
    postalCode: '5232',
    city: 'Obermaisling'
  },
  {
    postalCode: '5232',
    city: 'Sauldorf'
  },
  {
    postalCode: '5232',
    city: 'Setzka'
  },
  {
    postalCode: '5232',
    city: 'Siegertshaft'
  },
  {
    postalCode: '5232',
    city: 'Thal'
  },
  {
    postalCode: '5232',
    city: 'Unterkreit'
  },
  {
    postalCode: '5232',
    city: 'Untermaisling'
  },
  {
    postalCode: '5232',
    city: 'Walterding'
  },
  {
    postalCode: '5232',
    city: 'Wendling'
  },
  {
    postalCode: '5233',
    city: 'Aich'
  },
  {
    postalCode: '5233',
    city: 'Berg'
  },
  {
    postalCode: '5233',
    city: 'Buch'
  },
  {
    postalCode: '5233',
    city: 'Deimledt'
  },
  {
    postalCode: '5233',
    city: 'Dessenhausen'
  },
  {
    postalCode: '5233',
    city: 'Edt'
  },
  {
    postalCode: '5233',
    city: 'Engelschärding'
  },
  {
    postalCode: '5233',
    city: 'Erlach'
  },
  {
    postalCode: '5233',
    city: 'Feldmühl'
  },
  {
    postalCode: '5233',
    city: 'Glatzberg'
  },
  {
    postalCode: '5233',
    city: 'Großgollern'
  },
  {
    postalCode: '5233',
    city: 'Gschwendt'
  },
  {
    postalCode: '5233',
    city: 'Hart'
  },
  {
    postalCode: '5233',
    city: 'Humertsham'
  },
  {
    postalCode: '5233',
    city: 'Irnstötten'
  },
  {
    postalCode: '5233',
    city: 'Kager'
  },
  {
    postalCode: '5233',
    city: 'Kaltenhausen'
  },
  {
    postalCode: '5233',
    city: 'Kleingollern'
  },
  {
    postalCode: '5233',
    city: 'Landerting'
  },
  {
    postalCode: '5233',
    city: 'Moos'
  },
  {
    postalCode: '5233',
    city: 'Ottendorf'
  },
  {
    postalCode: '5233',
    city: 'Perleiten'
  },
  {
    postalCode: '5233',
    city: 'Pfaffing'
  },
  {
    postalCode: '5233',
    city: 'Pischelsdorf am Engelbach'
  },
  {
    postalCode: '5233',
    city: 'Posching'
  },
  {
    postalCode: '5233',
    city: 'Schmidham'
  },
  {
    postalCode: '5233',
    city: 'Schwarzgröben'
  },
  {
    postalCode: '5233',
    city: 'Siegerting'
  },
  {
    postalCode: '5233',
    city: 'Stapfing'
  },
  {
    postalCode: '5233',
    city: 'Stempfen'
  },
  {
    postalCode: '5233',
    city: 'Unterhart'
  },
  {
    postalCode: '5233',
    city: 'Wagenham'
  },
  {
    postalCode: '5233',
    city: 'Wehrsdorf'
  },
  {
    postalCode: '5241',
    city: 'Sonnleiten'
  },
  {
    postalCode: '5241',
    city: 'Thannstraß'
  },
  {
    postalCode: '5241',
    city: 'Aicheck'
  },
  {
    postalCode: '5241',
    city: 'Breitenberg'
  },
  {
    postalCode: '5241',
    city: 'Bucheck'
  },
  {
    postalCode: '5241',
    city: 'Großenaich'
  },
  {
    postalCode: '5241',
    city: 'Gstocket'
  },
  {
    postalCode: '5241',
    city: 'Haslau'
  },
  {
    postalCode: '5241',
    city: 'Leitnerseck'
  },
  {
    postalCode: '5241',
    city: 'Maria Schmolln'
  },
  {
    postalCode: '5241',
    city: 'Michlbach'
  },
  {
    postalCode: '5241',
    city: 'Oberfeld'
  },
  {
    postalCode: '5241',
    city: 'Oberminathal'
  },
  {
    postalCode: '5241',
    city: 'Perneck'
  },
  {
    postalCode: '5241',
    city: 'Schnellberg'
  },
  {
    postalCode: '5241',
    city: 'Schweigertsreith'
  },
  {
    postalCode: '5241',
    city: 'Sollach'
  },
  {
    postalCode: '5241',
    city: 'Unterfeld'
  },
  {
    postalCode: '5241',
    city: 'Unterminathal'
  },
  {
    postalCode: '5241',
    city: 'Utzeneck'
  },
  {
    postalCode: '5241',
    city: 'Winkelpoint'
  },
  {
    postalCode: '5241',
    city: 'Warleiten'
  },
  {
    postalCode: '5242',
    city: 'Bachleiten'
  },
  {
    postalCode: '5242',
    city: 'Höh'
  },
  {
    postalCode: '5242',
    city: 'Perneck'
  },
  {
    postalCode: '5242',
    city: 'Dobl'
  },
  {
    postalCode: '5242',
    city: 'Frauschereck'
  },
  {
    postalCode: '5242',
    city: 'Geierseck'
  },
  {
    postalCode: '5242',
    city: 'Grubmühl'
  },
  {
    postalCode: '5242',
    city: 'Klafterreith'
  },
  {
    postalCode: '5242',
    city: 'Obereck'
  },
  {
    postalCode: '5242',
    city: 'Peretseck'
  },
  {
    postalCode: '5242',
    city: 'Raucheneck'
  },
  {
    postalCode: '5242',
    city: 'Sankt Johann am Walde'
  },
  {
    postalCode: '5242',
    city: 'Schauberg'
  },
  {
    postalCode: '5242',
    city: 'Scherfeck'
  },
  {
    postalCode: '5242',
    city: 'Schlagereck'
  },
  {
    postalCode: '5242',
    city: 'Schnaidt'
  },
  {
    postalCode: '5242',
    city: 'Schneibenschlag'
  },
  {
    postalCode: '5242',
    city: 'Schöfeck'
  },
  {
    postalCode: '5242',
    city: 'Stixeck'
  },
  {
    postalCode: '5242',
    city: 'Straß'
  },
  {
    postalCode: '5251',
    city: 'Eigelsberg'
  },
  {
    postalCode: '5251',
    city: 'Leithen am Walde'
  },
  {
    postalCode: '5251',
    city: 'Offenschwandt'
  },
  {
    postalCode: '5251',
    city: 'Wasserdobl'
  },
  {
    postalCode: '5251',
    city: 'Aichbichl'
  },
  {
    postalCode: '5251',
    city: 'Aigertsham'
  },
  {
    postalCode: '5251',
    city: 'Ainetsreit'
  },
  {
    postalCode: '5251',
    city: 'Außerleiten'
  },
  {
    postalCode: '5251',
    city: 'Buchberg'
  },
  {
    postalCode: '5251',
    city: 'Diepoltsham'
  },
  {
    postalCode: '5251',
    city: 'Eden'
  },
  {
    postalCode: '5251',
    city: 'Eitzing'
  },
  {
    postalCode: '5251',
    city: 'Feichta'
  },
  {
    postalCode: '5251',
    city: 'Haging'
  },
  {
    postalCode: '5251',
    city: 'Herbstheim'
  },
  {
    postalCode: '5251',
    city: 'Höhnhart'
  },
  {
    postalCode: '5251',
    city: 'Hub'
  },
  {
    postalCode: '5251',
    city: 'Leitrachstetten'
  },
  {
    postalCode: '5251',
    city: 'Liedlschwandt'
  },
  {
    postalCode: '5251',
    city: 'Miesenberg'
  },
  {
    postalCode: '5251',
    city: 'Oberaichberg'
  },
  {
    postalCode: '5251',
    city: 'Peretsdobl'
  },
  {
    postalCode: '5251',
    city: 'Perwart'
  },
  {
    postalCode: '5251',
    city: 'Stegmühl'
  },
  {
    postalCode: '5251',
    city: 'Thalheim'
  },
  {
    postalCode: '5251',
    city: 'Thannstraß'
  },
  {
    postalCode: '5251',
    city: 'Unteraichberg'
  },
  {
    postalCode: '5251',
    city: 'Dobl'
  },
  {
    postalCode: '5251',
    city: 'Roith'
  },
  {
    postalCode: '5251',
    city: 'Schwandt'
  },
  {
    postalCode: '5251',
    city: 'Straß'
  },
  {
    postalCode: '5251',
    city: 'Warleiten'
  },
  {
    postalCode: '5251',
    city: 'Windschnur'
  },
  {
    postalCode: '5251',
    city: 'Winkl'
  },
  {
    postalCode: '5252',
    city: 'Aspach'
  },
  {
    postalCode: '5252',
    city: 'Baumgarten'
  },
  {
    postalCode: '5252',
    city: 'Dötting'
  },
  {
    postalCode: '5252',
    city: 'Ecking'
  },
  {
    postalCode: '5252',
    city: 'Englham'
  },
  {
    postalCode: '5252',
    city: 'Kappeln'
  },
  {
    postalCode: '5252',
    city: 'Kasting'
  },
  {
    postalCode: '5252',
    city: 'Katzlberg'
  },
  {
    postalCode: '5252',
    city: 'Kleinschneidt'
  },
  {
    postalCode: '5252',
    city: 'Migelsbach'
  },
  {
    postalCode: '5252',
    city: 'Mitterberg'
  },
  {
    postalCode: '5252',
    city: 'Niederham'
  },
  {
    postalCode: '5252',
    city: 'Parz'
  },
  {
    postalCode: '5252',
    city: 'Pimberg'
  },
  {
    postalCode: '5252',
    city: 'Rottersham'
  },
  {
    postalCode: '5252',
    city: 'Thal'
  },
  {
    postalCode: '5252',
    city: 'Wasserdobl'
  },
  {
    postalCode: '5261',
    city: 'Albrechtsberg'
  },
  {
    postalCode: '5261',
    city: 'Grillham'
  },
  {
    postalCode: '5261',
    city: 'Anzenberg'
  },
  {
    postalCode: '5261',
    city: 'Freihub'
  },
  {
    postalCode: '5261',
    city: 'Gaismannslohen'
  },
  {
    postalCode: '5261',
    city: 'Heitzing'
  },
  {
    postalCode: '5261',
    city: 'Helpfau'
  },
  {
    postalCode: '5261',
    city: 'Höfen'
  },
  {
    postalCode: '5261',
    city: 'Kager'
  },
  {
    postalCode: '5261',
    city: 'Lohnau'
  },
  {
    postalCode: '5261',
    city: 'Ort'
  },
  {
    postalCode: '5261',
    city: 'Reichsdorf'
  },
  {
    postalCode: '5261',
    city: 'Reith'
  },
  {
    postalCode: '5261',
    city: 'Sankt Florian'
  },
  {
    postalCode: '5261',
    city: 'Scheiblberg'
  },
  {
    postalCode: '5261',
    city: 'Sonnleiten'
  },
  {
    postalCode: '5261',
    city: 'Steinbruch'
  },
  {
    postalCode: '5261',
    city: 'Steinrödt'
  },
  {
    postalCode: '5261',
    city: 'Straß'
  },
  {
    postalCode: '5261',
    city: 'Uttendorf'
  },
  {
    postalCode: '5261',
    city: 'Wienern'
  },
  {
    postalCode: '5261',
    city: 'Haslau'
  },
  {
    postalCode: '5261',
    city: 'Zeiledt'
  },
  {
    postalCode: '5270',
    city: 'Albrechtsberg'
  },
  {
    postalCode: '5270',
    city: 'Biburg'
  },
  {
    postalCode: '5270',
    city: 'Brand'
  },
  {
    postalCode: '5270',
    city: 'Eglsee'
  },
  {
    postalCode: '5270',
    city: 'Fartham'
  },
  {
    postalCode: '5270',
    city: 'Harham'
  },
  {
    postalCode: '5270',
    city: 'Kobledt'
  },
  {
    postalCode: '5270',
    city: 'Penning'
  },
  {
    postalCode: '5270',
    city: 'Stockleiten'
  },
  {
    postalCode: '5270',
    city: 'Tal'
  },
  {
    postalCode: '5270',
    city: 'Vorbuch'
  },
  {
    postalCode: '5270',
    city: 'Wollöster'
  },
  {
    postalCode: '5270',
    city: 'Alm'
  },
  {
    postalCode: '5270',
    city: 'Brunning'
  },
  {
    postalCode: '5270',
    city: 'Mauerkirchen'
  },
  {
    postalCode: '5270',
    city: 'Spitzenberg'
  },
  {
    postalCode: '5270',
    city: 'Unterbrunning'
  },
  {
    postalCode: '5270',
    city: 'Spraidt'
  },
  {
    postalCode: '5271',
    city: 'Harham'
  },
  {
    postalCode: '5271',
    city: 'Sonnleiten'
  },
  {
    postalCode: '5271',
    city: 'Bäckenberg'
  },
  {
    postalCode: '5271',
    city: 'Dietraching'
  },
  {
    postalCode: '5271',
    city: 'Grubedt'
  },
  {
    postalCode: '5271',
    city: 'Hufnagl'
  },
  {
    postalCode: '5271',
    city: 'Moosbach'
  },
  {
    postalCode: '5271',
    city: 'Reisach'
  },
  {
    postalCode: '5271',
    city: 'Reisedt'
  },
  {
    postalCode: '5271',
    city: 'Schacha'
  },
  {
    postalCode: '5271',
    city: 'Spraidt'
  },
  {
    postalCode: '5271',
    city: 'Waasen'
  },
  {
    postalCode: '5271',
    city: 'Winden'
  },
  {
    postalCode: '5271',
    city: 'Lindlau'
  },
  {
    postalCode: '5271',
    city: 'Radlham'
  },
  {
    postalCode: '5271',
    city: 'Wimholz'
  },
  {
    postalCode: '5272',
    city: 'Leitrachstetten'
  },
  {
    postalCode: '5272',
    city: 'Schacha'
  },
  {
    postalCode: '5272',
    city: 'Schiefeck'
  },
  {
    postalCode: '5272',
    city: 'Ursprung'
  },
  {
    postalCode: '5272',
    city: 'Ascherdorf'
  },
  {
    postalCode: '5272',
    city: 'Himmelschlag'
  },
  {
    postalCode: '5272',
    city: 'Hub'
  },
  {
    postalCode: '5272',
    city: 'Matt'
  },
  {
    postalCode: '5272',
    city: 'Mitterdorf'
  },
  {
    postalCode: '5272',
    city: 'Obertreubach'
  },
  {
    postalCode: '5272',
    city: 'Pfendhub'
  },
  {
    postalCode: '5272',
    city: 'Radlham'
  },
  {
    postalCode: '5272',
    city: 'Schalchen'
  },
  {
    postalCode: '5272',
    city: 'Teiseneck'
  },
  {
    postalCode: '5272',
    city: 'Untertreubach'
  },
  {
    postalCode: '5272',
    city: 'Weidenpoint'
  },
  {
    postalCode: '5272',
    city: 'Wittigau'
  },
  {
    postalCode: '5273',
    city: 'Bruckwies'
  },
  {
    postalCode: '5273',
    city: 'Buch'
  },
  {
    postalCode: '5273',
    city: 'Edt'
  },
  {
    postalCode: '5273',
    city: 'Fraham'
  },
  {
    postalCode: '5273',
    city: 'Frieseneck'
  },
  {
    postalCode: '5273',
    city: 'Grünau'
  },
  {
    postalCode: '5273',
    city: 'Gschaidt'
  },
  {
    postalCode: '5273',
    city: 'Hinteredt'
  },
  {
    postalCode: '5273',
    city: 'Hofing'
  },
  {
    postalCode: '5273',
    city: 'Hub'
  },
  {
    postalCode: '5273',
    city: 'Jaiding'
  },
  {
    postalCode: '5273',
    city: 'Krottenthal'
  },
  {
    postalCode: '5273',
    city: 'Parschalling'
  },
  {
    postalCode: '5273',
    city: 'Rödham'
  },
  {
    postalCode: '5273',
    city: 'Roßbach'
  },
  {
    postalCode: '5273',
    city: 'Schwathof'
  },
  {
    postalCode: '5273',
    city: 'Thal'
  },
  {
    postalCode: '5273',
    city: 'Ursprung'
  },
  {
    postalCode: '5273',
    city: 'Wesen'
  },
  {
    postalCode: '5273',
    city: 'Wolfeck'
  },
  {
    postalCode: '5273',
    city: 'Zechleiten'
  },
  {
    postalCode: '5273',
    city: 'Marlupp'
  },
  {
    postalCode: '5273',
    city: 'Pirat'
  },
  {
    postalCode: '5273',
    city: 'Pudexing'
  },
  {
    postalCode: '5273',
    city: 'Sankt Veit im Innkreis'
  },
  {
    postalCode: '5273',
    city: 'Schacher'
  },
  {
    postalCode: '5273',
    city: 'Wimhub'
  },
  {
    postalCode: '5274',
    city: 'Alharting'
  },
  {
    postalCode: '5274',
    city: 'Atzing'
  },
  {
    postalCode: '5274',
    city: 'Au'
  },
  {
    postalCode: '5274',
    city: 'Brunning'
  },
  {
    postalCode: '5274',
    city: 'Burgkirchen'
  },
  {
    postalCode: '5274',
    city: 'Edthof'
  },
  {
    postalCode: '5274',
    city: 'Forstern'
  },
  {
    postalCode: '5274',
    city: 'Fuchshofen'
  },
  {
    postalCode: '5274',
    city: 'Fürch'
  },
  {
    postalCode: '5274',
    city: 'Geretsdorf'
  },
  {
    postalCode: '5274',
    city: 'Hermading'
  },
  {
    postalCode: '5274',
    city: 'Herrngassen'
  },
  {
    postalCode: '5274',
    city: 'Holzgassen'
  },
  {
    postalCode: '5274',
    city: 'Kaltenhausen'
  },
  {
    postalCode: '5274',
    city: 'Kühberg'
  },
  {
    postalCode: '5274',
    city: 'Lindhof'
  },
  {
    postalCode: '5274',
    city: 'Maxedt'
  },
  {
    postalCode: '5274',
    city: 'Mitterlach'
  },
  {
    postalCode: '5274',
    city: 'Oberhartberg'
  },
  {
    postalCode: '5274',
    city: 'Oberseibersdorf'
  },
  {
    postalCode: '5274',
    city: 'Passberg'
  },
  {
    postalCode: '5274',
    city: 'Sankt Georgen an der Mattig'
  },
  {
    postalCode: '5274',
    city: 'Unterhartberg'
  },
  {
    postalCode: '5274',
    city: 'Unterseibersdorf'
  },
  {
    postalCode: '5274',
    city: 'Vorbuch'
  },
  {
    postalCode: '5274',
    city: 'Weikerding'
  },
  {
    postalCode: '5274',
    city: 'Meinharting'
  },
  {
    postalCode: '5280',
    city: 'Aching'
  },
  {
    postalCode: '5280',
    city: 'Braunau am Inn'
  },
  {
    postalCode: '5280',
    city: 'Braunau Neustadt'
  },
  {
    postalCode: '5280',
    city: 'Gasteig'
  },
  {
    postalCode: '5280',
    city: 'Haiden'
  },
  {
    postalCode: '5280',
    city: 'Haselbach'
  },
  {
    postalCode: '5280',
    city: 'Himmellindach'
  },
  {
    postalCode: '5280',
    city: 'Höft'
  },
  {
    postalCode: '5280',
    city: 'Laab'
  },
  {
    postalCode: '5280',
    city: 'Lach'
  },
  {
    postalCode: '5280',
    city: 'Lindach'
  },
  {
    postalCode: '5280',
    city: 'Maierhof'
  },
  {
    postalCode: '5280',
    city: 'Neue Heimat'
  },
  {
    postalCode: '5280',
    city: 'Osternberg'
  },
  {
    postalCode: '5280',
    city: 'Ranshofen'
  },
  {
    postalCode: '5280',
    city: 'Tal'
  },
  {
    postalCode: '5280',
    city: 'Oberaching'
  },
  {
    postalCode: '5280',
    city: 'Dietfurt'
  },
  {
    postalCode: '5282',
    city: 'Au'
  },
  {
    postalCode: '5282',
    city: 'Blankenbach'
  },
  {
    postalCode: '5282',
    city: 'Oberrothenbuch'
  },
  {
    postalCode: '5282',
    city: 'Osternberg'
  },
  {
    postalCode: '5282',
    city: 'Ranshofen'
  },
  {
    postalCode: '5282',
    city: 'Roith'
  },
  {
    postalCode: '5282',
    city: 'Scheuhub'
  },
  {
    postalCode: '5282',
    city: 'Unterrothenbuch'
  },
  {
    postalCode: '5282',
    city: 'Kühberg'
  },
  {
    postalCode: '5282',
    city: 'Brunn im Gries'
  },
  {
    postalCode: '5282',
    city: 'Lach'
  },
  {
    postalCode: '5300',
    city: 'Berg'
  },
  {
    postalCode: '5300',
    city: 'Esch'
  },
  {
    postalCode: '5300',
    city: 'Hallwang'
  },
  {
    postalCode: '5300',
    city: 'Zilling'
  },
  {
    postalCode: '5301',
    city: 'Eugenbach'
  },
  {
    postalCode: '5301',
    city: 'Eugendorf'
  },
  {
    postalCode: '5301',
    city: 'Kalham'
  },
  {
    postalCode: '5301',
    city: 'Kirchberg'
  },
  {
    postalCode: '5301',
    city: 'Knutzing'
  },
  {
    postalCode: '5301',
    city: 'Neuhofen'
  },
  {
    postalCode: '5301',
    city: 'Pebering'
  },
  {
    postalCode: '5301',
    city: 'Reitberg'
  },
  {
    postalCode: '5301',
    city: 'Schaming'
  },
  {
    postalCode: '5301',
    city: 'Schwaighofen'
  },
  {
    postalCode: '5302',
    city: 'Kirchberg'
  },
  {
    postalCode: '5302',
    city: 'Berg'
  },
  {
    postalCode: '5302',
    city: 'Enzing'
  },
  {
    postalCode: '5302',
    city: 'Fenning'
  },
  {
    postalCode: '5302',
    city: 'Hankham'
  },
  {
    postalCode: '5302',
    city: 'Hatting'
  },
  {
    postalCode: '5302',
    city: 'Henndorf am Wallersee'
  },
  {
    postalCode: '5302',
    city: 'Hof'
  },
  {
    postalCode: '5302',
    city: 'Oelling'
  },
  {
    postalCode: '5302',
    city: 'Wankham'
  },
  {
    postalCode: '5303',
    city: 'Egg'
  },
  {
    postalCode: '5303',
    city: 'Enzersberg'
  },
  {
    postalCode: '5303',
    city: 'Leithen'
  },
  {
    postalCode: '5303',
    city: 'Oberdorf'
  },
  {
    postalCode: '5303',
    city: 'Thalgau'
  },
  {
    postalCode: '5303',
    city: 'Thalgauberg'
  },
  {
    postalCode: '5303',
    city: 'Unterdorf'
  },
  {
    postalCode: '5303',
    city: 'Vetterbach'
  },
  {
    postalCode: '5310',
    city: 'Innerschwand'
  },
  {
    postalCode: '5310',
    city: 'Mondsee'
  },
  {
    postalCode: '5310',
    city: 'Oberburgau'
  },
  {
    postalCode: '5310',
    city: 'Keuschen'
  },
  {
    postalCode: '5310',
    city: 'Sankt Lorenz'
  },
  {
    postalCode: '5310',
    city: 'Scharfling'
  },
  {
    postalCode: '5310',
    city: 'Gaisberg'
  },
  {
    postalCode: '5310',
    city: 'Guggenberg'
  },
  {
    postalCode: '5310',
    city: 'Hof'
  },
  {
    postalCode: '5310',
    city: 'Tiefgraben'
  },
  {
    postalCode: '5311',
    city: 'Au'
  },
  {
    postalCode: '5311',
    city: 'Innerschwand'
  },
  {
    postalCode: '5311',
    city: 'Mondsee'
  },
  {
    postalCode: '5321',
    city: 'Habach'
  },
  {
    postalCode: '5321',
    city: 'Koppl'
  },
  {
    postalCode: '5321',
    city: 'Ladau'
  },
  {
    postalCode: '5321',
    city: 'Winkl'
  },
  {
    postalCode: '5322',
    city: 'Elsenwang'
  },
  {
    postalCode: '5322',
    city: 'Gitzen'
  },
  {
    postalCode: '5322',
    city: 'Hinterschroffenau'
  },
  {
    postalCode: '5322',
    city: 'Hof bei Salzburg'
  },
  {
    postalCode: '5322',
    city: 'Vorderelsenwang'
  },
  {
    postalCode: '5323',
    city: 'Wimberg'
  },
  {
    postalCode: '5323',
    city: 'Ebenau'
  },
  {
    postalCode: '5323',
    city: 'Hinterebenau'
  },
  {
    postalCode: '5323',
    city: 'Hinterwinkl'
  },
  {
    postalCode: '5323',
    city: 'Unterberg'
  },
  {
    postalCode: '5323',
    city: 'Vorderschroffenau'
  },
  {
    postalCode: '5324',
    city: 'Alm'
  },
  {
    postalCode: '5324',
    city: 'Anger'
  },
  {
    postalCode: '5324',
    city: 'Faistenau'
  },
  {
    postalCode: '5324',
    city: 'Lidaun'
  },
  {
    postalCode: '5324',
    city: 'Ramsau'
  },
  {
    postalCode: '5324',
    city: 'Tiefbrunnau'
  },
  {
    postalCode: '5324',
    city: 'Vordersee'
  },
  {
    postalCode: '5324',
    city: 'Wald'
  },
  {
    postalCode: '5324',
    city: 'Hintersee'
  },
  {
    postalCode: '5324',
    city: 'Lämmerbach'
  },
  {
    postalCode: '5325',
    city: 'Plainfeld'
  },
  {
    postalCode: '5330',
    city: 'Fuschl am See'
  },
  {
    postalCode: '5340',
    city: 'Laim'
  },
  {
    postalCode: '5340',
    city: 'Pöllach'
  },
  {
    postalCode: '5340',
    city: 'Sankt Gilgen'
  },
  {
    postalCode: '5340',
    city: 'Winkl'
  },
  {
    postalCode: '5342',
    city: 'Gschwand'
  },
  {
    postalCode: '5342',
    city: 'Gschwendt'
  },
  {
    postalCode: '5350',
    city: 'Gschwand'
  },
  {
    postalCode: '5350',
    city: 'Aigen'
  },
  {
    postalCode: '5350',
    city: 'Gschwendt'
  },
  {
    postalCode: '5350',
    city: 'Strobl'
  },
  {
    postalCode: '5350',
    city: 'Weißenbach'
  },
  {
    postalCode: '5351',
    city: 'Haiden'
  },
  {
    postalCode: '5351',
    city: 'Lindau'
  },
  {
    postalCode: '5351',
    city: 'Ramsau'
  },
  {
    postalCode: '5351',
    city: 'Radau'
  },
  {
    postalCode: '5351',
    city: 'Rußbach'
  },
  {
    postalCode: '5351',
    city: 'Weinbach'
  },
  {
    postalCode: '5351',
    city: 'Windhag'
  },
  {
    postalCode: '5351',
    city: 'Wirling'
  },
  {
    postalCode: '5351',
    city: 'Aigen'
  },
  {
    postalCode: '5351',
    city: 'Weißenbach'
  },
  {
    postalCode: '5360',
    city: 'Ried'
  },
  {
    postalCode: '5360',
    city: 'Aschau'
  },
  {
    postalCode: '5360',
    city: 'Au'
  },
  {
    postalCode: '5360',
    city: 'Graben'
  },
  {
    postalCode: '5360',
    city: 'Mönichsreith'
  },
  {
    postalCode: '5360',
    city: 'St. Wolfgang im Salzkammergut'
  },
  {
    postalCode: '5360',
    city: 'Schwarzenbach'
  },
  {
    postalCode: '5400',
    city: 'Riedl'
  },
  {
    postalCode: '5400',
    city: 'Au'
  },
  {
    postalCode: '5400',
    city: 'Burgfried'
  },
  {
    postalCode: '5400',
    city: 'Bad Dürrnberg'
  },
  {
    postalCode: '5400',
    city: 'Gamp'
  },
  {
    postalCode: '5400',
    city: 'Gries'
  },
  {
    postalCode: '5400',
    city: 'Hallein'
  },
  {
    postalCode: '5400',
    city: 'Neualm'
  },
  {
    postalCode: '5400',
    city: 'Adneter Riedl'
  },
  {
    postalCode: '5400',
    city: 'Taxach'
  },
  {
    postalCode: '5400',
    city: 'Oberalm'
  },
  {
    postalCode: '5411',
    city: 'Oberalm'
  },
  {
    postalCode: '5411',
    city: 'Vorderwiestal'
  },
  {
    postalCode: '5411',
    city: 'Hinterwiestal'
  },
  {
    postalCode: '5412',
    city: 'Haslach'
  },
  {
    postalCode: '5412',
    city: 'Gries'
  },
  {
    postalCode: '5412',
    city: 'Hinterwiestal'
  },
  {
    postalCode: '5412',
    city: 'Puch bei Hallein'
  },
  {
    postalCode: '5412',
    city: 'Sankt Jakob am Thurn'
  },
  {
    postalCode: '5421',
    city: 'Adnet'
  },
  {
    postalCode: '5421',
    city: 'Spumberg'
  },
  {
    postalCode: '5421',
    city: 'Waidach'
  },
  {
    postalCode: '5421',
    city: 'Wimberg'
  },
  {
    postalCode: '5421',
    city: 'Gaißau'
  },
  {
    postalCode: '5421',
    city: 'Krispl'
  },
  {
    postalCode: '5422',
    city: 'Bad Dürrnberg'
  },
  {
    postalCode: '5423',
    city: 'Oberlangenberg'
  },
  {
    postalCode: '5423',
    city: 'Taugl'
  },
  {
    postalCode: '5423',
    city: 'Tauglboden'
  },
  {
    postalCode: '5424',
    city: 'Rengerberg'
  },
  {
    postalCode: '5424',
    city: 'Riedl'
  },
  {
    postalCode: '5424',
    city: 'Sankt Margarethen'
  },
  {
    postalCode: '5424',
    city: 'Vigaun'
  },
  {
    postalCode: '5424',
    city: 'Unterlangenberg'
  },
  {
    postalCode: '5431',
    city: 'Garnei'
  },
  {
    postalCode: '5431',
    city: 'Gasteig'
  },
  {
    postalCode: '5431',
    city: 'Georgenberg'
  },
  {
    postalCode: '5431',
    city: 'Jadorf'
  },
  {
    postalCode: '5431',
    city: 'Kellau'
  },
  {
    postalCode: '5431',
    city: 'Kuchl'
  },
  {
    postalCode: '5431',
    city: 'Moos'
  },
  {
    postalCode: '5431',
    city: 'Unterlangenberg'
  },
  {
    postalCode: '5431',
    city: 'Weißenbach'
  },
  {
    postalCode: '5440',
    city: 'Golling an der Salzach'
  },
  {
    postalCode: '5440',
    city: 'Obergäu'
  },
  {
    postalCode: '5440',
    city: 'Georgenberg'
  },
  {
    postalCode: '5440',
    city: 'Kellau'
  },
  {
    postalCode: '5440',
    city: 'Scheffau am Tennengebirge'
  },
  {
    postalCode: '5440',
    city: 'Voregg'
  },
  {
    postalCode: '5440',
    city: 'Torren'
  },
  {
    postalCode: '5441',
    city: 'Abtenau'
  },
  {
    postalCode: '5441',
    city: 'Au'
  },
  {
    postalCode: '5441',
    city: 'Döllerhof'
  },
  {
    postalCode: '5441',
    city: 'Erlfeld'
  },
  {
    postalCode: '5441',
    city: 'Fischbach'
  },
  {
    postalCode: '5441',
    city: 'Hallseiten'
  },
  {
    postalCode: '5441',
    city: 'Kehlhof'
  },
  {
    postalCode: '5441',
    city: 'Lindenthal'
  },
  {
    postalCode: '5441',
    city: 'Möselberg'
  },
  {
    postalCode: '5441',
    city: 'Pichl'
  },
  {
    postalCode: '5441',
    city: 'Rigaus'
  },
  {
    postalCode: '5441',
    city: 'Schorn'
  },
  {
    postalCode: '5441',
    city: 'Schratten'
  },
  {
    postalCode: '5441',
    city: 'Seetratten'
  },
  {
    postalCode: '5441',
    city: 'Seydegg'
  },
  {
    postalCode: '5441',
    city: 'Stocker'
  },
  {
    postalCode: '5441',
    city: 'Unterberg'
  },
  {
    postalCode: '5441',
    city: 'Wagner'
  },
  {
    postalCode: '5441',
    city: 'Waldhof'
  },
  {
    postalCode: '5441',
    city: 'Wegscheid'
  },
  {
    postalCode: '5441',
    city: 'Wallingwinkl'
  },
  {
    postalCode: '5441',
    city: 'Weitenau'
  },
  {
    postalCode: '5442',
    city: 'Gseng'
  },
  {
    postalCode: '5442',
    city: 'Seetratten'
  },
  {
    postalCode: '5442',
    city: 'Rußbachsaag'
  },
  {
    postalCode: '5442',
    city: 'Schattau'
  },
  {
    postalCode: '5450',
    city: 'Schlaming'
  },
  {
    postalCode: '5450',
    city: 'Imlau'
  },
  {
    postalCode: '5450',
    city: 'Scharten'
  },
  {
    postalCode: '5450',
    city: 'Werfen'
  },
  {
    postalCode: '5451',
    city: 'Scharten'
  },
  {
    postalCode: '5451',
    city: 'Sulzau'
  },
  {
    postalCode: '5451',
    city: 'Wimm'
  },
  {
    postalCode: '5452',
    city: 'Dorf'
  },
  {
    postalCode: '5452',
    city: 'Dorfwerfen'
  },
  {
    postalCode: '5452',
    city: 'Ellmauthal'
  },
  {
    postalCode: '5452',
    city: 'Grub'
  },
  {
    postalCode: '5452',
    city: 'Laubichl'
  },
  {
    postalCode: '5452',
    city: 'Lehen'
  },
  {
    postalCode: '5452',
    city: 'Maier'
  },
  {
    postalCode: '5452',
    city: 'Pöham'
  },
  {
    postalCode: '5452',
    city: 'Schlaming'
  },
  {
    postalCode: '5452',
    city: 'Imlau'
  },
  {
    postalCode: '5452',
    city: 'Reitsam'
  },
  {
    postalCode: '5453',
    city: 'Eulersberg'
  },
  {
    postalCode: '5453',
    city: 'Lampersbach'
  },
  {
    postalCode: '5453',
    city: 'Weng'
  },
  {
    postalCode: '5500',
    city: 'Alpfahrt'
  },
  {
    postalCode: '5500',
    city: 'Bischofshofen'
  },
  {
    postalCode: '5500',
    city: 'Buchberg'
  },
  {
    postalCode: '5500',
    city: 'Gainfeld'
  },
  {
    postalCode: '5500',
    city: 'Haidberg'
  },
  {
    postalCode: '5500',
    city: 'Kreuzberg'
  },
  {
    postalCode: '5500',
    city: 'Laideregg'
  },
  {
    postalCode: '5500',
    city: 'Mitterberghütten'
  },
  {
    postalCode: '5500',
    city: 'Winkl'
  },
  {
    postalCode: '5500',
    city: 'Pöham'
  },
  {
    postalCode: '5505',
    city: 'Mühlbach am Hochkönig'
  },
  {
    postalCode: '5505',
    city: 'Schlöglberg'
  },
  {
    postalCode: '5511',
    city: 'Bairau'
  },
  {
    postalCode: '5511',
    city: 'Hüttau'
  },
  {
    postalCode: '5511',
    city: 'Iglsbach'
  },
  {
    postalCode: '5511',
    city: 'Sonnberg'
  },
  {
    postalCode: '5511',
    city: 'Sonnhalb'
  },
  {
    postalCode: '5521',
    city: 'Bairau'
  },
  {
    postalCode: '5521',
    city: 'Sonnberg'
  },
  {
    postalCode: '5522',
    city: 'Lammertal'
  },
  {
    postalCode: '5522',
    city: 'Sankt Martin am Tennengebirge'
  },
  {
    postalCode: '5523',
    city: 'Gappen'
  },
  {
    postalCode: '5523',
    city: 'Neubach'
  },
  {
    postalCode: '5523',
    city: 'Promberg'
  },
  {
    postalCode: '5523',
    city: 'Lammertal'
  },
  {
    postalCode: '5524',
    city: 'Leitenhaus'
  },
  {
    postalCode: '5524',
    city: 'Salfelden'
  },
  {
    postalCode: '5524',
    city: 'Annaberg im Lammertal'
  },
  {
    postalCode: '5524',
    city: 'Braunötzhof'
  },
  {
    postalCode: '5524',
    city: 'Gappen'
  },
  {
    postalCode: '5524',
    city: 'Hefenscher'
  },
  {
    postalCode: '5524',
    city: 'Klockau'
  },
  {
    postalCode: '5524',
    city: 'Neubach'
  },
  {
    postalCode: '5524',
    city: 'Promberg'
  },
  {
    postalCode: '5524',
    city: 'Steuer'
  },
  {
    postalCode: '5531',
    city: 'Eben im Pongau'
  },
  {
    postalCode: '5531',
    city: 'Gasthofberg'
  },
  {
    postalCode: '5531',
    city: 'Schattbach'
  },
  {
    postalCode: '5532',
    city: 'Filzmoos'
  },
  {
    postalCode: '5532',
    city: 'Neuberg'
  },
  {
    postalCode: '5541',
    city: 'Altenmarkt im Pongau'
  },
  {
    postalCode: '5541',
    city: 'Palfen'
  },
  {
    postalCode: '5541',
    city: 'Feuersang'
  },
  {
    postalCode: '5541',
    city: 'Reitdorf'
  },
  {
    postalCode: '5541',
    city: 'Sinnhub'
  },
  {
    postalCode: '5542',
    city: 'Feuersang'
  },
  {
    postalCode: '5542',
    city: 'Flachau'
  },
  {
    postalCode: '5542',
    city: 'Höch'
  },
  {
    postalCode: '5542',
    city: 'Reitdorf'
  },
  {
    postalCode: '5550',
    city: 'Höggen'
  },
  {
    postalCode: '5550',
    city: 'Löbenau'
  },
  {
    postalCode: '5550',
    city: 'Mandling'
  },
  {
    postalCode: '5550',
    city: 'Radstadt'
  },
  {
    postalCode: '5550',
    city: 'Schwemmberg'
  },
  {
    postalCode: '5552',
    city: 'Forstau'
  },
  {
    postalCode: '5561',
    city: 'Untertauern'
  },
  {
    postalCode: '5562',
    city: 'Untertauern'
  },
  {
    postalCode: '5562',
    city: 'Tweng'
  },
  {
    postalCode: '5563',
    city: 'Tweng'
  },
  {
    postalCode: '5570',
    city: 'Faningberg'
  },
  {
    postalCode: '5570',
    city: 'Mauterndorf'
  },
  {
    postalCode: '5570',
    city: 'Neuseß'
  },
  {
    postalCode: '5570',
    city: 'Steindorf'
  },
  {
    postalCode: '5571',
    city: 'Mariapfarr'
  },
  {
    postalCode: '5571',
    city: 'Pichl'
  },
  {
    postalCode: '5571',
    city: 'Zankwarn'
  },
  {
    postalCode: '5572',
    city: 'Sankt Andrä im Lungau'
  },
  {
    postalCode: '5573',
    city: 'Weißpriach'
  },
  {
    postalCode: '5574',
    city: 'Göriach'
  },
  {
    postalCode: '5580',
    city: 'Lessach'
  },
  {
    postalCode: '5580',
    city: 'Zoitzach'
  },
  {
    postalCode: '5580',
    city: 'Haiden'
  },
  {
    postalCode: '5580',
    city: 'Keusching'
  },
  {
    postalCode: '5580',
    city: 'Lasaberg'
  },
  {
    postalCode: '5580',
    city: 'Mörtelsdorf'
  },
  {
    postalCode: '5580',
    city: 'Sauerfeld'
  },
  {
    postalCode: '5580',
    city: 'Seetal'
  },
  {
    postalCode: '5580',
    city: 'Tamsweg'
  },
  {
    postalCode: '5580',
    city: 'Wölting'
  },
  {
    postalCode: '5581',
    city: 'Sankt Margarethen im Lungau'
  },
  {
    postalCode: '5582',
    city: 'Sankt Margarethen im Lungau'
  },
  {
    postalCode: '5582',
    city: 'Höf'
  },
  {
    postalCode: '5582',
    city: 'Oberweißburg'
  },
  {
    postalCode: '5582',
    city: 'Sankt Martin'
  },
  {
    postalCode: '5582',
    city: 'Sankt Michael im Lungau'
  },
  {
    postalCode: '5582',
    city: 'Unterweißburg'
  },
  {
    postalCode: '5583',
    city: 'Hintermuhr'
  },
  {
    postalCode: '5583',
    city: 'Schellgaden'
  },
  {
    postalCode: '5583',
    city: 'Vordermuhr'
  },
  {
    postalCode: '5584',
    city: 'Lamm'
  },
  {
    postalCode: '5584',
    city: 'Rothenwand'
  },
  {
    postalCode: '5584',
    city: 'Wald'
  },
  {
    postalCode: '5584',
    city: 'Zederhaus'
  },
  {
    postalCode: '5585',
    city: 'Illmitzen'
  },
  {
    postalCode: '5585',
    city: 'Unternberg'
  },
  {
    postalCode: '5585',
    city: 'Voidersdorf'
  },
  {
    postalCode: '5591',
    city: 'Mignitz'
  },
  {
    postalCode: '5591',
    city: 'Mitterberg'
  },
  {
    postalCode: '5591',
    city: 'Ramingstein'
  },
  {
    postalCode: '5592',
    city: 'Bundschuh'
  },
  {
    postalCode: '5592',
    city: 'Thomatal'
  },
  {
    postalCode: '5600',
    city: 'Einöden'
  },
  {
    postalCode: '5600',
    city: 'Floitensberg'
  },
  {
    postalCode: '5600',
    city: 'Ginau'
  },
  {
    postalCode: '5600',
    city: 'Hallmoos'
  },
  {
    postalCode: '5600',
    city: 'Maschl'
  },
  {
    postalCode: '5600',
    city: 'Plankenau'
  },
  {
    postalCode: '5600',
    city: 'Reinbach'
  },
  {
    postalCode: '5600',
    city: 'Rettenstein'
  },
  {
    postalCode: '5600',
    city: 'Sankt Johann im Pongau'
  },
  {
    postalCode: '5600',
    city: 'Urreiting'
  },
  {
    postalCode: '5600',
    city: 'Sankt Veit im Pongau'
  },
  {
    postalCode: '5602',
    city: 'Floitensberg'
  },
  {
    postalCode: '5602',
    city: 'Ginau'
  },
  {
    postalCode: '5602',
    city: 'Hof'
  },
  {
    postalCode: '5602',
    city: 'Hofmarkt'
  },
  {
    postalCode: '5602',
    city: 'Schwaighof'
  },
  {
    postalCode: '5602',
    city: 'Vorderkleinarl'
  },
  {
    postalCode: '5602',
    city: 'Wagrain Markt'
  },
  {
    postalCode: '5603',
    city: 'Kleinarl'
  },
  {
    postalCode: '5611',
    city: 'Au'
  },
  {
    postalCode: '5611',
    city: 'Bach'
  },
  {
    postalCode: '5611',
    city: 'Eben'
  },
  {
    postalCode: '5611',
    city: 'Großarl'
  },
  {
    postalCode: '5611',
    city: 'Schied'
  },
  {
    postalCode: '5611',
    city: 'Unterberg'
  },
  {
    postalCode: '5612',
    city: 'Hüttschlag'
  },
  {
    postalCode: '5612',
    city: 'Karteis'
  },
  {
    postalCode: '5612',
    city: 'See'
  },
  {
    postalCode: '5620',
    city: 'Grafenhof'
  },
  {
    postalCode: '5620',
    city: 'Sankt Veit im Pongau'
  },
  {
    postalCode: '5620',
    city: 'Schwarzach im Pongau'
  },
  {
    postalCode: '5621',
    city: 'Enkerbichl'
  },
  {
    postalCode: '5621',
    city: 'Sankt Veit im Pongau'
  },
  {
    postalCode: '5622',
    city: 'Altenhof'
  },
  {
    postalCode: '5622',
    city: 'Boden'
  },
  {
    postalCode: '5622',
    city: 'Buchberg'
  },
  {
    postalCode: '5622',
    city: 'Enkerbichl'
  },
  {
    postalCode: '5622',
    city: 'Hasling'
  },
  {
    postalCode: '5622',
    city: 'Hofmark'
  },
  {
    postalCode: '5622',
    city: 'Maierhof'
  },
  {
    postalCode: '5622',
    city: 'March'
  },
  {
    postalCode: '5622',
    city: 'Mitterstein'
  },
  {
    postalCode: '5622',
    city: 'Oberhof'
  },
  {
    postalCode: '5622',
    city: 'Schattau'
  },
  {
    postalCode: '5622',
    city: 'Weng'
  },
  {
    postalCode: '5630',
    city: 'Anger'
  },
  {
    postalCode: '5630',
    city: 'Breitenberg'
  },
  {
    postalCode: '5630',
    city: 'Gadaunern'
  },
  {
    postalCode: '5630',
    city: 'Harbach'
  },
  {
    postalCode: '5630',
    city: 'Heißingfelding'
  },
  {
    postalCode: '5630',
    city: 'Laderding'
  },
  {
    postalCode: '5630',
    city: 'Bad Hofgastein'
  },
  {
    postalCode: '5630',
    city: 'Vorderschneeberg'
  },
  {
    postalCode: '5630',
    city: 'Weinetsberg'
  },
  {
    postalCode: '5630',
    city: 'Wieden'
  },
  {
    postalCode: '5632',
    city: 'Dorfgastein'
  },
  {
    postalCode: '5632',
    city: 'Luggau'
  },
  {
    postalCode: '5632',
    city: 'Maierhofen'
  },
  {
    postalCode: '5632',
    city: 'Unterberg'
  },
  {
    postalCode: '5640',
    city: 'Bad Gastein'
  },
  {
    postalCode: '5645',
    city: 'Bad Gastein'
  },
  {
    postalCode: '5651',
    city: 'Boden'
  },
  {
    postalCode: '5651',
    city: 'Maierhof'
  },
  {
    postalCode: '5651',
    city: 'Berg'
  },
  {
    postalCode: '5651',
    city: 'Embach'
  },
  {
    postalCode: '5651',
    city: 'Heuberg'
  },
  {
    postalCode: '5651',
    city: 'Lend'
  },
  {
    postalCode: '5651',
    city: 'Teufenbach'
  },
  {
    postalCode: '5651',
    city: 'Urbar'
  },
  {
    postalCode: '5651',
    city: 'Winkl'
  },
  {
    postalCode: '5651',
    city: 'Sankt Veit im Pongau'
  },
  {
    postalCode: '5651',
    city: 'Hundsdorf'
  },
  {
    postalCode: '5652',
    city: 'Berg'
  },
  {
    postalCode: '5652',
    city: 'Bodenberg'
  },
  {
    postalCode: '5652',
    city: 'Dorf'
  },
  {
    postalCode: '5652',
    city: 'Schattberg'
  },
  {
    postalCode: '5652',
    city: 'Schwarzenbach'
  },
  {
    postalCode: '5652',
    city: 'Sonnberg'
  },
  {
    postalCode: '5660',
    city: 'Winkl'
  },
  {
    postalCode: '5660',
    city: 'Berg'
  },
  {
    postalCode: '5660',
    city: 'Großsonnberg'
  },
  {
    postalCode: '5660',
    city: 'Gschwandtnerberg'
  },
  {
    postalCode: '5660',
    city: 'Hasenbach'
  },
  {
    postalCode: '5660',
    city: 'Höf'
  },
  {
    postalCode: '5660',
    city: 'Högmoos'
  },
  {
    postalCode: '5660',
    city: 'Hopfberg'
  },
  {
    postalCode: '5660',
    city: 'Hundsdorf'
  },
  {
    postalCode: '5660',
    city: 'Kleinsonnberg'
  },
  {
    postalCode: '5660',
    city: 'March'
  },
  {
    postalCode: '5660',
    city: 'Schackendorf'
  },
  {
    postalCode: '5660',
    city: 'Taxberg'
  },
  {
    postalCode: '5660',
    city: 'Taxenbach'
  },
  {
    postalCode: '5660',
    city: 'Thannberg'
  },
  {
    postalCode: '5661',
    city: 'Bucheben'
  },
  {
    postalCode: '5661',
    city: 'Fröstlberg'
  },
  {
    postalCode: '5661',
    city: 'Grub'
  },
  {
    postalCode: '5661',
    city: 'Hundsdorf'
  },
  {
    postalCode: '5661',
    city: 'Marktrevier'
  },
  {
    postalCode: '5661',
    city: 'Rauris'
  },
  {
    postalCode: '5661',
    city: 'Seidlwinkl'
  },
  {
    postalCode: '5661',
    city: 'Unterland'
  },
  {
    postalCode: '5661',
    city: 'Vorstanddorf'
  },
  {
    postalCode: '5661',
    city: 'Vorstandrevier'
  },
  {
    postalCode: '5661',
    city: 'Wörth'
  },
  {
    postalCode: '5661',
    city: 'Wörtherberg'
  },
  {
    postalCode: '5662',
    city: 'Brandenau'
  },
  {
    postalCode: '5662',
    city: 'Gries'
  },
  {
    postalCode: '5662',
    city: 'Hauserdorf'
  },
  {
    postalCode: '5662',
    city: 'Niederhof'
  },
  {
    postalCode: '5662',
    city: 'Reit'
  },
  {
    postalCode: '5662',
    city: 'Sankt Georgen'
  },
  {
    postalCode: '5662',
    city: 'Steinbach'
  },
  {
    postalCode: '5662',
    city: 'Winkl'
  },
  {
    postalCode: '5662',
    city: 'Großsonnberg'
  },
  {
    postalCode: '5662',
    city: 'Högmoos'
  },
  {
    postalCode: '5662',
    city: 'Lacken'
  },
  {
    postalCode: '5662',
    city: 'Thannberg'
  },
  {
    postalCode: '5671',
    city: 'Bruck an der Großglocknerstraße'
  },
  {
    postalCode: '5671',
    city: 'Fischhorn'
  },
  {
    postalCode: '5671',
    city: 'Hundsdorf'
  },
  {
    postalCode: '5671',
    city: 'Krössenbach'
  },
  {
    postalCode: '5671',
    city: 'Pichl'
  },
  {
    postalCode: '5671',
    city: 'Vorfusch'
  },
  {
    postalCode: '5672',
    city: 'Taxenbacher-Fusch'
  },
  {
    postalCode: '5672',
    city: 'Zeller-Fusch'
  },
  {
    postalCode: '5700',
    city: 'Atzing'
  },
  {
    postalCode: '5700',
    city: 'Maishofen'
  },
  {
    postalCode: '5700',
    city: 'Bruckberg'
  },
  {
    postalCode: '5700',
    city: 'Erlberg'
  },
  {
    postalCode: '5700',
    city: 'Schmitten'
  },
  {
    postalCode: '5700',
    city: 'Thumersbach'
  },
  {
    postalCode: '5700',
    city: 'Zell am See'
  },
  {
    postalCode: '5710',
    city: 'Kaprun'
  },
  {
    postalCode: '5721',
    city: 'Aufhausen'
  },
  {
    postalCode: '5721',
    city: 'Hummersdorf'
  },
  {
    postalCode: '5721',
    city: 'Piesendorf'
  },
  {
    postalCode: '5721',
    city: 'Walchen'
  },
  {
    postalCode: '5722',
    city: 'Aisdorf'
  },
  {
    postalCode: '5722',
    city: 'Ematen'
  },
  {
    postalCode: '5722',
    city: 'Gaisbichl'
  },
  {
    postalCode: '5722',
    city: 'Jesdorf'
  },
  {
    postalCode: '5722',
    city: 'Lengdorf'
  },
  {
    postalCode: '5722',
    city: 'Niedernsill'
  },
  {
    postalCode: '5722',
    city: 'Steindorf'
  },
  {
    postalCode: '5723',
    city: 'Hofham'
  },
  {
    postalCode: '5723',
    city: 'Köhlbichl'
  },
  {
    postalCode: '5723',
    city: 'Litzldorf'
  },
  {
    postalCode: '5723',
    city: 'Pölsen'
  },
  {
    postalCode: '5723',
    city: 'Quettensberg'
  },
  {
    postalCode: '5723',
    city: 'Schwarzenbach'
  },
  {
    postalCode: '5723',
    city: 'Stubach'
  },
  {
    postalCode: '5723',
    city: 'Tobersbach'
  },
  {
    postalCode: '5723',
    city: 'Uggl'
  },
  {
    postalCode: '5723',
    city: 'Uttendorf'
  },
  {
    postalCode: '5724',
    city: 'Bam'
  },
  {
    postalCode: '5724',
    city: 'Dürnberg'
  },
  {
    postalCode: '5724',
    city: 'Pirtendorf'
  },
  {
    postalCode: '5724',
    city: 'Stuhlfelden'
  },
  {
    postalCode: '5724',
    city: 'Wilhelmsdorf'
  },
  {
    postalCode: '5730',
    city: 'Jochberg'
  },
  {
    postalCode: '5730',
    city: 'Arndorf'
  },
  {
    postalCode: '5730',
    city: 'Burk'
  },
  {
    postalCode: '5730',
    city: 'Felben'
  },
  {
    postalCode: '5730',
    city: 'Feldstein'
  },
  {
    postalCode: '5730',
    city: 'Jochbergthurn'
  },
  {
    postalCode: '5730',
    city: 'Klausen'
  },
  {
    postalCode: '5730',
    city: 'Lämmerbichl'
  },
  {
    postalCode: '5730',
    city: 'Loferstein'
  },
  {
    postalCode: '5730',
    city: 'Mayrhofen'
  },
  {
    postalCode: '5730',
    city: 'Mittersill'
  },
  {
    postalCode: '5730',
    city: 'Oberfelben'
  },
  {
    postalCode: '5730',
    city: 'Paßthurn'
  },
  {
    postalCode: '5730',
    city: 'Rettenbach'
  },
  {
    postalCode: '5730',
    city: 'Schattberg'
  },
  {
    postalCode: '5730',
    city: 'Spielbichl'
  },
  {
    postalCode: '5730',
    city: 'Thalbach'
  },
  {
    postalCode: '5730',
    city: 'Unterfelben'
  },
  {
    postalCode: '5730',
    city: 'Weißenstein'
  },
  {
    postalCode: '5731',
    city: 'Arndorf'
  },
  {
    postalCode: '5731',
    city: 'Grubing'
  },
  {
    postalCode: '5731',
    city: 'Hollersbach im Pinzgau'
  },
  {
    postalCode: '5731',
    city: 'Jochberg'
  },
  {
    postalCode: '5731',
    city: 'Lämmerbichl'
  },
  {
    postalCode: '5731',
    city: 'Reitlehen'
  },
  {
    postalCode: '5731',
    city: 'Rettenbach'
  },
  {
    postalCode: '5731',
    city: 'Jochbergthurn'
  },
  {
    postalCode: '5732',
    city: 'Bicheln'
  },
  {
    postalCode: '5732',
    city: 'Dorf'
  },
  {
    postalCode: '5732',
    city: 'Mühlbach'
  },
  {
    postalCode: '5732',
    city: 'Mühlberg'
  },
  {
    postalCode: '5732',
    city: 'Schweinegg'
  },
  {
    postalCode: '5732',
    city: 'Jochberg'
  },
  {
    postalCode: '5733',
    city: 'Bramberg am Wildkogel'
  },
  {
    postalCode: '5733',
    city: 'Habach'
  },
  {
    postalCode: '5733',
    city: 'Weyer'
  },
  {
    postalCode: '5733',
    city: 'Leiten'
  },
  {
    postalCode: '5733',
    city: 'Sonnberg'
  },
  {
    postalCode: '5733',
    city: 'Schönbach'
  },
  {
    postalCode: '5733',
    city: 'Wenns'
  },
  {
    postalCode: '5733',
    city: 'Steinach'
  },
  {
    postalCode: '5741',
    city: 'Weyer'
  },
  {
    postalCode: '5741',
    city: 'Mitterhohenbramberg'
  },
  {
    postalCode: '5741',
    city: 'Neukirchen am Großvenediger'
  },
  {
    postalCode: '5741',
    city: 'Rosental'
  },
  {
    postalCode: '5741',
    city: 'Sulzau'
  },
  {
    postalCode: '5742',
    city: 'Rosental'
  },
  {
    postalCode: '5742',
    city: 'Hinterwaldberg'
  },
  {
    postalCode: '5742',
    city: 'Lahn'
  },
  {
    postalCode: '5742',
    city: 'Vorderkrimml'
  },
  {
    postalCode: '5742',
    city: 'Vorderwaldberg'
  },
  {
    postalCode: '5742',
    city: 'Wald'
  },
  {
    postalCode: '5742',
    city: 'Königsleiten'
  },
  {
    postalCode: '5743',
    city: 'Hochkrimml'
  },
  {
    postalCode: '5743',
    city: 'Oberkrimml'
  },
  {
    postalCode: '5743',
    city: 'Unterkrimml'
  },
  {
    postalCode: '5751',
    city: 'Atzing'
  },
  {
    postalCode: '5751',
    city: 'Maishofen'
  },
  {
    postalCode: '5751',
    city: 'Mitterhofen'
  },
  {
    postalCode: '5752',
    city: 'Viehhofen'
  },
  {
    postalCode: '5753',
    city: 'Saalbach'
  },
  {
    postalCode: '5754',
    city: 'Hinterglemm'
  },
  {
    postalCode: '5754',
    city: 'Saalbach'
  },
  {
    postalCode: '5760',
    city: 'Almdorf'
  },
  {
    postalCode: '5760',
    city: 'Bachwinkl'
  },
  {
    postalCode: '5760',
    city: 'Breitenbergham'
  },
  {
    postalCode: '5760',
    city: 'Bsuch'
  },
  {
    postalCode: '5760',
    city: 'Deuting'
  },
  {
    postalCode: '5760',
    city: 'Dorfheim'
  },
  {
    postalCode: '5760',
    city: 'Euring'
  },
  {
    postalCode: '5760',
    city: 'Gerling'
  },
  {
    postalCode: '5760',
    city: 'Haid'
  },
  {
    postalCode: '5760',
    city: 'Harham'
  },
  {
    postalCode: '5760',
    city: 'Hof'
  },
  {
    postalCode: '5760',
    city: 'Hohlwegen'
  },
  {
    postalCode: '5760',
    city: 'Kehlbach'
  },
  {
    postalCode: '5760',
    city: 'Lenzing'
  },
  {
    postalCode: '5760',
    city: 'Letting'
  },
  {
    postalCode: '5760',
    city: 'Marzon'
  },
  {
    postalCode: '5760',
    city: 'Mayrhofen'
  },
  {
    postalCode: '5760',
    city: 'Niederhaus'
  },
  {
    postalCode: '5760',
    city: 'Obsmarkt'
  },
  {
    postalCode: '5760',
    city: 'Pabing'
  },
  {
    postalCode: '5760',
    city: 'Pfaffenhofen'
  },
  {
    postalCode: '5760',
    city: 'Pfaffing'
  },
  {
    postalCode: '5760',
    city: 'Rain'
  },
  {
    postalCode: '5760',
    city: 'Ramseiden'
  },
  {
    postalCode: '5760',
    city: 'Ruhgassing'
  },
  {
    postalCode: '5760',
    city: 'Saalfelden am Steinernen Meer'
  },
  {
    postalCode: '5760',
    city: 'Schinking'
  },
  {
    postalCode: '5760',
    city: 'Schmalenbergham'
  },
  {
    postalCode: '5760',
    city: 'Schmieding'
  },
  {
    postalCode: '5760',
    city: 'Schützing'
  },
  {
    postalCode: '5760',
    city: 'Thor'
  },
  {
    postalCode: '5760',
    city: 'Uttenhofen'
  },
  {
    postalCode: '5760',
    city: 'Weikersbach'
  },
  {
    postalCode: '5760',
    city: 'Wiesersberg'
  },
  {
    postalCode: '5760',
    city: 'Wiesing'
  },
  {
    postalCode: '5761',
    city: 'Aberg'
  },
  {
    postalCode: '5761',
    city: 'Alm'
  },
  {
    postalCode: '5761',
    city: 'Bachwinkl'
  },
  {
    postalCode: '5761',
    city: 'Enterwinkl'
  },
  {
    postalCode: '5761',
    city: 'Griesbachwinkl'
  },
  {
    postalCode: '5761',
    city: 'Hinterthal'
  },
  {
    postalCode: '5761',
    city: 'Krallerwinkl'
  },
  {
    postalCode: '5761',
    city: 'Schattberg'
  },
  {
    postalCode: '5761',
    city: 'Sonnberg'
  },
  {
    postalCode: '5761',
    city: 'Schloßberg'
  },
  {
    postalCode: '5771',
    city: 'Berg'
  },
  {
    postalCode: '5771',
    city: 'Ecking'
  },
  {
    postalCode: '5771',
    city: 'Grießen'
  },
  {
    postalCode: '5771',
    city: 'Hirnreit'
  },
  {
    postalCode: '5771',
    city: 'Hütten'
  },
  {
    postalCode: '5771',
    city: 'Leogang'
  },
  {
    postalCode: '5771',
    city: 'Madreit'
  },
  {
    postalCode: '5771',
    city: 'Otting'
  },
  {
    postalCode: '5771',
    city: 'Pirzbichl'
  },
  {
    postalCode: '5771',
    city: 'Rain'
  },
  {
    postalCode: '5771',
    city: 'Rosental'
  },
  {
    postalCode: '5771',
    city: 'Schwarzleo'
  },
  {
    postalCode: '5771',
    city: 'Sinning'
  },
  {
    postalCode: '5771',
    city: 'Sonnberg'
  },
  {
    postalCode: '5771',
    city: 'Sonnrain'
  },
  {
    postalCode: '5771',
    city: 'Ullach'
  },
  {
    postalCode: '6020',
    city: 'Amras'
  },
  {
    postalCode: '6020',
    city: 'Arzl'
  },
  {
    postalCode: '6020',
    city: 'Hötting'
  },
  {
    postalCode: '6020',
    city: 'Innsbruck'
  },
  {
    postalCode: '6020',
    city: 'Mühlau'
  },
  {
    postalCode: '6020',
    city: 'Pradl'
  },
  {
    postalCode: '6020',
    city: 'Vill'
  },
  {
    postalCode: '6020',
    city: 'Wilten'
  },
  {
    postalCode: '6020',
    city: 'Mutters'
  },
  {
    postalCode: '6020',
    city: 'Raitis'
  },
  {
    postalCode: '6020',
    city: 'Natters'
  },
  {
    postalCode: '6020',
    city: 'Schönberg im Stubaital'
  },
  {
    postalCode: '6060',
    city: 'Absam'
  },
  {
    postalCode: '6060',
    city: 'Heiligkreuz'
  },
  {
    postalCode: '6060',
    city: 'Hall in Tirol'
  },
  {
    postalCode: '6060',
    city: 'Thaur'
  },
  {
    postalCode: '6063',
    city: 'Rum'
  },
  {
    postalCode: '6063',
    city: 'Thaur'
  },
  {
    postalCode: '6065',
    city: 'Thaur'
  },
  {
    postalCode: '6067',
    city: 'Absam'
  },
  {
    postalCode: '6067',
    city: 'Hall in Tirol'
  },
  {
    postalCode: '6068',
    city: 'Mils'
  },
  {
    postalCode: '6069',
    city: 'Gnadenwald'
  },
  {
    postalCode: '6070',
    city: 'Ampass'
  },
  {
    postalCode: '6071',
    city: 'Aldrans'
  },
  {
    postalCode: '6072',
    city: 'Lans'
  },
  {
    postalCode: '6073',
    city: 'Aldrans'
  },
  {
    postalCode: '6073',
    city: 'Sistrans'
  },
  {
    postalCode: '6074',
    city: 'Aldrans'
  },
  {
    postalCode: '6074',
    city: 'Rinn'
  },
  {
    postalCode: '6075',
    city: 'Tulfes'
  },
  {
    postalCode: '6075',
    city: 'Volderwald'
  },
  {
    postalCode: '6080',
    city: 'Igls'
  },
  {
    postalCode: '6080',
    city: 'Vill'
  },
  {
    postalCode: '6082',
    city: 'Patsch'
  },
  {
    postalCode: '6083',
    city: 'Ellbögen'
  },
  {
    postalCode: '6091',
    city: 'Götzens'
  },
  {
    postalCode: '6091',
    city: 'Natters'
  },
  {
    postalCode: '6092',
    city: 'Birgitz'
  },
  {
    postalCode: '6094',
    city: 'Axams'
  },
  {
    postalCode: '6094',
    city: 'Axamer Lizum'
  },
  {
    postalCode: '6094',
    city: 'Bachl'
  },
  {
    postalCode: '6094',
    city: 'Kristen'
  },
  {
    postalCode: '6095',
    city: 'Grinzens'
  },
  {
    postalCode: '6100',
    city: 'Leutasch'
  },
  {
    postalCode: '6100',
    city: 'Reith bei Seefeld'
  },
  {
    postalCode: '6100',
    city: 'Seefeld in Tirol'
  },
  {
    postalCode: '6100',
    city: 'Mösern'
  },
  {
    postalCode: '6100',
    city: 'Wildmoos'
  },
  {
    postalCode: '6103',
    city: 'Reith bei Seefeld'
  },
  {
    postalCode: '6105',
    city: 'Leutasch'
  },
  {
    postalCode: '6108',
    city: 'Scharnitz'
  },
  {
    postalCode: '6111',
    city: 'Großvolderberg'
  },
  {
    postalCode: '6111',
    city: 'Kleinvolderberg'
  },
  {
    postalCode: '6111',
    city: 'Volders'
  },
  {
    postalCode: '6112',
    city: 'Wattens'
  },
  {
    postalCode: '6113',
    city: 'Wattenberg'
  },
  {
    postalCode: '6114',
    city: 'Kolsass'
  },
  {
    postalCode: '6114',
    city: 'Weer'
  },
  {
    postalCode: '6115',
    city: 'Kolsassberg'
  },
  {
    postalCode: '6121',
    city: 'Baumkirchen'
  },
  {
    postalCode: '6122',
    city: 'Fritzens'
  },
  {
    postalCode: '6123',
    city: 'Eggen'
  },
  {
    postalCode: '6123',
    city: 'Mairbach'
  },
  {
    postalCode: '6123',
    city: 'Schlögelsbach'
  },
  {
    postalCode: '6123',
    city: 'Neu-Terfens'
  },
  {
    postalCode: '6123',
    city: 'Terfens'
  },
  {
    postalCode: '6123',
    city: 'Umlberg'
  },
  {
    postalCode: '6123',
    city: 'Vomperbach'
  },
  {
    postalCode: '6130',
    city: 'Schwaz'
  },
  {
    postalCode: '6133',
    city: 'Weerberg'
  },
  {
    postalCode: '6134',
    city: 'Fiecht'
  },
  {
    postalCode: '6134',
    city: 'Vomp'
  },
  {
    postalCode: '6134',
    city: 'Vomperbach'
  },
  {
    postalCode: '6134',
    city: 'Vomperberg'
  },
  {
    postalCode: '6135',
    city: 'Schlagturn'
  },
  {
    postalCode: '6135',
    city: 'Stans'
  },
  {
    postalCode: '6136',
    city: 'Pill'
  },
  {
    postalCode: '6141',
    city: 'Schönberg im Stubaital'
  },
  {
    postalCode: '6142',
    city: 'Mieders'
  },
  {
    postalCode: '6143',
    city: 'Matrei am Brenner'
  },
  {
    postalCode: '6143',
    city: 'Altstadt'
  },
  {
    postalCode: '6143',
    city: 'Matreiwald'
  },
  {
    postalCode: '6143',
    city: 'Mühlbachl'
  },
  {
    postalCode: '6143',
    city: 'Mützens'
  },
  {
    postalCode: '6143',
    city: 'Obfeldes'
  },
  {
    postalCode: '6143',
    city: 'Statz'
  },
  {
    postalCode: '6143',
    city: 'Zieglstadl'
  },
  {
    postalCode: '6143',
    city: 'Pfons'
  },
  {
    postalCode: '6143',
    city: 'Schöfens'
  },
  {
    postalCode: '6145',
    city: 'Außerweg'
  },
  {
    postalCode: '6145',
    city: 'Oberweg'
  },
  {
    postalCode: '6145',
    city: 'Unterweg'
  },
  {
    postalCode: '6150',
    city: 'Gschnitz'
  },
  {
    postalCode: '6150',
    city: 'Statz'
  },
  {
    postalCode: '6150',
    city: 'Mauern'
  },
  {
    postalCode: '6150',
    city: 'Stafflach'
  },
  {
    postalCode: '6150',
    city: 'Steinach am Brenner'
  },
  {
    postalCode: '6150',
    city: 'Trins'
  },
  {
    postalCode: '6152',
    city: 'Trins'
  },
  {
    postalCode: '6154',
    city: 'Außerschmirn'
  },
  {
    postalCode: '6154',
    city: 'Innerschmirn'
  },
  {
    postalCode: '6154',
    city: 'Vals'
  },
  {
    postalCode: '6156',
    city: 'Gries am Brenner'
  },
  {
    postalCode: '6157',
    city: 'Obernberg am Brenner'
  },
  {
    postalCode: '6161',
    city: 'Natters'
  },
  {
    postalCode: '6162',
    city: 'Kreith'
  },
  {
    postalCode: '6162',
    city: 'Mutters'
  },
  {
    postalCode: '6162',
    city: 'Raitis'
  },
  {
    postalCode: '6165',
    city: 'Gagers'
  },
  {
    postalCode: '6165',
    city: 'Kapfers'
  },
  {
    postalCode: '6165',
    city: 'Plöven'
  },
  {
    postalCode: '6165',
    city: 'Telfes im Stubai'
  },
  {
    postalCode: '6166',
    city: 'Fulpmes'
  },
  {
    postalCode: '6166',
    city: 'Medraz'
  },
  {
    postalCode: '6167',
    city: 'Medraz'
  },
  {
    postalCode: '6167',
    city: 'Neustift im Stubaital'
  },
  {
    postalCode: '6170',
    city: 'Pettnau'
  },
  {
    postalCode: '6170',
    city: 'Zirl'
  },
  {
    postalCode: '6173',
    city: 'Oberperfuss'
  },
  {
    postalCode: '6175',
    city: 'Afling'
  },
  {
    postalCode: '6175',
    city: 'Kematen in Tirol'
  },
  {
    postalCode: '6176',
    city: 'Afling'
  },
  {
    postalCode: '6176',
    city: 'Völs'
  },
  {
    postalCode: '6178',
    city: 'Unterperfuss'
  },
  {
    postalCode: '6179',
    city: 'Ranggen'
  },
  {
    postalCode: '6181',
    city: 'Sellrain'
  },
  {
    postalCode: '6182',
    city: 'Gries im Sellrain'
  },
  {
    postalCode: '6182',
    city: 'St. Sigmund im Sellrain'
  },
  {
    postalCode: '6182',
    city: 'Stams'
  },
  {
    postalCode: '6183',
    city: 'Kühtai'
  },
  {
    postalCode: '6184',
    city: 'St. Sigmund im Sellrain'
  },
  {
    postalCode: '6200',
    city: 'Buch'
  },
  {
    postalCode: '6200',
    city: 'Fischl'
  },
  {
    postalCode: '6200',
    city: 'Jenbach'
  },
  {
    postalCode: '6200',
    city: 'Tratzberg'
  },
  {
    postalCode: '6200',
    city: 'Strass im Zillertal'
  },
  {
    postalCode: '6210',
    city: 'Bradl'
  },
  {
    postalCode: '6210',
    city: 'Dikat'
  },
  {
    postalCode: '6210',
    city: 'Erlach'
  },
  {
    postalCode: '6210',
    city: 'Wiesing'
  },
  {
    postalCode: '6210',
    city: 'Rofansiedlung'
  },
  {
    postalCode: '6210',
    city: 'Astenberg'
  },
  {
    postalCode: '6210',
    city: 'Tiergarten'
  },
  {
    postalCode: '6210',
    city: 'Ehrenstall'
  },
  {
    postalCode: '6212',
    city: 'Maurach'
  },
  {
    postalCode: '6213',
    city: 'Pertisau'
  },
  {
    postalCode: '6215',
    city: 'Achenkirch'
  },
  {
    postalCode: '6215',
    city: 'Bächental'
  },
  {
    postalCode: '6215',
    city: 'Hinterriß'
  },
  {
    postalCode: '6215',
    city: 'Steinberg am Rofan'
  },
  {
    postalCode: '6222',
    city: 'Gallzein'
  },
  {
    postalCode: '6230',
    city: 'Brixlegg'
  },
  {
    postalCode: '6230',
    city: 'Mehrn'
  },
  {
    postalCode: '6230',
    city: 'Zimmermoos'
  },
  {
    postalCode: '6232',
    city: 'Lichtwerth'
  },
  {
    postalCode: '6232',
    city: 'Münster'
  },
  {
    postalCode: '6233',
    city: 'Mariatal'
  },
  {
    postalCode: '6233',
    city: 'Voldöpp'
  },
  {
    postalCode: '6234',
    city: 'Aschau'
  },
  {
    postalCode: '6234',
    city: 'Brandenberg'
  },
  {
    postalCode: '6235',
    city: 'Hygna'
  },
  {
    postalCode: '6235',
    city: 'Reith im Alpbachtal'
  },
  {
    postalCode: '6235',
    city: 'Scheffach'
  },
  {
    postalCode: '6236',
    city: 'Alpbach'
  },
  {
    postalCode: '6236',
    city: 'Hygna'
  },
  {
    postalCode: '6240',
    city: 'Rattenberg'
  },
  {
    postalCode: '6241',
    city: 'Radfeld'
  },
  {
    postalCode: '6250',
    city: 'Kundl'
  },
  {
    postalCode: '6250',
    city: 'Liesfeld'
  },
  {
    postalCode: '6250',
    city: 'Saulueg'
  },
  {
    postalCode: '6252',
    city: 'Breitenbach am Inn'
  },
  {
    postalCode: '6252',
    city: 'Haus'
  },
  {
    postalCode: '6252',
    city: 'Kleinsöll'
  },
  {
    postalCode: '6252',
    city: 'Schönau'
  },
  {
    postalCode: '6260',
    city: 'Bruck am Ziller'
  },
  {
    postalCode: '6260',
    city: 'Bruckerberg'
  },
  {
    postalCode: '6260',
    city: 'Imming'
  },
  {
    postalCode: '6261',
    city: 'Schlitters'
  },
  {
    postalCode: '6261',
    city: 'Strass im Zillertal'
  },
  {
    postalCode: '6262',
    city: 'Schlitters'
  },
  {
    postalCode: '6263',
    city: 'Fügen'
  },
  {
    postalCode: '6263',
    city: 'Gagering'
  },
  {
    postalCode: '6263',
    city: 'Kapfing'
  },
  {
    postalCode: '6263',
    city: 'Kleinboden'
  },
  {
    postalCode: '6263',
    city: 'Fügenberg'
  },
  {
    postalCode: '6263',
    city: 'Pankrazberg'
  },
  {
    postalCode: '6263',
    city: 'Schlitters'
  },
  {
    postalCode: '6264',
    city: 'Fügenberg'
  },
  {
    postalCode: '6264',
    city: 'Pankrazberg'
  },
  {
    postalCode: '6265',
    city: 'Hart im Zillertal'
  },
  {
    postalCode: '6265',
    city: 'Helfenstein'
  },
  {
    postalCode: '6265',
    city: 'Holdernach'
  },
  {
    postalCode: '6271',
    city: 'Finsing'
  },
  {
    postalCode: '6271',
    city: 'Kleinboden'
  },
  {
    postalCode: '6271',
    city: 'Uderns'
  },
  {
    postalCode: '6272',
    city: 'Emberg'
  },
  {
    postalCode: '6272',
    city: 'Kaltenbach'
  },
  {
    postalCode: '6272',
    city: 'Ried im Zillertal'
  },
  {
    postalCode: '6274',
    city: 'Aschau'
  },
  {
    postalCode: '6274',
    city: 'Distelberg'
  },
  {
    postalCode: '6275',
    city: 'Stumm'
  },
  {
    postalCode: '6276',
    city: 'Gattererberg'
  },
  {
    postalCode: '6276',
    city: 'Stummerberg'
  },
  {
    postalCode: '6277',
    city: 'Zellberg'
  },
  {
    postalCode: '6277',
    city: 'Zellbergeben'
  },
  {
    postalCode: '6278',
    city: 'Hainzenberg'
  },
  {
    postalCode: '6280',
    city: 'Gerlosberg'
  },
  {
    postalCode: '6280',
    city: 'Rohrberg'
  },
  {
    postalCode: '6280',
    city: 'Zell am Ziller'
  },
  {
    postalCode: '6281',
    city: 'Gerlos'
  },
  {
    postalCode: '6283',
    city: 'Hippach-Schwendberg'
  },
  {
    postalCode: '6283',
    city: 'Laimach'
  },
  {
    postalCode: '6283',
    city: 'Schwendau'
  },
  {
    postalCode: '6283',
    city: 'Zell am Ziller'
  },
  {
    postalCode: '6284',
    city: 'Ramsau im Zillertal'
  },
  {
    postalCode: '6290',
    city: 'Brandberg'
  },
  {
    postalCode: '6290',
    city: 'Finkenberg'
  },
  {
    postalCode: '6290',
    city: 'Mayrhofen'
  },
  {
    postalCode: '6290',
    city: 'Schwendau'
  },
  {
    postalCode: '6292',
    city: 'Finkenberg'
  },
  {
    postalCode: '6292',
    city: 'Schwendau'
  },
  {
    postalCode: '6293',
    city: 'Finkenberg'
  },
  {
    postalCode: '6293',
    city: 'Tux'
  },
  {
    postalCode: '6294',
    city: 'Tux'
  },
  {
    postalCode: '6295',
    city: 'Dornauberg'
  },
  {
    postalCode: '6295',
    city: 'Mayrhofen'
  },
  {
    postalCode: '6300',
    city: 'Angath'
  },
  {
    postalCode: '6300',
    city: 'Wörgl'
  },
  {
    postalCode: '6305',
    city: 'Itter'
  },
  {
    postalCode: '6306',
    city: 'Söll'
  },
  {
    postalCode: '6311',
    city: 'Oberau'
  },
  {
    postalCode: '6311',
    city: 'Thierbach'
  },
  {
    postalCode: '6311',
    city: 'Auffach'
  },
  {
    postalCode: '6313',
    city: 'Auffach'
  },
  {
    postalCode: '6314',
    city: 'Grafenweg'
  },
  {
    postalCode: '6314',
    city: 'Niederau'
  },
  {
    postalCode: '6320',
    city: 'Achleit'
  },
  {
    postalCode: '6320',
    city: 'Embach'
  },
  {
    postalCode: '6320',
    city: 'Angerberg'
  },
  {
    postalCode: '6322',
    city: 'Bichlwang'
  },
  {
    postalCode: '6322',
    city: 'Kastengstatt'
  },
  {
    postalCode: '6322',
    city: 'Kirchbichl'
  },
  {
    postalCode: '6322',
    city: 'Kirchbichler Boden'
  },
  {
    postalCode: '6322',
    city: 'Oberndorf'
  },
  {
    postalCode: '6323',
    city: 'Bad Häring'
  },
  {
    postalCode: '6323',
    city: 'Osterndorf'
  },
  {
    postalCode: '6323',
    city: 'Schönau'
  },
  {
    postalCode: '6324',
    city: 'Mariastein'
  },
  {
    postalCode: '6330',
    city: 'Eichelwang'
  },
  {
    postalCode: '6330',
    city: 'Kaisertal'
  },
  {
    postalCode: '6330',
    city: 'Endach'
  },
  {
    postalCode: '6330',
    city: 'Kufstein'
  },
  {
    postalCode: '6330',
    city: 'Kufstein-Stadtberg'
  },
  {
    postalCode: '6330',
    city: 'Mitterndorf'
  },
  {
    postalCode: '6330',
    city: 'Morsbach'
  },
  {
    postalCode: '6330',
    city: 'Thierberg'
  },
  {
    postalCode: '6330',
    city: 'Weissach'
  },
  {
    postalCode: '6330',
    city: 'Zell'
  },
  {
    postalCode: '6330',
    city: 'Söll'
  },
  {
    postalCode: '6334',
    city: 'Schwoich'
  },
  {
    postalCode: '6335',
    city: 'Hinterthiersee'
  },
  {
    postalCode: '6335',
    city: 'Landl'
  },
  {
    postalCode: '6335',
    city: 'Vorderthiersee'
  },
  {
    postalCode: '6335',
    city: 'Schmiedtal'
  },
  {
    postalCode: '6335',
    city: 'Mitterland'
  },
  {
    postalCode: '6335',
    city: 'Almen'
  },
  {
    postalCode: '6336',
    city: 'Morsbach'
  },
  {
    postalCode: '6336',
    city: 'Niederbreitenbach'
  },
  {
    postalCode: '6336',
    city: 'Oberlangkampfen'
  },
  {
    postalCode: '6336',
    city: 'Unterlangkampfen'
  },
  {
    postalCode: '6341',
    city: 'Asching'
  },
  {
    postalCode: '6341',
    city: 'Brand'
  },
  {
    postalCode: '6341',
    city: 'Nußham'
  },
  {
    postalCode: '6341',
    city: 'Oberbuchberg'
  },
  {
    postalCode: '6341',
    city: 'Ebbs'
  },
  {
    postalCode: '6341',
    city: 'Eichelwang'
  },
  {
    postalCode: '6341',
    city: 'Oberndorf'
  },
  {
    postalCode: '6341',
    city: 'Wagrain-Mühltal'
  },
  {
    postalCode: '6342',
    city: 'Niederndorf'
  },
  {
    postalCode: '6342',
    city: 'Hausern'
  },
  {
    postalCode: '6342',
    city: 'Eiberg'
  },
  {
    postalCode: '6342',
    city: 'Gränzing'
  },
  {
    postalCode: '6342',
    city: 'Noppenberg'
  },
  {
    postalCode: '6342',
    city: 'Praschberg'
  },
  {
    postalCode: '6342',
    city: 'Rettenschöss'
  },
  {
    postalCode: '6343',
    city: 'Erl'
  },
  {
    postalCode: '6343',
    city: 'Erlerberg'
  },
  {
    postalCode: '6343',
    city: 'Mühlgraben'
  },
  {
    postalCode: '6344',
    city: 'Kössen'
  },
  {
    postalCode: '6344',
    city: 'Durchholzen'
  },
  {
    postalCode: '6344',
    city: 'Oed'
  },
  {
    postalCode: '6344',
    city: 'Schwaigs'
  },
  {
    postalCode: '6344',
    city: 'Walchsee'
  },
  {
    postalCode: '6345',
    city: 'Kössen'
  },
  {
    postalCode: '6351',
    city: 'Scheffau am Wilden Kaiser'
  },
  {
    postalCode: '6351',
    city: 'Söll'
  },
  {
    postalCode: '6352',
    city: 'Ellmau'
  },
  {
    postalCode: '6353',
    city: 'Going am Wilden Kaiser'
  },
  {
    postalCode: '6353',
    city: 'Prama'
  },
  {
    postalCode: '6353',
    city: 'Schattseite'
  },
  {
    postalCode: '6353',
    city: 'Sonnseite'
  },
  {
    postalCode: '6353',
    city: 'Reith bei Kitzbühel'
  },
  {
    postalCode: '6361',
    city: 'Glantersberg'
  },
  {
    postalCode: '6361',
    city: 'Grafenweg'
  },
  {
    postalCode: '6361',
    city: 'Gruberberg'
  },
  {
    postalCode: '6361',
    city: 'Hopfgarten-Markt'
  },
  {
    postalCode: '6361',
    city: 'Kelchsau'
  },
  {
    postalCode: '6361',
    city: 'Penningberg'
  },
  {
    postalCode: '6361',
    city: 'Salvenberg'
  },
  {
    postalCode: '6363',
    city: 'Nachtsöllberg'
  },
  {
    postalCode: '6363',
    city: 'Salvenberg'
  },
  {
    postalCode: '6363',
    city: 'Au'
  },
  {
    postalCode: '6363',
    city: 'Außersalvenberg'
  },
  {
    postalCode: '6363',
    city: 'Bichling'
  },
  {
    postalCode: '6363',
    city: 'Feichten'
  },
  {
    postalCode: '6363',
    city: 'Holzham'
  },
  {
    postalCode: '6363',
    city: 'Kummern'
  },
  {
    postalCode: '6363',
    city: 'Moosen'
  },
  {
    postalCode: '6363',
    city: 'Mühltal'
  },
  {
    postalCode: '6363',
    city: 'Oberwindau'
  },
  {
    postalCode: '6363',
    city: 'Rettenbach'
  },
  {
    postalCode: '6363',
    city: 'Schwaigerberg'
  },
  {
    postalCode: '6363',
    city: 'Unterwindau'
  },
  {
    postalCode: '6363',
    city: 'Vorderwindau'
  },
  {
    postalCode: '6363',
    city: 'Westendorf'
  },
  {
    postalCode: '6364',
    city: 'Brixen im Thale'
  },
  {
    postalCode: '6364',
    city: 'Feuring'
  },
  {
    postalCode: '6364',
    city: 'Hof'
  },
  {
    postalCode: '6364',
    city: 'Lauterbach'
  },
  {
    postalCode: '6364',
    city: 'Sonnberg'
  },
  {
    postalCode: '6365',
    city: 'Kirchberg in Tirol'
  },
  {
    postalCode: '6365',
    city: 'Reith bei Kitzbühel'
  },
  {
    postalCode: '6370',
    city: 'Kitzbühel'
  },
  {
    postalCode: '6370',
    city: 'Reith bei Kitzbühel'
  },
  {
    postalCode: '6371',
    city: 'Aurach bei Kitzbühel'
  },
  {
    postalCode: '6372',
    city: 'Oberndorf in Tirol'
  },
  {
    postalCode: '6373',
    city: 'Jochberg'
  },
  {
    postalCode: '6380',
    city: 'St. Johann in Tirol'
  },
  {
    postalCode: '6382',
    city: 'Kirchdorf in Tirol'
  },
  {
    postalCode: '6382',
    city: 'Erpfendorf'
  },
  {
    postalCode: '6383',
    city: 'Kirchdorf in Tirol'
  },
  {
    postalCode: '6383',
    city: 'Erpfendorf'
  },
  {
    postalCode: '6384',
    city: 'Waidring'
  },
  {
    postalCode: '6385',
    city: 'Schwendt'
  },
  {
    postalCode: '6391',
    city: 'Fieberbrunn'
  },
  {
    postalCode: '6392',
    city: 'St. Jakob in Haus'
  },
  {
    postalCode: '6393',
    city: 'St. Ulrich am Pillersee'
  },
  {
    postalCode: '6395',
    city: 'Hochfilzen'
  },
  {
    postalCode: '6401',
    city: 'Eben'
  },
  {
    postalCode: '6401',
    city: 'Hof'
  },
  {
    postalCode: '6401',
    city: 'Inzing'
  },
  {
    postalCode: '6401',
    city: 'Moos'
  },
  {
    postalCode: '6401',
    city: 'Schindeltal'
  },
  {
    postalCode: '6401',
    city: 'Toblaten'
  },
  {
    postalCode: '6402',
    city: 'Hatting'
  },
  {
    postalCode: '6402',
    city: 'Hattingerberg'
  },
  {
    postalCode: '6403',
    city: 'Flaurling'
  },
  {
    postalCode: '6404',
    city: 'Pollingberg'
  },
  {
    postalCode: '6404',
    city: 'Polling in Tirol'
  },
  {
    postalCode: '6405',
    city: 'Pfaffenhofen'
  },
  {
    postalCode: '6406',
    city: 'Oberhofen im Inntal'
  },
  {
    postalCode: '6408',
    city: 'Pettnau'
  },
  {
    postalCode: '6410',
    city: 'Bairbach'
  },
  {
    postalCode: '6410',
    city: 'Birkenberg'
  },
  {
    postalCode: '6410',
    city: 'Brand'
  },
  {
    postalCode: '6410',
    city: 'Buchen'
  },
  {
    postalCode: '6410',
    city: 'Hinterberg'
  },
  {
    postalCode: '6410',
    city: 'Lehen'
  },
  {
    postalCode: '6410',
    city: 'Moos'
  },
  {
    postalCode: '6410',
    city: 'Platten'
  },
  {
    postalCode: '6410',
    city: 'Sagl'
  },
  {
    postalCode: '6410',
    city: 'St. Veit'
  },
  {
    postalCode: '6410',
    city: 'Telfs'
  },
  {
    postalCode: '6414',
    city: 'Barwies'
  },
  {
    postalCode: '6414',
    city: 'Obermieming'
  },
  {
    postalCode: '6414',
    city: 'See'
  },
  {
    postalCode: '6414',
    city: 'Untermieming'
  },
  {
    postalCode: '6414',
    city: 'Wildermieming'
  },
  {
    postalCode: '6416',
    city: 'Obsteig'
  },
  {
    postalCode: '6421',
    city: 'Rietz'
  },
  {
    postalCode: '6422',
    city: 'Stams'
  },
  {
    postalCode: '6423',
    city: 'Mötz'
  },
  {
    postalCode: '6424',
    city: 'Silz'
  },
  {
    postalCode: '6425',
    city: 'Haiming'
  },
  {
    postalCode: '6425',
    city: 'Haimingerberg'
  },
  {
    postalCode: '6425',
    city: 'Schlierenzau'
  },
  {
    postalCode: '6426',
    city: 'Roppen'
  },
  {
    postalCode: '6430',
    city: 'Haiming'
  },
  {
    postalCode: '6430',
    city: 'Ötztal-Bahnhof'
  },
  {
    postalCode: '6432',
    city: 'Sautens'
  },
  {
    postalCode: '6433',
    city: 'Brunau'
  },
  {
    postalCode: '6433',
    city: 'Ochsengarten'
  },
  {
    postalCode: '6433',
    city: 'Oetz'
  },
  {
    postalCode: '6441',
    city: 'Farst'
  },
  {
    postalCode: '6441',
    city: 'Köfels'
  },
  {
    postalCode: '6441',
    city: 'Niederthai'
  },
  {
    postalCode: '6441',
    city: 'Östen'
  },
  {
    postalCode: '6441',
    city: 'Tumpen'
  },
  {
    postalCode: '6441',
    city: 'Umhausen'
  },
  {
    postalCode: '6444',
    city: 'Gries'
  },
  {
    postalCode: '6444',
    city: 'Huben'
  },
  {
    postalCode: '6444',
    city: 'Oberlängenfeld'
  },
  {
    postalCode: '6444',
    city: 'Oberried'
  },
  {
    postalCode: '6444',
    city: 'Unterlängenfeld'
  },
  {
    postalCode: '6450',
    city: 'Gurgl'
  },
  {
    postalCode: '6450',
    city: 'Heiligkreuz'
  },
  {
    postalCode: '6450',
    city: 'Sölden'
  },
  {
    postalCode: '6450',
    city: 'Zwieselstein'
  },
  {
    postalCode: '6452',
    city: 'Sölden'
  },
  {
    postalCode: '6456',
    city: 'Gurgl'
  },
  {
    postalCode: '6456',
    city: 'Sölden'
  },
  {
    postalCode: '6458',
    city: 'Sölden'
  },
  {
    postalCode: '6458',
    city: 'Vent'
  },
  {
    postalCode: '6460',
    city: 'Imst'
  },
  {
    postalCode: '6460',
    city: 'Karrösten'
  },
  {
    postalCode: '6462',
    city: 'Karres'
  },
  {
    postalCode: '6464',
    city: 'Dollinger'
  },
  {
    postalCode: '6464',
    city: 'Obtarrenz'
  },
  {
    postalCode: '6464',
    city: 'Strad'
  },
  {
    postalCode: '6464',
    city: 'Tarrenz'
  },
  {
    postalCode: '6464',
    city: 'Walchenbach'
  },
  {
    postalCode: '6465',
    city: 'Nassereith'
  },
  {
    postalCode: '6471',
    city: 'Arzl im Pitztal'
  },
  {
    postalCode: '6471',
    city: 'Blons'
  },
  {
    postalCode: '6471',
    city: 'Hochasten'
  },
  {
    postalCode: '6471',
    city: 'Leins'
  },
  {
    postalCode: '6471',
    city: 'Ried'
  },
  {
    postalCode: '6471',
    city: 'Timmls'
  },
  {
    postalCode: '6471',
    city: 'Wald'
  },
  {
    postalCode: '6473',
    city: 'Fließ'
  },
  {
    postalCode: '6473',
    city: 'Piller'
  },
  {
    postalCode: '6473',
    city: 'Jerzens'
  },
  {
    postalCode: '6473',
    city: 'Wenns'
  },
  {
    postalCode: '6474',
    city: 'Jerzens'
  },
  {
    postalCode: '6481',
    city: 'Plangeross'
  },
  {
    postalCode: '6481',
    city: 'St. Leonhard im Pitztal'
  },
  {
    postalCode: '6481',
    city: 'Zaunhof'
  },
  {
    postalCode: '6491',
    city: 'Schönwies'
  },
  {
    postalCode: '6492',
    city: 'Imsterberg'
  },
  {
    postalCode: '6493',
    city: 'Mils bei Imst'
  },
  {
    postalCode: '6500',
    city: 'Fließ'
  },
  {
    postalCode: '6500',
    city: 'Grins'
  },
  {
    postalCode: '6500',
    city: 'Landeck'
  },
  {
    postalCode: '6500',
    city: 'Stanz bei Landeck'
  },
  {
    postalCode: '6511',
    city: 'Zammerberg'
  },
  {
    postalCode: '6511',
    city: 'Zams'
  },
  {
    postalCode: '6521',
    city: 'Fließ'
  },
  {
    postalCode: '6521',
    city: 'Niedergallmigg'
  },
  {
    postalCode: '6522',
    city: 'Faggen'
  },
  {
    postalCode: '6522',
    city: 'Kauns'
  },
  {
    postalCode: '6522',
    city: 'Prutz'
  },
  {
    postalCode: '6524',
    city: 'Kaunertal'
  },
  {
    postalCode: '6527',
    city: 'Kaunerberg'
  },
  {
    postalCode: '6528',
    city: 'Fendels'
  },
  {
    postalCode: '6531',
    city: 'Ried im Oberinntal'
  },
  {
    postalCode: '6532',
    city: 'Ladis'
  },
  {
    postalCode: '6533',
    city: 'Fiss'
  },
  {
    postalCode: '6534',
    city: 'Serfaus'
  },
  {
    postalCode: '6541',
    city: 'Serfaus'
  },
  {
    postalCode: '6541',
    city: 'Tösens'
  },
  {
    postalCode: '6542',
    city: 'Nauders'
  },
  {
    postalCode: '6542',
    city: 'Pfunds'
  },
  {
    postalCode: '6542',
    city: 'Serfaus'
  },
  {
    postalCode: '6543',
    city: 'Nauders'
  },
  {
    postalCode: '6544',
    city: 'Spiss'
  },
  {
    postalCode: '6551',
    city: 'Pians'
  },
  {
    postalCode: '6551',
    city: 'Quadratsch'
  },
  {
    postalCode: '6552',
    city: 'Tobadill'
  },
  {
    postalCode: '6553',
    city: 'Langesthei'
  },
  {
    postalCode: '6553',
    city: 'See'
  },
  {
    postalCode: '6555',
    city: 'Kappl'
  },
  {
    postalCode: '6555',
    city: 'See'
  },
  {
    postalCode: '6561',
    city: 'Ischgl'
  },
  {
    postalCode: '6562',
    city: 'Mathon'
  },
  {
    postalCode: '6563',
    city: 'Galtür'
  },
  {
    postalCode: '6571',
    city: 'Strengen'
  },
  {
    postalCode: '6572',
    city: 'Flirsch'
  },
  {
    postalCode: '6574',
    city: 'Pettneu am Arlberg'
  },
  {
    postalCode: '6574',
    city: 'Schnann'
  },
  {
    postalCode: '6580',
    city: 'St. Anton am Arlberg'
  },
  {
    postalCode: '6580',
    city: 'St. Jakob am Arlberg'
  },
  {
    postalCode: '6591',
    city: 'Grins'
  },
  {
    postalCode: '6591',
    city: 'Quadratsch'
  },
  {
    postalCode: '6600',
    city: 'Breitenwang'
  },
  {
    postalCode: '6600',
    city: 'Ehenbichl'
  },
  {
    postalCode: '6600',
    city: 'Lechaschau'
  },
  {
    postalCode: '6600',
    city: 'Musau'
  },
  {
    postalCode: '6600',
    city: 'Pflach'
  },
  {
    postalCode: '6600',
    city: 'Oberletzen'
  },
  {
    postalCode: '6600',
    city: 'Unterletzen'
  },
  {
    postalCode: '6600',
    city: 'Oberpinswang'
  },
  {
    postalCode: '6600',
    city: 'Unterpinswang'
  },
  {
    postalCode: '6600',
    city: 'Reutte'
  },
  {
    postalCode: '6604',
    city: 'Höfen'
  },
  {
    postalCode: '6604',
    city: 'Platten'
  },
  {
    postalCode: '6604',
    city: 'Hornberg'
  },
  {
    postalCode: '6610',
    city: 'Hinterbichl'
  },
  {
    postalCode: '6610',
    city: 'Holz'
  },
  {
    postalCode: '6610',
    city: 'Wängle'
  },
  {
    postalCode: '6610',
    city: 'Winkl'
  },
  {
    postalCode: '6611',
    city: 'Heiterwang'
  },
  {
    postalCode: '6621',
    city: 'Bichlbächle'
  },
  {
    postalCode: '6621',
    city: 'Kleinstockach'
  },
  {
    postalCode: '6621',
    city: 'Bichlbach'
  },
  {
    postalCode: '6621',
    city: 'Lähn'
  },
  {
    postalCode: '6621',
    city: 'Wengle'
  },
  {
    postalCode: '6622',
    city: 'Berwang'
  },
  {
    postalCode: '6622',
    city: 'Brand'
  },
  {
    postalCode: '6622',
    city: 'Gröben'
  },
  {
    postalCode: '6622',
    city: 'Mitteregg'
  },
  {
    postalCode: '6622',
    city: 'Rinnen'
  },
  {
    postalCode: '6622',
    city: 'Tal'
  },
  {
    postalCode: '6623',
    city: 'Kelmen'
  },
  {
    postalCode: '6623',
    city: 'Namlos'
  },
  {
    postalCode: '6631',
    city: 'Lermoos'
  },
  {
    postalCode: '6631',
    city: 'Obergarten'
  },
  {
    postalCode: '6631',
    city: 'Untergarten'
  },
  {
    postalCode: '6632',
    city: 'Ehrwald'
  },
  {
    postalCode: '6633',
    city: 'Biberwier'
  },
  {
    postalCode: '6642',
    city: 'Stanzach'
  },
  {
    postalCode: '6644',
    city: 'Elmen'
  },
  {
    postalCode: '6644',
    city: 'Klimm'
  },
  {
    postalCode: '6644',
    city: 'Martinau'
  },
  {
    postalCode: '6645',
    city: 'Vorderhornbach'
  },
  {
    postalCode: '6646',
    city: 'Hinterhornbach'
  },
  {
    postalCode: '6647',
    city: 'Boden'
  },
  {
    postalCode: '6647',
    city: 'Bschlabs'
  },
  {
    postalCode: '6650',
    city: 'Gramais'
  },
  {
    postalCode: '6651',
    city: 'Grießau'
  },
  {
    postalCode: '6651',
    city: 'Häselgehr'
  },
  {
    postalCode: '6652',
    city: 'Elbigenalp'
  },
  {
    postalCode: '6652',
    city: 'Köglen'
  },
  {
    postalCode: '6652',
    city: 'Obergrünau'
  },
  {
    postalCode: '6652',
    city: 'Untergiblen'
  },
  {
    postalCode: '6652',
    city: 'Untergrünau'
  },
  {
    postalCode: '6653',
    city: 'Bach'
  },
  {
    postalCode: '6653',
    city: 'Schönau'
  },
  {
    postalCode: '6653',
    city: 'Stockach'
  },
  {
    postalCode: '6653',
    city: 'Obergiblen'
  },
  {
    postalCode: '6654',
    city: 'Schönau'
  },
  {
    postalCode: '6654',
    city: 'Holzgau'
  },
  {
    postalCode: '6655',
    city: 'Kaisers'
  },
  {
    postalCode: '6655',
    city: 'Dickenau'
  },
  {
    postalCode: '6655',
    city: 'Hägerau'
  },
  {
    postalCode: '6655',
    city: 'Hinterellenbogen'
  },
  {
    postalCode: '6655',
    city: 'Steeg'
  },
  {
    postalCode: '6670',
    city: 'Forchach'
  },
  {
    postalCode: '6671',
    city: 'Rieden'
  },
  {
    postalCode: '6671',
    city: 'Weißenbach am Lech'
  },
  {
    postalCode: '6672',
    city: 'Haller'
  },
  {
    postalCode: '6672',
    city: 'Nesselwängle'
  },
  {
    postalCode: '6672',
    city: 'Rauth'
  },
  {
    postalCode: '6672',
    city: 'Gaicht'
  },
  {
    postalCode: '6673',
    city: 'Enge'
  },
  {
    postalCode: '6673',
    city: 'Grän'
  },
  {
    postalCode: '6673',
    city: 'Haldensee'
  },
  {
    postalCode: '6673',
    city: 'Lumberg'
  },
  {
    postalCode: '6675',
    city: 'Berg'
  },
  {
    postalCode: '6675',
    city: 'Bogen'
  },
  {
    postalCode: '6675',
    city: 'Geist'
  },
  {
    postalCode: '6675',
    city: 'Innergschwend'
  },
  {
    postalCode: '6675',
    city: 'Kienzen'
  },
  {
    postalCode: '6675',
    city: 'Kienzerle'
  },
  {
    postalCode: '6675',
    city: 'Schmieden'
  },
  {
    postalCode: '6675',
    city: 'Tannheim'
  },
  {
    postalCode: '6675',
    city: 'Untergschwend'
  },
  {
    postalCode: '6677',
    city: 'Fricken'
  },
  {
    postalCode: '6677',
    city: 'Kappl'
  },
  {
    postalCode: '6677',
    city: 'Rehbach'
  },
  {
    postalCode: '6677',
    city: 'Schattwald'
  },
  {
    postalCode: '6677',
    city: 'Steig'
  },
  {
    postalCode: '6677',
    city: 'Wies'
  },
  {
    postalCode: '6677',
    city: 'Zöblen'
  },
  {
    postalCode: '6682',
    city: 'Vils'
  },
  {
    postalCode: '6691',
    city: 'Jungholz'
  },
  {
    postalCode: '6700',
    city: 'Bings'
  },
  {
    postalCode: '6700',
    city: 'Bludenz'
  },
  {
    postalCode: '6700',
    city: 'Brunnenfeld'
  },
  {
    postalCode: '6700',
    city: 'Furkla'
  },
  {
    postalCode: '6700',
    city: 'Gasünd'
  },
  {
    postalCode: '6700',
    city: 'Rungelin'
  },
  {
    postalCode: '6700',
    city: 'Lorüns'
  },
  {
    postalCode: '6700',
    city: 'Stallehr'
  },
  {
    postalCode: '6706',
    city: 'Bürs'
  },
  {
    postalCode: '6707',
    city: 'Bürserberg'
  },
  {
    postalCode: '6708',
    city: 'Brand'
  },
  {
    postalCode: '6710',
    city: 'Beschling'
  },
  {
    postalCode: '6710',
    city: 'Latz'
  },
  {
    postalCode: '6710',
    city: 'Nenzing'
  },
  {
    postalCode: '6710',
    city: 'Nenzinger Himmel'
  },
  {
    postalCode: '6712',
    city: 'Thüringen'
  },
  {
    postalCode: '6713',
    city: 'Ludesch'
  },
  {
    postalCode: '6713',
    city: 'Ludescherberg'
  },
  {
    postalCode: '6714',
    city: 'Nüziders'
  },
  {
    postalCode: '6719',
    city: 'Bludesch'
  },
  {
    postalCode: '6721',
    city: 'Thüringerberg'
  },
  {
    postalCode: '6722',
    city: 'St. Gerold'
  },
  {
    postalCode: '6723',
    city: 'Blons'
  },
  {
    postalCode: '6731',
    city: 'Boden-Flecken'
  },
  {
    postalCode: '6731',
    city: 'Buchboden'
  },
  {
    postalCode: '6731',
    city: 'Buchholz'
  },
  {
    postalCode: '6731',
    city: 'Litze'
  },
  {
    postalCode: '6731',
    city: 'Seeberg'
  },
  {
    postalCode: '6731',
    city: 'Stein'
  },
  {
    postalCode: '6731',
    city: 'Türtsch'
  },
  {
    postalCode: '6733',
    city: 'Garlitt'
  },
  {
    postalCode: '6733',
    city: 'Fontanella'
  },
  {
    postalCode: '6733',
    city: 'Mittelberg'
  },
  {
    postalCode: '6733',
    city: 'Türtsch'
  },
  {
    postalCode: '6733',
    city: 'Faschina'
  },
  {
    postalCode: '6733',
    city: 'Seewald'
  },
  {
    postalCode: '6733',
    city: 'Säge'
  },
  {
    postalCode: '6741',
    city: 'Litze'
  },
  {
    postalCode: '6741',
    city: 'Marul'
  },
  {
    postalCode: '6741',
    city: 'Raggal'
  },
  {
    postalCode: '6741',
    city: 'Plazera'
  },
  {
    postalCode: '6751',
    city: 'Ausserbraz'
  },
  {
    postalCode: '6751',
    city: 'Grubs'
  },
  {
    postalCode: '6751',
    city: 'Hintergastenz'
  },
  {
    postalCode: '6751',
    city: 'Radin'
  },
  {
    postalCode: '6751',
    city: 'St. Leonhard'
  },
  {
    postalCode: '6751',
    city: 'Innerbraz'
  },
  {
    postalCode: '6752',
    city: 'Dalaas'
  },
  {
    postalCode: '6752',
    city: 'Wald am Arlberg'
  },
  {
    postalCode: '6754',
    city: 'Klösterle'
  },
  {
    postalCode: '6762',
    city: 'Stuben'
  },
  {
    postalCode: '6763',
    city: 'Lech'
  },
  {
    postalCode: '6764',
    city: 'Lech'
  },
  {
    postalCode: '6767',
    city: 'Lechleiten'
  },
  {
    postalCode: '6767',
    city: 'Hochkrumbach'
  },
  {
    postalCode: '6767',
    city: 'Warth'
  },
  {
    postalCode: '6771',
    city: 'St. Anton im Montafon'
  },
  {
    postalCode: '6773',
    city: 'Vandans'
  },
  {
    postalCode: '6774',
    city: 'Tschagguns'
  },
  {
    postalCode: '6780',
    city: 'Bartholomäberg'
  },
  {
    postalCode: '6780',
    city: 'Innerberg'
  },
  {
    postalCode: '6780',
    city: 'Gantschier'
  },
  {
    postalCode: '6780',
    city: 'Schruns'
  },
  {
    postalCode: '6780',
    city: 'Silbertal'
  },
  {
    postalCode: '6787',
    city: 'Gargellen'
  },
  {
    postalCode: '6791',
    city: 'Gortipohl'
  },
  {
    postalCode: '6791',
    city: 'St. Gallenkirch'
  },
  {
    postalCode: '6793',
    city: 'Gaschurn'
  },
  {
    postalCode: '6794',
    city: 'Partenen'
  },
  {
    postalCode: '6800',
    city: 'Feldkirch'
  },
  {
    postalCode: '6811',
    city: 'Göfis'
  },
  {
    postalCode: '6812',
    city: 'Meiningen'
  },
  {
    postalCode: '6820',
    city: 'Fellengatter-Amerlügen'
  },
  {
    postalCode: '6820',
    city: 'Frastafeders'
  },
  {
    postalCode: '6820',
    city: 'Frastanz'
  },
  {
    postalCode: '6820',
    city: 'Gampelün'
  },
  {
    postalCode: '6820',
    city: 'Gurtis'
  },
  {
    postalCode: '6820',
    city: 'Mittelberg'
  },
  {
    postalCode: '6822',
    city: 'Düns'
  },
  {
    postalCode: '6822',
    city: 'Dünserberg'
  },
  {
    postalCode: '6822',
    city: 'Schnifnerberg'
  },
  {
    postalCode: '6822',
    city: 'Röns'
  },
  {
    postalCode: '6822',
    city: 'Satteins'
  },
  {
    postalCode: '6822',
    city: 'Schnifis'
  },
  {
    postalCode: '6824',
    city: 'Röns'
  },
  {
    postalCode: '6824',
    city: 'Schlins'
  },
  {
    postalCode: '6830',
    city: 'Innerlaterns'
  },
  {
    postalCode: '6830',
    city: 'Laterns-Bonacker'
  },
  {
    postalCode: '6830',
    city: 'Laterns'
  },
  {
    postalCode: '6830',
    city: 'Brederis'
  },
  {
    postalCode: '6830',
    city: 'Rankweil'
  },
  {
    postalCode: '6832',
    city: 'Röthis'
  },
  {
    postalCode: '6832',
    city: 'Sulz'
  },
  {
    postalCode: '6833',
    city: 'Fraxern'
  },
  {
    postalCode: '6833',
    city: 'Klaus'
  },
  {
    postalCode: '6833',
    city: 'Weiler'
  },
  {
    postalCode: '6834',
    city: 'Satteins'
  },
  {
    postalCode: '6834',
    city: 'Übersaxen'
  },
  {
    postalCode: '6835',
    city: 'Batschuns'
  },
  {
    postalCode: '6835',
    city: 'Buchebrunnen'
  },
  {
    postalCode: '6835',
    city: 'Dafins'
  },
  {
    postalCode: '6835',
    city: 'Muntlix'
  },
  {
    postalCode: '6836',
    city: 'Viktorsberg'
  },
  {
    postalCode: '6840',
    city: 'Götzis'
  },
  {
    postalCode: '6841',
    city: 'Mäder'
  },
  {
    postalCode: '6842',
    city: 'Koblach'
  },
  {
    postalCode: '6842',
    city: 'Neuburg'
  },
  {
    postalCode: '6842',
    city: 'Udelberg'
  },
  {
    postalCode: '6844',
    city: 'Altach'
  },
  {
    postalCode: '6845',
    city: 'Hohenems'
  },
  {
    postalCode: '6850',
    city: 'Dornbirn'
  },
  {
    postalCode: '6850',
    city: 'Ebnit'
  },
  {
    postalCode: '6850',
    city: 'Schwarzenberg'
  },
  {
    postalCode: '6858',
    city: 'Bildstein'
  },
  {
    postalCode: '6858',
    city: 'Schwarzach'
  },
  {
    postalCode: '6858',
    city: 'Wolfurt'
  },
  {
    postalCode: '6861',
    city: 'Alberschwende'
  },
  {
    postalCode: '6863',
    city: 'Andelsbuch'
  },
  {
    postalCode: '6863',
    city: 'Egg'
  },
  {
    postalCode: '6863',
    city: 'Großdorf'
  },
  {
    postalCode: '6866',
    city: 'Andelsbuch'
  },
  {
    postalCode: '6867',
    city: 'Schwarzenberg'
  },
  {
    postalCode: '6870',
    city: 'Bezau'
  },
  {
    postalCode: '6870',
    city: 'Reuthe'
  },
  {
    postalCode: '6874',
    city: 'Bezau'
  },
  {
    postalCode: '6874',
    city: 'Bizau'
  },
  {
    postalCode: '6881',
    city: 'Mellau'
  },
  {
    postalCode: '6881',
    city: 'Hirschau'
  },
  {
    postalCode: '6882',
    city: 'Hirschau'
  },
  {
    postalCode: '6882',
    city: 'Schnepfau'
  },
  {
    postalCode: '6883',
    city: 'Au'
  },
  {
    postalCode: '6883',
    city: 'Rehmen'
  },
  {
    postalCode: '6884',
    city: 'Damüls'
  },
  {
    postalCode: '6886',
    city: 'Schoppernau'
  },
  {
    postalCode: '6888',
    city: 'Schröcken'
  },
  {
    postalCode: '6890',
    city: 'Lustenau'
  },
  {
    postalCode: '6900',
    city: 'Bregenz'
  },
  {
    postalCode: '6900',
    city: 'Fluh'
  },
  {
    postalCode: '6900',
    city: 'Lochau'
  },
  {
    postalCode: '6900',
    city: 'Möggers'
  },
  {
    postalCode: '6911',
    city: 'Bregenz'
  },
  {
    postalCode: '6911',
    city: 'Eichenberg'
  },
  {
    postalCode: '6911',
    city: 'Lochau'
  },
  {
    postalCode: '6912',
    city: 'Hörbranz'
  },
  {
    postalCode: '6912',
    city: 'Möggers'
  },
  {
    postalCode: '6914',
    city: 'Hohenweiler'
  },
  {
    postalCode: '6921',
    city: 'Kennelbach'
  },
  {
    postalCode: '6922',
    city: 'Wolfurt'
  },
  {
    postalCode: '6923',
    city: 'Lauterach'
  },
  {
    postalCode: '6932',
    city: 'Langen bei Bregenz'
  },
  {
    postalCode: '6933',
    city: 'Doren'
  },
  {
    postalCode: '6934',
    city: 'Sulzberg'
  },
  {
    postalCode: '6934',
    city: 'Thal'
  },
  {
    postalCode: '6941',
    city: 'Oberlangenegg'
  },
  {
    postalCode: '6941',
    city: 'Unterlangenegg'
  },
  {
    postalCode: '6941',
    city: 'Langenegg'
  },
  {
    postalCode: '6942',
    city: 'Hittisau'
  },
  {
    postalCode: '6942',
    city: 'Krumbach'
  },
  {
    postalCode: '6943',
    city: 'Riefensberg'
  },
  {
    postalCode: '6951',
    city: 'Lingenau'
  },
  {
    postalCode: '6952',
    city: 'Bolgenach'
  },
  {
    postalCode: '6952',
    city: 'Hittisau'
  },
  {
    postalCode: '6952',
    city: 'Sibratsgfäll'
  },
  {
    postalCode: '6960',
    city: 'Buch'
  },
  {
    postalCode: '6971',
    city: 'Hard'
  },
  {
    postalCode: '6972',
    city: 'Fußach'
  },
  {
    postalCode: '6973',
    city: 'Fußach'
  },
  {
    postalCode: '6973',
    city: 'Höchst'
  },
  {
    postalCode: '6974',
    city: 'Gaißau'
  },
  {
    postalCode: '6991',
    city: 'Riezlern'
  },
  {
    postalCode: '6992',
    city: 'Hirschegg'
  },
  {
    postalCode: '6993',
    city: 'Mittelberg'
  },
  {
    postalCode: '7000',
    city: 'Eisenstadt'
  },
  {
    postalCode: '7000',
    city: 'Kleinhöflein im Burgenland'
  },
  {
    postalCode: '7000',
    city: 'Sankt Georgen am Leithagebirge'
  },
  {
    postalCode: '7011',
    city: 'Siegendorf '
  },
  {
    postalCode: '7011',
    city: 'Zagersdorf '
  },
  {
    postalCode: '7013',
    city: 'Klingenbach '
  },
  {
    postalCode: '7021',
    city: 'Baumgarten '
  },
  {
    postalCode: '7021',
    city: 'Draßburg '
  },
  {
    postalCode: '7022',
    city: 'Loipersbach im Burgenland'
  },
  {
    postalCode: '7022',
    city: 'Schattendorf'
  },
  {
    postalCode: '7023',
    city: 'Pöttelsdorf'
  },
  {
    postalCode: '7023',
    city: 'Stöttera'
  },
  {
    postalCode: '7023',
    city: 'Zemendorf'
  },
  {
    postalCode: '7024',
    city: 'Hirm'
  },
  {
    postalCode: '7031',
    city: 'Krensdorf'
  },
  {
    postalCode: '7032',
    city: 'Sigleß'
  },
  {
    postalCode: '7033',
    city: 'Pöttsching'
  },
  {
    postalCode: '7033',
    city: 'Zillingtal '
  },
  {
    postalCode: '7034',
    city: 'Zillingtal '
  },
  {
    postalCode: '7035',
    city: 'Steinbrunn '
  },
  {
    postalCode: '7035',
    city: 'Zillingtal '
  },
  {
    postalCode: '7041',
    city: 'Antau '
  },
  {
    postalCode: '7041',
    city: 'Wulkaprodersdorf '
  },
  {
    postalCode: '7051',
    city: 'Großhöflein'
  },
  {
    postalCode: '7052',
    city: 'Müllendorf'
  },
  {
    postalCode: '7053',
    city: 'Hornstein '
  },
  {
    postalCode: '7061',
    city: 'Trausdorf an der Wulka '
  },
  {
    postalCode: '7062',
    city: 'Sankt Margarethen im Burgenland'
  },
  {
    postalCode: '7063',
    city: 'Oggau am Neusiedler See'
  },
  {
    postalCode: '7064',
    city: 'Oslip '
  },
  {
    postalCode: '7071',
    city: 'Rust'
  },
  {
    postalCode: '7072',
    city: 'Mörbisch am See'
  },
  {
    postalCode: '7081',
    city: 'Schützen am Gebirge'
  },
  {
    postalCode: '7082',
    city: 'Donnerskirchen'
  },
  {
    postalCode: '7083',
    city: 'Purbach am Neusiedler See'
  },
  {
    postalCode: '7091',
    city: 'Breitenbrunn'
  },
  {
    postalCode: '7092',
    city: 'Winden am See'
  },
  {
    postalCode: '7093',
    city: 'Jois'
  },
  {
    postalCode: '7100',
    city: 'Neusiedl am See'
  },
  {
    postalCode: '7100',
    city: 'Weiden am See'
  },
  {
    postalCode: '7111',
    city: 'Parndorf '
  },
  {
    postalCode: '7121',
    city: 'Weiden am See'
  },
  {
    postalCode: '7122',
    city: 'Gols'
  },
  {
    postalCode: '7123',
    city: 'Mönchhof'
  },
  {
    postalCode: '7131',
    city: 'Halbturn'
  },
  {
    postalCode: '7132',
    city: 'Apetlon'
  },
  {
    postalCode: '7132',
    city: 'Frauenkirchen'
  },
  {
    postalCode: '7141',
    city: 'Podersdorf am See'
  },
  {
    postalCode: '7142',
    city: 'Illmitz'
  },
  {
    postalCode: '7143',
    city: 'Apetlon'
  },
  {
    postalCode: '7151',
    city: 'Wallern im Burgenland'
  },
  {
    postalCode: '7152',
    city: 'Pamhagen'
  },
  {
    postalCode: '7152',
    city: 'Wallern im Burgenland'
  },
  {
    postalCode: '7161',
    city: 'Apetlon'
  },
  {
    postalCode: '7161',
    city: 'Sankt Andrä am Zicksee'
  },
  {
    postalCode: '7162',
    city: 'Tadten'
  },
  {
    postalCode: '7163',
    city: 'Andau'
  },
  {
    postalCode: '7201',
    city: 'Neudörfl'
  },
  {
    postalCode: '7202',
    city: 'Bad Sauerbrunn'
  },
  {
    postalCode: '7202',
    city: 'Lichtenwörth'
  },
  {
    postalCode: '7202',
    city: 'Pöttsching'
  },
  {
    postalCode: '7202',
    city: 'Wiesen'
  },
  {
    postalCode: '7203',
    city: 'Wiesen'
  },
  {
    postalCode: '7210',
    city: 'Mattersburg'
  },
  {
    postalCode: '7210',
    city: 'Walbersdorf'
  },
  {
    postalCode: '7212',
    city: 'Forchtenstein'
  },
  {
    postalCode: '7212',
    city: 'Ofenbach'
  },
  {
    postalCode: '7221',
    city: 'Marz'
  },
  {
    postalCode: '7222',
    city: 'Rohrbach bei Mattersburg'
  },
  {
    postalCode: '7223',
    city: 'Sieggraben'
  },
  {
    postalCode: '7301',
    city: 'Deutschkreutz'
  },
  {
    postalCode: '7301',
    city: 'Girm'
  },
  {
    postalCode: '7302',
    city: 'Kroatisch Minihof '
  },
  {
    postalCode: '7302',
    city: 'Nikitsch '
  },
  {
    postalCode: '7304',
    city: 'Großwarasdorf '
  },
  {
    postalCode: '7304',
    city: 'Kleinwarasdorf '
  },
  {
    postalCode: '7304',
    city: 'Langental '
  },
  {
    postalCode: '7304',
    city: 'Nebersdorf '
  },
  {
    postalCode: '7304',
    city: 'Mitterpullendorf'
  },
  {
    postalCode: '7311',
    city: 'Haschendorf'
  },
  {
    postalCode: '7311',
    city: 'Neckenmarkt'
  },
  {
    postalCode: '7312',
    city: 'Horitschon'
  },
  {
    postalCode: '7312',
    city: 'Unterpetersdorf'
  },
  {
    postalCode: '7321',
    city: 'Lackendorf'
  },
  {
    postalCode: '7321',
    city: 'Raiding'
  },
  {
    postalCode: '7321',
    city: 'Unterfrauenhaid'
  },
  {
    postalCode: '7322',
    city: 'Lackenbach'
  },
  {
    postalCode: '7323',
    city: 'Ritzing'
  },
  {
    postalCode: '7331',
    city: 'Kalkgruben'
  },
  {
    postalCode: '7331',
    city: 'Tschurndorf'
  },
  {
    postalCode: '7331',
    city: 'Weppersdorf'
  },
  {
    postalCode: '7332',
    city: 'Kalkgruben'
  },
  {
    postalCode: '7332',
    city: 'Kobersdorf'
  },
  {
    postalCode: '7332',
    city: 'Oberpetersdorf'
  },
  {
    postalCode: '7332',
    city: 'Tschurndorf'
  },
  {
    postalCode: '7341',
    city: 'Lindgraben'
  },
  {
    postalCode: '7341',
    city: 'Landsee'
  },
  {
    postalCode: '7341',
    city: 'Markt Sankt Martin'
  },
  {
    postalCode: '7341',
    city: 'Neudorf bei Landsee'
  },
  {
    postalCode: '7342',
    city: 'Kaisersdorf '
  },
  {
    postalCode: '7343',
    city: 'Neutal'
  },
  {
    postalCode: '7344',
    city: 'Stoob'
  },
  {
    postalCode: '7350',
    city: 'Mitterpullendorf'
  },
  {
    postalCode: '7350',
    city: 'Oberpullendorf '
  },
  {
    postalCode: '7350',
    city: 'Stoob'
  },
  {
    postalCode: '7361',
    city: 'Frankenau '
  },
  {
    postalCode: '7361',
    city: 'Lutzmannsburg'
  },
  {
    postalCode: '7361',
    city: 'Kroatisch Geresdorf '
  },
  {
    postalCode: '7361',
    city: 'Strebersdorf'
  },
  {
    postalCode: '7371',
    city: 'Oberrabnitz'
  },
  {
    postalCode: '7371',
    city: 'Piringsdorf'
  },
  {
    postalCode: '7371',
    city: 'Schwendgraben'
  },
  {
    postalCode: '7371',
    city: 'Unterrabnitz'
  },
  {
    postalCode: '7372',
    city: 'Draßmarkt'
  },
  {
    postalCode: '7372',
    city: 'Karl'
  },
  {
    postalCode: '7372',
    city: 'Weingraben '
  },
  {
    postalCode: '7400',
    city: 'Unterschützen'
  },
  {
    postalCode: '7400',
    city: 'Oberwart '
  },
  {
    postalCode: '7400',
    city: 'Sankt Martin in der Wart'
  },
  {
    postalCode: '7400',
    city: 'Drumling'
  },
  {
    postalCode: '7411',
    city: 'Kitzladen'
  },
  {
    postalCode: '7411',
    city: 'Loipersdorf im Burgenland'
  },
  {
    postalCode: '7411',
    city: 'Buchschachen'
  },
  {
    postalCode: '7411',
    city: 'Markt Allhau'
  },
  {
    postalCode: '7412',
    city: 'Wolfau'
  },
  {
    postalCode: '7421',
    city: 'Mönichkirchen'
  },
  {
    postalCode: '7421',
    city: 'Steirisch-Tauchen'
  },
  {
    postalCode: '7421',
    city: 'Wiesenhöf'
  },
  {
    postalCode: '7421',
    city: 'Anger'
  },
  {
    postalCode: '7421',
    city: 'Spital'
  },
  {
    postalCode: '7422',
    city: 'Riedlingsdorf'
  },
  {
    postalCode: '7423',
    city: 'Grafenschachen'
  },
  {
    postalCode: '7423',
    city: 'Kroisegg'
  },
  {
    postalCode: '7423',
    city: 'Neustift an der Lafnitz'
  },
  {
    postalCode: '7423',
    city: 'Sinnersdorf'
  },
  {
    postalCode: '7423',
    city: 'Hochart'
  },
  {
    postalCode: '7423',
    city: 'Pinkafeld'
  },
  {
    postalCode: '7423',
    city: 'Schönherrn'
  },
  {
    postalCode: '7423',
    city: 'Schreibersdorf'
  },
  {
    postalCode: '7423',
    city: 'Weinberg im Burgenland'
  },
  {
    postalCode: '7423',
    city: 'Wiesfleck'
  },
  {
    postalCode: '7431',
    city: 'Bad Tatzmannsdorf'
  },
  {
    postalCode: '7431',
    city: 'Jormannsdorf'
  },
  {
    postalCode: '7431',
    city: 'Sulzriegel'
  },
  {
    postalCode: '7431',
    city: 'Oberschützen'
  },
  {
    postalCode: '7432',
    city: 'Aschau im Burgenland'
  },
  {
    postalCode: '7432',
    city: 'Oberschützen'
  },
  {
    postalCode: '7432',
    city: 'Schmiedrait'
  },
  {
    postalCode: '7432',
    city: 'Willersdorf'
  },
  {
    postalCode: '7433',
    city: 'Bergwerk'
  },
  {
    postalCode: '7433',
    city: 'Grodnau'
  },
  {
    postalCode: '7433',
    city: 'Mariasdorf'
  },
  {
    postalCode: '7433',
    city: 'Neustift bei Schlaining'
  },
  {
    postalCode: '7433',
    city: 'Tauchen'
  },
  {
    postalCode: '7434',
    city: 'Bernstein'
  },
  {
    postalCode: '7434',
    city: 'Dreihütten'
  },
  {
    postalCode: '7434',
    city: 'Redlschlag'
  },
  {
    postalCode: '7434',
    city: 'Rettenbach'
  },
  {
    postalCode: '7434',
    city: 'Stuben'
  },
  {
    postalCode: '7434',
    city: 'Holzschlag'
  },
  {
    postalCode: '7435',
    city: 'Glashütten bei Schlaining'
  },
  {
    postalCode: '7435',
    city: 'Günseck'
  },
  {
    postalCode: '7435',
    city: 'Holzschlag'
  },
  {
    postalCode: '7435',
    city: 'Oberkohlstätten'
  },
  {
    postalCode: '7435',
    city: 'Unterkohlstätten'
  },
  {
    postalCode: '7441',
    city: 'Bubendorf im Burgenland'
  },
  {
    postalCode: '7441',
    city: 'Deutsch Gerisdorf'
  },
  {
    postalCode: '7441',
    city: 'Kogl im Burgenland'
  },
  {
    postalCode: '7441',
    city: 'Lebenbrunn'
  },
  {
    postalCode: '7441',
    city: 'Pilgersdorf'
  },
  {
    postalCode: '7441',
    city: 'Salmannsdorf'
  },
  {
    postalCode: '7441',
    city: 'Steinbach im Burgenland'
  },
  {
    postalCode: '7442',
    city: 'Glashütten bei Langeck im Burgenland'
  },
  {
    postalCode: '7442',
    city: 'Hammerteich'
  },
  {
    postalCode: '7442',
    city: 'Hochstraß'
  },
  {
    postalCode: '7442',
    city: 'Langeck im Burgenland'
  },
  {
    postalCode: '7442',
    city: 'Lockenhaus'
  },
  {
    postalCode: '7443',
    city: 'Liebing'
  },
  {
    postalCode: '7443',
    city: 'Rattersdorf'
  },
  {
    postalCode: '7444',
    city: 'Klostermarienberg'
  },
  {
    postalCode: '7444',
    city: 'Mannersdorf an der Rabnitz'
  },
  {
    postalCode: '7444',
    city: 'Unterloisdorf'
  },
  {
    postalCode: '7451',
    city: 'Oberloisdorf'
  },
  {
    postalCode: '7452',
    city: 'Großmutschen '
  },
  {
    postalCode: '7452',
    city: 'Kleinmutschen '
  },
  {
    postalCode: '7452',
    city: 'Unterpullendorf '
  },
  {
    postalCode: '7453',
    city: 'Dörfl'
  },
  {
    postalCode: '7453',
    city: 'Steinberg'
  },
  {
    postalCode: '7461',
    city: 'Altschlaining'
  },
  {
    postalCode: '7461',
    city: 'Goberling'
  },
  {
    postalCode: '7461',
    city: 'Neumarkt im Tauchental'
  },
  {
    postalCode: '7461',
    city: 'Stadtschlaining'
  },
  {
    postalCode: '7461',
    city: 'Allersdorf im Burgenland '
  },
  {
    postalCode: '7461',
    city: 'Allersgraben '
  },
  {
    postalCode: '7461',
    city: 'Mönchmeierhof '
  },
  {
    postalCode: '7461',
    city: 'Podler '
  },
  {
    postalCode: '7461',
    city: 'Rauhriegel '
  },
  {
    postalCode: '7463',
    city: 'Oberpodgoria '
  },
  {
    postalCode: '7463',
    city: 'Rumpersdorf '
  },
  {
    postalCode: '7463',
    city: 'Unterpodgoria '
  },
  {
    postalCode: '7463',
    city: 'Weiden bei Rechnitz '
  },
  {
    postalCode: '7463',
    city: 'Zuberbach '
  },
  {
    postalCode: '7464',
    city: 'Althodis '
  },
  {
    postalCode: '7464',
    city: 'Markt Neuhodis'
  },
  {
    postalCode: '7471',
    city: 'Rechnitz'
  },
  {
    postalCode: '7472',
    city: 'Dürnbach im Burgenland '
  },
  {
    postalCode: '7472',
    city: 'Schachendorf '
  },
  {
    postalCode: '7472',
    city: 'Schandorf '
  },
  {
    postalCode: '7473',
    city: 'Burg'
  },
  {
    postalCode: '7473',
    city: 'Hannersdorf'
  },
  {
    postalCode: '7473',
    city: 'Woppendorf'
  },
  {
    postalCode: '7474',
    city: 'Deutsch-Schützen'
  },
  {
    postalCode: '7474',
    city: 'Edlitz im Burgenland'
  },
  {
    postalCode: '7474',
    city: 'Eisenberg an der Pinka'
  },
  {
    postalCode: '7474',
    city: 'Höll'
  },
  {
    postalCode: '7474',
    city: 'Sankt Kathrein im Burgenland'
  },
  {
    postalCode: '7474',
    city: 'Burg'
  },
  {
    postalCode: '7501',
    city: 'Oberdorf im Burgenland'
  },
  {
    postalCode: '7501',
    city: 'Rotenturm an der Pinka'
  },
  {
    postalCode: '7501',
    city: 'Siget in der Wart '
  },
  {
    postalCode: '7501',
    city: 'Spitzzicken '
  },
  {
    postalCode: '7501',
    city: 'Eisenzicken'
  },
  {
    postalCode: '7501',
    city: 'Unterwart '
  },
  {
    postalCode: '7503',
    city: 'Großpetersdorf'
  },
  {
    postalCode: '7503',
    city: 'Kleinpetersdorf'
  },
  {
    postalCode: '7503',
    city: 'Kleinzicken'
  },
  {
    postalCode: '7503',
    city: 'Miedlingsdorf'
  },
  {
    postalCode: '7503',
    city: 'Welgersdorf'
  },
  {
    postalCode: '7503',
    city: 'Jabing'
  },
  {
    postalCode: '7503',
    city: 'Dürnbach im Burgenland '
  },
  {
    postalCode: '7503',
    city: 'Neumarkt im Tauchental'
  },
  {
    postalCode: '7503',
    city: 'Zuberbach '
  },
  {
    postalCode: '7511',
    city: 'Großbachselten'
  },
  {
    postalCode: '7511',
    city: 'Kleinbachselten'
  },
  {
    postalCode: '7511',
    city: 'Kotezicken'
  },
  {
    postalCode: '7511',
    city: 'Mischendorf'
  },
  {
    postalCode: '7511',
    city: 'Neuhaus in der Wart'
  },
  {
    postalCode: '7511',
    city: 'Rohrbach an der Teich'
  },
  {
    postalCode: '7512',
    city: 'Badersdorf'
  },
  {
    postalCode: '7512',
    city: 'Güttenbach '
  },
  {
    postalCode: '7512',
    city: 'Harmisch'
  },
  {
    postalCode: '7512',
    city: 'Kirchfidisch'
  },
  {
    postalCode: '7512',
    city: 'Kohfidisch'
  },
  {
    postalCode: '7521',
    city: 'Oberbildein'
  },
  {
    postalCode: '7521',
    city: 'Unterbildein'
  },
  {
    postalCode: '7521',
    city: 'Eberau'
  },
  {
    postalCode: '7521',
    city: 'Gaas'
  },
  {
    postalCode: '7521',
    city: 'Kulm im Burgenland'
  },
  {
    postalCode: '7521',
    city: 'Winten'
  },
  {
    postalCode: '7522',
    city: 'Kroatisch Ehrensdorf'
  },
  {
    postalCode: '7522',
    city: 'Deutsch Bieling'
  },
  {
    postalCode: '7522',
    city: 'Hagensdorf im Burgenland'
  },
  {
    postalCode: '7522',
    city: 'Heiligenbrunn'
  },
  {
    postalCode: '7522',
    city: 'Luising'
  },
  {
    postalCode: '7522',
    city: 'Reinersdorf'
  },
  {
    postalCode: '7522',
    city: 'Deutsch Ehrensdorf'
  },
  {
    postalCode: '7522',
    city: 'Steinfurt'
  },
  {
    postalCode: '7522',
    city: 'Strem'
  },
  {
    postalCode: '7522',
    city: 'Sumetendorf'
  },
  {
    postalCode: '7531',
    city: 'Kemeten'
  },
  {
    postalCode: '7532',
    city: 'Litzelsdorf'
  },
  {
    postalCode: '7533',
    city: 'Ollersdorf im Burgenland'
  },
  {
    postalCode: '7534',
    city: 'Olbendorf'
  },
  {
    postalCode: '7534',
    city: 'Rauchwart'
  },
  {
    postalCode: '7535',
    city: 'Güttenbach '
  },
  {
    postalCode: '7535',
    city: 'Kemeten'
  },
  {
    postalCode: '7535',
    city: 'Neuberg im Burgenland '
  },
  {
    postalCode: '7535',
    city: 'Rauchwart'
  },
  {
    postalCode: '7535',
    city: 'Gamischdorf'
  },
  {
    postalCode: '7535',
    city: 'Sankt Michael im Burgenland'
  },
  {
    postalCode: '7535',
    city: 'Schallendorf im Burgenland'
  },
  {
    postalCode: '7535',
    city: 'Deutsch Tschantschendorf'
  },
  {
    postalCode: '7535',
    city: 'Kroatisch Tschantschendorf'
  },
  {
    postalCode: '7535',
    city: 'Punitz'
  },
  {
    postalCode: '7535',
    city: 'Tudersdorf'
  },
  {
    postalCode: '7540',
    city: 'Rehgraben'
  },
  {
    postalCode: '7540',
    city: 'Sulz im Burgenland'
  },
  {
    postalCode: '7540',
    city: 'Großmürbisch'
  },
  {
    postalCode: '7540',
    city: 'Glasing'
  },
  {
    postalCode: '7540',
    city: 'Güssing'
  },
  {
    postalCode: '7540',
    city: 'Krottendorf'
  },
  {
    postalCode: '7540',
    city: 'Sankt Nikolaus'
  },
  {
    postalCode: '7540',
    city: 'Urbersdorf'
  },
  {
    postalCode: '7540',
    city: 'Inzenhof'
  },
  {
    postalCode: '7540',
    city: 'Kleinmürbisch'
  },
  {
    postalCode: '7540',
    city: 'Moschendorf'
  },
  {
    postalCode: '7540',
    city: 'Neusiedl'
  },
  {
    postalCode: '7540',
    city: 'Neustift bei Güssing'
  },
  {
    postalCode: '7540',
    city: 'Hasendorf im Burgenland'
  },
  {
    postalCode: '7540',
    city: 'Punitz'
  },
  {
    postalCode: '7540',
    city: 'Tobaj'
  },
  {
    postalCode: '7540',
    city: 'Tschanigraben'
  },
  {
    postalCode: '7542',
    city: 'Gerersdorf bei Güssing'
  },
  {
    postalCode: '7542',
    city: 'Sulz im Burgenland'
  },
  {
    postalCode: '7542',
    city: 'Steingraben'
  },
  {
    postalCode: '7543',
    city: 'Eisenhüttl'
  },
  {
    postalCode: '7543',
    city: 'Kukmirn'
  },
  {
    postalCode: '7543',
    city: 'Limbach'
  },
  {
    postalCode: '7543',
    city: 'Neusiedl'
  },
  {
    postalCode: '7551',
    city: 'Bocksdorf'
  },
  {
    postalCode: '7551',
    city: 'Heugraben'
  },
  {
    postalCode: '7551',
    city: 'Rohr im Burgenland'
  },
  {
    postalCode: '7551',
    city: 'Stegersbach'
  },
  {
    postalCode: '7552',
    city: 'Stinatz '
  },
  {
    postalCode: '7561',
    city: 'Heiligenkreuz im Lafnitztal'
  },
  {
    postalCode: '7561',
    city: 'Poppendorf im Burgenland'
  },
  {
    postalCode: '7562',
    city: 'Eltendorf'
  },
  {
    postalCode: '7562',
    city: 'Zahling'
  },
  {
    postalCode: '7563',
    city: 'Königsdorf'
  },
  {
    postalCode: '7564',
    city: 'Dobersdorf'
  },
  {
    postalCode: '7564',
    city: 'Rudersdorf'
  },
  {
    postalCode: '7571',
    city: 'Rudersdorf'
  },
  {
    postalCode: '7572',
    city: 'Deutsch Kaltenbrunn'
  },
  {
    postalCode: '7572',
    city: 'Rohrbrunn'
  },
  {
    postalCode: '8010',
    city: 'Edelsbach bei Graz'
  },
  {
    postalCode: '8010',
    city: 'Graz'
  },
  {
    postalCode: '8010',
    city: 'Hönigtal'
  },
  {
    postalCode: '8010',
    city: 'Kainbach'
  },
  {
    postalCode: '8010',
    city: 'Schaftal'
  },
  {
    postalCode: '8020',
    city: 'Graz'
  },
  {
    postalCode: '8036',
    city: 'Graz'
  },
  {
    postalCode: '8041',
    city: 'Thondorf'
  },
  {
    postalCode: '8041',
    city: 'Graz'
  },
  {
    postalCode: '8042',
    city: 'Graz'
  },
  {
    postalCode: '8043',
    city: 'Graz'
  },
  {
    postalCode: '8044',
    city: 'Graz'
  },
  {
    postalCode: '8044',
    city: 'Purgstall bei Eggersdorf'
  },
  {
    postalCode: '8044',
    city: 'Schaftal'
  },
  {
    postalCode: '8044',
    city: 'Rabnitz'
  },
  {
    postalCode: '8044',
    city: 'Fölling'
  },
  {
    postalCode: '8044',
    city: 'Niederschöckl'
  },
  {
    postalCode: '8044',
    city: 'Oberschöckl'
  },
  {
    postalCode: '8044',
    city: 'Weinitzen'
  },
  {
    postalCode: '8045',
    city: 'Graz'
  },
  {
    postalCode: '8045',
    city: 'Buch'
  },
  {
    postalCode: '8045',
    city: 'Hochgreit'
  },
  {
    postalCode: '8045',
    city: 'Hohenberg'
  },
  {
    postalCode: '8045',
    city: 'Kalkleiten'
  },
  {
    postalCode: '8045',
    city: 'Oberschöckl'
  },
  {
    postalCode: '8045',
    city: 'Weinitzen'
  },
  {
    postalCode: '8046',
    city: 'Sankt Veit'
  },
  {
    postalCode: '8046',
    city: 'Graz'
  },
  {
    postalCode: '8046',
    city: 'Eichberg'
  },
  {
    postalCode: '8046',
    city: 'Hochgreit'
  },
  {
    postalCode: '8046',
    city: 'Hub'
  },
  {
    postalCode: '8046',
    city: 'Krail'
  },
  {
    postalCode: '8046',
    city: 'Leber'
  },
  {
    postalCode: '8046',
    city: 'Mühl'
  },
  {
    postalCode: '8046',
    city: 'Neudorf'
  },
  {
    postalCode: '8046',
    city: 'Rannach'
  },
  {
    postalCode: '8046',
    city: 'Steingraben'
  },
  {
    postalCode: '8046',
    city: 'Ursprung'
  },
  {
    postalCode: '8047',
    city: 'Graz'
  },
  {
    postalCode: '8047',
    city: 'Hart bei Graz'
  },
  {
    postalCode: '8047',
    city: 'Hönigtal'
  },
  {
    postalCode: '8047',
    city: 'Kainbach'
  },
  {
    postalCode: '8051',
    city: 'Graz'
  },
  {
    postalCode: '8051',
    city: 'Thal'
  },
  {
    postalCode: '8052',
    city: 'Mantscha'
  },
  {
    postalCode: '8052',
    city: 'Graz'
  },
  {
    postalCode: '8052',
    city: 'Oberberg'
  },
  {
    postalCode: '8052',
    city: 'Steinberg'
  },
  {
    postalCode: '8052',
    city: 'Thal'
  },
  {
    postalCode: '8053',
    city: 'Graz'
  },
  {
    postalCode: '8054',
    city: 'Mantscha'
  },
  {
    postalCode: '8054',
    city: 'Mühlriegl'
  },
  {
    postalCode: '8054',
    city: 'Riederhof'
  },
  {
    postalCode: '8054',
    city: 'Graz'
  },
  {
    postalCode: '8054',
    city: 'Pirka'
  },
  {
    postalCode: '8054',
    city: 'Windorf'
  },
  {
    postalCode: '8054',
    city: 'Neupirka'
  },
  {
    postalCode: '8054',
    city: 'Seiersberg'
  },
  {
    postalCode: '8055',
    city: 'Graz'
  },
  {
    postalCode: '8055',
    city: 'Seiersberg'
  },
  {
    postalCode: '8061',
    city: 'Rabnitz'
  },
  {
    postalCode: '8061',
    city: 'Willersdorf'
  },
  {
    postalCode: '8061',
    city: 'Kickenheim'
  },
  {
    postalCode: '8061',
    city: 'Diepoltsberg'
  },
  {
    postalCode: '8061',
    city: 'Ebersdorf'
  },
  {
    postalCode: '8061',
    city: 'Rinnegg'
  },
  {
    postalCode: '8061',
    city: 'Sankt Radegund bei Graz'
  },
  {
    postalCode: '8061',
    city: 'Schöckl'
  },
  {
    postalCode: '8061',
    city: 'Plenzengreith'
  },
  {
    postalCode: '8061',
    city: 'Stenzengreith'
  },
  {
    postalCode: '8061',
    city: 'Stockheim'
  },
  {
    postalCode: '8061',
    city: 'Oberschöckl'
  },
  {
    postalCode: '8062',
    city: 'Hart bei Eggersdorf'
  },
  {
    postalCode: '8062',
    city: 'Gschwendt'
  },
  {
    postalCode: '8062',
    city: 'Hofstätten'
  },
  {
    postalCode: '8062',
    city: 'Kumberg'
  },
  {
    postalCode: '8062',
    city: 'Rabnitz'
  },
  {
    postalCode: '8062',
    city: 'Niederschöckl'
  },
  {
    postalCode: '8063',
    city: 'Brodersdorf'
  },
  {
    postalCode: '8063',
    city: 'Brodingberg'
  },
  {
    postalCode: '8063',
    city: 'Haselbach'
  },
  {
    postalCode: '8063',
    city: 'Edelsbach bei Graz'
  },
  {
    postalCode: '8063',
    city: 'Eggersdorf bei Graz'
  },
  {
    postalCode: '8063',
    city: 'Hart bei Eggersdorf'
  },
  {
    postalCode: '8063',
    city: 'Purgstall bei Eggersdorf'
  },
  {
    postalCode: '8063',
    city: 'Höf'
  },
  {
    postalCode: '8063',
    city: 'Präbach'
  },
  {
    postalCode: '8063',
    city: 'Hönigtal'
  },
  {
    postalCode: '8063',
    city: 'Rabnitz'
  },
  {
    postalCode: '8063',
    city: 'Pircha'
  },
  {
    postalCode: '8063',
    city: 'Mitterlaßnitz'
  },
  {
    postalCode: '8071',
    city: 'Dörfla'
  },
  {
    postalCode: '8071',
    city: 'Gössendorf'
  },
  {
    postalCode: '8071',
    city: 'Grambach'
  },
  {
    postalCode: '8071',
    city: 'Berndorf'
  },
  {
    postalCode: '8071',
    city: 'Hausmannstätten'
  },
  {
    postalCode: '8071',
    city: 'Dürwagersbach'
  },
  {
    postalCode: '8071',
    city: 'Raaba'
  },
  {
    postalCode: '8071',
    city: 'Breitenhilm'
  },
  {
    postalCode: '8071',
    city: 'Premstätten'
  },
  {
    postalCode: '8071',
    city: 'Wagersbach'
  },
  {
    postalCode: '8071',
    city: 'Vasoldsberg'
  },
  {
    postalCode: '8071',
    city: 'Wagersfeld'
  },
  {
    postalCode: '8072',
    city: 'Fernitz'
  },
  {
    postalCode: '8072',
    city: 'Gnaning'
  },
  {
    postalCode: '8072',
    city: 'Gössendorf'
  },
  {
    postalCode: '8072',
    city: 'Dillach'
  },
  {
    postalCode: '8072',
    city: 'Enzelsdorf'
  },
  {
    postalCode: '8072',
    city: 'Mellach'
  },
  {
    postalCode: '8072',
    city: 'Sankt Ulrich am Waasen'
  },
  {
    postalCode: '8072',
    city: 'Wutschdorf'
  },
  {
    postalCode: '8073',
    city: 'Abtissendorf'
  },
  {
    postalCode: '8073',
    city: 'Feldkirchen bei Graz'
  },
  {
    postalCode: '8073',
    city: 'Lebern'
  },
  {
    postalCode: '8073',
    city: 'Wagnitz'
  },
  {
    postalCode: '8073',
    city: 'Graz'
  },
  {
    postalCode: '8073',
    city: 'Forst'
  },
  {
    postalCode: '8073',
    city: 'Pirka'
  },
  {
    postalCode: '8073',
    city: 'Windorf'
  },
  {
    postalCode: '8073',
    city: 'Neupirka'
  },
  {
    postalCode: '8073',
    city: 'Neuwindorf'
  },
  {
    postalCode: '8073',
    city: 'Seiersberg'
  },
  {
    postalCode: '8074',
    city: 'Grambach'
  },
  {
    postalCode: '8074',
    city: 'Graz'
  },
  {
    postalCode: '8074',
    city: 'Dürwagersbach'
  },
  {
    postalCode: '8074',
    city: 'Raaba'
  },
  {
    postalCode: '8074',
    city: 'Lamberg'
  },
  {
    postalCode: '8074',
    city: 'Wagersbach'
  },
  {
    postalCode: '8075',
    city: 'Hart bei Graz'
  },
  {
    postalCode: '8075',
    city: 'Laßnitzhöhe'
  },
  {
    postalCode: '8081',
    city: 'Allerheiligen bei Wildon'
  },
  {
    postalCode: '8081',
    city: 'Kleinfeiting'
  },
  {
    postalCode: '8081',
    city: 'Edelstauden'
  },
  {
    postalCode: '8081',
    city: 'Empersdorf'
  },
  {
    postalCode: '8081',
    city: 'Liebensdorf'
  },
  {
    postalCode: '8081',
    city: 'Frannach'
  },
  {
    postalCode: '8081',
    city: 'Manning'
  },
  {
    postalCode: '8081',
    city: 'Oberlabill'
  },
  {
    postalCode: '8081',
    city: 'Felgitsch'
  },
  {
    postalCode: '8081',
    city: 'Heiligenkreuz am Waasen'
  },
  {
    postalCode: '8081',
    city: 'Kohldorf'
  },
  {
    postalCode: '8081',
    city: 'Guggitzgraben'
  },
  {
    postalCode: '8081',
    city: 'Kittenbach'
  },
  {
    postalCode: '8081',
    city: 'Oberdorf'
  },
  {
    postalCode: '8081',
    city: 'Pirching am Traubenberg'
  },
  {
    postalCode: '8081',
    city: 'Rettenbach in Oststeiermark'
  },
  {
    postalCode: '8081',
    city: 'Sankt Ulrich am Waasen'
  },
  {
    postalCode: '8081',
    city: 'Wutschdorf'
  },
  {
    postalCode: '8081',
    city: 'Breitenhilm'
  },
  {
    postalCode: '8081',
    city: 'Weißenbach'
  },
  {
    postalCode: '8082',
    city: 'Glatzau'
  },
  {
    postalCode: '8082',
    city: 'Kirchbach in Steiermark'
  },
  {
    postalCode: '8082',
    city: 'Kleinfrannach'
  },
  {
    postalCode: '8082',
    city: 'Maierhofen'
  },
  {
    postalCode: '8082',
    city: 'Tagensdorf'
  },
  {
    postalCode: '8082',
    city: 'Ziprein'
  },
  {
    postalCode: '8082',
    city: 'Tiefernitz'
  },
  {
    postalCode: '8082',
    city: 'Petersdorf '
  },
  {
    postalCode: '8082',
    city: 'Oberdorf'
  },
  {
    postalCode: '8082',
    city: 'Gigging'
  },
  {
    postalCode: '8082',
    city: 'Lichendorf'
  },
  {
    postalCode: '8082',
    city: 'Sankt Stefan im Rosental'
  },
  {
    postalCode: '8082',
    city: 'Trössengraben'
  },
  {
    postalCode: '8082',
    city: 'Breitenbuch'
  },
  {
    postalCode: '8082',
    city: 'Dörfla'
  },
  {
    postalCode: '8082',
    city: 'Kittenbach'
  },
  {
    postalCode: '8082',
    city: 'Maxendorf'
  },
  {
    postalCode: '8082',
    city: 'Weißenbach'
  },
  {
    postalCode: '8082',
    city: 'Zerlach'
  },
  {
    postalCode: '8083',
    city: 'Wörth'
  },
  {
    postalCode: '8083',
    city: 'Hamet'
  },
  {
    postalCode: '8083',
    city: 'Wetzelsdorf bei Jagerberg'
  },
  {
    postalCode: '8083',
    city: 'Maierhofen'
  },
  {
    postalCode: '8083',
    city: 'Aschau am Ottersbach'
  },
  {
    postalCode: '8083',
    city: 'Dollrath'
  },
  {
    postalCode: '8083',
    city: 'Frauenbach'
  },
  {
    postalCode: '8083',
    city: 'Höllgrund'
  },
  {
    postalCode: '8083',
    city: 'Krottendorf im Saßtal'
  },
  {
    postalCode: '8083',
    city: 'Lichendorf'
  },
  {
    postalCode: '8083',
    city: 'Lichtenegg'
  },
  {
    postalCode: '8083',
    city: 'Pölzengraben'
  },
  {
    postalCode: '8083',
    city: 'Reichersdorf'
  },
  {
    postalCode: '8083',
    city: 'Sankt Stefan im Rosental'
  },
  {
    postalCode: '8083',
    city: 'Tagensdorf'
  },
  {
    postalCode: '8091',
    city: 'Aug'
  },
  {
    postalCode: '8091',
    city: 'Grasdorf'
  },
  {
    postalCode: '8091',
    city: 'Jagerberg'
  },
  {
    postalCode: '8091',
    city: 'Jahrbach'
  },
  {
    postalCode: '8091',
    city: 'Lugitsch'
  },
  {
    postalCode: '8091',
    city: 'Oberzirknitz'
  },
  {
    postalCode: '8091',
    city: 'Pöllau'
  },
  {
    postalCode: '8091',
    city: 'Ungerdorf'
  },
  {
    postalCode: '8091',
    city: 'Unterzirknitz'
  },
  {
    postalCode: '8091',
    city: 'Aschau am Ottersbach'
  },
  {
    postalCode: '8091',
    city: 'Sankt Stefan im Rosental'
  },
  {
    postalCode: '8092',
    city: 'Landorf'
  },
  {
    postalCode: '8092',
    city: 'Mettersdorf am Saßbach'
  },
  {
    postalCode: '8092',
    city: 'Rannersdorf am Saßbach'
  },
  {
    postalCode: '8092',
    city: 'Rohrbach am Rosenberg'
  },
  {
    postalCode: '8092',
    city: 'Zehensdorf'
  },
  {
    postalCode: '8093',
    city: 'Bierbaum am Auersbach'
  },
  {
    postalCode: '8093',
    city: 'Dietersdorf am Gnasbach'
  },
  {
    postalCode: '8093',
    city: 'Mettersdorf am Saßbach'
  },
  {
    postalCode: '8093',
    city: 'Rohrbach am Rosenberg'
  },
  {
    postalCode: '8093',
    city: 'Zehensdorf'
  },
  {
    postalCode: '8093',
    city: 'Edla'
  },
  {
    postalCode: '8093',
    city: 'Entschendorf am Ottersbach'
  },
  {
    postalCode: '8093',
    city: 'Oberrosenberg'
  },
  {
    postalCode: '8093',
    city: 'Perbersdorf bei Sankt Peter'
  },
  {
    postalCode: '8093',
    city: 'Sankt Peter am Ottersbach'
  },
  {
    postalCode: '8093',
    city: 'Wiersdorf'
  },
  {
    postalCode: '8093',
    city: 'Wittmannsdorf'
  },
  {
    postalCode: '8101',
    city: 'Forstviertel'
  },
  {
    postalCode: '8101',
    city: 'Freßnitzviertel'
  },
  {
    postalCode: '8101',
    city: 'Kirchenviertel'
  },
  {
    postalCode: '8101',
    city: 'Sankt Veit'
  },
  {
    postalCode: '8101',
    city: 'Unterfriesach'
  },
  {
    postalCode: '8102',
    city: 'Freßnitzviertel'
  },
  {
    postalCode: '8102',
    city: 'Amstein'
  },
  {
    postalCode: '8102',
    city: 'Unterneudorf'
  },
  {
    postalCode: '8102',
    city: 'Markterviertl'
  },
  {
    postalCode: '8102',
    city: 'Präbichl'
  },
  {
    postalCode: '8102',
    city: 'Rechberg'
  },
  {
    postalCode: '8102',
    city: 'Schönegg'
  },
  {
    postalCode: '8102',
    city: 'Semriach'
  },
  {
    postalCode: '8102',
    city: 'Thoneben'
  },
  {
    postalCode: '8102',
    city: 'Windhof'
  },
  {
    postalCode: '8103',
    city: 'Stübinggraben'
  },
  {
    postalCode: '8103',
    city: 'Eisbach'
  },
  {
    postalCode: '8103',
    city: 'Hörgas'
  },
  {
    postalCode: '8103',
    city: 'Kehr und Plesch'
  },
  {
    postalCode: '8103',
    city: 'Rein'
  },
  {
    postalCode: '8111',
    city: 'Eisbach'
  },
  {
    postalCode: '8111',
    city: 'Hundsdorf'
  },
  {
    postalCode: '8111',
    city: 'Judendorf'
  },
  {
    postalCode: '8111',
    city: 'Kugelberg'
  },
  {
    postalCode: '8111',
    city: 'Rötz'
  },
  {
    postalCode: '8111',
    city: 'Straßengel'
  },
  {
    postalCode: '8111',
    city: 'Plankenwarth'
  },
  {
    postalCode: '8112',
    city: 'Eisbach'
  },
  {
    postalCode: '8112',
    city: 'Hörgas'
  },
  {
    postalCode: '8112',
    city: 'Rein'
  },
  {
    postalCode: '8112',
    city: 'Gratwein'
  },
  {
    postalCode: '8112',
    city: 'Hundsdorf'
  },
  {
    postalCode: '8112',
    city: 'Sankt Oswald bei Plankenwarth'
  },
  {
    postalCode: '8113',
    city: 'Eisbach'
  },
  {
    postalCode: '8113',
    city: 'Gschnaidt'
  },
  {
    postalCode: '8113',
    city: 'Michlbach'
  },
  {
    postalCode: '8113',
    city: 'Rohrbach'
  },
  {
    postalCode: '8113',
    city: 'Jaritzberg'
  },
  {
    postalCode: '8113',
    city: 'Lichtenegg'
  },
  {
    postalCode: '8113',
    city: 'Reiteregg'
  },
  {
    postalCode: '8113',
    city: 'Sankt Bartholomä'
  },
  {
    postalCode: '8113',
    city: 'Södingberg'
  },
  {
    postalCode: '8113',
    city: 'Plankenwarth'
  },
  {
    postalCode: '8113',
    city: 'Sankt Oswald bei Plankenwarth'
  },
  {
    postalCode: '8113',
    city: 'Kalchberg'
  },
  {
    postalCode: '8113',
    city: 'Stiwoll'
  },
  {
    postalCode: '8113',
    city: 'Thal'
  },
  {
    postalCode: '8114',
    city: 'Deutschfeistritz'
  },
  {
    postalCode: '8114',
    city: 'Himberg'
  },
  {
    postalCode: '8114',
    city: 'Kleinstübing'
  },
  {
    postalCode: '8114',
    city: 'Königgraben'
  },
  {
    postalCode: '8114',
    city: 'Stübinggraben'
  },
  {
    postalCode: '8114',
    city: 'Hörgas'
  },
  {
    postalCode: '8114',
    city: 'Unterfriesach'
  },
  {
    postalCode: '8114',
    city: 'Großstübing'
  },
  {
    postalCode: '8114',
    city: 'Gschnaidt'
  },
  {
    postalCode: '8114',
    city: 'Friesach'
  },
  {
    postalCode: '8114',
    city: 'Thoneben'
  },
  {
    postalCode: '8120',
    city: 'Peggau'
  },
  {
    postalCode: '8121',
    city: 'Deutschfeistritz'
  },
  {
    postalCode: '8121',
    city: 'Himberg'
  },
  {
    postalCode: '8121',
    city: 'Königgraben'
  },
  {
    postalCode: '8121',
    city: 'Prenning'
  },
  {
    postalCode: '8121',
    city: 'Adriach'
  },
  {
    postalCode: '8122',
    city: 'Arzwaldgraben'
  },
  {
    postalCode: '8122',
    city: 'Waldstein'
  },
  {
    postalCode: '8122',
    city: 'Hofamt'
  },
  {
    postalCode: '8122',
    city: 'Land-Übelbach'
  },
  {
    postalCode: '8124',
    city: 'Großstübing'
  },
  {
    postalCode: '8124',
    city: 'Kleintal'
  },
  {
    postalCode: '8124',
    city: 'Land-Übelbach'
  },
  {
    postalCode: '8124',
    city: 'Markt-Übelbach'
  },
  {
    postalCode: '8124',
    city: 'Neuhof'
  },
  {
    postalCode: '8130',
    city: 'Badl'
  },
  {
    postalCode: '8130',
    city: 'Brunnhof'
  },
  {
    postalCode: '8130',
    city: 'Frohnleiten'
  },
  {
    postalCode: '8130',
    city: 'Laas'
  },
  {
    postalCode: '8130',
    city: 'Maria Ebenort'
  },
  {
    postalCode: '8130',
    city: 'Peugen'
  },
  {
    postalCode: '8130',
    city: 'Pfannberg'
  },
  {
    postalCode: '8130',
    city: 'Schönau'
  },
  {
    postalCode: '8130',
    city: 'Schrauding'
  },
  {
    postalCode: '8130',
    city: 'Schweizerfabrik'
  },
  {
    postalCode: '8130',
    city: 'Ungersdorf'
  },
  {
    postalCode: '8130',
    city: 'Wannersdorf'
  },
  {
    postalCode: '8130',
    city: 'Adriach'
  },
  {
    postalCode: '8130',
    city: 'Gams'
  },
  {
    postalCode: '8130',
    city: 'Gamsgraben'
  },
  {
    postalCode: '8130',
    city: 'Hofamt'
  },
  {
    postalCode: '8130',
    city: 'Laufnitzdorf'
  },
  {
    postalCode: '8130',
    city: 'Laufnitzgraben'
  },
  {
    postalCode: '8130',
    city: 'Leutnant Günther-Siedlung'
  },
  {
    postalCode: '8130',
    city: 'Rothleiten'
  },
  {
    postalCode: '8130',
    city: 'Peggau'
  },
  {
    postalCode: '8130',
    city: 'Gschwendt'
  },
  {
    postalCode: '8130',
    city: 'Schrems'
  },
  {
    postalCode: '8130',
    city: 'Tyrnau'
  },
  {
    postalCode: '8131',
    city: 'Mautstatt'
  },
  {
    postalCode: '8131',
    city: 'Mixnitz'
  },
  {
    postalCode: '8131',
    city: 'Roßgraben'
  },
  {
    postalCode: '8131',
    city: 'Traföß'
  },
  {
    postalCode: '8131',
    city: 'Röthelstein'
  },
  {
    postalCode: '8132',
    city: 'Gabraun'
  },
  {
    postalCode: '8132',
    city: 'Kirchdorf'
  },
  {
    postalCode: '8132',
    city: 'Pernegg'
  },
  {
    postalCode: '8132',
    city: 'Traföß'
  },
  {
    postalCode: '8132',
    city: 'Zlatten'
  },
  {
    postalCode: '8141',
    city: 'Hautzendorf'
  },
  {
    postalCode: '8141',
    city: 'Oberpremstätten'
  },
  {
    postalCode: '8141',
    city: 'Unterpremstätten'
  },
  {
    postalCode: '8141',
    city: 'Bierbaum'
  },
  {
    postalCode: '8141',
    city: 'Laa'
  },
  {
    postalCode: '8141',
    city: 'Zettling'
  },
  {
    postalCode: '8141',
    city: 'Dietersdorf'
  },
  {
    postalCode: '8142',
    city: 'Forst'
  },
  {
    postalCode: '8142',
    city: 'Gradenfeld'
  },
  {
    postalCode: '8142',
    city: 'Kasten'
  },
  {
    postalCode: '8142',
    city: 'Ponigl'
  },
  {
    postalCode: '8142',
    city: 'Wundschuh'
  },
  {
    postalCode: '8142',
    city: 'Dietersdorf'
  },
  {
    postalCode: '8142',
    city: 'Steindorf'
  },
  {
    postalCode: '8142',
    city: 'Zwaring'
  },
  {
    postalCode: '8143',
    city: 'Dobl'
  },
  {
    postalCode: '8143',
    city: 'Muttendorf'
  },
  {
    postalCode: '8143',
    city: 'Petzendorf'
  },
  {
    postalCode: '8143',
    city: 'Weinzettl'
  },
  {
    postalCode: '8143',
    city: 'Tobisegg'
  },
  {
    postalCode: '8143',
    city: 'Unterpremstätten'
  },
  {
    postalCode: '8143',
    city: 'Fading'
  },
  {
    postalCode: '8143',
    city: 'Wuschan'
  },
  {
    postalCode: '8144',
    city: 'Attendorf'
  },
  {
    postalCode: '8144',
    city: 'Attendorfberg'
  },
  {
    postalCode: '8144',
    city: 'Riederhof'
  },
  {
    postalCode: '8144',
    city: 'Badegg'
  },
  {
    postalCode: '8144',
    city: 'Haselsdorf'
  },
  {
    postalCode: '8144',
    city: 'Haselsdorfberg'
  },
  {
    postalCode: '8144',
    city: 'Tobelbad'
  },
  {
    postalCode: '8144',
    city: 'Bischofegg'
  },
  {
    postalCode: '8144',
    city: 'Seiersberg'
  },
  {
    postalCode: '8144',
    city: 'Hautzendorf'
  },
  {
    postalCode: '8144',
    city: 'Unterpremstätten'
  },
  {
    postalCode: '8151',
    city: 'Attendorf'
  },
  {
    postalCode: '8151',
    city: 'Attendorfberg'
  },
  {
    postalCode: '8151',
    city: 'Haselsdorfberg'
  },
  {
    postalCode: '8151',
    city: 'Altenberg'
  },
  {
    postalCode: '8151',
    city: 'Altreiteregg'
  },
  {
    postalCode: '8151',
    city: 'Berndorf'
  },
  {
    postalCode: '8151',
    city: 'Doblegg'
  },
  {
    postalCode: '8151',
    city: 'Hitzendorf'
  },
  {
    postalCode: '8151',
    city: 'Höllberg'
  },
  {
    postalCode: '8151',
    city: 'Holzberg'
  },
  {
    postalCode: '8151',
    city: 'Mayersdorf'
  },
  {
    postalCode: '8151',
    city: 'Neureiteregg'
  },
  {
    postalCode: '8151',
    city: 'Niederberg'
  },
  {
    postalCode: '8151',
    city: 'Oberberg'
  },
  {
    postalCode: '8151',
    city: 'Pirka'
  },
  {
    postalCode: '8151',
    city: 'Rohrbach'
  },
  {
    postalCode: '8151',
    city: 'Steinberg'
  },
  {
    postalCode: '8151',
    city: 'Reiteregg'
  },
  {
    postalCode: '8151',
    city: 'Sankt Bartholomä'
  },
  {
    postalCode: '8151',
    city: 'Hausdorf'
  },
  {
    postalCode: '8151',
    city: 'Thal'
  },
  {
    postalCode: '8152',
    city: 'Berndorf'
  },
  {
    postalCode: '8152',
    city: 'Michlbach'
  },
  {
    postalCode: '8152',
    city: 'Gasselberg'
  },
  {
    postalCode: '8152',
    city: 'Hausdorf'
  },
  {
    postalCode: '8152',
    city: 'Neudorf bei Sankt Johann ob Hohenburg'
  },
  {
    postalCode: '8152',
    city: 'Södingberg'
  },
  {
    postalCode: '8152',
    city: 'Aichegg'
  },
  {
    postalCode: '8152',
    city: 'Bernau'
  },
  {
    postalCode: '8152',
    city: 'Kalchberg'
  },
  {
    postalCode: '8152',
    city: 'Muggauberg'
  },
  {
    postalCode: '8152',
    city: 'Raßberg'
  },
  {
    postalCode: '8152',
    city: 'Stallhofen'
  },
  {
    postalCode: '8152',
    city: 'Voitsberg'
  },
  {
    postalCode: '8153',
    city: 'Eggartsberg'
  },
  {
    postalCode: '8153',
    city: 'Geistthal'
  },
  {
    postalCode: '8153',
    city: 'Kleinalpe'
  },
  {
    postalCode: '8153',
    city: 'Sonnleiten'
  },
  {
    postalCode: '8153',
    city: 'Gschnaidt'
  },
  {
    postalCode: '8153',
    city: 'Södingberg'
  },
  {
    postalCode: '8153',
    city: 'Neuhof'
  },
  {
    postalCode: '8160',
    city: 'Etzersdorf'
  },
  {
    postalCode: '8160',
    city: 'Rollsdorf'
  },
  {
    postalCode: '8160',
    city: 'Garrach'
  },
  {
    postalCode: '8160',
    city: 'Kleinsemmering'
  },
  {
    postalCode: '8160',
    city: 'Haufenreith'
  },
  {
    postalCode: '8160',
    city: 'Hohenau an der Raab'
  },
  {
    postalCode: '8160',
    city: 'Krammersdorf'
  },
  {
    postalCode: '8160',
    city: 'Büchl'
  },
  {
    postalCode: '8160',
    city: 'Farcha'
  },
  {
    postalCode: '8160',
    city: 'Krottendorf'
  },
  {
    postalCode: '8160',
    city: 'Nöstl'
  },
  {
    postalCode: '8160',
    city: 'Preding'
  },
  {
    postalCode: '8160',
    city: 'Regerstätten'
  },
  {
    postalCode: '8160',
    city: 'Hofstätten'
  },
  {
    postalCode: '8160',
    city: 'Oberdorf'
  },
  {
    postalCode: '8160',
    city: 'Obergreith'
  },
  {
    postalCode: '8160',
    city: 'Pichl an der Raab'
  },
  {
    postalCode: '8160',
    city: 'Untergreith'
  },
  {
    postalCode: '8160',
    city: 'Göttelsberg'
  },
  {
    postalCode: '8160',
    city: 'Hafning'
  },
  {
    postalCode: '8160',
    city: 'Haselbach bei Weiz'
  },
  {
    postalCode: '8160',
    city: 'Leska'
  },
  {
    postalCode: '8160',
    city: 'Mortantsch'
  },
  {
    postalCode: '8160',
    city: 'Steinberg bei Weiz'
  },
  {
    postalCode: '8160',
    city: 'Affental'
  },
  {
    postalCode: '8160',
    city: 'Birchbaum'
  },
  {
    postalCode: '8160',
    city: 'Dürntal'
  },
  {
    postalCode: '8160',
    city: 'Gschaid bei Weiz'
  },
  {
    postalCode: '8160',
    city: 'Naas'
  },
  {
    postalCode: '8160',
    city: 'Harl'
  },
  {
    postalCode: '8160',
    city: 'Sankt Kathrein am Offenegg '
  },
  {
    postalCode: '8160',
    city: 'Stenzengreith'
  },
  {
    postalCode: '8160',
    city: 'Alterilz'
  },
  {
    postalCode: '8160',
    city: 'Grub'
  },
  {
    postalCode: '8160',
    city: 'Landscha bei Weiz'
  },
  {
    postalCode: '8160',
    city: 'Oberdorf bei Thannhausen'
  },
  {
    postalCode: '8160',
    city: 'Oberfladnitz-Thannhausen'
  },
  {
    postalCode: '8160',
    city: 'Peesen'
  },
  {
    postalCode: '8160',
    city: 'Ponigl'
  },
  {
    postalCode: '8160',
    city: 'Raas'
  },
  {
    postalCode: '8160',
    city: 'Trennstein'
  },
  {
    postalCode: '8160',
    city: 'Weiz'
  },
  {
    postalCode: '8162',
    city: 'Arzberg'
  },
  {
    postalCode: '8162',
    city: 'Auen'
  },
  {
    postalCode: '8162',
    city: 'Haufenreith'
  },
  {
    postalCode: '8162',
    city: 'Hohenau an der Raab'
  },
  {
    postalCode: '8162',
    city: 'Krammersdorf'
  },
  {
    postalCode: '8162',
    city: 'Amstein'
  },
  {
    postalCode: '8162',
    city: 'Oberneudorf'
  },
  {
    postalCode: '8162',
    city: 'Unterneudorf'
  },
  {
    postalCode: '8162',
    city: 'Hart'
  },
  {
    postalCode: '8162',
    city: 'Passail'
  },
  {
    postalCode: '8162',
    city: 'Tober'
  },
  {
    postalCode: '8162',
    city: 'Plenzengreith'
  },
  {
    postalCode: '8162',
    city: 'Tulwitzdorf'
  },
  {
    postalCode: '8163',
    city: 'Fladnitz an der Teichalm'
  },
  {
    postalCode: '8163',
    city: 'Fladnitzberg'
  },
  {
    postalCode: '8163',
    city: 'Schrems'
  },
  {
    postalCode: '8163',
    city: 'Fladnitz-Tober'
  },
  {
    postalCode: '8163',
    city: 'Teichalm'
  },
  {
    postalCode: '8163',
    city: 'Hintertober'
  },
  {
    postalCode: '8163',
    city: 'Tober'
  },
  {
    postalCode: '8163',
    city: 'Sankt Kathrein am Offenegg '
  },
  {
    postalCode: '8163',
    city: 'Rechberg'
  },
  {
    postalCode: '8163',
    city: 'Tulwitzdorf'
  },
  {
    postalCode: '8163',
    city: 'Tulwitzviertl'
  },
  {
    postalCode: '8163',
    city: 'Nechnitz'
  },
  {
    postalCode: '8171',
    city: 'Sankt Kathrein am Offenegg '
  },
  {
    postalCode: '8171',
    city: 'Sankt Kathrein am Offenegg'
  },
  {
    postalCode: '8172',
    city: 'Haslau bei Birkfeld'
  },
  {
    postalCode: '8172',
    city: 'Hohenau an der Raab'
  },
  {
    postalCode: '8172',
    city: 'Rossegg'
  },
  {
    postalCode: '8172',
    city: 'Sallegg'
  },
  {
    postalCode: '8172',
    city: 'Naintsch'
  },
  {
    postalCode: '8172',
    city: 'Offenegg'
  },
  {
    postalCode: '8172',
    city: 'Sankt Kathrein am Offenegg'
  },
  {
    postalCode: '8181',
    city: 'Wollsdorferegg'
  },
  {
    postalCode: '8181',
    city: 'Lohngraben'
  },
  {
    postalCode: '8181',
    city: 'Rollsdorf'
  },
  {
    postalCode: '8181',
    city: 'Farcha'
  },
  {
    postalCode: '8181',
    city: 'Preding'
  },
  {
    postalCode: '8181',
    city: 'Gschwendt'
  },
  {
    postalCode: '8181',
    city: 'Dörfl an der Raab'
  },
  {
    postalCode: '8181',
    city: 'Hohenkogl'
  },
  {
    postalCode: '8181',
    city: 'Mitterdorf an der Raab'
  },
  {
    postalCode: '8181',
    city: 'Oberdorf'
  },
  {
    postalCode: '8181',
    city: 'Pichl an der Raab'
  },
  {
    postalCode: '8181',
    city: 'Untergreith'
  },
  {
    postalCode: '8181',
    city: 'Schirnitz'
  },
  {
    postalCode: '8181',
    city: 'Fünfing bei Sankt Ruprecht an der Raab'
  },
  {
    postalCode: '8181',
    city: 'Grub bei Sankt Ruprecht an der Raab'
  },
  {
    postalCode: '8181',
    city: 'Sankt Ruprecht an der Raab'
  },
  {
    postalCode: '8181',
    city: 'Wolfgruben b.Sankt Ruprecht a.d.Raab'
  },
  {
    postalCode: '8181',
    city: 'Arndorf bei Sankt Ruprecht an der Raab'
  },
  {
    postalCode: '8181',
    city: 'Dietmannsdorf'
  },
  {
    postalCode: '8181',
    city: 'Kühwiesen'
  },
  {
    postalCode: '8181',
    city: 'Neudorf bei Sankt Ruprecht an der Raab'
  },
  {
    postalCode: '8181',
    city: 'Unterfladnitz'
  },
  {
    postalCode: '8181',
    city: 'Wollsdorf'
  },
  {
    postalCode: '8182',
    city: 'Oberfeistritz'
  },
  {
    postalCode: '8182',
    city: 'Kulming'
  },
  {
    postalCode: '8182',
    city: 'Elz'
  },
  {
    postalCode: '8182',
    city: 'Harl'
  },
  {
    postalCode: '8182',
    city: 'Höfling'
  },
  {
    postalCode: '8182',
    city: 'Klettendorf'
  },
  {
    postalCode: '8182',
    city: 'Perndorf'
  },
  {
    postalCode: '8182',
    city: 'Puch bei Weiz'
  },
  {
    postalCode: '8183',
    city: 'Oberfeistritz'
  },
  {
    postalCode: '8183',
    city: 'Floing'
  },
  {
    postalCode: '8183',
    city: 'Lebing'
  },
  {
    postalCode: '8183',
    city: 'Unterfeistritz'
  },
  {
    postalCode: '8183',
    city: 'Höfling'
  },
  {
    postalCode: '8184',
    city: 'Anger'
  },
  {
    postalCode: '8184',
    city: 'Baierdorf-Dorf'
  },
  {
    postalCode: '8184',
    city: 'Baierdorf-Umgebung'
  },
  {
    postalCode: '8184',
    city: 'Fresen'
  },
  {
    postalCode: '8184',
    city: 'Oberfeistritz'
  },
  {
    postalCode: '8184',
    city: 'Viertelfeistritz'
  },
  {
    postalCode: '8184',
    city: 'Floing'
  },
  {
    postalCode: '8184',
    city: 'Rossegg'
  },
  {
    postalCode: '8184',
    city: 'Naintsch'
  },
  {
    postalCode: '8190',
    city: 'Birkfeld'
  },
  {
    postalCode: '8190',
    city: 'Gschaid bei Birkfeld'
  },
  {
    postalCode: '8190',
    city: 'Haslau bei Birkfeld'
  },
  {
    postalCode: '8190',
    city: 'Aschau'
  },
  {
    postalCode: '8190',
    city: 'Rabendorf'
  },
  {
    postalCode: '8190',
    city: 'Sallegg'
  },
  {
    postalCode: '8190',
    city: 'Berg-und Hinterleitenviertel'
  },
  {
    postalCode: '8190',
    city: 'Dorfviertel'
  },
  {
    postalCode: '8190',
    city: 'Außeregg'
  },
  {
    postalCode: '8190',
    city: 'Feistritz'
  },
  {
    postalCode: '8190',
    city: 'Piregg'
  },
  {
    postalCode: '8190',
    city: 'Waisenegg'
  },
  {
    postalCode: '8191',
    city: 'Aschau'
  },
  {
    postalCode: '8191',
    city: 'Rabendorf'
  },
  {
    postalCode: '8191',
    city: 'Rossegg'
  },
  {
    postalCode: '8191',
    city: 'Sallegg'
  },
  {
    postalCode: '8192',
    city: 'Außeregg'
  },
  {
    postalCode: '8192',
    city: 'Feistritz'
  },
  {
    postalCode: '8192',
    city: 'Pacher'
  },
  {
    postalCode: '8192',
    city: 'Strallegg'
  },
  {
    postalCode: '8200',
    city: 'Albersdorf'
  },
  {
    postalCode: '8200',
    city: 'Kalch'
  },
  {
    postalCode: '8200',
    city: 'Postelgraben'
  },
  {
    postalCode: '8200',
    city: 'Brodersdorf'
  },
  {
    postalCode: '8200',
    city: 'Brodingberg'
  },
  {
    postalCode: '8200',
    city: 'Gleisdorf'
  },
  {
    postalCode: '8200',
    city: 'Präbach'
  },
  {
    postalCode: '8200',
    city: 'Hofstätten an der Raab'
  },
  {
    postalCode: '8200',
    city: 'Pirching an der Raab'
  },
  {
    postalCode: '8200',
    city: 'Wetzawinkel'
  },
  {
    postalCode: '8200',
    city: 'Wünschendorf'
  },
  {
    postalCode: '8200',
    city: 'Wolfgruben bei Gleisdorf'
  },
  {
    postalCode: '8200',
    city: 'Labuch'
  },
  {
    postalCode: '8200',
    city: 'Urscha'
  },
  {
    postalCode: '8200',
    city: 'Unterbuch'
  },
  {
    postalCode: '8200',
    city: 'Hart'
  },
  {
    postalCode: '8200',
    city: 'Laßnitzthal'
  },
  {
    postalCode: '8200',
    city: 'Flöcking'
  },
  {
    postalCode: '8200',
    city: 'Ludersdorf'
  },
  {
    postalCode: '8200',
    city: 'Pircha'
  },
  {
    postalCode: '8200',
    city: 'Wilfersdorf'
  },
  {
    postalCode: '8200',
    city: 'Mitterlaßnitz'
  },
  {
    postalCode: '8200',
    city: 'Arnwiesen'
  },
  {
    postalCode: '8200',
    city: 'Gamling'
  },
  {
    postalCode: '8200',
    city: 'Kaltenbrunn'
  },
  {
    postalCode: '8200',
    city: 'Nitscha'
  },
  {
    postalCode: '8200',
    city: 'Fünfing bei Gleisdorf'
  },
  {
    postalCode: '8200',
    city: 'Obergroßau'
  },
  {
    postalCode: '8200',
    city: 'Ungerdorf'
  },
  {
    postalCode: '8211',
    city: 'Kalch'
  },
  {
    postalCode: '8211',
    city: 'Postelgraben'
  },
  {
    postalCode: '8211',
    city: 'Prebuch'
  },
  {
    postalCode: '8211',
    city: 'Großpesendorf'
  },
  {
    postalCode: '8211',
    city: 'Neudorf'
  },
  {
    postalCode: '8211',
    city: 'Nitschaberg'
  },
  {
    postalCode: '8211',
    city: 'Prebensdorf'
  },
  {
    postalCode: '8211',
    city: 'Wolfgruben bei Gleisdorf'
  },
  {
    postalCode: '8211',
    city: 'Oberrettenbach'
  },
  {
    postalCode: '8211',
    city: 'Preßguts'
  },
  {
    postalCode: '8211',
    city: 'Schirnitz'
  },
  {
    postalCode: '8211',
    city: 'Egelsdorf'
  },
  {
    postalCode: '8211',
    city: 'Fünfing bei Gleisdorf'
  },
  {
    postalCode: '8212',
    city: 'Gersdorf an der Feistritz'
  },
  {
    postalCode: '8212',
    city: 'Hartensdorf'
  },
  {
    postalCode: '8212',
    city: 'Kulming'
  },
  {
    postalCode: '8212',
    city: 'Rohrbach am Kulm'
  },
  {
    postalCode: '8212',
    city: 'Oberrettenbach'
  },
  {
    postalCode: '8212',
    city: 'Rothgmos'
  },
  {
    postalCode: '8212',
    city: 'Hart'
  },
  {
    postalCode: '8212',
    city: 'Kleinpesendorf'
  },
  {
    postalCode: '8212',
    city: 'Pischelsdorf in der Steiermark'
  },
  {
    postalCode: '8212',
    city: 'Romatschachen'
  },
  {
    postalCode: '8212',
    city: 'Schachen am Römerbach'
  },
  {
    postalCode: '8212',
    city: 'Elz'
  },
  {
    postalCode: '8212',
    city: 'Höfling'
  },
  {
    postalCode: '8212',
    city: 'Reichendorf'
  },
  {
    postalCode: '8221',
    city: 'Hofing'
  },
  {
    postalCode: '8221',
    city: 'Illensdorf'
  },
  {
    postalCode: '8221',
    city: 'Hirnsdorf'
  },
  {
    postalCode: '8221',
    city: 'Kaibing'
  },
  {
    postalCode: '8222',
    city: 'Hofing'
  },
  {
    postalCode: '8222',
    city: 'Kaibing'
  },
  {
    postalCode: '8222',
    city: 'Kulming'
  },
  {
    postalCode: '8222',
    city: 'Siegersdorf bei Herberstein'
  },
  {
    postalCode: '8222',
    city: 'Sankt Johann bei Herberstein'
  },
  {
    postalCode: '8222',
    city: 'Buchberg bei Herberstein'
  },
  {
    postalCode: '8222',
    city: 'Untertiefenbach'
  },
  {
    postalCode: '8223',
    city: 'Floing'
  },
  {
    postalCode: '8223',
    city: 'Buchberg bei Herberstein'
  },
  {
    postalCode: '8223',
    city: 'Freienberg'
  },
  {
    postalCode: '8223',
    city: 'Stubenberg am See'
  },
  {
    postalCode: '8223',
    city: 'Vockenberg'
  },
  {
    postalCode: '8223',
    city: 'Zeil bei Stubenberg'
  },
  {
    postalCode: '8224',
    city: 'Blaindorf'
  },
  {
    postalCode: '8224',
    city: 'Hofing'
  },
  {
    postalCode: '8224',
    city: 'Dienersdorf'
  },
  {
    postalCode: '8224',
    city: 'Hartl'
  },
  {
    postalCode: '8224',
    city: 'Hofkirchen bei Hartberg'
  },
  {
    postalCode: '8224',
    city: 'Kaindorf'
  },
  {
    postalCode: '8224',
    city: 'Kopfing bei Kaindorf'
  },
  {
    postalCode: '8224',
    city: 'Hinteregg'
  },
  {
    postalCode: '8224',
    city: 'Winzendorf'
  },
  {
    postalCode: '8224',
    city: 'Vockenberg'
  },
  {
    postalCode: '8224',
    city: 'Obertiefenbach'
  },
  {
    postalCode: '8224',
    city: 'Untertiefenbach'
  },
  {
    postalCode: '8225',
    city: 'Staudach'
  },
  {
    postalCode: '8225',
    city: 'Flattendorf'
  },
  {
    postalCode: '8225',
    city: 'Hofkirchen bei Hartberg'
  },
  {
    postalCode: '8225',
    city: 'Pöllau'
  },
  {
    postalCode: '8225',
    city: 'Oberneuberg'
  },
  {
    postalCode: '8225',
    city: 'Unterneuberg'
  },
  {
    postalCode: '8225',
    city: 'Zeil-Pöllau'
  },
  {
    postalCode: '8225',
    city: 'Rabenwald'
  },
  {
    postalCode: '8225',
    city: 'Obersaifen'
  },
  {
    postalCode: '8225',
    city: 'Winkl-Boden'
  },
  {
    postalCode: '8225',
    city: 'Hinteregg'
  },
  {
    postalCode: '8225',
    city: 'Schönau'
  },
  {
    postalCode: '8225',
    city: 'Winzendorf'
  },
  {
    postalCode: '8225',
    city: 'Köppelreith'
  },
  {
    postalCode: '8225',
    city: 'Prätis'
  },
  {
    postalCode: '8225',
    city: 'Pongratzen'
  },
  {
    postalCode: '8230',
    city: 'Untersafen'
  },
  {
    postalCode: '8230',
    city: 'Penzendorf'
  },
  {
    postalCode: '8230',
    city: 'Staudach'
  },
  {
    postalCode: '8230',
    city: 'Wolfgrub'
  },
  {
    postalCode: '8230',
    city: 'Eggendorf'
  },
  {
    postalCode: '8230',
    city: 'Habersdorf'
  },
  {
    postalCode: '8230',
    city: 'Hartberg'
  },
  {
    postalCode: '8230',
    city: 'Ring'
  },
  {
    postalCode: '8230',
    city: 'Safenau'
  },
  {
    postalCode: '8230',
    city: 'Flattendorf'
  },
  {
    postalCode: '8230',
    city: 'Löffelbach'
  },
  {
    postalCode: '8230',
    city: 'Mitterdombach'
  },
  {
    postalCode: '8230',
    city: 'Schildbach'
  },
  {
    postalCode: '8230',
    city: 'Siebenbrunn'
  },
  {
    postalCode: '8230',
    city: 'Oberlungitz'
  },
  {
    postalCode: '8230',
    city: 'Wagendorf'
  },
  {
    postalCode: '8230',
    city: 'Schölbing'
  },
  {
    postalCode: '8230',
    city: 'Unterlungitz'
  },
  {
    postalCode: '8230',
    city: 'Winzendorf'
  },
  {
    postalCode: '8232',
    city: 'Erdwegen'
  },
  {
    postalCode: '8232',
    city: 'Grafendorf bei Hartberg'
  },
  {
    postalCode: '8232',
    city: 'Kleinlungitz'
  },
  {
    postalCode: '8232',
    city: 'Lechen'
  },
  {
    postalCode: '8232',
    city: 'Obersafen'
  },
  {
    postalCode: '8232',
    city: 'Reibersdorf'
  },
  {
    postalCode: '8232',
    city: 'Seibersdorf am Hammerwald'
  },
  {
    postalCode: '8232',
    city: 'Untersafen'
  },
  {
    postalCode: '8232',
    city: 'Pongratzen'
  },
  {
    postalCode: '8232',
    city: 'Stambach'
  },
  {
    postalCode: '8232',
    city: 'Zeilerviertel'
  },
  {
    postalCode: '8233',
    city: 'Kleinlungitz'
  },
  {
    postalCode: '8233',
    city: 'Lafnitz'
  },
  {
    postalCode: '8233',
    city: 'Rohrbachschlag'
  },
  {
    postalCode: '8234',
    city: 'Eichberg'
  },
  {
    postalCode: '8234',
    city: 'Lebing'
  },
  {
    postalCode: '8234',
    city: 'Kleinlungitz'
  },
  {
    postalCode: '8234',
    city: 'Reinberg'
  },
  {
    postalCode: '8234',
    city: 'Rohrbach an der Lafnitz'
  },
  {
    postalCode: '8234',
    city: 'Riegl'
  },
  {
    postalCode: '8234',
    city: 'Limbach'
  },
  {
    postalCode: '8234',
    city: 'Rohrbachschlag'
  },
  {
    postalCode: '8240',
    city: 'Ehrenschachen'
  },
  {
    postalCode: '8240',
    city: 'Friedberg'
  },
  {
    postalCode: '8240',
    city: 'Oberwaldbauern'
  },
  {
    postalCode: '8240',
    city: 'Ortgraben'
  },
  {
    postalCode: '8240',
    city: 'Schwaighof'
  },
  {
    postalCode: '8240',
    city: 'Stögersbach'
  },
  {
    postalCode: '8240',
    city: 'Pinggau'
  },
  {
    postalCode: '8240',
    city: 'Pinkafeld'
  },
  {
    postalCode: '8241',
    city: 'Bergen'
  },
  {
    postalCode: '8241',
    city: 'Burgfeld'
  },
  {
    postalCode: '8241',
    city: 'Dechantskirchen'
  },
  {
    postalCode: '8241',
    city: 'Hohenau am Wechsel'
  },
  {
    postalCode: '8241',
    city: 'Kroisbach'
  },
  {
    postalCode: '8241',
    city: 'Stögersbach'
  },
  {
    postalCode: '8241',
    city: 'Limbach'
  },
  {
    postalCode: '8241',
    city: 'Schlag bei Thalberg'
  },
  {
    postalCode: '8242',
    city: 'Auerbach'
  },
  {
    postalCode: '8242',
    city: 'Köppel'
  },
  {
    postalCode: '8242',
    city: 'Kronegg'
  },
  {
    postalCode: '8242',
    city: 'Riegl'
  },
  {
    postalCode: '8242',
    city: 'Sankt Lorenzen am Wechsel'
  },
  {
    postalCode: '8243',
    city: 'Ortgraben'
  },
  {
    postalCode: '8243',
    city: 'Baumgarten'
  },
  {
    postalCode: '8243',
    city: 'Dirnegg'
  },
  {
    postalCode: '8243',
    city: 'Haideggendorf'
  },
  {
    postalCode: '8243',
    city: 'Koglreith'
  },
  {
    postalCode: '8243',
    city: 'Pinggau'
  },
  {
    postalCode: '8243',
    city: 'Sparberegg'
  },
  {
    postalCode: '8243',
    city: 'Steirisch-Tauchen'
  },
  {
    postalCode: '8243',
    city: 'Tanzegg'
  },
  {
    postalCode: '8243',
    city: 'Wiesenhöf'
  },
  {
    postalCode: '8243',
    city: 'Elsenau'
  },
  {
    postalCode: '8243',
    city: 'Elsenau Sparberegg'
  },
  {
    postalCode: '8243',
    city: 'Götzendorf'
  },
  {
    postalCode: '8244',
    city: 'Anger'
  },
  {
    postalCode: '8244',
    city: 'Elsenau'
  },
  {
    postalCode: '8244',
    city: 'Haberl'
  },
  {
    postalCode: '8244',
    city: 'Knolln'
  },
  {
    postalCode: '8244',
    city: 'Schäffern'
  },
  {
    postalCode: '8244',
    city: 'Stübegg'
  },
  {
    postalCode: '8250',
    city: 'Kleinschlag'
  },
  {
    postalCode: '8250',
    city: 'Lebing'
  },
  {
    postalCode: '8250',
    city: 'Schnellerviertel'
  },
  {
    postalCode: '8250',
    city: 'Puchegg'
  },
  {
    postalCode: '8250',
    city: 'Reinberg'
  },
  {
    postalCode: '8250',
    city: 'Riegersbach'
  },
  {
    postalCode: '8250',
    city: 'Schachen bei Vorau'
  },
  {
    postalCode: '8250',
    city: 'Prätis'
  },
  {
    postalCode: '8250',
    city: 'Zeilerviertel'
  },
  {
    postalCode: '8250',
    city: 'Vorau'
  },
  {
    postalCode: '8250',
    city: 'Vornholz'
  },
  {
    postalCode: '8251',
    city: 'Karnerviertel'
  },
  {
    postalCode: '8251',
    city: 'Riegersbach'
  },
  {
    postalCode: '8251',
    city: 'Festenburg'
  },
  {
    postalCode: '8251',
    city: 'Köppel'
  },
  {
    postalCode: '8252',
    city: 'Karnerviertel'
  },
  {
    postalCode: '8252',
    city: 'Schmiedviertel'
  },
  {
    postalCode: '8252',
    city: 'Riegersbach'
  },
  {
    postalCode: '8253',
    city: 'Schmiedviertel'
  },
  {
    postalCode: '8253',
    city: 'Riegersbach'
  },
  {
    postalCode: '8253',
    city: 'Vornholz'
  },
  {
    postalCode: '8253',
    city: 'Arzberg'
  },
  {
    postalCode: '8253',
    city: 'Breitenbrunn'
  },
  {
    postalCode: '8253',
    city: 'Rieglerviertel'
  },
  {
    postalCode: '8253',
    city: 'Schrimpf'
  },
  {
    postalCode: '8253',
    city: 'Sommersgut'
  },
  {
    postalCode: '8254',
    city: 'Vornholz'
  },
  {
    postalCode: '8254',
    city: 'Kandlbauer'
  },
  {
    postalCode: '8254',
    city: 'Pittermann'
  },
  {
    postalCode: '8254',
    city: 'Sichart'
  },
  {
    postalCode: '8254',
    city: 'Sommersgut'
  },
  {
    postalCode: '8255',
    city: 'Filzmoos'
  },
  {
    postalCode: '8255',
    city: 'Kaltenegg'
  },
  {
    postalCode: '8255',
    city: 'Kirchenviertel'
  },
  {
    postalCode: '8255',
    city: 'Steinhöf'
  },
  {
    postalCode: '8261',
    city: 'Gschmaier'
  },
  {
    postalCode: '8261',
    city: 'Wetzawinkel'
  },
  {
    postalCode: '8261',
    city: 'Wolfgruben bei Gleisdorf'
  },
  {
    postalCode: '8261',
    city: 'Nestelbach im Ilztal'
  },
  {
    postalCode: '8261',
    city: 'Arnwiesen'
  },
  {
    postalCode: '8261',
    city: 'Oberrettenbach'
  },
  {
    postalCode: '8261',
    city: 'Egelsdorf'
  },
  {
    postalCode: '8261',
    city: 'Frösau'
  },
  {
    postalCode: '8261',
    city: 'Fünfing bei Gleisdorf'
  },
  {
    postalCode: '8261',
    city: 'Gnies'
  },
  {
    postalCode: '8261',
    city: 'Nagl'
  },
  {
    postalCode: '8261',
    city: 'Obergroßau'
  },
  {
    postalCode: '8261',
    city: 'Sinabelkirchen'
  },
  {
    postalCode: '8261',
    city: 'Untergroßau'
  },
  {
    postalCode: '8261',
    city: 'Unterrettenbach'
  },
  {
    postalCode: '8262',
    city: 'Gschmaier'
  },
  {
    postalCode: '8262',
    city: 'Hainfeld bei Fürstenfeld'
  },
  {
    postalCode: '8262',
    city: 'Buchberg bei Ilz'
  },
  {
    postalCode: '8262',
    city: 'Dambach'
  },
  {
    postalCode: '8262',
    city: 'Dörfl'
  },
  {
    postalCode: '8262',
    city: 'Ilz'
  },
  {
    postalCode: '8262',
    city: 'Kalsdorf bei Ilz'
  },
  {
    postalCode: '8262',
    city: 'Kleegraben'
  },
  {
    postalCode: '8262',
    city: 'Leithen'
  },
  {
    postalCode: '8262',
    city: 'Neudorf bei Ilz'
  },
  {
    postalCode: '8262',
    city: 'Reigersberg'
  },
  {
    postalCode: '8262',
    city: 'Eichberg bei Hartmannsdorf'
  },
  {
    postalCode: '8262',
    city: 'Hochenegg'
  },
  {
    postalCode: '8262',
    city: 'Mutzenfeld'
  },
  {
    postalCode: '8262',
    city: 'Nestelbach im Ilztal'
  },
  {
    postalCode: '8262',
    city: 'Nestelberg'
  },
  {
    postalCode: '8262',
    city: 'Walkersdorf'
  },
  {
    postalCode: '8262',
    city: 'Ziegenberg'
  },
  {
    postalCode: '8262',
    city: 'Gnies'
  },
  {
    postalCode: '8263',
    city: 'Großwilfersdorf'
  },
  {
    postalCode: '8263',
    city: 'Herrnberg'
  },
  {
    postalCode: '8263',
    city: 'Maierhofbergen'
  },
  {
    postalCode: '8263',
    city: 'Maierhofen'
  },
  {
    postalCode: '8263',
    city: 'Radersdorf'
  },
  {
    postalCode: '8264',
    city: 'Lichtenwald'
  },
  {
    postalCode: '8264',
    city: 'Großhartmannsdorf'
  },
  {
    postalCode: '8264',
    city: 'Hainersdorf'
  },
  {
    postalCode: '8264',
    city: 'Obgrün'
  },
  {
    postalCode: '8264',
    city: 'Riegersdorf'
  },
  {
    postalCode: '8265',
    city: 'Blaindorf'
  },
  {
    postalCode: '8265',
    city: 'Gersdorf an der Feistritz'
  },
  {
    postalCode: '8265',
    city: 'Gschmaier'
  },
  {
    postalCode: '8265',
    city: 'Neusiedl'
  },
  {
    postalCode: '8265',
    city: 'Großhartmannsdorf'
  },
  {
    postalCode: '8265',
    city: 'Großsteinbach'
  },
  {
    postalCode: '8265',
    city: 'Kroisbach an der Feistritz'
  },
  {
    postalCode: '8265',
    city: 'Hartl'
  },
  {
    postalCode: '8265',
    city: 'Leithen'
  },
  {
    postalCode: '8271',
    city: 'Hohenbrugg'
  },
  {
    postalCode: '8271',
    city: 'Leitersdorf bei Hartberg'
  },
  {
    postalCode: '8271',
    city: 'Wagerberg'
  },
  {
    postalCode: '8271',
    city: 'Bad Waltersdorf'
  },
  {
    postalCode: '8271',
    city: 'Großhart'
  },
  {
    postalCode: '8271',
    city: 'Oberlimbach'
  },
  {
    postalCode: '8271',
    city: 'Geier'
  },
  {
    postalCode: '8271',
    city: 'Rohrbach bei Waltersdorf'
  },
  {
    postalCode: '8271',
    city: 'Sebersdorf'
  },
  {
    postalCode: '8272',
    city: 'Geiseldorf'
  },
  {
    postalCode: '8272',
    city: 'Unterbuch'
  },
  {
    postalCode: '8272',
    city: 'Ebersdorf'
  },
  {
    postalCode: '8272',
    city: 'Nörning'
  },
  {
    postalCode: '8272',
    city: 'Großhart'
  },
  {
    postalCode: '8272',
    city: 'Neusiedl'
  },
  {
    postalCode: '8272',
    city: 'Hartl'
  },
  {
    postalCode: '8272',
    city: 'Geier'
  },
  {
    postalCode: '8272',
    city: 'Neustift bei Sebersdorf'
  },
  {
    postalCode: '8272',
    city: 'Rohrbach bei Waltersdorf'
  },
  {
    postalCode: '8272',
    city: 'Sebersdorf'
  },
  {
    postalCode: '8273',
    city: 'Ebersdorf'
  },
  {
    postalCode: '8273',
    city: 'Nörning'
  },
  {
    postalCode: '8273',
    city: 'Wagenbach'
  },
  {
    postalCode: '8273',
    city: 'Großhart'
  },
  {
    postalCode: '8274',
    city: 'Geiseldorf'
  },
  {
    postalCode: '8274',
    city: 'Oberbuch'
  },
  {
    postalCode: '8274',
    city: 'Unterbuch'
  },
  {
    postalCode: '8274',
    city: 'Unterdombach'
  },
  {
    postalCode: '8274',
    city: 'Safenau'
  },
  {
    postalCode: '8274',
    city: 'Wenireith'
  },
  {
    postalCode: '8274',
    city: 'Unterrohr'
  },
  {
    postalCode: '8274',
    city: 'Hopfau'
  },
  {
    postalCode: '8274',
    city: 'Längenbach'
  },
  {
    postalCode: '8274',
    city: 'Lemberg'
  },
  {
    postalCode: '8274',
    city: 'Mitterndorf'
  },
  {
    postalCode: '8274',
    city: 'Weinberg'
  },
  {
    postalCode: '8280',
    city: 'Altenmarkt bei Fürstenfeld'
  },
  {
    postalCode: '8280',
    city: 'Speltenbach'
  },
  {
    postalCode: '8280',
    city: 'Stadtbergen'
  },
  {
    postalCode: '8280',
    city: 'Fürstenfeld'
  },
  {
    postalCode: '8280',
    city: 'Dietersdorf bei Fürstenfeld'
  },
  {
    postalCode: '8280',
    city: 'Kohlgraben'
  },
  {
    postalCode: '8280',
    city: 'Hartl bei Fürstenfeld'
  },
  {
    postalCode: '8280',
    city: 'Übersbach'
  },
  {
    postalCode: '8282',
    city: 'Henndorf im Burgenland'
  },
  {
    postalCode: '8282',
    city: 'Dietersdorf bei Fürstenfeld'
  },
  {
    postalCode: '8282',
    city: 'Gillersdorf'
  },
  {
    postalCode: '8282',
    city: 'Loipersdorf bei Fürstenfeld'
  },
  {
    postalCode: '8282',
    city: 'Stein'
  },
  {
    postalCode: '8283',
    city: 'Bierbaum an der Safen'
  },
  {
    postalCode: '8283',
    city: 'Bad Blumau'
  },
  {
    postalCode: '8283',
    city: 'Jobst'
  },
  {
    postalCode: '8283',
    city: 'Kleinsteinbach'
  },
  {
    postalCode: '8283',
    city: 'Lindegg'
  },
  {
    postalCode: '8283',
    city: 'Loimeth'
  },
  {
    postalCode: '8283',
    city: 'Schwarzmannshofen'
  },
  {
    postalCode: '8283',
    city: 'Speilbrunn'
  },
  {
    postalCode: '8291',
    city: 'Burgau'
  },
  {
    postalCode: '8291',
    city: 'Burgauberg'
  },
  {
    postalCode: '8292',
    city: 'Neudauberg'
  },
  {
    postalCode: '8292',
    city: 'Hackerberg'
  },
  {
    postalCode: '8292',
    city: 'Unterlimbach'
  },
  {
    postalCode: '8292',
    city: 'Neudau'
  },
  {
    postalCode: '8293',
    city: 'Wörterberg'
  },
  {
    postalCode: '8293',
    city: 'Wörth an der Lafnitz'
  },
  {
    postalCode: '8294',
    city: 'Oberrohr'
  },
  {
    postalCode: '8294',
    city: 'Unterrohr'
  },
  {
    postalCode: '8294',
    city: 'Schölbing'
  },
  {
    postalCode: '8294',
    city: 'Hopfau'
  },
  {
    postalCode: '8295',
    city: 'Eggendorf'
  },
  {
    postalCode: '8295',
    city: 'Sankt Johann in der Haide'
  },
  {
    postalCode: '8295',
    city: 'Schölbing'
  },
  {
    postalCode: '8295',
    city: 'Unterlungitz'
  },
  {
    postalCode: '8301',
    city: 'Hart bei Graz'
  },
  {
    postalCode: '8301',
    city: 'Höf'
  },
  {
    postalCode: '8301',
    city: 'Präbach'
  },
  {
    postalCode: '8301',
    city: 'Hönigtal'
  },
  {
    postalCode: '8301',
    city: 'Kainbach'
  },
  {
    postalCode: '8301',
    city: 'Laßnitzhöhe'
  },
  {
    postalCode: '8301',
    city: 'Nestelbach bei Graz'
  },
  {
    postalCode: '8301',
    city: 'Premstätten'
  },
  {
    postalCode: '8302',
    city: 'Edelsgrub'
  },
  {
    postalCode: '8302',
    city: 'Empersdorf'
  },
  {
    postalCode: '8302',
    city: 'Präbach'
  },
  {
    postalCode: '8302',
    city: 'Krumegg'
  },
  {
    postalCode: '8302',
    city: 'Hirtenfeld'
  },
  {
    postalCode: '8302',
    city: 'Kogelbuch'
  },
  {
    postalCode: '8302',
    city: 'Langegg-Ort'
  },
  {
    postalCode: '8302',
    city: 'Laßnitzhöhe'
  },
  {
    postalCode: '8302',
    city: 'Laßnitzthal'
  },
  {
    postalCode: '8302',
    city: 'Mitterlaßnitz'
  },
  {
    postalCode: '8302',
    city: 'Nestelbach bei Graz'
  },
  {
    postalCode: '8302',
    city: 'Dürwagersbach'
  },
  {
    postalCode: '8302',
    city: 'Premstätten'
  },
  {
    postalCode: '8311',
    city: 'Kaag'
  },
  {
    postalCode: '8311',
    city: 'Mitterfladnitz'
  },
  {
    postalCode: '8311',
    city: 'Wetzawinkel'
  },
  {
    postalCode: '8311',
    city: 'Bärnbach'
  },
  {
    postalCode: '8311',
    city: 'Reith bei Hartmannsdorf'
  },
  {
    postalCode: '8311',
    city: 'Markt Hartmannsdorf'
  },
  {
    postalCode: '8311',
    city: 'Oed'
  },
  {
    postalCode: '8311',
    city: 'Pöllau bei Gleisdorf'
  },
  {
    postalCode: '8311',
    city: 'Eichberg bei Hartmannsdorf'
  },
  {
    postalCode: '8311',
    city: 'Frösau'
  },
  {
    postalCode: '8311',
    city: 'Takern'
  },
  {
    postalCode: '8312',
    city: 'Neustift'
  },
  {
    postalCode: '8312',
    city: 'Bärnbach'
  },
  {
    postalCode: '8312',
    city: 'Oed'
  },
  {
    postalCode: '8312',
    city: 'Breitenbach'
  },
  {
    postalCode: '8312',
    city: 'Ottendorf an der Rittschein'
  },
  {
    postalCode: '8312',
    city: 'Ziegenberg'
  },
  {
    postalCode: '8312',
    city: 'Krennach'
  },
  {
    postalCode: '8312',
    city: 'Schweinz'
  },
  {
    postalCode: '8313',
    city: 'Breitenfeld an der Rittschein'
  },
  {
    postalCode: '8313',
    city: 'Neustift'
  },
  {
    postalCode: '8313',
    city: 'Sankt Kind'
  },
  {
    postalCode: '8313',
    city: 'Tiefenbach'
  },
  {
    postalCode: '8313',
    city: 'Lembach bei Riegersburg'
  },
  {
    postalCode: '8313',
    city: 'Schweinz'
  },
  {
    postalCode: '8321',
    city: 'Hofstätten an der Raab'
  },
  {
    postalCode: '8321',
    city: 'Wetzawinkel'
  },
  {
    postalCode: '8321',
    city: 'Urscha'
  },
  {
    postalCode: '8321',
    city: 'Entschendorf'
  },
  {
    postalCode: '8321',
    city: 'Goggitsch'
  },
  {
    postalCode: '8321',
    city: 'Kroisbach'
  },
  {
    postalCode: '8321',
    city: 'St. Margarethen an der Raab'
  },
  {
    postalCode: '8321',
    city: 'Sulz'
  },
  {
    postalCode: '8321',
    city: 'Takern I'
  },
  {
    postalCode: '8321',
    city: 'Takern '
  },
  {
    postalCode: '8321',
    city: 'Zöbing'
  },
  {
    postalCode: '8322',
    city: 'Erbersdorf'
  },
  {
    postalCode: '8322',
    city: 'Mitterfladnitz'
  },
  {
    postalCode: '8322',
    city: 'Fladnitz im Raabtal'
  },
  {
    postalCode: '8322',
    city: 'Sankt Marein bei Graz-Umgebung'
  },
  {
    postalCode: '8322',
    city: 'St. Margarethen an der Raab'
  },
  {
    postalCode: '8322',
    city: 'Takern'
  },
  {
    postalCode: '8322',
    city: 'Zöbing'
  },
  {
    postalCode: '8322',
    city: 'Siegersdorf'
  },
  {
    postalCode: '8322',
    city: 'Studenzen'
  },
  {
    postalCode: '8323',
    city: 'Kohldorf'
  },
  {
    postalCode: '8323',
    city: 'Krumegg'
  },
  {
    postalCode: '8323',
    city: 'Hirtenfeld'
  },
  {
    postalCode: '8323',
    city: 'Lambach'
  },
  {
    postalCode: '8323',
    city: 'Mittergoggitsch'
  },
  {
    postalCode: '8323',
    city: 'Obergoggitsch'
  },
  {
    postalCode: '8323',
    city: 'Unterbuch'
  },
  {
    postalCode: '8323',
    city: 'Zaunstein'
  },
  {
    postalCode: '8323',
    city: 'Radersdorf'
  },
  {
    postalCode: '8323',
    city: 'Tiefernitz'
  },
  {
    postalCode: '8323',
    city: 'Petersdorf'
  },
  {
    postalCode: '8323',
    city: 'Sankt Marein bei Graz-Markt'
  },
  {
    postalCode: '8323',
    city: 'Sankt Marein bei Graz-Umgebung'
  },
  {
    postalCode: '8323',
    city: 'Goggitsch'
  },
  {
    postalCode: '8324',
    city: 'Berndorf'
  },
  {
    postalCode: '8324',
    city: 'Hof'
  },
  {
    postalCode: '8324',
    city: 'Kirchberg an der Raab'
  },
  {
    postalCode: '8324',
    city: 'Wörth bei Kirchberg an der Raab'
  },
  {
    postalCode: '8324',
    city: 'Oberdorf am Hochegg'
  },
  {
    postalCode: '8324',
    city: 'Radersdorf'
  },
  {
    postalCode: '8324',
    city: 'Tiefernitz'
  },
  {
    postalCode: '8324',
    city: 'Oberstorcha'
  },
  {
    postalCode: '8324',
    city: 'Axbach'
  },
  {
    postalCode: '8324',
    city: 'Petersdorf '
  },
  {
    postalCode: '8324',
    city: 'Sankt Stefan im Rosental'
  },
  {
    postalCode: '8324',
    city: 'Trössengraben'
  },
  {
    postalCode: '8330',
    city: 'Auersbach'
  },
  {
    postalCode: '8330',
    city: 'Wetzelsdorf'
  },
  {
    postalCode: '8330',
    city: 'Edelsbach bei Feldbach'
  },
  {
    postalCode: '8330',
    city: 'Rohr an der Raab'
  },
  {
    postalCode: '8330',
    city: 'Feldbach'
  },
  {
    postalCode: '8330',
    city: 'Pernreith'
  },
  {
    postalCode: '8330',
    city: 'Gniebing'
  },
  {
    postalCode: '8330',
    city: 'Oberweißenbach'
  },
  {
    postalCode: '8330',
    city: 'Paurach'
  },
  {
    postalCode: '8330',
    city: 'Unterweißenbach'
  },
  {
    postalCode: '8330',
    city: 'Edersgraben'
  },
  {
    postalCode: '8330',
    city: 'Gossendorf'
  },
  {
    postalCode: '8330',
    city: 'Höflach'
  },
  {
    postalCode: '8330',
    city: 'Bergl'
  },
  {
    postalCode: '8330',
    city: 'Dörfl'
  },
  {
    postalCode: '8330',
    city: 'Oberkornbach'
  },
  {
    postalCode: '8330',
    city: 'Schützing'
  },
  {
    postalCode: '8330',
    city: 'Leitersdorf im Raabtal'
  },
  {
    postalCode: '8330',
    city: 'Mühldorf bei Feldbach'
  },
  {
    postalCode: '8330',
    city: 'Obergiem'
  },
  {
    postalCode: '8330',
    city: 'Oedt bei Feldbach'
  },
  {
    postalCode: '8330',
    city: 'Petersdorf'
  },
  {
    postalCode: '8330',
    city: 'Reiting'
  },
  {
    postalCode: '8330',
    city: 'Untergiem'
  },
  {
    postalCode: '8330',
    city: 'Pertlstein'
  },
  {
    postalCode: '8330',
    city: 'Raabau'
  },
  {
    postalCode: '8330',
    city: 'Krennach'
  },
  {
    postalCode: '8332',
    city: 'Auersbach'
  },
  {
    postalCode: '8332',
    city: 'Wetzelsdorf'
  },
  {
    postalCode: '8332',
    city: 'Edelsbach bei Feldbach'
  },
  {
    postalCode: '8332',
    city: 'Kaag'
  },
  {
    postalCode: '8332',
    city: 'Rohr an der Raab'
  },
  {
    postalCode: '8332',
    city: 'Mitterfladnitz'
  },
  {
    postalCode: '8332',
    city: 'Fladnitz im Raabtal'
  },
  {
    postalCode: '8332',
    city: 'Gniebing'
  },
  {
    postalCode: '8332',
    city: 'Paurach'
  },
  {
    postalCode: '8333',
    city: 'Auersbach'
  },
  {
    postalCode: '8333',
    city: 'Wetzelsdorf'
  },
  {
    postalCode: '8333',
    city: 'Breitenfeld an der Rittschein'
  },
  {
    postalCode: '8333',
    city: 'Stang bei Hatzendorf'
  },
  {
    postalCode: '8333',
    city: 'Bergl'
  },
  {
    postalCode: '8333',
    city: 'Dörfl'
  },
  {
    postalCode: '8333',
    city: 'Edelsgraben'
  },
  {
    postalCode: '8333',
    city: 'Oberkornbach'
  },
  {
    postalCode: '8333',
    city: 'Schützing'
  },
  {
    postalCode: '8333',
    city: 'Lödersdorf '
  },
  {
    postalCode: '8333',
    city: 'Altenmarkt bei Riegersburg'
  },
  {
    postalCode: '8333',
    city: 'Grub I'
  },
  {
    postalCode: '8333',
    city: 'Krennach'
  },
  {
    postalCode: '8333',
    city: 'Lembach bei Riegersburg'
  },
  {
    postalCode: '8333',
    city: 'Riegersburg'
  },
  {
    postalCode: '8333',
    city: 'Schweinz'
  },
  {
    postalCode: '8334',
    city: 'Bergl'
  },
  {
    postalCode: '8334',
    city: 'Edelsgraben'
  },
  {
    postalCode: '8334',
    city: 'Oberkornbach'
  },
  {
    postalCode: '8334',
    city: 'Lödersdorf I'
  },
  {
    postalCode: '8334',
    city: 'Lödersdorf '
  },
  {
    postalCode: '8341',
    city: 'Baumgarten'
  },
  {
    postalCode: '8341',
    city: 'Wörth'
  },
  {
    postalCode: '8341',
    city: 'Kohlberg'
  },
  {
    postalCode: '8341',
    city: 'Oberstorcha'
  },
  {
    postalCode: '8341',
    city: 'Reith'
  },
  {
    postalCode: '8341',
    city: 'Unterstorcha'
  },
  {
    postalCode: '8341',
    city: 'Axbach'
  },
  {
    postalCode: '8341',
    city: 'Häusla'
  },
  {
    postalCode: '8341',
    city: 'Paldau'
  },
  {
    postalCode: '8341',
    city: 'Pöllau'
  },
  {
    postalCode: '8341',
    city: 'Puch'
  },
  {
    postalCode: '8341',
    city: 'Saaz'
  },
  {
    postalCode: '8341',
    city: 'Perlsdorf'
  },
  {
    postalCode: '8342',
    city: 'Aug'
  },
  {
    postalCode: '8342',
    city: 'Radisch'
  },
  {
    postalCode: '8342',
    city: 'Badenbrunn'
  },
  {
    postalCode: '8342',
    city: 'Baumgarten'
  },
  {
    postalCode: '8342',
    city: 'Wörth'
  },
  {
    postalCode: '8342',
    city: 'Dietersdorf am Gnasbach'
  },
  {
    postalCode: '8342',
    city: 'Burgfried'
  },
  {
    postalCode: '8342',
    city: 'Fischa'
  },
  {
    postalCode: '8342',
    city: 'Gnas'
  },
  {
    postalCode: '8342',
    city: 'Höf'
  },
  {
    postalCode: '8342',
    city: 'Obergnas'
  },
  {
    postalCode: '8342',
    city: 'Pernreith'
  },
  {
    postalCode: '8342',
    city: 'Grabersdorf'
  },
  {
    postalCode: '8342',
    city: 'Kohlberg'
  },
  {
    postalCode: '8342',
    city: 'Krusdorf'
  },
  {
    postalCode: '8342',
    city: 'Hirsdorf'
  },
  {
    postalCode: '8342',
    city: 'Katzelsdorf'
  },
  {
    postalCode: '8342',
    city: 'Kinsdorf'
  },
  {
    postalCode: '8342',
    city: 'Ludersdorf'
  },
  {
    postalCode: '8342',
    city: 'Maierdorf'
  },
  {
    postalCode: '8342',
    city: 'Perlsdorf'
  },
  {
    postalCode: '8342',
    city: 'Ebersdorf'
  },
  {
    postalCode: '8342',
    city: 'Katzendorf'
  },
  {
    postalCode: '8342',
    city: 'Poppendorf'
  },
  {
    postalCode: '8342',
    city: 'Raning'
  },
  {
    postalCode: '8342',
    city: 'Thien'
  },
  {
    postalCode: '8342',
    city: 'Trössing'
  },
  {
    postalCode: '8342',
    city: 'Glatzental'
  },
  {
    postalCode: '8342',
    city: 'Oberauersbach'
  },
  {
    postalCode: '8342',
    city: 'Unterauersbach'
  },
  {
    postalCode: '8343',
    city: 'Grub '
  },
  {
    postalCode: '8343',
    city: 'Waldsberg'
  },
  {
    postalCode: '8343',
    city: 'Katzendorf'
  },
  {
    postalCode: '8343',
    city: 'Poppendorf'
  },
  {
    postalCode: '8343',
    city: 'Hofstätten'
  },
  {
    postalCode: '8343',
    city: 'Trautmannsdorf'
  },
  {
    postalCode: '8344',
    city: 'Bad Gleichenberg'
  },
  {
    postalCode: '8344',
    city: 'Gleichenberg Dorf'
  },
  {
    postalCode: '8344',
    city: 'Klausen'
  },
  {
    postalCode: '8344',
    city: 'Bairisch Kölldorf'
  },
  {
    postalCode: '8344',
    city: 'Gossendorf'
  },
  {
    postalCode: '8344',
    city: 'Pichla'
  },
  {
    postalCode: '8344',
    city: 'Haag'
  },
  {
    postalCode: '8344',
    city: 'Merkendorf'
  },
  {
    postalCode: '8344',
    city: 'Steinbach'
  },
  {
    postalCode: '8344',
    city: 'Waldsberg'
  },
  {
    postalCode: '8344',
    city: 'Wilhelmsdorf'
  },
  {
    postalCode: '8344',
    city: 'Obergiem'
  },
  {
    postalCode: '8344',
    city: 'Petersdorf'
  },
  {
    postalCode: '8344',
    city: 'Pertlstein'
  },
  {
    postalCode: '8344',
    city: 'Trautmannsdorf'
  },
  {
    postalCode: '8345',
    city: 'Haselbach'
  },
  {
    postalCode: '8345',
    city: 'Hof bei Straden'
  },
  {
    postalCode: '8345',
    city: 'Karla'
  },
  {
    postalCode: '8345',
    city: 'Neusetz'
  },
  {
    postalCode: '8345',
    city: 'Radochen'
  },
  {
    postalCode: '8345',
    city: 'Grub '
  },
  {
    postalCode: '8345',
    city: 'Krusdorf'
  },
  {
    postalCode: '8345',
    city: 'Dirnbach'
  },
  {
    postalCode: '8345',
    city: 'Karbach'
  },
  {
    postalCode: '8345',
    city: 'Muggendorf'
  },
  {
    postalCode: '8345',
    city: 'Stainz bei Straden'
  },
  {
    postalCode: '8345',
    city: 'Sulzbach'
  },
  {
    postalCode: '8345',
    city: 'Hart bei Straden'
  },
  {
    postalCode: '8345',
    city: 'Kronnersdorf'
  },
  {
    postalCode: '8345',
    city: 'Marktl'
  },
  {
    postalCode: '8345',
    city: 'Nägelsdorf'
  },
  {
    postalCode: '8345',
    city: 'Schwabau'
  },
  {
    postalCode: '8345',
    city: 'Straden'
  },
  {
    postalCode: '8345',
    city: 'Waasen am Berg'
  },
  {
    postalCode: '8345',
    city: 'Wieden-Klausen'
  },
  {
    postalCode: '8345',
    city: 'Waldprecht'
  },
  {
    postalCode: '8345',
    city: 'Trössing'
  },
  {
    postalCode: '8350',
    city: 'Burgfeld'
  },
  {
    postalCode: '8350',
    city: 'Fehring'
  },
  {
    postalCode: '8350',
    city: 'Höflach'
  },
  {
    postalCode: '8350',
    city: 'Petersdorf I'
  },
  {
    postalCode: '8350',
    city: 'Petzelsdorf bei Fehring'
  },
  {
    postalCode: '8350',
    city: 'Schiefer'
  },
  {
    postalCode: '8350',
    city: 'Hohenbrugg an der Raab'
  },
  {
    postalCode: '8350',
    city: 'Weinberg an der Raab'
  },
  {
    postalCode: '8350',
    city: 'Brunn'
  },
  {
    postalCode: '8350',
    city: 'Johnsdorf'
  },
  {
    postalCode: '8350',
    city: 'Gutendorf'
  },
  {
    postalCode: '8350',
    city: 'Haselbach'
  },
  {
    postalCode: '8350',
    city: 'Mahrensdorf'
  },
  {
    postalCode: '8350',
    city: 'Pertlstein'
  },
  {
    postalCode: '8350',
    city: 'Magland'
  },
  {
    postalCode: '8350',
    city: 'Welten'
  },
  {
    postalCode: '8352',
    city: 'Hohenbrugg an der Raab'
  },
  {
    postalCode: '8352',
    city: 'Weinberg an der Raab'
  },
  {
    postalCode: '8352',
    city: 'Magland'
  },
  {
    postalCode: '8352',
    city: 'Oberlamm'
  },
  {
    postalCode: '8352',
    city: 'Unterlamm'
  },
  {
    postalCode: '8353',
    city: 'Burgfeld'
  },
  {
    postalCode: '8353',
    city: 'Gutendorf'
  },
  {
    postalCode: '8353',
    city: 'Kapfenstein'
  },
  {
    postalCode: '8353',
    city: 'Kölldorf'
  },
  {
    postalCode: '8353',
    city: 'Neustift'
  },
  {
    postalCode: '8353',
    city: 'Pichla'
  },
  {
    postalCode: '8354',
    city: 'Frutten'
  },
  {
    postalCode: '8354',
    city: 'Gießelsdorf'
  },
  {
    postalCode: '8354',
    city: 'Hochstraden'
  },
  {
    postalCode: '8354',
    city: 'Sichauf'
  },
  {
    postalCode: '8354',
    city: 'Aigen'
  },
  {
    postalCode: '8354',
    city: 'Jamm'
  },
  {
    postalCode: '8354',
    city: 'Klapping'
  },
  {
    postalCode: '8354',
    city: 'Plesch'
  },
  {
    postalCode: '8354',
    city: 'Risola'
  },
  {
    postalCode: '8354',
    city: 'Waltra'
  },
  {
    postalCode: '8355',
    city: 'Frutten'
  },
  {
    postalCode: '8355',
    city: 'Hof bei Straden'
  },
  {
    postalCode: '8355',
    city: 'Karla'
  },
  {
    postalCode: '8355',
    city: 'Neusetz'
  },
  {
    postalCode: '8355',
    city: 'Radochen'
  },
  {
    postalCode: '8355',
    city: 'Klöchberg'
  },
  {
    postalCode: '8355',
    city: 'Stainz bei Straden'
  },
  {
    postalCode: '8355',
    city: 'Sulzbach'
  },
  {
    postalCode: '8355',
    city: 'Größing'
  },
  {
    postalCode: '8355',
    city: 'Jörgen'
  },
  {
    postalCode: '8355',
    city: 'Laasen'
  },
  {
    postalCode: '8355',
    city: 'Patzen'
  },
  {
    postalCode: '8355',
    city: 'Pichla bei Radkersburg'
  },
  {
    postalCode: '8355',
    city: 'Tieschen'
  },
  {
    postalCode: '8361',
    city: 'Habegg'
  },
  {
    postalCode: '8361',
    city: 'Hatzendorf'
  },
  {
    postalCode: '8361',
    city: 'Oedgraben'
  },
  {
    postalCode: '8361',
    city: 'Stang bei Hatzendorf'
  },
  {
    postalCode: '8361',
    city: 'Tiefenbach'
  },
  {
    postalCode: '8361',
    city: 'Unterhatzendorf'
  },
  {
    postalCode: '8361',
    city: 'Lödersdorf '
  },
  {
    postalCode: '8361',
    city: 'Oberlamm'
  },
  {
    postalCode: '8362',
    city: 'Stadtbergen'
  },
  {
    postalCode: '8362',
    city: 'Breitenfeld an der Rittschein'
  },
  {
    postalCode: '8362',
    city: 'Maierhofbergen'
  },
  {
    postalCode: '8362',
    city: 'Tiefenbach'
  },
  {
    postalCode: '8362',
    city: 'Aschbach bei Fürstenfeld'
  },
  {
    postalCode: '8362',
    city: 'Kohlgraben'
  },
  {
    postalCode: '8362',
    city: 'Ruppersdorf'
  },
  {
    postalCode: '8362',
    city: 'Söchau'
  },
  {
    postalCode: '8362',
    city: 'Tautendorf bei Fürstenfeld'
  },
  {
    postalCode: '8362',
    city: 'Ebersdorf'
  },
  {
    postalCode: '8362',
    city: 'Hartl bei Fürstenfeld'
  },
  {
    postalCode: '8362',
    city: 'Rittschein'
  },
  {
    postalCode: '8362',
    city: 'Übersbach'
  },
  {
    postalCode: '8362',
    city: 'Oberlamm'
  },
  {
    postalCode: '8380',
    city: 'Grieselstein'
  },
  {
    postalCode: '8380',
    city: 'Henndorf im Burgenland'
  },
  {
    postalCode: '8380',
    city: 'Jennersdorf'
  },
  {
    postalCode: '8380',
    city: 'Rax'
  },
  {
    postalCode: '8380',
    city: 'Neumarkt an der Raab'
  },
  {
    postalCode: '8382',
    city: 'Deutsch Minihof'
  },
  {
    postalCode: '8382',
    city: 'Mogersdorf'
  },
  {
    postalCode: '8382',
    city: 'Wallendorf'
  },
  {
    postalCode: '8382',
    city: 'Krobotek'
  },
  {
    postalCode: '8382',
    city: 'Maria Bild'
  },
  {
    postalCode: '8382',
    city: 'Rosendorf'
  },
  {
    postalCode: '8382',
    city: 'Weichselbaum'
  },
  {
    postalCode: '8383',
    city: 'Doiber'
  },
  {
    postalCode: '8383',
    city: 'Gritsch'
  },
  {
    postalCode: '8383',
    city: 'Oberdrosen'
  },
  {
    postalCode: '8383',
    city: 'Neumarkt an der Raab'
  },
  {
    postalCode: '8383',
    city: 'Sankt Martin an der Raab'
  },
  {
    postalCode: '8383',
    city: 'Eisenberg an der Raab'
  },
  {
    postalCode: '8383',
    city: 'Welten'
  },
  {
    postalCode: '8384',
    city: 'Minihof-Liebau'
  },
  {
    postalCode: '8384',
    city: 'Tauka'
  },
  {
    postalCode: '8384',
    city: 'Windisch-Minihof'
  },
  {
    postalCode: '8384',
    city: 'Bonisdorf'
  },
  {
    postalCode: '8384',
    city: 'Oberdrosen'
  },
  {
    postalCode: '8385',
    city: 'Mühlgraben'
  },
  {
    postalCode: '8385',
    city: 'Bonisdorf'
  },
  {
    postalCode: '8385',
    city: 'Kalch'
  },
  {
    postalCode: '8385',
    city: 'Krottendorf bei Neuhaus am Klausenbach'
  },
  {
    postalCode: '8385',
    city: 'Neuhaus am Klausenbach'
  },
  {
    postalCode: '8385',
    city: 'Welten'
  },
  {
    postalCode: '8401',
    city: 'Wagnitz'
  },
  {
    postalCode: '8401',
    city: 'Großsulz'
  },
  {
    postalCode: '8401',
    city: 'Forst'
  },
  {
    postalCode: '8401',
    city: 'Kalsdorf bei Graz'
  },
  {
    postalCode: '8401',
    city: 'Kleinsulz'
  },
  {
    postalCode: '8401',
    city: 'Thalerhof'
  },
  {
    postalCode: '8401',
    city: 'Unterpremstätten'
  },
  {
    postalCode: '8401',
    city: 'Bierbaum'
  },
  {
    postalCode: '8401',
    city: 'Laa'
  },
  {
    postalCode: '8401',
    city: 'Zettling'
  },
  {
    postalCode: '8402',
    city: 'Werndorf'
  },
  {
    postalCode: '8403',
    city: 'Gralla'
  },
  {
    postalCode: '8403',
    city: 'Kehlsdorf'
  },
  {
    postalCode: '8403',
    city: 'Dexenberg'
  },
  {
    postalCode: '8403',
    city: 'Göttling'
  },
  {
    postalCode: '8403',
    city: 'Jöß'
  },
  {
    postalCode: '8403',
    city: 'Lang'
  },
  {
    postalCode: '8403',
    city: 'Langaberg'
  },
  {
    postalCode: '8403',
    city: 'Schirka'
  },
  {
    postalCode: '8403',
    city: 'Stangersdorf'
  },
  {
    postalCode: '8403',
    city: 'Bachsdorf'
  },
  {
    postalCode: '8403',
    city: 'Lebring'
  },
  {
    postalCode: '8403',
    city: 'Sankt Margarethen bei Lebring'
  },
  {
    postalCode: '8403',
    city: 'Oedt'
  },
  {
    postalCode: '8410',
    city: 'Allerheiligen bei Wildon'
  },
  {
    postalCode: '8410',
    city: 'Dillach'
  },
  {
    postalCode: '8410',
    city: 'Mellach'
  },
  {
    postalCode: '8410',
    city: 'Afram'
  },
  {
    postalCode: '8410',
    city: 'Aug'
  },
  {
    postalCode: '8410',
    city: 'Hart bei Wildon'
  },
  {
    postalCode: '8410',
    city: 'Neudorf'
  },
  {
    postalCode: '8410',
    city: 'Stocking'
  },
  {
    postalCode: '8410',
    city: 'Sukdull'
  },
  {
    postalCode: '8410',
    city: 'Kainach bei Wildon'
  },
  {
    postalCode: '8410',
    city: 'Lichendorf'
  },
  {
    postalCode: '8410',
    city: 'Neudorf ob Wildon'
  },
  {
    postalCode: '8410',
    city: 'Weitendorf'
  },
  {
    postalCode: '8410',
    city: 'Wildon'
  },
  {
    postalCode: '8410',
    city: 'Steindorf'
  },
  {
    postalCode: '8411',
    city: 'Flüssing'
  },
  {
    postalCode: '8411',
    city: 'Hengsberg'
  },
  {
    postalCode: '8411',
    city: 'Kehlsdorf'
  },
  {
    postalCode: '8411',
    city: 'Komberg'
  },
  {
    postalCode: '8411',
    city: 'Kühberg'
  },
  {
    postalCode: '8411',
    city: 'Matzelsdorf'
  },
  {
    postalCode: '8411',
    city: 'Schönberg an der Laßnitz'
  },
  {
    postalCode: '8411',
    city: 'Schrötten an der Laßnitz'
  },
  {
    postalCode: '8411',
    city: 'Grötsch'
  },
  {
    postalCode: '8411',
    city: 'Lamberg'
  },
  {
    postalCode: '8412',
    city: 'Allerheiligen bei Wildon'
  },
  {
    postalCode: '8412',
    city: 'Großfeiting'
  },
  {
    postalCode: '8412',
    city: 'Schwasdorf'
  },
  {
    postalCode: '8412',
    city: 'Siebing'
  },
  {
    postalCode: '8412',
    city: 'Sankt Ulrich am Waasen'
  },
  {
    postalCode: '8412',
    city: 'Sukdull'
  },
  {
    postalCode: '8413',
    city: 'Kleinfeiting'
  },
  {
    postalCode: '8413',
    city: 'Pesendorf'
  },
  {
    postalCode: '8413',
    city: 'Pichla'
  },
  {
    postalCode: '8413',
    city: 'Mitterlabill'
  },
  {
    postalCode: '8413',
    city: 'Badendorf'
  },
  {
    postalCode: '8413',
    city: 'Edelsee'
  },
  {
    postalCode: '8413',
    city: 'Gundersdorf'
  },
  {
    postalCode: '8413',
    city: 'Haslach an der Stiefing'
  },
  {
    postalCode: '8413',
    city: 'Laubegg'
  },
  {
    postalCode: '8413',
    city: 'Oberragnitz'
  },
  {
    postalCode: '8413',
    city: 'Oedt'
  },
  {
    postalCode: '8413',
    city: 'Ragnitz'
  },
  {
    postalCode: '8413',
    city: 'Rohr'
  },
  {
    postalCode: '8413',
    city: 'Baldau'
  },
  {
    postalCode: '8413',
    city: 'Gerbersdorf'
  },
  {
    postalCode: '8413',
    city: 'Kurzragnitz'
  },
  {
    postalCode: '8413',
    city: 'Lappach'
  },
  {
    postalCode: '8413',
    city: 'Prentern'
  },
  {
    postalCode: '8413',
    city: 'Sankt Georgen an der Stiefing'
  },
  {
    postalCode: '8413',
    city: 'Stiefing'
  },
  {
    postalCode: '8413',
    city: 'Stiefingberg'
  },
  {
    postalCode: '8413',
    city: 'Alla'
  },
  {
    postalCode: '8421',
    city: 'Breitenfeld am Tannenriegel'
  },
  {
    postalCode: '8421',
    city: 'Glojach'
  },
  {
    postalCode: '8421',
    city: 'Hainsdorf im Schwarzautal'
  },
  {
    postalCode: '8421',
    city: 'Matzelsdorf'
  },
  {
    postalCode: '8421',
    city: 'Techensdorf'
  },
  {
    postalCode: '8421',
    city: 'Zehensdorf'
  },
  {
    postalCode: '8421',
    city: 'Unterlabill'
  },
  {
    postalCode: '8421',
    city: 'Marchtring'
  },
  {
    postalCode: '8421',
    city: 'Maggau'
  },
  {
    postalCode: '8421',
    city: 'Schwarzau im Schwarzautal'
  },
  {
    postalCode: '8421',
    city: 'Seibuttendorf'
  },
  {
    postalCode: '8421',
    city: 'Wölferberg'
  },
  {
    postalCode: '8421',
    city: 'Wolfsberg im Schwarzautal'
  },
  {
    postalCode: '8422',
    city: 'Matzelsdorf'
  },
  {
    postalCode: '8422',
    city: 'Mettersdorf am Saßbach'
  },
  {
    postalCode: '8422',
    city: 'Hütt'
  },
  {
    postalCode: '8422',
    city: 'Leitersdorf'
  },
  {
    postalCode: '8422',
    city: 'Sankt Nikolai ob Draßling'
  },
  {
    postalCode: '8423',
    city: 'Seibersdorf bei Sankt Veit'
  },
  {
    postalCode: '8423',
    city: 'Labuttendorf'
  },
  {
    postalCode: '8423',
    city: 'Lind bei Sankt Veit am Vogau'
  },
  {
    postalCode: '8423',
    city: 'Lipsch'
  },
  {
    postalCode: '8423',
    city: 'Neutersdorf'
  },
  {
    postalCode: '8423',
    city: 'Rabenhof'
  },
  {
    postalCode: '8423',
    city: 'Sankt Veit am Vogau'
  },
  {
    postalCode: '8423',
    city: 'Wagendorf'
  },
  {
    postalCode: '8423',
    city: 'Vogau'
  },
  {
    postalCode: '8423',
    city: 'Perbersdorf bei Sankt Veit'
  },
  {
    postalCode: '8423',
    city: 'Pichla bei Mureck'
  },
  {
    postalCode: '8424',
    city: 'Gabersdorf'
  },
  {
    postalCode: '8424',
    city: 'Neudorf an der Mur'
  },
  {
    postalCode: '8424',
    city: 'Sajach'
  },
  {
    postalCode: '8430',
    city: 'Gralla'
  },
  {
    postalCode: '8430',
    city: 'Kittenberg'
  },
  {
    postalCode: '8430',
    city: 'Muggenau'
  },
  {
    postalCode: '8430',
    city: 'Grottenhof'
  },
  {
    postalCode: '8430',
    city: 'Kaindorf'
  },
  {
    postalCode: '8430',
    city: 'Kogelberg'
  },
  {
    postalCode: '8430',
    city: 'Bachsdorf'
  },
  {
    postalCode: '8430',
    city: 'Leibnitz'
  },
  {
    postalCode: '8430',
    city: 'Rettenbach'
  },
  {
    postalCode: '8430',
    city: 'Seggauberg'
  },
  {
    postalCode: '8430',
    city: 'Altenberg bei Leibnitz'
  },
  {
    postalCode: '8430',
    city: 'Grössing'
  },
  {
    postalCode: '8430',
    city: 'Maxlon'
  },
  {
    postalCode: '8430',
    city: 'Neutillmitsch'
  },
  {
    postalCode: '8430',
    city: 'Steingrub'
  },
  {
    postalCode: '8430',
    city: 'Tillmitsch Links der Laßnitz'
  },
  {
    postalCode: '8430',
    city: 'Tillmitsch Rechts der Laßnitz'
  },
  {
    postalCode: '8430',
    city: 'Hasendorf an der Mur'
  },
  {
    postalCode: '8430',
    city: 'Leitring'
  },
  {
    postalCode: '8435',
    city: 'Landscha an der Mur'
  },
  {
    postalCode: '8435',
    city: 'Leibnitz'
  },
  {
    postalCode: '8435',
    city: 'Wagendorf'
  },
  {
    postalCode: '8435',
    city: 'Seggauberg'
  },
  {
    postalCode: '8435',
    city: 'Aflenz an der Sulm'
  },
  {
    postalCode: '8435',
    city: 'Leitring'
  },
  {
    postalCode: '8435',
    city: 'Wagna'
  },
  {
    postalCode: '8441',
    city: 'Mantrach'
  },
  {
    postalCode: '8441',
    city: 'Nestelbach'
  },
  {
    postalCode: '8441',
    city: 'Brudersegg'
  },
  {
    postalCode: '8441',
    city: 'Fresing'
  },
  {
    postalCode: '8441',
    city: 'Steinriegel'
  },
  {
    postalCode: '8441',
    city: 'Höch'
  },
  {
    postalCode: '8441',
    city: 'Rettenberg'
  },
  {
    postalCode: '8442',
    city: 'Einöd'
  },
  {
    postalCode: '8442',
    city: 'Fresing'
  },
  {
    postalCode: '8442',
    city: 'Gauitsch'
  },
  {
    postalCode: '8442',
    city: 'Greith'
  },
  {
    postalCode: '8442',
    city: 'Neurath'
  },
  {
    postalCode: '8442',
    city: 'Steinriegel'
  },
  {
    postalCode: '8442',
    city: 'Höch'
  },
  {
    postalCode: '8443',
    city: 'Gleinstätten'
  },
  {
    postalCode: '8443',
    city: 'Haslach'
  },
  {
    postalCode: '8443',
    city: 'Prarath'
  },
  {
    postalCode: '8443',
    city: 'Goldes'
  },
  {
    postalCode: '8443',
    city: 'Obergreith'
  },
  {
    postalCode: '8443',
    city: 'Dornach'
  },
  {
    postalCode: '8443',
    city: 'Maierhof'
  },
  {
    postalCode: '8443',
    city: 'Pistorf'
  },
  {
    postalCode: '8443',
    city: 'Sausal'
  },
  {
    postalCode: '8443',
    city: 'Fantsch'
  },
  {
    postalCode: '8443',
    city: 'Praratheregg'
  },
  {
    postalCode: '8443',
    city: 'Untergreith'
  },
  {
    postalCode: '8443',
    city: 'Gasselsdorf'
  },
  {
    postalCode: '8443',
    city: 'Graschach'
  },
  {
    postalCode: '8444',
    city: 'Brünngraben'
  },
  {
    postalCode: '8444',
    city: 'Fantsch'
  },
  {
    postalCode: '8444',
    city: 'Höch'
  },
  {
    postalCode: '8444',
    city: 'Neudorf im Sausal'
  },
  {
    postalCode: '8444',
    city: 'Reith'
  },
  {
    postalCode: '8444',
    city: 'Rettenberg'
  },
  {
    postalCode: '8444',
    city: 'Sankt Andrä im Sausal'
  },
  {
    postalCode: '8444',
    city: 'Sausal'
  },
  {
    postalCode: '8444',
    city: 'Michlgleinz'
  },
  {
    postalCode: '8444',
    city: 'Weniggleinz'
  },
  {
    postalCode: '8451',
    city: 'Kranach'
  },
  {
    postalCode: '8451',
    city: 'Nestelberg'
  },
  {
    postalCode: '8451',
    city: 'Oberfahrenbach'
  },
  {
    postalCode: '8451',
    city: 'Heimschuh'
  },
  {
    postalCode: '8451',
    city: 'Kittenberg'
  },
  {
    postalCode: '8451',
    city: 'Muggenau'
  },
  {
    postalCode: '8451',
    city: 'Pernitsch'
  },
  {
    postalCode: '8451',
    city: 'Unterfahrenbach'
  },
  {
    postalCode: '8451',
    city: 'Gauitsch'
  },
  {
    postalCode: '8451',
    city: 'Neurath'
  },
  {
    postalCode: '8451',
    city: 'Oberlupitscheni'
  },
  {
    postalCode: '8451',
    city: 'Schönegg'
  },
  {
    postalCode: '8451',
    city: 'Seggauberg'
  },
  {
    postalCode: '8451',
    city: 'Altenberg bei Leibnitz'
  },
  {
    postalCode: '8451',
    city: 'Steingrub'
  },
  {
    postalCode: '8452',
    city: 'Eichberg-Trautenburg'
  },
  {
    postalCode: '8452',
    city: 'Burgstall'
  },
  {
    postalCode: '8452',
    city: 'Goldes'
  },
  {
    postalCode: '8452',
    city: 'Großklein'
  },
  {
    postalCode: '8452',
    city: 'Mantrach'
  },
  {
    postalCode: '8452',
    city: 'Mattelsberg'
  },
  {
    postalCode: '8452',
    city: 'Nestelbach'
  },
  {
    postalCode: '8452',
    city: 'Nestelberg'
  },
  {
    postalCode: '8452',
    city: 'Oberfahrenbach'
  },
  {
    postalCode: '8452',
    city: 'Unterfahrenbach'
  },
  {
    postalCode: '8452',
    city: 'Maierhof'
  },
  {
    postalCode: '8452',
    city: 'Eichberg'
  },
  {
    postalCode: '8452',
    city: 'Narrath'
  },
  {
    postalCode: '8453',
    city: 'Eichberg-Trautenburg'
  },
  {
    postalCode: '8453',
    city: 'Goldes'
  },
  {
    postalCode: '8453',
    city: 'Kitzelsdorf'
  },
  {
    postalCode: '8453',
    city: 'Obergreith'
  },
  {
    postalCode: '8453',
    city: 'Eichberg'
  },
  {
    postalCode: '8453',
    city: 'Gündorf'
  },
  {
    postalCode: '8453',
    city: 'Radiga'
  },
  {
    postalCode: '8453',
    city: 'Saggau'
  },
  {
    postalCode: '8453',
    city: 'Sankt Johann im Saggautal'
  },
  {
    postalCode: '8453',
    city: 'Untergreith'
  },
  {
    postalCode: '8454',
    city: 'Arnfels'
  },
  {
    postalCode: '8454',
    city: 'Maltschach'
  },
  {
    postalCode: '8454',
    city: 'Eichberg-Trautenburg'
  },
  {
    postalCode: '8454',
    city: 'Altenbach'
  },
  {
    postalCode: '8454',
    city: 'Hardegg'
  },
  {
    postalCode: '8454',
    city: 'Kitzelsdorf'
  },
  {
    postalCode: '8454',
    city: 'Krast'
  },
  {
    postalCode: '8454',
    city: 'Oberhaag'
  },
  {
    postalCode: '8454',
    city: 'Eichberg'
  },
  {
    postalCode: '8454',
    city: 'Saggau'
  },
  {
    postalCode: '8454',
    city: 'Remschnigg'
  },
  {
    postalCode: '8455',
    city: 'Pongratzen'
  },
  {
    postalCode: '8455',
    city: 'Wuggitz'
  },
  {
    postalCode: '8455',
    city: 'Altenbach'
  },
  {
    postalCode: '8455',
    city: 'Lieschen'
  },
  {
    postalCode: '8455',
    city: 'Obergreith'
  },
  {
    postalCode: '8455',
    city: 'Oberhaag'
  },
  {
    postalCode: '8455',
    city: 'Bischofegg'
  },
  {
    postalCode: '8461',
    city: 'Ewitsch'
  },
  {
    postalCode: '8461',
    city: 'Wielitsch'
  },
  {
    postalCode: '8461',
    city: 'Zieregg'
  },
  {
    postalCode: '8461',
    city: 'Ehrenhausen'
  },
  {
    postalCode: '8461',
    city: 'Landscha an der Mur'
  },
  {
    postalCode: '8461',
    city: 'Gamlitz'
  },
  {
    postalCode: '8461',
    city: 'Obervogau'
  },
  {
    postalCode: '8461',
    city: 'Ottenberg'
  },
  {
    postalCode: '8461',
    city: 'Ratsch an der Weinstraße'
  },
  {
    postalCode: '8461',
    city: 'Retznei'
  },
  {
    postalCode: '8461',
    city: 'Unterlupitscheni'
  },
  {
    postalCode: '8461',
    city: 'Sulztal'
  },
  {
    postalCode: '8461',
    city: 'Vogau'
  },
  {
    postalCode: '8462',
    city: 'Eckberg'
  },
  {
    postalCode: '8462',
    city: 'Gamlitz'
  },
  {
    postalCode: '8462',
    city: 'Grubtal'
  },
  {
    postalCode: '8462',
    city: 'Kranach'
  },
  {
    postalCode: '8462',
    city: 'Labitschberg'
  },
  {
    postalCode: '8462',
    city: 'Sernau'
  },
  {
    postalCode: '8462',
    city: 'Steinbach'
  },
  {
    postalCode: '8462',
    city: 'Fötschach'
  },
  {
    postalCode: '8462',
    city: 'Oberfahrenbach'
  },
  {
    postalCode: '8462',
    city: 'Oberlupitscheni'
  },
  {
    postalCode: '8463',
    city: 'Eichberg-Trautenburg'
  },
  {
    postalCode: '8463',
    city: 'Kranach'
  },
  {
    postalCode: '8463',
    city: 'Steinbach'
  },
  {
    postalCode: '8463',
    city: 'Fötschach'
  },
  {
    postalCode: '8463',
    city: 'Glanz'
  },
  {
    postalCode: '8463',
    city: 'Langegg'
  },
  {
    postalCode: '8463',
    city: 'Pößnitz'
  },
  {
    postalCode: '8463',
    city: 'Oberfahrenbach'
  },
  {
    postalCode: '8463',
    city: 'Leutschach'
  },
  {
    postalCode: '8463',
    city: 'Großwalz'
  },
  {
    postalCode: '8463',
    city: 'Remschnigg'
  },
  {
    postalCode: '8463',
    city: 'Schloßberg'
  },
  {
    postalCode: '8471',
    city: 'Oberschwarza'
  },
  {
    postalCode: '8471',
    city: 'Seibersdorf bei Sankt Veit'
  },
  {
    postalCode: '8471',
    city: 'Unterschwarza'
  },
  {
    postalCode: '8471',
    city: 'Graßnitzberg'
  },
  {
    postalCode: '8471',
    city: 'Obegg'
  },
  {
    postalCode: '8471',
    city: 'Spielfeld'
  },
  {
    postalCode: '8471',
    city: 'Gersdorf an der Mur'
  },
  {
    postalCode: '8472',
    city: 'Obervogau'
  },
  {
    postalCode: '8472',
    city: 'Gersdorf an der Mur'
  },
  {
    postalCode: '8472',
    city: 'Straß in Steiermark'
  },
  {
    postalCode: '8472',
    city: 'Vogau'
  },
  {
    postalCode: '8473',
    city: 'Lichendorf'
  },
  {
    postalCode: '8473',
    city: 'Weitersfeld an der Mur'
  },
  {
    postalCode: '8480',
    city: 'Eichfeld'
  },
  {
    postalCode: '8480',
    city: 'Oberrakitsch'
  },
  {
    postalCode: '8480',
    city: 'Misselsdorf'
  },
  {
    postalCode: '8480',
    city: 'Mureck'
  },
  {
    postalCode: '8481',
    city: 'Hainsdorf-Brunnsee'
  },
  {
    postalCode: '8481',
    city: 'Pichla bei Mureck'
  },
  {
    postalCode: '8481',
    city: 'Priebing'
  },
  {
    postalCode: '8481',
    city: 'Siebing'
  },
  {
    postalCode: '8481',
    city: 'Weinburg am Saßbach'
  },
  {
    postalCode: '8482',
    city: 'Diepersdorf'
  },
  {
    postalCode: '8482',
    city: 'Fluttendorf'
  },
  {
    postalCode: '8482',
    city: 'Gosdorf'
  },
  {
    postalCode: '8482',
    city: 'Misselsdorf'
  },
  {
    postalCode: '8482',
    city: 'Ratschendorf'
  },
  {
    postalCode: '8483',
    city: 'Deutsch Goritz'
  },
  {
    postalCode: '8483',
    city: 'Hofstätten bei Deutsch Goritz'
  },
  {
    postalCode: '8483',
    city: 'Krobathen'
  },
  {
    postalCode: '8483',
    city: 'Oberspitz'
  },
  {
    postalCode: '8483',
    city: 'Salsach'
  },
  {
    postalCode: '8483',
    city: 'Schrötten bei Deutsch Goritz'
  },
  {
    postalCode: '8483',
    city: 'Unterspitz'
  },
  {
    postalCode: '8483',
    city: 'Haselbach'
  },
  {
    postalCode: '8483',
    city: 'Ratschendorf'
  },
  {
    postalCode: '8483',
    city: 'Hart bei Straden'
  },
  {
    postalCode: '8483',
    city: 'Nägelsdorf'
  },
  {
    postalCode: '8484',
    city: 'Weixelbaum'
  },
  {
    postalCode: '8484',
    city: 'Haselbach'
  },
  {
    postalCode: '8484',
    city: 'Dietzen'
  },
  {
    postalCode: '8484',
    city: 'Donnersdorf'
  },
  {
    postalCode: '8484',
    city: 'Hürth'
  },
  {
    postalCode: '8484',
    city: 'Oberpurkla'
  },
  {
    postalCode: '8484',
    city: 'Unterpurkla'
  },
  {
    postalCode: '8484',
    city: 'Karla'
  },
  {
    postalCode: '8484',
    city: 'Radochen'
  },
  {
    postalCode: '8490',
    city: 'Bad Radkersburg'
  },
  {
    postalCode: '8490',
    city: 'Altneudörfl'
  },
  {
    postalCode: '8490',
    city: 'Dedenitz'
  },
  {
    postalCode: '8490',
    city: 'Goritz bei Radkersburg'
  },
  {
    postalCode: '8490',
    city: 'Hummersdorf'
  },
  {
    postalCode: '8490',
    city: 'Laafeld'
  },
  {
    postalCode: '8490',
    city: 'Pfarrsdorf'
  },
  {
    postalCode: '8490',
    city: 'Pridahof'
  },
  {
    postalCode: '8490',
    city: 'Sicheldorf'
  },
  {
    postalCode: '8490',
    city: 'Zelting'
  },
  {
    postalCode: '8492',
    city: 'Dietzen'
  },
  {
    postalCode: '8492',
    city: 'Dornau'
  },
  {
    postalCode: '8492',
    city: 'Drauchen'
  },
  {
    postalCode: '8492',
    city: 'Halbenrain'
  },
  {
    postalCode: '8492',
    city: 'Hürth'
  },
  {
    postalCode: '8492',
    city: 'Klöchberg'
  },
  {
    postalCode: '8492',
    city: 'Altneudörfl'
  },
  {
    postalCode: '8493',
    city: 'Deutsch Haseldorf'
  },
  {
    postalCode: '8493',
    city: 'Gruisla'
  },
  {
    postalCode: '8493',
    city: 'Klöch'
  },
  {
    postalCode: '8493',
    city: 'Klöchberg'
  },
  {
    postalCode: '8493',
    city: 'Pölten'
  },
  {
    postalCode: '8493',
    city: 'Pichla bei Radkersburg'
  },
  {
    postalCode: '8501',
    city: 'Lieboch'
  },
  {
    postalCode: '8501',
    city: 'Schadendorf'
  },
  {
    postalCode: '8501',
    city: 'Spatenhof'
  },
  {
    postalCode: '8502',
    city: 'Blumegg'
  },
  {
    postalCode: '8502',
    city: 'Breitenbach in der Weststeiermark'
  },
  {
    postalCode: '8502',
    city: 'Heuholz'
  },
  {
    postalCode: '8502',
    city: 'Hötschdorf'
  },
  {
    postalCode: '8502',
    city: 'Lannach'
  },
  {
    postalCode: '8502',
    city: 'Sajach'
  },
  {
    postalCode: '8502',
    city: 'Teipl'
  },
  {
    postalCode: '8503',
    city: 'Blumegg'
  },
  {
    postalCode: '8503',
    city: 'Teipl'
  },
  {
    postalCode: '8503',
    city: 'Tobisberg'
  },
  {
    postalCode: '8503',
    city: 'Oisnitz'
  },
  {
    postalCode: '8503',
    city: 'Sankt Josef (Weststeiermark)'
  },
  {
    postalCode: '8503',
    city: 'Tobisegg'
  },
  {
    postalCode: '8503',
    city: 'Grafendorf bei Stainz'
  },
  {
    postalCode: '8503',
    city: 'Graggerer'
  },
  {
    postalCode: '8503',
    city: 'Wetzelsdorfberg'
  },
  {
    postalCode: '8503',
    city: 'Wetzelsdorf in der Weststeiermark'
  },
  {
    postalCode: '8503',
    city: 'Wuschan'
  },
  {
    postalCode: '8504',
    city: 'Leitersdorf'
  },
  {
    postalCode: '8504',
    city: 'Klein-Preding'
  },
  {
    postalCode: '8504',
    city: 'Preding'
  },
  {
    postalCode: '8504',
    city: 'Tobis'
  },
  {
    postalCode: '8504',
    city: 'Tobisberg'
  },
  {
    postalCode: '8504',
    city: 'Wieselsdorf'
  },
  {
    postalCode: '8504',
    city: 'Grötsch'
  },
  {
    postalCode: '8504',
    city: 'Grafendorf bei Stainz'
  },
  {
    postalCode: '8504',
    city: 'Mettersdorf'
  },
  {
    postalCode: '8504',
    city: 'Wetzelsdorf in der Weststeiermark'
  },
  {
    postalCode: '8504',
    city: 'Zehndorf'
  },
  {
    postalCode: '8504',
    city: 'Pöls an der Wieserbahn'
  },
  {
    postalCode: '8504',
    city: 'Wuschan'
  },
  {
    postalCode: '8504',
    city: 'Zwaring'
  },
  {
    postalCode: '8505',
    city: 'Sausal'
  },
  {
    postalCode: '8505',
    city: 'Flamberg'
  },
  {
    postalCode: '8505',
    city: 'Greith'
  },
  {
    postalCode: '8505',
    city: 'Grötsch'
  },
  {
    postalCode: '8505',
    city: 'Lamperstätten'
  },
  {
    postalCode: '8505',
    city: 'Mitteregg'
  },
  {
    postalCode: '8505',
    city: 'Mollitsch'
  },
  {
    postalCode: '8505',
    city: 'Oberjahring'
  },
  {
    postalCode: '8505',
    city: 'Petzles'
  },
  {
    postalCode: '8505',
    city: 'Sankt Nikolai im Sausal'
  },
  {
    postalCode: '8505',
    city: 'Unterjahring'
  },
  {
    postalCode: '8505',
    city: 'Waldschach'
  },
  {
    postalCode: '8510',
    city: 'Greim'
  },
  {
    postalCode: '8510',
    city: 'Vochera am Weinberg'
  },
  {
    postalCode: '8510',
    city: 'Ettendorf bei Stainz'
  },
  {
    postalCode: '8510',
    city: 'Pichling bei Stainz'
  },
  {
    postalCode: '8510',
    city: 'Sommereben'
  },
  {
    postalCode: '8510',
    city: 'Wald in der Weststeiermark'
  },
  {
    postalCode: '8510',
    city: 'Angenofen'
  },
  {
    postalCode: '8510',
    city: 'Rainbach'
  },
  {
    postalCode: '8510',
    city: 'Sierling'
  },
  {
    postalCode: '8510',
    city: 'Teufenbach'
  },
  {
    postalCode: '8510',
    city: 'Trog'
  },
  {
    postalCode: '8510',
    city: 'Graschuh'
  },
  {
    postalCode: '8510',
    city: 'Herbersdorf'
  },
  {
    postalCode: '8510',
    city: 'Rassach'
  },
  {
    postalCode: '8510',
    city: 'Gamsgebirg'
  },
  {
    postalCode: '8510',
    city: 'Kothvogel'
  },
  {
    postalCode: '8510',
    city: 'Neurath'
  },
  {
    postalCode: '8510',
    city: 'Stainz'
  },
  {
    postalCode: '8510',
    city: 'Grafendorf bei Stainz'
  },
  {
    postalCode: '8510',
    city: 'Neudorf bei Stainz'
  },
  {
    postalCode: '8510',
    city: 'Stallhof'
  },
  {
    postalCode: '8511',
    city: 'Pichling bei Stainz'
  },
  {
    postalCode: '8511',
    city: 'Rossegg'
  },
  {
    postalCode: '8511',
    city: 'Greisdorf'
  },
  {
    postalCode: '8511',
    city: 'Sommereben'
  },
  {
    postalCode: '8511',
    city: 'Steinreib'
  },
  {
    postalCode: '8511',
    city: 'Grubberg'
  },
  {
    postalCode: '8511',
    city: 'Gundersdorf'
  },
  {
    postalCode: '8511',
    city: 'Teipl'
  },
  {
    postalCode: '8511',
    city: 'Teufenbach'
  },
  {
    postalCode: '8511',
    city: 'Lemsitz'
  },
  {
    postalCode: '8511',
    city: 'Lichtenhof'
  },
  {
    postalCode: '8511',
    city: 'Pirkhof'
  },
  {
    postalCode: '8511',
    city: 'Sankt Stefan ob Stainz'
  },
  {
    postalCode: '8511',
    city: 'Zirknitz'
  },
  {
    postalCode: '8521',
    city: 'Gussendorf'
  },
  {
    postalCode: '8521',
    city: 'Neudorf im Sausal'
  },
  {
    postalCode: '8521',
    city: 'Waldschach'
  },
  {
    postalCode: '8521',
    city: 'Lassenberg'
  },
  {
    postalCode: '8521',
    city: 'Schönaich'
  },
  {
    postalCode: '8521',
    city: 'Weniggleinz'
  },
  {
    postalCode: '8521',
    city: 'Wettmannstätten'
  },
  {
    postalCode: '8521',
    city: 'Wohlsdorf'
  },
  {
    postalCode: '8521',
    city: 'Zehndorf'
  },
  {
    postalCode: '8522',
    city: 'Groß Sankt Florian'
  },
  {
    postalCode: '8522',
    city: 'Grünau an der Laßnitz'
  },
  {
    postalCode: '8522',
    city: 'Gussendorf'
  },
  {
    postalCode: '8522',
    city: 'Kraubath in der Weststeiermark'
  },
  {
    postalCode: '8522',
    city: 'Krottendorf an der Laßnitz'
  },
  {
    postalCode: '8522',
    city: 'Lebing'
  },
  {
    postalCode: '8522',
    city: 'Petzelsdorf in der Weststeiermark'
  },
  {
    postalCode: '8522',
    city: 'Tanzelsdorf'
  },
  {
    postalCode: '8522',
    city: 'Vochera an der Laßnitz'
  },
  {
    postalCode: '8522',
    city: 'Lasselsdorf'
  },
  {
    postalCode: '8522',
    city: 'Rassach'
  },
  {
    postalCode: '8522',
    city: 'Fantsch'
  },
  {
    postalCode: '8522',
    city: 'Otternitz'
  },
  {
    postalCode: '8522',
    city: 'Neudorf bei Stainz'
  },
  {
    postalCode: '8522',
    city: 'Grub bei Groß Sankt Florian'
  },
  {
    postalCode: '8522',
    city: 'Hasreith'
  },
  {
    postalCode: '8522',
    city: 'Michlgleinz'
  },
  {
    postalCode: '8522',
    city: 'Mönichgleinz'
  },
  {
    postalCode: '8522',
    city: 'Nassau'
  },
  {
    postalCode: '8522',
    city: 'Sulzhof'
  },
  {
    postalCode: '8522',
    city: 'Unterbergla'
  },
  {
    postalCode: '8523',
    city: 'Gersdorf'
  },
  {
    postalCode: '8523',
    city: 'Deutschlandsberg'
  },
  {
    postalCode: '8523',
    city: 'Freidorf an der Laßnitz'
  },
  {
    postalCode: '8523',
    city: 'Freidorfer Gleinz'
  },
  {
    postalCode: '8523',
    city: 'Laßnitz'
  },
  {
    postalCode: '8523',
    city: 'Schamberg'
  },
  {
    postalCode: '8523',
    city: 'Zeierling'
  },
  {
    postalCode: '8523',
    city: 'Groß Sankt Florian'
  },
  {
    postalCode: '8524',
    city: 'Bergegg'
  },
  {
    postalCode: '8524',
    city: 'Feldbaum'
  },
  {
    postalCode: '8524',
    city: 'Sallegg'
  },
  {
    postalCode: '8524',
    city: 'Furth'
  },
  {
    postalCode: '8524',
    city: 'Bad Gams'
  },
  {
    postalCode: '8524',
    city: 'Gersdorf'
  },
  {
    postalCode: '8524',
    city: 'Greim'
  },
  {
    postalCode: '8524',
    city: 'Hohenfeld'
  },
  {
    postalCode: '8524',
    city: 'Mitteregg'
  },
  {
    postalCode: '8524',
    city: 'Müllegg'
  },
  {
    postalCode: '8524',
    city: 'Niedergams'
  },
  {
    postalCode: '8524',
    city: 'Vochera am Weinberg'
  },
  {
    postalCode: '8524',
    city: 'Deutschlandsberg'
  },
  {
    postalCode: '8524',
    city: 'Freiland bei Deutschlandsberg'
  },
  {
    postalCode: '8524',
    city: 'Rassach'
  },
  {
    postalCode: '8524',
    city: 'Gamsgebirg'
  },
  {
    postalCode: '8530',
    city: 'Sallegg'
  },
  {
    postalCode: '8530',
    city: 'Niedergams'
  },
  {
    postalCode: '8530',
    city: 'Deutschlandsberg'
  },
  {
    postalCode: '8530',
    city: 'Freidorfer Gleinz'
  },
  {
    postalCode: '8530',
    city: 'Freiland bei Deutschlandsberg'
  },
  {
    postalCode: '8530',
    city: 'Garanas'
  },
  {
    postalCode: '8530',
    city: 'Gressenberg'
  },
  {
    postalCode: '8530',
    city: 'Hohlbach'
  },
  {
    postalCode: '8530',
    city: 'Hollenegg'
  },
  {
    postalCode: '8530',
    city: 'Kresbach'
  },
  {
    postalCode: '8530',
    city: 'Neuberg'
  },
  {
    postalCode: '8530',
    city: 'Kloster'
  },
  {
    postalCode: '8530',
    city: 'Rettenbach'
  },
  {
    postalCode: '8530',
    city: 'Osterwitz'
  },
  {
    postalCode: '8530',
    city: 'Kruckenberg'
  },
  {
    postalCode: '8530',
    city: 'Rostock'
  },
  {
    postalCode: '8530',
    city: 'Trahütten'
  },
  {
    postalCode: '8541',
    city: 'Deutschlandsberg'
  },
  {
    postalCode: '8541',
    city: 'Garanas'
  },
  {
    postalCode: '8541',
    city: 'Oberfresen'
  },
  {
    postalCode: '8541',
    city: 'Gressenberg'
  },
  {
    postalCode: '8541',
    city: 'Aichegg'
  },
  {
    postalCode: '8541',
    city: 'Hollenegg'
  },
  {
    postalCode: '8541',
    city: 'Neuberg'
  },
  {
    postalCode: '8541',
    city: 'Rettenbach'
  },
  {
    postalCode: '8541',
    city: 'Trag'
  },
  {
    postalCode: '8541',
    city: 'Kruckenberg'
  },
  {
    postalCode: '8541',
    city: 'Limberg'
  },
  {
    postalCode: '8541',
    city: 'Mitterlimberg'
  },
  {
    postalCode: '8541',
    city: 'Kerschbaum'
  },
  {
    postalCode: '8541',
    city: 'Mainsdorf'
  },
  {
    postalCode: '8541',
    city: 'Schwanberg'
  },
  {
    postalCode: '8541',
    city: 'Unterfresen'
  },
  {
    postalCode: '8542',
    city: 'Hohlbach'
  },
  {
    postalCode: '8542',
    city: 'Mitterlimberg'
  },
  {
    postalCode: '8542',
    city: 'Bergla'
  },
  {
    postalCode: '8542',
    city: 'Greith'
  },
  {
    postalCode: '8542',
    city: 'Freidorf'
  },
  {
    postalCode: '8542',
    city: 'Kerschbaum'
  },
  {
    postalCode: '8542',
    city: 'Korbin'
  },
  {
    postalCode: '8542',
    city: 'Moos'
  },
  {
    postalCode: '8542',
    city: 'Poppenforst'
  },
  {
    postalCode: '8542',
    city: 'Sankt Peter im Sulmtal'
  },
  {
    postalCode: '8542',
    city: 'Wieden'
  },
  {
    postalCode: '8543',
    city: 'Aigen'
  },
  {
    postalCode: '8543',
    city: 'Bergla'
  },
  {
    postalCode: '8543',
    city: 'Dörfla'
  },
  {
    postalCode: '8543',
    city: 'Greith'
  },
  {
    postalCode: '8543',
    city: 'Gutenacker'
  },
  {
    postalCode: '8543',
    city: 'Oberhart'
  },
  {
    postalCode: '8543',
    city: 'Otternitz'
  },
  {
    postalCode: '8543',
    city: 'Reitererberg'
  },
  {
    postalCode: '8543',
    city: 'Sulb'
  },
  {
    postalCode: '8543',
    city: 'Dietmannsdorf im Sulmtal'
  },
  {
    postalCode: '8543',
    city: 'Gasselsdorf'
  },
  {
    postalCode: '8543',
    city: 'Graschach'
  },
  {
    postalCode: '8543',
    city: 'Hasreith'
  },
  {
    postalCode: '8544',
    city: 'Obergreith'
  },
  {
    postalCode: '8544',
    city: 'Brunn'
  },
  {
    postalCode: '8544',
    city: 'Jagernigg'
  },
  {
    postalCode: '8544',
    city: 'Pölfing'
  },
  {
    postalCode: '8544',
    city: 'Untergreith'
  },
  {
    postalCode: '8544',
    city: 'Oberhart'
  },
  {
    postalCode: '8544',
    city: 'Gasselsdorf'
  },
  {
    postalCode: '8544',
    city: 'Kopreinigg'
  },
  {
    postalCode: '8544',
    city: 'Pitschgauegg'
  },
  {
    postalCode: '8544',
    city: 'Tombach'
  },
  {
    postalCode: '8551',
    city: 'Aichberg'
  },
  {
    postalCode: '8551',
    city: 'Mitterlimberg'
  },
  {
    postalCode: '8551',
    city: 'Hörmsdorf'
  },
  {
    postalCode: '8551',
    city: 'Pitschgau'
  },
  {
    postalCode: '8551',
    city: 'Jagernigg'
  },
  {
    postalCode: '8551',
    city: 'Oberhart'
  },
  {
    postalCode: '8551',
    city: 'Pitschgauegg'
  },
  {
    postalCode: '8551',
    city: 'Buchenberg'
  },
  {
    postalCode: '8551',
    city: 'Kogl'
  },
  {
    postalCode: '8551',
    city: 'Pörbach'
  },
  {
    postalCode: '8551',
    city: 'Wernersdorf'
  },
  {
    postalCode: '8551',
    city: 'Unterfresen'
  },
  {
    postalCode: '8551',
    city: 'Wiel'
  },
  {
    postalCode: '8551',
    city: 'Altenmarkt'
  },
  {
    postalCode: '8551',
    city: 'Aug'
  },
  {
    postalCode: '8551',
    city: 'Buchegg'
  },
  {
    postalCode: '8551',
    city: 'Etzendorf'
  },
  {
    postalCode: '8551',
    city: 'Gaißeregg'
  },
  {
    postalCode: '8551',
    city: 'Gieselegg'
  },
  {
    postalCode: '8551',
    city: 'Lamberg'
  },
  {
    postalCode: '8551',
    city: 'Vordersdorf'
  },
  {
    postalCode: '8551',
    city: 'Wies'
  },
  {
    postalCode: '8552',
    city: 'Aibl'
  },
  {
    postalCode: '8552',
    city: 'Aichberg'
  },
  {
    postalCode: '8552',
    city: 'Hadernigg'
  },
  {
    postalCode: '8552',
    city: 'Sankt Bartlmä'
  },
  {
    postalCode: '8552',
    city: 'Sankt Lorenzen'
  },
  {
    postalCode: '8552',
    city: 'Staritsch'
  },
  {
    postalCode: '8552',
    city: 'Eibiswald'
  },
  {
    postalCode: '8552',
    city: 'Bachholz'
  },
  {
    postalCode: '8552',
    city: 'Feisternitz'
  },
  {
    postalCode: '8552',
    city: 'Kleinradl'
  },
  {
    postalCode: '8552',
    city: 'Kornriegl'
  },
  {
    postalCode: '8552',
    city: 'Oberlatein'
  },
  {
    postalCode: '8552',
    city: 'Stammeregg'
  },
  {
    postalCode: '8552',
    city: 'Sterglegg'
  },
  {
    postalCode: '8552',
    city: 'Haselbach'
  },
  {
    postalCode: '8552',
    city: 'Hörmsdorf'
  },
  {
    postalCode: '8552',
    city: 'Pitschgau'
  },
  {
    postalCode: '8552',
    city: 'Pörbach'
  },
  {
    postalCode: '8552',
    city: 'Altenmarkt'
  },
  {
    postalCode: '8552',
    city: 'Vordersdorf'
  },
  {
    postalCode: '8553',
    city: 'Hadernigg'
  },
  {
    postalCode: '8553',
    city: 'Rothwein'
  },
  {
    postalCode: '8553',
    city: 'Sankt Bartlmä'
  },
  {
    postalCode: '8553',
    city: 'Staritsch'
  },
  {
    postalCode: '8553',
    city: 'Krumbach'
  },
  {
    postalCode: '8553',
    city: 'Mitterstraßen'
  },
  {
    postalCode: '8553',
    city: 'Sankt Oswald ob Eibiswald'
  },
  {
    postalCode: '8553',
    city: 'Soboth'
  },
  {
    postalCode: '8553',
    city: 'Buchenberg'
  },
  {
    postalCode: '8554',
    city: 'Laaken'
  },
  {
    postalCode: '8554',
    city: 'Soboth'
  },
  {
    postalCode: '8561',
    city: 'Schadendorfberg'
  },
  {
    postalCode: '8561',
    city: 'Södingberg'
  },
  {
    postalCode: '8561',
    city: 'Stein'
  },
  {
    postalCode: '8561',
    city: 'Berndorf'
  },
  {
    postalCode: '8561',
    city: 'Moosing'
  },
  {
    postalCode: '8561',
    city: 'Großsöding'
  },
  {
    postalCode: '8561',
    city: 'Kleinsöding'
  },
  {
    postalCode: '8561',
    city: 'Pichling bei Mooskirchen'
  },
  {
    postalCode: '8562',
    city: 'Bubendorf'
  },
  {
    postalCode: '8562',
    city: 'Fluttendorf'
  },
  {
    postalCode: '8562',
    city: 'Gießenberg'
  },
  {
    postalCode: '8562',
    city: 'Kniezenberg'
  },
  {
    postalCode: '8562',
    city: 'Mooskirchen'
  },
  {
    postalCode: '8562',
    city: 'Neudorf bei Mooskirchen'
  },
  {
    postalCode: '8562',
    city: 'Rauchegg'
  },
  {
    postalCode: '8562',
    city: 'Rubmannsberg'
  },
  {
    postalCode: '8562',
    city: 'Stögersdorf'
  },
  {
    postalCode: '8562',
    city: 'Pirkhof'
  },
  {
    postalCode: '8563',
    city: 'Greisdorf'
  },
  {
    postalCode: '8563',
    city: 'Sommereben'
  },
  {
    postalCode: '8563',
    city: 'Krottendorf bei Ligist'
  },
  {
    postalCode: '8563',
    city: 'Dietenberg'
  },
  {
    postalCode: '8563',
    city: 'Grabenwarth'
  },
  {
    postalCode: '8563',
    city: 'Ligistberg'
  },
  {
    postalCode: '8563',
    city: 'Ligist Markt'
  },
  {
    postalCode: '8563',
    city: 'Oberwald'
  },
  {
    postalCode: '8563',
    city: 'Steinberg'
  },
  {
    postalCode: '8563',
    city: 'Unterwald'
  },
  {
    postalCode: '8563',
    city: 'Sankt Martin am Wöllmißberg'
  },
  {
    postalCode: '8564',
    city: 'Gasselberg'
  },
  {
    postalCode: '8564',
    city: 'Gaisfeld'
  },
  {
    postalCode: '8564',
    city: 'Kleingaisfeld'
  },
  {
    postalCode: '8564',
    city: 'Krottendorf bei Ligist'
  },
  {
    postalCode: '8564',
    city: 'Muggauberg'
  },
  {
    postalCode: '8564',
    city: 'Dietenberg'
  },
  {
    postalCode: '8564',
    city: 'Ligistberg'
  },
  {
    postalCode: '8564',
    city: 'Hallersdorf'
  },
  {
    postalCode: '8564',
    city: 'Sankt Johann ob Hohenburg'
  },
  {
    postalCode: '8564',
    city: 'Voitsberg'
  },
  {
    postalCode: '8565',
    city: 'Hallersdorf'
  },
  {
    postalCode: '8565',
    city: 'Hausdorf'
  },
  {
    postalCode: '8565',
    city: 'Köppling'
  },
  {
    postalCode: '8565',
    city: 'Moosing'
  },
  {
    postalCode: '8565',
    city: 'Muggauberg'
  },
  {
    postalCode: '8565',
    city: 'Neudorf bei Sankt Johann ob Hohenburg'
  },
  {
    postalCode: '8565',
    city: 'Sankt Johann ob Hohenburg'
  },
  {
    postalCode: '8570',
    city: 'Bärnbach'
  },
  {
    postalCode: '8570',
    city: 'Hochtregist'
  },
  {
    postalCode: '8570',
    city: 'Krottendorf bei Ligist'
  },
  {
    postalCode: '8570',
    city: 'Rosental an der Kainach'
  },
  {
    postalCode: '8570',
    city: 'Großwöllmiß'
  },
  {
    postalCode: '8570',
    city: 'Kleinwöllmiß'
  },
  {
    postalCode: '8570',
    city: 'Aichegg'
  },
  {
    postalCode: '8570',
    city: 'Voitsberg'
  },
  {
    postalCode: '8572',
    city: 'Bärnbach'
  },
  {
    postalCode: '8572',
    city: 'Hochtregist'
  },
  {
    postalCode: '8572',
    city: 'Piber'
  },
  {
    postalCode: '8572',
    city: 'Hemmerberg'
  },
  {
    postalCode: '8572',
    city: 'Piberegg'
  },
  {
    postalCode: '8572',
    city: 'Piberegg Rollsiedlung'
  },
  {
    postalCode: '8573',
    city: 'Hochtregist'
  },
  {
    postalCode: '8573',
    city: 'Gallmannsegg'
  },
  {
    postalCode: '8573',
    city: 'Hadergasse'
  },
  {
    postalCode: '8573',
    city: 'Breitenbach'
  },
  {
    postalCode: '8573',
    city: 'Kainach bei Voitsberg'
  },
  {
    postalCode: '8573',
    city: 'Oswaldgraben'
  },
  {
    postalCode: '8573',
    city: 'Hemmerberg'
  },
  {
    postalCode: '8573',
    city: 'Kohlschwarz'
  },
  {
    postalCode: '8580',
    city: 'Edelschrott'
  },
  {
    postalCode: '8580',
    city: 'Kreuzberg'
  },
  {
    postalCode: '8580',
    city: 'Graden'
  },
  {
    postalCode: '8580',
    city: 'Gradenberg'
  },
  {
    postalCode: '8580',
    city: 'Köflach'
  },
  {
    postalCode: '8580',
    city: 'Piber'
  },
  {
    postalCode: '8580',
    city: 'Pichling bei Köflach'
  },
  {
    postalCode: '8580',
    city: 'Puchbach'
  },
  {
    postalCode: '8580',
    city: 'Piberegg'
  },
  {
    postalCode: '8580',
    city: 'Rosental an der Kainach'
  },
  {
    postalCode: '8580',
    city: 'Großwöllmiß'
  },
  {
    postalCode: '8580',
    city: 'Kleinwöllmiß'
  },
  {
    postalCode: '8580',
    city: 'Sankt Martin am Wöllmißberg'
  },
  {
    postalCode: '8582',
    city: 'Bärnbach'
  },
  {
    postalCode: '8582',
    city: 'Piber'
  },
  {
    postalCode: '8582',
    city: 'Rosental an der Kainach'
  },
  {
    postalCode: '8582',
    city: 'Voitsberg'
  },
  {
    postalCode: '8583',
    city: 'Edelschrott'
  },
  {
    postalCode: '8583',
    city: 'Kreuzberg'
  },
  {
    postalCode: '8583',
    city: 'Hochgößnitz'
  },
  {
    postalCode: '8583',
    city: 'Modriach'
  },
  {
    postalCode: '8583',
    city: 'Pack'
  },
  {
    postalCode: '8583',
    city: 'Sankt Martin am Wöllmißberg'
  },
  {
    postalCode: '8584',
    city: 'Hirschegg'
  },
  {
    postalCode: '8591',
    city: 'Hochgößnitz'
  },
  {
    postalCode: '8591',
    city: 'Niedergößnitz'
  },
  {
    postalCode: '8591',
    city: 'Puchbach'
  },
  {
    postalCode: '8591',
    city: 'Kemetberg'
  },
  {
    postalCode: '8591',
    city: 'Kirchberg'
  },
  {
    postalCode: '8591',
    city: 'Maria Lankowitz'
  },
  {
    postalCode: '8591',
    city: 'Rosental an der Kainach'
  },
  {
    postalCode: '8592',
    city: 'Graden'
  },
  {
    postalCode: '8592',
    city: 'Kleinlobming'
  },
  {
    postalCode: '8592',
    city: 'Kirchberg'
  },
  {
    postalCode: '8592',
    city: 'Salla'
  },
  {
    postalCode: '8593',
    city: 'Graden'
  },
  {
    postalCode: '8593',
    city: 'Breitenbach'
  },
  {
    postalCode: '8593',
    city: 'Piberegg'
  },
  {
    postalCode: '8593',
    city: 'Salla'
  },
  {
    postalCode: '8600',
    city: 'Berndorf'
  },
  {
    postalCode: '8600',
    city: 'Bruck an der Mur'
  },
  {
    postalCode: '8600',
    city: 'Kaltbach'
  },
  {
    postalCode: '8600',
    city: 'Pischk'
  },
  {
    postalCode: '8600',
    city: 'Pischkberg'
  },
  {
    postalCode: '8600',
    city: 'Übelstein'
  },
  {
    postalCode: '8600',
    city: 'Wiener Vorstadt'
  },
  {
    postalCode: '8600',
    city: 'Frauenberg'
  },
  {
    postalCode: '8600',
    city: 'Arndorf'
  },
  {
    postalCode: '8600',
    city: 'Diemlach'
  },
  {
    postalCode: '8600',
    city: 'Emberg bei Bruck an der Mur'
  },
  {
    postalCode: '8600',
    city: 'Kapfenberg'
  },
  {
    postalCode: '8600',
    city: 'Schörgendorf'
  },
  {
    postalCode: '8600',
    city: 'Stegg'
  },
  {
    postalCode: '8600',
    city: 'Heuberg'
  },
  {
    postalCode: '8600',
    city: 'Kotzgraben'
  },
  {
    postalCode: '8600',
    city: 'Mötschlach'
  },
  {
    postalCode: '8600',
    city: 'Oberaich'
  },
  {
    postalCode: '8600',
    city: 'Oberdorf'
  },
  {
    postalCode: '8600',
    city: 'Picheldorf'
  },
  {
    postalCode: '8600',
    city: 'Sankt Dionysen'
  },
  {
    postalCode: '8600',
    city: 'Urgental'
  },
  {
    postalCode: '8600',
    city: 'Utschtal'
  },
  {
    postalCode: '8605',
    city: 'Arndorf'
  },
  {
    postalCode: '8605',
    city: 'Deuchendorf'
  },
  {
    postalCode: '8605',
    city: 'Diemlach'
  },
  {
    postalCode: '8605',
    city: 'Einöd'
  },
  {
    postalCode: '8605',
    city: 'Emberg bei Kapfenberg'
  },
  {
    postalCode: '8605',
    city: 'Hafendorf'
  },
  {
    postalCode: '8605',
    city: 'Kapfenberg'
  },
  {
    postalCode: '8605',
    city: 'Krottendorf'
  },
  {
    postalCode: '8605',
    city: 'Pötschach'
  },
  {
    postalCode: '8605',
    city: 'Pötschen'
  },
  {
    postalCode: '8605',
    city: 'Sankt Martin'
  },
  {
    postalCode: '8605',
    city: 'Schörgendorf'
  },
  {
    postalCode: '8605',
    city: 'Winkl'
  },
  {
    postalCode: '8605',
    city: 'Göritz'
  },
  {
    postalCode: '8605',
    city: 'Parschlug'
  },
  {
    postalCode: '8605',
    city: 'Pönegg'
  },
  {
    postalCode: '8605',
    city: 'Gassing'
  },
  {
    postalCode: '8611',
    city: 'Hüttengraben'
  },
  {
    postalCode: '8611',
    city: 'Oberdorf'
  },
  {
    postalCode: '8611',
    city: 'Obertal'
  },
  {
    postalCode: '8611',
    city: 'Rastal'
  },
  {
    postalCode: '8611',
    city: 'Sankt Katharein an der Laming'
  },
  {
    postalCode: '8611',
    city: 'Untertal'
  },
  {
    postalCode: '8612',
    city: 'Oberort'
  },
  {
    postalCode: '8612',
    city: 'Pichl-Großdorf'
  },
  {
    postalCode: '8612',
    city: 'Tal'
  },
  {
    postalCode: '8612',
    city: 'Unterort'
  },
  {
    postalCode: '8614',
    city: 'Sankt Erhard'
  },
  {
    postalCode: '8614',
    city: 'Sankt Jakob-Breitenau'
  },
  {
    postalCode: '8614',
    city: 'Gabraun'
  },
  {
    postalCode: '8614',
    city: 'Roßgraben'
  },
  {
    postalCode: '8616',
    city: 'Amassegg'
  },
  {
    postalCode: '8616',
    city: 'Gasen'
  },
  {
    postalCode: '8616',
    city: 'Mitterbach'
  },
  {
    postalCode: '8616',
    city: 'Sonnleitberg'
  },
  {
    postalCode: '8621',
    city: 'Sankt Ilgen'
  },
  {
    postalCode: '8621',
    city: 'Fölz'
  },
  {
    postalCode: '8621',
    city: 'Hinterberg'
  },
  {
    postalCode: '8621',
    city: 'Palbersdorf'
  },
  {
    postalCode: '8621',
    city: 'Thörl'
  },
  {
    postalCode: '8622',
    city: 'Etmißl'
  },
  {
    postalCode: '8622',
    city: 'Lonschitz'
  },
  {
    postalCode: '8622',
    city: 'Oisching'
  },
  {
    postalCode: '8623',
    city: 'Aflenz Kurort'
  },
  {
    postalCode: '8623',
    city: 'Dörflach'
  },
  {
    postalCode: '8623',
    city: 'Jauring'
  },
  {
    postalCode: '8623',
    city: 'Tutschach'
  },
  {
    postalCode: '8623',
    city: 'Fölz'
  },
  {
    postalCode: '8624',
    city: 'Döllach'
  },
  {
    postalCode: '8624',
    city: 'Graßnitz'
  },
  {
    postalCode: '8624',
    city: 'Hinterberg'
  },
  {
    postalCode: '8624',
    city: 'Au bei Turnau'
  },
  {
    postalCode: '8625',
    city: 'Pogusch'
  },
  {
    postalCode: '8625',
    city: 'Göriach'
  },
  {
    postalCode: '8625',
    city: 'Stübming'
  },
  {
    postalCode: '8625',
    city: 'Thal'
  },
  {
    postalCode: '8625',
    city: 'Turnau'
  },
  {
    postalCode: '8630',
    city: 'Gußwerk'
  },
  {
    postalCode: '8630',
    city: 'Halltal'
  },
  {
    postalCode: '8630',
    city: 'Mooshuben'
  },
  {
    postalCode: '8630',
    city: 'Rechengraben'
  },
  {
    postalCode: '8630',
    city: 'Walstern'
  },
  {
    postalCode: '8630',
    city: 'Mariazell'
  },
  {
    postalCode: '8630',
    city: 'Rasing'
  },
  {
    postalCode: '8630',
    city: 'Grünau'
  },
  {
    postalCode: '8630',
    city: 'Sankt Sebastian'
  },
  {
    postalCode: '8630',
    city: 'Ulreichsberg'
  },
  {
    postalCode: '8632',
    city: 'Greith'
  },
  {
    postalCode: '8632',
    city: 'Gußwerk'
  },
  {
    postalCode: '8632',
    city: 'Weichselboden'
  },
  {
    postalCode: '8632',
    city: 'Freingraben'
  },
  {
    postalCode: '8632',
    city: 'Schöneben'
  },
  {
    postalCode: '8634',
    city: 'Aschbach'
  },
  {
    postalCode: '8634',
    city: 'Wegscheid'
  },
  {
    postalCode: '8635',
    city: 'Gollrad'
  },
  {
    postalCode: '8636',
    city: 'Seewiesen'
  },
  {
    postalCode: '8641',
    city: 'Frauenberg'
  },
  {
    postalCode: '8641',
    city: 'Graschnitzgraben'
  },
  {
    postalCode: '8641',
    city: 'Pötschach'
  },
  {
    postalCode: '8641',
    city: 'Sankt Lorenzen im Mürztal'
  },
  {
    postalCode: '8641',
    city: 'Graschnitz'
  },
  {
    postalCode: '8641',
    city: 'Sankt Marein im Mürztal'
  },
  {
    postalCode: '8641',
    city: 'Schaldorf'
  },
  {
    postalCode: '8641',
    city: 'Sonnleiten-Wieden'
  },
  {
    postalCode: '8642',
    city: 'Göritz'
  },
  {
    postalCode: '8642',
    city: 'Fuscht'
  },
  {
    postalCode: '8642',
    city: 'Gassing'
  },
  {
    postalCode: '8642',
    city: 'Lesing'
  },
  {
    postalCode: '8642',
    city: 'Mödersdorf'
  },
  {
    postalCode: '8642',
    city: 'Mürzgraben'
  },
  {
    postalCode: '8642',
    city: 'Nechelheim'
  },
  {
    postalCode: '8642',
    city: 'Pogusch'
  },
  {
    postalCode: '8642',
    city: 'Sankt Lorenzen im Mürztal'
  },
  {
    postalCode: '8642',
    city: 'Scheuchenegg'
  },
  {
    postalCode: '8643',
    city: 'Allerheiligen im Mürztal'
  },
  {
    postalCode: '8643',
    city: 'Edelsdorf'
  },
  {
    postalCode: '8643',
    city: 'Jasnitz'
  },
  {
    postalCode: '8643',
    city: 'Leopersdorf'
  },
  {
    postalCode: '8643',
    city: 'Sölsnitz'
  },
  {
    postalCode: '8643',
    city: 'Wieden'
  },
  {
    postalCode: '8644',
    city: 'Mürzhofen'
  },
  {
    postalCode: '8644',
    city: 'Alt-Hadersdorf'
  },
  {
    postalCode: '8644',
    city: 'Mürzgraben'
  },
  {
    postalCode: '8650',
    city: 'Leopersdorf'
  },
  {
    postalCode: '8650',
    city: 'Kindberg'
  },
  {
    postalCode: '8652',
    city: 'Leopersdorf'
  },
  {
    postalCode: '8652',
    city: 'Kindberg'
  },
  {
    postalCode: '8652',
    city: 'Alt-Hadersdorf'
  },
  {
    postalCode: '8653',
    city: 'Edelsdorf'
  },
  {
    postalCode: '8653',
    city: 'Brandstatt'
  },
  {
    postalCode: '8653',
    city: 'Dickenbach'
  },
  {
    postalCode: '8653',
    city: 'Fladenbach'
  },
  {
    postalCode: '8653',
    city: 'Fochnitz'
  },
  {
    postalCode: '8653',
    city: 'Hollersbach'
  },
  {
    postalCode: '8653',
    city: 'Possegg'
  },
  {
    postalCode: '8653',
    city: 'Retsch'
  },
  {
    postalCode: '8653',
    city: 'Sonnberg'
  },
  {
    postalCode: '8653',
    city: 'Stanz im Mürztal'
  },
  {
    postalCode: '8653',
    city: 'Traßnitz'
  },
  {
    postalCode: '8653',
    city: 'Unteralm'
  },
  {
    postalCode: '8654',
    city: 'Falkenstein'
  },
  {
    postalCode: '8654',
    city: 'Fischbach'
  },
  {
    postalCode: '8654',
    city: 'Völlegg'
  },
  {
    postalCode: '8654',
    city: 'Fochnitz'
  },
  {
    postalCode: '8661',
    city: 'Mitterdorf im Mürztal'
  },
  {
    postalCode: '8661',
    city: 'Wartberg im Mürztal'
  },
  {
    postalCode: '8662',
    city: 'Freßnitz'
  },
  {
    postalCode: '8662',
    city: 'Sommer'
  },
  {
    postalCode: '8662',
    city: 'Lutschaun'
  },
  {
    postalCode: '8662',
    city: 'Mitterdorf im Mürztal'
  },
  {
    postalCode: '8663',
    city: 'Großveitsch'
  },
  {
    postalCode: '8663',
    city: 'Kleinveitsch'
  },
  {
    postalCode: '8663',
    city: 'Niederaigen'
  },
  {
    postalCode: '8663',
    city: 'Veitsch'
  },
  {
    postalCode: '8664',
    city: 'Großveitsch'
  },
  {
    postalCode: '8664',
    city: 'Kleinveitsch'
  },
  {
    postalCode: '8665',
    city: 'Feistritzberg'
  },
  {
    postalCode: '8665',
    city: 'Hönigsberg'
  },
  {
    postalCode: '8665',
    city: 'Langenwang'
  },
  {
    postalCode: '8665',
    city: 'Langenwang-Schwöbing'
  },
  {
    postalCode: '8665',
    city: 'Mitterberg'
  },
  {
    postalCode: '8665',
    city: 'Pretul'
  },
  {
    postalCode: '8665',
    city: 'Traibach'
  },
  {
    postalCode: '8670',
    city: 'Freßnitz'
  },
  {
    postalCode: '8670',
    city: 'Freßnitzgraben'
  },
  {
    postalCode: '8670',
    city: 'Krieglach'
  },
  {
    postalCode: '8670',
    city: 'Krieglach-Schwöbing'
  },
  {
    postalCode: '8670',
    city: 'Malleisten'
  },
  {
    postalCode: '8670',
    city: 'Massing'
  },
  {
    postalCode: '8670',
    city: 'Sommer'
  },
  {
    postalCode: '8670',
    city: 'Feistritzberg'
  },
  {
    postalCode: '8670',
    city: 'Langenwang-Schwöbing'
  },
  {
    postalCode: '8671',
    city: 'Alpl'
  },
  {
    postalCode: '8671',
    city: 'St. Kathrein am Hauenstein'
  },
  {
    postalCode: '8672',
    city: 'Kirchenviertel'
  },
  {
    postalCode: '8672',
    city: 'Landau'
  },
  {
    postalCode: '8672',
    city: 'St. Kathrein am Hauenstein'
  },
  {
    postalCode: '8673',
    city: 'Falkenstein'
  },
  {
    postalCode: '8673',
    city: 'Völlegg'
  },
  {
    postalCode: '8673',
    city: 'Grubbauer'
  },
  {
    postalCode: '8673',
    city: 'Kirchenviertel'
  },
  {
    postalCode: '8673',
    city: 'Filzmoos'
  },
  {
    postalCode: '8673',
    city: 'Steinhöf'
  },
  {
    postalCode: '8673',
    city: 'Landau'
  },
  {
    postalCode: '8673',
    city: 'Feistritz'
  },
  {
    postalCode: '8673',
    city: 'Pacher'
  },
  {
    postalCode: '8674',
    city: 'Grubbauer'
  },
  {
    postalCode: '8674',
    city: 'Feistritzwald'
  },
  {
    postalCode: '8674',
    city: 'Inneres Kaltenegg'
  },
  {
    postalCode: '8674',
    city: 'Rettenegg'
  },
  {
    postalCode: '8674',
    city: 'Filzmoos'
  },
  {
    postalCode: '8680',
    city: 'Auersbach'
  },
  {
    postalCode: '8680',
    city: 'Eichhorntal'
  },
  {
    postalCode: '8680',
    city: 'Ganz'
  },
  {
    postalCode: '8680',
    city: 'Lambach'
  },
  {
    postalCode: '8680',
    city: 'Schöneben'
  },
  {
    postalCode: '8680',
    city: 'Lechen'
  },
  {
    postalCode: '8680',
    city: 'Edlach'
  },
  {
    postalCode: '8680',
    city: 'Hönigsberg'
  },
  {
    postalCode: '8680',
    city: 'Kohleben'
  },
  {
    postalCode: '8680',
    city: 'Mürzzuschlag'
  },
  {
    postalCode: '8680',
    city: 'Pernreit'
  },
  {
    postalCode: '8680',
    city: 'Spital am Semmering'
  },
  {
    postalCode: '8682',
    city: 'Hönigsberg'
  },
  {
    postalCode: '8682',
    city: 'Langenwang'
  },
  {
    postalCode: '8682',
    city: 'Lechen'
  },
  {
    postalCode: '8682',
    city: 'Pretul'
  },
  {
    postalCode: '8684',
    city: 'Spital am Semmering'
  },
  {
    postalCode: '8685',
    city: 'Spital am Semmering'
  },
  {
    postalCode: '8685',
    city: 'Steinhaus am Semmering'
  },
  {
    postalCode: '8691',
    city: 'Altenberg'
  },
  {
    postalCode: '8691',
    city: 'Greith'
  },
  {
    postalCode: '8691',
    city: 'Kapellen'
  },
  {
    postalCode: '8691',
    city: 'Raxen'
  },
  {
    postalCode: '8691',
    city: 'Stojen'
  },
  {
    postalCode: '8692',
    city: 'Greith'
  },
  {
    postalCode: '8692',
    city: 'Alpl'
  },
  {
    postalCode: '8692',
    city: 'Arzbach'
  },
  {
    postalCode: '8692',
    city: 'Dorf'
  },
  {
    postalCode: '8692',
    city: 'Krampen'
  },
  {
    postalCode: '8692',
    city: 'Lechen'
  },
  {
    postalCode: '8692',
    city: 'Neudörfl'
  },
  {
    postalCode: '8692',
    city: 'Veitschbach'
  },
  {
    postalCode: '8693',
    city: 'Dobrein'
  },
  {
    postalCode: '8693',
    city: 'Dürrenthal'
  },
  {
    postalCode: '8693',
    city: 'Lanau'
  },
  {
    postalCode: '8693',
    city: 'Mürzsteg'
  },
  {
    postalCode: '8693',
    city: 'Niederalpl'
  },
  {
    postalCode: '8693',
    city: 'Scheiterboden'
  },
  {
    postalCode: '8693',
    city: 'Tebrin'
  },
  {
    postalCode: '8694',
    city: 'Steinalpl'
  },
  {
    postalCode: '8694',
    city: 'Frein an der Mürz'
  },
  {
    postalCode: '8694',
    city: 'Kaltenbach'
  },
  {
    postalCode: '8694',
    city: 'Lahnsattel'
  },
  {
    postalCode: '8694',
    city: 'Ulreichsberg'
  },
  {
    postalCode: '8700',
    city: 'Donawitz'
  },
  {
    postalCode: '8700',
    city: 'Göß'
  },
  {
    postalCode: '8700',
    city: 'Hinterberg'
  },
  {
    postalCode: '8700',
    city: 'Judendorf'
  },
  {
    postalCode: '8700',
    city: 'Leitendorf'
  },
  {
    postalCode: '8700',
    city: 'Leoben'
  },
  {
    postalCode: '8712',
    city: 'Niklasdorf'
  },
  {
    postalCode: '8712',
    city: 'Kletschach'
  },
  {
    postalCode: '8712',
    city: 'Köllach'
  },
  {
    postalCode: '8712',
    city: 'Prentgraben'
  },
  {
    postalCode: '8712',
    city: 'Proleb'
  },
  {
    postalCode: '8713',
    city: 'Kaisersberg'
  },
  {
    postalCode: '8713',
    city: 'Lichtensteinerberg'
  },
  {
    postalCode: '8713',
    city: 'Lobming'
  },
  {
    postalCode: '8713',
    city: 'Niederdorf'
  },
  {
    postalCode: '8713',
    city: 'Sankt Stefan ob Leoben'
  },
  {
    postalCode: '8713',
    city: 'Zmöllach'
  },
  {
    postalCode: '8713',
    city: 'Greith'
  },
  {
    postalCode: '8714',
    city: 'Kraubath an der Mur'
  },
  {
    postalCode: '8714',
    city: 'Kraubathgraben'
  },
  {
    postalCode: '8714',
    city: 'Leising'
  },
  {
    postalCode: '8714',
    city: 'Niederdorf'
  },
  {
    postalCode: '8714',
    city: 'Preg'
  },
  {
    postalCode: '8715',
    city: 'Altendorf'
  },
  {
    postalCode: '8715',
    city: 'Feistritz bei Knittelfeld'
  },
  {
    postalCode: '8715',
    city: 'Fötschach'
  },
  {
    postalCode: '8715',
    city: 'Gottsbach'
  },
  {
    postalCode: '8715',
    city: 'Leistach'
  },
  {
    postalCode: '8715',
    city: 'Pichl'
  },
  {
    postalCode: '8715',
    city: 'Preg'
  },
  {
    postalCode: '8715',
    city: 'Preggraben'
  },
  {
    postalCode: '8715',
    city: 'Ritzendorf'
  },
  {
    postalCode: '8715',
    city: 'Sankt Benedikten'
  },
  {
    postalCode: '8715',
    city: 'Sankt Lorenzen bei Knittelfeld'
  },
  {
    postalCode: '8715',
    city: 'Schütt'
  },
  {
    postalCode: '8715',
    city: 'Untermur'
  },
  {
    postalCode: '8715',
    city: 'Fentsch'
  },
  {
    postalCode: '8715',
    city: 'Ugendorf'
  },
  {
    postalCode: '8720',
    city: 'Apfelberg'
  },
  {
    postalCode: '8720',
    city: 'Landschach'
  },
  {
    postalCode: '8720',
    city: 'Flatschach'
  },
  {
    postalCode: '8720',
    city: 'Knittelfeld'
  },
  {
    postalCode: '8720',
    city: 'Hautzenbichl'
  },
  {
    postalCode: '8720',
    city: 'Kobenz'
  },
  {
    postalCode: '8720',
    city: 'Neuhautzenbichl'
  },
  {
    postalCode: '8720',
    city: 'Oberfarrach'
  },
  {
    postalCode: '8720',
    city: 'Raßnitz'
  },
  {
    postalCode: '8720',
    city: 'Reifersdorf'
  },
  {
    postalCode: '8720',
    city: 'Unterfarrach'
  },
  {
    postalCode: '8720',
    city: 'Glein'
  },
  {
    postalCode: '8720',
    city: 'Mitterbach'
  },
  {
    postalCode: '8720',
    city: 'Rachau'
  },
  {
    postalCode: '8720',
    city: 'Rattenberg'
  },
  {
    postalCode: '8720',
    city: 'Dürnberg'
  },
  {
    postalCode: '8720',
    city: 'Neuhofen'
  },
  {
    postalCode: '8720',
    city: 'Fötschach'
  },
  {
    postalCode: '8720',
    city: 'Gobernitz'
  },
  {
    postalCode: '8720',
    city: 'Kroisbach'
  },
  {
    postalCode: '8720',
    city: 'Obermur'
  },
  {
    postalCode: '8720',
    city: 'Sankt Margarethen bei Knittelfeld'
  },
  {
    postalCode: '8720',
    city: 'Sankt Margarethen bei Knittelfeld Sdlg'
  },
  {
    postalCode: '8724',
    city: 'Einhörn'
  },
  {
    postalCode: '8724',
    city: 'Ingering I'
  },
  {
    postalCode: '8724',
    city: 'Lind'
  },
  {
    postalCode: '8724',
    city: 'Maßweg'
  },
  {
    postalCode: '8724',
    city: 'Pausendorf'
  },
  {
    postalCode: '8724',
    city: 'Sachendorf'
  },
  {
    postalCode: '8724',
    city: 'Schönberg'
  },
  {
    postalCode: '8724',
    city: 'Spielberg'
  },
  {
    postalCode: '8724',
    city: 'Weyern'
  },
  {
    postalCode: '8731',
    city: 'Bischoffeld'
  },
  {
    postalCode: '8731',
    city: 'Gaal'
  },
  {
    postalCode: '8731',
    city: 'Gaalgraben'
  },
  {
    postalCode: '8731',
    city: 'Graden'
  },
  {
    postalCode: '8731',
    city: 'Ingering II'
  },
  {
    postalCode: '8731',
    city: 'Puchschachen'
  },
  {
    postalCode: '8731',
    city: 'Schattenberg'
  },
  {
    postalCode: '8732',
    city: 'Puchschachen'
  },
  {
    postalCode: '8732',
    city: 'Unterfarrach'
  },
  {
    postalCode: '8732',
    city: 'Dürnberg'
  },
  {
    postalCode: '8732',
    city: 'Hart'
  },
  {
    postalCode: '8732',
    city: 'Neuhofen'
  },
  {
    postalCode: '8732',
    city: 'Seckau'
  },
  {
    postalCode: '8732',
    city: 'Sonnwenddorf'
  },
  {
    postalCode: '8733',
    city: 'Moos'
  },
  {
    postalCode: '8733',
    city: 'Feistritzgraben'
  },
  {
    postalCode: '8733',
    city: 'Fentsch'
  },
  {
    postalCode: '8733',
    city: 'Fressenberg'
  },
  {
    postalCode: '8733',
    city: 'Greith'
  },
  {
    postalCode: '8733',
    city: 'Hof'
  },
  {
    postalCode: '8733',
    city: 'Laas'
  },
  {
    postalCode: '8733',
    city: 'Mitterfeld'
  },
  {
    postalCode: '8733',
    city: 'Prankh'
  },
  {
    postalCode: '8733',
    city: 'Sankt Marein bei Knittelfeld'
  },
  {
    postalCode: '8733',
    city: 'Sankt Martha'
  },
  {
    postalCode: '8733',
    city: 'Wasserleith'
  },
  {
    postalCode: '8733',
    city: 'Kniepaß'
  },
  {
    postalCode: '8734',
    city: 'Apfelberg'
  },
  {
    postalCode: '8734',
    city: 'Großlobming'
  },
  {
    postalCode: '8734',
    city: 'Kleinlobming'
  },
  {
    postalCode: '8734',
    city: 'Mitterlobming'
  },
  {
    postalCode: '8734',
    city: 'Thann'
  },
  {
    postalCode: '8740',
    city: 'Allersdorf'
  },
  {
    postalCode: '8740',
    city: 'Möbersdorf'
  },
  {
    postalCode: '8740',
    city: 'Möbersdorfsiedlung'
  },
  {
    postalCode: '8740',
    city: 'Laing'
  },
  {
    postalCode: '8740',
    city: 'Lind'
  },
  {
    postalCode: '8740',
    city: 'Farrach'
  },
  {
    postalCode: '8740',
    city: 'Neufisching'
  },
  {
    postalCode: '8740',
    city: 'Neuzeltweg'
  },
  {
    postalCode: '8740',
    city: 'Pfaffendorf'
  },
  {
    postalCode: '8740',
    city: 'Zeltweg'
  },
  {
    postalCode: '8741',
    city: 'Baumkirchen'
  },
  {
    postalCode: '8741',
    city: 'Eppenstein'
  },
  {
    postalCode: '8741',
    city: 'Größenberg'
  },
  {
    postalCode: '8741',
    city: 'Kathal'
  },
  {
    postalCode: '8741',
    city: 'Mühldorf'
  },
  {
    postalCode: '8741',
    city: 'Schoberegg'
  },
  {
    postalCode: '8741',
    city: 'Schwarzenbach am Größing'
  },
  {
    postalCode: '8741',
    city: 'Kleinlobming'
  },
  {
    postalCode: '8741',
    city: 'Mitterlobming'
  },
  {
    postalCode: '8741',
    city: 'Allersdorf'
  },
  {
    postalCode: '8741',
    city: 'Baierdorf'
  },
  {
    postalCode: '8741',
    city: 'Fisching'
  },
  {
    postalCode: '8741',
    city: 'Großfeistritz'
  },
  {
    postalCode: '8741',
    city: 'Murdorf'
  },
  {
    postalCode: '8741',
    city: 'Pichling'
  },
  {
    postalCode: '8741',
    city: 'Kothgraben'
  },
  {
    postalCode: '8741',
    city: 'Reisstraße'
  },
  {
    postalCode: '8741',
    city: 'Weißkirchen in Steiermark'
  },
  {
    postalCode: '8742',
    city: 'Großprethal'
  },
  {
    postalCode: '8742',
    city: 'Kathal in Obdachegg'
  },
  {
    postalCode: '8742',
    city: 'Kleinprethal'
  },
  {
    postalCode: '8742',
    city: 'Obdachegg'
  },
  {
    postalCode: '8742',
    city: 'Sankt Georgen in Obdachegg'
  },
  {
    postalCode: '8742',
    city: 'Amering'
  },
  {
    postalCode: '8742',
    city: 'Kathal'
  },
  {
    postalCode: '8742',
    city: 'Schwarzenbach am Größing'
  },
  {
    postalCode: '8742',
    city: 'Granitzen'
  },
  {
    postalCode: '8742',
    city: 'Obdach'
  },
  {
    postalCode: '8742',
    city: 'Rötsch'
  },
  {
    postalCode: '8742',
    city: 'Warbach'
  },
  {
    postalCode: '8742',
    city: 'Ossach'
  },
  {
    postalCode: '8742',
    city: 'Bärnthal'
  },
  {
    postalCode: '8742',
    city: 'Lavantegg'
  },
  {
    postalCode: '8742',
    city: 'Sankt Anna-Feriensiedlung'
  },
  {
    postalCode: '8742',
    city: 'Winterleiten'
  },
  {
    postalCode: '8742',
    city: 'Zanitzen'
  },
  {
    postalCode: '8742',
    city: 'Katschwald'
  },
  {
    postalCode: '8742',
    city: 'Kienberg'
  },
  {
    postalCode: '8742',
    city: 'Mönchegg'
  },
  {
    postalCode: '8750',
    city: 'Gasselsdorf'
  },
  {
    postalCode: '8750',
    city: 'Judenburg'
  },
  {
    postalCode: '8750',
    city: 'Ritzersdorf'
  },
  {
    postalCode: '8750',
    city: 'Strettweg'
  },
  {
    postalCode: '8750',
    city: 'Waltersdorf'
  },
  {
    postalCode: '8750',
    city: 'Maria Buch'
  },
  {
    postalCode: '8750',
    city: 'Murdorf'
  },
  {
    postalCode: '8750',
    city: 'Wöllmerdorf'
  },
  {
    postalCode: '8750',
    city: 'Oberweg'
  },
  {
    postalCode: '8750',
    city: 'Ossach'
  },
  {
    postalCode: '8750',
    city: 'Auerling'
  },
  {
    postalCode: '8750',
    city: 'Feeberg'
  },
  {
    postalCode: '8750',
    city: 'Reifling'
  },
  {
    postalCode: '8753',
    city: 'Aichdorf'
  },
  {
    postalCode: '8753',
    city: 'Dietersdorf'
  },
  {
    postalCode: '8753',
    city: 'Fohnsdorf'
  },
  {
    postalCode: '8753',
    city: 'Wasendorf'
  },
  {
    postalCode: '8753',
    city: 'Hetzendorf'
  },
  {
    postalCode: '8753',
    city: 'Kumpitz'
  },
  {
    postalCode: '8753',
    city: 'Allerheiligen'
  },
  {
    postalCode: '8753',
    city: 'Allerheiligengraben'
  },
  {
    postalCode: '8753',
    city: 'Paßhammer'
  },
  {
    postalCode: '8753',
    city: 'Rattenberg'
  },
  {
    postalCode: '8753',
    city: 'Sillweg'
  },
  {
    postalCode: '8754',
    city: 'Enzersdorf'
  },
  {
    postalCode: '8754',
    city: 'Paig'
  },
  {
    postalCode: '8754',
    city: 'Sauerbrunn'
  },
  {
    postalCode: '8754',
    city: 'Thalheim'
  },
  {
    postalCode: '8755',
    city: 'Feistritzgraben'
  },
  {
    postalCode: '8755',
    city: 'Furth'
  },
  {
    postalCode: '8755',
    city: 'Mitterdorf'
  },
  {
    postalCode: '8755',
    city: 'Möschitzgraben'
  },
  {
    postalCode: '8755',
    city: 'Pichl'
  },
  {
    postalCode: '8755',
    city: 'Rach'
  },
  {
    postalCode: '8755',
    city: 'Rothenthurm'
  },
  {
    postalCode: '8755',
    city: 'Sankt Peter ob Judenburg'
  },
  {
    postalCode: '8756',
    city: 'Pichlhofen'
  },
  {
    postalCode: '8756',
    city: 'Sankt Georgen ob Judenburg'
  },
  {
    postalCode: '8756',
    city: 'Scheiben'
  },
  {
    postalCode: '8756',
    city: 'Wöll'
  },
  {
    postalCode: '8761',
    city: 'Götzendorf'
  },
  {
    postalCode: '8761',
    city: 'Katzling'
  },
  {
    postalCode: '8761',
    city: 'Mauterndorf'
  },
  {
    postalCode: '8761',
    city: 'Mosing'
  },
  {
    postalCode: '8761',
    city: 'Oberkurzheim'
  },
  {
    postalCode: '8761',
    city: 'Thaling'
  },
  {
    postalCode: '8761',
    city: 'Enzersdorf'
  },
  {
    postalCode: '8761',
    city: 'Greith'
  },
  {
    postalCode: '8761',
    city: 'Gusterheim'
  },
  {
    postalCode: '8761',
    city: 'Mühltal'
  },
  {
    postalCode: '8761',
    city: 'Offenburg'
  },
  {
    postalCode: '8761',
    city: 'Pöls'
  },
  {
    postalCode: '8761',
    city: 'Pölshof'
  },
  {
    postalCode: '8762',
    city: 'Katzling'
  },
  {
    postalCode: '8762',
    city: 'Unterzeiring'
  },
  {
    postalCode: '8762',
    city: 'Winden'
  },
  {
    postalCode: '8762',
    city: 'Gföllgraben'
  },
  {
    postalCode: '8762',
    city: 'Oberzeiring'
  },
  {
    postalCode: '8762',
    city: 'Zeiringgraben'
  },
  {
    postalCode: '8762',
    city: 'Zugtal'
  },
  {
    postalCode: '8762',
    city: 'Möderbrugg'
  },
  {
    postalCode: '8762',
    city: 'Sankt Oswald'
  },
  {
    postalCode: '8763',
    city: 'Bretstein'
  },
  {
    postalCode: '8763',
    city: 'Möderbrugg'
  },
  {
    postalCode: '8763',
    city: 'Sankt Oswald'
  },
  {
    postalCode: '8764',
    city: 'Pusterwald'
  },
  {
    postalCode: '8765',
    city: 'Sankt Johann am Tauern Schattseite'
  },
  {
    postalCode: '8765',
    city: 'Sankt Johann am Tauern Sonnseite'
  },
  {
    postalCode: '8770',
    city: 'Madstein'
  },
  {
    postalCode: '8770',
    city: 'Brunn'
  },
  {
    postalCode: '8770',
    city: 'Greith'
  },
  {
    postalCode: '8770',
    city: 'Hinterlainsach'
  },
  {
    postalCode: '8770',
    city: 'Jassing'
  },
  {
    postalCode: '8770',
    city: 'Liesingtal'
  },
  {
    postalCode: '8770',
    city: 'Sankt Michael in Obersteiermark'
  },
  {
    postalCode: '8770',
    city: 'Vorderlainsach'
  },
  {
    postalCode: '8770',
    city: 'Stadlhof'
  },
  {
    postalCode: '8772',
    city: 'Mötschendorf'
  },
  {
    postalCode: '8772',
    city: 'Madstein'
  },
  {
    postalCode: '8772',
    city: 'Timmersdorf'
  },
  {
    postalCode: '8772',
    city: 'Traboch'
  },
  {
    postalCode: '8773',
    city: 'Dirnsdorf'
  },
  {
    postalCode: '8773',
    city: 'Glarsdorf'
  },
  {
    postalCode: '8773',
    city: 'Kammern im Liesingtal'
  },
  {
    postalCode: '8773',
    city: 'Leims'
  },
  {
    postalCode: '8773',
    city: 'Liesing'
  },
  {
    postalCode: '8773',
    city: 'Mochl'
  },
  {
    postalCode: '8773',
    city: 'Pfaffendorf'
  },
  {
    postalCode: '8773',
    city: 'Seiz'
  },
  {
    postalCode: '8773',
    city: 'Sparsbach'
  },
  {
    postalCode: '8773',
    city: 'Wolfgruben'
  },
  {
    postalCode: '8773',
    city: 'Traboch'
  },
  {
    postalCode: '8774',
    city: 'Eselberg'
  },
  {
    postalCode: '8774',
    city: 'Liesingau'
  },
  {
    postalCode: '8774',
    city: 'Magdwiesen'
  },
  {
    postalCode: '8774',
    city: 'Mautern in Steiermark'
  },
  {
    postalCode: '8774',
    city: 'Rannach'
  },
  {
    postalCode: '8774',
    city: 'Reitingau'
  },
  {
    postalCode: '8775',
    city: 'Kalwang'
  },
  {
    postalCode: '8775',
    city: 'Pisching'
  },
  {
    postalCode: '8775',
    city: 'Schattenberg'
  },
  {
    postalCode: '8775',
    city: 'Sonnberg'
  },
  {
    postalCode: '8781',
    city: 'Furth'
  },
  {
    postalCode: '8781',
    city: 'Liesing'
  },
  {
    postalCode: '8781',
    city: 'Melling'
  },
  {
    postalCode: '8781',
    city: 'Wald am Schoberpaß'
  },
  {
    postalCode: '8782',
    city: 'Furth'
  },
  {
    postalCode: '8782',
    city: 'Treglwang'
  },
  {
    postalCode: '8783',
    city: 'Au bei Gaishorn am See'
  },
  {
    postalCode: '8783',
    city: 'Gaishorn am See'
  },
  {
    postalCode: '8784',
    city: 'Bärndorf'
  },
  {
    postalCode: '8784',
    city: 'Dietmannsdorf bei Trieben'
  },
  {
    postalCode: '8784',
    city: 'Sankt Lorenzen im Paltental'
  },
  {
    postalCode: '8784',
    city: 'Schwarzenbach'
  },
  {
    postalCode: '8784',
    city: 'Trieben'
  },
  {
    postalCode: '8785',
    city: 'Hohentauern'
  },
  {
    postalCode: '8785',
    city: 'Triebental'
  },
  {
    postalCode: '8786',
    city: 'Admont'
  },
  {
    postalCode: '8786',
    city: 'Oppenberg'
  },
  {
    postalCode: '8786',
    city: 'Bärndorf'
  },
  {
    postalCode: '8786',
    city: 'Edlach'
  },
  {
    postalCode: '8786',
    city: 'Singsdorf'
  },
  {
    postalCode: '8786',
    city: 'Boder'
  },
  {
    postalCode: '8786',
    city: 'Bruckmühl'
  },
  {
    postalCode: '8786',
    city: 'Büschendorf'
  },
  {
    postalCode: '8786',
    city: 'Klamm'
  },
  {
    postalCode: '8786',
    city: 'Rottenmann'
  },
  {
    postalCode: '8786',
    city: 'Sankt Georgen'
  },
  {
    postalCode: '8786',
    city: 'Strechau'
  },
  {
    postalCode: '8786',
    city: 'Strechen'
  },
  {
    postalCode: '8786',
    city: 'Villmannsdorf'
  },
  {
    postalCode: '8786',
    city: 'Versbichl'
  },
  {
    postalCode: '8790',
    city: 'Eisenerz'
  },
  {
    postalCode: '8792',
    city: 'Glarsdorf'
  },
  {
    postalCode: '8792',
    city: 'Donawitz'
  },
  {
    postalCode: '8792',
    city: 'Hessenberg'
  },
  {
    postalCode: '8792',
    city: 'Sankt Peter-Freienstein'
  },
  {
    postalCode: '8792',
    city: 'Tollinggraben'
  },
  {
    postalCode: '8792',
    city: 'Traidersberg'
  },
  {
    postalCode: '8792',
    city: 'Traboch'
  },
  {
    postalCode: '8793',
    city: 'Edling'
  },
  {
    postalCode: '8793',
    city: 'Gai'
  },
  {
    postalCode: '8793',
    city: 'Gausendorf'
  },
  {
    postalCode: '8793',
    city: 'Gimplach'
  },
  {
    postalCode: '8793',
    city: 'Gößgraben'
  },
  {
    postalCode: '8793',
    city: 'Kurzheim'
  },
  {
    postalCode: '8793',
    city: 'Oberdorf'
  },
  {
    postalCode: '8793',
    city: 'Putzenberg'
  },
  {
    postalCode: '8793',
    city: 'Schardorf'
  },
  {
    postalCode: '8793',
    city: 'Töllach'
  },
  {
    postalCode: '8793',
    city: 'Untergimplach'
  },
  {
    postalCode: '8793',
    city: 'Unterkurzheim'
  },
  {
    postalCode: '8793',
    city: 'Windischbühel'
  },
  {
    postalCode: '8793',
    city: 'Hafning'
  },
  {
    postalCode: '8793',
    city: 'Krumpen'
  },
  {
    postalCode: '8793',
    city: 'Laintal'
  },
  {
    postalCode: '8793',
    city: 'Rötz'
  },
  {
    postalCode: '8793',
    city: 'Treffning'
  },
  {
    postalCode: '8793',
    city: 'Glarsdorf'
  },
  {
    postalCode: '8793',
    city: 'Mochl'
  },
  {
    postalCode: '8793',
    city: 'Trofaiach'
  },
  {
    postalCode: '8794',
    city: 'Hafning'
  },
  {
    postalCode: '8794',
    city: 'Rötz'
  },
  {
    postalCode: '8794',
    city: 'Vordernberg'
  },
  {
    postalCode: '8795',
    city: 'Radmer an der Hasel'
  },
  {
    postalCode: '8795',
    city: 'Radmer an der Stube'
  },
  {
    postalCode: '8800',
    city: 'Scheiben'
  },
  {
    postalCode: '8800',
    city: 'Frauenburg'
  },
  {
    postalCode: '8800',
    city: 'Unzmarkt'
  },
  {
    postalCode: '8811',
    city: 'Niederwölz'
  },
  {
    postalCode: '8811',
    city: 'Lind bei Scheifling'
  },
  {
    postalCode: '8811',
    city: 'Scheifling'
  },
  {
    postalCode: '8811',
    city: 'Feßnach'
  },
  {
    postalCode: '8811',
    city: 'Puchfeld'
  },
  {
    postalCode: '8811',
    city: 'Sankt Lorenzen bei Scheifling'
  },
  {
    postalCode: '8812',
    city: 'Baierdorf'
  },
  {
    postalCode: '8812',
    city: 'Mariahof'
  },
  {
    postalCode: '8812',
    city: 'Sankt Blasen'
  },
  {
    postalCode: '8813',
    city: 'Laßnitz-Lambrecht'
  },
  {
    postalCode: '8813',
    city: 'Sankt Blasen'
  },
  {
    postalCode: '8813',
    city: 'Sankt Lambrecht'
  },
  {
    postalCode: '8820',
    city: 'Unterwald'
  },
  {
    postalCode: '8820',
    city: 'Kulm am Zirbitz'
  },
  {
    postalCode: '8820',
    city: 'Mariahof'
  },
  {
    postalCode: '8820',
    city: 'Sankt Veit in der Gegend'
  },
  {
    postalCode: '8820',
    city: 'Neumarkt in Steiermark'
  },
  {
    postalCode: '8820',
    city: 'Perchau am Sattel'
  },
  {
    postalCode: '8820',
    city: 'Sankt Georgen bei Neumarkt'
  },
  {
    postalCode: '8820',
    city: 'Sankt Marein bei Neumarkt'
  },
  {
    postalCode: '8820',
    city: 'Puchfeld'
  },
  {
    postalCode: '8820',
    city: 'Zeutschach'
  },
  {
    postalCode: '8822',
    city: 'Unterwald'
  },
  {
    postalCode: '8822',
    city: 'St. Martin am Silberberg'
  },
  {
    postalCode: '8822',
    city: 'Jakobsberg'
  },
  {
    postalCode: '8822',
    city: 'Mühlen'
  },
  {
    postalCode: '8822',
    city: 'Noreia'
  },
  {
    postalCode: '8822',
    city: 'Sankt Veit in der Gegend'
  },
  {
    postalCode: '8831',
    city: 'Niederwölz'
  },
  {
    postalCode: '8831',
    city: 'Raiming'
  },
  {
    postalCode: '8831',
    city: 'Lind bei Scheifling'
  },
  {
    postalCode: '8831',
    city: 'Dürnberg'
  },
  {
    postalCode: '8831',
    city: 'Schönberg-Lachtal'
  },
  {
    postalCode: '8832',
    city: 'Oberwölz (Stadt)'
  },
  {
    postalCode: '8832',
    city: 'Vorstadt'
  },
  {
    postalCode: '8832',
    city: 'Hinterburg'
  },
  {
    postalCode: '8832',
    city: 'Krumegg'
  },
  {
    postalCode: '8832',
    city: 'Raiming'
  },
  {
    postalCode: '8832',
    city: 'Salchau'
  },
  {
    postalCode: '8832',
    city: 'Schöttl'
  },
  {
    postalCode: '8832',
    city: 'Kammersberg'
  },
  {
    postalCode: '8832',
    city: 'Eselsberg'
  },
  {
    postalCode: '8832',
    city: 'Mainhartsdorf'
  },
  {
    postalCode: '8832',
    city: 'Winklern bei Oberwölz'
  },
  {
    postalCode: '8833',
    city: 'Frojach'
  },
  {
    postalCode: '8833',
    city: 'Teufenbach'
  },
  {
    postalCode: '8841',
    city: 'Frojach'
  },
  {
    postalCode: '8842',
    city: 'Frojach'
  },
  {
    postalCode: '8842',
    city: 'Katsch an der Mur'
  },
  {
    postalCode: '8842',
    city: 'Althofen'
  },
  {
    postalCode: '8842',
    city: 'Kammersberg'
  },
  {
    postalCode: '8842',
    city: 'Peterdorf'
  },
  {
    postalCode: '8842',
    city: 'Triebendorf'
  },
  {
    postalCode: '8843',
    city: 'Mitterdorf'
  },
  {
    postalCode: '8843',
    city: 'Pöllau am Greim'
  },
  {
    postalCode: '8843',
    city: 'Althofen'
  },
  {
    postalCode: '8843',
    city: 'Feistritz am Kammersberg'
  },
  {
    postalCode: '8843',
    city: 'Kammersberg'
  },
  {
    postalCode: '8843',
    city: 'St. Peter am Kammersberg'
  },
  {
    postalCode: '8844',
    city: 'Rinegg'
  },
  {
    postalCode: '8844',
    city: 'Baierdorf'
  },
  {
    postalCode: '8844',
    city: 'Schöder'
  },
  {
    postalCode: '8844',
    city: 'Schöderberg'
  },
  {
    postalCode: '8850',
    city: 'Frojach'
  },
  {
    postalCode: '8850',
    city: 'Laßnitz-Lambrecht'
  },
  {
    postalCode: '8850',
    city: 'Laßnitz-Murau'
  },
  {
    postalCode: '8850',
    city: 'Sankt Egidi'
  },
  {
    postalCode: '8850',
    city: 'Auen'
  },
  {
    postalCode: '8850',
    city: 'Laßnitz'
  },
  {
    postalCode: '8850',
    city: 'Zanitzberg'
  },
  {
    postalCode: '8850',
    city: 'Murau'
  },
  {
    postalCode: '8850',
    city: 'Sankt Lorenzen ob Murau'
  },
  {
    postalCode: '8850',
    city: 'Stolzalpe'
  },
  {
    postalCode: '8850',
    city: 'Triebendorf'
  },
  {
    postalCode: '8852',
    city: 'Stolzalpe'
  },
  {
    postalCode: '8853',
    city: 'Freiberg'
  },
  {
    postalCode: '8853',
    city: 'Ranten'
  },
  {
    postalCode: '8853',
    city: 'Seebach'
  },
  {
    postalCode: '8853',
    city: 'Rinegg'
  },
  {
    postalCode: '8853',
    city: 'Schöder'
  },
  {
    postalCode: '8854',
    city: 'Krakaudorf'
  },
  {
    postalCode: '8854',
    city: 'Krakauhintermühlen'
  },
  {
    postalCode: '8854',
    city: 'Krakauschatten'
  },
  {
    postalCode: '8861',
    city: 'Bodendorf'
  },
  {
    postalCode: '8861',
    city: 'Lutzmannsdorf'
  },
  {
    postalCode: '8861',
    city: 'Sankt Georgen ob Murau'
  },
  {
    postalCode: '8861',
    city: 'Sankt Lorenzen ob Murau'
  },
  {
    postalCode: '8862',
    city: 'Einach'
  },
  {
    postalCode: '8862',
    city: 'Falkendorf'
  },
  {
    postalCode: '8862',
    city: 'St. Ruprecht ob Murau'
  },
  {
    postalCode: '8862',
    city: 'Paal'
  },
  {
    postalCode: '8862',
    city: 'Sonnberg'
  },
  {
    postalCode: '8862',
    city: 'Stadl an der Mur'
  },
  {
    postalCode: '8862',
    city: 'Steindorf'
  },
  {
    postalCode: '8863',
    city: 'Einach'
  },
  {
    postalCode: '8863',
    city: 'Predlitz'
  },
  {
    postalCode: '8864',
    city: 'Turrach'
  },
  {
    postalCode: '8900',
    city: 'Burgfried'
  },
  {
    postalCode: '8900',
    city: 'Liezen'
  },
  {
    postalCode: '8900',
    city: 'Neulassing'
  },
  {
    postalCode: '8900',
    city: 'Selzthal'
  },
  {
    postalCode: '8900',
    city: 'Versbichl'
  },
  {
    postalCode: '8903',
    city: 'Gatschling'
  },
  {
    postalCode: '8903',
    city: 'Neusiedl'
  },
  {
    postalCode: '8903',
    city: 'Sonnberg'
  },
  {
    postalCode: '8903',
    city: 'Fuchslucken'
  },
  {
    postalCode: '8903',
    city: 'Heuberg'
  },
  {
    postalCode: '8903',
    city: 'Schattenberg'
  },
  {
    postalCode: '8903',
    city: 'Spiegelsberg'
  },
  {
    postalCode: '8903',
    city: 'Treschmitz'
  },
  {
    postalCode: '8903',
    city: 'Trojach'
  },
  {
    postalCode: '8903',
    city: 'Unterberg'
  },
  {
    postalCode: '8903',
    city: 'Wieden'
  },
  {
    postalCode: '8903',
    city: 'Altlassing'
  },
  {
    postalCode: '8903',
    city: 'Burgfried'
  },
  {
    postalCode: '8903',
    city: 'Lassing-Kirchdorf'
  },
  {
    postalCode: '8903',
    city: 'Moos'
  },
  {
    postalCode: '8903',
    city: 'Niedermoos'
  },
  {
    postalCode: '8904',
    city: 'Ardning'
  },
  {
    postalCode: '8904',
    city: 'Frauenberg'
  },
  {
    postalCode: '8904',
    city: 'Pürgschachen'
  },
  {
    postalCode: '8911',
    city: 'Admont'
  },
  {
    postalCode: '8911',
    city: 'Aigen'
  },
  {
    postalCode: '8911',
    city: 'Krumau'
  },
  {
    postalCode: '8911',
    city: 'Frauenberg'
  },
  {
    postalCode: '8911',
    city: 'Hall'
  },
  {
    postalCode: '8912',
    city: 'Krumau'
  },
  {
    postalCode: '8912',
    city: 'Johnsbach'
  },
  {
    postalCode: '8913',
    city: 'Gstatterboden'
  },
  {
    postalCode: '8913',
    city: 'Weng im Gesäuse'
  },
  {
    postalCode: '8920',
    city: 'Hieflau'
  },
  {
    postalCode: '8920',
    city: 'Jassingau'
  },
  {
    postalCode: '8921',
    city: 'Kirchenlandl'
  },
  {
    postalCode: '8921',
    city: 'Lainbach'
  },
  {
    postalCode: '8921',
    city: 'Mooslandl'
  },
  {
    postalCode: '8922',
    city: 'Gams bei Hieflau'
  },
  {
    postalCode: '8923',
    city: 'Palfau'
  },
  {
    postalCode: '8924',
    city: 'Rothwald-Langau'
  },
  {
    postalCode: '8924',
    city: 'Wildalpen'
  },
  {
    postalCode: '8931',
    city: 'Großreifling'
  },
  {
    postalCode: '8931',
    city: 'Kirchenlandl'
  },
  {
    postalCode: '8931',
    city: 'Krippau'
  },
  {
    postalCode: '8931',
    city: 'Mooslandl'
  },
  {
    postalCode: '8932',
    city: 'Bergerviertel'
  },
  {
    postalCode: '8932',
    city: 'Sankt Gallen'
  },
  {
    postalCode: '8932',
    city: 'Bichl'
  },
  {
    postalCode: '8932',
    city: 'Weißenbach an der Enns'
  },
  {
    postalCode: '8932',
    city: 'Wolfsbachau'
  },
  {
    postalCode: '8933',
    city: 'Bergerviertel'
  },
  {
    postalCode: '8933',
    city: 'Oberreith'
  },
  {
    postalCode: '8933',
    city: 'Reiflingviertel'
  },
  {
    postalCode: '8933',
    city: 'Sankt Gallen'
  },
  {
    postalCode: '8933',
    city: 'Breitau'
  },
  {
    postalCode: '8934',
    city: 'Altenmarkt bei Sankt Gallen'
  },
  {
    postalCode: '8934',
    city: 'Eßling'
  },
  {
    postalCode: '8934',
    city: 'Oberlaussa'
  },
  {
    postalCode: '8934',
    city: 'Unterlaussa'
  },
  {
    postalCode: '8934',
    city: 'Frenz'
  },
  {
    postalCode: '8934',
    city: 'Weißwasser'
  },
  {
    postalCode: '8940',
    city: 'Fischern'
  },
  {
    postalCode: '8940',
    city: 'Schattenberg'
  },
  {
    postalCode: '8940',
    city: 'Stein'
  },
  {
    postalCode: '8940',
    city: 'Döllach'
  },
  {
    postalCode: '8940',
    city: 'Liezen'
  },
  {
    postalCode: '8940',
    city: 'Pyhrn'
  },
  {
    postalCode: '8940',
    city: 'Reithtal'
  },
  {
    postalCode: '8940',
    city: 'Weißenbach bei Liezen'
  },
  {
    postalCode: '8942',
    city: 'Maitschern'
  },
  {
    postalCode: '8942',
    city: 'Wörschach'
  },
  {
    postalCode: '8943',
    city: 'Aich'
  },
  {
    postalCode: '8943',
    city: 'Aigen im Ennstal'
  },
  {
    postalCode: '8943',
    city: 'Aiglern'
  },
  {
    postalCode: '8943',
    city: 'Gatschen'
  },
  {
    postalCode: '8943',
    city: 'Hohenberg'
  },
  {
    postalCode: '8943',
    city: 'Ketten'
  },
  {
    postalCode: '8943',
    city: 'Lantschern'
  },
  {
    postalCode: '8943',
    city: 'Mitteregg'
  },
  {
    postalCode: '8943',
    city: 'Quilk'
  },
  {
    postalCode: '8943',
    city: 'Ritzmannsdorf'
  },
  {
    postalCode: '8943',
    city: 'Sallaberg'
  },
  {
    postalCode: '8943',
    city: 'Tachenberg'
  },
  {
    postalCode: '8943',
    city: 'Vorberg'
  },
  {
    postalCode: '8950',
    city: 'Niederhofen'
  },
  {
    postalCode: '8950',
    city: 'Stainach'
  },
  {
    postalCode: '8951',
    city: 'Pürgg'
  },
  {
    postalCode: '8951',
    city: 'Trautenfels'
  },
  {
    postalCode: '8951',
    city: 'Unterburg'
  },
  {
    postalCode: '8951',
    city: 'Untergrimming'
  },
  {
    postalCode: '8952',
    city: 'Gatschen'
  },
  {
    postalCode: '8952',
    city: 'Lantschern'
  },
  {
    postalCode: '8952',
    city: 'Sallaberg'
  },
  {
    postalCode: '8952',
    city: 'Schlattham'
  },
  {
    postalCode: '8952',
    city: 'Bleiberg'
  },
  {
    postalCode: '8952',
    city: 'Erlsberg'
  },
  {
    postalCode: '8952',
    city: 'Winklern'
  },
  {
    postalCode: '8952',
    city: 'Falkenburg'
  },
  {
    postalCode: '8952',
    city: 'Altirdning'
  },
  {
    postalCode: '8952',
    city: 'Irdning'
  },
  {
    postalCode: '8952',
    city: 'Raumberg'
  },
  {
    postalCode: '8952',
    city: 'Kienach'
  },
  {
    postalCode: '8953',
    city: 'Donnersbach'
  },
  {
    postalCode: '8953',
    city: 'Erlsberg'
  },
  {
    postalCode: '8953',
    city: 'Fuchsberg'
  },
  {
    postalCode: '8953',
    city: 'Furrach'
  },
  {
    postalCode: '8953',
    city: 'Ilgenberg'
  },
  {
    postalCode: '8953',
    city: 'Ritzenberg'
  },
  {
    postalCode: '8953',
    city: 'Winklern'
  },
  {
    postalCode: '8953',
    city: 'Planneralm'
  },
  {
    postalCode: '8953',
    city: 'Donnersbachwald'
  },
  {
    postalCode: '8954',
    city: 'Mitterberg'
  },
  {
    postalCode: '8954',
    city: 'Salza'
  },
  {
    postalCode: '8954',
    city: 'Tipschern'
  },
  {
    postalCode: '8954',
    city: 'Diemlern'
  },
  {
    postalCode: '8954',
    city: 'Sankt Martin am Grimming'
  },
  {
    postalCode: '8960',
    city: 'Mitterberg'
  },
  {
    postalCode: '8960',
    city: 'Strimitzen'
  },
  {
    postalCode: '8960',
    city: 'Gritschenberg'
  },
  {
    postalCode: '8960',
    city: 'Moosberg auch Sonnberg'
  },
  {
    postalCode: '8960',
    city: 'Niederöblarn'
  },
  {
    postalCode: '8960',
    city: 'Straßerberg'
  },
  {
    postalCode: '8960',
    city: 'Öblarn'
  },
  {
    postalCode: '8960',
    city: 'Sonnberg'
  },
  {
    postalCode: '8961',
    city: 'Großsölk'
  },
  {
    postalCode: '8961',
    city: 'Kleinsölk'
  },
  {
    postalCode: '8961',
    city: 'Mitterberg'
  },
  {
    postalCode: '8961',
    city: 'Sankt Nikolai im Sölktal'
  },
  {
    postalCode: '8962',
    city: 'Aich'
  },
  {
    postalCode: '8962',
    city: 'Gröbming'
  },
  {
    postalCode: '8962',
    city: 'Kleinsölk'
  },
  {
    postalCode: '8962',
    city: 'Michaelerberg'
  },
  {
    postalCode: '8962',
    city: 'Gersdorf'
  },
  {
    postalCode: '8962',
    city: 'Mitterberg'
  },
  {
    postalCode: '8962',
    city: 'Unterlengdorf'
  },
  {
    postalCode: '8962',
    city: 'Pruggern'
  },
  {
    postalCode: '8962',
    city: 'Oberlengdorf'
  },
  {
    postalCode: '8965',
    city: 'Assach'
  },
  {
    postalCode: '8965',
    city: 'Auberg'
  },
  {
    postalCode: '8965',
    city: 'Michaelerberg'
  },
  {
    postalCode: '8965',
    city: 'Pruggern'
  },
  {
    postalCode: '8966',
    city: 'Aich'
  },
  {
    postalCode: '8966',
    city: 'Assach'
  },
  {
    postalCode: '8966',
    city: 'Auberg'
  },
  {
    postalCode: '8966',
    city: 'Gössenberg'
  },
  {
    postalCode: '8966',
    city: 'Ennsling'
  },
  {
    postalCode: '8967',
    city: 'Petersberg'
  },
  {
    postalCode: '8967',
    city: 'Birnberg'
  },
  {
    postalCode: '8967',
    city: 'Ennsling'
  },
  {
    postalCode: '8967',
    city: 'Gumpenberg'
  },
  {
    postalCode: '8967',
    city: 'Haus'
  },
  {
    postalCode: '8967',
    city: 'Lehen'
  },
  {
    postalCode: '8967',
    city: 'Oberhaus'
  },
  {
    postalCode: '8967',
    city: 'Oberhausberg'
  },
  {
    postalCode: '8967',
    city: 'Weißenbach'
  },
  {
    postalCode: '8970',
    city: 'Lehen'
  },
  {
    postalCode: '8970',
    city: 'Oberhausberg'
  },
  {
    postalCode: '8970',
    city: 'Preunegg'
  },
  {
    postalCode: '8970',
    city: 'Klaus'
  },
  {
    postalCode: '8970',
    city: 'Schladming'
  },
  {
    postalCode: '8971',
    city: 'Fastenberg'
  },
  {
    postalCode: '8971',
    city: 'Obertal'
  },
  {
    postalCode: '8971',
    city: 'Rohrmoos'
  },
  {
    postalCode: '8971',
    city: 'Untertal'
  },
  {
    postalCode: '8972',
    city: 'Weißenbach'
  },
  {
    postalCode: '8972',
    city: 'Ramsau am Dachstein'
  },
  {
    postalCode: '8972',
    city: 'Ramsauleiten'
  },
  {
    postalCode: '8972',
    city: 'Schildlehen'
  },
  {
    postalCode: '8973',
    city: 'Gleiming'
  },
  {
    postalCode: '8973',
    city: 'Pichl'
  },
  {
    postalCode: '8973',
    city: 'Preunegg'
  },
  {
    postalCode: '8973',
    city: 'Rohrmoos'
  },
  {
    postalCode: '8974',
    city: 'Pichl'
  },
  {
    postalCode: '8974',
    city: 'Mandling'
  },
  {
    postalCode: '8982',
    city: 'Pürgg'
  },
  {
    postalCode: '8982',
    city: 'Zlem'
  },
  {
    postalCode: '8982',
    city: 'Furt'
  },
  {
    postalCode: '8982',
    city: 'Klachau'
  },
  {
    postalCode: '8982',
    city: 'Tauplitz'
  },
  {
    postalCode: '8982',
    city: 'Tauplitzalm'
  },
  {
    postalCode: '8983',
    city: 'Bad Mitterndorf'
  },
  {
    postalCode: '8983',
    city: 'Krungl'
  },
  {
    postalCode: '8983',
    city: 'Neuhofen'
  },
  {
    postalCode: '8983',
    city: 'Obersdorf'
  },
  {
    postalCode: '8983',
    city: 'Rödschitz'
  },
  {
    postalCode: '8983',
    city: 'Thörl'
  },
  {
    postalCode: '8983',
    city: 'Zauchen'
  },
  {
    postalCode: '8983',
    city: 'Sonnenalm'
  },
  {
    postalCode: '8983',
    city: 'Knoppen'
  },
  {
    postalCode: '8984',
    city: 'Äußere Kainisch'
  },
  {
    postalCode: '8984',
    city: 'Knoppen'
  },
  {
    postalCode: '8984',
    city: 'Mühlreith'
  },
  {
    postalCode: '8984',
    city: 'Pichl'
  },
  {
    postalCode: '8990',
    city: 'Anger'
  },
  {
    postalCode: '8990',
    city: 'Bad Aussee'
  },
  {
    postalCode: '8990',
    city: 'Gschlößl'
  },
  {
    postalCode: '8990',
    city: 'Reitern'
  },
  {
    postalCode: '8990',
    city: 'Eselsbach'
  },
  {
    postalCode: '8990',
    city: 'Gallhof'
  },
  {
    postalCode: '8990',
    city: 'Lerchenreith'
  },
  {
    postalCode: '8990',
    city: 'Obertressen'
  },
  {
    postalCode: '8990',
    city: 'Reith'
  },
  {
    postalCode: '8990',
    city: 'Sarstein'
  },
  {
    postalCode: '8990',
    city: 'Unterkainisch'
  },
  {
    postalCode: '8992',
    city: 'Altaussee'
  },
  {
    postalCode: '8992',
    city: 'Fischerndorf'
  },
  {
    postalCode: '8992',
    city: 'Lichtersberg'
  },
  {
    postalCode: '8992',
    city: 'Lupitsch'
  },
  {
    postalCode: '8992',
    city: 'Puchen'
  },
  {
    postalCode: '8993',
    city: 'Archkogl'
  },
  {
    postalCode: '8993',
    city: 'Bräuhof'
  },
  {
    postalCode: '8993',
    city: 'Gößl'
  },
  {
    postalCode: '8993',
    city: 'Mosern'
  },
  {
    postalCode: '8993',
    city: 'Untertressen'
  },
  {
    postalCode: '9020',
    city: 'Aich an der Straße'
  },
  {
    postalCode: '9020',
    city: 'Niederdorf'
  },
  {
    postalCode: '9020',
    city: 'Klagenfurt'
  },
  {
    postalCode: '9020',
    city: 'Farchern'
  },
  {
    postalCode: '9020',
    city: 'Gottesbichl'
  },
  {
    postalCode: '9020',
    city: 'Gundersdorf'
  },
  {
    postalCode: '9020',
    city: 'Kreuzbichl'
  },
  {
    postalCode: '9020',
    city: 'Portendorf'
  },
  {
    postalCode: '9020',
    city: 'Poppichl'
  },
  {
    postalCode: '9020',
    city: 'Walddorf'
  },
  {
    postalCode: '9061',
    city: 'Klagenfurt'
  },
  {
    postalCode: '9061',
    city: 'Nußberg'
  },
  {
    postalCode: '9061',
    city: 'Tultschnig'
  },
  {
    postalCode: '9061',
    city: 'Poppichl'
  },
  {
    postalCode: '9061',
    city: 'Ziegelsdorf'
  },
  {
    postalCode: '9062',
    city: 'Albern'
  },
  {
    postalCode: '9062',
    city: 'Ameisbichl'
  },
  {
    postalCode: '9062',
    city: 'Bärndorf'
  },
  {
    postalCode: '9062',
    city: 'Dellach'
  },
  {
    postalCode: '9062',
    city: 'Goritschitzen'
  },
  {
    postalCode: '9062',
    city: 'Gradenegg'
  },
  {
    postalCode: '9062',
    city: 'Knasweg'
  },
  {
    postalCode: '9062',
    city: 'Kreggab'
  },
  {
    postalCode: '9062',
    city: 'Moosburg'
  },
  {
    postalCode: '9062',
    city: 'Obergöriach'
  },
  {
    postalCode: '9062',
    city: 'Prosintschach'
  },
  {
    postalCode: '9062',
    city: 'Ratzenegg'
  },
  {
    postalCode: '9062',
    city: 'Seigbichl'
  },
  {
    postalCode: '9062',
    city: 'Simislau'
  },
  {
    postalCode: '9062',
    city: 'Stallhofen'
  },
  {
    postalCode: '9062',
    city: 'Tuderschitz'
  },
  {
    postalCode: '9062',
    city: 'Untergöriach'
  },
  {
    postalCode: '9062',
    city: 'Unterlinden'
  },
  {
    postalCode: '9062',
    city: 'Vögelitz'
  },
  {
    postalCode: '9062',
    city: 'Ziegelsdorf'
  },
  {
    postalCode: '9062',
    city: 'Arlsdorf'
  },
  {
    postalCode: '9062',
    city: 'Freudenberg'
  },
  {
    postalCode: '9062',
    city: 'Krainig'
  },
  {
    postalCode: '9062',
    city: 'Nußberg'
  },
  {
    postalCode: '9062',
    city: 'Polan'
  },
  {
    postalCode: '9062',
    city: 'Rosenau'
  },
  {
    postalCode: '9062',
    city: 'St. Peter'
  },
  {
    postalCode: '9062',
    city: 'Tigring'
  },
  {
    postalCode: '9062',
    city: 'Wielen'
  },
  {
    postalCode: '9062',
    city: 'Windischbach'
  },
  {
    postalCode: '9062',
    city: 'Windischbach-Gegend'
  },
  {
    postalCode: '9062',
    city: 'Witsch'
  },
  {
    postalCode: '9062',
    city: 'Faning'
  },
  {
    postalCode: '9062',
    city: 'Gabriel'
  },
  {
    postalCode: '9062',
    city: 'Malleberg'
  },
  {
    postalCode: '9062',
    city: 'Pernach'
  },
  {
    postalCode: '9063',
    city: 'Klagenfurt'
  },
  {
    postalCode: '9063',
    city: 'Stuttern'
  },
  {
    postalCode: '9063',
    city: 'Arndorf'
  },
  {
    postalCode: '9063',
    city: 'Bergl'
  },
  {
    postalCode: '9063',
    city: 'Dellach'
  },
  {
    postalCode: '9063',
    city: 'Gröblach'
  },
  {
    postalCode: '9063',
    city: 'Hart'
  },
  {
    postalCode: '9063',
    city: 'Höfern'
  },
  {
    postalCode: '9063',
    city: 'Judendorf'
  },
  {
    postalCode: '9063',
    city: 'Kading'
  },
  {
    postalCode: '9063',
    city: 'Karnburg'
  },
  {
    postalCode: '9063',
    city: 'Kuchling'
  },
  {
    postalCode: '9063',
    city: 'Lind'
  },
  {
    postalCode: '9063',
    city: 'Maria Saal'
  },
  {
    postalCode: '9063',
    city: 'Meilsberg'
  },
  {
    postalCode: '9063',
    city: 'Meiselberg'
  },
  {
    postalCode: '9063',
    city: 'Möderndorf'
  },
  {
    postalCode: '9063',
    city: 'Pörtschach am Berg'
  },
  {
    postalCode: '9063',
    city: 'Possau'
  },
  {
    postalCode: '9063',
    city: 'Ratzendorf'
  },
  {
    postalCode: '9063',
    city: 'Rosendorf'
  },
  {
    postalCode: '9063',
    city: 'Rotheis'
  },
  {
    postalCode: '9063',
    city: 'Sagrad'
  },
  {
    postalCode: '9063',
    city: 'St. Michael am Zollfeld'
  },
  {
    postalCode: '9063',
    city: 'Stegendorf'
  },
  {
    postalCode: '9063',
    city: 'Techmannsdorf'
  },
  {
    postalCode: '9063',
    city: 'Thurn'
  },
  {
    postalCode: '9063',
    city: 'Töltschach'
  },
  {
    postalCode: '9063',
    city: 'Willersdorf'
  },
  {
    postalCode: '9063',
    city: 'Winklern'
  },
  {
    postalCode: '9063',
    city: 'Wrießnitz'
  },
  {
    postalCode: '9063',
    city: 'Wutschein'
  },
  {
    postalCode: '9063',
    city: 'Zell'
  },
  {
    postalCode: '9063',
    city: 'Zollfeld'
  },
  {
    postalCode: '9063',
    city: 'Prikalitz'
  },
  {
    postalCode: '9063',
    city: 'Affelsdorf'
  },
  {
    postalCode: '9063',
    city: 'Beintratten'
  },
  {
    postalCode: '9063',
    city: 'Galling'
  },
  {
    postalCode: '9063',
    city: 'Gersdorf'
  },
  {
    postalCode: '9063',
    city: 'Laasdorf'
  },
  {
    postalCode: '9063',
    city: 'Preilitz'
  },
  {
    postalCode: '9063',
    city: 'Raggasaal'
  },
  {
    postalCode: '9063',
    city: 'Tanzenberg'
  },
  {
    postalCode: '9063',
    city: 'Ulrichsberg'
  },
  {
    postalCode: '9063',
    city: 'Unterwuhr'
  },
  {
    postalCode: '9063',
    city: 'Wainz'
  },
  {
    postalCode: '9064',
    city: 'Christofberg'
  },
  {
    postalCode: '9064',
    city: 'Krobathen'
  },
  {
    postalCode: '9064',
    city: 'Ochsendorf'
  },
  {
    postalCode: '9064',
    city: 'St. Filippen'
  },
  {
    postalCode: '9064',
    city: 'Eixendorf'
  },
  {
    postalCode: '9064',
    city: 'Gammersdorf'
  },
  {
    postalCode: '9064',
    city: 'Göriach'
  },
  {
    postalCode: '9064',
    city: 'Latschach'
  },
  {
    postalCode: '9064',
    city: 'Magdalensberg'
  },
  {
    postalCode: '9064',
    city: 'Ottmanach'
  },
  {
    postalCode: '9064',
    city: 'Pirk'
  },
  {
    postalCode: '9064',
    city: 'Treffelsdorf'
  },
  {
    postalCode: '9064',
    city: 'Deinsdorf'
  },
  {
    postalCode: '9064',
    city: 'Dürnfeld'
  },
  {
    postalCode: '9064',
    city: 'Eibelhof'
  },
  {
    postalCode: '9064',
    city: 'Freudenberg'
  },
  {
    postalCode: '9064',
    city: 'Geiersdorf'
  },
  {
    postalCode: '9064',
    city: 'Großgörtschach'
  },
  {
    postalCode: '9064',
    city: 'Haag'
  },
  {
    postalCode: '9064',
    city: 'Hollern'
  },
  {
    postalCode: '9064',
    city: 'Kleingörtschach'
  },
  {
    postalCode: '9064',
    city: 'Kronabeth'
  },
  {
    postalCode: '9064',
    city: 'Lassendorf'
  },
  {
    postalCode: '9064',
    city: 'Leibnitz'
  },
  {
    postalCode: '9064',
    city: 'Matzendorf'
  },
  {
    postalCode: '9064',
    city: 'Pischeldorf'
  },
  {
    postalCode: '9064',
    city: 'Reigersdorf'
  },
  {
    postalCode: '9064',
    city: 'St. Lorenzen'
  },
  {
    postalCode: '9064',
    city: 'St. Martin'
  },
  {
    postalCode: '9064',
    city: 'St. Thomas'
  },
  {
    postalCode: '9064',
    city: 'Schöpfendorf'
  },
  {
    postalCode: '9064',
    city: 'Sillebrücke'
  },
  {
    postalCode: '9064',
    city: 'Timenitz'
  },
  {
    postalCode: '9064',
    city: 'Vellach'
  },
  {
    postalCode: '9064',
    city: 'Wutschein'
  },
  {
    postalCode: '9064',
    city: 'Zeiselberg'
  },
  {
    postalCode: '9064',
    city: 'Zinsdorf'
  },
  {
    postalCode: '9064',
    city: 'Frankenberg'
  },
  {
    postalCode: '9064',
    city: 'Salchendorf'
  },
  {
    postalCode: '9064',
    city: 'St. Michael ob der Gurk'
  },
  {
    postalCode: '9064',
    city: 'Unterbergen'
  },
  {
    postalCode: '9065',
    city: 'Ebenthal'
  },
  {
    postalCode: '9065',
    city: 'Gurnitz'
  },
  {
    postalCode: '9065',
    city: 'Moosberg'
  },
  {
    postalCode: '9065',
    city: 'Pfaffendorf'
  },
  {
    postalCode: '9065',
    city: 'Priedl'
  },
  {
    postalCode: '9065',
    city: 'Rain'
  },
  {
    postalCode: '9065',
    city: 'Reichersdorf'
  },
  {
    postalCode: '9065',
    city: 'Zell'
  },
  {
    postalCode: '9065',
    city: 'Zetterei'
  },
  {
    postalCode: '9065',
    city: 'Zwanzgerberg'
  },
  {
    postalCode: '9065',
    city: 'Kossiach '
  },
  {
    postalCode: '9065',
    city: 'Kreuth '
  },
  {
    postalCode: '9065',
    city: 'Lipizach '
  },
  {
    postalCode: '9065',
    city: 'Radsberg '
  },
  {
    postalCode: '9065',
    city: 'Schwarz '
  },
  {
    postalCode: '9065',
    city: 'Tutzach '
  },
  {
    postalCode: '9065',
    city: 'Werouzach '
  },
  {
    postalCode: '9065',
    city: 'Rosenegg'
  },
  {
    postalCode: '9065',
    city: 'Gradnitz'
  },
  {
    postalCode: '9065',
    city: 'Klagenfurt'
  },
  {
    postalCode: '9071',
    city: 'Köttmannsdorf'
  },
  {
    postalCode: '9071',
    city: 'Mostitz'
  },
  {
    postalCode: '9071',
    city: 'Neusaß'
  },
  {
    postalCode: '9071',
    city: 'Plöschenberg'
  },
  {
    postalCode: '9071',
    city: 'Preliebl'
  },
  {
    postalCode: '9071',
    city: 'St. Gandolf'
  },
  {
    postalCode: '9071',
    city: 'St. Margarethen'
  },
  {
    postalCode: '9071',
    city: 'Trabesing'
  },
  {
    postalCode: '9071',
    city: 'Tretram'
  },
  {
    postalCode: '9071',
    city: 'Tschachoritsch'
  },
  {
    postalCode: '9071',
    city: 'Tschrestal'
  },
  {
    postalCode: '9071',
    city: 'Wurdach'
  },
  {
    postalCode: '9071',
    city: 'Am Teller'
  },
  {
    postalCode: '9072',
    city: 'Bach '
  },
  {
    postalCode: '9072',
    city: 'Edling '
  },
  {
    postalCode: '9072',
    city: 'Fellersdorf '
  },
  {
    postalCode: '9072',
    city: 'Franzendorf '
  },
  {
    postalCode: '9072',
    city: 'Großkleinberg '
  },
  {
    postalCode: '9072',
    city: 'Ludmannsdorf '
  },
  {
    postalCode: '9072',
    city: 'Lukowitz '
  },
  {
    postalCode: '9072',
    city: 'Moschenitzen '
  },
  {
    postalCode: '9072',
    city: 'Muschkau '
  },
  {
    postalCode: '9072',
    city: 'Niederdörfl '
  },
  {
    postalCode: '9072',
    city: 'Oberdörfl '
  },
  {
    postalCode: '9072',
    city: 'Pugrad '
  },
  {
    postalCode: '9072',
    city: 'Rupertiberg '
  },
  {
    postalCode: '9072',
    city: 'Selkach '
  },
  {
    postalCode: '9072',
    city: 'Strein '
  },
  {
    postalCode: '9072',
    city: 'Wellersdorf '
  },
  {
    postalCode: '9072',
    city: 'Zedras '
  },
  {
    postalCode: '9073',
    city: 'Klagenfurt'
  },
  {
    postalCode: '9073',
    city: 'Aich'
  },
  {
    postalCode: '9073',
    city: 'Lambichl'
  },
  {
    postalCode: '9073',
    city: 'Rotschitzen'
  },
  {
    postalCode: '9073',
    city: 'Schwanein'
  },
  {
    postalCode: '9073',
    city: 'Tretram'
  },
  {
    postalCode: '9073',
    city: 'Thal'
  },
  {
    postalCode: '9073',
    city: 'Maiernigg'
  },
  {
    postalCode: '9074',
    city: 'Dobein'
  },
  {
    postalCode: '9074',
    city: 'Dobeinitz'
  },
  {
    postalCode: '9074',
    city: 'Höflein'
  },
  {
    postalCode: '9074',
    city: 'Höhe'
  },
  {
    postalCode: '9074',
    city: 'Keutschach'
  },
  {
    postalCode: '9074',
    city: 'Leisbach'
  },
  {
    postalCode: '9074',
    city: 'Linden'
  },
  {
    postalCode: '9074',
    city: 'Pertitschach'
  },
  {
    postalCode: '9074',
    city: 'Plaschischen'
  },
  {
    postalCode: '9074',
    city: 'Plescherken'
  },
  {
    postalCode: '9074',
    city: 'Rauth'
  },
  {
    postalCode: '9074',
    city: 'Reauz'
  },
  {
    postalCode: '9074',
    city: 'St. Margarethen'
  },
  {
    postalCode: '9074',
    city: 'St. Nikolai'
  },
  {
    postalCode: '9074',
    city: 'Schelesnitz'
  },
  {
    postalCode: '9081',
    city: 'Raunach'
  },
  {
    postalCode: '9081',
    city: 'Reifnitz'
  },
  {
    postalCode: '9081',
    city: 'St. Anna'
  },
  {
    postalCode: '9081',
    city: 'Sekirn'
  },
  {
    postalCode: '9082',
    city: 'Maria Wörth'
  },
  {
    postalCode: '9082',
    city: 'Oberdellach'
  },
  {
    postalCode: '9082',
    city: 'Unterdellach'
  },
  {
    postalCode: '9100',
    city: 'St. Peter am Wallersberg'
  },
  {
    postalCode: '9100',
    city: 'Arlsdorf'
  },
  {
    postalCode: '9100',
    city: 'Haimburg'
  },
  {
    postalCode: '9100',
    city: 'Kaltenbrunn'
  },
  {
    postalCode: '9100',
    city: 'Niedertrixen'
  },
  {
    postalCode: '9100',
    city: 'Oschenitzen'
  },
  {
    postalCode: '9100',
    city: 'Bach'
  },
  {
    postalCode: '9100',
    city: 'Dullach I'
  },
  {
    postalCode: '9100',
    city: 'Gurtschitschach'
  },
  {
    postalCode: '9100',
    city: 'Lippendorf'
  },
  {
    postalCode: '9100',
    city: 'Obersielach'
  },
  {
    postalCode: '9100',
    city: 'Ratschitschach'
  },
  {
    postalCode: '9100',
    city: 'Reifnitz'
  },
  {
    postalCode: '9100',
    city: 'St. Agnes'
  },
  {
    postalCode: '9100',
    city: 'Unarach'
  },
  {
    postalCode: '9100',
    city: 'Watzelsdorf'
  },
  {
    postalCode: '9100',
    city: 'Wernzach'
  },
  {
    postalCode: '9100',
    city: 'Völkermarkt'
  },
  {
    postalCode: '9100',
    city: 'Frankenberg'
  },
  {
    postalCode: '9100',
    city: 'Hafendorf'
  },
  {
    postalCode: '9100',
    city: 'Krenobitsch'
  },
  {
    postalCode: '9100',
    city: 'Neudenstein'
  },
  {
    postalCode: '9100',
    city: 'Penk'
  },
  {
    postalCode: '9100',
    city: 'Pörtschach'
  },
  {
    postalCode: '9100',
    city: 'Ruhstatt'
  },
  {
    postalCode: '9100',
    city: 'St. Margarethen ob Töllerberg'
  },
  {
    postalCode: '9100',
    city: 'Töllerberg'
  },
  {
    postalCode: '9100',
    city: 'Weinberg'
  },
  {
    postalCode: '9102',
    city: 'Michaelerberg'
  },
  {
    postalCode: '9102',
    city: 'Bösenort'
  },
  {
    postalCode: '9102',
    city: 'Aich'
  },
  {
    postalCode: '9102',
    city: 'Bergstein'
  },
  {
    postalCode: '9102',
    city: 'Bischofberg'
  },
  {
    postalCode: '9102',
    city: 'Führholz'
  },
  {
    postalCode: '9102',
    city: 'Gänsdorf'
  },
  {
    postalCode: '9102',
    city: 'Gattersdorf'
  },
  {
    postalCode: '9102',
    city: 'Korb'
  },
  {
    postalCode: '9102',
    city: 'Kremschitz'
  },
  {
    postalCode: '9102',
    city: 'Kulm'
  },
  {
    postalCode: '9102',
    city: 'Mittertrixen'
  },
  {
    postalCode: '9102',
    city: 'Obertrixen'
  },
  {
    postalCode: '9102',
    city: 'Rammersdorf'
  },
  {
    postalCode: '9102',
    city: 'St. Georgen am Weinberg'
  },
  {
    postalCode: '9102',
    city: 'Skoflitzen'
  },
  {
    postalCode: '9102',
    city: 'Waisenberg'
  },
  {
    postalCode: '9102',
    city: 'Winklern'
  },
  {
    postalCode: '9102',
    city: 'Terpetzen'
  },
  {
    postalCode: '9103',
    city: 'Diex'
  },
  {
    postalCode: '9103',
    city: 'Grafenbach'
  },
  {
    postalCode: '9103',
    city: 'Großenegg'
  },
  {
    postalCode: '9103',
    city: 'Haimburgerberg'
  },
  {
    postalCode: '9103',
    city: 'Bösenort'
  },
  {
    postalCode: '9103',
    city: 'Michaelerberg'
  },
  {
    postalCode: '9103',
    city: 'Wandelitzen'
  },
  {
    postalCode: '9104',
    city: 'Pustritz'
  },
  {
    postalCode: '9104',
    city: 'Wriesen'
  },
  {
    postalCode: '9104',
    city: 'Lichtenwald'
  },
  {
    postalCode: '9104',
    city: 'St. Leonhard an der Saualpe'
  },
  {
    postalCode: '9104',
    city: 'Wölfnitz'
  },
  {
    postalCode: '9104',
    city: 'Tschrietes'
  },
  {
    postalCode: '9111',
    city: 'Großenegg'
  },
  {
    postalCode: '9111',
    city: 'Haimburgerberg'
  },
  {
    postalCode: '9111',
    city: 'Gletschach'
  },
  {
    postalCode: '9111',
    city: 'St. Jakob'
  },
  {
    postalCode: '9111',
    city: 'Attendorf'
  },
  {
    postalCode: '9111',
    city: 'Berg ob St. Martin'
  },
  {
    postalCode: '9111',
    city: 'Dobrowa'
  },
  {
    postalCode: '9111',
    city: 'Haimburg'
  },
  {
    postalCode: '9111',
    city: 'Niedertrixen'
  },
  {
    postalCode: '9111',
    city: 'St. Martin'
  },
  {
    postalCode: '9111',
    city: 'St. Stefan'
  },
  {
    postalCode: '9111',
    city: 'Steinkogel'
  },
  {
    postalCode: '9111',
    city: 'Unterlinden'
  },
  {
    postalCode: '9111',
    city: 'Dürrenmoos'
  },
  {
    postalCode: '9111',
    city: 'Berg ob Attendorf'
  },
  {
    postalCode: '9112',
    city: 'Obergreutschach'
  },
  {
    postalCode: '9112',
    city: 'Altenmarkt'
  },
  {
    postalCode: '9112',
    city: 'Enzelsdorf'
  },
  {
    postalCode: '9112',
    city: 'Erlach'
  },
  {
    postalCode: '9112',
    city: 'Gariusch'
  },
  {
    postalCode: '9112',
    city: 'Griffen'
  },
  {
    postalCode: '9112',
    city: 'Griffnergemeinde'
  },
  {
    postalCode: '9112',
    city: 'Großenegg'
  },
  {
    postalCode: '9112',
    city: 'Haberberg'
  },
  {
    postalCode: '9112',
    city: 'Kaunz'
  },
  {
    postalCode: '9112',
    city: 'Kleindörfl'
  },
  {
    postalCode: '9112',
    city: 'Klosterberg'
  },
  {
    postalCode: '9112',
    city: 'Langegg'
  },
  {
    postalCode: '9112',
    city: 'Limberg'
  },
  {
    postalCode: '9112',
    city: 'Obere Gemeinde'
  },
  {
    postalCode: '9112',
    city: 'Poppendorf'
  },
  {
    postalCode: '9112',
    city: 'Rakounig'
  },
  {
    postalCode: '9112',
    city: 'Rausch'
  },
  {
    postalCode: '9112',
    city: 'Salzenberg'
  },
  {
    postalCode: '9112',
    city: 'St. Kollmann'
  },
  {
    postalCode: '9112',
    city: 'Schloßberg'
  },
  {
    postalCode: '9112',
    city: 'Stift Griffen'
  },
  {
    postalCode: '9112',
    city: 'Untergrafenbach'
  },
  {
    postalCode: '9112',
    city: 'Untergreutschach'
  },
  {
    postalCode: '9112',
    city: 'Unterrain'
  },
  {
    postalCode: '9112',
    city: 'Wallersberg'
  },
  {
    postalCode: '9112',
    city: 'Wallersdorf'
  },
  {
    postalCode: '9112',
    city: 'St. Jakob'
  },
  {
    postalCode: '9112',
    city: 'Unternberg'
  },
  {
    postalCode: '9112',
    city: 'Gönitz'
  },
  {
    postalCode: '9113',
    city: 'Grutschen'
  },
  {
    postalCode: '9113',
    city: 'Haberberg'
  },
  {
    postalCode: '9113',
    city: 'Lind'
  },
  {
    postalCode: '9113',
    city: 'Dobrowa'
  },
  {
    postalCode: '9113',
    city: 'Eis'
  },
  {
    postalCode: '9113',
    city: 'Kanaren'
  },
  {
    postalCode: '9113',
    city: 'Kleindiex'
  },
  {
    postalCode: '9113',
    city: 'Kraßnitz'
  },
  {
    postalCode: '9113',
    city: 'Lippitzbach'
  },
  {
    postalCode: '9113',
    city: 'Obermitterdorf'
  },
  {
    postalCode: '9113',
    city: 'Ruden'
  },
  {
    postalCode: '9113',
    city: 'St. Martin'
  },
  {
    postalCode: '9113',
    city: 'St. Nikolai'
  },
  {
    postalCode: '9113',
    city: 'St. Radegund'
  },
  {
    postalCode: '9113',
    city: 'Untermitterdorf'
  },
  {
    postalCode: '9113',
    city: 'Unternberg'
  },
  {
    postalCode: '9113',
    city: 'Unterrain'
  },
  {
    postalCode: '9113',
    city: 'Wunderstätten'
  },
  {
    postalCode: '9113',
    city: 'Bach'
  },
  {
    postalCode: '9113',
    city: 'St. Lorenzen'
  },
  {
    postalCode: '9121',
    city: 'Admont'
  },
  {
    postalCode: '9121',
    city: 'Drauhofen'
  },
  {
    postalCode: '9121',
    city: 'Dullach II'
  },
  {
    postalCode: '9121',
    city: 'Höhenbergen'
  },
  {
    postalCode: '9121',
    city: 'Hungerrain'
  },
  {
    postalCode: '9121',
    city: 'Lassein'
  },
  {
    postalCode: '9121',
    city: 'Ruppgegend'
  },
  {
    postalCode: '9121',
    city: 'Tainach'
  },
  {
    postalCode: '9121',
    city: 'Tainacherfeld'
  },
  {
    postalCode: '9121',
    city: 'Greuth'
  },
  {
    postalCode: '9121',
    city: 'Ladratschen'
  },
  {
    postalCode: '9121',
    city: 'Niederdorf'
  },
  {
    postalCode: '9121',
    city: 'Rakollach'
  },
  {
    postalCode: '9121',
    city: 'Wurzen'
  },
  {
    postalCode: '9121',
    city: 'Lasseinerbucht'
  },
  {
    postalCode: '9122',
    city: 'Brenndorf'
  },
  {
    postalCode: '9122',
    city: 'Grabelsdorf'
  },
  {
    postalCode: '9122',
    city: 'Horzach'
  },
  {
    postalCode: '9122',
    city: 'Kleindorf'
  },
  {
    postalCode: '9122',
    city: 'Klopein'
  },
  {
    postalCode: '9122',
    city: 'Lanzendorf'
  },
  {
    postalCode: '9122',
    city: 'Littermoos'
  },
  {
    postalCode: '9122',
    city: 'Obersammelsdorf'
  },
  {
    postalCode: '9122',
    city: 'Piskertschach'
  },
  {
    postalCode: '9122',
    city: 'Saager'
  },
  {
    postalCode: '9122',
    city: 'St. Kanzian am Klopeiner See'
  },
  {
    postalCode: '9122',
    city: 'Schreckendorf'
  },
  {
    postalCode: '9122',
    city: 'Seelach'
  },
  {
    postalCode: '9122',
    city: 'Seidendorf'
  },
  {
    postalCode: '9122',
    city: 'Srejach'
  },
  {
    postalCode: '9122',
    city: 'Steinerberg'
  },
  {
    postalCode: '9122',
    city: 'Stein im Jauntal'
  },
  {
    postalCode: '9122',
    city: 'Unterburg'
  },
  {
    postalCode: '9122',
    city: 'Unternarrach'
  },
  {
    postalCode: '9122',
    city: 'Untersammelsdorf'
  },
  {
    postalCode: '9122',
    city: 'Weitendorf'
  },
  {
    postalCode: '9122',
    city: 'Oberseidendorf'
  },
  {
    postalCode: '9123',
    city: 'Unterkrain'
  },
  {
    postalCode: '9123',
    city: 'Müllnern'
  },
  {
    postalCode: '9123',
    city: 'Obernarrach'
  },
  {
    postalCode: '9123',
    city: 'Pogerschitzen'
  },
  {
    postalCode: '9123',
    city: 'Rückersdorf'
  },
  {
    postalCode: '9123',
    city: 'Horzach II'
  },
  {
    postalCode: '9123',
    city: 'Lauchenholz'
  },
  {
    postalCode: '9123',
    city: 'Nageltschach'
  },
  {
    postalCode: '9123',
    city: 'St. Primus'
  },
  {
    postalCode: '9123',
    city: 'St. Veit im Jauntal'
  },
  {
    postalCode: '9123',
    city: 'Steinerberg'
  },
  {
    postalCode: '9123',
    city: 'Unternarrach'
  },
  {
    postalCode: '9123',
    city: 'Vesielach'
  },
  {
    postalCode: '9125',
    city: 'Duell'
  },
  {
    postalCode: '9125',
    city: 'Edling'
  },
  {
    postalCode: '9125',
    city: 'Graben'
  },
  {
    postalCode: '9125',
    city: 'Humtschach'
  },
  {
    postalCode: '9125',
    city: 'Kohldorf'
  },
  {
    postalCode: '9125',
    city: 'Kühnsdorf'
  },
  {
    postalCode: '9125',
    city: 'Mittlern'
  },
  {
    postalCode: '9125',
    city: 'Pribelsdorf'
  },
  {
    postalCode: '9125',
    city: 'St. Marxen'
  },
  {
    postalCode: '9125',
    city: 'Seebach'
  },
  {
    postalCode: '9125',
    city: 'Wasserhofen'
  },
  {
    postalCode: '9125',
    city: 'Peratschitzen'
  },
  {
    postalCode: '9125',
    city: 'St. Lorenzen'
  },
  {
    postalCode: '9125',
    city: 'Sertschach'
  },
  {
    postalCode: '9130',
    city: 'Leibsdorf'
  },
  {
    postalCode: '9130',
    city: 'Ameisbichl'
  },
  {
    postalCode: '9130',
    city: 'Annamischl'
  },
  {
    postalCode: '9130',
    city: 'Eibelhof'
  },
  {
    postalCode: '9130',
    city: 'Eiersdorf'
  },
  {
    postalCode: '9130',
    city: 'Erlach'
  },
  {
    postalCode: '9130',
    city: 'Goritschach'
  },
  {
    postalCode: '9130',
    city: 'Haidach'
  },
  {
    postalCode: '9130',
    city: 'Kreuth'
  },
  {
    postalCode: '9130',
    city: 'Kreuzergegend'
  },
  {
    postalCode: '9130',
    city: 'Krobathen'
  },
  {
    postalCode: '9130',
    city: 'Lanzendorf'
  },
  {
    postalCode: '9130',
    city: 'Linsenberg'
  },
  {
    postalCode: '9130',
    city: 'Pischeldorf'
  },
  {
    postalCode: '9130',
    city: 'Poggersdorf'
  },
  {
    postalCode: '9130',
    city: 'Rain'
  },
  {
    postalCode: '9130',
    city: 'Raunachmoos'
  },
  {
    postalCode: '9130',
    city: 'St. Johann'
  },
  {
    postalCode: '9130',
    city: 'St. Michael ob der Gurk'
  },
  {
    postalCode: '9130',
    city: 'Ströglach'
  },
  {
    postalCode: '9130',
    city: 'Wabelsdorf'
  },
  {
    postalCode: '9130',
    city: 'Wirtschach'
  },
  {
    postalCode: '9130',
    city: 'Pubersdorf'
  },
  {
    postalCode: '9131',
    city: 'Hinterberg'
  },
  {
    postalCode: '9131',
    city: 'Mieger'
  },
  {
    postalCode: '9131',
    city: 'Berg'
  },
  {
    postalCode: '9131',
    city: 'Goritschach'
  },
  {
    postalCode: '9131',
    city: 'Haber'
  },
  {
    postalCode: '9131',
    city: 'Kohldorf'
  },
  {
    postalCode: '9131',
    city: 'Kosasmojach'
  },
  {
    postalCode: '9131',
    city: 'Obermieger'
  },
  {
    postalCode: '9131',
    city: 'Obitschach'
  },
  {
    postalCode: '9131',
    city: 'Rottenstein'
  },
  {
    postalCode: '9131',
    city: 'Untermieger'
  },
  {
    postalCode: '9131',
    city: 'Saager'
  },
  {
    postalCode: '9131',
    city: 'Sabuatach'
  },
  {
    postalCode: '9131',
    city: 'Linsendorf'
  },
  {
    postalCode: '9131',
    city: 'Aich'
  },
  {
    postalCode: '9131',
    city: 'Althofen'
  },
  {
    postalCode: '9131',
    city: 'Dolina'
  },
  {
    postalCode: '9131',
    city: 'Froschendorf'
  },
  {
    postalCode: '9131',
    city: 'Grafenstein'
  },
  {
    postalCode: '9131',
    city: 'Gumisch'
  },
  {
    postalCode: '9131',
    city: 'Haidach'
  },
  {
    postalCode: '9131',
    city: 'Hum'
  },
  {
    postalCode: '9131',
    city: 'Klein Venedig'
  },
  {
    postalCode: '9131',
    city: 'Lind'
  },
  {
    postalCode: '9131',
    city: 'Münzendorf'
  },
  {
    postalCode: '9131',
    city: 'Oberfischern'
  },
  {
    postalCode: '9131',
    city: 'Oberwuchel'
  },
  {
    postalCode: '9131',
    city: 'Pirk'
  },
  {
    postalCode: '9131',
    city: 'Replach'
  },
  {
    postalCode: '9131',
    city: 'Sand'
  },
  {
    postalCode: '9131',
    city: 'St. Peter'
  },
  {
    postalCode: '9131',
    city: 'Schloss Rain'
  },
  {
    postalCode: '9131',
    city: 'Schulterndorf'
  },
  {
    postalCode: '9131',
    city: 'Tainacherfeld'
  },
  {
    postalCode: '9131',
    city: 'Thon'
  },
  {
    postalCode: '9131',
    city: 'Truttendorf'
  },
  {
    postalCode: '9131',
    city: 'Unterfischern'
  },
  {
    postalCode: '9131',
    city: 'Unterwuchel'
  },
  {
    postalCode: '9131',
    city: 'Werda'
  },
  {
    postalCode: '9131',
    city: 'Wölfnitz'
  },
  {
    postalCode: '9131',
    city: 'Zapfendorf'
  },
  {
    postalCode: '9131',
    city: 'Pakein'
  },
  {
    postalCode: '9131',
    city: 'Skarbin'
  },
  {
    postalCode: '9132',
    city: 'Abriach'
  },
  {
    postalCode: '9132',
    city: 'Abtei'
  },
  {
    postalCode: '9132',
    city: 'Dolintschach'
  },
  {
    postalCode: '9132',
    city: 'Enzelsdorf'
  },
  {
    postalCode: '9132',
    city: 'Feld'
  },
  {
    postalCode: '9132',
    city: 'Freibach'
  },
  {
    postalCode: '9132',
    city: 'Gallizien'
  },
  {
    postalCode: '9132',
    city: 'Glantschach'
  },
  {
    postalCode: '9132',
    city: 'Goritschach'
  },
  {
    postalCode: '9132',
    city: 'Krejanzach'
  },
  {
    postalCode: '9132',
    city: 'Möchling'
  },
  {
    postalCode: '9132',
    city: 'Moos'
  },
  {
    postalCode: '9132',
    city: 'Pirk'
  },
  {
    postalCode: '9132',
    city: 'Pölzling'
  },
  {
    postalCode: '9132',
    city: 'Robesch'
  },
  {
    postalCode: '9132',
    city: 'Unterkrain'
  },
  {
    postalCode: '9132',
    city: 'Vellach'
  },
  {
    postalCode: '9132',
    city: 'Wildenstein'
  },
  {
    postalCode: '9133',
    city: 'Blasnitzen '
  },
  {
    postalCode: '9133',
    city: 'Rechberg '
  },
  {
    postalCode: '9133',
    city: 'Unterort '
  },
  {
    postalCode: '9133',
    city: 'Weißenbach '
  },
  {
    postalCode: '9133',
    city: 'Zauchen '
  },
  {
    postalCode: '9133',
    city: 'Drabunaschach'
  },
  {
    postalCode: '9133',
    city: 'Blasnitzenberg'
  },
  {
    postalCode: '9133',
    city: 'Wigasnitz'
  },
  {
    postalCode: '9133',
    city: 'Dullach'
  },
  {
    postalCode: '9133',
    city: 'Goritschach'
  },
  {
    postalCode: '9133',
    city: 'Hart'
  },
  {
    postalCode: '9133',
    city: 'Jerischach'
  },
  {
    postalCode: '9133',
    city: 'Kleinzapfen'
  },
  {
    postalCode: '9133',
    city: 'Miklauzhof'
  },
  {
    postalCode: '9133',
    city: 'Proboj'
  },
  {
    postalCode: '9133',
    city: 'Rain'
  },
  {
    postalCode: '9133',
    city: 'Sielach'
  },
  {
    postalCode: '9133',
    city: 'Sittersdorf'
  },
  {
    postalCode: '9133',
    city: 'Weinberg'
  },
  {
    postalCode: '9133',
    city: 'Winkel'
  },
  {
    postalCode: '9135',
    city: 'Ebriach '
  },
  {
    postalCode: '9135',
    city: 'Bad Eisenkappel'
  },
  {
    postalCode: '9135',
    city: 'Koprein Petzen '
  },
  {
    postalCode: '9135',
    city: 'Koprein Sonnseite '
  },
  {
    postalCode: '9135',
    city: 'Leppen '
  },
  {
    postalCode: '9135',
    city: 'Lobnig '
  },
  {
    postalCode: '9135',
    city: 'Remschenig '
  },
  {
    postalCode: '9135',
    city: 'Trögern '
  },
  {
    postalCode: '9135',
    city: 'Vellach '
  },
  {
    postalCode: '9135',
    city: 'Zauchen '
  },
  {
    postalCode: '9141',
    city: 'Buchbrunn'
  },
  {
    postalCode: '9141',
    city: 'Buchhalm'
  },
  {
    postalCode: '9141',
    city: 'Eberndorf'
  },
  {
    postalCode: '9141',
    city: 'Gablern'
  },
  {
    postalCode: '9141',
    city: 'Gösselsdorf'
  },
  {
    postalCode: '9141',
    city: 'Hart'
  },
  {
    postalCode: '9141',
    city: 'Hof'
  },
  {
    postalCode: '9141',
    city: 'Homitzberg'
  },
  {
    postalCode: '9141',
    city: 'Köcking'
  },
  {
    postalCode: '9141',
    city: 'Loibegg'
  },
  {
    postalCode: '9141',
    city: 'Mökriach'
  },
  {
    postalCode: '9141',
    city: 'Oberburg'
  },
  {
    postalCode: '9141',
    city: 'Pudab'
  },
  {
    postalCode: '9141',
    city: 'Unterbergen'
  },
  {
    postalCode: '9141',
    city: 'Blasnitzenberg'
  },
  {
    postalCode: '9141',
    city: 'Altendorf'
  },
  {
    postalCode: '9141',
    city: 'Homelitschach'
  },
  {
    postalCode: '9141',
    city: 'Kristendorf'
  },
  {
    postalCode: '9141',
    city: 'Pfannsdorf'
  },
  {
    postalCode: '9141',
    city: 'Polena'
  },
  {
    postalCode: '9141',
    city: 'Sagerberg'
  },
  {
    postalCode: '9141',
    city: 'Sonnegg'
  },
  {
    postalCode: '9141',
    city: 'Tichoja'
  },
  {
    postalCode: '9141',
    city: 'Wrießnitz'
  },
  {
    postalCode: '9142',
    city: 'Globasnitz '
  },
  {
    postalCode: '9142',
    city: 'Jaunstein '
  },
  {
    postalCode: '9142',
    city: 'Kleindorf '
  },
  {
    postalCode: '9142',
    city: 'St. Stefan '
  },
  {
    postalCode: '9142',
    city: 'Slovenjach '
  },
  {
    postalCode: '9142',
    city: 'Tschepitschach '
  },
  {
    postalCode: '9142',
    city: 'Unterbergen '
  },
  {
    postalCode: '9142',
    city: 'Wackendorf '
  },
  {
    postalCode: '9142',
    city: 'Podrain'
  },
  {
    postalCode: '9143',
    city: 'Dolintschitschach '
  },
  {
    postalCode: '9143',
    city: 'Feistritz ob Bleiburg '
  },
  {
    postalCode: '9143',
    city: 'Gonowetz '
  },
  {
    postalCode: '9143',
    city: 'Hinterlibitsch '
  },
  {
    postalCode: '9143',
    city: 'Hof '
  },
  {
    postalCode: '9143',
    city: 'Lettenstätten '
  },
  {
    postalCode: '9143',
    city: 'Pirkdorf '
  },
  {
    postalCode: '9143',
    city: 'Ruttach-Schmelz '
  },
  {
    postalCode: '9143',
    city: 'St. Michael ob Bleiburg '
  },
  {
    postalCode: '9143',
    city: 'Tscherberg '
  },
  {
    postalCode: '9143',
    city: 'Unterlibitsch '
  },
  {
    postalCode: '9143',
    city: 'Unterort '
  },
  {
    postalCode: '9143',
    city: 'Winkel '
  },
  {
    postalCode: '9143',
    city: 'Traundorf '
  },
  {
    postalCode: '9150',
    city: 'Aich '
  },
  {
    postalCode: '9150',
    city: 'Bleiburg '
  },
  {
    postalCode: '9150',
    city: 'Dobrowa '
  },
  {
    postalCode: '9150',
    city: 'Draurain '
  },
  {
    postalCode: '9150',
    city: 'Ebersdorf '
  },
  {
    postalCode: '9150',
    city: 'Einersdorf '
  },
  {
    postalCode: '9150',
    city: 'Grablach'
  },
  {
    postalCode: '9150',
    city: 'Kömmel '
  },
  {
    postalCode: '9150',
    city: 'Kömmelgupf '
  },
  {
    postalCode: '9150',
    city: 'Lokowitzen'
  },
  {
    postalCode: '9150',
    city: 'Moos '
  },
  {
    postalCode: '9150',
    city: 'Replach '
  },
  {
    postalCode: '9150',
    city: 'Rinkenberg '
  },
  {
    postalCode: '9150',
    city: 'Rinkolach '
  },
  {
    postalCode: '9150',
    city: 'Ruttach '
  },
  {
    postalCode: '9150',
    city: 'St. Georgen'
  },
  {
    postalCode: '9150',
    city: 'St. Margarethen'
  },
  {
    postalCode: '9150',
    city: 'Schattenberg'
  },
  {
    postalCode: '9150',
    city: 'Schilterndorf '
  },
  {
    postalCode: '9150',
    city: 'Weißenstein'
  },
  {
    postalCode: '9150',
    city: 'Wiederndorf '
  },
  {
    postalCode: '9150',
    city: 'Woroujach'
  },
  {
    postalCode: '9150',
    city: 'Gonowetz '
  },
  {
    postalCode: '9150',
    city: 'Hinterlibitsch '
  },
  {
    postalCode: '9150',
    city: 'Penk '
  },
  {
    postalCode: '9150',
    city: 'Rischberg '
  },
  {
    postalCode: '9150',
    city: 'Unterort '
  },
  {
    postalCode: '9150',
    city: 'Winkel '
  },
  {
    postalCode: '9150',
    city: 'Loibach'
  },
  {
    postalCode: '9155',
    city: 'Bach'
  },
  {
    postalCode: '9155',
    city: 'Berg ob Leifling'
  },
  {
    postalCode: '9155',
    city: 'Draugegend '
  },
  {
    postalCode: '9155',
    city: 'Graditschach'
  },
  {
    postalCode: '9155',
    city: 'Hart '
  },
  {
    postalCode: '9155',
    city: 'Heiligenstadt '
  },
  {
    postalCode: '9155',
    city: 'Illmitzen'
  },
  {
    postalCode: '9155',
    city: 'Kogelnigberg'
  },
  {
    postalCode: '9155',
    city: 'Leifling'
  },
  {
    postalCode: '9155',
    city: 'Motschula'
  },
  {
    postalCode: '9155',
    city: 'Neuhaus'
  },
  {
    postalCode: '9155',
    city: 'Oberdorf '
  },
  {
    postalCode: '9155',
    city: 'Pudlach'
  },
  {
    postalCode: '9155',
    city: 'Schwabegg '
  },
  {
    postalCode: '9155',
    city: 'Unterdorf '
  },
  {
    postalCode: '9155',
    city: 'Wesnitzen'
  },
  {
    postalCode: '9161',
    city: 'Gaisach'
  },
  {
    postalCode: '9161',
    city: 'Göriach'
  },
  {
    postalCode: '9161',
    city: 'Hollenburg'
  },
  {
    postalCode: '9161',
    city: 'Lambichl'
  },
  {
    postalCode: '9161',
    city: 'Unterschloßberg'
  },
  {
    postalCode: '9161',
    city: 'Wegscheide'
  },
  {
    postalCode: '9161',
    city: 'Angern'
  },
  {
    postalCode: '9161',
    city: 'Angersbichl'
  },
  {
    postalCode: '9161',
    city: 'Ehrensdorf'
  },
  {
    postalCode: '9161',
    city: 'Göltschach'
  },
  {
    postalCode: '9161',
    city: 'Haimach'
  },
  {
    postalCode: '9161',
    city: 'Maria Rain'
  },
  {
    postalCode: '9161',
    city: 'Nadram'
  },
  {
    postalCode: '9161',
    city: 'Oberguntschach'
  },
  {
    postalCode: '9161',
    city: 'Obertöllern'
  },
  {
    postalCode: '9161',
    city: 'Saberda'
  },
  {
    postalCode: '9161',
    city: 'St. Ulrich'
  },
  {
    postalCode: '9161',
    city: 'Stemeritsch'
  },
  {
    postalCode: '9161',
    city: 'Strantschitschach'
  },
  {
    postalCode: '9161',
    city: 'Toppelsdorf'
  },
  {
    postalCode: '9161',
    city: 'Unterguntschach'
  },
  {
    postalCode: '9161',
    city: 'Untertöllern'
  },
  {
    postalCode: '9161',
    city: 'Tschedram'
  },
  {
    postalCode: '9162',
    city: 'Rabenberg'
  },
  {
    postalCode: '9162',
    city: 'St. Johann im Rosental'
  },
  {
    postalCode: '9162',
    city: 'Weizelsdorf'
  },
  {
    postalCode: '9162',
    city: 'Babniak'
  },
  {
    postalCode: '9162',
    city: 'Dornach'
  },
  {
    postalCode: '9162',
    city: 'Kappel an der Drau'
  },
  {
    postalCode: '9162',
    city: 'Kirschentheuer'
  },
  {
    postalCode: '9162',
    city: 'Laiplach'
  },
  {
    postalCode: '9162',
    city: 'Singerberg'
  },
  {
    postalCode: '9162',
    city: 'Strau'
  },
  {
    postalCode: '9163',
    city: 'Unterbergen'
  },
  {
    postalCode: '9163',
    city: 'Unterloibl'
  },
  {
    postalCode: '9163',
    city: 'Loibltal '
  },
  {
    postalCode: '9163',
    city: 'Windisch Bleiberg '
  },
  {
    postalCode: '9163',
    city: 'Bodental '
  },
  {
    postalCode: '9163',
    city: 'Strugarjach '
  },
  {
    postalCode: '9170',
    city: 'Dörfl'
  },
  {
    postalCode: '9170',
    city: 'Ferlach'
  },
  {
    postalCode: '9170',
    city: 'Glainach'
  },
  {
    postalCode: '9170',
    city: 'Görtschach'
  },
  {
    postalCode: '9170',
    city: 'Jaklin'
  },
  {
    postalCode: '9170',
    city: 'Laak'
  },
  {
    postalCode: '9170',
    city: 'Otrouza'
  },
  {
    postalCode: '9170',
    city: 'Rauth'
  },
  {
    postalCode: '9170',
    city: 'Reßnig'
  },
  {
    postalCode: '9170',
    city: 'Seidolach'
  },
  {
    postalCode: '9170',
    city: 'Tratten'
  },
  {
    postalCode: '9170',
    city: 'Unterferlach'
  },
  {
    postalCode: '9170',
    city: 'Unterglainach'
  },
  {
    postalCode: '9170',
    city: 'Waidisch'
  },
  {
    postalCode: '9170',
    city: 'Zell-Freibach '
  },
  {
    postalCode: '9170',
    city: 'Zell-Koschuta '
  },
  {
    postalCode: '9170',
    city: 'Zell-Mitterwinkel '
  },
  {
    postalCode: '9170',
    city: 'Zell-Oberwinkel '
  },
  {
    postalCode: '9170',
    city: 'Zell-Pfarre '
  },
  {
    postalCode: '9170',
    city: 'Zell-Schaida '
  },
  {
    postalCode: '9173',
    city: 'Freibach'
  },
  {
    postalCode: '9173',
    city: 'Dobrowa'
  },
  {
    postalCode: '9173',
    city: 'Dullach'
  },
  {
    postalCode: '9173',
    city: 'Gotschuchen'
  },
  {
    postalCode: '9173',
    city: 'Gupf'
  },
  {
    postalCode: '9173',
    city: 'Hintergupf'
  },
  {
    postalCode: '9173',
    city: 'Homölisch'
  },
  {
    postalCode: '9173',
    city: 'Niederdörfl'
  },
  {
    postalCode: '9173',
    city: 'Oberdörfl'
  },
  {
    postalCode: '9173',
    city: 'Sabosach'
  },
  {
    postalCode: '9173',
    city: 'St. Margareten im Rosental'
  },
  {
    postalCode: '9173',
    city: 'Seel'
  },
  {
    postalCode: '9173',
    city: 'Trieblach'
  },
  {
    postalCode: '9173',
    city: 'Zell-Homölisch '
  },
  {
    postalCode: '9181',
    city: 'Bärental'
  },
  {
    postalCode: '9181',
    city: 'Feistritz im Rosental'
  },
  {
    postalCode: '9181',
    city: 'Matschach'
  },
  {
    postalCode: '9181',
    city: 'Suetschach'
  },
  {
    postalCode: '9181',
    city: 'Hundsdorf'
  },
  {
    postalCode: '9181',
    city: 'Niederdörfl '
  },
  {
    postalCode: '9182',
    city: 'Dragositschach'
  },
  {
    postalCode: '9182',
    city: 'Fresnach'
  },
  {
    postalCode: '9182',
    city: 'Greuth'
  },
  {
    postalCode: '9182',
    city: 'Maria Elend'
  },
  {
    postalCode: '9182',
    city: 'St. Oswald'
  },
  {
    postalCode: '9182',
    city: 'Tallach'
  },
  {
    postalCode: '9183',
    city: 'Frießnitz'
  },
  {
    postalCode: '9183',
    city: 'Kanin'
  },
  {
    postalCode: '9183',
    city: 'Lessach'
  },
  {
    postalCode: '9183',
    city: 'Rosenbach'
  },
  {
    postalCode: '9183',
    city: 'Schlatten'
  },
  {
    postalCode: '9184',
    city: 'St. Johann'
  },
  {
    postalCode: '9184',
    city: 'Dreilach'
  },
  {
    postalCode: '9184',
    city: 'Feistritz'
  },
  {
    postalCode: '9184',
    city: 'Gorintschach'
  },
  {
    postalCode: '9184',
    city: 'Längdorf'
  },
  {
    postalCode: '9184',
    city: 'Mühlbach'
  },
  {
    postalCode: '9184',
    city: 'St. Jakob im Rosental'
  },
  {
    postalCode: '9184',
    city: 'St. Peter'
  },
  {
    postalCode: '9184',
    city: 'Srajach'
  },
  {
    postalCode: '9184',
    city: 'Tösching'
  },
  {
    postalCode: '9184',
    city: 'Winkl'
  },
  {
    postalCode: '9201',
    city: 'Klagenfurt'
  },
  {
    postalCode: '9201',
    city: 'Görtschach'
  },
  {
    postalCode: '9201',
    city: 'Krumpendorf'
  },
  {
    postalCode: '9201',
    city: 'Hohenfeld'
  },
  {
    postalCode: '9210',
    city: 'Pritschitz'
  },
  {
    postalCode: '9210',
    city: 'Kreggab'
  },
  {
    postalCode: '9210',
    city: 'Pörtschach am Wörther See'
  },
  {
    postalCode: '9212',
    city: 'Arndorf'
  },
  {
    postalCode: '9212',
    city: 'Greilitz'
  },
  {
    postalCode: '9212',
    city: 'Hadanig'
  },
  {
    postalCode: '9212',
    city: 'Karl'
  },
  {
    postalCode: '9212',
    city: 'Pavor'
  },
  {
    postalCode: '9212',
    city: 'Pernach'
  },
  {
    postalCode: '9212',
    city: 'Saag'
  },
  {
    postalCode: '9212',
    city: 'St. Bartlmä'
  },
  {
    postalCode: '9212',
    city: 'St. Martin am Techelsberg'
  },
  {
    postalCode: '9212',
    city: 'Schwarzendorf'
  },
  {
    postalCode: '9212',
    city: 'Sekull'
  },
  {
    postalCode: '9212',
    city: 'Tibitsch'
  },
  {
    postalCode: '9212',
    city: 'Töpriach'
  },
  {
    postalCode: '9212',
    city: 'Töschling'
  },
  {
    postalCode: '9212',
    city: 'Trabenig'
  },
  {
    postalCode: '9212',
    city: 'Trieblach'
  },
  {
    postalCode: '9212',
    city: 'Ebenfeld'
  },
  {
    postalCode: '9220',
    city: 'Aich'
  },
  {
    postalCode: '9220',
    city: 'Auen'
  },
  {
    postalCode: '9220',
    city: 'Saag'
  },
  {
    postalCode: '9220',
    city: 'Augsdorf'
  },
  {
    postalCode: '9220',
    city: 'Selpritsch'
  },
  {
    postalCode: '9220',
    city: 'Unterjeserz'
  },
  {
    postalCode: '9220',
    city: 'Kerschdorf'
  },
  {
    postalCode: '9220',
    city: 'Oberjeserz'
  },
  {
    postalCode: '9220',
    city: 'Bach'
  },
  {
    postalCode: '9220',
    city: 'Duel'
  },
  {
    postalCode: '9220',
    city: 'Fahrendorf'
  },
  {
    postalCode: '9220',
    city: 'Göriach'
  },
  {
    postalCode: '9220',
    city: 'Kranzlhofen'
  },
  {
    postalCode: '9220',
    city: 'Lind ob Velden'
  },
  {
    postalCode: '9220',
    city: 'Rajach'
  },
  {
    postalCode: '9220',
    city: 'Saisserach'
  },
  {
    postalCode: '9220',
    city: 'Sonnental'
  },
  {
    postalCode: '9220',
    city: 'Unterwinklern'
  },
  {
    postalCode: '9220',
    city: 'Velden am Wörther See'
  },
  {
    postalCode: '9220',
    city: 'Weinzierl'
  },
  {
    postalCode: '9220',
    city: 'Kantnig'
  },
  {
    postalCode: '9220',
    city: 'Sternberg'
  },
  {
    postalCode: '9231',
    city: 'Untertauern'
  },
  {
    postalCode: '9231',
    city: 'Dröschitz'
  },
  {
    postalCode: '9231',
    city: 'Köstenberg'
  },
  {
    postalCode: '9231',
    city: 'Oberdorf'
  },
  {
    postalCode: '9231',
    city: 'Oberwinklern'
  },
  {
    postalCode: '9231',
    city: 'Wurzen'
  },
  {
    postalCode: '9232',
    city: 'Berg'
  },
  {
    postalCode: '9232',
    city: 'Buchheim'
  },
  {
    postalCode: '9232',
    city: 'Dolintschach'
  },
  {
    postalCode: '9232',
    city: 'Obergoritschach'
  },
  {
    postalCode: '9232',
    city: 'Pirk'
  },
  {
    postalCode: '9232',
    city: 'Raun'
  },
  {
    postalCode: '9232',
    city: 'Untergoritschach'
  },
  {
    postalCode: '9232',
    city: 'Bergl'
  },
  {
    postalCode: '9232',
    city: 'Drau'
  },
  {
    postalCode: '9232',
    city: 'Duel'
  },
  {
    postalCode: '9232',
    city: 'Emmersdorf'
  },
  {
    postalCode: '9232',
    city: 'Frög'
  },
  {
    postalCode: '9232',
    city: 'Frojach'
  },
  {
    postalCode: '9232',
    city: 'Kleinberg'
  },
  {
    postalCode: '9232',
    city: 'Rosegg'
  },
  {
    postalCode: '9232',
    city: 'St. Lambrecht'
  },
  {
    postalCode: '9232',
    city: 'St. Martin'
  },
  {
    postalCode: '9241',
    city: 'Wudmath'
  },
  {
    postalCode: '9241',
    city: 'Damtschach'
  },
  {
    postalCode: '9241',
    city: 'Dragnitz'
  },
  {
    postalCode: '9241',
    city: 'Duel'
  },
  {
    postalCode: '9241',
    city: 'Föderlach I'
  },
  {
    postalCode: '9241',
    city: 'Goritschach'
  },
  {
    postalCode: '9241',
    city: 'Gottestal'
  },
  {
    postalCode: '9241',
    city: 'Kaltschach'
  },
  {
    postalCode: '9241',
    city: 'Kletschach'
  },
  {
    postalCode: '9241',
    city: 'Krottendorf'
  },
  {
    postalCode: '9241',
    city: 'Lichtpold'
  },
  {
    postalCode: '9241',
    city: 'Neudorf'
  },
  {
    postalCode: '9241',
    city: 'Ragain'
  },
  {
    postalCode: '9241',
    city: 'Sand'
  },
  {
    postalCode: '9241',
    city: 'Schleben'
  },
  {
    postalCode: '9241',
    city: 'Stallhofen'
  },
  {
    postalCode: '9241',
    city: 'Sternberg'
  },
  {
    postalCode: '9241',
    city: 'Terlach'
  },
  {
    postalCode: '9241',
    city: 'Trabenig'
  },
  {
    postalCode: '9241',
    city: 'Umberg'
  },
  {
    postalCode: '9241',
    city: 'Wernberg'
  },
  {
    postalCode: '9241',
    city: 'Zettin'
  },
  {
    postalCode: '9241',
    city: 'Föderlach II'
  },
  {
    postalCode: '9300',
    city: 'Breitenstein'
  },
  {
    postalCode: '9300',
    city: 'Siebenaich'
  },
  {
    postalCode: '9300',
    city: 'Steinbrücken'
  },
  {
    postalCode: '9300',
    city: 'Tratschweg'
  },
  {
    postalCode: '9300',
    city: 'Wimitzstein'
  },
  {
    postalCode: '9300',
    city: 'Dornhof'
  },
  {
    postalCode: '9300',
    city: 'Frauenstein'
  },
  {
    postalCode: '9300',
    city: 'Gassing'
  },
  {
    postalCode: '9300',
    city: 'Grassen'
  },
  {
    postalCode: '9300',
    city: 'Hammergraben'
  },
  {
    postalCode: '9300',
    city: 'Hintnausdorf'
  },
  {
    postalCode: '9300',
    city: 'Lorenziberg'
  },
  {
    postalCode: '9300',
    city: 'Obermühlbach'
  },
  {
    postalCode: '9300',
    city: 'Puppitsch'
  },
  {
    postalCode: '9300',
    city: 'Zensweg'
  },
  {
    postalCode: '9300',
    city: 'Dörfl'
  },
  {
    postalCode: '9300',
    city: 'Eggen'
  },
  {
    postalCode: '9300',
    city: 'Gray'
  },
  {
    postalCode: '9300',
    city: 'Höffern'
  },
  {
    postalCode: '9300',
    city: 'Hörzenbrunn'
  },
  {
    postalCode: '9300',
    city: 'Laggen'
  },
  {
    postalCode: '9300',
    city: 'Nußberg'
  },
  {
    postalCode: '9300',
    city: 'Predl'
  },
  {
    postalCode: '9300',
    city: 'Schaumboden'
  },
  {
    postalCode: '9300',
    city: 'Stammerdorf'
  },
  {
    postalCode: '9300',
    city: 'Steinbichl'
  },
  {
    postalCode: '9300',
    city: 'Stromberg'
  },
  {
    postalCode: '9300',
    city: 'Zwein'
  },
  {
    postalCode: '9300',
    city: 'Dreifaltigkeit'
  },
  {
    postalCode: '9300',
    city: 'Graßdorf'
  },
  {
    postalCode: '9300',
    city: 'Hunnenbrunn'
  },
  {
    postalCode: '9300',
    city: 'Kraindorf'
  },
  {
    postalCode: '9300',
    city: 'Mail'
  },
  {
    postalCode: '9300',
    city: 'Sand'
  },
  {
    postalCode: '9300',
    city: 'Goggerwenig'
  },
  {
    postalCode: '9300',
    city: 'Pirkfeld'
  },
  {
    postalCode: '9300',
    city: 'Podeblach'
  },
  {
    postalCode: '9300',
    city: 'Scheifling'
  },
  {
    postalCode: '9300',
    city: 'Taggenbrunn'
  },
  {
    postalCode: '9300',
    city: 'Tschirnig'
  },
  {
    postalCode: '9300',
    city: 'Mail-Süd'
  },
  {
    postalCode: '9300',
    city: 'St. Peter'
  },
  {
    postalCode: '9300',
    city: 'Arndorf'
  },
  {
    postalCode: '9300',
    city: 'Baardorf'
  },
  {
    postalCode: '9300',
    city: 'Dellach'
  },
  {
    postalCode: '9300',
    city: 'Draschelbach'
  },
  {
    postalCode: '9300',
    city: 'Hörzendorf'
  },
  {
    postalCode: '9300',
    city: 'Holz'
  },
  {
    postalCode: '9300',
    city: 'Muraunberg'
  },
  {
    postalCode: '9300',
    city: 'Niederdorf'
  },
  {
    postalCode: '9300',
    city: 'Projern'
  },
  {
    postalCode: '9300',
    city: 'Radweg'
  },
  {
    postalCode: '9300',
    city: 'Streimberg'
  },
  {
    postalCode: '9300',
    city: 'Unterbergen'
  },
  {
    postalCode: '9300',
    city: 'Aich'
  },
  {
    postalCode: '9300',
    city: 'Altglandorf'
  },
  {
    postalCode: '9300',
    city: 'Baiersdorf'
  },
  {
    postalCode: '9300',
    city: 'Blintendorf'
  },
  {
    postalCode: '9300',
    city: 'Mairist'
  },
  {
    postalCode: '9300',
    city: 'Milbersdorf'
  },
  {
    postalCode: '9300',
    city: 'Pflugern'
  },
  {
    postalCode: '9300',
    city: 'Ritzendorf'
  },
  {
    postalCode: '9300',
    city: 'St. Andrä'
  },
  {
    postalCode: '9300',
    city: 'St. Donat'
  },
  {
    postalCode: '9300',
    city: 'St. Veit an der Glan'
  },
  {
    postalCode: '9300',
    city: 'Untermühlbach'
  },
  {
    postalCode: '9300',
    city: 'Zwischenbergen'
  },
  {
    postalCode: '9300',
    city: 'Treffelsdorf'
  },
  {
    postalCode: '9311',
    city: 'Leiten'
  },
  {
    postalCode: '9311',
    city: 'Pfannhof'
  },
  {
    postalCode: '9311',
    city: 'Pörlinghof'
  },
  {
    postalCode: '9311',
    city: 'Seebichl'
  },
  {
    postalCode: '9311',
    city: 'Tratschweg'
  },
  {
    postalCode: '9311',
    city: 'Wimitz'
  },
  {
    postalCode: '9311',
    city: 'Zedl bei Kraig'
  },
  {
    postalCode: '9311',
    city: 'Grassen'
  },
  {
    postalCode: '9311',
    city: 'Äußere Wimitz'
  },
  {
    postalCode: '9311',
    city: 'Föbing'
  },
  {
    postalCode: '9311',
    city: 'Innere Wimitz'
  },
  {
    postalCode: '9311',
    city: 'Steinbichl'
  },
  {
    postalCode: '9311',
    city: 'Zwein'
  },
  {
    postalCode: '9311',
    city: 'Grua'
  },
  {
    postalCode: '9311',
    city: 'Mellach'
  },
  {
    postalCode: '9311',
    city: 'Kraig'
  },
  {
    postalCode: '9311',
    city: 'Überfeld'
  },
  {
    postalCode: '9312',
    city: 'Drasenberg'
  },
  {
    postalCode: '9312',
    city: 'Pfannhof'
  },
  {
    postalCode: '9312',
    city: 'Bergwerksgraben'
  },
  {
    postalCode: '9312',
    city: 'Dielach'
  },
  {
    postalCode: '9312',
    city: 'Eixendorf'
  },
  {
    postalCode: '9312',
    city: 'Gaming'
  },
  {
    postalCode: '9312',
    city: 'Kogl'
  },
  {
    postalCode: '9312',
    city: 'Meiselding'
  },
  {
    postalCode: '9312',
    city: 'Pirka'
  },
  {
    postalCode: '9312',
    city: 'Ringberg'
  },
  {
    postalCode: '9312',
    city: 'Straganz'
  },
  {
    postalCode: '9312',
    city: 'Unterdeka'
  },
  {
    postalCode: '9312',
    city: 'Gerach'
  },
  {
    postalCode: '9312',
    city: 'Rastenfeld'
  },
  {
    postalCode: '9312',
    city: 'Stein'
  },
  {
    postalCode: '9312',
    city: 'Treffling'
  },
  {
    postalCode: '9312',
    city: 'Tschatschg'
  },
  {
    postalCode: '9312',
    city: 'Welsbach'
  },
  {
    postalCode: '9313',
    city: 'Drasendorf'
  },
  {
    postalCode: '9313',
    city: 'Bernaich'
  },
  {
    postalCode: '9313',
    city: 'Wolschart'
  },
  {
    postalCode: '9313',
    city: 'Dellach'
  },
  {
    postalCode: '9313',
    city: 'Fiming'
  },
  {
    postalCode: '9313',
    city: 'Krottendorf'
  },
  {
    postalCode: '9313',
    city: 'Reipersdorf'
  },
  {
    postalCode: '9313',
    city: 'Rottenstein'
  },
  {
    postalCode: '9313',
    city: 'St. Georgen am Längsee'
  },
  {
    postalCode: '9313',
    city: 'Töplach'
  },
  {
    postalCode: '9313',
    city: 'Unterlatschach'
  },
  {
    postalCode: '9313',
    city: 'St. Peter'
  },
  {
    postalCode: '9314',
    city: 'Gasselhof'
  },
  {
    postalCode: '9314',
    city: 'Mannsberg'
  },
  {
    postalCode: '9314',
    city: 'Mauer'
  },
  {
    postalCode: '9314',
    city: 'Pölling'
  },
  {
    postalCode: '9314',
    city: 'Unterpassering'
  },
  {
    postalCode: '9314',
    city: 'Windisch'
  },
  {
    postalCode: '9314',
    city: 'Garzern'
  },
  {
    postalCode: '9314',
    city: 'Gösseling'
  },
  {
    postalCode: '9314',
    city: 'Hochosterwitz'
  },
  {
    postalCode: '9314',
    city: 'Kreutern'
  },
  {
    postalCode: '9314',
    city: 'Labon'
  },
  {
    postalCode: '9314',
    city: 'Launsdorf'
  },
  {
    postalCode: '9314',
    city: 'Maigern'
  },
  {
    postalCode: '9314',
    city: 'Niederosterwitz'
  },
  {
    postalCode: '9314',
    city: 'Rain'
  },
  {
    postalCode: '9314',
    city: 'St. Martin'
  },
  {
    postalCode: '9314',
    city: 'St. Sebastian'
  },
  {
    postalCode: '9314',
    city: 'Thalsdorf'
  },
  {
    postalCode: '9314',
    city: 'Unterbruckendorf'
  },
  {
    postalCode: '9314',
    city: 'Weindorf'
  },
  {
    postalCode: '9314',
    city: 'Wiendorf'
  },
  {
    postalCode: '9321',
    city: 'Boden'
  },
  {
    postalCode: '9321',
    city: 'Dobranberg'
  },
  {
    postalCode: '9321',
    city: 'Dürnfeld'
  },
  {
    postalCode: '9321',
    city: 'Edling'
  },
  {
    postalCode: '9321',
    city: 'Freiendorf'
  },
  {
    postalCode: '9321',
    city: 'Garzern'
  },
  {
    postalCode: '9321',
    city: 'Geiselsdorf'
  },
  {
    postalCode: '9321',
    city: 'Gölsach'
  },
  {
    postalCode: '9321',
    city: 'Grillberg'
  },
  {
    postalCode: '9321',
    city: 'Haide'
  },
  {
    postalCode: '9321',
    city: 'Haidkirchen'
  },
  {
    postalCode: '9321',
    city: 'Kappel am Krappfeld'
  },
  {
    postalCode: '9321',
    city: 'Krasta'
  },
  {
    postalCode: '9321',
    city: 'Landbrücken'
  },
  {
    postalCode: '9321',
    city: 'Latschach'
  },
  {
    postalCode: '9321',
    city: 'Lind'
  },
  {
    postalCode: '9321',
    city: 'Möriach'
  },
  {
    postalCode: '9321',
    city: 'Muschk'
  },
  {
    postalCode: '9321',
    city: 'Passering'
  },
  {
    postalCode: '9321',
    city: 'Poppenhof'
  },
  {
    postalCode: '9321',
    city: 'St. Klementen'
  },
  {
    postalCode: '9321',
    city: 'St. Martin am Krappfeld'
  },
  {
    postalCode: '9321',
    city: 'St. Willibald'
  },
  {
    postalCode: '9321',
    city: 'Schöttlhof'
  },
  {
    postalCode: '9321',
    city: 'Silberegg'
  },
  {
    postalCode: '9321',
    city: 'Unterbergen'
  },
  {
    postalCode: '9321',
    city: 'Unterpassering'
  },
  {
    postalCode: '9321',
    city: 'Unterstein'
  },
  {
    postalCode: '9321',
    city: 'Zeindorf'
  },
  {
    postalCode: '9321',
    city: 'Stammerdorf'
  },
  {
    postalCode: '9322',
    city: 'Eberdorf'
  },
  {
    postalCode: '9322',
    city: 'Gasteige'
  },
  {
    postalCode: '9322',
    city: 'Gaudritz'
  },
  {
    postalCode: '9322',
    city: 'Hirt'
  },
  {
    postalCode: '9322',
    city: 'Lorenzenberg'
  },
  {
    postalCode: '9322',
    city: 'Micheldorf'
  },
  {
    postalCode: '9322',
    city: 'Ruhsdorf'
  },
  {
    postalCode: '9323',
    city: 'Dürnstein in der Steiermark'
  },
  {
    postalCode: '9323',
    city: 'Wildbad Einöd'
  },
  {
    postalCode: '9330',
    city: 'Aich'
  },
  {
    postalCode: '9330',
    city: 'Althofen'
  },
  {
    postalCode: '9330',
    city: 'Eberdorf'
  },
  {
    postalCode: '9330',
    city: 'Krumfelden'
  },
  {
    postalCode: '9330',
    city: 'Muraniberg'
  },
  {
    postalCode: '9330',
    city: 'Rabenstein'
  },
  {
    postalCode: '9330',
    city: 'Töscheldorf'
  },
  {
    postalCode: '9330',
    city: 'Treibach'
  },
  {
    postalCode: '9330',
    city: 'Epritz'
  },
  {
    postalCode: '9330',
    city: 'Rain'
  },
  {
    postalCode: '9330',
    city: 'Dachberg'
  },
  {
    postalCode: '9330',
    city: 'Krasta'
  },
  {
    postalCode: '9330',
    city: 'Brugga'
  },
  {
    postalCode: '9330',
    city: 'Mölbling'
  },
  {
    postalCode: '9330',
    city: 'Rabing'
  },
  {
    postalCode: '9330',
    city: 'St. Kosmas'
  },
  {
    postalCode: '9330',
    city: 'St. Stefan am Krappfeld'
  },
  {
    postalCode: '9330',
    city: 'Stoberdorf'
  },
  {
    postalCode: '9330',
    city: 'Unterbergen'
  },
  {
    postalCode: '9330',
    city: 'Buldorf'
  },
  {
    postalCode: '9330',
    city: 'Hohenfeld'
  },
  {
    postalCode: '9330',
    city: 'Pöckstein-Zwischenwässern'
  },
  {
    postalCode: '9334',
    city: 'Übersberg'
  },
  {
    postalCode: '9334',
    city: 'Urtl'
  },
  {
    postalCode: '9334',
    city: 'Deinsberg'
  },
  {
    postalCode: '9334',
    city: 'Guttaring'
  },
  {
    postalCode: '9334',
    city: 'Guttaringberg'
  },
  {
    postalCode: '9334',
    city: 'Höffern'
  },
  {
    postalCode: '9334',
    city: 'Hollersberg'
  },
  {
    postalCode: '9334',
    city: 'Maria Hilf'
  },
  {
    postalCode: '9334',
    city: 'Oberstranach'
  },
  {
    postalCode: '9334',
    city: 'Rabachboden'
  },
  {
    postalCode: '9334',
    city: 'Ratteingraben'
  },
  {
    postalCode: '9334',
    city: 'St. Gertruden'
  },
  {
    postalCode: '9334',
    city: 'Schelmberg'
  },
  {
    postalCode: '9334',
    city: 'Sonnberg'
  },
  {
    postalCode: '9334',
    city: 'Urtlgraben'
  },
  {
    postalCode: '9334',
    city: 'Verlosnitz'
  },
  {
    postalCode: '9334',
    city: 'Waitschach'
  },
  {
    postalCode: '9334',
    city: 'Weindorf'
  },
  {
    postalCode: '9334',
    city: 'Silberegg'
  },
  {
    postalCode: '9335',
    city: 'Hinterberg'
  },
  {
    postalCode: '9335',
    city: 'Lölling Graben'
  },
  {
    postalCode: '9335',
    city: 'Lölling Schattseite'
  },
  {
    postalCode: '9335',
    city: 'Lölling Sonnseite'
  },
  {
    postalCode: '9335',
    city: 'Semlach'
  },
  {
    postalCode: '9335',
    city: 'Stranach'
  },
  {
    postalCode: '9341',
    city: 'Zedl'
  },
  {
    postalCode: '9341',
    city: 'Gratschitz'
  },
  {
    postalCode: '9341',
    city: 'Gunzenberg'
  },
  {
    postalCode: '9341',
    city: 'Stein'
  },
  {
    postalCode: '9341',
    city: 'Wattein'
  },
  {
    postalCode: '9341',
    city: 'Bachl'
  },
  {
    postalCode: '9341',
    city: 'Dielach'
  },
  {
    postalCode: '9341',
    city: 'Dobersberg'
  },
  {
    postalCode: '9341',
    city: 'Drahtzug'
  },
  {
    postalCode: '9341',
    city: 'Edling'
  },
  {
    postalCode: '9341',
    city: 'Gruschitz'
  },
  {
    postalCode: '9341',
    city: 'Gundersdorf'
  },
  {
    postalCode: '9341',
    city: 'Hackl'
  },
  {
    postalCode: '9341',
    city: 'Hausdorf'
  },
  {
    postalCode: '9341',
    city: 'Herd'
  },
  {
    postalCode: '9341',
    city: 'Höllein'
  },
  {
    postalCode: '9341',
    city: 'Kraßnitz'
  },
  {
    postalCode: '9341',
    city: 'Kreuth'
  },
  {
    postalCode: '9341',
    city: 'Kreuzen'
  },
  {
    postalCode: '9341',
    city: 'Langwiesen'
  },
  {
    postalCode: '9341',
    city: 'Lieding'
  },
  {
    postalCode: '9341',
    city: 'Machuli'
  },
  {
    postalCode: '9341',
    city: 'Mannsdorf'
  },
  {
    postalCode: '9341',
    city: 'Mellach'
  },
  {
    postalCode: '9341',
    city: 'Moschitz'
  },
  {
    postalCode: '9341',
    city: 'Olschnögg'
  },
  {
    postalCode: '9341',
    city: 'Pabenberg'
  },
  {
    postalCode: '9341',
    city: 'Ratschach'
  },
  {
    postalCode: '9341',
    city: 'St. Georgen'
  },
  {
    postalCode: '9341',
    city: 'St. Magdalen'
  },
  {
    postalCode: '9341',
    city: 'Schattseite'
  },
  {
    postalCode: '9341',
    city: 'Schmaritzen'
  },
  {
    postalCode: '9341',
    city: 'Straßburg-Stadt'
  },
  {
    postalCode: '9341',
    city: 'Unteraich'
  },
  {
    postalCode: '9341',
    city: 'Unterfarcha'
  },
  {
    postalCode: '9341',
    city: 'Unterrain'
  },
  {
    postalCode: '9341',
    city: 'Wildbach'
  },
  {
    postalCode: '9341',
    city: 'Wilpling'
  },
  {
    postalCode: '9341',
    city: 'Winklern'
  },
  {
    postalCode: '9342',
    city: 'Gassarest'
  },
  {
    postalCode: '9342',
    city: 'Gurk'
  },
  {
    postalCode: '9342',
    city: 'Kreuzberg'
  },
  {
    postalCode: '9342',
    city: 'Ranitz'
  },
  {
    postalCode: '9342',
    city: 'Reichenhaus'
  },
  {
    postalCode: '9342',
    city: 'Dörfl'
  },
  {
    postalCode: '9342',
    city: 'Finsterdorf'
  },
  {
    postalCode: '9342',
    city: 'Glanz'
  },
  {
    postalCode: '9342',
    city: 'Gruska'
  },
  {
    postalCode: '9342',
    city: 'Gwadnitz'
  },
  {
    postalCode: '9342',
    city: 'Krön'
  },
  {
    postalCode: '9342',
    city: 'Masternitzen'
  },
  {
    postalCode: '9342',
    city: 'Niederdorf'
  },
  {
    postalCode: '9342',
    city: 'Pisweg'
  },
  {
    postalCode: '9342',
    city: 'Straßa'
  },
  {
    postalCode: '9342',
    city: 'Sutsch'
  },
  {
    postalCode: '9342',
    city: 'Zabersdorf'
  },
  {
    postalCode: '9342',
    city: 'Zedl'
  },
  {
    postalCode: '9342',
    city: 'Zedroß'
  },
  {
    postalCode: '9342',
    city: 'Zeltschach'
  },
  {
    postalCode: '9342',
    city: 'Föbing'
  },
  {
    postalCode: '9342',
    city: 'Glabötsch'
  },
  {
    postalCode: '9342',
    city: 'Lees'
  },
  {
    postalCode: '9342',
    city: 'Mitterdorf'
  },
  {
    postalCode: '9342',
    city: 'Olschnitz'
  },
  {
    postalCode: '9342',
    city: 'Olschnitz-Lind'
  },
  {
    postalCode: '9342',
    city: 'Pölling'
  },
  {
    postalCode: '9342',
    city: 'St. Jakob'
  },
  {
    postalCode: '9342',
    city: 'St. Johann'
  },
  {
    postalCode: '9342',
    city: 'St. Peter'
  },
  {
    postalCode: '9342',
    city: 'Schneßnitz'
  },
  {
    postalCode: '9342',
    city: 'Straßburg-Stadt'
  },
  {
    postalCode: '9343',
    city: 'Ading'
  },
  {
    postalCode: '9343',
    city: 'Aich'
  },
  {
    postalCode: '9343',
    city: 'Bach (Zweinitz)'
  },
  {
    postalCode: '9343',
    city: 'Brunn (Zweinitz)'
  },
  {
    postalCode: '9343',
    city: 'Dielach'
  },
  {
    postalCode: '9343',
    city: 'Engelsdorf'
  },
  {
    postalCode: '9343',
    city: 'Grabenig'
  },
  {
    postalCode: '9343',
    city: 'Hundsdorf'
  },
  {
    postalCode: '9343',
    city: 'Kraßnitz'
  },
  {
    postalCode: '9343',
    city: 'Mödring'
  },
  {
    postalCode: '9343',
    city: 'St. Andrä'
  },
  {
    postalCode: '9343',
    city: 'Traming'
  },
  {
    postalCode: '9343',
    city: 'Wurz'
  },
  {
    postalCode: '9343',
    city: 'Zweinitz'
  },
  {
    postalCode: '9344',
    city: 'Hundsdorf'
  },
  {
    postalCode: '9344',
    city: 'Grua'
  },
  {
    postalCode: '9344',
    city: 'Psein'
  },
  {
    postalCode: '9344',
    city: 'Dalling'
  },
  {
    postalCode: '9344',
    city: 'Dolz'
  },
  {
    postalCode: '9344',
    city: 'Edling'
  },
  {
    postalCode: '9344',
    city: 'Hafendorf'
  },
  {
    postalCode: '9344',
    city: 'Hardernitzen'
  },
  {
    postalCode: '9344',
    city: 'Kaindorf'
  },
  {
    postalCode: '9344',
    city: 'Kötschendorf'
  },
  {
    postalCode: '9344',
    city: 'Lind'
  },
  {
    postalCode: '9344',
    city: 'Massanig'
  },
  {
    postalCode: '9344',
    city: 'Nassing'
  },
  {
    postalCode: '9344',
    city: 'Niederwurz'
  },
  {
    postalCode: '9344',
    city: 'Oberort'
  },
  {
    postalCode: '9344',
    city: 'Planitz'
  },
  {
    postalCode: '9344',
    city: 'Sadin'
  },
  {
    postalCode: '9344',
    city: 'Weitensfeld'
  },
  {
    postalCode: '9344',
    city: 'Wullroß'
  },
  {
    postalCode: '9344',
    city: 'Zammelsberg'
  },
  {
    postalCode: '9345',
    city: 'Hohenwurz'
  },
  {
    postalCode: '9345',
    city: 'Lassenberg'
  },
  {
    postalCode: '9345',
    city: 'Altenmarkt'
  },
  {
    postalCode: '9345',
    city: 'Kleinglödnitz'
  },
  {
    postalCode: '9345',
    city: 'Zauchwinkel'
  },
  {
    postalCode: '9345',
    city: 'Braunsberg'
  },
  {
    postalCode: '9345',
    city: 'Kaindorf'
  },
  {
    postalCode: '9345',
    city: 'Mödritsch'
  },
  {
    postalCode: '9345',
    city: 'Reinsberg'
  },
  {
    postalCode: '9345',
    city: 'Steindorf'
  },
  {
    postalCode: '9345',
    city: 'Tschriet'
  },
  {
    postalCode: '9346',
    city: 'Bach'
  },
  {
    postalCode: '9346',
    city: 'Brenitz'
  },
  {
    postalCode: '9346',
    city: 'Eden'
  },
  {
    postalCode: '9346',
    city: 'Flattnitz'
  },
  {
    postalCode: '9346',
    city: 'Glödnitz'
  },
  {
    postalCode: '9346',
    city: 'Grai'
  },
  {
    postalCode: '9346',
    city: 'Jauernig'
  },
  {
    postalCode: '9346',
    city: 'Laas'
  },
  {
    postalCode: '9346',
    city: 'Lassenberg'
  },
  {
    postalCode: '9346',
    city: 'Moos'
  },
  {
    postalCode: '9346',
    city: 'Rain'
  },
  {
    postalCode: '9346',
    city: 'Schattseite'
  },
  {
    postalCode: '9346',
    city: 'Torf'
  },
  {
    postalCode: '9346',
    city: 'Tschröschen'
  },
  {
    postalCode: '9346',
    city: 'Weißberg'
  },
  {
    postalCode: '9360',
    city: 'Dürnstein in der Steiermark'
  },
  {
    postalCode: '9360',
    city: 'Dörfl'
  },
  {
    postalCode: '9360',
    city: 'Engelsdorf'
  },
  {
    postalCode: '9360',
    city: 'Friesach'
  },
  {
    postalCode: '9360',
    city: 'Grafendorf'
  },
  {
    postalCode: '9360',
    city: 'Olsa'
  },
  {
    postalCode: '9360',
    city: 'Guldendorf'
  },
  {
    postalCode: '9360',
    city: 'Judendorf'
  },
  {
    postalCode: '9360',
    city: 'Dobritsch'
  },
  {
    postalCode: '9360',
    city: 'Gaisberg'
  },
  {
    postalCode: '9360',
    city: 'Gundersdorf'
  },
  {
    postalCode: '9360',
    city: 'Harold'
  },
  {
    postalCode: '9360',
    city: 'Hartmannsdorf'
  },
  {
    postalCode: '9360',
    city: 'Kräuping'
  },
  {
    postalCode: '9360',
    city: 'Oberdorf II'
  },
  {
    postalCode: '9360',
    city: 'Pabenberg'
  },
  {
    postalCode: '9360',
    city: 'Sattelbogen'
  },
  {
    postalCode: '9360',
    city: 'Schwall'
  },
  {
    postalCode: '9360',
    city: 'Silbermann'
  },
  {
    postalCode: '9360',
    city: 'Wagendorf'
  },
  {
    postalCode: '9360',
    city: 'Wiegen'
  },
  {
    postalCode: '9360',
    city: 'Zeltschach'
  },
  {
    postalCode: '9360',
    city: 'Zeltschachberg'
  },
  {
    postalCode: '9360',
    city: 'Guttaringberg'
  },
  {
    postalCode: '9360',
    city: 'Gulitzen'
  },
  {
    postalCode: '9360',
    city: 'Lorenzenberg'
  },
  {
    postalCode: '9360',
    city: 'Schödendorf'
  },
  {
    postalCode: '9360',
    city: 'Kulmitzen'
  },
  {
    postalCode: '9361',
    city: 'Gunzenberg'
  },
  {
    postalCode: '9361',
    city: 'Gwerz'
  },
  {
    postalCode: '9361',
    city: 'Hundsdorf'
  },
  {
    postalCode: '9361',
    city: 'Ingolsthal'
  },
  {
    postalCode: '9361',
    city: 'Leimersberg'
  },
  {
    postalCode: '9361',
    city: 'Mayerhofen'
  },
  {
    postalCode: '9361',
    city: 'Moserwinkl'
  },
  {
    postalCode: '9361',
    city: 'Oberdorf I'
  },
  {
    postalCode: '9361',
    city: 'Reisenberg'
  },
  {
    postalCode: '9361',
    city: 'Roßbach'
  },
  {
    postalCode: '9361',
    city: 'St. Johann'
  },
  {
    postalCode: '9361',
    city: 'St. Salvator'
  },
  {
    postalCode: '9361',
    city: 'St. Stefan'
  },
  {
    postalCode: '9361',
    city: 'Schratzbach'
  },
  {
    postalCode: '9361',
    city: 'Staudachhof'
  },
  {
    postalCode: '9361',
    city: 'Stegsdorf'
  },
  {
    postalCode: '9361',
    city: 'Timrian'
  },
  {
    postalCode: '9361',
    city: 'Wels'
  },
  {
    postalCode: '9361',
    city: 'Wiesen'
  },
  {
    postalCode: '9361',
    city: 'Zienitzen'
  },
  {
    postalCode: '9361',
    city: 'Zmuck'
  },
  {
    postalCode: '9362',
    city: 'Feistritz'
  },
  {
    postalCode: '9362',
    city: 'Grades'
  },
  {
    postalCode: '9362',
    city: 'Schnatten'
  },
  {
    postalCode: '9362',
    city: 'Zwatzhof'
  },
  {
    postalCode: '9362',
    city: 'Marienheim'
  },
  {
    postalCode: '9363',
    city: 'Maria Höfl'
  },
  {
    postalCode: '9363',
    city: 'Mödring'
  },
  {
    postalCode: '9363',
    city: 'Oberhof Schattseite'
  },
  {
    postalCode: '9363',
    city: 'Vellach'
  },
  {
    postalCode: '9363',
    city: 'Auen'
  },
  {
    postalCode: '9363',
    city: 'Felfernigthal'
  },
  {
    postalCode: '9363',
    city: 'Klachl'
  },
  {
    postalCode: '9363',
    city: 'Metnitz'
  },
  {
    postalCode: '9363',
    city: 'Oberalpe'
  },
  {
    postalCode: '9363',
    city: 'Oberhof Sonnseite'
  },
  {
    postalCode: '9363',
    city: 'Preining'
  },
  {
    postalCode: '9363',
    city: 'Schwarzenbach'
  },
  {
    postalCode: '9363',
    city: 'Teichl'
  },
  {
    postalCode: '9363',
    city: 'Unteralpe'
  },
  {
    postalCode: '9363',
    city: 'Wöbring'
  },
  {
    postalCode: '9371',
    city: 'Brückl'
  },
  {
    postalCode: '9371',
    city: 'Eppersdorf'
  },
  {
    postalCode: '9371',
    city: 'Hart'
  },
  {
    postalCode: '9371',
    city: 'Hausdorf'
  },
  {
    postalCode: '9371',
    city: 'Johannserberg'
  },
  {
    postalCode: '9371',
    city: 'Krainberg'
  },
  {
    postalCode: '9371',
    city: 'Labegg'
  },
  {
    postalCode: '9371',
    city: 'Michaelerberg'
  },
  {
    postalCode: '9371',
    city: 'Oberkrähwald'
  },
  {
    postalCode: '9371',
    city: 'Pirkach'
  },
  {
    postalCode: '9371',
    city: 'Salchendorf'
  },
  {
    postalCode: '9371',
    city: 'St. Gregorn'
  },
  {
    postalCode: '9371',
    city: 'St. Ulrich am Johannserberg'
  },
  {
    postalCode: '9371',
    city: 'Schmieddorf'
  },
  {
    postalCode: '9371',
    city: 'Selesen'
  },
  {
    postalCode: '9371',
    city: 'Tschutta'
  },
  {
    postalCode: '9371',
    city: 'St. Walburgen'
  },
  {
    postalCode: '9371',
    city: 'Klein St. Veit'
  },
  {
    postalCode: '9371',
    city: 'Reisdorf'
  },
  {
    postalCode: '9372',
    city: 'Baumgarten'
  },
  {
    postalCode: '9372',
    city: 'Eberstein'
  },
  {
    postalCode: '9372',
    city: 'Gutschen'
  },
  {
    postalCode: '9372',
    city: 'Hochfeistritz'
  },
  {
    postalCode: '9372',
    city: 'Kaltenberg'
  },
  {
    postalCode: '9372',
    city: 'Kulm'
  },
  {
    postalCode: '9372',
    city: 'Mirnig'
  },
  {
    postalCode: '9372',
    city: 'St. Oswald'
  },
  {
    postalCode: '9372',
    city: 'St. Walburgen'
  },
  {
    postalCode: '9372',
    city: 'Klein St. Paul'
  },
  {
    postalCode: '9372',
    city: 'Prailing'
  },
  {
    postalCode: '9372',
    city: 'Sittenberg'
  },
  {
    postalCode: '9373',
    city: 'Rattenberg'
  },
  {
    postalCode: '9373',
    city: 'St. Florian'
  },
  {
    postalCode: '9373',
    city: 'Filfing'
  },
  {
    postalCode: '9373',
    city: 'Klein St. Paul'
  },
  {
    postalCode: '9373',
    city: 'Prailing'
  },
  {
    postalCode: '9373',
    city: 'Sittenberg'
  },
  {
    postalCode: '9373',
    city: 'Wietersdorf'
  },
  {
    postalCode: '9374',
    city: 'Schelmberg'
  },
  {
    postalCode: '9374',
    city: 'Grünburg'
  },
  {
    postalCode: '9374',
    city: 'Buch'
  },
  {
    postalCode: '9374',
    city: 'Drattrum'
  },
  {
    postalCode: '9374',
    city: 'Dullberg'
  },
  {
    postalCode: '9374',
    city: 'Katschniggraben'
  },
  {
    postalCode: '9374',
    city: 'Kirchberg'
  },
  {
    postalCode: '9374',
    city: 'Kitschdorf'
  },
  {
    postalCode: '9374',
    city: 'Mösel'
  },
  {
    postalCode: '9374',
    city: 'Oberwietingberg'
  },
  {
    postalCode: '9374',
    city: 'Raffelsdorf'
  },
  {
    postalCode: '9374',
    city: 'Unterwietingberg'
  },
  {
    postalCode: '9374',
    city: 'Wieting'
  },
  {
    postalCode: '9374',
    city: 'Müllergraben'
  },
  {
    postalCode: '9375',
    city: 'Dobritsch'
  },
  {
    postalCode: '9375',
    city: 'Baierberg'
  },
  {
    postalCode: '9375',
    city: 'Gobertal'
  },
  {
    postalCode: '9375',
    city: 'Höffern'
  },
  {
    postalCode: '9375',
    city: 'Ratteingraben'
  },
  {
    postalCode: '9375',
    city: 'Schalkendorf'
  },
  {
    postalCode: '9375',
    city: 'Waitschach'
  },
  {
    postalCode: '9375',
    city: 'Unterwald'
  },
  {
    postalCode: '9375',
    city: 'Heft'
  },
  {
    postalCode: '9375',
    city: 'Hüttenberg'
  },
  {
    postalCode: '9375',
    city: 'Hüttenberg Land'
  },
  {
    postalCode: '9375',
    city: 'St. Johann am Pressen'
  },
  {
    postalCode: '9375',
    city: 'Zosen'
  },
  {
    postalCode: '9375',
    city: 'St. Martin am Silberberg'
  },
  {
    postalCode: '9376',
    city: 'Andreaskreuz'
  },
  {
    postalCode: '9376',
    city: 'Gossen'
  },
  {
    postalCode: '9376',
    city: 'Jouschitzen'
  },
  {
    postalCode: '9376',
    city: 'Knappenberg'
  },
  {
    postalCode: '9376',
    city: 'Lichtegg'
  },
  {
    postalCode: '9376',
    city: 'Obersemlach'
  },
  {
    postalCode: '9376',
    city: 'St. Johann am Pressen'
  },
  {
    postalCode: '9400',
    city: 'Hintergumitsch'
  },
  {
    postalCode: '9400',
    city: 'Zellach'
  },
  {
    postalCode: '9400',
    city: 'Wölling'
  },
  {
    postalCode: '9400',
    city: 'St. Thomas'
  },
  {
    postalCode: '9400',
    city: 'Pfaffendorf'
  },
  {
    postalCode: '9400',
    city: 'Raggl'
  },
  {
    postalCode: '9400',
    city: 'Hattendorf'
  },
  {
    postalCode: '9400',
    city: 'St. Johann'
  },
  {
    postalCode: '9400',
    city: 'Vordergumitsch'
  },
  {
    postalCode: '9400',
    city: 'Auen'
  },
  {
    postalCode: '9400',
    city: 'Gries'
  },
  {
    postalCode: '9400',
    city: 'Priel'
  },
  {
    postalCode: '9400',
    city: 'Reding'
  },
  {
    postalCode: '9400',
    city: 'Ritzing'
  },
  {
    postalCode: '9400',
    city: 'St. Jakob'
  },
  {
    postalCode: '9400',
    city: 'Schleifen'
  },
  {
    postalCode: '9400',
    city: 'Schoßbach'
  },
  {
    postalCode: '9400',
    city: 'Schwemmtratten'
  },
  {
    postalCode: '9400',
    city: 'Wolfsberg'
  },
  {
    postalCode: '9400',
    city: 'Gräbern'
  },
  {
    postalCode: '9400',
    city: 'Prebl'
  },
  {
    postalCode: '9400',
    city: 'Kleinedling'
  },
  {
    postalCode: '9411',
    city: 'Aichberg'
  },
  {
    postalCode: '9411',
    city: 'Forst'
  },
  {
    postalCode: '9411',
    city: 'Altendorf'
  },
  {
    postalCode: '9411',
    city: 'Arling'
  },
  {
    postalCode: '9411',
    city: 'Eselsdorf'
  },
  {
    postalCode: '9411',
    city: 'Hattendorf'
  },
  {
    postalCode: '9411',
    city: 'Kötsch'
  },
  {
    postalCode: '9411',
    city: 'Lading'
  },
  {
    postalCode: '9411',
    city: 'Lausing'
  },
  {
    postalCode: '9411',
    city: 'Pollheim'
  },
  {
    postalCode: '9411',
    city: 'St. Michael'
  },
  {
    postalCode: '9411',
    city: 'Witra'
  },
  {
    postalCode: '9412',
    city: 'Forst'
  },
  {
    postalCode: '9412',
    city: 'Leiwald'
  },
  {
    postalCode: '9412',
    city: 'Oberleidenberg'
  },
  {
    postalCode: '9412',
    city: 'Pfaffendorf'
  },
  {
    postalCode: '9412',
    city: 'Preims'
  },
  {
    postalCode: '9412',
    city: 'Raggl'
  },
  {
    postalCode: '9412',
    city: 'St. Margarethen im Lavanttal'
  },
  {
    postalCode: '9412',
    city: 'Unterleidenberg'
  },
  {
    postalCode: '9412',
    city: 'Arling'
  },
  {
    postalCode: '9413',
    city: 'Frantschach'
  },
  {
    postalCode: '9413',
    city: 'Hintergumitsch'
  },
  {
    postalCode: '9413',
    city: 'Hinterwölch'
  },
  {
    postalCode: '9413',
    city: 'Kaltstuben'
  },
  {
    postalCode: '9413',
    city: 'Kamp'
  },
  {
    postalCode: '9413',
    city: 'Kamperkogel'
  },
  {
    postalCode: '9413',
    city: 'Limberg'
  },
  {
    postalCode: '9413',
    city: 'Obergösel'
  },
  {
    postalCode: '9413',
    city: 'Praken'
  },
  {
    postalCode: '9413',
    city: 'St. Gertraud'
  },
  {
    postalCode: '9413',
    city: 'Trum-und Prössinggraben'
  },
  {
    postalCode: '9413',
    city: 'Untergösel'
  },
  {
    postalCode: '9413',
    city: 'Vorderlimberg'
  },
  {
    postalCode: '9413',
    city: 'Vorderwölch'
  },
  {
    postalCode: '9413',
    city: 'Zellach'
  },
  {
    postalCode: '9413',
    city: 'Vordertheißenegg'
  },
  {
    postalCode: '9413',
    city: 'Gräbern'
  },
  {
    postalCode: '9421',
    city: 'Eitweg'
  },
  {
    postalCode: '9421',
    city: 'Gemmersdorf'
  },
  {
    postalCode: '9421',
    city: 'Goding'
  },
  {
    postalCode: '9421',
    city: 'Hainsdorf'
  },
  {
    postalCode: '9421',
    city: 'Mosern'
  },
  {
    postalCode: '9421',
    city: 'Obereberndorf'
  },
  {
    postalCode: '9421',
    city: 'St. Ulrich'
  },
  {
    postalCode: '9421',
    city: 'Hartelsberg'
  },
  {
    postalCode: '9421',
    city: 'Hartneidstein'
  },
  {
    postalCode: '9422',
    city: 'Aich'
  },
  {
    postalCode: '9422',
    city: 'Dachberg'
  },
  {
    postalCode: '9422',
    city: 'Eisdorf'
  },
  {
    postalCode: '9422',
    city: 'Farrach'
  },
  {
    postalCode: '9422',
    city: 'Lindhof'
  },
  {
    postalCode: '9422',
    city: 'Maria Rojach'
  },
  {
    postalCode: '9422',
    city: 'Mitterpichling'
  },
  {
    postalCode: '9422',
    city: 'Mühldorf'
  },
  {
    postalCode: '9422',
    city: 'Untereberndorf'
  },
  {
    postalCode: '9422',
    city: 'Oberpichling'
  },
  {
    postalCode: '9422',
    city: 'Paierdorf'
  },
  {
    postalCode: '9422',
    city: 'Ragglach'
  },
  {
    postalCode: '9422',
    city: 'Ragglbach'
  },
  {
    postalCode: '9423',
    city: 'Weißenberg'
  },
  {
    postalCode: '9423',
    city: 'Andersdorf'
  },
  {
    postalCode: '9423',
    city: 'Fransdorf'
  },
  {
    postalCode: '9423',
    city: 'Götzendorf'
  },
  {
    postalCode: '9423',
    city: 'Gundisch'
  },
  {
    postalCode: '9423',
    city: 'Herzogberg'
  },
  {
    postalCode: '9423',
    city: 'Krakaberg'
  },
  {
    postalCode: '9423',
    city: 'Matschenbloch'
  },
  {
    postalCode: '9423',
    city: 'Niederhof'
  },
  {
    postalCode: '9423',
    city: 'Oberrainz'
  },
  {
    postalCode: '9423',
    city: 'Pfaffendorf'
  },
  {
    postalCode: '9423',
    city: 'Pontnig'
  },
  {
    postalCode: '9423',
    city: 'St. Georgen im Lavanttal'
  },
  {
    postalCode: '9423',
    city: 'Steinberg-Oberhaus'
  },
  {
    postalCode: '9423',
    city: 'Steinberg-Unterhaus'
  },
  {
    postalCode: '9423',
    city: 'Unterpichling'
  },
  {
    postalCode: '9423',
    city: 'Steinberg-Hart'
  },
  {
    postalCode: '9431',
    city: 'Paildorf'
  },
  {
    postalCode: '9431',
    city: 'Goding'
  },
  {
    postalCode: '9431',
    city: 'Kleinrojach'
  },
  {
    postalCode: '9431',
    city: 'Oberaigen'
  },
  {
    postalCode: '9431',
    city: 'Pichling'
  },
  {
    postalCode: '9431',
    city: 'Reisberg'
  },
  {
    postalCode: '9431',
    city: 'Schaßbach'
  },
  {
    postalCode: '9431',
    city: 'Schobersberg'
  },
  {
    postalCode: '9431',
    city: 'Unteraigen'
  },
  {
    postalCode: '9431',
    city: 'Völking'
  },
  {
    postalCode: '9431',
    city: 'Winkling-Nord'
  },
  {
    postalCode: '9431',
    city: 'Kleinedling'
  },
  {
    postalCode: '9431',
    city: 'St. Stefan'
  },
  {
    postalCode: '9431',
    city: 'Aichberg'
  },
  {
    postalCode: '9431',
    city: 'Reinfelsdorf'
  },
  {
    postalCode: '9431',
    city: 'St. Marein'
  },
  {
    postalCode: '9431',
    city: 'Siegelsdorf'
  },
  {
    postalCode: '9431',
    city: 'Thürn'
  },
  {
    postalCode: '9431',
    city: 'Glein'
  },
  {
    postalCode: '9431',
    city: 'Großedling'
  },
  {
    postalCode: '9431',
    city: 'Hartelsberg'
  },
  {
    postalCode: '9431',
    city: 'Kleinwinklern'
  },
  {
    postalCode: '9431',
    city: 'Maildorf'
  },
  {
    postalCode: '9431',
    city: 'Michaelsdorf'
  },
  {
    postalCode: '9431',
    city: 'Reideben'
  },
  {
    postalCode: '9431',
    city: 'Rieding'
  },
  {
    postalCode: '9431',
    city: 'Riegelsdorf'
  },
  {
    postalCode: '9431',
    city: 'St. Johann'
  },
  {
    postalCode: '9431',
    city: 'Vordergumitsch'
  },
  {
    postalCode: '9431',
    city: 'Weißenbach Gumitsch'
  },
  {
    postalCode: '9431',
    city: 'Weißenbach Rieding'
  },
  {
    postalCode: '9431',
    city: 'Wolkersdorf'
  },
  {
    postalCode: '9431',
    city: 'Priel'
  },
  {
    postalCode: '9433',
    city: 'Lamm'
  },
  {
    postalCode: '9433',
    city: 'Schönweg-Pustritz'
  },
  {
    postalCode: '9433',
    city: 'Streitberg'
  },
  {
    postalCode: '9433',
    city: 'Höfern'
  },
  {
    postalCode: '9433',
    city: 'Jakling'
  },
  {
    postalCode: '9433',
    city: 'Mosern'
  },
  {
    postalCode: '9433',
    city: 'Siebending'
  },
  {
    postalCode: '9433',
    city: 'Fischering'
  },
  {
    postalCode: '9433',
    city: 'Kollegg'
  },
  {
    postalCode: '9433',
    city: 'Oberaigen'
  },
  {
    postalCode: '9433',
    city: 'Pirk'
  },
  {
    postalCode: '9433',
    city: 'Unteragsdorf'
  },
  {
    postalCode: '9433',
    city: 'Wimpassing'
  },
  {
    postalCode: '9433',
    city: 'Winkling-Nord'
  },
  {
    postalCode: '9433',
    city: 'Wölzing-Fischering'
  },
  {
    postalCode: '9433',
    city: 'Langegg'
  },
  {
    postalCode: '9433',
    city: 'Messensach'
  },
  {
    postalCode: '9433',
    city: 'Mettersdorf'
  },
  {
    postalCode: '9433',
    city: 'Blaiken'
  },
  {
    postalCode: '9433',
    city: 'Burgstall-Pölling'
  },
  {
    postalCode: '9433',
    city: 'Burgstall-St. Andrä'
  },
  {
    postalCode: '9433',
    city: 'Langgen'
  },
  {
    postalCode: '9433',
    city: 'Oberagsdorf'
  },
  {
    postalCode: '9433',
    city: 'St. Andrä'
  },
  {
    postalCode: '9433',
    city: 'Pölling'
  },
  {
    postalCode: '9433',
    city: 'Framrach'
  },
  {
    postalCode: '9433',
    city: 'St. Jakob'
  },
  {
    postalCode: '9433',
    city: 'Unterrain'
  },
  {
    postalCode: '9433',
    city: 'Kienberg'
  },
  {
    postalCode: '9433',
    city: 'Schönweg-St. Andrä'
  },
  {
    postalCode: '9433',
    city: 'Pustritz'
  },
  {
    postalCode: '9433',
    city: 'Gönitz'
  },
  {
    postalCode: '9433',
    city: 'Zellbach'
  },
  {
    postalCode: '9433',
    city: 'Winkling-Süd'
  },
  {
    postalCode: '9433',
    city: 'Magersdorf'
  },
  {
    postalCode: '9433',
    city: 'Winkling'
  },
  {
    postalCode: '9433',
    city: 'Wois'
  },
  {
    postalCode: '9433',
    city: 'Kragelsdorf'
  },
  {
    postalCode: '9433',
    city: 'Schilting'
  },
  {
    postalCode: '9433',
    city: 'Wölzing-St. Andrä'
  },
  {
    postalCode: '9441',
    city: 'Schönberg'
  },
  {
    postalCode: '9441',
    city: 'Twimberg'
  },
  {
    postalCode: '9441',
    city: 'Gräbern'
  },
  {
    postalCode: '9441',
    city: 'Unterpreitenegg'
  },
  {
    postalCode: '9441',
    city: 'Hintertheißenegg'
  },
  {
    postalCode: '9441',
    city: 'Vordertheißenegg'
  },
  {
    postalCode: '9441',
    city: 'Waldenstein'
  },
  {
    postalCode: '9451',
    city: 'Pack'
  },
  {
    postalCode: '9451',
    city: 'Kleinpreitenegg'
  },
  {
    postalCode: '9451',
    city: 'Oberauerling'
  },
  {
    postalCode: '9451',
    city: 'Oberpreitenegg'
  },
  {
    postalCode: '9451',
    city: 'Preitenegg'
  },
  {
    postalCode: '9451',
    city: 'Unterauerling'
  },
  {
    postalCode: '9451',
    city: 'Unterpreitenegg'
  },
  {
    postalCode: '9461',
    city: 'Gräbern'
  },
  {
    postalCode: '9461',
    city: 'Prebl'
  },
  {
    postalCode: '9461',
    city: 'Wölling'
  },
  {
    postalCode: '9462',
    city: 'Bad St. Leonhard im Lavanttal'
  },
  {
    postalCode: '9462',
    city: 'Erzberg'
  },
  {
    postalCode: '9462',
    city: 'Görlitzen'
  },
  {
    postalCode: '9462',
    city: 'Kalchberg'
  },
  {
    postalCode: '9462',
    city: 'Lichtengraben'
  },
  {
    postalCode: '9462',
    city: 'Mauterndorf'
  },
  {
    postalCode: '9462',
    city: 'Raning'
  },
  {
    postalCode: '9462',
    city: 'Steinbruch'
  },
  {
    postalCode: '9462',
    city: 'Wartkogel'
  },
  {
    postalCode: '9462',
    city: 'Wiesenau'
  },
  {
    postalCode: '9462',
    city: 'Wisperndorf'
  },
  {
    postalCode: '9462',
    city: 'Kliening'
  },
  {
    postalCode: '9462',
    city: 'Schiefling'
  },
  {
    postalCode: '9462',
    city: 'Schönberg'
  },
  {
    postalCode: '9462',
    city: 'Twimberg'
  },
  {
    postalCode: '9462',
    city: 'Prebl'
  },
  {
    postalCode: '9462',
    city: 'St. Peter im Lavanttal'
  },
  {
    postalCode: '9462',
    city: 'Weitenbach'
  },
  {
    postalCode: '9462',
    city: 'Klippitztörl'
  },
  {
    postalCode: '9463',
    city: 'Großprethal'
  },
  {
    postalCode: '9463',
    city: 'Wartkogel'
  },
  {
    postalCode: '9463',
    city: 'Reichenfels'
  },
  {
    postalCode: '9463',
    city: 'St. Peter im Lavanttal'
  },
  {
    postalCode: '9463',
    city: 'Sommerau'
  },
  {
    postalCode: '9463',
    city: 'Weitenbach'
  },
  {
    postalCode: '9463',
    city: 'Winterleiten'
  },
  {
    postalCode: '9463',
    city: 'Zanitzen'
  },
  {
    postalCode: '9470',
    city: 'Aich'
  },
  {
    postalCode: '9470',
    city: 'Allersdorf'
  },
  {
    postalCode: '9470',
    city: 'Raggane'
  },
  {
    postalCode: '9470',
    city: 'Unterrainz'
  },
  {
    postalCode: '9470',
    city: 'St. Margarethen'
  },
  {
    postalCode: '9470',
    city: 'Deutsch-Grutschen'
  },
  {
    postalCode: '9470',
    city: 'Granitztal-St. Paul'
  },
  {
    postalCode: '9470',
    city: 'Granitztal-Weißenegg'
  },
  {
    postalCode: '9470',
    city: 'Kollnitzgreuth'
  },
  {
    postalCode: '9470',
    city: 'St. Martin'
  },
  {
    postalCode: '9470',
    city: 'Schildberg'
  },
  {
    postalCode: '9470',
    city: 'Windisch-Grutschen'
  },
  {
    postalCode: '9470',
    city: 'Hundsdorf'
  },
  {
    postalCode: '9470',
    city: 'Johannesberg'
  },
  {
    postalCode: '9470',
    city: 'Kampach'
  },
  {
    postalCode: '9470',
    city: 'Legerbuch'
  },
  {
    postalCode: '9470',
    city: 'Loschental'
  },
  {
    postalCode: '9470',
    city: 'St. Paul im Lavanttal'
  },
  {
    postalCode: '9470',
    city: 'Stadling'
  },
  {
    postalCode: '9470',
    city: 'Unterhaus'
  },
  {
    postalCode: '9470',
    city: 'Winkling'
  },
  {
    postalCode: '9470',
    city: 'Gönitz'
  },
  {
    postalCode: '9470',
    city: 'Zellbach'
  },
  {
    postalCode: '9472',
    city: 'Ettendorf'
  },
  {
    postalCode: '9472',
    city: 'Krottendorf'
  },
  {
    postalCode: '9472',
    city: 'Lamprechtsberg'
  },
  {
    postalCode: '9472',
    city: 'Schwarzenbach'
  },
  {
    postalCode: '9472',
    city: 'Weißenberg'
  },
  {
    postalCode: '9472',
    city: 'Achalm'
  },
  {
    postalCode: '9472',
    city: 'Magdalensberg'
  },
  {
    postalCode: '9472',
    city: 'Unterholz'
  },
  {
    postalCode: '9472',
    city: 'Weinberg'
  },
  {
    postalCode: '9473',
    city: 'St. Vinzenz'
  },
  {
    postalCode: '9473',
    city: 'Achalm'
  },
  {
    postalCode: '9473',
    city: 'Hart'
  },
  {
    postalCode: '9473',
    city: 'Lavamünd'
  },
  {
    postalCode: '9473',
    city: 'Lorenzenberg'
  },
  {
    postalCode: '9473',
    city: 'Magdalensberg'
  },
  {
    postalCode: '9473',
    city: 'Pfarrdorf'
  },
  {
    postalCode: '9473',
    city: 'Plestätten'
  },
  {
    postalCode: '9473',
    city: 'Rabenstein'
  },
  {
    postalCode: '9473',
    city: 'Rabensteingreuth'
  },
  {
    postalCode: '9473',
    city: 'Unterbergen'
  },
  {
    postalCode: '9473',
    city: 'Witternig'
  },
  {
    postalCode: '9473',
    city: 'Wunderstätten'
  },
  {
    postalCode: '9473',
    city: 'Zeil'
  },
  {
    postalCode: '9473',
    city: 'Legerbuch'
  },
  {
    postalCode: '9500',
    city: 'Villach-Innere Stadt'
  },
  {
    postalCode: '9500',
    city: 'Goritschach'
  },
  {
    postalCode: '9500',
    city: 'Heiligengeist'
  },
  {
    postalCode: '9500',
    city: 'Mittewald ob Villach'
  },
  {
    postalCode: '9500',
    city: 'Neufellach'
  },
  {
    postalCode: '9500',
    city: 'Obere Fellach'
  },
  {
    postalCode: '9500',
    city: 'Pogöriach'
  },
  {
    postalCode: '9500',
    city: 'St. Georgen'
  },
  {
    postalCode: '9500',
    city: 'Untere Fellach'
  },
  {
    postalCode: '9500',
    city: 'Drautschen'
  },
  {
    postalCode: '9500',
    city: 'Gritschach'
  },
  {
    postalCode: '9500',
    city: 'Großvassach'
  },
  {
    postalCode: '9500',
    city: 'Kleinvassach'
  },
  {
    postalCode: '9500',
    city: 'Kumitz'
  },
  {
    postalCode: '9500',
    city: 'Landskron'
  },
  {
    postalCode: '9500',
    city: 'Neulandskron'
  },
  {
    postalCode: '9500',
    city: 'Oberwollanig'
  },
  {
    postalCode: '9500',
    city: 'Rennstein'
  },
  {
    postalCode: '9500',
    city: 'St. Leonhard'
  },
  {
    postalCode: '9500',
    city: 'St. Michael'
  },
  {
    postalCode: '9500',
    city: 'Unterwollanig'
  },
  {
    postalCode: '9500',
    city: 'Zauchen'
  },
  {
    postalCode: '9500',
    city: 'Dobrova'
  },
  {
    postalCode: '9500',
    city: 'Duel'
  },
  {
    postalCode: '9500',
    city: 'Kleinsattel'
  },
  {
    postalCode: '9500',
    city: 'Maria Gail'
  },
  {
    postalCode: '9500',
    city: 'Prossowitsch'
  },
  {
    postalCode: '9500',
    city: 'Tschinowitsch'
  },
  {
    postalCode: '9500',
    city: 'Turdanitsch'
  },
  {
    postalCode: '9500',
    city: 'Weißenbach'
  },
  {
    postalCode: '9500',
    city: 'Villach-Auen'
  },
  {
    postalCode: '9500',
    city: 'Villach-Seebach-Wasenboden'
  },
  {
    postalCode: '9500',
    city: 'Villach-Lind'
  },
  {
    postalCode: '9500',
    city: 'Villach-St. Agathen und Perau'
  },
  {
    postalCode: '9500',
    city: 'Villach-St. Martin'
  },
  {
    postalCode: '9500',
    city: 'Villach-Völkendorf'
  },
  {
    postalCode: '9500',
    city: 'Villach-Warmbad-Judendorf'
  },
  {
    postalCode: '9504',
    city: 'Villach-Auen'
  },
  {
    postalCode: '9504',
    city: 'Villach-Völkendorf'
  },
  {
    postalCode: '9504',
    city: 'Villach-Warmbad-Judendorf'
  },
  {
    postalCode: '9520',
    city: 'Annenheim'
  },
  {
    postalCode: '9520',
    city: 'Ossiachberg'
  },
  {
    postalCode: '9520',
    city: 'Sattendorf'
  },
  {
    postalCode: '9520',
    city: 'Stöcklweingarten'
  },
  {
    postalCode: '9521',
    city: 'Winklern'
  },
  {
    postalCode: '9521',
    city: 'Eichholz'
  },
  {
    postalCode: '9521',
    city: 'Görtschach'
  },
  {
    postalCode: '9521',
    city: 'Kanzelhöhe'
  },
  {
    postalCode: '9521',
    city: 'Köttwein'
  },
  {
    postalCode: '9521',
    city: 'Niederdorf'
  },
  {
    postalCode: '9521',
    city: 'Oberdorf'
  },
  {
    postalCode: '9521',
    city: 'Pölling'
  },
  {
    postalCode: '9521',
    city: 'Retzen'
  },
  {
    postalCode: '9521',
    city: 'Schloss Treffen'
  },
  {
    postalCode: '9521',
    city: 'Seespitz'
  },
  {
    postalCode: '9521',
    city: 'Töbring'
  },
  {
    postalCode: '9521',
    city: 'Treffen'
  },
  {
    postalCode: '9521',
    city: 'Tschlein'
  },
  {
    postalCode: '9523',
    city: 'Gratschach'
  },
  {
    postalCode: '9523',
    city: 'Heiligen Gestade'
  },
  {
    postalCode: '9523',
    city: 'Kumitz'
  },
  {
    postalCode: '9523',
    city: 'Landskron'
  },
  {
    postalCode: '9523',
    city: 'Neulandskron'
  },
  {
    postalCode: '9523',
    city: 'St. Andrä'
  },
  {
    postalCode: '9523',
    city: 'St. Ruprecht'
  },
  {
    postalCode: '9523',
    city: 'Urlaken'
  },
  {
    postalCode: '9524',
    city: 'Landskron'
  },
  {
    postalCode: '9524',
    city: 'St. Magdalen'
  },
  {
    postalCode: '9524',
    city: 'St. Ulrich'
  },
  {
    postalCode: '9524',
    city: 'Villach-Seebach-Wasenboden'
  },
  {
    postalCode: '9530',
    city: 'Bleiberg-Nötsch'
  },
  {
    postalCode: '9530',
    city: 'Bad Bleiberg'
  },
  {
    postalCode: '9530',
    city: 'Hüttendorf'
  },
  {
    postalCode: '9530',
    city: 'Kadutschen'
  },
  {
    postalCode: '9531',
    city: 'Bleiberg-Kreuth'
  },
  {
    postalCode: '9531',
    city: 'Bleiberg-Nötsch'
  },
  {
    postalCode: '9531',
    city: 'Hermsberg'
  },
  {
    postalCode: '9531',
    city: 'Labientschach'
  },
  {
    postalCode: '9535',
    city: 'Aich'
  },
  {
    postalCode: '9535',
    city: 'Albersdorf'
  },
  {
    postalCode: '9535',
    city: 'Auen'
  },
  {
    postalCode: '9535',
    city: 'Farrendorf'
  },
  {
    postalCode: '9535',
    city: 'Goritschach'
  },
  {
    postalCode: '9535',
    city: 'Penken'
  },
  {
    postalCode: '9535',
    city: 'Roach'
  },
  {
    postalCode: '9535',
    city: 'Roda'
  },
  {
    postalCode: '9535',
    city: 'Schiefling'
  },
  {
    postalCode: '9535',
    city: 'St. Kathrein'
  },
  {
    postalCode: '9535',
    city: 'Techelweg'
  },
  {
    postalCode: '9535',
    city: 'Augsdorf'
  },
  {
    postalCode: '9535',
    city: 'Zauchen'
  },
  {
    postalCode: '9536',
    city: 'Ottosch'
  },
  {
    postalCode: '9536',
    city: 'Roach'
  },
  {
    postalCode: '9536',
    city: 'Raunach'
  },
  {
    postalCode: '9536',
    city: 'Dieschitz'
  },
  {
    postalCode: '9536',
    city: 'Latschach'
  },
  {
    postalCode: '9536',
    city: 'Pulpitsch'
  },
  {
    postalCode: '9536',
    city: 'St. Egyden'
  },
  {
    postalCode: '9536',
    city: 'Treffen'
  },
  {
    postalCode: '9541',
    city: 'Äußere Einöde'
  },
  {
    postalCode: '9541',
    city: 'Buchholz'
  },
  {
    postalCode: '9541',
    city: 'Innere Einöde'
  },
  {
    postalCode: '9541',
    city: 'Kras'
  },
  {
    postalCode: '9541',
    city: 'Lötschenberg'
  },
  {
    postalCode: '9541',
    city: 'Winklern'
  },
  {
    postalCode: '9542',
    city: 'Möderboden'
  },
  {
    postalCode: '9542',
    city: 'Afritz'
  },
  {
    postalCode: '9542',
    city: 'Berg ob Afritz'
  },
  {
    postalCode: '9542',
    city: 'Gassen'
  },
  {
    postalCode: '9542',
    city: 'Kraa'
  },
  {
    postalCode: '9542',
    city: 'Lierzberg'
  },
  {
    postalCode: '9542',
    city: 'Scherzboden'
  },
  {
    postalCode: '9542',
    city: 'Tassach'
  },
  {
    postalCode: '9542',
    city: 'Tauchenberg'
  },
  {
    postalCode: '9542',
    city: 'Tobitsch'
  },
  {
    postalCode: '9542',
    city: 'Verditz'
  },
  {
    postalCode: '9543',
    city: 'Arriach'
  },
  {
    postalCode: '9543',
    city: 'Berg ob Arriach'
  },
  {
    postalCode: '9543',
    city: 'Dreihofen'
  },
  {
    postalCode: '9543',
    city: 'Hinterbuchholz'
  },
  {
    postalCode: '9543',
    city: 'Hinterwinkl'
  },
  {
    postalCode: '9543',
    city: 'Hundsdorf'
  },
  {
    postalCode: '9543',
    city: 'Innerteuchen'
  },
  {
    postalCode: '9543',
    city: 'Laastadt'
  },
  {
    postalCode: '9543',
    city: 'Oberwöllan'
  },
  {
    postalCode: '9543',
    city: 'Sauboden'
  },
  {
    postalCode: '9543',
    city: 'Sauerwald'
  },
  {
    postalCode: '9543',
    city: 'Stadt'
  },
  {
    postalCode: '9543',
    city: 'Unterwöllan'
  },
  {
    postalCode: '9543',
    city: 'Vorderwinkl'
  },
  {
    postalCode: '9543',
    city: 'Waldweg'
  },
  {
    postalCode: '9544',
    city: 'Erlach'
  },
  {
    postalCode: '9544',
    city: 'Klamberg'
  },
  {
    postalCode: '9544',
    city: 'Feld am See'
  },
  {
    postalCode: '9544',
    city: 'Feldpannalpe'
  },
  {
    postalCode: '9544',
    city: 'Rauth'
  },
  {
    postalCode: '9544',
    city: 'Schattseite'
  },
  {
    postalCode: '9544',
    city: 'Untersee'
  },
  {
    postalCode: '9544',
    city: 'Wiesen'
  },
  {
    postalCode: '9545',
    city: 'Kaning'
  },
  {
    postalCode: '9545',
    city: 'Dabor'
  },
  {
    postalCode: '9545',
    city: 'Ebene'
  },
  {
    postalCode: '9545',
    city: 'Erdmannsiedlung'
  },
  {
    postalCode: '9545',
    city: 'Frischg'
  },
  {
    postalCode: '9545',
    city: 'Hohensaß'
  },
  {
    postalCode: '9545',
    city: 'Laufenberg'
  },
  {
    postalCode: '9545',
    city: 'Mitterberg'
  },
  {
    postalCode: '9545',
    city: 'Obertweng'
  },
  {
    postalCode: '9545',
    city: 'Radenthein'
  },
  {
    postalCode: '9545',
    city: 'St. Peter'
  },
  {
    postalCode: '9545',
    city: 'Schrott'
  },
  {
    postalCode: '9545',
    city: 'Zödl'
  },
  {
    postalCode: '9545',
    city: 'Schattseite'
  },
  {
    postalCode: '9545',
    city: 'Untertweng'
  },
  {
    postalCode: '9546',
    city: 'Aigen'
  },
  {
    postalCode: '9546',
    city: 'Bach'
  },
  {
    postalCode: '9546',
    city: 'Kleinkirchheim'
  },
  {
    postalCode: '9546',
    city: 'Obertschern'
  },
  {
    postalCode: '9546',
    city: 'Rottenstein'
  },
  {
    postalCode: '9546',
    city: 'St. Oswald'
  },
  {
    postalCode: '9546',
    city: 'Staudach'
  },
  {
    postalCode: '9546',
    city: 'Untertschern'
  },
  {
    postalCode: '9546',
    city: 'Zirkitzen'
  },
  {
    postalCode: '9546',
    city: 'Hohensaß'
  },
  {
    postalCode: '9551',
    city: 'Bodensdorf'
  },
  {
    postalCode: '9551',
    city: 'Burg'
  },
  {
    postalCode: '9551',
    city: 'Ossiachberg'
  },
  {
    postalCode: '9551',
    city: 'St. Urban'
  },
  {
    postalCode: '9551',
    city: 'Stiegl'
  },
  {
    postalCode: '9551',
    city: 'Tratten'
  },
  {
    postalCode: '9551',
    city: 'Tschöran'
  },
  {
    postalCode: '9551',
    city: 'Unterberg'
  },
  {
    postalCode: '9551',
    city: 'Winkl Ossiachberg'
  },
  {
    postalCode: '9551',
    city: 'Deutschberg'
  },
  {
    postalCode: '9551',
    city: 'Stöcklweingarten'
  },
  {
    postalCode: '9552',
    city: 'Fresen'
  },
  {
    postalCode: '9552',
    city: 'Manessen'
  },
  {
    postalCode: '9552',
    city: 'Sallach'
  },
  {
    postalCode: '9552',
    city: 'Golk'
  },
  {
    postalCode: '9552',
    city: 'Ossiachberg'
  },
  {
    postalCode: '9552',
    city: 'Sonnberg'
  },
  {
    postalCode: '9552',
    city: 'Steindorf am Ossiacher See'
  },
  {
    postalCode: '9552',
    city: 'Stiegl'
  },
  {
    postalCode: '9554',
    city: 'Retschitz-Simonhöhe'
  },
  {
    postalCode: '9554',
    city: 'Agsdorf'
  },
  {
    postalCode: '9554',
    city: 'Agsdorf-Gegend'
  },
  {
    postalCode: '9554',
    city: 'Bach'
  },
  {
    postalCode: '9554',
    city: 'Bach-St. Urban'
  },
  {
    postalCode: '9554',
    city: 'Eggen'
  },
  {
    postalCode: '9554',
    city: 'Gall'
  },
  {
    postalCode: '9554',
    city: 'Gasmai'
  },
  {
    postalCode: '9554',
    city: 'Göschl'
  },
  {
    postalCode: '9554',
    city: 'Grai'
  },
  {
    postalCode: '9554',
    city: 'Hafenberg'
  },
  {
    postalCode: '9554',
    city: 'Kleingradenegg'
  },
  {
    postalCode: '9554',
    city: 'Lawesen'
  },
  {
    postalCode: '9554',
    city: 'Oberdorf'
  },
  {
    postalCode: '9554',
    city: 'Reggen'
  },
  {
    postalCode: '9554',
    city: 'Retschitz'
  },
  {
    postalCode: '9554',
    city: 'Rittolach'
  },
  {
    postalCode: '9554',
    city: 'Rogg'
  },
  {
    postalCode: '9554',
    city: 'St. Urban'
  },
  {
    postalCode: '9554',
    city: 'Stattenberg'
  },
  {
    postalCode: '9554',
    city: 'Trenk'
  },
  {
    postalCode: '9554',
    city: 'Tumpf'
  },
  {
    postalCode: '9554',
    city: 'Zirkitz'
  },
  {
    postalCode: '9554',
    city: 'Zwattendorf'
  },
  {
    postalCode: '9554',
    city: 'Gößeberg'
  },
  {
    postalCode: '9554',
    city: 'Buggl in Bach'
  },
  {
    postalCode: '9554',
    city: 'Simonhöhe'
  },
  {
    postalCode: '9555',
    city: 'Bach'
  },
  {
    postalCode: '9555',
    city: 'Besendorf'
  },
  {
    postalCode: '9555',
    city: 'Deblach'
  },
  {
    postalCode: '9555',
    city: 'Flatschach'
  },
  {
    postalCode: '9555',
    city: 'Friedlach'
  },
  {
    postalCode: '9555',
    city: 'Glanegg'
  },
  {
    postalCode: '9555',
    city: 'Glantscha'
  },
  {
    postalCode: '9555',
    city: 'Gösselsberg'
  },
  {
    postalCode: '9555',
    city: 'Grintschach'
  },
  {
    postalCode: '9555',
    city: 'Kadöll'
  },
  {
    postalCode: '9555',
    city: 'Krobathen'
  },
  {
    postalCode: '9555',
    city: 'Maria Feicht'
  },
  {
    postalCode: '9555',
    city: 'Maria Feicht-Gegend'
  },
  {
    postalCode: '9555',
    city: 'Mautbrücken'
  },
  {
    postalCode: '9555',
    city: 'Metschach'
  },
  {
    postalCode: '9555',
    city: 'Paindorf'
  },
  {
    postalCode: '9555',
    city: 'St. Gandolf'
  },
  {
    postalCode: '9555',
    city: 'Schwambach'
  },
  {
    postalCode: '9555',
    city: 'Unterglanegg'
  },
  {
    postalCode: '9555',
    city: 'Zmuln'
  },
  {
    postalCode: '9556',
    city: 'Beißendorf'
  },
  {
    postalCode: '9556',
    city: 'Fachau'
  },
  {
    postalCode: '9556',
    city: 'Kreuth'
  },
  {
    postalCode: '9556',
    city: 'Besendorf'
  },
  {
    postalCode: '9556',
    city: 'Gramilach'
  },
  {
    postalCode: '9556',
    city: 'Haidach'
  },
  {
    postalCode: '9556',
    city: 'Kulm'
  },
  {
    postalCode: '9556',
    city: 'Mauer'
  },
  {
    postalCode: '9556',
    city: 'Meschkowitz'
  },
  {
    postalCode: '9556',
    city: 'St. Leonhard'
  },
  {
    postalCode: '9556',
    city: 'Tauchendorf'
  },
  {
    postalCode: '9556',
    city: 'Grund'
  },
  {
    postalCode: '9556',
    city: 'Bärndorf'
  },
  {
    postalCode: '9556',
    city: 'Eggen I'
  },
  {
    postalCode: '9556',
    city: 'Glantschach'
  },
  {
    postalCode: '9556',
    city: 'Gößeberg'
  },
  {
    postalCode: '9556',
    city: 'Hardegg'
  },
  {
    postalCode: '9556',
    city: 'Hoch-Liebenfels'
  },
  {
    postalCode: '9556',
    city: 'Hohenstein'
  },
  {
    postalCode: '9556',
    city: 'Lebmach'
  },
  {
    postalCode: '9556',
    city: 'Liebenfels'
  },
  {
    postalCode: '9556',
    city: 'Liemberg'
  },
  {
    postalCode: '9556',
    city: 'Lorberhof'
  },
  {
    postalCode: '9556',
    city: 'Mailsberg'
  },
  {
    postalCode: '9556',
    city: 'Miedling'
  },
  {
    postalCode: '9556',
    city: 'Moos'
  },
  {
    postalCode: '9556',
    city: 'Pulst'
  },
  {
    postalCode: '9556',
    city: 'Puppitsch'
  },
  {
    postalCode: '9556',
    city: 'Radelsdorf'
  },
  {
    postalCode: '9556',
    city: 'Rohnsdorf'
  },
  {
    postalCode: '9556',
    city: 'Rosenbichl'
  },
  {
    postalCode: '9556',
    city: 'Tschadam'
  },
  {
    postalCode: '9556',
    city: 'Waggendorf'
  },
  {
    postalCode: '9556',
    city: 'Wasai'
  },
  {
    postalCode: '9556',
    city: 'Weitensfeld'
  },
  {
    postalCode: '9556',
    city: 'Woitsch'
  },
  {
    postalCode: '9556',
    city: 'Zmuln'
  },
  {
    postalCode: '9556',
    city: 'Zwattendorf'
  },
  {
    postalCode: '9556',
    city: 'Zweikirchen'
  },
  {
    postalCode: '9556',
    city: 'Eggen II'
  },
  {
    postalCode: '9556',
    city: 'Freundsam'
  },
  {
    postalCode: '9556',
    city: 'Gasmai'
  },
  {
    postalCode: '9556',
    city: 'Graben'
  },
  {
    postalCode: '9556',
    city: 'Gradenegg'
  },
  {
    postalCode: '9556',
    city: 'Grassendorf'
  },
  {
    postalCode: '9556',
    city: 'Hart'
  },
  {
    postalCode: '9556',
    city: 'Ladein'
  },
  {
    postalCode: '9556',
    city: 'Pflausach'
  },
  {
    postalCode: '9556',
    city: 'Pflugern'
  },
  {
    postalCode: '9556',
    city: 'Rasting'
  },
  {
    postalCode: '9556',
    city: 'Reidenau'
  },
  {
    postalCode: '9556',
    city: 'Sörg'
  },
  {
    postalCode: '9556',
    city: 'Sörgerberg'
  },
  {
    postalCode: '9556',
    city: 'Zojach'
  },
  {
    postalCode: '9556',
    city: 'St. Paul'
  },
  {
    postalCode: '9556',
    city: 'Eberdorf'
  },
  {
    postalCode: '9556',
    city: 'Karnberg'
  },
  {
    postalCode: '9556',
    city: 'Karlsberg'
  },
  {
    postalCode: '9560',
    city: 'Feistritz'
  },
  {
    postalCode: '9560',
    city: 'Dietrichstein'
  },
  {
    postalCode: '9560',
    city: 'Dobra'
  },
  {
    postalCode: '9560',
    city: 'Elbling'
  },
  {
    postalCode: '9560',
    city: 'Feldkirchen in Kärnten'
  },
  {
    postalCode: '9560',
    city: 'Förolach'
  },
  {
    postalCode: '9560',
    city: 'Glan'
  },
  {
    postalCode: '9560',
    city: 'Glanblick'
  },
  {
    postalCode: '9560',
    city: 'Guttaring'
  },
  {
    postalCode: '9560',
    city: 'Haiden'
  },
  {
    postalCode: '9560',
    city: 'Kofl'
  },
  {
    postalCode: '9560',
    city: 'Krahberg'
  },
  {
    postalCode: '9560',
    city: 'Laboisen'
  },
  {
    postalCode: '9560',
    city: 'Lang'
  },
  {
    postalCode: '9560',
    city: 'Leinig'
  },
  {
    postalCode: '9560',
    city: 'Lendorf'
  },
  {
    postalCode: '9560',
    city: 'Liebetig'
  },
  {
    postalCode: '9560',
    city: 'Lindl'
  },
  {
    postalCode: '9560',
    city: 'Metzing'
  },
  {
    postalCode: '9560',
    city: 'Pökelitz'
  },
  {
    postalCode: '9560',
    city: 'Poitschach'
  },
  {
    postalCode: '9560',
    city: 'Poitschachgraben'
  },
  {
    postalCode: '9560',
    city: 'Powirtschach'
  },
  {
    postalCode: '9560',
    city: 'Praschig'
  },
  {
    postalCode: '9560',
    city: 'Rabensdorf'
  },
  {
    postalCode: '9560',
    city: 'Raunach'
  },
  {
    postalCode: '9560',
    city: 'Rennweg'
  },
  {
    postalCode: '9560',
    city: 'Rottendorf'
  },
  {
    postalCode: '9560',
    city: 'St. Ruprecht'
  },
  {
    postalCode: '9560',
    city: 'St. Stefan'
  },
  {
    postalCode: '9560',
    city: 'St. Ulrich'
  },
  {
    postalCode: '9560',
    city: 'Seitenberg'
  },
  {
    postalCode: '9560',
    city: 'Sonnrain'
  },
  {
    postalCode: '9560',
    city: 'Tschwarzen'
  },
  {
    postalCode: '9560',
    city: 'Unterrain'
  },
  {
    postalCode: '9560',
    city: 'Wachsenberg'
  },
  {
    postalCode: '9560',
    city: 'Waiern'
  },
  {
    postalCode: '9560',
    city: 'Weit'
  },
  {
    postalCode: '9560',
    city: 'Adriach'
  },
  {
    postalCode: '9560',
    city: 'Alpen'
  },
  {
    postalCode: '9560',
    city: 'Bösenlacken'
  },
  {
    postalCode: '9560',
    city: 'Buchscheiden'
  },
  {
    postalCode: '9560',
    city: 'Debar'
  },
  {
    postalCode: '9560',
    city: 'Dellach'
  },
  {
    postalCode: '9560',
    city: 'Dolintschig'
  },
  {
    postalCode: '9560',
    city: 'Eberdorf'
  },
  {
    postalCode: '9560',
    city: 'Egg'
  },
  {
    postalCode: '9560',
    city: 'Glanhofen'
  },
  {
    postalCode: '9560',
    city: 'Höfling'
  },
  {
    postalCode: '9560',
    city: 'Klachl'
  },
  {
    postalCode: '9560',
    city: 'Klausen'
  },
  {
    postalCode: '9560',
    city: 'Leiten'
  },
  {
    postalCode: '9560',
    city: 'Pernegg'
  },
  {
    postalCode: '9560',
    city: 'Pollenitz'
  },
  {
    postalCode: '9560',
    city: 'Prägrad'
  },
  {
    postalCode: '9560',
    city: 'Stocklitz'
  },
  {
    postalCode: '9560',
    city: 'Tramoitschig'
  },
  {
    postalCode: '9560',
    city: 'Unterberg'
  },
  {
    postalCode: '9560',
    city: 'Aich'
  },
  {
    postalCode: '9560',
    city: 'Albern'
  },
  {
    postalCode: '9560',
    city: 'Briefelsdorf'
  },
  {
    postalCode: '9560',
    city: 'Farcha'
  },
  {
    postalCode: '9560',
    city: 'Fasching'
  },
  {
    postalCode: '9560',
    city: 'Gradisch'
  },
  {
    postalCode: '9560',
    city: 'Hart'
  },
  {
    postalCode: '9560',
    city: 'Ingelsdorf'
  },
  {
    postalCode: '9560',
    city: 'Kallitsch'
  },
  {
    postalCode: '9560',
    city: 'Klein St. Veit'
  },
  {
    postalCode: '9560',
    city: 'Kreuth'
  },
  {
    postalCode: '9560',
    city: 'Maltschach'
  },
  {
    postalCode: '9560',
    city: 'Markstein'
  },
  {
    postalCode: '9560',
    city: 'Mattersdorf'
  },
  {
    postalCode: '9560',
    city: 'Micheldorf'
  },
  {
    postalCode: '9560',
    city: 'Naßweg'
  },
  {
    postalCode: '9560',
    city: 'Niederdorf'
  },
  {
    postalCode: '9560',
    city: 'Oberglan'
  },
  {
    postalCode: '9560',
    city: 'Persching'
  },
  {
    postalCode: '9560',
    city: 'Radweg'
  },
  {
    postalCode: '9560',
    city: 'St. Martin'
  },
  {
    postalCode: '9560',
    city: 'Sittich'
  },
  {
    postalCode: '9560',
    city: 'Untere Glan'
  },
  {
    postalCode: '9560',
    city: 'Zingelsberg'
  },
  {
    postalCode: '9560',
    city: 'Agsdorf-Gegend'
  },
  {
    postalCode: '9560',
    city: 'Poitschach-Baracke'
  },
  {
    postalCode: '9560',
    city: 'Draschen'
  },
  {
    postalCode: '9560',
    city: 'Kaidern'
  },
  {
    postalCode: '9560',
    city: 'Kraß'
  },
  {
    postalCode: '9560',
    city: 'Knasweg'
  },
  {
    postalCode: '9560',
    city: 'Witsch'
  },
  {
    postalCode: '9560',
    city: 'Tauern'
  },
  {
    postalCode: '9560',
    city: 'St. Nikolai'
  },
  {
    postalCode: '9560',
    city: 'Apetig'
  },
  {
    postalCode: '9560',
    city: 'Bichl'
  },
  {
    postalCode: '9560',
    city: 'Burgrad'
  },
  {
    postalCode: '9560',
    city: 'Langacker'
  },
  {
    postalCode: '9560',
    city: 'Nadling'
  },
  {
    postalCode: '9560',
    city: 'Pfaffendorf'
  },
  {
    postalCode: '9560',
    city: 'Regin'
  },
  {
    postalCode: '9560',
    city: 'Tiffen'
  },
  {
    postalCode: '9560',
    city: 'Tscherneitsch'
  },
  {
    postalCode: '9560',
    city: 'Dölnitz'
  },
  {
    postalCode: '9560',
    city: 'Edern'
  },
  {
    postalCode: '9560',
    city: 'Edling'
  },
  {
    postalCode: '9560',
    city: 'Felfern'
  },
  {
    postalCode: '9560',
    city: 'Fuchsgruben'
  },
  {
    postalCode: '9560',
    city: 'Glabegg'
  },
  {
    postalCode: '9560',
    city: 'Goggau'
  },
  {
    postalCode: '9560',
    city: 'Graben'
  },
  {
    postalCode: '9560',
    city: 'Hinterwachsenberg'
  },
  {
    postalCode: '9560',
    city: 'Jeinitz'
  },
  {
    postalCode: '9560',
    city: 'Kerschdorf'
  },
  {
    postalCode: '9560',
    city: 'Köttern'
  },
  {
    postalCode: '9560',
    city: 'Kraßnitz'
  },
  {
    postalCode: '9560',
    city: 'Niederwinklern'
  },
  {
    postalCode: '9560',
    city: 'Pölling'
  },
  {
    postalCode: '9560',
    city: 'Prapra'
  },
  {
    postalCode: '9560',
    city: 'Regenfeld'
  },
  {
    postalCode: '9560',
    city: 'Rotapfel'
  },
  {
    postalCode: '9560',
    city: 'Sallas'
  },
  {
    postalCode: '9560',
    city: 'Sassl'
  },
  {
    postalCode: '9560',
    city: 'Severgraben'
  },
  {
    postalCode: '9560',
    city: 'Steuerberg'
  },
  {
    postalCode: '9560',
    city: 'Thörl'
  },
  {
    postalCode: '9560',
    city: 'Unterhof'
  },
  {
    postalCode: '9560',
    city: 'Wabl'
  },
  {
    postalCode: '9560',
    city: 'Wiggis'
  },
  {
    postalCode: '9560',
    city: 'Eden'
  },
  {
    postalCode: '9562',
    city: 'Außerteuchen'
  },
  {
    postalCode: '9562',
    city: 'Dragelsberg'
  },
  {
    postalCode: '9562',
    city: 'Eden'
  },
  {
    postalCode: '9562',
    city: 'Flatschach'
  },
  {
    postalCode: '9562',
    city: 'Glanz'
  },
  {
    postalCode: '9562',
    city: 'Grilzgraben'
  },
  {
    postalCode: '9562',
    city: 'Grintschach'
  },
  {
    postalCode: '9562',
    city: 'Himmelberg'
  },
  {
    postalCode: '9562',
    city: 'Hochegg'
  },
  {
    postalCode: '9562',
    city: 'Klatzenberg'
  },
  {
    postalCode: '9562',
    city: 'Kösting'
  },
  {
    postalCode: '9562',
    city: 'Lassen'
  },
  {
    postalCode: '9562',
    city: 'Linz'
  },
  {
    postalCode: '9562',
    city: 'Oberboden'
  },
  {
    postalCode: '9562',
    city: 'Pichlern'
  },
  {
    postalCode: '9562',
    city: 'Pojedl'
  },
  {
    postalCode: '9562',
    city: 'Saurachberg'
  },
  {
    postalCode: '9562',
    city: 'Schleichenfeld'
  },
  {
    postalCode: '9562',
    city: 'Schwaig'
  },
  {
    postalCode: '9562',
    city: 'Sonnleiten'
  },
  {
    postalCode: '9562',
    city: 'Spitzenbichl'
  },
  {
    postalCode: '9562',
    city: 'Tiebel'
  },
  {
    postalCode: '9562',
    city: 'Tiffnerwinkl'
  },
  {
    postalCode: '9562',
    city: 'Tobitsch'
  },
  {
    postalCode: '9562',
    city: 'Tschriet'
  },
  {
    postalCode: '9562',
    city: 'Werschling'
  },
  {
    postalCode: '9562',
    city: 'Winklern'
  },
  {
    postalCode: '9562',
    city: 'Wöllach'
  },
  {
    postalCode: '9562',
    city: 'Zedlitzberg'
  },
  {
    postalCode: '9563',
    city: 'Bergl'
  },
  {
    postalCode: '9563',
    city: 'Eben'
  },
  {
    postalCode: '9563',
    city: 'Gnesau'
  },
  {
    postalCode: '9563',
    city: 'Sonnleiten'
  },
  {
    postalCode: '9563',
    city: 'Görzberg'
  },
  {
    postalCode: '9563',
    city: 'Görzwinkl'
  },
  {
    postalCode: '9563',
    city: 'Gurk'
  },
  {
    postalCode: '9563',
    city: 'Haidenbach'
  },
  {
    postalCode: '9563',
    city: 'Maitratten'
  },
  {
    postalCode: '9563',
    city: 'Mitteregg'
  },
  {
    postalCode: '9563',
    city: 'Weißenbach'
  },
  {
    postalCode: '9563',
    city: 'Zedlitzdorf'
  },
  {
    postalCode: '9563',
    city: 'Tiebel'
  },
  {
    postalCode: '9564',
    city: 'Bergl'
  },
  {
    postalCode: '9564',
    city: 'Falkertsee'
  },
  {
    postalCode: '9564',
    city: 'Lassen'
  },
  {
    postalCode: '9564',
    city: 'Mitterdorf'
  },
  {
    postalCode: '9564',
    city: 'Patergassen'
  },
  {
    postalCode: '9564',
    city: 'Plaß'
  },
  {
    postalCode: '9564',
    city: 'St. Margarethen'
  },
  {
    postalCode: '9564',
    city: 'Vorderkoflach'
  },
  {
    postalCode: '9564',
    city: 'Vorwald'
  },
  {
    postalCode: '9564',
    city: 'Wiederschwing'
  },
  {
    postalCode: '9564',
    city: 'Wiedweg'
  },
  {
    postalCode: '9564',
    city: 'Rottenstein'
  },
  {
    postalCode: '9565',
    city: 'Ebene Reichenau'
  },
  {
    postalCode: '9565',
    city: 'Hinterkoflach'
  },
  {
    postalCode: '9565',
    city: 'Lorenzenberg'
  },
  {
    postalCode: '9565',
    city: 'St. Lorenzen'
  },
  {
    postalCode: '9565',
    city: 'Saureggen'
  },
  {
    postalCode: '9565',
    city: 'Schuß'
  },
  {
    postalCode: '9565',
    city: 'Seebach'
  },
  {
    postalCode: '9565',
    city: 'Turracherhöhe'
  },
  {
    postalCode: '9565',
    city: 'Waidach'
  },
  {
    postalCode: '9565',
    city: 'Winkl'
  },
  {
    postalCode: '9570',
    city: 'Alt-Ossiach'
  },
  {
    postalCode: '9570',
    city: 'Ossiach'
  },
  {
    postalCode: '9570',
    city: 'Ostriach'
  },
  {
    postalCode: '9570',
    city: 'Rappitsch'
  },
  {
    postalCode: '9571',
    city: 'Albeck Obere Schattseite'
  },
  {
    postalCode: '9571',
    city: 'Albeck Untere Schattseite'
  },
  {
    postalCode: '9571',
    city: 'Benesirnitz'
  },
  {
    postalCode: '9571',
    city: 'Egarn'
  },
  {
    postalCode: '9571',
    city: 'Frankenberg'
  },
  {
    postalCode: '9571',
    city: 'Grillenberg'
  },
  {
    postalCode: '9571',
    city: 'Hochrindl'
  },
  {
    postalCode: '9571',
    city: 'Hofern'
  },
  {
    postalCode: '9571',
    city: 'Holzern'
  },
  {
    postalCode: '9571',
    city: 'Kalsberg'
  },
  {
    postalCode: '9571',
    city: 'Kogl'
  },
  {
    postalCode: '9571',
    city: 'Kruckenalm'
  },
  {
    postalCode: '9571',
    city: 'Lamm'
  },
  {
    postalCode: '9571',
    city: 'Leßnitz'
  },
  {
    postalCode: '9571',
    city: 'Neualbeck'
  },
  {
    postalCode: '9571',
    city: 'Oberdörfl'
  },
  {
    postalCode: '9571',
    city: 'Obereggen'
  },
  {
    postalCode: '9571',
    city: 'St. Ruprecht'
  },
  {
    postalCode: '9571',
    city: 'Sirnitz'
  },
  {
    postalCode: '9571',
    city: 'Sirnitz-Schattseite'
  },
  {
    postalCode: '9571',
    city: 'Sirnitz-Sonnseite'
  },
  {
    postalCode: '9571',
    city: 'Sirnitz-Winkl'
  },
  {
    postalCode: '9571',
    city: 'Spitzwiesen'
  },
  {
    postalCode: '9571',
    city: 'Stron'
  },
  {
    postalCode: '9571',
    city: 'Unterdörfl'
  },
  {
    postalCode: '9571',
    city: 'Untereggen'
  },
  {
    postalCode: '9571',
    city: 'Wippa'
  },
  {
    postalCode: '9571',
    city: 'Hochrindl-Alpl'
  },
  {
    postalCode: '9571',
    city: 'Hochrindl-Kegel'
  },
  {
    postalCode: '9571',
    city: 'Hochrindl-Tatermann'
  },
  {
    postalCode: '9571',
    city: 'Seebachern'
  },
  {
    postalCode: '9572',
    city: 'Albern'
  },
  {
    postalCode: '9572',
    city: 'Arlsdorf'
  },
  {
    postalCode: '9572',
    city: 'Bach'
  },
  {
    postalCode: '9572',
    city: 'Bischofsberg'
  },
  {
    postalCode: '9572',
    city: 'Brunn'
  },
  {
    postalCode: '9572',
    city: 'Deutsch Griffen'
  },
  {
    postalCode: '9572',
    city: 'Faulwinkel'
  },
  {
    postalCode: '9572',
    city: 'Göschelsberg'
  },
  {
    postalCode: '9572',
    city: 'Graben'
  },
  {
    postalCode: '9572',
    city: 'Gray'
  },
  {
    postalCode: '9572',
    city: 'Hintereggen'
  },
  {
    postalCode: '9572',
    city: 'Leßnitz'
  },
  {
    postalCode: '9572',
    city: 'Meisenberg'
  },
  {
    postalCode: '9572',
    city: 'Messaneggen'
  },
  {
    postalCode: '9572',
    city: 'Mitteregg'
  },
  {
    postalCode: '9572',
    city: 'Oberlamm'
  },
  {
    postalCode: '9572',
    city: 'Pesseneggen'
  },
  {
    postalCode: '9572',
    city: 'Ratzendorf'
  },
  {
    postalCode: '9572',
    city: 'Rauscheggen'
  },
  {
    postalCode: '9572',
    city: 'Sand'
  },
  {
    postalCode: '9572',
    city: 'Tanzenberg'
  },
  {
    postalCode: '9572',
    city: 'Unterlamm'
  },
  {
    postalCode: '9572',
    city: 'Gantschach'
  },
  {
    postalCode: '9580',
    city: 'Bogenfeld'
  },
  {
    postalCode: '9580',
    city: 'Egg am Faaker See'
  },
  {
    postalCode: '9580',
    city: 'Graschitz'
  },
  {
    postalCode: '9580',
    city: 'Greuth'
  },
  {
    postalCode: '9580',
    city: 'Großsattel'
  },
  {
    postalCode: '9580',
    city: 'Kratschach'
  },
  {
    postalCode: '9580',
    city: 'Maria Gail'
  },
  {
    postalCode: '9580',
    city: 'Mittewald'
  },
  {
    postalCode: '9580',
    city: 'St. Niklas an der Drau'
  },
  {
    postalCode: '9580',
    city: 'Serai'
  },
  {
    postalCode: '9580',
    city: 'Drobollach am Faaker See'
  },
  {
    postalCode: '9581',
    city: 'Unteraichwald'
  },
  {
    postalCode: '9581',
    city: 'Kopein'
  },
  {
    postalCode: '9581',
    city: 'Ledenitzen'
  },
  {
    postalCode: '9581',
    city: 'Mallenitzen'
  },
  {
    postalCode: '9581',
    city: 'Oberferlach'
  },
  {
    postalCode: '9581',
    city: 'Petschnitzen'
  },
  {
    postalCode: '9581',
    city: 'Unterferlach'
  },
  {
    postalCode: '9581',
    city: 'Pirk'
  },
  {
    postalCode: '9582',
    city: 'Altfinkenstein'
  },
  {
    postalCode: '9582',
    city: 'Outschena'
  },
  {
    postalCode: '9582',
    city: 'Unteraichwald'
  },
  {
    postalCode: '9582',
    city: 'Untergreuth'
  },
  {
    postalCode: '9582',
    city: 'Latschach'
  },
  {
    postalCode: '9582',
    city: 'Oberaichwald'
  },
  {
    postalCode: '9582',
    city: 'Pogöriach'
  },
  {
    postalCode: '9582',
    city: 'Ratnitz'
  },
  {
    postalCode: '9583',
    city: 'Faak am See'
  },
  {
    postalCode: '9583',
    city: 'Finkenstein'
  },
  {
    postalCode: '9584',
    city: 'Finkenstein'
  },
  {
    postalCode: '9584',
    city: 'Goritschach'
  },
  {
    postalCode: '9584',
    city: 'Höfling'
  },
  {
    postalCode: '9585',
    city: 'Techanting'
  },
  {
    postalCode: '9585',
    city: 'Neumüllnern'
  },
  {
    postalCode: '9585',
    city: 'Gödersdorf'
  },
  {
    postalCode: '9585',
    city: 'Müllnern'
  },
  {
    postalCode: '9585',
    city: 'Stobitzen'
  },
  {
    postalCode: '9585',
    city: 'Susalitsch'
  },
  {
    postalCode: '9585',
    city: 'Villach-Warmbad-Judendorf'
  },
  {
    postalCode: '9586',
    city: 'Hart'
  },
  {
    postalCode: '9586',
    city: 'Fürnitz'
  },
  {
    postalCode: '9586',
    city: 'St. Job'
  },
  {
    postalCode: '9586',
    city: 'Sigmontitsch'
  },
  {
    postalCode: '9586',
    city: 'Stobitzen'
  },
  {
    postalCode: '9586',
    city: 'Oberfederaun'
  },
  {
    postalCode: '9586',
    city: 'Oberschütt'
  },
  {
    postalCode: '9586',
    city: 'Unterfederaun'
  },
  {
    postalCode: '9586',
    city: 'Unterschütt'
  },
  {
    postalCode: '9587',
    city: 'Erlendorf'
  },
  {
    postalCode: '9587',
    city: 'Hart'
  },
  {
    postalCode: '9587',
    city: 'Krainberg'
  },
  {
    postalCode: '9587',
    city: 'Krainegg'
  },
  {
    postalCode: '9587',
    city: 'Neuhaus an der Gail'
  },
  {
    postalCode: '9587',
    city: 'Radendorf'
  },
  {
    postalCode: '9587',
    city: 'Riegersdorf'
  },
  {
    postalCode: '9587',
    city: 'St. Leonhard bei Siebenbrünn'
  },
  {
    postalCode: '9587',
    city: 'Tschau'
  },
  {
    postalCode: '9587',
    city: 'Korpitsch'
  },
  {
    postalCode: '9587',
    city: 'Oberschütt'
  },
  {
    postalCode: '9601',
    city: 'Agoritschach'
  },
  {
    postalCode: '9601',
    city: 'Arnoldstein'
  },
  {
    postalCode: '9601',
    city: 'Gailitz'
  },
  {
    postalCode: '9601',
    city: 'Greuth'
  },
  {
    postalCode: '9601',
    city: 'Lind'
  },
  {
    postalCode: '9601',
    city: 'Pöckau'
  },
  {
    postalCode: '9601',
    city: 'Seltschach'
  },
  {
    postalCode: '9601',
    city: 'Stossau'
  },
  {
    postalCode: '9602',
    city: 'Maglern'
  },
  {
    postalCode: '9602',
    city: 'Oberthörl'
  },
  {
    postalCode: '9602',
    city: 'Pessendellach'
  },
  {
    postalCode: '9602',
    city: 'Thörl-Maglern-Greuth'
  },
  {
    postalCode: '9602',
    city: 'Unterthörl'
  },
  {
    postalCode: '9602',
    city: 'Draschitz'
  },
  {
    postalCode: '9602',
    city: 'Hohenthurn'
  },
  {
    postalCode: '9611',
    city: 'Emmersdorf'
  },
  {
    postalCode: '9611',
    city: 'Förk'
  },
  {
    postalCode: '9611',
    city: 'Michelhofen'
  },
  {
    postalCode: '9611',
    city: 'Nötsch'
  },
  {
    postalCode: '9611',
    city: 'Saak'
  },
  {
    postalCode: '9612',
    city: 'Bach'
  },
  {
    postalCode: '9612',
    city: 'Dellach'
  },
  {
    postalCode: '9612',
    city: 'Glabatschach'
  },
  {
    postalCode: '9612',
    city: 'Kerschdorf'
  },
  {
    postalCode: '9612',
    city: 'Kreublach'
  },
  {
    postalCode: '9612',
    city: 'Kühweg'
  },
  {
    postalCode: '9612',
    city: 'Labientschach'
  },
  {
    postalCode: '9612',
    city: 'Poglantschach'
  },
  {
    postalCode: '9612',
    city: 'St. Georgen im Gailtal'
  },
  {
    postalCode: '9612',
    city: 'Semering'
  },
  {
    postalCode: '9612',
    city: 'Wertschach'
  },
  {
    postalCode: '9613',
    city: 'Feistritz an der Gail'
  },
  {
    postalCode: '9613',
    city: 'Achomitz'
  },
  {
    postalCode: '9613',
    city: 'Draschitz'
  },
  {
    postalCode: '9613',
    city: 'Dreulach'
  },
  {
    postalCode: '9613',
    city: 'Göriach'
  },
  {
    postalCode: '9614',
    city: 'Bodenhof'
  },
  {
    postalCode: '9614',
    city: 'Vorderberg'
  },
  {
    postalCode: '9615',
    city: 'Latschach'
  },
  {
    postalCode: '9615',
    city: 'Förolach'
  },
  {
    postalCode: '9615',
    city: 'Görtschach'
  },
  {
    postalCode: '9615',
    city: 'Presseggen'
  },
  {
    postalCode: '9615',
    city: 'Schinzengraben'
  },
  {
    postalCode: '9615',
    city: 'Siebenbrünn'
  },
  {
    postalCode: '9615',
    city: 'Wittenig'
  },
  {
    postalCode: '9615',
    city: 'Zuchen'
  },
  {
    postalCode: '9615',
    city: 'Möderndorf'
  },
  {
    postalCode: '9615',
    city: 'Sonnleitn'
  },
  {
    postalCode: '9615',
    city: 'Köstendorf'
  },
  {
    postalCode: '9620',
    city: 'Brunn'
  },
  {
    postalCode: '9620',
    city: 'Jadersdorf'
  },
  {
    postalCode: '9620',
    city: 'Lassendorf'
  },
  {
    postalCode: '9620',
    city: 'St. Lorenzen im Gitschtal'
  },
  {
    postalCode: '9620',
    city: 'Wulzentratten'
  },
  {
    postalCode: '9620',
    city: 'Braunitzen'
  },
  {
    postalCode: '9620',
    city: 'Eggforst'
  },
  {
    postalCode: '9620',
    city: 'Neudorf'
  },
  {
    postalCode: '9620',
    city: 'Potschach'
  },
  {
    postalCode: '9620',
    city: 'Aigen'
  },
  {
    postalCode: '9620',
    city: 'Grünburg'
  },
  {
    postalCode: '9620',
    city: 'Hermagor'
  },
  {
    postalCode: '9620',
    city: 'Khünburg'
  },
  {
    postalCode: '9620',
    city: 'Kraß'
  },
  {
    postalCode: '9620',
    city: 'Kreuth ob Möschach'
  },
  {
    postalCode: '9620',
    city: 'Kühwegboden'
  },
  {
    postalCode: '9620',
    city: 'Liesch'
  },
  {
    postalCode: '9620',
    city: 'Neuprießenegg'
  },
  {
    postalCode: '9620',
    city: 'Obermöschach'
  },
  {
    postalCode: '9620',
    city: 'Obervellach'
  },
  {
    postalCode: '9620',
    city: 'Presseggersee'
  },
  {
    postalCode: '9620',
    city: 'Radnig'
  },
  {
    postalCode: '9620',
    city: 'Radnigforst'
  },
  {
    postalCode: '9620',
    city: 'Untermöschach'
  },
  {
    postalCode: '9620',
    city: 'Untervellach'
  },
  {
    postalCode: '9620',
    city: 'Bergl'
  },
  {
    postalCode: '9620',
    city: 'Burgstall'
  },
  {
    postalCode: '9620',
    city: 'Kameritsch'
  },
  {
    postalCode: '9620',
    city: 'Kraschach'
  },
  {
    postalCode: '9620',
    city: 'Kühweg'
  },
  {
    postalCode: '9620',
    city: 'Mitschig'
  },
  {
    postalCode: '9620',
    city: 'Möderndorf'
  },
  {
    postalCode: '9620',
    city: 'Podlanig'
  },
  {
    postalCode: '9620',
    city: 'Postran'
  },
  {
    postalCode: '9620',
    city: 'Sonnenalpe Nassfeld'
  },
  {
    postalCode: '9620',
    city: 'Watschig'
  },
  {
    postalCode: '9620',
    city: 'Guggenberg'
  },
  {
    postalCode: '9620',
    city: 'Sonnleitn'
  },
  {
    postalCode: '9622',
    city: 'Langwiesen'
  },
  {
    postalCode: '9622',
    city: 'Golz'
  },
  {
    postalCode: '9622',
    city: 'Leditz'
  },
  {
    postalCode: '9622',
    city: 'Regitt'
  },
  {
    postalCode: '9622',
    city: 'Weißbriach'
  },
  {
    postalCode: '9623',
    city: 'Kerschdorf'
  },
  {
    postalCode: '9623',
    city: 'Bach'
  },
  {
    postalCode: '9623',
    city: 'Bichlhof'
  },
  {
    postalCode: '9623',
    city: 'Dragantschach'
  },
  {
    postalCode: '9623',
    city: 'Edling'
  },
  {
    postalCode: '9623',
    city: 'Hadersdorf'
  },
  {
    postalCode: '9623',
    city: 'Karnitzen'
  },
  {
    postalCode: '9623',
    city: 'Köstendorf'
  },
  {
    postalCode: '9623',
    city: 'Latschach'
  },
  {
    postalCode: '9623',
    city: 'Matschiedl'
  },
  {
    postalCode: '9623',
    city: 'Nieselach'
  },
  {
    postalCode: '9623',
    city: 'Pölland'
  },
  {
    postalCode: '9623',
    city: 'Pörtschach'
  },
  {
    postalCode: '9623',
    city: 'St. Paul an der Gail'
  },
  {
    postalCode: '9623',
    city: 'St. Stefan an der Gail'
  },
  {
    postalCode: '9623',
    city: 'Schmölzing'
  },
  {
    postalCode: '9623',
    city: 'Sussawitsch'
  },
  {
    postalCode: '9623',
    city: 'Tratten'
  },
  {
    postalCode: '9624',
    city: 'Brugg'
  },
  {
    postalCode: '9624',
    city: 'Dellach'
  },
  {
    postalCode: '9624',
    city: 'Egg'
  },
  {
    postalCode: '9624',
    city: 'Fritzendorf'
  },
  {
    postalCode: '9624',
    city: 'Götzing'
  },
  {
    postalCode: '9624',
    city: 'Kreuth ob Mellweg'
  },
  {
    postalCode: '9624',
    city: 'Latschach'
  },
  {
    postalCode: '9624',
    city: 'Mellach'
  },
  {
    postalCode: '9624',
    city: 'Mellweg'
  },
  {
    postalCode: '9624',
    city: 'Micheldorf'
  },
  {
    postalCode: '9624',
    city: 'Nampolach'
  },
  {
    postalCode: '9624',
    city: 'Paßriach'
  },
  {
    postalCode: '9624',
    city: 'Potschach'
  },
  {
    postalCode: '9624',
    city: 'Süßenberg'
  },
  {
    postalCode: '9631',
    city: 'Achleiten'
  },
  {
    postalCode: '9631',
    city: 'Danz'
  },
  {
    postalCode: '9631',
    city: 'Jenig'
  },
  {
    postalCode: '9631',
    city: 'Kleinbergl'
  },
  {
    postalCode: '9631',
    city: 'Kreuth ob Rattendorf'
  },
  {
    postalCode: '9631',
    city: 'Rattendorf'
  },
  {
    postalCode: '9631',
    city: 'Schlanitzen'
  },
  {
    postalCode: '9631',
    city: 'Schmidt'
  },
  {
    postalCode: '9631',
    city: 'Tröpolach'
  },
  {
    postalCode: '9631',
    city: 'Anraun'
  },
  {
    postalCode: '9631',
    city: 'Schimanberg'
  },
  {
    postalCode: '9631',
    city: 'Tramun'
  },
  {
    postalCode: '9631',
    city: 'Waidegg'
  },
  {
    postalCode: '9632',
    city: 'Hochwart'
  },
  {
    postalCode: '9632',
    city: 'Kirchbach'
  },
  {
    postalCode: '9632',
    city: 'Oberdöbernitzen'
  },
  {
    postalCode: '9632',
    city: 'Staudachberg'
  },
  {
    postalCode: '9632',
    city: 'Stöfflerberg'
  },
  {
    postalCode: '9632',
    city: 'Treßdorf'
  },
  {
    postalCode: '9632',
    city: 'Unterdöbernitzen'
  },
  {
    postalCode: '9632',
    city: 'Wassertheurerberg'
  },
  {
    postalCode: '9632',
    city: 'Krieben'
  },
  {
    postalCode: '9633',
    city: 'Forst'
  },
  {
    postalCode: '9633',
    city: 'Reisach'
  },
  {
    postalCode: '9633',
    city: 'Reißkofelbad'
  },
  {
    postalCode: '9633',
    city: 'Rinsenegg'
  },
  {
    postalCode: '9633',
    city: 'Schönboden'
  },
  {
    postalCode: '9633',
    city: 'Stranig'
  },
  {
    postalCode: '9633',
    city: 'Unterbuchach'
  },
  {
    postalCode: '9634',
    city: 'Bodenmühl'
  },
  {
    postalCode: '9634',
    city: 'Goderschach'
  },
  {
    postalCode: '9634',
    city: 'Grafendorf'
  },
  {
    postalCode: '9634',
    city: 'Griminitzen'
  },
  {
    postalCode: '9634',
    city: 'Gundersheim'
  },
  {
    postalCode: '9634',
    city: 'Katlingberg'
  },
  {
    postalCode: '9634',
    city: 'Lenzhof'
  },
  {
    postalCode: '9634',
    city: 'Oberbuchach'
  },
  {
    postalCode: '9634',
    city: 'Rauth'
  },
  {
    postalCode: '9634',
    city: 'Schmalzgrube'
  },
  {
    postalCode: '9634',
    city: 'Welzberg'
  },
  {
    postalCode: '9635',
    city: 'Dellach'
  },
  {
    postalCode: '9635',
    city: 'Goldberg'
  },
  {
    postalCode: '9635',
    city: 'Gurina'
  },
  {
    postalCode: '9635',
    city: 'Leifling'
  },
  {
    postalCode: '9635',
    city: 'Monsell'
  },
  {
    postalCode: '9635',
    city: 'Nölbling'
  },
  {
    postalCode: '9635',
    city: 'Rüben'
  },
  {
    postalCode: '9635',
    city: 'St. Daniel'
  },
  {
    postalCode: '9635',
    city: 'Stollwitz'
  },
  {
    postalCode: '9635',
    city: 'Wieserberg'
  },
  {
    postalCode: '9635',
    city: 'Gratzhof'
  },
  {
    postalCode: '9635',
    city: 'Kronhof'
  },
  {
    postalCode: '9635',
    city: 'Weidenburg'
  },
  {
    postalCode: '9640',
    city: 'Höfling'
  },
  {
    postalCode: '9640',
    city: 'Buchach'
  },
  {
    postalCode: '9640',
    city: 'Dobra'
  },
  {
    postalCode: '9640',
    city: 'Gailberg'
  },
  {
    postalCode: '9640',
    city: 'Kötschach'
  },
  {
    postalCode: '9640',
    city: 'Kreuth'
  },
  {
    postalCode: '9640',
    city: 'Kreuzberg'
  },
  {
    postalCode: '9640',
    city: 'Laas'
  },
  {
    postalCode: '9640',
    city: 'Lanz'
  },
  {
    postalCode: '9640',
    city: 'Mandorf'
  },
  {
    postalCode: '9640',
    city: 'Mauthen'
  },
  {
    postalCode: '9640',
    city: 'Plöcken'
  },
  {
    postalCode: '9640',
    city: 'Plon'
  },
  {
    postalCode: '9640',
    city: 'Wetzmann'
  },
  {
    postalCode: '9640',
    city: 'Gentschach'
  },
  {
    postalCode: '9640',
    city: 'Kosta'
  },
  {
    postalCode: '9640',
    city: 'Nischlwitz'
  },
  {
    postalCode: '9640',
    city: 'Passau'
  },
  {
    postalCode: '9640',
    city: 'Sittmoos'
  },
  {
    postalCode: '9640',
    city: 'Dolling'
  },
  {
    postalCode: '9640',
    city: 'Krieghof'
  },
  {
    postalCode: '9640',
    city: 'Mahlbach'
  },
  {
    postalCode: '9640',
    city: 'Würmlach'
  },
  {
    postalCode: '9651',
    city: 'Aigen'
  },
  {
    postalCode: '9651',
    city: 'St. Jakob im Lesachtal'
  },
  {
    postalCode: '9651',
    city: 'Strajach'
  },
  {
    postalCode: '9651',
    city: 'Würda'
  },
  {
    postalCode: '9652',
    city: 'Podlanig'
  },
  {
    postalCode: '9652',
    city: 'Birnbaum'
  },
  {
    postalCode: '9652',
    city: 'Egg'
  },
  {
    postalCode: '9652',
    city: 'Kornat'
  },
  {
    postalCode: '9652',
    city: 'Mattling'
  },
  {
    postalCode: '9652',
    city: 'Nostra'
  },
  {
    postalCode: '9652',
    city: 'Wodmaier'
  },
  {
    postalCode: '9653',
    city: 'Assing'
  },
  {
    postalCode: '9653',
    city: 'Durnthal'
  },
  {
    postalCode: '9653',
    city: 'Klebas'
  },
  {
    postalCode: '9653',
    city: 'Ladstatt'
  },
  {
    postalCode: '9653',
    city: 'Liesing'
  },
  {
    postalCode: '9653',
    city: 'Niedergail'
  },
  {
    postalCode: '9653',
    city: 'Obergail'
  },
  {
    postalCode: '9653',
    city: 'Oberring'
  },
  {
    postalCode: '9653',
    city: 'Pallas'
  },
  {
    postalCode: '9653',
    city: 'Rüben'
  },
  {
    postalCode: '9653',
    city: 'Stabenthein'
  },
  {
    postalCode: '9653',
    city: 'Tscheltsch'
  },
  {
    postalCode: '9654',
    city: 'Frohn'
  },
  {
    postalCode: '9654',
    city: 'St. Lorenzen im Lesachtal'
  },
  {
    postalCode: '9654',
    city: 'Wiesen'
  },
  {
    postalCode: '9654',
    city: 'Xaveriberg'
  },
  {
    postalCode: '9654',
    city: 'Tuffbad'
  },
  {
    postalCode: '9655',
    city: 'Guggenberg'
  },
  {
    postalCode: '9655',
    city: 'Maria Luggau'
  },
  {
    postalCode: '9655',
    city: 'Moos'
  },
  {
    postalCode: '9655',
    city: 'Promeggen'
  },
  {
    postalCode: '9655',
    city: 'Raut'
  },
  {
    postalCode: '9655',
    city: 'Salach'
  },
  {
    postalCode: '9655',
    city: 'Sterzen'
  },
  {
    postalCode: '9655',
    city: 'Tiefenbach'
  },
  {
    postalCode: '9701',
    city: 'Beinten'
  },
  {
    postalCode: '9701',
    city: 'Kamering'
  },
  {
    postalCode: '9701',
    city: 'Großegg'
  },
  {
    postalCode: '9701',
    city: 'Aichforst'
  },
  {
    postalCode: '9701',
    city: 'Brodbrenten'
  },
  {
    postalCode: '9701',
    city: 'Burgbichl'
  },
  {
    postalCode: '9701',
    city: 'Kleinegg'
  },
  {
    postalCode: '9701',
    city: 'Molzbichl'
  },
  {
    postalCode: '9701',
    city: 'Neuolsach'
  },
  {
    postalCode: '9701',
    city: 'Nußdorf'
  },
  {
    postalCode: '9701',
    city: 'Olsach'
  },
  {
    postalCode: '9701',
    city: 'Rothenthurn'
  },
  {
    postalCode: '9701',
    city: 'Schwarzenbach'
  },
  {
    postalCode: '9701',
    city: 'Winkl'
  },
  {
    postalCode: '9701',
    city: 'Oberzmöln'
  },
  {
    postalCode: '9701',
    city: 'Unterzmöln'
  },
  {
    postalCode: '9701',
    city: 'Mauthbrücken'
  },
  {
    postalCode: '9702',
    city: 'Insberg'
  },
  {
    postalCode: '9702',
    city: 'Beinten'
  },
  {
    postalCode: '9702',
    city: 'Ferndorf'
  },
  {
    postalCode: '9702',
    city: 'Glanz'
  },
  {
    postalCode: '9702',
    city: 'Gschriet'
  },
  {
    postalCode: '9702',
    city: 'Lang'
  },
  {
    postalCode: '9702',
    city: 'Politzen'
  },
  {
    postalCode: '9702',
    city: 'Rudersdorf'
  },
  {
    postalCode: '9702',
    city: 'St. Jakob'
  },
  {
    postalCode: '9702',
    city: 'St. Paul'
  },
  {
    postalCode: '9702',
    city: 'Sonnwiesen'
  },
  {
    postalCode: '9702',
    city: 'Laas'
  },
  {
    postalCode: '9702',
    city: 'Nußdorf'
  },
  {
    postalCode: '9710',
    city: 'Feffernitz'
  },
  {
    postalCode: '9710',
    city: 'Mühlboden'
  },
  {
    postalCode: '9710',
    city: 'Neu-Feffernitz'
  },
  {
    postalCode: '9710',
    city: 'Duel'
  },
  {
    postalCode: '9710',
    city: 'Ebenwald'
  },
  {
    postalCode: '9710',
    city: 'Feistritz an der Drau'
  },
  {
    postalCode: '9710',
    city: 'Feistritz an der Drau-Neusiedlung'
  },
  {
    postalCode: '9710',
    city: 'Pobersach'
  },
  {
    postalCode: '9710',
    city: 'Pöllan'
  },
  {
    postalCode: '9710',
    city: 'Rubland'
  },
  {
    postalCode: '9710',
    city: 'Pogöriach'
  },
  {
    postalCode: '9710',
    city: 'Stuben'
  },
  {
    postalCode: '9711',
    city: 'Nikelsdorf'
  },
  {
    postalCode: '9711',
    city: 'Patendorf'
  },
  {
    postalCode: '9711',
    city: 'Aifersdorf'
  },
  {
    postalCode: '9711',
    city: 'Kamering'
  },
  {
    postalCode: '9711',
    city: 'Kreuzen'
  },
  {
    postalCode: '9711',
    city: 'Paternion'
  },
  {
    postalCode: '9711',
    city: 'Tragin'
  },
  {
    postalCode: '9711',
    city: 'Aichach'
  },
  {
    postalCode: '9711',
    city: 'Liesing'
  },
  {
    postalCode: '9711',
    city: 'Scharnitzen'
  },
  {
    postalCode: '9712',
    city: 'Amberg'
  },
  {
    postalCode: '9712',
    city: 'Fresach'
  },
  {
    postalCode: '9712',
    city: 'Laas'
  },
  {
    postalCode: '9712',
    city: 'Mitterberg'
  },
  {
    postalCode: '9712',
    city: 'Mooswald'
  },
  {
    postalCode: '9712',
    city: 'Tragenwinkel'
  },
  {
    postalCode: '9713',
    city: 'Alberden'
  },
  {
    postalCode: '9713',
    city: 'Drußnitz'
  },
  {
    postalCode: '9713',
    city: 'Hammergraben'
  },
  {
    postalCode: '9713',
    city: 'Hochegg'
  },
  {
    postalCode: '9713',
    city: 'Hollernach'
  },
  {
    postalCode: '9713',
    city: 'Ried'
  },
  {
    postalCode: '9713',
    city: 'Tragail'
  },
  {
    postalCode: '9713',
    city: 'Wiederschwing'
  },
  {
    postalCode: '9713',
    city: 'Ziebl'
  },
  {
    postalCode: '9713',
    city: 'Zlan'
  },
  {
    postalCode: '9714',
    city: 'Boden'
  },
  {
    postalCode: '9714',
    city: 'Gassen'
  },
  {
    postalCode: '9714',
    city: 'Rosental'
  },
  {
    postalCode: '9714',
    city: 'Stockenboi'
  },
  {
    postalCode: '9714',
    city: 'Unteralm'
  },
  {
    postalCode: '9714',
    city: 'Wiederschwing'
  },
  {
    postalCode: '9714',
    city: 'Seetal am Goldeck'
  },
  {
    postalCode: '9714',
    city: 'Mösel'
  },
  {
    postalCode: '9714',
    city: 'Weißenbach'
  },
  {
    postalCode: '9721',
    city: 'Lansach'
  },
  {
    postalCode: '9721',
    city: 'Kellerberg'
  },
  {
    postalCode: '9721',
    city: 'Tscheuritsch'
  },
  {
    postalCode: '9721',
    city: 'Uggowitz'
  },
  {
    postalCode: '9721',
    city: 'Weißenstein'
  },
  {
    postalCode: '9722',
    city: 'Stadelbach'
  },
  {
    postalCode: '9722',
    city: 'Töplitsch'
  },
  {
    postalCode: '9722',
    city: 'Gummern'
  },
  {
    postalCode: '9722',
    city: 'Lauen'
  },
  {
    postalCode: '9722',
    city: 'Puch'
  },
  {
    postalCode: '9722',
    city: 'Weißenbach'
  },
  {
    postalCode: '9751',
    city: 'Feistritz'
  },
  {
    postalCode: '9751',
    city: 'Lanzewitzen'
  },
  {
    postalCode: '9751',
    city: 'Nigglai'
  },
  {
    postalCode: '9751',
    city: 'Obergottesfeld'
  },
  {
    postalCode: '9751',
    city: 'Sachsenburg'
  },
  {
    postalCode: '9753',
    city: 'Bärnbad'
  },
  {
    postalCode: '9753',
    city: 'Blaßnig'
  },
  {
    postalCode: '9753',
    city: 'Kleblach'
  },
  {
    postalCode: '9753',
    city: 'Lengholz'
  },
  {
    postalCode: '9753',
    city: 'Lind im Drautal'
  },
  {
    postalCode: '9753',
    city: 'Radlberg'
  },
  {
    postalCode: '9753',
    city: 'Siflitz'
  },
  {
    postalCode: '9753',
    city: 'Leßnig'
  },
  {
    postalCode: '9753',
    city: 'Pirkeben'
  },
  {
    postalCode: '9753',
    city: 'Obergottesfeld'
  },
  {
    postalCode: '9753',
    city: 'Althaus'
  },
  {
    postalCode: '9753',
    city: 'Fellbach'
  },
  {
    postalCode: '9753',
    city: 'Fellberg'
  },
  {
    postalCode: '9753',
    city: 'Oberallach'
  },
  {
    postalCode: '9753',
    city: 'Raggnitz'
  },
  {
    postalCode: '9754',
    city: 'Flattachberg'
  },
  {
    postalCode: '9754',
    city: 'Gajach'
  },
  {
    postalCode: '9754',
    city: 'Gerlamoos'
  },
  {
    postalCode: '9754',
    city: 'Mitterberg'
  },
  {
    postalCode: '9754',
    city: 'Radlach'
  },
  {
    postalCode: '9754',
    city: 'Rottenstein'
  },
  {
    postalCode: '9754',
    city: 'Steinfeld'
  },
  {
    postalCode: '9761',
    city: 'Emberg'
  },
  {
    postalCode: '9761',
    city: 'Emberger Alm'
  },
  {
    postalCode: '9761',
    city: 'Amberg'
  },
  {
    postalCode: '9761',
    city: 'Amlach'
  },
  {
    postalCode: '9761',
    city: 'Bruggen'
  },
  {
    postalCode: '9761',
    city: 'Eben'
  },
  {
    postalCode: '9761',
    city: 'Egg'
  },
  {
    postalCode: '9761',
    city: 'Gnoppnitz'
  },
  {
    postalCode: '9761',
    city: 'Greifenburg'
  },
  {
    postalCode: '9761',
    city: 'Gries'
  },
  {
    postalCode: '9761',
    city: 'Hauzendorf'
  },
  {
    postalCode: '9761',
    city: 'Kalch'
  },
  {
    postalCode: '9761',
    city: 'Kerschbaum'
  },
  {
    postalCode: '9761',
    city: 'Kreuzberg'
  },
  {
    postalCode: '9761',
    city: 'Pobersach'
  },
  {
    postalCode: '9761',
    city: 'Rasdorf'
  },
  {
    postalCode: '9761',
    city: 'Tröbelsberg'
  },
  {
    postalCode: '9761',
    city: 'Waisach'
  },
  {
    postalCode: '9761',
    city: 'Weneberg'
  },
  {
    postalCode: '9761',
    city: 'Wassertheuer'
  },
  {
    postalCode: '9762',
    city: 'Gatschach'
  },
  {
    postalCode: '9762',
    city: 'Naggl'
  },
  {
    postalCode: '9762',
    city: 'Neusach'
  },
  {
    postalCode: '9762',
    city: 'Oberdorf'
  },
  {
    postalCode: '9762',
    city: 'Techendorf'
  },
  {
    postalCode: '9762',
    city: 'Kreuzberg'
  },
  {
    postalCode: '9762',
    city: 'Tröbelsberg'
  },
  {
    postalCode: '9771',
    city: 'Berg'
  },
  {
    postalCode: '9771',
    city: 'Ebenberg'
  },
  {
    postalCode: '9771',
    city: 'Emberg'
  },
  {
    postalCode: '9771',
    city: 'Feistritz'
  },
  {
    postalCode: '9771',
    city: 'Frallach'
  },
  {
    postalCode: '9771',
    city: 'Goppelsberg'
  },
  {
    postalCode: '9771',
    city: 'Oberberg'
  },
  {
    postalCode: '9771',
    city: 'Schlußnig'
  },
  {
    postalCode: '9772',
    city: 'Dellach'
  },
  {
    postalCode: '9772',
    city: 'Draßnitz'
  },
  {
    postalCode: '9772',
    city: 'Draßnitzdorf'
  },
  {
    postalCode: '9772',
    city: 'Glatschach'
  },
  {
    postalCode: '9772',
    city: 'Grientschnig'
  },
  {
    postalCode: '9772',
    city: 'Nörenach'
  },
  {
    postalCode: '9772',
    city: 'Raßnig'
  },
  {
    postalCode: '9772',
    city: 'Rietschach'
  },
  {
    postalCode: '9772',
    city: 'Schmelz'
  },
  {
    postalCode: '9772',
    city: 'Stein'
  },
  {
    postalCode: '9772',
    city: 'Suppersberg'
  },
  {
    postalCode: '9772',
    city: 'Weinberg'
  },
  {
    postalCode: '9773',
    city: 'Glanz'
  },
  {
    postalCode: '9773',
    city: 'Griebitsch'
  },
  {
    postalCode: '9773',
    city: 'Gröfelhof'
  },
  {
    postalCode: '9773',
    city: 'Hintergassen'
  },
  {
    postalCode: '9773',
    city: 'Irschen'
  },
  {
    postalCode: '9773',
    city: 'Leppen'
  },
  {
    postalCode: '9773',
    city: 'Mötschlach'
  },
  {
    postalCode: '9773',
    city: 'Pflügen'
  },
  {
    postalCode: '9773',
    city: 'Pölland'
  },
  {
    postalCode: '9773',
    city: 'Potschling'
  },
  {
    postalCode: '9773',
    city: 'Rittersdorf'
  },
  {
    postalCode: '9773',
    city: 'Schörstadt'
  },
  {
    postalCode: '9773',
    city: 'Stresweg'
  },
  {
    postalCode: '9773',
    city: 'Weneberg'
  },
  {
    postalCode: '9781',
    city: 'Simmerlach'
  },
  {
    postalCode: '9781',
    city: 'Flaschberg'
  },
  {
    postalCode: '9781',
    city: 'Gailberg'
  },
  {
    postalCode: '9781',
    city: 'Oberdrauburg'
  },
  {
    postalCode: '9781',
    city: 'Oberpirkach'
  },
  {
    postalCode: '9781',
    city: 'Ötting'
  },
  {
    postalCode: '9781',
    city: 'Rosenberg'
  },
  {
    postalCode: '9781',
    city: 'Unterpirkach'
  },
  {
    postalCode: '9781',
    city: 'Waidach'
  },
  {
    postalCode: '9781',
    city: 'Schrottenberg'
  },
  {
    postalCode: '9781',
    city: 'Zwickenberg'
  },
  {
    postalCode: '9782',
    city: 'Damer'
  },
  {
    postalCode: '9782',
    city: 'Lengberg'
  },
  {
    postalCode: '9782',
    city: 'Lindsberg'
  },
  {
    postalCode: '9782',
    city: 'Michelsberg'
  },
  {
    postalCode: '9782',
    city: 'Nikolsdorf'
  },
  {
    postalCode: '9782',
    city: 'Nörsach'
  },
  {
    postalCode: '9782',
    city: 'Plone'
  },
  {
    postalCode: '9800',
    city: 'Schüttbach'
  },
  {
    postalCode: '9800',
    city: 'Windschnurn'
  },
  {
    postalCode: '9800',
    city: 'Lurnbichl'
  },
  {
    postalCode: '9800',
    city: 'St. Wolfgang'
  },
  {
    postalCode: '9800',
    city: 'Aich'
  },
  {
    postalCode: '9800',
    city: 'Baldersdorf'
  },
  {
    postalCode: '9800',
    city: 'Edling'
  },
  {
    postalCode: '9800',
    city: 'Kleinsaß'
  },
  {
    postalCode: '9800',
    city: 'Krieselsdorf'
  },
  {
    postalCode: '9800',
    city: 'Oberamlach'
  },
  {
    postalCode: '9800',
    city: 'Oberdorf'
  },
  {
    postalCode: '9800',
    city: 'St. Peter'
  },
  {
    postalCode: '9800',
    city: 'St. Sigmund'
  },
  {
    postalCode: '9800',
    city: 'Spittal an der Drau'
  },
  {
    postalCode: '9800',
    city: 'Tangern'
  },
  {
    postalCode: '9800',
    city: 'Unteramlach'
  },
  {
    postalCode: '9800',
    city: 'Zgurn'
  },
  {
    postalCode: '9805',
    city: 'Baldramsdorf'
  },
  {
    postalCode: '9805',
    city: 'Faschendorf'
  },
  {
    postalCode: '9805',
    city: 'Gendorf'
  },
  {
    postalCode: '9805',
    city: 'Goldeck'
  },
  {
    postalCode: '9805',
    city: 'Lampersberg'
  },
  {
    postalCode: '9805',
    city: 'Oberaich'
  },
  {
    postalCode: '9805',
    city: 'Rosenheim'
  },
  {
    postalCode: '9805',
    city: 'Schwaig'
  },
  {
    postalCode: '9805',
    city: 'Unterhaus'
  },
  {
    postalCode: '9811',
    city: 'Feicht'
  },
  {
    postalCode: '9811',
    city: 'Feichtendorf'
  },
  {
    postalCode: '9811',
    city: 'Freßnitz'
  },
  {
    postalCode: '9811',
    city: 'Hühnersberg'
  },
  {
    postalCode: '9811',
    city: 'Lendorf'
  },
  {
    postalCode: '9811',
    city: 'Litzlhof'
  },
  {
    postalCode: '9811',
    city: 'Rojach'
  },
  {
    postalCode: '9811',
    city: 'St. Peter in Holz'
  },
  {
    postalCode: '9811',
    city: 'Tröbach'
  },
  {
    postalCode: '9812',
    city: 'Göriach'
  },
  {
    postalCode: '9812',
    city: 'Pusarnitz'
  },
  {
    postalCode: '9812',
    city: 'St. Gertraud'
  },
  {
    postalCode: '9812',
    city: 'St. Stefan'
  },
  {
    postalCode: '9812',
    city: 'Steindorf'
  },
  {
    postalCode: '9812',
    city: 'Stöcklern'
  },
  {
    postalCode: '9812',
    city: 'Tröbach'
  },
  {
    postalCode: '9813',
    city: 'Altenmarkt'
  },
  {
    postalCode: '9813',
    city: 'Möllbrücke'
  },
  {
    postalCode: '9813',
    city: 'Pattendorf'
  },
  {
    postalCode: '9813',
    city: 'Premersdorf'
  },
  {
    postalCode: '9813',
    city: 'Drauhofen'
  },
  {
    postalCode: '9813',
    city: 'Metnitz'
  },
  {
    postalCode: '9813',
    city: 'Sachsenweg'
  },
  {
    postalCode: '9814',
    city: 'Mühldorf'
  },
  {
    postalCode: '9814',
    city: 'Rappersdorf'
  },
  {
    postalCode: '9814',
    city: 'Sachsenweg'
  },
  {
    postalCode: '9815',
    city: 'Hattelberg'
  },
  {
    postalCode: '9815',
    city: 'Mitterberg'
  },
  {
    postalCode: '9815',
    city: 'Oberkolbnitz'
  },
  {
    postalCode: '9815',
    city: 'Polan'
  },
  {
    postalCode: '9815',
    city: 'Preisdorf'
  },
  {
    postalCode: '9815',
    city: 'Rottau'
  },
  {
    postalCode: '9815',
    city: 'Tratten'
  },
  {
    postalCode: '9815',
    city: 'Unterkolbnitz'
  },
  {
    postalCode: '9815',
    city: 'Zandlach'
  },
  {
    postalCode: '9815',
    city: 'Sandbichl'
  },
  {
    postalCode: '9816',
    city: 'Gappen'
  },
  {
    postalCode: '9816',
    city: 'Litzldorf'
  },
  {
    postalCode: '9816',
    city: 'Moos'
  },
  {
    postalCode: '9816',
    city: 'Napplach'
  },
  {
    postalCode: '9816',
    city: 'Penk'
  },
  {
    postalCode: '9816',
    city: 'Teuchl'
  },
  {
    postalCode: '9816',
    city: 'Zwenberg'
  },
  {
    postalCode: '9821',
    city: 'Dürnvellach'
  },
  {
    postalCode: '9821',
    city: 'Kaponig'
  },
  {
    postalCode: '9821',
    city: 'Lassach Schattseite'
  },
  {
    postalCode: '9821',
    city: 'Lassach Sonnseite'
  },
  {
    postalCode: '9821',
    city: 'Leutschach'
  },
  {
    postalCode: '9821',
    city: 'Obergratschach'
  },
  {
    postalCode: '9821',
    city: 'Obervellach'
  },
  {
    postalCode: '9821',
    city: 'Obervellach-West'
  },
  {
    postalCode: '9821',
    city: 'Oberwolliggen'
  },
  {
    postalCode: '9821',
    city: 'Pfaffenberg'
  },
  {
    postalCode: '9821',
    city: 'Räuflach'
  },
  {
    postalCode: '9821',
    city: 'Raufen'
  },
  {
    postalCode: '9821',
    city: 'Semslach'
  },
  {
    postalCode: '9821',
    city: 'Söbriach'
  },
  {
    postalCode: '9821',
    city: 'Stallhofen'
  },
  {
    postalCode: '9821',
    city: 'Stampf'
  },
  {
    postalCode: '9821',
    city: 'Untergratschach'
  },
  {
    postalCode: '9821',
    city: 'Untervocken'
  },
  {
    postalCode: '9821',
    city: 'Unterwolliggen'
  },
  {
    postalCode: '9822',
    city: 'Dösen'
  },
  {
    postalCode: '9822',
    city: 'Mallnitz'
  },
  {
    postalCode: '9822',
    city: 'Rabisch'
  },
  {
    postalCode: '9822',
    city: 'Stappitz'
  },
  {
    postalCode: '9831',
    city: 'Außerfragant'
  },
  {
    postalCode: '9831',
    city: 'Flattach'
  },
  {
    postalCode: '9831',
    city: 'Flattachberg'
  },
  {
    postalCode: '9831',
    city: 'Grafenberg'
  },
  {
    postalCode: '9831',
    city: 'Innerfragant'
  },
  {
    postalCode: '9831',
    city: 'Kleindorf'
  },
  {
    postalCode: '9831',
    city: 'Laas'
  },
  {
    postalCode: '9831',
    city: 'Schmelzhütten'
  },
  {
    postalCode: '9831',
    city: 'Waben'
  },
  {
    postalCode: '9832',
    city: 'Berg ob Stall'
  },
  {
    postalCode: '9832',
    city: 'Gößnitz'
  },
  {
    postalCode: '9832',
    city: 'Gußnigberg'
  },
  {
    postalCode: '9832',
    city: 'Latzendorf'
  },
  {
    postalCode: '9832',
    city: 'Obersteinwand'
  },
  {
    postalCode: '9832',
    city: 'Pußtratten'
  },
  {
    postalCode: '9832',
    city: 'Rakowitzen'
  },
  {
    postalCode: '9832',
    city: 'Sagas'
  },
  {
    postalCode: '9832',
    city: 'Schwersberg'
  },
  {
    postalCode: '9832',
    city: 'Sonnberg'
  },
  {
    postalCode: '9832',
    city: 'Stadlberg'
  },
  {
    postalCode: '9832',
    city: 'Stall'
  },
  {
    postalCode: '9832',
    city: 'Stieflberg'
  },
  {
    postalCode: '9832',
    city: 'Untersteinwand'
  },
  {
    postalCode: '9832',
    city: 'Wöllatratten'
  },
  {
    postalCode: '9833',
    city: 'Lainach'
  },
  {
    postalCode: '9833',
    city: 'Lamnitz'
  },
  {
    postalCode: '9833',
    city: 'Lobersberg'
  },
  {
    postalCode: '9833',
    city: 'Plappergassen'
  },
  {
    postalCode: '9833',
    city: 'Rangersdorf'
  },
  {
    postalCode: '9833',
    city: 'Tresdorf'
  },
  {
    postalCode: '9833',
    city: 'Wenneberg'
  },
  {
    postalCode: '9833',
    city: 'Witschdorf'
  },
  {
    postalCode: '9833',
    city: 'Zladisch'
  },
  {
    postalCode: '9833',
    city: 'Stein'
  },
  {
    postalCode: '9841',
    city: 'Auen'
  },
  {
    postalCode: '9841',
    city: 'Lobersberg'
  },
  {
    postalCode: '9841',
    city: 'Langang'
  },
  {
    postalCode: '9841',
    city: 'Namlach'
  },
  {
    postalCode: '9841',
    city: 'Penzelberg'
  },
  {
    postalCode: '9841',
    city: 'Reintal'
  },
  {
    postalCode: '9841',
    city: 'Winklern'
  },
  {
    postalCode: '9841',
    city: 'Zwischenbergen'
  },
  {
    postalCode: '9842',
    city: 'Asten'
  },
  {
    postalCode: '9842',
    city: 'Auen'
  },
  {
    postalCode: '9842',
    city: 'Lassach'
  },
  {
    postalCode: '9842',
    city: 'Mörtschach'
  },
  {
    postalCode: '9842',
    city: 'Mörtschachberg'
  },
  {
    postalCode: '9842',
    city: 'Pirkachberg'
  },
  {
    postalCode: '9842',
    city: 'Rettenbach'
  },
  {
    postalCode: '9842',
    city: 'Stampfen'
  },
  {
    postalCode: '9842',
    city: 'Stranach'
  },
  {
    postalCode: '9843',
    city: 'Allas'
  },
  {
    postalCode: '9843',
    city: 'Döllach'
  },
  {
    postalCode: '9843',
    city: 'Egg'
  },
  {
    postalCode: '9843',
    city: 'Göritz'
  },
  {
    postalCode: '9843',
    city: 'Kraß'
  },
  {
    postalCode: '9843',
    city: 'Mitteldorf'
  },
  {
    postalCode: '9843',
    city: 'Mitten'
  },
  {
    postalCode: '9843',
    city: 'Putschall'
  },
  {
    postalCode: '9843',
    city: 'Ranach'
  },
  {
    postalCode: '9843',
    city: 'Sagritz'
  },
  {
    postalCode: '9843',
    city: 'Untersagritz'
  },
  {
    postalCode: '9843',
    city: 'Winklsagritz'
  },
  {
    postalCode: '9843',
    city: 'Zirknitz'
  },
  {
    postalCode: '9843',
    city: 'Am Putzenhof'
  },
  {
    postalCode: '9844',
    city: 'Apriach'
  },
  {
    postalCode: '9844',
    city: 'Aichhorn'
  },
  {
    postalCode: '9844',
    city: 'Fleiß'
  },
  {
    postalCode: '9844',
    city: 'Hadergasse'
  },
  {
    postalCode: '9844',
    city: 'Pockhorn'
  },
  {
    postalCode: '9844',
    city: 'Rojach'
  },
  {
    postalCode: '9844',
    city: 'Schachnern'
  },
  {
    postalCode: '9844',
    city: 'Untertauern'
  },
  {
    postalCode: '9844',
    city: 'Winkl'
  },
  {
    postalCode: '9844',
    city: 'Wolkersdorf'
  },
  {
    postalCode: '9844',
    city: 'Hof'
  },
  {
    postalCode: '9851',
    city: 'Seebach'
  },
  {
    postalCode: '9851',
    city: 'Lieserbrücke'
  },
  {
    postalCode: '9851',
    city: 'Karlsdorf'
  },
  {
    postalCode: '9851',
    city: 'Lieserhofen'
  },
  {
    postalCode: '9851',
    city: 'Litzldorf'
  },
  {
    postalCode: '9851',
    city: 'Lurnbichl'
  },
  {
    postalCode: '9851',
    city: 'Raufen'
  },
  {
    postalCode: '9851',
    city: 'Kras'
  },
  {
    postalCode: '9851',
    city: 'Lieseregg'
  },
  {
    postalCode: '9852',
    city: 'Aich'
  },
  {
    postalCode: '9852',
    city: 'Altersberg'
  },
  {
    postalCode: '9852',
    city: 'Hintereggen'
  },
  {
    postalCode: '9852',
    city: 'Neuschitz'
  },
  {
    postalCode: '9852',
    city: 'Oberallach'
  },
  {
    postalCode: '9852',
    city: 'Pirk'
  },
  {
    postalCode: '9852',
    city: 'Rachenbach'
  },
  {
    postalCode: '9852',
    city: 'Radl'
  },
  {
    postalCode: '9852',
    city: 'Trebesing'
  },
  {
    postalCode: '9852',
    city: 'Zelsach'
  },
  {
    postalCode: '9852',
    city: 'Zlatting'
  },
  {
    postalCode: '9852',
    city: 'Trebesing-Bad'
  },
  {
    postalCode: '9853',
    city: 'Oberkreuschlach'
  },
  {
    postalCode: '9853',
    city: 'Treffenboden'
  },
  {
    postalCode: '9853',
    city: 'Gmünd'
  },
  {
    postalCode: '9853',
    city: 'Karnerau'
  },
  {
    postalCode: '9853',
    city: 'Landfraß'
  },
  {
    postalCode: '9853',
    city: 'Moos'
  },
  {
    postalCode: '9853',
    city: 'Moostratte'
  },
  {
    postalCode: '9853',
    city: 'Oberbuch'
  },
  {
    postalCode: '9853',
    city: 'Perau'
  },
  {
    postalCode: '9853',
    city: 'Platz'
  },
  {
    postalCode: '9853',
    city: 'Unterbuch'
  },
  {
    postalCode: '9853',
    city: 'Grünleiten'
  },
  {
    postalCode: '9853',
    city: 'Burgwiese'
  },
  {
    postalCode: '9853',
    city: 'Unterkreuschlach'
  },
  {
    postalCode: '9853',
    city: 'Brochendorf'
  },
  {
    postalCode: '9853',
    city: 'Dornbach'
  },
  {
    postalCode: '9853',
    city: 'Fischertratten'
  },
  {
    postalCode: '9853',
    city: 'Kleinhattenberg'
  },
  {
    postalCode: '9853',
    city: 'Saps'
  },
  {
    postalCode: '9853',
    city: 'Großhattenberg'
  },
  {
    postalCode: '9854',
    city: 'Brandstatt'
  },
  {
    postalCode: '9854',
    city: 'Feistritz'
  },
  {
    postalCode: '9854',
    city: 'Göß'
  },
  {
    postalCode: '9854',
    city: 'Gries'
  },
  {
    postalCode: '9854',
    city: 'Hilpersdorf'
  },
  {
    postalCode: '9854',
    city: 'Koschach'
  },
  {
    postalCode: '9854',
    city: 'Krainberg'
  },
  {
    postalCode: '9854',
    city: 'Malta'
  },
  {
    postalCode: '9854',
    city: 'Maltaberg'
  },
  {
    postalCode: '9854',
    city: 'Schlatzing'
  },
  {
    postalCode: '9854',
    city: 'Schlatzingerau'
  },
  {
    postalCode: '9861',
    city: 'Heitzelsberg'
  },
  {
    postalCode: '9861',
    city: 'Densdorf'
  },
  {
    postalCode: '9861',
    city: 'Eisentratten'
  },
  {
    postalCode: '9861',
    city: 'Gamschitz'
  },
  {
    postalCode: '9861',
    city: 'Hammerboden'
  },
  {
    postalCode: '9861',
    city: 'Innernöring'
  },
  {
    postalCode: '9861',
    city: 'Laggen'
  },
  {
    postalCode: '9861',
    city: 'Leoben'
  },
  {
    postalCode: '9861',
    city: 'Leobengraben'
  },
  {
    postalCode: '9861',
    city: 'Pirkeggen'
  },
  {
    postalCode: '9861',
    city: 'Pressingberg'
  },
  {
    postalCode: '9861',
    city: 'Puchreit'
  },
  {
    postalCode: '9861',
    city: 'Sonnberg'
  },
  {
    postalCode: '9861',
    city: 'Unterkremsbrücke'
  },
  {
    postalCode: '9861',
    city: 'Vordernöring'
  },
  {
    postalCode: '9861',
    city: 'Winkl'
  },
  {
    postalCode: '9861',
    city: 'Lientsch'
  },
  {
    postalCode: '9862',
    city: 'Illwitzen'
  },
  {
    postalCode: '9862',
    city: 'Innerkrems'
  },
  {
    postalCode: '9862',
    city: 'Kremsbrücke'
  },
  {
    postalCode: '9862',
    city: 'Neuhammer'
  },
  {
    postalCode: '9862',
    city: 'Oberburgstallberg'
  },
  {
    postalCode: '9862',
    city: 'Oberkremsberg'
  },
  {
    postalCode: '9862',
    city: 'Pleßnitz'
  },
  {
    postalCode: '9862',
    city: 'Purbach'
  },
  {
    postalCode: '9862',
    city: 'Rauchenkatsch'
  },
  {
    postalCode: '9862',
    city: 'Reitern'
  },
  {
    postalCode: '9862',
    city: 'St. Nikolai'
  },
  {
    postalCode: '9862',
    city: 'Steinwand'
  },
  {
    postalCode: '9862',
    city: 'Unterburgstallberg'
  },
  {
    postalCode: '9862',
    city: 'Unterkremsberg'
  },
  {
    postalCode: '9862',
    city: 'Vorderkrems'
  },
  {
    postalCode: '9862',
    city: 'Wetschenbach'
  },
  {
    postalCode: '9862',
    city: 'Pleschberg'
  },
  {
    postalCode: '9863',
    city: 'Abwerzg'
  },
  {
    postalCode: '9863',
    city: 'Adenberg'
  },
  {
    postalCode: '9863',
    city: 'Angern'
  },
  {
    postalCode: '9863',
    city: 'Aschbach'
  },
  {
    postalCode: '9863',
    city: 'Atzensberg'
  },
  {
    postalCode: '9863',
    city: 'Brugg'
  },
  {
    postalCode: '9863',
    city: 'Frankenberg'
  },
  {
    postalCode: '9863',
    city: 'Gries'
  },
  {
    postalCode: '9863',
    city: 'Katschberghöhe'
  },
  {
    postalCode: '9863',
    city: 'Krangl'
  },
  {
    postalCode: '9863',
    city: 'Laußnitz'
  },
  {
    postalCode: '9863',
    city: 'Mühlbach'
  },
  {
    postalCode: '9863',
    city: 'Oberdorf'
  },
  {
    postalCode: '9863',
    city: 'Pleschberg'
  },
  {
    postalCode: '9863',
    city: 'Pölla'
  },
  {
    postalCode: '9863',
    city: 'Pron'
  },
  {
    postalCode: '9863',
    city: 'Rennweg'
  },
  {
    postalCode: '9863',
    city: 'Ried'
  },
  {
    postalCode: '9863',
    city: 'St. Georgen'
  },
  {
    postalCode: '9863',
    city: 'St. Peter'
  },
  {
    postalCode: '9863',
    city: 'Saraberg'
  },
  {
    postalCode: '9863',
    city: 'Schlaipf'
  },
  {
    postalCode: '9863',
    city: 'Steinwand'
  },
  {
    postalCode: '9863',
    city: 'Wirnsberg'
  },
  {
    postalCode: '9863',
    city: 'Zanaischg'
  },
  {
    postalCode: '9871',
    city: 'Seebach'
  },
  {
    postalCode: '9871',
    city: 'Kötzing'
  },
  {
    postalCode: '9871',
    city: 'Kolm'
  },
  {
    postalCode: '9871',
    city: 'Liedweg'
  },
  {
    postalCode: '9871',
    city: 'Muskanitzen'
  },
  {
    postalCode: '9871',
    city: 'Pirk'
  },
  {
    postalCode: '9871',
    city: 'Schloßau'
  },
  {
    postalCode: '9871',
    city: 'Tangern'
  },
  {
    postalCode: '9871',
    city: 'Trasischk'
  },
  {
    postalCode: '9871',
    city: 'Treffling'
  },
  {
    postalCode: '9871',
    city: 'Unterhaus'
  },
  {
    postalCode: '9871',
    city: 'Seeboden'
  },
  {
    postalCode: '9871',
    city: 'Am Tschiernock'
  },
  {
    postalCode: '9872',
    city: 'Großdombra'
  },
  {
    postalCode: '9872',
    city: 'Kleindombra'
  },
  {
    postalCode: '9872',
    city: 'Lechnerschaft'
  },
  {
    postalCode: '9872',
    city: 'Millstatt'
  },
  {
    postalCode: '9872',
    city: 'Dellach'
  },
  {
    postalCode: '9872',
    city: 'Görtschach'
  },
  {
    postalCode: '9872',
    city: 'Gössering'
  },
  {
    postalCode: '9872',
    city: 'Grantsch'
  },
  {
    postalCode: '9872',
    city: 'Hohengaß'
  },
  {
    postalCode: '9872',
    city: 'Lammersdorf'
  },
  {
    postalCode: '9872',
    city: 'Laubendorf'
  },
  {
    postalCode: '9872',
    city: 'Matzelsdorf'
  },
  {
    postalCode: '9872',
    city: 'Obermillstatt'
  },
  {
    postalCode: '9872',
    city: 'Öttern'
  },
  {
    postalCode: '9872',
    city: 'Pesenthein'
  },
  {
    postalCode: '9872',
    city: 'Sappl'
  },
  {
    postalCode: '9872',
    city: 'Schwaigerschaft'
  },
  {
    postalCode: '9872',
    city: 'Tschierweg'
  },
  {
    postalCode: '9872',
    city: 'Tangern'
  },
  {
    postalCode: '9873',
    city: 'Starfach'
  },
  {
    postalCode: '9873',
    city: 'Döbriach'
  },
  {
    postalCode: '9900',
    city: 'Amlach'
  },
  {
    postalCode: '9900',
    city: 'Lavant'
  },
  {
    postalCode: '9900',
    city: 'Lienz'
  },
  {
    postalCode: '9900',
    city: 'Patriasdorf'
  },
  {
    postalCode: '9900',
    city: 'Debanttal'
  },
  {
    postalCode: '9900',
    city: 'Glanz'
  },
  {
    postalCode: '9900',
    city: 'Oberdrum'
  },
  {
    postalCode: '9900',
    city: 'Oberlienz'
  },
  {
    postalCode: '9900',
    city: 'Tristach'
  },
  {
    postalCode: '9904',
    city: 'Thurn'
  },
  {
    postalCode: '9904',
    city: 'Oberdorf'
  },
  {
    postalCode: '9904',
    city: 'Prappernitze'
  },
  {
    postalCode: '9904',
    city: 'Zauche'
  },
  {
    postalCode: '9904',
    city: 'Zettersfeld'
  },
  {
    postalCode: '9905',
    city: 'Obergaimberg'
  },
  {
    postalCode: '9905',
    city: 'Untergaimberg'
  },
  {
    postalCode: '9909',
    city: 'Burgfrieden'
  },
  {
    postalCode: '9909',
    city: 'Leisach'
  },
  {
    postalCode: '9909',
    city: 'Leisach-Gries'
  },
  {
    postalCode: '9911',
    city: 'Mittewald'
  },
  {
    postalCode: '9911',
    city: 'Bichl'
  },
  {
    postalCode: '9911',
    city: 'Herol'
  },
  {
    postalCode: '9911',
    city: 'Klausen'
  },
  {
    postalCode: '9911',
    city: 'Oberthal'
  },
  {
    postalCode: '9911',
    city: 'St. Justina'
  },
  {
    postalCode: '9911',
    city: 'Thal-Römerweg'
  },
  {
    postalCode: '9911',
    city: 'Thal-Wilfern'
  },
  {
    postalCode: '9911',
    city: 'Vergein'
  },
  {
    postalCode: '9911',
    city: 'Bannberg'
  },
  {
    postalCode: '9911',
    city: 'Burg'
  },
  {
    postalCode: '9911',
    city: 'Dörfl'
  },
  {
    postalCode: '9911',
    city: 'Kosten'
  },
  {
    postalCode: '9911',
    city: 'Oberassling'
  },
  {
    postalCode: '9911',
    city: 'Penzendorf'
  },
  {
    postalCode: '9911',
    city: 'Schrottendorf'
  },
  {
    postalCode: '9911',
    city: 'Thal-Aue'
  },
  {
    postalCode: '9911',
    city: 'Unterassling'
  },
  {
    postalCode: '9912',
    city: 'Anras'
  },
  {
    postalCode: '9912',
    city: 'Asch'
  },
  {
    postalCode: '9912',
    city: 'Mittewald'
  },
  {
    postalCode: '9912',
    city: 'Winkl'
  },
  {
    postalCode: '9912',
    city: 'Kolls'
  },
  {
    postalCode: '9912',
    city: 'Kobreil'
  },
  {
    postalCode: '9912',
    city: 'Margarethenbrücke'
  },
  {
    postalCode: '9912',
    city: 'Mairwiesen'
  },
  {
    postalCode: '9912',
    city: 'Planitzen'
  },
  {
    postalCode: '9912',
    city: 'Rain'
  },
  {
    postalCode: '9912',
    city: 'Kollreid'
  },
  {
    postalCode: '9912',
    city: 'Köden'
  },
  {
    postalCode: '9912',
    city: 'Oberried'
  },
  {
    postalCode: '9912',
    city: 'Raut'
  },
  {
    postalCode: '9912',
    city: 'Unterried'
  },
  {
    postalCode: '9912',
    city: 'Wiesen'
  },
  {
    postalCode: '9912',
    city: 'Erlbrücke'
  },
  {
    postalCode: '9912',
    city: 'Lehen'
  },
  {
    postalCode: '9912',
    city: 'Gebreite'
  },
  {
    postalCode: '9912',
    city: 'Goll'
  },
  {
    postalCode: '9913',
    city: 'Abfaltersbach'
  },
  {
    postalCode: '9920',
    city: 'Panzendorf'
  },
  {
    postalCode: '9920',
    city: 'Tessenberg'
  },
  {
    postalCode: '9920',
    city: 'Arnbach'
  },
  {
    postalCode: '9920',
    city: 'Sillian'
  },
  {
    postalCode: '9920',
    city: 'Sillianberg'
  },
  {
    postalCode: '9920',
    city: 'Strassen'
  },
  {
    postalCode: '9931',
    city: 'Außervillgraten'
  },
  {
    postalCode: '9932',
    city: 'Innervillgraten'
  },
  {
    postalCode: '9941',
    city: 'Hollbruck'
  },
  {
    postalCode: '9941',
    city: 'Kartitsch'
  },
  {
    postalCode: '9942',
    city: 'Bergen'
  },
  {
    postalCode: '9942',
    city: 'Leiten'
  },
  {
    postalCode: '9942',
    city: 'Obertilliach'
  },
  {
    postalCode: '9942',
    city: 'Rodarm'
  },
  {
    postalCode: '9943',
    city: 'Untertilliach'
  },
  {
    postalCode: '9951',
    city: 'Ainet'
  },
  {
    postalCode: '9951',
    city: 'Alkus'
  },
  {
    postalCode: '9951',
    city: 'Gwabl'
  },
  {
    postalCode: '9952',
    city: 'St. Johann im Walde'
  },
  {
    postalCode: '9954',
    city: 'Schlaiten'
  },
  {
    postalCode: '9961',
    city: 'Dölach'
  },
  {
    postalCode: '9961',
    city: 'Hof'
  },
  {
    postalCode: '9961',
    city: 'Hopfgarten in Defereggen'
  },
  {
    postalCode: '9961',
    city: 'Lerch'
  },
  {
    postalCode: '9961',
    city: 'Plon'
  },
  {
    postalCode: '9961',
    city: 'Rajach'
  },
  {
    postalCode: '9961',
    city: 'Ratzell'
  },
  {
    postalCode: '9962',
    city: 'Bruggen'
  },
  {
    postalCode: '9962',
    city: 'Gassen'
  },
  {
    postalCode: '9962',
    city: 'Görtschach'
  },
  {
    postalCode: '9962',
    city: 'Gritzen'
  },
  {
    postalCode: '9962',
    city: 'Gsaritzen'
  },
  {
    postalCode: '9962',
    city: 'Moos'
  },
  {
    postalCode: '9963',
    city: 'Außerrotte'
  },
  {
    postalCode: '9963',
    city: 'Feistritz'
  },
  {
    postalCode: '9963',
    city: 'Innerrotte'
  },
  {
    postalCode: '9963',
    city: 'Oberrotte'
  },
  {
    postalCode: '9963',
    city: 'Unterrotte'
  },
  {
    postalCode: '9971',
    city: 'Bichl'
  },
  {
    postalCode: '9971',
    city: 'Feld'
  },
  {
    postalCode: '9971',
    city: 'Ganz'
  },
  {
    postalCode: '9971',
    city: 'Glanz'
  },
  {
    postalCode: '9971',
    city: 'Gruben'
  },
  {
    postalCode: '9971',
    city: 'Hinterburg'
  },
  {
    postalCode: '9971',
    city: 'Hinteregg'
  },
  {
    postalCode: '9971',
    city: 'Huben'
  },
  {
    postalCode: '9971',
    city: 'Kaltenhaus'
  },
  {
    postalCode: '9971',
    city: 'Kienburg'
  },
  {
    postalCode: '9971',
    city: 'Klaunz'
  },
  {
    postalCode: '9971',
    city: 'Klausen'
  },
  {
    postalCode: '9971',
    city: 'Matrei in Osttirol'
  },
  {
    postalCode: '9971',
    city: 'Mattersberg'
  },
  {
    postalCode: '9971',
    city: 'Moos'
  },
  {
    postalCode: '9971',
    city: 'Proßegg'
  },
  {
    postalCode: '9971',
    city: 'Raneburg'
  },
  {
    postalCode: '9971',
    city: 'Seblas'
  },
  {
    postalCode: '9971',
    city: 'Tauer'
  },
  {
    postalCode: '9971',
    city: 'Waier'
  },
  {
    postalCode: '9971',
    city: 'Zedlach'
  },
  {
    postalCode: '9971',
    city: 'Berg'
  },
  {
    postalCode: '9972',
    city: 'Göriach'
  },
  {
    postalCode: '9972',
    city: 'Mellitz'
  },
  {
    postalCode: '9972',
    city: 'Mitteldorf'
  },
  {
    postalCode: '9972',
    city: 'Niedermauern'
  },
  {
    postalCode: '9972',
    city: 'Obermauern'
  },
  {
    postalCode: '9972',
    city: 'Virgen'
  },
  {
    postalCode: '9972',
    city: 'Welzelach'
  },
  {
    postalCode: '9974',
    city: 'Bichl'
  },
  {
    postalCode: '9974',
    city: 'Bobojach'
  },
  {
    postalCode: '9974',
    city: 'Hinterbichl'
  },
  {
    postalCode: '9974',
    city: 'St. Andrä'
  },
  {
    postalCode: '9974',
    city: 'Wallhorn'
  },
  {
    postalCode: '9981',
    city: 'Arnig'
  },
  {
    postalCode: '9981',
    city: 'Burg'
  },
  {
    postalCode: '9981',
    city: 'Glor-Berg'
  },
  {
    postalCode: '9981',
    city: 'Großdorf'
  },
  {
    postalCode: '9981',
    city: 'Ködnitz'
  },
  {
    postalCode: '9981',
    city: 'Lana'
  },
  {
    postalCode: '9981',
    city: 'Lesach'
  },
  {
    postalCode: '9981',
    city: 'Oberpeischlach'
  },
  {
    postalCode: '9981',
    city: 'Staniska'
  },
  {
    postalCode: '9981',
    city: 'Unterburg'
  },
  {
    postalCode: '9981',
    city: 'Unterpeischlach'
  },
  {
    postalCode: '9990',
    city: 'Debant'
  },
  {
    postalCode: '9990',
    city: 'Debanttal'
  },
  {
    postalCode: '9990',
    city: 'Nußdorf'
  },
  {
    postalCode: '9990',
    city: 'Nußdorfer Berg'
  },
  {
    postalCode: '9991',
    city: 'Dölsach'
  },
  {
    postalCode: '9991',
    city: 'Gödnach'
  },
  {
    postalCode: '9991',
    city: 'Göriach'
  },
  {
    postalCode: '9991',
    city: 'Görtschach'
  },
  {
    postalCode: '9991',
    city: 'Stribach'
  },
  {
    postalCode: '9992',
    city: 'Iselsberg'
  },
  {
    postalCode: '9992',
    city: 'Stronach'
  }
];

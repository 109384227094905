import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formatQuestion, ShowIconByQuestionIdUtil, ShowIfUtil } from '../../common/utils';
import { updateCustomerJourneyAnswer } from '../../store/actions';
import {
  COLOR,
  HEATING_BUILT_YEAR,
  HEATING_LIVING_SPACE,
  QUESTION_ID,
  SPECIAL_CHARACTER
} from '../../common/constants';
import { Input, StyledSpan, InfoHelpDescription } from '../../components/epilot-forms/common';

class InputDropdownQuestionContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      answer: SPECIAL_CHARACTER.EMPTY,
      options: [],
      showDropdown: 'none'
    };
  }

  componentDidMount() {
    const { customerJourneyAnswer, question } = this.props;
    let options = [];

    switch (question.id) {
      case QUESTION_ID.HEATING_BUILDING_YEAR:
        options = [
          {
            id: 0,
            value: question.objectModel.items[HEATING_BUILT_YEAR.AFTER_2002]
          },
          {
            id: 1,
            value: question.objectModel.items[HEATING_BUILT_YEAR.BETWEEN_1995_2001]
          },
          {
            id: 2,
            value: question.objectModel.items[HEATING_BUILT_YEAR.BETWEEN_1984_1994]
          },
          {
            id: 3,
            value: question.objectModel.items[HEATING_BUILT_YEAR.BETWEEN_1978_1983]
          },
          {
            id: 4,
            value: question.objectModel.items[HEATING_BUILT_YEAR.BEFORE_1977]
          }
        ];

        this.setState({ answer: customerJourneyAnswer.heatingBuiltYear });
        this.setState({ options: options });
        break;
      case QUESTION_ID.HEATING_LIVING_SPACE:
        options = [
          {
            id: 0,
            value: '0 - ' + question.objectModel.items[HEATING_LIVING_SPACE.FIRST_LEVEL_MAX_SIZE]
          },
          {
            id: 1,
            value:
              question.objectModel.items[HEATING_LIVING_SPACE.FIRST_LEVEL_MAX_SIZE] +
              ' - ' +
              question.objectModel.items[HEATING_LIVING_SPACE.SECOND_LEVEL_MAX_SIZE]
          },
          {
            id: 2,
            value:
              question.objectModel.items[HEATING_LIVING_SPACE.SECOND_LEVEL_MAX_SIZE] +
              ' - ' +
              question.objectModel.items[HEATING_LIVING_SPACE.THIRD_LEVEL_MAX_SIZE]
          }
        ];

        if (customerJourneyAnswer.heatingLivingSpaceIndex >= 0) {
          this.setState({
            answer: options[customerJourneyAnswer.heatingLivingSpaceIndex].value
          });
        } else {
          this.setState({ answer: customerJourneyAnswer.heatingLivingSpace });
        }

        this.setState({ options: options });
        break;
      default:
        break;
    }
  }

  renderInputDropdown = () => {
    const { frontendConfiguration, question, fontText } = this.props;
    const { answer, showDropdown } = this.state;

    return (
      <div className="position-relative ep-max-width-300" style={{ margin: '0 auto' }}>
        <Input
          fontSize="14px"
          height="56px"
          maxWidth="300px"
          minWidth="80px"
          borderRadius={frontendConfiguration.borderRadius}
          borderColorFocus={frontendConfiguration.primaryColor}
          primaryTextColor={frontendConfiguration.primaryColor}
          value={answer}
          require
          onChange={value => this.onChangeInput('answer', value)}
          fontFamily={fontText}
        />
        <i
          className={
            showDropdown === ''
              ? 'ep-icon-minimal-up ep__txt--sm ep-position-top-3 ep-cursor-pointer'
              : 'ep-icon-minimal-down ep__txt--sm ep-position-top-3 ep-cursor-pointer'
          }
          style={{
            position: 'absolute',
            top: 'calc(50% - 5px)',
            left: 'calc(50% + 115px)'
          }}
          onClick={this.showHideDropDown}
        />
        <ShowIfUtil condition={question.id === QUESTION_ID.HEATING_LIVING_SPACE}>
          <StyledSpan
            font={fontText}
            style={{
              position: 'absolute',
              top: 'calc(50% - 8px)',
              left: 'calc(50% + 85px)'
            }}
          >
            {'m'}
            <sup>2</sup>
          </StyledSpan>
        </ShowIfUtil>
        <div style={{ display: showDropdown }} className="ep-dropdown-input">
          {this.renderDropdown()}
        </div>
      </div>
    );
  };

  renderDropdown = () => {
    const { options } = this.state;

    return options.map(option => {
      return (
        <div
          onClick={this.onChangeDropdown.bind(this, option.value)}
          className="ep-dropdown-input-item"
          key={option.id}
        >
          {option.value}
        </div>
      );
    });
  };

  onChangeInput = (name, value) => {
    const { question, updateCustomerJourneyAnswer } = this.props;
    let newCustomerJourneyAnswer = {};

    if (this.validateInput(value)) {
      this.setState({ [name]: value });

      switch (question.id) {
        case QUESTION_ID.HEATING_BUILDING_YEAR:
          if (value >= 2002) {
            newCustomerJourneyAnswer.heatingBuiltYearIndex = 0;
          }
          if (value >= 1995 && value <= 2001) {
            newCustomerJourneyAnswer.heatingBuiltYearIndex = 1;
          }
          if (value >= 1984 && value <= 1994) {
            newCustomerJourneyAnswer.heatingBuiltYearIndex = 2;
          }
          if (value >= 1978 && value <= 1983) {
            newCustomerJourneyAnswer.heatingBuiltYearIndex = 3;
          }
          if (value <= 1977) {
            newCustomerJourneyAnswer.heatingBuiltYearIndex = 4;
          }
          newCustomerJourneyAnswer.heatingBuiltYear = value;
          break;
        case QUESTION_ID.HEATING_LIVING_SPACE:
          newCustomerJourneyAnswer.heatingLivingSpace = value;
          newCustomerJourneyAnswer.heatingLivingSpaceIndex = -1;
          break;
        default:
          break;
      }
    }
    updateCustomerJourneyAnswer(newCustomerJourneyAnswer);
  };

  onChangeDropdown = selected => {
    this.setState({ answer: selected });

    const { question, updateCustomerJourneyAnswer } = this.props;
    const { showDropdown } = this.state;

    if (showDropdown === '') {
      this.setState({ showDropdown: 'none' });
    } else {
      this.setState({ showDropdown: '' });
    }

    let newCustomerJourneyAnswer = {};

    switch (question.id) {
      case QUESTION_ID.HEATING_BUILDING_YEAR:
        if (selected === question.objectModel.items[HEATING_BUILT_YEAR.AFTER_2002]) {
          newCustomerJourneyAnswer.heatingBuiltYearIndex = 0;
        }
        if (selected === question.objectModel.items[HEATING_BUILT_YEAR.BETWEEN_1995_2001]) {
          newCustomerJourneyAnswer.heatingBuiltYearIndex = 1;
        }
        if (selected === question.objectModel.items[HEATING_BUILT_YEAR.BETWEEN_1984_1994]) {
          newCustomerJourneyAnswer.heatingBuiltYearIndex = 2;
        }
        if (selected === question.objectModel.items[HEATING_BUILT_YEAR.BETWEEN_1978_1983]) {
          newCustomerJourneyAnswer.heatingBuiltYearIndex = 3;
        }
        if (selected === question.objectModel.items[HEATING_BUILT_YEAR.BEFORE_1977]) {
          newCustomerJourneyAnswer.heatingBuiltYearIndex = 4;
        }
        newCustomerJourneyAnswer.heatingBuiltYear = selected;
        break;
      case QUESTION_ID.HEATING_LIVING_SPACE:
        if (
          selected ===
          '0 - ' + question.objectModel.items[HEATING_LIVING_SPACE.FIRST_LEVEL_MAX_SIZE]
        ) {
          newCustomerJourneyAnswer.heatingLivingSpace = this.calHeatingLivingSpace(
            0,
            question.objectModel.items[HEATING_LIVING_SPACE.FIRST_LEVEL_MAX_SIZE]
          );
          newCustomerJourneyAnswer.heatingLivingSpaceIndex = 0;
        }
        if (
          selected ===
          question.objectModel.items[HEATING_LIVING_SPACE.FIRST_LEVEL_MAX_SIZE] +
            ' - ' +
            question.objectModel.items[HEATING_LIVING_SPACE.SECOND_LEVEL_MAX_SIZE]
        ) {
          newCustomerJourneyAnswer.heatingLivingSpace = this.calHeatingLivingSpace(
            question.objectModel.items[HEATING_LIVING_SPACE.FIRST_LEVEL_MAX_SIZE],
            question.objectModel.items[HEATING_LIVING_SPACE.SECOND_LEVEL_MAX_SIZE]
          );
          newCustomerJourneyAnswer.heatingLivingSpaceIndex = 1;
        }
        if (
          selected ===
          question.objectModel.items[HEATING_LIVING_SPACE.SECOND_LEVEL_MAX_SIZE] +
            ' - ' +
            question.objectModel.items[HEATING_LIVING_SPACE.THIRD_LEVEL_MAX_SIZE]
        ) {
          newCustomerJourneyAnswer.heatingLivingSpace = this.calHeatingLivingSpace(
            question.objectModel.items[HEATING_LIVING_SPACE.SECOND_LEVEL_MAX_SIZE],
            question.objectModel.items[HEATING_LIVING_SPACE.THIRD_LEVEL_MAX_SIZE]
          );
          newCustomerJourneyAnswer.heatingLivingSpaceIndex = 2;
        }
        break;
      default:
        break;
    }

    updateCustomerJourneyAnswer(newCustomerJourneyAnswer);
  };

  calHeatingLivingSpace = (rangeStart, rangeEnd) => {
    return (parseInt(rangeStart) + parseInt(rangeEnd)) / 2;
  };

  validateInput = input => {
    const { question } = this.props;
    const { options } = this.state;

    switch (question.id) {
      case QUESTION_ID.HEATING_BUILDING_YEAR:
        if (options.indexOf(input) > -1) {
          return true;
        }
        return !isNaN(input);
      case QUESTION_ID.HEATING_LIVING_SPACE:
        if (options.indexOf(input) > -1) {
          return true;
        }
        return !isNaN(input);
      default:
        break;
    }
  };

  showHideDropDown = () => {
    const { showDropdown } = this.state;

    if (showDropdown === '') {
      this.setState({ showDropdown: 'none' });
    } else {
      this.setState({ showDropdown: '' });
    }
  };

  render() {
    const {
      frontendConfiguration,
      customerJourneyAnswer,
      question,
      fontText,
      defaultTextColor
    } = this.props;

    const textColor = {
      color: defaultTextColor
    };

    return (
      <div className="ep-customerjourneypage__question">
        <ShowIconByQuestionIdUtil
          questionId={question.id}
          colorIcon={frontendConfiguration.primaryColor}
        />
        <div className="text-center ep-min-height-70 ep--pt-8 ep--pb-16">
          <StyledSpan
            font={fontText}
            className="ep__txt-regular ep__txt--lg ep__txt-no-select"
            style={textColor}
          >
            {formatQuestion(question.questionTitle, customerJourneyAnswer.name)}
          </StyledSpan>
          <InfoHelpDescription
            showHelpDescription={question.showHelpDescription}
            tooltipInfoContent={<StyledSpan font={fontText}>{question.helpDescription}</StyledSpan>}
            classNameForInfoIcon="ep__txt--lg ep--ml-8"
            defaultTextColor={defaultTextColor}
          />
        </div>
        {this.renderInputDropdown()}
      </div>
    );
  }
}

InputDropdownQuestionContainer.propTypes = {
  question: PropTypes.object,
  fontText: PropTypes.object,
  defaultTextColor: PropTypes.string,
  customerJourneyAnswer: PropTypes.object,
  frontendConfiguration: PropTypes.object,
  updateCustomerJourneyAnswer: PropTypes.func
};

InputDropdownQuestionContainer.defaultProps = {
  question: {},
  fontText: {},
  defaultTextColor: COLOR.DARK_GRAY,
  customerJourneyAnswer: {},
  frontendConfiguration: {},
  updateCustomerJourneyAnswer: () => ({})
};

function mapStateToProps(state) {
  return {
    frontendConfiguration: state.frontendConfiguration,
    customerJourneyAnswer: state.customerJourneyAnswer,
    customerJourneyMapping: state.customerJourneyMapping
  };
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateCustomerJourneyAnswer
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputDropdownQuestionContainer);

export const PRODUCT_SELECTED_KEY = {
  SOLAR: 'isSelectedProductSolar',
  STORAGE: 'isSelectedProductStorage',
  CHARGE: 'isSelectedProductCharge',
  POWER: 'isSelectedProductPower',
  HOUSE_CONNECTION_ELECTRICITY: 'isSelectedProductHouseConnectionForElectricity',
  HOUSE_CONNECTION_GAS: 'isSelectedProductHouseConnectionForGas',
  HOUSE_CONNECTION_WATER: 'isSelectedProductHouseConnectionForWater',
  HOUSE_CONNECTION_HEAT: 'isSelectedProductHouseConnectionForHeating',
  HOUSE_CONNECTION_BROADBAND: 'isSelectedProductHouseConnectionForBroadband',
  CHP: 'isSelectedProductCHP',
  HEATING: 'isSelectedProductHeating',
  HEATPUMP: 'isSelectedProductHeatPump',
  CARSHARING: 'isSelectedProductCarsharing',
  GAS: 'isSelectedProductGas',
  SOLAR_B2B: 'isSelectedProductSolarB2B',
  CHARGE_B2B: 'isSelectedProductChargeB2B'
};

export const PACKAGE_SELECTED_KEY = {
  SOLAR: 'selectedSolarPackage',
  STORAGE: 'selectedStoragePackage',
  CHARGE: 'selectedChargePackage',
  POWER: 'selectedPowerPackage',
  HOUSE_CONNECTION_ELECTRICITY: 'selectedHouseConnectionForElectricityPackage',
  HOUSE_CONNECTION_GAS: 'selectedHouseConnectionForGasPackage',
  HOUSE_CONNECTION_WATER: 'selectedHouseConnectionForWaterPackage',
  HOUSE_CONNECTION_HEAT: 'selectedHouseConnectionForHeatingPackage',
  HOUSE_CONNECTION_BROADBAND: 'selectedHouseConnectionForBroadbandPackage',
  CHP: 'selectedCHPPackage',
  HEATING: 'selectedHeatingPackage',
  HEATPUMP: 'selectedHeatPumpPackage',
  CARSHARING: 'selectedCarsharingPackage',
  GAS: 'selectedGasPackage',
  SOLAR_B2B: 'selectedSolarB2B',
  CHARGE_B2B: 'selectedChargeB2B'
};

export const PACKAGE_PREMIUM_TYPE_KEY = {
  SOLAR: 'isSelectedSolarPackagePremiumType',
  STORAGE: 'isSelectedStoragePackagePremiumType',
  CHARGE: 'isSelectedChargePackagePremiumType',
  POWER: 'isSelectedPowerPackagePremiumType',
  HOUSE_CONNECTION_ELECTRICITY: 'isSelectedHouseConnectionForElectricityPackagePremiumType',
  HOUSE_CONNECTION_GAS: 'isSelectedHouseConnectionForGasPackagePremiumType',
  HOUSE_CONNECTION_WATER: 'isSelectedHouseConnectionForWaterPackagePremiumType',
  HOUSE_CONNECTION_HEAT: 'isSelectedHouseConnectionForHeatingPackagePremiumType',
  HOUSE_CONNECTION_BROADBAND: 'isSelectedHouseConnectionForBroadbandPackagePremiumType',
  CHP: 'isSelectedCHPPackagePremiumType',
  HEATING: 'isSelectedHeatingPackagePremiumType',
  HEATPUMP: 'isSelectedHeatPumpPackagePremiumType',
  CARSHARING: 'isSelectedCarsharingPackagePremiumType',
  GAS: 'isSelectedGasPackagePremiumType',
  SOLAR_B2B: 'isSelectedSolarB2BPackagePremiumType',
  CHARGE_B2B: 'isSelectedChargeB2BPackagePremiumType'
};

export const SELECTED_KEY_PRODUCT_TYPE_MAP = {
  isSelectedProductSolar: 'SOLAR',
  isSelectedProductStorage: 'STORAGE',
  isSelectedProductCharge: 'CHARGE',
  isSelectedProductPower: 'POWER',
  isSelectedProductHouseConnectionForElectricity: 'HOUSE_CONNECTION_ELECTRICITY',
  isSelectedProductHouseConnectionForGas: 'HOUSE_CONNECTION_GAS',
  isSelectedProductHouseConnectionForWater: 'HOUSE_CONNECTION_WATER',
  isSelectedProductHouseConnectionForHeating: 'HOUSE_CONNECTION_HEAT',
  isSelectedProductHouseConnectionForBroadband: 'HOUSE_CONNECTION_BROADBAND',
  isSelectedProductCHP: 'CHP',
  isSelectedProductHeating: 'HEATING',
  isSelectedProductHeatPump: 'HEATPUMP',
  isSelectedProductCarsharing: 'CARSHARING',
  isSelectedProductGas: 'GAS',
  isSelectedProductSolarB2B: 'SOLAR_B2B',
  isSelectedProductChargeB2B: 'CHARGE_B2B'
};

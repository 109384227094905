import React from 'react';
import * as PropTypes from 'prop-types';
import { CheckBox } from './common';
import { COLOR } from '../../common/constants';

const CheckBoxReadAccept = props => {
  const {
    isChecked,
    defaultTextColor,
    fontText,
    primaryColor,
    require,
    policiesCheckboxChecked,
    checkboxConfig,
    onChange
  } = props;
  return (
    <div>
      <CheckBox
        require={require}
        className="ep__txt--sm ep__txt-regular"
        onChange={e => onChange(e, checkboxConfig.label, checkboxConfig.checkboxLabel)}
        content={checkboxConfig.checkboxLabel}
        isChecked={isChecked}
        fontText={fontText}
        defaultTextColor={defaultTextColor}
        primaryCheckBoxIconColor={primaryColor}
        policiesCheckboxChecked={policiesCheckboxChecked}
      />
    </div>
  );
};

CheckBoxReadAccept.propTypes = {
  defaultTextColor: PropTypes.string,
  primaryColor: PropTypes.string,
  isChecked: PropTypes.bool,
  fontText: PropTypes.object,
  onChange: PropTypes.func,
  require: PropTypes.bool,
  policiesCheckboxChecked: PropTypes.bool,
  checkboxConfig: PropTypes.object
};

CheckBoxReadAccept.defaultProps = {
  defaultTextColor: COLOR.GRAY,
  primaryColor: COLOR.DEFAULT_BLUE,
  isChecked: false,
  fontText: {},
  onChange: function() {},
  require: false,
  policiesCheckboxChecked: false,
  checkboxConfig: {}
};

export default CheckBoxReadAccept;

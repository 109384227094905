import React, { PureComponent } from 'react';
import _ from 'lodash';
import * as PropTypes from 'prop-types';
import { COLOR, MESSAGE_CONSTANTS, SPECIAL_CHARACTER, Z_INDEX } from '../../common/constants';
import {
  AutoComplete,
  CheckBox,
  DropDown,
  Input,
  StyledLabel,
  StyledP,
  StyledSpan
} from '../../components/epilot-forms/common';
import {
  CustomCollapse,
  formatAddress,
  ShowIfUtil,
  getListCountry,
  getCountryGermanNameFromCode,
  getListSalutation
} from '../../common/utils';

class AdditionalAddressesContainer extends PureComponent {
  onCollapse = () => {
    const { setContactFormHeight } = this.props;
    setTimeout(() => {
      setContactFormHeight();
    }, 100);
  };

  renderCompletedContent = (item, index) => {
    const { additionalAddressFields, fontFamily, defaultTextColor } = this.props;
    const { additionalAddressModels } = additionalAddressFields;
    return (
      <div className="ep--border-radius-8 ep--p-32">
        <div className="row">
          <div className="col-12">
            <StyledLabel
              className="ep__txt--sm font-weight-normal ep__txt-no-select ep--mb-0"
              font={fontFamily}
              style={{ color: defaultTextColor }}
            >
              {additionalAddressModels[index].addressFieldLabel}
            </StyledLabel>
            <StyledP
              className="ep__txt--md ep__txt-bold ep__txt-no-select ep--mb-0"
              font={fontFamily}
              style={{ color: defaultTextColor }}
            >
              {item.sameAsBillingAddress
                ? additionalAddressModels[index].checkboxLabel
                : `${item.firstName}${SPECIAL_CHARACTER.SPACE}${item.lastName}, ${formatAddress(
                    item.streetName + SPECIAL_CHARACTER.SPACE + item.streetNumber,
                    item.postalCode,
                    item.city,
                    item.country
                  )}`}
            </StyledP>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      additionalAddresses,
      additionalAddressFields,
      primaryTextColor,
      defaultTextColor,
      primaryColor,
      fontFamily,
      borderRadius,
      borderColorFocus,
      updateData
    } = this.props;
    const { additionalAddressModels } = additionalAddressFields;

    return _.map(additionalAddressModels, (item, index) => (
      <CustomCollapse
        zIndex={Z_INDEX.Z_INDEX_LEVEL_3 - index}
        title={item.sectionName}
        fontText={fontFamily}
        isCompleted={
          additionalAddresses[index].isValid !== null && additionalAddresses[index].isValid
        }
        onCollapse={this.onCollapse}
        completedContent={this.renderCompletedContent(additionalAddresses[index], index)}
        errorMessage={MESSAGE_CONSTANTS.CHECK_ALL_REQUIRED_FIELD}
        isError={additionalAddresses[index].isValid !== null && !additionalAddresses[index].isValid}
        key={index}
        defaultTextColor={defaultTextColor}
      >
        <div className="ep--border-radius-8 ep--p-32">
          <StyledP
            className="ep__txt--sm ep__txt-bold text-center"
            font={fontFamily}
            style={{ color: defaultTextColor }}
          >
            {item.description}
          </StyledP>
          <CheckBox
            className="ep--mt-32"
            onChange={() => {
              this.onCollapse();
              updateData(index, {
                sameAsBillingAddress: !additionalAddresses[index].sameAsBillingAddress
              });
            }}
            content={item.checkboxLabel}
            isChecked={additionalAddresses[index].sameAsBillingAddress}
            primaryTextColor={primaryTextColor}
            defaultTextColor={defaultTextColor}
            fontText={fontFamily}
            primaryCheckBoxIconColor={primaryColor}
          />
          <div className="row ep--mt-32">
            <ShowIfUtil condition={!additionalAddresses[index].sameAsBillingAddress}>
              <StyledLabel
                className="col-12 ep__txt--sm ep__txt-regular ep__txt-no-select"
                font={fontFamily}
                style={{ color: defaultTextColor }}
              >
                {item.addressFieldLabel}
                {item.requiredAddressFields && (
                  <StyledSpan
                    className="ep__txt--sm ep__txt-regular ep__txt-no-select"
                    font={fontFamily}
                    style={{ color: defaultTextColor }}
                  >
                    {' ' + SPECIAL_CHARACTER.ASTERISK}
                  </StyledSpan>
                )}
              </StyledLabel>
              <ShowIfUtil condition={item.salutation && item.salutation.active}>
                <div className="col-12 ep--mb-8">
                  <div className="row">
                    <div className="col-6">
                      <DropDown
                        borderRadius={borderRadius}
                        primaryTextColor={primaryTextColor}
                        defaultTextColor={defaultTextColor}
                        onItemSelect={value => updateData(index, { salutation: value })}
                        fontFamily={fontFamily}
                        options={getListSalutation()}
                        borderColorFocus={borderColorFocus}
                        styleClassText="ep__txt--md ep__txt-regular"
                      />
                      <ShowIfUtil
                        condition={
                          !!additionalAddresses[index].errorMessage.isShowErrorMessageSalutation
                        }
                      >
                        <div className="ep-text-red ep__txt--sm ep--mt-8">
                          <StyledSpan font={fontFamily}>{MESSAGE_CONSTANTS.REQUIRED}</StyledSpan>
                        </div>
                      </ShowIfUtil>
                    </div>
                  </div>
                </div>
              </ShowIfUtil>
              <ShowIfUtil condition={item.activeFullName}>
                <div className="col-6 ep--mb-8">
                  <Input
                    borderRadius={borderRadius}
                    primaryTextColor={primaryTextColor}
                    defaultTextColor={defaultTextColor}
                    require
                    onChange={value => updateData(index, { firstName: value })}
                    fontFamily={fontFamily}
                    borderColorFocus={borderColorFocus}
                    placeholder={item.firstName}
                    value={additionalAddresses[index].firstName}
                  />
                  <ShowIfUtil
                    condition={
                      !!additionalAddresses[index].errorMessage.isShowErrorMessageFirstName
                    }
                  >
                    <div className="ep-text-red ep__txt--sm ep--mt-8">
                      <StyledSpan font={fontFamily}>{MESSAGE_CONSTANTS.REQUIRED}</StyledSpan>
                    </div>
                  </ShowIfUtil>
                </div>
                <div className="col-6 ep--mb-8">
                  <Input
                    borderRadius={borderRadius}
                    primaryTextColor={primaryTextColor}
                    defaultTextColor={defaultTextColor}
                    require
                    onChange={value => updateData(index, { lastName: value })}
                    fontFamily={fontFamily}
                    borderColorFocus={borderColorFocus}
                    placeholder={item.lastName}
                    value={additionalAddresses[index].lastName}
                  />
                  <ShowIfUtil
                    condition={!!additionalAddresses[index].errorMessage.isShowErrorMessageLastName}
                  >
                    <div className="ep-text-red ep__txt--sm ep--mt-8">
                      <StyledSpan font={fontFamily}>{MESSAGE_CONSTANTS.REQUIRED}</StyledSpan>
                    </div>
                  </ShowIfUtil>
                </div>
              </ShowIfUtil>
              <ShowIfUtil condition={item.activeCompanyName}>
                <div className="col-12 ep--mb-8">
                  <Input
                    borderRadius={borderRadius}
                    primaryTextColor={primaryTextColor}
                    defaultTextColor={defaultTextColor}
                    require
                    onChange={value => updateData(index, { companyName: value })}
                    fontFamily={fontFamily}
                    borderColorFocus={borderColorFocus}
                    placeholder={item.companyName}
                    value={additionalAddresses[index].companyName}
                  />
                </div>
              </ShowIfUtil>
              <ShowIfUtil condition={item.activeEmail}>
                <div className="col-12 ep--mb-8">
                  <Input
                    borderRadius={borderRadius}
                    primaryTextColor={primaryTextColor}
                    defaultTextColor={defaultTextColor}
                    require
                    onChange={value => updateData(index, { email: value })}
                    fontFamily={fontFamily}
                    borderColorFocus={borderColorFocus}
                    placeholder={item.email}
                    value={additionalAddresses[index].email}
                  />
                  <ShowIfUtil
                    condition={!!additionalAddresses[index].errorMessage.isShowErrorMessageEmail}
                  >
                    <div className="ep-text-red ep__txt--sm ep--mt-8">
                      <StyledSpan font={fontFamily}>{MESSAGE_CONSTANTS.REQUIRED}</StyledSpan>
                    </div>
                  </ShowIfUtil>
                  <ShowIfUtil
                    condition={
                      !!additionalAddresses[index].errorMessage.isShowMessageInvalidEmailPattern
                    }
                  >
                    <div className="ep-text-red ep__txt--sm ep--mt-8">
                      <StyledSpan font={fontFamily}>{MESSAGE_CONSTANTS.EMAIL_INVALID}</StyledSpan>
                    </div>
                  </ShowIfUtil>
                </div>
              </ShowIfUtil>
              <ShowIfUtil condition={item.activePhone}>
                <div className="col-12 ep--mb-8">
                  <Input
                    borderRadius={borderRadius}
                    primaryTextColor={primaryTextColor}
                    defaultTextColor={defaultTextColor}
                    require
                    onChange={value => updateData(index, { phone: value })}
                    fontFamily={fontFamily}
                    borderColorFocus={borderColorFocus}
                    placeholder={item.phone}
                    value={additionalAddresses[index].phone}
                  />
                  <ShowIfUtil
                    condition={!!additionalAddresses[index].errorMessage.isShowErrorMessagePhone}
                  >
                    <div className="ep-text-red ep__txt--sm ep--mt-8">
                      <StyledSpan font={fontFamily}>{MESSAGE_CONSTANTS.REQUIRED}</StyledSpan>
                    </div>
                  </ShowIfUtil>
                </div>
              </ShowIfUtil>
              <ShowIfUtil condition={item.activeRegistrationDetails}>
                <div className="col-12 ep--mb-8">
                  <Input
                    borderRadius={borderRadius}
                    primaryTextColor={primaryTextColor}
                    defaultTextColor={defaultTextColor}
                    require
                    onChange={value => updateData(index, { registerCourt: value })}
                    fontFamily={fontFamily}
                    borderColorFocus={borderColorFocus}
                    placeholder={item.registerCourt}
                    value={additionalAddresses[index].registerCourt}
                  />
                  <ShowIfUtil
                    condition={
                      !!additionalAddresses[index].errorMessage.isShowErrorMessageRegisterCourt
                    }
                  >
                    <div className="ep-text-red ep__txt--sm ep--mt-8">
                      <StyledSpan font={fontFamily}>{MESSAGE_CONSTANTS.REQUIRED}</StyledSpan>
                    </div>
                  </ShowIfUtil>
                </div>
                <div className="col-12 ep--mb-8">
                  <Input
                    borderRadius={borderRadius}
                    primaryTextColor={primaryTextColor}
                    defaultTextColor={defaultTextColor}
                    require
                    onChange={value => updateData(index, { registerNumber: value })}
                    fontFamily={fontFamily}
                    borderColorFocus={borderColorFocus}
                    placeholder={item.registerNumber}
                    value={additionalAddresses[index].registerNumber}
                  />
                  <ShowIfUtil
                    condition={
                      !!additionalAddresses[index].errorMessage.isShowErrorMessageRegisterNumber
                    }
                  >
                    <div className="ep-text-red ep__txt--sm ep--mt-8">
                      <StyledSpan font={fontFamily}>{MESSAGE_CONSTANTS.REQUIRED}</StyledSpan>
                    </div>
                  </ShowIfUtil>
                </div>
              </ShowIfUtil>
              <div className="col-9 ep--mb-8">
                <Input
                  borderRadius={borderRadius}
                  primaryTextColor={primaryTextColor}
                  defaultTextColor={defaultTextColor}
                  require
                  onChange={value => updateData(index, { streetName: value })}
                  fontFamily={fontFamily}
                  borderColorFocus={borderColorFocus}
                  placeholder={item.streetName}
                  value={additionalAddresses[index].streetName}
                />
              </div>
              <div className="col-3 ep--mb-8">
                <Input
                  borderRadius={borderRadius}
                  primaryTextColor={primaryTextColor}
                  defaultTextColor={defaultTextColor}
                  require
                  onChange={value => updateData(index, { streetNumber: value })}
                  fontFamily={fontFamily}
                  borderColorFocus={borderColorFocus}
                  placeholder={item.streetNo}
                  value={additionalAddresses[index].streetNumber}
                />
              </div>
              <div className="col-6 ep--mb-8">
                <Input
                  borderRadius={borderRadius}
                  primaryTextColor={primaryTextColor}
                  defaultTextColor={defaultTextColor}
                  require
                  onChange={value => updateData(index, { postalCode: value })}
                  fontFamily={fontFamily}
                  borderColorFocus={borderColorFocus}
                  placeholder={item.postalCode}
                  value={additionalAddresses[index].postalCode}
                />
              </div>
              <div className="col-6 ep--mb-8">
                <Input
                  borderRadius={borderRadius}
                  primaryTextColor={primaryTextColor}
                  defaultTextColor={defaultTextColor}
                  require
                  onChange={value => updateData(index, { city: value })}
                  fontFamily={fontFamily}
                  borderColorFocus={borderColorFocus}
                  placeholder={item.cityOrTown}
                  value={additionalAddresses[index].city}
                />
              </div>
              <div className="col-12">
                <AutoComplete
                  disabled={!item.showCountryDropDown}
                  placeholder={item.country}
                  borderRadius={borderRadius}
                  primaryTextColor={primaryTextColor}
                  defaultTextColor={defaultTextColor}
                  require
                  onItemSelect={country => updateData(index, { country: country })}
                  fontFamily={fontFamily}
                  options={getListCountry()}
                  popoverClassName="autocomplete-popover-custom"
                  inputClassName="ep-autocomplete-input"
                  inputValue={additionalAddresses[index].country}
                  selectedValue={
                    additionalAddresses[index].country ||
                    getCountryGermanNameFromCode(item.defaultCountry) ||
                    getListCountry()[0]
                  }
                  borderColorFocus={borderColorFocus}
                  itemsLimited={false}
                />
              </div>
              <ShowIfUtil condition={!!additionalAddresses[index].errorMessage.address}>
                <div className="col-12 ep-text-red ep__txt--sm ep--mt-8">
                  <StyledSpan font={fontFamily}>
                    {additionalAddresses[index].errorMessage.addressErrorMessage}
                  </StyledSpan>
                </div>
              </ShowIfUtil>
            </ShowIfUtil>
          </div>
        </div>
      </CustomCollapse>
    ));
  }
}

AdditionalAddressesContainer.propTypes = {
  additionalAddressFields: PropTypes.object,
  primaryTextColor: PropTypes.string,
  defaultTextColor: PropTypes.string,
  fontFamily: PropTypes.object,
  primaryColor: PropTypes.string,
  borderRadius: PropTypes.string,
  borderColorFocus: PropTypes.string,
  additionalAddresses: PropTypes.array,
  updateData: PropTypes.func,
  setContactFormHeight: PropTypes.func
};

AdditionalAddressesContainer.defaultProps = {
  additionalAddressFields: {},
  primaryTextColor: COLOR.BLACK,
  defaultTextColor: COLOR.DARK_GRAY,
  fontFamily: {},
  primaryColor: COLOR.DEFAULT_BLUE,
  borderRadius: '4px',
  borderColorFocus: COLOR.DEFAULT_BLUE,
  additionalAddresses: [],
  updateData: () => ({}),
  setContactFormHeight: () => ({})
};

export default AdditionalAddressesContainer;

import _ from 'lodash';
import {
  convertProdTypeToProdName,
  formatPrice,
  isAllOnlinePurchaseProductPackagesSummaryV2,
  getSubTotalPrice,
  getAddOnV2TotalNetPrice,
  getTaxPricingSummary
} from '.';
import {
  GERMAN_TEXT,
  PAYMENT_FREQUENCY,
  SPECIAL_CHARACTER,
  ADD_ON_V2_PRODUCT_TYPE
} from '../constants';

const getSummaryLogInfo = (
  isOnlinePurchase,
  emailTemplateV2Data,
  totalPrice,
  totalPriceV2MixedOrder,
  selectedAddOnV2MapKeyByProductType
) => {
  let summaryInfo = {};
  if (isOnlinePurchase) {
    // mixed order
    const onlinePurchaseProductPackagesSummary = _.filter(
      emailTemplateV2Data.productPackagesSummary,
      item => !item.onRequest && !item.alwaysShowOnRequest
    );
    const orderRequestProductPackagesSummary = _.filter(
      emailTemplateV2Data.productPackagesSummary,
      item => item.onRequest || item.alwaysShowOnRequest
    );
    if (!_.isEmpty(onlinePurchaseProductPackagesSummary)) {
      summaryInfo.onlinePurchase = getRequestOnlinePurchase(
        false,
        isOnlinePurchase,
        onlinePurchaseProductPackagesSummary,
        emailTemplateV2Data,
        totalPriceV2MixedOrder,
        selectedAddOnV2MapKeyByProductType
      );
    }
    if (!_.isEmpty(orderRequestProductPackagesSummary)) {
      summaryInfo.orderRequest = getRequestOnlinePurchase(
        true,
        isOnlinePurchase,
        orderRequestProductPackagesSummary,
        emailTemplateV2Data,
        totalPriceV2MixedOrder,
        selectedAddOnV2MapKeyByProductType
      );
    }
  } else {
    // order summary
    summaryInfo.orderRequest = getRequestOnlinePurchase(
      false,
      isOnlinePurchase,
      emailTemplateV2Data.productPackagesSummary,
      emailTemplateV2Data,
      totalPrice,
      selectedAddOnV2MapKeyByProductType
    );
  }
  return summaryInfo;
};

const getRequestOnlinePurchase = (
  isOnRequest,
  isOnlinePurchase,
  productPackagesSummary,
  emailTemplateV2Data,
  totalPrice,
  selectedAddOnV2MapKeyByProductType
) => {
  return {
    productPackages: getProductPackages(productPackagesSummary, selectedAddOnV2MapKeyByProductType),
    globalAddOns:
      isOnRequest === false ? getGlobalAddOns(selectedAddOnV2MapKeyByProductType) : null,
    subTotal:
      isOnRequest === false
        ? getSubtotalPrice(emailTemplateV2Data, selectedAddOnV2MapKeyByProductType)
        : null,
    taxes:
      isOnRequest === false
        ? getTaxesPrice(emailTemplateV2Data, selectedAddOnV2MapKeyByProductType)
        : null,
    totalPrice: getTotalPriceResponse(
      isOnRequest,
      isOnlinePurchase,
      emailTemplateV2Data.productPackagesSummary,
      totalPrice
    )
  };
};

const getTotalPriceResponse = (
  isOnRequest,
  isOnlinePurchase,
  productPackagesSummary,
  totalPrice
) => {
  let totalPriceResponse = SPECIAL_CHARACTER.EMPTY;
  if (!isOnlinePurchase) {
    const isAllOnlinePurchaseProducts = isAllOnlinePurchaseProductPackagesSummaryV2(
      productPackagesSummary
    );
    totalPriceResponse = isAllOnlinePurchaseProducts
      ? getTotalPrice(totalPrice)
      : GERMAN_TEXT.ON_REQUEST;
  } else {
    totalPriceResponse = isOnRequest ? null : getTotalPrice(totalPrice);
  }
  return totalPriceResponse;
};

const getProductPackages = (productPackagesSummary, selectedAddOnV2MapKeyByProductType) => {
  let productPackages = [];
  _.forEach(productPackagesSummary, productPackageSummary => {
    let productPackage = {};
    productPackage.name = productPackageSummary.packageName
      ? productPackageSummary.packageName
      : convertProdTypeToProdName(productPackageSummary.productType);
    if (!productPackageSummary.alwaysShowOnRequest && !productPackageSummary.onRequest) {
      productPackage.value = getPrices(
        productPackageSummary.totalOneTimeNetRetailPrice,
        productPackageSummary.totalMonthlyNetRetailPrice,
        productPackageSummary.totalYearlyNetRetailPrice,
        false
      );
      productPackage.addOns = getProductAddOns(
        productPackageSummary,
        selectedAddOnV2MapKeyByProductType
      );
    } else {
      productPackage.value = GERMAN_TEXT.ON_REQUEST;
    }
    productPackages.push(productPackage);
  });
  return productPackages;
};

const getGlobalAddOns = selectedAddOnV2MapKeyByProductType => {
  let globalAddOns = [];
  if (
    selectedAddOnV2MapKeyByProductType[ADD_ON_V2_PRODUCT_TYPE.GLOBAL] &&
    selectedAddOnV2MapKeyByProductType[ADD_ON_V2_PRODUCT_TYPE.GLOBAL].length > 0
  ) {
    return addedAddOns(selectedAddOnV2MapKeyByProductType[ADD_ON_V2_PRODUCT_TYPE.GLOBAL]);
  }
  return globalAddOns;
};

const getProductAddOns = (productPackageSummary, selectedAddOnV2MapKeyByProductType) => {
  return addedAddOns(selectedAddOnV2MapKeyByProductType[productPackageSummary.productType]);
};

const addedAddOns = listAddedAddOn => {
  let addOns = [];
  _.map(listAddedAddOn, addedAddOn => {
    let addOn = {};
    addOn.name = addedAddOn.addOnName;
    if (addedAddOn.addOnSellingOption.showPriceOnFrontend) {
      addOn.value = GERMAN_TEXT.ON_REQUEST;
    } else {
      addOn.value = getPrices(
        addedAddOn.addOnSellingOption.oneTimeNetPrice,
        addedAddOn.addOnSellingOption.monthlyNetPrice,
        addedAddOn.addOnSellingOption.yearlyNetPrice,
        false
      );
    }
    addOns.push(addOn);
  });
  return addOns;
};

const getPrices = (oneTimePrice, monthlyPrice, yearlyPrice, isTotalPrice) => {
  let price = SPECIAL_CHARACTER.EMPTY;
  if (
    _.isNumber(oneTimePrice) &&
    _.isNumber(monthlyPrice) &&
    _.isNumber(yearlyPrice) &&
    oneTimePrice <= 0 &&
    monthlyPrice <= 0 &&
    yearlyPrice <= 0
  ) {
    return formatPrice(0);
  } else {
    if (_.isNumber(oneTimePrice)) {
      price = formatPrice(oneTimePrice > 0 ? oneTimePrice : 0);
    }
    if (_.isNumber(monthlyPrice)) {
      price +=
        addLineBreak(_.isNumber(oneTimePrice)) +
        SPECIAL_CHARACTER.PLUS +
        SPECIAL_CHARACTER.SPACE +
        formatPrice(monthlyPrice > 0 ? monthlyPrice : 0) +
        SPECIAL_CHARACTER.SPACE +
        addSpanClassForTotalPrice(GERMAN_TEXT.MONTHLY, isTotalPrice);
    }
    if (_.isNumber(yearlyPrice)) {
      price +=
        addLineBreak(_.isNumber(oneTimePrice) || _.isNumber(monthlyPrice)) +
        SPECIAL_CHARACTER.PLUS +
        SPECIAL_CHARACTER.SPACE +
        formatPrice(yearlyPrice > 0 ? yearlyPrice : 0) +
        SPECIAL_CHARACTER.SPACE +
        addSpanClassForTotalPrice(GERMAN_TEXT.YEARLY, isTotalPrice);
    }
  }

  return price;
};

const addLineBreak = condition => {
  return condition === true ? SPECIAL_CHARACTER.LINE_BREAK : SPECIAL_CHARACTER.EMPTY;
};

const addSpanClassForTotalPrice = (text, isTotalPrice) => {
  return isTotalPrice === true ? '<span class="ep-text-content-normal">' + text + '</span>' : text;
};

const getSubtotalPrice = (emailTemplateV2Data, selectedAddOnV2MapKeyByProductType) => {
  const addOnV2TotalNetPrice = getAddOnV2TotalNetPrice(selectedAddOnV2MapKeyByProductType);
  const subtotalPrice = getSubTotalPrice(
    emailTemplateV2Data.productPackagesSummary,
    addOnV2TotalNetPrice
  );

  if (!subtotalPrice.isOnRequest) {
    return getPrices(
      subtotalPrice.oneTimePrice,
      subtotalPrice.monthlyPrice,
      subtotalPrice.yearlyPrice,
      false
    );
  } else {
    return GERMAN_TEXT.ON_REQUEST;
  }
};

const getTaxesPrice = (emailTemplateV2Data, selectedAddOnV2MapKeyByProductType) => {
  const taxes = getTaxPricingSummary(
    selectedAddOnV2MapKeyByProductType,
    emailTemplateV2Data.productPackagesSummary
  );
  let taxesPrice = [];
  taxes.map(tax =>
    taxesPrice.push({
      name: `${tax.name}(${tax.rate}%)`,
      value: getPrices(
        tax.price[PAYMENT_FREQUENCY.ONE_TIME],
        tax.price[PAYMENT_FREQUENCY.MONTHLY],
        tax.price[PAYMENT_FREQUENCY.YEARLY],
        false
      )
    })
  );
  return taxesPrice;
};

const getTotalPrice = totalPrice => {
  return getPrices(totalPrice.oneTimePrice, totalPrice.monthlyPrice, totalPrice.yearlyPrice, true);
};

export { getSummaryLogInfo };

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as PropTypes from 'prop-types';
import { AutoComplete, Input } from '../../components/epilot-forms/common';
import {
  GET_STREET_NAMES_SUCCESS,
  getStreetNames,
  resetProductAvailability
} from '../../store/actions';
import {
  COLOR,
  SERVICE_REQUEST_STATUS,
  SPECIAL_CHARACTER,
  MESSAGE_CONSTANTS
} from '../../common/constants';

class AddressAutoCompleteContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      options: []
    };
  }

  componentDidMount() {
    const { frontendConfiguration, customerJourneyAnswer, getStreetNames } = this.props;
    const postalCodeCityArr = customerJourneyAnswer.postalCode.split(' ');
    if (_.isArray(postalCodeCityArr) && postalCodeCityArr.length >= 2) {
      const postalCode = postalCodeCityArr[0];
      const city = _.join(postalCodeCityArr.splice(1), ' ');
      const postalCodeModel = {
        frontend_token: frontendConfiguration.token,
        postal_code: postalCode,
        city: city
      };
      getStreetNames(postalCodeModel).then(action => {
        if (action.type === GET_STREET_NAMES_SUCCESS) {
          this.setState({
            options: action.payload
          });
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      customerJourneyAnswer: {
        address: { street, number }
      },
      resetProductAvailability,
      externalServiceRequestStatus
    } = this.props;
    const {
      customerJourneyAnswer: {
        address: { street: prevStreet, number: prevNumber }
      }
    } = prevProps;
    if (
      (street !== prevStreet || number !== prevNumber) &&
      externalServiceRequestStatus !== SERVICE_REQUEST_STATUS.NO_REQUEST
    ) {
      resetProductAvailability();
    }
  }

  onItemSelect = e => {
    const { onSelect } = this.props;
    onSelect(e);
  };

  onListFilter = (query, items) => {
    return items
      .filter(item => item.toLowerCase().substring(0, query.length) === query.toLowerCase())
      .slice(0, 50);
  };

  streetNotFoundOnchange = e => {
    const { streetNotFoundOnchange } = this.props;
    streetNotFoundOnchange('streetNotFound', e);
  };

  getStreetFromStore = () => {
    const { customerJourneyAnswer } = this.props;
    if (customerJourneyAnswer.address.hasOwnProperty('street')) {
      return customerJourneyAnswer.address.street;
    }
    if (customerJourneyAnswer.address.hasOwnProperty('streetNotFound')) {
      return customerJourneyAnswer.address.streetNotFound;
    }
    return SPECIAL_CHARACTER.EMPTY;
  };

  render() {
    const {
      fontText,
      frontendConfiguration,
      customerJourneyAnswer,
      onChange,
      defaultTextColor
    } = this.props;
    const { options } = this.state;
    return (
      <div
        className="row justify-content-center align-items-center pr-4"
        style={{
          maxWidth: '396px',
          margin: '0 auto'
        }}
      >
        <div className="col-10 pl-0 text-right" style={{ maxWidth: '300px' }}>
          <AutoComplete
            isAddress
            streetNotFoundOnchange={this.streetNotFoundOnchange}
            isStreetNotFound={customerJourneyAnswer.address.hasOwnProperty('streetNotFound')}
            selectedValue={this.getStreetFromStore()}
            placeholder={
              customerJourneyAnswer.address.hasOwnProperty('street')
                ? customerJourneyAnswer.address.street
                : 'Straße'
            }
            borderRadius={frontendConfiguration.borderRadius}
            borderColorFocus={frontendConfiguration.primaryColor}
            defaultTextColor={defaultTextColor}
            require
            onItemSelect={this.onItemSelect}
            fontFamily={fontText}
            options={options}
            inputStyle={{
              height: '56px',
              minWidth: '80px',
              maxWidth: '300px'
            }}
            customFilter={this.onListFilter}
            initialContent={MESSAGE_CONSTANTS.STREET_NOT_FOUND}
            itemsLimited
          />
        </div>
        <div className="col-2 pl-0 text-left">
          <Input
            isStreetNotFound={customerJourneyAnswer.address.hasOwnProperty('streetNotFound')}
            fontSize="14px"
            height="56px"
            width="80px"
            borderRadius={frontendConfiguration.borderRadius}
            label=""
            type="text"
            placeholder="Nr."
            require
            onChange={value => onChange('number', value)}
            value={
              customerJourneyAnswer.address.hasOwnProperty('number')
                ? customerJourneyAnswer.address.number
                : SPECIAL_CHARACTER.EMPTY
            }
            defaultTextColor={defaultTextColor}
            fontFamily={fontText}
            borderColorFocus={frontendConfiguration.primaryColor}
          />
        </div>
      </div>
    );
  }
}

AddressAutoCompleteContainer.propTypes = {
  frontendConfiguration: PropTypes.object,
  customerJourneyAnswer: PropTypes.object,
  fontText: PropTypes.object,
  onSelect: PropTypes.func,
  resetProductAvailability: PropTypes.func.isRequired,
  externalServiceRequestStatus: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  getStreetNames: PropTypes.func,
  defaultTextColor: PropTypes.string,
  streetNotFoundOnchange: PropTypes.func
};

AddressAutoCompleteContainer.defaultProps = {
  frontendConfiguration: {},
  customerJourneyAnswer: {},
  fontText: {},
  defaultTextColor: COLOR.DARK_GRAY,
  onSelect: () => ({}),
  onChange: () => ({}),
  getStreetNames: () => ({}),
  streetNotFoundOnchange: () => ({})
};

function mapStateToProps(state) {
  return {
    frontendConfiguration: state.frontendConfiguration,
    customerJourneyAnswer: state.customerJourneyAnswer,
    externalServiceRequestStatus: state.externalProductAvailability.externalServiceRequestStatus
  };
}

export default connect(
  mapStateToProps,
  { resetProductAvailability, getStreetNames }
)(AddressAutoCompleteContainer);

import _ from 'lodash';
import {
  ADD_MAPPING_LABEL,
  CREATE_CURRENT_PRODUCT_PACKAGES,
  DELETE_MAPPING_LABEL,
  RESET_CURRENT_STATE,
  RESET_CUSTOMER_JOURNEY_DATA,
  UPDATE_CHANGE_ADDRESS,
  UPDATE_CURR_PRODUCT_PACKAGE_CONFIG,
  UPDATE_CURRENT_PACKAGE,
  UPDATE_CURRENT_PACKAGE_MANUALLY,
  UPDATE_CURRENT_PACKAGE_ON_REQUEST,
  UPDATE_CURRENT_QUESTIONS,
  UPDATE_SHOW_ALL_PRODUCT_ON_REQUEST
} from '../actions';
import {
  CURRENCY,
  KW_CONFIG_KEY,
  KWH_CONFIG_KEY,
  LITTER_PER_SEC_CONFIG_KEY,
  METER_CONFIG_KEY,
  METER_STREET_FRONT_LENGTH_FOR_HC_WATER_CONFIG_KEY,
  HOUSING_UNIT_FOR_HC_WATER_CONFIG_KEY,
  SQUARE_METRE_PROPERTY_SIZE_FOR_HC_WATER_CONFIG_KEY,
  SQUARE_METRE_FLOOR_SIZE_FOR_HC_WATER_CONFIG_KEY,
  ON_REQUEST,
  PAYMENT_FREQUENCY,
  PRODUCT_TYPE,
  PRODUCT_TYPE_CURR_PACKAGE_MAPPING,
  VARIABLE
} from '../../common/constants';

const initState = {
  currQuestions: [],
  currQuestionIds: [],
  currQuestionMap: {},
  currQuestionIndexMap: {},
  currMappingLabels: {},
  currProductPackageConfig: {},
  isAddressChange: false,
  isShowAllProductOnRequest: false
};

export default (state = initState, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_PACKAGE_ON_REQUEST:
      return {
        ...state,
        [action.payload]: generateOnRequestPackage()
      };
    case UPDATE_CHANGE_ADDRESS:
      return {
        ...state,
        ...action.payload
      };
    case UPDATE_CURRENT_QUESTIONS:
      return {
        ...state,
        ...parseCurrQuestion(action.payload)
      };
    case CREATE_CURRENT_PRODUCT_PACKAGES:
      return {
        ...state,
        ...createCurrProductPackages(action.payload)
      };
    case UPDATE_CURRENT_PACKAGE:
      return {
        ...state,
        ...getAllProductTypePackageBaseOnCurrentConsumption({
          data: action.payload,
          currState: state
        })
      };
    case UPDATE_CURRENT_PACKAGE_MANUALLY:
      return {
        ...state,
        ...updateCurrProductPackageManually({
          data: action.payload,
          currState: state
        })
      };
    case UPDATE_CURR_PRODUCT_PACKAGE_CONFIG:
      return {
        ...state,
        currProductPackageConfig: action.payload
      };
    case ADD_MAPPING_LABEL:
      return {
        ...state,
        currMappingLabels: {
          ...state.currMappingLabels,
          ...action.payload
        }
      };
    case DELETE_MAPPING_LABEL:
      return {
        ...state,
        currMappingLabels: _.omit(state.currMappingLabels, action.payload)
      };
    case UPDATE_SHOW_ALL_PRODUCT_ON_REQUEST:
      return {
        ...state,
        isShowAllProductOnRequest: action.payload.isShowAllProductOnRequest || false
      };
    case RESET_CURRENT_STATE:
    case RESET_CUSTOMER_JOURNEY_DATA:
      return initState;
    default:
      return state;
  }
};

const generateOnRequestPackage = () => ({
  packageId: null,
  sellingOptionId: null,
  name: null,
  sellingOptionName: null,
  duration: null,
  durationUnit: null,
  monthlyPrice: ON_REQUEST,
  oneTimePrice: ON_REQUEST,
  yearlyPrice: ON_REQUEST,
  totalPrice: ON_REQUEST,
  pricingComponents: [],
  componentGroups: [],
  variableCompIds: []
});

const updateCurrProductPackageManually = ({ data, currState }) => {
  const {
    productType,
    kwConsumption,
    sellingOption,
    litterPerSecConsumption,
    meterConsumption,
    meterStreetFrontLengthForHCWaterConsumption,
    housingUnitForHCWaterConsumption,
    squareMetrePropertySizeForHCWaterConsumption,
    squareMetreFloorSizeForHCWaterConsumption,
    kwhConsumption,
    kwConsumptionHCGas,
    kwConsumptionHCHeating
  } = data;
  let productPackagePrice = {};
  let productPackagePriceByKWConsumption = {};
  let productPackagePriceByMeterConsumption = {};
  let productPackagePriceByMeterStreetFrontLengthForHCWaterConsumption = {};
  let productPackagePriceByHousingUnitForHCWaterConsumption = {};
  let productPackagePriceBySquareMetrePropertySizeForHCWaterConsumption = {};
  let productPackagePriceBySquareMetreFloorSizeForHCWaterConsumption = {};
  let productPackagePriceByLitterPerSecConsumption = {};
  let matchedPriceByKWConsumption;
  let matchedPriceByMeterConsumption;
  let matchedPriceByMeterStreetFrontLengthForHCWaterConsumption;
  let matchedPriceByHousingUnitForHCWaterConsumption;
  let matchedPriceBySquareMetrePropertySizeForHCWaterConsumption;
  let matchedPriceBySquareMetreFloorSizeForHCWaterConsumption;
  let matchedPriceByLitterPerSecConsumption;
  let consumption = null;
  switch (productType) {
    case PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY:
    case PRODUCT_TYPE.HOUSE_CONNECTION_GAS:
    case PRODUCT_TYPE.HOUSE_CONNECTION_HEAT:
      switch (productType) {
        case PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY:
          consumption = kwConsumption;
          break;
        case PRODUCT_TYPE.HOUSE_CONNECTION_GAS:
          consumption = kwConsumptionHCGas;
          break;
        case PRODUCT_TYPE.HOUSE_CONNECTION_HEAT:
          consumption = kwConsumptionHCHeating;
          break;
        default:
          break;
      }
      [
        productPackagePriceByKWConsumption,
        matchedPriceByKWConsumption
      ] = getProductPackagePriceByManuallySelectSellingOption({
        sellingOption,
        compConfigKey: KW_CONFIG_KEY,
        consumption
      });
      [
        productPackagePriceByMeterConsumption,
        matchedPriceByMeterConsumption
      ] = getProductPackagePriceByManuallySelectSellingOption({
        sellingOption,
        compConfigKey: METER_CONFIG_KEY,
        consumption: meterConsumption
      });
      if (
        productPackagePriceByMeterConsumption.oneTimePrice === ON_REQUEST ||
        productPackagePriceByKWConsumption.oneTimePrice === ON_REQUEST
      ) {
        productPackagePrice =
          productPackagePriceByMeterConsumption.oneTimePrice === ON_REQUEST
            ? productPackagePriceByMeterConsumption
            : productPackagePriceByKWConsumption;
      } else {
        if (
          productPackagePriceByMeterConsumption.isUsingStaticPrice &&
          productPackagePriceByKWConsumption.isUsingStaticPrice
        ) {
          productPackagePrice = productPackagePriceByKWConsumption;
        } else if (
          !productPackagePriceByKWConsumption.isUsingStaticPrice &&
          !productPackagePriceByMeterConsumption.isUsingStaticPrice
        ) {
          const staticPrice = parseStaticPrice(sellingOption);
          const totalVariablePrice = sumAllMatchedPriceOfSameSellingOption({
            firstPricingComp: matchedPriceByKWConsumption,
            secondPricingComp: matchedPriceByMeterConsumption
          });
          productPackagePrice = sumAllMatchedPriceOfSameSellingOption({
            firstPricingComp: totalVariablePrice,
            secondPricingComp: staticPrice
          });
        } else {
          productPackagePrice = productPackagePriceByMeterConsumption.isUsingStaticPrice
            ? productPackagePriceByKWConsumption
            : productPackagePriceByMeterConsumption;
        }
      }
      break;
    case PRODUCT_TYPE.HOUSE_CONNECTION_WATER:
      [
        productPackagePriceByLitterPerSecConsumption,
        matchedPriceByLitterPerSecConsumption
      ] = getProductPackagePriceByManuallySelectSellingOption({
        sellingOption,
        compConfigKey: LITTER_PER_SEC_CONFIG_KEY,
        consumption: litterPerSecConsumption
      });
      [
        productPackagePriceByMeterConsumption,
        matchedPriceByMeterConsumption
      ] = getProductPackagePriceByManuallySelectSellingOption({
        sellingOption,
        compConfigKey: METER_CONFIG_KEY,
        consumption: meterConsumption
      });
      [
        productPackagePriceByMeterStreetFrontLengthForHCWaterConsumption,
        matchedPriceByMeterStreetFrontLengthForHCWaterConsumption
      ] = getProductPackagePriceByManuallySelectSellingOption({
        sellingOption,
        compConfigKey: METER_STREET_FRONT_LENGTH_FOR_HC_WATER_CONFIG_KEY,
        consumption: meterStreetFrontLengthForHCWaterConsumption
      });
      [
        productPackagePriceByHousingUnitForHCWaterConsumption,
        matchedPriceByHousingUnitForHCWaterConsumption
      ] = getProductPackagePriceByManuallySelectSellingOption({
        sellingOption,
        compConfigKey: HOUSING_UNIT_FOR_HC_WATER_CONFIG_KEY,
        consumption: housingUnitForHCWaterConsumption
      });
      [
        productPackagePriceBySquareMetrePropertySizeForHCWaterConsumption,
        matchedPriceBySquareMetrePropertySizeForHCWaterConsumption
      ] = getProductPackagePriceByManuallySelectSellingOption({
        sellingOption,
        compConfigKey: SQUARE_METRE_PROPERTY_SIZE_FOR_HC_WATER_CONFIG_KEY,
        consumption: squareMetrePropertySizeForHCWaterConsumption
      });
      [
        productPackagePriceBySquareMetreFloorSizeForHCWaterConsumption,
        matchedPriceBySquareMetreFloorSizeForHCWaterConsumption
      ] = getProductPackagePriceByManuallySelectSellingOption({
        sellingOption,
        compConfigKey: SQUARE_METRE_FLOOR_SIZE_FOR_HC_WATER_CONFIG_KEY,
        consumption: squareMetreFloorSizeForHCWaterConsumption
      });
      if (
        productPackagePriceByMeterConsumption.oneTimePrice === ON_REQUEST ||
        productPackagePriceByLitterPerSecConsumption.oneTimePrice === ON_REQUEST ||
        productPackagePriceByMeterStreetFrontLengthForHCWaterConsumption.oneTimePrice ===
          ON_REQUEST ||
        productPackagePriceByHousingUnitForHCWaterConsumption.oneTimePrice === ON_REQUEST ||
        productPackagePriceBySquareMetrePropertySizeForHCWaterConsumption.oneTimePrice ===
          ON_REQUEST ||
        productPackagePriceBySquareMetreFloorSizeForHCWaterConsumption.oneTimePrice === ON_REQUEST
      ) {
        if (productPackagePriceByMeterConsumption.oneTimePrice === ON_REQUEST) {
          productPackagePrice = productPackagePriceByMeterConsumption;
        } else if (productPackagePriceByLitterPerSecConsumption.oneTimePrice === ON_REQUEST) {
          productPackagePrice = productPackagePriceByLitterPerSecConsumption;
        } else if (
          productPackagePriceByMeterStreetFrontLengthForHCWaterConsumption.oneTimePrice ===
          ON_REQUEST
        ) {
          productPackagePrice = productPackagePriceByMeterStreetFrontLengthForHCWaterConsumption;
        } else if (
          productPackagePriceByHousingUnitForHCWaterConsumption.oneTimePrice === ON_REQUEST
        ) {
          productPackagePrice = productPackagePriceByHousingUnitForHCWaterConsumption;
        } else if (
          productPackagePriceBySquareMetrePropertySizeForHCWaterConsumption.oneTimePrice ===
          ON_REQUEST
        ) {
          productPackagePrice = productPackagePriceBySquareMetrePropertySizeForHCWaterConsumption;
        } else {
          productPackagePrice = productPackagePriceBySquareMetreFloorSizeForHCWaterConsumption;
        }
      } else {
        if (
          productPackagePriceByMeterConsumption.isUsingStaticPrice &&
          productPackagePriceByMeterStreetFrontLengthForHCWaterConsumption.isUsingStaticPrice &&
          productPackagePriceByLitterPerSecConsumption.isUsingStaticPrice &&
          productPackagePriceByHousingUnitForHCWaterConsumption.isUsingStaticPrice &&
          productPackagePriceBySquareMetrePropertySizeForHCWaterConsumption.isUsingStaticPrice &&
          productPackagePriceBySquareMetreFloorSizeForHCWaterConsumption.isUsingStaticPrice
        ) {
          productPackagePrice = productPackagePriceByLitterPerSecConsumption;
        } else if (
          !productPackagePriceByLitterPerSecConsumption.isUsingStaticPrice &&
          !productPackagePriceByMeterStreetFrontLengthForHCWaterConsumption.isUsingStaticPrice &&
          !productPackagePriceByMeterConsumption.isUsingStaticPrice &&
          !productPackagePriceByHousingUnitForHCWaterConsumption.isUsingStaticPrice &&
          !productPackagePriceBySquareMetrePropertySizeForHCWaterConsumption.isUsingStaticPrice &&
          !productPackagePriceBySquareMetreFloorSizeForHCWaterConsumption.isUsingStaticPrice
        ) {
          const staticPrice = parseStaticPrice(sellingOption);
          let tempTotalVariablePrice = {};
          tempTotalVariablePrice = sumAllMatchedPriceOfSameSellingOption({
            firstPricingComp: matchedPriceByLitterPerSecConsumption,
            secondPricingComp: matchedPriceBySquareMetrePropertySizeForHCWaterConsumption
          });
          tempTotalVariablePrice = sumAllMatchedPriceOfSameSellingOption({
            firstPricingComp: tempTotalVariablePrice,
            secondPricingComp: matchedPriceBySquareMetreFloorSizeForHCWaterConsumption
          });
          tempTotalVariablePrice = sumAllMatchedPriceOfSameSellingOption({
            firstPricingComp: tempTotalVariablePrice,
            secondPricingComp: matchedPriceByHousingUnitForHCWaterConsumption
          });
          tempTotalVariablePrice = sumAllMatchedPriceOfSameSellingOption({
            firstPricingComp: tempTotalVariablePrice,
            secondPricingComp: matchedPriceByMeterStreetFrontLengthForHCWaterConsumption
          });
          tempTotalVariablePrice = sumAllMatchedPriceOfSameSellingOption({
            firstPricingComp: tempTotalVariablePrice,
            secondPricingComp: matchedPriceByMeterConsumption
          });
          productPackagePrice = sumAllMatchedPriceOfSameSellingOption({
            firstPricingComp: tempTotalVariablePrice,
            secondPricingComp: staticPrice
          });
        } else {
          const staticPrice = parseStaticPrice(sellingOption);
          let tempProductPackagePrice = null;

          if (!productPackagePriceByMeterConsumption.isUsingStaticPrice) {
            tempProductPackagePrice = getMatchedPriceOfConsumption({
              pricingComp: matchedPriceByMeterConsumption
            });
          }

          if (
            !productPackagePriceByMeterStreetFrontLengthForHCWaterConsumption.isUsingStaticPrice
          ) {
            tempProductPackagePrice = tempProductPackagePrice
              ? sumAllMatchedPriceOfSameSellingOption({
                  firstPricingComp: tempProductPackagePrice,
                  secondPricingComp: matchedPriceByMeterStreetFrontLengthForHCWaterConsumption
                })
              : getMatchedPriceOfConsumption({
                  pricingComp: matchedPriceByMeterStreetFrontLengthForHCWaterConsumption
                });
          }

          if (!productPackagePriceByHousingUnitForHCWaterConsumption.isUsingStaticPrice) {
            tempProductPackagePrice = tempProductPackagePrice
              ? sumAllMatchedPriceOfSameSellingOption({
                  firstPricingComp: tempProductPackagePrice,
                  secondPricingComp: matchedPriceByHousingUnitForHCWaterConsumption
                })
              : getMatchedPriceOfConsumption({
                  pricingComp: matchedPriceByHousingUnitForHCWaterConsumption
                });
          }

          if (
            !productPackagePriceBySquareMetrePropertySizeForHCWaterConsumption.isUsingStaticPrice
          ) {
            tempProductPackagePrice = tempProductPackagePrice
              ? sumAllMatchedPriceOfSameSellingOption({
                  firstPricingComp: tempProductPackagePrice,
                  secondPricingComp: matchedPriceBySquareMetrePropertySizeForHCWaterConsumption
                })
              : getMatchedPriceOfConsumption({
                  pricingComp: matchedPriceBySquareMetrePropertySizeForHCWaterConsumption
                });
          }

          if (!productPackagePriceBySquareMetreFloorSizeForHCWaterConsumption.isUsingStaticPrice) {
            tempProductPackagePrice = tempProductPackagePrice
              ? sumAllMatchedPriceOfSameSellingOption({
                  firstPricingComp: tempProductPackagePrice,
                  secondPricingComp: matchedPriceBySquareMetreFloorSizeForHCWaterConsumption
                })
              : getMatchedPriceOfConsumption({
                  pricingComp: matchedPriceBySquareMetreFloorSizeForHCWaterConsumption
                });
          }

          if (!productPackagePriceByLitterPerSecConsumption.isUsingStaticPrice) {
            tempProductPackagePrice = tempProductPackagePrice
              ? sumAllMatchedPriceOfSameSellingOption({
                  firstPricingComp: tempProductPackagePrice,
                  secondPricingComp: matchedPriceByLitterPerSecConsumption
                })
              : getMatchedPriceOfConsumption({
                  pricingComp: matchedPriceByLitterPerSecConsumption
                });
          }

          productPackagePrice = sumAllMatchedPriceOfSameSellingOption({
            firstPricingComp: tempProductPackagePrice,
            secondPricingComp: staticPrice
          });
        }
      }
      break;
    case PRODUCT_TYPE.CHP:
    case PRODUCT_TYPE.HEATING:
    case PRODUCT_TYPE.CHARGE:
      [productPackagePrice] = getProductPackagePriceByManuallySelectSellingOption({
        sellingOption,
        compConfigKey: KW_CONFIG_KEY,
        consumption: kwConsumption
      });
      break;
    case PRODUCT_TYPE.HOUSE_CONNECTION_BROADBAND:
      [productPackagePrice] = getProductPackagePriceByManuallySelectSellingOption({
        sellingOption,
        compConfigKey: METER_CONFIG_KEY,
        consumption: meterConsumption
      });
      break;
    case PRODUCT_TYPE.SOLAR:
    case PRODUCT_TYPE.STORAGE:
    case PRODUCT_TYPE.POWER:
    case PRODUCT_TYPE.GAS:
      [productPackagePrice] = getProductPackagePriceByManuallySelectSellingOption({
        sellingOption,
        compConfigKey: KWH_CONFIG_KEY,
        consumption: kwhConsumption
      });
      break;
    case PRODUCT_TYPE.CARSHARING:
    case PRODUCT_TYPE.HEATPUMP:
      [productPackagePrice] = getProductPackagePriceByManuallySelectSellingOption({
        sellingOption
      });
      break;
    case PRODUCT_TYPE.SOLAR_B2B:
    case PRODUCT_TYPE.CHARGE_B2B:
      [productPackagePrice] = getProductPackagePriceByManuallySelectSellingOption({
        sellingOption,
        compConfigKey: KW_CONFIG_KEY,
        consumption: kwConsumption
      });
      break;
    default:
      break;
  }

  return {
    [PRODUCT_TYPE_CURR_PACKAGE_MAPPING[productType]]: {
      ...currState[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[productType]],
      ...productPackagePrice
    }
  };
};

const getProductPackagePriceByManuallySelectSellingOption = ({
  sellingOption,
  compConfigKey,
  consumption
}) => {
  let productPackagePrice;
  let matchedPrice = getMatchedPrice({
    sellingOption,
    compConfigKey,
    consumption
  });
  if (matchedPrice) {
    if (!matchedPrice.isUsingStaticPrice) {
      const staticPrice = parseStaticPrice(sellingOption);
      productPackagePrice = sumAllMatchedPriceOfSameSellingOption({
        firstPricingComp: matchedPrice,
        secondPricingComp: staticPrice
      });
    } else {
      productPackagePrice = matchedPrice;
    }
  } else {
    productPackagePrice = {
      packageId: sellingOption.packageId,
      sellingOptionId: sellingOption.sellingOptionId,
      name: sellingOption.packageName,
      sellingOptionName: sellingOption.title,
      duration: sellingOption.duration,
      durationUnit: sellingOption.durationUnit,
      monthlyPrice: ON_REQUEST,
      oneTimePrice: ON_REQUEST,
      yearlyPrice: ON_REQUEST,
      totalPrice: ON_REQUEST,
      pricingComponents: [],
      componentGroups: [],
      variableCompIds: []
    };
  }
  return [productPackagePrice, matchedPrice];
};

const generateCurrQuestionIndexMap = currQuestionIds => {
  let currQuestionIndexMap = {};
  _.forEach(currQuestionIds, (elem, index) => {
    currQuestionIndexMap[elem] = index;
  });
  return currQuestionIndexMap;
};

const parseCurrQuestion = questions => {
  let currQuestions = questions ? _.filter(questions, question => question.enable) : [];

  const currQuestionIds = _.map(currQuestions, 'id');

  const currQuestionMap = _.keyBy(currQuestions, 'id');

  const currQuestionIndexMap = generateCurrQuestionIndexMap(currQuestionIds);

  return {
    currQuestions,
    currQuestionIds,
    currQuestionMap,
    currQuestionIndexMap
  };
};

const createCurrProductPackages = ({ productPackageConfig, products }) => {
  let currProductPackages = {};
  _.forEach(products, product => {
    const currPackageConfig = productPackageConfig[product.type];
    const {
      packageMap,
      sellingOptionMap,
      cheapestSellingOptionId,
      cheapestPackageId
    } = currPackageConfig;
    // product do not config package, return
    if (_.isEmpty(packageMap)) return;
    currProductPackages[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[product.type]] = {
      packageId: cheapestPackageId,
      sellingOptionId: cheapestSellingOptionId,
      sellingOptionName: sellingOptionMap[cheapestSellingOptionId].title,
      duration: sellingOptionMap[cheapestSellingOptionId].duration,
      durationUnit: sellingOptionMap[cheapestSellingOptionId].durationUnit,
      name: packageMap[cheapestPackageId].packageName,
      ...parseStaticPrice(sellingOptionMap[cheapestSellingOptionId])
    };
  });
  return currProductPackages;
};

const parseStaticPrice = sellingOption => {
  const {
    staticCompConfig: { pricingConfig }
  } = sellingOption;
  let pricingComponents = [];
  let currOneTimePricingConfig = pricingConfig[PAYMENT_FREQUENCY.ONE_TIME];
  let currMonthlyPricingConfig = pricingConfig[PAYMENT_FREQUENCY.MONTHLY];
  let currYearlyPricingConfig = pricingConfig[PAYMENT_FREQUENCY.YEARLY];

  currOneTimePricingConfig && pricingComponents.push(...currOneTimePricingConfig.pricingComps);
  currMonthlyPricingConfig && pricingComponents.push(...currMonthlyPricingConfig.pricingComps);
  currYearlyPricingConfig && pricingComponents.push(...currYearlyPricingConfig.pricingComps);

  let staticPrice = {
    oneTimePrice: currOneTimePricingConfig
      ? Number(currOneTimePricingConfig.totalPrice.toFixed(2))
      : null,
    monthlyPrice: currMonthlyPricingConfig
      ? Number(currMonthlyPricingConfig.totalPrice.toFixed(2))
      : null,
    yearlyPrice: currYearlyPricingConfig
      ? Number(currYearlyPricingConfig.totalPrice.toFixed(2))
      : null,
    pricingComponents: pricingComponents,
    variableCompIds: []
  };

  if (_.isEmpty(pricingComponents)) {
    //this package do not contain static comp, set default one time price is 0
    staticPrice.oneTimePrice = 0;
    staticPrice.monthlyPrice = 0;
    staticPrice.yearlyPrice = 0;
  }
  return staticPrice;
};

const calcPricePaymentFrequencyGroup = ({ pricingComponent }) => {
  const totalPriceByPaymentFrequency = {
    oneTimePrice: null,
    monthlyPrice: null,
    yearlyPrice: null
  };
  let price = _.isNumber(pricingComponent.realPrice)
    ? pricingComponent.realPrice
    : pricingComponent.price;

  switch (pricingComponent.paymentFrequency) {
    case PAYMENT_FREQUENCY.ONE_TIME:
      totalPriceByPaymentFrequency.oneTimePrice =
        totalPriceByPaymentFrequency.oneTimePrice +
        (pricingComponent.currency === CURRENCY.EURO ? price : price / 100);
      break;
    case PAYMENT_FREQUENCY.MONTHLY:
      totalPriceByPaymentFrequency.monthlyPrice =
        totalPriceByPaymentFrequency.monthlyPrice +
        (pricingComponent.currency === CURRENCY.EURO ? price : price / 100);
      break;
    case PAYMENT_FREQUENCY.YEARLY:
      totalPriceByPaymentFrequency.yearlyPrice =
        totalPriceByPaymentFrequency.yearlyPrice +
        (pricingComponent.currency === CURRENCY.EURO ? price : price / 100);
      break;
    default:
      break;
  }
  return {
    ...totalPriceByPaymentFrequency
  };
};

const getMatchedPriceOfConsumption = ({ pricingComp }) => {
  let totalPricingComponents = [];
  if (!pricingComp.isUsingStaticPrice) {
    totalPricingComponents = [...pricingComp.pricingComponents];
  }
  return {
    packageId: pricingComp.packageId,
    sellingOptionId: pricingComp.sellingOptionId,
    name: pricingComp.name,
    sellingOptionName: pricingComp.sellingOptionName,
    duration: pricingComp.duration,
    durationUnit: pricingComp.durationUnit,
    pricingComponents: totalPricingComponents,
    variableCompIds: [...pricingComp.variableCompIds],
    ...sumPricingComponents(totalPricingComponents)
  };
};

const sumAllMatchedPriceOfSameSellingOption = ({ firstPricingComp, secondPricingComp }) => {
  let totalPricingComponents = [];
  if (!firstPricingComp.isUsingStaticPrice) {
    totalPricingComponents = [...firstPricingComp.pricingComponents];
  }
  if (!secondPricingComp.isUsingStaticPrice) {
    totalPricingComponents = [...totalPricingComponents, ...secondPricingComp.pricingComponents];
  }
  return {
    packageId: firstPricingComp.packageId,
    sellingOptionId: firstPricingComp.sellingOptionId,
    name: firstPricingComp.name,
    sellingOptionName: firstPricingComp.sellingOptionName,
    duration: firstPricingComp.duration,
    durationUnit: firstPricingComp.durationUnit,
    pricingComponents: totalPricingComponents,
    variableCompIds: [...firstPricingComp.variableCompIds, ...secondPricingComp.variableCompIds],
    ...sumPricingComponents(totalPricingComponents)
  };
};

const sumPricingComponents = pricingComponents => {
  let totalPriceGroup = {
    oneTimePrice: null,
    monthlyPrice: null,
    yearlyPrice: null
  };
  _.forEach(pricingComponents, pricingComponent => {
    const tmpPriceGroup = calcPricePaymentFrequencyGroup({ pricingComponent });
    if (tmpPriceGroup.oneTimePrice !== null || totalPriceGroup.oneTimePrice !== null) {
      totalPriceGroup.oneTimePrice = totalPriceGroup.oneTimePrice + tmpPriceGroup.oneTimePrice;
    }
    if (tmpPriceGroup.monthlyPrice !== null || totalPriceGroup.monthlyPrice !== null) {
      totalPriceGroup.monthlyPrice = totalPriceGroup.monthlyPrice + tmpPriceGroup.monthlyPrice;
    }
    if (tmpPriceGroup.yearlyPrice !== null || totalPriceGroup.yearlyPrice !== null) {
      totalPriceGroup.yearlyPrice = totalPriceGroup.yearlyPrice + tmpPriceGroup.yearlyPrice;
    }
  });
  let totalPrice = null;
  if (totalPriceGroup.oneTimePrice !== null) {
    totalPrice = totalPrice + totalPriceGroup.oneTimePrice;
  }
  if (totalPriceGroup.monthlyPrice !== null) {
    totalPrice = totalPrice + totalPriceGroup.monthlyPrice;
  }
  if (totalPriceGroup.yearlyPrice !== null) {
    totalPrice = totalPrice + totalPriceGroup.yearlyPrice;
  }
  return {
    ...totalPriceGroup,
    totalPrice
  };
};

const calcVariablePrice = ({ noRange, toInfinityRange, specificRange, finalConsumption }) => {
  let calculatedVariablePrice = {
    pricingComponents: [],
    variableCompIds: []
  };
  // Get all pricing component matched with range condition
  _.forIn(noRange, elem => {
    elem.compId && calculatedVariablePrice.variableCompIds.push(elem.compId);
    let tmpPricingComponent = { ...elem.pricingComponent };
    if (elem.type === VARIABLE) {
      tmpPricingComponent.realPrice = finalConsumption * tmpPricingComponent.price;
      tmpPricingComponent.netRealPrice = finalConsumption * tmpPricingComponent.netPrice;
    } else {
      tmpPricingComponent.realPrice = elem.quantity * tmpPricingComponent.price;
      tmpPricingComponent.netRealPrice = elem.quantity * tmpPricingComponent.netPrice;
    }
    elem.pricingComponent && calculatedVariablePrice.pricingComponents.push(tmpPricingComponent);
  });
  // Get all pricing component matched with range condition
  _.forIn(toInfinityRange, elem => {
    let lowerBoundCondition;
    if (elem.from === 0) {
      lowerBoundCondition = finalConsumption >= elem.from;
    } else {
      lowerBoundCondition = finalConsumption > elem.from;
    }
    if (lowerBoundCondition) {
      elem.compId && calculatedVariablePrice.variableCompIds.push(elem.compId);
      let tmpPricingComponent = { ...elem.pricingComponent };
      if (elem.type === VARIABLE) {
        tmpPricingComponent.realPrice = (finalConsumption - elem.from) * tmpPricingComponent.price;
        tmpPricingComponent.netRealPrice =
          (finalConsumption - elem.from) * tmpPricingComponent.netPrice;
      } else {
        tmpPricingComponent.realPrice = elem.quantity * tmpPricingComponent.price;
        tmpPricingComponent.netRealPrice = elem.quantity * tmpPricingComponent.netPrice;
      }
      elem.pricingComponent && calculatedVariablePrice.pricingComponents.push(tmpPricingComponent);
    }
  });
  // Get all pricing component matched with range condition
  _.forIn(specificRange, elem => {
    let lowerBoundCondition;
    if (elem.from === 0) {
      lowerBoundCondition = finalConsumption >= elem.from;
    } else {
      lowerBoundCondition = finalConsumption > elem.from;
    }
    if (lowerBoundCondition && finalConsumption <= elem.to) {
      elem.compId && calculatedVariablePrice.variableCompIds.push(elem.compId);
      let tmpPricingComponent = { ...elem.pricingComponent };
      if (elem.type === VARIABLE) {
        tmpPricingComponent.realPrice = (finalConsumption - elem.from) * tmpPricingComponent.price;
        tmpPricingComponent.netRealPrice =
          (finalConsumption - elem.from) * tmpPricingComponent.netPrice;
      } else {
        tmpPricingComponent.realPrice = elem.quantity * tmpPricingComponent.price;
        tmpPricingComponent.netRealPrice = elem.quantity * tmpPricingComponent.netPrice;
      }
      elem.pricingComponent && calculatedVariablePrice.pricingComponents.push(tmpPricingComponent);
    }
  });

  // sum all pricing component price group by payment frequently
  calculatedVariablePrice = {
    ...calculatedVariablePrice,
    ...sumPricingComponents(calculatedVariablePrice.pricingComponents)
  };

  let totalPrice = null;
  if (calculatedVariablePrice.oneTimePrice !== null) {
    totalPrice = totalPrice + calculatedVariablePrice.oneTimePrice;
  }

  if (calculatedVariablePrice.monthlyPrice !== null) {
    totalPrice = totalPrice + calculatedVariablePrice.monthlyPrice;
  }

  if (calculatedVariablePrice.yearlyPrice !== null) {
    totalPrice = totalPrice + calculatedVariablePrice.yearlyPrice;
  }

  return {
    ...calculatedVariablePrice,
    totalPrice
  };
};

const calcMatchedPriceOfVariableComponent = ({
  sellingOption,
  compConfigKey,
  currentConsumption
}) => {
  const {
    [compConfigKey]: { specificRange, toInfinityRange, noRange }
  } = sellingOption;
  const variablePrice = calcVariablePrice({
    noRange,
    toInfinityRange,
    specificRange,
    finalConsumption: Number(currentConsumption)
  });
  if (variablePrice.totalPrice !== null) {
    // there is matched selling option
    return {
      sellingOptionId: Number(sellingOption.sellingOptionId),
      packageId: Number(sellingOption.packageId),
      name: sellingOption.packageName,
      duration: sellingOption.duration,
      durationUnit: sellingOption.durationUnit,
      sellingOptionName: sellingOption.title,
      ...variablePrice
    };
  }
  return null;
};

const getMatchedPrice = ({ sellingOption, compConfigKey, consumption }) => {
  if (sellingOption[compConfigKey]) {
    // there is variable component config, calculate matched selling option by variable component
    return calcMatchedPriceOfVariableComponent({
      sellingOption,
      compConfigKey,
      currentConsumption: consumption
    });
  } else {
    // no variable component config, auto matched selling option, use static component
    if (_.isEmpty(sellingOption.staticCompConfig.staticComps)) {
      // no static component config, use default static component
      return {
        packageId: sellingOption.packageId,
        sellingOptionId: sellingOption.sellingOptionId,
        name: sellingOption.packageName,
        sellingOptionName: sellingOption.title,
        duration: sellingOption.duration,
        durationUnit: sellingOption.durationUnit,
        oneTimePrice: 0,
        monthlyPrice: 0,
        yearlyPrice: 0,
        totalPrice: 0,
        pricingComponents: [],
        variableCompIds: [],
        isUsingStaticPrice: true
      };
    } else {
      return {
        packageId: sellingOption.packageId,
        sellingOptionId: sellingOption.sellingOptionId,
        name: sellingOption.packageName,
        sellingOptionName: sellingOption.title,
        duration: sellingOption.duration,
        durationUnit: sellingOption.durationUnit,
        ...parseStaticPrice(sellingOption),
        totalPrice: sellingOption.staticCompConfig.totalPrice,
        variableCompIds: [],
        isUsingStaticPrice: true
      };
    }
  }
};

const getMatchedPrices = ({ sellingOptionMap, compConfigKey, consumption }) => {
  let matchedPrice;
  let matchedPrices = [];
  _.forIn(sellingOptionMap, sellingOption => {
    matchedPrice = getMatchedPrice({ sellingOption, compConfigKey, consumption });
    if (matchedPrice) {
      matchedPrices.push(matchedPrice);
    }
  });
  return matchedPrices;
};

const calcFinalProductPackagePrice = ({
  matchedPrices,
  sellingOptionMap,
  productType,
  currPackage,
  variableConfigKeys
}) => {
  let finalProductPackagePrice = {};
  if (matchedPrices === null) {
    // no variable configured, return current package
    finalProductPackagePrice[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[productType]] = {
      ...currPackage
    };
  } else if (_.isEmpty(matchedPrices)) {
    // no selling option matched, return on request
    finalProductPackagePrice[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[productType]] = {
      ...currPackage,
      monthlyPrice: ON_REQUEST,
      oneTimePrice: ON_REQUEST,
      yearlyPrice: ON_REQUEST,
      totalPrice: ON_REQUEST,
      pricingComponents: [],
      variableCompIds: []
    };
  } else {
    let tmpFinalProductPackages = [];
    _.forEach(matchedPrices, matchedPrice => {
      if (!matchedPrice.isUsingStaticPrice) {
        const staticPrice = parseStaticPrice(sellingOptionMap[matchedPrice.sellingOptionId]);
        tmpFinalProductPackages.push({
          ...sumAllMatchedPriceOfSameSellingOption({
            firstPricingComp: matchedPrice,
            secondPricingComp: staticPrice
          })
        });
      } else {
        tmpFinalProductPackages.push(matchedPrice);
      }
    });
    const staticPackageSellingOptionNoVariableComponentConfig = getStaticPriceOfNoVariableComponentSellingOption(
      {
        variableConfigKeys,
        sellingOptionMap
      }
    );
    const totalPriceBySellingOptionId = {};
    _.forEach(tmpFinalProductPackages, item => {
      totalPriceBySellingOptionId[item.sellingOptionId] = {
        oneTimePrice: item.oneTimePrice,
        monthlyPrice: item.monthlyPrice,
        yearlyPrice: item.yearlyPrice
      };
    });
    // find cheapest product package and return
    let cheapestProductPackage = _.minBy(
      [...tmpFinalProductPackages, ...staticPackageSellingOptionNoVariableComponentConfig],
      'totalPrice'
    );
    cheapestProductPackage = {
      ...cheapestProductPackage,
      ...sumPricingComponents(cheapestProductPackage.pricingComponents)
    };

    finalProductPackagePrice[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[productType]] = {
      ...cheapestProductPackage,
      ...totalPriceBySellingOptionId
    };
  }
  return finalProductPackagePrice;
};

const getPackagePricesBaseOnCurrentConsumption = ({ data, currState }) => {
  const {
    productType,
    sellingOptionMap,
    kwConsumption,
    litterPerSecConsumption,
    meterConsumption,
    meterStreetFrontLengthForHCWaterConsumption,
    housingUnitForHCWaterConsumption,
    squareMetrePropertySizeForHCWaterConsumption,
    squareMetreFloorSizeForHCWaterConsumption,
    kwhConsumption,
    kwConsumptionHCGas,
    kwConsumptionHCHeating
  } = data;
  let tmpCurrentPackages = {};
  const currPackage = currState[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[productType]];
  let kwMatchedPrices;
  let meterMatchedPrices;
  let meterStreetFrontLengthForHCWaterMatchedPrices;
  let housingUnitForHCWaterMatchedPrices;
  let squareMetrePropertySizeForHCWaterMatchedPrices;
  let squareMetreFloorSizeForHCWaterMatchedPrices;
  let litterPerSecMatchedPrices;
  switch (productType) {
    case PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY:
    case PRODUCT_TYPE.HOUSE_CONNECTION_GAS:
    case PRODUCT_TYPE.HOUSE_CONNECTION_HEAT:
      kwMatchedPrices = null;
      if (
        kwConsumption !== null ||
        kwConsumptionHCGas !== null ||
        kwConsumptionHCHeating !== null
      ) {
        let consumption = null;
        switch (productType) {
          case PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY:
            consumption = kwConsumption;
            break;
          case PRODUCT_TYPE.HOUSE_CONNECTION_GAS:
            consumption = kwConsumptionHCGas;
            break;
          case PRODUCT_TYPE.HOUSE_CONNECTION_HEAT:
            consumption = kwConsumptionHCHeating;
            break;
          default:
            break;
        }
        kwMatchedPrices = getMatchedPrices({
          sellingOptionMap,
          consumption,
          compConfigKey: KW_CONFIG_KEY
        });
      }
      meterMatchedPrices = null;
      if (meterConsumption !== null) {
        meterMatchedPrices = getMatchedPrices({
          sellingOptionMap,
          consumption: meterConsumption,
          compConfigKey: METER_CONFIG_KEY
        });
      }
      if (meterMatchedPrices === null && kwMatchedPrices === null) {
        // no variable price component config, returns static component
        tmpCurrentPackages[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[productType]] = {
          ...currPackage
        };
      } else {
        if (meterMatchedPrices !== null && kwMatchedPrices === null) {
          // only meter variable price configured, return matched selling option
          tmpCurrentPackages = {
            ...tmpCurrentPackages,
            ...getProductPackageByTotalConsumption({
              sellingOptionMap,
              consumption: meterConsumption,
              compConfigKey: METER_CONFIG_KEY,
              variableConfigKeys: [METER_CONFIG_KEY, KW_CONFIG_KEY],
              productType,
              currPackage
            })
          };
        } else if (meterMatchedPrices === null && kwMatchedPrices !== null) {
          // only kw variable price configured, return matched selling option
          tmpCurrentPackages = {
            ...tmpCurrentPackages,
            ...getProductPackageByTotalConsumption({
              sellingOptionMap,
              consumption: kwConsumption,
              compConfigKey: KW_CONFIG_KEY,
              variableConfigKeys: [METER_CONFIG_KEY, KW_CONFIG_KEY],
              productType,
              currPackage
            })
          };
        } else {
          // both variable price configured, find a matched selling option.
          if (_.isEmpty(kwMatchedPrices) || _.isEmpty(meterMatchedPrices)) {
            // no selling option matched both variable consumption, return on request
            tmpCurrentPackages[
              PRODUCT_TYPE_CURR_PACKAGE_MAPPING[productType]
            ] = generateOnRequestPackage();
          } else {
            // find selling option matched both variable consumption
            let matchedPrices = [];
            const matchedPricesGroupBySellingOptionId = _.groupBy(
              [...kwMatchedPrices, ...meterMatchedPrices],
              'sellingOptionId'
            );
            // group both matched selling option of 2 variable by ID
            _.forIn(matchedPricesGroupBySellingOptionId, matchedSellingOptionsElem => {
              if (_.isArray(matchedSellingOptionsElem) && matchedSellingOptionsElem.length >= 2) {
                // if length of array > 2, so that is a matched selling option of both variable
                matchedPrices.push(
                  sumAllMatchedPriceOfSameSellingOption({
                    firstPricingComp: matchedSellingOptionsElem[0],
                    secondPricingComp: matchedSellingOptionsElem[1]
                  })
                );
              }
            });
            if (_.isEmpty(matchedPrices)) {
              const staticPriceOfNoVariableComponentSellingOption = getStaticPriceOfNoVariableComponentSellingOption(
                {
                  variableConfigKeys: [METER_CONFIG_KEY, KW_CONFIG_KEY],
                  sellingOptionMap
                }
              );
              const cheapestPackagePrice = _.minBy(
                [...staticPriceOfNoVariableComponentSellingOption],
                'totalPrice'
              );
              if (_.isEmpty(cheapestPackagePrice)) {
                // no selling option matched both variable consumption, return on request
                tmpCurrentPackages[
                  PRODUCT_TYPE_CURR_PACKAGE_MAPPING[productType]
                ] = generateOnRequestPackage();
              } else {
                tmpCurrentPackages[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[productType]] = {
                  ...cheapestPackagePrice
                };
              }
            } else {
              tmpCurrentPackages = {
                ...tmpCurrentPackages,
                ...calcFinalProductPackagePrice({
                  matchedPrices,
                  sellingOptionMap,
                  productType,
                  currPackage,
                  variableConfigKeys: [KW_CONFIG_KEY, METER_CONFIG_KEY]
                })
              };
            }
          }
        }
      }
      break;
    case PRODUCT_TYPE.HOUSE_CONNECTION_WATER:
      litterPerSecMatchedPrices = null;
      if (litterPerSecConsumption !== null) {
        litterPerSecMatchedPrices = getMatchedPrices({
          sellingOptionMap,
          consumption: litterPerSecConsumption,
          compConfigKey: LITTER_PER_SEC_CONFIG_KEY
        });
      }
      meterMatchedPrices = null;
      if (meterConsumption !== null) {
        meterMatchedPrices = getMatchedPrices({
          sellingOptionMap,
          consumption: meterConsumption,
          compConfigKey: METER_CONFIG_KEY
        });
      }
      meterStreetFrontLengthForHCWaterMatchedPrices = null;
      if (meterStreetFrontLengthForHCWaterConsumption !== null) {
        meterStreetFrontLengthForHCWaterMatchedPrices = getMatchedPrices({
          sellingOptionMap,
          consumption: meterStreetFrontLengthForHCWaterConsumption,
          compConfigKey: METER_STREET_FRONT_LENGTH_FOR_HC_WATER_CONFIG_KEY
        });
      }
      housingUnitForHCWaterMatchedPrices = null;
      if (housingUnitForHCWaterConsumption !== null) {
        housingUnitForHCWaterMatchedPrices = getMatchedPrices({
          sellingOptionMap,
          consumption: housingUnitForHCWaterConsumption,
          compConfigKey: HOUSING_UNIT_FOR_HC_WATER_CONFIG_KEY
        });
      }
      squareMetrePropertySizeForHCWaterMatchedPrices = null;
      if (squareMetrePropertySizeForHCWaterConsumption !== null) {
        squareMetrePropertySizeForHCWaterMatchedPrices = getMatchedPrices({
          sellingOptionMap,
          consumption: squareMetrePropertySizeForHCWaterConsumption,
          compConfigKey: SQUARE_METRE_PROPERTY_SIZE_FOR_HC_WATER_CONFIG_KEY
        });
      }
      squareMetreFloorSizeForHCWaterMatchedPrices = null;
      if (squareMetreFloorSizeForHCWaterConsumption !== null) {
        squareMetreFloorSizeForHCWaterMatchedPrices = getMatchedPrices({
          sellingOptionMap,
          consumption: squareMetreFloorSizeForHCWaterConsumption,
          compConfigKey: SQUARE_METRE_FLOOR_SIZE_FOR_HC_WATER_CONFIG_KEY
        });
      }
      if (
        meterMatchedPrices === null &&
        litterPerSecMatchedPrices === null &&
        meterStreetFrontLengthForHCWaterMatchedPrices === null &&
        housingUnitForHCWaterMatchedPrices === null &&
        squareMetrePropertySizeForHCWaterMatchedPrices === null &&
        squareMetreFloorSizeForHCWaterMatchedPrices === null
      ) {
        // no variable price component config, returns static component
        tmpCurrentPackages[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[productType]] = {
          ...currPackage
        };
      } else {
        if (
          meterMatchedPrices !== null &&
          litterPerSecMatchedPrices === null &&
          meterStreetFrontLengthForHCWaterMatchedPrices === null &&
          housingUnitForHCWaterMatchedPrices === null &&
          squareMetrePropertySizeForHCWaterMatchedPrices === null &&
          squareMetreFloorSizeForHCWaterMatchedPrices === null
        ) {
          // only meter variable price configured, return matched selling option
          tmpCurrentPackages = {
            ...tmpCurrentPackages,
            ...getProductPackageByTotalConsumption({
              sellingOptionMap,
              consumption: meterConsumption,
              compConfigKey: METER_CONFIG_KEY,
              variableConfigKeys: [
                METER_CONFIG_KEY,
                LITTER_PER_SEC_CONFIG_KEY,
                METER_STREET_FRONT_LENGTH_FOR_HC_WATER_CONFIG_KEY,
                HOUSING_UNIT_FOR_HC_WATER_CONFIG_KEY,
                SQUARE_METRE_PROPERTY_SIZE_FOR_HC_WATER_CONFIG_KEY,
                SQUARE_METRE_FLOOR_SIZE_FOR_HC_WATER_CONFIG_KEY
              ],
              productType,
              currPackage
            })
          };
        } else if (
          meterMatchedPrices === null &&
          litterPerSecMatchedPrices !== null &&
          meterStreetFrontLengthForHCWaterMatchedPrices === null &&
          housingUnitForHCWaterMatchedPrices === null &&
          squareMetrePropertySizeForHCWaterMatchedPrices === null &&
          squareMetreFloorSizeForHCWaterMatchedPrices === null
        ) {
          // only litter per sec variable price configured, return matched selling option
          tmpCurrentPackages = {
            ...tmpCurrentPackages,
            ...getProductPackageByTotalConsumption({
              sellingOptionMap,
              consumption: litterPerSecConsumption,
              compConfigKey: LITTER_PER_SEC_CONFIG_KEY,
              variableConfigKeys: [
                METER_CONFIG_KEY,
                LITTER_PER_SEC_CONFIG_KEY,
                METER_STREET_FRONT_LENGTH_FOR_HC_WATER_CONFIG_KEY,
                HOUSING_UNIT_FOR_HC_WATER_CONFIG_KEY,
                SQUARE_METRE_PROPERTY_SIZE_FOR_HC_WATER_CONFIG_KEY,
                SQUARE_METRE_FLOOR_SIZE_FOR_HC_WATER_CONFIG_KEY
              ],
              productType,
              currPackage
            })
          };
        } else if (
          meterMatchedPrices === null &&
          litterPerSecMatchedPrices === null &&
          meterStreetFrontLengthForHCWaterMatchedPrices !== null &&
          housingUnitForHCWaterMatchedPrices === null &&
          squareMetrePropertySizeForHCWaterMatchedPrices === null &&
          squareMetreFloorSizeForHCWaterMatchedPrices === null
        ) {
          // only meter street front length for HCWater variable price configured, return matched selling option
          tmpCurrentPackages = {
            ...tmpCurrentPackages,
            ...getProductPackageByTotalConsumption({
              sellingOptionMap,
              consumption: meterStreetFrontLengthForHCWaterConsumption,
              compConfigKey: METER_STREET_FRONT_LENGTH_FOR_HC_WATER_CONFIG_KEY,
              variableConfigKeys: [
                METER_CONFIG_KEY,
                LITTER_PER_SEC_CONFIG_KEY,
                METER_STREET_FRONT_LENGTH_FOR_HC_WATER_CONFIG_KEY,
                HOUSING_UNIT_FOR_HC_WATER_CONFIG_KEY,
                SQUARE_METRE_PROPERTY_SIZE_FOR_HC_WATER_CONFIG_KEY,
                SQUARE_METRE_FLOOR_SIZE_FOR_HC_WATER_CONFIG_KEY
              ],
              productType,
              currPackage
            })
          };
        } else if (
          meterMatchedPrices === null &&
          litterPerSecMatchedPrices === null &&
          meterStreetFrontLengthForHCWaterMatchedPrices === null &&
          housingUnitForHCWaterMatchedPrices !== null &&
          squareMetrePropertySizeForHCWaterMatchedPrices === null &&
          squareMetreFloorSizeForHCWaterMatchedPrices === null
        ) {
          // only housing unit for HCWater variable price configured, return matched selling option
          tmpCurrentPackages = {
            ...tmpCurrentPackages,
            ...getProductPackageByTotalConsumption({
              sellingOptionMap,
              consumption: housingUnitForHCWaterConsumption,
              compConfigKey: HOUSING_UNIT_FOR_HC_WATER_CONFIG_KEY,
              variableConfigKeys: [
                METER_CONFIG_KEY,
                LITTER_PER_SEC_CONFIG_KEY,
                METER_STREET_FRONT_LENGTH_FOR_HC_WATER_CONFIG_KEY,
                HOUSING_UNIT_FOR_HC_WATER_CONFIG_KEY,
                SQUARE_METRE_PROPERTY_SIZE_FOR_HC_WATER_CONFIG_KEY,
                SQUARE_METRE_FLOOR_SIZE_FOR_HC_WATER_CONFIG_KEY
              ],
              productType,
              currPackage
            })
          };
        } else if (
          meterMatchedPrices === null &&
          litterPerSecMatchedPrices === null &&
          meterStreetFrontLengthForHCWaterMatchedPrices === null &&
          housingUnitForHCWaterMatchedPrices === null &&
          squareMetrePropertySizeForHCWaterMatchedPrices !== null &&
          squareMetreFloorSizeForHCWaterMatchedPrices === null
        ) {
          // only squaremetre property size for HCWater variable price configured, return matched selling option
          tmpCurrentPackages = {
            ...tmpCurrentPackages,
            ...getProductPackageByTotalConsumption({
              sellingOptionMap,
              consumption: squareMetrePropertySizeForHCWaterConsumption,
              compConfigKey: SQUARE_METRE_PROPERTY_SIZE_FOR_HC_WATER_CONFIG_KEY,
              variableConfigKeys: [
                METER_CONFIG_KEY,
                LITTER_PER_SEC_CONFIG_KEY,
                METER_STREET_FRONT_LENGTH_FOR_HC_WATER_CONFIG_KEY,
                HOUSING_UNIT_FOR_HC_WATER_CONFIG_KEY,
                SQUARE_METRE_PROPERTY_SIZE_FOR_HC_WATER_CONFIG_KEY,
                SQUARE_METRE_FLOOR_SIZE_FOR_HC_WATER_CONFIG_KEY
              ],
              productType,
              currPackage
            })
          };
        } else if (
          meterMatchedPrices === null &&
          litterPerSecMatchedPrices === null &&
          meterStreetFrontLengthForHCWaterMatchedPrices === null &&
          housingUnitForHCWaterMatchedPrices === null &&
          squareMetrePropertySizeForHCWaterMatchedPrices === null &&
          squareMetreFloorSizeForHCWaterMatchedPrices !== null
        ) {
          // only squaremetre floor size for HCWater variable price configured, return matched selling option
          tmpCurrentPackages = {
            ...tmpCurrentPackages,
            ...getProductPackageByTotalConsumption({
              sellingOptionMap,
              consumption: squareMetreFloorSizeForHCWaterConsumption,
              compConfigKey: SQUARE_METRE_FLOOR_SIZE_FOR_HC_WATER_CONFIG_KEY,
              variableConfigKeys: [
                METER_CONFIG_KEY,
                LITTER_PER_SEC_CONFIG_KEY,
                METER_STREET_FRONT_LENGTH_FOR_HC_WATER_CONFIG_KEY,
                HOUSING_UNIT_FOR_HC_WATER_CONFIG_KEY,
                SQUARE_METRE_PROPERTY_SIZE_FOR_HC_WATER_CONFIG_KEY,
                SQUARE_METRE_FLOOR_SIZE_FOR_HC_WATER_CONFIG_KEY
              ],
              productType,
              currPackage
            })
          };
        } else {
          // both variable price configured, find a matched selling option.
          if (
            _.isEmpty(meterMatchedPrices) ||
            _.isEmpty(litterPerSecMatchedPrices) ||
            _.isEmpty(meterStreetFrontLengthForHCWaterMatchedPrices) ||
            _.isEmpty(housingUnitForHCWaterMatchedPrices) ||
            _.isEmpty(squareMetrePropertySizeForHCWaterMatchedPrices) ||
            _.isEmpty(squareMetreFloorSizeForHCWaterMatchedPrices)
          ) {
            // no selling option matched both variable consumption, return on request
            tmpCurrentPackages[
              PRODUCT_TYPE_CURR_PACKAGE_MAPPING[productType]
            ] = generateOnRequestPackage();
          } else {
            // find selling option matched both variable consumption
            let matchedPrices = [];
            const matchedPricesGroupBySellingOptionId = _.groupBy(
              [
                ...litterPerSecMatchedPrices,
                ...squareMetreFloorSizeForHCWaterMatchedPrices,
                ...squareMetrePropertySizeForHCWaterMatchedPrices,
                ...housingUnitForHCWaterMatchedPrices,
                ...meterStreetFrontLengthForHCWaterMatchedPrices,
                ...meterMatchedPrices
              ],
              'sellingOptionId'
            );
            // group both matched selling option of 2 variable by ID
            _.forIn(matchedPricesGroupBySellingOptionId, matchedPricesGroup => {
              if (_.isArray(matchedPricesGroup) && matchedPricesGroup.length >= 6) {
                // if length of array > 6, so that is a matched selling option of both variable
                let tempMatchedPrices = [];
                tempMatchedPrices = sumAllMatchedPriceOfSameSellingOption({
                  firstPricingComp: matchedPricesGroup[0],
                  secondPricingComp: matchedPricesGroup[1]
                });
                tempMatchedPrices = sumAllMatchedPriceOfSameSellingOption({
                  firstPricingComp: tempMatchedPrices,
                  secondPricingComp: matchedPricesGroup[2]
                });
                tempMatchedPrices = sumAllMatchedPriceOfSameSellingOption({
                  firstPricingComp: tempMatchedPrices,
                  secondPricingComp: matchedPricesGroup[3]
                });
                tempMatchedPrices = sumAllMatchedPriceOfSameSellingOption({
                  firstPricingComp: tempMatchedPrices,
                  secondPricingComp: matchedPricesGroup[4]
                });
                matchedPrices.push(
                  sumAllMatchedPriceOfSameSellingOption({
                    firstPricingComp: tempMatchedPrices,
                    secondPricingComp: matchedPricesGroup[5]
                  })
                );
              }
            });
            if (_.isEmpty(matchedPrices)) {
              const staticPriceOfNoVariableComponentSellingOption = getStaticPriceOfNoVariableComponentSellingOption(
                {
                  variableConfigKeys: [
                    METER_CONFIG_KEY,
                    LITTER_PER_SEC_CONFIG_KEY,
                    METER_STREET_FRONT_LENGTH_FOR_HC_WATER_CONFIG_KEY,
                    HOUSING_UNIT_FOR_HC_WATER_CONFIG_KEY,
                    SQUARE_METRE_PROPERTY_SIZE_FOR_HC_WATER_CONFIG_KEY,
                    SQUARE_METRE_FLOOR_SIZE_FOR_HC_WATER_CONFIG_KEY
                  ],
                  sellingOptionMap
                }
              );
              const cheapestPackagePrice = _.minBy(
                [...staticPriceOfNoVariableComponentSellingOption],
                'totalPrice'
              );
              if (_.isEmpty(cheapestPackagePrice)) {
                // no selling option matched both variable consumption, return on request
                tmpCurrentPackages[
                  PRODUCT_TYPE_CURR_PACKAGE_MAPPING[productType]
                ] = generateOnRequestPackage();
              } else {
                tmpCurrentPackages[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[productType]] = {
                  ...cheapestPackagePrice
                };
              }
            } else {
              tmpCurrentPackages = {
                ...tmpCurrentPackages,
                ...calcFinalProductPackagePrice({
                  matchedPrices,
                  sellingOptionMap,
                  productType,
                  currPackage,
                  variableConfigKeys: [
                    METER_CONFIG_KEY,
                    LITTER_PER_SEC_CONFIG_KEY,
                    METER_STREET_FRONT_LENGTH_FOR_HC_WATER_CONFIG_KEY,
                    HOUSING_UNIT_FOR_HC_WATER_CONFIG_KEY,
                    SQUARE_METRE_PROPERTY_SIZE_FOR_HC_WATER_CONFIG_KEY,
                    SQUARE_METRE_FLOOR_SIZE_FOR_HC_WATER_CONFIG_KEY
                  ]
                })
              };
            }
          }
        }
      }
      break;
    case PRODUCT_TYPE.STORAGE:
    case PRODUCT_TYPE.POWER:
    case PRODUCT_TYPE.GAS:
      if (kwhConsumption) {
        tmpCurrentPackages = {
          ...tmpCurrentPackages,
          ...getProductPackageByTotalConsumption({
            sellingOptionMap,
            consumption: kwhConsumption,
            compConfigKey: KWH_CONFIG_KEY,
            variableConfigKeys: [KWH_CONFIG_KEY],
            productType,
            currPackage
          })
        };
      }
      break;
    case PRODUCT_TYPE.HOUSE_CONNECTION_BROADBAND:
      if (meterConsumption) {
        tmpCurrentPackages = {
          ...tmpCurrentPackages,
          ...getProductPackageByTotalConsumption({
            sellingOptionMap,
            consumption: meterConsumption,
            compConfigKey: METER_CONFIG_KEY,
            variableConfigKeys: [METER_CONFIG_KEY],
            productType,
            currPackage
          })
        };
      }
      break;
    case PRODUCT_TYPE.CARSHARING:
    case PRODUCT_TYPE.HEATPUMP:
      tmpCurrentPackages = {
        ...tmpCurrentPackages,
        ...getProductPackageByTotalConsumption({
          sellingOptionMap,
          productType,
          currPackage
        })
      };
      break;
    default:
      break;
  }
  return tmpCurrentPackages;
};

const getAllProductTypePackageBaseOnCurrentConsumption = ({ data, currState }) => {
  const {
    kwConsumption,
    litterPerSecConsumption,
    meterConsumption,
    meterStreetFrontLengthForHCWaterConsumption,
    housingUnitForHCWaterConsumption,
    squareMetrePropertySizeForHCWaterConsumption,
    squareMetreFloorSizeForHCWaterConsumption,
    kwhConsumption,
    kwConsumptionHCGas,
    kwConsumptionHCHeating
  } = data;
  const { currProductPackageConfig } = currState;
  let tmpCurrentPackages = {};
  let tmpData = {
    kwConsumption,
    litterPerSecConsumption,
    meterConsumption,
    meterStreetFrontLengthForHCWaterConsumption,
    housingUnitForHCWaterConsumption,
    squareMetrePropertySizeForHCWaterConsumption,
    squareMetreFloorSizeForHCWaterConsumption,
    kwhConsumption,
    kwConsumptionHCGas,
    kwConsumptionHCHeating
  };
  _.forIn(currProductPackageConfig, (productPackage, productType) => {
    const { sellingOptionMap } = productPackage;
    tmpData = {
      ...tmpData,
      sellingOptionMap,
      productType
    };
    if (_.isEmpty(sellingOptionMap)) {
      tmpCurrentPackages = {
        ...tmpCurrentPackages,
        [PRODUCT_TYPE_CURR_PACKAGE_MAPPING[productType]]: generateOnRequestPackage()
      };
    } else {
      tmpCurrentPackages = {
        ...tmpCurrentPackages,
        ...getPackagePricesBaseOnCurrentConsumption({
          data: tmpData,
          currState
        })
      };
    }
  });
  return tmpCurrentPackages;
};

const getProductPackageByTotalConsumption = ({
  sellingOptionMap,
  consumption,
  compConfigKey,
  productType,
  currPackage,
  variableConfigKeys
}) => {
  let matchedPrices = getMatchedPrices({
    sellingOptionMap,
    consumption,
    compConfigKey
  });
  return {
    ...calcFinalProductPackagePrice({
      matchedPrices,
      sellingOptionMap,
      productType,
      currPackage,
      variableConfigKeys
    })
  };
};

const getStaticPriceOfNoVariableComponentSellingOption = ({
  variableConfigKeys,
  sellingOptionMap
}) => {
  let staticPackages = [];
  _.forIn(sellingOptionMap, sellingOption => {
    let isAllVariableConfigNotExist = true;
    _.forEach(variableConfigKeys, variableConfigKey => {
      if (sellingOption[variableConfigKey] !== null) {
        isAllVariableConfigNotExist = false;
      }
    });
    if (isAllVariableConfigNotExist) {
      const {
        staticCompConfig: { pricingConfig }
      } = sellingOption;
      let oneTimePrice = null;
      let monthlyPrice = null;
      let yearlyPrice = null;
      let pricingComponents = [];
      if (pricingConfig[PAYMENT_FREQUENCY.ONE_TIME]) {
        oneTimePrice = pricingConfig[PAYMENT_FREQUENCY.ONE_TIME].totalPrice;
        pricingComponents.push(...pricingConfig[PAYMENT_FREQUENCY.ONE_TIME].pricingComps);
      }
      if (pricingConfig[PAYMENT_FREQUENCY.MONTHLY]) {
        monthlyPrice = pricingConfig[PAYMENT_FREQUENCY.MONTHLY].totalPrice;
        pricingComponents.push(...pricingConfig[PAYMENT_FREQUENCY.MONTHLY].pricingComps);
      }
      if (pricingConfig[PAYMENT_FREQUENCY.YEARLY]) {
        yearlyPrice = pricingConfig[PAYMENT_FREQUENCY.YEARLY].totalPrice;
        pricingComponents.push(...pricingConfig[PAYMENT_FREQUENCY.YEARLY].pricingComps);
      }
      let totalPrice = oneTimePrice + monthlyPrice + yearlyPrice;
      staticPackages.push({
        packageId: sellingOption.packageId,
        sellingOptionId: sellingOption.sellingOptionId,
        sellingOptionName: sellingOption.title,
        duration: sellingOption.duration,
        durationUnit: sellingOption.durationUnit,
        name: sellingOption.packageName,
        oneTimePrice,
        monthlyPrice,
        yearlyPrice,
        totalPrice,
        pricingComponents,
        variableCompIds: []
      });
    }
  });
  return staticPackages;
};

import { createSelector } from 'reselect';
import _ from 'lodash';
import { currentStateSelector, productSelectionsSelector, productsSelector } from '.';
import {
  PRODUCT_SELECTED_KEY,
  PRODUCT_STATUS,
  PRODUCT_TYPE_CURR_PACKAGE_MAPPING
} from '../../common/constants';
import { isPricingV2SelectedProductOnRequest } from '../../common/utils';

const getProductSelections = state => productSelectionsSelector(state);
const getProducts = state => productsSelector(state);

export const selectedProductsSelector = createSelector(
  [getProductSelections, getProducts],
  (productSelections, products) => {
    return _.filter(
      products,
      product =>
        (!product.status || (product.status && product.status !== PRODUCT_STATUS.DEACTIVATED)) &&
        productSelections[PRODUCT_SELECTED_KEY[product.type]]
    );
  }
);

const getSelectedProducts = state => selectedProductsSelector(state);
const getCurrentState = state => currentStateSelector(state);

export const isAllSelectedProductOnRequestSelector = createSelector(
  [getSelectedProducts, getCurrentState],
  (selectedProducts, currState) => {
    return (
      !_.isEmpty(selectedProducts) &&
      _.every(selectedProducts, selectedProduct => {
        const currentSelectedPackage =
          currState[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[selectedProduct.type]];
        return isPricingV2SelectedProductOnRequest({ currentSelectedPackage, selectedProduct });
      })
    );
  }
);

export const isSomeSelectedProductOnRequestSelector = createSelector(
  [getSelectedProducts, getCurrentState],
  (selectedProducts, currState) => {
    return (
      !_.isEmpty(selectedProducts) &&
      _.some(selectedProducts, selectedProduct => {
        const currentSelectedPackage =
          currState[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[selectedProduct.type]];
        return isPricingV2SelectedProductOnRequest({ currentSelectedPackage, selectedProduct });
      })
    );
  }
);

export const onRequestSelectedProductTypesSelector = createSelector(
  [getSelectedProducts, getCurrentState],
  (selectedProducts, currState) => {
    return _.filter(
      _.map(selectedProducts, selectedProduct => {
        const currentSelectedPackage =
          currState[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[selectedProduct.type]];
        if (currentSelectedPackage == null) {
          return null;
        }
        if (isPricingV2SelectedProductOnRequest({ currentSelectedPackage, selectedProduct })) {
          return selectedProduct.type;
        }
        return null;
      }),
      element => element
    );
  }
);

import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { Position, Tooltip } from '@blueprintjs/core';
import {
  ADD_ON_V2_PRODUCT_TYPE,
  BOOLEAN,
  GERMAN_TEXT,
  ONLINE_PURCHASE,
  PRODUCT_SELECTED_KEY,
  PRODUCT_TYPE,
  PURCHASE_TYPE,
  REQUESTS
} from '../../common/constants';
import {
  StyledP,
  StyledSpan,
  StyledSpanBold,
  PriceBlock,
  PackageSummary,
  TaxSummary
} from '../../components';
import { checkIsOnlinePurchaseV2, convertProdTypeToProdName, ShowIfUtil } from '../../common/utils';
import {
  createCurrProductPackages,
  updateCurrentPackage,
  updateCurrProductPackageConfig,
  updateProductSelections,
  removeSelectedAddOnV2,
  removeSelectedGlobalAddOnV2
} from '../../store/actions';
import {
  emailTemplateV2DataSelector,
  isAllSelectedProductOnRequestSelector,
  KWConsumptionForElectricitySelector,
  KWConsumptionForGasSelector,
  KWConsumptionForHeatingSelector,
  KWHConsumptionSelector,
  litterPerSecConsumptionSelector,
  meterConsumptionSelector,
  meterStreetFrontLengthForHCWaterConsumptionSelector,
  housingUnitForHCWaterConsumptionSelector,
  squareMetrePropertySizeForHCWaterConsumptionSelector,
  squareMetreFloorSizeForHCWaterConsumptionSelector,
  productSelectionsSelector,
  productsSelector,
  selectedProductsSelector,
  subTotalPriceSelector,
  taxPricingSummarySelector,
  totalPriceCalcV2ExcludeOnRequestSelector
} from '../../store/selectors';
import { generatePackageConfig } from '../../store/reducers/productPackageConfigReducer';
import OtherAddOnV2Summary from '../../components/common/OrderSummary/OtherAddOnV2Summary';

class MixedOrderSummaryContainer extends PureComponent {
  constructor(props) {
    super(props);
    const { routeManager } = this.props;
    this.isPreviewCheckoutPage = routeManager.isPreviewCheckoutPage === BOOLEAN.TRUE;
  }

  componentDidMount() {
    if (this.isPreviewCheckoutPage) {
      const {
        kwConsumption,
        kwConsumptionHCGas,
        kwConsumptionHCHeating,
        litterPerSecConsumption,
        meterConsumption,
        meterStreetFrontLengthForHCWaterConsumption,
        housingUnitForHCWaterConsumption,
        squareMetrePropertySizeForHCWaterConsumption,
        squareMetreFloorSizeForHCWaterConsumption,
        updateCurrentPackage,
        kwhConsumption,
        productPackageConfig,
        updateCurrProductPackageConfig,
        createCurrProductPackages,
        products
      } = this.props;
      createCurrProductPackages({ productPackageConfig, products });
      if (productPackageConfig[PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY]) {
        const electricHCProductPackageConfig =
          productPackageConfig[PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY];
        const electricHCPackageNoLabels = _.filter(electricHCProductPackageConfig.packages, elem =>
          _.isEmpty(elem.labels)
        );
        let tmpProductPackageConfig = {
          ...productPackageConfig,
          [PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY]: {
            ...generatePackageConfig({
              packages: electricHCPackageNoLabels,
              productType: PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY
            })
          }
        };
        updateCurrProductPackageConfig(tmpProductPackageConfig);
      } else {
        updateCurrProductPackageConfig(productPackageConfig);
      }
      updateCurrentPackage({
        kwConsumption,
        kwConsumptionHCGas,
        kwConsumptionHCHeating,
        litterPerSecConsumption,
        meterStreetFrontLengthForHCWaterConsumption,
        housingUnitForHCWaterConsumption,
        squareMetrePropertySizeForHCWaterConsumption,
        squareMetreFloorSizeForHCWaterConsumption,
        kwhConsumption,
        meterConsumption
      });
      this.selectCheapestPackages();
    }
  }

  componentDidUpdate(prevProps) {
    const { isAllSelectedProductOnRequest, removeSelectedGlobalAddOnV2 } = this.props;
    if (
      prevProps.isAllSelectedProductOnRequest !== isAllSelectedProductOnRequest &&
      isAllSelectedProductOnRequest
    ) {
      removeSelectedGlobalAddOnV2();
    }
  }

  removePackage = item => {
    const { updateProductSelections } = this.props;
    updateProductSelections({
      [PRODUCT_SELECTED_KEY[item.productType]]: false
    });
  };

  removeAddOn = (productType, addOnId) => {
    const { removeSelectedAddOnV2 } = this.props;
    removeSelectedAddOnV2({
      productType,
      addOnId
    });
  };

  selectCheapestPackages = () => {
    const { products, updateProductSelections } = this.props;
    let newProductSelect = {};

    _.forEach(products, product => {
      newProductSelect[PRODUCT_SELECTED_KEY[product.type]] = true;
    });

    updateProductSelections(newProductSelect);
  };

  renderPackagePrice = item => {
    const { fontText, frontendConfiguration } = this.props;
    return (
      <PriceBlock
        defaultTextColor={frontendConfiguration.defaultTextColor}
        fontText={fontText}
        oneTimePrice={item.totalOneTimeNetRetailPrice}
        monthlyPrice={item.totalMonthlyNetRetailPrice}
        yearlyPrice={item.totalYearlyNetRetailPrice}
        isOnRequest={item.alwaysShowOnRequest || item.onRequest}
      />
    );
  };

  renderSubTotalPrice = () => {
    const { subTotalPrice, frontendConfiguration, fontText } = this.props;
    return (
      <PriceBlock
        defaultTextColor={frontendConfiguration.defaultTextColor}
        fontText={fontText}
        oneTimePrice={subTotalPrice.oneTimePrice}
        monthlyPrice={subTotalPrice.monthlyPrice}
        yearlyPrice={subTotalPrice.yearlyPrice}
        isOnRequest={subTotalPrice.isOnRequest}
      />
    );
  };

  render() {
    const {
      frontendConfiguration,
      fontText,
      noProductSelected,
      totalPriceV2,
      selectedProducts,
      emailTemplateV2Data,
      thankYouPageData,
      selectedAddOnV2MapKeyByProductType,
      isShowPackageInfo,
      taxPricingSummary
    } = this.props;

    const textColor = {
      color: frontendConfiguration.defaultTextColor
    };

    const isOnlinePurchase =
      checkIsOnlinePurchaseV2(frontendConfiguration, totalPriceV2) ===
      PURCHASE_TYPE.ONLINE_PURCHASE;
    const onlinePurchaseOnlyRequest = thankYouPageData.onlinePurchaseOnlyRequest;
    const onlinePurchaseProductPackagesSummary = _.filter(
      emailTemplateV2Data.productPackagesSummary,
      item => !item.onRequest && !item.alwaysShowOnRequest
    );
    const orderRequestProductPackagesSummary = _.filter(
      emailTemplateV2Data.productPackagesSummary,
      item => item.onRequest || item.alwaysShowOnRequest
    );
    return (
      <>
        {/*Online purchase summary*/}
        <ShowIfUtil
          condition={!_.isEmpty(onlinePurchaseProductPackagesSummary) && isShowPackageInfo}
        >
          <div className="ep-order-summary-wrapper ep--pb-0 ep--mr-8 ep--mb-32">
            <StyledP
              font={fontText}
              style={textColor}
              className="ep__txt--h2 ep__txt-bold ep-bg-light-grey ep--py-24 ep--mb-0 ep--pl-16"
            >
              {ONLINE_PURCHASE.DE}
            </StyledP>
            <div className="container">
              <div className="row">
                {/*PACKAGES*/}
                <div className="col-12 packages">
                  <StyledP
                    font={fontText}
                    style={textColor}
                    className="ep-opacity-50 ep__txt--sm ep__txt-bold ep--mt-24 ep--mb-0"
                  >
                    {'PRODUKTE'}
                  </StyledP>
                  {noProductSelected ? (
                    <StyledP
                      font={fontText}
                      style={textColor}
                      className="ep-opacity-50 ep__txt--sm font-italic ep__txt-bold ep--my-24"
                    >
                      {'Kein Paket ausgewählt.'}
                    </StyledP>
                  ) : (
                    onlinePurchaseProductPackagesSummary.map(item => {
                      return (
                        <PackageSummary
                          key={`${convertProdTypeToProdName(item.productType)}_${item.packageId}`}
                          textColor={textColor}
                          defaultTextColor={frontendConfiguration.defaultTextColor}
                          removePackage={this.removePackage}
                          selectedProducts={selectedProducts}
                          packageSummary={item}
                          fontText={fontText}
                          removeAddOn={this.removeAddOn}
                          selectedAddOnV2MapKeyByProductType={selectedAddOnV2MapKeyByProductType}
                        />
                      );
                    })
                  )}
                </div>
                {selectedAddOnV2MapKeyByProductType[ADD_ON_V2_PRODUCT_TYPE.GLOBAL] &&
                  selectedAddOnV2MapKeyByProductType[ADD_ON_V2_PRODUCT_TYPE.GLOBAL].length > 0 && (
                    <OtherAddOnV2Summary
                      textColor={textColor}
                      fontText={fontText}
                      otherAddOns={
                        selectedAddOnV2MapKeyByProductType[ADD_ON_V2_PRODUCT_TYPE.GLOBAL]
                      }
                      removeAddOn={this.removeAddOn}
                    />
                  )}
                <hr className="col-12 ep-bg-little-dark-grey" />

                <div className="col-12 ep--mt-24">
                  {/* SUBTOTAL PRICE */}
                  <div className="row ep--mb-24">
                    <StyledSpanBold
                      font={fontText}
                      style={textColor}
                      className="col-6 text-right ep__txt--md ep-opacity-50 ep__txt-semibold"
                    >
                      {'Zwischensumme'}
                    </StyledSpanBold>

                    <div className="col-5 ep__txt--md ep__txt-bold text-right text-break">
                      <Tooltip
                        content={this.renderSubTotalPrice()}
                        position={Position.BOTTOM}
                        tooltipClassName="ep-tooltip ep-tooltip-width-300"
                      >
                        {this.renderSubTotalPrice()}
                      </Tooltip>
                    </div>
                  </div>

                  {/* TAX PRICE */}
                  <TaxSummary
                    taxPricingSummary={taxPricingSummary}
                    fontText={fontText}
                    defaultTextColor={frontendConfiguration.defaultTextColor}
                  />
                </div>
              </div>
            </div>

            {/* TOTAL PRICE */}
            <div className="container ep-bg-light-grey ep--py-24 ep--mt-24">
              <div className="row">
                <div className="col-11 ep__txt--h1 ep__txt-bold text-right ep-ellipsis">
                  <PriceBlock
                    defaultTextColor={frontendConfiguration.defaultTextColor}
                    fontText={fontText}
                    oneTimePrice={totalPriceV2.oneTimePrice}
                    monthlyPrice={totalPriceV2.monthlyPrice}
                    yearlyPrice={totalPriceV2.yearlyPrice}
                  />
                </div>
              </div>

              <div className="row">
                <StyledSpanBold
                  font={fontText}
                  style={textColor}
                  className="ep__txt--sm ep-opacity-50 ep__txt-semibold text-right col-11"
                >
                  {GERMAN_TEXT.TOTAL_AMOUNT_INCL_TAX}
                </StyledSpanBold>
              </div>
            </div>
          </div>
        </ShowIfUtil>

        {/*Request summary*/}
        <ShowIfUtil condition={!_.isEmpty(orderRequestProductPackagesSummary) && isShowPackageInfo}>
          <div className="ep-order-summary-wrapper ep--pb-0 ep--mr-8 ep--mb-8">
            <StyledP
              font={fontText}
              style={textColor}
              className="ep__txt--h2 ep__txt-bold ep-bg-light-grey ep--py-24 ep--mb-0 ep--pl-16"
            >
              {REQUESTS.DE}
            </StyledP>
            <div className="container">
              <div className="row">
                {/*PACKAGES*/}
                <div className="col-12 packages">
                  <StyledP
                    font={fontText}
                    style={textColor}
                    className="ep-opacity-50 ep__txt--sm ep__txt-bold ep--mt-24 ep--mb-0"
                  >
                    {'PRODUKTE'}
                  </StyledP>
                  {noProductSelected ? (
                    <StyledP
                      font={fontText}
                      style={textColor}
                      className="ep-opacity-50 ep__txt--sm font-italic ep__txt-bold ep--mt-24 ep--my-24"
                    >
                      {'Kein Paket ausgewählt.'}
                    </StyledP>
                  ) : (
                    orderRequestProductPackagesSummary.map(item => {
                      return (
                        <PackageSummary
                          key={`${convertProdTypeToProdName(item.productType)}_${item.packageId}`}
                          textColor={textColor}
                          defaultTextColor={frontendConfiguration.defaultTextColor}
                          removePackage={this.removePackage}
                          selectedProducts={selectedProducts}
                          packageSummary={item}
                          fontText={fontText}
                          removeAddOn={this.removeAddOn}
                          selectedAddOnV2MapKeyByProductType={selectedAddOnV2MapKeyByProductType}
                        />
                      );
                    })
                  )}
                </div>
              </div>
            </div>

            {/* TOTAL PRICE */}
            <div className="container ep-bg-light-grey ep--py-24 ep--mt-24">
              <div className="row">
                <StyledSpan
                  font={fontText}
                  style={textColor}
                  className="col-11 ep__txt--h1 ep__txt-bold text-right ep-ellipsis"
                >
                  {GERMAN_TEXT.ON_REQUEST}
                </StyledSpan>
              </div>
            </div>
          </div>
        </ShowIfUtil>

        <ShowIfUtil condition={!isShowPackageInfo}>
          <div className="ep-order-summary-wrapper ep--pb-0 ep--mr-8 ep--mb-8">
            <StyledP
              font={fontText}
              style={textColor}
              className="ep__txt--h2 ep__txt-bold ep-bg-light-grey ep--py-24 ep--mb-0 ep--pl-16"
            >
              {isOnlinePurchase && !onlinePurchaseOnlyRequest ? ONLINE_PURCHASE.DE : REQUESTS.DE}
            </StyledP>
            <div className="container">
              <div className="row">
                {/*PACKAGES*/}
                <div className="col-12 packages">
                  <StyledP
                    font={fontText}
                    style={textColor}
                    className="ep-opacity-50 ep__txt--sm ep__txt-bold ep--my-24"
                  >
                    {'PRODUKTE'}
                  </StyledP>
                  {noProductSelected ? (
                    <StyledP
                      font={fontText}
                      style={textColor}
                      className="ep-opacity-50 ep__txt--sm font-italic ep__txt-bold ep--my-24"
                    >
                      {'Kein Paket ausgewählt.'}
                    </StyledP>
                  ) : (
                    emailTemplateV2Data.productPackagesSummary.map(item => {
                      return (
                        <div
                          className="row ep--mb-24"
                          key={`${convertProdTypeToProdName(item.productType)}_${item.packageId}`}
                        >
                          {/* PACKAGE NAME*/}
                          <div className="col-6 ep__txt--md ep__txt-bold ep--mb-0 text-break">
                            <Tooltip
                              content={
                                <StyledSpanBold font={fontText} style={textColor}>
                                  {convertProdTypeToProdName(item.productType)}
                                </StyledSpanBold>
                              }
                              position={Position.BOTTOM}
                              tooltipClassName="ep-tooltip ep-tooltip-width-300"
                            >
                              <StyledSpanBold font={fontText} style={textColor}>
                                {convertProdTypeToProdName(item.productType)}
                              </StyledSpanBold>
                            </Tooltip>
                          </div>

                          {/* PRICE */}
                          <div className="col-5 text-right ep--mb-0 ep__txt--md ep__txt-bold ep-ellipsis">
                            <Tooltip
                              content={
                                <StyledSpan font={fontText} style={textColor}>
                                  {GERMAN_TEXT.ON_REQUEST}
                                </StyledSpan>
                              }
                              position={Position.BOTTOM}
                              tooltipClassName="ep-tooltip ep-tooltip-width-300"
                            >
                              <StyledSpan font={fontText} style={textColor}>
                                {GERMAN_TEXT.ON_REQUEST}
                              </StyledSpan>
                            </Tooltip>
                          </div>

                          {/* REMOVE PACKAGE */}
                          <ShowIfUtil condition={selectedProducts && selectedProducts.length > 1}>
                            <div className="col-1 pl-1 pr-0 pt-1">
                              <i
                                style={textColor}
                                className="ep-icon-simple-remove ep-cursor-pointer ep-font-size-18"
                                onClick={() => this.removePackage(item)}
                              />
                            </div>
                          </ShowIfUtil>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            </div>

            {/* TOTAL PRICE */}
            <div className="container ep-bg-light-grey ep--py-24 ep--mt-24">
              <div className="row">
                <StyledSpan
                  font={fontText}
                  style={textColor}
                  className="col-11 ep__txt--h1 ep__txt-bold text-right ep-ellipsis"
                >
                  {GERMAN_TEXT.ON_REQUEST}
                </StyledSpan>
              </div>
            </div>
          </div>
        </ShowIfUtil>
      </>
    );
  }
}

MixedOrderSummaryContainer.propTypes = {
  frontendConfiguration: PropTypes.object,
  fontText: PropTypes.object,
  updateProductSelections: PropTypes.func,
  noProductSelected: PropTypes.bool,
  totalPriceV2: PropTypes.object,
  routeManager: PropTypes.object,
  products: PropTypes.array,
  selectedProducts: PropTypes.array.isRequired,
  taxPricingSummary: PropTypes.array.isRequired,
  emailTemplateV2Data: PropTypes.object.isRequired,
  subTotalPrice: PropTypes.object.isRequired,
  updateCurrProductPackageConfig: PropTypes.func.isRequired,
  createCurrProductPackages: PropTypes.func.isRequired,
  removeSelectedGlobalAddOnV2: PropTypes.func.isRequired,
  selectedAddOnV2MapKeyByProductType: PropTypes.object.isRequired,
  isAllSelectedProductOnRequest: PropTypes.bool.isRequired,
  removeSelectedAddOnV2: PropTypes.func.isRequired,
  kwConsumption: PropTypes.string,
  kwConsumptionHCGas: PropTypes.string,
  kwConsumptionHCHeating: PropTypes.string,
  litterPerSecConsumption: PropTypes.string,
  meterStreetFrontLengthForHCWaterConsumption: PropTypes.string,
  housingUnitForHCWaterConsumption: PropTypes.string,
  squareMetrePropertySizeForHCWaterConsumption: PropTypes.string,
  squareMetreFloorSizeForHCWaterConsumption: PropTypes.string,
  meterConsumption: PropTypes.string,
  updateCurrentPackage: PropTypes.func,
  kwhConsumption: PropTypes.string,
  productPackageConfig: PropTypes.object,
  thankYouPageData: PropTypes.object,
  isShowPackageInfo: PropTypes.bool
};

MixedOrderSummaryContainer.defaultProps = {
  frontendConfiguration: {},
  noProductSelected: false,
  totalPriceV2: {},
  routeManager: {},
  products: [],
  fontText: '',
  updateProductSelections: () => ({}),
  kwConsumption: '',
  kwConsumptionHCGas: '',
  kwConsumptionHCHeating: '',
  litterPerSecConsumption: '',
  meterConsumption: '',
  meterStreetFrontLengthForHCWaterConsumption: '',
  housingUnitForHCWaterConsumption: '',
  squareMetrePropertySizeForHCWaterConsumption: '',
  squareMetreFloorSizeForHCWaterConsumption: '',
  updateCurrentPackage: () => ({}),
  kwhConsumption: '',
  productPackageConfig: {},
  thankYouPageData: {},
  isShowPackageInfo: false
};

function mapStateToProps(state) {
  return {
    isAllSelectedProductOnRequest: isAllSelectedProductOnRequestSelector(state),
    frontendConfiguration: state.frontendConfiguration,
    thankYouPageData: state.thankYouPage,
    customerJourneyAnswer: state.customerJourneyAnswer,
    customerJourneyMapping: state.customerJourneyMapping,
    productSelections: productSelectionsSelector(state),
    routeManager: state.route,
    products: productsSelector(state),
    selectedProducts: selectedProductsSelector(state),
    emailTemplateV2Data: emailTemplateV2DataSelector(state),
    totalPriceV2: totalPriceCalcV2ExcludeOnRequestSelector(state),
    currState: state.currentState,
    kwConsumption: KWConsumptionForElectricitySelector(state),
    kwConsumptionHCGas: KWConsumptionForGasSelector(state),
    kwConsumptionHCHeating: KWConsumptionForHeatingSelector(state),
    litterPerSecConsumption: litterPerSecConsumptionSelector(state),
    meterConsumption: meterConsumptionSelector(state),
    meterStreetFrontLengthForHCWaterConsumption: meterStreetFrontLengthForHCWaterConsumptionSelector(
      state
    ),
    housingUnitForHCWaterConsumption: housingUnitForHCWaterConsumptionSelector(state),
    squareMetrePropertySizeForHCWaterConsumption: squareMetrePropertySizeForHCWaterConsumptionSelector(
      state
    ),
    squareMetreFloorSizeForHCWaterConsumption: squareMetreFloorSizeForHCWaterConsumptionSelector(
      state
    ),
    kwhConsumption: KWHConsumptionSelector(state),
    productPackageConfig: state.productPackageConfig,
    selectedAddOnV2MapKeyByProductType: state.addOnV2Selections.selectedAddOnV2MapKeyByProductType,
    subTotalPrice: subTotalPriceSelector(state),
    taxPricingSummary: taxPricingSummarySelector(state)
  };
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateProductSelections,
        updateCurrentPackage,
        createCurrProductPackages,
        updateCurrProductPackageConfig,
        removeSelectedAddOnV2,
        removeSelectedGlobalAddOnV2
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MixedOrderSummaryContainer);

import React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { ClipLoader } from 'react-spinners';
import { parse } from 'query-string';
import _ from 'lodash';
import Bugsnag from '@bugsnag/js';
import { ContactForm, CustomerJourney, Webshop, WebshopV2 } from '../containers';
import {
  convertProdTypeToProdName,
  generateOpportunityTitle,
  getSummaryDataModelV1,
  ShowIfUtil,
  getOnlinePurchasePaymentType,
  generateUploadFileData,
  retryMakeHttpRequest
} from '../common/utils';
import {
  CREATE_OPPORTUNITY_FAILURE,
  CREATE_OPPORTUNITY_SUCCESS,
  CREATE_PDF_FAILURE,
  createOpportunity,
  createPdfResponses,
  FRONTEND_CONFIGURATION_FAILURE,
  FRONTEND_CONFIGURATION_SUCCESS,
  GET_PRE_SIGNED_URL_FAILURE,
  getFrontendConfiguration,
  getPreSignedUrl,
  LINK_UPLOADED_ATTACHMENT_TO_OPP_FAILURE,
  linkUploadedAttachmentToOPP,
  processPayPalPayment,
  updateCustomerJourneyAnswer,
  updateFrontendConfiguration,
  updatePaymentInfo,
  updateRouterParam,
  updateThankYouPageData,
  UPLOAD_FILE_PRE_SIGNED_URL_FAILURE,
  uploadAttachment,
  uploadFilePreSignedUrl,
  updateContactForm
} from '../store/actions';
import {
  ATTRIBUTE,
  CONNECT_ERROR_PAGE,
  COUNTRY,
  CUSTOMER_TYPE,
  DOCUMENT_TYPE,
  EXCEED_RATE_LIMIT_PAGE,
  FRONTEND_TYPE,
  HOUSE_CONNECTION_IS_OWNER,
  LAUNCHING_SOON_PAGE,
  NOT_FOUND_PAGE,
  PACKAGE_PREMIUM_TYPE_KEY,
  PACKAGE_SELECTED_KEY,
  PACKAGE_TYPE,
  PAYMENT_OPTION,
  PAYMENT_TYPE,
  PRODUCT_TYPE,
  PURCHASE_TYPE,
  RENTAL_UNIT,
  SPECIAL_CHARACTER,
  STATUS,
  SUCCESS_PAGE,
  THANK_YOU_SCREEN_PAGE,
  UNSUPPORTED_BROWSER_PAGE,
  VISIBILITY_TYPE
} from '../common/constants';
import {
  createBundlingOPAddOnV2RequestParamsSelector,
  createNonBundlingOPAddOnV2RequestParamsSelector,
  emailTemplateV2DataSelector,
  getFontTextBoldSelector,
  getFontTextSelector,
  isAllProductsDeactivatedSelector,
  isAllProductsOnRequestSelector,
  onRequestSelectedProductTypesSelector,
  productSelectionsSelector,
  selectedProductsSelector,
  totalPriceCalcSelector
} from '../store/selectors';
import { detectIE } from '../common/utils/BrowserUtils';

class FrontendPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingFrontend: false,
      fontText: {
        font: '',
        fontUrl: ''
      },
      fontTextBold: {
        font: '',
        fontUrl: ''
      }
    };
    const {
      location: { search },
      updateRouterParam
    } = this.props;
    updateRouterParam(parse(search));
  }

  componentDidMount() {
    const {
      getFrontendConfiguration,
      location,
      history,
      updateFrontendConfiguration,
      updateThankYouPageData,
      updateCustomerJourneyAnswer
    } = this.props;
    const { token, street_name, street_no, postalcode, city, country_id } = parse(location.search);

    this.handleCustomFont();

    if (detectIE() && detectIE() < 11) {
      history.push(UNSUPPORTED_BROWSER_PAGE);
    }

    if (token) {
      this.setState({ loadingFrontend: true });
      getFrontendConfiguration({ token }).then(action => {
        const { frontendConfiguration } = this.props;

        let showPackageInfoSpecialCondition = false;

        if (frontendConfiguration.designOptions) {
          showPackageInfoSpecialCondition =
            frontendConfiguration.designOptions.showPackageInfoSpecialCondition;
        }

        let isShowPackageInfo = frontendConfiguration.isShowPackageInfo;
        switch (action.type) {
          case FRONTEND_CONFIGURATION_SUCCESS:
            this.appendPayPalScript(frontendConfiguration.onlinePurchaseSettingModel);
            if (
              showPackageInfoSpecialCondition &&
              showPackageInfoSpecialCondition.requiredConnectionValueRequired
            ) {
              isShowPackageInfo = false;
            }
            updateFrontendConfiguration({ token, isShowPackageInfo });
            updateThankYouPageData({
              orderNumber: '',
              userEmail: '',
              onlinePurchaseOnlyRequest: false
            });
            this.setState({ loadingFrontend: false });
            break;
          case FRONTEND_CONFIGURATION_FAILURE:
            this.setState({ loadingFrontend: false });
            this.routeToConnectErrorPage();
            break;
          default:
            break;
        }
      });

      let countries = [];
      let country = '';
      for (const key in COUNTRY) {
        countries.push(COUNTRY[key]);
      }
      if (country_id) {
        _.forEach(countries, item => {
          if (country_id === item.code) {
            country = item.englishName;
          }
        });
      }

      const addressDataFromQueryParam = {
        street: street_name,
        number: street_no,
        postalCode: postalcode,
        cityName: city,
        country: country
      };
      updateCustomerJourneyAnswer({
        address: addressDataFromQueryParam,
        streetListed: !(street_name || street_no),
        postalCode: !!postalcode && !!city ? postalcode + ' ' + city : '',
        postalCodePrefilled: !!postalcode && !!city
      });
    } else {
      history.push(NOT_FOUND_PAGE);
    }
  }

  componentDidUpdate(prevProps) {
    const { frontendConfiguration } = this.props;
    if (prevProps.frontendConfiguration === false && frontendConfiguration) {
      if (
        frontendConfiguration.frontendStatus !== STATUS.ACTIVE &&
        frontendConfiguration.frontendStatus !== STATUS.INCOMPLETE
      ) {
        this.routeToLaunchingSoonPage();
      }

      this.handleCustomFont();
    }
  }

  appendPayPalScript = onlinePurchaseSettingModel => {
    if (
      onlinePurchaseSettingModel &&
      onlinePurchaseSettingModel.active &&
      Array.isArray(onlinePurchaseSettingModel.paymentMethods) &&
      onlinePurchaseSettingModel.paymentMethods.length > 0
    ) {
      const payPalPaymentSetting = onlinePurchaseSettingModel.paymentMethods.find(
        item => item.paymentType === PAYMENT_OPTION.PAYMENTBYPAYPAL
      );
      if (payPalPaymentSetting && payPalPaymentSetting.active) {
        const payPalClientId = payPalPaymentSetting.configuration?.payPalClientId;
        const script = document.createElement('script');
        script.src = `https://www.paypal.com/sdk/js?client-id=${payPalClientId}&disable-funding=credit,card,venmo,sofort,sepa,p24,mybank,mercadopago,ideal,giropay,eps,blik,bancontact&currency=EUR&intent=authorize`;
        script.async = true;
        document.head.appendChild(script);
      }
    }
  };

  handleCustomFont = () => {
    const { fontText, fontTextBold } = this.props;

    // Custom font
    this.setState(prevState => ({
      fontText: {
        ...prevState.fontText,
        ...fontText
      }
    }));

    this.setState(prevState => ({
      fontTextBold: {
        ...prevState.fontTextBold,
        ...fontTextBold
      }
    }));
  };

  getQuestionUploadedFileInfo = () => {
    const { customerJourneyAnswer } = this.props;
    let attachments = [];
    const propertyDocument = [...customerJourneyAnswer.propertyDocument];
    const consentForm = [...customerJourneyAnswer.consentForm];
    const fileUpload = [...customerJourneyAnswer.fileUpload];

    if (propertyDocument.length === 0 && consentForm.length === 0 && fileUpload.length === 0) {
      return attachments;
    }

    if (customerJourneyAnswer.houseConnectionIsOwner === HOUSE_CONNECTION_IS_OWNER.YES) {
      attachments = propertyDocument;
    } else {
      attachments = propertyDocument.concat(consentForm);
    }

    let tmpUploadedFiles = [];

    _.forEach(fileUpload, elem => {
      _.forEach(elem.files, file => {
        file.labelIds = _.map(elem.fileUploadLabels, label => label.id);
        tmpUploadedFiles.push(file);
      });
    });

    if (tmpUploadedFiles.length > 0) {
      attachments = attachments.concat(tmpUploadedFiles);
    }
    return attachments;
  };

  /**
   * Link question uploaded file info to OPP
   *
   * @param customerId
   * @param questionUploadedFileInfo
   * @param opportunityIds
   * @returns {Promise<void>}
   */
  linkQuestionUploadedFileInfoToOpp = async ({
    customerId,
    questionUploadedFileInfo,
    opportunityIds
  }) => {
    try {
      const { preSignedUrls } = this.props;
      await retryMakeHttpRequest({
        callback: this.linkUploadedAttachmentToOppPromise,
        data: {
          customerId,
          bucketName: preSignedUrls[0]?.bucketName || '',
          opportunityFileData: _.flatten(
            _.map(questionUploadedFileInfo, file => {
              const preSignedUrlData =
                _.find(preSignedUrls, elem => {
                  if (typeof elem.originalFileName === 'string' && typeof file.name === 'string') {
                    return (
                      elem?.originalFileName?.localeCompare(file.name) === 0 &&
                      file.questionId === elem.questionId
                    );
                  }
                  return false;
                }) || {};
              return _.map(opportunityIds, id => ({
                fileName: preSignedUrlData?.fileName,
                fileType: `.${file.name?.split('.')?.pop()}`,
                fileSize: file.size,
                originalFileName: file.name,
                visibilityType: VISIBILITY_TYPE.PRIVATE,
                documentType: DOCUMENT_TYPE.OPPORTUNITY_ATTACHMENT,
                opportunityId: id,
                labelIds: file.labelIds ?? []
              }));
            })
          )
        },
        retryCount: 0,
        errorType: LINK_UPLOADED_ATTACHMENT_TO_OPP_FAILURE
      });
    } catch (e) {
      throw e;
    }
  };

  /**
   * Link contact form uploaded file info to OPP
   *
   * @param customerId
   * @param questionUploadedFileInfo
   * @param opportunityIds
   * @returns {Promise<void>}
   */
  linkContactFormUploadedFileInfoToOpp = async ({
    customerId,
    contactFormUploadedFileInfo,
    opportunityIds
  }) => {
    try {
      const { preSignedUrls } = this.props;
      await retryMakeHttpRequest({
        callback: this.linkUploadedAttachmentToOppPromise,
        data: {
          customerId,
          bucketName: preSignedUrls[0]?.bucketName || '',
          opportunityFileData: _.flatten(
            _.map(contactFormUploadedFileInfo, file => {
              const preSignedUrlData =
                _.find(preSignedUrls, elem => {
                  if (typeof elem.originalFileName === 'string' && typeof file.name === 'string') {
                    return elem?.originalFileName?.localeCompare(file.name) === 0;
                  }
                  return false;
                }) || {};
              return _.map(opportunityIds, id => ({
                fileName: preSignedUrlData?.fileName,
                fileType: `.${file.name?.split('.')?.pop()}`,
                fileSize: file.size,
                originalFileName: file.name,
                visibilityType: VISIBILITY_TYPE.PRIVATE,
                documentType: DOCUMENT_TYPE.OPPORTUNITY_ATTACHMENT,
                opportunityId: id,
                labelIds: file.labelIds ?? []
              }));
            })
          )
        },
        retryCount: 0,
        errorType: LINK_UPLOADED_ATTACHMENT_TO_OPP_FAILURE
      });
    } catch (e) {
      throw e;
    }
  };

  createOpportunities = ({
    customerId,
    customerFirstName,
    customerLastName,
    customerType,
    companyName,
    firstName,
    lastName,
    reasonForContacting,
    frontEndResponseId,
    orderNumber,
    opportunityBillingAddress,
    opportunityDeliveryAddress,
    opportunityAdditionalAddresses,
    opportunityDateApiModels,
    opportunityMultipleChoiceApiModels,
    title,
    opportunityPackageApiModelsData,
    purchaseType,
    opportunityApiPricingModelV2,
    contactCustomFields
  }) => {
    const {
      selectedProducts,
      frontendConfiguration,
      productSelections,
      allProductDeactivated,
      emailTemplateV2Data,
      thankYouPageData,
      allProductOnRequest,
      bundlingOPAddOnV2RequestParams,
      nonBundlingOPAddOnV2RequestParams,
      onRequestProductTypes,
      paymentInfo,
      customerJourneyAnswer
    } = this.props;

    if (frontendConfiguration.frontendType === FRONTEND_TYPE.CONTACT_FORM) {
      this.createOpportunitiesV1(
        [
          {
            frontendToken: frontendConfiguration.token,
            customerId,
            title,
            opportunityApiPricingModelV1: {
              opportunityPackageApiModels: opportunityPackageApiModelsData
            },
            frontEndResponseId,
            orderNumber,
            opportunityBillingAddress,
            opportunityDeliveryAddress,
            opportunityAdditionalAddresses,
            opportunityDateApiModels,
            opportunityMultipleChoiceApiModels,
            filesAttachedCounter: this.countFilesAttached(),
            browserVersion: navigator.appVersion,
            contactCustomFields
          }
        ],
        { customerFirstName, customerLastName }
      );
      return;
    }

    if (
      (allProductDeactivated || allProductOnRequest || customerJourneyAnswer.isUserInputPostcode) &&
      _.isEmpty(selectedProducts)
    ) {
      this.createOpportunitiesV1(
        [
          {
            frontendToken: frontendConfiguration.token,
            customerId,
            title: generateOpportunityTitle(
              convertProdTypeToProdName(PRODUCT_TYPE.GENERIC),
              customerType,
              companyName,
              firstName,
              lastName,
              reasonForContacting
            ),
            opportunityApiPricingModelV1:
              frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY
                ? {
                    opportunityPackageApiModels: [{ productId: -1 }]
                  }
                : null,
            opportunityApiPricingModelV2:
              frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2
                ? {
                    opportunityPackageApiModels: [{ productId: -1 }]
                  }
                : null,
            frontEndResponseId,
            orderNumber,
            opportunityBillingAddress,
            opportunityDeliveryAddress,
            opportunityAdditionalAddresses,
            opportunityDateApiModels,
            opportunityMultipleChoiceApiModels,
            filesAttachedCounter: this.countFilesAttached(),
            browserVersion: navigator.appVersion,
            contactCustomFields
          }
        ],
        { customerFirstName, customerLastName }
      );
      return;
    }

    let opportunityApiModel = {
      frontendToken: frontendConfiguration.token,
      title: SPECIAL_CHARACTER.EMPTY,
      customerId: customerId,
      orderNumber: orderNumber,
      frontEndResponseId: frontEndResponseId,
      opportunityBillingAddress: opportunityBillingAddress,
      opportunityDeliveryAddress: opportunityDeliveryAddress,
      opportunityAdditionalAddresses: opportunityAdditionalAddresses,
      opportunityDateApiModels: opportunityDateApiModels,
      opportunityMultipleChoiceApiModels: opportunityMultipleChoiceApiModels,
      filesAttachedCounter: this.countFilesAttached(),
      onRequestProductTypes: onRequestProductTypes,
      browserVersion: navigator.appVersion,
      contactCustomFields: contactCustomFields,
      paymentType: getOnlinePurchasePaymentType(paymentInfo.paymentType)
    };
    let opportunityPackageApiModels = [];
    let orderRequestOpportunityPackageApiModelsV1 = [];
    let onlinePurchaseOpportunityPackageApiModelsV1 = [];
    let listProductName = [];
    let listOpportunityModel = [];
    _.forEach(selectedProducts, selectedProduct => {
      const packageType = productSelections[PACKAGE_PREMIUM_TYPE_KEY[selectedProduct.type]]
        ? PACKAGE_TYPE.PREMIUM
        : PACKAGE_TYPE.STANDARD;
      const selectedPackage = productSelections[PACKAGE_SELECTED_KEY[selectedProduct.type]];
      const packageByProductPackagesSummaryV2 = _.find(
        emailTemplateV2Data.productPackagesSummary,
        item => item.productType === selectedProduct.type
      );
      const packageSummaryModelV1 = _.find(
        getSummaryDataModelV1(selectedProducts, productSelections).packageSummaryModels,
        item => item.productType === selectedProduct.type
      );
      const purchaseTypeData =
        frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2
          ? this.getPurchaseTypeIsNotBundleConfigV2({
              purchaseType,
              packageByProductPackagesSummaryV2,
              thankYouPageData
            })
          : this.getPurchaseTypeIsNotBundleConfigV1({
              purchaseType,
              packageSummaryModelV1,
              thankYouPageData
            });
      let addons = [];
      let opportunityApiModel = {
        frontendToken: frontendConfiguration.token,
        title: SPECIAL_CHARACTER.EMPTY,
        customerId: customerId,
        frontEndResponseId: frontEndResponseId,
        orderNumber: purchaseTypeData === PURCHASE_TYPE.REQUEST ? null : orderNumber,
        purchaseType: purchaseTypeData,
        opportunityBillingAddress: opportunityBillingAddress,
        opportunityDeliveryAddress: opportunityDeliveryAddress,
        opportunityAdditionalAddresses: opportunityAdditionalAddresses,
        opportunityDateApiModels: opportunityDateApiModels,
        opportunityMultipleChoiceApiModels: opportunityMultipleChoiceApiModels,
        filesAttachedCounter: this.countFilesAttached(),
        onRequestProductTypes: onRequestProductTypes,
        browserVersion: navigator.appVersion,
        contactCustomFields: contactCustomFields,
        paymentType: getOnlinePurchasePaymentType(paymentInfo.paymentType)
      };

      listProductName.push(convertProdTypeToProdName(selectedProduct.type));

      _.forEach(selectedProduct.addons, addon => {
        if (productSelections.selectedAddonIds.indexOf(addon.id) >= 0) {
          addons.push(addon.id);
        }
      });

      const opportunityPackageApiModel = {
        productId: selectedProduct.id,
        productPackageId: productSelections[PACKAGE_SELECTED_KEY[selectedProduct.type]].id,
        packageType: productSelections[PACKAGE_PREMIUM_TYPE_KEY[selectedProduct.type]] ? 1 : 0,
        addons: addons,
        monthlyInterest: this.filterInterestByPackageType(packageType, selectedPackage),
        rentalPrice: this.filterMonthlyPriceByPackageType(packageType, selectedPackage)
      };
      if (!_.isEmpty(packageSummaryModelV1) && packageSummaryModelV1.onRequest) {
        orderRequestOpportunityPackageApiModelsV1.push(opportunityPackageApiModel);
      }
      if (!_.isEmpty(packageSummaryModelV1) && !packageSummaryModelV1.onRequest) {
        onlinePurchaseOpportunityPackageApiModelsV1.push(opportunityPackageApiModel);
      }
      opportunityPackageApiModels.push(opportunityPackageApiModel);

      if (!frontendConfiguration.isProductBundlingEnable && !allProductDeactivated) {
        opportunityApiModel.title = _.isEmpty(listProductName)
          ? generateOpportunityTitle(
              convertProdTypeToProdName(PRODUCT_TYPE.GENERIC),
              customerType,
              companyName,
              firstName,
              lastName,
              reasonForContacting
            )
          : generateOpportunityTitle(
              convertProdTypeToProdName(selectedProduct.type),
              customerType,
              companyName,
              firstName,
              lastName,
              reasonForContacting
            );
        if (frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2) {
          opportunityApiModel.opportunityApiPricingModelV2 = {
            opportunityPackageApiModels: [
              _.find(
                opportunityApiPricingModelV2.opportunityPackageApiModels,
                elem => elem.productId === selectedProduct.id
              )
            ],
            variableModels: {
              [selectedProduct.type]:
                opportunityApiPricingModelV2.variableModels[selectedProduct.type]
            }
          };
          opportunityApiModel.selectedAddonSellingOptionIds =
            nonBundlingOPAddOnV2RequestParams[selectedProduct.type];
        } else {
          opportunityApiModel.opportunityApiPricingModelV1 = {
            paymentMethod:
              productSelections.selectedPurchaseRental === PAYMENT_TYPE.PURCHASE ? 0 : 1,
            rentalDuration: productSelections.selectedRentalInfo.duration,
            durationUnit: RENTAL_UNIT[productSelections.selectedRentalInfo.unit],
            opportunityPackageApiModels: [...opportunityPackageApiModels]
          };
        }

        listOpportunityModel.push(opportunityApiModel);
        opportunityPackageApiModels = [];
      }
    });

    if (frontendConfiguration.isProductBundlingEnable && !allProductDeactivated) {
      opportunityApiModel.title = _.isEmpty(listProductName)
        ? generateOpportunityTitle(
            convertProdTypeToProdName(PRODUCT_TYPE.GENERIC),
            customerType,
            companyName,
            firstName,
            lastName,
            reasonForContacting
          )
        : generateOpportunityTitle(
            listProductName.join(', '),
            customerType,
            companyName,
            firstName,
            lastName,
            reasonForContacting
          );
      if (frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2) {
        if (purchaseType !== PURCHASE_TYPE.ONLINE_PURCHASE) {
          const opportunityApiModelData = {
            ...opportunityApiModel,
            orderNumber: null,
            purchaseType: PURCHASE_TYPE.REQUEST,
            opportunityApiPricingModelV2: {
              opportunityPackageApiModels: _.isEmpty(listProductName)
                ? [
                    {
                      productId: -1
                    }
                  ]
                : [...opportunityApiPricingModelV2.opportunityPackageApiModels],
              variableModels: { ...opportunityApiPricingModelV2.variableModels }
            },
            selectedAddonSellingOptionIds: bundlingOPAddOnV2RequestParams
          };
          listOpportunityModel.push(opportunityApiModelData);
        } else {
          if (!_.isEmpty(opportunityApiPricingModelV2.onlinePurchaseOpportunityPackageApiModels)) {
            const onlinePurchaseOpportunityBundle = {
              ...opportunityApiModel,
              orderNumber: orderNumber,
              purchaseType: PURCHASE_TYPE.ONLINE_PURCHASE,
              opportunityApiPricingModelV2: {
                opportunityPackageApiModels: _.isEmpty(listProductName)
                  ? [
                      {
                        productId: -1
                      }
                    ]
                  : [...opportunityApiPricingModelV2.onlinePurchaseOpportunityPackageApiModels],
                variableModels: { ...opportunityApiPricingModelV2.onlinePurchaseVariableModels }
              },
              selectedAddonSellingOptionIds: bundlingOPAddOnV2RequestParams
            };
            listOpportunityModel.push(onlinePurchaseOpportunityBundle);
          }
          if (!_.isEmpty(opportunityApiPricingModelV2.orderRequestOpportunityPackageApiModels)) {
            const orderRequestOpportunityBundle = {
              ...opportunityApiModel,
              orderNumber: null,
              purchaseType: PURCHASE_TYPE.REQUEST,
              opportunityApiPricingModelV2: {
                opportunityPackageApiModels: _.isEmpty(listProductName)
                  ? [
                      {
                        productId: -1
                      }
                    ]
                  : [...opportunityApiPricingModelV2.orderRequestOpportunityPackageApiModels],
                variableModels: { ...opportunityApiPricingModelV2.orderRequestVariableModels }
              },
              selectedAddonSellingOptionIds: bundlingOPAddOnV2RequestParams
            };
            listOpportunityModel.push(orderRequestOpportunityBundle);
            // this.createOpportunity(orderRequestOpportunityBundle);
          }
        }
      } else {
        let opportunityApiPricingModelV1 = {
          rentalDuration: productSelections.selectedRentalInfo.duration,
          durationUnit: RENTAL_UNIT[productSelections.selectedRentalInfo.unit]
        };
        if (purchaseType !== PURCHASE_TYPE.ONLINE_PURCHASE) {
          opportunityApiPricingModelV1 = {
            ...opportunityApiPricingModelV1,
            // Hot fix 254
            paymentMethod:
              productSelections.selectedPurchaseRental === PAYMENT_TYPE.PURCHASE ? 0 : 1,
            opportunityPackageApiModels: [...opportunityPackageApiModels]
          };
          opportunityApiModel.opportunityApiPricingModelV1 = { ...opportunityApiPricingModelV1 };
          listOpportunityModel.push(opportunityApiModel);
        } else {
          if (!_.isEmpty(orderRequestOpportunityPackageApiModelsV1)) {
            opportunityApiPricingModelV1 = {
              ...opportunityApiPricingModelV1,
              paymentMethod: 1,
              opportunityPackageApiModels: [...orderRequestOpportunityPackageApiModelsV1]
            };
            listOpportunityModel.push({
              ...opportunityApiModel,
              opportunityApiPricingModelV1: { ...opportunityApiPricingModelV1 }
            });
          }
          if (!_.isEmpty(onlinePurchaseOpportunityPackageApiModelsV1)) {
            opportunityApiPricingModelV1 = {
              ...opportunityApiPricingModelV1,
              paymentMethod: 0,
              opportunityPackageApiModels: [...onlinePurchaseOpportunityPackageApiModelsV1]
            };
            listOpportunityModel.push({
              ...opportunityApiModel,
              opportunityApiPricingModelV1: { ...opportunityApiPricingModelV1 }
            });
          }
        }
      }
    }
    this.createOpportunitiesV1(listOpportunityModel, {
      customerLastName,
      customerFirstName
    });
  };

  getPurchaseTypeIsNotBundleConfigV1 = ({
    purchaseType,
    packageSummaryModelV1,
    thankYouPageData
  }) => {
    let purchaseTypeData = PURCHASE_TYPE.REQUEST;
    if (purchaseType !== PURCHASE_TYPE.REQUEST) {
      if (
        !_.isEmpty(packageSummaryModelV1) &&
        !packageSummaryModelV1.onRequest &&
        !thankYouPageData.onlinePurchaseOnlyRequest
      ) {
        purchaseTypeData = PURCHASE_TYPE.ONLINE_PURCHASE;
      }
    }
    return purchaseTypeData;
  };

  getPurchaseTypeIsNotBundleConfigV2 = ({
    purchaseType,
    packageByProductPackagesSummaryV2,
    thankYouPageData
  }) => {
    let purchaseTypeData = PURCHASE_TYPE.REQUEST;
    if (purchaseType !== PURCHASE_TYPE.REQUEST) {
      if (
        !_.isEmpty(packageByProductPackagesSummaryV2) &&
        !packageByProductPackagesSummaryV2.onRequest &&
        !packageByProductPackagesSummaryV2.alwaysShowOnRequest &&
        !thankYouPageData.onlinePurchaseOnlyRequest
      ) {
        purchaseTypeData = PURCHASE_TYPE.ONLINE_PURCHASE;
      }
    }
    return purchaseTypeData;
  };

  countFilesAttached = () => {
    const { frontendConfiguration, contactFormData } = this.props;
    const saveTaxDocumentsToOpp = _.find(frontendConfiguration.availableFields, {
      key: ATTRIBUTE.SAVE_TAX_DOCUMENT_TO_OPP_KEY
    });
    let filesAttachedCounter = 0;
    if (contactFormData.customerType === CUSTOMER_TYPE.BUSINESS) {
      if (
        _.isArray(contactFormData.uploadTaxDocuments) &&
        contactFormData.uploadTaxDocuments.length > 0 &&
        (saveTaxDocumentsToOpp && saveTaxDocumentsToOpp.enable)
      ) {
        filesAttachedCounter += [...contactFormData.uploadTaxDocuments].length;
      }
    }

    if (
      _.isArray(contactFormData.relatedDocuments) &&
      contactFormData.relatedDocuments.length > 0
    ) {
      filesAttachedCounter += [...contactFormData.relatedDocuments].length;
    }
    filesAttachedCounter += this.getQuestionUploadedFileInfo().length;
    if (frontendConfiguration.displayPdfResponse && frontendConfiguration.pdfFilenameResponses) {
      filesAttachedCounter++;
    }
    return filesAttachedCounter;
  };

  /**
   * Upload all attachments of journey
   *
   * @param action
   * @param frontendConfiguration
   * @param contactFormData
   * @param customerId
   * @param customerLastName
   * @param customerFirstName
   * @returns {Promise<void>}
   */
  handleUploadAttachments = async ({
    action,
    frontendConfiguration,
    contactFormData,
    customerId,
    customerLastName,
    customerFirstName
  }) => {
    const { preSignedUrls } = this.props;
    let opportunityIds;
    const uploadedRelatedDocumentInfo = [];

    const taxDocumentUploadedFilesInfo = [];
    const taxDocumentUploadedFiles = [];
    // Uploaded files in question (consent form, property, custom upload file question)
    const questionUploadedFileInfo = [];
    try {
      opportunityIds = _.map(action.payload, data => data.opportunityId);

      const saveTaxDocumentsToOpp = _.find(frontendConfiguration.availableFields, {
        key: ATTRIBUTE.SAVE_TAX_DOCUMENT_TO_OPP_KEY
      });

      if (contactFormData.customerType === CUSTOMER_TYPE.BUSINESS) {
        if (
          contactFormData.uploadTaxDocuments?.length > 0 &&
          (saveTaxDocumentsToOpp && saveTaxDocumentsToOpp.enable)
        ) {
          taxDocumentUploadedFiles.push(...contactFormData.uploadTaxDocuments);
          taxDocumentUploadedFilesInfo.push(
            ...generateUploadFileData({
              files: [...contactFormData.uploadTaxDocuments],
              customerFirstName,
              isTaxDocument: true,
              customerLastName
            })
          );
          // We need to upload tax document after creating customer because of customer info required
          await this.handleUploadTaxDocumentsUsingPreSignedUrl({
            files: taxDocumentUploadedFiles,
            uploadFileData: taxDocumentUploadedFilesInfo,
            opportunityIds: opportunityIds,
            customerId
          });
        }
      }

      if (contactFormData.relatedDocuments?.length > 0) {
        uploadedRelatedDocumentInfo.push(...contactFormData.relatedDocuments);
        await this.linkContactFormUploadedFileInfoToOpp({
          contactFormUploadedFileInfo: uploadedRelatedDocumentInfo,
          customerId,
          opportunityIds
        });
      }

      if (!_.isEmpty(this.getQuestionUploadedFileInfo())) {
        questionUploadedFileInfo.push(...this.getQuestionUploadedFileInfo());
        await this.linkQuestionUploadedFileInfoToOpp({
          questionUploadedFileInfo,
          customerId,
          opportunityIds
        });
      }
    } catch (error) {
      const opportunityIds = _.map(action.payload, data => data.opportunityId);
      Bugsnag.notify(error, function(event) {
        event.severity = 'error';
        event.context = 'upload-attachment';
        event.addMetadata('payload', { payload: error?.payload });
        if (preSignedUrls?.length > 0) {
          event.addMetadata('uploadedFileInfo', {
            info: preSignedUrls
          });
        }
        event.addMetadata('opportunityIds', { ids: opportunityIds });
      });
      throw error;
    }
  };

  /**
   * Generate PDF response and attach to created OPP
   *
   * @param frontendConfiguration
   * @param action
   * @returns {Promise<void>}
   */
  handleGeneratePDFResponse = async ({ frontendConfiguration, action }) => {
    try {
      await retryMakeHttpRequest({
        data: {
          data: action.payload,
          fileName: frontendConfiguration.pdfFilenameResponses
        },
        retryCount: 0,
        callback: this.createPdfPromises,
        errorType: CREATE_PDF_FAILURE
      });
    } catch (error) {
      const opportunityIds = _.map(action.payload, data => data.opportunityId);
      Bugsnag.notify(error, function(event) {
        event.severity = 'error';
        event.context = 'generate-frontend-log-pdf-response';
        event.addMetadata('payload', { payload: error?.payload });
        event.addMetadata('opportunityIds', { ids: opportunityIds });
      });
      throw error;
    }
  };

  /**
   * Handle payment by paypal
   *
   * @param action
   * @returns {Promise<void>}
   */
  handlePayPalPayment = async action => {
    const {
      frontendConfiguration,
      processPayPalPayment,
      updatePaymentInfo,
      paymentInfo
    } = this.props;
    try {
      if (
        frontendConfiguration.onlinePurchaseSettingModel &&
        frontendConfiguration.onlinePurchaseSettingModel.active &&
        Array.isArray(frontendConfiguration.onlinePurchaseSettingModel.paymentMethods) &&
        frontendConfiguration.onlinePurchaseSettingModel.paymentMethods.length > 0
      ) {
        const payPalPaymentSetting = frontendConfiguration.onlinePurchaseSettingModel.paymentMethods.find(
          item => item.paymentType === PAYMENT_OPTION.PAYMENTBYPAYPAL
        );
        if (
          payPalPaymentSetting &&
          payPalPaymentSetting.active &&
          paymentInfo.paymentType === PAYMENT_OPTION.PAYMENTBYPAYPAL &&
          paymentInfo.payPalConnected
        ) {
          const opportunityIds = _.map(action.payload, data => data.opportunityId);
          const params = {
            order_id: paymentInfo.orderId,
            authorization_id: paymentInfo.authorizationId,
            buyer_email: paymentInfo.buyerEmail,
            brand_id: frontendConfiguration.brandId,
            opportunity_ids: opportunityIds
          };

          const payPalAction = await processPayPalPayment(params);

          if (payPalAction.status === 201 || payPalAction.status === 200) {
            updatePaymentInfo({ paid: true });
          }
        }
      }
    } catch (error) {
      const tmpError = new Error('Paypal failed');
      tmpError.payload = error;
      const opportunityIds = _.map(action.payload, data => data.opportunityId);
      Bugsnag.notify(tmpError, function(event) {
        event.severity = 'error';
        event.context = 'paypal';
        event.addMetadata('opportunityIds', { ids: opportunityIds });
        event.addMetadata('payload', { payload: tmpError?.payload });
      });
      throw error;
    }
  };

  /**
   * Call API create OPP
   *
   * @param opportunityModels
   * @param customerFirstName
   * @param customerLastName
   */
  createOpportunitiesV1 = (opportunityModels, { customerFirstName, customerLastName }) => {
    const { createOpportunity, updateContactForm } = this.props;
    createOpportunity(opportunityModels).then(async action => {
      switch (action.type) {
        case CREATE_OPPORTUNITY_SUCCESS:
          await this.handlePostCreateOppProcess({
            opportunityModels,
            action,
            customerFirstName,
            customerLastName
          });
          break;
        case CREATE_OPPORTUNITY_FAILURE:
          Bugsnag.notify(
            new Error(`${action.status}: ${action.type} ${action.payload?.errorMessage}`)
          );
          updateContactForm({
            isSubmittingContactForm: false
          });
          this.routeToConnectErrorPage();
          break;
        default:
          break;
      }
    });
  };

  /**
   * There are 3 different processes after creating OPP successfully:
   * 1. Upload attachments
   * 2. Handle payment by Paypal
   * 3. Generate Frontend log PDF response
   *
   * @param opportunityModels
   * @param action
   * @param customerFirstName
   * @param customerLastName
   * @returns {Promise<void>}
   */
  handlePostCreateOppProcess = async ({
    opportunityModels,
    action,
    customerFirstName,
    customerLastName
  }) => {
    const {
      frontendConfiguration,
      contactFormData,
      uploadFileStatus: { errors: uploadFileErrors },
      updateContactForm
    } = this.props;
    let isError = false;
    try {
      await this.handleUploadAttachments({
        frontendConfiguration,
        contactFormData,
        action,
        customerId: opportunityModels[0]?.customerId,
        customerFirstName: customerFirstName,
        customerLastName: customerLastName
      });
    } catch (error) {
      isError = true;
    }

    try {
      await this.handlePayPalPayment(action);
    } catch (error) {
      isError = true;
    }

    if (frontendConfiguration.displayPdfResponse && frontendConfiguration.pdfFilenameResponses) {
      try {
        await this.handleGeneratePDFResponse({ frontendConfiguration, action });
      } catch (error) {
        isError = true;
      }
    }

    updateContactForm({
      isSubmittingContactForm: false
    });

    if (isError) {
      this.routeToConnectErrorPage();
    } else {
      // Report upload file error when uploading file in question or related documents
      if (uploadFileErrors?.length > 0) {
        const opportunityIds = _.map(action.payload, data => data.opportunityId);
        Bugsnag.notify(new Error(`Some files failed to upload`), function(event) {
          event.severity = 'error';
          event.context = 'upload-attachment';
          event.addMetadata('payload', { payload: JSON.stringify(uploadFileErrors) });
          event.addMetadata('opportunityIds', { ids: opportunityIds });
        });
      }
      this.routeToSuccessPage();
    }
  };

  /**
   * Get S3 Opp document bucket pre-signed URL and upload file using that url
   *
   * @param files
   * @param opportunityIds
   * @param customerId
   * @param uploadFileData
   * @returns {Promise<void>}
   */
  handleUploadTaxDocumentsUsingPreSignedUrl = async ({
    files,
    opportunityIds,
    customerId,
    uploadFileData
  }) => {
    try {
      const preSignedUrlResponses = await retryMakeHttpRequest({
        callback: this.getPreSignedUrlPromise,
        data: { uploadFileData },
        retryCount: 0,
        errorType: GET_PRE_SIGNED_URL_FAILURE
      });

      for (const file of files) {
        await retryMakeHttpRequest({
          callback: this.uploadFileUsingPreSignUrlPromise,
          data: {
            files: [file],
            preSignedUrlData: _.map(preSignedUrlResponses, response => response.payload)
          },
          retryCount: 0,
          errorType: UPLOAD_FILE_PRE_SIGNED_URL_FAILURE
        });
      }

      await retryMakeHttpRequest({
        callback: this.linkUploadedAttachmentToOppPromise,
        data: {
          customerId,
          bucketName: preSignedUrlResponses[0]?.payload?.bucketName || '',
          opportunityFileData: _.flatten(
            _.map(files, file => {
              const preSignedUrlData =
                _.find(preSignedUrlResponses, elem => {
                  if (
                    typeof elem.payload?.originalFileName === 'string' &&
                    typeof file.name === 'string'
                  ) {
                    return elem.payload?.originalFileName?.localeCompare(file.name) === 0;
                  }
                  return false;
                }) || {};
              return _.map(opportunityIds, id => ({
                fileName: preSignedUrlData?.payload?.fileName,
                fileType: `.${file.name?.split('.')?.pop()}`,
                fileSize: file.size,
                originalFileName: file.name,
                visibilityType: VISIBILITY_TYPE.PRIVATE,
                documentType: DOCUMENT_TYPE.OPPORTUNITY_ATTACHMENT,
                opportunityId: id,
                labelIds: file.labelIds ?? []
              }));
            })
          )
        },
        retryCount: 0,
        errorType: LINK_UPLOADED_ATTACHMENT_TO_OPP_FAILURE
      });
    } catch (error) {
      Bugsnag.notify(error, function(event) {
        event.severity = 'error';
        event.context = 'upload-attachment';
        event.addMetadata('opportunityIds', { ids: opportunityIds });
        if (error?.payload) {
          event.addMetadata('payload', { payload: error.payload });
        }
      });
      throw error;
    }
  };

  /**
   * Return list promise of link uploaded file info to corresponding opp action
   *
   * @param data
   * @returns {Promise[]}
   */
  linkUploadedAttachmentToOppPromise = data => {
    const { linkUploadedAttachmentToOPP } = this.props;
    return [linkUploadedAttachmentToOPP(data)];
  };

  /**
   * Return list promise of upload file to s3 bucket action
   *
   * @param preSignedUrlData
   * @param files
   * @returns {Promise[]}
   */
  uploadFileUsingPreSignUrlPromise = ({ preSignedUrlData, files }) => {
    const { uploadFilePreSignedUrl } = this.props;
    return _.map(files, file => {
      return uploadFilePreSignedUrl({
        endpoint:
          _.find(preSignedUrlData, data => {
            if (typeof data.originalFileName === 'string' && typeof file.name === 'string') {
              return data.originalFileName?.localeCompare(file.name) === 0;
            }
            return false;
          })?.uploadURL || '',
        file: file,
        contentType: file.type || 'text/plain'
      });
    });
  };

  /**
   * Return list promise of get S3 pre-signed url action
   *
   * @param uploadFileData
   * @returns {Promise[]}
   */
  getPreSignedUrlPromise = ({ uploadFileData }) => {
    const { getPreSignedUrl } = this.props;
    return _.map(uploadFileData, data => {
      return getPreSignedUrl({
        ...data
      });
    });
  };

  upload = async (attachments, opportunityId) => {
    const { uploadAttachment } = this.props;
    attachments.append('opportunityId', opportunityId.toString());
    return await uploadAttachment(attachments);
  };

  /**
   * Return list promise of create pdf action
   *
   * @param data
   * @param fileName
   * @returns {unknown[]}
   */
  createPdfPromises = ({ data, fileName }) => {
    const { createPdfResponses } = this.props;
    return _.map(data, elem =>
      createPdfResponses({
        frontendResponseId: elem.frontEndResponseId,
        opportunityId: elem.opportunityId,
        symbolId: elem.symbolId,
        fileName
      })
    );
  };

  filterInterestByPackageType = (packageType, packageSelected) => {
    const { productSelections } = this.props;
    let interest = 0;

    if (packageSelected && packageSelected.packageRentals) {
      _.forEach(packageSelected.packageRentals, object => {
        if (
          object.packageRentalType === packageType &&
          object.duration === productSelections.selectedRentalInfo.duration &&
          object.unit === RENTAL_UNIT[productSelections.selectedRentalInfo.unit]
        ) {
          interest = object.interest;
        }
      });
    }
    return interest;
  };

  filterMonthlyPriceByPackageType = (packageType, packageSelected) => {
    const { productSelections } = this.props;
    let monthlyPrice = 0;

    if (packageSelected && packageSelected.packageRentals) {
      _.forEach(packageSelected.packageRentals, object => {
        if (
          object.packageRentalType === packageType &&
          object.duration === productSelections.selectedRentalInfo.duration &&
          object.unit === RENTAL_UNIT[productSelections.selectedRentalInfo.unit]
        ) {
          monthlyPrice = object.price;
        }
      });
    }
    return monthlyPrice;
  };

  routeToLaunchingSoonPage = () => {
    const { history } = this.props;
    history.push(LAUNCHING_SOON_PAGE);
  };

  routeToConnectErrorPage = () => {
    const { history } = this.props;
    history.push(CONNECT_ERROR_PAGE);
  };

  routeToExceedRateLimitPage = () => {
    const { history } = this.props;
    history.push(EXCEED_RATE_LIMIT_PAGE);
  };

  routeToSuccessPage = () => {
    const { frontendConfiguration, history } = this.props;

    if (
      frontendConfiguration &&
      frontendConfiguration.frontendType !== FRONTEND_TYPE.CUSTOMER_JOURNEY &&
      frontendConfiguration.frontendType !== FRONTEND_TYPE.CUSTOMER_JOURNEY_V2
    ) {
      history.push(SUCCESS_PAGE);
    } else {
      history.push(THANK_YOU_SCREEN_PAGE);
    }
  };

  render() {
    const { fontText, fontTextBold, loadingFrontend } = this.state;
    const { frontendConfiguration } = this.props;
    const linkColor =
      frontendConfiguration.frontendType === FRONTEND_TYPE.CONTACT_FORM
        ? frontendConfiguration.buttonBackground
        : frontendConfiguration.primaryColor;

    if (loadingFrontend)
      return (
        <div
          className="container-fluid"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '600px',
            width: '100%'
          }}
        >
          <ClipLoader size={70} color="#4084e8" />
        </div>
      );

    return (
      <div>
        <ShowIfUtil condition={frontendConfiguration.frontendType === FRONTEND_TYPE.CONTACT_FORM}>
          <ContactForm
            routeToExceedRateLimitPage={this.routeToExceedRateLimitPage}
            routeToConnectErrorPage={this.routeToConnectErrorPage}
            routeToSuccessPage={this.routeToSuccessPage}
            createOpportunities={this.createOpportunities}
            fontText={fontText}
            fontTextBold={fontTextBold}
            linkColor={linkColor}
          />
        </ShowIfUtil>
        <ShowIfUtil condition={frontendConfiguration.frontendType === FRONTEND_TYPE.WEBSHOP}>
          <Webshop
            routeToConnectErrorPage={this.routeToConnectErrorPage}
            routeToSuccessPage={this.routeToSuccessPage}
            createOpportunities={this.createOpportunities}
            fontText={fontText}
            fontTextBold={fontTextBold}
            linkColor={linkColor}
          />
        </ShowIfUtil>
        <ShowIfUtil condition={frontendConfiguration.frontendType === FRONTEND_TYPE.WEBSHOP_V2}>
          <WebshopV2
            routeToConnectErrorPage={this.routeToConnectErrorPage}
            routeToSuccessPage={this.routeToSuccessPage}
            createOpportunities={this.createOpportunities}
            fontText={fontText}
            fontTextBold={fontTextBold}
            linkColor={linkColor}
          />
        </ShowIfUtil>
        <ShowIfUtil
          condition={frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY}
        >
          <CustomerJourney
            routeToConnectErrorPage={this.routeToConnectErrorPage}
            routeToSuccessPage={this.routeToSuccessPage}
            createOpportunities={this.createOpportunities}
            fontText={fontText}
            fontTextBold={fontTextBold}
            linkColor={linkColor}
          />
        </ShowIfUtil>
        <ShowIfUtil
          condition={frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2}
        >
          <CustomerJourney
            routeToConnectErrorPage={this.routeToConnectErrorPage}
            routeToSuccessPage={this.routeToSuccessPage}
            createOpportunities={this.createOpportunities}
            fontText={fontText}
            fontTextBold={fontTextBold}
            linkColor={linkColor}
          />
        </ShowIfUtil>
      </div>
    );
  }
}

FrontendPage.propTypes = {
  thankYouPageData: PropTypes.object,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getFrontendConfiguration: PropTypes.func.isRequired,
  updateFrontendConfiguration: PropTypes.func.isRequired,
  updateThankYouPageData: PropTypes.func.isRequired,
  getPreSignedUrl: PropTypes.func.isRequired,
  uploadFilePreSignedUrl: PropTypes.func.isRequired,
  linkUploadedAttachmentToOPP: PropTypes.func.isRequired,
  createOpportunity: PropTypes.func.isRequired,
  uploadAttachment: PropTypes.func.isRequired,
  updateRouterParam: PropTypes.func.isRequired,
  frontendConfiguration: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
  productSelections: PropTypes.object,
  customerJourneyAnswer: PropTypes.object,
  fontText: PropTypes.object,
  fontTextBold: PropTypes.object,
  contactFormData: PropTypes.object,
  selectedProducts: PropTypes.array,
  allProductDeactivated: PropTypes.bool,
  allProductOnRequest: PropTypes.bool,
  emailTemplateV2Data: PropTypes.object.isRequired,
  nonBundlingOPAddOnV2RequestParams: PropTypes.object.isRequired,
  bundlingOPAddOnV2RequestParams: PropTypes.object.isRequired,
  createPdfResponses: PropTypes.func.isRequired,
  onRequestProductTypes: PropTypes.array.isRequired,
  updateCustomerJourneyAnswer: PropTypes.func,
  paymentInfo: PropTypes.object,
  updateContactForm: PropTypes.func.isRequired,
  processPayPalPayment: PropTypes.func.isRequired,
  updatePaymentInfo: PropTypes.func.isRequired,
  preSignedUrls: PropTypes.array.isRequired,
  uploadFileStatus: PropTypes.object.isRequired
};

FrontendPage.defaultProps = {
  thankYouPageData: {},
  fontText: {},
  fontTextBold: {},
  selectedProducts: [],
  productSelections: {},
  customerJourneyAnswer: {},
  contactFormData: {},
  allProductDeactivated: true,
  allProductOnRequest: true,
  updateCustomerJourneyAnswer: () => ({}),
  paymentInfo: {}
};

const mapStateToProps = state => {
  return {
    thankYouPageData: state.thankYouPage,
    frontendConfiguration: state.frontendConfiguration,
    productSelections: productSelectionsSelector(state),
    customerJourneyAnswer: state.customerJourneyAnswer,
    totalPrice: totalPriceCalcSelector(state),
    selectedProducts: selectedProductsSelector(state),
    allProductDeactivated: isAllProductsDeactivatedSelector(state),
    allProductOnRequest: isAllProductsOnRequestSelector(state),
    fontText: getFontTextSelector(state),
    contactFormData: state.contactFormData,
    fontTextBold: getFontTextBoldSelector(state),
    emailTemplateV2Data: emailTemplateV2DataSelector(state),
    bundlingOPAddOnV2RequestParams: createBundlingOPAddOnV2RequestParamsSelector(state),
    nonBundlingOPAddOnV2RequestParams: createNonBundlingOPAddOnV2RequestParamsSelector(state),
    onRequestProductTypes: onRequestSelectedProductTypesSelector(state),
    paymentInfo: state.paymentInfo,
    preSignedUrls: state.uploadFileStatus.preSignedUrls,
    uploadFileStatus: state.uploadFileStatus
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators(
    {
      uploadFilePreSignedUrl,
      linkUploadedAttachmentToOPP,
      getFrontendConfiguration,
      createOpportunity,
      uploadAttachment,
      updateFrontendConfiguration,
      updateThankYouPageData,
      updateRouterParam,
      createPdfResponses,
      updateCustomerJourneyAnswer,
      getPreSignedUrl,
      processPayPalPayment,
      updatePaymentInfo,
      updateContactForm
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FrontendPage);

export const UPDATE_PRODUCT_SELECTIONS = 'UPDATE_PRODUCT_SELECTIONS';
export const RESET_PRODUCT_SELECTIONS = 'RESET_PRODUCT_SELECTIONS';
export const SELECT_ALL_PRODUCT_SELECTIONS = 'SELECT_PRODUCT_SELECTIONS';
export const REMOVE_SELECTED_ADDONS = 'REMOVE_SELECTED_ADDONS';
export const UPDATE_SELECTED_ADDON = 'UPDATE_SELECTED_ADDONS';
export const REMOVE_AUTOMATIC_SELECTED_ADDON = 'UPDATE_AUTOMATIC_SELECTED_ADDONS';

export const updateProductSelections = data => ({
  type: UPDATE_PRODUCT_SELECTIONS,
  payload: data
});

export const resetProductSelections = () => ({
  type: RESET_PRODUCT_SELECTIONS
});

export const selectAllProductSelections = () => ({
  type: SELECT_ALL_PRODUCT_SELECTIONS
});

export const removeSelectedAddons = data => ({
  type: REMOVE_SELECTED_ADDONS,
  payload: data
});

export const updateSelectedAddon = data => ({
  type: UPDATE_SELECTED_ADDON,
  payload: data
});

export const removeAutomaticSelectedAddon = data => ({
  type: REMOVE_AUTOMATIC_SELECTED_ADDON,
  payload: data
});

export const LANGUAGE = {
  EN: 'EN',
  DE: 'DE'
};

export const LOCALE = {
  EN: 'en',
  DE: 'de'
};

export const CONFIRM_LOCALE = {
  YES_EN: 'Yes',
  NO_EN: 'No',
  YES_DE: 'Ja',
  NO_DE: 'Nein'
};

export const DELIVERY_ADDRESS = {
  EN: 'Delivery address',
  DE: 'Lieferadresse'
};

export const SERVER_MESSAGE_CONSTANTS = {
  FIRST_NAME_NULL: 'The parameter firstName is REQUIRED.',
  FIRST_NAME_REQUIRED: 'The firstName value is REQUIRED.',
  FIRST_NAME_TOO_LONG: 'firstName exceeded max length.',
  LAST_NAME_NULL: 'The parameter lastName is REQUIRED.',
  LAST_NAME_REQUIRED: 'The lastName value is REQUIRED.',
  LAST_NAME_TOO_LONG: 'lastName exceeded max length.',
  EMAIL_EXISTED: 'A customer with the same email address already exists.',
  EMAIL_INVALID: 'The email address format is invalid.',
  EMAIL_TOO_LONG: 'email exceeded max length.',
  PHONE_TOO_LONG: 'phone exceeded max length.',
  COMPANY_NAME_EXISTED: 'A customer with the same company name already exists.',
  COMPANY_NAME_TOO_LONG: 'companyName exceeded max length.',
  CUSTOMER_NUMBER_TOO_LONG: 'customerNumber exceeded max length.',
  STREET_TOO_LONG: 'street exceeded max length.',
  CITY_TOO_LONG: 'city exceeded max length.',
  POSTCODE_TOO_LONG: 'postcode exceeded max length.',
  COUNTRY_TOO_LONG: 'country exceeded max length.'
};

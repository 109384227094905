import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { formatQuestion, ShowIconByQuestionIdUtil, ShowIfUtil } from '../../common/utils';
import {
  COLOR,
  GEO_QUESTION_STATUS,
  SERVICE_REQUEST_STATUS,
  SPECIAL_CHARACTER,
  GERMAN_TEXT,
  INPUT_TYPE,
  CONTACT_FORM_KEY,
  ADDRESS_DEFAULT_LABEL,
  MESSAGE_CONSTANTS
} from '../../common/constants';
import {
  CheckBox,
  Input,
  StyledSpan,
  InfoHelpDescription
} from '../../components/epilot-forms/common';
import { AddressAutoComplete } from '.';
import {
  checkProductAvailableExternalService,
  resetProductAvailability,
  updateCustomerJourneyAnswer,
  updateIsAddressChange
} from '../../store/actions';
import { geoQuestionStatusSelector } from '../../store/selectors';

class AddressQuestionContainer extends Component {
  streetNotFoundOnchange = _.debounce((name, value) => {
    const { address } = this.state;
    if (!value) {
      delete address.streetNotFound;
    } else {
      address[name] = value;
    }
    this.setState(
      {
        address: {
          ...address
        }
      },
      () => {
        this.dispatchAddressToStore();
      }
    );
  }, 200);

  constructor(props) {
    super(props);
    const { customerJourneyAnswer } = this.props;
    this.state = {
      address: { ...customerJourneyAnswer.address } || {},
      isAddressChange: false
    };
  }

  componentDidMount() {
    const { isAddressChange } = this.state;
    const { question, updateCustomerJourneyAnswer, updateIsAddressChange } = this.props;
    updateCustomerJourneyAnswer({
      deliveryAddressReadOnly: question.objectModel.deliveryAddressReadOnly
        ? question.objectModel.deliveryAddressReadOnly
        : false
    });
    updateIsAddressChange({ isAddressChange });
  }

  componentDidUpdate(prevProps) {
    const {
      geoQuestionStatus,
      checkProductAvailableExternalService,
      frontendConfiguration: {
        thirdPartyServiceSetting: { active: thirdPartyServiceEnable }
      },
      customerJourneyAnswer: { address, streetListed },
      externalServiceRequestStatus,
      resetProductAvailability
    } = this.props;

    const {
      customerJourneyAnswer: { address: prevAddress },
      externalServiceRequestStatus: prevExternalServiceRequestStatus
    } = prevProps;

    if (
      externalServiceRequestStatus !== prevExternalServiceRequestStatus &&
      externalServiceRequestStatus === SERVICE_REQUEST_STATUS.NO_REQUEST
    ) {
      this.setState(prevState => ({
        address: {
          ...prevState.address,
          lat: null,
          lng: null,
          country: null
        }
      }));
    }

    const { district, block, lot, street, number } = address;

    if (
      !streetListed &&
      (district || block || lot || street || number) &&
      externalServiceRequestStatus !== SERVICE_REQUEST_STATUS.NO_REQUEST &&
      (street !== prevAddress.street ||
        number !== prevAddress.number ||
        district !== prevAddress.district ||
        block !== prevAddress.block ||
        lot !== prevAddress.lot)
    ) {
      resetProductAvailability();
    }

    if (geoQuestionStatus === GEO_QUESTION_STATUS.ADDRESS_DETAILS && thirdPartyServiceEnable) {
      if (!_.isEqual(address, prevAddress)) {
        const isValidAddressFormData =
          address.district &&
          address.district.trim() &&
          address.block &&
          address.block.trim() &&
          address.lot &&
          address.lot.trim();

        const isValidAddressWithCoordinatorData = !!(
          address.street &&
          address.number &&
          address.number.trim()
        );

        if ((streetListed && isValidAddressWithCoordinatorData) || isValidAddressFormData) {
          checkProductAvailableExternalService();
        }
      }
    }
  }

  onSelect = e => {
    const { address } = this.state;
    if (address.hasOwnProperty('streetNotFound')) {
      delete address.streetNotFound;
    }

    if (e) {
      this.setState(
        {
          address: {
            ...address,
            street: e
          },
          isAddressChange: true
        },
        () => {
          this.dispatchAddressToStore();
        }
      );
    }
  };

  onChange = _.debounce((name, value) => {
    this.setState(
      prevState => ({
        address: {
          ...prevState.address,
          [name]: value
        },
        isAddressChange: true
      }),
      () => {
        this.dispatchAddressToStore();
      }
    );
  }, 200);

  dispatchAddressToStore = () => {
    const { updateCustomerJourneyAnswer, updateIsAddressChange } = this.props;
    const { address, isAddressChange } = this.state;
    updateCustomerJourneyAnswer({ address });
    updateIsAddressChange({ isAddressChange });
  };

  onChangeCheckBox = () => {
    const { customerJourneyAnswer, updateCustomerJourneyAnswer } = this.props;
    const { address } = this.state;
    let comment = '';
    customerJourneyAnswer.streetListed === true
      ? (comment = GERMAN_TEXT.ADDRESS_COMMENT_ANSWER)
      : (comment = SPECIAL_CHARACTER.EMPTY);

    if (customerJourneyAnswer.streetListed) {
      // check box selected
      if (customerJourneyAnswer.address.hasOwnProperty('streetNotFound')) {
        address.street = customerJourneyAnswer.address.streetNotFound;
        delete address.streetNotFound;
      }
    } else {
      // check box unselected
      if (customerJourneyAnswer.address.hasOwnProperty('street')) {
        address.streetNotFound = customerJourneyAnswer.address.street;
        delete address.street;
      }
    }
    this.setState({
      ...address
    });
    let newCustomerJourneyAnswer = {
      streetListed: !customerJourneyAnswer.streetListed,
      comment: comment,
      address: {
        ...address
      }
    };
    updateCustomerJourneyAnswer(newCustomerJourneyAnswer);
  };

  render() {
    const {
      question,
      fontText,
      frontendConfiguration,
      customerJourneyAnswer,
      defaultTextColor
    } = this.props;

    const textColor = { color: defaultTextColor };

    return (
      <div className="ep-customerjourneypage__question">
        <ShowIfUtil condition={customerJourneyAnswer.streetListed}>
          <ShowIconByQuestionIdUtil
            questionId={question.id}
            colorIcon={frontendConfiguration.primaryColor}
          />
        </ShowIfUtil>

        <div className="text-center ep-min-height-70 ep--pt-8 ep--pb-16">
          <StyledSpan
            font={fontText}
            className="ep__txt-regular ep__txt--lg ep__txt-no-select"
            style={textColor}
          >
            {formatQuestion(question.questionTitle, customerJourneyAnswer.name)}
          </StyledSpan>
          <InfoHelpDescription
            showHelpDescription={question.showHelpDescription}
            tooltipInfoContent={<StyledSpan font={fontText}>{question.helpDescription}</StyledSpan>}
            classNameForInfoIcon="ep__txt--lg ep--ml-8"
            defaultTextColor={defaultTextColor}
          />
        </div>

        {customerJourneyAnswer.streetListed && (
          <div style={{ minHeight: '68px' }}>
            <ShowIfUtil condition={customerJourneyAnswer.streetListed}>
              <AddressAutoComplete
                question={question}
                onSelect={this.onSelect}
                onChange={this.onChange}
                streetNotFoundOnchange={this.streetNotFoundOnchange}
                fontText={fontText}
                defaultTextColor={defaultTextColor}
                borderColorFocus={frontendConfiguration.primaryColor}
              />
            </ShowIfUtil>
            {/*----- ERROR MESSAGE -----*/}
            <ShowIfUtil
              condition={
                customerJourneyAnswer.address.streetNotFound !== undefined &&
                customerJourneyAnswer.address.streetNotFound !== ''
              }
            >
              <div
                className="ep-text-red ep__txt--sm ep--mt-8"
                style={{
                  maxWidth: '396px',
                  margin: '0 auto',
                  textAlign: 'left'
                }}
              >
                <StyledSpan className="pl-1" font={fontText}>
                  {MESSAGE_CONSTANTS.STREET_NOT_FOUND}
                </StyledSpan>
              </div>
            </ShowIfUtil>
          </div>
        )}

        <div className="d-flex justify-content-center align-content-center ep--mt-16">
          <ShowIfUtil condition={question.objectModel.showAndRequirePropertyDetails}>
            <CheckBox
              onChange={this.onChangeCheckBox}
              content={question.objectModel && question.objectModel.checkboxMessage}
              isChecked={!customerJourneyAnswer.streetListed}
              defaultTextColor={defaultTextColor}
              fontText={fontText}
              primaryCheckBoxIconColor={frontendConfiguration.primaryColor}
            />
            <ShowIfUtil
              condition={
                !!(
                  question.objectModel.description.show && question.objectModel.description.message
                )
              }
            >
              <InfoHelpDescription
                showHelpDescription={question.objectModel.description.show}
                tooltipInfoContent={
                  <StyledSpan font={fontText}>
                    {question.objectModel.description.message}
                  </StyledSpan>
                }
                classNameForInfoIcon="ep__txt--lg ep--ml-8"
                defaultTextColor={defaultTextColor}
              />
            </ShowIfUtil>
          </ShowIfUtil>
        </div>
        {!customerJourneyAnswer.streetListed && (
          <div style={{ minHeight: '240px' }}>
            <div
              className="row ep--mt-16 p-0"
              style={{
                maxWidth: '396px',
                margin: '0 auto'
              }}
            >
              <div className="col-9 pl-0 text-right" style={{ maxWidth: '300px' }}>
                <Input
                  fontSize="14px"
                  height="56px"
                  minWidth="80px"
                  maxWidth="300px"
                  borderRadius={frontendConfiguration.borderRadius}
                  label=""
                  type={INPUT_TYPE.TEXT}
                  placeholder={ADDRESS_DEFAULT_LABEL.STREET_NAME}
                  require
                  onChange={value => this.onChange(CONTACT_FORM_KEY.STREET, value)}
                  value={customerJourneyAnswer.address.street || SPECIAL_CHARACTER.EMPTY}
                  defaultTextColor={defaultTextColor}
                  fontFamily={fontText}
                  borderColorFocus={frontendConfiguration.primaryColor}
                />
              </div>
              <div className="col-3 p-0 text-left">
                <Input
                  fontSize="14px"
                  height="56px"
                  maxwidth="96px"
                  width="100%"
                  borderRadius={frontendConfiguration.borderRadius}
                  label=""
                  type={INPUT_TYPE.TEXT}
                  placeholder={ADDRESS_DEFAULT_LABEL.NUMBER}
                  require
                  onChange={value => this.onChange(CONTACT_FORM_KEY.NUMBER, value)}
                  value={customerJourneyAnswer.address.number || SPECIAL_CHARACTER.EMPTY}
                  defaultTextColor={defaultTextColor}
                  fontFamily={fontText}
                  borderColorFocus={frontendConfiguration.primaryColor}
                />
              </div>
            </div>
            <Input
              fontSize="14px"
              height="56px"
              minWidth="80px"
              maxWidth="396px"
              classStyle="ep--mt-16"
              borderRadius={frontendConfiguration.borderRadius}
              label=""
              type={INPUT_TYPE.TEXT}
              placeholder={ADDRESS_DEFAULT_LABEL.DISTRICT}
              require
              onChange={value => this.onChange(CONTACT_FORM_KEY.DISTRICT, value)}
              value={customerJourneyAnswer.address.district || SPECIAL_CHARACTER.EMPTY}
              defaultTextColor={defaultTextColor}
              fontFamily={fontText}
              borderColorFocus={frontendConfiguration.primaryColor}
            />
            <div
              className="row ep--mt-16 p-0"
              style={{
                maxWidth: '396px',
                margin: '0 auto'
              }}
            >
              <div
                className="col-6 pl-0 text-right"
                style={{ maxWidth: '198px', paddingRight: '7.5px' }}
              >
                <Input
                  questionId={question.id}
                  name={CONTACT_FORM_KEY.BLOCK}
                  fontSize="14px"
                  height="56px"
                  minWidth="80px"
                  maxWidth="198px"
                  borderRadius={frontendConfiguration.borderRadius}
                  label=""
                  type={INPUT_TYPE.TEXT}
                  placeholder={ADDRESS_DEFAULT_LABEL.BLOCK}
                  require
                  onChange={value => this.onChange(CONTACT_FORM_KEY.BLOCK, value)}
                  value={customerJourneyAnswer.address.block || SPECIAL_CHARACTER.EMPTY}
                  defaultTextColor={defaultTextColor}
                  fontFamily={fontText}
                  borderColorFocus={frontendConfiguration.primaryColor}
                />
              </div>
              <div
                className="col-6 pl-0 text-left"
                ref={el => {
                  if (el) {
                    el.style.setProperty('padding-left', '7.5px', 'important');
                    el.style.setProperty('padding-right', '0px', 'important');
                  }
                }}
                style={{ maxWidth: '198px' }}
              >
                <Input
                  questionId={question.id}
                  name={CONTACT_FORM_KEY.LOT}
                  fontSize="14px"
                  height="56px"
                  minWidth="80px"
                  maxWidth="198px"
                  borderRadius={frontendConfiguration.borderRadius}
                  label=""
                  type={INPUT_TYPE.TEXT}
                  placeholder={ADDRESS_DEFAULT_LABEL.LOT}
                  require
                  onChange={value => this.onChange(CONTACT_FORM_KEY.LOT, value)}
                  value={customerJourneyAnswer.address.lot || SPECIAL_CHARACTER.EMPTY}
                  defaultTextColor={defaultTextColor}
                  fontFamily={fontText}
                  borderColorFocus={frontendConfiguration.primaryColor}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

AddressQuestionContainer.propTypes = {
  question: PropTypes.object,
  fontText: PropTypes.object,
  defaultTextColor: PropTypes.string,
  geoQuestionStatus: PropTypes.string,
  externalServiceRequestStatus: PropTypes.string.isRequired,
  customerJourneyAnswer: PropTypes.object,
  frontendConfiguration: PropTypes.object,
  updateCustomerJourneyAnswer: PropTypes.func,
  checkProductAvailableExternalService: PropTypes.func,
  resetProductAvailability: PropTypes.func.isRequired,
  updateIsAddressChange: PropTypes.func
};

AddressQuestionContainer.defaultProps = {
  question: {},
  fontText: {},
  defaultTextColor: COLOR.DARK_GRAY,
  geoQuestionStatus: SPECIAL_CHARACTER.EMPTY,
  customerJourneyAnswer: {},
  frontendConfiguration: {},
  updateCustomerJourneyAnswer: () => ({}),
  checkProductAvailableExternalService: () => ({}),
  updateIsAddressChange: () => ({})
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        checkProductAvailableExternalService,
        resetProductAvailability,
        updateCustomerJourneyAnswer,
        updateIsAddressChange
      },
      dispatch
    )
  };
}

function mapStateToProps(state) {
  return {
    frontendConfiguration: state.frontendConfiguration,
    customerJourneyAnswer: state.customerJourneyAnswer,
    externalServiceRequestStatus: state.externalProductAvailability.externalServiceRequestStatus,
    geoQuestionStatus: geoQuestionStatusSelector(state)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressQuestionContainer);

import _ from 'lodash';
import { RENTAL_UNIT } from '../constants';

export const filterMonthlyPriceGrossByPackageType = (productSelections, packageType, packageSelected) => {
  let packageRentalType = packageType ? 1 : 0;
  let monthlyPriceGross = 0;

  if (packageSelected.packageRentals === null) {
    monthlyPriceGross =
      packageRentalType === 0 ? packageSelected.standardRetailPriceGross : packageSelected.premiumRetailPriceGross;
  } else {
    _.forEach(packageSelected.packageRentals, rental => {
      if (
        rental.packageRentalType === packageRentalType &&
        rental.duration === productSelections.selectedRentalInfo.duration &&
        rental.unit === RENTAL_UNIT[productSelections.selectedRentalInfo.unit]
      ) {
        monthlyPriceGross = rental.priceGross;
      }
    });
  }
  return monthlyPriceGross;
};

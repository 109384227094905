import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { updateCustomerJourneyAnswer, updateFrontendConfiguration } from '../../store/actions';
import {
  COLOR,
  ELECTRICITY_MAX_POWER,
  GAS_MAX_LOAD,
  INPUT_TYPE,
  QUESTION_ID,
  SPECIAL_CHARACTER,
  WATER_MAX_LOAD,
  HEATING_CAPACITY
} from '../../common/constants';
import { Input, StyledSpan, InfoHelpDescription } from '../../components/epilot-forms/common';
import {
  convertDataToComma,
  convertDataToDot,
  formatQuestion,
  ShowIfUtil
} from '../../common/utils';
import { productSelectionsSelector } from '../../store/selectors';

class MultipleInputQuestionContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      mappingValueForQuestion: this.initValueForQuestion() || {}
    };
  }

  componentDidMount() {
    const {
      frontendConfiguration: {
        designOptions: { showPackageInfoSpecialCondition }
      },
      updateFrontendConfiguration
    } = this.props;
    if (
      showPackageInfoSpecialCondition &&
      showPackageInfoSpecialCondition.requiredConnectionValueRequired
    ) {
      updateFrontendConfiguration({ isShowPackageInfo: true });
    }
  }

  initValueForQuestion = () => {
    const { customerJourneyAnswer, updateCustomerJourneyAnswer, question } = this.props;
    let mappingValueForQuestion = {};
    let newCustomerJourneyAnswer = {};
    let roundUpValue;
    _.map(Object.keys(question.objectModel.items), key => {
      switch (key) {
        case ELECTRICITY_MAX_POWER:
          roundUpValue =
            customerJourneyAnswer.houseConnectionElectricityMaxPower === SPECIAL_CHARACTER.EMPTY
              ? '0.00'
              : this.roundUpHCRequiredValues(
                  customerJourneyAnswer.houseConnectionElectricityMaxPower,
                  ELECTRICITY_MAX_POWER
                );
          mappingValueForQuestion.houseConnectionElectricityMaxPower = roundUpValue;
          newCustomerJourneyAnswer.houseConnectionElectricityMaxPower = roundUpValue;
          break;
        case GAS_MAX_LOAD:
          roundUpValue =
            customerJourneyAnswer.houseConnectionGasMaxLoad === SPECIAL_CHARACTER.EMPTY
              ? '0.00'
              : this.roundUpHCRequiredValues(
                  customerJourneyAnswer.houseConnectionGasMaxLoad,
                  GAS_MAX_LOAD
                );
          mappingValueForQuestion.houseConnectionGasMaxLoad = roundUpValue;
          newCustomerJourneyAnswer.houseConnectionGasMaxLoad = roundUpValue;
          break;
        case WATER_MAX_LOAD:
          roundUpValue =
            customerJourneyAnswer.houseConnectionWaterMaxFlow === SPECIAL_CHARACTER.EMPTY
              ? '0.00'
              : this.roundUpHCRequiredValues(
                  customerJourneyAnswer.houseConnectionWaterMaxFlow,
                  WATER_MAX_LOAD
                );
          mappingValueForQuestion.houseConnectionWaterMaxFlow = roundUpValue;
          newCustomerJourneyAnswer.houseConnectionWaterMaxFlow = roundUpValue;
          break;
        case HEATING_CAPACITY:
          roundUpValue =
            customerJourneyAnswer.houseConnectionHeatingCapacity === SPECIAL_CHARACTER.EMPTY
              ? '0.00'
              : this.roundUpHCRequiredValues(
                  customerJourneyAnswer.houseConnectionHeatingCapacity,
                  HEATING_CAPACITY
                );
          mappingValueForQuestion.houseConnectionHeatingCapacity = roundUpValue;
          newCustomerJourneyAnswer.houseConnectionHeatingCapacity = roundUpValue;
          break;
        default:
          break;
      }
    });
    updateCustomerJourneyAnswer(newCustomerJourneyAnswer);
    return mappingValueForQuestion;
  };

  onChange = (name, value) => {
    const { mappingValueForQuestion } = this.state;
    const newValue = convertDataToDot(value);

    switch (name) {
      case ELECTRICITY_MAX_POWER:
        mappingValueForQuestion.houseConnectionElectricityMaxPower = newValue;
        break;
      case GAS_MAX_LOAD:
        mappingValueForQuestion.houseConnectionGasMaxLoad = newValue;
        break;
      case WATER_MAX_LOAD:
        mappingValueForQuestion.houseConnectionWaterMaxFlow = newValue;
        break;
      case HEATING_CAPACITY:
        mappingValueForQuestion.houseConnectionHeatingCapacity = newValue;
        break;
      default:
        break;
    }
    this.setState({ mappingValueForQuestion });
  };

  onBlur = (name, value) => {
    const { updateCustomerJourneyAnswer } = this.props;
    const { mappingValueForQuestion } = this.state;
    let newCustomerJourneyAnswer = {};
    let roundUpValue;
    const newValue = convertDataToDot(value);
    switch (name) {
      case ELECTRICITY_MAX_POWER:
        roundUpValue = this.roundUpHCRequiredValues(newValue, ELECTRICITY_MAX_POWER);
        mappingValueForQuestion.houseConnectionElectricityMaxPower = roundUpValue;
        newCustomerJourneyAnswer.houseConnectionElectricityMaxPower = roundUpValue;
        break;
      case GAS_MAX_LOAD:
        roundUpValue = this.roundUpHCRequiredValues(newValue, GAS_MAX_LOAD);
        mappingValueForQuestion.houseConnectionGasMaxLoad = roundUpValue;
        newCustomerJourneyAnswer.houseConnectionGasMaxLoad = roundUpValue;
        break;
      case WATER_MAX_LOAD:
        roundUpValue = this.roundUpHCRequiredValues(newValue, WATER_MAX_LOAD);
        mappingValueForQuestion.houseConnectionWaterMaxFlow = roundUpValue;
        newCustomerJourneyAnswer.houseConnectionWaterMaxFlow = roundUpValue;
        break;
      case HEATING_CAPACITY:
        roundUpValue = this.roundUpHCRequiredValues(newValue, HEATING_CAPACITY);
        mappingValueForQuestion.houseConnectionHeatingCapacity = roundUpValue;
        newCustomerJourneyAnswer.houseConnectionHeatingCapacity = roundUpValue;
        break;
      default:
        break;
    }
    this.setState({ mappingValueForQuestion });
    updateCustomerJourneyAnswer(newCustomerJourneyAnswer);
  };

  inputType = () => {
    // const { question } = this.props;
    //
    // if (question.id === QUESTION_ID.HOUSE_CONNECTION_REQUIRED_VALUES) {
    //   return INPUT_TYPE.NUMBER;
    // }
    return INPUT_TYPE.TEXT;
  };

  inputValue = name => {
    const { mappingValueForQuestion } = this.state;

    switch (name) {
      case ELECTRICITY_MAX_POWER:
        return mappingValueForQuestion.houseConnectionElectricityMaxPower;
      case GAS_MAX_LOAD:
        return mappingValueForQuestion.houseConnectionGasMaxLoad;
      case WATER_MAX_LOAD:
        return mappingValueForQuestion.houseConnectionWaterMaxFlow;
      case HEATING_CAPACITY:
        return mappingValueForQuestion.houseConnectionHeatingCapacity;
      default:
        break;
    }
  };

  roundUpHCRequiredValues = (value, key) => {
    const { question } = this.props;
    let newValue = value;
    if (
      question.id === QUESTION_ID.HOUSE_CONNECTION_REQUIRED_VALUES &&
      question.objectModel.items[key] &&
      question.objectModel.items[key].roundUp
    ) {
      newValue = Math.ceil(value).toFixed(2);
    }
    return newValue;
  };

  checkUnit = key => {
    switch (key) {
      case ELECTRICITY_MAX_POWER:
      case GAS_MAX_LOAD:
      case HEATING_CAPACITY:
        return 'kW';
      case WATER_MAX_LOAD:
        return 'l/s';
      default:
        break;
    }
  };

  renderMultipleInput = () => {
    const {
      question,
      productSelections,
      fontText,
      frontendConfiguration,
      defaultTextColor
    } = this.props;
    const items = question.objectModel.items;

    return _.map(Object.keys(items), key => {
      if (
        !(
          (key === ELECTRICITY_MAX_POWER &&
            !productSelections.isSelectedProductHouseConnectionForElectricity) ||
          (key === GAS_MAX_LOAD && !productSelections.isSelectedProductHouseConnectionForGas) ||
          (key === WATER_MAX_LOAD && !productSelections.isSelectedProductHouseConnectionForWater) ||
          (key === HEATING_CAPACITY &&
            !productSelections.isSelectedProductHouseConnectionForHeating)
        )
      ) {
        return (
          <div className="row ep--pb-16" key={key}>
            <div className="col-6 d-flex justify-content-start align-items-center">
              <StyledSpan
                font={fontText}
                className="ep__txt-regular ep__txt--sm text-left ep__txt-no-select"
                style={{ color: defaultTextColor }}
              >
                {items[key].label}
              </StyledSpan>
            </div>
            <div className="col-6 d-flex justify-content-center align-items-center">
              <Input
                questionId={question.id}
                fontSize="14px"
                height="56px"
                minWidth="120px"
                maxWidth="240px"
                textAlign="left"
                paddingRight="3rem"
                customClassStyle=""
                borderRadius={frontendConfiguration.borderRadius}
                type={this.inputType()}
                require
                onChange={value => this.onChange(key, value)}
                onBlur={value => this.onBlur(key, value)}
                value={convertDataToComma(this.inputValue(key) && this.inputValue(key).toString())}
                primaryTextColor={COLOR.DARK_GRAY}
                defaultTextColor={defaultTextColor}
                fontFamily={fontText}
                borderColorFocus={frontendConfiguration.primaryColor}
                unit={this.checkUnit(key)}
              />
            </div>
          </div>
        );
      }
    });
  };

  render() {
    const { question, fontText, customerJourneyAnswer, defaultTextColor } = this.props;

    const textColor = {
      color: defaultTextColor
    };

    return (
      <div className="ep-customerjourneypage__question">
        <div className="text-center ep-min-height-70 ep--pt-8 ep--pb-16">
          <StyledSpan
            font={fontText}
            className="ep__txt-regular ep__txt--lg ep__txt-no-select"
            style={textColor}
          >
            {formatQuestion(question.questionTitle, customerJourneyAnswer.name)}
          </StyledSpan>
          <InfoHelpDescription
            showHelpDescription={question.showHelpDescription}
            tooltipInfoContent={<StyledSpan font={fontText}>{question.helpDescription}</StyledSpan>}
            classNameForInfoIcon="ep__txt--lg ep--ml-8"
            defaultTextColor={defaultTextColor}
          />
        </div>

        <div className="" style={{ minHeight: '288px' }}>
          {this.renderMultipleInput()}
        </div>
        <ShowIfUtil
          condition={
            question.id === QUESTION_ID.HOUSE_CONNECTION_REQUIRED_VALUES &&
            (question.objectModel && question.objectModel.warningMessage.show)
          }
        >
          <div className="text-center ep--pt-8 ep--pb-16">
            <StyledSpan
              font={fontText}
              className="ep__txt-semibold ep__txt--sm ep__txt-no-select"
              style={{ color: '#8A8A8A' }}
            >
              {question.objectModel.warningMessage.message}
            </StyledSpan>
          </div>
        </ShowIfUtil>
      </div>
    );
  }
}

MultipleInputQuestionContainer.propTypes = {
  question: PropTypes.object,
  fontText: PropTypes.object,
  defaultTextColor: PropTypes.string,
  customerJourneyAnswer: PropTypes.object,
  frontendConfiguration: PropTypes.object,
  productSelections: PropTypes.object,
  updateFrontendConfiguration: PropTypes.func,
  updateCustomerJourneyAnswer: PropTypes.func
};

MultipleInputQuestionContainer.defaultProps = {
  question: {},
  fontText: {},
  defaultTextColor: COLOR.DARK_GRAY,
  customerJourneyAnswer: {},
  frontendConfiguration: {},
  productSelections: {},
  updateFrontendConfiguration: () => ({}),
  updateCustomerJourneyAnswer: () => ({})
};

const mapStateToProps = state => {
  return {
    frontendConfiguration: state.frontendConfiguration,
    customerJourneyAnswer: state.customerJourneyAnswer,
    productSelections: productSelectionsSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateFrontendConfiguration,
        updateCustomerJourneyAnswer
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MultipleInputQuestionContainer);

import {
  GET_PRE_SIGNED_URL_REQUEST,
  RESET_CUSTOMER_JOURNEY_DATA,
  UPDATE_PRE_SIGNED_URLS,
  UPDATE_UPLOAD_FILE_ERRORS,
  UPDATE_UPLOAD_FILE_STATUS
} from '../actions';

const initState = {
  isUploading: false,
  preSignedUrls: [],
  errors: []
};

export default (state = initState, action) => {
  switch (action.type) {
    case GET_PRE_SIGNED_URL_REQUEST:
      return {
        ...state,
        isUploading: true
      };
    case UPDATE_UPLOAD_FILE_STATUS:
      return {
        ...state,
        ...action.payload
      };
    case UPDATE_PRE_SIGNED_URLS: {
      return {
        ...state,
        preSignedUrls: [...state.preSignedUrls, ...action.payload]
      };
    }
    case UPDATE_UPLOAD_FILE_ERRORS:
      return {
        ...state,
        errors: [...state.errors, action.payload]
      };
    case RESET_CUSTOMER_JOURNEY_DATA:
      return initState;
    default:
      return state;
  }
};

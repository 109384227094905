import _ from 'lodash';

export const removeAddon = (product, cloneProductSelections, itemId) => {
  if (product && product.addons) {
    const addonIndex = _.findIndex(product.addons, addon => addon.id === itemId);
    if (itemId && addonIndex === -1) return;
    _.forEach(product.addons, addon => {
      itemId
        ? _.pull(cloneProductSelections.selectedAddonIds, itemId)
        : _.pull(cloneProductSelections.selectedAddonIds, addon.id);
    });
  }
  return cloneProductSelections.selectedAddonIds;
};

export const findCheapestSellingOptionAddOnV2 = sellingOptions =>
  _.minBy(sellingOptions, sellingOption =>
    Number(
      sellingOption.oneTimeNetPrice + sellingOption.monthlyNetPrice + sellingOption.yearlyNetPrice
    )
  );

export const isAllOnRequestAddOns = addOns => {
  return _.every(addOns, function(addon) {
    return addon.sellingOptions[0] && addon.sellingOptions[0].showPriceOnFrontend === true;
  });
};

export const isPriceAddOnV2 = addOnV2TotalPrice => {
  return !!(
    addOnV2TotalPrice.oneTimePrice ||
    addOnV2TotalPrice.monthlyPrice ||
    addOnV2TotalPrice.yearlyPrice
  );
};

export const removeSpecificAddonId = (specificAddonIds, selectedAddonIds) =>
  _.pullAll(selectedAddonIds, specificAddonIds);

export const removeInvalidAddonsWhenChangePackageV1 = ({
  currentPackage,
  nextPackage,
  currentSelectedAddonIds
}) => {
  if (currentPackage?.id !== nextPackage?.id && currentPackage?.specificAddonIds?.length > 0) {
    const cloneSpecificAddonIds = [...currentPackage?.specificAddonIds];
    const cloneSelectedAddonIds = [...currentSelectedAddonIds];

    return removeSpecificAddonId(cloneSpecificAddonIds, cloneSelectedAddonIds);
  }
  return currentSelectedAddonIds;
};

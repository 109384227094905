export const PRODUCT_ORDER = {
  SOLAR: 0,
  STORAGE: 1,
  CHARGE: 2,
  POWER: 3,
  HOUSE_CONNECTION_ELECTRICITY: 4,
  HOUSE_CONNECTION_GAS: 5,
  HOUSE_CONNECTION_WATER: 6,
  HOUSE_CONNECTION_HEAT: 7,
  HOUSE_CONNECTION_BROADBAND: 8,
  CHP: 9,
  HEATING: 10,
  HEATPUMP: 11,
  CARSHARING: 12,
  GAS: 13,
  SMARTHOME: 20,
  SOLAR_B2B: 21,
  CHARGE_B2B: 22
};

export const PRODUCT_TYPE = {
  GENERIC: 'GENERIC',
  SOLAR: 'SOLAR',
  CHARGE: 'CHARGE',
  POWER: 'POWER',
  STORAGE: 'STORAGE',
  HOUSE_CONNECTION_ELECTRICITY: 'HOUSE_CONNECTION_ELECTRICITY',
  HOUSE_CONNECTION_GAS: 'HOUSE_CONNECTION_GAS',
  HOUSE_CONNECTION_WATER: 'HOUSE_CONNECTION_WATER',
  HOUSE_CONNECTION_HEAT: 'HOUSE_CONNECTION_HEAT',
  HOUSE_CONNECTION_BROADBAND: 'HOUSE_CONNECTION_BROADBAND',
  CHP: 'CHP',
  HEATING: 'HEATING',
  HEATPUMP: 'HEATPUMP',
  CARSHARING: 'CARSHARING',
  GAS: 'GAS',
  SMARTHOME: 'SMARTHOME',
  SOLAR_B2B: 'SOLAR_B2B',
  CHARGE_B2B: 'CHARGE_B2B'
};

export const PRODUCT_NAME = {
  GENERIC: 'Generisch',
  SOLAR: 'Solar',
  CHARGE: 'Ladeinfrastruktur',
  POWER: 'Strom',
  STORAGE: 'Speicher',
  HOUSE_CONNECTION_ELECTRICITY: 'Hausanschluss für Strom',
  HOUSE_CONNECTION_GAS: 'Hausanschluss für Gas',
  HOUSE_CONNECTION_WATER: 'Hausanschluss für Wasser',
  HOUSE_CONNECTION_HEAT: 'Hausanschluss für Fernwärme',
  HOUSE_CONNECTION_BROADBAND: 'Hausanschluss für Breitband',
  CHP: 'KWK',
  HEATING: 'Heizung',
  HEATPUMP: 'Wärmepumpe',
  CARSHARING: 'Carsharing',
  GAS: 'Gas',
  SMARTHOME: 'smarthome',
  SOLAR_B2B: 'Solar B2B',
  CHARGE_B2B: 'Charge B2B'
};

export const ALL_PRODUCT_TYPE = [
  'GENERIC',
  'SOLAR',
  'CHARGE',
  'POWER',
  'STORAGE',
  'HOUSE_CONNECTION_ELECTRICITY',
  'HOUSE_CONNECTION_GAS',
  'HOUSE_CONNECTION_WATER',
  'HOUSE_CONNECTION_HEAT',
  'HOUSE_CONNECTION_BROADBAND',
  'CHP',
  'HEATING',
  'HEATPUMP',
  'CARSHARING',
  'GAS',
  'SMARTHOME',
  'SOLAR_B2B',
  'CHARGE_B2B'
];

export const PRODUCT_TYPE_CURR_PACKAGE_MAPPING = {
  GENERIC: 'currGenericPackage',
  SOLAR: 'currSolarPackage',
  CHARGE: 'currChargePackage',
  POWER: 'currPowerPackage',
  STORAGE: 'currStoragePackage',
  HOUSE_CONNECTION_ELECTRICITY: 'currElectricityHCPackage',
  HOUSE_CONNECTION_GAS: 'currGasHCPackage',
  HOUSE_CONNECTION_WATER: 'currWaterHCPackage',
  HOUSE_CONNECTION_HEAT: 'currHeatHCPackage',
  HOUSE_CONNECTION_BROADBAND: 'currBroadbandHCPackage',
  CHP: 'currCHPPackage',
  HEATING: 'currHeatingPackage',
  HEATPUMP: 'currHeatpumpPackage',
  CARSHARING: 'currCarsharingPackage',
  SOLAR_B2B: 'currSolarB2BPackage',
  CHARGE_B2B: 'currChargeB2BPackage'
};

export const CURR_PACKAGE_PRODUCT_TYPE_MAPPING = {
  currGenericPackage: 'GENERIC',
  currSolarPackage: 'SOLAR',
  currChargePackage: 'CHARGE',
  currPowerPackage: 'POWER',
  currStoragePackage: 'STORAGE',
  currElectricityHCPackage: 'HOUSE_CONNECTION_ELECTRICITY',
  currGasHCPackage: 'HOUSE_CONNECTION_GAS',
  currWaterHCPackage: 'HOUSE_CONNECTION_WATER',
  currHeatHCPackage: 'HOUSE_CONNECTION_HEAT',
  currBroadbandHCPackage: 'HOUSE_CONNECTION_BROADBAND',
  currCHPPackage: 'CHP',
  currHeatingPackage: 'HEATING',
  currHeatpumpPackage: 'HEATPUMP',
  currCarsharingPackage: 'CARSHARING',
  currGasPackage: 'GAS',
  currSolarB2BPackage: 'SOLAR_B2B',
  currChargeB2BPackage: 'CHARGE_B2B'
};

export const PACKAGE_LABEL_TYPE = {
  E_MOBILITY: 'eMobility',
  TRENCH_LENGTH: 'trenchLength',
  BROADBAND: 'broadband'
};

export const PRODUCT_HAS_FILTERED_PACKAGE_LABEL = [
  PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY,
  PRODUCT_TYPE.HOUSE_CONNECTION_GAS,
  PRODUCT_TYPE.HOUSE_CONNECTION_WATER,
  PRODUCT_TYPE.HOUSE_CONNECTION_HEAT,
  PRODUCT_TYPE.HOUSE_CONNECTION_BROADBAND
];

export const PRODUCT_STATUS = {
  LICENSED: 0,
  DEACTIVATED: 1,
  UN_LICENSED: 2
};

export const PRODUCT_DEACTIVATED_MESSAGE = {
  EN: 'Currently not available',
  DE: 'Aktuell nicht verfügbar'
};

export const ADD_ON_V2_PRODUCT_TYPE = {
  ...PRODUCT_TYPE,
  GLOBAL: 'GLOBAL'
};

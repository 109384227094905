import { createSelector } from 'reselect';
import _ from 'lodash';
import { ATTRIBUTE, QUESTION_EVENT, QUESTION_TYPE } from '../../common/constants';

const getCurrQuestions = state => state.currentState.currQuestions;
const getProducts = state => state.frontendConfiguration.products;
const getProductIds = state => state.frontendConfiguration.productIds;
const getProductMap = state => state.frontendConfiguration.productMap;

export const productAddonsMappingSelector = createSelector(
  [getCurrQuestions, getProducts, getProductIds, getProductMap],
  (currQuestions, products, productIds, productMap) => {
    let productAddonMap = {};
    _.forEach(productIds, elem => {
      productAddonMap[elem] = {
        singleAddonSelectionGroup: [],
        singleAddonSelectionGroupIds: [],
        singleAddonSelectionGroupMap: {},
        normalAddons: [],
        normalAddonIds: []
      };
    });

    let singleAddonsSelectionQuestion = _.filter(currQuestions, {
      type: QUESTION_TYPE.MULTIPLE_CHOICE,
      multipleAddonsSelectionEnable: false
    });

    _.forEach(singleAddonsSelectionQuestion, question => {
      let selectAddonEventQuestions = _.filter(question.objectModel, {
        event: QUESTION_EVENT.SELECT_AN_ADD_ON
      });

      _.forEach(selectAddonEventQuestions, selectAddonEventQuestion => {
        _.forEach(selectAddonEventQuestion.addOnMappingModels, productConfig => {
          let currentProduct = productMap[productConfig.productId];
          // Find addons and ids of all addons need to be group for each product
          let singleAddonSelectionGroupIds = _.map(
            _.unionBy(productConfig.addOnPackageMapping, ATTRIBUTE.ADDON_ID),
            ATTRIBUTE.ADDON_ID
          );
          // add addon of other package mapping to addons groups
          if (
            productConfig.otherPackagesMappingAddOnId &&
            _.indexOf(singleAddonSelectionGroupIds, productConfig.otherPackagesMappingAddOnId) ===
              -1
          ) {
            singleAddonSelectionGroupIds.push(productConfig.otherPackagesMappingAddOnId);
          }
          let tmpGroupAddons = _.filter(
            currentProduct.addons,
            addon => _.indexOf(singleAddonSelectionGroupIds, addon.id) !== -1
          );
          productAddonMap[productConfig.productId].singleAddonSelectionGroupIds.push(
            ...singleAddonSelectionGroupIds
          );
          productAddonMap[productConfig.productId].singleAddonSelectionGroup.push(
            ...tmpGroupAddons
          );
        });
      });
    });

    _.forEach(products, product => {
      // normal addons (allow multiple selection) is not belong to single selection addon group
      let tmpNormalAddons = _.filter(
        product.addons,
        addon =>
          _.indexOf(productAddonMap[product.id].singleAddonSelectionGroupIds, addon.id) === -1
      );
      let tmpNormalAddonIds = _.map(tmpNormalAddons, ATTRIBUTE.ID);
      productAddonMap[product.id].normalAddons.push(...tmpNormalAddons);
      productAddonMap[product.id].normalAddonIds.push(...tmpNormalAddonIds);
    });

    _.forOwn(productAddonMap, value => {
      value.singleAddonSelectionGroupMap = _.keyBy(value.singleAddonSelectionGroup, ATTRIBUTE.ID);
      value.singleAddonSelectionGroup = _.uniqBy(value.singleAddonSelectionGroup, ATTRIBUTE.ID);
      value.singleAddonSelectionGroupIds = _.uniq(value.singleAddonSelectionGroupIds);
      value.normalAddons = _.uniqBy(value.normalAddons, ATTRIBUTE.ID);
      value.normalAddonIds = _.uniq(value.normalAddonIds);
    });
    return productAddonMap;
  }
);

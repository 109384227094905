import React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { GERMAN_TEXT, SPECIAL_CHARACTER } from '../../../common/constants';
import { StyledP, StyledSpan } from '../../epilot-forms/common';
import { formatPrice } from '../../../common/utils';

const PriceBlock = ({
  oneTimePrice,
  monthlyPrice,
  yearlyPrice,
  isOnRequest,
  fontText,
  defaultTextColor
}) => {
  const textColor = { color: defaultTextColor };
  let isRenderOneTimePrice = _.isNumber(oneTimePrice);
  let isRenderMonthlyPrice = _.isNumber(monthlyPrice);
  let isRenderYearlyPrice = _.isNumber(yearlyPrice);

  return (
    <>
      {isOnRequest ? (
        <StyledP font={fontText} style={textColor} className="ep--mb-0">
          {GERMAN_TEXT.ON_REQUEST}
        </StyledP>
      ) : (
        <>
          {isRenderOneTimePrice && (
            <StyledP font={fontText} style={textColor} className="ep--mb-0">
              {formatPrice(oneTimePrice)}
            </StyledP>
          )}
          {isRenderMonthlyPrice && (
            <StyledP font={fontText} style={textColor} className="ep--mb-0">
              {monthlyPrice >= 0
                ? isRenderOneTimePrice
                  ? SPECIAL_CHARACTER.PLUS
                  : ''
                : SPECIAL_CHARACTER.MINUS}{' '}
              {formatPrice(Math.abs(monthlyPrice))}
              <StyledSpan font={fontText} className="ep__txt--md" style={textColor}>
                {` ${GERMAN_TEXT.MONTHLY}`}
              </StyledSpan>
            </StyledP>
          )}
          {isRenderYearlyPrice && (
            <StyledP font={fontText} style={textColor} className="ep--mb-0">
              {yearlyPrice >= 0
                ? isRenderOneTimePrice || isRenderMonthlyPrice
                  ? SPECIAL_CHARACTER.PLUS
                  : ''
                : SPECIAL_CHARACTER.MINUS}{' '}
              {formatPrice(Math.abs(yearlyPrice))}
              <StyledSpan font={fontText} className="ep__txt--md" style={textColor}>
                {` ${GERMAN_TEXT.YEARLY}`}
              </StyledSpan>
            </StyledP>
          )}
        </>
      )}
    </>
  );
};

PriceBlock.propTypes = {
  oneTimePrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  monthlyPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  yearlyPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isOnRequest: PropTypes.bool,
  fontText: PropTypes.object.isRequired,
  defaultTextColor: PropTypes.string.isRequired
};

PriceBlock.defaultProps = {
  oneTimePrice: null,
  monthlyPrice: null,
  yearlyPrice: null,
  isOnRequest: false
};

export default React.memo(PriceBlock);

import React from 'react';
import { Position, Tooltip } from '@blueprintjs/core';
import _ from 'lodash';
import * as PropTypes from 'prop-types';
import { convertProdTypeToProdName, ShowIfUtil } from '../../../common/utils';
import { StyledSpan, StyledSpanBold } from '../../epilot-forms/common';
import { PriceBlock } from './index';
import { GERMAN_TEXT } from '../../../common/constants';

const PackageSummary = ({
  packageSummary,
  selectedProducts,
  textColor,
  fontText,
  removePackage,
  removeAddOn,
  defaultTextColor,
  selectedAddOnV2MapKeyByProductType,
  isShowPackageInfo
}) => {
  const _renderPackagePriceBlock = packageSummary => {
    return (
      <PriceBlock
        defaultTextColor={defaultTextColor}
        fontText={fontText}
        oneTimePrice={packageSummary.totalOneTimeNetRetailPrice}
        monthlyPrice={packageSummary.totalMonthlyNetRetailPrice}
        yearlyPrice={packageSummary.totalYearlyNetRetailPrice}
        isOnRequest={packageSummary.alwaysShowOnRequest || packageSummary.onRequest}
      />
    );
  };

  const _renderAddOnPriceBlock = addOnSellingOption => {
    if (addOnSellingOption.showPriceOnFrontend) {
      return (
        <StyledSpan font={fontText} style={textColor}>
          {GERMAN_TEXT.ON_REQUEST}
        </StyledSpan>
      );
    }
    return (
      <PriceBlock
        defaultTextColor={defaultTextColor}
        fontText={fontText}
        oneTimePrice={addOnSellingOption.oneTimeNetPrice}
        monthlyPrice={addOnSellingOption.monthlyNetPrice}
        yearlyPrice={addOnSellingOption.yearlyNetPrice}
      />
    );
  };

  return (
    <>
      <div className="row ep--mt-16">
        {/* PACKAGE NAME*/}
        {isShowPackageInfo ? (
          <div className="col-6 ep__txt--md ep__txt-bold ep--mb-0 text-break">
            <Tooltip
              content={
                <StyledSpanBold font={fontText} style={textColor}>
                  {packageSummary.packageName
                    ? packageSummary.packageName
                    : convertProdTypeToProdName(packageSummary.productType)}
                </StyledSpanBold>
              }
              position={Position.BOTTOM}
              tooltipClassName="ep-tooltip ep-tooltip-width-300"
            >
              <StyledSpanBold font={fontText} style={textColor}>
                {packageSummary.packageName
                  ? packageSummary.packageName
                  : convertProdTypeToProdName(packageSummary.productType)}
              </StyledSpanBold>
            </Tooltip>
          </div>
        ) : (
          <div className="col-6 ep__txt--md ep__txt-bold ep--mb-0 text-break">
            <Tooltip
              content={
                <StyledSpanBold font={fontText} style={textColor}>
                  {convertProdTypeToProdName(packageSummary.productType)}
                </StyledSpanBold>
              }
              position={Position.BOTTOM}
              tooltipClassName="ep-tooltip ep-tooltip-width-300"
            >
              <StyledSpanBold font={fontText} style={textColor}>
                {convertProdTypeToProdName(packageSummary.productType)}
              </StyledSpanBold>
            </Tooltip>
          </div>
        )}

        {/* PRICE */}
        {isShowPackageInfo ? (
          <div className="col-5 text-right ep--mb-0 ep__txt--md ep__txt-bold ep-ellipsis">
            <Tooltip
              content={_renderPackagePriceBlock(packageSummary)}
              position={Position.BOTTOM}
              tooltipClassName="ep-tooltip ep-tooltip-width-300"
            >
              {_renderPackagePriceBlock(packageSummary)}
            </Tooltip>
          </div>
        ) : (
          <div className="col-5 text-right ep--mb-0 ep__txt--md ep__txt-bold ep-ellipsis">
            <Tooltip
              content={
                <StyledSpan font={fontText} style={textColor}>
                  {GERMAN_TEXT.ON_REQUEST}
                </StyledSpan>
              }
              position={Position.BOTTOM}
              tooltipClassName="ep-tooltip ep-tooltip-width-300"
            >
              <StyledSpan font={fontText} style={textColor}>
                {GERMAN_TEXT.ON_REQUEST}
              </StyledSpan>
            </Tooltip>
          </div>
        )}
        {/* REMOVE PACKAGE */}
        <ShowIfUtil condition={selectedProducts && selectedProducts.length > 1}>
          <div className="col-1 pl-1 pr-0 pt-1">
            <i
              className="ep-icon-simple-remove ep-cursor-pointer ep-font-size-18"
              style={textColor}
              onClick={() => removePackage(packageSummary)}
            />
          </div>
        </ShowIfUtil>
      </div>
      {_.map(selectedAddOnV2MapKeyByProductType[packageSummary.productType], addOn => {
        return (
          <div className="row ep--mt-8" key={addOn.addOnId}>
            {/* ADD-ON NAME*/}
            <div className="col-5 offset-1 ep__txt--md ep__txt-bold ep--mb-0 text-break">
              <Tooltip
                content={
                  <StyledSpanBold font={fontText} style={textColor}>
                    {addOn.addOnName}
                  </StyledSpanBold>
                }
                position={Position.BOTTOM}
                tooltipClassName="ep-tooltip ep-tooltip-width-300"
              >
                <StyledSpanBold font={fontText} style={textColor}>
                  {addOn.addOnName}
                </StyledSpanBold>
              </Tooltip>
            </div>

            {/* PRICE */}
            <div className="col-5 text-right ep--mb-0 ep__txt--md ep__txt-bold ep-ellipsis">
              <Tooltip
                content={_renderAddOnPriceBlock(addOn.addOnSellingOption)}
                position={Position.BOTTOM}
                tooltipClassName="ep-tooltip ep-tooltip-width-300"
              >
                {_renderAddOnPriceBlock(addOn.addOnSellingOption)}
              </Tooltip>
            </div>

            {/* REMOVE ADD-ON */}
            <div className="col-1 pl-1 pr-0 pt-1">
              <i
                className="ep-icon-simple-remove ep-cursor-pointer ep-font-size-18"
                style={textColor}
                onClick={() => removeAddOn(packageSummary.productType, addOn.addOnId)}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

PackageSummary.propTypes = {
  packageSummary: PropTypes.object.isRequired,
  selectedAddOnV2MapKeyByProductType: PropTypes.object.isRequired,
  selectedProducts: PropTypes.array.isRequired,
  textColor: PropTypes.object.isRequired,
  fontText: PropTypes.object.isRequired,
  removePackage: PropTypes.func.isRequired,
  removeAddOn: PropTypes.func.isRequired,
  defaultTextColor: PropTypes.string.isRequired,
  isShowPackageInfo: PropTypes.bool
};

PackageSummary.defaultProps = {
  isShowPackageInfo: true
};

export default React.memo(PackageSummary);

import _ from 'lodash';
import {
  UPDATE_SELECTED_ADDON,
  REMOVE_SELECTED_ADDONS,
  RESET_PRODUCT_SELECTIONS,
  SELECT_ALL_PRODUCT_SELECTIONS,
  UPDATE_PRODUCT_SELECTIONS,
  REMOVE_AUTOMATIC_SELECTED_ADDON,
  RESET_CUSTOMER_JOURNEY_DATA
} from '../actions';
import { PAYMENT_TYPE } from '../../common/constants';

let initState = {
  selectedSolarPackage: {},
  selectedStoragePackage: {},
  selectedChargePackage: {},
  selectedPowerPackage: {},
  selectedHouseConnectionForElectricityPackage: {},
  selectedHouseConnectionForGasPackage: {},
  selectedHouseConnectionForWaterPackage: {},
  selectedHouseConnectionForHeatingPackage: {},
  selectedHouseConnectionForBroadbandPackage: {},
  selectedHeatingPackage: {},
  selectedCHPPackage: {},
  selectedHeatPumpPackage: {},
  selectedCarsharingPackage: {},
  selectedGasPackage: {},
  selectedSolarB2BPackage: {},
  selectedChargeB2BPackage: {},

  isSelectedSolarPackagePremiumType: false,
  isSelectedStoragePackagePremiumType: false,
  isSelectedChargePackagePremiumType: false,
  isSelectedPowerPackagePremiumType: false,
  isSelectedHouseConnectionForElectricityPackagePremiumType: false,
  isSelectedHouseConnectionForGasPackagePremiumType: false,
  isSelectedHouseConnectionForWaterPackagePremiumType: false,
  isSelectedHouseConnectionForHeatingPackagePremiumType: false,
  isSelectedHouseConnectionForBroadbandPackagePremiumType: false,
  isSelectedHeatingPackagePremiumType: false,
  isSelectedCHPPackagePremiumType: false,
  isSelectedHeatPumpPackagePremiumType: false,
  isSelectedCarsharingPackagePremiumType: false,
  isSelectedGasPackagePremiumType: false,
  isSelectedSolarB2BPackagePremiumType: false,
  isSelectedChargeB2BPackagePremiumType: false,

  isSelectedProductSolar: false,
  isSelectedProductStorage: false,
  isSelectedProductCharge: false,
  isSelectedProductPower: false,
  isSelectedProductHouseConnectionForElectricity: false,
  isSelectedProductHouseConnectionForGas: false,
  isSelectedProductHouseConnectionForWater: false,
  isSelectedProductHouseConnectionForHeating: false,
  isSelectedProductHouseConnectionForBroadband: false,
  isSelectedProductHeating: false,
  isSelectedProductCHP: false,
  isSelectedProductHeatPump: false,
  isSelectedProductCarsharing: false,
  isSelectedProductGas: false,
  isSelectedProductSolarB2B: false,
  isSelectedProductChargeB2B: false,

  isManualSelectedStorageProduct: false,
  isManualSelectedChargeProduct: false,
  isManualSelectedPowerProduct: false,
  selectedPurchaseRental: PAYMENT_TYPE.PURCHASE,
  selectedRentalInfo: {},
  selectedAddonIds: [],
  automaticSelectedAddonIds: [],
  preselectProductsAndPackages: true
};

export default (state = initState, action) => {
  if (_.isPlainObject(action.payload) && _.isEmpty(action.payload)) return state;
  let newState = {};
  switch (action.type) {
    case UPDATE_PRODUCT_SELECTIONS:
      return {
        ...state,
        ...action.payload
      };
    case REMOVE_SELECTED_ADDONS:
      if (_.isArray(action.payload) && !_.isEmpty(action.payload)) {
        return {
          ...state,
          selectedAddonIds: _.without(state.selectedAddonIds.slice(), ...action.payload)
        };
      }
      return state;
    case UPDATE_SELECTED_ADDON:
      if (action.payload) {
        let tmpSelectedAddonIds = state.selectedAddonIds.slice();
        if (_.indexOf(tmpSelectedAddonIds, action.payload) !== -1) {
          tmpSelectedAddonIds = _.without(tmpSelectedAddonIds, action.payload);
        } else {
          tmpSelectedAddonIds.push(action.payload);
        }
        return {
          ...state,
          selectedAddonIds: tmpSelectedAddonIds
        };
      }
      return state;
    case REMOVE_AUTOMATIC_SELECTED_ADDON:
      if (action.payload) {
        let tmpAutomaticSelectedAddons = state.automaticSelectedAddonIds.slice();
        _.pull(tmpAutomaticSelectedAddons, action.payload);
        return {
          ...state,
          automaticSelectedAddonIds: tmpAutomaticSelectedAddons
        };
      }
      return state;
    case SELECT_ALL_PRODUCT_SELECTIONS:
      newState = {
        isSelectedProductSolar: true,
        isSelectedProductStorage: true,
        isSelectedProductCharge: true,
        isSelectedProductPower: true,
        isSelectedProductHouseConnectionForElectricity: true,
        isSelectedProductHouseConnectionForGas: true,
        isSelectedProductHouseConnectionForWater: true,
        isSelectedProductHouseConnectionForHeating: true,
        isSelectedProductHouseConnectionForBroadband: true,
        isSelectedProductHeating: true,
        isSelectedProductCHP: true,
        isSelectedProductHeatPump: true,
        isSelectedProductCarsharing: true,
        isSelectedProductSolarB2B: true,
        isSelectedProductChargeB2B: true
      };
      return {
        ...state,
        ...newState
      };
    case RESET_PRODUCT_SELECTIONS:
    case RESET_CUSTOMER_JOURNEY_DATA:
      return initState;
    default:
      return state;
  }
};

import React, { useEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { Collapse } from '@blueprintjs/core';
import { connect } from 'react-redux';

import {
  ADD_ON_V2_PRODUCT_TYPE,
  COLOR,
  GERMAN_TEXT,
  SPECIAL_CHARACTER
} from '../../common/constants';
import { StyledSpan } from '../../components/epilot-forms/common';
import {
  addOnV2TotalPriceSelector,
  isAllSelectedProductOnRequestSelector,
  selectedProductsSelector
} from '../../store/selectors';
import {
  removeInvalidSelectedAddOnV2,
  removeSelectedGlobalAddOnV2,
  updateAddOnSellingOptionV2,
  updateProductSelections,
  updateSelectedAddOnV2
} from '../../store/actions';
import { AddOnPrice, AddOnV2 } from '../../components';
import { isAllOnRequestAddOns, isPriceAddOnV2 } from '../../common/utils';

const GlobalAddOnContainer = ({
  addOnV2Selections,
  uiStyle,
  productType,
  productAddOnConfig,
  setScrollHeight,
  isShowPackageInfo,
  updateAddOnSellingOptionV2,
  updateSelectedAddOnV2,
  addOnV2TotalPriceMapKeyByProductType,
  isAllSelectedProductOnRequest,
  removeSelectedGlobalAddOnV2,
  selectedProducts
}) => {
  const [isShowAddOn, setIsShowAddOn] = useState(null);
  const [isAddOnItemDisabled, setIsAddOnItemDisabled] = useState(false);
  const { defaultTextColor, borderRadiusMax4px, textColor, fontText, primaryColor } = uiStyle;
  const [style, setStyle] = useState({
    color: defaultTextColor,
    border: '1px solid rgba(68,68,68,0.16)',
    borderRadius: borderRadiusMax4px,
    backgroundColor: COLOR.LIGHT_GRAY,
    marginBottom: 1
  });

  const addOnRef = useRef();

  const otherAddOnRef = useRef();

  // calculate product view height
  useEffect(() => {
    // Must compare with true or false value, do not use falsy check
    // This hack avoid product view height bug in first time render when invoke
    // setScrollHeight with negative value
    if (isShowAddOn === true) {
      setScrollHeight(addOnRef.current.clientHeight + 10);
      setStyle(style => ({ ...style, marginBottom: 5 }));
    } else if (isShowAddOn === false) {
      setScrollHeight(-addOnRef.current.clientHeight + 5);
      setIsShowAddOn(null);
      setStyle(style => ({ ...style, marginBottom: 1 }));
    }
  }, [isShowAddOn, setScrollHeight, setStyle]);

  const _selectAddOn = (addOnId, addOnSellingOption, addOnName) => {
    updateSelectedAddOnV2({
      productType: productType,
      addOnId: addOnId,
      addOnSellingOption,
      addOnName
    });
  };

  const _selectAddOnSellingOption = (addOnId, addOnSellingOption, addOnName) => {
    updateAddOnSellingOptionV2({
      productType: productType,
      addOnId: addOnId,
      addOnSellingOption,
      addOnName
    });
  };

  useEffect(() => {
    if (
      selectedProducts.length === 0 &&
      addOnV2Selections.selectedAddOnV2MapKeyByProductType[ADD_ON_V2_PRODUCT_TYPE.GLOBAL]
    ) {
      removeSelectedGlobalAddOnV2();
    }
  }, [addOnV2Selections, removeSelectedGlobalAddOnV2, selectedProducts]);

  useEffect(() => {
    setIsAddOnItemDisabled(selectedProducts.length === 0);
  }, [isAllSelectedProductOnRequest, selectedProducts, isShowPackageInfo]);

  const globalAddOnClassName = classNames({
    row: true,
    'ep--mx-0': true,
    'd-flex': true,
    'align-items-center': true,
    'justify-content-between': true,
    'ep--py-8': !isShowAddOn,
    'ep--pt-8': isShowAddOn,
    'ep--mt-8': true
  });

  const addOnsClassName = classNames({
    'col-12': true,
    'ep--pb-8': isShowAddOn
  });

  if (productAddOnConfig.globalAddOns.length === 0) return null;

  return (
    <div className={globalAddOnClassName} style={style} ref={otherAddOnRef}>
      <div className="col-1 d-flex align-items-center ep--pl-16 ep--pr-0">
        <i
          className={`ep-position-top-3 ep-cursor-pointer ep__txt--sm ep__txt-no-select ${
            isShowAddOn ? 'ep-icon-minimal-up' : 'ep-icon-minimal-down'
          }`}
          style={{ color: textColor }}
          onClick={() => setIsShowAddOn(!isShowAddOn)}
        />
      </div>

      <div className="col-7 d-flex align-items-center">
        <StyledSpan
          className="ep__txt--md ep__txt-regular ep__txt-no-select"
          font={fontText}
          style={{ color: textColor }}
        >
          {GERMAN_TEXT.OTHER_ADD_ONS} (
          {addOnV2Selections.selectedAddOnV2MapKeyByProductType[productType]
            ? addOnV2Selections.selectedAddOnV2MapKeyByProductType[productType].length
            : 0}
          )
        </StyledSpan>
      </div>
      {addOnV2TotalPriceMapKeyByProductType[productType] &&
      isPriceAddOnV2(addOnV2TotalPriceMapKeyByProductType[productType]) ? (
        <AddOnPrice
          fontText={fontText}
          textColor={textColor}
          oneTimePrice={addOnV2TotalPriceMapKeyByProductType[productType].oneTimePrice}
          monthlyPrice={addOnV2TotalPriceMapKeyByProductType[productType].monthlyPrice}
          yearlyPrice={addOnV2TotalPriceMapKeyByProductType[productType].yearlyPrice}
        />
      ) : (
        <div className="col-4 d-flex align-items-center justify-content-end">
          <StyledSpan
            font={fontText}
            className="ep__txt--md text-right ep__txt-no-select"
            style={{ color: textColor }}
          >
            {isAllOnRequestAddOns(productAddOnConfig.globalAddOns)
              ? GERMAN_TEXT.ON_REQUEST
              : `0,00 ${SPECIAL_CHARACTER.EURO}`}
          </StyledSpan>
        </div>
      )}
      {isShowAddOn && <hr className="ep--mt-8 ep--mb-0 w-100" />}
      <div
        className={addOnsClassName}
        style={{ backgroundColor: 'white', borderRadius: borderRadiusMax4px }}
      >
        <Collapse isOpen={isShowAddOn} keepChildrenMounted transitionDuration={0}>
          <div ref={addOnRef}>
            <AddOnV2
              addOns={productAddOnConfig.globalAddOns}
              textColor={textColor}
              productType={productType}
              disabled={isAddOnItemDisabled}
              addOnV2Selections={addOnV2Selections}
              fontText={fontText}
              setScrollHeight={setScrollHeight}
              primaryColor={primaryColor}
              selectAddOn={_selectAddOn}
              selectAddOnSellingOption={_selectAddOnSellingOption}
            />
          </div>
        </Collapse>
      </div>
    </div>
  );
};

GlobalAddOnContainer.propTypes = {
  addOnV2Selections: PropTypes.object.isRequired,
  selectedProducts: PropTypes.array.isRequired,
  productAddOnConfig: PropTypes.object.isRequired,
  addOnV2TotalPriceMapKeyByProductType: PropTypes.object.isRequired,
  uiStyle: PropTypes.object.isRequired,
  setScrollHeight: PropTypes.func.isRequired,
  updateAddOnSellingOptionV2: PropTypes.func.isRequired,
  removeSelectedGlobalAddOnV2: PropTypes.func.isRequired,
  updateSelectedAddOnV2: PropTypes.func.isRequired,
  isAllSelectedProductOnRequest: PropTypes.bool.isRequired,
  isShowPackageInfo: PropTypes.bool.isRequired,
  productType: PropTypes.string.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateProductSelections,
        updateSelectedAddOnV2,
        updateAddOnSellingOptionV2,
        removeInvalidSelectedAddOnV2,
        removeSelectedGlobalAddOnV2
      },
      dispatch
    )
  };
};

const mapStateToProps = state => {
  return {
    addOnV2Selections: state.addOnV2Selections,
    productAddOnConfig: state.productAddOnConfig,
    addOnV2TotalPriceMapKeyByProductType: addOnV2TotalPriceSelector(state),
    isShowPackageInfo: state.frontendConfiguration.isShowPackageInfo,
    isAllSelectedProductOnRequest: isAllSelectedProductOnRequestSelector(state),
    selectedProducts: selectedProductsSelector(state)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(GlobalAddOnContainer));

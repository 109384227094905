import { createSelector } from 'reselect';
import { CHECK_PRODUCT_AVAILABILITY, FRONTEND } from '../../common/constants';

const getAddress = state => state.customerJourneyAnswer.address;
const getPostalCode = state => state.customerJourneyAnswer.postalCode;
const getToken = state => state.frontendConfiguration.token;
const getExternalServiceUrl = state => state.frontendConfiguration.thirdPartyServiceSetting.externalServiceUrl;

export const requestParamExternalServiceSelector = createSelector(
  [getAddress, getPostalCode, getToken, getExternalServiceUrl],
  (address, postalCode, token, externalServiceUrl) => {
    return {
      externalServiceUrl,
      requestBody: {
        meta: {
          v: '1',
          action: CHECK_PRODUCT_AVAILABILITY,
          sender: {
            type: FRONTEND,
            id: token || 'fa566f12-6172-4dde-86e3-ace3965687dc'
          },
          organization_id: 179704,
          timestamp: new Date(),
          webhook_id: null
        },
        geodata: {
          postcode: (postalCode && postalCode.split(' ')[0]) || '',
          city: (postalCode && postalCode.split(' ')[1]) || '',
          street_name: address.street || '',
          street_number: address.number || '',
          country_code: '',
          additional_details: '',
          register: {
            district: address.district || '',
            lot: address.lot || '',
            plot: address.plot || ''
          },
          geometry: {
            location: {
              lat: address.lat || 0,
              lng: address.lng || 0
            }
          }
        }
      }
    };
  }
);

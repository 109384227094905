import _ from 'lodash';
import { DETECT_BROWSER, DETECT_DEVICE, TYPE_FONT_FILE } from '../constants';

export const checkCustomFont = customFontUrls => {
  let parser = new UAParser(); //eslint-disable-line
  let result = parser.getResult();
  let customFont = {};

  if (result.device.type === DETECT_DEVICE.MOBILE || result.device.type === DETECT_DEVICE.TABLET) {
    _.forEach(customFontUrls, customFontUrl => {
      if (customFontUrl.type === TYPE_FONT_FILE.TTF) {
        customFont = customFontUrl;
      }
    });
    return customFont;
  } else if (result.browser.name === DETECT_BROWSER.IE) {
    _.forEach(customFontUrls, customFontUrl => {
      if (customFontUrl.type === TYPE_FONT_FILE.EOT) {
        customFont = customFontUrl;
      }
    });
    return customFont;
  } else {
    _.forEach(customFontUrls, customFontUrl => {
      if (customFontUrl.type === TYPE_FONT_FILE.WOFF2) {
        customFont = customFontUrl;
        return true;
      } else if (customFontUrl.type === TYPE_FONT_FILE.WOFF) {
        customFont = customFontUrl;
      }
    });

    if (!customFont.url) {
      let ttfTmp = customFontUrls.find(x => x.type === TYPE_FONT_FILE.TTF);
      if (ttfTmp) {
        customFont = ttfTmp;
        return customFont;
      } else {
        customFont = customFontUrls.find(x => x.type === TYPE_FONT_FILE.EOT);
        return customFont;
      }
    }

    return customFont;
  }
};

import { createSelector } from 'reselect';
import _ from 'lodash';
import {
  KW_CONSUMPTION_CJ_ANSWERS_KEY,
  KWH_CONSUMPTION_CJ_ANSWERS_KEY,
  LITTER_PER_SEC_CONSUMPTION_CJ_ANSWERS_KEY,
  SQUARE_METRE_CONSUMPTION_CJ_ANSWERS_KEY,
  METER_CONSUMPTION_CJ_ANSWERS_KEY,
  HOUSING_UNIT_CONSUMPTION_CJ_ANSWERS_KEY,
  SPECIAL_CHARACTER
} from '../../common/constants';
import { calcTotalOutputHeatingByBuiltYearAndLivingSpace } from '../../common/utils';

const getCustomerJourneyAnswer = state => state.customerJourneyAnswer;
const getCustomerJourneyMapping = state => state.customerJourneyMapping;

export const KWHConsumptionSelector = createSelector(
  [getCustomerJourneyMapping],
  customerJourneyMapping => {
    const { totalConsumption } = filterConsumptionsByKey(
      customerJourneyMapping,
      KWH_CONSUMPTION_CJ_ANSWERS_KEY
    );
    console.log('KWH', totalConsumption === null ? 0 : totalConsumption);
    return totalConsumption === null ? 0 : totalConsumption.toFixed(2);
  }
);

export const KWConsumptionForGasSelector = createSelector(
  [getCustomerJourneyAnswer],
  customerJourneyAnswer => {
    let totalKWConsumption = null;
    const { houseConnectionGasMaxLoad } = filterConsumptionsByKey(
      customerJourneyAnswer,
      KW_CONSUMPTION_CJ_ANSWERS_KEY
    );

    totalKWConsumption = handleSumTotalConsumption(totalKWConsumption, houseConnectionGasMaxLoad);
    console.log('KW GAS', totalKWConsumption === null ? 0 : totalKWConsumption);
    return totalKWConsumption === null ? '0.00' : totalKWConsumption.toFixed(2);
  }
);

export const KWConsumptionForHeatingSelector = createSelector(
  [getCustomerJourneyAnswer],
  customerJourneyAnswer => {
    let totalKWConsumption = null;
    const { houseConnectionHeatingCapacity } = filterConsumptionsByKey(
      customerJourneyAnswer,
      KW_CONSUMPTION_CJ_ANSWERS_KEY
    );

    totalKWConsumption = handleSumTotalConsumption(
      totalKWConsumption,
      houseConnectionHeatingCapacity
    );
    console.log('KW HEATING', totalKWConsumption === null ? 0 : totalKWConsumption);
    return totalKWConsumption === null ? '0.00' : totalKWConsumption.toFixed(2);
  }
);

export const KWConsumptionForElectricitySelector = createSelector(
  [getCustomerJourneyAnswer],
  customerJourneyAnswer => {
    let totalKWConsumption = null;
    const {
      houseConnectionElectricityMaxPower,
      totalAdditionalOutput,
      outputHeating
    } = filterConsumptionsByKey(customerJourneyAnswer, KW_CONSUMPTION_CJ_ANSWERS_KEY);

    if (houseConnectionElectricityMaxPower !== SPECIAL_CHARACTER.EMPTY) {
      // use houseConnectionElectricityMaxPower to calculate total kw consumption
      totalKWConsumption = totalKWConsumption + Number(houseConnectionElectricityMaxPower);
    } else {
      totalKWConsumption = handleSumTotalConsumption(totalKWConsumption, totalAdditionalOutput);
    }

    totalKWConsumption = handleSumTotalConsumption(totalKWConsumption, outputHeating);

    if (
      customerJourneyAnswer.heatingBuiltYearIndex !== -1 &&
      customerJourneyAnswer.heatingLivingSpace !== SPECIAL_CHARACTER.EMPTY
    ) {
      totalKWConsumption = handleSumTotalConsumption(
        totalKWConsumption,
        calcTotalOutputHeatingByBuiltYearAndLivingSpace(customerJourneyAnswer)
      );
    }

    console.log('KW EXCLUDE GAS', totalKWConsumption === null ? 0 : totalKWConsumption);
    return totalKWConsumption === null ? '0.00' : totalKWConsumption.toFixed(2);
  }
);

export const litterPerSecConsumptionSelector = createSelector(
  [getCustomerJourneyAnswer],
  customerJourneyAnswer => {
    let totalLitterPerSecConsumption = null;
    const {
      houseConnectionExtinguishingWaterDemand,
      houseConnectionWaterMaxFlow,
      houseConnectionPeakWater
    } = filterConsumptionsByKey(customerJourneyAnswer, LITTER_PER_SEC_CONSUMPTION_CJ_ANSWERS_KEY);
    // user has not input value yet
    if (houseConnectionWaterMaxFlow !== SPECIAL_CHARACTER.EMPTY) {
      totalLitterPerSecConsumption =
        totalLitterPerSecConsumption + Number(houseConnectionWaterMaxFlow);
      // user has not input value yet
    } else {
      if (houseConnectionExtinguishingWaterDemand !== SPECIAL_CHARACTER.EMPTY) {
        totalLitterPerSecConsumption =
          totalLitterPerSecConsumption + Number(houseConnectionExtinguishingWaterDemand);
      }
      if (houseConnectionPeakWater !== SPECIAL_CHARACTER.EMPTY) {
        totalLitterPerSecConsumption =
          totalLitterPerSecConsumption + Number(houseConnectionPeakWater);
      }
    }
    console.log('l/s', totalLitterPerSecConsumption === null ? 0 : totalLitterPerSecConsumption);
    return totalLitterPerSecConsumption === null ? '0.00' : totalLitterPerSecConsumption.toFixed(2);
  }
);

export const meterConsumptionSelector = createSelector(
  [getCustomerJourneyAnswer],
  customerJourneyAnswer => {
    let totalMeterConsumption = null;
    const { houseConnectionTrenchLength } = filterConsumptionsByKey(
      customerJourneyAnswer,
      METER_CONSUMPTION_CJ_ANSWERS_KEY
    );
    totalMeterConsumption = handleSumTotalConsumption(
      totalMeterConsumption,
      houseConnectionTrenchLength
    );
    console.log('meter', totalMeterConsumption === null ? 0 : totalMeterConsumption);
    return totalMeterConsumption === null ? '0.00' : totalMeterConsumption.toFixed(2);
  }
);

export const meterStreetFrontLengthForHCWaterConsumptionSelector = createSelector(
  [getCustomerJourneyAnswer],
  customerJourneyAnswer => {
    let totalMeterStreetFrontLengthForHCWaterConsumption = null;
    const { houseConnectionWaterStreetFrontLength } = filterConsumptionsByKey(
      customerJourneyAnswer,
      METER_CONSUMPTION_CJ_ANSWERS_KEY
    );
    totalMeterStreetFrontLengthForHCWaterConsumption = handleSumTotalConsumption(
      totalMeterStreetFrontLengthForHCWaterConsumption,
      houseConnectionWaterStreetFrontLength
    );
    return totalMeterStreetFrontLengthForHCWaterConsumption === null
      ? '0.00'
      : totalMeterStreetFrontLengthForHCWaterConsumption.toFixed(2);
  }
);

export const squareMetrePropertySizeForHCWaterConsumptionSelector = createSelector(
  [getCustomerJourneyAnswer],
  customerJourneyAnswer => {
    let totalSquareMetreConsumptionForHCWater = null;
    const { houseConnectionWaterPropertySize } = filterConsumptionsByKey(
      customerJourneyAnswer,
      SQUARE_METRE_CONSUMPTION_CJ_ANSWERS_KEY
    );
    totalSquareMetreConsumptionForHCWater = handleSumTotalConsumption(
      totalSquareMetreConsumptionForHCWater,
      houseConnectionWaterPropertySize
    );
    return totalSquareMetreConsumptionForHCWater === null
      ? '0.00'
      : totalSquareMetreConsumptionForHCWater.toFixed(2);
  }
);

export const squareMetreFloorSizeForHCWaterConsumptionSelector = createSelector(
  [getCustomerJourneyAnswer],
  customerJourneyAnswer => {
    let totalSquareMetreConsumptionForHCWater = null;
    const { houseConnectionWaterFloorSpace } = filterConsumptionsByKey(
      customerJourneyAnswer,
      SQUARE_METRE_CONSUMPTION_CJ_ANSWERS_KEY
    );
    totalSquareMetreConsumptionForHCWater = handleSumTotalConsumption(
      totalSquareMetreConsumptionForHCWater,
      houseConnectionWaterFloorSpace
    );
    return totalSquareMetreConsumptionForHCWater === null
      ? '0.00'
      : totalSquareMetreConsumptionForHCWater.toFixed(2);
  }
);

export const housingUnitForHCWaterConsumptionSelector = createSelector(
  [getCustomerJourneyAnswer],
  customerJourneyAnswer => {
    let totalHousingUnitConsumptionForHCWater = null;
    const { houseConnectionUnits } = filterConsumptionsByKey(
      customerJourneyAnswer,
      HOUSING_UNIT_CONSUMPTION_CJ_ANSWERS_KEY
    );
    totalHousingUnitConsumptionForHCWater = handleSumTotalConsumption(
      totalHousingUnitConsumptionForHCWater,
      houseConnectionUnits
    );
    return totalHousingUnitConsumptionForHCWater === null
      ? '0.00'
      : totalHousingUnitConsumptionForHCWater.toFixed(2);
  }
);

const filterConsumptionsByKey = (customerJourneyAnswer, typeMapping) => {
  const results = {};
  _.forEach(typeMapping, elem => {
    results[elem] = customerJourneyAnswer[elem];
  });
  return results;
};

const handleSumTotalConsumption = (totalConsumption, consumption) => {
  return consumption !== SPECIAL_CHARACTER.EMPTY
    ? totalConsumption + Number(consumption)
    : totalConsumption;
};

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { formatQuestion, ShowIconByQuestionIdUtil } from '../../common/utils';
import { updateCustomerJourneyAnswer, updateCustomerJourneyMapping } from '../../store/actions';
import { COLOR, HOUSEHOLD_SIZE, USAGE_OF_ELECTRIC_CAR } from '../../common/constants';
import { StyledSpan, InfoHelpDescription } from '../../components/epilot-forms/common';

const MinusPlus = styled.section`
  display: flex;
  justify-content: center;
`;

class SpinnerQuestionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: 0,
      selections: []
    };
  }

  componentWillMount() {
    const { customerJourneyAnswer, question } = this.props;
    let householdMappings = [];
    let onePerson = {};
    let twoPerson = {};
    let threePerson = {};
    let fourPerson = {};
    let fivePerson = {};
    let fivePlusPerson = {};

    if (question.objectModel.items['1 person']) {
      onePerson = {
        householdSize: HOUSEHOLD_SIZE.ONE,
        value: question.objectModel.items['1 person']
      };
    } else {
      onePerson = {
        householdSize: HOUSEHOLD_SIZE.ONE,
        value: question.objectModel.items['1 Person']
      };
    }
    householdMappings.push(onePerson);

    if (question.objectModel.items['2 persons']) {
      twoPerson = {
        householdSize: HOUSEHOLD_SIZE.TWO,
        value: question.objectModel.items['2 persons']
      };
    } else {
      twoPerson = {
        householdSize: HOUSEHOLD_SIZE.TWO,
        value: question.objectModel.items['2 Personen']
      };
    }
    householdMappings.push(twoPerson);

    if (question.objectModel.items['3 persons']) {
      threePerson = {
        householdSize: HOUSEHOLD_SIZE.THREE,
        value: question.objectModel.items['3 persons']
      };
    } else {
      threePerson = {
        householdSize: HOUSEHOLD_SIZE.THREE,
        value: question.objectModel.items['3 Personen']
      };
    }
    householdMappings.push(threePerson);

    if (question.objectModel.items['4 persons']) {
      fourPerson = {
        householdSize: HOUSEHOLD_SIZE.FOUR,
        value: question.objectModel.items['4 persons']
      };
    } else {
      fourPerson = {
        householdSize: HOUSEHOLD_SIZE.FOUR,
        value: question.objectModel.items['4 Personen']
      };
    }
    householdMappings.push(fourPerson);

    if (question.objectModel.items['5 persons']) {
      fivePerson = {
        householdSize: HOUSEHOLD_SIZE.FIVE,
        value: question.objectModel.items['5 persons']
      };
    } else {
      fivePerson = {
        householdSize: HOUSEHOLD_SIZE.FIVE,
        value: question.objectModel.items['5 Personen']
      };
    }
    householdMappings.push(fivePerson);

    if (question.objectModel.items['> 5 persons']) {
      fivePlusPerson = {
        householdSize: HOUSEHOLD_SIZE.FIVE_PLUS,
        value: question.objectModel.items['> 5 persons']
      };
    } else {
      fivePlusPerson = {
        householdSize: HOUSEHOLD_SIZE.FIVE_PLUS,
        value: question.objectModel.items['> 5 Personen']
      };
    }
    householdMappings.push(fivePlusPerson);

    this.setState({
      answer: customerJourneyAnswer.householdSize,
      selections: householdMappings
    });
  }

  plus = () => {
    const { answer, selections } = this.state;
    const {
      customerJourneyAnswer,
      customerJourneyMapping,
      updateCustomerJourneyMapping,
      updateCustomerJourneyAnswer
    } = this.props;

    if (answer !== selections.length) {
      this.setState(
        {
          answer: answer + 1
        },
        () => {
          const { answer } = this.state;
          let newCustomerJourneyAnswer = {};
          let newCustomerJourneyMapping = {};

          newCustomerJourneyAnswer.householdSize = answer;
          newCustomerJourneyMapping.householdConsumption = Number(selections[answer - 1]['value']);

          if (customerJourneyAnswer.electricCar === USAGE_OF_ELECTRIC_CAR.YES) {
            newCustomerJourneyMapping.totalConsumption =
              newCustomerJourneyMapping.householdConsumption +
              customerJourneyMapping.electricCarUsageConsumption;
          } else {
            newCustomerJourneyMapping.totalConsumption =
              newCustomerJourneyMapping.householdConsumption;
          }
          updateCustomerJourneyMapping(newCustomerJourneyMapping);
          updateCustomerJourneyAnswer(newCustomerJourneyAnswer);
        }
      );
    }
  };

  minus = () => {
    const {
      customerJourneyAnswer,
      customerJourneyMapping,
      updateCustomerJourneyMapping,
      updateCustomerJourneyAnswer
    } = this.props;
    const { answer, selections } = this.state;

    if (answer - 1 !== 0) {
      this.setState(
        {
          answer: answer - 1
        },
        () => {
          const { answer } = this.state;
          let newCustomerJourneyAnswer = {};
          let newCustomerJourneyMapping = {};

          newCustomerJourneyAnswer.householdSize = answer;
          newCustomerJourneyMapping.householdConsumption = Number(selections[answer - 1]['value']);

          if (customerJourneyAnswer.electricCar === USAGE_OF_ELECTRIC_CAR.YES) {
            newCustomerJourneyMapping.totalConsumption =
              newCustomerJourneyMapping.householdConsumption +
              customerJourneyMapping.electricCarUsageConsumption;
          } else {
            newCustomerJourneyMapping.totalConsumption =
              newCustomerJourneyMapping.householdConsumption;
          }

          updateCustomerJourneyMapping(newCustomerJourneyMapping);
          updateCustomerJourneyAnswer(newCustomerJourneyAnswer);
        }
      );
    }
  };

  render() {
    const {
      frontendConfiguration,
      customerJourneyAnswer,
      question,
      fontText,
      defaultTextColor
    } = this.props;
    const { answer, selections } = this.state;

    const enabled = {
      color: '#222',
      opacity: '1',
      cursor: 'pointer'
    };

    const disabled = {
      color: '#222',
      opacity: '0.5',
      cursor: 'not-allowed'
    };

    const textColor = {
      color: defaultTextColor
    };

    return (
      <div className="ep-customerjourneypage__question">
        <ShowIconByQuestionIdUtil
          questionId={question.id}
          colorIcon={frontendConfiguration.primaryColor}
        />

        <div className="text-center ep-min-height-70 ep--pt-8 ep--pb-16">
          <StyledSpan
            font={fontText}
            className="ep__txt-regular ep__txt--lg ep__txt-no-select"
            style={textColor}
          >
            {formatQuestion(question.questionTitle, customerJourneyAnswer.name)}
          </StyledSpan>
          <InfoHelpDescription
            showHelpDescription={question.showHelpDescription}
            tooltipInfoContent={<StyledSpan font={fontText}>{question.helpDescription}</StyledSpan>}
            classNameForInfoIcon="ep__txt--lg ep--ml-8"
            defaultTextColor={defaultTextColor}
          />
        </div>

        <div className="ep-min-height-130">
          <MinusPlus>
            <i
              className="ep-icon-circle-delete ep__txt--ic"
              style={answer - 1 !== 0 ? enabled : disabled}
              onClick={this.minus}
            />
            <StyledSpan
              className="ep__txt--h1 ep__txt-regular ep-width-62 ep__txt-no-select"
              font={fontText}
              style={textColor}
            >
              {selections[answer - 1]['householdSize']}
            </StyledSpan>
            <i
              className="ep-icon-circle-add ep__txt--ic"
              style={answer - 1 !== selections.length - 1 ? enabled : disabled}
              onClick={this.plus}
            />
          </MinusPlus>
        </div>
      </div>
    );
  }
}

SpinnerQuestionContainer.propTypes = {
  question: PropTypes.object,
  fontText: PropTypes.object,
  defaultTextColor: PropTypes.string,
  frontendConfiguration: PropTypes.object,
  customerJourneyAnswer: PropTypes.object,
  customerJourneyMapping: PropTypes.object,
  updateCustomerJourneyAnswer: PropTypes.func,
  updateCustomerJourneyMapping: PropTypes.func
};

SpinnerQuestionContainer.defaultProps = {
  question: {},
  fontText: {},
  defaultTextColor: COLOR.DARK_GRAY,
  frontendConfiguration: {},
  customerJourneyAnswer: {},
  customerJourneyMapping: {},
  updateCustomerJourneyAnswer: () => ({}),
  updateCustomerJourneyMapping: () => ({})
};

function mapStateToProps(state) {
  return {
    frontendConfiguration: state.frontendConfiguration,
    customerJourneyAnswer: state.customerJourneyAnswer,
    customerJourneyMapping: state.customerJourneyMapping
  };
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateCustomerJourneyAnswer,
        updateCustomerJourneyMapping
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpinnerQuestionContainer);

import React from 'react';
import * as PropTypes from 'prop-types';
import { ShowIfUtil } from './ShowIfUtil';
import { hexaToRGBA } from './HexaToRGBAUtil';
import { QUESTION_ID, QUESTION_TYPE } from '../constants';

export const ShowIconByQuestionIdUtil = ({ questionId, questionType, colorIcon }) => {
  if (!colorIcon) return null;
  return (
    <section className="ep--mb-8">
      <ShowIfUtil
        condition={
          questionId === QUESTION_ID.NAME ||
          questionId === QUESTION_ID.HEATING_NAME ||
          questionId === QUESTION_ID.HOUSE_CONNECTION_NAME ||
          questionId === QUESTION_ID.CHARGE_NAME
        }
      >
        <div
          className="mx-auto ep-wrapper-icon-title-question"
          style={{ background: hexaToRGBA(colorIcon, 0.05) }}
        >
          <i
            className="d-inline-block ep-icon-single-03"
            style={{ color: colorIcon, fontSize: '4.375rem' }}
          />
        </div>
      </ShowIfUtil>
      <ShowIfUtil condition={questionId === QUESTION_ID.HOUSEHOLD_SIZE}>
        <div
          className="mx-auto ep-wrapper-icon-title-question"
          style={{ background: hexaToRGBA(colorIcon, 0.05) }}
        >
          <i
            className="d-inline-block ep-icon-multiple-11"
            style={{ color: colorIcon, fontSize: '4.375rem' }}
          />
        </div>
      </ShowIfUtil>
      <ShowIfUtil condition={questionId === QUESTION_ID.AUTARKY}>
        <div
          className="mx-auto ep-wrapper-icon-title-question"
          style={{ background: hexaToRGBA(colorIcon, 0.05) }}
        >
          <i
            className="d-inline-block ep-icon-battery-level"
            style={{ color: colorIcon, fontSize: '4.375rem' }}
          />
        </div>
      </ShowIfUtil>
      <ShowIfUtil condition={questionId === QUESTION_ID.ELECTRIC_CAR}>
        <div
          className="mx-auto ep-wrapper-icon-title-question"
          style={{ background: hexaToRGBA(colorIcon, 0.05) }}
        >
          <i
            className="d-inline-block ep-icon-car-connect"
            style={{ color: colorIcon, fontSize: '4.375rem' }}
          />
        </div>
      </ShowIfUtil>
      <ShowIfUtil condition={questionId === QUESTION_ID.ELECTRIC_CAR_USAGE}>
        <div
          className="mx-auto ep-wrapper-icon-title-question"
          style={{ background: hexaToRGBA(colorIcon, 0.05) }}
        >
          <i
            className="d-inline-block ep-icon-navigation"
            style={{ color: colorIcon, fontSize: '4.375rem' }}
          />
        </div>
      </ShowIfUtil>
      <ShowIfUtil condition={questionId === QUESTION_ID.ELECTRIC_CAR_CHARGE_SPEED}>
        <div
          className="mx-auto ep-wrapper-icon-title-question"
          style={{ background: hexaToRGBA(colorIcon, 0.05) }}
        >
          <i
            className="d-inline-block ep-icon-fuel-electric"
            style={{ color: colorIcon, fontSize: '4.375rem' }}
          />
        </div>
      </ShowIfUtil>
      <ShowIfUtil condition={questionId === QUESTION_ID.POWER_FLAT_RATE}>
        <div
          className="mx-auto ep-wrapper-icon-title-question"
          style={{ background: hexaToRGBA(colorIcon, 0.05) }}
        >
          <i
            className="d-inline-block ep-icon-hybrid"
            style={{ color: colorIcon, fontSize: '4.375rem' }}
          />
        </div>
      </ShowIfUtil>
      <ShowIfUtil condition={questionId === QUESTION_ID.USAGE_OF_GAS}>
        <div
          className="mx-auto ep-wrapper-icon-title-question"
          style={{ background: hexaToRGBA(colorIcon, 0.05) }}
        >
          <i
            className="d-inline-block ep-icon-flame"
            style={{ color: colorIcon, fontSize: '4.375rem' }}
          />
        </div>
      </ShowIfUtil>
      <ShowIfUtil
        condition={
          questionId === QUESTION_ID.OUTPUT_OF_HEATING_KNOWING ||
          questionId === QUESTION_ID.OUTPUT_OF_HEATING ||
          questionId === QUESTION_ID.HEATING_ITEM
        }
      >
        <div
          className="mx-auto ep-wrapper-icon-title-question"
          style={{ background: hexaToRGBA(colorIcon, 0.05) }}
        >
          <i
            className="d-inline-block epc-icon-heating"
            style={{ color: colorIcon, fontSize: '4.375rem' }}
          />
        </div>
      </ShowIfUtil>
      <ShowIfUtil condition={questionId === QUESTION_ID.HEATING_LIVING_SPACE}>
        <div
          className="mx-auto ep-wrapper-icon-title-question"
          style={{ background: hexaToRGBA(colorIcon, 0.05) }}
        >
          <i
            className="d-inline-block ep-icon-full-screen"
            style={{ color: colorIcon, fontSize: '4.375rem' }}
          />
        </div>
      </ShowIfUtil>
      <ShowIfUtil condition={questionId === QUESTION_ID.HEATING_BUILDING_YEAR}>
        <div
          className="mx-auto ep-wrapper-icon-title-question"
          style={{ background: hexaToRGBA(colorIcon, 0.05) }}
        >
          <i
            className="d-inline-block ep-icon-dog-house"
            style={{ color: colorIcon, fontSize: '4.375rem' }}
          />
        </div>
      </ShowIfUtil>
      <ShowIfUtil
        condition={
          questionId === QUESTION_ID.HOUSE_CONNECTION_NEW_OR_CHANGE ||
          questionId === QUESTION_ID.HOUSE_CONNECTION_SELECT_PRODUCT ||
          questionId === QUESTION_ID.HOUSE_CONNECTION_INSTALLATION_DATE
        }
      >
        <div
          className="mx-auto ep-wrapper-icon-title-question"
          style={{ background: hexaToRGBA(colorIcon, 0.05) }}
        >
          <i
            className="d-inline-block ep-icon-settings"
            style={{ color: colorIcon, fontSize: '4.375rem' }}
          />
        </div>
      </ShowIfUtil>
      <ShowIfUtil
        condition={
          questionId === QUESTION_ID.HOUSE_CONNECTION_PRIVATELY_OR_BUSINESS ||
          questionId === QUESTION_ID.HOUSE_CONNECTION_PREPARATION_WATER_HEATERS ||
          questionId === QUESTION_ID.HOUSE_CONNECTION_BUILDING_TYPE ||
          questionId === QUESTION_ID.HOUSE_CONNECTION_SINGLE_FAMILY_HOUSE ||
          questionId === QUESTION_ID.CHARGE_PRIVATELY_OR_BUSINESS ||
          questionId === QUESTION_ID.HOUSE_CONNECTION_UNITS ||
          questionId === QUESTION_ID.HOUSE_CONNECTION_TRENCH_LENGTH ||
          questionId === QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_FEED_INTO_GRID ||
          questionId === QUESTION_ID.HOUSE_CONNECTION_EXTINGUISHING_WATER_DEMAND ||
          questionId === QUESTION_ID.HOUSE_CONNECTION_OUTLET_PRESSURE_REQUIRED ||
          questionId === QUESTION_ID.HOUSE_CONNECTION_PEAK_WATER ||
          questionId === QUESTION_ID.HOUSE_CONNECTION_WATER_STREET_FRONT_LENGTH ||
          questionId === QUESTION_ID.HOUSE_CONNECTION_WATER_PROPERTY_SIZE ||
          questionId === QUESTION_ID.HOUSE_CONNECTION_WATER_FLOOR_SPACE
        }
      >
        <div
          className="mx-auto ep-wrapper-icon-title-question"
          style={{ background: hexaToRGBA(colorIcon, 0.05) }}
        >
          <i
            className="d-inline-block ep-icon-home-52"
            style={{ color: colorIcon, fontSize: '4.375rem' }}
          />
        </div>
      </ShowIfUtil>
      <ShowIfUtil
        condition={
          questionId === QUESTION_ID.HOUSE_CONNECTION_APARTMENT_BUILDING ||
          questionId === QUESTION_ID.HOUSE_CONNECTION_IS_NEW_BUILDING ||
          questionId === QUESTION_ID.HOUSE_CONNECTION_UPLOAD_PROPERTY_DOCUMENT
        }
      >
        <div
          className="mx-auto ep-wrapper-icon-title-question"
          style={{ background: hexaToRGBA(colorIcon, 0.05) }}
        >
          <i
            className="d-inline-block ep-icon-building"
            style={{ color: colorIcon, fontSize: '4.375rem' }}
          />
        </div>
      </ShowIfUtil>
      <ShowIfUtil
        condition={
          questionId === QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_MAX_POWER ||
          questionId === QUESTION_ID.HOUSE_CONNECTION_GAS_MAX_LOAD ||
          questionId === QUESTION_ID.HOUSE_CONNECTION_WATER_MAX_FLOW
        }
      >
        <div
          className="mx-auto ep-wrapper-icon-title-question"
          style={{ background: hexaToRGBA(colorIcon, 0.05) }}
        >
          <i
            className="d-inline-block ep-icon-preferences"
            style={{ color: colorIcon, fontSize: '4.375rem' }}
          />
        </div>
      </ShowIfUtil>
      <ShowIfUtil
        condition={
          questionId === QUESTION_ID.HOUSE_CONNECTION_IS_OWNER ||
          questionId === QUESTION_ID.HOUSE_CONNECTION_UPLOAD_CONSENT_FORM
        }
      >
        <div
          className="mx-auto ep-wrapper-icon-title-question"
          style={{ background: hexaToRGBA(colorIcon, 0.05) }}
        >
          <i
            className="d-inline-block ep-icon-key-26"
            style={{ color: colorIcon, fontSize: '4.375rem' }}
          />
        </div>
      </ShowIfUtil>
      <ShowIfUtil
        condition={
          questionId === QUESTION_ID.POSTAL_CODE || questionId === QUESTION_ID.ADDRESS_DETAILS
        }
      >
        <div
          className="mx-auto ep-wrapper-icon-title-question"
          style={{ background: hexaToRGBA(colorIcon, 0.05) }}
        >
          <i
            className="d-inline-block ep-icon-m-location"
            style={{ color: colorIcon, fontSize: '4.375rem' }}
          />
        </div>
      </ShowIfUtil>
      <ShowIfUtil condition={questionId === QUESTION_ID.CHARGE_TYPE}>
        <div
          className="mx-auto ep-wrapper-icon-title-question"
          style={{ background: hexaToRGBA(colorIcon, 0.05) }}
        >
          <i
            className="d-inline-block epc-icon-charge"
            style={{ color: colorIcon, fontSize: '5.625rem' }}
          />
        </div>
      </ShowIfUtil>
      <ShowIfUtil condition={questionId === QUESTION_ID.CHARGE_CAR_SPEED}>
        <div
          className="mx-auto ep-wrapper-icon-title-question"
          style={{ background: hexaToRGBA(colorIcon, 0.05) }}
        >
          <i
            className="d-inline-block ep-icon-time"
            style={{ color: colorIcon, fontSize: '5rem' }}
          />
        </div>
      </ShowIfUtil>
      <ShowIfUtil
        condition={
          questionId === QUESTION_ID.HOUSE_CONNECTION_E_MOBILITY ||
          questionId === QUESTION_ID.HOUSE_CONNECTION_E_MOBILITY_REQUIREMENT
        }
      >
        <div
          className="mx-auto ep-wrapper-icon-title-question"
          style={{ background: hexaToRGBA(colorIcon, 0.05) }}
        >
          <i
            className="d-inline-block ep-icon-fuel-electric"
            style={{ color: colorIcon, fontSize: '5rem' }}
          />
        </div>
      </ShowIfUtil>
      <ShowIfUtil
        condition={
          questionType === QUESTION_TYPE.MULTIPLE_CHOICE ||
          questionType === QUESTION_TYPE.DATE_PICKER ||
          questionType === QUESTION_TYPE.FILE_UPLOAD
        }
      >
        <div
          className="mx-auto ep-wrapper-icon-title-question"
          style={{ background: hexaToRGBA(colorIcon, 0.05) }}
        >
          <i
            className="d-inline-block ep-icon-settings"
            style={{ color: colorIcon, fontSize: '4.375rem' }}
          />
        </div>
      </ShowIfUtil>
    </section>
  );
};

ShowIconByQuestionIdUtil.propTypes = {
  questionType: PropTypes.string,
  colorIcon: PropTypes.string,
  questionId: PropTypes.number
};

ShowIconByQuestionIdUtil.defaultProps = {
  questionType: '',
  colorIcon: '',
  questionId: 0
};

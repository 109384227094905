import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { Position, Tooltip } from '@blueprintjs/core';
import {
  ADDON_AVAILABILITY,
  COLOR,
  FRONTEND_TYPE,
  PACKAGE_PREMIUM_TYPE_KEY,
  PACKAGE_SELECTED_KEY,
  PAYMENT_TYPE,
  PRODUCT_AVAILABLE_STATUS,
  PRODUCT_TYPE,
  RENTAL,
  RENTAL_UNIT,
  SPECIAL_CHARACTER,
  PRODUCT_STATUS,
  PRODUCT_DEACTIVATED_MESSAGE
} from '../../common/constants';
import { CheckBox, StyledSpan, StyledSpanBold } from '../../components/epilot-forms';
import { Addon } from '.';
import {
  convertProdTypeToProdName,
  formatPrice,
  formatSpecification,
  ShowIconByProductTypeUtil,
  ShowIfUtil
} from '../../common/utils';
import {
  removeAutomaticSelectedAddon,
  removeSelectedAddons,
  updateProductSelections,
  updateSelectedAddon
} from '../../store/actions';
import { productAddonsMappingSelector, productSelectionsSelector } from '../../store/selectors';

class ProductContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedPackageName: 'Kein Paket ausgewählt',
      selectedPackageSpecifications: '-',
      price: '-',
      upfrontFee: SPECIAL_CHARACTER.EMPTY,
      onRequest: false,
      isOpen: false
    };
    this.addonElement = null;
  }

  componentDidMount() {
    const { setScrollHeight } = this.props;
    this.renderSelectedPackageInfo();
    setScrollHeight();
  }

  componentDidUpdate(prevProps) {
    const { productSelections, product, productAddonsMapping } = this.props;
    const { isOpen } = this.state;
    if (this.props !== prevProps) {
      // switch between buy and rent, check package on request and remove addon if needed
      if (
        productSelections.selectedPurchaseRental !==
        prevProps.productSelections.selectedPurchaseRental
      ) {
        let isPackageOnRequest = this.checkCurrentPackageOnRequest();
        if (isPackageOnRequest) {
          const singleAddonSelectionGroupIds =
            productAddonsMapping[product.id].singleAddonSelectionGroupIds;
          const normalAddonIds = productAddonsMapping[product.id].normalAddonIds;
          this.handleSelectAddon(null, [...singleAddonSelectionGroupIds, ...normalAddonIds]);
        }
      }
      if (
        !isOpen &&
        productSelections.automaticSelectedAddonIds !==
          prevProps.productSelections.automaticSelectedAddonIds
      ) {
        this.toggleShowAddon();
      }
      this.renderSelectedPackageInfo();
    }
  }

  checkCurrentPackageOnRequest = () => {
    const { productSelections, product } = this.props;
    const currentSelectedPackage = productSelections[PACKAGE_SELECTED_KEY[product.type]];
    const isCurrentPackagePremium = productSelections[PACKAGE_PREMIUM_TYPE_KEY[product.type]];
    let isPackageOnRequest = true;
    if (productSelections.selectedPurchaseRental === PAYMENT_TYPE.PURCHASE) {
      if (isCurrentPackagePremium) {
        if (currentSelectedPackage && currentSelectedPackage.premiumRetailAble) {
          isPackageOnRequest = false;
        }
      } else if (currentSelectedPackage && currentSelectedPackage.standardRetailAble) {
        isPackageOnRequest = false;
      }
    } else {
      if (isCurrentPackagePremium) {
        if (currentSelectedPackage && currentSelectedPackage.premiumRentedStatus) {
          isPackageOnRequest = false;
        }
      } else if (currentSelectedPackage && currentSelectedPackage.standardRentedStatus) {
        isPackageOnRequest = false;
      }
    }
    return isPackageOnRequest;
  };

  setRefAddonComponent = ref => (this.addonElement = ref);

  viewAllPackages = product => {
    const { customerJourneyAnswer, viewAllPackages } = this.props;
    if (customerJourneyAnswer.availableProduct.indexOf(product.type) >= 0) {
      viewAllPackages(product);
    }
  };

  renderSelectedPackageInfo = () => {
    const { product, productSelections } = this.props;
    const currentSelectedPackage = productSelections[PACKAGE_SELECTED_KEY[product.type]];
    if (currentSelectedPackage && currentSelectedPackage.id) {
      this.setSelectedPackageInfo();
      this.calculateSelectedPackagePrice();
    } else {
      this.setNoPackageSelectedInfo();
    }
  };

  setNoPackageSelectedInfo = () => {
    const { product } = this.props;
    if (
      product.type === PRODUCT_TYPE.SOLAR ||
      product.type === PRODUCT_TYPE.STORAGE ||
      product.type === PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY ||
      product.type === PRODUCT_TYPE.HOUSE_CONNECTION_GAS ||
      product.type === PRODUCT_TYPE.HOUSE_CONNECTION_WATER ||
      product.type === PRODUCT_TYPE.HOUSE_CONNECTION_HEAT ||
      product.type === PRODUCT_TYPE.HOUSE_CONNECTION_BROADBAND ||
      product.type === PRODUCT_TYPE.HEATING ||
      product.type === PRODUCT_TYPE.SOLAR_B2B
    ) {
      this.setState({
        selectedPackageName: !_.isEmpty(product.packages)
          ? product.packages[0].packageName
          : convertProdTypeToProdName(product.type),
        selectedPackageSpecifications: '-',
        price: RENTAL.ON_REQUEST,
        upfrontFee: SPECIAL_CHARACTER.EMPTY,
        onRequest: false
      });
    } else {
      this.setState({
        selectedPackageName: 'Kein Paket ausgewählt',
        selectedPackageSpecifications: '-',
        price: '-',
        upfrontFee: SPECIAL_CHARACTER.EMPTY,
        onRequest: false
      });
    }
  };

  setSelectedPackageInfo = () => {
    const { product, productSelections } = this.props;
    const currentSelectedPackage = productSelections[PACKAGE_SELECTED_KEY[product.type]];
    const isCurrentSelectedPackagePremium =
      productSelections[PACKAGE_PREMIUM_TYPE_KEY[product.type]];
    this.setState({
      selectedPackageName: currentSelectedPackage.packageName,
      selectedPackageSpecifications: formatSpecification(
        product.type,
        currentSelectedPackage.specifications
      ),
      upfrontFee: this.getUpFrontFee(currentSelectedPackage, isCurrentSelectedPackagePremium)
    });
  };

  calculateSelectedPackagePrice = () => {
    const { product, productSelections } = this.props;
    const currentPackage = productSelections[PACKAGE_SELECTED_KEY[product.type]];
    const isCurrentPackagePremium = productSelections[PACKAGE_PREMIUM_TYPE_KEY[product.type]];
    if (product.showOnRequest) {
      this.setState({
        onRequest: true,
        price: RENTAL.ON_REQUEST
      });
    } else {
      if (productSelections.selectedPurchaseRental === PAYMENT_TYPE.PURCHASE) {
        if (
          (!currentPackage.standardRetailAble && !isCurrentPackagePremium) ||
          (!currentPackage.premiumRetailAble && isCurrentPackagePremium)
        ) {
          this.setState({
            onRequest: true,
            price: RENTAL.ON_REQUEST
          });
        } else {
          this.setState({
            price: formatPrice(
              isCurrentPackagePremium
                ? currentPackage.premiumRetailPriceGross
                : currentPackage.standardRetailPriceGross
            )
          });
        }
      } else {
        if (
          (!currentPackage.standardRentedStatus && !isCurrentPackagePremium) ||
          (!currentPackage.premiumRentedStatus && isCurrentPackagePremium)
        ) {
          this.setState({
            onRequest: true,
            price: RENTAL.ON_REQUEST
          });
        } else {
          const monthlyPrice = this.filterMonthlyPriceByPackageType(
            isCurrentPackagePremium,
            currentPackage
          );
          this.setState({
            onRequest: false,
            price: formatPrice(monthlyPrice)
          });
        }
      }
    }
  };

  getUpFrontFee = (packageSelected, isPremium) => {
    let upfrontFee = 0;

    if (isPremium) {
      if (packageSelected.premiumRentedStatus && packageSelected.premiumRentalUpfrontFee) {
        upfrontFee = packageSelected.premiumRentalUpfrontFeeGross;
      }
    } else {
      if (packageSelected.standardRentedStatus && packageSelected.standardRentalUpfrontFee) {
        upfrontFee = packageSelected.standardRentalUpfrontFeeGross;
      }
    }

    return upfrontFee;
  };

  filterMonthlyPriceByPackageType = (packageType, packageSelected) => {
    const { productSelections } = this.props;
    const packageRentalType = packageType ? 1 : 0;
    let monthlyPrice = 0;
    if (!packageSelected.packageRentals) {
      monthlyPrice =
        packageRentalType === 0
          ? packageSelected.standardRetailPriceGross
          : packageSelected.premiumRetailPriceGross;
    } else {
      _.forEach(packageSelected.packageRentals, object => {
        if (
          object.packageRentalType === packageRentalType &&
          object.duration === productSelections.selectedRentalInfo.duration &&
          object.unit === RENTAL_UNIT[productSelections.selectedRentalInfo.unit]
        ) {
          monthlyPrice = object.priceGross;
        }
      });
    }
    return monthlyPrice;
  };

  toggleShowAddon = () => {
    const { setScrollHeight } = this.props;
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen }, () => {
      const { isOpen } = this.state;
      if (this.addonElement) {
        isOpen
          ? setScrollHeight(this.addonElement.clientHeight)
          : setScrollHeight(-this.addonElement.clientHeight);
      }
    });
  };

  handleSelectAddon = (addAddonId, removeAddonIds) => {
    const { removeSelectedAddons, updateSelectedAddon } = this.props;
    if (addAddonId) {
      updateSelectedAddon(addAddonId);
    }
    if (!_.isEmpty(removeAddonIds)) {
      removeSelectedAddons(removeAddonIds);
    }
  };

  isAddonOnRequest = addon => {
    const { productSelections } = this.props;
    return (
      (productSelections.selectedPurchaseRental === PAYMENT_TYPE.PURCHASE && !addon.retailAble) ||
      (productSelections.selectedPurchaseRental === PAYMENT_TYPE.RENTAL && !addon.rentable)
    );
  };

  calculateTotalAddonPrice = () => {
    const { productSelections, product } = this.props;
    let totalAddonPrice = 0;
    product.addons &&
      _.forEach(product.addons, addon => {
        if (productSelections.selectedAddonIds.indexOf(addon.id) >= 0) {
          if (
            productSelections.selectedPurchaseRental === PAYMENT_TYPE.PURCHASE &&
            addon.retailAble
          ) {
            totalAddonPrice += addon.retailPriceGross;
          } else {
            if (addon.rentable) {
              _.forEach(addon.addonRentals, rental => {
                if (
                  rental.duration === productSelections.selectedRentalInfo.duration &&
                  rental.unit === RENTAL_UNIT[productSelections.selectedRentalInfo.unit]
                ) {
                  totalAddonPrice += rental.grossPrice;
                }
              });
            }
          }
        }
      });
    return totalAddonPrice;
  };

  displayTotalAddonPrice = price => {
    const { product, productSelections } = this.props;
    let isOnRequest =
      product.addons &&
      _.some(product.addons, addon => {
        if (productSelections.selectedAddonIds.indexOf(addon.id) >= 0) {
          if (this.isAddonOnRequest(addon)) {
            return true;
          }
        }
      });
    isOnRequest = product.showOnRequest ? product.showOnRequest : isOnRequest;
    if (isOnRequest) {
      return RENTAL.ON_REQUEST;
    }
    if (productSelections.selectedPurchaseRental === PAYMENT_TYPE.PURCHASE) {
      return formatPrice(price);
    }
    return formatPrice(price) + SPECIAL_CHARACTER.SPACE + RENTAL.PER_MONTH;
  };

  numberOfSelectedAddon = () => {
    const { product, productSelections } = this.props;
    let numberOfSelectedAddon = 0;
    product.addons &&
      _.forEach(product.addons, addon => {
        if (productSelections.selectedAddonIds.indexOf(addon.id) >= 0) {
          numberOfSelectedAddon += 1;
        }
      });
    return numberOfSelectedAddon;
  };

  numberOfDisplayedAddon = () => {
    const { productSelections, product } = this.props;
    let numberOfDisplayedAddon = 0;

    _.forEach(product.addons, addon => {
      const isAddonSpecificToSelectedPackage = productSelections[PACKAGE_SELECTED_KEY[product.type]]
        .specificAddonIds
        ? productSelections[PACKAGE_SELECTED_KEY[product.type]].specificAddonIds.indexOf(
            addon.id
          ) >= 0
        : false;
      const displayAddon =
        addon.availability === ADDON_AVAILABILITY.ALL_PACKAGES ||
        addon.availability === ADDON_AVAILABILITY.PACKAGE_BY_VENDOR ||
        isAddonSpecificToSelectedPackage;

      if (displayAddon) {
        numberOfDisplayedAddon += 1;
      }
    });
    return numberOfDisplayedAddon;
  };

  renderAddons = () => {
    const { isOpen } = this.state;
    const {
      fontText,
      product,
      productSelections,
      frontendConfiguration,
      isSelectedProduct,
      isAddonEnable,
      productAddonsMapping,
      removeAutomaticSelectedAddon,
      setScrollHeight
    } = this.props;
    if (_.isEmpty(productAddonsMapping) || _.isEmpty(productAddonsMapping[product.id])) {
      return null;
    }
    const textColor = COLOR.DARK_GRAY;
    const currentSelectedPackage = productSelections[PACKAGE_SELECTED_KEY[product.type]];
    let isPackageOnRequest = this.checkCurrentPackageOnRequest();
    const normalAddons = productAddonsMapping[product.id].normalAddons;
    const singleSelectionAddons = _.filter(
      productAddonsMapping[product.id].singleAddonSelectionGroup,
      addon => {
        return (
          _.indexOf(currentSelectedPackage.specificAddonIds, addon.id) !== -1 ||
          addon.availability === ADDON_AVAILABILITY.ALL_PACKAGES ||
          addon.availability === ADDON_AVAILABILITY.PACKAGE_BY_VENDOR
        );
      }
    );
    const singleSelectionAddonMap = productAddonsMapping[product.id].singleAddonSelectionGroupMap;
    const singleSelectionAddonIds = productAddonsMapping[product.id].singleAddonSelectionGroupIds;

    return (
      <ShowIfUtil condition={this.numberOfDisplayedAddon() > 0}>
        <Addon
          fontText={fontText}
          removeAutomaticSelectedAddon={removeAutomaticSelectedAddon}
          normalAddons={normalAddons}
          singleSelectionAddons={singleSelectionAddons}
          singleSelectionAddonIds={singleSelectionAddonIds}
          singleSelectionAddonMap={singleSelectionAddonMap}
          automaticSelectedAddonIds={productSelections.automaticSelectedAddonIds}
          productSelections={productSelections}
          customBorderRadius={frontendConfiguration.borderRadiusMax4px}
          defaultTextColor={frontendConfiguration.defaultTextColor}
          primaryColor={frontendConfiguration.primaryColor}
          isDisableAddonCheckbox={!isSelectedProduct || !isAddonEnable || isPackageOnRequest}
          textColor={textColor}
          currentSelectedPackage={currentSelectedPackage}
          toggleShowAddon={this.toggleShowAddon}
          setRefAddonComponent={this.setRefAddonComponent}
          handleSelectAddon={this.handleSelectAddon}
          setScrollHeight={setScrollHeight}
          addonTotalPrice={this.displayTotalAddonPrice(this.calculateTotalAddonPrice())}
          selectedAddonsCount={this.numberOfSelectedAddon()}
          isShowAddon={isOpen}
        />
      </ShowIfUtil>
    );
  };

  render() {
    const {
      selectedPackageName,
      selectedPackageSpecifications,
      price,
      upfrontFee,
      onRequest
    } = this.state;
    const {
      product,
      fontText,
      fontTextBold,
      frontendConfiguration,
      selectProduct,
      isSelectedProduct,
      productSelections
    } = this.props;
    // Always show package info in WEBSHOP
    const isShowPackageInfo =
      frontendConfiguration.isShowPackageInfo ||
      frontendConfiguration.frontendType === FRONTEND_TYPE.WEBSHOP;

    const tooltipTextColor = {
      color: frontendConfiguration.defaultTextColor
    };

    let style;
    let textColor;
    let iconColor;
    let shadowClass = SPECIAL_CHARACTER.EMPTY;

    const available =
      product.availableStatus && product.availableStatus === PRODUCT_AVAILABLE_STATUS.ACTIVATED;
    const deactivatedProduct = !!(product.status && product.status === PRODUCT_STATUS.DEACTIVATED);

    if (available && !deactivatedProduct) {
      if (isSelectedProduct) {
        style = {
          backgroundColor: frontendConfiguration.primaryColor,
          color: frontendConfiguration.primaryTextColor,
          borderRadius: frontendConfiguration.borderRadiusMax4px
        };

        textColor = {
          color: frontendConfiguration.primaryTextColor
        };

        iconColor = frontendConfiguration.primaryTextColor;
      } else {
        style = {
          backgroundColor: COLOR.TRANSPARENT,
          color: frontendConfiguration.defaultTextColor,
          border: '1px solid rgba(68,68,68,0.16)',
          borderRadius: frontendConfiguration.borderRadiusMax4px
        };

        textColor = {
          color: frontendConfiguration.defaultTextColor
        };

        iconColor = frontendConfiguration.defaultTextColor;
      }

      shadowClass = ' box-shadow';
    } else {
      style = {
        backgroundColor: '#F9F9F9',
        color: '#8A8A8A',
        borderRadius: frontendConfiguration.borderRadiusMax4px
      };

      textColor = {
        color: '#8A8A8A'
      };

      iconColor = '#8A8A8A';
    }
    let productWrapperClass =
      frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY
        ? 'ep-webshop-product-wrapper-customer-journey ep--mt-8'
        : 'ep-webshop-product-wrapper ep--mt-8';
    return (
      <div>
        <Tooltip
          content={
            <StyledSpan font={fontText} style={tooltipTextColor}>
              {'Dieses Produkt ist leider nicht an der angegebenen Postleitzahl verfügbar.'}
            </StyledSpan>
          }
          className="w-100 ep--px-8"
          position={Position.BOTTOM}
          tooltipClassName="ep-tooltip"
          disabled={available}
        >
          <div
            className={
              productWrapperClass +
              ' d-flex align-items-center justify-content-between' +
              shadowClass
            }
            style={style}
          >
            <div className="d-flex align-items-center ep-ellipsis ep--mr-8">
              <CheckBox
                className="ep--ml-16"
                onChange={() => selectProduct(!isSelectedProduct, product, available)}
                disabled={!available || deactivatedProduct}
                content={SPECIAL_CHARACTER.EMPTY}
                isChecked={available && !deactivatedProduct ? isSelectedProduct : false}
                primaryTextColor={frontendConfiguration.primaryTextColor}
                primaryCheckBoxIconColor={frontendConfiguration.primaryColor}
              />

              <Tooltip
                content={
                  <StyledSpan font={fontText} style={tooltipTextColor}>
                    {convertProdTypeToProdName(product.type)}
                  </StyledSpan>
                }
                position={Position.BOTTOM}
                disabled={!available}
                tooltipClassName="ep-tooltip"
                className="ep--ml-8"
              >
                <ShowIconByProductTypeUtil color={iconColor} productType={product.type} />
              </Tooltip>

              <div className="ep--ml-16 w-100 ep-ellipsis">
                <ShowIfUtil condition={!isShowPackageInfo}>
                  <StyledSpan
                    font={fontText}
                    dangerouslySetInnerHTML={{
                      __html: convertProdTypeToProdName(product.type)
                    }}
                    className="ep__txt--lg ep__txt-semibold"
                    style={textColor}
                  />
                </ShowIfUtil>

                <ShowIfUtil condition={isShowPackageInfo}>
                  {/* PACKAGE NAME */}
                  <div>
                    <Tooltip
                      content={
                        <StyledSpan font={fontText} style={tooltipTextColor}>
                          {selectedPackageName}
                        </StyledSpan>
                      }
                      position={Position.BOTTOM}
                      tooltipClassName="ep-tooltip ep-tooltip-width-300"
                      disabled={!available}
                      className="ep-ellipsis w-100"
                    >
                      <div className="ep-ellipsis w-100">
                        <StyledSpanBold
                          font={fontTextBold}
                          className={
                            frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY
                              ? 'ep__txt--lg ep__txt-bold'
                              : 'ep__txt--md ep__txt-bold'
                          }
                          style={textColor}
                        >
                          {selectedPackageName}
                        </StyledSpanBold>
                      </div>
                    </Tooltip>
                  </div>
                  <ShowIfUtil condition={!deactivatedProduct}>
                    {/* POWER */}
                    <ShowIfUtil
                      condition={
                        product.type !== PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY &&
                        product.type !== PRODUCT_TYPE.HOUSE_CONNECTION_GAS &&
                        product.type !== PRODUCT_TYPE.HOUSE_CONNECTION_WATER &&
                        product.type !== PRODUCT_TYPE.HOUSE_CONNECTION_HEAT &&
                        product.type !== PRODUCT_TYPE.HOUSE_CONNECTION_BROADBAND
                      }
                    >
                      <Tooltip
                        content={
                          <StyledSpan
                            dangerouslySetInnerHTML={{
                              __html: selectedPackageSpecifications
                            }}
                            font={fontText}
                            style={tooltipTextColor}
                          />
                        }
                        position={Position.BOTTOM}
                        tooltipClassName="ep-tooltip"
                        className="ep-ellipsis w-100"
                        disabled={!available}
                      >
                        <div className="ep-ellipsis w-100">
                          <i
                            className={`d-inline-block ep-icon-dashboard-half ${
                              frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY
                                ? 'ep__txt--md'
                                : 'ep__txt--sm'
                            } ep--mr-8`}
                            style={textColor}
                          />
                          <StyledSpan
                            font={fontText}
                            dangerouslySetInnerHTML={{
                              __html: selectedPackageSpecifications
                            }}
                            className={`ep__txt-regular ${
                              frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY
                                ? 'ep__txt--md'
                                : 'ep__txt--sm'
                            }`}
                            style={textColor}
                          />
                        </div>
                      </Tooltip>
                    </ShowIfUtil>

                    {/* PRICE */}
                    <div>
                      <Tooltip
                        content={
                          <StyledSpan font={fontText} style={tooltipTextColor}>
                            {productSelections.selectedPurchaseRental === PAYMENT_TYPE.RENTAL &&
                            !onRequest
                              ? `${price} ${RENTAL.PER_MONTH} + ${formatPrice(upfrontFee)} ${
                                  RENTAL.UPFRONT_FEE
                                }`
                              : price}
                          </StyledSpan>
                        }
                        position={Position.BOTTOM}
                        tooltipClassName="ep-tooltip"
                        className="ep-ellipsis w-100"
                        disabled={!available}
                      >
                        <div className="ep-ellipsis w-100">
                          <i
                            className={`d-inline-block ep-icon-money-coins ${
                              frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY
                                ? ' ep__txt--md'
                                : 'ep__txt--sm'
                            } ep--mr-8`}
                            style={textColor}
                          />
                          <StyledSpan
                            font={fontText}
                            className={`ep__txt-regular ${
                              frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY
                                ? 'ep__txt--md'
                                : 'ep__txt--sm'
                            }`}
                            style={textColor}
                          >
                            {price}
                          </StyledSpan>

                          <ShowIfUtil
                            condition={
                              price !== '-' &&
                              productSelections.selectedPurchaseRental === PAYMENT_TYPE.RENTAL &&
                              !onRequest
                            }
                          >
                            <StyledSpan
                              font={fontText}
                              className={`ep__txt-regular ${
                                frontendConfiguration.frontendType ===
                                FRONTEND_TYPE.CUSTOMER_JOURNEY
                                  ? 'ep__txt--md'
                                  : 'ep__txt--sm'
                              }`}
                              style={textColor}
                            >
                              {RENTAL.PER_MONTH}
                            </StyledSpan>
                          </ShowIfUtil>
                          <ShowIfUtil
                            condition={
                              price !== '-' &&
                              productSelections.selectedPurchaseRental === PAYMENT_TYPE.RENTAL &&
                              !onRequest &&
                              upfrontFee > 0
                            }
                          >
                            <StyledSpan
                              font={fontText}
                              className={`ep-opacity-50 ep__txt-regular ml-1 ${
                                frontendConfiguration.frontendType ===
                                FRONTEND_TYPE.CUSTOMER_JOURNEY
                                  ? 'ep__txt--md'
                                  : 'ep__txt--sm'
                              }`}
                              style={textColor}
                            >
                              {`+ ${formatPrice(upfrontFee)} ${RENTAL.UPFRONT_FEE}`}
                            </StyledSpan>
                          </ShowIfUtil>
                        </div>
                      </Tooltip>
                    </div>
                  </ShowIfUtil>

                  <ShowIfUtil condition={deactivatedProduct}>
                    <div className="ep-ellipsis w-100">
                      <i
                        className={`d-inline-block ep-icon-money-coins ${
                          frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY
                            ? ' ep__txt--md'
                            : 'ep__txt--sm'
                        } ep--mr-8`}
                        style={textColor}
                      />
                      <StyledSpan
                        font={fontText}
                        className={`ep__txt-regular ${
                          frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY
                            ? 'ep__txt--md'
                            : 'ep__txt--sm'
                        }`}
                        style={textColor}
                      >
                        {PRODUCT_DEACTIVATED_MESSAGE.DE}
                      </StyledSpan>
                    </div>
                  </ShowIfUtil>
                </ShowIfUtil>
              </div>
            </div>

            <ShowIfUtil condition={isShowPackageInfo}>
              <div
                className="ep--mr-16 justify-content-end"
                onClick={() => !deactivatedProduct && this.viewAllPackages(product)}
              >
                <i
                  className="ep-icon-circle-right-37 ep__txt--h1 ep-cursor-pointer"
                  style={textColor}
                />
              </div>
            </ShowIfUtil>
          </div>
        </Tooltip>
        <ShowIfUtil condition={frontendConfiguration.isShowPackageInfo}>
          {this.renderAddons()}
        </ShowIfUtil>
      </div>
    );
  }
}

ProductContainer.propTypes = {
  product: PropTypes.object,
  isSelectedProduct: PropTypes.bool,
  isAddonEnable: PropTypes.bool,
  viewAllPackages: PropTypes.func,
  selectProduct: PropTypes.func,
  removeAutomaticSelectedAddon: PropTypes.func.isRequired,
  removeSelectedAddons: PropTypes.func,
  updateSelectedAddon: PropTypes.func,
  fontText: PropTypes.object,
  fontTextBold: PropTypes.object,
  setScrollHeight: PropTypes.func,
  frontendConfiguration: PropTypes.object,
  productAddonsMapping: PropTypes.object,
  productSelections: PropTypes.object,
  customerJourneyAnswer: PropTypes.object
};

ProductContainer.defaultProps = {
  productAddonsMapping: {},
  product: {},
  isAddonEnable: false,
  isSelectedProduct: true,
  fontText: {},
  fontTextBold: {},
  frontendConfiguration: PropTypes.object,
  productSelections: PropTypes.object,
  customerJourneyAnswer: PropTypes.object,
  removeSelectedAddons: () => ({}),
  updateSelectedAddon: () => ({}),
  viewAllPackages: () => ({}),
  selectProduct: () => ({}),
  setScrollHeight: () => ({})
};

function mapStateToProps(state) {
  return {
    frontendConfiguration: state.frontendConfiguration,
    productSelections: productSelectionsSelector(state),
    productAddonsMapping: productAddonsMappingSelector(state),
    customerJourneyAnswer: state.customerJourneyAnswer
  };
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateProductSelections,
        removeSelectedAddons,
        updateSelectedAddon,
        removeAutomaticSelectedAddon
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductContainer);

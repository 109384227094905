import axios from 'axios';

export const CALL_API = 'CALL_API';

const callApi = (endpoint, method, params, isFile, isNodeServerAPI = false, isBinary = false) => {
  if (
    endpoint &&
    (endpoint.indexOf('https://') === -1 && endpoint.indexOf('http://') === -1 && !isNodeServerAPI)
  ) {
    endpoint = `https://${endpoint}`;
  }
  let config = {
    method: method,
    url: endpoint,
    data: params
  };
  if (method !== 'GET') {
    if (isFile) {
      config.headers = {
        ...config.headers,
        'Content-Type': 'multipart/form-data'
      };
      config.body = params;
    } else if (isBinary) {
      config.headers = {
        ...config.headers,
        'Content-Type': params.contentType
      };
      config.data = params.file;
    } else {
      config.body = JSON.stringify(params);
    }
  }
  return axios(config);
};

const customMiddleWare = store => next => action => {
  if (action) {
    const callAPI = action[CALL_API];
    if (typeof callAPI === 'undefined') {
      return next(action);
    }

    let { endpoint, params, file, isBinary } = callAPI;
    const { method, types, isNodeServerAPI } = callAPI;
    if (typeof endpoint === 'function') {
      endpoint = endpoint(store.getState());
    }

    if (typeof method !== 'string') {
      throw new Error('Specify a string method for calling.');
    }

    if (typeof endpoint !== 'string') {
      throw new Error('Specify a string endpoint URL.');
    }

    if (!Array.isArray(types) || types.length !== 3) {
      throw new Error('Expected an array of three action types.');
    }

    if (!types.every(type => typeof type === 'string')) {
      throw new Error('Expected action types to be strings.');
    }

    const actionWith = data => {
      const finalAction = { ...action, ...data };
      delete finalAction[CALL_API];
      return finalAction;
    };

    const [requestType, successType, failureType] = types;

    next(actionWith({ type: requestType }));

    return callApi(endpoint, method, params || {}, file, isNodeServerAPI, isBinary).then(
      response => {
        return next(
          actionWith({
            payload: response.data,
            type: successType,
            status: response.status
          })
        );
      },
      error => {
        if (error.response) {
          return next(
            actionWith({
              type: failureType,
              status: error.response.status || 600,
              statusText: error.response.statusText || 'Unknown error occur',
              payload: error.response.data || {},
              error: error.toString(),
              message: error.message,
              stack: error.stack,
              ref: error
            })
          );
        } else {
          return next(
            actionWith({
              type: failureType,
              error: error?.toString(),
              message: error?.message,
              stack: error?.stack,
              ref: error
            })
          );
        }
      }
    );
  }
};

export default customMiddleWare;

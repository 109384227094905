import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { formatQuestion, ShowIconByQuestionIdUtil } from '../../common/utils';
import {
  addMappingLabel,
  deleteMappingLabel,
  updateCustomerJourneyAnswer
} from '../../store/actions';
import {
  COLOR,
  FRONTEND_TYPE,
  HOUSE_CONNECTION_BUILDING_TYPE,
  PACKAGE_LABEL_TYPE,
  QUESTION_ID,
  BUILDING_TYPE
} from '../../common/constants';
import { DropDown, StyledSpan, InfoHelpDescription } from '../../components/epilot-forms/common';

class DropdownQuestionContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      options: []
    };
  }

  componentDidMount() {
    const {
      frontendConfiguration,
      question,
      updateCustomerJourneyAnswer,
      customerJourneyAnswer
    } = this.props;
    let newCustomerJourneyAnswer = {};
    let multipleChoiceItems;
    if (question.id === QUESTION_ID.HOUSE_CONNECTION_BUILDING_TYPE) {
      let options = [];
      _.forEach(question.objectModel.items, item => {
        options.push(item.label);
      });
      if (
        frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2 &&
        question.objectModel.includeBroadbandCableRepeater
      ) {
        options.push(question.objectModel.labelForBroadbandCableRepeater);
        this.setState({ options: options });
      } else {
        this.setState({ options: options });
      }
      if (customerJourneyAnswer.houseConnectionBuildingType === -1) {
        if (
          options[0] === BUILDING_TYPE.RESIDENTIAL ||
          options[0] === BUILDING_TYPE.RESIDENTIAL_GERMAN
        ) {
          newCustomerJourneyAnswer.houseConnectionBuildingType =
            HOUSE_CONNECTION_BUILDING_TYPE.SINGLE_FAMILY_HOUSE;
        } else if (
          options[0] === BUILDING_TYPE.INDUSTRIAL ||
          options[0] === BUILDING_TYPE.INDUSTRIAL_GERMAN
        ) {
          newCustomerJourneyAnswer.houseConnectionBuildingType =
            HOUSE_CONNECTION_BUILDING_TYPE.APARTMENT_BUILDING;
        } else {
          newCustomerJourneyAnswer.houseConnectionBuildingType =
            HOUSE_CONNECTION_BUILDING_TYPE.OTHER;
        }
        newCustomerJourneyAnswer.houseConnectionBuildingTypeName = options[0];

        multipleChoiceItems = _.find(
          [...customerJourneyAnswer.multipleChoiceItems],
          item => item.id === question.id
        );
        newCustomerJourneyAnswer.multipleChoiceItems = !multipleChoiceItems
          ? [
              ...customerJourneyAnswer.multipleChoiceItems,
              {
                id: question.id,
                label: question.questionLabel,
                value: options[0]
              }
            ]
          : [...customerJourneyAnswer.multipleChoiceItems];
        updateCustomerJourneyAnswer(newCustomerJourneyAnswer);
      }
    }
  }

  onItemSelect = selected => {
    const {
      question,
      updateCustomerJourneyAnswer,
      addMappingLabel,
      deleteMappingLabel,
      customerJourneyAnswer
    } = this.props;
    let newCustomerJourneyAnswer = {};
    let cloneMultipleChoiceItems;
    let multipleChoiceIndex;
    if (question.id === QUESTION_ID.HOUSE_CONNECTION_BUILDING_TYPE) {
      if (
        question.objectModel.includeBroadbandCableRepeater &&
        question.objectModel.labelForBroadbandCableRepeater === selected
      ) {
        newCustomerJourneyAnswer.houseConnectionBuildingType =
          HOUSE_CONNECTION_BUILDING_TYPE.BROADBAND_CABLE_REPEATER;
        if (
          question.objectModel.packageLabel.id !== -1 &&
          question.objectModel.packageLabel.label
        ) {
          addMappingLabel({
            [PACKAGE_LABEL_TYPE.BROADBAND]: question.objectModel.packageLabel.label
          });
        }
      } else {
        _.forEach(question.objectModel.items, item => {
          if (
            item.label === selected &&
            (item.option === BUILDING_TYPE.RESIDENTIAL ||
              item.option === BUILDING_TYPE.RESIDENTIAL_GERMAN)
          ) {
            newCustomerJourneyAnswer.houseConnectionBuildingType =
              HOUSE_CONNECTION_BUILDING_TYPE.SINGLE_FAMILY_HOUSE;
          } else if (
            item.label === selected &&
            (item.option === BUILDING_TYPE.INDUSTRIAL ||
              item.option === BUILDING_TYPE.INDUSTRIAL_GERMAN)
          ) {
            newCustomerJourneyAnswer.houseConnectionBuildingType =
              HOUSE_CONNECTION_BUILDING_TYPE.APARTMENT_BUILDING;
          } else {
            newCustomerJourneyAnswer.houseConnectionBuildingType =
              HOUSE_CONNECTION_BUILDING_TYPE.OTHER;
          }
        });
        deleteMappingLabel(PACKAGE_LABEL_TYPE.BROADBAND);
      }
      newCustomerJourneyAnswer.houseConnectionBuildingTypeName = selected;
      cloneMultipleChoiceItems = [...customerJourneyAnswer.multipleChoiceItems];
      multipleChoiceIndex = _.findIndex(cloneMultipleChoiceItems, item => item.id === question.id);
      if (multipleChoiceIndex > -1) {
        cloneMultipleChoiceItems[multipleChoiceIndex].value = selected;
        newCustomerJourneyAnswer.multipleChoiceItems = cloneMultipleChoiceItems;
      }
    }

    updateCustomerJourneyAnswer(newCustomerJourneyAnswer);
  };

  render() {
    const {
      frontendConfiguration,
      customerJourneyAnswer,
      question,
      fontText,
      defaultTextColor
    } = this.props;
    const { options } = this.state;

    const textColor = {
      color: defaultTextColor
    };

    return (
      <div className="ep-customerjourneypage__question">
        <ShowIconByQuestionIdUtil
          questionId={question.id}
          colorIcon={frontendConfiguration.primaryColor}
        />
        <div className="text-center ep-min-height-70 ep--pt-8 ep--pb-16">
          <StyledSpan
            font={fontText}
            className="ep__txt-regular ep__txt--lg ep__txt-no-select"
            style={textColor}
          >
            {formatQuestion(question.questionTitle, customerJourneyAnswer.name)}
          </StyledSpan>
          <InfoHelpDescription
            showHelpDescription={question.showHelpDescription}
            tooltipInfoContent={<StyledSpan font={fontText}>{question.helpDescription}</StyledSpan>}
            classNameForInfoIcon="ep__txt--lg ep--ml-8"
            defaultTextColor={defaultTextColor}
          />
        </div>
        <div className="position-relative ep-max-width-300" style={{ margin: '0 auto' }}>
          <DropDown
            height="48px"
            options={options}
            onItemSelect={this.onItemSelect}
            selectedValue={customerJourneyAnswer.houseConnectionBuildingTypeName}
            borderColorFocus={frontendConfiguration.primaryColor}
            defaultTextColor={defaultTextColor}
            fontFamily={fontText}
            styleClassText="ep__txt--md ep__txt-regular"
          />
        </div>
      </div>
    );
  }
}

DropdownQuestionContainer.propTypes = {
  question: PropTypes.object,
  fontText: PropTypes.object,
  defaultTextColor: PropTypes.string,
  customerJourneyAnswer: PropTypes.object,
  frontendConfiguration: PropTypes.object,
  updateCustomerJourneyAnswer: PropTypes.func,
  addMappingLabel: PropTypes.func.isRequired,
  deleteMappingLabel: PropTypes.func.isRequired
};

DropdownQuestionContainer.defaultProps = {
  question: {},
  fontText: {},
  defaultTextColor: COLOR.DARK_GRAY,
  customerJourneyAnswer: {},
  frontendConfiguration: {},
  updateCustomerJourneyAnswer: () => ({})
};

function mapStateToProps(state) {
  return {
    frontendConfiguration: state.frontendConfiguration,
    customerJourneyAnswer: state.customerJourneyAnswer,
    customerJourneyMapping: state.customerJourneyMapping
  };
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateCustomerJourneyAnswer,
        addMappingLabel,
        deleteMappingLabel
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DropdownQuestionContainer);

import React, { memo, useEffect, useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import * as PropTypes from 'prop-types';
import { Collapse } from '@blueprintjs/core';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { COLOR, GERMAN_TEXT, SPECIAL_CHARACTER } from '../../common/constants';
import { AddOnPrice, AddOnV2, StyledSpan } from '../../components';
import {
  removeInvalidSelectedAddOnV2,
  updateAddOnSellingOptionV2,
  updateProductSelections,
  updateSelectedAddOnV2,
  removeAllSelectedAddOnV2,
  removeSelectedAddOnV2ByProductType
} from '../../store/actions';
import { addOnV2TotalPriceSelector } from '../../store/selectors';
import { isAllOnRequestAddOns, isPriceAddOnV2 } from '../../common/utils';

const AddOnContainer = ({
  removeInvalidSelectedAddOnV2,
  productAddOnConfig,
  customBorderRadius,
  fontText,
  currentPackage,
  isProductSelected,
  primaryColor,
  productType,
  addOnV2Selections,
  textColor,
  updateSelectedAddOnV2,
  updateAddOnSellingOptionV2,
  setScrollHeight,
  addOnV2TotalPriceMapKeyByProductType,
  isShowAllProductOnRequest,
  removeAllSelectedAddOnV2,
  isShowPackageInfo,
  removeSelectedAddOnV2ByProductType
}) => {
  const { selectedAddOnV2MapKeyByProductType } = addOnV2Selections;
  const selectedAddOnV2 = selectedAddOnV2MapKeyByProductType[productType] || [];
  const [addOns, setAddOns] = useState([]);
  const [selectedAddOnCount, setSelectedAddOnCount] = useState(selectedAddOnV2.length);
  const [isAddOnItemDisabled, setIsAddOnItemDisabled] = useState(false);
  const [isShowAddOn, setIsShowAddOn] = useState(null);
  const borderRadius = `0px 0px ${customBorderRadius} ${customBorderRadius}`;
  const addOnRef = useRef();

  const latestSelectedAddOnCount = useRef(selectedAddOnCount);

  useEffect(() => {
    latestSelectedAddOnCount.current = selectedAddOnCount;
  });

  // count selected add-ons
  useEffect(() => {
    if (selectedAddOnV2MapKeyByProductType[productType]) {
      setSelectedAddOnCount(selectedAddOnV2MapKeyByProductType[productType].length);
    } else {
      setSelectedAddOnCount(0);
    }
  }, [selectedAddOnV2MapKeyByProductType, productType]);

  // calculate product view height
  useEffect(() => {
    // Must compare with true or false value, do not use falsy check
    // This hack avoid product view height bug in first time render when invoke
    // setScrollHeight with negative value
    if (isShowAddOn === true) {
      setScrollHeight(addOnRef.current.clientHeight);
    } else if (isShowAddOn === false) {
      setScrollHeight(-addOnRef.current.clientHeight + 2);
      setIsShowAddOn(null);
    }
  }, [isShowAddOn, setScrollHeight]);

  // get available add-ons
  useEffect(() => {
    const {
      productAddOnsMapKeyByProductType,
      specificPackageAddOnsMapKeyByPackageId,
      otherPackageAddOnsMapKeyByExcludedPackageId
    } = productAddOnConfig;
    const availableAddons = [];

    if (productAddOnsMapKeyByProductType[productType]) {
      availableAddons.push(...productAddOnsMapKeyByProductType[productType]);
    }

    if (specificPackageAddOnsMapKeyByPackageId[currentPackage.packageId]) {
      availableAddons.push(...specificPackageAddOnsMapKeyByPackageId[currentPackage.packageId]);
    }

    const tmpAddOns = [];
    _.forIn(otherPackageAddOnsMapKeyByExcludedPackageId, (addOns, excludedPackageId) => {
      if (currentPackage.packageId && Number(excludedPackageId) !== currentPackage.packageId) {
        tmpAddOns.push(...addOns);
      }
    });
    availableAddons.push(..._.filter(tmpAddOns, tmpAddOn => tmpAddOn.productType === productType));

    setAddOns(_.uniqWith(availableAddons, _.isEqual));
  }, [productAddOnConfig, productType, currentPackage]);

  // remove selected add-ons when package change
  useEffect(() => {
    const {
      specificPackageAddOnsMapKeyByPackageId,
      productAddOnsMapKeyByProductType,
      otherPackageAddOnsMapKeyByExcludedPackageId
    } = productAddOnConfig;
    if (latestSelectedAddOnCount.current > 0) {
      const validAddOnIds = [];

      if (productAddOnsMapKeyByProductType[productType]) {
        validAddOnIds.push(...productAddOnsMapKeyByProductType[productType].map(e => e.id));
      }

      if (specificPackageAddOnsMapKeyByPackageId[currentPackage.packageId]) {
        validAddOnIds.push(
          ...specificPackageAddOnsMapKeyByPackageId[currentPackage.packageId].map(e => e.id)
        );
      }

      const tmpAddOns = [];
      _.forIn(otherPackageAddOnsMapKeyByExcludedPackageId, (addOns, excludedPackageId) => {
        if (currentPackage.packageId && Number(excludedPackageId) !== currentPackage.packageId) {
          tmpAddOns.push(...addOns);
        }
      });
      validAddOnIds.push(
        ..._.filter(tmpAddOns, tmpAddOn => tmpAddOn.productType === productType).map(e => e.id)
      );

      removeInvalidSelectedAddOnV2({
        productType,
        validAddOnIds
      });
    }
  }, [removeInvalidSelectedAddOnV2, productType, productAddOnConfig, currentPackage]);

  // disable add-on selection
  useEffect(() => {
    setIsAddOnItemDisabled(!isProductSelected);
  }, [isProductSelected, currentPackage, isShowPackageInfo]);

  const _selectAddOn = (addOnId, addOnSellingOption, addOnName) => {
    updateSelectedAddOnV2({
      productType: productType,
      addOnId: addOnId,
      addOnSellingOption,
      addOnName
    });
  };

  const _selectAddOnSellingOption = (addOnId, addOnSellingOption, addOnName) => {
    updateAddOnSellingOptionV2({
      productType: productType,
      addOnId: addOnId,
      addOnSellingOption,
      addOnName
    });
  };

  if (addOns?.length === 0) return null;

  return (
    <div
      className="row ep--mx-16 ep--py-8"
      style={{
        backgroundColor: COLOR.LIGHT_GRAY,
        borderRadius
      }}
    >
      <div className="col-1 d-flex align-items-center ep--pl-16 ep--pr-0">
        <i
          className={`ep-position-top-3 ep-cursor-pointer ep__txt--sm ep__txt-no-select ${
            isShowAddOn ? 'ep-icon-minimal-up' : 'ep-icon-minimal-down'
          }`}
          style={{ color: textColor }}
          onClick={() => setIsShowAddOn(!isShowAddOn)}
        />
      </div>

      <div className="col-7 d-flex align-items-center">
        <StyledSpan
          className="ep__txt--md ep__txt-regular ep__txt-no-select"
          font={fontText}
          style={{ color: textColor }}
        >
          {GERMAN_TEXT.ADD_ONS} ({selectedAddOnCount || 0})
        </StyledSpan>
      </div>

      {addOnV2TotalPriceMapKeyByProductType[productType] &&
      isPriceAddOnV2(addOnV2TotalPriceMapKeyByProductType[productType]) ? (
        <AddOnPrice
          fontText={fontText}
          textColor={textColor}
          oneTimePrice={addOnV2TotalPriceMapKeyByProductType[productType].oneTimePrice}
          monthlyPrice={addOnV2TotalPriceMapKeyByProductType[productType].monthlyPrice}
          yearlyPrice={addOnV2TotalPriceMapKeyByProductType[productType].yearlyPrice}
        />
      ) : (
        <div className="col-4 d-flex align-items-center justify-content-end">
          <StyledSpan
            font={fontText}
            className="ep__txt--md text-right ep__txt-no-select"
            style={{ color: textColor }}
          >
            {isAllOnRequestAddOns(addOns)
              ? GERMAN_TEXT.ON_REQUEST
              : `0,00 ${SPECIAL_CHARACTER.EURO}`}
          </StyledSpan>
        </div>
      )}

      <div className={`col-12 ${isShowAddOn ? 'ep--mt-8' : ''}`}>
        <Collapse isOpen={isShowAddOn} keepChildrenMounted transitionDuration={0}>
          <div ref={addOnRef}>
            <hr className="ep--mt-0 ep--mb-16" />
            <AddOnV2
              addOns={addOns}
              disabled={isAddOnItemDisabled}
              textColor={textColor}
              productType={productType}
              addOnV2Selections={addOnV2Selections}
              fontText={fontText}
              setScrollHeight={setScrollHeight}
              primaryColor={primaryColor}
              selectAddOn={_selectAddOn}
              selectAddOnSellingOption={_selectAddOnSellingOption}
            />
          </div>
        </Collapse>
      </div>
    </div>
  );
};

AddOnContainer.propTypes = {
  customBorderRadius: PropTypes.string,
  fontText: PropTypes.object,
  productAddOnConfig: PropTypes.object.isRequired,
  currentPackage: PropTypes.object.isRequired,
  isProductSelected: PropTypes.bool.isRequired,
  isShowPackageInfo: PropTypes.bool.isRequired,
  primaryColor: PropTypes.string,
  productType: PropTypes.string.isRequired,
  addOnV2Selections: PropTypes.object.isRequired,
  addOnV2TotalPriceMapKeyByProductType: PropTypes.object.isRequired,
  textColor: PropTypes.string,
  isShowAllProductOnRequest: PropTypes.bool.isRequired,
  updateSelectedAddOnV2: PropTypes.func.isRequired,
  removeAllSelectedAddOnV2: PropTypes.func.isRequired,
  removeInvalidSelectedAddOnV2: PropTypes.func.isRequired,
  removeSelectedAddOnV2ByProductType: PropTypes.func.isRequired,
  setScrollHeight: PropTypes.func.isRequired,
  updateAddOnSellingOptionV2: PropTypes.func.isRequired
};

AddOnContainer.defaultProps = {
  customBorderRadius: '0px',
  fontText: {},
  primaryColor: COLOR.DEFAULT_BLUE,
  textColor: COLOR.DARK_GRAY
};

const mapStateToProps = state => {
  return {
    addOnV2Selections: state.addOnV2Selections,
    productAddOnConfig: state.productAddOnConfig,
    addOnV2TotalPriceMapKeyByProductType: addOnV2TotalPriceSelector(state),
    isShowAllProductOnRequest: state.currentState.isShowAllProductOnRequest
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateProductSelections,
        updateSelectedAddOnV2,
        updateAddOnSellingOptionV2,
        removeInvalidSelectedAddOnV2,
        removeAllSelectedAddOnV2,
        removeSelectedAddOnV2ByProductType
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(AddOnContainer));

import React, { useEffect, memo } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { Radio } from '@blueprintjs/core';
import $ from 'jquery';
import { COLOR, SPECIAL_CHARACTER, GERMAN_TEXT } from '../../../common/constants';
import { StyledSpan } from '../../epilot-forms/common';
import AddOnPrice from './AddOnPrice';

const RadioBtn = styled.div`
  .pt-control {
    margin-bottom: 0px !important;
  }
`;

const AddOnSellingOptionItem = ({
  addOn,
  addOnSellingOption,
  fontText,
  isAddOnSelected,
  isSelected,
  primaryColor,
  radioBtnClassName,
  selectAddOnSellingOption,
  textColor
}) => {
  const {
    id,
    name,
    oneTimePrice,
    monthlyPrice,
    yearlyPrice,
    showPriceOnFrontend
  } = addOnSellingOption;

  useEffect(() => {
    if (`addon-${isSelected}-${id}` === `addon-true-${id}`) {
      $(`.addon-true-${id}`)
        .find('span')
        .css({ 'background-color': primaryColor });
    } else {
      $(`.addon-false-${id}`)
        .find('span')
        .css({ 'background-color': COLOR.WHITE });
      $(`.addon-null-${id}`)
        .find('span')
        .css({ 'background-color': COLOR.WHITE });
    }
  }, [id, isSelected, primaryColor]);

  return (
    <div className="row ep--mt-8">
      <div className="col-1 offset-1 d-flex align-items-center">
        <RadioBtn>
          <Radio
            checked={isSelected}
            disabled={!isAddOnSelected && !isSelected}
            className={radioBtnClassName}
            onChange={() => selectAddOnSellingOption(addOn.id, addOnSellingOption, addOn.name)}
          />
        </RadioBtn>
      </div>

      <div className="col-6 d-flex align-items-center">
        <StyledSpan
          font={fontText}
          className="ep__txt--md ep__txt-no-select ep-ellipsis"
          style={{ color: textColor }}
        >
          {name}
        </StyledSpan>
      </div>
      {showPriceOnFrontend ? (
        <div className="col-4 d-flex align-items-center justify-content-end">
          <StyledSpan
            font={fontText}
            className="ep__txt--md text-right ep__txt-no-select ep-ellipsis"
            style={{ color: textColor }}
          >
            {GERMAN_TEXT.ON_REQUEST}
          </StyledSpan>
        </div>
      ) : (
        <AddOnPrice
          fontText={fontText}
          textColor={textColor}
          oneTimePrice={oneTimePrice}
          monthlyPrice={monthlyPrice}
          yearlyPrice={yearlyPrice}
        />
      )}
    </div>
  );
};

AddOnSellingOptionItem.propTypes = {
  addOnSellingOption: PropTypes.object,
  addOn: PropTypes.object.isRequired,
  fontText: PropTypes.object,
  isAddOnSelected: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  primaryColor: PropTypes.string,
  radioBtnClassName: PropTypes.string,
  selectAddOnSellingOption: PropTypes.func.isRequired,
  textColor: PropTypes.string
};

AddOnSellingOptionItem.defaultProps = {
  addOnSellingOption: {},
  fontText: {},
  primaryColor: COLOR.DEFAULT_BLUE,
  radioBtnClassName: SPECIAL_CHARACTER.EMPTY,
  textColor: COLOR.DARK_GRAY
};

export default memo(AddOnSellingOptionItem);

import { UPDATE_PAYMENT_INFO } from '../actions';

let initState = {
  paymentType: '',
  paid: false,

  payPalConnected: false,
  orderId: '',
  authorizationId: '',
  buyerEmail: ''
};

export default (state = initState, action) => {
  switch (action.type) {
    case UPDATE_PAYMENT_INFO:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

import { createSelector } from 'reselect';
import _ from 'lodash';
import {
  LabelForBroadbandCableRepeater,
  PRODUCT_AVAILABLE_STATUS,
  PRODUCT_TYPE,
  SERVICE_REQUEST_STATUS
} from '../../common/constants';

const HOUSE_CONNECTION_HEAT = 'HOUSE_CONNECTION_HEAT';
const HOUSE_CONNECTION_HEATING = 'HOUSE_CONNECTION_HEATING';

const AVAILABILITY_PRODUCT_EXTERNAL_SERVICE_TYPE = [
  'HOUSE_CONNECTION_ELECTRICITY',
  'HOUSE_CONNECTION_GAS',
  'HOUSE_CONNECTION_WATER',
  'HOUSE_CONNECTION_HEAT',
  'HOUSE_CONNECTION_BROADBAND'
];

const getProducts = state => state.frontendConfiguration.products;
const getAvailableProductsExternalService = state =>
  state.externalProductAvailability.availableProducts;
const getExternalServiceRequestStatus = state =>
  state.externalProductAvailability.externalServiceRequestStatus;
const getHouseConnectionTrenchLength = state =>
  state.customerJourneyAnswer.houseConnectionTrenchLength;
const getHouseConnectionBuildingTypeName = state =>
  state.customerJourneyAnswer.houseConnectionBuildingTypeName;
const getThirdPartyServiceSetting = state => state.frontendConfiguration.thirdPartyServiceSetting;

export const availableProductsExternalServiceSelector = createSelector(
  [
    getProducts,
    getAvailableProductsExternalService,
    getExternalServiceRequestStatus,
    getHouseConnectionTrenchLength,
    getThirdPartyServiceSetting,
    getHouseConnectionBuildingTypeName
  ],
  (
    products,
    availableProductsExternalService,
    externalServiceRequestStatus,
    houseConnectionTrenchLength,
    thirdPartyServiceSetting,
    houseConnectionBuildingTypeName
  ) => {
    let tmpProducts = [];
    let availableProductsExternalServiceMap = {};
    let isValidAvailableProductsExternalService =
      availableProductsExternalService && !_.isEmpty(availableProductsExternalService);

    if (isValidAvailableProductsExternalService) {
      availableProductsExternalServiceMap = _.keyBy(availableProductsExternalService, 'product');
    }

    _.forEach(products, elem => {
      let tmpProduct = _.assign({}, elem);
      let productType = tmpProduct.type || null;

      // TODO: Only check five type of product for now, add more in future
      if (AVAILABILITY_PRODUCT_EXTERNAL_SERVICE_TYPE.indexOf(productType) === -1) {
        tmpProduct.availableStatus = PRODUCT_AVAILABLE_STATUS.ACTIVATED;
        tmpProduct.showOnRequest = false;
      } else {
        if (productType === HOUSE_CONNECTION_HEAT) {
          productType = HOUSE_CONNECTION_HEATING;
        }

        let availableProductExternalService = availableProductsExternalServiceMap[productType];

        if (externalServiceRequestStatus !== SERVICE_REQUEST_STATUS.NO_REQUEST) {
          if (
            externalServiceRequestStatus === SERVICE_REQUEST_STATUS.REQUEST_FAILURE ||
            !isValidAvailableProductsExternalService
          ) {
            // Request failure or incorrect response data
            tmpProduct.availableStatus = PRODUCT_AVAILABLE_STATUS.ACTIVATED;
            tmpProduct.showOnRequest = true;
          } else if (availableProductExternalService) {
            if (availableProductExternalService.availability === 1) {
              const isInstallationPositionFarAwayProviderNode = checkInstallationPositionFarAwayProviderNode(
                {
                  productType,
                  houseConnectionBuildingTypeName,
                  thirdPartyServiceSetting,
                  availableProductExternalService,
                  houseConnectionTrenchLength
                }
              );
              tmpProduct.availableStatus = PRODUCT_AVAILABLE_STATUS.ACTIVATED;
              tmpProduct.showOnRequest = isInstallationPositionFarAwayProviderNode;
              tmpProduct.isFarAwayProviderNode = isInstallationPositionFarAwayProviderNode;
              tmpProduct.distanceToNode =
                availableProductExternalService.productDetails.distanceToNode;
            } else if (availableProductExternalService.availability === 0) {
              tmpProduct.availableStatus = PRODUCT_AVAILABLE_STATUS.DEACTIVATED;
              tmpProduct.showOnRequest = false;
            } else {
              // Missing availability prop
              tmpProduct.availableStatus = PRODUCT_AVAILABLE_STATUS.ACTIVATED;
              tmpProduct.showOnRequest = true;
            }
          }
        } else {
          tmpProduct.availableStatus = PRODUCT_AVAILABLE_STATUS.ACTIVATED;
          tmpProduct.showOnRequest = false;
        }
      }

      tmpProducts.push(tmpProduct);
    });
    return tmpProducts;
  }
);
const checkInstallationPositionFarAwayProviderNode = ({
  productType,
  houseConnectionBuildingTypeName,
  thirdPartyServiceSetting,
  availableProductExternalService,
  houseConnectionTrenchLength
}) => {
  let showOnRequest = false;
  if (thirdPartyServiceSetting) {
    if (
      PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY === productType &&
      (houseConnectionBuildingTypeName === LabelForBroadbandCableRepeater.EN ||
        houseConnectionBuildingTypeName === LabelForBroadbandCableRepeater.DE) &&
      thirdPartyServiceSetting.activeMaxDistancePulbicBCR &&
      isProviderNodeFarAwayNearestBroadbandCableRepeaters({
        distanceToNode: availableProductExternalService.productDetails.distanceToNode,
        maxDistancePublicBCR: thirdPartyServiceSetting.maxDistancePublicBCR
      })
    ) {
      showOnRequest = true;
    }

    if (
      thirdPartyServiceSetting.activeMaxDistanceToGridConfiguraion &&
      isProviderNodeFarAwayFromInstallationAddress({
        distanceToNode: availableProductExternalService.productDetails.distanceToNode,
        distancePrivate: houseConnectionTrenchLength,
        maxDistancePublic: thirdPartyServiceSetting.maxDistanceToGrid
      })
    ) {
      showOnRequest = true;
    }
  }
  return showOnRequest;
};

export const isProviderNodeFarAwayFromInstallationAddress = ({
  distanceToNode,
  distancePrivate,
  maxDistancePublic
}) => {
  return distanceToNode > Number(distancePrivate) + maxDistancePublic;
};

export const isProviderNodeFarAwayNearestBroadbandCableRepeaters = ({
  distanceToNode,
  maxDistancePublicBCR
}) => {
  return distanceToNode > maxDistancePublicBCR;
};

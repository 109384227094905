export const CHECK_PRODUCT_AVAILABILITY = 'CHECK_PRODUCT_AVAILABILITY';
export const FRONTEND = 'frontend';

export const GEO_QUESTION_STATUS = {
  NO_GEO_QUESTION: 'no_geo_question',
  POSTAL_CODE: 'postal_code',
  ADDRESS_DETAILS: 'address_details',
  PIN_ADDRESS_ON_MAP: 'google_map_card'
};

export const BOOLEAN = {
  TRUE: 'true',
  FALSE: 'false'
};

export const PATTERN = {
  EMAIL_PATTERN: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, //eslint-disable-line
  PHONE_PATTERN: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/, //eslint-disable-line
  GERMAN_POSTCODE_PATTERN: /\b((?:0[1-46-9]\d{3})|(?:[1-357-9]\d{4})|(?:[4][0-24-9]\d{3})|(?:[6][013-9]\d{3}))\b/
};

export const SPECIAL_CHARACTER = {
  EMPTY: '',
  SPACE: ' ',
  DASH: '-',
  EURO: '€',
  CENT: 'ct',
  HASH: '#',
  PLUS: '+',
  MINUS: '-',
  EQUAL: '=',
  AND: '&',
  QUESTION_MARK: '?',
  LINE_BREAK: '<br/>',
  COMMA: ',',
  ASTERISK: '*',
  DOT: '.',
  SLASH: '/'
};

export const INPUT_TYPE = {
  TEXT: 'text',
  NUMBER: 'number'
};

export const INPUT_VALUE = {
  ZERO: 0
};

export const FRONTEND_TYPE = {
  CONTACT_FORM: 'CONTACT_FORM',
  WEBSHOP: 'WEBSHOP',
  WEBSHOP_V2: 'WEBSHOP_V2',
  CUSTOMER_JOURNEY: 'CUSTOMER_JOURNEY',
  CUSTOMER_JOURNEY_V2: 'CUSTOMER_JOURNEY_VERSION_2'
};

export const STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  INCOMPLETE: 'INCOMPLETE',
  DELETED: 'DELETED'
};

export const CUSTOMER_TYPE = {
  PRIVATE: 'Private',
  PRIVATE_CUSTOMER: 'Privatkunden',
  BUSINESS: 'Business',
  BUSINESS_CUSTOMER: 'Geschäftskunden'
};

export const PAYMENT_TYPE = {
  PURCHASE: 'purchase',
  RENTAL: 'rental',
  BUY: 'Kaufen',
  RENT: 'Mieten'
};

export const PAYMENT_OPTION = {
  PAYMENTBYINVOICE: 'PAYMENT_BY_INVOICE',
  PAYMENT_BY_INVOICE: 'Zahlung per Rechnung',
  SEPADIRECTDEBIT: 'SEPA_DIRECT_DEBIT',
  SEPA_DIRECT_DEBIT: 'Sepa Direct Debit',
  CREDITCARD: 'CREDIT_CARD',
  CREDIT_CARD: 'Credit Card',
  PAYMENTBYPAYPAL: 'PAYPAL',
  PAYPAL: 'PayPal'
};

export const ONLINE_PURCHASE_PAYMENT_TYPE = {
  PAYMENT_BY_INVOICE: 0,
  SEPA_DIRECT_DEBIT: 1,
  CREDIT_CARD: 2,
  PAYPAL: 3
};

export const SERVICE_REQUEST_STATUS = {
  NO_REQUEST: 'NO_REQUEST',
  REQUEST_SUCCESS: 'REQUEST_SUCCESS',
  REQUEST_FAILURE: 'REQUEST_FAILURE'
};

export const RENTAL = {
  MONTH: 'MONTH',
  MONTHS: 'months',
  YEAR: 'YEAR',
  PER_MONTH: '/Monat',
  PER_YEAR: '/Jahr',
  ON_REQUEST: 'Auf Anfrage',
  MONTH_FORMATTED: 'Monat',
  MONTHS_FORMATTED: 'Monate',
  YEAR_FORMATTED: 'Jahr',
  YEARS_FORMATTED: 'Jahre',
  UPFRONT_FEE: 'Vorauszahlung'
};

export const UNIT = {
  PERCENT: '%',
  METER: 'm',
  KILOMETER: 'km',
  KILOWALT: 'kW',
  WATER_FLOW: 'l/s',
  KWTH_HTML: 'KW<sub>th</sub>',
  SQUARE_METRE: 'm<sup>2</sup>',
  CUBIC_METRE: 'l/s',
  M_BAR: 'mbar'
};

export const ATTRIBUTE = {
  ID: 'id',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  ADDON_ID: 'addOnId',
  NAME: 'name',
  ERROR_MESSAGE: 'errorMessage',
  FRONT_END_RESPONSE_ID: 'frontEndResponseId',
  ORDER_NUMBER: 'orderNumber',
  DEFAULT_TEXT_COLOR: 'defaultTextColor',
  ELECTRICITY: 'Electricity',
  GAS: 'Gas',
  WATER: 'Water',
  HEATING: 'Heating',
  BROADBAND: 'Broadband',
  OPPORTUNITY_ID_PARAM: 'opportunityId',
  SYMBOL_ID_PARAM: 'symbolId',
  FILES_PARAM: 'files',
  FILES_UPLOAD_LABELS_PARAM: 'filesUploadLabels',
  FILES_UPLOAD_TAX_DOCUMENTS: 'filesUploadTaxDocuments',
  BILLING_ADDRESS: 'opportunityBillingAddress',
  DELIVERY_ADDRESS: 'opportunityDeliveryAddress',
  ADDITIONAL_ADDRESSES: 'opportunityAdditionalAddresses',
  VISIBILITY_TYPE: 'visibilityType',
  CUSTOMER_ID: 'customerid',
  SAVE_TAX_DOCUMENT_TO_OPP_KEY: 'saveTaxDocumentsToOpp'
};

export const VISIBILITY_TYPE = {
  HIDDEN: 0,
  EVERYONE: 1,
  RESTRICTED: 2,
  PRIVATE: 3,
  PUBLIC: 4
};

export const DOCUMENT_TYPE = {
  FILES: 0,
  TEMPLATES: 1,
  OPPORTUNITY_ATTACHMENT: 2,
  EMAIL_TEMPLATE: 3,
  DOCUMENTS: 4,
  MESSAGE: 5,
  PARTNER: 6,
  VENDOR: 7
};

export const LIST_DEFAULT_FONT = [
  'Montserrat',
  'Arial',
  'Arial Black',
  'Century Gothic',
  'Courier',
  'Courier New',
  'Geneva',
  'Georgia',
  'Lucida Grande',
  'Lucida Sans',
  'Lucida',
  'Palatino Linotype',
  'Palatino',
  'Tahoma',
  'Times New Roman',
  'Times',
  'Trebuchet MS',
  'Verdana',
  'Roboto'
];

export const COLOR = {
  TRANSPARENT: 'transparent',
  WHITE: '#FFF',
  BLACK: '#000',
  DARK_GRAY: '#222',
  GRAY: '#444',
  LIGHT_GRAY: '#F9F9F9',
  LIGHT_GRAY_1: '#B5B5B5',
  RED: 'red',
  ERROR_TEXT_COLOR: '#FF5754',
  DEFAULT_BLUE: '#4084E8'
};

export const POSITION_TOOLTIP = {
  TOP_LEFT: 'top-left',
  TOP: 'top',
  TOP_RIGHT: 'top-right',
  RIGHT_TOP: 'right-top',
  RIGHT: 'right',
  RIGHT_BOTTOM: 'right-bottom',
  BOTTOM_RIGHT: 'bottom-right',
  BOTTOM: 'bottom',
  BOTTOM_LEFT: 'bottom-left',
  LEFT_BOTTOM: 'left-bottom',
  LEFT: 'left',
  LEFT_TOP: 'left-top'
};

export const DETECT_BROWSER = {
  IE: 'IE'
};

export const DETECT_DEVICE = {
  MOBILE: 'mobile',
  TABLET: 'tablet'
};

export const TYPE_FONT_FILE = {
  TTF: 'ttf',
  EOT: 'eot',
  WOFF2: 'woff2',
  WOFF: 'woff'
};

export const MAXIMUM_FILE_UPLOAD = 10485760;

export const DEFAULT_ACCEPTED_FILE_TYPES =
  '.jpeg,.png,.jpg,.pdf,.doc,.xls,.docx,.xlsx,.ppt,.pptx,.odt,.ods,.odp';

export const WATER_HEATER_NAME = 'Water heater';

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { formatQuestion, ShowIconByQuestionIdUtil } from '../../common/utils';
import { COLOR, SERVICE_REQUEST_STATUS, SPECIAL_CHARACTER } from '../../common/constants';
import { InfoHelpDescription, StyledSpan } from '../../components/epilot-forms/common';
import { PostalCodeAutoComplete } from '.';
import {
  checkProductAvailableInternalService,
  resetProductAvailability,
  updateCustomerJourneyAnswer
} from '../../store/actions';

class PostalCodeQuestionContainer extends Component {
  componentDidMount() {
    const {
      frontendConfiguration,
      customerJourneyAnswer,
      checkProductAvailableInternalService
    } = this.props;
    if (customerJourneyAnswer.postalCodePrefilled) {
      const postalCodeModel = {
        token: frontendConfiguration.token,
        postalCode: customerJourneyAnswer.postalCode.replace(
          SPECIAL_CHARACTER.SPACE,
          SPECIAL_CHARACTER.DASH
        )
      };
      checkProductAvailableInternalService(postalCodeModel).then();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      frontendConfiguration,
      customerJourneyAnswer,
      resetProductAvailability,
      externalServiceRequestStatus,
      checkProductAvailableInternalService
    } = this.props;
    const { customerJourneyAnswer: prevCustomerJourneyAnswer } = prevProps;
    if (customerJourneyAnswer.postalCodePrefilled) {
      const postalCodeModel = {
        token: frontendConfiguration.token,
        postalCode: customerJourneyAnswer.postalCode.replace(
          SPECIAL_CHARACTER.SPACE,
          SPECIAL_CHARACTER.DASH
        )
      };
      checkProductAvailableInternalService(postalCodeModel).then();
    }
    if (
      !_.isEqual(customerJourneyAnswer.postalCode, prevCustomerJourneyAnswer.postalCode) &&
      externalServiceRequestStatus !== SERVICE_REQUEST_STATUS.NO_REQUEST
    ) {
      resetProductAvailability();
    }
  }

  onSelect = (selectedValue, isUserInputPostcode = false) => {
    const { updateCustomerJourneyAnswer, customerJourneyAnswer } = this.props;
    let newCustomerJourneyAnswer = {};
    newCustomerJourneyAnswer.isUserInputPostcode = isUserInputPostcode;
    if (customerJourneyAnswer.postalCode !== selectedValue) {
      newCustomerJourneyAnswer.postalCode = selectedValue;
      newCustomerJourneyAnswer.address = {};
    } else {
      newCustomerJourneyAnswer.houseConnectionBuildingTypeName = SPECIAL_CHARACTER.EMPTY;
    }

    updateCustomerJourneyAnswer(newCustomerJourneyAnswer);
  };

  render() {
    const {
      fontText,
      frontendConfiguration,
      question,
      customerJourneyAnswer,
      defaultTextColor
    } = this.props;
    const textColor = {
      color: defaultTextColor
    };
    return (
      <div className="ep-customerjourneypage__question">
        <ShowIconByQuestionIdUtil
          questionId={question.id}
          colorIcon={frontendConfiguration.primaryColor}
        />

        <div className="text-center ep-min-height-70 ep--pt-8 ep--pb-16">
          <StyledSpan
            font={fontText}
            className="ep__txt-regular ep__txt--lg ep__txt-no-select"
            style={textColor}
          >
            {formatQuestion(question.questionTitle, customerJourneyAnswer.name)}
          </StyledSpan>
          <InfoHelpDescription
            showHelpDescription={question.showHelpDescription}
            tooltipInfoContent={<StyledSpan font={fontText}>{question.helpDescription}</StyledSpan>}
            classNameForInfoIcon="ep__txt--lg ep--ml-8"
            defaultTextColor={defaultTextColor}
          />
        </div>

        <div className="ep-min-height-130">
          <PostalCodeAutoComplete
            onSelect={this.onSelect}
            fontText={fontText}
            borderColorFocus={frontendConfiguration.primaryColor}
            defaultTextColor={defaultTextColor}
          />
        </div>
      </div>
    );
  }
}

PostalCodeQuestionContainer.propTypes = {
  question: PropTypes.object,
  fontText: PropTypes.object,
  defaultTextColor: PropTypes.string,
  externalServiceRequestStatus: PropTypes.string.isRequired,
  frontendConfiguration: PropTypes.object,
  customerJourneyAnswer: PropTypes.object,
  updateCustomerJourneyAnswer: PropTypes.func,
  resetProductAvailability: PropTypes.func,
  checkProductAvailableInternalService: PropTypes.func
};

PostalCodeQuestionContainer.defaultProps = {
  question: {},
  fontText: {},
  defaultTextColor: COLOR.DARK_GRAY,
  frontendConfiguration: {},
  customerJourneyAnswer: {},
  updateCustomerJourneyAnswer: () => ({}),
  resetProductAvailability: () => ({}),
  checkProductAvailableInternalService: () => ({})
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        resetProductAvailability,
        updateCustomerJourneyAnswer,
        checkProductAvailableInternalService
      },
      dispatch
    )
  };
}

function mapStateToProps(state) {
  return {
    frontendConfiguration: state.frontendConfiguration,
    customerJourneyAnswer: state.customerJourneyAnswer,
    externalServiceRequestStatus: state.externalProductAvailability.externalServiceRequestStatus
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostalCodeQuestionContainer);

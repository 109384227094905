import { createSelector } from 'reselect';
import { checkCustomFont, loadFont } from '../../common/utils';

const getNormalCustomFonts = state => state.frontendConfiguration.normalCustomFonts;
const getFontFamily = state => state.frontendConfiguration.fontFamily;
const getBoldCustomFonts = state => state.frontendConfiguration.boldCustomFonts;

const getFontTextSelector = createSelector(
  [getNormalCustomFonts, getFontFamily, getBoldCustomFonts],
  (normalCustomFonts, fontFamily, boldCustomFonts) => {
    if (normalCustomFonts) {
      const customFont = checkCustomFont(normalCustomFonts);
      const fontText = loadFont(fontFamily, customFont.url, boldCustomFonts, false);
      let fontTextData = {
        font: fontText.font
      };
      if (fontText.fontUrl) {
        fontTextData = {
          ...fontTextData,
          fontUrl: fontText.fontUrl
        };
      }
      return fontTextData;
    } else {
      const fontText = loadFont(fontFamily, normalCustomFonts, boldCustomFonts, false);
      return {
        font: fontText.font
      };
    }
  }
);

const getFontTextBoldSelector = createSelector(
  [getFontFamily, getBoldCustomFonts],
  (fontFamily, boldCustomFonts) => {
    if (boldCustomFonts) {
      const customFont = checkCustomFont(boldCustomFonts);
      const fontTextBold = loadFont(fontFamily, null, customFont.url, true);
      let fontTextBoldData = {
        font: fontTextBold.font
      };
      if (fontTextBold.fontUrl) {
        fontTextBoldData = {
          ...fontTextBoldData,
          fontUrl: fontTextBold.fontUrl
        };
      }
      return fontTextBoldData;
    } else {
      const fontTextBold = loadFont(fontFamily, null, boldCustomFonts, true);
      return {
        font: fontTextBold.font
      };
    }
  }
);

export { getFontTextSelector, getFontTextBoldSelector };

import { RESET_CUSTOMER_JOURNEY_DATA, UPDATE_THANK_YOU_PAGE_DATA } from '../actions';

const initState = {
  orderNumber: '',
  userEmail: '',
  onlinePurchaseOnlyRequest: false
};

export default (state = initState, action) => {
  switch (action.type) {
    case UPDATE_THANK_YOU_PAGE_DATA:
      return {
        ...state,
        ...action.payload
      };
    case RESET_CUSTOMER_JOURNEY_DATA:
      return initState;
    default:
      return state;
  }
};

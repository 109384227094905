import React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { formatPrice, ShowIfUtil } from '../../common/utils';
import { StyledSpan } from '../../components';
import {
  productSelectionsSelector,
  selectedProductsSelector,
  totalPriceCalcV2Selector
} from '../../store/selectors';
import {
  COLOR,
  FRONTEND_TYPE,
  GERMAN_TEXT,
  ON_REQUEST,
  RENTAL,
  SPECIAL_CHARACTER
} from '../../common/constants';

const TemporaryTotalContainer = ({
  fontText,
  totalPriceV2,
  frontendConfiguration,
  selectedProducts,
  setScrollHeight
}) => {
  const style = {
    color:
      frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2
        ? frontendConfiguration.secondaryTextColor
        : COLOR.GRAY
  };
  const renderTotalPrice = () => {
    // only show plus character if there are more than one type of price (one time, monthly, yearly)
    const currentPriceTypes = Object.values(totalPriceV2).filter(
      price => price !== null && price !== 0
    );
    return (
      <div>
        {/*TOTAL PRICE PAY FOR ONE TIME*/}
        <div className="ep-ellipsis">
          <ShowIfUtil
            condition={selectedProducts.length <= 0 || totalPriceV2.oneTimePrice !== null}
          >
            <StyledSpan font={fontText} className="ep__txt--h1 ep__txt-regular" style={style}>
              {formatPrice(
                totalPriceV2.oneTimePrice !== null && totalPriceV2.oneTimePrice !== 0
                  ? totalPriceV2.oneTimePrice
                  : 0
              )}
            </StyledSpan>
          </ShowIfUtil>
        </div>

        {/*TOTAL PRICE PAY FOR EVERY MONTH*/}
        <ShowIfUtil
          condition={totalPriceV2.monthlyPrice !== null && totalPriceV2.monthlyPrice !== 0}
        >
          <div className="ep-ellipsis">
            <StyledSpan font={fontText} className="ep__txt--h1 ep__txt-regular" style={style}>
              <ShowIfUtil condition>
                <StyledSpan font={fontText} className="pr-1" style={style}>
                  {totalPriceV2.monthlyPrice > 0
                    ? currentPriceTypes.length > 1 &&
                      totalPriceV2.oneTimePrice !== null &&
                      totalPriceV2.oneTimePrice !== 0
                      ? SPECIAL_CHARACTER.PLUS
                      : ''
                    : SPECIAL_CHARACTER.MINUS}
                </StyledSpan>
              </ShowIfUtil>
              {formatPrice(Math.abs(totalPriceV2.monthlyPrice))}
              <StyledSpan font={fontText} className="ep__txt--md ep__txt-regular" style={style}>
                /{GERMAN_TEXT.MONTH}
              </StyledSpan>
            </StyledSpan>
          </div>
        </ShowIfUtil>

        {/*TOTAL PRICE PAY FOR EVERY YEAR*/}
        <ShowIfUtil condition={totalPriceV2.yearlyPrice !== null && totalPriceV2.yearlyPrice !== 0}>
          <div className="ep-ellipsis">
            <StyledSpan font={fontText} className="ep__txt--h1 ep__txt-regular" style={style}>
              <ShowIfUtil condition>
                <StyledSpan font={fontText} className="pr-1" style={style}>
                  {totalPriceV2.yearlyPrice > 0
                    ? currentPriceTypes.length > 1
                      ? SPECIAL_CHARACTER.PLUS
                      : ''
                    : SPECIAL_CHARACTER.MINUS}
                </StyledSpan>
              </ShowIfUtil>
              {formatPrice(Math.abs(totalPriceV2.yearlyPrice))}
              <StyledSpan font={fontText} className="ep__txt--md ep__txt-regular" style={style}>
                /{GERMAN_TEXT.YEAR}
              </StyledSpan>
            </StyledSpan>
          </div>
        </ShowIfUtil>
        <div>
          <StyledSpan
            font={fontText}
            className="ep__txt--sm ep__txt-bold ep-opacity-50"
            style={style}
          >
            {GERMAN_TEXT.TOTAL_AMOUNT_INCL_TAX}
          </StyledSpan>
        </div>
      </div>
    );
  };

  return (
    <div
      className="text-right w-100"
      ref={ref => {
        if (ref) {
          setScrollHeight();
        }
      }}
    >
      <div className="ep-ellipsis ep--py-16 ep--px-16">
        <div className="d-inline">
          {totalPriceV2.oneTimePrice !== ON_REQUEST &&
          totalPriceV2.monthlyPrice !== ON_REQUEST &&
          totalPriceV2.yearlyPrice !== ON_REQUEST ? (
            renderTotalPrice()
          ) : (
            <StyledSpan font={fontText} className="ep__txt--h1 ep__txt-bold" style={style}>
              {RENTAL.ON_REQUEST}
            </StyledSpan>
          )}
        </div>
      </div>
    </div>
  );
};

TemporaryTotalContainer.propTypes = {
  fontText: PropTypes.object,
  totalPriceV2: PropTypes.object,
  frontendConfiguration: PropTypes.object,
  selectedProducts: PropTypes.array.isRequired,
  setScrollHeight: PropTypes.func.isRequired
};

TemporaryTotalContainer.defaultProps = {
  fontText: {},
  totalPriceV2: {},
  frontendConfiguration: {}
};

function mapStateToProps(state) {
  return {
    frontendConfiguration: state.frontendConfiguration,
    productSelections: productSelectionsSelector(state),
    selectedProducts: selectedProductsSelector(state),
    totalPriceV2: totalPriceCalcV2Selector(state)
  };
}

export default connect(mapStateToProps)(TemporaryTotalContainer);

import React, { PureComponent } from 'react';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  CustomCollapse,
  formatAddress,
  ShowIfUtil,
  getListCountry,
  getCountryGermanNameFromCode,
  getListSalutation
} from '../../common/utils';
import {
  ADDRESS_DEFAULT_LABEL,
  COLOR,
  COUNTRY,
  MESSAGE_CONSTANTS,
  SPECIAL_CHARACTER,
  Z_INDEX
} from '../../common/constants';
import {
  AutoComplete,
  CheckBox,
  DateInput,
  DropDown,
  Input,
  StyledLabel,
  StyledP,
  StyledSpan
} from '../../components/epilot-forms/common';
import {
  isAllProductsDeactivatedSelector,
  isAllProductsOnRequestSelector
} from '../../store/selectors';

class InstallationDetailFieldContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showCheckbox: true,
      streetReadOnly: false,
      numberReadOnly: false,
      postalCodeReadOnly: false,
      districtReadOnly: false,
      blockReadOnly: false,
      lotReadOnly: false,
      cityReadOnly: false,
      countryReadOnly: false
    };
  }

  componentDidMount() {
    this.setDeliveryAddressReadOnly();
    this.prefillDeliveryAddress();
  }

  setDeliveryAddressReadOnly = () => {
    const { customerJourneyAnswer, allProductDeactivated, allProductOnRequest } = this.props;
    const { postalCode, address } = customerJourneyAnswer;
    if (
      customerJourneyAnswer.deliveryAddressReadOnly &&
      !allProductDeactivated &&
      !allProductOnRequest
    ) {
      if (postalCode) {
        this.setState({ postalCodeReadOnly: true, cityReadOnly: true });
      }
      if (address.street) {
        this.setState({ streetReadOnly: true });
      }
      if (address.number) {
        this.setState({ numberReadOnly: true });
      }
      if (address.country) {
        this.setState({ countryReadOnly: true });
      }
      if (address.district) {
        this.setState({ districtReadOnly: true });
      }
      if (address.block) {
        this.setState({ blockReadOnly: true });
      }
      if (address.lot) {
        this.setState({ lotReadOnly: true });
      }
    }
  };

  prefillDeliveryAddress = () => {
    const { customerJourneyAnswer, updateData, installationDetails } = this.props;
    const { postalCode, address } = customerJourneyAnswer;
    let sameAsBillingAddress = true;
    let postal = SPECIAL_CHARACTER.EMPTY;
    let city = SPECIAL_CHARACTER.EMPTY;
    let country = SPECIAL_CHARACTER.EMPTY;
    if (postalCode || address.number || address.street || address.country) {
      sameAsBillingAddress = false;
      this.setState({ showCheckbox: false });
    }
    if (postalCode) {
      postal = postalCode.split(SPECIAL_CHARACTER.SPACE)[0];
      city = postalCode.replace(postal + SPECIAL_CHARACTER.SPACE, SPECIAL_CHARACTER.EMPTY);
    }
    let countries = [];
    for (const key in COUNTRY) {
      countries.push(COUNTRY[key]);
    }
    if (address.country) {
      _.forEach(countries, item => {
        if (address.country === item.englishName) {
          country = item.germanName;
        }
      });
    }
    country = country === SPECIAL_CHARACTER.EMPTY ? installationDetails.country : country;
    updateData({
      sameAsBillingAddress: sameAsBillingAddress,
      streetNumber: address.number || SPECIAL_CHARACTER.EMPTY,
      streetName: address.street || SPECIAL_CHARACTER.EMPTY,
      district: address.district || SPECIAL_CHARACTER.EMPTY,
      block: address.block || SPECIAL_CHARACTER.EMPTY,
      lot: address.lot || SPECIAL_CHARACTER.EMPTY,
      postalCode: postal,
      city: city,
      country: country
    });
  };

  onCollapse = () => {
    const { setContactFormHeight } = this.props;
    setTimeout(() => {
      setContactFormHeight();
    }, 100);
  };

  renderCompletedContent = () => {
    const {
      installationDetailsFields,
      fontText,
      defaultTextColor,
      installationDetails
    } = this.props;
    const {
      installationAddress,
      checkboxLabel,
      activePreferredInstallationDate,
      preferredInstallationDate
    } = installationDetailsFields;
    return (
      <div className="ep--border-radius-8 ep--p-32">
        <div className="row">
          <div className="col-12">
            <StyledLabel
              className="ep__txt--sm font-weight-normal ep__txt-no-select ep--mb-0"
              font={fontText}
              style={{ color: defaultTextColor }}
            >
              {installationAddress}
            </StyledLabel>
            <StyledP
              className="ep__txt--md ep__txt-bold ep__txt-no-select ep--mb-0"
              font={fontText}
              style={{ color: defaultTextColor }}
            >
              {installationDetails.sameAsBillingAddress
                ? checkboxLabel
                : formatAddress(
                    installationDetails.streetName +
                      SPECIAL_CHARACTER.SPACE +
                      installationDetails.streetNumber,
                    installationDetails.postalCode,
                    installationDetails.city,
                    installationDetails.country
                  )}
            </StyledP>
          </div>
        </div>
        <ShowIfUtil condition={!!activePreferredInstallationDate}>
          <div className="row ep--mt-16">
            <div className="col-12">
              <StyledLabel
                className="ep__txt--sm font-weight-normal ep__txt-no-select ep--mb-0"
                font={fontText}
                style={{ color: defaultTextColor }}
              >
                {preferredInstallationDate}
              </StyledLabel>
              <StyledP
                className="ep__txt--md ep__txt-bold ep__txt-no-select ep--mb-0"
                font={fontText}
                style={{ color: defaultTextColor }}
              >
                {!installationDetails.preferredDate ||
                installationDetails.preferredDate === 'Invalid date'
                  ? 'Kein Datum ausgewählt'
                  : moment(installationDetails.preferredDate).format('DD MMM YYYY')}
              </StyledP>
              <ShowIfUtil
                condition={
                  !(
                    !installationDetails.preferredDate ||
                    installationDetails.preferredDate === 'Invalid date'
                  )
                }
              >
                <div className="ep-text-red ep__txt--sm ep--mt-8">
                  <StyledSpan font={fontText}>{MESSAGE_CONSTANTS.REQUIRED}</StyledSpan>
                </div>
              </ShowIfUtil>
            </div>
          </div>
        </ShowIfUtil>
      </div>
    );
  };

  render() {
    const {
      installationDetails,
      installationDetailsFields,
      defaultTextColor,
      primaryTextColor,
      primaryColor,
      fontText,
      borderRadius,
      borderColorFocus,
      updateData,
      customerJourneyAnswer
    } = this.props;

    const { streetListed, deliveryAddressReadOnly } = customerJourneyAnswer;

    const {
      showCheckbox,
      streetReadOnly,
      numberReadOnly,
      postalCodeReadOnly,
      cityReadOnly,
      countryReadOnly,
      districtReadOnly,
      blockReadOnly,
      lotReadOnly
    } = this.state;

    const {
      sectionName,
      description,
      checkboxLabel,
      addressFieldLabel,
      requiredAddressFields,
      preferredInstallationDate,
      activePreferredInstallationDate,
      requiredPreferredInstallationDate,
      firstName,
      lastName,
      activeFullName,
      streetName,
      streetNo,
      postalCode,
      cityOrTown,
      country,
      companyName,
      activeCompanyName,
      salutation,
      email,
      phone,
      registerCourt,
      registerNumber,
      activeEmail,
      activePhone,
      activeRegistrationDetails,
      showCountryDropDown,
      defaultCountry
    } = installationDetailsFields;
    return (
      <CustomCollapse
        zIndex={Z_INDEX.Z_INDEX_LEVEL_4}
        title={sectionName}
        fontText={fontText}
        isCompleted={installationDetails.isValid !== null && installationDetails.isValid}
        onCollapse={this.onCollapse}
        completedContent={this.renderCompletedContent()}
        errorMessage={MESSAGE_CONSTANTS.CHECK_ALL_REQUIRED_FIELD}
        isError={installationDetails.isValid !== null && !installationDetails.isValid}
        defaultTextColor={defaultTextColor}
      >
        <div className="ep--border-radius-8 ep--p-32">
          <StyledP
            className="ep__txt--sm ep__txt-bold text-center"
            font={fontText}
            style={{ color: defaultTextColor }}
          >
            {description}
          </StyledP>
          <ShowIfUtil condition={showCheckbox}>
            <CheckBox
              className="ep--mt-32"
              onChange={() => {
                this.onCollapse();
                updateData({
                  sameAsBillingAddress: !installationDetails.sameAsBillingAddress
                });
              }}
              content={checkboxLabel}
              isChecked={installationDetails.sameAsBillingAddress}
              defaultTextColor={defaultTextColor}
              primaryTextColor={primaryTextColor}
              fontText={fontText}
              primaryCheckBoxIconColor={primaryColor}
            />
          </ShowIfUtil>
          <div className="row ep--mt-32">
            <ShowIfUtil condition={!installationDetails.sameAsBillingAddress}>
              <StyledLabel
                className="col-12 ep__txt--sm ep__txt-regular ep__txt-no-select"
                font={fontText}
                style={{ color: defaultTextColor }}
              >
                {addressFieldLabel}
                {requiredAddressFields && (
                  <StyledSpan
                    className="ep__txt--sm ep__txt-regular ep__txt-no-select"
                    font={fontText}
                    style={{ color: defaultTextColor }}
                  >
                    {' ' + SPECIAL_CHARACTER.ASTERISK}
                  </StyledSpan>
                )}
              </StyledLabel>
              <ShowIfUtil condition={salutation && salutation.active}>
                <div className="col-12 ep--mb-8">
                  <div className="row">
                    <div className="col-6">
                      <DropDown
                        borderRadius={borderRadius}
                        primaryTextColor={primaryTextColor}
                        defaultTextColor={defaultTextColor}
                        onItemSelect={value => updateData({ salutation: value })}
                        fontFamily={fontText}
                        options={getListSalutation()}
                        borderColorFocus={borderColorFocus}
                        styleClassText="ep__txt--md ep__txt-regular"
                      />
                      <ShowIfUtil
                        condition={!!installationDetails.errorMessage.isShowErrorMessageSalutation}
                      >
                        <div className="ep-text-red ep__txt--sm ep--mt-8">
                          <StyledSpan font={fontText}>{MESSAGE_CONSTANTS.REQUIRED}</StyledSpan>
                        </div>
                      </ShowIfUtil>
                    </div>
                  </div>
                </div>
              </ShowIfUtil>
              <ShowIfUtil condition={activeFullName}>
                <div className="col-6 ep--mb-8">
                  <Input
                    borderRadius={borderRadius}
                    primaryTextColor={primaryTextColor}
                    defaultTextColor={defaultTextColor}
                    require
                    onChange={value => updateData({ firstName: value })}
                    fontFamily={fontText}
                    borderColorFocus={borderColorFocus}
                    placeholder={firstName}
                    value={installationDetails.firstName}
                  />
                  <ShowIfUtil
                    condition={!!installationDetails.errorMessage.isShowErrorMessageFirstName}
                  >
                    <div className="ep-text-red ep__txt--sm ep--mt-8">
                      <StyledSpan font={fontText}>{MESSAGE_CONSTANTS.REQUIRED}</StyledSpan>
                    </div>
                  </ShowIfUtil>
                </div>
                <div className="col-6 ep--mb-8">
                  <Input
                    borderRadius={borderRadius}
                    primaryTextColor={primaryTextColor}
                    defaultTextColor={defaultTextColor}
                    require
                    onChange={value => updateData({ lastName: value })}
                    fontFamily={fontText}
                    borderColorFocus={borderColorFocus}
                    placeholder={lastName}
                    value={installationDetails.lastName}
                  />
                  <ShowIfUtil
                    condition={!!installationDetails.errorMessage.isShowErrorMessageLastName}
                  >
                    <div className="ep-text-red ep__txt--sm ep--mt-8">
                      <StyledSpan font={fontText}>{MESSAGE_CONSTANTS.REQUIRED}</StyledSpan>
                    </div>
                  </ShowIfUtil>
                </div>
              </ShowIfUtil>
              <ShowIfUtil condition={activeCompanyName}>
                <div className="col-12 ep--mb-8">
                  <Input
                    borderRadius={borderRadius}
                    primaryTextColor={primaryTextColor}
                    defaultTextColor={defaultTextColor}
                    require
                    onChange={value => updateData({ companyName: value })}
                    fontFamily={fontText}
                    borderColorFocus={borderColorFocus}
                    placeholder={companyName}
                    value={installationDetails.companyName}
                  />
                </div>
              </ShowIfUtil>
              <ShowIfUtil condition={activeEmail}>
                <div className="col-12 ep--mb-8">
                  <Input
                    borderRadius={borderRadius}
                    primaryTextColor={primaryTextColor}
                    defaultTextColor={defaultTextColor}
                    require
                    onChange={value => updateData({ email: value })}
                    fontFamily={fontText}
                    borderColorFocus={borderColorFocus}
                    placeholder={email}
                    value={installationDetails.email}
                  />
                  <ShowIfUtil
                    condition={!!installationDetails.errorMessage.isShowErrorMessageEmail}
                  >
                    <div className="ep-text-red ep__txt--sm ep--mt-8">
                      <StyledSpan font={fontText}>{MESSAGE_CONSTANTS.REQUIRED}</StyledSpan>
                    </div>
                  </ShowIfUtil>
                  <ShowIfUtil
                    condition={!!installationDetails.errorMessage.isShowMessageInvalidEmailPattern}
                  >
                    <div className="ep-text-red ep__txt--sm ep--mt-8">
                      <StyledSpan font={fontText}>{MESSAGE_CONSTANTS.EMAIL_INVALID}</StyledSpan>
                    </div>
                  </ShowIfUtil>
                </div>
              </ShowIfUtil>
              <ShowIfUtil condition={activePhone}>
                <div className="col-12 ep--mb-8">
                  <Input
                    borderRadius={borderRadius}
                    primaryTextColor={primaryTextColor}
                    defaultTextColor={defaultTextColor}
                    require
                    onChange={value => updateData({ phone: value })}
                    fontFamily={fontText}
                    borderColorFocus={borderColorFocus}
                    placeholder={phone}
                    value={installationDetails.phone}
                  />
                  <ShowIfUtil
                    condition={!!installationDetails.errorMessage.isShowErrorMessagePhone}
                  >
                    <div className="ep-text-red ep__txt--sm ep--mt-8">
                      <StyledSpan font={fontText}>{MESSAGE_CONSTANTS.REQUIRED}</StyledSpan>
                    </div>
                  </ShowIfUtil>
                </div>
              </ShowIfUtil>
              <ShowIfUtil condition={activeRegistrationDetails}>
                <div className="col-12 ep--mb-8">
                  <Input
                    borderRadius={borderRadius}
                    primaryTextColor={primaryTextColor}
                    defaultTextColor={defaultTextColor}
                    require
                    onChange={value => updateData({ registerCourt: value })}
                    fontFamily={fontText}
                    borderColorFocus={borderColorFocus}
                    placeholder={registerCourt}
                    value={installationDetails.registerCourt}
                  />
                  <ShowIfUtil
                    condition={!!installationDetails.errorMessage.isShowErrorMessageRegisterCourt}
                  >
                    <div className="ep-text-red ep__txt--sm ep--mt-8">
                      <StyledSpan font={fontText}>{MESSAGE_CONSTANTS.REQUIRED}</StyledSpan>
                    </div>
                  </ShowIfUtil>
                </div>
                <div className="col-12 ep--mb-8">
                  <Input
                    borderRadius={borderRadius}
                    primaryTextColor={primaryTextColor}
                    defaultTextColor={defaultTextColor}
                    require
                    onChange={value => updateData({ registerNumber: value })}
                    fontFamily={fontText}
                    borderColorFocus={borderColorFocus}
                    placeholder={registerNumber}
                    value={installationDetails.registerNumber}
                  />
                  <ShowIfUtil
                    condition={!!installationDetails.errorMessage.isShowErrorMessageRegisterNumber}
                  >
                    <div className="ep-text-red ep__txt--sm ep--mt-8">
                      <StyledSpan font={fontText}>{MESSAGE_CONSTANTS.REQUIRED}</StyledSpan>
                    </div>
                  </ShowIfUtil>
                </div>
              </ShowIfUtil>
              <div className="col-9 ep--mb-8">
                <Input
                  borderRadius={borderRadius}
                  primaryTextColor={primaryTextColor}
                  defaultTextColor={defaultTextColor}
                  require
                  onChange={value => updateData({ streetName: value })}
                  fontFamily={fontText}
                  borderColorFocus={borderColorFocus}
                  placeholder={streetName}
                  value={installationDetails.streetName}
                  disabled={streetReadOnly}
                />
              </div>
              <div className="col-3 ep--mb-8">
                <Input
                  borderRadius={borderRadius}
                  primaryTextColor={primaryTextColor}
                  defaultTextColor={defaultTextColor}
                  require
                  onChange={value => updateData({ streetNumber: value })}
                  fontFamily={fontText}
                  borderColorFocus={borderColorFocus}
                  placeholder={streetNo}
                  value={installationDetails.streetNumber}
                  disabled={numberReadOnly}
                />
              </div>
              <ShowIfUtil condition={!!(!streetListed && deliveryAddressReadOnly)}>
                <div className="col-12 ep--mb-8">
                  <Input
                    borderRadius={borderRadius}
                    primaryTextColor={primaryTextColor}
                    defaultTextColor={defaultTextColor}
                    require
                    onChange={value => updateData({ district: value })}
                    fontFamily={fontText}
                    borderColorFocus={borderColorFocus}
                    placeholder={ADDRESS_DEFAULT_LABEL.DISTRICT}
                    value={installationDetails.district}
                    disabled={districtReadOnly}
                  />
                </div>
                <div className="col-6 ep--mb-8">
                  <Input
                    borderRadius={borderRadius}
                    primaryTextColor={primaryTextColor}
                    defaultTextColor={defaultTextColor}
                    require
                    onChange={value => updateData({ block: value })}
                    fontFamily={fontText}
                    borderColorFocus={borderColorFocus}
                    placeholder={ADDRESS_DEFAULT_LABEL.BLOCK}
                    value={installationDetails.block}
                    disabled={blockReadOnly}
                  />
                </div>
                <div className="col-6 ep--mb-8">
                  <Input
                    borderRadius={borderRadius}
                    primaryTextColor={primaryTextColor}
                    defaultTextColor={defaultTextColor}
                    require
                    onChange={value => updateData({ lot: value })}
                    fontFamily={fontText}
                    borderColorFocus={borderColorFocus}
                    placeholder={ADDRESS_DEFAULT_LABEL.LOT}
                    value={installationDetails.lot}
                    disabled={lotReadOnly}
                  />
                </div>
              </ShowIfUtil>
              <div className="col-6 ep--mb-8">
                <Input
                  borderRadius={borderRadius}
                  primaryTextColor={primaryTextColor}
                  defaultTextColor={defaultTextColor}
                  require
                  onChange={value => updateData({ postalCode: value })}
                  fontFamily={fontText}
                  borderColorFocus={borderColorFocus}
                  placeholder={postalCode}
                  value={installationDetails.postalCode}
                  disabled={postalCodeReadOnly}
                />
              </div>
              <div className="col-6 ep--mb-8">
                <Input
                  borderRadius={borderRadius}
                  primaryTextColor={primaryTextColor}
                  defaultTextColor={defaultTextColor}
                  require
                  onChange={value => updateData({ city: value })}
                  fontFamily={fontText}
                  borderColorFocus={borderColorFocus}
                  placeholder={cityOrTown}
                  value={installationDetails.city}
                  disabled={cityReadOnly}
                />
              </div>
              <div className="col-12">
                <AutoComplete
                  disabled={!showCountryDropDown || countryReadOnly}
                  placeholder={country}
                  borderRadius={borderRadius}
                  primaryTextColor={primaryTextColor}
                  defaultTextColor={defaultTextColor}
                  require
                  onItemSelect={country => updateData({ country: country })}
                  fontFamily={fontText}
                  options={getListCountry()}
                  popoverClassName="autocomplete-popover-custom"
                  inputClassName="ep-autocomplete-input"
                  inputValue={installationDetails.country}
                  selectedValue={
                    installationDetails.country ||
                    getCountryGermanNameFromCode(defaultCountry) ||
                    getListCountry()[0]
                  }
                  borderColorFocus={borderColorFocus}
                  itemsLimited={false}
                />
              </div>
              <ShowIfUtil condition={!!installationDetails.errorMessage.address}>
                <div className="col-12 ep-text-red ep__txt--sm ep--mt-8">
                  {installationDetails.errorMessage.addressErrorMessage}
                </div>
              </ShowIfUtil>
            </ShowIfUtil>
            <ShowIfUtil condition={!!activePreferredInstallationDate}>
              <div className="col-12 ep--mt-32">
                <StyledLabel
                  className="ep__txt--sm ep__txt-regular ep__txt-no-select"
                  font={fontText}
                  style={{ color: defaultTextColor }}
                >
                  {preferredInstallationDate}
                  {requiredPreferredInstallationDate && (
                    <StyledSpan
                      className="ep__txt--sm ep__txt-regular ep__txt-no-select"
                      font={fontText}
                      style={{ color: defaultTextColor }}
                    >
                      {' *'}
                    </StyledSpan>
                  )}
                </StyledLabel>
                <DateInput
                  value={installationDetails.preferredDate}
                  onChange={date => updateData({ preferredDate: date })}
                  primaryTextColor={COLOR.DARK_GRAY}
                  defaultTextColor={defaultTextColor}
                  minDate={new Date()}
                  inputClassName="ep-datepicker-input"
                />
                <ShowIfUtil condition={!!installationDetails.errorMessage.preferredDate}>
                  <div className="ep-text-red ep__txt--sm ep--mt-8">
                    {MESSAGE_CONSTANTS.REQUIRED}
                  </div>
                </ShowIfUtil>
              </div>
            </ShowIfUtil>
          </div>
        </div>
      </CustomCollapse>
    );
  }
}

InstallationDetailFieldContainer.propTypes = {
  customerJourneyAnswer: PropTypes.object,
  allProductDeactivated: PropTypes.bool,
  allProductOnRequest: PropTypes.bool,
  installationDetailsFields: PropTypes.object,
  primaryTextColor: PropTypes.string,
  defaultTextColor: PropTypes.string,
  fontText: PropTypes.object,
  primaryColor: PropTypes.string,
  borderRadius: PropTypes.string,
  borderColorFocus: PropTypes.string,
  installationDetails: PropTypes.object,
  updateData: PropTypes.func,
  setContactFormHeight: PropTypes.func
};

InstallationDetailFieldContainer.defaultProps = {
  customerJourneyAnswer: {},
  allProductDeactivated: false,
  allProductOnRequest: false,
  installationDetailsFields: {},
  primaryTextColor: COLOR.BLACK,
  defaultTextColor: COLOR.DARK_GRAY,
  fontText: {},
  primaryColor: COLOR.DEFAULT_BLUE,
  borderRadius: '4px',
  borderColorFocus: COLOR.DEFAULT_BLUE,
  installationDetails: {},
  updateData: () => ({}),
  setContactFormHeight: () => ({})
};

const mapStateToProps = state => ({
  customerJourneyAnswer: state.customerJourneyAnswer,
  allProductDeactivated: isAllProductsDeactivatedSelector(state),
  allProductOnRequest: isAllProductsOnRequestSelector(state)
});

export default connect(mapStateToProps)(InstallationDetailFieldContainer);

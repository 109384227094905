import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  ALL_POSTAL_CODE,
  COLOR,
  GEO_QUESTION_STATUS,
  SPECIAL_CHARACTER
} from '../../common/constants';
import {
  checkProductAvailableExternalService,
  checkProductAvailableInternalService,
  INTERNAL_PRODUCT_AVAILABILITY_SUCCESS
} from '../../store/actions';
import { geoQuestionStatusSelector } from '../../store/selectors';
import PostcodeAutoComplete from '../../components/epilot-forms/common/PostcodeAutoComplete';

class PostalCodeAutoCompleteContainer extends PureComponent {
  onPostcodeCityPairSelect = (value, isUserInputPostcode = false) => {
    const {
      onSelect,
      geoQuestionStatus,
      checkProductAvailableExternalService,
      frontendConfiguration,
      checkProductAvailableInternalService
    } = this.props;

    if (isUserInputPostcode) {
      onSelect(value, true);
      return;
    }

    const postalCodeModel = {
      token: frontendConfiguration.token,
      postalCode: value.replace(SPECIAL_CHARACTER.SPACE, SPECIAL_CHARACTER.DASH)
    };

    if (frontendConfiguration.thirdPartyServiceSetting.active) {
      if (value) {
        if (geoQuestionStatus === GEO_QUESTION_STATUS.POSTAL_CODE) {
          checkProductAvailableExternalService();
        }
        onSelect(value);
      }
    } else {
      if (postalCodeModel.postalCode !== SPECIAL_CHARACTER.EMPTY) {
        checkProductAvailableInternalService(postalCodeModel).then(action => {
          switch (action.type) {
            case INTERNAL_PRODUCT_AVAILABILITY_SUCCESS:
              onSelect(value);
              break;
            default:
              break;
          }
        });
      }
    }
  };

  onPostcodeCityPairFilter = (query, items) => {
    const trimQueryString = query.trim();
    if (isNaN(trimQueryString) || trimQueryString.indexOf(' ') >= 1) {
      const queryToArray = trimQueryString.split(' ');
      if (
        _.isArray(queryToArray) &&
        queryToArray.length >= 2 &&
        !isNaN(parseInt(trimQueryString))
      ) {
        return items
          .filter(
            item =>
              item.toLowerCase().substring(0, trimQueryString.length) ===
              trimQueryString.toLowerCase()
          )
          .slice(0, 50);
      } else {
        return items
          .filter(item => {
            let getCity = item.split(' ');
            getCity = getCity.slice(1).join(' ');
            return (
              getCity.toLowerCase().substring(0, trimQueryString.length) ===
              trimQueryString.toLowerCase()
            );
          })
          .slice(0, 50);
      }
    } else {
      return items
        .filter(item => item.split(' ')[0].substring(0, trimQueryString.length) === trimQueryString)
        .slice(0, 50);
    }
  };

  postcodeCityPairOptions = () => {
    let options = [];
    const {
      frontendConfiguration: { uploadedPostcode }
    } = this.props;
    let allPostalCode;
    if (uploadedPostcode?.length > 0) {
      allPostalCode = uploadedPostcode;
    } else {
      allPostalCode = ALL_POSTAL_CODE;
    }
    _.forEach(allPostalCode, postalCode => {
      options.push(postalCode.postalCode + SPECIAL_CHARACTER.SPACE + postalCode.city);
    });

    return [...new Set(options)].sort((postcodeCityPairA, postcodeCityPairB) => {
      // sort postcode by city alphabet
      const cityA = postcodeCityPairA.split(' ')[1];
      const cityB = postcodeCityPairB.split(' ')[1];
      return cityA.localeCompare(cityB);
    });
  };

  render() {
    const { fontText, frontendConfiguration, customerJourneyAnswer, defaultTextColor } = this.props;

    return (
      <div className="d-flex justify-content-center align-items-center">
        <div style={{ width: '300px' }}>
          <PostcodeAutoComplete
            selectedValue={customerJourneyAnswer.postalCode}
            placeholder={
              customerJourneyAnswer.postalCode !== SPECIAL_CHARACTER.EMPTY
                ? customerJourneyAnswer.postalCode
                : 'PLZ eingeben'
            }
            borderRadius={frontendConfiguration.borderRadius}
            borderColorFocus={frontendConfiguration.primaryColor}
            defaultTextColor={defaultTextColor}
            primaryTextColor={frontendConfiguration.primaryTextColor}
            require
            onItemSelect={this.onPostcodeCityPairSelect}
            fontFamily={fontText}
            options={this.postcodeCityPairOptions()}
            inputStyle={{
              height: '56px',
              minWidth: '80px',
              maxWidth: '300px',
              color: defaultTextColor
            }}
            customFilter={this.onPostcodeCityPairFilter}
            initialContent="PLZ eingeben"
            itemsLimited
          />
        </div>
      </div>
    );
  }
}

PostalCodeAutoCompleteContainer.propTypes = {
  onSelect: PropTypes.func,
  checkProductAvailableInternalService: PropTypes.func,
  checkProductAvailableExternalService: PropTypes.func,
  fontText: PropTypes.object,
  frontendConfiguration: PropTypes.object,
  customerJourneyAnswer: PropTypes.object,
  geoQuestionStatus: PropTypes.string,
  defaultTextColor: PropTypes.string
};

PostalCodeAutoCompleteContainer.defaultProps = {
  onSelect: () => ({}),
  fontText: {},
  checkProductAvailableInternalService: () => ({}),
  checkProductAvailableExternalService: () => ({}),
  frontendConfiguration: {},
  customerJourneyAnswer: {},
  geoQuestionStatus: '',
  defaultTextColor: COLOR.DARK_GRAY
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        checkProductAvailableInternalService,
        checkProductAvailableExternalService
      },
      dispatch
    )
  };
}

function mapStateToProps(state) {
  return {
    frontendConfiguration: state.frontendConfiguration,
    customerJourneyAnswer: state.customerJourneyAnswer,
    geoQuestionStatus: geoQuestionStatusSelector(state)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostalCodeAutoCompleteContainer);

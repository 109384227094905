import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { COLOR, MESSAGE_CONSTANTS } from '../../common/constants';
import { CustomCollapse, DropZoneFileV2, ShowIfUtil, getAcceptFileType } from '../../common/utils';
import { StyledSpan } from '../../components';
import { useUploadFile } from '../../common/hooks/upload-file.hook';
import { removeUploadedRelatedDocument, updateUploadedRelatedDocuments } from '../../store/actions';

const UploadRelatedDocumentContainer = ({
  fontText,
  linkColor,
  setContactFormHeight,
  isValidRelatedDocumentData
}) => {
  const acceptFileType = useSelector(state => getAcceptFileType(state.frontendConfiguration));
  const {
    frontendConfiguration: {
      contactFormFileUploadOption: {
        sectionName = '',
        description = '',
        uploadInstruction = '',
        required = false
      },
      defaultTextColor = COLOR.DARK_GRAY
    },
    contactFormData: { relatedDocuments, isSubmittingContactForm },
    uploadFileStatus: { isUploading }
  } = useSelector(state => state);
  const [uploadedFileInfo, setUploadedFileInfo] = useState([]);
  const dispatch = useDispatch();

  const {
    onDrop,
    isMultipleUpload,
    setIsMultipleUpload,
    invalidFileNames,
    invalidSizeFileNames,
    invalidTypeFileNames,
    failedUploadedFileNames,
    successUploadedFileInfo,
    setSuccessUploadedFileInfo
  } = useUploadFile({
    uploadedFileInfo
  });

  const onCollapse = () => {
    setTimeout(() => {
      setContactFormHeight();
    }, 100);
  };

  const renderCompletedContent = () => {
    return (
      <ShowIfUtil condition={_.isArray(uploadedFileInfo) && uploadedFileInfo.length > 0}>
        <div className="w-100">
          <div className="ep--border-radius-8 ep--p-32">
            {_.map(uploadedFileInfo, (file, index) => (
              <div
                className="d-flex justify-content-between align-items-center ep--mt-8 ep--mb-8"
                key={index}
              >
                <div className="d-flex align-items-center">
                  <i className="ep-icon-attach-87" style={{ color: defaultTextColor }} />
                  <StyledSpan
                    className="ep__txt--md ep__txt-semibold ep__txt-no-select ep--ml-8"
                    style={{ color: linkColor }}
                    font={fontText}
                  >
                    {file.name}
                  </StyledSpan>
                </div>
              </div>
            ))}
          </div>
        </div>
      </ShowIfUtil>
    );
  };

  useEffect(() => {
    setUploadedFileInfo(relatedDocuments);
  }, [relatedDocuments, setUploadedFileInfo]);

  useEffect(() => {
    setIsMultipleUpload(true);
  }, [setIsMultipleUpload]);

  useEffect(() => {
    if (successUploadedFileInfo?.length > 0) {
      dispatch(updateUploadedRelatedDocuments(successUploadedFileInfo));
      setSuccessUploadedFileInfo([]);
    }
  }, [successUploadedFileInfo, dispatch, setSuccessUploadedFileInfo]);

  const _removeUploadedRelatedDocument = uploadedRelatedDocument => {
    dispatch(removeUploadedRelatedDocument(uploadedRelatedDocument));
  };

  return (
    <div className="w-100">
      <CustomCollapse
        title={sectionName}
        fontText={fontText}
        completedContent={renderCompletedContent()}
        onCollapse={onCollapse}
        errorMessage={MESSAGE_CONSTANTS.CHECK_ALL_REQUIRED_FIELD}
        isCompleted={isValidRelatedDocumentData}
        uploadDocument={isValidRelatedDocumentData === null}
        isError={isValidRelatedDocumentData !== null && isValidRelatedDocumentData === false}
        defaultTextColor={defaultTextColor}
      >
        <div className="ep--p-32">
          <div className="ep__txt--sm ep__txt-semibold text-center ep--mt-32">
            <StyledSpan font={fontText} style={{ color: defaultTextColor }}>
              {description}
            </StyledSpan>
            <ShowIfUtil condition={required}>
              <StyledSpan
                className="ep__txt--sm ep__txt-regular ep__txt-no-select"
                font={fontText}
                style={{ color: defaultTextColor }}
              >
                {' *'}
              </StyledSpan>
            </ShowIfUtil>
          </div>
          <div className="ep--mt-32 ep--mb-32">
            <DropZoneFileV2
              isUploading={isUploading || isSubmittingContactForm}
              fontText={fontText}
              linkColor={linkColor}
              defaultTextColor={defaultTextColor}
              uploadInstruction={uploadInstruction}
              uploadedFileInfo={uploadedFileInfo}
              isMultipleUpload={isMultipleUpload}
              acceptFileType={acceptFileType}
              removeUploadedFile={_removeUploadedRelatedDocument}
              required={required}
              onDrop={onDrop}
              invalidFileNames={invalidFileNames}
              invalidSizeFileNames={invalidSizeFileNames}
              invalidTypeFileNames={invalidTypeFileNames}
              failedUploadedFileNames={failedUploadedFileNames}
            />
          </div>
        </div>
      </CustomCollapse>
    </div>
  );
};

UploadRelatedDocumentContainer.propTypes = {
  fontText: PropTypes.object,
  linkColor: PropTypes.string,
  setContactFormHeight: PropTypes.func,
  isValidRelatedDocumentData: PropTypes.bool
};

UploadRelatedDocumentContainer.defaultProps = {
  fontText: { font: 'Montserrat', fontUrl: '' },
  linkColor: COLOR.DEFAULT_BLUE,
  setContactFormHeight: () => ({}),
  isValidRelatedDocumentData: null
};

export default UploadRelatedDocumentContainer;

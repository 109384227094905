import React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import {
  FRONTEND_TYPE,
  PAYMENT_TYPE,
  RENTAL,
  SPECIAL_CHARACTER,
  GERMAN_TEXT
} from '../../common/constants';
import {
  checkSelectedProductOnRequest,
  formatPrice,
  ShowIfUtil,
  getRentalRate
} from '../../common/utils';
import { StyledSpan, StyledSpanBold } from '../../components/epilot-forms/common';
import {
  productSelectionsSelector,
  selectedProductsSelector,
  totalPriceCalcSelector
} from '../../store/selectors';

const TemporaryTotalContainer = ({
  selectedProducts,
  totalPrice,
  frontendConfiguration,
  productSelections,
  fontText,
  fontTextBold,
  isContactForm
}) => {
  const style = {
    color:
      frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY
        ? frontendConfiguration.secondaryTextColor
        : frontendConfiguration.defaultTextColor
  };

  const selectedProductOnRequest = checkSelectedProductOnRequest({
    selectedProducts,
    productSelections
  });

  const showRentalPrice = selectedProductOnRequest
    ? RENTAL.ON_REQUEST
    : formatPrice(totalPrice.totalPriceGross);

  const rentalRate = getRentalRate(
    productSelections.selectedPurchaseRental,
    productSelections.selectedRentalInfo
  );

  const showRentalPriceUnit = selectedProductOnRequest ? SPECIAL_CHARACTER.EMPTY : RENTAL.PER_MONTH;
  return (
    <div className="text-right">
      <ShowIfUtil condition={productSelections.selectedPurchaseRental === PAYMENT_TYPE.PURCHASE}>
        <div style={{ height: 30 }}>
          <StyledSpanBold className="ep__txt--h1 ep__txt-regular" style={style} font={fontTextBold}>
            {totalPrice.totalPriceGross >= 0 && !selectedProductOnRequest
              ? formatPrice(totalPrice.totalPriceGross)
              : RENTAL.ON_REQUEST}
          </StyledSpanBold>
        </div>
        <ShowIfUtil condition={!selectedProductOnRequest}>
          <div>
            <StyledSpan
              className="ep__txt--sm ep-opacity-50 ep__txt-regular"
              style={style}
              font={fontText}
            >
              {GERMAN_TEXT.TOTAL_AMOUNT_INCL_TAX}
            </StyledSpan>
          </div>
        </ShowIfUtil>
      </ShowIfUtil>

      <ShowIfUtil condition={productSelections.selectedPurchaseRental === PAYMENT_TYPE.RENTAL}>
        <div style={{ height: 30 }}>
          <StyledSpanBold className="ep__txt--h1 ep__txt-regular" style={style} font={fontTextBold}>
            {showRentalPrice}
            <StyledSpan className="ep__txt--h1 ep__txt-regular" style={style} font={fontText}>
              {showRentalPriceUnit}
            </StyledSpan>
          </StyledSpanBold>
        </div>
        <ShowIfUtil condition={!selectedProductOnRequest}>
          <div>
            <StyledSpan
              className="ep__txt--sm ep-opacity-50 ep__txt-regular"
              style={style}
              font={fontText}
            >
              {GERMAN_TEXT.TOTAL_MONTHLY_AMOUNT}
            </StyledSpan>
          </div>
          <ShowIfUtil condition={isContactForm}>
            <div>
              <StyledSpanBold
                font={fontText}
                className="ep__txt--sm ep-text-dark-grey ep__txt-bold text-right col-11"
              >
                {GERMAN_TEXT.TOTAL_AMOUNT_INCL_TAX} {GERMAN_TEXT.FOR} {rentalRate}
              </StyledSpanBold>
            </div>
          </ShowIfUtil>
          <ShowIfUtil condition={totalPrice.totalUpfrontFeeGross > 0}>
            <StyledSpan className="ep__txt--sm ep__txt-regular" style={style} font={fontText}>
              {'+ ' + formatPrice(totalPrice.totalUpfrontFeeGross) + ' Vorauszahlung'}
            </StyledSpan>
          </ShowIfUtil>
        </ShowIfUtil>
      </ShowIfUtil>
    </div>
  );
};

TemporaryTotalContainer.propTypes = {
  fontText: PropTypes.object,
  fontTextBold: PropTypes.object,
  totalPrice: PropTypes.object,
  frontendConfiguration: PropTypes.object,
  productSelections: PropTypes.object,
  selectedProducts: PropTypes.array,
  isContactForm: PropTypes.bool
};

TemporaryTotalContainer.defaultProps = {
  fontText: {},
  fontTextBold: {},
  totalPrice: {},
  frontendConfiguration: {},
  productSelections: {},
  selectedProducts: [],
  isContactForm: false
};

function mapStateToProps(state) {
  return {
    frontendConfiguration: state.frontendConfiguration,
    productSelections: productSelectionsSelector(state),
    selectedProducts: selectedProductsSelector(state),
    totalPrice: totalPriceCalcSelector(state)
  };
}

export default connect(mapStateToProps)(TemporaryTotalContainer);

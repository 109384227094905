import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Position, Tooltip } from '@blueprintjs/core';
import classNames from 'classnames';
import {
  COLOR,
  INPUT_TYPE,
  QUESTION_ID,
  SPECIAL_CHARACTER,
  UNIT,
  WATER_HEATER_NAME
} from '../../common/constants';
import {
  calculateHCElectricityMaxPower,
  convertDataToDot,
  formatNumber,
  ShowIfUtil
} from '../../common/utils';
import {
  DropDown,
  Input,
  NumberSpinner,
  StyledButton,
  StyledLabelBold
} from '../../components/epilot-forms/common';
import { updateCustomerJourneyAnswer } from '../../store/actions';
import { currentStateSelector } from '../../store/selectors';

class AdditionalPowerItemContainer extends Component {
  onChangeInput = _.debounce(value => {
    const { itemIndex, updateCustomerJourneyAnswer, customerJourneyAnswer } = this.props;

    let cloneAdditionalPowerItems = _.cloneDeep(customerJourneyAnswer.additionalPowerItems);
    cloneAdditionalPowerItems[itemIndex].output = convertDataToDot(value);

    const totalAdditionalOutput = this.calTotalAdditionalOutput(cloneAdditionalPowerItems);

    let newCustomerJourneyAnswer = {
      additionalPowerItems: cloneAdditionalPowerItems,
      totalAdditionalOutput: totalAdditionalOutput,
      houseConnectionElectricityMaxPower: calculateHCElectricityMaxPower(
        totalAdditionalOutput,
        customerJourneyAnswer.eMobilityPower
      )
    };

    updateCustomerJourneyAnswer(newCustomerJourneyAnswer);
  }, 400);

  getTotalAdditionalOutput = additionalPowerItems => {
    const {
      currentState: { currQuestionMap },
      question
    } = this.props;
    let total = 0,
      totalItemPrice = 0;

    _.forEach(additionalPowerItems, item => {
      if (
        currQuestionMap[QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_ADDITIONAL] &&
        item.name === question.objectModel.items[WATER_HEATER_NAME]
      ) {
        _.forEach(
          currQuestionMap[QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_ADDITIONAL].objectModel
            .waterHeaterConcurrency,
          waterHeaterConcurrency => {
            if (
              (Number(waterHeaterConcurrency.max) >= Number(waterHeaterConcurrency.min) &&
                Number(item.quantity) <= Number(waterHeaterConcurrency.max) &&
                Number(item.quantity) >= Number(waterHeaterConcurrency.min)) ||
              (Number(waterHeaterConcurrency.max) >= Number(waterHeaterConcurrency.min) &&
                Number(item.quantity) > Number(waterHeaterConcurrency.max))
            ) {
              totalItemPrice =
                Number(item.output) * Number(waterHeaterConcurrency.value) * Number(item.quantity);
            }
          }
        );
        total += Number(totalItemPrice);
      } else {
        total += Number(item.quantity) * Number(item.output);
      }
    });

    return total;
  };

  renderDropDown = () => {
    const {
      frontendConfiguration,
      customerJourneyAnswer,
      options,
      itemIndex,
      fontText,
      defaultTextColor
    } = this.props;

    return (
      <DropDown
        height="48px"
        minWidthClass="ep-min-width-60"
        options={options}
        onItemSelect={this.onItemSelect}
        selectedValue={customerJourneyAnswer.additionalPowerItems[itemIndex].name}
        borderColorFocus={frontendConfiguration.primaryColor}
        fontFamily={fontText}
        defaultTextColor={defaultTextColor}
      />
    );
  };

  isOtherOption = () => {
    const { question, customerJourneyAnswer, itemIndex } = this.props;
    return (
      question.objectModel.items['Other'] &&
      question.objectModel.items['Other'] ===
        customerJourneyAnswer.additionalPowerItems[itemIndex].name
    );
  };

  onChange = value => {
    const { updateCustomerJourneyAnswer, customerJourneyAnswer, itemIndex } = this.props;
    let cloneAdditionalPowerItems = _.cloneDeep(customerJourneyAnswer.additionalPowerItems);
    cloneAdditionalPowerItems[itemIndex].otherOption =
      value !== undefined && value !== null ? value : SPECIAL_CHARACTER.EMPTY;

    let newCustomerJourneyAnswer = {
      additionalPowerItems: cloneAdditionalPowerItems
    };
    updateCustomerJourneyAnswer(newCustomerJourneyAnswer);
  };

  renderOtherInput = (props = this.props) => {
    const {
      frontendConfiguration,
      customerJourneyAnswer: { additionalPowerItems },
      itemIndex,
      fontText,
      defaultTextColor
    } = props;
    return (
      <div className="align-items-center text-left">
        <Input
          fontSize="14px"
          height="48px"
          minWidth="80px"
          fontWeight="600"
          textAlign="left"
          customClassStyle="ep-ellipsis"
          borderRadius={frontendConfiguration.borderRadius}
          label=""
          type={INPUT_TYPE.TEXT}
          placeholder="Bezeichnung"
          require
          onChange={this.onChange}
          value={additionalPowerItems[itemIndex].otherOption}
          defaultTextColor={defaultTextColor}
          fontFamily={fontText}
          borderColorFocus={frontendConfiguration.primaryColor}
        />
      </div>
    );
  };

  onItemSelect = itemValue => {
    const { question, itemIndex, customerJourneyAnswer, updateCustomerJourneyAnswer } = this.props;

    let cloneAdditionalPowerItems = _.cloneDeep(customerJourneyAnswer.additionalPowerItems);
    cloneAdditionalPowerItems[itemIndex].name = itemValue;
    if (
      question.objectModel.items['Other'] &&
      question.objectModel.items['Other'] === itemValue &&
      !cloneAdditionalPowerItems[itemIndex].otherOption
    ) {
      cloneAdditionalPowerItems[itemIndex].otherOption = SPECIAL_CHARACTER.EMPTY;
    } else {
      if (cloneAdditionalPowerItems[itemIndex].otherOption === SPECIAL_CHARACTER.EMPTY) {
        delete cloneAdditionalPowerItems[itemIndex].otherOption;
      }
    }

    const totalAdditionalOutput = this.calTotalAdditionalOutput(cloneAdditionalPowerItems);

    let newCustomerJourneyAnswer = {
      additionalPowerItems: cloneAdditionalPowerItems,
      totalAdditionalOutput: totalAdditionalOutput,
      houseConnectionElectricityMaxPower: calculateHCElectricityMaxPower(
        totalAdditionalOutput,
        customerJourneyAnswer.eMobilityPower
      )
    };
    updateCustomerJourneyAnswer(newCustomerJourneyAnswer);
  };

  renderNumberSpinner = () => {
    const { customerJourneyAnswer, itemIndex, fontText, defaultTextColor } = this.props;

    return (
      <NumberSpinner
        onChange={this.onChangeSpinner}
        value={customerJourneyAnswer.additionalPowerItems[itemIndex].quantity}
        fontText={fontText}
        fontSize="14px"
        defaultTextColor={defaultTextColor}
      />
    );
  };

  onChangeSpinner = e => {
    const { itemIndex, updateCustomerJourneyAnswer, customerJourneyAnswer } = this.props;

    let cloneAdditionalPowerItems = _.cloneDeep(customerJourneyAnswer.additionalPowerItems);
    cloneAdditionalPowerItems[itemIndex].quantity = e;

    const totalAdditionalOutput = this.calTotalAdditionalOutput(cloneAdditionalPowerItems);

    let newCustomerJourneyAnswer = {
      additionalPowerItems: cloneAdditionalPowerItems,
      totalAdditionalOutput: totalAdditionalOutput,
      houseConnectionElectricityMaxPower: calculateHCElectricityMaxPower(
        totalAdditionalOutput,
        customerJourneyAnswer.eMobilityPower
      )
    };
    updateCustomerJourneyAnswer(newCustomerJourneyAnswer);
  };

  renderInput = () => {
    const {
      frontendConfiguration,
      customerJourneyAnswer,
      itemIndex,
      fontText,
      question,
      defaultTextColor
    } = this.props;

    return (
      <Input
        questionId={question.id}
        fontSize="14px"
        height="48px"
        minWidth="80px"
        fontWeight="600"
        textAlign="left"
        paddingRight="3rem"
        customClassStyle="ep-ellipsis"
        borderRadius={frontendConfiguration.borderRadius}
        label=""
        type={INPUT_TYPE.TEXT}
        placeholder="tbd"
        require
        onChange={this.onChangeInput}
        value={customerJourneyAnswer.additionalPowerItems[itemIndex].output}
        defaultTextColor={defaultTextColor}
        fontFamily={fontText}
        borderColorFocus={frontendConfiguration.primaryColor}
        unit={UNIT.KILOWALT}
      />
    );
  };

  renderTotalNumber = () => {
    let totalItemPrice = 0;
    const {
      currentState: { currQuestionMap },
      customerJourneyAnswer,
      itemIndex,
      fontText,
      defaultTextColor
    } = this.props;

    const style = {
      fontText,
      color: defaultTextColor
    };

    if (currQuestionMap[QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_ADDITIONAL]) {
      customerJourneyAnswer.additionalPowerItems[itemIndex].name ===
      currQuestionMap[QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_ADDITIONAL].objectModel.items[
        WATER_HEATER_NAME
      ]
        ? _.forEach(
            currQuestionMap[QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_ADDITIONAL].objectModel
              .waterHeaterConcurrency,
            item => {
              if (
                (Number(item.max) >= Number(item.min) &&
                  Number(customerJourneyAnswer.additionalPowerItems[itemIndex].quantity) <=
                    Number(item.max) &&
                  Number(customerJourneyAnswer.additionalPowerItems[itemIndex].quantity) >=
                    Number(item.min)) ||
                (Number(item.max) >= Number(item.min) &&
                  Number(customerJourneyAnswer.additionalPowerItems[itemIndex].quantity) >
                    Number(item.max))
              ) {
                totalItemPrice =
                  Number(customerJourneyAnswer.additionalPowerItems[itemIndex].output) *
                  Number(item.value) *
                  Number(customerJourneyAnswer.additionalPowerItems[itemIndex].quantity);
              }
            }
          )
        : (totalItemPrice =
            Number(customerJourneyAnswer.additionalPowerItems[itemIndex].output) *
            Number(customerJourneyAnswer.additionalPowerItems[itemIndex].quantity));
    }

    return (
      <Tooltip
        content={
          <StyledLabelBold font={fontText} style={{ color: defaultTextColor }}>
            {formatNumber(totalItemPrice).toString()}
            {UNIT.KILOWALT}
          </StyledLabelBold>
        }
        position={Position.BOTTOM}
        className="mw-100"
        tooltipClassName="ep-tooltip"
      >
        <div
          className="ep-ellipsis ep__txt--md ep__txt-bold ep-height-48 ep--py-12 m-0 ep__txt-no-select"
          style={style}
        >
          {formatNumber(totalItemPrice).toString()}
          {SPECIAL_CHARACTER.SPACE + UNIT.KILOWALT}
        </div>
      </Tooltip>
    );
  };

  removeItem = () => {
    const { itemIndex, updateCustomerJourneyAnswer, customerJourneyAnswer } = this.props;
    if (itemIndex !== -1) {
      let cloneAdditionalPowerItems = _.cloneDeep(customerJourneyAnswer.additionalPowerItems);
      cloneAdditionalPowerItems.splice(itemIndex, 1);

      const totalAdditionalOutput = this.calTotalAdditionalOutput(cloneAdditionalPowerItems);

      let newCustomerJourneyAnswer = {
        additionalPowerItems: cloneAdditionalPowerItems,
        totalAdditionalOutput: totalAdditionalOutput,
        houseConnectionElectricityMaxPower: calculateHCElectricityMaxPower(
          totalAdditionalOutput,
          customerJourneyAnswer.eMobilityPower
        )
      };
      updateCustomerJourneyAnswer(newCustomerJourneyAnswer);
    }
  };

  calTotalAdditionalOutput = cloneAdditionalPowerItems => {
    const { customerJourneyAnswer } = this.props;
    return (
      Number(customerJourneyAnswer.housingElectricityMaxPower) +
      Number(customerJourneyAnswer.eMobilityPower) +
      this.getTotalAdditionalOutput(cloneAdditionalPowerItems)
    );
  };

  render() {
    const { frontendConfiguration, fontText, defaultTextColor } = this.props;
    const dropDownStyle = classNames({
      'col-5 item-dropdown ep--pr-16': !this.isOtherOption(),
      'col-2 item-dropdown-other-option ep--pr-0': this.isOtherOption(),
      'ep--py-8 pl-0': true
    });

    return (
      <div className="wrapper-item row m-0 mb-1">
        <div className={dropDownStyle}>{this.renderDropDown()}</div>
        <ShowIfUtil condition={this.isOtherOption()}>
          <div className="col-3 item-other-input ep--py-8 ep--px-16">{this.renderOtherInput()}</div>
        </ShowIfUtil>
        <div className="col-2 item-number-spinner ep--py-8 ep--pl-0">
          <div
            style={{
              border: '1px solid',
              borderRadius: frontendConfiguration.borderRadius,
              borderColor: COLOR.LIGHT_GRAY_1
            }}
          >
            {this.renderNumberSpinner()}
          </div>
        </div>
        <div className="col-2 item-input ep__txt-bold ep--py-8 pl-0">{this.renderInput()}</div>
        <div className="col-2 item-total-number text-right ep--py-8 px-0">
          {this.renderTotalNumber()}
        </div>
        <div className="col-1 item-remove ep--py-8 px-0">
          <StyledButton
            font={fontText}
            className="ep-icon-simple-remove ep-bg-transparent border-0 ep-cursor-pointer ep-height-48 ep-font-size-20"
            style={{ color: defaultTextColor }}
            onClick={this.removeItem}
          />
        </div>
      </div>
    );
  }
}

AdditionalPowerItemContainer.propTypes = {
  fontText: PropTypes.object,
  options: PropTypes.array,
  itemIndex: PropTypes.number,
  question: PropTypes.object,
  defaultTextColor: PropTypes.string,
  updateCustomerJourneyAnswer: PropTypes.func,
  frontendConfiguration: PropTypes.object,
  customerJourneyAnswer: PropTypes.object,
  currentState: PropTypes.object
};

AdditionalPowerItemContainer.defaultProps = {
  fontText: {},
  options: [],
  itemIndex: 0,
  question: { id: 0 },
  defaultTextColor: COLOR.DARK_GRAY,
  updateCustomerJourneyAnswer: () => ({}),
  customerJourneyAnswer: {},
  frontendConfiguration: {},
  currentState: {}
};

const mapStateToProps = state => {
  return {
    frontendConfiguration: state.frontendConfiguration,
    customerJourneyAnswer: state.customerJourneyAnswer,
    customerJourneyMapping: state.customerJourneyMapping,
    currentState: currentStateSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateCustomerJourneyAnswer
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdditionalPowerItemContainer);

import { CALL_API } from '../middlewares/customMiddleware';

export const UPDATE_PAYMENT_INFO = 'UPDATE_PAYMENT_INFO';

export const updatePaymentInfo = data => ({
  type: UPDATE_PAYMENT_INFO,
  payload: data
});

const linkProcessPayPalPaymentEndpoint =
  process.env.REACT_APP_SLS_PAYPAL_CAPTURE_URL ||
  'https://9vd2d5z25c.execute-api.eu-central-1.amazonaws.com/dev/v1/capture';
export const PROCESS_PAYPAL_PAYMENT_REQUEST = 'PROCESS_PAYPAL_PAYMENT_REQUEST';
export const PROCESS_PAYPAL_PAYMENT_SUCCESS = 'PROCESS_PAYPAL_PAYMENT_SUCCESS';
export const PROCESS_PAYPAL_PAYMENT_FAILURE = 'PROCESS_PAYPAL_PAYMENT_FAILURE';
export const processPayPalPayment = params => dispatch => {
  return dispatch({
    [CALL_API]: {
      types: [
        PROCESS_PAYPAL_PAYMENT_REQUEST,
        PROCESS_PAYPAL_PAYMENT_SUCCESS,
        PROCESS_PAYPAL_PAYMENT_FAILURE
      ],
      endpoint: linkProcessPayPalPaymentEndpoint,
      method: 'POST',
      params: params
    }
  });
};

import _ from 'lodash';
import {
  REMOVE_ALL_SELECTED_ADDON_V2,
  REMOVE_INVALID_SELECTED_ADDON_V2,
  REMOVE_SELECTED_ADDON_V2,
  REMOVE_SELECTED_ADDON_V2_BY_PRODUCT_TYPE,
  REMOVE_SELECTED_GLOBAL_ADDON_V2,
  RESET_CUSTOMER_JOURNEY_DATA,
  RESET_PRODUCT_SELECTIONS,
  UPDATE_ADDON_SELLING_OPTION_V2,
  UPDATE_PRODUCT_SELECTIONS,
  UPDATE_SELECTED_ADDON_V2
} from '../actions';
import { ADD_ON_V2_PRODUCT_TYPE, SELECTED_KEY_PRODUCT_TYPE_MAP } from '../../common/constants';

let initState = {
  selectedAddOnV2MapKeyByProductType: {}
};

export default (state = initState, action) => {
  if (_.isPlainObject(action.payload) && _.isEmpty(action.payload)) return state;
  let tmpSelectedAddOnV2MapKeyByProductType = {};
  let addOnId;
  let addOnSellingOption;
  let addOnName;
  let productType;
  let selectedAddOns;
  let addOnIndex;
  let productSelected;
  let unselectedProductType;
  let productSelectedKey;
  let validAddOnIds;
  let selectedByQuestionId;
  switch (action.type) {
    case UPDATE_SELECTED_ADDON_V2:
      ({
        productType,
        addOnSellingOption,
        addOnId,
        addOnName,
        selectedByQuestionId = null
      } = action.payload);
      tmpSelectedAddOnV2MapKeyByProductType = _.cloneDeep(state.selectedAddOnV2MapKeyByProductType);
      selectedAddOns = tmpSelectedAddOnV2MapKeyByProductType[productType];
      if (selectedAddOns?.length > 0) {
        addOnIndex = _.findIndex(selectedAddOns, e => e.addOnId === addOnId);
        if (addOnIndex !== -1) {
          // remote existed addon
          selectedAddOns.splice(addOnIndex, 1);
        } else {
          // add new addon
          selectedAddOns.push({
            addOnId,
            addOnSellingOption,
            addOnName,
            selectedByQuestionId
          });
        }
      } else {
        tmpSelectedAddOnV2MapKeyByProductType[productType] = [
          {
            addOnId,
            addOnSellingOption,
            addOnName,
            selectedByQuestionId
          }
        ];
      }
      return {
        ...state,
        selectedAddOnV2MapKeyByProductType: { ...tmpSelectedAddOnV2MapKeyByProductType }
      };
    case UPDATE_ADDON_SELLING_OPTION_V2:
      ({
        productType,
        addOnSellingOption,
        addOnId,
        addOnName,
        selectedByQuestionId = null
      } = action.payload);
      tmpSelectedAddOnV2MapKeyByProductType = _.cloneDeep(state.selectedAddOnV2MapKeyByProductType);
      selectedAddOns = tmpSelectedAddOnV2MapKeyByProductType[productType];
      addOnIndex = _.findIndex(selectedAddOns, e => e.addOnId === addOnId);
      selectedAddOns.splice(addOnIndex, 1, {
        addOnId,
        addOnSellingOption: addOnSellingOption,
        addOnName,
        selectedByQuestionId
      });
      return {
        ...state,
        selectedAddOnV2MapKeyByProductType: tmpSelectedAddOnV2MapKeyByProductType
      };
    case UPDATE_PRODUCT_SELECTIONS:
      tmpSelectedAddOnV2MapKeyByProductType = _.cloneDeep(state.selectedAddOnV2MapKeyByProductType);
      productSelected = action.payload;
      productSelectedKey = Object.keys(productSelected).find(x => x.includes('isSelectedProduct'));
      if (productSelectedKey && productSelected[productSelectedKey] === false) {
        // product unselect, remove corresponding selected addon
        unselectedProductType = SELECTED_KEY_PRODUCT_TYPE_MAP[productSelectedKey];
        if (tmpSelectedAddOnV2MapKeyByProductType[unselectedProductType]) {
          delete tmpSelectedAddOnV2MapKeyByProductType[unselectedProductType];
        }
        return {
          ...state,
          selectedAddOnV2MapKeyByProductType: tmpSelectedAddOnV2MapKeyByProductType
        };
      }
      return state;
    case REMOVE_INVALID_SELECTED_ADDON_V2:
      tmpSelectedAddOnV2MapKeyByProductType = _.cloneDeep(state.selectedAddOnV2MapKeyByProductType);
      ({ productType, validAddOnIds } = action.payload);
      selectedAddOns = tmpSelectedAddOnV2MapKeyByProductType[productType];
      if (selectedAddOns) {
        _.remove(selectedAddOns, element => _.indexOf(validAddOnIds, element.addOnId) === -1);
        return {
          ...state,
          selectedAddOnV2MapKeyByProductType: tmpSelectedAddOnV2MapKeyByProductType
        };
      }
      return state;
    case REMOVE_SELECTED_ADDON_V2:
      tmpSelectedAddOnV2MapKeyByProductType = _.cloneDeep(state.selectedAddOnV2MapKeyByProductType);
      ({ productType, addOnId } = action.payload);
      selectedAddOns = tmpSelectedAddOnV2MapKeyByProductType[productType];
      if (selectedAddOns) {
        _.remove(selectedAddOns, element => element.addOnId === addOnId);
        return {
          ...state,
          selectedAddOnV2MapKeyByProductType: tmpSelectedAddOnV2MapKeyByProductType
        };
      }
      return state;
    case REMOVE_SELECTED_GLOBAL_ADDON_V2:
      tmpSelectedAddOnV2MapKeyByProductType = _.cloneDeep(state.selectedAddOnV2MapKeyByProductType);
      delete tmpSelectedAddOnV2MapKeyByProductType[ADD_ON_V2_PRODUCT_TYPE.GLOBAL];
      return {
        ...state,
        selectedAddOnV2MapKeyByProductType: tmpSelectedAddOnV2MapKeyByProductType
      };
    case REMOVE_SELECTED_ADDON_V2_BY_PRODUCT_TYPE:
      tmpSelectedAddOnV2MapKeyByProductType = _.cloneDeep(state.selectedAddOnV2MapKeyByProductType);
      delete tmpSelectedAddOnV2MapKeyByProductType[action.payload];
      return {
        ...state,
        selectedAddOnV2MapKeyByProductType: tmpSelectedAddOnV2MapKeyByProductType
      };
    case REMOVE_ALL_SELECTED_ADDON_V2:
    case RESET_PRODUCT_SELECTIONS:
    case RESET_CUSTOMER_JOURNEY_DATA:
      return initState;
    default:
      return state;
  }
};

import React from 'react';
import * as PropTypes from 'prop-types';
import { Tooltip, Popover } from '@blueprintjs/core';
import { COLOR, POSITION_TOOLTIP } from '../../../common/constants';
import { ShowIfUtil } from '../../../common/utils';

const InfoHelpDescription = ({
  tooltipInfoContent,
  showHelpDescription,
  position,
  classNameForInfoIcon,
  defaultTextColor
}) => {
  return (
    <ShowIfUtil condition={showHelpDescription}>
      <Popover
        content={tooltipInfoContent}
        position={position}
        popoverClassName="ep-tooltip-dark ep-tooltip-width-500"
        className="align-middle"
      >
        <Tooltip>
          <i
            className={`d-inline-block ep-icon-alert-circle-i ep-cursor-pointer ${classNameForInfoIcon}`}
            style={{ outline: 'none', color: defaultTextColor }}
          />
        </Tooltip>
      </Popover>
    </ShowIfUtil>
  );
};

InfoHelpDescription.propTypes = {
  showHelpDescription: PropTypes.bool,
  position: PropTypes.string,
  classNameForInfoIcon: PropTypes.string,
  defaultTextColor: PropTypes.string,
  tooltipInfoContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.array
  ])
};

InfoHelpDescription.defaultProps = {
  showHelpDescription: false,
  tooltipInfoContent: '',
  classNameForInfoIcon: '',
  defaultTextColor: COLOR.DARK_GRAY,
  position: POSITION_TOOLTIP.BOTTOM
};

export default InfoHelpDescription;

import _ from 'lodash';
import { CUSTOMER_TYPE, SPECIAL_CHARACTER } from '../constants';

export const generateOpportunityTitle = (
  productName,
  customerType,
  companyName,
  firstName,
  lastName,
  reasonForContacting
) => {
  const customerName =
    customerType === CUSTOMER_TYPE.PRIVATE ? firstName + SPECIAL_CHARACTER.SPACE + lastName : companyName;
  const reason = !_.isUndefined(reasonForContacting) ? ` (${reasonForContacting}) ` : SPECIAL_CHARACTER.EMPTY;
  return productName + ' - ' + customerName + reason;
};

import { CALL_API } from '../middlewares/customMiddleware';
import { API_URL_VO, SLS_ORG_GEO_SEARCH_URL } from '../../common/settings/ApiUrlSettings';
import { ATTRIBUTE } from '../../common/constants';

export const RESET_CUSTOMER_JOURNEY_DATA = 'RESET_CUSTOMER_JOURNEY_DATA';

export const resetCustomerJourneyData = () => ({
  type: RESET_CUSTOMER_JOURNEY_DATA
});

export const UPLOAD_ATTACHMENT_REQUEST = 'UPLOAD_ATTACHMENT_REQUEST';
export const UPLOAD_ATTACHMENT_SUCCESS = 'UPLOAD_ATTACHMENT_SUCCESS';
export const UPLOAD_ATTACHMENT_FAILURE = 'UPLOAD_ATTACHMENT_FAILURE';
export const OPPORTUNITIES_UPLOAD_ATTACHMENT_URL = '/opportunities/upload';
export const CUSTOMER_UPLOAD_ATTACHMENT_URL = '/customers/upload';

export const uploadAttachment = params => dispatch => {
  const uploadAttachmentUrl = params.get(ATTRIBUTE.CUSTOMER_ID)
    ? `${API_URL_VO}${CUSTOMER_UPLOAD_ATTACHMENT_URL}`
    : `${API_URL_VO}${OPPORTUNITIES_UPLOAD_ATTACHMENT_URL}`;
  return dispatch({
    [CALL_API]: {
      types: [UPLOAD_ATTACHMENT_REQUEST, UPLOAD_ATTACHMENT_SUCCESS, UPLOAD_ATTACHMENT_FAILURE],
      endpoint: uploadAttachmentUrl,
      method: 'POST',
      params: params,
      file: true
    }
  });
};

export const GET_STREET_NAMES_REQUEST = 'GET_STREET_NAMES_REQUEST';
export const GET_STREET_NAMES_SUCCESS = 'GET_STREET_NAMES_SUCCESS';
export const GET_STREET_NAMES_FAILURE = 'GET_STREET_NAMES_FAILURE';
export const GET_STREET_NAMES_URL = '/organization/geodata/search';

export const getStreetNames = params => dispatch => {
  const getStreetNamesUrl = `${SLS_ORG_GEO_SEARCH_URL}${GET_STREET_NAMES_URL}`;
  return dispatch({
    [CALL_API]: {
      types: [GET_STREET_NAMES_REQUEST, GET_STREET_NAMES_SUCCESS, GET_STREET_NAMES_FAILURE],
      endpoint: getStreetNamesUrl,
      method: 'POST',
      params: params
    }
  });
};

import _ from 'lodash';
import { SPECIAL_CHARACTER } from '../constants';

const formatNumber = number => {
  return (
    number &&
    number.toLocaleString('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  );
};

const convertDataToComma = value => {
  let convertDataToComma = value && !_.isString(value) ? value.toString() : value;
  if (convertDataToComma && _.indexOf(convertDataToComma, SPECIAL_CHARACTER.DOT) > -1) {
    convertDataToComma = convertDataToComma
      .split(SPECIAL_CHARACTER.DOT)
      .join(SPECIAL_CHARACTER.COMMA);
  }
  return convertDataToComma;
};

const convertDataToDot = value => {
  let convertDataToDot = value && !_.isString(value) ? value.toString() : value;
  if (convertDataToDot && _.indexOf(convertDataToDot, SPECIAL_CHARACTER.COMMA) > -1) {
    convertDataToDot = convertDataToDot.split(SPECIAL_CHARACTER.COMMA).join(SPECIAL_CHARACTER.DOT);
  }
  return convertDataToDot;
};

export { formatNumber, convertDataToComma, convertDataToDot };

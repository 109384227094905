import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { Icon, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import styled from 'styled-components';
import { StyledButton, StyledLabel, StyledSpan } from './StyledElement';
import { COLOR, SPECIAL_CHARACTER } from '../../../common/constants';
import { ShowIfUtil } from '../../../common/utils';

const StyledMenuItem = styled(MenuItem)`
  @font-face {
    font-family: 'custom';
    src: url(${props => props.font.fontUrl});
  }
  font-family: ${props => props.font.font} !important;
  color: ${props => props.color};
  &.pt-active {
    color: ${props => props.color} !important;
  }
  & > div {
    font-family: ${props => props.font.font} !important;
  }
`;

class DropDown extends Component {
  constructor(props) {
    super(props);
    const { isObjectOptions, selectedItem, selectedValue } = this.props;
    this.state = {
      value: isObjectOptions ? selectedItem : selectedValue
    };
  }

  componentWillReceiveProps(nextProps) {
    const { selectedValue, isObjectOptions, selectedItem } = this.props;
    if (selectedValue !== nextProps.selectedValue) {
      this.setState({
        value: nextProps.selectedValue
      });
    }

    if (isObjectOptions) {
      if (JSON.stringify(selectedItem) !== JSON.stringify(nextProps.selectedItem)) {
        this.setState({
          value: nextProps.selectedItem
        });
      }
    }

    if (nextProps.confirm) {
      if (isObjectOptions) {
        this.setState({
          value: nextProps.selectedItem
        });
      } else {
        this.setState({
          value: nextProps.selectedValue
        });
      }
    }
  }

  onItemSelect = item => {
    const { onItemSelect } = this.props;
    this.setState({
      value: item
    });
    onItemSelect(item);
  };

  onBlur = e => {
    const { onBlur } = this.props;
    onBlur(e);
  };

  getDisplayText = () => {
    const { isObjectOptions, isWebshopV2, itemLabel, options } = this.props;
    const { value } = this.state;

    if (isWebshopV2) {
      return !_.isEmpty(value) ? itemLabel(value) : itemLabel(options[0]);
    }
    if (isObjectOptions) {
      return !_.isEmpty(value)
        ? itemLabel(value.duration, value.unit)
        : itemLabel(options[0].duration, options[0].unit);
    } else {
      return value ? value : options[0];
    }
  };

  renderDataItem = (item, { handleClick, modifiers }) => {
    const {
      isObjectOptions,
      isWebshopV2,
      itemLabel,
      itemKey,
      defaultTextColor,
      fontFamily
    } = this.props;

    return (
      <div style={{ color: defaultTextColor }} key={isObjectOptions ? item[itemKey] : item}>
        <StyledMenuItem
          font={fontFamily}
          active={modifiers.active}
          key={isObjectOptions ? item[itemKey] : item}
          onClick={handleClick}
          text={
            isWebshopV2
              ? itemLabel(item)
              : isObjectOptions
              ? itemLabel(item.duration, item.unit)
              : item
          }
          color={defaultTextColor}
        />
      </div>
    );
  };

  render() {
    const {
      classStyle,
      label,
      fontFamily,
      options,
      borderColorFocus,
      borderRadius,
      height,
      icon,
      require,
      styleClassText,
      usePortal,
      minWidthClass,
      defaultTextColor
    } = this.props;

    return (
      <div className={classStyle}>
        <ShowIfUtil condition={label !== SPECIAL_CHARACTER.EMPTY}>
          <StyledLabel
            className="ep__txt--sm ep__txt-regular ep__txt-no-select"
            font={fontFamily}
            style={{ color: defaultTextColor }}
          >
            {label}
            <ShowIfUtil condition={require}>
              <StyledSpan
                className="ep__txt--sm ep__txt-regular text-required ep__txt-no-select"
                font={fontFamily}
                style={{ color: defaultTextColor }}
              >
                {' *'}
              </StyledSpan>
            </ShowIfUtil>
          </StyledLabel>
        </ShowIfUtil>
        <div style={{ fontFamily: fontFamily.font }}>
          <Select
            className={`ep-dropdown ${minWidthClass}`}
            items={options}
            onItemSelect={this.onItemSelect}
            itemRenderer={this.renderDataItem}
            filterable={false}
            popoverProps={{
              popoverClassName: 'dropdown-popover-custom',
              usePortal: usePortal,
              color: defaultTextColor
            }}
          >
            <StyledButton
              font={fontFamily}
              borderColorFocus={borderColorFocus}
              style={{
                borderRadius: borderRadius,
                color: defaultTextColor,
                height: height
              }}
              className="pt-minimal dropdown-button ep__txt-no-select"
            >
              <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ color: defaultTextColor }}
              >
                <ShowIfUtil condition={icon !== SPECIAL_CHARACTER.EMPTY}>
                  <i className={`mr-1 ${icon}`} style={{ color: defaultTextColor }} />
                </ShowIfUtil>
                <StyledSpan
                  font={fontFamily}
                  className={`ep-ellipsis text-left ${styleClassText}`}
                  title={this.getDisplayText()}
                  style={{ color: defaultTextColor }}
                >
                  {this.getDisplayText()}
                </StyledSpan>
                <Icon icon="caret-down" />
              </div>
            </StyledButton>
          </Select>
        </div>
      </div>
    );
  }
}

DropDown.propTypes = {
  classStyle: PropTypes.string,
  options: PropTypes.array,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  fontFamily: PropTypes.object,
  borderRadius: PropTypes.string,
  selectedValue: PropTypes.string,
  isObjectOptions: PropTypes.bool,
  confirm: PropTypes.bool,
  selectedItem: PropTypes.object,
  itemKey: PropTypes.string,
  itemLabel: PropTypes.func,
  icon: PropTypes.string,
  borderColorFocus: PropTypes.string,
  onItemSelect: PropTypes.func,
  height: PropTypes.string,
  require: PropTypes.bool,
  styleClassText: PropTypes.string,
  usePortal: PropTypes.bool,
  minWidthClass: PropTypes.string,
  defaultTextColor: PropTypes.string,
  isWebshopV2: PropTypes.bool
};

DropDown.defaultProps = {
  height: '',
  borderColorFocus: '',
  confirm: false,
  defaultTextColor: COLOR.DARK_GRAY,
  classStyle: SPECIAL_CHARACTER.EMPTY,
  label: SPECIAL_CHARACTER.EMPTY,
  options: [],
  onBlur: () => ({}),
  selectedValue: SPECIAL_CHARACTER.EMPTY,
  fontFamily: {},
  borderRadius: '4px',
  isObjectOptions: false,
  selectedItem: {},
  itemKey: SPECIAL_CHARACTER.EMPTY,
  itemLabel: function() {},
  icon: SPECIAL_CHARACTER.EMPTY,
  onItemSelect: function() {},
  require: false,
  styleClassText: 'ep__txt--md ep__txt-semibold',
  usePortal: false,
  minWidthClass: 'ep-min-width-70',
  isWebshopV2: false
};

export default DropDown;

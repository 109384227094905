import React, { useEffect, useRef, useState, memo } from 'react';
import { Collapse } from '@blueprintjs/core';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { CheckBox, StyledSpan } from '../../epilot-forms/common';
import { COLOR, SPECIAL_CHARACTER, GERMAN_TEXT } from '../../../common/constants';
import { AddOnSellingOptionItem, AddOnPrice } from '.';

const AddOnItem = ({
  addOn,
  disabled,
  fontText,
  productType,
  isAddOnSelected,
  selectedAddOn,
  primaryColor,
  selectAddOn,
  selectAddOnSellingOption,
  textColor,
  setScrollHeight
}) => {
  const [isShowAddOnSellingOption, setIsShowAddOnSellingOption] = useState(null);

  const addonSellingOptionRef = useRef();

  useEffect(() => {
    // Must compare with true or false value, do not use falsy check
    // This hack avoid product view height bug in first time render when invoke
    // setScrollHeight with negative value
    if (isShowAddOnSellingOption === true) {
      setScrollHeight(addonSellingOptionRef.current.clientHeight);
    } else if (isShowAddOnSellingOption === false) {
      setScrollHeight(-addonSellingOptionRef.current.clientHeight);
      setIsShowAddOnSellingOption(null);
    }
  }, [isShowAddOnSellingOption, setScrollHeight]);

  return (
    <div className="row ep--mt-8">
      <CheckBox
        disabled={disabled}
        isChecked={isAddOnSelected}
        onChange={() => selectAddOn(addOn.id, addOn.sellingOptions[0], addOn.name)}
        primaryCheckBoxIconColor={primaryColor}
        defaultTextColor={textColor}
        className="col-1"
      />

      <div className="col-1 d-flex align-items-center">
        <i
          className={`ep-position-top-3 ep-cursor-pointer ep__txt--sm ep__txt-no-select ${
            isShowAddOnSellingOption ? 'ep-icon-minimal-up' : 'ep-icon-minimal-down'
          }`}
          style={{ color: textColor }}
          onClick={() => setIsShowAddOnSellingOption(!isShowAddOnSellingOption)}
        />
      </div>

      <div className="col-6 d-flex align-items-center">
        <StyledSpan
          font={fontText}
          className="ep__txt--md ep__txt-no-select ep-ellipsis"
          style={{ color: textColor }}
        >
          {addOn.name}
        </StyledSpan>
      </div>

      {selectedAddOn.addOnSellingOption && !selectedAddOn.addOnSellingOption.showPriceOnFrontend ? (
        <AddOnPrice
          fontText={fontText}
          textColor={textColor}
          oneTimePrice={selectedAddOn.addOnSellingOption.oneTimePrice}
          monthlyPrice={selectedAddOn.addOnSellingOption.monthlyPrice}
          yearlyPrice={selectedAddOn.addOnSellingOption.yearlyPrice}
        />
      ) : (
        <div className="col-4 d-flex align-items-center justify-content-end">
          <StyledSpan
            font={fontText}
            className="ep__txt--md text-right ep__txt-no-select"
            style={{ color: textColor }}
          >
            {addOn.sellingOptions[0] && addOn.sellingOptions[0].showPriceOnFrontend
              ? GERMAN_TEXT.ON_REQUEST
              : `0,00 ${SPECIAL_CHARACTER.EURO}`}
          </StyledSpan>
        </div>
      )}

      <div className="col-12" style={{ minHeight: 0 }}>
        <Collapse isOpen={isShowAddOnSellingOption} keepChildrenMounted transitionDuration={0}>
          <div ref={addonSellingOptionRef}>
            {_.map(addOn.sellingOptions, addOnSellingOption => {
              const isAddOnSellingOptionSelected = !!(
                selectedAddOn.addOnSellingOption &&
                selectedAddOn.addOnSellingOption.id === addOnSellingOption.id
              );
              return (
                <AddOnSellingOptionItem
                  addOnSellingOption={addOnSellingOption}
                  addOn={addOn}
                  fontText={fontText}
                  primaryColor={primaryColor}
                  isAddOnSelected={isAddOnSelected}
                  isSelected={isAddOnSellingOptionSelected}
                  key={`${productType}-${addOn.id}-${addOnSellingOption.id}`}
                  selectAddOnSellingOption={selectAddOnSellingOption}
                  radioBtnClassName={`addon-${isAddOnSellingOptionSelected}-${addOnSellingOption.id}`}
                />
              );
            })}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

AddOnItem.propTypes = {
  addOn: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
  fontText: PropTypes.object,
  selectedAddOn: PropTypes.object,
  isAddOnSelected: PropTypes.bool.isRequired,
  primaryColor: PropTypes.string,
  productType: PropTypes.string.isRequired,
  selectAddOn: PropTypes.func.isRequired,
  selectAddOnSellingOption: PropTypes.func.isRequired,
  setScrollHeight: PropTypes.func.isRequired,
  textColor: PropTypes.string
};

AddOnItem.defaultProps = {
  addOn: {},
  fontText: {},
  selectedAddOn: {},
  primaryColor: COLOR.DEFAULT_BLUE,
  textColor: COLOR.DARK_GRAY
};

export default memo(AddOnItem);

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { StyledSpan } from '../../components';
import { COLOR } from '../../common/constants';
import { updatePaymentInfo } from '../../store/actions';

let PayPalButton = null;

class PaypalContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false
    };

    PayPalButton = window.paypal.Buttons.driver('react', { React, ReactDOM });
  }

  createOrder = (data, actions) => {
    const { amount } = this.props;

    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: amount
          }
        }
      ]
    });
  };

  onApprove = (data, actions) => {
    const { updatePaymentInfo } = this.props;

    actions.order.authorize().then(authorization => {
      const authorizationId = authorization.purchase_units[0].payments.authorizations[0].id;
      updatePaymentInfo({
        payPalConnected: true,
        orderId: data.orderID,
        authorizationId: authorizationId,
        buyerEmail: authorization.payer.email_address
      });
    });
  };

  onError = err => {
    this.setState({ error: true });
  };

  onCancel = (data, actions) => {
    //TODO: handle cancel case
  };

  render() {
    const { error } = this.state;
    const { paymentInfo, defaultTextColor, fontFamily } = this.props;

    const textColor = {
      color: error ? 'red' : defaultTextColor
    };

    return (
      <div>
        {!paymentInfo.payPalConnected && !error && (
          <div>
            <PayPalButton
              createOrder={(data, actions) => this.createOrder(data, actions)}
              onApprove={(data, actions) => this.onApprove(data, actions)}
              onError={err => this.onError(err)}
              onCancel={(data, actions) => this.onCancel(data, actions)}
            />
          </div>
        )}

        {paymentInfo.payPalConnected && (
          <div>
            <StyledSpan
              className="ep__txt--sm ep__txt-bold ep-text-dark-grey"
              style={textColor}
              font={fontFamily}
            >
              {'The payment will be completed when you submit the order.'}
            </StyledSpan>
          </div>
        )}

        {error && (
          <div>
            <StyledSpan className="ep__txt--sm ep__txt-bold" style={textColor} font={fontFamily}>
              {'Your PayPal payment could not be processed. Please select another payment option.'}
            </StyledSpan>
          </div>
        )}
      </div>
    );
  }
}

PaypalContainer.propTypes = {
  paymentInfo: PropTypes.object.isRequired,
  amount: PropTypes.number,
  fontFamily: PropTypes.object,
  defaultTextColor: PropTypes.string,
  updatePaymentInfo: PropTypes.func.isRequired
};

PaypalContainer.defaultProps = {
  amount: 0,
  fontFamily: {},
  defaultTextColor: COLOR.DARK_GRAY
};

function mapStateToProps(state) {
  return {
    paymentInfo: state.paymentInfo
  };
}

export default connect(
  mapStateToProps,
  {
    updatePaymentInfo
  }
)(PaypalContainer);

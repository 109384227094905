import _ from 'lodash';
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { parse } from 'query-string';
import { bindActionCreators } from 'redux';
import {
  checkPurchaseType,
  checkPurchaseTypeV2,
  checkSelectedProductOnRequest,
  getSummaryDataModelV1,
  hexaToRGBA,
  isAllOnlinePurchaseProductPackagesSummaryV2,
  isAllOnRequestProductPackagesSummaryV2,
  ShowIfUtil
} from '../common/utils';
import { StyledSpan } from '../components';
import {
  BOOLEAN,
  COLOR,
  CONNECT_ERROR_PAGE,
  FRONTEND_TYPE,
  NOT_FOUND_PAGE,
  PAYMENT_OPTION,
  PURCHASE_TYPE,
  SPECIAL_CHARACTER
} from '../common/constants';
import {
  FRONTEND_CONFIGURATION_FAILURE,
  getFrontendConfiguration,
  resetCustomerJourneyData,
  updateRouterParam
} from '../store/actions';
import {
  emailTemplateV2DataSelector,
  getFontTextSelector,
  productSelectionsSelector,
  selectedProductsSelector,
  totalPriceCalcSelector,
  totalPriceCalcV2Selector
} from '../store/selectors';

class ThankYouScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      icon: 'ep-icon-single-copy-04',
      isOnlinePurchaseScreen: false,
      contentTitle: '',
      contentSubTitle: '',
      bodyContent: '',
      contentLink: '',
      fontText: {
        font: SPECIAL_CHARACTER.EMPTY,
        fontUrl: SPECIAL_CHARACTER.EMPTY
      }
    };
  }

  componentDidMount() {
    const {
      updateRouterParam,
      location,
      history,
      getFrontendConfiguration,
      frontendConfiguration,
      fontText
    } = this.props;
    const {
      token,
      allow_submit,
      isPreviewThankyouScreen,
      previewOnlinePurchase,
      previewMixedOrder
    } = parse(location.search);

    // Custom font
    this.setState(prevState => ({
      fontText: {
        ...prevState.fontText,
        ...fontText
      }
    }));
    // This is use to preview thank you screen page in admin page.
    if (token) {
      updateRouterParam({
        allowSubmit: allow_submit,
        isPreviewThankyouScreen: isPreviewThankyouScreen,
        previewOnlinePurchase: previewOnlinePurchase,
        previewMixedOrder: previewMixedOrder
      });
      getFrontendConfiguration({ token }).then(action => {
        switch (action.type) {
          case FRONTEND_CONFIGURATION_FAILURE:
            history.push(CONNECT_ERROR_PAGE);
            break;
          default:
            break;
        }
      });
    } else {
      if (!frontendConfiguration) {
        history.push(NOT_FOUND_PAGE);
      } else {
        // If we are in frontend page, all data exist, just render it
        this.generateThankYouScreenData();
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { frontendConfiguration } = this.props;
    if (
      frontendConfiguration === false &&
      frontendConfiguration !== nextProps.frontendConfiguration
    ) {
      this.generateThankYouScreenData(nextProps.frontendConfiguration);
    }
  }

  generateThankYouScreenData = responseFEConfiguration => {
    const {
      routeManager,
      thankYouPageData,
      productSelections,
      totalPrice,
      totalPriceV2,
      frontendConfiguration: existedFEConfiguration,
      selectedProducts,
      emailTemplateV2Data
    } = this.props;
    let {
      icon,
      isOnlinePurchaseScreen,
      contentTitle,
      contentSubTitle,
      bodyContent,
      contentLink
    } = this.state;
    let frontendConfiguration = null;
    if (responseFEConfiguration) {
      frontendConfiguration = responseFEConfiguration;
    } else {
      frontendConfiguration = existedFEConfiguration;
    }
    if (!frontendConfiguration) return;

    const {
      onlinePurchaseSettingModel,
      thankYouScreenOptionsModel,
      frontendType
    } = frontendConfiguration;

    const isPreview = routeManager.isPreviewThankyouScreen && routeManager.previewOnlinePurchase;
    const purchaseType = checkPurchaseType(
      productSelections.selectedPurchaseRental,
      totalPrice,
      onlinePurchaseSettingModel.active
    );

    const purchaseTypeV2 = checkPurchaseTypeV2(totalPriceV2, onlinePurchaseSettingModel.active);
    if (isPreview) {
      isOnlinePurchaseScreen = routeManager.previewOnlinePurchase === BOOLEAN.TRUE;
    } else {
      isOnlinePurchaseScreen = onlinePurchaseSettingModel && onlinePurchaseSettingModel.active;
    }
    if (isPreview) {
      contentTitle = thankYouScreenOptionsModel.orderRequestTitle;
      bodyContent = thankYouScreenOptionsModel.orderRequestSubtitle;
      contentLink = thankYouScreenOptionsModel.orderRequestHyperLink;
      if (onlinePurchaseSettingModel.active) {
        if (routeManager.previewOnlinePurchase === BOOLEAN.TRUE) {
          contentTitle = thankYouScreenOptionsModel.onlinePurchaseTitle;
          contentSubTitle = thankYouScreenOptionsModel.onlinePurchaseSubTitle;
          bodyContent = thankYouScreenOptionsModel.onlinePurchaseBody;
          contentLink = thankYouScreenOptionsModel.onlinePurchaseHyperLink;
          icon = 'ep-icon-cart-speed';
        } else if (routeManager.previewMixedOrder === BOOLEAN.TRUE) {
          contentTitle = thankYouScreenOptionsModel.mixedOrderTitle;
          contentSubTitle = thankYouScreenOptionsModel.mixedOrderSubTitle;
          bodyContent = thankYouScreenOptionsModel.mixedOrderBody;
          contentLink = thankYouScreenOptionsModel.mixedOrderHyperLink;
          icon = 'ep-icon-cart-speed';
        }
      }
    } else {
      const checkConditionV1 =
        frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY &&
        !getSummaryDataModelV1(selectedProducts, productSelections)
          .isAllOnRequestPackageSummaryModelsV1 &&
        purchaseType !== PURCHASE_TYPE.REQUEST &&
        !_.isEmpty(selectedProducts) &&
        !checkSelectedProductOnRequest({
          selectedProducts,
          productSelections
        });
      const checkConditionV2 =
        frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2 &&
        !isAllOnRequestProductPackagesSummaryV2(emailTemplateV2Data.productPackagesSummary) &&
        purchaseTypeV2 !== PURCHASE_TYPE.REQUEST;
      if (
        isOnlinePurchaseScreen &&
        !thankYouPageData.onlinePurchaseOnlyRequest &&
        (checkConditionV1 || checkConditionV2)
      ) {
        if (
          (frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2 &&
            isAllOnlinePurchaseProductPackagesSummaryV2(
              emailTemplateV2Data.productPackagesSummary
            )) ||
          (frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY &&
            getSummaryDataModelV1(selectedProducts, productSelections)
              .isAllOnlinePurchasePackageSummaryModelsV1)
        ) {
          contentTitle = thankYouScreenOptionsModel.onlinePurchaseTitle;
          contentSubTitle = thankYouScreenOptionsModel.onlinePurchaseSubTitle.replace(
            '[[Ordernumber]]',
            thankYouPageData.orderNumber
          );
          bodyContent = thankYouScreenOptionsModel.onlinePurchaseBody.replace(
            '[[Useremail]]',
            thankYouPageData.userEmail
          );
          contentLink = thankYouScreenOptionsModel.onlinePurchaseHyperLink;
          icon = 'ep-icon-cart-speed';
        } else {
          contentTitle = thankYouScreenOptionsModel.mixedOrderTitle;
          contentSubTitle = thankYouScreenOptionsModel.mixedOrderSubTitle.replace(
            '[[Ordernumber]]',
            thankYouPageData.orderNumber
          );
          bodyContent = thankYouScreenOptionsModel.mixedOrderBody.replace(
            '[[Useremail]]',
            thankYouPageData.userEmail
          );
          contentLink = thankYouScreenOptionsModel.mixedOrderHyperLink;
          icon = 'ep-icon-cart-speed';
        }
      } else {
        contentTitle = thankYouScreenOptionsModel.orderRequestTitle;
        bodyContent = thankYouScreenOptionsModel.orderRequestSubtitle;
        contentLink = thankYouScreenOptionsModel.orderRequestHyperLink;
      }
    }
    this.setState({
      isOnlinePurchaseScreen,
      icon,
      contentTitle,
      contentSubTitle,
      bodyContent,
      contentLink
    });
  };

  resetData = () => {
    const { resetCustomerJourneyData } = this.props;
    resetCustomerJourneyData();
  };

  render() {
    let {
      icon,
      isOnlinePurchaseScreen,
      contentTitle,
      contentSubTitle,
      bodyContent,
      contentLink,
      fontText
    } = this.state;
    const { frontendConfiguration, paymentInfo } = this.props;
    return (
      <section className="d-flex justify-content-center align-items-center flex-column ep-thank-you-screen-wrapper ep--mb-8">
        {/*----- ICON -----*/}
        <div
          className="layout-icon mx-auto"
          style={{
            background: hexaToRGBA(
              frontendConfiguration ? frontendConfiguration.primaryColor : COLOR.DEFAULT_BLUE,
              0.05
            )
          }}
        >
          <ShowIfUtil condition={icon === 'ep-icon-single-copy-04'}>
            <i
              className="ep-icon-single-copy-04"
              style={{
                color: frontendConfiguration
                  ? frontendConfiguration.primaryColor
                  : COLOR.DEFAULT_BLUE,
                fontSize: '4.375rem'
              }}
            />
          </ShowIfUtil>
          <ShowIfUtil condition={icon === 'ep-icon-cart-speed'}>
            <i
              className="ep-icon-cart-speed"
              style={{
                color: frontendConfiguration
                  ? frontendConfiguration.primaryColor
                  : COLOR.DEFAULT_BLUE,
                fontSize: '4.375rem'
              }}
            />
          </ShowIfUtil>
        </div>
        {/*----- TITLE -----*/}
        <div className="ep--mt-32 text-center">
          <StyledSpan
            font={fontText}
            className="ep__txt--h1 ep__txt-semibold"
            style={{
              color: frontendConfiguration ? frontendConfiguration.primaryColor : COLOR.DEFAULT_BLUE
            }}
          >
            {contentTitle}
          </StyledSpan>
        </div>

        {/*----- SUBTITLE -----*/}
        <ShowIfUtil condition={isOnlinePurchaseScreen}>
          <StyledSpan
            font={fontText}
            className="ep__txt--lg ep__txt-semibold ep-opacity-50"
            style={{ color: COLOR.DARK_GRAY }}
          >
            {contentSubTitle}
          </StyledSpan>
        </ShowIfUtil>

        {/*----- BODY -----*/}
        <div className="ep--mt-32 text-center">
          <StyledSpan
            font={fontText}
            className="ep__txt--md ep__txt-regular"
            style={{ color: frontendConfiguration.defaultTextColor }}
          >
            {bodyContent}
          </StyledSpan>
        </div>

        {frontendConfiguration.onlinePurchaseSettingModel &&
          frontendConfiguration.onlinePurchaseSettingModel.active &&
          paymentInfo.paymentType !== PAYMENT_OPTION.PAYMENTBYINVOICE &&
          !paymentInfo.paid && (
            <div className="ep--mt-32 text-center">
              <StyledSpan
                font={fontText}
                className="ep__txt--md ep__txt-regular"
                style={{ color: 'red' }}
              >
                {'Your payment could not be processed successfully.'}
              </StyledSpan>
            </div>
          )}

        {/*----- HYPERLINK TEXT -----*/}
        <div className="ep--mt-32">
          <StyledSpan
            font={fontText}
            className="ep__txt--sm ep__txt-semibold"
            style={{
              color: frontendConfiguration ? frontendConfiguration.primaryColor : COLOR.DEFAULT_BLUE
            }}
            onClick={this.resetData}
          >
            <Link
              style={{
                color: frontendConfiguration
                  ? frontendConfiguration.primaryColor
                  : COLOR.DEFAULT_BLUE
              }}
              to={`/frontends?token=${frontendConfiguration.token}`}
            >
              <StyledSpan font={fontText}>{contentLink}</StyledSpan>
            </Link>
          </StyledSpan>
        </div>
      </section>
    );
  }
}

ThankYouScreen.propTypes = {
  frontendConfiguration: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  thankYouPageData: PropTypes.object,
  productSelections: PropTypes.object,
  totalPrice: PropTypes.object,
  totalPriceV2: PropTypes.object,
  routeManager: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  fontText: PropTypes.object,
  updateRouterParam: PropTypes.func.isRequired,
  resetCustomerJourneyData: PropTypes.func.isRequired,
  getFrontendConfiguration: PropTypes.func.isRequired,
  selectedProducts: PropTypes.array,
  emailTemplateV2Data: PropTypes.object.isRequired,
  paymentInfo: PropTypes.object.isRequired
};

ThankYouScreen.defaultProps = {
  frontendConfiguration: {},
  thankYouPageData: {},
  productSelections: {},
  totalPrice: {},
  totalPriceV2: {},
  routeManager: {},
  history: {},
  location: {},
  fontText: {},
  selectedProducts: []
};

function mapStateToProps(state) {
  return {
    frontendConfiguration: state.frontendConfiguration,
    productSelections: productSelectionsSelector(state),
    fontText: getFontTextSelector(state),
    totalPrice: totalPriceCalcSelector(state),
    totalPriceV2: totalPriceCalcV2Selector(state),
    selectedProducts: selectedProductsSelector(state),
    emailTemplateV2Data: emailTemplateV2DataSelector(state),
    thankYouPageData: state.thankYouPage,
    routeManager: state.route,
    paymentInfo: state.paymentInfo
  };
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators(
    {
      getFrontendConfiguration,
      updateRouterParam,
      resetCustomerJourneyData
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThankYouScreen);

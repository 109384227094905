import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { Position, Tooltip } from '@blueprintjs/core';
import classNames from 'classnames';
import _ from 'lodash';
import {
  COLOR,
  FRONTEND_TYPE,
  GERMAN_TEXT,
  ON_REQUEST,
  PAYMENT_FREQUENCY,
  PRODUCT_DEACTIVATED_MESSAGE,
  PRODUCT_SELECTED_KEY,
  PRODUCT_STATUS,
  SPECIAL_CHARACTER
} from '../../common/constants';
import {
  CheckBox,
  StyledButtonBold,
  StyledSpan,
  StyledSpanBold
} from '../../components/epilot-forms';
import {
  convertProdTypeToProdName,
  formatPrice,
  ShowIconByProductTypeUtil,
  ShowIfUtil
} from '../../common/utils';
import { updateProductSelections, updateSelectedAddOnV2 } from '../../store/actions';
import AddOnContainer from './AddOnContainer';

class ProductContainer extends PureComponent {
  componentDidUpdate(prevProps) {
    const { isProductAvailable, productType, updateProductSelections } = this.props;
    if (isProductAvailable !== prevProps.isProductAvailable && !isProductAvailable) {
      updateProductSelections({
        [PRODUCT_SELECTED_KEY[productType]]: false
      });
    }
  }

  renderPrice = ({ currentPackage, fontStyle, textColor, fontText }, isToolTipShowing) => {
    const tmpPrices = [];
    if (currentPackage.oneTimePrice !== null) {
      if (currentPackage.oneTimePrice !== ON_REQUEST && !currentPackage.alwaysShowOnRequest) {
        tmpPrices.push({
          id: `${currentPackage.packageId}-oneTime`,
          unit: '€',
          type: PAYMENT_FREQUENCY.ONE_TIME,
          price: currentPackage.oneTimePrice
        });
      } else {
        tmpPrices.push({
          id: `${currentPackage.packageId}-oneTime-onRequest`,
          unit: '',
          type: '',
          price: GERMAN_TEXT.ON_REQUEST
        });
      }
    }
    if (currentPackage.monthlyPrice !== null) {
      if (currentPackage.monthlyPrice !== ON_REQUEST && !currentPackage.alwaysShowOnRequest) {
        tmpPrices.push({
          id: `${currentPackage.packageId}-monthly`,
          unit: '€/Monat',
          type: PAYMENT_FREQUENCY.MONTHLY,
          price: currentPackage.monthlyPrice
        });
      } else {
        tmpPrices.push({
          id: `${currentPackage.packageId}-monthly-onRequest`,
          unit: '',
          type: '',
          price: GERMAN_TEXT.ON_REQUEST
        });
      }
    }

    if (currentPackage.yearlyPrice !== null) {
      if (currentPackage.monthlyPrice !== ON_REQUEST && !currentPackage.alwaysShowOnRequest) {
        tmpPrices.push({
          id: `${currentPackage.packageId}-yearly`,
          unit: '€/jahr',
          type: PAYMENT_FREQUENCY.YEARLY,
          price: currentPackage.yearlyPrice
        });
      } else {
        tmpPrices.push({
          id: `${currentPackage.packageId}-yearly-onRequest`,
          unit: '',
          type: '',
          price: GERMAN_TEXT.ON_REQUEST
        });
      }
    }

    return _.map(tmpPrices, (elem, index) => {
      const priceClassName = classNames({
        'ep__txt-regular': true,
        [fontStyle]: true,
        'ep--ml-16': index !== 0 && !isToolTipShowing
      });
      return (
        <div
          className={
            index === 0 ? 'd-inline ep-ellipsis ep__txt-no-select' : 'ep-ellipsis ep__txt-no-select'
          }
          key={elem.id}
        >
          <ShowIfUtil condition={index === 0}>
            <StyledSpan font={fontText} className={priceClassName} style={textColor}>
              {formatPrice(elem.price, elem.unit)}
            </StyledSpan>
          </ShowIfUtil>

          <ShowIfUtil
            condition={index !== 0 && elem.price !== GERMAN_TEXT.ON_REQUEST && elem.price !== 0}
          >
            <StyledSpan
              font={fontText}
              className={priceClassName + ` ${isToolTipShowing ? 'pr-1' : 'px-1'}`}
              style={textColor}
            >
              {elem.price > 0 ? SPECIAL_CHARACTER.PLUS : SPECIAL_CHARACTER.MINUS}
            </StyledSpan>
            <StyledSpan font={fontText} style={textColor}>
              {formatPrice(Math.abs(elem.price), elem.unit)}
            </StyledSpan>
          </ShowIfUtil>
        </div>
      );
    });
  };

  handleSelectProduct = () => {
    const { productType, isProductSelected, selectProduct, isProductAvailable } = this.props;
    if (isProductAvailable) {
      selectProduct({ productType, isProductSelected });
    }
  };

  getAvailableAddon = () => {
    const { productType, currentPackage, productAddOnConfig } = this.props;
    const {
      productAddOnsMapKeyByProductType,
      specificPackageAddOnsMapKeyByPackageId
    } = productAddOnConfig;
    const availableAddons = [];

    if (productAddOnsMapKeyByProductType[productType]) {
      availableAddons.push(
        ...productAddOnsMapKeyByProductType[productType].filter(
          addOn => addOn.sellingOptions?.length > 0
        )
      );
    }

    if (specificPackageAddOnsMapKeyByPackageId[currentPackage.packageId]) {
      availableAddons.push(
        ...specificPackageAddOnsMapKeyByPackageId[currentPackage.packageId].filter(
          addOn => addOn.sellingOptions?.length > 0
        )
      );
    }

    return _.uniqWith(availableAddons, _.isEqual);
  };

  renderAddOns = () => {
    const {
      isProductSelected,
      productId,
      uiStyle,
      productType,
      setScrollHeight,
      frontendConfiguration,
      currentPackage
    } = this.props;

    const { primaryColor, fontText } = uiStyle;

    const textColor = COLOR.DARK_GRAY;

    return (
      <AddOnContainer
        customBorderRadius={frontendConfiguration.borderRadiusMax4px}
        fontText={fontText}
        isProductSelected={isProductSelected}
        primaryColor={primaryColor}
        productId={productId}
        productType={productType}
        textColor={textColor}
        currentPackage={currentPackage}
        setScrollHeight={setScrollHeight}
        isShowPackageInfo={frontendConfiguration.isShowPackageInfo}
      />
    );
  };

  render() {
    const {
      productType,
      currentPackage,
      isProductSelected,
      isProductAvailable,
      showPricingComp,
      showProductPackageComp,
      uiStyle,
      frontendConfiguration,
      product
    } = this.props;

    const { frontendType } = frontendConfiguration;
    // Always show package info in WEBSHOP
    const isShowPackageInfo =
      frontendConfiguration.isShowPackageInfo ||
      frontendConfiguration.frontendType === FRONTEND_TYPE.WEBSHOP_V2;

    const {
      designOptions: {
        showPackageInfoSpecialCondition: { showAllComponents }
      }
    } = frontendConfiguration;

    const { primaryColor, primaryTextColor, borderRadiusMax4px, fontText, fontTextBold } = uiStyle;

    const tooltipTextColor = {
      color: frontendConfiguration.defaultTextColor
    };

    let style;
    let textColor;
    let textColorVAT = {};
    let borderButtonViewComponent = {};
    let iconColor;
    let shadowClass = SPECIAL_CHARACTER.EMPTY;
    const deactivatedProduct = !!(product.status && product.status === PRODUCT_STATUS.DEACTIVATED);

    if (isProductAvailable && !deactivatedProduct) {
      if (isProductSelected) {
        style = {
          backgroundColor: primaryColor,
          color: primaryTextColor,
          borderRadius: borderRadiusMax4px,
          border: '1px solid transparent'
        };

        textColor = {
          color: primaryTextColor
        };

        textColorVAT = {
          color: COLOR.WHITE
        };

        borderButtonViewComponent = {
          border: '1px solid rgba(255, 255, 255, 0.16)'
        };

        iconColor = primaryTextColor;
      } else {
        style = {
          backgroundColor: COLOR.TRANSPARENT,
          color: frontendConfiguration.defaultTextColor,
          border: '1px solid rgba(68,68,68,0.16)',
          borderRadius: borderRadiusMax4px
        };

        textColor = {
          color: frontendConfiguration.defaultTextColor
        };

        textColorVAT = {
          color: frontendConfiguration.defaultTextColor
        };

        iconColor = frontendConfiguration.defaultTextColor;
      }

      shadowClass = ' box-shadow';
    } else {
      style = {
        backgroundColor: '#F9F9F9',
        color: '#8A8A8A',
        borderRadius: borderRadiusMax4px
      };
      textColor = {
        color: '#8A8A8A'
      };
      iconColor = '#8A8A8A';
    }
    const viewProductPackageBtnClass = classNames({
      'ep--mr-16': true,
      'justify-content-end': true,
      'ep-text-dark-grey ep-cursor-not-allow': !isProductAvailable,
      'ep-cursor-pointer': isProductAvailable
    });

    const productWrapperClass = classNames({
      'ep-webshopv2-product-wrapper-customer-journey ep--my-4':
        frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2,
      'ep-webshopv2-product-wrapper ep--my-4': frontendType !== FRONTEND_TYPE.CUSTOMER_JOURNEY_V2
    });

    const fontStylePackageName = classNames({
      'ep__txt-bold': true,
      'ep__txt--lg': frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2,
      'ep__txt--md': !(frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2)
    });

    const fontStyle = classNames({
      'ep__txt--md': frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2,
      'ep__txt--sm': !(frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2)
    });

    const viewComponentBtnStyle = {
      color: frontendConfiguration.primaryTextColor,
      backgroundColor: isProductSelected ? frontendConfiguration.primaryColor : COLOR.WHITE,
      borderButtonViewComponent
    };

    return (
      <>
        <Tooltip
          content={
            <StyledSpan font={fontText} style={tooltipTextColor}>
              {'Dieses Produkt ist leider nicht an der angegebenen Postleitzahl verfügbar.'}
            </StyledSpan>
          }
          className="w-100"
          position={Position.BOTTOM}
          tooltipClassName="ep-tooltip"
          disabled={isProductAvailable}
        >
          <div
            className={
              productWrapperClass +
              ' d-flex align-items-center justify-content-between' +
              shadowClass
            }
            style={style}
          >
            <div className="d-flex align-items-center ep-ellipsis ep--mr-8">
              <CheckBox
                className="ep--ml-16"
                onChange={this.handleSelectProduct}
                disabled={!isProductAvailable || deactivatedProduct}
                content={SPECIAL_CHARACTER.EMPTY}
                isChecked={!deactivatedProduct && isProductSelected}
                primaryTextColor={primaryTextColor}
                primaryCheckBoxIconColor={primaryColor}
              />

              <Tooltip
                content={
                  <StyledSpan font={fontText} style={tooltipTextColor}>
                    {convertProdTypeToProdName(productType)}
                  </StyledSpan>
                }
                position={Position.BOTTOM}
                disabled={!isProductAvailable}
                tooltipClassName="ep-tooltip"
                className="ep--ml-8"
              >
                <ShowIconByProductTypeUtil color={iconColor} productType={productType} />
              </Tooltip>

              <div className="ep--ml-16 w-100 ep-ellipsis ep--py-16">
                <ShowIfUtil condition={!isShowPackageInfo}>
                  <StyledSpan
                    font={fontText}
                    dangerouslySetInnerHTML={{
                      __html: convertProdTypeToProdName(productType)
                    }}
                    className="ep__txt-no-select ep__txt--lg ep__txt-semibold"
                    style={textColor}
                  />
                </ShowIfUtil>

                <ShowIfUtil condition={isShowPackageInfo}>
                  {/* PACKAGE NAME */}
                  <div>
                    <Tooltip
                      content={
                        <StyledSpan font={fontText} style={tooltipTextColor}>
                          {currentPackage.packageId
                            ? currentPackage.name
                            : convertProdTypeToProdName(productType)}
                        </StyledSpan>
                      }
                      position={Position.BOTTOM}
                      tooltipClassName="ep-tooltip ep-tooltip-width-300"
                      disabled={!isProductAvailable}
                      className="ep-ellipsis w-100"
                    >
                      <div className="ep__txt-no-select ep-ellipsis w-100">
                        <StyledSpanBold
                          font={fontTextBold}
                          className={fontStylePackageName}
                          style={textColor}
                        >
                          {currentPackage.packageId
                            ? currentPackage.name
                            : convertProdTypeToProdName(productType)}
                        </StyledSpanBold>
                      </div>
                    </Tooltip>
                  </div>
                  <ShowIfUtil condition={!deactivatedProduct}>
                    {/* PRICE */}
                    <div>
                      <Tooltip
                        content={this.renderPrice(
                          {
                            currentPackage,
                            textColor: { color: frontendConfiguration.defaultTextColor },
                            fontStyle,
                            textColorVAT: { color: frontendConfiguration.defaultTextColor },
                            fontText
                          },
                          true
                        )}
                        position={Position.BOTTOM}
                        tooltipClassName="ep-tooltip"
                        className="ep-ellipsis w-100"
                        disabled={!isProductAvailable}
                        hoverCloseDelay={200}
                      >
                        <div className="ep-ellipsis w-100">
                          <i
                            className={`d-inline-block ep-icon-money-coins ${fontStyle} ep--mr-8`}
                            style={textColor}
                          />
                          {this.renderPrice({
                            currentPackage,
                            textColor,
                            fontStyle,
                            textColorVAT,
                            fontText
                          })}
                        </div>
                      </Tooltip>
                    </div>
                  </ShowIfUtil>
                  <ShowIfUtil condition={deactivatedProduct}>
                    <div className="ep-ellipsis w-100">
                      <i
                        className={`d-inline-block ep-icon-money-coins ${fontStyle} ep--mr-8`}
                        style={textColor}
                      />
                      <StyledSpan
                        font={fontText}
                        className={`ep__txt-regular ${
                          frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY
                            ? 'ep__txt--md'
                            : 'ep__txt--sm'
                        }`}
                        style={textColor}
                      >
                        {PRODUCT_DEACTIVATED_MESSAGE.DE}
                      </StyledSpan>
                    </div>
                  </ShowIfUtil>
                  {/* VIEW COMPONENT BUTTON*/}
                  <ShowIfUtil
                    condition={
                      !!(
                        showAllComponents &&
                        currentPackage.packageId &&
                        !currentPackage.alwaysShowOnRequest
                      )
                    }
                  >
                    <StyledButtonBold
                      font={fontTextBold}
                      className="d-flex align-items-center ep-button view-component-button ep__txt-bold ep__txt--sm ep--mt-8 ep__txt-no-select"
                      style={viewComponentBtnStyle}
                      disabled={!isProductAvailable}
                      onClick={() => showPricingComp(productType)}
                    >
                      <StyledSpan style={textColor} font={fontText}>
                        {GERMAN_TEXT.VIEW_COMPONENTS}
                      </StyledSpan>
                      <i
                        className="d-inline-block ep-icon-small-right ep__txt--h1"
                        style={textColor}
                      />
                    </StyledButtonBold>
                  </ShowIfUtil>
                </ShowIfUtil>
              </div>
            </div>
            <ShowIfUtil condition={!!(isShowPackageInfo && currentPackage.packageId)}>
              <div
                className={viewProductPackageBtnClass}
                onClick={() =>
                  isProductAvailable && !deactivatedProduct
                    ? showProductPackageComp(productType)
                    : {}
                }
              >
                <i className="ep-icon-circle-right-37 ep__txt--h1" style={textColor} />
              </div>
            </ShowIfUtil>
          </div>
        </Tooltip>
        {this.renderAddOns()}
      </>
    );
  }
}

ProductContainer.propTypes = {
  setScrollHeight: PropTypes.func.isRequired,
  showProductPackageComp: PropTypes.func.isRequired,
  updateProductSelections: PropTypes.func.isRequired,
  showPricingComp: PropTypes.func.isRequired,
  selectProduct: PropTypes.func.isRequired,
  currentPackage: PropTypes.object,
  productAddOnConfig: PropTypes.object.isRequired,
  isProductSelected: PropTypes.bool,
  isProductAvailable: PropTypes.bool,
  productId: PropTypes.number.isRequired,
  productType: PropTypes.string.isRequired,
  uiStyle: PropTypes.object.isRequired,
  frontendConfiguration: PropTypes.object,
  product: PropTypes.object
};

ProductContainer.defaultProps = {
  isProductSelected: false,
  isProductAvailable: true,
  currentPackage: {},
  frontendConfiguration: {},
  product: {}
};

const mapStateToProps = state => ({
  productAddOnConfig: state.productAddOnConfig,
  frontendConfiguration: state.frontendConfiguration
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateProductSelections,
        updateSelectedAddOnV2
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductContainer);

import React, { Component } from 'react';
import { Suggest } from '@blueprintjs/select';
import { MenuItem } from '@blueprintjs/core';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import { COLOR, MESSAGE_CONSTANTS, SPECIAL_CHARACTER } from '../../../common/constants';
import { ShowIfUtil } from '../../../common/utils';
import { StyledLabel, StyledSpan } from './StyledElement';

const StyledSuggestAutocomplete = styled.div`
  font-family: ${props => props.children[0].props.font.font} !important;
`;

const StyledMenuItem = styled(MenuItem)`
  color: ${props => props.color};
  &.pt-active {
    color: ${props => props.color} !important;
  }
`;

class AutoComplete extends Component {
  constructor(props) {
    super(props);
    const { selectedValue } = this.props;
    this.state = {
      value: selectedValue,
      isValid: true
    };
    this.inputValue = SPECIAL_CHARACTER.EMPTY;
  }

  componentDidMount() {
    const { borderColorFocus, defaultTextColor } = this.props;
    const input = document.getElementsByClassName('ep-input');
    this.addEvent(input, defaultTextColor, borderColorFocus);

    const autoInput = document.getElementsByClassName('pt-input');
    this.addEvent(autoInput, defaultTextColor, borderColorFocus);
  }

  componentWillReceiveProps(nextProps, prevState) {
    const { selectedValue } = this.props;
    if (selectedValue !== nextProps.selectedValue) {
      this.setState({ value: nextProps.selectedValue });
    }
    if (nextProps.isStreetNotFound !== prevState.isStreetNotFound) {
      const autoInput = document.getElementsByClassName('pt-input');
      if (nextProps.isStreetNotFound) {
        this.addBorderColor(autoInput, COLOR.RED);
      } else {
        this.addBorderColor(autoInput, COLOR.LIGHT_GRAY_1);
      }
    }
  }

  addEvent = (elements, defaultTextColor, borderColorFocus) => {
    for (let i = 0; i < elements.length; i++) {
      if (elements[i] && elements[i].style) {
        elements[i].style.color = defaultTextColor;
        elements[i].addEventListener('focus', function() {
          if (elements[i]?.style?.borderColor !== COLOR.RED) {
            elements[i].style.borderColor = borderColorFocus;
          }
        });
        elements[i].addEventListener('blur', function() {
          if (elements[i]?.style?.borderColor !== COLOR.RED) {
            elements[i].style.borderColor = COLOR.LIGHT_GRAY_1;
          }
        });
      }
    }
  };

  addBorderColor = (elements, borderColor) => {
    for (let i = 0; i < elements.length; i++) {
      if (elements[i] && elements[i].style) {
        elements[i].style.borderColor = borderColor;
      }
    }
  };

  onItemSelect = item => {
    const { onItemSelect } = this.props;
    if (item) {
      this.setState({
        value: item
      });
      this.inputValue = item;
      onItemSelect(item);
    }
  };

  onFilter = (query, items) => {
    const { customFilter, itemsLimited } = this.props;
    if (customFilter) {
      return customFilter(query, items);
    }
    if (itemsLimited) {
      return items
        .filter(item => item.toLowerCase().indexOf(query.toLowerCase()) >= 0)
        .slice(0, 50);
    }
    return items.filter(item => item.toLowerCase().indexOf(query.toLowerCase()) >= 0);
  };

  /**
   * Handle when user click outside the popover
   * If user type an incorrect value, the selected item will reset to empty
   * */
  onChange = e => {
    const { selectedValue, options, onItemSelect, isAddress, streetNotFoundOnchange } = this.props;
    let inputValue = e.target.value;
    let originalInputValue = e.target.value;
    let correctedAddress = false;
    const queryToArray = inputValue.split(' ');
    if (_.isArray(queryToArray) && queryToArray.length === 2) {
      const postalCodeToString = queryToArray[0].toString();
      const cityToString = queryToArray[1].toString();
      if (postalCodeToString && !_.includes(selectedValue, postalCodeToString)) {
        inputValue = postalCodeToString;
      }
      if (cityToString && !_.includes(selectedValue, cityToString)) {
        inputValue = cityToString;
      }
    }
    this.inputValue = inputValue;
    this.setState({ value: originalInputValue });
    if (options.find(country => country === originalInputValue)) {
      correctedAddress = true;
      onItemSelect(originalInputValue);
    }
    if (isAddress && !correctedAddress) {
      streetNotFoundOnchange(originalInputValue);
    }
  };

  popoverOnClose = () => {
    const { options } = this.props;
    if (this.inputValue === SPECIAL_CHARACTER.EMPTY) {
      this.onItemSelect(SPECIAL_CHARACTER.EMPTY);
      return;
    }

    for (const item of options) {
      if (this.inputValue === item) {
        return;
      }
    }
    this.onItemSelect(SPECIAL_CHARACTER.EMPTY);
  };

  renderDataItem = (item, { handleClick, modifiers }) => {
    const { defaultTextColor } = this.props;

    return (
      <div style={{ color: defaultTextColor }} key={item}>
        <StyledMenuItem
          active={modifiers.active}
          key={item}
          onClick={handleClick}
          text={item}
          color={defaultTextColor}
        />
      </div>
    );
  };

  render() {
    const {
      disabled,
      fontFamily,
      inputStyle,
      classStyle,
      label,
      require,
      initialContent,
      options,
      placeholder,
      fontSize,
      defaultTextColor,
      isAddress
    } = this.props;
    const { isValid, value } = this.state;

    const textColor = {
      color: defaultTextColor
    };

    return (
      <div className={classStyle}>
        {/*----- AUTOCOMPLETE LABEL -----*/}
        <ShowIfUtil condition={label !== SPECIAL_CHARACTER.EMPTY}>
          <StyledLabel
            className="ep__txt--sm ep__txt-regular ep__txt-no-select"
            font={fontFamily}
            style={{ color: defaultTextColor }}
          >
            {label}
            <ShowIfUtil condition={require}>
              <StyledSpan
                className="ep__txt--sm ep__txt-regular ep__txt-no-select"
                font={fontFamily}
                style={{ color: defaultTextColor }}
              >
                {' *'}
              </StyledSpan>
            </ShowIfUtil>
          </StyledLabel>
        </ShowIfUtil>

        {/*----- AUTOCOMPLETE -----*/}
        <ShowIfUtil condition={!!initialContent}>
          <StyledSuggestAutocomplete>
            <Suggest
              disabled={disabled}
              font={fontFamily}
              noResults={
                <MenuItem
                  disabled
                  text={
                    isAddress === true ? MESSAGE_CONSTANTS.STREET_NOT_FOUND : 'Keine Ergebnisse'
                  }
                />
              }
              initialContent={<MenuItem disabled text={initialContent} />}
              className="ep-autocomplete ep-custom-autocomplete"
              inputValueRenderer={item => item}
              itemRenderer={this.renderDataItem}
              items={options}
              onItemSelect={this.onItemSelect}
              itemListPredicate={this.onFilter}
              inputProps={{
                placeholder: placeholder,
                onChange: this.onChange,
                className: 'ep-autocomplete-input',
                style: {
                  ...inputStyle,
                  fontSize,
                  ...textColor
                },
                fontSize,
                value
              }}
              popoverProps={{
                popoverClassName: 'autocomplete-popover-custom',
                usePortal: false,
                onClose: this.popoverOnClose
              }}
            />

            {/*----- ERROR MESSAGE -----*/}
            <ShowIfUtil condition={!isValid && value.trim() === SPECIAL_CHARACTER.EMPTY}>
              <div className="">{MESSAGE_CONSTANTS.REQUIRED}</div>
            </ShowIfUtil>
          </StyledSuggestAutocomplete>
        </ShowIfUtil>
        <ShowIfUtil condition={!initialContent}>
          <StyledSuggestAutocomplete>
            <Suggest
              font={fontFamily}
              noResults={
                <MenuItem
                  disabled
                  text={
                    isAddress === true ? MESSAGE_CONSTANTS.STREET_NOT_FOUND : 'Keine Ergebnisse 111'
                  }
                />
              }
              className="ep-autocomplete ep-custom-autocomplete"
              inputValueRenderer={item => item}
              itemRenderer={this.renderDataItem}
              items={options}
              onItemSelect={this.onItemSelect}
              itemListPredicate={this.onFilter}
              inputProps={{
                autoComplete: 'disabled',
                disabled: disabled,
                placeholder: placeholder,
                onChange: this.onChange,
                className: 'ep-autocomplete-input',
                style: {
                  ...inputStyle,
                  fontSize,
                  textColor
                },
                value
              }}
              popoverProps={{
                popoverClassName: 'autocomplete-popover-custom',
                usePortal: false,
                onClose: this.popoverOnClose
              }}
            />

            {/*----- ERROR MESSAGE -----*/}
            <ShowIfUtil condition={!isValid && value.trim() === SPECIAL_CHARACTER.EMPTY}>
              <div className="">{MESSAGE_CONSTANTS.REQUIRED}</div>
            </ShowIfUtil>
          </StyledSuggestAutocomplete>
        </ShowIfUtil>
      </div>
    );
  }
}

AutoComplete.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  require: PropTypes.bool,
  classStyle: PropTypes.string,
  defaultTextColor: PropTypes.string,
  fontFamily: PropTypes.object,
  options: PropTypes.array,
  selectedValue: PropTypes.string,
  onItemSelect: PropTypes.func,
  inputStyle: PropTypes.object,
  customFilter: PropTypes.func,
  borderColorFocus: PropTypes.string,
  initialContent: PropTypes.string,
  fontSize: PropTypes.string,
  itemsLimited: PropTypes.bool,
  isAddress: PropTypes.bool,
  isStreetNotFound: PropTypes.bool,
  streetNotFoundOnchange: PropTypes.func
};

AutoComplete.defaultProps = {
  disabled: false,
  borderColorFocus: '',
  label: SPECIAL_CHARACTER.EMPTY,
  placeholder: SPECIAL_CHARACTER.EMPTY,
  require: false,
  classStyle: SPECIAL_CHARACTER.EMPTY,
  defaultTextColor: COLOR.DARK_GRAY,
  options: [],
  selectedValue: SPECIAL_CHARACTER.EMPTY,
  fontFamily: {},
  onItemSelect: () => ({}),
  inputStyle: {},
  customFilter: undefined,
  fontSize: '14px',
  initialContent: SPECIAL_CHARACTER.EMPTY,
  itemsLimited: false,
  isAddress: false,
  isStreetNotFound: false,
  streetNotFoundOnchange: () => {}
};

export default AutoComplete;

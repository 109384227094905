import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { StyledButtonBold } from './StyledElement';
import { FRONTEND_TYPE } from '../../../common/constants';

class Switch extends Component {
  constructor(props) {
    super(props);
    const { options } = this.props;
    this.state = {
      selected: options[0].keyName
    };
  }

  componentDidMount() {
    const { selectedOption } = this.props;
    if (selectedOption.hasOwnProperty('keyName')) {
      this.setState({ selected: selectedOption.keyName });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { selectedOption } = this.props;
    if (selectedOption !== nextProps.selectedOption) {
      this.setState({ selected: nextProps.selectedOption.keyName });
    }
  }

  onChange = key => {
    const { onChange } = this.props;
    this.setState({ selected: key });
    onChange(key);
  };

  render() {
    const { fontTextBold, frontendConfiguration, options, height, buttonClass } = this.props;
    const { selected } = this.state;

    const switchStyle = {
      height: height
    };

    const buttonStyle = {
      color: frontendConfiguration.defaultTextColor,
      borderRadius: frontendConfiguration.borderRadius
    };

    const activeButtonStyle = {
      background:
        frontendConfiguration.frontendType === FRONTEND_TYPE.CONTACT_FORM
          ? frontendConfiguration.buttonBackground
          : frontendConfiguration.primaryColor,
      color:
        frontendConfiguration.frontendType === FRONTEND_TYPE.CONTACT_FORM
          ? frontendConfiguration.buttonTextColor
          : frontendConfiguration.primaryTextColor,
      borderColor:
        frontendConfiguration.frontendType === FRONTEND_TYPE.CONTACT_FORM
          ? frontendConfiguration.buttonBackground
          : frontendConfiguration.primaryColor,
      borderRadius: frontendConfiguration.borderRadius
    };

    return (
      <div className="ep-switch" style={switchStyle}>
        <StyledButtonBold
          data-position="left"
          className={`ep-switch-button ep__txt--md ep__txt-bold ep__txt-no-select ${buttonClass}`}
          font={fontTextBold}
          style={selected === options[0].keyName ? activeButtonStyle : buttonStyle}
          onClick={() => {
            this.onChange(options[0].keyName);
          }}
        >
          {options[0].valueName}
        </StyledButtonBold>
        <StyledButtonBold
          data-position="right"
          className={`ep-switch-button ep__txt--md ep__txt-bold ep__txt-no-select ${buttonClass}`}
          font={fontTextBold}
          style={selected === options[1].keyName ? activeButtonStyle : buttonStyle}
          onClick={() => {
            this.onChange(options[1].keyName);
          }}
        >
          {options[1].valueName}
        </StyledButtonBold>
      </div>
    );
  }
}

Switch.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  selectedOption: PropTypes.object,
  fontTextBold: PropTypes.object,
  frontendConfiguration: PropTypes.object,
  height: PropTypes.string,
  buttonClass: PropTypes.string
};

Switch.defaultProps = {
  options: [],
  onChange: function() {},
  selectedOption: {},
  fontTextBold: {},
  frontendConfiguration: {},
  height: '32px',
  buttonClass: ''
};

function mapStateToProps(state) {
  return {
    frontendConfiguration: state.frontendConfiguration
  };
}

export default connect(mapStateToProps)(Switch);

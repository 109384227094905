export const UPDATE_CUSTOMER_JOURNEY_MAPPING = 'UPDATE_CUSTOMER_JOURNEY_MAPPING';
export const RESET_CUSTOMER_JOURNEY_MAPPING = 'RESET_CUSTOMER_JOURNEY_MAPPING';

export const updateCustomerJourneyMapping = data => ({
  type: UPDATE_CUSTOMER_JOURNEY_MAPPING,
  payload: data
});

export const resetCustomerJourneyMapping = () => ({
  type: RESET_CUSTOMER_JOURNEY_MAPPING
});

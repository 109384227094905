import { PRODUCT_TYPE } from '../constants';

export const selectProductForSingleHouseConnectionCJ = (products, productSelections, packageMappings) => {
  if (packageMappings.hasOwnProperty(PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY) && products.length === 1) {
    productSelections.isSelectedProductHouseConnectionForElectricity = true;
  }
  if (packageMappings.hasOwnProperty(PRODUCT_TYPE.HOUSE_CONNECTION_GAS) && products.length === 1) {
    productSelections.isSelectedProductHouseConnectionForGas = true;
  }
  if (packageMappings.hasOwnProperty(PRODUCT_TYPE.HOUSE_CONNECTION_WATER) && products.length === 1) {
    productSelections.isSelectedProductHouseConnectionForWater = true;
  }
  if (packageMappings.hasOwnProperty(PRODUCT_TYPE.HOUSE_CONNECTION_HEAT) && products.length === 1) {
    productSelections.isSelectedProductHouseConnectionForHeating = true;
  }
  if (packageMappings.hasOwnProperty(PRODUCT_TYPE.HOUSE_CONNECTION_BROADBAND) && products.length === 1) {
    productSelections.isSelectedProductHouseConnectionForBroadband = true;
  }

  return productSelections;
};

import React from 'react';
import * as PropTypes from 'prop-types';
import { Position, Tooltip } from '@blueprintjs/core';
import _ from 'lodash';

import { StyledP, StyledSpan, StyledSpanBold } from '../../epilot-forms/common';
import { ADD_ON_V2_PRODUCT_TYPE, GERMAN_TEXT } from '../../../common/constants';
import { PriceBlock } from './index';

const OtherAddOnV2Summary = ({ fontText, textColor, otherAddOns, removeAddOn }) => {
  const _renderAddOnPriceBlock = addOnSellingOption => {
    if (addOnSellingOption.showPriceOnFrontend) {
      return (
        <StyledSpan font={fontText} style={textColor}>
          {GERMAN_TEXT.ON_REQUEST}
        </StyledSpan>
      );
    }
    return (
      <PriceBlock
        defaultTextColor={textColor.color}
        fontText={fontText}
        oneTimePrice={addOnSellingOption.oneTimeNetPrice}
        monthlyPrice={addOnSellingOption.monthlyNetPrice}
        yearlyPrice={addOnSellingOption.yearlyNetPrice}
      />
    );
  };
  return (
    <>
      <hr className="col-12 ep-bg-little-dark-grey" />
      <div className="col-12 packages">
        <StyledP
          font={fontText}
          className="ep-opacity-50 ep__txt--sm ep__txt-bold ep--my-24"
          style={textColor}
        >
          {'ANDERE ZUSATZOPTIONEN'}
        </StyledP>
        {_.map(otherAddOns, otherAddOn => {
          return (
            <div className="row ep--mt-16" key={otherAddOn.addOnId}>
              {/* ADD-ON NAME*/}
              <div className="col-6 ep__txt--md ep__txt-bold ep--mb-0 text-break">
                <Tooltip
                  content={
                    <StyledSpanBold font={fontText} style={textColor}>
                      {otherAddOn.addOnName}
                    </StyledSpanBold>
                  }
                  position={Position.BOTTOM}
                  tooltipClassName="ep-tooltip ep-tooltip-width-300"
                >
                  <StyledSpanBold font={fontText} style={textColor}>
                    {otherAddOn.addOnName}
                  </StyledSpanBold>
                </Tooltip>
              </div>

              {/* PRICE */}
              <div className="col-5 text-right ep--mb-0 ep__txt--md ep__txt-bold ep-ellipsis">
                <Tooltip
                  content={_renderAddOnPriceBlock(otherAddOn.addOnSellingOption)}
                  position={Position.BOTTOM}
                  tooltipClassName="ep-tooltip ep-tooltip-width-300"
                >
                  {_renderAddOnPriceBlock(otherAddOn.addOnSellingOption)}
                </Tooltip>
              </div>
              {/* REMOVE ADD-ON */}
              <div className="col-1 pl-1 pr-0 pt-1">
                <i
                  className="ep-icon-simple-remove ep-cursor-pointer ep-font-size-18"
                  style={textColor}
                  onClick={() => removeAddOn(ADD_ON_V2_PRODUCT_TYPE.GLOBAL, otherAddOn.addOnId)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

OtherAddOnV2Summary.propTypes = {
  fontText: PropTypes.object.isRequired,
  textColor: PropTypes.object.isRequired,
  otherAddOns: PropTypes.array.isRequired,
  removeAddOn: PropTypes.func.isRequired
};

export default OtherAddOnV2Summary;

import { formatNumber } from './FormatNumberUtil';
import { PRODUCT_TYPE } from '../constants';

export const formatSpecification = (productType, packageSpecification) => {
  switch (productType) {
    case PRODUCT_TYPE.SOLAR:
      return (
        'Leistung: ' +
        formatNumber(packageSpecification[0].value) +
        ' ' +
        packageSpecification[0].unit
      );
    case PRODUCT_TYPE.STORAGE:
      return (
        'Speicherkapazität: ' +
        formatNumber(packageSpecification[0].value) +
        ' ' +
        packageSpecification[0].unit
      );
    case PRODUCT_TYPE.CHARGE:
      return (
        'Ladekapazität: ' +
        formatNumber(packageSpecification[0].value) +
        ' ' +
        packageSpecification[0].unit
      );
    case PRODUCT_TYPE.POWER:
      return (
        'Max. jährlicher Verbrauch: ' +
        formatNumber(packageSpecification[0].value) +
        ' ' +
        packageSpecification[0].unit
      );
    case PRODUCT_TYPE.GAS:
      return (
        'Max. jährlicher Verbrauch: ' +
        formatNumber(packageSpecification[0].value) +
        ' ' +
        packageSpecification[0].unit
      );
    case PRODUCT_TYPE.HEATING:
      return (
        'Leistung: ' +
        formatNumber(packageSpecification[0].value) +
        ' ' +
        packageSpecification[0].unit
      );
    case PRODUCT_TYPE.CHP:
      return (
        'Leistung: ' +
        formatNumber(packageSpecification[0].value) +
        ' ' +
        packageSpecification[0].unit +
        '/ ' +
        formatNumber(packageSpecification[1].value) +
        ' ' +
        packageSpecification[1].unit
      );
    case PRODUCT_TYPE.HEATPUMP:
      return (
        'Wärmeleistung: ' +
        formatNumber(packageSpecification[2].value) +
        ' ' +
        packageSpecification[2].unit
      );
    case PRODUCT_TYPE.SOLAR_B2B:
      return (
        'Leistung: ' +
        formatNumber(packageSpecification[0].value) +
        ' ' +
        packageSpecification[0].unit
      );
    case PRODUCT_TYPE.CHARGE_B2B:
      return (
        'Ladekapazität: ' +
        formatNumber(packageSpecification[0].value) +
        ' ' +
        packageSpecification[0].unit
      );
    default:
      return '-';
  }
};

import { UPDATE_ROUTER_PARAM } from '../actions';

const initState = {
  isPreviewCheckoutPage: 'false',
  isPreviewThankyouScreen: 'false',
  previewMixedOrder: 'false',
  cache: 'true',
  allow_submit: 'true',
  token: '',
  previewOnlinePurchase: null
};

export default (state = initState, action) => {
  switch (action.type) {
    case UPDATE_ROUTER_PARAM:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

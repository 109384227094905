import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'formdata-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/store';

Bugsnag.start({
  apiKey: 'bc0c92803e6732266e767dd36a3fff38',
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_STAGE || 'development',
  enabledReleaseStages: ['production', 'staging', 'development']
});

const store = configureStore();

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const render = Component => {
  // eslint-disable-next-line react/no-render-return-value
  return ReactDOM.render(
    <ErrorBoundary>
      <Provider store={store}>
        <BrowserRouter>
          <Component />
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>,
    document.getElementById('root')
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

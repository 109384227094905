import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import $ from 'jquery';
import styled from 'styled-components';
import classNames from 'classnames';
import { Position, Radio, Tooltip } from '@blueprintjs/core';
import { CheckBox, StyledSpan } from '../../epilot-forms/common';
import { COLOR } from '../../../common/constants';
import AddonCheckboxPopup from '../AddonCheckboxPopup';

const RadioBtn = styled.div`
  .pt-control {
    margin-bottom: 0px !important;
  }
`;

const AddonItem = ({
  removeAutomaticSelectedAddon,
  isAutomaticSelected,
  addonId,
  addonPrice,
  addonName,
  handleSelectAddon,
  isSelected,
  isDisableAddonCheckbox,
  textColor,
  primaryColor,
  defaultTextColor,
  fontText,
  useRadioButton,
  radioBtnClassName
}) => {
  const container = classNames({
    row: true,
    'ep--mt-8': useRadioButton,
    'ep--mt-16': !useRadioButton
  });
  const checkboxContainer = classNames({
    'col-1': true,
    'offset-1': useRadioButton,
    'd-flex': true,
    'align-items-center': true,
    'ep--pl-16': true,
    'ep--pr-0': true
  });
  const addonNameContainer = classNames({
    'col-8': !useRadioButton,
    'col-7': useRadioButton,
    'd-flex': true,
    'align-items-center': true
  });

  const addonNameFontSize = classNames({
    'ep__txt--md': !useRadioButton,
    'ep__txt--sm': useRadioButton,
    'ep__txt-regular': true
  });

  const addonPriceFontSize = classNames({
    'ep__txt--md': !useRadioButton,
    'ep__txt--sm': useRadioButton,
    'ep__txt-regular': true,
    'text-right': true
  });

  useEffect(() => {
    if (`addon-${isSelected}-${addonId}` === `addon-true-${addonId}`) {
      $(`.addon-true-${addonId}`)
        .find('span')
        .css({ 'background-color': primaryColor });
    } else {
      $(`.addon-false-${addonId}`)
        .find('span')
        .css({ 'background-color': COLOR.WHITE });
      $(`.addon-null-${addonId}`)
        .find('span')
        .css({ 'background-color': COLOR.WHITE });
    }
  }, [isSelected, addonId, primaryColor]);

  return (
    <div className={container}>
      {useRadioButton ? (
        <div className={checkboxContainer}>
          <RadioBtn>
            <Radio
              key={addonId}
              className={radioBtnClassName}
              checked={isSelected}
              onChange={handleSelectAddon}
              disabled={isDisableAddonCheckbox}
            />
          </RadioBtn>
        </div>
      ) : (
        <div className={checkboxContainer}>
          <Tooltip
            isOpen={isAutomaticSelected}
            content={
              <AddonCheckboxPopup
                btnAction={() => removeAutomaticSelectedAddon(addonId)}
                btnText="Schließen"
                popupContent="Die Zusatzoption wurde entsprechend des ausgewählten Paketes aktualisiert."
                defaultTextColor={defaultTextColor}
              />
            }
            tooltipClassName="ep-tooltip"
            position={Position.RIGHT}
          >
            <CheckBox
              onChange={handleSelectAddon}
              isChecked={isSelected}
              disabled={isDisableAddonCheckbox}
              primaryTextColor={textColor}
              defaultTextColor={defaultTextColor}
              primaryCheckBoxIconColor={primaryColor}
              fontText={fontText}
            />
          </Tooltip>
        </div>
      )}
      <div className={addonNameContainer}>
        <StyledSpan
          font={fontText}
          className={addonNameFontSize}
          style={{ color: defaultTextColor }}
        >
          {addonName}
        </StyledSpan>
      </div>
      <div className="col-3 d-flex align-items-center justify-content-end">
        <StyledSpan
          font={fontText}
          className={addonPriceFontSize}
          style={{ color: defaultTextColor }}
        >
          {addonPrice}
        </StyledSpan>
      </div>
    </div>
  );
};

AddonItem.propTypes = {
  isDisableAddonCheckbox: PropTypes.bool,
  addonName: PropTypes.string,
  isSelected: PropTypes.bool,
  isAutomaticSelected: PropTypes.bool,
  useRadioButton: PropTypes.bool,
  fontText: PropTypes.object,
  textColor: PropTypes.string,
  addonPrice: PropTypes.string,
  defaultTextColor: PropTypes.string,
  primaryColor: PropTypes.string,
  handleSelectAddon: PropTypes.func,
  removeAutomaticSelectedAddon: PropTypes.func.isRequired,
  radioBtnClassName: PropTypes.string,
  addonId: PropTypes.number
};

AddonItem.defaultProps = {
  fontText: {},
  textColor: COLOR.BLACK,
  handleSelectAddon: () => ({}),
  isDisableAddonCheckbox: false,
  useRadioButton: false,
  isSelected: false,
  isAutomaticSelected: false,
  addonPrice: '',
  addonName: '',
  defaultTextColor: COLOR.DARK_GRAY,
  primaryColor: COLOR.DEFAULT_BLUE,
  radioBtnClassName: '',
  addonId: 0
};

export default AddonItem;

import React from 'react';
import * as PropTypes from 'prop-types';
import { ShowIfUtil } from './ShowIfUtil';
import { PRODUCT_TYPE } from '../constants';

export const ShowIconByProductTypeUtil = ({ productType, color }) => {
  const colorTmp = {
    color
  };

  return (
    <div>
      <ShowIfUtil condition={productType === PRODUCT_TYPE.SOLAR}>
        <i className="epc-icon-solar ep__txt--ic" style={colorTmp} />
      </ShowIfUtil>
      <ShowIfUtil condition={productType === PRODUCT_TYPE.STORAGE}>
        <i className="epc-icon-storage ep__txt--ic" style={colorTmp} />
      </ShowIfUtil>
      <ShowIfUtil condition={productType === PRODUCT_TYPE.CHARGE}>
        <i className="epc-icon-charge ep__txt--ic" style={colorTmp} />
      </ShowIfUtil>
      <ShowIfUtil condition={productType === PRODUCT_TYPE.POWER}>
        <i className="epc-icon-power ep__txt--ic" style={colorTmp} />
      </ShowIfUtil>
      <ShowIfUtil condition={productType === PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY}>
        <i className="epc-icon-house-electricity ep__txt--ic" style={colorTmp} />
      </ShowIfUtil>
      <ShowIfUtil condition={productType === PRODUCT_TYPE.HOUSE_CONNECTION_GAS}>
        <i className="epc-icon-house-gas ep__txt--ic" style={colorTmp} />
      </ShowIfUtil>
      <ShowIfUtil condition={productType === PRODUCT_TYPE.HOUSE_CONNECTION_WATER}>
        <i className="epc-icon-house-water ep__txt--ic" style={colorTmp} />
      </ShowIfUtil>
      <ShowIfUtil condition={productType === PRODUCT_TYPE.HOUSE_CONNECTION_HEAT}>
        <i className="epc-icon-house-heating ep__txt--ic" style={colorTmp} />
      </ShowIfUtil>
      <ShowIfUtil condition={productType === PRODUCT_TYPE.HOUSE_CONNECTION_BROADBAND}>
        <i className="epc-icon-house-broadband ep__txt--ic" style={colorTmp} />
      </ShowIfUtil>
      <ShowIfUtil condition={productType === PRODUCT_TYPE.HEATING}>
        <i className="epc-icon-heating ep__txt--ic" style={colorTmp} />
      </ShowIfUtil>
      <ShowIfUtil condition={productType === PRODUCT_TYPE.CHP}>
        <i className="epc-icon-chp ep__txt--ic" style={colorTmp} />
      </ShowIfUtil>
      <ShowIfUtil condition={productType === PRODUCT_TYPE.HEATPUMP}>
        <i className="epc-icon-heatpump ep__txt--ic" style={colorTmp} />
      </ShowIfUtil>
      <ShowIfUtil condition={productType === PRODUCT_TYPE.CARSHARING}>
        <i className="epc-icon-carsharing ep__txt--ic" style={colorTmp} />
      </ShowIfUtil>
      <ShowIfUtil condition={productType === PRODUCT_TYPE.GAS}>
        <i className="epc-icon-gas ep__txt--ic" style={colorTmp} />
      </ShowIfUtil>
      <ShowIfUtil condition={productType === PRODUCT_TYPE.SOLAR_B2B}>
        <i className="epc-icon-solar-b2b ep__txt--ic" style={colorTmp} />
      </ShowIfUtil>
      <ShowIfUtil condition={productType === PRODUCT_TYPE.CHARGE_B2B}>
        <i className="epc-icon-charge-b2b ep__txt--ic" style={colorTmp} />
      </ShowIfUtil>
    </div>
  );
};

ShowIconByProductTypeUtil.propTypes = {
  productType: PropTypes.string.isRequired,
  color: PropTypes.string
};

ShowIconByProductTypeUtil.defaultProps = {
  color: '#222'
};

import { createSelector } from 'reselect';
import _ from 'lodash';
import { getAddOnV2TotalNetPrice } from '../../common/utils';

const getSelectedAddOnV2MapKeyByProductType = state =>
  state.addOnV2Selections.selectedAddOnV2MapKeyByProductType;

export const addOnV2TotalPriceSelector = createSelector(
  [getSelectedAddOnV2MapKeyByProductType],
  selectedAddOnV2MapKeyByProductType =>
    _.reduce(
      selectedAddOnV2MapKeyByProductType,
      (result, value, key) => {
        if (!value || value.length === 0) return result;
        result[key] = _.reduce(
          value,
          (result, value) => {
            if (value.addOnSellingOption) {
              if (
                value.addOnSellingOption.oneTimePrice &&
                !value.addOnSellingOption.showPriceOnFrontend
              ) {
                result.oneTimePrice += value.addOnSellingOption.oneTimePrice;
              }
              if (
                value.addOnSellingOption.monthlyPrice &&
                !value.addOnSellingOption.showPriceOnFrontend
              ) {
                result.monthlyPrice += value.addOnSellingOption.monthlyPrice;
              }
              if (
                value.addOnSellingOption.yearlyPrice &&
                !value.addOnSellingOption.showPriceOnFrontend
              ) {
                result.yearlyPrice += value.addOnSellingOption.yearlyPrice;
              }
            }
            return result;
          },
          {
            oneTimePrice: null,
            monthlyPrice: null,
            yearlyPrice: null
          }
        );
        return result;
      },
      {}
    )
);

export const addOnV2TotalNetPriceSelector = createSelector(
  [getSelectedAddOnV2MapKeyByProductType],
  selectedAddOnV2MapKeyByProductType => {
    return getAddOnV2TotalNetPrice(selectedAddOnV2MapKeyByProductType);
  }
);

import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { Collapse, Position, Tooltip } from '@blueprintjs/core';
import { CheckBox, StyledSpan } from '../../components/epilot-forms/common';
import { COLOR, SPECIAL_CHARACTER } from '../../common/constants';
import { AddonCheckboxPopup, AddonItem } from '../../components/common';

const SingleAddonSelectionGroupContainer = ({
  setRefAddonComponent,
  removeAutomaticSelectedAddon,
  isAutomaticSelected,
  currentSelectedAddon,
  singleSelectionAddons,
  singleSelectionAddonIds,
  isDisableAddonCheckbox,
  calcAddonPrice,
  handleSelectAddon,
  isShowGroupAddon,
  toggleIsShowGroupAddon,
  textColor,
  primaryColor,
  defaultTextColor,
  fontText
}) => {
  const [latestSelectedAddon, setLatestSelectedAddon] = useState(singleSelectionAddons[0] || null);

  useEffect(() => {
    // reset in case of package change
    if (!currentSelectedAddon) {
      setLatestSelectedAddon(singleSelectionAddons[0]);
    }
  }, [currentSelectedAddon, singleSelectionAddons]);

  useEffect(() => {
    if (isAutomaticSelected) {
      if (
        latestSelectedAddon &&
        currentSelectedAddon &&
        latestSelectedAddon.id !== currentSelectedAddon.id
      ) {
        setLatestSelectedAddon(currentSelectedAddon);
      }
    }
  }, [isAutomaticSelected, latestSelectedAddon, currentSelectedAddon]);

  const arrowIconClassName = classNames({
    'ep-position-top-3': true,
    'ep-cursor-pointer': true,
    'ep__txt--sm': true,
    'ep--pt-8': true,
    'ep-icon-minimal-up': isShowGroupAddon,
    'ep-icon-minimal-down': !isShowGroupAddon
  });

  const selectAddonInGroup = selectedAddon => {
    let removeAddonIds = _.without(singleSelectionAddonIds, selectedAddon.id);
    handleSelectAddon(selectedAddon.id, removeAddonIds);
    setLatestSelectedAddon(selectedAddon);
  };

  const selectAddonGroupCheckbox = () => {
    if (currentSelectedAddon) {
      //remove all addon in group
      handleSelectAddon(null, singleSelectionAddonIds);
    } else {
      if (latestSelectedAddon) {
        handleSelectAddon(latestSelectedAddon?.id);
        if (!isShowGroupAddon) {
          toggleIsShowGroupAddon(!isShowGroupAddon);
        }
      } else {
        handleSelectAddon(latestSelectedAddon?.id);
        setLatestSelectedAddon(latestSelectedAddon);
        if (!isShowGroupAddon) {
          toggleIsShowGroupAddon(!isShowGroupAddon);
        }
      }
    }
  };

  const renderAddOnInGroup = () => {
    return _.map(singleSelectionAddons, addon => {
      const isSelected = currentSelectedAddon ? currentSelectedAddon.id === addon.id : false;
      return (
        <AddonItem
          key={addon.id}
          addonId={addon.id}
          addonName={addon.name}
          addonPrice={calcAddonPrice(addon)}
          isSelected={isSelected}
          isDisableAddonCheckbox={isDisableAddonCheckbox}
          handleSelectAddon={() => selectAddonInGroup(addon)}
          textColor={textColor}
          primaryColor={primaryColor}
          defaultTextColor={defaultTextColor}
          fontText={fontText}
          useRadioButton
          radioBtnClassName={`addon-${isSelected}-${addon.id}`}
          removeAutomaticSelectedAddon={removeAutomaticSelectedAddon}
        />
      );
    });
  };

  return (
    <div className="row ep--mt-16">
      <div className="col-1 d-flex align-items-center ep--pl-16 ep--pr-0">
        <Tooltip
          isOpen={isAutomaticSelected}
          content={
            <AddonCheckboxPopup
              btnAction={() =>
                currentSelectedAddon && removeAutomaticSelectedAddon(currentSelectedAddon.id)
              }
              btnText="Schließen"
              popupContent="Die Zusatzoption wurde entsprechend des ausgewählten Paketes aktualisiert."
              defaultTextColor={defaultTextColor}
            />
          }
          tooltipClassName="ep-tooltip"
          position={Position.RIGHT}
        >
          <CheckBox
            onChange={() => selectAddonGroupCheckbox()}
            isChecked={!!currentSelectedAddon}
            disabled={isDisableAddonCheckbox}
            primaryCheckBoxIconColor={primaryColor}
            defaultTextColor={defaultTextColor}
          />
        </Tooltip>
      </div>
      <div className="col-1 d-flex align-items-center ep--pl-16 ep--pr-0">
        <i
          className={arrowIconClassName}
          onClick={() => toggleIsShowGroupAddon(!isShowGroupAddon)}
          style={{ color: defaultTextColor }}
        />
      </div>
      <div className="col-7 d-flex align-items-center">
        <StyledSpan
          font={fontText}
          className="ep__txt--md ep__txt-regular"
          style={{ color: defaultTextColor }}
        >
          {currentSelectedAddon
            ? currentSelectedAddon.name
            : latestSelectedAddon
            ? latestSelectedAddon.name
            : null}
        </StyledSpan>
      </div>
      <div className="col-3 d-flex align-items-center justify-content-end">
        <StyledSpan
          font={fontText}
          className="ep__txt--md ep__txt-regular text-right"
          style={{ color: defaultTextColor }}
        >
          {currentSelectedAddon
            ? `${calcAddonPrice(currentSelectedAddon)}`
            : latestSelectedAddon
            ? `${calcAddonPrice(latestSelectedAddon)}`
            : `0 ${SPECIAL_CHARACTER.EURO}`}
        </StyledSpan>
      </div>
      <div className="col-12" style={{ minHeight: 0 }}>
        <Collapse isOpen={isShowGroupAddon} keepChildrenMounted transitionDuration={0}>
          <div ref={ref => setRefAddonComponent(ref)}>{renderAddOnInGroup()}</div>
        </Collapse>
      </div>
    </div>
  );
};

SingleAddonSelectionGroupContainer.propTypes = {
  singleSelectionAddons: PropTypes.array.isRequired,
  singleSelectionAddonIds: PropTypes.array.isRequired,
  defaultTextColor: PropTypes.string,
  primaryColor: PropTypes.string,
  textColor: PropTypes.string,
  fontText: PropTypes.object,
  currentSelectedAddon: PropTypes.object,
  isDisableAddonCheckbox: PropTypes.bool,
  isShowGroupAddon: PropTypes.bool,
  isAutomaticSelected: PropTypes.bool,
  handleSelectAddon: PropTypes.func,
  setRefAddonComponent: PropTypes.func,
  removeAutomaticSelectedAddon: PropTypes.func.isRequired,
  calcAddonPrice: PropTypes.func,
  toggleIsShowGroupAddon: PropTypes.func
};

SingleAddonSelectionGroupContainer.defaultProps = {
  fontText: {},
  textColor: COLOR.BLACK,
  defaultTextColor: COLOR.DARK_GRAY,
  primaryColor: COLOR.DEFAULT_BLUE,
  isDisableAddonCheckbox: false,
  isAutomaticSelected: false,
  isShowGroupAddon: false,
  currentSelectedAddon: {},
  handleSelectAddon: () => ({}),
  calcAddonPrice: () => ({}),
  setRefAddonComponent: () => ({}),
  toggleIsShowGroupAddon: () => ({})
};

export default React.memo(SingleAddonSelectionGroupContainer);

import { camelizeKeys } from 'humps';
import {
  EXTERNAL_PRODUCT_AVAILABILITY_FAILURE,
  EXTERNAL_PRODUCT_AVAILABILITY_REQUEST,
  EXTERNAL_PRODUCT_AVAILABILITY_SUCCESS,
  RESET_CUSTOMER_JOURNEY_DATA,
  RESET_EXTERNAL_PRODUCT_AVAILABILITY
} from '../actions';
import { SERVICE_REQUEST_STATUS } from '../../common/constants';

const initState = {
  externalServiceRequestStatus: SERVICE_REQUEST_STATUS.NO_REQUEST
};

const externalProductAvailability = (state = initState, action) => {
  switch (action.type) {
    case EXTERNAL_PRODUCT_AVAILABILITY_SUCCESS:
      return {
        ...state,
        ...camelizeKeys(action.payload),
        externalServiceRequestStatus: SERVICE_REQUEST_STATUS.REQUEST_SUCCESS
      };
    case EXTERNAL_PRODUCT_AVAILABILITY_FAILURE:
      return {
        ...state,
        externalServiceRequestStatus: SERVICE_REQUEST_STATUS.REQUEST_FAILURE
      };
    case RESET_CUSTOMER_JOURNEY_DATA:
    case RESET_EXTERNAL_PRODUCT_AVAILABILITY:
    case EXTERNAL_PRODUCT_AVAILABILITY_REQUEST:
      return {
        ...initState
      };
    default:
      return state;
  }
};

export default externalProductAvailability;

import { combineReducers } from 'redux';

import frontendConfigurationReducer from './frontendConfigurationReducer';
import customerJourneyAnswerReducer from './customerJourneyAnswerReducer';
import customerJourneyMappingReducer from './customerJourneyMappingReducer';
import contactFormReducer from './contactFormReducer';
import productSelectionsReducer from './productSelectionsReducer';
import thankYouPageReducer from './thankYouPageReducer';
import productAvailabilityReducer from './productAvailabilityReducer';
import routeReducer from './routeReducer';
import currentStateReducer from './currentStateReducer';
import productPackageConfigReducer from './productPackageConfigReducer';
import addOnV2SelectionReducer from './addOnV2SelectionsReducer';
import productAddOnConfigReducer from './productAddOnConfigReducer';
import paymentInfoReducer from './paymentInfoReducer';
import uploadFileStatus from './uploadFileStatusReducer';

export default combineReducers({
  currentState: currentStateReducer,
  productPackageConfig: productPackageConfigReducer,
  productAddOnConfig: productAddOnConfigReducer,
  frontendConfiguration: frontendConfigurationReducer,
  customerJourneyAnswer: customerJourneyAnswerReducer,
  customerJourneyMapping: customerJourneyMappingReducer,
  contactFormData: contactFormReducer,
  productSelections: productSelectionsReducer,
  addOnV2Selections: addOnV2SelectionReducer,
  thankYouPage: thankYouPageReducer,
  externalProductAvailability: productAvailabilityReducer,
  route: routeReducer,
  paymentInfo: paymentInfoReducer,
  uploadFileStatus: uploadFileStatus
});

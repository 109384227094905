import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { StyledSpan } from './StyledElement';
import { COLOR } from '../../../common/constants';

class NumberSpinner extends Component {
  constructor(props) {
    super(props);
    const { value } = this.props;
    this.state = {
      value: value
    };
  }

  componentWillReceiveProps(nextProps) {
    const { value } = this.props;
    if (nextProps.value !== value) {
      this.setState({
        value: nextProps.value
      });
    }
  }

  plus = async () => {
    const { onChange } = this.props;
    const { value } = this.state;
    this.setState(
      {
        value: value + 1
      },
      () => {
        onChange(value + 1);
      }
    );
  };

  minus = async () => {
    const { value } = this.state;
    const { onChange } = this.props;
    if (value > 1) {
      this.setState(
        {
          value: value - 1
        },
        () => {
          onChange(value - 1);
        }
      );
    }
  };

  render() {
    const { value } = this.state;
    const { fontText, heightSpan, fontSize, fontWeight, defaultTextColor } = this.props;
    const enabled = {
      color: defaultTextColor,
      opacity: '1',
      cursor: 'pointer'
    };

    const disabled = {
      color: COLOR.DARK_GRAY,
      opacity: '0.5',
      cursor: 'not-allowed'
    };

    return (
      <div className="d-flex justify-content-center align-items-center ep-min-width-80 ep--px-16 ep--py-8">
        <i
          className="ep-icon-circle-delete ep__txt--ic ep-font-size-20"
          style={value > 1 ? enabled : disabled}
          onClick={this.minus}
        />

        <StyledSpan
          className="ep__txt--h1 ep__txt-regular ep-width-62 ep__txt-no-select ep--mx-8"
          font={fontText}
          style={{
            color: defaultTextColor,
            height: heightSpan,
            fontSize: fontSize,
            fontWeight: fontWeight
          }}
        >
          {value}
        </StyledSpan>
        <i
          className="ep-icon-circle-add ep__txt--ic ep-font-size-20"
          style={enabled}
          onClick={this.plus}
        />
      </div>
    );
  }
}

NumberSpinner.propTypes = {
  value: PropTypes.number,
  fontText: PropTypes.object,
  onChange: PropTypes.func,
  heightSpan: PropTypes.string,
  fontWeight: PropTypes.string,
  fontSize: PropTypes.string,
  defaultTextColor: PropTypes.string
};

NumberSpinner.defaultProps = {
  value: 1,
  fontText: {},
  heightSpan: '30px',
  fontSize: '16px',
  fontWeight: '600',
  defaultTextColor: COLOR.DARK_GRAY,
  onChange: function() {}
};

export default NumberSpinner;

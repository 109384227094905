import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formatQuestion, ShowIconByQuestionIdUtil } from '../../common/utils';
import { COLOR } from '../../common/constants';
import { DateInput, StyledSpan, InfoHelpDescription } from '../../components/epilot-forms/common';
import { updateCustomerJourneyAnswer } from '../../store/actions';

class DateInputQuestionContainer extends Component {
  onChange = e => {
    const { updateCustomerJourneyAnswer } = this.props;
    let newCustomerJourneyAnswer = {
      installationDate: e
    };
    updateCustomerJourneyAnswer(newCustomerJourneyAnswer);
  };

  render() {
    const {
      question,
      fontText,
      frontendConfiguration,
      customerJourneyAnswer,
      defaultTextColor
    } = this.props;
    const textColor = {
      color: defaultTextColor
    };
    return (
      <div className="ep-customerjourneypage__question">
        <ShowIconByQuestionIdUtil
          questionId={question.id}
          colorIcon={frontendConfiguration.primaryColor}
        />
        <div className="text-center ep-min-height-70 ep--pt-8 ep--pb-16">
          <StyledSpan
            font={fontText}
            className="ep__txt-regular ep__txt--lg ep__txt-no-select"
            style={textColor}
          >
            {formatQuestion(question.questionTitle, customerJourneyAnswer.name)}
          </StyledSpan>
          <InfoHelpDescription
            showHelpDescription={question.showHelpDescription}
            tooltipInfoContent={<StyledSpan font={fontText}>{question.helpDescription}</StyledSpan>}
            classNameForInfoIcon="ep__txt--lg ep--ml-8"
            defaultTextColor={defaultTextColor}
          />
        </div>
        <div className="d-flex justify-content-center ep-min-height-130">
          <DateInput
            value={customerJourneyAnswer.installationDate}
            onChange={this.onChange}
            primaryTextColor={COLOR.DARK_GRAY}
            defaultTextColor={defaultTextColor}
            minDate={new Date()}
          />
        </div>
      </div>
    );
  }
}

DateInputQuestionContainer.propTypes = {
  question: PropTypes.object,
  fontText: PropTypes.object,
  defaultTextColor: PropTypes.string,
  customerJourneyAnswer: PropTypes.object,
  frontendConfiguration: PropTypes.object,
  updateCustomerJourneyAnswer: PropTypes.func
};

DateInputQuestionContainer.defaultProps = {
  question: {},
  fontText: {},
  defaultTextColor: COLOR.DARK_GRAY,
  customerJourneyAnswer: {},
  frontendConfiguration: {},
  updateCustomerJourneyAnswer: () => ({})
};

function mapStateToProps(state) {
  return {
    frontendConfiguration: state.frontendConfiguration,
    customerJourneyAnswer: state.customerJourneyAnswer,
    customerJourneyMapping: state.customerJourneyMapping
  };
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateCustomerJourneyAnswer
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DateInputQuestionContainer);

/* ----- Importing libraries -----*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PropTypes from 'prop-types';
import { parse } from 'query-string';
import { ShowIfUtil } from '../common/utils';
import { StyledSpan, StyledP } from '../components';
import { COLOR, CONNECT_ERROR_PAGE, NOT_FOUND_PAGE, SPECIAL_CHARACTER } from '../common/constants';
import { FRONTEND_CONFIGURATION_FAILURE, getFrontendConfiguration } from '../store/actions';
import { getFontTextBoldSelector, getFontTextSelector } from '../store/selectors';

class CancelPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fontText: {
        font: SPECIAL_CHARACTER.EMPTY,
        fontUrl: SPECIAL_CHARACTER.EMPTY
      },
      fontTextBold: {
        font: SPECIAL_CHARACTER.EMPTY,
        fontUrl: SPECIAL_CHARACTER.EMPTY
      }
    };
  }

  componentDidMount() {
    const {
      location,
      history,
      getFrontendConfiguration,
      frontendConfiguration,
      fontText,
      fontTextBold
    } = this.props;

    const { token } = parse(location.search);
    // Custom font
    this.setState(prevState => ({
      fontText: {
        ...prevState.fontText,
        ...fontText
      }
    }));

    this.setState(prevState => ({
      fontTextBold: {
        ...prevState.fontTextBold,
        font: fontTextBold.font
      }
    }));

    if (token) {
      getFrontendConfiguration({ token }).then(action => {
        switch (action.type) {
          case FRONTEND_CONFIGURATION_FAILURE:
            history.push(CONNECT_ERROR_PAGE);
            break;
          default:
            break;
        }
      });
    } else {
      if (!frontendConfiguration) {
        history.push(NOT_FOUND_PAGE);
      }
    }
  }

  render() {
    const { fontText, fontTextBold } = this.state;
    const { frontendConfiguration } = this.props;
    const primaryColor = {
      color: frontendConfiguration ? frontendConfiguration.primaryColor : COLOR.DEFAULT_BLUE
    };
    return (
      <div className="text-center ep--p-32" style={{ backgroundColor: COLOR.WHITE }}>
        <ShowIfUtil
          condition={
            frontendConfiguration !== undefined &&
            frontendConfiguration.cancelScreenCJDefaultInfo !== undefined
          }
        >
          <div className="ep-success-wrapper-icon" style={primaryColor}>
            <i className="epc-icon-construction-sign" />
          </div>
          <StyledP
            font={fontTextBold}
            className="ep__txt--h1 ep__txt-semibold text-center ep--mt-32 ep--mb-0 ep-text-black"
          >
            {frontendConfiguration && frontendConfiguration.cancelScreenCJDefaultInfo
              ? frontendConfiguration.cancelScreenCJDefaultInfo.title
              : null}
          </StyledP>
          <div className="text-center ep--mt-32">
            <StyledSpan
              font={fontText}
              className="ep__txt--md ep__txt-semibold ep-text-light-black ep--mb-0 d-inline-block"
              style={{ maxWidth: '26.875rem' }}
            >
              {frontendConfiguration && frontendConfiguration.cancelScreenCJDefaultInfo
                ? frontendConfiguration.cancelScreenCJDefaultInfo.body
                : null}
            </StyledSpan>
          </div>
          <div className="text-center ep--mt-32">
            <StyledSpan
              font={fontTextBold}
              className="ep__txt--sm ep__txt-semibold ep-text-dark-blue ep-cursor-pointer"
            >
              {frontendConfiguration && frontendConfiguration.cancelScreenCJDefaultInfo
                ? frontendConfiguration.cancelScreenCJDefaultInfo.hyperlinkText
                : null}
            </StyledSpan>
          </div>
        </ShowIfUtil>
      </div>
    );
  }
}

CancelPage.propTypes = {
  frontendConfiguration: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  fontText: PropTypes.object,
  fontTextBold: PropTypes.object,
  getFrontendConfiguration: PropTypes.func.isRequired,
  history: PropTypes.object,
  location: PropTypes.object
};

CancelPage.defaultProps = {
  frontendConfiguration: {},
  fontText: {},
  fontTextBold: {},
  history: {},
  location: {}
};

function mapStateToProps(state) {
  return {
    frontendConfiguration: state.frontendConfiguration,
    fontText: getFontTextSelector(state),
    fontTextBold: getFontTextBoldSelector(state)
  };
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators(
    {
      getFrontendConfiguration
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CancelPage);

import { createSelector } from 'reselect';
import _ from 'lodash';
import {
  KILOWATT_COMPONENT,
  KILOWATT_HOUR_COMPONENT,
  LITER_PER_SECOND_COMPONENT,
  METER_COMPONENT,
  STREET_FRONT_LENGTH,
  PROPERTY_SIZE,
  FLOOR_SIZE,
  HOUSING_UNITS,
  PRODUCT_TYPE,
  PRODUCT_TYPE_CURR_PACKAGE_MAPPING,
  HEATING_CAPACITY_COMPONENT
} from '../../common/constants';
import {
  currentStateSelector,
  KWConsumptionForElectricitySelector,
  KWConsumptionForGasSelector,
  KWHConsumptionSelector,
  KWConsumptionForHeatingSelector,
  litterPerSecConsumptionSelector,
  meterConsumptionSelector,
  meterStreetFrontLengthForHCWaterConsumptionSelector,
  squareMetrePropertySizeForHCWaterConsumptionSelector,
  squareMetreFloorSizeForHCWaterConsumptionSelector,
  housingUnitForHCWaterConsumptionSelector,
  selectedProductsSelector
} from '.';

const getSelectedProducts = state => selectedProductsSelector(state);
const getCurrentState = state => currentStateSelector(state);
const getKWConsumption = state => KWConsumptionForElectricitySelector(state);
const getKWConsumptionHCGas = state => KWConsumptionForGasSelector(state);
const getKWConsumptionHCHeating = state => KWConsumptionForHeatingSelector(state);
const getKWHConsumption = state => KWHConsumptionSelector(state);
const getLitterPerSecConsumption = state => litterPerSecConsumptionSelector(state);
const getMeterConsumption = state => meterConsumptionSelector(state);
const getMeterStreetFrontLengthForHCWaterConsumption = state =>
  meterStreetFrontLengthForHCWaterConsumptionSelector(state);
const getSquareMetrePropertySizeForHCWaterConsumption = state =>
  squareMetrePropertySizeForHCWaterConsumptionSelector(state);
const getSquareMetreFloorSizeForHCWaterConsumption = state =>
  squareMetreFloorSizeForHCWaterConsumptionSelector(state);
const getHousingUnitForHCWaterConsumption = state =>
  housingUnitForHCWaterConsumptionSelector(state);
const getCurrQuestions = state => state.currentState.currQuestions;

export const createOPPackageV2RequestParamsSelector = createSelector(
  [
    getSelectedProducts,
    getCurrentState,
    getKWConsumption,
    getKWConsumptionHCGas,
    getKWConsumptionHCHeating,
    getKWHConsumption,
    getLitterPerSecConsumption,
    getMeterConsumption,
    getMeterStreetFrontLengthForHCWaterConsumption,
    getSquareMetrePropertySizeForHCWaterConsumption,
    getSquareMetreFloorSizeForHCWaterConsumption,
    getHousingUnitForHCWaterConsumption,
    getCurrQuestions
  ],
  (
    selectedProducts,
    currentState,
    kwConsumption,
    kwConsumptionHCGas,
    kwConsumptionHCHeating,
    kwhConsumption,
    litterPerSecConsumption,
    meterConsumption,
    meterStreetFrontLengthForHCWaterConsumption,
    squareMetrePropertySizeForHCWaterConsumption,
    squareMetreFloorSizeForHCWaterConsumption,
    housingUnitForHCWaterConsumption
  ) => {
    const createOPPackageV2RequestParams = {
      opportunityPackageApiModels: [],
      variableModels: {},
      onlinePurchaseOpportunityPackageApiModels: [],
      onlinePurchaseVariableModels: {},
      orderRequestOpportunityPackageApiModels: [],
      orderRequestVariableModels: {}
    };
    _.forEach(selectedProducts, selectedProduct => {
      const currPackageKey = PRODUCT_TYPE_CURR_PACKAGE_MAPPING[selectedProduct.type];
      if (currentState[currPackageKey]) {
        const opportunityPackageApiData = {
          productId: selectedProduct.id,
          productPackageId: !currentState[currPackageKey].isPackageAlwaysShowOnRequest
            ? currentState[currPackageKey].packageId
            : null,
          productSellingOptionId: !currentState[currPackageKey].isPackageAlwaysShowOnRequest
            ? currentState[currPackageKey].sellingOptionId
            : null,
          variablePackagePricingComponentIds: !currentState[currPackageKey]
            .isPackageAlwaysShowOnRequest
            ? currentState[currPackageKey].variableCompIds || []
            : []
        };
        if (
          currentState[currPackageKey].packageId &&
          !currentState[currPackageKey].isPackageAlwaysShowOnRequest
        ) {
          createOPPackageV2RequestParams.onlinePurchaseOpportunityPackageApiModels.push(
            opportunityPackageApiData
          );
        } else {
          createOPPackageV2RequestParams.orderRequestOpportunityPackageApiModels.push(
            opportunityPackageApiData
          );
        }
        switch (selectedProduct.type) {
          case PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY:
            const electricityVariableModels = {
              [selectedProduct.type]: [
                {
                  type: METER_COMPONENT,
                  value: Number(meterConsumption)
                },
                {
                  type: KILOWATT_COMPONENT,
                  value: Number(kwConsumption)
                }
              ]
            };
            if (
              currentState[currPackageKey].packageId &&
              !currentState[currPackageKey].isPackageAlwaysShowOnRequest
            ) {
              createOPPackageV2RequestParams.onlinePurchaseVariableModels = _.assignIn(
                createOPPackageV2RequestParams.onlinePurchaseVariableModels,
                electricityVariableModels
              );
            } else {
              createOPPackageV2RequestParams.orderRequestVariableModels = _.assignIn(
                createOPPackageV2RequestParams.orderRequestVariableModels,
                electricityVariableModels
              );
            }
            break;
          case PRODUCT_TYPE.HOUSE_CONNECTION_GAS:
            const gasVariableModels = {
              [selectedProduct.type]: [
                {
                  type: METER_COMPONENT,
                  value: Number(meterConsumption)
                },
                {
                  type: KILOWATT_COMPONENT,
                  value: Number(kwConsumptionHCGas)
                }
              ]
            };
            if (
              currentState[currPackageKey].packageId &&
              !currentState[currPackageKey].isPackageAlwaysShowOnRequest
            ) {
              createOPPackageV2RequestParams.onlinePurchaseVariableModels = _.assignIn(
                createOPPackageV2RequestParams.onlinePurchaseVariableModels,
                gasVariableModels
              );
            } else {
              createOPPackageV2RequestParams.orderRequestVariableModels = _.assignIn(
                createOPPackageV2RequestParams.orderRequestVariableModels,
                gasVariableModels
              );
            }
            break;
          case PRODUCT_TYPE.HOUSE_CONNECTION_WATER:
            const waterVariableModels = {
              [selectedProduct.type]: [
                {
                  type: METER_COMPONENT,
                  value: Number(meterConsumption)
                },
                {
                  type: LITER_PER_SECOND_COMPONENT,
                  value: Number(litterPerSecConsumption)
                },
                {
                  type: HOUSING_UNITS,
                  value: Number(housingUnitForHCWaterConsumption)
                },
                {
                  type: STREET_FRONT_LENGTH,
                  value: Number(meterStreetFrontLengthForHCWaterConsumption)
                },
                {
                  type: PROPERTY_SIZE,
                  value: Number(squareMetrePropertySizeForHCWaterConsumption)
                },
                {
                  type: FLOOR_SIZE,
                  value: Number(squareMetreFloorSizeForHCWaterConsumption)
                }
              ]
            };
            if (
              currentState[currPackageKey].packageId &&
              !currentState[currPackageKey].isPackageAlwaysShowOnRequest
            ) {
              createOPPackageV2RequestParams.onlinePurchaseVariableModels = _.assignIn(
                createOPPackageV2RequestParams.onlinePurchaseVariableModels,
                waterVariableModels
              );
            } else {
              createOPPackageV2RequestParams.orderRequestVariableModels = _.assignIn(
                createOPPackageV2RequestParams.orderRequestVariableModels,
                waterVariableModels
              );
            }
            break;
          case PRODUCT_TYPE.HEATING:
            const heatingVariableModels = {
              [selectedProduct.type]: [
                {
                  type: METER_COMPONENT,
                  value: Number(meterConsumption)
                }
              ]
            };
            if (
              currentState[currPackageKey].packageId &&
              !currentState[currPackageKey].isPackageAlwaysShowOnRequest
            ) {
              createOPPackageV2RequestParams.onlinePurchaseVariableModels = _.assignIn(
                createOPPackageV2RequestParams.onlinePurchaseVariableModels,
                heatingVariableModels
              );
            } else {
              createOPPackageV2RequestParams.orderRequestVariableModels = _.assignIn(
                createOPPackageV2RequestParams.orderRequestVariableModels,
                heatingVariableModels
              );
            }
            break;
          case PRODUCT_TYPE.CHP:
            const CHPVariableModels = {
              [selectedProduct.type]: [
                {
                  type: KILOWATT_COMPONENT,
                  value: Number(kwConsumption)
                }
              ]
            };
            if (
              currentState[currPackageKey].packageId &&
              !currentState[currPackageKey].isPackageAlwaysShowOnRequest
            ) {
              createOPPackageV2RequestParams.onlinePurchaseVariableModels = _.assignIn(
                createOPPackageV2RequestParams.onlinePurchaseVariableModels,
                CHPVariableModels
              );
            } else {
              createOPPackageV2RequestParams.orderRequestVariableModels = _.assignIn(
                createOPPackageV2RequestParams.orderRequestVariableModels,
                CHPVariableModels
              );
            }
            break;
          case PRODUCT_TYPE.STORAGE:
          case PRODUCT_TYPE.SOLAR:
          case PRODUCT_TYPE.POWER:
          case PRODUCT_TYPE.GAS:
            const kwhProductsVariableModels = {
              [selectedProduct.type]: [
                {
                  type: KILOWATT_HOUR_COMPONENT,
                  value: Number(kwhConsumption)
                }
              ]
            };
            if (
              currentState[currPackageKey].packageId &&
              !currentState[currPackageKey].isPackageAlwaysShowOnRequest
            ) {
              createOPPackageV2RequestParams.onlinePurchaseVariableModels = _.assignIn(
                createOPPackageV2RequestParams.onlinePurchaseVariableModels,
                kwhProductsVariableModels
              );
            } else {
              createOPPackageV2RequestParams.orderRequestVariableModels = _.assignIn(
                createOPPackageV2RequestParams.orderRequestVariableModels,
                kwhProductsVariableModels
              );
            }
            break;
          case PRODUCT_TYPE.HOUSE_CONNECTION_BROADBAND:
            const broadbandVariableModels = {
              [selectedProduct.type]: [
                {
                  type: METER_COMPONENT,
                  value: Number(meterConsumption)
                }
              ]
            };
            if (
              currentState[currPackageKey].packageId &&
              !currentState[currPackageKey].isPackageAlwaysShowOnRequest
            ) {
              createOPPackageV2RequestParams.onlinePurchaseVariableModels = _.assignIn(
                createOPPackageV2RequestParams.onlinePurchaseVariableModels,
                broadbandVariableModels
              );
            } else {
              createOPPackageV2RequestParams.orderRequestVariableModels = _.assignIn(
                createOPPackageV2RequestParams.orderRequestVariableModels,
                broadbandVariableModels
              );
            }
            break;
          case PRODUCT_TYPE.HOUSE_CONNECTION_HEAT:
            const hcHeatVariableModels = {
              [selectedProduct.type]: [
                {
                  type: METER_COMPONENT,
                  value: Number(meterConsumption)
                },
                {
                  type: HEATING_CAPACITY_COMPONENT,
                  value: Number(kwConsumptionHCHeating)
                }
              ]
            };
            if (
              currentState[currPackageKey].packageId &&
              !currentState[currPackageKey].isPackageAlwaysShowOnRequest
            ) {
              createOPPackageV2RequestParams.onlinePurchaseVariableModels = _.assignIn(
                createOPPackageV2RequestParams.onlinePurchaseVariableModels,
                hcHeatVariableModels
              );
            } else {
              createOPPackageV2RequestParams.orderRequestVariableModels = _.assignIn(
                createOPPackageV2RequestParams.orderRequestVariableModels,
                hcHeatVariableModels
              );
            }
            break;
          default:
            break;
        }
      }
    });
    createOPPackageV2RequestParams.opportunityPackageApiModels = [
      ...createOPPackageV2RequestParams.onlinePurchaseOpportunityPackageApiModels,
      ...createOPPackageV2RequestParams.orderRequestOpportunityPackageApiModels
    ];
    createOPPackageV2RequestParams.variableModels = {
      ...createOPPackageV2RequestParams.onlinePurchaseVariableModels,
      ...createOPPackageV2RequestParams.orderRequestVariableModels
    };
    return createOPPackageV2RequestParams;
  }
);

import { CALL_API } from '../middlewares/customMiddleware';
import { API_URL_VO } from '../../common/settings/ApiUrlSettings';

export const CREATE_OPPORTUNITY_REQUEST = 'CREATE_OPPORTUNITY_REQUEST';
export const CREATE_OPPORTUNITY_SUCCESS = 'CREATE_OPPORTUNITY_SUCCESS';
export const CREATE_OPPORTUNITY_FAILURE = 'CREATE_OPPORTUNITY_FAILURE';
export const OPPORTUNITY_URL = '/opportunities';

export const FRONTEND_RESPONSE_ID = 'frontend_response_id';
export const OPPORTUNITY_ID = 'opportunity_id';
export const FILE_NAME = 'filename';
export const FILE_NAME_REPLACE = '[[opp_id]]';
export const PDF_URL = '/fl-pdf';

export const createOpportunity = params => dispatch => {
  const fullOpportunityUrl = `${API_URL_VO}${OPPORTUNITY_URL}`;
  return dispatch({
    [CALL_API]: {
      types: [CREATE_OPPORTUNITY_REQUEST, CREATE_OPPORTUNITY_SUCCESS, CREATE_OPPORTUNITY_FAILURE],
      endpoint: fullOpportunityUrl,
      method: 'POST',
      params: params
    }
  });
};

export const CREATE_PDF_REQUEST = 'CREATE_PDF_REQUEST';
export const CREATE_PDF_SUCCESS = 'CREATE_PDF_SUCCESS';
export const CREATE_PDF_FAILURE = 'CREATE_PDF_FAILURE';
export const createPdfResponses = ({
  frontendResponseId,
  opportunityId,
  symbolId,
  fileName
}) => dispatch => {
  const filenameReplace = fileName.includes(FILE_NAME_REPLACE)
    ? fileName.replace(FILE_NAME_REPLACE, symbolId)
    : fileName;
  const fullPDFUrl = `${API_URL_VO}${PDF_URL}?${FRONTEND_RESPONSE_ID}=${frontendResponseId}&${OPPORTUNITY_ID}=${opportunityId}&${FILE_NAME}=${filenameReplace}.pdf`;
  return dispatch({
    [CALL_API]: {
      types: [CREATE_PDF_REQUEST, CREATE_PDF_SUCCESS, CREATE_PDF_FAILURE],
      endpoint: encodeURI(fullPDFUrl),
      method: 'POST'
    }
  });
};

import React from 'react';
import {
  GoogleMap,
  InfoWindow,
  KmlLayer,
  Marker,
  withGoogleMap,
  withScriptjs
} from 'react-google-maps';
import Geocode from 'react-geocode';
import * as PropTypes from 'prop-types';

Geocode.setApiKey('AIzaSyAJYgU_yutTY172LEjBhVOWoPUnPUkPMhI');
Geocode.enableDebug();

class Map extends React.Component {
  constructor(props) {
    super(props);
    const { zoom } = this.props;
    this.state = {
      address: '',
      city: '',
      area: '',
      state: '',
      country: '',
      mapPosition: {
        lat: 0,
        lng: 0
      },
      markerPosition: {
        lat: 0,
        lng: 0
      },
      zoom: zoom,
      mapTypeId: 'roadmap'
    };

    this._map = '';
  }

  componentDidMount() {
    const { query, addressData, isAddressChange } = this.props;
    const { lat, lng } = addressData;
    if (lat && lng && !isAddressChange) {
      this.updateMapByLatLng(lat, lng);
    } else {
      this.searchLocation(query);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { query } = this.props;
    if (nextProps.query !== query) {
      this.searchLocation(query);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { markerPosition, address, city, area, state, country } = this.state;
    if (
      markerPosition.lat !== nextState.markerPosition.lat ||
      address !== nextState.address ||
      city !== nextState.city ||
      area !== nextState.area ||
      state !== nextState.state ||
      country !== nextState.country
    ) {
      return true;
    } else if (markerPosition.lat === nextState.markerPosition.lat) {
      return false;
    }
  }

  getCity = addressArray => {
    let city = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  getArea = addressArray => {
    let area = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            'sublocality_level_1' === addressArray[i].types[j] ||
            'locality' === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };

  getState = addressArray => {
    let state = '';
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          'administrative_area_level_1' === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };

  getCountry = addressArray => {
    let state = '';
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && 'country' === addressArray[i].types[0]) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };

  searchLocation = query => {
    const { getCoordinates } = this.props;
    Geocode.fromAddress(query).then(
      response => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray),
          country = this.getCountry(addressArray),
          latValue = response.results[0].geometry.location.lat,
          lngValue = response.results[0].geometry.location.lng;
        getCoordinates(latValue, lngValue, country);

        this.setState({
          address: address || '',
          area: area || '',
          city: city || '',
          state: state || '',
          country: country || '',
          markerPosition: {
            lat: latValue,
            lng: lngValue
          },
          mapPosition: {
            lat: latValue,
            lng: lngValue
          }
        });
      },
      error => {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    );
  };

  onInfoWindowClose = () => ({});

  onMarkerDragEnd = event => {
    const { onUpdateIsAddressChange } = this.props;
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();
    this.updateMapByLatLng(newLat, newLng);
    onUpdateIsAddressChange();
  };

  updateMapByLatLng = (newLat, newLng) => {
    const { getCoordinates } = this.props;
    Geocode.fromLatLng(newLat, newLng).then(
      response => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray),
          country = this.getCountry(addressArray);

        this.setState({
          address: address || '',
          area: area || '',
          city: city || '',
          state: state || '',
          country: country || '',
          markerPosition: {
            lat: newLat,
            lng: newLng
          },
          mapPosition: {
            lat: newLat,
            lng: newLng
          }
        });
        getCoordinates(newLat, newLng, country);
      },
      error => {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    );
  };

  onZoomChanged = () =>
    this.setState({
      zoom: this._map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.zoom
    });

  onMapTypeIdChanged = () =>
    this.setState({
      mapTypeId: this._map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.mapTypeId
    });

  infoWindowPosition = () => {
    const { zoom } = this.state;
    switch (zoom) {
      case 0:
        return 22;
      case 1:
        return 16;
      case 2:
        return 9;
      case 3:
        return 5;
      case 4:
        return 2.5;
      case 5:
        return 1.2;
      case 6:
        return 0.6;
      case 7:
        return 0.3;
      case 8:
        return 0.15;
      case 9:
        return 0.08;
      case 10:
        return 0.04;
      case 11:
        return 0.02;
      case 12:
        return 0.009;
      case 13:
        return 0.004;
      case 14:
        return 0.002;
      case 15:
        return 0.001;
      case 16:
        return 0.0005;
      case 17:
        return 0.0003;
      case 18:
        return 0.00015;
      case 19:
        return 0.00007;
      case 20:
        return 0.00004;
      case 21:
        return 0.00002;
      case 22:
        return 0.00001;
      default:
        break;
    }
  };

  textForInfoWindow = () => {
    const { addressData, postalCode } = this.props;
    if (addressData.street || addressData.number) {
      return `${addressData.street} ${addressData.number}, ${postalCode}`;
    }
    return `${postalCode}`;
  };

  render() {
    const { google, height, kmlDataUrl } = this.props;
    const { zoom, mapTypeId, mapPosition, markerPosition } = this.state;
    const AsyncMap = withScriptjs(
      withGoogleMap(() => (
        <GoogleMap
          google={google}
          ref={map => {
            if (map) {
              this._map = map;
              this._map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.mapTypeId = mapTypeId;
            }
          }}
          onZoomChanged={this.onZoomChanged}
          onMapTypeIdChanged={this.onMapTypeIdChanged}
          zoom={zoom}
          center={{ lat: mapPosition.lat, lng: mapPosition.lng }}
        >
          <Marker
            google={google}
            name="Dolores park"
            draggable
            onDragEnd={this.onMarkerDragEnd}
            position={{ lat: markerPosition.lat, lng: markerPosition.lng }}
          />
          <Marker />

          <KmlLayer url={kmlDataUrl} options={{ preserveViewport: true, clickable: false }} />

          <InfoWindow
            onClose={this.onInfoWindowClose}
            position={{
              lat: markerPosition.lat + this.infoWindowPosition(),
              lng: markerPosition.lng
            }}
          >
            <div>
              <span className="p-0 m-0">{this.textForInfoWindow()}</span>
            </div>
          </InfoWindow>
        </GoogleMap>
      ))
    );
    let map;
    if (markerPosition.lat !== undefined) {
      map = (
        <div>
          <AsyncMap
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAJYgU_yutTY172LEjBhVOWoPUnPUkPMhI&libraries=places"
            loadingElement={<div className="h-100" />}
            containerElement={<div style={{ height: height }} />}
            mapElement={<div className="h-100" />}
          />
        </div>
      );
    } else {
      map = <div style={{ height: height }} />;
    }
    return map;
  }
}

Map.propTypes = {
  getCoordinates: PropTypes.func,
  query: PropTypes.string,
  zoom: PropTypes.number,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  google: PropTypes.object,
  kmlDataUrl: PropTypes.string,
  addressData: PropTypes.object,
  postalCode: PropTypes.string,
  isAddressChange: PropTypes.bool,
  onUpdateIsAddressChange: PropTypes.func
};

Map.defaultProps = {
  getCoordinates: () => ({}),
  query: '',
  zoom: 0,
  height: '',
  google: {},
  kmlDataUrl: '',
  addressData: {},
  postalCode: '',
  isAddressChange: false,
  onUpdateIsAddressChange: () => ({})
};

export default Map;

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { PAYMENT_TYPE, RENTAL, SPECIAL_CHARACTER } from '../../common/constants';
import { updateProductSelections } from '../../store/actions';
import { ShowIfUtil } from '../../common/utils';
import { DropDown, StyledSpan, Switch } from '../../components/epilot-forms/common';
import { productSelectionsSelector } from '../../store/selectors';

class PurchaseRentalSelectionContainer extends Component {
  constructor(props) {
    super(props);
    const { productSelections } = this.props;
    this.state = {
      selectedValue: productSelections.selectedPurchaseRental || PAYMENT_TYPE.PURCHASE
    };
  }

  componentWillReceiveProps(nextProps) {
    const { productSelections } = this.props;
    if (
      nextProps.productSelections.selectedPurchaseRental !==
      productSelections.selectedPurchaseRental
    ) {
      this.setState({
        selectedValue: nextProps.productSelections.selectedPurchaseRental
      });
    }
  }

  renderDropdown = () => {
    const { frontendConfiguration } = this.props;
    if (
      frontendConfiguration.listRentalInfo.length === 0 &&
      frontendConfiguration.isOneTimePayOff
    ) {
      return this.renderPurchaseOnly();
    }
    if (frontendConfiguration.listRentalInfo.length > 0 && !frontendConfiguration.isOneTimePayOff) {
      return this.renderRentalOnly();
    }
    if (frontendConfiguration.listRentalInfo.length > 0 && frontendConfiguration.isOneTimePayOff) {
      return this.renderPurchaseAndRental();
    }
    return SPECIAL_CHARACTER.EMPTY;
  };

  renderPurchaseOnly = () => {
    return (
      <div className="d-flex justify-content-center align-items-center ep--my-8 ep-height-33" />
    );
  };

  renderRentalOnly = () => {
    const { fontText, frontendConfiguration, productSelections } = this.props;
    const listRentalInfo = this.filterAndSortListRentalInfo();

    return (
      <div className="d-flex justify-content-center align-items-center ep--my-8 ep-height-33">
        <StyledSpan
          font={fontText}
          className="ep__txt--md ep__txt-regular mr-2"
          style={{ color: frontendConfiguration.defaultTextColor }}
        >
          {frontendConfiguration.buyRentLabelValueItems.rentalDuration + ' '}
        </StyledSpan>
        <ShowIfUtil condition={listRentalInfo.length > 1}>
          <DropDown
            options={listRentalInfo}
            defaultTextColor={frontendConfiguration.defaultTextColor}
            isObjectOptions
            itemLabel={this.formatDuration}
            itemKey="id"
            onItemSelect={this.onItemSelect}
            selectedItem={productSelections.selectedRentalInfo}
            icon="ep-icon-money-time ep__txt--md"
            fontFamily={fontText}
          />
        </ShowIfUtil>
        <ShowIfUtil condition={listRentalInfo.length === 1}>
          <i
            className="ep-icon-money-time ep__txt--md ep__txt-blackbold mr-1"
            style={{ color: frontendConfiguration.defaultTextColor }}
          />
          <StyledSpan
            font={fontText}
            className="ep__txt--md ep__txt-regular"
            style={{ color: frontendConfiguration.defaultTextColor }}
          >
            {this.formatDuration(listRentalInfo[0].duration, listRentalInfo[0].unit)}
          </StyledSpan>
        </ShowIfUtil>
      </div>
    );
  };

  getLabelByPaymentType = paymentType => {
    const { frontendConfiguration } = this.props;
    if (paymentType === PAYMENT_TYPE.PURCHASE) {
      return frontendConfiguration.buyRentLabelValueItems
        ? frontendConfiguration.buyRentLabelValueItems.buy
        : PAYMENT_TYPE.BUY;
    }
    if (paymentType === PAYMENT_TYPE.RENTAL) {
      return frontendConfiguration.buyRentLabelValueItems
        ? frontendConfiguration.buyRentLabelValueItems.rent
        : PAYMENT_TYPE.RENT;
    }
  };

  renderPurchaseAndRental = () => {
    const { fontText, fontTextBold, frontendConfiguration, productSelections } = this.props;
    const { selectedValue } = this.state;
    const options = [
      {
        keyName: PAYMENT_TYPE.PURCHASE,
        valueName: frontendConfiguration.buyRentLabelValueItems
          ? frontendConfiguration.buyRentLabelValueItems.buy
          : PAYMENT_TYPE.BUY
      },
      {
        keyName: PAYMENT_TYPE.RENTAL,
        valueName: frontendConfiguration.buyRentLabelValueItems
          ? frontendConfiguration.buyRentLabelValueItems.rent
          : PAYMENT_TYPE.RENT
      }
    ];

    const selectedOption = {
      keyName:
        selectedValue === PAYMENT_TYPE.PURCHASE ? PAYMENT_TYPE.PURCHASE : PAYMENT_TYPE.RENTAL,
      valueName: this.getLabelByPaymentType(selectedValue)
    };

    const listRentalInfo = this.filterAndSortListRentalInfo();
    return (
      <div className="d-inline-flex align-items-center ep--my-8 ep-height-33">
        <StyledSpan
          className="d-none d-sm-block ep__txt--md ep__txt-regular mr-1"
          font={fontText}
          style={{ color: frontendConfiguration.defaultTextColor }}
        >
          {'Ich möchte'}
        </StyledSpan>

        <Switch
          options={options}
          onChange={this.onChange}
          fontText={fontText}
          fontTextBold={fontTextBold}
          selectedOption={selectedOption}
        />

        <div className="d-inline-flex align-items-center ml-1">
          <ShowIfUtil
            condition={selectedValue === PAYMENT_TYPE.RENTAL && listRentalInfo.length > 1}
          >
            <DropDown
              options={listRentalInfo}
              isObjectOptions
              itemLabel={this.formatDuration}
              itemKey="id"
              onItemSelect={this.onItemSelect}
              defaultTextColor={frontendConfiguration.defaultTextColor}
              borderColorFocus={frontendConfiguration.primaryColor}
              selectedItem={productSelections.selectedRentalInfo}
              icon="ep-icon-money-time ep__txt--md"
              height="30px"
              fontFamily={fontText}
            />
          </ShowIfUtil>
          <ShowIfUtil
            condition={selectedValue === PAYMENT_TYPE.RENTAL && listRentalInfo.length === 1}
          >
            <i
              className="ep-icon-money-time ep__txt--md ep__txt-blackbold"
              style={{ color: frontendConfiguration.defaultTextColor }}
            />
            <StyledSpan
              font={fontText}
              className="ep__txt--md ep__txt-regular ep--ml-8"
              style={{ color: frontendConfiguration.defaultTextColor }}
            >
              {' ' + this.formatDuration(listRentalInfo[0].duration, listRentalInfo[0].unit)}
            </StyledSpan>
          </ShowIfUtil>
        </div>
      </div>
    );
  };

  filterAndSortListRentalInfo = () => {
    const { frontendConfiguration } = this.props;
    const cloneFrontEndConfiguration = _.assign({}, frontendConfiguration);
    let listRentalInfo = cloneFrontEndConfiguration.listRentalInfo;
    const productId = cloneFrontEndConfiguration.listRentalInfo[0].productId;

    _.forEach(listRentalInfo, rentalInfo => {
      if (rentalInfo) {
        if (rentalInfo.productId !== productId) {
          listRentalInfo.splice(listRentalInfo.indexOf(rentalInfo), 1);
        }
      }
    });

    listRentalInfo.sort(function(a, b) {
      if (a.unit === b.unit) {
        return a.duration - b.duration;
      }
      return a.unit > b.unit ? 1 : -1;
    });

    return listRentalInfo;
  };

  onChange = e => {
    const { updateProductSelections } = this.props;
    this.setState({ selectedValue: e });
    updateProductSelections({
      selectedPurchaseRental: e
    });
  };

  onItemSelect = item => {
    const { updateProductSelections } = this.props;
    updateProductSelections({
      selectedRentalInfo: item
    });
  };

  formatDuration = (duration, unit) => {
    if (unit === RENTAL.MONTH) {
      if (duration > 1) {
        return duration + SPECIAL_CHARACTER.SPACE + RENTAL.MONTHS_FORMATTED;
      }
      return duration + SPECIAL_CHARACTER.SPACE + RENTAL.MONTH_FORMATTED;
    }
    if (duration > 1) {
      return duration + SPECIAL_CHARACTER.SPACE + RENTAL.YEARS_FORMATTED;
    }
    return duration + SPECIAL_CHARACTER.SPACE + RENTAL.YEAR_FORMATTED;
  };

  render() {
    return (
      <div className="d-flex justify-content-center align-items-center">
        {this.renderDropdown()}
      </div>
    );
  }
}

PurchaseRentalSelectionContainer.propTypes = {
  fontText: PropTypes.object,
  fontTextBold: PropTypes.object,
  productSelections: PropTypes.object,
  frontendConfiguration: PropTypes.object,
  updateProductSelections: PropTypes.func
};

PurchaseRentalSelectionContainer.defaultProps = {
  fontText: {},
  fontTextBold: {},
  productSelections: {},
  frontendConfiguration: {},
  updateProductSelections: () => ({})
};

function mapStateToProps(state) {
  return {
    frontendConfiguration: state.frontendConfiguration,
    productSelections: productSelectionsSelector(state)
  };
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateProductSelections
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseRentalSelectionContainer);

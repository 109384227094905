import React, { Component } from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import { StyledButtonBold, StyledSpan } from '../../components';
import { ProgressBar } from '.';
import {
  ContactForm,
  MixedOrderSummary,
  MixedOrderSummaryV2,
  OrderSummary,
  OrderSummaryV2,
  Webshop,
  WebshopV2
} from '..';
import CancelCustomerJourney from '../../components/customer-journey/CancelCustomerJourney';
import {
  hexaToRGBA,
  renderQuestion,
  selectProductForSingleHouseConnectionCJ,
  ShowIfUtil
} from '../../common/utils';
import {
  deleteMappingLabel,
  resetAnswerAfterChangePrivatelyOrBusiness,
  updateCurrentQuestions,
  updateCustomerJourneyAnswer,
  updateCustomerJourneyMapping,
  updateFrontendConfiguration,
  updateProductSelections,
  resetProductSelections
} from '../../store/actions';
import {
  BOOLEAN,
  COLOR,
  COMMON_QUESTION_QUANTITY,
  ELECTRIC_CAR_QUESTION_IDS,
  FRONTEND_TYPE,
  GEO_QUESTION_STATUS,
  HOUSE_CONNECTION_BUILDING_TYPE,
  HOUSE_CONNECTION_E_MOBILITY,
  HOUSE_CONNECTION_ELECTRICITY_FEED_INTO_GRID,
  HOUSE_CONNECTION_EXTINGUISHING_WATER_DEMAND,
  HOUSE_CONNECTION_IS_OWNER,
  HOUSE_CONNECTION_NEW_OR_CHANGE,
  KNOWING_OF_HEATING_OUTPUT,
  MESSAGE_CONSTANTS,
  PACKAGE_LABEL_TYPE,
  PRIVATELY_OR_BUSINESS,
  PRODUCT_TYPE,
  QUESTION_EVENT,
  QUESTION_ID,
  QUESTION_TYPE,
  SERVICE_REQUEST_STATUS,
  SPECIAL_CHARACTER,
  USAGE_OF_ELECTRIC_CAR
} from '../../common/constants';
import {
  cookingQuestionsSelector,
  geoQuestionStatusSelector,
  isAllProductsDeactivatedSelector,
  isAllProductsOnRequestSelector,
  isUnitHCQuestionExistedSelector,
  isUseBusinessQuestionsSelector,
  productSelectionsSelector,
  productsSelector,
  selectedProductsSelector
} from '../../store/selectors';

class CustomerJourneyContainer extends Component {
  constructor(props) {
    super(props);
    const { routeManager, currQuestionIds } = this.props;
    this.state = {
      lastQuestionIndex: _.isEmpty(currQuestionIds) ? 0 : currQuestionIds.length - 1,
      contactFormIndex: _.isEmpty(currQuestionIds) ? 0 : currQuestionIds.length,
      currQuestionIndex: 0,
      questionIndexJumpingHistory: {},
      questionIndexBeforeGoToCF: null,
      questionIndexBeforeGoToCancelCJ: null,
      isPreSelectProduct: false
    };
    this.isPreviewCheckoutPage = routeManager.isPreviewCheckoutPage === BOOLEAN.TRUE;
  }

  componentDidMount() {
    const { frontendConfiguration, products, questions, updateCurrentQuestions } = this.props;
    let { packageMappings } = frontendConfiguration;
    let cloneQuestions = _.assign([], questions);

    this.handleWindowResize();
    this.setState({
      isPreSelectProduct:
        (packageMappings.hasOwnProperty(PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY) ||
          packageMappings.hasOwnProperty(PRODUCT_TYPE.HOUSE_CONNECTION_GAS) ||
          packageMappings.hasOwnProperty(PRODUCT_TYPE.HOUSE_CONNECTION_WATER) ||
          packageMappings.hasOwnProperty(PRODUCT_TYPE.HOUSE_CONNECTION_HEAT) ||
          packageMappings.hasOwnProperty(PRODUCT_TYPE.HOUSE_CONNECTION_BROADBAND)) &&
        products.length > 1
    });
    _.forEach(cloneQuestions, question => {
      if (question.id === QUESTION_ID.HOUSE_CONNECTION_SELECT_PRODUCT && products.length === 1) {
        question.enable = false;
        return false;
      }
    });

    !_.isEmpty(cloneQuestions) && updateCurrentQuestions(cloneQuestions);
  }

  componentDidUpdate(prevProps) {
    const {
      selectedProducts: prevSelectedProducts,
      currQuestionIds: prevCurrQuestionIds,
      isUseBusinessQuestions: prevIsUseBusinessQuestions
    } = prevProps;
    const {
      selectedProducts,
      updateCurrentQuestions,
      currQuestionIds,
      currQuestionIndexMap,
      currQuestionMap,
      questions,
      businessQuestions,
      isUseBusinessQuestions,
      updateCustomerJourneyAnswer,
      updateCustomerJourneyMapping,
      customerJourneyAnswer
    } = this.props;
    const { currQuestionIndex } = this.state;
    if (isUseBusinessQuestions !== prevIsUseBusinessQuestions) {
      let tmpCurrentQuestions = [];
      if (isUseBusinessQuestions) {
        tmpCurrentQuestions.push(...questions.slice(0, COMMON_QUESTION_QUANTITY));
        tmpCurrentQuestions.push(...businessQuestions);
        updateCurrentQuestions(tmpCurrentQuestions);
      } else {
        updateCurrentQuestions(questions);
      }
      this.resetAnswersAfterChangingCustomerType();
      this.resetAdditionalAnswersAfterChangingCustomerType();
      this.resetSelectedAddons();
    }
    if (!_.isEqual(prevCurrQuestionIds, currQuestionIds)) {
      let newCustomerJourneyAnswer = {};
      let newCustomerJourneyMapping = {};
      let autarkyQuestion = currQuestionMap[QUESTION_ID.AUTARKY];
      let electricCarUsageQuestion = currQuestionMap[QUESTION_ID.ELECTRIC_CAR_USAGE];
      let householdSize = currQuestionMap[QUESTION_ID.HOUSEHOLD_SIZE];
      let houseConnectionOutletPressureRequired =
        currQuestionMap[QUESTION_ID.HOUSE_CONNECTION_OUTLET_PRESSURE_REQUIRED];
      this.setState({
        lastQuestionIndex: currQuestionIds.length - 1,
        currQuestionIndex: this.isShowingContactForm() ? currQuestionIds.length : currQuestionIndex,
        contactFormIndex: currQuestionIds.length
      });
      if (autarkyQuestion && autarkyQuestion.objectModel) {
        newCustomerJourneyAnswer.autarky = autarkyQuestion.objectModel.minValue;
      }
      if (electricCarUsageQuestion && electricCarUsageQuestion.objectModel) {
        newCustomerJourneyAnswer.electricCarUsage = electricCarUsageQuestion.objectModel.minValue;
        newCustomerJourneyMapping.electricCarUsageConsumption = Number(
          electricCarUsageQuestion.objectModel.items['5000']
        );
      }
      if (householdSize && householdSize.objectModel) {
        let householdSizeConfig =
          householdSize.objectModel.items['1 person'] ||
          householdSize.objectModel.items['1 Person'];
        if (householdSizeConfig) {
          newCustomerJourneyMapping.householdConsumption = Number(householdSizeConfig);
          newCustomerJourneyMapping.totalConsumption = Number(householdSizeConfig);
        }
      }
      if (
        houseConnectionOutletPressureRequired &&
        houseConnectionOutletPressureRequired.objectModel
      ) {
        if (
          _.find(selectedProducts, product => product.type === PRODUCT_TYPE.HOUSE_CONNECTION_GAS)
        ) {
          if (!customerJourneyAnswer.houseConnectionOutletPressureRequired) {
            newCustomerJourneyAnswer.houseConnectionOutletPressureRequired =
              houseConnectionOutletPressureRequired.objectModel.inputConfig.maxValue;
            newCustomerJourneyAnswer.houseConnectionDefaultOutletPressureRequired =
              houseConnectionOutletPressureRequired.objectModel.inputConfig.maxValue;
          }
        }
      }
      !_.isEmpty(newCustomerJourneyAnswer) && updateCustomerJourneyAnswer(newCustomerJourneyAnswer);
      !_.isEmpty(newCustomerJourneyMapping) &&
        updateCustomerJourneyMapping(newCustomerJourneyMapping);
    }
    if (selectedProducts !== prevSelectedProducts) {
      this.setState({ isPreSelectProduct: selectedProducts.length === 0 });
    }
    if (!this.isShowingContactForm()) {
      if (currQuestionIndex !== 0 && prevCurrQuestionIds.length > currQuestionIds.length) {
        let prevQuestionId = _.findKey(
          prevProps.currQuestionIndexMap,
          elem => elem === currQuestionIndex
        );
        prevQuestionId = Number(prevQuestionId);
        // check prev question still exist
        if (_.indexOf(currQuestionIds, prevQuestionId) !== -1) {
          // keep the current standing question that do not belong to removed question.
          this.setState({
            currQuestionIndex: currQuestionIndexMap[prevQuestionId]
          });
        } else {
          // current standing question has been removed, handle jump to next available question
          const removedQuestionIds = _.difference(prevCurrQuestionIds, currQuestionIds);
          if (!_.isEmpty(removedQuestionIds)) {
            const firstRemovedQuestionId = removedQuestionIds[0];
            const tmpPrevCurrQuestionIds = prevCurrQuestionIds.slice(
              prevCurrQuestionIds.indexOf(firstRemovedQuestionId)
            );
            const availableQuestionIds = _.difference(tmpPrevCurrQuestionIds, removedQuestionIds);
            const firstAvailableQuestionId = availableQuestionIds[0];
            if (_.isNumber(firstAvailableQuestionId)) {
              const nextAvailableQuestionIdIndex =
                prevProps.currQuestionIndexMap[firstAvailableQuestionId];
              let nextAvailableQuestionId = _.findKey(
                prevProps.currQuestionIndexMap,
                elem => elem === nextAvailableQuestionIdIndex
              );
              nextAvailableQuestionId = Number(nextAvailableQuestionId);
              this.setState({
                currQuestionIndex: currQuestionIndexMap[nextAvailableQuestionId]
              });
            }
          }
        }
      }
      if (currQuestionIndex !== 0 && prevCurrQuestionIds.length < currQuestionIds.length) {
        let prevQuestionId = _.findKey(
          prevProps.currQuestionIndexMap,
          elem => elem === currQuestionIndex
        );
        prevQuestionId = Number(prevQuestionId);
        // keep the current standing question that do not belong to added question.
        this.setState({
          currQuestionIndex: currQuestionIndexMap[prevQuestionId]
        });
      }
    }
  }

  handleGoNext = () => {
    const { currQuestionIndex, contactFormIndex } = this.state;
    const {
      currQuestions,
      customerJourneyAnswer,
      currQuestionIndexMap,
      isUnitHCQuestionExisted
    } = this.props;
    let nextQuestionIndex = currQuestionIndex + 1;
    let currQuestion = currQuestions[currQuestionIndex];

    switch (currQuestion.id) {
      case QUESTION_ID.POSTAL_CODE:
        this.nextStepAtPostalCodeQuestion(nextQuestionIndex);
        break;
      case QUESTION_ID.ADDRESS_DETAILS:
      case QUESTION_ID.PIN_ADDRESS_ON_MAP:
        this.nextStepAtAddressQuestion(nextQuestionIndex);
        break;

      case QUESTION_ID.ELECTRIC_CAR:
        this.nextStepAtElectricCarQuestion(nextQuestionIndex);
        break;

      case QUESTION_ID.OUTPUT_OF_HEATING_KNOWING:
        this.nextStepAtOutputHeatingKnowing(nextQuestionIndex);
        break;

      case QUESTION_ID.HOUSE_CONNECTION_NEW_OR_CHANGE:
        this.nextStepAtHouseConnectionNewOrChangeQuestion(nextQuestionIndex);
        break;

      case QUESTION_ID.HOUSE_CONNECTION_PRIVATELY_OR_BUSINESS:
        if (!isUnitHCQuestionExisted) {
          this.nextStepAtPrivatelyOrBusinessQuestions(nextQuestionIndex);
        } else {
          this.handleGoToNextQuestion(nextQuestionIndex);
        }
        break;

      case QUESTION_ID.HOUSE_CONNECTION_SELECT_PRODUCT:
        if (!isUnitHCQuestionExisted) {
          this.nextStepAtSelectProductQuestion();
        } else {
          this.handleGoToNextQuestion(nextQuestionIndex);
        }
        break;

      case QUESTION_ID.HOUSE_CONNECTION_BUILDING_TYPE:
        this.nextStepAtBuildingTypeQuestion(nextQuestionIndex);
        break;

      case QUESTION_ID.HOUSE_CONNECTION_SINGLE_FAMILY_HOUSE:
        if (!isUnitHCQuestionExisted) {
          this.nextStepAtSingleFamilyHouseQuestion();
        } else {
          this.handleGoToNextQuestion(nextQuestionIndex);
        }
        break;

      case QUESTION_ID.HOUSE_CONNECTION_APARTMENT_BUILDING:
        this.nextStepAtApartmentBuildingQuestion();
        break;

      case QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_MAX_POWER:
        this.nextStepAtElectricityMaxPowerQuestion();
        break;

      case QUESTION_ID.HOUSE_CONNECTION_GAS_MAX_LOAD:
        this.nextStepAtGasMaxLoadQuestion();
        break;

      case QUESTION_ID.HOUSE_CONNECTION_IS_OWNER:
        this.nextStepAtIsOwnerQuestion(nextQuestionIndex);
        break;

      case QUESTION_ID.HOUSE_CONNECTION_UPLOAD_CONSENT_FORM:
        this.nextStepAtUploadConsentForm(nextQuestionIndex);
        break;

      case QUESTION_ID.HOUSE_CONNECTION_E_MOBILITY:
        this.nextStepAtEmobility(nextQuestionIndex);
        break;

      case QUESTION_ID.HOUSE_CONNECTION_REQUIRED_VALUES:
        this.nextStepAtHouseConnectionRequiredValues(nextQuestionIndex);
        break;

      case QUESTION_ID.HOUSE_CONNECTION_PREPARATION_WATER_HEATERS:
        this.handleGoToNextQuestion(nextQuestionIndex);
        break;

      default:
        if (
          currQuestion.type === QUESTION_TYPE.MULTIPLE_CHOICE ||
          currQuestion.type === QUESTION_TYPE.DATE_PICKER
        ) {
          const selected = currQuestion.objectModel[customerJourneyAnswer[currQuestion.id]];
          if (selected) {
            switch (selected.event) {
              case QUESTION_EVENT.JUMP_TO_A_QUESTION:
                this.handleGoToNextQuestion(currQuestionIndexMap[selected.jumpedQuestionId]);
                return;
              case QUESTION_EVENT.JUMP_TO_CONTACT_FORM:
                this.handleGoToNextQuestion(contactFormIndex);
                return;
              case QUESTION_EVENT.CANCEL_CUSTOMER_JOURNEY:
                this.handleGoToNextQuestionForCancel(currQuestionIndex);
                return;
              default:
                break;
            }
          }
        }
        this.handleGoToNextQuestion(nextQuestionIndex);
        break;
    }
  };

  nextStepAtEmobility = nextQuestionIndex => {
    const { customerJourneyAnswer, currQuestionIds } = this.props;
    const nextQuestionId = currQuestionIds[nextQuestionIndex];
    if (customerJourneyAnswer.eMobilityPlan === HOUSE_CONNECTION_E_MOBILITY.NO) {
      if (nextQuestionId === QUESTION_ID.HOUSE_CONNECTION_E_MOBILITY_REQUIREMENT) {
        this.handleGoToNextQuestion(nextQuestionIndex + 1);
        return;
      }
    }
    this.handleGoToNextQuestion(nextQuestionIndex);
  };

  nextStepAtElectricCarQuestion = nextQuestionIndex => {
    const { customerJourneyAnswer, currQuestionIds } = this.props;
    if (customerJourneyAnswer.electricCar === USAGE_OF_ELECTRIC_CAR.NO) {
      // skip all electric car questions
      let skippedNextQuestionIndex =
        this.getFinalByPassIdIndex(currQuestionIds, ELECTRIC_CAR_QUESTION_IDS) + 1;
      this.handleGoToNextQuestion(skippedNextQuestionIndex);
    } else {
      this.handleGoToNextQuestion(nextQuestionIndex);
    }
  };

  //TODO: Go to CF event question
  nextStepAtOutputHeatingKnowing = nextQuestionIndex => {
    const { customerJourneyAnswer } = this.props;
    const { contactFormIndex } = this.state;
    if (customerJourneyAnswer.outputOfHeatingKnowing === KNOWING_OF_HEATING_OUTPUT.NO) {
      this.handleGoToNextQuestion(contactFormIndex);
    } else {
      this.handleGoToNextQuestion(nextQuestionIndex);
    }
  };

  //TODO: Go to CF event question
  nextStepAtHouseConnectionNewOrChangeQuestion = nextQuestionIndex => {
    const { customerJourneyAnswer } = this.props;
    const { contactFormIndex } = this.state;
    if (
      customerJourneyAnswer.houseConnectionNewOrChange === HOUSE_CONNECTION_NEW_OR_CHANGE.CHANGE
    ) {
      this.handleGoToNextQuestion(contactFormIndex);
    } else {
      this.handleGoToNextQuestion(nextQuestionIndex);
    }
  };

  //TODO: Go to CF event question
  nextStepAtPrivatelyOrBusinessQuestions = nextQuestionIndex => {
    const { customerJourneyAnswer } = this.props;
    const { contactFormIndex } = this.state;
    if (
      customerJourneyAnswer.houseConnectionPrivatelyOrBusiness === PRIVATELY_OR_BUSINESS.BUSINESS
    ) {
      this.handleGoToNextQuestion(contactFormIndex);
    } else {
      this.handleGoToNextQuestion(nextQuestionIndex);
    }
  };

  nextStepAtBuildingTypeQuestion = nextQuestionIndex => {
    const {
      customerJourneyAnswer,
      currQuestionIndexMap,
      isUnitHCQuestionExisted,
      updateCustomerJourneyAnswer
    } = this.props;
    const { contactFormIndex } = this.state;
    let newCustomerJourneyAnswer = {};
    if (
      customerJourneyAnswer.houseConnectionBuildingType ===
      HOUSE_CONNECTION_BUILDING_TYPE.BROADBAND_CABLE_REPEATER
    ) {
      if (customerJourneyAnswer.houseConnectionElectricityMaxPower === SPECIAL_CHARACTER.EMPTY) {
        newCustomerJourneyAnswer.houseConnectionElectricityMaxPower = 5;
        updateCustomerJourneyAnswer(newCustomerJourneyAnswer);
      }
      if (currQuestionIndexMap[QUESTION_ID.HOUSE_CONNECTION_REQUIRED_VALUES]) {
        this.handleGoToNextQuestion(
          currQuestionIndexMap[QUESTION_ID.HOUSE_CONNECTION_REQUIRED_VALUES]
        );
      } else if (currQuestionIndexMap[QUESTION_ID.HOUSE_CONNECTION_UPLOAD_PROPERTY_DOCUMENT]) {
        this.handleGoToNextQuestion(
          currQuestionIndexMap[QUESTION_ID.HOUSE_CONNECTION_UPLOAD_PROPERTY_DOCUMENT]
        );
      } else {
        let nextAvailableQuestionId = this.getNextAvailableQuestionIdAfterSpecificQuestionId(
          QUESTION_ID.HOUSE_CONNECTION_UPLOAD_PROPERTY_DOCUMENT
        );
        if (nextAvailableQuestionId) {
          this.handleGoToNextQuestion(currQuestionIndexMap[nextAvailableQuestionId]);
        } else {
          // jump to contact form
          this.handleGoToNextQuestion(contactFormIndex);
        }
      }
    } else {
      if (!isUnitHCQuestionExisted) {
        if (
          customerJourneyAnswer.houseConnectionBuildingType ===
          HOUSE_CONNECTION_BUILDING_TYPE.APARTMENT_BUILDING
        ) {
          this.handleGoToNextQuestion(
            currQuestionIndexMap[QUESTION_ID.HOUSE_CONNECTION_APARTMENT_BUILDING]
          );
          return;
        }
      }
      this.handleGoToNextQuestion(nextQuestionIndex);
    }
  };

  nextStepAtSingleFamilyHouseQuestion = () => {
    const { products, currQuestionIndexMap } = this.props;
    if (products.length === 1) {
      this.handleGoToNextQuestion(currQuestionIndexMap[QUESTION_ID.HOUSE_CONNECTION_IS_OWNER]);
    } else {
      this.handleGoToNextQuestion(
        currQuestionIndexMap[QUESTION_ID.HOUSE_CONNECTION_SELECT_PRODUCT]
      );
    }
  };

  nextStepAtApartmentBuildingQuestion = () => {
    const { products, currQuestions, customerJourneyAnswer, currQuestionIndexMap } = this.props;
    const { currQuestionIndex } = this.state;
    if (products.length === 1) {
      let apartmentBuildingQuestionIndex =
        currQuestionIndexMap[QUESTION_ID.HOUSE_CONNECTION_APARTMENT_BUILDING];
      if (
        apartmentBuildingQuestionIndex &&
        customerJourneyAnswer.houseConnectionNumberOfApartment >
          currQuestions[apartmentBuildingQuestionIndex].objectModel.items['Offer package up to']
      ) {
        this.handleGoToNextQuestion(currQuestionIndex + 2);
      } else {
        this.handleGoToNextQuestion(currQuestionIndexMap[QUESTION_ID.HOUSE_CONNECTION_IS_OWNER]);
      }
    } else {
      this.handleGoToNextQuestion(
        currQuestionIndexMap[QUESTION_ID.HOUSE_CONNECTION_SELECT_PRODUCT]
      );
    }
  };

  nextStepAtHouseConnectionRequiredValues = () => {
    const { customerJourneyAnswer, currQuestionIndexMap } = this.props;
    const { currQuestionIndex, contactFormIndex } = this.state;

    if (
      customerJourneyAnswer.houseConnectionBuildingType ===
      HOUSE_CONNECTION_BUILDING_TYPE.BROADBAND_CABLE_REPEATER
    ) {
      if (currQuestionIndexMap[QUESTION_ID.HOUSE_CONNECTION_UPLOAD_PROPERTY_DOCUMENT]) {
        this.handleGoToNextQuestion(
          currQuestionIndexMap[QUESTION_ID.HOUSE_CONNECTION_UPLOAD_PROPERTY_DOCUMENT]
        );
      } else {
        let nextAvailableQuestionId = this.getNextAvailableQuestionIdAfterSpecificQuestionId(
          QUESTION_ID.HOUSE_CONNECTION_UPLOAD_PROPERTY_DOCUMENT
        );
        if (nextAvailableQuestionId) {
          this.handleGoToNextQuestion(currQuestionIndexMap[nextAvailableQuestionId]);
        } else {
          // jump to contact form
          this.handleGoToNextQuestion(contactFormIndex);
        }
      }
    } else {
      this.handleGoToNextQuestion(currQuestionIndex + 1);
    }
  };

  nextStepAtSelectProductQuestion = () => {
    const { productSelections, currQuestionIndexMap } = this.props;
    if (productSelections.isSelectedProductHouseConnectionForElectricity) {
      this.handleGoToNextQuestion(
        currQuestionIndexMap[QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_MAX_POWER]
      );
    } else if (productSelections.isSelectedProductHouseConnectionForGas) {
      this.handleGoToNextQuestion(currQuestionIndexMap[QUESTION_ID.HOUSE_CONNECTION_GAS_MAX_LOAD]);
    } else if (productSelections.isSelectedProductHouseConnectionForWater) {
      this.handleGoToNextQuestion(
        currQuestionIndexMap[QUESTION_ID.HOUSE_CONNECTION_WATER_MAX_FLOW]
      );
    } else {
      this.handleGoToNextQuestion(currQuestionIndexMap[QUESTION_ID.HOUSE_CONNECTION_IS_OWNER]);
    }
  };

  nextStepAtElectricityMaxPowerQuestion = () => {
    const { productSelections, currQuestionIndexMap } = this.props;
    if (productSelections.isSelectedProductHouseConnectionForGas) {
      this.handleGoToNextQuestion(currQuestionIndexMap[QUESTION_ID.HOUSE_CONNECTION_GAS_MAX_LOAD]);
    } else if (productSelections.isSelectedProductHouseConnectionForWater) {
      this.handleGoToNextQuestion(
        currQuestionIndexMap[QUESTION_ID.HOUSE_CONNECTION_WATER_MAX_FLOW]
      );
    } else {
      this.handleGoToNextQuestion(currQuestionIndexMap[QUESTION_ID.HOUSE_CONNECTION_IS_OWNER]);
    }
  };

  nextStepAtGasMaxLoadQuestion = () => {
    const { productSelections, currQuestionIndexMap } = this.props;
    if (productSelections.isSelectedProductHouseConnectionForWater) {
      this.handleGoToNextQuestion(
        currQuestionIndexMap[QUESTION_ID.HOUSE_CONNECTION_WATER_MAX_FLOW]
      );
    } else {
      this.handleGoToNextQuestion(currQuestionIndexMap[QUESTION_ID.HOUSE_CONNECTION_IS_OWNER]);
    }
  };

  nextStepAtIsOwnerQuestion = nextQuestionIndex => {
    const {
      customerJourneyAnswer,
      currQuestionIndexMap,
      isUnitHCQuestionExisted,
      currQuestionIds
    } = this.props;

    if (!isUnitHCQuestionExisted) {
      if (customerJourneyAnswer.houseConnectionIsOwner === HOUSE_CONNECTION_IS_OWNER.YES) {
        // Go to HOUSE_CONNECTION_IS_NEW_BUILDING question
        this.handleGoToNextQuestion(
          currQuestionIndexMap[QUESTION_ID.HOUSE_CONNECTION_IS_NEW_BUILDING]
        );
      } else {
        this.handleGoToNextQuestion(nextQuestionIndex);
      }
    } else {
      if (customerJourneyAnswer.houseConnectionIsOwner === HOUSE_CONNECTION_IS_OWNER.YES) {
        // Go to HOUSE_CONNECTION_UPLOAD_PROPERTY_DOCUMENT question
        if (currQuestionIndexMap[QUESTION_ID.HOUSE_CONNECTION_UPLOAD_PROPERTY_DOCUMENT]) {
          this.handleGoToNextQuestion(
            currQuestionIndexMap[QUESTION_ID.HOUSE_CONNECTION_UPLOAD_PROPERTY_DOCUMENT]
          );
        } else {
          const idUploadConsentFormQuestionExist = _.find(
            currQuestionIds,
            id => id === QUESTION_ID.HOUSE_CONNECTION_UPLOAD_CONSENT_FORM
          );
          if (idUploadConsentFormQuestionExist) {
            this.handleGoToNextQuestion(nextQuestionIndex + 1);
          } else {
            this.handleGoToNextQuestion(nextQuestionIndex);
          }
        }
      } else {
        this.handleGoToNextQuestion(nextQuestionIndex);
      }
    }
  };

  nextStepAtUploadConsentForm = nextQuestionIndex => {
    const { updateCustomerJourneyAnswer, customerJourneyAnswer } = this.props;
    if (customerJourneyAnswer.consentForm.length === 0) {
      updateCustomerJourneyAnswer({ consentFormError: MESSAGE_CONSTANTS.REQUIRED });
    } else {
      updateCustomerJourneyAnswer({ consentFormError: MESSAGE_CONSTANTS.EMPTY });
      this.handleGoToNextQuestion(nextQuestionIndex);
    }
  };

  nextStepAtAddressQuestion = nextQuestionIndex => {
    const { allProductDeactivated, allProductOnRequest } = this.props;
    const { contactFormIndex } = this.state;
    if (allProductDeactivated || allProductOnRequest) {
      this.handleGoToNextQuestion(contactFormIndex);
    } else {
      this.reselectProductForSingleHouseConnectionCJ();
      this.handleGoToNextQuestion(nextQuestionIndex);
    }
  };

  nextStepAtPostalCodeQuestion = nextQuestionIndex => {
    const {
      allProductDeactivated,
      allProductOnRequest,
      customerJourneyAnswer,
      resetProductSelections
    } = this.props;
    const { contactFormIndex, currQuestionIndex } = this.state;
    if (customerJourneyAnswer.isUserInputPostcode) {
      resetProductSelections();
    }
    if (allProductDeactivated || allProductOnRequest || customerJourneyAnswer.isUserInputPostcode) {
      this.setState({ questionIndexBeforeGoToCF: currQuestionIndex });
      this.handleGoToNextQuestion(contactFormIndex);
    } else {
      this.reselectProductForSingleHouseConnectionCJ();
      this.handleGoToNextQuestion(nextQuestionIndex);
    }
  };

  handleGoBack = () => {
    const {
      questionIndexBeforeGoToCF,
      currQuestionIndex,
      questionIndexJumpingHistory,
      questionIndexBeforeGoToCancelCJ
    } = this.state;
    if (questionIndexBeforeGoToCancelCJ !== null) {
      this.handleGoToNextQuestion(questionIndexBeforeGoToCancelCJ);
      this.setState({ questionIndexBeforeGoToCancelCJ: null });
      return;
    }
    let previousQuestionIndex = currQuestionIndex - 1;
    if (questionIndexBeforeGoToCF !== null) {
      this.handleGoToNextQuestion(questionIndexBeforeGoToCF);
      this.setState({ questionIndexBeforeGoToCF: null });
      return;
    }
    if (questionIndexJumpingHistory[currQuestionIndex]) {
      let tempQuestionIndexBeforeJumping = questionIndexJumpingHistory[currQuestionIndex];
      this.setState({
        questionIndexJumpingHistory: {
          ...questionIndexJumpingHistory,
          [currQuestionIndex]: null
        }
      });
      this.handleGoToNextQuestion(tempQuestionIndexBeforeJumping);
      return;
    }
    this.handleGoToNextQuestion(previousQuestionIndex);
  };

  handleGoToNextQuestion = destinationQuestionIndex => {
    const {
      lastQuestionIndex,
      contactFormIndex,
      currQuestionIndex,
      questionIndexJumpingHistory
    } = this.state;
    let tmpDestinationQuestionIndex;
    if (_.isNumber(destinationQuestionIndex)) {
      tmpDestinationQuestionIndex = destinationQuestionIndex;
      if (destinationQuestionIndex > currQuestionIndex + 1) {
        this.setState({
          questionIndexJumpingHistory: {
            ...questionIndexJumpingHistory,
            [tmpDestinationQuestionIndex]: currQuestionIndex
          }
        });
      } else if (destinationQuestionIndex > lastQuestionIndex) {
        tmpDestinationQuestionIndex = contactFormIndex;
        this.setState({ questionIndexBeforeGoToCF: currQuestionIndex });
      }
    } else {
      this.setState({ questionIndexBeforeGoToCF: currQuestionIndex });
      tmpDestinationQuestionIndex = contactFormIndex;
    }
    this.setState({ currQuestionIndex: tmpDestinationQuestionIndex });
  };

  handleGoToNextQuestionForCancel = currQuestionIndex => {
    this.setState({ questionIndexBeforeGoToCancelCJ: currQuestionIndex });
  };

  resetAnswersAfterChangingCustomerType = () => {
    const { resetAnswerAfterChangePrivatelyOrBusiness } = this.props;
    resetAnswerAfterChangePrivatelyOrBusiness();
    this.resetMappingLabel();
  };

  resetAdditionalAnswersAfterChangingCustomerType = () => {
    const { currQuestions, updateCustomerJourneyAnswer } = this.props;
    const multipleChoiceQuestion = _.find(
      currQuestions,
      question => question.type === QUESTION_TYPE.MULTIPLE_CHOICE
    );
    multipleChoiceQuestion && updateCustomerJourneyAnswer({ [multipleChoiceQuestion.id]: -1 });
  };

  resetMappingLabel = () => {
    const { deleteMappingLabel, currMappingLabels } = this.props;
    if (currMappingLabels && currMappingLabels[PACKAGE_LABEL_TYPE.BROADBAND]) {
      deleteMappingLabel(PACKAGE_LABEL_TYPE.BROADBAND);
    }
    if (currMappingLabels && currMappingLabels[PACKAGE_LABEL_TYPE.TRENCH_LENGTH]) {
      deleteMappingLabel(PACKAGE_LABEL_TYPE.TRENCH_LENGTH);
    }
    if (currMappingLabels && currMappingLabels[PACKAGE_LABEL_TYPE.E_MOBILITY]) {
      deleteMappingLabel(PACKAGE_LABEL_TYPE.E_MOBILITY);
    }
  };

  resetSelectedAddons = () => {
    const { updateProductSelections } = this.props;
    updateProductSelections({ selectedAddonIds: [] });
  };

  reselectProductForSingleHouseConnectionCJ = () => {
    const { frontendConfiguration, products, updateProductSelections } = this.props;
    const { packageMappings } = frontendConfiguration;
    let newProductSelections = {};
    newProductSelections = selectProductForSingleHouseConnectionCJ(
      products,
      newProductSelections,
      packageMappings
    );
    !_.isEmpty(newProductSelections) && updateProductSelections(newProductSelections);
  };

  getFinalByPassIdIndex = (sourceArr, skipArr) => {
    let sourceArrFinalByPassIdIndex = -1;
    _.forEach(skipArr, byPassElem => {
      let sourceArrCurrByPassIdIndex = _.indexOf(sourceArr, byPassElem);
      if (sourceArrCurrByPassIdIndex !== -1) {
        sourceArrFinalByPassIdIndex = sourceArrCurrByPassIdIndex;
      } else {
        return false;
      }
    });
    return sourceArrFinalByPassIdIndex;
  };

  getNextAvailableQuestionIdAfterSpecificQuestionId = specificQuestionId => {
    const {
      currQuestionIds,
      frontendConfiguration: { questionIds }
    } = this.props;
    const questionIdsAfterSpecificQuestionId = _.slice(
      questionIds,
      _.findIndex(questionIds, questionId => questionId === specificQuestionId) + 1
    );
    let nextAvailableQuestionId = null;
    _.forEach(questionIdsAfterSpecificQuestionId, questionIdAfterSpecificQuestionId => {
      if (_.indexOf(currQuestionIds, questionIdAfterSpecificQuestionId) !== -1) {
        nextAvailableQuestionId = questionIdAfterSpecificQuestionId;
        return false;
      }
    });
    return nextAvailableQuestionId;
  };

  isShowingContactForm = () => {
    const { currQuestionIndex, lastQuestionIndex } = this.state;
    return currQuestionIndex >= lastQuestionIndex + 1;
  };

  isNextButtonEnabled = () => {
    const {
      currQuestions,
      frontendConfiguration,
      customerJourneyAnswer,
      productSelections,
      geoQuestionStatus,
      externalServiceRequestStatus,
      allProductDeactivated,
      isUploading,
      checkingInternalProductAvailability
    } = this.props;
    const { isCJQuestionsSkipEnable } = frontendConfiguration;
    const { currQuestionIndex } = this.state;
    let cloneDatePickerItems;
    let dateIndex;
    if (this.isShowingContactForm()) {
      return;
    }
    if (isUploading) {
      return false;
    }
    if (checkingInternalProductAvailability) {
      return false;
    }
    let currQuestion = currQuestions[currQuestionIndex];
    if (!currQuestion) {
      return;
    }
    const currQuestionId = currQuestion.id;
    switch (currQuestionId) {
      case QUESTION_ID.NAME:
        return customerJourneyAnswer.name !== SPECIAL_CHARACTER.EMPTY;
      case QUESTION_ID.POSTAL_CODE:
        if (customerJourneyAnswer.isUserInputPostcode) {
          return true;
        }
        if (allProductDeactivated) return false;
        if (
          frontendConfiguration.thirdPartyServiceSetting &&
          frontendConfiguration.thirdPartyServiceSetting.active
        ) {
          if (
            geoQuestionStatus === GEO_QUESTION_STATUS.ADDRESS_DETAILS ||
            geoQuestionStatus === GEO_QUESTION_STATUS.PIN_ADDRESS_ON_MAP
          ) {
            return !_.isEmpty(customerJourneyAnswer.postalCode);
          }
        }
        return !!customerJourneyAnswer.postalCodeMapped;
      case QUESTION_ID.PIN_ADDRESS_ON_MAP:
        if (allProductDeactivated) return false;
        if (
          frontendConfiguration.thirdPartyServiceSetting &&
          frontendConfiguration.thirdPartyServiceSetting.active
        ) {
          if (
            geoQuestionStatus === GEO_QUESTION_STATUS.ADDRESS_DETAILS ||
            geoQuestionStatus === GEO_QUESTION_STATUS.PIN_ADDRESS_ON_MAP
          ) {
            return (
              !_.isEmpty(customerJourneyAnswer.postalCode) &&
              externalServiceRequestStatus !== SERVICE_REQUEST_STATUS.NO_REQUEST
            );
          }
        }
        return !!customerJourneyAnswer.postalCodeMapped;
      case QUESTION_ID.ELECTRIC_CAR:
        return isCJQuestionsSkipEnable || customerJourneyAnswer.electricCar !== -1;
      case QUESTION_ID.CHARGE_CAR_SPEED:
        return isCJQuestionsSkipEnable || customerJourneyAnswer.chargeCarSpeed !== -1;
      case QUESTION_ID.HEATING_ITEM:
        return isCJQuestionsSkipEnable || customerJourneyAnswer.whereToHeat !== -1;
      case QUESTION_ID.HEATING_LIVING_SPACE:
        return isCJQuestionsSkipEnable || customerJourneyAnswer.heatingLivingSpace !== '';
      case QUESTION_ID.HEATING_BUILDING_YEAR:
        return isCJQuestionsSkipEnable || customerJourneyAnswer.heatingBuiltYear !== '';
      case QUESTION_ID.USAGE_OF_GAS:
        return isCJQuestionsSkipEnable || customerJourneyAnswer.usageOfGas !== -1;
      case QUESTION_ID.OUTPUT_OF_HEATING_KNOWING:
        return isCJQuestionsSkipEnable || customerJourneyAnswer.outputOfHeatingKnowing !== -1;
      case QUESTION_ID.OUTPUT_OF_HEATING:
        return isCJQuestionsSkipEnable || customerJourneyAnswer.outputHeating !== '';
      case QUESTION_ID.ADDRESS_DETAILS:
        if (allProductDeactivated) return false;
        if (customerJourneyAnswer.streetListed) {
          return !!(
            customerJourneyAnswer.address.street &&
            customerJourneyAnswer.address.number &&
            !customerJourneyAnswer.address.streetNotFound
          );
        }
        return true;
      case QUESTION_ID.HEATING_NAME:
        return customerJourneyAnswer.name !== SPECIAL_CHARACTER.EMPTY;
      case QUESTION_ID.HOUSE_CONNECTION_NAME:
        return customerJourneyAnswer.name !== SPECIAL_CHARACTER.EMPTY;
      case QUESTION_ID.HOUSE_CONNECTION_NEW_OR_CHANGE:
        return customerJourneyAnswer.houseConnectionNewOrChange !== -1;
      case QUESTION_ID.HOUSE_CONNECTION_PRIVATELY_OR_BUSINESS:
        return customerJourneyAnswer.houseConnectionPrivatelyOrBusiness !== -1;
      case QUESTION_ID.HOUSE_CONNECTION_BUILDING_TYPE:
        return customerJourneyAnswer.houseConnectionBuildingType !== -1;
      case QUESTION_ID.HOUSE_CONNECTION_APARTMENT_BUILDING:
        return customerJourneyAnswer.houseConnectionNumberOfApartment !== SPECIAL_CHARACTER.EMPTY;
      case QUESTION_ID.HOUSE_CONNECTION_SELECT_PRODUCT:
        return !(
          !productSelections.isSelectedProductHouseConnectionForElectricity &&
          !productSelections.isSelectedProductHouseConnectionForGas &&
          !productSelections.isSelectedProductHouseConnectionForWater &&
          !productSelections.isSelectedProductHouseConnectionForHeating &&
          !productSelections.isSelectedProductHouseConnectionForBroadband
        );
      case QUESTION_ID.HOUSE_CONNECTION_REQUIRED_VALUES:
        if (productSelections.isSelectedProductHouseConnectionForElectricity) {
          if (
            !(
              customerJourneyAnswer.houseConnectionElectricityMaxPower &&
              customerJourneyAnswer.houseConnectionElectricityMaxPower !== '0.00'
            )
          ) {
            return false;
          }
        }
        if (productSelections.isSelectedProductHouseConnectionForGas) {
          if (
            !(
              customerJourneyAnswer.houseConnectionGasMaxLoad &&
              customerJourneyAnswer.houseConnectionGasMaxLoad !== '0.00'
            )
          ) {
            return false;
          }
        }
        if (productSelections.isSelectedProductHouseConnectionForWater) {
          if (
            !(
              customerJourneyAnswer.houseConnectionWaterMaxFlow &&
              customerJourneyAnswer.houseConnectionWaterMaxFlow !== '0.00'
            )
          ) {
            return false;
          }
        }
        if (productSelections.isSelectedProductHouseConnectionForHeating) {
          if (
            !(
              customerJourneyAnswer.houseConnectionHeatingCapacity &&
              customerJourneyAnswer.houseConnectionHeatingCapacity !== '0.00'
            )
          ) {
            return false;
          }
        }
        return true;
      case QUESTION_ID.HOUSE_CONNECTION_IS_OWNER:
        return customerJourneyAnswer.houseConnectionIsOwner !== -1;
      case QUESTION_ID.CHARGE_NAME:
        return customerJourneyAnswer.name !== SPECIAL_CHARACTER.EMPTY;
      case QUESTION_ID.CHARGE_PRIVATELY_OR_BUSINESS:
        return customerJourneyAnswer.chargePrivatelyOrBusiness !== -1;
      case QUESTION_ID.CHARGE_TYPE:
        return customerJourneyAnswer.chargeType !== -1;
      case QUESTION_ID.HOUSE_CONNECTION_E_MOBILITY:
        return customerJourneyAnswer.eMobilityPlan !== -1;
      case QUESTION_ID.HOUSE_CONNECTION_E_MOBILITY_REQUIREMENT:
        return customerJourneyAnswer.eMobilityPower !== SPECIAL_CHARACTER.EMPTY;
      case QUESTION_ID.HOUSE_CONNECTION_OUTLET_PRESSURE_REQUIRED:
        return (
          customerJourneyAnswer.houseConnectionOutletPressureRequired !== SPECIAL_CHARACTER.EMPTY
        );
      case QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_FEED_INTO_GRID:
        if (
          customerJourneyAnswer.houseConnectionElectricityIsFeedIntoGrid ===
          HOUSE_CONNECTION_ELECTRICITY_FEED_INTO_GRID.YES
        ) {
          return (
            customerJourneyAnswer.houseConnectionElectricityFeedIntoGrid !==
              SPECIAL_CHARACTER.EMPTY &&
            parseInt(customerJourneyAnswer.houseConnectionElectricityFeedIntoGrid) > 0
          );
        }
        return customerJourneyAnswer.houseConnectionElectricityIsFeedIntoGrid !== -1;
      case QUESTION_ID.HOUSE_CONNECTION_EXTINGUISHING_WATER_DEMAND:
        if (
          customerJourneyAnswer.houseConnectionIsExtinguishingWaterDemand ===
          HOUSE_CONNECTION_EXTINGUISHING_WATER_DEMAND.YES
        ) {
          return (
            customerJourneyAnswer.houseConnectionExtinguishingWaterDemand !==
            SPECIAL_CHARACTER.EMPTY
          );
        }
        return customerJourneyAnswer.houseConnectionIsExtinguishingWaterDemand !== -1;
      case QUESTION_ID.HOUSE_CONNECTION_SINGLE_FAMILY_HOUSE:
        return frontendConfiguration.isCJQuestionsSkipEnable
          ? true
          : customerJourneyAnswer.houseConnectionCellar !== -1;
      case QUESTION_ID.HOUSE_CONNECTION_IS_NEW_BUILDING:
        return frontendConfiguration.isCJQuestionsSkipEnable
          ? true
          : customerJourneyAnswer.houseConnectionIsNewBuilding !== -1;
      case QUESTION_ID.HOUSE_CONNECTION_UPLOAD_CONSENT_FORM:
        return customerJourneyAnswer.consentForm && customerJourneyAnswer.consentForm.length > 0;
      case QUESTION_ID.HOUSE_CONNECTION_UPLOAD_PROPERTY_DOCUMENT:
        return frontendConfiguration.isCJQuestionsSkipEnable
          ? true
          : customerJourneyAnswer.propertyDocument &&
              customerJourneyAnswer.propertyDocument.length > 0;
      case QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_ADDITIONAL:
        return _.isEmpty(customerJourneyAnswer.additionalPowerItems)
          ? true
          : _.every(customerJourneyAnswer.additionalPowerItems, item => {
              if (item.otherOption != null) {
                return (
                  Number(item.output) > 0 &&
                  (item.otherOption && item.otherOption !== SPECIAL_CHARACTER.EMPTY)
                );
              } else {
                return Number(item.output) > 0;
              }
            });
      case QUESTION_ID.HOUSE_CONNECTION_PREPARATION_WATER_HEATERS:
        return customerJourneyAnswer.houseConnectionWarmWaterPreparation !== -1;
      case QUESTION_ID.HOUSE_CONNECTION_PEAK_WATER:
        return customerJourneyAnswer.houseConnectionPeakWater > 0;
      default:
        if (
          currQuestion.type === QUESTION_TYPE.MULTIPLE_CHOICE ||
          currQuestion.type === QUESTION_TYPE.DATE_PICKER
        ) {
          cloneDatePickerItems = [...customerJourneyAnswer.datePickerItems];
          dateIndex = _.findIndex(cloneDatePickerItems, item => item.id === currQuestionId);
          if (
            currQuestion.required &&
            (_.isUndefined(customerJourneyAnswer[currQuestionId]) ||
              customerJourneyAnswer[currQuestionId] === -1 ||
              (cloneDatePickerItems[dateIndex] && !cloneDatePickerItems[dateIndex].date))
          ) {
            return false;
          }
        }
        if (currQuestion.type === QUESTION_TYPE.FILE_UPLOAD) {
          const fileUpload = _.find(customerJourneyAnswer.fileUpload, {
            questionId: currQuestionId
          });
          return !currQuestion.required ? true : fileUpload && fileUpload.files.length > 0;
        }
        return true;
    }
  };

  handleWindowResize = () => {
    let resizeTimeout;
    window.addEventListener('resize', () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(this.setDynamicHeight, 50);
    });
  };

  showContactForm = () => {
    const { contactFormIndex, currQuestionIndex } = this.state;
    this.setState({ questionIndexBeforeGoToCF: currQuestionIndex });
    this.handleGoToNextQuestion(contactFormIndex);
  };

  renderQuestions = () => {
    const {
      fontText,
      fontTextBold,
      currQuestions,
      frontendConfiguration: { textColor },
      linkColor
    } = this.props;
    const { currQuestionIndex } = this.state;

    let currQuestion = currQuestions[currQuestionIndex];
    if (currQuestion) {
      return renderQuestion(currQuestion, fontText, fontTextBold, textColor, linkColor);
    }
  };

  renderCancelScreenInfo = questionIndexBeforeGoToCancelCJ => {
    if (questionIndexBeforeGoToCancelCJ) {
      const { currQuestions, customerJourneyAnswer, frontendConfiguration } = this.props;
      let currQuestion = currQuestions[questionIndexBeforeGoToCancelCJ];
      const cancelCFSelected = currQuestion.objectModel[customerJourneyAnswer[currQuestion.id]];
      if (cancelCFSelected.cancelCustomerJourneyAnswerModel.activeCustomizeCancelScreen) {
        return {
          title: cancelCFSelected.cancelCustomerJourneyAnswerModel.header,
          body: cancelCFSelected.cancelCustomerJourneyAnswerModel.messageContent,
          hyperlinkText: cancelCFSelected.cancelCustomerJourneyAnswerModel.hyperlinkText
        };
      }
      return frontendConfiguration.cancelScreenCJDefaultInfo;
    }
  };

  isHouseConnectionProductV2 = frontendConfiguration => {
    if (frontendConfiguration.frontendType !== FRONTEND_TYPE.CUSTOMER_JOURNEY_V2) {
      return false;
    }
    let isHouseConnectionProduct = true;

    for (const product of frontendConfiguration.products) {
      switch (product.type) {
        case PRODUCT_TYPE.HOUSE_CONNECTION_BROADBAND:
        case PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY:
        case PRODUCT_TYPE.HOUSE_CONNECTION_GAS:
        case PRODUCT_TYPE.HOUSE_CONNECTION_HEAT:
        case PRODUCT_TYPE.HOUSE_CONNECTION_WATER:
          break;
        default:
          isHouseConnectionProduct = false;
          break;
      }
      if (!isHouseConnectionProduct) {
        break;
      }
    }
    return isHouseConnectionProduct;
  };

  render() {
    const {
      frontendConfiguration,
      routeToConnectErrorPage,
      routeToSuccessPage,
      createOpportunities,
      fontText,
      fontTextBold,
      selectedProducts,
      linkColor,
      isUploading
    } = this.props;

    const isHouseConnectionProductV2 = this.isHouseConnectionProductV2(frontendConfiguration);

    const {
      currQuestionIndex,
      lastQuestionIndex,
      isPreSelectProduct,
      questionIndexBeforeGoToCancelCJ
    } = this.state;
    const style = {
      background: hexaToRGBA(
        frontendConfiguration.backgroundColor,
        frontendConfiguration.backgroundOpacity / 100
      ),
      padding: '5px'
    };
    let buttonText = '';
    if (frontendConfiguration.designOptions) {
      buttonText = frontendConfiguration.onlinePurchaseSettingModel.active
        ? frontendConfiguration.designOptions.onlinePurchaseButtonText
        : frontendConfiguration.designOptions.requestButtonText;
    }

    const CTAStyle = {
      background: frontendConfiguration.secondaryColor,
      borderRadius: frontendConfiguration.borderRadiusMax4px
    };

    const CTAButtonStyle = {
      opacity: _.isEmpty(selectedProducts) || isUploading ? 0.5 : 1,
      cursor: _.isEmpty(selectedProducts) || isUploading ? 'not-allowed' : 'pointer',
      background: frontendConfiguration.buttonBackground,
      borderRadius: frontendConfiguration.buttonBorderRadius,
      color: frontendConfiguration.buttonTextColor,
      border: 0
    };

    const nextButtonStyle = {
      color: frontendConfiguration.primaryTextColor,
      backgroundColor: frontendConfiguration.primaryColor,
      borderRadius: frontendConfiguration.borderRadius,
      width: '150px',
      border: 0,
      position: 'relative',
      opacity: this.isNextButtonEnabled() ? 1 : 0.5,
      cursor: this.isNextButtonEnabled() ? 'pointer' : 'not-allowed'
    };

    const backButtonStyle = {
      backgroundColor: 'transparent',
      border: 'none',
      position: 'relative',
      padding: 0,
      opacity: isUploading ? 0.5 : 1,
      cursor: isUploading ? 'not-allowed' : 'pointer'
    };

    const textColor = {
      color: frontendConfiguration.secondaryTextColor
    };

    const customerJourneyCTAStyle = {
      backgroundColor: frontendConfiguration.secondaryColor,
      borderRadius: frontendConfiguration.borderRadiusMax4px,
      marginLeft: '0',
      marginRight: '0'
    };

    const orderContactFormStyle = classNames({
      'col-12': true,
      'col-md-6': true,
      'col-lg-7': true,
      'order-contact-form': this.isPreviewCheckoutPage || currQuestionIndex > lastQuestionIndex
    });

    return (
      <div>
        <ShowIfUtil condition={questionIndexBeforeGoToCancelCJ !== null}>
          <CancelCustomerJourney
            cancelScreenInfo={this.renderCancelScreenInfo(questionIndexBeforeGoToCancelCJ)}
            previousStep={this.handleGoBack}
          />
        </ShowIfUtil>
        <ShowIfUtil condition={questionIndexBeforeGoToCancelCJ === null}>
          <div className="container-fluid show-grid" style={style}>
            <div className="row">
              <div className={orderContactFormStyle}>
                <div className="row m-0">
                  <div className="col-12 ep--mt-56">
                    <ShowIfUtil
                      condition={
                        !this.isPreviewCheckoutPage && currQuestionIndex <= lastQuestionIndex
                      }
                    >
                      {this.renderQuestions()}
                      <div className="d-flex align-items-center justify-content-center ep--mb-24">
                        <ShowIfUtil condition={currQuestionIndex !== 0}>
                          <StyledButtonBold
                            disabled={isUploading}
                            onClick={this.handleGoBack}
                            className="ep--mr-16 ep--mt-16 ep__txt-bold"
                            style={backButtonStyle}
                          >
                            <i className="ep-icon-tail-left ep-navigation-icon__arrow" />
                          </StyledButtonBold>
                        </ShowIfUtil>
                        <StyledButtonBold
                          font={fontTextBold}
                          className="ep-button ep__txt-bold ep__txt--md ep--mt-16 ep__txt-no-select"
                          style={nextButtonStyle}
                          disabled={!this.isNextButtonEnabled()}
                          onClick={this.handleGoNext}
                        >
                          {'Weiter'}
                          <i
                            className="ep-icon-tail-right position-absolute ep-navigation-icon__arrow"
                            style={{
                              right: '7%',
                              top: '20%'
                            }}
                          />
                        </StyledButtonBold>
                      </div>
                    </ShowIfUtil>
                    <ShowIfUtil
                      condition={
                        this.isPreviewCheckoutPage || currQuestionIndex > lastQuestionIndex
                      }
                    >
                      <ContactForm
                        routeToConnectErrorPage={routeToConnectErrorPage}
                        routeToSuccessPage={routeToSuccessPage}
                        createOpportunities={createOpportunities}
                        isPreSelectProduct={isPreSelectProduct}
                        fontText={fontText}
                        fontTextBold={fontTextBold}
                        previousStep={this.handleGoBack}
                        linkColor={linkColor}
                      />
                    </ShowIfUtil>
                  </div>
                </div>
                <ShowIfUtil
                  condition={!this.isPreviewCheckoutPage && currQuestionIndex <= lastQuestionIndex}
                >
                  <div className="row">
                    <div className="col-12" style={{ zIndex: 5 }}>
                      <ShowIfUtil condition={currQuestionIndex <= lastQuestionIndex}>
                        <ProgressBar
                          currentQuestionIndex={currQuestionIndex}
                          lastQuestionIndex={lastQuestionIndex}
                        />
                        <ShowIfUtil condition={frontendConfiguration.isShowPackageInfo}>
                          <div className="ep--mt-24 d-md-none" />
                          <div
                            className="ep-customerjourneypage-tellus-wrapper d-none d-md-flex justify-content-between align-items-center ep--mt-8 ep--p-24"
                            style={CTAStyle}
                          >
                            <StyledSpan
                              className="ep__txt-regular ep__txt--h2"
                              font={fontText}
                              style={textColor}
                            >
                              {frontendConfiguration.designOptions.journeyBottomPanelText}
                            </StyledSpan>
                            <ShowIfUtil condition={frontendConfiguration.isCJQuestionsSkipEnable}>
                              <StyledButtonBold
                                font={fontTextBold}
                                className="ep-button ep__btn--large ep__txt-bold ep__txt--md ep--px-8 ep--py-0"
                                style={CTAButtonStyle}
                                onClick={this.showContactForm}
                                disabled={_.isEmpty(selectedProducts) || isUploading}
                              >
                                {buttonText}
                              </StyledButtonBold>
                            </ShowIfUtil>
                          </div>
                        </ShowIfUtil>
                      </ShowIfUtil>
                    </div>
                  </div>
                </ShowIfUtil>
              </div>
              <div className="col-12 col-md-6 col-lg-5">
                {this.isPreviewCheckoutPage || currQuestionIndex > lastQuestionIndex ? (
                  <div className="ep--mt-56">
                    <ShowIfUtil
                      condition={
                        frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY
                      }
                    >
                      {frontendConfiguration.onlinePurchaseSettingModel.active ? (
                        <MixedOrderSummary
                          fontText={fontText}
                          fontTextBold={fontTextBold}
                          productsWrapperHeight={SPECIAL_CHARACTER.EMPTY}
                          isPreSelectProduct={isPreSelectProduct}
                          isShowPackageInfo={frontendConfiguration.isShowPackageInfo}
                          defaultTextColor={frontendConfiguration.defaultTextColor}
                          secondaryTextColor={frontendConfiguration.secondaryTextColor}
                        />
                      ) : (
                        <OrderSummary
                          fontText={fontText}
                          fontTextBold={fontTextBold}
                          productsWrapperHeight={SPECIAL_CHARACTER.EMPTY}
                          isPreSelectProduct={isPreSelectProduct}
                          isShowPackageInfo={frontendConfiguration.isShowPackageInfo}
                        />
                      )}
                    </ShowIfUtil>

                    <ShowIfUtil
                      condition={
                        frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2
                      }
                    >
                      {frontendConfiguration.onlinePurchaseSettingModel.active ? (
                        <MixedOrderSummaryV2
                          fontText={fontText}
                          fontTextBold={fontTextBold}
                          productsWrapperHeight={SPECIAL_CHARACTER.EMPTY}
                          isPreSelectProduct={isPreSelectProduct}
                          isShowPackageInfo={frontendConfiguration.isShowPackageInfo}
                          defaultTextColor={frontendConfiguration.defaultTextColor}
                          secondaryTextColor={frontendConfiguration.secondaryTextColor}
                        />
                      ) : (
                        <OrderSummaryV2
                          fontText={fontText}
                          fontTextBold={fontTextBold}
                          productsWrapperHeight={SPECIAL_CHARACTER.EMPTY}
                          isPreSelectProduct={isPreSelectProduct}
                          isShowPackageInfo={frontendConfiguration.isShowPackageInfo}
                        />
                      )}
                    </ShowIfUtil>
                  </div>
                ) : frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY ? (
                  <Webshop
                    routeToConnectErrorPage={routeToConnectErrorPage}
                    routeToSuccessPage={routeToSuccessPage}
                    customerJourney
                    fontText={fontText}
                    fontTextBold={fontTextBold}
                    productsWrapperHeight={SPECIAL_CHARACTER.EMPTY}
                  >
                    <ShowIfUtil
                      condition={
                        !this.isPreviewCheckoutPage &&
                        currQuestionIndex <= lastQuestionIndex &&
                        frontendConfiguration.isCJQuestionsSkipEnable
                      }
                    >
                      <div className="ep--mt-24 d-md-none w-100">
                        <StyledButtonBold
                          font={fontTextBold}
                          className="ep-button ep__btn--large ep__txt-bold ep__txt--md w-100 ep--px-8 ep--py-0"
                          style={CTAButtonStyle}
                          onClick={this.showContactForm}
                          disabled={_.isEmpty(selectedProducts) || isUploading}
                        >
                          {buttonText}
                        </StyledButtonBold>
                      </div>
                    </ShowIfUtil>
                  </Webshop>
                ) : (
                  <WebshopV2
                    routeToConnectErrorPage={routeToConnectErrorPage}
                    routeToSuccessPage={routeToSuccessPage}
                    customerJourney
                    fontText={fontText}
                    fontTextBold={fontTextBold}
                    productsWrapperHeight={SPECIAL_CHARACTER.EMPTY}
                    isHouseConnectionProductV2={isHouseConnectionProductV2}
                  >
                    <ShowIfUtil
                      condition={
                        !this.isPreviewCheckoutPage &&
                        currQuestionIndex <= lastQuestionIndex &&
                        frontendConfiguration.isCJQuestionsSkipEnable
                      }
                    >
                      <div className="ep--mt-24 d-md-none w-100">
                        <StyledButtonBold
                          font={fontTextBold}
                          className="ep-button ep__btn--large ep__txt-bold ep__txt--md w-100 ep--px-8 ep--py-0"
                          style={CTAButtonStyle}
                          onClick={this.showContactForm}
                          disabled={_.isEmpty(selectedProducts) || isUploading}
                        >
                          {buttonText}
                        </StyledButtonBold>
                      </div>
                    </ShowIfUtil>
                  </WebshopV2>
                )}
              </div>
            </div>
            <ShowIfUtil
              condition={
                !this.isPreviewCheckoutPage &&
                !frontendConfiguration.isShowPackageInfo &&
                currQuestionIndex <= lastQuestionIndex
              }
            >
              <div
                className="row text-center ep--mt-8 ep--pt-16 ep--pb-16"
                style={customerJourneyCTAStyle}
              >
                <div className="col-12 col-md-6 col-lg-7 ep-customerjourneypage-tellus-wrapper__text">
                  <StyledSpan
                    font={fontText}
                    style={textColor}
                    className="ep__txt--h2 ep__txt-regular"
                  >
                    {frontendConfiguration.designOptions.journeyBottomPanelText}
                  </StyledSpan>
                </div>
                <div
                  className="col-12 col-md-6 col-lg-5 box"
                  style={{
                    paddingLeft: '5%',
                    paddingRight: '5%'
                  }}
                >
                  <div className="ep--mt-24 d-md-none" />
                  <ShowIfUtil condition={frontendConfiguration.isCJQuestionsSkipEnable}>
                    <StyledButtonBold
                      font={fontTextBold}
                      className="ep-button ep__btn--large ep__txt-bold ep__txt--md w-100 ep--px-8 ep--py-0"
                      style={CTAButtonStyle}
                      onClick={this.showContactForm}
                      disabled={_.isEmpty(selectedProducts) || isUploading}
                    >
                      {buttonText}
                    </StyledButtonBold>
                  </ShowIfUtil>
                </div>
              </div>
            </ShowIfUtil>
          </div>
        </ShowIfUtil>
      </div>
    );
  }
}

CustomerJourneyContainer.propTypes = {
  externalServiceRequestStatus: PropTypes.string.isRequired,
  resetProductSelections: PropTypes.func.isRequired,
  updateCustomerJourneyAnswer: PropTypes.func.isRequired,
  resetAnswerAfterChangePrivatelyOrBusiness: PropTypes.func.isRequired,
  updateCustomerJourneyMapping: PropTypes.func.isRequired,
  updateCurrentQuestions: PropTypes.func.isRequired,
  updateProductSelections: PropTypes.func.isRequired,
  routeToConnectErrorPage: PropTypes.func.isRequired,
  routeToSuccessPage: PropTypes.func.isRequired,
  isUseBusinessQuestions: PropTypes.bool.isRequired,
  currQuestions: PropTypes.array.isRequired,
  currQuestionIds: PropTypes.array.isRequired,
  businessQuestions: PropTypes.array.isRequired,
  questions: PropTypes.array.isRequired,
  currQuestionMap: PropTypes.object.isRequired,
  currQuestionIndexMap: PropTypes.object.isRequired,
  createOpportunities: PropTypes.func,
  geoQuestionStatus: PropTypes.string,
  selectedProducts: PropTypes.array,
  products: PropTypes.array,
  isUploading: PropTypes.bool.isRequired,
  checkingInternalProductAvailability: PropTypes.bool,
  allProductDeactivated: PropTypes.bool,
  allProductOnRequest: PropTypes.bool,
  isUnitHCQuestionExisted: PropTypes.bool,
  fontText: PropTypes.object,
  fontTextBold: PropTypes.object,
  linkColor: PropTypes.string,
  customerJourneyAnswer: PropTypes.object,
  frontendConfiguration: PropTypes.object,
  productSelections: PropTypes.object,
  routeManager: PropTypes.object,
  currMappingLabels: PropTypes.object.isRequired,
  deleteMappingLabel: PropTypes.func.isRequired
};

CustomerJourneyContainer.defaultProps = {
  selectedProducts: [],
  allProductDeactivated: true,
  allProductOnRequest: true,
  isUnitHCQuestionExisted: false,
  checkingInternalProductAvailability: false,
  createOpportunities: () => ({}),
  products: [],
  fontText: {},
  fontTextBold: {},
  linkColor: COLOR.DEFAULT_BLUE,
  customerJourneyAnswer: {},
  frontendConfiguration: {},
  productSelections: {},
  routeManager: {},
  geoQuestionStatus: ''
};

const mapStateToProps = state => {
  const frontendConfiguration = state.frontendConfiguration;
  const {
    currQuestions,
    currQuestionIds,
    currQuestionMap,
    currQuestionIndexMap
  } = cookingQuestionsSelector(state);
  const isUnitHCQuestionExisted = isUnitHCQuestionExistedSelector(state);
  const businessQuestions = state.frontendConfiguration.businessQuestions;
  const questions = state.frontendConfiguration.questions;
  const questionMap = state.frontendConfiguration.questionMap;
  const isUseBusinessQuestions = isUseBusinessQuestionsSelector(state);
  const currMappingLabels = state.currentState.currMappingLabels;
  const { checkingInternalProductAvailability } = state.customerJourneyAnswer;
  return {
    isUseBusinessQuestions,
    isUnitHCQuestionExisted,
    frontendConfiguration,
    customerJourneyAnswer: state.customerJourneyAnswer,
    currQuestions,
    currQuestionIds,
    currQuestionMap,
    currQuestionIndexMap,
    currMappingLabels,
    businessQuestions,
    questions,
    questionMap,
    checkingInternalProductAvailability,
    productSelections: productSelectionsSelector(state),
    geoQuestionStatus: geoQuestionStatusSelector(state),
    selectedProducts: selectedProductsSelector(state),
    products: productsSelector(state),
    routeManager: state.route,
    allProductDeactivated: isAllProductsDeactivatedSelector(state),
    allProductOnRequest: isAllProductsOnRequestSelector(state),
    externalServiceRequestStatus: state.externalProductAvailability.externalServiceRequestStatus,
    isUploading: state.uploadFileStatus.isUploading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateCustomerJourneyAnswer,
        updateFrontendConfiguration,
        updateCustomerJourneyMapping,
        updateProductSelections,
        updateCurrentQuestions,
        resetAnswerAfterChangePrivatelyOrBusiness,
        deleteMappingLabel,
        resetProductSelections
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerJourneyContainer);

import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import _ from 'lodash';

import {
  calculateHCElectricityMaxPower,
  calculateHCWaterMaxFlow,
  formatQuestion,
  ShowIconByQuestionIdUtil,
  ShowIfUtil
} from '../../common/utils';
import {
  addMappingLabel,
  deleteMappingLabel,
  updateCurrentPackage,
  updateCustomerJourneyAnswer,
  updateCustomerJourneyMapping
} from '../../store/actions';
import {
  COLOR,
  INPUT_TYPE,
  PACKAGE_LABEL_TYPE,
  QUESTION_ID,
  SPECIAL_CHARACTER,
  UNIT
} from '../../common/constants';
import {
  CheckBox,
  InfoHelpDescription,
  Input,
  StyledSpan
} from '../../components/epilot-forms/common';

class InputQuestionContainer extends PureComponent {
  onChange = _.debounce(value => {
    const {
      question,
      customerJourneyAnswer,
      updateCustomerJourneyAnswer,
      updateCustomerJourneyMapping
    } = this.props;
    let newCustomerJourneyAnswer = {};
    let newCustomerJourneyMapping = {};

    switch (question.id) {
      case QUESTION_ID.OUTPUT_OF_HEATING:
        newCustomerJourneyAnswer.outputHeating = value;
        newCustomerJourneyMapping.outputHeating = value;
        break;
      case QUESTION_ID.HOUSE_CONNECTION_APARTMENT_BUILDING:
        newCustomerJourneyAnswer.houseConnectionNumberOfApartment = value;
        break;
      case QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_MAX_POWER:
        newCustomerJourneyAnswer.houseConnectionElectricityMaxPower = value;
        break;
      case QUESTION_ID.HOUSE_CONNECTION_GAS_MAX_LOAD:
        newCustomerJourneyAnswer.houseConnectionGasMaxLoad = value;
        break;
      case QUESTION_ID.HOUSE_CONNECTION_WATER_MAX_FLOW:
        newCustomerJourneyAnswer.houseConnectionWaterMaxFlow = value;
        break;
      case QUESTION_ID.HOUSE_CONNECTION_TRENCH_LENGTH:
        newCustomerJourneyAnswer.houseConnectionTrenchLength = value;
        break;
      case QUESTION_ID.HOUSE_CONNECTION_WATER_STREET_FRONT_LENGTH:
        newCustomerJourneyAnswer.houseConnectionWaterStreetFrontLength = value;
        break;
      case QUESTION_ID.HOUSE_CONNECTION_WATER_PROPERTY_SIZE:
        newCustomerJourneyAnswer.houseConnectionWaterPropertySize = value;
        break;
      case QUESTION_ID.HOUSE_CONNECTION_WATER_FLOOR_SPACE:
        newCustomerJourneyAnswer.houseConnectionWaterFloorSpace = value;
        break;
      case QUESTION_ID.HOUSE_CONNECTION_E_MOBILITY_REQUIREMENT:
        newCustomerJourneyAnswer.eMobilityPower = value;
        newCustomerJourneyAnswer.totalAdditionalOutput =
          Number(customerJourneyAnswer.totalAdditionalOutput) +
          Number(value) -
          customerJourneyAnswer.eMobilityPower;
        newCustomerJourneyAnswer.houseConnectionElectricityMaxPower = calculateHCElectricityMaxPower(
          Number(newCustomerJourneyAnswer.totalAdditionalOutput),
          Number(value)
        );
        break;
      case QUESTION_ID.HOUSE_CONNECTION_PEAK_WATER:
        newCustomerJourneyAnswer.houseConnectionPeakWater = value;
        newCustomerJourneyAnswer.houseConnectionWaterMaxFlow = calculateHCWaterMaxFlow(
          Number(customerJourneyAnswer.houseConnectionExtinguishingWaterDemand),
          Number(value)
        );
        break;
      case QUESTION_ID.HOUSE_CONNECTION_OUTLET_PRESSURE_REQUIRED:
        newCustomerJourneyAnswer.houseConnectionOutletPressureRequired = value;
        break;
      default:
        newCustomerJourneyAnswer.name = value;
        break;
    }
    updateCustomerJourneyAnswer(newCustomerJourneyAnswer);
    updateCustomerJourneyMapping(newCustomerJourneyMapping);
  }, 300);

  constructor(props) {
    super(props);
    const { customerJourneyAnswer } = props;
    this.state = {
      checked: customerJourneyAnswer.isDigMyOwnTrench
    };
  }

  getInputValue = () => {
    const { question, customerJourneyAnswer } = this.props;

    switch (question.id) {
      case QUESTION_ID.OUTPUT_OF_HEATING:
        return customerJourneyAnswer.outputHeating.toString();
      case QUESTION_ID.HOUSE_CONNECTION_APARTMENT_BUILDING:
        return customerJourneyAnswer.houseConnectionNumberOfApartment.toString();
      case QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_MAX_POWER:
        return customerJourneyAnswer.houseConnectionElectricityMaxPower.toString();
      case QUESTION_ID.HOUSE_CONNECTION_GAS_MAX_LOAD:
        return customerJourneyAnswer.houseConnectionGasMaxLoad.toString();
      case QUESTION_ID.HOUSE_CONNECTION_WATER_MAX_FLOW:
        return customerJourneyAnswer.houseConnectionWaterMaxFlow.toString();
      case QUESTION_ID.HOUSE_CONNECTION_PEAK_WATER:
        return customerJourneyAnswer.houseConnectionPeakWater.toString();
      case QUESTION_ID.HOUSE_CONNECTION_TRENCH_LENGTH:
        if (customerJourneyAnswer.houseConnectionTrenchLength === SPECIAL_CHARACTER.EMPTY) {
          return '0';
        }
        return customerJourneyAnswer.houseConnectionTrenchLength.toString();
      case QUESTION_ID.HOUSE_CONNECTION_WATER_STREET_FRONT_LENGTH:
        if (
          customerJourneyAnswer.houseConnectionWaterStreetFrontLength === SPECIAL_CHARACTER.EMPTY
        ) {
          return '0';
        }
        return customerJourneyAnswer.houseConnectionWaterStreetFrontLength.toString();
      case QUESTION_ID.HOUSE_CONNECTION_WATER_PROPERTY_SIZE:
        if (customerJourneyAnswer.houseConnectionWaterPropertySize === SPECIAL_CHARACTER.EMPTY) {
          return '0';
        }
        return customerJourneyAnswer.houseConnectionWaterPropertySize.toString();
      case QUESTION_ID.HOUSE_CONNECTION_WATER_FLOOR_SPACE:
        if (customerJourneyAnswer.houseConnectionWaterFloorSpace === SPECIAL_CHARACTER.EMPTY) {
          return '0';
        }
        return customerJourneyAnswer.houseConnectionWaterFloorSpace.toString();
      case QUESTION_ID.HOUSE_CONNECTION_E_MOBILITY_REQUIREMENT:
        if (customerJourneyAnswer.eMobilityPower === SPECIAL_CHARACTER.EMPTY) {
          return '0';
        }
        return customerJourneyAnswer.eMobilityPower.toString();
      case QUESTION_ID.HOUSE_CONNECTION_OUTLET_PRESSURE_REQUIRED:
        return customerJourneyAnswer.houseConnectionOutletPressureRequired.toString();
      default:
        return customerJourneyAnswer.name;
    }
  };

  getInputLabel = () => {
    const { fontText, question, defaultTextColor } = this.props;
    const textColor = {
      color: defaultTextColor
    };

    switch (question.id) {
      case QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_MAX_POWER:
        return (
          <div className="ep-min-height-114">
            <StyledSpan className="ep__txt-no-select" font={fontText} style={textColor}>
              {`Strom (max. Leistungsbedarf)`}
            </StyledSpan>
          </div>
        );
      case QUESTION_ID.HOUSE_CONNECTION_GAS_MAX_LOAD:
        return (
          <div className="ep-min-height-114">
            <StyledSpan className="ep__txt-no-select" font={fontText} style={textColor}>
              {`Gas (max. Leistungsbedarf)`}
            </StyledSpan>
          </div>
        );
      case QUESTION_ID.HOUSE_CONNECTION_WATER_MAX_FLOW:
        return (
          <div className="ep-min-height-114">
            <StyledSpan className="ep__txt-no-select" font={fontText} style={textColor}>
              {`Wasser (max. Durchfluss)`}
            </StyledSpan>
          </div>
        );
      default:
        break;
    }
  };

  getInputUnit = () => {
    const { question } = this.props;

    switch (question.id) {
      case QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_MAX_POWER:
        return UNIT.KILOWALT;
      case QUESTION_ID.HOUSE_CONNECTION_GAS_MAX_LOAD:
        return UNIT.KILOWALT;
      case QUESTION_ID.HOUSE_CONNECTION_WATER_MAX_FLOW:
      case QUESTION_ID.HOUSE_CONNECTION_PEAK_WATER:
        return UNIT.WATER_FLOW;
      case QUESTION_ID.HOUSE_CONNECTION_WATER_STREET_FRONT_LENGTH:
      case QUESTION_ID.HOUSE_CONNECTION_TRENCH_LENGTH:
        return UNIT.METER;
      case QUESTION_ID.HOUSE_CONNECTION_WATER_FLOOR_SPACE:
      case QUESTION_ID.HOUSE_CONNECTION_WATER_PROPERTY_SIZE:
        return UNIT.SQUARE_METRE;
      case QUESTION_ID.HOUSE_CONNECTION_E_MOBILITY_REQUIREMENT:
        return UNIT.KILOWALT;
      case QUESTION_ID.HOUSE_CONNECTION_OUTLET_PRESSURE_REQUIRED:
        return UNIT.M_BAR;
      default:
        return;
    }
  };

  getPlaceholder = () => {
    const { question } = this.props;
    switch (question.id) {
      case QUESTION_ID.HOUSE_CONNECTION_E_MOBILITY_REQUIREMENT:
        return 'Leistungsbedarf';
      case QUESTION_ID.HOUSE_CONNECTION_OUTLET_PRESSURE_REQUIRED:
      case QUESTION_ID.HOUSE_CONNECTION_PEAK_WATER:
        return question.objectModel.inputConfig.inputPlaceholder;
      default:
        return question.questionLabel;
    }
  };

  isShowCheckbox = () => {
    const { question } = this.props;

    return (
      question.id === QUESTION_ID.HOUSE_CONNECTION_TRENCH_LENGTH &&
      question.objectModel.checkBoxConfig.isActive
    );
  };

  checkboxContent = () => {
    const { question } = this.props;
    if (question.id === QUESTION_ID.HOUSE_CONNECTION_TRENCH_LENGTH) {
      return question.objectModel.checkBoxConfig.title;
    }
  };

  onChangeCheckBox = () => {
    const {
      question,
      updateCustomerJourneyAnswer,
      addMappingLabel,
      deleteMappingLabel
    } = this.props;
    const { checked } = this.state;
    this.setState(
      {
        checked: !checked
      },
      () => {
        const { checked } = this.state;
        if (question.id === QUESTION_ID.HOUSE_CONNECTION_TRENCH_LENGTH) {
          let digMyOwnTrench = SPECIAL_CHARACTER.EMPTY;
          if (checked) {
            digMyOwnTrench = question.objectModel.checkBoxConfig.title;
          }
          if (
            question.objectModel &&
            question.objectModel.packageLabel &&
            question.objectModel.packageLabel.id !== -1 &&
            question.objectModel.packageLabel.label
          ) {
            if (checked) {
              addMappingLabel({
                [PACKAGE_LABEL_TYPE.TRENCH_LENGTH]: question.objectModel.packageLabel.label
              });
            } else {
              deleteMappingLabel(PACKAGE_LABEL_TYPE.TRENCH_LENGTH);
            }
          }
          updateCustomerJourneyAnswer({ isDigMyOwnTrench: checked, digMyOwnTrench });
        }
      }
    );
  };

  render() {
    const { frontendConfiguration, question, fontText, customerJourneyAnswer } = this.props;
    const { checked } = this.state;
    const textColor = {
      color: frontendConfiguration.defaultTextColor
    };

    return (
      <div className="ep-customerjourneypage__question">
        <ShowIconByQuestionIdUtil
          questionId={question.id}
          colorIcon={frontendConfiguration.primaryColor}
        />
        <div className="text-center ep-min-height-70 ep--pt-8 ep--pb-16">
          <StyledSpan
            font={fontText}
            className="ep__txt-regular ep__txt--lg ep__txt-no-select"
            style={textColor}
          >
            {formatQuestion(question.questionTitle, customerJourneyAnswer.name)}
          </StyledSpan>

          <InfoHelpDescription
            showHelpDescription={question.showHelpDescription}
            tooltipInfoContent={<StyledSpan font={fontText}>{question.helpDescription}</StyledSpan>}
            classNameForInfoIcon="ep__txt--lg ep--ml-8"
            defaultTextColor={frontendConfiguration.defaultTextColor}
          />
        </div>
        <div className="ep__txt-regular ep__txt--sm">{this.getInputLabel()}</div>
        <div className="ep-min-height-130">
          <Input
            questionId={question.id}
            fontSize="14px"
            height="56px"
            maxWidth="300px"
            minWidth="80px"
            textAlign="center"
            borderRadius={frontendConfiguration.borderRadius}
            label=""
            type={INPUT_TYPE.TEXT}
            placeholder={this.getPlaceholder()}
            require
            onChange={value => this.onChange(value)}
            value={this.getInputValue()}
            defaultTextColor={frontendConfiguration.defaultTextColor}
            fontFamily={fontText}
            borderColorFocus={frontendConfiguration.primaryColor}
            unit={this.getInputUnit()}
          />
          <ShowIfUtil condition={this.isShowCheckbox()}>
            <div className="d-flex align-items-center justify-content-center ep--mt-16">
              <CheckBox
                onChange={this.onChangeCheckBox}
                content={this.checkboxContent()}
                isChecked={checked}
                defaultTextColor={frontendConfiguration.defaultTextColor}
                primaryCheckBoxIconColor={frontendConfiguration.primaryColor}
                fontText={fontText}
              />
            </div>
          </ShowIfUtil>
        </div>
      </div>
    );
  }
}

InputQuestionContainer.propTypes = {
  question: PropTypes.object,
  customerJourneyAnswer: PropTypes.object,
  frontendConfiguration: PropTypes.object,
  fontText: PropTypes.object,
  defaultTextColor: PropTypes.string,
  updateCustomerJourneyAnswer: PropTypes.func,
  updateCustomerJourneyMapping: PropTypes.func,
  addMappingLabel: PropTypes.func.isRequired,
  deleteMappingLabel: PropTypes.func.isRequired
};

InputQuestionContainer.defaultProps = {
  question: {},
  customerJourneyAnswer: {},
  frontendConfiguration: {},
  fontText: {},
  defaultTextColor: COLOR.DARK_GRAY,
  updateCustomerJourneyAnswer: () => ({}),
  updateCustomerJourneyMapping: () => ({})
};

const mapStateToProps = state => {
  return {
    frontendConfiguration: state.frontendConfiguration,
    customerJourneyAnswer: state.customerJourneyAnswer,
    customerJourneyMapping: state.customerJourneyMapping
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateCustomerJourneyAnswer,
        updateCustomerJourneyMapping,
        addMappingLabel,
        deleteMappingLabel,
        updateCurrentPackage
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputQuestionContainer);

import React from 'react';
import Dropzone from 'react-dropzone';
import _ from 'lodash';
import * as PropTypes from 'prop-types';
import { COLOR, SPECIAL_CHARACTER } from '../constants';
import { StyledLabel, StyledSpan } from '../../components';
import { ShowIfUtil } from './ShowIfUtil';
import { isValidFileSize, isValidFileName, getErrMessageUploadFiles } from '.';

export class DropZoneFile extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      multipleUpload: props.multipleUpload,
      files: [],
      isShow: true,
      errMessage: SPECIAL_CHARACTER.EMPTY
    };
  }

  onDrop = (approvedFiles, rejectedFiles) => {
    let { files } = this.state;
    let tmpUploadedFiles = [];

    _.forEach(approvedFiles, file => {
      if (isValidFileSize(file) && isValidFileName(files, file)) {
        tmpUploadedFiles.push(file);
      }
    });

    this.setState(
      {
        files: [...files, ...tmpUploadedFiles],
        errMessage: getErrMessageUploadFiles(files, approvedFiles, rejectedFiles)
      },
      () => {
        this.updateFiles();
      }
    );
  };

  removeFile = index => {
    let { files } = this.state;
    let newFiles = _.assign([], files);
    newFiles.splice(index, 1);

    this.setState(
      {
        files: newFiles
      },
      () => {
        this.updateFiles();
      }
    );
  };

  hideError = () => {
    this.setState({ errMessage: SPECIAL_CHARACTER.EMPTY });
  };

  updateFiles = () => {
    const { onUpdateData } = this.props;
    let { files } = this.state;
    onUpdateData(files);
  };

  renderFileList = () => {
    const { files, isShow } = this.state;
    const { fontText, defaultTextColor, linkColor } = this.props;
    return _.map(files, (file, index) => (
      <div
        className="d-flex justify-content-between align-items-center ep--mt-8 ep--mb-8"
        key={index}
      >
        <div className="d-flex align-items-center">
          <i className="ep-icon-attach-87" style={{ color: defaultTextColor }} />
          <StyledSpan
            className="ep__txt--md ep__txt-semibold ep__txt-no-select ep--ml-8"
            style={{ color: linkColor }}
            font={fontText}
          >
            {file.name}
          </StyledSpan>
        </div>
        <ShowIfUtil condition={isShow}>
          <i
            className="ep-icon-trash-simple ep-cursor-pointer float-right"
            onClick={() => this.removeFile(index)}
            style={{ color: defaultTextColor }}
          />
        </ShowIfUtil>
      </div>
    ));
  };

  render() {
    const {
      uploadInstruction,
      label,
      fontText,
      primaryTextColor,
      defaultTextColor,
      require,
      acceptFileType,
      isUploading
    } = this.props;
    const { multipleUpload, errMessage } = this.state;
    const textColor = {
      color: defaultTextColor
    };
    const errorStyle = {
      color: COLOR.ERROR_TEXT_COLOR,
      textAlign: 'center'
    };
    return (
      <React.Fragment>
        <ShowIfUtil condition={label !== SPECIAL_CHARACTER.EMPTY}>
          <StyledLabel
            className="ep__txt--sm ep__txt-regular ep__txt-no-select"
            font={fontText}
            style={{ color: primaryTextColor }}
          >
            {label}
            <ShowIfUtil condition={require}>
              <StyledSpan
                className="ep__txt--sm ep__txt-regular ep__txt-no-select"
                font={fontText}
                style={{ color: primaryTextColor }}
              >
                {' *'}
              </StyledSpan>
            </ShowIfUtil>
          </StyledLabel>
        </ShowIfUtil>
        <Dropzone
          multiple={multipleUpload}
          accept={acceptFileType}
          onDrop={this.onDrop}
          style={{ background: COLOR.WHITE }}
          disabled={isUploading}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              className="ep-cursor-pointer row justify-content-center align-items-center ep-dropzone ep--ml-0"
            >
              <input {...getInputProps()} />
              <i className="ep-icon-cloud-upload-94 p-8" style={textColor} />
              <StyledSpan className="ep__txt--sm ml-2" font={fontText} style={textColor}>
                {uploadInstruction}
              </StyledSpan>
            </div>
          )}
        </Dropzone>
        <div className="ep--mt-16">{this.renderFileList()}</div>
        <ShowIfUtil condition={errMessage !== SPECIAL_CHARACTER.EMPTY}>
          <div className="text-center ep--pt-8" style={{ height: '61px' }}>
            <StyledSpan
              className="ep__txt--required ep__txt-regular ep__txt-no-select"
              font={fontText}
              style={errorStyle}
            >
              {errMessage}
            </StyledSpan>
            <span className="ep-cursor-pointer" style={errorStyle} onClick={this.hideError}>
              {'   x'}
            </span>
          </div>
        </ShowIfUtil>
      </React.Fragment>
    );
  }
}

DropZoneFile.propTypes = {
  uploadInstruction: PropTypes.string,
  isUploading: PropTypes.bool.isRequired,
  multipleUpload: PropTypes.bool,
  onUpdateData: PropTypes.func,
  primaryTextColor: PropTypes.string,
  defaultTextColor: PropTypes.string,
  linkColor: PropTypes.string,
  label: PropTypes.string,
  fontText: PropTypes.object,
  require: PropTypes.bool,
  acceptFileType: PropTypes.string.isRequired
};

DropZoneFile.defaultProps = {
  uploadInstruction: '',
  primaryTextColor: COLOR.DARK_GRAY,
  defaultTextColor: COLOR.DARK_GRAY,
  linkColor: COLOR.DEFAULT_BLUE,
  label: SPECIAL_CHARACTER.EMPTY,
  fontText: {},
  require: false,
  multipleUpload: true,
  onUpdateData: () => ({})
};

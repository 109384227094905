import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import $ from 'jquery';
import { connect } from 'react-redux';
import { RadioLabel, StyledSpan } from '../../components/epilot-forms/common';
import { COLOR, FRONTEND_TYPE, MESSAGE_CONSTANTS, PAYMENT_OPTION } from '../../common/constants';
import { CustomCollapse } from '../../common/utils';
import PayPalContainer from './PayPalContainer';
import {
  isSomeSelectedProductOnRequestSelector,
  totalPriceCalcSelector,
  totalPriceCalcV2Selector
} from '../../store/selectors';
import { updatePaymentInfo } from '../../store/actions';

class PaymentOptionsContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedPayment: ''
    };
  }

  componentDidMount() {
    this.handleSelectDefaultPayment();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { primaryColor, borderColorFocus } = this.props;
    const { selectedPayment } = this.state;

    if (prevState.selectedPayment !== selectedPayment) {
      const selectedPaymentElem = $(`.${selectedPayment}`);
      selectedPaymentElem.css({ color: primaryColor });
      selectedPaymentElem
        .find('.pt-control-indicator')
        .css({ border: `1px solid ${borderColorFocus}`, background: `${COLOR.WHITE}` });
      selectedPaymentElem.find('.pt-control-indicator').addClass('pt-modify-before');
    }
  };

  handleSelectDefaultPayment = () => {
    const {
      updateData,
      updatePaymentInfo,
      frontendConfiguration,
      paymentInfo,
      isSomeSelectedProductOnRequest
    } = this.props;
    const payment =
      Array.isArray(frontendConfiguration.onlinePurchaseSettingModel.paymentMethods) &&
      frontendConfiguration.onlinePurchaseSettingModel.paymentMethods.length > 0
        ? frontendConfiguration.onlinePurchaseSettingModel.paymentMethods.find(item => item.active)
        : null;
    let selectedPayment;
    if (
      isSomeSelectedProductOnRequest &&
      paymentInfo.paymentType === PAYMENT_OPTION.PAYMENTBYPAYPAL
    ) {
      selectedPayment = payment.paymentType;
    } else {
      selectedPayment = paymentInfo.paymentType ? paymentInfo.paymentType : payment.paymentType;
    }

    if (payment) {
      this.setState({ selectedPayment: selectedPayment });
      updateData({ selectedPayment: selectedPayment });
      updatePaymentInfo({ ...paymentInfo, paymentType: selectedPayment });
    }
  };

  selectPaymentOption = e => {
    const { updateData, updatePaymentInfo } = this.props;

    if (e.target.value) {
      this.setState({ selectedPayment: e.target.value });
      updateData({ selectedPayment: e.target.value });
      updatePaymentInfo({
        paymentType: e.target.value,
        paid: false,
        orderId: '',
        authorizationId: '',
        buyerEmail: ''
      });
    }
  };

  onCollapse = () => {
    const { setContactFormHeight } = this.props;

    setTimeout(() => {
      setContactFormHeight();
    }, 100);
  };

  renderCompletedContent = () => {
    const { defaultTextColor } = this.props;
    return (
      <div className="w-100">
        <div className="ep--border-radius-8 ep--p-32">
          <div className="ep__txt--md ep__txt-bold" style={{ color: defaultTextColor }}>
            {PAYMENT_OPTION.PAYMENT_BY_INVOICE}
          </div>
        </div>
      </div>
    );
  };

  enablePaymentOption = paymentType => {
    const { frontendConfiguration, totalPrice, isSomeSelectedProductOnRequest } = this.props;

    if (paymentType !== PAYMENT_OPTION.PAYMENTBYINVOICE) {
      if (frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2) {
        return !isSomeSelectedProductOnRequest;
      } else {
        return _.isNumber(totalPrice.totalPrice) && totalPrice.totalPrice > 0;
      }
    }
    return true;
  };

  render() {
    const {
      frontendConfiguration,
      onlinePurchaseSettingModel,
      defaultTextColor,
      borderRadius,
      fontFamily,
      primaryColor,
      paymentOptionsData,
      totalPrice,
      totalPriceV2,
      isSomeSelectedProductOnRequest
    } = this.props;

    const { selectedPayment } = this.state;

    const textColor = {
      color: defaultTextColor
    };

    return (
      <div className="w-100">
        <style>
          {`.pt-control-indicator.pt-modify-before:before {
                         width: 10px !important;
                         height: 10px !important;
                         background-color: ${primaryColor} !important;
                     }`}
        </style>
        <CustomCollapse
          fontText={fontFamily}
          defaultTextColor={defaultTextColor}
          title="Zahlungsoptionen"
          completedContent={this.renderCompletedContent()}
          onCollapse={this.onCollapse}
          errorMessage={MESSAGE_CONSTANTS.CHECK_ALL_REQUIRED_FIELD}
          isCompleted={paymentOptionsData.isDataValid}
          isError={paymentOptionsData.isDataValid !== undefined && !paymentOptionsData.isDataValid}
        >
          <div className="ep--border-radius-8 ep--p-32">
            <div className="ep__txt--md ep__txt-bold text-center">
              <StyledSpan font={fontFamily} style={textColor}>
                Bitte die gewünschte Zahlungsart auswählen
              </StyledSpan>
            </div>
            {Array.isArray(onlinePurchaseSettingModel.paymentMethods) &&
              onlinePurchaseSettingModel.paymentMethods.length > 0 &&
              onlinePurchaseSettingModel.paymentMethods.map(item => {
                const isShowPaypalButton =
                  item.paymentType === PAYMENT_OPTION.PAYMENTBYPAYPAL &&
                  !isSomeSelectedProductOnRequest;
                return (
                  item.active && (
                    <div className="mx-auto payment__option--wrapper" key={item.paymentType}>
                      {item.paymentType === PAYMENT_OPTION.PAYMENTBYPAYPAL && isShowPaypalButton ? (
                        <div
                          className="d-flex payment__option--radio align-items-center ep--pt-8 ep--mt-32"
                          style={{ borderRadius }}
                        >
                          <RadioLabel
                            className={item.paymentType}
                            onChange={this.selectPaymentOption}
                            name={item.paymentType}
                            value={item.paymentType}
                            isChecked={item.paymentType === selectedPayment}
                            primaryColor={primaryColor}
                            defaultTextColor={defaultTextColor}
                            fontFamily={fontFamily}
                            titleFont="ep__txt--lg ep__txt-bold"
                            content={PAYMENT_OPTION[item.paymentType]}
                            disabled={!this.enablePaymentOption(item.paymentType)}
                          />
                        </div>
                      ) : item.paymentType !== PAYMENT_OPTION.PAYMENTBYPAYPAL ? (
                        <div
                          className="d-flex payment__option--radio align-items-center ep--pt-8 ep--mt-32"
                          style={{ borderRadius }}
                        >
                          <RadioLabel
                            className={item.paymentType}
                            onChange={this.selectPaymentOption}
                            name={item.paymentType}
                            value={item.paymentType}
                            isChecked={item.paymentType === selectedPayment}
                            primaryColor={primaryColor}
                            defaultTextColor={defaultTextColor}
                            fontFamily={fontFamily}
                            titleFont="ep__txt--lg ep__txt-bold"
                            content={PAYMENT_OPTION[item.paymentType]}
                            disabled={!this.enablePaymentOption(item.paymentType)}
                          />
                        </div>
                      ) : null}
                      {item.paymentType === PAYMENT_OPTION.PAYMENTBYINVOICE &&
                        selectedPayment === PAYMENT_OPTION.PAYMENTBYINVOICE && (
                          <>
                            <div className="ep--mt-8 ep--mb-32">
                              <StyledSpan
                                className="ep__txt--sm ep__txt-bold ep-text-dark-grey"
                                style={textColor}
                                font={fontFamily}
                              >
                                {'Die Rechnung wird an die angegebene Rechnungsadresse versendet.'}
                              </StyledSpan>
                            </div>
                          </>
                        )}
                      {isShowPaypalButton && selectedPayment === PAYMENT_OPTION.PAYMENTBYPAYPAL && (
                        <>
                          <div className="ep--mt-8">
                            <PayPalContainer
                              amount={
                                frontendConfiguration.frontendType ===
                                FRONTEND_TYPE.CUSTOMER_JOURNEY_V2
                                  ? totalPriceV2.oneTimePrice
                                  : totalPrice.totalPrice
                              }
                              isPayPalSelected={selectedPayment === PAYMENT_OPTION.PAYMENTBYPAYPAL}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  )
                );
              })}
          </div>
        </CustomCollapse>
      </div>
    );
  }
}

PaymentOptionsContainer.propTypes = {
  frontendConfiguration: PropTypes.object,
  onlinePurchaseSettingModel: PropTypes.object,
  paymentOptionsData: PropTypes.object,
  defaultTextColor: PropTypes.string,
  borderRadius: PropTypes.string,
  fontFamily: PropTypes.object,
  primaryColor: PropTypes.string,
  borderColorFocus: PropTypes.string,
  updateData: PropTypes.func,
  setContactFormHeight: PropTypes.func,
  totalPrice: PropTypes.object,
  totalPriceV2: PropTypes.object,
  isSomeSelectedProductOnRequest: PropTypes.bool.isRequired,
  updatePaymentInfo: PropTypes.func,
  paymentInfo: PropTypes.object
};

PaymentOptionsContainer.defaultProps = {
  frontendConfiguration: {},
  onlinePurchaseSettingModel: {},
  paymentOptionsData: {},
  primaryColor: COLOR.DEFAULT_BLUE,
  defaultTextColor: COLOR.DARK_GRAY,
  fontFamily: {},
  borderRadius: '',
  borderColorFocus: '',
  updateData: () => {},
  setContactFormHeight: () => {},
  totalPrice: {},
  totalPriceV2: {},
  updatePaymentInfo: () => {},
  paymentInfo: {}
};

function mapStateToProps(state) {
  return {
    frontendConfiguration: state.frontendConfiguration,
    totalPrice: totalPriceCalcSelector(state),
    totalPriceV2: totalPriceCalcV2Selector(state),
    isSomeSelectedProductOnRequest: isSomeSelectedProductOnRequestSelector(state),
    paymentInfo: state.paymentInfo
  };
}

export default connect(
  mapStateToProps,
  { updatePaymentInfo }
)(PaymentOptionsContainer);

import {
  PAYMENT_TYPE,
  PURCHASE_TYPE,
  ON_REQUEST,
  FRONTEND_TYPE,
  SELLING_OPTION_TYPE,
  PAYMENT_FREQUENCY,
  PAYMENT_OPTION,
  ONLINE_PURCHASE_PAYMENT_TYPE
} from '../constants';
import { checkIsOnlinePurchase, checkIsOnlinePurchaseV2 } from './ProductUtils';

export const checkOnlinePurchase = (
  frontendConfiguration,
  totalPrice,
  productSelections,
  selectedProducts,
  onlinePurchaseOnlyRequest,
  totalPriceV2
) => {
  return frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2
    ? checkIsOnlinePurchaseV2(frontendConfiguration, totalPriceV2) === PURCHASE_TYPE.ONLINE_PURCHASE
    : checkIsOnlinePurchase({
        frontendConfiguration,
        productSelections,
        totalPrice,
        selectedProducts,
        onlinePurchaseOnlyRequest
      }) === PURCHASE_TYPE.ONLINE_PURCHASE;
};

export const checkPurchaseType = (paymentType, totalPrice, onlinePurchaseActive) => {
  let result = PURCHASE_TYPE.REQUEST;
  if (onlinePurchaseActive) {
    if (paymentType === PAYMENT_TYPE.PURCHASE && totalPrice.totalPriceGross >= 0) {
      result = PURCHASE_TYPE.ONLINE_PURCHASE;
    }
    if (
      paymentType === PAYMENT_TYPE.RENTAL &&
      (totalPrice.totalPriceGross >= 0 ||
        (totalPrice.totalUpfrontFeeGross >= 0 &&
          totalPrice.totalPurchasePriceGross === totalPrice.totalUpfrontFeeGross))
    ) {
      result = PURCHASE_TYPE.ONLINE_PURCHASE;
    }
  }
  return result;
};

export const checkPurchaseTypeV2 = (totalPrice, onlinePurchaseActive) => {
  let result = PURCHASE_TYPE.REQUEST;
  if (onlinePurchaseActive) {
    if (
      totalPrice.oneTimePrice !== ON_REQUEST &&
      totalPrice.monthlyPrice !== ON_REQUEST &&
      totalPrice.yearlyPrice !== ON_REQUEST
    ) {
      result = PURCHASE_TYPE.ONLINE_PURCHASE;
    }
  }
  return result;
};

export const isSellingOptionOneTime = sellingOptionType =>
  !!(sellingOptionType && sellingOptionType === SELLING_OPTION_TYPE.ONE_TIME);

export const isSellingOptionRecurring = sellingOptionType =>
  !!(sellingOptionType && sellingOptionType === SELLING_OPTION_TYPE.RECURRING);

export const isPaymentFrequencyOneTime = paymentFrequency =>
  !!(paymentFrequency && paymentFrequency === PAYMENT_FREQUENCY.ONE_TIME);

export const isPaymentFrequencyMonthly = paymentFrequency =>
  !!(paymentFrequency && paymentFrequency === PAYMENT_FREQUENCY.MONTHLY);

export const isPaymentFrequencyYearly = paymentFrequency =>
  !!(paymentFrequency && paymentFrequency === PAYMENT_FREQUENCY.YEARLY);

export const isPaymentFrequencyRecurring = paymentFrequency =>
  !!(isPaymentFrequencyMonthly(paymentFrequency) && isPaymentFrequencyYearly(paymentFrequency));

export const getOnlinePurchasePaymentType = paymentType => {
  switch (paymentType) {
    case PAYMENT_OPTION.PAYMENTBYINVOICE:
      return ONLINE_PURCHASE_PAYMENT_TYPE.PAYMENT_BY_INVOICE;
    case PAYMENT_OPTION.SEPADIRECTDEBIT:
      return ONLINE_PURCHASE_PAYMENT_TYPE.SEPA_DIRECT_DEBIT;
    case PAYMENT_OPTION.CREDITCARD:
      return ONLINE_PURCHASE_PAYMENT_TYPE.CREDIT_CARD;
    case PAYMENT_OPTION.PAYMENTBYPAYPAL:
      return ONLINE_PURCHASE_PAYMENT_TYPE.PAYPAL;
    default:
      return ONLINE_PURCHASE_PAYMENT_TYPE.PAYMENT_BY_INVOICE;
  }
};

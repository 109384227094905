import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { CHECKBOXES, COLOR, SPECIAL_CHARACTER } from '../../common/constants';
import { CheckBoxReadAccept } from '.';
import { ShowIfUtil } from '../../common/utils';

const GroupCheckboxes = ({
  className,
  fontText,
  defaultTextColor,
  primaryColor,
  checkboxConfig,
  onChange,
  checkboxesErrorMessage,
  selectedCheckboxes
}) => {
  const [policyLabelCheckBox, setPolicyLabelCheckBox] = useState(selectedCheckboxes.policyLabel);
  const [advertisingCheckBox, setAdvertisingCheckBox] = useState(selectedCheckboxes.advertising);
  const [otherCheckboxes, setOtherCheckboxes] = useState(selectedCheckboxes.otherCheckboxes);

  const onChangeCheckBox = (name, value, label, checkboxLabel, index) => {
    let cloneOtherCheckboxes;
    const active = true;
    switch (name) {
      case CHECKBOXES.POLICY_LABEL:
        setPolicyLabelCheckBox({ label, checkboxLabel, value });
        onChange(name, { label, checkboxLabel, value, active });
        break;
      case CHECKBOXES.ADVERTISING:
        setAdvertisingCheckBox({ label, checkboxLabel, value });
        onChange(name, { label, checkboxLabel, value, active });
        break;
      case CHECKBOXES.OTHER_CHECKBOXES:
        cloneOtherCheckboxes = _.assignIn([], otherCheckboxes);
        cloneOtherCheckboxes[index] = { label, checkboxLabel, value, active };
        setOtherCheckboxes(cloneOtherCheckboxes);
        onChange(name, cloneOtherCheckboxes);
        break;
      default:
        break;
    }
  };

  const renderPolicyLabel = () => {
    return (
      <div className="ep--mb-8 order-0">
        <CheckBoxReadAccept
          require={checkboxConfig.policyLabel.required}
          onChange={(e, policyLabel, policyLabelHTML) =>
            onChangeCheckBox(
              CHECKBOXES.POLICY_LABEL,
              e.target.checked,
              policyLabel,
              policyLabelHTML
            )
          }
          isChecked={policyLabelCheckBox && policyLabelCheckBox.value}
          defaultTextColor={defaultTextColor}
          primaryColor={primaryColor}
          fontText={fontText}
          checkboxConfig={policyLabelCheckBox}
          checkboxLinks={checkboxConfig.checkboxLinks}
          policiesCheckboxChecked={checkboxesErrorMessage[CHECKBOXES.POLICY_LABEL]}
        />
      </div>
    );
  };

  const renderAdvertising = () => {
    return (
      <div className="ep--mb-8 order-1">
        <CheckBoxReadAccept
          require={checkboxConfig.advertising.required}
          onChange={(e, advertisingLabel, advertisingLabelHTML) =>
            onChangeCheckBox(
              CHECKBOXES.ADVERTISING,
              e.target.checked,
              advertisingLabel,
              advertisingLabelHTML
            )
          }
          isChecked={advertisingCheckBox && advertisingCheckBox.value}
          defaultTextColor={defaultTextColor}
          primaryColor={primaryColor}
          fontText={fontText}
          checkboxConfig={advertisingCheckBox}
          checkboxLinks={checkboxConfig.checkboxLinks}
          policiesCheckboxChecked={checkboxesErrorMessage[CHECKBOXES.ADVERTISING]}
        />
      </div>
    );
  };

  const renderOtherCheckboxes = () => {
    return _.map(checkboxConfig.otherCheckboxes, (item, index) => {
      if (item.active) {
        return (
          <ShowIfUtil condition={item.active} key={index + 2}>
            <div className={`ep--mb-8 order-${index + 2}`}>
              <CheckBoxReadAccept
                require={item.required}
                onChange={(e, otherCheckboxLabel, otherCheckboxLabelHTML) =>
                  onChangeCheckBox(
                    CHECKBOXES.OTHER_CHECKBOXES,
                    e.target.checked,
                    otherCheckboxLabel,
                    otherCheckboxLabelHTML,
                    index
                  )
                }
                isChecked={otherCheckboxes[index] && otherCheckboxes[index].value}
                defaultTextColor={defaultTextColor}
                primaryColor={primaryColor}
                fontText={fontText}
                checkboxConfig={otherCheckboxes[index]}
                checkboxLinks={checkboxConfig.checkboxLinks}
                policiesCheckboxChecked={
                  checkboxesErrorMessage[CHECKBOXES.OTHER_CHECKBOXES] &&
                  checkboxesErrorMessage[CHECKBOXES.OTHER_CHECKBOXES][index]
                }
              />
            </div>
          </ShowIfUtil>
        );
      }
    });
  };

  return (
    <div className={`d-flex flex-column ${className}`}>
      <ShowIfUtil condition={checkboxConfig.policyLabel && checkboxConfig.policyLabel.active}>
        {renderPolicyLabel()}
      </ShowIfUtil>
      <ShowIfUtil condition={checkboxConfig.advertising && checkboxConfig.advertising.active}>
        {renderAdvertising()}
      </ShowIfUtil>
      <ShowIfUtil condition={!_.isEmpty(checkboxConfig.otherCheckboxes)}>
        {renderOtherCheckboxes()}
      </ShowIfUtil>
    </div>
  );
};

GroupCheckboxes.propTypes = {
  defaultTextColor: PropTypes.string,
  primaryColor: PropTypes.string,
  fontText: PropTypes.object,
  checkboxConfig: PropTypes.object,
  checkboxesErrorMessage: PropTypes.object,
  selectedCheckboxes: PropTypes.object,
  className: PropTypes.string,
  onChange: PropTypes.func
};

GroupCheckboxes.defaultProps = {
  className: SPECIAL_CHARACTER.EMPTY,
  defaultTextColor: COLOR.GRAY,
  primaryColor: COLOR.DEFAULT_BLUE,
  fontText: {},
  checkboxConfig: {},
  checkboxesErrorMessage: {},
  selectedCheckboxes: {},
  onChange: () => {}
};

export default GroupCheckboxes;

import _ from 'lodash';
import { RENTAL_UNIT } from '../constants';

export const filterMonthlyPriceByPackageType = (productSelections, packageType, packageSelected) => {
  let packageRentalType = packageType ? 1 : 0;
  let monthlyPrice = 0;

  if (packageSelected.packageRentals == null) {
    monthlyPrice = packageRentalType === 0 ? packageSelected.standardRetailPrice : packageSelected.premiumRetailPrice;
  } else {
    _.forEach(packageSelected.packageRentals, rental => {
      if (
        rental.packageRentalType === packageRentalType &&
        rental.duration === productSelections.selectedRentalInfo.duration &&
        rental.unit === RENTAL_UNIT[productSelections.selectedRentalInfo.unit]
      ) {
        monthlyPrice = rental.price;
      }
    });
  }
  return monthlyPrice;
};

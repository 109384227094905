import _ from 'lodash';
import { FRONTEND_CONFIGURATION_SUCCESS } from '../actions';
import {
  CURRENCY,
  FIXED,
  PACKAGE_PRICING_COMPONENT_TYPE,
  PAYMENT_FREQUENCY,
  PRICING_COMP_RANGE_TYPE,
  PRODUCT_TYPE
} from '../../common/constants';
import {
  isPaymentFrequencyOneTime,
  isPaymentFrequencyRecurring,
  isSellingOptionOneTime,
  isSellingOptionRecurring
} from '../../common/utils';

const initState = {};

export default (state = initState, action) => {
  switch (action.type) {
    case FRONTEND_CONFIGURATION_SUCCESS:
      return {
        ...state,
        ...parsePackageConfig(action.payload)
      };
    default:
      return state;
  }
};

const parsePackageConfig = data => {
  let packageConfig = {};
  const products = data.products;
  const productTypePackageMap = _.mapValues(_.keyBy(products, 'type'), 'productPackages');
  _.forIn(productTypePackageMap, (packages, productType) => {
    packageConfig[productType] = {
      ...generatePackageConfig({ packages, productType })
    };
  });
  return packageConfig;
};

export const generatePackageConfig = ({ packages, productType }) => {
  let packageMap = _.keyBy(packages, 'id');
  let packageSellingOptionMap = _.mapValues(_.keyBy(packages, 'id'), 'sellingOptions');
  let sellingOptionMap = parseSellingOption({ packageSellingOptionMap, packageMap, productType });
  let [cheapestPackageId, cheapestSellingOptionId] = findCheapestPackageAndSellingOptionId(
    sellingOptionMap
  );
  const isPackageAlwaysShowOnRequest =
    cheapestSellingOptionId &&
    sellingOptionMap[cheapestSellingOptionId] &&
    sellingOptionMap[cheapestSellingOptionId].alwaysShowOnRequest;
  return {
    packages: packages,
    packageMap: packageMap,
    packageSellingOptionMap: packageSellingOptionMap,
    sellingOptionMap: sellingOptionMap,
    cheapestPackageId: cheapestPackageId,
    cheapestSellingOptionId: cheapestSellingOptionId,
    isPackageAlwaysShowOnRequest: isPackageAlwaysShowOnRequest
  };
};

const isOnRequest = sellingOption => {
  for (const packagePricingComponent of sellingOption.packagePricingComponents) {
    if (
      (isSellingOptionOneTime(sellingOption.sellingOptionType) &&
        isPaymentFrequencyRecurring(packagePricingComponent.pricingComponent.paymentFrequency)) ||
      (isSellingOptionRecurring(sellingOption.sellingOptionType) &&
        isPaymentFrequencyOneTime(packagePricingComponent.pricingComponent.paymentFrequency))
    ) {
      return true;
    }
  }
  return false;
};

const parseSellingOption = ({ packageSellingOptionMap, packageMap, productType }) => {
  let compConfig = {};
  _.forIn(packageSellingOptionMap, (sellingOptions, packageId) => {
    _.forEach(sellingOptions, sellingOption => {
      const {
        packagePricingComponents,
        duration,
        durationUnit,
        alwaysShowOnRequest,
        title: sellingOptionTitle
      } = sellingOption;

      const [
        staticComps,
        kwComps,
        kwhComps,
        meterComps,
        meterStreetFrontLengthForHCWaterComps,
        housingUnitForHCWaterComps,
        squareMetrePropertySizeForHCWaterComps,
        squareMetreFloorSizeForHCWaterComps,
        literPerSecComps
      ] = filterPackagePricingComps({ packagePricingComponents });

      const [
        noRangeKWComps,
        specificRangeKWComps,
        toInfinityRangeKWComps
      ] = filterPricingCompRangeType(kwComps);

      const [
        noRangeMeterComps,
        specificRangeMeterComps,
        toInfinityRangeMeterComps
      ] = filterPricingCompRangeType(meterComps);

      const [
        noRangeMeterStreetFrontLengthForHCWaterComps,
        specificRangeMeterStreetFrontLengthForHCWaterComps,
        toInfinityRangeMeterStreetFrontLengthForHCWaterComps
      ] = filterPricingCompRangeType(meterStreetFrontLengthForHCWaterComps);

      const [
        noRangeSquareMetrePropertySizeForHCWaterComps,
        specificRangeSquareMetrePropertySizeForHCWaterComps,
        toInfinityRangeSquareMetrePropertySizeForHCWaterComps
      ] = filterPricingCompRangeType(squareMetrePropertySizeForHCWaterComps);

      const [
        noRangeSquareMetreFloorSizeForHCWaterComps,
        specificRangeSquareMetreFloorSizeForHCWaterComps,
        toInfinityRangeSquareMetreFloorSizeForHCWaterComps
      ] = filterPricingCompRangeType(squareMetreFloorSizeForHCWaterComps);

      const [
        noRangeHousingUnitForHCWaterComps,
        specificRangeHousingUnitForHCWaterComps,
        toInfinityRangeHousingUnitForHCWaterComps
      ] = filterPricingCompRangeType(housingUnitForHCWaterComps);

      const [
        noRangeLiterPerSecComps,
        specificRangeLiterPerSecComps,
        toInfinityRangeLiterPerSecComps
      ] = filterPricingCompRangeType(literPerSecComps);

      const [
        noRangeKWHComps,
        specificRangeKWHComps,
        toInfinityRangeKWHComps
      ] = filterPricingCompRangeType(kwhComps);

      const staticCompPricingConfig = parseStaticCompPricingConfig({
        staticComps,
        duration,
        durationUnit
      });

      let isShowOnRequest = alwaysShowOnRequest;
      if (!isShowOnRequest) {
        switch (productType) {
          case PRODUCT_TYPE.HOUSE_CONNECTION_BROADBAND:
          case PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY:
          case PRODUCT_TYPE.HOUSE_CONNECTION_GAS:
          case PRODUCT_TYPE.HOUSE_CONNECTION_HEAT:
          case PRODUCT_TYPE.HOUSE_CONNECTION_WATER:
            break;
          default:
            isShowOnRequest = isOnRequest(sellingOption);
            break;
        }
      }

      compConfig[sellingOption.id] = {
        packageId: Number(packageId),
        sellingOptionId: sellingOption.id,
        title: sellingOptionTitle,
        packageName: packageMap[packageId].packageName,
        packageProvider: packageMap[packageId].provider,
        packageLabels: _.map(packageMap[packageId].labels, 'label'),
        performances: _.map(packageMap[packageId].performances, 'performance'),
        alwaysShowOnRequest: isShowOnRequest,
        duration,
        durationUnit,
        staticCompConfig: {
          staticComps,
          staticCompMap: _.keyBy(staticComps, 'id'),
          pricingConfig: staticCompPricingConfig,
          totalPrice: getTotalPriceStaticComp(staticCompPricingConfig)
        },
        kwCompConfig: _.isEmpty(kwComps)
          ? null
          : {
              kwComps,
              kwCompMap: _.keyBy(kwComps, 'id'),
              specificRange: parseSpecificRange({
                comps: specificRangeKWComps,
                duration,
                durationUnit
              }),
              toInfinityRange: parseToInfinityRange({
                comps: toInfinityRangeKWComps,
                duration,
                durationUnit
              }),
              noRange: parseNoRange({ comps: noRangeKWComps, duration, durationUnit })
            },
        kwhCompConfig: _.isEmpty(kwhComps)
          ? null
          : {
              kwhComps,
              kwhCompMap: _.keyBy(kwhComps, 'id'),
              specificRange: parseSpecificRange({
                comps: specificRangeKWHComps,
                duration,
                durationUnit
              }),
              toInfinityRange: parseToInfinityRange({
                comps: toInfinityRangeKWHComps,
                duration,
                durationUnit
              }),
              noRange: parseNoRange({ comps: noRangeKWHComps, duration, durationUnit })
            },
        meterCompConfig: _.isEmpty(meterComps)
          ? null
          : {
              meterComps,
              meterCompMap: _.keyBy(meterComps, 'id'),
              specificRange: parseSpecificRange({
                comps: specificRangeMeterComps,
                duration,
                durationUnit
              }),
              toInfinityRange: parseToInfinityRange({
                comps: toInfinityRangeMeterComps,
                duration,
                durationUnit
              }),
              noRange: parseNoRange({ comps: noRangeMeterComps, duration, durationUnit })
            },
        meterStreetFrontLengthForHCWaterCompConfig: _.isEmpty(meterStreetFrontLengthForHCWaterComps)
          ? null
          : {
              meterStreetFrontLengthForHCWaterComps,
              meterStreetFrontLengthForHCWaterCompMap: _.keyBy(
                meterStreetFrontLengthForHCWaterComps,
                'id'
              ),
              specificRange: parseSpecificRange({
                comps: specificRangeMeterStreetFrontLengthForHCWaterComps,
                duration,
                durationUnit
              }),
              toInfinityRange: parseToInfinityRange({
                comps: toInfinityRangeMeterStreetFrontLengthForHCWaterComps,
                duration,
                durationUnit
              }),
              noRange: parseNoRange({
                comps: noRangeMeterStreetFrontLengthForHCWaterComps,
                duration,
                durationUnit
              })
            },
        housingUnitForHCWaterCompConfig: _.isEmpty(housingUnitForHCWaterComps)
          ? null
          : {
              housingUnitForHCWaterComps,
              housingUnitForHCWaterCompMap: _.keyBy(housingUnitForHCWaterComps, 'id'),
              specificRange: parseSpecificRange({
                comps: specificRangeHousingUnitForHCWaterComps,
                duration,
                durationUnit
              }),
              toInfinityRange: parseToInfinityRange({
                comps: toInfinityRangeHousingUnitForHCWaterComps,
                duration,
                durationUnit
              }),
              noRange: parseNoRange({
                comps: noRangeHousingUnitForHCWaterComps,
                duration,
                durationUnit
              })
            },
        squareMetrePropertySizeForHCWaterCompConfig: _.isEmpty(
          squareMetrePropertySizeForHCWaterComps
        )
          ? null
          : {
              squareMetrePropertySizeForHCWaterComps,
              squareMetrePropertySizeForHCWaterCompMap: _.keyBy(
                squareMetrePropertySizeForHCWaterComps,
                'id'
              ),
              specificRange: parseSpecificRange({
                comps: specificRangeSquareMetrePropertySizeForHCWaterComps,
                duration,
                durationUnit
              }),
              toInfinityRange: parseToInfinityRange({
                comps: toInfinityRangeSquareMetrePropertySizeForHCWaterComps,
                duration,
                durationUnit
              }),
              noRange: parseNoRange({
                comps: noRangeSquareMetrePropertySizeForHCWaterComps,
                duration,
                durationUnit
              })
            },
        squareMetreFloorSizeForHCWaterCompConfig: _.isEmpty(squareMetreFloorSizeForHCWaterComps)
          ? null
          : {
              squareMetreFloorSizeForHCWaterComps,
              squareMetreFloorSizeForHCWaterCompMap: _.keyBy(
                squareMetreFloorSizeForHCWaterComps,
                'id'
              ),
              specificRange: parseSpecificRange({
                comps: specificRangeSquareMetreFloorSizeForHCWaterComps,
                duration,
                durationUnit
              }),
              toInfinityRange: parseToInfinityRange({
                comps: toInfinityRangeSquareMetreFloorSizeForHCWaterComps,
                duration,
                durationUnit
              }),
              noRange: parseNoRange({
                comps: noRangeSquareMetreFloorSizeForHCWaterComps,
                duration,
                durationUnit
              })
            },
        literPerSecCompConfig: _.isEmpty(literPerSecComps)
          ? null
          : {
              literPerSecComps,
              literPerSecCompMap: _.keyBy(literPerSecComps, 'id'),
              specificRange: parseSpecificRange({
                comps: specificRangeLiterPerSecComps,
                duration,
                durationUnit
              }),
              toInfinityRange: parseToInfinityRange({
                comps: toInfinityRangeLiterPerSecComps,
                duration,
                durationUnit
              }),
              noRange: parseNoRange({ comps: noRangeLiterPerSecComps, duration, durationUnit })
            }
      };
    });
  });
  return {
    ...compConfig
  };
};

const parseStaticCompPricingConfig = ({ staticComps }) => {
  // pending duration and duration type for now
  let totalOnetimePrice = 0;
  let totalMonthlyPrice = 0;
  let totalYearlyPrice = 0;
  let oneTimePricingComps = [];
  let monthlyPricingComps = [];
  let yearlyPricingComps = [];
  let isOneTimePaymentExisted = false;
  let isMonthlyPaymentExisted = false;
  let isYearlyPaymentExisted = false;
  _.forEach(staticComps, staticComp => {
    let { quantity } = staticComp;

    let grossRetailPrice, paymentFrequency, name, id, netRetailPrice, retailPriceTaxId, retailTax;

    let currency = CURRENCY.EURO;

    let childComponentsOfGroup = [];

    if (staticComp.pricingComponent) {
      ({
        grossRetailPrice,
        paymentFrequency,
        name,
        id,
        netRetailPrice,
        retailPriceTaxId,
        retailTax
      } = staticComp.pricingComponent);
    } else {
      // For component group
      ({
        grossRetailPrice,
        paymentFrequency,
        name,
        id,
        netRetailPrice,
        retailPriceTaxId,
        retailTax
      } = staticComp);
      quantity = 1;
      currency = staticComp.currency;
    }

    switch (paymentFrequency) {
      case PAYMENT_FREQUENCY.ONE_TIME:
        isOneTimePaymentExisted = true;
        totalOnetimePrice = totalOnetimePrice + grossRetailPrice * quantity;
        oneTimePricingComps.push({
          id,
          name,
          paymentFrequency,
          price: grossRetailPrice * quantity,
          type: FIXED,
          netPrice: netRetailPrice * quantity,
          retailPriceTaxId,
          retailTax,
          currency,
          childComponentsOfGroup
        });
        break;
      case PAYMENT_FREQUENCY.MONTHLY:
        isMonthlyPaymentExisted = true;
        totalMonthlyPrice = totalMonthlyPrice + grossRetailPrice * quantity;
        monthlyPricingComps.push({
          id,
          name,
          paymentFrequency,
          price: grossRetailPrice * quantity,
          type: FIXED,
          netPrice: netRetailPrice * quantity,
          retailPriceTaxId,
          retailTax,
          currency,
          childComponentsOfGroup
        });
        break;
      case PAYMENT_FREQUENCY.YEARLY:
        isYearlyPaymentExisted = true;
        totalYearlyPrice = totalYearlyPrice + grossRetailPrice * quantity;
        yearlyPricingComps.push({
          id,
          name,
          paymentFrequency,
          price: grossRetailPrice * quantity,
          type: FIXED,
          netPrice: netRetailPrice * quantity,
          retailPriceTaxId,
          retailTax,
          currency,
          childComponentsOfGroup
        });
        break;
      default:
        break;
    }
  });
  return {
    [PAYMENT_FREQUENCY.ONE_TIME]: isOneTimePaymentExisted
      ? {
          totalPrice: totalOnetimePrice,
          pricingComps: oneTimePricingComps
        }
      : null,
    [PAYMENT_FREQUENCY.MONTHLY]: isMonthlyPaymentExisted
      ? {
          totalPrice: totalMonthlyPrice,
          pricingComps: monthlyPricingComps
        }
      : null,
    [PAYMENT_FREQUENCY.YEARLY]: isYearlyPaymentExisted
      ? {
          totalPrice: totalYearlyPrice,
          pricingComps: yearlyPricingComps
        }
      : null
  };
};

const filterPackagePricingComps = ({ packagePricingComponents }) => {
  let staticComps = [];
  let kwComps = [];
  let kwhComps = [];
  let meterComps = [];
  let meterStreetFrontLengthForHCWaterComps = [];
  let housingUnitForHCWaterComps = [];
  let squareMetrePropertySizeForHCWaterComps = [];
  let squareMetreFloorSizeForHCWaterComps = [];
  let literPerSecComps = [];
  _.forEach(packagePricingComponents, packagePricingComponent => {
    switch (packagePricingComponent.packagePricingComponentType) {
      case PACKAGE_PRICING_COMPONENT_TYPE.KILOWATT_COMPONENT:
      case PACKAGE_PRICING_COMPONENT_TYPE.HEATING_CAPACITY_COMPONENT:
        kwComps.push(packagePricingComponent);
        break;
      case PACKAGE_PRICING_COMPONENT_TYPE.KILOWATT_HOUR_COMPONENT:
        kwhComps.push(packagePricingComponent);
        break;
      case PACKAGE_PRICING_COMPONENT_TYPE.METER_COMPONENT:
        meterComps.push(packagePricingComponent);
        break;
      case PACKAGE_PRICING_COMPONENT_TYPE.STREET_FRONT_LENGTH:
        meterStreetFrontLengthForHCWaterComps.push(packagePricingComponent);
        break;
      case PACKAGE_PRICING_COMPONENT_TYPE.HOUSING_UNITS:
        housingUnitForHCWaterComps.push(packagePricingComponent);
        break;
      case PACKAGE_PRICING_COMPONENT_TYPE.PROPERTY_SIZE:
        squareMetrePropertySizeForHCWaterComps.push(packagePricingComponent);
        break;
      case PACKAGE_PRICING_COMPONENT_TYPE.FLOOR_SIZE:
        squareMetreFloorSizeForHCWaterComps.push(packagePricingComponent);
        break;
      case PACKAGE_PRICING_COMPONENT_TYPE.LITER_PER_SECOND_COMPONENT:
        literPerSecComps.push(packagePricingComponent);
        break;
      case PACKAGE_PRICING_COMPONENT_TYPE.STATIC_COMPONENT:
        staticComps.push(packagePricingComponent);
        break;
      default:
        break;
    }
  });

  return [
    staticComps,
    kwComps,
    kwhComps,
    meterComps,
    meterStreetFrontLengthForHCWaterComps,
    housingUnitForHCWaterComps,
    squareMetrePropertySizeForHCWaterComps,
    squareMetreFloorSizeForHCWaterComps,
    literPerSecComps
  ];
};

const filterPricingCompRangeType = packagePricingComponents => {
  let noRangeComps = [];
  let specificRangeComps = [];
  let toInfinityRangeComps = [];
  _.forEach(packagePricingComponents, packagePricingComponent => {
    switch (packagePricingComponent.rangeType) {
      case PRICING_COMP_RANGE_TYPE.SPECIFIC_RANGE:
        specificRangeComps.push(packagePricingComponent);
        break;
      case PRICING_COMP_RANGE_TYPE.TO_INFINITY:
        toInfinityRangeComps.push(packagePricingComponent);
        break;
      case PRICING_COMP_RANGE_TYPE.NO_RANGE:
        noRangeComps.push(packagePricingComponent);
        break;
      default:
        break;
    }
  });
  return [noRangeComps, specificRangeComps, toInfinityRangeComps];
};

const getTotalPriceStaticComp = staticCompPricingConfig => {
  let totalPrice = 0;
  _.forIn(staticCompPricingConfig, priceConfig => {
    if (priceConfig) {
      totalPrice = totalPrice + priceConfig.totalPrice;
    }
  });
  return totalPrice;
};

const calcTotalPrice = ({ comp }) => {
  const { quantity } = comp;
  if (comp.pricingComponent) {
    const { grossRetailPrice } = comp.pricingComponent;
    if (!isNaN(grossRetailPrice)) {
      return grossRetailPrice * quantity;
    }
  } else {
    // for component group
    const grossRetailPrice = comp.grossRetailPrice;
    if (!isNaN(grossRetailPrice)) {
      return grossRetailPrice;
    }
  }
};

const parsePricingComponent = ({ pricingComponent }) => {
  return {
    id: pricingComponent.id,
    name: pricingComponent.name,
    paymentFrequency: pricingComponent.paymentFrequency,
    price: Number(pricingComponent.grossRetailPrice),
    type: pricingComponent.priceType,
    netPrice: Number(pricingComponent.netRetailPrice),
    retailPriceTaxId: pricingComponent.retailPriceTaxId,
    retailTax: pricingComponent.retailTax,
    currency: pricingComponent.currency ? pricingComponent.currency : CURRENCY.EURO,
    childComponentsOfGroup: pricingComponent.childComponentsOfGroup
      ? pricingComponent.childComponentsOfGroup
      : []
  };
};

const prepareDataForParsing = ({ comp, duration, durationUnit }) => {
  let id,
    name,
    paymentFrequency,
    priceType,
    grossRetailPrice,
    netRetailPrice,
    retailPriceTaxId,
    retailTax,
    totalPrice;
  let quantity = 1;
  let currency = CURRENCY.EURO;
  let childComponentsOfGroup = [];

  if (comp.pricingComponent) {
    id = comp.pricingComponent.id;
    name = comp.pricingComponent.name;
    paymentFrequency = comp.pricingComponent.paymentFrequency;
    priceType = comp.pricingComponent.priceType;
    grossRetailPrice = comp.pricingComponent.grossRetailPrice;
    netRetailPrice = comp.pricingComponent.netRetailPrice;
    retailPriceTaxId = comp.pricingComponent.retailPriceTaxId;
    retailTax = comp.pricingComponent.retailTax;
    quantity = comp.quantity;
  } else {
    // for component group
    id = comp.id;
    name = comp.name;
    paymentFrequency = comp.paymentFrequency;
    priceType = comp.priceType;
    grossRetailPrice = comp.grossRetailPrice;
    netRetailPrice = comp.netRetailPrice;
    retailPriceTaxId = comp.retailPriceTaxId;
    retailTax = comp.retailTax;
    currency = comp.currency;
  }

  totalPrice = calcTotalPrice({ comp, duration, durationUnit });

  return {
    id,
    name,
    paymentFrequency,
    priceType,
    grossRetailPrice,
    netRetailPrice,
    retailPriceTaxId,
    retailTax,
    currency,
    childComponentsOfGroup,
    quantity,
    totalPrice
  };
};

const parseNoRange = ({ comps, duration, durationUnit }) => {
  let result = {};

  let totalPriceAllNoRangeComp = null;

  let noRangeCompIds = [];

  _.forEach(comps, (comp, index) => {
    let {
      id,
      name,
      paymentFrequency,
      priceType,
      grossRetailPrice,
      netRetailPrice,
      retailPriceTaxId,
      retailTax,
      currency,
      childComponentsOfGroup,
      quantity,
      totalPrice
    } = prepareDataForParsing({ comp, duration, durationUnit });

    if (totalPrice === null) {
      return;
    }

    totalPriceAllNoRangeComp =
      totalPriceAllNoRangeComp === null ? totalPrice : totalPriceAllNoRangeComp + totalPrice;

    noRangeCompIds.push(comp.id);

    result[index + 1] = {
      compId: comp.id,
      totalPrice: totalPrice,
      paymentFrequency: paymentFrequency,
      quantity: quantity,
      type: priceType,
      pricingComponent: {
        ...parsePricingComponent({
          pricingComponent: {
            id,
            name,
            paymentFrequency,
            grossRetailPrice,
            priceType,
            netRetailPrice,
            retailPriceTaxId,
            retailTax,
            currency: currency,
            childComponentsOfGroup: childComponentsOfGroup
          }
        })
      }
    };
  });

  return totalPriceAllNoRangeComp === null
    ? null
    : {
        ...result,
        totalPrice: totalPriceAllNoRangeComp,
        noRangeCompIds
      };
};

const parseToInfinityRange = ({ comps, duration, durationUnit }) => {
  let result = {};

  _.forEach(comps, comp => {
    let {
      id,
      name,
      paymentFrequency,
      priceType,
      grossRetailPrice,
      netRetailPrice,
      retailPriceTaxId,
      retailTax,
      currency,
      childComponentsOfGroup,
      quantity,
      totalPrice
    } = prepareDataForParsing({ comp, duration, durationUnit });

    let from = comp.pricingComponent ? comp.startRangeToInfinity : comp.startRange;

    result[comp.id] = {
      compId: comp.id,
      from,
      totalPrice: totalPrice,
      paymentFrequency: paymentFrequency,
      type: priceType,
      quantity: quantity,
      pricingComponent: {
        ...parsePricingComponent({
          pricingComponent: {
            id,
            name,
            paymentFrequency,
            grossRetailPrice,
            priceType,
            netRetailPrice,
            retailPriceTaxId,
            retailTax,
            currency: currency,
            childComponentsOfGroup: childComponentsOfGroup
          }
        }),
        lowBoundValue: from
      }
    };
  });

  return {
    ...result
  };
};

const parseSpecificRange = ({ comps, duration, durationUnit }) => {
  let result = {};

  _.forEach(comps, comp => {
    let {
      id,
      name,
      paymentFrequency,
      priceType,
      grossRetailPrice,
      netRetailPrice,
      retailPriceTaxId,
      retailTax,
      currency,
      childComponentsOfGroup,
      quantity,
      totalPrice
    } = prepareDataForParsing({ comp, duration, durationUnit });

    let { startRange: from, endRange: to } = comp;

    result[`${from}-${to}-${comp.id}`] = {
      from,
      to,
      totalPrice: totalPrice,
      compId: comp.id,
      paymentFrequency: paymentFrequency,
      type: priceType,
      quantity: quantity,
      pricingComponent: {
        ...parsePricingComponent({
          pricingComponent: {
            id,
            name,
            paymentFrequency,
            grossRetailPrice,
            priceType,
            netRetailPrice,
            retailPriceTaxId,
            retailTax,
            currency: currency,
            childComponentsOfGroup: childComponentsOfGroup
          }
        }),
        lowBoundValue: from
      }
    };
  });

  return {
    ...result
  };
};

export const findCheapestPackageAndSellingOptionId = sellingOptionMap => {
  let cheapestSellingOptionPrice = null;
  let cheapestSellingOptionId = null;
  let cheapestPackageId = null;
  _.forIn(sellingOptionMap, sellingOption => {
    if (
      sellingOption.staticCompConfig.totalPrice < cheapestSellingOptionPrice ||
      !cheapestSellingOptionId
    ) {
      cheapestSellingOptionPrice = sellingOption.staticCompConfig.totalPrice;
      cheapestPackageId = sellingOption.packageId;
      cheapestSellingOptionId = sellingOption.sellingOptionId;
    }
  });
  return [cheapestPackageId, cheapestSellingOptionId];
};

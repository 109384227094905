export const MESSAGE_CONSTANTS = {
  REQUIRED: 'Diese Angabe wird benötigt.',
  TOO_LONG: '200 Zeichen maximal',
  MESSAGE_ADDRESS_FIELDS_REQUIRED: 'Alle Adressfelder werden benötigt.',
  MESSAGE_TOO_LONG: '1500 Zeichen maximal',
  EMAIL_INVALID: 'Bitte gib eine gültige Email-Adresse an.',
  PHONE_INVALID: 'Bitte gib eine gültige Telefonnummer an.',
  ACCEPT_TOS_ERROR: 'You must accept the terms of service',
  DEFAULT_ERROR: 'Something went wrong.',
  MAX_FILE_SIZE: 'Die ausgewählte Datei ist zu groß (max. 10MB).',
  INVALID_FILE_TYPE: 'Ungültiger Dateityp.',
  CHECK_ALL_REQUIRED_FIELD: 'Bitte fülle alle erforderlichen Felder aus',
  CHECK_VALID_TAX_ID:
    'Diese USt-IdNr. ist bereits einem anderen Unternehmen zugeordnet. Bitte die Eingabe überprüfen.',
  FILES_NAME_EXIST: 'Ein Dateiname existiert bereits',
  STREET_NOT_FOUND: 'Straße nicht gefunden',
  POSTCODE_INVALID: 'Keine gültige PLZ. Bitte Eingabe prüfen.'
};

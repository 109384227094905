import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { formatNumber, formatQuestion, ScrollUtil, ShowIfUtil } from '../../common/utils';
import {
  InfoHelpDescription,
  StyledButton,
  StyledSpan
} from '../../components/epilot-forms/common';
import {
  COLOR,
  GERMAN_TEXT,
  HOUSE_CONNECTION_E_MOBILITY,
  QUESTION_ID,
  SPECIAL_CHARACTER,
  UNIT,
  WATER_HEATER_NAME
} from '../../common/constants';
import { AdditionalPowerItem } from '.';
import { updateCustomerJourneyAnswer } from '../../store/actions';
import { cookingQuestionsSelector } from '../../store/selectors';

class AdditionalPowerQuestionContainer extends PureComponent {
  renderHousingUnits = () => {
    const { customerJourneyAnswer, fontText, currQuestionIds, defaultTextColor } = this.props;
    const housingUnitsQuestionId = _.find(
      currQuestionIds,
      id => id === QUESTION_ID.HOUSE_CONNECTION_UNITS
    );
    const textColor = {
      color: defaultTextColor
    };

    return (
      <ShowIfUtil condition={!!housingUnitsQuestionId}>
        <div className="wrapper-item row m-0 ep-ellipsis ep__txt--md ep__txt-bold ep__txt-no-select">
          <StyledSpan
            font={fontText}
            className="col-5 item-dropdown item-housing-unit ep-height-48 ep--pr-16 ep--py-8 pl-0"
            style={textColor}
          >
            {GERMAN_TEXT.HOUSING_UNITS}
          </StyledSpan>
          <div className="col-2 item-number-spinner ep--py-8 ep--pl-0">
            <StyledSpan font={fontText} style={textColor}>
              {customerJourneyAnswer.houseConnectionUnits}
            </StyledSpan>
          </div>
          <div className="col-2 item-input ep__txt-bold ep--py-8 pl-0" />
          <div className="col-2 item-total-number ep__txt-bold ep--py-8 px-0" style={textColor}>
            <StyledSpan font={fontText}>
              {Number(customerJourneyAnswer.housingElectricityMaxPower).toFixed(2)}
              {SPECIAL_CHARACTER.SPACE + UNIT.KILOWALT}
            </StyledSpan>
          </div>
          <div className="col-1 item-remove ep--py-8 px-0" />
        </div>
      </ShowIfUtil>
    );
  };

  renderChargingStation = () => {
    const { customerJourneyAnswer, fontText, currQuestionIds, defaultTextColor } = this.props;
    const chargingStationQuestionId = _.find(
      currQuestionIds,
      id => id === QUESTION_ID.HOUSE_CONNECTION_E_MOBILITY_REQUIREMENT
    );
    const textColor = {
      color: defaultTextColor
    };

    return (
      <ShowIfUtil condition={!!chargingStationQuestionId}>
        <div className="wrapper-item row m-0 ep-ellipsis ep__txt--md ep__txt-bold ep__txt-no-select">
          <StyledSpan
            font={fontText}
            className="col-5 item-dropdown item-housing-unit ep-height-48 ep--pr-16 ep--py-8 pl-0"
            style={textColor}
          >
            {GERMAN_TEXT.CHARGING_STATION}
          </StyledSpan>
          <div className="col-2 item-number-spinner ep--py-8 ep--pl-0" />
          <div className="col-2 item-input ep__txt-bold ep--py-8 pl-0" />
          <div className="col-2 item-total-number ep__txt-bold ep--py-8 px-0" style={textColor}>
            <StyledSpan font={fontText}>
              {Number(customerJourneyAnswer.eMobilityPower).toFixed(2)}
              {SPECIAL_CHARACTER.SPACE + UNIT.KILOWALT}
            </StyledSpan>
          </div>
          <div className="col-1 item-remove ep--py-8 px-0" />
        </div>
      </ShowIfUtil>
    );
  };

  renderItems = () => {
    const { customerJourneyAnswer, fontText, question, defaultTextColor } = this.props;
    return _.map(customerJourneyAnswer.additionalPowerItems, (item, index) => {
      return (
        <AdditionalPowerItem
          question={question}
          options={this.options()}
          fontText={fontText}
          key={item.id}
          itemIndex={index}
          defaultTextColor={defaultTextColor}
        />
      );
    });
  };

  options = () => {
    const { question } = this.props;
    let options = [];

    _.forEach(Object.keys(question.objectModel.items), key => {
      options.push(question.objectModel.items[key]);
    });
    return options;
  };

  addItems = () => {
    const { question, updateCustomerJourneyAnswer, customerJourneyAnswer } = this.props;
    let newCustomerJourneyAnswer = {
      additionalPowerItems: _.cloneDeep(customerJourneyAnswer.additionalPowerItems)
    };
    const item = {
      id: _.uniqueId('additional_power_item_'),
      name: question.objectModel.items[WATER_HEATER_NAME],
      quantity: 1,
      output: '0'
    };
    newCustomerJourneyAnswer.additionalPowerItems.push(item);
    updateCustomerJourneyAnswer(newCustomerJourneyAnswer);
  };

  isEnableScroll = () => {
    const { currQuestionIds, customerJourneyAnswer } = this.props;
    const housingUnitsQuestionId = _.find(
      currQuestionIds,
      id => id === QUESTION_ID.HOUSE_CONNECTION_UNITS
    );
    const chargingStationQuestionId = _.find(
      currQuestionIds,
      id => id === QUESTION_ID.HOUSE_CONNECTION_E_MOBILITY_REQUIREMENT
    );
    if (
      housingUnitsQuestionId &&
      (chargingStationQuestionId &&
        customerJourneyAnswer.eMobilityPlan === HOUSE_CONNECTION_E_MOBILITY.YES)
    ) {
      return customerJourneyAnswer.additionalPowerItems.length > 1;
    } else if (!housingUnitsQuestionId && !chargingStationQuestionId) {
      return customerJourneyAnswer.additionalPowerItems.length > 3;
    } else {
      return customerJourneyAnswer.additionalPowerItems.length > 2;
    }
  };

  render() {
    const {
      fontText,
      question,
      frontendConfiguration,
      customerJourneyAnswer,
      defaultTextColor
    } = this.props;
    const textColor = {
      color: defaultTextColor
    };
    let setHeightWrapperAddItems = this.isEnableScroll() ? '212px' : '213px';
    return (
      <div className="ep-customerjourneypage__question">
        <div className="text-center ep-min-height-70 ep--pt-8 ep--pb-16">
          <StyledSpan
            font={fontText}
            className="ep__txt-regular ep__txt--lg ep__txt-no-select"
            style={textColor}
          >
            {formatQuestion(question.questionTitle, customerJourneyAnswer.name)}
          </StyledSpan>
          <InfoHelpDescription
            showHelpDescription={question.showHelpDescription}
            tooltipInfoContent={<StyledSpan font={fontText}>{question.helpDescription}</StyledSpan>}
            classNameForInfoIcon="ep__txt--lg ep--ml-8"
            defaultTextColor={defaultTextColor}
          />
        </div>
        <div className="row m-0">
          <div className="col-6 text-left">
            <StyledButton
              font={fontText}
              className="text-left ep__txt-bold ep-bg-transparent border-0 ep-cursor-pointer"
              style={{ color: frontendConfiguration.primaryColor }}
              onClick={this.addItems}
            >
              {'+ Position hinzufügen'}
            </StyledButton>
          </div>
          <div className="col-6 text-right">
            <StyledSpan className="ep__txt-bold ep-font-size-16" font={fontText} style={textColor}>
              {'Gesamt: ' +
                formatNumber(customerJourneyAnswer.totalAdditionalOutput).toString() +
                SPECIAL_CHARACTER.SPACE +
                UNIT.KILOWALT}
            </StyledSpan>
          </div>
        </div>
        <div className="ep-hr-custom" />

        {/*Render on desktop*/}
        <div className="d-none d-lg-block">
          <ScrollUtil height={`${setHeightWrapperAddItems}`} className="ep--py-8 text-center">
            {this.renderHousingUnits()}
            {customerJourneyAnswer.eMobilityPlan === HOUSE_CONNECTION_E_MOBILITY.YES &&
              this.renderChargingStation()}
            {this.renderItems()}
          </ScrollUtil>
        </div>

        {/*Render on mobile*/}
        <div className="d-block d-lg-none">
          <div className="ep--py-8 text-center">
            {this.renderHousingUnits()}
            {customerJourneyAnswer.eMobilityPlan === HOUSE_CONNECTION_E_MOBILITY.YES &&
              this.renderChargingStation()}
            {this.renderItems()}
          </div>
        </div>
        {this.isEnableScroll() && <div className="ep-hr-custom d-none d-lg-block" />}
      </div>
    );
  }
}

AdditionalPowerQuestionContainer.propTypes = {
  frontendConfiguration: PropTypes.object,
  customerJourneyAnswer: PropTypes.object,
  updateCustomerJourneyAnswer: PropTypes.func,
  question: PropTypes.object,
  fontText: PropTypes.object,
  defaultTextColor: PropTypes.string,
  currQuestionIds: PropTypes.array.isRequired
};

AdditionalPowerQuestionContainer.defaultProps = {
  frontendConfiguration: {},
  customerJourneyAnswer: {},
  updateCustomerJourneyAnswer: () => ({}),
  question: {},
  fontText: {},
  defaultTextColor: COLOR.DARK_GRAY
};

function mapStateToProps(state) {
  const { currQuestionIds } = cookingQuestionsSelector(state);
  return {
    frontendConfiguration: state.frontendConfiguration,
    customerJourneyAnswer: state.customerJourneyAnswer,
    customerJourneyMapping: state.customerJourneyMapping,
    currQuestionIds
  };
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateCustomerJourneyAnswer
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdditionalPowerQuestionContainer);

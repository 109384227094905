import React from 'react';
import {
  AdditionalPowerQuestion,
  AddressQuestion,
  DateInputQuestion,
  InputDropdownQuestion,
  DropdownQuestion,
  GoogleMapContainer,
  InputQuestion,
  InputWithInfoQuestion,
  MultipleChoiceQuestion,
  MultipleInputQuestion,
  PostalCodeQuestion,
  SelectionQuestion,
  SliderQuestion,
  SpinnerQuestion,
  UploadFileQuestion
} from '../../containers';
import { QUESTION_TYPE } from '../constants';

export const renderQuestion = (question, fontText, fontTextBold, textColor, linkColor) => {
  switch (question.type) {
    case QUESTION_TYPE.SPINNER:
      return (
        <SpinnerQuestion
          key={question.id}
          question={question}
          fontText={fontText}
          fontTextBold={fontTextBold}
          defaultTextColor={textColor}
        />
      );
    case QUESTION_TYPE.HOUSE_CONNECTION_TABLE_INPUT:
      return (
        <MultipleChoiceQuestion
          key={question.id}
          question={question}
          fontText={fontText}
          fontTextBold={fontTextBold}
          defaultTextColor={textColor}
        />
      );
    case QUESTION_TYPE.HOUSE_CONNECTION_UPLOAD:
    case QUESTION_TYPE.FILE_UPLOAD:
      return (
        <UploadFileQuestion
          key={question.id}
          question={question}
          fontText={fontText}
          fontTextBold={fontTextBold}
          defaultTextColor={textColor}
          linkColor={linkColor}
        />
      );
    case QUESTION_TYPE.POSTAL_CODE:
      return (
        <PostalCodeQuestion
          key={question.id}
          question={question}
          fontText={fontText}
          fontTextBold={fontTextBold}
          defaultTextColor={textColor}
        />
      );
    case QUESTION_TYPE.HOUSE_CONNECTION_TABS:
      return (
        <InputWithInfoQuestion
          key={question.id}
          question={question}
          fontText={fontText}
          fontTextBold={fontTextBold}
          defaultTextColor={textColor}
        />
      );
    case QUESTION_TYPE.HOUSE_CONNECTION_MULTIPLE_INPUT:
      return (
        <MultipleInputQuestion
          key={question.id}
          question={question}
          fontText={fontText}
          fontTextBold={fontTextBold}
          defaultTextColor={textColor}
        />
      );
    case QUESTION_TYPE.HOUSE_CONNECTION_TABLE_MULTIPLE_CHOICE:
      return (
        <AdditionalPowerQuestion
          key={question.id}
          question={question}
          fontText={fontText}
          fontTextBold={fontTextBold}
          defaultTextColor={textColor}
        />
      );
    case QUESTION_TYPE.ADDRESS_DETAILS:
      return (
        <AddressQuestion
          key={question.id}
          question={question}
          fontText={fontText}
          fontTextBold={fontTextBold}
          defaultTextColor={textColor}
        />
      );
    case QUESTION_TYPE.PIN_ADDRESS_ON_MAP:
      return (
        <GoogleMapContainer
          key={question.id}
          question={question}
          fontText={fontText}
          fontTextBold={fontTextBold}
          defaultTextColor={textColor}
        />
      );
    case QUESTION_TYPE.DATE_INPUT:
      return (
        <DateInputQuestion
          key={question.id}
          question={question}
          fontText={fontText}
          fontTextBold={fontTextBold}
          defaultTextColor={textColor}
        />
      );
    case QUESTION_TYPE.PERCENT_SLIDER:
    case QUESTION_TYPE.TABLE_SLIDER:
      return (
        <SliderQuestion
          key={question.id}
          question={question}
          fontText={fontText}
          fontTextBold={fontTextBold}
          defaultTextColor={textColor}
        />
      );
    case QUESTION_TYPE.RADIO_BUTTON:
    case QUESTION_TYPE.CUSTOM_RADIO:
    case QUESTION_TYPE.HOUSE_CONNECTION_TEXT:
    case QUESTION_TYPE.HOUSE_CONNECTION_TEXT_SWITCH:
    case QUESTION_TYPE.HOUSE_CONNECTION_TEXT_CONFIGURABLE_MESSAGE:
    case QUESTION_TYPE.HEATING_TABLE:
    case QUESTION_TYPE.CHARGE_TEXT:
    case QUESTION_TYPE.CHARGE_TABLE_INPUT:
    case QUESTION_TYPE.CHARGE_TABLE_WITH_RADIO_BUTTON:
    case QUESTION_TYPE.DATE_PICKER:
    case QUESTION_TYPE.HOUSE_CONNECTION_TEXT_WITH_CHECKBOX:
    case QUESTION_TYPE.MULTIPLE_CHOICE:
    case QUESTION_TYPE.HOUSE_CONNECTION_CHOICE_WITH_INPUT:
      return (
        <SelectionQuestion
          key={question.id}
          question={question}
          fontText={fontText}
          fontTextBold={fontTextBold}
          defaultTextColor={textColor}
        />
      );
    case QUESTION_TYPE.HEATING_INPUT:
    case QUESTION_TYPE.HOUSE_CONNECTION_INPUT:
    case QUESTION_TYPE.HOUSE_CONNECTION_TABLE_NO_HEADER_INPUT:
    case QUESTION_TYPE.INPUT:
    case QUESTION_TYPE.CHP_INPUT:
    case QUESTION_TYPE.INPUT_WITH_CHECKBOX:
      return (
        <InputQuestion
          key={question.id}
          question={question}
          fontText={fontText}
          fontTextBold={fontTextBold}
          defaultTextColor={textColor}
        />
      );
    case QUESTION_TYPE.HEATING_SLIDERS:
    case QUESTION_TYPE.HEATING_TEXT:
      return (
        <InputDropdownQuestion
          key={question.id}
          question={question}
          fontText={fontText}
          fontTextBold={fontTextBold}
          defaultTextColor={textColor}
        />
      );
    case QUESTION_TYPE.HOUSE_CONNECTION_DROPDOWN:
      return (
        <DropdownQuestion
          key={question.id}
          question={question}
          fontText={fontText}
          fontTextBold={fontTextBold}
          defaultTextColor={textColor}
        />
      );
    default:
      break;
  }
};

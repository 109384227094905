export const ADDON_AVAILABILITY = {
  ALL_PACKAGES: 0,
  PACKAGE_BY_VENDOR: 1,
  SPECIFIC_PACKAGE: 2
};

export const ADDON_MAPPING_OPTION = {
  ALL_PACKAGES: 0,
  SPECIFIC_PACKAGE: 1
};

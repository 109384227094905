import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import {
  ATTRIBUTE,
  BOOLEAN,
  CHARGE_CAR_SPEED,
  CHARGE_TYPE,
  CHECKBOXES,
  COLOR,
  CONFIRM_LOCALE,
  CONTACT_FORM_KEY,
  CONTACT_FORM_TYPE,
  COUNTRY,
  CUSTOMER_TYPE,
  DELIVERY_ADDRESS,
  EXAMPTION_DATA,
  FRONTEND_TYPE,
  GERMAN_TEXT,
  HEATING_LIVING_SPACE,
  HOUSE_CONNECTION_BUILDING_TYPE,
  HOUSE_CONNECTION_E_MOBILITY,
  HOUSE_CONNECTION_ELECTRICITY_FEED_INTO_GRID,
  HOUSE_CONNECTION_EXTINGUISHING_WATER_DEMAND,
  HOUSE_CONNECTION_IS_OWNER,
  HOUSE_CONNECTION_NEW_OR_CHANGE,
  HOUSEHOLD_SIZE,
  INPUT_TYPE_CONTACT_FORM,
  KNOWING_OF_HEATING_OUTPUT,
  LANGUAGE,
  LOCALE,
  MESSAGE_CONSTANTS,
  NO_INFORMATION,
  PATTERN,
  PAYMENT_OPTION,
  PAYMENT_TYPE,
  PRIVATELY_OR_BUSINESS,
  PRODUCT_NAME,
  PRODUCT_SELECTED_KEY,
  PRODUCT_TYPE,
  PURCHASE_TYPE,
  QUESTION_ID,
  QUESTION_TYPE,
  RENTAL,
  RENTAL_UNIT,
  SALUTATION_DATA,
  SALUTATION_KEY,
  SERVER_MESSAGE_CONSTANTS,
  SERVICE_REQUEST_STATUS,
  SPECIAL_CHARACTER,
  STATUS,
  UNIT,
  USAGE_OF_ELECTRIC_CAR,
  WATER_HEATER_NAME,
  WHERE_TO_HEAT,
  Z_INDEX
} from '../../common/constants';
import {
  CheckBoxReadAccept,
  GroupCheckboxes,
  StyledButtonBold,
  StyledLabel,
  StyledP,
  StyledSpan,
  Switch
} from '../../components/epilot-forms';
import { Attachments, InstallationDetailField, PaymentOptions, Question } from '.';
import UploadRelatedDocument from './UploadRelatedDocumentContainer';
import {
  checkIsOnlinePurchase,
  checkIsOnlinePurchaseV2,
  checkOnlinePurchase,
  checkSelectedProductOnRequest,
  convertDataToComma,
  convertProdTypeToProdName,
  CustomCollapse,
  formatAddress,
  formatNumber,
  formatQuestion,
  generateOpportunityTitle,
  getAddressFormatted,
  getCountryGermanNameFromCode,
  getCustomerInfoFormatted,
  getListSalutation,
  getRentalRate,
  getSummaryDataModelV1,
  getSummaryLogInfo,
  hexaToRGBA,
  isAllOnlinePurchaseProductPackagesSummaryV2,
  isAllOnRequestProductPackagesSummaryV2,
  replaceShortCodeContent,
  ScrollUtil,
  ShowIfUtil,
  getOnlinePurchasePaymentType
} from '../../common/utils';
import {
  CHECK_VALID_TAX_ID_SUCCESS,
  checkValidTaxID,
  CREATE_CUSTOMER_FAILURE,
  CREATE_CUSTOMER_SUCCESS,
  createCustomer,
  updateContactForm,
  updateCustomerJourneyAnswer,
  updateThankYouPageData,
  uploadAttachment
} from '../../store/actions';
import {
  cookingQuestionsSelector,
  createOPPackageV2RequestParamsSelector,
  currentStateSelector,
  emailTemplateV2DataSelector,
  isAllProductsDeactivatedSelector,
  productSelectionsSelector,
  productsSelector,
  selectedProductsSelector,
  totalPriceCalcSelector,
  totalPriceCalcV2Selector,
  totalPriceCalcV2ExcludeOnRequestSelector
} from '../../store/selectors';
import AdditionalAddressesContainer from './AdditionalAddressesContainer';

class ContactFormContainer extends Component {
  constructor(props) {
    super(props);
    const { routeManager, customerJourneyAnswer } = this.props;

    this.state = {
      customerType: CUSTOMER_TYPE.PRIVATE,
      contactFormData: {},
      selectedCheckboxes: this.initialSelectedCheckboxes(),
      customerId: 0,
      customerFirstName: null,
      customerLastName: null,
      frontEndResponseId: 0,
      orderNumber: SPECIAL_CHARACTER.EMPTY,
      opportunityBillingAddress: {},
      opportunityDeliveryAddress: {},
      opportunityAdditionalAddresses: [],
      opportunityDateApiModels: [],
      opportunityMultipleChoiceApiModels: [],
      contactFormHeight: SPECIAL_CHARACTER.EMPTY,
      isBillingAddressCompleted: undefined,
      okToContact: false,
      availableFields: this.getAvailableFieldsJSONToDisplayUI(),
      installationDetails: this.initialValue(SALUTATION_KEY.INSTALLATION_FIELD),
      additionalAddresses: this.initialValue(SALUTATION_KEY.ADDITIONAL_ADDRESSES_FIELD),
      onlinePurchaseOnlyRequest: false,
      contactCustomFields: [],
      isValidRelatedDocumentData: null
    };

    // Display list of question
    this.contactFormData = {};
    this.privateCustomerData = {};
    this.businessCustomerData = {};
    this.taxRegistrationKeys = [
      CONTACT_FORM_KEY.TAX_ID,
      CONTACT_FORM_KEY.EXEMPT_UNTIL,
      CONTACT_FORM_KEY.UPLOAD_DOCUMENTS,
      CONTACT_FORM_KEY.REGISTER_COURT,
      CONTACT_FORM_KEY.REGISTER_NUMBER,
      CONTACT_FORM_KEY.REGISTRATION_DETAILS
    ];
    this.contactFormErrorMessage = {};
    this.checkboxesErrorMessage = {};
    this.paymentOptionsData = {
      selectedPayment: this.initialPaymentOption(),
      isDataValid: undefined
    };
    this.relatedDocumentsData = {
      selectedRelatedDocument: [],
      isDataValid: undefined
    };
    this.isPreviewCheckoutPage = routeManager.isPreviewCheckoutPage === BOOLEAN.TRUE;
    this.hasInitialPostalCode = Boolean(customerJourneyAnswer.postalCode);
    this.hasInitialStreetName =
      customerJourneyAnswer.address.hasOwnProperty(CONTACT_FORM_KEY.STREET) &&
      Boolean(customerJourneyAnswer.address.street);
    this.hasInitialStreetNumber =
      customerJourneyAnswer.address.hasOwnProperty(CONTACT_FORM_KEY.NUMBER) &&
      Boolean(customerJourneyAnswer.address.number);
    this.hasInitialDistrict =
      customerJourneyAnswer.address.hasOwnProperty(CONTACT_FORM_KEY.DISTRICT) &&
      Boolean(customerJourneyAnswer.address.district);
    this.hasInitialBlock =
      customerJourneyAnswer.address.hasOwnProperty(CONTACT_FORM_KEY.BLOCK) &&
      Boolean(customerJourneyAnswer.address.block);
    this.hasInitialLot =
      customerJourneyAnswer.address.hasOwnProperty(CONTACT_FORM_KEY.LOT) &&
      Boolean(customerJourneyAnswer.address.lot);
  }

  componentDidMount = () => {
    const { frontendConfiguration, customerJourneyAnswer, contactFormData } = this.props;

    const { availableFields } = this.state;
    this.handleAvailableFields();
    this.initContactFormHeight();
    this.handleWindowResize();
    this.hasInitialPostalCode = false;
    this.hasInitialStreetName = false;
    this.hasInitialStreetNumber = false;
    this.hasInitialDistrict = false;
    this.hasInitialBlock = false;
    this.hasInitialLot = false;
    switch (frontendConfiguration.contactFormType) {
      case CONTACT_FORM_TYPE.BUSINESS:
        this.setState({ customerType: CUSTOMER_TYPE.BUSINESS });
        break;
      case CONTACT_FORM_TYPE.PRIVATE_AND_BUSINESS:
        this.setState({ customerType: CUSTOMER_TYPE.PRIVATE });
        break;
      default:
        this.setState({ customerType: CUSTOMER_TYPE.PRIVATE });
        break;
    }

    if (
      (frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY ||
        frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2) &&
      frontendConfiguration.contactFormType === CONTACT_FORM_TYPE.PRIVATE_AND_BUSINESS
    ) {
      if (this.getQuestionIndex(QUESTION_ID.HOUSE_CONNECTION_PRIVATELY_OR_BUSINESS) !== undefined) {
        if (
          customerJourneyAnswer.houseConnectionPrivatelyOrBusiness ===
          PRIVATELY_OR_BUSINESS.BUSINESS
        ) {
          this.setState({ customerType: CUSTOMER_TYPE.BUSINESS });
        } else {
          this.setState({ customerType: CUSTOMER_TYPE.PRIVATE });
        }
      }
      if (this.getQuestionIndex(QUESTION_ID.CHARGE_PRIVATELY_OR_BUSINESS) !== undefined) {
        if (customerJourneyAnswer.chargePrivatelyOrBusiness === PRIVATELY_OR_BUSINESS.BUSINESS) {
          this.setState({ customerType: CUSTOMER_TYPE.BUSINESS });
        } else {
          this.setState({ customerType: CUSTOMER_TYPE.PRIVATE });
        }
      }
    }
    this.initContactForm(availableFields);
    this.contactFormData = _.assignIn({}, this.contactFormData, contactFormData.customerData);
    this.businessCustomerData = _.assignIn(
      {},
      this.businessCustomerData,
      contactFormData.customerData
    );
    this.privateCustomerData = _.assignIn(
      {},
      this.privateCustomerData,
      contactFormData.customerData
    );
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { selectedProducts, updateContactForm } = this.props;
    const { customerType } = this.state;
    if (prevProps.selectedProducts !== selectedProducts) {
      setTimeout(() => {
        this.setScrollHeight();
      }, 200);
    }
    if (prevState.customerType !== customerType) {
      updateContactForm({
        customerType
      });
    }
  };

  initialSelectedCheckboxes = () => {
    const {
      frontendConfiguration: { checkboxes },
      linkColor
    } = this.props;
    let selectedCheckboxes = {};
    if (!checkboxes) {
      return {};
    }

    _.forEach(_.keys(checkboxes), checkbox => {
      if (checkbox === CHECKBOXES.OTHER_CHECKBOXES) {
        const otherCheckboxes = [];
        _.forEach(checkboxes[checkbox], item => {
          otherCheckboxes.push({
            label: replaceShortCodeContent(item, checkboxes.checkboxLinks, linkColor).contentNoHTML,
            checkboxLabel: replaceShortCodeContent(item, checkboxes.checkboxLinks, linkColor)
              .contentHTML,
            value: false,
            active: item.active
          });
        });
        selectedCheckboxes[checkbox] = otherCheckboxes;
      }

      if (
        (checkbox === CHECKBOXES.POLICY_LABEL || checkbox === CHECKBOXES.ADVERTISING) &&
        checkboxes[checkbox].active
      ) {
        selectedCheckboxes[checkbox] = {
          label: replaceShortCodeContent(checkboxes[checkbox], checkboxes.checkboxLinks, linkColor)
            .contentNoHTML,
          checkboxLabel: replaceShortCodeContent(
            checkboxes[checkbox],
            checkboxes.checkboxLinks,
            linkColor
          ).contentHTML,
          value: false,
          active: checkboxes[checkbox].active
        };
      }
    });
    return selectedCheckboxes;
  };

  initialPaymentOption = () => {
    let selectedPayment = SPECIAL_CHARACTER.EMPTY;
    const {
      frontendConfiguration: { onlinePurchaseSettingModel }
    } = this.props;
    if (
      onlinePurchaseSettingModel &&
      _.isArray(onlinePurchaseSettingModel.paymentMethods) &&
      onlinePurchaseSettingModel.paymentMethods.length > 0
    ) {
      const paymentOption = _.find(onlinePurchaseSettingModel.paymentMethods, item => item.active);
      if (paymentOption && paymentOption.paymentType) {
        selectedPayment = paymentOption.paymentType;
      }
    }
    return selectedPayment;
  };

  initialValue = name => {
    const {
      frontendConfiguration: { installationDetailsFields, additionalAddressesConfiguration }
    } = this.props;
    switch (name) {
      case SALUTATION_KEY.INSTALLATION_FIELD:
        return {
          sameAsBillingAddress: true,
          salutation: SPECIAL_CHARACTER.EMPTY,
          firstName: '',
          lastName: '',
          activeFullName: installationDetailsFields.activeFullName,
          streetName: '',
          streetNumber: '',
          district: '',
          block: '',
          lot: '',
          postalCode: '',
          city: '',
          country: getCountryGermanNameFromCode(installationDetailsFields.defaultCountry),
          companyName: '',
          email: '',
          phone: '',
          registerCourt: '',
          registerNumber: '',
          preferredDate: new Date(),
          isValid: null,
          requiredEmail: installationDetailsFields.requiredEmail,
          requiredPhone: installationDetailsFields.requiredPhone,
          activeRegistrationDetails: installationDetailsFields.activeRegistrationDetails,
          requiredRegisterCourt: installationDetailsFields.requiredRegisterCourt,
          requiredRegisterNumber: installationDetailsFields.requiredRegisterNumber,
          errorMessage: {
            address: false,
            isShowErrorMessageFirstName: false,
            isShowErrorMessageLastName: false,
            isShowErrorMessageEmail: false,
            isShowMessageInvalidEmailPattern: false,
            isShowErrorMessagePhone: false,
            isShowErrorMessageRegisterCourt: false,
            isShowErrorMessageRegisterNumber: false,
            preferredDate: false
          }
        };
      case SALUTATION_KEY.ADDITIONAL_ADDRESSES_FIELD:
        if (
          additionalAddressesConfiguration &&
          additionalAddressesConfiguration.additionalAddressModels
        ) {
          return _.map(additionalAddressesConfiguration.additionalAddressModels, item => {
            return {
              salutation: SPECIAL_CHARACTER.EMPTY,
              firstName: '',
              lastName: '',
              activeFullName: item.activeFullName,
              streetName: '',
              streetNo: '',
              postalCode: '',
              city: '',
              country: getCountryGermanNameFromCode(item.defaultCountry),
              companyName: '',
              email: '',
              phone: '',
              registerCourt: '',
              registerNumber: '',
              isValid: null,
              sameAsBillingAddress: true,
              requiredEmail: item.requiredEmail,
              requiredPhone: item.requiredPhone,
              activeRegistrationDetails: item.activeRegistrationDetails,
              requiredRegisterCourt: item.requiredRegisterCourt,
              requiredRegisterNumber: item.requiredRegisterNumber,
              errorMessage: {
                address: false,
                isShowErrorMessageFirstName: false,
                isShowErrorMessageLastName: false,
                isShowErrorMessageEmail: false,
                isShowMessageInvalidEmailPattern: false,
                isShowErrorMessagePhone: false,
                isShowErrorMessageRegisterCourt: false,
                isShowErrorMessageRegisterNumber: false
              }
            };
          });
        }
        return [];
      default:
        break;
    }
  };

  getCountryCodeFromGermanName = name => {
    for (const key in COUNTRY) {
      if (COUNTRY[key].germanName === name) {
        return COUNTRY[key].code;
      }
    }
  };

  initContactFormHeight = () => {
    const initHeight = setInterval(() => {
      if (this.scrollBlock) {
        this.setScrollHeight();
        clearInterval(initHeight);
      }
    }, 50);
  };

  handleWindowResize = () => {
    let resizeTimeout;
    window.addEventListener('resize', () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(this.setScrollHeight, 50);
    });
  };

  setScrollHeight = () => {
    const { height } = this.props;

    if (height === SPECIAL_CHARACTER.EMPTY) {
      // Display full content
      if (this.scrollBlock) {
        this.setState({ contactFormHeight: this.scrollBlock.clientHeight });
      }
    } else {
      // Display with scroll bar
      if (this.stickyBlock) {
        this.setState({
          contactFormHeight: `calc(${height} - ${this.stickyBlock.clientHeight}px)`
        });
      }
    }
  };

  getAvailableFieldsJSONToDisplayUI = () => {
    const { frontendConfiguration } = this.props;
    let json = _.assign([], frontendConfiguration.availableFields);
    for (let i = 0; i < json.length; i++) {
      if (json[i].key === CONTACT_FORM_KEY.CUSTOM_FIELD) {
        if (json[i].enable) {
          _.forEach(json[i].items, customField => {
            json.push(customField);
          });
          json.splice(i, 1);
          break;
        }
      }
    }

    return json;
  };

  initContactForm = fields => {
    _.forEach(fields, field => {
      if (field.enable) {
        switch (field.key) {
          case CONTACT_FORM_KEY.REASON_FOR_CONTACTING:
            this.contactFormData[field.key] = field.items[0].value;
            this.updatePrivateBusinessData(field.key, field.items[0].value);
            break;
          default:
            this.contactFormData[field.key] = SPECIAL_CHARACTER.EMPTY;
            this.updatePrivateBusinessData(field.key, SPECIAL_CHARACTER.EMPTY);
            break;
        }
        this.contactFormErrorMessage[field.key] = SPECIAL_CHARACTER.EMPTY;
      }
    });

    this.setState({
      contactFormData: this.contactFormData
    });
  };

  onChange = (name, e) => {
    const { updateContactForm } = this.props;
    switch (name) {
      case CONTACT_FORM_KEY.UPLOAD_DOCUMENTS:
        updateContactForm({ [name]: e });
        this.contactFormData[name] = e;
        this.updatePrivateBusinessData(name, e);
        break;
      case CONTACT_FORM_KEY.EXEMPT_UNTIL:
        this.contactFormData[name] = e;
        this.updatePrivateBusinessData(name, e);
        this.updateContactFormToStore(name, e);
        break;
      default:
        this.contactFormData[name] = e;
        this.updatePrivateBusinessData(name, e);
        this.updateContactFormToStore(name, e);
        break;
    }
  };

  updateContactFormToStore = (name, value) => {
    const { updateContactForm } = this.props;
    updateContactForm({ customerData: _.assignIn({}, this.contactFormData, { name: value }) });
  };

  updatePrivateBusinessData = (name, value) => {
    const { customerType, availableFields } = this.state;
    if (customerType === CUSTOMER_TYPE.BUSINESS) {
      if (name !== CONTACT_FORM_KEY.REASON_FOR_CONTACTING) {
        let reasonForContacting = availableFields.find(field => {
          return field && field.key === CONTACT_FORM_KEY.REASON_FOR_CONTACTING;
        });
        if (!_.isEmpty(reasonForContacting)) {
          if (!_.isEmpty(reasonForContacting.items)) {
            this.businessCustomerData[CONTACT_FORM_KEY.REASON_FOR_CONTACTING] =
              reasonForContacting.items[0].value;
          }
        }
      }
      this.businessCustomerData[name] = value;
    } else {
      this.privateCustomerData[name] = value;
    }
  };

  handleAvailableFields = (value, name) => {
    const { availableFields } = this.state;
    let taxExemptionStatusSelection = {};
    let cloneAvailableFields = _.assign([], availableFields);
    const taxExemptionStatusObject = _.find(cloneAvailableFields, item => item.key === name);
    if (taxExemptionStatusObject && taxExemptionStatusObject.items) {
      taxExemptionStatusSelection = _.find(
        taxExemptionStatusObject.items,
        item => item.value === value
      );
    }
    switch (taxExemptionStatusSelection && taxExemptionStatusSelection.key) {
      case CONTACT_FORM_KEY.EXEMPT_ID:
        cloneAvailableFields = _.map(cloneAvailableFields, item => {
          if (
            item.key === CONTACT_FORM_KEY.EXEMPT_UNTIL ||
            item.key === CONTACT_FORM_KEY.UPLOAD_DOCUMENTS
          ) {
            let data = _.assignIn({}, item);
            data.enable = true;
            return data;
          } else {
            return item;
          }
        });
        this.setState({
          availableFields: cloneAvailableFields
        });
        break;
      default:
        cloneAvailableFields = _.map(cloneAvailableFields, item => {
          if (
            item.key === CONTACT_FORM_KEY.EXEMPT_UNTIL ||
            item.key === CONTACT_FORM_KEY.UPLOAD_DOCUMENTS
          ) {
            let data = _.assignIn({}, item);
            data.enable = false;
            return data;
          } else {
            return item;
          }
        });
        this.setState({
          availableFields: cloneAvailableFields
        });
        break;
    }
  };

  onItemSelect = (name, value) => {
    const { customerType } = this.state;
    this.contactFormData[name] = value;
    if (customerType === CUSTOMER_TYPE.BUSINESS && name === CONTACT_FORM_KEY.TAX_EXEMPTION_STATUS) {
      this.handleAvailableFields(value, name);
      this.onCollapse();
    }
    this.updatePrivateBusinessData(name, value);
  };

  submitContactForm = async () => {
    const {
      frontendConfiguration,
      customerJourneyAnswer,
      updateContactForm,
      routeManager,
      selectedProducts,
      productSelections,
      contactFormData: { relatedDocuments }
    } = this.props;
    const { customerType, contactFormData } = this.state;
    let isValidRelatedDocumentData = null;
    let contactInfo = _.assign({}, contactFormData);
    this.resetError();
    this.validateContactForm();
    this.validateCheckboxes();
    if (contactInfo[CONTACT_FORM_KEY.TAX_ID] && contactInfo[CONTACT_FORM_KEY.TAX_ID].trim() > 0) {
      const checkValidTaxID = await this.validateValidTaxID(
        contactInfo[CONTACT_FORM_KEY.TAX_ID],
        contactInfo.companyName
      );
      if (!checkValidTaxID) {
        this.contactFormErrorMessage[CONTACT_FORM_KEY.TAX_ID] =
          MESSAGE_CONSTANTS.CHECK_VALID_TAX_ID;
      } else {
        this.contactFormErrorMessage[CONTACT_FORM_KEY.TAX_ID] = SPECIAL_CHARACTER.EMPTY;
      }
    }

    if (customerType === CUSTOMER_TYPE.PRIVATE) {
      updateContactForm({ [CONTACT_FORM_KEY.UPLOAD_DOCUMENTS]: [] });
      _.forEach(this.taxRegistrationKeys, key => {
        this.contactFormErrorMessage[key] = SPECIAL_CHARACTER.EMPTY;
      });
    }
    if (frontendConfiguration.isInstallationDetailsFieldsActive) {
      this.validateInstallationDetails();
    }
    if (
      frontendConfiguration.additionalAddressesConfiguration &&
      frontendConfiguration.additionalAddressesConfiguration.active
    ) {
      this.validateAdditionalAddresses();
    }
    if (
      frontendConfiguration.onlinePurchaseSettingModel &&
      frontendConfiguration.onlinePurchaseSettingModel.active
    ) {
      if (
        !this.paymentOptionsData.selectedPayment &&
        !checkSelectedProductOnRequest({
          selectedProducts,
          productSelections
        })
      ) {
        this.paymentOptionsData = {
          ...this.paymentOptionsData,
          isDataValid: false
        };
        return;
      } else {
        this.paymentOptionsData = {
          ...this.paymentOptionsData,
          isDataValid: true
        };
      }
    }
    if (
      frontendConfiguration.contactFormFileUploadOption &&
      frontendConfiguration.contactFormFileUploadOption.active &&
      frontendConfiguration.contactFormFileUploadOption.required
    ) {
      if (relatedDocuments?.length === 0) {
        isValidRelatedDocumentData = false;
      } else {
        isValidRelatedDocumentData = true;
      }
    }
    const opportunityDateApiModels = _.map(customerJourneyAnswer.datePickerItems, item => {
      return {
        date: item.date,
        label: item.label
      };
    });

    const opportunityMultipleChoiceApiModels = _.map(
      customerJourneyAnswer.multipleChoiceItems,
      item => {
        return {
          value: item.value,
          label: item.label
        };
      }
    );

    const contactCustomFields = this.getContactInfo().customFields;

    this.setState(
      prevState => ({
        ...prevState,
        opportunityDateApiModels,
        opportunityMultipleChoiceApiModels,
        contactFormData: this.contactFormData,
        contactCustomFields,
        isValidRelatedDocumentData: isValidRelatedDocumentData
      }),
      () => {
        const isValid = this.isContactFormValid();
        if (isValid) {
          if (routeManager.allow_submit !== BOOLEAN.FALSE) {
            this.disableRequestButton();
          }
          this.createCustomer();
        }
        this.setState({ isBillingAddressCompleted: this.isBillingAddressValid() });
      }
    );
  };

  disableRequestButton = () => {
    document.getElementById('contact-form-request-button').disabled = true;
    document.getElementById('contact-form-request-button').style.opacity = '0.5';
    document.getElementById('contact-form-request-button').style.cursor = 'wait';
  };

  resetError = () => {
    const {
      frontendConfiguration: { additionalAddressesConfiguration }
    } = this.props;
    const { availableFields, customerType } = this.state;

    _.forEach(availableFields, availableField => {
      if (availableField.enable) {
        this.contactFormErrorMessage[availableField.key] = SPECIAL_CHARACTER.EMPTY;
      }
    });

    if (customerType === CUSTOMER_TYPE.BUSINESS) {
      _.forEach(this.taxRegistrationKeys, key => {
        this.contactFormErrorMessage[key] = SPECIAL_CHARACTER.EMPTY;
      });
    }

    if (
      additionalAddressesConfiguration &&
      additionalAddressesConfiguration.additionalAddressModels
    ) {
      let cloneAdditionalAddressModels = _.assignIn(
        [],
        additionalAddressesConfiguration.additionalAddressModels
      );
      _.forEach(cloneAdditionalAddressModels, item => {
        if (item.requiredAddressFields) {
          this.contactFormErrorMessage[item.sectionName] = SPECIAL_CHARACTER.EMPTY;
        }
      });
    }
  };

  validateInvalidEmailPattern = (email, errorMessage) => {
    if (!_.isEmpty(email) && !PATTERN.EMAIL_PATTERN.test(email.trim())) {
      errorMessage.isShowMessageInvalidEmailPattern = true;
      return true;
    }
    return false;
  };

  validateInvalidPostcodePattern = (country, postcode) => {
    return (
      country &&
      (country === COUNTRY.GERMANY.englishName || country === COUNTRY.GERMANY.germanName) &&
      postcode &&
      postcode.trim() !== SPECIAL_CHARACTER.EMPTY &&
      !PATTERN.GERMAN_POSTCODE_PATTERN.test(postcode)
    );
  };

  validateInstallationDetails = () => {
    const {
      frontendConfiguration: { installationDetailsFields },
      customerJourneyAnswer: { streetListed }
    } = this.props;
    const { installationDetails } = this.state;
    let newInstallationDetails = {
      errorMessage: {
        isShowErrorMessageFirstName: false,
        isShowErrorMessageLastName: false,
        address: false,
        addressErrorMessage: SPECIAL_CHARACTER.EMPTY,
        preferredDate: false
      },
      isValid: true
    };

    if (
      installationDetailsFields.requiredAddressFields &&
      !installationDetails.sameAsBillingAddress
    ) {
      if (
        (installationDetailsFields.salutation.active &&
          installationDetailsFields.salutation.required &&
          (!installationDetails.salutation ||
            installationDetails.salutation === GERMAN_TEXT.SELECT_SALUTATION)) ||
        (installationDetails.activeFullName && _.isEmpty(installationDetails.firstName)) ||
        (installationDetails.activeFullName && _.isEmpty(installationDetails.lastName)) ||
        (_.isEmpty(installationDetails.streetName) && streetListed) ||
        (_.isEmpty(installationDetails.streetNumber) && streetListed) ||
        _.isEmpty(installationDetails.postalCode) ||
        _.isEmpty(installationDetails.city) ||
        (_.isEmpty(installationDetails.country) &&
          _.isEmpty(installationDetailsFields.defaultCountry)) ||
        (installationDetailsFields.activeEmail &&
          installationDetails.requiredEmail &&
          _.isEmpty(installationDetails.email)) ||
        (installationDetailsFields.activePhone &&
          installationDetails.requiredPhone &&
          _.isEmpty(installationDetails.phone)) ||
        (installationDetails.activeRegistrationDetails &&
          installationDetails.requiredRegisterCourt &&
          _.isEmpty(installationDetails.registerCourt)) ||
        (installationDetails.activeRegistrationDetails &&
          installationDetails.requiredRegisterNumber &&
          _.isEmpty(installationDetails.registerNumber)) ||
        (_.isEmpty(installationDetails.companyName) &&
          installationDetailsFields.activeCompanyName &&
          installationDetailsFields.requiredCompanyName)
      ) {
        if (
          installationDetailsFields.salutation.active &&
          installationDetailsFields.salutation.required &&
          (!installationDetails.salutation ||
            installationDetails.salutation === GERMAN_TEXT.SELECT_SALUTATION)
        ) {
          newInstallationDetails.errorMessage.isShowErrorMessageSalutation = true;
        }
        if (installationDetails.activeFullName && _.isEmpty(installationDetails.firstName)) {
          newInstallationDetails.errorMessage.isShowErrorMessageFirstName = true;
        }
        if (installationDetails.activeFullName && _.isEmpty(installationDetails.lastName)) {
          newInstallationDetails.errorMessage.isShowErrorMessageLastName = true;
        }
        if (installationDetails.requiredEmail && _.isEmpty(installationDetails.email)) {
          newInstallationDetails.errorMessage.isShowErrorMessageEmail = true;
        } else {
          this.validateInvalidEmailPattern(
            installationDetails.email,
            newInstallationDetails.errorMessage
          );
        }
        if (installationDetails.requiredPhone && _.isEmpty(installationDetails.phone)) {
          newInstallationDetails.errorMessage.isShowErrorMessagePhone = true;
        }
        if (installationDetails.installationDetails) {
          if (
            installationDetails.requiredRegisterCourt &&
            _.isEmpty(installationDetails.registerCourt)
          ) {
            newInstallationDetails.errorMessage.isShowErrorMessageRegisterCourt = true;
          }
          if (
            installationDetails.requiredRegisterNumber &&
            _.isEmpty(installationDetails.registerNumber)
          ) {
            newInstallationDetails.errorMessage.isShowErrorMessageRegisterNumber = true;
          }
        }
        if (
          (_.isEmpty(installationDetails.streetName) && streetListed) ||
          (_.isEmpty(installationDetails.streetNumber) && streetListed) ||
          _.isEmpty(installationDetails.postalCode) ||
          _.isEmpty(installationDetails.city) ||
          (_.isEmpty(installationDetails.country) &&
            _.isEmpty(installationDetailsFields.defaultCountry)) ||
          (_.isEmpty(installationDetails.companyName) &&
            installationDetailsFields.activeCompanyName &&
            installationDetailsFields.requiredCompanyName)
        ) {
          newInstallationDetails.errorMessage.address = true;
          newInstallationDetails.errorMessage.addressErrorMessage =
            MESSAGE_CONSTANTS.MESSAGE_ADDRESS_FIELDS_REQUIRED;
        }
        newInstallationDetails.isValid = false;
      } else {
        if (
          this.validateInvalidEmailPattern(
            installationDetails.email,
            newInstallationDetails.errorMessage
          )
        ) {
          newInstallationDetails.isValid = false;
        }
        if (
          this.validateInvalidPostcodePattern(
            installationDetails.country,
            installationDetails.postalCode
          )
        ) {
          newInstallationDetails.errorMessage.address = true;
          newInstallationDetails.errorMessage.addressErrorMessage =
            MESSAGE_CONSTANTS.POSTCODE_INVALID;
          newInstallationDetails.isValid = false;
        }
      }
    } else {
      newInstallationDetails.isValid = true;
    }

    let {
      activePreferredInstallationDate = false,
      requiredPreferredInstallationDate = false
    } = installationDetailsFields;

    if (
      activePreferredInstallationDate &&
      requiredPreferredInstallationDate &&
      !installationDetails.preferredDate
    ) {
      newInstallationDetails.errorMessage.preferredDate = true;
      newInstallationDetails.isValid = false;
    }
    this.setState({
      installationDetails: {
        ...installationDetails,
        ...newInstallationDetails
      }
    });
  };

  validateAdditionalAddresses = () => {
    const {
      frontendConfiguration: { additionalAddressesConfiguration }
    } = this.props;
    const { additionalAddresses } = this.state;
    let cloneAdditionalAddresses = _.assignIn([], additionalAddresses);
    let cloneAdditionalAddressModels = _.assignIn(
      [],
      additionalAddressesConfiguration.additionalAddressModels
    );
    _.forEach(cloneAdditionalAddressModels, (item, index) => {
      if (item.requiredAddressFields && !cloneAdditionalAddresses[index].sameAsBillingAddress) {
        if (
          (cloneAdditionalAddressModels[index].salutation.active &&
            cloneAdditionalAddressModels[index].salutation.required &&
            (!additionalAddresses[index].salutation ||
              additionalAddresses[index].salutation === GERMAN_TEXT.SELECT_SALUTATION)) ||
          (additionalAddresses[index].activeFullName &&
            _.isEmpty(additionalAddresses[index].firstName)) ||
          (additionalAddresses[index].activeFullName &&
            _.isEmpty(additionalAddresses[index].lastName)) ||
          _.isEmpty(additionalAddresses[index].streetName) ||
          _.isEmpty(additionalAddresses[index].streetNumber) ||
          _.isEmpty(additionalAddresses[index].postalCode) ||
          _.isEmpty(additionalAddresses[index].city) ||
          (_.isEmpty(additionalAddresses[index].country) && _.isEmpty(item.defaultCountry)) ||
          (cloneAdditionalAddressModels[index].activeEmail &&
            additionalAddresses[index].requiredEmail &&
            _.isEmpty(additionalAddresses[index].email)) ||
          (cloneAdditionalAddressModels[index].activePhone &&
            additionalAddresses[index].requiredPhone &&
            _.isEmpty(additionalAddresses[index].phone)) ||
          (additionalAddresses[index].activeRegistrationDetails &&
            additionalAddresses[index].requiredRegisterCourt &&
            _.isEmpty(additionalAddresses[index].registerCourt)) ||
          (additionalAddresses[index].activeRegistrationDetails &&
            additionalAddresses[index].requiredRegisterNumber &&
            _.isEmpty(additionalAddresses[index].registerNumber)) ||
          (_.isEmpty(additionalAddresses[index].companyName) &&
            item.activeCompanyName &&
            item.requiredCompanyName)
        ) {
          if (
            cloneAdditionalAddressModels[index].salutation.active &&
            cloneAdditionalAddressModels[index].salutation.required &&
            (!additionalAddresses[index].salutation ||
              additionalAddresses[index].salutation === GERMAN_TEXT.SELECT_SALUTATION)
          ) {
            cloneAdditionalAddresses[index].errorMessage.isShowErrorMessageSalutation = true;
          }
          if (
            additionalAddresses[index].activeFullName &&
            _.isEmpty(additionalAddresses[index].firstName)
          ) {
            cloneAdditionalAddresses[index].errorMessage.isShowErrorMessageFirstName = true;
          }
          if (
            additionalAddresses[index].activeFullName &&
            _.isEmpty(additionalAddresses[index].lastName)
          ) {
            cloneAdditionalAddresses[index].errorMessage.isShowErrorMessageLastName = true;
          }
          if (
            additionalAddresses[index].requiredEmail &&
            _.isEmpty(additionalAddresses[index].email)
          ) {
            cloneAdditionalAddresses[index].errorMessage.isShowErrorMessageEmail = true;
          } else {
            this.validateInvalidEmailPattern(
              additionalAddresses[index].email,
              cloneAdditionalAddresses[index].errorMessage
            );
          }
          if (
            additionalAddresses[index].requiredPhone &&
            _.isEmpty(additionalAddresses[index].phone)
          ) {
            cloneAdditionalAddresses[index].errorMessage.isShowErrorMessagePhone = true;
          }
          if (additionalAddresses[index].activeRegistrationDetails) {
            if (
              additionalAddresses[index].requiredRegisterCourt &&
              _.isEmpty(additionalAddresses[index].registerCourt)
            ) {
              cloneAdditionalAddresses[index].errorMessage.isShowErrorMessageRegisterCourt = true;
            }
            if (
              additionalAddresses[index].requiredRegisterNumber &&
              _.isEmpty(additionalAddresses[index].registerNumber)
            ) {
              cloneAdditionalAddresses[index].errorMessage.isShowErrorMessageRegisterNumber = true;
            }
          }
          if (
            _.isEmpty(additionalAddresses[index].streetName) ||
            _.isEmpty(additionalAddresses[index].streetNumber) ||
            _.isEmpty(additionalAddresses[index].postalCode) ||
            _.isEmpty(additionalAddresses[index].city) ||
            (_.isEmpty(additionalAddresses[index].country) && _.isEmpty(item.defaultCountry)) ||
            (_.isEmpty(additionalAddresses[index].companyName) &&
              item.activeCompanyName &&
              item.requiredCompanyName)
          ) {
            cloneAdditionalAddresses[index].errorMessage.address = true;
            cloneAdditionalAddresses[index].errorMessage.addressErrorMessage =
              MESSAGE_CONSTANTS.MESSAGE_ADDRESS_FIELDS_REQUIRED;
          }
          this.contactFormErrorMessage[item.sectionName] = MESSAGE_CONSTANTS.REQUIRED;
          cloneAdditionalAddresses[index].isValid = false;
        } else {
          if (
            this.validateInvalidEmailPattern(
              additionalAddresses[index].email,
              cloneAdditionalAddresses[index].errorMessage
            )
          ) {
            this.contactFormErrorMessage[item.sectionName] = MESSAGE_CONSTANTS.REQUIRED;
            cloneAdditionalAddresses[index].isValid = false;
          }
          if (
            this.validateInvalidPostcodePattern(
              additionalAddresses[index].country,
              additionalAddresses[index].postalCode
            )
          ) {
            cloneAdditionalAddresses[index].errorMessage.address = true;
            cloneAdditionalAddresses[index].errorMessage.addressErrorMessage =
              MESSAGE_CONSTANTS.POSTCODE_INVALID;
            cloneAdditionalAddresses[index].isValid = false;
            this.contactFormErrorMessage[item.sectionName] = MESSAGE_CONSTANTS.REQUIRED;
          }
        }
      } else {
        cloneAdditionalAddresses[index].isValid = true;
      }
    });
    this.setState({ additionalAddresses: cloneAdditionalAddresses });
  };

  isBillingAddressValid = () => {
    for (const key in this.contactFormErrorMessage) {
      if (this.contactFormErrorMessage.hasOwnProperty(key)) {
        if (this.contactFormErrorMessage[key] !== SPECIAL_CHARACTER.EMPTY) {
          return false;
        }
      }
    }
    return true;
  };

  isContactFormValid = () => {
    const { frontendConfiguration } = this.props;
    const { installationDetails, isValidRelatedDocumentData } = this.state;
    for (const key in this.contactFormErrorMessage) {
      if (this.contactFormErrorMessage.hasOwnProperty(key)) {
        if (this.contactFormErrorMessage[key] !== SPECIAL_CHARACTER.EMPTY) {
          return false;
        }
      }
    }
    return (
      !(frontendConfiguration.isInstallationDetailsFieldsActive && !installationDetails.isValid) &&
      !(
        frontendConfiguration.contactFormFileUploadOption &&
        frontendConfiguration.contactFormFileUploadOption.active &&
        isValidRelatedDocumentData === false
      ) &&
      !this.checkboxesErrorMessage[CHECKBOXES.POLICY_LABEL] &&
      !this.checkboxesErrorMessage[CHECKBOXES.ADVERTISING] &&
      this.validateOtherCheckboxes()
    );
  };

  validateOtherCheckboxes = () => {
    let checkOtherCheckbox = true;
    const { selectedCheckboxes } = this.state;
    const {
      frontendConfiguration: { checkboxes }
    } = this.props;
    if (
      checkboxes &&
      _.isArray(checkboxes.otherCheckboxes) &&
      checkboxes.otherCheckboxes.length > 0
    ) {
      _.forEach(checkboxes.otherCheckboxes, (item, index) => {
        if (item.active && item.required) {
          if (
            selectedCheckboxes.otherCheckboxes[index] &&
            !selectedCheckboxes.otherCheckboxes[index].value
          ) {
            checkOtherCheckbox = false;
          }
        }
      });
    }
    return checkOtherCheckbox;
  };

  validateContactForm = () => {
    const { customerType, availableFields } = this.state;
    _.forEach(availableFields, availableField => {
      if (availableField.enable) {
        const key = availableField.key;
        const value = this.contactFormData[key];

        if (
          key === CONTACT_FORM_KEY.SALUTATION &&
          availableField.required &&
          (!value || value === GERMAN_TEXT.SELECT_SALUTATION)
        ) {
          this.contactFormErrorMessage[key] = MESSAGE_CONSTANTS.REQUIRED;
        }

        if (
          key === CONTACT_FORM_KEY.EMAIL &&
          (value.trim() !== SPECIAL_CHARACTER.EMPTY && !PATTERN.EMAIL_PATTERN.test(value.trim()))
        ) {
          this.contactFormErrorMessage[key] = MESSAGE_CONSTANTS.EMAIL_INVALID;
        }

        if (
          key === CONTACT_FORM_KEY.MOBILE_NUMBER &&
          availableField.required &&
          (value.trim() !== SPECIAL_CHARACTER.EMPTY && !PATTERN.PHONE_PATTERN.test(value))
        ) {
          this.contactFormErrorMessage[key] = MESSAGE_CONSTANTS.PHONE_INVALID;
        }

        if (key === CONTACT_FORM_KEY.ADDRESS) {
          const { country, postalCode } =
            customerType === CUSTOMER_TYPE.PRIVATE
              ? this.privateCustomerData
              : this.businessCustomerData;
          if (this.validateInvalidPostcodePattern(country, postalCode)) {
            this.contactFormErrorMessage[key] = MESSAGE_CONSTANTS.POSTCODE_INVALID;
          }
        }

        if (availableField.type === INPUT_TYPE_CONTACT_FORM.TEXT && value && value.length > 200) {
          this.contactFormErrorMessage[key] = MESSAGE_CONSTANTS.TOO_LONG;
        }

        if (
          availableField.type === INPUT_TYPE_CONTACT_FORM.TEXTAREA &&
          value &&
          value.length > 1500
        ) {
          this.contactFormErrorMessage[key] = MESSAGE_CONSTANTS.MESSAGE_TOO_LONG;
        }

        if (
          availableField.required &&
          key !== CONTACT_FORM_KEY.EXEMPT_UNTIL &&
          key !== CONTACT_FORM_KEY.UPLOAD_DOCUMENTS &&
          value.trim() === SPECIAL_CHARACTER.EMPTY &&
          key !== CONTACT_FORM_KEY.ADDRESS &&
          key !== CONTACT_FORM_KEY.CONTACT_PERSON &&
          key !== CONTACT_FORM_KEY.SECTION_NAME
        ) {
          if (
            customerType === CUSTOMER_TYPE.PRIVATE &&
            key !== CONTACT_FORM_KEY.COMPANY_NAME &&
            key !== CONTACT_FORM_KEY.TAX_EXEMPTION_STATUS
          ) {
            this.contactFormErrorMessage[key] = MESSAGE_CONSTANTS.REQUIRED;
          }
          if (
            customerType === CUSTOMER_TYPE.BUSINESS &&
            key !== CONTACT_FORM_KEY.FIRST_NAME &&
            key !== CONTACT_FORM_KEY.LAST_NAME &&
            key !== CONTACT_FORM_KEY.EMAIL
          ) {
            this.contactFormErrorMessage[key] = MESSAGE_CONSTANTS.REQUIRED;
          }
        }

        if (
          availableField.required &&
          !value &&
          (key === CONTACT_FORM_KEY.EXEMPT_UNTIL || key === CONTACT_FORM_KEY.UPLOAD_DOCUMENTS)
        ) {
          this.contactFormErrorMessage[key] = MESSAGE_CONSTANTS.REQUIRED;
        }

        if (
          (key === CONTACT_FORM_KEY.ADDRESS && availableField.required) ||
          (customerType === CUSTOMER_TYPE.BUSINESS &&
            ((key === CONTACT_FORM_KEY.CONTACT_PERSON && availableField.required) ||
              key === CONTACT_FORM_KEY.REGISTRATION_DETAILS))
        ) {
          for (let subQuestion of availableField.items) {
            if (
              subQuestion.enable &&
              (!this.contactFormData[subQuestion.key] ||
                (subQuestion.required &&
                  (_.trim(this.contactFormData[subQuestion.key]) === SPECIAL_CHARACTER.EMPTY ||
                    (customerType === CUSTOMER_TYPE.BUSINESS &&
                      !this.businessCustomerData[subQuestion.key]) ||
                    (customerType === CUSTOMER_TYPE.PRIVATE &&
                      !this.privateCustomerData[subQuestion.key]))))
            ) {
              if (subQuestion.key !== CONTACT_FORM_KEY.ADDRESS_LABELS) {
                if (key === CONTACT_FORM_KEY.ADDRESS) {
                  this.contactFormErrorMessage[key] =
                    MESSAGE_CONSTANTS.MESSAGE_ADDRESS_FIELDS_REQUIRED;
                  break;
                } else if (subQuestion.required) {
                  this.contactFormErrorMessage[subQuestion.key] = MESSAGE_CONSTANTS.REQUIRED;
                }
              }
            }
          }
        }
      }
    });
    console.log(this.contactFormErrorMessage);
  };

  validateValidTaxID = (value, companyName) => {
    const { checkValidTaxID } = this.props;
    return new Promise(resolve => {
      checkValidTaxID({
        taxid: value,
        companyname: companyName
      }).then(response => {
        if (response.type === CHECK_VALID_TAX_ID_SUCCESS) {
          resolve(response.payload.taxIdValid);
        } else {
          resolve(false);
        }
      });
    });
  };

  validateCheckboxes = () => {
    const {
      frontendConfiguration: { checkboxes }
    } = this.props;
    const { selectedCheckboxes } = this.state;
    if (!checkboxes || _.isEmpty(selectedCheckboxes)) {
      return;
    }
    if (checkboxes.policyLabel.active && checkboxes.policyLabel.required) {
      if (!selectedCheckboxes[CHECKBOXES.POLICY_LABEL].value) {
        this.checkboxesErrorMessage = {
          ...this.checkboxesErrorMessage,
          [CHECKBOXES.POLICY_LABEL]: true
        };
      } else {
        this.checkboxesErrorMessage = {
          ...this.checkboxesErrorMessage,
          [CHECKBOXES.POLICY_LABEL]: false
        };
      }
    }

    if (checkboxes.advertising.active) {
      if (checkboxes.advertising.required) {
        if (!selectedCheckboxes[CHECKBOXES.ADVERTISING].value) {
          this.checkboxesErrorMessage = {
            ...this.checkboxesErrorMessage,
            [CHECKBOXES.ADVERTISING]: true
          };
        } else {
          this.checkboxesErrorMessage = {
            ...this.checkboxesErrorMessage,
            [CHECKBOXES.ADVERTISING]: false
          };
          this.setState({ okToContact: true });
        }
      } else {
        if (selectedCheckboxes[CHECKBOXES.ADVERTISING].value) {
          this.setState({ okToContact: true });
        }
      }
    }

    if (_.isArray(checkboxes.otherCheckboxes) && checkboxes.otherCheckboxes.length > 0) {
      let cloneIsValidOtherCheckboxes = _.assignIn([], selectedCheckboxes.otherCheckboxes);
      _.forEach(checkboxes.otherCheckboxes, (item, index) => {
        if (item.active && item.required) {
          cloneIsValidOtherCheckboxes[index] = !!(
            selectedCheckboxes.otherCheckboxes[index] &&
            !selectedCheckboxes.otherCheckboxes[index].value
          );
        } else {
          cloneIsValidOtherCheckboxes[index] = false;
        }
      });
      this.checkboxesErrorMessage = {
        ...this.checkboxesErrorMessage,
        [CHECKBOXES.OTHER_CHECKBOXES]: cloneIsValidOtherCheckboxes
      };
    }
  };

  getAnswers = () => {
    const {
      frontendConfiguration,
      customerJourneyAnswer,
      customerJourneyMapping,
      productSelections,
      allProductDeactivated,
      currQuestions
    } = this.props;
    let answers = [];

    _.forEach(currQuestions, question => {
      if (question.enable) {
        const response = {
          questionId: question.id,
          question: formatQuestion(question.questionTitle, customerJourneyAnswer.name),
          answer: SPECIAL_CHARACTER.EMPTY,
          unit: SPECIAL_CHARACTER.EMPTY,
          electricConsumption: 0
        };

        let householdSize = customerJourneyAnswer.householdSize;
        let electricCar = customerJourneyAnswer.electricCar;
        const whereToHeat = customerJourneyAnswer.whereToHeat;
        const address = customerJourneyAnswer.address;

        switch (question.id) {
          case QUESTION_ID.NAME:
            response.answer = customerJourneyAnswer.name;
            break;

          case QUESTION_ID.HOUSEHOLD_SIZE:
            if (allProductDeactivated) {
              return;
            }
            if (householdSize.toString() === HOUSEHOLD_SIZE.SIX) {
              householdSize = HOUSEHOLD_SIZE.FIVE_PLUS;
            }
            response.answer = householdSize.toString();
            response.electricConsumption = customerJourneyMapping.householdConsumption;
            break;

          case QUESTION_ID.AUTARKY:
            if (allProductDeactivated) {
              return;
            }
            response.answer = customerJourneyAnswer.autarky;
            response.unit = UNIT.PERCENT;
            break;

          case QUESTION_ID.ELECTRIC_CAR:
            if (allProductDeactivated) {
              return;
            }
            if (
              electricCar === USAGE_OF_ELECTRIC_CAR.YES ||
              electricCar === USAGE_OF_ELECTRIC_CAR.NO
            ) {
              response.answer = question.objectModel.items[electricCar];
            }
            break;

          case QUESTION_ID.ELECTRIC_CAR_USAGE:
            if (customerJourneyAnswer.electricCar === USAGE_OF_ELECTRIC_CAR.YES) {
              response.answer = customerJourneyAnswer.electricCarUsage;
              response.electricConsumption = customerJourneyMapping.electricCarUsageConsumption;
              response.unit = UNIT.KILOMETER;
            }
            break;

          case QUESTION_ID.ELECTRIC_CAR_CHARGE_SPEED:
            if (
              customerJourneyAnswer.electricCar === USAGE_OF_ELECTRIC_CAR.YES &&
              customerJourneyAnswer.electricCarChargeSpeed >= 0
            ) {
              response.answer =
                question.objectModel.buttonLabels[customerJourneyAnswer.electricCarChargeSpeed];
            }
            break;

          case QUESTION_ID.POWER_FLAT_RATE:
            if (customerJourneyAnswer.powerFlatRate >= 0) {
              response.answer = question.objectModel.items[customerJourneyAnswer.powerFlatRate];
            }
            break;

          case QUESTION_ID.USAGE_OF_GAS:
            if (customerJourneyAnswer.usageOfGas >= 0) {
              response.answer = question.objectModel.items[customerJourneyAnswer.usageOfGas];
            }
            break;

          case QUESTION_ID.OUTPUT_OF_HEATING_KNOWING:
            if (customerJourneyAnswer.outputOfHeatingKnowing >= 0) {
              response.answer = convertDataToComma(
                question.objectModel.items[customerJourneyAnswer.outputOfHeatingKnowing]
              );
            }
            break;

          case QUESTION_ID.OUTPUT_OF_HEATING:
            if (
              customerJourneyAnswer.outputOfHeatingKnowing === KNOWING_OF_HEATING_OUTPUT.YES &&
              customerJourneyAnswer.outputHeating >= 0
            ) {
              response.answer = convertDataToComma(customerJourneyAnswer.outputHeating);
              response.unit = UNIT.KWTH_HTML;
            }
            break;

          case QUESTION_ID.HEATING_NAME:
            response.answer = customerJourneyAnswer.name;
            break;

          case QUESTION_ID.HEATING_ITEM:
            if (whereToHeat >= 0) {
              response.answer =
                question.objectModel.items[WHERE_TO_HEAT.PROPERTIES[whereToHeat].ENGLISH];
            }
            break;

          case QUESTION_ID.HEATING_LIVING_SPACE:
            if (customerJourneyAnswer.whereToHeat === WHERE_TO_HEAT.SINGLE_FAMILY_HOUSE) {
              if (customerJourneyAnswer.heatingLivingSpaceIndex >= 0) {
                const options = [
                  question.objectModel.minValue +
                    ' - ' +
                    question.objectModel.items[HEATING_LIVING_SPACE.FIRST_LEVEL_MAX_SIZE],
                  question.objectModel.items[HEATING_LIVING_SPACE.FIRST_LEVEL_MAX_SIZE] +
                    ' - ' +
                    question.objectModel.items[HEATING_LIVING_SPACE.SECOND_LEVEL_MAX_SIZE],
                  question.objectModel.items[HEATING_LIVING_SPACE.SECOND_LEVEL_MAX_SIZE] +
                    ' - ' +
                    question.objectModel.items[HEATING_LIVING_SPACE.THIRD_LEVEL_MAX_SIZE],
                  question.objectModel.items[HEATING_LIVING_SPACE.THIRD_LEVEL_MAX_SIZE] +
                    ' - ' +
                    question.objectModel.maxValue
                ];
                response.answer = options[customerJourneyAnswer.heatingLivingSpaceIndex];
              } else {
                response.answer = customerJourneyAnswer.heatingLivingSpace;
              }
              response.unit = UNIT.SQUARE_METRE;
            }
            break;

          case QUESTION_ID.HEATING_BUILDING_YEAR:
            if (customerJourneyAnswer.whereToHeat === WHERE_TO_HEAT.SINGLE_FAMILY_HOUSE) {
              response.answer = customerJourneyAnswer.heatingBuiltYear;
            }
            break;

          case QUESTION_ID.HOUSE_CONNECTION_NAME:
            response.answer = customerJourneyAnswer.name;
            break;

          case QUESTION_ID.HOUSE_CONNECTION_NEW_OR_CHANGE:
            if (allProductDeactivated) {
              return;
            }
            response.answer =
              question.objectModel.items[customerJourneyAnswer.houseConnectionNewOrChange];
            break;

          case QUESTION_ID.HOUSE_CONNECTION_PRIVATELY_OR_BUSINESS:
            if (
              customerJourneyAnswer.houseConnectionNewOrChange ===
              HOUSE_CONNECTION_NEW_OR_CHANGE.CHANGE
            ) {
              return;
            }
            if (frontendConfiguration.contactFormType !== CONTACT_FORM_TYPE.PRIVATE_AND_BUSINESS) {
              return;
            }
            if (allProductDeactivated) {
              return;
            }
            response.answer =
              question.objectModel.items[customerJourneyAnswer.houseConnectionPrivatelyOrBusiness];
            break;

          case QUESTION_ID.HOUSE_CONNECTION_PREPARATION_WATER_HEATERS:
            response.answer =
              question.objectModel.items[customerJourneyAnswer.houseConnectionWarmWaterPreparation];
            break;

          case QUESTION_ID.HOUSE_CONNECTION_BUILDING_TYPE:
            if (
              customerJourneyAnswer.houseConnectionNewOrChange ===
              HOUSE_CONNECTION_NEW_OR_CHANGE.CHANGE
            ) {
              return;
            }
            response.answer = customerJourneyAnswer.houseConnectionBuildingTypeName;
            break;

          case QUESTION_ID.HOUSE_CONNECTION_SINGLE_FAMILY_HOUSE:
            response.answer =
              question.objectModel.items[customerJourneyAnswer.houseConnectionCellar];
            break;

          case QUESTION_ID.HOUSE_CONNECTION_APARTMENT_BUILDING:
            if (
              customerJourneyAnswer.houseConnectionNewOrChange ===
              HOUSE_CONNECTION_NEW_OR_CHANGE.CHANGE
            ) {
              return;
            }
            if (
              customerJourneyAnswer.houseConnectionBuildingType !==
              HOUSE_CONNECTION_BUILDING_TYPE.APARTMENT_BUILDING
            ) {
              return;
            }
            response.answer = customerJourneyAnswer.houseConnectionNumberOfApartment;
            break;

          case QUESTION_ID.HOUSE_CONNECTION_SELECT_PRODUCT:
            if (
              customerJourneyAnswer.houseConnectionNewOrChange ===
              HOUSE_CONNECTION_NEW_OR_CHANGE.CHANGE
            ) {
              return;
            }
            response.answer = this.getSelectedProductName();
            break;

          case QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_MAX_POWER:
            if (
              customerJourneyAnswer.houseConnectionNewOrChange ===
              HOUSE_CONNECTION_NEW_OR_CHANGE.CHANGE
            ) {
              return;
            }
            if (!productSelections.isSelectedProductHouseConnectionForElectricity) {
              return;
            }
            response.question = response.question + ' (Strom - max. Leistungsbedarf)';
            response.answer = convertDataToComma(
              customerJourneyAnswer.houseConnectionElectricityMaxPower
            );
            response.unit = UNIT.KILOWALT;
            break;

          case QUESTION_ID.HOUSE_CONNECTION_GAS_MAX_LOAD:
            if (
              customerJourneyAnswer.houseConnectionNewOrChange ===
              HOUSE_CONNECTION_NEW_OR_CHANGE.CHANGE
            ) {
              return;
            }
            if (!productSelections.isSelectedProductHouseConnectionForGas) {
              return;
            }
            response.question = response.question + ' (Gas - max. Leistungsbedarf)';
            response.answer = convertDataToComma(customerJourneyAnswer.houseConnectionGasMaxLoad);
            response.unit = UNIT.KILOWALT;
            break;

          case QUESTION_ID.HOUSE_CONNECTION_WATER_MAX_FLOW:
            if (
              customerJourneyAnswer.houseConnectionNewOrChange ===
              HOUSE_CONNECTION_NEW_OR_CHANGE.CHANGE
            ) {
              return;
            }
            if (!productSelections.isSelectedProductHouseConnectionForWater) {
              return;
            }
            response.question = response.question + ' (Wasser - max. Durchfluss)';
            response.answer = convertDataToComma(
              customerJourneyAnswer.houseConnectionWaterMaxFlow ||
                customerJourneyAnswer.houseConnectionExtinguishingWaterDemand +
                  customerJourneyAnswer.houseConnectionPeakWater
            );
            response.unit = UNIT.WATER_FLOW;
            break;

          case QUESTION_ID.HOUSE_CONNECTION_IS_OWNER:
            if (
              customerJourneyAnswer.houseConnectionNewOrChange ===
              HOUSE_CONNECTION_NEW_OR_CHANGE.CHANGE
            ) {
              return;
            }
            response.answer =
              question.objectModel.items[customerJourneyAnswer.houseConnectionIsOwner];
            break;

          case QUESTION_ID.HOUSE_CONNECTION_UPLOAD_CONSENT_FORM:
            if (
              customerJourneyAnswer.houseConnectionNewOrChange ===
              HOUSE_CONNECTION_NEW_OR_CHANGE.CHANGE
            ) {
              return;
            }
            if (customerJourneyAnswer.houseConnectionIsOwner === HOUSE_CONNECTION_IS_OWNER.YES) {
              return;
            }
            response.answer = this.getAttachmentsName(customerJourneyAnswer.consentForm);
            break;

          case QUESTION_ID.HOUSE_CONNECTION_IS_NEW_BUILDING:
            if (
              customerJourneyAnswer.houseConnectionNewOrChange ===
              HOUSE_CONNECTION_NEW_OR_CHANGE.CHANGE
            ) {
              return;
            }
            response.answer =
              question.objectModel.items[customerJourneyAnswer.houseConnectionIsNewBuilding];
            break;

          case QUESTION_ID.HOUSE_CONNECTION_UPLOAD_PROPERTY_DOCUMENT:
            if (
              customerJourneyAnswer.houseConnectionNewOrChange ===
              HOUSE_CONNECTION_NEW_OR_CHANGE.CHANGE
            ) {
              return;
            }
            response.answer = this.getAttachmentsName(customerJourneyAnswer.propertyDocument);
            break;

          case QUESTION_ID.HOUSE_CONNECTION_TRENCH_LENGTH:
            if (
              customerJourneyAnswer.houseConnectionNewOrChange ===
              HOUSE_CONNECTION_NEW_OR_CHANGE.CHANGE
            ) {
              return;
            }
            response.answer = convertDataToComma(
              customerJourneyAnswer.houseConnectionTrenchLength === SPECIAL_CHARACTER
                ? 0
                : customerJourneyAnswer.houseConnectionTrenchLength
            );
            response.answer += ` ${UNIT.METER}`;
            if (customerJourneyAnswer.isDigMyOwnTrench) {
              const confirmation =
                frontendConfiguration.locale === LOCALE.DE
                  ? CONFIRM_LOCALE.YES_DE
                  : CONFIRM_LOCALE.YES_EN;
              response.answer += `${SPECIAL_CHARACTER.LINE_BREAK}${confirmation}`;
              response.answer += `${SPECIAL_CHARACTER.LINE_BREAK}${customerJourneyAnswer.digMyOwnTrench}`;
            }
            break;

          case QUESTION_ID.HOUSE_CONNECTION_WATER_STREET_FRONT_LENGTH:
            response.answer = convertDataToComma(
              customerJourneyAnswer.houseConnectionWaterStreetFrontLength === SPECIAL_CHARACTER
                ? 0
                : customerJourneyAnswer.houseConnectionWaterStreetFrontLength
            );
            response.answer += ` ${UNIT.METER}`;
            break;
          case QUESTION_ID.HOUSE_CONNECTION_WATER_PROPERTY_SIZE:
            response.answer = convertDataToComma(
              customerJourneyAnswer.houseConnectionWaterPropertySize === SPECIAL_CHARACTER
                ? 0
                : customerJourneyAnswer.houseConnectionWaterPropertySize
            );
            response.answer += ` ${UNIT.SQUARE_METRE}`;
            break;
          case QUESTION_ID.HOUSE_CONNECTION_WATER_FLOOR_SPACE:
            response.answer = convertDataToComma(
              customerJourneyAnswer.houseConnectionWaterFloorSpace === SPECIAL_CHARACTER
                ? 0
                : customerJourneyAnswer.houseConnectionWaterFloorSpace
            );
            response.answer += ` ${UNIT.SQUARE_METRE}`;
            break;
          case QUESTION_ID.HOUSE_CONNECTION_UNITS:
            if (
              customerJourneyAnswer.houseConnectionNewOrChange ===
              HOUSE_CONNECTION_NEW_OR_CHANGE.CHANGE
            ) {
              return;
            }
            if (
              customerJourneyAnswer.houseConnectionBuildingType ===
              HOUSE_CONNECTION_BUILDING_TYPE.BROADBAND_CABLE_REPEATER
            ) {
              return;
            }
            response.answer = convertDataToComma(customerJourneyAnswer.houseConnectionUnits);
            break;

          case QUESTION_ID.HOUSE_CONNECTION_REQUIRED_VALUES:
            if (
              customerJourneyAnswer.houseConnectionNewOrChange ===
              HOUSE_CONNECTION_NEW_OR_CHANGE.CHANGE
            ) {
              return;
            }
            response.answer = this.getHouseConnectionRequiredValuesAnswer();
            break;

          case QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_ADDITIONAL:
            if (
              customerJourneyAnswer.houseConnectionNewOrChange ===
              HOUSE_CONNECTION_NEW_OR_CHANGE.CHANGE
            ) {
              return;
            }
            response.answer = this.getHouseConnectionElectricityAnswer(question);
            break;

          case QUESTION_ID.HOUSE_CONNECTION_INSTALLATION_DATE:
            if (
              customerJourneyAnswer.houseConnectionNewOrChange ===
              HOUSE_CONNECTION_NEW_OR_CHANGE.CHANGE
            ) {
              return;
            }
            response.answer = moment(customerJourneyAnswer.installationDate).format('DD.MM.YYYY');
            break;

          case QUESTION_ID.HOUSE_CONNECTION_E_MOBILITY:
            if (
              customerJourneyAnswer.houseConnectionNewOrChange ===
              HOUSE_CONNECTION_NEW_OR_CHANGE.CHANGE
            ) {
              return;
            }
            response.answer = question.objectModel.items[customerJourneyAnswer.eMobilityPlan];
            if (
              question.objectModel.checkBoxConfig.isActive &&
              customerJourneyAnswer.eMobilityAgree
            ) {
              response.answer +=
                SPECIAL_CHARACTER.LINE_BREAK + question.objectModel.checkBoxConfig.title;
            }
            break;

          case QUESTION_ID.HOUSE_CONNECTION_E_MOBILITY_REQUIREMENT:
            if (
              customerJourneyAnswer.houseConnectionNewOrChange ===
                HOUSE_CONNECTION_NEW_OR_CHANGE.CHANGE ||
              customerJourneyAnswer.eMobilityPlan !== HOUSE_CONNECTION_E_MOBILITY.YES
            ) {
              return;
            }
            if (customerJourneyAnswer.eMobilityPlan === HOUSE_CONNECTION_E_MOBILITY.YES) {
              response.answer = convertDataToComma(customerJourneyAnswer.eMobilityPower);
              response.unit = UNIT.KILOWALT;
            }
            break;

          case QUESTION_ID.POSTAL_CODE:
            response.answer = customerJourneyAnswer.postalCode;
            break;

          case QUESTION_ID.ADDRESS_DETAILS:
            response.answer = this.getAddressAnswer();
            break;

          case QUESTION_ID.PIN_ADDRESS_ON_MAP:
            response.answer = 'Koordinate: (' + address.lat + ', ' + address.lng + ')';
            break;

          case QUESTION_ID.CHARGE_NAME:
            response.answer = customerJourneyAnswer.name;
            break;

          case QUESTION_ID.CHARGE_PRIVATELY_OR_BUSINESS:
            if (!currQuestions[this.getQuestionIndex(QUESTION_ID.CHARGE_PRIVATELY_OR_BUSINESS)]) {
              return;
            }
            response.answer =
              question.objectModel.items[customerJourneyAnswer.chargePrivatelyOrBusiness];
            break;

          case QUESTION_ID.CHARGE_TYPE:
            if (!currQuestions[this.getQuestionIndex(QUESTION_ID.CHARGE_TYPE)]) {
              return;
            }
            if (customerJourneyAnswer.chargeType === CHARGE_TYPE.CHARGING_STATION.index) {
              response.answer = question.objectModel.items[CHARGE_TYPE.CHARGING_STATION.name];
            }
            if (customerJourneyAnswer.chargeType === CHARGE_TYPE.WALLBOX.index) {
              response.answer = question.objectModel.items[CHARGE_TYPE.WALLBOX.name];
            }
            break;

          case QUESTION_ID.CHARGE_CAR_SPEED:
            if (customerJourneyAnswer.chargeCarSpeed === CHARGE_CAR_SPEED.SLOW.index) {
              response.answer = question.objectModel.tableItems.items[CHARGE_CAR_SPEED.SLOW.name];
            }
            if (customerJourneyAnswer.chargeCarSpeed === CHARGE_CAR_SPEED.MEDIUM.index) {
              response.answer = question.objectModel.tableItems.items[CHARGE_CAR_SPEED.MEDIUM.name];
            }
            if (customerJourneyAnswer.chargeCarSpeed === CHARGE_CAR_SPEED.FAST.index) {
              response.answer = question.objectModel.tableItems.items[CHARGE_CAR_SPEED.FAST.name];
            }
            break;

          case QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_FEED_INTO_GRID:
            response.answer =
              customerJourneyAnswer.houseConnectionElectricityIsFeedIntoGrid ===
              HOUSE_CONNECTION_ELECTRICITY_FEED_INTO_GRID.YES
                ? convertDataToComma(
                    question.objectModel.items[
                      customerJourneyAnswer.houseConnectionElectricityIsFeedIntoGrid
                    ]
                  ) +
                  SPECIAL_CHARACTER.SPACE +
                  SPECIAL_CHARACTER.DASH +
                  SPECIAL_CHARACTER.SPACE +
                  convertDataToComma(customerJourneyAnswer.houseConnectionElectricityFeedIntoGrid) +
                  SPECIAL_CHARACTER.SPACE +
                  UNIT.KILOWALT
                : convertDataToComma(
                    question.objectModel.items[
                      customerJourneyAnswer.houseConnectionElectricityIsFeedIntoGrid
                    ]
                  );
            break;
          case QUESTION_ID.HOUSE_CONNECTION_PEAK_WATER:
            response.answer =
              convertDataToComma(customerJourneyAnswer.houseConnectionPeakWater) +
              SPECIAL_CHARACTER.SPACE +
              UNIT.WATER_FLOW;
            break;

          case QUESTION_ID.HOUSE_CONNECTION_OUTLET_PRESSURE_REQUIRED:
            response.answer =
              convertDataToComma(customerJourneyAnswer.houseConnectionOutletPressureRequired) +
              SPECIAL_CHARACTER.SPACE +
              UNIT.M_BAR;
            break;

          case QUESTION_ID.HOUSE_CONNECTION_EXTINGUISHING_WATER_DEMAND:
            response.answer =
              customerJourneyAnswer.houseConnectionIsExtinguishingWaterDemand ===
              HOUSE_CONNECTION_EXTINGUISHING_WATER_DEMAND.YES
                ? convertDataToComma(
                    question.objectModel.items[
                      customerJourneyAnswer.houseConnectionIsExtinguishingWaterDemand
                    ]
                  ) +
                  SPECIAL_CHARACTER.SPACE +
                  SPECIAL_CHARACTER.DASH +
                  SPECIAL_CHARACTER.SPACE +
                  convertDataToComma(
                    customerJourneyAnswer.houseConnectionExtinguishingWaterDemand
                  ) +
                  SPECIAL_CHARACTER.SPACE +
                  UNIT.CUBIC_METRE
                : convertDataToComma(
                    question.objectModel.items[
                      customerJourneyAnswer.houseConnectionIsExtinguishingWaterDemand
                    ]
                  );
            break;

          default:
            if (question.objectModel && question.objectModel[customerJourneyAnswer[question.id]]) {
              response.answer = question.objectModel[customerJourneyAnswer[question.id]].content;
            }
            if (
              Array.isArray(customerJourneyAnswer.datePickerItems) &&
              customerJourneyAnswer.datePickerItems.length
            ) {
              const cloneDatePickerItems = [...customerJourneyAnswer.datePickerItems];
              const dateIndex = _.findIndex(cloneDatePickerItems, item => item.id === question.id);
              if (dateIndex > -1) {
                response.answer = cloneDatePickerItems[dateIndex].date
                  ? moment(cloneDatePickerItems[dateIndex].date).format('DD.MM.YYYY')
                  : cloneDatePickerItems[dateIndex].date;
              }
            }
            if (question.type === QUESTION_TYPE.FILE_UPLOAD) {
              const fileIndex = _.findIndex(
                customerJourneyAnswer.fileUpload,
                fileUpload => fileUpload.questionId === question.id
              );
              if (fileIndex != null && customerJourneyAnswer.fileUpload[fileIndex]) {
                response.answer = this.getAttachmentsName(
                  customerJourneyAnswer.fileUpload[fileIndex].files
                );
              }
            }
            break;
        }
        answers.push(response);
      }
    });
    return answers;
  };

  getAddressAnswer = () => {
    const { customerJourneyAnswer } = this.props;
    let answer = SPECIAL_CHARACTER.EMPTY;
    const address = customerJourneyAnswer.address;

    if (customerJourneyAnswer.comment) {
      answer += customerJourneyAnswer.comment + SPECIAL_CHARACTER.LINE_BREAK;
    }
    if (address.street || address.number) {
      answer +=
        'Straße + Hausnummer: ' +
        (
          (address.street ? address.street : SPECIAL_CHARACTER.EMPTY) +
          SPECIAL_CHARACTER.SPACE +
          (address.number ? address.number : SPECIAL_CHARACTER.EMPTY) +
          SPECIAL_CHARACTER.LINE_BREAK
        ).trim();
    }
    if (address.district) {
      answer += 'Ortsteil/Gemarkung: ' + address.district + SPECIAL_CHARACTER.LINE_BREAK;
    }
    if (address.block) {
      answer += 'Flur: ' + address.block + SPECIAL_CHARACTER.LINE_BREAK;
    }
    if (address.lot) {
      answer += 'Flurstück: ' + address.lot + SPECIAL_CHARACTER.LINE_BREAK;
    }

    return answer;
  };

  getHouseConnectionElectricityAnswer = question => {
    const {
      customerJourneyAnswer,
      currentState: { currQuestionMap }
    } = this.props;
    let answer = SPECIAL_CHARACTER.EMPTY;

    if (currQuestionMap[QUESTION_ID.HOUSE_CONNECTION_UNITS]) {
      answer +=
        `Wohneinheiten (Menge: ${customerJourneyAnswer.houseConnectionUnits}) - Gesamt: ${Number(
          customerJourneyAnswer.housingElectricityMaxPower
        ).toFixed(2)} kW` + SPECIAL_CHARACTER.LINE_BREAK;
    }

    if (customerJourneyAnswer.eMobilityPlan === HOUSE_CONNECTION_E_MOBILITY.YES) {
      answer +=
        `Ladeeinrichtung (Menge: 1) - Gesamt: ${customerJourneyAnswer.eMobilityPower} kW` +
        SPECIAL_CHARACTER.LINE_BREAK;
    }

    if (customerJourneyAnswer.housingElectricityMaxPower)
      _.forEach(customerJourneyAnswer.additionalPowerItems, item => {
        let totalPrice = 0;
        let otherOption = '';
        let tmpWaterHeaterConcurrency = '';
        if (
          question.objectModel.items['Other'] &&
          question.objectModel.items['Other'] === item.name
        ) {
          otherOption = item.otherOption ? ': ' + item.otherOption : '';
        }
        item.name ===
        currQuestionMap[QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_ADDITIONAL].objectModel.items[
          WATER_HEATER_NAME
        ]
          ? _.forEach(
              currQuestionMap[QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_ADDITIONAL].objectModel
                .waterHeaterConcurrency,
              waterHeaterConcurrency => {
                if (
                  (Number(waterHeaterConcurrency.max) >= Number(waterHeaterConcurrency.min) &&
                    Number(item.quantity) <= Number(waterHeaterConcurrency.max) &&
                    Number(item.quantity) >= Number(waterHeaterConcurrency.min)) ||
                  (Number(waterHeaterConcurrency.max) >= Number(waterHeaterConcurrency.min) &&
                    Number(item.quantity) > Number(waterHeaterConcurrency.max))
                ) {
                  totalPrice =
                    Number(item.output) *
                    Number(waterHeaterConcurrency.value) *
                    Number(item.quantity);
                  tmpWaterHeaterConcurrency =
                    ' * ' + Number(waterHeaterConcurrency.value).toFixed(2);
                }
              }
            )
          : (totalPrice = Number(item.output) * Number(item.quantity));
        answer +=
          item.name +
          otherOption +
          ' (Menge: ' +
          item.quantity +
          ') - ' +
          item.quantity +
          ' * ' +
          item.output +
          SPECIAL_CHARACTER.SPACE +
          UNIT.KILOWALT +
          tmpWaterHeaterConcurrency +
          ' - Gesamt: ' +
          formatNumber(totalPrice).toString() +
          SPECIAL_CHARACTER.SPACE +
          UNIT.KILOWALT +
          SPECIAL_CHARACTER.LINE_BREAK;
      });

    return answer;
  };

  getHouseConnectionRequiredValuesAnswer = () => {
    const { customerJourneyAnswer, productSelections } = this.props;
    let answer = SPECIAL_CHARACTER.EMPTY;

    if (productSelections.isSelectedProductHouseConnectionForElectricity) {
      answer +=
        'Strom - max. Leistungsbedarf ' +
        convertDataToComma(customerJourneyAnswer.houseConnectionElectricityMaxPower) +
        SPECIAL_CHARACTER.SPACE +
        UNIT.KILOWALT +
        SPECIAL_CHARACTER.LINE_BREAK;
    }
    if (productSelections.isSelectedProductHouseConnectionForGas) {
      answer +=
        'Gas - max. Leistungsbedarf ' +
        convertDataToComma(customerJourneyAnswer.houseConnectionGasMaxLoad) +
        SPECIAL_CHARACTER.SPACE +
        UNIT.KILOWALT +
        SPECIAL_CHARACTER.LINE_BREAK;
    }
    if (productSelections.isSelectedProductHouseConnectionForWater) {
      answer +=
        'Wasser - max. Durchfluss ' +
        convertDataToComma(
          customerJourneyAnswer.houseConnectionWaterMaxFlow ||
            customerJourneyAnswer.houseConnectionExtinguishingWaterDemand +
              customerJourneyAnswer.houseConnectionPeakWater
        ) +
        SPECIAL_CHARACTER.SPACE +
        UNIT.WATER_FLOW +
        SPECIAL_CHARACTER.LINE_BREAK;
    }
    if (productSelections.isSelectedProductHouseConnectionForHeating) {
      answer +=
        'Fernwärme - (max. Leistungsbedarf) ' +
        convertDataToComma(customerJourneyAnswer.houseConnectionHeatingCapacity) +
        SPECIAL_CHARACTER.SPACE +
        UNIT.KILOWALT +
        SPECIAL_CHARACTER.LINE_BREAK;
    }

    return answer;
  };

  getAttachmentsName = attachments => {
    let attachmentsName = SPECIAL_CHARACTER.EMPTY;

    _.forEach(attachments, file => {
      attachmentsName = attachmentsName + file.name + SPECIAL_CHARACTER.LINE_BREAK;
    });

    return attachmentsName;
  };

  getQuestionIndex = questionId => {
    const { currQuestions } = this.props;
    let questionIndex = undefined;

    _.forEach(currQuestions, (question, index) => {
      if (question.id === questionId) {
        questionIndex = index;
      }
    });

    return questionIndex;
  };

  getSelectedProductName = () => {
    const { productSelections } = this.props;
    let productSelected = SPECIAL_CHARACTER.EMPTY;

    if (productSelections.isSelectedProductHouseConnectionForElectricity) {
      productSelected =
        productSelected + PRODUCT_NAME.HOUSE_CONNECTION_ELECTRICITY + SPECIAL_CHARACTER.LINE_BREAK;
    }
    if (productSelections.isSelectedProductHouseConnectionForGas) {
      productSelected =
        productSelected + PRODUCT_NAME.HOUSE_CONNECTION_GAS + SPECIAL_CHARACTER.LINE_BREAK;
    }
    if (productSelections.isSelectedProductHouseConnectionForWater) {
      productSelected =
        productSelected + PRODUCT_NAME.HOUSE_CONNECTION_WATER + SPECIAL_CHARACTER.LINE_BREAK;
    }
    if (productSelections.isSelectedProductHouseConnectionForHeating) {
      productSelected =
        productSelected + PRODUCT_NAME.HOUSE_CONNECTION_HEAT + SPECIAL_CHARACTER.LINE_BREAK;
    }
    if (productSelections.isSelectedProductHouseConnectionForBroadband) {
      productSelected =
        productSelected + PRODUCT_NAME.HOUSE_CONNECTION_BROADBAND + SPECIAL_CHARACTER.LINE_BREAK;
    }
    return productSelected;
  };

  getContactInfo = () => {
    const { frontendConfiguration, contactFormData } = this.props;
    const {
      selectedCheckboxes,
      installationDetails,
      customerType,
      additionalAddresses,
      okToContact,
      onlinePurchaseOnlyRequest
    } = this.state;

    // Add contact form fields
    let contactInfo = {};
    if (customerType === CUSTOMER_TYPE.BUSINESS) {
      contactInfo = _.assign({}, this.businessCustomerData);
      contactInfo.address = SPECIAL_CHARACTER.EMPTY;
    } else {
      contactInfo = _.assign({}, this.privateCustomerData);
    }
    if (contactInfo.hasOwnProperty(CONTACT_FORM_KEY.ADDRESS)) {
      if (customerType === CUSTOMER_TYPE.BUSINESS) {
        contactInfo.address = getAddressFormatted(
          this.businessCustomerData[CONTACT_FORM_KEY.STREET_NAME],
          this.businessCustomerData[CONTACT_FORM_KEY.STREET_NUMBER],
          this.businessCustomerData[CONTACT_FORM_KEY.POSTAL_CODE],
          this.businessCustomerData[CONTACT_FORM_KEY.CITY_TOWN],
          this.businessCustomerData[CONTACT_FORM_KEY.COUNTRY]
        );
      } else {
        contactInfo.address = getAddressFormatted(
          this.privateCustomerData[CONTACT_FORM_KEY.STREET_NAME],
          this.privateCustomerData[CONTACT_FORM_KEY.STREET_NUMBER],
          this.privateCustomerData[CONTACT_FORM_KEY.POSTAL_CODE],
          this.privateCustomerData[CONTACT_FORM_KEY.CITY_TOWN],
          this.privateCustomerData[CONTACT_FORM_KEY.COUNTRY]
        );
      }
    }
    delete contactInfo[CONTACT_FORM_KEY.STREET_NAME];
    delete contactInfo[CONTACT_FORM_KEY.STREET_NUMBER];
    delete contactInfo[CONTACT_FORM_KEY.POSTAL_CODE];
    delete contactInfo[CONTACT_FORM_KEY.CITY_TOWN];
    delete contactInfo[CONTACT_FORM_KEY.COUNTRY];
    delete contactInfo[CONTACT_FORM_KEY.REGISTRATION_DETAILS];
    delete contactInfo[CONTACT_FORM_KEY.UPLOAD_DOCUMENTS];

    if (customerType === CUSTOMER_TYPE.BUSINESS) {
      contactInfo.taxExemptionStatus = contactInfo.taxExemptionStatus || EXAMPTION_DATA.NOT_EXEMPT;
      if (!_.isEmpty(contactFormData.uploadTaxDocuments)) {
        contactInfo.uploadTaxDocuments = _.map(
          contactFormData.uploadTaxDocuments,
          elem => elem.path
        ).join(SPECIAL_CHARACTER.LINE_BREAK);
        contactInfo.uploadTaxDocuments =
          contactInfo.uploadTaxDocuments + SPECIAL_CHARACTER.LINE_BREAK;
      }
    }

    const {
      onlinePurchaseSettingModel: { onlinePurchaseOnlyRequestConfiguration }
    } = frontendConfiguration;
    if (onlinePurchaseOnlyRequestConfiguration && onlinePurchaseOnlyRequestConfiguration.active) {
      contactInfo.onlinePurchaseOnlyRequest = {
        label: onlinePurchaseOnlyRequestConfiguration.checkboxLabel,
        value: onlinePurchaseOnlyRequest
      };
    }

    // Add CustomFields array
    contactInfo.customFields = [];
    const originCustomFields = _.assign([], frontendConfiguration.availableFields);
    _.forEach(originCustomFields, customField => {
      if (customField.key === CONTACT_FORM_KEY.CUSTOM_FIELD && customField.enable) {
        _.forEach(customField.items, subField => {
          let item = {
            label: subField.value,
            value: contactInfo[subField.key]
          };
          contactInfo.customFields.push(item);
          delete contactInfo[subField.key];
        });
      }
    });
    let relatedDocumentsToString = SPECIAL_CHARACTER.EMPTY;
    const { relatedDocuments } = contactFormData;
    if (relatedDocuments?.length > 0) {
      _.forEach(relatedDocuments, file => {
        relatedDocumentsToString =
          relatedDocumentsToString + file.name + SPECIAL_CHARACTER.LINE_BREAK;
      });
      contactInfo.relatedDocuments = relatedDocumentsToString;
    }

    // Add Checkboxes
    if (
      (selectedCheckboxes[CHECKBOXES.POLICY_LABEL] &&
        selectedCheckboxes[CHECKBOXES.POLICY_LABEL].value) ||
      (selectedCheckboxes[CHECKBOXES.ADVERTISING] &&
        selectedCheckboxes[CHECKBOXES.ADVERTISING].value) ||
      !_.isEmpty(selectedCheckboxes[CHECKBOXES.OTHER_CHECKBOXES])
    ) {
      let allSelectedCheckboxes = _.values(selectedCheckboxes);
      allSelectedCheckboxes = _.flatten(allSelectedCheckboxes);
      let allSelectedCheckboxesActive = [];
      _.map(allSelectedCheckboxes, item => {
        if (item.active) {
          allSelectedCheckboxesActive.push({
            label: item.label,
            value: item.value
          });
        }
      });
      contactInfo.selectedCheckboxes = allSelectedCheckboxesActive;
    }
    contactInfo.okToContact = okToContact;

    // Add preferred installation Date
    if (frontendConfiguration.isInstallationDetailsFieldsActive) {
      let {
        activePreferredInstallationDate,
        preferredInstallationDate
      } = frontendConfiguration.installationDetailsFields;
      if (activePreferredInstallationDate) {
        contactInfo.preferredInstallationDate = {
          label: preferredInstallationDate,
          value: moment(installationDetails.preferredDate).format('DD.MM.YYYY')
        };
      }
    }

    // Installation address
    let installationAddress = {
      addressName: '',
      addressValue: ''
    };
    if (frontendConfiguration.isInstallationDetailsFieldsActive) {
      let { sectionName, checkboxLabel } = frontendConfiguration.installationDetailsFields;
      installationAddress.addressName = sectionName;
      if (installationDetails.sameAsBillingAddress) {
        installationAddress.addressValue = checkboxLabel;
      } else {
        const customerInfors = {
          salutation: installationDetails.salutation,
          firstName: installationDetails.firstName,
          lastName: installationDetails.lastName,
          companyName: installationDetails.companyName,
          phone: installationDetails.phone,
          email: installationDetails.email,
          activeRegistrationDetails: installationDetails.activeRegistrationDetails,
          registerCourt: installationDetails.registerCourt,
          registerNumber: installationDetails.registerNumber
        };
        installationAddress.addressValue =
          getCustomerInfoFormatted(customerInfors) +
          getAddressFormatted(
            installationDetails.streetName,
            installationDetails.streetNumber,
            installationDetails.district,
            installationDetails.block,
            installationDetails.lot,
            installationDetails.postalCode,
            installationDetails.city,
            installationDetails.country
          );
      }
    }
    contactInfo.installationAddress = installationAddress;

    // Add additional address
    const cloneAdditionalAddresses = _.assignIn([], additionalAddresses);
    let additionalAddressesResponse = [];
    if (
      frontendConfiguration.additionalAddressesConfiguration &&
      frontendConfiguration.additionalAddressesConfiguration.active &&
      frontendConfiguration.additionalAddressesConfiguration.additionalAddressModels
    ) {
      let cloneAdditionalAddressModels = _.assignIn(
        [],
        frontendConfiguration.additionalAddressesConfiguration.additionalAddressModels
      );
      additionalAddressesResponse = _.map(cloneAdditionalAddresses, (item, index) => {
        const customerInfors = {
          salutation: item.salutation,
          firstName: item.firstName,
          lastName: item.lastName,
          companyName: item.companyName,
          phone: item.phone,
          email: item.email,
          activeRegistrationDetails: item.activeRegistrationDetails,
          registerCourt: item.registerCourt,
          registerNumber: item.registerNumber
        };
        return {
          addressName: cloneAdditionalAddressModels[index].sectionName,
          addressValue: item.sameAsBillingAddress
            ? cloneAdditionalAddressModels[index].checkboxLabel
            : getCustomerInfoFormatted(customerInfors) +
              getAddressFormatted(
                item.streetName,
                item.streetNumber,
                item.postalCode,
                item.city,
                item.country
              )
        };
      });
    }
    contactInfo.additionalAddressesResponse = additionalAddressesResponse;
    return contactInfo;
  };

  getSelectedProducts = () => {
    const { products, productSelections } = this.props;
    let selectedProducts = [];

    _.forEach(products, product => {
      if (productSelections[PRODUCT_SELECTED_KEY[product.type]]) {
        selectedProducts.push(product);
      }
    });
    return selectedProducts;
  };

  getCustomerEmailInfo = () => {
    const {
      productSelections,
      frontendConfiguration,
      totalPrice,
      emailTemplateV2Data,
      totalPriceV2
    } = this.props;
    const { onlinePurchaseOnlyRequest } = this.state;
    const selectedProducts = this.getSelectedProducts();

    let customerEmailInfoData = {
      onlinePurchaseRequest: checkOnlinePurchase(
        frontendConfiguration,
        totalPrice,
        productSelections,
        selectedProducts,
        onlinePurchaseOnlyRequest,
        totalPriceV2
      )
    };
    if (
      frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY ||
      frontendConfiguration.frontendType === FRONTEND_TYPE.WEBSHOP
    ) {
      customerEmailInfoData.pricingInfoV1 = {
        rentalDuration: productSelections.selectedRentalInfo.duration,
        rentalUnit: RENTAL_UNIT[productSelections.selectedRentalInfo.unit],
        rentalDurationUnit: getRentalRate(
          productSelections.selectedPurchaseRental,
          productSelections.selectedRentalInfo
        ),
        paymentOption: productSelections.selectedPurchaseRental === PAYMENT_TYPE.PURCHASE ? 0 : 1,
        packageSummaryModels: getSummaryDataModelV1(selectedProducts, productSelections)
          .packageSummaryModels,
        addOnSummaryModels: getSummaryDataModelV1(selectedProducts, productSelections)
          .addOnSummaryModels
      };
    }

    if (frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2) {
      customerEmailInfoData.pricingInfoV2 = {
        productPackagesSummary: [...emailTemplateV2Data.productPackagesSummary]
      };
    }
    return customerEmailInfoData;
  };

  getSalutationKey = (name, value) => {
    let salutationKey = '';
    switch (name) {
      case SALUTATION_KEY.CONTACT_FORM_FIELD:
      case SALUTATION_KEY.INSTALLATION_FIELD:
      case SALUTATION_KEY.ADDITIONAL_ADDRESSES_FIELD:
        salutationKey = _.findKey(SALUTATION_DATA[LANGUAGE.DE], data => data === value);
        if (salutationKey) {
          return salutationKey;
        } else {
          return 'EMPTY';
        }
      default:
        break;
    }
  };

  getTaxExemption = value => {
    const { availableFields } = this.state;
    let taxExemptionStatusSelection = {};
    let taxExemption = false;
    const taxExemptionStatusObject = _.find(
      availableFields,
      item => item.key === CONTACT_FORM_KEY.TAX_EXEMPTION_STATUS
    );
    if (taxExemptionStatusObject && taxExemptionStatusObject.items) {
      taxExemptionStatusSelection = _.find(
        taxExemptionStatusObject.items,
        item => item.value === value
      );
      if (taxExemptionStatusSelection && taxExemptionStatusSelection.key) {
        taxExemption = taxExemptionStatusSelection.key === CONTACT_FORM_KEY.EXEMPT_ID;
      }
    }
    return taxExemption;
  };

  createCustomer = () => {
    const {
      frontendConfiguration,
      productSelections,
      totalPrice,
      selectedProducts,
      routeManager,
      createCustomer,
      updateThankYouPageData,
      createOpportunities,
      addressProperty,
      allProductDeactivated,
      routeToConnectErrorPage,
      routeToExceedRateLimitPage,
      createOPPackageV2RequestParams,
      totalPriceV2,
      totalPriceV2MixedOrder,
      emailTemplateV2Data,
      selectedAddOnV2MapKeyByProductType,
      paymentInfo,
      updateContactForm
    } = this.props;

    const {
      customerType,
      okToContact,
      installationDetails,
      availableFields,
      additionalAddresses,
      onlinePurchaseOnlyRequest
    } = this.state;

    const notAllowSubmit = routeManager.allow_submit === BOOLEAN.FALSE;

    if (!notAllowSubmit && !this.isPreviewCheckoutPage) {
      const contactInfo = this.getContactInfo();
      const contactInfoJSON = JSON.stringify(contactInfo);

      let frontEndResponseModel = {
        contactInfo: contactInfoJSON
      };

      if (
        frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY ||
        frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2
      ) {
        const answers = this.getAnswers();
        frontEndResponseModel.answers = JSON.stringify(answers);
      }
      if (frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2) {
        const summaryLogInfo = getSummaryLogInfo(
          frontendConfiguration.onlinePurchaseSettingModel.active,
          emailTemplateV2Data,
          totalPriceV2,
          totalPriceV2MixedOrder,
          selectedAddOnV2MapKeyByProductType
        );
        frontEndResponseModel.onlinePurchase = summaryLogInfo.onlinePurchase
          ? JSON.stringify(summaryLogInfo.onlinePurchase)
          : null;
        frontEndResponseModel.orderRequest = summaryLogInfo.orderRequest
          ? JSON.stringify(summaryLogInfo.orderRequest)
          : null;
      }

      let billingAddress;
      let deliveryAddress;

      // Add billing address object
      if (customerType === CUSTOMER_TYPE.BUSINESS) {
        billingAddress = {
          companyName:
            this.contactFormData[CONTACT_FORM_KEY.COMPANY_NAME] || SPECIAL_CHARACTER.EMPTY,
          addressLabels: this.contactFormData[CONTACT_FORM_KEY.ADDRESS_LABELS] || [],
          streetName:
            this.businessCustomerData[CONTACT_FORM_KEY.STREET_NAME] || SPECIAL_CHARACTER.EMPTY,
          streetNumber:
            this.businessCustomerData[CONTACT_FORM_KEY.STREET_NUMBER] || SPECIAL_CHARACTER.EMPTY,
          postcode: this.businessCustomerData[CONTACT_FORM_KEY.POSTAL_CODE],
          country: SPECIAL_CHARACTER.EMPTY,
          city: this.businessCustomerData[CONTACT_FORM_KEY.CITY_TOWN],
          firstName: this.businessCustomerData.firstName,
          lastName: this.businessCustomerData.lastName,
          email: this.businessCustomerData.email,
          phone: this.businessCustomerData.mobileNumber,
          salutation: this.getSalutationKey(
            SALUTATION_KEY.CONTACT_FORM_FIELD,
            this.businessCustomerData.salutation
          )
        };
        /* Change Country name to Country code*/
        billingAddress.country = this.getCountryCodeFromGermanName(
          this.businessCustomerData.country
        );
      } else {
        billingAddress = {
          addressLabels: this.contactFormData[CONTACT_FORM_KEY.ADDRESS_LABELS] || [],
          streetName:
            this.privateCustomerData[CONTACT_FORM_KEY.STREET_NAME] || SPECIAL_CHARACTER.EMPTY,
          streetNumber:
            this.privateCustomerData[CONTACT_FORM_KEY.STREET_NUMBER] || SPECIAL_CHARACTER.EMPTY,
          postcode: this.privateCustomerData[CONTACT_FORM_KEY.POSTAL_CODE],
          country: SPECIAL_CHARACTER.EMPTY,
          city: this.privateCustomerData[CONTACT_FORM_KEY.CITY_TOWN],
          firstName: this.privateCustomerData.firstName,
          lastName: this.privateCustomerData.lastName,
          email: this.privateCustomerData.email,
          phone: this.privateCustomerData.mobileNumber,
          salutation: this.getSalutationKey(
            SALUTATION_KEY.CONTACT_FORM_FIELD,
            this.privateCustomerData.salutation
          )
        };
        /* Change Country name to Country code*/
        billingAddress.country = this.getCountryCodeFromGermanName(
          this.privateCustomerData.country
        );
      }
      if (contactInfo.hasOwnProperty(CONTACT_FORM_KEY.ADDRESS)) {
        const addressConfigModel = availableFields.find(f => f.key === CONTACT_FORM_KEY.ADDRESS);
        if (addressConfigModel) {
          billingAddress.addressName = addressConfigModel.value;
        }
      }

      let contactDetails = {
        frontendToken: frontendConfiguration.token,
        customerType: customerType,
        salutation: this.getSalutationKey(
          SALUTATION_KEY.CONTACT_FORM_FIELD,
          this.privateCustomerData.salutation
        ),
        companyName: SPECIAL_CHARACTER.EMPTY,
        firstName: this.privateCustomerData.firstName,
        lastName: this.privateCustomerData.lastName,
        email: this.privateCustomerData.email,
        phone: this.privateCustomerData.mobileNumber,
        customerNumber: this.privateCustomerData.customerNumber,
        okToContact: okToContact,
        frontEndResponseModel: frontEndResponseModel,
        customerEmailInfo:
          frontendConfiguration.frontendType === FRONTEND_TYPE.CONTACT_FORM
            ? null
            : this.getCustomerEmailInfo(),
        customerAddressApiModel: billingAddress,
        paymentType: getOnlinePurchasePaymentType(paymentInfo.paymentType)
      };
      if (customerType === CUSTOMER_TYPE.BUSINESS) {
        const contactBusinessDetails = {
          salutation: this.getSalutationKey(
            SALUTATION_KEY.CONTACT_FORM_FIELD,
            this.businessCustomerData.salutation
          ),
          companyName: this.businessCustomerData.companyName,
          firstName: this.businessCustomerData.firstName,
          lastName: this.businessCustomerData.lastName,
          email: this.businessCustomerData.email,
          phone: this.businessCustomerData.mobileNumber,
          customerNumber: this.businessCustomerData.customerNumber
        };
        const taxAndRegistrationDetails = {
          taxId: this.businessCustomerData.taxId,
          exemptionEndDate: this.businessCustomerData.exemptUntil,
          taxExemption: this.getTaxExemption(this.businessCustomerData.taxExemptionStatus),
          registerCourt: this.businessCustomerData.registerCourt,
          registerNumber: this.businessCustomerData.registerNumber
        };
        contactDetails = _.assignIn(
          contactDetails,
          contactBusinessDetails,
          taxAndRegistrationDetails
        );
      }

      if (
        !_.isEmpty(addressProperty) ||
        _.isEqual(addressProperty, {
          district: null,
          plotdetail: {
            blockNo: null,
            lotNo: null
          }
        })
      ) {
        contactDetails.customerAddressPropertyModel = addressProperty;
      }

      if (frontendConfiguration.isInstallationDetailsFieldsActive) {
        // Add Delivery address
        let {
          addressFieldLabel,
          salutation,
          addressLabels,
          defaultCountry
        } = frontendConfiguration.installationDetailsFields;
        if (installationDetails.sameAsBillingAddress) {
          deliveryAddress = _.cloneDeep(billingAddress);
          if (!_.isEmpty(addressLabels)) {
            deliveryAddress.addressLabels = addressLabels;
          }
        } else {
          deliveryAddress = {
            firstName: installationDetails.firstName,
            lastName: installationDetails.lastName,
            streetName: installationDetails.streetName,
            streetNumber: installationDetails.streetNumber,
            district: installationDetails.district,
            block: installationDetails.block,
            lot: installationDetails.lot,
            postcode: installationDetails.postalCode,
            email: installationDetails.email,
            phone: installationDetails.phone,
            registerCourt: installationDetails.registerCourt,
            registerNumber: installationDetails.registerNumber,
            country: SPECIAL_CHARACTER.EMPTY,
            city: installationDetails.city,
            companyName: installationDetails.companyName,
            salutation:
              salutation && salutation.active
                ? this.getSalutationKey(
                    SALUTATION_KEY.INSTALLATION_FIELD,
                    installationDetails.salutation
                  )
                : SPECIAL_CHARACTER.EMPTY,
            addressLabels: addressLabels
          };
          deliveryAddress.addressName = addressFieldLabel;
          /* Change Country name to Country code*/
          deliveryAddress.country = _.isEmpty(installationDetails.country)
            ? defaultCountry
            : this.getCountryCodeFromGermanName(installationDetails.country);
        }
        contactDetails.sameAsBillingAddress = installationDetails.sameAsBillingAddress;
        contactDetails.deliveryAddress = deliveryAddress;
      } else {
        contactDetails.sameAsBillingAddress = false;
        deliveryAddress = this.generateDeliveryAddressFromAnswers(customerType);
      }
      contactDetails.deliveryAddress = deliveryAddress;

      if (
        frontendConfiguration.additionalAddressesConfiguration &&
        frontendConfiguration.additionalAddressesConfiguration.active
      ) {
        const cloneAdditionalAddresses = _.assignIn([], additionalAddresses);
        let cloneAdditionalAddressModels = _.assignIn(
          [],
          frontendConfiguration.additionalAddressesConfiguration.additionalAddressModels
        );
        contactDetails.additionalAddresses = _.map(cloneAdditionalAddresses, (item, index) => {
          return {
            salutation:
              cloneAdditionalAddressModels[index].salutation &&
              cloneAdditionalAddressModels[index].salutation.active
                ? this.getSalutationKey(SALUTATION_KEY.ADDITIONAL_ADDRESSES_FIELD, item.salutation)
                : SPECIAL_CHARACTER.EMPTY,
            firstName: item.firstName,
            lastName: item.lastName,
            streetName: item.streetName,
            streetNumber: item.streetNumber,
            postcode: item.postalCode,
            email: item.email,
            phone: item.phone,
            registerCourt: item.registerCourt,
            registerNumber: item.registerNumber,
            country: _.isEmpty(item.country)
              ? cloneAdditionalAddressModels[index].defaultCountry
              : this.getCountryCodeFromGermanName(item.country),
            city: item.city,
            sameAsBillingAddress: item.sameAsBillingAddress,
            addressName: cloneAdditionalAddressModels[index].sectionName,
            companyName: item.companyName,
            addressLabels: cloneAdditionalAddressModels[index].addressLabels
          };
        });
      }
      createCustomer(contactDetails).then(action => {
        updateThankYouPageData({
          userEmail:
            customerType === CUSTOMER_TYPE.BUSINESS
              ? this.businessCustomerData.email
              : this.privateCustomerData.email,
          orderNumber: action?.payload?.[ATTRIBUTE.ORDER_NUMBER]
        });
        switch (action.type) {
          case CREATE_CUSTOMER_SUCCESS:
            this.setState(
              {
                customerId: action.payload[ATTRIBUTE.ID],
                customerFirstName: action.payload[ATTRIBUTE.FIRST_NAME],
                customerLastName: action.payload[ATTRIBUTE.LAST_NAME],
                frontEndResponseId: action.payload[ATTRIBUTE.FRONT_END_RESPONSE_ID],
                orderNumber: action.payload[ATTRIBUTE.ORDER_NUMBER],
                opportunityBillingAddress: action.payload[ATTRIBUTE.BILLING_ADDRESS] || {},
                opportunityDeliveryAddress: action.payload[ATTRIBUTE.DELIVERY_ADDRESS] || {},
                opportunityAdditionalAddresses: action.payload[ATTRIBUTE.ADDITIONAL_ADDRESSES] || []
              },
              () => {
                const {
                  customerType,
                  customerId,
                  customerFirstName,
                  customerLastName,
                  frontEndResponseId,
                  orderNumber,
                  opportunityBillingAddress,
                  opportunityDeliveryAddress,
                  opportunityDateApiModels,
                  opportunityMultipleChoiceApiModels,
                  opportunityAdditionalAddresses,
                  contactCustomFields
                } = this.state;
                if (frontendConfiguration.frontendType === FRONTEND_TYPE.CONTACT_FORM) {
                  this.createOpportunity();
                } else if (allProductDeactivated) {
                  this.createGenericOpportunityForAllUnavailableProduct();
                } else {
                  createOpportunities({
                    customerId,
                    customerFirstName,
                    customerLastName,
                    customerType,
                    companyName: this.getCustomerDataForOpportunity().companyName,
                    firstName: this.getCustomerDataForOpportunity().firstName,
                    lastName: this.getCustomerDataForOpportunity().lastName,
                    reasonForContacting: this.getCustomerDataForOpportunity().reasonForContacting,
                    frontEndResponseId,
                    orderNumber: this.handleOrderNumber(
                      frontendConfiguration,
                      productSelections,
                      totalPrice,
                      totalPriceV2,
                      selectedProducts,
                      onlinePurchaseOnlyRequest,
                      orderNumber
                    ),
                    opportunityBillingAddress,
                    opportunityDeliveryAddress,
                    opportunityDateApiModels,
                    opportunityMultipleChoiceApiModels,
                    opportunityAdditionalAddresses,
                    purchaseType: checkOnlinePurchase(
                      frontendConfiguration,
                      totalPrice,
                      productSelections,
                      selectedProducts,
                      onlinePurchaseOnlyRequest,
                      totalPriceV2
                    )
                      ? PURCHASE_TYPE.ONLINE_PURCHASE
                      : PURCHASE_TYPE.REQUEST,
                    opportunityApiPricingModelV2:
                      frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2
                        ? createOPPackageV2RequestParams
                        : null,
                    productType: null,
                    contactCustomFields
                  });
                }
              }
            );
            break;
          case CREATE_CUSTOMER_FAILURE:
            if (action.status === 400) {
              this.handleCustomerError(action.payload);
            } else if (action.status === 406) {
              updateContactForm({
                isSubmittingContactForm: false
              });
              routeToExceedRateLimitPage();
            } else {
              updateContactForm({
                isSubmittingContactForm: false
              });
              routeToConnectErrorPage();
            }
            break;
          default:
            break;
        }
      });
    }
  };

  generateDeliveryAddressFromAnswers = customerType => {
    const { customerJourneyAnswer } = this.props;
    const { postalCode, address } = customerJourneyAnswer;
    let postal = null;
    let city = null;
    let country = null;
    if (postalCode) {
      postal = postalCode.split(SPECIAL_CHARACTER.SPACE)[0];
      city = postalCode.replace(postal + SPECIAL_CHARACTER.SPACE, SPECIAL_CHARACTER.EMPTY);
    }
    let countries = [];
    for (const key in COUNTRY) {
      countries.push(COUNTRY[key]);
    }
    if (address.country) {
      _.forEach(countries, item => {
        if (address.country === item.englishName) {
          country = item.germanName;
          return;
        }
      });
    }

    let deliveryAddress = {
      firstName:
        customerType === CUSTOMER_TYPE.BUSINESS
          ? this.businessCustomerData[CONTACT_FORM_KEY.FIRST_NAME] || null
          : this.privateCustomerData[CONTACT_FORM_KEY.FIRST_NAME] || null,
      lastName:
        customerType === CUSTOMER_TYPE.BUSINESS
          ? this.businessCustomerData[CONTACT_FORM_KEY.LAST_NAME] || null
          : this.privateCustomerData[CONTACT_FORM_KEY.LAST_NAME] || null,
      streetName: address.street || null,
      streetNumber: address.number || null,
      postcode: postal || null,
      email:
        customerType === CUSTOMER_TYPE.BUSINESS
          ? this.businessCustomerData[CONTACT_FORM_KEY.EMAIL] || null
          : this.privateCustomerData[CONTACT_FORM_KEY.EMAIL] || null,
      phone:
        customerType === CUSTOMER_TYPE.BUSINESS
          ? this.businessCustomerData[CONTACT_FORM_KEY.MOBILE_NUMBER] || null
          : this.privateCustomerData[CONTACT_FORM_KEY.MOBILE_NUMBER] || null,
      registerCourt: null,
      registerNumber: null,
      country: this.getCountryCodeFromGermanName(country) || null,
      city: city,
      companyName: CUSTOMER_TYPE.BUSINESS
        ? this.businessCustomerData[CONTACT_FORM_KEY.MOBILE_NUMBER]
        : SPECIAL_CHARACTER.EMPTY,
      salutation: SPECIAL_CHARACTER.EMPTY,
      addressLabels: []
    };
    deliveryAddress.addressName = DELIVERY_ADDRESS.DE;

    if (
      !deliveryAddress.streetName &&
      !deliveryAddress.streetNumber &&
      !deliveryAddress.postcode &&
      !deliveryAddress.city &&
      !deliveryAddress.country
    ) {
      deliveryAddress = null;
    }
    return deliveryAddress;
  };

  handleOrderNumber = (
    frontendConfiguration,
    productSelections,
    totalPrice,
    totalPriceV2,
    selectedProducts,
    onlinePurchaseOnlyRequest,
    orderNumber
  ) => {
    if (frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY) {
      return checkIsOnlinePurchase({
        frontendConfiguration,
        productSelections,
        totalPrice,
        selectedProducts,
        onlinePurchaseOnlyRequest
      }) === PURCHASE_TYPE.ONLINE_PURCHASE
        ? orderNumber
        : null;
    } else if (frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2) {
      return checkIsOnlinePurchaseV2(frontendConfiguration, totalPriceV2) ===
        PURCHASE_TYPE.ONLINE_PURCHASE
        ? orderNumber
        : null;
    }
  };

  createGenericOpportunityForAllUnavailableProduct = () => {
    const {
      createOpportunities,
      frontendConfiguration,
      productSelections,
      totalPrice,
      selectedProducts,
      createOPPackageV2RequestParams,
      totalPriceV2
    } = this.props;
    const {
      customerId,
      customerFirstName,
      customerLastName,
      customerType,
      frontEndResponseId,
      orderNumber,
      opportunityBillingAddress,
      opportunityDeliveryAddress,
      opportunityDateApiModels,
      opportunityMultipleChoiceApiModels,
      opportunityAdditionalAddresses,
      onlinePurchaseOnlyRequest,
      contactCustomFields
    } = this.state;

    createOpportunities({
      customerId,
      customerFirstName,
      customerLastName,
      customerType,
      companyName: this.getCustomerDataForOpportunity().companyName,
      firstName: this.getCustomerDataForOpportunity().firstName,
      lastName: this.getCustomerDataForOpportunity().lastName,
      reasonForContacting: this.getCustomerDataForOpportunity().reasonForContacting,
      frontEndResponseId,
      orderNumber: this.handleOrderNumber(
        frontendConfiguration,
        productSelections,
        totalPrice,
        totalPriceV2,
        selectedProducts,
        onlinePurchaseOnlyRequest,
        orderNumber
      ),
      opportunityBillingAddress,
      opportunityDeliveryAddress,
      opportunityDateApiModels,
      opportunityMultipleChoiceApiModels,
      opportunityAdditionalAddresses,
      title: generateOpportunityTitle(
        convertProdTypeToProdName(PRODUCT_TYPE.GENERIC),
        customerType,
        this.getCustomerDataForOpportunity().companyName,
        this.getCustomerDataForOpportunity().firstName,
        this.getCustomerDataForOpportunity().lastName,
        this.getCustomerDataForOpportunity().reasonForContacting
      ),
      opportunityPackageApiModelsData: [
        {
          productId: -1
        }
      ],
      purchaseType: checkOnlinePurchase(
        frontendConfiguration,
        totalPrice,
        productSelections,
        selectedProducts,
        onlinePurchaseOnlyRequest,
        totalPriceV2
      )
        ? PURCHASE_TYPE.ONLINE_PURCHASE
        : PURCHASE_TYPE.REQUEST,
      opportunityApiPricingModelV2:
        frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2
          ? createOPPackageV2RequestParams
          : null,
      productType: PRODUCT_TYPE.GENERIC,
      contactCustomFields
    });
  };

  createOpportunity = () => {
    const {
      products,
      createOpportunities,
      frontendConfiguration,
      productSelections,
      totalPrice,
      selectedProducts,
      createOPPackageV2RequestParams,
      totalPriceV2
    } = this.props;
    const {
      customerId,
      customerType,
      customerFirstName,
      customerLastName,
      frontEndResponseId,
      orderNumber,
      opportunityBillingAddress,
      opportunityDeliveryAddress,
      opportunityDateApiModels,
      opportunityMultipleChoiceApiModels,
      opportunityAdditionalAddresses,
      onlinePurchaseOnlyRequest,
      contactCustomFields
    } = this.state;

    createOpportunities({
      customerFirstName,
      customerLastName,
      customerId,
      frontEndResponseId,
      orderNumber: this.handleOrderNumber(
        frontendConfiguration,
        productSelections,
        totalPrice,
        totalPriceV2,
        selectedProducts,
        onlinePurchaseOnlyRequest,
        orderNumber
      ),
      opportunityBillingAddress,
      opportunityDeliveryAddress,
      opportunityDateApiModels,
      opportunityMultipleChoiceApiModels,
      opportunityAdditionalAddresses,
      title: generateOpportunityTitle(
        convertProdTypeToProdName(products[0].type),
        customerType,
        this.getCustomerDataForOpportunity().companyName,
        this.getCustomerDataForOpportunity().firstName,
        this.getCustomerDataForOpportunity().lastName,
        this.getCustomerDataForOpportunity().reasonForContacting
      ),
      opportunityPackageApiModelsData: [
        {
          productId: products[0].id
        }
      ],
      purchaseType: checkOnlinePurchase(
        frontendConfiguration,
        totalPrice,
        productSelections,
        selectedProducts,
        onlinePurchaseOnlyRequest,
        totalPriceV2
      )
        ? PURCHASE_TYPE.ONLINE_PURCHASE
        : PURCHASE_TYPE.REQUEST,
      opportunityApiPricingModelV2:
        frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2
          ? createOPPackageV2RequestParams
          : null,
      productType: products[0].type,
      contactCustomFields
    });
  };

  handleCustomerError = error => {
    const {
      frontendConfiguration,
      createOpportunities,
      routeToConnectErrorPage,
      allProductDeactivated,
      productSelections,
      totalPrice,
      selectedProducts,
      createOPPackageV2RequestParams,
      totalPriceV2
    } = this.props;
    const { onlinePurchaseOnlyRequest } = this.state;
    switch (error[ATTRIBUTE.ERROR_MESSAGE]) {
      case SERVER_MESSAGE_CONSTANTS.FIRST_NAME_NULL:
        this.contactFormErrorMessage[CONTACT_FORM_KEY.FIRST_NAME] = MESSAGE_CONSTANTS.REQUIRED;
        break;
      case SERVER_MESSAGE_CONSTANTS.FIRST_NAME_REQUIRED:
        this.contactFormErrorMessage[CONTACT_FORM_KEY.FIRST_NAME] = MESSAGE_CONSTANTS.REQUIRED;
        break;
      case SERVER_MESSAGE_CONSTANTS.FIRST_NAME_TOO_LONG:
        this.contactFormErrorMessage[CONTACT_FORM_KEY.FIRST_NAME] = MESSAGE_CONSTANTS.TOO_LONG;
        break;
      case SERVER_MESSAGE_CONSTANTS.LAST_NAME_NULL:
        this.contactFormErrorMessage[CONTACT_FORM_KEY.LAST_NAME] = MESSAGE_CONSTANTS.REQUIRED;
        break;
      case SERVER_MESSAGE_CONSTANTS.LAST_NAME_REQUIRED:
        this.contactFormErrorMessage[CONTACT_FORM_KEY.LAST_NAME] = MESSAGE_CONSTANTS.REQUIRED;
        break;
      case SERVER_MESSAGE_CONSTANTS.LAST_NAME_TOO_LONG:
        this.contactFormErrorMessage[CONTACT_FORM_KEY.LAST_NAME] = MESSAGE_CONSTANTS.TOO_LONG;
        break;
      case SERVER_MESSAGE_CONSTANTS.EMAIL_INVALID:
        this.contactFormErrorMessage[CONTACT_FORM_KEY.EMAIL] = MESSAGE_CONSTANTS.EMAIL_INVALID;
        break;
      case SERVER_MESSAGE_CONSTANTS.EMAIL_TOO_LONG:
        this.contactFormErrorMessage[CONTACT_FORM_KEY.EMAIL] = MESSAGE_CONSTANTS.TOO_LONG;
        break;
      case SERVER_MESSAGE_CONSTANTS.EMAIL_EXISTED:
      case SERVER_MESSAGE_CONSTANTS.COMPANY_NAME_EXISTED:
        this.setState(
          {
            customerId: error[ATTRIBUTE.ID],
            customerFirstName: error[ATTRIBUTE.FIRST_NAME],
            customerLastName: error[ATTRIBUTE.LAST_NAME],
            frontEndResponseId: error[ATTRIBUTE.FRONT_END_RESPONSE_ID],
            orderNumber: error[ATTRIBUTE.ORDER_NUMBER],
            opportunityBillingAddress: error[ATTRIBUTE.BILLING_ADDRESS] || {},
            opportunityDeliveryAddress: error[ATTRIBUTE.DELIVERY_ADDRESS] || {},
            opportunityAdditionalAddresses: error[ATTRIBUTE.ADDITIONAL_ADDRESSES] || []
          },
          () => {
            let {
              customerFirstName,
              customerLastName,
              customerId,
              customerType,
              frontEndResponseId,
              orderNumber,
              opportunityBillingAddress,
              opportunityDeliveryAddress,
              opportunityDateApiModels,
              opportunityMultipleChoiceApiModels,
              opportunityAdditionalAddresses,
              contactCustomFields
            } = this.state;

            if (frontendConfiguration.frontendType === FRONTEND_TYPE.CONTACT_FORM) {
              this.createOpportunity();
            } else if (allProductDeactivated) {
              this.createGenericOpportunityForAllUnavailableProduct();
            } else {
              createOpportunities({
                customerId,
                customerFirstName,
                customerLastName,
                customerType,
                companyName: this.getCustomerDataForOpportunity().companyName,
                firstName: this.getCustomerDataForOpportunity().firstName,
                lastName: this.getCustomerDataForOpportunity().lastName,
                reasonForContacting: this.getCustomerDataForOpportunity().reasonForContacting,
                frontEndResponseId,
                orderNumber: this.handleOrderNumber(
                  frontendConfiguration,
                  productSelections,
                  totalPrice,
                  totalPriceV2,
                  selectedProducts,
                  onlinePurchaseOnlyRequest,
                  orderNumber
                ),
                opportunityBillingAddress,
                opportunityDeliveryAddress,
                opportunityDateApiModels,
                opportunityMultipleChoiceApiModels,
                opportunityAdditionalAddresses,
                purchaseType: checkOnlinePurchase(
                  frontendConfiguration,
                  totalPrice,
                  productSelections,
                  selectedProducts,
                  onlinePurchaseOnlyRequest,
                  totalPriceV2
                )
                  ? PURCHASE_TYPE.ONLINE_PURCHASE
                  : PURCHASE_TYPE.REQUEST,
                opportunityApiPricingModelV2:
                  frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2
                    ? createOPPackageV2RequestParams
                    : null,
                productType: null,
                contactCustomFields
              });
            }
          }
        );
        break;
      case SERVER_MESSAGE_CONSTANTS.COMPANY_NAME_TOO_LONG:
        this.contactFormErrorMessage[CONTACT_FORM_KEY.COMPANY_NAME] = MESSAGE_CONSTANTS.TOO_LONG;
        break;
      case SERVER_MESSAGE_CONSTANTS.PHONE_TOO_LONG:
        this.contactFormErrorMessage[CONTACT_FORM_KEY.MOBILE_NUMBER] = MESSAGE_CONSTANTS.TOO_LONG;
        break;
      case SERVER_MESSAGE_CONSTANTS.STREET_TOO_LONG:
      case SERVER_MESSAGE_CONSTANTS.CITY_TOO_LONG:
      case SERVER_MESSAGE_CONSTANTS.POSTCODE_TOO_LONG:
      case SERVER_MESSAGE_CONSTANTS.COUNTRY_TOO_LONG:
        this.contactFormErrorMessage[CONTACT_FORM_KEY.ADDRESS] = MESSAGE_CONSTANTS.TOO_LONG;
        break;
      case SERVER_MESSAGE_CONSTANTS.CUSTOMER_NUMBER_TOO_LONG:
        this.contactFormErrorMessage[CONTACT_FORM_KEY.CUSTOMER_NUMBER] = MESSAGE_CONSTANTS.TOO_LONG;
        break;
      default:
        routeToConnectErrorPage();
        break;
    }
  };

  getCustomerDataForOpportunity = () => {
    const { customerType } = this.state;
    if (customerType === CUSTOMER_TYPE.BUSINESS) {
      return {
        companyName: this.businessCustomerData.companyName,
        firstName: this.businessCustomerData.firstName,
        lastName: this.businessCustomerData.lastName,
        reasonForContacting: this.businessCustomerData.reasonForContacting
      };
    }
    return {
      companyName: this.privateCustomerData.companyName,
      firstName: this.privateCustomerData.firstName,
      lastName: this.privateCustomerData.lastName,
      reasonForContacting: this.privateCustomerData.reasonForContacting
    };
  };

  onChangeCheckboxes = (name, data) => {
    const { selectedCheckboxes } = this.state;
    selectedCheckboxes[name] = data;
    this.setState({
      selectedCheckboxes
    });
  };

  canSubmitContactForm = () => {
    const {
      frontendConfiguration: { frontendStatus, frontendType },
      selectedProducts,
      paymentInfo,
      uploadFileStatus,
      customerJourneyAnswer
    } = this.props;

    if (uploadFileStatus.isUploading) {
      return false;
    }

    if (
      frontendType !== FRONTEND_TYPE.CONTACT_FORM &&
      selectedProducts.length === 0 &&
      !customerJourneyAnswer.isUserInputPostcode
    ) {
      return false;
    }

    if (
      this.paymentOptionsData.selectedPayment === PAYMENT_OPTION.PAYMENTBYPAYPAL &&
      !paymentInfo.payPalConnected
    ) {
      return false;
    }

    return frontendStatus === STATUS.ACTIVE;
  };

  getColToDisplay = field => {
    const fieldsDisplayAsCol6 = [
      CONTACT_FORM_KEY.FIRST_NAME,
      CONTACT_FORM_KEY.LAST_NAME,
      CONTACT_FORM_KEY.POSTAL_CODE,
      CONTACT_FORM_KEY.CITY_TOWN
    ];
    for (const item of fieldsDisplayAsCol6) {
      if (field === item) {
        return 6;
      }
    }
    if (field === CONTACT_FORM_KEY.STREET_NAME) {
      return 8;
    }
    if (field === CONTACT_FORM_KEY.STREET_NUMBER) {
      return 4;
    }

    return 12;
  };

  onChangeSwitch = e => {
    this.setState({
      customerType: e
    });
    this.onCollapse();
  };

  updateInstallationDetails = data => {
    const { updateCustomerJourneyAnswer, customerJourneyAnswer } = this.props;
    const { installationDetails } = this.state;
    if (data[CONTACT_FORM_KEY.STREET_NAME]) {
      updateCustomerJourneyAnswer({
        address: _.assignIn(customerJourneyAnswer.address, {
          street: data[CONTACT_FORM_KEY.STREET_NAME]
        })
      });
    }
    if (data[CONTACT_FORM_KEY.STREET_NUMBER]) {
      updateCustomerJourneyAnswer({
        address: _.assignIn(customerJourneyAnswer.address, {
          number: data[CONTACT_FORM_KEY.STREET_NUMBER]
        })
      });
    }
    this.setState({
      installationDetails: {
        ...installationDetails,
        ...data,
        errorMessage: {
          address: false,
          preferredDate: false
        },
        isValid: null
      }
    });
  };

  updateAdditionalAddresses = (index, data) => {
    const { additionalAddresses } = this.state;
    let cloneAdditionalAddresses = _.assignIn([], additionalAddresses);
    cloneAdditionalAddresses[index] = {
      ...cloneAdditionalAddresses[index],
      ...data,
      errorMessage: {
        address: false
      },
      isValid: null
    };
    this.setState({
      additionalAddresses: cloneAdditionalAddresses
    });
  };

  updateSelectedPayment = data => {
    this.paymentOptionsData = {
      ...this.paymentOptionsData,
      ...data
    };
  };

  updateRelatedDocument = data => {
    const { updateContactForm } = this.props;
    const cloneRelatedDocument = [...data];
    this.relatedDocumentsData = {
      ...this.relatedDocumentsData,
      selectedRelatedDocument: cloneRelatedDocument
    };
    updateContactForm({ relatedDocuments: cloneRelatedDocument });
  };

  contactFormMessage = () => {
    const {
      frontendConfiguration,
      productSelections,
      totalPrice,
      totalPriceV2,
      selectedProducts,
      customerJourneyAnswer,
      allProductDeactivated,
      currQuestions,
      routeManager
    } = this.props;
    const { onlinePurchaseOnlyRequest } = this.state;
    const isOnlinePurchase = checkOnlinePurchase(
      frontendConfiguration,
      totalPrice,
      productSelections,
      selectedProducts,
      onlinePurchaseOnlyRequest,
      totalPriceV2
    );
    if (allProductDeactivated) {
      return currQuestions && currQuestions[1]
        ? currQuestions[1].objectModel.items
          ? currQuestions[1].objectModel.items.content
          : SPECIAL_CHARACTER.EMPTY
        : SPECIAL_CHARACTER.EMPTY;
    }
    if (customerJourneyAnswer.contactFormMessage) {
      return customerJourneyAnswer.contactFormMessage;
    }
    if (routeManager.isPreviewCheckoutPage === BOOLEAN.TRUE) {
      return this.getMessageAndButtonTextIsPreviewCheckoutPage().messageText;
    } else {
      return this.getMessageAndButtonTextIsNotPreviewCheckoutPage(isOnlinePurchase).messageText;
    }
  };

  renderPrivateBusinessSwitch = () => {
    const { frontendConfiguration, customerJourneyAnswer } = this.props;
    const { customerType } = this.state;
    const privateOption = {
      keyName: CUSTOMER_TYPE.PRIVATE,
      valueName: frontendConfiguration.customerTypeLabelValueItems
        ? frontendConfiguration.customerTypeLabelValueItems.privateCustomer
        : CUSTOMER_TYPE.PRIVATE_CUSTOMER
    };
    const businessOption = {
      keyName: CUSTOMER_TYPE.BUSINESS,
      valueName: frontendConfiguration.customerTypeLabelValueItems
        ? frontendConfiguration.customerTypeLabelValueItems.businessCustomer
        : CUSTOMER_TYPE.BUSINESS_CUSTOMER
    };
    const options = [privateOption, businessOption];

    let selectedOption = privateOption;

    if (
      frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY ||
      frontendConfiguration.frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2
    ) {
      if (
        this.getQuestionIndex(QUESTION_ID.HOUSE_CONNECTION_PRIVATELY_OR_BUSINESS) !== undefined &&
        customerJourneyAnswer.houseConnectionPrivatelyOrBusiness ===
          PRIVATELY_OR_BUSINESS.BUSINESS &&
        customerType === CUSTOMER_TYPE.BUSINESS
      ) {
        selectedOption = businessOption;
      }
      if (
        this.getQuestionIndex(QUESTION_ID.CHARGE_PRIVATELY_OR_BUSINESS) !== undefined &&
        customerJourneyAnswer.chargePrivatelyOrBusiness === PRIVATELY_OR_BUSINESS.BUSINESS &&
        customerType === CUSTOMER_TYPE.BUSINESS
      ) {
        selectedOption = businessOption;
      }
    }
    if (customerType === CUSTOMER_TYPE.BUSINESS) {
      selectedOption = businessOption;
    }

    const { fontText, fontTextBold } = this.props;

    return (
      <Switch
        options={options}
        onChange={this.onChangeSwitch}
        selectedOption={selectedOption}
        fontText={fontText}
        fontTextBold={fontTextBold}
        buttonClass="ep-ellipsis"
      />
    );
  };

  renderFieldsAsGroup = (element, textColor, fontText) => {
    const { frontendConfiguration, customerJourneyAnswer } = this.props;
    const { customerType } = this.state;
    let groupContent;
    let items = [];

    _.forEach(element.items, (item, i) => {
      if (item.enable) {
        let countryOptions = [];
        let exemptOptions = [];
        let initialValue = SPECIAL_CHARACTER.EMPTY;
        let disabled = false;
        switch (item.key) {
          /* Init value for POSTAL_CODE and CITY_TOWN
                     Postal code format: "10115 Berlin" */
          case CONTACT_FORM_KEY.POSTAL_CODE:
            initialValue = customerJourneyAnswer.postalCode.split(' ')[0];
            if (this.hasInitialPostalCode) {
              this.contactFormData[CONTACT_FORM_KEY.POSTAL_CODE] = initialValue;
            }
            break;
          case CONTACT_FORM_KEY.CITY_TOWN:
            initialValue = customerJourneyAnswer.postalCode.split(' ')[1];
            if (this.hasInitialPostalCode) {
              this.contactFormData[CONTACT_FORM_KEY.CITY_TOWN] = initialValue;
            }
            break;
          case CONTACT_FORM_KEY.ADDRESS_LABELS:
            this.contactFormData[CONTACT_FORM_KEY.ADDRESS_LABELS] = item.addressLabels;
            break;
          /* END init value */
          case CONTACT_FORM_KEY.STREET_NAME:
            initialValue = customerJourneyAnswer.address.hasOwnProperty(CONTACT_FORM_KEY.STREET)
              ? customerJourneyAnswer.address.street
              : SPECIAL_CHARACTER.EMPTY;
            if (this.hasInitialStreetName) {
              this.contactFormData[CONTACT_FORM_KEY.STREET_NAME] = initialValue;
            }
            break;
          case CONTACT_FORM_KEY.STREET_NUMBER:
            initialValue = customerJourneyAnswer.address.hasOwnProperty(CONTACT_FORM_KEY.NUMBER)
              ? customerJourneyAnswer.address.number
              : SPECIAL_CHARACTER.EMPTY;
            if (this.hasInitialStreetNumber) {
              this.contactFormData[CONTACT_FORM_KEY.STREET_NUMBER] = initialValue;
            }
            break;
          case CONTACT_FORM_KEY.DISTRICT:
            initialValue = customerJourneyAnswer.address.hasOwnProperty(CONTACT_FORM_KEY.DISTRICT)
              ? customerJourneyAnswer.address.district
              : SPECIAL_CHARACTER.EMPTY;
            if (this.hasInitialDistrict) {
              this.contactFormData[CONTACT_FORM_KEY.DISTRICT] = initialValue;
            }
            break;
          case CONTACT_FORM_KEY.BLOCK:
            initialValue = customerJourneyAnswer.address.hasOwnProperty(CONTACT_FORM_KEY.BLOCK)
              ? customerJourneyAnswer.address.block
              : SPECIAL_CHARACTER.EMPTY;
            if (this.hasInitialBlock) {
              this.contactFormData[CONTACT_FORM_KEY.BLOCK] = initialValue;
            }
            break;
          case CONTACT_FORM_KEY.LOT:
            initialValue = customerJourneyAnswer.address.hasOwnProperty(CONTACT_FORM_KEY.LOT)
              ? customerJourneyAnswer.address.lot
              : SPECIAL_CHARACTER.EMPTY;
            if (this.hasInitialLot) {
              this.contactFormData[CONTACT_FORM_KEY.LOT] = initialValue;
            }
            break;
          case CONTACT_FORM_KEY.COUNTRY:
            disabled = item.items[0].value === 'false';
            if (
              (customerType === CUSTOMER_TYPE.PRIVATE &&
                _.isEmpty(this.privateCustomerData[CONTACT_FORM_KEY.COUNTRY])) ||
              (customerType === CUSTOMER_TYPE.BUSINESS &&
                _.isEmpty(this.businessCustomerData[CONTACT_FORM_KEY.COUNTRY]))
            ) {
              this.contactFormData[CONTACT_FORM_KEY.COUNTRY] = getCountryGermanNameFromCode(
                item.items[1].value
              );
              this.updatePrivateBusinessData(
                CONTACT_FORM_KEY.COUNTRY,
                getCountryGermanNameFromCode(item.items[1].value)
              );
            }
            for (const key in COUNTRY) {
              countryOptions.push(COUNTRY[key].germanName);
            }
            countryOptions.sort();
            break;
          case CONTACT_FORM_KEY.TAX_EXEMPTION_STATUS:
            exemptOptions = _.map(item.items, item => item.value);
            initialValue =
              this.contactFormData[CONTACT_FORM_KEY.TAX_EXEMPTION_STATUS] || exemptOptions[0];
            break;
          default:
            break;
        }

        let col = this.getColToDisplay(item.key);
        let childQuestion;

        if (element.key === CONTACT_FORM_KEY.REGISTRATION_DETAILS) {
          childQuestion = (
            <div className="ep--mb-16" key={item.value}>
              <StyledLabel
                className="ep__txt--sm ep__txt-regular ep__txt-no-select"
                font={fontText}
                style={{ color: frontendConfiguration.defaultTextColor }}
              >
                {item.value}
                <ShowIfUtil condition={item.enable && item.required}>
                  <StyledSpan
                    className="ep__txt--sm ep__txt-regular ep__txt-no-select"
                    font={fontText}
                    style={{ color: frontendConfiguration.defaultTextColor }}
                  >
                    {' *'}
                  </StyledSpan>
                </ShowIfUtil>
              </StyledLabel>
              <Question
                required={item.required}
                enable={item.enable}
                placeholder={item.value}
                options={exemptOptions}
                type={item.type}
                primaryTextColor={textColor}
                defaultTextColor={frontendConfiguration.defaultTextColor}
                borderRadius={frontendConfiguration.borderRadius}
                onChange={e => this.onChange(item.key, e)}
                onItemSelect={value => this.onItemSelect(item.key, value)}
                fontFamily={fontText}
                initialValue={
                  customerType === CUSTOMER_TYPE.BUSINESS
                    ? this.businessCustomerData[item.key]
                    : this.privateCustomerData[item.key]
                }
                borderColorFocus={frontendConfiguration.primaryColor}
              />
              <ShowIfUtil condition={!!this.contactFormErrorMessage[item.key]}>
                <StyledSpan
                  className="ep__txt--required ep__txt-regular ep__txt-no-select"
                  font={fontText}
                  style={{ color: COLOR.ERROR_TEXT_COLOR }}
                >
                  {this.contactFormErrorMessage[item.key] + ' '}
                </StyledSpan>
              </ShowIfUtil>
            </div>
          );
        } else {
          childQuestion = (
            <div
              className={`col-${col} ${i === element.items.length - 1 ? '' : 'ep--mb-8'}`}
              key={item.key + '-' + i}
            >
              <Question
                disabled={disabled}
                required={item.required}
                enable={item.enable}
                placeholder={item.required ? `${item.value}*` : item.value}
                options={countryOptions}
                type={item.type}
                primaryTextColor={textColor}
                defaultTextColor={frontendConfiguration.defaultTextColor}
                borderRadius={frontendConfiguration.borderRadius}
                onChange={e => this.onChange(item.key, e)}
                onItemSelect={value => this.onItemSelect(item.key, value)}
                fontFamily={fontText}
                initialValue={
                  customerType === CUSTOMER_TYPE.BUSINESS
                    ? this.businessCustomerData[item.key]
                    : this.privateCustomerData[item.key]
                }
                borderColorFocus={frontendConfiguration.primaryColor}
              />
              <ShowIfUtil condition={!!this.contactFormErrorMessage[item.key]}>
                <StyledSpan
                  className="ep__txt--required ep__txt-regular ep__txt-no-select"
                  font={fontText}
                  style={{ color: COLOR.ERROR_TEXT_COLOR }}
                >
                  {this.contactFormErrorMessage[item.key] + ' '}
                </StyledSpan>
              </ShowIfUtil>
            </div>
          );
        }
        items.push(childQuestion);
      }
    });

    if (element.key === CONTACT_FORM_KEY.REGISTRATION_DETAILS) {
      groupContent = items;
    } else {
      groupContent = (
        <div>
          <StyledLabel
            className="ep__txt--sm ep__txt-regular ep__txt-no-select"
            font={fontText}
            style={{ color: frontendConfiguration.defaultTextColor }}
          >
            {element.value}
            <ShowIfUtil condition={element.required}>
              <StyledSpan
                className="ep__txt--sm ep__txt-regular ep__txt-no-select"
                font={fontText}
                style={{ color: frontendConfiguration.defaultTextColor }}
              >
                {' *'}
              </StyledSpan>
            </ShowIfUtil>
          </StyledLabel>

          <div className="row">{items}</div>
        </div>
      );
    }
    return groupContent;
  };

  onCollapse = () => {
    setTimeout(() => {
      this.setScrollHeight();
    }, 100);
  };

  renderQuestionList = (fontText, textColor, errorStyle) => {
    const { frontendConfiguration } = this.props;
    const { isBillingAddressCompleted, availableFields } = this.state;

    return (
      <div className="row">
        <CustomCollapse
          zIndex={Z_INDEX.Z_INDEX_LEVEL_5}
          title={
            availableFields[0].key === CONTACT_FORM_KEY.SECTION_NAME
              ? availableFields[0].value
              : 'Rechnungsadresse'
          }
          fontText={fontText}
          defaultTextColor={frontendConfiguration.defaultTextColor}
          completedContent={this.renderQuestionListCollapseClosed(fontText, textColor)}
          onCollapse={this.onCollapse}
          isCompleted={isBillingAddressCompleted}
          isError={isBillingAddressCompleted !== undefined && !isBillingAddressCompleted}
          errorMessage={MESSAGE_CONSTANTS.CHECK_ALL_REQUIRED_FIELD}
        >
          <div className="col-12 ep--p-32 ep--border-radius-8">
            <StyledP
              className="ep__txt--sm ep__txt-bold text-center"
              font={fontText}
              style={{ color: frontendConfiguration.defaultTextColor }}
            >
              {availableFields[1].key === CONTACT_FORM_KEY.DESCRIPTION
                ? availableFields[1].value
                : 'Wie lauten deine persönlichen Daten?'}
            </StyledP>
            <div className="row">
              {this.renderQuestionListCollapseOpened(fontText, textColor, errorStyle)}
            </div>
          </div>
        </CustomCollapse>
      </div>
    );
  };

  renderQuestionListCollapseClosed = (fontText, textColor) => {
    let questionList = [];
    const { customerType, availableFields } = this.state;
    _.forEach(availableFields, (element, i) => {
      if (
        customerType === CUSTOMER_TYPE.PRIVATE &&
        (element.key === CONTACT_FORM_KEY.COMPANY_NAME ||
          element.key === CONTACT_FORM_KEY.CONTACT_PERSON)
      ) {
        return;
      }

      if (
        customerType === CUSTOMER_TYPE.BUSINESS &&
        (element.key === CONTACT_FORM_KEY.FIRST_NAME ||
          element.key === CONTACT_FORM_KEY.LAST_NAME ||
          element.key === CONTACT_FORM_KEY.EMAIL ||
          element.key === CONTACT_FORM_KEY.MOBILE_NUMBER)
      ) {
        return;
      }

      if (element.enable || element.key === CONTACT_FORM_KEY.CONTACT_PERSON) {
        let dropDownOptions = element.items;
        if (element.type === INPUT_TYPE_CONTACT_FORM.DROPDOWN && element.items !== null) {
          dropDownOptions = [];
          _.forEach(element.items, item => {
            dropDownOptions.push(item.value);
          });
        }

        let questionContent = (
          <div>
            <StyledLabel
              className="ep__txt--sm font-weight-normal ep__txt-no-select ep--mb-0"
              font={fontText}
              style={{ color: textColor }}
            >
              {element.value}
            </StyledLabel>
            <StyledP
              className="ep__txt--md ep__txt-bold ep__txt-no-select ep--mb-0"
              font={fontText}
              style={{ color: textColor }}
            >
              {this.contactFormData[element.key] !== SPECIAL_CHARACTER.EMPTY
                ? this.contactFormData[element.key]
                : NO_INFORMATION}
            </StyledP>
          </div>
        );

        if (element.key === CONTACT_FORM_KEY.CONTACT_PERSON) {
          questionContent = (
            <div>
              <StyledLabel
                className="ep__txt--sm font-weight-normal ep__txt-no-select ep--mb-0"
                font={fontText}
                style={{ color: textColor }}
              >
                {element.value}
              </StyledLabel>
              <StyledP
                className="ep__txt--md ep__txt-bold ep__txt-no-select ep--mb-0"
                font={fontText}
                style={{ color: textColor }}
              >
                {this.contactFormData[CONTACT_FORM_KEY.FIRST_NAME] +
                  SPECIAL_CHARACTER.SPACE +
                  this.contactFormData[CONTACT_FORM_KEY.LAST_NAME]}
              </StyledP>
              <StyledP
                className="ep__txt--md ep__txt-bold ep__txt-no-select ep--mb-0"
                font={fontText}
                style={{ color: textColor }}
              >
                {this.contactFormData[CONTACT_FORM_KEY.EMAIL] +
                  this.contactFormData[CONTACT_FORM_KEY.MOBILE_NUMBER]}
              </StyledP>
              <StyledP
                className="ep__txt--md ep__txt-bold ep__txt-no-select ep--mb-0"
                font={fontText}
                style={{ color: textColor }}
              >
                {this.contactFormData[CONTACT_FORM_KEY.MOBILE_NUMBER]}
              </StyledP>
            </div>
          );
        }

        if (element.key === CONTACT_FORM_KEY.ADDRESS) {
          questionContent = (
            <div>
              <StyledLabel
                className="ep__txt--sm font-weight-normal ep__txt-no-select ep--mb-0"
                font={fontText}
                style={{ color: textColor }}
              >
                {element.value}
              </StyledLabel>
              <StyledP
                className="ep__txt--md ep__txt-bold ep__txt-no-select ep--mb-0"
                font={fontText}
                style={{ color: textColor }}
              >
                {formatAddress(
                  this.contactFormData[CONTACT_FORM_KEY.STREET_NAME] +
                    SPECIAL_CHARACTER.SPACE +
                    this.contactFormData[CONTACT_FORM_KEY.STREET_NUMBER],
                  this.contactFormData[CONTACT_FORM_KEY.POSTAL_CODE],
                  this.contactFormData[CONTACT_FORM_KEY.CITY_TOWN],
                  this.contactFormData[CONTACT_FORM_KEY.COUNTRY]
                )}
              </StyledP>
            </div>
          );
        }

        if (element.key === CONTACT_FORM_KEY.SALUTATION) {
          questionContent = (
            <div className="row">
              <div className="col-6">
                <StyledLabel
                  className="ep__txt--sm font-weight-normal ep__txt-no-select ep--mb-0"
                  font={fontText}
                  style={{ color: textColor }}
                >
                  {element.value}
                </StyledLabel>
                <StyledP
                  className="ep__txt--md ep__txt-bold ep__txt-no-select ep--mb-0"
                  font={fontText}
                  style={{ color: textColor }}
                >
                  {this.contactFormData[element.key] !== SPECIAL_CHARACTER.EMPTY
                    ? this.contactFormData[element.key]
                    : NO_INFORMATION}
                </StyledP>
              </div>
            </div>
          );
        }

        let colDisplay = this.getColToDisplay(element.key);
        const questionItem = (
          <div className={`ep--mb-16 col-${colDisplay}`} key={element.key + '-' + i}>
            {questionContent}
          </div>
        );
        questionList.push(questionItem);
      }
    });

    return (
      <div className="col-12 ep--p-32 ep--border-radius-8">
        <div className="row">{questionList}</div>
      </div>
    );
  };

  renderQuestionListCollapseOpened = (fontText, textColor, errorStyle) => {
    const { frontendConfiguration, linkColor } = this.props;
    const { customerType, availableFields } = this.state;
    let questionList = [];

    _.forEach(availableFields, (element, i) => {
      if (
        element.key === CONTACT_FORM_KEY.SECTION_NAME ||
        element.key === CONTACT_FORM_KEY.DESCRIPTION
      ) {
        return;
      }

      if (
        customerType === CUSTOMER_TYPE.PRIVATE &&
        (element.key === CONTACT_FORM_KEY.COMPANY_NAME ||
          element.key === CONTACT_FORM_KEY.TAX_ID ||
          element.key === CONTACT_FORM_KEY.EXEMPT_UNTIL ||
          element.key === CONTACT_FORM_KEY.UPLOAD_DOCUMENTS ||
          element.key === CONTACT_FORM_KEY.TAX_EXEMPTION_STATUS ||
          element.key === CONTACT_FORM_KEY.REGISTRATION_DETAILS ||
          element.key === CONTACT_FORM_KEY.CONTACT_PERSON)
      ) {
        return;
      }

      if (
        customerType === CUSTOMER_TYPE.BUSINESS &&
        (element.key === CONTACT_FORM_KEY.FIRST_NAME ||
          element.key === CONTACT_FORM_KEY.LAST_NAME ||
          element.key === CONTACT_FORM_KEY.EMAIL ||
          element.key === CONTACT_FORM_KEY.MOBILE_NUMBER)
      ) {
        return;
      }

      if (element.enable || element.key === CONTACT_FORM_KEY.CONTACT_PERSON) {
        let dropDownOptions = [];
        if (element.type === INPUT_TYPE_CONTACT_FORM.DROPDOWN && element.items !== null) {
          _.forEach(element.items, item => {
            dropDownOptions.push(item.value);
          });
        }

        let questionContent = (
          <Question
            label={element.value}
            required={element.required}
            enable={element.enable}
            placeholder={element.value}
            options={dropDownOptions}
            type={element.type}
            linkColor={linkColor}
            primaryTextColor={textColor}
            defaultTextColor={frontendConfiguration.defaultTextColor}
            borderRadius={frontendConfiguration.borderRadius}
            onChange={e => this.onChange(element.key, e)}
            onItemSelect={value => this.onItemSelect(element.key, value)}
            fontFamily={fontText}
            borderColorFocus={frontendConfiguration.primaryColor}
            initialValue={
              customerType === CUSTOMER_TYPE.BUSINESS
                ? this.businessCustomerData[element.key]
                : this.privateCustomerData[element.key]
            }
          />
        );

        if (
          element.key === CONTACT_FORM_KEY.CONTACT_PERSON ||
          element.key === CONTACT_FORM_KEY.ADDRESS ||
          element.key === CONTACT_FORM_KEY.REGISTRATION_DETAILS
        ) {
          questionContent = this.renderFieldsAsGroup(element, textColor, fontText);
        }

        if (element.key === CONTACT_FORM_KEY.SALUTATION) {
          questionContent = (
            <div className="row">
              <div className="col-6">
                <Question
                  label={element.value}
                  required={element.required}
                  enable={element.enable}
                  placeholder={element.value}
                  options={getListSalutation()}
                  type={element.type}
                  linkColor={linkColor}
                  primaryTextColor={textColor}
                  defaultTextColor={frontendConfiguration.defaultTextColor}
                  borderRadius={frontendConfiguration.borderRadius}
                  onChange={e => this.onChange(element.key, e)}
                  onItemSelect={value => this.onItemSelect(element.key, value)}
                  fontFamily={fontText}
                  borderColorFocus={frontendConfiguration.primaryColor}
                  initialValue={
                    customerType === CUSTOMER_TYPE.BUSINESS
                      ? this.businessCustomerData[element.key]
                      : this.privateCustomerData[element.key]
                  }
                />
              </div>
            </div>
          );
        }

        let colDisplay = this.getColToDisplay(element.key);

        const questionItem = (
          <div
            className={
              element.key === CONTACT_FORM_KEY.REGISTRATION_DETAILS
                ? `col-${colDisplay}`
                : `ep--mb-16 col-${colDisplay}`
            }
            key={element.key + '-' + i}
            style={{ color: frontendConfiguration.defaultTextColor }}
          >
            {questionContent}

            <StyledSpan
              className="ep__txt--required ep__txt-regular ep__txt-no-select"
              font={fontText}
              style={errorStyle}
            >
              {element.key !== CONTACT_FORM_KEY.CONTACT_PERSON &&
                element.key !== CONTACT_FORM_KEY.REGISTRATION_DETAILS &&
                this.contactFormErrorMessage[element.key] &&
                this.contactFormErrorMessage[element.key] + ' '}
            </StyledSpan>
          </div>
        );

        questionList.push(questionItem);
      }
    });
    return questionList;
  };

  previousStep = () => {
    const { previousStep } = this.props;

    previousStep();
  };

  getMessageAndButtonTextIsPreviewCheckoutPage = () => {
    const {
      frontendConfiguration: { onlinePurchaseSettingModel, checkOutOptionsModel },
      routeManager
    } = this.props;
    let messageAndButtonText = {
      messageText: checkOutOptionsModel.orderRequestMessage,
      buttonText: checkOutOptionsModel.orderRequestBtnText
    };
    if (onlinePurchaseSettingModel.active) {
      if (routeManager.previewOnlinePurchase === BOOLEAN.TRUE) {
        messageAndButtonText.messageText = checkOutOptionsModel.onlinePurchaseMessage;
        messageAndButtonText.buttonText = checkOutOptionsModel.onlinePurchaseBtnText;
      } else if (routeManager.previewMixedOrder === BOOLEAN.TRUE) {
        messageAndButtonText.messageText = checkOutOptionsModel.mixedOrderMessage;
        messageAndButtonText.buttonText = checkOutOptionsModel.mixedOrderBtnText;
      }
    }
    return messageAndButtonText;
  };

  getMessageAndButtonTextIsNotPreviewCheckoutPage = isOnlinePurchase => {
    const {
      frontendConfiguration: {
        frontendType,
        onlinePurchaseSettingModel,
        checkOutOptionsModel,
        designOptions
      },
      selectedProducts,
      productSelections,
      emailTemplateV2Data
    } = this.props;
    let messageAndButtonText = {
      messageText:
        checkOutOptionsModel && checkOutOptionsModel.orderRequestMessage
          ? checkOutOptionsModel.orderRequestMessage
          : null,
      buttonText:
        checkOutOptionsModel && checkOutOptionsModel.orderRequestBtnText
          ? checkOutOptionsModel.orderRequestBtnText
          : designOptions.buttonText
    };
    const { onlinePurchaseOnlyRequest } = this.state;
    const isAllProductPackageOnRequestV2 =
      frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2 &&
      !isAllOnRequestProductPackagesSummaryV2(emailTemplateV2Data.productPackagesSummary);
    const isAllProductPackageOnRequestV1 =
      frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY &&
      !getSummaryDataModelV1(selectedProducts, productSelections)
        .isAllOnRequestPackageSummaryModelsV1;

    const isAllOnlinePurchaseProductPackageOnRequestV2 =
      frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2 &&
      isAllOnlinePurchaseProductPackagesSummaryV2(emailTemplateV2Data.productPackagesSummary);
    const isAllOnlinePurchaseProductPackageOnRequestV1 =
      frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY &&
      getSummaryDataModelV1(selectedProducts, productSelections)
        .isAllOnlinePurchasePackageSummaryModelsV1;
    if (onlinePurchaseSettingModel.active) {
      if (
        isOnlinePurchase &&
        !onlinePurchaseOnlyRequest &&
        (isAllProductPackageOnRequestV2 || isAllProductPackageOnRequestV1)
      ) {
        if (
          isAllOnlinePurchaseProductPackageOnRequestV2 ||
          isAllOnlinePurchaseProductPackageOnRequestV1
        ) {
          messageAndButtonText.messageText = checkOutOptionsModel.onlinePurchaseMessage;
          messageAndButtonText.buttonText = checkOutOptionsModel.onlinePurchaseBtnText;
        } else {
          messageAndButtonText.messageText = checkOutOptionsModel.mixedOrderMessage;
          messageAndButtonText.buttonText = checkOutOptionsModel.mixedOrderBtnText;
        }
      }
    }
    return messageAndButtonText;
  };

  renderSubmitButton = isOnlinePurchase => {
    const {
      frontendConfiguration: {
        frontendType,
        buttonBackground,
        buttonBorderRadius,
        buttonTextColor
      },
      routeManager,
      fontTextBold,
      contactFormData,
      uploadFileStatus
    } = this.props;

    const buttonStyle = {
      color: buttonTextColor,
      backgroundColor: buttonBackground,
      borderRadius: buttonBorderRadius,
      width:
        frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY ||
        frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2
          ? '300px'
          : '100%',
      opacity: this.canSubmitContactForm() ? 1 : 0.5,
      cursor: this.canSubmitContactForm() ? 'pointer' : 'not-allowed',
      border: 0
    };

    const backButtonStyle = {
      opacity: !contactFormData.isSubmittingContactForm ? 1 : 0.5,
      cursor: !contactFormData.isSubmittingContactForm ? 'pointer' : 'not-allowed'
    };

    let buttonText;
    if (routeManager.isPreviewCheckoutPage === BOOLEAN.TRUE) {
      buttonText = this.getMessageAndButtonTextIsPreviewCheckoutPage().buttonText;
    } else {
      buttonText = this.getMessageAndButtonTextIsNotPreviewCheckoutPage(isOnlinePurchase)
        .buttonText;
    }

    return (
      <div className="d-flex justify-content-center align-items-center">
        {frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY ||
        frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2 ? (
          <i
            className="ep-icon-tail-left ep--mr-16 ep__txt-bold ep-navigation-icon--hover"
            style={backButtonStyle}
            onClick={
              this.isPreviewCheckoutPage
                ? () => ({})
                : contactFormData.isSubmittingContactForm || uploadFileStatus.isUploading
                ? () => ({})
                : this.previousStep
            }
          />
        ) : (
          SPECIAL_CHARACTER.EMPTY
        )}
        <StyledButtonBold
          font={fontTextBold}
          id="contact-form-request-button"
          className="ep-button ep-fs-13 ep__txt-bold ep__txt-no-select"
          style={buttonStyle}
          disabled={!this.canSubmitContactForm()}
          onClick={this.submitContactForm}
        >
          {buttonText}
        </StyledButtonBold>
      </div>
    );
  };

  render() {
    const {
      frontendConfiguration,
      frontendConfiguration: { frontendType, onlinePurchaseSettingModel },
      productSelections,
      totalPrice,
      backToSelectProduct,
      fontText,
      primaryTextColor,
      height,
      selectedProducts,
      customerJourneyAnswer,
      updateThankYouPageData,
      linkColor,
      currQuestions
    } = this.props;
    const {
      contactFormHeight,
      installationDetails,
      selectedCheckboxes,
      additionalAddresses,
      onlinePurchaseOnlyRequest,
      isValidRelatedDocumentData
    } = this.state;

    const errorStyle = {
      color: COLOR.ERROR_TEXT_COLOR
    };
    let style = {
      background: COLOR.TRANSPARENT
    };
    let textColor = SPECIAL_CHARACTER.EMPTY;
    let marginLeftContactForm = '0px';
    let marginRightContactForm = '0px';
    switch (frontendConfiguration.frontendType) {
      case FRONTEND_TYPE.CONTACT_FORM:
        style = {
          backgroundColor: hexaToRGBA(
            frontendConfiguration.backgroundColor,
            frontendConfiguration.backgroundOpacity / 100
          )
        };
        textColor = frontendConfiguration.textColor;
        break;
      case FRONTEND_TYPE.WEBSHOP:
        marginLeftContactForm = '-15px';
        marginRightContactForm = '-15px';
        textColor = frontendConfiguration.secondaryTextColor;
        break;
      case FRONTEND_TYPE.CUSTOMER_JOURNEY:
        textColor = frontendConfiguration.textColor;
        break;
      default:
        break;
    }

    const questionList = this.renderQuestionList(fontText, textColor, errorStyle);

    const isOnlinePurchase = frontendConfiguration.onlinePurchaseSettingModel.active;

    return (
      <div className="ep-webshop-contactform-wrapper" style={style}>
        <ScrollUtil
          height={`${contactFormHeight + 10}px`}
          submitButton={
            frontendType !== FRONTEND_TYPE.CONTACT_FORM
              ? this.renderSubmitButton(isOnlinePurchase)
              : undefined
          }
          marginLeft={marginLeftContactForm}
          marginRight={marginRightContactForm}
          showShadowBottom={height !== SPECIAL_CHARACTER.EMPTY}
        >
          <div
            ref={scroll => {
              this.scrollBlock = scroll;
            }}
          >
            <ShowIfUtil condition={frontendConfiguration.frontendType === FRONTEND_TYPE.WEBSHOP}>
              <div className="ep-webshop-contactform-quote text-center ep--mb-16 container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="ep-webshop-contactform-backbutton">
                      <i
                        className="ep-icon-minimal-left ep__txt--lg float-left ep-cursor-pointer ep--mt-24"
                        onClick={backToSelectProduct}
                      />
                    </div>

                    <ShowIfUtil
                      condition={productSelections.selectedPurchaseRental === PAYMENT_TYPE.PURCHASE}
                    >
                      <div style={{ height: '30px' }}>
                        <StyledSpan
                          className="ep__txt--ic ep__txt-no-select"
                          font={fontText}
                          style={{ color: frontendConfiguration.defaultTextColor }}
                        >
                          {formatNumber(totalPrice.totalPriceGross) + ' ' + SPECIAL_CHARACTER.EURO}
                        </StyledSpan>
                      </div>
                      <div>
                        <StyledSpan
                          className="ep__txt--sm ep-opacity-50 ep__txt-no-select"
                          font={fontText}
                          style={{ color: frontendConfiguration.defaultTextColor }}
                        >
                          {GERMAN_TEXT.TOTAL_AMOUNT_INCL_TAX}
                        </StyledSpan>
                      </div>
                    </ShowIfUtil>

                    <ShowIfUtil
                      condition={productSelections.selectedPurchaseRental === PAYMENT_TYPE.RENTAL}
                    >
                      <ShowIfUtil
                        condition={totalPrice.totalPriceGross > 0}
                        style={{ height: '30px' }}
                      >
                        <StyledSpan
                          className="ep__txt--ic ep__txt-no-select"
                          font={fontText}
                          style={{ color: frontendConfiguration.defaultTextColor }}
                        >
                          {formatNumber(totalPrice.totalPriceGross) + ' ' + SPECIAL_CHARACTER.EURO}
                        </StyledSpan>
                        <StyledSpan
                          className="ep__txt--md ep__txt-no-select"
                          font={fontText}
                          style={{ color: frontendConfiguration.defaultTextColor }}
                        >
                          {RENTAL.PER_MONTH}
                        </StyledSpan>
                      </ShowIfUtil>
                      <ShowIfUtil
                        condition={totalPrice.totalPriceGross === 0}
                        style={{ height: '30px' }}
                      >
                        <StyledSpan
                          className="ep__txt--ic ep__txt-no-select"
                          font={fontText}
                          style={{ color: frontendConfiguration.defaultTextColor }}
                        >
                          {RENTAL.ON_REQUEST}
                        </StyledSpan>
                      </ShowIfUtil>
                      <ShowIfUtil condition={totalPrice.totalUpfrontFeeGross > 0}>
                        <StyledSpan
                          className="ep__txt--sm ep-opacity-50 ep__txt-no-select"
                          font={fontText}
                          style={{ color: frontendConfiguration.defaultTextColor }}
                        >
                          {'+ ' +
                            formatNumber(totalPrice.totalUpfrontFeeGross) +
                            SPECIAL_CHARACTER.SPACE +
                            SPECIAL_CHARACTER.EURO +
                            ' Vorabzahlungsbetrag'}
                        </StyledSpan>
                      </ShowIfUtil>
                    </ShowIfUtil>
                  </div>
                </div>
              </div>
            </ShowIfUtil>

            <ShowIfUtil
              condition={
                frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY ||
                frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2
              }
            >
              <div className="text-center container-fluid">
                <div className="row">
                  <ShowIfUtil condition={customerJourneyAnswer.isUserInputPostcode}>
                    <div className="col-12">
                      <StyledSpan
                        className="ep__txt--lg ep__txt-regular ep__txt-no-select"
                        font={fontText}
                        style={{ color: frontendConfiguration.defaultTextColor }}
                      >
                        {
                          currQuestions.find(question => question.id === QUESTION_ID.POSTAL_CODE)
                            ?.objectModel?.items?.content
                        }
                      </StyledSpan>
                    </div>
                  </ShowIfUtil>
                  <ShowIfUtil condition={!customerJourneyAnswer.isUserInputPostcode}>
                    <div className="col-12">
                      <StyledSpan
                        className="ep__txt--lg ep__txt-regular ep__txt-no-select"
                        font={fontText}
                        style={{ color: frontendConfiguration.defaultTextColor }}
                      >
                        {this.contactFormMessage()}
                      </StyledSpan>
                    </div>
                  </ShowIfUtil>
                </div>
              </div>
            </ShowIfUtil>

            <div className="ep-contactpage-wrapper container-fluid ep--pb-0">
              <div>
                <div className="row">
                  <ShowIfUtil
                    condition={
                      frontendConfiguration.contactFormType ===
                        CONTACT_FORM_TYPE.PRIVATE_AND_BUSINESS &&
                      customerJourneyAnswer.houseConnectionPrivatelyOrBusiness === -1
                    }
                  >
                    <div className="col-12 ep--mb-16 ep--px-16 w-100">
                      {this.renderPrivateBusinessSwitch()}
                    </div>
                  </ShowIfUtil>

                  <div className="col-12 ep--mb-32">{questionList}</div>

                  <ShowIfUtil condition={frontendConfiguration.isInstallationDetailsFieldsActive}>
                    <div className="col-12 ep--mb-32">
                      <div className="row">
                        <InstallationDetailField
                          installationDetailsFields={
                            frontendConfiguration.installationDetailsFields
                          }
                          fontText={fontText}
                          primaryTextColor={textColor}
                          borderRadius={frontendConfiguration.borderRadius}
                          borderColorFocus={frontendConfiguration.primaryColor}
                          defaultTextColor={frontendConfiguration.defaultTextColor}
                          primaryColor={frontendConfiguration.primaryColor}
                          installationDetails={installationDetails}
                          updateData={this.updateInstallationDetails}
                          setContactFormHeight={this.setScrollHeight}
                        />
                      </div>
                    </div>
                  </ShowIfUtil>

                  <ShowIfUtil
                    condition={
                      !!(
                        frontendConfiguration.additionalAddressesConfiguration &&
                        frontendConfiguration.additionalAddressesConfiguration.active
                      )
                    }
                  >
                    <div className="col-12 ep--mb-32">
                      <div className="row">
                        <AdditionalAddressesContainer
                          additionalAddressFields={
                            frontendConfiguration.additionalAddressesConfiguration
                          }
                          fontFamily={fontText}
                          primaryTextColor={textColor}
                          borderRadius={frontendConfiguration.borderRadius}
                          borderColorFocus={frontendConfiguration.primaryColor}
                          primaryColor={frontendConfiguration.primaryColor}
                          defaultTextColor={frontendConfiguration.defaultTextColor}
                          additionalAddresses={additionalAddresses}
                          updateData={this.updateAdditionalAddresses}
                          setContactFormHeight={this.setScrollHeight}
                        />
                      </div>
                    </div>
                  </ShowIfUtil>

                  <ShowIfUtil
                    condition={
                      frontendConfiguration.contactFormFileUploadOption &&
                      frontendConfiguration.contactFormFileUploadOption.active
                    }
                  >
                    <div className="col-12 ep--mb-32">
                      <div className="row">
                        {frontendConfiguration.contactFormFileUploadOption && (
                          <UploadRelatedDocument
                            fontText={fontText}
                            linkColor={linkColor}
                            setContactFormHeight={this.setScrollHeight}
                            isValidRelatedDocumentData={isValidRelatedDocumentData}
                          />
                        )}
                      </div>
                    </div>
                  </ShowIfUtil>
                  <ShowIfUtil
                    condition={
                      onlinePurchaseSettingModel &&
                      onlinePurchaseSettingModel.active &&
                      onlinePurchaseSettingModel.onlinePurchaseOnlyRequestConfiguration &&
                      onlinePurchaseSettingModel.onlinePurchaseOnlyRequestConfiguration.active &&
                      ((_.isArray(selectedProducts) &&
                        selectedProducts.length > 0 &&
                        !checkSelectedProductOnRequest({
                          selectedProducts,
                          productSelections
                        })) ||
                        (frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2 && isOnlinePurchase))
                    }
                  >
                    <div className="col-12 ep--mb-8">
                      <CheckBoxReadAccept
                        onChange={e => {
                          this.setState({ onlinePurchaseOnlyRequest: e.target.checked }, () => {
                            setTimeout(() => {
                              this.setScrollHeight();
                            }, 100);
                          });
                          updateThankYouPageData({ onlinePurchaseOnlyRequest: e.target.checked });
                        }}
                        isChecked={onlinePurchaseOnlyRequest}
                        defaultTextColor={frontendConfiguration.defaultTextColor}
                        primaryTextColor={primaryTextColor}
                        primaryColor={frontendConfiguration.primaryColor}
                        fontText={fontText}
                        checkboxConfig={
                          onlinePurchaseSettingModel.onlinePurchaseOnlyRequestConfiguration
                        }
                      />
                    </div>
                  </ShowIfUtil>

                  <ShowIfUtil
                    condition={
                      !onlinePurchaseOnlyRequest &&
                      onlinePurchaseSettingModel &&
                      onlinePurchaseSettingModel.active &&
                      ((_.isArray(selectedProducts) &&
                        selectedProducts.length > 0 &&
                        !checkSelectedProductOnRequest({
                          selectedProducts,
                          productSelections
                        })) ||
                        (frontendType === FRONTEND_TYPE.CUSTOMER_JOURNEY_V2 && isOnlinePurchase))
                    }
                  >
                    <div className="col-12 ep--mb-32">
                      <div className="row">
                        <PaymentOptions
                          primaryColor={frontendConfiguration.primaryColor}
                          defaultTextColor={frontendConfiguration.defaultTextColor}
                          fontFamily={fontText}
                          primaryTextColor={textColor}
                          borderRadius={frontendConfiguration.borderRadius}
                          borderColorFocus={frontendConfiguration.primaryColor}
                          onlinePurchaseSettingModel={
                            frontendConfiguration.onlinePurchaseSettingModel
                          }
                          paymentOptionsData={this.paymentOptionsData}
                          updateData={this.updateSelectedPayment}
                          setContactFormHeight={this.setScrollHeight}
                        />
                      </div>
                    </div>
                  </ShowIfUtil>

                  <div className="ep--mb-16 col-12">
                    <GroupCheckboxes
                      fontText={fontText}
                      primaryTextColor={textColor}
                      defaultTextColor={frontendConfiguration.defaultTextColor}
                      primaryColor={
                        frontendConfiguration.frontendType === FRONTEND_TYPE.CONTACT_FORM
                          ? frontendConfiguration.buttonBackground
                          : frontendConfiguration.primaryColor
                      }
                      checkboxConfig={frontendConfiguration.checkboxes}
                      selectedCheckboxes={selectedCheckboxes}
                      checkboxesErrorMessage={this.checkboxesErrorMessage}
                      onChange={this.onChangeCheckboxes}
                    />
                  </div>

                  <ShowIfUtil
                    condition={
                      frontendConfiguration.listAttachment !== undefined &&
                      frontendConfiguration.listAttachment &&
                      frontendConfiguration.listAttachment.length > 0
                    }
                  >
                    <div className="col-12 ep--px-32">
                      <div className="ep-line-divider-h2 ep--mb-16" />

                      <Attachments
                        frontendConfiguration={frontendConfiguration}
                        listAttachment={frontendConfiguration.listAttachment}
                        isOnlinePurchase={isOnlinePurchase}
                        font={fontText}
                        textColor={frontendConfiguration.primaryColor}
                        setContactFormHeight={this.setScrollHeight}
                      />
                    </div>
                  </ShowIfUtil>

                  <ShowIfUtil
                    condition={frontendConfiguration.frontendType === FRONTEND_TYPE.CONTACT_FORM}
                  >
                    <div className="ep--mt-8 ep--mb-16 col-12">
                      {this.renderSubmitButton(isOnlinePurchase)}
                    </div>
                  </ShowIfUtil>
                </div>
              </div>
            </div>
          </div>
        </ScrollUtil>

        <div
          ref={sticky => {
            this.stickyBlock = sticky;
          }}
        >
          <ShowIfUtil condition={frontendConfiguration.frontendType !== FRONTEND_TYPE.CONTACT_FORM}>
            <div className="row" style={style}>
              <div className="ep--mt-16 ep--mb-16 col-12">
                {this.renderSubmitButton(isOnlinePurchase)}
              </div>
            </div>
          </ShowIfUtil>
        </div>
      </div>
    );
  }
}

ContactFormContainer.propTypes = {
  updateContactForm: PropTypes.func,
  checkValidTaxID: PropTypes.func,
  backToSelectProduct: PropTypes.func,
  updateThankYouPageData: PropTypes.func,
  createOpportunities: PropTypes.func,
  routeToConnectErrorPage: PropTypes.func,
  routeToExceedRateLimitPage: PropTypes.func,
  previousStep: PropTypes.func,
  createCustomer: PropTypes.func,
  allProductDeactivated: PropTypes.bool,
  fontText: PropTypes.object,
  frontendConfiguration: PropTypes.object,
  addressProperty: PropTypes.object,
  totalPrice: PropTypes.object,
  totalPriceV2: PropTypes.object,
  totalPriceV2MixedOrder: PropTypes.object,
  productSelections: PropTypes.object,
  customerJourneyAnswer: PropTypes.object,
  customerJourneyMapping: PropTypes.object,
  fontTextBold: PropTypes.object,
  height: PropTypes.string,
  routeManager: PropTypes.object,
  products: PropTypes.array,
  currQuestions: PropTypes.array.isRequired,
  selectedProducts: PropTypes.array,
  primaryTextColor: PropTypes.string,
  linkColor: PropTypes.string,
  contactFormData: PropTypes.object,
  createOPPackageV2RequestParams: PropTypes.object,
  emailTemplateV2Data: PropTypes.object.isRequired,
  uploadFileStatus: PropTypes.object.isRequired,
  currentState: PropTypes.object,
  updateCustomerJourneyAnswer: PropTypes.func,
  selectedAddOnV2MapKeyByProductType: PropTypes.object.isRequired,
  paymentInfo: PropTypes.object
};

ContactFormContainer.defaultProps = {
  updateContactForm: () => ({}),
  updateThankYouPageData: () => ({}),
  checkValidTaxID: () => ({}),
  backToSelectProduct: () => ({}),
  createOpportunities: () => ({}),
  routeToConnectErrorPage: () => ({}),
  routeToExceedRateLimitPage: () => ({}),
  previousStep: () => ({}),
  createCustomer: () => ({}),
  allProductDeactivated: true,
  fontText: {},
  frontendConfiguration: {},
  totalPrice: {},
  totalPriceV2: {},
  totalPriceV2MixedOrder: {},
  productSelections: {},
  customerJourneyAnswer: {},
  customerJourneyMapping: {},
  addressProperty: {},
  fontTextBold: {},
  height: SPECIAL_CHARACTER.EMPTY,
  routeManager: {},
  products: [],
  selectedProducts: [],
  primaryTextColor: COLOR.DARK_GRAY,
  linkColor: COLOR.DEFAULT_BLUE,
  contactFormData: {},
  createOPPackageV2RequestParams: [],
  currentState: {},
  updateCustomerJourneyAnswer: () => ({}),
  paymentInfo: {}
};

function mapStateToProps(state) {
  const { externalProductAvailability } = state;
  let addressProperty = {};
  if (
    externalProductAvailability.externalServiceRequestStatus ===
    SERVICE_REQUEST_STATUS.REQUEST_SUCCESS
  ) {
    if (
      externalProductAvailability.additionalDetails &&
      externalProductAvailability.additionalDetails.geodata &&
      externalProductAvailability.additionalDetails.geodata.register
    ) {
      const { register } = externalProductAvailability.additionalDetails.geodata;
      addressProperty = {
        district: register.district || null,
        plotdetail: {
          blockNo: register.lot || null,
          lotNo: register.plot || null
        }
      };
    }
  }
  const { currQuestions } = cookingQuestionsSelector(state);
  return {
    frontendConfiguration: state.frontendConfiguration,
    currQuestions,
    productSelections: productSelectionsSelector(state),
    selectedProducts: selectedProductsSelector(state),
    totalPrice: totalPriceCalcSelector(state),
    createOPPackageV2RequestParams: createOPPackageV2RequestParamsSelector(state),
    totalPriceV2: totalPriceCalcV2Selector(state),
    totalPriceV2MixedOrder: totalPriceCalcV2ExcludeOnRequestSelector(state),
    emailTemplateV2Data: emailTemplateV2DataSelector(state),
    customerJourneyAnswer: state.customerJourneyAnswer,
    customerJourneyMapping: state.customerJourneyMapping,
    contactFormData: state.contactFormData,
    routeManager: state.route,
    products: productsSelector(state),
    addressProperty,
    allProductDeactivated: isAllProductsDeactivatedSelector(state),
    currentState: currentStateSelector(state),
    selectedAddOnV2MapKeyByProductType: state.addOnV2Selections.selectedAddOnV2MapKeyByProductType,
    paymentInfo: state.paymentInfo,
    uploadFileStatus: state.uploadFileStatus
  };
}

export default connect(
  mapStateToProps,
  {
    createCustomer,
    checkValidTaxID,
    updateThankYouPageData,
    updateContactForm,
    uploadAttachment,
    updateCustomerJourneyAnswer
  }
)(ContactFormContainer);

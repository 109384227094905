export const INPUT_TYPE_CONTACT_FORM = {
  TEXT: 'TEXT',
  DROPDOWN: 'DROPDOWN',
  TEXTAREA: 'TEXTAREA',
  AUTO_COMPLETE: 'AUTO_COMPLETE',
  MULTI_QUESTION: 'MULTI_QUESTION',
  DOCUMENT_UPLOAD: 'DOCUMENT_UPLOAD',
  DATE_TIME_SELECT: 'DATE_TIME_SELECT',
  SELECTION: 'SELECTION'
};

export const Z_INDEX = {
  Z_INDEX_BASIC_LEVEL: 9998,
  Z_INDEX_LEVEL_3: 10003,
  Z_INDEX_LEVEL_4: 10004,
  Z_INDEX_LEVEL_5: 10005
};

export const EXAMPTION_DATA = {
  NOT_EXEMPT: 'Not exempt',
  EXEMPT: 'Exempt',
  UPLOAD_INSTRUCTION: 'Per Drag & Drop Datei hochladen oder vom Computer auswählen.'
};

export const CONTACT_FORM_KEY = {
  SECTION_NAME: 'sectionName',
  DESCRIPTION: 'description',
  COMPANY_NAME: 'companyName',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  MOBILE_NUMBER: 'mobileNumber',
  CONTACT_PERSON: 'contactPerson',
  REASON_FOR_CONTACTING: 'reasonForContacting',
  MESSAGE: 'message',
  CUSTOMER_NUMBER: 'customerNumber',
  ADDRESS: 'address',
  ADDRESS_LABELS: 'labels',
  STREET: 'street',
  STREET_ADDRESS: 'streetAddress',
  STREET_NAME: 'streetName',
  NUMBER: 'number',
  STREET_NUMBER: 'streetNumber',
  DISTRICT: 'district',
  BLOCK: 'block',
  LOT: 'lot',
  POSTAL_CODE: 'postalCode',
  CITY_TOWN: 'cityTown',
  COUNTRY: 'country',
  CUSTOM_FIELD: 'customField',
  EXEMPT_UNTIL: 'exemptUntil',
  UPLOAD_DOCUMENTS: 'uploadTaxDocuments',
  REGISTRATION_DETAILS: 'registrationDetails',
  TAX_EXEMPTION_STATUS: 'taxExemptionStatus',
  TAX_ID: 'taxId',
  REGISTER_COURT: 'registerCourt',
  REGISTER_NUMBER: 'registerNumber',
  EXEMPT_ID: 'exempt',
  SALUTATION: 'salutation'
};

export const SALUTATION_KEY = {
  CONTACT_FORM_FIELD: 'contactFormFields',
  INSTALLATION_FIELD: 'installationField',
  ADDITIONAL_ADDRESSES_FIELD: 'additionalAddressFields'
};

export const SALUTATION_DATA = {
  DE: {
    MR: 'Herr',
    MRS: 'Frau',
    OTHER: 'Divers'
  },
  EN: {
    MR: 'Mr',
    MRS: 'Ms/Mrs',
    OTHER: 'Other'
  }
};

export const CONTACT_FORM_TYPE = {
  PRIVATE: 0,
  BUSINESS: 1,
  PRIVATE_AND_BUSINESS: 2
};

export const CHECKBOXES = {
  POLICIES: 'policies',
  POLICY_LABEL: 'policyLabel',
  ADVERTISING: 'advertising',
  OTHER_CHECKBOXES: 'otherCheckboxes'
};

export const INSTALLATION_FIELD = {
  SECTION_NAME: 'sectionName',
  SECTION_DESCRIPTION: 'description',
  CHECKBOX_LABEL: 'checkboxLabel',
  ADDRESS_FIELDS_LABEL: 'addressFieldLabel',
  INSTALLATION_ADDRESS: 'installationAddress',
  REQUIRED_ADDRESS_FIELDS: 'requiredAddressFields',
  PREFERRED_INSTALLATION_DATE: 'preferredInstallationDate',
  ACTIVE_PREFERRED_INSTALLATION_DATE: 'activePreferredInstallationDate',
  REQUIRED_PREFERRED_INSTALLATION_DATE: 'requiredPreferredInstallationDate',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  STREET_NAME: 'streetName',
  STREET_NO: 'streetNo',
  POSTAL_CODE: 'postalCode',
  CITY_TOWN: 'cityOrTown',
  COUNTRY: 'country'
};

export const ADDRESS_DEFAULT_LABEL = {
  STREET_NAME: 'Straße',
  STREET_NUMBER: 'Hausnummer',
  NUMBER: 'Nr.',
  DISTRICT: 'Ortsteil/Gemarkung',
  BLOCK: 'Flur',
  LOT: 'Flurstück',
  POSTAL_CODE: 'Postleitzahl',
  CITY_TOWN: 'Stadt',
  COUNTRY: 'Land'
};

export const NO_INFORMATION = 'Keine Information';

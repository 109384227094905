import { SPECIAL_CHARACTER } from '../constants';

export const getAddressFormatted = (
  streetName,
  streetNumber,
  district,
  block,
  lot,
  postalCode,
  cityTown,
  country
) => {
  // Add ADDRESS field
  let streetNameFormatted = streetName ? streetName : SPECIAL_CHARACTER.EMPTY;
  let streetNumberFormatted = streetNumber ? streetNumber : SPECIAL_CHARACTER.EMPTY;
  let blockFormatted = block ? block : SPECIAL_CHARACTER.EMPTY;
  let lotFormatted = lot ? lot : SPECIAL_CHARACTER.EMPTY;
  let address =
    streetNameFormatted || streetNameFormatted
      ? streetNameFormatted + SPECIAL_CHARACTER.SPACE + streetNumberFormatted
      : SPECIAL_CHARACTER.EMPTY;
  let address1 =
    blockFormatted || lotFormatted
      ? blockFormatted + SPECIAL_CHARACTER.SPACE + lotFormatted
      : SPECIAL_CHARACTER.EMPTY;
  const postcodeCity = (
    (postalCode ? postalCode : SPECIAL_CHARACTER.EMPTY) +
    SPECIAL_CHARACTER.SPACE +
    (cityTown ? cityTown : SPECIAL_CHARACTER.EMPTY)
  ).trim();
  const countryFormatted = country ? country : SPECIAL_CHARACTER.EMPTY;
  const districtFormatted = district ? district : SPECIAL_CHARACTER.EMPTY;

  // Add District to ADDRESS
  if (districtFormatted && address) {
    address += SPECIAL_CHARACTER.COMMA + SPECIAL_CHARACTER.LINE_BREAK + districtFormatted;
  } else if (districtFormatted) {
    address += districtFormatted;
  }

  // Add Block + Lot to ADDRESS
  if (address1 && address) {
    address += SPECIAL_CHARACTER.COMMA + SPECIAL_CHARACTER.LINE_BREAK + address1;
  } else if (address1) {
    address += address1;
  }

  // Add Postal code + City to ADDRESS
  if (postcodeCity && address) {
    address += SPECIAL_CHARACTER.COMMA + SPECIAL_CHARACTER.LINE_BREAK + postcodeCity;
  } else if (postcodeCity) {
    address += postcodeCity;
  }

  // Add Country to ADDRESS
  if (countryFormatted && address) {
    address += SPECIAL_CHARACTER.COMMA + SPECIAL_CHARACTER.LINE_BREAK + countryFormatted;
  } else if (countryFormatted) {
    address += countryFormatted;
  }

  return address;
};

import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import * as PropTypes from 'prop-types';
import { Collapse } from '@blueprintjs/core';
import {
  ADDON_AVAILABILITY,
  COLOR,
  PAYMENT_TYPE,
  RENTAL,
  RENTAL_UNIT,
  SPECIAL_CHARACTER
} from '../../common/constants';
import { StyledSpan } from '../../components';
import { formatPrice } from '../../common/utils';
import { AddonItem } from '../../components/common';
import { SingleAddonSelectionGroup } from '.';

const AddonContainer = ({
  primaryColor,
  defaultTextColor,
  customBorderRadius,
  toggleShowAddon,
  isShowAddon,
  fontText,
  textColor,
  selectedAddonsCount,
  normalAddons,
  singleSelectionAddons,
  singleSelectionAddonIds,
  singleSelectionAddonMap,
  productSelections,
  currentSelectedPackage,
  setRefAddonComponent,
  handleSelectAddon,
  isDisableAddonCheckbox,
  addonTotalPrice,
  automaticSelectedAddonIds,
  removeAutomaticSelectedAddon,
  setScrollHeight
}) => {
  const [isShowGroupAddon, setIsShowGroupAddon] = useState(false);
  const [singleSelectionAddonGroupRef, setSingleSelectionAddonGroupRef] = useState(null);
  const [isAutomaticSelectedAddon, setIsAutomaticSelectedAddon] = useState(false);
  const [currentSelectedAddon, setCurrentSelectedAddon] = useState(null);

  useEffect(() => {
    if (isDisableAddonCheckbox) {
      setCurrentSelectedAddon(null);
      setIsAutomaticSelectedAddon(false);
    }
  }, [isDisableAddonCheckbox]);

  useEffect(() => {
    let currentSelectedAddon = null;
    _.forEach(productSelections.selectedAddonIds, elem => {
      if (_.indexOf(singleSelectionAddonIds, elem) !== -1) {
        currentSelectedAddon = singleSelectionAddonMap[elem];
      }
    });
    if (currentSelectedAddon) {
      setCurrentSelectedAddon(currentSelectedAddon);
      const isCurrentAddonSelectedAutomatic =
        _.indexOf(automaticSelectedAddonIds, currentSelectedAddon.id) !== -1;
      setIsAutomaticSelectedAddon(isCurrentAddonSelectedAutomatic);
      if (isCurrentAddonSelectedAutomatic && !isShowGroupAddon) {
        setIsShowGroupAddon(true);
      }
    } else {
      setCurrentSelectedAddon(null);
      setIsAutomaticSelectedAddon(false);
    }
  }, [
    isShowGroupAddon,
    productSelections.selectedAddonIds,
    singleSelectionAddonIds,
    singleSelectionAddonMap,
    automaticSelectedAddonIds
  ]);

  useEffect(() => {
    if (singleSelectionAddonGroupRef) {
      if (isShowGroupAddon) {
        setScrollHeight(singleSelectionAddonGroupRef.clientHeight);
      } else {
        setScrollHeight(-singleSelectionAddonGroupRef.clientHeight);
      }
    }
  }, [isShowGroupAddon, singleSelectionAddonGroupRef, setScrollHeight]);

  const calcAddonPrice = addon => {
    if (isAddonOnRequest(addon)) {
      return RENTAL.ON_REQUEST;
    }
    if (productSelections.selectedPurchaseRental === PAYMENT_TYPE.PURCHASE) {
      return formatPrice(addon.retailPriceGross);
    }
    return formatPrice(monthlyPrice(addon)) + SPECIAL_CHARACTER.SPACE + RENTAL.PER_MONTH;
  };

  const isAddonOnRequest = addon =>
    (productSelections.selectedPurchaseRental === PAYMENT_TYPE.PURCHASE && !addon.retailAble) ||
    (productSelections.selectedPurchaseRental === PAYMENT_TYPE.RENTAL && !addon.rentable);

  const monthlyPrice = addon => {
    let monthlyPriceGross = 0;
    if (addon.addonRentals === null) {
      return addon.retailPriceGross;
    }
    _.forEach(addon.addonRentals, rental => {
      if (
        rental.duration === productSelections.selectedRentalInfo.duration &&
        rental.unit === RENTAL_UNIT[productSelections.selectedRentalInfo.unit]
      ) {
        monthlyPriceGross = rental.grossPrice;
      }
    });
    return monthlyPriceGross;
  };

  const renderNormalAddons = () => {
    if (_.isEmpty(normalAddons)) {
      return null;
    }
    return _.map(normalAddons, addon => {
      const isAddonSpecificToSelectedPackage = currentSelectedPackage.specificAddonIds
        ? _.indexOf(currentSelectedPackage.specificAddonIds, addon.id) !== -1
        : false;
      const isShowAddon =
        addon.availability === ADDON_AVAILABILITY.ALL_PACKAGES ||
        addon.availability === ADDON_AVAILABILITY.PACKAGE_BY_VENDOR ||
        isAddonSpecificToSelectedPackage;
      if (!isShowAddon) {
        return null;
      }
      const isSelected = _.indexOf(productSelections.selectedAddonIds, addon.id) !== -1;
      const isAutomaticSelected = _.indexOf(automaticSelectedAddonIds, addon.id) !== -1;
      return (
        <AddonItem
          key={addon.id}
          addonId={addon.id}
          addonName={addon.name}
          isSelected={isSelected}
          isAutomaticSelected={isAutomaticSelected}
          addonPrice={calcAddonPrice(addon)}
          handleSelectAddon={() => handleSelectAddon(addon.id)}
          isDisableAddonCheckbox={isDisableAddonCheckbox}
          textColor={textColor}
          defaultTextColor={defaultTextColor}
          primaryColor={primaryColor}
          fontText={fontText}
          useRadioButton={false}
          removeAutomaticSelectedAddon={removeAutomaticSelectedAddon}
        />
      );
    });
  };

  const renderGroupAddons = () => {
    if (_.isEmpty(singleSelectionAddons)) {
      return null;
    }
    return (
      <SingleAddonSelectionGroup
        setRefAddonComponent={setSingleSelectionAddonGroupRef}
        currentSelectedAddon={currentSelectedAddon}
        selectedAddonIds={productSelections.selectedAddonIds}
        singleSelectionAddons={singleSelectionAddons}
        singleSelectionAddonIds={singleSelectionAddonIds}
        calcAddonPrice={calcAddonPrice}
        isShowGroupAddon={isShowGroupAddon}
        isAutomaticSelected={isAutomaticSelectedAddon}
        toggleIsShowGroupAddon={setIsShowGroupAddon}
        isDisableAddonCheckbox={isDisableAddonCheckbox}
        currentSelectedPackage={currentSelectedPackage}
        handleSelectAddon={handleSelectAddon}
        textColor={textColor}
        defaultTextColor={defaultTextColor}
        primaryColor={primaryColor}
        fontText={fontText}
        removeAutomaticSelectedAddon={removeAutomaticSelectedAddon}
      />
    );
  };

  const borderRadius = `0px 0px ${customBorderRadius} ${customBorderRadius}`;
  return (
    <div
      className="row ep--mx-16 ep--py-16"
      style={{
        backgroundColor: COLOR.LIGHT_GRAY,
        borderRadius
      }}
    >
      <div className="col-1 d-flex align-items-center ep--pl-16 ep--pr-0">
        <i
          className={`ep-position-top-3 ep-cursor-pointer ep__txt--sm ${
            isShowAddon ? 'ep-icon-minimal-up' : 'ep-icon-minimal-down'
          }`}
          style={{ color: defaultTextColor }}
          onClick={toggleShowAddon}
        />
      </div>
      <div className="col-7 d-flex align-items-center">
        <StyledSpan
          className="ep__txt--md ep__txt-regular"
          font={fontText}
          style={{ color: defaultTextColor }}
        >
          {`Zusatzoptionen ( ${selectedAddonsCount} )`}
        </StyledSpan>
      </div>
      <div className="col-4 d-flex align-items-center justify-content-end">
        <StyledSpan
          font={fontText}
          className="ep__txt--md ep__txt-regular text-right"
          style={{ color: defaultTextColor }}
        >
          {addonTotalPrice}
        </StyledSpan>
      </div>
      <div className={`col-12 ${isShowAddon ? 'ep--mt-16' : ''}`} style={{ minHeight: 0 }}>
        <Collapse isOpen={isShowAddon} keepChildrenMounted transitionDuration={0}>
          <div ref={ref => setRefAddonComponent(ref)}>
            <hr className="ep--mt-0 ep--mb-16" />
            {renderGroupAddons()}
            {renderNormalAddons()}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

AddonContainer.propTypes = {
  currentSelectedPackage: PropTypes.object.isRequired,
  productSelections: PropTypes.object.isRequired,
  normalAddons: PropTypes.array.isRequired,
  automaticSelectedAddonIds: PropTypes.array.isRequired,
  singleSelectionAddons: PropTypes.array.isRequired,
  singleSelectionAddonIds: PropTypes.array.isRequired,
  singleSelectionAddonMap: PropTypes.object.isRequired,
  fontText: PropTypes.object,
  selectedAddonsCount: PropTypes.number,
  isShowAddon: PropTypes.bool,
  isDisableAddonCheckbox: PropTypes.bool,
  defaultTextColor: PropTypes.string,
  primaryColor: PropTypes.string,
  customBorderRadius: PropTypes.string,
  textColor: PropTypes.string,
  addonTotalPrice: PropTypes.string,
  toggleShowAddon: PropTypes.func,
  setRefAddonComponent: PropTypes.func,
  removeAutomaticSelectedAddon: PropTypes.func.isRequired,
  setScrollHeight: PropTypes.func.isRequired,
  handleSelectAddon: PropTypes.func
};

AddonContainer.defaultProps = {
  toggleShowAddon: () => ({}),
  isShowAddon: false,
  fontText: {},
  textColor: COLOR.BLACK,
  defaultTextColor: COLOR.DARK_GRAY,
  primaryColor: COLOR.BLACK,
  selectedAddonsCount: 0,
  addonTotalPrice: '',
  customBorderRadius: '0px',
  setRefAddonComponent: () => ({}),
  handleSelectAddon: () => ({}),
  isDisableAddonCheckbox: false
};

export default React.memo(AddonContainer);

import { createSelector } from 'reselect';
import _ from 'lodash';
import {
  ON_REQUEST,
  PRODUCT_SELECTED_KEY,
  PRODUCT_TYPE_CURR_PACKAGE_MAPPING
} from '../../common/constants';
import { currentStateSelector } from './currentStateSelector';
import { roundUpTwoDecimal } from '../../common/utils';

const getProductPackageConfig = state => state.productPackageConfig;
const getProductSelections = state => state.productSelections;
const getCurrentState = state => currentStateSelector(state);
const getSelectedAddOnV2MapKeyByProductType = state =>
  state.addOnV2Selections.selectedAddOnV2MapKeyByProductType;

export const totalPriceCalcV2Selector = createSelector(
  [
    getProductPackageConfig,
    getProductSelections,
    getCurrentState,
    getSelectedAddOnV2MapKeyByProductType
  ],
  (productPackageConfig, productSelections, currentState, selectedAddOnV2MapKeyByProductType) => {
    let {
      oneTimePrice,
      monthlyPrice,
      yearlyPrice,
      isOneTimePriceOnRequestExist,
      isMonthlyTimePriceOnRequestExist,
      isYearlyTimePriceOnRequestExist,
      isMixedOrderEnable
    } = calculateTotalPrice({
      productPackageConfig,
      productSelections,
      currentState,
      selectedAddOnV2MapKeyByProductType
    });

    return {
      oneTimePrice: isOneTimePriceOnRequestExist && !isMixedOrderEnable ? ON_REQUEST : oneTimePrice,
      monthlyPrice:
        isMonthlyTimePriceOnRequestExist && !isMixedOrderEnable ? ON_REQUEST : monthlyPrice,
      yearlyPrice: isYearlyTimePriceOnRequestExist && !isMixedOrderEnable ? ON_REQUEST : yearlyPrice
    };
  }
);

export const totalPriceCalcV2ExcludeOnRequestSelector = createSelector(
  [
    getProductPackageConfig,
    getProductSelections,
    getCurrentState,
    getSelectedAddOnV2MapKeyByProductType
  ],
  (productPackageConfig, productSelections, currentState, selectedAddOnV2MapKeyByProductType) => {
    const { oneTimePrice, monthlyPrice, yearlyPrice } = calculateTotalPrice({
      productPackageConfig,
      productSelections,
      currentState,
      selectedAddOnV2MapKeyByProductType
    });

    return {
      oneTimePrice,
      monthlyPrice,
      yearlyPrice
    };
  }
);

const calculateTotalPrice = ({
  productPackageConfig,
  productSelections,
  currentState,
  selectedAddOnV2MapKeyByProductType
}) => {
  let oneTimePrice = null;
  let monthlyPrice = null;
  let yearlyPrice = null;
  let isOneTimePriceOnRequestExist = false;
  let isMonthlyTimePriceOnRequestExist = false;
  let isYearlyTimePriceOnRequestExist = false;
  let isMixedOrderEnable = false;

  _.forEach(productPackageConfig, (_value, key) => {
    if (
      currentState[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[key]] &&
      productSelections[PRODUCT_SELECTED_KEY[key]]
    ) {
      const currentOneTimePrice = roundUpTwoDecimal(
        currentState[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[key]].oneTimePrice
      );
      const currentMonthlyPrice = roundUpTwoDecimal(
        currentState[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[key]].monthlyPrice
      );
      const currentYearlyPrice = roundUpTwoDecimal(
        currentState[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[key]].yearlyPrice
      );
      if (currentOneTimePrice !== null) {
        if (
          currentOneTimePrice !== ON_REQUEST &&
          !currentState[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[key]].alwaysShowOnRequest
        ) {
          oneTimePrice = oneTimePrice + currentOneTimePrice;
          isMixedOrderEnable = true;
        } else {
          isOneTimePriceOnRequestExist = true;
        }
      }

      if (currentMonthlyPrice !== null) {
        if (
          currentMonthlyPrice !== ON_REQUEST &&
          !currentState[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[key]].alwaysShowOnRequest
        ) {
          monthlyPrice = monthlyPrice + currentMonthlyPrice;
          isMixedOrderEnable = true;
        } else {
          isMonthlyTimePriceOnRequestExist = true;
        }
      }

      if (currentYearlyPrice !== null) {
        if (
          currentYearlyPrice !== ON_REQUEST &&
          !currentState[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[key]].alwaysShowOnRequest
        ) {
          yearlyPrice = yearlyPrice + currentYearlyPrice;
          isMixedOrderEnable = true;
        } else {
          isYearlyTimePriceOnRequestExist = true;
        }
      }
    }
  });

  const {
    oneTimePrice: oneTimePriceIncludedAddOnPrice,
    monthlyPrice: monthlyPriceIncludedAddOnPrice,
    yearlyPrice: yearlyPriceIncludedAddOnPrice
  } = _.reduce(
    selectedAddOnV2MapKeyByProductType,
    (result, value) => {
      if (!value || value.length === 0) return result;
      const addOnTotalPrice = _.reduce(
        value,
        (result, value) => {
          if (!value || !value.addOnSellingOption || value.addOnSellingOption.showPriceOnFrontend) {
            return result;
          }
          if (value.addOnSellingOption.oneTimePrice != null) {
            result.oneTimePrice =
              result.oneTimePrice + roundUpTwoDecimal(value.addOnSellingOption.oneTimePrice);
          }
          if (value.addOnSellingOption.monthlyPrice != null) {
            result.monthlyPrice =
              result.monthlyPrice + roundUpTwoDecimal(value.addOnSellingOption.monthlyPrice);
          }
          if (value.addOnSellingOption.yearlyPrice != null) {
            result.yearlyPrice =
              result.yearlyPrice + roundUpTwoDecimal(value.addOnSellingOption.yearlyPrice);
          }
          return result;
        },
        {
          oneTimePrice: null,
          monthlyPrice: null,
          yearlyPrice: null
        }
      );
      if (addOnTotalPrice.oneTimePrice !== null) {
        result.oneTimePrice = result.oneTimePrice + addOnTotalPrice.oneTimePrice;
      }
      if (addOnTotalPrice.monthlyPrice !== null) {
        result.monthlyPrice = result.monthlyPrice + addOnTotalPrice.monthlyPrice;
      }
      if (addOnTotalPrice.yearlyPrice !== null) {
        result.yearlyPrice = result.yearlyPrice + addOnTotalPrice.yearlyPrice;
      }
      return result;
    },
    {
      oneTimePrice,
      monthlyPrice,
      yearlyPrice
    }
  );

  return {
    oneTimePrice: oneTimePriceIncludedAddOnPrice,
    monthlyPrice: monthlyPriceIncludedAddOnPrice,
    yearlyPrice: yearlyPriceIncludedAddOnPrice,
    isOneTimePriceOnRequestExist,
    isMonthlyTimePriceOnRequestExist,
    isYearlyTimePriceOnRequestExist,
    isMixedOrderEnable
  };
};

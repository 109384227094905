/* ----- Importing libraries -----*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { getFrontendConfiguration } from '../store/actions';
import { StyledSpan } from '../components';
import { COLOR, SPECIAL_CHARACTER } from '../common/constants';
import { getFontTextBoldSelector, getFontTextSelector } from '../store/selectors';

class ExceedRateLimitPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fontText: {
        font: SPECIAL_CHARACTER.EMPTY,
        fontUrl: SPECIAL_CHARACTER.EMPTY
      },
      fontTextBold: {
        font: SPECIAL_CHARACTER.EMPTY,
        fontUrl: SPECIAL_CHARACTER.EMPTY
      }
    };
  }

  componentDidMount() {
    const { fontText, fontTextBold } = this.props;
    // Custom font
    this.setState(prevState => ({
      fontText: {
        ...prevState.fontText,
        ...fontText
      }
    }));

    this.setState(prevState => ({
      fontTextBold: {
        ...prevState.fontTextBold,
        font: fontTextBold.font
      }
    }));
  }

  render() {
    const { fontText, fontTextBold } = this.state;
    const { frontendConfiguration } = this.props;

    return (
      <div className="text-center ep--p-32" style={{ backgroundColor: COLOR.WHITE }}>
        <div
          className="ep-success-wrapper-icon"
          style={{
            color: frontendConfiguration ? frontendConfiguration.primaryColor : COLOR.DEFAULT_BLUE
          }}
        >
          <i className="ep-icon-spaceship" />
        </div>
        <div className="d-flex justify-content-center align-items-center ep--mt-32">
          <StyledSpan
            font={fontTextBold}
            className="ep__txt--h1"
            style={{
              color: frontendConfiguration ? frontendConfiguration.primaryColor : COLOR.DEFAULT_BLUE
            }}
          >
            {'Maximale Anzahl an Anfragen erreicht'}
          </StyledSpan>
        </div>
        <div className="d-flex justify-content-center align-items-center ep--mt-32">
          <StyledSpan
            font={fontText}
            className="ep__txt--md"
            style={{
              color: frontendConfiguration ? frontendConfiguration.primaryColor : COLOR.DEFAULT_BLUE
            }}
          >
            {'Die maximale Anzahl an Anfragen innerhalb einer kurzen Zeit ist erreicht. Um Missbrauch vorzubeugen, kann eine weitere Anfrage in einigen Minuten vorgenommen werden.'}
          </StyledSpan>
        </div>
      </div>
    );
  }
}

ExceedRateLimitPage.propTypes = {
  fontText: PropTypes.object,
  fontTextBold: PropTypes.object,
  frontendConfiguration: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
};

ExceedRateLimitPage.defaultProps = {
  fontText: {},
  fontTextBold: {},
  frontendConfiguration: {}
};

function mapStateToProps(state) {
  return {
    fontText: getFontTextSelector(state),
    fontTextBold: getFontTextBoldSelector(state)
  };
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators(
    {
      getFrontendConfiguration
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExceedRateLimitPage);

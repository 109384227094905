import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { hexaToRGBA } from '../common/utils';
import { FRONTEND_TYPE, SPECIAL_CHARACTER } from '../common/constants';
import { StyledSpan } from '../components';
import { getFontTextSelector } from '../store/selectors';

class SuccessPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      link: SPECIAL_CHARACTER.EMPTY,
      fontText: {
        font: SPECIAL_CHARACTER.EMPTY,
        fontUrl: SPECIAL_CHARACTER.EMPTY
      }
    };
  }

  componentDidMount() {
    const { frontendConfiguration, fontText } = this.props;

    // Custom font
    this.setState(prevState => ({
      fontText: {
        ...prevState.fontText,
        ...fontText
      }
    }));

    if (frontendConfiguration !== false) {
      let link = '/frontends?token=' + frontendConfiguration.token;

      this.setState({
        link: link
      });
    }
  }

  render() {
    const { fontText, link } = this.state;
    const { frontendConfiguration } = this.props;
    const style = {
      backgroundColor: hexaToRGBA(
        frontendConfiguration.backgroundColor,
        frontendConfiguration.backgroundOpacity / 100
      )
    };
    const textColor = frontendConfiguration.textColor;
    const linkColor =
      frontendConfiguration.frontendType === FRONTEND_TYPE.CONTACT_FORM
        ? frontendConfiguration.buttonBackground
        : frontendConfiguration.primaryColor;

    return (
      <div className="text-center ep--p-32" style={style}>
        <div className="ep-success-wrapper-icon" style={{ color: textColor }}>
          <i className="ep-icon-like-2" />
        </div>
        <div className="d-flex justify-content-center align-items-center ep--mt-32">
          <StyledSpan font={fontText} className="ep__txt--h1" style={{ color: textColor }}>
            {'Danke!'}
          </StyledSpan>
        </div>
        <div className="d-flex justify-content-center align-items-center ep--mt-32">
          <StyledSpan font={fontText} className="ep__txt--md" style={{ color: textColor }}>
            {'Wir haben die Anfrage erhalten und werden uns in Kürze melden.'}
          </StyledSpan>
        </div>
        <div className="d-flex justify-content-center align-items-center ep--mt-32">
          <Link
            to={link}
            className="ep-text-content-normal"
            style={{
              color: linkColor,
              fontFamily: `${fontText.font}`
            }}
          >
            {'Zurück'}
          </Link>
        </div>
      </div>
    );
  }
}

SuccessPage.propTypes = {
  frontendConfiguration: PropTypes.object,
  fontText: PropTypes.object
};

SuccessPage.defaultProps = {
  frontendConfiguration: {},
  fontText: {}
};

function mapStateToProps(state) {
  return {
    frontendConfiguration: state.frontendConfiguration,
    fontText: getFontTextSelector(state)
  };
}

export default connect(mapStateToProps)(SuccessPage);

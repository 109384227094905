import * as _ from 'lodash';
import {
  FILTER_ADD_ON_SELLING_OPTIONS_BY_PAYMENT_TYPE,
  FRONTEND_CONFIGURATION_SUCCESS
} from '../actions';
import {
  ADD_ON_V2_PRODUCT_TYPE,
  ADDON_AVAILABILITY,
  QUESTION_TYPE,
  QUESTION_EVENT
} from '../../common/constants';

const initState = {};

export default (state = initState, action) => {
  switch (action.type) {
    case FRONTEND_CONFIGURATION_SUCCESS:
      return {
        ...state,
        ...parseAddOnConfiguration(action.payload.questionConfigurations, action.payload.addOnsV2)
      };
    case FILTER_ADD_ON_SELLING_OPTIONS_BY_PAYMENT_TYPE:
      return {
        ...state,
        ...parseAddOnConfiguration(
          action.payload.questionConfigurations,
          action.payload.addOnsV2,
          action.payload.paymentType
        )
      };
    default:
      return state;
  }
};

const parseAddOnConfiguration = (questions, addOns = [], paymentType = '') => {
  let globalAddOns = [];

  let productAddOnsMapKeyByProductType = {};

  let specificPackageAddOnsMapKeyByPackageId = {};

  let otherPackageAddOnsMapKeyByExcludedPackageId = {};

  const addOnsMapKeyByAddOnId = _.keyBy(addOns, 'id');

  const multipleChoiceQuestions = _.filter(
    questions,
    question => question.type === QUESTION_TYPE.MULTIPLE_CHOICE
  );
  const selectAnAddOnConfig = _.reduce(
    multipleChoiceQuestions,
    (result, value) => {
      const addOnConfig = _.filter(
        value.objectModel,
        addOnConfig => addOnConfig.event === QUESTION_EVENT.SELECT_AN_ADD_ON
      );
      if (addOnConfig?.length > 0) {
        result.push(..._.flatten(addOnConfig.map(addOnConfig => addOnConfig.addOnMappingModels)));
      }
      return result;
    },
    []
  );

  if (selectAnAddOnConfig?.length > 0) {
    // handle specific addon config for Journey 2.0 when there are some select an addon event questions.
    // https://e-pilot.atlassian.net/browse/PRO-1415
    _.forEach(selectAnAddOnConfig, addOnConfig => {
      if (
        addOnConfig.allPackagesMappingAddOnId !== 0 ||
        (addOnConfig.otherPackagesMappingAddOnId !== 0 &&
          addOnConfig.addOnPackageMapping?.length === 0)
      ) {
        // addon mapped by all packages of its product type
        // NOTE: if we enable specific package addon mapping config without specific package,
        // other package addon mapping config will be the same at all package mapping config
        const clonedAddOn = _.cloneDeep(
          addOnsMapKeyByAddOnId[addOnConfig.allPackagesMappingAddOnId] ??
            addOnsMapKeyByAddOnId[addOnConfig.otherPackagesMappingAddOnId]
        );
        if (!clonedAddOn) return;
        // filter selling options by payment type
        if (paymentType) {
          clonedAddOn.sellingOptions = clonedAddOn.sellingOptions.filter(
            sellingOption => sellingOption.paymentType === paymentType
          );
        }
        productAddOnsMapKeyByProductType = {
          ...productAddOnsMapKeyByProductType,
          [addOnConfig.productType]: _.uniqBy(
            [...(productAddOnsMapKeyByProductType[addOnConfig.productType] ?? []), clonedAddOn],
            'id'
          )
        };
      } else {
        // specific package addon mapping
        _.forEach(addOnConfig.addOnPackageMapping, mapping => {
          const clonedAddOn = _.cloneDeep(addOnsMapKeyByAddOnId[mapping.addOnId]);
          if (!clonedAddOn) return;
          // filter selling options by payment type
          if (paymentType) {
            clonedAddOn.sellingOptions = clonedAddOn.sellingOptions.filter(
              sellingOption => sellingOption.paymentType === paymentType
            );
          }
          clonedAddOn.productType = addOnConfig.productType;
          if (
            specificPackageAddOnsMapKeyByPackageId[mapping.packageId] &&
            _.findIndex(
              specificPackageAddOnsMapKeyByPackageId[mapping.packageId],
              elem => elem.id === clonedAddOn.id && elem.productType === clonedAddOn.productType
            ) === -1
          ) {
            specificPackageAddOnsMapKeyByPackageId[mapping.packageId].push(clonedAddOn);
          } else {
            specificPackageAddOnsMapKeyByPackageId = {
              ...specificPackageAddOnsMapKeyByPackageId,
              [mapping.packageId]: [clonedAddOn]
            };
          }
          // other package addon mapping
          const otherPackageClonedAddOn = _.cloneDeep(
            addOnsMapKeyByAddOnId[addOnConfig.otherPackagesMappingAddOnId]
          );
          if (!otherPackageClonedAddOn) return;
          // filter selling options by payment type
          if (paymentType) {
            otherPackageClonedAddOn.sellingOptions = otherPackageClonedAddOn.sellingOptions.filter(
              sellingOption => sellingOption.paymentType === paymentType
            );
          }
          otherPackageClonedAddOn.productType = addOnConfig.productType;
          otherPackageAddOnsMapKeyByExcludedPackageId = {
            ...otherPackageAddOnsMapKeyByExcludedPackageId,
            [mapping.packageId]: _.uniqWith(
              [
                ...(otherPackageAddOnsMapKeyByExcludedPackageId[mapping.packageId] ?? []),
                otherPackageClonedAddOn
              ],
              _.isEqual
            )
          };
        });
      }
    });
  } else {
    // normal addon config for Journey 2.0
    _.forEach(addOns, addOn => {
      const clonedAddOn = _.cloneDeep(addOn);
      if (paymentType) {
        clonedAddOn.sellingOptions = clonedAddOn.sellingOptions.filter(
          sellingOption => sellingOption.paymentType === paymentType
        );
      }
      if (clonedAddOn.productType == null) {
        clonedAddOn.productType = ADD_ON_V2_PRODUCT_TYPE.GLOBAL;
        globalAddOns.push(clonedAddOn);
      } else if (clonedAddOn.packageAvailability !== ADDON_AVAILABILITY.SPECIFIC_PACKAGE) {
        if (productAddOnsMapKeyByProductType[clonedAddOn.productType]) {
          productAddOnsMapKeyByProductType[clonedAddOn.productType].push(clonedAddOn);
        } else {
          productAddOnsMapKeyByProductType = {
            ...productAddOnsMapKeyByProductType,
            [clonedAddOn.productType]: [clonedAddOn]
          };
        }
      } else {
        clonedAddOn.specificPackages.forEach(packageId => {
          if (
            specificPackageAddOnsMapKeyByPackageId[packageId] &&
            _.findIndex(
              specificPackageAddOnsMapKeyByPackageId[packageId],
              elem => elem.id === clonedAddOn.id
            ) === -1
          ) {
            specificPackageAddOnsMapKeyByPackageId[packageId].push(clonedAddOn);
          } else {
            specificPackageAddOnsMapKeyByPackageId = {
              ...specificPackageAddOnsMapKeyByPackageId,
              [packageId]: [clonedAddOn]
            };
          }
        });
      }
    });
  }

  return {
    globalAddOns,
    productAddOnsMapKeyByProductType,
    specificPackageAddOnsMapKeyByPackageId,
    otherPackageAddOnsMapKeyByExcludedPackageId
  };
};

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { Collapse, Position, Tooltip } from '@blueprintjs/core';
import _ from 'lodash';
import $ from 'jquery';
import styled from 'styled-components';
import { updateProductSelections, updateSelectedAddon } from '../../store/actions';
import {
  ATTRIBUTE,
  COLOR,
  PACKAGE_PREMIUM_TYPE_KEY,
  PACKAGE_SELECTED_KEY,
  PACKAGE_TYPE,
  PAYMENT_TYPE,
  PRODUCT_AVAILABLE_STATUS,
  PRODUCT_SELECTED_KEY,
  RENTAL,
  RENTAL_UNIT
} from '../../common/constants';
import { productSelectionsSelector } from '../../store/selectors';
import { PackageRadioButton, StyledSpan, StyledSpanBold } from '../../components/epilot-forms';
import { formatPrice, formatSpecification, removeAddon, ShowIfUtil } from '../../common/utils';

const DescriptionText = styled.div`
  margin-top: 5px;
  margin-left: 42px;
  margin-right: 20px;
  text-align: justify;
`;

class PackageContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  componentDidMount = () => {
    $('.pt-collapse').addClass('pt-description-wrap');
    this.setBackgroundColor();
  };

  componentDidUpdate = () => {
    this.setBackgroundColor();
  };

  setBackgroundColor = () => {
    const { frontendConfiguration, isSelectedPackage, isPremiumType, productPackage } = this.props;
    const radio = document.getElementsByClassName(
      'package-' + isPremiumType + '-' + productPackage[ATTRIBUTE.ID]
    );

    for (let node of radio[0].childNodes) {
      if (node.className === 'pt-control-indicator') {
        if (isSelectedPackage) {
          node.style.backgroundColor =
            frontendConfiguration.primaryColor !== '#ffffff'
              ? frontendConfiguration.primaryColor
              : COLOR.BLACK;
        } else {
          node.style.backgroundColor = COLOR.WHITE;
        }
        break;
      }
    }
  };

  selectPackage = () => {
    const {
      product,
      productSelections,
      productPackage,
      isPremiumType,
      updateProductSelections
    } = this.props;
    const cloneProductSelections = _.assign({}, productSelections);
    let newProductSelections = {};

    if (product.availableStatus !== PRODUCT_AVAILABLE_STATUS.DEACTIVATED) {
      newProductSelections[PACKAGE_SELECTED_KEY[product.type]] = productPackage;
      newProductSelections[PACKAGE_PREMIUM_TYPE_KEY[product.type]] = isPremiumType;
      newProductSelections[PRODUCT_SELECTED_KEY[product.type]] = true;
      newProductSelections.selectedAddonIds = removeAddon(product, cloneProductSelections);
      updateProductSelections(newProductSelections);
    }
  };

  handleClick = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });

    Array.from(document.getElementsByClassName('ep-icon-minimal-up')).forEach(function(element) {
      element.click();
    });
  };

  filterMonthlyPriceByPackageType = (packageType, packageSelected) => {
    const { productSelections } = this.props;
    let packageRentalType = packageType ? PACKAGE_TYPE.PREMIUM : PACKAGE_TYPE.STANDARD;
    let monthlyPriceGross = 0;
    if (!packageSelected.packageRentals) {
      monthlyPriceGross =
        packageRentalType === 0
          ? packageSelected.standardRetailPriceGross
          : packageSelected.premiumRetailPriceGross;
    } else {
      _.forEach(packageSelected.packageRentals, object => {
        if (
          object.packageRentalType === packageRentalType &&
          object.duration === productSelections.selectedRentalInfo.duration &&
          object.unit === RENTAL_UNIT[productSelections.selectedRentalInfo.unit]
        ) {
          monthlyPriceGross = object.priceGross;
        }
      });
    }
    return monthlyPriceGross;
  };

  render() {
    const {
      fontText,
      fontTextBold,
      isPremiumType,
      productPackage,
      selectedPackage,
      isSelectedPackage,
      product,
      productSelections,
      frontendConfiguration
    } = this.props;

    const { isOpen } = this.state;

    const textColor = {
      color: frontendConfiguration.defaultTextColor
    };

    return (
      <div className="container-fluid">
        <div className="d-flex justify-content-between flex-nowrap ep--mt-16">
          <div className="d-flex justify-content-start ep-ellipsis">
            <div className="justify-content-end align-items-start ep--pt-8">
              <PackageRadioButton
                className={`package-${isPremiumType}-${productPackage[ATTRIBUTE.ID]}`}
                onChange={this.selectPackage}
                name="package"
                isChecked={selectedPackage.hasOwnProperty(ATTRIBUTE.ID) ? isSelectedPackage : null}
                style={textColor}
              />
            </div>

            <div className="ep--ml-16 ep-ellipsis">
              <Tooltip
                content={
                  <StyledSpan font={fontText} style={textColor}>
                    {productPackage.packageName}
                  </StyledSpan>
                }
                position={Position.BOTTOM}
                className="ep-ellipsis w-100"
                tooltipClassName="ep-tooltip ep-tooltip-width-300"
              >
                <StyledSpanBold
                  className="ep__txt--md ep__txt-bold ep__txt-no-select ep-ellipsis d-block"
                  font={fontTextBold}
                  style={textColor}
                >
                  {productPackage.packageName}
                </StyledSpanBold>
              </Tooltip>

              <div className="d-flex justify-content-start align-items-center ep--pt-8">
                <i
                  className="d-inline-block ep-icon-dashboard-half ep__txt--sm ep--mr-8"
                  style={textColor}
                />
                <StyledSpan
                  dangerouslySetInnerHTML={{
                    __html: formatSpecification(product.type, productPackage.specifications)
                  }}
                  className="ep__txt--sm ep__txt-regular ep__txt-no-select"
                  font={fontText}
                  style={textColor}
                />
              </div>

              <div className="ep--pt-8 ep-ellipsis">
                <ShowIfUtil
                  condition={
                    productSelections.selectedPurchaseRental === PAYMENT_TYPE.PURCHASE &&
                    !isPremiumType
                  }
                >
                  <div
                    className="d-flex justify-content-start align-items-center"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    <i className="ep-icon-money-coins ep__txt--sm ep--mr-8" style={textColor} />
                    <StyledSpan
                      className="ep__txt--sm ep__txt-regular ep__txt-no-select"
                      font={fontText}
                      style={textColor}
                    >
                      {productPackage.standardRetailAble
                        ? formatPrice(productPackage.standardRetailPriceGross)
                        : RENTAL.ON_REQUEST}
                    </StyledSpan>
                  </div>
                </ShowIfUtil>
                <ShowIfUtil
                  condition={
                    productSelections.selectedPurchaseRental === PAYMENT_TYPE.PURCHASE &&
                    isPremiumType
                  }
                >
                  <div
                    className="d-flex justify-content-start align-items-center"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    <i className="ep-icon-money-coins ep__txt--sm ep--mr-8" style={textColor} />
                    <StyledSpan
                      className="ep__txt--sm ep__txt-regular ep__txt-no-select"
                      font={fontText}
                      style={textColor}
                    >
                      {productPackage.premiumRetailAble
                        ? formatPrice(productPackage.premiumRetailPriceGross)
                        : RENTAL.ON_REQUEST}
                    </StyledSpan>
                  </div>
                </ShowIfUtil>
                <ShowIfUtil
                  condition={
                    productSelections.selectedPurchaseRental === PAYMENT_TYPE.RENTAL &&
                    !isPremiumType
                  }
                >
                  <div className="d-flex justify-content-start align-items-center">
                    <i className="ep-icon-money-coins ep__txt--sm ep--mr-8" style={textColor} />
                    <StyledSpan
                      className="ep__txt--sm ep__txt-regular ep__txt-no-select"
                      font={fontText}
                      style={textColor}
                    >
                      {productPackage.standardRentedStatus
                        ? ` ${formatPrice(
                            this.filterMonthlyPriceByPackageType(isPremiumType, productPackage)
                          )}${RENTAL.PER_MONTH}`
                        : RENTAL.ON_REQUEST}
                    </StyledSpan>
                    <ShowIfUtil condition={productPackage.standardRentalUpfrontFeeGross > 0}>
                      <StyledSpan
                        className="ep__txt--sm ep__txt-regular ml-1 ep__txt-no-select"
                        font={fontText}
                        style={textColor}
                      >
                        {`+ ${formatPrice(productPackage.standardRentalUpfrontFeeGross)} ${
                          RENTAL.UPFRONT_FEE
                        }`}
                      </StyledSpan>
                    </ShowIfUtil>
                  </div>
                </ShowIfUtil>
                <ShowIfUtil
                  condition={
                    productSelections.selectedPurchaseRental === PAYMENT_TYPE.RENTAL &&
                    isPremiumType
                  }
                >
                  <div className="d-flex justify-content-start align-items-center">
                    <i className="ep-icon-money-coins ep__txt--sm ep--mr-8" style={textColor} />
                    <StyledSpan
                      className="ep__txt--sm ep__txt-regular ep__txt-no-select"
                      font={fontText}
                      style={textColor}
                    >
                      {'   ' + productPackage.premiumRentedStatus
                        ? ` ${formatPrice(
                            this.filterMonthlyPriceByPackageType(isPremiumType, productPackage)
                          )} ${RENTAL.PER_MONTH}`
                        : RENTAL.ON_REQUEST}
                    </StyledSpan>
                    <ShowIfUtil condition={productPackage.premiumRentalUpfrontFeeGross > 0}>
                      <StyledSpan
                        className="ep__txt--sm ep__txt-regular ml-1 ep__txt-no-select"
                        font={fontText}
                        style={textColor}
                      >
                        {`+ ${formatPrice(productPackage.premiumRentalUpfrontFeeGross)} ${
                          RENTAL.UPFRONT_FEE
                        }`}
                      </StyledSpan>
                    </ShowIfUtil>
                  </div>
                </ShowIfUtil>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-end ep--ml-8">
            <i
              className={`ep--ml-8 ep--pt-8 ${
                isOpen
                  ? 'ep-icon-minimal-up ep__txt--sm ep-position-top-3 ep-cursor-pointer'
                  : 'ep-icon-minimal-down ep__txt--sm ep-position-top-3 ep-cursor-pointer'
              }`}
              style={textColor}
              onClick={this.handleClick}
            />
          </div>
        </div>
        <DescriptionText className="d-flex flex-row">
          <div className="justify-content-start align-items-start">
            <Collapse isOpen={isOpen} keepChildrenMounted>
              <ShowIfUtil condition={!isPremiumType}>
                <div className="d-flex">
                  <i className="ep-icon-alert-circle-i ep__txt--sm" style={textColor} />
                  <StyledSpan
                    className="ep__txt--sm ep__txt-regular ep--pl-8 ep-opacity-50 ep__txt-no-select"
                    font={fontText}
                    style={textColor}
                  >
                    {productPackage.standardDescription ? productPackage.standardDescription : '-'}
                  </StyledSpan>
                </div>
              </ShowIfUtil>
              <ShowIfUtil condition={isPremiumType}>
                <div className="d-flex">
                  <i className="ep-icon-alert-circle-i ep__txt--sm" style={textColor} />
                  <StyledSpan
                    className="ep__txt--sm ep__txt-regular ep--pl-8 ep-opacity-50 ep__txt-no-select"
                    font={fontText}
                    style={textColor}
                  >
                    {productPackage.premiumDescription ? productPackage.premiumDescription : '-'}
                  </StyledSpan>
                </div>
              </ShowIfUtil>
            </Collapse>
          </div>
        </DescriptionText>
      </div>
    );
  }
}

PackageContainer.propTypes = {
  product: PropTypes.object.isRequired,
  productPackage: PropTypes.object.isRequired,
  isPremiumType: PropTypes.bool,
  selectedPackage: PropTypes.object,
  isSelectedPackage: PropTypes.bool,
  fontText: PropTypes.object,
  fontTextBold: PropTypes.object,
  updateProductSelections: PropTypes.func,
  productSelections: PropTypes.object,
  frontendConfiguration: PropTypes.object
};

PackageContainer.defaultProps = {
  fontText: {},
  fontTextBold: {},
  updateProductSelections: () => ({}),
  productSelections: {},
  frontendConfiguration: {},
  isPremiumType: false,
  selectedPackage: {},
  isSelectedPackage: false
};

function mapStateToProps(state) {
  return {
    frontendConfiguration: state.frontendConfiguration,
    productSelections: productSelectionsSelector(state)
  };
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateProductSelections,
        updateSelectedAddon
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PackageContainer);

import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { Position, Tooltip } from '@blueprintjs/core';
import {
  ATTRIBUTE,
  BOOLEAN,
  GERMAN_TEXT,
  ONLINE_PURCHASE,
  PACKAGE_SELECTED_KEY,
  PAYMENT_TYPE,
  PRODUCT_TYPE,
  PURCHASE_TYPE,
  RENTAL,
  REQUESTS,
  SPECIAL_CHARACTER,
  STATUS
} from '../../common/constants';
import {
  StyledButton,
  StyledP,
  StyledSpan,
  StyledSpanBold
} from '../../components/epilot-forms/common';
import {
  checkIsOnlinePurchase,
  checkSelectedProductOnRequest,
  convertProdTypeToProdName,
  filterMonthlyPriceByPackageType,
  formatPrice,
  getSummaryDataModelV1,
  removeAddon,
  ShowIfUtil,
  checkAddonRentalRate
} from '../../common/utils';
import {
  removeSelectedAddons,
  selectAllProductSelections,
  updateProductSelections
} from '../../store/actions';
import {
  productSelectionsSelector,
  productsSelector,
  selectedProductsSelector,
  totalPriceCalcSelector
} from '../../store/selectors';
import { TemporaryTotal } from '../webshop';

class OrderSummaryContainer extends PureComponent {
  constructor(props) {
    super(props);
    const { routeManager, selectAllProductSelections } = this.props;
    this.isPreviewCheckoutPage = routeManager.isPreviewCheckoutPage === BOOLEAN.TRUE;
    if (this.isPreviewCheckoutPage) {
      selectAllProductSelections();
    }
  }

  componentDidMount() {
    const { updateProductSelections } = this.props;
    if (this.isPreviewCheckoutPage) {
      this.selectCheapestPackages();
    }
    updateProductSelections({
      preselectProductsAndPackages: false
    });
  }

  showAddonPrice = item => {
    const { productSelections } = this.props;
    if (productSelections.selectedPurchaseRental === PAYMENT_TYPE.PURCHASE) {
      if (!item.retailAble) {
        return RENTAL.ON_REQUEST;
      }
      if (item.retailPrice || item.retailPrice === 0) {
        return formatPrice(item.retailPrice);
      } else {
        return RENTAL.ON_REQUEST;
      }
    } else if (productSelections.selectedPurchaseRental === PAYMENT_TYPE.RENTAL) {
      if (!item.rentable) {
        return RENTAL.ON_REQUEST;
      }
      if (item.retailTaxId || item.retailTaxId === 0) {
        const rentalInfo = productSelections.selectedRentalInfo;
        const addonRental = Array.isArray(item.addonRentals)
          ? item.addonRentals.find(addonRental => checkAddonRentalRate(addonRental, rentalInfo))
          : null;
        if (addonRental) {
          return formatPrice(addonRental.price);
        } else {
          return RENTAL.ON_REQUEST;
        }
      } else {
        return RENTAL.ON_REQUEST;
      }
    }
  };

  showPackagePrice = item => {
    const { productSelections, selectedProducts } = this.props;
    let packagePrice = null;
    _.forEach(selectedProducts, selectedProduct => {
      switch (selectedProduct.type) {
        case PRODUCT_TYPE.SOLAR:
          packagePrice = this.checkPackagePrice(
            productSelections.isSelectedSolarPackagePremiumType,
            item
          );
          break;
        case PRODUCT_TYPE.CHARGE:
          packagePrice = this.checkPackagePrice(
            productSelections.isSelectedChargePackagePremiumType,
            item
          );
          break;
        case PRODUCT_TYPE.POWER:
          packagePrice = this.checkPackagePrice(
            productSelections.isSelectedPowerPackagePremiumType,
            item
          );
          break;
        case PRODUCT_TYPE.STORAGE:
          packagePrice = this.checkPackagePrice(
            productSelections.isSelectedStoragePackagePremiumType,
            item
          );
          break;
        case PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY:
          packagePrice = this.checkPackagePrice(
            productSelections.isSelectedHouseConnectionForElectricityPackagePremiumType,
            item
          );
          break;
        case PRODUCT_TYPE.HOUSE_CONNECTION_GAS:
          packagePrice = this.checkPackagePrice(
            productSelections.isSelectedHouseConnectionForGasPackagePremiumType,
            item
          );
          break;
        case PRODUCT_TYPE.HOUSE_CONNECTION_WATER:
          packagePrice = this.checkPackagePrice(
            productSelections.isSelectedHouseConnectionForWaterPackagePremiumType,
            item
          );
          break;
        case PRODUCT_TYPE.HOUSE_CONNECTION_HEAT:
          packagePrice = this.checkPackagePrice(
            productSelections.isSelectedHouseConnectionForHeatingPackagePremiumType,
            item
          );
          break;
        case PRODUCT_TYPE.HOUSE_CONNECTION_BROADBAND:
          packagePrice = this.checkPackagePrice(
            productSelections.isSelectedHouseConnectionForBroadbandPackagePremiumType,
            item
          );
          break;
        case PRODUCT_TYPE.HEATING:
          packagePrice = this.checkPackagePrice(
            productSelections.isSelectedHeatingPackagePremiumType,
            item
          );
          break;
        case PRODUCT_TYPE.HEATPUMP:
          packagePrice = this.checkPackagePrice(
            productSelections.isSelectedHeatPumpPackagePremiumType,
            item
          );
          break;
        case PRODUCT_TYPE.CARSHARING:
          packagePrice = this.checkPackagePrice(
            productSelections.isSelectedCarsharingPackagePremiumType,
            item
          );
          break;
        case PRODUCT_TYPE.CHP:
          packagePrice = this.checkPackagePrice(
            productSelections.isSelectedCHPPackagePremiumType,
            item
          );
          break;
        case PRODUCT_TYPE.SOLAR_B2B:
          packagePrice = this.checkPackagePrice(
            productSelections.isSelectedSolarB2BPackagePremiumType,
            item
          );
          break;
        case PRODUCT_TYPE.CHARGE_B2B:
          packagePrice = this.checkPackagePrice(
            productSelections.isSelectedChargeB2BPackagePremiumType,
            item
          );
          break;
        default:
          break;
      }
    });
    return packagePrice;
  };

  removePackage = item => {
    const { productSelections, updateProductSelections, selectedProducts } = this.props;
    const cloneProductSelections = _.assign({}, productSelections);
    let newProductSelections = {};

    _.forEach(selectedProducts, product => {
      if (
        productSelections[PACKAGE_SELECTED_KEY[product.type]].id === item.id ||
        product.type === item.productType
      ) {
        switch (product.type) {
          case PRODUCT_TYPE.SOLAR:
            newProductSelections.isSelectedProductSolar = false;
            newProductSelections.selectedAddonIds = removeAddon(product, cloneProductSelections);
            newProductSelections.selectedAddonIds = removeAddon(product, cloneProductSelections);
            break;
          case PRODUCT_TYPE.CHARGE:
            newProductSelections.isSelectedProductCharge = false;
            newProductSelections.selectedAddonIds = removeAddon(product, cloneProductSelections);
            break;
          case PRODUCT_TYPE.POWER:
            newProductSelections.isSelectedProductPower = false;
            newProductSelections.selectedAddonIds = removeAddon(product, cloneProductSelections);
            break;
          case PRODUCT_TYPE.STORAGE:
            newProductSelections.isSelectedProductStorage = false;
            newProductSelections.selectedAddonIds = removeAddon(product, cloneProductSelections);
            break;
          case PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY:
            newProductSelections.isSelectedProductHouseConnectionForElectricity = false;
            newProductSelections.selectedAddonIds = removeAddon(product, cloneProductSelections);
            break;
          case PRODUCT_TYPE.HOUSE_CONNECTION_GAS:
            newProductSelections.isSelectedProductHouseConnectionForGas = false;
            newProductSelections.selectedAddonIds = removeAddon(product, cloneProductSelections);
            break;
          case PRODUCT_TYPE.HOUSE_CONNECTION_WATER:
            newProductSelections.isSelectedProductHouseConnectionForWater = false;
            newProductSelections.selectedAddonIds = removeAddon(product, cloneProductSelections);
            break;
          case PRODUCT_TYPE.HOUSE_CONNECTION_HEAT:
            newProductSelections.isSelectedProductHouseConnectionForHeating = false;
            newProductSelections.selectedAddonIds = removeAddon(product, cloneProductSelections);
            break;
          case PRODUCT_TYPE.HOUSE_CONNECTION_BROADBAND:
            newProductSelections.isSelectedProductHouseConnectionForBroadband = false;
            newProductSelections.selectedAddonIds = removeAddon(product, cloneProductSelections);
            break;
          case PRODUCT_TYPE.HEATING:
            newProductSelections.isSelectedProductHeating = false;
            newProductSelections.selectedAddonIds = removeAddon(product, cloneProductSelections);
            break;
          case PRODUCT_TYPE.HEATPUMP:
            newProductSelections.isSelectedProductHeatPump = false;
            newProductSelections.selectedAddonIds = removeAddon(product, cloneProductSelections);
            break;
          case PRODUCT_TYPE.CARSHARING:
            newProductSelections.isSelectedProductCarsharing = false;
            newProductSelections.selectedAddonIds = removeAddon(product, cloneProductSelections);
            break;
          case PRODUCT_TYPE.CHP:
            newProductSelections.isSelectedProductCHP = false;
            newProductSelections.selectedAddonIds = removeAddon(product, cloneProductSelections);
            break;
          default:
            break;
        }
      }
    });
    !_.isEmpty(newProductSelections) && updateProductSelections(newProductSelections);
  };

  removeAddOn = item => {
    const { removeSelectedAddons } = this.props;
    removeSelectedAddons([item.id]);
  };

  selectCheapestPackages = () => {
    //TODO: DUPLICATE CODE, REFACTOR
    const { products, updateProductSelections } = this.props;
    let newProductSelections = {};

    _.forEach(products, product => {
      let price = 0;

      _.forEach(product.packages, onePackage => {
        if (onePackage.status === STATUS.ACTIVE) {
          price = onePackage.standardRetailPrice;
        }
      });

      _.forEach(product.packages, onePackage => {
        if (onePackage.status === STATUS.ACTIVE) {
          if (onePackage.standardRetailPrice <= price) {
            newProductSelections[PACKAGE_SELECTED_KEY[product.type]] = onePackage;
            price = onePackage.standardRetailPrice;
          }
        }
      });
    });
    !_.isEmpty(newProductSelections) && updateProductSelections(newProductSelections);
  };

  handleListPackage = () => {
    //TODO: move to redux or selector
    const { productSelections, selectedProducts } = this.props;
    let listPackageSelected = [];
    _.forEach(selectedProducts, product => {
      const selectedPackage = productSelections[PACKAGE_SELECTED_KEY[product.type]];
      if (selectedPackage.hasOwnProperty(ATTRIBUTE.ID)) {
        listPackageSelected.push({
          ...productSelections[PACKAGE_SELECTED_KEY[product.type]],
          productType: product.type
        });
      } else {
        listPackageSelected.push({
          id: _.uniqueId('selected_product_'),
          productName: convertProdTypeToProdName(product.type),
          productType: product.type
        });
      }
    });
    return listPackageSelected;
  };

  handleListAddOn = () => {
    //TODO: move to redux or selector
    const { productSelections, selectedProducts } = this.props;
    let listAddOnSelected = [];

    if (productSelections.selectedAddonIds.length > 0) {
      _.forEach(productSelections.selectedAddonIds, selectedAddonId => {
        _.forEach(selectedProducts, product => {
          if (product.addons) {
            if (product.addons.length > 0) {
              let selectedAddon;
              selectedAddon = product.addons.find(item => item.id === selectedAddonId);
              if (selectedAddon) {
                listAddOnSelected.push(selectedAddon);
              }
            }
          }
        });
      });
    }

    return listAddOnSelected;
  };

  checkPackagePrice = (isPremiumType, item) => {
    const { productSelections } = this.props;
    if (productSelections.selectedPurchaseRental === PAYMENT_TYPE.PURCHASE && !isPremiumType) {
      if (item.standardRetailAble) {
        return formatPrice(item.standardRetailPrice);
      } else {
        return RENTAL.ON_REQUEST;
      }
    } else if (
      productSelections.selectedPurchaseRental === PAYMENT_TYPE.PURCHASE &&
      isPremiumType
    ) {
      if (item.premiumRetailAble) {
        return formatPrice(item.premiumRetailPrice);
      } else {
        return RENTAL.ON_REQUEST;
      }
    } else if (productSelections.selectedPurchaseRental === PAYMENT_TYPE.RENTAL && !isPremiumType) {
      if (item.standardRentedStatus) {
        return (
          formatPrice(filterMonthlyPriceByPackageType(productSelections, isPremiumType, item)) +
          SPECIAL_CHARACTER.SPACE +
          RENTAL.PER_MONTH
        );
      } else {
        return RENTAL.ON_REQUEST;
      }
    } else if (productSelections.selectedPurchaseRental === PAYMENT_TYPE.RENTAL && isPremiumType) {
      if (item.premiumRentedStatus) {
        return (
          formatPrice(filterMonthlyPriceByPackageType(productSelections, isPremiumType, item)) +
          SPECIAL_CHARACTER.SPACE +
          RENTAL.PER_MONTH
        );
      } else {
        return RENTAL.ON_REQUEST;
      }
    }
  };

  render() {
    const {
      frontendConfiguration,
      fontText,
      fontTextBold,
      noProductSelected,
      totalPrice,
      productSelections,
      selectedProducts,
      thankYouPageData,
      isShowPackageInfo
    } = this.props;

    const isOnlinePurchase =
      checkIsOnlinePurchase({
        frontendConfiguration,
        productSelections,
        totalPrice,
        selectedProducts,
        onlinePurchaseOnlyRequest: thankYouPageData.onlinePurchaseOnlyRequest
      }) === PURCHASE_TYPE.ONLINE_PURCHASE;
    const totalPriceWebshop = {
      padding: '20px'
    };

    const isAllOnlinePurchasePackages = getSummaryDataModelV1(selectedProducts, productSelections)
      .isAllOnlinePurchasePackageSummaryModelsV1;

    const textColor = {
      color: frontendConfiguration.defaultTextColor
    };

    return (
      <div className="ep-order-summary-wrapper ep--pb-0 ep--mr-8 ep--mb-8">
        <StyledP
          font={fontText}
          className="ep__txt--h2 ep__txt-bold ep-bg-light-grey ep--py-24 ep--mb-0 ep--pl-16"
          style={textColor}
        >
          {isOnlinePurchase ? ONLINE_PURCHASE.DE : REQUESTS.DE}
        </StyledP>
        <div className="container">
          <div className="row">
            {/*PACKAGES*/}
            <div className="col-12 packages">
              <StyledP
                font={fontText}
                className="ep-opacity-50 ep__txt--sm ep__txt-bold ep--my-24"
                style={textColor}
              >
                {'PRODUKTE'}
              </StyledP>
              {noProductSelected ? (
                <StyledP
                  font={fontText}
                  className="ep-opacity-50 ep__txt--sm font-italic ep__txt-bold ep--my-24"
                  style={textColor}
                >
                  {'Kein Paket ausgewählt.'}
                </StyledP>
              ) : (
                this.handleListPackage().map(item => {
                  const packagePriceContainer = classNames({
                    'col-4': true,
                    'text-right': true,
                    'ep--mb-0': true,
                    'ep__txt--md': true,
                    'ep__txt-bold': true,
                    'ep-ellipsis': true,
                    'ep-opacity-50': this.showPackagePrice(item) === RENTAL.ON_REQUEST
                  });
                  return (
                    <div className="row ep--mb-24 align-items-center" key={item.id}>
                      <StyledSpan
                        className="col-7 ep__txt--md ep__txt-bold ep--mb-0 text-break"
                        font={fontText}
                        style={textColor}
                      >
                        {isShowPackageInfo ? (
                          <Tooltip
                            content={
                              <StyledP font={fontText} style={textColor}>
                                {item.packageName ? item.packageName : item.productName}
                              </StyledP>
                            }
                            position={Position.BOTTOM}
                            tooltipClassName="ep-tooltip ep-tooltip-width-300"
                          >
                            <div>
                              <StyledSpanBold font={fontText} style={textColor}>
                                {item.packageName ? item.packageName : item.productName}
                              </StyledSpanBold>
                            </div>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            content={
                              <StyledP font={fontText} style={textColor}>
                                {convertProdTypeToProdName(item.productType)}
                              </StyledP>
                            }
                            position={Position.BOTTOM}
                            tooltipClassName="ep-tooltip ep-tooltip-width-300"
                          >
                            <div>
                              <StyledSpanBold font={fontText} style={textColor}>
                                {convertProdTypeToProdName(item.productType)}
                              </StyledSpanBold>
                            </div>
                          </Tooltip>
                        )}
                      </StyledSpan>
                      <StyledSpan
                        className={packagePriceContainer}
                        font={fontText}
                        style={textColor}
                      >
                        {isShowPackageInfo ? (
                          <Tooltip
                            content={
                              <StyledP font={fontText} style={textColor}>
                                {this.showPackagePrice(item)}
                              </StyledP>
                            }
                            position={Position.BOTTOM}
                            tooltipClassName="ep-tooltip ep-tooltip-width-300"
                          >
                            <div>
                              <StyledSpanBold font={fontText} style={textColor}>
                                {this.showPackagePrice(item)}
                              </StyledSpanBold>
                            </div>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            content={GERMAN_TEXT.ON_REQUEST}
                            position={Position.BOTTOM}
                            tooltipClassName="ep-tooltip ep-tooltip-width-300"
                          >
                            <StyledSpan font={fontText} style={textColor}>
                              {GERMAN_TEXT.ON_REQUEST}
                            </StyledSpan>
                          </Tooltip>
                        )}
                      </StyledSpan>

                      <div className="item-remove col-1 px-0">
                        <StyledButton
                          font={fontText}
                          className="ep-icon-simple-remove ep-bg-transparent border-0 ep-cursor-pointer ep-remove-order-summary"
                          onClick={() => this.removePackage(item)}
                          style={textColor}
                        />
                      </div>
                    </div>
                  );
                })
              )}
            </div>

            <ShowIfUtil condition={isShowPackageInfo}>
              <hr className="col-12 ep-bg-little-dark-grey" />

              {/*ADD-ONS*/}
              <div className="col-12">
                <ShowIfUtil condition={this.handleListAddOn().length > 0}>
                  <StyledP
                    font={fontText}
                    className="ep-opacity-50 ep__txt--sm ep__txt-bold ep--my-24"
                    style={textColor}
                  >
                    {'ZUSATZOPTIONEN'}
                  </StyledP>
                  {this.handleListAddOn().map(item => {
                    const addonPriceContainer = classNames({
                      'col-4': true,
                      'text-right': true,
                      'ep--mb-0': true,
                      'ep__txt--md': true,
                      'ep__txt-bold': true,
                      'ep-ellipsis': true,
                      'ep-opacity-50': this.showAddonPrice(item) === RENTAL.ON_REQUEST
                    });
                    return (
                      <div className="row ep--mb-24 align-items-center" key={item.id}>
                        <StyledSpan
                          className="col-7 ep__txt--md ep__txt-bold ep--mb-0 ep-ellipsis"
                          font={fontText}
                          style={textColor}
                        >
                          <Tooltip
                            content={
                              <StyledP font={fontText} style={textColor}>
                                {item.name}
                              </StyledP>
                            }
                            position={Position.BOTTOM}
                            tooltipClassName="ep-tooltip ep-tooltip-width-300"
                          >
                            <div>
                              <StyledSpanBold font={fontText} style={textColor}>
                                {item.name}
                              </StyledSpanBold>
                            </div>
                          </Tooltip>
                        </StyledSpan>

                        <StyledSpan
                          className={addonPriceContainer}
                          font={fontText}
                          style={textColor}
                        >
                          <Tooltip
                            content={
                              <StyledP font={fontText} style={textColor}>
                                {this.showAddonPrice(item)}
                              </StyledP>
                            }
                            position={Position.BOTTOM}
                            tooltipClassName="ep-tooltip ep-tooltip-width-300"
                          >
                            <div>
                              <StyledSpanBold font={fontText} style={textColor}>
                                {this.showAddonPrice(item)}
                              </StyledSpanBold>
                            </div>
                          </Tooltip>
                        </StyledSpan>
                        <div className="item-remove col-1 px-0">
                          <StyledButton
                            font={fontText}
                            style={textColor}
                            className="ep-icon-simple-remove ep-bg-transparent border-0 ep-cursor-pointer ep-remove-order-summary"
                            onClick={() => this.removeAddOn(item)}
                          />
                        </div>
                      </div>
                    );
                  })}
                  <hr
                    className="col-12 ep-bg-little-dark-grey"
                    style={{
                      marginLeft: '-15px',
                      marginRight: '-15px'
                    }}
                  />
                </ShowIfUtil>
              </div>

              {/*SUBTOTAL-VAT*/}
              <div className="col-12 ep--mt-24">
                <div className="row ep--mb-8">
                  <StyledSpanBold
                    font={fontText}
                    className="col-7 text-right ep__txt--md ep-opacity-50 ep__txt-semibold"
                    style={textColor}
                  >
                    {'Zwischensumme'}
                  </StyledSpanBold>
                  <StyledSpan
                    className="col-4 ep__txt--md ep__txt-bold text-right ep-ellipsis"
                    font={fontText}
                    style={textColor}
                  >
                    <Tooltip
                      content={
                        <StyledSpanBold font={fontText} style={textColor}>
                          {!checkSelectedProductOnRequest({ selectedProducts, productSelections })
                            ? formatPrice(totalPrice.totalPrice)
                            : RENTAL.ON_REQUEST}
                        </StyledSpanBold>
                      }
                      position={Position.BOTTOM}
                      tooltipClassName="ep-tooltip ep-tooltip-width-300"
                    >
                      <div>
                        <StyledSpanBold font={fontText} style={textColor}>
                          {!checkSelectedProductOnRequest({ selectedProducts, productSelections })
                            ? formatPrice(totalPrice.totalPrice)
                            : RENTAL.ON_REQUEST}
                        </StyledSpanBold>
                      </div>
                    </Tooltip>
                  </StyledSpan>
                  <span className="col-1" />
                </div>
                <div className="row ep--mb-24">
                  <StyledSpanBold
                    font={fontText}
                    style={textColor}
                    className="col-7 text-right ep__txt--md ep-opacity-50 ep__txt-semibold"
                  >
                    {'MwSt.'}
                  </StyledSpanBold>
                  <StyledSpan
                    className="col-4 ep__txt--md ep__txt-bold text-right ep-ellipsis"
                    font={fontText}
                    style={textColor}
                  >
                    <Tooltip
                      content={
                        <StyledSpanBold font={fontText} style={textColor}>
                          {formatPrice(totalPrice.totalTax)}
                        </StyledSpanBold>
                      }
                      position={Position.BOTTOM}
                      tooltipClassName="ep-tooltip ep-tooltip-width-300"
                    >
                      <div>
                        <StyledSpanBold font={fontText} style={textColor}>
                          {formatPrice(totalPrice.totalTax)}
                        </StyledSpanBold>
                      </div>
                    </Tooltip>
                  </StyledSpan>
                </div>
              </div>
            </ShowIfUtil>
          </div>
        </div>
        {/* TOTAL PRICE */}
        <ShowIfUtil condition={isAllOnlinePurchasePackages && isShowPackageInfo}>
          <div className="container ep-bg-light-grey d-flex flex-column" style={totalPriceWebshop}>
            <TemporaryTotal fontText={fontText} fontTextBold={fontTextBold} isContactForm />
          </div>
        </ShowIfUtil>
        <ShowIfUtil condition={!isShowPackageInfo}>
          <div className="container ep-bg-light-grey ep--py-24">
            <div className="row">
              <StyledSpan
                font={fontText}
                className="col-11 ep__txt--h1 ep__txt-bold text-right ep-ellipsis"
                style={{ color: frontendConfiguration.secondaryTextColor }}
              >
                {GERMAN_TEXT.ON_REQUEST}
              </StyledSpan>
            </div>
          </div>
        </ShowIfUtil>

        <ShowIfUtil condition={!isAllOnlinePurchasePackages && isShowPackageInfo}>
          <div className="container ep-bg-light-grey ep--py-24">
            <div className="row">
              <StyledSpan
                font={fontText}
                className="col-11 ep__txt--h1 ep__txt-bold text-right ep-ellipsis"
                style={{ color: frontendConfiguration.secondaryTextColor }}
              >
                {GERMAN_TEXT.ON_REQUEST}
              </StyledSpan>
            </div>

            <div className="row">
              <StyledSpanBold
                font={fontText}
                className="ep__txt--sm ep-text-dark-grey ep__txt-semibold text-right col-11"
                style={{ color: frontendConfiguration.secondaryTextColor }}
              >
                {GERMAN_TEXT.TOTAL_AMOUNT_INCL_TAX}
              </StyledSpanBold>
            </div>
          </div>
        </ShowIfUtil>
      </div>
    );
  }
}

OrderSummaryContainer.propTypes = {
  frontendConfiguration: PropTypes.object,
  thankYouPageData: PropTypes.object,
  fontText: PropTypes.object,
  fontTextBold: PropTypes.object,
  selectAllProductSelections: PropTypes.func,
  removeSelectedAddons: PropTypes.func.isRequired,
  updateProductSelections: PropTypes.func,
  productSelections: PropTypes.object,
  noProductSelected: PropTypes.bool,
  totalPrice: PropTypes.object,
  routeManager: PropTypes.object,
  products: PropTypes.array,
  selectedProducts: PropTypes.array.isRequired,
  isShowPackageInfo: PropTypes.bool
};

OrderSummaryContainer.defaultProps = {
  frontendConfiguration: {},
  thankYouPageData: {},
  productSelections: {},
  noProductSelected: false,
  totalPrice: {},
  routeManager: {},
  products: [],
  fontText: '',
  fontTextBold: '',
  selectAllProductSelections: () => ({}),
  updateProductSelections: () => ({}),
  isShowPackageInfo: false
};

function mapStateToProps(state) {
  return {
    frontendConfiguration: state.frontendConfiguration,
    thankYouPageData: state.thankYouPage,
    totalPrice: totalPriceCalcSelector(state),
    customerJourneyAnswer: state.customerJourneyAnswer,
    customerJourneyMapping: state.customerJourneyMapping,
    productSelections: productSelectionsSelector(state),
    routeManager: state.route,
    products: productsSelector(state),
    selectedProducts: selectedProductsSelector(state)
  };
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        selectAllProductSelections,
        updateProductSelections,
        removeSelectedAddons
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderSummaryContainer);

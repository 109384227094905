import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import Dropzone from 'react-dropzone';
import { ClipLoader } from 'react-spinners';
import { COLOR, QUESTION_ID, QUESTION_TYPE } from '../../common/constants';
import {
  formatQuestion,
  ShowIconByQuestionIdUtil,
  ShowIfUtil,
  getAcceptFileType
} from '../../common/utils';
import { InfoHelpDescription, StyledSpan } from '../../components/epilot-forms/common';
import { updateCustomerJourneyAnswer } from '../../store/actions';
import { useUploadFile } from '../../common/hooks/upload-file.hook';

const UploadFileQuestionContainer = ({ question, fontText, defaultTextColor }) => {
  const textColor = {
    color: defaultTextColor
  };

  const errorStyle = {
    color: COLOR.ERROR_TEXT_COLOR,
    textAlign: 'center'
  };
  const dispatch = useDispatch();
  const {
    frontendConfiguration,
    customerJourneyAnswer,
    uploadFileStatus: { isUploading }
  } = useSelector(state => state);
  const [uploadedFileInfo, setUploadedFileInfo] = useState([]);
  const {
    onDrop,
    isMultipleUpload,
    setIsMultipleUpload,
    invalidFileNames,
    invalidSizeFileNames,
    invalidTypeFileNames,
    failedUploadedFileNames,
    successUploadedFileInfo,
    setSuccessUploadedFileInfo
  } = useUploadFile({
    question,
    uploadedFileInfo
  });

  const _removeUploadedFile = fileName => {
    const newCJAnswer = {};
    switch (question.id) {
      case QUESTION_ID.HOUSE_CONNECTION_UPLOAD_PROPERTY_DOCUMENT:
        newCJAnswer.propertyDocument = _.reduce(
          customerJourneyAnswer.propertyDocument,
          (result, value) => {
            if (value?.name?.localeCompare(fileName) !== 0) {
              result.push(value);
            }
            return result;
          },
          []
        );
        break;
      case QUESTION_ID.HOUSE_CONNECTION_UPLOAD_CONSENT_FORM:
        newCJAnswer.consentForm = _.reduce(
          customerJourneyAnswer.consentForm,
          (result, value) => {
            if (value?.name?.localeCompare(fileName) !== 0) {
              result.push(value);
            }
            return result;
          },
          []
        );
        break;
      default:
        if (question.type === QUESTION_TYPE.FILE_UPLOAD) {
          let cloneFileUpload = _.assign([], customerJourneyAnswer.fileUpload);
          if (_.find(customerJourneyAnswer.fileUpload, { questionId: question.id })) {
            const currentQuestionIndex = _.findIndex(
              customerJourneyAnswer.fileUpload,
              file => file.questionId === question.id
            );
            cloneFileUpload[currentQuestionIndex].files = _.reduce(
              cloneFileUpload[currentQuestionIndex]?.files,
              (result, value) => {
                if (value?.name?.localeCompare(fileName) !== 0) {
                  result.push(value);
                }
                return result;
              },
              []
            );
          }
          newCJAnswer.fileUpload = cloneFileUpload;
        }
        break;
    }
    !_.isEmpty(newCJAnswer) && dispatch(updateCustomerJourneyAnswer(newCJAnswer));
  };

  // Update success uploaded file name to redux store
  useEffect(() => {
    if (successUploadedFileInfo?.length > 0) {
      const newCJAnswer = {};
      switch (question.id) {
        case QUESTION_ID.HOUSE_CONNECTION_UPLOAD_PROPERTY_DOCUMENT:
          newCJAnswer.propertyDocument = [
            ...customerJourneyAnswer.propertyDocument,
            ...successUploadedFileInfo
          ];
          break;
        case QUESTION_ID.HOUSE_CONNECTION_UPLOAD_CONSENT_FORM:
          newCJAnswer.consentForm = [
            ...customerJourneyAnswer.consentForm,
            ...successUploadedFileInfo
          ];
          break;
        default:
          if (question.type === QUESTION_TYPE.FILE_UPLOAD) {
            let cloneFileUpload = _.assign([], customerJourneyAnswer.fileUpload);
            if (_.find(customerJourneyAnswer.fileUpload, { questionId: question.id })) {
              cloneFileUpload[
                _.findIndex(customerJourneyAnswer.fileUpload, function(file) {
                  return file.questionId === question.id;
                })
              ].files = successUploadedFileInfo;
            } else {
              cloneFileUpload.push({
                questionId: question.id,
                fileUploadLabels: question?.objectModel?.fileUploadLabels,
                files: successUploadedFileInfo
              });
            }
            newCJAnswer.fileUpload = cloneFileUpload;
          }
          break;
      }
      !_.isEmpty(newCJAnswer) && dispatch(updateCustomerJourneyAnswer(newCJAnswer));
      setSuccessUploadedFileInfo([]);
    }
  }, [
    successUploadedFileInfo,
    question,
    customerJourneyAnswer,
    dispatch,
    setSuccessUploadedFileInfo
  ]);

  // Set uploaded file info
  useEffect(() => {
    if (question.id === QUESTION_ID.HOUSE_CONNECTION_UPLOAD_PROPERTY_DOCUMENT) {
      setUploadedFileInfo([...customerJourneyAnswer.propertyDocument]);
      setIsMultipleUpload(true);
    } else if (question.id === QUESTION_ID.HOUSE_CONNECTION_UPLOAD_CONSENT_FORM) {
      setUploadedFileInfo([...customerJourneyAnswer.consentForm]);
      setIsMultipleUpload(false);
    } else if (question.type === QUESTION_TYPE.FILE_UPLOAD) {
      const isFileUpload = _.find(customerJourneyAnswer.fileUpload, { questionId: question.id });
      isFileUpload?.files?.length > 0 && setUploadedFileInfo([...isFileUpload.files]);
      setIsMultipleUpload(true);
    }
  }, [question, customerJourneyAnswer, setIsMultipleUpload]);

  return (
    <div className="ep-customerjourneypage__question">
      <ShowIconByQuestionIdUtil
        questionId={question.id}
        questionType={question.type}
        colorIcon={frontendConfiguration.primaryColor}
      />
      <div className="text-center ep-min-height-70 ep--pt-8 ep--pb-16">
        <StyledSpan
          font={fontText}
          className="ep__txt-regular ep__txt--lg ep__txt-no-select"
          style={textColor}
        >
          {formatQuestion(question.questionTitle, customerJourneyAnswer.name)}
        </StyledSpan>
        <InfoHelpDescription
          showHelpDescription={question.showHelpDescription}
          tooltipInfoContent={<StyledSpan font={fontText}>{question.helpDescription}</StyledSpan>}
          classNameForInfoIcon="ep__txt--lg ep--ml-8"
          defaultTextColor={defaultTextColor}
        />
      </div>

      <div style={{ minHeight: '69px' }}>
        <Dropzone
          multiple={isMultipleUpload}
          accept={getAcceptFileType(frontendConfiguration)}
          onDrop={onDrop}
          disabled={isUploading}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              className="ep-cursor-pointer ep-dropzone row justify-content-center align-items-center"
            >
              <input {...getInputProps()} />
              <i className="d-inline-block ep-icon-cloud-upload-94 ep--mr-8" style={textColor} />
              <StyledSpan font={fontText} style={textColor}>
                Per Drag & Drop Datei hochladen oder vom Computer auswählen.
              </StyledSpan>
            </div>
          )}
        </Dropzone>
      </div>
      {isUploading ? (
        <ClipLoader
          color={defaultTextColor || '#000'}
          loading
          size={25}
          css={{
            marginLeft: 10
          }}
        />
      ) : (
        <div>
          {_.map(uploadedFileInfo, (fileInfo, index) => (
            <div
              className="ep--m-8"
              key={index}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
              }}
            >
              <div className="d-flex align-items-center">
                <StyledSpan className="ep__txt-no-select" font={fontText} style={textColor}>
                  {fileInfo.name}
                </StyledSpan>
              </div>
              <i
                className="ep-icon-trash-simple ep-cursor-pointer"
                style={{ ...textColor, position: 'absolute', right: 0 }}
                onClick={() => _removeUploadedFile(fileInfo.name)}
              />
            </div>
          ))}
          <ShowIfUtil condition={failedUploadedFileNames?.length > 0}>
            {_.map(failedUploadedFileNames, value => (
              <div className="text-center" key={value}>
                <StyledSpan
                  className="ep__txt--md ep__txt--required ep__txt-regular ep__txt-no-select"
                  font={fontText}
                  style={errorStyle}
                >
                  {`${value} Hochladen fehlgeschlagen`}
                </StyledSpan>
              </div>
            ))}
          </ShowIfUtil>
          <ShowIfUtil condition={invalidFileNames?.length > 0}>
            {_.map(invalidFileNames, value => (
              <div className="text-center" key={value}>
                <StyledSpan
                  className="ep__txt--md ep__txt--required ep__txt-regular ep__txt-no-select"
                  font={fontText}
                  style={errorStyle}
                >
                  {`${value} ist bereits vorhanden`}
                </StyledSpan>
              </div>
            ))}
          </ShowIfUtil>
          <ShowIfUtil condition={invalidSizeFileNames?.length > 0}>
            {_.map(invalidSizeFileNames, value => (
              <div className="text-center" key={value}>
                <StyledSpan
                  className="ep__txt--md ep__txt--required ep__txt-regular ep__txt-no-select"
                  font={fontText}
                  style={errorStyle}
                >
                  {`${value} hat eine ungültige Dateigröße, Dateigröße sollte kleiner als 10MB sein`}
                </StyledSpan>
              </div>
            ))}
          </ShowIfUtil>
          <ShowIfUtil condition={invalidTypeFileNames?.length > 0}>
            {_.map(invalidTypeFileNames, value => (
              <div className="text-center" key={value}>
                <StyledSpan
                  className="ep__txt--md ep__txt--required ep__txt-regular ep__txt-no-select"
                  font={fontText}
                  style={errorStyle}
                >
                  {`${value} ist ein ungültiger Dateityp`}
                </StyledSpan>
              </div>
            ))}
          </ShowIfUtil>
        </div>
      )}
    </div>
  );
};

UploadFileQuestionContainer.propTypes = {
  question: PropTypes.object,
  fontText: PropTypes.object,
  defaultTextColor: PropTypes.string
};

UploadFileQuestionContainer.defaultProps = {
  question: {},
  fontText: {},
  defaultTextColor: COLOR.DARK_GRAY
};

export default UploadFileQuestionContainer;

export const LIMIT_HEIGHT = {
  DEFAULT_PRODUCT_WRAPPER: '455px',
  DEFAULT_PACKAGE_WRAPPER: '455px',
  DEFAULT_WEBSHOPV2_WRAPPER: '498px',
  DEFAULT_TOTAL_AMOUNT_WRAPPER: '88px',
  DEFAULT_CUSTOMER_JOURNEY_HEIGHT: '594px'
};

export const PACKAGE_TYPE_WEBSHOP = {
  STANDARD: 'Standard',
  PREMIUM: 'Premium'
};

export const PRODUCT_AVAILABLE_STATUS = {
  DEACTIVATED: 'DEACTIVATED',
  ACTIVATED: 'ACTIVATED'
};

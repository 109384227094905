import _ from 'lodash';
import { SPECIAL_CHARACTER } from '../constants';

const formatAddress = (streetName, postalCode, city, country) => {
  let address = streetName ? streetName : SPECIAL_CHARACTER.EMPTY;
  const postcodeCity = (
    (postalCode ? postalCode : SPECIAL_CHARACTER.EMPTY) +
    SPECIAL_CHARACTER.SPACE +
    (city ? city : SPECIAL_CHARACTER.EMPTY)
  ).trim();
  const countryTmp = country ? country : SPECIAL_CHARACTER.EMPTY;
  // Add Postal code + City to ADDRESS
  if (postcodeCity && address) {
    address += SPECIAL_CHARACTER.COMMA + SPECIAL_CHARACTER.SPACE + postcodeCity;
  } else if (postcodeCity) {
    address += postcodeCity;
  }
  // Add Country to ADDRESS
  if (countryTmp && address) {
    address += SPECIAL_CHARACTER.COMMA + SPECIAL_CHARACTER.SPACE + countryTmp;
  } else if (countryTmp) {
    address += countryTmp;
  }

  return address;
};

const replaceShortCodeContent = (data, checkboxLinks, linkColor) => {
  const cloneData = _.assign({}, data);
  let contentHTML = cloneData.label;
  let contentNoHTML = cloneData.label;
  _.forEach(checkboxLinks, item => {
    if (contentHTML.indexOf(item.shortCode) > -1) {
      contentHTML = contentHTML
        .split(item.shortCode)
        .join(
          "<a class='ep__txt-bold' style ='font-family: inherit; color: " +
            linkColor +
            "' target='_blank' href='" +
            formatLink(item.linkUrl) +
            "'/>" +
            item.label +
            '</a>'
        );
    }
    if (contentNoHTML.indexOf(item.shortCode) > -1) {
      contentNoHTML = contentNoHTML.split(item.shortCode).join(item.label);
    }
  });
  return {
    contentHTML,
    contentNoHTML
  };
};

const formatLink = link => {
  if (!link.startsWith('http')) {
    link = '//' + link;
  }
  return link;
};

const getCustomerInfoFormatted = customerInfors => {
  let customerInfo = SPECIAL_CHARACTER.EMPTY;
  if (customerInfors.salutation && customerInfors.firstName && customerInfors.lastName) {
    customerInfo =
      customerInfo +
      customerInfors.salutation +
      SPECIAL_CHARACTER.SPACE +
      customerInfors.firstName +
      SPECIAL_CHARACTER.SPACE +
      customerInfors.lastName +
      SPECIAL_CHARACTER.COMMA +
      SPECIAL_CHARACTER.LINE_BREAK;
  }
  if (customerInfors.companyName) {
    customerInfo =
      customerInfo +
      customerInfors.companyName +
      SPECIAL_CHARACTER.COMMA +
      SPECIAL_CHARACTER.LINE_BREAK;
  }
  if (customerInfors.phone) {
    customerInfo =
      customerInfo + customerInfors.phone + SPECIAL_CHARACTER.COMMA + SPECIAL_CHARACTER.LINE_BREAK;
  }
  if (customerInfors.email) {
    customerInfo =
      customerInfo + customerInfors.email + SPECIAL_CHARACTER.COMMA + SPECIAL_CHARACTER.LINE_BREAK;
  }
  if (customerInfors.activeRegistrationDetails) {
    if (customerInfors.registerCourt) {
      customerInfo =
        customerInfo +
        customerInfors.registerCourt +
        SPECIAL_CHARACTER.COMMA +
        SPECIAL_CHARACTER.LINE_BREAK;
    }
    if (customerInfors.registerNumber) {
      customerInfo =
        customerInfo +
        customerInfors.registerNumber +
        SPECIAL_CHARACTER.COMMA +
        SPECIAL_CHARACTER.LINE_BREAK;
    }
  }

  return customerInfo;
};

export { formatAddress, replaceShortCodeContent, getCustomerInfoFormatted };

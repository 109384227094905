import {
  INTERNAL_PRODUCT_AVAILABILITY_SUCCESS,
  INTERNAL_PRODUCT_AVAILABILITY_FAILURE,
  INTERNAL_PRODUCT_AVAILABILITY_REQUEST,
  RESET_ANSWER_AFTER_CHANGE_PRIVATELY_OR_BUSINESS,
  RESET_CUSTOMER_JOURNEY_ANSWER,
  RESET_CUSTOMER_JOURNEY_DATA,
  RESET_EXTERNAL_PRODUCT_AVAILABILITY,
  UPDATE_CUSTOMER_JOURNEY_ANSWER
} from '../actions';
import { ALL_PRODUCT_TYPE, SPECIAL_CHARACTER } from '../../common/constants';

let initState = {
  name: SPECIAL_CHARACTER.EMPTY,
  householdSize: 1,
  autarky: SPECIAL_CHARACTER.EMPTY,
  electricCar: -1,
  electricCarUsage: SPECIAL_CHARACTER.EMPTY,
  electricCarChargeSpeed: -1,
  powerFlatRate: -1,
  usageOfGas: -1,
  outputOfHeatingKnowing: -1,
  outputHeating: SPECIAL_CHARACTER.EMPTY,
  whereToHeat: -1,
  heatingLivingSpace: SPECIAL_CHARACTER.EMPTY,
  heatingLivingSpaceIndex: -1,
  heatingBuiltYear: SPECIAL_CHARACTER.EMPTY,
  heatingBuiltYearIndex: -1,
  houseConnectionNewOrChange: -1,
  houseConnectionPrivatelyOrBusiness: -1,
  houseConnectionBuildingType: -1,
  houseConnectionUnits: 1,
  houseConnectionWarmWaterPreparation: -1,
  housingElectricityMaxPower: 0,
  houseConnectionCellar: -1,
  houseConnectionNumberOfApartment: SPECIAL_CHARACTER.EMPTY,
  houseConnectionElectricityMaxPower: SPECIAL_CHARACTER.EMPTY,
  houseConnectionGasMaxLoad: SPECIAL_CHARACTER.EMPTY,
  houseConnectionWaterMaxFlow: SPECIAL_CHARACTER.EMPTY,
  houseConnectionHeatingCapacity: SPECIAL_CHARACTER.EMPTY,
  houseConnectionIsOwner: -1,
  houseConnectionIsNewBuilding: -1,
  houseConnectionTrenchLength: 0,
  houseConnectionWaterStreetFrontLength: 0,
  houseConnectionWaterPropertySize: 0,
  houseConnectionWaterFloorSpace: 0,
  houseConnectionElectricityIsFeedIntoGrid: -1,
  houseConnectionElectricityFeedIntoGrid: 0,
  houseConnectionPeakWater: 0,
  houseConnectionIsExtinguishingWaterDemand: -1,
  houseConnectionExtinguishingWaterDemand: SPECIAL_CHARACTER.EMPTY,
  houseConnectionOutletPressureRequired: SPECIAL_CHARACTER.EMPTY,
  houseConnectionDefaultOutletPressureRequired: 0,
  consentForm: [],
  propertyDocument: [],
  fileUpload: [],
  consentFormError: SPECIAL_CHARACTER.EMPTY,
  propertyDocumentError: SPECIAL_CHARACTER.EMPTY,
  fileUploadError: SPECIAL_CHARACTER.EMPTY,
  postalCode: SPECIAL_CHARACTER.EMPTY,
  availableProduct: ALL_PRODUCT_TYPE,
  postalCodeMapped: false,
  postalCodePrefilled: false,
  isUserInputPostcode: false,
  chargePrivatelyOrBusiness: -1,
  chargeType: -1,
  chargeCarSpeed: -1,
  streetListed: true,
  address: {},
  deliveryAddressReadOnly: false,
  additionalPowerItems: [],
  totalAdditionalOutput: 0,
  installationDate: new Date(),
  datePickerItems: [],
  multipleChoiceItems: [],
  eMobilityPlan: -1,
  eMobilityPower: 0,
  eMobilityAgree: false,
  contactFormMessage: null,
  isDigMyOwnTrench: false,
  isShowPriceElectricity: false,
  digMyOwnTrench: SPECIAL_CHARACTER.EMPTY,
  houseConnectionBuildingTypeName: SPECIAL_CHARACTER.EMPTY,
  checkingInternalProductAvailability: false
};

export default (state = initState, action) => {
  switch (action.type) {
    case UPDATE_CUSTOMER_JOURNEY_ANSWER:
      return {
        ...state,
        ...action.payload
      };
    case INTERNAL_PRODUCT_AVAILABILITY_SUCCESS:
      return {
        ...state,
        availableProduct: action.payload,
        postalCodeMapped: true,
        checkingInternalProductAvailability: false
      };
    case INTERNAL_PRODUCT_AVAILABILITY_FAILURE:
      return {
        ...state,
        checkingInternalProductAvailability: false
      };
    case INTERNAL_PRODUCT_AVAILABILITY_REQUEST:
      return {
        ...state,
        checkingInternalProductAvailability: true
      };
    case RESET_ANSWER_AFTER_CHANGE_PRIVATELY_OR_BUSINESS:
      return {
        ...state,
        houseConnectionBuildingType: -1,
        houseConnectionUnits: 1,
        houseConnectionWarmWaterPreparation: -1,
        houseConnectionCellar: -1,
        houseConnectionElectricityMaxPower: SPECIAL_CHARACTER.EMPTY,
        houseConnectionGasMaxLoad: SPECIAL_CHARACTER.EMPTY,
        houseConnectionWaterMaxFlow: SPECIAL_CHARACTER.EMPTY,
        houseConnectionHeatingCapacity: SPECIAL_CHARACTER.EMPTY,
        houseConnectionIsOwner: -1,
        houseConnectionIsNewBuilding: -1,
        houseConnectionTrenchLength: SPECIAL_CHARACTER.EMPTY,
        houseConnectionWaterStreetFrontLength: 0,
        houseConnectionWaterPropertySize: 0,
        houseConnectionWaterFloorSpace: 0,
        houseConnectionElectricityIsFeedIntoGrid: -1,
        houseConnectionElectricityFeedIntoGrid: 0,
        houseConnectionPeakWater: 0,
        houseConnectionIsExtinguishingWaterDemand: -1,
        houseConnectionExtinguishingWaterDemand: SPECIAL_CHARACTER.EMPTY,
        houseConnectionOutletPressureRequired: state.houseConnectionDefaultOutletPressureRequired,
        consentForm: [],
        propertyDocument: [],
        additionalPowerItems: [],
        totalAdditionalOutput: 0,
        eMobilityPlan: -1,
        eMobilityPower: SPECIAL_CHARACTER.EMPTY,
        eMobilityAgree: false,
        installationDate: new Date(),
        datePickerItems: [],
        multipleChoiceItems: [],
        isDigMyOwnTrench: false,
        isShowPriceElectricity: false,
        digMyOwnTrench: SPECIAL_CHARACTER.EMPTY
      };
    case RESET_EXTERNAL_PRODUCT_AVAILABILITY:
      return {
        ...state,
        address: {
          ...state.address,
          lat: null,
          lng: null,
          country: null
        }
      };
    case RESET_CUSTOMER_JOURNEY_ANSWER:
    case RESET_CUSTOMER_JOURNEY_DATA:
      return initState;
    default:
      return state;
  }
};

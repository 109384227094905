import _ from 'lodash';
import { generatePackageConfig } from '../../store/reducers/productPackageConfigReducer';
import { METER_COMPONENT, PACKAGE_LABEL_TYPE, PRODUCT_TYPE } from '../constants';

export const filterSellingOptionByProductAndLabels = ({
  productPackageConfig,
  labels,
  providers,
  productTypes
}) => {
  const getLabelsCompare = productType => {
    let labelResult = [];
    if (
      labels[PACKAGE_LABEL_TYPE.E_MOBILITY] &&
      productType === PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY
    ) {
      labelResult.push(labels[PACKAGE_LABEL_TYPE.E_MOBILITY]);
    }
    if (labels[PACKAGE_LABEL_TYPE.TRENCH_LENGTH]) {
      labelResult.push(labels[PACKAGE_LABEL_TYPE.TRENCH_LENGTH]);
    }
    if (
      labels[PACKAGE_LABEL_TYPE.BROADBAND] &&
      productType === PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY
    ) {
      labelResult.push(labels[PACKAGE_LABEL_TYPE.BROADBAND]);
    }
    return labelResult;
  };

  let listPackageResult = {};
  _.forEach(productTypes, productType => {
    let packagesResult = [];
    if (productPackageConfig[productType]) {
      const labelComparison = getLabelsCompare(productType);
      const { sellingOptionMap, packageMap, packages } = productPackageConfig[productType];
      if (_.isEmpty(providers[productType]) && (_.isEmpty(labels) || _.isEmpty(labelComparison))) {
        packagesResult = _.filter(packages, elem => _.isEmpty(elem.labels));
      } else {
        let matchedPackage = null;
        _.forIn(sellingOptionMap, sellingOption => {
          if (!_.isEmpty(providers[productType])) {
            if (providers[productType] === sellingOption.packageProvider) {
              matchedPackage = packageMap[sellingOption.packageId];
            }
          }
          if (!_.isEmpty(providers[productType]) && matchedPackage) {
            const tmpLabels = _.map(matchedPackage.labels, elem => elem.label);
            if (!_.isEmpty(labelComparison)) {
              if (!_.isEqual(labelComparison.sort(), tmpLabels.sort())) {
                matchedPackage = null;
              }
            } else if (!_.isEmpty(tmpLabels)) {
              matchedPackage = null;
            }
          } else if (!_.isEmpty(providers[productType]) && !matchedPackage) {
            return true;
          } else {
            // no provider
            if (!_.isEmpty(labelComparison)) {
              if (_.isEqual(labelComparison.sort(), sellingOption.packageLabels.sort())) {
                matchedPackage = packageMap[sellingOption.packageId];
              }
            }
          }
          if (matchedPackage) {
            packagesResult.push(matchedPackage);
          }
        });
      }
    }
    packagesResult = _.uniqBy(packagesResult, 'id');
    listPackageResult = {
      ...listPackageResult,
      [productType]: {
        ...listPackageResult[productType],
        ...generatePackageConfig({ packages: packagesResult, productType })
      }
    };
  });

  return listPackageResult;
};

export const filterPackageWithoutMeterComponent = ({ currProductPackageConfig, products }) => {
  let _currProductPackageConfig = {};
  _.forEach(products, product => {
    if (product.isFarAwayProviderNode) {
      // remove meter component from package config if installation position too far away
      // provider node
      const { packages } = currProductPackageConfig[product.type];
      const _packages = [];

      _.forEach(packages, _package => {
        let isPackageHasMeterComp = false;
        _.forEach(_package.sellingOptions, _sellingOption => {
          if (
            _.find(
              _sellingOption.packagePricingComponents,
              _packagePricingComponent =>
                _packagePricingComponent.packagePricingComponentType === METER_COMPONENT
            )
          ) {
            isPackageHasMeterComp = true;
            return false;
          }
        });
        if (!isPackageHasMeterComp) {
          _packages.push(_package);
        }
      });

      _currProductPackageConfig = {
        ..._currProductPackageConfig,
        [product.type]: generatePackageConfig({ packages: _packages, productType: product.type })
      };
    }
  });
  return {
    ...currProductPackageConfig,
    ..._currProductPackageConfig
  };
};

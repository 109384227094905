import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { StyledSpan } from '../../epilot-forms';
import { GERMAN_TEXT, SPECIAL_CHARACTER } from '../../../common/constants';
import { formatPrice, ShowIfUtil } from '../../../common/utils';

const AddOnPrice = ({ fontText, textColor, oneTimePrice, monthlyPrice, yearlyPrice }) => {
  const isRenderOneTimePriceText = _.isNumber(oneTimePrice);
  const isRenderMonthlyPriceText = _.isNumber(monthlyPrice);

  const monthlyPriceClassName = classNames({
    'd-flex': true,
    'align-items-center': true,
    'justify-content-end': true,
    'col-12': isRenderOneTimePriceText,
    'col-4': !isRenderOneTimePriceText
  });

  const yearlyPriceClassName = classNames({
    'd-flex': true,
    'align-items-center': true,
    'justify-content-end': true,
    'col-12': isRenderOneTimePriceText || isRenderMonthlyPriceText,
    'col-4': !isRenderOneTimePriceText && !isRenderMonthlyPriceText
  });

  return (
    <>
      <ShowIfUtil condition={_.isNumber(oneTimePrice)}>
        <div className="col-4 d-flex align-items-center justify-content-end">
          <StyledSpan
            font={fontText}
            className="ep__txt--md text-right ep__txt-no-select ep-ellipsis"
            style={{ color: textColor }}
          >
            {`${oneTimePrice < 0 ? SPECIAL_CHARACTER.MINUS : ''} ${formatPrice(
              Math.abs(oneTimePrice)
            )}`}
          </StyledSpan>
        </div>
      </ShowIfUtil>
      <ShowIfUtil condition={_.isNumber(monthlyPrice)}>
        <div className={monthlyPriceClassName}>
          <StyledSpan
            font={fontText}
            className="ep__txt--md text-right ep__txt-no-select ep-ellipsis"
            style={{ color: textColor }}
          >
            {`${
              monthlyPrice >= 0
                ? isRenderOneTimePriceText
                  ? SPECIAL_CHARACTER.PLUS
                  : ''
                : SPECIAL_CHARACTER.MINUS
            } ${formatPrice(Math.abs(monthlyPrice))} ${GERMAN_TEXT.MONTHLY}`}
          </StyledSpan>
        </div>
      </ShowIfUtil>
      <ShowIfUtil condition={_.isNumber(yearlyPrice)}>
        <div className={yearlyPriceClassName}>
          <StyledSpan
            font={fontText}
            className="ep__txt--md text-right ep__txt-no-select ep-ellipsis"
            style={{ color: textColor }}
          >
            {`${
              yearlyPrice >= 0
                ? isRenderOneTimePriceText || isRenderMonthlyPriceText
                  ? SPECIAL_CHARACTER.PLUS
                  : ''
                : SPECIAL_CHARACTER.MINUS
            } ${formatPrice(Math.abs(yearlyPrice))} ${GERMAN_TEXT.YEARLY}`}
          </StyledSpan>
        </div>
      </ShowIfUtil>
    </>
  );
};

AddOnPrice.propTypes = {
  fontText: PropTypes.object.isRequired,
  textColor: PropTypes.string.isRequired,
  oneTimePrice: PropTypes.number,
  monthlyPrice: PropTypes.number,
  yearlyPrice: PropTypes.number
};

AddOnPrice.defaultProps = {
  oneTimePrice: null,
  monthlyPrice: null,
  yearlyPrice: null
};

export default memo(AddOnPrice);

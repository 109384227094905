import React, { Component } from 'react';
import { Suggest } from '@blueprintjs/select';
import { MenuItem } from '@blueprintjs/core';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { COLOR, MESSAGE_CONSTANTS, SPECIAL_CHARACTER } from '../../../common/constants';
import { ShowIfUtil } from '../../../common/utils';
import { StyledLabel, StyledSpan } from './StyledElement';

const StyledSuggestAutocomplete = styled.div`
  font-family: ${props => props.children[0].props.font.font} !important;
`;

const StyledMenuItem = styled(MenuItem)`
  color: ${props => props.color};
  &.pt-active {
    color: ${props => props.color} !important;
  }
`;

class PostcodeAutoComplete extends Component {
  constructor(props) {
    super(props);
    const { selectedValue } = this.props;
    this.state = {
      value: selectedValue,
      isValid: true
    };
  }

  componentWillReceiveProps(nextProps) {
    const { selectedValue } = this.props;
    if (selectedValue !== nextProps.selectedValue) {
      this.setState({ value: nextProps.selectedValue });
    }
  }

  onItemSelect = item => {
    const { onItemSelect } = this.props;
    if (item) {
      this.setState({
        value: item
      });
      onItemSelect(item);
    }
  };

  onFilter = (query, items) => {
    const { customFilter, itemsLimited } = this.props;
    if (customFilter) {
      return customFilter(query, items);
    }
    if (itemsLimited) {
      return items
        .filter(item => item.toLowerCase().indexOf(query.toLowerCase()) >= 0)
        .slice(0, 50);
    }
    return items.filter(item => item.toLowerCase().indexOf(query.toLowerCase()) >= 0);
  };

  onChange = e => this.setState({ value: e.target.value });

  popoverOnClose = () => {
    const { options, selectedValue, onItemSelect } = this.props;
    const { value } = this.state;
    if (!value || value?.trim() === '') return;

    if (selectedValue?.toLowerCase() === value.toLowerCase()) {
      return;
    }

    for (const option of options) {
      if (!isNaN(Number(value.trim())) && option?.toLowerCase().includes(value.toLowerCase())) {
        this.onItemSelect(value);
        return;
      }

      if (option?.toLowerCase() === value.toLowerCase()) {
        this.onItemSelect(option);
        return;
      }
    }

    onItemSelect(value, true);
  };

  renderDataItem = (item, { handleClick, modifiers }) => {
    const { defaultTextColor } = this.props;

    return (
      <div style={{ color: defaultTextColor }} key={item}>
        <StyledMenuItem
          active={modifiers.active}
          key={item}
          onClick={handleClick}
          text={item}
          color={defaultTextColor}
        />
      </div>
    );
  };

  render() {
    const {
      disabled,
      fontFamily,
      inputStyle,
      classStyle,
      label,
      require,
      initialContent,
      options,
      placeholder,
      fontSize,
      defaultTextColor,
      isAddress
    } = this.props;
    const { isValid, value } = this.state;

    const textColor = {
      color: defaultTextColor
    };

    return (
      <div className={classStyle}>
        {/*----- AUTOCOMPLETE LABEL -----*/}
        <ShowIfUtil condition={label !== SPECIAL_CHARACTER.EMPTY}>
          <StyledLabel
            className="ep__txt--sm ep__txt-regular ep__txt-no-select"
            font={fontFamily}
            style={{ color: defaultTextColor }}
          >
            {label}
            <ShowIfUtil condition={require}>
              <StyledSpan
                className="ep__txt--sm ep__txt-regular ep__txt-no-select"
                font={fontFamily}
                style={{ color: defaultTextColor }}
              >
                {' *'}
              </StyledSpan>
            </ShowIfUtil>
          </StyledLabel>
        </ShowIfUtil>

        {/*----- AUTOCOMPLETE -----*/}
        <ShowIfUtil condition={!!initialContent}>
          <StyledSuggestAutocomplete>
            <Suggest
              disabled={disabled}
              font={fontFamily}
              noResults={
                <MenuItem
                  disabled
                  text={
                    isAddress === true ? MESSAGE_CONSTANTS.STREET_NOT_FOUND : 'Keine Ergebnisse'
                  }
                />
              }
              initialContent={<MenuItem disabled text={initialContent} />}
              className="ep-autocomplete ep-custom-autocomplete"
              inputValueRenderer={item => item}
              itemRenderer={this.renderDataItem}
              items={options}
              onItemSelect={this.onItemSelect}
              itemListPredicate={this.onFilter}
              inputProps={{
                placeholder: placeholder,
                onChange: this.onChange,
                className: 'ep-autocomplete-input',
                style: {
                  ...inputStyle,
                  fontSize,
                  ...textColor
                },
                fontSize,
                value
              }}
              popoverProps={{
                popoverClassName: 'autocomplete-popover-custom',
                usePortal: false,
                popoverDidClose: this.popoverOnClose
              }}
            />

            {/*----- ERROR MESSAGE -----*/}
            <ShowIfUtil condition={!isValid && value.trim() === SPECIAL_CHARACTER.EMPTY}>
              <div className="">{MESSAGE_CONSTANTS.REQUIRED}</div>
            </ShowIfUtil>
          </StyledSuggestAutocomplete>
        </ShowIfUtil>
        <ShowIfUtil condition={!initialContent}>
          <StyledSuggestAutocomplete>
            <Suggest
              font={fontFamily}
              noResults={
                <MenuItem
                  disabled
                  text={
                    isAddress === true ? MESSAGE_CONSTANTS.STREET_NOT_FOUND : 'Keine Ergebnisse'
                  }
                />
              }
              className="ep-autocomplete ep-custom-autocomplete"
              inputValueRenderer={item => item}
              itemRenderer={this.renderDataItem}
              items={options}
              onItemSelect={this.onItemSelect}
              itemListPredicate={this.onFilter}
              inputProps={{
                autoComplete: 'disabled',
                disabled: disabled,
                placeholder: placeholder,
                onChange: this.onChange,
                className: 'ep-autocomplete-input',
                style: {
                  ...inputStyle,
                  fontSize,
                  textColor
                },
                value
              }}
              popoverProps={{
                popoverClassName: 'autocomplete-popover-custom',
                usePortal: false,
                onClose: this.popoverOnClose
              }}
            />

            {/*----- ERROR MESSAGE -----*/}
            <ShowIfUtil condition={!isValid && value.trim() === SPECIAL_CHARACTER.EMPTY}>
              <div className="">{MESSAGE_CONSTANTS.REQUIRED}</div>
            </ShowIfUtil>
          </StyledSuggestAutocomplete>
        </ShowIfUtil>
      </div>
    );
  }
}

PostcodeAutoComplete.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  require: PropTypes.bool,
  classStyle: PropTypes.string,
  defaultTextColor: PropTypes.string,
  fontFamily: PropTypes.object,
  options: PropTypes.array,
  selectedValue: PropTypes.string,
  onItemSelect: PropTypes.func,
  inputStyle: PropTypes.object,
  customFilter: PropTypes.func,
  initialContent: PropTypes.string,
  fontSize: PropTypes.string,
  itemsLimited: PropTypes.bool,
  isAddress: PropTypes.bool
};

PostcodeAutoComplete.defaultProps = {
  disabled: false,
  label: SPECIAL_CHARACTER.EMPTY,
  placeholder: SPECIAL_CHARACTER.EMPTY,
  require: false,
  classStyle: SPECIAL_CHARACTER.EMPTY,
  defaultTextColor: COLOR.DARK_GRAY,
  options: [],
  selectedValue: SPECIAL_CHARACTER.EMPTY,
  fontFamily: {},
  onItemSelect: () => ({}),
  inputStyle: {},
  customFilter: undefined,
  fontSize: '14px',
  initialContent: SPECIAL_CHARACTER.EMPTY,
  itemsLimited: false,
  isAddress: false
};

export default PostcodeAutoComplete;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { Position, Tab, Tabs, Tooltip } from '@blueprintjs/core';
import {
  convertProdTypeToProdName,
  ScrollUtil,
  ShowIconByProductTypeUtil,
  ShowIfUtil
} from '../../common/utils';
import {
  COLOR,
  PACKAGE_TYPE_WEBSHOP,
  SPECIAL_CHARACTER,
  STATUS,
  LIMIT_HEIGHT,
  GERMAN_TEXT
} from '../../common/constants';
import { StyledSpan, StyledSpanBold } from '../../components/epilot-forms/common';
import { Package } from '.';

class ProductPackageContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: PACKAGE_TYPE_WEBSHOP.STANDARD,
      numberOfPremiumPackage: 0,
      componentHeight: LIMIT_HEIGHT.DEFAULT_PACKAGE_WRAPPER
    };
  }

  componentDidMount() {
    const { numberOfPremiumPackage } = this.state;
    const { product } = this.props;
    this.setHeight();
    this.handleWindowResize();
    _.map(product.packages, productPackage => {
      if (productPackage.activatePremium) {
        this.setState({ numberOfPremiumPackage: numberOfPremiumPackage + 1 });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    const { customerJourneyAnswer, product } = this.props;
    if (nextProps.customerJourneyAnswer !== customerJourneyAnswer) {
      if (customerJourneyAnswer.availableProduct.indexOf(product.type) < 0) {
        this.showProductComponent();
      }
    }
  }

  componentDidUpdate() {
    const { componentHeight } = this.state;
    parseInt(componentHeight, 10) < 455
      ? componentHeight.toString()
      : // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ componentHeight: `${305 + 140}px` });
  }

  setHeight = () => {
    const { selectedTab } = this.state;
    const { packageInfoHeight } = this.props;
    if (packageInfoHeight === SPECIAL_CHARACTER.EMPTY) {
      if (this.standard && selectedTab === PACKAGE_TYPE_WEBSHOP.STANDARD) {
        this.setState({
          componentHeight: `${this.standard.clientHeight + 140}px`
        });
      }

      if (this.premium && selectedTab === PACKAGE_TYPE_WEBSHOP.PREMIUM) {
        this.setState({
          componentHeight: `${this.premium.clientHeight + 140}px`
        });
      }
    } else {
      this.setState({
        componentHeight: `calc(${packageInfoHeight} - 10px)`
      });
    }
  };

  showProductComponent = () => {
    const { showProductComponent } = this.props;
    showProductComponent();
  };

  handleTabChange = e => {
    this.setState({ selectedTab: e });
  };

  handleWindowResize = () => {
    let resizeTimeout;
    window.addEventListener('resize', () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(this.setHeight, 30);
    });
  };

  generatePremiumPackages = () => {
    const {
      product,
      selectedPackage,
      isSelectedPackagePremiumType,
      fontText,
      fontTextBold
    } = this.props;

    return _.map(product.packages, productPackage => {
      const isSelectedPackage =
        productPackage.id === selectedPackage.id && isSelectedPackagePremiumType;

      return (
        <ShowIfUtil condition={productPackage.activatePremium} key={productPackage.id}>
          <Package
            product={product}
            productPackage={productPackage}
            isPremiumType
            selectedPackage={selectedPackage}
            isSelectedPackage={isSelectedPackage}
            fontText={fontText}
            fontTextBold={fontTextBold}
          />
        </ShowIfUtil>
      );
    });
  };

  generateStandardPackages = () => {
    const {
      selectedPackage,
      product,
      isSelectedPackagePremiumType,
      fontText,
      fontTextBold
    } = this.props;
    return product.packages.map(productPackage => {
      const isSelectedPackage =
        productPackage.id === selectedPackage.id && !isSelectedPackagePremiumType;

      return (
        <ShowIfUtil condition={productPackage.status === STATUS.ACTIVE} key={productPackage.id}>
          <Package
            product={product}
            productPackage={productPackage}
            isPremiumType={false}
            selectedPackage={selectedPackage}
            isSelectedPackage={isSelectedPackage}
            fontText={fontText}
            fontTextBold={fontTextBold}
          />
        </ShowIfUtil>
      );
    });
  };

  render() {
    const { selectedTab, componentHeight, numberOfPremiumPackage } = this.state;
    const { fontText, fontTextBold, frontendConfiguration, product } = this.props;

    const textColor = {
      color: frontendConfiguration.primaryTextColor
    };

    return (
      <div
        className="ep-webshop-selectpackage-wrapper ep--mx-8"
        style={{ borderRadius: frontendConfiguration.borderRadiusMax4px }}
      >
        <div
          className="ep-webshop-selectpackage-header d-flex align-items-center"
          style={{
            background: frontendConfiguration.primaryColor,
            borderTopLeftRadius: frontendConfiguration.borderRadiusMax4px,
            borderTopRightRadius: frontendConfiguration.borderRadiusMax4px
          }}
        >
          <i
            className="ep-icon-circle-left-38 ep__txt--h1 ep-cursor-pointer ep--ml-16"
            style={textColor}
            onClick={this.showProductComponent}
          />

          <Tooltip
            content={
              <StyledSpan font={fontText}>{convertProdTypeToProdName(product.type)}</StyledSpan>
            }
            position={Position.BOTTOM}
            className="ep--ml-16"
            tooltipClassName="ep-tooltip"
          >
            <ShowIconByProductTypeUtil
              color={frontendConfiguration.primaryTextColor}
              productType={product.type}
            />
          </Tooltip>

          <StyledSpan font={fontText} className="ep__txt--h2 ep--ml-16" style={textColor}>
            {GERMAN_TEXT.SELECT_A_PACKAGE}
          </StyledSpan>
        </div>

        <div className="clearfix" style={{ backgroundColor: frontendConfiguration.primaryColor }} />

        <Tabs
          className={numberOfPremiumPackage > 0 ? 'ep-tabs' : 'ep-tabs-1'}
          onChange={this.handleTabChange}
          selectedTabId={selectedTab}
          style={{ backgroundColor: frontendConfiguration.primaryColor }}
        >
          <Tab
            id={PACKAGE_TYPE_WEBSHOP.STANDARD}
            title={
              <div
                style={{
                  backgroundColor: frontendConfiguration.primaryColor,
                  height: 27,
                  marginBottom: 3
                }}
              >
                <StyledSpanBold
                  font={fontTextBold}
                  className="ep__txt--md ep__txt-bold"
                  style={textColor}
                >
                  {'Standard'}
                </StyledSpanBold>
              </div>
            }
            panel={
              <ScrollUtil height={`calc(${componentHeight} - 106px)`} showShadowBottom>
                <div
                  ref={standard => {
                    this.standard = standard;
                  }}
                  style={{ backgroundColor: COLOR.LIGHT_GRAY }}
                >
                  {this.generateStandardPackages()}
                </div>
              </ScrollUtil>
            }
          />

          {numberOfPremiumPackage > 0 ? (
            <Tab
              id={PACKAGE_TYPE_WEBSHOP.PREMIUM}
              title={
                <div
                  style={{
                    backgroundColor: frontendConfiguration.primaryColor,
                    height: 27,
                    marginBottom: 3
                  }}
                >
                  <StyledSpanBold
                    font={fontTextBold}
                    className="ep__txt--md ep__txt-bold"
                    style={textColor}
                  >
                    {`Premium`}
                  </StyledSpanBold>
                </div>
              }
              panel={
                <ScrollUtil height={`calc(${componentHeight} - 106px)`} showShadowBottom>
                  <div
                    ref={premium => {
                      this.premium = premium;
                    }}
                    style={{ backgroundColor: COLOR.LIGHT_GRAY }}
                  >
                    {this.generatePremiumPackages()}
                  </div>
                </ScrollUtil>
              }
            />
          ) : (
            SPECIAL_CHARACTER.EMPTY
          )}
        </Tabs>
      </div>
    );
  }
}

ProductPackageContainer.propTypes = {
  product: PropTypes.object,
  selectedPackage: PropTypes.object,
  isSelectedPackagePremiumType: PropTypes.bool,
  showProductComponent: PropTypes.func,
  fontText: PropTypes.object,
  fontTextBold: PropTypes.object,
  packageInfoHeight: PropTypes.string,
  customerJourneyAnswer: PropTypes.object,
  frontendConfiguration: PropTypes.object
};

ProductPackageContainer.defaultProps = {
  fontText: {},
  fontTextBold: {},
  product: {},
  selectedPackage: {},
  customerJourneyAnswer: {},
  frontendConfiguration: {},
  isSelectedPackagePremiumType: false,
  packageInfoHeight: SPECIAL_CHARACTER.EMPTY,
  showProductComponent: () => ({})
};

function mapStateToProps(state) {
  return {
    frontendConfiguration: state.frontendConfiguration,
    customerJourneyAnswer: state.customerJourneyAnswer,
    customerJourneyMapping: state.customerJourneyMapping
  };
}

export default connect(mapStateToProps)(ProductPackageContainer);

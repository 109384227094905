import React, { Component } from 'react';
import { Checkbox } from '@blueprintjs/core';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { StyledSpan } from './StyledElement';
import { COLOR, MESSAGE_CONSTANTS, SPECIAL_CHARACTER } from '../../../common/constants';
import { ShowIfUtil } from '../../../common/utils';

const CheckboxCustom = styled.div`
  .pt-control-indicator {
    border: 1px solid ${COLOR.LIGHT_GRAY_1} !important;
    color: ${props =>
      props.checkIconColor !== '#ffffff' ? props.checkIconColor : COLOR.BLACK} !important;
    margin-top: 3px;
  }
`;

class CheckBox extends Component {
  constructor(props) {
    super(props);
    const { isChecked } = this.props;
    this.state = {
      checked: isChecked
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ checked: nextProps.isChecked });
  }

  onChange = e => {
    const { checked } = this.state;
    const { onChange } = this.props;

    this.setState({
      checked: !checked
    });

    onChange(e);
  };

  render() {
    const {
      className,
      name,
      disabled,
      fontText,
      defaultTextColor,
      content,
      primaryCheckBoxIconColor,
      require,
      policiesCheckboxChecked,
      otherCheckboxesChecked
    } = this.props;
    const { checked } = this.state;
    return (
      <div className={className}>
        <CheckboxCustom checkIconColor={primaryCheckBoxIconColor}>
          <Checkbox
            className="ep--mb-0 ep__txt--sm ep__txt-regular"
            name={name}
            checked={checked}
            disabled={disabled}
            onChange={this.onChange}
          >
            <StyledSpan
              font={fontText}
              className="ep__txt--md ep__txt-regular"
              style={{ color: defaultTextColor }}
            >
              {ReactHtmlParser(content)}
            </StyledSpan>
            <ShowIfUtil condition={require}>
              <StyledSpan
                className="ep__txt--sm ep__txt-regular ep__txt-no-select"
                font={fontText}
                style={{ color: defaultTextColor }}
              >
                {' *'}
              </StyledSpan>
            </ShowIfUtil>
            <ShowIfUtil condition={policiesCheckboxChecked || otherCheckboxesChecked}>
              <div>
                <StyledSpan
                  font={fontText}
                  className="ep__txt--required ep__txt-regular ep__txt-no-select"
                  style={{ color: COLOR.ERROR_TEXT_COLOR }}
                >
                  {MESSAGE_CONSTANTS.REQUIRED}
                </StyledSpan>
              </div>
            </ShowIfUtil>
          </Checkbox>
        </CheckboxCustom>
      </div>
    );
  }
}

CheckBox.propTypes = {
  className: PropTypes.string,
  isChecked: PropTypes.bool,
  content: PropTypes.string,
  name: PropTypes.string,
  defaultTextColor: PropTypes.string,
  primaryCheckBoxIconColor: PropTypes.string.isRequired,
  fontText: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  require: PropTypes.bool,
  policiesCheckboxChecked: PropTypes.bool,
  otherCheckboxesChecked: PropTypes.bool
};

CheckBox.defaultProps = {
  className: SPECIAL_CHARACTER.EMPTY,
  isChecked: false,
  content: SPECIAL_CHARACTER.EMPTY,
  name: SPECIAL_CHARACTER.EMPTY,
  defaultTextColor: COLOR.DARK_GRAY,
  fontText: {},
  disabled: false,
  onChange: function() {},
  require: false,
  policiesCheckboxChecked: false,
  otherCheckboxesChecked: false
};

export default CheckBox;

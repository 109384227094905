/* ----- Importing libraries -----*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { StyledSpan, StyledP } from '..';
import { COLOR, SPECIAL_CHARACTER } from '../../common/constants';
import { getFontTextBoldSelector, getFontTextSelector } from '../../store/selectors';

class CancelCustomerJourney extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fontText: {
        font: SPECIAL_CHARACTER.EMPTY,
        fontUrl: SPECIAL_CHARACTER.EMPTY
      },
      fontTextBold: {
        font: SPECIAL_CHARACTER.EMPTY,
        fontUrl: SPECIAL_CHARACTER.EMPTY
      }
    };
  }

  componentDidMount() {
    const { fontText, fontTextBold } = this.props;
    // Custom font
    this.setState(prevState => ({
      fontText: {
        ...prevState.fontText,
        ...fontText
      }
    }));

    this.setState(prevState => ({
      fontTextBold: {
        ...prevState.fontTextBold,
        font: fontTextBold.font
      }
    }));
  }

  render() {
    const { fontText, fontTextBold } = this.state;
    const { frontendConfiguration, previousStep, cancelScreenInfo } = this.props;
    const primaryColor = {
      color: frontendConfiguration ? frontendConfiguration.primaryColor : COLOR.DEFAULT_BLUE
    };
    return (
      <div className="text-center ep--p-32" style={{ backgroundColor: COLOR.WHITE }}>
        <div className="ep-success-wrapper-icon">
          <i className="epc-icon-construction-sign" style={primaryColor} />
        </div>
        <StyledP
          font={fontTextBold}
          className="ep__txt--h1 ep__txt-semibold text-center ep--mt-32 ep--mb-0 ep-text-black"
        >
          {cancelScreenInfo.title}
        </StyledP>
        <div className="text-center ep--mt-32">
          <StyledSpan
            font={fontText}
            className="ep__txt--md ep__txt-semibold ep-text-light-black ep--mb-0 d-inline-block"
            style={{ maxWidth: '26.875rem' }}
          >
            {cancelScreenInfo.body}
          </StyledSpan>
        </div>
        <div className="text-center ep--mt-32">
          <StyledSpan
            font={fontTextBold}
            className="ep__txt--sm ep__txt-semibold ep-text-dark-blue ep-cursor-pointer"
            onClick={previousStep}
          >
            {cancelScreenInfo.hyperlinkText}
          </StyledSpan>
        </div>
      </div>
    );
  }
}

CancelCustomerJourney.propTypes = {
  fontText: PropTypes.object,
  fontTextBold: PropTypes.object,
  frontendConfiguration: PropTypes.object,
  cancelScreenInfo: PropTypes.object,
  previousStep: PropTypes.func
};

CancelCustomerJourney.defaultProps = {
  fontText: {},
  fontTextBold: {},
  frontendConfiguration: {},
  cancelScreenInfo: {},
  previousStep: () => ({})
};

function mapStateToProps(state) {
  return {
    fontText: getFontTextSelector(state),
    fontTextBold: getFontTextBoldSelector(state),
    frontendConfiguration: state.frontendConfiguration
  };
}

export default connect(mapStateToProps)(CancelCustomerJourney);

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { Slider } from '@blueprintjs/core';
import { COLOR, PRODUCT_TYPE, QUESTION_ID, SPECIAL_CHARACTER, UNIT } from '../../common/constants';
import {
  updateCustomerJourneyAnswer,
  updateCustomerJourneyMapping,
  updateProductSelections
} from '../../store/actions';
import { StyledSpan, InfoHelpDescription } from '../../components/epilot-forms/common';
import {
  formatQuestion,
  hexaToRGBA,
  removeAddon,
  ShowIconByQuestionIdUtil
} from '../../common/utils';
import { productSelectionsSelector } from '../../store/selectors';

class SliderQuestionContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      answer: 0,
      min: 0,
      max: 0,
      increaseNumber: 1,
      unit: SPECIAL_CHARACTER.EMPTY
    };
  }

  componentDidMount() {
    const { question, customerJourneyAnswer, frontendConfiguration } = this.props;
    this.setState({
      min: question.objectModel.minValue,
      max: question.objectModel.maxValue
    });

    if (question.id === QUESTION_ID.ELECTRIC_CAR_USAGE) {
      this.setState({
        answer: customerJourneyAnswer.electricCarUsage,
        increaseNumber: question.objectModel.increaseNumber,
        unit: SPECIAL_CHARACTER.SPACE + UNIT.KILOMETER
      });
    }

    if (question.id === QUESTION_ID.AUTARKY) {
      this.setState({
        answer: customerJourneyAnswer.autarky,
        increaseNumber: 1,
        unit: UNIT.PERCENT
      });
    }
    setTimeout(() => {
      document.getElementsByClassName('pt-slider-handle')[0].style.backgroundColor =
        frontendConfiguration.primaryColor;
      document.getElementsByClassName('pt-slider-handle')[0].style.backgroundImage = 'none';
      document.getElementsByClassName('pt-slider-progress')[0].style.backgroundColor =
        frontendConfiguration.primaryColor;
    }, 1);
  }

  getChangeHandler = key => {
    return value => {
      this.setState({ [key]: value });
      const {
        customerJourneyAnswer,
        customerJourneyMapping,
        productSelections,
        frontendConfiguration,
        question,
        updateCustomerJourneyAnswer,
        updateCustomerJourneyMapping,
        updateProductSelections
      } = this.props;
      let cloneProductSelections = _.assign({}, productSelections);
      let newProductSelections = {
        selectedAddonIds: []
      };

      let newCustomerJourneyAnswer = _.assign({}, customerJourneyAnswer);
      let newCustomerJourneyMapping = _.assign({}, customerJourneyMapping);

      if (question.id === QUESTION_ID.ELECTRIC_CAR_USAGE) {
        newCustomerJourneyAnswer.electricCarUsage = value;
        newCustomerJourneyMapping.electricCarUsageConsumption = Number(
          question.objectModel.items[value]
        );
        newCustomerJourneyMapping.totalConsumption =
          newCustomerJourneyMapping.electricCarUsageConsumption +
          newCustomerJourneyMapping.householdConsumption;
      }

      if (question.id === QUESTION_ID.AUTARKY) {
        newCustomerJourneyAnswer.autarky = value;
        newCustomerJourneyMapping.selectStorage = value >= question.objectModel.value;
        if (!newCustomerJourneyMapping.selectStorage) {
          _.forEach(frontendConfiguration.products, product => {
            if (product.type === PRODUCT_TYPE.STORAGE) {
              if (productSelections.selectedAddonIds) {
                newProductSelections.selectedAddonIds = removeAddon(
                  product,
                  cloneProductSelections
                );
              }
            }
          });
        }
      }
      updateCustomerJourneyAnswer(newCustomerJourneyAnswer);
      updateCustomerJourneyMapping(newCustomerJourneyMapping);
      updateProductSelections(newProductSelections);
    };
  };

  formatNumber = value => {
    return value.toLocaleString(undefined, {
      maximumFractionDigits: 0
    });
  };

  render() {
    const { min, max, increaseNumber, answer, unit } = this.state;
    const {
      customerJourneyAnswer,
      frontendConfiguration,
      question,
      fontText,
      defaultTextColor
    } = this.props;
    const textColor = {
      color: defaultTextColor
    };

    return (
      <div className="ep-customerjourneypage__question ep__txt-no-select">
        <ShowIconByQuestionIdUtil
          questionId={question.id}
          colorIcon={frontendConfiguration.primaryColor}
        />

        <div className="text-center ep-min-height-70 ep--pt-8 ep--pb-16">
          <StyledSpan font={fontText} className="ep__txt-regular ep__txt--lg" style={textColor}>
            {formatQuestion(question.questionTitle, customerJourneyAnswer.name)}
          </StyledSpan>
          <InfoHelpDescription
            showHelpDescription={question.showHelpDescription}
            tooltipInfoContent={<StyledSpan font={fontText}>{question.helpDescription}</StyledSpan>}
            classNameForInfoIcon="ep__txt--lg ep--ml-8"
            defaultTextColor={defaultTextColor}
          />
        </div>

        <div className="ep-min-height-130">
          <div className="row justify-content-center">
            <div className="col-2 d-none d-md-block">
              <StyledSpan
                className="ep__txt--md ep__txt-regular ep-line-height-32"
                font={fontText}
                style={textColor}
              >
                {this.formatNumber(min) +
                  (this.formatNumber(min) === 0 ? SPECIAL_CHARACTER.EMPTY : unit.trim())}
              </StyledSpan>
            </div>
            <div className="col-8 p-0">
              <div
                style={{
                  background: hexaToRGBA(frontendConfiguration.primaryColor, 0.08),
                  paddingTop: '0.8rem',
                  paddingBottom: '0.8rem',
                  paddingRight: '0.8rem',
                  paddingLeft: '0.8rem',
                  borderRadius: frontendConfiguration.borderRadius
                }}
              >
                <Slider
                  className="ep-slider"
                  min={min}
                  max={max}
                  stepSize={increaseNumber}
                  labelRenderer={false}
                  onChange={this.getChangeHandler('answer')}
                  value={answer}
                />
              </div>
            </div>
            <div className="col-2 d-none d-md-block">
              <StyledSpan
                className="ep__txt--md ep__txt-regular ep-line-height-32"
                font={fontText}
                style={textColor}
              >
                {this.formatNumber(max) + unit.trim()}
              </StyledSpan>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <StyledSpan
              className="ep__txt--md ep__txt-regular ep-line-height-32 ep__txt-no-select"
              font={fontText}
              style={textColor}
            >
              {this.formatNumber(answer) + unit.trim()}
            </StyledSpan>
          </div>
        </div>
      </div>
    );
  }
}

SliderQuestionContainer.propTypes = {
  question: PropTypes.object,
  fontText: PropTypes.object,
  defaultTextColor: PropTypes.string,
  frontendConfiguration: PropTypes.object,
  customerJourneyMapping: PropTypes.object,
  customerJourneyAnswer: PropTypes.object,
  productSelections: PropTypes.object,
  updateCustomerJourneyAnswer: PropTypes.func,
  updateCustomerJourneyMapping: PropTypes.func,
  updateProductSelections: PropTypes.func
};

SliderQuestionContainer.defaultProps = {
  question: {},
  fontText: {},
  defaultTextColor: COLOR.DARK_GRAY,
  frontendConfiguration: {},
  customerJourneyMapping: {},
  customerJourneyAnswer: {},
  productSelections: {},
  updateCustomerJourneyAnswer: () => ({}),
  updateCustomerJourneyMapping: () => ({}),
  updateProductSelections: () => ({})
};

function mapStateToProps(state) {
  return {
    frontendConfiguration: state.frontendConfiguration,
    customerJourneyAnswer: state.customerJourneyAnswer,
    customerJourneyMapping: state.customerJourneyMapping,
    productSelections: productSelectionsSelector(state)
  };
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateCustomerJourneyAnswer,
        updateCustomerJourneyMapping,
        updateProductSelections
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SliderQuestionContainer);

import { createSelector } from 'reselect';
import _ from 'lodash';
import { productsSelector } from '.';
import { PRODUCT_AVAILABLE_STATUS } from '../../common/constants';

const getProducts = state => productsSelector(state);

export const isAllProductsDeactivatedSelector = createSelector(
  [getProducts],
  products => {
    return _.every(products, { availableStatus: PRODUCT_AVAILABLE_STATUS.DEACTIVATED });
  }
);

export const isAllProductsOnRequestSelector = createSelector(
  [getProducts],
  products => {
    return _.every(products, { showOnRequest: true });
  }
);

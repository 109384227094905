import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { registerSelectors } from 'reselect-tools';
import rootReducer from './reducers';
import customMiddleWare from './middlewares/customMiddleware';
import * as selectors from './selectors';

registerSelectors(selectors);

const configureStore = () => {
  let store;
  if (process.env.NODE_ENV !== 'production') {
    store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk, customMiddleWare)));
    if (module.hot) {
      module.hot.accept('./reducers', () => {
        store.replaceReducer(rootReducer);
      });
    }
  } else {
    store = createStore(rootReducer, applyMiddleware(thunk, customMiddleWare));
  }
  return store;
};

export default configureStore;

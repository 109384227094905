import _ from 'lodash';
import { ADD_ON_V2_PRODUCT_TYPE, PAYMENT_FREQUENCY } from '../constants';

const getSubTotalPrice = (productPackagesSummary, addOnV2NetTotalPrice) => {
  let oneTimePrice;
  let monthlyPrice;
  let yearlyPrice;
  let isOnRequest = true;

  ({ oneTimePrice, monthlyPrice, yearlyPrice } = _.reduce(
    productPackagesSummary,
    (result, value) => {
      if (!value.onRequest && !value.alwaysShowOnRequest) {
        if (value.totalOneTimeNetRetailPrice !== null) {
          result.oneTimePrice += value.totalOneTimeNetRetailPrice;
        }
        if (value.totalMonthlyNetRetailPrice !== null) {
          result.monthlyPrice += value.totalMonthlyNetRetailPrice;
        }
        if (value.totalYearlyNetRetailPrice !== null) {
          result.yearlyPrice += value.totalYearlyNetRetailPrice;
        }
        isOnRequest = false;
      }
      return result;
    },
    {
      oneTimePrice: null,
      monthlyPrice: null,
      yearlyPrice: null
    }
  ));

  if (addOnV2NetTotalPrice.oneTimeNetPrice !== null) {
    oneTimePrice += addOnV2NetTotalPrice.oneTimeNetPrice;
  }

  if (addOnV2NetTotalPrice.monthlyNetPrice !== null) {
    monthlyPrice += addOnV2NetTotalPrice.monthlyNetPrice;
  }

  if (addOnV2NetTotalPrice.yearlyNetPrice !== null) {
    yearlyPrice += addOnV2NetTotalPrice.yearlyNetPrice;
  }

  return {
    oneTimePrice,
    monthlyPrice,
    yearlyPrice,
    isOnRequest
  };
};

const getAddOnV2TotalNetPrice = selectedAddOnV2MapKeyByProductType => {
  return _.reduce(
    selectedAddOnV2MapKeyByProductType,
    (result, value) => {
      if (!value || value.length === 0) return result;

      const addOnNetPrice = _.reduce(
        value,
        (result, value) => {
          if (value.addOnSellingOption && !value.addOnSellingOption.showPriceOnFrontend) {
            if (value.addOnSellingOption.oneTimeNetPrice) {
              result.oneTimeNetPrice += value.addOnSellingOption.oneTimeNetPrice;
            }
            if (value.addOnSellingOption.monthlyNetPrice) {
              result.monthlyNetPrice += value.addOnSellingOption.monthlyNetPrice;
            }
            if (value.addOnSellingOption.yearlyNetPrice) {
              result.yearlyNetPrice += value.addOnSellingOption.yearlyNetPrice;
            }
          }
          return result;
        },
        {
          oneTimeNetPrice: null,
          monthlyNetPrice: null,
          yearlyNetPrice: null
        }
      );

      if (addOnNetPrice.oneTimeNetPrice !== null) {
        result.oneTimeNetPrice += addOnNetPrice.oneTimeNetPrice;
      }
      if (addOnNetPrice.monthlyNetPrice !== null) {
        result.monthlyNetPrice += addOnNetPrice.monthlyNetPrice;
      }
      if (addOnNetPrice.yearlyNetPrice !== null) {
        result.yearlyNetPrice += addOnNetPrice.yearlyNetPrice;
      }

      return result;
    },
    {
      oneTimeNetPrice: null,
      monthlyNetPrice: null,
      yearlyNetPrice: null
    }
  );
};

const getTaxPricingSummary = (selectedAddOnV2MapKeyByProductType, productPackagesSummary) => {
  let taxes = [];
  _.forEach(productPackagesSummary, productPackageSummary => {
    if (!productPackageSummary.onRequest && !productPackageSummary.alwaysShowOnRequest) {
      _.forEach(productPackageSummary.taxData, taxObject => {
        const cloneTaxObject = _.cloneDeep(taxObject);
        let existedTax = _.find(taxes, tax => taxObject.id === tax.id);
        if (existedTax) {
          calculateTaxPrice(existedTax, cloneTaxObject, PAYMENT_FREQUENCY.ONE_TIME);
          calculateTaxPrice(existedTax, cloneTaxObject, PAYMENT_FREQUENCY.MONTHLY);
          calculateTaxPrice(existedTax, cloneTaxObject, PAYMENT_FREQUENCY.YEARLY);
        } else {
          taxes.push(cloneTaxObject);
        }
      });
    }
  });

  _.forEach(selectedAddOnV2MapKeyByProductType[ADD_ON_V2_PRODUCT_TYPE.GLOBAL], globalAddOn => {
    if (!globalAddOn.addOnSellingOption.showPriceOnFrontend) {
      _.forEach(globalAddOn.addOnSellingOption.taxes, taxObject => {
        const cloneTaxObject = _.cloneDeep(taxObject);
        let existedTax = _.find(taxes, tax => cloneTaxObject.id === tax.id);
        if (existedTax) {
          calculateTaxPrice(existedTax, cloneTaxObject, PAYMENT_FREQUENCY.ONE_TIME);
          calculateTaxPrice(existedTax, cloneTaxObject, PAYMENT_FREQUENCY.MONTHLY);
          calculateTaxPrice(existedTax, cloneTaxObject, PAYMENT_FREQUENCY.YEARLY);
        } else {
          taxes.push(cloneTaxObject);
        }
      });
    }
  });

  return taxes;
};

const calculateTaxPrice = (existedTax, tax, paymentFrequency) => {
  if (tax.price[paymentFrequency] !== null) {
    if (existedTax.price[paymentFrequency] === null) {
      existedTax.price[paymentFrequency] = tax.price[paymentFrequency];
    } else {
      existedTax.price[paymentFrequency] += tax.price[paymentFrequency];
    }
  }
};

const roundUpTwoDecimal = price => {
  if (price == null) return price;

  if (!_.isNumber(price)) return price;

  return Math.round((price + Number.EPSILON) * 100) / 100;
};

export { getSubTotalPrice, getAddOnV2TotalNetPrice, getTaxPricingSummary, roundUpTwoDecimal };

import React from 'react';
import { Radio } from '@blueprintjs/core';
import * as PropTypes from 'prop-types';
import { SPECIAL_CHARACTER } from '../../common/constants';

const PackageRadioButton = ({ className, name, isChecked, onChange }) => (
  <Radio className={className} name={name} checked={isChecked} onChange={onChange} />
);

PackageRadioButton.propTypes = {
  className: PropTypes.string,
  isChecked: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func
};

PackageRadioButton.defaultProps = {
  className: SPECIAL_CHARACTER.EMPTY,
  isChecked: false,
  name: SPECIAL_CHARACTER.EMPTY,
  onChange: () => ({})
};

export default PackageRadioButton;

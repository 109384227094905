import { PAYMENT_TYPE, RENTAL, RENTAL_UNIT, SPECIAL_CHARACTER } from '../constants';

const getRentalRate = (selectedPurchaseRental, selectedRentalInfo) => {
  if (
    selectedPurchaseRental === PAYMENT_TYPE.RENTAL &&
    (selectedRentalInfo && selectedRentalInfo.duration)
  ) {
    return formatDuration(selectedRentalInfo.duration, selectedRentalInfo.unit);
  }
  return SPECIAL_CHARACTER.EMPTY;
};

const formatDuration = (duration, unit) => {
  if (unit === RENTAL.MONTH) {
    if (duration > 1) {
      return duration + SPECIAL_CHARACTER.SPACE + RENTAL.MONTHS_FORMATTED;
    }
    return duration + SPECIAL_CHARACTER.SPACE + RENTAL.MONTH_FORMATTED;
  }
  if (duration > 1) {
    return duration + SPECIAL_CHARACTER.SPACE + RENTAL.YEARS_FORMATTED;
  }
  return duration + SPECIAL_CHARACTER.SPACE + RENTAL.YEAR_FORMATTED;
};

const checkAddonRentalRate = (addonRental, selectedRentalInfo) => {
  if (addonRental.duration === selectedRentalInfo.duration) {
    if (addonRental.unit === RENTAL_UNIT.MONTH && selectedRentalInfo.unit === RENTAL.MONTH) {
      return true;
    }
    if (addonRental.unit === RENTAL_UNIT.YEAR && selectedRentalInfo.unit === RENTAL.YEAR) {
      return true;
    }
  }
  return false;
};

export { getRentalRate, checkAddonRentalRate };

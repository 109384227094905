import _ from 'lodash';
import { productsSelector } from './productsSelector';
import {
  HOUSE_CONNECTION_PREPARATION_WATER_HEATERS,
  HOUSE_CONNECTION_ELECTRICITY_FEED_INTO_GRID,
  HOUSE_CONNECTION_EXTINGUISHING_WATER_DEMAND,
  ON_REQUEST,
  PRODUCT_TYPE,
  PRODUCT_TYPE_CURR_PACKAGE_MAPPING,
  QUESTION_ID
} from '../../common/constants';
import { isInputAnswerExceedMaxValue, isSelectionAnswerYes } from './productSelectionsSelector';
import { createDeepEqualSelector, questionConfigCJSelector } from './questionsSelector';

const getCurrentState = state => state.currentState;
const getProducts = state => productsSelector(state);
const getCustomerJourneyAnswer = state => state.customerJourneyAnswer;
const getQuestionConfigCJ = state => questionConfigCJSelector(state);

export const currentStateSelector = createDeepEqualSelector(
  [getCurrentState, getProducts, getCustomerJourneyAnswer, getQuestionConfigCJ],
  (currentState, products, customerJourneyAnswer, questionConfigCJ) => {
    let cloneCurrentState = _.assign({}, currentState);
    _.forEach(products, product => {
      const currentStateProductPackageKey = PRODUCT_TYPE_CURR_PACKAGE_MAPPING[product.type];
      if (product.showOnRequest || currentState.isShowAllProductOnRequest) {
        if (
          cloneCurrentState[currentStateProductPackageKey] &&
          cloneCurrentState[currentStateProductPackageKey].packageId !== null
        ) {
          // show package name if have matched package
          cloneCurrentState = {
            ...cloneCurrentState,
            [currentStateProductPackageKey]: {
              ...cloneCurrentState[currentStateProductPackageKey],
              totalPrice: ON_REQUEST,
              monthlyPrice: ON_REQUEST,
              oneTimePrice: ON_REQUEST,
              yearlyPrice: ON_REQUEST,
              pricingComponents: [],
              variableCompIds: [],
              alwaysShowOnRequest: true
            }
          };
        } else {
          // show product name if no package matched
          cloneCurrentState = {
            ...cloneCurrentState,
            [currentStateProductPackageKey]: setCurrPackageOnRequest()
          };
        }
      } else {
        let questionId;
        switch (product.type) {
          case PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY:
            questionId = QUESTION_ID.HOUSE_CONNECTION_ELECTRICITY_FEED_INTO_GRID;
            if (
              isSelectionAnswerYes(
                questionId,
                customerJourneyAnswer,
                HOUSE_CONNECTION_ELECTRICITY_FEED_INTO_GRID.YES
              )
            ) {
              if (
                isInputAnswerExceedMaxValue(questionId, questionConfigCJ, customerJourneyAnswer)
              ) {
                cloneCurrentState = {
                  ...cloneCurrentState,
                  [PRODUCT_TYPE_CURR_PACKAGE_MAPPING[product.type]]: {
                    ...cloneCurrentState[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[product.type]],
                    ...setCurrPackageNameOnRequest()
                  }
                };
                return;
              }
            }
            if (
              customerJourneyAnswer.isShowPriceElectricity &&
              isSelectionAnswerYes(
                QUESTION_ID.HOUSE_CONNECTION_PREPARATION_WATER_HEATERS,
                customerJourneyAnswer,
                HOUSE_CONNECTION_PREPARATION_WATER_HEATERS.YES
              )
            ) {
              cloneCurrentState = {
                ...cloneCurrentState,
                [PRODUCT_TYPE_CURR_PACKAGE_MAPPING[product.type]]: {
                  ...cloneCurrentState[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[product.type]],
                  ...setCurrPackageNameOnRequest()
                }
              };
              return;
            }
            break;
          case PRODUCT_TYPE.HOUSE_CONNECTION_GAS:
            questionId = QUESTION_ID.HOUSE_CONNECTION_OUTLET_PRESSURE_REQUIRED;
            if (isInputAnswerExceedMaxValue(questionId, questionConfigCJ, customerJourneyAnswer)) {
              cloneCurrentState = {
                ...cloneCurrentState,
                [PRODUCT_TYPE_CURR_PACKAGE_MAPPING[product.type]]: {
                  ...cloneCurrentState[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[product.type]],
                  ...setCurrPackageNameOnRequest()
                }
              };
              return;
            }
            break;
          case PRODUCT_TYPE.HOUSE_CONNECTION_WATER:
            questionId = QUESTION_ID.HOUSE_CONNECTION_EXTINGUISHING_WATER_DEMAND;
            if (
              isSelectionAnswerYes(
                questionId,
                customerJourneyAnswer,
                HOUSE_CONNECTION_EXTINGUISHING_WATER_DEMAND.YES
              )
            ) {
              if (
                isInputAnswerExceedMaxValue(questionId, questionConfigCJ, customerJourneyAnswer)
              ) {
                cloneCurrentState = {
                  ...cloneCurrentState,
                  [PRODUCT_TYPE_CURR_PACKAGE_MAPPING[product.type]]: {
                    ...cloneCurrentState[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[product.type]],
                    ...setCurrPackageNameOnRequest()
                  }
                };
                return;
              }
            }
            break;
          default:
            break;
        }

        // Check package always show price as 'On request'
        if (
          cloneCurrentState.currProductPackageConfig[product.type] &&
          cloneCurrentState[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[product.type]]
        ) {
          const sellingOptionId = cloneCurrentState[currentStateProductPackageKey].sellingOptionId;
          const sellingOptionMap =
            cloneCurrentState.currProductPackageConfig[product.type].sellingOptionMap;
          if (!_.isEmpty(sellingOptionMap) && sellingOptionMap[sellingOptionId]) {
            cloneCurrentState = {
              ...cloneCurrentState,
              [PRODUCT_TYPE_CURR_PACKAGE_MAPPING[product.type]]: {
                ...cloneCurrentState[currentStateProductPackageKey],
                alwaysShowOnRequest: sellingOptionMap[sellingOptionId].alwaysShowOnRequest
              }
            };
          }
        }
      }
    });
    return cloneCurrentState;
  }
);

const setCurrPackageNameOnRequest = () => {
  return {
    totalPrice: ON_REQUEST,
    monthlyPrice: ON_REQUEST,
    oneTimePrice: ON_REQUEST,
    yearlyPrice: ON_REQUEST,
    pricingComponents: []
  };
};

const setCurrPackageOnRequest = () => {
  return {
    totalPrice: ON_REQUEST,
    monthlyPrice: ON_REQUEST,
    oneTimePrice: ON_REQUEST,
    yearlyPrice: ON_REQUEST,
    pricingComponents: [],
    variableCompIds: [],
    packageId: null,
    sellingOptionId: null,
    title: null,
    duration: null,
    durationUnit: null,
    name: null
  };
};

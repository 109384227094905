export const UPDATE_CURRENT_QUESTIONS = 'UPDATE_CURRENT_QUESTIONS';
export const updateCurrentQuestions = data => ({
  type: UPDATE_CURRENT_QUESTIONS,
  payload: data
});

export const CREATE_CURRENT_QUESTIONS = 'CREATE_CURRENT_QUESTIONS';
export const createCurrentQuestions = data => ({
  type: CREATE_CURRENT_QUESTIONS,
  payload: data
});

export const UPDATE_CURRENT_PACKAGE = 'UPDATE_CURRENT_PACKAGE';
export const updateCurrentPackage = data => ({
  type: UPDATE_CURRENT_PACKAGE,
  payload: data
});

export const CREATE_CURRENT_PRODUCT_PACKAGES = 'CREATE_CURRENT_PRODUCT_PACKAGES';
export const createCurrProductPackages = data => ({
  type: CREATE_CURRENT_PRODUCT_PACKAGES,
  payload: data
});

export const UPDATE_CURRENT_PACKAGE_ON_REQUEST = 'UPDATE_CURRENT_PACKAGE_ON_REQUEST';
export const updateCurrPackageOnRequest = data => ({
  type: UPDATE_CURRENT_PACKAGE_ON_REQUEST,
  payload: data
});

export const UPDATE_CURRENT_PACKAGE_MANUALLY = 'UPDATE_CURRENT_PACKAGE_MANUALLY';
export const updateCurrPackageManually = data => ({
  type: UPDATE_CURRENT_PACKAGE_MANUALLY,
  payload: data
});

export const ADD_MAPPING_LABEL = 'ADD_MAPPING_LABEL';
export const addMappingLabel = data => ({
  type: ADD_MAPPING_LABEL,
  payload: data
});

export const DELETE_MAPPING_LABEL = 'DELETE_MAPPING_LABEL';
export const deleteMappingLabel = data => ({
  type: DELETE_MAPPING_LABEL,
  payload: data
});

export const UPDATE_CURR_PRODUCT_PACKAGE_CONFIG = 'UPDATE_CURR_PRODUCT_PACKAGE_CONFIG';
export const updateCurrProductPackageConfig = data => ({
  type: UPDATE_CURR_PRODUCT_PACKAGE_CONFIG,
  payload: data
});

export const UPDATE_SHOW_ALL_PRODUCT_ON_REQUEST = 'UPDATE_SHOW_ALL_PRODUCT_ON_REQUEST';
export const updateShowAllProductOnRequest = data => ({
  type: UPDATE_SHOW_ALL_PRODUCT_ON_REQUEST,
  payload: data
});

export const RESET_CURRENT_STATE = 'RESET_CURRENT_STATE';
export const resetCurrentState = () => ({
  type: RESET_CURRENT_STATE
});

import { createSelector } from 'reselect';
import _ from 'lodash';
import { PRODUCT_AVAILABLE_STATUS } from '../../common/constants';

const getProducts = state => state.frontendConfiguration.products;
const getInternalServiceAvailableProducts = state => state.customerJourneyAnswer.availableProduct;

export const availableProductsInternalServiceSelector = createSelector(
  [getProducts, getInternalServiceAvailableProducts],
  (products, availableProductsInternalService) => {
    let tmpProducts = [];
    _.forEach(products, elem => {
      let tmpProduct = _.assign({}, elem);
      availableProductsInternalService &&
      availableProductsInternalService.indexOf(tmpProduct.type) !== -1
        ? (tmpProduct.availableStatus = PRODUCT_AVAILABLE_STATUS.ACTIVATED)
        : (tmpProduct.availableStatus = PRODUCT_AVAILABLE_STATUS.DEACTIVATED);
      tmpProduct.showOnRequest = false;
      tmpProducts.push(tmpProduct);
    });
    return tmpProducts;
  }
);

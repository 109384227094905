export const UPDATE_SELECTED_ADDON_V2 = 'UPDATE_SELECTED_ADDON_V2';
export const updateSelectedAddOnV2 = data => ({
  type: UPDATE_SELECTED_ADDON_V2,
  payload: data
});

export const UPDATE_ADDON_SELLING_OPTION_V2 = 'UPDATE_ADDON_SELLING_OPTION_V2';
export const updateAddOnSellingOptionV2 = data => ({
  type: UPDATE_ADDON_SELLING_OPTION_V2,
  payload: data
});

export const REMOVE_INVALID_SELECTED_ADDON_V2 = 'REMOVE_INVALID_SELECTED_ADDON_V2';
export const removeInvalidSelectedAddOnV2 = data => ({
  type: REMOVE_INVALID_SELECTED_ADDON_V2,
  payload: data
});

export const REMOVE_SELECTED_ADDON_V2 = 'REMOVE_SELECTED_ADDON_V2';
export const removeSelectedAddOnV2 = data => ({
  type: REMOVE_SELECTED_ADDON_V2,
  payload: data
});

export const REMOVE_SELECTED_GLOBAL_ADDON_V2 = 'REMOVE_SELECTED_GLOBAL_ADDON_V2';
export const removeSelectedGlobalAddOnV2 = () => ({
  type: REMOVE_SELECTED_GLOBAL_ADDON_V2
});

export const REMOVE_ALL_SELECTED_ADDON_V2 = 'REMOVE_ALL_SELECTED_ADDON_V2';
export const removeAllSelectedAddOnV2 = () => ({
  type: REMOVE_ALL_SELECTED_ADDON_V2
});

export const REMOVE_SELECTED_ADDON_V2_BY_PRODUCT_TYPE = 'REMOVE_SELECTED_ADDON_V2_BY_PRODUCT_TYPE';
export const removeSelectedAddOnV2ByProductType = data => ({
  type: REMOVE_SELECTED_ADDON_V2_BY_PRODUCT_TYPE,
  payload: data
});

export const TAX = {
  INCL_MONTH_VAT: ' (inkl. MwSt/Monat.)',
  INCL_YEAR_VAT: ' (inkl. MwSt/Jahr.)'
};

export const PAYMENT_FREQUENCY = {
  ONE_TIME: 'ONE_TIME',
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY'
};

export const SELLING_OPTION_TYPE = {
  ONE_TIME: 'ONE_TIME',
  RECURRING: 'RECURRING'
};

export const SELLING_OPTION = {
  ONE_TIME: 'One-time',
  RECURRING: 'Recurring',
  MONTH: 'months',
  YEAR: 'years'
};

export const DURATION_UNIT = {
  MONTH: 'MONTH',
  YEAR: 'YEAR'
};

export const KWH_CONSUMPTION_CJ_ANSWERS_KEY = ['totalConsumption'];

export const METER_CONSUMPTION_CJ_ANSWERS_KEY = [
  'houseConnectionTrenchLength',
  'houseConnectionWaterStreetFrontLength'
];

export const SQUARE_METRE_CONSUMPTION_CJ_ANSWERS_KEY = [
  'houseConnectionWaterPropertySize',
  'houseConnectionWaterFloorSpace'
];

export const HOUSING_UNIT_CONSUMPTION_CJ_ANSWERS_KEY = ['houseConnectionUnits'];

export const LITTER_PER_SEC_CONSUMPTION_CJ_ANSWERS_KEY = [
  'houseConnectionExtinguishingWaterDemand',
  'houseConnectionWaterMaxFlow',
  'houseConnectionPeakWater'
];

export const KW_CONSUMPTION_CJ_ANSWERS_KEY = [
  'housingElectricityMaxPower',
  'houseConnectionGasMaxLoad',
  'totalAdditionalOutput',
  'houseConnectionElectricityFeedIntoGrid',
  'eMobilityPower',
  'houseConnectionElectricityMaxPower',
  'houseConnectionHeatingCapacity',
  'heatingLivingSpace',
  'outputHeating'
];

export const KW_CONFIG_KEY = 'kwCompConfig';
export const KWH_CONFIG_KEY = 'kwhCompConfig';
export const LITTER_PER_SEC_CONFIG_KEY = 'literPerSecCompConfig';
export const METER_CONFIG_KEY = 'meterCompConfig';
export const METER_STREET_FRONT_LENGTH_FOR_HC_WATER_CONFIG_KEY =
  'meterStreetFrontLengthForHCWaterCompConfig';
export const HOUSING_UNIT_FOR_HC_WATER_CONFIG_KEY = 'housingUnitForHCWaterCompConfig';
export const SQUARE_METRE_PROPERTY_SIZE_FOR_HC_WATER_CONFIG_KEY =
  'squareMetrePropertySizeForHCWaterCompConfig';
export const SQUARE_METRE_FLOOR_SIZE_FOR_HC_WATER_CONFIG_KEY =
  'squareMetreFloorSizeForHCWaterCompConfig';
export const ON_REQUEST = 'ON_REQUEST';
export const VARIABLE = 'VARIABLE';
export const FIXED = 'FIXED';

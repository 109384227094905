import { createSelector } from 'reselect';
import _ from 'lodash';
import {
  availableProductsExternalServiceSelector,
  availableProductsInternalServiceSelector
} from '.';
import {
  HOUSE_CONNECTION_BUILDING_TYPE,
  PRODUCT_AVAILABLE_STATUS,
  PRODUCT_TYPE
} from '../../common/constants';

const productsInternalServiceCheck = state => availableProductsInternalServiceSelector(state);
const productsExternalServiceCheck = state => availableProductsExternalServiceSelector(state);
const getHCBuildingTypeAnswer = state => state.customerJourneyAnswer.houseConnectionBuildingType;
const thirdPartyServiceSetting = state => state.frontendConfiguration.thirdPartyServiceSetting;

export const productsSelector = createSelector(
  [
    productsInternalServiceCheck,
    productsExternalServiceCheck,
    thirdPartyServiceSetting,
    getHCBuildingTypeAnswer
  ],
  (productsInternal, productsExternal, thirdPartyServiceSetting, houseConnectionBuildingType) => {
    let tmpProducts;
    const newProducts = [];
    if (thirdPartyServiceSetting && thirdPartyServiceSetting.active) {
      tmpProducts = productsExternal;
    } else {
      tmpProducts = productsInternal;
    }
    if (houseConnectionBuildingType === HOUSE_CONNECTION_BUILDING_TYPE.BROADBAND_CABLE_REPEATER) {
      _.forEach(tmpProducts, product => {
        if (product.type !== PRODUCT_TYPE.HOUSE_CONNECTION_ELECTRICITY) {
          newProducts.push({
            ...product,
            availableStatus: PRODUCT_AVAILABLE_STATUS.DEACTIVATED
          });
        } else {
          newProducts.push(product);
        }
      });
    }
    return !_.isEmpty(newProducts) ? newProducts : tmpProducts;
  }
);

import _ from 'lodash';
import * as uuid from 'uuid';
import {
  DEFAULT_ACCEPTED_FILE_TYPES,
  SPECIAL_CHARACTER,
  MESSAGE_CONSTANTS,
  MAXIMUM_FILE_UPLOAD
} from '../constants';

export const isValidFileSize = file => file.size <= MAXIMUM_FILE_UPLOAD;

export const isValidFileName = (uploadedFileInfo, file) =>
  !_.some(uploadedFileInfo, { name: file.name });

export const getErrMessageUploadFiles = (filesUploaded, approvedFiles, rejectedFiles) => {
  let errMessage = SPECIAL_CHARACTER.EMPTY;
  _.forEach(approvedFiles, file => {
    if (!isValidFileSize(file)) {
      errMessage = MESSAGE_CONSTANTS.MAX_FILE_SIZE;
      return false;
    } else if (!isValidFileName(filesUploaded, file)) {
      errMessage = MESSAGE_CONSTANTS.FILES_NAME_EXIST;
      return false;
    }
  });
  if (rejectedFiles.length > 0) {
    errMessage = MESSAGE_CONSTANTS.INVALID_FILE_TYPE;
  }
  return errMessage;
};

export const getAcceptFileType = frontendConfiguration => {
  if (frontendConfiguration.displayAllowFileTypes) {
    let acceptedFilesType = [];
    _.forEach(frontendConfiguration.allowFileTypes.split(SPECIAL_CHARACTER.COMMA), file => {
      acceptedFilesType.push(SPECIAL_CHARACTER.DOT + file.trim());
    });
    return acceptedFilesType.join();
  }
  return DEFAULT_ACCEPTED_FILE_TYPES;
};

/**
 * Generate upload file data to request pre-signed url
 *
 * @param files
 * @param isTaxDocument
 * @param customerFirstName
 * @param customerLastName
 * @returns {[]}
 */
export const generateUploadFileData = ({
  files,
  isTaxDocument = false,
  customerFirstName,
  customerLastName
}) => {
  return _.map(files, file => {
    let fileNameS3;
    const fileExtension = file.name?.split('.')?.pop() ?? 'txt';
    if (isTaxDocument) {
      fileNameS3 = `Steuerdokument - ${customerFirstName} ${customerLastName} - ${uuid.v4()}.${fileExtension}`;
    } else {
      fileNameS3 = `${uuid.v4()}.${fileExtension}`;
    }
    const contentType = file.type || 'text/plain';
    return {
      fileName: fileNameS3,
      fileType: contentType,
      originalFileName: file.name,
      // questionId does not exist when upload file in contact form
      questionId: file.questionId ?? 0
    };
  });
};

const sleep = m => new Promise(r => setTimeout(r, m));

/**
 * Retry make http request 3 times.
 *
 * @param callback
 * @param data
 * @param retryCount
 * @param errorType
 * @returns {Promise<unknown[]>}
 */
export const retryMakeHttpRequest = async ({ callback, data, retryCount, errorType }) => {
  const responses = await Promise.all(callback(data));
  if (_.some(responses, response => response.type === errorType)) {
    retryCount += 1;
    if (retryCount >= 3) {
      const err = new Error('Request failed');
      err.payload = responses;
      throw err;
    }
    await sleep(3000);
    await retryMakeHttpRequest({ callback, data, retryCount, errorType });
  } else {
    return responses;
  }
};

import React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';

export const ShowIfUtil = ({ condition, children }) => {
  if (condition) {
    if (!_.isUndefined(children.length)) {
      return <React.Fragment>{children}</React.Fragment>;
    } else {
      return children;
    }
  }
  return null;
};

ShowIfUtil.propTypes = {
  condition: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.array
  ]).isRequired
};

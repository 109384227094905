import React, { Component } from 'react';
import DatePicker from 'react-date-picker/dist/entry.nostyle';
import * as PropTypes from 'prop-types';
import $ from 'jquery';
import styled from 'styled-components';
import { COLOR, SPECIAL_CHARACTER } from '../../../common/constants';
import { ShowIfUtil } from '../../../common/utils';
import { StyledLabel, StyledSpan } from './StyledElement';

const StyledDatePicker = styled(DatePicker)`
  .react-date-picker__wrapper {
    color: ${props => props.defaultTextColor} !important;
    button {
      color: ${props => props.defaultTextColor} !important;
    }
    .react-date-picker__inputGroup__input {
      color: ${props => props.defaultTextColor} !important;
    }
  }
`;

class DateInput extends Component {
  constructor(props) {
    super(props);
    const { value } = this.props;
    this.state = {
      date: value
    };
  }

  componentDidMount() {
    const { date } = this.state;

    const rdpInputGroupElem = $('.react-date-picker__inputGroup');
    const rdpElem = $('.react-date-picker');
    if (date) {
      rdpInputGroupElem.find('span').text('.');
      rdpElem.find('.react-date-picker__clear-button').addClass('ep-icon-simple-remove');
    } else {
      this.selectedDate();
    }
    rdpInputGroupElem.addClass('react-date-picker-center');
    rdpElem.find('.react-date-picker__calendar-button').addClass('ep-icon-calendar-grid-58');
    this.customDatePickerStyle();
  }

  onChange = date => {
    const { onChange } = this.props;
    this.setState({ date });
    onChange(date);
    if (date === 'Invalid date' || date === null) {
      this.selectedDate();
    } else {
      const rdpInputGroupElem = $('.react-date-picker__inputGroup');
      rdpInputGroupElem
        .find('.react-date-picker__inputGroup__month')
        .removeClass('react-date-picker__inputGroup__month__set__width');
      rdpInputGroupElem.find('span').text('.');
      $('.react-date-picker')
        .find('.react-date-picker__clear-button')
        .addClass('ep-icon-simple-remove');
    }
    this.customDatePickerStyle();
  };

  customDatePickerStyle = () => {
    $('.react-date-picker__inputGroup__day')
      .prev('.react-date-picker__inputGroup__divider')
      .text('');
  };

  selectedDate = () => {
    const rdpInputGroupElem = $('.react-date-picker__inputGroup');
    rdpInputGroupElem.find('span').text('');
    rdpInputGroupElem
      .find('.react-date-picker__inputGroup__month')
      .addClass('react-date-picker__inputGroup__month__set__width');
    rdpInputGroupElem
      .find('.react-date-picker__inputGroup__month')
      .attr('placeholder', 'Datum auswählen');
    rdpInputGroupElem.find('.react-date-picker__inputGroup__day').attr('placeholder', '');
    rdpInputGroupElem.find('.react-date-picker__inputGroup__year').attr('placeholder', '');
  };

  render() {
    const {
      className,
      inputClassName,
      minDate,
      label,
      fontFamily,
      defaultTextColor,
      require
    } = this.props;
    const { date } = this.state;
    return (
      <div className={className}>
        <ShowIfUtil condition={label !== SPECIAL_CHARACTER.EMPTY}>
          <StyledLabel
            className="ep__txt--sm ep__txt-regular ep__txt-no-select"
            font={fontFamily}
            style={{ color: defaultTextColor }}
          >
            {label}
            <ShowIfUtil condition={require}>
              <StyledSpan
                className="ep__txt--sm ep__txt-regular ep__txt-no-select"
                font={fontFamily}
                style={{ color: defaultTextColor }}
              >
                {' *'}
              </StyledSpan>
            </ShowIfUtil>
          </StyledLabel>
        </ShowIfUtil>
        <StyledDatePicker
          locale="de-DE"
          className={`ep-cursor-pointer ep-react-date-input ${inputClassName}`}
          calendarClassName="ep-calendar-date-input"
          onChange={this.onChange}
          value={date}
          calendarIcon=" "
          clearIcon=" "
          minDate={minDate}
          defaultTextColor={defaultTextColor}
        />
      </div>
    );
  }
}

DateInput.propTypes = {
  className: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  inputClassName: PropTypes.string,
  defaultTextColor: PropTypes.string,
  label: PropTypes.string,
  fontFamily: PropTypes.object,
  require: PropTypes.bool
};

DateInput.defaultProps = {
  minDate: new Date(),
  className: SPECIAL_CHARACTER.EMPTY,
  onChange: function() {},
  value: new Date(),
  inputClassName: SPECIAL_CHARACTER.EMPTY,
  defaultTextColor: COLOR.DARK_GRAY,
  label: SPECIAL_CHARACTER.EMPTY,
  fontFamily: {},
  require: false
};

export default DateInput;

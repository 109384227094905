import _ from 'lodash';
import { COUNTRY } from '../constants';

const getListCountry = () => {
  return _.map(COUNTRY, country => country.germanName).sort();
};

const getCountryGermanNameFromCode = code => {
  return Object.values(COUNTRY).find(country => country.code === code).germanName;
};

export { getListCountry, getCountryGermanNameFromCode };

import { LIST_DEFAULT_FONT, SPECIAL_CHARACTER } from '../constants';

export const loadFont = (font, normalCustomFontUrl, boldCustomFontUrl, isBold) => {
  if (LIST_DEFAULT_FONT.indexOf(font) > -1) {
    return {
      font: font,
      fontUrl: SPECIAL_CHARACTER.EMPTY
    };
  } else {
    if (isBold) {
      if (boldCustomFontUrl) {
        return {
          font: 'customBold',
          fontUrl: boldCustomFontUrl
        };
      }
      if (normalCustomFontUrl) {
        return {
          font: 'custom',
          fontUrl: normalCustomFontUrl
        };
      }
      return {
        font: 'Montserrat',
        fontUrl: SPECIAL_CHARACTER.EMPTY
      };
    }
    if (normalCustomFontUrl) {
      return {
        font: 'custom',
        fontUrl: normalCustomFontUrl
      };
    }
    return {
      font: 'Montserrat',
      fontUrl: SPECIAL_CHARACTER.EMPTY
    };
  }
};

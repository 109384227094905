import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { Position, Tooltip } from '@blueprintjs/core';
import { formatPrice, ScrollUtil, ShowIfUtil } from '../../common/utils';
import {
  COLOR,
  CURRENCY,
  GERMAN_TEXT,
  PAYMENT_FREQUENCY,
  SPECIAL_CHARACTER
} from '../../common/constants';
import { StyledSpan } from '../../components/epilot-forms/common';

class PricingComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pricingComponentHeight: ''
    };
  }

  componentDidMount() {
    this.setHeight();
    this.handleWindowResize();
  }

  setHeight = () => {
    if (this.pricingComponents) {
      this.setState({
        pricingComponentHeight: this.pricingComponents.clientHeight
      });
    }
  };

  handleWindowResize = () => {
    let resizeTimeout;
    window.addEventListener('resize', () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(this.setHeight, 30);
    });
  };

  showProductComponent = () => {
    const { showProductComponent } = this.props;
    showProductComponent();
  };

  render() {
    const {
      fontText,
      frontendConfiguration,
      pricingComponents,
      setScrollHeight,
      pricingComponentHeight: pricingComponentHeightProps
    } = this.props;
    const { pricingComponentHeight } = this.state;
    const {
      designOptions: {
        showPackageInfoSpecialCondition: { showIndividualPricing }
      }
    } = frontendConfiguration;

    const textColor = {
      color: frontendConfiguration.primaryTextColor
    };

    const defaultTextColor = {
      color: frontendConfiguration.defaultTextColor
    };

    const pricingComponentNameStyle = classNames({
      'col-7': showIndividualPricing,
      'col-12': !showIndividualPricing
    });

    return (
      <div
        className="ep-webshop-selectpackage-wrapper"
        style={{ borderRadius: frontendConfiguration.borderRadiusMax4px }}
        ref={ref => {
          if (ref) {
            setScrollHeight(ref.clientHeight);
          }
        }}
      >
        <div
          className="ep-webshop-selectpackage-header d-flex align-items-center"
          style={{
            background: frontendConfiguration.primaryColor,
            borderTopLeftRadius: frontendConfiguration.borderRadiusMax4px,
            borderTopRightRadius: frontendConfiguration.borderRadiusMax4px
          }}
        >
          <i
            className="ep-icon-circle-left-38 ep__txt--h1 ep-cursor-pointer ep--ml-16"
            style={textColor}
            onClick={this.showProductComponent}
          />

          <StyledSpan
            font={fontText}
            className="ep__txt--h2 ep--ml-16 ep__txt-no-select"
            style={textColor}
          >
            {GERMAN_TEXT.SELECT_A_PACKAGE}
          </StyledSpan>
        </div>

        <div className="clearfix" style={{ backgroundColor: frontendConfiguration.primaryColor }} />

        <ScrollUtil
          height={
            parseInt(pricingComponentHeight, 10) < parseInt(pricingComponentHeightProps)
              ? pricingComponentHeight.toString()
              : pricingComponentHeightProps.toString()
          }
          showShadowBottom
        >
          <div
            ref={pricingComponentRef => {
              if (pricingComponentRef) {
                this.pricingComponents = pricingComponentRef;
              }
            }}
            className="ep--px-16 ep--pt-8"
            style={{ backgroundColor: COLOR.LIGHT_GRAY }}
          >
            <StyledSpan
              font={fontText}
              className="ep__txt--sm ep__txt-bold ep__txt-no-select"
              style={defaultTextColor}
            >
              {GERMAN_TEXT.PACKAGE_COMPONENTS}
            </StyledSpan>
            {_.map(pricingComponents, (pricingComponent, index) => {
              const currentPrice = _.isNumber(pricingComponent.realPrice)
                ? pricingComponent.realPrice
                : pricingComponent.price;
              const currency =
                pricingComponent.currency === CURRENCY.CENT
                  ? SPECIAL_CHARACTER.CENT
                  : SPECIAL_CHARACTER.EURO;
              return (
                <div
                  key={`${pricingComponent.id}-${index}`}
                  className="row ep__txt--sm ep__txt-regular ep--pt-8"
                >
                  <div className={pricingComponentNameStyle}>
                    <Tooltip
                      content={
                        <StyledSpan font={fontText} style={defaultTextColor}>
                          {pricingComponent.name}
                        </StyledSpan>
                      }
                      position={Position.BOTTOM}
                      className="mw-100"
                      tooltipClassName="ep-tooltip"
                    >
                      <div className="ep-ellipsis">
                        <StyledSpan
                          font={fontText}
                          className="ep__txt-no-select"
                          style={defaultTextColor}
                        >
                          {pricingComponent.name}
                        </StyledSpan>
                      </div>
                    </Tooltip>
                    {pricingComponent.childComponentsOfGroup &&
                      pricingComponent.childComponentsOfGroup.length > 0 && (
                        <Tooltip
                          content={_.map(
                            pricingComponent.childComponentsOfGroup,
                            childComponentOfGroup => {
                              const currency =
                                childComponentOfGroup.currency === CURRENCY.EURO
                                  ? SPECIAL_CHARACTER.EURO
                                  : SPECIAL_CHARACTER.CENT;
                              const paymentFrequency =
                                pricingComponent.paymentFrequency === PAYMENT_FREQUENCY.MONTHLY
                                  ? `/${GERMAN_TEXT.MONTH}`
                                  : pricingComponent.paymentFrequency === PAYMENT_FREQUENCY.YEARLY
                                  ? `/${GERMAN_TEXT.YEAR}`
                                  : '';
                              return (
                                <div className="row" key={childComponentOfGroup.id}>
                                  <div className="col">
                                    <StyledSpan
                                      className="ep-ellipsis"
                                      font={fontText}
                                      style={defaultTextColor}
                                    >
                                      {childComponentOfGroup.name}
                                    </StyledSpan>
                                  </div>
                                  <div className="col text-right">
                                    <StyledSpan font={fontText} style={defaultTextColor}>
                                      {`${childComponentOfGroup.grossRetailPrice} ${currency}${paymentFrequency}`}
                                    </StyledSpan>
                                  </div>
                                </div>
                              );
                            }
                          )}
                          className="mw-100"
                          tooltipClassName="ep-tooltip"
                          hoverCloseDelay={200}
                          position={Position.RIGHT}
                        >
                          <i
                            className="d-inline-block ep-icon-alert-circle-i ep-cursor-pointer ml-2"
                            style={{ outline: 'none', color: defaultTextColor }}
                          />
                        </Tooltip>
                      )}
                  </div>

                  <ShowIfUtil condition={showIndividualPricing}>
                    <div className="col-5 text-right">
                      <Tooltip
                        content={
                          <StyledSpan font={fontText} style={defaultTextColor}>
                            {formatPrice(currentPrice, currency)}
                            <ShowIfUtil
                              condition={
                                pricingComponent.paymentFrequency !== PAYMENT_FREQUENCY.ONE_TIME
                              }
                            >
                              /
                              {pricingComponent.paymentFrequency === PAYMENT_FREQUENCY.MONTHLY
                                ? GERMAN_TEXT.MONTH
                                : GERMAN_TEXT.YEAR}
                            </ShowIfUtil>
                          </StyledSpan>
                        }
                        position={Position.BOTTOM}
                        className="mw-100"
                        tooltipClassName="ep-tooltip"
                        hoverCloseDelay={200}
                      >
                        <StyledSpan
                          font={fontText}
                          className="col-5 ep--pl-0 ep-ellipsis ep__txt-no-select"
                          style={{ ...fontText, ...defaultTextColor }}
                        >
                          {formatPrice(currentPrice, currency)}
                          <ShowIfUtil
                            condition={
                              pricingComponent.paymentFrequency !== PAYMENT_FREQUENCY.ONE_TIME
                            }
                          >
                            /
                            {pricingComponent.paymentFrequency === PAYMENT_FREQUENCY.MONTHLY
                              ? GERMAN_TEXT.MONTH
                              : GERMAN_TEXT.YEAR}
                          </ShowIfUtil>
                        </StyledSpan>
                      </Tooltip>
                    </div>
                  </ShowIfUtil>
                </div>
              );
            })}
          </div>
        </ScrollUtil>
      </div>
    );
  }
}

PricingComponent.propTypes = {
  fontText: PropTypes.object,
  frontendConfiguration: PropTypes.object,
  showProductComponent: PropTypes.func,
  setScrollHeight: PropTypes.func.isRequired,
  pricingComponents: PropTypes.array,
  pricingComponentHeight: PropTypes.string
};

PricingComponent.defaultProps = {
  fontText: {},
  frontendConfiguration: {},
  showProductComponent: () => ({}),
  pricingComponents: [],
  pricingComponentHeight: ''
};

function mapStateToProps(state) {
  return {
    frontendConfiguration: state.frontendConfiguration,
    customerJourneyAnswer: state.customerJourneyAnswer,
    customerJourneyMapping: state.customerJourneyMapping
  };
}

export default connect(mapStateToProps)(PricingComponent);

export const QUESTION_TYPE = {
  INPUT: 'input',
  SPINNER: 'spinner',
  PERCENT_SLIDER: 'percent_slider',
  TABLE_SLIDER: 'table_slider',
  RADIO_BUTTON: 'radio_button',
  CUSTOM_RADIO: 'custom_radio',
  CHP_INPUT: 'chp_input',
  HEATING_INPUT: 'heating_input',
  HEATING_TABLE: 'heating_table',
  HEATING_SLIDERS: 'heating_sliders',
  HEATING_TEXT: 'heating_text',
  HOUSE_CONNECTION_INPUT: 'house_connection_input',
  HOUSE_CONNECTION_DROPDOWN: 'dropdown',
  HOUSE_CONNECTION_TEXT: 'house_connection_text',
  HOUSE_CONNECTION_TEXT_SWITCH: 'house_connection_text_switch',
  HOUSE_CONNECTION_TEXT_CONFIGURABLE_MESSAGE: 'house_connection_text_configurable_message',
  HOUSE_CONNECTION_TABLE_NO_HEADER_INPUT: 'house_connection_table_no_header_input',
  HOUSE_CONNECTION_TABLE_INPUT: 'house_connection_table_input',
  HOUSE_CONNECTION_UPLOAD: 'house_connection_upload',
  HOUSE_CONNECTION_TABS: 'house_connection_tabs',
  HOUSE_CONNECTION_MULTIPLE_INPUT: 'house_connection_multiple_input',
  HOUSE_CONNECTION_TABLE_MULTIPLE_CHOICE: 'house_connection_table_multiple_choice',
  HOUSE_CONNECTION_TEXT_WITH_CHECKBOX: 'house_connection_text_with_checkbox',
  HOUSE_CONNECTION_CHOICE_WITH_INPUT: 'house_connection_choice_with_input',
  POSTAL_CODE: 'postal_code',
  CHARGE_TEXT: 'charge_text',
  CHARGE_TABLE_INPUT: 'table_input',
  CHARGE_TABLE_WITH_RADIO_BUTTON: 'table_with_radio_button',
  INPUT_WITH_CHECKBOX: 'input_with_checkbox',
  ADDRESS_DETAILS: 'address_details',
  PIN_ADDRESS_ON_MAP: 'google_map_card',
  DATE_INPUT: 'date_input',
  MULTIPLE_CHOICE: 'multiple_choice',
  DATE_PICKER: 'date_picker',
  FILE_UPLOAD: 'file_upload'
};

export const QUESTION_ID = {
  NAME: 0,
  HOUSEHOLD_SIZE: 1,
  AUTARKY: 2,
  ELECTRIC_CAR: 3,
  ELECTRIC_CAR_USAGE: 4,
  ELECTRIC_CAR_CHARGE_SPEED: 5,
  POWER_FLAT_RATE: 6,
  USAGE_OF_GAS: 7,
  OUTPUT_OF_HEATING_KNOWING: 8,
  OUTPUT_OF_HEATING: 9,
  HEATING_NAME: 10,
  HEATING_ITEM: 11,
  HEATING_LIVING_SPACE: 12,
  HEATING_BUILDING_YEAR: 13,
  HOUSE_CONNECTION_NAME: 14,
  HOUSE_CONNECTION_NEW_OR_CHANGE: 15,
  HOUSE_CONNECTION_PRIVATELY_OR_BUSINESS: 16,
  HOUSE_CONNECTION_BUILDING_TYPE: 17,
  HOUSE_CONNECTION_SINGLE_FAMILY_HOUSE: 18,
  HOUSE_CONNECTION_APARTMENT_BUILDING: 19,
  HOUSE_CONNECTION_SELECT_PRODUCT: 20,
  HOUSE_CONNECTION_ELECTRICITY_MAX_POWER: 21,
  HOUSE_CONNECTION_GAS_MAX_LOAD: 22,
  HOUSE_CONNECTION_WATER_MAX_FLOW: 23,
  HOUSE_CONNECTION_IS_OWNER: 24,
  HOUSE_CONNECTION_UPLOAD_CONSENT_FORM: 25,
  HOUSE_CONNECTION_IS_NEW_BUILDING: 26,
  HOUSE_CONNECTION_UPLOAD_PROPERTY_DOCUMENT: 27,
  POSTAL_CODE: 28,
  CHARGE_NAME: 29,
  CHARGE_PRIVATELY_OR_BUSINESS: 30,
  CHARGE_TYPE: 31,
  CHARGE_CAR_SPEED: 32,
  ADDRESS_DETAILS: 33,
  PIN_ADDRESS_ON_MAP: 44,
  HOUSE_CONNECTION_UNITS: 34,
  HOUSE_CONNECTION_ELECTRICITY_ADDITIONAL: 35,
  HOUSE_CONNECTION_TRENCH_LENGTH: 36,
  HOUSE_CONNECTION_INSTALLATION_DATE: 37,
  HOUSE_CONNECTION_REQUIRED_VALUES: 38,
  HOUSE_CONNECTION_E_MOBILITY: 39,
  HOUSE_CONNECTION_E_MOBILITY_REQUIREMENT: 40,
  HOUSE_CONNECTION_ELECTRICITY_FEED_INTO_GRID: 41,
  HOUSE_CONNECTION_EXTINGUISHING_WATER_DEMAND: 42,
  HOUSE_CONNECTION_OUTLET_PRESSURE_REQUIRED: 43,
  HOUSE_CONNECTION_PEAK_WATER: 45,
  HOUSE_CONNECTION_PREPARATION_WATER_HEATERS: 46,
  HOUSE_CONNECTION_WATER_STREET_FRONT_LENGTH: 47,
  HOUSE_CONNECTION_WATER_PROPERTY_SIZE: 48,
  HOUSE_CONNECTION_WATER_FLOOR_SPACE: 49
};

export const QUESTION_ID_INPUT_ANSWER_MAPPING = {
  41: 'houseConnectionElectricityFeedIntoGrid',
  42: 'houseConnectionExtinguishingWaterDemand',
  43: 'houseConnectionOutletPressureRequired'
};

export const QUESTION_ID_SELECTION_ANSWER_MAPPING = {
  41: 'houseConnectionElectricityIsFeedIntoGrid',
  42: 'houseConnectionIsExtinguishingWaterDemand',
  46: 'houseConnectionWarmWaterPreparation'
};

export const QUESTION_VARIABLE = {
  NAME: '[[name]]'
};

export const HOUSEHOLD_SIZE = {
  ONE: '1',
  TWO: '2',
  THREE: '3',
  FOUR: '4',
  FIVE: '5',
  FIVE_PLUS: '5+',
  SIX: '6'
};

export const QUESTION_EVENT = {
  GO_TO_NEXT_QUESTION: 'GO_TO_NEXT_QUESTION',
  JUMP_TO_A_QUESTION: 'JUMP_TO_A_QUESTION',
  JUMP_TO_CONTACT_FORM: 'JUMP_TO_CONTACT_FORM',
  SELECT_AN_ADD_ON: 'SELECT_AN_ADD_ON',
  CANCEL_CUSTOMER_JOURNEY: 'CANCEL_CUSTOMER_JOURNEY'
};

export const ELECTRIC_CAR_QUESTION_IDS = [3, 4, 5];

import { createSelector } from 'reselect';
import _ from 'lodash';
import { SERVICE_REQUEST_STATUS } from '../../common/constants';

const getExternalProductAvailability = state => state.externalProductAvailability;

export const providerProductSelector = createSelector(
  [getExternalProductAvailability],
  externalProductAvailability => {
    const { externalServiceRequestStatus, availableProducts } = externalProductAvailability;
    if (externalServiceRequestStatus === SERVICE_REQUEST_STATUS.REQUEST_SUCCESS) {
      let productProvider = {};
      _.forEach(availableProducts, availableProduct => {
        productProvider = {
          ...productProvider,
          [availableProduct.product]: availableProduct.productDetails.serviceOperator
        };
      });
      return productProvider;
    }
    return {};
  }
);

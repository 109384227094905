import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import {
  PAYMENT_TYPE,
  RENTAL,
  SELLING_OPTION_TYPE,
  SPECIAL_CHARACTER
} from '../../common/constants';
import { updateProductSelections } from '../../store/actions';
import { ShowIfUtil } from '../../common/utils';
import { DropDown, StyledSpan, Switch } from '../../components/epilot-forms/common';
import { productSelectionsSelector } from '../../store/selectors';

class PurchaseRentalSelectionContainer extends Component {
  constructor(props) {
    super(props);
    const { productSelections } = this.props;
    this.state = {
      selectedValue: productSelections.selectedPurchaseRental || SELLING_OPTION_TYPE.ONE_TIME
    };
  }

  componentWillReceiveProps(nextProps) {
    const { productSelections } = this.props;
    if (
      nextProps.productSelections.selectedPurchaseRental !==
      productSelections.selectedPurchaseRental
    ) {
      this.setState({
        selectedValue: nextProps.productSelections.selectedPurchaseRental
      });
    }
  }

  renderDropdown = () => {
    const { isPurchase, isRental } = this.props;

    if (isPurchase && isRental) {
      return this.renderPurchaseAndRental();
    }
    if (isPurchase) {
      return this.renderPurchaseOnly();
    }
    if (isRental) {
      return this.renderRentalOnly();
    }
  };

  renderPurchaseOnly = () => {
    return (
      <div className="d-flex justify-content-center align-items-center ep--my-8 ep-height-33" />
    );
  };

  renderRentalOnly = () => {
    const { fontText, frontendConfiguration, productSelections } = this.props;
    const listRentalInfo = this.sortRecurringList();

    return (
      <div className="d-flex justify-content-center align-items-center ep--my-8 ep-height-33">
        <StyledSpan
          font={fontText}
          className="ep__txt--md ep__txt-regular mr-2"
          style={{ color: frontendConfiguration.defaultTextColor }}
        >
          {frontendConfiguration.buyRentLabelValueItems.rentalDuration + ' '}
        </StyledSpan>
        <ShowIfUtil condition={listRentalInfo.length > 1}>
          <DropDown
            options={listRentalInfo}
            defaultTextColor={frontendConfiguration.defaultTextColor}
            isObjectOptions
            itemLabel={this.formatDuration}
            itemKey="id"
            onItemSelect={this.onItemSelect}
            selectedItem={productSelections.selectedRentalInfo}
            icon="ep-icon-money-time ep__txt--md"
            fontFamily={fontText}
          />
        </ShowIfUtil>
        <ShowIfUtil condition={listRentalInfo.length === 1}>
          <i
            className="ep-icon-money-time ep__txt--md ep__txt-blackbold mr-1"
            style={{ color: frontendConfiguration.defaultTextColor }}
          />
          <StyledSpan
            font={fontText}
            className="ep__txt--md ep__txt-regular"
            style={{ color: frontendConfiguration.defaultTextColor }}
          >
            {this.formatDuration(listRentalInfo[0].duration, listRentalInfo[0].unit)}
          </StyledSpan>
        </ShowIfUtil>
      </div>
    );
  };

  getLabelByPaymentType = paymentType => {
    const { frontendConfiguration } = this.props;
    if (paymentType === SELLING_OPTION_TYPE.ONE_TIME) {
      return frontendConfiguration.buyRentLabelValueItems
        ? frontendConfiguration.buyRentLabelValueItems.buy
        : PAYMENT_TYPE.BUY;
    }
    if (paymentType === SELLING_OPTION_TYPE.RECURRING) {
      return frontendConfiguration.buyRentLabelValueItems
        ? frontendConfiguration.buyRentLabelValueItems.rent
        : PAYMENT_TYPE.RENT;
    }
  };

  renderPurchaseAndRental = () => {
    const {
      fontText,
      fontTextBold,
      frontendConfiguration,
      productSelections,
      recurringList
    } = this.props;
    const { selectedValue } = this.state;
    const options = [
      {
        keyName: SELLING_OPTION_TYPE.ONE_TIME,
        valueName: frontendConfiguration.buyRentLabelValueItems
          ? frontendConfiguration.buyRentLabelValueItems.buy
          : PAYMENT_TYPE.BUY
      },
      {
        keyName: SELLING_OPTION_TYPE.RECURRING,
        valueName: frontendConfiguration.buyRentLabelValueItems
          ? frontendConfiguration.buyRentLabelValueItems.rent
          : PAYMENT_TYPE.RENT
      }
    ];

    const selectedOption = {
      keyName:
        selectedValue === SELLING_OPTION_TYPE.ONE_TIME
          ? SELLING_OPTION_TYPE.ONE_TIME
          : SELLING_OPTION_TYPE.RECURRING,
      valueName: this.getLabelByPaymentType(selectedValue)
    };
    const sortedRecurringList = this.sortRecurringList();

    return (
      <div className="d-inline-flex align-items-center ep--my-8 ep-height-33">
        <StyledSpan
          className="d-none d-sm-block ep__txt--md ep__txt-regular mr-1"
          font={fontText}
          style={{ color: frontendConfiguration.defaultTextColor }}
        >
          {'Ich möchte'}
        </StyledSpan>

        <Switch
          options={options}
          onChange={this.onChange}
          fontText={fontText}
          fontTextBold={fontTextBold}
          selectedOption={selectedOption}
        />

        <div className="d-inline-flex align-items-center ml-1">
          <ShowIfUtil
            condition={selectedValue === SELLING_OPTION_TYPE.RECURRING && recurringList.length > 1}
          >
            <DropDown
              options={sortedRecurringList}
              isObjectOptions
              itemLabel={this.formatDuration}
              itemKey="id"
              onItemSelect={this.onItemSelect}
              defaultTextColor={frontendConfiguration.defaultTextColor}
              borderColorFocus={frontendConfiguration.primaryColor}
              selectedItem={productSelections.selectedRentalInfo}
              icon="ep-icon-money-time ep__txt--md"
              height="30px"
              fontFamily={fontText}
            />
          </ShowIfUtil>
          <ShowIfUtil
            condition={
              selectedValue === SELLING_OPTION_TYPE.RECURRING && recurringList.length === 1
            }
          >
            <i
              className="ep-icon-money-time ep__txt--md ep__txt-blackbold"
              style={{ color: frontendConfiguration.defaultTextColor }}
            />
            <StyledSpan
              font={fontText}
              className="ep__txt--md ep__txt-regular ep--ml-8"
              style={{ color: frontendConfiguration.defaultTextColor }}
            >
              {' ' + this.formatDuration(recurringList[0].duration, recurringList[0].unit)}
            </StyledSpan>
          </ShowIfUtil>
        </div>
      </div>
    );
  };

  sortRecurringList = () => {
    const { recurringList } = this.props;
    const clonedRecurringList = _.assign([], recurringList);

    clonedRecurringList.sort(function(a, b) {
      if (a.unit === b.unit) {
        return a.duration - b.duration;
      }
      return a.unit > b.unit ? 1 : -1;
    });

    return clonedRecurringList;
  };

  onChange = e => {
    const { updateProductSelections } = this.props;
    this.setState({ selectedValue: e });
    updateProductSelections({
      selectedPurchaseRental: e
    });
  };

  onItemSelect = item => {
    const { updateProductSelections } = this.props;
    updateProductSelections({
      selectedRentalInfo: item
    });
  };

  formatDuration = (duration, unit) => {
    if (unit === RENTAL.MONTH) {
      if (duration > 1) {
        return duration + SPECIAL_CHARACTER.SPACE + RENTAL.MONTHS_FORMATTED;
      }
      return duration + SPECIAL_CHARACTER.SPACE + RENTAL.MONTH_FORMATTED;
    }
    if (duration > 1) {
      return duration + SPECIAL_CHARACTER.SPACE + RENTAL.YEARS_FORMATTED;
    }
    return duration + SPECIAL_CHARACTER.SPACE + RENTAL.YEAR_FORMATTED;
  };

  render() {
    return (
      <div className="d-flex justify-content-center align-items-center">
        {this.renderDropdown()}
      </div>
    );
  }
}

PurchaseRentalSelectionContainer.propTypes = {
  fontText: PropTypes.object,
  fontTextBold: PropTypes.object,
  productSelections: PropTypes.object,
  frontendConfiguration: PropTypes.object,
  updateProductSelections: PropTypes.func,
  isPurchase: PropTypes.bool.isRequired,
  isRental: PropTypes.bool.isRequired,
  recurringList: PropTypes.array
};

PurchaseRentalSelectionContainer.defaultProps = {
  fontText: {},
  fontTextBold: {},
  productSelections: {},
  frontendConfiguration: {},
  updateProductSelections: () => ({}),
  recurringList: []
};

function mapStateToProps(state) {
  return {
    frontendConfiguration: state.frontendConfiguration,
    productSelections: productSelectionsSelector(state)
  };
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateProductSelections
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseRentalSelectionContainer);

export const ELECTRICITY_MAX_POWER = 'electricityMaxPower';
export const GAS_MAX_LOAD = 'gasMaxLoad';
export const WATER_MAX_LOAD = 'waterMaxLoad';
export const HEATING_CAPACITY = 'heatingCapacity';
export const COMMON_QUESTION_QUANTITY = 7;
export const METER_COMPONENT = 'METER_COMPONENT';
export const KILOWATT_COMPONENT = 'KILOWATT_COMPONENT';
export const KILOWATT_HOUR_COMPONENT = 'KILOWATT_HOUR_COMPONENT';
export const HEATING_CAPACITY_COMPONENT = 'HEATING_CAPACITY_COMPONENT';
export const LITER_PER_SECOND_COMPONENT = 'LITER_PER_SECOND_COMPONENT';
export const HOUSING_UNITS = 'HOUSING_UNITS';
export const STREET_FRONT_LENGTH = 'STREET_FRONT_LENGTH';
export const PROPERTY_SIZE = 'PROPERTY_SIZE';
export const FLOOR_SIZE = 'FLOOR_SIZE';

export const LabelForBroadbandCableRepeater = {
  EN: 'Broadband cable repeater',
  DE: 'Breitband Kabelverstärker'
};

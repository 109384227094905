import { createSelector } from 'reselect';
import { PRIVATELY_OR_BUSINESS, QUESTION_ID } from '../../common/constants';

const getCurrQuestionMap = state => state.currentState.currQuestionMap;
const getHCPrivateOrBusiness = state =>
  state.customerJourneyAnswer.houseConnectionPrivatelyOrBusiness;
const getQuestionMap = state => state.frontendConfiguration.questionMap;

export const isUseBusinessQuestionsSelector = createSelector(
  [getCurrQuestionMap, getHCPrivateOrBusiness],
  (currQuestionMap, houseConnectionPrivatelyOrBusiness) => {
    const privatelyOrBusinessHCQuestion =
      currQuestionMap[QUESTION_ID.HOUSE_CONNECTION_PRIVATELY_OR_BUSINESS];
    if (
      privatelyOrBusinessHCQuestion &&
      privatelyOrBusinessHCQuestion.objectModel &&
      privatelyOrBusinessHCQuestion.objectModel.separateCustomerPath
    ) {
      return (
        houseConnectionPrivatelyOrBusiness !== -1 &&
        houseConnectionPrivatelyOrBusiness === PRIVATELY_OR_BUSINESS.BUSINESS
      );
    }
    return false;
  }
);

export const isUnitHCQuestionExistedSelector = createSelector(
  [getQuestionMap],
  questionMap => !!questionMap[QUESTION_ID.HOUSE_CONNECTION_UNITS]
);

import { createSelector } from 'reselect';
import { emailTemplateV2DataSelector } from './emailTemplateV2DataSelector';
import { addOnV2TotalNetPriceSelector } from './addOnV2TotalPriceSelector';
import { getSubTotalPrice, getTaxPricingSummary } from '../../common/utils';

const getSelectedAddOnV2MapKeyByProductType = state =>
  state.addOnV2Selections.selectedAddOnV2MapKeyByProductType;

const getProductPackagesSummary = state =>
  emailTemplateV2DataSelector(state).productPackagesSummary;

const getAddOnV2NetTotalPrice = state => addOnV2TotalNetPriceSelector(state);

export const subTotalPriceSelector = createSelector(
  [getProductPackagesSummary, getAddOnV2NetTotalPrice],
  (productPackagesSummary, addOnV2NetTotalPrice) => {
    return getSubTotalPrice(productPackagesSummary, addOnV2NetTotalPrice);
  }
);

export const taxPricingSummarySelector = createSelector(
  [getSelectedAddOnV2MapKeyByProductType, getProductPackagesSummary],
  (selectedAddOnV2MapKeyByProductType, productPackagesSummary) => {
    return getTaxPricingSummary(selectedAddOnV2MapKeyByProductType, productPackagesSummary);
  }
);

import { CALL_API } from '../middlewares/customMiddleware';

export const UPDATE_UPLOAD_FILE_STATUS = 'UPDATE_UPLOAD_FILE_STATUS';

export const updateUploadFileStatus = data => ({
  type: UPDATE_UPLOAD_FILE_STATUS,
  payload: data
});

export const UPDATE_PRE_SIGNED_URLS = 'UPDATE_PRE_SIGNED_URLS';

export const updatePreSignedUrls = data => ({
  type: UPDATE_PRE_SIGNED_URLS,
  payload: data
});

export const UPDATE_UPLOAD_FILE_ERRORS = 'UPDATE_UPLOAD_FILE_ERRORS';
export const updateUploadFileErrors = error => ({
  type: UPDATE_UPLOAD_FILE_ERRORS,
  payload: error
});

const preSignedUrlEndpoint =
  process.env.REACT_APP_SLS_S3_PRE_SIGNED_URL ||
  'https://4hcwjnp6l3.execute-api.eu-central-1.amazonaws.com/dev/v1';

export const GET_PRE_SIGNED_URL_REQUEST = 'GET_PRE_SIGNED_URL_REQUEST';
export const GET_PRE_SIGNED_URL_SUCCESS = 'GET_PRE_SIGNED_URL_SUCCESS';
export const GET_PRE_SIGNED_URL_FAILURE = 'GET_PRE_SIGNED_URL_FAILURE';
export const getPreSignedUrl = params => dispatch => {
  return dispatch({
    [CALL_API]: {
      types: [GET_PRE_SIGNED_URL_REQUEST, GET_PRE_SIGNED_URL_SUCCESS, GET_PRE_SIGNED_URL_FAILURE],
      method: 'POST',
      params: params,
      endpoint: preSignedUrlEndpoint
    }
  });
};

export const UPLOAD_FILE_PRE_SIGNED_URL_REQUEST = 'UPLOAD_FILE_PRE_SIGNED_URL_REQUEST';
export const UPLOAD_FILE_PRE_SIGNED_URL_SUCCESS = 'UPLOAD_FILE_PRE_SIGNED_URL_SUCCESS';
export const UPLOAD_FILE_PRE_SIGNED_URL_FAILURE = 'UPLOAD_FILE_PRE_SIGNED_URL_FAILURE';
export const uploadFilePreSignedUrl = params => dispatch => {
  return dispatch({
    [CALL_API]: {
      types: [
        UPLOAD_FILE_PRE_SIGNED_URL_REQUEST,
        UPLOAD_FILE_PRE_SIGNED_URL_SUCCESS,
        UPLOAD_FILE_PRE_SIGNED_URL_FAILURE
      ],
      method: 'PUT',
      params: params,
      isBinary: true,
      endpoint: params.endpoint
    }
  });
};

const linkUploadedAttachmentToOppEndpoint =
  process.env.REACT_APP_SLS_LINK_ATTACHMENT_TO_OPP_URL ||
  'https://8bk58383gb.execute-api.eu-central-1.amazonaws.com/dev/v1';

export const LINK_UPLOADED_ATTACHMENT_TO_OPP_REQUEST = 'LINK_UPLOADED_ATTACHMENT_TO_OPP_REQUEST';
export const LINK_UPLOADED_ATTACHMENT_TO_OPP_SUCCESS = 'LINK_UPLOADED_ATTACHMENT_TO_OPP_SUCCESS';
export const LINK_UPLOADED_ATTACHMENT_TO_OPP_FAILURE = 'LINK_UPLOADED_ATTACHMENT_TO_OPP_FAILURE';
export const linkUploadedAttachmentToOPP = params => dispatch => {
  return dispatch({
    [CALL_API]: {
      types: [
        LINK_UPLOADED_ATTACHMENT_TO_OPP_REQUEST,
        LINK_UPLOADED_ATTACHMENT_TO_OPP_SUCCESS,
        LINK_UPLOADED_ATTACHMENT_TO_OPP_FAILURE
      ],
      method: 'POST',
      params: params,
      endpoint: linkUploadedAttachmentToOppEndpoint
    }
  });
};

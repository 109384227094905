import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { Position, Tooltip } from '@blueprintjs/core';
import { convertProdTypeToProdName, ShowIconByProductTypeUtil } from '../../common/utils';
import {
  COLOR,
  GERMAN_TEXT,
  LIMIT_HEIGHT,
  PRODUCT_TYPE_CURR_PACKAGE_MAPPING,
  SPECIAL_CHARACTER
} from '../../common/constants';
import { StyledSpan } from '../../components/epilot-forms/common';
import { Package } from '.';
import { currentStateSelector } from '../../store/selectors';

class ProductPackageContainer extends Component {
  componentDidUpdate(prevProps) {
    const { customerJourneyAnswer, product } = this.props;
    if (prevProps.customerJourneyAnswer !== customerJourneyAnswer) {
      if (customerJourneyAnswer.availableProduct.indexOf(product.type) < 0) {
        this.showProductComponent();
      }
    }
  }

  showProductComponent = () => {
    const { showProductComponent } = this.props;
    showProductComponent();
  };

  generateStandardPackages = () => {
    const {
      selectedPackage,
      productType,
      fontText,
      fontTextBold,
      currProductPackageConfig,
      currentState
    } = this.props;
    const selectedProduct =
      !_.isEmpty(currProductPackageConfig) && currProductPackageConfig[productType];
    return _.map(selectedProduct.packages, (productPackage, index) => {
      const isSelectedPackage =
        productPackage.id ===
        currentState[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[productType]].packageId;
      return (
        <Package
          key={index}
          productType={productType}
          productPackage={productPackage}
          sellingOptionMap={selectedProduct.sellingOptionMap}
          sellingOptionId={
            currentState[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[productType]].sellingOptionId
          }
          selectedPackage={selectedPackage}
          isSelectedPackage={isSelectedPackage}
          fontText={fontText}
          fontTextBold={fontTextBold}
          currProductPackage={currentState[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[productType]]}
        />
      );
    });
  };

  render() {
    const { fontText, frontendConfiguration, productType, setScrollHeight } = this.props;
    const textColor = {
      color: frontendConfiguration.primaryTextColor
    };

    return (
      <div
        className="ep-webshop-selectpackage-wrapper"
        style={{ borderRadius: frontendConfiguration.borderRadiusMax4px }}
        ref={ref => {
          if (ref) {
            setScrollHeight(ref.clientHeight);
          }
        }}
      >
        <div
          className="ep-webshop-selectpackage-header d-flex align-items-center"
          style={{
            background: frontendConfiguration.primaryColor,
            borderTopLeftRadius: frontendConfiguration.borderRadiusMax4px,
            borderTopRightRadius: frontendConfiguration.borderRadiusMax4px
          }}
          ref={packageHeaderRef => {
            if (packageHeaderRef) {
              this.packageHeader = packageHeaderRef;
            }
          }}
        >
          <i
            className="ep-icon-circle-left-38 ep__txt--h1 ep-cursor-pointer ep--ml-16"
            style={textColor}
            onClick={this.showProductComponent}
          />

          <Tooltip
            content={
              <StyledSpan font={fontText} style={{ color: frontendConfiguration.defaultTextColor }}>
                {convertProdTypeToProdName(productType)}
              </StyledSpan>
            }
            position={Position.BOTTOM}
            className="ep--ml-16"
            tooltipClassName="ep-tooltip"
          >
            <ShowIconByProductTypeUtil
              color={frontendConfiguration.primaryTextColor}
              productType={productType}
            />
          </Tooltip>

          <StyledSpan font={fontText} className="ep__txt--h2 ep--ml-16" style={textColor}>
            {GERMAN_TEXT.SELECT_A_PACKAGE}
          </StyledSpan>
        </div>

        <div
          className="overflow-auto"
          style={{
            backgroundColor: COLOR.LIGHT_GRAY,
            maxHeight: frontendConfiguration.isShowPackageInfo
              ? this.packageHeader
                ? parseInt(LIMIT_HEIGHT.DEFAULT_CUSTOMER_JOURNEY_HEIGHT) -
                  this.packageHeader.clientHeight
                : '518px'
              : this.packageHeader
              ? parseInt(LIMIT_HEIGHT.DEFAULT_WEBSHOPV2_WRAPPER) - this.packageHeader &&
                this.packageHeader.clientHeight
              : '422px'
          }}
        >
          {this.generateStandardPackages()}
        </div>
      </div>
    );
  }
}

ProductPackageContainer.propTypes = {
  currProductPackageConfig: PropTypes.object,
  product: PropTypes.object,
  selectedPackage: PropTypes.object,
  showProductComponent: PropTypes.func,
  setScrollHeight: PropTypes.func.isRequired,
  fontText: PropTypes.object,
  fontTextBold: PropTypes.object,
  customerJourneyAnswer: PropTypes.object,
  frontendConfiguration: PropTypes.object,
  currentState: PropTypes.object,
  productType: PropTypes.string
};

ProductPackageContainer.defaultProps = {
  currProductPackageConfig: {},
  fontText: {},
  fontTextBold: {},
  product: {},
  selectedPackage: {},
  customerJourneyAnswer: {},
  frontendConfiguration: {},
  showProductComponent: () => ({}),
  currentState: {},
  productType: SPECIAL_CHARACTER.EMPTY
};

function mapStateToProps(state) {
  const currentState = currentStateSelector(state);
  const { currProductPackageConfig } = currentState;
  return {
    frontendConfiguration: state.frontendConfiguration,
    customerJourneyAnswer: state.customerJourneyAnswer,
    customerJourneyMapping: state.customerJourneyMapping,
    currentState,
    currProductPackageConfig
  };
}

export default connect(mapStateToProps)(ProductPackageContainer);

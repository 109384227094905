import { createSelector } from 'reselect';
import _ from 'lodash';
import { selectedProductsSelector } from './selectedProductsSelector';
import { ADD_ON_V2_PRODUCT_TYPE, PRODUCT_TYPE_CURR_PACKAGE_MAPPING } from '../../common/constants';
import { currentStateSelector } from './currentStateSelector';
import { isPricingV2SelectedProductOnRequest } from '../../common/utils';

const getSelectedAddOnV2MapKeyByProductType = state =>
  state.addOnV2Selections.selectedAddOnV2MapKeyByProductType;

const getSelectedProducts = state => selectedProductsSelector(state);

const groupByAddOnId = (selectedAddOns, accumulator) =>
  _.reduce(
    selectedAddOns,
    (result, selectedAddOn) => {
      if (!result[selectedAddOn.addOnId]) {
        result[selectedAddOn.addOnId] = [selectedAddOn.addOnSellingOption.id];
      } else {
        result[selectedAddOn.addOnId].push(selectedAddOn.addOnSellingOption.id);
      }
      return result;
    },
    accumulator
  );

const initSelectedAddOnMapKeyByProductType = selectedProducts => {
  return _.reduce(
    selectedProducts,
    (result, value) => {
      result[value.type] = {};
      return result;
    },
    {}
  );
};

export const createBundlingOPAddOnV2RequestParamsSelector = createSelector(
  [getSelectedAddOnV2MapKeyByProductType],
  selectedAddOnV2MapKeyByProductType =>
    _.reduce(
      selectedAddOnV2MapKeyByProductType,
      (result, selectedAddOns) => {
        result = groupByAddOnId(selectedAddOns, result);
        return result;
      },
      {}
    )
);

const getCurrentState = state => currentStateSelector(state);

export const createNonBundlingOPAddOnV2RequestParamsSelector = createSelector(
  [getSelectedAddOnV2MapKeyByProductType, getSelectedProducts, getCurrentState],
  (selectedAddOnV2MapKeyByProductType, selectedProducts, currentState) => {
    const tmpSelectedAddOnMapKeyByProductType = _.reduce(
      selectedAddOnV2MapKeyByProductType,
      (result, selectedAddOns, key) => {
        result[key] = groupByAddOnId(selectedAddOns, {});
        return result;
      },
      initSelectedAddOnMapKeyByProductType(selectedProducts)
    );

    if (tmpSelectedAddOnMapKeyByProductType[ADD_ON_V2_PRODUCT_TYPE.GLOBAL]) {
      _.forIn(tmpSelectedAddOnMapKeyByProductType, (addOnMapKeyByAddOnId, productType) => {
        // Add global add-ons to selected add-ons of one selected product package
        // That selected product package should not be on request
        const currentPackage = currentState[PRODUCT_TYPE_CURR_PACKAGE_MAPPING[productType]];
        const currentProduct = selectedProducts.find(
          selectedProduct => selectedProduct.type === productType
        );

        if (
          productType !== ADD_ON_V2_PRODUCT_TYPE.GLOBAL &&
          !isPricingV2SelectedProductOnRequest({
            currentSelectedPackage: currentPackage,
            selectedProduct: currentProduct
          })
        ) {
          _.forIn(
            tmpSelectedAddOnMapKeyByProductType[ADD_ON_V2_PRODUCT_TYPE.GLOBAL],
            (addOnSellingOptionIds, addOnId) => {
              if (addOnMapKeyByAddOnId[addOnId]) {
                addOnMapKeyByAddOnId[addOnId].push(...addOnSellingOptionIds);
              } else {
                addOnMapKeyByAddOnId[addOnId] = addOnSellingOptionIds;
              }
            }
          );
          return false;
        }
      });
    }
    return tmpSelectedAddOnMapKeyByProductType;
  }
);

import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { COLOR, MESSAGE_CONSTANTS, SPECIAL_CHARACTER } from '../../../common/constants';
import { ShowIfUtil } from '../../../common/utils';
import { StyledLabel, StyledSpan } from './StyledElement';

const TextAreaFont = styled.textarea`
  @font-face {
    font-family: 'custom';
    src: url(${props => props.font.fontUrl});
  }

  font-family: ${props => props.font.font} !important;

  &:focus {
    border-color: ${props => props.borderColorFocus} !important;
  }
`;

class TextArea extends Component {
  constructor(props) {
    super(props);
    const { value, height } = this.props;
    this.state = {
      value: value,
      isValid: true
    };
    this.element = null;
    this.autoHeight = height;
  }

  componentWillReceiveProps(nextProps) {
    const { value } = this.props;
    if (nextProps.value !== value) {
      this.setState({ value: nextProps.value });
    }
    if (nextProps.require && typeof nextProps.value === 'string' && nextProps.value.trim()) {
      this.setState({ isValid: true });
    }
  }

  onChange = e => {
    const { onChange, height } = this.props;
    this.autoHeight =
      this.element.scrollHeight > height && e.target.value ? this.element.scrollHeight : height;
    this.setState({ value: e.target.value });
    onChange(e.target.value);
  };

  onBlur = e => {
    const { onBlur } = this.props;
    onBlur(e);
  };

  handleFocus = e => {
    e.target.select();
  };

  render() {
    const {
      name,
      textAlign,
      classStyle,
      label,
      fontFamily,
      defaultTextColor,
      type,
      disabled,
      placeholder,
      maxLength,
      borderColorFocus,
      borderRadius,
      fontSize,
      width,
      maxWidth,
      minWidth,
      require
    } = this.props;
    const { value, isValid } = this.state;
    return (
      <div className={classStyle}>
        {/*----- INPUT LABEL -----*/}
        <ShowIfUtil condition={label !== SPECIAL_CHARACTER.EMPTY}>
          <StyledLabel
            className="ep__txt--sm ep__txt-regular ep__txt-no-select"
            font={fontFamily}
            style={{ color: defaultTextColor }}
          >
            {label}
            <ShowIfUtil condition={require}>
              <StyledSpan
                className="ep__txt--sm ep__txt-regular ep__txt-no-select"
                font={fontFamily}
                style={{ color: defaultTextColor }}
              >
                {' *'}
              </StyledSpan>
            </ShowIfUtil>
          </StyledLabel>
        </ShowIfUtil>
        {/*----- INPUT -----*/}
        <TextAreaFont
          ref={el => (this.element = el)}
          font={fontFamily}
          className="ep-input"
          type={type}
          name={name}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          maxLength={maxLength}
          onChange={this.onChange}
          onFocus={this.handleFocus}
          onBlur={this.onBlur}
          min={0}
          borderColorFocus={borderColorFocus}
          style={{
            borderRadius: borderRadius,
            fontSize: fontSize,
            width: width,
            maxWidth: maxWidth,
            minWidth: minWidth,
            height: this.autoHeight,
            textAlign: textAlign,
            resize: 'none',
            paddingTop: '15px',
            overflow: 'auto',
            color: defaultTextColor
          }}
        />
        {/*----- ERROR MESSAGE -----*/}
        <ShowIfUtil condition={!isValid && value.trim() === SPECIAL_CHARACTER.EMPTY}>
          <div className="">{MESSAGE_CONSTANTS.REQUIRED}</div>
        </ShowIfUtil>
      </div>
    );
  }
}

TextArea.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  require: PropTypes.bool,
  classStyle: PropTypes.string,
  defaultTextColor: PropTypes.string,
  borderRadius: PropTypes.string,
  fontFamily: PropTypes.object,
  fontSize: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  textAlign: PropTypes.string,
  borderColorFocus: PropTypes.string,
  disabled: PropTypes.bool,
  maxWidth: PropTypes.string,
  minWidth: PropTypes.string,
  value: PropTypes.string
};

TextArea.defaultProps = {
  borderColorFocus: '',
  disabled: false,
  maxWidth: '',
  minWidth: '',
  value: '',
  type: 'text',
  label: SPECIAL_CHARACTER.EMPTY,
  placeholder: null,
  maxLength: 5000,
  name: SPECIAL_CHARACTER.EMPTY,
  require: false,
  classStyle: SPECIAL_CHARACTER.EMPTY,
  defaultTextColor: COLOR.DARK_GRAY,
  borderRadius: SPECIAL_CHARACTER.EMPTY,
  fontFamily: {},
  fontSize: '14px',
  width: '100%',
  height: 64,
  textAlign: 'left',
  onChange: () => ({}),
  onBlur: () => ({})
};

export default TextArea;

import styled from 'styled-components';

export const StyledP = styled.p`
  @font-face {
    font-family: 'custom';
    src: url(${props => props.font.fontUrl});
  }

  font-family: ${props => props.font.font} !important;
`;

export const StyledSpan = styled.span`
  @font-face {
    font-family: 'custom';
    src: url(${props => props.font.fontUrl});
  }

  font-family: ${props => props.font.font} !important;
`;

export const StyledSpanBold = styled.span`
  @font-face {
    font-family: 'customBold';
    src: url(${props => props.font.fontUrl});
  }

  font-family: ${props => props.font.font} !important;
`;

export const StyledLabel = styled.label`
  @font-face {
    font-family: 'custom';
    src: url(${props => props.font.fontUrl});
  }

  font-family: ${props => props.font.font} !important;
`;

export const StyledLabelBold = styled.label`
  @font-face {
    font-family: 'customBold';
    src: url(${props => props.font.fontUrl});
  }

  font-family: ${props => props.font.font} !important;
`;

export const StyledButton = styled.button`
  @font-face {
    font-family: 'custom';
    src: url(${props => props?.font?.fontUrl});
  }

  font-family: ${props => props?.font?.font} !important;

  &:focus {
    border-color: ${props => props?.borderColorFocus} !important;
  }
`;

export const StyledButtonBold = styled.button`
  @font-face {
    font-family: 'customBold';
    src: url(${props => props?.font?.fontUrl});
  }

  font-family: ${props => props?.font?.font} !important;

  &:focus {
    border-color: ${props => props?.borderColorFocus} !important;
  }
`;

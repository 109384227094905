import { createSelector } from 'reselect';
import _ from 'lodash';
import { CURR_PACKAGE_PRODUCT_TYPE_MAPPING, PAYMENT_FREQUENCY } from '../../common/constants';
import { currentStateSelector, selectedProductsSelector } from '.';

const getCurrentState = state => currentStateSelector(state);
const getSelectedProduct = state => selectedProductsSelector(state);
const getSelectedAddOnV2MapKeyByProductType = state =>
  state.addOnV2Selections.selectedAddOnV2MapKeyByProductType;

export const emailTemplateV2DataSelector = createSelector(
  [getCurrentState, getSelectedProduct, getSelectedAddOnV2MapKeyByProductType],
  (currentState, selectedProduct, selectedAddOnV2MapKeyByProductType) => {
    const productPackagesSummary = [];
    _.forIn(currentState, (value, key) => {
      if (
        CURR_PACKAGE_PRODUCT_TYPE_MAPPING[key] &&
        _.find(selectedProduct, elem => elem.type === CURR_PACKAGE_PRODUCT_TYPE_MAPPING[key])
      ) {
        productPackagesSummary.push({
          packageId: value.packageId,
          packageName: value.name,
          sellingOptionName: value.sellingOptionName,
          duration: value.duration,
          durationUnit: value.durationUnit,
          productType: CURR_PACKAGE_PRODUCT_TYPE_MAPPING[key],
          alwaysShowOnRequest: value.alwaysShowOnRequest,
          ...parseEmailTemplateData({
            packageData: value,
            addOnData:
              selectedAddOnV2MapKeyByProductType[CURR_PACKAGE_PRODUCT_TYPE_MAPPING[key]] || []
          })
        });
      }
    });
    return {
      productPackagesSummary
    };
  }
);

const parseEmailTemplateData = ({ packageData, addOnData }) => {
  let totalOneTimeNetRetailPrice = null;
  let totalMonthlyNetRetailPrice = null;
  let totalYearlyNetRetailPrice = null;
  let packageTaxData = [];
  let addOnTaxData = [];

  if (_.isEmpty(packageData.pricingComponents)) {
    return {
      onRequest: true
    };
  }
  _.forEach(packageData.pricingComponents, pricingComponent => {
    const {
      netPrice,
      retailPriceTaxId,
      paymentFrequency,
      retailTax,
      price,
      realPrice,
      netRealPrice
    } = pricingComponent;

    let oneTimeTax = null;
    let monthlyTax = null;
    let yearlyTax = null;

    let finalRealPrice = _.isNumber(realPrice) ? realPrice : price;
    let finalRealNetPrice = _.isNumber(netRealPrice) ? netRealPrice : netPrice;

    switch (paymentFrequency) {
      case PAYMENT_FREQUENCY.ONE_TIME:
        totalOneTimeNetRetailPrice = totalOneTimeNetRetailPrice + finalRealNetPrice;
        oneTimeTax = finalRealPrice - finalRealNetPrice;
        break;
      case PAYMENT_FREQUENCY.MONTHLY:
        totalMonthlyNetRetailPrice = totalMonthlyNetRetailPrice + finalRealNetPrice;
        monthlyTax = finalRealPrice - finalRealNetPrice;
        break;
      case PAYMENT_FREQUENCY.YEARLY:
        totalYearlyNetRetailPrice = totalYearlyNetRetailPrice + finalRealNetPrice;
        yearlyTax = finalRealPrice - finalRealNetPrice;
        break;
      default:
        break;
    }

    if (retailPriceTaxId !== 0) {
      packageTaxData.push({
        id: retailPriceTaxId,
        name: retailTax.name,
        rate: retailTax.rate,
        price: {
          [PAYMENT_FREQUENCY.ONE_TIME]: oneTimeTax,
          [PAYMENT_FREQUENCY.MONTHLY]: monthlyTax,
          [PAYMENT_FREQUENCY.YEARLY]: yearlyTax
        }
      });
    }
  });

  _.forEach(addOnData, addOn => {
    if (!addOn.addOnSellingOption.showPriceOnFrontend) {
      addOnTaxData.push(...addOn.addOnSellingOption.taxes);
    }
  });

  return {
    // Accept negative price (BW-8)
    totalOneTimeNetRetailPrice,
    totalMonthlyNetRetailPrice,
    totalYearlyNetRetailPrice,
    taxData: [...packageTaxData, ...addOnTaxData],
    onRequest: packageData.alwaysShowOnRequest
  };
};

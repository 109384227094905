import { RESET_CUSTOMER_JOURNEY_DATA, UPDATE_CUSTOMER_JOURNEY_MAPPING } from '../actions';

let initState = {
  householdConsumption: 0,
  electricCarUsageConsumption: 0,
  totalConsumption: 0,
  outputHeating: 0,
  selectStorage: false,
  selectCharge: false,
  selectPower: false
};

export default (state = initState, action) => {
  switch (action.type) {
    case UPDATE_CUSTOMER_JOURNEY_MAPPING:
      return {
        ...state,
        ...action.payload
      };
    case RESET_CUSTOMER_JOURNEY_DATA:
      return initState;
    default:
      return state;
  }
};
